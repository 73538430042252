const project8ShadingProgression = [
  {
    ID: -1,
    polygon: "ANotExistingPolygon",
    "04-15 00:00": 0,
    "04-15 00:15": 0,
    "04-15 00:30": 0,
    "04-15 00:45": 0,
    "04-15 01:00": 0,
    "04-15 01:15": 0,
    "04-15 01:30": 0,
    "04-15 01:45": 0,
    "04-15 02:00": 0,
    "04-15 02:15": 0,
    "04-15 02:30": 0,
    "04-15 02:45": 0,
    "04-15 03:00": 0,
    "04-15 03:15": 0,
    "04-15 03:30": 0,
    "04-15 03:45": 0,
    "04-15 04:00": 0,
    "04-15 04:15": 0,
    "04-15 04:30": 0,
    "04-15 04:45": 0,
    "04-15 05:00": 0,
    "04-15 05:15": 0,
    "04-15 05:30": 0,
    "04-15 05:45": 0,
    "04-15 06:00": 0,
    "04-15 06:15": 0,
    "04-15 06:30": 0,
    "04-15 06:45": 0,
    "04-15 07:00": 0,
    "04-15 07:15": 0,
    "04-15 07:30": 0,
    "04-15 07:45": 0,
    "04-15 08:00": 0,
    "04-15 08:15": 0,
    "04-15 08:30": 0,
    "04-15 08:45": 0,
    "04-15 09:00": 0,
    "04-15 09:15": 0,
    "04-15 09:30": 0,
    "04-15 09:45": 0,
    "04-15 10:00": 0,
    "04-15 10:15": 0,
    "04-15 10:30": 0,
    "04-15 10:45": 0,
    "04-15 11:00": 0,
    "04-15 11:15": 0,
    "04-15 11:30": 0,
    "04-15 11:45": 0,
    "04-15 12:00": 0,
    "04-15 12:15": 0,
    "04-15 12:30": 0,
    "04-15 12:45": 0,
    "04-15 13:00": 0,
    "04-15 13:15": 0,
    "04-15 13:30": 0,
    "04-15 13:45": 0,
    "04-15 14:00": 0,
    "04-15 14:15": 0,
    "04-15 14:30": 0,
    "04-15 14:45": 0,
    "04-15 15:00": 0,
    "04-15 15:15": 0,
    "04-15 15:30": 0,
    "04-15 15:45": 0,
    "04-15 16:00": 0,
    "04-15 16:15": 0,
    "04-15 16:30": 0,
    "04-15 16:45": 0,
    "04-15 17:00": 0,
    "04-15 17:15": 0,
    "04-15 17:30": 0,
    "04-15 17:45": 0,
    "04-15 18:00": 0,
    "04-15 18:15": 0,
    "04-15 18:30": 0,
    "04-15 18:45": 0,
    "04-15 19:00": 0,
    "04-15 19:15": 0,
    "04-15 19:30": 0,
    "04-15 19:45": 0,
    "04-15 20:00": 0,
    "04-15 20:15": 0,
    "04-15 20:30": 0,
    "04-15 20:45": 0,
    "04-15 21:00": 0,
    "04-15 21:15": 0,
    "04-15 21:30": 0,
    "04-15 21:45": 0,
    "04-15 22:00": 0,
    "04-15 22:15": 0,
    "04-15 22:30": 0,
    "04-15 22:45": 0,
    "04-15 23:00": 0,
    "04-15 23:15": 0,
    "04-15 23:30": 0,
    "04-15 23:45": 0,
  },
  {
    ID: "1OG_100",
    polygon: [
      -41.513,
      -65.556,
      7.17,
      -41.513,
      -65.556,
      10.165,
      -45.465,
      -66.67,
      10.165,
      -45.465,
      -66.67,
      10.165,
      -45.465,
      -66.67,
      7.17,
      -41.513,
      -65.556,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
  },
  {
    ID: "1OG_101",
    polygon: [
      -50.611,
      -68.119,
      7.17,
      -50.611,
      -68.119,
      10.165,
      -54.563,
      -69.232,
      10.165,
      -54.563,
      -69.232,
      10.165,
      -54.563,
      -69.232,
      7.17,
      -50.611,
      -68.119,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
  },
  {
    ID: "1OG_118",
    polygon: [
      0.166,
      -50.843,
      7.17,
      0.166,
      -50.843,
      10.165,
      0.804,
      -53.109,
      10.165,
      0.804,
      -53.109,
      10.165,
      0.804,
      -53.109,
      7.17,
      0.166,
      -50.843,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
  },
  {
    ID: "1OG_119",
    polygon: [
      -0.869,
      -47.166,
      7.17,
      -0.869,
      -47.166,
      10.165,
      -0.231,
      -49.432,
      10.165,
      -0.231,
      -49.432,
      10.165,
      -0.231,
      -49.432,
      7.17,
      -0.869,
      -47.166,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "1OG_120",
    polygon: [
      -1.901,
      -43.504,
      7.17,
      -1.901,
      -43.504,
      10.165,
      -1.263,
      -45.769,
      10.165,
      -1.263,
      -45.769,
      10.165,
      -1.263,
      -45.769,
      7.17,
      -1.901,
      -43.504,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
  },
  {
    ID: "1OG_121",
    polygon: [
      -2.937,
      -39.827,
      7.17,
      -2.937,
      -39.827,
      10.165,
      -2.299,
      -42.093,
      10.165,
      -2.299,
      -42.093,
      10.165,
      -2.299,
      -42.093,
      7.17,
      -2.937,
      -39.827,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
  },
  {
    ID: "1OG_186",
    polygon: [
      -44.926,
      -51.143,
      7.17,
      -44.926,
      -51.143,
      10.165,
      -40.974,
      -50.03,
      10.165,
      -40.974,
      -50.03,
      10.165,
      -40.974,
      -50.03,
      7.17,
      -44.926,
      -51.143,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_187",
    polygon: [
      -40.377,
      -49.861,
      7.17,
      -40.377,
      -49.861,
      10.165,
      -36.424,
      -48.748,
      10.165,
      -36.424,
      -48.748,
      10.165,
      -36.424,
      -48.748,
      7.17,
      -40.377,
      -49.861,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_188",
    polygon: [
      -54.024,
      -53.706,
      7.17,
      -54.024,
      -53.706,
      10.165,
      -50.071,
      -52.592,
      10.165,
      -50.071,
      -52.592,
      10.165,
      -50.071,
      -52.592,
      7.17,
      -54.024,
      -53.706,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_189",
    polygon: [
      -49.474,
      -52.424,
      7.17,
      -49.474,
      -52.424,
      10.165,
      -45.522,
      -51.311,
      10.165,
      -45.522,
      -51.311,
      10.165,
      -45.522,
      -51.311,
      7.17,
      -49.474,
      -52.424,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_190",
    polygon: [
      -58.572,
      -54.987,
      7.17,
      -58.572,
      -54.987,
      10.165,
      -54.62,
      -53.874,
      10.165,
      -54.62,
      -53.874,
      10.165,
      -54.62,
      -53.874,
      7.17,
      -58.572,
      -54.987,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_2323",
    polygon: [
      -35.006,
      -20.436,
      6.82,
      -37.027,
      -21.005,
      6.82,
      -36.729,
      -22.064,
      6.82,
      -36.729,
      -22.064,
      6.82,
      -34.708,
      -21.494,
      6.82,
      -35.006,
      -20.436,
      6.82,
    ],
    "04-15 02:05": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
  },
  {
    ID: "1OG_330",
    polygon: [
      -11.004,
      -44.561,
      7.17,
      -11.004,
      -44.561,
      10.165,
      -11.642,
      -42.295,
      10.165,
      -11.642,
      -42.295,
      10.165,
      -11.642,
      -42.295,
      7.17,
      -11.004,
      -44.561,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1OG_331",
    polygon: [
      -9.968,
      -48.237,
      7.17,
      -9.968,
      -48.237,
      10.165,
      -10.606,
      -45.972,
      10.165,
      -10.606,
      -45.972,
      10.165,
      -10.606,
      -45.972,
      7.17,
      -9.968,
      -48.237,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 13:00": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1OG_332",
    polygon: [
      -8.937,
      -51.9,
      7.17,
      -8.937,
      -51.9,
      10.165,
      -9.575,
      -49.634,
      10.165,
      -9.575,
      -49.634,
      10.165,
      -9.575,
      -49.634,
      7.17,
      -8.937,
      -51.9,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "1OG_333",
    polygon: [
      -7.901,
      -55.577,
      7.17,
      -7.901,
      -55.577,
      10.165,
      -8.539,
      -53.311,
      10.165,
      -8.539,
      -53.311,
      10.165,
      -8.539,
      -53.311,
      7.17,
      -7.901,
      -55.577,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "1OG_364",
    polygon: [
      -79.986,
      -73.415,
      7.17,
      -79.986,
      -73.415,
      10.165,
      -79.348,
      -75.681,
      10.165,
      -79.348,
      -75.681,
      10.165,
      -79.348,
      -75.681,
      7.17,
      -79.986,
      -73.415,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_365",
    polygon: [
      -81.022,
      -69.738,
      7.17,
      -81.022,
      -69.738,
      10.165,
      -80.384,
      -72.004,
      10.165,
      -80.384,
      -72.004,
      10.165,
      -80.384,
      -72.004,
      7.17,
      -81.022,
      -69.738,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_366",
    polygon: [
      -82.053,
      -66.076,
      7.17,
      -82.053,
      -66.076,
      10.165,
      -81.415,
      -68.342,
      10.165,
      -81.415,
      -68.342,
      10.165,
      -81.415,
      -68.342,
      7.17,
      -82.053,
      -66.076,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_367",
    polygon: [
      -83.089,
      -62.399,
      7.17,
      -83.089,
      -62.399,
      10.165,
      -82.451,
      -64.665,
      10.165,
      -82.451,
      -64.665,
      10.165,
      -82.451,
      -64.665,
      7.17,
      -83.089,
      -62.399,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
  },
  {
    ID: "1OG_368",
    polygon: [
      -62.982,
      -59.647,
      7.17,
      -62.982,
      -59.647,
      10.165,
      -63.851,
      -56.562,
      10.165,
      -63.851,
      -56.562,
      10.165,
      -63.851,
      -56.562,
      7.17,
      -62.982,
      -59.647,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
  },
  {
    ID: "1OG_369",
    polygon: [
      -61.945,
      -63.328,
      7.17,
      -61.945,
      -63.328,
      10.165,
      -62.814,
      -60.243,
      10.165,
      -62.814,
      -60.243,
      10.165,
      -62.814,
      -60.243,
      7.17,
      -61.945,
      -63.328,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
  },
  {
    ID: "1OG_370",
    polygon: [
      -60.915,
      -66.986,
      7.17,
      -60.915,
      -66.986,
      10.165,
      -61.784,
      -63.901,
      10.165,
      -61.784,
      -63.901,
      10.165,
      -61.784,
      -63.901,
      7.17,
      -60.915,
      -66.986,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_371",
    polygon: [
      -59.878,
      -70.668,
      7.17,
      -59.878,
      -70.668,
      10.165,
      -60.747,
      -67.583,
      10.165,
      -60.747,
      -67.583,
      10.165,
      -60.747,
      -67.583,
      7.17,
      -59.878,
      -70.668,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_372",
    polygon: [
      -28.006,
      -58.334,
      7.17,
      -28.006,
      -58.334,
      10.165,
      -27.137,
      -61.419,
      10.165,
      -27.137,
      -61.419,
      10.165,
      -27.137,
      -61.419,
      7.17,
      -28.006,
      -58.334,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_373",
    polygon: [
      -29.043,
      -54.653,
      7.17,
      -29.043,
      -54.653,
      10.165,
      -28.174,
      -57.738,
      10.165,
      -28.174,
      -57.738,
      10.165,
      -28.174,
      -57.738,
      7.17,
      -29.043,
      -54.653,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_374",
    polygon: [
      -30.074,
      -50.995,
      7.17,
      -30.074,
      -50.995,
      10.165,
      -29.205,
      -54.08,
      10.165,
      -29.205,
      -54.08,
      10.165,
      -29.205,
      -54.08,
      7.17,
      -30.074,
      -50.995,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_375",
    polygon: [
      -31.111,
      -47.313,
      7.17,
      -31.111,
      -47.313,
      10.165,
      -30.242,
      -50.398,
      10.165,
      -30.242,
      -50.398,
      10.165,
      -30.242,
      -50.398,
      7.17,
      -31.111,
      -47.313,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1OG_57",
    polygon: [
      -91.158,
      -67.131,
      7.17,
      -91.158,
      -67.131,
      10.165,
      -91.793,
      -64.879,
      10.165,
      -91.793,
      -64.879,
      10.165,
      -91.793,
      -64.879,
      7.17,
      -91.158,
      -67.131,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1OG_58",
    polygon: [
      -90.123,
      -70.808,
      7.17,
      -90.123,
      -70.808,
      10.165,
      -90.757,
      -68.556,
      10.165,
      -90.757,
      -68.556,
      10.165,
      -90.757,
      -68.556,
      7.17,
      -90.123,
      -70.808,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "1OG_59",
    polygon: [
      -89.091,
      -74.471,
      7.17,
      -89.091,
      -74.471,
      10.165,
      -89.725,
      -72.218,
      10.165,
      -89.725,
      -72.218,
      10.165,
      -89.725,
      -72.218,
      7.17,
      -89.091,
      -74.471,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "1OG_60",
    polygon: [
      -88.055,
      -78.148,
      7.17,
      -88.055,
      -78.148,
      10.165,
      -88.69,
      -75.895,
      10.165,
      -88.69,
      -75.895,
      10.165,
      -88.69,
      -75.895,
      7.17,
      -88.055,
      -78.148,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1OG_97",
    polygon: [
      -36.964,
      -64.275,
      7.17,
      -36.964,
      -64.275,
      10.165,
      -40.916,
      -65.388,
      10.165,
      -40.916,
      -65.388,
      10.165,
      -40.916,
      -65.388,
      7.17,
      -36.964,
      -64.275,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1OG_98",
    polygon: [
      -32.415,
      -62.994,
      7.17,
      -32.415,
      -62.994,
      10.165,
      -36.367,
      -64.107,
      10.165,
      -36.367,
      -64.107,
      10.165,
      -36.367,
      -64.107,
      7.17,
      -32.415,
      -62.994,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
  },
  {
    ID: "1OG_99",
    polygon: [
      -46.062,
      -66.838,
      7.17,
      -46.062,
      -66.838,
      10.165,
      -50.014,
      -67.951,
      10.165,
      -50.014,
      -67.951,
      10.165,
      -50.014,
      -67.951,
      7.17,
      -46.062,
      -66.838,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
  },
  {
    ID: "1UG_2284",
    polygon: [
      20.088,
      -29.979,
      0.3,
      20.088,
      -29.979,
      1.45,
      20.413,
      -31.135,
      1.45,
      20.413,
      -31.135,
      1.45,
      20.413,
      -31.135,
      0.3,
      20.088,
      -29.979,
      0.3,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1UG_2285",
    polygon: [
      17.154,
      -19.565,
      0.2,
      17.154,
      -19.565,
      1.35,
      17.479,
      -20.72,
      1.35,
      17.479,
      -20.72,
      1.35,
      17.479,
      -20.72,
      0.2,
      17.154,
      -19.565,
      0.2,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "1UG_2286",
    polygon: [
      7.429,
      15.512,
      0.4,
      7.429,
      15.512,
      1.4,
      7.7,
      14.55,
      1.4,
      7.7,
      14.55,
      1.4,
      7.7,
      14.55,
      0.4,
      7.429,
      15.512,
      0.4,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "2OG_405",
    polygon: [
      0.166,
      -50.843,
      10.67,
      0.166,
      -50.843,
      13.665,
      0.804,
      -53.109,
      13.665,
      0.804,
      -53.109,
      13.665,
      0.804,
      -53.109,
      10.67,
      0.166,
      -50.843,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
  },
  {
    ID: "2OG_406",
    polygon: [
      -0.869,
      -47.166,
      10.67,
      -0.869,
      -47.166,
      13.665,
      -0.231,
      -49.432,
      13.665,
      -0.231,
      -49.432,
      13.665,
      -0.231,
      -49.432,
      10.67,
      -0.869,
      -47.166,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "2OG_407",
    polygon: [
      -1.901,
      -43.504,
      10.67,
      -1.901,
      -43.504,
      13.665,
      -1.263,
      -45.769,
      13.665,
      -1.263,
      -45.769,
      13.665,
      -1.263,
      -45.769,
      10.67,
      -1.901,
      -43.504,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
  },
  {
    ID: "2OG_408",
    polygon: [
      -2.937,
      -39.827,
      10.67,
      -2.937,
      -39.827,
      13.665,
      -2.299,
      -42.093,
      13.665,
      -2.299,
      -42.093,
      13.665,
      -2.299,
      -42.093,
      10.67,
      -2.937,
      -39.827,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "2OG_526",
    polygon: [
      -11.004,
      -44.561,
      10.67,
      -11.004,
      -44.561,
      13.665,
      -11.642,
      -42.295,
      13.665,
      -11.642,
      -42.295,
      13.665,
      -11.642,
      -42.295,
      10.67,
      -11.004,
      -44.561,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "2OG_527",
    polygon: [
      -9.968,
      -48.237,
      10.67,
      -9.968,
      -48.237,
      13.665,
      -10.606,
      -45.972,
      13.665,
      -10.606,
      -45.972,
      13.665,
      -10.606,
      -45.972,
      10.67,
      -9.968,
      -48.237,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "2OG_528",
    polygon: [
      -8.937,
      -51.9,
      10.67,
      -8.937,
      -51.9,
      13.665,
      -9.575,
      -49.634,
      13.665,
      -9.575,
      -49.634,
      13.665,
      -9.575,
      -49.634,
      10.67,
      -8.937,
      -51.9,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "2OG_529",
    polygon: [
      -7.901,
      -55.577,
      10.67,
      -7.901,
      -55.577,
      13.665,
      -8.539,
      -53.311,
      13.665,
      -8.539,
      -53.311,
      13.665,
      -8.539,
      -53.311,
      10.67,
      -7.901,
      -55.577,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2OG_712",
    polygon: [
      -89.091,
      -74.471,
      10.67,
      -89.091,
      -74.471,
      13.665,
      -89.725,
      -72.218,
      13.665,
      -89.725,
      -72.218,
      13.665,
      -89.725,
      -72.218,
      10.67,
      -89.091,
      -74.471,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "2OG_713",
    polygon: [
      -88.055,
      -78.148,
      10.67,
      -88.055,
      -78.148,
      13.665,
      -88.69,
      -75.895,
      13.665,
      -88.69,
      -75.895,
      13.665,
      -88.69,
      -75.895,
      10.67,
      -88.055,
      -78.148,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "2OG_759",
    polygon: [
      -79.986,
      -73.415,
      10.67,
      -79.986,
      -73.415,
      13.665,
      -79.348,
      -75.681,
      13.665,
      -79.348,
      -75.681,
      13.665,
      -79.348,
      -75.681,
      10.67,
      -79.986,
      -73.415,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "2OG_760",
    polygon: [
      -81.022,
      -69.738,
      10.67,
      -81.022,
      -69.738,
      13.665,
      -80.384,
      -72.004,
      13.665,
      -80.384,
      -72.004,
      13.665,
      -80.384,
      -72.004,
      10.67,
      -81.022,
      -69.738,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "2OG_783",
    polygon: [
      -91.158,
      -67.131,
      10.67,
      -91.158,
      -67.131,
      13.665,
      -91.793,
      -64.879,
      13.665,
      -91.793,
      -64.879,
      13.665,
      -91.793,
      -64.879,
      10.67,
      -91.158,
      -67.131,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "2OG_784",
    polygon: [
      -90.123,
      -70.808,
      10.67,
      -90.123,
      -70.808,
      13.665,
      -90.757,
      -68.556,
      13.665,
      -90.757,
      -68.556,
      13.665,
      -90.757,
      -68.556,
      10.67,
      -90.123,
      -70.808,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 12:05": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "2OG_871",
    polygon: [
      -82.053,
      -66.076,
      10.67,
      -82.053,
      -66.076,
      13.665,
      -81.415,
      -68.342,
      13.665,
      -81.415,
      -68.342,
      13.665,
      -81.415,
      -68.342,
      10.67,
      -82.053,
      -66.076,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
  },
  {
    ID: "2OG_872",
    polygon: [
      -83.089,
      -62.399,
      10.67,
      -83.089,
      -62.399,
      13.665,
      -82.451,
      -64.665,
      13.665,
      -82.451,
      -64.665,
      13.665,
      -82.451,
      -64.665,
      10.67,
      -83.089,
      -62.399,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:40": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "3OG_1031",
    polygon: [
      -11.004,
      -44.561,
      14.17,
      -11.004,
      -44.561,
      17.165,
      -11.642,
      -42.295,
      17.165,
      -11.642,
      -42.295,
      17.165,
      -11.642,
      -42.295,
      14.17,
      -11.004,
      -44.561,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "3OG_1032",
    polygon: [
      -9.968,
      -48.237,
      14.17,
      -9.968,
      -48.237,
      17.165,
      -10.606,
      -45.972,
      17.165,
      -10.606,
      -45.972,
      17.165,
      -10.606,
      -45.972,
      14.17,
      -9.968,
      -48.237,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "3OG_1033",
    polygon: [
      -8.937,
      -51.9,
      14.17,
      -8.937,
      -51.9,
      17.165,
      -9.575,
      -49.634,
      17.165,
      -9.575,
      -49.634,
      17.165,
      -9.575,
      -49.634,
      14.17,
      -8.937,
      -51.9,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3OG_1034",
    polygon: [
      -7.901,
      -55.577,
      14.17,
      -7.901,
      -55.577,
      17.165,
      -8.539,
      -53.311,
      17.165,
      -8.539,
      -53.311,
      17.165,
      -8.539,
      -53.311,
      14.17,
      -7.901,
      -55.577,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "3OG_1041",
    polygon: [
      -89.091,
      -74.471,
      14.17,
      -89.091,
      -74.471,
      17.165,
      -89.725,
      -72.218,
      17.165,
      -89.725,
      -72.218,
      17.165,
      -89.725,
      -72.218,
      14.17,
      -89.091,
      -74.471,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "3OG_1042",
    polygon: [
      -88.055,
      -78.148,
      14.17,
      -88.055,
      -78.148,
      17.165,
      -88.69,
      -75.895,
      17.165,
      -88.69,
      -75.895,
      17.165,
      -88.69,
      -75.895,
      14.17,
      -88.055,
      -78.148,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3OG_1087",
    polygon: [
      -79.986,
      -73.415,
      14.17,
      -79.986,
      -73.415,
      17.165,
      -79.348,
      -75.681,
      17.165,
      -79.348,
      -75.681,
      17.165,
      -79.348,
      -75.681,
      14.17,
      -79.986,
      -73.415,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "3OG_1088",
    polygon: [
      -81.022,
      -69.738,
      14.17,
      -81.022,
      -69.738,
      17.165,
      -80.384,
      -72.004,
      17.165,
      -80.384,
      -72.004,
      17.165,
      -80.384,
      -72.004,
      14.17,
      -81.022,
      -69.738,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "3OG_1109",
    polygon: [
      -91.158,
      -67.131,
      14.17,
      -91.158,
      -67.131,
      17.165,
      -91.793,
      -64.879,
      17.165,
      -91.793,
      -64.879,
      17.165,
      -91.793,
      -64.879,
      14.17,
      -91.158,
      -67.131,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "3OG_1110",
    polygon: [
      -90.123,
      -70.808,
      14.17,
      -90.123,
      -70.808,
      17.165,
      -90.757,
      -68.556,
      17.165,
      -90.757,
      -68.556,
      17.165,
      -90.757,
      -68.556,
      14.17,
      -90.123,
      -70.808,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "3OG_1197",
    polygon: [
      -82.053,
      -66.076,
      14.17,
      -82.053,
      -66.076,
      17.165,
      -81.415,
      -68.342,
      17.165,
      -81.415,
      -68.342,
      17.165,
      -81.415,
      -68.342,
      14.17,
      -82.053,
      -66.076,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "3OG_1198",
    polygon: [
      -83.089,
      -62.399,
      14.17,
      -83.089,
      -62.399,
      17.165,
      -82.451,
      -64.665,
      17.165,
      -82.451,
      -64.665,
      17.165,
      -82.451,
      -64.665,
      14.17,
      -83.089,
      -62.399,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "3OG_911",
    polygon: [
      0.166,
      -50.843,
      14.17,
      0.166,
      -50.843,
      17.165,
      0.804,
      -53.109,
      17.165,
      0.804,
      -53.109,
      17.165,
      0.804,
      -53.109,
      14.17,
      0.166,
      -50.843,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
  },
  {
    ID: "3OG_912",
    polygon: [
      -0.869,
      -47.166,
      14.17,
      -0.869,
      -47.166,
      17.165,
      -0.231,
      -49.432,
      17.165,
      -0.231,
      -49.432,
      17.165,
      -0.231,
      -49.432,
      14.17,
      -0.869,
      -47.166,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "3OG_913",
    polygon: [
      -1.901,
      -43.504,
      14.17,
      -1.901,
      -43.504,
      17.165,
      -1.263,
      -45.769,
      17.165,
      -1.263,
      -45.769,
      17.165,
      -1.263,
      -45.769,
      14.17,
      -1.901,
      -43.504,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "3OG_914",
    polygon: [
      -2.937,
      -39.827,
      14.17,
      -2.937,
      -39.827,
      17.165,
      -2.299,
      -42.093,
      17.165,
      -2.299,
      -42.093,
      17.165,
      -2.299,
      -42.093,
      14.17,
      -2.937,
      -39.827,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "4OG_1235",
    polygon: [
      0.166,
      -50.843,
      17.67,
      0.166,
      -50.843,
      20.665,
      0.804,
      -53.109,
      20.665,
      0.804,
      -53.109,
      20.665,
      0.804,
      -53.109,
      17.67,
      0.166,
      -50.843,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
  },
  {
    ID: "4OG_1236",
    polygon: [
      -0.869,
      -47.166,
      17.67,
      -0.869,
      -47.166,
      20.665,
      -0.231,
      -49.432,
      20.665,
      -0.231,
      -49.432,
      20.665,
      -0.231,
      -49.432,
      17.67,
      -0.869,
      -47.166,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:35": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "4OG_1237",
    polygon: [
      -1.901,
      -43.504,
      17.67,
      -1.901,
      -43.504,
      20.665,
      -1.263,
      -45.769,
      20.665,
      -1.263,
      -45.769,
      20.665,
      -1.263,
      -45.769,
      17.67,
      -1.901,
      -43.504,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "4OG_1238",
    polygon: [
      -2.937,
      -39.827,
      17.67,
      -2.937,
      -39.827,
      20.665,
      -2.299,
      -42.093,
      20.665,
      -2.299,
      -42.093,
      20.665,
      -2.299,
      -42.093,
      17.67,
      -2.937,
      -39.827,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "4OG_1355",
    polygon: [
      -11.004,
      -44.561,
      17.67,
      -11.004,
      -44.561,
      20.665,
      -11.642,
      -42.295,
      20.665,
      -11.642,
      -42.295,
      20.665,
      -11.642,
      -42.295,
      17.67,
      -11.004,
      -44.561,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "4OG_1356",
    polygon: [
      -9.968,
      -48.237,
      17.67,
      -9.968,
      -48.237,
      20.665,
      -10.606,
      -45.972,
      20.665,
      -10.606,
      -45.972,
      20.665,
      -10.606,
      -45.972,
      17.67,
      -9.968,
      -48.237,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "4OG_1357",
    polygon: [
      -8.937,
      -51.9,
      17.67,
      -8.937,
      -51.9,
      20.665,
      -9.575,
      -49.634,
      20.665,
      -9.575,
      -49.634,
      20.665,
      -9.575,
      -49.634,
      17.67,
      -8.937,
      -51.9,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "4OG_1358",
    polygon: [
      -7.901,
      -55.577,
      17.67,
      -7.901,
      -55.577,
      20.665,
      -8.539,
      -53.311,
      20.665,
      -8.539,
      -53.311,
      20.665,
      -8.539,
      -53.311,
      17.67,
      -7.901,
      -55.577,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4OG_1365",
    polygon: [
      -89.091,
      -74.471,
      17.67,
      -89.091,
      -74.471,
      20.665,
      -89.725,
      -72.218,
      20.665,
      -89.725,
      -72.218,
      20.665,
      -89.725,
      -72.218,
      17.67,
      -89.091,
      -74.471,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "4OG_1366",
    polygon: [
      -88.055,
      -78.148,
      17.67,
      -88.055,
      -78.148,
      20.665,
      -88.69,
      -75.895,
      20.665,
      -88.69,
      -75.895,
      20.665,
      -88.69,
      -75.895,
      17.67,
      -88.055,
      -78.148,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4OG_1411",
    polygon: [
      -79.986,
      -73.415,
      17.67,
      -79.986,
      -73.415,
      20.665,
      -79.348,
      -75.681,
      20.665,
      -79.348,
      -75.681,
      20.665,
      -79.348,
      -75.681,
      17.67,
      -79.986,
      -73.415,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "4OG_1412",
    polygon: [
      -81.022,
      -69.738,
      17.67,
      -81.022,
      -69.738,
      20.665,
      -80.384,
      -72.004,
      20.665,
      -80.384,
      -72.004,
      20.665,
      -80.384,
      -72.004,
      17.67,
      -81.022,
      -69.738,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 08:10": 1,
    "04-15 08:25": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
  },
  {
    ID: "4OG_1433",
    polygon: [
      -91.158,
      -67.131,
      17.67,
      -91.158,
      -67.131,
      20.665,
      -91.793,
      -64.879,
      20.665,
      -91.793,
      -64.879,
      20.665,
      -91.793,
      -64.879,
      17.67,
      -91.158,
      -67.131,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "4OG_1434",
    polygon: [
      -90.123,
      -70.808,
      17.67,
      -90.123,
      -70.808,
      20.665,
      -90.757,
      -68.556,
      20.665,
      -90.757,
      -68.556,
      20.665,
      -90.757,
      -68.556,
      17.67,
      -90.123,
      -70.808,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "4OG_1521",
    polygon: [
      -82.053,
      -66.076,
      17.67,
      -82.053,
      -66.076,
      20.665,
      -81.415,
      -68.342,
      20.665,
      -81.415,
      -68.342,
      20.665,
      -81.415,
      -68.342,
      17.67,
      -82.053,
      -66.076,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "4OG_1522",
    polygon: [
      -83.089,
      -62.399,
      17.67,
      -83.089,
      -62.399,
      20.665,
      -82.451,
      -64.665,
      20.665,
      -82.451,
      -64.665,
      20.665,
      -82.451,
      -64.665,
      17.67,
      -83.089,
      -62.399,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "5OG_1559",
    polygon: [
      0.166,
      -50.843,
      21.17,
      0.166,
      -50.843,
      24.165,
      0.804,
      -53.109,
      24.165,
      0.804,
      -53.109,
      24.165,
      0.804,
      -53.109,
      21.17,
      0.166,
      -50.843,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
  },
  {
    ID: "5OG_1560",
    polygon: [
      -0.869,
      -47.166,
      21.17,
      -0.869,
      -47.166,
      24.165,
      -0.231,
      -49.432,
      24.165,
      -0.231,
      -49.432,
      24.165,
      -0.231,
      -49.432,
      21.17,
      -0.869,
      -47.166,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "5OG_1561",
    polygon: [
      -1.901,
      -43.504,
      21.17,
      -1.901,
      -43.504,
      24.165,
      -1.263,
      -45.769,
      24.165,
      -1.263,
      -45.769,
      24.165,
      -1.263,
      -45.769,
      21.17,
      -1.901,
      -43.504,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "5OG_1562",
    polygon: [
      -2.937,
      -39.827,
      21.17,
      -2.937,
      -39.827,
      24.165,
      -2.299,
      -42.093,
      24.165,
      -2.299,
      -42.093,
      24.165,
      -2.299,
      -42.093,
      21.17,
      -2.937,
      -39.827,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "5OG_1679",
    polygon: [
      -11.004,
      -44.561,
      21.17,
      -11.004,
      -44.561,
      24.165,
      -11.642,
      -42.295,
      24.165,
      -11.642,
      -42.295,
      24.165,
      -11.642,
      -42.295,
      21.17,
      -11.004,
      -44.561,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "5OG_1680",
    polygon: [
      -9.968,
      -48.237,
      21.17,
      -9.968,
      -48.237,
      24.165,
      -10.606,
      -45.972,
      24.165,
      -10.606,
      -45.972,
      24.165,
      -10.606,
      -45.972,
      21.17,
      -9.968,
      -48.237,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "5OG_1681",
    polygon: [
      -8.937,
      -51.9,
      21.17,
      -8.937,
      -51.9,
      24.165,
      -9.575,
      -49.634,
      24.165,
      -9.575,
      -49.634,
      24.165,
      -9.575,
      -49.634,
      21.17,
      -8.937,
      -51.9,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "5OG_1682",
    polygon: [
      -7.901,
      -55.577,
      21.17,
      -7.901,
      -55.577,
      24.165,
      -8.539,
      -53.311,
      24.165,
      -8.539,
      -53.311,
      24.165,
      -8.539,
      -53.311,
      21.17,
      -7.901,
      -55.577,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "5OG_1689",
    polygon: [
      -89.091,
      -74.471,
      21.17,
      -89.091,
      -74.471,
      24.165,
      -89.725,
      -72.218,
      24.165,
      -89.725,
      -72.218,
      24.165,
      -89.725,
      -72.218,
      21.17,
      -89.091,
      -74.471,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "5OG_1690",
    polygon: [
      -88.055,
      -78.148,
      21.17,
      -88.055,
      -78.148,
      24.165,
      -88.69,
      -75.895,
      24.165,
      -88.69,
      -75.895,
      24.165,
      -88.69,
      -75.895,
      21.17,
      -88.055,
      -78.148,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "5OG_1735",
    polygon: [
      -79.986,
      -73.415,
      21.17,
      -79.986,
      -73.415,
      24.165,
      -79.348,
      -75.681,
      24.165,
      -79.348,
      -75.681,
      24.165,
      -79.348,
      -75.681,
      21.17,
      -79.986,
      -73.415,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "5OG_1736",
    polygon: [
      -81.022,
      -69.738,
      21.17,
      -81.022,
      -69.738,
      24.165,
      -80.384,
      -72.004,
      24.165,
      -80.384,
      -72.004,
      24.165,
      -80.384,
      -72.004,
      21.17,
      -81.022,
      -69.738,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "5OG_1757",
    polygon: [
      -91.158,
      -67.131,
      21.17,
      -91.158,
      -67.131,
      24.165,
      -91.793,
      -64.879,
      24.165,
      -91.793,
      -64.879,
      24.165,
      -91.793,
      -64.879,
      21.17,
      -91.158,
      -67.131,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "5OG_1758",
    polygon: [
      -90.123,
      -70.808,
      21.17,
      -90.123,
      -70.808,
      24.165,
      -90.757,
      -68.556,
      24.165,
      -90.757,
      -68.556,
      24.165,
      -90.757,
      -68.556,
      21.17,
      -90.123,
      -70.808,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "5OG_1845",
    polygon: [
      -82.053,
      -66.076,
      21.17,
      -82.053,
      -66.076,
      24.165,
      -81.415,
      -68.342,
      24.165,
      -81.415,
      -68.342,
      24.165,
      -81.415,
      -68.342,
      21.17,
      -82.053,
      -66.076,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "5OG_1846",
    polygon: [
      -83.089,
      -62.399,
      21.17,
      -83.089,
      -62.399,
      24.165,
      -82.451,
      -64.665,
      24.165,
      -82.451,
      -64.665,
      24.165,
      -82.451,
      -64.665,
      21.17,
      -83.089,
      -62.399,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "EG_1885",
    polygon: [
      -50.522,
      -84.888,
      2.07,
      -50.522,
      -84.888,
      6.54,
      -51.677,
      -85.214,
      6.54,
      -51.677,
      -85.214,
      6.54,
      -51.677,
      -85.214,
      2.07,
      -50.522,
      -84.888,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
  },
  {
    ID: "EG_1886",
    polygon: [
      -51.773,
      -85.241,
      4.534,
      -51.773,
      -85.241,
      6.519,
      -53.024,
      -85.593,
      6.519,
      -53.024,
      -85.593,
      6.519,
      -53.024,
      -85.593,
      4.534,
      -51.773,
      -85.241,
      4.534,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_1887",
    polygon: [
      -51.774,
      -85.241,
      2.07,
      -51.774,
      -85.241,
      4.465,
      -53.025,
      -85.593,
      4.465,
      -53.025,
      -85.593,
      4.465,
      -53.025,
      -85.593,
      2.07,
      -51.774,
      -85.241,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
  },
  {
    ID: "EG_1888",
    polygon: [
      -53.12,
      -85.62,
      4.534,
      -53.12,
      -85.62,
      6.519,
      -54.372,
      -85.973,
      6.519,
      -54.372,
      -85.973,
      6.519,
      -54.372,
      -85.973,
      4.534,
      -53.12,
      -85.62,
      4.534,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_1889",
    polygon: [
      -53.121,
      -85.62,
      2.07,
      -53.121,
      -85.62,
      4.465,
      -54.372,
      -85.973,
      4.465,
      -54.372,
      -85.973,
      4.465,
      -54.372,
      -85.973,
      2.07,
      -53.121,
      -85.62,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
  },
  {
    ID: "EG_1890",
    polygon: [
      -54.468,
      -86.0,
      4.534,
      -54.468,
      -86.0,
      6.519,
      -55.719,
      -86.352,
      6.519,
      -55.719,
      -86.352,
      6.519,
      -55.719,
      -86.352,
      4.534,
      -54.468,
      -86.0,
      4.534,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_1891",
    polygon: [
      -54.469,
      -86.0,
      2.07,
      -54.469,
      -86.0,
      4.465,
      -55.72,
      -86.352,
      4.465,
      -55.72,
      -86.352,
      4.465,
      -55.72,
      -86.352,
      2.07,
      -54.469,
      -86.0,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
  },
  {
    ID: "EG_1892",
    polygon: [
      -55.815,
      -86.379,
      4.534,
      -55.815,
      -86.379,
      6.519,
      -57.067,
      -86.732,
      6.519,
      -57.067,
      -86.732,
      6.519,
      -57.067,
      -86.732,
      4.534,
      -55.815,
      -86.379,
      4.534,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_1893",
    polygon: [
      -55.816,
      -86.38,
      2.07,
      -55.816,
      -86.38,
      4.465,
      -57.068,
      -86.732,
      4.465,
      -57.068,
      -86.732,
      4.465,
      -57.068,
      -86.732,
      2.07,
      -55.816,
      -86.38,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
  },
  {
    ID: "EG_1894",
    polygon: [
      -57.163,
      -86.759,
      4.534,
      -57.163,
      -86.759,
      6.519,
      -58.414,
      -87.111,
      6.519,
      -58.414,
      -87.111,
      6.519,
      -58.414,
      -87.111,
      4.534,
      -57.163,
      -86.759,
      4.534,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_1895",
    polygon: [
      -57.164,
      -86.759,
      2.07,
      -57.164,
      -86.759,
      4.465,
      -58.415,
      -87.112,
      4.465,
      -58.415,
      -87.112,
      4.465,
      -58.415,
      -87.112,
      2.07,
      -57.164,
      -86.759,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
  },
  {
    ID: "EG_1896",
    polygon: [
      -58.51,
      -87.138,
      4.534,
      -58.51,
      -87.138,
      6.519,
      -59.762,
      -87.491,
      6.519,
      -59.762,
      -87.491,
      6.519,
      -59.762,
      -87.491,
      4.534,
      -58.51,
      -87.138,
      4.534,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_1897",
    polygon: [
      -58.511,
      -87.139,
      2.07,
      -58.511,
      -87.139,
      4.465,
      -59.763,
      -87.491,
      4.465,
      -59.763,
      -87.491,
      4.465,
      -59.763,
      -87.491,
      2.07,
      -58.511,
      -87.139,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
  },
  {
    ID: "EG_1898",
    polygon: [
      -59.858,
      -87.518,
      4.534,
      -59.858,
      -87.518,
      6.519,
      -61.109,
      -87.87,
      6.519,
      -61.109,
      -87.87,
      6.519,
      -61.109,
      -87.87,
      4.534,
      -59.858,
      -87.518,
      4.534,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_1899",
    polygon: [
      -59.859,
      -87.518,
      2.07,
      -59.859,
      -87.518,
      4.465,
      -61.11,
      -87.871,
      4.465,
      -61.11,
      -87.871,
      4.465,
      -61.11,
      -87.871,
      2.07,
      -59.859,
      -87.518,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
  },
  {
    ID: "EG_1900",
    polygon: [
      -61.206,
      -87.898,
      4.534,
      -61.206,
      -87.898,
      6.519,
      -62.457,
      -88.25,
      6.519,
      -62.457,
      -88.25,
      6.519,
      -62.457,
      -88.25,
      4.534,
      -61.206,
      -87.898,
      4.534,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_1901",
    polygon: [
      -61.206,
      -87.898,
      2.07,
      -61.206,
      -87.898,
      4.465,
      -62.458,
      -88.25,
      4.465,
      -62.458,
      -88.25,
      4.465,
      -62.458,
      -88.25,
      2.07,
      -61.206,
      -87.898,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
  },
  {
    ID: "EG_1902",
    polygon: [
      -62.553,
      -88.277,
      4.534,
      -62.553,
      -88.277,
      6.519,
      -63.804,
      -88.63,
      6.519,
      -63.804,
      -88.63,
      6.519,
      -63.804,
      -88.63,
      4.534,
      -62.553,
      -88.277,
      4.534,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_1903",
    polygon: [
      -62.554,
      -88.277,
      2.07,
      -62.554,
      -88.277,
      4.465,
      -63.805,
      -88.63,
      4.465,
      -63.805,
      -88.63,
      4.465,
      -63.805,
      -88.63,
      2.07,
      -62.554,
      -88.277,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
  },
  {
    ID: "EG_1904",
    polygon: [
      -63.901,
      -88.657,
      4.534,
      -63.901,
      -88.657,
      6.519,
      -65.152,
      -89.009,
      6.519,
      -65.152,
      -89.009,
      6.519,
      -65.152,
      -89.009,
      4.534,
      -63.901,
      -88.657,
      4.534,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_1905",
    polygon: [
      -63.902,
      -88.657,
      2.07,
      -63.902,
      -88.657,
      4.465,
      -65.153,
      -89.01,
      4.465,
      -65.153,
      -89.01,
      4.465,
      -65.153,
      -89.01,
      2.07,
      -63.902,
      -88.657,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
  },
  {
    ID: "EG_1910",
    polygon: [
      -65.249,
      -89.037,
      2.07,
      -65.249,
      -89.037,
      6.54,
      -66.597,
      -89.416,
      6.54,
      -66.597,
      -89.416,
      6.54,
      -66.597,
      -89.416,
      2.07,
      -65.249,
      -89.037,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1911",
    polygon: [
      -66.693,
      -89.443,
      2.07,
      -66.693,
      -89.443,
      6.54,
      -68.041,
      -89.823,
      6.54,
      -68.041,
      -89.823,
      6.54,
      -68.041,
      -89.823,
      2.07,
      -66.693,
      -89.443,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1912",
    polygon: [
      -68.137,
      -89.85,
      4.509,
      -68.137,
      -89.85,
      6.539,
      -69.609,
      -90.265,
      6.539,
      -69.609,
      -90.265,
      6.539,
      -69.609,
      -90.265,
      4.509,
      -68.137,
      -89.85,
      4.509,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
  },
  {
    ID: "EG_1913",
    polygon: [
      -68.137,
      -89.85,
      2.06,
      -68.137,
      -89.85,
      4.45,
      -69.609,
      -90.265,
      4.45,
      -69.609,
      -90.265,
      4.45,
      -69.609,
      -90.265,
      2.06,
      -68.137,
      -89.85,
      2.06,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
  },
  {
    ID: "EG_1914",
    polygon: [
      -69.706,
      -90.292,
      4.509,
      -69.706,
      -90.292,
      6.539,
      -71.178,
      -90.707,
      6.539,
      -71.178,
      -90.707,
      6.539,
      -71.178,
      -90.707,
      4.509,
      -69.706,
      -90.292,
      4.509,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
  },
  {
    ID: "EG_1915",
    polygon: [
      -69.706,
      -90.292,
      2.06,
      -69.706,
      -90.292,
      4.45,
      -71.178,
      -90.707,
      4.45,
      -71.178,
      -90.707,
      4.45,
      -71.178,
      -90.707,
      2.06,
      -69.706,
      -90.292,
      2.06,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
  },
  {
    ID: "EG_1916",
    polygon: [
      -71.275,
      -90.734,
      2.07,
      -71.275,
      -90.734,
      6.54,
      -72.622,
      -91.113,
      6.54,
      -72.622,
      -91.113,
      6.54,
      -72.622,
      -91.113,
      2.07,
      -71.275,
      -90.734,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1917",
    polygon: [
      -72.718,
      -91.141,
      2.07,
      -72.718,
      -91.141,
      6.54,
      -74.066,
      -91.52,
      6.54,
      -74.066,
      -91.52,
      6.54,
      -74.066,
      -91.52,
      2.07,
      -72.718,
      -91.141,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1918",
    polygon: [
      -74.16,
      -91.547,
      2.07,
      -74.16,
      -91.547,
      6.54,
      -75.507,
      -91.926,
      6.54,
      -75.507,
      -91.926,
      6.54,
      -75.507,
      -91.926,
      2.07,
      -74.16,
      -91.547,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1919",
    polygon: [
      -75.604,
      -91.953,
      2.07,
      -75.604,
      -91.953,
      6.54,
      -76.951,
      -92.333,
      6.54,
      -76.951,
      -92.333,
      6.54,
      -76.951,
      -92.333,
      2.07,
      -75.604,
      -91.953,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1920",
    polygon: [
      -77.05,
      -92.361,
      2.07,
      -77.05,
      -92.361,
      6.54,
      -78.397,
      -92.74,
      6.54,
      -78.397,
      -92.74,
      6.54,
      -78.397,
      -92.74,
      2.07,
      -77.05,
      -92.361,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1921",
    polygon: [
      -78.494,
      -92.767,
      2.07,
      -78.494,
      -92.767,
      6.54,
      -79.841,
      -93.147,
      6.54,
      -79.841,
      -93.147,
      6.54,
      -79.841,
      -93.147,
      2.07,
      -78.494,
      -92.767,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1922",
    polygon: [
      -79.935,
      -93.173,
      2.07,
      -79.935,
      -93.173,
      6.54,
      -81.283,
      -93.553,
      6.54,
      -81.283,
      -93.553,
      6.54,
      -81.283,
      -93.553,
      2.07,
      -79.935,
      -93.173,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1923",
    polygon: [
      -81.379,
      -93.58,
      2.07,
      -81.379,
      -93.58,
      6.54,
      -82.727,
      -93.96,
      6.54,
      -82.727,
      -93.96,
      6.54,
      -82.727,
      -93.96,
      2.07,
      -81.379,
      -93.58,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1924",
    polygon: [
      -82.825,
      -93.987,
      2.07,
      -82.825,
      -93.987,
      6.54,
      -84.173,
      -94.367,
      6.54,
      -84.173,
      -94.367,
      6.54,
      -84.173,
      -94.367,
      2.07,
      -82.825,
      -93.987,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1925",
    polygon: [
      -84.269,
      -94.394,
      2.07,
      -84.269,
      -94.394,
      6.54,
      -85.617,
      -94.774,
      6.54,
      -85.617,
      -94.774,
      6.54,
      -85.617,
      -94.774,
      2.07,
      -84.269,
      -94.394,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1926",
    polygon: [
      -85.71,
      -94.8,
      2.07,
      -85.71,
      -94.8,
      6.54,
      -87.058,
      -95.18,
      6.54,
      -87.058,
      -95.18,
      6.54,
      -87.058,
      -95.18,
      2.07,
      -85.71,
      -94.8,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1927",
    polygon: [
      -87.154,
      -95.207,
      2.07,
      -87.154,
      -95.207,
      6.54,
      -88.502,
      -95.586,
      6.54,
      -88.502,
      -95.586,
      6.54,
      -88.502,
      -95.586,
      2.07,
      -87.154,
      -95.207,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1928",
    polygon: [
      -31.288,
      -66.753,
      2.045,
      -31.288,
      -66.753,
      6.565,
      -33.731,
      -67.441,
      6.565,
      -33.731,
      -67.441,
      6.565,
      -33.731,
      -67.441,
      2.045,
      -31.288,
      -66.753,
      2.045,
    ],
    "04-15 02:05": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
  },
  {
    ID: "EG_1929",
    polygon: [
      -33.779,
      -67.455,
      2.045,
      -33.779,
      -67.455,
      6.565,
      -36.223,
      -68.143,
      6.565,
      -36.223,
      -68.143,
      6.565,
      -36.223,
      -68.143,
      2.045,
      -33.779,
      -67.455,
      2.045,
    ],
    "04-15 02:05": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
  },
  {
    ID: "EG_1930",
    polygon: [
      -36.272,
      -68.157,
      2.045,
      -36.272,
      -68.157,
      6.565,
      -38.716,
      -68.845,
      6.565,
      -38.716,
      -68.845,
      6.565,
      -38.716,
      -68.845,
      2.045,
      -36.272,
      -68.157,
      2.045,
    ],
    "04-15 02:05": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
  },
  {
    ID: "EG_1931",
    polygon: [
      -46.128,
      -70.933,
      2.045,
      -46.128,
      -70.933,
      6.565,
      -48.572,
      -71.621,
      6.565,
      -48.572,
      -71.621,
      6.565,
      -48.572,
      -71.621,
      2.045,
      -46.128,
      -70.933,
      2.045,
    ],
    "04-15 02:05": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
  },
  {
    ID: "EG_1932",
    polygon: [
      -48.62,
      -71.635,
      2.045,
      -48.62,
      -71.635,
      6.565,
      -51.063,
      -72.323,
      6.565,
      -51.063,
      -72.323,
      6.565,
      -51.063,
      -72.323,
      2.045,
      -48.62,
      -71.635,
      2.045,
    ],
    "04-15 02:05": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "EG_1933",
    polygon: [
      -51.113,
      -72.337,
      2.045,
      -51.113,
      -72.337,
      6.565,
      -53.556,
      -73.025,
      6.565,
      -53.556,
      -73.025,
      6.565,
      -53.556,
      -73.025,
      2.045,
      -51.113,
      -72.337,
      2.045,
    ],
    "04-15 02:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "EG_1934",
    polygon: [
      -26.543,
      -78.134,
      2.07,
      -26.543,
      -78.134,
      6.54,
      -27.698,
      -78.459,
      6.54,
      -27.698,
      -78.459,
      6.54,
      -27.698,
      -78.459,
      2.07,
      -26.543,
      -78.134,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
  },
  {
    ID: "EG_1935",
    polygon: [
      -23.655,
      -77.32,
      2.07,
      -23.655,
      -77.32,
      6.54,
      -25.003,
      -77.7,
      6.54,
      -25.003,
      -77.7,
      6.54,
      -25.003,
      -77.7,
      2.07,
      -23.655,
      -77.32,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1936",
    polygon: [
      -25.099,
      -77.727,
      2.07,
      -25.099,
      -77.727,
      6.54,
      -26.447,
      -78.107,
      6.54,
      -26.447,
      -78.107,
      6.54,
      -26.447,
      -78.107,
      2.07,
      -25.099,
      -77.727,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1937",
    polygon: [
      -20.768,
      -76.507,
      2.07,
      -20.768,
      -76.507,
      6.54,
      -22.115,
      -76.887,
      6.54,
      -22.115,
      -76.887,
      6.54,
      -22.115,
      -76.887,
      2.07,
      -20.768,
      -76.507,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1938",
    polygon: [
      -22.212,
      -76.914,
      2.07,
      -22.212,
      -76.914,
      6.54,
      -23.559,
      -77.293,
      6.54,
      -23.559,
      -77.293,
      6.54,
      -23.559,
      -77.293,
      2.07,
      -22.212,
      -76.914,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1939",
    polygon: [
      -19.324,
      -76.1,
      2.07,
      -19.324,
      -76.1,
      6.54,
      -20.671,
      -76.48,
      6.54,
      -20.671,
      -76.48,
      6.54,
      -20.671,
      -76.48,
      2.07,
      -19.324,
      -76.1,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1940",
    polygon: [
      -16.436,
      -75.287,
      2.07,
      -16.436,
      -75.287,
      6.54,
      -17.784,
      -75.666,
      6.54,
      -17.784,
      -75.666,
      6.54,
      -17.784,
      -75.666,
      2.07,
      -16.436,
      -75.287,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1941",
    polygon: [
      -12.105,
      -74.067,
      2.07,
      -12.105,
      -74.067,
      6.54,
      -13.452,
      -74.446,
      6.54,
      -13.452,
      -74.446,
      6.54,
      -13.452,
      -74.446,
      2.07,
      -12.105,
      -74.067,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1942",
    polygon: [
      -7.773,
      -72.847,
      2.07,
      -7.773,
      -72.847,
      6.54,
      -9.121,
      -73.226,
      6.54,
      -9.121,
      -73.226,
      6.54,
      -9.121,
      -73.226,
      2.07,
      -7.773,
      -72.847,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1943",
    polygon: [
      -6.33,
      -72.44,
      2.07,
      -6.33,
      -72.44,
      6.54,
      -7.677,
      -72.82,
      6.54,
      -7.677,
      -72.82,
      6.54,
      -7.677,
      -72.82,
      2.07,
      -6.33,
      -72.44,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1944",
    polygon: [
      -0.507,
      -70.8,
      2.07,
      -0.507,
      -70.8,
      6.54,
      -1.854,
      -71.179,
      6.54,
      -1.854,
      -71.179,
      6.54,
      -1.854,
      -71.179,
      2.07,
      -0.507,
      -70.8,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1945",
    polygon: [
      3.825,
      -69.58,
      2.07,
      3.825,
      -69.58,
      6.54,
      2.477,
      -69.959,
      6.54,
      2.477,
      -69.959,
      6.54,
      2.477,
      -69.959,
      2.07,
      3.825,
      -69.58,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1946",
    polygon: [
      8.156,
      -68.36,
      2.07,
      8.156,
      -68.36,
      6.54,
      6.809,
      -68.739,
      6.54,
      6.809,
      -68.739,
      6.54,
      6.809,
      -68.739,
      2.07,
      8.156,
      -68.36,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 10:10": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1947",
    polygon: [
      12.488,
      -67.139,
      2.07,
      12.488,
      -67.139,
      6.54,
      11.14,
      -67.519,
      6.54,
      11.14,
      -67.519,
      6.54,
      11.14,
      -67.519,
      2.07,
      12.488,
      -67.139,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 09:35": 1,
    "04-15 09:45": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_1948",
    polygon: [
      -43.677,
      -70.243,
      4.493,
      -43.677,
      -70.243,
      6.565,
      -46.083,
      -70.92,
      6.565,
      -46.083,
      -70.92,
      6.565,
      -46.083,
      -70.92,
      4.493,
      -43.677,
      -70.243,
      4.493,
    ],
    "04-15 02:05": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "EG_1949",
    polygon: [
      -44.877,
      -70.581,
      2.035,
      -44.877,
      -70.581,
      4.447,
      -46.061,
      -70.914,
      4.447,
      -46.061,
      -70.914,
      4.447,
      -46.061,
      -70.914,
      2.035,
      -44.877,
      -70.581,
      2.035,
    ],
    "04-15 02:05": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
  },
  {
    ID: "EG_1950",
    polygon: [
      -43.693,
      -70.247,
      2.035,
      -43.693,
      -70.247,
      4.447,
      -44.877,
      -70.581,
      4.447,
      -44.877,
      -70.581,
      4.447,
      -44.877,
      -70.581,
      2.035,
      -43.693,
      -70.247,
      2.035,
    ],
    "04-15 02:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
  },
  {
    ID: "EG_1951",
    polygon: [
      -41.222,
      -69.551,
      4.493,
      -41.222,
      -69.551,
      6.565,
      -43.628,
      -70.229,
      6.565,
      -43.628,
      -70.229,
      6.565,
      -43.628,
      -70.229,
      4.493,
      -41.222,
      -69.551,
      4.493,
    ],
    "04-15 02:05": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
  },
  {
    ID: "EG_1952",
    polygon: [
      -42.422,
      -69.889,
      2.035,
      -42.422,
      -69.889,
      4.447,
      -43.606,
      -70.223,
      4.447,
      -43.606,
      -70.223,
      4.447,
      -43.606,
      -70.223,
      2.035,
      -42.422,
      -69.889,
      2.035,
    ],
    "04-15 02:05": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "EG_1953",
    polygon: [
      -41.238,
      -69.556,
      2.035,
      -41.238,
      -69.556,
      4.447,
      -42.422,
      -69.889,
      4.447,
      -42.422,
      -69.889,
      4.447,
      -42.422,
      -69.889,
      2.035,
      -41.238,
      -69.556,
      2.035,
    ],
    "04-15 02:05": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
  },
  {
    ID: "EG_1954",
    polygon: [
      -38.768,
      -68.86,
      4.493,
      -38.768,
      -68.86,
      6.565,
      -41.174,
      -69.538,
      6.565,
      -41.174,
      -69.538,
      6.565,
      -41.174,
      -69.538,
      4.493,
      -38.768,
      -68.86,
      4.493,
    ],
    "04-15 02:05": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "EG_1955",
    polygon: [
      -39.968,
      -69.198,
      2.035,
      -39.968,
      -69.198,
      4.447,
      -41.152,
      -69.531,
      4.447,
      -41.152,
      -69.531,
      4.447,
      -41.152,
      -69.531,
      2.035,
      -39.968,
      -69.198,
      2.035,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
  },
  {
    ID: "EG_1956",
    polygon: [
      -38.784,
      -68.864,
      2.035,
      -38.784,
      -68.864,
      4.447,
      -39.968,
      -69.198,
      4.447,
      -39.968,
      -69.198,
      4.447,
      -39.968,
      -69.198,
      2.035,
      -38.784,
      -68.864,
      2.035,
    ],
    "04-15 02:05": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
  },
  {
    ID: "EG_1957",
    polygon: [
      -14.322,
      11.871,
      2.05,
      -14.322,
      11.871,
      4.44,
      -12.85,
      12.286,
      4.44,
      -12.85,
      12.286,
      4.44,
      -12.85,
      12.286,
      2.05,
      -14.322,
      11.871,
      2.05,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "EG_1958",
    polygon: [
      -21.446,
      9.864,
      2.07,
      -21.446,
      9.864,
      6.54,
      -20.195,
      10.217,
      6.54,
      -20.195,
      10.217,
      6.54,
      -20.195,
      10.217,
      2.07,
      -21.446,
      9.864,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1959",
    polygon: [
      -22.794,
      9.485,
      2.07,
      -22.794,
      9.485,
      6.54,
      -21.542,
      9.837,
      6.54,
      -21.542,
      9.837,
      6.54,
      -21.542,
      9.837,
      2.07,
      -22.794,
      9.485,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1960",
    polygon: [
      -24.141,
      9.105,
      2.07,
      -24.141,
      9.105,
      6.54,
      -22.89,
      9.458,
      6.54,
      -22.89,
      9.458,
      6.54,
      -22.89,
      9.458,
      2.07,
      -24.141,
      9.105,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "EG_1961",
    polygon: [
      -25.488,
      8.726,
      2.07,
      -25.488,
      8.726,
      6.54,
      -24.237,
      9.078,
      6.54,
      -24.237,
      9.078,
      6.54,
      -24.237,
      9.078,
      2.07,
      -25.488,
      8.726,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "EG_1962",
    polygon: [
      -26.836,
      8.346,
      2.07,
      -26.836,
      8.346,
      6.54,
      -25.585,
      8.699,
      6.54,
      -25.585,
      8.699,
      6.54,
      -25.585,
      8.699,
      2.07,
      -26.836,
      8.346,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "EG_1963",
    polygon: [
      -28.184,
      7.967,
      2.07,
      -28.184,
      7.967,
      6.54,
      -26.932,
      8.319,
      6.54,
      -26.932,
      8.319,
      6.54,
      -26.932,
      8.319,
      2.07,
      -28.184,
      7.967,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "EG_1964",
    polygon: [
      -29.531,
      7.587,
      2.07,
      -29.531,
      7.587,
      6.54,
      -28.28,
      7.939,
      6.54,
      -28.28,
      7.939,
      6.54,
      -28.28,
      7.939,
      2.07,
      -29.531,
      7.587,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "EG_1965",
    polygon: [
      -30.878,
      7.207,
      2.07,
      -30.878,
      7.207,
      6.54,
      -29.627,
      7.56,
      6.54,
      -29.627,
      7.56,
      6.54,
      -29.627,
      7.56,
      2.07,
      -30.878,
      7.207,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "EG_1966",
    polygon: [
      -32.45,
      6.765,
      2.07,
      -32.45,
      6.765,
      4.46,
      -30.977,
      7.18,
      4.46,
      -30.977,
      7.18,
      4.46,
      -30.977,
      7.18,
      2.07,
      -32.45,
      6.765,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "EG_1967",
    polygon: [
      -32.45,
      6.765,
      4.51,
      -32.45,
      6.765,
      6.54,
      -30.977,
      7.18,
      6.54,
      -30.977,
      7.18,
      6.54,
      -30.977,
      7.18,
      4.51,
      -32.45,
      6.765,
      4.51,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "EG_1968",
    polygon: [
      -33.795,
      6.386,
      2.07,
      -33.795,
      6.386,
      6.54,
      -32.544,
      6.738,
      6.54,
      -32.544,
      6.738,
      6.54,
      -32.544,
      6.738,
      2.07,
      -33.795,
      6.386,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "EG_1969",
    polygon: [
      -35.143,
      6.006,
      2.07,
      -35.143,
      6.006,
      6.54,
      -33.891,
      6.359,
      6.54,
      -33.891,
      6.359,
      6.54,
      -33.891,
      6.359,
      2.07,
      -35.143,
      6.006,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "EG_1970",
    polygon: [
      -36.49,
      5.627,
      2.07,
      -36.49,
      5.627,
      6.54,
      -35.239,
      5.979,
      6.54,
      -35.239,
      5.979,
      6.54,
      -35.239,
      5.979,
      2.07,
      -36.49,
      5.627,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "EG_1971",
    polygon: [
      -37.838,
      5.247,
      2.07,
      -37.838,
      5.247,
      6.54,
      -36.586,
      5.6,
      6.54,
      -36.586,
      5.6,
      6.54,
      -36.586,
      5.6,
      2.07,
      -37.838,
      5.247,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "EG_1972",
    polygon: [
      -39.185,
      4.868,
      2.07,
      -39.185,
      4.868,
      6.54,
      -37.934,
      5.22,
      6.54,
      -37.934,
      5.22,
      6.54,
      -37.934,
      5.22,
      2.07,
      -39.185,
      4.868,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1973",
    polygon: [
      -40.533,
      4.488,
      2.07,
      -40.533,
      4.488,
      6.54,
      -39.281,
      4.84,
      6.54,
      -39.281,
      4.84,
      6.54,
      -39.281,
      4.84,
      2.07,
      -40.533,
      4.488,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1974",
    polygon: [
      -41.88,
      4.108,
      2.07,
      -41.88,
      4.108,
      6.54,
      -40.629,
      4.461,
      6.54,
      -40.629,
      4.461,
      6.54,
      -40.629,
      4.461,
      2.07,
      -41.88,
      4.108,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1975",
    polygon: [
      -43.228,
      3.729,
      2.07,
      -43.228,
      3.729,
      6.54,
      -41.976,
      4.081,
      6.54,
      -41.976,
      4.081,
      6.54,
      -41.976,
      4.081,
      2.07,
      -43.228,
      3.729,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1976",
    polygon: [
      -44.576,
      3.349,
      2.07,
      -44.576,
      3.349,
      6.54,
      -43.325,
      3.702,
      6.54,
      -43.325,
      3.702,
      6.54,
      -43.325,
      3.702,
      2.07,
      -44.576,
      3.349,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1977",
    polygon: [
      -46.147,
      2.907,
      2.07,
      -46.147,
      2.907,
      4.46,
      -44.674,
      3.321,
      4.46,
      -44.674,
      3.321,
      4.46,
      -44.674,
      3.321,
      2.07,
      -46.147,
      2.907,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1978",
    polygon: [
      -46.147,
      2.907,
      4.51,
      -46.147,
      2.907,
      6.54,
      -44.674,
      3.321,
      6.54,
      -44.674,
      3.321,
      6.54,
      -44.674,
      3.321,
      4.51,
      -46.147,
      2.907,
      4.51,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1979",
    polygon: [
      -47.492,
      2.528,
      2.07,
      -47.492,
      2.528,
      6.54,
      -46.241,
      2.88,
      6.54,
      -46.241,
      2.88,
      6.54,
      -46.241,
      2.88,
      2.07,
      -47.492,
      2.528,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1980",
    polygon: [
      -48.84,
      2.148,
      2.07,
      -48.84,
      2.148,
      6.54,
      -47.588,
      2.501,
      6.54,
      -47.588,
      2.501,
      6.54,
      -47.588,
      2.501,
      2.07,
      -48.84,
      2.148,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1981",
    polygon: [
      -50.187,
      1.769,
      2.07,
      -50.187,
      1.769,
      6.54,
      -48.936,
      2.121,
      6.54,
      -48.936,
      2.121,
      6.54,
      -48.936,
      2.121,
      2.07,
      -50.187,
      1.769,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1982",
    polygon: [
      -51.535,
      1.389,
      2.07,
      -51.535,
      1.389,
      6.54,
      -50.284,
      1.741,
      6.54,
      -50.284,
      1.741,
      6.54,
      -50.284,
      1.741,
      2.07,
      -51.535,
      1.389,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1983",
    polygon: [
      -52.228,
      1.194,
      2.07,
      -52.228,
      1.194,
      6.54,
      -51.631,
      1.362,
      6.54,
      -51.631,
      1.362,
      6.54,
      -51.631,
      1.362,
      2.07,
      -52.228,
      1.194,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_1984",
    polygon: [
      -73.718,
      -4.854,
      2.07,
      -73.718,
      -4.854,
      6.54,
      -73.087,
      -4.677,
      6.54,
      -73.087,
      -4.677,
      6.54,
      -73.087,
      -4.677,
      2.07,
      -73.718,
      -4.854,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_1985",
    polygon: [
      -75.065,
      -5.234,
      2.07,
      -75.065,
      -5.234,
      6.54,
      -73.814,
      -4.882,
      6.54,
      -73.814,
      -4.882,
      6.54,
      -73.814,
      -4.882,
      2.07,
      -75.065,
      -5.234,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "EG_1986",
    polygon: [
      -76.413,
      -5.614,
      2.07,
      -76.413,
      -5.614,
      6.54,
      -75.161,
      -5.261,
      6.54,
      -75.161,
      -5.261,
      6.54,
      -75.161,
      -5.261,
      2.07,
      -76.413,
      -5.614,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "EG_1987",
    polygon: [
      -77.76,
      -5.993,
      2.07,
      -77.76,
      -5.993,
      6.54,
      -76.509,
      -5.641,
      6.54,
      -76.509,
      -5.641,
      6.54,
      -76.509,
      -5.641,
      2.07,
      -77.76,
      -5.993,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1988",
    polygon: [
      -79.108,
      -6.373,
      2.07,
      -79.108,
      -6.373,
      6.54,
      -77.857,
      -6.02,
      6.54,
      -77.857,
      -6.02,
      6.54,
      -77.857,
      -6.02,
      2.07,
      -79.108,
      -6.373,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1989",
    polygon: [
      -80.455,
      -6.752,
      2.07,
      -80.455,
      -6.752,
      6.54,
      -79.204,
      -6.4,
      6.54,
      -79.204,
      -6.4,
      6.54,
      -79.204,
      -6.4,
      2.07,
      -80.455,
      -6.752,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1990",
    polygon: [
      -81.803,
      -7.132,
      2.07,
      -81.803,
      -7.132,
      6.54,
      -80.552,
      -6.779,
      6.54,
      -80.552,
      -6.779,
      6.54,
      -80.552,
      -6.779,
      2.07,
      -81.803,
      -7.132,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1991",
    polygon: [
      -83.15,
      -7.511,
      2.07,
      -83.15,
      -7.511,
      6.54,
      -81.899,
      -7.159,
      6.54,
      -81.899,
      -7.159,
      6.54,
      -81.899,
      -7.159,
      2.07,
      -83.15,
      -7.511,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1992",
    polygon: [
      -84.499,
      -7.891,
      2.07,
      -84.499,
      -7.891,
      6.54,
      -83.247,
      -7.539,
      6.54,
      -83.247,
      -7.539,
      6.54,
      -83.247,
      -7.539,
      2.07,
      -84.499,
      -7.891,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1993",
    polygon: [
      -86.069,
      -8.334,
      2.07,
      -86.069,
      -8.334,
      4.46,
      -84.597,
      -7.919,
      4.46,
      -84.597,
      -7.919,
      4.46,
      -84.597,
      -7.919,
      2.07,
      -86.069,
      -8.334,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "EG_1994",
    polygon: [
      -86.069,
      -8.334,
      4.51,
      -86.069,
      -8.334,
      6.54,
      -84.597,
      -7.919,
      6.54,
      -84.597,
      -7.919,
      6.54,
      -84.597,
      -7.919,
      4.51,
      -86.069,
      -8.334,
      4.51,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "EG_1995",
    polygon: [
      -87.414,
      -8.713,
      2.07,
      -87.414,
      -8.713,
      6.54,
      -86.163,
      -8.36,
      6.54,
      -86.163,
      -8.36,
      6.54,
      -86.163,
      -8.36,
      2.07,
      -87.414,
      -8.713,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1996",
    polygon: [
      -88.762,
      -9.092,
      2.07,
      -88.762,
      -9.092,
      6.54,
      -87.511,
      -8.74,
      6.54,
      -87.511,
      -8.74,
      6.54,
      -87.511,
      -8.74,
      2.07,
      -88.762,
      -9.092,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1997",
    polygon: [
      -90.109,
      -9.472,
      2.07,
      -90.109,
      -9.472,
      6.54,
      -88.858,
      -9.119,
      6.54,
      -88.858,
      -9.119,
      6.54,
      -88.858,
      -9.119,
      2.07,
      -90.109,
      -9.472,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1998",
    polygon: [
      -91.457,
      -9.851,
      2.07,
      -91.457,
      -9.851,
      6.54,
      -90.206,
      -9.499,
      6.54,
      -90.206,
      -9.499,
      6.54,
      -90.206,
      -9.499,
      2.07,
      -91.457,
      -9.851,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_1999",
    polygon: [
      -94.599,
      -10.736,
      2.07,
      -94.599,
      -10.736,
      4.46,
      -93.126,
      -10.321,
      4.46,
      -93.126,
      -10.321,
      4.46,
      -93.126,
      -10.321,
      2.07,
      -94.599,
      -10.736,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_2000",
    polygon: [
      -94.599,
      -10.736,
      4.51,
      -94.599,
      -10.736,
      6.54,
      -93.126,
      -10.321,
      6.54,
      -93.126,
      -10.321,
      6.54,
      -93.126,
      -10.321,
      4.51,
      -94.599,
      -10.736,
      4.51,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "EG_2001",
    polygon: [
      -95.944,
      -11.115,
      2.07,
      -95.944,
      -11.115,
      6.54,
      -94.692,
      -10.763,
      6.54,
      -94.692,
      -10.763,
      6.54,
      -94.692,
      -10.763,
      2.07,
      -95.944,
      -11.115,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_2002",
    polygon: [
      -97.291,
      -11.495,
      2.07,
      -97.291,
      -11.495,
      6.54,
      -96.04,
      -11.142,
      6.54,
      -96.04,
      -11.142,
      6.54,
      -96.04,
      -11.142,
      2.07,
      -97.291,
      -11.495,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_2003",
    polygon: [
      -98.639,
      -11.874,
      2.07,
      -98.639,
      -11.874,
      6.54,
      -97.387,
      -11.522,
      6.54,
      -97.387,
      -11.522,
      6.54,
      -97.387,
      -11.522,
      2.07,
      -98.639,
      -11.874,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "EG_2004",
    polygon: [
      -99.987,
      -12.254,
      2.07,
      -99.987,
      -12.254,
      6.54,
      -98.736,
      -11.902,
      6.54,
      -98.736,
      -11.902,
      6.54,
      -98.736,
      -11.902,
      2.07,
      -99.987,
      -12.254,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:35": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "EG_2005",
    polygon: [
      -93.03,
      -10.294,
      2.07,
      -93.03,
      -10.294,
      4.46,
      -91.557,
      -9.88,
      4.46,
      -91.557,
      -9.88,
      4.46,
      -91.557,
      -9.88,
      2.07,
      -93.03,
      -10.294,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_2006",
    polygon: [
      -93.03,
      -10.294,
      4.51,
      -93.03,
      -10.294,
      6.54,
      -91.557,
      -9.88,
      6.54,
      -91.557,
      -9.88,
      6.54,
      -91.557,
      -9.88,
      4.51,
      -93.03,
      -10.294,
      4.51,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "EG_2007",
    polygon: [
      -101.334,
      -12.634,
      2.07,
      -101.334,
      -12.634,
      6.54,
      -100.083,
      -12.281,
      6.54,
      -100.083,
      -12.281,
      6.54,
      -100.083,
      -12.281,
      2.07,
      -101.334,
      -12.634,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "EG_2008",
    polygon: [
      -105.377,
      -13.772,
      2.07,
      -105.377,
      -13.772,
      6.54,
      -104.126,
      -13.42,
      6.54,
      -104.126,
      -13.42,
      6.54,
      -104.126,
      -13.42,
      2.07,
      -105.377,
      -13.772,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2009",
    polygon: [
      -109.878,
      -12.968,
      2.073,
      -109.878,
      -12.968,
      6.543,
      -108.482,
      -12.574,
      6.543,
      -108.482,
      -12.574,
      6.543,
      -108.482,
      -12.574,
      2.073,
      -109.878,
      -12.968,
      2.073,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "EG_2012",
    polygon: [
      -112.899,
      -13.818,
      2.07,
      -112.899,
      -13.818,
      4.46,
      -111.426,
      -13.404,
      4.46,
      -111.426,
      -13.404,
      4.46,
      -111.426,
      -13.404,
      2.07,
      -112.899,
      -13.818,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "EG_2013",
    polygon: [
      -112.899,
      -13.818,
      4.51,
      -112.899,
      -13.818,
      6.54,
      -111.426,
      -13.404,
      6.54,
      -111.426,
      -13.404,
      6.54,
      -111.426,
      -13.404,
      4.51,
      -112.899,
      -13.818,
      4.51,
    ],
    "04-15 02:05": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_2014",
    polygon: [
      -115.846,
      -14.649,
      2.073,
      -115.846,
      -14.649,
      6.543,
      -114.45,
      -14.255,
      6.543,
      -114.45,
      -14.255,
      6.543,
      -114.45,
      -14.255,
      2.073,
      -115.846,
      -14.649,
      2.073,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "EG_2015",
    polygon: [
      -117.338,
      -15.069,
      2.073,
      -117.338,
      -15.069,
      6.543,
      -115.942,
      -14.676,
      6.543,
      -115.942,
      -14.676,
      6.543,
      -115.942,
      -14.676,
      2.073,
      -117.338,
      -15.069,
      2.073,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "EG_2016",
    polygon: [
      -121.813,
      -16.33,
      2.073,
      -121.813,
      -16.33,
      6.543,
      -120.418,
      -15.936,
      6.543,
      -120.418,
      -15.936,
      6.543,
      -120.418,
      -15.936,
      2.073,
      -121.813,
      -16.33,
      2.073,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "EG_2017",
    polygon: [
      -123.305,
      -16.75,
      2.073,
      -123.305,
      -16.75,
      6.543,
      -121.91,
      -16.357,
      6.543,
      -121.91,
      -16.357,
      6.543,
      -121.91,
      -16.357,
      2.073,
      -123.305,
      -16.75,
      2.073,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "EG_2018",
    polygon: [
      -127.781,
      -18.011,
      2.073,
      -127.781,
      -18.011,
      6.543,
      -126.386,
      -17.617,
      6.543,
      -126.386,
      -17.617,
      6.543,
      -126.386,
      -17.617,
      2.073,
      -127.781,
      -18.011,
      2.073,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "EG_2019",
    polygon: [
      -47.115,
      -47.681,
      2.07,
      -47.115,
      -47.681,
      6.565,
      -44.316,
      -46.892,
      6.565,
      -44.316,
      -46.892,
      6.565,
      -44.316,
      -46.892,
      2.07,
      -47.115,
      -47.681,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2020",
    polygon: [
      -49.963,
      -48.483,
      2.07,
      -49.963,
      -48.483,
      6.565,
      -47.163,
      -47.694,
      6.565,
      -47.163,
      -47.694,
      6.565,
      -47.163,
      -47.694,
      2.07,
      -49.963,
      -48.483,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2021",
    polygon: [
      -52.812,
      -49.285,
      2.07,
      -52.812,
      -49.285,
      6.565,
      -50.012,
      -48.497,
      6.565,
      -50.012,
      -48.497,
      6.565,
      -50.012,
      -48.497,
      2.07,
      -52.812,
      -49.285,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2022",
    polygon: [
      -55.031,
      -49.911,
      2.07,
      -55.031,
      -49.911,
      6.565,
      -52.862,
      -49.3,
      6.565,
      -52.862,
      -49.3,
      6.565,
      -52.862,
      -49.3,
      2.07,
      -55.031,
      -49.911,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2023",
    polygon: [
      -59.703,
      -51.227,
      2.07,
      -59.703,
      -51.227,
      6.565,
      -57.533,
      -50.616,
      6.565,
      -57.533,
      -50.616,
      6.565,
      -57.533,
      -50.616,
      2.07,
      -59.703,
      -51.227,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2024",
    polygon: [
      -44.266,
      -46.878,
      2.07,
      -44.266,
      -46.878,
      6.565,
      -42.364,
      -46.343,
      6.565,
      -42.364,
      -46.343,
      6.565,
      -42.364,
      -46.343,
      2.07,
      -44.266,
      -46.878,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2025",
    polygon: [
      -40.847,
      -45.915,
      4.264,
      -40.847,
      -45.915,
      6.494,
      -39.444,
      -45.52,
      6.494,
      -39.444,
      -45.52,
      6.494,
      -39.444,
      -45.52,
      4.264,
      -40.847,
      -45.915,
      4.264,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2026",
    polygon: [
      -42.25,
      -46.31,
      4.264,
      -42.25,
      -46.31,
      6.494,
      -40.847,
      -45.915,
      6.494,
      -40.847,
      -45.915,
      6.494,
      -40.847,
      -45.915,
      4.264,
      -42.25,
      -46.31,
      4.264,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2027",
    polygon: [
      -40.847,
      -45.915,
      2.035,
      -40.847,
      -45.915,
      4.265,
      -39.444,
      -45.52,
      4.265,
      -39.444,
      -45.52,
      4.265,
      -39.444,
      -45.52,
      2.035,
      -40.847,
      -45.915,
      2.035,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2028",
    polygon: [
      -42.25,
      -46.31,
      2.035,
      -42.25,
      -46.31,
      4.265,
      -40.847,
      -45.915,
      4.265,
      -40.847,
      -45.915,
      4.265,
      -40.847,
      -45.915,
      2.035,
      -42.25,
      -46.31,
      2.035,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2031",
    polygon: [
      -56.281,
      -50.263,
      2.035,
      -56.281,
      -50.263,
      4.448,
      -55.097,
      -49.929,
      4.448,
      -55.097,
      -49.929,
      4.448,
      -55.097,
      -49.929,
      2.035,
      -56.281,
      -50.263,
      2.035,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2032",
    polygon: [
      -57.465,
      -50.596,
      2.035,
      -57.465,
      -50.596,
      4.448,
      -56.281,
      -50.263,
      4.448,
      -56.281,
      -50.263,
      4.448,
      -56.281,
      -50.263,
      2.035,
      -57.465,
      -50.596,
      2.035,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2033",
    polygon: [
      -56.281,
      -50.263,
      4.468,
      -56.281,
      -50.263,
      6.565,
      -55.097,
      -49.929,
      6.565,
      -55.097,
      -49.929,
      6.565,
      -55.097,
      -49.929,
      4.468,
      -56.281,
      -50.263,
      4.468,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2034",
    polygon: [
      -57.465,
      -50.596,
      4.468,
      -57.465,
      -50.596,
      6.565,
      -56.281,
      -50.263,
      6.565,
      -56.281,
      -50.263,
      6.565,
      -56.281,
      -50.263,
      4.468,
      -57.465,
      -50.596,
      4.468,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2035",
    polygon: [
      -130.474,
      -20.55,
      2.07,
      -130.474,
      -20.55,
      6.54,
      -130.853,
      -19.203,
      6.54,
      -130.853,
      -19.203,
      6.54,
      -130.853,
      -19.203,
      2.07,
      -130.474,
      -20.55,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "EG_2036",
    polygon: [
      -130.067,
      -21.994,
      2.07,
      -130.067,
      -21.994,
      6.54,
      -130.446,
      -20.647,
      6.54,
      -130.446,
      -20.647,
      6.54,
      -130.446,
      -20.647,
      2.07,
      -130.067,
      -21.994,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "EG_2037",
    polygon: [
      -128.847,
      -26.326,
      2.07,
      -128.847,
      -26.326,
      6.54,
      -129.226,
      -24.978,
      6.54,
      -129.226,
      -24.978,
      6.54,
      -129.226,
      -24.978,
      2.07,
      -128.847,
      -26.326,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "EG_2038",
    polygon: [
      -128.44,
      -27.77,
      2.07,
      -128.44,
      -27.77,
      6.54,
      -128.819,
      -26.423,
      6.54,
      -128.819,
      -26.423,
      6.54,
      -128.819,
      -26.423,
      2.07,
      -128.44,
      -27.77,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "EG_2039",
    polygon: [
      -127.22,
      -32.101,
      2.07,
      -127.22,
      -32.101,
      6.54,
      -127.6,
      -30.753,
      6.54,
      -127.6,
      -30.753,
      6.54,
      -127.6,
      -30.753,
      2.07,
      -127.22,
      -32.101,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "EG_2040",
    polygon: [
      -126.0,
      -36.433,
      2.07,
      -126.0,
      -36.433,
      6.54,
      -126.379,
      -35.085,
      6.54,
      -126.379,
      -35.085,
      6.54,
      -126.379,
      -35.085,
      2.07,
      -126.0,
      -36.433,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_2041",
    polygon: [
      -124.834,
      -40.572,
      2.07,
      -124.834,
      -40.572,
      6.54,
      -125.186,
      -39.32,
      6.54,
      -125.186,
      -39.32,
      6.54,
      -125.186,
      -39.32,
      2.07,
      -124.834,
      -40.572,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 17:30": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "EG_2042",
    polygon: [
      -124.454,
      -41.919,
      2.07,
      -124.454,
      -41.919,
      6.54,
      -124.807,
      -40.668,
      6.54,
      -124.807,
      -40.668,
      6.54,
      -124.807,
      -40.668,
      2.07,
      -124.454,
      -41.919,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_2043",
    polygon: [
      -123.695,
      -44.614,
      2.07,
      -123.695,
      -44.614,
      6.54,
      -124.048,
      -43.363,
      6.54,
      -124.048,
      -43.363,
      6.54,
      -124.048,
      -43.363,
      2.07,
      -123.695,
      -44.614,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 17:50": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_2044",
    polygon: [
      -123.316,
      -45.962,
      2.07,
      -123.316,
      -45.962,
      6.54,
      -123.668,
      -44.711,
      6.54,
      -123.668,
      -44.711,
      6.54,
      -123.668,
      -44.711,
      2.07,
      -123.316,
      -45.962,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "EG_2045",
    polygon: [
      -122.936,
      -47.309,
      2.07,
      -122.936,
      -47.309,
      6.54,
      -123.288,
      -46.058,
      6.54,
      -123.288,
      -46.058,
      6.54,
      -123.288,
      -46.058,
      2.07,
      -122.936,
      -47.309,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 17:50": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_2046",
    polygon: [
      -122.556,
      -48.657,
      2.07,
      -122.556,
      -48.657,
      6.54,
      -122.909,
      -47.406,
      6.54,
      -122.909,
      -47.406,
      6.54,
      -122.909,
      -47.406,
      2.07,
      -122.556,
      -48.657,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
  },
  {
    ID: "EG_2047",
    polygon: [
      -122.177,
      -50.005,
      2.07,
      -122.177,
      -50.005,
      6.54,
      -122.529,
      -48.753,
      6.54,
      -122.529,
      -48.753,
      6.54,
      -122.529,
      -48.753,
      2.07,
      -122.177,
      -50.005,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "EG_2048",
    polygon: [
      -121.797,
      -51.352,
      2.07,
      -121.797,
      -51.352,
      6.54,
      -122.15,
      -50.101,
      6.54,
      -122.15,
      -50.101,
      6.54,
      -122.15,
      -50.101,
      2.07,
      -121.797,
      -51.352,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "EG_2049",
    polygon: [
      -121.418,
      -52.7,
      2.07,
      -121.418,
      -52.7,
      6.54,
      -121.77,
      -51.448,
      6.54,
      -121.77,
      -51.448,
      6.54,
      -121.77,
      -51.448,
      2.07,
      -121.418,
      -52.7,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2050",
    polygon: [
      -121.038,
      -54.047,
      2.07,
      -121.038,
      -54.047,
      6.54,
      -121.391,
      -52.796,
      6.54,
      -121.391,
      -52.796,
      6.54,
      -121.391,
      -52.796,
      2.07,
      -121.038,
      -54.047,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
  },
  {
    ID: "EG_2051",
    polygon: [
      -120.658,
      -55.395,
      2.07,
      -120.658,
      -55.395,
      6.54,
      -121.011,
      -54.143,
      6.54,
      -121.011,
      -54.143,
      6.54,
      -121.011,
      -54.143,
      2.07,
      -120.658,
      -55.395,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "EG_2052",
    polygon: [
      -120.279,
      -56.742,
      2.07,
      -120.279,
      -56.742,
      6.54,
      -120.631,
      -55.491,
      6.54,
      -120.631,
      -55.491,
      6.54,
      -120.631,
      -55.491,
      2.07,
      -120.279,
      -56.742,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
  },
  {
    ID: "EG_2053",
    polygon: [
      -119.899,
      -58.09,
      2.07,
      -119.899,
      -58.09,
      6.54,
      -120.252,
      -56.839,
      6.54,
      -120.252,
      -56.839,
      6.54,
      -120.252,
      -56.839,
      2.07,
      -119.899,
      -58.09,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
  },
  {
    ID: "EG_2054",
    polygon: [
      -119.52,
      -59.437,
      2.07,
      -119.52,
      -59.437,
      6.54,
      -119.872,
      -58.186,
      6.54,
      -119.872,
      -58.186,
      6.54,
      -119.872,
      -58.186,
      2.07,
      -119.52,
      -59.437,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
  },
  {
    ID: "EG_2055",
    polygon: [
      -119.078,
      -61.006,
      4.51,
      -119.078,
      -61.006,
      6.54,
      -119.493,
      -59.534,
      6.54,
      -119.493,
      -59.534,
      6.54,
      -119.493,
      -59.534,
      4.51,
      -119.078,
      -61.006,
      4.51,
    ],
    "04-15 02:05": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "EG_2056",
    polygon: [
      -119.078,
      -61.006,
      2.07,
      -119.078,
      -61.006,
      4.46,
      -119.493,
      -59.534,
      4.46,
      -119.493,
      -59.534,
      4.46,
      -119.493,
      -59.534,
      2.07,
      -119.078,
      -61.006,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "EG_2057",
    polygon: [
      -118.693,
      -62.373,
      4.511,
      -118.693,
      -62.373,
      6.541,
      -119.051,
      -61.102,
      6.541,
      -119.051,
      -61.102,
      6.541,
      -119.051,
      -61.102,
      4.511,
      -118.693,
      -62.373,
      4.511,
    ],
    "04-15 02:05": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "EG_2058",
    polygon: [
      -118.693,
      -62.373,
      2.288,
      -118.693,
      -62.373,
      4.465,
      -119.051,
      -61.103,
      4.465,
      -119.051,
      -61.103,
      4.465,
      -119.051,
      -61.103,
      2.288,
      -118.693,
      -62.373,
      2.288,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:30": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "EG_2059",
    polygon: [
      -118.286,
      -63.817,
      2.29,
      -118.286,
      -63.817,
      6.54,
      -118.666,
      -62.469,
      6.54,
      -118.666,
      -62.469,
      6.54,
      -118.666,
      -62.469,
      2.29,
      -118.286,
      -63.817,
      2.29,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 16:00": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "EG_2060",
    polygon: [
      -117.879,
      -65.26,
      2.29,
      -117.879,
      -65.26,
      6.54,
      -118.259,
      -63.913,
      6.54,
      -118.259,
      -63.913,
      6.54,
      -118.259,
      -63.913,
      2.29,
      -117.879,
      -65.26,
      2.29,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "EG_2061",
    polygon: [
      -117.473,
      -66.704,
      2.29,
      -117.473,
      -66.704,
      6.54,
      -117.852,
      -65.357,
      6.54,
      -117.852,
      -65.357,
      6.54,
      -117.852,
      -65.357,
      2.29,
      -117.473,
      -66.704,
      2.29,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
  },
  {
    ID: "EG_2062",
    polygon: [
      -117.066,
      -68.148,
      2.29,
      -117.066,
      -68.148,
      6.54,
      -117.446,
      -66.8,
      6.54,
      -117.446,
      -66.8,
      6.54,
      -117.446,
      -66.8,
      2.29,
      -117.066,
      -68.148,
      2.29,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "EG_2063",
    polygon: [
      -116.659,
      -69.592,
      2.29,
      -116.659,
      -69.592,
      6.54,
      -117.039,
      -68.244,
      6.54,
      -117.039,
      -68.244,
      6.54,
      -117.039,
      -68.244,
      2.29,
      -116.659,
      -69.592,
      2.29,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "EG_2064",
    polygon: [
      -116.266,
      -70.988,
      2.29,
      -116.266,
      -70.988,
      6.54,
      -116.632,
      -69.688,
      6.54,
      -116.632,
      -69.688,
      6.54,
      -116.632,
      -69.688,
      2.29,
      -116.266,
      -70.988,
      2.29,
    ],
    "04-15 02:05": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "EG_2065",
    polygon: [
      -91.596,
      -66.834,
      2.05,
      -91.596,
      -66.834,
      6.57,
      -92.25,
      -64.513,
      6.57,
      -92.25,
      -64.513,
      6.57,
      -92.25,
      -64.513,
      2.05,
      -91.596,
      -66.834,
      2.05,
    ],
    "04-15 02:05": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "EG_2066",
    polygon: [
      -90.928,
      -69.206,
      2.05,
      -90.928,
      -69.206,
      6.57,
      -91.582,
      -66.885,
      6.57,
      -91.582,
      -66.885,
      6.57,
      -91.582,
      -66.885,
      2.05,
      -90.928,
      -69.206,
      2.05,
    ],
    "04-15 02:05": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
  },
  {
    ID: "EG_2067",
    polygon: [
      -90.261,
      -71.573,
      2.05,
      -90.261,
      -71.573,
      6.57,
      -90.915,
      -69.252,
      6.57,
      -90.915,
      -69.252,
      6.57,
      -90.915,
      -69.252,
      2.05,
      -90.261,
      -71.573,
      2.05,
    ],
    "04-15 02:05": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "EG_2068",
    polygon: [
      -89.593,
      -73.945,
      2.05,
      -89.593,
      -73.945,
      6.57,
      -90.247,
      -71.623,
      6.57,
      -90.247,
      -71.623,
      6.57,
      -90.247,
      -71.623,
      2.05,
      -89.593,
      -73.945,
      2.05,
    ],
    "04-15 02:05": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "EG_2069",
    polygon: [
      -88.925,
      -76.314,
      2.05,
      -88.925,
      -76.314,
      6.57,
      -89.579,
      -73.993,
      6.57,
      -89.579,
      -73.993,
      6.57,
      -89.579,
      -73.993,
      2.05,
      -88.925,
      -76.314,
      2.05,
    ],
    "04-15 02:05": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "EG_2070",
    polygon: [
      -88.585,
      -77.523,
      2.035,
      -88.585,
      -77.523,
      4.43,
      -88.907,
      -76.381,
      4.43,
      -88.907,
      -76.381,
      4.43,
      -88.907,
      -76.381,
      2.035,
      -88.585,
      -77.523,
      2.035,
    ],
    "04-15 02:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "EG_2071",
    polygon: [
      -88.263,
      -78.665,
      2.035,
      -88.263,
      -78.665,
      4.43,
      -88.585,
      -77.523,
      4.43,
      -88.585,
      -77.523,
      4.43,
      -88.585,
      -77.523,
      2.035,
      -88.263,
      -78.665,
      2.035,
    ],
    "04-15 02:05": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "EG_2072",
    polygon: [
      -88.572,
      -77.57,
      4.51,
      -88.572,
      -77.57,
      6.572,
      -88.905,
      -76.386,
      6.572,
      -88.905,
      -76.386,
      6.572,
      -88.905,
      -76.386,
      4.51,
      -88.572,
      -77.57,
      4.51,
    ],
    "04-15 02:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "EG_2073",
    polygon: [
      -88.259,
      -78.681,
      4.51,
      -88.259,
      -78.681,
      6.572,
      -88.572,
      -77.57,
      6.572,
      -88.572,
      -77.57,
      6.572,
      -88.572,
      -77.57,
      4.51,
      -88.259,
      -78.681,
      4.51,
    ],
    "04-15 02:05": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_2075",
    polygon: [
      12.871,
      -63.426,
      2.07,
      12.871,
      -63.426,
      6.54,
      13.251,
      -64.774,
      6.54,
      13.251,
      -64.774,
      6.54,
      13.251,
      -64.774,
      2.07,
      12.871,
      -63.426,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2076",
    polygon: [
      11.651,
      -59.095,
      2.07,
      11.651,
      -59.095,
      6.54,
      12.031,
      -60.443,
      6.54,
      12.031,
      -60.443,
      6.54,
      12.031,
      -60.443,
      2.07,
      11.651,
      -59.095,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2077",
    polygon: [
      16.384,
      -15.411,
      2.07,
      16.384,
      -15.411,
      6.54,
      16.736,
      -16.662,
      6.54,
      16.736,
      -16.662,
      6.54,
      16.736,
      -16.662,
      2.07,
      16.384,
      -15.411,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2082",
    polygon: [
      -0.07,
      -48.78,
      2.045,
      -0.07,
      -48.78,
      6.565,
      0.584,
      -51.103,
      6.565,
      0.584,
      -51.103,
      6.565,
      0.584,
      -51.103,
      2.045,
      -0.07,
      -48.78,
      2.045,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "EG_2083",
    polygon: [
      -0.738,
      -46.409,
      2.045,
      -0.738,
      -46.409,
      6.565,
      -0.084,
      -48.731,
      6.565,
      -0.084,
      -48.731,
      6.565,
      -0.084,
      -48.731,
      2.045,
      -0.738,
      -46.409,
      2.045,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "EG_2084",
    polygon: [
      -1.406,
      -44.038,
      2.045,
      -1.406,
      -44.038,
      6.565,
      -0.751,
      -46.361,
      6.565,
      -0.751,
      -46.361,
      6.565,
      -0.751,
      -46.361,
      2.045,
      -1.406,
      -44.038,
      2.045,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
  },
  {
    ID: "EG_2085",
    polygon: [
      -2.074,
      -41.667,
      2.045,
      -2.074,
      -41.667,
      6.565,
      -1.419,
      -43.99,
      6.565,
      -1.419,
      -43.99,
      6.565,
      -1.419,
      -43.99,
      2.045,
      -2.074,
      -41.667,
      2.045,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
  },
  {
    ID: "EG_2086",
    polygon: [
      -2.741,
      -39.297,
      2.045,
      -2.741,
      -39.297,
      6.565,
      -2.087,
      -41.619,
      6.565,
      -2.087,
      -41.619,
      6.565,
      -2.087,
      -41.619,
      2.045,
      -2.741,
      -39.297,
      2.045,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
  },
  {
    ID: "EG_2087",
    polygon: [
      0.603,
      -51.169,
      2.012,
      0.603,
      -51.169,
      4.407,
      0.925,
      -52.311,
      4.407,
      0.925,
      -52.311,
      4.407,
      0.925,
      -52.311,
      2.012,
      0.603,
      -51.169,
      2.012,
    ],
    "04-15 02:05": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
  },
  {
    ID: "EG_2088",
    polygon: [
      0.925,
      -52.311,
      2.012,
      0.925,
      -52.311,
      4.407,
      1.246,
      -53.453,
      4.407,
      1.246,
      -53.453,
      4.407,
      1.246,
      -53.453,
      2.012,
      0.925,
      -52.311,
      2.012,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2089",
    polygon: [
      0.948,
      -52.394,
      4.457,
      0.948,
      -52.394,
      6.519,
      1.257,
      -53.491,
      6.519,
      1.257,
      -53.491,
      6.519,
      1.257,
      -53.491,
      4.457,
      0.948,
      -52.394,
      4.457,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2090",
    polygon: [
      0.598,
      -51.153,
      4.477,
      0.598,
      -51.153,
      6.539,
      0.94,
      -52.365,
      6.539,
      0.94,
      -52.365,
      6.539,
      0.94,
      -52.365,
      4.477,
      0.598,
      -51.153,
      4.477,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
  },
  {
    ID: "EG_2091",
    polygon: [
      -51.306,
      -82.225,
      2.07,
      -51.306,
      -82.225,
      6.54,
      -50.927,
      -83.573,
      6.54,
      -50.927,
      -83.573,
      6.54,
      -50.927,
      -83.573,
      2.07,
      -51.306,
      -82.225,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2092",
    polygon: [
      -50.902,
      -83.659,
      2.07,
      -50.902,
      -83.659,
      6.54,
      -50.55,
      -84.911,
      6.54,
      -50.55,
      -84.911,
      6.54,
      -50.55,
      -84.911,
      2.07,
      -50.902,
      -83.659,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
  },
  {
    ID: "EG_2093",
    polygon: [
      -51.714,
      -80.776,
      2.07,
      -51.714,
      -80.776,
      6.54,
      -51.335,
      -82.124,
      6.54,
      -51.335,
      -82.124,
      6.54,
      -51.335,
      -82.124,
      2.07,
      -51.714,
      -80.776,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2094",
    polygon: [
      -53.004,
      -76.198,
      2.07,
      -53.004,
      -76.198,
      6.54,
      -52.624,
      -77.546,
      6.54,
      -52.624,
      -77.546,
      6.54,
      -52.624,
      -77.546,
      2.07,
      -53.004,
      -76.198,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2095",
    polygon: [
      -53.412,
      -74.75,
      2.07,
      -53.412,
      -74.75,
      6.54,
      -53.032,
      -76.097,
      6.54,
      -53.032,
      -76.097,
      6.54,
      -53.032,
      -76.097,
      2.07,
      -53.412,
      -74.75,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 06:30": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2096",
    polygon: [
      -53.817,
      -73.312,
      2.07,
      -53.817,
      -73.312,
      6.54,
      -53.437,
      -74.659,
      6.54,
      -53.437,
      -74.659,
      6.54,
      -53.437,
      -74.659,
      2.07,
      -53.817,
      -73.312,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 06:45": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2097",
    polygon: [
      -52.593,
      -77.658,
      2.054,
      -52.593,
      -77.658,
      4.449,
      -52.189,
      -79.092,
      4.449,
      -52.189,
      -79.092,
      4.449,
      -52.189,
      -79.092,
      2.054,
      -52.593,
      -77.658,
      2.054,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
  },
  {
    ID: "EG_2098",
    polygon: [
      -52.151,
      -79.227,
      2.054,
      -52.151,
      -79.227,
      4.449,
      -51.747,
      -80.661,
      4.449,
      -51.747,
      -80.661,
      4.449,
      -51.747,
      -80.661,
      2.054,
      -52.151,
      -79.227,
      2.054,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
  },
  {
    ID: "EG_2099",
    polygon: [
      -52.597,
      -77.641,
      4.5,
      -52.597,
      -77.641,
      6.535,
      -52.184,
      -79.109,
      6.535,
      -52.184,
      -79.109,
      6.535,
      -52.184,
      -79.109,
      4.5,
      -52.597,
      -77.641,
      4.5,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
  },
  {
    ID: "EG_2100",
    polygon: [
      -52.156,
      -79.21,
      4.5,
      -52.156,
      -79.21,
      6.535,
      -51.742,
      -80.678,
      6.535,
      -51.742,
      -80.678,
      6.535,
      -51.742,
      -80.678,
      4.5,
      -52.156,
      -79.21,
      4.5,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
  },
  {
    ID: "EG_2101",
    polygon: [
      -66.925,
      -26.758,
      2.054,
      -66.925,
      -26.758,
      4.449,
      -66.521,
      -28.192,
      4.449,
      -66.521,
      -28.192,
      4.449,
      -66.521,
      -28.192,
      2.054,
      -66.925,
      -26.758,
      2.054,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
  },
  {
    ID: "EG_2102",
    polygon: [
      -66.483,
      -28.327,
      2.054,
      -66.483,
      -28.327,
      4.449,
      -66.079,
      -29.761,
      4.449,
      -66.079,
      -29.761,
      4.449,
      -66.079,
      -29.761,
      2.054,
      -66.483,
      -28.327,
      2.054,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
  },
  {
    ID: "EG_2103",
    polygon: [
      -66.93,
      -26.741,
      4.5,
      -66.93,
      -26.741,
      6.535,
      -66.516,
      -28.209,
      6.535,
      -66.516,
      -28.209,
      6.535,
      -66.516,
      -28.209,
      4.5,
      -66.93,
      -26.741,
      4.5,
    ],
    "04-15 02:05": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2104",
    polygon: [
      -66.488,
      -28.31,
      4.5,
      -66.488,
      -28.31,
      6.535,
      -66.074,
      -29.778,
      6.535,
      -66.074,
      -29.778,
      6.535,
      -66.074,
      -29.778,
      4.5,
      -66.488,
      -28.31,
      4.5,
    ],
    "04-15 02:05": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2105",
    polygon: [
      -68.568,
      -20.925,
      2.054,
      -68.568,
      -20.925,
      4.449,
      -68.164,
      -22.359,
      4.449,
      -68.164,
      -22.359,
      4.449,
      -68.164,
      -22.359,
      2.054,
      -68.568,
      -20.925,
      2.054,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
  },
  {
    ID: "EG_2106",
    polygon: [
      -68.126,
      -22.494,
      2.054,
      -68.126,
      -22.494,
      4.449,
      -67.722,
      -23.928,
      4.449,
      -67.722,
      -23.928,
      4.449,
      -67.722,
      -23.928,
      2.054,
      -68.126,
      -22.494,
      2.054,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
  },
  {
    ID: "EG_2107",
    polygon: [
      -68.573,
      -20.908,
      4.5,
      -68.573,
      -20.908,
      6.535,
      -68.159,
      -22.376,
      6.535,
      -68.159,
      -22.376,
      6.535,
      -68.159,
      -22.376,
      4.5,
      -68.573,
      -20.908,
      4.5,
    ],
    "04-15 02:05": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2108",
    polygon: [
      -68.131,
      -22.477,
      4.5,
      -68.131,
      -22.477,
      6.535,
      -67.717,
      -23.945,
      6.535,
      -67.717,
      -23.945,
      6.535,
      -67.717,
      -23.945,
      4.5,
      -68.131,
      -22.477,
      4.5,
    ],
    "04-15 02:05": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2109",
    polygon: [
      -61.87,
      -44.704,
      2.075,
      -61.87,
      -44.704,
      6.545,
      -61.517,
      -45.956,
      6.545,
      -61.517,
      -45.956,
      6.545,
      -61.517,
      -45.956,
      2.075,
      -61.87,
      -44.704,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2110",
    polygon: [
      -62.249,
      -43.357,
      2.075,
      -62.249,
      -43.357,
      6.545,
      -61.897,
      -44.608,
      6.545,
      -61.897,
      -44.608,
      6.545,
      -61.897,
      -44.608,
      2.075,
      -62.249,
      -43.357,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2111",
    polygon: [
      -62.629,
      -42.009,
      2.075,
      -62.629,
      -42.009,
      6.545,
      -62.277,
      -43.26,
      6.545,
      -62.277,
      -43.26,
      6.545,
      -62.277,
      -43.26,
      2.075,
      -62.629,
      -42.009,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2112",
    polygon: [
      -63.009,
      -40.662,
      2.075,
      -63.009,
      -40.662,
      6.545,
      -62.656,
      -41.913,
      6.545,
      -62.656,
      -41.913,
      6.545,
      -62.656,
      -41.913,
      2.075,
      -63.009,
      -40.662,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2113",
    polygon: [
      -63.388,
      -39.314,
      2.075,
      -63.388,
      -39.314,
      6.545,
      -63.036,
      -40.565,
      6.545,
      -63.036,
      -40.565,
      6.545,
      -63.036,
      -40.565,
      2.075,
      -63.388,
      -39.314,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2114",
    polygon: [
      -63.768,
      -37.967,
      2.075,
      -63.768,
      -37.967,
      6.545,
      -63.415,
      -39.218,
      6.545,
      -63.415,
      -39.218,
      6.545,
      -63.415,
      -39.218,
      2.075,
      -63.768,
      -37.967,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2115",
    polygon: [
      -64.147,
      -36.619,
      2.075,
      -64.147,
      -36.619,
      6.545,
      -63.795,
      -37.87,
      6.545,
      -63.795,
      -37.87,
      6.545,
      -63.795,
      -37.87,
      2.075,
      -64.147,
      -36.619,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
  },
  {
    ID: "EG_2116",
    polygon: [
      -64.527,
      -35.271,
      2.075,
      -64.527,
      -35.271,
      6.545,
      -64.175,
      -36.523,
      6.545,
      -64.175,
      -36.523,
      6.545,
      -64.175,
      -36.523,
      2.075,
      -64.527,
      -35.271,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
  },
  {
    ID: "EG_2117",
    polygon: [
      -64.908,
      -33.919,
      2.075,
      -64.908,
      -33.919,
      6.545,
      -64.555,
      -35.17,
      6.545,
      -64.555,
      -35.17,
      6.545,
      -64.555,
      -35.17,
      2.075,
      -64.908,
      -33.919,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
  },
  {
    ID: "EG_2118",
    polygon: [
      -65.288,
      -32.571,
      2.075,
      -65.288,
      -32.571,
      6.545,
      -64.935,
      -33.823,
      6.545,
      -64.935,
      -33.823,
      6.545,
      -64.935,
      -33.823,
      2.075,
      -65.288,
      -32.571,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
  },
  {
    ID: "EG_2119",
    polygon: [
      -65.667,
      -31.224,
      2.075,
      -65.667,
      -31.224,
      6.545,
      -65.315,
      -32.475,
      6.545,
      -65.315,
      -32.475,
      6.545,
      -65.315,
      -32.475,
      2.075,
      -65.667,
      -31.224,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
  },
  {
    ID: "EG_2120",
    polygon: [
      -66.047,
      -29.876,
      2.075,
      -66.047,
      -29.876,
      6.545,
      -65.694,
      -31.128,
      6.545,
      -65.694,
      -31.128,
      6.545,
      -65.694,
      -31.128,
      2.075,
      -66.047,
      -29.876,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
  },
  {
    ID: "EG_2121",
    polygon: [
      -67.31,
      -25.391,
      2.075,
      -67.31,
      -25.391,
      6.545,
      -66.958,
      -26.642,
      6.545,
      -66.958,
      -26.642,
      6.545,
      -66.958,
      -26.642,
      2.075,
      -67.31,
      -25.391,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
  },
  {
    ID: "EG_2122",
    polygon: [
      -67.69,
      -24.043,
      2.075,
      -67.69,
      -24.043,
      6.545,
      -67.337,
      -25.295,
      6.545,
      -67.337,
      -25.295,
      6.545,
      -67.337,
      -25.295,
      2.075,
      -67.69,
      -24.043,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
  },
  {
    ID: "EG_2123",
    polygon: [
      -68.952,
      -19.563,
      2.075,
      -68.952,
      -19.563,
      6.545,
      -68.599,
      -20.814,
      6.545,
      -68.599,
      -20.814,
      6.545,
      -68.599,
      -20.814,
      2.075,
      -68.952,
      -19.563,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
  },
  {
    ID: "EG_2124",
    polygon: [
      -69.331,
      -18.216,
      2.075,
      -69.331,
      -18.216,
      6.545,
      -68.979,
      -19.467,
      6.545,
      -68.979,
      -19.467,
      6.545,
      -68.979,
      -19.467,
      2.075,
      -69.331,
      -18.216,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2125",
    polygon: [
      -69.711,
      -16.868,
      2.075,
      -69.711,
      -16.868,
      6.545,
      -69.358,
      -18.119,
      6.545,
      -69.358,
      -18.119,
      6.545,
      -69.358,
      -18.119,
      2.075,
      -69.711,
      -16.868,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2126",
    polygon: [
      -70.09,
      -15.521,
      2.075,
      -70.09,
      -15.521,
      6.545,
      -69.738,
      -16.772,
      6.545,
      -69.738,
      -16.772,
      6.545,
      -69.738,
      -16.772,
      2.075,
      -70.09,
      -15.521,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2127",
    polygon: [
      -70.47,
      -14.173,
      2.075,
      -70.47,
      -14.173,
      6.545,
      -70.118,
      -15.424,
      6.545,
      -70.118,
      -15.424,
      6.545,
      -70.118,
      -15.424,
      2.075,
      -70.47,
      -14.173,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2128",
    polygon: [
      -70.85,
      -12.825,
      2.075,
      -70.85,
      -12.825,
      6.545,
      -70.497,
      -14.077,
      6.545,
      -70.497,
      -14.077,
      6.545,
      -70.497,
      -14.077,
      2.075,
      -70.85,
      -12.825,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2129",
    polygon: [
      -71.229,
      -11.478,
      2.075,
      -71.229,
      -11.478,
      6.545,
      -70.877,
      -12.729,
      6.545,
      -70.877,
      -12.729,
      6.545,
      -70.877,
      -12.729,
      2.075,
      -71.229,
      -11.478,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2130",
    polygon: [
      -71.609,
      -10.13,
      2.075,
      -71.609,
      -10.13,
      6.545,
      -71.256,
      -11.382,
      6.545,
      -71.256,
      -11.382,
      6.545,
      -71.256,
      -11.382,
      2.075,
      -71.609,
      -10.13,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2131",
    polygon: [
      -71.99,
      -8.778,
      2.075,
      -71.99,
      -8.778,
      6.545,
      -71.637,
      -10.029,
      6.545,
      -71.637,
      -10.029,
      6.545,
      -71.637,
      -10.029,
      2.075,
      -71.99,
      -8.778,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2132",
    polygon: [
      -72.369,
      -7.43,
      2.075,
      -72.369,
      -7.43,
      6.545,
      -72.017,
      -8.682,
      6.545,
      -72.017,
      -8.682,
      6.545,
      -72.017,
      -8.682,
      2.075,
      -72.369,
      -7.43,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2133",
    polygon: [
      -72.749,
      -6.083,
      2.075,
      -72.749,
      -6.083,
      6.545,
      -72.396,
      -7.334,
      6.545,
      -72.396,
      -7.334,
      6.545,
      -72.396,
      -7.334,
      2.075,
      -72.749,
      -6.083,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2134",
    polygon: [
      -73.156,
      -4.639,
      2.075,
      -73.156,
      -4.639,
      6.545,
      -72.776,
      -5.987,
      6.545,
      -72.776,
      -5.987,
      6.545,
      -72.776,
      -5.987,
      2.075,
      -73.156,
      -4.639,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2135",
    polygon: [
      -28.028,
      -77.122,
      2.07,
      -28.028,
      -77.122,
      6.54,
      -28.407,
      -75.775,
      6.54,
      -28.407,
      -75.775,
      6.54,
      -28.407,
      -75.775,
      2.07,
      -28.028,
      -77.122,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
  },
  {
    ID: "EG_2136",
    polygon: [
      -28.434,
      -75.679,
      2.07,
      -28.434,
      -75.679,
      6.54,
      -28.814,
      -74.331,
      6.54,
      -28.814,
      -74.331,
      6.54,
      -28.814,
      -74.331,
      2.07,
      -28.434,
      -75.679,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "EG_2137",
    polygon: [
      -27.648,
      -78.47,
      2.07,
      -27.648,
      -78.47,
      6.54,
      -28.001,
      -77.219,
      6.54,
      -28.001,
      -77.219,
      6.54,
      -28.001,
      -77.219,
      2.07,
      -27.648,
      -78.47,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
  },
  {
    ID: "EG_2138",
    polygon: [
      -30.132,
      -69.653,
      2.07,
      -30.132,
      -69.653,
      6.54,
      -30.511,
      -68.305,
      6.54,
      -30.511,
      -68.305,
      6.54,
      -30.511,
      -68.305,
      2.07,
      -30.132,
      -69.653,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_2139",
    polygon: [
      -30.538,
      -68.209,
      2.07,
      -30.538,
      -68.209,
      6.54,
      -30.918,
      -66.862,
      6.54,
      -30.918,
      -66.862,
      6.54,
      -30.918,
      -66.862,
      2.07,
      -30.538,
      -68.209,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_2140",
    polygon: [
      -29.725,
      -71.097,
      2.07,
      -29.725,
      -71.097,
      6.54,
      -30.105,
      -69.749,
      6.54,
      -30.105,
      -69.749,
      6.54,
      -30.105,
      -69.749,
      2.07,
      -29.725,
      -71.097,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_2141",
    polygon: [
      -29.283,
      -72.666,
      4.509,
      -29.283,
      -72.666,
      6.539,
      -29.698,
      -71.194,
      6.539,
      -29.698,
      -71.194,
      6.539,
      -29.698,
      -71.194,
      4.509,
      -29.283,
      -72.666,
      4.509,
    ],
    "04-15 02:05": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "EG_2142",
    polygon: [
      -28.841,
      -74.235,
      4.509,
      -28.841,
      -74.235,
      6.539,
      -29.256,
      -72.762,
      6.539,
      -29.256,
      -72.762,
      6.539,
      -29.256,
      -72.762,
      4.509,
      -28.841,
      -74.235,
      4.509,
    ],
    "04-15 02:05": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2143",
    polygon: [
      -29.283,
      -72.666,
      2.065,
      -29.283,
      -72.666,
      4.455,
      -29.698,
      -71.194,
      4.455,
      -29.698,
      -71.194,
      4.455,
      -29.698,
      -71.194,
      2.065,
      -29.283,
      -72.666,
      2.065,
    ],
    "04-15 02:05": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "EG_2144",
    polygon: [
      -28.841,
      -74.235,
      2.065,
      -28.841,
      -74.235,
      4.455,
      -29.256,
      -72.762,
      4.455,
      -29.256,
      -72.762,
      4.455,
      -29.256,
      -72.762,
      2.065,
      -28.841,
      -74.235,
      2.065,
    ],
    "04-15 02:05": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2145",
    polygon: [
      -51.59,
      -0.851,
      2.07,
      -51.59,
      -0.851,
      6.54,
      -51.97,
      0.497,
      6.54,
      -51.97,
      0.497,
      6.54,
      -51.97,
      0.497,
      2.07,
      -51.59,
      -0.851,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_2147",
    polygon: [
      -51.997,
      0.593,
      2.07,
      -51.997,
      0.593,
      6.54,
      -52.174,
      1.224,
      6.54,
      -52.174,
      1.224,
      6.54,
      -52.174,
      1.224,
      2.07,
      -51.997,
      0.593,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_2148",
    polygon: [
      -51.183,
      -2.295,
      2.07,
      -51.183,
      -2.295,
      6.54,
      -51.563,
      -0.947,
      6.54,
      -51.563,
      -0.947,
      6.54,
      -51.563,
      -0.947,
      2.07,
      -51.183,
      -2.295,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_2149",
    polygon: [
      -50.777,
      -3.738,
      2.07,
      -50.777,
      -3.738,
      6.54,
      -51.156,
      -2.391,
      6.54,
      -51.156,
      -2.391,
      6.54,
      -51.156,
      -2.391,
      2.07,
      -50.777,
      -3.738,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_2150",
    polygon: [
      -50.37,
      -5.182,
      2.07,
      -50.37,
      -5.182,
      6.54,
      -50.749,
      -3.835,
      6.54,
      -50.749,
      -3.835,
      6.54,
      -50.749,
      -3.835,
      2.07,
      -50.37,
      -5.182,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_2151",
    polygon: [
      -49.963,
      -6.626,
      2.07,
      -49.963,
      -6.626,
      6.54,
      -50.343,
      -5.279,
      6.54,
      -50.343,
      -5.279,
      6.54,
      -50.343,
      -5.279,
      2.07,
      -49.963,
      -6.626,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "EG_2152",
    polygon: [
      -49.556,
      -8.07,
      2.07,
      -49.556,
      -8.07,
      6.54,
      -49.936,
      -6.722,
      6.54,
      -49.936,
      -6.722,
      6.54,
      -49.936,
      -6.722,
      2.07,
      -49.556,
      -8.07,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_2153",
    polygon: [
      -49.15,
      -9.514,
      2.07,
      -49.15,
      -9.514,
      6.54,
      -49.529,
      -8.166,
      6.54,
      -49.529,
      -8.166,
      6.54,
      -49.529,
      -8.166,
      2.07,
      -49.15,
      -9.514,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 17:50": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "EG_2154",
    polygon: [
      -48.743,
      -10.958,
      2.07,
      -48.743,
      -10.958,
      6.54,
      -49.123,
      -9.61,
      6.54,
      -49.123,
      -9.61,
      6.54,
      -49.123,
      -9.61,
      2.07,
      -48.743,
      -10.958,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
  },
  {
    ID: "EG_2155",
    polygon: [
      -48.336,
      -12.401,
      2.07,
      -48.336,
      -12.401,
      6.54,
      -48.716,
      -11.054,
      6.54,
      -48.716,
      -11.054,
      6.54,
      -48.716,
      -11.054,
      2.07,
      -48.336,
      -12.401,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
  },
  {
    ID: "EG_2156",
    polygon: [
      -47.93,
      -13.845,
      2.07,
      -47.93,
      -13.845,
      6.54,
      -48.309,
      -12.498,
      6.54,
      -48.309,
      -12.498,
      6.54,
      -48.309,
      -12.498,
      2.07,
      -47.93,
      -13.845,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "EG_2157",
    polygon: [
      -47.523,
      -15.289,
      2.07,
      -47.523,
      -15.289,
      6.54,
      -47.903,
      -13.941,
      6.54,
      -47.903,
      -13.941,
      6.54,
      -47.903,
      -13.941,
      2.07,
      -47.523,
      -15.289,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
  },
  {
    ID: "EG_2158",
    polygon: [
      -47.116,
      -16.733,
      2.07,
      -47.116,
      -16.733,
      6.54,
      -47.496,
      -15.385,
      6.54,
      -47.496,
      -15.385,
      6.54,
      -47.496,
      -15.385,
      2.07,
      -47.116,
      -16.733,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
  },
  {
    ID: "EG_2159",
    polygon: [
      -46.71,
      -18.177,
      2.07,
      -46.71,
      -18.177,
      6.54,
      -47.089,
      -16.829,
      6.54,
      -47.089,
      -16.829,
      6.54,
      -47.089,
      -16.829,
      2.07,
      -46.71,
      -18.177,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
  },
  {
    ID: "EG_2160",
    polygon: [
      -46.303,
      -19.62,
      2.07,
      -46.303,
      -19.62,
      6.54,
      -46.682,
      -18.273,
      6.54,
      -46.682,
      -18.273,
      6.54,
      -46.682,
      -18.273,
      2.07,
      -46.303,
      -19.62,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
  },
  {
    ID: "EG_2161",
    polygon: [
      -43.983,
      -20.517,
      2.07,
      -43.983,
      -20.517,
      6.54,
      -44.362,
      -19.17,
      6.54,
      -44.362,
      -19.17,
      6.54,
      -44.362,
      -19.17,
      2.07,
      -43.983,
      -20.517,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2162",
    polygon: [
      -43.579,
      -21.95,
      2.07,
      -43.579,
      -21.95,
      6.54,
      -43.959,
      -20.603,
      6.54,
      -43.959,
      -20.603,
      6.54,
      -43.959,
      -20.603,
      2.07,
      -43.579,
      -21.95,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2163",
    polygon: [
      -42.339,
      -26.353,
      2.07,
      -42.339,
      -26.353,
      6.54,
      -42.718,
      -25.005,
      6.54,
      -42.718,
      -25.005,
      6.54,
      -42.718,
      -25.005,
      2.07,
      -42.339,
      -26.353,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2164",
    polygon: [
      -41.927,
      -27.817,
      2.07,
      -41.927,
      -27.817,
      6.54,
      -42.306,
      -26.469,
      6.54,
      -42.306,
      -26.469,
      6.54,
      -42.306,
      -26.469,
      2.07,
      -41.927,
      -27.817,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2165",
    polygon: [
      -41.517,
      -29.271,
      2.07,
      -41.517,
      -29.271,
      6.54,
      -41.897,
      -27.923,
      6.54,
      -41.897,
      -27.923,
      6.54,
      -41.897,
      -27.923,
      2.07,
      -41.517,
      -29.271,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2166",
    polygon: [
      -41.105,
      -30.732,
      2.07,
      -41.105,
      -30.732,
      6.54,
      -41.485,
      -29.385,
      6.54,
      -41.485,
      -29.385,
      6.54,
      -41.485,
      -29.385,
      2.07,
      -41.105,
      -30.732,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2167",
    polygon: [
      -40.693,
      -32.196,
      2.07,
      -40.693,
      -32.196,
      6.54,
      -41.073,
      -30.849,
      6.54,
      -41.073,
      -30.849,
      6.54,
      -41.073,
      -30.849,
      2.07,
      -40.693,
      -32.196,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2168",
    polygon: [
      -40.282,
      -33.655,
      2.07,
      -40.282,
      -33.655,
      6.54,
      -40.662,
      -32.308,
      6.54,
      -40.662,
      -32.308,
      6.54,
      -40.662,
      -32.308,
      2.07,
      -40.282,
      -33.655,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2169",
    polygon: [
      -39.871,
      -35.113,
      2.07,
      -39.871,
      -35.113,
      6.54,
      -40.251,
      -33.765,
      6.54,
      -40.251,
      -33.765,
      6.54,
      -40.251,
      -33.765,
      2.07,
      -39.871,
      -35.113,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "EG_2170",
    polygon: [
      -38.636,
      -39.497,
      2.07,
      -38.636,
      -39.497,
      6.54,
      -39.016,
      -38.15,
      6.54,
      -39.016,
      -38.15,
      6.54,
      -39.016,
      -38.15,
      2.07,
      -38.636,
      -39.497,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "EG_2171",
    polygon: [
      -43.161,
      -23.436,
      2.064,
      -43.161,
      -23.436,
      4.454,
      -43.545,
      -22.071,
      4.454,
      -43.545,
      -22.071,
      4.454,
      -43.545,
      -22.071,
      2.064,
      -43.161,
      -23.436,
      2.064,
    ],
    "04-15 02:05": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
  },
  {
    ID: "EG_2172",
    polygon: [
      -42.749,
      -24.897,
      2.064,
      -42.749,
      -24.897,
      4.454,
      -43.134,
      -23.532,
      4.454,
      -43.134,
      -23.532,
      4.454,
      -43.134,
      -23.532,
      2.064,
      -42.749,
      -24.897,
      2.064,
    ],
    "04-15 02:05": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
  },
  {
    ID: "EG_2173",
    polygon: [
      -43.161,
      -23.436,
      4.515,
      -43.161,
      -23.436,
      6.485,
      -43.545,
      -22.071,
      6.485,
      -43.545,
      -22.071,
      6.485,
      -43.545,
      -22.071,
      4.515,
      -43.161,
      -23.436,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "EG_2174",
    polygon: [
      -42.749,
      -24.897,
      4.515,
      -42.749,
      -24.897,
      6.485,
      -43.134,
      -23.532,
      6.485,
      -43.134,
      -23.532,
      6.485,
      -43.134,
      -23.532,
      4.515,
      -42.749,
      -24.897,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "EG_2175",
    polygon: [
      -39.457,
      -36.586,
      2.064,
      -39.457,
      -36.586,
      4.454,
      -39.841,
      -35.221,
      4.454,
      -39.841,
      -35.221,
      4.454,
      -39.841,
      -35.221,
      2.064,
      -39.457,
      -36.586,
      2.064,
    ],
    "04-15 02:05": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
  },
  {
    ID: "EG_2176",
    polygon: [
      -39.045,
      -38.047,
      2.064,
      -39.045,
      -38.047,
      4.454,
      -39.429,
      -36.682,
      4.454,
      -39.429,
      -36.682,
      4.454,
      -39.429,
      -36.682,
      2.064,
      -39.045,
      -38.047,
      2.064,
    ],
    "04-15 02:05": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
  },
  {
    ID: "EG_2177",
    polygon: [
      -39.457,
      -36.586,
      4.515,
      -39.457,
      -36.586,
      6.485,
      -39.841,
      -35.221,
      6.485,
      -39.841,
      -35.221,
      6.485,
      -39.841,
      -35.221,
      4.515,
      -39.457,
      -36.586,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2178",
    polygon: [
      -39.045,
      -38.047,
      4.515,
      -39.045,
      -38.047,
      6.485,
      -39.429,
      -36.682,
      6.485,
      -39.429,
      -36.682,
      6.485,
      -39.429,
      -36.682,
      4.515,
      -39.045,
      -38.047,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2179",
    polygon: [
      -108.476,
      -37.751,
      2.07,
      -108.476,
      -37.751,
      6.54,
      -108.856,
      -36.403,
      6.54,
      -108.856,
      -36.403,
      6.54,
      -108.856,
      -36.403,
      2.07,
      -108.476,
      -37.751,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
  },
  {
    ID: "EG_2181",
    polygon: [
      -106.036,
      -46.414,
      2.07,
      -106.036,
      -46.414,
      6.54,
      -106.415,
      -45.066,
      6.54,
      -106.415,
      -45.066,
      6.54,
      -106.415,
      -45.066,
      2.07,
      -106.036,
      -46.414,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
  },
  {
    ID: "EG_2182",
    polygon: [
      -105.823,
      -47.169,
      2.07,
      -105.823,
      -47.169,
      6.54,
      -106.009,
      -46.51,
      6.54,
      -106.009,
      -46.51,
      6.54,
      -106.009,
      -46.51,
      2.07,
      -105.823,
      -47.169,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "EG_2183",
    polygon: [
      -108.883,
      -36.307,
      2.07,
      -108.883,
      -36.307,
      6.54,
      -109.068,
      -35.648,
      6.54,
      -109.068,
      -35.648,
      6.54,
      -109.068,
      -35.648,
      2.07,
      -108.883,
      -36.307,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "EG_2184",
    polygon: [
      -107.663,
      -40.638,
      2.07,
      -107.663,
      -40.638,
      4.465,
      -108.042,
      -39.291,
      4.465,
      -108.042,
      -39.291,
      4.465,
      -108.042,
      -39.291,
      2.07,
      -107.663,
      -40.638,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "EG_2185",
    polygon: [
      -107.663,
      -40.638,
      4.515,
      -107.663,
      -40.638,
      6.54,
      -108.042,
      -39.291,
      6.54,
      -108.042,
      -39.291,
      6.54,
      -108.042,
      -39.291,
      4.515,
      -107.663,
      -40.638,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "EG_2186",
    polygon: [
      -107.256,
      -42.082,
      2.07,
      -107.256,
      -42.082,
      4.465,
      -107.636,
      -40.735,
      4.465,
      -107.636,
      -40.735,
      4.465,
      -107.636,
      -40.735,
      2.07,
      -107.256,
      -42.082,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "EG_2187",
    polygon: [
      -107.256,
      -42.082,
      4.515,
      -107.256,
      -42.082,
      6.54,
      -107.636,
      -40.735,
      6.54,
      -107.636,
      -40.735,
      6.54,
      -107.636,
      -40.735,
      4.515,
      -107.256,
      -42.082,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "EG_2188",
    polygon: [
      -106.849,
      -43.526,
      2.07,
      -106.849,
      -43.526,
      4.465,
      -107.229,
      -42.178,
      4.465,
      -107.229,
      -42.178,
      4.465,
      -107.229,
      -42.178,
      2.07,
      -106.849,
      -43.526,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "EG_2189",
    polygon: [
      -106.849,
      -43.526,
      4.515,
      -106.849,
      -43.526,
      6.54,
      -107.229,
      -42.178,
      6.54,
      -107.229,
      -42.178,
      6.54,
      -107.229,
      -42.178,
      4.515,
      -106.849,
      -43.526,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "EG_2190",
    polygon: [
      -106.443,
      -44.97,
      2.07,
      -106.443,
      -44.97,
      4.465,
      -106.822,
      -43.622,
      4.465,
      -106.822,
      -43.622,
      4.465,
      -106.822,
      -43.622,
      2.07,
      -106.443,
      -44.97,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "EG_2191",
    polygon: [
      -106.443,
      -44.97,
      4.515,
      -106.443,
      -44.97,
      6.54,
      -106.822,
      -43.622,
      6.54,
      -106.822,
      -43.622,
      6.54,
      -106.822,
      -43.622,
      4.515,
      -106.443,
      -44.97,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "EG_2192",
    polygon: [
      -108.07,
      -39.193,
      2.07,
      -108.07,
      -39.193,
      5.13,
      -108.449,
      -37.845,
      5.13,
      -108.449,
      -37.845,
      5.13,
      -108.449,
      -37.845,
      2.07,
      -108.07,
      -39.193,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "EG_2193",
    polygon: [
      -108.07,
      -39.193,
      5.19,
      -108.07,
      -39.193,
      6.54,
      -108.449,
      -37.845,
      6.54,
      -108.449,
      -37.845,
      6.54,
      -108.449,
      -37.845,
      5.19,
      -108.07,
      -39.193,
      5.19,
    ],
    "04-15 02:05": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "EG_2194",
    polygon: [
      -111.221,
      -44.861,
      2.0,
      -111.221,
      -44.861,
      4.395,
      -110.841,
      -46.209,
      4.395,
      -110.841,
      -46.209,
      4.395,
      -110.841,
      -46.209,
      2.0,
      -111.221,
      -44.861,
      2.0,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2195",
    polygon: [
      -111.221,
      -44.861,
      4.445,
      -111.221,
      -44.861,
      6.47,
      -110.841,
      -46.209,
      6.47,
      -110.841,
      -46.209,
      6.47,
      -110.841,
      -46.209,
      4.445,
      -111.221,
      -44.861,
      4.445,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2196",
    polygon: [
      -111.627,
      -43.417,
      2.0,
      -111.627,
      -43.417,
      4.395,
      -111.248,
      -44.765,
      4.395,
      -111.248,
      -44.765,
      4.395,
      -111.248,
      -44.765,
      2.0,
      -111.627,
      -43.417,
      2.0,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2197",
    polygon: [
      -111.627,
      -43.417,
      4.445,
      -111.627,
      -43.417,
      6.47,
      -111.248,
      -44.765,
      6.47,
      -111.248,
      -44.765,
      6.47,
      -111.248,
      -44.765,
      4.445,
      -111.627,
      -43.417,
      4.445,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2198",
    polygon: [
      -112.034,
      -41.974,
      2.0,
      -112.034,
      -41.974,
      4.395,
      -111.654,
      -43.321,
      4.395,
      -111.654,
      -43.321,
      4.395,
      -111.654,
      -43.321,
      2.0,
      -112.034,
      -41.974,
      2.0,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2199",
    polygon: [
      -112.034,
      -41.974,
      4.445,
      -112.034,
      -41.974,
      6.47,
      -111.654,
      -43.321,
      6.47,
      -111.654,
      -43.321,
      6.47,
      -111.654,
      -43.321,
      4.445,
      -112.034,
      -41.974,
      4.445,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2200",
    polygon: [
      -112.441,
      -40.53,
      2.0,
      -112.441,
      -40.53,
      4.395,
      -112.061,
      -41.877,
      4.395,
      -112.061,
      -41.877,
      4.395,
      -112.061,
      -41.877,
      2.0,
      -112.441,
      -40.53,
      2.0,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2201",
    polygon: [
      -112.441,
      -40.53,
      4.445,
      -112.441,
      -40.53,
      6.47,
      -112.061,
      -41.877,
      6.47,
      -112.061,
      -41.877,
      6.47,
      -112.061,
      -41.877,
      4.445,
      -112.441,
      -40.53,
      4.445,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2202",
    polygon: [
      -112.847,
      -39.086,
      2.0,
      -112.847,
      -39.086,
      4.395,
      -112.468,
      -40.434,
      4.395,
      -112.468,
      -40.434,
      4.395,
      -112.468,
      -40.434,
      2.0,
      -112.847,
      -39.086,
      2.0,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2203",
    polygon: [
      -112.847,
      -39.086,
      4.445,
      -112.847,
      -39.086,
      6.47,
      -112.468,
      -40.434,
      6.47,
      -112.468,
      -40.434,
      6.47,
      -112.468,
      -40.434,
      4.445,
      -112.847,
      -39.086,
      4.445,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2204",
    polygon: [
      -110.814,
      -46.305,
      2.0,
      -110.814,
      -46.305,
      6.47,
      -110.434,
      -47.653,
      6.47,
      -110.434,
      -47.653,
      6.47,
      -110.434,
      -47.653,
      2.0,
      -110.814,
      -46.305,
      2.0,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2205",
    polygon: [
      -113.254,
      -37.642,
      2.0,
      -113.254,
      -37.642,
      6.47,
      -112.875,
      -38.99,
      6.47,
      -112.875,
      -38.99,
      6.47,
      -112.875,
      -38.99,
      2.0,
      -113.254,
      -37.642,
      2.0,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2206",
    polygon: [
      -110.41,
      -47.739,
      2.0,
      -110.41,
      -47.739,
      6.47,
      -110.224,
      -48.399,
      6.47,
      -110.224,
      -48.399,
      6.47,
      -110.224,
      -48.399,
      2.0,
      -110.41,
      -47.739,
      2.0,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2207",
    polygon: [
      -113.47,
      -36.877,
      2.0,
      -113.47,
      -36.877,
      6.47,
      -113.284,
      -37.536,
      6.47,
      -113.284,
      -37.536,
      6.47,
      -113.284,
      -37.536,
      2.0,
      -113.47,
      -36.877,
      2.0,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2208",
    polygon: [
      -109.402,
      -35.461,
      2.07,
      -109.402,
      -35.461,
      6.54,
      -109.948,
      -35.615,
      6.54,
      -109.948,
      -35.615,
      6.54,
      -109.948,
      -35.615,
      2.07,
      -109.402,
      -35.461,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "EG_2209",
    polygon: [
      -112.739,
      -36.401,
      2.07,
      -112.739,
      -36.401,
      6.54,
      -113.285,
      -36.555,
      6.54,
      -113.285,
      -36.555,
      6.54,
      -113.285,
      -36.555,
      2.07,
      -112.739,
      -36.401,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:05": 1,
  },
  {
    ID: "EG_2210",
    polygon: [
      -111.39,
      -36.021,
      2.07,
      -111.39,
      -36.021,
      4.465,
      -112.642,
      -36.374,
      4.465,
      -112.642,
      -36.374,
      4.465,
      -112.642,
      -36.374,
      2.07,
      -111.39,
      -36.021,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
  },
  {
    ID: "EG_2211",
    polygon: [
      -110.044,
      -35.642,
      2.07,
      -110.044,
      -35.642,
      4.465,
      -111.295,
      -35.994,
      4.465,
      -111.295,
      -35.994,
      4.465,
      -111.295,
      -35.994,
      2.07,
      -110.044,
      -35.642,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
  },
  {
    ID: "EG_2212",
    polygon: [
      -111.39,
      -36.021,
      4.515,
      -111.39,
      -36.021,
      6.54,
      -112.642,
      -36.374,
      6.54,
      -112.642,
      -36.374,
      6.54,
      -112.642,
      -36.374,
      4.515,
      -111.39,
      -36.021,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
  },
  {
    ID: "EG_2213",
    polygon: [
      -110.044,
      -35.642,
      4.515,
      -110.044,
      -35.642,
      6.54,
      -111.295,
      -35.994,
      6.54,
      -111.295,
      -35.994,
      6.54,
      -111.295,
      -35.994,
      4.515,
      -110.044,
      -35.642,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
  },
  {
    ID: "EG_2214",
    polygon: [
      -106.556,
      -47.656,
      2.07,
      -106.556,
      -47.656,
      6.54,
      -106.01,
      -47.502,
      6.54,
      -106.01,
      -47.502,
      6.54,
      -106.01,
      -47.502,
      2.07,
      -106.556,
      -47.656,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2215",
    polygon: [
      -107.903,
      -48.036,
      2.07,
      -107.903,
      -48.036,
      4.465,
      -106.652,
      -47.683,
      4.465,
      -106.652,
      -47.683,
      4.465,
      -106.652,
      -47.683,
      2.07,
      -107.903,
      -48.036,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2216",
    polygon: [
      -109.25,
      -48.415,
      4.515,
      -109.25,
      -48.415,
      6.54,
      -107.999,
      -48.063,
      6.54,
      -107.999,
      -48.063,
      6.54,
      -107.999,
      -48.063,
      4.515,
      -109.25,
      -48.415,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2217",
    polygon: [
      -107.903,
      -48.036,
      4.515,
      -107.903,
      -48.036,
      6.54,
      -106.652,
      -47.683,
      6.54,
      -106.652,
      -47.683,
      6.54,
      -106.652,
      -47.683,
      4.515,
      -107.903,
      -48.036,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2218",
    polygon: [
      -109.888,
      -48.595,
      2.07,
      -109.888,
      -48.595,
      6.54,
      -109.342,
      -48.441,
      6.54,
      -109.342,
      -48.441,
      6.54,
      -109.342,
      -48.441,
      2.07,
      -109.888,
      -48.595,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2219",
    polygon: [
      -109.251,
      -48.415,
      2.07,
      -109.251,
      -48.415,
      4.465,
      -108.0,
      -48.063,
      4.465,
      -108.0,
      -48.063,
      4.465,
      -108.0,
      -48.063,
      2.07,
      -109.251,
      -48.415,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2220",
    polygon: [
      -79.619,
      -41.903,
      2.07,
      -79.619,
      -41.903,
      6.54,
      -79.253,
      -43.203,
      6.54,
      -79.253,
      -43.203,
      6.54,
      -79.253,
      -43.203,
      2.07,
      -79.619,
      -41.903,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2221",
    polygon: [
      -84.337,
      -25.154,
      2.07,
      -84.337,
      -25.154,
      6.54,
      -83.971,
      -26.453,
      6.54,
      -83.971,
      -26.453,
      6.54,
      -83.971,
      -26.453,
      2.07,
      -84.337,
      -25.154,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 09:10": 1,
    "04-15 09:20": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "EG_2222",
    polygon: [
      -84.532,
      -24.46,
      2.07,
      -84.532,
      -24.46,
      6.54,
      -84.364,
      -25.057,
      6.54,
      -84.364,
      -25.057,
      6.54,
      -84.364,
      -25.057,
      2.07,
      -84.532,
      -24.46,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
  },
  {
    ID: "EG_2223",
    polygon: [
      -79.226,
      -43.297,
      2.07,
      -79.226,
      -43.297,
      6.54,
      -79.058,
      -43.894,
      6.54,
      -79.058,
      -43.894,
      6.54,
      -79.058,
      -43.894,
      2.07,
      -79.226,
      -43.297,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2224",
    polygon: [
      -80.012,
      -40.507,
      2.12,
      -80.012,
      -40.507,
      4.465,
      -79.646,
      -41.806,
      4.465,
      -79.646,
      -41.806,
      4.465,
      -79.646,
      -41.806,
      2.12,
      -80.012,
      -40.507,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2225",
    polygon: [
      -80.012,
      -40.507,
      4.515,
      -80.012,
      -40.507,
      6.54,
      -79.646,
      -41.806,
      6.54,
      -79.646,
      -41.806,
      6.54,
      -79.646,
      -41.806,
      4.515,
      -80.012,
      -40.507,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2226",
    polygon: [
      -80.405,
      -39.111,
      2.12,
      -80.405,
      -39.111,
      4.465,
      -80.039,
      -40.41,
      4.465,
      -80.039,
      -40.41,
      4.465,
      -80.039,
      -40.41,
      2.12,
      -80.405,
      -39.111,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2227",
    polygon: [
      -80.405,
      -39.111,
      4.515,
      -80.405,
      -39.111,
      6.54,
      -80.039,
      -40.41,
      6.54,
      -80.039,
      -40.41,
      6.54,
      -80.039,
      -40.41,
      4.515,
      -80.405,
      -39.111,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2228",
    polygon: [
      -80.799,
      -37.715,
      2.12,
      -80.799,
      -37.715,
      4.465,
      -80.433,
      -39.015,
      4.465,
      -80.433,
      -39.015,
      4.465,
      -80.433,
      -39.015,
      2.12,
      -80.799,
      -37.715,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2229",
    polygon: [
      -80.799,
      -37.715,
      4.515,
      -80.799,
      -37.715,
      6.54,
      -80.433,
      -39.015,
      6.54,
      -80.433,
      -39.015,
      6.54,
      -80.433,
      -39.015,
      4.515,
      -80.799,
      -37.715,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2230",
    polygon: [
      -81.192,
      -36.32,
      2.12,
      -81.192,
      -36.32,
      4.465,
      -80.826,
      -37.619,
      4.465,
      -80.826,
      -37.619,
      4.465,
      -80.826,
      -37.619,
      2.12,
      -81.192,
      -36.32,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2231",
    polygon: [
      -81.192,
      -36.32,
      4.515,
      -81.192,
      -36.32,
      6.54,
      -80.826,
      -37.619,
      6.54,
      -80.826,
      -37.619,
      6.54,
      -80.826,
      -37.619,
      4.515,
      -81.192,
      -36.32,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2232",
    polygon: [
      -81.585,
      -34.924,
      2.12,
      -81.585,
      -34.924,
      4.465,
      -81.219,
      -36.223,
      4.465,
      -81.219,
      -36.223,
      4.465,
      -81.219,
      -36.223,
      2.12,
      -81.585,
      -34.924,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2233",
    polygon: [
      -81.585,
      -34.924,
      4.515,
      -81.585,
      -34.924,
      6.54,
      -81.219,
      -36.223,
      6.54,
      -81.219,
      -36.223,
      6.54,
      -81.219,
      -36.223,
      4.515,
      -81.585,
      -34.924,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2234",
    polygon: [
      -81.978,
      -33.528,
      2.12,
      -81.978,
      -33.528,
      4.465,
      -81.612,
      -34.828,
      4.465,
      -81.612,
      -34.828,
      4.465,
      -81.612,
      -34.828,
      2.12,
      -81.978,
      -33.528,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2235",
    polygon: [
      -81.978,
      -33.528,
      4.515,
      -81.978,
      -33.528,
      6.54,
      -81.612,
      -34.828,
      6.54,
      -81.612,
      -34.828,
      6.54,
      -81.612,
      -34.828,
      4.515,
      -81.978,
      -33.528,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "EG_2236",
    polygon: [
      -82.371,
      -32.132,
      2.12,
      -82.371,
      -32.132,
      4.465,
      -82.005,
      -33.432,
      4.465,
      -82.005,
      -33.432,
      4.465,
      -82.005,
      -33.432,
      2.12,
      -82.371,
      -32.132,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2237",
    polygon: [
      -82.371,
      -32.132,
      4.515,
      -82.371,
      -32.132,
      6.54,
      -82.005,
      -33.432,
      6.54,
      -82.005,
      -33.432,
      6.54,
      -82.005,
      -33.432,
      4.515,
      -82.371,
      -32.132,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
  },
  {
    ID: "EG_2238",
    polygon: [
      -82.764,
      -30.737,
      2.12,
      -82.764,
      -30.737,
      4.465,
      -82.398,
      -32.036,
      4.465,
      -82.398,
      -32.036,
      4.465,
      -82.398,
      -32.036,
      2.12,
      -82.764,
      -30.737,
      2.12,
    ],
    "04-15 02:05": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "EG_2239",
    polygon: [
      -82.764,
      -30.737,
      4.515,
      -82.764,
      -30.737,
      6.54,
      -82.398,
      -32.036,
      6.54,
      -82.398,
      -32.036,
      6.54,
      -82.398,
      -32.036,
      4.515,
      -82.764,
      -30.737,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
  },
  {
    ID: "EG_2240",
    polygon: [
      -83.157,
      -29.341,
      2.12,
      -83.157,
      -29.341,
      4.465,
      -82.791,
      -30.641,
      4.465,
      -82.791,
      -30.641,
      4.465,
      -82.791,
      -30.641,
      2.12,
      -83.157,
      -29.341,
      2.12,
    ],
    "04-15 02:05": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
  },
  {
    ID: "EG_2241",
    polygon: [
      -83.157,
      -29.341,
      4.515,
      -83.157,
      -29.341,
      6.54,
      -82.791,
      -30.641,
      6.54,
      -82.791,
      -30.641,
      6.54,
      -82.791,
      -30.641,
      4.515,
      -83.157,
      -29.341,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "EG_2242",
    polygon: [
      -83.551,
      -27.945,
      2.12,
      -83.551,
      -27.945,
      4.465,
      -83.185,
      -29.245,
      4.465,
      -83.185,
      -29.245,
      4.465,
      -83.185,
      -29.245,
      2.12,
      -83.551,
      -27.945,
      2.12,
    ],
    "04-15 02:05": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "EG_2243",
    polygon: [
      -83.551,
      -27.945,
      4.515,
      -83.551,
      -27.945,
      6.54,
      -83.185,
      -29.245,
      6.54,
      -83.185,
      -29.245,
      6.54,
      -83.185,
      -29.245,
      4.515,
      -83.551,
      -27.945,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "EG_2244",
    polygon: [
      -83.944,
      -26.55,
      2.12,
      -83.944,
      -26.55,
      4.465,
      -83.578,
      -27.849,
      4.465,
      -83.578,
      -27.849,
      4.465,
      -83.578,
      -27.849,
      2.12,
      -83.944,
      -26.55,
      2.12,
    ],
    "04-15 02:05": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
  },
  {
    ID: "EG_2245",
    polygon: [
      -83.944,
      -26.55,
      4.515,
      -83.944,
      -26.55,
      6.54,
      -83.578,
      -27.849,
      6.54,
      -83.578,
      -27.849,
      6.54,
      -83.578,
      -27.849,
      4.515,
      -83.944,
      -26.55,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 09:35": 1,
  },
  {
    ID: "EG_2246",
    polygon: [
      -74.844,
      -41.961,
      2.07,
      -74.844,
      -41.961,
      6.54,
      -75.21,
      -40.661,
      6.54,
      -75.21,
      -40.661,
      6.54,
      -75.21,
      -40.661,
      2.07,
      -74.844,
      -41.961,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2247",
    polygon: [
      -79.562,
      -25.212,
      2.07,
      -79.562,
      -25.212,
      6.54,
      -79.928,
      -23.912,
      6.54,
      -79.928,
      -23.912,
      6.54,
      -79.928,
      -23.912,
      2.07,
      -79.562,
      -25.212,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "EG_2248",
    polygon: [
      -79.955,
      -23.815,
      2.07,
      -79.955,
      -23.815,
      6.54,
      -80.124,
      -23.219,
      6.54,
      -80.124,
      -23.219,
      6.54,
      -80.124,
      -23.219,
      2.07,
      -79.955,
      -23.815,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2249",
    polygon: [
      -74.649,
      -42.652,
      2.07,
      -74.649,
      -42.652,
      6.54,
      -74.817,
      -42.056,
      6.54,
      -74.817,
      -42.056,
      6.54,
      -74.817,
      -42.056,
      2.07,
      -74.649,
      -42.652,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2250",
    polygon: [
      -75.238,
      -40.564,
      2.12,
      -75.238,
      -40.564,
      4.465,
      -75.604,
      -39.265,
      4.465,
      -75.604,
      -39.265,
      4.465,
      -75.604,
      -39.265,
      2.12,
      -75.238,
      -40.564,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2251",
    polygon: [
      -75.238,
      -40.564,
      4.515,
      -75.238,
      -40.564,
      6.54,
      -75.604,
      -39.265,
      6.54,
      -75.604,
      -39.265,
      6.54,
      -75.604,
      -39.265,
      4.515,
      -75.238,
      -40.564,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2252",
    polygon: [
      -75.631,
      -39.169,
      2.12,
      -75.631,
      -39.169,
      4.465,
      -75.997,
      -37.869,
      4.465,
      -75.997,
      -37.869,
      4.465,
      -75.997,
      -37.869,
      2.12,
      -75.631,
      -39.169,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2253",
    polygon: [
      -75.631,
      -39.169,
      4.515,
      -75.631,
      -39.169,
      6.54,
      -75.997,
      -37.869,
      6.54,
      -75.997,
      -37.869,
      6.54,
      -75.997,
      -37.869,
      4.515,
      -75.631,
      -39.169,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2254",
    polygon: [
      -76.024,
      -37.773,
      2.12,
      -76.024,
      -37.773,
      4.465,
      -76.39,
      -36.473,
      4.465,
      -76.39,
      -36.473,
      4.465,
      -76.39,
      -36.473,
      2.12,
      -76.024,
      -37.773,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2255",
    polygon: [
      -76.024,
      -37.773,
      4.515,
      -76.024,
      -37.773,
      6.54,
      -76.39,
      -36.473,
      6.54,
      -76.39,
      -36.473,
      6.54,
      -76.39,
      -36.473,
      4.515,
      -76.024,
      -37.773,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2256",
    polygon: [
      -76.417,
      -36.377,
      2.12,
      -76.417,
      -36.377,
      4.465,
      -76.783,
      -35.078,
      4.465,
      -76.783,
      -35.078,
      4.465,
      -76.783,
      -35.078,
      2.12,
      -76.417,
      -36.377,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2257",
    polygon: [
      -76.417,
      -36.377,
      4.515,
      -76.417,
      -36.377,
      6.54,
      -76.783,
      -35.078,
      6.54,
      -76.783,
      -35.078,
      6.54,
      -76.783,
      -35.078,
      4.515,
      -76.417,
      -36.377,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2258",
    polygon: [
      -76.81,
      -34.981,
      2.12,
      -76.81,
      -34.981,
      4.465,
      -77.176,
      -33.682,
      4.465,
      -77.176,
      -33.682,
      4.465,
      -77.176,
      -33.682,
      2.12,
      -76.81,
      -34.981,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2259",
    polygon: [
      -76.81,
      -34.981,
      4.515,
      -76.81,
      -34.981,
      6.54,
      -77.176,
      -33.682,
      6.54,
      -77.176,
      -33.682,
      6.54,
      -77.176,
      -33.682,
      4.515,
      -76.81,
      -34.981,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2260",
    polygon: [
      -77.203,
      -33.586,
      2.12,
      -77.203,
      -33.586,
      4.465,
      -77.569,
      -32.286,
      4.465,
      -77.569,
      -32.286,
      4.465,
      -77.569,
      -32.286,
      2.12,
      -77.203,
      -33.586,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2261",
    polygon: [
      -77.203,
      -33.586,
      4.515,
      -77.203,
      -33.586,
      6.54,
      -77.569,
      -32.286,
      6.54,
      -77.569,
      -32.286,
      6.54,
      -77.569,
      -32.286,
      4.515,
      -77.203,
      -33.586,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2262",
    polygon: [
      -77.596,
      -32.19,
      2.12,
      -77.596,
      -32.19,
      4.465,
      -77.962,
      -30.891,
      4.465,
      -77.962,
      -30.891,
      4.465,
      -77.962,
      -30.891,
      2.12,
      -77.596,
      -32.19,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2263",
    polygon: [
      -77.596,
      -32.19,
      4.515,
      -77.596,
      -32.19,
      6.54,
      -77.962,
      -30.891,
      6.54,
      -77.962,
      -30.891,
      6.54,
      -77.962,
      -30.891,
      4.515,
      -77.596,
      -32.19,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2264",
    polygon: [
      -77.99,
      -30.794,
      2.12,
      -77.99,
      -30.794,
      4.465,
      -78.356,
      -29.495,
      4.465,
      -78.356,
      -29.495,
      4.465,
      -78.356,
      -29.495,
      2.12,
      -77.99,
      -30.794,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2265",
    polygon: [
      -77.99,
      -30.794,
      4.515,
      -77.99,
      -30.794,
      6.54,
      -78.356,
      -29.495,
      6.54,
      -78.356,
      -29.495,
      6.54,
      -78.356,
      -29.495,
      4.515,
      -77.99,
      -30.794,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2266",
    polygon: [
      -78.383,
      -29.399,
      2.12,
      -78.383,
      -29.399,
      4.465,
      -78.749,
      -28.099,
      4.465,
      -78.749,
      -28.099,
      4.465,
      -78.749,
      -28.099,
      2.12,
      -78.383,
      -29.399,
      2.12,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2267",
    polygon: [
      -78.383,
      -29.399,
      4.515,
      -78.383,
      -29.399,
      6.54,
      -78.749,
      -28.099,
      6.54,
      -78.749,
      -28.099,
      6.54,
      -78.749,
      -28.099,
      4.515,
      -78.383,
      -29.399,
      4.515,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2268",
    polygon: [
      -78.776,
      -28.003,
      2.12,
      -78.776,
      -28.003,
      4.465,
      -79.142,
      -26.703,
      4.465,
      -79.142,
      -26.703,
      4.465,
      -79.142,
      -26.703,
      2.12,
      -78.776,
      -28.003,
      2.12,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
  },
  {
    ID: "EG_2269",
    polygon: [
      -78.776,
      -28.003,
      4.515,
      -78.776,
      -28.003,
      6.54,
      -79.142,
      -26.703,
      6.54,
      -79.142,
      -26.703,
      6.54,
      -79.142,
      -26.703,
      4.515,
      -78.776,
      -28.003,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 11:55": 1,
  },
  {
    ID: "EG_2270",
    polygon: [
      -79.169,
      -26.607,
      2.12,
      -79.169,
      -26.607,
      4.465,
      -79.535,
      -25.308,
      4.465,
      -79.535,
      -25.308,
      4.465,
      -79.535,
      -25.308,
      2.12,
      -79.169,
      -26.607,
      2.12,
    ],
    "04-15 02:05": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "EG_2271",
    polygon: [
      -79.169,
      -26.607,
      4.515,
      -79.169,
      -26.607,
      6.54,
      -79.535,
      -25.308,
      6.54,
      -79.535,
      -25.308,
      6.54,
      -79.535,
      -25.308,
      4.515,
      -79.169,
      -26.607,
      4.515,
    ],
    "04-15 02:05": 1,
    "04-15 11:55": 1,
  },
  {
    ID: "EG_2272",
    polygon: [
      -82.404,
      -23.794,
      2.07,
      -82.404,
      -23.794,
      4.465,
      -83.655,
      -24.146,
      4.465,
      -83.655,
      -24.146,
      4.465,
      -83.655,
      -24.146,
      2.07,
      -82.404,
      -23.794,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
  },
  {
    ID: "EG_2273",
    polygon: [
      -82.402,
      -23.794,
      4.518,
      -82.402,
      -23.794,
      6.543,
      -83.654,
      -24.146,
      6.543,
      -83.654,
      -24.146,
      6.543,
      -83.654,
      -24.146,
      4.518,
      -82.402,
      -23.794,
      4.518,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
  },
  {
    ID: "EG_2274",
    polygon: [
      -81.056,
      -23.414,
      2.07,
      -81.056,
      -23.414,
      4.465,
      -82.307,
      -23.767,
      4.465,
      -82.307,
      -23.767,
      4.465,
      -82.307,
      -23.767,
      2.07,
      -81.056,
      -23.414,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
  },
  {
    ID: "EG_2275",
    polygon: [
      -81.054,
      -23.414,
      4.518,
      -81.054,
      -23.414,
      6.543,
      -82.306,
      -23.766,
      6.543,
      -82.306,
      -23.766,
      6.543,
      -82.306,
      -23.766,
      4.518,
      -81.054,
      -23.414,
      4.518,
    ],
    "04-15 02:05": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
  },
  {
    ID: "EG_2276",
    polygon: [
      -80.413,
      -23.233,
      2.07,
      -80.413,
      -23.233,
      6.54,
      -80.959,
      -23.387,
      6.54,
      -80.959,
      -23.387,
      6.54,
      -80.959,
      -23.387,
      2.07,
      -80.413,
      -23.233,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
  },
  {
    ID: "EG_2277",
    polygon: [
      -83.751,
      -24.173,
      2.07,
      -83.751,
      -24.173,
      6.54,
      -84.297,
      -24.327,
      6.54,
      -84.297,
      -24.327,
      6.54,
      -84.297,
      -24.327,
      2.07,
      -83.751,
      -24.173,
      2.07,
    ],
    "04-15 02:05": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
  },
  {
    ID: "EG_2278",
    polygon: [
      -78.143,
      -43.715,
      2.07,
      -78.143,
      -43.715,
      4.465,
      -76.891,
      -43.362,
      4.465,
      -76.891,
      -43.362,
      4.465,
      -76.891,
      -43.362,
      2.07,
      -78.143,
      -43.715,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2279",
    polygon: [
      -78.142,
      -43.715,
      4.518,
      -78.142,
      -43.715,
      6.543,
      -76.89,
      -43.362,
      6.543,
      -76.89,
      -43.362,
      6.543,
      -76.89,
      -43.362,
      4.518,
      -78.142,
      -43.715,
      4.518,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2280",
    polygon: [
      -76.795,
      -43.335,
      2.07,
      -76.795,
      -43.335,
      4.465,
      -75.544,
      -42.983,
      4.465,
      -75.544,
      -42.983,
      4.465,
      -75.544,
      -42.983,
      2.07,
      -76.795,
      -43.335,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2281",
    polygon: [
      -76.794,
      -43.335,
      4.518,
      -76.794,
      -43.335,
      6.543,
      -75.542,
      -42.982,
      6.543,
      -75.542,
      -42.982,
      6.543,
      -75.542,
      -42.982,
      4.518,
      -76.794,
      -43.335,
      4.518,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2282",
    polygon: [
      -75.447,
      -42.955,
      2.07,
      -75.447,
      -42.955,
      6.54,
      -74.901,
      -42.802,
      6.54,
      -74.901,
      -42.802,
      6.54,
      -74.901,
      -42.802,
      2.07,
      -75.447,
      -42.955,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2283",
    polygon: [
      -78.784,
      -43.896,
      2.07,
      -78.784,
      -43.896,
      6.54,
      -78.239,
      -43.742,
      6.54,
      -78.239,
      -43.742,
      6.54,
      -78.239,
      -43.742,
      2.07,
      -78.784,
      -43.896,
      2.07,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2351",
    polygon: [
      10.824,
      -56.159,
      4.51,
      10.824,
      -56.159,
      6.54,
      11.217,
      -57.555,
      6.54,
      11.217,
      -57.555,
      6.54,
      11.217,
      -57.555,
      4.51,
      10.824,
      -56.159,
      4.51,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
  },
  {
    ID: "EG_2352",
    polygon: [
      4.284,
      -52.952,
      4.508,
      4.284,
      -52.952,
      6.538,
      5.757,
      -52.537,
      6.538,
      5.757,
      -52.537,
      6.538,
      5.757,
      -52.537,
      4.508,
      4.284,
      -52.952,
      4.508,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "EG_2354",
    polygon: [
      -114.122,
      -70.337,
      2.176,
      -114.122,
      -70.337,
      6.546,
      -115.469,
      -70.717,
      6.546,
      -115.469,
      -70.717,
      6.546,
      -115.469,
      -70.717,
      2.176,
      -114.122,
      -70.337,
      2.176,
    ],
    "04-15 02:05": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2355",
    polygon: [
      -112.678,
      -69.93,
      2.176,
      -112.678,
      -69.93,
      6.546,
      -114.025,
      -70.31,
      6.546,
      -114.025,
      -70.31,
      6.546,
      -114.025,
      -70.31,
      2.176,
      -112.678,
      -69.93,
      2.176,
    ],
    "04-15 02:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2356",
    polygon: [
      -111.234,
      -69.524,
      2.176,
      -111.234,
      -69.524,
      6.546,
      -112.582,
      -69.903,
      6.546,
      -112.582,
      -69.903,
      6.546,
      -112.582,
      -69.903,
      2.176,
      -111.234,
      -69.524,
      2.176,
    ],
    "04-15 02:05": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2357",
    polygon: [
      -109.79,
      -69.117,
      2.176,
      -109.79,
      -69.117,
      6.546,
      -111.138,
      -69.497,
      6.546,
      -111.138,
      -69.497,
      6.546,
      -111.138,
      -69.497,
      2.176,
      -109.79,
      -69.117,
      2.176,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2358",
    polygon: [
      -108.345,
      -68.71,
      2.176,
      -108.345,
      -68.71,
      6.546,
      -109.693,
      -69.09,
      6.546,
      -109.693,
      -69.09,
      6.546,
      -109.693,
      -69.09,
      2.176,
      -108.345,
      -68.71,
      2.176,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2359",
    polygon: [
      -106.901,
      -68.303,
      2.075,
      -106.901,
      -68.303,
      6.545,
      -108.249,
      -68.683,
      6.545,
      -108.249,
      -68.683,
      6.545,
      -108.249,
      -68.683,
      2.075,
      -106.901,
      -68.303,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2360",
    polygon: [
      -105.458,
      -67.897,
      2.075,
      -105.458,
      -67.897,
      6.545,
      -106.805,
      -68.276,
      6.545,
      -106.805,
      -68.276,
      6.545,
      -106.805,
      -68.276,
      2.075,
      -105.458,
      -67.897,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2361",
    polygon: [
      -104.014,
      -67.49,
      2.075,
      -104.014,
      -67.49,
      6.545,
      -105.361,
      -67.869,
      6.545,
      -105.361,
      -67.869,
      6.545,
      -105.361,
      -67.869,
      2.075,
      -104.014,
      -67.49,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2362",
    polygon: [
      -102.565,
      -67.082,
      2.075,
      -102.565,
      -67.082,
      6.545,
      -103.913,
      -67.461,
      6.545,
      -103.913,
      -67.461,
      6.545,
      -103.913,
      -67.461,
      2.075,
      -102.565,
      -67.082,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2363",
    polygon: [
      -101.121,
      -66.675,
      2.075,
      -101.121,
      -66.675,
      6.545,
      -102.469,
      -67.055,
      6.545,
      -102.469,
      -67.055,
      6.545,
      -102.469,
      -67.055,
      2.075,
      -101.121,
      -66.675,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2364",
    polygon: [
      -99.678,
      -66.268,
      2.075,
      -99.678,
      -66.268,
      6.545,
      -101.025,
      -66.648,
      6.545,
      -101.025,
      -66.648,
      6.545,
      -101.025,
      -66.648,
      2.075,
      -99.678,
      -66.268,
      2.075,
    ],
    "04-15 02:05": 1,
    "04-15 11:40": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "EG_2365",
    polygon: [
      -115.565,
      -70.744,
      2.295,
      -115.565,
      -70.744,
      6.665,
      -116.335,
      -70.961,
      6.665,
      -116.335,
      -70.961,
      6.665,
      -116.335,
      -70.961,
      2.295,
      -115.565,
      -70.744,
      2.295,
    ],
    "04-15 02:05": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
  },
  {
    ID: "EG_2372",
    polygon: [
      -2.741,
      -71.736,
      2.0,
      -1.928,
      -74.624,
      2.0,
      3.077,
      -73.214,
      2.0,
      3.077,
      -73.214,
      2.0,
      2.264,
      -70.326,
      2.0,
      -2.741,
      -71.736,
      2.0,
    ],
    "04-15 02:05": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
  },
  {
    ID: "EG_2374",
    polygon: [
      2.505,
      -70.258,
      2.0,
      3.318,
      -73.146,
      2.0,
      8.372,
      -71.722,
      2.0,
      8.372,
      -71.722,
      2.0,
      7.558,
      -68.835,
      2.0,
      2.505,
      -70.258,
      2.0,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2375",
    polygon: [
      7.799,
      -68.767,
      2.0,
      8.612,
      -71.654,
      2.0,
      13.617,
      -70.244,
      2.0,
      13.617,
      -70.244,
      2.0,
      12.804,
      -67.357,
      2.0,
      7.799,
      -68.767,
      2.0,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2376",
    polygon: [
      13.045,
      -67.289,
      2.0,
      13.858,
      -70.177,
      2.0,
      16.269,
      -69.498,
      2.0,
      16.269,
      -69.498,
      2.0,
      15.456,
      -66.61,
      2.0,
      13.045,
      -67.289,
      2.0,
    ],
    "04-15 02:05": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2377",
    polygon: [
      15.696,
      -66.542,
      2.0,
      16.51,
      -69.43,
      2.0,
      17.66,
      -69.106,
      2.0,
      17.66,
      -69.106,
      2.0,
      16.847,
      -66.218,
      2.0,
      15.696,
      -66.542,
      2.0,
    ],
    "04-15 02:05": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 13:10": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2378",
    polygon: [
      17.087,
      -66.15,
      2.0,
      17.901,
      -69.038,
      2.0,
      21.703,
      -67.967,
      2.0,
      21.703,
      -67.967,
      2.0,
      20.889,
      -65.079,
      2.0,
      17.087,
      -66.15,
      2.0,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2379",
    polygon: [
      21.13,
      -65.012,
      2.0,
      21.943,
      -67.899,
      2.0,
      24.249,
      -67.25,
      2.0,
      24.249,
      -67.25,
      2.0,
      23.435,
      -64.362,
      2.0,
      21.13,
      -65.012,
      2.0,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2380",
    polygon: [
      23.676,
      -64.294,
      2.0,
      24.489,
      -67.182,
      2.0,
      27.184,
      -66.423,
      2.0,
      27.184,
      -66.423,
      2.0,
      26.371,
      -63.535,
      2.0,
      23.676,
      -64.294,
      2.0,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
  },
  {
    ID: "EG_2381",
    polygon: [
      -23.599,
      -83.294,
      2.491,
      -19.542,
      -82.151,
      2.491,
      -20.084,
      -80.225,
      2.491,
      -20.084,
      -80.225,
      2.491,
      -24.141,
      -81.368,
      2.491,
      -23.599,
      -83.294,
      2.491,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
  },
  {
    ID: "EG_2382",
    polygon: [
      -55.543,
      -92.337,
      2.51,
      -51.486,
      -91.194,
      2.51,
      -52.029,
      -89.269,
      2.51,
      -52.029,
      -89.269,
      2.51,
      -56.086,
      -90.411,
      2.51,
      -55.543,
      -92.337,
      2.51,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
  },
  {
    ID: "EG_2383",
    polygon: [
      -114.099,
      -101.093,
      2.77,
      -113.886,
      -101.848,
      2.77,
      -111.092,
      -101.062,
      2.77,
      -111.092,
      -101.062,
      2.77,
      -111.305,
      -100.306,
      2.77,
      -114.099,
      -101.093,
      2.77,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "EG_2384",
    polygon: [
      -114.463,
      -99.798,
      2.77,
      -114.131,
      -100.977,
      2.77,
      -111.338,
      -100.19,
      2.77,
      -111.338,
      -100.19,
      2.77,
      -111.67,
      -99.011,
      2.77,
      -114.463,
      -99.798,
      2.77,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "EG_2385",
    polygon: [
      -114.838,
      -98.467,
      2.77,
      -114.506,
      -99.646,
      2.77,
      -111.713,
      -98.859,
      2.77,
      -111.713,
      -98.859,
      2.77,
      -112.045,
      -97.68,
      2.77,
      -114.838,
      -98.467,
      2.77,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "EG_2386",
    polygon: [
      -115.193,
      -97.209,
      2.77,
      -114.861,
      -98.388,
      2.77,
      -112.067,
      -97.601,
      2.77,
      -112.067,
      -97.601,
      2.77,
      -112.399,
      -96.422,
      2.77,
      -115.193,
      -97.209,
      2.77,
    ],
    "04-15 02:05": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "EG_2387",
    polygon: [
      -115.557,
      -95.914,
      2.77,
      -115.225,
      -97.094,
      2.77,
      -112.432,
      -96.307,
      2.77,
      -112.432,
      -96.307,
      2.77,
      -112.764,
      -95.128,
      2.77,
      -115.557,
      -95.914,
      2.77,
    ],
    "04-15 02:05": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "EG_2389",
    polygon: [
      -116.287,
      -93.325,
      2.77,
      -115.955,
      -94.504,
      2.77,
      -113.161,
      -93.717,
      2.77,
      -113.161,
      -93.717,
      2.77,
      -113.493,
      -92.538,
      2.77,
      -116.287,
      -93.325,
      2.77,
    ],
    "04-15 02:05": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "EG_2390",
    polygon: [
      -115.922,
      -94.62,
      2.77,
      -115.59,
      -95.799,
      2.77,
      -112.796,
      -95.012,
      2.77,
      -112.796,
      -95.012,
      2.77,
      -113.129,
      -93.833,
      2.77,
      -115.922,
      -94.62,
      2.77,
    ],
    "04-15 02:05": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "EG_2391",
    polygon: [
      -116.651,
      -92.031,
      2.77,
      -116.319,
      -93.21,
      2.77,
      -113.526,
      -92.423,
      2.77,
      -113.526,
      -92.423,
      2.77,
      -113.858,
      -91.244,
      2.77,
      -116.651,
      -92.031,
      2.77,
    ],
    "04-15 02:05": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "EG_2392",
    polygon: [
      -117.016,
      -90.736,
      2.77,
      -116.684,
      -91.915,
      2.77,
      -113.89,
      -91.128,
      2.77,
      -113.89,
      -91.128,
      2.77,
      -114.223,
      -89.949,
      2.77,
      -117.016,
      -90.736,
      2.77,
    ],
    "04-15 02:05": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "EG_2393",
    polygon: [
      -114.523,
      -88.882,
      2.514,
      -117.317,
      -89.668,
      3.026,
      -117.049,
      -90.62,
      3.026,
      -117.049,
      -90.62,
      3.026,
      -114.255,
      -89.834,
      2.514,
      -114.523,
      -88.882,
      2.514,
    ],
    "04-15 02:05": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "EG_2395",
    polygon: [
      -102.852,
      -100.76,
      2.128,
      -102.239,
      -102.937,
      2.975,
      -98.293,
      -101.825,
      2.975,
      -98.293,
      -101.825,
      2.975,
      -98.906,
      -99.648,
      2.128,
      -102.852,
      -100.76,
      2.128,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "EG_2396",
    polygon: [
      -126.874,
      -15.423,
      1.875,
      -126.359,
      -17.251,
      1.875,
      -122.412,
      -16.14,
      1.875,
      -122.412,
      -16.14,
      1.875,
      -122.928,
      -14.311,
      1.875,
      -126.874,
      -15.423,
      1.875,
    ],
    "04-15 02:05": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
  },
  {
    ID: "EG_2397",
    polygon: [
      -85.15,
      -2.111,
      1.875,
      -84.635,
      -3.94,
      1.875,
      -80.689,
      -2.829,
      1.875,
      -80.689,
      -2.829,
      1.875,
      -81.204,
      -1.0,
      1.875,
      -85.15,
      -2.111,
      1.875,
    ],
    "04-15 02:05": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "EG_2398",
    polygon: [
      -45.301,
      9.113,
      1.875,
      -44.786,
      7.285,
      1.875,
      -40.839,
      8.396,
      1.875,
      -40.839,
      8.396,
      1.875,
      -41.355,
      10.225,
      1.875,
      -45.301,
      9.113,
      1.875,
    ],
    "04-15 02:05": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "EG_2399",
    polygon: [
      -18.573,
      14.253,
      1.875,
      -17.99,
      12.183,
      1.875,
      -13.274,
      13.512,
      1.875,
      -13.274,
      13.512,
      1.875,
      -13.857,
      15.581,
      1.875,
      -18.573,
      14.253,
      1.875,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1101_M0010",
    polygon: [
      -107.566,
      -30.718,
      7.17,
      -107.566,
      -30.718,
      10.165,
      -109.927,
      -31.383,
      10.165,
      -109.927,
      -31.383,
      10.165,
      -109.927,
      -31.383,
      7.17,
      -107.566,
      -30.718,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1102_M0010",
    polygon: [
      -111.293,
      -31.768,
      7.17,
      -111.293,
      -31.768,
      10.165,
      -113.177,
      -32.299,
      10.165,
      -113.177,
      -32.299,
      10.165,
      -113.177,
      -32.299,
      7.17,
      -111.293,
      -31.768,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1103_M0010",
    polygon: [
      -113.24,
      -32.316,
      7.17,
      -113.24,
      -32.316,
      10.165,
      -115.124,
      -32.847,
      10.165,
      -115.124,
      -32.847,
      10.165,
      -115.124,
      -32.847,
      7.17,
      -113.24,
      -32.316,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1104_M0010",
    polygon: [
      -116.491,
      -33.232,
      7.17,
      -116.491,
      -33.232,
      10.165,
      -118.375,
      -33.763,
      10.165,
      -118.375,
      -33.763,
      10.165,
      -118.375,
      -33.763,
      7.17,
      -116.491,
      -33.232,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1105_M0010",
    polygon: [
      -118.438,
      -33.78,
      7.17,
      -118.438,
      -33.78,
      10.165,
      -120.322,
      -34.311,
      10.165,
      -120.322,
      -34.311,
      10.165,
      -120.322,
      -34.311,
      7.17,
      -118.438,
      -33.78,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1106_M0010",
    polygon: [
      -121.686,
      -34.695,
      7.17,
      -121.686,
      -34.695,
      10.165,
      -123.57,
      -35.226,
      10.165,
      -123.57,
      -35.226,
      10.165,
      -123.57,
      -35.226,
      7.17,
      -121.686,
      -34.695,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1106_M0011",
    polygon: [
      -123.633,
      -35.244,
      7.17,
      -123.633,
      -35.244,
      10.165,
      -125.517,
      -35.775,
      10.165,
      -125.517,
      -35.775,
      10.165,
      -125.517,
      -35.775,
      7.17,
      -123.633,
      -35.244,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1106_M0012",
    polygon: [
      -126.111,
      -35.436,
      7.17,
      -126.111,
      -35.436,
      10.165,
      -126.636,
      -33.573,
      10.165,
      -126.636,
      -33.573,
      10.165,
      -126.636,
      -33.573,
      7.17,
      -126.111,
      -35.436,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE100xxE01O_EZR1106_M0013",
    polygon: [
      -126.654,
      -33.51,
      7.17,
      -126.654,
      -33.51,
      10.165,
      -127.178,
      -31.648,
      10.165,
      -127.178,
      -31.648,
      10.165,
      -127.178,
      -31.648,
      7.17,
      -126.654,
      -33.51,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1107_M0010",
    polygon: [
      -127.553,
      -30.318,
      7.17,
      -127.553,
      -30.318,
      10.165,
      -128.078,
      -28.455,
      10.165,
      -128.078,
      -28.455,
      10.165,
      -128.078,
      -28.455,
      7.17,
      -127.553,
      -30.318,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1107_M0011",
    polygon: [
      -128.095,
      -28.392,
      7.17,
      -128.095,
      -28.392,
      10.165,
      -128.62,
      -26.53,
      10.165,
      -128.62,
      -26.53,
      10.165,
      -128.62,
      -26.53,
      7.17,
      -128.095,
      -28.392,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1108_M0010",
    polygon: [
      -129.004,
      -25.168,
      7.17,
      -129.004,
      -25.168,
      10.165,
      -129.528,
      -23.305,
      10.165,
      -129.528,
      -23.305,
      10.165,
      -129.528,
      -23.305,
      7.17,
      -129.004,
      -25.168,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE100xxE01O_EZR1108_M0011",
    polygon: [
      -129.546,
      -23.242,
      7.17,
      -129.546,
      -23.242,
      10.165,
      -130.071,
      -21.38,
      10.165,
      -130.071,
      -21.38,
      10.165,
      -130.071,
      -21.38,
      7.17,
      -129.546,
      -23.242,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "LE100xxE01O_EZR1108_M0012",
    polygon: [
      -129.72,
      -20.806,
      7.17,
      -129.72,
      -20.806,
      10.165,
      -127.836,
      -20.276,
      10.165,
      -127.836,
      -20.276,
      10.165,
      -127.836,
      -20.276,
      7.17,
      -129.72,
      -20.806,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1108_M0013",
    polygon: [
      -127.773,
      -20.258,
      7.17,
      -127.773,
      -20.258,
      10.165,
      -125.889,
      -19.727,
      10.165,
      -125.889,
      -19.727,
      10.165,
      -125.889,
      -19.727,
      7.17,
      -127.773,
      -20.258,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1109_M0010",
    polygon: [
      -124.522,
      -19.342,
      7.17,
      -124.522,
      -19.342,
      10.165,
      -122.638,
      -18.812,
      10.165,
      -122.638,
      -18.812,
      10.165,
      -122.638,
      -18.812,
      7.17,
      -124.522,
      -19.342,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1110_M0010",
    polygon: [
      -122.575,
      -18.794,
      7.17,
      -122.575,
      -18.794,
      10.165,
      -120.691,
      -18.263,
      10.165,
      -120.691,
      -18.263,
      10.165,
      -120.691,
      -18.263,
      7.17,
      -122.575,
      -18.794,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1111_M0010",
    polygon: [
      -119.327,
      -17.879,
      7.17,
      -119.327,
      -17.879,
      10.165,
      -117.443,
      -17.348,
      10.165,
      -117.443,
      -17.348,
      10.165,
      -117.443,
      -17.348,
      7.17,
      -119.327,
      -17.879,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1112_M0010",
    polygon: [
      -117.38,
      -17.33,
      7.17,
      -117.38,
      -17.33,
      10.165,
      -115.496,
      -16.8,
      10.165,
      -115.496,
      -16.8,
      10.165,
      -115.496,
      -16.8,
      7.17,
      -117.38,
      -17.33,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1113_M0010",
    polygon: [
      -114.129,
      -16.415,
      7.17,
      -114.129,
      -16.415,
      10.165,
      -112.245,
      -15.884,
      10.165,
      -112.245,
      -15.884,
      10.165,
      -112.245,
      -15.884,
      7.17,
      -114.129,
      -16.415,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE100xxE01O_EZR1114_M0010",
    polygon: [
      -112.182,
      -15.866,
      7.17,
      -112.182,
      -15.866,
      10.165,
      -110.298,
      -15.336,
      10.165,
      -110.298,
      -15.336,
      10.165,
      -110.298,
      -15.336,
      7.17,
      -112.182,
      -15.866,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE100xxE01O_EZR1115_M0010",
    polygon: [
      -108.934,
      -14.951,
      7.17,
      -108.934,
      -14.951,
      10.165,
      -107.05,
      -14.421,
      10.165,
      -107.05,
      -14.421,
      10.165,
      -107.05,
      -14.421,
      7.17,
      -108.934,
      -14.951,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1116_M0010",
    polygon: [
      -106.987,
      -14.403,
      7.17,
      -106.987,
      -14.403,
      10.165,
      -105.103,
      -13.872,
      10.165,
      -105.103,
      -13.872,
      10.165,
      -105.103,
      -13.872,
      7.17,
      -106.987,
      -14.403,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1117_M0010",
    polygon: [
      -103.177,
      -15.408,
      7.17,
      -103.177,
      -15.408,
      10.165,
      -101.213,
      -14.854,
      10.165,
      -101.213,
      -14.854,
      10.165,
      -101.213,
      -14.854,
      7.17,
      -103.177,
      -15.408,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1118_M0010",
    polygon: [
      -101.151,
      -14.837,
      7.17,
      -101.151,
      -14.837,
      10.165,
      -99.187,
      -14.284,
      10.165,
      -99.187,
      -14.284,
      10.165,
      -99.187,
      -14.284,
      7.17,
      -101.151,
      -14.837,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1119_M0011",
    polygon: [
      -98.341,
      -11.967,
      7.17,
      -98.341,
      -11.967,
      10.165,
      -96.457,
      -11.437,
      10.165,
      -96.457,
      -11.437,
      10.165,
      -96.457,
      -11.437,
      7.17,
      -98.341,
      -11.967,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1120_M0010",
    polygon: [
      -96.394,
      -11.419,
      7.17,
      -96.394,
      -11.419,
      10.165,
      -94.51,
      -10.888,
      10.165,
      -94.51,
      -10.888,
      10.165,
      -94.51,
      -10.888,
      7.17,
      -96.394,
      -11.419,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1121_M0010",
    polygon: [
      -93.143,
      -10.503,
      7.17,
      -93.143,
      -10.503,
      10.165,
      -91.259,
      -9.973,
      10.165,
      -91.259,
      -9.973,
      10.165,
      -91.259,
      -9.973,
      7.17,
      -93.143,
      -10.503,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1122_M0010",
    polygon: [
      -91.197,
      -9.955,
      7.17,
      -91.197,
      -9.955,
      10.165,
      -89.312,
      -9.424,
      10.165,
      -89.312,
      -9.424,
      10.165,
      -89.312,
      -9.424,
      7.17,
      -91.197,
      -9.955,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1123_M0010",
    polygon: [
      -87.948,
      -9.04,
      7.17,
      -87.948,
      -9.04,
      10.165,
      -86.064,
      -8.509,
      10.165,
      -86.064,
      -8.509,
      10.165,
      -86.064,
      -8.509,
      7.17,
      -87.948,
      -9.04,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1124_M0010",
    polygon: [
      -86.001,
      -8.492,
      7.17,
      -86.001,
      -8.492,
      10.165,
      -84.117,
      -7.961,
      10.165,
      -84.117,
      -7.961,
      10.165,
      -84.117,
      -7.961,
      7.17,
      -86.001,
      -8.492,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1125_M0010",
    polygon: [
      -82.75,
      -7.576,
      7.17,
      -82.75,
      -7.576,
      10.165,
      -80.866,
      -7.045,
      10.165,
      -80.866,
      -7.045,
      10.165,
      -80.866,
      -7.045,
      7.17,
      -82.75,
      -7.576,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1126_M0010",
    polygon: [
      -80.803,
      -7.027,
      7.17,
      -80.803,
      -7.027,
      10.165,
      -78.919,
      -6.497,
      10.165,
      -78.919,
      -6.497,
      10.165,
      -78.919,
      -6.497,
      7.17,
      -80.803,
      -7.027,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1127_M0010",
    polygon: [
      -77.555,
      -6.112,
      7.17,
      -77.555,
      -6.112,
      10.165,
      -75.671,
      -5.582,
      10.165,
      -75.671,
      -5.582,
      10.165,
      -75.671,
      -5.582,
      7.17,
      -77.555,
      -6.112,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1127_M0011",
    polygon: [
      -75.608,
      -5.564,
      7.17,
      -75.608,
      -5.564,
      10.165,
      -73.724,
      -5.033,
      10.165,
      -73.724,
      -5.033,
      10.165,
      -73.724,
      -5.033,
      7.17,
      -75.608,
      -5.564,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1127_M0012",
    polygon: [
      -73.124,
      -5.386,
      7.17,
      -73.124,
      -5.386,
      10.165,
      -72.606,
      -7.224,
      10.165,
      -72.606,
      -7.224,
      10.165,
      -72.606,
      -7.224,
      7.17,
      -73.124,
      -5.386,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1127_M0013",
    polygon: [
      -72.589,
      -7.287,
      7.17,
      -72.589,
      -7.287,
      10.165,
      -72.071,
      -9.125,
      10.165,
      -72.071,
      -9.125,
      10.165,
      -72.071,
      -9.125,
      7.17,
      -72.589,
      -7.287,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1128_M0010",
    polygon: [
      -71.673,
      -10.535,
      7.17,
      -71.673,
      -10.535,
      10.165,
      -71.156,
      -12.374,
      10.165,
      -71.156,
      -12.374,
      10.165,
      -71.156,
      -12.374,
      7.17,
      -71.673,
      -10.535,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1128_M0011",
    polygon: [
      -71.138,
      -12.436,
      7.17,
      -71.138,
      -12.436,
      10.165,
      -70.62,
      -14.275,
      10.165,
      -70.62,
      -14.275,
      10.165,
      -70.62,
      -14.275,
      7.17,
      -71.138,
      -12.436,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1129_M0010",
    polygon: [
      -70.23,
      -15.658,
      7.17,
      -70.23,
      -15.658,
      10.165,
      -69.713,
      -17.497,
      10.165,
      -69.713,
      -17.497,
      10.165,
      -69.713,
      -17.497,
      7.17,
      -70.23,
      -15.658,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1129_M0011",
    polygon: [
      -69.695,
      -17.559,
      7.17,
      -69.695,
      -17.559,
      10.165,
      -69.177,
      -19.398,
      10.165,
      -69.177,
      -19.398,
      10.165,
      -69.177,
      -19.398,
      7.17,
      -69.695,
      -17.559,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1129_M0012",
    polygon: [
      -69.521,
      -20.001,
      7.17,
      -69.521,
      -20.001,
      10.165,
      -71.405,
      -20.532,
      10.165,
      -71.405,
      -20.532,
      10.165,
      -71.405,
      -20.532,
      7.17,
      -69.521,
      -20.001,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1129_M0013",
    polygon: [
      -71.468,
      -20.55,
      7.17,
      -71.468,
      -20.55,
      10.165,
      -73.352,
      -21.081,
      10.165,
      -73.352,
      -21.081,
      10.165,
      -73.352,
      -21.081,
      7.17,
      -71.468,
      -20.55,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
  },
  {
    ID: "LE100xxE01O_EZR1130_M0010",
    polygon: [
      -74.719,
      -21.466,
      7.17,
      -74.719,
      -21.466,
      10.165,
      -76.603,
      -21.996,
      10.165,
      -76.603,
      -21.996,
      10.165,
      -76.603,
      -21.996,
      7.17,
      -74.719,
      -21.466,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1131_M0010",
    polygon: [
      -76.666,
      -22.014,
      7.17,
      -76.666,
      -22.014,
      10.165,
      -78.55,
      -22.545,
      10.165,
      -78.55,
      -22.545,
      10.165,
      -78.55,
      -22.545,
      7.17,
      -76.666,
      -22.014,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
  },
  {
    ID: "LE100xxE01O_EZR1132_M0010",
    polygon: [
      -79.914,
      -22.929,
      7.17,
      -79.914,
      -22.929,
      10.165,
      -81.799,
      -23.46,
      10.165,
      -81.799,
      -23.46,
      10.165,
      -81.799,
      -23.46,
      7.17,
      -79.914,
      -22.929,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1133_M0010",
    polygon: [
      -81.861,
      -23.477,
      7.17,
      -81.861,
      -23.477,
      10.165,
      -83.745,
      -24.008,
      10.165,
      -83.745,
      -24.008,
      10.165,
      -83.745,
      -24.008,
      7.17,
      -81.861,
      -23.477,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
  },
  {
    ID: "LE100xxE01O_EZR1134_M0010",
    polygon: [
      -85.108,
      -24.392,
      7.17,
      -85.108,
      -24.392,
      10.165,
      -87.469,
      -25.057,
      10.165,
      -87.469,
      -25.057,
      10.165,
      -87.469,
      -25.057,
      7.17,
      -85.108,
      -24.392,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1135_M0010",
    polygon: [
      -87.703,
      -26.115,
      7.17,
      -87.703,
      -26.115,
      10.165,
      -87.221,
      -27.826,
      10.165,
      -87.221,
      -27.826,
      10.165,
      -87.221,
      -27.826,
      7.17,
      -87.703,
      -26.115,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1149_M0010",
    polygon: [
      -106.321,
      -33.206,
      7.17,
      -106.321,
      -33.206,
      10.165,
      -106.803,
      -31.495,
      10.165,
      -106.803,
      -31.495,
      10.165,
      -106.803,
      -31.495,
      7.17,
      -106.321,
      -33.206,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1149_M0011",
    polygon: [
      -105.822,
      -34.98,
      7.17,
      -105.822,
      -34.98,
      10.165,
      -106.304,
      -33.269,
      10.165,
      -106.304,
      -33.269,
      10.165,
      -106.304,
      -33.269,
      7.17,
      -105.822,
      -34.98,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "LE100xxE01O_EZR1149_M0012",
    polygon: [
      -104.956,
      -38.053,
      7.17,
      -104.956,
      -38.053,
      10.165,
      -105.438,
      -36.342,
      10.165,
      -105.438,
      -36.342,
      10.165,
      -105.438,
      -36.342,
      7.17,
      -104.956,
      -38.053,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE100xxE01O_EZR1201_M0010",
    polygon: [
      -107.578,
      -52.168,
      7.17,
      -107.578,
      -52.168,
      10.165,
      -105.694,
      -51.637,
      10.165,
      -105.694,
      -51.637,
      10.165,
      -105.694,
      -51.637,
      7.17,
      -107.578,
      -52.168,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1202_M0010",
    polygon: [
      -109.525,
      -52.716,
      7.17,
      -109.525,
      -52.716,
      10.165,
      -107.641,
      -52.185,
      10.165,
      -107.641,
      -52.185,
      10.165,
      -107.641,
      -52.185,
      7.17,
      -109.525,
      -52.716,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1203_M0010",
    polygon: [
      -112.774,
      -53.631,
      7.17,
      -112.774,
      -53.631,
      10.165,
      -110.89,
      -53.101,
      10.165,
      -110.89,
      -53.101,
      10.165,
      -110.89,
      -53.101,
      7.17,
      -112.774,
      -53.631,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1204_M0010",
    polygon: [
      -114.721,
      -54.18,
      7.17,
      -114.721,
      -54.18,
      10.165,
      -112.836,
      -53.649,
      10.165,
      -112.836,
      -53.649,
      10.165,
      -112.836,
      -53.649,
      7.17,
      -114.721,
      -54.18,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1205_M0010",
    polygon: [
      -117.972,
      -55.095,
      7.17,
      -117.972,
      -55.095,
      10.165,
      -116.087,
      -54.565,
      10.165,
      -116.087,
      -54.565,
      10.165,
      -116.087,
      -54.565,
      7.17,
      -117.972,
      -55.095,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1205_M0011",
    polygon: [
      -119.918,
      -55.644,
      7.17,
      -119.918,
      -55.644,
      10.165,
      -118.034,
      -55.113,
      10.165,
      -118.034,
      -55.113,
      10.165,
      -118.034,
      -55.113,
      7.17,
      -119.918,
      -55.644,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1205_M0012",
    polygon: [
      -119.731,
      -58.076,
      7.17,
      -119.731,
      -58.076,
      10.165,
      -120.256,
      -56.213,
      10.165,
      -120.256,
      -56.213,
      10.165,
      -120.256,
      -56.213,
      7.17,
      -119.731,
      -58.076,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "LE100xxE01O_EZR1205_M0013",
    polygon: [
      -119.189,
      -60.001,
      7.17,
      -119.189,
      -60.001,
      10.165,
      -119.713,
      -58.139,
      10.165,
      -119.713,
      -58.139,
      10.165,
      -119.713,
      -58.139,
      7.17,
      -119.189,
      -60.001,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "LE100xxE01O_EZR1206_M0010",
    polygon: [
      -118.28,
      -63.226,
      7.17,
      -118.28,
      -63.226,
      10.165,
      -118.805,
      -61.363,
      10.165,
      -118.805,
      -61.363,
      10.165,
      -118.805,
      -61.363,
      7.17,
      -118.28,
      -63.226,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1206_M0011",
    polygon: [
      -117.738,
      -65.151,
      7.17,
      -117.738,
      -65.151,
      10.165,
      -118.262,
      -63.289,
      10.165,
      -118.262,
      -63.289,
      10.165,
      -118.262,
      -63.289,
      7.17,
      -117.738,
      -65.151,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1207_M0010",
    polygon: [
      -116.838,
      -68.344,
      7.17,
      -116.838,
      -68.344,
      10.165,
      -117.363,
      -66.482,
      10.165,
      -117.363,
      -66.482,
      10.165,
      -117.363,
      -66.482,
      7.17,
      -116.838,
      -68.344,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
  },
  {
    ID: "LE100xxE01O_EZR1207_M0011",
    polygon: [
      -116.296,
      -70.27,
      7.17,
      -116.296,
      -70.27,
      10.165,
      -116.821,
      -68.407,
      10.165,
      -116.821,
      -68.407,
      10.165,
      -116.821,
      -68.407,
      7.17,
      -116.296,
      -70.27,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1207_M0012",
    polygon: [
      -113.81,
      -70.078,
      7.17,
      -113.81,
      -70.078,
      10.165,
      -115.696,
      -70.61,
      10.165,
      -115.696,
      -70.61,
      10.165,
      -115.696,
      -70.61,
      7.17,
      -113.81,
      -70.078,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:55": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1207_M0013",
    polygon: [
      -111.861,
      -69.529,
      7.17,
      -111.861,
      -69.529,
      10.165,
      -113.747,
      -70.061,
      10.165,
      -113.747,
      -70.061,
      10.165,
      -113.747,
      -70.061,
      7.17,
      -111.861,
      -69.529,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:55": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1208_M0010",
    polygon: [
      -108.612,
      -68.614,
      7.17,
      -108.612,
      -68.614,
      10.165,
      -110.499,
      -69.146,
      10.165,
      -110.499,
      -69.146,
      10.165,
      -110.499,
      -69.146,
      7.17,
      -108.612,
      -68.614,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:55": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1209_M00010",
    polygon: [
      -106.663,
      -68.065,
      7.17,
      -106.663,
      -68.065,
      10.165,
      -108.55,
      -68.597,
      10.165,
      -108.55,
      -68.597,
      10.165,
      -108.55,
      -68.597,
      7.17,
      -106.663,
      -68.065,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:55": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1210_M0010",
    polygon: [
      -103.414,
      -67.15,
      7.17,
      -103.414,
      -67.15,
      10.165,
      -105.301,
      -67.681,
      10.165,
      -105.301,
      -67.681,
      10.165,
      -105.301,
      -67.681,
      7.17,
      -103.414,
      -67.15,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:55": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1211_M0010",
    polygon: [
      -101.465,
      -66.601,
      7.17,
      -101.465,
      -66.601,
      10.165,
      -103.352,
      -67.132,
      10.165,
      -103.352,
      -67.132,
      10.165,
      -103.352,
      -67.132,
      7.17,
      -101.465,
      -66.601,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1212_M0010",
    polygon: [
      -98.217,
      -65.686,
      7.17,
      -98.217,
      -65.686,
      10.165,
      -100.103,
      -66.217,
      10.165,
      -100.103,
      -66.217,
      10.165,
      -100.103,
      -66.217,
      7.17,
      -98.217,
      -65.686,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1213_M0010",
    polygon: [
      -96.268,
      -65.137,
      7.17,
      -96.268,
      -65.137,
      10.165,
      -98.154,
      -65.668,
      10.165,
      -98.154,
      -65.668,
      10.165,
      -98.154,
      -65.668,
      7.17,
      -96.268,
      -65.137,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1214_M0010",
    polygon: [
      -92.547,
      -64.089,
      7.17,
      -92.547,
      -64.089,
      10.165,
      -94.908,
      -64.754,
      10.165,
      -94.908,
      -64.754,
      10.165,
      -94.908,
      -64.754,
      7.17,
      -92.547,
      -64.089,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1215_M0010",
    polygon: [
      -80.484,
      -60.691,
      7.17,
      -80.484,
      -60.691,
      10.165,
      -82.845,
      -61.356,
      10.165,
      -82.845,
      -61.356,
      10.165,
      -82.845,
      -61.356,
      7.17,
      -80.484,
      -60.691,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 08:45": 1,
    "04-15 08:55": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1216_M0010",
    polygon: [
      -77.236,
      -59.776,
      7.17,
      -77.236,
      -59.776,
      10.165,
      -79.122,
      -60.307,
      10.165,
      -79.122,
      -60.307,
      10.165,
      -79.122,
      -60.307,
      7.17,
      -77.236,
      -59.776,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:20": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 08:45": 1,
    "04-15 08:55": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
  },
  {
    ID: "LE100xxE01O_EZR1217_M0010",
    polygon: [
      -75.286,
      -59.227,
      7.17,
      -75.286,
      -59.227,
      10.165,
      -77.173,
      -59.758,
      10.165,
      -77.173,
      -59.758,
      10.165,
      -77.173,
      -59.758,
      7.17,
      -75.286,
      -59.227,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:45": 1,
    "04-15 08:55": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1218_M0010",
    polygon: [
      -72.038,
      -58.312,
      7.17,
      -72.038,
      -58.312,
      10.165,
      -73.924,
      -58.843,
      10.165,
      -73.924,
      -58.843,
      10.165,
      -73.924,
      -58.843,
      7.17,
      -72.038,
      -58.312,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:45": 1,
    "04-15 08:55": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1219_M0010",
    polygon: [
      -70.089,
      -57.763,
      7.17,
      -70.089,
      -57.763,
      10.165,
      -71.975,
      -58.294,
      10.165,
      -71.975,
      -58.294,
      10.165,
      -71.975,
      -58.294,
      7.17,
      -70.089,
      -57.763,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:55": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1220_M0010",
    polygon: [
      -66.84,
      -56.848,
      7.17,
      -66.84,
      -56.848,
      10.165,
      -68.727,
      -57.379,
      10.165,
      -68.727,
      -57.379,
      10.165,
      -68.727,
      -57.379,
      7.17,
      -66.84,
      -56.848,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1221_M0010",
    polygon: [
      -64.891,
      -56.299,
      7.17,
      -64.891,
      -56.299,
      10.165,
      -66.778,
      -56.83,
      10.165,
      -66.778,
      -56.83,
      10.165,
      -66.778,
      -56.83,
      7.17,
      -64.891,
      -56.299,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
  },
  {
    ID: "LE100xxE01O_EZR1222_M0012",
    polygon: [
      -59.885,
      -52.395,
      7.17,
      -59.885,
      -52.395,
      10.165,
      -59.368,
      -54.233,
      10.165,
      -59.368,
      -54.233,
      10.165,
      -59.368,
      -54.233,
      7.17,
      -59.885,
      -52.395,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
  },
  {
    ID: "LE100xxE01O_EZR1222_M0013",
    polygon: [
      -60.421,
      -50.494,
      7.17,
      -60.421,
      -50.494,
      10.165,
      -59.903,
      -52.332,
      10.165,
      -59.903,
      -52.332,
      10.165,
      -59.903,
      -52.332,
      7.17,
      -60.421,
      -50.494,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1223_M0010",
    polygon: [
      -61.329,
      -47.272,
      7.17,
      -61.329,
      -47.272,
      10.165,
      -60.811,
      -49.11,
      10.165,
      -60.811,
      -49.11,
      10.165,
      -60.811,
      -49.11,
      7.17,
      -61.329,
      -47.272,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1223_M0011",
    polygon: [
      -61.864,
      -45.371,
      7.17,
      -61.864,
      -45.371,
      10.165,
      -61.346,
      -47.209,
      10.165,
      -61.346,
      -47.209,
      10.165,
      -61.346,
      -47.209,
      7.17,
      -61.864,
      -45.371,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1224_M0010",
    polygon: [
      -62.779,
      -42.122,
      7.17,
      -62.779,
      -42.122,
      10.165,
      -62.261,
      -43.96,
      10.165,
      -62.261,
      -43.96,
      10.165,
      -62.261,
      -43.96,
      7.17,
      -62.779,
      -42.122,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1224_M0011",
    polygon: [
      -63.315,
      -40.221,
      7.17,
      -63.315,
      -40.221,
      10.165,
      -62.797,
      -42.059,
      10.165,
      -62.797,
      -42.059,
      10.165,
      -62.797,
      -42.059,
      7.17,
      -63.315,
      -40.221,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1224_M0012",
    polygon: [
      -65.802,
      -40.4,
      7.17,
      -65.802,
      -40.4,
      10.165,
      -63.918,
      -39.869,
      10.165,
      -63.918,
      -39.869,
      10.165,
      -63.918,
      -39.869,
      7.17,
      -65.802,
      -40.4,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1224_M0013",
    polygon: [
      -67.748,
      -40.948,
      7.17,
      -67.748,
      -40.948,
      10.165,
      -65.864,
      -40.418,
      10.165,
      -65.864,
      -40.418,
      10.165,
      -65.864,
      -40.418,
      7.17,
      -67.748,
      -40.948,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1225_M0010",
    polygon: [
      -70.997,
      -41.864,
      7.17,
      -70.997,
      -41.864,
      10.165,
      -69.113,
      -41.333,
      10.165,
      -69.113,
      -41.333,
      10.165,
      -69.113,
      -41.333,
      7.17,
      -70.997,
      -41.864,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1226_M0010",
    polygon: [
      -72.944,
      -42.412,
      7.17,
      -72.944,
      -42.412,
      10.165,
      -71.06,
      -41.881,
      10.165,
      -71.06,
      -41.881,
      10.165,
      -71.06,
      -41.881,
      7.17,
      -72.944,
      -42.412,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1227_M0010",
    polygon: [
      -76.195,
      -43.328,
      7.17,
      -76.195,
      -43.328,
      10.165,
      -74.311,
      -42.797,
      10.165,
      -74.311,
      -42.797,
      10.165,
      -74.311,
      -42.797,
      7.17,
      -76.195,
      -43.328,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1228_M0010",
    polygon: [
      -78.142,
      -43.876,
      7.17,
      -78.142,
      -43.876,
      10.165,
      -76.257,
      -43.345,
      10.165,
      -76.257,
      -43.345,
      10.165,
      -76.257,
      -43.345,
      7.17,
      -78.142,
      -43.876,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1229_M0010",
    polygon: [
      -81.869,
      -44.926,
      7.17,
      -81.869,
      -44.926,
      10.165,
      -79.508,
      -44.261,
      10.165,
      -79.508,
      -44.261,
      10.165,
      -79.508,
      -44.261,
      7.17,
      -81.869,
      -44.926,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1230_M0010",
    polygon: [
      -83.106,
      -42.433,
      7.17,
      -83.106,
      -42.433,
      10.165,
      -82.624,
      -44.144,
      10.165,
      -82.624,
      -44.144,
      10.165,
      -82.624,
      -44.144,
      7.17,
      -83.106,
      -42.433,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1231_M0010",
    polygon: [
      -83.606,
      -40.659,
      7.17,
      -83.606,
      -40.659,
      10.165,
      -83.124,
      -42.37,
      10.165,
      -83.124,
      -42.37,
      10.165,
      -83.124,
      -42.37,
      7.17,
      -83.606,
      -40.659,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1231_M0011",
    polygon: [
      -84.472,
      -37.586,
      7.17,
      -84.472,
      -37.586,
      10.165,
      -83.99,
      -39.297,
      10.165,
      -83.99,
      -39.297,
      10.165,
      -83.99,
      -39.297,
      7.17,
      -84.472,
      -37.586,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1232_M0010",
    polygon: [
      -84.971,
      -35.813,
      7.17,
      -84.971,
      -35.813,
      10.165,
      -84.489,
      -37.524,
      10.165,
      -84.489,
      -37.524,
      10.165,
      -84.489,
      -37.524,
      7.17,
      -84.971,
      -35.813,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1232_M0011",
    polygon: [
      -85.838,
      -32.735,
      7.17,
      -85.838,
      -32.735,
      10.165,
      -85.356,
      -34.446,
      10.165,
      -85.356,
      -34.446,
      10.165,
      -85.356,
      -34.446,
      7.17,
      -85.838,
      -32.735,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1233_M0010",
    polygon: [
      -87.203,
      -27.889,
      7.17,
      -87.203,
      -27.889,
      10.165,
      -86.721,
      -29.6,
      10.165,
      -86.721,
      -29.6,
      10.165,
      -86.721,
      -29.6,
      7.17,
      -87.203,
      -27.889,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1233_M0011",
    polygon: [
      -86.338,
      -30.962,
      7.17,
      -86.338,
      -30.962,
      10.165,
      -85.856,
      -32.672,
      10.165,
      -85.856,
      -32.672,
      10.165,
      -85.856,
      -32.672,
      7.17,
      -86.338,
      -30.962,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1234_M0010",
    polygon: [
      -104.457,
      -39.826,
      7.17,
      -104.457,
      -39.826,
      10.165,
      -104.939,
      -38.115,
      10.165,
      -104.939,
      -38.115,
      10.165,
      -104.939,
      -38.115,
      7.17,
      -104.457,
      -39.826,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 12:05": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "LE100xxE01O_EZR1235_M0010",
    polygon: [
      -103.59,
      -42.904,
      7.17,
      -103.59,
      -42.904,
      10.165,
      -104.072,
      -41.193,
      10.165,
      -104.072,
      -41.193,
      10.165,
      -104.072,
      -41.193,
      7.17,
      -103.59,
      -42.904,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1236_M0010",
    polygon: [
      -103.09,
      -44.677,
      7.17,
      -103.09,
      -44.677,
      10.165,
      -103.572,
      -42.967,
      10.165,
      -103.572,
      -42.967,
      10.165,
      -103.572,
      -42.967,
      7.17,
      -103.09,
      -44.677,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1237_M0010",
    polygon: [
      -101.725,
      -49.524,
      7.17,
      -101.725,
      -49.524,
      10.165,
      -102.207,
      -47.813,
      10.165,
      -102.207,
      -47.813,
      10.165,
      -102.207,
      -47.813,
      7.17,
      -101.725,
      -49.524,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "LE100xxE01O_EZR1237_M0011",
    polygon: [
      -102.225,
      -47.75,
      7.17,
      -102.225,
      -47.75,
      10.165,
      -102.707,
      -46.039,
      10.165,
      -102.707,
      -46.039,
      10.165,
      -102.707,
      -46.039,
      7.17,
      -102.225,
      -47.75,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1251_M0010",
    polygon: [
      -104.327,
      -51.252,
      7.17,
      -104.327,
      -51.252,
      10.165,
      -101.967,
      -50.587,
      10.165,
      -101.967,
      -50.587,
      10.165,
      -101.967,
      -50.587,
      7.17,
      -104.327,
      -51.252,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1301_M0010",
    polygon: [
      -93.897,
      -80.78,
      7.165,
      -93.897,
      -80.78,
      10.16,
      -92.012,
      -80.249,
      10.16,
      -92.012,
      -80.249,
      10.16,
      -92.012,
      -80.249,
      7.165,
      -93.897,
      -80.78,
      7.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "LE100xxE01O_EZR1301_M0011",
    polygon: [
      -95.843,
      -81.328,
      7.165,
      -95.843,
      -81.328,
      10.16,
      -93.959,
      -80.798,
      10.16,
      -93.959,
      -80.798,
      10.16,
      -93.959,
      -80.798,
      7.165,
      -95.843,
      -81.328,
      7.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "LE100xxE01O_EZR1302_M0010",
    polygon: [
      -99.094,
      -82.244,
      7.165,
      -99.094,
      -82.244,
      10.16,
      -97.21,
      -81.713,
      10.16,
      -97.21,
      -81.713,
      10.16,
      -97.21,
      -81.713,
      7.165,
      -99.094,
      -82.244,
      7.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1303_M0010",
    polygon: [
      -101.041,
      -82.792,
      7.165,
      -101.041,
      -82.792,
      10.16,
      -99.157,
      -82.262,
      10.16,
      -99.157,
      -82.262,
      10.16,
      -99.157,
      -82.262,
      7.165,
      -101.041,
      -82.792,
      7.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1304_M0010",
    polygon: [
      -104.297,
      -83.71,
      7.165,
      -104.297,
      -83.71,
      10.16,
      -102.413,
      -83.179,
      10.16,
      -102.413,
      -83.179,
      10.16,
      -102.413,
      -83.179,
      7.165,
      -104.297,
      -83.71,
      7.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1305_M0010",
    polygon: [
      -106.244,
      -84.258,
      7.165,
      -106.244,
      -84.258,
      10.16,
      -104.36,
      -83.727,
      10.16,
      -104.36,
      -83.727,
      10.16,
      -104.36,
      -83.727,
      7.165,
      -106.244,
      -84.258,
      7.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE100xxE01O_EZR1306_M0010",
    polygon: [
      -109.495,
      -85.174,
      7.165,
      -109.495,
      -85.174,
      10.16,
      -107.611,
      -84.643,
      10.16,
      -107.611,
      -84.643,
      10.16,
      -107.611,
      -84.643,
      7.165,
      -109.495,
      -85.174,
      7.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "LE100xxE01O_EZR1306_M0011",
    polygon: [
      -111.442,
      -85.722,
      7.165,
      -111.442,
      -85.722,
      10.16,
      -109.557,
      -85.191,
      10.16,
      -109.557,
      -85.191,
      10.16,
      -109.557,
      -85.191,
      7.165,
      -111.442,
      -85.722,
      7.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1306_M0012",
    polygon: [
      -111.261,
      -88.156,
      7.17,
      -111.261,
      -88.156,
      10.165,
      -111.786,
      -86.293,
      10.165,
      -111.786,
      -86.293,
      10.165,
      -111.786,
      -86.293,
      7.17,
      -111.261,
      -88.156,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "LE100xxE01O_EZR1306_M0013",
    polygon: [
      -110.719,
      -90.081,
      7.17,
      -110.719,
      -90.081,
      10.165,
      -111.243,
      -88.219,
      10.165,
      -111.243,
      -88.219,
      10.165,
      -111.243,
      -88.219,
      7.17,
      -110.719,
      -90.081,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1307_M0010",
    polygon: [
      -109.81,
      -93.306,
      7.17,
      -109.81,
      -93.306,
      10.165,
      -110.335,
      -91.443,
      10.165,
      -110.335,
      -91.443,
      10.165,
      -110.335,
      -91.443,
      7.17,
      -109.81,
      -93.306,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1307_M0011",
    polygon: [
      -109.268,
      -95.232,
      7.17,
      -109.268,
      -95.232,
      10.165,
      -109.792,
      -93.369,
      10.165,
      -109.792,
      -93.369,
      10.165,
      -109.792,
      -93.369,
      7.17,
      -109.268,
      -95.232,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1308_M0010",
    polygon: [
      -108.368,
      -98.424,
      7.17,
      -108.368,
      -98.424,
      10.165,
      -108.893,
      -96.562,
      10.165,
      -108.893,
      -96.562,
      10.165,
      -108.893,
      -96.562,
      7.17,
      -108.368,
      -98.424,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1308_M0011",
    polygon: [
      -107.826,
      -100.35,
      7.17,
      -107.826,
      -100.35,
      10.165,
      -108.351,
      -98.487,
      10.165,
      -108.351,
      -98.487,
      10.165,
      -108.351,
      -98.487,
      7.17,
      -107.826,
      -100.35,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE01O_EZR1308_M0012",
    polygon: [
      -105.339,
      -100.158,
      7.17,
      -105.339,
      -100.158,
      10.165,
      -107.225,
      -100.69,
      10.165,
      -107.225,
      -100.69,
      10.165,
      -107.225,
      -100.69,
      7.17,
      -105.339,
      -100.158,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1308_M0013",
    polygon: [
      -103.392,
      -99.61,
      7.17,
      -103.392,
      -99.61,
      10.165,
      -105.278,
      -100.141,
      10.165,
      -105.278,
      -100.141,
      10.165,
      -105.278,
      -100.141,
      7.17,
      -103.392,
      -99.61,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1309_M0010",
    polygon: [
      -100.14,
      -98.694,
      7.17,
      -100.14,
      -98.694,
      10.165,
      -102.027,
      -99.225,
      10.165,
      -102.027,
      -99.225,
      10.165,
      -102.027,
      -99.225,
      7.17,
      -100.14,
      -98.694,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1310_M0010",
    polygon: [
      -98.194,
      -98.146,
      7.17,
      -98.194,
      -98.146,
      10.165,
      -100.08,
      -98.677,
      10.165,
      -100.08,
      -98.677,
      10.165,
      -100.08,
      -98.677,
      7.17,
      -98.194,
      -98.146,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1311_M0010",
    polygon: [
      -94.943,
      -97.23,
      7.17,
      -94.943,
      -97.23,
      10.165,
      -96.83,
      -97.761,
      10.165,
      -96.83,
      -97.761,
      10.165,
      -96.83,
      -97.761,
      7.17,
      -94.943,
      -97.23,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1312_M0010",
    polygon: [
      -92.996,
      -96.682,
      7.17,
      -92.996,
      -96.682,
      10.165,
      -94.883,
      -97.213,
      10.165,
      -94.883,
      -97.213,
      10.165,
      -94.883,
      -97.213,
      7.17,
      -92.996,
      -96.682,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1313_M0010",
    polygon: [
      -89.745,
      -95.766,
      7.17,
      -89.745,
      -95.766,
      10.165,
      -91.632,
      -96.297,
      10.165,
      -91.632,
      -96.297,
      10.165,
      -91.632,
      -96.297,
      7.17,
      -89.745,
      -95.766,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1314_M0010",
    polygon: [
      -87.798,
      -95.217,
      7.17,
      -87.798,
      -95.217,
      10.165,
      -89.685,
      -95.749,
      10.165,
      -89.685,
      -95.749,
      10.165,
      -89.685,
      -95.749,
      7.17,
      -87.798,
      -95.217,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1315_M0010",
    polygon: [
      -84.547,
      -94.302,
      7.17,
      -84.547,
      -94.302,
      10.165,
      -86.434,
      -94.833,
      10.165,
      -86.434,
      -94.833,
      10.165,
      -86.434,
      -94.833,
      7.17,
      -84.547,
      -94.302,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1316_M0010",
    polygon: [
      -82.601,
      -93.753,
      7.17,
      -82.601,
      -93.753,
      10.165,
      -84.487,
      -94.285,
      10.165,
      -84.487,
      -94.285,
      10.165,
      -84.487,
      -94.285,
      7.17,
      -82.601,
      -93.753,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1317_M0010",
    polygon: [
      -79.797,
      -90.886,
      7.17,
      -79.797,
      -90.886,
      10.165,
      -81.761,
      -91.439,
      10.165,
      -81.761,
      -91.439,
      10.165,
      -81.761,
      -91.439,
      7.17,
      -79.797,
      -90.886,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
  },
  {
    ID: "LE100xxE01O_EZR1318_M0010",
    polygon: [
      -77.771,
      -90.315,
      7.17,
      -77.771,
      -90.315,
      10.165,
      -79.734,
      -90.868,
      10.165,
      -79.734,
      -90.868,
      10.165,
      -79.734,
      -90.868,
      7.17,
      -77.771,
      -90.315,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
  },
  {
    ID: "LE100xxE01O_EZR1319_M0011",
    polygon: [
      -73.96,
      -91.319,
      7.17,
      -73.96,
      -91.319,
      10.165,
      -75.846,
      -91.851,
      10.165,
      -75.846,
      -91.851,
      10.165,
      -75.846,
      -91.851,
      7.17,
      -73.96,
      -91.319,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1320_M0010",
    polygon: [
      -72.013,
      -90.771,
      7.17,
      -72.013,
      -90.771,
      10.165,
      -73.9,
      -91.302,
      10.165,
      -73.9,
      -91.302,
      10.165,
      -73.9,
      -91.302,
      7.17,
      -72.013,
      -90.771,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1321_M0010",
    polygon: [
      -68.762,
      -89.855,
      7.17,
      -68.762,
      -89.855,
      10.165,
      -70.648,
      -90.386,
      10.165,
      -70.648,
      -90.386,
      10.165,
      -70.648,
      -90.386,
      7.17,
      -68.762,
      -89.855,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1322_M0010",
    polygon: [
      -66.815,
      -89.307,
      7.17,
      -66.815,
      -89.307,
      10.165,
      -68.701,
      -89.838,
      10.165,
      -68.701,
      -89.838,
      10.165,
      -68.701,
      -89.838,
      7.17,
      -66.815,
      -89.307,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1323_M0010",
    polygon: [
      -63.564,
      -88.391,
      7.17,
      -63.564,
      -88.391,
      10.165,
      -65.451,
      -88.922,
      10.165,
      -65.451,
      -88.922,
      10.165,
      -65.451,
      -88.922,
      7.17,
      -63.564,
      -88.391,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1324_M0010",
    polygon: [
      -61.618,
      -87.843,
      7.17,
      -61.618,
      -87.843,
      10.165,
      -63.504,
      -88.374,
      10.165,
      -63.504,
      -88.374,
      10.165,
      -63.504,
      -88.374,
      7.17,
      -61.618,
      -87.843,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1325_M0010",
    polygon: [
      -58.366,
      -86.927,
      7.17,
      -58.366,
      -86.927,
      10.165,
      -60.253,
      -87.458,
      10.165,
      -60.253,
      -87.458,
      10.165,
      -60.253,
      -87.458,
      7.17,
      -58.366,
      -86.927,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1326_M0010",
    polygon: [
      -56.419,
      -86.378,
      7.17,
      -56.419,
      -86.378,
      10.165,
      -58.306,
      -86.91,
      10.165,
      -58.306,
      -86.91,
      10.165,
      -58.306,
      -86.91,
      7.17,
      -56.419,
      -86.378,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1327_M0010",
    polygon: [
      -53.168,
      -85.463,
      7.17,
      -53.168,
      -85.463,
      10.165,
      -55.055,
      -85.994,
      10.165,
      -55.055,
      -85.994,
      10.165,
      -55.055,
      -85.994,
      7.17,
      -53.168,
      -85.463,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE01O_EZR1327_M0011",
    polygon: [
      -51.222,
      -84.914,
      7.17,
      -51.222,
      -84.914,
      10.165,
      -53.108,
      -85.446,
      10.165,
      -53.108,
      -85.446,
      10.165,
      -53.108,
      -85.446,
      7.17,
      -51.222,
      -84.914,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE01O_EZR1327_M0012",
    polygon: [
      -51.41,
      -82.473,
      7.17,
      -51.41,
      -82.473,
      10.165,
      -50.892,
      -84.312,
      10.165,
      -50.892,
      -84.312,
      10.165,
      -50.892,
      -84.312,
      7.17,
      -51.41,
      -82.473,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1327_M0013",
    polygon: [
      -51.945,
      -80.572,
      7.17,
      -51.945,
      -80.572,
      10.165,
      -51.427,
      -82.411,
      10.165,
      -51.427,
      -82.411,
      10.165,
      -51.427,
      -82.411,
      7.17,
      -51.945,
      -80.572,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1328_M0010",
    polygon: [
      -52.853,
      -77.35,
      7.17,
      -52.853,
      -77.35,
      10.165,
      -52.335,
      -79.189,
      10.165,
      -52.335,
      -79.189,
      10.165,
      -52.335,
      -79.189,
      7.17,
      -52.853,
      -77.35,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1328_M0011",
    polygon: [
      -53.388,
      -75.449,
      7.17,
      -53.388,
      -75.449,
      10.165,
      -52.871,
      -77.288,
      10.165,
      -52.871,
      -77.288,
      10.165,
      -52.871,
      -77.288,
      7.17,
      -53.388,
      -75.449,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1329_M0010",
    polygon: [
      -54.303,
      -72.201,
      7.17,
      -54.303,
      -72.201,
      10.165,
      -53.786,
      -74.039,
      10.165,
      -53.786,
      -74.039,
      10.165,
      -53.786,
      -74.039,
      7.17,
      -54.303,
      -72.201,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE01O_EZR1329_M0011",
    polygon: [
      -54.839,
      -70.3,
      7.17,
      -54.839,
      -70.3,
      10.165,
      -54.321,
      -72.138,
      10.165,
      -54.321,
      -72.138,
      10.165,
      -54.321,
      -72.138,
      7.17,
      -54.839,
      -70.3,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE01O_EZR1330_M0010",
    polygon: [
      -62.52,
      -71.942,
      7.165,
      -62.52,
      -71.942,
      10.16,
      -60.636,
      -71.411,
      10.16,
      -60.636,
      -71.411,
      10.16,
      -60.636,
      -71.411,
      7.165,
      -62.52,
      -71.942,
      7.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1331_M0010",
    polygon: [
      -64.467,
      -72.49,
      7.165,
      -64.467,
      -72.49,
      10.16,
      -62.583,
      -71.959,
      10.16,
      -62.583,
      -71.959,
      10.16,
      -62.583,
      -71.959,
      7.165,
      -64.467,
      -72.49,
      7.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1332_M0010",
    polygon: [
      -67.723,
      -73.407,
      7.165,
      -67.723,
      -73.407,
      10.16,
      -65.839,
      -72.877,
      10.16,
      -65.839,
      -72.877,
      10.16,
      -65.839,
      -72.877,
      7.165,
      -67.723,
      -73.407,
      7.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE01O_EZR1333_M0010",
    polygon: [
      -69.67,
      -73.956,
      7.165,
      -69.67,
      -73.956,
      10.16,
      -67.785,
      -73.425,
      10.16,
      -67.785,
      -73.425,
      10.16,
      -67.785,
      -73.425,
      7.165,
      -69.67,
      -73.956,
      7.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2101_M0010",
    polygon: [
      -107.566,
      -30.718,
      10.67,
      -107.566,
      -30.718,
      13.665,
      -109.927,
      -31.383,
      13.665,
      -109.927,
      -31.383,
      13.665,
      -109.927,
      -31.383,
      10.67,
      -107.566,
      -30.718,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2102_M0010",
    polygon: [
      -111.293,
      -31.768,
      10.67,
      -111.293,
      -31.768,
      13.665,
      -113.177,
      -32.299,
      13.665,
      -113.177,
      -32.299,
      13.665,
      -113.177,
      -32.299,
      10.67,
      -111.293,
      -31.768,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2103_M0010",
    polygon: [
      -113.24,
      -32.316,
      10.67,
      -113.24,
      -32.316,
      13.665,
      -115.124,
      -32.847,
      13.665,
      -115.124,
      -32.847,
      13.665,
      -115.124,
      -32.847,
      10.67,
      -113.24,
      -32.316,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2104_M0010",
    polygon: [
      -116.491,
      -33.232,
      10.67,
      -116.491,
      -33.232,
      13.665,
      -118.375,
      -33.763,
      13.665,
      -118.375,
      -33.763,
      13.665,
      -118.375,
      -33.763,
      10.67,
      -116.491,
      -33.232,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2105_M0010",
    polygon: [
      -118.438,
      -33.78,
      10.67,
      -118.438,
      -33.78,
      13.665,
      -120.322,
      -34.311,
      13.665,
      -120.322,
      -34.311,
      13.665,
      -120.322,
      -34.311,
      10.67,
      -118.438,
      -33.78,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2106_M0010",
    polygon: [
      -121.686,
      -34.695,
      10.67,
      -121.686,
      -34.695,
      13.665,
      -123.57,
      -35.226,
      13.665,
      -123.57,
      -35.226,
      13.665,
      -123.57,
      -35.226,
      10.67,
      -121.686,
      -34.695,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2106_M0011",
    polygon: [
      -123.633,
      -35.244,
      10.67,
      -123.633,
      -35.244,
      13.665,
      -125.517,
      -35.775,
      13.665,
      -125.517,
      -35.775,
      13.665,
      -125.517,
      -35.775,
      10.67,
      -123.633,
      -35.244,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2106_M0012",
    polygon: [
      -126.111,
      -35.436,
      10.67,
      -126.111,
      -35.436,
      13.665,
      -126.636,
      -33.573,
      13.665,
      -126.636,
      -33.573,
      13.665,
      -126.636,
      -33.573,
      10.67,
      -126.111,
      -35.436,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2106_M0013",
    polygon: [
      -126.654,
      -33.51,
      10.67,
      -126.654,
      -33.51,
      13.665,
      -127.178,
      -31.648,
      13.665,
      -127.178,
      -31.648,
      13.665,
      -127.178,
      -31.648,
      10.67,
      -126.654,
      -33.51,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "LE100xxE02O_EZR2107_M0010",
    polygon: [
      -127.553,
      -30.318,
      10.67,
      -127.553,
      -30.318,
      13.665,
      -128.078,
      -28.455,
      13.665,
      -128.078,
      -28.455,
      13.665,
      -128.078,
      -28.455,
      10.67,
      -127.553,
      -30.318,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2107_M0011",
    polygon: [
      -128.095,
      -28.392,
      10.67,
      -128.095,
      -28.392,
      13.665,
      -128.62,
      -26.53,
      13.665,
      -128.62,
      -26.53,
      13.665,
      -128.62,
      -26.53,
      10.67,
      -128.095,
      -28.392,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2108_M0010",
    polygon: [
      -129.004,
      -25.168,
      10.67,
      -129.004,
      -25.168,
      13.665,
      -129.528,
      -23.305,
      13.665,
      -129.528,
      -23.305,
      13.665,
      -129.528,
      -23.305,
      10.67,
      -129.004,
      -25.168,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2108_M0011",
    polygon: [
      -129.546,
      -23.242,
      10.67,
      -129.546,
      -23.242,
      13.665,
      -130.071,
      -21.38,
      13.665,
      -130.071,
      -21.38,
      13.665,
      -130.071,
      -21.38,
      10.67,
      -129.546,
      -23.242,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2108_M0012",
    polygon: [
      -129.72,
      -20.806,
      10.67,
      -129.72,
      -20.806,
      13.665,
      -127.836,
      -20.276,
      13.665,
      -127.836,
      -20.276,
      13.665,
      -127.836,
      -20.276,
      10.67,
      -129.72,
      -20.806,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2108_M0013",
    polygon: [
      -127.773,
      -20.258,
      10.67,
      -127.773,
      -20.258,
      13.665,
      -125.889,
      -19.727,
      13.665,
      -125.889,
      -19.727,
      13.665,
      -125.889,
      -19.727,
      10.67,
      -127.773,
      -20.258,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2109_M0010",
    polygon: [
      -124.522,
      -19.342,
      10.67,
      -124.522,
      -19.342,
      13.665,
      -122.638,
      -18.812,
      13.665,
      -122.638,
      -18.812,
      13.665,
      -122.638,
      -18.812,
      10.67,
      -124.522,
      -19.342,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2110_M0010",
    polygon: [
      -122.575,
      -18.794,
      10.67,
      -122.575,
      -18.794,
      13.665,
      -120.691,
      -18.263,
      13.665,
      -120.691,
      -18.263,
      13.665,
      -120.691,
      -18.263,
      10.67,
      -122.575,
      -18.794,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2111_M0010",
    polygon: [
      -119.327,
      -17.879,
      10.67,
      -119.327,
      -17.879,
      13.665,
      -117.443,
      -17.348,
      13.665,
      -117.443,
      -17.348,
      13.665,
      -117.443,
      -17.348,
      10.67,
      -119.327,
      -17.879,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2112_M0010",
    polygon: [
      -117.38,
      -17.33,
      10.67,
      -117.38,
      -17.33,
      13.665,
      -115.496,
      -16.8,
      13.665,
      -115.496,
      -16.8,
      13.665,
      -115.496,
      -16.8,
      10.67,
      -117.38,
      -17.33,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2113_M0010",
    polygon: [
      -114.129,
      -16.415,
      10.67,
      -114.129,
      -16.415,
      13.665,
      -112.245,
      -15.884,
      13.665,
      -112.245,
      -15.884,
      13.665,
      -112.245,
      -15.884,
      10.67,
      -114.129,
      -16.415,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2114_M0010",
    polygon: [
      -112.182,
      -15.866,
      10.67,
      -112.182,
      -15.866,
      13.665,
      -110.298,
      -15.336,
      13.665,
      -110.298,
      -15.336,
      13.665,
      -110.298,
      -15.336,
      10.67,
      -112.182,
      -15.866,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2115_M0010",
    polygon: [
      -108.934,
      -14.951,
      10.67,
      -108.934,
      -14.951,
      13.665,
      -107.05,
      -14.421,
      13.665,
      -107.05,
      -14.421,
      13.665,
      -107.05,
      -14.421,
      10.67,
      -108.934,
      -14.951,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2116_M0010",
    polygon: [
      -106.987,
      -14.403,
      10.67,
      -106.987,
      -14.403,
      13.665,
      -105.103,
      -13.872,
      13.665,
      -105.103,
      -13.872,
      13.665,
      -105.103,
      -13.872,
      10.67,
      -106.987,
      -14.403,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2116_M0011",
    polygon: [
      -104.507,
      -14.208,
      10.67,
      -104.507,
      -14.208,
      13.665,
      -104.236,
      -15.171,
      13.665,
      -104.236,
      -15.171,
      13.665,
      -104.236,
      -15.171,
      10.67,
      -104.507,
      -14.208,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2117_M0010",
    polygon: [
      -103.177,
      -15.408,
      10.67,
      -103.177,
      -15.408,
      13.665,
      -101.213,
      -14.854,
      13.665,
      -101.213,
      -14.854,
      13.665,
      -101.213,
      -14.854,
      10.67,
      -103.177,
      -15.408,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2118_M0010",
    polygon: [
      -101.151,
      -14.837,
      10.67,
      -101.151,
      -14.837,
      13.665,
      -99.187,
      -14.284,
      13.665,
      -99.187,
      -14.284,
      13.665,
      -99.187,
      -14.284,
      10.67,
      -101.151,
      -14.837,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2119_M0010",
    polygon: [
      -98.341,
      -11.967,
      10.67,
      -98.341,
      -11.967,
      13.665,
      -96.457,
      -11.437,
      13.665,
      -96.457,
      -11.437,
      13.665,
      -96.457,
      -11.437,
      10.67,
      -98.341,
      -11.967,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2119_M0011",
    polygon: [
      -98.413,
      -13.531,
      10.67,
      -98.413,
      -13.531,
      13.665,
      -98.684,
      -12.568,
      13.665,
      -98.684,
      -12.568,
      13.665,
      -98.684,
      -12.568,
      10.67,
      -98.413,
      -13.531,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2120_M0010",
    polygon: [
      -96.394,
      -11.419,
      10.67,
      -96.394,
      -11.419,
      13.665,
      -94.51,
      -10.888,
      13.665,
      -94.51,
      -10.888,
      13.665,
      -94.51,
      -10.888,
      10.67,
      -96.394,
      -11.419,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2121_M0010",
    polygon: [
      -93.143,
      -10.503,
      10.67,
      -93.143,
      -10.503,
      13.665,
      -91.259,
      -9.973,
      13.665,
      -91.259,
      -9.973,
      13.665,
      -91.259,
      -9.973,
      10.67,
      -93.143,
      -10.503,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2122_M0010",
    polygon: [
      -91.197,
      -9.955,
      10.67,
      -91.197,
      -9.955,
      13.665,
      -89.312,
      -9.424,
      13.665,
      -89.312,
      -9.424,
      13.665,
      -89.312,
      -9.424,
      10.67,
      -91.197,
      -9.955,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2123_M0010",
    polygon: [
      -87.948,
      -9.04,
      10.67,
      -87.948,
      -9.04,
      13.665,
      -86.064,
      -8.509,
      13.665,
      -86.064,
      -8.509,
      13.665,
      -86.064,
      -8.509,
      10.67,
      -87.948,
      -9.04,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2124_M0010",
    polygon: [
      -86.001,
      -8.492,
      10.67,
      -86.001,
      -8.492,
      13.665,
      -84.117,
      -7.961,
      13.665,
      -84.117,
      -7.961,
      13.665,
      -84.117,
      -7.961,
      10.67,
      -86.001,
      -8.492,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2125_M0010",
    polygon: [
      -82.75,
      -7.576,
      10.67,
      -82.75,
      -7.576,
      13.665,
      -80.866,
      -7.045,
      13.665,
      -80.866,
      -7.045,
      13.665,
      -80.866,
      -7.045,
      10.67,
      -82.75,
      -7.576,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2126_M0010",
    polygon: [
      -80.803,
      -7.027,
      10.67,
      -80.803,
      -7.027,
      13.665,
      -78.919,
      -6.497,
      13.665,
      -78.919,
      -6.497,
      13.665,
      -78.919,
      -6.497,
      10.67,
      -80.803,
      -7.027,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2127_M0010",
    polygon: [
      -77.555,
      -6.112,
      10.67,
      -77.555,
      -6.112,
      13.665,
      -75.671,
      -5.582,
      13.665,
      -75.671,
      -5.582,
      13.665,
      -75.671,
      -5.582,
      10.67,
      -77.555,
      -6.112,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2127_M0011",
    polygon: [
      -75.608,
      -5.564,
      10.67,
      -75.608,
      -5.564,
      13.665,
      -73.724,
      -5.033,
      13.665,
      -73.724,
      -5.033,
      13.665,
      -73.724,
      -5.033,
      10.67,
      -75.608,
      -5.564,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2127_M0012",
    polygon: [
      -73.124,
      -5.386,
      10.67,
      -73.124,
      -5.386,
      13.665,
      -72.606,
      -7.224,
      13.665,
      -72.606,
      -7.224,
      13.665,
      -72.606,
      -7.224,
      10.67,
      -73.124,
      -5.386,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2127_M0013",
    polygon: [
      -72.589,
      -7.287,
      10.67,
      -72.589,
      -7.287,
      13.665,
      -72.071,
      -9.125,
      13.665,
      -72.071,
      -9.125,
      13.665,
      -72.071,
      -9.125,
      10.67,
      -72.589,
      -7.287,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2128_M0010",
    polygon: [
      -71.673,
      -10.535,
      10.67,
      -71.673,
      -10.535,
      13.665,
      -71.156,
      -12.374,
      13.665,
      -71.156,
      -12.374,
      13.665,
      -71.156,
      -12.374,
      10.67,
      -71.673,
      -10.535,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2128_M0011",
    polygon: [
      -71.138,
      -12.436,
      10.67,
      -71.138,
      -12.436,
      13.665,
      -70.62,
      -14.275,
      13.665,
      -70.62,
      -14.275,
      13.665,
      -70.62,
      -14.275,
      10.67,
      -71.138,
      -12.436,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2129_M0010",
    polygon: [
      -70.23,
      -15.658,
      10.67,
      -70.23,
      -15.658,
      13.665,
      -69.713,
      -17.497,
      13.665,
      -69.713,
      -17.497,
      13.665,
      -69.713,
      -17.497,
      10.67,
      -70.23,
      -15.658,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2129_M0011",
    polygon: [
      -69.695,
      -17.559,
      10.67,
      -69.695,
      -17.559,
      13.665,
      -69.177,
      -19.398,
      13.665,
      -69.177,
      -19.398,
      13.665,
      -69.177,
      -19.398,
      10.67,
      -69.695,
      -17.559,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2129_M0012",
    polygon: [
      -69.521,
      -20.001,
      10.67,
      -69.521,
      -20.001,
      13.665,
      -71.405,
      -20.532,
      13.665,
      -71.405,
      -20.532,
      13.665,
      -71.405,
      -20.532,
      10.67,
      -69.521,
      -20.001,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2129_M0013",
    polygon: [
      -71.468,
      -20.55,
      10.67,
      -71.468,
      -20.55,
      13.665,
      -73.352,
      -21.081,
      13.665,
      -73.352,
      -21.081,
      13.665,
      -73.352,
      -21.081,
      10.67,
      -71.468,
      -20.55,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2130_M0010",
    polygon: [
      -74.719,
      -21.466,
      10.67,
      -74.719,
      -21.466,
      13.665,
      -76.603,
      -21.996,
      13.665,
      -76.603,
      -21.996,
      13.665,
      -76.603,
      -21.996,
      10.67,
      -74.719,
      -21.466,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
  },
  {
    ID: "LE100xxE02O_EZR2131_M0010",
    polygon: [
      -76.666,
      -22.014,
      10.67,
      -76.666,
      -22.014,
      13.665,
      -78.55,
      -22.545,
      13.665,
      -78.55,
      -22.545,
      13.665,
      -78.55,
      -22.545,
      10.67,
      -76.666,
      -22.014,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "LE100xxE02O_EZR2132_M0010",
    polygon: [
      -79.914,
      -22.929,
      10.67,
      -79.914,
      -22.929,
      13.665,
      -81.799,
      -23.46,
      13.665,
      -81.799,
      -23.46,
      13.665,
      -81.799,
      -23.46,
      10.67,
      -79.914,
      -22.929,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
  },
  {
    ID: "LE100xxE02O_EZR2133_M0010",
    polygon: [
      -81.861,
      -23.477,
      10.67,
      -81.861,
      -23.477,
      13.665,
      -83.745,
      -24.008,
      13.665,
      -83.745,
      -24.008,
      13.665,
      -83.745,
      -24.008,
      10.67,
      -81.861,
      -23.477,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2134_M0010",
    polygon: [
      -85.108,
      -24.392,
      10.67,
      -85.108,
      -24.392,
      13.665,
      -87.469,
      -25.057,
      13.665,
      -87.469,
      -25.057,
      13.665,
      -87.469,
      -25.057,
      10.67,
      -85.108,
      -24.392,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2135_M0010",
    polygon: [
      -87.703,
      -26.115,
      10.67,
      -87.703,
      -26.115,
      13.665,
      -87.221,
      -27.826,
      13.665,
      -87.221,
      -27.826,
      13.665,
      -87.221,
      -27.826,
      10.67,
      -87.703,
      -26.115,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2149_M0010",
    polygon: [
      -106.321,
      -33.206,
      10.67,
      -106.321,
      -33.206,
      13.665,
      -106.803,
      -31.495,
      13.665,
      -106.803,
      -31.495,
      13.665,
      -106.803,
      -31.495,
      10.67,
      -106.321,
      -33.206,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2149_M0011",
    polygon: [
      -105.822,
      -34.98,
      10.67,
      -105.822,
      -34.98,
      13.665,
      -106.304,
      -33.269,
      13.665,
      -106.304,
      -33.269,
      13.665,
      -106.304,
      -33.269,
      10.67,
      -105.822,
      -34.98,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "LE100xxE02O_EZR2149_M0012",
    polygon: [
      -104.956,
      -38.053,
      10.67,
      -104.956,
      -38.053,
      13.665,
      -105.438,
      -36.342,
      13.665,
      -105.438,
      -36.342,
      13.665,
      -105.438,
      -36.342,
      10.67,
      -104.956,
      -38.053,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2201_M0010",
    polygon: [
      -107.578,
      -52.168,
      10.67,
      -107.578,
      -52.168,
      13.665,
      -105.694,
      -51.637,
      13.665,
      -105.694,
      -51.637,
      13.665,
      -105.694,
      -51.637,
      10.67,
      -107.578,
      -52.168,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2202_M0010",
    polygon: [
      -109.525,
      -52.716,
      10.67,
      -109.525,
      -52.716,
      13.665,
      -107.641,
      -52.185,
      13.665,
      -107.641,
      -52.185,
      13.665,
      -107.641,
      -52.185,
      10.67,
      -109.525,
      -52.716,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2203_M0010",
    polygon: [
      -112.774,
      -53.631,
      10.67,
      -112.774,
      -53.631,
      13.665,
      -110.89,
      -53.101,
      13.665,
      -110.89,
      -53.101,
      13.665,
      -110.89,
      -53.101,
      10.67,
      -112.774,
      -53.631,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2204_M0010",
    polygon: [
      -114.721,
      -54.18,
      10.67,
      -114.721,
      -54.18,
      13.665,
      -112.836,
      -53.649,
      13.665,
      -112.836,
      -53.649,
      13.665,
      -112.836,
      -53.649,
      10.67,
      -114.721,
      -54.18,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE02O_EZR2205_M0010",
    polygon: [
      -117.972,
      -55.095,
      10.67,
      -117.972,
      -55.095,
      13.665,
      -116.087,
      -54.565,
      13.665,
      -116.087,
      -54.565,
      13.665,
      -116.087,
      -54.565,
      10.67,
      -117.972,
      -55.095,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE02O_EZR2205_M0011",
    polygon: [
      -119.918,
      -55.644,
      10.67,
      -119.918,
      -55.644,
      13.665,
      -118.034,
      -55.113,
      13.665,
      -118.034,
      -55.113,
      13.665,
      -118.034,
      -55.113,
      10.67,
      -119.918,
      -55.644,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE02O_EZR2205_M0012",
    polygon: [
      -119.731,
      -58.076,
      10.67,
      -119.731,
      -58.076,
      13.665,
      -120.256,
      -56.213,
      13.665,
      -120.256,
      -56.213,
      13.665,
      -120.256,
      -56.213,
      10.67,
      -119.731,
      -58.076,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE02O_EZR2205_M0013",
    polygon: [
      -119.189,
      -60.001,
      10.67,
      -119.189,
      -60.001,
      13.665,
      -119.713,
      -58.139,
      13.665,
      -119.713,
      -58.139,
      13.665,
      -119.713,
      -58.139,
      10.67,
      -119.189,
      -60.001,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "LE100xxE02O_EZR2206_M0010",
    polygon: [
      -118.28,
      -63.226,
      10.67,
      -118.28,
      -63.226,
      13.665,
      -118.805,
      -61.363,
      13.665,
      -118.805,
      -61.363,
      13.665,
      -118.805,
      -61.363,
      10.67,
      -118.28,
      -63.226,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2206_M0011",
    polygon: [
      -117.738,
      -65.151,
      10.67,
      -117.738,
      -65.151,
      13.665,
      -118.262,
      -63.289,
      13.665,
      -118.262,
      -63.289,
      13.665,
      -118.262,
      -63.289,
      10.67,
      -117.738,
      -65.151,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2207_M0010",
    polygon: [
      -116.838,
      -68.344,
      10.67,
      -116.838,
      -68.344,
      13.665,
      -117.363,
      -66.482,
      13.665,
      -117.363,
      -66.482,
      13.665,
      -117.363,
      -66.482,
      10.67,
      -116.838,
      -68.344,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2207_M0011",
    polygon: [
      -116.296,
      -70.27,
      10.67,
      -116.296,
      -70.27,
      13.665,
      -116.821,
      -68.407,
      13.665,
      -116.821,
      -68.407,
      13.665,
      -116.821,
      -68.407,
      10.67,
      -116.296,
      -70.27,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "LE100xxE02O_EZR2207_M0012",
    polygon: [
      -113.81,
      -70.078,
      10.67,
      -113.81,
      -70.078,
      13.665,
      -115.696,
      -70.61,
      13.665,
      -115.696,
      -70.61,
      13.665,
      -115.696,
      -70.61,
      10.67,
      -113.81,
      -70.078,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2207_M0013",
    polygon: [
      -111.861,
      -69.529,
      10.67,
      -111.861,
      -69.529,
      13.665,
      -113.747,
      -70.061,
      13.665,
      -113.747,
      -70.061,
      13.665,
      -113.747,
      -70.061,
      10.67,
      -111.861,
      -69.529,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2208_M0010",
    polygon: [
      -108.612,
      -68.614,
      10.67,
      -108.612,
      -68.614,
      13.665,
      -110.499,
      -69.146,
      13.665,
      -110.499,
      -69.146,
      13.665,
      -110.499,
      -69.146,
      10.67,
      -108.612,
      -68.614,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2209_M0010",
    polygon: [
      -106.663,
      -68.065,
      10.67,
      -106.663,
      -68.065,
      13.665,
      -108.55,
      -68.597,
      13.665,
      -108.55,
      -68.597,
      13.665,
      -108.55,
      -68.597,
      10.67,
      -106.663,
      -68.065,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2210_M0010",
    polygon: [
      -103.414,
      -67.15,
      10.67,
      -103.414,
      -67.15,
      13.665,
      -105.301,
      -67.681,
      13.665,
      -105.301,
      -67.681,
      13.665,
      -105.301,
      -67.681,
      10.67,
      -103.414,
      -67.15,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2211_M0010",
    polygon: [
      -101.465,
      -66.601,
      10.67,
      -101.465,
      -66.601,
      13.665,
      -103.352,
      -67.132,
      13.665,
      -103.352,
      -67.132,
      13.665,
      -103.352,
      -67.132,
      10.67,
      -101.465,
      -66.601,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2212_M0010",
    polygon: [
      -98.217,
      -65.686,
      10.67,
      -98.217,
      -65.686,
      13.665,
      -100.103,
      -66.217,
      13.665,
      -100.103,
      -66.217,
      13.665,
      -100.103,
      -66.217,
      10.67,
      -98.217,
      -65.686,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2213_M0010",
    polygon: [
      -96.268,
      -65.137,
      10.67,
      -96.268,
      -65.137,
      13.665,
      -98.154,
      -65.668,
      13.665,
      -98.154,
      -65.668,
      13.665,
      -98.154,
      -65.668,
      10.67,
      -96.268,
      -65.137,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2214_M0010",
    polygon: [
      -92.547,
      -64.089,
      10.67,
      -92.547,
      -64.089,
      13.665,
      -94.908,
      -64.754,
      13.665,
      -94.908,
      -64.754,
      13.665,
      -94.908,
      -64.754,
      10.67,
      -92.547,
      -64.089,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2215_M0010",
    polygon: [
      -80.484,
      -60.691,
      10.67,
      -80.484,
      -60.691,
      13.665,
      -82.845,
      -61.356,
      13.665,
      -82.845,
      -61.356,
      13.665,
      -82.845,
      -61.356,
      10.67,
      -80.484,
      -60.691,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2216_M0010",
    polygon: [
      -77.236,
      -59.776,
      10.67,
      -77.236,
      -59.776,
      13.665,
      -79.122,
      -60.307,
      13.665,
      -79.122,
      -60.307,
      13.665,
      -79.122,
      -60.307,
      10.67,
      -77.236,
      -59.776,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2217_M0010",
    polygon: [
      -75.286,
      -59.227,
      10.67,
      -75.286,
      -59.227,
      13.665,
      -77.173,
      -59.758,
      13.665,
      -77.173,
      -59.758,
      13.665,
      -77.173,
      -59.758,
      10.67,
      -75.286,
      -59.227,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "LE100xxE02O_EZR2218_M0010",
    polygon: [
      -72.038,
      -58.312,
      10.67,
      -72.038,
      -58.312,
      13.665,
      -73.924,
      -58.843,
      13.665,
      -73.924,
      -58.843,
      13.665,
      -73.924,
      -58.843,
      10.67,
      -72.038,
      -58.312,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2219_M0010",
    polygon: [
      -70.089,
      -57.763,
      10.67,
      -70.089,
      -57.763,
      13.665,
      -71.975,
      -58.294,
      13.665,
      -71.975,
      -58.294,
      13.665,
      -71.975,
      -58.294,
      10.67,
      -70.089,
      -57.763,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
  },
  {
    ID: "LE100xxE02O_EZR2220_M0010",
    polygon: [
      -66.84,
      -56.848,
      10.67,
      -66.84,
      -56.848,
      13.665,
      -68.727,
      -57.379,
      13.665,
      -68.727,
      -57.379,
      13.665,
      -68.727,
      -57.379,
      10.67,
      -66.84,
      -56.848,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2221_M0010",
    polygon: [
      -64.891,
      -56.299,
      10.67,
      -64.891,
      -56.299,
      13.665,
      -66.778,
      -56.83,
      13.665,
      -66.778,
      -56.83,
      13.665,
      -66.778,
      -56.83,
      10.67,
      -64.891,
      -56.299,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2222_M0010",
    polygon: [
      -61.642,
      -55.384,
      10.67,
      -61.642,
      -55.384,
      13.665,
      -63.529,
      -55.915,
      13.665,
      -63.529,
      -55.915,
      13.665,
      -63.529,
      -55.915,
      10.67,
      -61.642,
      -55.384,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2222_M0011",
    polygon: [
      -59.693,
      -54.835,
      10.67,
      -59.693,
      -54.835,
      13.665,
      -61.58,
      -55.366,
      13.665,
      -61.58,
      -55.366,
      13.665,
      -61.58,
      -55.366,
      10.67,
      -59.693,
      -54.835,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2222_M0012",
    polygon: [
      -59.885,
      -52.395,
      10.67,
      -59.885,
      -52.395,
      13.665,
      -59.368,
      -54.233,
      13.665,
      -59.368,
      -54.233,
      13.665,
      -59.368,
      -54.233,
      10.67,
      -59.885,
      -52.395,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2222_M0013",
    polygon: [
      -60.421,
      -50.494,
      10.67,
      -60.421,
      -50.494,
      13.665,
      -59.903,
      -52.332,
      13.665,
      -59.903,
      -52.332,
      13.665,
      -59.903,
      -52.332,
      10.67,
      -60.421,
      -50.494,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2223_M0010",
    polygon: [
      -61.329,
      -47.272,
      10.67,
      -61.329,
      -47.272,
      13.665,
      -60.811,
      -49.11,
      13.665,
      -60.811,
      -49.11,
      13.665,
      -60.811,
      -49.11,
      10.67,
      -61.329,
      -47.272,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2223_M0011",
    polygon: [
      -61.864,
      -45.371,
      10.67,
      -61.864,
      -45.371,
      13.665,
      -61.346,
      -47.209,
      13.665,
      -61.346,
      -47.209,
      13.665,
      -61.346,
      -47.209,
      10.67,
      -61.864,
      -45.371,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2224_M0010",
    polygon: [
      -62.779,
      -42.122,
      10.67,
      -62.779,
      -42.122,
      13.665,
      -62.261,
      -43.96,
      13.665,
      -62.261,
      -43.96,
      13.665,
      -62.261,
      -43.96,
      10.67,
      -62.779,
      -42.122,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2224_M0011",
    polygon: [
      -63.315,
      -40.221,
      10.67,
      -63.315,
      -40.221,
      13.665,
      -62.797,
      -42.059,
      13.665,
      -62.797,
      -42.059,
      13.665,
      -62.797,
      -42.059,
      10.67,
      -63.315,
      -40.221,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2224_M0012",
    polygon: [
      -65.802,
      -40.4,
      10.67,
      -65.802,
      -40.4,
      13.665,
      -63.918,
      -39.869,
      13.665,
      -63.918,
      -39.869,
      13.665,
      -63.918,
      -39.869,
      10.67,
      -65.802,
      -40.4,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2224_M0013",
    polygon: [
      -67.748,
      -40.948,
      10.67,
      -67.748,
      -40.948,
      13.665,
      -65.864,
      -40.418,
      13.665,
      -65.864,
      -40.418,
      13.665,
      -65.864,
      -40.418,
      10.67,
      -67.748,
      -40.948,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2225_M0010",
    polygon: [
      -70.997,
      -41.864,
      10.67,
      -70.997,
      -41.864,
      13.665,
      -69.113,
      -41.333,
      13.665,
      -69.113,
      -41.333,
      13.665,
      -69.113,
      -41.333,
      10.67,
      -70.997,
      -41.864,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2226_M0010",
    polygon: [
      -72.944,
      -42.412,
      10.67,
      -72.944,
      -42.412,
      13.665,
      -71.06,
      -41.881,
      13.665,
      -71.06,
      -41.881,
      13.665,
      -71.06,
      -41.881,
      10.67,
      -72.944,
      -42.412,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2227_M0010",
    polygon: [
      -76.195,
      -43.328,
      10.67,
      -76.195,
      -43.328,
      13.665,
      -74.311,
      -42.797,
      13.665,
      -74.311,
      -42.797,
      13.665,
      -74.311,
      -42.797,
      10.67,
      -76.195,
      -43.328,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2228_M0010",
    polygon: [
      -78.142,
      -43.876,
      10.67,
      -78.142,
      -43.876,
      13.665,
      -76.257,
      -43.345,
      13.665,
      -76.257,
      -43.345,
      13.665,
      -76.257,
      -43.345,
      10.67,
      -78.142,
      -43.876,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2229_M0010",
    polygon: [
      -81.869,
      -44.926,
      10.67,
      -81.869,
      -44.926,
      13.665,
      -79.508,
      -44.261,
      13.665,
      -79.508,
      -44.261,
      13.665,
      -79.508,
      -44.261,
      10.67,
      -81.869,
      -44.926,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2230_M0010",
    polygon: [
      -83.106,
      -42.433,
      10.67,
      -83.106,
      -42.433,
      13.665,
      -82.624,
      -44.144,
      13.665,
      -82.624,
      -44.144,
      13.665,
      -82.624,
      -44.144,
      10.67,
      -83.106,
      -42.433,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2231_M0010",
    polygon: [
      -83.606,
      -40.659,
      10.67,
      -83.606,
      -40.659,
      13.665,
      -83.124,
      -42.37,
      13.665,
      -83.124,
      -42.37,
      13.665,
      -83.124,
      -42.37,
      10.67,
      -83.606,
      -40.659,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2231_M0011",
    polygon: [
      -84.472,
      -37.586,
      10.67,
      -84.472,
      -37.586,
      13.665,
      -83.99,
      -39.297,
      13.665,
      -83.99,
      -39.297,
      13.665,
      -83.99,
      -39.297,
      10.67,
      -84.472,
      -37.586,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2232_M0010",
    polygon: [
      -84.971,
      -35.813,
      10.67,
      -84.971,
      -35.813,
      13.665,
      -84.489,
      -37.524,
      13.665,
      -84.489,
      -37.524,
      13.665,
      -84.489,
      -37.524,
      10.67,
      -84.971,
      -35.813,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
  },
  {
    ID: "LE100xxE02O_EZR2232_M0011",
    polygon: [
      -85.838,
      -32.735,
      10.67,
      -85.838,
      -32.735,
      13.665,
      -85.356,
      -34.446,
      13.665,
      -85.356,
      -34.446,
      13.665,
      -85.356,
      -34.446,
      10.67,
      -85.838,
      -32.735,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "LE100xxE02O_EZR2233_M0010",
    polygon: [
      -87.203,
      -27.889,
      10.67,
      -87.203,
      -27.889,
      13.665,
      -86.721,
      -29.6,
      13.665,
      -86.721,
      -29.6,
      13.665,
      -86.721,
      -29.6,
      10.67,
      -87.203,
      -27.889,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2233_M0011",
    polygon: [
      -86.338,
      -30.962,
      10.67,
      -86.338,
      -30.962,
      13.665,
      -85.856,
      -32.672,
      13.665,
      -85.856,
      -32.672,
      13.665,
      -85.856,
      -32.672,
      10.67,
      -86.338,
      -30.962,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2234_M0010",
    polygon: [
      -104.457,
      -39.826,
      10.67,
      -104.457,
      -39.826,
      13.665,
      -104.939,
      -38.115,
      13.665,
      -104.939,
      -38.115,
      13.665,
      -104.939,
      -38.115,
      10.67,
      -104.457,
      -39.826,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
  },
  {
    ID: "LE100xxE02O_EZR2235_M0010",
    polygon: [
      -103.59,
      -42.904,
      10.67,
      -103.59,
      -42.904,
      13.665,
      -104.072,
      -41.193,
      13.665,
      -104.072,
      -41.193,
      13.665,
      -104.072,
      -41.193,
      10.67,
      -103.59,
      -42.904,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2236_M0010",
    polygon: [
      -103.09,
      -44.677,
      10.67,
      -103.09,
      -44.677,
      13.665,
      -103.572,
      -42.967,
      13.665,
      -103.572,
      -42.967,
      13.665,
      -103.572,
      -42.967,
      10.67,
      -103.09,
      -44.677,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE02O_EZR2237_M0010",
    polygon: [
      -101.725,
      -49.524,
      10.67,
      -101.725,
      -49.524,
      13.665,
      -102.207,
      -47.813,
      13.665,
      -102.207,
      -47.813,
      13.665,
      -102.207,
      -47.813,
      10.67,
      -101.725,
      -49.524,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2237_M0011",
    polygon: [
      -102.225,
      -47.75,
      10.67,
      -102.225,
      -47.75,
      13.665,
      -102.707,
      -46.039,
      13.665,
      -102.707,
      -46.039,
      13.665,
      -102.707,
      -46.039,
      10.67,
      -102.225,
      -47.75,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "LE100xxE02O_EZR2251_M0010",
    polygon: [
      -104.327,
      -51.252,
      10.67,
      -104.327,
      -51.252,
      13.665,
      -101.967,
      -50.587,
      13.665,
      -101.967,
      -50.587,
      13.665,
      -101.967,
      -50.587,
      10.67,
      -104.327,
      -51.252,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2301_M0010",
    polygon: [
      -93.897,
      -80.78,
      10.665,
      -93.897,
      -80.78,
      13.66,
      -92.012,
      -80.249,
      13.66,
      -92.012,
      -80.249,
      13.66,
      -92.012,
      -80.249,
      10.665,
      -93.897,
      -80.78,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2301_M0011",
    polygon: [
      -95.843,
      -81.328,
      10.665,
      -95.843,
      -81.328,
      13.66,
      -93.959,
      -80.798,
      13.66,
      -93.959,
      -80.798,
      13.66,
      -93.959,
      -80.798,
      10.665,
      -95.843,
      -81.328,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2302_M0010",
    polygon: [
      -99.094,
      -82.244,
      10.665,
      -99.094,
      -82.244,
      13.66,
      -97.21,
      -81.713,
      13.66,
      -97.21,
      -81.713,
      13.66,
      -97.21,
      -81.713,
      10.665,
      -99.094,
      -82.244,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2303_M0010",
    polygon: [
      -101.041,
      -82.792,
      10.665,
      -101.041,
      -82.792,
      13.66,
      -99.157,
      -82.262,
      13.66,
      -99.157,
      -82.262,
      13.66,
      -99.157,
      -82.262,
      10.665,
      -101.041,
      -82.792,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2304_M0010",
    polygon: [
      -104.297,
      -83.71,
      10.665,
      -104.297,
      -83.71,
      13.66,
      -102.413,
      -83.179,
      13.66,
      -102.413,
      -83.179,
      13.66,
      -102.413,
      -83.179,
      10.665,
      -104.297,
      -83.71,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2305_M0010",
    polygon: [
      -106.244,
      -84.258,
      10.665,
      -106.244,
      -84.258,
      13.66,
      -104.36,
      -83.727,
      13.66,
      -104.36,
      -83.727,
      13.66,
      -104.36,
      -83.727,
      10.665,
      -106.244,
      -84.258,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE02O_EZR2306_M0010",
    polygon: [
      -109.495,
      -85.174,
      10.665,
      -109.495,
      -85.174,
      13.66,
      -107.611,
      -84.643,
      13.66,
      -107.611,
      -84.643,
      13.66,
      -107.611,
      -84.643,
      10.665,
      -109.495,
      -85.174,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE02O_EZR2306_M0011",
    polygon: [
      -111.442,
      -85.722,
      10.665,
      -111.442,
      -85.722,
      13.66,
      -109.557,
      -85.191,
      13.66,
      -109.557,
      -85.191,
      13.66,
      -109.557,
      -85.191,
      10.665,
      -111.442,
      -85.722,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE02O_EZR2306_M0012",
    polygon: [
      -111.261,
      -88.156,
      10.67,
      -111.261,
      -88.156,
      13.665,
      -111.786,
      -86.293,
      13.665,
      -111.786,
      -86.293,
      13.665,
      -111.786,
      -86.293,
      10.67,
      -111.261,
      -88.156,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2306_M0013",
    polygon: [
      -110.719,
      -90.081,
      10.67,
      -110.719,
      -90.081,
      13.665,
      -111.243,
      -88.219,
      13.665,
      -111.243,
      -88.219,
      13.665,
      -111.243,
      -88.219,
      10.67,
      -110.719,
      -90.081,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "LE100xxE02O_EZR2307_M0010",
    polygon: [
      -109.81,
      -93.306,
      10.67,
      -109.81,
      -93.306,
      13.665,
      -110.335,
      -91.443,
      13.665,
      -110.335,
      -91.443,
      13.665,
      -110.335,
      -91.443,
      10.67,
      -109.81,
      -93.306,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE100xxE02O_EZR2307_M0011",
    polygon: [
      -109.268,
      -95.232,
      10.67,
      -109.268,
      -95.232,
      13.665,
      -109.792,
      -93.369,
      13.665,
      -109.792,
      -93.369,
      13.665,
      -109.792,
      -93.369,
      10.67,
      -109.268,
      -95.232,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2308_M0010",
    polygon: [
      -108.368,
      -98.424,
      10.67,
      -108.368,
      -98.424,
      13.665,
      -108.893,
      -96.562,
      13.665,
      -108.893,
      -96.562,
      13.665,
      -108.893,
      -96.562,
      10.67,
      -108.368,
      -98.424,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2308_M0011",
    polygon: [
      -107.826,
      -100.35,
      10.67,
      -107.826,
      -100.35,
      13.665,
      -108.351,
      -98.487,
      13.665,
      -108.351,
      -98.487,
      13.665,
      -108.351,
      -98.487,
      10.67,
      -107.826,
      -100.35,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2308_M0012",
    polygon: [
      -105.339,
      -100.158,
      10.67,
      -105.339,
      -100.158,
      13.665,
      -107.225,
      -100.69,
      13.665,
      -107.225,
      -100.69,
      13.665,
      -107.225,
      -100.69,
      10.67,
      -105.339,
      -100.158,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2308_M0013",
    polygon: [
      -103.392,
      -99.61,
      10.67,
      -103.392,
      -99.61,
      13.665,
      -105.278,
      -100.141,
      13.665,
      -105.278,
      -100.141,
      13.665,
      -105.278,
      -100.141,
      10.67,
      -103.392,
      -99.61,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2309_M0010",
    polygon: [
      -100.14,
      -98.694,
      10.67,
      -100.14,
      -98.694,
      13.665,
      -102.027,
      -99.225,
      13.665,
      -102.027,
      -99.225,
      13.665,
      -102.027,
      -99.225,
      10.67,
      -100.14,
      -98.694,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2310_M0010",
    polygon: [
      -98.194,
      -98.146,
      10.67,
      -98.194,
      -98.146,
      13.665,
      -100.08,
      -98.677,
      13.665,
      -100.08,
      -98.677,
      13.665,
      -100.08,
      -98.677,
      10.67,
      -98.194,
      -98.146,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2311_M0010",
    polygon: [
      -94.943,
      -97.23,
      10.67,
      -94.943,
      -97.23,
      13.665,
      -96.83,
      -97.761,
      13.665,
      -96.83,
      -97.761,
      13.665,
      -96.83,
      -97.761,
      10.67,
      -94.943,
      -97.23,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2312_M0010",
    polygon: [
      -92.996,
      -96.682,
      10.67,
      -92.996,
      -96.682,
      13.665,
      -94.883,
      -97.213,
      13.665,
      -94.883,
      -97.213,
      13.665,
      -94.883,
      -97.213,
      10.67,
      -92.996,
      -96.682,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2313_M0010",
    polygon: [
      -89.745,
      -95.766,
      10.67,
      -89.745,
      -95.766,
      13.665,
      -91.632,
      -96.297,
      13.665,
      -91.632,
      -96.297,
      13.665,
      -91.632,
      -96.297,
      10.67,
      -89.745,
      -95.766,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2314_M0010",
    polygon: [
      -87.798,
      -95.217,
      10.67,
      -87.798,
      -95.217,
      13.665,
      -89.685,
      -95.749,
      13.665,
      -89.685,
      -95.749,
      13.665,
      -89.685,
      -95.749,
      10.67,
      -87.798,
      -95.217,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2315_M0010",
    polygon: [
      -84.547,
      -94.302,
      10.67,
      -84.547,
      -94.302,
      13.665,
      -86.434,
      -94.833,
      13.665,
      -86.434,
      -94.833,
      13.665,
      -86.434,
      -94.833,
      10.67,
      -84.547,
      -94.302,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2316_M0010",
    polygon: [
      -82.601,
      -93.753,
      10.67,
      -82.601,
      -93.753,
      13.665,
      -84.487,
      -94.285,
      13.665,
      -84.487,
      -94.285,
      13.665,
      -84.487,
      -94.285,
      10.67,
      -82.601,
      -93.753,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2316_M0011",
    polygon: [
      -82.54,
      -92.194,
      10.67,
      -82.54,
      -92.194,
      13.665,
      -82.269,
      -93.156,
      13.665,
      -82.269,
      -93.156,
      13.665,
      -82.269,
      -93.156,
      10.67,
      -82.54,
      -92.194,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
  },
  {
    ID: "LE100xxE02O_EZR2317_M0010",
    polygon: [
      -79.797,
      -90.886,
      10.67,
      -79.797,
      -90.886,
      13.665,
      -81.761,
      -91.439,
      13.665,
      -81.761,
      -91.439,
      13.665,
      -81.761,
      -91.439,
      10.67,
      -79.797,
      -90.886,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
  },
  {
    ID: "LE100xxE02O_EZR2318_M0010",
    polygon: [
      -77.771,
      -90.315,
      10.67,
      -77.771,
      -90.315,
      13.665,
      -79.734,
      -90.868,
      13.665,
      -79.734,
      -90.868,
      13.665,
      -79.734,
      -90.868,
      10.67,
      -77.771,
      -90.315,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
  },
  {
    ID: "LE100xxE02O_EZR2319_M0010",
    polygon: [
      -73.96,
      -91.319,
      10.67,
      -73.96,
      -91.319,
      13.665,
      -75.846,
      -91.851,
      13.665,
      -75.846,
      -91.851,
      13.665,
      -75.846,
      -91.851,
      10.67,
      -73.96,
      -91.319,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2319_M0011",
    polygon: [
      -76.446,
      -91.516,
      10.67,
      -76.446,
      -91.516,
      13.665,
      -76.717,
      -90.553,
      13.665,
      -76.717,
      -90.553,
      13.665,
      -76.717,
      -90.553,
      10.67,
      -76.446,
      -91.516,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2320_M0010",
    polygon: [
      -72.013,
      -90.771,
      10.67,
      -72.013,
      -90.771,
      13.665,
      -73.9,
      -91.302,
      13.665,
      -73.9,
      -91.302,
      13.665,
      -73.9,
      -91.302,
      10.67,
      -72.013,
      -90.771,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2321_M0010",
    polygon: [
      -68.762,
      -89.855,
      10.67,
      -68.762,
      -89.855,
      13.665,
      -70.648,
      -90.386,
      13.665,
      -70.648,
      -90.386,
      13.665,
      -70.648,
      -90.386,
      10.67,
      -68.762,
      -89.855,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2322_M0010",
    polygon: [
      -66.815,
      -89.307,
      10.67,
      -66.815,
      -89.307,
      13.665,
      -68.701,
      -89.838,
      13.665,
      -68.701,
      -89.838,
      13.665,
      -68.701,
      -89.838,
      10.67,
      -66.815,
      -89.307,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2323_M0010",
    polygon: [
      -63.564,
      -88.391,
      10.67,
      -63.564,
      -88.391,
      13.665,
      -65.451,
      -88.922,
      13.665,
      -65.451,
      -88.922,
      13.665,
      -65.451,
      -88.922,
      10.67,
      -63.564,
      -88.391,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2324_M0010",
    polygon: [
      -61.618,
      -87.843,
      10.67,
      -61.618,
      -87.843,
      13.665,
      -63.504,
      -88.374,
      13.665,
      -63.504,
      -88.374,
      13.665,
      -63.504,
      -88.374,
      10.67,
      -61.618,
      -87.843,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2325_M0010",
    polygon: [
      -58.366,
      -86.927,
      10.67,
      -58.366,
      -86.927,
      13.665,
      -60.253,
      -87.458,
      13.665,
      -60.253,
      -87.458,
      13.665,
      -60.253,
      -87.458,
      10.67,
      -58.366,
      -86.927,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2326_M0010",
    polygon: [
      -56.419,
      -86.378,
      10.67,
      -56.419,
      -86.378,
      13.665,
      -58.306,
      -86.91,
      13.665,
      -58.306,
      -86.91,
      13.665,
      -58.306,
      -86.91,
      10.67,
      -56.419,
      -86.378,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2327_M0010",
    polygon: [
      -53.168,
      -85.463,
      10.67,
      -53.168,
      -85.463,
      13.665,
      -55.055,
      -85.994,
      13.665,
      -55.055,
      -85.994,
      13.665,
      -55.055,
      -85.994,
      10.67,
      -53.168,
      -85.463,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE02O_EZR2327_M0011",
    polygon: [
      -51.222,
      -84.914,
      10.67,
      -51.222,
      -84.914,
      13.665,
      -53.108,
      -85.446,
      13.665,
      -53.108,
      -85.446,
      13.665,
      -53.108,
      -85.446,
      10.67,
      -51.222,
      -84.914,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE02O_EZR2327_M0012",
    polygon: [
      -51.41,
      -82.473,
      10.67,
      -51.41,
      -82.473,
      13.665,
      -50.892,
      -84.312,
      13.665,
      -50.892,
      -84.312,
      13.665,
      -50.892,
      -84.312,
      10.67,
      -51.41,
      -82.473,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2327_M0013",
    polygon: [
      -51.945,
      -80.572,
      10.67,
      -51.945,
      -80.572,
      13.665,
      -51.427,
      -82.411,
      13.665,
      -51.427,
      -82.411,
      13.665,
      -51.427,
      -82.411,
      10.67,
      -51.945,
      -80.572,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2328_M0010",
    polygon: [
      -52.853,
      -77.35,
      10.67,
      -52.853,
      -77.35,
      13.665,
      -52.335,
      -79.189,
      13.665,
      -52.335,
      -79.189,
      13.665,
      -52.335,
      -79.189,
      10.67,
      -52.853,
      -77.35,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2328_M0011",
    polygon: [
      -53.388,
      -75.449,
      10.67,
      -53.388,
      -75.449,
      13.665,
      -52.871,
      -77.288,
      13.665,
      -52.871,
      -77.288,
      13.665,
      -52.871,
      -77.288,
      10.67,
      -53.388,
      -75.449,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2329_M0010",
    polygon: [
      -54.303,
      -72.201,
      10.67,
      -54.303,
      -72.201,
      13.665,
      -53.786,
      -74.039,
      13.665,
      -53.786,
      -74.039,
      13.665,
      -53.786,
      -74.039,
      10.67,
      -54.303,
      -72.201,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE02O_EZR2329_M0011",
    polygon: [
      -54.839,
      -70.3,
      10.67,
      -54.839,
      -70.3,
      13.665,
      -54.321,
      -72.138,
      13.665,
      -54.321,
      -72.138,
      13.665,
      -54.321,
      -72.138,
      10.67,
      -54.839,
      -70.3,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE02O_EZR2329_M0012",
    polygon: [
      -57.322,
      -70.478,
      10.665,
      -57.322,
      -70.478,
      13.66,
      -55.438,
      -69.947,
      13.66,
      -55.438,
      -69.947,
      13.66,
      -55.438,
      -69.947,
      10.665,
      -57.322,
      -70.478,
      10.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2329_M0013",
    polygon: [
      -59.269,
      -71.026,
      10.665,
      -59.269,
      -71.026,
      13.66,
      -57.385,
      -70.495,
      13.66,
      -57.385,
      -70.495,
      13.66,
      -57.385,
      -70.495,
      10.665,
      -59.269,
      -71.026,
      10.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2330_M0010",
    polygon: [
      -62.52,
      -71.942,
      10.665,
      -62.52,
      -71.942,
      13.66,
      -60.636,
      -71.411,
      13.66,
      -60.636,
      -71.411,
      13.66,
      -60.636,
      -71.411,
      10.665,
      -62.52,
      -71.942,
      10.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2331_M0010",
    polygon: [
      -64.467,
      -72.49,
      10.665,
      -64.467,
      -72.49,
      13.66,
      -62.583,
      -71.959,
      13.66,
      -62.583,
      -71.959,
      13.66,
      -62.583,
      -71.959,
      10.665,
      -64.467,
      -72.49,
      10.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2332_M0010",
    polygon: [
      -67.723,
      -73.407,
      10.665,
      -67.723,
      -73.407,
      13.66,
      -65.839,
      -72.877,
      13.66,
      -65.839,
      -72.877,
      13.66,
      -65.839,
      -72.877,
      10.665,
      -67.723,
      -73.407,
      10.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE02O_EZR2333_M0010",
    polygon: [
      -69.67,
      -73.956,
      10.665,
      -69.67,
      -73.956,
      13.66,
      -67.785,
      -73.425,
      13.66,
      -67.785,
      -73.425,
      13.66,
      -67.785,
      -73.425,
      10.665,
      -69.67,
      -73.956,
      10.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3101_M0010",
    polygon: [
      -107.566,
      -30.718,
      14.17,
      -107.566,
      -30.718,
      17.165,
      -109.927,
      -31.383,
      17.165,
      -109.927,
      -31.383,
      17.165,
      -109.927,
      -31.383,
      14.17,
      -107.566,
      -30.718,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3102_M0010",
    polygon: [
      -111.293,
      -31.768,
      14.17,
      -111.293,
      -31.768,
      17.165,
      -113.177,
      -32.299,
      17.165,
      -113.177,
      -32.299,
      17.165,
      -113.177,
      -32.299,
      14.17,
      -111.293,
      -31.768,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3103_M0010",
    polygon: [
      -113.24,
      -32.316,
      14.17,
      -113.24,
      -32.316,
      17.165,
      -115.124,
      -32.847,
      17.165,
      -115.124,
      -32.847,
      17.165,
      -115.124,
      -32.847,
      14.17,
      -113.24,
      -32.316,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3104_M0010",
    polygon: [
      -116.491,
      -33.232,
      14.17,
      -116.491,
      -33.232,
      17.165,
      -118.375,
      -33.763,
      17.165,
      -118.375,
      -33.763,
      17.165,
      -118.375,
      -33.763,
      14.17,
      -116.491,
      -33.232,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3105_M0010",
    polygon: [
      -118.438,
      -33.78,
      14.17,
      -118.438,
      -33.78,
      17.165,
      -120.322,
      -34.311,
      17.165,
      -120.322,
      -34.311,
      17.165,
      -120.322,
      -34.311,
      14.17,
      -118.438,
      -33.78,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3106_M0010",
    polygon: [
      -121.686,
      -34.695,
      14.17,
      -121.686,
      -34.695,
      17.165,
      -123.57,
      -35.226,
      17.165,
      -123.57,
      -35.226,
      17.165,
      -123.57,
      -35.226,
      14.17,
      -121.686,
      -34.695,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3106_M0011",
    polygon: [
      -123.633,
      -35.244,
      14.17,
      -123.633,
      -35.244,
      17.165,
      -125.517,
      -35.775,
      17.165,
      -125.517,
      -35.775,
      17.165,
      -125.517,
      -35.775,
      14.17,
      -123.633,
      -35.244,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3106_M0012",
    polygon: [
      -126.111,
      -35.436,
      14.17,
      -126.111,
      -35.436,
      17.165,
      -126.636,
      -33.573,
      17.165,
      -126.636,
      -33.573,
      17.165,
      -126.636,
      -33.573,
      14.17,
      -126.111,
      -35.436,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3106_M0013",
    polygon: [
      -126.654,
      -33.51,
      14.17,
      -126.654,
      -33.51,
      17.165,
      -127.178,
      -31.648,
      17.165,
      -127.178,
      -31.648,
      17.165,
      -127.178,
      -31.648,
      14.17,
      -126.654,
      -33.51,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3107_M0010",
    polygon: [
      -127.553,
      -30.318,
      14.17,
      -127.553,
      -30.318,
      17.165,
      -128.078,
      -28.455,
      17.165,
      -128.078,
      -28.455,
      17.165,
      -128.078,
      -28.455,
      14.17,
      -127.553,
      -30.318,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3107_M0011",
    polygon: [
      -128.095,
      -28.392,
      14.17,
      -128.095,
      -28.392,
      17.165,
      -128.62,
      -26.53,
      17.165,
      -128.62,
      -26.53,
      17.165,
      -128.62,
      -26.53,
      14.17,
      -128.095,
      -28.392,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3108_M0010",
    polygon: [
      -129.004,
      -25.168,
      14.17,
      -129.004,
      -25.168,
      17.165,
      -129.528,
      -23.305,
      17.165,
      -129.528,
      -23.305,
      17.165,
      -129.528,
      -23.305,
      14.17,
      -129.004,
      -25.168,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3108_M0011",
    polygon: [
      -129.546,
      -23.242,
      14.17,
      -129.546,
      -23.242,
      17.165,
      -130.071,
      -21.38,
      17.165,
      -130.071,
      -21.38,
      17.165,
      -130.071,
      -21.38,
      14.17,
      -129.546,
      -23.242,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3108_M0012",
    polygon: [
      -129.72,
      -20.806,
      14.17,
      -129.72,
      -20.806,
      17.165,
      -127.836,
      -20.276,
      17.165,
      -127.836,
      -20.276,
      17.165,
      -127.836,
      -20.276,
      14.17,
      -129.72,
      -20.806,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3108_M0013",
    polygon: [
      -127.773,
      -20.258,
      14.17,
      -127.773,
      -20.258,
      17.165,
      -125.889,
      -19.727,
      17.165,
      -125.889,
      -19.727,
      17.165,
      -125.889,
      -19.727,
      14.17,
      -127.773,
      -20.258,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3109_M0010",
    polygon: [
      -124.522,
      -19.342,
      14.17,
      -124.522,
      -19.342,
      17.165,
      -122.638,
      -18.812,
      17.165,
      -122.638,
      -18.812,
      17.165,
      -122.638,
      -18.812,
      14.17,
      -124.522,
      -19.342,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3110_M0010",
    polygon: [
      -122.575,
      -18.794,
      14.17,
      -122.575,
      -18.794,
      17.165,
      -120.691,
      -18.263,
      17.165,
      -120.691,
      -18.263,
      17.165,
      -120.691,
      -18.263,
      14.17,
      -122.575,
      -18.794,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3111_M0010",
    polygon: [
      -119.327,
      -17.879,
      14.17,
      -119.327,
      -17.879,
      17.165,
      -117.443,
      -17.348,
      17.165,
      -117.443,
      -17.348,
      17.165,
      -117.443,
      -17.348,
      14.17,
      -119.327,
      -17.879,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3112_M0010",
    polygon: [
      -117.38,
      -17.33,
      14.17,
      -117.38,
      -17.33,
      17.165,
      -115.496,
      -16.8,
      17.165,
      -115.496,
      -16.8,
      17.165,
      -115.496,
      -16.8,
      14.17,
      -117.38,
      -17.33,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3113_M0010",
    polygon: [
      -114.129,
      -16.415,
      14.17,
      -114.129,
      -16.415,
      17.165,
      -112.245,
      -15.884,
      17.165,
      -112.245,
      -15.884,
      17.165,
      -112.245,
      -15.884,
      14.17,
      -114.129,
      -16.415,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3114_M0010",
    polygon: [
      -112.182,
      -15.866,
      14.17,
      -112.182,
      -15.866,
      17.165,
      -110.298,
      -15.336,
      17.165,
      -110.298,
      -15.336,
      17.165,
      -110.298,
      -15.336,
      14.17,
      -112.182,
      -15.866,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3115_M0010",
    polygon: [
      -108.934,
      -14.951,
      14.17,
      -108.934,
      -14.951,
      17.165,
      -107.05,
      -14.421,
      17.165,
      -107.05,
      -14.421,
      17.165,
      -107.05,
      -14.421,
      14.17,
      -108.934,
      -14.951,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3116_M0010",
    polygon: [
      -106.987,
      -14.403,
      14.17,
      -106.987,
      -14.403,
      17.165,
      -105.103,
      -13.872,
      17.165,
      -105.103,
      -13.872,
      17.165,
      -105.103,
      -13.872,
      14.17,
      -106.987,
      -14.403,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3116_M0011",
    polygon: [
      -104.507,
      -14.208,
      14.17,
      -104.507,
      -14.208,
      17.165,
      -104.236,
      -15.171,
      17.165,
      -104.236,
      -15.171,
      17.165,
      -104.236,
      -15.171,
      14.17,
      -104.507,
      -14.208,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3117_M0010",
    polygon: [
      -103.177,
      -15.408,
      14.17,
      -103.177,
      -15.408,
      17.165,
      -101.213,
      -14.854,
      17.165,
      -101.213,
      -14.854,
      17.165,
      -101.213,
      -14.854,
      14.17,
      -103.177,
      -15.408,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3118_M0010",
    polygon: [
      -101.151,
      -14.837,
      14.17,
      -101.151,
      -14.837,
      17.165,
      -99.187,
      -14.284,
      17.165,
      -99.187,
      -14.284,
      17.165,
      -99.187,
      -14.284,
      14.17,
      -101.151,
      -14.837,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3119_M0010",
    polygon: [
      -98.341,
      -11.967,
      14.17,
      -98.341,
      -11.967,
      17.165,
      -96.457,
      -11.437,
      17.165,
      -96.457,
      -11.437,
      17.165,
      -96.457,
      -11.437,
      14.17,
      -98.341,
      -11.967,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3119_M0011",
    polygon: [
      -98.413,
      -13.531,
      14.17,
      -98.413,
      -13.531,
      17.165,
      -98.684,
      -12.568,
      17.165,
      -98.684,
      -12.568,
      17.165,
      -98.684,
      -12.568,
      14.17,
      -98.413,
      -13.531,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3120_M0010",
    polygon: [
      -96.394,
      -11.419,
      14.17,
      -96.394,
      -11.419,
      17.165,
      -94.51,
      -10.888,
      17.165,
      -94.51,
      -10.888,
      17.165,
      -94.51,
      -10.888,
      14.17,
      -96.394,
      -11.419,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3121_M0010",
    polygon: [
      -93.143,
      -10.503,
      14.17,
      -93.143,
      -10.503,
      17.165,
      -91.259,
      -9.973,
      17.165,
      -91.259,
      -9.973,
      17.165,
      -91.259,
      -9.973,
      14.17,
      -93.143,
      -10.503,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3122_M0010",
    polygon: [
      -91.197,
      -9.955,
      14.17,
      -91.197,
      -9.955,
      17.165,
      -89.312,
      -9.424,
      17.165,
      -89.312,
      -9.424,
      17.165,
      -89.312,
      -9.424,
      14.17,
      -91.197,
      -9.955,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3123_M0010",
    polygon: [
      -87.948,
      -9.04,
      14.17,
      -87.948,
      -9.04,
      17.165,
      -86.064,
      -8.509,
      17.165,
      -86.064,
      -8.509,
      17.165,
      -86.064,
      -8.509,
      14.17,
      -87.948,
      -9.04,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3124_M0010",
    polygon: [
      -86.001,
      -8.492,
      14.17,
      -86.001,
      -8.492,
      17.165,
      -84.117,
      -7.961,
      17.165,
      -84.117,
      -7.961,
      17.165,
      -84.117,
      -7.961,
      14.17,
      -86.001,
      -8.492,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3125_M0010",
    polygon: [
      -82.75,
      -7.576,
      14.17,
      -82.75,
      -7.576,
      17.165,
      -80.866,
      -7.045,
      17.165,
      -80.866,
      -7.045,
      17.165,
      -80.866,
      -7.045,
      14.17,
      -82.75,
      -7.576,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3126_M0010",
    polygon: [
      -80.803,
      -7.027,
      14.17,
      -80.803,
      -7.027,
      17.165,
      -78.919,
      -6.497,
      17.165,
      -78.919,
      -6.497,
      17.165,
      -78.919,
      -6.497,
      14.17,
      -80.803,
      -7.027,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3127_M0010",
    polygon: [
      -77.555,
      -6.112,
      14.17,
      -77.555,
      -6.112,
      17.165,
      -75.671,
      -5.582,
      17.165,
      -75.671,
      -5.582,
      17.165,
      -75.671,
      -5.582,
      14.17,
      -77.555,
      -6.112,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3127_M0011",
    polygon: [
      -75.608,
      -5.564,
      14.17,
      -75.608,
      -5.564,
      17.165,
      -73.724,
      -5.033,
      17.165,
      -73.724,
      -5.033,
      17.165,
      -73.724,
      -5.033,
      14.17,
      -75.608,
      -5.564,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3127_M0012",
    polygon: [
      -73.124,
      -5.386,
      14.17,
      -73.124,
      -5.386,
      17.165,
      -72.606,
      -7.224,
      17.165,
      -72.606,
      -7.224,
      17.165,
      -72.606,
      -7.224,
      14.17,
      -73.124,
      -5.386,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3127_M0013",
    polygon: [
      -72.589,
      -7.287,
      14.17,
      -72.589,
      -7.287,
      17.165,
      -72.071,
      -9.125,
      17.165,
      -72.071,
      -9.125,
      17.165,
      -72.071,
      -9.125,
      14.17,
      -72.589,
      -7.287,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3128_M0010",
    polygon: [
      -71.673,
      -10.535,
      14.17,
      -71.673,
      -10.535,
      17.165,
      -71.156,
      -12.374,
      17.165,
      -71.156,
      -12.374,
      17.165,
      -71.156,
      -12.374,
      14.17,
      -71.673,
      -10.535,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3128_M0011",
    polygon: [
      -71.138,
      -12.436,
      14.17,
      -71.138,
      -12.436,
      17.165,
      -70.62,
      -14.275,
      17.165,
      -70.62,
      -14.275,
      17.165,
      -70.62,
      -14.275,
      14.17,
      -71.138,
      -12.436,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3129_M0010",
    polygon: [
      -70.23,
      -15.658,
      14.17,
      -70.23,
      -15.658,
      17.165,
      -69.713,
      -17.497,
      17.165,
      -69.713,
      -17.497,
      17.165,
      -69.713,
      -17.497,
      14.17,
      -70.23,
      -15.658,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3129_M0011",
    polygon: [
      -69.695,
      -17.559,
      14.17,
      -69.695,
      -17.559,
      17.165,
      -69.177,
      -19.398,
      17.165,
      -69.177,
      -19.398,
      17.165,
      -69.177,
      -19.398,
      14.17,
      -69.695,
      -17.559,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3129_M0012",
    polygon: [
      -69.521,
      -20.001,
      14.17,
      -69.521,
      -20.001,
      17.165,
      -71.405,
      -20.532,
      17.165,
      -71.405,
      -20.532,
      17.165,
      -71.405,
      -20.532,
      14.17,
      -69.521,
      -20.001,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3129_M0013",
    polygon: [
      -71.468,
      -20.55,
      14.17,
      -71.468,
      -20.55,
      17.165,
      -73.352,
      -21.081,
      17.165,
      -73.352,
      -21.081,
      17.165,
      -73.352,
      -21.081,
      14.17,
      -71.468,
      -20.55,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3130_M0010",
    polygon: [
      -74.719,
      -21.466,
      14.17,
      -74.719,
      -21.466,
      17.165,
      -76.603,
      -21.996,
      17.165,
      -76.603,
      -21.996,
      17.165,
      -76.603,
      -21.996,
      14.17,
      -74.719,
      -21.466,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
  },
  {
    ID: "LE100xxE03O_EZR3131_M0010",
    polygon: [
      -76.666,
      -22.014,
      14.17,
      -76.666,
      -22.014,
      17.165,
      -78.55,
      -22.545,
      17.165,
      -78.55,
      -22.545,
      17.165,
      -78.55,
      -22.545,
      14.17,
      -76.666,
      -22.014,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3132_M0010",
    polygon: [
      -79.914,
      -22.929,
      14.17,
      -79.914,
      -22.929,
      17.165,
      -81.799,
      -23.46,
      17.165,
      -81.799,
      -23.46,
      17.165,
      -81.799,
      -23.46,
      14.17,
      -79.914,
      -22.929,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3133_M0010",
    polygon: [
      -81.861,
      -23.477,
      14.17,
      -81.861,
      -23.477,
      17.165,
      -83.745,
      -24.008,
      17.165,
      -83.745,
      -24.008,
      17.165,
      -83.745,
      -24.008,
      14.17,
      -81.861,
      -23.477,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
  },
  {
    ID: "LE100xxE03O_EZR3134_M0010",
    polygon: [
      -85.108,
      -24.392,
      14.17,
      -85.108,
      -24.392,
      17.165,
      -87.469,
      -25.057,
      17.165,
      -87.469,
      -25.057,
      17.165,
      -87.469,
      -25.057,
      14.17,
      -85.108,
      -24.392,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3135_M0010",
    polygon: [
      -87.703,
      -26.115,
      14.17,
      -87.703,
      -26.115,
      17.165,
      -87.221,
      -27.826,
      17.165,
      -87.221,
      -27.826,
      17.165,
      -87.221,
      -27.826,
      14.17,
      -87.703,
      -26.115,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3149_M0010",
    polygon: [
      -106.321,
      -33.206,
      14.17,
      -106.321,
      -33.206,
      17.165,
      -106.803,
      -31.495,
      17.165,
      -106.803,
      -31.495,
      17.165,
      -106.803,
      -31.495,
      14.17,
      -106.321,
      -33.206,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3149_M0011",
    polygon: [
      -105.822,
      -34.98,
      14.17,
      -105.822,
      -34.98,
      17.165,
      -106.304,
      -33.269,
      17.165,
      -106.304,
      -33.269,
      17.165,
      -106.304,
      -33.269,
      14.17,
      -105.822,
      -34.98,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "LE100xxE03O_EZR3149_M0012",
    polygon: [
      -104.956,
      -38.053,
      14.17,
      -104.956,
      -38.053,
      17.165,
      -105.438,
      -36.342,
      17.165,
      -105.438,
      -36.342,
      17.165,
      -105.438,
      -36.342,
      14.17,
      -104.956,
      -38.053,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3201_M0010",
    polygon: [
      -107.578,
      -52.168,
      14.17,
      -107.578,
      -52.168,
      17.165,
      -105.694,
      -51.637,
      17.165,
      -105.694,
      -51.637,
      17.165,
      -105.694,
      -51.637,
      14.17,
      -107.578,
      -52.168,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3202_M0010",
    polygon: [
      -109.525,
      -52.716,
      14.17,
      -109.525,
      -52.716,
      17.165,
      -107.641,
      -52.185,
      17.165,
      -107.641,
      -52.185,
      17.165,
      -107.641,
      -52.185,
      14.17,
      -109.525,
      -52.716,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3203_M0010",
    polygon: [
      -112.774,
      -53.631,
      14.17,
      -112.774,
      -53.631,
      17.165,
      -110.89,
      -53.101,
      17.165,
      -110.89,
      -53.101,
      17.165,
      -110.89,
      -53.101,
      14.17,
      -112.774,
      -53.631,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3204_M0010",
    polygon: [
      -114.721,
      -54.18,
      14.17,
      -114.721,
      -54.18,
      17.165,
      -112.836,
      -53.649,
      17.165,
      -112.836,
      -53.649,
      17.165,
      -112.836,
      -53.649,
      14.17,
      -114.721,
      -54.18,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3205_M0010",
    polygon: [
      -117.972,
      -55.095,
      14.17,
      -117.972,
      -55.095,
      17.165,
      -116.087,
      -54.565,
      17.165,
      -116.087,
      -54.565,
      17.165,
      -116.087,
      -54.565,
      14.17,
      -117.972,
      -55.095,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3205_M0011",
    polygon: [
      -119.918,
      -55.644,
      14.17,
      -119.918,
      -55.644,
      17.165,
      -118.034,
      -55.113,
      17.165,
      -118.034,
      -55.113,
      17.165,
      -118.034,
      -55.113,
      14.17,
      -119.918,
      -55.644,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3205_M0012",
    polygon: [
      -119.731,
      -58.076,
      14.17,
      -119.731,
      -58.076,
      17.165,
      -120.256,
      -56.213,
      17.165,
      -120.256,
      -56.213,
      17.165,
      -120.256,
      -56.213,
      14.17,
      -119.731,
      -58.076,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3205_M0013",
    polygon: [
      -119.189,
      -60.001,
      14.17,
      -119.189,
      -60.001,
      17.165,
      -119.713,
      -58.139,
      17.165,
      -119.713,
      -58.139,
      17.165,
      -119.713,
      -58.139,
      14.17,
      -119.189,
      -60.001,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3206_M0010",
    polygon: [
      -118.28,
      -63.226,
      14.17,
      -118.28,
      -63.226,
      17.165,
      -118.805,
      -61.363,
      17.165,
      -118.805,
      -61.363,
      17.165,
      -118.805,
      -61.363,
      14.17,
      -118.28,
      -63.226,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3206_M0011",
    polygon: [
      -117.738,
      -65.151,
      14.17,
      -117.738,
      -65.151,
      17.165,
      -118.262,
      -63.289,
      17.165,
      -118.262,
      -63.289,
      17.165,
      -118.262,
      -63.289,
      14.17,
      -117.738,
      -65.151,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3207_M0010",
    polygon: [
      -116.838,
      -68.344,
      14.17,
      -116.838,
      -68.344,
      17.165,
      -117.363,
      -66.482,
      17.165,
      -117.363,
      -66.482,
      17.165,
      -117.363,
      -66.482,
      14.17,
      -116.838,
      -68.344,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3207_M0011",
    polygon: [
      -116.296,
      -70.27,
      14.17,
      -116.296,
      -70.27,
      17.165,
      -116.821,
      -68.407,
      17.165,
      -116.821,
      -68.407,
      17.165,
      -116.821,
      -68.407,
      14.17,
      -116.296,
      -70.27,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3207_M0012",
    polygon: [
      -113.81,
      -70.078,
      14.17,
      -113.81,
      -70.078,
      17.165,
      -115.696,
      -70.61,
      17.165,
      -115.696,
      -70.61,
      17.165,
      -115.696,
      -70.61,
      14.17,
      -113.81,
      -70.078,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3207_M0013",
    polygon: [
      -111.861,
      -69.529,
      14.17,
      -111.861,
      -69.529,
      17.165,
      -113.747,
      -70.061,
      17.165,
      -113.747,
      -70.061,
      17.165,
      -113.747,
      -70.061,
      14.17,
      -111.861,
      -69.529,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3208_M0010",
    polygon: [
      -108.612,
      -68.614,
      14.17,
      -108.612,
      -68.614,
      17.165,
      -110.499,
      -69.146,
      17.165,
      -110.499,
      -69.146,
      17.165,
      -110.499,
      -69.146,
      14.17,
      -108.612,
      -68.614,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3209_M0010",
    polygon: [
      -106.663,
      -68.065,
      14.17,
      -106.663,
      -68.065,
      17.165,
      -108.55,
      -68.597,
      17.165,
      -108.55,
      -68.597,
      17.165,
      -108.55,
      -68.597,
      14.17,
      -106.663,
      -68.065,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3210_M0010",
    polygon: [
      -103.414,
      -67.15,
      14.17,
      -103.414,
      -67.15,
      17.165,
      -105.301,
      -67.681,
      17.165,
      -105.301,
      -67.681,
      17.165,
      -105.301,
      -67.681,
      14.17,
      -103.414,
      -67.15,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3211_M0010",
    polygon: [
      -101.465,
      -66.601,
      14.17,
      -101.465,
      -66.601,
      17.165,
      -103.352,
      -67.132,
      17.165,
      -103.352,
      -67.132,
      17.165,
      -103.352,
      -67.132,
      14.17,
      -101.465,
      -66.601,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3212_M0010",
    polygon: [
      -98.217,
      -65.686,
      14.17,
      -98.217,
      -65.686,
      17.165,
      -100.103,
      -66.217,
      17.165,
      -100.103,
      -66.217,
      17.165,
      -100.103,
      -66.217,
      14.17,
      -98.217,
      -65.686,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3213_M0010",
    polygon: [
      -96.268,
      -65.137,
      14.17,
      -96.268,
      -65.137,
      17.165,
      -98.154,
      -65.668,
      17.165,
      -98.154,
      -65.668,
      17.165,
      -98.154,
      -65.668,
      14.17,
      -96.268,
      -65.137,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3214_M0010",
    polygon: [
      -92.547,
      -64.089,
      14.17,
      -92.547,
      -64.089,
      17.165,
      -94.908,
      -64.754,
      17.165,
      -94.908,
      -64.754,
      17.165,
      -94.908,
      -64.754,
      14.17,
      -92.547,
      -64.089,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3215_M0010",
    polygon: [
      -80.484,
      -60.691,
      14.17,
      -80.484,
      -60.691,
      17.165,
      -82.845,
      -61.356,
      17.165,
      -82.845,
      -61.356,
      17.165,
      -82.845,
      -61.356,
      14.17,
      -80.484,
      -60.691,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3216_M0010",
    polygon: [
      -77.236,
      -59.776,
      14.17,
      -77.236,
      -59.776,
      17.165,
      -79.122,
      -60.307,
      17.165,
      -79.122,
      -60.307,
      17.165,
      -79.122,
      -60.307,
      14.17,
      -77.236,
      -59.776,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3217_M0010",
    polygon: [
      -75.286,
      -59.227,
      14.17,
      -75.286,
      -59.227,
      17.165,
      -77.173,
      -59.758,
      17.165,
      -77.173,
      -59.758,
      17.165,
      -77.173,
      -59.758,
      14.17,
      -75.286,
      -59.227,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "LE100xxE03O_EZR3218_M0010",
    polygon: [
      -72.038,
      -58.312,
      14.17,
      -72.038,
      -58.312,
      17.165,
      -73.924,
      -58.843,
      17.165,
      -73.924,
      -58.843,
      17.165,
      -73.924,
      -58.843,
      14.17,
      -72.038,
      -58.312,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
  },
  {
    ID: "LE100xxE03O_EZR3219_M0010",
    polygon: [
      -70.089,
      -57.763,
      14.17,
      -70.089,
      -57.763,
      17.165,
      -71.975,
      -58.294,
      17.165,
      -71.975,
      -58.294,
      17.165,
      -71.975,
      -58.294,
      14.17,
      -70.089,
      -57.763,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3220_M0010",
    polygon: [
      -66.84,
      -56.848,
      14.17,
      -66.84,
      -56.848,
      17.165,
      -68.727,
      -57.379,
      17.165,
      -68.727,
      -57.379,
      17.165,
      -68.727,
      -57.379,
      14.17,
      -66.84,
      -56.848,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3221_M0010",
    polygon: [
      -64.891,
      -56.299,
      14.17,
      -64.891,
      -56.299,
      17.165,
      -66.778,
      -56.83,
      17.165,
      -66.778,
      -56.83,
      17.165,
      -66.778,
      -56.83,
      14.17,
      -64.891,
      -56.299,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3222_M0010",
    polygon: [
      -61.642,
      -55.384,
      14.17,
      -61.642,
      -55.384,
      17.165,
      -63.529,
      -55.915,
      17.165,
      -63.529,
      -55.915,
      17.165,
      -63.529,
      -55.915,
      14.17,
      -61.642,
      -55.384,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3222_M0011",
    polygon: [
      -59.693,
      -54.835,
      14.17,
      -59.693,
      -54.835,
      17.165,
      -61.58,
      -55.366,
      17.165,
      -61.58,
      -55.366,
      17.165,
      -61.58,
      -55.366,
      14.17,
      -59.693,
      -54.835,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3222_M0012",
    polygon: [
      -59.885,
      -52.395,
      14.17,
      -59.885,
      -52.395,
      17.165,
      -59.368,
      -54.233,
      17.165,
      -59.368,
      -54.233,
      17.165,
      -59.368,
      -54.233,
      14.17,
      -59.885,
      -52.395,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3222_M0013",
    polygon: [
      -60.421,
      -50.494,
      14.17,
      -60.421,
      -50.494,
      17.165,
      -59.903,
      -52.332,
      17.165,
      -59.903,
      -52.332,
      17.165,
      -59.903,
      -52.332,
      14.17,
      -60.421,
      -50.494,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3223_M0010",
    polygon: [
      -61.329,
      -47.272,
      14.17,
      -61.329,
      -47.272,
      17.165,
      -60.811,
      -49.11,
      17.165,
      -60.811,
      -49.11,
      17.165,
      -60.811,
      -49.11,
      14.17,
      -61.329,
      -47.272,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3223_M0011",
    polygon: [
      -61.864,
      -45.371,
      14.17,
      -61.864,
      -45.371,
      17.165,
      -61.346,
      -47.209,
      17.165,
      -61.346,
      -47.209,
      17.165,
      -61.346,
      -47.209,
      14.17,
      -61.864,
      -45.371,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3224_M0010",
    polygon: [
      -62.779,
      -42.122,
      14.17,
      -62.779,
      -42.122,
      17.165,
      -62.261,
      -43.96,
      17.165,
      -62.261,
      -43.96,
      17.165,
      -62.261,
      -43.96,
      14.17,
      -62.779,
      -42.122,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3224_M0011",
    polygon: [
      -63.315,
      -40.221,
      14.17,
      -63.315,
      -40.221,
      17.165,
      -62.797,
      -42.059,
      17.165,
      -62.797,
      -42.059,
      17.165,
      -62.797,
      -42.059,
      14.17,
      -63.315,
      -40.221,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3224_M0012",
    polygon: [
      -65.802,
      -40.4,
      14.17,
      -65.802,
      -40.4,
      17.165,
      -63.918,
      -39.869,
      17.165,
      -63.918,
      -39.869,
      17.165,
      -63.918,
      -39.869,
      14.17,
      -65.802,
      -40.4,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3224_M0013",
    polygon: [
      -67.748,
      -40.948,
      14.17,
      -67.748,
      -40.948,
      17.165,
      -65.864,
      -40.418,
      17.165,
      -65.864,
      -40.418,
      17.165,
      -65.864,
      -40.418,
      14.17,
      -67.748,
      -40.948,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3225_M0010",
    polygon: [
      -70.997,
      -41.864,
      14.17,
      -70.997,
      -41.864,
      17.165,
      -69.113,
      -41.333,
      17.165,
      -69.113,
      -41.333,
      17.165,
      -69.113,
      -41.333,
      14.17,
      -70.997,
      -41.864,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3226_M0010",
    polygon: [
      -72.944,
      -42.412,
      14.17,
      -72.944,
      -42.412,
      17.165,
      -71.06,
      -41.881,
      17.165,
      -71.06,
      -41.881,
      17.165,
      -71.06,
      -41.881,
      14.17,
      -72.944,
      -42.412,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3227_M0010",
    polygon: [
      -76.195,
      -43.328,
      14.17,
      -76.195,
      -43.328,
      17.165,
      -74.311,
      -42.797,
      17.165,
      -74.311,
      -42.797,
      17.165,
      -74.311,
      -42.797,
      14.17,
      -76.195,
      -43.328,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3228_M0010",
    polygon: [
      -78.142,
      -43.876,
      14.17,
      -78.142,
      -43.876,
      17.165,
      -76.257,
      -43.345,
      17.165,
      -76.257,
      -43.345,
      17.165,
      -76.257,
      -43.345,
      14.17,
      -78.142,
      -43.876,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3229_M0010",
    polygon: [
      -81.869,
      -44.926,
      14.17,
      -81.869,
      -44.926,
      17.165,
      -79.508,
      -44.261,
      17.165,
      -79.508,
      -44.261,
      17.165,
      -79.508,
      -44.261,
      14.17,
      -81.869,
      -44.926,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3230_M0010",
    polygon: [
      -83.106,
      -42.433,
      14.17,
      -83.106,
      -42.433,
      17.165,
      -82.624,
      -44.144,
      17.165,
      -82.624,
      -44.144,
      17.165,
      -82.624,
      -44.144,
      14.17,
      -83.106,
      -42.433,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3231_M0010",
    polygon: [
      -83.606,
      -40.659,
      14.17,
      -83.606,
      -40.659,
      17.165,
      -83.124,
      -42.37,
      17.165,
      -83.124,
      -42.37,
      17.165,
      -83.124,
      -42.37,
      14.17,
      -83.606,
      -40.659,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3231_M0011",
    polygon: [
      -84.472,
      -37.586,
      14.17,
      -84.472,
      -37.586,
      17.165,
      -83.99,
      -39.297,
      17.165,
      -83.99,
      -39.297,
      17.165,
      -83.99,
      -39.297,
      14.17,
      -84.472,
      -37.586,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3232_M0010",
    polygon: [
      -84.971,
      -35.813,
      14.17,
      -84.971,
      -35.813,
      17.165,
      -84.489,
      -37.524,
      17.165,
      -84.489,
      -37.524,
      17.165,
      -84.489,
      -37.524,
      14.17,
      -84.971,
      -35.813,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 08:45": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3232_M0011",
    polygon: [
      -85.838,
      -32.735,
      14.17,
      -85.838,
      -32.735,
      17.165,
      -85.356,
      -34.446,
      17.165,
      -85.356,
      -34.446,
      17.165,
      -85.356,
      -34.446,
      14.17,
      -85.838,
      -32.735,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3233_M0010",
    polygon: [
      -87.203,
      -27.889,
      14.17,
      -87.203,
      -27.889,
      17.165,
      -86.721,
      -29.6,
      17.165,
      -86.721,
      -29.6,
      17.165,
      -86.721,
      -29.6,
      14.17,
      -87.203,
      -27.889,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3233_M0011",
    polygon: [
      -86.338,
      -30.962,
      14.17,
      -86.338,
      -30.962,
      17.165,
      -85.856,
      -32.672,
      17.165,
      -85.856,
      -32.672,
      17.165,
      -85.856,
      -32.672,
      14.17,
      -86.338,
      -30.962,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3234_M0010",
    polygon: [
      -104.457,
      -39.826,
      14.17,
      -104.457,
      -39.826,
      17.165,
      -104.939,
      -38.115,
      17.165,
      -104.939,
      -38.115,
      17.165,
      -104.939,
      -38.115,
      14.17,
      -104.457,
      -39.826,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE03O_EZR3235_M0010",
    polygon: [
      -103.59,
      -42.904,
      14.17,
      -103.59,
      -42.904,
      17.165,
      -104.072,
      -41.193,
      17.165,
      -104.072,
      -41.193,
      17.165,
      -104.072,
      -41.193,
      14.17,
      -103.59,
      -42.904,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3236_M0010",
    polygon: [
      -103.09,
      -44.677,
      14.17,
      -103.09,
      -44.677,
      17.165,
      -103.572,
      -42.967,
      17.165,
      -103.572,
      -42.967,
      17.165,
      -103.572,
      -42.967,
      14.17,
      -103.09,
      -44.677,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3237_M0010",
    polygon: [
      -101.725,
      -49.524,
      14.17,
      -101.725,
      -49.524,
      17.165,
      -102.207,
      -47.813,
      17.165,
      -102.207,
      -47.813,
      17.165,
      -102.207,
      -47.813,
      14.17,
      -101.725,
      -49.524,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3237_M0011",
    polygon: [
      -102.225,
      -47.75,
      14.17,
      -102.225,
      -47.75,
      17.165,
      -102.707,
      -46.039,
      17.165,
      -102.707,
      -46.039,
      17.165,
      -102.707,
      -46.039,
      14.17,
      -102.225,
      -47.75,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3251_M0010",
    polygon: [
      -104.327,
      -51.252,
      14.17,
      -104.327,
      -51.252,
      17.165,
      -101.967,
      -50.587,
      17.165,
      -101.967,
      -50.587,
      17.165,
      -101.967,
      -50.587,
      14.17,
      -104.327,
      -51.252,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3301_M0010",
    polygon: [
      -93.897,
      -80.78,
      14.165,
      -93.897,
      -80.78,
      17.16,
      -92.012,
      -80.249,
      17.16,
      -92.012,
      -80.249,
      17.16,
      -92.012,
      -80.249,
      14.165,
      -93.897,
      -80.78,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3301_M0011",
    polygon: [
      -95.843,
      -81.328,
      14.165,
      -95.843,
      -81.328,
      17.16,
      -93.959,
      -80.798,
      17.16,
      -93.959,
      -80.798,
      17.16,
      -93.959,
      -80.798,
      14.165,
      -95.843,
      -81.328,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3302_M0010",
    polygon: [
      -99.094,
      -82.244,
      14.165,
      -99.094,
      -82.244,
      17.16,
      -97.21,
      -81.713,
      17.16,
      -97.21,
      -81.713,
      17.16,
      -97.21,
      -81.713,
      14.165,
      -99.094,
      -82.244,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3303_M0010",
    polygon: [
      -101.041,
      -82.792,
      14.165,
      -101.041,
      -82.792,
      17.16,
      -99.157,
      -82.262,
      17.16,
      -99.157,
      -82.262,
      17.16,
      -99.157,
      -82.262,
      14.165,
      -101.041,
      -82.792,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3304_M0010",
    polygon: [
      -104.297,
      -83.71,
      14.165,
      -104.297,
      -83.71,
      17.16,
      -102.413,
      -83.179,
      17.16,
      -102.413,
      -83.179,
      17.16,
      -102.413,
      -83.179,
      14.165,
      -104.297,
      -83.71,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3305_M0010",
    polygon: [
      -106.244,
      -84.258,
      14.165,
      -106.244,
      -84.258,
      17.16,
      -104.36,
      -83.727,
      17.16,
      -104.36,
      -83.727,
      17.16,
      -104.36,
      -83.727,
      14.165,
      -106.244,
      -84.258,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3306_M0010",
    polygon: [
      -109.495,
      -85.174,
      14.165,
      -109.495,
      -85.174,
      17.16,
      -107.611,
      -84.643,
      17.16,
      -107.611,
      -84.643,
      17.16,
      -107.611,
      -84.643,
      14.165,
      -109.495,
      -85.174,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3306_M0011",
    polygon: [
      -111.442,
      -85.722,
      14.165,
      -111.442,
      -85.722,
      17.16,
      -109.557,
      -85.191,
      17.16,
      -109.557,
      -85.191,
      17.16,
      -109.557,
      -85.191,
      14.165,
      -111.442,
      -85.722,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3306_M0012",
    polygon: [
      -111.261,
      -88.156,
      14.17,
      -111.261,
      -88.156,
      17.165,
      -111.786,
      -86.293,
      17.165,
      -111.786,
      -86.293,
      17.165,
      -111.786,
      -86.293,
      14.17,
      -111.261,
      -88.156,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE100xxE03O_EZR3306_M0013",
    polygon: [
      -110.719,
      -90.081,
      14.17,
      -110.719,
      -90.081,
      17.165,
      -111.243,
      -88.219,
      17.165,
      -111.243,
      -88.219,
      17.165,
      -111.243,
      -88.219,
      14.17,
      -110.719,
      -90.081,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3307_M0010",
    polygon: [
      -109.81,
      -93.306,
      14.17,
      -109.81,
      -93.306,
      17.165,
      -110.335,
      -91.443,
      17.165,
      -110.335,
      -91.443,
      17.165,
      -110.335,
      -91.443,
      14.17,
      -109.81,
      -93.306,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3307_M0011",
    polygon: [
      -109.268,
      -95.232,
      14.17,
      -109.268,
      -95.232,
      17.165,
      -109.792,
      -93.369,
      17.165,
      -109.792,
      -93.369,
      17.165,
      -109.792,
      -93.369,
      14.17,
      -109.268,
      -95.232,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3308_M0010",
    polygon: [
      -108.368,
      -98.424,
      14.17,
      -108.368,
      -98.424,
      17.165,
      -108.893,
      -96.562,
      17.165,
      -108.893,
      -96.562,
      17.165,
      -108.893,
      -96.562,
      14.17,
      -108.368,
      -98.424,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3308_M0011",
    polygon: [
      -107.826,
      -100.35,
      14.17,
      -107.826,
      -100.35,
      17.165,
      -108.351,
      -98.487,
      17.165,
      -108.351,
      -98.487,
      17.165,
      -108.351,
      -98.487,
      14.17,
      -107.826,
      -100.35,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE03O_EZR3308_M0012",
    polygon: [
      -105.339,
      -100.158,
      14.17,
      -105.339,
      -100.158,
      17.165,
      -107.225,
      -100.69,
      17.165,
      -107.225,
      -100.69,
      17.165,
      -107.225,
      -100.69,
      14.17,
      -105.339,
      -100.158,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "LE100xxE03O_EZR3308_M0013",
    polygon: [
      -103.392,
      -99.61,
      14.17,
      -103.392,
      -99.61,
      17.165,
      -105.278,
      -100.141,
      17.165,
      -105.278,
      -100.141,
      17.165,
      -105.278,
      -100.141,
      14.17,
      -103.392,
      -99.61,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3309_M0010",
    polygon: [
      -100.14,
      -98.694,
      14.17,
      -100.14,
      -98.694,
      17.165,
      -102.027,
      -99.225,
      17.165,
      -102.027,
      -99.225,
      17.165,
      -102.027,
      -99.225,
      14.17,
      -100.14,
      -98.694,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3310_M0010",
    polygon: [
      -98.194,
      -98.146,
      14.17,
      -98.194,
      -98.146,
      17.165,
      -100.08,
      -98.677,
      17.165,
      -100.08,
      -98.677,
      17.165,
      -100.08,
      -98.677,
      14.17,
      -98.194,
      -98.146,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3311_M0010",
    polygon: [
      -94.943,
      -97.23,
      14.17,
      -94.943,
      -97.23,
      17.165,
      -96.83,
      -97.761,
      17.165,
      -96.83,
      -97.761,
      17.165,
      -96.83,
      -97.761,
      14.17,
      -94.943,
      -97.23,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3312_M0010",
    polygon: [
      -92.996,
      -96.682,
      14.17,
      -92.996,
      -96.682,
      17.165,
      -94.883,
      -97.213,
      17.165,
      -94.883,
      -97.213,
      17.165,
      -94.883,
      -97.213,
      14.17,
      -92.996,
      -96.682,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3313_M0010",
    polygon: [
      -89.745,
      -95.766,
      14.17,
      -89.745,
      -95.766,
      17.165,
      -91.632,
      -96.297,
      17.165,
      -91.632,
      -96.297,
      17.165,
      -91.632,
      -96.297,
      14.17,
      -89.745,
      -95.766,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3314_M0010",
    polygon: [
      -87.798,
      -95.217,
      14.17,
      -87.798,
      -95.217,
      17.165,
      -89.685,
      -95.749,
      17.165,
      -89.685,
      -95.749,
      17.165,
      -89.685,
      -95.749,
      14.17,
      -87.798,
      -95.217,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3315_M0010",
    polygon: [
      -84.547,
      -94.302,
      14.17,
      -84.547,
      -94.302,
      17.165,
      -86.434,
      -94.833,
      17.165,
      -86.434,
      -94.833,
      17.165,
      -86.434,
      -94.833,
      14.17,
      -84.547,
      -94.302,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3316_M0010",
    polygon: [
      -82.601,
      -93.753,
      14.17,
      -82.601,
      -93.753,
      17.165,
      -84.487,
      -94.285,
      17.165,
      -84.487,
      -94.285,
      17.165,
      -84.487,
      -94.285,
      14.17,
      -82.601,
      -93.753,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3316_M0011",
    polygon: [
      -82.54,
      -92.194,
      14.17,
      -82.54,
      -92.194,
      17.165,
      -82.269,
      -93.156,
      17.165,
      -82.269,
      -93.156,
      17.165,
      -82.269,
      -93.156,
      14.17,
      -82.54,
      -92.194,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
  },
  {
    ID: "LE100xxE03O_EZR3317_M0010",
    polygon: [
      -79.797,
      -90.886,
      14.17,
      -79.797,
      -90.886,
      17.165,
      -81.761,
      -91.439,
      17.165,
      -81.761,
      -91.439,
      17.165,
      -81.761,
      -91.439,
      14.17,
      -79.797,
      -90.886,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
  },
  {
    ID: "LE100xxE03O_EZR3318_M0010",
    polygon: [
      -77.771,
      -90.315,
      14.17,
      -77.771,
      -90.315,
      17.165,
      -79.734,
      -90.868,
      17.165,
      -79.734,
      -90.868,
      17.165,
      -79.734,
      -90.868,
      14.17,
      -77.771,
      -90.315,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
  },
  {
    ID: "LE100xxE03O_EZR3319_M0010",
    polygon: [
      -73.96,
      -91.319,
      14.17,
      -73.96,
      -91.319,
      17.165,
      -75.846,
      -91.851,
      17.165,
      -75.846,
      -91.851,
      17.165,
      -75.846,
      -91.851,
      14.17,
      -73.96,
      -91.319,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3319_M0011",
    polygon: [
      -76.446,
      -91.516,
      14.17,
      -76.446,
      -91.516,
      17.165,
      -76.717,
      -90.553,
      17.165,
      -76.717,
      -90.553,
      17.165,
      -76.717,
      -90.553,
      14.17,
      -76.446,
      -91.516,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3320_M0010",
    polygon: [
      -72.013,
      -90.771,
      14.17,
      -72.013,
      -90.771,
      17.165,
      -73.9,
      -91.302,
      17.165,
      -73.9,
      -91.302,
      17.165,
      -73.9,
      -91.302,
      14.17,
      -72.013,
      -90.771,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3321_M0010",
    polygon: [
      -68.762,
      -89.855,
      14.17,
      -68.762,
      -89.855,
      17.165,
      -70.648,
      -90.386,
      17.165,
      -70.648,
      -90.386,
      17.165,
      -70.648,
      -90.386,
      14.17,
      -68.762,
      -89.855,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3322_M0010",
    polygon: [
      -66.815,
      -89.307,
      14.17,
      -66.815,
      -89.307,
      17.165,
      -68.701,
      -89.838,
      17.165,
      -68.701,
      -89.838,
      17.165,
      -68.701,
      -89.838,
      14.17,
      -66.815,
      -89.307,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3323_M0010",
    polygon: [
      -63.564,
      -88.391,
      14.17,
      -63.564,
      -88.391,
      17.165,
      -65.451,
      -88.922,
      17.165,
      -65.451,
      -88.922,
      17.165,
      -65.451,
      -88.922,
      14.17,
      -63.564,
      -88.391,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3324_M0010",
    polygon: [
      -61.618,
      -87.843,
      14.17,
      -61.618,
      -87.843,
      17.165,
      -63.504,
      -88.374,
      17.165,
      -63.504,
      -88.374,
      17.165,
      -63.504,
      -88.374,
      14.17,
      -61.618,
      -87.843,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3325_M0010",
    polygon: [
      -58.366,
      -86.927,
      14.17,
      -58.366,
      -86.927,
      17.165,
      -60.253,
      -87.458,
      17.165,
      -60.253,
      -87.458,
      17.165,
      -60.253,
      -87.458,
      14.17,
      -58.366,
      -86.927,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3326_M0010",
    polygon: [
      -56.419,
      -86.378,
      14.17,
      -56.419,
      -86.378,
      17.165,
      -58.306,
      -86.91,
      17.165,
      -58.306,
      -86.91,
      17.165,
      -58.306,
      -86.91,
      14.17,
      -56.419,
      -86.378,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3327_M0010",
    polygon: [
      -53.168,
      -85.463,
      14.17,
      -53.168,
      -85.463,
      17.165,
      -55.055,
      -85.994,
      17.165,
      -55.055,
      -85.994,
      17.165,
      -55.055,
      -85.994,
      14.17,
      -53.168,
      -85.463,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE03O_EZR3327_M0011",
    polygon: [
      -51.222,
      -84.914,
      14.17,
      -51.222,
      -84.914,
      17.165,
      -53.108,
      -85.446,
      17.165,
      -53.108,
      -85.446,
      17.165,
      -53.108,
      -85.446,
      14.17,
      -51.222,
      -84.914,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE03O_EZR3327_M0012",
    polygon: [
      -51.41,
      -82.473,
      14.17,
      -51.41,
      -82.473,
      17.165,
      -50.892,
      -84.312,
      17.165,
      -50.892,
      -84.312,
      17.165,
      -50.892,
      -84.312,
      14.17,
      -51.41,
      -82.473,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3327_M0013",
    polygon: [
      -51.945,
      -80.572,
      14.17,
      -51.945,
      -80.572,
      17.165,
      -51.427,
      -82.411,
      17.165,
      -51.427,
      -82.411,
      17.165,
      -51.427,
      -82.411,
      14.17,
      -51.945,
      -80.572,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3328_M0010",
    polygon: [
      -52.853,
      -77.35,
      14.17,
      -52.853,
      -77.35,
      17.165,
      -52.335,
      -79.189,
      17.165,
      -52.335,
      -79.189,
      17.165,
      -52.335,
      -79.189,
      14.17,
      -52.853,
      -77.35,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3328_M0011",
    polygon: [
      -53.388,
      -75.449,
      14.17,
      -53.388,
      -75.449,
      17.165,
      -52.871,
      -77.288,
      17.165,
      -52.871,
      -77.288,
      17.165,
      -52.871,
      -77.288,
      14.17,
      -53.388,
      -75.449,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3329_M0010",
    polygon: [
      -54.303,
      -72.201,
      14.17,
      -54.303,
      -72.201,
      17.165,
      -53.786,
      -74.039,
      17.165,
      -53.786,
      -74.039,
      17.165,
      -53.786,
      -74.039,
      14.17,
      -54.303,
      -72.201,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE03O_EZR3329_M0011",
    polygon: [
      -54.839,
      -70.3,
      14.17,
      -54.839,
      -70.3,
      17.165,
      -54.321,
      -72.138,
      17.165,
      -54.321,
      -72.138,
      17.165,
      -54.321,
      -72.138,
      14.17,
      -54.839,
      -70.3,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE03O_EZR3329_M0012",
    polygon: [
      -57.322,
      -70.478,
      14.165,
      -57.322,
      -70.478,
      17.16,
      -55.438,
      -69.947,
      17.16,
      -55.438,
      -69.947,
      17.16,
      -55.438,
      -69.947,
      14.165,
      -57.322,
      -70.478,
      14.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3329_M0013",
    polygon: [
      -59.269,
      -71.026,
      14.165,
      -59.269,
      -71.026,
      17.16,
      -57.385,
      -70.495,
      17.16,
      -57.385,
      -70.495,
      17.16,
      -57.385,
      -70.495,
      14.165,
      -59.269,
      -71.026,
      14.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3330_M0010",
    polygon: [
      -62.52,
      -71.942,
      14.165,
      -62.52,
      -71.942,
      17.16,
      -60.636,
      -71.411,
      17.16,
      -60.636,
      -71.411,
      17.16,
      -60.636,
      -71.411,
      14.165,
      -62.52,
      -71.942,
      14.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3331_M0010",
    polygon: [
      -64.467,
      -72.49,
      14.165,
      -64.467,
      -72.49,
      17.16,
      -62.583,
      -71.959,
      17.16,
      -62.583,
      -71.959,
      17.16,
      -62.583,
      -71.959,
      14.165,
      -64.467,
      -72.49,
      14.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3332_M0010",
    polygon: [
      -67.723,
      -73.407,
      14.165,
      -67.723,
      -73.407,
      17.16,
      -65.839,
      -72.877,
      17.16,
      -65.839,
      -72.877,
      17.16,
      -65.839,
      -72.877,
      14.165,
      -67.723,
      -73.407,
      14.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE03O_EZR3333_M0010",
    polygon: [
      -69.67,
      -73.956,
      14.165,
      -69.67,
      -73.956,
      17.16,
      -67.785,
      -73.425,
      17.16,
      -67.785,
      -73.425,
      17.16,
      -67.785,
      -73.425,
      14.165,
      -69.67,
      -73.956,
      14.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4101_M0010",
    polygon: [
      -107.566,
      -30.718,
      17.67,
      -107.566,
      -30.718,
      20.665,
      -109.927,
      -31.383,
      20.665,
      -109.927,
      -31.383,
      20.665,
      -109.927,
      -31.383,
      17.67,
      -107.566,
      -30.718,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4102_M0010",
    polygon: [
      -111.293,
      -31.768,
      17.67,
      -111.293,
      -31.768,
      20.665,
      -113.177,
      -32.299,
      20.665,
      -113.177,
      -32.299,
      20.665,
      -113.177,
      -32.299,
      17.67,
      -111.293,
      -31.768,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4103_M0010",
    polygon: [
      -113.24,
      -32.316,
      17.67,
      -113.24,
      -32.316,
      20.665,
      -115.124,
      -32.847,
      20.665,
      -115.124,
      -32.847,
      20.665,
      -115.124,
      -32.847,
      17.67,
      -113.24,
      -32.316,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4104_M0010",
    polygon: [
      -116.491,
      -33.232,
      17.67,
      -116.491,
      -33.232,
      20.665,
      -118.375,
      -33.763,
      20.665,
      -118.375,
      -33.763,
      20.665,
      -118.375,
      -33.763,
      17.67,
      -116.491,
      -33.232,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4105_M0010",
    polygon: [
      -118.438,
      -33.78,
      17.67,
      -118.438,
      -33.78,
      20.665,
      -120.322,
      -34.311,
      20.665,
      -120.322,
      -34.311,
      20.665,
      -120.322,
      -34.311,
      17.67,
      -118.438,
      -33.78,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4106_M0010",
    polygon: [
      -121.686,
      -34.695,
      17.67,
      -121.686,
      -34.695,
      20.665,
      -123.57,
      -35.226,
      20.665,
      -123.57,
      -35.226,
      20.665,
      -123.57,
      -35.226,
      17.67,
      -121.686,
      -34.695,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4106_M0011",
    polygon: [
      -123.633,
      -35.244,
      17.67,
      -123.633,
      -35.244,
      20.665,
      -125.517,
      -35.775,
      20.665,
      -125.517,
      -35.775,
      20.665,
      -125.517,
      -35.775,
      17.67,
      -123.633,
      -35.244,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4106_M0012",
    polygon: [
      -126.111,
      -35.436,
      17.67,
      -126.111,
      -35.436,
      20.665,
      -126.636,
      -33.573,
      20.665,
      -126.636,
      -33.573,
      20.665,
      -126.636,
      -33.573,
      17.67,
      -126.111,
      -35.436,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4106_M0013",
    polygon: [
      -126.654,
      -33.51,
      17.67,
      -126.654,
      -33.51,
      20.665,
      -127.178,
      -31.648,
      20.665,
      -127.178,
      -31.648,
      20.665,
      -127.178,
      -31.648,
      17.67,
      -126.654,
      -33.51,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4107_M0010",
    polygon: [
      -127.553,
      -30.318,
      17.67,
      -127.553,
      -30.318,
      20.665,
      -128.078,
      -28.455,
      20.665,
      -128.078,
      -28.455,
      20.665,
      -128.078,
      -28.455,
      17.67,
      -127.553,
      -30.318,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4107_M0011",
    polygon: [
      -128.095,
      -28.392,
      17.67,
      -128.095,
      -28.392,
      20.665,
      -128.62,
      -26.53,
      20.665,
      -128.62,
      -26.53,
      20.665,
      -128.62,
      -26.53,
      17.67,
      -128.095,
      -28.392,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4108_M0010",
    polygon: [
      -129.004,
      -25.168,
      17.67,
      -129.004,
      -25.168,
      20.665,
      -129.528,
      -23.305,
      20.665,
      -129.528,
      -23.305,
      20.665,
      -129.528,
      -23.305,
      17.67,
      -129.004,
      -25.168,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4108_M0011",
    polygon: [
      -129.546,
      -23.242,
      17.67,
      -129.546,
      -23.242,
      20.665,
      -130.071,
      -21.38,
      20.665,
      -130.071,
      -21.38,
      20.665,
      -130.071,
      -21.38,
      17.67,
      -129.546,
      -23.242,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4108_M0012",
    polygon: [
      -129.72,
      -20.806,
      17.67,
      -129.72,
      -20.806,
      20.665,
      -127.836,
      -20.276,
      20.665,
      -127.836,
      -20.276,
      20.665,
      -127.836,
      -20.276,
      17.67,
      -129.72,
      -20.806,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4108_M0013",
    polygon: [
      -127.773,
      -20.258,
      17.67,
      -127.773,
      -20.258,
      20.665,
      -125.889,
      -19.727,
      20.665,
      -125.889,
      -19.727,
      20.665,
      -125.889,
      -19.727,
      17.67,
      -127.773,
      -20.258,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4109_M0010",
    polygon: [
      -124.522,
      -19.342,
      17.67,
      -124.522,
      -19.342,
      20.665,
      -122.638,
      -18.812,
      20.665,
      -122.638,
      -18.812,
      20.665,
      -122.638,
      -18.812,
      17.67,
      -124.522,
      -19.342,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4110_M0010",
    polygon: [
      -122.575,
      -18.794,
      17.67,
      -122.575,
      -18.794,
      20.665,
      -120.691,
      -18.263,
      20.665,
      -120.691,
      -18.263,
      20.665,
      -120.691,
      -18.263,
      17.67,
      -122.575,
      -18.794,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4111_M0010",
    polygon: [
      -119.327,
      -17.879,
      17.67,
      -119.327,
      -17.879,
      20.665,
      -117.443,
      -17.348,
      20.665,
      -117.443,
      -17.348,
      20.665,
      -117.443,
      -17.348,
      17.67,
      -119.327,
      -17.879,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4112_M0010",
    polygon: [
      -117.38,
      -17.33,
      17.67,
      -117.38,
      -17.33,
      20.665,
      -115.496,
      -16.8,
      20.665,
      -115.496,
      -16.8,
      20.665,
      -115.496,
      -16.8,
      17.67,
      -117.38,
      -17.33,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4113_M0010",
    polygon: [
      -114.129,
      -16.415,
      17.67,
      -114.129,
      -16.415,
      20.665,
      -112.245,
      -15.884,
      20.665,
      -112.245,
      -15.884,
      20.665,
      -112.245,
      -15.884,
      17.67,
      -114.129,
      -16.415,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4114_M0010",
    polygon: [
      -112.182,
      -15.866,
      17.67,
      -112.182,
      -15.866,
      20.665,
      -110.298,
      -15.336,
      20.665,
      -110.298,
      -15.336,
      20.665,
      -110.298,
      -15.336,
      17.67,
      -112.182,
      -15.866,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4115_M0010",
    polygon: [
      -108.934,
      -14.951,
      17.67,
      -108.934,
      -14.951,
      20.665,
      -107.05,
      -14.421,
      20.665,
      -107.05,
      -14.421,
      20.665,
      -107.05,
      -14.421,
      17.67,
      -108.934,
      -14.951,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4116_M0010",
    polygon: [
      -106.987,
      -14.403,
      17.67,
      -106.987,
      -14.403,
      20.665,
      -105.103,
      -13.872,
      20.665,
      -105.103,
      -13.872,
      20.665,
      -105.103,
      -13.872,
      17.67,
      -106.987,
      -14.403,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4116_M0011",
    polygon: [
      -104.507,
      -14.208,
      17.67,
      -104.507,
      -14.208,
      20.665,
      -104.236,
      -15.171,
      20.665,
      -104.236,
      -15.171,
      20.665,
      -104.236,
      -15.171,
      17.67,
      -104.507,
      -14.208,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4117_M0010",
    polygon: [
      -103.177,
      -15.408,
      17.67,
      -103.177,
      -15.408,
      20.665,
      -101.213,
      -14.854,
      20.665,
      -101.213,
      -14.854,
      20.665,
      -101.213,
      -14.854,
      17.67,
      -103.177,
      -15.408,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4118_M0010",
    polygon: [
      -101.151,
      -14.837,
      17.67,
      -101.151,
      -14.837,
      20.665,
      -99.187,
      -14.284,
      20.665,
      -99.187,
      -14.284,
      20.665,
      -99.187,
      -14.284,
      17.67,
      -101.151,
      -14.837,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4119_M0010",
    polygon: [
      -98.341,
      -11.967,
      17.67,
      -98.341,
      -11.967,
      20.665,
      -96.457,
      -11.437,
      20.665,
      -96.457,
      -11.437,
      20.665,
      -96.457,
      -11.437,
      17.67,
      -98.341,
      -11.967,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4119_M0011",
    polygon: [
      -98.413,
      -13.531,
      17.67,
      -98.413,
      -13.531,
      20.665,
      -98.684,
      -12.568,
      20.665,
      -98.684,
      -12.568,
      20.665,
      -98.684,
      -12.568,
      17.67,
      -98.413,
      -13.531,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4120_M0010",
    polygon: [
      -96.394,
      -11.419,
      17.67,
      -96.394,
      -11.419,
      20.665,
      -94.51,
      -10.888,
      20.665,
      -94.51,
      -10.888,
      20.665,
      -94.51,
      -10.888,
      17.67,
      -96.394,
      -11.419,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4121_M0010",
    polygon: [
      -93.143,
      -10.503,
      17.67,
      -93.143,
      -10.503,
      20.665,
      -91.259,
      -9.973,
      20.665,
      -91.259,
      -9.973,
      20.665,
      -91.259,
      -9.973,
      17.67,
      -93.143,
      -10.503,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4122_M0010",
    polygon: [
      -91.197,
      -9.955,
      17.67,
      -91.197,
      -9.955,
      20.665,
      -89.312,
      -9.424,
      20.665,
      -89.312,
      -9.424,
      20.665,
      -89.312,
      -9.424,
      17.67,
      -91.197,
      -9.955,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4123_M0010",
    polygon: [
      -87.948,
      -9.04,
      17.67,
      -87.948,
      -9.04,
      20.665,
      -86.064,
      -8.509,
      20.665,
      -86.064,
      -8.509,
      20.665,
      -86.064,
      -8.509,
      17.67,
      -87.948,
      -9.04,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4124_M0010",
    polygon: [
      -86.001,
      -8.492,
      17.67,
      -86.001,
      -8.492,
      20.665,
      -84.117,
      -7.961,
      20.665,
      -84.117,
      -7.961,
      20.665,
      -84.117,
      -7.961,
      17.67,
      -86.001,
      -8.492,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4125_M0010",
    polygon: [
      -82.75,
      -7.576,
      17.67,
      -82.75,
      -7.576,
      20.665,
      -80.866,
      -7.045,
      20.665,
      -80.866,
      -7.045,
      20.665,
      -80.866,
      -7.045,
      17.67,
      -82.75,
      -7.576,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4126_M0010",
    polygon: [
      -80.803,
      -7.027,
      17.67,
      -80.803,
      -7.027,
      20.665,
      -78.919,
      -6.497,
      20.665,
      -78.919,
      -6.497,
      20.665,
      -78.919,
      -6.497,
      17.67,
      -80.803,
      -7.027,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4127_M0010",
    polygon: [
      -77.555,
      -6.112,
      17.67,
      -77.555,
      -6.112,
      20.665,
      -75.671,
      -5.582,
      20.665,
      -75.671,
      -5.582,
      20.665,
      -75.671,
      -5.582,
      17.67,
      -77.555,
      -6.112,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4127_M0011",
    polygon: [
      -75.608,
      -5.564,
      17.67,
      -75.608,
      -5.564,
      20.665,
      -73.724,
      -5.033,
      20.665,
      -73.724,
      -5.033,
      20.665,
      -73.724,
      -5.033,
      17.67,
      -75.608,
      -5.564,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4127_M0012",
    polygon: [
      -73.124,
      -5.386,
      17.67,
      -73.124,
      -5.386,
      20.665,
      -72.606,
      -7.224,
      20.665,
      -72.606,
      -7.224,
      20.665,
      -72.606,
      -7.224,
      17.67,
      -73.124,
      -5.386,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4127_M0013",
    polygon: [
      -72.589,
      -7.287,
      17.67,
      -72.589,
      -7.287,
      20.665,
      -72.071,
      -9.125,
      20.665,
      -72.071,
      -9.125,
      20.665,
      -72.071,
      -9.125,
      17.67,
      -72.589,
      -7.287,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4128_M0010",
    polygon: [
      -71.673,
      -10.535,
      17.67,
      -71.673,
      -10.535,
      20.665,
      -71.156,
      -12.374,
      20.665,
      -71.156,
      -12.374,
      20.665,
      -71.156,
      -12.374,
      17.67,
      -71.673,
      -10.535,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4128_M0011",
    polygon: [
      -71.138,
      -12.436,
      17.67,
      -71.138,
      -12.436,
      20.665,
      -70.62,
      -14.275,
      20.665,
      -70.62,
      -14.275,
      20.665,
      -70.62,
      -14.275,
      17.67,
      -71.138,
      -12.436,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4129_M0010",
    polygon: [
      -70.23,
      -15.658,
      17.67,
      -70.23,
      -15.658,
      20.665,
      -69.713,
      -17.497,
      20.665,
      -69.713,
      -17.497,
      20.665,
      -69.713,
      -17.497,
      17.67,
      -70.23,
      -15.658,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4129_M0011",
    polygon: [
      -69.695,
      -17.559,
      17.67,
      -69.695,
      -17.559,
      20.665,
      -69.177,
      -19.398,
      20.665,
      -69.177,
      -19.398,
      20.665,
      -69.177,
      -19.398,
      17.67,
      -69.695,
      -17.559,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4129_M0012",
    polygon: [
      -69.521,
      -20.001,
      17.67,
      -69.521,
      -20.001,
      20.665,
      -71.405,
      -20.532,
      20.665,
      -71.405,
      -20.532,
      20.665,
      -71.405,
      -20.532,
      17.67,
      -69.521,
      -20.001,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4129_M0013",
    polygon: [
      -71.468,
      -20.55,
      17.67,
      -71.468,
      -20.55,
      20.665,
      -73.352,
      -21.081,
      20.665,
      -73.352,
      -21.081,
      20.665,
      -73.352,
      -21.081,
      17.67,
      -71.468,
      -20.55,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4130_M0010",
    polygon: [
      -74.719,
      -21.466,
      17.67,
      -74.719,
      -21.466,
      20.665,
      -76.603,
      -21.996,
      20.665,
      -76.603,
      -21.996,
      20.665,
      -76.603,
      -21.996,
      17.67,
      -74.719,
      -21.466,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4131_M0010",
    polygon: [
      -76.666,
      -22.014,
      17.67,
      -76.666,
      -22.014,
      20.665,
      -78.55,
      -22.545,
      20.665,
      -78.55,
      -22.545,
      20.665,
      -78.55,
      -22.545,
      17.67,
      -76.666,
      -22.014,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4132_M0010",
    polygon: [
      -79.914,
      -22.929,
      17.67,
      -79.914,
      -22.929,
      20.665,
      -81.799,
      -23.46,
      20.665,
      -81.799,
      -23.46,
      20.665,
      -81.799,
      -23.46,
      17.67,
      -79.914,
      -22.929,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4133_M0010",
    polygon: [
      -81.861,
      -23.477,
      17.67,
      -81.861,
      -23.477,
      20.665,
      -83.745,
      -24.008,
      20.665,
      -83.745,
      -24.008,
      20.665,
      -83.745,
      -24.008,
      17.67,
      -81.861,
      -23.477,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4134_M0010",
    polygon: [
      -85.108,
      -24.392,
      17.67,
      -85.108,
      -24.392,
      20.665,
      -87.469,
      -25.057,
      20.665,
      -87.469,
      -25.057,
      20.665,
      -87.469,
      -25.057,
      17.67,
      -85.108,
      -24.392,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
  },
  {
    ID: "LE100xxE04O_EZR4135_M0010",
    polygon: [
      -87.703,
      -26.115,
      17.67,
      -87.703,
      -26.115,
      20.665,
      -87.221,
      -27.826,
      20.665,
      -87.221,
      -27.826,
      20.665,
      -87.221,
      -27.826,
      17.67,
      -87.703,
      -26.115,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4149_M0010",
    polygon: [
      -106.321,
      -33.206,
      17.67,
      -106.321,
      -33.206,
      20.665,
      -106.803,
      -31.495,
      20.665,
      -106.803,
      -31.495,
      20.665,
      -106.803,
      -31.495,
      17.67,
      -106.321,
      -33.206,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4149_M0011",
    polygon: [
      -105.822,
      -34.98,
      17.67,
      -105.822,
      -34.98,
      20.665,
      -106.304,
      -33.269,
      20.665,
      -106.304,
      -33.269,
      20.665,
      -106.304,
      -33.269,
      17.67,
      -105.822,
      -34.98,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
  },
  {
    ID: "LE100xxE04O_EZR4149_M0012",
    polygon: [
      -104.956,
      -38.053,
      17.67,
      -104.956,
      -38.053,
      20.665,
      -105.438,
      -36.342,
      20.665,
      -105.438,
      -36.342,
      20.665,
      -105.438,
      -36.342,
      17.67,
      -104.956,
      -38.053,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4201_M0010",
    polygon: [
      -107.578,
      -52.168,
      17.67,
      -107.578,
      -52.168,
      20.665,
      -105.694,
      -51.637,
      20.665,
      -105.694,
      -51.637,
      20.665,
      -105.694,
      -51.637,
      17.67,
      -107.578,
      -52.168,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4202_M0010",
    polygon: [
      -109.525,
      -52.716,
      17.67,
      -109.525,
      -52.716,
      20.665,
      -107.641,
      -52.185,
      20.665,
      -107.641,
      -52.185,
      20.665,
      -107.641,
      -52.185,
      17.67,
      -109.525,
      -52.716,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4203_M0010",
    polygon: [
      -112.774,
      -53.631,
      17.67,
      -112.774,
      -53.631,
      20.665,
      -110.89,
      -53.101,
      20.665,
      -110.89,
      -53.101,
      20.665,
      -110.89,
      -53.101,
      17.67,
      -112.774,
      -53.631,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4204_M0010",
    polygon: [
      -114.721,
      -54.18,
      17.67,
      -114.721,
      -54.18,
      20.665,
      -112.836,
      -53.649,
      20.665,
      -112.836,
      -53.649,
      20.665,
      -112.836,
      -53.649,
      17.67,
      -114.721,
      -54.18,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4205_M0010",
    polygon: [
      -117.972,
      -55.095,
      17.67,
      -117.972,
      -55.095,
      20.665,
      -116.087,
      -54.565,
      20.665,
      -116.087,
      -54.565,
      20.665,
      -116.087,
      -54.565,
      17.67,
      -117.972,
      -55.095,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4205_M0011",
    polygon: [
      -119.918,
      -55.644,
      17.67,
      -119.918,
      -55.644,
      20.665,
      -118.034,
      -55.113,
      20.665,
      -118.034,
      -55.113,
      20.665,
      -118.034,
      -55.113,
      17.67,
      -119.918,
      -55.644,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4205_M0012",
    polygon: [
      -119.731,
      -58.076,
      17.67,
      -119.731,
      -58.076,
      20.665,
      -120.256,
      -56.213,
      20.665,
      -120.256,
      -56.213,
      20.665,
      -120.256,
      -56.213,
      17.67,
      -119.731,
      -58.076,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4205_M0013",
    polygon: [
      -119.189,
      -60.001,
      17.67,
      -119.189,
      -60.001,
      20.665,
      -119.713,
      -58.139,
      20.665,
      -119.713,
      -58.139,
      20.665,
      -119.713,
      -58.139,
      17.67,
      -119.189,
      -60.001,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4206_M0010",
    polygon: [
      -118.28,
      -63.226,
      17.67,
      -118.28,
      -63.226,
      20.665,
      -118.805,
      -61.363,
      20.665,
      -118.805,
      -61.363,
      20.665,
      -118.805,
      -61.363,
      17.67,
      -118.28,
      -63.226,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4206_M0011",
    polygon: [
      -117.738,
      -65.151,
      17.67,
      -117.738,
      -65.151,
      20.665,
      -118.262,
      -63.289,
      20.665,
      -118.262,
      -63.289,
      20.665,
      -118.262,
      -63.289,
      17.67,
      -117.738,
      -65.151,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4207_M0010",
    polygon: [
      -116.838,
      -68.344,
      17.67,
      -116.838,
      -68.344,
      20.665,
      -117.363,
      -66.482,
      20.665,
      -117.363,
      -66.482,
      20.665,
      -117.363,
      -66.482,
      17.67,
      -116.838,
      -68.344,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4207_M0011",
    polygon: [
      -116.296,
      -70.27,
      17.67,
      -116.296,
      -70.27,
      20.665,
      -116.821,
      -68.407,
      20.665,
      -116.821,
      -68.407,
      20.665,
      -116.821,
      -68.407,
      17.67,
      -116.296,
      -70.27,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4207_M0012",
    polygon: [
      -113.81,
      -70.078,
      17.67,
      -113.81,
      -70.078,
      20.665,
      -115.696,
      -70.61,
      20.665,
      -115.696,
      -70.61,
      20.665,
      -115.696,
      -70.61,
      17.67,
      -113.81,
      -70.078,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4207_M0013",
    polygon: [
      -111.861,
      -69.529,
      17.67,
      -111.861,
      -69.529,
      20.665,
      -113.747,
      -70.061,
      20.665,
      -113.747,
      -70.061,
      20.665,
      -113.747,
      -70.061,
      17.67,
      -111.861,
      -69.529,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4208_M0010",
    polygon: [
      -108.612,
      -68.614,
      17.67,
      -108.612,
      -68.614,
      20.665,
      -110.499,
      -69.146,
      20.665,
      -110.499,
      -69.146,
      20.665,
      -110.499,
      -69.146,
      17.67,
      -108.612,
      -68.614,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4209_M0010",
    polygon: [
      -106.663,
      -68.065,
      17.67,
      -106.663,
      -68.065,
      20.665,
      -108.55,
      -68.597,
      20.665,
      -108.55,
      -68.597,
      20.665,
      -108.55,
      -68.597,
      17.67,
      -106.663,
      -68.065,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4210_M0010",
    polygon: [
      -103.414,
      -67.15,
      17.67,
      -103.414,
      -67.15,
      20.665,
      -105.301,
      -67.681,
      20.665,
      -105.301,
      -67.681,
      20.665,
      -105.301,
      -67.681,
      17.67,
      -103.414,
      -67.15,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4211_M0010",
    polygon: [
      -101.465,
      -66.601,
      17.67,
      -101.465,
      -66.601,
      20.665,
      -103.352,
      -67.132,
      20.665,
      -103.352,
      -67.132,
      20.665,
      -103.352,
      -67.132,
      17.67,
      -101.465,
      -66.601,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4212_M0010",
    polygon: [
      -98.217,
      -65.686,
      17.67,
      -98.217,
      -65.686,
      20.665,
      -100.103,
      -66.217,
      20.665,
      -100.103,
      -66.217,
      20.665,
      -100.103,
      -66.217,
      17.67,
      -98.217,
      -65.686,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4213_M0010",
    polygon: [
      -96.268,
      -65.137,
      17.67,
      -96.268,
      -65.137,
      20.665,
      -98.154,
      -65.668,
      20.665,
      -98.154,
      -65.668,
      20.665,
      -98.154,
      -65.668,
      17.67,
      -96.268,
      -65.137,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4214_M0010",
    polygon: [
      -92.547,
      -64.089,
      17.67,
      -92.547,
      -64.089,
      20.665,
      -94.908,
      -64.754,
      20.665,
      -94.908,
      -64.754,
      20.665,
      -94.908,
      -64.754,
      17.67,
      -92.547,
      -64.089,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4215_M0010",
    polygon: [
      -80.484,
      -60.691,
      17.67,
      -80.484,
      -60.691,
      20.665,
      -82.845,
      -61.356,
      20.665,
      -82.845,
      -61.356,
      20.665,
      -82.845,
      -61.356,
      17.67,
      -80.484,
      -60.691,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
  },
  {
    ID: "LE100xxE04O_EZR4216_M0010",
    polygon: [
      -77.236,
      -59.776,
      17.67,
      -77.236,
      -59.776,
      20.665,
      -79.122,
      -60.307,
      20.665,
      -79.122,
      -60.307,
      20.665,
      -79.122,
      -60.307,
      17.67,
      -77.236,
      -59.776,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4217_M0010",
    polygon: [
      -75.286,
      -59.227,
      17.67,
      -75.286,
      -59.227,
      20.665,
      -77.173,
      -59.758,
      20.665,
      -77.173,
      -59.758,
      20.665,
      -77.173,
      -59.758,
      17.67,
      -75.286,
      -59.227,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
  },
  {
    ID: "LE100xxE04O_EZR4218_M0010",
    polygon: [
      -72.038,
      -58.312,
      17.67,
      -72.038,
      -58.312,
      20.665,
      -73.924,
      -58.843,
      20.665,
      -73.924,
      -58.843,
      20.665,
      -73.924,
      -58.843,
      17.67,
      -72.038,
      -58.312,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4219_M0010",
    polygon: [
      -70.089,
      -57.763,
      17.67,
      -70.089,
      -57.763,
      20.665,
      -71.975,
      -58.294,
      20.665,
      -71.975,
      -58.294,
      20.665,
      -71.975,
      -58.294,
      17.67,
      -70.089,
      -57.763,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4220_M0010",
    polygon: [
      -66.84,
      -56.848,
      17.67,
      -66.84,
      -56.848,
      20.665,
      -68.727,
      -57.379,
      20.665,
      -68.727,
      -57.379,
      20.665,
      -68.727,
      -57.379,
      17.67,
      -66.84,
      -56.848,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4221_M0010",
    polygon: [
      -64.891,
      -56.299,
      17.67,
      -64.891,
      -56.299,
      20.665,
      -66.778,
      -56.83,
      20.665,
      -66.778,
      -56.83,
      20.665,
      -66.778,
      -56.83,
      17.67,
      -64.891,
      -56.299,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4222_M0010",
    polygon: [
      -61.642,
      -55.384,
      17.67,
      -61.642,
      -55.384,
      20.665,
      -63.529,
      -55.915,
      20.665,
      -63.529,
      -55.915,
      20.665,
      -63.529,
      -55.915,
      17.67,
      -61.642,
      -55.384,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4222_M0011",
    polygon: [
      -59.693,
      -54.835,
      17.67,
      -59.693,
      -54.835,
      20.665,
      -61.58,
      -55.366,
      20.665,
      -61.58,
      -55.366,
      20.665,
      -61.58,
      -55.366,
      17.67,
      -59.693,
      -54.835,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4222_M0012",
    polygon: [
      -59.885,
      -52.395,
      17.67,
      -59.885,
      -52.395,
      20.665,
      -59.368,
      -54.233,
      20.665,
      -59.368,
      -54.233,
      20.665,
      -59.368,
      -54.233,
      17.67,
      -59.885,
      -52.395,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4222_M0013",
    polygon: [
      -60.421,
      -50.494,
      17.67,
      -60.421,
      -50.494,
      20.665,
      -59.903,
      -52.332,
      20.665,
      -59.903,
      -52.332,
      20.665,
      -59.903,
      -52.332,
      17.67,
      -60.421,
      -50.494,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4223_M0010",
    polygon: [
      -61.329,
      -47.272,
      17.67,
      -61.329,
      -47.272,
      20.665,
      -60.811,
      -49.11,
      20.665,
      -60.811,
      -49.11,
      20.665,
      -60.811,
      -49.11,
      17.67,
      -61.329,
      -47.272,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4223_M0011",
    polygon: [
      -61.864,
      -45.371,
      17.67,
      -61.864,
      -45.371,
      20.665,
      -61.346,
      -47.209,
      20.665,
      -61.346,
      -47.209,
      20.665,
      -61.346,
      -47.209,
      17.67,
      -61.864,
      -45.371,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4224_M0010",
    polygon: [
      -62.779,
      -42.122,
      17.67,
      -62.779,
      -42.122,
      20.665,
      -62.261,
      -43.96,
      20.665,
      -62.261,
      -43.96,
      20.665,
      -62.261,
      -43.96,
      17.67,
      -62.779,
      -42.122,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4224_M0011",
    polygon: [
      -63.315,
      -40.221,
      17.67,
      -63.315,
      -40.221,
      20.665,
      -62.797,
      -42.059,
      20.665,
      -62.797,
      -42.059,
      20.665,
      -62.797,
      -42.059,
      17.67,
      -63.315,
      -40.221,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4224_M0012",
    polygon: [
      -65.802,
      -40.4,
      17.67,
      -65.802,
      -40.4,
      20.665,
      -63.918,
      -39.869,
      20.665,
      -63.918,
      -39.869,
      20.665,
      -63.918,
      -39.869,
      17.67,
      -65.802,
      -40.4,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4224_M0013",
    polygon: [
      -67.748,
      -40.948,
      17.67,
      -67.748,
      -40.948,
      20.665,
      -65.864,
      -40.418,
      20.665,
      -65.864,
      -40.418,
      20.665,
      -65.864,
      -40.418,
      17.67,
      -67.748,
      -40.948,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4225_M0010",
    polygon: [
      -70.997,
      -41.864,
      17.67,
      -70.997,
      -41.864,
      20.665,
      -69.113,
      -41.333,
      20.665,
      -69.113,
      -41.333,
      20.665,
      -69.113,
      -41.333,
      17.67,
      -70.997,
      -41.864,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4226_M0010",
    polygon: [
      -72.944,
      -42.412,
      17.67,
      -72.944,
      -42.412,
      20.665,
      -71.06,
      -41.881,
      20.665,
      -71.06,
      -41.881,
      20.665,
      -71.06,
      -41.881,
      17.67,
      -72.944,
      -42.412,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4227_M0010",
    polygon: [
      -76.195,
      -43.328,
      17.67,
      -76.195,
      -43.328,
      20.665,
      -74.311,
      -42.797,
      20.665,
      -74.311,
      -42.797,
      20.665,
      -74.311,
      -42.797,
      17.67,
      -76.195,
      -43.328,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4228_M0010",
    polygon: [
      -78.142,
      -43.876,
      17.67,
      -78.142,
      -43.876,
      20.665,
      -76.257,
      -43.345,
      20.665,
      -76.257,
      -43.345,
      20.665,
      -76.257,
      -43.345,
      17.67,
      -78.142,
      -43.876,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4229_M0010",
    polygon: [
      -81.869,
      -44.926,
      17.67,
      -81.869,
      -44.926,
      20.665,
      -79.508,
      -44.261,
      20.665,
      -79.508,
      -44.261,
      20.665,
      -79.508,
      -44.261,
      17.67,
      -81.869,
      -44.926,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4230_M0010",
    polygon: [
      -83.106,
      -42.433,
      17.67,
      -83.106,
      -42.433,
      20.665,
      -82.624,
      -44.144,
      20.665,
      -82.624,
      -44.144,
      20.665,
      -82.624,
      -44.144,
      17.67,
      -83.106,
      -42.433,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4231_M0010",
    polygon: [
      -83.606,
      -40.659,
      17.67,
      -83.606,
      -40.659,
      20.665,
      -83.124,
      -42.37,
      20.665,
      -83.124,
      -42.37,
      20.665,
      -83.124,
      -42.37,
      17.67,
      -83.606,
      -40.659,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4231_M0011",
    polygon: [
      -84.472,
      -37.586,
      17.67,
      -84.472,
      -37.586,
      20.665,
      -83.99,
      -39.297,
      20.665,
      -83.99,
      -39.297,
      20.665,
      -83.99,
      -39.297,
      17.67,
      -84.472,
      -37.586,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4232_M0010",
    polygon: [
      -84.971,
      -35.813,
      17.67,
      -84.971,
      -35.813,
      20.665,
      -84.489,
      -37.524,
      20.665,
      -84.489,
      -37.524,
      20.665,
      -84.489,
      -37.524,
      17.67,
      -84.971,
      -35.813,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4232_M0011",
    polygon: [
      -85.838,
      -32.735,
      17.67,
      -85.838,
      -32.735,
      20.665,
      -85.356,
      -34.446,
      20.665,
      -85.356,
      -34.446,
      20.665,
      -85.356,
      -34.446,
      17.67,
      -85.838,
      -32.735,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4233_M0010",
    polygon: [
      -87.203,
      -27.889,
      17.67,
      -87.203,
      -27.889,
      20.665,
      -86.721,
      -29.6,
      20.665,
      -86.721,
      -29.6,
      20.665,
      -86.721,
      -29.6,
      17.67,
      -87.203,
      -27.889,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4233_M0011",
    polygon: [
      -86.338,
      -30.962,
      17.67,
      -86.338,
      -30.962,
      20.665,
      -85.856,
      -32.672,
      20.665,
      -85.856,
      -32.672,
      20.665,
      -85.856,
      -32.672,
      17.67,
      -86.338,
      -30.962,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4234_M0010",
    polygon: [
      -104.457,
      -39.826,
      17.67,
      -104.457,
      -39.826,
      20.665,
      -104.939,
      -38.115,
      20.665,
      -104.939,
      -38.115,
      20.665,
      -104.939,
      -38.115,
      17.67,
      -104.457,
      -39.826,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE04O_EZR4235_M0010",
    polygon: [
      -103.59,
      -42.904,
      17.67,
      -103.59,
      -42.904,
      20.665,
      -104.072,
      -41.193,
      20.665,
      -104.072,
      -41.193,
      20.665,
      -104.072,
      -41.193,
      17.67,
      -103.59,
      -42.904,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4236_M0010",
    polygon: [
      -103.09,
      -44.677,
      17.67,
      -103.09,
      -44.677,
      20.665,
      -103.572,
      -42.967,
      20.665,
      -103.572,
      -42.967,
      20.665,
      -103.572,
      -42.967,
      17.67,
      -103.09,
      -44.677,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4237_M0010",
    polygon: [
      -101.725,
      -49.524,
      17.67,
      -101.725,
      -49.524,
      20.665,
      -102.207,
      -47.813,
      20.665,
      -102.207,
      -47.813,
      20.665,
      -102.207,
      -47.813,
      17.67,
      -101.725,
      -49.524,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4237_M0011",
    polygon: [
      -102.225,
      -47.75,
      17.67,
      -102.225,
      -47.75,
      20.665,
      -102.707,
      -46.039,
      20.665,
      -102.707,
      -46.039,
      20.665,
      -102.707,
      -46.039,
      17.67,
      -102.225,
      -47.75,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4251_M0010",
    polygon: [
      -104.327,
      -51.252,
      17.67,
      -104.327,
      -51.252,
      20.665,
      -101.967,
      -50.587,
      20.665,
      -101.967,
      -50.587,
      20.665,
      -101.967,
      -50.587,
      17.67,
      -104.327,
      -51.252,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4301_M0010",
    polygon: [
      -93.897,
      -80.78,
      17.665,
      -93.897,
      -80.78,
      20.66,
      -92.012,
      -80.249,
      20.66,
      -92.012,
      -80.249,
      20.66,
      -92.012,
      -80.249,
      17.665,
      -93.897,
      -80.78,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4301_M0011",
    polygon: [
      -95.843,
      -81.328,
      17.665,
      -95.843,
      -81.328,
      20.66,
      -93.959,
      -80.798,
      20.66,
      -93.959,
      -80.798,
      20.66,
      -93.959,
      -80.798,
      17.665,
      -95.843,
      -81.328,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4302_M0010",
    polygon: [
      -99.094,
      -82.244,
      17.665,
      -99.094,
      -82.244,
      20.66,
      -97.21,
      -81.713,
      20.66,
      -97.21,
      -81.713,
      20.66,
      -97.21,
      -81.713,
      17.665,
      -99.094,
      -82.244,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4303_M0010",
    polygon: [
      -101.041,
      -82.792,
      17.665,
      -101.041,
      -82.792,
      20.66,
      -99.157,
      -82.262,
      20.66,
      -99.157,
      -82.262,
      20.66,
      -99.157,
      -82.262,
      17.665,
      -101.041,
      -82.792,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4304_M0010",
    polygon: [
      -104.297,
      -83.71,
      17.665,
      -104.297,
      -83.71,
      20.66,
      -102.413,
      -83.179,
      20.66,
      -102.413,
      -83.179,
      20.66,
      -102.413,
      -83.179,
      17.665,
      -104.297,
      -83.71,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4305_M0010",
    polygon: [
      -106.244,
      -84.258,
      17.665,
      -106.244,
      -84.258,
      20.66,
      -104.36,
      -83.727,
      20.66,
      -104.36,
      -83.727,
      20.66,
      -104.36,
      -83.727,
      17.665,
      -106.244,
      -84.258,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4306_M0010",
    polygon: [
      -109.495,
      -85.174,
      17.665,
      -109.495,
      -85.174,
      20.66,
      -107.611,
      -84.643,
      20.66,
      -107.611,
      -84.643,
      20.66,
      -107.611,
      -84.643,
      17.665,
      -109.495,
      -85.174,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4306_M0011",
    polygon: [
      -111.442,
      -85.722,
      17.665,
      -111.442,
      -85.722,
      20.66,
      -109.557,
      -85.191,
      20.66,
      -109.557,
      -85.191,
      20.66,
      -109.557,
      -85.191,
      17.665,
      -111.442,
      -85.722,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4306_M0012",
    polygon: [
      -111.261,
      -88.156,
      17.67,
      -111.261,
      -88.156,
      20.665,
      -111.786,
      -86.293,
      20.665,
      -111.786,
      -86.293,
      20.665,
      -111.786,
      -86.293,
      17.67,
      -111.261,
      -88.156,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4306_M0013",
    polygon: [
      -110.719,
      -90.081,
      17.67,
      -110.719,
      -90.081,
      20.665,
      -111.243,
      -88.219,
      20.665,
      -111.243,
      -88.219,
      20.665,
      -111.243,
      -88.219,
      17.67,
      -110.719,
      -90.081,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4307_M0010",
    polygon: [
      -109.81,
      -93.306,
      17.67,
      -109.81,
      -93.306,
      20.665,
      -110.335,
      -91.443,
      20.665,
      -110.335,
      -91.443,
      20.665,
      -110.335,
      -91.443,
      17.67,
      -109.81,
      -93.306,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4307_M0011",
    polygon: [
      -109.268,
      -95.232,
      17.67,
      -109.268,
      -95.232,
      20.665,
      -109.792,
      -93.369,
      20.665,
      -109.792,
      -93.369,
      20.665,
      -109.792,
      -93.369,
      17.67,
      -109.268,
      -95.232,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4308_M0010",
    polygon: [
      -108.368,
      -98.424,
      17.67,
      -108.368,
      -98.424,
      20.665,
      -108.893,
      -96.562,
      20.665,
      -108.893,
      -96.562,
      20.665,
      -108.893,
      -96.562,
      17.67,
      -108.368,
      -98.424,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4308_M0011",
    polygon: [
      -107.826,
      -100.35,
      17.67,
      -107.826,
      -100.35,
      20.665,
      -108.351,
      -98.487,
      20.665,
      -108.351,
      -98.487,
      20.665,
      -108.351,
      -98.487,
      17.67,
      -107.826,
      -100.35,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE04O_EZR4308_M0012",
    polygon: [
      -105.339,
      -100.158,
      17.67,
      -105.339,
      -100.158,
      20.665,
      -107.225,
      -100.69,
      20.665,
      -107.225,
      -100.69,
      20.665,
      -107.225,
      -100.69,
      17.67,
      -105.339,
      -100.158,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "LE100xxE04O_EZR4308_M0013",
    polygon: [
      -103.392,
      -99.61,
      17.67,
      -103.392,
      -99.61,
      20.665,
      -105.278,
      -100.141,
      20.665,
      -105.278,
      -100.141,
      20.665,
      -105.278,
      -100.141,
      17.67,
      -103.392,
      -99.61,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4309_M0010",
    polygon: [
      -100.14,
      -98.694,
      17.67,
      -100.14,
      -98.694,
      20.665,
      -102.027,
      -99.225,
      20.665,
      -102.027,
      -99.225,
      20.665,
      -102.027,
      -99.225,
      17.67,
      -100.14,
      -98.694,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4310_M0010",
    polygon: [
      -98.194,
      -98.146,
      17.67,
      -98.194,
      -98.146,
      20.665,
      -100.08,
      -98.677,
      20.665,
      -100.08,
      -98.677,
      20.665,
      -100.08,
      -98.677,
      17.67,
      -98.194,
      -98.146,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4311_M0010",
    polygon: [
      -94.943,
      -97.23,
      17.67,
      -94.943,
      -97.23,
      20.665,
      -96.83,
      -97.761,
      20.665,
      -96.83,
      -97.761,
      20.665,
      -96.83,
      -97.761,
      17.67,
      -94.943,
      -97.23,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4312_M0010",
    polygon: [
      -92.996,
      -96.682,
      17.67,
      -92.996,
      -96.682,
      20.665,
      -94.883,
      -97.213,
      20.665,
      -94.883,
      -97.213,
      20.665,
      -94.883,
      -97.213,
      17.67,
      -92.996,
      -96.682,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4313_M0010",
    polygon: [
      -89.745,
      -95.766,
      17.67,
      -89.745,
      -95.766,
      20.665,
      -91.632,
      -96.297,
      20.665,
      -91.632,
      -96.297,
      20.665,
      -91.632,
      -96.297,
      17.67,
      -89.745,
      -95.766,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4314_M0010",
    polygon: [
      -87.798,
      -95.217,
      17.67,
      -87.798,
      -95.217,
      20.665,
      -89.685,
      -95.749,
      20.665,
      -89.685,
      -95.749,
      20.665,
      -89.685,
      -95.749,
      17.67,
      -87.798,
      -95.217,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4315_M0010",
    polygon: [
      -84.547,
      -94.302,
      17.67,
      -84.547,
      -94.302,
      20.665,
      -86.434,
      -94.833,
      20.665,
      -86.434,
      -94.833,
      20.665,
      -86.434,
      -94.833,
      17.67,
      -84.547,
      -94.302,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4316_M0010",
    polygon: [
      -82.601,
      -93.753,
      17.67,
      -82.601,
      -93.753,
      20.665,
      -84.487,
      -94.285,
      20.665,
      -84.487,
      -94.285,
      20.665,
      -84.487,
      -94.285,
      17.67,
      -82.601,
      -93.753,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4316_M0011",
    polygon: [
      -82.54,
      -92.194,
      17.67,
      -82.54,
      -92.194,
      20.665,
      -82.269,
      -93.156,
      20.665,
      -82.269,
      -93.156,
      20.665,
      -82.269,
      -93.156,
      17.67,
      -82.54,
      -92.194,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
  },
  {
    ID: "LE100xxE04O_EZR4317_M0010",
    polygon: [
      -79.797,
      -90.886,
      17.67,
      -79.797,
      -90.886,
      20.665,
      -81.761,
      -91.439,
      20.665,
      -81.761,
      -91.439,
      20.665,
      -81.761,
      -91.439,
      17.67,
      -79.797,
      -90.886,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
  },
  {
    ID: "LE100xxE04O_EZR4318_M0010",
    polygon: [
      -77.771,
      -90.315,
      17.67,
      -77.771,
      -90.315,
      20.665,
      -79.734,
      -90.868,
      20.665,
      -79.734,
      -90.868,
      20.665,
      -79.734,
      -90.868,
      17.67,
      -77.771,
      -90.315,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
  },
  {
    ID: "LE100xxE04O_EZR4319_M0010",
    polygon: [
      -73.96,
      -91.319,
      17.67,
      -73.96,
      -91.319,
      20.665,
      -75.846,
      -91.851,
      20.665,
      -75.846,
      -91.851,
      20.665,
      -75.846,
      -91.851,
      17.67,
      -73.96,
      -91.319,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4319_M0011",
    polygon: [
      -76.446,
      -91.516,
      17.67,
      -76.446,
      -91.516,
      20.665,
      -76.717,
      -90.553,
      20.665,
      -76.717,
      -90.553,
      20.665,
      -76.717,
      -90.553,
      17.67,
      -76.446,
      -91.516,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4320_M0010",
    polygon: [
      -72.013,
      -90.771,
      17.67,
      -72.013,
      -90.771,
      20.665,
      -73.9,
      -91.302,
      20.665,
      -73.9,
      -91.302,
      20.665,
      -73.9,
      -91.302,
      17.67,
      -72.013,
      -90.771,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4321_M0010",
    polygon: [
      -68.762,
      -89.855,
      17.67,
      -68.762,
      -89.855,
      20.665,
      -70.648,
      -90.386,
      20.665,
      -70.648,
      -90.386,
      20.665,
      -70.648,
      -90.386,
      17.67,
      -68.762,
      -89.855,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4322_M0010",
    polygon: [
      -66.815,
      -89.307,
      17.67,
      -66.815,
      -89.307,
      20.665,
      -68.701,
      -89.838,
      20.665,
      -68.701,
      -89.838,
      20.665,
      -68.701,
      -89.838,
      17.67,
      -66.815,
      -89.307,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4323_M0010",
    polygon: [
      -63.564,
      -88.391,
      17.67,
      -63.564,
      -88.391,
      20.665,
      -65.451,
      -88.922,
      20.665,
      -65.451,
      -88.922,
      20.665,
      -65.451,
      -88.922,
      17.67,
      -63.564,
      -88.391,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4324_M0010",
    polygon: [
      -61.618,
      -87.843,
      17.67,
      -61.618,
      -87.843,
      20.665,
      -63.504,
      -88.374,
      20.665,
      -63.504,
      -88.374,
      20.665,
      -63.504,
      -88.374,
      17.67,
      -61.618,
      -87.843,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4325_M0010",
    polygon: [
      -58.366,
      -86.927,
      17.67,
      -58.366,
      -86.927,
      20.665,
      -60.253,
      -87.458,
      20.665,
      -60.253,
      -87.458,
      20.665,
      -60.253,
      -87.458,
      17.67,
      -58.366,
      -86.927,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4326_M0010",
    polygon: [
      -56.419,
      -86.378,
      17.67,
      -56.419,
      -86.378,
      20.665,
      -58.306,
      -86.91,
      20.665,
      -58.306,
      -86.91,
      20.665,
      -58.306,
      -86.91,
      17.67,
      -56.419,
      -86.378,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4327_M0010",
    polygon: [
      -53.168,
      -85.463,
      17.67,
      -53.168,
      -85.463,
      20.665,
      -55.055,
      -85.994,
      20.665,
      -55.055,
      -85.994,
      20.665,
      -55.055,
      -85.994,
      17.67,
      -53.168,
      -85.463,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE04O_EZR4327_M0011",
    polygon: [
      -51.222,
      -84.914,
      17.67,
      -51.222,
      -84.914,
      20.665,
      -53.108,
      -85.446,
      20.665,
      -53.108,
      -85.446,
      20.665,
      -53.108,
      -85.446,
      17.67,
      -51.222,
      -84.914,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE04O_EZR4327_M0012",
    polygon: [
      -51.41,
      -82.473,
      17.67,
      -51.41,
      -82.473,
      20.665,
      -50.892,
      -84.312,
      20.665,
      -50.892,
      -84.312,
      20.665,
      -50.892,
      -84.312,
      17.67,
      -51.41,
      -82.473,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4327_M0013",
    polygon: [
      -51.945,
      -80.572,
      17.67,
      -51.945,
      -80.572,
      20.665,
      -51.427,
      -82.411,
      20.665,
      -51.427,
      -82.411,
      20.665,
      -51.427,
      -82.411,
      17.67,
      -51.945,
      -80.572,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4328_M0010",
    polygon: [
      -52.853,
      -77.35,
      17.67,
      -52.853,
      -77.35,
      20.665,
      -52.335,
      -79.189,
      20.665,
      -52.335,
      -79.189,
      20.665,
      -52.335,
      -79.189,
      17.67,
      -52.853,
      -77.35,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4328_M0011",
    polygon: [
      -53.388,
      -75.449,
      17.67,
      -53.388,
      -75.449,
      20.665,
      -52.871,
      -77.288,
      20.665,
      -52.871,
      -77.288,
      20.665,
      -52.871,
      -77.288,
      17.67,
      -53.388,
      -75.449,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4329_M0010",
    polygon: [
      -54.303,
      -72.201,
      17.67,
      -54.303,
      -72.201,
      20.665,
      -53.786,
      -74.039,
      20.665,
      -53.786,
      -74.039,
      20.665,
      -53.786,
      -74.039,
      17.67,
      -54.303,
      -72.201,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE04O_EZR4329_M0011",
    polygon: [
      -54.839,
      -70.3,
      17.67,
      -54.839,
      -70.3,
      20.665,
      -54.321,
      -72.138,
      20.665,
      -54.321,
      -72.138,
      20.665,
      -54.321,
      -72.138,
      17.67,
      -54.839,
      -70.3,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE04O_EZR4329_M0012",
    polygon: [
      -57.322,
      -70.478,
      17.665,
      -57.322,
      -70.478,
      20.66,
      -55.438,
      -69.947,
      20.66,
      -55.438,
      -69.947,
      20.66,
      -55.438,
      -69.947,
      17.665,
      -57.322,
      -70.478,
      17.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4329_M0013",
    polygon: [
      -59.269,
      -71.026,
      17.665,
      -59.269,
      -71.026,
      20.66,
      -57.385,
      -70.495,
      20.66,
      -57.385,
      -70.495,
      20.66,
      -57.385,
      -70.495,
      17.665,
      -59.269,
      -71.026,
      17.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4330_M0010",
    polygon: [
      -62.52,
      -71.942,
      17.665,
      -62.52,
      -71.942,
      20.66,
      -60.636,
      -71.411,
      20.66,
      -60.636,
      -71.411,
      20.66,
      -60.636,
      -71.411,
      17.665,
      -62.52,
      -71.942,
      17.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4331_M0010",
    polygon: [
      -64.467,
      -72.49,
      17.665,
      -64.467,
      -72.49,
      20.66,
      -62.583,
      -71.959,
      20.66,
      -62.583,
      -71.959,
      20.66,
      -62.583,
      -71.959,
      17.665,
      -64.467,
      -72.49,
      17.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4332_M0010",
    polygon: [
      -67.723,
      -73.407,
      17.665,
      -67.723,
      -73.407,
      20.66,
      -65.839,
      -72.877,
      20.66,
      -65.839,
      -72.877,
      20.66,
      -65.839,
      -72.877,
      17.665,
      -67.723,
      -73.407,
      17.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE04O_EZR4333_M0010",
    polygon: [
      -69.67,
      -73.956,
      17.665,
      -69.67,
      -73.956,
      20.66,
      -67.785,
      -73.425,
      20.66,
      -67.785,
      -73.425,
      20.66,
      -67.785,
      -73.425,
      17.665,
      -69.67,
      -73.956,
      17.665,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5101_M0010",
    polygon: [
      -107.566,
      -30.718,
      21.17,
      -107.566,
      -30.718,
      24.165,
      -109.927,
      -31.383,
      24.165,
      -109.927,
      -31.383,
      24.165,
      -109.927,
      -31.383,
      21.17,
      -107.566,
      -30.718,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5102_M0010",
    polygon: [
      -111.293,
      -31.768,
      21.17,
      -111.293,
      -31.768,
      24.165,
      -113.177,
      -32.299,
      24.165,
      -113.177,
      -32.299,
      24.165,
      -113.177,
      -32.299,
      21.17,
      -111.293,
      -31.768,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5103_M0010",
    polygon: [
      -113.24,
      -32.316,
      21.17,
      -113.24,
      -32.316,
      24.165,
      -115.124,
      -32.847,
      24.165,
      -115.124,
      -32.847,
      24.165,
      -115.124,
      -32.847,
      21.17,
      -113.24,
      -32.316,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5104_M0010",
    polygon: [
      -116.491,
      -33.232,
      21.17,
      -116.491,
      -33.232,
      24.165,
      -118.375,
      -33.763,
      24.165,
      -118.375,
      -33.763,
      24.165,
      -118.375,
      -33.763,
      21.17,
      -116.491,
      -33.232,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5105_M0010",
    polygon: [
      -118.438,
      -33.78,
      21.17,
      -118.438,
      -33.78,
      24.165,
      -120.322,
      -34.311,
      24.165,
      -120.322,
      -34.311,
      24.165,
      -120.322,
      -34.311,
      21.17,
      -118.438,
      -33.78,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5106_M0010",
    polygon: [
      -121.686,
      -34.695,
      21.17,
      -121.686,
      -34.695,
      24.165,
      -123.57,
      -35.226,
      24.165,
      -123.57,
      -35.226,
      24.165,
      -123.57,
      -35.226,
      21.17,
      -121.686,
      -34.695,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5106_M0011",
    polygon: [
      -123.633,
      -35.244,
      21.17,
      -123.633,
      -35.244,
      24.165,
      -125.517,
      -35.775,
      24.165,
      -125.517,
      -35.775,
      24.165,
      -125.517,
      -35.775,
      21.17,
      -123.633,
      -35.244,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5106_M0012",
    polygon: [
      -126.111,
      -35.436,
      21.17,
      -126.111,
      -35.436,
      24.165,
      -126.636,
      -33.573,
      24.165,
      -126.636,
      -33.573,
      24.165,
      -126.636,
      -33.573,
      21.17,
      -126.111,
      -35.436,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5106_M0013",
    polygon: [
      -126.654,
      -33.51,
      21.17,
      -126.654,
      -33.51,
      24.165,
      -127.178,
      -31.648,
      24.165,
      -127.178,
      -31.648,
      24.165,
      -127.178,
      -31.648,
      21.17,
      -126.654,
      -33.51,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5107_M0010.01",
    polygon: [
      -127.553,
      -30.318,
      21.17,
      -127.553,
      -30.318,
      24.165,
      -128.078,
      -28.455,
      24.165,
      -128.078,
      -28.455,
      24.165,
      -128.078,
      -28.455,
      21.17,
      -127.553,
      -30.318,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5107_M0010.02",
    polygon: [
      -87.703,
      -26.115,
      21.17,
      -87.703,
      -26.115,
      24.165,
      -87.221,
      -27.826,
      24.165,
      -87.221,
      -27.826,
      24.165,
      -87.221,
      -27.826,
      21.17,
      -87.703,
      -26.115,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5107_M0011",
    polygon: [
      -128.095,
      -28.392,
      21.17,
      -128.095,
      -28.392,
      24.165,
      -128.62,
      -26.53,
      24.165,
      -128.62,
      -26.53,
      24.165,
      -128.62,
      -26.53,
      21.17,
      -128.095,
      -28.392,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5108_M0010",
    polygon: [
      -129.004,
      -25.168,
      21.17,
      -129.004,
      -25.168,
      24.165,
      -129.528,
      -23.305,
      24.165,
      -129.528,
      -23.305,
      24.165,
      -129.528,
      -23.305,
      21.17,
      -129.004,
      -25.168,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5108_M0011",
    polygon: [
      -129.546,
      -23.242,
      21.17,
      -129.546,
      -23.242,
      24.165,
      -130.071,
      -21.38,
      24.165,
      -130.071,
      -21.38,
      24.165,
      -130.071,
      -21.38,
      21.17,
      -129.546,
      -23.242,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5108_M0012",
    polygon: [
      -129.72,
      -20.806,
      21.17,
      -129.72,
      -20.806,
      24.165,
      -127.836,
      -20.276,
      24.165,
      -127.836,
      -20.276,
      24.165,
      -127.836,
      -20.276,
      21.17,
      -129.72,
      -20.806,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5108_M0013",
    polygon: [
      -127.773,
      -20.258,
      21.17,
      -127.773,
      -20.258,
      24.165,
      -125.889,
      -19.727,
      24.165,
      -125.889,
      -19.727,
      24.165,
      -125.889,
      -19.727,
      21.17,
      -127.773,
      -20.258,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5109_M0010",
    polygon: [
      -124.522,
      -19.342,
      21.17,
      -124.522,
      -19.342,
      24.165,
      -122.638,
      -18.812,
      24.165,
      -122.638,
      -18.812,
      24.165,
      -122.638,
      -18.812,
      21.17,
      -124.522,
      -19.342,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5110_M0010",
    polygon: [
      -122.575,
      -18.794,
      21.17,
      -122.575,
      -18.794,
      24.165,
      -120.691,
      -18.263,
      24.165,
      -120.691,
      -18.263,
      24.165,
      -120.691,
      -18.263,
      21.17,
      -122.575,
      -18.794,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5111_M0010",
    polygon: [
      -119.327,
      -17.879,
      21.17,
      -119.327,
      -17.879,
      24.165,
      -117.443,
      -17.348,
      24.165,
      -117.443,
      -17.348,
      24.165,
      -117.443,
      -17.348,
      21.17,
      -119.327,
      -17.879,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5112_M0010",
    polygon: [
      -117.38,
      -17.33,
      21.17,
      -117.38,
      -17.33,
      24.165,
      -115.496,
      -16.8,
      24.165,
      -115.496,
      -16.8,
      24.165,
      -115.496,
      -16.8,
      21.17,
      -117.38,
      -17.33,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5113_M0010",
    polygon: [
      -114.129,
      -16.415,
      21.17,
      -114.129,
      -16.415,
      24.165,
      -112.245,
      -15.884,
      24.165,
      -112.245,
      -15.884,
      24.165,
      -112.245,
      -15.884,
      21.17,
      -114.129,
      -16.415,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5114_M0010",
    polygon: [
      -112.182,
      -15.866,
      21.17,
      -112.182,
      -15.866,
      24.165,
      -110.298,
      -15.336,
      24.165,
      -110.298,
      -15.336,
      24.165,
      -110.298,
      -15.336,
      21.17,
      -112.182,
      -15.866,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5115_M0010",
    polygon: [
      -108.934,
      -14.951,
      21.17,
      -108.934,
      -14.951,
      24.165,
      -107.05,
      -14.421,
      24.165,
      -107.05,
      -14.421,
      24.165,
      -107.05,
      -14.421,
      21.17,
      -108.934,
      -14.951,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5116_M0010",
    polygon: [
      -106.987,
      -14.403,
      21.17,
      -106.987,
      -14.403,
      24.165,
      -105.103,
      -13.872,
      24.165,
      -105.103,
      -13.872,
      24.165,
      -105.103,
      -13.872,
      21.17,
      -106.987,
      -14.403,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5116_M0011",
    polygon: [
      -104.507,
      -14.208,
      21.17,
      -104.507,
      -14.208,
      24.165,
      -104.236,
      -15.171,
      24.165,
      -104.236,
      -15.171,
      24.165,
      -104.236,
      -15.171,
      21.17,
      -104.507,
      -14.208,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5117_M0010",
    polygon: [
      -103.177,
      -15.408,
      21.17,
      -103.177,
      -15.408,
      24.165,
      -101.213,
      -14.854,
      24.165,
      -101.213,
      -14.854,
      24.165,
      -101.213,
      -14.854,
      21.17,
      -103.177,
      -15.408,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5118_M0010",
    polygon: [
      -101.151,
      -14.837,
      21.17,
      -101.151,
      -14.837,
      24.165,
      -99.187,
      -14.284,
      24.165,
      -99.187,
      -14.284,
      24.165,
      -99.187,
      -14.284,
      21.17,
      -101.151,
      -14.837,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5119_M0010",
    polygon: [
      -98.413,
      -13.531,
      21.17,
      -98.413,
      -13.531,
      24.165,
      -98.684,
      -12.568,
      24.165,
      -98.684,
      -12.568,
      24.165,
      -98.684,
      -12.568,
      21.17,
      -98.413,
      -13.531,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5119_M0011",
    polygon: [
      -98.341,
      -11.967,
      21.17,
      -98.341,
      -11.967,
      24.165,
      -96.457,
      -11.437,
      24.165,
      -96.457,
      -11.437,
      24.165,
      -96.457,
      -11.437,
      21.17,
      -98.341,
      -11.967,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5120_M0010",
    polygon: [
      -96.394,
      -11.419,
      21.17,
      -96.394,
      -11.419,
      24.165,
      -94.51,
      -10.888,
      24.165,
      -94.51,
      -10.888,
      24.165,
      -94.51,
      -10.888,
      21.17,
      -96.394,
      -11.419,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5121_M0010",
    polygon: [
      -93.143,
      -10.503,
      21.17,
      -93.143,
      -10.503,
      24.165,
      -91.259,
      -9.973,
      24.165,
      -91.259,
      -9.973,
      24.165,
      -91.259,
      -9.973,
      21.17,
      -93.143,
      -10.503,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5122_M0010",
    polygon: [
      -91.197,
      -9.955,
      21.17,
      -91.197,
      -9.955,
      24.165,
      -89.312,
      -9.424,
      24.165,
      -89.312,
      -9.424,
      24.165,
      -89.312,
      -9.424,
      21.17,
      -91.197,
      -9.955,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5123_M0010",
    polygon: [
      -87.948,
      -9.04,
      21.17,
      -87.948,
      -9.04,
      24.165,
      -86.064,
      -8.509,
      24.165,
      -86.064,
      -8.509,
      24.165,
      -86.064,
      -8.509,
      21.17,
      -87.948,
      -9.04,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5124_M0010",
    polygon: [
      -86.001,
      -8.492,
      21.17,
      -86.001,
      -8.492,
      24.165,
      -84.117,
      -7.961,
      24.165,
      -84.117,
      -7.961,
      24.165,
      -84.117,
      -7.961,
      21.17,
      -86.001,
      -8.492,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5125_M0010",
    polygon: [
      -82.75,
      -7.576,
      21.17,
      -82.75,
      -7.576,
      24.165,
      -80.866,
      -7.045,
      24.165,
      -80.866,
      -7.045,
      24.165,
      -80.866,
      -7.045,
      21.17,
      -82.75,
      -7.576,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5126_M0010",
    polygon: [
      -80.803,
      -7.027,
      21.17,
      -80.803,
      -7.027,
      24.165,
      -78.919,
      -6.497,
      24.165,
      -78.919,
      -6.497,
      24.165,
      -78.919,
      -6.497,
      21.17,
      -80.803,
      -7.027,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5127_M0010",
    polygon: [
      -77.555,
      -6.112,
      21.17,
      -77.555,
      -6.112,
      24.165,
      -75.671,
      -5.582,
      24.165,
      -75.671,
      -5.582,
      24.165,
      -75.671,
      -5.582,
      21.17,
      -77.555,
      -6.112,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5127_M0011",
    polygon: [
      -75.608,
      -5.564,
      21.17,
      -75.608,
      -5.564,
      24.165,
      -73.724,
      -5.033,
      24.165,
      -73.724,
      -5.033,
      24.165,
      -73.724,
      -5.033,
      21.17,
      -75.608,
      -5.564,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5127_M0012",
    polygon: [
      -73.124,
      -5.386,
      21.17,
      -73.124,
      -5.386,
      24.165,
      -72.606,
      -7.224,
      24.165,
      -72.606,
      -7.224,
      24.165,
      -72.606,
      -7.224,
      21.17,
      -73.124,
      -5.386,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5127_M0013",
    polygon: [
      -72.589,
      -7.287,
      21.17,
      -72.589,
      -7.287,
      24.165,
      -72.071,
      -9.125,
      24.165,
      -72.071,
      -9.125,
      24.165,
      -72.071,
      -9.125,
      21.17,
      -72.589,
      -7.287,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5128_M0010",
    polygon: [
      -71.673,
      -10.535,
      21.17,
      -71.673,
      -10.535,
      24.165,
      -71.156,
      -12.374,
      24.165,
      -71.156,
      -12.374,
      24.165,
      -71.156,
      -12.374,
      21.17,
      -71.673,
      -10.535,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5128_M0011",
    polygon: [
      -71.138,
      -12.436,
      21.17,
      -71.138,
      -12.436,
      24.165,
      -70.62,
      -14.275,
      24.165,
      -70.62,
      -14.275,
      24.165,
      -70.62,
      -14.275,
      21.17,
      -71.138,
      -12.436,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5129_M0010",
    polygon: [
      -70.23,
      -15.658,
      21.17,
      -70.23,
      -15.658,
      24.165,
      -69.713,
      -17.497,
      24.165,
      -69.713,
      -17.497,
      24.165,
      -69.713,
      -17.497,
      21.17,
      -70.23,
      -15.658,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5129_M0011",
    polygon: [
      -69.695,
      -17.559,
      21.17,
      -69.695,
      -17.559,
      24.165,
      -69.177,
      -19.398,
      24.165,
      -69.177,
      -19.398,
      24.165,
      -69.177,
      -19.398,
      21.17,
      -69.695,
      -17.559,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5129_M0012",
    polygon: [
      -69.521,
      -20.001,
      21.17,
      -69.521,
      -20.001,
      24.165,
      -71.405,
      -20.532,
      24.165,
      -71.405,
      -20.532,
      24.165,
      -71.405,
      -20.532,
      21.17,
      -69.521,
      -20.001,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5129_M0013",
    polygon: [
      -71.468,
      -20.55,
      21.17,
      -71.468,
      -20.55,
      24.165,
      -73.352,
      -21.081,
      24.165,
      -73.352,
      -21.081,
      24.165,
      -73.352,
      -21.081,
      21.17,
      -71.468,
      -20.55,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5130_M0010",
    polygon: [
      -74.719,
      -21.466,
      21.17,
      -74.719,
      -21.466,
      24.165,
      -76.603,
      -21.996,
      24.165,
      -76.603,
      -21.996,
      24.165,
      -76.603,
      -21.996,
      21.17,
      -74.719,
      -21.466,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5131_M0010",
    polygon: [
      -76.666,
      -22.014,
      21.17,
      -76.666,
      -22.014,
      24.165,
      -78.55,
      -22.545,
      24.165,
      -78.55,
      -22.545,
      24.165,
      -78.55,
      -22.545,
      21.17,
      -76.666,
      -22.014,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5132_M0010",
    polygon: [
      -79.914,
      -22.929,
      21.17,
      -79.914,
      -22.929,
      24.165,
      -81.799,
      -23.46,
      24.165,
      -81.799,
      -23.46,
      24.165,
      -81.799,
      -23.46,
      21.17,
      -79.914,
      -22.929,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5133_M0010",
    polygon: [
      -81.861,
      -23.477,
      21.17,
      -81.861,
      -23.477,
      24.165,
      -83.745,
      -24.008,
      24.165,
      -83.745,
      -24.008,
      24.165,
      -83.745,
      -24.008,
      21.17,
      -81.861,
      -23.477,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5134_M0010",
    polygon: [
      -85.108,
      -24.392,
      21.17,
      -85.108,
      -24.392,
      24.165,
      -87.469,
      -25.057,
      24.165,
      -87.469,
      -25.057,
      24.165,
      -87.469,
      -25.057,
      21.17,
      -85.108,
      -24.392,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
  },
  {
    ID: "LE100xxE05O_EZR5149_M0010",
    polygon: [
      -106.321,
      -33.206,
      21.17,
      -106.321,
      -33.206,
      24.165,
      -106.803,
      -31.495,
      24.165,
      -106.803,
      -31.495,
      24.165,
      -106.803,
      -31.495,
      21.17,
      -106.321,
      -33.206,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5149_M0011",
    polygon: [
      -105.822,
      -34.98,
      21.17,
      -105.822,
      -34.98,
      24.165,
      -106.304,
      -33.269,
      24.165,
      -106.304,
      -33.269,
      24.165,
      -106.304,
      -33.269,
      21.17,
      -105.822,
      -34.98,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5149_M0012",
    polygon: [
      -104.956,
      -38.053,
      21.17,
      -104.956,
      -38.053,
      24.165,
      -105.438,
      -36.342,
      24.165,
      -105.438,
      -36.342,
      24.165,
      -105.438,
      -36.342,
      21.17,
      -104.956,
      -38.053,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE05O_EZR5201_M0010",
    polygon: [
      -107.578,
      -52.168,
      21.17,
      -107.578,
      -52.168,
      24.165,
      -105.694,
      -51.637,
      24.165,
      -105.694,
      -51.637,
      24.165,
      -105.694,
      -51.637,
      21.17,
      -107.578,
      -52.168,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5202_M0010",
    polygon: [
      -109.525,
      -52.716,
      21.17,
      -109.525,
      -52.716,
      24.165,
      -107.641,
      -52.185,
      24.165,
      -107.641,
      -52.185,
      24.165,
      -107.641,
      -52.185,
      21.17,
      -109.525,
      -52.716,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5203_M0010",
    polygon: [
      -112.774,
      -53.631,
      21.17,
      -112.774,
      -53.631,
      24.165,
      -110.89,
      -53.101,
      24.165,
      -110.89,
      -53.101,
      24.165,
      -110.89,
      -53.101,
      21.17,
      -112.774,
      -53.631,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5204_M0010",
    polygon: [
      -114.721,
      -54.18,
      21.17,
      -114.721,
      -54.18,
      24.165,
      -112.836,
      -53.649,
      24.165,
      -112.836,
      -53.649,
      24.165,
      -112.836,
      -53.649,
      21.17,
      -114.721,
      -54.18,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5205_M0010",
    polygon: [
      -117.972,
      -55.095,
      21.17,
      -117.972,
      -55.095,
      24.165,
      -116.087,
      -54.565,
      24.165,
      -116.087,
      -54.565,
      24.165,
      -116.087,
      -54.565,
      21.17,
      -117.972,
      -55.095,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5205_M0011",
    polygon: [
      -119.918,
      -55.644,
      21.17,
      -119.918,
      -55.644,
      24.165,
      -118.034,
      -55.113,
      24.165,
      -118.034,
      -55.113,
      24.165,
      -118.034,
      -55.113,
      21.17,
      -119.918,
      -55.644,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5205_M0012",
    polygon: [
      -119.731,
      -58.076,
      21.17,
      -119.731,
      -58.076,
      24.165,
      -120.256,
      -56.213,
      24.165,
      -120.256,
      -56.213,
      24.165,
      -120.256,
      -56.213,
      21.17,
      -119.731,
      -58.076,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5205_M0013",
    polygon: [
      -119.189,
      -60.001,
      21.17,
      -119.189,
      -60.001,
      24.165,
      -119.713,
      -58.139,
      24.165,
      -119.713,
      -58.139,
      24.165,
      -119.713,
      -58.139,
      21.17,
      -119.189,
      -60.001,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5206_M0010",
    polygon: [
      -118.28,
      -63.226,
      21.17,
      -118.28,
      -63.226,
      24.165,
      -118.805,
      -61.363,
      24.165,
      -118.805,
      -61.363,
      24.165,
      -118.805,
      -61.363,
      21.17,
      -118.28,
      -63.226,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5206_M0011",
    polygon: [
      -117.738,
      -65.151,
      21.17,
      -117.738,
      -65.151,
      24.165,
      -118.262,
      -63.289,
      24.165,
      -118.262,
      -63.289,
      24.165,
      -118.262,
      -63.289,
      21.17,
      -117.738,
      -65.151,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5207_M0010",
    polygon: [
      -116.838,
      -68.344,
      21.17,
      -116.838,
      -68.344,
      24.165,
      -117.363,
      -66.482,
      24.165,
      -117.363,
      -66.482,
      24.165,
      -117.363,
      -66.482,
      21.17,
      -116.838,
      -68.344,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5207_M0011",
    polygon: [
      -116.296,
      -70.27,
      21.17,
      -116.296,
      -70.27,
      24.165,
      -116.821,
      -68.407,
      24.165,
      -116.821,
      -68.407,
      24.165,
      -116.821,
      -68.407,
      21.17,
      -116.296,
      -70.27,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5207_M0012",
    polygon: [
      -113.81,
      -70.078,
      21.17,
      -113.81,
      -70.078,
      24.165,
      -115.696,
      -70.61,
      24.165,
      -115.696,
      -70.61,
      24.165,
      -115.696,
      -70.61,
      21.17,
      -113.81,
      -70.078,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5207_M0013",
    polygon: [
      -111.861,
      -69.529,
      21.17,
      -111.861,
      -69.529,
      24.165,
      -113.747,
      -70.061,
      24.165,
      -113.747,
      -70.061,
      24.165,
      -113.747,
      -70.061,
      21.17,
      -111.861,
      -69.529,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5208_M0010",
    polygon: [
      -108.612,
      -68.614,
      21.17,
      -108.612,
      -68.614,
      24.165,
      -110.499,
      -69.146,
      24.165,
      -110.499,
      -69.146,
      24.165,
      -110.499,
      -69.146,
      21.17,
      -108.612,
      -68.614,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5209_M0010",
    polygon: [
      -106.663,
      -68.065,
      21.17,
      -106.663,
      -68.065,
      24.165,
      -108.55,
      -68.597,
      24.165,
      -108.55,
      -68.597,
      24.165,
      -108.55,
      -68.597,
      21.17,
      -106.663,
      -68.065,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5210_M0010",
    polygon: [
      -103.414,
      -67.15,
      21.17,
      -103.414,
      -67.15,
      24.165,
      -105.301,
      -67.681,
      24.165,
      -105.301,
      -67.681,
      24.165,
      -105.301,
      -67.681,
      21.17,
      -103.414,
      -67.15,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5211_M0010",
    polygon: [
      -101.465,
      -66.601,
      21.17,
      -101.465,
      -66.601,
      24.165,
      -103.352,
      -67.132,
      24.165,
      -103.352,
      -67.132,
      24.165,
      -103.352,
      -67.132,
      21.17,
      -101.465,
      -66.601,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5212_M0010",
    polygon: [
      -98.217,
      -65.686,
      21.17,
      -98.217,
      -65.686,
      24.165,
      -100.103,
      -66.217,
      24.165,
      -100.103,
      -66.217,
      24.165,
      -100.103,
      -66.217,
      21.17,
      -98.217,
      -65.686,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5213_M0010",
    polygon: [
      -96.268,
      -65.137,
      21.17,
      -96.268,
      -65.137,
      24.165,
      -98.154,
      -65.668,
      24.165,
      -98.154,
      -65.668,
      24.165,
      -98.154,
      -65.668,
      21.17,
      -96.268,
      -65.137,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5214_M0010",
    polygon: [
      -92.547,
      -64.089,
      21.17,
      -92.547,
      -64.089,
      24.165,
      -94.908,
      -64.754,
      24.165,
      -94.908,
      -64.754,
      24.165,
      -94.908,
      -64.754,
      21.17,
      -92.547,
      -64.089,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5215_M0010",
    polygon: [
      -80.484,
      -60.691,
      21.17,
      -80.484,
      -60.691,
      24.165,
      -82.845,
      -61.356,
      24.165,
      -82.845,
      -61.356,
      24.165,
      -82.845,
      -61.356,
      21.17,
      -80.484,
      -60.691,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5216_M0010",
    polygon: [
      -77.236,
      -59.776,
      21.17,
      -77.236,
      -59.776,
      24.165,
      -79.122,
      -60.307,
      24.165,
      -79.122,
      -60.307,
      24.165,
      -79.122,
      -60.307,
      21.17,
      -77.236,
      -59.776,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5217_M0010",
    polygon: [
      -75.286,
      -59.227,
      21.17,
      -75.286,
      -59.227,
      24.165,
      -77.173,
      -59.758,
      24.165,
      -77.173,
      -59.758,
      24.165,
      -77.173,
      -59.758,
      21.17,
      -75.286,
      -59.227,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5218_M0010",
    polygon: [
      -72.038,
      -58.312,
      21.17,
      -72.038,
      -58.312,
      24.165,
      -73.924,
      -58.843,
      24.165,
      -73.924,
      -58.843,
      24.165,
      -73.924,
      -58.843,
      21.17,
      -72.038,
      -58.312,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5219_M0010",
    polygon: [
      -70.089,
      -57.763,
      21.17,
      -70.089,
      -57.763,
      24.165,
      -71.975,
      -58.294,
      24.165,
      -71.975,
      -58.294,
      24.165,
      -71.975,
      -58.294,
      21.17,
      -70.089,
      -57.763,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5220_M0010",
    polygon: [
      -66.84,
      -56.848,
      21.17,
      -66.84,
      -56.848,
      24.165,
      -68.727,
      -57.379,
      24.165,
      -68.727,
      -57.379,
      24.165,
      -68.727,
      -57.379,
      21.17,
      -66.84,
      -56.848,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5221_M0010",
    polygon: [
      -64.891,
      -56.299,
      21.17,
      -64.891,
      -56.299,
      24.165,
      -66.778,
      -56.83,
      24.165,
      -66.778,
      -56.83,
      24.165,
      -66.778,
      -56.83,
      21.17,
      -64.891,
      -56.299,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5222_M0010",
    polygon: [
      -61.642,
      -55.384,
      21.17,
      -61.642,
      -55.384,
      24.165,
      -63.529,
      -55.915,
      24.165,
      -63.529,
      -55.915,
      24.165,
      -63.529,
      -55.915,
      21.17,
      -61.642,
      -55.384,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5222_M0011",
    polygon: [
      -59.693,
      -54.835,
      21.17,
      -59.693,
      -54.835,
      24.165,
      -61.58,
      -55.366,
      24.165,
      -61.58,
      -55.366,
      24.165,
      -61.58,
      -55.366,
      21.17,
      -59.693,
      -54.835,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5222_M0012",
    polygon: [
      -59.885,
      -52.395,
      21.17,
      -59.885,
      -52.395,
      24.165,
      -59.368,
      -54.233,
      24.165,
      -59.368,
      -54.233,
      24.165,
      -59.368,
      -54.233,
      21.17,
      -59.885,
      -52.395,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5222_M0013",
    polygon: [
      -60.421,
      -50.494,
      21.17,
      -60.421,
      -50.494,
      24.165,
      -59.903,
      -52.332,
      24.165,
      -59.903,
      -52.332,
      24.165,
      -59.903,
      -52.332,
      21.17,
      -60.421,
      -50.494,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5223_M0010",
    polygon: [
      -61.329,
      -47.272,
      21.17,
      -61.329,
      -47.272,
      24.165,
      -60.811,
      -49.11,
      24.165,
      -60.811,
      -49.11,
      24.165,
      -60.811,
      -49.11,
      21.17,
      -61.329,
      -47.272,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5223_M0011",
    polygon: [
      -61.864,
      -45.371,
      21.17,
      -61.864,
      -45.371,
      24.165,
      -61.346,
      -47.209,
      24.165,
      -61.346,
      -47.209,
      24.165,
      -61.346,
      -47.209,
      21.17,
      -61.864,
      -45.371,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5224_M0010",
    polygon: [
      -62.779,
      -42.122,
      21.17,
      -62.779,
      -42.122,
      24.165,
      -62.261,
      -43.96,
      24.165,
      -62.261,
      -43.96,
      24.165,
      -62.261,
      -43.96,
      21.17,
      -62.779,
      -42.122,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5224_M0011",
    polygon: [
      -63.315,
      -40.221,
      21.17,
      -63.315,
      -40.221,
      24.165,
      -62.797,
      -42.059,
      24.165,
      -62.797,
      -42.059,
      24.165,
      -62.797,
      -42.059,
      21.17,
      -63.315,
      -40.221,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5224_M0012",
    polygon: [
      -65.802,
      -40.4,
      21.17,
      -65.802,
      -40.4,
      24.165,
      -63.918,
      -39.869,
      24.165,
      -63.918,
      -39.869,
      24.165,
      -63.918,
      -39.869,
      21.17,
      -65.802,
      -40.4,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5224_M0013",
    polygon: [
      -67.748,
      -40.948,
      21.17,
      -67.748,
      -40.948,
      24.165,
      -65.864,
      -40.418,
      24.165,
      -65.864,
      -40.418,
      24.165,
      -65.864,
      -40.418,
      21.17,
      -67.748,
      -40.948,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE100xxE05O_EZR5225_M0010",
    polygon: [
      -70.997,
      -41.864,
      21.17,
      -70.997,
      -41.864,
      24.165,
      -69.113,
      -41.333,
      24.165,
      -69.113,
      -41.333,
      24.165,
      -69.113,
      -41.333,
      21.17,
      -70.997,
      -41.864,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5226_M0010",
    polygon: [
      -72.944,
      -42.412,
      21.17,
      -72.944,
      -42.412,
      24.165,
      -71.06,
      -41.881,
      24.165,
      -71.06,
      -41.881,
      24.165,
      -71.06,
      -41.881,
      21.17,
      -72.944,
      -42.412,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE100xxE05O_EZR5227_M0010",
    polygon: [
      -76.195,
      -43.328,
      21.17,
      -76.195,
      -43.328,
      24.165,
      -74.311,
      -42.797,
      24.165,
      -74.311,
      -42.797,
      24.165,
      -74.311,
      -42.797,
      21.17,
      -76.195,
      -43.328,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5228_M0010",
    polygon: [
      -78.142,
      -43.876,
      21.17,
      -78.142,
      -43.876,
      24.165,
      -76.257,
      -43.345,
      24.165,
      -76.257,
      -43.345,
      24.165,
      -76.257,
      -43.345,
      21.17,
      -78.142,
      -43.876,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5229_M0010",
    polygon: [
      -81.869,
      -44.926,
      21.17,
      -81.869,
      -44.926,
      24.165,
      -79.508,
      -44.261,
      24.165,
      -79.508,
      -44.261,
      24.165,
      -79.508,
      -44.261,
      21.17,
      -81.869,
      -44.926,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5230_M0010",
    polygon: [
      -83.106,
      -42.433,
      21.17,
      -83.106,
      -42.433,
      24.165,
      -82.624,
      -44.144,
      24.165,
      -82.624,
      -44.144,
      24.165,
      -82.624,
      -44.144,
      21.17,
      -83.106,
      -42.433,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5231_M0010",
    polygon: [
      -83.606,
      -40.659,
      21.17,
      -83.606,
      -40.659,
      24.165,
      -83.124,
      -42.37,
      24.165,
      -83.124,
      -42.37,
      24.165,
      -83.124,
      -42.37,
      21.17,
      -83.606,
      -40.659,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5231_M0011",
    polygon: [
      -84.472,
      -37.586,
      21.17,
      -84.472,
      -37.586,
      24.165,
      -83.99,
      -39.297,
      24.165,
      -83.99,
      -39.297,
      24.165,
      -83.99,
      -39.297,
      21.17,
      -84.472,
      -37.586,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5232_M0010",
    polygon: [
      -84.971,
      -35.813,
      21.17,
      -84.971,
      -35.813,
      24.165,
      -84.489,
      -37.524,
      24.165,
      -84.489,
      -37.524,
      24.165,
      -84.489,
      -37.524,
      21.17,
      -84.971,
      -35.813,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5232_M0011",
    polygon: [
      -85.838,
      -32.735,
      21.17,
      -85.838,
      -32.735,
      24.165,
      -85.356,
      -34.446,
      24.165,
      -85.356,
      -34.446,
      24.165,
      -85.356,
      -34.446,
      21.17,
      -85.838,
      -32.735,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5233_M0010",
    polygon: [
      -87.203,
      -27.889,
      21.17,
      -87.203,
      -27.889,
      24.165,
      -86.721,
      -29.6,
      24.165,
      -86.721,
      -29.6,
      24.165,
      -86.721,
      -29.6,
      21.17,
      -87.203,
      -27.889,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5233_M0011",
    polygon: [
      -86.338,
      -30.962,
      21.17,
      -86.338,
      -30.962,
      24.165,
      -85.856,
      -32.672,
      24.165,
      -85.856,
      -32.672,
      24.165,
      -85.856,
      -32.672,
      21.17,
      -86.338,
      -30.962,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5235_M0010.01",
    polygon: [
      -104.457,
      -39.826,
      21.17,
      -104.457,
      -39.826,
      24.165,
      -104.939,
      -38.115,
      24.165,
      -104.939,
      -38.115,
      24.165,
      -104.939,
      -38.115,
      21.17,
      -104.457,
      -39.826,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "LE100xxE05O_EZR5235_M0010.02",
    polygon: [
      -104.327,
      -51.252,
      21.17,
      -104.327,
      -51.252,
      24.165,
      -101.967,
      -50.587,
      24.165,
      -101.967,
      -50.587,
      24.165,
      -101.967,
      -50.587,
      21.17,
      -104.327,
      -51.252,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5235_M0011",
    polygon: [
      -103.59,
      -42.904,
      21.17,
      -103.59,
      -42.904,
      24.165,
      -104.072,
      -41.193,
      24.165,
      -104.072,
      -41.193,
      24.165,
      -104.072,
      -41.193,
      21.17,
      -103.59,
      -42.904,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5236_M0010",
    polygon: [
      -103.09,
      -44.677,
      21.17,
      -103.09,
      -44.677,
      24.165,
      -103.572,
      -42.967,
      24.165,
      -103.572,
      -42.967,
      24.165,
      -103.572,
      -42.967,
      21.17,
      -103.09,
      -44.677,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5237_M0010",
    polygon: [
      -101.725,
      -49.524,
      21.17,
      -101.725,
      -49.524,
      24.165,
      -102.207,
      -47.813,
      24.165,
      -102.207,
      -47.813,
      24.165,
      -102.207,
      -47.813,
      21.17,
      -101.725,
      -49.524,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5237_M0011",
    polygon: [
      -102.225,
      -47.75,
      21.17,
      -102.225,
      -47.75,
      24.165,
      -102.707,
      -46.039,
      24.165,
      -102.707,
      -46.039,
      24.165,
      -102.707,
      -46.039,
      21.17,
      -102.225,
      -47.75,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5301_M0010",
    polygon: [
      -93.897,
      -80.78,
      21.165,
      -93.897,
      -80.78,
      24.16,
      -92.012,
      -80.249,
      24.16,
      -92.012,
      -80.249,
      24.16,
      -92.012,
      -80.249,
      21.165,
      -93.897,
      -80.78,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5301_M0011",
    polygon: [
      -95.843,
      -81.328,
      21.165,
      -95.843,
      -81.328,
      24.16,
      -93.959,
      -80.798,
      24.16,
      -93.959,
      -80.798,
      24.16,
      -93.959,
      -80.798,
      21.165,
      -95.843,
      -81.328,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5302_M0010",
    polygon: [
      -99.094,
      -82.244,
      21.165,
      -99.094,
      -82.244,
      24.16,
      -97.21,
      -81.713,
      24.16,
      -97.21,
      -81.713,
      24.16,
      -97.21,
      -81.713,
      21.165,
      -99.094,
      -82.244,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5303_M0010",
    polygon: [
      -101.041,
      -82.792,
      21.165,
      -101.041,
      -82.792,
      24.16,
      -99.157,
      -82.262,
      24.16,
      -99.157,
      -82.262,
      24.16,
      -99.157,
      -82.262,
      21.165,
      -101.041,
      -82.792,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5304_M0010",
    polygon: [
      -104.297,
      -83.71,
      21.165,
      -104.297,
      -83.71,
      24.16,
      -102.413,
      -83.179,
      24.16,
      -102.413,
      -83.179,
      24.16,
      -102.413,
      -83.179,
      21.165,
      -104.297,
      -83.71,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5305_M0010",
    polygon: [
      -106.244,
      -84.258,
      21.165,
      -106.244,
      -84.258,
      24.16,
      -104.36,
      -83.727,
      24.16,
      -104.36,
      -83.727,
      24.16,
      -104.36,
      -83.727,
      21.165,
      -106.244,
      -84.258,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5306_M0010",
    polygon: [
      -109.495,
      -85.174,
      21.165,
      -109.495,
      -85.174,
      24.16,
      -107.611,
      -84.643,
      24.16,
      -107.611,
      -84.643,
      24.16,
      -107.611,
      -84.643,
      21.165,
      -109.495,
      -85.174,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5306_M0011",
    polygon: [
      -111.442,
      -85.722,
      21.165,
      -111.442,
      -85.722,
      24.16,
      -109.557,
      -85.191,
      24.16,
      -109.557,
      -85.191,
      24.16,
      -109.557,
      -85.191,
      21.165,
      -111.442,
      -85.722,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5306_M0012",
    polygon: [
      -111.261,
      -88.156,
      21.17,
      -111.261,
      -88.156,
      24.165,
      -111.786,
      -86.293,
      24.165,
      -111.786,
      -86.293,
      24.165,
      -111.786,
      -86.293,
      21.17,
      -111.261,
      -88.156,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5306_M0013",
    polygon: [
      -110.719,
      -90.081,
      21.17,
      -110.719,
      -90.081,
      24.165,
      -111.243,
      -88.219,
      24.165,
      -111.243,
      -88.219,
      24.165,
      -111.243,
      -88.219,
      21.17,
      -110.719,
      -90.081,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5307_M0010",
    polygon: [
      -109.81,
      -93.306,
      21.17,
      -109.81,
      -93.306,
      24.165,
      -110.335,
      -91.443,
      24.165,
      -110.335,
      -91.443,
      24.165,
      -110.335,
      -91.443,
      21.17,
      -109.81,
      -93.306,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5307_M0011",
    polygon: [
      -109.268,
      -95.232,
      21.17,
      -109.268,
      -95.232,
      24.165,
      -109.792,
      -93.369,
      24.165,
      -109.792,
      -93.369,
      24.165,
      -109.792,
      -93.369,
      21.17,
      -109.268,
      -95.232,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5308_M0010",
    polygon: [
      -108.368,
      -98.424,
      21.17,
      -108.368,
      -98.424,
      24.165,
      -108.893,
      -96.562,
      24.165,
      -108.893,
      -96.562,
      24.165,
      -108.893,
      -96.562,
      21.17,
      -108.368,
      -98.424,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5308_M0011",
    polygon: [
      -107.826,
      -100.35,
      21.17,
      -107.826,
      -100.35,
      24.165,
      -108.351,
      -98.487,
      24.165,
      -108.351,
      -98.487,
      24.165,
      -108.351,
      -98.487,
      21.17,
      -107.826,
      -100.35,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE100xxE05O_EZR5308_M0012",
    polygon: [
      -105.339,
      -100.158,
      21.17,
      -105.339,
      -100.158,
      24.165,
      -107.225,
      -100.69,
      24.165,
      -107.225,
      -100.69,
      24.165,
      -107.225,
      -100.69,
      21.17,
      -105.339,
      -100.158,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "LE100xxE05O_EZR5308_M0013",
    polygon: [
      -103.392,
      -99.61,
      21.17,
      -103.392,
      -99.61,
      24.165,
      -105.278,
      -100.141,
      24.165,
      -105.278,
      -100.141,
      24.165,
      -105.278,
      -100.141,
      21.17,
      -103.392,
      -99.61,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5309_M0010",
    polygon: [
      -100.14,
      -98.694,
      21.17,
      -100.14,
      -98.694,
      24.165,
      -102.027,
      -99.225,
      24.165,
      -102.027,
      -99.225,
      24.165,
      -102.027,
      -99.225,
      21.17,
      -100.14,
      -98.694,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5310_M0010",
    polygon: [
      -98.194,
      -98.146,
      21.17,
      -98.194,
      -98.146,
      24.165,
      -100.08,
      -98.677,
      24.165,
      -100.08,
      -98.677,
      24.165,
      -100.08,
      -98.677,
      21.17,
      -98.194,
      -98.146,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5311_M0010",
    polygon: [
      -94.943,
      -97.23,
      21.17,
      -94.943,
      -97.23,
      24.165,
      -96.83,
      -97.761,
      24.165,
      -96.83,
      -97.761,
      24.165,
      -96.83,
      -97.761,
      21.17,
      -94.943,
      -97.23,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5312_M0010",
    polygon: [
      -92.996,
      -96.682,
      21.17,
      -92.996,
      -96.682,
      24.165,
      -94.883,
      -97.213,
      24.165,
      -94.883,
      -97.213,
      24.165,
      -94.883,
      -97.213,
      21.17,
      -92.996,
      -96.682,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5313_M0010",
    polygon: [
      -89.745,
      -95.766,
      21.17,
      -89.745,
      -95.766,
      24.165,
      -91.632,
      -96.297,
      24.165,
      -91.632,
      -96.297,
      24.165,
      -91.632,
      -96.297,
      21.17,
      -89.745,
      -95.766,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5314_M0010",
    polygon: [
      -87.798,
      -95.217,
      21.17,
      -87.798,
      -95.217,
      24.165,
      -89.685,
      -95.749,
      24.165,
      -89.685,
      -95.749,
      24.165,
      -89.685,
      -95.749,
      21.17,
      -87.798,
      -95.217,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5315_M0010",
    polygon: [
      -84.547,
      -94.302,
      21.17,
      -84.547,
      -94.302,
      24.165,
      -86.434,
      -94.833,
      24.165,
      -86.434,
      -94.833,
      24.165,
      -86.434,
      -94.833,
      21.17,
      -84.547,
      -94.302,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5316_M0010",
    polygon: [
      -82.601,
      -93.753,
      21.17,
      -82.601,
      -93.753,
      24.165,
      -84.487,
      -94.285,
      24.165,
      -84.487,
      -94.285,
      24.165,
      -84.487,
      -94.285,
      21.17,
      -82.601,
      -93.753,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5316_M0011",
    polygon: [
      -82.54,
      -92.194,
      21.17,
      -82.54,
      -92.194,
      24.165,
      -82.269,
      -93.156,
      24.165,
      -82.269,
      -93.156,
      24.165,
      -82.269,
      -93.156,
      21.17,
      -82.54,
      -92.194,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
  },
  {
    ID: "LE100xxE05O_EZR5317_M0010",
    polygon: [
      -79.797,
      -90.886,
      21.17,
      -79.797,
      -90.886,
      24.165,
      -81.761,
      -91.439,
      24.165,
      -81.761,
      -91.439,
      24.165,
      -81.761,
      -91.439,
      21.17,
      -79.797,
      -90.886,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
  },
  {
    ID: "LE100xxE05O_EZR5318_M0010",
    polygon: [
      -77.771,
      -90.315,
      21.17,
      -77.771,
      -90.315,
      24.165,
      -79.734,
      -90.868,
      24.165,
      -79.734,
      -90.868,
      24.165,
      -79.734,
      -90.868,
      21.17,
      -77.771,
      -90.315,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5319_M0010",
    polygon: [
      -76.446,
      -91.516,
      21.17,
      -76.446,
      -91.516,
      24.165,
      -76.717,
      -90.553,
      24.165,
      -76.717,
      -90.553,
      24.165,
      -76.717,
      -90.553,
      21.17,
      -76.446,
      -91.516,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5319_M0011",
    polygon: [
      -73.96,
      -91.319,
      21.17,
      -73.96,
      -91.319,
      24.165,
      -75.846,
      -91.851,
      24.165,
      -75.846,
      -91.851,
      24.165,
      -75.846,
      -91.851,
      21.17,
      -73.96,
      -91.319,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5320_M0010",
    polygon: [
      -72.013,
      -90.771,
      21.17,
      -72.013,
      -90.771,
      24.165,
      -73.9,
      -91.302,
      24.165,
      -73.9,
      -91.302,
      24.165,
      -73.9,
      -91.302,
      21.17,
      -72.013,
      -90.771,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5321_M0010",
    polygon: [
      -68.762,
      -89.855,
      21.17,
      -68.762,
      -89.855,
      24.165,
      -70.648,
      -90.386,
      24.165,
      -70.648,
      -90.386,
      24.165,
      -70.648,
      -90.386,
      21.17,
      -68.762,
      -89.855,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5322_M0010",
    polygon: [
      -66.815,
      -89.307,
      21.17,
      -66.815,
      -89.307,
      24.165,
      -68.701,
      -89.838,
      24.165,
      -68.701,
      -89.838,
      24.165,
      -68.701,
      -89.838,
      21.17,
      -66.815,
      -89.307,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5323_M0010",
    polygon: [
      -63.564,
      -88.391,
      21.17,
      -63.564,
      -88.391,
      24.165,
      -65.451,
      -88.922,
      24.165,
      -65.451,
      -88.922,
      24.165,
      -65.451,
      -88.922,
      21.17,
      -63.564,
      -88.391,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5324_M0010",
    polygon: [
      -61.618,
      -87.843,
      21.17,
      -61.618,
      -87.843,
      24.165,
      -63.504,
      -88.374,
      24.165,
      -63.504,
      -88.374,
      24.165,
      -63.504,
      -88.374,
      21.17,
      -61.618,
      -87.843,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5325_M0010",
    polygon: [
      -58.366,
      -86.927,
      21.17,
      -58.366,
      -86.927,
      24.165,
      -60.253,
      -87.458,
      24.165,
      -60.253,
      -87.458,
      24.165,
      -60.253,
      -87.458,
      21.17,
      -58.366,
      -86.927,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5326_M0010",
    polygon: [
      -56.419,
      -86.378,
      21.17,
      -56.419,
      -86.378,
      24.165,
      -58.306,
      -86.91,
      24.165,
      -58.306,
      -86.91,
      24.165,
      -58.306,
      -86.91,
      21.17,
      -56.419,
      -86.378,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5327_M0010",
    polygon: [
      -53.168,
      -85.463,
      21.17,
      -53.168,
      -85.463,
      24.165,
      -55.055,
      -85.994,
      24.165,
      -55.055,
      -85.994,
      24.165,
      -55.055,
      -85.994,
      21.17,
      -53.168,
      -85.463,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5327_M0011",
    polygon: [
      -51.222,
      -84.914,
      21.17,
      -51.222,
      -84.914,
      24.165,
      -53.108,
      -85.446,
      24.165,
      -53.108,
      -85.446,
      24.165,
      -53.108,
      -85.446,
      21.17,
      -51.222,
      -84.914,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5327_M0012",
    polygon: [
      -51.41,
      -82.473,
      21.17,
      -51.41,
      -82.473,
      24.165,
      -50.892,
      -84.312,
      24.165,
      -50.892,
      -84.312,
      24.165,
      -50.892,
      -84.312,
      21.17,
      -51.41,
      -82.473,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5327_M0013",
    polygon: [
      -51.945,
      -80.572,
      21.17,
      -51.945,
      -80.572,
      24.165,
      -51.427,
      -82.411,
      24.165,
      -51.427,
      -82.411,
      24.165,
      -51.427,
      -82.411,
      21.17,
      -51.945,
      -80.572,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5328_M0010",
    polygon: [
      -52.853,
      -77.35,
      21.17,
      -52.853,
      -77.35,
      24.165,
      -52.335,
      -79.189,
      24.165,
      -52.335,
      -79.189,
      24.165,
      -52.335,
      -79.189,
      21.17,
      -52.853,
      -77.35,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5328_M0011",
    polygon: [
      -53.388,
      -75.449,
      21.17,
      -53.388,
      -75.449,
      24.165,
      -52.871,
      -77.288,
      24.165,
      -52.871,
      -77.288,
      24.165,
      -52.871,
      -77.288,
      21.17,
      -53.388,
      -75.449,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5329_M0010",
    polygon: [
      -54.303,
      -72.201,
      21.17,
      -54.303,
      -72.201,
      24.165,
      -53.786,
      -74.039,
      24.165,
      -53.786,
      -74.039,
      24.165,
      -53.786,
      -74.039,
      21.17,
      -54.303,
      -72.201,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE100xxE05O_EZR5329_M0011",
    polygon: [
      -54.839,
      -70.3,
      21.17,
      -54.839,
      -70.3,
      24.165,
      -54.321,
      -72.138,
      24.165,
      -54.321,
      -72.138,
      24.165,
      -54.321,
      -72.138,
      21.17,
      -54.839,
      -70.3,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5329_M0012",
    polygon: [
      -57.322,
      -70.478,
      21.165,
      -57.322,
      -70.478,
      24.16,
      -55.438,
      -69.947,
      24.16,
      -55.438,
      -69.947,
      24.16,
      -55.438,
      -69.947,
      21.165,
      -57.322,
      -70.478,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE100xxE05O_EZR5329_M0013",
    polygon: [
      -59.269,
      -71.026,
      21.165,
      -59.269,
      -71.026,
      24.16,
      -57.385,
      -70.495,
      24.16,
      -57.385,
      -70.495,
      24.16,
      -57.385,
      -70.495,
      21.165,
      -59.269,
      -71.026,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5330_M0010",
    polygon: [
      -62.52,
      -71.942,
      21.165,
      -62.52,
      -71.942,
      24.16,
      -60.636,
      -71.411,
      24.16,
      -60.636,
      -71.411,
      24.16,
      -60.636,
      -71.411,
      21.165,
      -62.52,
      -71.942,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "LE100xxE05O_EZR5331_M0010",
    polygon: [
      -64.467,
      -72.49,
      21.165,
      -64.467,
      -72.49,
      24.16,
      -62.583,
      -71.959,
      24.16,
      -62.583,
      -71.959,
      24.16,
      -62.583,
      -71.959,
      21.165,
      -64.467,
      -72.49,
      21.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "LE100xxE05O_EZR5332_M0010",
    polygon: [
      -67.723,
      -73.407,
      21.165,
      -67.723,
      -73.407,
      24.16,
      -65.839,
      -72.877,
      24.16,
      -65.839,
      -72.877,
      24.16,
      -65.839,
      -72.877,
      21.165,
      -67.723,
      -73.407,
      21.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE100xxE05O_EZR5333_M0010",
    polygon: [
      -69.67,
      -73.956,
      21.165,
      -69.67,
      -73.956,
      24.16,
      -67.785,
      -73.425,
      24.16,
      -67.785,
      -73.425,
      24.16,
      -67.785,
      -73.425,
      21.165,
      -69.67,
      -73.956,
      21.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1101_M0010",
    polygon: [
      -4.957,
      -1.815,
      7.17,
      -4.957,
      -1.815,
      10.165,
      -7.318,
      -2.48,
      10.165,
      -7.318,
      -2.48,
      10.165,
      -7.318,
      -2.48,
      7.17,
      -4.957,
      -1.815,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1102_M0010",
    polygon: [
      -1.71,
      -0.9,
      7.17,
      -1.71,
      -0.9,
      10.165,
      -3.594,
      -1.431,
      10.165,
      -3.594,
      -1.431,
      10.165,
      -3.594,
      -1.431,
      7.17,
      -1.71,
      -0.9,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1103_M0010",
    polygon: [
      0.237,
      -0.352,
      7.17,
      0.237,
      -0.352,
      10.165,
      -1.648,
      -0.883,
      10.165,
      -1.648,
      -0.883,
      10.165,
      -1.648,
      -0.883,
      7.17,
      0.237,
      -0.352,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1104_M0010",
    polygon: [
      3.485,
      0.563,
      7.17,
      3.485,
      0.563,
      10.165,
      1.601,
      0.032,
      10.165,
      1.601,
      0.032,
      10.165,
      1.601,
      0.032,
      7.17,
      3.485,
      0.563,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1105_M0010",
    polygon: [
      5.432,
      1.112,
      7.17,
      5.432,
      1.112,
      10.165,
      3.548,
      0.581,
      10.165,
      3.548,
      0.581,
      10.165,
      3.548,
      0.581,
      7.17,
      5.432,
      1.112,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1106_M0010",
    polygon: [
      8.683,
      2.027,
      7.17,
      8.683,
      2.027,
      10.165,
      6.799,
      1.497,
      10.165,
      6.799,
      1.497,
      10.165,
      6.799,
      1.497,
      7.17,
      8.683,
      2.027,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
  },
  {
    ID: "LE200xxF01O_EZR1106_M0011",
    polygon: [
      10.63,
      2.576,
      7.17,
      10.63,
      2.576,
      10.165,
      8.745,
      2.045,
      10.165,
      8.745,
      2.045,
      10.165,
      8.745,
      2.045,
      7.17,
      10.63,
      2.576,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1106_M0012",
    polygon: [
      10.458,
      5.012,
      7.17,
      10.458,
      5.012,
      10.165,
      10.975,
      3.174,
      10.165,
      10.975,
      3.174,
      10.165,
      10.975,
      3.174,
      7.17,
      10.458,
      5.012,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1106_M0013",
    polygon: [
      9.922,
      6.913,
      7.17,
      9.922,
      6.913,
      10.165,
      10.44,
      5.075,
      10.165,
      10.44,
      5.075,
      10.165,
      10.44,
      5.075,
      7.17,
      9.922,
      6.913,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1107_M0010",
    polygon: [
      9.014,
      10.135,
      7.17,
      9.014,
      10.135,
      10.165,
      9.532,
      8.297,
      10.165,
      9.532,
      8.297,
      10.165,
      9.532,
      8.297,
      7.17,
      9.014,
      10.135,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1107_M0011",
    polygon: [
      8.479,
      12.036,
      7.17,
      8.479,
      12.036,
      10.165,
      8.997,
      10.198,
      10.165,
      8.997,
      10.198,
      10.165,
      8.997,
      10.198,
      7.17,
      8.479,
      12.036,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1108_M0010",
    polygon: [
      7.564,
      15.285,
      7.17,
      7.564,
      15.285,
      10.165,
      8.082,
      13.446,
      10.165,
      8.082,
      13.446,
      10.165,
      8.082,
      13.446,
      7.17,
      7.564,
      15.285,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1108_M0011",
    polygon: [
      7.028,
      17.186,
      7.17,
      7.028,
      17.186,
      10.165,
      7.546,
      15.348,
      10.165,
      7.546,
      15.348,
      10.165,
      7.546,
      15.348,
      7.17,
      7.028,
      17.186,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1108_M0012",
    polygon: [
      4.543,
      17.013,
      7.17,
      4.543,
      17.013,
      10.165,
      6.427,
      17.544,
      10.165,
      6.427,
      17.544,
      10.165,
      6.427,
      17.544,
      7.17,
      4.543,
      17.013,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1108_M0013",
    polygon: [
      2.596,
      16.465,
      7.17,
      2.596,
      16.465,
      10.165,
      4.48,
      16.995,
      10.165,
      4.48,
      16.995,
      10.165,
      4.48,
      16.995,
      7.17,
      2.596,
      16.465,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1109_M0010",
    polygon: [
      -0.653,
      15.55,
      7.17,
      -0.653,
      15.55,
      10.165,
      1.232,
      16.08,
      10.165,
      1.232,
      16.08,
      10.165,
      1.232,
      16.08,
      7.17,
      -0.653,
      15.55,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1110_M0010",
    polygon: [
      -2.599,
      15.001,
      7.17,
      -2.599,
      15.001,
      10.165,
      -0.715,
      15.532,
      10.165,
      -0.715,
      15.532,
      10.165,
      -0.715,
      15.532,
      7.17,
      -2.599,
      15.001,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1111_M0010",
    polygon: [
      -5.85,
      14.086,
      7.17,
      -5.85,
      14.086,
      10.165,
      -3.966,
      14.616,
      10.165,
      -3.966,
      14.616,
      10.165,
      -3.966,
      14.616,
      7.17,
      -5.85,
      14.086,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1112_M0010",
    polygon: [
      -7.797,
      13.537,
      7.17,
      -7.797,
      13.537,
      10.165,
      -5.913,
      14.068,
      10.165,
      -5.913,
      14.068,
      10.165,
      -5.913,
      14.068,
      7.17,
      -7.797,
      13.537,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1113_M0010",
    polygon: [
      -11.046,
      12.622,
      7.17,
      -11.046,
      12.622,
      10.165,
      -9.161,
      13.153,
      10.165,
      -9.161,
      13.153,
      10.165,
      -9.161,
      13.153,
      7.17,
      -11.046,
      12.622,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1114_M0010",
    polygon: [
      -12.992,
      12.074,
      7.17,
      -12.992,
      12.074,
      10.165,
      -11.108,
      12.604,
      10.165,
      -11.108,
      12.604,
      10.165,
      -11.108,
      12.604,
      7.17,
      -12.992,
      12.074,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1115_M0010",
    polygon: [
      -16.243,
      11.158,
      7.17,
      -16.243,
      11.158,
      10.165,
      -14.359,
      11.689,
      10.165,
      -14.359,
      11.689,
      10.165,
      -14.359,
      11.689,
      7.17,
      -16.243,
      11.158,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1116_M0010",
    polygon: [
      -18.19,
      10.61,
      7.17,
      -18.19,
      10.61,
      10.165,
      -16.306,
      11.14,
      10.165,
      -16.306,
      11.14,
      10.165,
      -16.306,
      11.14,
      7.17,
      -18.19,
      10.61,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1117_M0010",
    polygon: [
      -21.0,
      7.74,
      7.17,
      -21.0,
      7.74,
      10.165,
      -19.036,
      8.293,
      10.165,
      -19.036,
      8.293,
      10.165,
      -19.036,
      8.293,
      7.17,
      -21.0,
      7.74,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1118_M0010",
    polygon: [
      -23.026,
      7.17,
      7.17,
      -23.026,
      7.17,
      10.165,
      -21.062,
      7.723,
      10.165,
      -21.062,
      7.723,
      10.165,
      -21.062,
      7.723,
      7.17,
      -23.026,
      7.17,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1119_M0010",
    polygon: [
      -26.836,
      8.174,
      7.17,
      -26.836,
      8.174,
      10.165,
      -24.952,
      8.705,
      10.165,
      -24.952,
      8.705,
      10.165,
      -24.952,
      8.705,
      7.17,
      -26.836,
      8.174,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1120_M0010",
    polygon: [
      -28.783,
      7.626,
      7.17,
      -28.783,
      7.626,
      10.165,
      -26.899,
      8.157,
      10.165,
      -26.899,
      8.157,
      10.165,
      -26.899,
      8.157,
      7.17,
      -28.783,
      7.626,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1121_M0010",
    polygon: [
      -32.031,
      6.711,
      7.17,
      -32.031,
      6.711,
      10.165,
      -30.147,
      7.242,
      10.165,
      -30.147,
      7.242,
      10.165,
      -30.147,
      7.242,
      7.17,
      -32.031,
      6.711,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "LE200xxF01O_EZR1122_M0010",
    polygon: [
      -33.978,
      6.162,
      7.17,
      -33.978,
      6.162,
      10.165,
      -32.094,
      6.693,
      10.165,
      -32.094,
      6.693,
      10.165,
      -32.094,
      6.693,
      7.17,
      -33.978,
      6.162,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "LE200xxF01O_EZR1123_M0010",
    polygon: [
      -37.229,
      5.247,
      7.17,
      -37.229,
      5.247,
      10.165,
      -35.345,
      5.777,
      10.165,
      -35.345,
      5.777,
      10.165,
      -35.345,
      5.777,
      7.17,
      -37.229,
      5.247,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1124_M0010",
    polygon: [
      -39.176,
      4.698,
      7.17,
      -39.176,
      4.698,
      10.165,
      -37.292,
      5.229,
      10.165,
      -37.292,
      5.229,
      10.165,
      -37.292,
      5.229,
      7.17,
      -39.176,
      4.698,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1125_M0010",
    polygon: [
      -42.424,
      3.783,
      7.17,
      -42.424,
      3.783,
      10.165,
      -40.54,
      4.314,
      10.165,
      -40.54,
      4.314,
      10.165,
      -40.54,
      4.314,
      7.17,
      -42.424,
      3.783,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1126_M0010",
    polygon: [
      -44.371,
      3.235,
      7.17,
      -44.371,
      3.235,
      10.165,
      -42.487,
      3.766,
      10.165,
      -42.487,
      3.766,
      10.165,
      -42.487,
      3.766,
      7.17,
      -44.371,
      3.235,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1127_M0010",
    polygon: [
      -47.622,
      2.319,
      7.17,
      -47.622,
      2.319,
      10.165,
      -45.738,
      2.85,
      10.165,
      -45.738,
      2.85,
      10.165,
      -45.738,
      2.85,
      7.17,
      -47.622,
      2.319,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1127_M0011",
    polygon: [
      -49.569,
      1.771,
      7.17,
      -49.569,
      1.771,
      10.165,
      -47.685,
      2.301,
      10.165,
      -47.685,
      2.301,
      10.165,
      -47.685,
      2.301,
      7.17,
      -49.569,
      1.771,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1127_M0012",
    polygon: [
      -49.394,
      -0.668,
      7.17,
      -49.394,
      -0.668,
      10.165,
      -49.912,
      1.17,
      10.165,
      -49.912,
      1.17,
      10.165,
      -49.912,
      1.17,
      7.17,
      -49.394,
      -0.668,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1127_M0013",
    polygon: [
      -48.859,
      -2.569,
      7.17,
      -48.859,
      -2.569,
      10.165,
      -49.377,
      -0.731,
      10.165,
      -49.377,
      -0.731,
      10.165,
      -49.377,
      -0.731,
      7.17,
      -48.859,
      -2.569,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1128_M0010",
    polygon: [
      -47.951,
      -5.791,
      7.17,
      -47.951,
      -5.791,
      10.165,
      -48.469,
      -3.953,
      10.165,
      -48.469,
      -3.953,
      10.165,
      -48.469,
      -3.953,
      7.17,
      -47.951,
      -5.791,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1128_M0011",
    polygon: [
      -47.416,
      -7.692,
      7.17,
      -47.416,
      -7.692,
      10.165,
      -47.933,
      -5.854,
      10.165,
      -47.933,
      -5.854,
      10.165,
      -47.933,
      -5.854,
      7.17,
      -47.416,
      -7.692,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1129_ M0010",
    polygon: [
      -46.501,
      -10.941,
      7.17,
      -46.501,
      -10.941,
      10.165,
      -47.018,
      -9.102,
      10.165,
      -47.018,
      -9.102,
      10.165,
      -47.018,
      -9.102,
      7.17,
      -46.501,
      -10.941,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1129_ M0011",
    polygon: [
      -45.965,
      -12.842,
      7.17,
      -45.965,
      -12.842,
      10.165,
      -46.483,
      -11.004,
      10.165,
      -46.483,
      -11.004,
      10.165,
      -46.483,
      -11.004,
      7.17,
      -45.965,
      -12.842,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1129_ M0012",
    polygon: [
      -43.482,
      -12.667,
      7.17,
      -43.482,
      -12.667,
      10.165,
      -45.366,
      -13.197,
      10.165,
      -45.366,
      -13.197,
      10.165,
      -45.366,
      -13.197,
      7.17,
      -43.482,
      -12.667,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1129_ M0013",
    polygon: [
      -41.535,
      -12.118,
      7.17,
      -41.535,
      -12.118,
      10.165,
      -43.419,
      -12.649,
      10.165,
      -43.419,
      -12.649,
      10.165,
      -43.419,
      -12.649,
      7.17,
      -41.535,
      -12.118,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1130_M0010",
    polygon: [
      -38.287,
      -11.203,
      7.17,
      -38.287,
      -11.203,
      10.165,
      -40.171,
      -11.734,
      10.165,
      -40.171,
      -11.734,
      10.165,
      -40.171,
      -11.734,
      7.17,
      -38.287,
      -11.203,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1131_M0010",
    polygon: [
      -36.34,
      -10.655,
      7.17,
      -36.34,
      -10.655,
      10.165,
      -38.224,
      -11.186,
      10.165,
      -38.224,
      -11.186,
      10.165,
      -38.224,
      -11.186,
      7.17,
      -36.34,
      -10.655,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1132_M0010",
    polygon: [
      -33.089,
      -9.739,
      7.17,
      -33.089,
      -9.739,
      10.165,
      -34.973,
      -10.27,
      10.165,
      -34.973,
      -10.27,
      10.165,
      -34.973,
      -10.27,
      7.17,
      -33.089,
      -9.739,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1133_M0010",
    polygon: [
      -31.142,
      -9.191,
      7.17,
      -31.142,
      -9.191,
      10.165,
      -33.026,
      -9.722,
      10.165,
      -33.026,
      -9.722,
      10.165,
      -33.026,
      -9.722,
      7.17,
      -31.142,
      -9.191,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1134_M0010",
    polygon: [
      -27.416,
      -8.141,
      7.17,
      -27.416,
      -8.141,
      10.165,
      -29.776,
      -8.806,
      10.165,
      -29.776,
      -8.806,
      10.165,
      -29.776,
      -8.806,
      7.17,
      -27.416,
      -8.141,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1135_M0010",
    polygon: [
      -26.17,
      -10.63,
      7.17,
      -26.17,
      -10.63,
      10.165,
      -26.652,
      -8.919,
      10.165,
      -26.652,
      -8.919,
      10.165,
      -26.652,
      -8.919,
      7.17,
      -26.17,
      -10.63,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1149_M0010",
    polygon: [
      -7.55,
      -3.543,
      7.17,
      -7.55,
      -3.543,
      10.165,
      -7.068,
      -5.254,
      10.165,
      -7.068,
      -5.254,
      10.165,
      -7.068,
      -5.254,
      7.17,
      -7.55,
      -3.543,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1149_M0011",
    polygon: [
      -7.051,
      -5.317,
      7.17,
      -7.051,
      -5.317,
      10.165,
      -6.569,
      -7.028,
      10.165,
      -6.569,
      -7.028,
      10.165,
      -6.569,
      -7.028,
      7.17,
      -7.051,
      -5.317,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1149_M0012",
    polygon: [
      -6.185,
      -8.389,
      7.17,
      -6.185,
      -8.389,
      10.165,
      -5.703,
      -10.1,
      10.165,
      -5.703,
      -10.1,
      10.165,
      -5.703,
      -10.1,
      7.17,
      -6.185,
      -8.389,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1201_M0010",
    polygon: [
      2.009,
      -21.299,
      7.17,
      2.009,
      -21.299,
      10.165,
      3.894,
      -20.768,
      10.165,
      3.894,
      -20.768,
      10.165,
      3.894,
      -20.768,
      7.17,
      2.009,
      -21.299,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1202_M0010",
    polygon: [
      3.956,
      -20.75,
      7.17,
      3.956,
      -20.75,
      10.165,
      5.84,
      -20.22,
      10.165,
      5.84,
      -20.22,
      10.165,
      5.84,
      -20.22,
      7.17,
      3.956,
      -20.75,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1203_M0010",
    polygon: [
      7.207,
      -19.835,
      7.17,
      7.207,
      -19.835,
      10.165,
      9.091,
      -19.304,
      10.165,
      9.091,
      -19.304,
      10.165,
      9.091,
      -19.304,
      7.17,
      7.207,
      -19.835,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1204_M0010",
    polygon: [
      9.154,
      -19.286,
      7.17,
      9.154,
      -19.286,
      10.165,
      11.038,
      -18.756,
      10.165,
      11.038,
      -18.756,
      10.165,
      11.038,
      -18.756,
      7.17,
      9.154,
      -19.286,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1205_M0010",
    polygon: [
      12.402,
      -18.371,
      7.17,
      12.402,
      -18.371,
      10.165,
      14.287,
      -17.841,
      10.165,
      14.287,
      -17.841,
      10.165,
      14.287,
      -17.841,
      7.17,
      12.402,
      -18.371,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1205_M0011",
    polygon: [
      14.349,
      -17.823,
      7.17,
      14.349,
      -17.823,
      10.165,
      16.233,
      -17.292,
      10.165,
      16.233,
      -17.292,
      10.165,
      16.233,
      -17.292,
      7.17,
      14.349,
      -17.823,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1205_M0012",
    polygon: [
      16.838,
      -17.649,
      7.17,
      16.838,
      -17.649,
      10.165,
      17.356,
      -19.488,
      10.165,
      17.356,
      -19.488,
      10.165,
      17.356,
      -19.488,
      7.17,
      16.838,
      -17.649,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1205_M0013",
    polygon: [
      17.373,
      -19.55,
      7.17,
      17.373,
      -19.55,
      10.165,
      17.891,
      -21.389,
      10.165,
      17.891,
      -21.389,
      10.165,
      17.891,
      -21.389,
      7.17,
      17.373,
      -19.55,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1206_M0010",
    polygon: [
      18.288,
      -22.799,
      7.17,
      18.288,
      -22.799,
      10.165,
      18.806,
      -24.637,
      10.165,
      18.806,
      -24.637,
      10.165,
      18.806,
      -24.637,
      7.17,
      18.288,
      -22.799,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1206_M0011",
    polygon: [
      18.824,
      -24.7,
      7.17,
      18.824,
      -24.7,
      10.165,
      19.342,
      -26.538,
      10.165,
      19.342,
      -26.538,
      10.165,
      19.342,
      -26.538,
      7.17,
      18.824,
      -24.7,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1207_M0010",
    polygon: [
      19.731,
      -27.922,
      7.17,
      19.731,
      -27.922,
      10.165,
      20.249,
      -29.76,
      10.165,
      20.249,
      -29.76,
      10.165,
      20.249,
      -29.76,
      7.17,
      19.731,
      -27.922,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1207_M0011",
    polygon: [
      20.267,
      -29.823,
      7.17,
      20.267,
      -29.823,
      10.165,
      20.785,
      -31.661,
      10.165,
      20.785,
      -31.661,
      10.165,
      20.785,
      -31.661,
      7.17,
      20.267,
      -29.823,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1207_M0012",
    polygon: [
      20.458,
      -32.257,
      7.17,
      20.458,
      -32.257,
      10.165,
      18.571,
      -32.789,
      10.165,
      18.571,
      -32.789,
      10.165,
      18.571,
      -32.789,
      7.17,
      20.458,
      -32.257,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "LE200xxF01O_EZR1207_M0013",
    polygon: [
      18.508,
      -32.806,
      7.17,
      18.508,
      -32.806,
      10.165,
      16.622,
      -33.338,
      10.165,
      16.622,
      -33.338,
      10.165,
      16.622,
      -33.338,
      7.17,
      18.508,
      -32.806,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1208_M0010",
    polygon: [
      15.26,
      -33.722,
      7.17,
      15.26,
      -33.722,
      10.165,
      13.373,
      -34.253,
      10.165,
      13.373,
      -34.253,
      10.165,
      13.373,
      -34.253,
      7.17,
      15.26,
      -33.722,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1209_M0010",
    polygon: [
      13.311,
      -34.271,
      7.17,
      13.311,
      -34.271,
      10.165,
      11.424,
      -34.802,
      10.165,
      11.424,
      -34.802,
      10.165,
      11.424,
      -34.802,
      7.17,
      13.311,
      -34.271,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1210_M0010",
    polygon: [
      10.062,
      -35.186,
      7.17,
      10.062,
      -35.186,
      10.165,
      8.176,
      -35.717,
      10.165,
      8.176,
      -35.717,
      10.165,
      8.176,
      -35.717,
      7.17,
      10.062,
      -35.186,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1211_M0010",
    polygon: [
      8.113,
      -35.735,
      7.17,
      8.113,
      -35.735,
      10.165,
      6.226,
      -36.266,
      10.165,
      6.226,
      -36.266,
      10.165,
      6.226,
      -36.266,
      7.17,
      8.113,
      -35.735,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1212_M0010",
    polygon: [
      4.864,
      -36.65,
      7.17,
      4.864,
      -36.65,
      10.165,
      2.978,
      -37.181,
      10.165,
      2.978,
      -37.181,
      10.165,
      2.978,
      -37.181,
      7.17,
      4.864,
      -36.65,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1213_M0010",
    polygon: [
      2.915,
      -37.199,
      7.17,
      2.915,
      -37.199,
      10.165,
      1.029,
      -37.73,
      10.165,
      1.029,
      -37.73,
      10.165,
      1.029,
      -37.73,
      7.17,
      2.915,
      -37.199,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1214_M0010",
    polygon: [
      -0.333,
      -38.114,
      7.175,
      -0.333,
      -38.114,
      10.17,
      -2.694,
      -38.779,
      10.17,
      -2.694,
      -38.779,
      10.17,
      -2.694,
      -38.779,
      7.175,
      -0.333,
      -38.114,
      7.175,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1215_M0010",
    polygon: [
      -12.396,
      -41.512,
      7.175,
      -12.396,
      -41.512,
      10.17,
      -14.757,
      -42.177,
      10.17,
      -14.757,
      -42.177,
      10.17,
      -14.757,
      -42.177,
      7.175,
      -12.396,
      -41.512,
      7.175,
    ],
    "04-15 02:05": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1216_M0010",
    polygon: [
      -16.119,
      -42.56,
      7.17,
      -16.119,
      -42.56,
      10.165,
      -18.006,
      -43.092,
      10.165,
      -18.006,
      -43.092,
      10.165,
      -18.006,
      -43.092,
      7.17,
      -16.119,
      -42.56,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1217_M0010",
    polygon: [
      -18.068,
      -43.109,
      7.17,
      -18.068,
      -43.109,
      10.165,
      -19.955,
      -43.641,
      10.165,
      -19.955,
      -43.641,
      10.165,
      -19.955,
      -43.641,
      7.17,
      -18.068,
      -43.109,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1218_M0010",
    polygon: [
      -21.317,
      -44.025,
      7.17,
      -21.317,
      -44.025,
      10.165,
      -23.203,
      -44.556,
      10.165,
      -23.203,
      -44.556,
      10.165,
      -23.203,
      -44.556,
      7.17,
      -21.317,
      -44.025,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1219_M0010",
    polygon: [
      -23.266,
      -44.574,
      7.17,
      -23.266,
      -44.574,
      10.165,
      -25.152,
      -45.105,
      10.165,
      -25.152,
      -45.105,
      10.165,
      -25.152,
      -45.105,
      7.17,
      -23.266,
      -44.574,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1220_M0010",
    polygon: [
      -26.514,
      -45.489,
      7.17,
      -26.514,
      -45.489,
      10.165,
      -28.401,
      -46.02,
      10.165,
      -28.401,
      -46.02,
      10.165,
      -28.401,
      -46.02,
      7.17,
      -26.514,
      -45.489,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1221_M0010",
    polygon: [
      -28.464,
      -46.038,
      7.17,
      -28.464,
      -46.038,
      10.165,
      -30.35,
      -46.569,
      10.165,
      -30.35,
      -46.569,
      10.165,
      -30.35,
      -46.569,
      7.17,
      -28.464,
      -46.038,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1222_M0012",
    polygon: [
      -36.146,
      -47.674,
      7.17,
      -36.146,
      -47.674,
      10.165,
      -36.664,
      -45.836,
      10.165,
      -36.664,
      -45.836,
      10.165,
      -36.664,
      -45.836,
      7.17,
      -36.146,
      -47.674,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1222_M0013",
    polygon: [
      -36.682,
      -45.773,
      7.17,
      -36.682,
      -45.773,
      10.165,
      -37.199,
      -43.935,
      10.165,
      -37.199,
      -43.935,
      10.165,
      -37.199,
      -43.935,
      7.17,
      -36.682,
      -45.773,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "LE200xxF01O_EZR1223_M0010",
    polygon: [
      -37.608,
      -42.528,
      7.17,
      -37.608,
      -42.528,
      10.165,
      -38.126,
      -40.69,
      10.165,
      -38.126,
      -40.69,
      10.165,
      -38.126,
      -40.69,
      7.17,
      -37.608,
      -42.528,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1223_M0011",
    polygon: [
      -38.144,
      -40.627,
      7.17,
      -38.144,
      -40.627,
      10.165,
      -38.662,
      -38.789,
      10.165,
      -38.662,
      -38.789,
      10.165,
      -38.662,
      -38.789,
      7.17,
      -38.144,
      -40.627,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1224_M0010",
    polygon: [
      -39.047,
      -37.404,
      7.17,
      -39.047,
      -37.404,
      10.165,
      -39.564,
      -35.565,
      10.165,
      -39.564,
      -35.565,
      10.165,
      -39.564,
      -35.565,
      7.17,
      -39.047,
      -37.404,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
  },
  {
    ID: "LE200xxF01O_EZR1224_M0011",
    polygon: [
      -39.582,
      -35.503,
      7.17,
      -39.582,
      -35.503,
      10.165,
      -40.1,
      -33.664,
      10.165,
      -40.1,
      -33.664,
      10.165,
      -40.1,
      -33.664,
      7.17,
      -39.582,
      -35.503,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1224_M0012",
    polygon: [
      -39.767,
      -33.067,
      7.17,
      -39.767,
      -33.067,
      10.165,
      -37.883,
      -32.536,
      10.165,
      -37.883,
      -32.536,
      10.165,
      -37.883,
      -32.536,
      7.17,
      -39.767,
      -33.067,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1224_M0013",
    polygon: [
      -37.821,
      -32.518,
      7.17,
      -37.821,
      -32.518,
      10.165,
      -35.936,
      -31.988,
      10.165,
      -35.936,
      -31.988,
      10.165,
      -35.936,
      -31.988,
      7.17,
      -37.821,
      -32.518,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1225_M0010",
    polygon: [
      -34.57,
      -31.603,
      7.17,
      -34.57,
      -31.603,
      10.165,
      -32.685,
      -31.072,
      10.165,
      -32.685,
      -31.072,
      10.165,
      -32.685,
      -31.072,
      7.17,
      -34.57,
      -31.603,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1226_M0010",
    polygon: [
      -32.623,
      -31.054,
      7.17,
      -32.623,
      -31.054,
      10.165,
      -30.739,
      -30.523,
      10.165,
      -30.739,
      -30.523,
      10.165,
      -30.739,
      -30.523,
      7.17,
      -32.623,
      -31.054,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1227_M0010",
    polygon: [
      -29.374,
      -30.139,
      7.17,
      -29.374,
      -30.139,
      10.165,
      -27.49,
      -29.608,
      10.165,
      -27.49,
      -29.608,
      10.165,
      -27.49,
      -29.608,
      7.17,
      -29.374,
      -30.139,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1228_M0010",
    polygon: [
      -27.428,
      -29.591,
      7.17,
      -27.428,
      -29.591,
      10.165,
      -25.543,
      -29.06,
      10.165,
      -25.543,
      -29.06,
      10.165,
      -25.543,
      -29.06,
      7.17,
      -27.428,
      -29.591,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "LE200xxF01O_EZR1229_M0010",
    polygon: [
      -24.177,
      -28.675,
      7.17,
      -24.177,
      -28.675,
      10.165,
      -21.816,
      -28.01,
      10.165,
      -21.816,
      -28.01,
      10.165,
      -21.816,
      -28.01,
      7.17,
      -24.177,
      -28.675,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1230_M0010",
    polygon: [
      -21.574,
      -26.947,
      7.17,
      -21.574,
      -26.947,
      10.165,
      -22.056,
      -25.236,
      10.165,
      -22.056,
      -25.236,
      10.165,
      -22.056,
      -25.236,
      7.17,
      -21.574,
      -26.947,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "LE200xxF01O_EZR1231_M0010",
    polygon: [
      -22.074,
      -25.174,
      7.17,
      -22.074,
      -25.174,
      10.165,
      -22.556,
      -23.463,
      10.165,
      -22.556,
      -23.463,
      10.165,
      -22.556,
      -23.463,
      7.17,
      -22.074,
      -25.174,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1231_M0011",
    polygon: [
      -22.939,
      -22.101,
      7.17,
      -22.939,
      -22.101,
      10.165,
      -23.421,
      -20.39,
      10.165,
      -23.421,
      -20.39,
      10.165,
      -23.421,
      -20.39,
      7.17,
      -22.939,
      -22.101,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "LE200xxF01O_EZR1232_M0010",
    polygon: [
      -23.439,
      -20.327,
      7.17,
      -23.439,
      -20.327,
      10.165,
      -23.921,
      -18.616,
      10.165,
      -23.921,
      -18.616,
      10.165,
      -23.921,
      -18.616,
      7.17,
      -23.439,
      -20.327,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1232_M0011",
    polygon: [
      -24.306,
      -17.25,
      7.17,
      -24.306,
      -17.25,
      10.165,
      -24.788,
      -15.539,
      10.165,
      -24.788,
      -15.539,
      10.165,
      -24.788,
      -15.539,
      7.17,
      -24.306,
      -17.25,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 12:05": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "LE200xxF01O_EZR1233_M0010",
    polygon: [
      -25.671,
      -12.403,
      7.17,
      -25.671,
      -12.403,
      10.165,
      -26.153,
      -10.692,
      10.165,
      -26.153,
      -10.692,
      10.165,
      -26.153,
      -10.692,
      7.17,
      -25.671,
      -12.403,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1233_M0011",
    polygon: [
      -24.805,
      -15.476,
      7.17,
      -24.805,
      -15.476,
      10.165,
      -25.287,
      -13.765,
      10.165,
      -25.287,
      -13.765,
      10.165,
      -25.287,
      -13.765,
      7.17,
      -24.805,
      -15.476,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1234_M0010",
    polygon: [
      -5.686,
      -10.163,
      7.17,
      -5.686,
      -10.163,
      10.165,
      -5.204,
      -11.874,
      10.165,
      -5.204,
      -11.874,
      10.165,
      -5.204,
      -11.874,
      7.17,
      -5.686,
      -10.163,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1235_M0010",
    polygon: [
      -4.819,
      -13.241,
      7.17,
      -4.819,
      -13.241,
      10.165,
      -4.337,
      -14.952,
      10.165,
      -4.337,
      -14.952,
      10.165,
      -4.337,
      -14.952,
      7.17,
      -4.819,
      -13.241,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
  },
  {
    ID: "LE200xxF01O_EZR1236_M0010",
    polygon: [
      -4.319,
      -15.014,
      7.17,
      -4.319,
      -15.014,
      10.165,
      -3.837,
      -16.725,
      10.165,
      -3.837,
      -16.725,
      10.165,
      -3.837,
      -16.725,
      7.17,
      -4.319,
      -15.014,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1237_M0010",
    polygon: [
      -2.954,
      -19.861,
      7.17,
      -2.954,
      -19.861,
      10.165,
      -2.472,
      -21.572,
      10.165,
      -2.472,
      -21.572,
      10.165,
      -2.472,
      -21.572,
      7.17,
      -2.954,
      -19.861,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "LE200xxF01O_EZR1237_M0011",
    polygon: [
      -3.454,
      -18.087,
      7.17,
      -3.454,
      -18.087,
      10.165,
      -2.972,
      -19.798,
      10.165,
      -2.972,
      -19.798,
      10.165,
      -2.972,
      -19.798,
      7.17,
      -3.454,
      -18.087,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
  },
  {
    ID: "LE200xxF01O_EZR1251_M0010",
    polygon: [
      -1.718,
      -22.349,
      7.17,
      -1.718,
      -22.349,
      10.165,
      0.643,
      -21.684,
      10.165,
      0.643,
      -21.684,
      10.165,
      0.643,
      -21.684,
      7.17,
      -1.718,
      -22.349,
      7.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1301_M0010",
    polygon: [
      -18.951,
      -59.669,
      7.165,
      -18.951,
      -59.669,
      10.16,
      -17.067,
      -59.138,
      10.16,
      -17.067,
      -59.138,
      10.16,
      -17.067,
      -59.138,
      7.165,
      -18.951,
      -59.669,
      7.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "LE200xxF01O_EZR1302_M0010",
    polygon: [
      -20.898,
      -60.217,
      7.165,
      -20.898,
      -60.217,
      10.16,
      -19.013,
      -59.687,
      10.16,
      -19.013,
      -59.687,
      10.16,
      -19.013,
      -59.687,
      7.165,
      -20.898,
      -60.217,
      7.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1303_M0010",
    polygon: [
      -24.149,
      -61.133,
      7.165,
      -24.149,
      -61.133,
      10.16,
      -22.264,
      -60.602,
      10.16,
      -22.264,
      -60.602,
      10.16,
      -22.264,
      -60.602,
      7.165,
      -24.149,
      -61.133,
      7.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1304_M0010",
    polygon: [
      -26.095,
      -61.682,
      7.165,
      -26.095,
      -61.682,
      10.16,
      -24.211,
      -61.151,
      10.16,
      -24.211,
      -61.151,
      10.16,
      -24.211,
      -61.151,
      7.165,
      -26.095,
      -61.682,
      7.165,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1305_M0010",
    polygon: [
      -30.567,
      -67.481,
      7.17,
      -30.567,
      -67.481,
      10.165,
      -31.085,
      -65.643,
      10.165,
      -31.085,
      -65.643,
      10.165,
      -31.085,
      -65.643,
      7.17,
      -30.567,
      -67.481,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
  },
  {
    ID: "LE200xxF01O_EZR1305_M0011",
    polygon: [
      -31.102,
      -65.58,
      7.17,
      -31.102,
      -65.58,
      10.165,
      -31.62,
      -63.742,
      10.165,
      -31.62,
      -63.742,
      10.165,
      -31.62,
      -63.742,
      7.17,
      -31.102,
      -65.58,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1306_M0010",
    polygon: [
      -29.135,
      -72.608,
      7.17,
      -29.135,
      -72.608,
      10.165,
      -29.653,
      -70.769,
      10.165,
      -29.653,
      -70.769,
      10.165,
      -29.653,
      -70.769,
      7.17,
      -29.135,
      -72.608,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1306_M0011",
    polygon: [
      -29.671,
      -70.707,
      7.17,
      -29.671,
      -70.707,
      10.165,
      -30.189,
      -68.868,
      10.165,
      -30.189,
      -68.868,
      10.165,
      -30.189,
      -68.868,
      7.17,
      -29.671,
      -70.707,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
  },
  {
    ID: "LE200xxF01O_EZR1307_M0010",
    polygon: [
      -23.243,
      -77.027,
      7.17,
      -23.243,
      -77.027,
      10.165,
      -25.129,
      -77.559,
      10.165,
      -25.129,
      -77.559,
      10.165,
      -25.129,
      -77.559,
      7.17,
      -23.243,
      -77.027,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1307_M0011",
    polygon: [
      -25.189,
      -77.576,
      7.17,
      -25.189,
      -77.576,
      10.165,
      -27.076,
      -78.107,
      10.165,
      -27.076,
      -78.107,
      10.165,
      -27.076,
      -78.107,
      7.17,
      -25.189,
      -77.576,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF01O_EZR1307_M0012",
    polygon: [
      -27.68,
      -77.756,
      7.17,
      -27.68,
      -77.756,
      10.165,
      -28.198,
      -75.917,
      10.165,
      -28.198,
      -75.917,
      10.165,
      -28.198,
      -75.917,
      7.17,
      -27.68,
      -77.756,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1307_M0013",
    polygon: [
      -28.215,
      -75.855,
      7.17,
      -28.215,
      -75.855,
      10.165,
      -28.733,
      -74.016,
      10.165,
      -28.733,
      -74.016,
      10.165,
      -28.733,
      -74.016,
      7.17,
      -28.215,
      -75.855,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "LE200xxF01O_EZR1308_M0010",
    polygon: [
      -19.991,
      -76.112,
      7.17,
      -19.991,
      -76.112,
      10.165,
      -21.878,
      -76.643,
      10.165,
      -21.878,
      -76.643,
      10.165,
      -21.878,
      -76.643,
      7.17,
      -19.991,
      -76.112,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1309_M0010",
    polygon: [
      -18.044,
      -75.563,
      7.17,
      -18.044,
      -75.563,
      10.165,
      -19.931,
      -76.095,
      10.165,
      -19.931,
      -76.095,
      10.165,
      -19.931,
      -76.095,
      7.17,
      -18.044,
      -75.563,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1310_M0010",
    polygon: [
      -14.794,
      -74.648,
      7.17,
      -14.794,
      -74.648,
      10.165,
      -16.68,
      -75.179,
      10.165,
      -16.68,
      -75.179,
      10.165,
      -16.68,
      -75.179,
      7.17,
      -14.794,
      -74.648,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1311_M0010",
    polygon: [
      -12.847,
      -74.099,
      7.17,
      -12.847,
      -74.099,
      10.165,
      -14.734,
      -74.631,
      10.165,
      -14.734,
      -74.631,
      10.165,
      -14.734,
      -74.631,
      7.17,
      -12.847,
      -74.099,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1312_M0010",
    polygon: [
      -9.596,
      -73.183,
      7.17,
      -9.596,
      -73.183,
      10.165,
      -11.482,
      -73.715,
      10.165,
      -11.482,
      -73.715,
      10.165,
      -11.482,
      -73.715,
      7.17,
      -9.596,
      -73.183,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1313_M0010",
    polygon: [
      -7.649,
      -72.635,
      7.17,
      -7.649,
      -72.635,
      10.165,
      -9.535,
      -73.166,
      10.165,
      -9.535,
      -73.166,
      10.165,
      -9.535,
      -73.166,
      7.17,
      -7.649,
      -72.635,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1314_M0010",
    polygon: [
      -4.398,
      -71.719,
      7.17,
      -4.398,
      -71.719,
      10.165,
      -6.284,
      -72.251,
      10.165,
      -6.284,
      -72.251,
      10.165,
      -6.284,
      -72.251,
      7.17,
      -4.398,
      -71.719,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1315_M0010",
    polygon: [
      -2.451,
      -71.171,
      7.17,
      -2.451,
      -71.171,
      10.165,
      -4.338,
      -71.702,
      10.165,
      -4.338,
      -71.702,
      10.165,
      -4.338,
      -71.702,
      7.17,
      -2.451,
      -71.171,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1316_M0010",
    polygon: [
      0.799,
      -70.255,
      7.17,
      0.799,
      -70.255,
      10.165,
      -1.087,
      -70.787,
      10.165,
      -1.087,
      -70.787,
      10.165,
      -1.087,
      -70.787,
      7.17,
      0.799,
      -70.255,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1317_M0010",
    polygon: [
      2.746,
      -69.707,
      7.17,
      2.746,
      -69.707,
      10.165,
      0.86,
      -70.238,
      10.165,
      0.86,
      -70.238,
      10.165,
      0.86,
      -70.238,
      7.17,
      2.746,
      -69.707,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1318_M0010",
    polygon: [
      5.998,
      -68.791,
      7.17,
      5.998,
      -68.791,
      10.165,
      4.111,
      -69.322,
      10.165,
      4.111,
      -69.322,
      10.165,
      4.111,
      -69.322,
      7.17,
      5.998,
      -68.791,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1319_M0010",
    polygon: [
      7.944,
      -68.243,
      7.17,
      7.944,
      -68.243,
      10.165,
      6.058,
      -68.774,
      10.165,
      6.058,
      -68.774,
      10.165,
      6.058,
      -68.774,
      7.17,
      7.944,
      -68.243,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1320_M0010",
    polygon: [
      11.197,
      -67.332,
      7.17,
      11.197,
      -67.332,
      10.165,
      9.31,
      -67.864,
      10.165,
      9.31,
      -67.864,
      10.165,
      9.31,
      -67.864,
      7.17,
      11.197,
      -67.332,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF01O_EZR1320_M0011",
    polygon: [
      13.143,
      -66.784,
      7.17,
      13.143,
      -66.784,
      10.165,
      11.257,
      -67.315,
      10.165,
      11.257,
      -67.315,
      10.165,
      11.257,
      -67.315,
      7.17,
      13.143,
      -66.784,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF01O_EZR1320_M0012",
    polygon: [
      12.957,
      -64.348,
      7.17,
      12.957,
      -64.348,
      10.165,
      13.475,
      -66.187,
      10.165,
      13.475,
      -66.187,
      10.165,
      13.475,
      -66.187,
      7.17,
      12.957,
      -64.348,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1320_M0013",
    polygon: [
      12.421,
      -62.447,
      7.17,
      12.421,
      -62.447,
      10.165,
      12.939,
      -64.286,
      10.165,
      12.939,
      -64.286,
      10.165,
      12.939,
      -64.286,
      7.17,
      12.421,
      -62.447,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF01O_EZR1321_M0010",
    polygon: [
      11.514,
      -59.225,
      7.17,
      11.514,
      -59.225,
      10.165,
      12.032,
      -61.063,
      10.165,
      12.032,
      -61.063,
      10.165,
      12.032,
      -61.063,
      7.17,
      11.514,
      -59.225,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF01O_EZR1321_M0011",
    polygon: [
      10.978,
      -57.324,
      7.17,
      10.978,
      -57.324,
      10.165,
      11.496,
      -59.162,
      10.165,
      11.496,
      -59.162,
      10.165,
      11.496,
      -59.162,
      7.17,
      10.978,
      -57.324,
      7.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2101_M0010",
    polygon: [
      -4.957,
      -1.815,
      10.67,
      -4.957,
      -1.815,
      13.665,
      -7.318,
      -2.48,
      13.665,
      -7.318,
      -2.48,
      13.665,
      -7.318,
      -2.48,
      10.67,
      -4.957,
      -1.815,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2102_M0010",
    polygon: [
      -1.71,
      -0.9,
      10.67,
      -1.71,
      -0.9,
      13.665,
      -3.594,
      -1.431,
      13.665,
      -3.594,
      -1.431,
      13.665,
      -3.594,
      -1.431,
      10.67,
      -1.71,
      -0.9,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2103_M0010",
    polygon: [
      0.237,
      -0.352,
      10.67,
      0.237,
      -0.352,
      13.665,
      -1.648,
      -0.883,
      13.665,
      -1.648,
      -0.883,
      13.665,
      -1.648,
      -0.883,
      10.67,
      0.237,
      -0.352,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
  },
  {
    ID: "LE200xxF02O_EZR2104_M0010",
    polygon: [
      3.485,
      0.563,
      10.67,
      3.485,
      0.563,
      13.665,
      1.601,
      0.032,
      13.665,
      1.601,
      0.032,
      13.665,
      1.601,
      0.032,
      10.67,
      3.485,
      0.563,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "LE200xxF02O_EZR2105_M0010",
    polygon: [
      5.432,
      1.112,
      10.67,
      5.432,
      1.112,
      13.665,
      3.548,
      0.581,
      13.665,
      3.548,
      0.581,
      13.665,
      3.548,
      0.581,
      10.67,
      5.432,
      1.112,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
  },
  {
    ID: "LE200xxF02O_EZR2106_M0010",
    polygon: [
      8.683,
      2.027,
      10.67,
      8.683,
      2.027,
      13.665,
      6.799,
      1.497,
      13.665,
      6.799,
      1.497,
      13.665,
      6.799,
      1.497,
      10.67,
      8.683,
      2.027,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2106_M0011",
    polygon: [
      10.63,
      2.576,
      10.67,
      10.63,
      2.576,
      13.665,
      8.745,
      2.045,
      13.665,
      8.745,
      2.045,
      13.665,
      8.745,
      2.045,
      10.67,
      10.63,
      2.576,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2106_M0012",
    polygon: [
      10.458,
      5.012,
      10.67,
      10.458,
      5.012,
      13.665,
      10.975,
      3.174,
      13.665,
      10.975,
      3.174,
      13.665,
      10.975,
      3.174,
      10.67,
      10.458,
      5.012,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2106_M0013",
    polygon: [
      9.922,
      6.913,
      10.67,
      9.922,
      6.913,
      13.665,
      10.44,
      5.075,
      13.665,
      10.44,
      5.075,
      13.665,
      10.44,
      5.075,
      10.67,
      9.922,
      6.913,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2107_M0010",
    polygon: [
      9.014,
      10.135,
      10.67,
      9.014,
      10.135,
      13.665,
      9.532,
      8.297,
      13.665,
      9.532,
      8.297,
      13.665,
      9.532,
      8.297,
      10.67,
      9.014,
      10.135,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2107_M0011",
    polygon: [
      8.479,
      12.036,
      10.67,
      8.479,
      12.036,
      13.665,
      8.997,
      10.198,
      13.665,
      8.997,
      10.198,
      13.665,
      8.997,
      10.198,
      10.67,
      8.479,
      12.036,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2108_M0010",
    polygon: [
      7.564,
      15.285,
      10.67,
      7.564,
      15.285,
      13.665,
      8.082,
      13.446,
      13.665,
      8.082,
      13.446,
      13.665,
      8.082,
      13.446,
      10.67,
      7.564,
      15.285,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2108_M0011",
    polygon: [
      7.028,
      17.186,
      10.67,
      7.028,
      17.186,
      13.665,
      7.546,
      15.348,
      13.665,
      7.546,
      15.348,
      13.665,
      7.546,
      15.348,
      10.67,
      7.028,
      17.186,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2108_M0012",
    polygon: [
      4.543,
      17.013,
      10.67,
      4.543,
      17.013,
      13.665,
      6.427,
      17.544,
      13.665,
      6.427,
      17.544,
      13.665,
      6.427,
      17.544,
      10.67,
      4.543,
      17.013,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2108_M0013",
    polygon: [
      2.596,
      16.465,
      10.67,
      2.596,
      16.465,
      13.665,
      4.48,
      16.995,
      13.665,
      4.48,
      16.995,
      13.665,
      4.48,
      16.995,
      10.67,
      2.596,
      16.465,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2109_M0010",
    polygon: [
      -0.653,
      15.55,
      10.67,
      -0.653,
      15.55,
      13.665,
      1.232,
      16.08,
      13.665,
      1.232,
      16.08,
      13.665,
      1.232,
      16.08,
      10.67,
      -0.653,
      15.55,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2110_M0010",
    polygon: [
      -2.599,
      15.001,
      10.67,
      -2.599,
      15.001,
      13.665,
      -0.715,
      15.532,
      13.665,
      -0.715,
      15.532,
      13.665,
      -0.715,
      15.532,
      10.67,
      -2.599,
      15.001,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2111_M0010",
    polygon: [
      -5.85,
      14.086,
      10.67,
      -5.85,
      14.086,
      13.665,
      -3.966,
      14.616,
      13.665,
      -3.966,
      14.616,
      13.665,
      -3.966,
      14.616,
      10.67,
      -5.85,
      14.086,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2112_M0010",
    polygon: [
      -7.797,
      13.537,
      10.67,
      -7.797,
      13.537,
      13.665,
      -5.913,
      14.068,
      13.665,
      -5.913,
      14.068,
      13.665,
      -5.913,
      14.068,
      10.67,
      -7.797,
      13.537,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2113_M0010",
    polygon: [
      -11.046,
      12.622,
      10.67,
      -11.046,
      12.622,
      13.665,
      -9.161,
      13.153,
      13.665,
      -9.161,
      13.153,
      13.665,
      -9.161,
      13.153,
      10.67,
      -11.046,
      12.622,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2114_M0010",
    polygon: [
      -12.992,
      12.074,
      10.67,
      -12.992,
      12.074,
      13.665,
      -11.108,
      12.604,
      13.665,
      -11.108,
      12.604,
      13.665,
      -11.108,
      12.604,
      10.67,
      -12.992,
      12.074,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2115_M0010",
    polygon: [
      -16.243,
      11.158,
      10.67,
      -16.243,
      11.158,
      13.665,
      -14.359,
      11.689,
      13.665,
      -14.359,
      11.689,
      13.665,
      -14.359,
      11.689,
      10.67,
      -16.243,
      11.158,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2116_M0010",
    polygon: [
      -18.262,
      9.047,
      10.67,
      -18.262,
      9.047,
      13.665,
      -18.533,
      10.009,
      13.665,
      -18.533,
      10.009,
      13.665,
      -18.533,
      10.009,
      10.67,
      -18.262,
      9.047,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2116_M0011",
    polygon: [
      -18.19,
      10.61,
      10.67,
      -18.19,
      10.61,
      13.665,
      -16.306,
      11.14,
      13.665,
      -16.306,
      11.14,
      13.665,
      -16.306,
      11.14,
      10.67,
      -18.19,
      10.61,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2117_M0010",
    polygon: [
      -21.0,
      7.74,
      10.67,
      -21.0,
      7.74,
      13.665,
      -19.036,
      8.293,
      13.665,
      -19.036,
      8.293,
      13.665,
      -19.036,
      8.293,
      10.67,
      -21.0,
      7.74,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2118_M0010",
    polygon: [
      -23.026,
      7.17,
      10.67,
      -23.026,
      7.17,
      13.665,
      -21.062,
      7.723,
      13.665,
      -21.062,
      7.723,
      13.665,
      -21.062,
      7.723,
      10.67,
      -23.026,
      7.17,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2119_M0010",
    polygon: [
      -24.357,
      8.369,
      10.67,
      -24.357,
      8.369,
      13.665,
      -24.085,
      7.406,
      13.665,
      -24.085,
      7.406,
      13.665,
      -24.085,
      7.406,
      10.67,
      -24.357,
      8.369,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2119_M0011",
    polygon: [
      -26.836,
      8.174,
      10.67,
      -26.836,
      8.174,
      13.665,
      -24.952,
      8.705,
      13.665,
      -24.952,
      8.705,
      13.665,
      -24.952,
      8.705,
      10.67,
      -26.836,
      8.174,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF02O_EZR2120_M0010",
    polygon: [
      -28.783,
      7.626,
      10.67,
      -28.783,
      7.626,
      13.665,
      -26.899,
      8.157,
      13.665,
      -26.899,
      8.157,
      13.665,
      -26.899,
      8.157,
      10.67,
      -28.783,
      7.626,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF02O_EZR2121_M0010",
    polygon: [
      -32.031,
      6.711,
      10.67,
      -32.031,
      6.711,
      13.665,
      -30.147,
      7.242,
      13.665,
      -30.147,
      7.242,
      13.665,
      -30.147,
      7.242,
      10.67,
      -32.031,
      6.711,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "LE200xxF02O_EZR2122_M0010",
    polygon: [
      -33.978,
      6.162,
      10.67,
      -33.978,
      6.162,
      13.665,
      -32.094,
      6.693,
      13.665,
      -32.094,
      6.693,
      13.665,
      -32.094,
      6.693,
      10.67,
      -33.978,
      6.162,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "LE200xxF02O_EZR2123_M0010",
    polygon: [
      -37.229,
      5.247,
      10.67,
      -37.229,
      5.247,
      13.665,
      -35.345,
      5.777,
      13.665,
      -35.345,
      5.777,
      13.665,
      -35.345,
      5.777,
      10.67,
      -37.229,
      5.247,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2124_M0010",
    polygon: [
      -39.176,
      4.698,
      10.67,
      -39.176,
      4.698,
      13.665,
      -37.292,
      5.229,
      13.665,
      -37.292,
      5.229,
      13.665,
      -37.292,
      5.229,
      10.67,
      -39.176,
      4.698,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2125_M0010",
    polygon: [
      -42.424,
      3.783,
      10.67,
      -42.424,
      3.783,
      13.665,
      -40.54,
      4.314,
      13.665,
      -40.54,
      4.314,
      13.665,
      -40.54,
      4.314,
      10.67,
      -42.424,
      3.783,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2126_M0010",
    polygon: [
      -44.371,
      3.235,
      10.67,
      -44.371,
      3.235,
      13.665,
      -42.487,
      3.766,
      13.665,
      -42.487,
      3.766,
      13.665,
      -42.487,
      3.766,
      10.67,
      -44.371,
      3.235,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2127_M0010",
    polygon: [
      -47.622,
      2.319,
      10.67,
      -47.622,
      2.319,
      13.665,
      -45.738,
      2.85,
      13.665,
      -45.738,
      2.85,
      13.665,
      -45.738,
      2.85,
      10.67,
      -47.622,
      2.319,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2127_M0011",
    polygon: [
      -49.569,
      1.771,
      10.67,
      -49.569,
      1.771,
      13.665,
      -47.685,
      2.301,
      13.665,
      -47.685,
      2.301,
      13.665,
      -47.685,
      2.301,
      10.67,
      -49.569,
      1.771,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2127_M0012",
    polygon: [
      -49.394,
      -0.668,
      10.67,
      -49.394,
      -0.668,
      13.665,
      -49.912,
      1.17,
      13.665,
      -49.912,
      1.17,
      13.665,
      -49.912,
      1.17,
      10.67,
      -49.394,
      -0.668,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2127_M0013",
    polygon: [
      -48.859,
      -2.569,
      10.67,
      -48.859,
      -2.569,
      13.665,
      -49.377,
      -0.731,
      13.665,
      -49.377,
      -0.731,
      13.665,
      -49.377,
      -0.731,
      10.67,
      -48.859,
      -2.569,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2128_M0010",
    polygon: [
      -47.951,
      -5.791,
      10.67,
      -47.951,
      -5.791,
      13.665,
      -48.469,
      -3.953,
      13.665,
      -48.469,
      -3.953,
      13.665,
      -48.469,
      -3.953,
      10.67,
      -47.951,
      -5.791,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2128_M0011",
    polygon: [
      -47.416,
      -7.692,
      10.67,
      -47.416,
      -7.692,
      13.665,
      -47.933,
      -5.854,
      13.665,
      -47.933,
      -5.854,
      13.665,
      -47.933,
      -5.854,
      10.67,
      -47.416,
      -7.692,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2129_M0010",
    polygon: [
      -46.501,
      -10.941,
      10.67,
      -46.501,
      -10.941,
      13.665,
      -47.018,
      -9.102,
      13.665,
      -47.018,
      -9.102,
      13.665,
      -47.018,
      -9.102,
      10.67,
      -46.501,
      -10.941,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2129_M0011",
    polygon: [
      -45.965,
      -12.842,
      10.67,
      -45.965,
      -12.842,
      13.665,
      -46.483,
      -11.004,
      13.665,
      -46.483,
      -11.004,
      13.665,
      -46.483,
      -11.004,
      10.67,
      -45.965,
      -12.842,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "LE200xxF02O_EZR2129_M0012",
    polygon: [
      -43.482,
      -12.667,
      10.67,
      -43.482,
      -12.667,
      13.665,
      -45.366,
      -13.197,
      13.665,
      -45.366,
      -13.197,
      13.665,
      -45.366,
      -13.197,
      10.67,
      -43.482,
      -12.667,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2129_M0013",
    polygon: [
      -41.535,
      -12.118,
      10.67,
      -41.535,
      -12.118,
      13.665,
      -43.419,
      -12.649,
      13.665,
      -43.419,
      -12.649,
      13.665,
      -43.419,
      -12.649,
      10.67,
      -41.535,
      -12.118,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2130_M0010",
    polygon: [
      -38.287,
      -11.203,
      10.67,
      -38.287,
      -11.203,
      13.665,
      -40.171,
      -11.734,
      13.665,
      -40.171,
      -11.734,
      13.665,
      -40.171,
      -11.734,
      10.67,
      -38.287,
      -11.203,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2131_M0010",
    polygon: [
      -36.34,
      -10.655,
      10.67,
      -36.34,
      -10.655,
      13.665,
      -38.224,
      -11.186,
      13.665,
      -38.224,
      -11.186,
      13.665,
      -38.224,
      -11.186,
      10.67,
      -36.34,
      -10.655,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2132_M0010",
    polygon: [
      -33.089,
      -9.739,
      10.67,
      -33.089,
      -9.739,
      13.665,
      -34.973,
      -10.27,
      13.665,
      -34.973,
      -10.27,
      13.665,
      -34.973,
      -10.27,
      10.67,
      -33.089,
      -9.739,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2133_M0010",
    polygon: [
      -31.142,
      -9.191,
      10.67,
      -31.142,
      -9.191,
      13.665,
      -33.026,
      -9.722,
      13.665,
      -33.026,
      -9.722,
      13.665,
      -33.026,
      -9.722,
      10.67,
      -31.142,
      -9.191,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2134_M0010",
    polygon: [
      -27.416,
      -8.141,
      10.67,
      -27.416,
      -8.141,
      13.665,
      -29.776,
      -8.806,
      13.665,
      -29.776,
      -8.806,
      13.665,
      -29.776,
      -8.806,
      10.67,
      -27.416,
      -8.141,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2135_M0010",
    polygon: [
      -26.17,
      -10.63,
      10.67,
      -26.17,
      -10.63,
      13.665,
      -26.652,
      -8.919,
      13.665,
      -26.652,
      -8.919,
      13.665,
      -26.652,
      -8.919,
      10.67,
      -26.17,
      -10.63,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2149_M0010",
    polygon: [
      -7.55,
      -3.543,
      10.67,
      -7.55,
      -3.543,
      13.665,
      -7.068,
      -5.254,
      13.665,
      -7.068,
      -5.254,
      13.665,
      -7.068,
      -5.254,
      10.67,
      -7.55,
      -3.543,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2149_M0011",
    polygon: [
      -7.051,
      -5.317,
      10.67,
      -7.051,
      -5.317,
      13.665,
      -6.569,
      -7.028,
      13.665,
      -6.569,
      -7.028,
      13.665,
      -6.569,
      -7.028,
      10.67,
      -7.051,
      -5.317,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2149_M0012",
    polygon: [
      -6.185,
      -8.389,
      10.67,
      -6.185,
      -8.389,
      13.665,
      -5.703,
      -10.1,
      13.665,
      -5.703,
      -10.1,
      13.665,
      -5.703,
      -10.1,
      10.67,
      -6.185,
      -8.389,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2201_M0010",
    polygon: [
      2.009,
      -21.299,
      10.67,
      2.009,
      -21.299,
      13.665,
      3.894,
      -20.768,
      13.665,
      3.894,
      -20.768,
      13.665,
      3.894,
      -20.768,
      10.67,
      2.009,
      -21.299,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2202_M0010",
    polygon: [
      3.956,
      -20.75,
      10.67,
      3.956,
      -20.75,
      13.665,
      5.84,
      -20.22,
      13.665,
      5.84,
      -20.22,
      13.665,
      5.84,
      -20.22,
      10.67,
      3.956,
      -20.75,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2203_M0010",
    polygon: [
      7.207,
      -19.835,
      10.67,
      7.207,
      -19.835,
      13.665,
      9.091,
      -19.304,
      13.665,
      9.091,
      -19.304,
      13.665,
      9.091,
      -19.304,
      10.67,
      7.207,
      -19.835,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2204_M0010",
    polygon: [
      9.154,
      -19.286,
      10.67,
      9.154,
      -19.286,
      13.665,
      11.038,
      -18.756,
      13.665,
      11.038,
      -18.756,
      13.665,
      11.038,
      -18.756,
      10.67,
      9.154,
      -19.286,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2205_M0010",
    polygon: [
      12.402,
      -18.371,
      10.67,
      12.402,
      -18.371,
      13.665,
      14.287,
      -17.841,
      13.665,
      14.287,
      -17.841,
      13.665,
      14.287,
      -17.841,
      10.67,
      12.402,
      -18.371,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2205_M0011",
    polygon: [
      14.349,
      -17.823,
      10.67,
      14.349,
      -17.823,
      13.665,
      16.233,
      -17.292,
      13.665,
      16.233,
      -17.292,
      13.665,
      16.233,
      -17.292,
      10.67,
      14.349,
      -17.823,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2205_M0012",
    polygon: [
      16.838,
      -17.649,
      10.67,
      16.838,
      -17.649,
      13.665,
      17.356,
      -19.488,
      13.665,
      17.356,
      -19.488,
      13.665,
      17.356,
      -19.488,
      10.67,
      16.838,
      -17.649,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2205_M0013",
    polygon: [
      17.373,
      -19.55,
      10.67,
      17.373,
      -19.55,
      13.665,
      17.891,
      -21.389,
      13.665,
      17.891,
      -21.389,
      13.665,
      17.891,
      -21.389,
      10.67,
      17.373,
      -19.55,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2206_M0010",
    polygon: [
      18.288,
      -22.799,
      10.67,
      18.288,
      -22.799,
      13.665,
      18.806,
      -24.637,
      13.665,
      18.806,
      -24.637,
      13.665,
      18.806,
      -24.637,
      10.67,
      18.288,
      -22.799,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2206_M0011",
    polygon: [
      18.824,
      -24.7,
      10.67,
      18.824,
      -24.7,
      13.665,
      19.342,
      -26.538,
      13.665,
      19.342,
      -26.538,
      13.665,
      19.342,
      -26.538,
      10.67,
      18.824,
      -24.7,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2207_M0010",
    polygon: [
      19.731,
      -27.922,
      10.67,
      19.731,
      -27.922,
      13.665,
      20.249,
      -29.76,
      13.665,
      20.249,
      -29.76,
      13.665,
      20.249,
      -29.76,
      10.67,
      19.731,
      -27.922,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2207_M0011",
    polygon: [
      20.267,
      -29.823,
      10.67,
      20.267,
      -29.823,
      13.665,
      20.785,
      -31.661,
      13.665,
      20.785,
      -31.661,
      13.665,
      20.785,
      -31.661,
      10.67,
      20.267,
      -29.823,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2207_M0012",
    polygon: [
      20.458,
      -32.257,
      10.67,
      20.458,
      -32.257,
      13.665,
      18.571,
      -32.789,
      13.665,
      18.571,
      -32.789,
      13.665,
      18.571,
      -32.789,
      10.67,
      20.458,
      -32.257,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2207_M0013",
    polygon: [
      18.508,
      -32.806,
      10.67,
      18.508,
      -32.806,
      13.665,
      16.622,
      -33.338,
      13.665,
      16.622,
      -33.338,
      13.665,
      16.622,
      -33.338,
      10.67,
      18.508,
      -32.806,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2208_M0010",
    polygon: [
      15.26,
      -33.722,
      10.67,
      15.26,
      -33.722,
      13.665,
      13.373,
      -34.253,
      13.665,
      13.373,
      -34.253,
      13.665,
      13.373,
      -34.253,
      10.67,
      15.26,
      -33.722,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2209_M0010",
    polygon: [
      13.311,
      -34.271,
      10.67,
      13.311,
      -34.271,
      13.665,
      11.424,
      -34.802,
      13.665,
      11.424,
      -34.802,
      13.665,
      11.424,
      -34.802,
      10.67,
      13.311,
      -34.271,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "LE200xxF02O_EZR2210_M0010",
    polygon: [
      10.062,
      -35.186,
      10.67,
      10.062,
      -35.186,
      13.665,
      8.176,
      -35.717,
      13.665,
      8.176,
      -35.717,
      13.665,
      8.176,
      -35.717,
      10.67,
      10.062,
      -35.186,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
  },
  {
    ID: "LE200xxF02O_EZR2211_M0010",
    polygon: [
      8.113,
      -35.735,
      10.67,
      8.113,
      -35.735,
      13.665,
      6.226,
      -36.266,
      13.665,
      6.226,
      -36.266,
      13.665,
      6.226,
      -36.266,
      10.67,
      8.113,
      -35.735,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2212_M0010",
    polygon: [
      4.864,
      -36.65,
      10.67,
      4.864,
      -36.65,
      13.665,
      2.978,
      -37.181,
      13.665,
      2.978,
      -37.181,
      13.665,
      2.978,
      -37.181,
      10.67,
      4.864,
      -36.65,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "LE200xxF02O_EZR2213_M0010",
    polygon: [
      2.915,
      -37.199,
      10.67,
      2.915,
      -37.199,
      13.665,
      1.029,
      -37.73,
      13.665,
      1.029,
      -37.73,
      13.665,
      1.029,
      -37.73,
      10.67,
      2.915,
      -37.199,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2214_M0010",
    polygon: [
      -0.333,
      -38.114,
      10.675,
      -0.333,
      -38.114,
      13.67,
      -2.694,
      -38.779,
      13.67,
      -2.694,
      -38.779,
      13.67,
      -2.694,
      -38.779,
      10.675,
      -0.333,
      -38.114,
      10.675,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2215_M0010",
    polygon: [
      -12.396,
      -41.512,
      10.675,
      -12.396,
      -41.512,
      13.67,
      -14.757,
      -42.177,
      13.67,
      -14.757,
      -42.177,
      13.67,
      -14.757,
      -42.177,
      10.675,
      -12.396,
      -41.512,
      10.675,
    ],
    "04-15 02:05": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2216_M0010",
    polygon: [
      -16.119,
      -42.56,
      10.67,
      -16.119,
      -42.56,
      13.665,
      -18.006,
      -43.092,
      13.665,
      -18.006,
      -43.092,
      13.665,
      -18.006,
      -43.092,
      10.67,
      -16.119,
      -42.56,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2217_M0010",
    polygon: [
      -18.068,
      -43.109,
      10.67,
      -18.068,
      -43.109,
      13.665,
      -19.955,
      -43.641,
      13.665,
      -19.955,
      -43.641,
      13.665,
      -19.955,
      -43.641,
      10.67,
      -18.068,
      -43.109,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2218_M0010",
    polygon: [
      -21.317,
      -44.025,
      10.67,
      -21.317,
      -44.025,
      13.665,
      -23.203,
      -44.556,
      13.665,
      -23.203,
      -44.556,
      13.665,
      -23.203,
      -44.556,
      10.67,
      -21.317,
      -44.025,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2219_M0010",
    polygon: [
      -23.266,
      -44.574,
      10.67,
      -23.266,
      -44.574,
      13.665,
      -25.152,
      -45.105,
      13.665,
      -25.152,
      -45.105,
      13.665,
      -25.152,
      -45.105,
      10.67,
      -23.266,
      -44.574,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2220_M0010",
    polygon: [
      -26.514,
      -45.489,
      10.67,
      -26.514,
      -45.489,
      13.665,
      -28.401,
      -46.02,
      13.665,
      -28.401,
      -46.02,
      13.665,
      -28.401,
      -46.02,
      10.67,
      -26.514,
      -45.489,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2221_M0010",
    polygon: [
      -28.464,
      -46.038,
      10.67,
      -28.464,
      -46.038,
      13.665,
      -30.35,
      -46.569,
      13.665,
      -30.35,
      -46.569,
      13.665,
      -30.35,
      -46.569,
      10.67,
      -28.464,
      -46.038,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2222_M0010",
    polygon: [
      -31.712,
      -46.953,
      10.67,
      -31.712,
      -46.953,
      13.665,
      -33.599,
      -47.484,
      13.665,
      -33.599,
      -47.484,
      13.665,
      -33.599,
      -47.484,
      10.67,
      -31.712,
      -46.953,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2222_M0011",
    polygon: [
      -33.661,
      -47.502,
      10.67,
      -33.661,
      -47.502,
      13.665,
      -35.548,
      -48.033,
      13.665,
      -35.548,
      -48.033,
      13.665,
      -35.548,
      -48.033,
      10.67,
      -33.661,
      -47.502,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2222_M0012",
    polygon: [
      -36.146,
      -47.674,
      10.67,
      -36.146,
      -47.674,
      13.665,
      -36.664,
      -45.836,
      13.665,
      -36.664,
      -45.836,
      13.665,
      -36.664,
      -45.836,
      10.67,
      -36.146,
      -47.674,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "LE200xxF02O_EZR2222_M0013",
    polygon: [
      -36.682,
      -45.773,
      10.67,
      -36.682,
      -45.773,
      13.665,
      -37.199,
      -43.935,
      13.665,
      -37.199,
      -43.935,
      13.665,
      -37.199,
      -43.935,
      10.67,
      -36.682,
      -45.773,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "LE200xxF02O_EZR2223_M0010",
    polygon: [
      -37.608,
      -42.528,
      10.67,
      -37.608,
      -42.528,
      13.665,
      -38.126,
      -40.69,
      13.665,
      -38.126,
      -40.69,
      13.665,
      -38.126,
      -40.69,
      10.67,
      -37.608,
      -42.528,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "LE200xxF02O_EZR2223_M0011",
    polygon: [
      -38.144,
      -40.627,
      10.67,
      -38.144,
      -40.627,
      13.665,
      -38.662,
      -38.789,
      13.665,
      -38.662,
      -38.789,
      13.665,
      -38.662,
      -38.789,
      10.67,
      -38.144,
      -40.627,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2224_M0010",
    polygon: [
      -39.047,
      -37.404,
      10.67,
      -39.047,
      -37.404,
      13.665,
      -39.564,
      -35.565,
      13.665,
      -39.564,
      -35.565,
      13.665,
      -39.564,
      -35.565,
      10.67,
      -39.047,
      -37.404,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "LE200xxF02O_EZR2224_M0011",
    polygon: [
      -39.582,
      -35.503,
      10.67,
      -39.582,
      -35.503,
      13.665,
      -40.1,
      -33.664,
      13.665,
      -40.1,
      -33.664,
      13.665,
      -40.1,
      -33.664,
      10.67,
      -39.582,
      -35.503,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "LE200xxF02O_EZR2224_M0012",
    polygon: [
      -39.767,
      -33.067,
      10.67,
      -39.767,
      -33.067,
      13.665,
      -37.883,
      -32.536,
      13.665,
      -37.883,
      -32.536,
      13.665,
      -37.883,
      -32.536,
      10.67,
      -39.767,
      -33.067,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2224_M0013",
    polygon: [
      -37.821,
      -32.518,
      10.67,
      -37.821,
      -32.518,
      13.665,
      -35.936,
      -31.988,
      13.665,
      -35.936,
      -31.988,
      13.665,
      -35.936,
      -31.988,
      10.67,
      -37.821,
      -32.518,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE200xxF02O_EZR2225_M0010",
    polygon: [
      -34.57,
      -31.603,
      10.67,
      -34.57,
      -31.603,
      13.665,
      -32.685,
      -31.072,
      13.665,
      -32.685,
      -31.072,
      13.665,
      -32.685,
      -31.072,
      10.67,
      -34.57,
      -31.603,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE200xxF02O_EZR2226_M0010",
    polygon: [
      -32.623,
      -31.054,
      10.67,
      -32.623,
      -31.054,
      13.665,
      -30.739,
      -30.523,
      13.665,
      -30.739,
      -30.523,
      13.665,
      -30.739,
      -30.523,
      10.67,
      -32.623,
      -31.054,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "LE200xxF02O_EZR2227_M0010",
    polygon: [
      -29.374,
      -30.139,
      10.67,
      -29.374,
      -30.139,
      13.665,
      -27.49,
      -29.608,
      13.665,
      -27.49,
      -29.608,
      13.665,
      -27.49,
      -29.608,
      10.67,
      -29.374,
      -30.139,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2228_M0010",
    polygon: [
      -27.428,
      -29.591,
      10.67,
      -27.428,
      -29.591,
      13.665,
      -25.543,
      -29.06,
      13.665,
      -25.543,
      -29.06,
      13.665,
      -25.543,
      -29.06,
      10.67,
      -27.428,
      -29.591,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2229_M0010",
    polygon: [
      -24.177,
      -28.675,
      10.67,
      -24.177,
      -28.675,
      13.665,
      -21.816,
      -28.01,
      13.665,
      -21.816,
      -28.01,
      13.665,
      -21.816,
      -28.01,
      10.67,
      -24.177,
      -28.675,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2230_M0010",
    polygon: [
      -21.574,
      -26.947,
      10.67,
      -21.574,
      -26.947,
      13.665,
      -22.056,
      -25.236,
      13.665,
      -22.056,
      -25.236,
      13.665,
      -22.056,
      -25.236,
      10.67,
      -21.574,
      -26.947,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2231_M0010",
    polygon: [
      -22.074,
      -25.174,
      10.67,
      -22.074,
      -25.174,
      13.665,
      -22.556,
      -23.463,
      13.665,
      -22.556,
      -23.463,
      13.665,
      -22.556,
      -23.463,
      10.67,
      -22.074,
      -25.174,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2231_M0011",
    polygon: [
      -22.939,
      -22.101,
      10.67,
      -22.939,
      -22.101,
      13.665,
      -23.421,
      -20.39,
      13.665,
      -23.421,
      -20.39,
      13.665,
      -23.421,
      -20.39,
      10.67,
      -22.939,
      -22.101,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "LE200xxF02O_EZR2232_M0010",
    polygon: [
      -23.439,
      -20.327,
      10.67,
      -23.439,
      -20.327,
      13.665,
      -23.921,
      -18.616,
      13.665,
      -23.921,
      -18.616,
      13.665,
      -23.921,
      -18.616,
      10.67,
      -23.439,
      -20.327,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE200xxF02O_EZR2232_M0011",
    polygon: [
      -24.306,
      -17.25,
      10.67,
      -24.306,
      -17.25,
      13.665,
      -24.788,
      -15.539,
      13.665,
      -24.788,
      -15.539,
      13.665,
      -24.788,
      -15.539,
      10.67,
      -24.306,
      -17.25,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "LE200xxF02O_EZR2233_M0010",
    polygon: [
      -25.671,
      -12.403,
      10.67,
      -25.671,
      -12.403,
      13.665,
      -26.153,
      -10.692,
      13.665,
      -26.153,
      -10.692,
      13.665,
      -26.153,
      -10.692,
      10.67,
      -25.671,
      -12.403,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "LE200xxF02O_EZR2233_M0011",
    polygon: [
      -24.805,
      -15.476,
      10.67,
      -24.805,
      -15.476,
      13.665,
      -25.287,
      -13.765,
      13.665,
      -25.287,
      -13.765,
      13.665,
      -25.287,
      -13.765,
      10.67,
      -24.805,
      -15.476,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2234_M0010",
    polygon: [
      -5.686,
      -10.163,
      10.67,
      -5.686,
      -10.163,
      13.665,
      -5.204,
      -11.874,
      13.665,
      -5.204,
      -11.874,
      13.665,
      -5.204,
      -11.874,
      10.67,
      -5.686,
      -10.163,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "LE200xxF02O_EZR2235_M0010",
    polygon: [
      -4.819,
      -13.241,
      10.67,
      -4.819,
      -13.241,
      13.665,
      -4.337,
      -14.952,
      13.665,
      -4.337,
      -14.952,
      13.665,
      -4.337,
      -14.952,
      10.67,
      -4.819,
      -13.241,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
  },
  {
    ID: "LE200xxF02O_EZR2236_M0010",
    polygon: [
      -4.319,
      -15.014,
      10.67,
      -4.319,
      -15.014,
      13.665,
      -3.837,
      -16.725,
      13.665,
      -3.837,
      -16.725,
      13.665,
      -3.837,
      -16.725,
      10.67,
      -4.319,
      -15.014,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2237_M0010",
    polygon: [
      -2.954,
      -19.861,
      10.67,
      -2.954,
      -19.861,
      13.665,
      -2.472,
      -21.572,
      13.665,
      -2.472,
      -21.572,
      13.665,
      -2.472,
      -21.572,
      10.67,
      -2.954,
      -19.861,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "LE200xxF02O_EZR2237_M0011",
    polygon: [
      -3.454,
      -18.087,
      10.67,
      -3.454,
      -18.087,
      13.665,
      -2.972,
      -19.798,
      13.665,
      -2.972,
      -19.798,
      13.665,
      -2.972,
      -19.798,
      10.67,
      -3.454,
      -18.087,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
  },
  {
    ID: "LE200xxF02O_EZR2251_M0010",
    polygon: [
      -1.718,
      -22.349,
      10.67,
      -1.718,
      -22.349,
      13.665,
      0.643,
      -21.684,
      13.665,
      0.643,
      -21.684,
      13.665,
      0.643,
      -21.684,
      10.67,
      -1.718,
      -22.349,
      10.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2301_M0010",
    polygon: [
      -18.951,
      -59.669,
      10.665,
      -18.951,
      -59.669,
      13.66,
      -17.067,
      -59.138,
      13.66,
      -17.067,
      -59.138,
      13.66,
      -17.067,
      -59.138,
      10.665,
      -18.951,
      -59.669,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2302_M0010",
    polygon: [
      -20.898,
      -60.217,
      10.665,
      -20.898,
      -60.217,
      13.66,
      -19.013,
      -59.687,
      13.66,
      -19.013,
      -59.687,
      13.66,
      -19.013,
      -59.687,
      10.665,
      -20.898,
      -60.217,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "LE200xxF02O_EZR2303_M0010",
    polygon: [
      -24.149,
      -61.133,
      10.665,
      -24.149,
      -61.133,
      13.66,
      -22.264,
      -60.602,
      13.66,
      -22.264,
      -60.602,
      13.66,
      -22.264,
      -60.602,
      10.665,
      -24.149,
      -61.133,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "LE200xxF02O_EZR2304_M0010",
    polygon: [
      -26.095,
      -61.682,
      10.665,
      -26.095,
      -61.682,
      13.66,
      -24.211,
      -61.151,
      13.66,
      -24.211,
      -61.151,
      13.66,
      -24.211,
      -61.151,
      10.665,
      -26.095,
      -61.682,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE200xxF02O_EZR2305_M0010",
    polygon: [
      -30.567,
      -67.481,
      10.67,
      -30.567,
      -67.481,
      13.665,
      -31.085,
      -65.643,
      13.665,
      -31.085,
      -65.643,
      13.665,
      -31.085,
      -65.643,
      10.67,
      -30.567,
      -67.481,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2305_M0011",
    polygon: [
      -31.102,
      -65.58,
      10.67,
      -31.102,
      -65.58,
      13.665,
      -31.62,
      -63.742,
      13.665,
      -31.62,
      -63.742,
      13.665,
      -31.62,
      -63.742,
      10.67,
      -31.102,
      -65.58,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2305_M0012",
    polygon: [
      -31.293,
      -63.146,
      10.665,
      -31.293,
      -63.146,
      13.66,
      -29.409,
      -62.615,
      13.66,
      -29.409,
      -62.615,
      13.66,
      -29.409,
      -62.615,
      10.665,
      -31.293,
      -63.146,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2305_M0013",
    polygon: [
      -29.346,
      -62.597,
      10.665,
      -29.346,
      -62.597,
      13.66,
      -27.462,
      -62.067,
      13.66,
      -27.462,
      -62.067,
      13.66,
      -27.462,
      -62.067,
      10.665,
      -29.346,
      -62.597,
      10.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
  },
  {
    ID: "LE200xxF02O_EZR2306_M0010",
    polygon: [
      -29.135,
      -72.608,
      10.67,
      -29.135,
      -72.608,
      13.665,
      -29.653,
      -70.769,
      13.665,
      -29.653,
      -70.769,
      13.665,
      -29.653,
      -70.769,
      10.67,
      -29.135,
      -72.608,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "LE200xxF02O_EZR2306_M0011",
    polygon: [
      -29.671,
      -70.707,
      10.67,
      -29.671,
      -70.707,
      13.665,
      -30.189,
      -68.868,
      13.665,
      -30.189,
      -68.868,
      13.665,
      -30.189,
      -68.868,
      10.67,
      -29.671,
      -70.707,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2307_M0010",
    polygon: [
      -23.243,
      -77.027,
      10.67,
      -23.243,
      -77.027,
      13.665,
      -25.129,
      -77.559,
      13.665,
      -25.129,
      -77.559,
      13.665,
      -25.129,
      -77.559,
      10.67,
      -23.243,
      -77.027,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2307_M0011",
    polygon: [
      -25.189,
      -77.576,
      10.67,
      -25.189,
      -77.576,
      13.665,
      -27.076,
      -78.107,
      13.665,
      -27.076,
      -78.107,
      13.665,
      -27.076,
      -78.107,
      10.67,
      -25.189,
      -77.576,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF02O_EZR2307_M0012",
    polygon: [
      -27.68,
      -77.756,
      10.67,
      -27.68,
      -77.756,
      13.665,
      -28.198,
      -75.917,
      13.665,
      -28.198,
      -75.917,
      13.665,
      -28.198,
      -75.917,
      10.67,
      -27.68,
      -77.756,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "LE200xxF02O_EZR2307_M0013",
    polygon: [
      -28.215,
      -75.855,
      10.67,
      -28.215,
      -75.855,
      13.665,
      -28.733,
      -74.016,
      13.665,
      -28.733,
      -74.016,
      13.665,
      -28.733,
      -74.016,
      10.67,
      -28.215,
      -75.855,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "LE200xxF02O_EZR2308_M0010",
    polygon: [
      -19.991,
      -76.112,
      10.67,
      -19.991,
      -76.112,
      13.665,
      -21.878,
      -76.643,
      13.665,
      -21.878,
      -76.643,
      13.665,
      -21.878,
      -76.643,
      10.67,
      -19.991,
      -76.112,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2309_M0010",
    polygon: [
      -18.044,
      -75.563,
      10.67,
      -18.044,
      -75.563,
      13.665,
      -19.931,
      -76.095,
      13.665,
      -19.931,
      -76.095,
      13.665,
      -19.931,
      -76.095,
      10.67,
      -18.044,
      -75.563,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2310_M0010",
    polygon: [
      -14.794,
      -74.648,
      10.67,
      -14.794,
      -74.648,
      13.665,
      -16.68,
      -75.179,
      13.665,
      -16.68,
      -75.179,
      13.665,
      -16.68,
      -75.179,
      10.67,
      -14.794,
      -74.648,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2311_M0010",
    polygon: [
      -12.847,
      -74.099,
      10.67,
      -12.847,
      -74.099,
      13.665,
      -14.734,
      -74.631,
      13.665,
      -14.734,
      -74.631,
      13.665,
      -14.734,
      -74.631,
      10.67,
      -12.847,
      -74.099,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2312_M0010",
    polygon: [
      -9.596,
      -73.183,
      10.67,
      -9.596,
      -73.183,
      13.665,
      -11.482,
      -73.715,
      13.665,
      -11.482,
      -73.715,
      13.665,
      -11.482,
      -73.715,
      10.67,
      -9.596,
      -73.183,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2313_M0010",
    polygon: [
      -7.649,
      -72.635,
      10.67,
      -7.649,
      -72.635,
      13.665,
      -9.535,
      -73.166,
      13.665,
      -9.535,
      -73.166,
      13.665,
      -9.535,
      -73.166,
      10.67,
      -7.649,
      -72.635,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2314_M0010",
    polygon: [
      -4.398,
      -71.719,
      10.67,
      -4.398,
      -71.719,
      13.665,
      -6.284,
      -72.251,
      13.665,
      -6.284,
      -72.251,
      13.665,
      -6.284,
      -72.251,
      10.67,
      -4.398,
      -71.719,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2315_M0010",
    polygon: [
      -2.451,
      -71.171,
      10.67,
      -2.451,
      -71.171,
      13.665,
      -4.338,
      -71.702,
      13.665,
      -4.338,
      -71.702,
      13.665,
      -4.338,
      -71.702,
      10.67,
      -2.451,
      -71.171,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2316_M0010",
    polygon: [
      0.799,
      -70.255,
      10.67,
      0.799,
      -70.255,
      13.665,
      -1.087,
      -70.787,
      13.665,
      -1.087,
      -70.787,
      13.665,
      -1.087,
      -70.787,
      10.67,
      0.799,
      -70.255,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2317_M0010",
    polygon: [
      2.746,
      -69.707,
      10.67,
      2.746,
      -69.707,
      13.665,
      0.86,
      -70.238,
      13.665,
      0.86,
      -70.238,
      13.665,
      0.86,
      -70.238,
      10.67,
      2.746,
      -69.707,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2318_M0010",
    polygon: [
      5.998,
      -68.791,
      10.67,
      5.998,
      -68.791,
      13.665,
      4.111,
      -69.322,
      13.665,
      4.111,
      -69.322,
      13.665,
      4.111,
      -69.322,
      10.67,
      5.998,
      -68.791,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2319_M0010",
    polygon: [
      7.944,
      -68.243,
      10.67,
      7.944,
      -68.243,
      13.665,
      6.058,
      -68.774,
      13.665,
      6.058,
      -68.774,
      13.665,
      6.058,
      -68.774,
      10.67,
      7.944,
      -68.243,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2320_M0010",
    polygon: [
      11.197,
      -67.332,
      10.67,
      11.197,
      -67.332,
      13.665,
      9.31,
      -67.864,
      13.665,
      9.31,
      -67.864,
      13.665,
      9.31,
      -67.864,
      10.67,
      11.197,
      -67.332,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF02O_EZR2320_M0011",
    polygon: [
      13.143,
      -66.784,
      10.67,
      13.143,
      -66.784,
      13.665,
      11.257,
      -67.315,
      13.665,
      11.257,
      -67.315,
      13.665,
      11.257,
      -67.315,
      10.67,
      13.143,
      -66.784,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF02O_EZR2320_M0012",
    polygon: [
      12.957,
      -64.348,
      10.67,
      12.957,
      -64.348,
      13.665,
      13.475,
      -66.187,
      13.665,
      13.475,
      -66.187,
      13.665,
      13.475,
      -66.187,
      10.67,
      12.957,
      -64.348,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2320_M0013",
    polygon: [
      12.421,
      -62.447,
      10.67,
      12.421,
      -62.447,
      13.665,
      12.939,
      -64.286,
      13.665,
      12.939,
      -64.286,
      13.665,
      12.939,
      -64.286,
      10.67,
      12.421,
      -62.447,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF02O_EZR2321_M0010",
    polygon: [
      11.514,
      -59.225,
      10.67,
      11.514,
      -59.225,
      13.665,
      12.032,
      -61.063,
      13.665,
      12.032,
      -61.063,
      13.665,
      12.032,
      -61.063,
      10.67,
      11.514,
      -59.225,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF02O_EZR2321_M0011",
    polygon: [
      10.978,
      -57.324,
      10.67,
      10.978,
      -57.324,
      13.665,
      11.496,
      -59.162,
      13.665,
      11.496,
      -59.162,
      13.665,
      11.496,
      -59.162,
      10.67,
      10.978,
      -57.324,
      10.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3101_M0010",
    polygon: [
      -4.957,
      -1.815,
      14.17,
      -4.957,
      -1.815,
      17.165,
      -7.318,
      -2.48,
      17.165,
      -7.318,
      -2.48,
      17.165,
      -7.318,
      -2.48,
      14.17,
      -4.957,
      -1.815,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3102_M0010",
    polygon: [
      -1.71,
      -0.9,
      14.17,
      -1.71,
      -0.9,
      17.165,
      -3.594,
      -1.431,
      17.165,
      -3.594,
      -1.431,
      17.165,
      -3.594,
      -1.431,
      14.17,
      -1.71,
      -0.9,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
  },
  {
    ID: "LE200xxF03O_EZR3103_M0010",
    polygon: [
      0.237,
      -0.352,
      14.17,
      0.237,
      -0.352,
      17.165,
      -1.648,
      -0.883,
      17.165,
      -1.648,
      -0.883,
      17.165,
      -1.648,
      -0.883,
      14.17,
      0.237,
      -0.352,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3104_M0010",
    polygon: [
      3.485,
      0.563,
      14.17,
      3.485,
      0.563,
      17.165,
      1.601,
      0.032,
      17.165,
      1.601,
      0.032,
      17.165,
      1.601,
      0.032,
      14.17,
      3.485,
      0.563,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3105_M0010",
    polygon: [
      5.432,
      1.112,
      14.17,
      5.432,
      1.112,
      17.165,
      3.548,
      0.581,
      17.165,
      3.548,
      0.581,
      17.165,
      3.548,
      0.581,
      14.17,
      5.432,
      1.112,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
  },
  {
    ID: "LE200xxF03O_EZR3106_M0010",
    polygon: [
      8.683,
      2.027,
      14.17,
      8.683,
      2.027,
      17.165,
      6.799,
      1.497,
      17.165,
      6.799,
      1.497,
      17.165,
      6.799,
      1.497,
      14.17,
      8.683,
      2.027,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3106_M0011",
    polygon: [
      10.63,
      2.576,
      14.17,
      10.63,
      2.576,
      17.165,
      8.745,
      2.045,
      17.165,
      8.745,
      2.045,
      17.165,
      8.745,
      2.045,
      14.17,
      10.63,
      2.576,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3106_M0012",
    polygon: [
      10.458,
      5.012,
      14.17,
      10.458,
      5.012,
      17.165,
      10.975,
      3.174,
      17.165,
      10.975,
      3.174,
      17.165,
      10.975,
      3.174,
      14.17,
      10.458,
      5.012,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3106_M0013",
    polygon: [
      9.922,
      6.913,
      14.17,
      9.922,
      6.913,
      17.165,
      10.44,
      5.075,
      17.165,
      10.44,
      5.075,
      17.165,
      10.44,
      5.075,
      14.17,
      9.922,
      6.913,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3107_M0010",
    polygon: [
      9.014,
      10.135,
      14.17,
      9.014,
      10.135,
      17.165,
      9.532,
      8.297,
      17.165,
      9.532,
      8.297,
      17.165,
      9.532,
      8.297,
      14.17,
      9.014,
      10.135,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3107_M0011",
    polygon: [
      8.479,
      12.036,
      14.17,
      8.479,
      12.036,
      17.165,
      8.997,
      10.198,
      17.165,
      8.997,
      10.198,
      17.165,
      8.997,
      10.198,
      14.17,
      8.479,
      12.036,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3108_M0010",
    polygon: [
      7.564,
      15.285,
      14.17,
      7.564,
      15.285,
      17.165,
      8.082,
      13.446,
      17.165,
      8.082,
      13.446,
      17.165,
      8.082,
      13.446,
      14.17,
      7.564,
      15.285,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3108_M0011",
    polygon: [
      7.028,
      17.186,
      14.17,
      7.028,
      17.186,
      17.165,
      7.546,
      15.348,
      17.165,
      7.546,
      15.348,
      17.165,
      7.546,
      15.348,
      14.17,
      7.028,
      17.186,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3108_M0012",
    polygon: [
      4.543,
      17.013,
      14.17,
      4.543,
      17.013,
      17.165,
      6.427,
      17.544,
      17.165,
      6.427,
      17.544,
      17.165,
      6.427,
      17.544,
      14.17,
      4.543,
      17.013,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3108_M0013",
    polygon: [
      2.596,
      16.465,
      14.17,
      2.596,
      16.465,
      17.165,
      4.48,
      16.995,
      17.165,
      4.48,
      16.995,
      17.165,
      4.48,
      16.995,
      14.17,
      2.596,
      16.465,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3109_M0010",
    polygon: [
      -0.653,
      15.55,
      14.17,
      -0.653,
      15.55,
      17.165,
      1.232,
      16.08,
      17.165,
      1.232,
      16.08,
      17.165,
      1.232,
      16.08,
      14.17,
      -0.653,
      15.55,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3110_M0010",
    polygon: [
      -2.599,
      15.001,
      14.17,
      -2.599,
      15.001,
      17.165,
      -0.715,
      15.532,
      17.165,
      -0.715,
      15.532,
      17.165,
      -0.715,
      15.532,
      14.17,
      -2.599,
      15.001,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3111_M0010",
    polygon: [
      -5.85,
      14.086,
      14.17,
      -5.85,
      14.086,
      17.165,
      -3.966,
      14.616,
      17.165,
      -3.966,
      14.616,
      17.165,
      -3.966,
      14.616,
      14.17,
      -5.85,
      14.086,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3112_M0010",
    polygon: [
      -7.797,
      13.537,
      14.17,
      -7.797,
      13.537,
      17.165,
      -5.913,
      14.068,
      17.165,
      -5.913,
      14.068,
      17.165,
      -5.913,
      14.068,
      14.17,
      -7.797,
      13.537,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3113_M0010",
    polygon: [
      -11.046,
      12.622,
      14.17,
      -11.046,
      12.622,
      17.165,
      -9.161,
      13.153,
      17.165,
      -9.161,
      13.153,
      17.165,
      -9.161,
      13.153,
      14.17,
      -11.046,
      12.622,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3114_M0010",
    polygon: [
      -12.992,
      12.074,
      14.17,
      -12.992,
      12.074,
      17.165,
      -11.108,
      12.604,
      17.165,
      -11.108,
      12.604,
      17.165,
      -11.108,
      12.604,
      14.17,
      -12.992,
      12.074,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3115_M0010",
    polygon: [
      -16.243,
      11.158,
      14.17,
      -16.243,
      11.158,
      17.165,
      -14.359,
      11.689,
      17.165,
      -14.359,
      11.689,
      17.165,
      -14.359,
      11.689,
      14.17,
      -16.243,
      11.158,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3116_M0010",
    polygon: [
      -18.19,
      10.61,
      14.17,
      -18.19,
      10.61,
      17.165,
      -16.306,
      11.14,
      17.165,
      -16.306,
      11.14,
      17.165,
      -16.306,
      11.14,
      14.17,
      -18.19,
      10.61,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3116_M0011",
    polygon: [
      -18.262,
      9.047,
      14.17,
      -18.262,
      9.047,
      17.165,
      -18.533,
      10.009,
      17.165,
      -18.533,
      10.009,
      17.165,
      -18.533,
      10.009,
      14.17,
      -18.262,
      9.047,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3117_M0010",
    polygon: [
      -21.0,
      7.74,
      14.17,
      -21.0,
      7.74,
      17.165,
      -19.036,
      8.293,
      17.165,
      -19.036,
      8.293,
      17.165,
      -19.036,
      8.293,
      14.17,
      -21.0,
      7.74,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3118_M0010",
    polygon: [
      -23.026,
      7.17,
      14.17,
      -23.026,
      7.17,
      17.165,
      -21.062,
      7.723,
      17.165,
      -21.062,
      7.723,
      17.165,
      -21.062,
      7.723,
      14.17,
      -23.026,
      7.17,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3119_M0010",
    polygon: [
      -26.836,
      8.174,
      14.17,
      -26.836,
      8.174,
      17.165,
      -24.952,
      8.705,
      17.165,
      -24.952,
      8.705,
      17.165,
      -24.952,
      8.705,
      14.17,
      -26.836,
      8.174,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3119_M0011",
    polygon: [
      -24.357,
      8.369,
      14.17,
      -24.357,
      8.369,
      17.165,
      -24.085,
      7.406,
      17.165,
      -24.085,
      7.406,
      17.165,
      -24.085,
      7.406,
      14.17,
      -24.357,
      8.369,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3120_M0010",
    polygon: [
      -28.783,
      7.626,
      14.17,
      -28.783,
      7.626,
      17.165,
      -26.899,
      8.157,
      17.165,
      -26.899,
      8.157,
      17.165,
      -26.899,
      8.157,
      14.17,
      -28.783,
      7.626,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3121_M0010",
    polygon: [
      -32.031,
      6.711,
      14.17,
      -32.031,
      6.711,
      17.165,
      -30.147,
      7.242,
      17.165,
      -30.147,
      7.242,
      17.165,
      -30.147,
      7.242,
      14.17,
      -32.031,
      6.711,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "LE200xxF03O_EZR3122_M0010",
    polygon: [
      -33.978,
      6.162,
      14.17,
      -33.978,
      6.162,
      17.165,
      -32.094,
      6.693,
      17.165,
      -32.094,
      6.693,
      17.165,
      -32.094,
      6.693,
      14.17,
      -33.978,
      6.162,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "LE200xxF03O_EZR3123_M0010",
    polygon: [
      -37.229,
      5.247,
      14.17,
      -37.229,
      5.247,
      17.165,
      -35.345,
      5.777,
      17.165,
      -35.345,
      5.777,
      17.165,
      -35.345,
      5.777,
      14.17,
      -37.229,
      5.247,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3124_M0010",
    polygon: [
      -39.176,
      4.698,
      14.17,
      -39.176,
      4.698,
      17.165,
      -37.292,
      5.229,
      17.165,
      -37.292,
      5.229,
      17.165,
      -37.292,
      5.229,
      14.17,
      -39.176,
      4.698,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3125_M0010",
    polygon: [
      -42.424,
      3.783,
      14.17,
      -42.424,
      3.783,
      17.165,
      -40.54,
      4.314,
      17.165,
      -40.54,
      4.314,
      17.165,
      -40.54,
      4.314,
      14.17,
      -42.424,
      3.783,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3126_M0010",
    polygon: [
      -44.371,
      3.235,
      14.17,
      -44.371,
      3.235,
      17.165,
      -42.487,
      3.766,
      17.165,
      -42.487,
      3.766,
      17.165,
      -42.487,
      3.766,
      14.17,
      -44.371,
      3.235,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3127_M0010",
    polygon: [
      -47.622,
      2.319,
      14.17,
      -47.622,
      2.319,
      17.165,
      -45.738,
      2.85,
      17.165,
      -45.738,
      2.85,
      17.165,
      -45.738,
      2.85,
      14.17,
      -47.622,
      2.319,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3127_M0011",
    polygon: [
      -49.569,
      1.771,
      14.17,
      -49.569,
      1.771,
      17.165,
      -47.685,
      2.301,
      17.165,
      -47.685,
      2.301,
      17.165,
      -47.685,
      2.301,
      14.17,
      -49.569,
      1.771,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3127_M0012",
    polygon: [
      -49.394,
      -0.668,
      14.17,
      -49.394,
      -0.668,
      17.165,
      -49.912,
      1.17,
      17.165,
      -49.912,
      1.17,
      17.165,
      -49.912,
      1.17,
      14.17,
      -49.394,
      -0.668,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3127_M0013",
    polygon: [
      -48.859,
      -2.569,
      14.17,
      -48.859,
      -2.569,
      17.165,
      -49.377,
      -0.731,
      17.165,
      -49.377,
      -0.731,
      17.165,
      -49.377,
      -0.731,
      14.17,
      -48.859,
      -2.569,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3128_M0010",
    polygon: [
      -47.951,
      -5.791,
      14.17,
      -47.951,
      -5.791,
      17.165,
      -48.469,
      -3.953,
      17.165,
      -48.469,
      -3.953,
      17.165,
      -48.469,
      -3.953,
      14.17,
      -47.951,
      -5.791,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3128_M0011",
    polygon: [
      -47.416,
      -7.692,
      14.17,
      -47.416,
      -7.692,
      17.165,
      -47.933,
      -5.854,
      17.165,
      -47.933,
      -5.854,
      17.165,
      -47.933,
      -5.854,
      14.17,
      -47.416,
      -7.692,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 16:05": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3129_M0010",
    polygon: [
      -46.501,
      -10.941,
      14.17,
      -46.501,
      -10.941,
      17.165,
      -47.018,
      -9.102,
      17.165,
      -47.018,
      -9.102,
      17.165,
      -47.018,
      -9.102,
      14.17,
      -46.501,
      -10.941,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3129_M0011",
    polygon: [
      -45.965,
      -12.842,
      14.17,
      -45.965,
      -12.842,
      17.165,
      -46.483,
      -11.004,
      17.165,
      -46.483,
      -11.004,
      17.165,
      -46.483,
      -11.004,
      14.17,
      -45.965,
      -12.842,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3129_M0012",
    polygon: [
      -43.482,
      -12.667,
      14.17,
      -43.482,
      -12.667,
      17.165,
      -45.366,
      -13.197,
      17.165,
      -45.366,
      -13.197,
      17.165,
      -45.366,
      -13.197,
      14.17,
      -43.482,
      -12.667,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3129_M0013",
    polygon: [
      -41.535,
      -12.118,
      14.17,
      -41.535,
      -12.118,
      17.165,
      -43.419,
      -12.649,
      17.165,
      -43.419,
      -12.649,
      17.165,
      -43.419,
      -12.649,
      14.17,
      -41.535,
      -12.118,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3130_M0010",
    polygon: [
      -38.287,
      -11.203,
      14.17,
      -38.287,
      -11.203,
      17.165,
      -40.171,
      -11.734,
      17.165,
      -40.171,
      -11.734,
      17.165,
      -40.171,
      -11.734,
      14.17,
      -38.287,
      -11.203,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3131_M0010",
    polygon: [
      -36.34,
      -10.655,
      14.17,
      -36.34,
      -10.655,
      17.165,
      -38.224,
      -11.186,
      17.165,
      -38.224,
      -11.186,
      17.165,
      -38.224,
      -11.186,
      14.17,
      -36.34,
      -10.655,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3132_M0010",
    polygon: [
      -33.089,
      -9.739,
      14.17,
      -33.089,
      -9.739,
      17.165,
      -34.973,
      -10.27,
      17.165,
      -34.973,
      -10.27,
      17.165,
      -34.973,
      -10.27,
      14.17,
      -33.089,
      -9.739,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3133_M0010",
    polygon: [
      -31.142,
      -9.191,
      14.17,
      -31.142,
      -9.191,
      17.165,
      -33.026,
      -9.722,
      17.165,
      -33.026,
      -9.722,
      17.165,
      -33.026,
      -9.722,
      14.17,
      -31.142,
      -9.191,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3134_M0010",
    polygon: [
      -27.416,
      -8.141,
      14.17,
      -27.416,
      -8.141,
      17.165,
      -29.776,
      -8.806,
      17.165,
      -29.776,
      -8.806,
      17.165,
      -29.776,
      -8.806,
      14.17,
      -27.416,
      -8.141,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3135_M0010",
    polygon: [
      -26.17,
      -10.63,
      14.17,
      -26.17,
      -10.63,
      17.165,
      -26.652,
      -8.919,
      17.165,
      -26.652,
      -8.919,
      17.165,
      -26.652,
      -8.919,
      14.17,
      -26.17,
      -10.63,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3149_M0010",
    polygon: [
      -7.55,
      -3.543,
      14.17,
      -7.55,
      -3.543,
      17.165,
      -7.068,
      -5.254,
      17.165,
      -7.068,
      -5.254,
      17.165,
      -7.068,
      -5.254,
      14.17,
      -7.55,
      -3.543,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3149_M0011",
    polygon: [
      -7.051,
      -5.317,
      14.17,
      -7.051,
      -5.317,
      17.165,
      -6.569,
      -7.028,
      17.165,
      -6.569,
      -7.028,
      17.165,
      -6.569,
      -7.028,
      14.17,
      -7.051,
      -5.317,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3149_M0012",
    polygon: [
      -6.185,
      -8.389,
      14.17,
      -6.185,
      -8.389,
      17.165,
      -5.703,
      -10.1,
      17.165,
      -5.703,
      -10.1,
      17.165,
      -5.703,
      -10.1,
      14.17,
      -6.185,
      -8.389,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3201_M0010",
    polygon: [
      2.009,
      -21.299,
      14.17,
      2.009,
      -21.299,
      17.165,
      3.894,
      -20.768,
      17.165,
      3.894,
      -20.768,
      17.165,
      3.894,
      -20.768,
      14.17,
      2.009,
      -21.299,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3202_M0010",
    polygon: [
      3.956,
      -20.75,
      14.17,
      3.956,
      -20.75,
      17.165,
      5.84,
      -20.22,
      17.165,
      5.84,
      -20.22,
      17.165,
      5.84,
      -20.22,
      14.17,
      3.956,
      -20.75,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3203_M0010",
    polygon: [
      7.207,
      -19.835,
      14.17,
      7.207,
      -19.835,
      17.165,
      9.091,
      -19.304,
      17.165,
      9.091,
      -19.304,
      17.165,
      9.091,
      -19.304,
      14.17,
      7.207,
      -19.835,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3204_M0010",
    polygon: [
      9.154,
      -19.286,
      14.17,
      9.154,
      -19.286,
      17.165,
      11.038,
      -18.756,
      17.165,
      11.038,
      -18.756,
      17.165,
      11.038,
      -18.756,
      14.17,
      9.154,
      -19.286,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3205_M0010",
    polygon: [
      12.402,
      -18.371,
      14.17,
      12.402,
      -18.371,
      17.165,
      14.287,
      -17.841,
      17.165,
      14.287,
      -17.841,
      17.165,
      14.287,
      -17.841,
      14.17,
      12.402,
      -18.371,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3205_M0011",
    polygon: [
      14.349,
      -17.823,
      14.17,
      14.349,
      -17.823,
      17.165,
      16.233,
      -17.292,
      17.165,
      16.233,
      -17.292,
      17.165,
      16.233,
      -17.292,
      14.17,
      14.349,
      -17.823,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3205_M0012",
    polygon: [
      16.838,
      -17.649,
      14.17,
      16.838,
      -17.649,
      17.165,
      17.356,
      -19.488,
      17.165,
      17.356,
      -19.488,
      17.165,
      17.356,
      -19.488,
      14.17,
      16.838,
      -17.649,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3205_M0013",
    polygon: [
      17.373,
      -19.55,
      14.17,
      17.373,
      -19.55,
      17.165,
      17.891,
      -21.389,
      17.165,
      17.891,
      -21.389,
      17.165,
      17.891,
      -21.389,
      14.17,
      17.373,
      -19.55,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3206_M0010",
    polygon: [
      18.288,
      -22.799,
      14.17,
      18.288,
      -22.799,
      17.165,
      18.806,
      -24.637,
      17.165,
      18.806,
      -24.637,
      17.165,
      18.806,
      -24.637,
      14.17,
      18.288,
      -22.799,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3206_M0011",
    polygon: [
      18.824,
      -24.7,
      14.17,
      18.824,
      -24.7,
      17.165,
      19.342,
      -26.538,
      17.165,
      19.342,
      -26.538,
      17.165,
      19.342,
      -26.538,
      14.17,
      18.824,
      -24.7,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3207_M0010",
    polygon: [
      19.731,
      -27.922,
      14.17,
      19.731,
      -27.922,
      17.165,
      20.249,
      -29.76,
      17.165,
      20.249,
      -29.76,
      17.165,
      20.249,
      -29.76,
      14.17,
      19.731,
      -27.922,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3207_M0011",
    polygon: [
      20.267,
      -29.823,
      14.17,
      20.267,
      -29.823,
      17.165,
      20.785,
      -31.661,
      17.165,
      20.785,
      -31.661,
      17.165,
      20.785,
      -31.661,
      14.17,
      20.267,
      -29.823,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3207_M0012",
    polygon: [
      20.458,
      -32.257,
      14.17,
      20.458,
      -32.257,
      17.165,
      18.571,
      -32.789,
      17.165,
      18.571,
      -32.789,
      17.165,
      18.571,
      -32.789,
      14.17,
      20.458,
      -32.257,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3207_M0013",
    polygon: [
      18.508,
      -32.806,
      14.17,
      18.508,
      -32.806,
      17.165,
      16.622,
      -33.338,
      17.165,
      16.622,
      -33.338,
      17.165,
      16.622,
      -33.338,
      14.17,
      18.508,
      -32.806,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3208_M0010",
    polygon: [
      15.26,
      -33.722,
      14.17,
      15.26,
      -33.722,
      17.165,
      13.373,
      -34.253,
      17.165,
      13.373,
      -34.253,
      17.165,
      13.373,
      -34.253,
      14.17,
      15.26,
      -33.722,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3209_M0010",
    polygon: [
      13.311,
      -34.271,
      14.17,
      13.311,
      -34.271,
      17.165,
      11.424,
      -34.802,
      17.165,
      11.424,
      -34.802,
      17.165,
      11.424,
      -34.802,
      14.17,
      13.311,
      -34.271,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3210_M0010",
    polygon: [
      10.062,
      -35.186,
      14.17,
      10.062,
      -35.186,
      17.165,
      8.176,
      -35.717,
      17.165,
      8.176,
      -35.717,
      17.165,
      8.176,
      -35.717,
      14.17,
      10.062,
      -35.186,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3211_M0010",
    polygon: [
      8.113,
      -35.735,
      14.17,
      8.113,
      -35.735,
      17.165,
      6.226,
      -36.266,
      17.165,
      6.226,
      -36.266,
      17.165,
      6.226,
      -36.266,
      14.17,
      8.113,
      -35.735,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
  },
  {
    ID: "LE200xxF03O_EZR3212_M0010",
    polygon: [
      4.864,
      -36.65,
      14.17,
      4.864,
      -36.65,
      17.165,
      2.978,
      -37.181,
      17.165,
      2.978,
      -37.181,
      17.165,
      2.978,
      -37.181,
      14.17,
      4.864,
      -36.65,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "LE200xxF03O_EZR3213_M0010",
    polygon: [
      2.915,
      -37.199,
      14.17,
      2.915,
      -37.199,
      17.165,
      1.029,
      -37.73,
      17.165,
      1.029,
      -37.73,
      17.165,
      1.029,
      -37.73,
      14.17,
      2.915,
      -37.199,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3214_M0010",
    polygon: [
      -0.333,
      -38.114,
      14.175,
      -0.333,
      -38.114,
      17.17,
      -2.694,
      -38.779,
      17.17,
      -2.694,
      -38.779,
      17.17,
      -2.694,
      -38.779,
      14.175,
      -0.333,
      -38.114,
      14.175,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3215_M0010",
    polygon: [
      -12.396,
      -41.512,
      14.175,
      -12.396,
      -41.512,
      17.17,
      -14.757,
      -42.177,
      17.17,
      -14.757,
      -42.177,
      17.17,
      -14.757,
      -42.177,
      14.175,
      -12.396,
      -41.512,
      14.175,
    ],
    "04-15 02:05": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3216_M0010",
    polygon: [
      -16.119,
      -42.56,
      14.17,
      -16.119,
      -42.56,
      17.165,
      -18.006,
      -43.092,
      17.165,
      -18.006,
      -43.092,
      17.165,
      -18.006,
      -43.092,
      14.17,
      -16.119,
      -42.56,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3217_M0010",
    polygon: [
      -18.068,
      -43.109,
      14.17,
      -18.068,
      -43.109,
      17.165,
      -19.955,
      -43.641,
      17.165,
      -19.955,
      -43.641,
      17.165,
      -19.955,
      -43.641,
      14.17,
      -18.068,
      -43.109,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3218_M0010",
    polygon: [
      -21.317,
      -44.025,
      14.17,
      -21.317,
      -44.025,
      17.165,
      -23.203,
      -44.556,
      17.165,
      -23.203,
      -44.556,
      17.165,
      -23.203,
      -44.556,
      14.17,
      -21.317,
      -44.025,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3219_M0010",
    polygon: [
      -23.266,
      -44.574,
      14.17,
      -23.266,
      -44.574,
      17.165,
      -25.152,
      -45.105,
      17.165,
      -25.152,
      -45.105,
      17.165,
      -25.152,
      -45.105,
      14.17,
      -23.266,
      -44.574,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3220_M0010",
    polygon: [
      -26.514,
      -45.489,
      14.17,
      -26.514,
      -45.489,
      17.165,
      -28.401,
      -46.02,
      17.165,
      -28.401,
      -46.02,
      17.165,
      -28.401,
      -46.02,
      14.17,
      -26.514,
      -45.489,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3221_M0010",
    polygon: [
      -28.464,
      -46.038,
      14.17,
      -28.464,
      -46.038,
      17.165,
      -30.35,
      -46.569,
      17.165,
      -30.35,
      -46.569,
      17.165,
      -30.35,
      -46.569,
      14.17,
      -28.464,
      -46.038,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3222_M0010",
    polygon: [
      -31.712,
      -46.953,
      14.17,
      -31.712,
      -46.953,
      17.165,
      -33.599,
      -47.484,
      17.165,
      -33.599,
      -47.484,
      17.165,
      -33.599,
      -47.484,
      14.17,
      -31.712,
      -46.953,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3222_M0011",
    polygon: [
      -33.661,
      -47.502,
      14.17,
      -33.661,
      -47.502,
      17.165,
      -35.548,
      -48.033,
      17.165,
      -35.548,
      -48.033,
      17.165,
      -35.548,
      -48.033,
      14.17,
      -33.661,
      -47.502,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3222_M0012",
    polygon: [
      -36.146,
      -47.674,
      14.17,
      -36.146,
      -47.674,
      17.165,
      -36.664,
      -45.836,
      17.165,
      -36.664,
      -45.836,
      17.165,
      -36.664,
      -45.836,
      14.17,
      -36.146,
      -47.674,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3222_M0013",
    polygon: [
      -36.682,
      -45.773,
      14.17,
      -36.682,
      -45.773,
      17.165,
      -37.199,
      -43.935,
      17.165,
      -37.199,
      -43.935,
      17.165,
      -37.199,
      -43.935,
      14.17,
      -36.682,
      -45.773,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "LE200xxF03O_EZR3223_M0010",
    polygon: [
      -37.608,
      -42.528,
      14.17,
      -37.608,
      -42.528,
      17.165,
      -38.126,
      -40.69,
      17.165,
      -38.126,
      -40.69,
      17.165,
      -38.126,
      -40.69,
      14.17,
      -37.608,
      -42.528,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3223_M0011",
    polygon: [
      -38.144,
      -40.627,
      14.17,
      -38.144,
      -40.627,
      17.165,
      -38.662,
      -38.789,
      17.165,
      -38.662,
      -38.789,
      17.165,
      -38.662,
      -38.789,
      14.17,
      -38.144,
      -40.627,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3224_M0010",
    polygon: [
      -39.047,
      -37.404,
      14.17,
      -39.047,
      -37.404,
      17.165,
      -39.564,
      -35.565,
      17.165,
      -39.564,
      -35.565,
      17.165,
      -39.564,
      -35.565,
      14.17,
      -39.047,
      -37.404,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3224_M0011",
    polygon: [
      -39.582,
      -35.503,
      14.17,
      -39.582,
      -35.503,
      17.165,
      -40.1,
      -33.664,
      17.165,
      -40.1,
      -33.664,
      17.165,
      -40.1,
      -33.664,
      14.17,
      -39.582,
      -35.503,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3224_M0012",
    polygon: [
      -39.767,
      -33.067,
      14.17,
      -39.767,
      -33.067,
      17.165,
      -37.883,
      -32.536,
      17.165,
      -37.883,
      -32.536,
      17.165,
      -37.883,
      -32.536,
      14.17,
      -39.767,
      -33.067,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3224_M0013",
    polygon: [
      -37.821,
      -32.518,
      14.17,
      -37.821,
      -32.518,
      17.165,
      -35.936,
      -31.988,
      17.165,
      -35.936,
      -31.988,
      17.165,
      -35.936,
      -31.988,
      14.17,
      -37.821,
      -32.518,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3225_M0010",
    polygon: [
      -34.57,
      -31.603,
      14.17,
      -34.57,
      -31.603,
      17.165,
      -32.685,
      -31.072,
      17.165,
      -32.685,
      -31.072,
      17.165,
      -32.685,
      -31.072,
      14.17,
      -34.57,
      -31.603,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3226_M0010",
    polygon: [
      -32.623,
      -31.054,
      14.17,
      -32.623,
      -31.054,
      17.165,
      -30.739,
      -30.523,
      17.165,
      -30.739,
      -30.523,
      17.165,
      -30.739,
      -30.523,
      14.17,
      -32.623,
      -31.054,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3227_M0010",
    polygon: [
      -29.374,
      -30.139,
      14.17,
      -29.374,
      -30.139,
      17.165,
      -27.49,
      -29.608,
      17.165,
      -27.49,
      -29.608,
      17.165,
      -27.49,
      -29.608,
      14.17,
      -29.374,
      -30.139,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3228_M0010",
    polygon: [
      -27.428,
      -29.591,
      14.17,
      -27.428,
      -29.591,
      17.165,
      -25.543,
      -29.06,
      17.165,
      -25.543,
      -29.06,
      17.165,
      -25.543,
      -29.06,
      14.17,
      -27.428,
      -29.591,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3229_M0010",
    polygon: [
      -24.177,
      -28.675,
      14.17,
      -24.177,
      -28.675,
      17.165,
      -21.816,
      -28.01,
      17.165,
      -21.816,
      -28.01,
      17.165,
      -21.816,
      -28.01,
      14.17,
      -24.177,
      -28.675,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3230_M0010",
    polygon: [
      -21.574,
      -26.947,
      14.17,
      -21.574,
      -26.947,
      17.165,
      -22.056,
      -25.236,
      17.165,
      -22.056,
      -25.236,
      17.165,
      -22.056,
      -25.236,
      14.17,
      -21.574,
      -26.947,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3231_M0010",
    polygon: [
      -22.074,
      -25.174,
      14.17,
      -22.074,
      -25.174,
      17.165,
      -22.556,
      -23.463,
      17.165,
      -22.556,
      -23.463,
      17.165,
      -22.556,
      -23.463,
      14.17,
      -22.074,
      -25.174,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3231_M0011",
    polygon: [
      -22.939,
      -22.101,
      14.17,
      -22.939,
      -22.101,
      17.165,
      -23.421,
      -20.39,
      17.165,
      -23.421,
      -20.39,
      17.165,
      -23.421,
      -20.39,
      14.17,
      -22.939,
      -22.101,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3232_M0010",
    polygon: [
      -23.439,
      -20.327,
      14.17,
      -23.439,
      -20.327,
      17.165,
      -23.921,
      -18.616,
      17.165,
      -23.921,
      -18.616,
      17.165,
      -23.921,
      -18.616,
      14.17,
      -23.439,
      -20.327,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3232_M0011",
    polygon: [
      -24.306,
      -17.25,
      14.17,
      -24.306,
      -17.25,
      17.165,
      -24.788,
      -15.539,
      17.165,
      -24.788,
      -15.539,
      17.165,
      -24.788,
      -15.539,
      14.17,
      -24.306,
      -17.25,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "LE200xxF03O_EZR3233_M0010",
    polygon: [
      -25.671,
      -12.403,
      14.17,
      -25.671,
      -12.403,
      17.165,
      -26.153,
      -10.692,
      17.165,
      -26.153,
      -10.692,
      17.165,
      -26.153,
      -10.692,
      14.17,
      -25.671,
      -12.403,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "LE200xxF03O_EZR3233_M0011",
    polygon: [
      -24.805,
      -15.476,
      14.17,
      -24.805,
      -15.476,
      17.165,
      -25.287,
      -13.765,
      17.165,
      -25.287,
      -13.765,
      17.165,
      -25.287,
      -13.765,
      14.17,
      -24.805,
      -15.476,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE200xxF03O_EZR3234_M0010",
    polygon: [
      -5.686,
      -10.163,
      14.17,
      -5.686,
      -10.163,
      17.165,
      -5.204,
      -11.874,
      17.165,
      -5.204,
      -11.874,
      17.165,
      -5.204,
      -11.874,
      14.17,
      -5.686,
      -10.163,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3235_M0010",
    polygon: [
      -4.819,
      -13.241,
      14.17,
      -4.819,
      -13.241,
      17.165,
      -4.337,
      -14.952,
      17.165,
      -4.337,
      -14.952,
      17.165,
      -4.337,
      -14.952,
      14.17,
      -4.819,
      -13.241,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 08:45": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3236_M0010",
    polygon: [
      -4.319,
      -15.014,
      14.17,
      -4.319,
      -15.014,
      17.165,
      -3.837,
      -16.725,
      17.165,
      -3.837,
      -16.725,
      17.165,
      -3.837,
      -16.725,
      14.17,
      -4.319,
      -15.014,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3237_M0010",
    polygon: [
      -2.954,
      -19.861,
      14.17,
      -2.954,
      -19.861,
      17.165,
      -2.472,
      -21.572,
      17.165,
      -2.472,
      -21.572,
      17.165,
      -2.472,
      -21.572,
      14.17,
      -2.954,
      -19.861,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3237_M0011",
    polygon: [
      -3.454,
      -18.087,
      14.17,
      -3.454,
      -18.087,
      17.165,
      -2.972,
      -19.798,
      17.165,
      -2.972,
      -19.798,
      17.165,
      -2.972,
      -19.798,
      14.17,
      -3.454,
      -18.087,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
  },
  {
    ID: "LE200xxF03O_EZR3251_M0010",
    polygon: [
      -1.718,
      -22.349,
      14.17,
      -1.718,
      -22.349,
      17.165,
      0.643,
      -21.684,
      17.165,
      0.643,
      -21.684,
      17.165,
      0.643,
      -21.684,
      14.17,
      -1.718,
      -22.349,
      14.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3301_M0010",
    polygon: [
      -18.951,
      -59.669,
      14.165,
      -18.951,
      -59.669,
      17.16,
      -17.067,
      -59.138,
      17.16,
      -17.067,
      -59.138,
      17.16,
      -17.067,
      -59.138,
      14.165,
      -18.951,
      -59.669,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3302_M0010",
    polygon: [
      -20.898,
      -60.217,
      14.165,
      -20.898,
      -60.217,
      17.16,
      -19.013,
      -59.687,
      17.16,
      -19.013,
      -59.687,
      17.16,
      -19.013,
      -59.687,
      14.165,
      -20.898,
      -60.217,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3303_M0010",
    polygon: [
      -24.149,
      -61.133,
      14.165,
      -24.149,
      -61.133,
      17.16,
      -22.264,
      -60.602,
      17.16,
      -22.264,
      -60.602,
      17.16,
      -22.264,
      -60.602,
      14.165,
      -24.149,
      -61.133,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3304_M0010",
    polygon: [
      -26.095,
      -61.682,
      14.165,
      -26.095,
      -61.682,
      17.16,
      -24.211,
      -61.151,
      17.16,
      -24.211,
      -61.151,
      17.16,
      -24.211,
      -61.151,
      14.165,
      -26.095,
      -61.682,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3305_M0010",
    polygon: [
      -30.567,
      -67.481,
      14.17,
      -30.567,
      -67.481,
      17.165,
      -31.085,
      -65.643,
      17.165,
      -31.085,
      -65.643,
      17.165,
      -31.085,
      -65.643,
      14.17,
      -30.567,
      -67.481,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3305_M0011",
    polygon: [
      -31.102,
      -65.58,
      14.17,
      -31.102,
      -65.58,
      17.165,
      -31.62,
      -63.742,
      17.165,
      -31.62,
      -63.742,
      17.165,
      -31.62,
      -63.742,
      14.17,
      -31.102,
      -65.58,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3305_M0012",
    polygon: [
      -31.293,
      -63.146,
      14.165,
      -31.293,
      -63.146,
      17.16,
      -29.409,
      -62.615,
      17.16,
      -29.409,
      -62.615,
      17.16,
      -29.409,
      -62.615,
      14.165,
      -31.293,
      -63.146,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3305_M0013",
    polygon: [
      -29.346,
      -62.597,
      14.165,
      -29.346,
      -62.597,
      17.16,
      -27.462,
      -62.067,
      17.16,
      -27.462,
      -62.067,
      17.16,
      -27.462,
      -62.067,
      14.165,
      -29.346,
      -62.597,
      14.165,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3306_M0010",
    polygon: [
      -29.135,
      -72.608,
      14.17,
      -29.135,
      -72.608,
      17.165,
      -29.653,
      -70.769,
      17.165,
      -29.653,
      -70.769,
      17.165,
      -29.653,
      -70.769,
      14.17,
      -29.135,
      -72.608,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3306_M0011",
    polygon: [
      -29.671,
      -70.707,
      14.17,
      -29.671,
      -70.707,
      17.165,
      -30.189,
      -68.868,
      17.165,
      -30.189,
      -68.868,
      17.165,
      -30.189,
      -68.868,
      14.17,
      -29.671,
      -70.707,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3307_M0010",
    polygon: [
      -23.243,
      -77.027,
      14.17,
      -23.243,
      -77.027,
      17.165,
      -25.129,
      -77.559,
      17.165,
      -25.129,
      -77.559,
      17.165,
      -25.129,
      -77.559,
      14.17,
      -23.243,
      -77.027,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3307_M0011",
    polygon: [
      -25.189,
      -77.576,
      14.17,
      -25.189,
      -77.576,
      17.165,
      -27.076,
      -78.107,
      17.165,
      -27.076,
      -78.107,
      17.165,
      -27.076,
      -78.107,
      14.17,
      -25.189,
      -77.576,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF03O_EZR3307_M0012",
    polygon: [
      -27.68,
      -77.756,
      14.17,
      -27.68,
      -77.756,
      17.165,
      -28.198,
      -75.917,
      17.165,
      -28.198,
      -75.917,
      17.165,
      -28.198,
      -75.917,
      14.17,
      -27.68,
      -77.756,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "LE200xxF03O_EZR3307_M0013",
    polygon: [
      -28.215,
      -75.855,
      14.17,
      -28.215,
      -75.855,
      17.165,
      -28.733,
      -74.016,
      17.165,
      -28.733,
      -74.016,
      17.165,
      -28.733,
      -74.016,
      14.17,
      -28.215,
      -75.855,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "LE200xxF03O_EZR3308_M0010",
    polygon: [
      -19.991,
      -76.112,
      14.17,
      -19.991,
      -76.112,
      17.165,
      -21.878,
      -76.643,
      17.165,
      -21.878,
      -76.643,
      17.165,
      -21.878,
      -76.643,
      14.17,
      -19.991,
      -76.112,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3309_M0010",
    polygon: [
      -18.044,
      -75.563,
      14.17,
      -18.044,
      -75.563,
      17.165,
      -19.931,
      -76.095,
      17.165,
      -19.931,
      -76.095,
      17.165,
      -19.931,
      -76.095,
      14.17,
      -18.044,
      -75.563,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3310_M0010",
    polygon: [
      -14.794,
      -74.648,
      14.17,
      -14.794,
      -74.648,
      17.165,
      -16.68,
      -75.179,
      17.165,
      -16.68,
      -75.179,
      17.165,
      -16.68,
      -75.179,
      14.17,
      -14.794,
      -74.648,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3311_M0010",
    polygon: [
      -12.847,
      -74.099,
      14.17,
      -12.847,
      -74.099,
      17.165,
      -14.734,
      -74.631,
      17.165,
      -14.734,
      -74.631,
      17.165,
      -14.734,
      -74.631,
      14.17,
      -12.847,
      -74.099,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3312_M0010",
    polygon: [
      -9.596,
      -73.183,
      14.17,
      -9.596,
      -73.183,
      17.165,
      -11.482,
      -73.715,
      17.165,
      -11.482,
      -73.715,
      17.165,
      -11.482,
      -73.715,
      14.17,
      -9.596,
      -73.183,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3313_M0010",
    polygon: [
      -7.649,
      -72.635,
      14.17,
      -7.649,
      -72.635,
      17.165,
      -9.535,
      -73.166,
      17.165,
      -9.535,
      -73.166,
      17.165,
      -9.535,
      -73.166,
      14.17,
      -7.649,
      -72.635,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3314_M0010",
    polygon: [
      -4.398,
      -71.719,
      14.17,
      -4.398,
      -71.719,
      17.165,
      -6.284,
      -72.251,
      17.165,
      -6.284,
      -72.251,
      17.165,
      -6.284,
      -72.251,
      14.17,
      -4.398,
      -71.719,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3315_M0010",
    polygon: [
      -2.451,
      -71.171,
      14.17,
      -2.451,
      -71.171,
      17.165,
      -4.338,
      -71.702,
      17.165,
      -4.338,
      -71.702,
      17.165,
      -4.338,
      -71.702,
      14.17,
      -2.451,
      -71.171,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3316_M0010",
    polygon: [
      0.799,
      -70.255,
      14.17,
      0.799,
      -70.255,
      17.165,
      -1.087,
      -70.787,
      17.165,
      -1.087,
      -70.787,
      17.165,
      -1.087,
      -70.787,
      14.17,
      0.799,
      -70.255,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3317_M0010",
    polygon: [
      2.746,
      -69.707,
      14.17,
      2.746,
      -69.707,
      17.165,
      0.86,
      -70.238,
      17.165,
      0.86,
      -70.238,
      17.165,
      0.86,
      -70.238,
      14.17,
      2.746,
      -69.707,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3318_M0010",
    polygon: [
      5.998,
      -68.791,
      14.17,
      5.998,
      -68.791,
      17.165,
      4.111,
      -69.322,
      17.165,
      4.111,
      -69.322,
      17.165,
      4.111,
      -69.322,
      14.17,
      5.998,
      -68.791,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3319_M0010",
    polygon: [
      7.944,
      -68.243,
      14.17,
      7.944,
      -68.243,
      17.165,
      6.058,
      -68.774,
      17.165,
      6.058,
      -68.774,
      17.165,
      6.058,
      -68.774,
      14.17,
      7.944,
      -68.243,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3320_M0010",
    polygon: [
      11.197,
      -67.332,
      14.17,
      11.197,
      -67.332,
      17.165,
      9.31,
      -67.864,
      17.165,
      9.31,
      -67.864,
      17.165,
      9.31,
      -67.864,
      14.17,
      11.197,
      -67.332,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF03O_EZR3320_M0011",
    polygon: [
      13.143,
      -66.784,
      14.17,
      13.143,
      -66.784,
      17.165,
      11.257,
      -67.315,
      17.165,
      11.257,
      -67.315,
      17.165,
      11.257,
      -67.315,
      14.17,
      13.143,
      -66.784,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF03O_EZR3320_M0012",
    polygon: [
      12.957,
      -64.348,
      14.17,
      12.957,
      -64.348,
      17.165,
      13.475,
      -66.187,
      17.165,
      13.475,
      -66.187,
      17.165,
      13.475,
      -66.187,
      14.17,
      12.957,
      -64.348,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3320_M0013",
    polygon: [
      12.421,
      -62.447,
      14.17,
      12.421,
      -62.447,
      17.165,
      12.939,
      -64.286,
      17.165,
      12.939,
      -64.286,
      17.165,
      12.939,
      -64.286,
      14.17,
      12.421,
      -62.447,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF03O_EZR3321_M0010",
    polygon: [
      11.514,
      -59.225,
      14.17,
      11.514,
      -59.225,
      17.165,
      12.032,
      -61.063,
      17.165,
      12.032,
      -61.063,
      17.165,
      12.032,
      -61.063,
      14.17,
      11.514,
      -59.225,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF03O_EZR3321_M0011",
    polygon: [
      10.978,
      -57.324,
      14.17,
      10.978,
      -57.324,
      17.165,
      11.496,
      -59.162,
      17.165,
      11.496,
      -59.162,
      17.165,
      11.496,
      -59.162,
      14.17,
      10.978,
      -57.324,
      14.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4101_M0010",
    polygon: [
      -4.957,
      -1.815,
      17.67,
      -4.957,
      -1.815,
      20.665,
      -7.318,
      -2.48,
      20.665,
      -7.318,
      -2.48,
      20.665,
      -7.318,
      -2.48,
      17.67,
      -4.957,
      -1.815,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
  },
  {
    ID: "LE200xxF04O_EZR4102_M0010",
    polygon: [
      -1.71,
      -0.9,
      17.67,
      -1.71,
      -0.9,
      20.665,
      -3.594,
      -1.431,
      20.665,
      -3.594,
      -1.431,
      20.665,
      -3.594,
      -1.431,
      17.67,
      -1.71,
      -0.9,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4103_M0010",
    polygon: [
      0.237,
      -0.352,
      17.67,
      0.237,
      -0.352,
      20.665,
      -1.648,
      -0.883,
      20.665,
      -1.648,
      -0.883,
      20.665,
      -1.648,
      -0.883,
      17.67,
      0.237,
      -0.352,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4104_M0010",
    polygon: [
      3.485,
      0.563,
      17.67,
      3.485,
      0.563,
      20.665,
      1.601,
      0.032,
      20.665,
      1.601,
      0.032,
      20.665,
      1.601,
      0.032,
      17.67,
      3.485,
      0.563,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4105_M0010",
    polygon: [
      5.432,
      1.112,
      17.67,
      5.432,
      1.112,
      20.665,
      3.548,
      0.581,
      20.665,
      3.548,
      0.581,
      20.665,
      3.548,
      0.581,
      17.67,
      5.432,
      1.112,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4106_M0010",
    polygon: [
      8.683,
      2.027,
      17.67,
      8.683,
      2.027,
      20.665,
      6.799,
      1.497,
      20.665,
      6.799,
      1.497,
      20.665,
      6.799,
      1.497,
      17.67,
      8.683,
      2.027,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4106_M0011",
    polygon: [
      10.63,
      2.576,
      17.67,
      10.63,
      2.576,
      20.665,
      8.745,
      2.045,
      20.665,
      8.745,
      2.045,
      20.665,
      8.745,
      2.045,
      17.67,
      10.63,
      2.576,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4106_M0012",
    polygon: [
      10.458,
      5.012,
      17.67,
      10.458,
      5.012,
      20.665,
      10.975,
      3.174,
      20.665,
      10.975,
      3.174,
      20.665,
      10.975,
      3.174,
      17.67,
      10.458,
      5.012,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4106_M0013",
    polygon: [
      9.922,
      6.913,
      17.67,
      9.922,
      6.913,
      20.665,
      10.44,
      5.075,
      20.665,
      10.44,
      5.075,
      20.665,
      10.44,
      5.075,
      17.67,
      9.922,
      6.913,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4107_M0010",
    polygon: [
      9.014,
      10.135,
      17.67,
      9.014,
      10.135,
      20.665,
      9.532,
      8.297,
      20.665,
      9.532,
      8.297,
      20.665,
      9.532,
      8.297,
      17.67,
      9.014,
      10.135,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4107_M0011",
    polygon: [
      8.479,
      12.036,
      17.67,
      8.479,
      12.036,
      20.665,
      8.997,
      10.198,
      20.665,
      8.997,
      10.198,
      20.665,
      8.997,
      10.198,
      17.67,
      8.479,
      12.036,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4108_M0010",
    polygon: [
      7.564,
      15.285,
      17.67,
      7.564,
      15.285,
      20.665,
      8.082,
      13.446,
      20.665,
      8.082,
      13.446,
      20.665,
      8.082,
      13.446,
      17.67,
      7.564,
      15.285,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4108_M0011",
    polygon: [
      7.028,
      17.186,
      17.67,
      7.028,
      17.186,
      20.665,
      7.546,
      15.348,
      20.665,
      7.546,
      15.348,
      20.665,
      7.546,
      15.348,
      17.67,
      7.028,
      17.186,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4108_M0012",
    polygon: [
      4.543,
      17.013,
      17.67,
      4.543,
      17.013,
      20.665,
      6.427,
      17.544,
      20.665,
      6.427,
      17.544,
      20.665,
      6.427,
      17.544,
      17.67,
      4.543,
      17.013,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4108_M0013",
    polygon: [
      2.596,
      16.465,
      17.67,
      2.596,
      16.465,
      20.665,
      4.48,
      16.995,
      20.665,
      4.48,
      16.995,
      20.665,
      4.48,
      16.995,
      17.67,
      2.596,
      16.465,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4109_M0010",
    polygon: [
      -0.653,
      15.55,
      17.67,
      -0.653,
      15.55,
      20.665,
      1.232,
      16.08,
      20.665,
      1.232,
      16.08,
      20.665,
      1.232,
      16.08,
      17.67,
      -0.653,
      15.55,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4110_M0010",
    polygon: [
      -2.599,
      15.001,
      17.67,
      -2.599,
      15.001,
      20.665,
      -0.715,
      15.532,
      20.665,
      -0.715,
      15.532,
      20.665,
      -0.715,
      15.532,
      17.67,
      -2.599,
      15.001,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4111_M0010",
    polygon: [
      -5.85,
      14.086,
      17.67,
      -5.85,
      14.086,
      20.665,
      -3.966,
      14.616,
      20.665,
      -3.966,
      14.616,
      20.665,
      -3.966,
      14.616,
      17.67,
      -5.85,
      14.086,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4112_M0010",
    polygon: [
      -7.797,
      13.537,
      17.67,
      -7.797,
      13.537,
      20.665,
      -5.913,
      14.068,
      20.665,
      -5.913,
      14.068,
      20.665,
      -5.913,
      14.068,
      17.67,
      -7.797,
      13.537,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4113_M0010",
    polygon: [
      -11.046,
      12.622,
      17.67,
      -11.046,
      12.622,
      20.665,
      -9.161,
      13.153,
      20.665,
      -9.161,
      13.153,
      20.665,
      -9.161,
      13.153,
      17.67,
      -11.046,
      12.622,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4114_M0010",
    polygon: [
      -12.992,
      12.074,
      17.67,
      -12.992,
      12.074,
      20.665,
      -11.108,
      12.604,
      20.665,
      -11.108,
      12.604,
      20.665,
      -11.108,
      12.604,
      17.67,
      -12.992,
      12.074,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4115_M0010",
    polygon: [
      -16.243,
      11.158,
      17.67,
      -16.243,
      11.158,
      20.665,
      -14.359,
      11.689,
      20.665,
      -14.359,
      11.689,
      20.665,
      -14.359,
      11.689,
      17.67,
      -16.243,
      11.158,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4116_M0010",
    polygon: [
      -18.19,
      10.61,
      17.67,
      -18.19,
      10.61,
      20.665,
      -16.306,
      11.14,
      20.665,
      -16.306,
      11.14,
      20.665,
      -16.306,
      11.14,
      17.67,
      -18.19,
      10.61,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4116_M0011",
    polygon: [
      -18.262,
      9.047,
      17.67,
      -18.262,
      9.047,
      20.665,
      -18.533,
      10.009,
      20.665,
      -18.533,
      10.009,
      20.665,
      -18.533,
      10.009,
      17.67,
      -18.262,
      9.047,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4117_M0010",
    polygon: [
      -21.0,
      7.74,
      17.67,
      -21.0,
      7.74,
      20.665,
      -19.036,
      8.293,
      20.665,
      -19.036,
      8.293,
      20.665,
      -19.036,
      8.293,
      17.67,
      -21.0,
      7.74,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4118_M0010",
    polygon: [
      -23.026,
      7.17,
      17.67,
      -23.026,
      7.17,
      20.665,
      -21.062,
      7.723,
      20.665,
      -21.062,
      7.723,
      20.665,
      -21.062,
      7.723,
      17.67,
      -23.026,
      7.17,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4119_M0010",
    polygon: [
      -26.836,
      8.174,
      17.67,
      -26.836,
      8.174,
      20.665,
      -24.952,
      8.705,
      20.665,
      -24.952,
      8.705,
      20.665,
      -24.952,
      8.705,
      17.67,
      -26.836,
      8.174,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4119_M0011",
    polygon: [
      -24.357,
      8.369,
      17.67,
      -24.357,
      8.369,
      20.665,
      -24.085,
      7.406,
      20.665,
      -24.085,
      7.406,
      20.665,
      -24.085,
      7.406,
      17.67,
      -24.357,
      8.369,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4120_M0010",
    polygon: [
      -28.783,
      7.626,
      17.67,
      -28.783,
      7.626,
      20.665,
      -26.899,
      8.157,
      20.665,
      -26.899,
      8.157,
      20.665,
      -26.899,
      8.157,
      17.67,
      -28.783,
      7.626,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4121_M0010",
    polygon: [
      -32.031,
      6.711,
      17.67,
      -32.031,
      6.711,
      20.665,
      -30.147,
      7.242,
      20.665,
      -30.147,
      7.242,
      20.665,
      -30.147,
      7.242,
      17.67,
      -32.031,
      6.711,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4122_M0010",
    polygon: [
      -33.978,
      6.162,
      17.67,
      -33.978,
      6.162,
      20.665,
      -32.094,
      6.693,
      20.665,
      -32.094,
      6.693,
      20.665,
      -32.094,
      6.693,
      17.67,
      -33.978,
      6.162,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4123_M0010",
    polygon: [
      -37.229,
      5.247,
      17.67,
      -37.229,
      5.247,
      20.665,
      -35.345,
      5.777,
      20.665,
      -35.345,
      5.777,
      20.665,
      -35.345,
      5.777,
      17.67,
      -37.229,
      5.247,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4124_M0010",
    polygon: [
      -39.176,
      4.698,
      17.67,
      -39.176,
      4.698,
      20.665,
      -37.292,
      5.229,
      20.665,
      -37.292,
      5.229,
      20.665,
      -37.292,
      5.229,
      17.67,
      -39.176,
      4.698,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4125_M0010",
    polygon: [
      -42.424,
      3.783,
      17.67,
      -42.424,
      3.783,
      20.665,
      -40.54,
      4.314,
      20.665,
      -40.54,
      4.314,
      20.665,
      -40.54,
      4.314,
      17.67,
      -42.424,
      3.783,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4126_M0010",
    polygon: [
      -44.371,
      3.235,
      17.67,
      -44.371,
      3.235,
      20.665,
      -42.487,
      3.766,
      20.665,
      -42.487,
      3.766,
      20.665,
      -42.487,
      3.766,
      17.67,
      -44.371,
      3.235,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4127_M0010",
    polygon: [
      -47.622,
      2.319,
      17.67,
      -47.622,
      2.319,
      20.665,
      -45.738,
      2.85,
      20.665,
      -45.738,
      2.85,
      20.665,
      -45.738,
      2.85,
      17.67,
      -47.622,
      2.319,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4127_M0011",
    polygon: [
      -49.569,
      1.771,
      17.67,
      -49.569,
      1.771,
      20.665,
      -47.685,
      2.301,
      20.665,
      -47.685,
      2.301,
      20.665,
      -47.685,
      2.301,
      17.67,
      -49.569,
      1.771,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4127_M0012",
    polygon: [
      -49.394,
      -0.668,
      17.67,
      -49.394,
      -0.668,
      20.665,
      -49.912,
      1.17,
      20.665,
      -49.912,
      1.17,
      20.665,
      -49.912,
      1.17,
      17.67,
      -49.394,
      -0.668,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4127_M0013",
    polygon: [
      -48.859,
      -2.569,
      17.67,
      -48.859,
      -2.569,
      20.665,
      -49.377,
      -0.731,
      20.665,
      -49.377,
      -0.731,
      20.665,
      -49.377,
      -0.731,
      17.67,
      -48.859,
      -2.569,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4128_M0010",
    polygon: [
      -47.951,
      -5.791,
      17.67,
      -47.951,
      -5.791,
      20.665,
      -48.469,
      -3.953,
      20.665,
      -48.469,
      -3.953,
      20.665,
      -48.469,
      -3.953,
      17.67,
      -47.951,
      -5.791,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4128_M0011",
    polygon: [
      -47.416,
      -7.692,
      17.67,
      -47.416,
      -7.692,
      20.665,
      -47.933,
      -5.854,
      20.665,
      -47.933,
      -5.854,
      20.665,
      -47.933,
      -5.854,
      17.67,
      -47.416,
      -7.692,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4129_M0010",
    polygon: [
      -46.501,
      -10.941,
      17.67,
      -46.501,
      -10.941,
      20.665,
      -47.018,
      -9.102,
      20.665,
      -47.018,
      -9.102,
      20.665,
      -47.018,
      -9.102,
      17.67,
      -46.501,
      -10.941,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4129_M0011",
    polygon: [
      -45.965,
      -12.842,
      17.67,
      -45.965,
      -12.842,
      20.665,
      -46.483,
      -11.004,
      20.665,
      -46.483,
      -11.004,
      20.665,
      -46.483,
      -11.004,
      17.67,
      -45.965,
      -12.842,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "LE200xxF04O_EZR4129_M0012",
    polygon: [
      -43.482,
      -12.667,
      17.67,
      -43.482,
      -12.667,
      20.665,
      -45.366,
      -13.197,
      20.665,
      -45.366,
      -13.197,
      20.665,
      -45.366,
      -13.197,
      17.67,
      -43.482,
      -12.667,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4129_M0013",
    polygon: [
      -41.535,
      -12.118,
      17.67,
      -41.535,
      -12.118,
      20.665,
      -43.419,
      -12.649,
      20.665,
      -43.419,
      -12.649,
      20.665,
      -43.419,
      -12.649,
      17.67,
      -41.535,
      -12.118,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4130_M0010",
    polygon: [
      -38.287,
      -11.203,
      17.67,
      -38.287,
      -11.203,
      20.665,
      -40.171,
      -11.734,
      20.665,
      -40.171,
      -11.734,
      20.665,
      -40.171,
      -11.734,
      17.67,
      -38.287,
      -11.203,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4131_M0010",
    polygon: [
      -36.34,
      -10.655,
      17.67,
      -36.34,
      -10.655,
      20.665,
      -38.224,
      -11.186,
      20.665,
      -38.224,
      -11.186,
      20.665,
      -38.224,
      -11.186,
      17.67,
      -36.34,
      -10.655,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4132_M0010",
    polygon: [
      -33.089,
      -9.739,
      17.67,
      -33.089,
      -9.739,
      20.665,
      -34.973,
      -10.27,
      20.665,
      -34.973,
      -10.27,
      20.665,
      -34.973,
      -10.27,
      17.67,
      -33.089,
      -9.739,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4133_M0010",
    polygon: [
      -31.142,
      -9.191,
      17.67,
      -31.142,
      -9.191,
      20.665,
      -33.026,
      -9.722,
      20.665,
      -33.026,
      -9.722,
      20.665,
      -33.026,
      -9.722,
      17.67,
      -31.142,
      -9.191,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4134_M0010",
    polygon: [
      -27.416,
      -8.141,
      17.67,
      -27.416,
      -8.141,
      20.665,
      -29.776,
      -8.806,
      20.665,
      -29.776,
      -8.806,
      20.665,
      -29.776,
      -8.806,
      17.67,
      -27.416,
      -8.141,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4135_M0010",
    polygon: [
      -26.17,
      -10.63,
      17.67,
      -26.17,
      -10.63,
      20.665,
      -26.652,
      -8.919,
      20.665,
      -26.652,
      -8.919,
      20.665,
      -26.652,
      -8.919,
      17.67,
      -26.17,
      -10.63,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4149_M0010",
    polygon: [
      -7.55,
      -3.543,
      17.67,
      -7.55,
      -3.543,
      20.665,
      -7.068,
      -5.254,
      20.665,
      -7.068,
      -5.254,
      20.665,
      -7.068,
      -5.254,
      17.67,
      -7.55,
      -3.543,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4149_M0011",
    polygon: [
      -7.051,
      -5.317,
      17.67,
      -7.051,
      -5.317,
      20.665,
      -6.569,
      -7.028,
      20.665,
      -6.569,
      -7.028,
      20.665,
      -6.569,
      -7.028,
      17.67,
      -7.051,
      -5.317,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4149_M0012",
    polygon: [
      -6.185,
      -8.389,
      17.67,
      -6.185,
      -8.389,
      20.665,
      -5.703,
      -10.1,
      20.665,
      -5.703,
      -10.1,
      20.665,
      -5.703,
      -10.1,
      17.67,
      -6.185,
      -8.389,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4201_M0010",
    polygon: [
      2.009,
      -21.299,
      17.67,
      2.009,
      -21.299,
      20.665,
      3.894,
      -20.768,
      20.665,
      3.894,
      -20.768,
      20.665,
      3.894,
      -20.768,
      17.67,
      2.009,
      -21.299,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4202_M0010",
    polygon: [
      3.956,
      -20.75,
      17.67,
      3.956,
      -20.75,
      20.665,
      5.84,
      -20.22,
      20.665,
      5.84,
      -20.22,
      20.665,
      5.84,
      -20.22,
      17.67,
      3.956,
      -20.75,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4203_M0010",
    polygon: [
      7.207,
      -19.835,
      17.67,
      7.207,
      -19.835,
      20.665,
      9.091,
      -19.304,
      20.665,
      9.091,
      -19.304,
      20.665,
      9.091,
      -19.304,
      17.67,
      7.207,
      -19.835,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4204_M0010",
    polygon: [
      9.154,
      -19.286,
      17.67,
      9.154,
      -19.286,
      20.665,
      11.038,
      -18.756,
      20.665,
      11.038,
      -18.756,
      20.665,
      11.038,
      -18.756,
      17.67,
      9.154,
      -19.286,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4205_M0010",
    polygon: [
      12.402,
      -18.371,
      17.67,
      12.402,
      -18.371,
      20.665,
      14.287,
      -17.841,
      20.665,
      14.287,
      -17.841,
      20.665,
      14.287,
      -17.841,
      17.67,
      12.402,
      -18.371,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4205_M0011",
    polygon: [
      14.349,
      -17.823,
      17.67,
      14.349,
      -17.823,
      20.665,
      16.233,
      -17.292,
      20.665,
      16.233,
      -17.292,
      20.665,
      16.233,
      -17.292,
      17.67,
      14.349,
      -17.823,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4205_M0012",
    polygon: [
      16.838,
      -17.649,
      17.67,
      16.838,
      -17.649,
      20.665,
      17.356,
      -19.488,
      20.665,
      17.356,
      -19.488,
      20.665,
      17.356,
      -19.488,
      17.67,
      16.838,
      -17.649,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4205_M0013",
    polygon: [
      17.373,
      -19.55,
      17.67,
      17.373,
      -19.55,
      20.665,
      17.891,
      -21.389,
      20.665,
      17.891,
      -21.389,
      20.665,
      17.891,
      -21.389,
      17.67,
      17.373,
      -19.55,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4206_M0010",
    polygon: [
      18.288,
      -22.799,
      17.67,
      18.288,
      -22.799,
      20.665,
      18.806,
      -24.637,
      20.665,
      18.806,
      -24.637,
      20.665,
      18.806,
      -24.637,
      17.67,
      18.288,
      -22.799,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4206_M0011",
    polygon: [
      18.824,
      -24.7,
      17.67,
      18.824,
      -24.7,
      20.665,
      19.342,
      -26.538,
      20.665,
      19.342,
      -26.538,
      20.665,
      19.342,
      -26.538,
      17.67,
      18.824,
      -24.7,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4207_M0010",
    polygon: [
      19.731,
      -27.922,
      17.67,
      19.731,
      -27.922,
      20.665,
      20.249,
      -29.76,
      20.665,
      20.249,
      -29.76,
      20.665,
      20.249,
      -29.76,
      17.67,
      19.731,
      -27.922,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4207_M0011",
    polygon: [
      20.267,
      -29.823,
      17.67,
      20.267,
      -29.823,
      20.665,
      20.785,
      -31.661,
      20.665,
      20.785,
      -31.661,
      20.665,
      20.785,
      -31.661,
      17.67,
      20.267,
      -29.823,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4207_M0012",
    polygon: [
      20.458,
      -32.257,
      17.67,
      20.458,
      -32.257,
      20.665,
      18.571,
      -32.789,
      20.665,
      18.571,
      -32.789,
      20.665,
      18.571,
      -32.789,
      17.67,
      20.458,
      -32.257,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4207_M0013",
    polygon: [
      18.508,
      -32.806,
      17.67,
      18.508,
      -32.806,
      20.665,
      16.622,
      -33.338,
      20.665,
      16.622,
      -33.338,
      20.665,
      16.622,
      -33.338,
      17.67,
      18.508,
      -32.806,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4208_M0010",
    polygon: [
      15.26,
      -33.722,
      17.67,
      15.26,
      -33.722,
      20.665,
      13.373,
      -34.253,
      20.665,
      13.373,
      -34.253,
      20.665,
      13.373,
      -34.253,
      17.67,
      15.26,
      -33.722,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4209_M0010",
    polygon: [
      13.311,
      -34.271,
      17.67,
      13.311,
      -34.271,
      20.665,
      11.424,
      -34.802,
      20.665,
      11.424,
      -34.802,
      20.665,
      11.424,
      -34.802,
      17.67,
      13.311,
      -34.271,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4210_M0010",
    polygon: [
      10.062,
      -35.186,
      17.67,
      10.062,
      -35.186,
      20.665,
      8.176,
      -35.717,
      20.665,
      8.176,
      -35.717,
      20.665,
      8.176,
      -35.717,
      17.67,
      10.062,
      -35.186,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4211_M0010",
    polygon: [
      8.113,
      -35.735,
      17.67,
      8.113,
      -35.735,
      20.665,
      6.226,
      -36.266,
      20.665,
      6.226,
      -36.266,
      20.665,
      6.226,
      -36.266,
      17.67,
      8.113,
      -35.735,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4212_M0010",
    polygon: [
      4.864,
      -36.65,
      17.67,
      4.864,
      -36.65,
      20.665,
      2.978,
      -37.181,
      20.665,
      2.978,
      -37.181,
      20.665,
      2.978,
      -37.181,
      17.67,
      4.864,
      -36.65,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
  },
  {
    ID: "LE200xxF04O_EZR4213_M0010",
    polygon: [
      2.915,
      -37.199,
      17.67,
      2.915,
      -37.199,
      20.665,
      1.029,
      -37.73,
      20.665,
      1.029,
      -37.73,
      20.665,
      1.029,
      -37.73,
      17.67,
      2.915,
      -37.199,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4214_M0010",
    polygon: [
      -0.333,
      -38.114,
      17.675,
      -0.333,
      -38.114,
      20.67,
      -2.694,
      -38.779,
      20.67,
      -2.694,
      -38.779,
      20.67,
      -2.694,
      -38.779,
      17.675,
      -0.333,
      -38.114,
      17.675,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
  },
  {
    ID: "LE200xxF04O_EZR4215_M0010",
    polygon: [
      -12.396,
      -41.512,
      17.675,
      -12.396,
      -41.512,
      20.67,
      -14.757,
      -42.177,
      20.67,
      -14.757,
      -42.177,
      20.67,
      -14.757,
      -42.177,
      17.675,
      -12.396,
      -41.512,
      17.675,
    ],
    "04-15 02:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4216_M0010",
    polygon: [
      -16.119,
      -42.56,
      17.67,
      -16.119,
      -42.56,
      20.665,
      -18.006,
      -43.092,
      20.665,
      -18.006,
      -43.092,
      20.665,
      -18.006,
      -43.092,
      17.67,
      -16.119,
      -42.56,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4217_M0010",
    polygon: [
      -18.068,
      -43.109,
      17.67,
      -18.068,
      -43.109,
      20.665,
      -19.955,
      -43.641,
      20.665,
      -19.955,
      -43.641,
      20.665,
      -19.955,
      -43.641,
      17.67,
      -18.068,
      -43.109,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4218_M0010",
    polygon: [
      -21.317,
      -44.025,
      17.67,
      -21.317,
      -44.025,
      20.665,
      -23.203,
      -44.556,
      20.665,
      -23.203,
      -44.556,
      20.665,
      -23.203,
      -44.556,
      17.67,
      -21.317,
      -44.025,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4219_M0010",
    polygon: [
      -23.266,
      -44.574,
      17.67,
      -23.266,
      -44.574,
      20.665,
      -25.152,
      -45.105,
      20.665,
      -25.152,
      -45.105,
      20.665,
      -25.152,
      -45.105,
      17.67,
      -23.266,
      -44.574,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4220_M0010",
    polygon: [
      -26.514,
      -45.489,
      17.67,
      -26.514,
      -45.489,
      20.665,
      -28.401,
      -46.02,
      20.665,
      -28.401,
      -46.02,
      20.665,
      -28.401,
      -46.02,
      17.67,
      -26.514,
      -45.489,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4221_M0010",
    polygon: [
      -28.464,
      -46.038,
      17.67,
      -28.464,
      -46.038,
      20.665,
      -30.35,
      -46.569,
      20.665,
      -30.35,
      -46.569,
      20.665,
      -30.35,
      -46.569,
      17.67,
      -28.464,
      -46.038,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4222_M0010",
    polygon: [
      -31.712,
      -46.953,
      17.67,
      -31.712,
      -46.953,
      20.665,
      -33.599,
      -47.484,
      20.665,
      -33.599,
      -47.484,
      20.665,
      -33.599,
      -47.484,
      17.67,
      -31.712,
      -46.953,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4222_M0011",
    polygon: [
      -33.661,
      -47.502,
      17.67,
      -33.661,
      -47.502,
      20.665,
      -35.548,
      -48.033,
      20.665,
      -35.548,
      -48.033,
      20.665,
      -35.548,
      -48.033,
      17.67,
      -33.661,
      -47.502,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4222_M0012",
    polygon: [
      -36.146,
      -47.674,
      17.67,
      -36.146,
      -47.674,
      20.665,
      -36.664,
      -45.836,
      20.665,
      -36.664,
      -45.836,
      20.665,
      -36.664,
      -45.836,
      17.67,
      -36.146,
      -47.674,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "LE200xxF04O_EZR4222_M0013",
    polygon: [
      -36.682,
      -45.773,
      17.67,
      -36.682,
      -45.773,
      20.665,
      -37.199,
      -43.935,
      20.665,
      -37.199,
      -43.935,
      20.665,
      -37.199,
      -43.935,
      17.67,
      -36.682,
      -45.773,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE200xxF04O_EZR4223_M0010",
    polygon: [
      -37.608,
      -42.528,
      17.67,
      -37.608,
      -42.528,
      20.665,
      -38.126,
      -40.69,
      20.665,
      -38.126,
      -40.69,
      20.665,
      -38.126,
      -40.69,
      17.67,
      -37.608,
      -42.528,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4223_M0011",
    polygon: [
      -38.144,
      -40.627,
      17.67,
      -38.144,
      -40.627,
      20.665,
      -38.662,
      -38.789,
      20.665,
      -38.662,
      -38.789,
      20.665,
      -38.662,
      -38.789,
      17.67,
      -38.144,
      -40.627,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4224_M0010",
    polygon: [
      -39.047,
      -37.404,
      17.67,
      -39.047,
      -37.404,
      20.665,
      -39.564,
      -35.565,
      20.665,
      -39.564,
      -35.565,
      20.665,
      -39.564,
      -35.565,
      17.67,
      -39.047,
      -37.404,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF04O_EZR4224_M0011",
    polygon: [
      -39.582,
      -35.503,
      17.67,
      -39.582,
      -35.503,
      20.665,
      -40.1,
      -33.664,
      20.665,
      -40.1,
      -33.664,
      20.665,
      -40.1,
      -33.664,
      17.67,
      -39.582,
      -35.503,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF04O_EZR4224_M0012",
    polygon: [
      -39.767,
      -33.067,
      17.67,
      -39.767,
      -33.067,
      20.665,
      -37.883,
      -32.536,
      20.665,
      -37.883,
      -32.536,
      20.665,
      -37.883,
      -32.536,
      17.67,
      -39.767,
      -33.067,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "LE200xxF04O_EZR4224_M0013",
    polygon: [
      -37.821,
      -32.518,
      17.67,
      -37.821,
      -32.518,
      20.665,
      -35.936,
      -31.988,
      20.665,
      -35.936,
      -31.988,
      20.665,
      -35.936,
      -31.988,
      17.67,
      -37.821,
      -32.518,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF04O_EZR4225_M0010",
    polygon: [
      -34.57,
      -31.603,
      17.67,
      -34.57,
      -31.603,
      20.665,
      -32.685,
      -31.072,
      20.665,
      -32.685,
      -31.072,
      20.665,
      -32.685,
      -31.072,
      17.67,
      -34.57,
      -31.603,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF04O_EZR4226_M0010",
    polygon: [
      -32.623,
      -31.054,
      17.67,
      -32.623,
      -31.054,
      20.665,
      -30.739,
      -30.523,
      20.665,
      -30.739,
      -30.523,
      20.665,
      -30.739,
      -30.523,
      17.67,
      -32.623,
      -31.054,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF04O_EZR4227_M0010",
    polygon: [
      -29.374,
      -30.139,
      17.67,
      -29.374,
      -30.139,
      20.665,
      -27.49,
      -29.608,
      20.665,
      -27.49,
      -29.608,
      20.665,
      -27.49,
      -29.608,
      17.67,
      -29.374,
      -30.139,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF04O_EZR4228_M0010",
    polygon: [
      -27.428,
      -29.591,
      17.67,
      -27.428,
      -29.591,
      20.665,
      -25.543,
      -29.06,
      20.665,
      -25.543,
      -29.06,
      20.665,
      -25.543,
      -29.06,
      17.67,
      -27.428,
      -29.591,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4229_M0010",
    polygon: [
      -24.177,
      -28.675,
      17.67,
      -24.177,
      -28.675,
      20.665,
      -21.816,
      -28.01,
      20.665,
      -21.816,
      -28.01,
      20.665,
      -21.816,
      -28.01,
      17.67,
      -24.177,
      -28.675,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4230_M0010",
    polygon: [
      -21.574,
      -26.947,
      17.67,
      -21.574,
      -26.947,
      20.665,
      -22.056,
      -25.236,
      20.665,
      -22.056,
      -25.236,
      20.665,
      -22.056,
      -25.236,
      17.67,
      -21.574,
      -26.947,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
  },
  {
    ID: "LE200xxF04O_EZR4231_M0010",
    polygon: [
      -22.074,
      -25.174,
      17.67,
      -22.074,
      -25.174,
      20.665,
      -22.556,
      -23.463,
      20.665,
      -22.556,
      -23.463,
      20.665,
      -22.556,
      -23.463,
      17.67,
      -22.074,
      -25.174,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "LE200xxF04O_EZR4231_M0011",
    polygon: [
      -22.939,
      -22.101,
      17.67,
      -22.939,
      -22.101,
      20.665,
      -23.421,
      -20.39,
      20.665,
      -23.421,
      -20.39,
      20.665,
      -23.421,
      -20.39,
      17.67,
      -22.939,
      -22.101,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4232_M0010",
    polygon: [
      -23.439,
      -20.327,
      17.67,
      -23.439,
      -20.327,
      20.665,
      -23.921,
      -18.616,
      20.665,
      -23.921,
      -18.616,
      20.665,
      -23.921,
      -18.616,
      17.67,
      -23.439,
      -20.327,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4232_M0011",
    polygon: [
      -24.306,
      -17.25,
      17.67,
      -24.306,
      -17.25,
      20.665,
      -24.788,
      -15.539,
      20.665,
      -24.788,
      -15.539,
      20.665,
      -24.788,
      -15.539,
      17.67,
      -24.306,
      -17.25,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF04O_EZR4233_M0010",
    polygon: [
      -25.671,
      -12.403,
      17.67,
      -25.671,
      -12.403,
      20.665,
      -26.153,
      -10.692,
      20.665,
      -26.153,
      -10.692,
      20.665,
      -26.153,
      -10.692,
      17.67,
      -25.671,
      -12.403,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
  },
  {
    ID: "LE200xxF04O_EZR4233_M0011",
    polygon: [
      -24.805,
      -15.476,
      17.67,
      -24.805,
      -15.476,
      20.665,
      -25.287,
      -13.765,
      20.665,
      -25.287,
      -13.765,
      20.665,
      -25.287,
      -13.765,
      17.67,
      -24.805,
      -15.476,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4234_M0010",
    polygon: [
      -5.686,
      -10.163,
      17.67,
      -5.686,
      -10.163,
      20.665,
      -5.204,
      -11.874,
      20.665,
      -5.204,
      -11.874,
      20.665,
      -5.204,
      -11.874,
      17.67,
      -5.686,
      -10.163,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4235_M0010",
    polygon: [
      -4.819,
      -13.241,
      17.67,
      -4.819,
      -13.241,
      20.665,
      -4.337,
      -14.952,
      20.665,
      -4.337,
      -14.952,
      20.665,
      -4.337,
      -14.952,
      17.67,
      -4.819,
      -13.241,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4236_M0010",
    polygon: [
      -4.319,
      -15.014,
      17.67,
      -4.319,
      -15.014,
      20.665,
      -3.837,
      -16.725,
      20.665,
      -3.837,
      -16.725,
      20.665,
      -3.837,
      -16.725,
      17.67,
      -4.319,
      -15.014,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4237_M0010",
    polygon: [
      -2.954,
      -19.861,
      17.67,
      -2.954,
      -19.861,
      20.665,
      -2.472,
      -21.572,
      20.665,
      -2.472,
      -21.572,
      20.665,
      -2.472,
      -21.572,
      17.67,
      -2.954,
      -19.861,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "LE200xxF04O_EZR4237_M0011",
    polygon: [
      -3.454,
      -18.087,
      17.67,
      -3.454,
      -18.087,
      20.665,
      -2.972,
      -19.798,
      20.665,
      -2.972,
      -19.798,
      20.665,
      -2.972,
      -19.798,
      17.67,
      -3.454,
      -18.087,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
  },
  {
    ID: "LE200xxF04O_EZR4251_M0010",
    polygon: [
      -1.718,
      -22.349,
      17.67,
      -1.718,
      -22.349,
      20.665,
      0.643,
      -21.684,
      20.665,
      0.643,
      -21.684,
      20.665,
      0.643,
      -21.684,
      17.67,
      -1.718,
      -22.349,
      17.67,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4301_M0010",
    polygon: [
      -18.951,
      -59.669,
      17.665,
      -18.951,
      -59.669,
      20.66,
      -17.067,
      -59.138,
      20.66,
      -17.067,
      -59.138,
      20.66,
      -17.067,
      -59.138,
      17.665,
      -18.951,
      -59.669,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "LE200xxF04O_EZR4302_M0010",
    polygon: [
      -20.898,
      -60.217,
      17.665,
      -20.898,
      -60.217,
      20.66,
      -19.013,
      -59.687,
      20.66,
      -19.013,
      -59.687,
      20.66,
      -19.013,
      -59.687,
      17.665,
      -20.898,
      -60.217,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
  },
  {
    ID: "LE200xxF04O_EZR4303_M0010",
    polygon: [
      -24.149,
      -61.133,
      17.665,
      -24.149,
      -61.133,
      20.66,
      -22.264,
      -60.602,
      20.66,
      -22.264,
      -60.602,
      20.66,
      -22.264,
      -60.602,
      17.665,
      -24.149,
      -61.133,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
  },
  {
    ID: "LE200xxF04O_EZR4304_M0010",
    polygon: [
      -26.095,
      -61.682,
      17.665,
      -26.095,
      -61.682,
      20.66,
      -24.211,
      -61.151,
      20.66,
      -24.211,
      -61.151,
      20.66,
      -24.211,
      -61.151,
      17.665,
      -26.095,
      -61.682,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
  },
  {
    ID: "LE200xxF04O_EZR4305_M0010",
    polygon: [
      -30.567,
      -67.481,
      17.67,
      -30.567,
      -67.481,
      20.665,
      -31.085,
      -65.643,
      20.665,
      -31.085,
      -65.643,
      20.665,
      -31.085,
      -65.643,
      17.67,
      -30.567,
      -67.481,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "LE200xxF04O_EZR4305_M0011",
    polygon: [
      -31.102,
      -65.58,
      17.67,
      -31.102,
      -65.58,
      20.665,
      -31.62,
      -63.742,
      20.665,
      -31.62,
      -63.742,
      20.665,
      -31.62,
      -63.742,
      17.67,
      -31.102,
      -65.58,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "LE200xxF04O_EZR4305_M0012",
    polygon: [
      -31.293,
      -63.146,
      17.665,
      -31.293,
      -63.146,
      20.66,
      -29.409,
      -62.615,
      20.66,
      -29.409,
      -62.615,
      20.66,
      -29.409,
      -62.615,
      17.665,
      -31.293,
      -63.146,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
  },
  {
    ID: "LE200xxF04O_EZR4305_M0013",
    polygon: [
      -29.346,
      -62.597,
      17.665,
      -29.346,
      -62.597,
      20.66,
      -27.462,
      -62.067,
      20.66,
      -27.462,
      -62.067,
      20.66,
      -27.462,
      -62.067,
      17.665,
      -29.346,
      -62.597,
      17.665,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "LE200xxF04O_EZR4306_M0010",
    polygon: [
      -29.135,
      -72.608,
      17.67,
      -29.135,
      -72.608,
      20.665,
      -29.653,
      -70.769,
      20.665,
      -29.653,
      -70.769,
      20.665,
      -29.653,
      -70.769,
      17.67,
      -29.135,
      -72.608,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE200xxF04O_EZR4306_M0011",
    polygon: [
      -29.671,
      -70.707,
      17.67,
      -29.671,
      -70.707,
      20.665,
      -30.189,
      -68.868,
      20.665,
      -30.189,
      -68.868,
      20.665,
      -30.189,
      -68.868,
      17.67,
      -29.671,
      -70.707,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "LE200xxF04O_EZR4307_M0010",
    polygon: [
      -23.243,
      -77.027,
      17.67,
      -23.243,
      -77.027,
      20.665,
      -25.129,
      -77.559,
      20.665,
      -25.129,
      -77.559,
      20.665,
      -25.129,
      -77.559,
      17.67,
      -23.243,
      -77.027,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4307_M0011",
    polygon: [
      -25.189,
      -77.576,
      17.67,
      -25.189,
      -77.576,
      20.665,
      -27.076,
      -78.107,
      20.665,
      -27.076,
      -78.107,
      20.665,
      -27.076,
      -78.107,
      17.67,
      -25.189,
      -77.576,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF04O_EZR4307_M0012",
    polygon: [
      -27.68,
      -77.756,
      17.67,
      -27.68,
      -77.756,
      20.665,
      -28.198,
      -75.917,
      20.665,
      -28.198,
      -75.917,
      20.665,
      -28.198,
      -75.917,
      17.67,
      -27.68,
      -77.756,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "LE200xxF04O_EZR4307_M0013",
    polygon: [
      -28.215,
      -75.855,
      17.67,
      -28.215,
      -75.855,
      20.665,
      -28.733,
      -74.016,
      20.665,
      -28.733,
      -74.016,
      20.665,
      -28.733,
      -74.016,
      17.67,
      -28.215,
      -75.855,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE200xxF04O_EZR4308_M0010",
    polygon: [
      -19.991,
      -76.112,
      17.67,
      -19.991,
      -76.112,
      20.665,
      -21.878,
      -76.643,
      20.665,
      -21.878,
      -76.643,
      20.665,
      -21.878,
      -76.643,
      17.67,
      -19.991,
      -76.112,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4309_M0010",
    polygon: [
      -18.044,
      -75.563,
      17.67,
      -18.044,
      -75.563,
      20.665,
      -19.931,
      -76.095,
      20.665,
      -19.931,
      -76.095,
      20.665,
      -19.931,
      -76.095,
      17.67,
      -18.044,
      -75.563,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4310_M0010",
    polygon: [
      -14.794,
      -74.648,
      17.67,
      -14.794,
      -74.648,
      20.665,
      -16.68,
      -75.179,
      20.665,
      -16.68,
      -75.179,
      20.665,
      -16.68,
      -75.179,
      17.67,
      -14.794,
      -74.648,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4311_M0010",
    polygon: [
      -12.847,
      -74.099,
      17.67,
      -12.847,
      -74.099,
      20.665,
      -14.734,
      -74.631,
      20.665,
      -14.734,
      -74.631,
      20.665,
      -14.734,
      -74.631,
      17.67,
      -12.847,
      -74.099,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4312_M0010",
    polygon: [
      -9.596,
      -73.183,
      17.67,
      -9.596,
      -73.183,
      20.665,
      -11.482,
      -73.715,
      20.665,
      -11.482,
      -73.715,
      20.665,
      -11.482,
      -73.715,
      17.67,
      -9.596,
      -73.183,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4313_M0010",
    polygon: [
      -7.649,
      -72.635,
      17.67,
      -7.649,
      -72.635,
      20.665,
      -9.535,
      -73.166,
      20.665,
      -9.535,
      -73.166,
      20.665,
      -9.535,
      -73.166,
      17.67,
      -7.649,
      -72.635,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4314_M0010",
    polygon: [
      -4.398,
      -71.719,
      17.67,
      -4.398,
      -71.719,
      20.665,
      -6.284,
      -72.251,
      20.665,
      -6.284,
      -72.251,
      20.665,
      -6.284,
      -72.251,
      17.67,
      -4.398,
      -71.719,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4315_M0010",
    polygon: [
      -2.451,
      -71.171,
      17.67,
      -2.451,
      -71.171,
      20.665,
      -4.338,
      -71.702,
      20.665,
      -4.338,
      -71.702,
      20.665,
      -4.338,
      -71.702,
      17.67,
      -2.451,
      -71.171,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4316_M0010",
    polygon: [
      0.799,
      -70.255,
      17.67,
      0.799,
      -70.255,
      20.665,
      -1.087,
      -70.787,
      20.665,
      -1.087,
      -70.787,
      20.665,
      -1.087,
      -70.787,
      17.67,
      0.799,
      -70.255,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4317_M0010",
    polygon: [
      2.746,
      -69.707,
      17.67,
      2.746,
      -69.707,
      20.665,
      0.86,
      -70.238,
      20.665,
      0.86,
      -70.238,
      20.665,
      0.86,
      -70.238,
      17.67,
      2.746,
      -69.707,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4318_M0010",
    polygon: [
      5.998,
      -68.791,
      17.67,
      5.998,
      -68.791,
      20.665,
      4.111,
      -69.322,
      20.665,
      4.111,
      -69.322,
      20.665,
      4.111,
      -69.322,
      17.67,
      5.998,
      -68.791,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4319_M0010",
    polygon: [
      7.944,
      -68.243,
      17.67,
      7.944,
      -68.243,
      20.665,
      6.058,
      -68.774,
      20.665,
      6.058,
      -68.774,
      20.665,
      6.058,
      -68.774,
      17.67,
      7.944,
      -68.243,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4320_M0010",
    polygon: [
      11.197,
      -67.332,
      17.67,
      11.197,
      -67.332,
      20.665,
      9.31,
      -67.864,
      20.665,
      9.31,
      -67.864,
      20.665,
      9.31,
      -67.864,
      17.67,
      11.197,
      -67.332,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF04O_EZR4320_M0011",
    polygon: [
      13.143,
      -66.784,
      17.67,
      13.143,
      -66.784,
      20.665,
      11.257,
      -67.315,
      20.665,
      11.257,
      -67.315,
      20.665,
      11.257,
      -67.315,
      17.67,
      13.143,
      -66.784,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF04O_EZR4320_M0012",
    polygon: [
      12.957,
      -64.348,
      17.67,
      12.957,
      -64.348,
      20.665,
      13.475,
      -66.187,
      20.665,
      13.475,
      -66.187,
      20.665,
      13.475,
      -66.187,
      17.67,
      12.957,
      -64.348,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4320_M0013",
    polygon: [
      12.421,
      -62.447,
      17.67,
      12.421,
      -62.447,
      20.665,
      12.939,
      -64.286,
      20.665,
      12.939,
      -64.286,
      20.665,
      12.939,
      -64.286,
      17.67,
      12.421,
      -62.447,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF04O_EZR4321_M0010",
    polygon: [
      11.514,
      -59.225,
      17.67,
      11.514,
      -59.225,
      20.665,
      12.032,
      -61.063,
      20.665,
      12.032,
      -61.063,
      20.665,
      12.032,
      -61.063,
      17.67,
      11.514,
      -59.225,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF04O_EZR4321_M0011",
    polygon: [
      10.978,
      -57.324,
      17.67,
      10.978,
      -57.324,
      20.665,
      11.496,
      -59.162,
      20.665,
      11.496,
      -59.162,
      20.665,
      11.496,
      -59.162,
      17.67,
      10.978,
      -57.324,
      17.67,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5101_M0010",
    polygon: [
      -4.957,
      -1.815,
      21.17,
      -4.957,
      -1.815,
      24.165,
      -7.318,
      -2.48,
      24.165,
      -7.318,
      -2.48,
      24.165,
      -7.318,
      -2.48,
      21.17,
      -4.957,
      -1.815,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
  },
  {
    ID: "LE200xxF05O_EZR5102_M0010",
    polygon: [
      -1.71,
      -0.9,
      21.17,
      -1.71,
      -0.9,
      24.165,
      -3.594,
      -1.431,
      24.165,
      -3.594,
      -1.431,
      24.165,
      -3.594,
      -1.431,
      21.17,
      -1.71,
      -0.9,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF05O_EZR5103_M0010",
    polygon: [
      0.237,
      -0.352,
      21.17,
      0.237,
      -0.352,
      24.165,
      -1.648,
      -0.883,
      24.165,
      -1.648,
      -0.883,
      24.165,
      -1.648,
      -0.883,
      21.17,
      0.237,
      -0.352,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5104_M0010",
    polygon: [
      3.485,
      0.563,
      21.17,
      3.485,
      0.563,
      24.165,
      1.601,
      0.032,
      24.165,
      1.601,
      0.032,
      24.165,
      1.601,
      0.032,
      21.17,
      3.485,
      0.563,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF05O_EZR5105_M0010",
    polygon: [
      5.432,
      1.112,
      21.17,
      5.432,
      1.112,
      24.165,
      3.548,
      0.581,
      24.165,
      3.548,
      0.581,
      24.165,
      3.548,
      0.581,
      21.17,
      5.432,
      1.112,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5106_M0010",
    polygon: [
      8.683,
      2.027,
      21.17,
      8.683,
      2.027,
      24.165,
      6.799,
      1.497,
      24.165,
      6.799,
      1.497,
      24.165,
      6.799,
      1.497,
      21.17,
      8.683,
      2.027,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF05O_EZR5106_M0011",
    polygon: [
      10.63,
      2.576,
      21.17,
      10.63,
      2.576,
      24.165,
      8.745,
      2.045,
      24.165,
      8.745,
      2.045,
      24.165,
      8.745,
      2.045,
      21.17,
      10.63,
      2.576,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5106_M0012",
    polygon: [
      10.458,
      5.012,
      21.17,
      10.458,
      5.012,
      24.165,
      10.975,
      3.174,
      24.165,
      10.975,
      3.174,
      24.165,
      10.975,
      3.174,
      21.17,
      10.458,
      5.012,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5106_M0013",
    polygon: [
      9.922,
      6.913,
      21.17,
      9.922,
      6.913,
      24.165,
      10.44,
      5.075,
      24.165,
      10.44,
      5.075,
      24.165,
      10.44,
      5.075,
      21.17,
      9.922,
      6.913,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5107_M0010",
    polygon: [
      9.014,
      10.135,
      21.17,
      9.014,
      10.135,
      24.165,
      9.532,
      8.297,
      24.165,
      9.532,
      8.297,
      24.165,
      9.532,
      8.297,
      21.17,
      9.014,
      10.135,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5107_M0011",
    polygon: [
      8.479,
      12.036,
      21.17,
      8.479,
      12.036,
      24.165,
      8.997,
      10.198,
      24.165,
      8.997,
      10.198,
      24.165,
      8.997,
      10.198,
      21.17,
      8.479,
      12.036,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5108_M0010",
    polygon: [
      7.564,
      15.285,
      21.17,
      7.564,
      15.285,
      24.165,
      8.082,
      13.446,
      24.165,
      8.082,
      13.446,
      24.165,
      8.082,
      13.446,
      21.17,
      7.564,
      15.285,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5108_M0011",
    polygon: [
      7.028,
      17.186,
      21.17,
      7.028,
      17.186,
      24.165,
      7.546,
      15.348,
      24.165,
      7.546,
      15.348,
      24.165,
      7.546,
      15.348,
      21.17,
      7.028,
      17.186,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5108_M0012",
    polygon: [
      4.543,
      17.013,
      21.17,
      4.543,
      17.013,
      24.165,
      6.427,
      17.544,
      24.165,
      6.427,
      17.544,
      24.165,
      6.427,
      17.544,
      21.17,
      4.543,
      17.013,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5108_M0013",
    polygon: [
      2.596,
      16.465,
      21.17,
      2.596,
      16.465,
      24.165,
      4.48,
      16.995,
      24.165,
      4.48,
      16.995,
      24.165,
      4.48,
      16.995,
      21.17,
      2.596,
      16.465,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5109_M0010",
    polygon: [
      -0.653,
      15.55,
      21.17,
      -0.653,
      15.55,
      24.165,
      1.232,
      16.08,
      24.165,
      1.232,
      16.08,
      24.165,
      1.232,
      16.08,
      21.17,
      -0.653,
      15.55,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5110_M0010",
    polygon: [
      -2.599,
      15.001,
      21.17,
      -2.599,
      15.001,
      24.165,
      -0.715,
      15.532,
      24.165,
      -0.715,
      15.532,
      24.165,
      -0.715,
      15.532,
      21.17,
      -2.599,
      15.001,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5111_M0010",
    polygon: [
      -5.85,
      14.086,
      21.17,
      -5.85,
      14.086,
      24.165,
      -3.966,
      14.616,
      24.165,
      -3.966,
      14.616,
      24.165,
      -3.966,
      14.616,
      21.17,
      -5.85,
      14.086,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5112_M0010",
    polygon: [
      -7.797,
      13.537,
      21.17,
      -7.797,
      13.537,
      24.165,
      -5.913,
      14.068,
      24.165,
      -5.913,
      14.068,
      24.165,
      -5.913,
      14.068,
      21.17,
      -7.797,
      13.537,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5113_M0010",
    polygon: [
      -11.046,
      12.622,
      21.17,
      -11.046,
      12.622,
      24.165,
      -9.161,
      13.153,
      24.165,
      -9.161,
      13.153,
      24.165,
      -9.161,
      13.153,
      21.17,
      -11.046,
      12.622,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5114_M0010",
    polygon: [
      -12.992,
      12.074,
      21.17,
      -12.992,
      12.074,
      24.165,
      -11.108,
      12.604,
      24.165,
      -11.108,
      12.604,
      24.165,
      -11.108,
      12.604,
      21.17,
      -12.992,
      12.074,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5115_M0010",
    polygon: [
      -16.243,
      11.158,
      21.17,
      -16.243,
      11.158,
      24.165,
      -14.359,
      11.689,
      24.165,
      -14.359,
      11.689,
      24.165,
      -14.359,
      11.689,
      21.17,
      -16.243,
      11.158,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5116_M0010",
    polygon: [
      -18.19,
      10.61,
      21.17,
      -18.19,
      10.61,
      24.165,
      -16.306,
      11.14,
      24.165,
      -16.306,
      11.14,
      24.165,
      -16.306,
      11.14,
      21.17,
      -18.19,
      10.61,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5116_M0011",
    polygon: [
      -18.262,
      9.047,
      21.17,
      -18.262,
      9.047,
      24.165,
      -18.533,
      10.009,
      24.165,
      -18.533,
      10.009,
      24.165,
      -18.533,
      10.009,
      21.17,
      -18.262,
      9.047,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5117_M0010",
    polygon: [
      -21.0,
      7.74,
      21.17,
      -21.0,
      7.74,
      24.165,
      -19.036,
      8.293,
      24.165,
      -19.036,
      8.293,
      24.165,
      -19.036,
      8.293,
      21.17,
      -21.0,
      7.74,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF05O_EZR5118_M0010",
    polygon: [
      -23.026,
      7.17,
      21.17,
      -23.026,
      7.17,
      24.165,
      -21.062,
      7.723,
      24.165,
      -21.062,
      7.723,
      24.165,
      -21.062,
      7.723,
      21.17,
      -23.026,
      7.17,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF05O_EZR5119_M0010",
    polygon: [
      -26.836,
      8.174,
      21.17,
      -26.836,
      8.174,
      24.165,
      -24.952,
      8.705,
      24.165,
      -24.952,
      8.705,
      24.165,
      -24.952,
      8.705,
      21.17,
      -26.836,
      8.174,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5119_M0011",
    polygon: [
      -24.357,
      8.369,
      21.17,
      -24.357,
      8.369,
      24.165,
      -24.085,
      7.406,
      24.165,
      -24.085,
      7.406,
      24.165,
      -24.085,
      7.406,
      21.17,
      -24.357,
      8.369,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF05O_EZR5120_M0010",
    polygon: [
      -28.783,
      7.626,
      21.17,
      -28.783,
      7.626,
      24.165,
      -26.899,
      8.157,
      24.165,
      -26.899,
      8.157,
      24.165,
      -26.899,
      8.157,
      21.17,
      -28.783,
      7.626,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5121_M0010",
    polygon: [
      -32.031,
      6.711,
      21.17,
      -32.031,
      6.711,
      24.165,
      -30.147,
      7.242,
      24.165,
      -30.147,
      7.242,
      24.165,
      -30.147,
      7.242,
      21.17,
      -32.031,
      6.711,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5122_M0010",
    polygon: [
      -33.978,
      6.162,
      21.17,
      -33.978,
      6.162,
      24.165,
      -32.094,
      6.693,
      24.165,
      -32.094,
      6.693,
      24.165,
      -32.094,
      6.693,
      21.17,
      -33.978,
      6.162,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5123_M0010",
    polygon: [
      -37.229,
      5.247,
      21.17,
      -37.229,
      5.247,
      24.165,
      -35.345,
      5.777,
      24.165,
      -35.345,
      5.777,
      24.165,
      -35.345,
      5.777,
      21.17,
      -37.229,
      5.247,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5124_M0010",
    polygon: [
      -39.176,
      4.698,
      21.17,
      -39.176,
      4.698,
      24.165,
      -37.292,
      5.229,
      24.165,
      -37.292,
      5.229,
      24.165,
      -37.292,
      5.229,
      21.17,
      -39.176,
      4.698,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5125_M0010",
    polygon: [
      -42.424,
      3.783,
      21.17,
      -42.424,
      3.783,
      24.165,
      -40.54,
      4.314,
      24.165,
      -40.54,
      4.314,
      24.165,
      -40.54,
      4.314,
      21.17,
      -42.424,
      3.783,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5126_M0010",
    polygon: [
      -44.371,
      3.235,
      21.17,
      -44.371,
      3.235,
      24.165,
      -42.487,
      3.766,
      24.165,
      -42.487,
      3.766,
      24.165,
      -42.487,
      3.766,
      21.17,
      -44.371,
      3.235,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5127_M0010",
    polygon: [
      -47.622,
      2.319,
      21.17,
      -47.622,
      2.319,
      24.165,
      -45.738,
      2.85,
      24.165,
      -45.738,
      2.85,
      24.165,
      -45.738,
      2.85,
      21.17,
      -47.622,
      2.319,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5127_M0011",
    polygon: [
      -49.569,
      1.771,
      21.17,
      -49.569,
      1.771,
      24.165,
      -47.685,
      2.301,
      24.165,
      -47.685,
      2.301,
      24.165,
      -47.685,
      2.301,
      21.17,
      -49.569,
      1.771,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5127_M0012",
    polygon: [
      -49.394,
      -0.668,
      21.17,
      -49.394,
      -0.668,
      24.165,
      -49.912,
      1.17,
      24.165,
      -49.912,
      1.17,
      24.165,
      -49.912,
      1.17,
      21.17,
      -49.394,
      -0.668,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5127_M0013",
    polygon: [
      -48.859,
      -2.569,
      21.17,
      -48.859,
      -2.569,
      24.165,
      -49.377,
      -0.731,
      24.165,
      -49.377,
      -0.731,
      24.165,
      -49.377,
      -0.731,
      21.17,
      -48.859,
      -2.569,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5128_M0010",
    polygon: [
      -47.951,
      -5.791,
      21.17,
      -47.951,
      -5.791,
      24.165,
      -48.469,
      -3.953,
      24.165,
      -48.469,
      -3.953,
      24.165,
      -48.469,
      -3.953,
      21.17,
      -47.951,
      -5.791,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5128_M0011",
    polygon: [
      -47.416,
      -7.692,
      21.17,
      -47.416,
      -7.692,
      24.165,
      -47.933,
      -5.854,
      24.165,
      -47.933,
      -5.854,
      24.165,
      -47.933,
      -5.854,
      21.17,
      -47.416,
      -7.692,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5129_M0010",
    polygon: [
      -46.501,
      -10.941,
      21.17,
      -46.501,
      -10.941,
      24.165,
      -47.018,
      -9.102,
      24.165,
      -47.018,
      -9.102,
      24.165,
      -47.018,
      -9.102,
      21.17,
      -46.501,
      -10.941,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5129_M0011",
    polygon: [
      -45.965,
      -12.842,
      21.17,
      -45.965,
      -12.842,
      24.165,
      -46.483,
      -11.004,
      24.165,
      -46.483,
      -11.004,
      24.165,
      -46.483,
      -11.004,
      21.17,
      -45.965,
      -12.842,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "LE200xxF05O_EZR5129_M0012",
    polygon: [
      -43.482,
      -12.667,
      21.17,
      -43.482,
      -12.667,
      24.165,
      -45.366,
      -13.197,
      24.165,
      -45.366,
      -13.197,
      24.165,
      -45.366,
      -13.197,
      21.17,
      -43.482,
      -12.667,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF05O_EZR5129_M0013",
    polygon: [
      -41.535,
      -12.118,
      21.17,
      -41.535,
      -12.118,
      24.165,
      -43.419,
      -12.649,
      24.165,
      -43.419,
      -12.649,
      24.165,
      -43.419,
      -12.649,
      21.17,
      -41.535,
      -12.118,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5130_M0010",
    polygon: [
      -38.287,
      -11.203,
      21.17,
      -38.287,
      -11.203,
      24.165,
      -40.171,
      -11.734,
      24.165,
      -40.171,
      -11.734,
      24.165,
      -40.171,
      -11.734,
      21.17,
      -38.287,
      -11.203,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF05O_EZR5131_M0010",
    polygon: [
      -36.34,
      -10.655,
      21.17,
      -36.34,
      -10.655,
      24.165,
      -38.224,
      -11.186,
      24.165,
      -38.224,
      -11.186,
      24.165,
      -38.224,
      -11.186,
      21.17,
      -36.34,
      -10.655,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5132_M0010",
    polygon: [
      -33.089,
      -9.739,
      21.17,
      -33.089,
      -9.739,
      24.165,
      -34.973,
      -10.27,
      24.165,
      -34.973,
      -10.27,
      24.165,
      -34.973,
      -10.27,
      21.17,
      -33.089,
      -9.739,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF05O_EZR5133_M0010",
    polygon: [
      -31.142,
      -9.191,
      21.17,
      -31.142,
      -9.191,
      24.165,
      -33.026,
      -9.722,
      24.165,
      -33.026,
      -9.722,
      24.165,
      -33.026,
      -9.722,
      21.17,
      -31.142,
      -9.191,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5134_M0010",
    polygon: [
      -27.416,
      -8.141,
      21.17,
      -27.416,
      -8.141,
      24.165,
      -29.776,
      -8.806,
      24.165,
      -29.776,
      -8.806,
      24.165,
      -29.776,
      -8.806,
      21.17,
      -27.416,
      -8.141,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF05O_EZR5135_M0010",
    polygon: [
      -26.17,
      -10.63,
      21.17,
      -26.17,
      -10.63,
      24.165,
      -26.652,
      -8.919,
      24.165,
      -26.652,
      -8.919,
      24.165,
      -26.652,
      -8.919,
      21.17,
      -26.17,
      -10.63,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5149_M0010",
    polygon: [
      -7.55,
      -3.543,
      21.17,
      -7.55,
      -3.543,
      24.165,
      -7.068,
      -5.254,
      24.165,
      -7.068,
      -5.254,
      24.165,
      -7.068,
      -5.254,
      21.17,
      -7.55,
      -3.543,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5149_M0011",
    polygon: [
      -7.051,
      -5.317,
      21.17,
      -7.051,
      -5.317,
      24.165,
      -6.569,
      -7.028,
      24.165,
      -6.569,
      -7.028,
      24.165,
      -6.569,
      -7.028,
      21.17,
      -7.051,
      -5.317,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5149_M0012",
    polygon: [
      -6.185,
      -8.389,
      21.17,
      -6.185,
      -8.389,
      24.165,
      -5.703,
      -10.1,
      24.165,
      -5.703,
      -10.1,
      24.165,
      -5.703,
      -10.1,
      21.17,
      -6.185,
      -8.389,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5201_M0010",
    polygon: [
      2.009,
      -21.299,
      21.17,
      2.009,
      -21.299,
      24.165,
      3.894,
      -20.768,
      24.165,
      3.894,
      -20.768,
      24.165,
      3.894,
      -20.768,
      21.17,
      2.009,
      -21.299,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF05O_EZR5202_M0010",
    polygon: [
      3.956,
      -20.75,
      21.17,
      3.956,
      -20.75,
      24.165,
      5.84,
      -20.22,
      24.165,
      5.84,
      -20.22,
      24.165,
      5.84,
      -20.22,
      21.17,
      3.956,
      -20.75,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "LE200xxF05O_EZR5203_M0010",
    polygon: [
      7.207,
      -19.835,
      21.17,
      7.207,
      -19.835,
      24.165,
      9.091,
      -19.304,
      24.165,
      9.091,
      -19.304,
      24.165,
      9.091,
      -19.304,
      21.17,
      7.207,
      -19.835,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
  },
  {
    ID: "LE200xxF05O_EZR5204_M0010",
    polygon: [
      9.154,
      -19.286,
      21.17,
      9.154,
      -19.286,
      24.165,
      11.038,
      -18.756,
      24.165,
      11.038,
      -18.756,
      24.165,
      11.038,
      -18.756,
      21.17,
      9.154,
      -19.286,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
  },
  {
    ID: "LE200xxF05O_EZR5205_M0010",
    polygon: [
      12.402,
      -18.371,
      21.17,
      12.402,
      -18.371,
      24.165,
      14.287,
      -17.841,
      24.165,
      14.287,
      -17.841,
      24.165,
      14.287,
      -17.841,
      21.17,
      12.402,
      -18.371,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF05O_EZR5205_M0011",
    polygon: [
      14.349,
      -17.823,
      21.17,
      14.349,
      -17.823,
      24.165,
      16.233,
      -17.292,
      24.165,
      16.233,
      -17.292,
      24.165,
      16.233,
      -17.292,
      21.17,
      14.349,
      -17.823,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5205_M0012",
    polygon: [
      16.838,
      -17.649,
      21.17,
      16.838,
      -17.649,
      24.165,
      17.356,
      -19.488,
      24.165,
      17.356,
      -19.488,
      24.165,
      17.356,
      -19.488,
      21.17,
      16.838,
      -17.649,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5205_M0013",
    polygon: [
      17.373,
      -19.55,
      21.17,
      17.373,
      -19.55,
      24.165,
      17.891,
      -21.389,
      24.165,
      17.891,
      -21.389,
      24.165,
      17.891,
      -21.389,
      21.17,
      17.373,
      -19.55,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5206_M0010",
    polygon: [
      18.288,
      -22.799,
      21.17,
      18.288,
      -22.799,
      24.165,
      18.806,
      -24.637,
      24.165,
      18.806,
      -24.637,
      24.165,
      18.806,
      -24.637,
      21.17,
      18.288,
      -22.799,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5206_M0011",
    polygon: [
      18.824,
      -24.7,
      21.17,
      18.824,
      -24.7,
      24.165,
      19.342,
      -26.538,
      24.165,
      19.342,
      -26.538,
      24.165,
      19.342,
      -26.538,
      21.17,
      18.824,
      -24.7,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5207_M0010",
    polygon: [
      19.731,
      -27.922,
      21.17,
      19.731,
      -27.922,
      24.165,
      20.249,
      -29.76,
      24.165,
      20.249,
      -29.76,
      24.165,
      20.249,
      -29.76,
      21.17,
      19.731,
      -27.922,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5207_M0011",
    polygon: [
      20.267,
      -29.823,
      21.17,
      20.267,
      -29.823,
      24.165,
      20.785,
      -31.661,
      24.165,
      20.785,
      -31.661,
      24.165,
      20.785,
      -31.661,
      21.17,
      20.267,
      -29.823,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5207_M0012",
    polygon: [
      20.458,
      -32.257,
      21.17,
      20.458,
      -32.257,
      24.165,
      18.571,
      -32.789,
      24.165,
      18.571,
      -32.789,
      24.165,
      18.571,
      -32.789,
      21.17,
      20.458,
      -32.257,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5207_M0013",
    polygon: [
      18.508,
      -32.806,
      21.17,
      18.508,
      -32.806,
      24.165,
      16.622,
      -33.338,
      24.165,
      16.622,
      -33.338,
      24.165,
      16.622,
      -33.338,
      21.17,
      18.508,
      -32.806,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF05O_EZR5208_M0010",
    polygon: [
      15.26,
      -33.722,
      21.17,
      15.26,
      -33.722,
      24.165,
      13.373,
      -34.253,
      24.165,
      13.373,
      -34.253,
      24.165,
      13.373,
      -34.253,
      21.17,
      15.26,
      -33.722,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5209_M0010",
    polygon: [
      13.311,
      -34.271,
      21.17,
      13.311,
      -34.271,
      24.165,
      11.424,
      -34.802,
      24.165,
      11.424,
      -34.802,
      24.165,
      11.424,
      -34.802,
      21.17,
      13.311,
      -34.271,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF05O_EZR5210_M0010",
    polygon: [
      10.062,
      -35.186,
      21.17,
      10.062,
      -35.186,
      24.165,
      8.176,
      -35.717,
      24.165,
      8.176,
      -35.717,
      24.165,
      8.176,
      -35.717,
      21.17,
      10.062,
      -35.186,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5211_M0010",
    polygon: [
      8.113,
      -35.735,
      21.17,
      8.113,
      -35.735,
      24.165,
      6.226,
      -36.266,
      24.165,
      6.226,
      -36.266,
      24.165,
      6.226,
      -36.266,
      21.17,
      8.113,
      -35.735,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF05O_EZR5212_M0010",
    polygon: [
      4.864,
      -36.65,
      21.17,
      4.864,
      -36.65,
      24.165,
      2.978,
      -37.181,
      24.165,
      2.978,
      -37.181,
      24.165,
      2.978,
      -37.181,
      21.17,
      4.864,
      -36.65,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5213_M0010",
    polygon: [
      2.915,
      -37.199,
      21.17,
      2.915,
      -37.199,
      24.165,
      1.029,
      -37.73,
      24.165,
      1.029,
      -37.73,
      24.165,
      1.029,
      -37.73,
      21.17,
      2.915,
      -37.199,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5214_M0010",
    polygon: [
      -0.333,
      -38.114,
      21.175,
      -0.333,
      -38.114,
      24.17,
      -2.694,
      -38.779,
      24.17,
      -2.694,
      -38.779,
      24.17,
      -2.694,
      -38.779,
      21.175,
      -0.333,
      -38.114,
      21.175,
    ],
    "04-15 02:05": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5215_M0010",
    polygon: [
      -12.396,
      -41.512,
      21.175,
      -12.396,
      -41.512,
      24.17,
      -14.757,
      -42.177,
      24.17,
      -14.757,
      -42.177,
      24.17,
      -14.757,
      -42.177,
      21.175,
      -12.396,
      -41.512,
      21.175,
    ],
    "04-15 02:05": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
  {
    ID: "LE200xxF05O_EZR5216_M0010",
    polygon: [
      -16.119,
      -42.56,
      21.17,
      -16.119,
      -42.56,
      24.165,
      -18.006,
      -43.092,
      24.165,
      -18.006,
      -43.092,
      24.165,
      -18.006,
      -43.092,
      21.17,
      -16.119,
      -42.56,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5217_M0010",
    polygon: [
      -18.068,
      -43.109,
      21.17,
      -18.068,
      -43.109,
      24.165,
      -19.955,
      -43.641,
      24.165,
      -19.955,
      -43.641,
      24.165,
      -19.955,
      -43.641,
      21.17,
      -18.068,
      -43.109,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF05O_EZR5218_M0010",
    polygon: [
      -21.317,
      -44.025,
      21.17,
      -21.317,
      -44.025,
      24.165,
      -23.203,
      -44.556,
      24.165,
      -23.203,
      -44.556,
      24.165,
      -23.203,
      -44.556,
      21.17,
      -21.317,
      -44.025,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5219_M0010",
    polygon: [
      -23.266,
      -44.574,
      21.17,
      -23.266,
      -44.574,
      24.165,
      -25.152,
      -45.105,
      24.165,
      -25.152,
      -45.105,
      24.165,
      -25.152,
      -45.105,
      21.17,
      -23.266,
      -44.574,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF05O_EZR5220_M0010",
    polygon: [
      -26.514,
      -45.489,
      21.17,
      -26.514,
      -45.489,
      24.165,
      -28.401,
      -46.02,
      24.165,
      -28.401,
      -46.02,
      24.165,
      -28.401,
      -46.02,
      21.17,
      -26.514,
      -45.489,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5221_M0010",
    polygon: [
      -28.464,
      -46.038,
      21.17,
      -28.464,
      -46.038,
      24.165,
      -30.35,
      -46.569,
      24.165,
      -30.35,
      -46.569,
      24.165,
      -30.35,
      -46.569,
      21.17,
      -28.464,
      -46.038,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
  },
  {
    ID: "LE200xxF05O_EZR5222_M0010",
    polygon: [
      -31.712,
      -46.953,
      21.17,
      -31.712,
      -46.953,
      24.165,
      -33.599,
      -47.484,
      24.165,
      -33.599,
      -47.484,
      24.165,
      -33.599,
      -47.484,
      21.17,
      -31.712,
      -46.953,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5222_M0011",
    polygon: [
      -33.661,
      -47.502,
      21.17,
      -33.661,
      -47.502,
      24.165,
      -35.548,
      -48.033,
      24.165,
      -35.548,
      -48.033,
      24.165,
      -35.548,
      -48.033,
      21.17,
      -33.661,
      -47.502,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5222_M0012",
    polygon: [
      -36.146,
      -47.674,
      21.17,
      -36.146,
      -47.674,
      24.165,
      -36.664,
      -45.836,
      24.165,
      -36.664,
      -45.836,
      24.165,
      -36.664,
      -45.836,
      21.17,
      -36.146,
      -47.674,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "LE200xxF05O_EZR5222_M0013",
    polygon: [
      -36.682,
      -45.773,
      21.17,
      -36.682,
      -45.773,
      24.165,
      -37.199,
      -43.935,
      24.165,
      -37.199,
      -43.935,
      24.165,
      -37.199,
      -43.935,
      21.17,
      -36.682,
      -45.773,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5223_M0010",
    polygon: [
      -37.608,
      -42.528,
      21.17,
      -37.608,
      -42.528,
      24.165,
      -38.126,
      -40.69,
      24.165,
      -38.126,
      -40.69,
      24.165,
      -38.126,
      -40.69,
      21.17,
      -37.608,
      -42.528,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5223_M0011",
    polygon: [
      -38.144,
      -40.627,
      21.17,
      -38.144,
      -40.627,
      24.165,
      -38.662,
      -38.789,
      24.165,
      -38.662,
      -38.789,
      24.165,
      -38.662,
      -38.789,
      21.17,
      -38.144,
      -40.627,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5224_M0010",
    polygon: [
      -39.047,
      -37.404,
      21.17,
      -39.047,
      -37.404,
      24.165,
      -39.564,
      -35.565,
      24.165,
      -39.564,
      -35.565,
      24.165,
      -39.564,
      -35.565,
      21.17,
      -39.047,
      -37.404,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF05O_EZR5224_M0011",
    polygon: [
      -39.582,
      -35.503,
      21.17,
      -39.582,
      -35.503,
      24.165,
      -40.1,
      -33.664,
      24.165,
      -40.1,
      -33.664,
      24.165,
      -40.1,
      -33.664,
      21.17,
      -39.582,
      -35.503,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF05O_EZR5224_M0012",
    polygon: [
      -39.767,
      -33.067,
      21.17,
      -39.767,
      -33.067,
      24.165,
      -37.883,
      -32.536,
      24.165,
      -37.883,
      -32.536,
      24.165,
      -37.883,
      -32.536,
      21.17,
      -39.767,
      -33.067,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF05O_EZR5224_M0013",
    polygon: [
      -37.821,
      -32.518,
      21.17,
      -37.821,
      -32.518,
      24.165,
      -35.936,
      -31.988,
      24.165,
      -35.936,
      -31.988,
      24.165,
      -35.936,
      -31.988,
      21.17,
      -37.821,
      -32.518,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF05O_EZR5225_M0010",
    polygon: [
      -34.57,
      -31.603,
      21.17,
      -34.57,
      -31.603,
      24.165,
      -32.685,
      -31.072,
      24.165,
      -32.685,
      -31.072,
      24.165,
      -32.685,
      -31.072,
      21.17,
      -34.57,
      -31.603,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5226_M0010",
    polygon: [
      -32.623,
      -31.054,
      21.17,
      -32.623,
      -31.054,
      24.165,
      -30.739,
      -30.523,
      24.165,
      -30.739,
      -30.523,
      24.165,
      -30.739,
      -30.523,
      21.17,
      -32.623,
      -31.054,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5227_M0010",
    polygon: [
      -29.374,
      -30.139,
      21.17,
      -29.374,
      -30.139,
      24.165,
      -27.49,
      -29.608,
      24.165,
      -27.49,
      -29.608,
      24.165,
      -27.49,
      -29.608,
      21.17,
      -29.374,
      -30.139,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF05O_EZR5228_M0010",
    polygon: [
      -27.428,
      -29.591,
      21.17,
      -27.428,
      -29.591,
      24.165,
      -25.543,
      -29.06,
      24.165,
      -25.543,
      -29.06,
      24.165,
      -25.543,
      -29.06,
      21.17,
      -27.428,
      -29.591,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
  },
  {
    ID: "LE200xxF05O_EZR5229_M0010",
    polygon: [
      -24.177,
      -28.675,
      21.17,
      -24.177,
      -28.675,
      24.165,
      -21.816,
      -28.01,
      24.165,
      -21.816,
      -28.01,
      24.165,
      -21.816,
      -28.01,
      21.17,
      -24.177,
      -28.675,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "LE200xxF05O_EZR5230_M0010",
    polygon: [
      -21.574,
      -26.947,
      21.17,
      -21.574,
      -26.947,
      24.165,
      -22.056,
      -25.236,
      24.165,
      -22.056,
      -25.236,
      24.165,
      -22.056,
      -25.236,
      21.17,
      -21.574,
      -26.947,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5231_M0010",
    polygon: [
      -22.074,
      -25.174,
      21.17,
      -22.074,
      -25.174,
      24.165,
      -22.556,
      -23.463,
      24.165,
      -22.556,
      -23.463,
      24.165,
      -22.556,
      -23.463,
      21.17,
      -22.074,
      -25.174,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "LE200xxF05O_EZR5231_M0011",
    polygon: [
      -22.939,
      -22.101,
      21.17,
      -22.939,
      -22.101,
      24.165,
      -23.421,
      -20.39,
      24.165,
      -23.421,
      -20.39,
      24.165,
      -23.421,
      -20.39,
      21.17,
      -22.939,
      -22.101,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5232_M0010",
    polygon: [
      -23.439,
      -20.327,
      21.17,
      -23.439,
      -20.327,
      24.165,
      -23.921,
      -18.616,
      24.165,
      -23.921,
      -18.616,
      24.165,
      -23.921,
      -18.616,
      21.17,
      -23.439,
      -20.327,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:35": 1,
    "04-15 17:40": 1,
    "04-15 17:45": 1,
    "04-15 17:50": 1,
    "04-15 17:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5232_M0011",
    polygon: [
      -24.306,
      -17.25,
      21.17,
      -24.306,
      -17.25,
      24.165,
      -24.788,
      -15.539,
      24.165,
      -24.788,
      -15.539,
      24.165,
      -24.788,
      -15.539,
      21.17,
      -24.306,
      -17.25,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
    "04-15 17:30": 1,
    "04-15 17:35": 1,
  },
  {
    ID: "LE200xxF05O_EZR5233_M0010",
    polygon: [
      -25.671,
      -12.403,
      21.17,
      -25.671,
      -12.403,
      24.165,
      -26.153,
      -10.692,
      24.165,
      -26.153,
      -10.692,
      24.165,
      -26.153,
      -10.692,
      21.17,
      -25.671,
      -12.403,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5233_M0011",
    polygon: [
      -24.805,
      -15.476,
      21.17,
      -24.805,
      -15.476,
      24.165,
      -25.287,
      -13.765,
      24.165,
      -25.287,
      -13.765,
      24.165,
      -25.287,
      -13.765,
      21.17,
      -24.805,
      -15.476,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
    "04-15 16:35": 1,
    "04-15 16:40": 1,
    "04-15 16:45": 1,
    "04-15 16:50": 1,
    "04-15 16:55": 1,
    "04-15 17:00": 1,
    "04-15 17:05": 1,
    "04-15 17:10": 1,
    "04-15 17:15": 1,
    "04-15 17:20": 1,
    "04-15 17:25": 1,
  },
  {
    ID: "LE200xxF05O_EZR5234_M0010",
    polygon: [
      -5.686,
      -10.163,
      21.17,
      -5.686,
      -10.163,
      24.165,
      -5.204,
      -11.874,
      24.165,
      -5.204,
      -11.874,
      24.165,
      -5.204,
      -11.874,
      21.17,
      -5.686,
      -10.163,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5235_M0010",
    polygon: [
      -4.819,
      -13.241,
      21.17,
      -4.819,
      -13.241,
      24.165,
      -4.337,
      -14.952,
      24.165,
      -4.337,
      -14.952,
      24.165,
      -4.337,
      -14.952,
      21.17,
      -4.819,
      -13.241,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5236_M0010",
    polygon: [
      -4.319,
      -15.014,
      21.17,
      -4.319,
      -15.014,
      24.165,
      -3.837,
      -16.725,
      24.165,
      -3.837,
      -16.725,
      24.165,
      -3.837,
      -16.725,
      21.17,
      -4.319,
      -15.014,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
  },
  {
    ID: "LE200xxF05O_EZR5237_M0010",
    polygon: [
      -2.954,
      -19.861,
      21.17,
      -2.954,
      -19.861,
      24.165,
      -2.472,
      -21.572,
      24.165,
      -2.472,
      -21.572,
      24.165,
      -2.472,
      -21.572,
      21.17,
      -2.954,
      -19.861,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "LE200xxF05O_EZR5237_M0011",
    polygon: [
      -3.454,
      -18.087,
      21.17,
      -3.454,
      -18.087,
      24.165,
      -2.972,
      -19.798,
      24.165,
      -2.972,
      -19.798,
      24.165,
      -2.972,
      -19.798,
      21.17,
      -3.454,
      -18.087,
      21.17,
    ],
    "04-15 02:05": 1,
    "04-15 04:45": 1,
    "04-15 04:50": 1,
    "04-15 04:55": 1,
    "04-15 05:00": 1,
    "04-15 05:05": 1,
    "04-15 05:10": 1,
    "04-15 05:15": 1,
    "04-15 05:20": 1,
    "04-15 05:25": 1,
    "04-15 05:30": 1,
    "04-15 05:35": 1,
    "04-15 05:40": 1,
    "04-15 05:45": 1,
    "04-15 05:50": 1,
    "04-15 05:55": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
  },
  {
    ID: "LE200xxF05O_EZR5251_M0010",
    polygon: [
      -1.718,
      -22.349,
      21.17,
      -1.718,
      -22.349,
      24.165,
      0.643,
      -21.684,
      24.165,
      0.643,
      -21.684,
      24.165,
      0.643,
      -21.684,
      21.17,
      -1.718,
      -22.349,
      21.17,
    ],
    "04-15 02:05": 1,
  },
  {
    ID: "Roof_2336",
    polygon: [
      -88.928,
      -70.785,
      26.328,
      -87.629,
      -70.419,
      26.34,
      -87.913,
      -69.408,
      26.34,
      -87.913,
      -69.408,
      26.34,
      -89.213,
      -69.774,
      26.328,
      -88.928,
      -70.785,
      26.328,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:35": 1,
    "04-15 07:45": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
  },
  {
    ID: "Roof_2337",
    polygon: [
      -89.444,
      -68.954,
      26.328,
      -88.144,
      -68.588,
      26.34,
      -88.429,
      -67.577,
      26.34,
      -88.429,
      -67.577,
      26.34,
      -89.728,
      -67.943,
      26.328,
      -89.444,
      -68.954,
      26.328,
    ],
    "04-15 02:05": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:35": 1,
    "04-15 07:45": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
  },
  {
    ID: "Roof_2338",
    polygon: [
      -87.898,
      -74.445,
      26.328,
      -86.599,
      -74.079,
      26.34,
      -86.883,
      -73.068,
      26.34,
      -86.883,
      -73.068,
      26.34,
      -88.183,
      -73.434,
      26.328,
      -87.898,
      -74.445,
      26.328,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
  },
  {
    ID: "Roof_2339",
    polygon: [
      -88.414,
      -72.614,
      26.328,
      -87.114,
      -72.248,
      26.34,
      -87.399,
      -71.237,
      26.34,
      -87.399,
      -71.237,
      26.34,
      -88.698,
      -71.603,
      26.328,
      -88.414,
      -72.614,
      26.328,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:15": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
  },
  {
    ID: "Roof_2343",
    polygon: [
      -2.58,
      -45.387,
      26.328,
      -3.879,
      -45.753,
      26.34,
      -3.595,
      -46.764,
      26.34,
      -3.595,
      -46.764,
      26.34,
      -2.295,
      -46.398,
      26.328,
      -2.58,
      -45.387,
      26.328,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
  },
  {
    ID: "Roof_2344",
    polygon: [
      -3.095,
      -43.556,
      26.328,
      -4.395,
      -43.922,
      26.34,
      -4.11,
      -44.933,
      26.34,
      -4.11,
      -44.933,
      26.34,
      -2.811,
      -44.567,
      26.328,
      -3.095,
      -43.556,
      26.328,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
  },
  {
    ID: "Roof_2345",
    polygon: [
      -1.55,
      -49.047,
      26.328,
      -2.849,
      -49.413,
      26.34,
      -2.565,
      -50.424,
      26.34,
      -2.565,
      -50.424,
      26.34,
      -1.265,
      -50.058,
      26.328,
      -1.55,
      -49.047,
      26.328,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
  },
  {
    ID: "Roof_2346",
    polygon: [
      -2.065,
      -47.216,
      26.328,
      -3.364,
      -47.582,
      26.34,
      -3.08,
      -48.593,
      26.34,
      -3.08,
      -48.593,
      26.34,
      -1.78,
      -48.227,
      26.328,
      -2.065,
      -47.216,
      26.328,
    ],
    "04-15 02:05": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 07:15": 1,
    "04-15 07:20": 1,
    "04-15 07:25": 1,
    "04-15 07:30": 1,
    "04-15 07:35": 1,
    "04-15 07:40": 1,
    "04-15 07:45": 1,
    "04-15 07:50": 1,
    "04-15 07:55": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
    "04-15 14:10": 1,
    "04-15 14:15": 1,
    "04-15 14:20": 1,
    "04-15 14:25": 1,
    "04-15 14:30": 1,
    "04-15 14:35": 1,
    "04-15 14:40": 1,
    "04-15 14:45": 1,
    "04-15 14:50": 1,
    "04-15 14:55": 1,
    "04-15 15:00": 1,
    "04-15 15:05": 1,
    "04-15 15:10": 1,
    "04-15 15:15": 1,
    "04-15 15:20": 1,
  },
  {
    ID: "Roof_2349",
    polygon: [
      -6.377,
      -35.405,
      25.495,
      -6.241,
      -35.886,
      25.495,
      -5.664,
      -35.724,
      25.495,
      -5.664,
      -35.724,
      25.495,
      -5.799,
      -35.243,
      25.495,
      -6.377,
      -35.405,
      25.495,
    ],
    "04-15 02:05": 1,
    "04-15 08:00": 1,
    "04-15 08:05": 1,
    "04-15 08:10": 1,
    "04-15 08:15": 1,
    "04-15 08:20": 1,
    "04-15 08:25": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:45": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:10": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 15:20": 1,
    "04-15 15:25": 1,
    "04-15 15:30": 1,
    "04-15 15:35": 1,
    "04-15 15:40": 1,
    "04-15 15:45": 1,
    "04-15 15:50": 1,
    "04-15 15:55": 1,
    "04-15 16:00": 1,
    "04-15 16:05": 1,
    "04-15 16:10": 1,
    "04-15 16:15": 1,
    "04-15 16:20": 1,
    "04-15 16:25": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "Roof_2350",
    polygon: [
      -91.743,
      -59.46,
      25.495,
      -91.608,
      -59.941,
      25.495,
      -91.03,
      -59.778,
      25.495,
      -91.03,
      -59.778,
      25.495,
      -91.166,
      -59.297,
      25.495,
      -91.743,
      -59.46,
      25.495,
    ],
    "04-15 02:05": 1,
    "04-15 06:00": 1,
    "04-15 06:05": 1,
    "04-15 06:10": 1,
    "04-15 06:15": 1,
    "04-15 06:20": 1,
    "04-15 06:25": 1,
    "04-15 06:30": 1,
    "04-15 06:35": 1,
    "04-15 06:40": 1,
    "04-15 06:45": 1,
    "04-15 06:50": 1,
    "04-15 06:55": 1,
    "04-15 07:00": 1,
    "04-15 07:05": 1,
    "04-15 07:10": 1,
    "04-15 08:30": 1,
    "04-15 08:35": 1,
    "04-15 08:40": 1,
    "04-15 08:50": 1,
    "04-15 08:55": 1,
    "04-15 09:00": 1,
    "04-15 09:05": 1,
    "04-15 09:15": 1,
    "04-15 09:20": 1,
    "04-15 09:25": 1,
    "04-15 09:30": 1,
    "04-15 09:35": 1,
    "04-15 09:40": 1,
    "04-15 09:45": 1,
    "04-15 09:50": 1,
    "04-15 09:55": 1,
    "04-15 10:00": 1,
    "04-15 10:05": 1,
    "04-15 10:10": 1,
    "04-15 10:15": 1,
    "04-15 10:20": 1,
    "04-15 10:25": 1,
    "04-15 10:30": 1,
    "04-15 10:35": 1,
    "04-15 10:40": 1,
    "04-15 10:45": 1,
    "04-15 10:50": 1,
    "04-15 10:55": 1,
    "04-15 11:00": 1,
    "04-15 11:05": 1,
    "04-15 11:10": 1,
    "04-15 11:15": 1,
    "04-15 11:20": 1,
    "04-15 11:25": 1,
    "04-15 11:30": 1,
    "04-15 11:35": 1,
    "04-15 11:40": 1,
    "04-15 11:45": 1,
    "04-15 11:50": 1,
    "04-15 11:55": 1,
    "04-15 12:00": 1,
    "04-15 12:05": 1,
    "04-15 12:10": 1,
    "04-15 12:15": 1,
    "04-15 12:20": 1,
    "04-15 12:25": 1,
    "04-15 12:30": 1,
    "04-15 12:35": 1,
    "04-15 12:40": 1,
    "04-15 12:45": 1,
    "04-15 12:50": 1,
    "04-15 12:55": 1,
    "04-15 13:00": 1,
    "04-15 13:05": 1,
    "04-15 13:10": 1,
    "04-15 13:15": 1,
    "04-15 13:20": 1,
    "04-15 13:25": 1,
    "04-15 13:30": 1,
    "04-15 13:35": 1,
    "04-15 13:40": 1,
    "04-15 13:45": 1,
    "04-15 13:50": 1,
    "04-15 13:55": 1,
    "04-15 14:00": 1,
    "04-15 14:05": 1,
  },
];

export default project8ShadingProgression;
