import { useFrame } from "@react-three/fiber";
import { useDispatch } from "react-redux";
import * as THREE from "three";
import { setCompassRotationValue } from "../../redux/compass";

const CompassValue = () => {
  const dispatch = useDispatch();
  useFrame(({ camera }) => {
    var vector = new THREE.Vector3();
    var center = new THREE.Vector3();
    var spherical = new THREE.Spherical();
    const { x, y, z } = camera.rotation;
    vector.copy({ x, y, z }).sub(center);
    spherical.setFromVector3(vector);
    const quaternion = new THREE.Quaternion();
    quaternion.copy(camera.quaternion);
    const euler = new THREE.Euler();
    euler.setFromQuaternion(quaternion, "YXZ");
    const theta = ((euler.y * 180) / Math.PI) % 360;

    dispatch(setCompassRotationValue(theta));
  });

  return;
};

export default CompassValue;
