import React from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { setPVDataUnit } from "../../redux/pvData";
import { usePVColorBar } from "../CustomHooks";
import { CustomColorBar } from "./ColorBarComponent";
import "./colorBar.css";

const PVDataUnitSelector = () => {
  const { unit, unitOptions } = useSelector((state) => state.pvData);
  const dispatch = useDispatch()

  const dropDownOptions = unitOptions.map(option => ({ key: option, text: option, value: option, content: option }))

  return (createPortal(<Dropdown
    style={{
      position: "absolute",
      right: "1.5%",
      top: "100px",
      cursor: "pointer",
      fontSize: "14px",
      zIndex: 104,
      width: 105,
    }}
    direction="left"
    onChange={(e, { value }) => dispatch(setPVDataUnit(value))}
    options={dropDownOptions}
    value={unit}
    defaultValue={unit}
    inline
  />, document.body)
  )

}

const ColorBarPV = () => {
  const { isVisible, subcategory, minVal, maxVal } = usePVColorBar();
  return (
    <CustomColorBar
      colorbarClassName="color-bar-pv"
      category="PV Panel"
      subcategory={subcategory}
      unit={() => <PVDataUnitSelector />}
      dataMin={minVal}
      dataMax={maxVal}
      isVisible={isVisible}
    />
  );
};

export default ColorBarPV;
