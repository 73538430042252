import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { GiPhotoCamera } from "react-icons/gi";


const removeNoscriptTags = () => {
    document.querySelectorAll('noscript')?.forEach((noscript) => {
        noscript.style.display = 'none';
    });
};

const restoreNoscriptTags = () => {
    document.querySelectorAll('noscript')?.forEach((noscript) => {
        noscript.style.display = '';
    });
};

export const captureElement = (targetElement, filename = 'screenshot') => {
    removeNoscriptTags()

    html2canvas(targetElement, {
        useCORS: true,
    }).then((canvasSnapshot) => {
        const screenshot = canvasSnapshot.toDataURL('image/png');
        saveAs(screenshot, `${filename}.png`);
    });
    restoreNoscriptTags()
};

export const CaptureComponent = ({ targetElement, filename, style, ...props }) => {
    return <GiPhotoCamera style={{
        width: "24px",
        height: "24px",
        cursor: 'pointer',
        ...style
    }} onClick={(e) => {
        captureElement(targetElement(), filename)
    }}
        {...props}
    />

};
