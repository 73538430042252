import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Asset from "./Asset";
import { setloading } from "../../redux/sceneSlice";
function Ceilings(props) {
  const project_id = localStorage.getItem("project_id");
  var ceilingList = useSelector((state) => state.sceneVar.ceilingList);
  const dispatch = useDispatch();
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );
  var targetBuildingScene = useSelector(
    (state) => state.sceneVisibility.value[3]
  );

  return (
    <group name="ceiling" visible={targetBuildingScene}>
      {ceilingList.map((floor, index) => {
        return (
          <>
            <Asset
              url={`${process.env.REACT_APP_API_URL}project/glb/?project_id=${project_id}&type=${floor}`}
              visible={selectedFloor[index]}
              onFulfilled={
                index === ceilingList.length - 1
                  ? () => dispatch(setloading({ index: 3, value: false }))
                  : null
              }
              onLoading={() => dispatch(setloading({ index: 3, value: true }))}
            />
          </>
        );
      })}
    </group>
  );
}
export default React.memo(Ceilings);
