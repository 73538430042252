import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: { pagex: 0, pagey: 0 },
  name: null,
  isShowing: false,
  firstIsShowing: false,
  downloadIsShowing: false,
};

const uploadSlice = createSlice({
  name: "uploadData",
  initialState,
  reducers: {
    updateUploadPosition: (state) => {
      state.firstIsShowing = true;
    },
    toggleUploadIsShowing: (state) => {
      state.isShowing = !state.isShowing;
    },
    toggleDownloadIsShowing: (state) => {
      state.downloadIsShowing = !state.downloadIsShowing;
    },
  },
});

export const {
  updateUploadPosition,
  toggleUploadIsShowing,
  toggleDownloadIsShowing,
} = uploadSlice.actions;

export default uploadSlice.reducer;
