import React, { useEffect, useRef } from "react";

const ClickOutsideHandler = ({ children, handleClickOutside, preventOnCaptureButtonClick = true }) => {
    const refOne = useRef(null);
    useEffect(() => {
        document.addEventListener("click", onClickOutside, true);
        return () => {
            document.removeEventListener("click", onClickOutside, true);
        };
    }, []);
    const onClickOutside = (e) => {
        const homepageCaptureButton = document.getElementById('Home-Capture-Button')
        const isCaptureButtonClicked = homepageCaptureButton.contains(e.target)
        const preventClose = preventOnCaptureButtonClick && isCaptureButtonClicked
        if (refOne.current && !refOne.current.contains(e.target) && !preventClose) {
            handleClickOutside()
        }
    };
    return (
        <div ref={refOne}>
            {children}
        </div>
    )
};

export default ClickOutsideHandler;
