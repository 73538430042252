const project15_29ShaindgProgression = [
  {
    ID: -1,
    polygon: "ANotExistingPolygon",
    "04-15 00:00": 0,
    "04-15 00:15": 0,
    "04-15 00:30": 0,
    "04-15 00:45": 0,
    "04-15 01:00": 0,
    "04-15 01:15": 0,
    "04-15 01:30": 0,
    "04-15 01:45": 0,
    "04-15 02:00": 0,
    "04-15 02:15": 0,
    "04-15 02:30": 0,
    "04-15 02:45": 0,
    "04-15 03:00": 0,
    "04-15 03:15": 0,
    "04-15 03:30": 0,
    "04-15 03:45": 0,
    "04-15 04:00": 0,
    "04-15 04:15": 0,
    "04-15 04:30": 0,
    "04-15 04:45": 0,
    "04-15 05:00": 0,
    "04-15 05:15": 0,
    "04-15 05:30": 0,
    "04-15 05:45": 0,
    "04-15 06:00": 0,
    "04-15 06:15": 0,
    "04-15 06:30": 0,
    "04-15 06:45": 0,
    "04-15 07:00": 0,
    "04-15 07:15": 0,
    "04-15 07:30": 0,
    "04-15 07:45": 0,
    "04-15 08:00": 0,
    "04-15 08:15": 0,
    "04-15 08:30": 0,
    "04-15 08:45": 0,
    "04-15 09:00": 0,
    "04-15 09:15": 0,
    "04-15 09:30": 0,
    "04-15 09:45": 0,
    "04-15 10:00": 0,
    "04-15 10:15": 0,
    "04-15 10:30": 0,
    "04-15 10:45": 0,
    "04-15 11:00": 0,
    "04-15 11:15": 0,
    "04-15 11:30": 0,
    "04-15 11:45": 0,
    "04-15 12:00": 0,
    "04-15 12:15": 0,
    "04-15 12:30": 0,
    "04-15 12:45": 0,
    "04-15 13:00": 0,
    "04-15 13:15": 0,
    "04-15 13:30": 0,
    "04-15 13:45": 0,
    "04-15 14:00": 0,
    "04-15 14:15": 0,
    "04-15 14:30": 0,
    "04-15 14:45": 0,
    "04-15 15:00": 0,
    "04-15 15:15": 0,
    "04-15 15:30": 0,
    "04-15 15:45": 0,
    "04-15 16:00": 0,
    "04-15 16:15": 0,
    "04-15 16:30": 0,
    "04-15 16:45": 0,
    "04-15 17:00": 0,
    "04-15 17:15": 0,
    "04-15 17:30": 0,
    "04-15 17:45": 0,
    "04-15 18:00": 0,
    "04-15 18:15": 0,
    "04-15 18:30": 0,
    "04-15 18:45": 0,
    "04-15 19:00": 0,
    "04-15 19:15": 0,
    "04-15 19:30": 0,
    "04-15 19:45": 0,
    "04-15 20:00": 0,
    "04-15 20:15": 0,
    "04-15 20:30": 0,
    "04-15 20:45": 0,
    "04-15 21:00": 0,
    "04-15 21:15": 0,
    "04-15 21:30": 0,
    "04-15 21:45": 0,
    "04-15 22:00": 0,
    "04-15 22:15": 0,
    "04-15 22:30": 0,
    "04-15 22:45": 0,
    "04-15 23:00": 0,
    "04-15 23:15": 0,
    "04-15 23:30": 0,
    "04-15 23:45": 0,
  },
  {
    ID: "1412.0",
    polygon: [
      18.649,
      -10.223,
      39.128,

      17.464,
      -10.415,
      39.128,
      17.464,
      -10.415,
      40.05,
      17.464,
      -10.415,
      40.05,
      18.649,
      -10.223,
      40.05,
      18.649,
      -10.223,
      39.128,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "807.0",
    polygon: [
      -50.233,
      -40.803,
      8.36,
      -52.049,
      -40.902,
      8.36,
      -52.049,
      -40.902,
      9.26,
      -52.049,
      -40.902,
      9.26,
      -50.233,
      -40.803,
      9.26,
      -50.233,
      -40.803,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1541.0",
    polygon: [
      -4.364,
      -13.852,
      32.86,
      -5.868,
      -14.101,
      32.86,
      -5.868,
      -14.101,
      35.32,
      -5.868,
      -14.101,
      35.32,
      -4.364,
      -13.852,
      35.32,
      -4.364,
      -13.852,
      32.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1145.0",
    polygon: [
      -23.325,
      -39.362,
      16.46,
      -25.142,
      -39.46,
      16.46,
      -25.142,
      -39.46,
      17.36,
      -25.142,
      -39.46,
      17.36,
      -23.325,
      -39.362,
      17.36,
      -23.325,
      -39.362,
      16.46,
    ],
    "04-15 05:45": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "332.0",
    polygon: [
      -43.741,
      -40.473,
      5.352,
      -44.649,
      -40.522,
      5.352,
      -44.649,
      -40.522,
      5.598,
      -44.649,
      -40.522,
      5.598,
      -43.741,
      -40.473,
      5.598,
      -43.741,
      -40.473,
      5.352,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "950.0",
    polygon: [
      -56.772,
      -41.16,
      10.16,
      -57.791,
      -41.215,
      10.16,
      -57.791,
      -41.215,
      11.06,
      -57.791,
      -41.215,
      11.06,
      -56.772,
      -41.16,
      11.06,
      -56.772,
      -41.16,
      10.16,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "502.0",
    polygon: [
      -39.56,
      -40.844,
      0.05,
      -40.622,
      -40.902,
      0.05,
      -40.622,
      -40.902,
      2.78,
      -40.622,
      -40.902,
      2.78,
      -39.56,
      -40.844,
      2.78,
      -39.56,
      -40.844,
      0.05,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "371.0",
    polygon: [
      -44.64,
      -40.518,
      4.077,
      -45.548,
      -40.567,
      4.077,
      -45.548,
      -40.567,
      4.323,
      -45.548,
      -40.567,
      4.323,
      -44.64,
      -40.518,
      4.323,
      -44.64,
      -40.518,
      4.077,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1067.0",
    polygon: [
      -12.119,
      -38.752,
      10.16,
      -13.935,
      -38.851,
      10.16,
      -13.935,
      -38.851,
      11.06,
      -13.935,
      -38.851,
      11.06,
      -12.119,
      -38.752,
      11.06,
      -12.119,
      -38.752,
      10.16,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "989.0",
    polygon: [
      -52.074,
      -40.904,
      13.76,
      -53.092,
      -40.959,
      13.76,
      -53.092,
      -40.959,
      14.66,
      -53.092,
      -40.959,
      14.66,
      -52.074,
      -40.904,
      14.66,
      -52.074,
      -40.904,
      13.76,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1106.0",
    polygon: [
      -26.203,
      -39.518,
      12.86,
      -28.019,
      -39.617,
      12.86,
      -28.019,
      -39.617,
      13.76,
      -28.019,
      -39.617,
      13.76,
      -26.203,
      -39.518,
      13.76,
      -26.203,
      -39.518,
      12.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "72.0",
    polygon: [
      -70.122,
      -42.498,
      15.36,
      -71.184,
      -42.554,
      15.36,
      -71.184,
      -42.554,
      17.82,
      -71.184,
      -42.554,
      17.82,
      -70.122,
      -42.498,
      17.82,
      -70.122,
      -42.498,
      15.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "666.0",
    polygon: [
      -18.618,
      -39.128,
      5.844,
      -19.527,
      -39.176,
      5.844,
      -19.527,
      -39.176,
      6.09,
      -19.527,
      -39.176,
      6.09,
      -18.618,
      -39.128,
      6.09,
      -18.618,
      -39.128,
      5.844,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "32.0",
    polygon: [
      -67.723,
      -42.374,
      8.36,
      -69.123,
      -42.449,
      8.36,
      -69.123,
      -42.449,
      9.18,
      -69.123,
      -42.449,
      9.18,
      -67.723,
      -42.374,
      9.18,
      -67.723,
      -42.374,
      8.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1028.0",
    polygon: [
      -13.935,
      -38.851,
      8.36,
      -15.751,
      -38.95,
      8.36,
      -15.751,
      -38.95,
      9.26,
      -15.751,
      -38.95,
      9.26,
      -13.935,
      -38.851,
      9.26,
      -13.935,
      -38.851,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "541.0",
    polygon: [
      -28.912,
      -39.668,
      6.09,
      -29.821,
      -39.715,
      6.09,
      -29.821,
      -39.715,
      6.336,
      -29.821,
      -39.715,
      6.336,
      -28.912,
      -39.668,
      6.336,
      -28.912,
      -39.668,
      6.09,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "166.0",
    polygon: [
      18.252,
      -10.318,
      2.78,
      16.003,
      -10.691,
      2.78,
      16.003,
      -10.691,
      3.82,
      16.003,
      -10.691,
      3.82,
      18.252,
      -10.318,
      3.82,
      18.252,
      -10.318,
      2.78,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "424.0",
    polygon: [
      -1.416,
      -38.786,
      4.86,
      -2.498,
      -38.843,
      4.86,
      -2.498,
      -38.843,
      7.32,
      -2.498,
      -38.843,
      7.32,
      -1.416,
      -38.786,
      7.32,
      -1.416,
      -38.786,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "207.0",
    polygon: [
      -54.024,
      -41.033,
      5.352,
      -54.933,
      -41.082,
      5.352,
      -54.933,
      -41.082,
      5.598,
      -54.933,
      -41.082,
      5.598,
      -54.024,
      -41.033,
      5.598,
      -54.024,
      -41.033,
      5.352,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "584.0",
    polygon: [
      -26.202,
      -39.526,
      0.05,
      -28.018,
      -39.621,
      0.05,
      -28.018,
      -39.621,
      2.78,
      -28.018,
      -39.621,
      2.78,
      -26.202,
      -39.526,
      2.78,
      -26.202,
      -39.526,
      0.05,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1662.0",
    polygon: [
      10.527,
      -11.542,
      39.128,
      9.343,
      -11.734,
      39.128,
      9.343,
      -11.734,
      40.05,
      9.343,
      -11.734,
      40.05,
      10.527,
      -11.542,
      40.05,
      10.527,
      -11.542,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "463.0",
    polygon: [
      -10.091,
      -39.241,
      11.86,
      -11.154,
      -39.297,
      11.86,
      -11.154,
      -39.297,
      14.32,
      -11.154,
      -39.297,
      14.32,
      -10.091,
      -39.241,
      14.32,
      -10.091,
      -39.241,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "246.0",
    polygon: [
      -54.938,
      -41.079,
      4.077,
      -55.847,
      -41.128,
      4.077,
      -55.847,
      -41.128,
      4.323,
      -55.847,
      -41.128,
      4.323,
      -54.938,
      -41.079,
      4.323,
      -54.938,
      -41.079,
      4.077,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1280.0",
    polygon: [
      2.566,
      -30.976,
      15.36,
      2.566,
      -32.307,
      15.36,
      2.566,
      -32.307,
      17.82,
      2.566,
      -32.307,
      17.82,
      2.566,
      -30.976,
      17.82,
      2.566,
      -30.976,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1241.0",
    polygon: [
      2.566,
      -19.002,
      4.86,
      2.566,
      -20.332,
      4.86,
      2.566,
      -20.332,
      7.32,
      2.566,
      -20.332,
      7.32,
      2.566,
      -19.002,
      7.32,
      2.566,
      -19.002,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1281.0",
    polygon: [
      2.566,
      -29.646,
      15.36,
      2.566,
      -30.976,
      15.36,
      2.566,
      -30.976,
      17.82,
      2.566,
      -30.976,
      17.82,
      2.566,
      -29.646,
      17.82,
      2.566,
      -29.646,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "808.0",
    polygon: [
      -53.118,
      -40.961,
      8.36,
      -54.935,
      -41.06,
      8.36,
      -54.935,
      -41.06,
      9.26,
      -54.935,
      -41.06,
      9.26,
      -53.118,
      -40.961,
      9.26,
      -53.118,
      -40.961,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "425.0",
    polygon: [
      -0.335,
      -38.73,
      4.86,
      -1.416,
      -38.786,
      4.86,
      -1.416,
      -38.786,
      7.32,
      -1.416,
      -38.786,
      7.32,
      -0.335,
      -38.73,
      7.32,
      -0.335,
      -38.73,
      4.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "73.0",
    polygon: [
      -65.715,
      -42.262,
      15.36,
      -66.797,
      -42.32,
      15.36,
      -66.797,
      -42.32,
      17.82,
      -66.797,
      -42.32,
      17.82,
      -65.715,
      -42.262,
      17.82,
      -65.715,
      -42.262,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "33.0",
    polygon: [
      -67.723,
      -42.374,
      9.18,
      -69.123,
      -42.449,
      9.18,
      -69.123,
      -42.449,
      10.0,
      -69.123,
      -42.449,
      10.0,
      -67.723,
      -42.374,
      10.0,
      -67.723,
      -42.374,
      9.18,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "333.0",
    polygon: [
      -46.437,
      -40.619,
      5.598,
      -47.345,
      -40.669,
      5.598,
      -47.345,
      -40.669,
      5.844,
      -47.345,
      -40.669,
      5.844,
      -46.437,
      -40.619,
      5.844,
      -46.437,
      -40.619,
      5.598,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "1029.0",
    polygon: [
      -20.464,
      -39.206,
      8.36,
      -21.483,
      -39.261,
      8.36,
      -21.483,
      -39.261,
      9.26,
      -21.483,
      -39.261,
      9.26,
      -20.464,
      -39.206,
      9.26,
      -20.464,
      -39.206,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "208.0",
    polygon: [
      -53.131,
      -40.984,
      5.352,
      -54.039,
      -41.034,
      5.352,
      -54.039,
      -41.034,
      5.598,
      -54.039,
      -41.034,
      5.598,
      -53.131,
      -40.984,
      5.598,
      -53.131,
      -40.984,
      5.352,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "667.0",
    polygon: [
      -17.71,
      -39.081,
      5.844,
      -18.618,
      -39.128,
      5.844,
      -18.618,
      -39.128,
      6.09,
      -18.618,
      -39.128,
      6.09,
      -17.71,
      -39.081,
      6.09,
      -17.71,
      -39.081,
      5.844,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1107.0",
    polygon: [
      -12.119,
      -38.752,
      13.76,
      -13.935,
      -38.851,
      13.76,
      -13.935,
      -38.851,
      14.66,
      -13.935,
      -38.851,
      14.66,
      -12.119,
      -38.752,
      14.66,
      -12.119,
      -38.752,
      13.76,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1502.0",
    polygon: [
      1.035,
      -12.956,
      18.86,
      -0.116,
      -13.147,
      18.86,
      -0.116,
      -13.147,
      21.32,
      -0.116,
      -13.147,
      21.32,
      1.035,
      -12.956,
      21.32,
      1.035,
      -12.956,
      18.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1146.0",
    polygon: [
      -26.203,
      -39.518,
      16.46,
      -28.019,
      -39.617,
      16.46,
      -28.019,
      -39.617,
      17.36,
      -28.019,
      -39.617,
      17.36,
      -26.203,
      -39.518,
      17.36,
      -26.203,
      -39.518,
      16.46,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1108.0",
    polygon: [
      -15.769,
      -38.951,
      13.76,
      -16.788,
      -39.006,
      13.76,
      -16.788,
      -39.006,
      14.66,
      -16.788,
      -39.006,
      14.66,
      -15.769,
      -38.951,
      14.66,
      -15.769,
      -38.951,
      13.76,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "542.0",
    polygon: [
      -28.018,
      -39.621,
      6.09,
      -28.927,
      -39.668,
      6.09,
      -28.927,
      -39.668,
      6.336,
      -28.927,
      -39.668,
      6.336,
      -28.018,
      -39.621,
      6.336,
      -28.018,
      -39.621,
      6.09,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "373.0",
    polygon: [
      -46.442,
      -40.616,
      3.831,
      -47.35,
      -40.666,
      3.831,
      -47.35,
      -40.666,
      4.077,
      -47.35,
      -40.666,
      4.077,
      -46.442,
      -40.616,
      4.077,
      -46.442,
      -40.616,
      3.831,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "1663.0",
    polygon: [
      11.881,
      -11.322,
      36.36,
      10.696,
      -11.514,
      36.36,
      10.696,
      -11.514,
      39.128,
      10.696,
      -11.514,
      39.128,
      11.881,
      -11.322,
      39.128,
      11.881,
      -11.322,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "503.0",
    polygon: [
      -38.497,
      -40.786,
      0.05,
      -39.56,
      -40.844,
      0.05,
      -39.56,
      -40.844,
      2.78,
      -39.56,
      -40.844,
      2.78,
      -38.497,
      -40.786,
      2.78,
      -38.497,
      -40.786,
      0.05,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "951.0",
    polygon: [
      -48.416,
      -40.704,
      10.16,
      -50.233,
      -40.803,
      10.16,
      -50.233,
      -40.803,
      11.06,
      -50.233,
      -40.803,
      11.06,
      -48.416,
      -40.704,
      11.06,
      -48.416,
      -40.704,
      10.16,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "34.0",
    polygon: [
      -67.723,
      -42.374,
      10.0,
      -69.123,
      -42.449,
      10.0,
      -69.123,
      -42.449,
      10.82,
      -69.123,
      -42.449,
      10.82,
      -67.723,
      -42.374,
      10.82,
      -67.723,
      -42.374,
      10.0,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1068.0",
    polygon: [
      -15.769,
      -38.951,
      10.16,
      -16.788,
      -39.006,
      10.16,
      -16.788,
      -39.006,
      11.06,
      -16.788,
      -39.006,
      11.06,
      -15.769,
      -38.951,
      11.06,
      -15.769,
      -38.951,
      10.16,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1501.0",
    polygon: [
      -0.116,
      -13.147,
      18.86,
      -1.268,
      -13.338,
      18.86,
      -1.268,
      -13.338,
      21.32,
      -1.268,
      -13.338,
      21.32,
      -0.116,
      -13.147,
      21.32,
      -0.116,
      -13.147,
      18.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1373.0",
    polygon: [
      18.642,
      -10.188,
      25.86,
      17.326,
      -10.406,
      25.86,
      17.326,
      -10.406,
      28.32,
      17.326,
      -10.406,
      28.32,
      18.642,
      -10.188,
      28.32,
      18.642,
      -10.188,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1242.0",
    polygon: [
      2.566,
      -17.671,
      4.86,
      2.566,
      -19.002,
      4.86,
      2.566,
      -19.002,
      7.32,
      2.566,
      -19.002,
      7.32,
      2.566,
      -17.671,
      7.32,
      2.566,
      -17.671,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "710.0",
    polygon: [
      -16.802,
      -39.033,
      2.78,
      -18.619,
      -39.128,
      2.78,
      -18.619,
      -39.128,
      3.82,
      -18.619,
      -39.128,
      3.82,
      -16.802,
      -39.033,
      3.82,
      -16.802,
      -39.033,
      2.78,
    ],
    "04-15 15:00": 1,
  },
  {
    ID: "167.0",
    polygon: [
      14.543,
      -10.933,
      0.05,
      13.753,
      -11.064,
      0.05,
      13.753,
      -11.064,
      2.78,
      13.753,
      -11.064,
      2.78,
      14.543,
      -10.933,
      2.78,
      14.543,
      -10.933,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "585.0",
    polygon: [
      -24.266,
      -39.424,
      4.86,
      -25.174,
      -39.472,
      4.86,
      -25.174,
      -39.472,
      5.106,
      -25.174,
      -39.472,
      5.106,
      -24.266,
      -39.424,
      5.106,
      -24.266,
      -39.424,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "991.0",
    polygon: [
      -48.416,
      -40.704,
      13.76,
      -50.233,
      -40.803,
      13.76,
      -50.233,
      -40.803,
      14.66,
      -50.233,
      -40.803,
      14.66,
      -48.416,
      -40.704,
      14.66,
      -48.416,
      -40.704,
      13.76,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "74.0",
    polygon: [
      -64.635,
      -42.205,
      15.36,
      -65.716,
      -42.262,
      15.36,
      -65.716,
      -42.262,
      17.82,
      -65.716,
      -42.262,
      17.82,
      -64.635,
      -42.205,
      17.82,
      -64.635,
      -42.205,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "504.0",
    polygon: [
      -40.622,
      -40.902,
      2.78,
      -42.747,
      -41.017,
      2.78,
      -42.747,
      -41.017,
      3.82,
      -42.747,
      -41.017,
      3.82,
      -40.622,
      -40.902,
      3.82,
      -40.622,
      -40.902,
      2.78,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1664.0",
    polygon: [
      11.881,
      -11.322,
      39.128,
      10.696,
      -11.514,
      39.128,
      10.696,
      -11.514,
      40.05,
      10.696,
      -11.514,
      40.05,
      11.881,
      -11.322,
      40.05,
      11.881,
      -11.322,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "586.0",
    polygon: [
      -23.372,
      -39.377,
      4.86,
      -24.281,
      -39.425,
      4.86,
      -24.281,
      -39.425,
      5.106,
      -24.281,
      -39.425,
      5.106,
      -23.372,
      -39.377,
      5.106,
      -23.372,
      -39.377,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "809.0",
    polygon: [
      -54.935,
      -41.06,
      8.36,
      -56.751,
      -41.159,
      8.36,
      -56.751,
      -41.159,
      9.26,
      -56.751,
      -41.159,
      9.26,
      -54.935,
      -41.06,
      9.26,
      -54.935,
      -41.06,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "465.0",
    polygon: [
      -7.966,
      -39.13,
      11.86,
      -9.029,
      -39.185,
      11.86,
      -9.029,
      -39.185,
      14.32,
      -9.029,
      -39.185,
      14.32,
      -7.966,
      -39.13,
      14.32,
      -7.966,
      -39.13,
      11.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1282.0",
    polygon: [
      2.566,
      -28.315,
      15.36,
      2.566,
      -29.646,
      15.36,
      2.566,
      -29.646,
      17.82,
      2.566,
      -29.646,
      17.82,
      2.566,
      -28.315,
      17.82,
      2.566,
      -28.315,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1069.0",
    polygon: [
      -13.935,
      -38.851,
      10.16,
      -15.751,
      -38.95,
      10.16,
      -15.751,
      -38.95,
      11.06,
      -15.751,
      -38.95,
      11.06,
      -13.935,
      -38.851,
      11.06,
      -13.935,
      -38.851,
      10.16,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "625.0",
    polygon: [
      -24.266,
      -39.424,
      4.569,
      -25.174,
      -39.472,
      4.569,
      -25.174,
      -39.472,
      4.815,
      -25.174,
      -39.472,
      4.815,
      -24.266,
      -39.424,
      4.815,
      -24.266,
      -39.424,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1030.0",
    polygon: [
      -25.163,
      -39.462,
      8.36,
      -26.182,
      -39.517,
      8.36,
      -26.182,
      -39.517,
      9.26,
      -26.182,
      -39.517,
      9.26,
      -25.163,
      -39.462,
      9.26,
      -25.163,
      -39.462,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1503.0",
    polygon: [
      -4.36,
      -13.851,
      18.86,
      -5.864,
      -14.101,
      18.86,
      -5.864,
      -14.101,
      21.32,
      -5.864,
      -14.101,
      21.32,
      -4.36,
      -13.851,
      21.32,
      -4.36,
      -13.851,
      18.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "711.0",
    polygon: [
      -19.527,
      -39.176,
      0.05,
      -20.435,
      -39.223,
      0.05,
      -20.435,
      -39.223,
      2.78,
      -20.435,
      -39.223,
      2.78,
      -19.527,
      -39.176,
      2.78,
      -19.527,
      -39.176,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "168.0",
    polygon: [
      18.252,
      -10.318,
      0.05,
      17.463,
      -10.449,
      0.05,
      17.463,
      -10.449,
      2.78,
      17.463,
      -10.449,
      2.78,
      18.252,
      -10.318,
      2.78,
      18.252,
      -10.318,
      0.05,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1243.0",
    polygon: [
      2.566,
      -16.341,
      4.86,
      2.566,
      -17.671,
      4.86,
      2.566,
      -17.671,
      7.32,
      2.566,
      -17.671,
      7.32,
      2.566,
      -16.341,
      7.32,
      2.566,
      -16.341,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "952.0",
    polygon: [
      -50.233,
      -40.803,
      10.16,
      -52.049,
      -40.902,
      10.16,
      -52.049,
      -40.902,
      11.06,
      -52.049,
      -40.902,
      11.06,
      -50.233,
      -40.803,
      11.06,
      -50.233,
      -40.803,
      10.16,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "669.0",
    polygon: [
      -19.512,
      -39.175,
      6.09,
      -20.421,
      -39.223,
      6.09,
      -20.421,
      -39.223,
      6.336,
      -20.421,
      -39.223,
      6.336,
      -19.512,
      -39.175,
      6.336,
      -19.512,
      -39.175,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "426.0",
    polygon: [
      0.747,
      -38.673,
      4.86,
      -0.335,
      -38.73,
      4.86,
      -0.335,
      -38.73,
      7.32,
      -0.335,
      -38.73,
      7.32,
      0.747,
      -38.673,
      7.32,
      0.747,
      -38.673,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "543.0",
    polygon: [
      -27.11,
      -39.573,
      6.09,
      -28.018,
      -39.621,
      6.09,
      -28.018,
      -39.621,
      6.336,
      -28.018,
      -39.621,
      6.336,
      -27.11,
      -39.573,
      6.336,
      -27.11,
      -39.573,
      6.09,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "248.0",
    polygon: [
      -53.136,
      -40.981,
      4.077,
      -54.045,
      -41.03,
      4.077,
      -54.045,
      -41.03,
      4.323,
      -54.045,
      -41.03,
      4.323,
      -53.136,
      -40.981,
      4.323,
      -53.136,
      -40.981,
      4.077,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1148.0",
    polygon: [
      6.678,
      -12.056,
      8.36,
      5.526,
      -12.247,
      8.36,
      5.526,
      -12.247,
      10.82,
      5.526,
      -12.247,
      10.82,
      6.678,
      -12.056,
      10.82,
      6.678,
      -12.056,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "1543.0",
    polygon: [
      -1.672,
      -13.405,
      32.86,
      -2.824,
      -13.596,
      32.86,
      -2.824,
      -13.596,
      35.32,
      -2.824,
      -13.596,
      35.32,
      -1.672,
      -13.405,
      35.32,
      -1.672,
      -13.405,
      32.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "334.0",
    polygon: [
      -45.543,
      -40.571,
      5.598,
      -46.452,
      -40.62,
      5.598,
      -46.452,
      -40.62,
      5.844,
      -46.452,
      -40.62,
      5.844,
      -45.543,
      -40.571,
      5.844,
      -45.543,
      -40.571,
      5.598,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "668.0",
    polygon: [
      -16.816,
      -39.034,
      5.844,
      -17.725,
      -39.081,
      5.844,
      -17.725,
      -39.081,
      6.09,
      -17.725,
      -39.081,
      6.09,
      -16.816,
      -39.034,
      6.09,
      -16.816,
      -39.034,
      5.844,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "372.0",
    polygon: [
      -43.746,
      -40.469,
      4.077,
      -44.654,
      -40.519,
      4.077,
      -44.654,
      -40.519,
      4.323,
      -44.654,
      -40.519,
      4.323,
      -43.746,
      -40.469,
      4.323,
      -43.746,
      -40.469,
      4.077,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "247.0",
    polygon: [
      -54.03,
      -41.029,
      4.077,
      -54.938,
      -41.079,
      4.077,
      -54.938,
      -41.079,
      4.323,
      -54.938,
      -41.079,
      4.323,
      -54.03,
      -41.029,
      4.323,
      -54.03,
      -41.029,
      4.077,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "374.0",
    polygon: [
      -45.548,
      -40.567,
      3.831,
      -46.457,
      -40.617,
      3.831,
      -46.457,
      -40.617,
      4.077,
      -46.457,
      -40.617,
      4.077,
      -45.548,
      -40.567,
      4.077,
      -45.548,
      -40.567,
      3.831,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1109.0",
    polygon: [
      -13.935,
      -38.851,
      13.76,
      -15.751,
      -38.95,
      13.76,
      -15.751,
      -38.95,
      14.66,
      -15.751,
      -38.95,
      14.66,
      -13.935,
      -38.851,
      14.66,
      -13.935,
      -38.851,
      13.76,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "587.0",
    polygon: [
      -22.463,
      -39.33,
      4.86,
      -23.372,
      -39.377,
      4.86,
      -23.372,
      -39.377,
      5.106,
      -23.372,
      -39.377,
      5.106,
      -22.463,
      -39.33,
      5.106,
      -22.463,
      -39.33,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1031.0",
    polygon: [
      -16.807,
      -39.007,
      8.36,
      -18.623,
      -39.106,
      8.36,
      -18.623,
      -39.106,
      9.26,
      -18.623,
      -39.106,
      9.26,
      -16.807,
      -39.007,
      9.26,
      -16.807,
      -39.007,
      8.36,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1375.0",
    polygon: [
      16.01,
      -10.625,
      25.86,
      14.693,
      -10.843,
      25.86,
      14.693,
      -10.843,
      28.32,
      14.693,
      -10.843,
      28.32,
      16.01,
      -10.625,
      28.32,
      16.01,
      -10.625,
      25.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "544.0",
    polygon: [
      -26.216,
      -39.526,
      6.09,
      -27.125,
      -39.574,
      6.09,
      -27.125,
      -39.574,
      6.336,
      -27.125,
      -39.574,
      6.336,
      -26.216,
      -39.526,
      6.336,
      -26.216,
      -39.526,
      6.09,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "953.0",
    polygon: [
      -53.118,
      -40.961,
      10.16,
      -54.935,
      -41.06,
      10.16,
      -54.935,
      -41.06,
      11.06,
      -54.935,
      -41.06,
      11.06,
      -53.118,
      -40.961,
      11.06,
      -53.118,
      -40.961,
      10.16,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1070.0",
    polygon: [
      -20.464,
      -39.206,
      10.16,
      -21.483,
      -39.261,
      10.16,
      -21.483,
      -39.261,
      11.06,
      -21.483,
      -39.261,
      11.06,
      -20.464,
      -39.206,
      11.06,
      -20.464,
      -39.206,
      10.16,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1665.0",
    polygon: [
      13.234,
      -11.102,
      36.36,
      12.05,
      -11.295,
      36.36,
      12.05,
      -11.295,
      39.128,
      12.05,
      -11.295,
      39.128,
      13.234,
      -11.102,
      39.128,
      13.234,
      -11.102,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "626.0",
    polygon: [
      -23.372,
      -39.377,
      4.569,
      -24.281,
      -39.425,
      4.569,
      -24.281,
      -39.425,
      4.815,
      -24.281,
      -39.425,
      4.815,
      -23.372,
      -39.377,
      4.815,
      -23.372,
      -39.377,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "427.0",
    polygon: [
      -36.108,
      -40.638,
      8.36,
      -37.508,
      -40.714,
      8.36,
      -37.508,
      -40.714,
      9.18,
      -37.508,
      -40.714,
      9.18,
      -36.108,
      -40.638,
      9.18,
      -36.108,
      -40.638,
      8.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "169.0",
    polygon: [
      15.273,
      -10.812,
      0.05,
      14.543,
      -10.933,
      0.05,
      14.543,
      -10.933,
      2.78,
      14.543,
      -10.933,
      2.78,
      15.273,
      -10.812,
      2.78,
      15.273,
      -10.812,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1542.0",
    polygon: [
      -2.824,
      -13.596,
      32.86,
      -3.975,
      -13.787,
      32.86,
      -3.975,
      -13.787,
      35.32,
      -3.975,
      -13.787,
      35.32,
      -2.824,
      -13.596,
      35.32,
      -2.824,
      -13.596,
      32.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "464.0",
    polygon: [
      -9.029,
      -39.185,
      11.86,
      -10.091,
      -39.241,
      11.86,
      -10.091,
      -39.241,
      14.32,
      -10.091,
      -39.241,
      14.32,
      -9.029,
      -39.185,
      14.32,
      -9.029,
      -39.185,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "35.0",
    polygon: [
      -73.318,
      -42.673,
      8.36,
      -74.38,
      -42.73,
      8.36,
      -74.38,
      -42.73,
      10.82,
      -74.38,
      -42.73,
      10.82,
      -73.318,
      -42.673,
      10.82,
      -73.318,
      -42.673,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "209.0",
    polygon: [
      -55.827,
      -41.131,
      5.598,
      -56.735,
      -41.181,
      5.598,
      -56.735,
      -41.181,
      5.844,
      -56.735,
      -41.181,
      5.844,
      -55.827,
      -41.131,
      5.844,
      -55.827,
      -41.131,
      5.598,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1374.0",
    polygon: [
      17.326,
      -10.406,
      25.86,
      16.01,
      -10.625,
      25.86,
      16.01,
      -10.625,
      28.32,
      16.01,
      -10.625,
      28.32,
      17.326,
      -10.406,
      28.32,
      17.326,
      -10.406,
      25.86,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "712.0",
    polygon: [
      -17.711,
      -39.081,
      0.05,
      -18.619,
      -39.128,
      0.05,
      -18.619,
      -39.128,
      2.78,
      -18.619,
      -39.128,
      2.78,
      -17.711,
      -39.081,
      2.78,
      -17.711,
      -39.081,
      0.05,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "249.0",
    polygon: [
      -55.832,
      -41.128,
      3.831,
      -56.741,
      -41.177,
      3.831,
      -56.741,
      -41.177,
      4.077,
      -56.741,
      -41.177,
      4.077,
      -55.832,
      -41.128,
      4.077,
      -55.832,
      -41.128,
      3.831,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1244.0",
    polygon: [
      2.566,
      -15.01,
      4.86,
      2.566,
      -16.341,
      4.86,
      2.566,
      -16.341,
      7.32,
      2.566,
      -16.341,
      7.32,
      2.566,
      -15.01,
      7.32,
      2.566,
      -15.01,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "36.0",
    polygon: [
      -72.255,
      -42.616,
      8.36,
      -73.318,
      -42.673,
      8.36,
      -73.318,
      -42.673,
      10.82,
      -73.318,
      -42.673,
      10.82,
      -72.255,
      -42.616,
      10.82,
      -72.255,
      -42.616,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1110.0",
    polygon: [
      -20.464,
      -39.206,
      13.76,
      -21.483,
      -39.261,
      13.76,
      -21.483,
      -39.261,
      14.66,
      -21.483,
      -39.261,
      14.66,
      -20.464,
      -39.206,
      14.66,
      -20.464,
      -39.206,
      13.76,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "375.0",
    polygon: [
      -44.64,
      -40.518,
      3.831,
      -45.548,
      -40.567,
      3.831,
      -45.548,
      -40.567,
      4.077,
      -45.548,
      -40.567,
      4.077,
      -44.64,
      -40.518,
      4.077,
      -44.64,
      -40.518,
      3.831,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1504.0",
    polygon: [
      -2.82,
      -13.596,
      18.86,
      -3.971,
      -13.787,
      18.86,
      -3.971,
      -13.787,
      21.32,
      -3.971,
      -13.787,
      21.32,
      -2.82,
      -13.596,
      21.32,
      -2.82,
      -13.596,
      18.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "670.0",
    polygon: [
      -18.618,
      -39.128,
      6.09,
      -19.527,
      -39.176,
      6.09,
      -19.527,
      -39.176,
      6.336,
      -19.527,
      -39.176,
      6.336,
      -18.618,
      -39.128,
      6.336,
      -18.618,
      -39.128,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1149.0",
    polygon: [
      9.376,
      -11.608,
      8.36,
      8.224,
      -11.8,
      8.36,
      8.224,
      -11.8,
      10.82,
      8.224,
      -11.8,
      10.82,
      9.376,
      -11.608,
      10.82,
      9.376,
      -11.608,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "810.0",
    polygon: [
      -57.812,
      -41.216,
      8.36,
      -59.629,
      -41.315,
      8.36,
      -59.629,
      -41.315,
      9.26,
      -59.629,
      -41.315,
      9.26,
      -57.812,
      -41.216,
      9.26,
      -57.812,
      -41.216,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1544.0",
    polygon: [
      -6.268,
      -14.168,
      32.86,
      -7.42,
      -14.359,
      32.86,
      -7.42,
      -14.359,
      35.32,
      -7.42,
      -14.359,
      35.32,
      -6.268,
      -14.168,
      35.32,
      -6.268,
      -14.168,
      32.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "466.0",
    polygon: [
      -6.904,
      -39.074,
      11.86,
      -7.966,
      -39.13,
      11.86,
      -7.966,
      -39.13,
      14.32,
      -7.966,
      -39.13,
      14.32,
      -6.904,
      -39.074,
      14.32,
      -6.904,
      -39.074,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "335.0",
    polygon: [
      -44.634,
      -40.521,
      5.598,
      -45.543,
      -40.571,
      5.598,
      -45.543,
      -40.571,
      5.844,
      -45.543,
      -40.571,
      5.844,
      -44.634,
      -40.521,
      5.844,
      -44.634,
      -40.521,
      5.598,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1283.0",
    polygon: [
      2.566,
      -26.985,
      15.36,
      2.566,
      -28.315,
      15.36,
      2.566,
      -28.315,
      17.82,
      2.566,
      -28.315,
      17.82,
      2.566,
      -26.985,
      17.82,
      2.566,
      -26.985,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "505.0",
    polygon: [
      -38.497,
      -40.786,
      2.78,
      -40.622,
      -40.902,
      2.78,
      -40.622,
      -40.902,
      3.82,
      -40.622,
      -40.902,
      3.82,
      -38.497,
      -40.786,
      3.82,
      -38.497,
      -40.786,
      2.78,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "210.0",
    polygon: [
      -54.933,
      -41.082,
      5.598,
      -55.842,
      -41.132,
      5.598,
      -55.842,
      -41.132,
      5.844,
      -55.842,
      -41.132,
      5.844,
      -54.933,
      -41.082,
      5.844,
      -54.933,
      -41.082,
      5.598,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1071.0",
    polygon: [
      -25.163,
      -39.462,
      10.16,
      -26.182,
      -39.517,
      10.16,
      -26.182,
      -39.517,
      11.06,
      -26.182,
      -39.517,
      11.06,
      -25.163,
      -39.462,
      11.06,
      -25.163,
      -39.462,
      10.16,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "506.0",
    polygon: [
      -36.074,
      -40.746,
      2.78,
      -37.474,
      -40.822,
      2.78,
      -37.474,
      -40.822,
      3.82,
      -37.474,
      -40.822,
      3.82,
      -36.074,
      -40.746,
      3.82,
      -36.074,
      -40.746,
      2.78,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1032.0",
    polygon: [
      -18.623,
      -39.106,
      8.36,
      -20.44,
      -39.205,
      8.36,
      -20.44,
      -39.205,
      9.26,
      -20.44,
      -39.205,
      9.26,
      -18.623,
      -39.106,
      9.26,
      -18.623,
      -39.106,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "467.0",
    polygon: [
      -2.497,
      -38.843,
      11.86,
      -3.578,
      -38.9,
      11.86,
      -3.578,
      -38.9,
      14.32,
      -3.578,
      -38.9,
      14.32,
      -2.497,
      -38.843,
      14.32,
      -2.497,
      -38.843,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "954.0",
    polygon: [
      -54.935,
      -41.06,
      10.16,
      -56.751,
      -41.159,
      10.16,
      -56.751,
      -41.159,
      11.06,
      -56.751,
      -41.159,
      11.06,
      -54.935,
      -41.06,
      11.06,
      -54.935,
      -41.06,
      10.16,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "76.0",
    polygon: [
      -62.472,
      -42.089,
      15.36,
      -63.553,
      -42.147,
      15.36,
      -63.553,
      -42.147,
      17.82,
      -63.553,
      -42.147,
      17.82,
      -62.472,
      -42.089,
      17.82,
      -62.472,
      -42.089,
      15.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1111.0",
    polygon: [
      -25.163,
      -39.462,
      13.76,
      -26.182,
      -39.517,
      13.76,
      -26.182,
      -39.517,
      14.66,
      -26.182,
      -39.517,
      14.66,
      -25.163,
      -39.462,
      14.66,
      -25.163,
      -39.462,
      13.76,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1284.0",
    polygon: [
      2.566,
      -25.654,
      15.36,
      2.566,
      -26.985,
      15.36,
      2.566,
      -26.985,
      17.82,
      2.566,
      -26.985,
      17.82,
      2.566,
      -25.654,
      17.82,
      2.566,
      -25.654,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1666.0",
    polygon: [
      13.234,
      -11.102,
      39.128,
      12.05,
      -11.295,
      39.128,
      12.05,
      -11.295,
      40.05,
      12.05,
      -11.295,
      40.05,
      13.234,
      -11.102,
      40.05,
      13.234,
      -11.102,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "992.0",
    polygon: [
      -50.233,
      -40.803,
      13.76,
      -52.049,
      -40.902,
      13.76,
      -52.049,
      -40.902,
      14.66,
      -52.049,
      -40.902,
      14.66,
      -50.233,
      -40.803,
      14.66,
      -50.233,
      -40.803,
      13.76,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "811.0",
    polygon: [
      -59.629,
      -41.315,
      8.36,
      -61.445,
      -41.414,
      8.36,
      -61.445,
      -41.414,
      9.26,
      -61.445,
      -41.414,
      9.26,
      -59.629,
      -41.315,
      9.26,
      -59.629,
      -41.315,
      8.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1505.0",
    polygon: [
      -1.668,
      -13.405,
      18.86,
      -2.82,
      -13.596,
      18.86,
      -2.82,
      -13.596,
      21.32,
      -2.82,
      -13.596,
      21.32,
      -1.668,
      -13.405,
      21.32,
      -1.668,
      -13.405,
      18.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "671.0",
    polygon: [
      -17.71,
      -39.081,
      6.09,
      -18.618,
      -39.128,
      6.09,
      -18.618,
      -39.128,
      6.336,
      -18.618,
      -39.128,
      6.336,
      -17.71,
      -39.081,
      6.336,
      -17.71,
      -39.081,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1194.0",
    polygon: [
      2.562,
      -36.298,
      8.36,
      1.762,
      -36.298,
      8.36,
      1.762,
      -36.298,
      10.82,
      1.762,
      -36.298,
      10.82,
      2.562,
      -36.298,
      10.82,
      2.562,
      -36.298,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "713.0",
    polygon: [
      -14.839,
      -38.93,
      4.86,
      -15.748,
      -38.978,
      4.86,
      -15.748,
      -38.978,
      5.106,
      -15.748,
      -38.978,
      5.106,
      -14.839,
      -38.93,
      5.106,
      -14.839,
      -38.93,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1245.0",
    polygon: [
      2.566,
      -34.968,
      8.36,
      2.566,
      -36.298,
      8.36,
      2.566,
      -36.298,
      10.82,
      2.566,
      -36.298,
      10.82,
      2.566,
      -34.968,
      10.82,
      2.566,
      -34.968,
      8.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "170.0",
    polygon: [
      16.001,
      -10.691,
      0.05,
      15.271,
      -10.812,
      0.05,
      15.271,
      -10.812,
      2.78,
      15.271,
      -10.812,
      2.78,
      16.001,
      -10.691,
      2.78,
      16.001,
      -10.691,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "993.0",
    polygon: [
      -53.118,
      -40.961,
      13.76,
      -54.935,
      -41.06,
      13.76,
      -54.935,
      -41.06,
      14.66,
      -54.935,
      -41.06,
      14.66,
      -53.118,
      -40.961,
      14.66,
      -53.118,
      -40.961,
      13.76,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "75.0",
    polygon: [
      -63.553,
      -42.147,
      15.36,
      -64.635,
      -42.205,
      15.36,
      -64.635,
      -42.205,
      17.82,
      -64.635,
      -42.205,
      17.82,
      -63.553,
      -42.147,
      17.82,
      -63.553,
      -42.147,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "211.0",
    polygon: [
      -54.024,
      -41.033,
      5.598,
      -54.933,
      -41.082,
      5.598,
      -54.933,
      -41.082,
      5.844,
      -54.933,
      -41.082,
      5.844,
      -54.024,
      -41.033,
      5.844,
      -54.024,
      -41.033,
      5.598,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "376.0",
    polygon: [
      -43.746,
      -40.469,
      3.831,
      -44.654,
      -40.519,
      3.831,
      -44.654,
      -40.519,
      4.077,
      -44.654,
      -40.519,
      4.077,
      -43.746,
      -40.469,
      4.077,
      -43.746,
      -40.469,
      3.831,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "990.0",
    polygon: [
      -56.772,
      -41.16,
      13.76,
      -57.791,
      -41.215,
      13.76,
      -57.791,
      -41.215,
      14.66,
      -57.791,
      -41.215,
      14.66,
      -56.772,
      -41.16,
      14.66,
      -56.772,
      -41.16,
      13.76,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "250.0",
    polygon: [
      -54.938,
      -41.079,
      3.831,
      -55.847,
      -41.128,
      3.831,
      -55.847,
      -41.128,
      4.077,
      -55.847,
      -41.128,
      4.077,
      -54.938,
      -41.079,
      4.077,
      -54.938,
      -41.079,
      3.831,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "588.0",
    polygon: [
      -21.569,
      -39.283,
      4.86,
      -22.478,
      -39.33,
      4.86,
      -22.478,
      -39.33,
      5.106,
      -22.478,
      -39.33,
      5.106,
      -21.569,
      -39.283,
      5.106,
      -21.569,
      -39.283,
      4.86,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "428.0",
    polygon: [
      -36.108,
      -40.638,
      9.18,
      -37.508,
      -40.714,
      9.18,
      -37.508,
      -40.714,
      10.0,
      -37.508,
      -40.714,
      10.0,
      -36.108,
      -40.638,
      10.0,
      -36.108,
      -40.638,
      9.18,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1376.0",
    polygon: [
      14.693,
      -10.843,
      25.86,
      13.377,
      -11.061,
      25.86,
      13.377,
      -11.061,
      28.32,
      13.377,
      -11.061,
      28.32,
      14.693,
      -10.843,
      28.32,
      14.693,
      -10.843,
      25.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "994.0",
    polygon: [
      -54.935,
      -41.06,
      13.76,
      -56.751,
      -41.159,
      13.76,
      -56.751,
      -41.159,
      14.66,
      -56.751,
      -41.159,
      14.66,
      -54.935,
      -41.06,
      14.66,
      -54.935,
      -41.06,
      13.76,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "627.0",
    polygon: [
      -22.463,
      -39.33,
      4.569,
      -23.372,
      -39.377,
      4.569,
      -23.372,
      -39.377,
      4.815,
      -23.372,
      -39.377,
      4.815,
      -22.463,
      -39.33,
      4.815,
      -22.463,
      -39.33,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "37.0",
    polygon: [
      -71.193,
      -42.56,
      8.36,
      -72.255,
      -42.616,
      8.36,
      -72.255,
      -42.616,
      10.82,
      -72.255,
      -42.616,
      10.82,
      -71.193,
      -42.56,
      10.82,
      -71.193,
      -42.56,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "507.0",
    polygon: [
      -4.484,
      -39.042,
      0.05,
      -5.884,
      -39.115,
      0.05,
      -5.884,
      -39.115,
      2.78,
      -5.884,
      -39.115,
      2.78,
      -4.484,
      -39.042,
      2.78,
      -4.484,
      -39.042,
      0.05,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "336.0",
    polygon: [
      -43.741,
      -40.473,
      5.598,
      -44.649,
      -40.522,
      5.598,
      -44.649,
      -40.522,
      5.844,
      -44.649,
      -40.522,
      5.844,
      -43.741,
      -40.473,
      5.844,
      -43.741,
      -40.473,
      5.598,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1546.0",
    polygon: [
      -7.42,
      -14.359,
      32.86,
      -8.571,
      -14.55,
      32.86,
      -8.571,
      -14.55,
      35.32,
      -8.571,
      -14.55,
      35.32,
      -7.42,
      -14.359,
      35.32,
      -7.42,
      -14.359,
      32.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "589.0",
    polygon: [
      -24.266,
      -39.424,
      5.106,
      -25.174,
      -39.472,
      5.106,
      -25.174,
      -39.472,
      5.352,
      -25.174,
      -39.472,
      5.352,
      -24.266,
      -39.424,
      5.352,
      -24.266,
      -39.424,
      5.106,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "753.0",
    polygon: [
      -14.839,
      -38.93,
      4.569,
      -15.748,
      -38.978,
      4.569,
      -15.748,
      -38.978,
      4.815,
      -15.748,
      -38.978,
      4.815,
      -14.839,
      -38.93,
      4.815,
      -14.839,
      -38.93,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "337.0",
    polygon: [
      -46.437,
      -40.619,
      5.844,
      -47.345,
      -40.669,
      5.844,
      -47.345,
      -40.669,
      6.09,
      -47.345,
      -40.669,
      6.09,
      -46.437,
      -40.619,
      6.09,
      -46.437,
      -40.619,
      5.844,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "911.0",
    polygon: [
      -43.728,
      -40.449,
      17.36,
      -45.545,
      -40.548,
      17.36,
      -45.545,
      -40.548,
      18.55,
      -45.545,
      -40.548,
      18.55,
      -43.728,
      -40.449,
      18.55,
      -43.728,
      -40.449,
      17.36,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "468.0",
    polygon: [
      -1.416,
      -38.786,
      11.86,
      -2.498,
      -38.843,
      11.86,
      -2.498,
      -38.843,
      14.32,
      -2.498,
      -38.843,
      14.32,
      -1.416,
      -38.786,
      14.32,
      -1.416,
      -38.786,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1285.0",
    polygon: [
      2.566,
      -24.324,
      15.36,
      2.566,
      -25.654,
      15.36,
      2.566,
      -25.654,
      17.82,
      2.566,
      -25.654,
      17.82,
      2.566,
      -24.324,
      17.82,
      2.566,
      -24.324,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1033.0",
    polygon: [
      -21.509,
      -39.263,
      8.36,
      -23.325,
      -39.362,
      8.36,
      -23.325,
      -39.362,
      9.26,
      -23.325,
      -39.362,
      9.26,
      -21.509,
      -39.263,
      9.26,
      -21.509,
      -39.263,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1150.0",
    polygon: [
      12.075,
      -11.161,
      8.36,
      10.923,
      -11.352,
      8.36,
      10.923,
      -11.352,
      10.82,
      10.923,
      -11.352,
      10.82,
      12.075,
      -11.161,
      10.82,
      12.075,
      -11.161,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1151.0",
    polygon: [
      14.773,
      -10.713,
      8.36,
      13.621,
      -10.904,
      8.36,
      13.621,
      -10.904,
      10.82,
      13.621,
      -10.904,
      10.82,
      14.773,
      -10.713,
      10.82,
      14.773,
      -10.713,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "77.0",
    polygon: [
      -60.521,
      -41.389,
      4.86,
      -61.429,
      -41.438,
      4.86,
      -61.429,
      -41.438,
      5.106,
      -61.429,
      -41.438,
      5.106,
      -60.521,
      -41.389,
      5.106,
      -60.521,
      -41.389,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "628.0",
    polygon: [
      -21.569,
      -39.283,
      4.569,
      -22.478,
      -39.33,
      4.569,
      -22.478,
      -39.33,
      4.815,
      -22.478,
      -39.33,
      4.815,
      -21.569,
      -39.283,
      4.815,
      -21.569,
      -39.283,
      4.569,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "171.0",
    polygon: [
      16.73,
      -10.57,
      0.05,
      16.0,
      -10.691,
      0.05,
      16.0,
      -10.691,
      2.78,
      16.0,
      -10.691,
      2.78,
      16.73,
      -10.57,
      2.78,
      16.73,
      -10.57,
      0.05,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1072.0",
    polygon: [
      -16.807,
      -39.007,
      10.16,
      -18.623,
      -39.106,
      10.16,
      -18.623,
      -39.106,
      11.06,
      -18.623,
      -39.106,
      11.06,
      -16.807,
      -39.007,
      11.06,
      -16.807,
      -39.007,
      10.16,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1377.0",
    polygon: [
      13.377,
      -11.061,
      25.86,
      12.061,
      -11.28,
      25.86,
      12.061,
      -11.28,
      28.32,
      12.061,
      -11.28,
      28.32,
      13.377,
      -11.061,
      28.32,
      13.377,
      -11.061,
      25.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "251.0",
    polygon: [
      -54.03,
      -41.029,
      3.831,
      -54.938,
      -41.079,
      3.831,
      -54.938,
      -41.079,
      4.077,
      -54.938,
      -41.079,
      4.077,
      -54.03,
      -41.029,
      4.077,
      -54.03,
      -41.029,
      3.831,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "429.0",
    polygon: [
      -36.108,
      -40.638,
      10.0,
      -37.508,
      -40.714,
      10.0,
      -37.508,
      -40.714,
      10.82,
      -37.508,
      -40.714,
      10.82,
      -36.108,
      -40.638,
      10.82,
      -36.108,
      -40.638,
      10.0,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "714.0",
    polygon: [
      -13.946,
      -38.883,
      4.86,
      -14.854,
      -38.931,
      4.86,
      -14.854,
      -38.931,
      5.106,
      -14.854,
      -38.931,
      5.106,
      -13.946,
      -38.883,
      5.106,
      -13.946,
      -38.883,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "212.0",
    polygon: [
      -53.131,
      -40.984,
      5.598,
      -54.039,
      -41.034,
      5.598,
      -54.039,
      -41.034,
      5.844,
      -54.039,
      -41.034,
      5.844,
      -53.131,
      -40.984,
      5.844,
      -53.131,
      -40.984,
      5.598,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "672.0",
    polygon: [
      -16.816,
      -39.034,
      6.09,
      -17.725,
      -39.081,
      6.09,
      -17.725,
      -39.081,
      6.336,
      -17.725,
      -39.081,
      6.336,
      -16.816,
      -39.034,
      6.336,
      -16.816,
      -39.034,
      6.09,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1545.0",
    polygon: [
      -8.971,
      -14.616,
      32.86,
      -10.123,
      -14.807,
      32.86,
      -10.123,
      -14.807,
      35.32,
      -10.123,
      -14.807,
      35.32,
      -8.971,
      -14.616,
      35.32,
      -8.971,
      -14.616,
      32.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1507.0",
    polygon: [
      -6.264,
      -14.167,
      18.86,
      -7.415,
      -14.358,
      18.86,
      -7.415,
      -14.358,
      21.32,
      -7.415,
      -14.358,
      21.32,
      -6.264,
      -14.167,
      21.32,
      -6.264,
      -14.167,
      18.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1112.0",
    polygon: [
      -16.807,
      -39.007,
      13.76,
      -18.623,
      -39.106,
      13.76,
      -18.623,
      -39.106,
      14.66,
      -18.623,
      -39.106,
      14.66,
      -16.807,
      -39.007,
      14.66,
      -16.807,
      -39.007,
      13.76,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "381.0",
    polygon: [
      -67.699,
      -42.479,
      0.05,
      -69.099,
      -42.554,
      0.05,
      -69.099,
      -42.554,
      2.78,
      -69.099,
      -42.554,
      2.78,
      -67.699,
      -42.479,
      2.78,
      -67.699,
      -42.479,
      0.05,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "38.0",
    polygon: [
      -70.13,
      -42.503,
      8.36,
      -71.193,
      -42.56,
      8.36,
      -71.193,
      -42.56,
      10.82,
      -71.193,
      -42.56,
      10.82,
      -70.13,
      -42.503,
      10.82,
      -70.13,
      -42.503,
      8.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "78.0",
    polygon: [
      -59.627,
      -41.34,
      4.86,
      -60.536,
      -41.389,
      4.86,
      -60.536,
      -41.389,
      5.106,
      -60.536,
      -41.389,
      5.106,
      -59.627,
      -41.34,
      5.106,
      -59.627,
      -41.34,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1667.0",
    polygon: [
      14.588,
      -10.882,
      36.36,
      13.403,
      -11.075,
      36.36,
      13.403,
      -11.075,
      39.128,
      13.403,
      -11.075,
      39.128,
      14.588,
      -10.882,
      39.128,
      14.588,
      -10.882,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "508.0",
    polygon: [
      -2.487,
      -38.874,
      0.05,
      -3.569,
      -38.931,
      0.05,
      -3.569,
      -38.931,
      2.78,
      -3.569,
      -38.931,
      2.78,
      -2.487,
      -38.874,
      2.78,
      -2.487,
      -38.874,
      0.05,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "955.0",
    polygon: [
      -57.812,
      -41.216,
      10.16,
      -59.629,
      -41.315,
      10.16,
      -59.629,
      -41.315,
      11.06,
      -59.629,
      -41.315,
      11.06,
      -57.812,
      -41.216,
      11.06,
      -57.812,
      -41.216,
      10.16,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "121.0",
    polygon: [
      -60.526,
      -41.389,
      4.569,
      -61.434,
      -41.438,
      4.569,
      -61.434,
      -41.438,
      4.815,
      -61.434,
      -41.438,
      4.815,
      -60.526,
      -41.389,
      4.815,
      -60.526,
      -41.389,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "995.0",
    polygon: [
      -57.812,
      -41.216,
      13.76,
      -59.629,
      -41.315,
      13.76,
      -59.629,
      -41.315,
      14.66,
      -59.629,
      -41.315,
      14.66,
      -57.812,
      -41.216,
      14.66,
      -57.812,
      -41.216,
      13.76,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "469.0",
    polygon: [
      -0.335,
      -38.73,
      11.86,
      -1.416,
      -38.786,
      11.86,
      -1.416,
      -38.786,
      14.32,
      -1.416,
      -38.786,
      14.32,
      -0.335,
      -38.73,
      14.32,
      -0.335,
      -38.73,
      11.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1286.0",
    polygon: [
      2.566,
      -22.993,
      15.36,
      2.566,
      -24.324,
      15.36,
      2.566,
      -24.324,
      17.82,
      2.566,
      -24.324,
      17.82,
      2.566,
      -22.993,
      17.82,
      2.566,
      -22.993,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1246.0",
    polygon: [
      2.566,
      -33.637,
      8.36,
      2.566,
      -34.968,
      8.36,
      2.566,
      -34.968,
      10.82,
      2.566,
      -34.968,
      10.82,
      2.566,
      -33.637,
      10.82,
      2.566,
      -33.637,
      8.36,
    ],
    "04-15 04:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1547.0",
    polygon: [
      -10.123,
      -14.807,
      32.86,
      -11.274,
      -14.998,
      32.86,
      -11.274,
      -14.998,
      35.32,
      -11.274,
      -14.998,
      35.32,
      -10.123,
      -14.807,
      35.32,
      -10.123,
      -14.807,
      32.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1034.0",
    polygon: [
      -23.325,
      -39.362,
      8.36,
      -25.142,
      -39.46,
      8.36,
      -25.142,
      -39.46,
      9.26,
      -25.142,
      -39.46,
      9.26,
      -23.325,
      -39.362,
      9.26,
      -23.325,
      -39.362,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "590.0",
    polygon: [
      -23.372,
      -39.377,
      5.106,
      -24.281,
      -39.425,
      5.106,
      -24.281,
      -39.425,
      5.352,
      -24.281,
      -39.425,
      5.352,
      -23.372,
      -39.377,
      5.352,
      -23.372,
      -39.377,
      5.106,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1152.0",
    polygon: [
      17.472,
      -10.266,
      8.36,
      16.32,
      -10.457,
      8.36,
      16.32,
      -10.457,
      10.82,
      16.32,
      -10.457,
      10.82,
      17.472,
      -10.266,
      10.82,
      17.472,
      -10.266,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "754.0",
    polygon: [
      -13.946,
      -38.883,
      4.569,
      -14.854,
      -38.931,
      4.569,
      -14.854,
      -38.931,
      4.815,
      -14.854,
      -38.931,
      4.815,
      -13.946,
      -38.883,
      4.815,
      -13.946,
      -38.883,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "338.0",
    polygon: [
      -45.543,
      -40.571,
      5.844,
      -46.452,
      -40.62,
      5.844,
      -46.452,
      -40.62,
      6.09,
      -46.452,
      -40.62,
      6.09,
      -45.543,
      -40.571,
      6.09,
      -45.543,
      -40.571,
      5.844,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "213.0",
    polygon: [
      -55.827,
      -41.131,
      5.844,
      -56.735,
      -41.181,
      5.844,
      -56.735,
      -41.181,
      6.09,
      -56.735,
      -41.181,
      6.09,
      -55.827,
      -41.131,
      6.09,
      -55.827,
      -41.131,
      5.844,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "996.0",
    polygon: [
      -43.728,
      -40.449,
      14.66,
      -45.545,
      -40.548,
      14.66,
      -45.545,
      -40.548,
      15.56,
      -45.545,
      -40.548,
      15.56,
      -43.728,
      -40.449,
      15.56,
      -43.728,
      -40.449,
      14.66,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "172.0",
    polygon: [
      17.459,
      -10.449,
      0.05,
      16.729,
      -10.57,
      0.05,
      16.729,
      -10.57,
      2.78,
      16.729,
      -10.57,
      2.78,
      17.459,
      -10.449,
      2.78,
      17.459,
      -10.449,
      0.05,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1073.0",
    polygon: [
      -18.623,
      -39.106,
      10.16,
      -20.44,
      -39.205,
      10.16,
      -20.44,
      -39.205,
      11.06,
      -20.44,
      -39.205,
      11.06,
      -18.623,
      -39.106,
      11.06,
      -18.623,
      -39.106,
      10.16,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1247.0",
    polygon: [
      2.566,
      -32.307,
      8.36,
      2.566,
      -33.637,
      8.36,
      2.566,
      -33.637,
      10.82,
      2.566,
      -33.637,
      10.82,
      2.566,
      -32.307,
      10.82,
      2.566,
      -32.307,
      8.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "79.0",
    polygon: [
      -58.718,
      -41.29,
      4.86,
      -59.627,
      -41.34,
      4.86,
      -59.627,
      -41.34,
      5.106,
      -59.627,
      -41.34,
      5.106,
      -58.718,
      -41.29,
      5.106,
      -58.718,
      -41.29,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "430.0",
    polygon: [
      -41.702,
      -40.942,
      8.36,
      -42.764,
      -41.0,
      8.36,
      -42.764,
      -41.0,
      10.82,
      -42.764,
      -41.0,
      10.82,
      -41.702,
      -40.942,
      10.82,
      -41.702,
      -40.942,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1668.0",
    polygon: [
      14.588,
      -10.882,
      39.128,
      13.403,
      -11.075,
      39.128,
      13.403,
      -11.075,
      40.05,
      13.403,
      -11.075,
      40.05,
      14.588,
      -10.882,
      40.05,
      14.588,
      -10.882,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "912.0",
    polygon: [
      -47.379,
      -40.648,
      17.36,
      -48.397,
      -40.703,
      17.36,
      -48.397,
      -40.703,
      18.55,
      -48.397,
      -40.703,
      18.55,
      -47.379,
      -40.648,
      18.55,
      -47.379,
      -40.648,
      17.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1378.0",
    polygon: [
      12.061,
      -11.28,
      25.86,
      10.744,
      -11.498,
      25.86,
      10.744,
      -11.498,
      28.32,
      10.744,
      -11.498,
      28.32,
      12.061,
      -11.28,
      28.32,
      12.061,
      -11.28,
      25.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "715.0",
    polygon: [
      -13.037,
      -38.836,
      4.86,
      -13.946,
      -38.883,
      4.86,
      -13.946,
      -38.883,
      5.106,
      -13.946,
      -38.883,
      5.106,
      -13.037,
      -38.836,
      5.106,
      -13.037,
      -38.836,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "956.0",
    polygon: [
      -43.728,
      -40.449,
      11.06,
      -45.545,
      -40.548,
      11.06,
      -45.545,
      -40.548,
      11.96,
      -45.545,
      -40.548,
      11.96,
      -43.728,
      -40.449,
      11.96,
      -43.728,
      -40.449,
      11.06,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "252.0",
    polygon: [
      -53.136,
      -40.981,
      3.831,
      -54.045,
      -41.03,
      3.831,
      -54.045,
      -41.03,
      4.077,
      -54.045,
      -41.03,
      4.077,
      -53.136,
      -40.981,
      4.077,
      -53.136,
      -40.981,
      3.831,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1197.0",
    polygon: [
      2.566,
      -33.637,
      0.031,
      2.566,
      -36.298,
      0.031,
      2.566,
      -36.298,
      2.761,
      2.566,
      -36.298,
      2.761,
      2.566,
      -33.637,
      2.761,
      2.566,
      -33.637,
      0.031,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "40.0",
    polygon: [
      -65.723,
      -42.268,
      8.36,
      -66.805,
      -42.325,
      8.36,
      -66.805,
      -42.325,
      10.82,
      -66.805,
      -42.325,
      10.82,
      -65.723,
      -42.268,
      10.82,
      -65.723,
      -42.268,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "384.0",
    polygon: [
      -65.703,
      -42.311,
      0.05,
      -66.784,
      -42.369,
      0.05,
      -66.784,
      -42.369,
      2.78,
      -66.784,
      -42.369,
      2.78,
      -65.703,
      -42.311,
      2.78,
      -65.703,
      -42.311,
      0.05,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1509.0",
    polygon: [
      -8.967,
      -14.615,
      18.86,
      -10.119,
      -14.806,
      18.86,
      -10.119,
      -14.806,
      21.32,
      -10.119,
      -14.806,
      21.32,
      -8.967,
      -14.615,
      21.32,
      -8.967,
      -14.615,
      18.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "509.0",
    polygon: [
      0.756,
      -38.704,
      0.05,
      -0.326,
      -38.761,
      0.05,
      -0.326,
      -38.761,
      2.78,
      -0.326,
      -38.761,
      2.78,
      0.756,
      -38.704,
      2.78,
      0.756,
      -38.704,
      0.05,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "339.0",
    polygon: [
      -44.634,
      -40.521,
      5.844,
      -45.543,
      -40.571,
      5.844,
      -45.543,
      -40.571,
      6.09,
      -45.543,
      -40.571,
      6.09,
      -44.634,
      -40.521,
      6.09,
      -44.634,
      -40.521,
      5.844,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "470.0",
    polygon: [
      0.747,
      -38.673,
      11.86,
      -0.335,
      -38.73,
      11.86,
      -0.335,
      -38.73,
      14.32,
      -0.335,
      -38.73,
      14.32,
      0.747,
      -38.673,
      14.32,
      0.747,
      -38.673,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1557.0",
    polygon: [
      -10.075,
      -14.889,
      36.36,
      -11.26,
      -15.081,
      36.36,
      -11.26,
      -15.081,
      39.128,
      -11.26,
      -15.081,
      39.128,
      -10.075,
      -14.889,
      39.128,
      -10.075,
      -14.889,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1113.0",
    polygon: [
      -18.623,
      -39.106,
      13.76,
      -20.44,
      -39.205,
      13.76,
      -20.44,
      -39.205,
      14.66,
      -20.44,
      -39.205,
      14.66,
      -18.623,
      -39.106,
      14.66,
      -18.623,
      -39.106,
      13.76,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "122.0",
    polygon: [
      -59.632,
      -41.34,
      4.569,
      -60.541,
      -41.39,
      4.569,
      -60.541,
      -41.39,
      4.815,
      -60.541,
      -41.39,
      4.815,
      -59.632,
      -41.34,
      4.815,
      -59.632,
      -41.34,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "591.0",
    polygon: [
      -22.463,
      -39.33,
      5.106,
      -23.372,
      -39.377,
      5.106,
      -23.372,
      -39.377,
      5.352,
      -23.372,
      -39.377,
      5.352,
      -22.463,
      -39.33,
      5.352,
      -22.463,
      -39.33,
      5.106,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1287.0",
    polygon: [
      2.566,
      -21.663,
      15.36,
      2.566,
      -22.993,
      15.36,
      2.566,
      -22.993,
      17.82,
      2.566,
      -22.993,
      17.82,
      2.566,
      -21.663,
      17.82,
      2.566,
      -21.663,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "755.0",
    polygon: [
      -13.037,
      -38.836,
      4.569,
      -13.946,
      -38.883,
      4.569,
      -13.946,
      -38.883,
      4.815,
      -13.946,
      -38.883,
      4.815,
      -13.037,
      -38.836,
      4.815,
      -13.037,
      -38.836,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "913.0",
    polygon: [
      -45.545,
      -40.548,
      17.36,
      -47.361,
      -40.647,
      17.36,
      -47.361,
      -40.647,
      18.55,
      -47.361,
      -40.647,
      18.55,
      -45.545,
      -40.548,
      18.55,
      -45.545,
      -40.548,
      17.36,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "431.0",
    polygon: [
      -40.639,
      -40.884,
      8.36,
      -41.702,
      -40.942,
      8.36,
      -41.702,
      -40.942,
      10.82,
      -41.702,
      -40.942,
      10.82,
      -40.639,
      -40.884,
      10.82,
      -40.639,
      -40.884,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1153.0",
    polygon: [
      5.131,
      -12.313,
      8.36,
      3.979,
      -12.504,
      8.36,
      3.979,
      -12.504,
      10.82,
      3.979,
      -12.504,
      10.82,
      5.131,
      -12.313,
      10.82,
      5.131,
      -12.313,
      8.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "214.0",
    polygon: [
      -54.933,
      -41.082,
      5.844,
      -55.842,
      -41.132,
      5.844,
      -55.842,
      -41.132,
      6.09,
      -55.842,
      -41.132,
      6.09,
      -54.933,
      -41.082,
      6.09,
      -54.933,
      -41.082,
      5.844,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1074.0",
    polygon: [
      -21.509,
      -39.263,
      10.16,
      -23.325,
      -39.362,
      10.16,
      -23.325,
      -39.362,
      11.06,
      -23.325,
      -39.362,
      11.06,
      -21.509,
      -39.263,
      11.06,
      -21.509,
      -39.263,
      10.16,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1198.0",
    polygon: [
      2.566,
      -32.307,
      0.031,
      2.566,
      -33.637,
      0.031,
      2.566,
      -33.637,
      2.761,
      2.566,
      -33.637,
      2.761,
      2.566,
      -32.307,
      2.761,
      2.566,
      -32.307,
      0.031,
    ],
    "04-15 05:15": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "716.0",
    polygon: [
      -12.143,
      -38.789,
      4.86,
      -13.052,
      -38.836,
      4.86,
      -13.052,
      -38.836,
      5.106,
      -13.052,
      -38.836,
      5.106,
      -12.143,
      -38.789,
      5.106,
      -12.143,
      -38.789,
      4.86,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1248.0",
    polygon: [
      2.566,
      -30.976,
      8.36,
      2.566,
      -32.307,
      8.36,
      2.566,
      -32.307,
      10.82,
      2.566,
      -32.307,
      10.82,
      2.566,
      -30.976,
      10.82,
      2.566,
      -30.976,
      8.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1035.0",
    polygon: [
      -26.203,
      -39.518,
      8.36,
      -28.019,
      -39.617,
      8.36,
      -28.019,
      -39.617,
      9.26,
      -28.019,
      -39.617,
      9.26,
      -26.203,
      -39.518,
      9.26,
      -26.203,
      -39.518,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1379.0",
    polygon: [
      10.744,
      -11.498,
      25.86,
      9.428,
      -11.716,
      25.86,
      9.428,
      -11.716,
      28.32,
      9.428,
      -11.716,
      28.32,
      10.744,
      -11.498,
      28.32,
      10.744,
      -11.498,
      25.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "175.0",
    polygon: [
      16.003,
      -10.691,
      3.82,
      13.753,
      -11.064,
      3.82,
      13.753,
      -11.064,
      4.86,
      13.753,
      -11.064,
      4.86,
      16.003,
      -10.691,
      4.86,
      16.003,
      -10.691,
      3.82,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "957.0",
    polygon: [
      -47.379,
      -40.648,
      11.06,
      -48.397,
      -40.703,
      11.06,
      -48.397,
      -40.703,
      11.96,
      -48.397,
      -40.703,
      11.96,
      -47.379,
      -40.648,
      11.96,
      -47.379,
      -40.648,
      11.06,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "257.0",
    polygon: [
      -51.125,
      -40.875,
      4.86,
      -52.033,
      -40.924,
      4.86,
      -52.033,
      -40.924,
      5.106,
      -52.033,
      -40.924,
      5.106,
      -51.125,
      -40.875,
      5.106,
      -51.125,
      -40.875,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1510.0",
    polygon: [
      -7.415,
      -14.358,
      18.86,
      -8.567,
      -14.549,
      18.86,
      -8.567,
      -14.549,
      21.32,
      -8.567,
      -14.549,
      21.32,
      -7.415,
      -14.358,
      21.32,
      -7.415,
      -14.358,
      18.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "510.0",
    polygon: [
      -1.406,
      -38.818,
      0.05,
      -2.487,
      -38.874,
      0.05,
      -2.487,
      -38.874,
      2.78,
      -2.487,
      -38.874,
      2.78,
      -1.406,
      -38.818,
      2.78,
      -1.406,
      -38.818,
      0.05,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "123.0",
    polygon: [
      -58.723,
      -41.291,
      4.569,
      -59.632,
      -41.34,
      4.569,
      -59.632,
      -41.34,
      4.815,
      -59.632,
      -41.34,
      4.815,
      -58.723,
      -41.291,
      4.815,
      -58.723,
      -41.291,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "297.0",
    polygon: [
      -51.13,
      -40.875,
      4.569,
      -52.038,
      -40.925,
      4.569,
      -52.038,
      -40.925,
      4.815,
      -52.038,
      -40.925,
      4.815,
      -51.13,
      -40.875,
      4.815,
      -51.13,
      -40.875,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "80.0",
    polygon: [
      -57.825,
      -41.242,
      4.86,
      -58.733,
      -41.291,
      4.86,
      -58.733,
      -41.291,
      5.106,
      -58.733,
      -41.291,
      5.106,
      -57.825,
      -41.242,
      5.106,
      -57.825,
      -41.242,
      4.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "997.0",
    polygon: [
      -47.379,
      -40.648,
      14.66,
      -48.397,
      -40.703,
      14.66,
      -48.397,
      -40.703,
      15.56,
      -48.397,
      -40.703,
      15.56,
      -47.379,
      -40.648,
      15.56,
      -47.379,
      -40.648,
      14.66,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "41.0",
    polygon: [
      -64.643,
      -42.21,
      8.36,
      -65.724,
      -42.268,
      8.36,
      -65.724,
      -42.268,
      10.82,
      -65.724,
      -42.268,
      10.82,
      -64.643,
      -42.21,
      10.82,
      -64.643,
      -42.21,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1558.0",
    polygon: [
      -10.075,
      -14.889,
      39.128,
      -11.26,
      -15.081,
      39.128,
      -11.26,
      -15.081,
      40.05,
      -11.26,
      -15.081,
      40.05,
      -10.075,
      -14.889,
      40.05,
      -10.075,
      -14.889,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "385.0",
    polygon: [
      -62.459,
      -42.138,
      0.05,
      -63.541,
      -42.196,
      0.05,
      -63.541,
      -42.196,
      2.78,
      -63.541,
      -42.196,
      2.78,
      -62.459,
      -42.138,
      2.78,
      -62.459,
      -42.138,
      0.05,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "958.0",
    polygon: [
      -45.545,
      -40.548,
      11.06,
      -47.361,
      -40.647,
      11.06,
      -47.361,
      -40.647,
      11.96,
      -47.361,
      -40.647,
      11.96,
      -45.545,
      -40.548,
      11.96,
      -45.545,
      -40.548,
      11.06,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1380.0",
    polygon: [
      9.428,
      -11.716,
      25.86,
      8.112,
      -11.935,
      25.86,
      8.112,
      -11.935,
      28.32,
      8.112,
      -11.935,
      28.32,
      9.428,
      -11.716,
      28.32,
      9.428,
      -11.716,
      25.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1075.0",
    polygon: [
      -23.325,
      -39.362,
      10.16,
      -25.142,
      -39.46,
      10.16,
      -25.142,
      -39.46,
      11.06,
      -25.142,
      -39.46,
      11.06,
      -23.325,
      -39.362,
      11.06,
      -23.325,
      -39.362,
      10.16,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "432.0",
    polygon: [
      -39.577,
      -40.827,
      8.36,
      -40.639,
      -40.884,
      8.36,
      -40.639,
      -40.884,
      10.82,
      -40.639,
      -40.884,
      10.82,
      -39.577,
      -40.827,
      10.82,
      -39.577,
      -40.827,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "592.0",
    polygon: [
      -21.569,
      -39.283,
      5.106,
      -22.478,
      -39.33,
      5.106,
      -22.478,
      -39.33,
      5.352,
      -22.478,
      -39.33,
      5.352,
      -21.569,
      -39.283,
      5.352,
      -21.569,
      -39.283,
      5.106,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1036.0",
    polygon: [
      -28.019,
      -39.617,
      8.36,
      -29.836,
      -39.716,
      8.36,
      -29.836,
      -39.716,
      9.26,
      -29.836,
      -39.716,
      9.26,
      -28.019,
      -39.617,
      9.26,
      -28.019,
      -39.617,
      8.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1288.0",
    polygon: [
      2.566,
      -20.332,
      15.36,
      2.566,
      -21.663,
      15.36,
      2.566,
      -21.663,
      17.82,
      2.566,
      -21.663,
      17.82,
      2.566,
      -20.332,
      17.82,
      2.566,
      -20.332,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "756.0",
    polygon: [
      -12.143,
      -38.789,
      4.569,
      -13.052,
      -38.836,
      4.569,
      -13.052,
      -38.836,
      4.815,
      -13.052,
      -38.836,
      4.815,
      -12.143,
      -38.789,
      4.815,
      -12.143,
      -38.789,
      4.569,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "914.0",
    polygon: [
      -52.074,
      -40.904,
      17.36,
      -53.092,
      -40.959,
      17.36,
      -53.092,
      -40.959,
      18.55,
      -53.092,
      -40.959,
      18.55,
      -52.074,
      -40.904,
      18.55,
      -52.074,
      -40.904,
      17.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "340.0",
    polygon: [
      -43.741,
      -40.473,
      5.844,
      -44.649,
      -40.522,
      5.844,
      -44.649,
      -40.522,
      6.09,
      -44.649,
      -40.522,
      6.09,
      -43.741,
      -40.473,
      6.09,
      -43.741,
      -40.473,
      5.844,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "471.0",
    polygon: [
      -36.107,
      -40.656,
      15.36,
      -37.507,
      -40.732,
      15.36,
      -37.507,
      -40.732,
      16.18,
      -37.507,
      -40.732,
      16.18,
      -36.107,
      -40.656,
      16.18,
      -36.107,
      -40.656,
      15.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1154.0",
    polygon: [
      7.829,
      -11.865,
      8.36,
      6.678,
      -12.056,
      8.36,
      6.678,
      -12.056,
      10.82,
      6.678,
      -12.056,
      10.82,
      7.829,
      -11.865,
      10.82,
      7.829,
      -11.865,
      8.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "388.0",
    polygon: [
      -64.621,
      -42.254,
      0.05,
      -65.703,
      -42.311,
      0.05,
      -65.703,
      -42.311,
      2.78,
      -65.703,
      -42.311,
      2.78,
      -64.621,
      -42.254,
      2.78,
      -64.621,
      -42.254,
      0.05,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "124.0",
    polygon: [
      -57.83,
      -41.242,
      4.569,
      -58.738,
      -41.291,
      4.569,
      -58.738,
      -41.291,
      4.815,
      -58.738,
      -41.291,
      4.815,
      -57.83,
      -41.242,
      4.815,
      -57.83,
      -41.242,
      4.569,
    ],
    "04-15 12:15": 1,
  },
  {
    ID: "1511.0",
    polygon: [
      -10.119,
      -14.806,
      18.86,
      -11.27,
      -14.997,
      18.86,
      -11.27,
      -14.997,
      21.32,
      -11.27,
      -14.997,
      21.32,
      -10.119,
      -14.806,
      21.32,
      -10.119,
      -14.806,
      18.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "511.0",
    polygon: [
      -0.324,
      -38.761,
      0.05,
      -1.406,
      -38.818,
      0.05,
      -1.406,
      -38.818,
      2.78,
      -1.406,
      -38.818,
      2.78,
      -0.324,
      -38.761,
      2.78,
      -0.324,
      -38.761,
      0.05,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "176.0",
    polygon: [
      18.252,
      -10.318,
      3.82,
      16.003,
      -10.691,
      3.82,
      16.003,
      -10.691,
      4.86,
      16.003,
      -10.691,
      4.86,
      18.252,
      -10.318,
      4.86,
      18.252,
      -10.318,
      3.82,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "85.0",
    polygon: [
      -60.521,
      -41.389,
      5.106,
      -61.429,
      -41.438,
      5.106,
      -61.429,
      -41.438,
      5.352,
      -61.429,
      -41.438,
      5.352,
      -60.521,
      -41.389,
      5.352,
      -60.521,
      -41.389,
      5.106,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1249.0",
    polygon: [
      2.566,
      -29.646,
      8.36,
      2.566,
      -30.976,
      8.36,
      2.566,
      -30.976,
      10.82,
      2.566,
      -30.976,
      10.82,
      2.566,
      -29.646,
      10.82,
      2.566,
      -29.646,
      8.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "298.0",
    polygon: [
      -50.236,
      -40.826,
      4.569,
      -51.145,
      -40.876,
      4.569,
      -51.145,
      -40.876,
      4.815,
      -51.145,
      -40.876,
      4.815,
      -50.236,
      -40.826,
      4.815,
      -50.236,
      -40.826,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "717.0",
    polygon: [
      -14.839,
      -38.93,
      5.106,
      -15.748,
      -38.978,
      5.106,
      -15.748,
      -38.978,
      5.352,
      -15.748,
      -38.978,
      5.352,
      -14.839,
      -38.93,
      5.352,
      -14.839,
      -38.93,
      5.106,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "42.0",
    polygon: [
      -63.562,
      -42.152,
      8.36,
      -64.643,
      -42.21,
      8.36,
      -64.643,
      -42.21,
      10.82,
      -64.643,
      -42.21,
      10.82,
      -63.562,
      -42.152,
      10.82,
      -63.562,
      -42.152,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "998.0",
    polygon: [
      -45.545,
      -40.548,
      14.66,
      -47.361,
      -40.647,
      14.66,
      -47.361,
      -40.647,
      15.56,
      -47.361,
      -40.647,
      15.56,
      -45.545,
      -40.548,
      15.56,
      -45.545,
      -40.548,
      14.66,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "215.0",
    polygon: [
      -54.024,
      -41.033,
      5.844,
      -54.933,
      -41.082,
      5.844,
      -54.933,
      -41.082,
      6.09,
      -54.933,
      -41.082,
      6.09,
      -54.024,
      -41.033,
      6.09,
      -54.024,
      -41.033,
      5.844,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1204.0",
    polygon: [
      2.566,
      -15.01,
      0.031,
      2.566,
      -17.671,
      0.031,
      2.566,
      -17.671,
      2.761,
      2.566,
      -17.671,
      2.761,
      2.566,
      -15.01,
      2.761,
      2.566,
      -15.01,
      0.031,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1115.0",
    polygon: [
      -23.325,
      -39.362,
      13.76,
      -25.142,
      -39.46,
      13.76,
      -25.142,
      -39.46,
      14.66,
      -25.142,
      -39.46,
      14.66,
      -23.325,
      -39.362,
      14.66,
      -23.325,
      -39.362,
      13.76,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1670.0",
    polygon: [
      15.941,
      -10.662,
      39.128,
      14.757,
      -10.855,
      39.128,
      14.757,
      -10.855,
      40.05,
      14.757,
      -10.855,
      40.05,
      15.941,
      -10.662,
      40.05,
      15.941,
      -10.662,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "258.0",
    polygon: [
      -50.231,
      -40.826,
      4.86,
      -51.14,
      -40.876,
      4.86,
      -51.14,
      -40.876,
      5.106,
      -51.14,
      -40.876,
      5.106,
      -50.231,
      -40.826,
      5.106,
      -50.231,
      -40.826,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "299.0",
    polygon: [
      -49.327,
      -40.777,
      4.569,
      -50.236,
      -40.826,
      4.569,
      -50.236,
      -40.826,
      4.815,
      -50.236,
      -40.826,
      4.815,
      -49.327,
      -40.777,
      4.815,
      -49.327,
      -40.777,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "389.0",
    polygon: [
      -63.54,
      -42.196,
      0.05,
      -64.621,
      -42.254,
      0.05,
      -64.621,
      -42.254,
      2.78,
      -64.621,
      -42.254,
      2.78,
      -63.54,
      -42.196,
      2.78,
      -63.54,
      -42.196,
      0.05,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1512.0",
    polygon: [
      -0.12,
      -13.148,
      22.36,
      -1.272,
      -13.339,
      22.36,
      -1.272,
      -13.339,
      24.82,
      -1.272,
      -13.339,
      24.82,
      -0.12,
      -13.148,
      24.82,
      -0.12,
      -13.148,
      22.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1116.0",
    polygon: [
      -26.203,
      -39.518,
      13.76,
      -28.019,
      -39.617,
      13.76,
      -28.019,
      -39.617,
      14.66,
      -28.019,
      -39.617,
      14.66,
      -26.203,
      -39.518,
      14.66,
      -26.203,
      -39.518,
      13.76,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "43.0",
    polygon: [
      -62.48,
      -42.094,
      8.36,
      -63.562,
      -42.152,
      8.36,
      -63.562,
      -42.152,
      10.82,
      -63.562,
      -42.152,
      10.82,
      -62.48,
      -42.094,
      10.82,
      -62.48,
      -42.094,
      8.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "999.0",
    polygon: [
      -52.074,
      -40.904,
      14.66,
      -53.092,
      -40.959,
      14.66,
      -53.092,
      -40.959,
      15.56,
      -53.092,
      -40.959,
      15.56,
      -52.074,
      -40.904,
      15.56,
      -52.074,
      -40.904,
      14.66,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1289.0",
    polygon: [
      2.566,
      -19.002,
      15.36,
      2.566,
      -20.332,
      15.36,
      2.566,
      -20.332,
      17.82,
      2.566,
      -20.332,
      17.82,
      2.566,
      -19.002,
      17.82,
      2.566,
      -19.002,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "86.0",
    polygon: [
      -59.627,
      -41.34,
      5.106,
      -60.536,
      -41.389,
      5.106,
      -60.536,
      -41.389,
      5.352,
      -60.536,
      -41.389,
      5.352,
      -59.627,
      -41.34,
      5.352,
      -59.627,
      -41.34,
      5.106,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1155.0",
    polygon: [
      10.528,
      -11.417,
      8.36,
      9.376,
      -11.608,
      8.36,
      9.376,
      -11.608,
      10.82,
      9.376,
      -11.608,
      10.82,
      10.528,
      -11.417,
      10.82,
      10.528,
      -11.417,
      8.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1669.0",
    polygon: [
      15.941,
      -10.662,
      36.36,
      14.757,
      -10.855,
      36.36,
      14.757,
      -10.855,
      39.128,
      14.757,
      -10.855,
      39.128,
      15.941,
      -10.662,
      39.128,
      15.941,
      -10.662,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1671.0",
    polygon: [
      17.295,
      -10.442,
      36.36,
      16.111,
      -10.635,
      36.36,
      16.111,
      -10.635,
      39.128,
      16.111,
      -10.635,
      39.128,
      17.295,
      -10.442,
      39.128,
      17.295,
      -10.442,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "259.0",
    polygon: [
      -49.322,
      -40.777,
      4.86,
      -50.231,
      -40.826,
      4.86,
      -50.231,
      -40.826,
      5.106,
      -50.231,
      -40.826,
      5.106,
      -49.322,
      -40.777,
      5.106,
      -49.322,
      -40.777,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1076.0",
    polygon: [
      -26.203,
      -39.518,
      10.16,
      -28.019,
      -39.617,
      10.16,
      -28.019,
      -39.617,
      11.06,
      -28.019,
      -39.617,
      11.06,
      -26.203,
      -39.518,
      11.06,
      -26.203,
      -39.518,
      10.16,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1559.0",
    polygon: [
      1.052,
      -13.081,
      36.36,
      -0.132,
      -13.273,
      36.36,
      -0.132,
      -13.273,
      39.128,
      -0.132,
      -13.273,
      39.128,
      1.052,
      -13.081,
      39.128,
      1.052,
      -13.081,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "512.0",
    polygon: [
      -1.406,
      -38.818,
      2.78,
      -3.569,
      -38.931,
      2.78,
      -3.569,
      -38.931,
      3.82,
      -3.569,
      -38.931,
      3.82,
      -1.406,
      -38.818,
      3.82,
      -1.406,
      -38.818,
      2.78,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "473.0",
    polygon: [
      -36.107,
      -40.656,
      17.0,
      -37.507,
      -40.732,
      17.0,
      -37.507,
      -40.732,
      17.82,
      -37.507,
      -40.732,
      17.82,
      -36.107,
      -40.656,
      17.82,
      -36.107,
      -40.656,
      17.0,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1037.0",
    polygon: [
      -12.119,
      -38.752,
      17.36,
      -13.935,
      -38.851,
      17.36,
      -13.935,
      -38.851,
      18.55,
      -13.935,
      -38.851,
      18.55,
      -12.119,
      -38.752,
      18.55,
      -12.119,
      -38.752,
      17.36,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "217.0",
    polygon: [
      -55.827,
      -41.131,
      6.09,
      -56.735,
      -41.181,
      6.09,
      -56.735,
      -41.181,
      6.336,
      -56.735,
      -41.181,
      6.336,
      -55.827,
      -41.131,
      6.336,
      -55.827,
      -41.131,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "341.0",
    polygon: [
      -46.437,
      -40.619,
      6.09,
      -47.345,
      -40.669,
      6.09,
      -47.345,
      -40.669,
      6.336,
      -47.345,
      -40.669,
      6.336,
      -46.437,
      -40.619,
      6.336,
      -46.437,
      -40.619,
      6.09,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "1250.0",
    polygon: [
      2.566,
      -28.315,
      8.36,
      2.566,
      -29.646,
      8.36,
      2.566,
      -29.646,
      10.82,
      2.566,
      -29.646,
      10.82,
      2.566,
      -28.315,
      10.82,
      2.566,
      -28.315,
      8.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1290.0",
    polygon: [
      2.566,
      -17.671,
      15.36,
      2.566,
      -19.002,
      15.36,
      2.566,
      -19.002,
      17.82,
      2.566,
      -19.002,
      17.82,
      2.566,
      -17.671,
      17.82,
      2.566,
      -17.671,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1205.0",
    polygon: [
      2.566,
      -33.637,
      2.78,
      2.566,
      -36.298,
      2.78,
      2.566,
      -36.298,
      3.82,
      2.566,
      -36.298,
      3.82,
      2.566,
      -33.637,
      3.82,
      2.566,
      -33.637,
      2.78,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "915.0",
    polygon: [
      -56.772,
      -41.16,
      17.36,
      -57.791,
      -41.215,
      17.36,
      -57.791,
      -41.215,
      18.55,
      -57.791,
      -41.215,
      18.55,
      -56.772,
      -41.16,
      18.55,
      -56.772,
      -41.16,
      17.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "593.0",
    polygon: [
      -24.266,
      -39.424,
      5.352,
      -25.174,
      -39.472,
      5.352,
      -25.174,
      -39.472,
      5.598,
      -25.174,
      -39.472,
      5.598,
      -24.266,
      -39.424,
      5.598,
      -24.266,
      -39.424,
      5.352,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "633.0",
    polygon: [
      -24.266,
      -39.424,
      4.077,
      -25.174,
      -39.472,
      4.077,
      -25.174,
      -39.472,
      4.323,
      -25.174,
      -39.472,
      4.323,
      -24.266,
      -39.424,
      4.323,
      -24.266,
      -39.424,
      4.077,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "177.0",
    polygon: [
      10.608,
      -11.573,
      3.82,
      8.359,
      -11.946,
      3.82,
      8.359,
      -11.946,
      4.86,
      8.359,
      -11.946,
      4.86,
      10.608,
      -11.573,
      4.86,
      10.608,
      -11.573,
      3.82,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "472.0",
    polygon: [
      -36.107,
      -40.656,
      16.18,
      -37.507,
      -40.732,
      16.18,
      -37.507,
      -40.732,
      17.0,
      -37.507,
      -40.732,
      17.0,
      -36.107,
      -40.656,
      17.0,
      -36.107,
      -40.656,
      16.18,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "718.0",
    polygon: [
      -13.946,
      -38.883,
      5.106,
      -14.854,
      -38.931,
      5.106,
      -14.854,
      -38.931,
      5.352,
      -14.854,
      -38.931,
      5.352,
      -13.946,
      -38.883,
      5.352,
      -13.946,
      -38.883,
      5.106,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "434.0",
    polygon: [
      -34.108,
      -40.529,
      8.36,
      -35.189,
      -40.588,
      8.36,
      -35.189,
      -40.588,
      10.82,
      -35.189,
      -40.588,
      10.82,
      -34.108,
      -40.529,
      10.82,
      -34.108,
      -40.529,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "959.0",
    polygon: [
      -52.074,
      -40.904,
      11.06,
      -53.092,
      -40.959,
      11.06,
      -53.092,
      -40.959,
      11.96,
      -53.092,
      -40.959,
      11.96,
      -52.074,
      -40.904,
      11.96,
      -52.074,
      -40.904,
      11.06,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "594.0",
    polygon: [
      -23.372,
      -39.377,
      5.352,
      -24.281,
      -39.425,
      5.352,
      -24.281,
      -39.425,
      5.598,
      -24.281,
      -39.425,
      5.598,
      -23.372,
      -39.377,
      5.598,
      -23.372,
      -39.377,
      5.352,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "260.0",
    polygon: [
      -48.429,
      -40.728,
      4.86,
      -49.337,
      -40.778,
      4.86,
      -49.337,
      -40.778,
      5.106,
      -49.337,
      -40.778,
      5.106,
      -48.429,
      -40.728,
      5.106,
      -48.429,
      -40.728,
      4.86,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1381.0",
    polygon: [
      8.112,
      -11.935,
      25.86,
      6.795,
      -12.153,
      25.86,
      6.795,
      -12.153,
      28.32,
      6.795,
      -12.153,
      28.32,
      8.112,
      -11.935,
      28.32,
      8.112,
      -11.935,
      25.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1038.0",
    polygon: [
      -15.769,
      -38.951,
      17.36,
      -16.788,
      -39.006,
      17.36,
      -16.788,
      -39.006,
      18.55,
      -16.788,
      -39.006,
      18.55,
      -15.769,
      -38.951,
      18.55,
      -15.769,
      -38.951,
      17.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1560.0",
    polygon: [
      1.052,
      -13.081,
      39.128,
      -0.132,
      -13.273,
      39.128,
      -0.132,
      -13.273,
      40.05,
      -0.132,
      -13.273,
      40.05,
      1.052,
      -13.081,
      40.05,
      1.052,
      -13.081,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "960.0",
    polygon: [
      -56.772,
      -41.16,
      11.06,
      -57.791,
      -41.215,
      11.06,
      -57.791,
      -41.215,
      11.96,
      -57.791,
      -41.215,
      11.96,
      -56.772,
      -41.16,
      11.96,
      -56.772,
      -41.16,
      11.06,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "433.0",
    polygon: [
      -38.514,
      -40.769,
      8.36,
      -39.577,
      -40.827,
      8.36,
      -39.577,
      -40.827,
      10.82,
      -39.577,
      -40.827,
      10.82,
      -38.514,
      -40.769,
      10.82,
      -38.514,
      -40.769,
      8.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "916.0",
    polygon: [
      -48.416,
      -40.704,
      17.36,
      -50.233,
      -40.803,
      17.36,
      -50.233,
      -40.803,
      18.55,
      -50.233,
      -40.803,
      18.55,
      -48.416,
      -40.704,
      18.55,
      -48.416,
      -40.704,
      17.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1077.0",
    polygon: [
      -12.119,
      -38.752,
      11.06,
      -13.935,
      -38.851,
      11.06,
      -13.935,
      -38.851,
      11.96,
      -13.935,
      -38.851,
      11.96,
      -12.119,
      -38.752,
      11.96,
      -12.119,
      -38.752,
      11.06,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "342.0",
    polygon: [
      -45.543,
      -40.571,
      6.09,
      -46.452,
      -40.62,
      6.09,
      -46.452,
      -40.62,
      6.336,
      -46.452,
      -40.62,
      6.336,
      -45.543,
      -40.571,
      6.336,
      -45.543,
      -40.571,
      6.09,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1114.0",
    polygon: [
      -21.509,
      -39.263,
      13.76,
      -23.325,
      -39.362,
      13.76,
      -23.325,
      -39.362,
      14.66,
      -23.325,
      -39.362,
      14.66,
      -21.509,
      -39.263,
      14.66,
      -21.509,
      -39.263,
      13.76,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "216.0",
    polygon: [
      -53.131,
      -40.984,
      5.844,
      -54.039,
      -41.034,
      5.844,
      -54.039,
      -41.034,
      6.09,
      -54.039,
      -41.034,
      6.09,
      -53.131,
      -40.984,
      6.09,
      -53.131,
      -40.984,
      5.844,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1156.0",
    polygon: [
      13.226,
      -10.97,
      8.36,
      12.075,
      -11.161,
      8.36,
      12.075,
      -11.161,
      10.82,
      12.075,
      -11.161,
      10.82,
      13.226,
      -10.97,
      10.82,
      13.226,
      -10.97,
      8.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1382.0",
    polygon: [
      6.795,
      -12.153,
      25.86,
      5.479,
      -12.371,
      25.86,
      5.479,
      -12.371,
      28.32,
      5.479,
      -12.371,
      28.32,
      6.795,
      -12.153,
      28.32,
      6.795,
      -12.153,
      25.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "178.0",
    polygon: [
      12.857,
      -11.2,
      3.82,
      10.608,
      -11.573,
      3.82,
      10.608,
      -11.573,
      4.86,
      10.608,
      -11.573,
      4.86,
      12.857,
      -11.2,
      4.86,
      12.857,
      -11.2,
      3.82,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1251.0",
    polygon: [
      2.566,
      -26.985,
      8.36,
      2.566,
      -28.315,
      8.36,
      2.566,
      -28.315,
      10.82,
      2.566,
      -28.315,
      10.82,
      2.566,
      -26.985,
      10.82,
      2.566,
      -26.985,
      8.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "719.0",
    polygon: [
      -13.037,
      -38.836,
      5.106,
      -13.946,
      -38.883,
      5.106,
      -13.946,
      -38.883,
      5.352,
      -13.946,
      -38.883,
      5.352,
      -13.037,
      -38.836,
      5.352,
      -13.037,
      -38.836,
      5.106,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1672.0",
    polygon: [
      17.295,
      -10.442,
      39.128,
      16.111,
      -10.635,
      39.128,
      16.111,
      -10.635,
      40.05,
      16.111,
      -10.635,
      40.05,
      17.295,
      -10.442,
      40.05,
      17.295,
      -10.442,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "300.0",
    polygon: [
      -48.434,
      -40.728,
      4.569,
      -49.342,
      -40.778,
      4.569,
      -49.342,
      -40.778,
      4.815,
      -49.342,
      -40.778,
      4.815,
      -48.434,
      -40.728,
      4.815,
      -48.434,
      -40.728,
      4.569,
    ],
    "04-15 09:45": 1,
  },
  {
    ID: "390.0",
    polygon: [
      -64.621,
      -42.254,
      2.78,
      -66.784,
      -42.369,
      2.78,
      -66.784,
      -42.369,
      3.82,
      -66.784,
      -42.369,
      3.82,
      -64.621,
      -42.254,
      3.82,
      -64.621,
      -42.254,
      2.78,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1117.0",
    polygon: [
      -12.119,
      -38.752,
      14.66,
      -13.935,
      -38.851,
      14.66,
      -13.935,
      -38.851,
      15.56,
      -13.935,
      -38.851,
      15.56,
      -12.119,
      -38.752,
      15.56,
      -12.119,
      -38.752,
      14.66,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1513.0",
    polygon: [
      1.031,
      -12.957,
      22.36,
      -0.12,
      -13.148,
      22.36,
      -0.12,
      -13.148,
      24.82,
      -0.12,
      -13.148,
      24.82,
      1.031,
      -12.957,
      24.82,
      1.031,
      -12.957,
      22.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1206.0",
    polygon: [
      2.566,
      -30.976,
      2.78,
      2.566,
      -33.637,
      2.78,
      2.566,
      -33.637,
      3.82,
      2.566,
      -33.637,
      3.82,
      2.566,
      -30.976,
      3.82,
      2.566,
      -30.976,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "1039.0",
    polygon: [
      -13.935,
      -38.851,
      17.36,
      -15.751,
      -38.95,
      17.36,
      -15.751,
      -38.95,
      18.55,
      -15.751,
      -38.95,
      18.55,
      -13.935,
      -38.851,
      18.55,
      -13.935,
      -38.851,
      17.36,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1078.0",
    polygon: [
      -15.769,
      -38.951,
      11.06,
      -16.788,
      -39.006,
      11.06,
      -16.788,
      -39.006,
      11.96,
      -16.788,
      -39.006,
      11.96,
      -15.769,
      -38.951,
      11.96,
      -15.769,
      -38.951,
      11.06,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1563.0",
    polygon: [
      -4.292,
      -13.949,
      39.128,
      -5.916,
      -14.213,
      39.128,
      -5.916,
      -14.213,
      40.05,
      -5.916,
      -14.213,
      40.05,
      -4.292,
      -13.949,
      40.05,
      -4.292,
      -13.949,
      39.128,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "343.0",
    polygon: [
      -44.634,
      -40.521,
      6.09,
      -45.543,
      -40.571,
      6.09,
      -45.543,
      -40.571,
      6.336,
      -45.543,
      -40.571,
      6.336,
      -44.634,
      -40.521,
      6.336,
      -44.634,
      -40.521,
      6.09,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "474.0",
    polygon: [
      -41.701,
      -40.961,
      15.36,
      -42.763,
      -41.018,
      15.36,
      -42.763,
      -41.018,
      17.82,
      -42.763,
      -41.018,
      17.82,
      -41.701,
      -40.961,
      17.82,
      -41.701,
      -40.961,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1157.0",
    polygon: [
      15.925,
      -10.522,
      8.36,
      14.773,
      -10.713,
      8.36,
      14.773,
      -10.713,
      10.82,
      14.773,
      -10.713,
      10.82,
      15.925,
      -10.522,
      10.82,
      15.925,
      -10.522,
      8.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "595.0",
    polygon: [
      -22.463,
      -39.33,
      5.352,
      -23.372,
      -39.377,
      5.352,
      -23.372,
      -39.377,
      5.598,
      -23.372,
      -39.377,
      5.598,
      -22.463,
      -39.33,
      5.598,
      -22.463,
      -39.33,
      5.352,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "917.0",
    polygon: [
      -50.233,
      -40.803,
      17.36,
      -52.049,
      -40.902,
      17.36,
      -52.049,
      -40.902,
      18.55,
      -52.049,
      -40.902,
      18.55,
      -50.233,
      -40.803,
      18.55,
      -50.233,
      -40.803,
      17.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "634.0",
    polygon: [
      -23.372,
      -39.377,
      4.077,
      -24.281,
      -39.425,
      4.077,
      -24.281,
      -39.425,
      4.323,
      -24.281,
      -39.425,
      4.323,
      -23.372,
      -39.377,
      4.323,
      -23.372,
      -39.377,
      4.077,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "513.0",
    polygon: [
      0.757,
      -38.704,
      2.78,
      -1.406,
      -38.818,
      2.78,
      -1.406,
      -38.818,
      3.82,
      -1.406,
      -38.818,
      3.82,
      0.757,
      -38.704,
      3.82,
      0.757,
      -38.704,
      2.78,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1207.0",
    polygon: [
      2.566,
      -28.315,
      2.78,
      2.566,
      -30.976,
      2.78,
      2.566,
      -30.976,
      3.82,
      2.566,
      -30.976,
      3.82,
      2.566,
      -28.315,
      3.82,
      2.566,
      -28.315,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1383.0",
    polygon: [
      5.479,
      -12.371,
      25.86,
      4.163,
      -12.59,
      25.86,
      4.163,
      -12.59,
      28.32,
      4.163,
      -12.59,
      28.32,
      5.479,
      -12.371,
      28.32,
      5.479,
      -12.371,
      25.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1291.0",
    polygon: [
      2.566,
      -16.341,
      15.36,
      2.566,
      -17.671,
      15.36,
      2.566,
      -17.671,
      17.82,
      2.566,
      -17.671,
      17.82,
      2.566,
      -16.341,
      17.82,
      2.566,
      -16.341,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "720.0",
    polygon: [
      -12.143,
      -38.789,
      5.106,
      -13.052,
      -38.836,
      5.106,
      -13.052,
      -38.836,
      5.352,
      -13.052,
      -38.836,
      5.352,
      -12.143,
      -38.789,
      5.352,
      -12.143,
      -38.789,
      5.106,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "87.0",
    polygon: [
      -58.718,
      -41.29,
      5.106,
      -59.627,
      -41.34,
      5.106,
      -59.627,
      -41.34,
      5.352,
      -59.627,
      -41.34,
      5.352,
      -58.718,
      -41.29,
      5.352,
      -58.718,
      -41.29,
      5.106,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1252.0",
    polygon: [
      2.566,
      -25.654,
      8.36,
      2.566,
      -26.985,
      8.36,
      2.566,
      -26.985,
      10.82,
      2.566,
      -26.985,
      10.82,
      2.566,
      -25.654,
      10.82,
      2.566,
      -25.654,
      8.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "435.0",
    polygon: [
      -33.027,
      -40.47,
      8.36,
      -34.109,
      -40.529,
      8.36,
      -34.109,
      -40.529,
      10.82,
      -34.109,
      -40.529,
      10.82,
      -33.027,
      -40.47,
      10.82,
      -33.027,
      -40.47,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1674.0",
    polygon: [
      -36.676,
      -7.155,
      36.36,
      -37.498,
      -7.155,
      36.36,
      -37.498,
      -7.155,
      39.128,
      -37.498,
      -7.155,
      39.128,
      -36.676,
      -7.155,
      39.128,
      -36.676,
      -7.155,
      36.36,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "44.0",
    polygon: [
      -67.715,
      -42.369,
      4.86,
      -69.115,
      -42.444,
      4.86,
      -69.115,
      -42.444,
      5.68,
      -69.115,
      -42.444,
      5.68,
      -67.715,
      -42.369,
      5.68,
      -67.715,
      -42.369,
      4.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "261.0",
    polygon: [
      -51.125,
      -40.875,
      5.106,
      -52.033,
      -40.924,
      5.106,
      -52.033,
      -40.924,
      5.352,
      -52.033,
      -40.924,
      5.352,
      -51.125,
      -40.875,
      5.352,
      -51.125,
      -40.875,
      5.106,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "218.0",
    polygon: [
      -54.933,
      -41.082,
      6.09,
      -55.842,
      -41.132,
      6.09,
      -55.842,
      -41.132,
      6.336,
      -55.842,
      -41.132,
      6.336,
      -54.933,
      -41.082,
      6.336,
      -54.933,
      -41.082,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1000.0",
    polygon: [
      -56.772,
      -41.16,
      14.66,
      -57.791,
      -41.215,
      14.66,
      -57.791,
      -41.215,
      15.56,
      -57.791,
      -41.215,
      15.56,
      -56.772,
      -41.16,
      15.56,
      -56.772,
      -41.16,
      14.66,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "961.0",
    polygon: [
      -48.416,
      -40.704,
      11.06,
      -50.233,
      -40.803,
      11.06,
      -50.233,
      -40.803,
      11.96,
      -50.233,
      -40.803,
      11.96,
      -48.416,
      -40.704,
      11.96,
      -48.416,
      -40.704,
      11.06,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "88.0",
    polygon: [
      -57.825,
      -41.242,
      5.106,
      -58.733,
      -41.291,
      5.106,
      -58.733,
      -41.291,
      5.352,
      -58.733,
      -41.291,
      5.352,
      -57.825,
      -41.242,
      5.352,
      -57.825,
      -41.242,
      5.106,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "45.0",
    polygon: [
      -67.715,
      -42.369,
      5.68,
      -69.115,
      -42.444,
      5.68,
      -69.115,
      -42.444,
      6.5,
      -69.115,
      -42.444,
      6.5,
      -67.715,
      -42.369,
      6.5,
      -67.715,
      -42.369,
      5.68,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1292.0",
    polygon: [
      2.566,
      -15.01,
      15.36,
      2.566,
      -16.341,
      15.36,
      2.566,
      -16.341,
      17.82,
      2.566,
      -16.341,
      17.82,
      2.566,
      -15.01,
      17.82,
      2.566,
      -15.01,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "635.0",
    polygon: [
      -22.463,
      -39.33,
      4.077,
      -23.372,
      -39.377,
      4.077,
      -23.372,
      -39.377,
      4.323,
      -23.372,
      -39.377,
      4.323,
      -22.463,
      -39.33,
      4.323,
      -22.463,
      -39.33,
      4.077,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1001.0",
    polygon: [
      -48.416,
      -40.704,
      14.66,
      -50.233,
      -40.803,
      14.66,
      -50.233,
      -40.803,
      15.56,
      -50.233,
      -40.803,
      15.56,
      -48.416,
      -40.704,
      15.56,
      -48.416,
      -40.704,
      14.66,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1118.0",
    polygon: [
      -15.769,
      -38.951,
      14.66,
      -16.788,
      -39.006,
      14.66,
      -16.788,
      -39.006,
      15.56,
      -16.788,
      -39.006,
      15.56,
      -15.769,
      -38.951,
      15.56,
      -15.769,
      -38.951,
      14.66,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1514.0",
    polygon: [
      -4.364,
      -13.852,
      22.36,
      -5.868,
      -14.101,
      22.36,
      -5.868,
      -14.101,
      24.82,
      -5.868,
      -14.101,
      24.82,
      -4.364,
      -13.852,
      24.82,
      -4.364,
      -13.852,
      22.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "179.0",
    polygon: [
      10.608,
      -11.573,
      4.86,
      8.359,
      -11.946,
      4.86,
      8.359,
      -11.946,
      7.32,
      8.359,
      -11.946,
      7.32,
      10.608,
      -11.573,
      7.32,
      10.608,
      -11.573,
      4.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "1079.0",
    polygon: [
      -13.935,
      -38.851,
      11.06,
      -15.751,
      -38.95,
      11.06,
      -15.751,
      -38.95,
      11.96,
      -15.751,
      -38.95,
      11.96,
      -13.935,
      -38.851,
      11.96,
      -13.935,
      -38.851,
      11.06,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "962.0",
    polygon: [
      -50.233,
      -40.803,
      11.06,
      -52.049,
      -40.902,
      11.06,
      -52.049,
      -40.902,
      11.96,
      -52.049,
      -40.902,
      11.96,
      -50.233,
      -40.803,
      11.96,
      -50.233,
      -40.803,
      11.06,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "721.0",
    polygon: [
      -14.839,
      -38.93,
      5.352,
      -15.748,
      -38.978,
      5.352,
      -15.748,
      -38.978,
      5.598,
      -15.748,
      -38.978,
      5.598,
      -14.839,
      -38.93,
      5.598,
      -14.839,
      -38.93,
      5.352,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1253.0",
    polygon: [
      2.566,
      -24.324,
      8.36,
      2.566,
      -25.654,
      8.36,
      2.566,
      -25.654,
      10.82,
      2.566,
      -25.654,
      10.82,
      2.566,
      -24.324,
      10.82,
      2.566,
      -24.324,
      8.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "180.0",
    polygon: [
      12.857,
      -11.2,
      4.86,
      10.608,
      -11.573,
      4.86,
      10.608,
      -11.573,
      7.32,
      10.608,
      -11.573,
      7.32,
      12.857,
      -11.2,
      7.32,
      12.857,
      -11.2,
      4.86,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1208.0",
    polygon: [
      2.566,
      -25.654,
      2.78,
      2.566,
      -28.315,
      2.78,
      2.566,
      -28.315,
      3.82,
      2.566,
      -28.315,
      3.82,
      2.566,
      -25.654,
      3.82,
      2.566,
      -25.654,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1675.0",
    polygon: [
      -35.853,
      -7.155,
      36.36,
      -36.676,
      -7.155,
      36.36,
      -36.676,
      -7.155,
      39.128,
      -36.676,
      -7.155,
      39.128,
      -35.853,
      -7.155,
      39.128,
      -35.853,
      -7.155,
      36.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1158.0",
    polygon: [
      18.623,
      -10.075,
      8.36,
      17.472,
      -10.266,
      8.36,
      17.472,
      -10.266,
      10.82,
      17.472,
      -10.266,
      10.82,
      18.623,
      -10.075,
      10.82,
      18.623,
      -10.075,
      8.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1119.0",
    polygon: [
      -13.935,
      -38.851,
      14.66,
      -15.751,
      -38.95,
      14.66,
      -15.751,
      -38.95,
      15.56,
      -15.751,
      -38.95,
      15.56,
      -13.935,
      -38.851,
      15.56,
      -13.935,
      -38.851,
      14.66,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "515.0",
    polygon: [
      -9.019,
      -39.237,
      0.05,
      -10.082,
      -39.292,
      0.05,
      -10.082,
      -39.292,
      2.78,
      -10.082,
      -39.292,
      2.78,
      -9.019,
      -39.237,
      2.78,
      -9.019,
      -39.237,
      0.05,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "596.0",
    polygon: [
      -21.569,
      -39.283,
      5.352,
      -22.478,
      -39.33,
      5.352,
      -22.478,
      -39.33,
      5.598,
      -22.478,
      -39.33,
      5.598,
      -21.569,
      -39.283,
      5.598,
      -21.569,
      -39.283,
      5.352,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1040.0",
    polygon: [
      -20.464,
      -39.206,
      17.36,
      -21.483,
      -39.261,
      17.36,
      -21.483,
      -39.261,
      18.55,
      -21.483,
      -39.261,
      18.55,
      -20.464,
      -39.206,
      18.55,
      -20.464,
      -39.206,
      17.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1566.0",
    polygon: [
      -4.292,
      -13.949,
      36.36,
      -5.104,
      -14.081,
      36.36,
      -5.104,
      -14.081,
      39.128,
      -5.104,
      -14.081,
      39.128,
      -4.292,
      -13.949,
      39.128,
      -4.292,
      -13.949,
      36.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "476.0",
    polygon: [
      -39.576,
      -40.845,
      15.36,
      -40.638,
      -40.903,
      15.36,
      -40.638,
      -40.903,
      17.82,
      -40.638,
      -40.903,
      17.82,
      -39.576,
      -40.845,
      17.82,
      -39.576,
      -40.845,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "262.0",
    polygon: [
      -50.231,
      -40.826,
      5.106,
      -51.14,
      -40.876,
      5.106,
      -51.14,
      -40.876,
      5.352,
      -51.14,
      -40.876,
      5.352,
      -50.231,
      -40.826,
      5.352,
      -50.231,
      -40.826,
      5.106,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1041.0",
    polygon: [
      -25.163,
      -39.462,
      17.36,
      -26.182,
      -39.517,
      17.36,
      -26.182,
      -39.517,
      18.55,
      -26.182,
      -39.517,
      18.55,
      -25.163,
      -39.462,
      18.55,
      -25.163,
      -39.462,
      17.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "514.0",
    polygon: [
      -10.082,
      -39.292,
      0.05,
      -11.145,
      -39.348,
      0.05,
      -11.145,
      -39.348,
      2.78,
      -11.145,
      -39.348,
      2.78,
      -10.082,
      -39.292,
      2.78,
      -10.082,
      -39.292,
      0.05,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1676.0",
    polygon: [
      -35.853,
      -7.155,
      39.128,
      -37.498,
      -7.155,
      39.128,
      -37.498,
      -7.155,
      40.05,
      -37.498,
      -7.155,
      40.05,
      -35.853,
      -7.155,
      40.05,
      -35.853,
      -7.155,
      39.128,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "46.0",
    polygon: [
      -67.715,
      -42.369,
      6.5,
      -69.115,
      -42.444,
      6.5,
      -69.115,
      -42.444,
      7.32,
      -69.115,
      -42.444,
      7.32,
      -67.715,
      -42.369,
      7.32,
      -67.715,
      -42.369,
      6.5,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "263.0",
    polygon: [
      -49.322,
      -40.777,
      5.106,
      -50.231,
      -40.826,
      5.106,
      -50.231,
      -40.826,
      5.352,
      -50.231,
      -40.826,
      5.352,
      -49.322,
      -40.777,
      5.352,
      -49.322,
      -40.777,
      5.106,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "919.0",
    polygon: [
      -54.935,
      -41.06,
      17.36,
      -56.751,
      -41.159,
      17.36,
      -56.751,
      -41.159,
      18.55,
      -56.751,
      -41.159,
      18.55,
      -54.935,
      -41.06,
      18.55,
      -54.935,
      -41.06,
      17.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "963.0",
    polygon: [
      -53.118,
      -40.961,
      11.06,
      -54.935,
      -41.06,
      11.06,
      -54.935,
      -41.06,
      11.96,
      -54.935,
      -41.06,
      11.96,
      -53.118,
      -40.961,
      11.96,
      -53.118,
      -40.961,
      11.06,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1159.0",
    polygon: [
      3.979,
      -12.504,
      8.36,
      2.827,
      -12.695,
      8.36,
      2.827,
      -12.695,
      10.82,
      2.827,
      -12.695,
      10.82,
      3.979,
      -12.504,
      10.82,
      3.979,
      -12.504,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "397.0",
    polygon: [
      -73.297,
      -42.701,
      0.05,
      -74.359,
      -42.757,
      0.05,
      -74.359,
      -42.757,
      2.78,
      -74.359,
      -42.757,
      2.78,
      -73.297,
      -42.701,
      2.78,
      -73.297,
      -42.701,
      0.05,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1568.0",
    polygon: [
      -5.104,
      -14.081,
      36.36,
      -5.916,
      -14.213,
      36.36,
      -5.916,
      -14.213,
      39.128,
      -5.916,
      -14.213,
      39.128,
      -5.104,
      -14.081,
      39.128,
      -5.104,
      -14.081,
      36.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "391.0",
    polygon: [
      -62.458,
      -42.138,
      2.78,
      -64.621,
      -42.254,
      2.78,
      -64.621,
      -42.254,
      3.82,
      -64.621,
      -42.254,
      3.82,
      -62.458,
      -42.138,
      3.82,
      -62.458,
      -42.138,
      2.78,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "475.0",
    polygon: [
      -40.638,
      -40.903,
      15.36,
      -41.701,
      -40.961,
      15.36,
      -41.701,
      -40.961,
      17.82,
      -41.701,
      -40.961,
      17.82,
      -40.638,
      -40.903,
      17.82,
      -40.638,
      -40.903,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "597.0",
    polygon: [
      -24.266,
      -39.424,
      5.598,
      -25.174,
      -39.472,
      5.598,
      -25.174,
      -39.472,
      5.844,
      -25.174,
      -39.472,
      5.844,
      -24.266,
      -39.424,
      5.844,
      -24.266,
      -39.424,
      5.598,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1080.0",
    polygon: [
      -20.464,
      -39.206,
      11.06,
      -21.483,
      -39.261,
      11.06,
      -21.483,
      -39.261,
      11.96,
      -21.483,
      -39.261,
      11.96,
      -20.464,
      -39.206,
      11.96,
      -20.464,
      -39.206,
      11.06,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1515.0",
    polygon: [
      -2.824,
      -13.596,
      22.36,
      -3.975,
      -13.787,
      22.36,
      -3.975,
      -13.787,
      24.82,
      -3.975,
      -13.787,
      24.82,
      -2.824,
      -13.596,
      24.82,
      -2.824,
      -13.596,
      22.36,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "437.0",
    polygon: [
      -30.865,
      -40.353,
      8.36,
      -31.946,
      -40.412,
      8.36,
      -31.946,
      -40.412,
      10.82,
      -31.946,
      -40.412,
      10.82,
      -30.865,
      -40.353,
      10.82,
      -30.865,
      -40.353,
      8.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "918.0",
    polygon: [
      -53.118,
      -40.961,
      17.36,
      -54.935,
      -41.06,
      17.36,
      -54.935,
      -41.06,
      18.55,
      -54.935,
      -41.06,
      18.55,
      -53.118,
      -40.961,
      18.55,
      -53.118,
      -40.961,
      17.36,
    ],
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "219.0",
    polygon: [
      -54.024,
      -41.033,
      6.09,
      -54.933,
      -41.082,
      6.09,
      -54.933,
      -41.082,
      6.336,
      -54.933,
      -41.082,
      6.336,
      -54.024,
      -41.033,
      6.336,
      -54.024,
      -41.033,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "220.0",
    polygon: [
      -53.131,
      -40.984,
      6.09,
      -54.039,
      -41.034,
      6.09,
      -54.039,
      -41.034,
      6.336,
      -54.039,
      -41.034,
      6.336,
      -53.131,
      -40.984,
      6.336,
      -53.131,
      -40.984,
      6.09,
    ],
    "04-15 12:30": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "636.0",
    polygon: [
      -21.569,
      -39.283,
      4.077,
      -22.478,
      -39.33,
      4.077,
      -22.478,
      -39.33,
      4.323,
      -22.478,
      -39.33,
      4.323,
      -21.569,
      -39.283,
      4.323,
      -21.569,
      -39.283,
      4.077,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "436.0",
    polygon: [
      -31.946,
      -40.412,
      8.36,
      -33.027,
      -40.47,
      8.36,
      -33.027,
      -40.47,
      10.82,
      -33.027,
      -40.47,
      10.82,
      -31.946,
      -40.412,
      10.82,
      -31.946,
      -40.412,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "761.0",
    polygon: [
      -14.839,
      -38.93,
      4.077,
      -15.748,
      -38.978,
      4.077,
      -15.748,
      -38.978,
      4.323,
      -15.748,
      -38.978,
      4.323,
      -14.839,
      -38.93,
      4.323,
      -14.839,
      -38.93,
      4.077,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1384.0",
    polygon: [
      4.163,
      -12.59,
      25.86,
      2.846,
      -12.808,
      25.86,
      2.846,
      -12.808,
      28.32,
      2.846,
      -12.808,
      28.32,
      4.163,
      -12.59,
      28.32,
      4.163,
      -12.59,
      25.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1293.0",
    polygon: [
      18.646,
      -10.187,
      18.86,
      17.33,
      -10.406,
      18.86,
      17.33,
      -10.406,
      21.32,
      17.33,
      -10.406,
      21.32,
      18.646,
      -10.187,
      21.32,
      18.646,
      -10.187,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "89.0",
    polygon: [
      -60.521,
      -41.389,
      5.352,
      -61.429,
      -41.438,
      5.352,
      -61.429,
      -41.438,
      5.598,
      -61.429,
      -41.438,
      5.598,
      -60.521,
      -41.389,
      5.598,
      -60.521,
      -41.389,
      5.352,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "181.0",
    polygon: [
      16.003,
      -10.691,
      4.86,
      13.753,
      -11.064,
      4.86,
      13.753,
      -11.064,
      7.32,
      13.753,
      -11.064,
      7.32,
      16.003,
      -10.691,
      7.32,
      16.003,
      -10.691,
      4.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1209.0",
    polygon: [
      2.566,
      -22.993,
      2.78,
      2.566,
      -25.654,
      2.78,
      2.566,
      -25.654,
      3.82,
      2.566,
      -25.654,
      3.82,
      2.566,
      -22.993,
      3.82,
      2.566,
      -22.993,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1002.0",
    polygon: [
      -50.233,
      -40.803,
      14.66,
      -52.049,
      -40.902,
      14.66,
      -52.049,
      -40.902,
      15.56,
      -52.049,
      -40.902,
      15.56,
      -50.233,
      -40.803,
      15.56,
      -50.233,
      -40.803,
      14.66,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1004.0",
    polygon: [
      -54.935,
      -41.06,
      14.66,
      -56.751,
      -41.159,
      14.66,
      -56.751,
      -41.159,
      15.56,
      -56.751,
      -41.159,
      15.56,
      -54.935,
      -41.06,
      15.56,
      -54.935,
      -41.06,
      14.66,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "48.0",
    polygon: [
      -72.247,
      -42.611,
      4.86,
      -73.309,
      -42.668,
      4.86,
      -73.309,
      -42.668,
      7.32,
      -73.309,
      -42.668,
      7.32,
      -72.247,
      -42.611,
      7.32,
      -72.247,
      -42.611,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "91.0",
    polygon: [
      -58.718,
      -41.29,
      5.352,
      -59.627,
      -41.34,
      5.352,
      -59.627,
      -41.34,
      5.598,
      -59.627,
      -41.34,
      5.598,
      -58.718,
      -41.29,
      5.598,
      -58.718,
      -41.29,
      5.352,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "399.0",
    polygon: [
      -71.172,
      -42.587,
      0.05,
      -72.234,
      -42.644,
      0.05,
      -72.234,
      -42.644,
      2.78,
      -72.234,
      -42.644,
      2.78,
      -71.172,
      -42.587,
      2.78,
      -71.172,
      -42.587,
      0.05,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1121.0",
    polygon: [
      -25.163,
      -39.462,
      14.66,
      -26.182,
      -39.517,
      14.66,
      -26.182,
      -39.517,
      15.56,
      -26.182,
      -39.517,
      15.56,
      -25.163,
      -39.462,
      15.56,
      -25.163,
      -39.462,
      14.66,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1517.0",
    polygon: [
      -6.268,
      -14.168,
      22.36,
      -7.42,
      -14.359,
      22.36,
      -7.42,
      -14.359,
      24.82,
      -7.42,
      -14.359,
      24.82,
      -6.268,
      -14.168,
      24.82,
      -6.268,
      -14.168,
      22.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1255.0",
    polygon: [
      2.566,
      -21.663,
      8.36,
      2.566,
      -22.993,
      8.36,
      2.566,
      -22.993,
      10.82,
      2.566,
      -22.993,
      10.82,
      2.566,
      -21.663,
      10.82,
      2.566,
      -21.663,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1386.0",
    polygon: [
      18.642,
      -10.188,
      29.36,
      17.326,
      -10.406,
      29.36,
      17.326,
      -10.406,
      31.82,
      17.326,
      -10.406,
      31.82,
      18.642,
      -10.188,
      31.82,
      18.642,
      -10.188,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "438.0",
    polygon: [
      -4.497,
      -38.948,
      8.36,
      -5.897,
      -39.021,
      8.36,
      -5.897,
      -39.021,
      9.18,
      -5.897,
      -39.021,
      9.18,
      -4.497,
      -38.948,
      9.18,
      -4.497,
      -38.948,
      8.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "477.0",
    polygon: [
      -38.513,
      -40.787,
      15.36,
      -39.576,
      -40.845,
      15.36,
      -39.576,
      -40.845,
      17.82,
      -39.576,
      -40.845,
      17.82,
      -38.513,
      -40.787,
      17.82,
      -38.513,
      -40.787,
      15.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1516.0",
    polygon: [
      -1.672,
      -13.405,
      22.36,
      -2.824,
      -13.596,
      22.36,
      -2.824,
      -13.596,
      24.82,
      -2.824,
      -13.596,
      24.82,
      -1.672,
      -13.405,
      24.82,
      -1.672,
      -13.405,
      22.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "398.0",
    polygon: [
      -72.234,
      -42.644,
      0.05,
      -73.297,
      -42.701,
      0.05,
      -73.297,
      -42.701,
      2.78,
      -73.297,
      -42.701,
      2.78,
      -72.234,
      -42.644,
      2.78,
      -72.234,
      -42.644,
      0.05,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "722.0",
    polygon: [
      -13.946,
      -38.883,
      5.352,
      -14.854,
      -38.931,
      5.352,
      -14.854,
      -38.931,
      5.598,
      -14.854,
      -38.931,
      5.598,
      -13.946,
      -38.883,
      5.598,
      -13.946,
      -38.883,
      5.352,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1254.0",
    polygon: [
      2.566,
      -22.993,
      8.36,
      2.566,
      -24.324,
      8.36,
      2.566,
      -24.324,
      10.82,
      2.566,
      -24.324,
      10.82,
      2.566,
      -22.993,
      10.82,
      2.566,
      -22.993,
      8.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1299.0",
    polygon: [
      14.697,
      -10.842,
      18.86,
      13.381,
      -11.061,
      18.86,
      13.381,
      -11.061,
      21.32,
      13.381,
      -11.061,
      21.32,
      14.697,
      -10.842,
      21.32,
      14.697,
      -10.842,
      18.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "762.0",
    polygon: [
      -13.946,
      -38.883,
      4.077,
      -14.854,
      -38.931,
      4.077,
      -14.854,
      -38.931,
      4.323,
      -14.854,
      -38.931,
      4.323,
      -13.946,
      -38.883,
      4.323,
      -13.946,
      -38.883,
      4.077,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "130.0",
    polygon: [
      -59.632,
      -41.335,
      4.077,
      -60.541,
      -41.384,
      4.077,
      -60.541,
      -41.384,
      4.323,
      -60.541,
      -41.384,
      4.323,
      -59.632,
      -41.335,
      4.323,
      -59.632,
      -41.335,
      4.077,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "921.0",
    polygon: [
      -59.629,
      -41.315,
      17.36,
      -61.445,
      -41.414,
      17.36,
      -61.445,
      -41.414,
      18.55,
      -61.445,
      -41.414,
      18.55,
      -59.629,
      -41.315,
      18.55,
      -59.629,
      -41.315,
      17.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "305.0",
    polygon: [
      -51.13,
      -40.871,
      4.077,
      -52.039,
      -40.921,
      4.077,
      -52.039,
      -40.921,
      4.323,
      -52.039,
      -40.921,
      4.323,
      -51.13,
      -40.871,
      4.323,
      -51.13,
      -40.871,
      4.077,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1120.0",
    polygon: [
      -20.464,
      -39.206,
      14.66,
      -21.483,
      -39.261,
      14.66,
      -21.483,
      -39.261,
      15.56,
      -21.483,
      -39.261,
      15.56,
      -20.464,
      -39.206,
      15.56,
      -20.464,
      -39.206,
      14.66,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "638.0",
    polygon: [
      -23.372,
      -39.377,
      3.831,
      -24.281,
      -39.425,
      3.831,
      -24.281,
      -39.425,
      4.077,
      -24.281,
      -39.425,
      4.077,
      -23.372,
      -39.377,
      4.077,
      -23.372,
      -39.377,
      3.831,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1043.0",
    polygon: [
      -18.623,
      -39.106,
      17.36,
      -20.44,
      -39.205,
      17.36,
      -20.44,
      -39.205,
      18.55,
      -20.44,
      -39.205,
      18.55,
      -18.623,
      -39.106,
      18.55,
      -18.623,
      -39.106,
      17.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "516.0",
    polygon: [
      -7.957,
      -39.181,
      0.05,
      -9.019,
      -39.237,
      0.05,
      -9.019,
      -39.237,
      2.78,
      -9.019,
      -39.237,
      2.78,
      -7.957,
      -39.181,
      2.78,
      -7.957,
      -39.181,
      0.05,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1298.0",
    polygon: [
      16.014,
      -10.624,
      18.86,
      14.697,
      -10.842,
      18.86,
      14.697,
      -10.842,
      21.32,
      14.697,
      -10.842,
      21.32,
      16.014,
      -10.624,
      21.32,
      16.014,
      -10.624,
      18.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "478.0",
    polygon: [
      -34.107,
      -40.548,
      15.36,
      -35.188,
      -40.606,
      15.36,
      -35.188,
      -40.606,
      17.82,
      -35.188,
      -40.606,
      17.82,
      -34.107,
      -40.548,
      17.82,
      -34.107,
      -40.548,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "637.0",
    polygon: [
      -24.266,
      -39.424,
      3.831,
      -25.174,
      -39.472,
      3.831,
      -25.174,
      -39.472,
      4.077,
      -25.174,
      -39.472,
      4.077,
      -24.266,
      -39.424,
      4.077,
      -24.266,
      -39.424,
      3.831,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "599.0",
    polygon: [
      -22.463,
      -39.33,
      5.598,
      -23.372,
      -39.377,
      5.598,
      -23.372,
      -39.377,
      5.844,
      -23.372,
      -39.377,
      5.844,
      -22.463,
      -39.33,
      5.844,
      -22.463,
      -39.33,
      5.598,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "920.0",
    polygon: [
      -57.812,
      -41.216,
      17.36,
      -59.629,
      -41.315,
      17.36,
      -59.629,
      -41.315,
      18.55,
      -59.629,
      -41.315,
      18.55,
      -57.812,
      -41.216,
      18.55,
      -57.812,
      -41.216,
      17.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "518.0",
    polygon: [
      -9.019,
      -39.237,
      2.78,
      -11.145,
      -39.348,
      2.78,
      -11.145,
      -39.348,
      3.82,
      -11.145,
      -39.348,
      3.82,
      -9.019,
      -39.237,
      3.82,
      -9.019,
      -39.237,
      2.78,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "264.0",
    polygon: [
      -48.429,
      -40.728,
      5.106,
      -49.337,
      -40.778,
      5.106,
      -49.337,
      -40.778,
      5.352,
      -49.337,
      -40.778,
      5.352,
      -48.429,
      -40.728,
      5.352,
      -48.429,
      -40.728,
      5.106,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1082.0",
    polygon: [
      -16.807,
      -39.007,
      11.06,
      -18.623,
      -39.106,
      11.06,
      -18.623,
      -39.106,
      11.96,
      -18.623,
      -39.106,
      11.96,
      -16.807,
      -39.007,
      11.96,
      -16.807,
      -39.007,
      11.06,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1570.0",
    polygon: [
      -8.739,
      -14.672,
      39.128,
      -9.924,
      -14.864,
      39.128,
      -9.924,
      -14.864,
      40.05,
      -9.924,
      -14.864,
      40.05,
      -8.739,
      -14.672,
      40.05,
      -8.739,
      -14.672,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "724.0",
    polygon: [
      -12.143,
      -38.789,
      5.352,
      -13.052,
      -38.836,
      5.352,
      -13.052,
      -38.836,
      5.598,
      -13.052,
      -38.836,
      5.598,
      -12.143,
      -38.789,
      5.598,
      -12.143,
      -38.789,
      5.352,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1387.0",
    polygon: [
      17.326,
      -10.406,
      29.36,
      16.01,
      -10.625,
      29.36,
      16.01,
      -10.625,
      31.82,
      16.01,
      -10.625,
      31.82,
      17.326,
      -10.406,
      31.82,
      17.326,
      -10.406,
      29.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1160.0",
    polygon: [
      6.678,
      -12.056,
      11.86,
      5.526,
      -12.247,
      11.86,
      5.526,
      -12.247,
      14.32,
      5.526,
      -12.247,
      14.32,
      6.678,
      -12.056,
      14.32,
      6.678,
      -12.056,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "639.0",
    polygon: [
      -22.463,
      -39.33,
      3.831,
      -23.372,
      -39.377,
      3.831,
      -23.372,
      -39.377,
      4.077,
      -23.372,
      -39.377,
      4.077,
      -22.463,
      -39.33,
      4.077,
      -22.463,
      -39.33,
      3.831,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "763.0",
    polygon: [
      -13.037,
      -38.836,
      4.077,
      -13.946,
      -38.883,
      4.077,
      -13.946,
      -38.883,
      4.323,
      -13.946,
      -38.883,
      4.323,
      -13.037,
      -38.836,
      4.323,
      -13.037,
      -38.836,
      4.077,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "964.0",
    polygon: [
      -54.935,
      -41.06,
      11.06,
      -56.751,
      -41.159,
      11.06,
      -56.751,
      -41.159,
      11.96,
      -56.751,
      -41.159,
      11.96,
      -54.935,
      -41.06,
      11.96,
      -54.935,
      -41.06,
      11.06,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "47.0",
    polygon: [
      -73.309,
      -42.668,
      4.86,
      -74.372,
      -42.725,
      4.86,
      -74.372,
      -42.725,
      7.32,
      -74.372,
      -42.725,
      7.32,
      -73.309,
      -42.668,
      7.32,
      -73.309,
      -42.668,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "131.0",
    polygon: [
      -58.724,
      -41.285,
      4.077,
      -59.632,
      -41.335,
      4.077,
      -59.632,
      -41.335,
      4.323,
      -59.632,
      -41.335,
      4.323,
      -58.724,
      -41.285,
      4.323,
      -58.724,
      -41.285,
      4.077,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "439.0",
    polygon: [
      -4.497,
      -38.948,
      9.18,
      -5.897,
      -39.021,
      9.18,
      -5.897,
      -39.021,
      10.0,
      -5.897,
      -39.021,
      10.0,
      -4.497,
      -38.948,
      10.0,
      -4.497,
      -38.948,
      9.18,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "598.0",
    polygon: [
      -23.372,
      -39.377,
      5.598,
      -24.281,
      -39.425,
      5.598,
      -24.281,
      -39.425,
      5.844,
      -24.281,
      -39.425,
      5.844,
      -23.372,
      -39.377,
      5.844,
      -23.372,
      -39.377,
      5.598,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "183.0",
    polygon: [
      5.2,
      -12.44,
      2.78,
      2.951,
      -12.813,
      2.78,
      2.951,
      -12.813,
      3.82,
      2.951,
      -12.813,
      3.82,
      5.2,
      -12.44,
      3.82,
      5.2,
      -12.44,
      2.78,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "90.0",
    polygon: [
      -59.627,
      -41.34,
      5.352,
      -60.536,
      -41.389,
      5.352,
      -60.536,
      -41.389,
      5.598,
      -60.536,
      -41.389,
      5.598,
      -59.627,
      -41.34,
      5.598,
      -59.627,
      -41.34,
      5.352,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1081.0",
    polygon: [
      -25.163,
      -39.462,
      11.06,
      -26.182,
      -39.517,
      11.06,
      -26.182,
      -39.517,
      11.96,
      -26.182,
      -39.517,
      11.96,
      -25.163,
      -39.462,
      11.96,
      -25.163,
      -39.462,
      11.06,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "517.0",
    polygon: [
      -6.894,
      -39.125,
      0.05,
      -7.957,
      -39.181,
      0.05,
      -7.957,
      -39.181,
      2.78,
      -7.957,
      -39.181,
      2.78,
      -6.894,
      -39.125,
      2.78,
      -6.894,
      -39.125,
      0.05,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1569.0",
    polygon: [
      -8.739,
      -14.672,
      36.36,
      -9.924,
      -14.864,
      36.36,
      -9.924,
      -14.864,
      39.128,
      -9.924,
      -14.864,
      39.128,
      -8.739,
      -14.672,
      39.128,
      -8.739,
      -14.672,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1005.0",
    polygon: [
      -57.812,
      -41.216,
      14.66,
      -59.629,
      -41.315,
      14.66,
      -59.629,
      -41.315,
      15.56,
      -59.629,
      -41.315,
      15.56,
      -57.812,
      -41.216,
      15.56,
      -57.812,
      -41.216,
      14.66,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "92.0",
    polygon: [
      -57.825,
      -41.242,
      5.352,
      -58.733,
      -41.291,
      5.352,
      -58.733,
      -41.291,
      5.598,
      -58.733,
      -41.291,
      5.598,
      -57.825,
      -41.242,
      5.598,
      -57.825,
      -41.242,
      5.352,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "49.0",
    polygon: [
      -71.184,
      -42.554,
      4.86,
      -72.247,
      -42.611,
      4.86,
      -72.247,
      -42.611,
      7.32,
      -72.247,
      -42.611,
      7.32,
      -71.184,
      -42.554,
      7.32,
      -71.184,
      -42.554,
      4.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1256.0",
    polygon: [
      2.566,
      -20.332,
      8.36,
      2.566,
      -21.663,
      8.36,
      2.566,
      -21.663,
      10.82,
      2.566,
      -21.663,
      10.82,
      2.566,
      -20.332,
      10.82,
      2.566,
      -20.332,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "182.0",
    polygon: [
      18.252,
      -10.318,
      4.86,
      16.003,
      -10.691,
      4.86,
      16.003,
      -10.691,
      7.32,
      16.003,
      -10.691,
      7.32,
      18.252,
      -10.318,
      7.32,
      18.252,
      -10.318,
      4.86,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1297.0",
    polygon: [
      17.33,
      -10.406,
      18.86,
      16.014,
      -10.624,
      18.86,
      16.014,
      -10.624,
      21.32,
      16.014,
      -10.624,
      21.32,
      17.33,
      -10.406,
      21.32,
      17.33,
      -10.406,
      18.86,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1211.0",
    polygon: [
      2.566,
      -17.671,
      2.78,
      2.566,
      -20.332,
      2.78,
      2.566,
      -20.332,
      3.82,
      2.566,
      -20.332,
      3.82,
      2.566,
      -17.671,
      3.82,
      2.566,
      -17.671,
      2.78,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "723.0",
    polygon: [
      -13.037,
      -38.836,
      5.352,
      -13.946,
      -38.883,
      5.352,
      -13.946,
      -38.883,
      5.598,
      -13.946,
      -38.883,
      5.598,
      -13.037,
      -38.836,
      5.598,
      -13.037,
      -38.836,
      5.352,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1679.0",
    polygon: [
      -41.744,
      -7.155,
      39.128,
      -42.944,
      -7.155,
      39.128,
      -42.944,
      -7.155,
      40.05,
      -42.944,
      -7.155,
      40.05,
      -41.744,
      -7.155,
      40.05,
      -41.744,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "400.0",
    polygon: [
      -70.109,
      -42.53,
      0.05,
      -71.172,
      -42.587,
      0.05,
      -71.172,
      -42.587,
      2.78,
      -71.172,
      -42.587,
      2.78,
      -70.109,
      -42.53,
      2.78,
      -70.109,
      -42.53,
      0.05,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "129.0",
    polygon: [
      -60.526,
      -41.383,
      4.077,
      -61.435,
      -41.433,
      4.077,
      -61.435,
      -41.433,
      4.323,
      -61.435,
      -41.433,
      4.323,
      -60.526,
      -41.383,
      4.323,
      -60.526,
      -41.383,
      4.077,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1162.0",
    polygon: [
      12.075,
      -11.161,
      11.86,
      10.923,
      -11.352,
      11.86,
      10.923,
      -11.352,
      14.32,
      10.923,
      -11.352,
      14.32,
      12.075,
      -11.161,
      14.32,
      12.075,
      -11.161,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1210.0",
    polygon: [
      2.566,
      -20.332,
      2.78,
      2.566,
      -22.993,
      2.78,
      2.566,
      -22.993,
      3.82,
      2.566,
      -22.993,
      3.82,
      2.566,
      -20.332,
      3.82,
      2.566,
      -20.332,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "265.0",
    polygon: [
      -51.125,
      -40.875,
      5.352,
      -52.033,
      -40.924,
      5.352,
      -52.033,
      -40.924,
      5.598,
      -52.033,
      -40.924,
      5.598,
      -51.125,
      -40.875,
      5.598,
      -51.125,
      -40.875,
      5.352,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1042.0",
    polygon: [
      -16.807,
      -39.007,
      17.36,
      -18.623,
      -39.106,
      17.36,
      -18.623,
      -39.106,
      18.55,
      -18.623,
      -39.106,
      18.55,
      -16.807,
      -39.007,
      18.55,
      -16.807,
      -39.007,
      17.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1122.0",
    polygon: [
      -16.807,
      -39.007,
      14.66,
      -18.623,
      -39.106,
      14.66,
      -18.623,
      -39.106,
      15.56,
      -18.623,
      -39.106,
      15.56,
      -16.807,
      -39.007,
      15.56,
      -16.807,
      -39.007,
      14.66,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1003.0",
    polygon: [
      -53.118,
      -40.961,
      14.66,
      -54.935,
      -41.06,
      14.66,
      -54.935,
      -41.06,
      15.56,
      -54.935,
      -41.06,
      15.56,
      -53.118,
      -40.961,
      15.56,
      -53.118,
      -40.961,
      14.66,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1677.0",
    polygon: [
      -41.744,
      -7.155,
      36.36,
      -42.944,
      -7.155,
      36.36,
      -42.944,
      -7.155,
      39.128,
      -42.944,
      -7.155,
      39.128,
      -41.744,
      -7.155,
      39.128,
      -41.744,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "965.0",
    polygon: [
      -57.812,
      -41.216,
      11.06,
      -59.629,
      -41.315,
      11.06,
      -59.629,
      -41.315,
      11.96,
      -59.629,
      -41.315,
      11.96,
      -57.812,
      -41.216,
      11.96,
      -57.812,
      -41.216,
      11.06,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1161.0",
    polygon: [
      9.376,
      -11.608,
      11.86,
      8.224,
      -11.8,
      11.86,
      8.224,
      -11.8,
      14.32,
      8.224,
      -11.8,
      14.32,
      9.376,
      -11.608,
      14.32,
      9.376,
      -11.608,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1518.0",
    polygon: [
      -8.971,
      -14.616,
      22.36,
      -10.123,
      -14.807,
      22.36,
      -10.123,
      -14.807,
      24.82,
      -10.123,
      -14.807,
      24.82,
      -8.971,
      -14.616,
      24.82,
      -8.971,
      -14.616,
      22.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1682.0",
    polygon: [
      -30.408,
      -7.155,
      36.36,
      -31.608,
      -7.155,
      36.36,
      -31.608,
      -7.155,
      39.128,
      -31.608,
      -7.155,
      39.128,
      -30.408,
      -7.155,
      39.128,
      -30.408,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "267.0",
    polygon: [
      -49.322,
      -40.777,
      5.352,
      -50.231,
      -40.826,
      5.352,
      -50.231,
      -40.826,
      5.598,
      -50.231,
      -40.826,
      5.598,
      -49.322,
      -40.777,
      5.598,
      -49.322,
      -40.777,
      5.352,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "967.0",
    polygon: [
      -47.379,
      -40.648,
      11.96,
      -48.397,
      -40.703,
      11.96,
      -48.397,
      -40.703,
      12.86,
      -48.397,
      -40.703,
      12.86,
      -47.379,
      -40.648,
      12.86,
      -47.379,
      -40.648,
      11.96,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1084.0",
    polygon: [
      -21.509,
      -39.263,
      11.06,
      -23.325,
      -39.362,
      11.06,
      -23.325,
      -39.362,
      11.96,
      -23.325,
      -39.362,
      11.96,
      -21.509,
      -39.263,
      11.96,
      -21.509,
      -39.263,
      11.06,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "765.0",
    polygon: [
      -14.839,
      -38.93,
      3.831,
      -15.748,
      -38.978,
      3.831,
      -15.748,
      -38.978,
      4.077,
      -15.748,
      -38.978,
      4.077,
      -14.839,
      -38.93,
      4.077,
      -14.839,
      -38.93,
      3.831,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1389.0",
    polygon: [
      14.693,
      -10.843,
      29.36,
      13.377,
      -11.061,
      29.36,
      13.377,
      -11.061,
      31.82,
      13.377,
      -11.061,
      31.82,
      14.693,
      -10.843,
      31.82,
      14.693,
      -10.843,
      29.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1301.0",
    polygon: [
      12.065,
      -11.279,
      18.86,
      10.748,
      -11.497,
      18.86,
      10.748,
      -11.497,
      21.32,
      10.748,
      -11.497,
      21.32,
      12.065,
      -11.279,
      21.32,
      12.065,
      -11.279,
      18.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "307.0",
    polygon: [
      -49.328,
      -40.773,
      4.077,
      -50.236,
      -40.823,
      4.077,
      -50.236,
      -40.823,
      4.323,
      -50.236,
      -40.823,
      4.323,
      -49.328,
      -40.773,
      4.323,
      -49.328,
      -40.773,
      4.077,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "94.0",
    polygon: [
      -59.627,
      -41.34,
      5.598,
      -60.536,
      -41.389,
      5.598,
      -60.536,
      -41.389,
      5.844,
      -60.536,
      -41.389,
      5.844,
      -59.627,
      -41.34,
      5.844,
      -59.627,
      -41.34,
      5.598,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1007.0",
    polygon: [
      -47.379,
      -40.648,
      15.56,
      -48.397,
      -40.703,
      15.56,
      -48.397,
      -40.703,
      16.46,
      -48.397,
      -40.703,
      16.46,
      -47.379,
      -40.648,
      16.46,
      -47.379,
      -40.648,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "51.0",
    polygon: [
      -65.715,
      -42.262,
      4.86,
      -66.797,
      -42.32,
      4.86,
      -66.797,
      -42.32,
      7.32,
      -66.797,
      -42.32,
      7.32,
      -65.715,
      -42.262,
      7.32,
      -65.715,
      -42.262,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1388.0",
    polygon: [
      16.01,
      -10.625,
      29.36,
      14.693,
      -10.843,
      29.36,
      14.693,
      -10.843,
      31.82,
      14.693,
      -10.843,
      31.82,
      16.01,
      -10.625,
      31.82,
      16.01,
      -10.625,
      29.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1044.0",
    polygon: [
      -21.509,
      -39.263,
      17.36,
      -23.325,
      -39.362,
      17.36,
      -23.325,
      -39.362,
      18.55,
      -23.325,
      -39.362,
      18.55,
      -21.509,
      -39.263,
      18.55,
      -21.509,
      -39.263,
      17.36,
    ],
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "479.0",
    polygon: [
      -33.026,
      -40.489,
      15.36,
      -34.108,
      -40.548,
      15.36,
      -34.108,
      -40.548,
      17.82,
      -34.108,
      -40.548,
      17.82,
      -33.026,
      -40.489,
      17.82,
      -33.026,
      -40.489,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "927.0",
    polygon: [
      -59.629,
      -41.315,
      9.26,
      -61.445,
      -41.414,
      9.26,
      -61.445,
      -41.414,
      10.16,
      -61.445,
      -41.414,
      10.16,
      -59.629,
      -41.315,
      10.16,
      -59.629,
      -41.315,
      9.26,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1571.0",
    polygon: [
      -7.403,
      -14.455,
      36.36,
      -8.588,
      -14.647,
      36.36,
      -8.588,
      -14.647,
      39.128,
      -8.588,
      -14.647,
      39.128,
      -7.403,
      -14.455,
      39.128,
      -7.403,
      -14.455,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "600.0",
    polygon: [
      -21.569,
      -39.283,
      5.598,
      -22.478,
      -39.33,
      5.598,
      -22.478,
      -39.33,
      5.844,
      -22.478,
      -39.33,
      5.844,
      -21.569,
      -39.283,
      5.844,
      -21.569,
      -39.283,
      5.598,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "50.0",
    polygon: [
      -70.122,
      -42.498,
      4.86,
      -71.184,
      -42.554,
      4.86,
      -71.184,
      -42.554,
      7.32,
      -71.184,
      -42.554,
      7.32,
      -70.122,
      -42.498,
      7.32,
      -70.122,
      -42.498,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1123.0",
    polygon: [
      -18.623,
      -39.106,
      14.66,
      -20.44,
      -39.205,
      14.66,
      -20.44,
      -39.205,
      15.56,
      -20.44,
      -39.205,
      15.56,
      -18.623,
      -39.106,
      15.56,
      -18.623,
      -39.106,
      14.66,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "401.0",
    polygon: [
      -72.234,
      -42.644,
      2.78,
      -74.359,
      -42.757,
      2.78,
      -74.359,
      -42.757,
      3.82,
      -74.359,
      -42.757,
      3.82,
      -72.234,
      -42.644,
      3.82,
      -72.234,
      -42.644,
      2.78,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1519.0",
    polygon: [
      -7.42,
      -14.359,
      22.36,
      -8.571,
      -14.55,
      22.36,
      -8.571,
      -14.55,
      24.82,
      -8.571,
      -14.55,
      24.82,
      -7.42,
      -14.359,
      24.82,
      -7.42,
      -14.359,
      22.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1257.0",
    polygon: [
      2.566,
      -19.002,
      8.36,
      2.566,
      -20.332,
      8.36,
      2.566,
      -20.332,
      10.82,
      2.566,
      -20.332,
      10.82,
      2.566,
      -19.002,
      10.82,
      2.566,
      -19.002,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "184.0",
    polygon: [
      7.45,
      -12.067,
      2.78,
      5.2,
      -12.44,
      2.78,
      5.2,
      -12.44,
      3.82,
      5.2,
      -12.44,
      3.82,
      7.45,
      -12.067,
      3.82,
      7.45,
      -12.067,
      2.78,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "725.0",
    polygon: [
      -14.839,
      -38.93,
      5.598,
      -15.748,
      -38.978,
      5.598,
      -15.748,
      -38.978,
      5.844,
      -15.748,
      -38.978,
      5.844,
      -14.839,
      -38.93,
      5.844,
      -14.839,
      -38.93,
      5.598,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1006.0",
    polygon: [
      -43.728,
      -40.449,
      15.56,
      -45.545,
      -40.548,
      15.56,
      -45.545,
      -40.548,
      16.46,
      -45.545,
      -40.548,
      16.46,
      -43.728,
      -40.449,
      16.46,
      -43.728,
      -40.449,
      15.56,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "520.0",
    polygon: [
      -4.484,
      -39.042,
      2.78,
      -5.884,
      -39.115,
      2.78,
      -5.884,
      -39.115,
      3.82,
      -5.884,
      -39.115,
      3.82,
      -4.484,
      -39.042,
      3.82,
      -4.484,
      -39.042,
      2.78,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "480.0",
    polygon: [
      -31.945,
      -40.43,
      15.36,
      -33.026,
      -40.489,
      15.36,
      -33.026,
      -40.489,
      17.82,
      -33.026,
      -40.489,
      17.82,
      -31.945,
      -40.43,
      17.82,
      -31.945,
      -40.43,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "133.0",
    polygon: [
      -60.526,
      -41.383,
      3.831,
      -61.435,
      -41.433,
      3.831,
      -61.435,
      -41.433,
      4.077,
      -61.435,
      -41.433,
      4.077,
      -60.526,
      -41.383,
      4.077,
      -60.526,
      -41.383,
      3.831,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "928.0",
    polygon: [
      -59.629,
      -41.315,
      10.16,
      -61.445,
      -41.414,
      10.16,
      -61.445,
      -41.414,
      11.06,
      -61.445,
      -41.414,
      11.06,
      -59.629,
      -41.315,
      11.06,
      -59.629,
      -41.315,
      10.16,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1045.0",
    polygon: [
      -23.325,
      -39.362,
      17.36,
      -25.142,
      -39.46,
      17.36,
      -25.142,
      -39.46,
      18.55,
      -25.142,
      -39.46,
      18.55,
      -23.325,
      -39.362,
      18.55,
      -23.325,
      -39.362,
      17.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "601.0",
    polygon: [
      -24.266,
      -39.424,
      5.844,
      -25.174,
      -39.472,
      5.844,
      -25.174,
      -39.472,
      6.09,
      -25.174,
      -39.472,
      6.09,
      -24.266,
      -39.424,
      6.09,
      -24.266,
      -39.424,
      5.844,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1572.0",
    polygon: [
      -7.403,
      -14.455,
      39.128,
      -8.588,
      -14.647,
      39.128,
      -8.588,
      -14.647,
      40.05,
      -8.588,
      -14.647,
      40.05,
      -7.403,
      -14.455,
      40.05,
      -7.403,
      -14.455,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "440.0",
    polygon: [
      -4.497,
      -38.948,
      10.0,
      -5.897,
      -39.021,
      10.0,
      -5.897,
      -39.021,
      10.82,
      -5.897,
      -39.021,
      10.82,
      -4.497,
      -38.948,
      10.82,
      -4.497,
      -38.948,
      10.0,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "929.0",
    polygon: [
      -59.629,
      -41.315,
      11.06,
      -61.445,
      -41.414,
      11.06,
      -61.445,
      -41.414,
      11.96,
      -61.445,
      -41.414,
      11.96,
      -59.629,
      -41.315,
      11.96,
      -59.629,
      -41.315,
      11.06,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "132.0",
    polygon: [
      -57.83,
      -41.237,
      4.077,
      -58.739,
      -41.286,
      4.077,
      -58.739,
      -41.286,
      4.323,
      -58.739,
      -41.286,
      4.323,
      -57.83,
      -41.237,
      4.323,
      -57.83,
      -41.237,
      4.077,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "443.0",
    polygon: [
      -7.966,
      -39.13,
      8.36,
      -9.029,
      -39.185,
      8.36,
      -9.029,
      -39.185,
      10.82,
      -9.029,
      -39.185,
      10.82,
      -7.966,
      -39.13,
      10.82,
      -7.966,
      -39.13,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "308.0",
    polygon: [
      -48.434,
      -40.725,
      4.077,
      -49.343,
      -40.774,
      4.077,
      -49.343,
      -40.774,
      4.323,
      -49.343,
      -40.774,
      4.323,
      -48.434,
      -40.725,
      4.323,
      -48.434,
      -40.725,
      4.077,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1683.0",
    polygon: [
      -40.382,
      -7.155,
      36.36,
      -41.582,
      -7.155,
      36.36,
      -41.582,
      -7.155,
      39.128,
      -41.582,
      -7.155,
      39.128,
      -40.382,
      -7.155,
      39.128,
      -40.382,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "602.0",
    polygon: [
      -23.372,
      -39.377,
      5.844,
      -24.281,
      -39.425,
      5.844,
      -24.281,
      -39.425,
      6.09,
      -24.281,
      -39.425,
      6.09,
      -23.372,
      -39.377,
      6.09,
      -23.372,
      -39.377,
      5.844,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "966.0",
    polygon: [
      -43.728,
      -40.449,
      11.96,
      -45.545,
      -40.548,
      11.96,
      -45.545,
      -40.548,
      12.86,
      -45.545,
      -40.548,
      12.86,
      -43.728,
      -40.449,
      12.86,
      -43.728,
      -40.449,
      11.96,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1165.0",
    polygon: [
      5.131,
      -12.313,
      11.86,
      3.979,
      -12.504,
      11.86,
      3.979,
      -12.504,
      14.32,
      3.979,
      -12.504,
      14.32,
      5.131,
      -12.313,
      14.32,
      5.131,
      -12.313,
      11.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "306.0",
    polygon: [
      -50.236,
      -40.823,
      4.077,
      -51.145,
      -40.872,
      4.077,
      -51.145,
      -40.872,
      4.323,
      -51.145,
      -40.872,
      4.323,
      -50.236,
      -40.823,
      4.323,
      -50.236,
      -40.823,
      4.077,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1259.0",
    polygon: [
      2.566,
      -16.341,
      8.36,
      2.566,
      -17.671,
      8.36,
      2.566,
      -17.671,
      10.82,
      2.566,
      -17.671,
      10.82,
      2.566,
      -16.341,
      10.82,
      2.566,
      -16.341,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "442.0",
    polygon: [
      -9.029,
      -39.185,
      8.36,
      -10.091,
      -39.241,
      8.36,
      -10.091,
      -39.241,
      10.82,
      -10.091,
      -39.241,
      10.82,
      -9.029,
      -39.185,
      10.82,
      -9.029,
      -39.185,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "268.0",
    polygon: [
      -48.429,
      -40.728,
      5.352,
      -49.337,
      -40.778,
      5.352,
      -49.337,
      -40.778,
      5.598,
      -49.337,
      -40.778,
      5.598,
      -48.429,
      -40.728,
      5.598,
      -48.429,
      -40.728,
      5.352,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "726.0",
    polygon: [
      -13.946,
      -38.883,
      5.598,
      -14.854,
      -38.931,
      5.598,
      -14.854,
      -38.931,
      5.844,
      -14.854,
      -38.931,
      5.844,
      -13.946,
      -38.883,
      5.844,
      -13.946,
      -38.883,
      5.598,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1083.0",
    polygon: [
      -18.623,
      -39.106,
      11.06,
      -20.44,
      -39.205,
      11.06,
      -20.44,
      -39.205,
      11.96,
      -20.44,
      -39.205,
      11.96,
      -18.623,
      -39.106,
      11.96,
      -18.623,
      -39.106,
      11.06,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1573.0",
    polygon: [
      -6.067,
      -14.238,
      36.36,
      -7.252,
      -14.43,
      36.36,
      -7.252,
      -14.43,
      39.128,
      -7.252,
      -14.43,
      39.128,
      -6.067,
      -14.238,
      39.128,
      -6.067,
      -14.238,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "93.0",
    polygon: [
      -60.521,
      -41.389,
      5.598,
      -61.429,
      -41.438,
      5.598,
      -61.429,
      -41.438,
      5.844,
      -61.429,
      -41.438,
      5.844,
      -60.521,
      -41.389,
      5.844,
      -60.521,
      -41.389,
      5.598,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "766.0",
    polygon: [
      -13.946,
      -38.883,
      3.831,
      -14.854,
      -38.931,
      3.831,
      -14.854,
      -38.931,
      4.077,
      -14.854,
      -38.931,
      4.077,
      -13.946,
      -38.883,
      4.077,
      -13.946,
      -38.883,
      3.831,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "764.0",
    polygon: [
      -12.143,
      -38.789,
      4.077,
      -13.052,
      -38.836,
      4.077,
      -13.052,
      -38.836,
      4.323,
      -13.052,
      -38.836,
      4.323,
      -12.143,
      -38.789,
      4.323,
      -12.143,
      -38.789,
      4.077,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "441.0",
    polygon: [
      -10.091,
      -39.241,
      8.36,
      -11.154,
      -39.297,
      8.36,
      -11.154,
      -39.297,
      10.82,
      -11.154,
      -39.297,
      10.82,
      -10.091,
      -39.241,
      10.82,
      -10.091,
      -39.241,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1164.0",
    polygon: [
      17.472,
      -10.266,
      11.86,
      16.32,
      -10.457,
      11.86,
      16.32,
      -10.457,
      14.32,
      16.32,
      -10.457,
      14.32,
      17.472,
      -10.266,
      14.32,
      17.472,
      -10.266,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "96.0",
    polygon: [
      -57.825,
      -41.242,
      5.598,
      -58.733,
      -41.291,
      5.598,
      -58.733,
      -41.291,
      5.844,
      -58.733,
      -41.291,
      5.844,
      -57.825,
      -41.242,
      5.844,
      -57.825,
      -41.242,
      5.598,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "482.0",
    polygon: [
      -4.497,
      -38.948,
      15.36,
      -5.897,
      -39.021,
      15.36,
      -5.897,
      -39.021,
      16.18,
      -5.897,
      -39.021,
      16.18,
      -4.497,
      -38.948,
      16.18,
      -4.497,
      -38.948,
      15.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1212.0",
    polygon: [
      2.566,
      -15.01,
      2.78,
      2.566,
      -17.671,
      2.78,
      2.566,
      -17.671,
      3.82,
      2.566,
      -17.671,
      3.82,
      2.566,
      -15.01,
      3.82,
      2.566,
      -15.01,
      2.78,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "521.0",
    polygon: [
      -28.912,
      -39.668,
      4.86,
      -29.821,
      -39.715,
      4.86,
      -29.821,
      -39.715,
      5.106,
      -29.821,
      -39.715,
      5.106,
      -28.912,
      -39.668,
      5.106,
      -28.912,
      -39.668,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "1684.0",
    polygon: [
      -40.382,
      -7.155,
      39.128,
      -41.582,
      -7.155,
      39.128,
      -41.582,
      -7.155,
      40.05,
      -41.582,
      -7.155,
      40.05,
      -40.382,
      -7.155,
      40.05,
      -40.382,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1521.0",
    polygon: [
      -0.12,
      -13.148,
      25.86,
      -1.272,
      -13.339,
      25.86,
      -1.272,
      -13.339,
      28.32,
      -1.272,
      -13.339,
      28.32,
      -0.12,
      -13.148,
      28.32,
      -0.12,
      -13.148,
      25.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "53.0",
    polygon: [
      -63.553,
      -42.147,
      4.86,
      -64.635,
      -42.205,
      4.86,
      -64.635,
      -42.205,
      7.32,
      -64.635,
      -42.205,
      7.32,
      -63.553,
      -42.147,
      7.32,
      -63.553,
      -42.147,
      4.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1300.0",
    polygon: [
      13.381,
      -11.061,
      18.86,
      12.065,
      -11.279,
      18.86,
      12.065,
      -11.279,
      21.32,
      12.065,
      -11.279,
      21.32,
      13.381,
      -11.061,
      21.32,
      13.381,
      -11.061,
      18.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1522.0",
    polygon: [
      1.031,
      -12.957,
      25.86,
      -0.12,
      -13.148,
      25.86,
      -0.12,
      -13.148,
      28.32,
      -0.12,
      -13.148,
      28.32,
      1.031,
      -12.957,
      28.32,
      1.031,
      -12.957,
      25.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1390.0",
    polygon: [
      13.377,
      -11.061,
      29.36,
      12.061,
      -11.28,
      29.36,
      12.061,
      -11.28,
      31.82,
      12.061,
      -11.28,
      31.82,
      13.377,
      -11.061,
      31.82,
      13.377,
      -11.061,
      29.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "185.0",
    polygon: [
      5.2,
      -12.44,
      3.82,
      2.951,
      -12.813,
      3.82,
      2.951,
      -12.813,
      4.86,
      2.951,
      -12.813,
      4.86,
      5.2,
      -12.44,
      4.86,
      5.2,
      -12.44,
      3.82,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "969.0",
    polygon: [
      -52.074,
      -40.904,
      11.96,
      -53.092,
      -40.959,
      11.96,
      -53.092,
      -40.959,
      12.86,
      -53.092,
      -40.959,
      12.86,
      -52.074,
      -40.904,
      12.86,
      -52.074,
      -40.904,
      11.96,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "309.0",
    polygon: [
      -51.13,
      -40.871,
      3.831,
      -52.039,
      -40.921,
      3.831,
      -52.039,
      -40.921,
      4.077,
      -52.039,
      -40.921,
      4.077,
      -51.13,
      -40.871,
      4.077,
      -51.13,
      -40.871,
      3.831,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "266.0",
    polygon: [
      -50.231,
      -40.826,
      5.352,
      -51.14,
      -40.876,
      5.352,
      -51.14,
      -40.876,
      5.598,
      -51.14,
      -40.876,
      5.598,
      -50.231,
      -40.826,
      5.598,
      -50.231,
      -40.826,
      5.352,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "186.0",
    polygon: [
      7.45,
      -12.067,
      3.82,
      5.2,
      -12.44,
      3.82,
      5.2,
      -12.44,
      4.86,
      5.2,
      -12.44,
      4.86,
      7.45,
      -12.067,
      4.86,
      7.45,
      -12.067,
      3.82,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "1125.0",
    polygon: [
      -23.325,
      -39.362,
      14.66,
      -25.142,
      -39.46,
      14.66,
      -25.142,
      -39.46,
      15.56,
      -25.142,
      -39.46,
      15.56,
      -23.325,
      -39.362,
      15.56,
      -23.325,
      -39.362,
      14.66,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "646.0",
    polygon: [
      -21.556,
      -39.282,
      2.78,
      -23.372,
      -39.377,
      2.78,
      -23.372,
      -39.377,
      3.82,
      -23.372,
      -39.377,
      3.82,
      -21.556,
      -39.282,
      3.82,
      -21.556,
      -39.282,
      2.78,
    ],
    "04-15 15:00": 1,
  },
  {
    ID: "519.0",
    polygon: [
      -6.894,
      -39.125,
      2.78,
      -9.019,
      -39.237,
      2.78,
      -9.019,
      -39.237,
      3.82,
      -9.019,
      -39.237,
      3.82,
      -6.894,
      -39.125,
      3.82,
      -6.894,
      -39.125,
      2.78,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1391.0",
    polygon: [
      12.061,
      -11.28,
      29.36,
      10.744,
      -11.498,
      29.36,
      10.744,
      -11.498,
      31.82,
      10.744,
      -11.498,
      31.82,
      12.061,
      -11.28,
      31.82,
      12.061,
      -11.28,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "727.0",
    polygon: [
      -13.037,
      -38.836,
      5.598,
      -13.946,
      -38.883,
      5.598,
      -13.946,
      -38.883,
      5.844,
      -13.946,
      -38.883,
      5.844,
      -13.037,
      -38.836,
      5.844,
      -13.037,
      -38.836,
      5.598,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "930.0",
    polygon: [
      -59.629,
      -41.315,
      11.96,
      -61.445,
      -41.414,
      11.96,
      -61.445,
      -41.414,
      12.86,
      -61.445,
      -41.414,
      12.86,
      -59.629,
      -41.315,
      12.86,
      -59.629,
      -41.315,
      11.96,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "404.0",
    polygon: [
      -67.699,
      -42.479,
      2.78,
      -69.099,
      -42.554,
      2.78,
      -69.099,
      -42.554,
      3.82,
      -69.099,
      -42.554,
      3.82,
      -67.699,
      -42.479,
      3.82,
      -67.699,
      -42.479,
      2.78,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1258.0",
    polygon: [
      2.566,
      -17.671,
      8.36,
      2.566,
      -19.002,
      8.36,
      2.566,
      -19.002,
      10.82,
      2.566,
      -19.002,
      10.82,
      2.566,
      -17.671,
      10.82,
      2.566,
      -17.671,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "481.0",
    polygon: [
      -30.864,
      -40.371,
      15.36,
      -31.945,
      -40.43,
      15.36,
      -31.945,
      -40.43,
      17.82,
      -31.945,
      -40.43,
      17.82,
      -30.864,
      -40.371,
      17.82,
      -30.864,
      -40.371,
      15.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1680.0",
    polygon: [
      -30.408,
      -7.155,
      39.128,
      -31.608,
      -7.155,
      39.128,
      -31.608,
      -7.155,
      40.05,
      -31.608,
      -7.155,
      40.05,
      -30.408,
      -7.155,
      40.05,
      -30.408,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1260.0",
    polygon: [
      2.566,
      -15.01,
      8.36,
      2.566,
      -16.341,
      8.36,
      2.566,
      -16.341,
      10.82,
      2.566,
      -16.341,
      10.82,
      2.566,
      -15.01,
      10.82,
      2.566,
      -15.01,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1574.0",
    polygon: [
      -6.067,
      -14.238,
      39.128,
      -7.252,
      -14.43,
      39.128,
      -7.252,
      -14.43,
      40.05,
      -7.252,
      -14.43,
      40.05,
      -6.067,
      -14.238,
      40.05,
      -6.067,
      -14.238,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "97.0",
    polygon: [
      -60.521,
      -41.389,
      5.844,
      -61.429,
      -41.438,
      5.844,
      -61.429,
      -41.438,
      6.09,
      -61.429,
      -41.438,
      6.09,
      -60.521,
      -41.389,
      6.09,
      -60.521,
      -41.389,
      5.844,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "522.0",
    polygon: [
      -28.018,
      -39.621,
      4.86,
      -28.927,
      -39.668,
      4.86,
      -28.927,
      -39.668,
      5.106,
      -28.927,
      -39.668,
      5.106,
      -28.018,
      -39.621,
      5.106,
      -28.018,
      -39.621,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "767.0",
    polygon: [
      -13.037,
      -38.836,
      3.831,
      -13.946,
      -38.883,
      3.831,
      -13.946,
      -38.883,
      4.077,
      -13.946,
      -38.883,
      4.077,
      -13.037,
      -38.836,
      4.077,
      -13.037,
      -38.836,
      3.831,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "270.0",
    polygon: [
      -50.231,
      -40.826,
      5.598,
      -51.14,
      -40.876,
      5.598,
      -51.14,
      -40.876,
      5.844,
      -51.14,
      -40.876,
      5.844,
      -50.231,
      -40.826,
      5.844,
      -50.231,
      -40.826,
      5.598,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "405.0",
    polygon: [
      -36.107,
      -40.66,
      4.86,
      -37.507,
      -40.736,
      4.86,
      -37.507,
      -40.736,
      5.68,
      -37.507,
      -40.736,
      5.68,
      -36.107,
      -40.66,
      5.68,
      -36.107,
      -40.66,
      4.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1087.0",
    polygon: [
      -12.119,
      -38.752,
      11.96,
      -13.935,
      -38.851,
      11.96,
      -13.935,
      -38.851,
      12.86,
      -13.935,
      -38.851,
      12.86,
      -12.119,
      -38.752,
      12.86,
      -12.119,
      -38.752,
      11.96,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "52.0",
    polygon: [
      -64.635,
      -42.205,
      4.86,
      -65.716,
      -42.262,
      4.86,
      -65.716,
      -42.262,
      7.32,
      -65.716,
      -42.262,
      7.32,
      -64.635,
      -42.205,
      7.32,
      -64.635,
      -42.205,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1523.0",
    polygon: [
      -4.364,
      -13.852,
      25.86,
      -5.868,
      -14.101,
      25.86,
      -5.868,
      -14.101,
      28.32,
      -5.868,
      -14.101,
      28.32,
      -4.364,
      -13.852,
      28.32,
      -4.364,
      -13.852,
      25.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1163.0",
    polygon: [
      14.773,
      -10.713,
      11.86,
      13.621,
      -10.904,
      11.86,
      13.621,
      -10.904,
      14.32,
      13.621,
      -10.904,
      14.32,
      14.773,
      -10.713,
      14.32,
      14.773,
      -10.713,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1520.0",
    polygon: [
      -10.123,
      -14.807,
      22.36,
      -11.274,
      -14.998,
      22.36,
      -11.274,
      -14.998,
      24.82,
      -11.274,
      -14.998,
      24.82,
      -10.123,
      -14.807,
      24.82,
      -10.123,
      -14.807,
      22.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "647.0",
    polygon: [
      -24.281,
      -39.425,
      0.05,
      -25.189,
      -39.473,
      0.05,
      -25.189,
      -39.473,
      2.78,
      -25.189,
      -39.473,
      2.78,
      -24.281,
      -39.425,
      2.78,
      -24.281,
      -39.425,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1086.0",
    polygon: [
      -26.203,
      -39.518,
      11.06,
      -28.019,
      -39.617,
      11.06,
      -28.019,
      -39.617,
      11.96,
      -28.019,
      -39.617,
      11.96,
      -26.203,
      -39.518,
      11.96,
      -26.203,
      -39.518,
      11.06,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "134.0",
    polygon: [
      -59.632,
      -41.335,
      3.831,
      -60.541,
      -41.384,
      3.831,
      -60.541,
      -41.384,
      4.077,
      -60.541,
      -41.384,
      4.077,
      -59.632,
      -41.335,
      4.077,
      -59.632,
      -41.335,
      3.831,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1008.0",
    polygon: [
      -45.545,
      -40.548,
      15.56,
      -47.361,
      -40.647,
      15.56,
      -47.361,
      -40.647,
      16.46,
      -47.361,
      -40.647,
      16.46,
      -45.545,
      -40.548,
      16.46,
      -45.545,
      -40.548,
      15.56,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1047.0",
    polygon: [
      -28.019,
      -39.617,
      17.36,
      -29.836,
      -39.716,
      17.36,
      -29.836,
      -39.716,
      18.55,
      -29.836,
      -39.716,
      18.55,
      -28.019,
      -39.617,
      18.55,
      -28.019,
      -39.617,
      17.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "640.0",
    polygon: [
      -21.569,
      -39.283,
      3.831,
      -22.478,
      -39.33,
      3.831,
      -22.478,
      -39.33,
      4.077,
      -22.478,
      -39.33,
      4.077,
      -21.569,
      -39.283,
      4.077,
      -21.569,
      -39.283,
      3.831,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1046.0",
    polygon: [
      -26.203,
      -39.518,
      17.36,
      -28.019,
      -39.617,
      17.36,
      -28.019,
      -39.617,
      18.55,
      -28.019,
      -39.617,
      18.55,
      -26.203,
      -39.518,
      18.55,
      -26.203,
      -39.518,
      17.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "135.0",
    polygon: [
      -58.724,
      -41.285,
      3.831,
      -59.632,
      -41.335,
      3.831,
      -59.632,
      -41.335,
      4.077,
      -59.632,
      -41.335,
      4.077,
      -58.724,
      -41.285,
      4.077,
      -58.724,
      -41.285,
      3.831,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "269.0",
    polygon: [
      -51.125,
      -40.875,
      5.598,
      -52.033,
      -40.924,
      5.598,
      -52.033,
      -40.924,
      5.844,
      -52.033,
      -40.924,
      5.844,
      -51.125,
      -40.875,
      5.844,
      -51.125,
      -40.875,
      5.598,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1302.0",
    polygon: [
      10.748,
      -11.497,
      18.86,
      9.432,
      -11.716,
      18.86,
      9.432,
      -11.716,
      21.32,
      9.432,
      -11.716,
      21.32,
      10.748,
      -11.497,
      21.32,
      10.748,
      -11.497,
      18.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "310.0",
    polygon: [
      -50.236,
      -40.823,
      3.831,
      -51.145,
      -40.872,
      3.831,
      -51.145,
      -40.872,
      4.077,
      -51.145,
      -40.872,
      4.077,
      -50.236,
      -40.823,
      4.077,
      -50.236,
      -40.823,
      3.831,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "188.0",
    polygon: [
      7.45,
      -12.067,
      4.86,
      5.2,
      -12.44,
      4.86,
      5.2,
      -12.44,
      7.32,
      5.2,
      -12.44,
      7.32,
      7.45,
      -12.067,
      7.32,
      7.45,
      -12.067,
      4.86,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "187.0",
    polygon: [
      5.2,
      -12.44,
      4.86,
      2.951,
      -12.813,
      4.86,
      2.951,
      -12.813,
      7.32,
      2.951,
      -12.813,
      7.32,
      5.2,
      -12.44,
      7.32,
      5.2,
      -12.44,
      4.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "603.0",
    polygon: [
      -22.463,
      -39.33,
      5.844,
      -23.372,
      -39.377,
      5.844,
      -23.372,
      -39.377,
      6.09,
      -23.372,
      -39.377,
      6.09,
      -22.463,
      -39.33,
      6.09,
      -22.463,
      -39.33,
      5.844,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1166.0",
    polygon: [
      7.829,
      -11.865,
      11.86,
      6.678,
      -12.056,
      11.86,
      6.678,
      -12.056,
      14.32,
      6.678,
      -12.056,
      14.32,
      7.829,
      -11.865,
      14.32,
      7.829,
      -11.865,
      11.86,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1216.0",
    polygon: [
      2.562,
      -36.298,
      4.86,
      1.762,
      -36.298,
      4.86,
      1.762,
      -36.298,
      7.32,
      1.762,
      -36.298,
      7.32,
      2.562,
      -36.298,
      7.32,
      2.562,
      -36.298,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "1085.0",
    polygon: [
      -23.325,
      -39.362,
      11.06,
      -25.142,
      -39.46,
      11.06,
      -25.142,
      -39.46,
      11.96,
      -25.142,
      -39.46,
      11.96,
      -23.325,
      -39.362,
      11.96,
      -23.325,
      -39.362,
      11.06,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "406.0",
    polygon: [
      -36.107,
      -40.66,
      5.68,
      -37.507,
      -40.736,
      5.68,
      -37.507,
      -40.736,
      6.5,
      -37.507,
      -40.736,
      6.5,
      -36.107,
      -40.66,
      6.5,
      -36.107,
      -40.66,
      5.68,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1048.0",
    polygon: [
      -28.019,
      -39.617,
      9.26,
      -29.836,
      -39.716,
      9.26,
      -29.836,
      -39.716,
      10.16,
      -29.836,
      -39.716,
      10.16,
      -28.019,
      -39.617,
      10.16,
      -28.019,
      -39.617,
      9.26,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "95.0",
    polygon: [
      -58.718,
      -41.29,
      5.598,
      -59.627,
      -41.34,
      5.598,
      -59.627,
      -41.34,
      5.844,
      -59.627,
      -41.34,
      5.844,
      -58.718,
      -41.29,
      5.844,
      -58.718,
      -41.29,
      5.598,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "768.0",
    polygon: [
      -12.143,
      -38.789,
      3.831,
      -13.052,
      -38.836,
      3.831,
      -13.052,
      -38.836,
      4.077,
      -13.052,
      -38.836,
      4.077,
      -12.143,
      -38.789,
      4.077,
      -12.143,
      -38.789,
      3.831,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1126.0",
    polygon: [
      -26.203,
      -39.518,
      14.66,
      -28.019,
      -39.617,
      14.66,
      -28.019,
      -39.617,
      15.56,
      -28.019,
      -39.617,
      15.56,
      -26.203,
      -39.518,
      15.56,
      -26.203,
      -39.518,
      14.66,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "54.0",
    polygon: [
      -62.472,
      -42.089,
      4.86,
      -63.553,
      -42.147,
      4.86,
      -63.553,
      -42.147,
      7.32,
      -63.553,
      -42.147,
      7.32,
      -62.472,
      -42.089,
      7.32,
      -62.472,
      -42.089,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "604.0",
    polygon: [
      -21.569,
      -39.283,
      5.844,
      -22.478,
      -39.33,
      5.844,
      -22.478,
      -39.33,
      6.09,
      -22.478,
      -39.33,
      6.09,
      -21.569,
      -39.283,
      6.09,
      -21.569,
      -39.283,
      5.844,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "403.0",
    polygon: [
      -70.109,
      -42.53,
      2.78,
      -72.234,
      -42.644,
      2.78,
      -72.234,
      -42.644,
      3.82,
      -72.234,
      -42.644,
      3.82,
      -70.109,
      -42.53,
      3.82,
      -70.109,
      -42.53,
      2.78,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1009.0",
    polygon: [
      -52.074,
      -40.904,
      15.56,
      -53.092,
      -40.959,
      15.56,
      -53.092,
      -40.959,
      16.46,
      -53.092,
      -40.959,
      16.46,
      -52.074,
      -40.904,
      16.46,
      -52.074,
      -40.904,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1010.0",
    polygon: [
      -56.772,
      -41.16,
      15.56,
      -57.791,
      -41.215,
      15.56,
      -57.791,
      -41.215,
      16.46,
      -57.791,
      -41.215,
      16.46,
      -56.772,
      -41.16,
      16.46,
      -56.772,
      -41.16,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1575.0",
    polygon: [
      -2.956,
      -13.732,
      36.36,
      -4.14,
      -13.925,
      36.36,
      -4.14,
      -13.925,
      39.128,
      -4.14,
      -13.925,
      39.128,
      -2.956,
      -13.732,
      39.128,
      -2.956,
      -13.732,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1303.0",
    polygon: [
      9.432,
      -11.716,
      18.86,
      8.116,
      -11.934,
      18.86,
      8.116,
      -11.934,
      21.32,
      8.116,
      -11.934,
      21.32,
      9.432,
      -11.716,
      21.32,
      9.432,
      -11.716,
      18.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1392.0",
    polygon: [
      10.744,
      -11.498,
      29.36,
      9.428,
      -11.716,
      29.36,
      9.428,
      -11.716,
      31.82,
      9.428,
      -11.716,
      31.82,
      10.744,
      -11.498,
      31.82,
      10.744,
      -11.498,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1685.0",
    polygon: [
      -39.021,
      -7.155,
      36.36,
      -40.221,
      -7.155,
      36.36,
      -40.221,
      -7.155,
      39.128,
      -40.221,
      -7.155,
      39.128,
      -39.021,
      -7.155,
      39.128,
      -39.021,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "970.0",
    polygon: [
      -56.772,
      -41.16,
      11.96,
      -57.791,
      -41.215,
      11.96,
      -57.791,
      -41.215,
      12.86,
      -57.791,
      -41.215,
      12.86,
      -56.772,
      -41.16,
      12.86,
      -56.772,
      -41.16,
      11.96,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "648.0",
    polygon: [
      -22.464,
      -39.33,
      0.05,
      -23.372,
      -39.377,
      0.05,
      -23.372,
      -39.377,
      2.78,
      -23.372,
      -39.377,
      2.78,
      -22.464,
      -39.33,
      2.78,
      -22.464,
      -39.33,
      0.05,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "444.0",
    polygon: [
      -6.904,
      -39.074,
      8.36,
      -7.966,
      -39.13,
      8.36,
      -7.966,
      -39.13,
      10.82,
      -7.966,
      -39.13,
      10.82,
      -6.904,
      -39.074,
      10.82,
      -6.904,
      -39.074,
      8.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "729.0",
    polygon: [
      -14.839,
      -38.93,
      5.844,
      -15.748,
      -38.978,
      5.844,
      -15.748,
      -38.978,
      6.09,
      -15.748,
      -38.978,
      6.09,
      -14.839,
      -38.93,
      6.09,
      -14.839,
      -38.93,
      5.844,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "931.0",
    polygon: [
      -59.629,
      -41.315,
      12.86,
      -61.445,
      -41.414,
      12.86,
      -61.445,
      -41.414,
      13.76,
      -61.445,
      -41.414,
      13.76,
      -59.629,
      -41.315,
      13.76,
      -59.629,
      -41.315,
      12.86,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1127.0",
    polygon: [
      -12.119,
      -38.752,
      15.56,
      -13.935,
      -38.851,
      15.56,
      -13.935,
      -38.851,
      16.46,
      -13.935,
      -38.851,
      16.46,
      -12.119,
      -38.752,
      16.46,
      -12.119,
      -38.752,
      15.56,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1261.0",
    polygon: [
      2.566,
      -34.968,
      11.86,
      2.566,
      -36.298,
      11.86,
      2.566,
      -36.298,
      14.32,
      2.566,
      -36.298,
      14.32,
      2.566,
      -34.968,
      14.32,
      2.566,
      -34.968,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "483.0",
    polygon: [
      -4.497,
      -38.948,
      16.18,
      -5.897,
      -39.021,
      16.18,
      -5.897,
      -39.021,
      17.0,
      -5.897,
      -39.021,
      17.0,
      -4.497,
      -38.948,
      17.0,
      -4.497,
      -38.948,
      16.18,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "728.0",
    polygon: [
      -12.143,
      -38.789,
      5.598,
      -13.052,
      -38.836,
      5.598,
      -13.052,
      -38.836,
      5.844,
      -13.052,
      -38.836,
      5.844,
      -12.143,
      -38.789,
      5.844,
      -12.143,
      -38.789,
      5.598,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "561.0",
    polygon: [
      -28.912,
      -39.668,
      4.569,
      -29.821,
      -39.715,
      4.569,
      -29.821,
      -39.715,
      4.815,
      -29.821,
      -39.715,
      4.815,
      -28.912,
      -39.668,
      4.815,
      -28.912,
      -39.668,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "1124.0",
    polygon: [
      -21.509,
      -39.263,
      14.66,
      -23.325,
      -39.362,
      14.66,
      -23.325,
      -39.362,
      15.56,
      -23.325,
      -39.362,
      15.56,
      -21.509,
      -39.263,
      15.56,
      -21.509,
      -39.263,
      14.66,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "968.0",
    polygon: [
      -45.545,
      -40.548,
      11.96,
      -47.361,
      -40.647,
      11.96,
      -47.361,
      -40.647,
      12.86,
      -47.361,
      -40.647,
      12.86,
      -45.545,
      -40.548,
      12.86,
      -45.545,
      -40.548,
      11.96,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "932.0",
    polygon: [
      -59.629,
      -41.315,
      13.76,
      -61.445,
      -41.414,
      13.76,
      -61.445,
      -41.414,
      14.66,
      -61.445,
      -41.414,
      14.66,
      -59.629,
      -41.315,
      14.66,
      -59.629,
      -41.315,
      13.76,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1049.0",
    polygon: [
      -28.019,
      -39.617,
      10.16,
      -29.836,
      -39.716,
      10.16,
      -29.836,
      -39.716,
      11.06,
      -29.836,
      -39.716,
      11.06,
      -28.019,
      -39.617,
      11.06,
      -28.019,
      -39.617,
      10.16,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "445.0",
    polygon: [
      -2.497,
      -38.843,
      8.36,
      -3.578,
      -38.9,
      8.36,
      -3.578,
      -38.9,
      10.82,
      -3.578,
      -38.9,
      10.82,
      -2.497,
      -38.843,
      10.82,
      -2.497,
      -38.843,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "1128.0",
    polygon: [
      -15.769,
      -38.951,
      15.56,
      -16.788,
      -39.006,
      15.56,
      -16.788,
      -39.006,
      16.46,
      -16.788,
      -39.006,
      16.46,
      -15.769,
      -38.951,
      16.46,
      -15.769,
      -38.951,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "55.0",
    polygon: [
      -67.715,
      -42.369,
      11.86,
      -69.115,
      -42.444,
      11.86,
      -69.115,
      -42.444,
      12.68,
      -69.115,
      -42.444,
      12.68,
      -67.715,
      -42.369,
      12.68,
      -67.715,
      -42.369,
      11.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1011.0",
    polygon: [
      -48.416,
      -40.704,
      15.56,
      -50.233,
      -40.803,
      15.56,
      -50.233,
      -40.803,
      16.46,
      -50.233,
      -40.803,
      16.46,
      -48.416,
      -40.704,
      16.46,
      -48.416,
      -40.704,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "446.0",
    polygon: [
      -1.416,
      -38.786,
      8.36,
      -2.498,
      -38.843,
      8.36,
      -2.498,
      -38.843,
      10.82,
      -2.498,
      -38.843,
      10.82,
      -1.416,
      -38.786,
      10.82,
      -1.416,
      -38.786,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "933.0",
    polygon: [
      -59.629,
      -41.315,
      14.66,
      -61.445,
      -41.414,
      14.66,
      -61.445,
      -41.414,
      15.56,
      -61.445,
      -41.414,
      15.56,
      -59.629,
      -41.315,
      15.56,
      -59.629,
      -41.315,
      14.66,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "563.0",
    polygon: [
      -27.11,
      -39.573,
      4.569,
      -28.018,
      -39.621,
      4.569,
      -28.018,
      -39.621,
      4.815,
      -28.018,
      -39.621,
      4.815,
      -27.11,
      -39.573,
      4.815,
      -27.11,
      -39.573,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "1168.0",
    polygon: [
      13.226,
      -10.97,
      11.86,
      12.075,
      -11.161,
      11.86,
      12.075,
      -11.161,
      14.32,
      12.075,
      -11.161,
      14.32,
      13.226,
      -10.97,
      14.32,
      13.226,
      -10.97,
      11.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1305.0",
    polygon: [
      6.799,
      -12.152,
      18.86,
      5.483,
      -12.371,
      18.86,
      5.483,
      -12.371,
      21.32,
      5.483,
      -12.371,
      21.32,
      6.799,
      -12.152,
      21.32,
      6.799,
      -12.152,
      18.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "606.0",
    polygon: [
      -23.372,
      -39.377,
      6.09,
      -24.281,
      -39.425,
      6.09,
      -24.281,
      -39.425,
      6.336,
      -24.281,
      -39.425,
      6.336,
      -23.372,
      -39.377,
      6.336,
      -23.372,
      -39.377,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1217.0",
    polygon: [
      2.562,
      -36.298,
      11.86,
      1.762,
      -36.298,
      11.86,
      1.762,
      -36.298,
      14.32,
      1.762,
      -36.298,
      14.32,
      2.562,
      -36.298,
      14.32,
      2.562,
      -36.298,
      11.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "971.0",
    polygon: [
      -48.416,
      -40.704,
      11.96,
      -50.233,
      -40.803,
      11.96,
      -50.233,
      -40.803,
      12.86,
      -50.233,
      -40.803,
      12.86,
      -48.416,
      -40.704,
      12.86,
      -48.416,
      -40.704,
      11.96,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "271.0",
    polygon: [
      -49.322,
      -40.777,
      5.598,
      -50.231,
      -40.826,
      5.598,
      -50.231,
      -40.826,
      5.844,
      -50.231,
      -40.826,
      5.844,
      -49.322,
      -40.777,
      5.844,
      -49.322,
      -40.777,
      5.598,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1686.0",
    polygon: [
      -39.021,
      -7.155,
      39.128,
      -40.221,
      -7.155,
      39.128,
      -40.221,
      -7.155,
      40.05,
      -40.221,
      -7.155,
      40.05,
      -39.021,
      -7.155,
      40.05,
      -39.021,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "484.0",
    polygon: [
      -4.497,
      -38.948,
      17.0,
      -5.897,
      -39.021,
      17.0,
      -5.897,
      -39.021,
      17.82,
      -5.897,
      -39.021,
      17.82,
      -4.497,
      -38.948,
      17.82,
      -4.497,
      -38.948,
      17.0,
    ],
    "04-15 09:15": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "1088.0",
    polygon: [
      -15.769,
      -38.951,
      11.96,
      -16.788,
      -39.006,
      11.96,
      -16.788,
      -39.006,
      12.86,
      -16.788,
      -39.006,
      12.86,
      -15.769,
      -38.951,
      12.86,
      -15.769,
      -38.951,
      11.96,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1577.0",
    polygon: [
      -1.62,
      -13.515,
      36.36,
      -2.804,
      -13.708,
      36.36,
      -2.804,
      -13.708,
      39.128,
      -2.804,
      -13.708,
      39.128,
      -1.62,
      -13.515,
      39.128,
      -1.62,
      -13.515,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1393.0",
    polygon: [
      9.428,
      -11.716,
      29.36,
      8.112,
      -11.935,
      29.36,
      8.112,
      -11.935,
      31.82,
      8.112,
      -11.935,
      31.82,
      9.428,
      -11.716,
      31.82,
      9.428,
      -11.716,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "524.0",
    polygon: [
      -26.216,
      -39.526,
      4.86,
      -27.125,
      -39.574,
      4.86,
      -27.125,
      -39.574,
      5.106,
      -27.125,
      -39.574,
      5.106,
      -26.216,
      -39.526,
      5.106,
      -26.216,
      -39.526,
      4.86,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "649.0",
    polygon: [
      -19.512,
      -39.175,
      4.86,
      -20.421,
      -39.223,
      4.86,
      -20.421,
      -39.223,
      5.106,
      -20.421,
      -39.223,
      5.106,
      -19.512,
      -39.175,
      5.106,
      -19.512,
      -39.175,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "311.0",
    polygon: [
      -49.328,
      -40.773,
      3.831,
      -50.236,
      -40.823,
      3.831,
      -50.236,
      -40.823,
      4.077,
      -50.236,
      -40.823,
      4.077,
      -49.328,
      -40.773,
      4.077,
      -49.328,
      -40.773,
      3.831,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1169.0",
    polygon: [
      15.925,
      -10.522,
      11.86,
      14.773,
      -10.713,
      11.86,
      14.773,
      -10.713,
      14.32,
      14.773,
      -10.713,
      14.32,
      15.925,
      -10.522,
      14.32,
      15.925,
      -10.522,
      11.86,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1306.0",
    polygon: [
      5.483,
      -12.371,
      18.86,
      4.167,
      -12.589,
      18.86,
      4.167,
      -12.589,
      21.32,
      4.167,
      -12.589,
      21.32,
      5.483,
      -12.371,
      21.32,
      5.483,
      -12.371,
      18.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "607.0",
    polygon: [
      -22.463,
      -39.33,
      6.09,
      -23.372,
      -39.377,
      6.09,
      -23.372,
      -39.377,
      6.336,
      -23.372,
      -39.377,
      6.336,
      -22.463,
      -39.33,
      6.336,
      -22.463,
      -39.33,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1578.0",
    polygon: [
      -1.62,
      -13.515,
      39.128,
      -2.804,
      -13.708,
      39.128,
      -2.804,
      -13.708,
      40.05,
      -2.804,
      -13.708,
      40.05,
      -1.62,
      -13.515,
      40.05,
      -1.62,
      -13.515,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "143.0",
    polygon: [
      -57.811,
      -41.236,
      2.78,
      -59.628,
      -41.334,
      2.78,
      -59.628,
      -41.334,
      3.82,
      -59.628,
      -41.334,
      3.82,
      -57.811,
      -41.236,
      3.82,
      -57.811,
      -41.236,
      2.78,
    ],
    "04-15 15:00": 1,
  },
  {
    ID: "650.0",
    polygon: [
      -18.618,
      -39.128,
      4.86,
      -19.527,
      -39.176,
      4.86,
      -19.527,
      -39.176,
      5.106,
      -19.527,
      -39.176,
      5.106,
      -18.618,
      -39.128,
      5.106,
      -18.618,
      -39.128,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "525.0",
    polygon: [
      -28.912,
      -39.668,
      5.106,
      -29.821,
      -39.715,
      5.106,
      -29.821,
      -39.715,
      5.352,
      -29.821,
      -39.715,
      5.352,
      -28.912,
      -39.668,
      5.352,
      -28.912,
      -39.668,
      5.106,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "1264.0",
    polygon: [
      2.566,
      -30.976,
      11.86,
      2.566,
      -32.307,
      11.86,
      2.566,
      -32.307,
      14.32,
      2.566,
      -32.307,
      14.32,
      2.566,
      -30.976,
      14.32,
      2.566,
      -30.976,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "523.0",
    polygon: [
      -27.11,
      -39.573,
      4.86,
      -28.018,
      -39.621,
      4.86,
      -28.018,
      -39.621,
      5.106,
      -28.018,
      -39.621,
      5.106,
      -27.11,
      -39.573,
      5.106,
      -27.11,
      -39.573,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "408.0",
    polygon: [
      -41.7,
      -40.964,
      4.86,
      -42.763,
      -41.022,
      4.86,
      -42.763,
      -41.022,
      7.32,
      -42.763,
      -41.022,
      7.32,
      -41.7,
      -40.964,
      7.32,
      -41.7,
      -40.964,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1220.0",
    polygon: [
      2.562,
      -36.298,
      0.05,
      1.762,
      -36.298,
      0.05,
      1.762,
      -36.298,
      2.78,
      1.762,
      -36.298,
      2.78,
      2.562,
      -36.298,
      2.78,
      2.562,
      -36.298,
      0.05,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "1052.0",
    polygon: [
      -28.019,
      -39.617,
      12.86,
      -29.836,
      -39.716,
      12.86,
      -29.836,
      -39.716,
      13.76,
      -29.836,
      -39.716,
      13.76,
      -28.019,
      -39.617,
      13.76,
      -28.019,
      -39.617,
      12.86,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1263.0",
    polygon: [
      2.566,
      -32.307,
      11.86,
      2.566,
      -33.637,
      11.86,
      2.566,
      -33.637,
      14.32,
      2.566,
      -33.637,
      14.32,
      2.566,
      -32.307,
      14.32,
      2.566,
      -32.307,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "189.0",
    polygon: [
      7.45,
      -12.067,
      0.05,
      6.325,
      -12.253,
      0.05,
      6.325,
      -12.253,
      2.78,
      6.325,
      -12.253,
      2.78,
      7.45,
      -12.067,
      2.78,
      7.45,
      -12.067,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "1051.0",
    polygon: [
      -28.019,
      -39.617,
      11.96,
      -29.836,
      -39.716,
      11.96,
      -29.836,
      -39.716,
      12.86,
      -29.836,
      -39.716,
      12.86,
      -28.019,
      -39.617,
      12.86,
      -28.019,
      -39.617,
      11.96,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1218.0",
    polygon: [
      2.562,
      -36.298,
      15.36,
      1.762,
      -36.298,
      15.36,
      1.762,
      -36.298,
      17.82,
      1.762,
      -36.298,
      17.82,
      2.562,
      -36.298,
      17.82,
      2.562,
      -36.298,
      15.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "447.0",
    polygon: [
      -0.335,
      -38.73,
      8.36,
      -1.416,
      -38.786,
      8.36,
      -1.416,
      -38.786,
      10.82,
      -1.416,
      -38.786,
      10.82,
      -0.335,
      -38.73,
      10.82,
      -0.335,
      -38.73,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "934.0",
    polygon: [
      -59.629,
      -41.315,
      15.56,
      -61.445,
      -41.414,
      15.56,
      -61.445,
      -41.414,
      16.46,
      -61.445,
      -41.414,
      16.46,
      -59.629,
      -41.315,
      16.46,
      -59.629,
      -41.315,
      15.56,
    ],
    "04-15 06:00": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "564.0",
    polygon: [
      -26.216,
      -39.526,
      4.569,
      -27.125,
      -39.574,
      4.569,
      -27.125,
      -39.574,
      4.815,
      -27.125,
      -39.574,
      4.815,
      -26.216,
      -39.526,
      4.815,
      -26.216,
      -39.526,
      4.569,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1130.0",
    polygon: [
      -20.464,
      -39.206,
      15.56,
      -21.483,
      -39.261,
      15.56,
      -21.483,
      -39.261,
      16.46,
      -21.483,
      -39.261,
      16.46,
      -20.464,
      -39.206,
      16.46,
      -20.464,
      -39.206,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "192.0",
    polygon: [
      4.076,
      -12.626,
      0.05,
      2.951,
      -12.813,
      0.05,
      2.951,
      -12.813,
      2.78,
      2.951,
      -12.813,
      2.78,
      4.076,
      -12.626,
      2.78,
      4.076,
      -12.626,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "608.0",
    polygon: [
      -21.569,
      -39.283,
      6.09,
      -22.478,
      -39.33,
      6.09,
      -22.478,
      -39.33,
      6.336,
      -22.478,
      -39.33,
      6.336,
      -21.569,
      -39.283,
      6.336,
      -21.569,
      -39.283,
      6.09,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "689.0",
    polygon: [
      -19.512,
      -39.175,
      4.569,
      -20.421,
      -39.223,
      4.569,
      -20.421,
      -39.223,
      4.815,
      -20.421,
      -39.223,
      4.815,
      -19.512,
      -39.175,
      4.815,
      -19.512,
      -39.175,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1129.0",
    polygon: [
      -13.935,
      -38.851,
      15.56,
      -15.751,
      -38.95,
      15.56,
      -15.751,
      -38.95,
      16.46,
      -15.751,
      -38.95,
      16.46,
      -13.935,
      -38.851,
      16.46,
      -13.935,
      -38.851,
      15.56,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "136.0",
    polygon: [
      -57.83,
      -41.237,
      3.831,
      -58.739,
      -41.286,
      3.831,
      -58.739,
      -41.286,
      4.077,
      -58.739,
      -41.286,
      4.077,
      -57.83,
      -41.237,
      4.077,
      -57.83,
      -41.237,
      3.831,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "973.0",
    polygon: [
      -53.118,
      -40.961,
      11.96,
      -54.935,
      -41.06,
      11.96,
      -54.935,
      -41.06,
      12.86,
      -54.935,
      -41.06,
      12.86,
      -53.118,
      -40.961,
      12.86,
      -53.118,
      -40.961,
      11.96,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1050.0",
    polygon: [
      -28.019,
      -39.617,
      11.06,
      -29.836,
      -39.716,
      11.06,
      -29.836,
      -39.716,
      11.96,
      -29.836,
      -39.716,
      11.96,
      -28.019,
      -39.617,
      11.96,
      -28.019,
      -39.617,
      11.06,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1091.0",
    polygon: [
      -25.163,
      -39.462,
      11.96,
      -26.182,
      -39.517,
      11.96,
      -26.182,
      -39.517,
      12.86,
      -26.182,
      -39.517,
      12.86,
      -25.163,
      -39.462,
      12.86,
      -25.163,
      -39.462,
      11.96,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "732.0",
    polygon: [
      -12.143,
      -38.789,
      5.844,
      -13.052,
      -38.836,
      5.844,
      -13.052,
      -38.836,
      6.09,
      -13.052,
      -38.836,
      6.09,
      -12.143,
      -38.789,
      6.09,
      -12.143,
      -38.789,
      5.844,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1265.0",
    polygon: [
      2.566,
      -29.646,
      11.86,
      2.566,
      -30.976,
      11.86,
      2.566,
      -30.976,
      14.32,
      2.566,
      -30.976,
      14.32,
      2.566,
      -29.646,
      14.32,
      2.566,
      -29.646,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "731.0",
    polygon: [
      -13.037,
      -38.836,
      5.844,
      -13.946,
      -38.883,
      5.844,
      -13.946,
      -38.883,
      6.09,
      -13.946,
      -38.883,
      6.09,
      -13.037,
      -38.836,
      6.09,
      -13.037,
      -38.836,
      5.844,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1262.0",
    polygon: [
      2.566,
      -33.637,
      11.86,
      2.566,
      -34.968,
      11.86,
      2.566,
      -34.968,
      14.32,
      2.566,
      -34.968,
      14.32,
      2.566,
      -33.637,
      14.32,
      2.566,
      -33.637,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1090.0",
    polygon: [
      -20.464,
      -39.206,
      11.96,
      -21.483,
      -39.261,
      11.96,
      -21.483,
      -39.261,
      12.86,
      -21.483,
      -39.261,
      12.86,
      -20.464,
      -39.206,
      12.86,
      -20.464,
      -39.206,
      11.96,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "191.0",
    polygon: [
      5.2,
      -12.44,
      0.05,
      4.076,
      -12.626,
      0.05,
      4.076,
      -12.626,
      2.78,
      4.076,
      -12.626,
      2.78,
      5.2,
      -12.44,
      2.78,
      5.2,
      -12.44,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "1012.0",
    polygon: [
      -50.233,
      -40.803,
      15.56,
      -52.049,
      -40.902,
      15.56,
      -52.049,
      -40.902,
      16.46,
      -52.049,
      -40.902,
      16.46,
      -50.233,
      -40.803,
      16.46,
      -50.233,
      -40.803,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "733.0",
    polygon: [
      -14.839,
      -38.93,
      6.09,
      -15.748,
      -38.978,
      6.09,
      -15.748,
      -38.978,
      6.336,
      -15.748,
      -38.978,
      6.336,
      -14.839,
      -38.93,
      6.336,
      -14.839,
      -38.93,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1396.0",
    polygon: [
      5.479,
      -12.371,
      29.36,
      4.163,
      -12.59,
      29.36,
      4.163,
      -12.59,
      31.82,
      4.163,
      -12.59,
      31.82,
      5.479,
      -12.371,
      31.82,
      5.479,
      -12.371,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "190.0",
    polygon: [
      6.325,
      -12.253,
      0.05,
      5.2,
      -12.44,
      0.05,
      5.2,
      -12.44,
      2.78,
      5.2,
      -12.44,
      2.78,
      6.325,
      -12.253,
      2.78,
      6.325,
      -12.253,
      0.05,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "1395.0",
    polygon: [
      6.795,
      -12.153,
      29.36,
      5.479,
      -12.371,
      29.36,
      5.479,
      -12.371,
      31.82,
      5.479,
      -12.371,
      31.82,
      6.795,
      -12.153,
      31.82,
      6.795,
      -12.153,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "98.0",
    polygon: [
      -59.627,
      -41.34,
      5.844,
      -60.536,
      -41.389,
      5.844,
      -60.536,
      -41.389,
      6.09,
      -60.536,
      -41.389,
      6.09,
      -59.627,
      -41.34,
      6.09,
      -59.627,
      -41.34,
      5.844,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "526.0",
    polygon: [
      -28.018,
      -39.621,
      5.106,
      -28.927,
      -39.668,
      5.106,
      -28.927,
      -39.668,
      5.352,
      -28.927,
      -39.668,
      5.352,
      -28.018,
      -39.621,
      5.352,
      -28.018,
      -39.621,
      5.106,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "410.0",
    polygon: [
      -39.575,
      -40.849,
      4.86,
      -40.638,
      -40.906,
      4.86,
      -40.638,
      -40.906,
      7.32,
      -40.638,
      -40.906,
      7.32,
      -39.575,
      -40.849,
      7.32,
      -39.575,
      -40.849,
      4.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "651.0",
    polygon: [
      -17.71,
      -39.081,
      4.86,
      -18.618,
      -39.128,
      4.86,
      -18.618,
      -39.128,
      5.106,
      -18.618,
      -39.128,
      5.106,
      -17.71,
      -39.081,
      5.106,
      -17.71,
      -39.081,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "486.0",
    polygon: [
      -9.029,
      -39.185,
      15.36,
      -10.091,
      -39.241,
      15.36,
      -10.091,
      -39.241,
      17.82,
      -10.091,
      -39.241,
      17.82,
      -9.029,
      -39.185,
      17.82,
      -9.029,
      -39.185,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "57.0",
    polygon: [
      -67.715,
      -42.369,
      13.5,
      -69.115,
      -42.444,
      13.5,
      -69.115,
      -42.444,
      14.32,
      -69.115,
      -42.444,
      14.32,
      -67.715,
      -42.369,
      14.32,
      -67.715,
      -42.369,
      13.5,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1307.0",
    polygon: [
      4.167,
      -12.589,
      18.86,
      2.85,
      -12.807,
      18.86,
      2.85,
      -12.807,
      21.32,
      2.85,
      -12.807,
      21.32,
      4.167,
      -12.589,
      21.32,
      4.167,
      -12.589,
      18.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1524.0",
    polygon: [
      -2.824,
      -13.596,
      25.86,
      -3.975,
      -13.787,
      25.86,
      -3.975,
      -13.787,
      28.32,
      -3.975,
      -13.787,
      28.32,
      -2.824,
      -13.596,
      28.32,
      -2.824,
      -13.596,
      25.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1131.0",
    polygon: [
      -25.163,
      -39.462,
      15.56,
      -26.182,
      -39.517,
      15.56,
      -26.182,
      -39.517,
      16.46,
      -26.182,
      -39.517,
      16.46,
      -25.163,
      -39.462,
      16.46,
      -25.163,
      -39.462,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1089.0",
    polygon: [
      -13.935,
      -38.851,
      11.96,
      -15.751,
      -38.95,
      11.96,
      -15.751,
      -38.95,
      12.86,
      -15.751,
      -38.95,
      12.86,
      -13.935,
      -38.851,
      12.86,
      -13.935,
      -38.851,
      11.96,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "56.0",
    polygon: [
      -67.715,
      -42.369,
      12.68,
      -69.115,
      -42.444,
      12.68,
      -69.115,
      -42.444,
      13.5,
      -69.115,
      -42.444,
      13.5,
      -67.715,
      -42.369,
      13.5,
      -67.715,
      -42.369,
      12.68,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1527.0",
    polygon: [
      -8.971,
      -14.616,
      25.86,
      -10.123,
      -14.807,
      25.86,
      -10.123,
      -14.807,
      28.32,
      -10.123,
      -14.807,
      28.32,
      -8.971,
      -14.616,
      28.32,
      -8.971,
      -14.616,
      25.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "409.0",
    polygon: [
      -40.638,
      -40.906,
      4.86,
      -41.7,
      -40.964,
      4.86,
      -41.7,
      -40.964,
      7.32,
      -41.7,
      -40.964,
      7.32,
      -40.638,
      -40.906,
      7.32,
      -40.638,
      -40.906,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1526.0",
    polygon: [
      -6.268,
      -14.168,
      25.86,
      -7.42,
      -14.359,
      25.86,
      -7.42,
      -14.359,
      28.32,
      -7.42,
      -14.359,
      28.32,
      -6.268,
      -14.168,
      28.32,
      -6.268,
      -14.168,
      25.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1576.0",
    polygon: [
      -2.956,
      -13.732,
      39.128,
      -4.14,
      -13.925,
      39.128,
      -4.14,
      -13.925,
      40.05,
      -4.14,
      -13.925,
      40.05,
      -2.956,
      -13.732,
      40.05,
      -2.956,
      -13.732,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "101.0",
    polygon: [
      -60.521,
      -41.389,
      6.09,
      -61.429,
      -41.438,
      6.09,
      -61.429,
      -41.438,
      6.336,
      -61.429,
      -41.438,
      6.336,
      -60.521,
      -41.389,
      6.336,
      -60.521,
      -41.389,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "58.0",
    polygon: [
      -73.309,
      -42.668,
      11.86,
      -74.372,
      -42.725,
      11.86,
      -74.372,
      -42.725,
      14.32,
      -74.372,
      -42.725,
      14.32,
      -73.309,
      -42.668,
      14.32,
      -73.309,
      -42.668,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1525.0",
    polygon: [
      -1.672,
      -13.405,
      25.86,
      -2.824,
      -13.596,
      25.86,
      -2.824,
      -13.596,
      28.32,
      -2.824,
      -13.596,
      28.32,
      -1.672,
      -13.405,
      28.32,
      -1.672,
      -13.405,
      25.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1013.0",
    polygon: [
      -53.118,
      -40.961,
      15.56,
      -54.935,
      -41.06,
      15.56,
      -54.935,
      -41.06,
      16.46,
      -54.935,
      -41.06,
      16.46,
      -53.118,
      -40.961,
      16.46,
      -53.118,
      -40.961,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "274.0",
    polygon: [
      -50.231,
      -40.826,
      5.844,
      -51.14,
      -40.876,
      5.844,
      -51.14,
      -40.876,
      6.09,
      -51.14,
      -40.876,
      6.09,
      -50.231,
      -40.826,
      6.09,
      -50.231,
      -40.826,
      5.844,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "100.0",
    polygon: [
      -57.825,
      -41.242,
      5.844,
      -58.733,
      -41.291,
      5.844,
      -58.733,
      -41.291,
      6.09,
      -58.733,
      -41.291,
      6.09,
      -57.825,
      -41.242,
      6.09,
      -57.825,
      -41.242,
      5.844,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "691.0",
    polygon: [
      -17.71,
      -39.081,
      4.569,
      -18.618,
      -39.128,
      4.569,
      -18.618,
      -39.128,
      4.815,
      -18.618,
      -39.128,
      4.815,
      -17.71,
      -39.081,
      4.815,
      -17.71,
      -39.081,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "273.0",
    polygon: [
      -51.125,
      -40.875,
      5.844,
      -52.033,
      -40.924,
      5.844,
      -52.033,
      -40.924,
      6.09,
      -52.033,
      -40.924,
      6.09,
      -51.125,
      -40.875,
      6.09,
      -51.125,
      -40.875,
      5.844,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "312.0",
    polygon: [
      -48.434,
      -40.725,
      3.831,
      -49.343,
      -40.774,
      3.831,
      -49.343,
      -40.774,
      4.077,
      -49.343,
      -40.774,
      4.077,
      -48.434,
      -40.725,
      4.077,
      -48.434,
      -40.725,
      3.831,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "99.0",
    polygon: [
      -58.718,
      -41.29,
      5.844,
      -59.627,
      -41.34,
      5.844,
      -59.627,
      -41.34,
      6.09,
      -59.627,
      -41.34,
      6.09,
      -58.718,
      -41.29,
      6.09,
      -58.718,
      -41.29,
      5.844,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "730.0",
    polygon: [
      -13.946,
      -38.883,
      5.844,
      -14.854,
      -38.931,
      5.844,
      -14.854,
      -38.931,
      6.09,
      -14.854,
      -38.931,
      6.09,
      -13.946,
      -38.883,
      6.09,
      -13.946,
      -38.883,
      5.844,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "690.0",
    polygon: [
      -18.618,
      -39.128,
      4.569,
      -19.527,
      -39.176,
      4.569,
      -19.527,
      -39.176,
      4.815,
      -19.527,
      -39.176,
      4.815,
      -18.618,
      -39.128,
      4.815,
      -18.618,
      -39.128,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "485.0",
    polygon: [
      -10.091,
      -39.241,
      15.36,
      -11.154,
      -39.297,
      15.36,
      -11.154,
      -39.297,
      17.82,
      -11.154,
      -39.297,
      17.82,
      -10.091,
      -39.241,
      17.82,
      -10.091,
      -39.241,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1689.0",
    polygon: [
      -34.492,
      -7.155,
      36.36,
      -35.692,
      -7.155,
      36.36,
      -35.692,
      -7.155,
      39.128,
      -35.692,
      -7.155,
      39.128,
      -34.492,
      -7.155,
      39.128,
      -34.492,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1580.0",
    polygon: [
      -0.284,
      -13.298,
      39.128,
      -1.468,
      -13.491,
      39.128,
      -1.468,
      -13.491,
      40.05,
      -1.468,
      -13.491,
      40.05,
      -0.284,
      -13.298,
      40.05,
      -0.284,
      -13.298,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "972.0",
    polygon: [
      -50.233,
      -40.803,
      11.96,
      -52.049,
      -40.902,
      11.96,
      -52.049,
      -40.902,
      12.86,
      -52.049,
      -40.902,
      12.86,
      -50.233,
      -40.803,
      12.86,
      -50.233,
      -40.803,
      11.96,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "527.0",
    polygon: [
      -27.11,
      -39.573,
      5.106,
      -28.018,
      -39.621,
      5.106,
      -28.018,
      -39.621,
      5.352,
      -28.018,
      -39.621,
      5.352,
      -27.11,
      -39.573,
      5.352,
      -27.11,
      -39.573,
      5.106,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "448.0",
    polygon: [
      0.747,
      -38.673,
      8.36,
      -0.335,
      -38.73,
      8.36,
      -0.335,
      -38.73,
      10.82,
      -0.335,
      -38.73,
      10.82,
      0.747,
      -38.673,
      10.82,
      0.747,
      -38.673,
      8.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "562.0",
    polygon: [
      -28.018,
      -39.621,
      4.569,
      -28.927,
      -39.668,
      4.569,
      -28.927,
      -39.668,
      4.815,
      -28.927,
      -39.668,
      4.815,
      -28.018,
      -39.621,
      4.815,
      -28.018,
      -39.621,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "652.0",
    polygon: [
      -16.816,
      -39.034,
      4.86,
      -17.725,
      -39.081,
      4.86,
      -17.725,
      -39.081,
      5.106,
      -17.725,
      -39.081,
      5.106,
      -16.816,
      -39.034,
      5.106,
      -16.816,
      -39.034,
      4.86,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1167.0",
    polygon: [
      10.528,
      -11.417,
      11.86,
      9.376,
      -11.608,
      11.86,
      9.376,
      -11.608,
      14.32,
      9.376,
      -11.608,
      14.32,
      10.528,
      -11.417,
      14.32,
      10.528,
      -11.417,
      11.86,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "935.0",
    polygon: [
      -59.629,
      -41.315,
      16.46,
      -61.445,
      -41.414,
      16.46,
      -61.445,
      -41.414,
      17.36,
      -61.445,
      -41.414,
      17.36,
      -59.629,
      -41.315,
      17.36,
      -59.629,
      -41.315,
      16.46,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "145.0",
    polygon: [
      -57.811,
      -41.236,
      0.05,
      -59.627,
      -41.334,
      0.05,
      -59.627,
      -41.334,
      2.78,
      -59.627,
      -41.334,
      2.78,
      -57.811,
      -41.236,
      2.78,
      -57.811,
      -41.236,
      0.05,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "974.0",
    polygon: [
      -54.935,
      -41.06,
      11.96,
      -56.751,
      -41.159,
      11.96,
      -56.751,
      -41.159,
      12.86,
      -56.751,
      -41.159,
      12.86,
      -54.935,
      -41.06,
      12.86,
      -54.935,
      -41.06,
      11.96,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "775.0",
    polygon: [
      -13.946,
      -38.883,
      0.05,
      -15.762,
      -38.979,
      0.05,
      -15.762,
      -38.979,
      2.78,
      -15.762,
      -38.979,
      2.78,
      -13.946,
      -38.883,
      2.78,
      -13.946,
      -38.883,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1014.0",
    polygon: [
      -54.935,
      -41.06,
      15.56,
      -56.751,
      -41.159,
      15.56,
      -56.751,
      -41.159,
      16.46,
      -56.751,
      -41.159,
      16.46,
      -54.935,
      -41.06,
      16.46,
      -54.935,
      -41.06,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1170.0",
    polygon: [
      18.623,
      -10.075,
      11.86,
      17.472,
      -10.266,
      11.86,
      17.472,
      -10.266,
      14.32,
      17.472,
      -10.266,
      14.32,
      18.623,
      -10.075,
      14.32,
      18.623,
      -10.075,
      11.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1304.0",
    polygon: [
      8.116,
      -11.934,
      18.86,
      6.799,
      -12.152,
      18.86,
      6.799,
      -12.152,
      21.32,
      6.799,
      -12.152,
      21.32,
      8.116,
      -11.934,
      21.32,
      8.116,
      -11.934,
      18.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1394.0",
    polygon: [
      8.112,
      -11.935,
      29.36,
      6.795,
      -12.153,
      29.36,
      6.795,
      -12.153,
      31.82,
      6.795,
      -12.153,
      31.82,
      8.112,
      -11.935,
      31.82,
      8.112,
      -11.935,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "318.0",
    polygon: [
      -43.727,
      -40.468,
      2.78,
      -45.544,
      -40.567,
      2.78,
      -45.544,
      -40.567,
      3.82,
      -45.544,
      -40.567,
      3.82,
      -43.727,
      -40.468,
      3.82,
      -43.727,
      -40.468,
      2.78,
    ],
    "04-15 15:00": 1,
  },
  {
    ID: "1688.0",
    polygon: [
      -37.66,
      -7.155,
      39.128,
      -38.86,
      -7.155,
      39.128,
      -38.86,
      -7.155,
      40.05,
      -38.86,
      -7.155,
      40.05,
      -37.66,
      -7.155,
      40.05,
      -37.66,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "144.0",
    polygon: [
      -59.627,
      -41.334,
      0.05,
      -61.444,
      -41.433,
      0.05,
      -61.444,
      -41.433,
      2.78,
      -61.444,
      -41.433,
      2.78,
      -59.627,
      -41.334,
      2.78,
      -59.627,
      -41.334,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "605.0",
    polygon: [
      -24.266,
      -39.424,
      6.09,
      -25.174,
      -39.472,
      6.09,
      -25.174,
      -39.472,
      6.336,
      -25.174,
      -39.472,
      6.336,
      -24.266,
      -39.424,
      6.336,
      -24.266,
      -39.424,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "407.0",
    polygon: [
      -36.107,
      -40.66,
      6.5,
      -37.507,
      -40.736,
      6.5,
      -37.507,
      -40.736,
      7.32,
      -37.507,
      -40.736,
      7.32,
      -36.107,
      -40.66,
      7.32,
      -36.107,
      -40.66,
      6.5,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "487.0",
    polygon: [
      -7.966,
      -39.13,
      15.36,
      -9.029,
      -39.185,
      15.36,
      -9.029,
      -39.185,
      17.82,
      -9.029,
      -39.185,
      17.82,
      -7.966,
      -39.13,
      17.82,
      -7.966,
      -39.13,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1579.0",
    polygon: [
      -0.284,
      -13.298,
      36.36,
      -1.468,
      -13.491,
      36.36,
      -1.468,
      -13.491,
      39.128,
      -1.468,
      -13.491,
      39.128,
      -0.284,
      -13.298,
      39.128,
      -0.284,
      -13.298,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1687.0",
    polygon: [
      -37.66,
      -7.155,
      36.36,
      -38.86,
      -7.155,
      36.36,
      -38.86,
      -7.155,
      39.128,
      -38.86,
      -7.155,
      39.128,
      -37.66,
      -7.155,
      39.128,
      -37.66,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "774.0",
    polygon: [
      -12.13,
      -38.788,
      2.78,
      -13.946,
      -38.883,
      2.78,
      -13.946,
      -38.883,
      3.82,
      -13.946,
      -38.883,
      3.82,
      -12.13,
      -38.788,
      3.82,
      -12.13,
      -38.788,
      2.78,
    ],
    "04-15 15:00": 1,
  },
  {
    ID: "272.0",
    polygon: [
      -48.429,
      -40.728,
      5.598,
      -49.337,
      -40.778,
      5.598,
      -49.337,
      -40.778,
      5.844,
      -49.337,
      -40.778,
      5.844,
      -48.429,
      -40.728,
      5.844,
      -48.429,
      -40.728,
      5.598,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "776.0",
    polygon: [
      -12.129,
      -38.788,
      0.05,
      -13.946,
      -38.883,
      0.05,
      -13.946,
      -38.883,
      2.78,
      -13.946,
      -38.883,
      2.78,
      -12.129,
      -38.788,
      2.78,
      -12.129,
      -38.788,
      0.05,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "147.0",
    polygon: [
      -48.415,
      -40.724,
      2.78,
      -50.232,
      -40.823,
      2.78,
      -50.232,
      -40.823,
      3.82,
      -50.232,
      -40.823,
      3.82,
      -48.415,
      -40.724,
      3.82,
      -48.415,
      -40.724,
      2.78,
    ],
    "04-15 15:00": 1,
  },
  {
    ID: "1132.0",
    polygon: [
      -16.807,
      -39.007,
      15.56,
      -18.623,
      -39.106,
      15.56,
      -18.623,
      -39.106,
      16.46,
      -18.623,
      -39.106,
      16.46,
      -16.807,
      -39.007,
      16.46,
      -16.807,
      -39.007,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "320.0",
    polygon: [
      -43.727,
      -40.468,
      0.05,
      -45.543,
      -40.567,
      0.05,
      -45.543,
      -40.567,
      2.78,
      -45.543,
      -40.567,
      2.78,
      -43.727,
      -40.468,
      2.78,
      -43.727,
      -40.468,
      0.05,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1528.0",
    polygon: [
      -7.42,
      -14.359,
      25.86,
      -8.571,
      -14.55,
      25.86,
      -8.571,
      -14.55,
      28.32,
      -8.571,
      -14.55,
      28.32,
      -7.42,
      -14.359,
      28.32,
      -7.42,
      -14.359,
      25.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "412.0",
    polygon: [
      -34.107,
      -40.551,
      4.86,
      -35.188,
      -40.61,
      4.86,
      -35.188,
      -40.61,
      7.32,
      -35.188,
      -40.61,
      7.32,
      -34.107,
      -40.551,
      7.32,
      -34.107,
      -40.551,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "102.0",
    polygon: [
      -59.627,
      -41.34,
      6.09,
      -60.536,
      -41.389,
      6.09,
      -60.536,
      -41.389,
      6.336,
      -60.536,
      -41.389,
      6.336,
      -59.627,
      -41.34,
      6.336,
      -59.627,
      -41.34,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "692.0",
    polygon: [
      -16.816,
      -39.034,
      4.569,
      -17.725,
      -39.081,
      4.569,
      -17.725,
      -39.081,
      4.815,
      -17.725,
      -39.081,
      4.815,
      -16.816,
      -39.034,
      4.815,
      -16.816,
      -39.034,
      4.569,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "777.0",
    polygon: [
      -54.934,
      -41.079,
      0.05,
      -55.842,
      -41.128,
      0.05,
      -55.842,
      -41.128,
      2.78,
      -55.842,
      -41.128,
      2.78,
      -54.934,
      -41.079,
      2.78,
      -54.934,
      -41.079,
      0.05,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1690.0",
    polygon: [
      -34.492,
      -7.155,
      39.128,
      -35.692,
      -7.155,
      39.128,
      -35.692,
      -7.155,
      40.05,
      -35.692,
      -7.155,
      40.05,
      -34.492,
      -7.155,
      40.05,
      -34.492,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "734.0",
    polygon: [
      -13.946,
      -38.883,
      6.09,
      -14.854,
      -38.931,
      6.09,
      -14.854,
      -38.931,
      6.336,
      -14.854,
      -38.931,
      6.336,
      -13.946,
      -38.883,
      6.336,
      -13.946,
      -38.883,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "975.0",
    polygon: [
      -57.812,
      -41.216,
      11.96,
      -59.629,
      -41.315,
      11.96,
      -59.629,
      -41.315,
      12.86,
      -59.629,
      -41.315,
      12.86,
      -57.812,
      -41.216,
      12.86,
      -57.812,
      -41.216,
      11.96,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1266.0",
    polygon: [
      2.566,
      -28.315,
      11.86,
      2.566,
      -29.646,
      11.86,
      2.566,
      -29.646,
      14.32,
      2.566,
      -29.646,
      14.32,
      2.566,
      -28.315,
      14.32,
      2.566,
      -28.315,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "488.0",
    polygon: [
      -6.904,
      -39.074,
      15.36,
      -7.966,
      -39.13,
      15.36,
      -7.966,
      -39.13,
      17.82,
      -7.966,
      -39.13,
      17.82,
      -6.904,
      -39.074,
      17.82,
      -6.904,
      -39.074,
      15.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "275.0",
    polygon: [
      -49.322,
      -40.777,
      5.844,
      -50.231,
      -40.826,
      5.844,
      -50.231,
      -40.826,
      6.09,
      -50.231,
      -40.826,
      6.09,
      -49.322,
      -40.777,
      6.09,
      -49.322,
      -40.777,
      5.844,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1092.0",
    polygon: [
      -16.807,
      -39.007,
      11.96,
      -18.623,
      -39.106,
      11.96,
      -18.623,
      -39.106,
      12.86,
      -18.623,
      -39.106,
      12.86,
      -16.807,
      -39.007,
      12.86,
      -16.807,
      -39.007,
      11.96,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1094.0",
    polygon: [
      -21.509,
      -39.263,
      11.96,
      -23.325,
      -39.362,
      11.96,
      -23.325,
      -39.362,
      12.86,
      -23.325,
      -39.362,
      12.86,
      -21.509,
      -39.263,
      12.86,
      -21.509,
      -39.263,
      11.96,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1356.0",
    polygon: [
      18.649,
      -10.223,
      36.36,
      17.464,
      -10.415,
      36.36,
      17.464,
      -10.415,
      39.128,
      17.464,
      -10.415,
      39.128,
      18.649,
      -10.223,
      39.128,
      18.649,
      -10.223,
      36.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1268.0",
    polygon: [
      2.566,
      -25.654,
      11.86,
      2.566,
      -26.985,
      11.86,
      2.566,
      -26.985,
      14.32,
      2.566,
      -26.985,
      14.32,
      2.566,
      -25.654,
      14.32,
      2.566,
      -25.654,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "411.0",
    polygon: [
      -38.513,
      -40.791,
      4.86,
      -39.575,
      -40.849,
      4.86,
      -39.575,
      -40.849,
      7.32,
      -39.575,
      -40.849,
      7.32,
      -38.513,
      -40.791,
      7.32,
      -38.513,
      -40.791,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1397.0",
    polygon: [
      4.163,
      -12.59,
      29.36,
      2.846,
      -12.808,
      29.36,
      2.846,
      -12.808,
      31.82,
      2.846,
      -12.808,
      31.82,
      4.163,
      -12.59,
      31.82,
      4.163,
      -12.59,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "450.0",
    polygon: [
      -36.107,
      -40.656,
      12.68,
      -37.507,
      -40.732,
      12.68,
      -37.507,
      -40.732,
      13.5,
      -37.507,
      -40.732,
      13.5,
      -36.107,
      -40.656,
      13.5,
      -36.107,
      -40.656,
      12.68,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "735.0",
    polygon: [
      -13.037,
      -38.836,
      6.09,
      -13.946,
      -38.883,
      6.09,
      -13.946,
      -38.883,
      6.336,
      -13.946,
      -38.883,
      6.336,
      -13.037,
      -38.836,
      6.336,
      -13.037,
      -38.836,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "449.0",
    polygon: [
      -36.107,
      -40.656,
      11.86,
      -37.507,
      -40.732,
      11.86,
      -37.507,
      -40.732,
      12.68,
      -37.507,
      -40.732,
      12.68,
      -36.107,
      -40.656,
      12.68,
      -36.107,
      -40.656,
      11.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "59.0",
    polygon: [
      -72.247,
      -42.611,
      11.86,
      -73.309,
      -42.668,
      11.86,
      -73.309,
      -42.668,
      14.32,
      -73.309,
      -42.668,
      14.32,
      -72.247,
      -42.611,
      14.32,
      -72.247,
      -42.611,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1054.0",
    polygon: [
      -28.019,
      -39.617,
      14.66,
      -29.836,
      -39.716,
      14.66,
      -29.836,
      -39.716,
      15.56,
      -29.836,
      -39.716,
      15.56,
      -28.019,
      -39.617,
      15.56,
      -28.019,
      -39.617,
      14.66,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1267.0",
    polygon: [
      2.566,
      -26.985,
      11.86,
      2.566,
      -28.315,
      11.86,
      2.566,
      -28.315,
      14.32,
      2.566,
      -28.315,
      14.32,
      2.566,
      -26.985,
      14.32,
      2.566,
      -26.985,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "529.0",
    polygon: [
      -28.912,
      -39.668,
      5.352,
      -29.821,
      -39.715,
      5.352,
      -29.821,
      -39.715,
      5.598,
      -29.821,
      -39.715,
      5.598,
      -28.912,
      -39.668,
      5.598,
      -28.912,
      -39.668,
      5.352,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "1053.0",
    polygon: [
      -28.019,
      -39.617,
      13.76,
      -29.836,
      -39.716,
      13.76,
      -29.836,
      -39.716,
      14.66,
      -29.836,
      -39.716,
      14.66,
      -28.019,
      -39.617,
      14.66,
      -28.019,
      -39.617,
      13.76,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1650.0",
    polygon: [
      2.406,
      -12.861,
      39.128,
      1.221,
      -13.054,
      39.128,
      1.221,
      -13.054,
      40.05,
      1.221,
      -13.054,
      40.05,
      2.406,
      -12.861,
      40.05,
      2.406,
      -12.861,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1093.0",
    polygon: [
      -18.623,
      -39.106,
      11.96,
      -20.44,
      -39.205,
      11.96,
      -20.44,
      -39.205,
      12.86,
      -20.44,
      -39.205,
      12.86,
      -18.623,
      -39.106,
      12.86,
      -18.623,
      -39.106,
      11.96,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1692.0",
    polygon: [
      -33.131,
      -6.955,
      39.128,
      -34.331,
      -6.955,
      39.128,
      -34.331,
      -6.955,
      40.05,
      -34.331,
      -6.955,
      40.05,
      -33.131,
      -6.955,
      40.05,
      -33.131,
      -6.955,
      39.128,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1171.0",
    polygon: [
      3.979,
      -12.504,
      11.86,
      2.827,
      -12.695,
      11.86,
      2.827,
      -12.695,
      14.32,
      2.827,
      -12.695,
      14.32,
      3.979,
      -12.504,
      14.32,
      3.979,
      -12.504,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "60.0",
    polygon: [
      -71.184,
      -42.554,
      11.86,
      -72.247,
      -42.611,
      11.86,
      -72.247,
      -42.611,
      14.32,
      -72.247,
      -42.611,
      14.32,
      -71.184,
      -42.554,
      14.32,
      -71.184,
      -42.554,
      11.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1649.0",
    polygon: [
      2.406,
      -12.861,
      36.36,
      1.221,
      -13.054,
      36.36,
      1.221,
      -13.054,
      39.128,
      1.221,
      -13.054,
      39.128,
      2.406,
      -12.861,
      39.128,
      2.406,
      -12.861,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1016.0",
    polygon: [
      -43.728,
      -40.449,
      16.46,
      -45.545,
      -40.548,
      16.46,
      -45.545,
      -40.548,
      17.36,
      -45.545,
      -40.548,
      17.36,
      -43.728,
      -40.449,
      17.36,
      -43.728,
      -40.449,
      16.46,
    ],
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1360.0",
    polygon: [
      18.642,
      -10.188,
      22.36,
      17.326,
      -10.406,
      22.36,
      17.326,
      -10.406,
      24.82,
      17.326,
      -10.406,
      24.82,
      18.642,
      -10.188,
      24.82,
      18.642,
      -10.188,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1133.0",
    polygon: [
      -18.623,
      -39.106,
      15.56,
      -20.44,
      -39.205,
      15.56,
      -20.44,
      -39.205,
      16.46,
      -20.44,
      -39.205,
      16.46,
      -18.623,
      -39.106,
      16.46,
      -18.623,
      -39.106,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "653.0",
    polygon: [
      -19.512,
      -39.175,
      5.106,
      -20.421,
      -39.223,
      5.106,
      -20.421,
      -39.223,
      5.352,
      -20.421,
      -39.223,
      5.352,
      -19.512,
      -39.175,
      5.352,
      -19.512,
      -39.175,
      5.106,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "195.0",
    polygon: [
      -55.842,
      -41.128,
      0.05,
      -56.75,
      -41.178,
      0.05,
      -56.75,
      -41.178,
      2.78,
      -56.75,
      -41.178,
      2.78,
      -55.842,
      -41.128,
      2.78,
      -55.842,
      -41.128,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "1529.0",
    polygon: [
      -10.123,
      -14.807,
      25.86,
      -11.274,
      -14.998,
      25.86,
      -11.274,
      -14.998,
      28.32,
      -11.274,
      -14.998,
      28.32,
      -10.123,
      -14.807,
      28.32,
      -10.123,
      -14.807,
      25.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "528.0",
    polygon: [
      -26.216,
      -39.526,
      5.106,
      -27.125,
      -39.574,
      5.106,
      -27.125,
      -39.574,
      5.352,
      -27.125,
      -39.574,
      5.352,
      -26.216,
      -39.526,
      5.352,
      -26.216,
      -39.526,
      5.106,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "103.0",
    polygon: [
      -58.718,
      -41.29,
      6.09,
      -59.627,
      -41.34,
      6.09,
      -59.627,
      -41.34,
      6.336,
      -59.627,
      -41.34,
      6.336,
      -58.718,
      -41.29,
      6.336,
      -58.718,
      -41.29,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "319.0",
    polygon: [
      -45.543,
      -40.567,
      0.05,
      -47.359,
      -40.666,
      0.05,
      -47.359,
      -40.666,
      2.78,
      -47.359,
      -40.666,
      2.78,
      -45.543,
      -40.567,
      2.78,
      -45.543,
      -40.567,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "654.0",
    polygon: [
      -18.618,
      -39.128,
      5.106,
      -19.527,
      -39.176,
      5.106,
      -19.527,
      -39.176,
      5.352,
      -19.527,
      -39.176,
      5.352,
      -18.618,
      -39.128,
      5.352,
      -18.618,
      -39.128,
      5.106,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "736.0",
    polygon: [
      -12.143,
      -38.789,
      6.09,
      -13.052,
      -38.836,
      6.09,
      -13.052,
      -38.836,
      6.336,
      -13.052,
      -38.836,
      6.336,
      -12.143,
      -38.789,
      6.336,
      -12.143,
      -38.789,
      6.09,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "1530.0",
    polygon: [
      -0.12,
      -13.148,
      29.36,
      -1.272,
      -13.339,
      29.36,
      -1.272,
      -13.339,
      31.82,
      -1.272,
      -13.339,
      31.82,
      -0.12,
      -13.148,
      31.82,
      -0.12,
      -13.148,
      29.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1651.0",
    polygon: [
      3.759,
      -12.641,
      36.36,
      2.575,
      -12.834,
      36.36,
      2.575,
      -12.834,
      39.128,
      2.575,
      -12.834,
      39.128,
      3.759,
      -12.641,
      39.128,
      3.759,
      -12.641,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "277.0",
    polygon: [
      -51.125,
      -40.875,
      6.09,
      -52.033,
      -40.924,
      6.09,
      -52.033,
      -40.924,
      6.336,
      -52.033,
      -40.924,
      6.336,
      -51.125,
      -40.875,
      6.336,
      -51.125,
      -40.875,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1399.0",
    polygon: [
      18.642,
      -10.188,
      32.86,
      17.326,
      -10.406,
      32.86,
      17.326,
      -10.406,
      35.32,
      17.326,
      -10.406,
      35.32,
      18.642,
      -10.188,
      35.32,
      18.642,
      -10.188,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "976.0",
    polygon: [
      -43.728,
      -40.449,
      12.86,
      -45.545,
      -40.548,
      12.86,
      -45.545,
      -40.548,
      13.76,
      -45.545,
      -40.548,
      13.76,
      -43.728,
      -40.449,
      13.76,
      -43.728,
      -40.449,
      12.86,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "61.0",
    polygon: [
      -70.122,
      -42.498,
      11.86,
      -71.184,
      -42.554,
      11.86,
      -71.184,
      -42.554,
      14.32,
      -71.184,
      -42.554,
      14.32,
      -70.122,
      -42.498,
      14.32,
      -70.122,
      -42.498,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1400.0",
    polygon: [
      17.326,
      -10.406,
      32.86,
      16.01,
      -10.625,
      32.86,
      16.01,
      -10.625,
      35.32,
      16.01,
      -10.625,
      35.32,
      17.326,
      -10.406,
      35.32,
      17.326,
      -10.406,
      32.86,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "148.0",
    polygon: [
      -51.14,
      -40.872,
      0.05,
      -52.048,
      -40.922,
      0.05,
      -52.048,
      -40.922,
      2.78,
      -52.048,
      -40.922,
      2.78,
      -51.14,
      -40.872,
      2.78,
      -51.14,
      -40.872,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1691.0",
    polygon: [
      -33.131,
      -6.955,
      36.36,
      -34.331,
      -6.955,
      36.36,
      -34.331,
      -6.955,
      39.128,
      -34.331,
      -6.955,
      39.128,
      -33.131,
      -6.955,
      39.128,
      -33.131,
      -6.955,
      36.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1055.0",
    polygon: [
      -28.019,
      -39.617,
      15.56,
      -29.836,
      -39.716,
      15.56,
      -29.836,
      -39.716,
      16.46,
      -29.836,
      -39.716,
      16.46,
      -28.019,
      -39.617,
      16.46,
      -28.019,
      -39.617,
      15.56,
    ],
    "04-15 06:00": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "490.0",
    polygon: [
      -1.416,
      -38.786,
      15.36,
      -2.498,
      -38.843,
      15.36,
      -2.498,
      -38.843,
      17.82,
      -2.498,
      -38.843,
      17.82,
      -1.416,
      -38.786,
      17.82,
      -1.416,
      -38.786,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "321.0",
    polygon: [
      -46.437,
      -40.619,
      4.86,
      -47.345,
      -40.669,
      4.86,
      -47.345,
      -40.669,
      5.106,
      -47.345,
      -40.669,
      5.106,
      -46.437,
      -40.619,
      5.106,
      -46.437,
      -40.619,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "276.0",
    polygon: [
      -48.429,
      -40.728,
      5.844,
      -49.337,
      -40.778,
      5.844,
      -49.337,
      -40.778,
      6.09,
      -49.337,
      -40.778,
      6.09,
      -48.429,
      -40.728,
      6.09,
      -48.429,
      -40.728,
      5.844,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "938.0",
    polygon: [
      -45.545,
      -40.548,
      9.26,
      -47.361,
      -40.647,
      9.26,
      -47.361,
      -40.647,
      10.16,
      -47.361,
      -40.647,
      10.16,
      -45.545,
      -40.548,
      10.16,
      -45.545,
      -40.548,
      9.26,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1056.0",
    polygon: [
      -28.019,
      -39.617,
      16.46,
      -29.836,
      -39.716,
      16.46,
      -29.836,
      -39.716,
      17.36,
      -29.836,
      -39.716,
      17.36,
      -28.019,
      -39.617,
      17.36,
      -28.019,
      -39.617,
      16.46,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "413.0",
    polygon: [
      -33.026,
      -40.492,
      4.86,
      -34.108,
      -40.551,
      4.86,
      -34.108,
      -40.551,
      7.32,
      -34.108,
      -40.551,
      7.32,
      -33.026,
      -40.492,
      7.32,
      -33.026,
      -40.492,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "489.0",
    polygon: [
      -2.497,
      -38.843,
      15.36,
      -3.578,
      -38.9,
      15.36,
      -3.578,
      -38.9,
      17.82,
      -3.578,
      -38.9,
      17.82,
      -2.497,
      -38.843,
      17.82,
      -2.497,
      -38.843,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "1134.0",
    polygon: [
      -21.509,
      -39.263,
      15.56,
      -23.325,
      -39.362,
      15.56,
      -23.325,
      -39.362,
      16.46,
      -23.325,
      -39.362,
      16.46,
      -21.509,
      -39.263,
      16.46,
      -21.509,
      -39.263,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "977.0",
    polygon: [
      -47.379,
      -40.648,
      12.86,
      -48.397,
      -40.703,
      12.86,
      -48.397,
      -40.703,
      13.76,
      -48.397,
      -40.703,
      13.76,
      -47.379,
      -40.648,
      13.76,
      -47.379,
      -40.648,
      12.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "530.0",
    polygon: [
      -28.018,
      -39.621,
      5.352,
      -28.927,
      -39.668,
      5.352,
      -28.927,
      -39.668,
      5.598,
      -28.927,
      -39.668,
      5.598,
      -28.018,
      -39.621,
      5.598,
      -28.018,
      -39.621,
      5.352,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1173.0",
    polygon: [
      9.376,
      -11.608,
      15.36,
      8.224,
      -11.8,
      15.36,
      8.224,
      -11.8,
      17.82,
      8.224,
      -11.8,
      17.82,
      9.376,
      -11.608,
      17.82,
      9.376,
      -11.608,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "936.0",
    polygon: [
      -43.728,
      -40.449,
      9.26,
      -45.545,
      -40.548,
      9.26,
      -45.545,
      -40.548,
      10.16,
      -45.545,
      -40.548,
      10.16,
      -43.728,
      -40.449,
      10.16,
      -43.728,
      -40.449,
      9.26,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "104.0",
    polygon: [
      -57.825,
      -41.242,
      6.09,
      -58.733,
      -41.291,
      6.09,
      -58.733,
      -41.291,
      6.336,
      -58.733,
      -41.291,
      6.336,
      -57.825,
      -41.242,
      6.336,
      -57.825,
      -41.242,
      6.09,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "491.0",
    polygon: [
      -0.335,
      -38.73,
      15.36,
      -1.416,
      -38.786,
      15.36,
      -1.416,
      -38.786,
      17.82,
      -1.416,
      -38.786,
      17.82,
      -0.335,
      -38.73,
      17.82,
      -0.335,
      -38.73,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "569.0",
    polygon: [
      -28.912,
      -39.668,
      4.077,
      -29.821,
      -39.715,
      4.077,
      -29.821,
      -39.715,
      4.323,
      -29.821,
      -39.715,
      4.323,
      -28.912,
      -39.668,
      4.323,
      -28.912,
      -39.668,
      4.077,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "978.0",
    polygon: [
      -45.545,
      -40.548,
      12.86,
      -47.361,
      -40.647,
      12.86,
      -47.361,
      -40.647,
      13.76,
      -47.361,
      -40.647,
      13.76,
      -45.545,
      -40.548,
      13.76,
      -45.545,
      -40.548,
      12.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "939.0",
    polygon: [
      -52.074,
      -40.904,
      9.26,
      -53.092,
      -40.959,
      9.26,
      -53.092,
      -40.959,
      10.16,
      -53.092,
      -40.959,
      10.16,
      -52.074,
      -40.904,
      10.16,
      -52.074,
      -40.904,
      9.26,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1693.0",
    polygon: [
      -31.769,
      -7.155,
      36.36,
      -32.969,
      -7.155,
      36.36,
      -32.969,
      -7.155,
      39.128,
      -32.969,
      -7.155,
      39.128,
      -31.769,
      -7.155,
      39.128,
      -31.769,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1174.0",
    polygon: [
      12.075,
      -11.161,
      15.36,
      10.923,
      -11.352,
      15.36,
      10.923,
      -11.352,
      17.82,
      10.923,
      -11.352,
      17.82,
      12.075,
      -11.161,
      17.82,
      12.075,
      -11.161,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "937.0",
    polygon: [
      -47.379,
      -40.648,
      9.26,
      -48.397,
      -40.703,
      9.26,
      -48.397,
      -40.703,
      10.16,
      -48.397,
      -40.703,
      10.16,
      -47.379,
      -40.648,
      10.16,
      -47.379,
      -40.648,
      9.26,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1095.0",
    polygon: [
      -23.325,
      -39.362,
      11.96,
      -25.142,
      -39.46,
      11.96,
      -25.142,
      -39.46,
      12.86,
      -25.142,
      -39.46,
      12.86,
      -23.325,
      -39.362,
      12.86,
      -23.325,
      -39.362,
      11.96,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1361.0",
    polygon: [
      17.326,
      -10.406,
      22.36,
      16.01,
      -10.625,
      22.36,
      16.01,
      -10.625,
      24.82,
      16.01,
      -10.625,
      24.82,
      17.326,
      -10.406,
      24.82,
      17.326,
      -10.406,
      22.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1172.0",
    polygon: [
      6.678,
      -12.056,
      15.36,
      5.526,
      -12.247,
      15.36,
      5.526,
      -12.247,
      17.82,
      5.526,
      -12.247,
      17.82,
      6.678,
      -12.056,
      17.82,
      6.678,
      -12.056,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1227.0",
    polygon: [
      2.566,
      -34.968,
      4.86,
      2.566,
      -36.298,
      4.86,
      2.566,
      -36.298,
      7.32,
      2.566,
      -36.298,
      7.32,
      2.566,
      -34.968,
      7.32,
      2.566,
      -34.968,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1226.0",
    polygon: [
      2.562,
      -36.298,
      2.78,
      1.762,
      -36.298,
      2.78,
      1.762,
      -36.298,
      3.82,
      1.762,
      -36.298,
      3.82,
      2.562,
      -36.298,
      3.82,
      2.562,
      -36.298,
      2.78,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "452.0",
    polygon: [
      -41.701,
      -40.961,
      11.86,
      -42.763,
      -41.018,
      11.86,
      -42.763,
      -41.018,
      14.32,
      -42.763,
      -41.018,
      14.32,
      -41.701,
      -40.961,
      14.32,
      -41.701,
      -40.961,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "655.0",
    polygon: [
      -17.71,
      -39.081,
      5.106,
      -18.618,
      -39.128,
      5.106,
      -18.618,
      -39.128,
      5.352,
      -18.618,
      -39.128,
      5.352,
      -17.71,
      -39.081,
      5.352,
      -17.71,
      -39.081,
      5.106,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1015.0",
    polygon: [
      -57.812,
      -41.216,
      15.56,
      -59.629,
      -41.315,
      15.56,
      -59.629,
      -41.315,
      16.46,
      -59.629,
      -41.315,
      16.46,
      -57.812,
      -41.216,
      16.46,
      -57.812,
      -41.216,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1017.0",
    polygon: [
      -47.379,
      -40.648,
      16.46,
      -48.397,
      -40.703,
      16.46,
      -48.397,
      -40.703,
      17.36,
      -48.397,
      -40.703,
      17.36,
      -47.379,
      -40.648,
      17.36,
      -47.379,
      -40.648,
      16.46,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "779.0",
    polygon: [
      -50.232,
      -40.823,
      0.05,
      -51.14,
      -40.872,
      0.05,
      -51.14,
      -40.872,
      2.78,
      -51.14,
      -40.872,
      2.78,
      -50.232,
      -40.823,
      2.78,
      -50.232,
      -40.823,
      0.05,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "778.0",
    polygon: [
      -53.117,
      -40.98,
      0.05,
      -54.026,
      -41.029,
      0.05,
      -54.026,
      -41.029,
      2.78,
      -54.026,
      -41.029,
      2.78,
      -53.117,
      -40.98,
      2.78,
      -53.117,
      -40.98,
      0.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "451.0",
    polygon: [
      -36.107,
      -40.656,
      13.5,
      -37.507,
      -40.732,
      13.5,
      -37.507,
      -40.732,
      14.32,
      -37.507,
      -40.732,
      14.32,
      -36.107,
      -40.656,
      14.32,
      -36.107,
      -40.656,
      13.5,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "414.0",
    polygon: [
      -31.945,
      -40.434,
      4.86,
      -33.026,
      -40.492,
      4.86,
      -33.026,
      -40.492,
      7.32,
      -33.026,
      -40.492,
      7.32,
      -31.945,
      -40.434,
      7.32,
      -31.945,
      -40.434,
      4.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1531.0",
    polygon: [
      1.031,
      -12.957,
      29.36,
      -0.12,
      -13.148,
      29.36,
      -0.12,
      -13.148,
      31.82,
      -0.12,
      -13.148,
      31.82,
      1.031,
      -12.957,
      31.82,
      1.031,
      -12.957,
      29.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "656.0",
    polygon: [
      -16.816,
      -39.034,
      5.106,
      -17.725,
      -39.081,
      5.106,
      -17.725,
      -39.081,
      5.352,
      -17.725,
      -39.081,
      5.352,
      -16.816,
      -39.034,
      5.352,
      -16.816,
      -39.034,
      5.106,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "361.0",
    polygon: [
      -46.442,
      -40.62,
      4.569,
      -47.35,
      -40.669,
      4.569,
      -47.35,
      -40.669,
      4.815,
      -47.35,
      -40.669,
      4.815,
      -46.442,
      -40.62,
      4.815,
      -46.442,
      -40.62,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "1018.0",
    polygon: [
      -45.545,
      -40.548,
      16.46,
      -47.361,
      -40.647,
      16.46,
      -47.361,
      -40.647,
      17.36,
      -47.361,
      -40.647,
      17.36,
      -45.545,
      -40.548,
      17.36,
      -45.545,
      -40.548,
      16.46,
    ],
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "149.0",
    polygon: [
      -49.323,
      -40.773,
      0.05,
      -50.232,
      -40.823,
      0.05,
      -50.232,
      -40.823,
      2.78,
      -50.232,
      -40.823,
      2.78,
      -49.323,
      -40.773,
      2.78,
      -49.323,
      -40.773,
      0.05,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "322.0",
    polygon: [
      -45.543,
      -40.571,
      4.86,
      -46.452,
      -40.62,
      4.86,
      -46.452,
      -40.62,
      5.106,
      -46.452,
      -40.62,
      5.106,
      -45.543,
      -40.571,
      5.106,
      -45.543,
      -40.571,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "62.0",
    polygon: [
      -65.715,
      -42.262,
      11.86,
      -66.797,
      -42.32,
      11.86,
      -66.797,
      -42.32,
      14.32,
      -66.797,
      -42.32,
      14.32,
      -65.715,
      -42.262,
      14.32,
      -65.715,
      -42.262,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1135.0",
    polygon: [
      -23.325,
      -39.362,
      15.56,
      -25.142,
      -39.46,
      15.56,
      -25.142,
      -39.46,
      16.46,
      -25.142,
      -39.46,
      16.46,
      -23.325,
      -39.362,
      16.46,
      -23.325,
      -39.362,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "362.0",
    polygon: [
      -45.548,
      -40.571,
      4.569,
      -46.457,
      -40.621,
      4.569,
      -46.457,
      -40.621,
      4.815,
      -46.457,
      -40.621,
      4.815,
      -45.548,
      -40.571,
      4.815,
      -45.548,
      -40.571,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "1057.0",
    polygon: [
      -12.119,
      -38.752,
      9.26,
      -13.935,
      -38.851,
      9.26,
      -13.935,
      -38.851,
      10.16,
      -13.935,
      -38.851,
      10.16,
      -12.119,
      -38.752,
      10.16,
      -12.119,
      -38.752,
      9.26,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "940.0",
    polygon: [
      -56.772,
      -41.16,
      9.26,
      -57.791,
      -41.215,
      9.26,
      -57.791,
      -41.215,
      10.16,
      -57.791,
      -41.215,
      10.16,
      -56.772,
      -41.16,
      10.16,
      -56.772,
      -41.16,
      9.26,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "570.0",
    polygon: [
      -28.018,
      -39.621,
      4.077,
      -28.927,
      -39.668,
      4.077,
      -28.927,
      -39.668,
      4.323,
      -28.927,
      -39.668,
      4.323,
      -28.018,
      -39.621,
      4.323,
      -28.018,
      -39.621,
      4.077,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1175.0",
    polygon: [
      14.773,
      -10.713,
      15.36,
      13.621,
      -10.904,
      15.36,
      13.621,
      -10.904,
      17.82,
      13.621,
      -10.904,
      17.82,
      14.773,
      -10.713,
      17.82,
      14.773,
      -10.713,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "531.0",
    polygon: [
      -27.11,
      -39.573,
      5.352,
      -28.018,
      -39.621,
      5.352,
      -28.018,
      -39.621,
      5.598,
      -28.018,
      -39.621,
      5.598,
      -27.11,
      -39.573,
      5.598,
      -27.11,
      -39.573,
      5.352,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1652.0",
    polygon: [
      3.759,
      -12.641,
      39.128,
      2.575,
      -12.834,
      39.128,
      2.575,
      -12.834,
      40.05,
      2.575,
      -12.834,
      40.05,
      3.759,
      -12.641,
      40.05,
      3.759,
      -12.641,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1362.0",
    polygon: [
      16.01,
      -10.625,
      22.36,
      14.693,
      -10.843,
      22.36,
      14.693,
      -10.843,
      24.82,
      14.693,
      -10.843,
      24.82,
      16.01,
      -10.625,
      24.82,
      16.01,
      -10.625,
      22.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "493.0",
    polygon: [
      -36.074,
      -40.746,
      0.05,
      -37.474,
      -40.822,
      0.05,
      -37.474,
      -40.822,
      2.78,
      -37.474,
      -40.822,
      2.78,
      -36.074,
      -40.746,
      2.78,
      -36.074,
      -40.746,
      0.05,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1096.0",
    polygon: [
      -26.203,
      -39.518,
      11.96,
      -28.019,
      -39.617,
      11.96,
      -28.019,
      -39.617,
      12.86,
      -28.019,
      -39.617,
      12.86,
      -26.203,
      -39.518,
      12.86,
      -26.203,
      -39.518,
      11.96,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1401.0",
    polygon: [
      16.01,
      -10.625,
      32.86,
      14.693,
      -10.843,
      32.86,
      14.693,
      -10.843,
      35.32,
      14.693,
      -10.843,
      35.32,
      16.01,
      -10.625,
      35.32,
      16.01,
      -10.625,
      32.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "453.0",
    polygon: [
      -40.638,
      -40.903,
      11.86,
      -41.701,
      -40.961,
      11.86,
      -41.701,
      -40.961,
      14.32,
      -41.701,
      -40.961,
      14.32,
      -40.638,
      -40.903,
      14.32,
      -40.638,
      -40.903,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1402.0",
    polygon: [
      14.693,
      -10.843,
      32.86,
      13.377,
      -11.061,
      32.86,
      13.377,
      -11.061,
      35.32,
      13.377,
      -11.061,
      35.32,
      14.693,
      -10.843,
      35.32,
      14.693,
      -10.843,
      32.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "532.0",
    polygon: [
      -26.216,
      -39.526,
      5.352,
      -27.125,
      -39.574,
      5.352,
      -27.125,
      -39.574,
      5.598,
      -27.125,
      -39.574,
      5.598,
      -26.216,
      -39.526,
      5.598,
      -26.216,
      -39.526,
      5.352,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "571.0",
    polygon: [
      -27.11,
      -39.573,
      4.077,
      -28.018,
      -39.621,
      4.077,
      -28.018,
      -39.621,
      4.323,
      -28.018,
      -39.621,
      4.323,
      -27.11,
      -39.573,
      4.323,
      -27.11,
      -39.573,
      4.077,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "941.0",
    polygon: [
      -48.416,
      -40.704,
      9.26,
      -50.233,
      -40.803,
      9.26,
      -50.233,
      -40.803,
      10.16,
      -50.233,
      -40.803,
      10.16,
      -48.416,
      -40.704,
      10.16,
      -48.416,
      -40.704,
      9.26,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1176.0",
    polygon: [
      17.472,
      -10.266,
      15.36,
      16.32,
      -10.457,
      15.36,
      16.32,
      -10.457,
      17.82,
      16.32,
      -10.457,
      17.82,
      17.472,
      -10.266,
      17.82,
      17.472,
      -10.266,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1363.0",
    polygon: [
      14.693,
      -10.843,
      22.36,
      13.377,
      -11.061,
      22.36,
      13.377,
      -11.061,
      24.82,
      13.377,
      -11.061,
      24.82,
      14.693,
      -10.843,
      24.82,
      14.693,
      -10.843,
      22.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1653.0",
    polygon: [
      5.113,
      -12.421,
      36.36,
      3.929,
      -12.614,
      36.36,
      3.929,
      -12.614,
      39.128,
      3.929,
      -12.614,
      39.128,
      5.113,
      -12.421,
      39.128,
      5.113,
      -12.421,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "416.0",
    polygon: [
      -4.497,
      -38.948,
      4.86,
      -5.897,
      -39.021,
      4.86,
      -5.897,
      -39.021,
      5.68,
      -5.897,
      -39.021,
      5.68,
      -4.497,
      -38.948,
      5.68,
      -4.497,
      -38.948,
      4.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1098.0",
    polygon: [
      -15.769,
      -38.951,
      12.86,
      -16.788,
      -39.006,
      12.86,
      -16.788,
      -39.006,
      13.76,
      -16.788,
      -39.006,
      13.76,
      -15.769,
      -38.951,
      13.76,
      -15.769,
      -38.951,
      12.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1271.0",
    polygon: [
      2.566,
      -21.663,
      11.86,
      2.566,
      -22.993,
      11.86,
      2.566,
      -22.993,
      14.32,
      2.566,
      -22.993,
      14.32,
      2.566,
      -21.663,
      14.32,
      2.566,
      -21.663,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1229.0",
    polygon: [
      2.566,
      -33.637,
      4.86,
      2.566,
      -34.968,
      4.86,
      2.566,
      -34.968,
      7.32,
      2.566,
      -34.968,
      7.32,
      2.566,
      -33.637,
      7.32,
      2.566,
      -33.637,
      4.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "415.0",
    polygon: [
      -30.863,
      -40.375,
      4.86,
      -31.945,
      -40.434,
      4.86,
      -31.945,
      -40.434,
      7.32,
      -31.945,
      -40.434,
      7.32,
      -30.863,
      -40.375,
      7.32,
      -30.863,
      -40.375,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "279.0",
    polygon: [
      -49.322,
      -40.777,
      6.09,
      -50.231,
      -40.826,
      6.09,
      -50.231,
      -40.826,
      6.336,
      -50.231,
      -40.826,
      6.336,
      -49.322,
      -40.777,
      6.336,
      -49.322,
      -40.777,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "324.0",
    polygon: [
      -43.741,
      -40.473,
      4.86,
      -44.649,
      -40.522,
      4.86,
      -44.649,
      -40.522,
      5.106,
      -44.649,
      -40.522,
      5.106,
      -43.741,
      -40.473,
      5.106,
      -43.741,
      -40.473,
      4.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1403.0",
    polygon: [
      13.377,
      -11.061,
      32.86,
      12.061,
      -11.28,
      32.86,
      12.061,
      -11.28,
      35.32,
      12.061,
      -11.28,
      35.32,
      13.377,
      -11.061,
      35.32,
      13.377,
      -11.061,
      32.86,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1231.0",
    polygon: [
      2.566,
      -32.307,
      4.86,
      2.566,
      -33.637,
      4.86,
      2.566,
      -33.637,
      7.32,
      2.566,
      -33.637,
      7.32,
      2.566,
      -32.307,
      7.32,
      2.566,
      -32.307,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "780.0",
    polygon: [
      -48.415,
      -40.724,
      0.05,
      -49.323,
      -40.773,
      0.05,
      -49.323,
      -40.773,
      2.78,
      -49.323,
      -40.773,
      2.78,
      -48.415,
      -40.724,
      2.78,
      -48.415,
      -40.724,
      0.05,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "979.0",
    polygon: [
      -52.074,
      -40.904,
      12.86,
      -53.092,
      -40.959,
      12.86,
      -53.092,
      -40.959,
      13.76,
      -53.092,
      -40.959,
      13.76,
      -52.074,
      -40.904,
      13.76,
      -52.074,
      -40.904,
      12.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1136.0",
    polygon: [
      -26.203,
      -39.518,
      15.56,
      -28.019,
      -39.617,
      15.56,
      -28.019,
      -39.617,
      16.46,
      -28.019,
      -39.617,
      16.46,
      -26.203,
      -39.518,
      16.46,
      -26.203,
      -39.518,
      15.56,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "658.0",
    polygon: [
      -18.618,
      -39.128,
      5.352,
      -19.527,
      -39.176,
      5.352,
      -19.527,
      -39.176,
      5.598,
      -19.527,
      -39.176,
      5.598,
      -18.618,
      -39.128,
      5.598,
      -18.618,
      -39.128,
      5.352,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "64.0",
    polygon: [
      -63.553,
      -42.147,
      11.86,
      -64.635,
      -42.205,
      11.86,
      -64.635,
      -42.205,
      14.32,
      -64.635,
      -42.205,
      14.32,
      -63.553,
      -42.147,
      14.32,
      -63.553,
      -42.147,
      11.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1059.0",
    polygon: [
      -13.935,
      -38.851,
      9.26,
      -15.751,
      -38.95,
      9.26,
      -15.751,
      -38.95,
      10.16,
      -15.751,
      -38.95,
      10.16,
      -13.935,
      -38.851,
      10.16,
      -13.935,
      -38.851,
      9.26,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "198.0",
    polygon: [
      -54.933,
      -41.082,
      4.86,
      -55.842,
      -41.132,
      4.86,
      -55.842,
      -41.132,
      5.106,
      -55.842,
      -41.132,
      5.106,
      -54.933,
      -41.082,
      5.106,
      -54.933,
      -41.082,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "492.0",
    polygon: [
      0.747,
      -38.673,
      15.36,
      -0.335,
      -38.73,
      15.36,
      -0.335,
      -38.73,
      17.82,
      -0.335,
      -38.73,
      17.82,
      0.747,
      -38.673,
      17.82,
      0.747,
      -38.673,
      15.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "323.0",
    polygon: [
      -44.634,
      -40.521,
      4.86,
      -45.543,
      -40.571,
      4.86,
      -45.543,
      -40.571,
      5.106,
      -45.543,
      -40.571,
      5.106,
      -44.634,
      -40.521,
      5.106,
      -44.634,
      -40.521,
      4.86,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1654.0",
    polygon: [
      5.113,
      -12.421,
      39.128,
      3.929,
      -12.614,
      39.128,
      3.929,
      -12.614,
      40.05,
      3.929,
      -12.614,
      40.05,
      5.113,
      -12.421,
      40.05,
      5.113,
      -12.421,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1694.0",
    polygon: [
      -31.769,
      -7.155,
      39.128,
      -32.969,
      -7.155,
      39.128,
      -32.969,
      -7.155,
      40.05,
      -32.969,
      -7.155,
      40.05,
      -31.769,
      -7.155,
      40.05,
      -31.769,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "278.0",
    polygon: [
      -50.231,
      -40.826,
      6.09,
      -51.14,
      -40.876,
      6.09,
      -51.14,
      -40.876,
      6.336,
      -51.14,
      -40.876,
      6.336,
      -50.231,
      -40.826,
      6.336,
      -50.231,
      -40.826,
      6.09,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1020.0",
    polygon: [
      -56.772,
      -41.16,
      16.46,
      -57.791,
      -41.215,
      16.46,
      -57.791,
      -41.215,
      17.36,
      -57.791,
      -41.215,
      17.36,
      -56.772,
      -41.16,
      17.36,
      -56.772,
      -41.16,
      16.46,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1232.0",
    polygon: [
      2.566,
      -30.976,
      4.86,
      2.566,
      -32.307,
      4.86,
      2.566,
      -32.307,
      7.32,
      2.566,
      -32.307,
      7.32,
      2.566,
      -30.976,
      7.32,
      2.566,
      -30.976,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "63.0",
    polygon: [
      -64.635,
      -42.205,
      11.86,
      -65.716,
      -42.262,
      11.86,
      -65.716,
      -42.262,
      14.32,
      -65.716,
      -42.262,
      14.32,
      -64.635,
      -42.205,
      14.32,
      -64.635,
      -42.205,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "781.0",
    polygon: [
      -23.372,
      -39.377,
      0.05,
      -24.281,
      -39.425,
      0.05,
      -24.281,
      -39.425,
      2.78,
      -24.281,
      -39.425,
      2.78,
      -23.372,
      -39.377,
      2.78,
      -23.372,
      -39.377,
      0.05,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "363.0",
    polygon: [
      -44.639,
      -40.522,
      4.569,
      -45.548,
      -40.571,
      4.569,
      -45.548,
      -40.571,
      4.815,
      -45.548,
      -40.571,
      4.815,
      -44.639,
      -40.522,
      4.815,
      -44.639,
      -40.522,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "572.0",
    polygon: [
      -26.216,
      -39.526,
      4.077,
      -27.125,
      -39.574,
      4.077,
      -27.125,
      -39.574,
      4.323,
      -27.125,
      -39.574,
      4.323,
      -26.216,
      -39.526,
      4.323,
      -26.216,
      -39.526,
      4.077,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "280.0",
    polygon: [
      -48.429,
      -40.728,
      6.09,
      -49.337,
      -40.778,
      6.09,
      -49.337,
      -40.778,
      6.336,
      -49.337,
      -40.778,
      6.336,
      -48.429,
      -40.728,
      6.336,
      -48.429,
      -40.728,
      6.09,
    ],
    "04-15 13:00": 1,
  },
  {
    ID: "980.0",
    polygon: [
      -56.772,
      -41.16,
      12.86,
      -57.791,
      -41.215,
      12.86,
      -57.791,
      -41.215,
      13.76,
      -57.791,
      -41.215,
      13.76,
      -56.772,
      -41.16,
      13.76,
      -56.772,
      -41.16,
      12.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "782.0",
    polygon: [
      -21.556,
      -39.282,
      0.05,
      -22.464,
      -39.33,
      0.05,
      -22.464,
      -39.33,
      2.78,
      -22.464,
      -39.33,
      2.78,
      -21.556,
      -39.282,
      2.78,
      -21.556,
      -39.282,
      0.05,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1695.0",
    polygon: [
      -68.276,
      -7.155,
      36.36,
      -69.098,
      -7.155,
      36.36,
      -69.098,
      -7.155,
      39.128,
      -69.098,
      -7.155,
      39.128,
      -68.276,
      -7.155,
      39.128,
      -68.276,
      -7.155,
      36.36,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1532.0",
    polygon: [
      -4.364,
      -13.852,
      29.36,
      -5.868,
      -14.101,
      29.36,
      -5.868,
      -14.101,
      31.82,
      -5.868,
      -14.101,
      31.82,
      -4.364,
      -13.852,
      31.82,
      -4.364,
      -13.852,
      29.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1097.0",
    polygon: [
      -12.119,
      -38.752,
      12.86,
      -13.935,
      -38.851,
      12.86,
      -13.935,
      -38.851,
      13.76,
      -13.935,
      -38.851,
      13.76,
      -12.119,
      -38.752,
      13.76,
      -12.119,
      -38.752,
      12.86,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "942.0",
    polygon: [
      -50.233,
      -40.803,
      9.26,
      -52.049,
      -40.902,
      9.26,
      -52.049,
      -40.902,
      10.16,
      -52.049,
      -40.902,
      10.16,
      -50.233,
      -40.803,
      10.16,
      -50.233,
      -40.803,
      9.26,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "455.0",
    polygon: [
      -38.513,
      -40.787,
      11.86,
      -39.576,
      -40.845,
      11.86,
      -39.576,
      -40.845,
      14.32,
      -39.576,
      -40.845,
      14.32,
      -38.513,
      -40.787,
      14.32,
      -38.513,
      -40.787,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "454.0",
    polygon: [
      -39.576,
      -40.845,
      11.86,
      -40.638,
      -40.903,
      11.86,
      -40.638,
      -40.903,
      14.32,
      -40.638,
      -40.903,
      14.32,
      -39.576,
      -40.845,
      14.32,
      -39.576,
      -40.845,
      11.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1019.0",
    polygon: [
      -52.074,
      -40.904,
      16.46,
      -53.092,
      -40.959,
      16.46,
      -53.092,
      -40.959,
      17.36,
      -53.092,
      -40.959,
      17.36,
      -52.074,
      -40.904,
      17.36,
      -52.074,
      -40.904,
      16.46,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1533.0",
    polygon: [
      -2.824,
      -13.596,
      29.36,
      -3.975,
      -13.787,
      29.36,
      -3.975,
      -13.787,
      31.82,
      -3.975,
      -13.787,
      31.82,
      -2.824,
      -13.596,
      31.82,
      -2.824,
      -13.596,
      29.36,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "156.0",
    polygon: [
      12.857,
      -11.2,
      2.78,
      10.608,
      -11.573,
      2.78,
      10.608,
      -11.573,
      3.82,
      10.608,
      -11.573,
      3.82,
      12.857,
      -11.2,
      3.82,
      12.857,
      -11.2,
      2.78,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "1364.0",
    polygon: [
      13.377,
      -11.061,
      22.36,
      12.061,
      -11.28,
      22.36,
      12.061,
      -11.28,
      24.82,
      12.061,
      -11.28,
      24.82,
      13.377,
      -11.061,
      24.82,
      13.377,
      -11.061,
      22.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "197.0",
    polygon: [
      -55.827,
      -41.131,
      4.86,
      -56.735,
      -41.181,
      4.86,
      -56.735,
      -41.181,
      5.106,
      -56.735,
      -41.181,
      5.106,
      -55.827,
      -41.131,
      5.106,
      -55.827,
      -41.131,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1696.0",
    polygon: [
      -67.453,
      -7.155,
      36.36,
      -68.276,
      -7.155,
      36.36,
      -68.276,
      -7.155,
      39.128,
      -68.276,
      -7.155,
      39.128,
      -67.453,
      -7.155,
      39.128,
      -67.453,
      -7.155,
      36.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "237.0",
    polygon: [
      -55.832,
      -41.131,
      4.569,
      -56.74,
      -41.181,
      4.569,
      -56.74,
      -41.181,
      4.815,
      -56.74,
      -41.181,
      4.815,
      -55.832,
      -41.131,
      4.815,
      -55.832,
      -41.131,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "1269.0",
    polygon: [
      2.566,
      -24.324,
      11.86,
      2.566,
      -25.654,
      11.86,
      2.566,
      -25.654,
      14.32,
      2.566,
      -25.654,
      14.32,
      2.566,
      -24.324,
      14.32,
      2.566,
      -24.324,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1177.0",
    polygon: [
      5.131,
      -12.313,
      15.36,
      3.979,
      -12.504,
      15.36,
      3.979,
      -12.504,
      17.82,
      3.979,
      -12.504,
      17.82,
      5.131,
      -12.313,
      17.82,
      5.131,
      -12.313,
      15.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1137.0",
    polygon: [
      -12.119,
      -38.752,
      16.46,
      -13.935,
      -38.851,
      16.46,
      -13.935,
      -38.851,
      17.36,
      -13.935,
      -38.851,
      17.36,
      -12.119,
      -38.752,
      17.36,
      -12.119,
      -38.752,
      16.46,
    ],
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "238.0",
    polygon: [
      -54.938,
      -41.083,
      4.569,
      -55.847,
      -41.132,
      4.569,
      -55.847,
      -41.132,
      4.815,
      -55.847,
      -41.132,
      4.815,
      -54.938,
      -41.083,
      4.815,
      -54.938,
      -41.083,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "150.0",
    polygon: [
      10.608,
      -11.573,
      2.78,
      8.359,
      -11.946,
      2.78,
      8.359,
      -11.946,
      3.82,
      8.359,
      -11.946,
      3.82,
      10.608,
      -11.573,
      3.82,
      10.608,
      -11.573,
      2.78,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "697.0",
    polygon: [
      -19.512,
      -39.175,
      4.077,
      -20.421,
      -39.223,
      4.077,
      -20.421,
      -39.223,
      4.323,
      -20.421,
      -39.223,
      4.323,
      -19.512,
      -39.175,
      4.323,
      -19.512,
      -39.175,
      4.077,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1270.0",
    polygon: [
      2.566,
      -22.993,
      11.86,
      2.566,
      -24.324,
      11.86,
      2.566,
      -24.324,
      14.32,
      2.566,
      -24.324,
      14.32,
      2.566,
      -22.993,
      14.32,
      2.566,
      -22.993,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "196.0",
    polygon: [
      -54.026,
      -41.029,
      0.05,
      -54.934,
      -41.079,
      0.05,
      -54.934,
      -41.079,
      2.78,
      -54.934,
      -41.079,
      2.78,
      -54.026,
      -41.029,
      2.78,
      -54.026,
      -41.029,
      0.05,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1058.0",
    polygon: [
      -15.769,
      -38.951,
      9.26,
      -16.788,
      -39.006,
      9.26,
      -16.788,
      -39.006,
      10.16,
      -16.788,
      -39.006,
      10.16,
      -15.769,
      -38.951,
      10.16,
      -15.769,
      -38.951,
      9.26,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "657.0",
    polygon: [
      -19.512,
      -39.175,
      5.352,
      -20.421,
      -39.223,
      5.352,
      -20.421,
      -39.223,
      5.598,
      -20.421,
      -39.223,
      5.598,
      -19.512,
      -39.175,
      5.598,
      -19.512,
      -39.175,
      5.352,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "533.0",
    polygon: [
      -28.912,
      -39.668,
      5.598,
      -29.821,
      -39.715,
      5.598,
      -29.821,
      -39.715,
      5.844,
      -29.821,
      -39.715,
      5.844,
      -28.912,
      -39.668,
      5.844,
      -28.912,
      -39.668,
      5.598,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "199.0",
    polygon: [
      -54.024,
      -41.033,
      4.86,
      -54.933,
      -41.082,
      4.86,
      -54.933,
      -41.082,
      5.106,
      -54.933,
      -41.082,
      5.106,
      -54.024,
      -41.033,
      5.106,
      -54.024,
      -41.033,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1272.0",
    polygon: [
      2.566,
      -20.332,
      11.86,
      2.566,
      -21.663,
      11.86,
      2.566,
      -21.663,
      14.32,
      2.566,
      -21.663,
      14.32,
      2.566,
      -20.332,
      14.32,
      2.566,
      -20.332,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "494.0",
    polygon: [
      -34.091,
      -40.547,
      0.05,
      -35.172,
      -40.606,
      0.05,
      -35.172,
      -40.606,
      2.78,
      -35.172,
      -40.606,
      2.78,
      -34.091,
      -40.547,
      2.78,
      -34.091,
      -40.547,
      0.05,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "981.0",
    polygon: [
      -48.416,
      -40.704,
      12.86,
      -50.233,
      -40.803,
      12.86,
      -50.233,
      -40.803,
      13.76,
      -50.233,
      -40.803,
      13.76,
      -48.416,
      -40.704,
      13.76,
      -48.416,
      -40.704,
      12.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1233.0",
    polygon: [
      2.566,
      -29.646,
      4.86,
      2.566,
      -30.976,
      4.86,
      2.566,
      -30.976,
      7.32,
      2.566,
      -30.976,
      7.32,
      2.566,
      -29.646,
      7.32,
      2.566,
      -29.646,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "783.0",
    polygon: [
      -18.619,
      -39.128,
      0.05,
      -19.527,
      -39.176,
      0.05,
      -19.527,
      -39.176,
      2.78,
      -19.527,
      -39.176,
      2.78,
      -18.619,
      -39.128,
      2.78,
      -18.619,
      -39.128,
      0.05,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "495.0",
    polygon: [
      -30.848,
      -40.37,
      0.05,
      -31.929,
      -40.429,
      0.05,
      -31.929,
      -40.429,
      2.78,
      -31.929,
      -40.429,
      2.78,
      -30.848,
      -40.37,
      2.78,
      -30.848,
      -40.37,
      0.05,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1534.0",
    polygon: [
      -1.672,
      -13.405,
      29.36,
      -2.824,
      -13.596,
      29.36,
      -2.824,
      -13.596,
      31.82,
      -2.824,
      -13.596,
      31.82,
      -1.672,
      -13.405,
      31.82,
      -1.672,
      -13.405,
      29.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1234.0",
    polygon: [
      2.566,
      -28.315,
      4.86,
      2.566,
      -29.646,
      4.86,
      2.566,
      -29.646,
      7.32,
      2.566,
      -29.646,
      7.32,
      2.566,
      -28.315,
      7.32,
      2.566,
      -28.315,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1535.0",
    polygon: [
      -6.268,
      -14.168,
      29.36,
      -7.42,
      -14.359,
      29.36,
      -7.42,
      -14.359,
      31.82,
      -7.42,
      -14.359,
      31.82,
      -6.268,
      -14.168,
      31.82,
      -6.268,
      -14.168,
      29.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "698.0",
    polygon: [
      -18.618,
      -39.128,
      4.077,
      -19.527,
      -39.176,
      4.077,
      -19.527,
      -39.176,
      4.323,
      -19.527,
      -39.176,
      4.323,
      -18.618,
      -39.128,
      4.323,
      -18.618,
      -39.128,
      4.077,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1697.0",
    polygon: [
      -67.453,
      -7.155,
      39.128,
      -69.098,
      -7.155,
      39.128,
      -69.098,
      -7.155,
      40.05,
      -69.098,
      -7.155,
      40.05,
      -67.453,
      -7.155,
      40.05,
      -67.453,
      -7.155,
      39.128,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "699.0",
    polygon: [
      -17.71,
      -39.081,
      4.077,
      -18.618,
      -39.128,
      4.077,
      -18.618,
      -39.128,
      4.323,
      -18.618,
      -39.128,
      4.323,
      -17.71,
      -39.081,
      4.323,
      -17.71,
      -39.081,
      4.077,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "418.0",
    polygon: [
      -4.497,
      -38.948,
      6.5,
      -5.897,
      -39.021,
      6.5,
      -5.897,
      -39.021,
      7.32,
      -5.897,
      -39.021,
      7.32,
      -4.497,
      -38.948,
      7.32,
      -4.497,
      -38.948,
      6.5,
    ],
    "04-15 09:15": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "660.0",
    polygon: [
      -16.816,
      -39.034,
      5.352,
      -17.725,
      -39.081,
      5.352,
      -17.725,
      -39.081,
      5.598,
      -17.725,
      -39.081,
      5.598,
      -16.816,
      -39.034,
      5.598,
      -16.816,
      -39.034,
      5.352,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1273.0",
    polygon: [
      2.566,
      -19.002,
      11.86,
      2.566,
      -20.332,
      11.86,
      2.566,
      -20.332,
      14.32,
      2.566,
      -20.332,
      14.32,
      2.566,
      -19.002,
      14.32,
      2.566,
      -19.002,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1061.0",
    polygon: [
      -25.163,
      -39.462,
      9.26,
      -26.182,
      -39.517,
      9.26,
      -26.182,
      -39.517,
      10.16,
      -26.182,
      -39.517,
      10.16,
      -25.163,
      -39.462,
      10.16,
      -25.163,
      -39.462,
      9.26,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1060.0",
    polygon: [
      -20.464,
      -39.206,
      9.26,
      -21.483,
      -39.261,
      9.26,
      -21.483,
      -39.261,
      10.16,
      -21.483,
      -39.261,
      10.16,
      -20.464,
      -39.206,
      10.16,
      -20.464,
      -39.206,
      9.26,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "325.0",
    polygon: [
      -46.437,
      -40.619,
      5.106,
      -47.345,
      -40.669,
      5.106,
      -47.345,
      -40.669,
      5.352,
      -47.345,
      -40.669,
      5.352,
      -46.437,
      -40.619,
      5.352,
      -46.437,
      -40.619,
      5.106,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "1365.0",
    polygon: [
      12.061,
      -11.28,
      22.36,
      10.744,
      -11.498,
      22.36,
      10.744,
      -11.498,
      24.82,
      10.744,
      -11.498,
      24.82,
      12.061,
      -11.28,
      24.82,
      12.061,
      -11.28,
      22.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1022.0",
    polygon: [
      -50.233,
      -40.803,
      16.46,
      -52.049,
      -40.902,
      16.46,
      -52.049,
      -40.902,
      17.36,
      -52.049,
      -40.902,
      17.36,
      -50.233,
      -40.803,
      17.36,
      -50.233,
      -40.803,
      16.46,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "326.0",
    polygon: [
      -45.543,
      -40.571,
      5.106,
      -46.452,
      -40.62,
      5.106,
      -46.452,
      -40.62,
      5.352,
      -46.452,
      -40.62,
      5.352,
      -45.543,
      -40.571,
      5.352,
      -45.543,
      -40.571,
      5.106,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "65.0",
    polygon: [
      -62.472,
      -42.089,
      11.86,
      -63.553,
      -42.147,
      11.86,
      -63.553,
      -42.147,
      14.32,
      -63.553,
      -42.147,
      14.32,
      -62.472,
      -42.089,
      14.32,
      -62.472,
      -42.089,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "982.0",
    polygon: [
      -50.233,
      -40.803,
      12.86,
      -52.049,
      -40.902,
      12.86,
      -52.049,
      -40.902,
      13.76,
      -52.049,
      -40.902,
      13.76,
      -50.233,
      -40.803,
      13.76,
      -50.233,
      -40.803,
      12.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1655.0",
    polygon: [
      6.467,
      -12.202,
      36.36,
      5.282,
      -12.394,
      36.36,
      5.282,
      -12.394,
      39.128,
      5.282,
      -12.394,
      39.128,
      6.467,
      -12.202,
      39.128,
      6.467,
      -12.202,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "535.0",
    polygon: [
      -27.11,
      -39.573,
      5.598,
      -28.018,
      -39.621,
      5.598,
      -28.018,
      -39.621,
      5.844,
      -28.018,
      -39.621,
      5.844,
      -27.11,
      -39.573,
      5.844,
      -27.11,
      -39.573,
      5.598,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "534.0",
    polygon: [
      -28.018,
      -39.621,
      5.598,
      -28.927,
      -39.668,
      5.598,
      -28.927,
      -39.668,
      5.844,
      -28.927,
      -39.668,
      5.844,
      -28.018,
      -39.621,
      5.844,
      -28.018,
      -39.621,
      5.598,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1139.0",
    polygon: [
      -13.935,
      -38.851,
      16.46,
      -15.751,
      -38.95,
      16.46,
      -15.751,
      -38.95,
      17.36,
      -15.751,
      -38.95,
      17.36,
      -13.935,
      -38.851,
      17.36,
      -13.935,
      -38.851,
      16.46,
    ],
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1698.0",
    polygon: [
      -73.344,
      -7.155,
      36.36,
      -74.544,
      -7.155,
      36.36,
      -74.544,
      -7.155,
      39.128,
      -74.544,
      -7.155,
      39.128,
      -73.344,
      -7.155,
      39.128,
      -73.344,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "943.0",
    polygon: [
      -53.118,
      -40.961,
      9.26,
      -54.935,
      -41.06,
      9.26,
      -54.935,
      -41.06,
      10.16,
      -54.935,
      -41.06,
      10.16,
      -53.118,
      -40.961,
      10.16,
      -53.118,
      -40.961,
      9.26,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "574.0",
    polygon: [
      -28.018,
      -39.621,
      3.831,
      -28.927,
      -39.668,
      3.831,
      -28.927,
      -39.668,
      4.077,
      -28.927,
      -39.668,
      4.077,
      -28.018,
      -39.621,
      4.077,
      -28.018,
      -39.621,
      3.831,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "456.0",
    polygon: [
      -34.107,
      -40.548,
      11.86,
      -35.188,
      -40.606,
      11.86,
      -35.188,
      -40.606,
      14.32,
      -35.188,
      -40.606,
      14.32,
      -34.107,
      -40.548,
      14.32,
      -34.107,
      -40.548,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "158.0",
    polygon: [
      9.148,
      -11.815,
      0.05,
      8.359,
      -11.946,
      0.05,
      8.359,
      -11.946,
      2.78,
      8.359,
      -11.946,
      2.78,
      9.148,
      -11.815,
      2.78,
      9.148,
      -11.815,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "1021.0",
    polygon: [
      -48.416,
      -40.704,
      16.46,
      -50.233,
      -40.803,
      16.46,
      -50.233,
      -40.803,
      17.36,
      -50.233,
      -40.803,
      17.36,
      -48.416,
      -40.704,
      17.36,
      -48.416,
      -40.704,
      16.46,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1179.0",
    polygon: [
      10.528,
      -11.417,
      15.36,
      9.376,
      -11.608,
      15.36,
      9.376,
      -11.608,
      17.82,
      9.376,
      -11.608,
      17.82,
      10.528,
      -11.417,
      17.82,
      10.528,
      -11.417,
      15.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1405.0",
    polygon: [
      10.744,
      -11.498,
      32.86,
      9.428,
      -11.716,
      32.86,
      9.428,
      -11.716,
      35.32,
      9.428,
      -11.716,
      35.32,
      10.744,
      -11.498,
      35.32,
      10.744,
      -11.498,
      32.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "573.0",
    polygon: [
      -28.912,
      -39.668,
      3.831,
      -29.821,
      -39.715,
      3.831,
      -29.821,
      -39.715,
      4.077,
      -29.821,
      -39.715,
      4.077,
      -28.912,
      -39.668,
      4.077,
      -28.912,
      -39.668,
      3.831,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "417.0",
    polygon: [
      -4.497,
      -38.948,
      5.68,
      -5.897,
      -39.021,
      5.68,
      -5.897,
      -39.021,
      6.5,
      -5.897,
      -39.021,
      6.5,
      -4.497,
      -38.948,
      6.5,
      -4.497,
      -38.948,
      5.68,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1656.0",
    polygon: [
      6.467,
      -12.202,
      39.128,
      5.282,
      -12.394,
      39.128,
      5.282,
      -12.394,
      40.05,
      5.282,
      -12.394,
      40.05,
      6.467,
      -12.202,
      40.05,
      6.467,
      -12.202,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "239.0",
    polygon: [
      -54.029,
      -41.033,
      4.569,
      -54.938,
      -41.083,
      4.569,
      -54.938,
      -41.083,
      4.815,
      -54.938,
      -41.083,
      4.815,
      -54.029,
      -41.033,
      4.815,
      -54.029,
      -41.033,
      4.569,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "66.0",
    polygon: [
      -67.715,
      -42.369,
      15.36,
      -69.115,
      -42.444,
      15.36,
      -69.115,
      -42.444,
      16.18,
      -69.115,
      -42.444,
      16.18,
      -67.715,
      -42.369,
      16.18,
      -67.715,
      -42.369,
      15.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "200.0",
    polygon: [
      -53.131,
      -40.984,
      4.86,
      -54.039,
      -41.034,
      4.86,
      -54.039,
      -41.034,
      5.106,
      -54.039,
      -41.034,
      5.106,
      -53.131,
      -40.984,
      5.106,
      -53.131,
      -40.984,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "457.0",
    polygon: [
      -33.026,
      -40.489,
      11.86,
      -34.108,
      -40.548,
      11.86,
      -34.108,
      -40.548,
      14.32,
      -34.108,
      -40.548,
      14.32,
      -33.026,
      -40.489,
      14.32,
      -33.026,
      -40.489,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1178.0",
    polygon: [
      7.829,
      -11.865,
      15.36,
      6.678,
      -12.056,
      15.36,
      6.678,
      -12.056,
      17.82,
      6.678,
      -12.056,
      17.82,
      7.829,
      -11.865,
      17.82,
      7.829,
      -11.865,
      15.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1138.0",
    polygon: [
      -15.769,
      -38.951,
      16.46,
      -16.788,
      -39.006,
      16.46,
      -16.788,
      -39.006,
      17.36,
      -16.788,
      -39.006,
      17.36,
      -15.769,
      -38.951,
      17.36,
      -15.769,
      -38.951,
      16.46,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1404.0",
    polygon: [
      12.061,
      -11.28,
      32.86,
      10.744,
      -11.498,
      32.86,
      10.744,
      -11.498,
      35.32,
      10.744,
      -11.498,
      35.32,
      12.061,
      -11.28,
      35.32,
      12.061,
      -11.28,
      32.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "159.0",
    polygon: [
      12.857,
      -11.2,
      0.05,
      12.068,
      -11.331,
      0.05,
      12.068,
      -11.331,
      2.78,
      12.068,
      -11.331,
      2.78,
      12.857,
      -11.2,
      2.78,
      12.857,
      -11.2,
      0.05,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "1100.0",
    polygon: [
      -20.464,
      -39.206,
      12.86,
      -21.483,
      -39.261,
      12.86,
      -21.483,
      -39.261,
      13.76,
      -21.483,
      -39.261,
      13.76,
      -20.464,
      -39.206,
      13.76,
      -20.464,
      -39.206,
      12.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "659.0",
    polygon: [
      -17.71,
      -39.081,
      5.352,
      -18.618,
      -39.128,
      5.352,
      -18.618,
      -39.128,
      5.598,
      -18.618,
      -39.128,
      5.598,
      -17.71,
      -39.081,
      5.598,
      -17.71,
      -39.081,
      5.352,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "201.0",
    polygon: [
      -55.827,
      -41.131,
      5.106,
      -56.735,
      -41.181,
      5.106,
      -56.735,
      -41.181,
      5.352,
      -56.735,
      -41.181,
      5.352,
      -55.827,
      -41.131,
      5.352,
      -55.827,
      -41.131,
      5.106,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1274.0",
    polygon: [
      2.566,
      -17.671,
      11.86,
      2.566,
      -19.002,
      11.86,
      2.566,
      -19.002,
      14.32,
      2.566,
      -19.002,
      14.32,
      2.566,
      -17.671,
      14.32,
      2.566,
      -17.671,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "983.0",
    polygon: [
      -53.118,
      -40.961,
      12.86,
      -54.935,
      -41.06,
      12.86,
      -54.935,
      -41.06,
      13.76,
      -54.935,
      -41.06,
      13.76,
      -53.118,
      -40.961,
      13.76,
      -53.118,
      -40.961,
      12.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1099.0",
    polygon: [
      -13.935,
      -38.851,
      12.86,
      -15.751,
      -38.95,
      12.86,
      -15.751,
      -38.95,
      13.76,
      -15.751,
      -38.95,
      13.76,
      -13.935,
      -38.851,
      13.76,
      -13.935,
      -38.851,
      12.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "496.0",
    polygon: [
      -33.009,
      -40.488,
      0.05,
      -34.091,
      -40.547,
      0.05,
      -34.091,
      -40.547,
      2.78,
      -34.091,
      -40.547,
      2.78,
      -33.009,
      -40.488,
      2.78,
      -33.009,
      -40.488,
      0.05,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "944.0",
    polygon: [
      -54.935,
      -41.06,
      9.26,
      -56.751,
      -41.159,
      9.26,
      -56.751,
      -41.159,
      10.16,
      -56.751,
      -41.159,
      10.16,
      -54.935,
      -41.06,
      10.16,
      -54.935,
      -41.06,
      9.26,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1235.0",
    polygon: [
      2.566,
      -26.985,
      4.86,
      2.566,
      -28.315,
      4.86,
      2.566,
      -28.315,
      7.32,
      2.566,
      -28.315,
      7.32,
      2.566,
      -26.985,
      7.32,
      2.566,
      -26.985,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "784.0",
    polygon: [
      -16.802,
      -39.033,
      0.05,
      -17.711,
      -39.081,
      0.05,
      -17.711,
      -39.081,
      2.78,
      -17.711,
      -39.081,
      2.78,
      -16.802,
      -39.033,
      2.78,
      -16.802,
      -39.033,
      0.05,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1366.0",
    polygon: [
      10.744,
      -11.498,
      22.36,
      9.428,
      -11.716,
      22.36,
      9.428,
      -11.716,
      24.82,
      9.428,
      -11.716,
      24.82,
      10.744,
      -11.498,
      24.82,
      10.744,
      -11.498,
      22.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "69.0",
    polygon: [
      -73.309,
      -42.668,
      15.36,
      -74.372,
      -42.725,
      15.36,
      -74.372,
      -42.725,
      17.82,
      -74.372,
      -42.725,
      17.82,
      -73.309,
      -42.668,
      17.82,
      -73.309,
      -42.668,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1536.0",
    polygon: [
      -8.971,
      -14.616,
      29.36,
      -10.123,
      -14.807,
      29.36,
      -10.123,
      -14.807,
      31.82,
      -10.123,
      -14.807,
      31.82,
      -8.971,
      -14.616,
      31.82,
      -8.971,
      -14.616,
      29.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1538.0",
    polygon: [
      -10.123,
      -14.807,
      29.36,
      -11.274,
      -14.998,
      29.36,
      -11.274,
      -14.998,
      31.82,
      -11.274,
      -14.998,
      31.82,
      -10.123,
      -14.807,
      31.82,
      -10.123,
      -14.807,
      29.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "700.0",
    polygon: [
      -16.816,
      -39.034,
      4.077,
      -17.725,
      -39.081,
      4.077,
      -17.725,
      -39.081,
      4.323,
      -17.725,
      -39.081,
      4.323,
      -16.816,
      -39.034,
      4.323,
      -16.816,
      -39.034,
      4.077,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1658.0",
    polygon: [
      7.82,
      -11.982,
      39.128,
      6.636,
      -12.174,
      39.128,
      6.636,
      -12.174,
      40.05,
      6.636,
      -12.174,
      40.05,
      7.82,
      -11.982,
      40.05,
      7.82,
      -11.982,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "202.0",
    polygon: [
      -54.933,
      -41.082,
      5.106,
      -55.842,
      -41.132,
      5.106,
      -55.842,
      -41.132,
      5.352,
      -55.842,
      -41.132,
      5.352,
      -54.933,
      -41.082,
      5.352,
      -54.933,
      -41.082,
      5.106,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1659.0",
    polygon: [
      9.174,
      -11.762,
      36.36,
      7.989,
      -11.954,
      36.36,
      7.989,
      -11.954,
      39.128,
      7.989,
      -11.954,
      39.128,
      9.174,
      -11.762,
      39.128,
      9.174,
      -11.762,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1699.0",
    polygon: [
      -73.344,
      -7.155,
      39.128,
      -74.544,
      -7.155,
      39.128,
      -74.544,
      -7.155,
      40.05,
      -74.544,
      -7.155,
      40.05,
      -73.344,
      -7.155,
      40.05,
      -73.344,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1236.0",
    polygon: [
      2.566,
      -25.654,
      4.86,
      2.566,
      -26.985,
      4.86,
      2.566,
      -26.985,
      7.32,
      2.566,
      -26.985,
      7.32,
      2.566,
      -25.654,
      7.32,
      2.566,
      -25.654,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "421.0",
    polygon: [
      -7.966,
      -39.13,
      4.86,
      -9.029,
      -39.185,
      4.86,
      -9.029,
      -39.185,
      7.32,
      -9.029,
      -39.185,
      7.32,
      -7.966,
      -39.13,
      7.32,
      -7.966,
      -39.13,
      4.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "419.0",
    polygon: [
      -10.091,
      -39.241,
      4.86,
      -11.154,
      -39.297,
      4.86,
      -11.154,
      -39.297,
      7.32,
      -11.154,
      -39.297,
      7.32,
      -10.091,
      -39.241,
      7.32,
      -10.091,
      -39.241,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "788.0",
    polygon: [
      -47.379,
      -40.648,
      8.36,
      -48.397,
      -40.703,
      8.36,
      -48.397,
      -40.703,
      9.26,
      -48.397,
      -40.703,
      9.26,
      -47.379,
      -40.648,
      9.26,
      -47.379,
      -40.648,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1140.0",
    polygon: [
      -20.464,
      -39.206,
      16.46,
      -21.483,
      -39.261,
      16.46,
      -21.483,
      -39.261,
      17.36,
      -21.483,
      -39.261,
      17.36,
      -20.464,
      -39.206,
      17.36,
      -20.464,
      -39.206,
      16.46,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1142.0",
    polygon: [
      -16.807,
      -39.007,
      16.46,
      -18.623,
      -39.106,
      16.46,
      -18.623,
      -39.106,
      17.36,
      -18.623,
      -39.106,
      17.36,
      -16.807,
      -39.007,
      17.36,
      -16.807,
      -39.007,
      16.46,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "327.0",
    polygon: [
      -44.634,
      -40.521,
      5.106,
      -45.543,
      -40.571,
      5.106,
      -45.543,
      -40.571,
      5.352,
      -45.543,
      -40.571,
      5.352,
      -44.634,
      -40.521,
      5.352,
      -44.634,
      -40.521,
      5.106,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "329.0",
    polygon: [
      -46.437,
      -40.619,
      5.352,
      -47.345,
      -40.669,
      5.352,
      -47.345,
      -40.669,
      5.598,
      -47.345,
      -40.669,
      5.598,
      -46.437,
      -40.619,
      5.598,
      -46.437,
      -40.619,
      5.352,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "67.0",
    polygon: [
      -67.715,
      -42.369,
      16.18,
      -69.115,
      -42.444,
      16.18,
      -69.115,
      -42.444,
      17.0,
      -69.115,
      -42.444,
      17.0,
      -67.715,
      -42.369,
      17.0,
      -67.715,
      -42.369,
      16.18,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "985.0",
    polygon: [
      -57.812,
      -41.216,
      12.86,
      -59.629,
      -41.315,
      12.86,
      -59.629,
      -41.315,
      13.76,
      -59.629,
      -41.315,
      13.76,
      -57.812,
      -41.216,
      13.76,
      -57.812,
      -41.216,
      12.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "70.0",
    polygon: [
      -72.247,
      -42.611,
      15.36,
      -73.309,
      -42.668,
      15.36,
      -73.309,
      -42.668,
      17.82,
      -73.309,
      -42.668,
      17.82,
      -72.247,
      -42.611,
      17.82,
      -72.247,
      -42.611,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1368.0",
    polygon: [
      8.112,
      -11.935,
      22.36,
      6.795,
      -12.153,
      22.36,
      6.795,
      -12.153,
      24.82,
      6.795,
      -12.153,
      24.82,
      8.112,
      -11.935,
      24.82,
      8.112,
      -11.935,
      22.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "582.0",
    polygon: [
      -26.202,
      -39.526,
      2.78,
      -28.019,
      -39.621,
      2.78,
      -28.019,
      -39.621,
      3.82,
      -28.019,
      -39.621,
      3.82,
      -26.202,
      -39.526,
      3.82,
      -26.202,
      -39.526,
      2.78,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1369.0",
    polygon: [
      6.795,
      -12.153,
      22.36,
      5.479,
      -12.371,
      22.36,
      5.479,
      -12.371,
      24.82,
      5.479,
      -12.371,
      24.82,
      6.795,
      -12.153,
      24.82,
      6.795,
      -12.153,
      22.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1539.0",
    polygon: [
      -0.12,
      -13.148,
      32.86,
      -1.272,
      -13.339,
      32.86,
      -1.272,
      -13.339,
      35.32,
      -1.272,
      -13.339,
      35.32,
      -0.12,
      -13.148,
      35.32,
      -0.12,
      -13.148,
      32.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1701.0",
    polygon: [
      -62.008,
      -7.155,
      36.36,
      -63.208,
      -7.155,
      36.36,
      -63.208,
      -7.155,
      39.128,
      -63.208,
      -7.155,
      39.128,
      -62.008,
      -7.155,
      39.128,
      -62.008,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "661.0",
    polygon: [
      -19.512,
      -39.175,
      5.598,
      -20.421,
      -39.223,
      5.598,
      -20.421,
      -39.223,
      5.844,
      -20.421,
      -39.223,
      5.844,
      -19.512,
      -39.175,
      5.844,
      -19.512,
      -39.175,
      5.598,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "701.0",
    polygon: [
      -19.512,
      -39.175,
      3.831,
      -20.421,
      -39.223,
      3.831,
      -20.421,
      -39.223,
      4.077,
      -20.421,
      -39.223,
      4.077,
      -19.512,
      -39.175,
      4.077,
      -19.512,
      -39.175,
      3.831,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "703.0",
    polygon: [
      -17.71,
      -39.081,
      3.831,
      -18.618,
      -39.128,
      3.831,
      -18.618,
      -39.128,
      4.077,
      -18.618,
      -39.128,
      4.077,
      -17.71,
      -39.081,
      4.077,
      -17.71,
      -39.081,
      3.831,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "497.0",
    polygon: [
      -31.928,
      -40.429,
      0.05,
      -33.009,
      -40.488,
      0.05,
      -33.009,
      -40.488,
      2.78,
      -33.009,
      -40.488,
      2.78,
      -31.928,
      -40.429,
      2.78,
      -31.928,
      -40.429,
      0.05,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "947.0",
    polygon: [
      -47.379,
      -40.648,
      10.16,
      -48.397,
      -40.703,
      10.16,
      -48.397,
      -40.703,
      11.06,
      -48.397,
      -40.703,
      11.06,
      -47.379,
      -40.648,
      11.06,
      -47.379,
      -40.648,
      10.16,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "537.0",
    polygon: [
      -28.912,
      -39.668,
      5.844,
      -29.821,
      -39.715,
      5.844,
      -29.821,
      -39.715,
      6.09,
      -29.821,
      -39.715,
      6.09,
      -28.912,
      -39.668,
      6.09,
      -28.912,
      -39.668,
      5.844,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "1700.0",
    polygon: [
      -62.008,
      -7.155,
      39.128,
      -63.208,
      -7.155,
      39.128,
      -63.208,
      -7.155,
      40.05,
      -63.208,
      -7.155,
      40.05,
      -62.008,
      -7.155,
      40.05,
      -62.008,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "945.0",
    polygon: [
      -57.812,
      -41.216,
      9.26,
      -59.629,
      -41.315,
      9.26,
      -59.629,
      -41.315,
      10.16,
      -59.629,
      -41.315,
      10.16,
      -57.812,
      -41.216,
      10.16,
      -57.812,
      -41.216,
      9.26,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "984.0",
    polygon: [
      -54.935,
      -41.06,
      12.86,
      -56.751,
      -41.159,
      12.86,
      -56.751,
      -41.159,
      13.76,
      -56.751,
      -41.159,
      13.76,
      -54.935,
      -41.06,
      13.76,
      -54.935,
      -41.06,
      12.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "330.0",
    polygon: [
      -45.543,
      -40.571,
      5.352,
      -46.452,
      -40.62,
      5.352,
      -46.452,
      -40.62,
      5.598,
      -46.452,
      -40.62,
      5.598,
      -45.543,
      -40.571,
      5.598,
      -45.543,
      -40.571,
      5.352,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "204.0",
    polygon: [
      -53.131,
      -40.984,
      5.106,
      -54.039,
      -41.034,
      5.106,
      -54.039,
      -41.034,
      5.352,
      -54.039,
      -41.034,
      5.352,
      -53.131,
      -40.984,
      5.352,
      -53.131,
      -40.984,
      5.106,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "163.0",
    polygon: [
      11.336,
      -11.452,
      0.05,
      10.606,
      -11.573,
      0.05,
      10.606,
      -11.573,
      2.78,
      10.606,
      -11.573,
      2.78,
      11.336,
      -11.452,
      2.78,
      11.336,
      -11.452,
      0.05,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1182.0",
    polygon: [
      18.623,
      -10.075,
      15.36,
      17.472,
      -10.266,
      15.36,
      17.472,
      -10.266,
      17.82,
      17.472,
      -10.266,
      17.82,
      18.623,
      -10.075,
      17.82,
      18.623,
      -10.075,
      15.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1064.0",
    polygon: [
      -21.509,
      -39.263,
      9.26,
      -23.325,
      -39.362,
      9.26,
      -23.325,
      -39.362,
      10.16,
      -23.325,
      -39.362,
      10.16,
      -21.509,
      -39.263,
      10.16,
      -21.509,
      -39.263,
      9.26,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1657.0",
    polygon: [
      7.82,
      -11.982,
      36.36,
      6.636,
      -12.174,
      36.36,
      6.636,
      -12.174,
      39.128,
      6.636,
      -12.174,
      39.128,
      7.82,
      -11.982,
      39.128,
      7.82,
      -11.982,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1102.0",
    polygon: [
      -16.807,
      -39.007,
      12.86,
      -18.623,
      -39.106,
      12.86,
      -18.623,
      -39.106,
      13.76,
      -18.623,
      -39.106,
      13.76,
      -16.807,
      -39.007,
      13.76,
      -16.807,
      -39.007,
      12.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "664.0",
    polygon: [
      -16.816,
      -39.034,
      5.598,
      -17.725,
      -39.081,
      5.598,
      -17.725,
      -39.081,
      5.844,
      -17.725,
      -39.081,
      5.844,
      -16.816,
      -39.034,
      5.844,
      -16.816,
      -39.034,
      5.598,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "499.0",
    polygon: [
      -30.847,
      -40.37,
      2.78,
      -33.009,
      -40.488,
      2.78,
      -33.009,
      -40.488,
      3.82,
      -33.009,
      -40.488,
      3.82,
      -30.847,
      -40.37,
      3.82,
      -30.847,
      -40.37,
      2.78,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1367.0",
    polygon: [
      9.428,
      -11.716,
      22.36,
      8.112,
      -11.935,
      22.36,
      8.112,
      -11.935,
      24.82,
      8.112,
      -11.935,
      24.82,
      9.428,
      -11.716,
      24.82,
      9.428,
      -11.716,
      22.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "162.0",
    polygon: [
      10.607,
      -11.573,
      0.05,
      9.877,
      -11.694,
      0.05,
      9.877,
      -11.694,
      2.78,
      9.877,
      -11.694,
      2.78,
      10.607,
      -11.573,
      2.78,
      10.607,
      -11.573,
      0.05,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "164.0",
    polygon: [
      12.065,
      -11.331,
      0.05,
      11.335,
      -11.452,
      0.05,
      11.335,
      -11.452,
      2.78,
      11.335,
      -11.452,
      2.78,
      12.065,
      -11.331,
      2.78,
      12.065,
      -11.331,
      0.05,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "460.0",
    polygon: [
      -4.497,
      -38.948,
      11.86,
      -5.897,
      -39.021,
      11.86,
      -5.897,
      -39.021,
      12.68,
      -5.897,
      -39.021,
      12.68,
      -4.497,
      -38.948,
      12.68,
      -4.497,
      -38.948,
      11.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "946.0",
    polygon: [
      -43.728,
      -40.449,
      10.16,
      -45.545,
      -40.548,
      10.16,
      -45.545,
      -40.548,
      11.06,
      -45.545,
      -40.548,
      11.06,
      -43.728,
      -40.449,
      11.06,
      -43.728,
      -40.449,
      10.16,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1143.0",
    polygon: [
      -18.623,
      -39.106,
      16.46,
      -20.44,
      -39.205,
      16.46,
      -20.44,
      -39.205,
      17.36,
      -20.44,
      -39.205,
      17.36,
      -18.623,
      -39.106,
      17.36,
      -18.623,
      -39.106,
      16.46,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "663.0",
    polygon: [
      -17.71,
      -39.081,
      5.598,
      -18.618,
      -39.128,
      5.598,
      -18.618,
      -39.128,
      5.844,
      -18.618,
      -39.128,
      5.844,
      -17.71,
      -39.081,
      5.844,
      -17.71,
      -39.081,
      5.598,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1537.0",
    polygon: [
      -7.42,
      -14.359,
      29.36,
      -8.571,
      -14.55,
      29.36,
      -8.571,
      -14.55,
      31.82,
      -8.571,
      -14.55,
      31.82,
      -7.42,
      -14.359,
      31.82,
      -7.42,
      -14.359,
      29.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "538.0",
    polygon: [
      -28.018,
      -39.621,
      5.844,
      -28.927,
      -39.668,
      5.844,
      -28.927,
      -39.668,
      6.09,
      -28.927,
      -39.668,
      6.09,
      -28.018,
      -39.621,
      6.09,
      -28.018,
      -39.621,
      5.844,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1275.0",
    polygon: [
      2.566,
      -16.341,
      11.86,
      2.566,
      -17.671,
      11.86,
      2.566,
      -17.671,
      14.32,
      2.566,
      -17.671,
      14.32,
      2.566,
      -16.341,
      14.32,
      2.566,
      -16.341,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "422.0",
    polygon: [
      -6.904,
      -39.074,
      4.86,
      -7.966,
      -39.13,
      4.86,
      -7.966,
      -39.13,
      7.32,
      -7.966,
      -39.13,
      7.32,
      -6.904,
      -39.074,
      7.32,
      -6.904,
      -39.074,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "575.0",
    polygon: [
      -27.11,
      -39.573,
      3.831,
      -28.018,
      -39.621,
      3.831,
      -28.018,
      -39.621,
      4.077,
      -28.018,
      -39.621,
      4.077,
      -27.11,
      -39.573,
      4.077,
      -27.11,
      -39.573,
      3.831,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "576.0",
    polygon: [
      -26.216,
      -39.526,
      3.831,
      -27.125,
      -39.574,
      3.831,
      -27.125,
      -39.574,
      4.077,
      -27.125,
      -39.574,
      4.077,
      -26.216,
      -39.526,
      4.077,
      -26.216,
      -39.526,
      3.831,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1026.0",
    polygon: [
      -12.119,
      -38.752,
      8.36,
      -13.935,
      -38.851,
      8.36,
      -13.935,
      -38.851,
      9.26,
      -13.935,
      -38.851,
      9.26,
      -12.119,
      -38.752,
      9.26,
      -12.119,
      -38.752,
      8.36,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1103.0",
    polygon: [
      -18.623,
      -39.106,
      12.86,
      -20.44,
      -39.205,
      12.86,
      -20.44,
      -39.205,
      13.76,
      -20.44,
      -39.205,
      13.76,
      -18.623,
      -39.106,
      13.76,
      -18.623,
      -39.106,
      12.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1104.0",
    polygon: [
      -21.509,
      -39.263,
      12.86,
      -23.325,
      -39.362,
      12.86,
      -23.325,
      -39.362,
      13.76,
      -23.325,
      -39.362,
      13.76,
      -21.509,
      -39.263,
      13.76,
      -21.509,
      -39.263,
      12.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "328.0",
    polygon: [
      -43.741,
      -40.473,
      5.106,
      -44.649,
      -40.522,
      5.106,
      -44.649,
      -40.522,
      5.352,
      -44.649,
      -40.522,
      5.352,
      -43.741,
      -40.473,
      5.352,
      -43.741,
      -40.473,
      5.106,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1407.0",
    polygon: [
      8.112,
      -11.935,
      32.86,
      6.795,
      -12.153,
      32.86,
      6.795,
      -12.153,
      35.32,
      6.795,
      -12.153,
      35.32,
      8.112,
      -11.935,
      35.32,
      8.112,
      -11.935,
      32.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "986.0",
    polygon: [
      -43.728,
      -40.449,
      13.76,
      -45.545,
      -40.548,
      13.76,
      -45.545,
      -40.548,
      14.66,
      -45.545,
      -40.548,
      14.66,
      -43.728,
      -40.449,
      14.66,
      -43.728,
      -40.449,
      13.76,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1062.0",
    polygon: [
      -16.807,
      -39.007,
      9.26,
      -18.623,
      -39.106,
      9.26,
      -18.623,
      -39.106,
      10.16,
      -18.623,
      -39.106,
      10.16,
      -16.807,
      -39.007,
      10.16,
      -16.807,
      -39.007,
      9.26,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1180.0",
    polygon: [
      13.226,
      -10.97,
      15.36,
      12.075,
      -11.161,
      15.36,
      12.075,
      -11.161,
      17.82,
      12.075,
      -11.161,
      17.82,
      13.226,
      -10.97,
      17.82,
      13.226,
      -10.97,
      15.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "461.0",
    polygon: [
      -4.497,
      -38.948,
      12.68,
      -5.897,
      -39.021,
      12.68,
      -5.897,
      -39.021,
      13.5,
      -5.897,
      -39.021,
      13.5,
      -4.497,
      -38.948,
      13.5,
      -4.497,
      -38.948,
      12.68,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1063.0",
    polygon: [
      -18.623,
      -39.106,
      9.26,
      -20.44,
      -39.205,
      9.26,
      -20.44,
      -39.205,
      10.16,
      -20.44,
      -39.205,
      10.16,
      -18.623,
      -39.106,
      10.16,
      -18.623,
      -39.106,
      9.26,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1660.0",
    polygon: [
      9.174,
      -11.762,
      39.128,
      7.989,
      -11.954,
      39.128,
      7.989,
      -11.954,
      40.05,
      7.989,
      -11.954,
      40.05,
      9.174,
      -11.762,
      40.05,
      9.174,
      -11.762,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "662.0",
    polygon: [
      -18.618,
      -39.128,
      5.598,
      -19.527,
      -39.176,
      5.598,
      -19.527,
      -39.176,
      5.844,
      -19.527,
      -39.176,
      5.844,
      -18.618,
      -39.128,
      5.844,
      -18.618,
      -39.128,
      5.598,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1237.0",
    polygon: [
      2.566,
      -24.324,
      4.86,
      2.566,
      -25.654,
      4.86,
      2.566,
      -25.654,
      7.32,
      2.566,
      -25.654,
      7.32,
      2.566,
      -24.324,
      7.32,
      2.566,
      -24.324,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1025.0",
    polygon: [
      -57.812,
      -41.216,
      16.46,
      -59.629,
      -41.315,
      16.46,
      -59.629,
      -41.315,
      17.36,
      -59.629,
      -41.315,
      17.36,
      -57.812,
      -41.216,
      17.36,
      -57.812,
      -41.216,
      16.46,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1408.0",
    polygon: [
      6.795,
      -12.153,
      32.86,
      5.479,
      -12.371,
      32.86,
      5.479,
      -12.371,
      35.32,
      5.479,
      -12.371,
      35.32,
      6.795,
      -12.153,
      35.32,
      6.795,
      -12.153,
      32.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1409.0",
    polygon: [
      5.479,
      -12.371,
      32.86,
      4.163,
      -12.59,
      32.86,
      4.163,
      -12.59,
      35.32,
      4.163,
      -12.59,
      35.32,
      5.479,
      -12.371,
      35.32,
      5.479,
      -12.371,
      32.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "68.0",
    polygon: [
      -67.715,
      -42.369,
      17.0,
      -69.115,
      -42.444,
      17.0,
      -69.115,
      -42.444,
      17.82,
      -69.115,
      -42.444,
      17.82,
      -67.715,
      -42.369,
      17.82,
      -67.715,
      -42.369,
      17.0,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "948.0",
    polygon: [
      -45.545,
      -40.548,
      10.16,
      -47.361,
      -40.647,
      10.16,
      -47.361,
      -40.647,
      11.06,
      -47.361,
      -40.647,
      11.06,
      -45.545,
      -40.548,
      11.06,
      -45.545,
      -40.548,
      10.16,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1023.0",
    polygon: [
      -53.118,
      -40.961,
      16.46,
      -54.935,
      -41.06,
      16.46,
      -54.935,
      -41.06,
      17.36,
      -54.935,
      -41.06,
      17.36,
      -53.118,
      -40.961,
      17.36,
      -53.118,
      -40.961,
      16.46,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "459.0",
    polygon: [
      -30.864,
      -40.371,
      11.86,
      -31.945,
      -40.43,
      11.86,
      -31.945,
      -40.43,
      14.32,
      -31.945,
      -40.43,
      14.32,
      -30.864,
      -40.371,
      14.32,
      -30.864,
      -40.371,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "581.0",
    polygon: [
      -28.019,
      -39.621,
      2.78,
      -29.835,
      -39.716,
      2.78,
      -29.835,
      -39.716,
      3.82,
      -29.835,
      -39.716,
      3.82,
      -28.019,
      -39.621,
      3.82,
      -28.019,
      -39.621,
      2.78,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "1238.0",
    polygon: [
      2.566,
      -22.993,
      4.86,
      2.566,
      -24.324,
      4.86,
      2.566,
      -24.324,
      7.32,
      2.566,
      -24.324,
      7.32,
      2.566,
      -22.993,
      7.32,
      2.566,
      -22.993,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1702.0",
    polygon: [
      -71.982,
      -7.155,
      36.36,
      -73.182,
      -7.155,
      36.36,
      -73.182,
      -7.155,
      39.128,
      -73.182,
      -7.155,
      39.128,
      -71.982,
      -7.155,
      39.128,
      -71.982,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1276.0",
    polygon: [
      2.566,
      -15.01,
      11.86,
      2.566,
      -16.341,
      11.86,
      2.566,
      -16.341,
      14.32,
      2.566,
      -16.341,
      14.32,
      2.566,
      -15.01,
      14.32,
      2.566,
      -15.01,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1065.0",
    polygon: [
      -23.325,
      -39.362,
      9.26,
      -25.142,
      -39.46,
      9.26,
      -25.142,
      -39.46,
      10.16,
      -25.142,
      -39.46,
      10.16,
      -23.325,
      -39.362,
      10.16,
      -23.325,
      -39.362,
      9.26,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "498.0",
    polygon: [
      -33.009,
      -40.488,
      2.78,
      -35.172,
      -40.606,
      2.78,
      -35.172,
      -40.606,
      3.82,
      -35.172,
      -40.606,
      3.82,
      -33.009,
      -40.488,
      3.82,
      -33.009,
      -40.488,
      2.78,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "369.0",
    polygon: [
      -46.442,
      -40.616,
      4.077,
      -47.35,
      -40.666,
      4.077,
      -47.35,
      -40.666,
      4.323,
      -47.35,
      -40.666,
      4.323,
      -46.442,
      -40.616,
      4.323,
      -46.442,
      -40.616,
      4.077,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "536.0",
    polygon: [
      -26.216,
      -39.526,
      5.598,
      -27.125,
      -39.574,
      5.598,
      -27.125,
      -39.574,
      5.844,
      -27.125,
      -39.574,
      5.844,
      -26.216,
      -39.526,
      5.844,
      -26.216,
      -39.526,
      5.598,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "539.0",
    polygon: [
      -27.11,
      -39.573,
      5.844,
      -28.018,
      -39.621,
      5.844,
      -28.018,
      -39.621,
      6.09,
      -28.018,
      -39.621,
      6.09,
      -27.11,
      -39.573,
      6.09,
      -27.11,
      -39.573,
      5.844,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "794.0",
    polygon: [
      -56.772,
      -41.16,
      8.36,
      -57.791,
      -41.215,
      8.36,
      -57.791,
      -41.215,
      9.26,
      -57.791,
      -41.215,
      9.26,
      -56.772,
      -41.16,
      9.26,
      -56.772,
      -41.16,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "420.0",
    polygon: [
      -9.029,
      -39.185,
      4.86,
      -10.091,
      -39.241,
      4.86,
      -10.091,
      -39.241,
      7.32,
      -10.091,
      -39.241,
      7.32,
      -9.029,
      -39.185,
      7.32,
      -9.029,
      -39.185,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "789.0",
    polygon: [
      -45.545,
      -40.548,
      8.36,
      -47.361,
      -40.647,
      8.36,
      -47.361,
      -40.647,
      9.26,
      -47.361,
      -40.647,
      9.26,
      -45.545,
      -40.548,
      9.26,
      -45.545,
      -40.548,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1024.0",
    polygon: [
      -54.935,
      -41.06,
      16.46,
      -56.751,
      -41.159,
      16.46,
      -56.751,
      -41.159,
      17.36,
      -56.751,
      -41.159,
      17.36,
      -54.935,
      -41.06,
      17.36,
      -54.935,
      -41.06,
      16.46,
    ],
    "04-15 05:45": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1277.0",
    polygon: [
      2.566,
      -34.968,
      15.36,
      2.566,
      -36.298,
      15.36,
      2.566,
      -36.298,
      17.82,
      2.566,
      -36.298,
      17.82,
      2.566,
      -34.968,
      17.82,
      2.566,
      -34.968,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1239.0",
    polygon: [
      2.566,
      -21.663,
      4.86,
      2.566,
      -22.993,
      4.86,
      2.566,
      -22.993,
      7.32,
      2.566,
      -22.993,
      7.32,
      2.566,
      -21.663,
      7.32,
      2.566,
      -21.663,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1406.0",
    polygon: [
      9.428,
      -11.716,
      32.86,
      8.112,
      -11.935,
      32.86,
      8.112,
      -11.935,
      35.32,
      8.112,
      -11.935,
      35.32,
      9.428,
      -11.716,
      35.32,
      9.428,
      -11.716,
      32.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "161.0",
    polygon: [
      9.878,
      -11.694,
      0.05,
      9.148,
      -11.815,
      0.05,
      9.148,
      -11.815,
      2.78,
      9.148,
      -11.815,
      2.78,
      9.878,
      -11.694,
      2.78,
      9.878,
      -11.694,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "1183.0",
    polygon: [
      3.979,
      -12.504,
      15.36,
      2.827,
      -12.695,
      15.36,
      2.827,
      -12.695,
      17.82,
      2.827,
      -12.695,
      17.82,
      3.979,
      -12.504,
      17.82,
      3.979,
      -12.504,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1370.0",
    polygon: [
      5.479,
      -12.371,
      22.36,
      4.163,
      -12.59,
      22.36,
      4.163,
      -12.59,
      24.82,
      4.163,
      -12.59,
      24.82,
      5.479,
      -12.371,
      24.82,
      5.479,
      -12.371,
      22.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1181.0",
    polygon: [
      15.925,
      -10.522,
      15.36,
      14.773,
      -10.713,
      15.36,
      14.773,
      -10.713,
      17.82,
      14.773,
      -10.713,
      17.82,
      15.925,
      -10.522,
      17.82,
      15.925,
      -10.522,
      15.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1141.0",
    polygon: [
      -25.163,
      -39.462,
      16.46,
      -26.182,
      -39.517,
      16.46,
      -26.182,
      -39.517,
      17.36,
      -26.182,
      -39.517,
      17.36,
      -25.163,
      -39.462,
      17.36,
      -25.163,
      -39.462,
      16.46,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "458.0",
    polygon: [
      -31.945,
      -40.43,
      11.86,
      -33.026,
      -40.489,
      11.86,
      -33.026,
      -40.489,
      14.32,
      -33.026,
      -40.489,
      14.32,
      -31.945,
      -40.43,
      14.32,
      -31.945,
      -40.43,
      11.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "791.0",
    polygon: [
      -52.074,
      -40.904,
      8.36,
      -53.092,
      -40.959,
      8.36,
      -53.092,
      -40.959,
      9.26,
      -53.092,
      -40.959,
      9.26,
      -52.074,
      -40.904,
      9.26,
      -52.074,
      -40.904,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1101.0",
    polygon: [
      -25.163,
      -39.462,
      12.86,
      -26.182,
      -39.517,
      12.86,
      -26.182,
      -39.517,
      13.76,
      -26.182,
      -39.517,
      13.76,
      -25.163,
      -39.462,
      13.76,
      -25.163,
      -39.462,
      12.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "702.0",
    polygon: [
      -18.618,
      -39.128,
      3.831,
      -19.527,
      -39.176,
      3.831,
      -19.527,
      -39.176,
      4.077,
      -19.527,
      -39.176,
      4.077,
      -18.618,
      -39.128,
      4.077,
      -18.618,
      -39.128,
      3.831,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "203.0",
    polygon: [
      -54.024,
      -41.033,
      5.106,
      -54.933,
      -41.082,
      5.106,
      -54.933,
      -41.082,
      5.352,
      -54.933,
      -41.082,
      5.352,
      -54.024,
      -41.033,
      5.352,
      -54.024,
      -41.033,
      5.106,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1540.0",
    polygon: [
      1.031,
      -12.957,
      32.86,
      -0.12,
      -13.148,
      32.86,
      -0.12,
      -13.148,
      35.32,
      -0.12,
      -13.148,
      35.32,
      1.031,
      -12.957,
      35.32,
      1.031,
      -12.957,
      32.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "704.0",
    polygon: [
      -16.816,
      -39.034,
      3.831,
      -17.725,
      -39.081,
      3.831,
      -17.725,
      -39.081,
      4.077,
      -17.725,
      -39.081,
      4.077,
      -16.816,
      -39.034,
      4.077,
      -16.816,
      -39.034,
      3.831,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "205.0",
    polygon: [
      -55.827,
      -41.131,
      5.352,
      -56.735,
      -41.181,
      5.352,
      -56.735,
      -41.181,
      5.598,
      -56.735,
      -41.181,
      5.598,
      -55.827,
      -41.131,
      5.598,
      -55.827,
      -41.131,
      5.352,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1278.0",
    polygon: [
      2.566,
      -33.637,
      15.36,
      2.566,
      -34.968,
      15.36,
      2.566,
      -34.968,
      17.82,
      2.566,
      -34.968,
      17.82,
      2.566,
      -33.637,
      17.82,
      2.566,
      -33.637,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "500.0",
    polygon: [
      -41.685,
      -40.96,
      0.05,
      -42.747,
      -41.017,
      0.05,
      -42.747,
      -41.017,
      2.78,
      -42.747,
      -41.017,
      2.78,
      -41.685,
      -40.96,
      2.78,
      -41.685,
      -40.96,
      0.05,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "987.0",
    polygon: [
      -47.379,
      -40.648,
      13.76,
      -48.397,
      -40.703,
      13.76,
      -48.397,
      -40.703,
      14.66,
      -48.397,
      -40.703,
      14.66,
      -47.379,
      -40.648,
      14.66,
      -47.379,
      -40.648,
      13.76,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1144.0",
    polygon: [
      -21.509,
      -39.263,
      16.46,
      -23.325,
      -39.362,
      16.46,
      -23.325,
      -39.362,
      17.36,
      -23.325,
      -39.362,
      17.36,
      -21.509,
      -39.263,
      17.36,
      -21.509,
      -39.263,
      16.46,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1704.0",
    polygon: [
      -70.621,
      -7.155,
      36.36,
      -71.821,
      -7.155,
      36.36,
      -71.821,
      -7.155,
      39.128,
      -71.821,
      -7.155,
      39.128,
      -70.621,
      -7.155,
      39.128,
      -70.621,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "540.0",
    polygon: [
      -26.216,
      -39.526,
      5.844,
      -27.125,
      -39.574,
      5.844,
      -27.125,
      -39.574,
      6.09,
      -27.125,
      -39.574,
      6.09,
      -26.216,
      -39.526,
      6.09,
      -26.216,
      -39.526,
      5.844,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "949.0",
    polygon: [
      -52.074,
      -40.904,
      10.16,
      -53.092,
      -40.959,
      10.16,
      -53.092,
      -40.959,
      11.06,
      -53.092,
      -40.959,
      11.06,
      -52.074,
      -40.904,
      11.06,
      -52.074,
      -40.904,
      10.16,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1027.0",
    polygon: [
      -15.769,
      -38.951,
      8.36,
      -16.788,
      -39.006,
      8.36,
      -16.788,
      -39.006,
      9.26,
      -16.788,
      -39.006,
      9.26,
      -15.769,
      -38.951,
      9.26,
      -15.769,
      -38.951,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "165.0",
    polygon: [
      16.003,
      -10.691,
      2.78,
      13.753,
      -11.064,
      2.78,
      13.753,
      -11.064,
      3.82,
      13.753,
      -11.064,
      3.82,
      16.003,
      -10.691,
      3.82,
      16.003,
      -10.691,
      2.78,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1371.0",
    polygon: [
      4.163,
      -12.59,
      22.36,
      2.846,
      -12.808,
      22.36,
      2.846,
      -12.808,
      24.82,
      2.846,
      -12.808,
      24.82,
      4.163,
      -12.59,
      24.82,
      4.163,
      -12.59,
      22.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "583.0",
    polygon: [
      -28.018,
      -39.621,
      0.05,
      -29.835,
      -39.716,
      0.05,
      -29.835,
      -39.716,
      2.78,
      -29.835,
      -39.716,
      2.78,
      -28.018,
      -39.621,
      2.78,
      -28.018,
      -39.621,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "370.0",
    polygon: [
      -45.548,
      -40.567,
      4.077,
      -46.457,
      -40.617,
      4.077,
      -46.457,
      -40.617,
      4.323,
      -46.457,
      -40.617,
      4.323,
      -45.548,
      -40.567,
      4.323,
      -45.548,
      -40.567,
      4.077,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "245.0",
    polygon: [
      -55.832,
      -41.128,
      4.077,
      -56.741,
      -41.177,
      4.077,
      -56.741,
      -41.177,
      4.323,
      -56.741,
      -41.177,
      4.323,
      -55.832,
      -41.128,
      4.323,
      -55.832,
      -41.128,
      4.077,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1964.0",
    polygon: [
      -77.382,
      -7.156,
      25.86,
      -78.582,
      -7.156,
      25.86,
      -78.582,
      -7.156,
      28.32,
      -78.582,
      -7.156,
      28.32,
      -77.382,
      -7.156,
      28.32,
      -77.382,
      -7.156,
      25.86,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "462.0",
    polygon: [
      -4.497,
      -38.948,
      13.5,
      -5.897,
      -39.021,
      13.5,
      -5.897,
      -39.021,
      14.32,
      -5.897,
      -39.021,
      14.32,
      -4.497,
      -38.948,
      14.32,
      -4.497,
      -38.948,
      13.5,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "1807.0",
    polygon: [
      -30.408,
      -7.155,
      32.86,
      -31.608,
      -7.155,
      32.86,
      -31.608,
      -7.155,
      35.32,
      -31.608,
      -7.155,
      35.32,
      -30.408,
      -7.155,
      35.32,
      -30.408,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1703.0",
    polygon: [
      -71.982,
      -7.155,
      39.128,
      -73.182,
      -7.155,
      39.128,
      -73.182,
      -7.155,
      40.05,
      -73.182,
      -7.155,
      40.05,
      -71.982,
      -7.155,
      40.05,
      -71.982,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1886.0",
    polygon: [
      -18.238,
      -7.155,
      25.86,
      -19.438,
      -7.155,
      25.86,
      -19.438,
      -7.155,
      28.32,
      -19.438,
      -7.155,
      28.32,
      -18.238,
      -7.155,
      28.32,
      -18.238,
      -7.155,
      25.86,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1066.0",
    polygon: [
      -26.203,
      -39.518,
      9.26,
      -28.019,
      -39.617,
      9.26,
      -28.019,
      -39.617,
      10.16,
      -28.019,
      -39.617,
      10.16,
      -26.203,
      -39.518,
      10.16,
      -26.203,
      -39.518,
      9.26,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1847.0",
    polygon: [
      -59.304,
      -7.155,
      32.86,
      -60.504,
      -7.155,
      32.86,
      -60.504,
      -7.155,
      35.32,
      -60.504,
      -7.155,
      35.32,
      -59.304,
      -7.155,
      35.32,
      -59.304,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1925.0",
    polygon: [
      -55.247,
      -7.155,
      22.36,
      -56.447,
      -7.155,
      22.36,
      -56.447,
      -7.155,
      24.82,
      -56.447,
      -7.155,
      24.82,
      -55.247,
      -7.155,
      24.82,
      -55.247,
      -7.155,
      22.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1768.0",
    polygon: [
      -48.486,
      -7.155,
      39.128,
      -49.686,
      -7.155,
      39.128,
      -49.686,
      -7.155,
      40.05,
      -49.686,
      -7.155,
      40.05,
      -48.486,
      -7.155,
      40.05,
      -48.486,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1705.0",
    polygon: [
      -70.621,
      -7.155,
      39.128,
      -71.821,
      -7.155,
      39.128,
      -71.821,
      -7.155,
      40.05,
      -71.821,
      -7.155,
      40.05,
      -70.621,
      -7.155,
      40.05,
      -70.621,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "3061.0",
    polygon: [
      -74.947,
      11.446,
      4.86,
      -74.947,
      12.646,
      4.86,
      -74.947,
      12.646,
      7.32,
      -74.947,
      12.646,
      7.32,
      -74.947,
      11.446,
      7.32,
      -74.947,
      11.446,
      4.86,
    ],
    "04-15 14:00": 1,
  },
  {
    ID: "2535.0",
    polygon: [
      -57.145,
      -9.165,
      18.233,
      -57.145,
      -8.165,
      18.233,
      -55.936,
      -8.165,
      18.17,
      -55.936,
      -8.165,
      18.17,
      -55.936,
      -9.165,
      18.17,
      -57.145,
      -9.165,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "2652.0",
    polygon: [
      -43.895,
      -30.061,
      18.45,
      -43.895,
      -31.061,
      18.45,
      -47.869,
      -31.061,
      18.241,
      -47.869,
      -31.061,
      18.241,
      -47.869,
      -30.061,
      18.241,
      -43.895,
      -30.061,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2574.0",
    polygon: [
      -55.767,
      -29.908,
      18.161,
      -55.767,
      -28.908,
      18.161,
      -54.558,
      -28.908,
      18.098,
      -54.558,
      -28.908,
      18.098,
      -54.558,
      -29.908,
      18.098,
      -55.767,
      -29.908,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2613.0",
    polygon: [
      -50.79,
      -10.47,
      18.088,
      -50.79,
      -11.47,
      18.088,
      -51.998,
      -11.47,
      18.025,
      -51.998,
      -11.47,
      18.025,
      -51.998,
      -10.47,
      18.025,
      -50.79,
      -10.47,
      18.088,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2496.0",
    polygon: [
      -12.294,
      -31.213,
      18.45,
      -12.294,
      -32.213,
      18.45,
      -16.267,
      -32.213,
      18.241,
      -16.267,
      -32.213,
      18.241,
      -16.267,
      -31.213,
      18.241,
      -12.294,
      -31.213,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2052.0",
    polygon: [
      29.192,
      -7.154,
      22.36,
      27.992,
      -7.154,
      22.36,
      27.992,
      -7.154,
      24.82,
      27.992,
      -7.154,
      24.82,
      29.192,
      -7.154,
      24.82,
      29.192,
      -7.154,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1963.0",
    polygon: [
      -76.03,
      -7.156,
      22.36,
      -77.23,
      -7.156,
      22.36,
      -77.23,
      -7.156,
      24.82,
      -77.23,
      -7.156,
      24.82,
      -76.03,
      -7.156,
      24.82,
      -76.03,
      -7.156,
      22.36,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1924.0",
    polygon: [
      -53.895,
      -7.155,
      22.36,
      -55.095,
      -7.155,
      22.36,
      -55.095,
      -7.155,
      24.82,
      -55.095,
      -7.155,
      24.82,
      -53.895,
      -7.155,
      24.82,
      -53.895,
      -7.155,
      22.36,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2379.0",
    polygon: [
      -25.543,
      -18.384,
      18.233,
      -25.543,
      -17.384,
      18.233,
      -24.335,
      -17.384,
      18.17,
      -24.335,
      -17.384,
      18.17,
      -24.335,
      -18.384,
      18.17,
      -25.543,
      -18.384,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1806.0",
    polygon: [
      -41.744,
      -7.155,
      32.86,
      -42.944,
      -7.155,
      32.86,
      -42.944,
      -7.155,
      35.32,
      -42.944,
      -7.155,
      35.32,
      -41.744,
      -7.155,
      35.32,
      -41.744,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1885.0",
    polygon: [
      -16.886,
      -7.155,
      25.86,
      -18.086,
      -7.155,
      25.86,
      -18.086,
      -7.155,
      28.32,
      -18.086,
      -7.155,
      28.32,
      -16.886,
      -7.155,
      28.32,
      -16.886,
      -7.155,
      25.86,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1846.0",
    polygon: [
      -57.951,
      -7.155,
      32.86,
      -59.151,
      -7.155,
      32.86,
      -59.151,
      -7.155,
      35.32,
      -59.151,
      -7.155,
      35.32,
      -57.951,
      -7.155,
      35.32,
      -57.951,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1767.0",
    polygon: [
      -47.134,
      -7.155,
      36.36,
      -48.334,
      -7.155,
      36.36,
      -48.334,
      -7.155,
      39.128,
      -48.334,
      -7.155,
      39.128,
      -47.134,
      -7.155,
      39.128,
      -47.134,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2091.0",
    polygon: [
      30.545,
      -7.154,
      11.86,
      29.345,
      -7.154,
      11.86,
      29.345,
      -7.154,
      14.32,
      29.345,
      -7.154,
      14.32,
      30.545,
      -7.154,
      14.32,
      30.545,
      -7.154,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2536.0",
    polygon: [
      -55.767,
      -9.165,
      18.161,
      -55.767,
      -8.165,
      18.161,
      -54.558,
      -8.165,
      18.098,
      -54.558,
      -8.165,
      18.098,
      -54.558,
      -9.165,
      18.098,
      -55.767,
      -9.165,
      18.161,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2053.0",
    polygon: [
      27.84,
      -7.154,
      22.36,
      26.64,
      -7.154,
      22.36,
      26.64,
      -7.154,
      24.82,
      26.64,
      -7.154,
      24.82,
      27.84,
      -7.154,
      24.82,
      27.84,
      -7.154,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2002.0",
    polygon: [
      33.249,
      -7.156,
      36.36,
      32.049,
      -7.156,
      36.36,
      32.049,
      -7.156,
      39.128,
      32.049,
      -7.156,
      39.128,
      33.249,
      -7.156,
      39.128,
      33.249,
      -7.156,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2497.0",
    polygon: [
      -16.426,
      -31.213,
      18.233,
      -16.426,
      -32.213,
      18.233,
      -17.634,
      -32.213,
      18.17,
      -17.634,
      -32.213,
      18.17,
      -17.634,
      -31.213,
      18.17,
      -16.426,
      -31.213,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "423.0",
    polygon: [
      -2.497,
      -38.843,
      4.86,
      -3.578,
      -38.9,
      4.86,
      -3.578,
      -38.9,
      7.32,
      -3.578,
      -38.9,
      7.32,
      -2.497,
      -38.843,
      7.32,
      -2.497,
      -38.843,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2380.0",
    polygon: [
      -24.165,
      -18.384,
      18.161,
      -24.165,
      -17.384,
      18.161,
      -22.957,
      -17.384,
      18.098,
      -22.957,
      -17.384,
      18.098,
      -22.957,
      -18.384,
      18.098,
      -24.165,
      -18.384,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "331.0",
    polygon: [
      -44.634,
      -40.521,
      5.352,
      -45.543,
      -40.571,
      5.352,
      -45.543,
      -40.571,
      5.598,
      -45.543,
      -40.571,
      5.598,
      -44.634,
      -40.521,
      5.598,
      -44.634,
      -40.521,
      5.352,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2458.0",
    polygon: [
      -12.294,
      -18.537,
      18.45,
      -12.294,
      -19.537,
      18.45,
      -16.267,
      -19.537,
      18.241,
      -16.267,
      -19.537,
      18.241,
      -16.267,
      -18.537,
      18.241,
      -12.294,
      -18.537,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "71.0",
    polygon: [
      -71.184,
      -42.554,
      15.36,
      -72.247,
      -42.611,
      15.36,
      -72.247,
      -42.611,
      17.82,
      -72.247,
      -42.611,
      17.82,
      -71.184,
      -42.554,
      17.82,
      -71.184,
      -42.554,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "2329.0",
    polygon: [
      -11.626,
      -11.952,
      36.36,
      -11.626,
      -10.802,
      36.36,
      -11.626,
      -10.802,
      38.82,
      -11.626,
      -10.802,
      38.82,
      -11.626,
      -11.952,
      38.82,
      -11.626,
      -11.952,
      36.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1240.0",
    polygon: [
      2.566,
      -20.332,
      4.86,
      2.566,
      -21.663,
      4.86,
      2.566,
      -21.663,
      7.32,
      2.566,
      -21.663,
      7.32,
      2.566,
      -20.332,
      7.32,
      2.566,
      -20.332,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1410.0",
    polygon: [
      4.163,
      -12.59,
      32.86,
      2.846,
      -12.808,
      32.86,
      2.846,
      -12.808,
      35.32,
      2.846,
      -12.808,
      35.32,
      4.163,
      -12.59,
      35.32,
      4.163,
      -12.59,
      32.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1105.0",
    polygon: [
      -23.325,
      -39.362,
      12.86,
      -25.142,
      -39.46,
      12.86,
      -25.142,
      -39.46,
      13.76,
      -25.142,
      -39.46,
      13.76,
      -23.325,
      -39.362,
      13.76,
      -23.325,
      -39.362,
      12.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "2189.0",
    polygon: [
      27.84,
      -7.154,
      3.82,
      26.64,
      -7.154,
      3.82,
      26.64,
      -7.154,
      4.86,
      26.64,
      -7.154,
      4.86,
      27.84,
      -7.154,
      4.86,
      27.84,
      -7.154,
      3.82,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "2093.0",
    polygon: [
      27.84,
      -7.154,
      11.86,
      26.64,
      -7.154,
      11.86,
      26.64,
      -7.154,
      14.32,
      26.64,
      -7.154,
      14.32,
      27.84,
      -7.154,
      14.32,
      27.84,
      -7.154,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2733.0",
    polygon: [
      -74.884,
      -33.552,
      8.36,
      -74.884,
      -32.402,
      8.36,
      -74.884,
      -32.402,
      10.82,
      -74.884,
      -32.402,
      10.82,
      -74.884,
      -33.552,
      10.82,
      -74.884,
      -33.552,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2575.0",
    polygon: [
      -54.383,
      -29.908,
      18.088,
      -54.383,
      -28.908,
      18.088,
      -53.175,
      -28.908,
      18.025,
      -53.175,
      -28.908,
      18.025,
      -53.175,
      -29.908,
      18.025,
      -54.383,
      -29.908,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2459.0",
    polygon: [
      -16.423,
      -18.537,
      18.233,
      -16.423,
      -19.537,
      18.233,
      -20.396,
      -19.537,
      18.025,
      -20.396,
      -19.537,
      18.025,
      -20.396,
      -18.537,
      18.025,
      -16.423,
      -18.537,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "665.0",
    polygon: [
      -19.512,
      -39.175,
      5.844,
      -20.421,
      -39.223,
      5.844,
      -20.421,
      -39.223,
      6.09,
      -20.421,
      -39.223,
      6.09,
      -19.512,
      -39.175,
      6.09,
      -19.512,
      -39.175,
      5.844,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "2151.0",
    polygon: [
      -39.021,
      -15.241,
      22.36,
      -40.221,
      -15.241,
      22.36,
      -40.221,
      -15.241,
      24.82,
      -40.221,
      -15.241,
      24.82,
      -39.021,
      -15.241,
      24.82,
      -39.021,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2289.0",
    polygon: [
      -43.346,
      -7.925,
      22.36,
      -43.346,
      -7.368,
      22.36,
      -43.346,
      -7.368,
      24.82,
      -43.346,
      -7.368,
      24.82,
      -43.346,
      -7.925,
      24.82,
      -43.346,
      -7.925,
      22.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1706.0",
    polygon: [
      -69.26,
      -7.155,
      36.36,
      -70.46,
      -7.155,
      36.36,
      -70.46,
      -7.155,
      39.128,
      -70.46,
      -7.155,
      39.128,
      -69.26,
      -7.155,
      39.128,
      -69.26,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2004.0",
    polygon: [
      31.897,
      -7.156,
      36.36,
      30.697,
      -7.156,
      36.36,
      30.697,
      -7.156,
      39.128,
      30.697,
      -7.156,
      39.128,
      31.897,
      -7.156,
      39.128,
      31.897,
      -7.156,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "988.0",
    polygon: [
      -45.545,
      -40.548,
      13.76,
      -47.361,
      -40.647,
      13.76,
      -47.361,
      -40.647,
      14.66,
      -47.361,
      -40.647,
      14.66,
      -45.545,
      -40.548,
      14.66,
      -45.545,
      -40.548,
      13.76,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2693.0",
    polygon: [
      -74.887,
      -11.952,
      8.36,
      -74.887,
      -10.802,
      8.36,
      -74.887,
      -10.802,
      10.82,
      -74.887,
      -10.802,
      10.82,
      -74.887,
      -11.952,
      10.82,
      -74.887,
      -11.952,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2653.0",
    polygon: [
      -48.024,
      -30.061,
      18.233,
      -48.024,
      -31.061,
      18.233,
      -51.998,
      -31.061,
      18.025,
      -51.998,
      -31.061,
      18.025,
      -51.998,
      -30.061,
      18.025,
      -48.024,
      -30.061,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2420.0",
    polygon: [
      -29.675,
      -34.518,
      18.45,
      -29.675,
      -33.518,
      18.45,
      -25.702,
      -33.518,
      18.241,
      -25.702,
      -33.518,
      18.241,
      -25.702,
      -34.518,
      18.241,
      -29.675,
      -34.518,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2092.0",
    polygon: [
      29.192,
      -7.154,
      11.86,
      27.992,
      -7.154,
      11.86,
      27.992,
      -7.154,
      14.32,
      27.992,
      -7.154,
      14.32,
      29.192,
      -7.154,
      14.32,
      29.192,
      -7.154,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2150.0",
    polygon: [
      -40.382,
      -15.241,
      22.36,
      -41.582,
      -15.241,
      22.36,
      -41.582,
      -15.241,
      24.82,
      -41.582,
      -15.241,
      24.82,
      -40.382,
      -15.241,
      24.82,
      -40.382,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1661.0",
    polygon: [
      10.527,
      -11.542,
      36.36,
      9.343,
      -11.734,
      36.36,
      9.343,
      -11.734,
      39.128,
      9.343,
      -11.734,
      39.128,
      10.527,
      -11.542,
      39.128,
      10.527,
      -11.542,
      36.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2054.0",
    polygon: [
      26.488,
      -7.154,
      22.36,
      25.288,
      -7.154,
      22.36,
      25.288,
      -7.154,
      24.82,
      25.288,
      -7.154,
      24.82,
      26.488,
      -7.154,
      24.82,
      26.488,
      -7.154,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2694.0",
    polygon: [
      -74.887,
      -10.802,
      11.86,
      -74.887,
      -9.652,
      11.86,
      -74.887,
      -9.652,
      14.32,
      -74.887,
      -9.652,
      14.32,
      -74.887,
      -10.802,
      14.32,
      -74.887,
      -10.802,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2003.0",
    polygon: [
      31.897,
      -7.156,
      39.128,
      30.697,
      -7.156,
      39.128,
      30.697,
      -7.156,
      40.05,
      30.697,
      -7.156,
      40.05,
      31.897,
      -7.156,
      40.05,
      31.897,
      -7.156,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1965.0",
    polygon: [
      -78.734,
      -7.156,
      25.86,
      -79.934,
      -7.156,
      25.86,
      -79.934,
      -7.156,
      28.32,
      -79.934,
      -7.156,
      28.32,
      -78.734,
      -7.156,
      28.32,
      -78.734,
      -7.156,
      25.86,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2774.0",
    polygon: [
      -74.981,
      -37.964,
      4.86,
      -74.981,
      -36.464,
      4.86,
      -74.981,
      -36.464,
      7.32,
      -74.981,
      -36.464,
      7.32,
      -74.981,
      -37.964,
      7.32,
      -74.981,
      -37.964,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2868.0",
    polygon: [
      -74.886,
      23.499,
      8.36,
      -74.886,
      24.649,
      8.36,
      -74.886,
      24.649,
      10.82,
      -74.886,
      24.649,
      10.82,
      -74.886,
      23.499,
      10.82,
      -74.886,
      23.499,
      8.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2654.0",
    polygon: [
      -43.895,
      -28.908,
      18.45,
      -43.895,
      -29.908,
      18.45,
      -47.869,
      -29.908,
      18.241,
      -47.869,
      -29.908,
      18.241,
      -47.869,
      -28.908,
      18.241,
      -43.895,
      -28.908,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1279.0",
    polygon: [
      2.566,
      -32.307,
      15.36,
      2.566,
      -33.637,
      15.36,
      2.566,
      -33.637,
      17.82,
      2.566,
      -33.637,
      17.82,
      2.566,
      -32.307,
      17.82,
      2.566,
      -32.307,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2291.0",
    polygon: [
      -43.237,
      -11.952,
      22.36,
      -43.237,
      -10.802,
      22.36,
      -43.237,
      -10.802,
      24.82,
      -43.237,
      -10.802,
      24.82,
      -43.237,
      -11.952,
      24.82,
      -43.237,
      -11.952,
      22.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2822.0",
    polygon: [
      -74.961,
      -19.874,
      3.473,
      -74.961,
      -17.554,
      3.473,
      -74.961,
      -17.554,
      4.167,
      -74.961,
      -17.554,
      4.167,
      -74.961,
      -19.874,
      4.167,
      -74.961,
      -19.874,
      3.473,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2250.0",
    polygon: [
      -66.092,
      -16.256,
      25.86,
      -67.292,
      -16.256,
      25.86,
      -67.292,
      -16.256,
      28.32,
      -67.292,
      -16.256,
      28.32,
      -66.092,
      -16.256,
      28.32,
      -66.092,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1887.0",
    polygon: [
      -19.59,
      -7.155,
      25.86,
      -20.79,
      -7.155,
      25.86,
      -20.79,
      -7.155,
      28.32,
      -20.79,
      -7.155,
      28.32,
      -19.59,
      -7.155,
      28.32,
      -19.59,
      -7.155,
      25.86,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2251.0",
    polygon: [
      -63.369,
      -16.256,
      25.86,
      -64.569,
      -16.256,
      25.86,
      -64.569,
      -16.256,
      28.32,
      -64.569,
      -16.256,
      28.32,
      -63.369,
      -16.256,
      28.32,
      -63.369,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2418.0",
    polygon: [
      -29.675,
      -35.67,
      18.45,
      -29.675,
      -34.67,
      18.45,
      -25.702,
      -34.67,
      18.241,
      -25.702,
      -34.67,
      18.241,
      -25.702,
      -35.67,
      18.241,
      -29.675,
      -35.67,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2908.0",
    polygon: [
      -83.118,
      -6.717,
      32.86,
      -83.118,
      -5.517,
      32.86,
      -83.118,
      -5.517,
      35.32,
      -83.118,
      -5.517,
      35.32,
      -83.118,
      -6.717,
      35.32,
      -83.118,
      -6.717,
      32.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2498.0",
    polygon: [
      -17.804,
      -31.213,
      18.161,
      -17.804,
      -32.213,
      18.161,
      -19.012,
      -32.213,
      18.098,
      -19.012,
      -32.213,
      18.098,
      -19.012,
      -31.213,
      18.098,
      -17.804,
      -31.213,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1926.0",
    polygon: [
      -56.599,
      -7.155,
      22.36,
      -57.799,
      -7.155,
      22.36,
      -57.799,
      -7.155,
      24.82,
      -57.799,
      -7.155,
      24.82,
      -56.599,
      -7.155,
      24.82,
      -56.599,
      -7.155,
      22.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "2290.0",
    polygon: [
      -43.237,
      -10.802,
      22.36,
      -43.237,
      -9.652,
      22.36,
      -43.237,
      -9.652,
      24.82,
      -43.237,
      -9.652,
      24.82,
      -43.237,
      -10.802,
      24.82,
      -43.237,
      -10.802,
      22.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2249.0",
    polygon: [
      -69.26,
      -16.256,
      25.86,
      -70.46,
      -16.256,
      25.86,
      -70.46,
      -16.256,
      28.32,
      -70.46,
      -16.256,
      28.32,
      -69.26,
      -16.256,
      28.32,
      -69.26,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2732.0",
    polygon: [
      -74.884,
      -32.402,
      8.36,
      -74.884,
      -31.252,
      8.36,
      -74.884,
      -31.252,
      10.82,
      -74.884,
      -31.252,
      10.82,
      -74.884,
      -32.402,
      10.82,
      -74.884,
      -32.402,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "806.0",
    polygon: [
      -48.416,
      -40.704,
      8.36,
      -50.233,
      -40.803,
      8.36,
      -50.233,
      -40.803,
      9.26,
      -50.233,
      -40.803,
      9.26,
      -48.416,
      -40.704,
      9.26,
      -48.416,
      -40.704,
      8.36,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2192.0",
    polygon: [
      33.728,
      -7.154,
      2.78,
      33.401,
      -7.154,
      2.78,
      33.401,
      -7.154,
      3.82,
      33.401,
      -7.154,
      3.82,
      33.728,
      -7.154,
      3.82,
      33.728,
      -7.154,
      2.78,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2823.0",
    polygon: [
      -74.961,
      -17.554,
      2.78,
      -74.961,
      -15.234,
      2.78,
      -74.961,
      -15.234,
      3.473,
      -74.961,
      -15.234,
      3.473,
      -74.961,
      -17.554,
      3.473,
      -74.961,
      -17.554,
      2.78,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2330.0",
    polygon: [
      -74.444,
      -16.245,
      29.36,
      -74.444,
      -15.465,
      29.36,
      -74.444,
      -15.465,
      31.82,
      -74.444,
      -15.465,
      31.82,
      -74.444,
      -16.245,
      31.82,
      -74.444,
      -16.245,
      29.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2419.0",
    polygon: [
      -25.546,
      -35.67,
      18.233,
      -25.546,
      -34.67,
      18.233,
      -21.573,
      -34.67,
      18.025,
      -21.573,
      -34.67,
      18.025,
      -21.573,
      -35.67,
      18.025,
      -25.546,
      -35.67,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1848.0",
    polygon: [
      -60.656,
      -7.155,
      32.86,
      -61.856,
      -7.155,
      32.86,
      -61.856,
      -7.155,
      35.32,
      -61.856,
      -7.155,
      35.32,
      -60.656,
      -7.155,
      35.32,
      -60.656,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2328.0",
    polygon: [
      -11.626,
      -10.802,
      36.36,
      -11.626,
      -9.652,
      36.36,
      -11.626,
      -9.652,
      38.82,
      -11.626,
      -9.652,
      38.82,
      -11.626,
      -10.802,
      38.82,
      -11.626,
      -10.802,
      36.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2152.0",
    polygon: [
      -37.66,
      -15.241,
      22.36,
      -38.86,
      -15.241,
      22.36,
      -38.86,
      -15.241,
      24.82,
      -38.86,
      -15.241,
      24.82,
      -37.66,
      -15.241,
      24.82,
      -37.66,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "501.0",
    polygon: [
      -40.622,
      -40.902,
      0.05,
      -41.685,
      -40.96,
      0.05,
      -41.685,
      -40.96,
      2.78,
      -41.685,
      -40.96,
      2.78,
      -40.622,
      -40.902,
      2.78,
      -40.622,
      -40.902,
      0.05,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2773.0",
    polygon: [
      -74.981,
      -36.464,
      4.86,
      -74.981,
      -34.964,
      4.86,
      -74.981,
      -34.964,
      7.32,
      -74.981,
      -34.964,
      7.32,
      -74.981,
      -36.464,
      7.32,
      -74.981,
      -36.464,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2614.0",
    polygon: [
      -43.895,
      -9.317,
      18.45,
      -43.895,
      -10.317,
      18.45,
      -47.869,
      -10.317,
      18.241,
      -47.869,
      -10.317,
      18.241,
      -47.869,
      -9.317,
      18.241,
      -43.895,
      -9.317,
      18.45,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2869.0",
    polygon: [
      -74.886,
      22.349,
      8.36,
      -74.886,
      23.499,
      8.36,
      -74.886,
      23.499,
      10.82,
      -74.886,
      23.499,
      10.82,
      -74.886,
      22.349,
      10.82,
      -74.886,
      22.349,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2381.0",
    polygon: [
      -22.781,
      -18.384,
      18.088,
      -22.781,
      -17.384,
      18.088,
      -21.573,
      -17.384,
      18.025,
      -21.573,
      -17.384,
      18.025,
      -21.573,
      -18.384,
      18.025,
      -22.781,
      -18.384,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2695.0",
    polygon: [
      -74.887,
      -11.952,
      11.86,
      -74.887,
      -10.802,
      11.86,
      -74.887,
      -10.802,
      14.32,
      -74.887,
      -10.802,
      14.32,
      -74.887,
      -11.952,
      14.32,
      -74.887,
      -11.952,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2191.0",
    polygon: [
      33.728,
      -7.154,
      0.05,
      33.401,
      -7.154,
      0.05,
      33.401,
      -7.154,
      2.78,
      33.401,
      -7.154,
      2.78,
      33.728,
      -7.154,
      2.78,
      33.728,
      -7.154,
      0.05,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1769.0",
    polygon: [
      -48.486,
      -7.155,
      36.36,
      -49.686,
      -7.155,
      36.36,
      -49.686,
      -7.155,
      39.128,
      -49.686,
      -7.155,
      39.128,
      -48.486,
      -7.155,
      39.128,
      -48.486,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2968.0",
    polygon: [
      -83.118,
      0.681,
      32.86,
      -83.118,
      1.881,
      32.86,
      -83.118,
      1.881,
      35.32,
      -83.118,
      1.881,
      35.32,
      -83.118,
      0.681,
      35.32,
      -83.118,
      0.681,
      32.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3009.0",
    polygon: [
      -82.012,
      0.144,
      32.86,
      -83.112,
      0.144,
      32.86,
      -83.112,
      0.144,
      35.32,
      -83.112,
      0.144,
      35.32,
      -82.012,
      0.144,
      35.32,
      -82.012,
      0.144,
      32.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2576.0",
    polygon: [
      -61.277,
      -33.365,
      18.45,
      -61.277,
      -32.365,
      18.45,
      -57.304,
      -32.365,
      18.241,
      -57.304,
      -32.365,
      18.241,
      -57.304,
      -33.365,
      18.241,
      -61.277,
      -33.365,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2907.0",
    polygon: [
      -83.127,
      -6.717,
      39.128,
      -83.127,
      -5.517,
      39.128,
      -83.127,
      -5.517,
      40.05,
      -83.127,
      -5.517,
      40.05,
      -83.127,
      -6.717,
      40.05,
      -83.127,
      -6.717,
      39.128,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2615.0",
    polygon: [
      -48.024,
      -9.317,
      18.233,
      -48.024,
      -10.317,
      18.233,
      -51.998,
      -10.317,
      18.025,
      -51.998,
      -10.317,
      18.025,
      -51.998,
      -9.317,
      18.025,
      -48.024,
      -9.317,
      18.233,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2537.0",
    polygon: [
      -54.383,
      -9.165,
      18.088,
      -54.383,
      -8.165,
      18.088,
      -53.175,
      -8.165,
      18.025,
      -53.175,
      -8.165,
      18.025,
      -53.175,
      -9.165,
      18.025,
      -54.383,
      -9.165,
      18.088,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2969.0",
    polygon: [
      -83.118,
      2.033,
      32.86,
      -83.118,
      3.233,
      32.86,
      -83.118,
      3.233,
      35.32,
      -83.118,
      3.233,
      35.32,
      -83.118,
      2.033,
      35.32,
      -83.118,
      2.033,
      32.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "206.0",
    polygon: [
      -54.933,
      -41.082,
      5.352,
      -55.842,
      -41.132,
      5.352,
      -55.842,
      -41.132,
      5.598,
      -55.842,
      -41.132,
      5.598,
      -54.933,
      -41.082,
      5.598,
      -54.933,
      -41.082,
      5.352,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1808.0",
    polygon: [
      -40.382,
      -7.155,
      32.86,
      -41.582,
      -7.155,
      32.86,
      -41.582,
      -7.155,
      35.32,
      -41.582,
      -7.155,
      35.32,
      -40.382,
      -7.155,
      35.32,
      -40.382,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2292.0",
    polygon: [
      -74.807,
      -10.802,
      22.36,
      -74.807,
      -9.652,
      22.36,
      -74.807,
      -9.652,
      24.82,
      -74.807,
      -9.652,
      24.82,
      -74.807,
      -10.802,
      24.82,
      -74.807,
      -10.802,
      22.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2460.0",
    polygon: [
      -12.294,
      -17.384,
      18.45,
      -12.294,
      -18.384,
      18.45,
      -16.267,
      -18.384,
      18.241,
      -16.267,
      -18.384,
      18.241,
      -16.267,
      -17.384,
      18.241,
      -12.294,
      -17.384,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2193.0",
    polygon: [
      33.728,
      -7.154,
      3.82,
      33.401,
      -7.154,
      3.82,
      33.401,
      -7.154,
      4.86,
      33.401,
      -7.154,
      4.86,
      33.728,
      -7.154,
      4.86,
      33.728,
      -7.154,
      3.82,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2252.0",
    polygon: [
      -64.731,
      -16.256,
      25.86,
      -65.931,
      -16.256,
      25.86,
      -65.931,
      -16.256,
      28.32,
      -65.931,
      -16.256,
      28.32,
      -64.731,
      -16.256,
      28.32,
      -64.731,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2153.0",
    polygon: [
      -34.492,
      -15.241,
      22.36,
      -35.692,
      -15.241,
      22.36,
      -35.692,
      -15.241,
      24.82,
      -35.692,
      -15.241,
      24.82,
      -34.492,
      -15.241,
      24.82,
      -34.492,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2094.0",
    polygon: [
      26.488,
      -7.154,
      11.86,
      25.288,
      -7.154,
      11.86,
      25.288,
      -7.154,
      14.32,
      25.288,
      -7.154,
      14.32,
      26.488,
      -7.154,
      14.32,
      26.488,
      -7.154,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2005.0",
    polygon: [
      30.545,
      -7.156,
      39.128,
      29.345,
      -7.156,
      39.128,
      29.345,
      -7.156,
      40.05,
      29.345,
      -7.156,
      40.05,
      30.545,
      -7.156,
      40.05,
      30.545,
      -7.156,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2055.0",
    polygon: [
      25.136,
      -7.154,
      22.36,
      23.936,
      -7.154,
      22.36,
      23.936,
      -7.154,
      24.82,
      23.936,
      -7.154,
      24.82,
      25.136,
      -7.154,
      24.82,
      25.136,
      -7.154,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2154.0",
    polygon: [
      -33.131,
      -15.241,
      22.36,
      -34.331,
      -15.241,
      22.36,
      -34.331,
      -15.241,
      24.82,
      -34.331,
      -15.241,
      24.82,
      -33.131,
      -15.241,
      24.82,
      -33.131,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2616.0",
    polygon: [
      -43.895,
      -8.165,
      18.45,
      -43.895,
      -9.165,
      18.45,
      -47.869,
      -9.165,
      18.241,
      -47.869,
      -9.165,
      18.241,
      -47.869,
      -8.165,
      18.241,
      -43.895,
      -8.165,
      18.45,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2577.0",
    polygon: [
      -57.148,
      -33.365,
      18.233,
      -57.148,
      -32.365,
      18.233,
      -53.175,
      -32.365,
      18.025,
      -53.175,
      -32.365,
      18.025,
      -53.175,
      -33.365,
      18.025,
      -57.148,
      -33.365,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 11:45": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2499.0",
    polygon: [
      -19.188,
      -31.213,
      18.088,
      -19.188,
      -32.213,
      18.088,
      -20.396,
      -32.213,
      18.025,
      -20.396,
      -32.213,
      18.025,
      -20.396,
      -31.213,
      18.025,
      -19.188,
      -31.213,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2655.0",
    polygon: [
      -48.028,
      -28.908,
      18.233,
      -48.028,
      -29.908,
      18.233,
      -49.236,
      -29.908,
      18.17,
      -49.236,
      -29.908,
      18.17,
      -49.236,
      -28.908,
      18.17,
      -48.028,
      -28.908,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2331.0",
    polygon: [
      -62.108,
      -15.453,
      29.36,
      -62.108,
      -16.233,
      29.36,
      -62.108,
      -16.233,
      31.82,
      -62.108,
      -16.233,
      31.82,
      -62.108,
      -15.453,
      31.82,
      -62.108,
      -15.453,
      29.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "2421.0",
    polygon: [
      -25.543,
      -34.518,
      18.233,
      -25.543,
      -33.518,
      18.233,
      -24.335,
      -33.518,
      18.17,
      -24.335,
      -33.518,
      18.17,
      -24.335,
      -34.518,
      18.17,
      -25.543,
      -34.518,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2382.0",
    polygon: [
      -29.675,
      -21.841,
      18.45,
      -29.675,
      -20.841,
      18.45,
      -25.702,
      -20.841,
      18.241,
      -25.702,
      -20.841,
      18.241,
      -25.702,
      -21.841,
      18.241,
      -29.675,
      -21.841,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1707.0",
    polygon: [
      -69.26,
      -7.155,
      39.128,
      -70.46,
      -7.155,
      39.128,
      -70.46,
      -7.155,
      40.05,
      -70.46,
      -7.155,
      40.05,
      -69.26,
      -7.155,
      40.05,
      -69.26,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2826.0",
    polygon: [
      -74.961,
      -19.874,
      -0.35,
      -74.961,
      -19.054,
      -0.35,
      -74.961,
      -19.054,
      2.78,
      -74.961,
      -19.054,
      2.78,
      -74.961,
      -19.874,
      2.78,
      -74.961,
      -19.874,
      -0.35,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2872.0",
    polygon: [
      -74.886,
      28.899,
      8.36,
      -74.886,
      30.049,
      8.36,
      -74.886,
      30.049,
      10.82,
      -74.886,
      30.049,
      10.82,
      -74.886,
      28.899,
      10.82,
      -74.886,
      28.899,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2293.0",
    polygon: [
      -74.807,
      -11.952,
      22.36,
      -74.807,
      -10.802,
      22.36,
      -74.807,
      -10.802,
      24.82,
      -74.807,
      -10.802,
      24.82,
      -74.807,
      -11.952,
      24.82,
      -74.807,
      -11.952,
      22.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2696.0",
    polygon: [
      -74.887,
      -10.802,
      15.36,
      -74.887,
      -9.652,
      15.36,
      -74.887,
      -9.652,
      17.82,
      -74.887,
      -9.652,
      17.82,
      -74.887,
      -10.802,
      17.82,
      -74.887,
      -10.802,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2383.0",
    polygon: [
      -25.546,
      -21.841,
      18.233,
      -25.546,
      -20.841,
      18.233,
      -21.573,
      -20.841,
      18.025,
      -21.573,
      -20.841,
      18.025,
      -21.573,
      -21.841,
      18.025,
      -25.546,
      -21.841,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2824.0",
    polygon: [
      -74.961,
      -17.554,
      3.473,
      -74.961,
      -15.234,
      3.473,
      -74.961,
      -15.234,
      4.167,
      -74.961,
      -15.234,
      4.167,
      -74.961,
      -17.554,
      4.167,
      -74.961,
      -17.554,
      3.473,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2736.0",
    polygon: [
      -74.884,
      -37.801,
      8.36,
      -74.884,
      -36.651,
      8.36,
      -74.884,
      -36.651,
      10.82,
      -74.884,
      -36.651,
      10.82,
      -74.884,
      -37.801,
      10.82,
      -74.884,
      -37.801,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2253.0",
    polygon: [
      -68.276,
      -15.241,
      18.86,
      -69.098,
      -15.241,
      18.86,
      -69.098,
      -15.241,
      21.32,
      -69.098,
      -15.241,
      21.32,
      -68.276,
      -15.241,
      21.32,
      -68.276,
      -15.241,
      18.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2734.0",
    polygon: [
      -74.884,
      -35.101,
      8.36,
      -74.884,
      -33.951,
      8.36,
      -74.884,
      -33.951,
      10.82,
      -74.884,
      -33.951,
      10.82,
      -74.884,
      -35.101,
      10.82,
      -74.884,
      -35.101,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2056.0",
    polygon: [
      37.305,
      -7.154,
      25.86,
      36.105,
      -7.154,
      25.86,
      36.105,
      -7.154,
      28.32,
      36.105,
      -7.154,
      28.32,
      37.305,
      -7.154,
      28.32,
      37.305,
      -7.154,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2095.0",
    polygon: [
      25.136,
      -7.154,
      11.86,
      23.936,
      -7.154,
      11.86,
      23.936,
      -7.154,
      14.32,
      23.936,
      -7.154,
      14.32,
      25.136,
      -7.154,
      14.32,
      25.136,
      -7.154,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1850.0",
    polygon: [
      -44.43,
      -7.155,
      32.86,
      -45.63,
      -7.155,
      32.86,
      -45.63,
      -7.155,
      35.32,
      -45.63,
      -7.155,
      35.32,
      -44.43,
      -7.155,
      35.32,
      -44.43,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2617.0",
    polygon: [
      -48.028,
      -8.165,
      18.233,
      -48.028,
      -9.165,
      18.233,
      -49.236,
      -9.165,
      18.17,
      -49.236,
      -9.165,
      18.17,
      -49.236,
      -8.165,
      18.17,
      -48.028,
      -8.165,
      18.233,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1810.0",
    polygon: [
      -37.66,
      -7.155,
      32.86,
      -38.86,
      -7.155,
      32.86,
      -38.86,
      -7.155,
      35.32,
      -38.86,
      -7.155,
      35.32,
      -37.66,
      -7.155,
      35.32,
      -37.66,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2971.0",
    polygon: [
      -83.118,
      4.738,
      32.86,
      -83.118,
      5.938,
      32.86,
      -83.118,
      5.938,
      35.32,
      -83.118,
      5.938,
      35.32,
      -83.118,
      4.738,
      35.32,
      -83.118,
      4.738,
      32.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "1888.0",
    polygon: [
      -20.943,
      -7.155,
      25.86,
      -22.143,
      -7.155,
      25.86,
      -22.143,
      -7.155,
      28.32,
      -22.143,
      -7.155,
      28.32,
      -20.943,
      -7.155,
      28.32,
      -20.943,
      -7.155,
      25.86,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "3012.0",
    polygon: [
      -82.012,
      0.144,
      8.36,
      -83.112,
      0.144,
      8.36,
      -83.112,
      0.144,
      10.82,
      -83.112,
      0.144,
      10.82,
      -82.012,
      0.144,
      10.82,
      -82.012,
      0.144,
      8.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2697.0",
    polygon: [
      -74.887,
      -11.952,
      15.36,
      -74.887,
      -10.802,
      15.36,
      -74.887,
      -10.802,
      17.82,
      -74.887,
      -10.802,
      17.82,
      -74.887,
      -11.952,
      17.82,
      -74.887,
      -11.952,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2775.0",
    polygon: [
      -74.981,
      -38.784,
      4.86,
      -74.981,
      -37.964,
      4.86,
      -74.981,
      -37.964,
      7.32,
      -74.981,
      -37.964,
      7.32,
      -74.981,
      -38.784,
      7.32,
      -74.981,
      -38.784,
      4.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1889.0",
    polygon: [
      -22.295,
      -7.155,
      25.86,
      -23.495,
      -7.155,
      25.86,
      -23.495,
      -7.155,
      28.32,
      -23.495,
      -7.155,
      28.32,
      -22.295,
      -7.155,
      28.32,
      -22.295,
      -7.155,
      25.86,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "3011.0",
    polygon: [
      -81.761,
      -0.692,
      32.86,
      -81.761,
      -0.112,
      32.86,
      -81.761,
      -0.112,
      35.32,
      -81.761,
      -0.112,
      35.32,
      -81.761,
      -0.692,
      35.32,
      -81.761,
      -0.692,
      32.86,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2578.0",
    polygon: [
      -61.277,
      -32.213,
      18.45,
      -61.277,
      -31.213,
      18.45,
      -57.304,
      -31.213,
      18.241,
      -57.304,
      -31.213,
      18.241,
      -57.304,
      -32.213,
      18.241,
      -61.277,
      -32.213,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2777.0",
    polygon: [
      -74.981,
      -29.702,
      4.86,
      -74.981,
      -28.202,
      4.86,
      -74.981,
      -28.202,
      7.32,
      -74.981,
      -28.202,
      7.32,
      -74.981,
      -29.702,
      7.32,
      -74.981,
      -29.702,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2194.0",
    polygon: [
      33.728,
      -7.154,
      4.86,
      33.401,
      -7.154,
      4.86,
      33.401,
      -7.154,
      7.32,
      33.401,
      -7.154,
      7.32,
      33.728,
      -7.154,
      7.32,
      33.728,
      -7.154,
      4.86,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1770.0",
    polygon: [
      -49.838,
      -7.155,
      39.128,
      -51.038,
      -7.155,
      39.128,
      -51.038,
      -7.155,
      40.05,
      -51.038,
      -7.155,
      40.05,
      -49.838,
      -7.155,
      40.05,
      -49.838,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1771.0",
    polygon: [
      -49.838,
      -7.155,
      36.36,
      -51.038,
      -7.155,
      36.36,
      -51.038,
      -7.155,
      39.128,
      -51.038,
      -7.155,
      39.128,
      -49.838,
      -7.155,
      39.128,
      -49.838,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2911.0",
    polygon: [
      -83.127,
      -5.365,
      39.128,
      -83.127,
      -4.165,
      39.128,
      -83.127,
      -4.165,
      40.05,
      -83.127,
      -4.165,
      40.05,
      -83.127,
      -5.365,
      40.05,
      -83.127,
      -5.365,
      39.128,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2500.0",
    polygon: [
      -12.294,
      -34.67,
      18.45,
      -12.294,
      -35.67,
      18.45,
      -16.267,
      -35.67,
      18.241,
      -16.267,
      -35.67,
      18.241,
      -16.267,
      -34.67,
      18.241,
      -12.294,
      -34.67,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2972.0",
    polygon: [
      -83.118,
      0.681,
      8.36,
      -83.118,
      1.881,
      8.36,
      -83.118,
      1.881,
      10.82,
      -83.118,
      1.881,
      10.82,
      -83.118,
      0.681,
      10.82,
      -83.118,
      0.681,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1708.0",
    polygon: [
      -66.092,
      -7.155,
      36.36,
      -67.292,
      -7.155,
      36.36,
      -67.292,
      -7.155,
      39.128,
      -67.292,
      -7.155,
      39.128,
      -66.092,
      -7.155,
      39.128,
      -66.092,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2910.0",
    polygon: [
      -83.127,
      -5.365,
      36.36,
      -83.127,
      -4.165,
      36.36,
      -83.127,
      -4.165,
      39.128,
      -83.127,
      -4.165,
      39.128,
      -83.127,
      -5.365,
      39.128,
      -83.127,
      -5.365,
      36.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2871.0",
    polygon: [
      -74.886,
      25.049,
      8.36,
      -74.886,
      26.199,
      8.36,
      -74.886,
      26.199,
      10.82,
      -74.886,
      26.199,
      10.82,
      -74.886,
      25.049,
      10.82,
      -74.886,
      25.049,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2332.0",
    polygon: [
      -42.844,
      -16.245,
      29.36,
      -42.844,
      -15.465,
      29.36,
      -42.844,
      -15.465,
      31.82,
      -42.844,
      -15.465,
      31.82,
      -42.844,
      -16.245,
      31.82,
      -42.844,
      -16.245,
      29.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2422.0",
    polygon: [
      -24.165,
      -34.518,
      18.161,
      -24.165,
      -33.518,
      18.161,
      -22.957,
      -33.518,
      18.098,
      -22.957,
      -33.518,
      18.098,
      -22.957,
      -34.518,
      18.098,
      -24.165,
      -34.518,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2827.0",
    polygon: [
      -74.961,
      -16.394,
      -0.35,
      -74.961,
      -15.234,
      -0.35,
      -74.961,
      -15.234,
      2.78,
      -74.961,
      -15.234,
      2.78,
      -74.961,
      -16.394,
      2.78,
      -74.961,
      -16.394,
      -0.35,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2735.0",
    polygon: [
      -74.884,
      -36.251,
      8.36,
      -74.884,
      -35.101,
      8.36,
      -74.884,
      -35.101,
      10.82,
      -74.884,
      -35.101,
      10.82,
      -74.884,
      -36.251,
      10.82,
      -74.884,
      -36.251,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2461.0",
    polygon: [
      -16.426,
      -17.384,
      18.233,
      -16.426,
      -18.384,
      18.233,
      -17.634,
      -18.384,
      18.17,
      -17.634,
      -18.384,
      18.17,
      -17.634,
      -17.384,
      18.17,
      -16.426,
      -17.384,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2870.0",
    polygon: [
      -74.886,
      26.199,
      8.36,
      -74.886,
      27.349,
      8.36,
      -74.886,
      27.349,
      10.82,
      -74.886,
      27.349,
      10.82,
      -74.886,
      26.199,
      10.82,
      -74.886,
      26.199,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2912.0",
    polygon: [
      -83.127,
      -4.012,
      36.36,
      -83.127,
      -2.812,
      36.36,
      -83.127,
      -2.812,
      39.128,
      -83.127,
      -2.812,
      39.128,
      -83.127,
      -4.012,
      39.128,
      -83.127,
      -4.012,
      36.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2656.0",
    polygon: [
      -49.406,
      -28.908,
      18.161,
      -49.406,
      -29.908,
      18.161,
      -50.614,
      -29.908,
      18.098,
      -50.614,
      -29.908,
      18.098,
      -50.614,
      -28.908,
      18.098,
      -49.406,
      -28.908,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3067.0",
    polygon: [
      -74.947,
      12.798,
      4.86,
      -74.947,
      13.698,
      4.86,
      -74.947,
      13.698,
      7.32,
      -74.947,
      13.698,
      7.32,
      -74.947,
      12.798,
      7.32,
      -74.947,
      12.798,
      4.86,
    ],
    "04-15 14:15": 1,
  },
  {
    ID: "1966.0",
    polygon: [
      -80.086,
      -7.156,
      25.86,
      -81.286,
      -7.156,
      25.86,
      -81.286,
      -7.156,
      28.32,
      -81.286,
      -7.156,
      28.32,
      -80.086,
      -7.156,
      28.32,
      -80.086,
      -7.156,
      25.86,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2006.0",
    polygon: [
      30.545,
      -7.156,
      36.36,
      29.345,
      -7.156,
      36.36,
      29.345,
      -7.156,
      39.128,
      29.345,
      -7.156,
      39.128,
      30.545,
      -7.156,
      39.128,
      30.545,
      -7.156,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1809.0",
    polygon: [
      -39.021,
      -7.155,
      32.86,
      -40.221,
      -7.155,
      32.86,
      -40.221,
      -7.155,
      35.32,
      -40.221,
      -7.155,
      35.32,
      -39.021,
      -7.155,
      35.32,
      -39.021,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1967.0",
    polygon: [
      -81.438,
      -7.156,
      25.86,
      -82.638,
      -7.156,
      25.86,
      -82.638,
      -7.156,
      28.32,
      -82.638,
      -7.156,
      28.32,
      -81.438,
      -7.156,
      28.32,
      -81.438,
      -7.156,
      25.86,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1927.0",
    polygon: [
      -57.951,
      -7.155,
      22.36,
      -59.151,
      -7.155,
      22.36,
      -59.151,
      -7.155,
      24.82,
      -59.151,
      -7.155,
      24.82,
      -57.951,
      -7.155,
      24.82,
      -57.951,
      -7.155,
      22.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "1928.0",
    polygon: [
      -59.304,
      -7.155,
      22.36,
      -60.504,
      -7.155,
      22.36,
      -60.504,
      -7.155,
      24.82,
      -60.504,
      -7.155,
      24.82,
      -59.304,
      -7.155,
      24.82,
      -59.304,
      -7.155,
      22.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2970.0",
    polygon: [
      -83.118,
      3.386,
      32.86,
      -83.118,
      4.586,
      32.86,
      -83.118,
      4.586,
      35.32,
      -83.118,
      4.586,
      35.32,
      -83.118,
      3.386,
      35.32,
      -83.118,
      3.386,
      32.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "1849.0",
    polygon: [
      -43.077,
      -7.155,
      32.86,
      -44.277,
      -7.155,
      32.86,
      -44.277,
      -7.155,
      35.32,
      -44.277,
      -7.155,
      35.32,
      -43.077,
      -7.155,
      35.32,
      -43.077,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2776.0",
    polygon: [
      -74.981,
      -31.202,
      4.86,
      -74.981,
      -29.702,
      4.86,
      -74.981,
      -29.702,
      7.32,
      -74.981,
      -29.702,
      7.32,
      -74.981,
      -31.202,
      7.32,
      -74.981,
      -31.202,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1929.0",
    polygon: [
      -44.43,
      -7.155,
      22.36,
      -45.63,
      -7.155,
      22.36,
      -45.63,
      -7.155,
      24.82,
      -45.63,
      -7.155,
      24.82,
      -44.43,
      -7.155,
      24.82,
      -44.43,
      -7.155,
      22.36,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1811.0",
    polygon: [
      -34.492,
      -7.155,
      32.86,
      -35.692,
      -7.155,
      32.86,
      -35.692,
      -7.155,
      35.32,
      -35.692,
      -7.155,
      35.32,
      -34.492,
      -7.155,
      35.32,
      -34.492,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1890.0",
    polygon: [
      -23.647,
      -7.155,
      25.86,
      -24.847,
      -7.155,
      25.86,
      -24.847,
      -7.155,
      28.32,
      -24.847,
      -7.155,
      28.32,
      -23.647,
      -7.155,
      28.32,
      -23.647,
      -7.155,
      25.86,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1772.0",
    polygon: [
      -51.19,
      -7.155,
      39.128,
      -52.39,
      -7.155,
      39.128,
      -52.39,
      -7.155,
      40.05,
      -52.39,
      -7.155,
      40.05,
      -51.19,
      -7.155,
      40.05,
      -51.19,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1709.0",
    polygon: [
      -66.092,
      -7.155,
      39.128,
      -67.292,
      -7.155,
      39.128,
      -67.292,
      -7.155,
      40.05,
      -67.292,
      -7.155,
      40.05,
      -66.092,
      -7.155,
      40.05,
      -66.092,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2057.0",
    polygon: [
      35.953,
      -7.154,
      25.86,
      34.753,
      -7.154,
      25.86,
      34.753,
      -7.154,
      28.32,
      34.753,
      -7.154,
      28.32,
      35.953,
      -7.154,
      28.32,
      35.953,
      -7.154,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1968.0",
    polygon: [
      -76.03,
      -7.156,
      25.86,
      -77.23,
      -7.156,
      25.86,
      -77.23,
      -7.156,
      28.32,
      -77.23,
      -7.156,
      28.32,
      -76.03,
      -7.156,
      28.32,
      -76.03,
      -7.156,
      25.86,
    ],
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1851.0",
    polygon: [
      -77.382,
      -7.156,
      32.86,
      -78.582,
      -7.156,
      32.86,
      -78.582,
      -7.156,
      35.32,
      -78.582,
      -7.156,
      35.32,
      -77.382,
      -7.156,
      35.32,
      -77.382,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2096.0",
    polygon: [
      37.305,
      -7.154,
      15.36,
      36.105,
      -7.154,
      15.36,
      36.105,
      -7.154,
      17.82,
      36.105,
      -7.154,
      17.82,
      37.305,
      -7.154,
      17.82,
      37.305,
      -7.154,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1710.0",
    polygon: [
      -64.731,
      -6.955,
      36.36,
      -65.931,
      -6.955,
      36.36,
      -65.931,
      -6.955,
      39.128,
      -65.931,
      -6.955,
      39.128,
      -64.731,
      -6.955,
      39.128,
      -64.731,
      -6.955,
      36.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2540.0",
    polygon: [
      -61.277,
      -19.537,
      18.45,
      -61.277,
      -18.537,
      18.45,
      -57.304,
      -18.537,
      18.241,
      -57.304,
      -18.537,
      18.241,
      -57.304,
      -19.537,
      18.241,
      -61.277,
      -19.537,
      18.45,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2384.0",
    polygon: [
      -29.675,
      -20.689,
      18.45,
      -29.675,
      -19.689,
      18.45,
      -25.702,
      -19.689,
      18.241,
      -25.702,
      -19.689,
      18.241,
      -25.702,
      -20.689,
      18.241,
      -29.675,
      -20.689,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2698.0",
    polygon: [
      -74.884,
      -16.202,
      15.36,
      -74.884,
      -15.052,
      15.36,
      -74.884,
      -15.052,
      17.82,
      -74.884,
      -15.052,
      17.82,
      -74.884,
      -16.202,
      17.82,
      -74.884,
      -16.202,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2424.0",
    polygon: [
      -29.675,
      -37.975,
      18.45,
      -29.675,
      -36.975,
      18.45,
      -25.702,
      -36.975,
      18.241,
      -25.702,
      -36.975,
      18.241,
      -25.702,
      -37.975,
      18.241,
      -29.675,
      -37.975,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1852.0",
    polygon: [
      -78.734,
      -7.156,
      32.86,
      -79.934,
      -7.156,
      32.86,
      -79.934,
      -7.156,
      35.32,
      -79.934,
      -7.156,
      35.32,
      -78.734,
      -7.156,
      35.32,
      -78.734,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2913.0",
    polygon: [
      -83.127,
      -4.012,
      39.128,
      -83.127,
      -2.812,
      39.128,
      -83.127,
      -2.812,
      40.05,
      -83.127,
      -2.812,
      40.05,
      -83.127,
      -4.012,
      40.05,
      -83.127,
      -4.012,
      39.128,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2254.0",
    polygon: [
      -67.453,
      -15.241,
      18.86,
      -68.276,
      -15.241,
      18.86,
      -68.276,
      -15.241,
      21.32,
      -68.276,
      -15.241,
      21.32,
      -67.453,
      -15.241,
      21.32,
      -67.453,
      -15.241,
      18.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2255.0",
    polygon: [
      -73.344,
      -15.241,
      18.86,
      -74.544,
      -15.241,
      18.86,
      -74.544,
      -15.241,
      21.32,
      -74.544,
      -15.241,
      21.32,
      -73.344,
      -15.241,
      21.32,
      -73.344,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2657.0",
    polygon: [
      -50.79,
      -28.908,
      18.088,
      -50.79,
      -29.908,
      18.088,
      -51.998,
      -29.908,
      18.025,
      -51.998,
      -29.908,
      18.025,
      -51.998,
      -28.908,
      18.025,
      -50.79,
      -28.908,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1812.0",
    polygon: [
      -33.131,
      -7.155,
      32.86,
      -34.331,
      -7.155,
      32.86,
      -34.331,
      -7.155,
      35.32,
      -34.331,
      -7.155,
      35.32,
      -33.131,
      -7.155,
      35.32,
      -33.131,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2737.0",
    polygon: [
      -74.884,
      -38.951,
      8.36,
      -74.884,
      -37.801,
      8.36,
      -74.884,
      -37.801,
      10.82,
      -74.884,
      -37.801,
      10.82,
      -74.884,
      -38.951,
      10.82,
      -74.884,
      -38.951,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2385.0",
    polygon: [
      -25.543,
      -20.689,
      18.233,
      -25.543,
      -19.689,
      18.233,
      -24.335,
      -19.689,
      18.17,
      -24.335,
      -19.689,
      18.17,
      -24.335,
      -20.689,
      18.17,
      -25.543,
      -20.689,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2007.0",
    polygon: [
      29.192,
      -7.156,
      39.128,
      27.992,
      -7.156,
      39.128,
      27.992,
      -7.156,
      40.05,
      27.992,
      -7.156,
      40.05,
      29.192,
      -7.156,
      40.05,
      29.192,
      -7.156,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1891.0",
    polygon: [
      -24.999,
      -7.155,
      25.86,
      -26.199,
      -7.155,
      25.86,
      -26.199,
      -7.155,
      28.32,
      -26.199,
      -7.155,
      28.32,
      -24.999,
      -7.155,
      28.32,
      -24.999,
      -7.155,
      25.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2828.0",
    polygon: [
      -74.867,
      -5.235,
      2.78,
      -74.867,
      -2.915,
      2.78,
      -74.867,
      -2.915,
      3.473,
      -74.867,
      -2.915,
      3.473,
      -74.867,
      -5.235,
      3.473,
      -74.867,
      -5.235,
      2.78,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2501.0",
    polygon: [
      -16.423,
      -34.67,
      18.233,
      -16.423,
      -35.67,
      18.233,
      -20.396,
      -35.67,
      18.025,
      -20.396,
      -35.67,
      18.025,
      -20.396,
      -34.67,
      18.025,
      -16.423,
      -34.67,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2155.0",
    polygon: [
      -31.769,
      -15.241,
      22.36,
      -32.969,
      -15.241,
      22.36,
      -32.969,
      -15.241,
      24.82,
      -32.969,
      -15.241,
      24.82,
      -31.769,
      -15.241,
      24.82,
      -31.769,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1773.0",
    polygon: [
      -51.19,
      -7.155,
      36.36,
      -52.39,
      -7.155,
      36.36,
      -52.39,
      -7.155,
      39.128,
      -52.39,
      -7.155,
      39.128,
      -51.19,
      -7.155,
      39.128,
      -51.19,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2333.0",
    polygon: [
      -30.508,
      -15.453,
      29.36,
      -30.508,
      -16.233,
      29.36,
      -30.508,
      -16.233,
      31.82,
      -30.508,
      -16.233,
      31.82,
      -30.508,
      -15.453,
      31.82,
      -30.508,
      -15.453,
      29.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "2097.0",
    polygon: [
      35.953,
      -7.154,
      15.36,
      34.753,
      -7.154,
      15.36,
      34.753,
      -7.154,
      17.82,
      34.753,
      -7.154,
      17.82,
      35.953,
      -7.154,
      17.82,
      35.953,
      -7.154,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2699.0",
    polygon: [
      -74.884,
      -17.352,
      15.36,
      -74.884,
      -16.202,
      15.36,
      -74.884,
      -16.202,
      17.82,
      -74.884,
      -16.202,
      17.82,
      -74.884,
      -17.352,
      17.82,
      -74.884,
      -17.352,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2196.0",
    polygon: [
      -75.269,
      -7.052,
      29.36,
      -75.826,
      -7.052,
      29.36,
      -75.826,
      -7.052,
      31.82,
      -75.826,
      -7.052,
      31.82,
      -75.269,
      -7.052,
      31.82,
      -75.269,
      -7.052,
      29.36,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2619.0",
    polygon: [
      -50.79,
      -8.165,
      18.088,
      -50.79,
      -9.165,
      18.088,
      -51.998,
      -9.165,
      18.025,
      -51.998,
      -9.165,
      18.025,
      -51.998,
      -8.165,
      18.025,
      -50.79,
      -8.165,
      18.088,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2580.0",
    polygon: [
      -55.767,
      -32.213,
      18.161,
      -55.767,
      -31.213,
      18.161,
      -54.558,
      -31.213,
      18.098,
      -54.558,
      -31.213,
      18.098,
      -54.558,
      -32.213,
      18.098,
      -55.767,
      -32.213,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2463.0",
    polygon: [
      -19.188,
      -17.384,
      18.088,
      -19.188,
      -18.384,
      18.088,
      -20.396,
      -18.384,
      18.025,
      -20.396,
      -18.384,
      18.025,
      -20.396,
      -17.384,
      18.025,
      -19.188,
      -17.384,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2973.0",
    polygon: [
      -83.118,
      2.033,
      8.36,
      -83.118,
      3.233,
      8.36,
      -83.118,
      3.233,
      10.82,
      -83.118,
      3.233,
      10.82,
      -83.118,
      2.033,
      10.82,
      -83.118,
      2.033,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2295.0",
    polygon: [
      -11.74,
      -7.925,
      25.86,
      -11.74,
      -7.368,
      25.86,
      -11.74,
      -7.368,
      28.32,
      -11.74,
      -7.368,
      28.32,
      -11.74,
      -7.925,
      28.32,
      -11.74,
      -7.925,
      25.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2502.0",
    polygon: [
      -12.294,
      -33.518,
      18.45,
      -12.294,
      -34.518,
      18.45,
      -16.267,
      -34.518,
      18.241,
      -16.267,
      -34.518,
      18.241,
      -16.267,
      -33.518,
      18.241,
      -12.294,
      -33.518,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2195.0",
    polygon: [
      -43.64,
      -6.961,
      29.36,
      -44.197,
      -6.961,
      29.36,
      -44.197,
      -6.961,
      31.82,
      -44.197,
      -6.961,
      31.82,
      -43.64,
      -6.961,
      31.82,
      -43.64,
      -6.961,
      29.36,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2058.0",
    polygon: [
      34.601,
      -7.154,
      25.86,
      33.401,
      -7.154,
      25.86,
      33.401,
      -7.154,
      28.32,
      33.401,
      -7.154,
      28.32,
      34.601,
      -7.154,
      28.32,
      34.601,
      -7.154,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2658.0",
    polygon: [
      -43.895,
      -32.365,
      18.45,
      -43.895,
      -33.365,
      18.45,
      -47.869,
      -33.365,
      18.241,
      -47.869,
      -33.365,
      18.241,
      -47.869,
      -32.365,
      18.241,
      -43.895,
      -32.365,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2462.0",
    polygon: [
      -17.804,
      -17.384,
      18.161,
      -17.804,
      -18.384,
      18.161,
      -19.012,
      -18.384,
      18.098,
      -19.012,
      -18.384,
      18.098,
      -19.012,
      -17.384,
      18.098,
      -17.804,
      -17.384,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2618.0",
    polygon: [
      -49.406,
      -8.165,
      18.161,
      -49.406,
      -9.165,
      18.161,
      -50.614,
      -9.165,
      18.098,
      -50.614,
      -9.165,
      18.098,
      -50.614,
      -8.165,
      18.098,
      -49.406,
      -8.165,
      18.161,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2156.0",
    polygon: [
      -36.676,
      -15.241,
      18.86,
      -37.498,
      -15.241,
      18.86,
      -37.498,
      -15.241,
      21.32,
      -37.498,
      -15.241,
      21.32,
      -36.676,
      -15.241,
      21.32,
      -36.676,
      -15.241,
      18.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1969.0",
    polygon: [
      -77.382,
      -7.156,
      29.36,
      -78.582,
      -7.156,
      29.36,
      -78.582,
      -7.156,
      31.82,
      -78.582,
      -7.156,
      31.82,
      -77.382,
      -7.156,
      31.82,
      -77.382,
      -7.156,
      29.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2778.0",
    polygon: [
      -74.981,
      -28.202,
      4.86,
      -74.981,
      -26.824,
      4.86,
      -74.981,
      -26.824,
      7.32,
      -74.981,
      -26.824,
      7.32,
      -74.981,
      -28.202,
      7.32,
      -74.981,
      -28.202,
      4.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2334.0",
    polygon: [
      -74.444,
      -16.245,
      25.86,
      -74.444,
      -15.465,
      25.86,
      -74.444,
      -15.465,
      28.32,
      -74.444,
      -15.465,
      28.32,
      -74.444,
      -16.245,
      28.32,
      -74.444,
      -16.245,
      25.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2294.0",
    polygon: [
      -74.957,
      -7.928,
      25.86,
      -74.957,
      -7.371,
      25.86,
      -74.957,
      -7.371,
      28.32,
      -74.957,
      -7.371,
      28.32,
      -74.957,
      -7.928,
      28.32,
      -74.957,
      -7.928,
      25.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2579.0",
    polygon: [
      -57.145,
      -32.213,
      18.233,
      -57.145,
      -31.213,
      18.233,
      -55.936,
      -31.213,
      18.17,
      -55.936,
      -31.213,
      18.17,
      -55.936,
      -32.213,
      18.17,
      -57.145,
      -32.213,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2008.0",
    polygon: [
      29.192,
      -7.156,
      36.36,
      27.992,
      -7.156,
      36.36,
      27.992,
      -7.156,
      39.128,
      27.992,
      -7.156,
      39.128,
      29.192,
      -7.156,
      39.128,
      29.192,
      -7.156,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1930.0",
    polygon: [
      -45.782,
      -7.155,
      25.86,
      -46.982,
      -7.155,
      25.86,
      -46.982,
      -7.155,
      28.32,
      -46.982,
      -7.155,
      28.32,
      -45.782,
      -7.155,
      28.32,
      -45.782,
      -7.155,
      25.86,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2541.0",
    polygon: [
      -57.148,
      -19.537,
      18.233,
      -57.148,
      -18.537,
      18.233,
      -53.175,
      -18.537,
      18.025,
      -53.175,
      -18.537,
      18.025,
      -53.175,
      -19.537,
      18.025,
      -57.148,
      -19.537,
      18.233,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2423.0",
    polygon: [
      -22.781,
      -34.518,
      18.088,
      -22.781,
      -33.518,
      18.088,
      -21.573,
      -33.518,
      18.025,
      -21.573,
      -33.518,
      18.025,
      -21.573,
      -34.518,
      18.025,
      -22.781,
      -34.518,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2873.0",
    polygon: [
      -74.886,
      27.749,
      8.36,
      -74.886,
      28.899,
      8.36,
      -74.886,
      28.899,
      10.82,
      -74.886,
      28.899,
      10.82,
      -74.886,
      27.749,
      10.82,
      -74.886,
      27.749,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2975.0",
    polygon: [
      -83.118,
      4.738,
      8.36,
      -83.118,
      5.938,
      8.36,
      -83.118,
      5.938,
      10.82,
      -83.118,
      5.938,
      10.82,
      -83.118,
      4.738,
      10.82,
      -83.118,
      4.738,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2739.0",
    polygon: [
      -74.884,
      -17.352,
      11.86,
      -74.884,
      -16.202,
      11.86,
      -74.884,
      -16.202,
      14.32,
      -74.884,
      -16.202,
      14.32,
      -74.884,
      -17.352,
      14.32,
      -74.884,
      -17.352,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3015.0",
    polygon: [
      -82.012,
      0.144,
      11.86,
      -83.112,
      0.144,
      11.86,
      -83.112,
      0.144,
      14.32,
      -83.112,
      0.144,
      14.32,
      -82.012,
      0.144,
      14.32,
      -82.012,
      0.144,
      11.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2915.0",
    polygon: [
      -83.127,
      -2.66,
      39.128,
      -83.127,
      -1.46,
      39.128,
      -83.127,
      -1.46,
      40.05,
      -83.127,
      -1.46,
      40.05,
      -83.127,
      -2.66,
      40.05,
      -83.127,
      -2.66,
      39.128,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2780.0",
    polygon: [
      -74.887,
      -7.928,
      8.36,
      -74.887,
      -7.371,
      8.36,
      -74.887,
      -7.371,
      10.82,
      -74.887,
      -7.371,
      10.82,
      -74.887,
      -7.928,
      10.82,
      -74.887,
      -7.928,
      8.36,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2700.0",
    polygon: [
      -74.887,
      -13.502,
      15.36,
      -74.887,
      -12.352,
      15.36,
      -74.887,
      -12.352,
      17.82,
      -74.887,
      -12.352,
      17.82,
      -74.887,
      -13.502,
      17.82,
      -74.887,
      -13.502,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2581.0",
    polygon: [
      -54.383,
      -32.213,
      18.088,
      -54.383,
      -31.213,
      18.088,
      -53.175,
      -31.213,
      18.025,
      -53.175,
      -31.213,
      18.025,
      -53.175,
      -32.213,
      18.025,
      -54.383,
      -32.213,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2659.0",
    polygon: [
      -48.024,
      -32.365,
      18.233,
      -48.024,
      -33.365,
      18.233,
      -51.998,
      -33.365,
      18.025,
      -51.998,
      -33.365,
      18.025,
      -51.998,
      -32.365,
      18.025,
      -48.024,
      -32.365,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1854.0",
    polygon: [
      -81.438,
      -7.156,
      32.86,
      -82.638,
      -7.156,
      32.86,
      -82.638,
      -7.156,
      35.32,
      -82.638,
      -7.156,
      35.32,
      -81.438,
      -7.156,
      35.32,
      -81.438,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1893.0",
    polygon: [
      -27.704,
      -7.155,
      25.86,
      -28.904,
      -7.155,
      25.86,
      -28.904,
      -7.155,
      28.32,
      -28.904,
      -7.155,
      28.32,
      -27.704,
      -7.155,
      28.32,
      -27.704,
      -7.155,
      25.86,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "1775.0",
    polygon: [
      -52.543,
      -7.155,
      36.36,
      -53.743,
      -7.155,
      36.36,
      -53.743,
      -7.155,
      39.128,
      -53.743,
      -7.155,
      39.128,
      -52.543,
      -7.155,
      39.128,
      -52.543,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1712.0",
    polygon: [
      -63.369,
      -7.155,
      36.36,
      -64.569,
      -7.155,
      36.36,
      -64.569,
      -7.155,
      39.128,
      -64.569,
      -7.155,
      39.128,
      -63.369,
      -7.155,
      39.128,
      -63.369,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2875.0",
    polygon: [
      -74.886,
      30.449,
      8.36,
      -74.886,
      31.599,
      8.36,
      -74.886,
      31.599,
      10.82,
      -74.886,
      31.599,
      10.82,
      -74.886,
      30.449,
      10.82,
      -74.886,
      30.449,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2157.0",
    polygon: [
      -35.853,
      -15.241,
      18.86,
      -36.676,
      -15.241,
      18.86,
      -36.676,
      -15.241,
      21.32,
      -36.676,
      -15.241,
      21.32,
      -35.853,
      -15.241,
      21.32,
      -35.853,
      -15.241,
      18.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2296.0",
    polygon: [
      -11.63,
      -10.802,
      25.86,
      -11.63,
      -9.652,
      25.86,
      -11.63,
      -9.652,
      28.32,
      -11.63,
      -9.652,
      28.32,
      -11.63,
      -10.802,
      28.32,
      -11.63,
      -10.802,
      25.86,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2009.0",
    polygon: [
      27.84,
      -7.156,
      39.128,
      26.64,
      -7.156,
      39.128,
      26.64,
      -7.156,
      40.05,
      26.64,
      -7.156,
      40.05,
      27.84,
      -7.156,
      40.05,
      27.84,
      -7.156,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2425.0",
    polygon: [
      -25.546,
      -37.975,
      18.233,
      -25.546,
      -36.975,
      18.233,
      -21.573,
      -36.975,
      18.025,
      -21.573,
      -36.975,
      18.025,
      -21.573,
      -37.975,
      18.025,
      -25.546,
      -37.975,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2256.0",
    polygon: [
      -62.008,
      -15.241,
      18.86,
      -63.208,
      -15.241,
      18.86,
      -63.208,
      -15.241,
      21.32,
      -63.208,
      -15.241,
      21.32,
      -62.008,
      -15.241,
      21.32,
      -62.008,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2059.0",
    polygon: [
      33.249,
      -7.154,
      25.86,
      32.049,
      -7.154,
      25.86,
      32.049,
      -7.154,
      28.32,
      32.049,
      -7.154,
      28.32,
      33.249,
      -7.154,
      28.32,
      33.249,
      -7.154,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1970.0",
    polygon: [
      -78.734,
      -7.156,
      29.36,
      -79.934,
      -7.156,
      29.36,
      -79.934,
      -7.156,
      31.82,
      -79.934,
      -7.156,
      31.82,
      -78.734,
      -7.156,
      31.82,
      -78.734,
      -7.156,
      29.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1853.0",
    polygon: [
      -80.086,
      -7.156,
      32.86,
      -81.286,
      -7.156,
      32.86,
      -81.286,
      -7.156,
      35.32,
      -81.286,
      -7.156,
      35.32,
      -80.086,
      -7.156,
      35.32,
      -80.086,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2426.0",
    polygon: [
      -29.675,
      -36.823,
      18.45,
      -29.675,
      -35.823,
      18.45,
      -25.702,
      -35.823,
      18.241,
      -25.702,
      -35.823,
      18.241,
      -25.702,
      -36.823,
      18.241,
      -29.675,
      -36.823,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1711.0",
    polygon: [
      -64.731,
      -6.955,
      39.128,
      -65.931,
      -6.955,
      39.128,
      -65.931,
      -6.955,
      40.05,
      -65.931,
      -6.955,
      40.05,
      -64.731,
      -6.955,
      40.05,
      -64.731,
      -6.955,
      39.128,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1932.0",
    polygon: [
      -48.486,
      -7.155,
      25.86,
      -49.686,
      -7.155,
      25.86,
      -49.686,
      -7.155,
      28.32,
      -49.686,
      -7.155,
      28.32,
      -48.486,
      -7.155,
      28.32,
      -48.486,
      -7.155,
      25.86,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2701.0",
    polygon: [
      -74.887,
      -14.652,
      15.36,
      -74.887,
      -13.502,
      15.36,
      -74.887,
      -13.502,
      17.82,
      -74.887,
      -13.502,
      17.82,
      -74.887,
      -14.652,
      17.82,
      -74.887,
      -14.652,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2197.0",
    polygon: [
      -43.64,
      -6.961,
      18.86,
      -44.197,
      -6.961,
      18.86,
      -44.197,
      -6.961,
      21.32,
      -44.197,
      -6.961,
      21.32,
      -43.64,
      -6.961,
      21.32,
      -43.64,
      -6.961,
      18.86,
    ],
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2976.0",
    polygon: [
      -83.118,
      0.681,
      11.86,
      -83.118,
      1.881,
      11.86,
      -83.118,
      1.881,
      14.32,
      -83.118,
      1.881,
      14.32,
      -83.118,
      0.681,
      14.32,
      -83.118,
      0.681,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2464.0",
    polygon: [
      -12.294,
      -20.841,
      18.45,
      -12.294,
      -21.841,
      18.45,
      -16.267,
      -21.841,
      18.241,
      -16.267,
      -21.841,
      18.241,
      -16.267,
      -20.841,
      18.241,
      -12.294,
      -20.841,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2660.0",
    polygon: [
      -43.895,
      -31.213,
      18.45,
      -43.895,
      -32.213,
      18.45,
      -47.869,
      -32.213,
      18.241,
      -47.869,
      -32.213,
      18.241,
      -47.869,
      -31.213,
      18.241,
      -43.895,
      -31.213,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3014.0",
    polygon: [
      -81.761,
      -0.692,
      8.36,
      -81.761,
      -0.112,
      8.36,
      -81.761,
      -0.112,
      10.82,
      -81.761,
      -0.112,
      10.82,
      -81.761,
      -0.692,
      10.82,
      -81.761,
      -0.692,
      8.36,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2387.0",
    polygon: [
      -22.781,
      -20.689,
      18.088,
      -22.781,
      -19.689,
      18.088,
      -21.573,
      -19.689,
      18.025,
      -21.573,
      -19.689,
      18.025,
      -21.573,
      -20.689,
      18.025,
      -22.781,
      -20.689,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2504.0",
    polygon: [
      -17.804,
      -33.518,
      18.161,
      -17.804,
      -34.518,
      18.161,
      -19.012,
      -34.518,
      18.098,
      -19.012,
      -34.518,
      18.098,
      -19.012,
      -33.518,
      18.098,
      -17.804,
      -33.518,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2874.0",
    polygon: [
      -74.886,
      31.599,
      8.36,
      -74.886,
      32.749,
      8.36,
      -74.886,
      32.749,
      10.82,
      -74.886,
      32.749,
      10.82,
      -74.886,
      31.599,
      10.82,
      -74.886,
      31.599,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2336.0",
    polygon: [
      -42.844,
      -16.245,
      25.86,
      -42.844,
      -15.465,
      25.86,
      -42.844,
      -15.465,
      28.32,
      -42.844,
      -15.465,
      28.32,
      -42.844,
      -16.245,
      28.32,
      -42.844,
      -16.245,
      25.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "2335.0",
    polygon: [
      -62.108,
      -15.453,
      25.86,
      -62.108,
      -16.233,
      25.86,
      -62.108,
      -16.233,
      28.32,
      -62.108,
      -16.233,
      28.32,
      -62.108,
      -15.453,
      28.32,
      -62.108,
      -15.453,
      25.86,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "1713.0",
    polygon: [
      -63.369,
      -7.155,
      39.128,
      -64.569,
      -7.155,
      39.128,
      -64.569,
      -7.155,
      40.05,
      -64.569,
      -7.155,
      40.05,
      -63.369,
      -7.155,
      40.05,
      -63.369,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1972.0",
    polygon: [
      -81.438,
      -7.156,
      29.36,
      -82.638,
      -7.156,
      29.36,
      -82.638,
      -7.156,
      31.82,
      -82.638,
      -7.156,
      31.82,
      -81.438,
      -7.156,
      31.82,
      -81.438,
      -7.156,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2158.0",
    polygon: [
      -41.744,
      -15.241,
      18.86,
      -42.944,
      -15.241,
      18.86,
      -42.944,
      -15.241,
      21.32,
      -42.944,
      -15.241,
      21.32,
      -41.744,
      -15.241,
      21.32,
      -41.744,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1931.0",
    polygon: [
      -47.134,
      -7.155,
      25.86,
      -48.334,
      -7.155,
      25.86,
      -48.334,
      -7.155,
      28.32,
      -48.334,
      -7.155,
      28.32,
      -47.134,
      -7.155,
      28.32,
      -47.134,
      -7.155,
      25.86,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2829.0",
    polygon: [
      -74.867,
      -5.235,
      3.473,
      -74.867,
      -2.915,
      3.473,
      -74.867,
      -2.915,
      4.167,
      -74.867,
      -2.915,
      4.167,
      -74.867,
      -5.235,
      4.167,
      -74.867,
      -5.235,
      3.473,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2542.0",
    polygon: [
      -61.277,
      -18.384,
      18.45,
      -61.277,
      -17.384,
      18.45,
      -57.304,
      -17.384,
      18.241,
      -57.304,
      -17.384,
      18.241,
      -57.304,
      -18.384,
      18.241,
      -61.277,
      -18.384,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2198.0",
    polygon: [
      -75.269,
      -7.156,
      18.86,
      -75.826,
      -7.156,
      18.86,
      -75.826,
      -7.156,
      21.32,
      -75.826,
      -7.156,
      21.32,
      -75.269,
      -7.156,
      21.32,
      -75.269,
      -7.156,
      18.86,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1815.0",
    polygon: [
      -67.453,
      -7.156,
      32.86,
      -68.276,
      -7.156,
      32.86,
      -68.276,
      -7.156,
      35.32,
      -68.276,
      -7.156,
      35.32,
      -67.453,
      -7.156,
      35.32,
      -67.453,
      -7.156,
      32.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1971.0",
    polygon: [
      -80.086,
      -7.156,
      29.36,
      -81.286,
      -7.156,
      29.36,
      -81.286,
      -7.156,
      31.82,
      -81.286,
      -7.156,
      31.82,
      -80.086,
      -7.156,
      31.82,
      -80.086,
      -7.156,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1892.0",
    polygon: [
      -26.351,
      -7.155,
      25.86,
      -27.551,
      -7.155,
      25.86,
      -27.551,
      -7.155,
      28.32,
      -27.551,
      -7.155,
      28.32,
      -26.351,
      -7.155,
      28.32,
      -26.351,
      -7.155,
      25.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "2914.0",
    polygon: [
      -83.127,
      -2.66,
      36.36,
      -83.127,
      -1.46,
      36.36,
      -83.127,
      -1.46,
      39.128,
      -83.127,
      -1.46,
      39.128,
      -83.127,
      -2.66,
      39.128,
      -83.127,
      -2.66,
      36.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2465.0",
    polygon: [
      -16.423,
      -20.841,
      18.233,
      -16.423,
      -21.841,
      18.233,
      -20.396,
      -21.841,
      18.025,
      -20.396,
      -21.841,
      18.025,
      -20.396,
      -20.841,
      18.025,
      -16.423,
      -20.841,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2098.0",
    polygon: [
      34.601,
      -7.154,
      15.36,
      33.401,
      -7.154,
      15.36,
      33.401,
      -7.154,
      17.82,
      33.401,
      -7.154,
      17.82,
      34.601,
      -7.154,
      17.82,
      34.601,
      -7.154,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2781.0",
    polygon: [
      -74.887,
      -7.928,
      11.86,
      -74.887,
      -7.371,
      11.86,
      -74.887,
      -7.371,
      14.32,
      -74.887,
      -7.371,
      14.32,
      -74.887,
      -7.928,
      14.32,
      -74.887,
      -7.928,
      11.86,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1855.0",
    polygon: [
      -74.677,
      -7.156,
      32.86,
      -75.877,
      -7.156,
      32.86,
      -75.877,
      -7.156,
      35.32,
      -75.877,
      -7.156,
      35.32,
      -74.677,
      -7.156,
      35.32,
      -74.677,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1814.0",
    polygon: [
      -68.276,
      -7.156,
      32.86,
      -69.098,
      -7.156,
      32.86,
      -69.098,
      -7.156,
      35.32,
      -69.098,
      -7.156,
      35.32,
      -68.276,
      -7.156,
      35.32,
      -68.276,
      -7.156,
      32.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1774.0",
    polygon: [
      -52.543,
      -7.155,
      39.128,
      -53.743,
      -7.155,
      39.128,
      -53.743,
      -7.155,
      40.05,
      -53.743,
      -7.155,
      40.05,
      -52.543,
      -7.155,
      40.05,
      -52.543,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2740.0",
    polygon: [
      -74.887,
      -13.502,
      11.86,
      -74.887,
      -12.352,
      11.86,
      -74.887,
      -12.352,
      14.32,
      -74.887,
      -12.352,
      14.32,
      -74.887,
      -13.502,
      14.32,
      -74.887,
      -13.502,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2386.0",
    polygon: [
      -24.165,
      -20.689,
      18.161,
      -24.165,
      -19.689,
      18.161,
      -22.957,
      -19.689,
      18.098,
      -22.957,
      -19.689,
      18.098,
      -22.957,
      -20.689,
      18.098,
      -24.165,
      -20.689,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2738.0",
    polygon: [
      -74.884,
      -16.202,
      11.86,
      -74.884,
      -15.052,
      11.86,
      -74.884,
      -15.052,
      14.32,
      -74.884,
      -15.052,
      14.32,
      -74.884,
      -16.202,
      14.32,
      -74.884,
      -16.202,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2099.0",
    polygon: [
      33.249,
      -7.154,
      15.36,
      32.049,
      -7.154,
      15.36,
      32.049,
      -7.154,
      17.82,
      32.049,
      -7.154,
      17.82,
      33.249,
      -7.154,
      17.82,
      33.249,
      -7.154,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2060.0",
    polygon: [
      31.897,
      -7.154,
      25.86,
      30.697,
      -7.154,
      25.86,
      30.697,
      -7.154,
      28.32,
      30.697,
      -7.154,
      28.32,
      31.897,
      -7.154,
      28.32,
      31.897,
      -7.154,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2297.0",
    polygon: [
      -11.63,
      -11.952,
      25.86,
      -11.63,
      -10.802,
      25.86,
      -11.63,
      -10.802,
      28.32,
      -11.63,
      -10.802,
      28.32,
      -11.63,
      -11.952,
      28.32,
      -11.63,
      -11.952,
      25.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1933.0",
    polygon: [
      -49.838,
      -7.155,
      25.86,
      -51.038,
      -7.155,
      25.86,
      -51.038,
      -7.155,
      28.32,
      -51.038,
      -7.155,
      28.32,
      -49.838,
      -7.155,
      28.32,
      -49.838,
      -7.155,
      25.86,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2159.0",
    polygon: [
      -30.408,
      -15.241,
      18.86,
      -31.608,
      -15.241,
      18.86,
      -31.608,
      -15.241,
      21.32,
      -31.608,
      -15.241,
      21.32,
      -30.408,
      -15.241,
      21.32,
      -30.408,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2582.0",
    polygon: [
      -61.277,
      -35.67,
      18.45,
      -61.277,
      -34.67,
      18.45,
      -57.304,
      -34.67,
      18.241,
      -57.304,
      -34.67,
      18.241,
      -57.304,
      -35.67,
      18.241,
      -61.277,
      -35.67,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2974.0",
    polygon: [
      -83.118,
      3.386,
      8.36,
      -83.118,
      4.586,
      8.36,
      -83.118,
      4.586,
      10.82,
      -83.118,
      4.586,
      10.82,
      -83.118,
      3.386,
      10.82,
      -83.118,
      3.386,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1776.0",
    polygon: [
      -53.895,
      -7.155,
      39.128,
      -55.095,
      -7.155,
      39.128,
      -55.095,
      -7.155,
      40.05,
      -55.095,
      -7.155,
      40.05,
      -53.895,
      -7.155,
      40.05,
      -53.895,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2258.0",
    polygon: [
      -70.621,
      -15.241,
      18.86,
      -71.821,
      -15.241,
      18.86,
      -71.821,
      -15.241,
      21.32,
      -71.821,
      -15.241,
      21.32,
      -70.621,
      -15.241,
      21.32,
      -70.621,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2010.0",
    polygon: [
      27.84,
      -7.156,
      36.36,
      26.64,
      -7.156,
      36.36,
      26.64,
      -7.156,
      39.128,
      26.64,
      -7.156,
      39.128,
      27.84,
      -7.156,
      39.128,
      27.84,
      -7.156,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1894.0",
    polygon: [
      -12.83,
      -7.155,
      29.36,
      -14.03,
      -7.155,
      29.36,
      -14.03,
      -7.155,
      31.82,
      -14.03,
      -7.155,
      31.82,
      -12.83,
      -7.155,
      31.82,
      -12.83,
      -7.155,
      29.36,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2779.0",
    polygon: [
      -74.981,
      -32.585,
      4.86,
      -74.981,
      -31.202,
      4.86,
      -74.981,
      -31.202,
      7.32,
      -74.981,
      -31.202,
      7.32,
      -74.981,
      -32.585,
      7.32,
      -74.981,
      -32.585,
      4.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2543.0",
    polygon: [
      -57.145,
      -18.384,
      18.233,
      -57.145,
      -17.384,
      18.233,
      -55.936,
      -17.384,
      18.17,
      -55.936,
      -17.384,
      18.17,
      -55.936,
      -18.384,
      18.17,
      -57.145,
      -18.384,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2503.0",
    polygon: [
      -16.426,
      -33.518,
      18.233,
      -16.426,
      -34.518,
      18.233,
      -17.634,
      -34.518,
      18.17,
      -17.634,
      -34.518,
      18.17,
      -17.634,
      -33.518,
      18.17,
      -16.426,
      -33.518,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2298.0",
    polygon: [
      -30.129,
      -8.252,
      25.86,
      -30.129,
      -9.502,
      25.86,
      -30.129,
      -9.502,
      28.32,
      -30.129,
      -9.502,
      28.32,
      -30.129,
      -8.252,
      28.32,
      -30.129,
      -8.252,
      25.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1813.0",
    polygon: [
      -31.769,
      -7.155,
      32.86,
      -32.969,
      -7.155,
      32.86,
      -32.969,
      -7.155,
      35.32,
      -32.969,
      -7.155,
      35.32,
      -31.769,
      -7.155,
      35.32,
      -31.769,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2257.0",
    polygon: [
      -71.982,
      -15.241,
      18.86,
      -73.182,
      -15.241,
      18.86,
      -73.182,
      -15.241,
      21.32,
      -73.182,
      -15.241,
      21.32,
      -71.982,
      -15.241,
      21.32,
      -71.982,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2876.0",
    polygon: [
      -74.886,
      9.998,
      11.86,
      -74.886,
      11.148,
      11.86,
      -74.886,
      11.148,
      14.32,
      -74.886,
      11.148,
      14.32,
      -74.886,
      9.998,
      14.32,
      -74.886,
      9.998,
      11.86,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2545.0",
    polygon: [
      -54.383,
      -18.384,
      18.088,
      -54.383,
      -17.384,
      18.088,
      -53.175,
      -17.384,
      18.025,
      -53.175,
      -17.384,
      18.025,
      -53.175,
      -18.384,
      18.025,
      -54.383,
      -18.384,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2506.0",
    polygon: [
      -12.294,
      -36.975,
      18.45,
      -12.294,
      -37.975,
      18.45,
      -16.267,
      -37.975,
      18.241,
      -16.267,
      -37.975,
      18.241,
      -16.267,
      -36.975,
      18.241,
      -12.294,
      -36.975,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2584.0",
    polygon: [
      -61.277,
      -34.518,
      18.45,
      -61.277,
      -33.518,
      18.45,
      -57.304,
      -33.518,
      18.241,
      -57.304,
      -33.518,
      18.241,
      -57.304,
      -34.518,
      18.241,
      -61.277,
      -34.518,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2662.0",
    polygon: [
      -49.406,
      -31.213,
      18.161,
      -49.406,
      -32.213,
      18.161,
      -50.614,
      -32.213,
      18.098,
      -50.614,
      -32.213,
      18.098,
      -50.614,
      -31.213,
      18.098,
      -49.406,
      -31.213,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2389.0",
    polygon: [
      -25.546,
      -24.146,
      18.233,
      -25.546,
      -23.146,
      18.233,
      -21.573,
      -23.146,
      18.025,
      -21.573,
      -23.146,
      18.025,
      -21.573,
      -24.146,
      18.025,
      -25.546,
      -24.146,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2338.0",
    polygon: [
      -29.675,
      -17.232,
      18.45,
      -29.675,
      -16.232,
      18.45,
      -25.702,
      -16.232,
      18.241,
      -25.702,
      -16.232,
      18.241,
      -25.702,
      -17.232,
      18.241,
      -29.675,
      -17.232,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2623.0",
    polygon: [
      -48.024,
      -18.537,
      18.233,
      -48.024,
      -19.537,
      18.233,
      -51.998,
      -19.537,
      18.025,
      -51.998,
      -19.537,
      18.025,
      -51.998,
      -18.537,
      18.025,
      -48.024,
      -18.537,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2783.0",
    polygon: [
      -74.981,
      -25.279,
      2.78,
      -74.981,
      -22.959,
      2.78,
      -74.981,
      -22.959,
      3.473,
      -74.981,
      -22.959,
      3.473,
      -74.981,
      -25.279,
      3.473,
      -74.981,
      -25.279,
      2.78,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2200.0",
    polygon: [
      -75.269,
      -7.052,
      22.36,
      -75.826,
      -7.052,
      22.36,
      -75.826,
      -7.052,
      24.82,
      -75.826,
      -7.052,
      24.82,
      -75.269,
      -7.052,
      24.82,
      -75.269,
      -7.052,
      22.36,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2878.0",
    polygon: [
      -74.886,
      12.699,
      11.86,
      -74.886,
      13.849,
      11.86,
      -74.886,
      13.849,
      14.32,
      -74.886,
      13.849,
      14.32,
      -74.886,
      12.699,
      14.32,
      -74.886,
      12.699,
      11.86,
    ],
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2101.0",
    polygon: [
      30.545,
      -7.154,
      15.36,
      29.345,
      -7.154,
      15.36,
      29.345,
      -7.154,
      17.82,
      29.345,
      -7.154,
      17.82,
      30.545,
      -7.154,
      17.82,
      30.545,
      -7.154,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2977.0",
    polygon: [
      -83.118,
      2.033,
      11.86,
      -83.118,
      3.233,
      11.86,
      -83.118,
      3.233,
      14.32,
      -83.118,
      3.233,
      14.32,
      -83.118,
      2.033,
      14.32,
      -83.118,
      2.033,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2922.0",
    polygon: [
      -83.118,
      -2.66,
      32.86,
      -83.118,
      -1.46,
      32.86,
      -83.118,
      -1.46,
      35.32,
      -83.118,
      -1.46,
      35.32,
      -83.118,
      -2.66,
      35.32,
      -83.118,
      -2.66,
      32.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2742.0",
    polygon: [
      -74.884,
      -18.903,
      11.86,
      -74.884,
      -17.753,
      11.86,
      -74.884,
      -17.753,
      14.32,
      -74.884,
      -17.753,
      14.32,
      -74.884,
      -18.903,
      14.32,
      -74.884,
      -18.903,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2703.0",
    polygon: [
      -74.884,
      -20.053,
      15.36,
      -74.884,
      -18.903,
      15.36,
      -74.884,
      -18.903,
      17.82,
      -74.884,
      -18.903,
      17.82,
      -74.884,
      -20.053,
      17.82,
      -74.884,
      -20.053,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2585.0",
    polygon: [
      -57.145,
      -34.518,
      18.233,
      -57.145,
      -33.518,
      18.233,
      -55.936,
      -33.518,
      18.17,
      -55.936,
      -33.518,
      18.17,
      -55.936,
      -34.518,
      18.17,
      -57.145,
      -34.518,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2339.0",
    polygon: [
      -25.546,
      -17.232,
      18.233,
      -25.546,
      -16.232,
      18.233,
      -21.573,
      -16.232,
      18.025,
      -21.573,
      -16.232,
      18.025,
      -21.573,
      -17.232,
      18.025,
      -25.546,
      -17.232,
      18.233,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2583.0",
    polygon: [
      -57.148,
      -35.67,
      18.233,
      -57.148,
      -34.67,
      18.233,
      -53.175,
      -34.67,
      18.025,
      -53.175,
      -34.67,
      18.025,
      -53.175,
      -35.67,
      18.025,
      -57.148,
      -35.67,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1974.0",
    polygon: [
      -77.382,
      -7.156,
      8.36,
      -78.582,
      -7.156,
      8.36,
      -78.582,
      -7.156,
      10.82,
      -78.582,
      -7.156,
      10.82,
      -77.382,
      -7.156,
      10.82,
      -77.382,
      -7.156,
      8.36,
    ],
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2337.0",
    polygon: [
      -30.508,
      -15.453,
      25.86,
      -30.508,
      -16.233,
      25.86,
      -30.508,
      -16.233,
      28.32,
      -30.508,
      -16.233,
      28.32,
      -30.508,
      -15.453,
      28.32,
      -30.508,
      -15.453,
      25.86,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "3018.0",
    polygon: [
      -82.012,
      0.144,
      15.36,
      -83.112,
      0.144,
      15.36,
      -83.112,
      0.144,
      17.82,
      -83.112,
      0.144,
      17.82,
      -82.012,
      0.144,
      17.82,
      -82.012,
      0.144,
      15.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2622.0",
    polygon: [
      -43.895,
      -18.537,
      18.45,
      -43.895,
      -19.537,
      18.45,
      -47.869,
      -19.537,
      18.241,
      -47.869,
      -19.537,
      18.241,
      -47.869,
      -18.537,
      18.241,
      -43.895,
      -18.537,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1817.0",
    polygon: [
      -62.008,
      -7.156,
      32.86,
      -63.208,
      -7.156,
      32.86,
      -63.208,
      -7.156,
      35.32,
      -63.208,
      -7.156,
      35.32,
      -62.008,
      -7.156,
      35.32,
      -62.008,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2978.0",
    polygon: [
      -83.118,
      3.386,
      11.86,
      -83.118,
      4.586,
      11.86,
      -83.118,
      4.586,
      14.32,
      -83.118,
      4.586,
      14.32,
      -83.118,
      3.386,
      14.32,
      -83.118,
      3.386,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2544.0",
    polygon: [
      -55.767,
      -18.384,
      18.161,
      -55.767,
      -17.384,
      18.161,
      -54.558,
      -17.384,
      18.098,
      -54.558,
      -17.384,
      18.098,
      -54.558,
      -18.384,
      18.098,
      -55.767,
      -18.384,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2741.0",
    polygon: [
      -74.887,
      -14.652,
      11.86,
      -74.887,
      -13.502,
      11.86,
      -74.887,
      -13.502,
      14.32,
      -74.887,
      -13.502,
      14.32,
      -74.887,
      -14.652,
      14.32,
      -74.887,
      -14.652,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2299.0",
    polygon: [
      -30.129,
      -9.502,
      25.86,
      -30.129,
      -10.752,
      25.86,
      -30.129,
      -10.752,
      28.32,
      -30.129,
      -10.752,
      28.32,
      -30.129,
      -9.502,
      28.32,
      -30.129,
      -9.502,
      25.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1935.0",
    polygon: [
      -52.543,
      -7.155,
      25.86,
      -53.743,
      -7.155,
      25.86,
      -53.743,
      -7.155,
      28.32,
      -53.743,
      -7.155,
      28.32,
      -52.543,
      -7.155,
      28.32,
      -52.543,
      -7.155,
      25.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2390.0",
    polygon: [
      -29.675,
      -22.994,
      18.45,
      -29.675,
      -21.994,
      18.45,
      -25.702,
      -21.994,
      18.241,
      -25.702,
      -21.994,
      18.241,
      -25.702,
      -22.994,
      18.241,
      -29.675,
      -22.994,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1738.0",
    polygon: [
      -12.83,
      -7.155,
      39.128,
      -14.03,
      -7.155,
      39.128,
      -14.03,
      -7.155,
      40.05,
      -14.03,
      -7.155,
      40.05,
      -12.83,
      -7.155,
      40.05,
      -12.83,
      -7.155,
      39.128,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2702.0",
    polygon: [
      -74.884,
      -18.903,
      15.36,
      -74.884,
      -17.753,
      15.36,
      -74.884,
      -17.753,
      17.82,
      -74.884,
      -17.753,
      17.82,
      -74.884,
      -18.903,
      17.82,
      -74.884,
      -18.903,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2100.0",
    polygon: [
      31.897,
      -7.154,
      15.36,
      30.697,
      -7.154,
      15.36,
      30.697,
      -7.154,
      17.82,
      30.697,
      -7.154,
      17.82,
      31.897,
      -7.154,
      17.82,
      31.897,
      -7.154,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1973.0",
    polygon: [
      -76.03,
      -7.156,
      29.36,
      -77.23,
      -7.156,
      29.36,
      -77.23,
      -7.156,
      31.82,
      -77.23,
      -7.156,
      31.82,
      -76.03,
      -7.156,
      31.82,
      -76.03,
      -7.156,
      29.36,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1857.0",
    polygon: [
      -64.731,
      -7.156,
      32.86,
      -65.931,
      -7.156,
      32.86,
      -65.931,
      -7.156,
      35.32,
      -65.931,
      -7.156,
      35.32,
      -64.731,
      -7.156,
      35.32,
      -64.731,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2704.0",
    polygon: [
      -74.884,
      -21.602,
      15.36,
      -74.884,
      -20.452,
      15.36,
      -74.884,
      -20.452,
      17.82,
      -74.884,
      -20.452,
      17.82,
      -74.884,
      -21.602,
      17.82,
      -74.884,
      -21.602,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2429.0",
    polygon: [
      -22.781,
      -36.823,
      18.088,
      -22.781,
      -35.823,
      18.088,
      -21.573,
      -35.823,
      18.025,
      -21.573,
      -35.823,
      18.025,
      -21.573,
      -36.823,
      18.025,
      -22.781,
      -36.823,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2388.0",
    polygon: [
      -29.675,
      -24.146,
      18.45,
      -29.675,
      -23.146,
      18.45,
      -25.702,
      -23.146,
      18.241,
      -25.702,
      -23.146,
      18.241,
      -25.702,
      -24.146,
      18.241,
      -29.675,
      -24.146,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2507.0",
    polygon: [
      -16.423,
      -36.975,
      18.233,
      -16.423,
      -37.975,
      18.233,
      -20.396,
      -37.975,
      18.025,
      -20.396,
      -37.975,
      18.025,
      -20.396,
      -36.975,
      18.025,
      -16.423,
      -36.975,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2505.0",
    polygon: [
      -19.188,
      -33.518,
      18.088,
      -19.188,
      -34.518,
      18.088,
      -20.396,
      -34.518,
      18.025,
      -20.396,
      -34.518,
      18.025,
      -20.396,
      -33.518,
      18.025,
      -19.188,
      -33.518,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2468.0",
    polygon: [
      -17.804,
      -19.689,
      18.161,
      -17.804,
      -20.689,
      18.161,
      -19.012,
      -20.689,
      18.098,
      -19.012,
      -20.689,
      18.098,
      -19.012,
      -19.689,
      18.098,
      -17.804,
      -19.689,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1739.0",
    polygon: [
      -12.83,
      -7.155,
      36.36,
      -14.03,
      -7.155,
      36.36,
      -14.03,
      -7.155,
      39.128,
      -14.03,
      -7.155,
      39.128,
      -12.83,
      -7.155,
      39.128,
      -12.83,
      -7.155,
      36.36,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2840.0",
    polygon: [
      -74.886,
      9.998,
      15.36,
      -74.886,
      11.148,
      15.36,
      -74.886,
      11.148,
      17.82,
      -74.886,
      11.148,
      17.82,
      -74.886,
      9.998,
      17.82,
      -74.886,
      9.998,
      15.36,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2838.0",
    polygon: [
      -75.046,
      -1.13,
      2.799,
      -75.046,
      0.272,
      2.799,
      -75.046,
      0.272,
      4.186,
      -75.046,
      0.272,
      4.186,
      -75.046,
      -1.13,
      4.186,
      -75.046,
      -1.13,
      2.799,
    ],
    "04-15 15:45": 1,
  },
  {
    ID: "2466.0",
    polygon: [
      -12.294,
      -19.689,
      18.45,
      -12.294,
      -20.689,
      18.45,
      -16.267,
      -20.689,
      18.241,
      -16.267,
      -20.689,
      18.241,
      -16.267,
      -19.689,
      18.241,
      -12.294,
      -19.689,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2260.0",
    polygon: [
      -66.092,
      -15.241,
      18.86,
      -67.292,
      -15.241,
      18.86,
      -67.292,
      -15.241,
      21.32,
      -67.292,
      -15.241,
      21.32,
      -66.092,
      -15.241,
      21.32,
      -66.092,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2160.0",
    polygon: [
      -40.382,
      -15.241,
      18.86,
      -41.582,
      -15.241,
      18.86,
      -41.582,
      -15.241,
      21.32,
      -41.582,
      -15.241,
      21.32,
      -40.382,
      -15.241,
      21.32,
      -40.382,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2300.0",
    polygon: [
      -30.129,
      -10.752,
      25.86,
      -30.129,
      -12.002,
      25.86,
      -30.129,
      -12.002,
      28.32,
      -30.129,
      -12.002,
      28.32,
      -30.129,
      -10.752,
      28.32,
      -30.129,
      -10.752,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1934.0",
    polygon: [
      -51.19,
      -7.155,
      25.86,
      -52.39,
      -7.155,
      25.86,
      -52.39,
      -7.155,
      28.32,
      -52.39,
      -7.155,
      28.32,
      -51.19,
      -7.155,
      28.32,
      -51.19,
      -7.155,
      25.86,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2102.0",
    polygon: [
      29.192,
      -7.154,
      15.36,
      27.992,
      -7.154,
      15.36,
      27.992,
      -7.154,
      17.82,
      27.992,
      -7.154,
      17.82,
      29.192,
      -7.154,
      17.82,
      29.192,
      -7.154,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2061.0",
    polygon: [
      30.545,
      -7.154,
      25.86,
      29.345,
      -7.154,
      25.86,
      29.345,
      -7.154,
      28.32,
      29.345,
      -7.154,
      28.32,
      30.545,
      -7.154,
      28.32,
      30.545,
      -7.154,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2743.0",
    polygon: [
      -74.884,
      -20.053,
      11.86,
      -74.884,
      -18.903,
      11.86,
      -74.884,
      -18.903,
      14.32,
      -74.884,
      -18.903,
      14.32,
      -74.884,
      -20.053,
      14.32,
      -74.884,
      -20.053,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2161.0",
    polygon: [
      -39.021,
      -15.241,
      18.86,
      -40.221,
      -15.241,
      18.86,
      -40.221,
      -15.241,
      21.32,
      -40.221,
      -15.241,
      21.32,
      -39.021,
      -15.241,
      21.32,
      -39.021,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2427.0",
    polygon: [
      -25.543,
      -36.823,
      18.233,
      -25.543,
      -35.823,
      18.233,
      -24.335,
      -35.823,
      18.17,
      -24.335,
      -35.823,
      18.17,
      -24.335,
      -36.823,
      18.17,
      -25.543,
      -36.823,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1896.0",
    polygon: [
      -15.534,
      -7.155,
      29.36,
      -16.734,
      -7.155,
      29.36,
      -16.734,
      -7.155,
      31.82,
      -16.734,
      -7.155,
      31.82,
      -15.534,
      -7.155,
      31.82,
      -15.534,
      -7.155,
      29.36,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2546.0",
    polygon: [
      -61.277,
      -21.841,
      18.45,
      -61.277,
      -20.841,
      18.45,
      -57.304,
      -20.841,
      18.241,
      -57.304,
      -20.841,
      18.241,
      -57.304,
      -21.841,
      18.241,
      -61.277,
      -21.841,
      18.45,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1777.0",
    polygon: [
      -53.895,
      -7.155,
      36.36,
      -55.095,
      -7.155,
      36.36,
      -55.095,
      -7.155,
      39.128,
      -55.095,
      -7.155,
      39.128,
      -53.895,
      -7.155,
      39.128,
      -53.895,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2661.0",
    polygon: [
      -48.028,
      -31.213,
      18.233,
      -48.028,
      -32.213,
      18.233,
      -49.236,
      -32.213,
      18.17,
      -49.236,
      -32.213,
      18.17,
      -49.236,
      -31.213,
      18.17,
      -48.028,
      -31.213,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2921.0",
    polygon: [
      -83.118,
      -4.012,
      32.86,
      -83.118,
      -2.812,
      32.86,
      -83.118,
      -2.812,
      35.32,
      -83.118,
      -2.812,
      35.32,
      -83.118,
      -4.012,
      35.32,
      -83.118,
      -4.012,
      32.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2259.0",
    polygon: [
      -69.26,
      -15.241,
      18.86,
      -70.46,
      -15.241,
      18.86,
      -70.46,
      -15.241,
      21.32,
      -70.46,
      -15.241,
      21.32,
      -69.26,
      -15.241,
      21.32,
      -69.26,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2935.0",
    polygon: [
      -83.118,
      -6.717,
      18.86,
      -83.118,
      -5.517,
      18.86,
      -83.118,
      -5.517,
      21.32,
      -83.118,
      -5.517,
      21.32,
      -83.118,
      -6.717,
      21.32,
      -83.118,
      -6.717,
      18.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2011.0",
    polygon: [
      26.488,
      -7.156,
      39.128,
      25.288,
      -7.156,
      39.128,
      25.288,
      -7.156,
      40.05,
      25.288,
      -7.156,
      40.05,
      26.488,
      -7.156,
      40.05,
      26.488,
      -7.156,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2063.0",
    polygon: [
      27.84,
      -7.154,
      25.86,
      26.64,
      -7.154,
      25.86,
      26.64,
      -7.154,
      28.32,
      26.64,
      -7.154,
      28.32,
      27.84,
      -7.154,
      28.32,
      27.84,
      -7.154,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2467.0",
    polygon: [
      -16.426,
      -19.689,
      18.233,
      -16.426,
      -20.689,
      18.233,
      -17.634,
      -20.689,
      18.17,
      -17.634,
      -20.689,
      18.17,
      -17.634,
      -19.689,
      18.17,
      -16.426,
      -19.689,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2062.0",
    polygon: [
      29.192,
      -7.154,
      25.86,
      27.992,
      -7.154,
      25.86,
      27.992,
      -7.154,
      28.32,
      27.992,
      -7.154,
      28.32,
      29.192,
      -7.154,
      28.32,
      29.192,
      -7.154,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2428.0",
    polygon: [
      -24.165,
      -36.823,
      18.161,
      -24.165,
      -35.823,
      18.161,
      -22.957,
      -35.823,
      18.098,
      -22.957,
      -35.823,
      18.098,
      -22.957,
      -36.823,
      18.098,
      -24.165,
      -36.823,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2879.0",
    polygon: [
      -74.886,
      11.549,
      11.86,
      -74.886,
      12.699,
      11.86,
      -74.886,
      12.699,
      14.32,
      -74.886,
      12.699,
      14.32,
      -74.886,
      11.549,
      14.32,
      -74.886,
      11.549,
      11.86,
    ],
    "04-15 14:00": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2784.0",
    polygon: [
      -74.981,
      -22.959,
      -0.35,
      -74.981,
      -21.459,
      -0.35,
      -74.981,
      -21.459,
      2.78,
      -74.981,
      -21.459,
      2.78,
      -74.981,
      -22.959,
      2.78,
      -74.981,
      -22.959,
      -0.35,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2624.0",
    polygon: [
      -43.895,
      -17.384,
      18.45,
      -43.895,
      -18.384,
      18.45,
      -47.869,
      -18.384,
      18.241,
      -47.869,
      -18.384,
      18.241,
      -47.869,
      -17.384,
      18.241,
      -43.895,
      -17.384,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2012.0",
    polygon: [
      26.488,
      -7.156,
      36.36,
      25.288,
      -7.156,
      36.36,
      25.288,
      -7.156,
      39.128,
      25.288,
      -7.156,
      39.128,
      26.488,
      -7.156,
      39.128,
      26.488,
      -7.156,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "1816.0",
    polygon: [
      -73.344,
      -7.156,
      32.86,
      -74.544,
      -7.156,
      32.86,
      -74.544,
      -7.156,
      35.32,
      -74.544,
      -7.156,
      35.32,
      -73.344,
      -7.156,
      35.32,
      -73.344,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2201.0",
    polygon: [
      -43.64,
      -6.961,
      25.86,
      -44.197,
      -6.961,
      25.86,
      -44.197,
      -6.961,
      28.32,
      -44.197,
      -6.961,
      28.32,
      -43.64,
      -6.961,
      28.32,
      -43.64,
      -6.961,
      25.86,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1856.0",
    polygon: [
      -76.03,
      -7.156,
      32.86,
      -77.23,
      -7.156,
      32.86,
      -77.23,
      -7.156,
      35.32,
      -77.23,
      -7.156,
      35.32,
      -76.03,
      -7.156,
      35.32,
      -76.03,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2663.0",
    polygon: [
      -50.79,
      -31.213,
      18.088,
      -50.79,
      -32.213,
      18.088,
      -51.998,
      -32.213,
      18.025,
      -51.998,
      -32.213,
      18.025,
      -51.998,
      -31.213,
      18.025,
      -50.79,
      -31.213,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2199.0",
    polygon: [
      -43.64,
      -6.961,
      22.36,
      -44.197,
      -6.961,
      22.36,
      -44.197,
      -6.961,
      24.82,
      -44.197,
      -6.961,
      24.82,
      -43.64,
      -6.961,
      24.82,
      -43.64,
      -6.961,
      22.36,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2782.0",
    polygon: [
      -74.887,
      -7.928,
      15.36,
      -74.887,
      -7.371,
      15.36,
      -74.887,
      -7.371,
      17.82,
      -74.887,
      -7.371,
      17.82,
      -74.887,
      -7.928,
      17.82,
      -74.887,
      -7.928,
      15.36,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1778.0",
    polygon: [
      -55.247,
      -7.155,
      39.128,
      -56.447,
      -7.155,
      39.128,
      -56.447,
      -7.155,
      40.05,
      -56.447,
      -7.155,
      40.05,
      -55.247,
      -7.155,
      40.05,
      -55.247,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1895.0",
    polygon: [
      -14.182,
      -7.155,
      29.36,
      -15.382,
      -7.155,
      29.36,
      -15.382,
      -7.155,
      31.82,
      -15.382,
      -7.155,
      31.82,
      -14.182,
      -7.155,
      31.82,
      -14.182,
      -7.155,
      29.36,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2979.0",
    polygon: [
      -83.118,
      4.738,
      11.86,
      -83.118,
      5.938,
      11.86,
      -83.118,
      5.938,
      14.32,
      -83.118,
      5.938,
      14.32,
      -83.118,
      4.738,
      14.32,
      -83.118,
      4.738,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1858.0",
    polygon: [
      -12.83,
      -7.155,
      18.86,
      -14.03,
      -7.155,
      18.86,
      -14.03,
      -7.155,
      21.32,
      -14.03,
      -7.155,
      21.32,
      -12.83,
      -7.155,
      21.32,
      -12.83,
      -7.155,
      18.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1740.0",
    polygon: [
      -14.182,
      -7.155,
      39.128,
      -15.382,
      -7.155,
      39.128,
      -15.382,
      -7.155,
      40.05,
      -15.382,
      -7.155,
      40.05,
      -14.182,
      -7.155,
      40.05,
      -14.182,
      -7.155,
      39.128,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2547.0",
    polygon: [
      -57.148,
      -21.841,
      18.233,
      -57.148,
      -20.841,
      18.233,
      -53.175,
      -20.841,
      18.025,
      -53.175,
      -20.841,
      18.025,
      -53.175,
      -21.841,
      18.025,
      -57.148,
      -21.841,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1779.0",
    polygon: [
      -55.247,
      -7.155,
      36.36,
      -56.447,
      -7.155,
      36.36,
      -56.447,
      -7.155,
      39.128,
      -56.447,
      -7.155,
      39.128,
      -55.247,
      -7.155,
      39.128,
      -55.247,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2103.0",
    polygon: [
      27.84,
      -7.154,
      15.36,
      26.64,
      -7.154,
      15.36,
      26.64,
      -7.154,
      17.82,
      26.64,
      -7.154,
      17.82,
      27.84,
      -7.154,
      17.82,
      27.84,
      -7.154,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1936.0",
    polygon: [
      -53.895,
      -7.155,
      25.86,
      -55.095,
      -7.155,
      25.86,
      -55.095,
      -7.155,
      28.32,
      -55.095,
      -7.155,
      28.32,
      -53.895,
      -7.155,
      28.32,
      -53.895,
      -7.155,
      25.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1897.0",
    polygon: [
      -16.886,
      -7.155,
      29.36,
      -18.086,
      -7.155,
      29.36,
      -18.086,
      -7.155,
      31.82,
      -18.086,
      -7.155,
      31.82,
      -16.886,
      -7.155,
      31.82,
      -16.886,
      -7.155,
      29.36,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2705.0",
    polygon: [
      -74.884,
      -22.752,
      15.36,
      -74.884,
      -21.602,
      15.36,
      -74.884,
      -21.602,
      17.82,
      -74.884,
      -21.602,
      17.82,
      -74.884,
      -22.752,
      17.82,
      -74.884,
      -22.752,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2064.0",
    polygon: [
      26.488,
      -7.154,
      25.86,
      25.288,
      -7.154,
      25.86,
      25.288,
      -7.154,
      28.32,
      25.288,
      -7.154,
      28.32,
      26.488,
      -7.154,
      28.32,
      26.488,
      -7.154,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "1976.0",
    polygon: [
      -80.086,
      -7.156,
      8.36,
      -81.286,
      -7.156,
      8.36,
      -81.286,
      -7.156,
      10.82,
      -81.286,
      -7.156,
      10.82,
      -80.086,
      -7.156,
      10.82,
      -80.086,
      -7.156,
      8.36,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2470.0",
    polygon: [
      -12.294,
      -23.146,
      18.45,
      -12.294,
      -24.146,
      18.45,
      -16.267,
      -24.146,
      18.241,
      -16.267,
      -24.146,
      18.241,
      -16.267,
      -23.146,
      18.241,
      -12.294,
      -23.146,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2469.0",
    polygon: [
      -19.188,
      -19.689,
      18.088,
      -19.188,
      -20.689,
      18.088,
      -20.396,
      -20.689,
      18.025,
      -20.396,
      -20.689,
      18.025,
      -20.396,
      -19.689,
      18.025,
      -19.188,
      -19.689,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2104.0",
    polygon: [
      26.488,
      -7.154,
      15.36,
      25.288,
      -7.154,
      15.36,
      25.288,
      -7.154,
      17.82,
      25.288,
      -7.154,
      17.82,
      26.488,
      -7.154,
      17.82,
      26.488,
      -7.154,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2880.0",
    polygon: [
      -74.886,
      15.399,
      11.86,
      -74.886,
      16.549,
      11.86,
      -74.886,
      16.549,
      14.32,
      -74.886,
      16.549,
      14.32,
      -74.886,
      15.399,
      14.32,
      -74.886,
      15.399,
      11.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2261.0",
    polygon: [
      -63.369,
      -15.241,
      18.86,
      -64.569,
      -15.241,
      18.86,
      -64.569,
      -15.241,
      21.32,
      -64.569,
      -15.241,
      21.32,
      -63.369,
      -15.241,
      21.32,
      -63.369,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1780.0",
    polygon: [
      -56.599,
      -7.155,
      39.128,
      -57.799,
      -7.155,
      39.128,
      -57.799,
      -7.155,
      40.05,
      -57.799,
      -7.155,
      40.05,
      -56.599,
      -7.155,
      40.05,
      -56.599,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2302.0",
    polygon: [
      -61.716,
      -9.502,
      25.86,
      -61.716,
      -10.752,
      25.86,
      -61.716,
      -10.752,
      28.32,
      -61.716,
      -10.752,
      28.32,
      -61.716,
      -9.502,
      28.32,
      -61.716,
      -9.502,
      25.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2508.0",
    polygon: [
      -12.294,
      -35.823,
      18.45,
      -12.294,
      -36.823,
      18.45,
      -16.267,
      -36.823,
      18.241,
      -16.267,
      -36.823,
      18.241,
      -16.267,
      -35.823,
      18.241,
      -12.294,
      -35.823,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1975.0",
    polygon: [
      -78.734,
      -7.156,
      8.36,
      -79.934,
      -7.156,
      8.36,
      -79.934,
      -7.156,
      10.82,
      -79.934,
      -7.156,
      10.82,
      -78.734,
      -7.156,
      10.82,
      -78.734,
      -7.156,
      8.36,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1818.0",
    polygon: [
      -71.982,
      -7.156,
      32.86,
      -73.182,
      -7.156,
      32.86,
      -73.182,
      -7.156,
      35.32,
      -73.182,
      -7.156,
      35.32,
      -71.982,
      -7.156,
      35.32,
      -71.982,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2664.0",
    polygon: [
      -43.895,
      -34.67,
      18.45,
      -43.895,
      -35.67,
      18.45,
      -47.869,
      -35.67,
      18.241,
      -47.869,
      -35.67,
      18.241,
      -47.869,
      -34.67,
      18.241,
      -43.895,
      -34.67,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2262.0",
    polygon: [
      -64.731,
      -15.241,
      18.86,
      -65.931,
      -15.241,
      18.86,
      -65.931,
      -15.241,
      21.32,
      -65.931,
      -15.241,
      21.32,
      -64.731,
      -15.241,
      21.32,
      -64.731,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2785.0",
    polygon: [
      -75.046,
      -1.13,
      -1.14,
      -75.046,
      0.272,
      -1.14,
      -75.046,
      0.272,
      2.78,
      -75.046,
      0.272,
      2.78,
      -75.046,
      -1.13,
      2.78,
      -75.046,
      -1.13,
      -1.14,
    ],
    "04-15 12:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "2223.0",
    polygon: [
      -68.276,
      -16.256,
      29.36,
      -69.098,
      -16.256,
      29.36,
      -69.098,
      -16.256,
      31.82,
      -69.098,
      -16.256,
      31.82,
      -68.276,
      -16.256,
      31.82,
      -68.276,
      -16.256,
      29.36,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2430.0",
    polygon: [
      -29.675,
      -39.127,
      18.45,
      -29.675,
      -38.127,
      18.45,
      -25.702,
      -38.127,
      18.241,
      -25.702,
      -38.127,
      18.241,
      -25.702,
      -39.127,
      18.241,
      -29.675,
      -39.127,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2587.0",
    polygon: [
      -54.383,
      -34.518,
      18.088,
      -54.383,
      -33.518,
      18.088,
      -53.175,
      -33.518,
      18.025,
      -53.175,
      -33.518,
      18.025,
      -53.175,
      -34.518,
      18.025,
      -54.383,
      -34.518,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2980.0",
    polygon: [
      -83.118,
      0.681,
      15.36,
      -83.118,
      1.881,
      15.36,
      -83.118,
      1.881,
      17.82,
      -83.118,
      1.881,
      17.82,
      -83.118,
      0.681,
      17.82,
      -83.118,
      0.681,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1741.0",
    polygon: [
      -14.182,
      -7.155,
      36.36,
      -15.382,
      -7.155,
      36.36,
      -15.382,
      -7.155,
      39.128,
      -15.382,
      -7.155,
      39.128,
      -14.182,
      -7.155,
      39.128,
      -14.182,
      -7.155,
      36.36,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2625.0",
    polygon: [
      -48.028,
      -17.384,
      18.233,
      -48.028,
      -18.384,
      18.233,
      -49.236,
      -18.384,
      18.17,
      -49.236,
      -18.384,
      18.17,
      -49.236,
      -17.384,
      18.17,
      -48.028,
      -17.384,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2163.0",
    polygon: [
      -34.492,
      -15.241,
      18.86,
      -35.692,
      -15.241,
      18.86,
      -35.692,
      -15.241,
      21.32,
      -35.692,
      -15.241,
      21.32,
      -34.492,
      -15.241,
      21.32,
      -34.492,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2936.0",
    polygon: [
      -83.118,
      -5.365,
      18.86,
      -83.118,
      -4.165,
      18.86,
      -83.118,
      -4.165,
      21.32,
      -83.118,
      -4.165,
      21.32,
      -83.118,
      -5.365,
      21.32,
      -83.118,
      -5.365,
      18.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "2022.0",
    polygon: [
      37.305,
      -7.154,
      32.86,
      36.105,
      -7.154,
      32.86,
      36.105,
      -7.154,
      35.32,
      36.105,
      -7.154,
      35.32,
      37.305,
      -7.154,
      35.32,
      37.305,
      -7.154,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2706.0",
    polygon: [
      -74.884,
      -24.302,
      15.36,
      -74.884,
      -23.152,
      15.36,
      -74.884,
      -23.152,
      17.82,
      -74.884,
      -23.152,
      17.82,
      -74.884,
      -24.302,
      17.82,
      -74.884,
      -24.302,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2340.0",
    polygon: [
      -29.675,
      -16.079,
      18.45,
      -29.675,
      -15.079,
      18.45,
      -25.702,
      -15.079,
      18.241,
      -25.702,
      -15.079,
      18.241,
      -25.702,
      -16.079,
      18.241,
      -29.675,
      -16.079,
      18.45,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "1819.0",
    polygon: [
      -70.621,
      -7.156,
      32.86,
      -71.821,
      -7.156,
      32.86,
      -71.821,
      -7.156,
      35.32,
      -71.821,
      -7.156,
      35.32,
      -70.621,
      -7.156,
      35.32,
      -70.621,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2744.0",
    polygon: [
      -74.884,
      -21.602,
      11.86,
      -74.884,
      -20.452,
      11.86,
      -74.884,
      -20.452,
      14.32,
      -74.884,
      -20.452,
      14.32,
      -74.884,
      -21.602,
      14.32,
      -74.884,
      -21.602,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "2431.0",
    polygon: [
      -25.546,
      -39.127,
      18.233,
      -25.546,
      -38.127,
      18.233,
      -21.573,
      -38.127,
      18.025,
      -21.573,
      -38.127,
      18.025,
      -21.573,
      -39.127,
      18.025,
      -25.546,
      -39.127,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2162.0",
    polygon: [
      -37.66,
      -15.241,
      18.86,
      -38.86,
      -15.241,
      18.86,
      -38.86,
      -15.241,
      21.32,
      -38.86,
      -15.241,
      21.32,
      -37.66,
      -15.241,
      21.32,
      -37.66,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2392.0",
    polygon: [
      -24.165,
      -22.994,
      18.161,
      -24.165,
      -21.994,
      18.161,
      -22.957,
      -21.994,
      18.098,
      -22.957,
      -21.994,
      18.098,
      -22.957,
      -22.994,
      18.098,
      -24.165,
      -22.994,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2841.0",
    polygon: [
      -74.886,
      8.848,
      15.36,
      -74.886,
      9.998,
      15.36,
      -74.886,
      9.998,
      17.82,
      -74.886,
      9.998,
      17.82,
      -74.886,
      8.848,
      17.82,
      -74.886,
      8.848,
      15.36,
    ],
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2665.0",
    polygon: [
      -48.024,
      -34.67,
      18.233,
      -48.024,
      -35.67,
      18.233,
      -51.998,
      -35.67,
      18.025,
      -51.998,
      -35.67,
      18.025,
      -51.998,
      -34.67,
      18.025,
      -48.024,
      -34.67,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1937.0",
    polygon: [
      -55.247,
      -7.155,
      25.86,
      -56.447,
      -7.155,
      25.86,
      -56.447,
      -7.155,
      28.32,
      -56.447,
      -7.155,
      28.32,
      -55.247,
      -7.155,
      28.32,
      -55.247,
      -7.155,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2586.0",
    polygon: [
      -55.767,
      -34.518,
      18.161,
      -55.767,
      -33.518,
      18.161,
      -54.558,
      -33.518,
      18.098,
      -54.558,
      -33.518,
      18.098,
      -54.558,
      -34.518,
      18.098,
      -55.767,
      -34.518,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2626.0",
    polygon: [
      -49.406,
      -17.384,
      18.161,
      -49.406,
      -18.384,
      18.161,
      -50.614,
      -18.384,
      18.098,
      -50.614,
      -18.384,
      18.098,
      -50.614,
      -17.384,
      18.098,
      -49.406,
      -17.384,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2202.0",
    polygon: [
      -75.269,
      -7.052,
      25.86,
      -75.826,
      -7.052,
      25.86,
      -75.826,
      -7.052,
      28.32,
      -75.826,
      -7.052,
      28.32,
      -75.269,
      -7.052,
      28.32,
      -75.269,
      -7.052,
      25.86,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2548.0",
    polygon: [
      -61.277,
      -20.689,
      18.45,
      -61.277,
      -19.689,
      18.45,
      -57.304,
      -19.689,
      18.241,
      -57.304,
      -19.689,
      18.241,
      -57.304,
      -20.689,
      18.241,
      -61.277,
      -20.689,
      18.45,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1859.0",
    polygon: [
      -14.182,
      -7.155,
      18.86,
      -15.382,
      -7.155,
      18.86,
      -15.382,
      -7.155,
      21.32,
      -15.382,
      -7.155,
      21.32,
      -14.182,
      -7.155,
      21.32,
      -14.182,
      -7.155,
      18.86,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2391.0",
    polygon: [
      -25.543,
      -22.994,
      18.233,
      -25.543,
      -21.994,
      18.233,
      -24.335,
      -21.994,
      18.17,
      -24.335,
      -21.994,
      18.17,
      -24.335,
      -22.994,
      18.17,
      -25.543,
      -22.994,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2065.0",
    polygon: [
      25.136,
      -7.154,
      25.86,
      23.936,
      -7.154,
      25.86,
      23.936,
      -7.154,
      28.32,
      23.936,
      -7.154,
      28.32,
      25.136,
      -7.154,
      28.32,
      25.136,
      -7.154,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2341.0",
    polygon: [
      -25.543,
      -16.079,
      18.233,
      -25.543,
      -15.079,
      18.233,
      -24.335,
      -15.079,
      18.17,
      -24.335,
      -15.079,
      18.17,
      -24.335,
      -16.079,
      18.17,
      -25.543,
      -16.079,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2301.0",
    polygon: [
      -61.716,
      -8.252,
      25.86,
      -61.716,
      -9.502,
      25.86,
      -61.716,
      -9.502,
      28.32,
      -61.716,
      -9.502,
      28.32,
      -61.716,
      -8.252,
      28.32,
      -61.716,
      -8.252,
      25.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2509.0",
    polygon: [
      -16.426,
      -35.823,
      18.233,
      -16.426,
      -36.823,
      18.233,
      -17.634,
      -36.823,
      18.17,
      -17.634,
      -36.823,
      18.17,
      -17.634,
      -35.823,
      18.17,
      -16.426,
      -35.823,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1898.0",
    polygon: [
      -18.238,
      -7.155,
      29.36,
      -19.438,
      -7.155,
      29.36,
      -19.438,
      -7.155,
      31.82,
      -19.438,
      -7.155,
      31.82,
      -18.238,
      -7.155,
      31.82,
      -18.238,
      -7.155,
      29.36,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2265.0",
    polygon: [
      -11.74,
      -7.925,
      18.86,
      -11.74,
      -7.368,
      18.86,
      -11.74,
      -7.368,
      21.32,
      -11.74,
      -7.368,
      21.32,
      -11.74,
      -7.925,
      21.32,
      -11.74,
      -7.925,
      18.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2304.0",
    polygon: [
      -43.346,
      -7.925,
      25.86,
      -43.346,
      -7.368,
      25.86,
      -43.346,
      -7.368,
      28.32,
      -43.346,
      -7.368,
      28.32,
      -43.346,
      -7.925,
      28.32,
      -43.346,
      -7.925,
      25.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2165.0",
    polygon: [
      -31.769,
      -15.241,
      18.86,
      -32.969,
      -15.241,
      18.86,
      -32.969,
      -15.241,
      21.32,
      -32.969,
      -15.241,
      21.32,
      -31.769,
      -15.241,
      21.32,
      -31.769,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2472.0",
    polygon: [
      -12.294,
      -21.994,
      18.45,
      -12.294,
      -22.994,
      18.45,
      -16.267,
      -22.994,
      18.241,
      -16.267,
      -22.994,
      18.241,
      -16.267,
      -21.994,
      18.241,
      -12.294,
      -21.994,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1821.0",
    polygon: [
      -66.092,
      -7.156,
      32.86,
      -67.292,
      -7.156,
      32.86,
      -67.292,
      -7.156,
      35.32,
      -67.292,
      -7.156,
      35.32,
      -66.092,
      -7.156,
      35.32,
      -66.092,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1978.0",
    polygon: [
      -76.03,
      -7.156,
      8.36,
      -77.23,
      -7.156,
      8.36,
      -77.23,
      -7.156,
      10.82,
      -77.23,
      -7.156,
      10.82,
      -76.03,
      -7.156,
      10.82,
      -76.03,
      -7.156,
      8.36,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1782.0",
    polygon: [
      -57.951,
      -7.155,
      39.128,
      -59.151,
      -7.155,
      39.128,
      -59.151,
      -7.155,
      40.05,
      -59.151,
      -7.155,
      40.05,
      -57.951,
      -7.155,
      40.05,
      -57.951,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2105.0",
    polygon: [
      25.136,
      -7.154,
      15.36,
      23.936,
      -7.154,
      15.36,
      23.936,
      -7.154,
      17.82,
      23.936,
      -7.154,
      17.82,
      25.136,
      -7.154,
      17.82,
      25.136,
      -7.154,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1939.0",
    polygon: [
      -57.951,
      -7.155,
      25.86,
      -59.151,
      -7.155,
      25.86,
      -59.151,
      -7.155,
      28.32,
      -59.151,
      -7.155,
      28.32,
      -57.951,
      -7.155,
      28.32,
      -57.951,
      -7.155,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "2588.0",
    polygon: [
      -61.277,
      -37.975,
      18.45,
      -61.277,
      -36.975,
      18.45,
      -57.304,
      -36.975,
      18.241,
      -57.304,
      -36.975,
      18.241,
      -57.304,
      -37.975,
      18.241,
      -61.277,
      -37.975,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2666.0",
    polygon: [
      -43.895,
      -33.518,
      18.45,
      -43.895,
      -34.518,
      18.45,
      -47.869,
      -34.518,
      18.241,
      -47.869,
      -34.518,
      18.241,
      -47.869,
      -33.518,
      18.241,
      -43.895,
      -33.518,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2354.0",
    polygon: [
      -24.165,
      -16.079,
      18.161,
      -24.165,
      -15.079,
      18.161,
      -22.957,
      -15.079,
      18.098,
      -22.957,
      -15.079,
      18.098,
      -22.957,
      -16.079,
      18.098,
      -24.165,
      -16.079,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2066.0",
    polygon: [
      37.305,
      -7.154,
      29.36,
      36.105,
      -7.154,
      29.36,
      36.105,
      -7.154,
      31.82,
      36.105,
      -7.154,
      31.82,
      37.305,
      -7.154,
      31.82,
      37.305,
      -7.154,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2224.0",
    polygon: [
      -67.453,
      -16.256,
      29.36,
      -68.276,
      -16.256,
      29.36,
      -68.276,
      -16.256,
      31.82,
      -68.276,
      -16.256,
      31.82,
      -67.453,
      -16.256,
      31.82,
      -67.453,
      -16.256,
      29.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2432.0",
    polygon: [
      -12.294,
      -16.232,
      18.45,
      -12.294,
      -17.232,
      18.45,
      -16.267,
      -17.232,
      18.241,
      -16.267,
      -17.232,
      18.241,
      -16.267,
      -16.232,
      18.241,
      -12.294,
      -16.232,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2023.0",
    polygon: [
      35.953,
      -7.154,
      32.86,
      34.753,
      -7.154,
      32.86,
      34.753,
      -7.154,
      35.32,
      34.753,
      -7.154,
      35.32,
      35.953,
      -7.154,
      35.32,
      35.953,
      -7.154,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2791.0",
    polygon: [
      -74.981,
      -22.959,
      2.78,
      -74.981,
      -20.639,
      2.78,
      -74.981,
      -20.639,
      3.473,
      -74.981,
      -20.639,
      3.473,
      -74.981,
      -22.959,
      3.473,
      -74.981,
      -22.959,
      2.78,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2024.0",
    polygon: [
      34.601,
      -7.154,
      32.86,
      33.401,
      -7.154,
      32.86,
      33.401,
      -7.154,
      35.32,
      33.401,
      -7.154,
      35.32,
      34.601,
      -7.154,
      35.32,
      34.601,
      -7.154,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1820.0",
    polygon: [
      -69.26,
      -7.156,
      32.86,
      -70.46,
      -7.156,
      32.86,
      -70.46,
      -7.156,
      35.32,
      -70.46,
      -7.156,
      35.32,
      -69.26,
      -7.156,
      35.32,
      -69.26,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2881.0",
    polygon: [
      -74.886,
      14.249,
      11.86,
      -74.886,
      15.399,
      11.86,
      -74.886,
      15.399,
      14.32,
      -74.886,
      15.399,
      14.32,
      -74.886,
      14.249,
      14.32,
      -74.886,
      14.249,
      11.86,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2882.0",
    polygon: [
      -74.886,
      18.099,
      11.86,
      -74.886,
      19.249,
      11.86,
      -74.886,
      19.249,
      14.32,
      -74.886,
      19.249,
      14.32,
      -74.886,
      18.099,
      14.32,
      -74.886,
      18.099,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1781.0",
    polygon: [
      -56.599,
      -7.155,
      36.36,
      -57.799,
      -7.155,
      36.36,
      -57.799,
      -7.155,
      39.128,
      -57.799,
      -7.155,
      39.128,
      -56.599,
      -7.155,
      39.128,
      -56.599,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2982.0",
    polygon: [
      -83.118,
      3.386,
      15.36,
      -83.118,
      4.586,
      15.36,
      -83.118,
      4.586,
      17.82,
      -83.118,
      4.586,
      17.82,
      -83.118,
      3.386,
      17.82,
      -83.118,
      3.386,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2787.0",
    polygon: [
      -74.981,
      -25.279,
      3.473,
      -74.981,
      -22.959,
      3.473,
      -74.981,
      -22.959,
      4.167,
      -74.981,
      -22.959,
      4.167,
      -74.981,
      -25.279,
      4.167,
      -74.981,
      -25.279,
      3.473,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1938.0",
    polygon: [
      -56.599,
      -7.155,
      25.86,
      -57.799,
      -7.155,
      25.86,
      -57.799,
      -7.155,
      28.32,
      -57.799,
      -7.155,
      28.32,
      -56.599,
      -7.155,
      28.32,
      -56.599,
      -7.155,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2843.0",
    polygon: [
      -74.886,
      11.549,
      15.36,
      -74.886,
      12.699,
      15.36,
      -74.886,
      12.699,
      17.82,
      -74.886,
      12.699,
      17.82,
      -74.886,
      11.549,
      17.82,
      -74.886,
      11.549,
      15.36,
    ],
    "04-15 14:00": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2842.0",
    polygon: [
      -74.886,
      12.699,
      15.36,
      -74.886,
      13.849,
      15.36,
      -74.886,
      13.849,
      17.82,
      -74.886,
      13.849,
      17.82,
      -74.886,
      12.699,
      17.82,
      -74.886,
      12.699,
      15.36,
    ],
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "3021.0",
    polygon: [
      -82.012,
      0.144,
      18.86,
      -83.112,
      0.144,
      18.86,
      -83.112,
      0.144,
      21.32,
      -83.112,
      0.144,
      21.32,
      -82.012,
      0.144,
      21.32,
      -82.012,
      0.144,
      18.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1977.0",
    polygon: [
      -81.438,
      -7.156,
      8.36,
      -82.638,
      -7.156,
      8.36,
      -82.638,
      -7.156,
      10.82,
      -82.638,
      -7.156,
      10.82,
      -81.438,
      -7.156,
      10.82,
      -81.438,
      -7.156,
      8.36,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2844.0",
    polygon: [
      -74.886,
      15.399,
      15.36,
      -74.886,
      16.549,
      15.36,
      -74.886,
      16.549,
      17.82,
      -74.886,
      16.549,
      17.82,
      -74.886,
      15.399,
      17.82,
      -74.886,
      15.399,
      15.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2471.0",
    polygon: [
      -16.423,
      -23.146,
      18.233,
      -16.423,
      -24.146,
      18.233,
      -20.396,
      -24.146,
      18.025,
      -20.396,
      -24.146,
      18.025,
      -20.396,
      -23.146,
      18.025,
      -16.423,
      -23.146,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2708.0",
    polygon: [
      -74.884,
      -27.002,
      15.36,
      -74.884,
      -25.852,
      15.36,
      -74.884,
      -25.852,
      17.82,
      -74.884,
      -25.852,
      17.82,
      -74.884,
      -27.002,
      17.82,
      -74.884,
      -27.002,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "1861.0",
    polygon: [
      -16.886,
      -7.155,
      18.86,
      -18.086,
      -7.155,
      18.86,
      -18.086,
      -7.155,
      21.32,
      -18.086,
      -7.155,
      21.32,
      -16.886,
      -7.155,
      21.32,
      -16.886,
      -7.155,
      18.86,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1742.0",
    polygon: [
      -15.534,
      -7.155,
      39.128,
      -16.734,
      -7.155,
      39.128,
      -16.734,
      -7.155,
      40.05,
      -16.734,
      -7.155,
      40.05,
      -15.534,
      -7.155,
      40.05,
      -15.534,
      -7.155,
      39.128,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2067.0",
    polygon: [
      35.953,
      -7.154,
      29.36,
      34.753,
      -7.154,
      29.36,
      34.753,
      -7.154,
      31.82,
      34.753,
      -7.154,
      31.82,
      35.953,
      -7.154,
      31.82,
      35.953,
      -7.154,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2164.0",
    polygon: [
      -33.131,
      -15.241,
      18.86,
      -34.331,
      -15.241,
      18.86,
      -34.331,
      -15.241,
      21.32,
      -34.331,
      -15.241,
      21.32,
      -33.131,
      -15.241,
      21.32,
      -33.131,
      -15.241,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2225.0",
    polygon: [
      -73.344,
      -16.256,
      29.36,
      -74.544,
      -16.256,
      29.36,
      -74.544,
      -16.256,
      31.82,
      -74.544,
      -16.256,
      31.82,
      -73.344,
      -16.256,
      31.82,
      -73.344,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2433.0",
    polygon: [
      -16.423,
      -16.232,
      18.233,
      -16.423,
      -17.232,
      18.233,
      -20.396,
      -17.232,
      18.025,
      -20.396,
      -17.232,
      18.025,
      -20.396,
      -16.232,
      18.025,
      -16.423,
      -16.232,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2068.0",
    polygon: [
      34.601,
      -7.154,
      29.36,
      33.401,
      -7.154,
      29.36,
      33.401,
      -7.154,
      31.82,
      33.401,
      -7.154,
      31.82,
      34.601,
      -7.154,
      31.82,
      34.601,
      -7.154,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1783.0",
    polygon: [
      -57.951,
      -7.155,
      36.36,
      -59.151,
      -7.155,
      36.36,
      -59.151,
      -7.155,
      39.128,
      -59.151,
      -7.155,
      39.128,
      -57.951,
      -7.155,
      39.128,
      -57.951,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2883.0",
    polygon: [
      -74.886,
      16.949,
      11.86,
      -74.886,
      18.099,
      11.86,
      -74.886,
      18.099,
      14.32,
      -74.886,
      18.099,
      14.32,
      -74.886,
      16.949,
      14.32,
      -74.886,
      16.949,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1940.0",
    polygon: [
      -59.304,
      -7.155,
      25.86,
      -60.504,
      -7.155,
      25.86,
      -60.504,
      -7.155,
      28.32,
      -60.504,
      -7.155,
      28.32,
      -59.304,
      -7.155,
      28.32,
      -59.304,
      -7.155,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "1860.0",
    polygon: [
      -15.534,
      -7.155,
      18.86,
      -16.734,
      -7.155,
      18.86,
      -16.734,
      -7.155,
      21.32,
      -16.734,
      -7.155,
      21.32,
      -15.534,
      -7.155,
      21.32,
      -15.534,
      -7.155,
      18.86,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2303.0",
    polygon: [
      -61.716,
      -10.752,
      25.86,
      -61.716,
      -12.002,
      25.86,
      -61.716,
      -12.002,
      28.32,
      -61.716,
      -12.002,
      28.32,
      -61.716,
      -10.752,
      28.32,
      -61.716,
      -10.752,
      25.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2667.0",
    polygon: [
      -48.028,
      -33.518,
      18.233,
      -48.028,
      -34.518,
      18.233,
      -49.236,
      -34.518,
      18.17,
      -49.236,
      -34.518,
      18.17,
      -49.236,
      -33.518,
      18.17,
      -48.028,
      -33.518,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1900.0",
    polygon: [
      -20.943,
      -7.155,
      29.36,
      -22.143,
      -7.155,
      29.36,
      -22.143,
      -7.155,
      31.82,
      -22.143,
      -7.155,
      31.82,
      -20.943,
      -7.155,
      31.82,
      -20.943,
      -7.155,
      29.36,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2792.0",
    polygon: [
      -74.981,
      -21.459,
      -0.35,
      -74.981,
      -20.639,
      -0.35,
      -74.981,
      -20.639,
      2.78,
      -74.981,
      -20.639,
      2.78,
      -74.981,
      -21.459,
      2.78,
      -74.981,
      -21.459,
      -0.35,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2511.0",
    polygon: [
      -19.188,
      -35.823,
      18.088,
      -19.188,
      -36.823,
      18.088,
      -20.396,
      -36.823,
      18.025,
      -20.396,
      -36.823,
      18.025,
      -20.396,
      -35.823,
      18.025,
      -19.188,
      -35.823,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2589.0",
    polygon: [
      -57.148,
      -37.975,
      18.233,
      -57.148,
      -36.975,
      18.233,
      -53.175,
      -36.975,
      18.025,
      -53.175,
      -36.975,
      18.025,
      -53.175,
      -37.975,
      18.025,
      -57.148,
      -37.975,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2393.0",
    polygon: [
      -22.781,
      -22.994,
      18.088,
      -22.781,
      -21.994,
      18.088,
      -21.573,
      -21.994,
      18.025,
      -21.573,
      -21.994,
      18.025,
      -21.573,
      -22.994,
      18.025,
      -22.781,
      -22.994,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2263.0",
    polygon: [
      -74.807,
      -7.928,
      18.86,
      -74.807,
      -7.371,
      18.86,
      -74.807,
      -7.371,
      21.32,
      -74.807,
      -7.371,
      21.32,
      -74.807,
      -7.928,
      21.32,
      -74.807,
      -7.928,
      18.86,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1743.0",
    polygon: [
      -15.534,
      -7.155,
      36.36,
      -16.734,
      -7.155,
      36.36,
      -16.734,
      -7.155,
      39.128,
      -16.734,
      -7.155,
      39.128,
      -15.534,
      -7.155,
      39.128,
      -15.534,
      -7.155,
      36.36,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2550.0",
    polygon: [
      -55.767,
      -20.689,
      18.161,
      -55.767,
      -19.689,
      18.161,
      -54.558,
      -19.689,
      18.098,
      -54.558,
      -19.689,
      18.098,
      -54.558,
      -20.689,
      18.098,
      -55.767,
      -20.689,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1899.0",
    polygon: [
      -19.59,
      -7.155,
      29.36,
      -20.79,
      -7.155,
      29.36,
      -20.79,
      -7.155,
      31.82,
      -20.79,
      -7.155,
      31.82,
      -19.59,
      -7.155,
      31.82,
      -19.59,
      -7.155,
      29.36,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2116.0",
    polygon: [
      -36.676,
      -16.256,
      29.36,
      -37.498,
      -16.256,
      29.36,
      -37.498,
      -16.256,
      31.82,
      -37.498,
      -16.256,
      31.82,
      -36.676,
      -16.256,
      31.82,
      -36.676,
      -16.256,
      29.36,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1744.0",
    polygon: [
      -16.886,
      -7.155,
      39.128,
      -18.086,
      -7.155,
      39.128,
      -18.086,
      -7.155,
      40.05,
      -18.086,
      -7.155,
      40.05,
      -16.886,
      -7.155,
      40.05,
      -16.886,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2937.0",
    polygon: [
      -83.118,
      -4.012,
      18.86,
      -83.118,
      -2.812,
      18.86,
      -83.118,
      -2.812,
      21.32,
      -83.118,
      -2.812,
      21.32,
      -83.118,
      -4.012,
      21.32,
      -83.118,
      -4.012,
      18.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "2590.0",
    polygon: [
      -61.277,
      -36.823,
      18.45,
      -61.277,
      -35.823,
      18.45,
      -57.304,
      -35.823,
      18.241,
      -57.304,
      -35.823,
      18.241,
      -57.304,
      -36.823,
      18.241,
      -61.277,
      -36.823,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2884.0",
    polygon: [
      -74.886,
      20.799,
      11.86,
      -74.886,
      21.949,
      11.86,
      -74.886,
      21.949,
      14.32,
      -74.886,
      21.949,
      14.32,
      -74.886,
      20.799,
      14.32,
      -74.886,
      20.799,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2166.0",
    polygon: [
      33.249,
      -7.154,
      4.86,
      32.049,
      -7.154,
      4.86,
      32.049,
      -7.154,
      7.32,
      32.049,
      -7.154,
      7.32,
      33.249,
      -7.154,
      7.32,
      33.249,
      -7.154,
      4.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1823.0",
    polygon: [
      -63.369,
      -7.156,
      32.86,
      -64.569,
      -7.156,
      32.86,
      -64.569,
      -7.156,
      35.32,
      -64.569,
      -7.156,
      35.32,
      -63.369,
      -7.156,
      35.32,
      -63.369,
      -7.156,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2709.0",
    polygon: [
      -74.884,
      -28.152,
      15.36,
      -74.884,
      -27.002,
      15.36,
      -74.884,
      -27.002,
      17.82,
      -74.884,
      -27.002,
      17.82,
      -74.884,
      -28.152,
      17.82,
      -74.884,
      -28.152,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2627.0",
    polygon: [
      -50.79,
      -17.384,
      18.088,
      -50.79,
      -18.384,
      18.088,
      -51.998,
      -18.384,
      18.025,
      -51.998,
      -18.384,
      18.025,
      -51.998,
      -17.384,
      18.025,
      -50.79,
      -17.384,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2551.0",
    polygon: [
      -54.383,
      -20.689,
      18.088,
      -54.383,
      -19.689,
      18.088,
      -53.175,
      -19.689,
      18.025,
      -53.175,
      -19.689,
      18.025,
      -53.175,
      -20.689,
      18.025,
      -54.383,
      -20.689,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2355.0",
    polygon: [
      -22.781,
      -16.079,
      18.088,
      -22.781,
      -15.079,
      18.088,
      -21.573,
      -15.079,
      18.025,
      -21.573,
      -15.079,
      18.025,
      -21.573,
      -16.079,
      18.025,
      -22.781,
      -16.079,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1979.0",
    polygon: [
      -77.382,
      -7.156,
      11.86,
      -78.582,
      -7.156,
      11.86,
      -78.582,
      -7.156,
      14.32,
      -78.582,
      -7.156,
      14.32,
      -77.382,
      -7.156,
      14.32,
      -77.382,
      -7.156,
      11.86,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2025.0",
    polygon: [
      33.249,
      -7.154,
      32.86,
      32.049,
      -7.154,
      32.86,
      32.049,
      -7.154,
      35.32,
      32.049,
      -7.154,
      35.32,
      33.249,
      -7.154,
      35.32,
      33.249,
      -7.154,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2394.0",
    polygon: [
      -29.675,
      -26.451,
      18.45,
      -29.675,
      -25.451,
      18.45,
      -25.702,
      -25.451,
      18.241,
      -25.702,
      -25.451,
      18.241,
      -25.702,
      -26.451,
      18.241,
      -29.675,
      -26.451,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1862.0",
    polygon: [
      -18.238,
      -7.155,
      18.86,
      -19.438,
      -7.155,
      18.86,
      -19.438,
      -7.155,
      21.32,
      -19.438,
      -7.155,
      21.32,
      -18.238,
      -7.155,
      21.32,
      -18.238,
      -7.155,
      18.86,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2669.0",
    polygon: [
      -50.79,
      -33.518,
      18.088,
      -50.79,
      -34.518,
      18.088,
      -51.998,
      -34.518,
      18.025,
      -51.998,
      -34.518,
      18.025,
      -51.998,
      -33.518,
      18.025,
      -50.79,
      -33.518,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2748.0",
    polygon: [
      -74.884,
      -27.002,
      11.86,
      -74.884,
      -25.852,
      11.86,
      -74.884,
      -25.852,
      14.32,
      -74.884,
      -25.852,
      14.32,
      -74.884,
      -27.002,
      14.32,
      -74.884,
      -27.002,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2981.0",
    polygon: [
      -83.118,
      2.033,
      15.36,
      -83.118,
      3.233,
      15.36,
      -83.118,
      3.233,
      17.82,
      -83.118,
      3.233,
      17.82,
      -83.118,
      2.033,
      17.82,
      -83.118,
      2.033,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2552.0",
    polygon: [
      -61.277,
      -24.146,
      18.45,
      -61.277,
      -23.146,
      18.45,
      -57.304,
      -23.146,
      18.241,
      -57.304,
      -23.146,
      18.241,
      -57.304,
      -24.146,
      18.241,
      -61.277,
      -24.146,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1824.0",
    polygon: [
      -12.83,
      -7.155,
      32.86,
      -14.03,
      -7.155,
      32.86,
      -14.03,
      -7.155,
      35.32,
      -14.03,
      -7.155,
      35.32,
      -12.83,
      -7.155,
      35.32,
      -12.83,
      -7.155,
      32.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2549.0",
    polygon: [
      -57.145,
      -20.689,
      18.233,
      -57.145,
      -19.689,
      18.233,
      -55.936,
      -19.689,
      18.17,
      -55.936,
      -19.689,
      18.17,
      -55.936,
      -20.689,
      18.17,
      -57.145,
      -20.689,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2117.0",
    polygon: [
      -35.853,
      -16.256,
      29.36,
      -36.676,
      -16.256,
      29.36,
      -36.676,
      -16.256,
      31.82,
      -36.676,
      -16.256,
      31.82,
      -35.853,
      -16.256,
      31.82,
      -35.853,
      -16.256,
      29.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2069.0",
    polygon: [
      33.249,
      -7.154,
      29.36,
      32.049,
      -7.154,
      29.36,
      32.049,
      -7.154,
      31.82,
      32.049,
      -7.154,
      31.82,
      33.249,
      -7.154,
      31.82,
      33.249,
      -7.154,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2434.0",
    polygon: [
      -12.294,
      -15.079,
      18.45,
      -12.294,
      -16.079,
      18.45,
      -16.267,
      -16.079,
      18.241,
      -16.267,
      -16.079,
      18.241,
      -16.267,
      -15.079,
      18.241,
      -12.294,
      -15.079,
      18.45,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "1745.0",
    polygon: [
      -16.886,
      -7.155,
      36.36,
      -18.086,
      -7.155,
      36.36,
      -18.086,
      -7.155,
      39.128,
      -18.086,
      -7.155,
      39.128,
      -16.886,
      -7.155,
      39.128,
      -16.886,
      -7.155,
      36.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2628.0",
    polygon: [
      -43.895,
      -20.841,
      18.45,
      -43.895,
      -21.841,
      18.45,
      -47.869,
      -21.841,
      18.241,
      -47.869,
      -21.841,
      18.241,
      -47.869,
      -20.841,
      18.241,
      -43.895,
      -20.841,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2305.0",
    polygon: [
      -43.237,
      -10.802,
      25.86,
      -43.237,
      -9.652,
      25.86,
      -43.237,
      -9.652,
      28.32,
      -43.237,
      -9.652,
      28.32,
      -43.237,
      -10.802,
      28.32,
      -43.237,
      -10.802,
      25.86,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1901.0",
    polygon: [
      -22.295,
      -7.155,
      29.36,
      -23.495,
      -7.155,
      29.36,
      -23.495,
      -7.155,
      31.82,
      -23.495,
      -7.155,
      31.82,
      -22.295,
      -7.155,
      31.82,
      -22.295,
      -7.155,
      29.36,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2745.0",
    polygon: [
      -74.884,
      -22.752,
      11.86,
      -74.884,
      -21.602,
      11.86,
      -74.884,
      -21.602,
      14.32,
      -74.884,
      -21.602,
      14.32,
      -74.884,
      -22.752,
      14.32,
      -74.884,
      -22.752,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2395.0",
    polygon: [
      -25.546,
      -26.451,
      18.233,
      -25.546,
      -25.451,
      18.233,
      -21.573,
      -25.451,
      18.025,
      -21.573,
      -25.451,
      18.025,
      -21.573,
      -26.451,
      18.025,
      -25.546,
      -26.451,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2512.0",
    polygon: [
      -12.294,
      -38.127,
      18.45,
      -12.294,
      -38.627,
      18.45,
      -16.267,
      -38.627,
      18.241,
      -16.267,
      -38.627,
      18.241,
      -16.267,
      -38.127,
      18.241,
      -12.294,
      -38.127,
      18.45,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2707.0",
    polygon: [
      -74.884,
      -25.452,
      15.36,
      -74.884,
      -24.302,
      15.36,
      -74.884,
      -24.302,
      17.82,
      -74.884,
      -24.302,
      17.82,
      -74.884,
      -25.452,
      17.82,
      -74.884,
      -25.452,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2473.0",
    polygon: [
      -16.426,
      -21.994,
      18.233,
      -16.426,
      -22.994,
      18.233,
      -17.634,
      -22.994,
      18.17,
      -17.634,
      -22.994,
      18.17,
      -17.634,
      -21.994,
      18.17,
      -16.426,
      -21.994,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2629.0",
    polygon: [
      -48.024,
      -20.841,
      18.233,
      -48.024,
      -21.841,
      18.233,
      -51.998,
      -21.841,
      18.025,
      -51.998,
      -21.841,
      18.025,
      -51.998,
      -20.841,
      18.025,
      -48.024,
      -20.841,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1746.0",
    polygon: [
      -18.238,
      -7.155,
      39.128,
      -19.438,
      -7.155,
      39.128,
      -19.438,
      -7.155,
      40.05,
      -19.438,
      -7.155,
      40.05,
      -18.238,
      -7.155,
      40.05,
      -18.238,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2514.0",
    polygon: [
      -61.277,
      -17.232,
      18.45,
      -61.277,
      -16.232,
      18.45,
      -57.304,
      -16.232,
      18.241,
      -57.304,
      -16.232,
      18.241,
      -57.304,
      -17.232,
      18.241,
      -61.277,
      -17.232,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2984.0",
    polygon: [
      -83.118,
      0.681,
      18.86,
      -83.118,
      1.881,
      18.86,
      -83.118,
      1.881,
      21.32,
      -83.118,
      1.881,
      21.32,
      -83.118,
      0.681,
      21.32,
      -83.118,
      0.681,
      18.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2938.0",
    polygon: [
      -83.118,
      -2.66,
      18.86,
      -83.118,
      -1.46,
      18.86,
      -83.118,
      -1.46,
      21.32,
      -83.118,
      -1.46,
      21.32,
      -83.118,
      -2.66,
      21.32,
      -83.118,
      -2.66,
      18.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2749.0",
    polygon: [
      -74.884,
      -28.152,
      11.86,
      -74.884,
      -27.002,
      11.86,
      -74.884,
      -27.002,
      14.32,
      -74.884,
      -27.002,
      14.32,
      -74.884,
      -28.152,
      14.32,
      -74.884,
      -28.152,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2630.0",
    polygon: [
      -43.895,
      -19.689,
      18.45,
      -43.895,
      -20.689,
      18.45,
      -47.869,
      -20.689,
      18.241,
      -47.869,
      -20.689,
      18.241,
      -47.869,
      -19.689,
      18.241,
      -43.895,
      -19.689,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2939.0",
    polygon: [
      -83.118,
      -6.717,
      22.36,
      -83.118,
      -5.517,
      22.36,
      -83.118,
      -5.517,
      24.82,
      -83.118,
      -5.517,
      24.82,
      -83.118,
      -6.717,
      24.82,
      -83.118,
      -6.717,
      22.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2474.0",
    polygon: [
      -17.804,
      -21.994,
      18.161,
      -17.804,
      -22.994,
      18.161,
      -19.012,
      -22.994,
      18.098,
      -19.012,
      -22.994,
      18.098,
      -19.012,
      -21.994,
      18.098,
      -17.804,
      -21.994,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1941.0",
    polygon: [
      -44.43,
      -7.155,
      25.86,
      -45.63,
      -7.155,
      25.86,
      -45.63,
      -7.155,
      28.32,
      -45.63,
      -7.155,
      28.32,
      -44.43,
      -7.155,
      28.32,
      -44.43,
      -7.155,
      25.86,
    ],
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2268.0",
    polygon: [
      -30.129,
      -8.252,
      18.86,
      -30.129,
      -9.502,
      18.86,
      -30.129,
      -9.502,
      21.32,
      -30.129,
      -9.502,
      21.32,
      -30.129,
      -8.252,
      21.32,
      -30.129,
      -8.252,
      18.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2226.0",
    polygon: [
      -62.008,
      -16.256,
      29.36,
      -63.208,
      -16.256,
      29.36,
      -63.208,
      -16.256,
      31.82,
      -63.208,
      -16.256,
      31.82,
      -62.008,
      -16.256,
      31.82,
      -62.008,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1981.0",
    polygon: [
      -80.086,
      -7.156,
      11.86,
      -81.286,
      -7.156,
      11.86,
      -81.286,
      -7.156,
      14.32,
      -81.286,
      -7.156,
      14.32,
      -80.086,
      -7.156,
      14.32,
      -80.086,
      -7.156,
      11.86,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2356.0",
    polygon: [
      -29.675,
      -14.927,
      18.45,
      -29.675,
      -13.927,
      18.45,
      -25.702,
      -13.927,
      18.241,
      -25.702,
      -13.927,
      18.241,
      -25.702,
      -14.927,
      18.241,
      -29.675,
      -14.927,
      18.45,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2513.0",
    polygon: [
      -16.423,
      -38.127,
      18.233,
      -16.423,
      -38.627,
      18.233,
      -20.396,
      -38.627,
      18.025,
      -20.396,
      -38.627,
      18.025,
      -20.396,
      -38.127,
      18.025,
      -16.423,
      -38.127,
      18.233,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2747.0",
    polygon: [
      -74.884,
      -25.452,
      11.86,
      -74.884,
      -24.302,
      11.86,
      -74.884,
      -24.302,
      14.32,
      -74.884,
      -24.302,
      14.32,
      -74.884,
      -25.452,
      14.32,
      -74.884,
      -25.452,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2846.0",
    polygon: [
      -74.886,
      18.099,
      15.36,
      -74.886,
      19.249,
      15.36,
      -74.886,
      19.249,
      17.82,
      -74.886,
      19.249,
      17.82,
      -74.886,
      18.099,
      17.82,
      -74.886,
      18.099,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1902.0",
    polygon: [
      -23.647,
      -7.155,
      29.36,
      -24.847,
      -7.155,
      29.36,
      -24.847,
      -7.155,
      31.82,
      -24.847,
      -7.155,
      31.82,
      -23.647,
      -7.155,
      31.82,
      -23.647,
      -7.155,
      29.36,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2396.0",
    polygon: [
      -29.675,
      -25.299,
      18.45,
      -29.675,
      -24.299,
      18.45,
      -25.702,
      -24.299,
      18.241,
      -25.702,
      -24.299,
      18.241,
      -25.702,
      -25.299,
      18.241,
      -29.675,
      -25.299,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2510.0",
    polygon: [
      -17.804,
      -35.823,
      18.161,
      -17.804,
      -36.823,
      18.161,
      -19.012,
      -36.823,
      18.098,
      -19.012,
      -36.823,
      18.098,
      -19.012,
      -35.823,
      18.098,
      -17.804,
      -35.823,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2940.0",
    polygon: [
      -83.118,
      -5.365,
      22.36,
      -83.118,
      -4.165,
      22.36,
      -83.118,
      -4.165,
      24.82,
      -83.118,
      -4.165,
      24.82,
      -83.118,
      -5.365,
      24.82,
      -83.118,
      -5.365,
      22.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2167.0",
    polygon: [
      31.897,
      -7.154,
      4.86,
      30.697,
      -7.154,
      4.86,
      30.697,
      -7.154,
      7.32,
      30.697,
      -7.154,
      7.32,
      31.897,
      -7.154,
      7.32,
      31.897,
      -7.154,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2266.0",
    polygon: [
      -11.63,
      -10.802,
      18.86,
      -11.63,
      -9.652,
      18.86,
      -11.63,
      -9.652,
      21.32,
      -11.63,
      -9.652,
      21.32,
      -11.63,
      -10.802,
      21.32,
      -11.63,
      -10.802,
      18.86,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2357.0",
    polygon: [
      -25.546,
      -14.927,
      18.233,
      -25.546,
      -13.927,
      18.233,
      -21.573,
      -13.927,
      18.025,
      -21.573,
      -13.927,
      18.025,
      -21.573,
      -14.927,
      18.025,
      -25.546,
      -14.927,
      18.233,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2885.0",
    polygon: [
      -74.886,
      19.649,
      11.86,
      -74.886,
      20.799,
      11.86,
      -74.886,
      20.799,
      14.32,
      -74.886,
      20.799,
      14.32,
      -74.886,
      19.649,
      14.32,
      -74.886,
      19.649,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2668.0",
    polygon: [
      -49.406,
      -33.518,
      18.161,
      -49.406,
      -34.518,
      18.161,
      -50.614,
      -34.518,
      18.098,
      -50.614,
      -34.518,
      18.098,
      -50.614,
      -33.518,
      18.098,
      -49.406,
      -33.518,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1863.0",
    polygon: [
      -19.59,
      -7.155,
      18.86,
      -20.79,
      -7.155,
      18.86,
      -20.79,
      -7.155,
      21.32,
      -20.79,
      -7.155,
      21.32,
      -19.59,
      -7.155,
      21.32,
      -19.59,
      -7.155,
      18.86,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2591.0",
    polygon: [
      -57.145,
      -36.823,
      18.233,
      -57.145,
      -35.823,
      18.233,
      -55.936,
      -35.823,
      18.17,
      -55.936,
      -35.823,
      18.17,
      -55.936,
      -36.823,
      18.17,
      -57.145,
      -36.823,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2397.0",
    polygon: [
      -25.543,
      -25.299,
      18.233,
      -25.543,
      -24.299,
      18.233,
      -24.335,
      -24.299,
      18.17,
      -24.335,
      -24.299,
      18.17,
      -24.335,
      -25.299,
      18.17,
      -25.543,
      -25.299,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2793.0",
    polygon: [
      -74.981,
      -22.959,
      3.473,
      -74.981,
      -20.639,
      3.473,
      -74.981,
      -20.639,
      4.167,
      -74.981,
      -20.639,
      4.167,
      -74.981,
      -22.959,
      4.167,
      -74.981,
      -22.959,
      3.473,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2554.0",
    polygon: [
      -61.277,
      -22.994,
      18.45,
      -61.277,
      -21.994,
      18.45,
      -57.304,
      -21.994,
      18.241,
      -57.304,
      -21.994,
      18.241,
      -57.304,
      -22.994,
      18.241,
      -61.277,
      -22.994,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2228.0",
    polygon: [
      -70.621,
      -16.256,
      29.36,
      -71.821,
      -16.256,
      29.36,
      -71.821,
      -16.256,
      31.82,
      -71.821,
      -16.256,
      31.82,
      -70.621,
      -16.256,
      31.82,
      -70.621,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1942.0",
    polygon: [
      -45.782,
      -7.155,
      29.36,
      -46.982,
      -7.155,
      29.36,
      -46.982,
      -7.155,
      31.82,
      -46.982,
      -7.155,
      31.82,
      -45.782,
      -7.155,
      31.82,
      -45.782,
      -7.155,
      29.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2398.0",
    polygon: [
      -24.165,
      -25.299,
      18.161,
      -24.165,
      -24.299,
      18.161,
      -22.957,
      -24.299,
      18.098,
      -22.957,
      -24.299,
      18.098,
      -22.957,
      -25.299,
      18.098,
      -24.165,
      -25.299,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1980.0",
    polygon: [
      -78.734,
      -7.156,
      11.86,
      -79.934,
      -7.156,
      11.86,
      -79.934,
      -7.156,
      14.32,
      -79.934,
      -7.156,
      14.32,
      -78.734,
      -7.156,
      14.32,
      -78.734,
      -7.156,
      11.86,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1982.0",
    polygon: [
      -81.438,
      -7.156,
      11.86,
      -82.638,
      -7.156,
      11.86,
      -82.638,
      -7.156,
      14.32,
      -82.638,
      -7.156,
      14.32,
      -81.438,
      -7.156,
      14.32,
      -81.438,
      -7.156,
      11.86,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2026.0",
    polygon: [
      31.897,
      -7.154,
      32.86,
      30.697,
      -7.154,
      32.86,
      30.697,
      -7.154,
      35.32,
      30.697,
      -7.154,
      35.32,
      31.897,
      -7.154,
      35.32,
      31.897,
      -7.154,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2845.0",
    polygon: [
      -74.886,
      14.249,
      15.36,
      -74.886,
      15.399,
      15.36,
      -74.886,
      15.399,
      17.82,
      -74.886,
      15.399,
      17.82,
      -74.886,
      14.249,
      17.82,
      -74.886,
      14.249,
      15.36,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2593.0",
    polygon: [
      -54.383,
      -36.823,
      18.088,
      -54.383,
      -35.823,
      18.088,
      -53.175,
      -35.823,
      18.025,
      -53.175,
      -35.823,
      18.025,
      -53.175,
      -36.823,
      18.025,
      -54.383,
      -36.823,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2710.0",
    polygon: [
      -74.884,
      -29.702,
      15.36,
      -74.884,
      -28.552,
      15.36,
      -74.884,
      -28.552,
      17.82,
      -74.884,
      -28.552,
      17.82,
      -74.884,
      -29.702,
      17.82,
      -74.884,
      -29.702,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2985.0",
    polygon: [
      -83.118,
      2.033,
      18.86,
      -83.118,
      3.233,
      18.86,
      -83.118,
      3.233,
      21.32,
      -83.118,
      3.233,
      21.32,
      -83.118,
      2.033,
      21.32,
      -83.118,
      2.033,
      18.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2711.0",
    polygon: [
      -74.884,
      -30.852,
      15.36,
      -74.884,
      -29.702,
      15.36,
      -74.884,
      -29.702,
      17.82,
      -74.884,
      -29.702,
      17.82,
      -74.884,
      -30.852,
      17.82,
      -74.884,
      -30.852,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "1903.0",
    polygon: [
      -24.999,
      -7.155,
      29.36,
      -26.199,
      -7.155,
      29.36,
      -26.199,
      -7.155,
      31.82,
      -26.199,
      -7.155,
      31.82,
      -24.999,
      -7.155,
      31.82,
      -24.999,
      -7.155,
      29.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1825.0",
    polygon: [
      -14.182,
      -7.155,
      32.86,
      -15.382,
      -7.155,
      32.86,
      -15.382,
      -7.155,
      35.32,
      -15.382,
      -7.155,
      35.32,
      -14.182,
      -7.155,
      35.32,
      -14.182,
      -7.155,
      32.86,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2746.0",
    polygon: [
      -74.884,
      -24.302,
      11.86,
      -74.884,
      -23.152,
      11.86,
      -74.884,
      -23.152,
      14.32,
      -74.884,
      -23.152,
      14.32,
      -74.884,
      -24.302,
      14.32,
      -74.884,
      -24.302,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2435.0",
    polygon: [
      -16.426,
      -15.079,
      18.233,
      -16.426,
      -16.079,
      18.233,
      -17.634,
      -16.079,
      18.17,
      -17.634,
      -16.079,
      18.17,
      -17.634,
      -15.079,
      18.17,
      -16.426,
      -15.079,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3024.0",
    polygon: [
      -82.012,
      0.144,
      22.36,
      -83.112,
      0.144,
      22.36,
      -83.112,
      0.144,
      24.82,
      -83.112,
      0.144,
      24.82,
      -82.012,
      0.144,
      24.82,
      -82.012,
      0.144,
      22.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2983.0",
    polygon: [
      -83.118,
      4.738,
      15.36,
      -83.118,
      5.938,
      15.36,
      -83.118,
      5.938,
      17.82,
      -83.118,
      5.938,
      17.82,
      -83.118,
      4.738,
      17.82,
      -83.118,
      4.738,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2118.0",
    polygon: [
      -41.744,
      -16.256,
      29.36,
      -42.944,
      -16.256,
      29.36,
      -42.944,
      -16.256,
      31.82,
      -42.944,
      -16.256,
      31.82,
      -41.744,
      -16.256,
      31.82,
      -41.744,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2941.0",
    polygon: [
      -83.118,
      -4.012,
      22.36,
      -83.118,
      -2.812,
      22.36,
      -83.118,
      -2.812,
      24.82,
      -83.118,
      -2.812,
      24.82,
      -83.118,
      -4.012,
      24.82,
      -83.118,
      -4.012,
      22.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1784.0",
    polygon: [
      -59.304,
      -7.155,
      39.128,
      -60.504,
      -7.155,
      39.128,
      -60.504,
      -7.155,
      40.05,
      -60.504,
      -7.155,
      40.05,
      -59.304,
      -7.155,
      40.05,
      -59.304,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2230.0",
    polygon: [
      -66.092,
      -16.256,
      29.36,
      -67.292,
      -16.256,
      29.36,
      -67.292,
      -16.256,
      31.82,
      -67.292,
      -16.256,
      31.82,
      -66.092,
      -16.256,
      31.82,
      -66.092,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2477.0",
    polygon: [
      -16.423,
      -25.451,
      18.233,
      -16.423,
      -26.451,
      18.233,
      -20.396,
      -26.451,
      18.025,
      -20.396,
      -26.451,
      18.025,
      -20.396,
      -25.451,
      18.025,
      -16.423,
      -25.451,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2750.0",
    polygon: [
      -74.884,
      -29.702,
      11.86,
      -74.884,
      -28.552,
      11.86,
      -74.884,
      -28.552,
      14.32,
      -74.884,
      -28.552,
      14.32,
      -74.884,
      -29.702,
      14.32,
      -74.884,
      -29.702,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2476.0",
    polygon: [
      -12.294,
      -25.451,
      18.45,
      -12.294,
      -26.451,
      18.45,
      -16.267,
      -26.451,
      18.241,
      -16.267,
      -26.451,
      18.241,
      -16.267,
      -25.451,
      18.241,
      -12.294,
      -25.451,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2359.0",
    polygon: [
      -25.543,
      -13.775,
      18.233,
      -25.543,
      -12.775,
      18.233,
      -24.335,
      -12.775,
      18.17,
      -24.335,
      -12.775,
      18.17,
      -24.335,
      -13.775,
      18.17,
      -25.543,
      -13.775,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2070.0",
    polygon: [
      31.897,
      -7.154,
      29.36,
      30.697,
      -7.154,
      29.36,
      30.697,
      -7.154,
      31.82,
      30.697,
      -7.154,
      31.82,
      31.897,
      -7.154,
      31.82,
      31.897,
      -7.154,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2751.0",
    polygon: [
      -74.884,
      -30.852,
      11.86,
      -74.884,
      -29.702,
      11.86,
      -74.884,
      -29.702,
      14.32,
      -74.884,
      -29.702,
      14.32,
      -74.884,
      -30.852,
      14.32,
      -74.884,
      -30.852,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2267.0",
    polygon: [
      -11.63,
      -11.952,
      18.86,
      -11.63,
      -10.802,
      18.86,
      -11.63,
      -10.802,
      21.32,
      -11.63,
      -10.802,
      21.32,
      -11.63,
      -11.952,
      21.32,
      -11.63,
      -11.952,
      18.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2121.0",
    polygon: [
      -39.021,
      -16.256,
      29.36,
      -40.221,
      -16.256,
      29.36,
      -40.221,
      -16.256,
      31.82,
      -40.221,
      -16.256,
      31.82,
      -39.021,
      -16.256,
      31.82,
      -39.021,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2794.0",
    polygon: [
      -74.961,
      -14.505,
      2.78,
      -74.961,
      -12.185,
      2.78,
      -74.961,
      -12.185,
      3.473,
      -74.961,
      -12.185,
      3.473,
      -74.961,
      -14.505,
      3.473,
      -74.961,
      -14.505,
      2.78,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2886.0",
    polygon: [
      -74.886,
      23.499,
      11.86,
      -74.886,
      24.649,
      11.86,
      -74.886,
      24.649,
      14.32,
      -74.886,
      24.649,
      14.32,
      -74.886,
      23.499,
      14.32,
      -74.886,
      23.499,
      11.86,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2168.0",
    polygon: [
      30.545,
      -7.154,
      4.86,
      29.345,
      -7.154,
      4.86,
      29.345,
      -7.154,
      7.32,
      29.345,
      -7.154,
      7.32,
      30.545,
      -7.154,
      7.32,
      30.545,
      -7.154,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1785.0",
    polygon: [
      -59.304,
      -7.155,
      36.36,
      -60.504,
      -7.155,
      36.36,
      -60.504,
      -7.155,
      39.128,
      -60.504,
      -7.155,
      39.128,
      -59.304,
      -7.155,
      39.128,
      -59.304,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1827.0",
    polygon: [
      -16.886,
      -7.155,
      32.86,
      -18.086,
      -7.155,
      32.86,
      -18.086,
      -7.155,
      35.32,
      -18.086,
      -7.155,
      35.32,
      -16.886,
      -7.155,
      35.32,
      -16.886,
      -7.155,
      32.86,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2848.0",
    polygon: [
      -74.886,
      20.799,
      15.36,
      -74.886,
      21.949,
      15.36,
      -74.886,
      21.949,
      17.82,
      -74.886,
      21.949,
      17.82,
      -74.886,
      20.799,
      17.82,
      -74.886,
      20.799,
      15.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2942.0",
    polygon: [
      -83.118,
      -2.66,
      22.36,
      -83.118,
      -1.46,
      22.36,
      -83.118,
      -1.46,
      24.82,
      -83.118,
      -1.46,
      24.82,
      -83.118,
      -2.66,
      24.82,
      -83.118,
      -2.66,
      22.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2594.0",
    polygon: [
      -61.277,
      -40.28,
      18.45,
      -61.277,
      -39.28,
      18.45,
      -57.304,
      -39.28,
      18.241,
      -57.304,
      -39.28,
      18.241,
      -57.304,
      -40.28,
      18.241,
      -61.277,
      -40.28,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2269.0",
    polygon: [
      -30.129,
      -9.502,
      18.86,
      -30.129,
      -10.752,
      18.86,
      -30.129,
      -10.752,
      21.32,
      -30.129,
      -10.752,
      21.32,
      -30.129,
      -9.502,
      21.32,
      -30.129,
      -9.502,
      18.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2227.0",
    polygon: [
      -71.982,
      -16.256,
      29.36,
      -73.182,
      -16.256,
      29.36,
      -73.182,
      -16.256,
      31.82,
      -73.182,
      -16.256,
      31.82,
      -71.982,
      -16.256,
      31.82,
      -71.982,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2592.0",
    polygon: [
      -55.767,
      -36.823,
      18.161,
      -55.767,
      -35.823,
      18.161,
      -54.558,
      -35.823,
      18.098,
      -54.558,
      -35.823,
      18.098,
      -54.558,
      -36.823,
      18.098,
      -55.767,
      -36.823,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2399.0",
    polygon: [
      -22.781,
      -25.299,
      18.088,
      -22.781,
      -24.299,
      18.088,
      -21.573,
      -24.299,
      18.025,
      -21.573,
      -24.299,
      18.025,
      -21.573,
      -25.299,
      18.025,
      -22.781,
      -25.299,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2515.0",
    polygon: [
      -57.148,
      -17.232,
      18.233,
      -57.148,
      -16.232,
      18.233,
      -53.175,
      -16.232,
      18.025,
      -53.175,
      -16.232,
      18.025,
      -53.175,
      -17.232,
      18.025,
      -57.148,
      -17.232,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2887.0",
    polygon: [
      -74.886,
      22.349,
      11.86,
      -74.886,
      23.499,
      11.86,
      -74.886,
      23.499,
      14.32,
      -74.886,
      23.499,
      14.32,
      -74.886,
      22.349,
      14.32,
      -74.886,
      22.349,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "1826.0",
    polygon: [
      -15.534,
      -7.155,
      32.86,
      -16.734,
      -7.155,
      32.86,
      -16.734,
      -7.155,
      35.32,
      -16.734,
      -7.155,
      35.32,
      -15.534,
      -7.155,
      35.32,
      -15.534,
      -7.155,
      32.86,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1983.0",
    polygon: [
      -76.03,
      -7.156,
      11.86,
      -77.23,
      -7.156,
      11.86,
      -77.23,
      -7.156,
      14.32,
      -77.23,
      -7.156,
      14.32,
      -76.03,
      -7.156,
      14.32,
      -76.03,
      -7.156,
      11.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1864.0",
    polygon: [
      -20.943,
      -7.155,
      18.86,
      -22.143,
      -7.155,
      18.86,
      -22.143,
      -7.155,
      21.32,
      -22.143,
      -7.155,
      21.32,
      -20.943,
      -7.155,
      21.32,
      -20.943,
      -7.155,
      18.86,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2071.0",
    polygon: [
      30.545,
      -7.154,
      29.36,
      29.345,
      -7.154,
      29.36,
      29.345,
      -7.154,
      31.82,
      29.345,
      -7.154,
      31.82,
      30.545,
      -7.154,
      31.82,
      30.545,
      -7.154,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2475.0",
    polygon: [
      -19.188,
      -21.994,
      18.088,
      -19.188,
      -22.994,
      18.088,
      -20.396,
      -22.994,
      18.025,
      -20.396,
      -22.994,
      18.025,
      -20.396,
      -21.994,
      18.025,
      -19.188,
      -21.994,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2120.0",
    polygon: [
      -40.382,
      -16.256,
      29.36,
      -41.582,
      -16.256,
      29.36,
      -41.582,
      -16.256,
      31.82,
      -41.582,
      -16.256,
      31.82,
      -40.382,
      -16.256,
      31.82,
      -40.382,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2306.0",
    polygon: [
      -43.237,
      -11.952,
      25.86,
      -43.237,
      -10.802,
      25.86,
      -43.237,
      -10.802,
      28.32,
      -43.237,
      -10.802,
      28.32,
      -43.237,
      -11.952,
      28.32,
      -43.237,
      -11.952,
      25.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2028.0",
    polygon: [
      29.192,
      -7.154,
      32.86,
      27.992,
      -7.154,
      32.86,
      27.992,
      -7.154,
      35.32,
      27.992,
      -7.154,
      35.32,
      29.192,
      -7.154,
      35.32,
      29.192,
      -7.154,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1944.0",
    polygon: [
      -48.486,
      -7.155,
      29.36,
      -49.686,
      -7.155,
      29.36,
      -49.686,
      -7.155,
      31.82,
      -49.686,
      -7.155,
      31.82,
      -48.486,
      -7.155,
      31.82,
      -48.486,
      -7.155,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2309.0",
    polygon: [
      -74.957,
      -7.928,
      29.36,
      -74.957,
      -7.371,
      29.36,
      -74.957,
      -7.371,
      31.82,
      -74.957,
      -7.371,
      31.82,
      -74.957,
      -7.928,
      31.82,
      -74.957,
      -7.928,
      29.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2987.0",
    polygon: [
      -83.118,
      4.738,
      18.86,
      -83.118,
      5.938,
      18.86,
      -83.118,
      5.938,
      21.32,
      -83.118,
      5.938,
      21.32,
      -83.118,
      4.738,
      21.32,
      -83.118,
      4.738,
      18.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2888.0",
    polygon: [
      -74.886,
      26.199,
      11.86,
      -74.886,
      27.349,
      11.86,
      -74.886,
      27.349,
      14.32,
      -74.886,
      27.349,
      14.32,
      -74.886,
      26.199,
      14.32,
      -74.886,
      26.199,
      11.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2632.0",
    polygon: [
      -49.406,
      -19.689,
      18.161,
      -49.406,
      -20.689,
      18.161,
      -50.614,
      -20.689,
      18.098,
      -50.614,
      -20.689,
      18.098,
      -50.614,
      -19.689,
      18.098,
      -49.406,
      -19.689,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1788.0",
    polygon: [
      -43.077,
      -7.155,
      39.128,
      -44.277,
      -7.155,
      39.128,
      -44.277,
      -7.155,
      40.05,
      -44.277,
      -7.155,
      40.05,
      -43.077,
      -7.155,
      40.05,
      -43.077,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2556.0",
    polygon: [
      -55.767,
      -22.994,
      18.161,
      -55.767,
      -21.994,
      18.161,
      -54.558,
      -21.994,
      18.098,
      -54.558,
      -21.994,
      18.098,
      -54.558,
      -22.994,
      18.098,
      -55.767,
      -22.994,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2270.0",
    polygon: [
      -30.129,
      -10.752,
      18.86,
      -30.129,
      -12.002,
      18.86,
      -30.129,
      -12.002,
      21.32,
      -30.129,
      -12.002,
      21.32,
      -30.129,
      -10.752,
      21.32,
      -30.129,
      -10.752,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2631.0",
    polygon: [
      -48.028,
      -19.689,
      18.233,
      -48.028,
      -20.689,
      18.233,
      -49.236,
      -20.689,
      18.17,
      -49.236,
      -20.689,
      18.17,
      -49.236,
      -19.689,
      18.17,
      -48.028,
      -19.689,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2944.0",
    polygon: [
      -83.118,
      -5.365,
      25.86,
      -83.118,
      -4.165,
      25.86,
      -83.118,
      -4.165,
      28.32,
      -83.118,
      -4.165,
      28.32,
      -83.118,
      -5.365,
      28.32,
      -83.118,
      -5.365,
      25.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "1905.0",
    polygon: [
      -27.704,
      -7.155,
      29.36,
      -28.904,
      -7.155,
      29.36,
      -28.904,
      -7.155,
      31.82,
      -28.904,
      -7.155,
      31.82,
      -27.704,
      -7.155,
      31.82,
      -27.704,
      -7.155,
      29.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2027.0",
    polygon: [
      30.545,
      -7.154,
      32.86,
      29.345,
      -7.154,
      32.86,
      29.345,
      -7.154,
      35.32,
      29.345,
      -7.154,
      35.32,
      30.545,
      -7.154,
      35.32,
      30.545,
      -7.154,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2986.0",
    polygon: [
      -83.118,
      3.386,
      18.86,
      -83.118,
      4.586,
      18.86,
      -83.118,
      4.586,
      21.32,
      -83.118,
      4.586,
      21.32,
      -83.118,
      3.386,
      21.32,
      -83.118,
      3.386,
      18.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2169.0",
    polygon: [
      29.192,
      -7.154,
      4.86,
      27.992,
      -7.154,
      4.86,
      27.992,
      -7.154,
      7.32,
      27.992,
      -7.154,
      7.32,
      29.192,
      -7.154,
      7.32,
      29.192,
      -7.154,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2072.0",
    polygon: [
      29.192,
      -7.154,
      29.36,
      27.992,
      -7.154,
      29.36,
      27.992,
      -7.154,
      31.82,
      27.992,
      -7.154,
      31.82,
      29.192,
      -7.154,
      31.82,
      29.192,
      -7.154,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2943.0",
    polygon: [
      -83.118,
      -6.717,
      25.86,
      -83.118,
      -5.517,
      25.86,
      -83.118,
      -5.517,
      28.32,
      -83.118,
      -5.517,
      28.32,
      -83.118,
      -6.717,
      28.32,
      -83.118,
      -6.717,
      25.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "1866.0",
    polygon: [
      -23.647,
      -7.155,
      18.86,
      -24.847,
      -7.155,
      18.86,
      -24.847,
      -7.155,
      21.32,
      -24.847,
      -7.155,
      21.32,
      -23.647,
      -7.155,
      21.32,
      -23.647,
      -7.155,
      18.86,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "2170.0",
    polygon: [
      27.84,
      -7.154,
      4.86,
      26.64,
      -7.154,
      4.86,
      26.64,
      -7.154,
      7.32,
      26.64,
      -7.154,
      7.32,
      27.84,
      -7.154,
      7.32,
      27.84,
      -7.154,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2671.0",
    polygon: [
      -48.024,
      -36.975,
      18.233,
      -48.024,
      -37.975,
      18.233,
      -51.998,
      -37.975,
      18.025,
      -51.998,
      -37.975,
      18.025,
      -51.998,
      -36.975,
      18.025,
      -48.024,
      -36.975,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2595.0",
    polygon: [
      -57.148,
      -40.28,
      18.233,
      -57.148,
      -39.28,
      18.233,
      -53.175,
      -39.28,
      18.025,
      -53.175,
      -39.28,
      18.025,
      -53.175,
      -40.28,
      18.025,
      -57.148,
      -40.28,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2119.0",
    polygon: [
      -30.408,
      -16.256,
      29.36,
      -31.608,
      -16.256,
      29.36,
      -31.608,
      -16.256,
      31.82,
      -31.608,
      -16.256,
      31.82,
      -30.408,
      -16.256,
      31.82,
      -30.408,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2516.0",
    polygon: [
      -61.277,
      -16.079,
      18.45,
      -61.277,
      -15.079,
      18.45,
      -57.304,
      -15.079,
      18.241,
      -57.304,
      -15.079,
      18.241,
      -57.304,
      -16.079,
      18.241,
      -61.277,
      -16.079,
      18.45,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2436.0",
    polygon: [
      -17.804,
      -15.079,
      18.161,
      -17.804,
      -16.079,
      18.161,
      -19.012,
      -16.079,
      18.098,
      -19.012,
      -16.079,
      18.098,
      -19.012,
      -15.079,
      18.098,
      -17.804,
      -15.079,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1748.0",
    polygon: [
      -19.59,
      -7.155,
      39.128,
      -20.79,
      -7.155,
      39.128,
      -20.79,
      -7.155,
      40.05,
      -20.79,
      -7.155,
      40.05,
      -19.59,
      -7.155,
      40.05,
      -19.59,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2713.0",
    polygon: [
      -74.884,
      -33.552,
      15.36,
      -74.884,
      -32.402,
      15.36,
      -74.884,
      -32.402,
      17.82,
      -74.884,
      -32.402,
      17.82,
      -74.884,
      -33.552,
      17.82,
      -74.884,
      -33.552,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2171.0",
    polygon: [
      26.488,
      -7.154,
      4.86,
      25.288,
      -7.154,
      4.86,
      25.288,
      -7.154,
      7.32,
      25.288,
      -7.154,
      7.32,
      26.488,
      -7.154,
      7.32,
      26.488,
      -7.154,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2074.0",
    polygon: [
      26.488,
      -7.154,
      29.36,
      25.288,
      -7.154,
      29.36,
      25.288,
      -7.154,
      31.82,
      25.288,
      -7.154,
      31.82,
      26.488,
      -7.154,
      31.82,
      26.488,
      -7.154,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2553.0",
    polygon: [
      -57.148,
      -24.146,
      18.233,
      -57.148,
      -23.146,
      18.233,
      -53.175,
      -23.146,
      18.025,
      -53.175,
      -23.146,
      18.025,
      -53.175,
      -24.146,
      18.025,
      -57.148,
      -24.146,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2714.0",
    polygon: [
      -74.884,
      -35.101,
      15.36,
      -74.884,
      -33.951,
      15.36,
      -74.884,
      -33.951,
      17.82,
      -74.884,
      -33.951,
      17.82,
      -74.884,
      -35.101,
      17.82,
      -74.884,
      -35.101,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3029.0",
    polygon: [
      -81.761,
      -0.692,
      25.86,
      -81.761,
      -0.112,
      25.86,
      -81.761,
      -0.112,
      28.32,
      -81.761,
      -0.112,
      28.32,
      -81.761,
      -0.692,
      28.32,
      -81.761,
      -0.692,
      25.86,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2634.0",
    polygon: [
      -43.895,
      -23.146,
      18.45,
      -43.895,
      -24.146,
      18.45,
      -47.869,
      -24.146,
      18.241,
      -47.869,
      -24.146,
      18.241,
      -47.869,
      -23.146,
      18.241,
      -43.895,
      -23.146,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2478.0",
    polygon: [
      -12.294,
      -24.299,
      18.45,
      -12.294,
      -25.299,
      18.45,
      -16.267,
      -25.299,
      18.241,
      -16.267,
      -25.299,
      18.241,
      -16.267,
      -24.299,
      18.241,
      -12.294,
      -24.299,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2122.0",
    polygon: [
      -37.66,
      -16.256,
      29.36,
      -38.86,
      -16.256,
      29.36,
      -38.86,
      -16.256,
      31.82,
      -38.86,
      -16.256,
      31.82,
      -37.66,
      -16.256,
      31.82,
      -37.66,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2438.0",
    polygon: [
      -12.294,
      -13.927,
      18.45,
      -12.294,
      -14.927,
      18.45,
      -16.267,
      -14.927,
      18.241,
      -16.267,
      -14.927,
      18.241,
      -16.267,
      -13.927,
      18.241,
      -12.294,
      -13.927,
      18.45,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2847.0",
    polygon: [
      -74.886,
      16.949,
      15.36,
      -74.886,
      18.099,
      15.36,
      -74.886,
      18.099,
      17.82,
      -74.886,
      18.099,
      17.82,
      -74.886,
      16.949,
      17.82,
      -74.886,
      16.949,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2988.0",
    polygon: [
      -83.118,
      0.681,
      22.36,
      -83.118,
      1.881,
      22.36,
      -83.118,
      1.881,
      24.82,
      -83.118,
      1.881,
      24.82,
      -83.118,
      0.681,
      24.82,
      -83.118,
      0.681,
      22.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "1865.0",
    polygon: [
      -22.295,
      -7.155,
      18.86,
      -23.495,
      -7.155,
      18.86,
      -23.495,
      -7.155,
      21.32,
      -23.495,
      -7.155,
      21.32,
      -22.295,
      -7.155,
      21.32,
      -22.295,
      -7.155,
      18.86,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1828.0",
    polygon: [
      -18.238,
      -7.155,
      32.86,
      -19.438,
      -7.155,
      32.86,
      -19.438,
      -7.155,
      35.32,
      -19.438,
      -7.155,
      35.32,
      -18.238,
      -7.155,
      35.32,
      -18.238,
      -7.155,
      32.86,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2797.0",
    polygon: [
      -74.961,
      -12.185,
      2.78,
      -74.961,
      -9.865,
      2.78,
      -74.961,
      -9.865,
      3.473,
      -74.961,
      -9.865,
      3.473,
      -74.961,
      -12.185,
      3.473,
      -74.961,
      -12.185,
      2.78,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2633.0",
    polygon: [
      -50.79,
      -19.689,
      18.088,
      -50.79,
      -20.689,
      18.088,
      -51.998,
      -20.689,
      18.025,
      -51.998,
      -20.689,
      18.025,
      -51.998,
      -19.689,
      18.025,
      -50.79,
      -19.689,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1787.0",
    polygon: [
      -60.656,
      -6.955,
      36.36,
      -61.856,
      -6.955,
      36.36,
      -61.856,
      -6.955,
      39.128,
      -61.856,
      -6.955,
      39.128,
      -60.656,
      -6.955,
      39.128,
      -60.656,
      -6.955,
      36.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2361.0",
    polygon: [
      -22.781,
      -13.775,
      18.088,
      -22.781,
      -12.775,
      18.088,
      -21.573,
      -12.775,
      18.025,
      -21.573,
      -12.775,
      18.025,
      -21.573,
      -13.775,
      18.025,
      -22.781,
      -13.775,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2308.0",
    polygon: [
      -74.807,
      -11.952,
      25.86,
      -74.807,
      -10.802,
      25.86,
      -74.807,
      -10.802,
      28.32,
      -74.807,
      -10.802,
      28.32,
      -74.807,
      -11.952,
      28.32,
      -74.807,
      -11.952,
      25.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2271.0",
    polygon: [
      -61.716,
      -8.252,
      18.86,
      -61.716,
      -9.502,
      18.86,
      -61.716,
      -9.502,
      21.32,
      -61.716,
      -9.502,
      21.32,
      -61.716,
      -8.252,
      21.32,
      -61.716,
      -8.252,
      18.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2796.0",
    polygon: [
      -74.961,
      -14.505,
      3.473,
      -74.961,
      -12.185,
      3.473,
      -74.961,
      -12.185,
      4.167,
      -74.961,
      -12.185,
      4.167,
      -74.961,
      -14.505,
      4.167,
      -74.961,
      -14.505,
      3.473,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2358.0",
    polygon: [
      -29.675,
      -13.775,
      18.45,
      -29.675,
      -12.775,
      18.45,
      -25.702,
      -12.775,
      18.241,
      -25.702,
      -12.775,
      18.241,
      -25.702,
      -13.775,
      18.241,
      -29.675,
      -13.775,
      18.45,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2712.0",
    polygon: [
      -74.884,
      -32.402,
      15.36,
      -74.884,
      -31.252,
      15.36,
      -74.884,
      -31.252,
      17.82,
      -74.884,
      -31.252,
      17.82,
      -74.884,
      -32.402,
      17.82,
      -74.884,
      -32.402,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "1747.0",
    polygon: [
      -18.238,
      -7.155,
      36.36,
      -19.438,
      -7.155,
      36.36,
      -19.438,
      -7.155,
      39.128,
      -19.438,
      -7.155,
      39.128,
      -18.238,
      -7.155,
      39.128,
      -18.238,
      -7.155,
      36.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2752.0",
    polygon: [
      -74.884,
      -32.402,
      11.86,
      -74.884,
      -31.252,
      11.86,
      -74.884,
      -31.252,
      14.32,
      -74.884,
      -31.252,
      14.32,
      -74.884,
      -32.402,
      14.32,
      -74.884,
      -32.402,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2307.0",
    polygon: [
      -74.807,
      -10.802,
      25.86,
      -74.807,
      -9.652,
      25.86,
      -74.807,
      -9.652,
      28.32,
      -74.807,
      -9.652,
      28.32,
      -74.807,
      -10.802,
      28.32,
      -74.807,
      -10.802,
      25.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2231.0",
    polygon: [
      -63.369,
      -16.256,
      29.36,
      -64.569,
      -16.256,
      29.36,
      -64.569,
      -16.256,
      31.82,
      -64.569,
      -16.256,
      31.82,
      -63.369,
      -16.256,
      31.82,
      -63.369,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2672.0",
    polygon: [
      -43.895,
      -35.823,
      18.45,
      -43.895,
      -36.823,
      18.45,
      -47.869,
      -36.823,
      18.241,
      -47.869,
      -36.823,
      18.241,
      -47.869,
      -35.823,
      18.241,
      -43.895,
      -35.823,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1786.0",
    polygon: [
      -60.656,
      -6.955,
      39.128,
      -61.856,
      -6.955,
      39.128,
      -61.856,
      -6.955,
      40.05,
      -61.856,
      -6.955,
      40.05,
      -60.656,
      -6.955,
      40.05,
      -60.656,
      -6.955,
      39.128,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2555.0",
    polygon: [
      -57.145,
      -22.994,
      18.233,
      -57.145,
      -21.994,
      18.233,
      -55.936,
      -21.994,
      18.17,
      -55.936,
      -21.994,
      18.17,
      -55.936,
      -22.994,
      18.17,
      -57.145,
      -22.994,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2311.0",
    polygon: [
      -11.63,
      -10.802,
      29.36,
      -11.63,
      -9.652,
      29.36,
      -11.63,
      -9.652,
      31.82,
      -11.63,
      -9.652,
      31.82,
      -11.63,
      -10.802,
      31.82,
      -11.63,
      -10.802,
      29.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2229.0",
    polygon: [
      -69.26,
      -16.256,
      29.36,
      -70.46,
      -16.256,
      29.36,
      -70.46,
      -16.256,
      31.82,
      -70.46,
      -16.256,
      31.82,
      -69.26,
      -16.256,
      31.82,
      -69.26,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2073.0",
    polygon: [
      27.84,
      -7.154,
      29.36,
      26.64,
      -7.154,
      29.36,
      26.64,
      -7.154,
      31.82,
      26.64,
      -7.154,
      31.82,
      27.84,
      -7.154,
      31.82,
      27.84,
      -7.154,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2172.0",
    polygon: [
      25.136,
      -7.154,
      4.86,
      23.936,
      -7.154,
      4.86,
      23.936,
      -7.154,
      7.32,
      23.936,
      -7.154,
      7.32,
      25.136,
      -7.154,
      7.32,
      25.136,
      -7.154,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2123.0",
    polygon: [
      -34.492,
      -16.256,
      29.36,
      -35.692,
      -16.256,
      29.36,
      -35.692,
      -16.256,
      31.82,
      -35.692,
      -16.256,
      31.82,
      -34.492,
      -16.256,
      31.82,
      -34.492,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2518.0",
    polygon: [
      -55.767,
      -16.079,
      18.161,
      -55.767,
      -15.079,
      18.161,
      -54.558,
      -15.079,
      18.098,
      -54.558,
      -15.079,
      18.098,
      -54.558,
      -16.079,
      18.098,
      -55.767,
      -16.079,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2945.0",
    polygon: [
      -83.118,
      -4.012,
      25.86,
      -83.118,
      -2.812,
      25.86,
      -83.118,
      -2.812,
      28.32,
      -83.118,
      -2.812,
      28.32,
      -83.118,
      -4.012,
      28.32,
      -83.118,
      -4.012,
      25.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "1749.0",
    polygon: [
      -19.59,
      -7.155,
      36.36,
      -20.79,
      -7.155,
      36.36,
      -20.79,
      -7.155,
      39.128,
      -20.79,
      -7.155,
      39.128,
      -19.59,
      -7.155,
      39.128,
      -19.59,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2801.0",
    polygon: [
      -74.981,
      -38.784,
      3.473,
      -74.981,
      -36.464,
      3.473,
      -74.981,
      -36.464,
      4.167,
      -74.981,
      -36.464,
      4.167,
      -74.981,
      -38.784,
      4.167,
      -74.981,
      -38.784,
      3.473,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2479.0",
    polygon: [
      -16.426,
      -24.299,
      18.233,
      -16.426,
      -25.299,
      18.233,
      -17.634,
      -25.299,
      18.17,
      -17.634,
      -25.299,
      18.17,
      -17.634,
      -24.299,
      18.17,
      -16.426,
      -24.299,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1904.0",
    polygon: [
      -26.351,
      -7.155,
      29.36,
      -27.551,
      -7.155,
      29.36,
      -27.551,
      -7.155,
      31.82,
      -27.551,
      -7.155,
      31.82,
      -26.351,
      -7.155,
      31.82,
      -26.351,
      -7.155,
      29.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2439.0",
    polygon: [
      -16.423,
      -13.927,
      18.233,
      -16.423,
      -14.927,
      18.233,
      -20.396,
      -14.927,
      18.025,
      -20.396,
      -14.927,
      18.025,
      -20.396,
      -13.927,
      18.025,
      -16.423,
      -13.927,
      18.233,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2272.0",
    polygon: [
      -61.716,
      -9.502,
      18.86,
      -61.716,
      -10.752,
      18.86,
      -61.716,
      -10.752,
      21.32,
      -61.716,
      -10.752,
      21.32,
      -61.716,
      -9.502,
      21.32,
      -61.716,
      -9.502,
      18.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2360.0",
    polygon: [
      -24.165,
      -13.775,
      18.161,
      -24.165,
      -12.775,
      18.161,
      -22.957,
      -12.775,
      18.098,
      -22.957,
      -12.775,
      18.098,
      -22.957,
      -13.775,
      18.098,
      -24.165,
      -13.775,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2799.0",
    polygon: [
      -74.961,
      -12.185,
      3.473,
      -74.961,
      -9.865,
      3.473,
      -74.961,
      -9.865,
      4.167,
      -74.961,
      -9.865,
      4.167,
      -74.961,
      -12.185,
      4.167,
      -74.961,
      -12.185,
      3.473,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2889.0",
    polygon: [
      -74.886,
      25.049,
      11.86,
      -74.886,
      26.199,
      11.86,
      -74.886,
      26.199,
      14.32,
      -74.886,
      26.199,
      14.32,
      -74.886,
      25.049,
      14.32,
      -74.886,
      25.049,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3027.0",
    polygon: [
      -82.012,
      0.144,
      25.86,
      -83.112,
      0.144,
      25.86,
      -83.112,
      0.144,
      28.32,
      -83.112,
      0.144,
      28.32,
      -82.012,
      0.144,
      28.32,
      -82.012,
      0.144,
      25.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2310.0",
    polygon: [
      -11.74,
      -7.925,
      29.36,
      -11.74,
      -7.368,
      29.36,
      -11.74,
      -7.368,
      31.82,
      -11.74,
      -7.368,
      31.82,
      -11.74,
      -7.925,
      31.82,
      -11.74,
      -7.925,
      29.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2517.0",
    polygon: [
      -57.145,
      -16.079,
      18.233,
      -57.145,
      -15.079,
      18.233,
      -55.936,
      -15.079,
      18.17,
      -55.936,
      -15.079,
      18.17,
      -55.936,
      -16.079,
      18.17,
      -57.145,
      -16.079,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2029.0",
    polygon: [
      27.84,
      -7.154,
      32.86,
      26.64,
      -7.154,
      32.86,
      26.64,
      -7.154,
      35.32,
      26.64,
      -7.154,
      35.32,
      27.84,
      -7.154,
      35.32,
      27.84,
      -7.154,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1829.0",
    polygon: [
      -19.59,
      -7.155,
      32.86,
      -20.79,
      -7.155,
      32.86,
      -20.79,
      -7.155,
      35.32,
      -20.79,
      -7.155,
      35.32,
      -19.59,
      -7.155,
      35.32,
      -19.59,
      -7.155,
      32.86,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2670.0",
    polygon: [
      -43.895,
      -36.975,
      18.45,
      -43.895,
      -37.975,
      18.45,
      -47.869,
      -37.975,
      18.241,
      -47.869,
      -37.975,
      18.241,
      -47.869,
      -36.975,
      18.241,
      -43.895,
      -36.975,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2557.0",
    polygon: [
      -54.383,
      -22.994,
      18.088,
      -54.383,
      -21.994,
      18.088,
      -53.175,
      -21.994,
      18.025,
      -53.175,
      -21.994,
      18.025,
      -53.175,
      -22.994,
      18.025,
      -54.383,
      -22.994,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2400.0",
    polygon: [
      -29.675,
      -28.756,
      18.45,
      -29.675,
      -27.756,
      18.45,
      -25.702,
      -27.756,
      18.241,
      -25.702,
      -27.756,
      18.241,
      -25.702,
      -28.756,
      18.241,
      -29.675,
      -28.756,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2232.0",
    polygon: [
      -64.731,
      -16.256,
      29.36,
      -65.931,
      -16.256,
      29.36,
      -65.931,
      -16.256,
      31.82,
      -65.931,
      -16.256,
      31.82,
      -64.731,
      -16.256,
      31.82,
      -64.731,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1789.0",
    polygon: [
      -43.077,
      -7.155,
      36.36,
      -44.277,
      -7.155,
      36.36,
      -44.277,
      -7.155,
      39.128,
      -44.277,
      -7.155,
      39.128,
      -43.077,
      -7.155,
      39.128,
      -43.077,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2030.0",
    polygon: [
      26.488,
      -7.154,
      32.86,
      25.288,
      -7.154,
      32.86,
      25.288,
      -7.154,
      35.32,
      25.288,
      -7.154,
      35.32,
      26.488,
      -7.154,
      35.32,
      26.488,
      -7.154,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2437.0",
    polygon: [
      -19.188,
      -15.079,
      18.088,
      -19.188,
      -16.079,
      18.088,
      -20.396,
      -16.079,
      18.025,
      -20.396,
      -16.079,
      18.025,
      -20.396,
      -15.079,
      18.025,
      -19.188,
      -15.079,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1943.0",
    polygon: [
      -47.134,
      -7.155,
      29.36,
      -48.334,
      -7.155,
      29.36,
      -48.334,
      -7.155,
      31.82,
      -48.334,
      -7.155,
      31.82,
      -47.134,
      -7.155,
      31.82,
      -47.134,
      -7.155,
      29.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2673.0",
    polygon: [
      -48.028,
      -35.823,
      18.233,
      -48.028,
      -36.823,
      18.233,
      -49.236,
      -36.823,
      18.17,
      -49.236,
      -36.823,
      18.17,
      -49.236,
      -35.823,
      18.17,
      -48.028,
      -35.823,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2598.0",
    polygon: [
      -43.895,
      -15.079,
      18.45,
      -43.895,
      -16.079,
      18.45,
      -47.869,
      -16.079,
      18.241,
      -47.869,
      -16.079,
      18.241,
      -47.869,
      -15.079,
      18.241,
      -43.895,
      -15.079,
      18.45,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "2596.0",
    polygon: [
      -43.895,
      -16.232,
      18.45,
      -43.895,
      -17.232,
      18.45,
      -47.869,
      -17.232,
      18.241,
      -47.869,
      -17.232,
      18.241,
      -47.869,
      -16.232,
      18.241,
      -43.895,
      -16.232,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2635.0",
    polygon: [
      -48.024,
      -23.146,
      18.233,
      -48.024,
      -24.146,
      18.233,
      -51.998,
      -24.146,
      18.025,
      -51.998,
      -24.146,
      18.025,
      -51.998,
      -23.146,
      18.025,
      -48.024,
      -23.146,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2852.0",
    polygon: [
      -74.886,
      26.199,
      15.36,
      -74.886,
      27.349,
      15.36,
      -74.886,
      27.349,
      17.82,
      -74.886,
      27.349,
      17.82,
      -74.886,
      26.199,
      17.82,
      -74.886,
      26.199,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2173.0",
    polygon: [
      33.249,
      -7.154,
      0.05,
      31.973,
      -7.154,
      0.05,
      31.973,
      -7.154,
      2.78,
      31.973,
      -7.154,
      2.78,
      33.249,
      -7.154,
      2.78,
      33.249,
      -7.154,
      0.05,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2597.0",
    polygon: [
      -48.024,
      -16.232,
      18.233,
      -48.024,
      -17.232,
      18.233,
      -51.998,
      -17.232,
      18.025,
      -51.998,
      -17.232,
      18.025,
      -51.998,
      -16.232,
      18.025,
      -48.024,
      -16.232,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "1945.0",
    polygon: [
      -49.838,
      -7.155,
      29.36,
      -51.038,
      -7.155,
      29.36,
      -51.038,
      -7.155,
      31.82,
      -51.038,
      -7.155,
      31.82,
      -49.838,
      -7.155,
      31.82,
      -49.838,
      -7.155,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2403.0",
    polygon: [
      -25.543,
      -27.603,
      18.233,
      -25.543,
      -26.603,
      18.233,
      -24.335,
      -26.603,
      18.17,
      -24.335,
      -26.603,
      18.17,
      -24.335,
      -27.603,
      18.17,
      -25.543,
      -27.603,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2989.0",
    polygon: [
      -83.118,
      2.033,
      22.36,
      -83.118,
      3.233,
      22.36,
      -83.118,
      3.233,
      24.82,
      -83.118,
      3.233,
      24.82,
      -83.118,
      2.033,
      24.82,
      -83.118,
      2.033,
      22.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2363.0",
    polygon: [
      -25.546,
      -12.622,
      18.233,
      -25.546,
      -11.622,
      18.233,
      -21.573,
      -11.622,
      18.025,
      -21.573,
      -11.622,
      18.025,
      -21.573,
      -12.622,
      18.025,
      -25.546,
      -12.622,
      18.233,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1830.0",
    polygon: [
      -20.943,
      -7.155,
      32.86,
      -22.143,
      -7.155,
      32.86,
      -22.143,
      -7.155,
      35.32,
      -22.143,
      -7.155,
      35.32,
      -20.943,
      -7.155,
      35.32,
      -20.943,
      -7.155,
      32.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2440.0",
    polygon: [
      -12.294,
      -12.775,
      18.45,
      -12.294,
      -13.775,
      18.45,
      -16.267,
      -13.775,
      18.241,
      -16.267,
      -13.775,
      18.241,
      -16.267,
      -12.775,
      18.241,
      -12.294,
      -12.775,
      18.45,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2401.0",
    polygon: [
      -25.546,
      -28.756,
      18.233,
      -25.546,
      -27.756,
      18.233,
      -21.573,
      -27.756,
      18.025,
      -21.573,
      -27.756,
      18.025,
      -21.573,
      -28.756,
      18.025,
      -25.546,
      -28.756,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2891.0",
    polygon: [
      -74.886,
      27.749,
      11.86,
      -74.886,
      28.899,
      11.86,
      -74.886,
      28.899,
      14.32,
      -74.886,
      28.899,
      14.32,
      -74.886,
      27.749,
      14.32,
      -74.886,
      27.749,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2849.0",
    polygon: [
      -74.886,
      19.649,
      15.36,
      -74.886,
      20.799,
      15.36,
      -74.886,
      20.799,
      17.82,
      -74.886,
      20.799,
      17.82,
      -74.886,
      19.649,
      17.82,
      -74.886,
      19.649,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2362.0",
    polygon: [
      -29.675,
      -12.622,
      18.45,
      -29.675,
      -11.622,
      18.45,
      -25.702,
      -11.622,
      18.241,
      -25.702,
      -11.622,
      18.241,
      -25.702,
      -12.622,
      18.241,
      -29.675,
      -12.622,
      18.45,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "2558.0",
    polygon: [
      -61.277,
      -26.451,
      18.45,
      -61.277,
      -25.451,
      18.45,
      -57.304,
      -25.451,
      18.241,
      -57.304,
      -25.451,
      18.241,
      -57.304,
      -26.451,
      18.241,
      -61.277,
      -26.451,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2031.0",
    polygon: [
      25.136,
      -7.154,
      32.86,
      23.936,
      -7.154,
      32.86,
      23.936,
      -7.154,
      35.32,
      23.936,
      -7.154,
      35.32,
      25.136,
      -7.154,
      35.32,
      25.136,
      -7.154,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1986.0",
    polygon: [
      -80.086,
      -7.156,
      15.36,
      -81.286,
      -7.156,
      15.36,
      -81.286,
      -7.156,
      17.82,
      -81.286,
      -7.156,
      17.82,
      -80.086,
      -7.156,
      17.82,
      -80.086,
      -7.156,
      15.36,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2753.0",
    polygon: [
      -74.884,
      -33.552,
      11.86,
      -74.884,
      -32.402,
      11.86,
      -74.884,
      -32.402,
      14.32,
      -74.884,
      -32.402,
      14.32,
      -74.884,
      -33.552,
      14.32,
      -74.884,
      -33.552,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "3030.0",
    polygon: [
      -82.012,
      0.144,
      29.36,
      -83.112,
      0.144,
      29.36,
      -83.112,
      0.144,
      31.82,
      -83.112,
      0.144,
      31.82,
      -82.012,
      0.144,
      31.82,
      -82.012,
      0.144,
      29.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2674.0",
    polygon: [
      -49.406,
      -35.823,
      18.161,
      -49.406,
      -36.823,
      18.161,
      -50.614,
      -36.823,
      18.098,
      -50.614,
      -36.823,
      18.098,
      -50.614,
      -35.823,
      18.098,
      -49.406,
      -35.823,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2312.0",
    polygon: [
      -11.63,
      -11.952,
      29.36,
      -11.63,
      -10.802,
      29.36,
      -11.63,
      -10.802,
      31.82,
      -11.63,
      -10.802,
      31.82,
      -11.63,
      -11.952,
      31.82,
      -11.63,
      -11.952,
      29.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "1750.0",
    polygon: [
      -20.943,
      -7.155,
      39.128,
      -22.143,
      -7.155,
      39.128,
      -22.143,
      -7.155,
      40.05,
      -22.143,
      -7.155,
      40.05,
      -20.943,
      -7.155,
      40.05,
      -20.943,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2755.0",
    polygon: [
      -74.884,
      -36.251,
      11.86,
      -74.884,
      -35.101,
      11.86,
      -74.884,
      -35.101,
      14.32,
      -74.884,
      -35.101,
      14.32,
      -74.884,
      -36.251,
      14.32,
      -74.884,
      -36.251,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2946.0",
    polygon: [
      -83.118,
      -2.66,
      25.86,
      -83.118,
      -1.46,
      25.86,
      -83.118,
      -1.46,
      28.32,
      -83.118,
      -1.46,
      28.32,
      -83.118,
      -2.66,
      28.32,
      -83.118,
      -2.66,
      25.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2559.0",
    polygon: [
      -57.148,
      -26.451,
      18.233,
      -57.148,
      -25.451,
      18.233,
      -53.175,
      -25.451,
      18.025,
      -53.175,
      -25.451,
      18.025,
      -53.175,
      -26.451,
      18.025,
      -57.148,
      -26.451,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1752.0",
    polygon: [
      -22.295,
      -7.155,
      39.128,
      -23.495,
      -7.155,
      39.128,
      -23.495,
      -7.155,
      40.05,
      -23.495,
      -7.155,
      40.05,
      -22.295,
      -7.155,
      40.05,
      -22.295,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1751.0",
    polygon: [
      -20.943,
      -7.155,
      36.36,
      -22.143,
      -7.155,
      36.36,
      -22.143,
      -7.155,
      39.128,
      -22.143,
      -7.155,
      39.128,
      -20.943,
      -7.155,
      39.128,
      -20.943,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1867.0",
    polygon: [
      -24.999,
      -7.155,
      18.86,
      -26.199,
      -7.155,
      18.86,
      -26.199,
      -7.155,
      21.32,
      -26.199,
      -7.155,
      21.32,
      -24.999,
      -7.155,
      21.32,
      -24.999,
      -7.155,
      18.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1832.0",
    polygon: [
      -23.647,
      -7.155,
      32.86,
      -24.847,
      -7.155,
      32.86,
      -24.847,
      -7.155,
      35.32,
      -24.847,
      -7.155,
      35.32,
      -23.647,
      -7.155,
      35.32,
      -23.647,
      -7.155,
      32.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2075.0",
    polygon: [
      25.136,
      -7.154,
      29.36,
      23.936,
      -7.154,
      29.36,
      23.936,
      -7.154,
      31.82,
      23.936,
      -7.154,
      31.82,
      25.136,
      -7.154,
      31.82,
      25.136,
      -7.154,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2036.0",
    polygon: [
      37.305,
      -7.154,
      18.86,
      36.105,
      -7.154,
      18.86,
      36.105,
      -7.154,
      21.32,
      36.105,
      -7.154,
      21.32,
      37.305,
      -7.154,
      21.32,
      37.305,
      -7.154,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2947.0",
    polygon: [
      -83.118,
      -6.717,
      29.36,
      -83.118,
      -5.517,
      29.36,
      -83.118,
      -5.517,
      31.82,
      -83.118,
      -5.517,
      31.82,
      -83.118,
      -6.717,
      31.82,
      -83.118,
      -6.717,
      29.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2803.0",
    polygon: [
      -74.981,
      -36.464,
      3.473,
      -74.981,
      -34.144,
      3.473,
      -74.981,
      -34.144,
      4.167,
      -74.981,
      -34.144,
      4.167,
      -74.981,
      -36.464,
      4.167,
      -74.981,
      -36.464,
      3.473,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2800.0",
    polygon: [
      -74.981,
      -38.784,
      2.78,
      -74.981,
      -36.464,
      2.78,
      -74.981,
      -36.464,
      3.473,
      -74.981,
      -36.464,
      3.473,
      -74.981,
      -38.784,
      3.473,
      -74.981,
      -38.784,
      2.78,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2076.0",
    polygon: [
      37.305,
      -7.154,
      8.36,
      36.105,
      -7.154,
      8.36,
      36.105,
      -7.154,
      10.82,
      36.105,
      -7.154,
      10.82,
      37.305,
      -7.154,
      10.82,
      37.305,
      -7.154,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2519.0",
    polygon: [
      -54.383,
      -16.079,
      18.088,
      -54.383,
      -15.079,
      18.088,
      -53.175,
      -15.079,
      18.025,
      -53.175,
      -15.079,
      18.025,
      -53.175,
      -16.079,
      18.025,
      -54.383,
      -16.079,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1987.0",
    polygon: [
      -81.438,
      -7.156,
      15.36,
      -82.638,
      -7.156,
      15.36,
      -82.638,
      -7.156,
      17.82,
      -82.638,
      -7.156,
      17.82,
      -81.438,
      -7.156,
      17.82,
      -81.438,
      -7.156,
      15.36,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2480.0",
    polygon: [
      -17.804,
      -24.299,
      18.161,
      -17.804,
      -25.299,
      18.161,
      -19.012,
      -25.299,
      18.098,
      -19.012,
      -25.299,
      18.098,
      -19.012,
      -24.299,
      18.098,
      -17.804,
      -24.299,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2716.0",
    polygon: [
      -74.884,
      -37.801,
      15.36,
      -74.884,
      -36.651,
      15.36,
      -74.884,
      -36.651,
      17.82,
      -74.884,
      -36.651,
      17.82,
      -74.884,
      -37.801,
      17.82,
      -74.884,
      -37.801,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2715.0",
    polygon: [
      -74.884,
      -36.251,
      15.36,
      -74.884,
      -35.101,
      15.36,
      -74.884,
      -35.101,
      17.82,
      -74.884,
      -35.101,
      17.82,
      -74.884,
      -36.251,
      17.82,
      -74.884,
      -36.251,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2275.0",
    polygon: [
      -43.237,
      -10.802,
      18.86,
      -43.237,
      -9.652,
      18.86,
      -43.237,
      -9.652,
      21.32,
      -43.237,
      -9.652,
      21.32,
      -43.237,
      -10.802,
      21.32,
      -43.237,
      -10.802,
      18.86,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1831.0",
    polygon: [
      -22.295,
      -7.155,
      32.86,
      -23.495,
      -7.155,
      32.86,
      -23.495,
      -7.155,
      35.32,
      -23.495,
      -7.155,
      35.32,
      -22.295,
      -7.155,
      35.32,
      -22.295,
      -7.155,
      32.86,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1870.0",
    polygon: [
      -12.83,
      -7.155,
      22.36,
      -14.03,
      -7.155,
      22.36,
      -14.03,
      -7.155,
      24.82,
      -14.03,
      -7.155,
      24.82,
      -12.83,
      -7.155,
      24.82,
      -12.83,
      -7.155,
      22.36,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1949.0",
    polygon: [
      -55.247,
      -7.155,
      29.36,
      -56.447,
      -7.155,
      29.36,
      -56.447,
      -7.155,
      31.82,
      -56.447,
      -7.155,
      31.82,
      -55.247,
      -7.155,
      31.82,
      -55.247,
      -7.155,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2851.0",
    polygon: [
      -74.886,
      22.349,
      15.36,
      -74.886,
      23.499,
      15.36,
      -74.886,
      23.499,
      17.82,
      -74.886,
      23.499,
      17.82,
      -74.886,
      22.349,
      17.82,
      -74.886,
      22.349,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1868.0",
    polygon: [
      -26.351,
      -7.155,
      18.86,
      -27.551,
      -7.155,
      18.86,
      -27.551,
      -7.155,
      21.32,
      -27.551,
      -7.155,
      21.32,
      -26.351,
      -7.155,
      21.32,
      -26.351,
      -7.155,
      18.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "2560.0",
    polygon: [
      -61.277,
      -25.299,
      18.45,
      -61.277,
      -24.299,
      18.45,
      -57.304,
      -24.299,
      18.241,
      -57.304,
      -24.299,
      18.241,
      -57.304,
      -25.299,
      18.241,
      -61.277,
      -25.299,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2482.0",
    polygon: [
      -12.294,
      -27.756,
      18.45,
      -12.294,
      -28.756,
      18.45,
      -16.267,
      -28.756,
      18.241,
      -16.267,
      -28.756,
      18.241,
      -16.267,
      -27.756,
      18.241,
      -12.294,
      -27.756,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2441.0",
    polygon: [
      -16.426,
      -12.775,
      18.233,
      -16.426,
      -13.775,
      18.233,
      -17.634,
      -13.775,
      18.17,
      -17.634,
      -13.775,
      18.17,
      -17.634,
      -12.775,
      18.17,
      -16.426,
      -12.775,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2274.0",
    polygon: [
      -43.346,
      -7.925,
      18.86,
      -43.346,
      -7.368,
      18.86,
      -43.346,
      -7.368,
      21.32,
      -43.346,
      -7.368,
      21.32,
      -43.346,
      -7.925,
      21.32,
      -43.346,
      -7.925,
      18.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2802.0",
    polygon: [
      -74.981,
      -36.464,
      2.78,
      -74.981,
      -34.144,
      2.78,
      -74.981,
      -34.144,
      3.473,
      -74.981,
      -34.144,
      3.473,
      -74.981,
      -36.464,
      3.473,
      -74.981,
      -36.464,
      2.78,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2599.0",
    polygon: [
      -48.028,
      -15.079,
      18.233,
      -48.028,
      -16.079,
      18.233,
      -49.236,
      -16.079,
      18.17,
      -49.236,
      -16.079,
      18.17,
      -49.236,
      -15.079,
      18.17,
      -48.028,
      -15.079,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2850.0",
    polygon: [
      -74.886,
      23.499,
      15.36,
      -74.886,
      24.649,
      15.36,
      -74.886,
      24.649,
      17.82,
      -74.886,
      24.649,
      17.82,
      -74.886,
      23.499,
      17.82,
      -74.886,
      23.499,
      15.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "1909.0",
    polygon: [
      -49.838,
      -7.155,
      18.86,
      -51.038,
      -7.155,
      18.86,
      -51.038,
      -7.155,
      21.32,
      -51.038,
      -7.155,
      21.32,
      -49.838,
      -7.155,
      21.32,
      -49.838,
      -7.155,
      18.86,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2990.0",
    polygon: [
      -83.118,
      3.386,
      22.36,
      -83.118,
      4.586,
      22.36,
      -83.118,
      4.586,
      24.82,
      -83.118,
      4.586,
      24.82,
      -83.118,
      3.386,
      24.82,
      -83.118,
      3.386,
      22.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2717.0",
    polygon: [
      -74.884,
      -38.951,
      15.36,
      -74.884,
      -37.801,
      15.36,
      -74.884,
      -37.801,
      17.82,
      -74.884,
      -37.801,
      17.82,
      -74.884,
      -38.951,
      17.82,
      -74.884,
      -38.951,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "1984.0",
    polygon: [
      -77.382,
      -7.156,
      15.36,
      -78.582,
      -7.156,
      15.36,
      -78.582,
      -7.156,
      17.82,
      -78.582,
      -7.156,
      17.82,
      -77.382,
      -7.156,
      17.82,
      -77.382,
      -7.156,
      15.36,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2678.0",
    polygon: [
      -61.277,
      -39.127,
      18.45,
      -61.277,
      -38.127,
      18.45,
      -57.304,
      -38.127,
      18.241,
      -57.304,
      -38.127,
      18.241,
      -57.304,
      -39.127,
      18.241,
      -61.277,
      -39.127,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2175.0",
    polygon: [
      30.545,
      -7.154,
      0.05,
      29.268,
      -7.154,
      0.05,
      29.268,
      -7.154,
      2.78,
      29.268,
      -7.154,
      2.78,
      30.545,
      -7.154,
      2.78,
      30.545,
      -7.154,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1753.0",
    polygon: [
      -22.295,
      -7.155,
      36.36,
      -23.495,
      -7.155,
      36.36,
      -23.495,
      -7.155,
      39.128,
      -23.495,
      -7.155,
      39.128,
      -22.295,
      -7.155,
      39.128,
      -22.295,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2991.0",
    polygon: [
      -83.118,
      4.738,
      22.36,
      -83.118,
      5.938,
      22.36,
      -83.118,
      5.938,
      24.82,
      -83.118,
      5.938,
      24.82,
      -83.118,
      4.738,
      24.82,
      -83.118,
      4.738,
      22.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2754.0",
    polygon: [
      -74.884,
      -35.101,
      11.86,
      -74.884,
      -33.951,
      11.86,
      -74.884,
      -33.951,
      14.32,
      -74.884,
      -33.951,
      14.32,
      -74.884,
      -35.101,
      14.32,
      -74.884,
      -35.101,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1906.0",
    polygon: [
      -45.782,
      -7.155,
      18.86,
      -46.982,
      -7.155,
      18.86,
      -46.982,
      -7.155,
      21.32,
      -46.982,
      -7.155,
      21.32,
      -45.782,
      -7.155,
      21.32,
      -45.782,
      -7.155,
      18.86,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2481.0",
    polygon: [
      -19.188,
      -24.299,
      18.088,
      -19.188,
      -25.299,
      18.088,
      -20.396,
      -25.299,
      18.025,
      -20.396,
      -25.299,
      18.025,
      -20.396,
      -24.299,
      18.025,
      -19.188,
      -24.299,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1791.0",
    polygon: [
      -44.43,
      -7.155,
      36.36,
      -45.63,
      -7.155,
      36.36,
      -45.63,
      -7.155,
      39.128,
      -45.63,
      -7.155,
      39.128,
      -44.43,
      -7.155,
      39.128,
      -44.43,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2233.0",
    polygon: [
      -68.276,
      -15.241,
      22.36,
      -69.098,
      -15.241,
      22.36,
      -69.098,
      -15.241,
      24.82,
      -69.098,
      -15.241,
      24.82,
      -68.276,
      -15.241,
      24.82,
      -68.276,
      -15.241,
      22.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2854.0",
    polygon: [
      -74.886,
      28.899,
      15.36,
      -74.886,
      30.049,
      15.36,
      -74.886,
      30.049,
      17.82,
      -74.886,
      30.049,
      17.82,
      -74.886,
      28.899,
      17.82,
      -74.886,
      28.899,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2679.0",
    polygon: [
      -57.145,
      -39.127,
      18.233,
      -57.145,
      -38.127,
      18.233,
      -55.936,
      -38.127,
      18.17,
      -55.936,
      -38.127,
      18.17,
      -55.936,
      -39.127,
      18.17,
      -57.145,
      -39.127,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2404.0",
    polygon: [
      -24.165,
      -27.603,
      18.161,
      -24.165,
      -26.603,
      18.161,
      -22.957,
      -26.603,
      18.098,
      -22.957,
      -26.603,
      18.098,
      -22.957,
      -27.603,
      18.098,
      -24.165,
      -27.603,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2756.0",
    polygon: [
      -74.884,
      -37.801,
      11.86,
      -74.884,
      -36.651,
      11.86,
      -74.884,
      -36.651,
      14.32,
      -74.884,
      -36.651,
      14.32,
      -74.884,
      -37.801,
      14.32,
      -74.884,
      -37.801,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2038.0",
    polygon: [
      34.601,
      -7.154,
      18.86,
      33.401,
      -7.154,
      18.86,
      33.401,
      -7.154,
      21.32,
      33.401,
      -7.154,
      21.32,
      34.601,
      -7.154,
      21.32,
      34.601,
      -7.154,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2637.0",
    polygon: [
      -48.028,
      -21.994,
      18.233,
      -48.028,
      -22.994,
      18.233,
      -49.236,
      -22.994,
      18.17,
      -49.236,
      -22.994,
      18.17,
      -49.236,
      -21.994,
      18.17,
      -48.028,
      -21.994,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1871.0",
    polygon: [
      -14.182,
      -7.155,
      22.36,
      -15.382,
      -7.155,
      22.36,
      -15.382,
      -7.155,
      24.82,
      -15.382,
      -7.155,
      24.82,
      -14.182,
      -7.155,
      24.82,
      -14.182,
      -7.155,
      22.36,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2273.0",
    polygon: [
      -61.716,
      -10.752,
      18.86,
      -61.716,
      -12.002,
      18.86,
      -61.716,
      -12.002,
      21.32,
      -61.716,
      -12.002,
      21.32,
      -61.716,
      -10.752,
      21.32,
      -61.716,
      -10.752,
      18.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1946.0",
    polygon: [
      -51.19,
      -7.155,
      29.36,
      -52.39,
      -7.155,
      29.36,
      -52.39,
      -7.155,
      31.82,
      -52.39,
      -7.155,
      31.82,
      -51.19,
      -7.155,
      31.82,
      -51.19,
      -7.155,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2126.0",
    polygon: [
      -36.676,
      -16.256,
      25.86,
      -37.498,
      -16.256,
      25.86,
      -37.498,
      -16.256,
      28.32,
      -37.498,
      -16.256,
      28.32,
      -36.676,
      -16.256,
      28.32,
      -36.676,
      -16.256,
      25.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1948.0",
    polygon: [
      -53.895,
      -7.155,
      29.36,
      -55.095,
      -7.155,
      29.36,
      -55.095,
      -7.155,
      31.82,
      -55.095,
      -7.155,
      31.82,
      -53.895,
      -7.155,
      31.82,
      -53.895,
      -7.155,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2521.0",
    polygon: [
      -57.148,
      -14.927,
      18.233,
      -57.148,
      -13.927,
      18.233,
      -53.175,
      -13.927,
      18.025,
      -53.175,
      -13.927,
      18.025,
      -53.175,
      -14.927,
      18.025,
      -57.148,
      -14.927,
      18.233,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2125.0",
    polygon: [
      -31.769,
      -16.256,
      29.36,
      -32.969,
      -16.256,
      29.36,
      -32.969,
      -16.256,
      31.82,
      -32.969,
      -16.256,
      31.82,
      -31.769,
      -16.256,
      31.82,
      -31.769,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2890.0",
    polygon: [
      -74.886,
      28.899,
      11.86,
      -74.886,
      30.049,
      11.86,
      -74.886,
      30.049,
      14.32,
      -74.886,
      30.049,
      14.32,
      -74.886,
      28.899,
      14.32,
      -74.886,
      28.899,
      11.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1908.0",
    polygon: [
      -48.486,
      -7.155,
      18.86,
      -49.686,
      -7.155,
      18.86,
      -49.686,
      -7.155,
      21.32,
      -49.686,
      -7.155,
      21.32,
      -48.486,
      -7.155,
      21.32,
      -48.486,
      -7.155,
      18.86,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2176.0",
    polygon: [
      29.268,
      -7.154,
      0.05,
      27.992,
      -7.154,
      0.05,
      27.992,
      -7.154,
      2.78,
      27.992,
      -7.154,
      2.78,
      29.268,
      -7.154,
      2.78,
      29.268,
      -7.154,
      0.05,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2757.0",
    polygon: [
      -74.884,
      -38.951,
      11.86,
      -74.884,
      -37.801,
      11.86,
      -74.884,
      -37.801,
      14.32,
      -74.884,
      -37.801,
      14.32,
      -74.884,
      -38.951,
      14.32,
      -74.884,
      -38.951,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2364.0",
    polygon: [
      -29.675,
      -11.47,
      18.45,
      -29.675,
      -10.47,
      18.45,
      -25.702,
      -10.47,
      18.241,
      -25.702,
      -10.47,
      18.241,
      -25.702,
      -11.47,
      18.241,
      -29.675,
      -11.47,
      18.45,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "2174.0",
    polygon: [
      31.973,
      -7.154,
      0.05,
      30.697,
      -7.154,
      0.05,
      30.697,
      -7.154,
      2.78,
      30.697,
      -7.154,
      2.78,
      31.973,
      -7.154,
      2.78,
      31.973,
      -7.154,
      0.05,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1907.0",
    polygon: [
      -47.134,
      -7.155,
      18.86,
      -48.334,
      -7.155,
      18.86,
      -48.334,
      -7.155,
      21.32,
      -48.334,
      -7.155,
      21.32,
      -47.134,
      -7.155,
      21.32,
      -47.134,
      -7.155,
      18.86,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2718.0",
    polygon: [
      -74.884,
      -16.202,
      8.36,
      -74.884,
      -15.052,
      8.36,
      -74.884,
      -15.052,
      10.82,
      -74.884,
      -15.052,
      10.82,
      -74.884,
      -16.202,
      10.82,
      -74.884,
      -16.202,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1910.0",
    polygon: [
      -51.19,
      -7.155,
      18.86,
      -52.39,
      -7.155,
      18.86,
      -52.39,
      -7.155,
      21.32,
      -52.39,
      -7.155,
      21.32,
      -51.19,
      -7.155,
      21.32,
      -51.19,
      -7.155,
      18.86,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1833.0",
    polygon: [
      -24.999,
      -7.155,
      32.86,
      -26.199,
      -7.155,
      32.86,
      -26.199,
      -7.155,
      35.32,
      -26.199,
      -7.155,
      35.32,
      -24.999,
      -7.155,
      35.32,
      -24.999,
      -7.155,
      32.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "3034.0",
    polygon: [
      -74.947,
      30.327,
      4.86,
      -74.947,
      31.527,
      4.86,
      -74.947,
      31.527,
      7.32,
      -74.947,
      31.527,
      7.32,
      -74.947,
      30.327,
      7.32,
      -74.947,
      30.327,
      4.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1792.0",
    polygon: [
      -77.382,
      -7.155,
      39.128,
      -78.582,
      -7.155,
      39.128,
      -78.582,
      -7.155,
      40.05,
      -78.582,
      -7.155,
      40.05,
      -77.382,
      -7.155,
      40.05,
      -77.382,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1985.0",
    polygon: [
      -78.734,
      -7.156,
      15.36,
      -79.934,
      -7.156,
      15.36,
      -79.934,
      -7.156,
      17.82,
      -79.934,
      -7.156,
      17.82,
      -78.734,
      -7.156,
      17.82,
      -78.734,
      -7.156,
      15.36,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1754.0",
    polygon: [
      -23.647,
      -7.155,
      39.128,
      -24.847,
      -7.155,
      39.128,
      -24.847,
      -7.155,
      40.05,
      -24.847,
      -7.155,
      40.05,
      -23.647,
      -7.155,
      40.05,
      -23.647,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2600.0",
    polygon: [
      -49.406,
      -15.079,
      18.161,
      -49.406,
      -16.079,
      18.161,
      -50.614,
      -16.079,
      18.098,
      -50.614,
      -16.079,
      18.098,
      -50.614,
      -15.079,
      18.098,
      -49.406,
      -15.079,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2124.0",
    polygon: [
      -33.131,
      -16.256,
      29.36,
      -34.331,
      -16.256,
      29.36,
      -34.331,
      -16.256,
      31.82,
      -34.331,
      -16.256,
      31.82,
      -33.131,
      -16.256,
      31.82,
      -33.131,
      -16.256,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2365.0",
    polygon: [
      -25.543,
      -11.47,
      18.233,
      -25.543,
      -10.47,
      18.233,
      -24.335,
      -10.47,
      18.17,
      -24.335,
      -10.47,
      18.17,
      -24.335,
      -11.47,
      18.17,
      -25.543,
      -11.47,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1869.0",
    polygon: [
      -27.704,
      -7.155,
      18.86,
      -28.904,
      -7.155,
      18.86,
      -28.904,
      -7.155,
      21.32,
      -28.904,
      -7.155,
      21.32,
      -27.704,
      -7.155,
      21.32,
      -27.704,
      -7.155,
      18.86,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2520.0",
    polygon: [
      -61.277,
      -14.927,
      18.45,
      -61.277,
      -13.927,
      18.45,
      -57.304,
      -13.927,
      18.241,
      -57.304,
      -13.927,
      18.241,
      -57.304,
      -14.927,
      18.241,
      -61.277,
      -14.927,
      18.45,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2443.0",
    polygon: [
      -19.188,
      -12.775,
      18.088,
      -19.188,
      -13.775,
      18.088,
      -20.396,
      -13.775,
      18.025,
      -20.396,
      -13.775,
      18.025,
      -20.396,
      -12.775,
      18.025,
      -19.188,
      -12.775,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2234.0",
    polygon: [
      -67.453,
      -15.241,
      22.36,
      -68.276,
      -15.241,
      22.36,
      -68.276,
      -15.241,
      24.82,
      -68.276,
      -15.241,
      24.82,
      -67.453,
      -15.241,
      24.82,
      -67.453,
      -15.241,
      22.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2037.0",
    polygon: [
      35.953,
      -7.154,
      18.86,
      34.753,
      -7.154,
      18.86,
      34.753,
      -7.154,
      21.32,
      34.753,
      -7.154,
      21.32,
      35.953,
      -7.154,
      21.32,
      35.953,
      -7.154,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2313.0",
    polygon: [
      -30.129,
      -8.252,
      29.36,
      -30.129,
      -9.502,
      29.36,
      -30.129,
      -9.502,
      31.82,
      -30.129,
      -9.502,
      31.82,
      -30.129,
      -8.252,
      31.82,
      -30.129,
      -8.252,
      29.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2442.0",
    polygon: [
      -17.804,
      -12.775,
      18.161,
      -17.804,
      -13.775,
      18.161,
      -19.012,
      -13.775,
      18.098,
      -19.012,
      -13.775,
      18.098,
      -19.012,
      -12.775,
      18.098,
      -17.804,
      -12.775,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2077.0",
    polygon: [
      35.953,
      -7.154,
      8.36,
      34.753,
      -7.154,
      8.36,
      34.753,
      -7.154,
      10.82,
      34.753,
      -7.154,
      10.82,
      35.953,
      -7.154,
      10.82,
      35.953,
      -7.154,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2235.0",
    polygon: [
      -73.344,
      -15.241,
      22.36,
      -74.544,
      -15.241,
      22.36,
      -74.544,
      -15.241,
      24.82,
      -74.544,
      -15.241,
      24.82,
      -73.344,
      -15.241,
      24.82,
      -73.344,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2314.0",
    polygon: [
      -30.129,
      -9.502,
      29.36,
      -30.129,
      -10.752,
      29.36,
      -30.129,
      -10.752,
      31.82,
      -30.129,
      -10.752,
      31.82,
      -30.129,
      -9.502,
      31.82,
      -30.129,
      -9.502,
      29.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2993.0",
    polygon: [
      -83.118,
      2.033,
      25.86,
      -83.118,
      3.233,
      25.86,
      -83.118,
      3.233,
      28.32,
      -83.118,
      3.233,
      28.32,
      -83.118,
      2.033,
      28.32,
      -83.118,
      2.033,
      25.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2855.0",
    polygon: [
      -74.886,
      27.749,
      15.36,
      -74.886,
      28.899,
      15.36,
      -74.886,
      28.899,
      17.82,
      -74.886,
      28.899,
      17.82,
      -74.886,
      27.749,
      17.82,
      -74.886,
      27.749,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2894.0",
    polygon: [
      -74.886,
      9.998,
      18.86,
      -74.886,
      11.148,
      18.86,
      -74.886,
      11.148,
      21.32,
      -74.886,
      11.148,
      21.32,
      -74.886,
      9.998,
      21.32,
      -74.886,
      9.998,
      18.86,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2949.0",
    polygon: [
      -83.118,
      -4.012,
      29.36,
      -83.118,
      -2.812,
      29.36,
      -83.118,
      -2.812,
      31.82,
      -83.118,
      -2.812,
      31.82,
      -83.118,
      -4.012,
      31.82,
      -83.118,
      -4.012,
      29.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2805.0",
    polygon: [
      -74.981,
      -33.405,
      3.473,
      -74.981,
      -31.209,
      3.473,
      -74.981,
      -31.209,
      4.167,
      -74.981,
      -31.209,
      4.167,
      -74.981,
      -33.405,
      4.167,
      -74.981,
      -33.405,
      3.473,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2638.0",
    polygon: [
      -49.406,
      -21.994,
      18.161,
      -49.406,
      -22.994,
      18.161,
      -50.614,
      -22.994,
      18.098,
      -50.614,
      -22.994,
      18.098,
      -50.614,
      -21.994,
      18.098,
      -49.406,
      -21.994,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1950.0",
    polygon: [
      -56.599,
      -7.155,
      29.36,
      -57.799,
      -7.155,
      29.36,
      -57.799,
      -7.155,
      31.82,
      -57.799,
      -7.155,
      31.82,
      -56.599,
      -7.155,
      31.82,
      -56.599,
      -7.155,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1872.0",
    polygon: [
      -15.534,
      -7.155,
      22.36,
      -16.734,
      -7.155,
      22.36,
      -16.734,
      -7.155,
      24.82,
      -16.734,
      -7.155,
      24.82,
      -15.534,
      -7.155,
      24.82,
      -15.534,
      -7.155,
      22.36,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2804.0",
    polygon: [
      -74.981,
      -33.405,
      2.78,
      -74.981,
      -31.209,
      2.78,
      -74.981,
      -31.209,
      3.473,
      -74.981,
      -31.209,
      3.473,
      -74.981,
      -33.405,
      3.473,
      -74.981,
      -33.405,
      2.78,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2893.0",
    polygon: [
      -74.886,
      30.449,
      11.86,
      -74.886,
      31.599,
      11.86,
      -74.886,
      31.599,
      14.32,
      -74.886,
      31.599,
      14.32,
      -74.886,
      30.449,
      14.32,
      -74.886,
      30.449,
      11.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "3033.0",
    polygon: [
      -74.947,
      31.679,
      4.86,
      -74.947,
      32.579,
      4.86,
      -74.947,
      32.579,
      7.32,
      -74.947,
      32.579,
      7.32,
      -74.947,
      31.679,
      7.32,
      -74.947,
      31.679,
      4.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1951.0",
    polygon: [
      -57.951,
      -7.155,
      29.36,
      -59.151,
      -7.155,
      29.36,
      -59.151,
      -7.155,
      31.82,
      -59.151,
      -7.155,
      31.82,
      -57.951,
      -7.155,
      31.82,
      -57.951,
      -7.155,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1873.0",
    polygon: [
      -16.886,
      -7.155,
      22.36,
      -18.086,
      -7.155,
      22.36,
      -18.086,
      -7.155,
      24.82,
      -18.086,
      -7.155,
      24.82,
      -16.886,
      -7.155,
      24.82,
      -16.886,
      -7.155,
      22.36,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1755.0",
    polygon: [
      -23.647,
      -7.155,
      36.36,
      -24.847,
      -7.155,
      36.36,
      -24.847,
      -7.155,
      39.128,
      -24.847,
      -7.155,
      39.128,
      -23.647,
      -7.155,
      39.128,
      -23.647,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2236.0",
    polygon: [
      -62.008,
      -15.241,
      22.36,
      -63.208,
      -15.241,
      22.36,
      -63.208,
      -15.241,
      24.82,
      -63.208,
      -15.241,
      24.82,
      -62.008,
      -15.241,
      24.82,
      -62.008,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2994.0",
    polygon: [
      -83.118,
      3.386,
      25.86,
      -83.118,
      4.586,
      25.86,
      -83.118,
      4.586,
      28.32,
      -83.118,
      4.586,
      28.32,
      -83.118,
      3.386,
      28.32,
      -83.118,
      3.386,
      25.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2950.0",
    polygon: [
      -83.118,
      -2.66,
      29.36,
      -83.118,
      -1.46,
      29.36,
      -83.118,
      -1.46,
      31.82,
      -83.118,
      -1.46,
      31.82,
      -83.118,
      -2.66,
      31.82,
      -83.118,
      -2.66,
      29.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2366.0",
    polygon: [
      -24.165,
      -11.47,
      18.161,
      -24.165,
      -10.47,
      18.161,
      -22.957,
      -10.47,
      18.098,
      -22.957,
      -10.47,
      18.098,
      -22.957,
      -11.47,
      18.098,
      -24.165,
      -11.47,
      18.161,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2405.0",
    polygon: [
      -22.781,
      -27.603,
      18.088,
      -22.781,
      -26.603,
      18.088,
      -21.573,
      -26.603,
      18.025,
      -21.573,
      -26.603,
      18.025,
      -21.573,
      -27.603,
      18.025,
      -22.781,
      -27.603,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2758.0",
    polygon: [
      -74.981,
      -13.685,
      4.86,
      -74.981,
      -12.185,
      4.86,
      -74.981,
      -12.185,
      7.32,
      -74.981,
      -12.185,
      7.32,
      -74.981,
      -13.685,
      7.32,
      -74.981,
      -13.685,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2639.0",
    polygon: [
      -50.79,
      -21.994,
      18.088,
      -50.79,
      -22.994,
      18.088,
      -51.998,
      -22.994,
      18.025,
      -51.998,
      -22.994,
      18.025,
      -51.998,
      -21.994,
      18.025,
      -50.79,
      -21.994,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2522.0",
    polygon: [
      -61.277,
      -13.775,
      18.45,
      -61.277,
      -12.775,
      18.45,
      -57.304,
      -12.775,
      18.241,
      -57.304,
      -12.775,
      18.241,
      -57.304,
      -13.775,
      18.241,
      -61.277,
      -13.775,
      18.45,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2719.0",
    polygon: [
      -74.884,
      -17.352,
      8.36,
      -74.884,
      -16.202,
      8.36,
      -74.884,
      -16.202,
      10.82,
      -74.884,
      -16.202,
      10.82,
      -74.884,
      -17.352,
      10.82,
      -74.884,
      -17.352,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2680.0",
    polygon: [
      -55.767,
      -39.127,
      18.161,
      -55.767,
      -38.127,
      18.161,
      -54.558,
      -38.127,
      18.098,
      -54.558,
      -38.127,
      18.098,
      -54.558,
      -39.127,
      18.098,
      -55.767,
      -39.127,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2276.0",
    polygon: [
      -43.237,
      -11.952,
      18.86,
      -43.237,
      -10.802,
      18.86,
      -43.237,
      -10.802,
      21.32,
      -43.237,
      -10.802,
      21.32,
      -43.237,
      -11.952,
      21.32,
      -43.237,
      -11.952,
      18.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1790.0",
    polygon: [
      -44.43,
      -7.155,
      39.128,
      -45.63,
      -7.155,
      39.128,
      -45.63,
      -7.155,
      40.05,
      -45.63,
      -7.155,
      40.05,
      -44.43,
      -7.155,
      40.05,
      -44.43,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1947.0",
    polygon: [
      -52.543,
      -7.155,
      29.36,
      -53.743,
      -7.155,
      29.36,
      -53.743,
      -7.155,
      31.82,
      -53.743,
      -7.155,
      31.82,
      -52.543,
      -7.155,
      31.82,
      -52.543,
      -7.155,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2127.0",
    polygon: [
      -35.853,
      -16.256,
      25.86,
      -36.676,
      -16.256,
      25.86,
      -36.676,
      -16.256,
      28.32,
      -36.676,
      -16.256,
      28.32,
      -35.853,
      -16.256,
      28.32,
      -35.853,
      -16.256,
      25.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2561.0",
    polygon: [
      -57.145,
      -25.299,
      18.233,
      -57.145,
      -24.299,
      18.233,
      -55.936,
      -24.299,
      18.17,
      -55.936,
      -24.299,
      18.17,
      -55.936,
      -25.299,
      18.17,
      -57.145,
      -25.299,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2078.0",
    polygon: [
      34.601,
      -7.154,
      8.36,
      33.401,
      -7.154,
      8.36,
      33.401,
      -7.154,
      10.82,
      33.401,
      -7.154,
      10.82,
      34.601,
      -7.154,
      10.82,
      34.601,
      -7.154,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2039.0",
    polygon: [
      33.249,
      -7.154,
      18.86,
      32.049,
      -7.154,
      18.86,
      32.049,
      -7.154,
      21.32,
      32.049,
      -7.154,
      21.32,
      33.249,
      -7.154,
      21.32,
      33.249,
      -7.154,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2856.0",
    polygon: [
      -74.886,
      31.599,
      15.36,
      -74.886,
      32.749,
      15.36,
      -74.886,
      32.749,
      17.82,
      -74.886,
      32.749,
      17.82,
      -74.886,
      31.599,
      17.82,
      -74.886,
      31.599,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2483.0",
    polygon: [
      -16.423,
      -27.756,
      18.233,
      -16.423,
      -28.756,
      18.233,
      -20.396,
      -28.756,
      18.025,
      -20.396,
      -28.756,
      18.025,
      -20.396,
      -27.756,
      18.025,
      -16.423,
      -27.756,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3036.0",
    polygon: [
      -74.947,
      27.622,
      4.86,
      -74.947,
      28.822,
      4.86,
      -74.947,
      28.822,
      7.32,
      -74.947,
      28.822,
      7.32,
      -74.947,
      27.622,
      7.32,
      -74.947,
      27.622,
      4.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "2806.0",
    polygon: [
      -74.981,
      -31.209,
      2.78,
      -74.981,
      -28.202,
      2.78,
      -74.981,
      -28.202,
      3.473,
      -74.981,
      -28.202,
      3.473,
      -74.981,
      -31.209,
      3.473,
      -74.981,
      -31.209,
      2.78,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1988.0",
    polygon: [
      -76.03,
      -7.156,
      15.36,
      -77.23,
      -7.156,
      15.36,
      -77.23,
      -7.156,
      17.82,
      -77.23,
      -7.156,
      17.82,
      -76.03,
      -7.156,
      17.82,
      -76.03,
      -7.156,
      15.36,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1793.0",
    polygon: [
      -77.382,
      -7.155,
      36.36,
      -78.582,
      -7.155,
      36.36,
      -78.582,
      -7.155,
      39.128,
      -78.582,
      -7.155,
      39.128,
      -77.382,
      -7.155,
      39.128,
      -77.382,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1989.0",
    polygon: [
      -12.032,
      -7.071,
      18.86,
      -12.589,
      -7.071,
      18.86,
      -12.589,
      -7.071,
      21.32,
      -12.589,
      -7.071,
      21.32,
      -12.032,
      -7.071,
      21.32,
      -12.032,
      -7.071,
      18.86,
    ],
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1911.0",
    polygon: [
      -52.543,
      -7.155,
      18.86,
      -53.743,
      -7.155,
      18.86,
      -53.743,
      -7.155,
      21.32,
      -53.743,
      -7.155,
      21.32,
      -52.543,
      -7.155,
      21.32,
      -52.543,
      -7.155,
      18.86,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2995.0",
    polygon: [
      -83.118,
      4.738,
      25.86,
      -83.118,
      5.938,
      25.86,
      -83.118,
      5.938,
      28.32,
      -83.118,
      5.938,
      28.32,
      -83.118,
      4.738,
      28.32,
      -83.118,
      4.738,
      25.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2720.0",
    polygon: [
      -74.887,
      -13.502,
      8.36,
      -74.887,
      -12.352,
      8.36,
      -74.887,
      -12.352,
      10.82,
      -74.887,
      -12.352,
      10.82,
      -74.887,
      -13.502,
      10.82,
      -74.887,
      -13.502,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2523.0",
    polygon: [
      -57.145,
      -13.775,
      18.233,
      -57.145,
      -12.775,
      18.233,
      -55.936,
      -12.775,
      18.17,
      -55.936,
      -12.775,
      18.17,
      -55.936,
      -13.775,
      18.17,
      -57.145,
      -13.775,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2951.0",
    polygon: [
      -83.118,
      -6.717,
      8.36,
      -83.118,
      -5.517,
      8.36,
      -83.118,
      -5.517,
      10.82,
      -83.118,
      -5.517,
      10.82,
      -83.118,
      -6.717,
      10.82,
      -83.118,
      -6.717,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "2367.0",
    polygon: [
      -22.781,
      -11.47,
      18.088,
      -22.781,
      -10.47,
      18.088,
      -21.573,
      -10.47,
      18.025,
      -21.573,
      -10.47,
      18.025,
      -21.573,
      -11.47,
      18.025,
      -22.781,
      -11.47,
      18.088,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2640.0",
    polygon: [
      -43.895,
      -25.451,
      18.45,
      -43.895,
      -26.451,
      18.45,
      -47.869,
      -26.451,
      18.241,
      -47.869,
      -26.451,
      18.241,
      -47.869,
      -25.451,
      18.241,
      -43.895,
      -25.451,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2759.0",
    polygon: [
      -74.981,
      -14.505,
      4.86,
      -74.981,
      -13.685,
      4.86,
      -74.981,
      -13.685,
      7.32,
      -74.981,
      -13.685,
      7.32,
      -74.981,
      -14.505,
      7.32,
      -74.981,
      -14.505,
      4.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2406.0",
    polygon: [
      -29.675,
      -31.061,
      18.45,
      -29.675,
      -30.061,
      18.45,
      -25.702,
      -30.061,
      18.241,
      -25.702,
      -30.061,
      18.241,
      -25.702,
      -31.061,
      18.241,
      -29.675,
      -31.061,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2603.0",
    polygon: [
      -48.024,
      -13.927,
      18.233,
      -48.024,
      -14.927,
      18.233,
      -51.998,
      -14.927,
      18.025,
      -51.998,
      -14.927,
      18.025,
      -51.998,
      -13.927,
      18.025,
      -48.024,
      -13.927,
      18.233,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2178.0",
    polygon: [
      26.488,
      -7.154,
      0.05,
      25.288,
      -7.154,
      0.05,
      25.288,
      -7.154,
      2.78,
      25.288,
      -7.154,
      2.78,
      26.488,
      -7.154,
      2.78,
      26.488,
      -7.154,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2237.0",
    polygon: [
      -71.982,
      -15.241,
      22.36,
      -73.182,
      -15.241,
      22.36,
      -73.182,
      -15.241,
      24.82,
      -73.182,
      -15.241,
      24.82,
      -71.982,
      -15.241,
      24.82,
      -71.982,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1835.0",
    polygon: [
      -27.704,
      -7.155,
      32.86,
      -28.904,
      -7.155,
      32.86,
      -28.904,
      -7.155,
      35.32,
      -28.904,
      -7.155,
      35.32,
      -27.704,
      -7.155,
      35.32,
      -27.704,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2081.0",
    polygon: [
      30.545,
      -7.154,
      8.36,
      29.345,
      -7.154,
      8.36,
      29.345,
      -7.154,
      10.82,
      29.345,
      -7.154,
      10.82,
      30.545,
      -7.154,
      10.82,
      30.545,
      -7.154,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1795.0",
    polygon: [
      -78.734,
      -7.155,
      36.36,
      -79.934,
      -7.155,
      36.36,
      -79.934,
      -7.155,
      39.128,
      -79.934,
      -7.155,
      39.128,
      -78.734,
      -7.155,
      39.128,
      -78.734,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2239.0",
    polygon: [
      -69.26,
      -15.241,
      22.36,
      -70.46,
      -15.241,
      22.36,
      -70.46,
      -15.241,
      24.82,
      -70.46,
      -15.241,
      24.82,
      -69.26,
      -15.241,
      24.82,
      -69.26,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1991.0",
    polygon: [
      -12.032,
      -7.071,
      25.86,
      -12.589,
      -7.071,
      25.86,
      -12.589,
      -7.071,
      28.32,
      -12.589,
      -7.071,
      28.32,
      -12.032,
      -7.071,
      28.32,
      -12.032,
      -7.071,
      25.86,
    ],
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1913.0",
    polygon: [
      -55.247,
      -7.155,
      18.86,
      -56.447,
      -7.155,
      18.86,
      -56.447,
      -7.155,
      21.32,
      -56.447,
      -7.155,
      21.32,
      -55.247,
      -7.155,
      21.32,
      -55.247,
      -7.155,
      18.86,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "2278.0",
    polygon: [
      -74.807,
      -11.952,
      18.86,
      -74.807,
      -10.802,
      18.86,
      -74.807,
      -10.802,
      21.32,
      -74.807,
      -10.802,
      21.32,
      -74.807,
      -11.952,
      21.32,
      -74.807,
      -11.952,
      18.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1796.0",
    polygon: [
      -80.086,
      -7.155,
      39.128,
      -81.286,
      -7.155,
      39.128,
      -81.286,
      -7.155,
      40.05,
      -81.286,
      -7.155,
      40.05,
      -80.086,
      -7.155,
      40.05,
      -80.086,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2853.0",
    polygon: [
      -74.886,
      25.049,
      15.36,
      -74.886,
      26.199,
      15.36,
      -74.886,
      26.199,
      17.82,
      -74.886,
      26.199,
      17.82,
      -74.886,
      25.049,
      17.82,
      -74.886,
      25.049,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "1952.0",
    polygon: [
      -59.304,
      -7.155,
      29.36,
      -60.504,
      -7.155,
      29.36,
      -60.504,
      -7.155,
      31.82,
      -60.504,
      -7.155,
      31.82,
      -59.304,
      -7.155,
      31.82,
      -59.304,
      -7.155,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2807.0",
    polygon: [
      -74.981,
      -31.209,
      3.473,
      -74.981,
      -28.202,
      3.473,
      -74.981,
      -28.202,
      4.167,
      -74.981,
      -28.202,
      4.167,
      -74.981,
      -31.209,
      4.167,
      -74.981,
      -31.209,
      3.473,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2446.0",
    polygon: [
      -12.294,
      -10.47,
      18.45,
      -12.294,
      -11.47,
      18.45,
      -16.267,
      -11.47,
      18.241,
      -16.267,
      -11.47,
      18.241,
      -16.267,
      -10.47,
      18.241,
      -12.294,
      -10.47,
      18.45,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1874.0",
    polygon: [
      -18.238,
      -7.155,
      22.36,
      -19.438,
      -7.155,
      22.36,
      -19.438,
      -7.155,
      24.82,
      -19.438,
      -7.155,
      24.82,
      -18.238,
      -7.155,
      24.82,
      -18.238,
      -7.155,
      22.36,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1756.0",
    polygon: [
      -24.999,
      -7.155,
      39.128,
      -26.199,
      -7.155,
      39.128,
      -26.199,
      -7.155,
      40.05,
      -26.199,
      -7.155,
      40.05,
      -24.999,
      -7.155,
      40.05,
      -24.999,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2892.0",
    polygon: [
      -74.886,
      31.599,
      11.86,
      -74.886,
      32.749,
      11.86,
      -74.886,
      32.749,
      14.32,
      -74.886,
      32.749,
      14.32,
      -74.886,
      31.599,
      14.32,
      -74.886,
      31.599,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "3035.0",
    polygon: [
      -74.947,
      28.975,
      4.86,
      -74.947,
      30.175,
      4.86,
      -74.947,
      30.175,
      7.32,
      -74.947,
      30.175,
      7.32,
      -74.947,
      28.975,
      7.32,
      -74.947,
      28.975,
      4.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2444.0",
    polygon: [
      -12.294,
      -11.622,
      18.45,
      -12.294,
      -12.622,
      18.45,
      -16.267,
      -12.622,
      18.241,
      -16.267,
      -12.622,
      18.241,
      -16.267,
      -11.622,
      18.241,
      -12.294,
      -11.622,
      18.45,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2895.0",
    polygon: [
      -74.886,
      8.648,
      18.86,
      -74.886,
      9.999,
      18.86,
      -74.886,
      9.999,
      21.32,
      -74.886,
      9.999,
      21.32,
      -74.886,
      8.648,
      21.32,
      -74.886,
      8.648,
      18.86,
    ],
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2315.0",
    polygon: [
      -30.129,
      -10.752,
      29.36,
      -30.129,
      -12.002,
      29.36,
      -30.129,
      -12.002,
      31.82,
      -30.129,
      -12.002,
      31.82,
      -30.129,
      -10.752,
      31.82,
      -30.129,
      -10.752,
      29.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2368.0",
    polygon: [
      -29.675,
      -10.317,
      18.45,
      -29.675,
      -9.317,
      18.45,
      -25.702,
      -9.317,
      18.241,
      -25.702,
      -9.317,
      18.241,
      -25.702,
      -10.317,
      18.241,
      -29.675,
      -10.317,
      18.45,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "2080.0",
    polygon: [
      31.897,
      -7.154,
      8.36,
      30.697,
      -7.154,
      8.36,
      30.697,
      -7.154,
      10.82,
      30.697,
      -7.154,
      10.82,
      31.897,
      -7.154,
      10.82,
      31.897,
      -7.154,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2128.0",
    polygon: [
      -41.744,
      -16.256,
      25.86,
      -42.944,
      -16.256,
      25.86,
      -42.944,
      -16.256,
      28.32,
      -42.944,
      -16.256,
      28.32,
      -41.744,
      -16.256,
      28.32,
      -41.744,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2318.0",
    polygon: [
      -61.716,
      -10.752,
      29.36,
      -61.716,
      -12.002,
      29.36,
      -61.716,
      -12.002,
      31.82,
      -61.716,
      -12.002,
      31.82,
      -61.716,
      -10.752,
      31.82,
      -61.716,
      -10.752,
      29.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1794.0",
    polygon: [
      -78.734,
      -7.155,
      39.128,
      -79.934,
      -7.155,
      39.128,
      -79.934,
      -7.155,
      40.05,
      -79.934,
      -7.155,
      40.05,
      -78.734,
      -7.155,
      40.05,
      -78.734,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2562.0",
    polygon: [
      -55.767,
      -25.299,
      18.161,
      -55.767,
      -24.299,
      18.161,
      -54.558,
      -24.299,
      18.098,
      -54.558,
      -24.299,
      18.098,
      -54.558,
      -25.299,
      18.098,
      -55.767,
      -25.299,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1757.0",
    polygon: [
      -24.999,
      -7.155,
      36.36,
      -26.199,
      -7.155,
      36.36,
      -26.199,
      -7.155,
      39.128,
      -26.199,
      -7.155,
      39.128,
      -24.999,
      -7.155,
      39.128,
      -24.999,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2277.0",
    polygon: [
      -74.807,
      -10.802,
      18.86,
      -74.807,
      -9.652,
      18.86,
      -74.807,
      -9.652,
      21.32,
      -74.807,
      -9.652,
      21.32,
      -74.807,
      -10.802,
      21.32,
      -74.807,
      -10.802,
      18.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2486.0",
    polygon: [
      -17.804,
      -26.603,
      18.161,
      -17.804,
      -27.603,
      18.161,
      -19.012,
      -27.603,
      18.098,
      -19.012,
      -27.603,
      18.098,
      -19.012,
      -26.603,
      18.098,
      -17.804,
      -26.603,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2317.0",
    polygon: [
      -61.716,
      -9.502,
      29.36,
      -61.716,
      -10.752,
      29.36,
      -61.716,
      -10.752,
      31.82,
      -61.716,
      -10.752,
      31.82,
      -61.716,
      -9.502,
      31.82,
      -61.716,
      -9.502,
      29.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3032.0",
    polygon: [
      -81.761,
      -0.692,
      29.36,
      -81.761,
      -0.112,
      29.36,
      -81.761,
      -0.112,
      31.82,
      -81.761,
      -0.112,
      31.82,
      -81.761,
      -0.692,
      31.82,
      -81.761,
      -0.692,
      29.36,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2601.0",
    polygon: [
      -50.79,
      -15.079,
      18.088,
      -50.79,
      -16.079,
      18.088,
      -51.998,
      -16.079,
      18.025,
      -51.998,
      -16.079,
      18.025,
      -51.998,
      -15.079,
      18.025,
      -50.79,
      -15.079,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2369.0",
    polygon: [
      -25.546,
      -10.317,
      18.233,
      -25.546,
      -9.317,
      18.233,
      -21.573,
      -9.317,
      18.025,
      -21.573,
      -9.317,
      18.025,
      -21.573,
      -10.317,
      18.025,
      -25.546,
      -10.317,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2179.0",
    polygon: [
      25.136,
      -7.154,
      0.05,
      23.936,
      -7.154,
      0.05,
      23.936,
      -7.154,
      2.78,
      23.936,
      -7.154,
      2.78,
      25.136,
      -7.154,
      2.78,
      25.136,
      -7.154,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2079.0",
    polygon: [
      33.249,
      -7.154,
      8.36,
      32.049,
      -7.154,
      8.36,
      32.049,
      -7.154,
      10.82,
      32.049,
      -7.154,
      10.82,
      33.249,
      -7.154,
      10.82,
      33.249,
      -7.154,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2682.0",
    polygon: [
      -43.895,
      -38.127,
      18.45,
      -43.895,
      -39.127,
      18.45,
      -47.869,
      -39.127,
      18.241,
      -47.869,
      -39.127,
      18.241,
      -47.869,
      -38.127,
      18.241,
      -43.895,
      -38.127,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2683.0",
    polygon: [
      -48.028,
      -38.127,
      18.233,
      -48.028,
      -39.127,
      18.233,
      -49.236,
      -39.127,
      18.17,
      -49.236,
      -39.127,
      18.17,
      -49.236,
      -38.127,
      18.17,
      -48.028,
      -38.127,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2445.0",
    polygon: [
      -16.423,
      -11.622,
      18.233,
      -16.423,
      -12.622,
      18.233,
      -20.396,
      -12.622,
      18.025,
      -20.396,
      -12.622,
      18.025,
      -20.396,
      -11.622,
      18.025,
      -16.423,
      -11.622,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2896.0",
    polygon: [
      -74.886,
      7.498,
      18.86,
      -74.886,
      8.648,
      18.86,
      -74.886,
      8.648,
      21.32,
      -74.886,
      8.648,
      21.32,
      -74.886,
      7.498,
      21.32,
      -74.886,
      7.498,
      18.86,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2041.0",
    polygon: [
      30.545,
      -7.154,
      18.86,
      29.345,
      -7.154,
      18.86,
      29.345,
      -7.154,
      21.32,
      29.345,
      -7.154,
      21.32,
      30.545,
      -7.154,
      21.32,
      30.545,
      -7.154,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1836.0",
    polygon: [
      -29.056,
      -7.155,
      32.86,
      -30.256,
      -7.155,
      32.86,
      -30.256,
      -7.155,
      35.32,
      -30.256,
      -7.155,
      35.32,
      -29.056,
      -7.155,
      35.32,
      -29.056,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2564.0",
    polygon: [
      -61.277,
      -28.756,
      18.45,
      -61.277,
      -27.756,
      18.45,
      -57.304,
      -27.756,
      18.241,
      -57.304,
      -27.756,
      18.241,
      -57.304,
      -28.756,
      18.241,
      -61.277,
      -28.756,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1912.0",
    polygon: [
      -53.895,
      -7.155,
      18.86,
      -55.095,
      -7.155,
      18.86,
      -55.095,
      -7.155,
      21.32,
      -55.095,
      -7.155,
      21.32,
      -53.895,
      -7.155,
      21.32,
      -53.895,
      -7.155,
      18.86,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "2279.0",
    polygon: [
      -74.907,
      -7.928,
      22.36,
      -74.907,
      -7.371,
      22.36,
      -74.907,
      -7.371,
      24.82,
      -74.907,
      -7.371,
      24.82,
      -74.907,
      -7.928,
      24.82,
      -74.907,
      -7.928,
      22.36,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "1992.0",
    polygon: [
      -12.032,
      -7.071,
      29.36,
      -12.589,
      -7.071,
      29.36,
      -12.589,
      -7.071,
      31.82,
      -12.589,
      -7.071,
      31.82,
      -12.032,
      -7.071,
      31.82,
      -12.032,
      -7.071,
      29.36,
    ],
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2130.0",
    polygon: [
      -40.382,
      -16.256,
      25.86,
      -41.582,
      -16.256,
      25.86,
      -41.582,
      -16.256,
      28.32,
      -41.582,
      -16.256,
      28.32,
      -40.382,
      -16.256,
      28.32,
      -40.382,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2238.0",
    polygon: [
      -70.621,
      -15.241,
      22.36,
      -71.821,
      -15.241,
      22.36,
      -71.821,
      -15.241,
      24.82,
      -71.821,
      -15.241,
      24.82,
      -70.621,
      -15.241,
      24.82,
      -70.621,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2857.0",
    polygon: [
      -74.886,
      30.449,
      15.36,
      -74.886,
      31.599,
      15.36,
      -74.886,
      31.599,
      17.82,
      -74.886,
      31.599,
      17.82,
      -74.886,
      30.449,
      17.82,
      -74.886,
      30.449,
      15.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2485.0",
    polygon: [
      -16.426,
      -26.603,
      18.233,
      -16.426,
      -27.603,
      18.233,
      -17.634,
      -27.603,
      18.17,
      -17.634,
      -27.603,
      18.17,
      -17.634,
      -26.603,
      18.17,
      -16.426,
      -26.603,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2402.0",
    polygon: [
      -29.675,
      -27.603,
      18.45,
      -29.675,
      -26.603,
      18.45,
      -25.702,
      -26.603,
      18.241,
      -25.702,
      -26.603,
      18.241,
      -25.702,
      -27.603,
      18.241,
      -29.675,
      -27.603,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2316.0",
    polygon: [
      -61.716,
      -8.252,
      29.36,
      -61.716,
      -9.502,
      29.36,
      -61.716,
      -9.502,
      31.82,
      -61.716,
      -9.502,
      31.82,
      -61.716,
      -8.252,
      31.82,
      -61.716,
      -8.252,
      29.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2948.0",
    polygon: [
      -83.118,
      -5.365,
      29.36,
      -83.118,
      -4.165,
      29.36,
      -83.118,
      -4.165,
      31.82,
      -83.118,
      -4.165,
      31.82,
      -83.118,
      -5.365,
      31.82,
      -83.118,
      -5.365,
      29.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2675.0",
    polygon: [
      -50.79,
      -35.823,
      18.088,
      -50.79,
      -36.823,
      18.088,
      -51.998,
      -36.823,
      18.025,
      -51.998,
      -36.823,
      18.025,
      -51.998,
      -35.823,
      18.025,
      -50.79,
      -35.823,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2484.0",
    polygon: [
      -12.294,
      -26.603,
      18.45,
      -12.294,
      -27.603,
      18.45,
      -16.267,
      -27.603,
      18.241,
      -16.267,
      -27.603,
      18.241,
      -16.267,
      -26.603,
      18.241,
      -12.294,
      -26.603,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2177.0",
    polygon: [
      27.84,
      -7.154,
      0.05,
      26.64,
      -7.154,
      0.05,
      26.64,
      -7.154,
      2.78,
      26.64,
      -7.154,
      2.78,
      27.84,
      -7.154,
      2.78,
      27.84,
      -7.154,
      0.05,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2996.0",
    polygon: [
      -83.118,
      0.681,
      29.36,
      -83.118,
      1.881,
      29.36,
      -83.118,
      1.881,
      31.82,
      -83.118,
      1.881,
      31.82,
      -83.118,
      0.681,
      31.82,
      -83.118,
      0.681,
      29.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2524.0",
    polygon: [
      -55.767,
      -13.775,
      18.161,
      -55.767,
      -12.775,
      18.161,
      -54.558,
      -12.775,
      18.098,
      -54.558,
      -12.775,
      18.098,
      -54.558,
      -13.775,
      18.098,
      -55.767,
      -13.775,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2721.0",
    polygon: [
      -74.887,
      -14.652,
      8.36,
      -74.887,
      -13.502,
      8.36,
      -74.887,
      -13.502,
      10.82,
      -74.887,
      -13.502,
      10.82,
      -74.887,
      -14.652,
      10.82,
      -74.887,
      -14.652,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1834.0",
    polygon: [
      -26.351,
      -7.155,
      32.86,
      -27.551,
      -7.155,
      32.86,
      -27.551,
      -7.155,
      35.32,
      -27.551,
      -7.155,
      35.32,
      -26.351,
      -7.155,
      35.32,
      -26.351,
      -7.155,
      32.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2952.0",
    polygon: [
      -83.118,
      -5.365,
      8.36,
      -83.118,
      -4.165,
      8.36,
      -83.118,
      -4.165,
      10.82,
      -83.118,
      -4.165,
      10.82,
      -83.118,
      -5.365,
      10.82,
      -83.118,
      -5.365,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2641.0",
    polygon: [
      -48.024,
      -25.451,
      18.233,
      -48.024,
      -26.451,
      18.233,
      -51.998,
      -26.451,
      18.025,
      -51.998,
      -26.451,
      18.025,
      -51.998,
      -25.451,
      18.025,
      -48.024,
      -25.451,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2563.0",
    polygon: [
      -54.383,
      -25.299,
      18.088,
      -54.383,
      -24.299,
      18.088,
      -53.175,
      -24.299,
      18.025,
      -53.175,
      -24.299,
      18.025,
      -53.175,
      -25.299,
      18.025,
      -54.383,
      -25.299,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2407.0",
    polygon: [
      -25.546,
      -31.061,
      18.233,
      -25.546,
      -30.061,
      18.233,
      -21.573,
      -30.061,
      18.025,
      -21.573,
      -30.061,
      18.025,
      -21.573,
      -31.061,
      18.025,
      -25.546,
      -31.061,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1990.0",
    polygon: [
      -12.032,
      -7.071,
      22.36,
      -12.589,
      -7.071,
      22.36,
      -12.589,
      -7.071,
      24.82,
      -12.589,
      -7.071,
      24.82,
      -12.032,
      -7.071,
      24.82,
      -12.032,
      -7.071,
      22.36,
    ],
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1953.0",
    polygon: [
      -44.43,
      -7.155,
      29.36,
      -45.63,
      -7.155,
      29.36,
      -45.63,
      -7.155,
      31.82,
      -45.63,
      -7.155,
      31.82,
      -44.43,
      -7.155,
      31.82,
      -44.43,
      -7.155,
      29.36,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2129.0",
    polygon: [
      -30.408,
      -16.256,
      25.86,
      -31.608,
      -16.256,
      25.86,
      -31.608,
      -16.256,
      28.32,
      -31.608,
      -16.256,
      28.32,
      -30.408,
      -16.256,
      28.32,
      -30.408,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2447.0",
    polygon: [
      -16.426,
      -10.47,
      18.233,
      -16.426,
      -11.47,
      18.233,
      -17.634,
      -11.47,
      18.17,
      -17.634,
      -11.47,
      18.17,
      -17.634,
      -10.47,
      18.17,
      -16.426,
      -10.47,
      18.233,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1914.0",
    polygon: [
      -56.599,
      -7.155,
      18.86,
      -57.799,
      -7.155,
      18.86,
      -57.799,
      -7.155,
      21.32,
      -57.799,
      -7.155,
      21.32,
      -56.599,
      -7.155,
      21.32,
      -56.599,
      -7.155,
      18.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "2760.0",
    polygon: [
      -74.981,
      -16.054,
      4.86,
      -74.981,
      -15.234,
      4.86,
      -74.981,
      -15.234,
      7.32,
      -74.981,
      -15.234,
      7.32,
      -74.981,
      -16.054,
      7.32,
      -74.981,
      -16.054,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2602.0",
    polygon: [
      -43.895,
      -13.927,
      18.45,
      -43.895,
      -14.927,
      18.45,
      -47.869,
      -14.927,
      18.241,
      -47.869,
      -14.927,
      18.241,
      -47.869,
      -13.927,
      18.241,
      -43.895,
      -13.927,
      18.45,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2042.0",
    polygon: [
      29.192,
      -7.154,
      18.86,
      27.992,
      -7.154,
      18.86,
      27.992,
      -7.154,
      21.32,
      27.992,
      -7.154,
      21.32,
      29.192,
      -7.154,
      21.32,
      29.192,
      -7.154,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2681.0",
    polygon: [
      -54.383,
      -39.127,
      18.088,
      -54.383,
      -38.127,
      18.088,
      -53.175,
      -38.127,
      18.025,
      -53.175,
      -38.127,
      18.025,
      -53.175,
      -39.127,
      18.025,
      -54.383,
      -39.127,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1875.0",
    polygon: [
      -19.59,
      -7.155,
      22.36,
      -20.79,
      -7.155,
      22.36,
      -20.79,
      -7.155,
      24.82,
      -20.79,
      -7.155,
      24.82,
      -19.59,
      -7.155,
      24.82,
      -19.59,
      -7.155,
      22.36,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2040.0",
    polygon: [
      31.897,
      -7.154,
      18.86,
      30.697,
      -7.154,
      18.86,
      30.697,
      -7.154,
      21.32,
      30.697,
      -7.154,
      21.32,
      31.897,
      -7.154,
      21.32,
      31.897,
      -7.154,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2636.0",
    polygon: [
      -43.895,
      -21.994,
      18.45,
      -43.895,
      -22.994,
      18.45,
      -47.869,
      -22.994,
      18.241,
      -47.869,
      -22.994,
      18.241,
      -47.869,
      -21.994,
      18.241,
      -43.895,
      -21.994,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2992.0",
    polygon: [
      -83.118,
      0.681,
      25.86,
      -83.118,
      1.881,
      25.86,
      -83.118,
      1.881,
      28.32,
      -83.118,
      1.881,
      28.32,
      -83.118,
      0.681,
      28.32,
      -83.118,
      0.681,
      25.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2897.0",
    polygon: [
      -74.826,
      9.998,
      22.36,
      -74.826,
      11.148,
      22.36,
      -74.826,
      11.148,
      24.82,
      -74.826,
      11.148,
      24.82,
      -74.826,
      9.998,
      24.82,
      -74.826,
      9.998,
      22.36,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2997.0",
    polygon: [
      -83.118,
      2.033,
      29.36,
      -83.118,
      3.233,
      29.36,
      -83.118,
      3.233,
      31.82,
      -83.118,
      3.233,
      31.82,
      -83.118,
      2.033,
      31.82,
      -83.118,
      2.033,
      29.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2722.0",
    polygon: [
      -74.884,
      -18.903,
      8.36,
      -74.884,
      -17.753,
      8.36,
      -74.884,
      -17.753,
      10.82,
      -74.884,
      -17.753,
      10.82,
      -74.884,
      -18.903,
      10.82,
      -74.884,
      -18.903,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2525.0",
    polygon: [
      -54.383,
      -13.775,
      18.088,
      -54.383,
      -12.775,
      18.088,
      -53.175,
      -12.775,
      18.025,
      -53.175,
      -12.775,
      18.025,
      -53.175,
      -13.775,
      18.025,
      -54.383,
      -13.775,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2953.0",
    polygon: [
      -83.118,
      -4.012,
      8.36,
      -83.118,
      -2.812,
      8.36,
      -83.118,
      -2.812,
      10.82,
      -83.118,
      -2.812,
      10.82,
      -83.118,
      -4.012,
      10.82,
      -83.118,
      -4.012,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2642.0",
    polygon: [
      -43.895,
      -24.299,
      18.45,
      -43.895,
      -25.299,
      18.45,
      -47.869,
      -25.299,
      18.241,
      -47.869,
      -25.299,
      18.241,
      -47.869,
      -24.299,
      18.241,
      -43.895,
      -24.299,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2761.0",
    polygon: [
      -74.981,
      -17.554,
      4.86,
      -74.981,
      -16.054,
      4.86,
      -74.981,
      -16.054,
      7.32,
      -74.981,
      -16.054,
      7.32,
      -74.981,
      -17.554,
      7.32,
      -74.981,
      -17.554,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2487.0",
    polygon: [
      -19.188,
      -26.603,
      18.088,
      -19.188,
      -27.603,
      18.088,
      -20.396,
      -27.603,
      18.025,
      -20.396,
      -27.603,
      18.025,
      -20.396,
      -26.603,
      18.025,
      -19.188,
      -26.603,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1837.0",
    polygon: [
      -45.782,
      -7.155,
      32.86,
      -46.982,
      -7.155,
      32.86,
      -46.982,
      -7.155,
      35.32,
      -46.982,
      -7.155,
      35.32,
      -45.782,
      -7.155,
      35.32,
      -45.782,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1797.0",
    polygon: [
      -80.086,
      -7.155,
      36.36,
      -81.286,
      -7.155,
      36.36,
      -81.286,
      -7.155,
      39.128,
      -81.286,
      -7.155,
      39.128,
      -80.086,
      -7.155,
      39.128,
      -80.086,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2370.0",
    polygon: [
      -29.675,
      -9.165,
      18.45,
      -29.675,
      -8.165,
      18.45,
      -25.702,
      -8.165,
      18.241,
      -25.702,
      -8.165,
      18.241,
      -25.702,
      -9.165,
      18.241,
      -29.675,
      -9.165,
      18.45,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "2643.0",
    polygon: [
      -48.028,
      -24.299,
      18.233,
      -48.028,
      -25.299,
      18.233,
      -49.236,
      -25.299,
      18.17,
      -49.236,
      -25.299,
      18.17,
      -49.236,
      -24.299,
      18.17,
      -48.028,
      -24.299,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2684.0",
    polygon: [
      -49.406,
      -38.127,
      18.161,
      -49.406,
      -39.127,
      18.161,
      -50.614,
      -39.127,
      18.098,
      -50.614,
      -39.127,
      18.098,
      -50.614,
      -38.127,
      18.098,
      -49.406,
      -38.127,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2604.0",
    polygon: [
      -43.895,
      -12.775,
      18.45,
      -43.895,
      -13.775,
      18.45,
      -47.869,
      -13.775,
      18.241,
      -47.869,
      -13.775,
      18.241,
      -47.869,
      -12.775,
      18.241,
      -43.895,
      -12.775,
      18.45,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2565.0",
    polygon: [
      -57.148,
      -28.756,
      18.233,
      -57.148,
      -27.756,
      18.233,
      -53.175,
      -27.756,
      18.025,
      -53.175,
      -27.756,
      18.025,
      -53.175,
      -28.756,
      18.025,
      -57.148,
      -28.756,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2280.0",
    polygon: [
      -11.74,
      -7.925,
      22.36,
      -11.74,
      -7.368,
      22.36,
      -11.74,
      -7.368,
      24.82,
      -11.74,
      -7.368,
      24.82,
      -11.74,
      -7.925,
      24.82,
      -11.74,
      -7.925,
      22.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2131.0",
    polygon: [
      -39.021,
      -16.256,
      25.86,
      -40.221,
      -16.256,
      25.86,
      -40.221,
      -16.256,
      28.32,
      -40.221,
      -16.256,
      28.32,
      -39.021,
      -16.256,
      28.32,
      -39.021,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2082.0",
    polygon: [
      29.192,
      -7.154,
      8.36,
      27.992,
      -7.154,
      8.36,
      27.992,
      -7.154,
      10.82,
      27.992,
      -7.154,
      10.82,
      29.192,
      -7.154,
      10.82,
      29.192,
      -7.154,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2408.0",
    polygon: [
      -29.675,
      -29.908,
      18.45,
      -29.675,
      -28.908,
      18.45,
      -25.702,
      -28.908,
      18.241,
      -25.702,
      -28.908,
      18.241,
      -25.702,
      -29.908,
      18.241,
      -29.675,
      -29.908,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2448.0",
    polygon: [
      -17.804,
      -10.47,
      18.161,
      -17.804,
      -11.47,
      18.161,
      -19.012,
      -11.47,
      18.098,
      -19.012,
      -11.47,
      18.098,
      -19.012,
      -10.47,
      18.098,
      -17.804,
      -10.47,
      18.161,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2240.0",
    polygon: [
      -66.092,
      -15.241,
      22.36,
      -67.292,
      -15.241,
      22.36,
      -67.292,
      -15.241,
      24.82,
      -67.292,
      -15.241,
      24.82,
      -66.092,
      -15.241,
      24.82,
      -66.092,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2043.0",
    polygon: [
      27.84,
      -7.154,
      18.86,
      26.64,
      -7.154,
      18.86,
      26.64,
      -7.154,
      21.32,
      26.64,
      -7.154,
      21.32,
      27.84,
      -7.154,
      21.32,
      27.84,
      -7.154,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2319.0",
    polygon: [
      -43.346,
      -7.925,
      29.36,
      -43.346,
      -7.368,
      29.36,
      -43.346,
      -7.368,
      31.82,
      -43.346,
      -7.368,
      31.82,
      -43.346,
      -7.925,
      31.82,
      -43.346,
      -7.925,
      29.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2180.0",
    polygon: [
      30.545,
      -7.154,
      2.78,
      27.992,
      -7.154,
      2.78,
      27.992,
      -7.154,
      3.82,
      27.992,
      -7.154,
      3.82,
      30.545,
      -7.154,
      3.82,
      30.545,
      -7.154,
      2.78,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2898.0",
    polygon: [
      -74.826,
      8.648,
      22.36,
      -74.826,
      9.999,
      22.36,
      -74.826,
      9.999,
      24.82,
      -74.826,
      9.999,
      24.82,
      -74.826,
      8.648,
      24.82,
      -74.826,
      8.648,
      22.36,
    ],
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3038.0",
    polygon: [
      -74.947,
      25.218,
      4.86,
      -74.947,
      26.118,
      4.86,
      -74.947,
      26.118,
      7.32,
      -74.947,
      26.118,
      7.32,
      -74.947,
      25.218,
      7.32,
      -74.947,
      25.218,
      4.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2449.0",
    polygon: [
      -19.188,
      -10.47,
      18.088,
      -19.188,
      -11.47,
      18.088,
      -20.396,
      -11.47,
      18.025,
      -20.396,
      -11.47,
      18.025,
      -20.396,
      -10.47,
      18.025,
      -19.188,
      -10.47,
      18.088,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2044.0",
    polygon: [
      26.488,
      -7.154,
      18.86,
      25.288,
      -7.154,
      18.86,
      25.288,
      -7.154,
      21.32,
      25.288,
      -7.154,
      21.32,
      26.488,
      -7.154,
      21.32,
      26.488,
      -7.154,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2320.0",
    polygon: [
      -43.237,
      -10.802,
      29.36,
      -43.237,
      -9.652,
      29.36,
      -43.237,
      -9.652,
      31.82,
      -43.237,
      -9.652,
      31.82,
      -43.237,
      -10.802,
      31.82,
      -43.237,
      -10.802,
      29.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2241.0",
    polygon: [
      -63.369,
      -15.241,
      22.36,
      -64.569,
      -15.241,
      22.36,
      -64.569,
      -15.241,
      24.82,
      -64.569,
      -15.241,
      24.82,
      -63.369,
      -15.241,
      24.82,
      -63.369,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2181.0",
    polygon: [
      30.545,
      -7.154,
      3.82,
      27.992,
      -7.154,
      3.82,
      27.992,
      -7.154,
      4.86,
      27.992,
      -7.154,
      4.86,
      30.545,
      -7.154,
      4.86,
      30.545,
      -7.154,
      3.82,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "2488.0",
    polygon: [
      -12.294,
      -30.061,
      18.45,
      -12.294,
      -31.061,
      18.45,
      -16.267,
      -31.061,
      18.241,
      -16.267,
      -31.061,
      18.241,
      -16.267,
      -30.061,
      18.241,
      -12.294,
      -30.061,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1838.0",
    polygon: [
      -47.134,
      -7.155,
      32.86,
      -48.334,
      -7.155,
      32.86,
      -48.334,
      -7.155,
      35.32,
      -48.334,
      -7.155,
      35.32,
      -47.134,
      -7.155,
      35.32,
      -47.134,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1798.0",
    polygon: [
      -81.438,
      -7.155,
      39.128,
      -82.638,
      -7.155,
      39.128,
      -82.638,
      -7.155,
      40.05,
      -82.638,
      -7.155,
      40.05,
      -81.438,
      -7.155,
      40.05,
      -81.438,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1994.0",
    polygon: [
      -12.032,
      -7.075,
      36.36,
      -12.589,
      -7.075,
      36.36,
      -12.589,
      -7.075,
      38.82,
      -12.589,
      -7.075,
      38.82,
      -12.032,
      -7.075,
      38.82,
      -12.032,
      -7.075,
      36.36,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1955.0",
    polygon: [
      -78.734,
      -7.156,
      18.86,
      -79.934,
      -7.156,
      18.86,
      -79.934,
      -7.156,
      21.32,
      -79.934,
      -7.156,
      21.32,
      -78.734,
      -7.156,
      21.32,
      -78.734,
      -7.156,
      18.86,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1916.0",
    polygon: [
      -59.304,
      -7.155,
      18.86,
      -60.504,
      -7.155,
      18.86,
      -60.504,
      -7.155,
      21.32,
      -60.504,
      -7.155,
      21.32,
      -59.304,
      -7.155,
      21.32,
      -59.304,
      -7.155,
      18.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "1877.0",
    polygon: [
      -22.295,
      -7.155,
      22.36,
      -23.495,
      -7.155,
      22.36,
      -23.495,
      -7.155,
      24.82,
      -23.495,
      -7.155,
      24.82,
      -22.295,
      -7.155,
      24.82,
      -22.295,
      -7.155,
      22.36,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1759.0",
    polygon: [
      -26.351,
      -7.155,
      36.36,
      -27.551,
      -7.155,
      36.36,
      -27.551,
      -7.155,
      39.128,
      -27.551,
      -7.155,
      39.128,
      -26.351,
      -7.155,
      39.128,
      -26.351,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2410.0",
    polygon: [
      -24.165,
      -29.908,
      18.161,
      -24.165,
      -28.908,
      18.161,
      -22.957,
      -28.908,
      18.098,
      -22.957,
      -28.908,
      18.098,
      -22.957,
      -29.908,
      18.098,
      -24.165,
      -29.908,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1839.0",
    polygon: [
      -48.486,
      -7.155,
      32.86,
      -49.686,
      -7.155,
      32.86,
      -49.686,
      -7.155,
      35.32,
      -49.686,
      -7.155,
      35.32,
      -48.486,
      -7.155,
      35.32,
      -48.486,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1799.0",
    polygon: [
      -81.438,
      -7.155,
      36.36,
      -82.638,
      -7.155,
      36.36,
      -82.638,
      -7.155,
      39.128,
      -82.638,
      -7.155,
      39.128,
      -81.438,
      -7.155,
      39.128,
      -81.438,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2045.0",
    polygon: [
      25.136,
      -7.154,
      18.86,
      23.936,
      -7.154,
      18.86,
      23.936,
      -7.154,
      21.32,
      23.936,
      -7.154,
      21.32,
      25.136,
      -7.154,
      21.32,
      25.136,
      -7.154,
      18.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2371.0",
    polygon: [
      -25.543,
      -9.165,
      18.233,
      -25.543,
      -8.165,
      18.233,
      -24.335,
      -8.165,
      18.17,
      -24.335,
      -8.165,
      18.17,
      -24.335,
      -9.165,
      18.17,
      -25.543,
      -9.165,
      18.233,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "2083.0",
    polygon: [
      27.84,
      -7.154,
      8.36,
      26.64,
      -7.154,
      8.36,
      26.64,
      -7.154,
      10.82,
      26.64,
      -7.154,
      10.82,
      27.84,
      -7.154,
      10.82,
      27.84,
      -7.154,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1800.0",
    polygon: [
      -74.677,
      -7.155,
      39.128,
      -75.877,
      -7.155,
      39.128,
      -75.877,
      -7.155,
      40.05,
      -75.877,
      -7.155,
      40.05,
      -74.677,
      -7.155,
      40.05,
      -74.677,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2489.0",
    polygon: [
      -16.423,
      -30.061,
      18.233,
      -16.423,
      -31.061,
      18.233,
      -20.396,
      -31.061,
      18.025,
      -20.396,
      -31.061,
      18.025,
      -20.396,
      -30.061,
      18.025,
      -16.423,
      -30.061,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2084.0",
    polygon: [
      26.488,
      -7.154,
      8.36,
      25.288,
      -7.154,
      8.36,
      25.288,
      -7.154,
      10.82,
      25.288,
      -7.154,
      10.82,
      26.488,
      -7.154,
      10.82,
      26.488,
      -7.154,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2182.0",
    polygon: [
      33.249,
      -7.154,
      2.78,
      30.697,
      -7.154,
      2.78,
      30.697,
      -7.154,
      3.82,
      30.697,
      -7.154,
      3.82,
      33.249,
      -7.154,
      3.82,
      33.249,
      -7.154,
      2.78,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2321.0",
    polygon: [
      -43.237,
      -11.952,
      29.36,
      -43.237,
      -10.802,
      29.36,
      -43.237,
      -10.802,
      31.82,
      -43.237,
      -10.802,
      31.82,
      -43.237,
      -11.952,
      31.82,
      -43.237,
      -11.952,
      29.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2686.0",
    polygon: [
      -53.175,
      -40.432,
      18.025,
      -53.175,
      -40.932,
      18.025,
      -57.148,
      -40.932,
      18.233,
      -57.148,
      -40.932,
      18.233,
      -57.148,
      -40.432,
      18.233,
      -53.175,
      -40.432,
      18.025,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2644.0",
    polygon: [
      -49.406,
      -24.299,
      18.161,
      -49.406,
      -25.299,
      18.161,
      -50.614,
      -25.299,
      18.098,
      -50.614,
      -25.299,
      18.098,
      -50.614,
      -24.299,
      18.098,
      -49.406,
      -24.299,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2242.0",
    polygon: [
      -64.731,
      -15.241,
      22.36,
      -65.931,
      -15.241,
      22.36,
      -65.931,
      -15.241,
      24.82,
      -65.931,
      -15.241,
      24.82,
      -64.731,
      -15.241,
      24.82,
      -64.731,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2762.0",
    polygon: [
      -74.981,
      -19.054,
      4.86,
      -74.981,
      -17.554,
      4.86,
      -74.981,
      -17.554,
      7.32,
      -74.981,
      -17.554,
      7.32,
      -74.981,
      -19.054,
      7.32,
      -74.981,
      -19.054,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2605.0",
    polygon: [
      -48.028,
      -12.775,
      18.233,
      -48.028,
      -13.775,
      18.233,
      -49.236,
      -13.775,
      18.17,
      -49.236,
      -13.775,
      18.17,
      -49.236,
      -12.775,
      18.17,
      -48.028,
      -12.775,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2450.0",
    polygon: [
      -12.294,
      -9.317,
      18.45,
      -12.294,
      -10.317,
      18.45,
      -16.267,
      -10.317,
      18.241,
      -16.267,
      -10.317,
      18.241,
      -16.267,
      -9.317,
      18.241,
      -12.294,
      -9.317,
      18.45,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2133.0",
    polygon: [
      -34.492,
      -16.256,
      25.86,
      -35.692,
      -16.256,
      25.86,
      -35.692,
      -16.256,
      28.32,
      -35.692,
      -16.256,
      28.32,
      -34.492,
      -16.256,
      28.32,
      -34.492,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2899.0",
    polygon: [
      -74.826,
      7.498,
      22.36,
      -74.826,
      8.648,
      22.36,
      -74.826,
      8.648,
      24.82,
      -74.826,
      8.648,
      24.82,
      -74.826,
      7.498,
      24.82,
      -74.826,
      7.498,
      22.36,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2528.0",
    polygon: [
      -61.277,
      -11.47,
      18.45,
      -61.277,
      -10.47,
      18.45,
      -57.304,
      -10.47,
      18.241,
      -57.304,
      -10.47,
      18.241,
      -57.304,
      -11.47,
      18.241,
      -61.277,
      -11.47,
      18.45,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "2725.0",
    polygon: [
      -74.884,
      -22.752,
      8.36,
      -74.884,
      -21.602,
      8.36,
      -74.884,
      -21.602,
      10.82,
      -74.884,
      -21.602,
      10.82,
      -74.884,
      -22.752,
      10.82,
      -74.884,
      -22.752,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2724.0",
    polygon: [
      -74.884,
      -21.602,
      8.36,
      -74.884,
      -20.452,
      8.36,
      -74.884,
      -20.452,
      10.82,
      -74.884,
      -20.452,
      10.82,
      -74.884,
      -21.602,
      10.82,
      -74.884,
      -21.602,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2606.0",
    polygon: [
      -49.406,
      -12.775,
      18.161,
      -49.406,
      -13.775,
      18.161,
      -50.614,
      -13.775,
      18.098,
      -50.614,
      -13.775,
      18.098,
      -50.614,
      -12.775,
      18.098,
      -49.406,
      -12.775,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2281.0",
    polygon: [
      -11.63,
      -10.802,
      22.36,
      -11.63,
      -9.652,
      22.36,
      -11.63,
      -9.652,
      24.82,
      -11.63,
      -9.652,
      24.82,
      -11.63,
      -10.802,
      24.82,
      -11.63,
      -10.802,
      22.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2645.0",
    polygon: [
      -50.79,
      -24.299,
      18.088,
      -50.79,
      -25.299,
      18.088,
      -51.998,
      -25.299,
      18.025,
      -51.998,
      -25.299,
      18.025,
      -51.998,
      -24.299,
      18.025,
      -50.79,
      -24.299,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2607.0",
    polygon: [
      -50.79,
      -12.775,
      18.088,
      -50.79,
      -13.775,
      18.088,
      -51.998,
      -13.775,
      18.025,
      -51.998,
      -13.775,
      18.025,
      -51.998,
      -12.775,
      18.025,
      -50.79,
      -12.775,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2900.0",
    polygon: [
      -74.826,
      9.998,
      25.86,
      -74.826,
      11.148,
      25.86,
      -74.826,
      11.148,
      28.32,
      -74.826,
      11.148,
      28.32,
      -74.826,
      9.998,
      28.32,
      -74.826,
      9.998,
      25.86,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2411.0",
    polygon: [
      -22.781,
      -29.908,
      18.088,
      -22.781,
      -28.908,
      18.088,
      -21.573,
      -28.908,
      18.025,
      -21.573,
      -28.908,
      18.025,
      -21.573,
      -29.908,
      18.025,
      -22.781,
      -29.908,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2954.0",
    polygon: [
      -83.118,
      -2.66,
      8.36,
      -83.118,
      -1.46,
      8.36,
      -83.118,
      -1.46,
      10.82,
      -83.118,
      -1.46,
      10.82,
      -83.118,
      -2.66,
      10.82,
      -83.118,
      -2.66,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "2527.0",
    polygon: [
      -57.148,
      -12.622,
      18.233,
      -57.148,
      -11.622,
      18.233,
      -53.175,
      -11.622,
      18.025,
      -53.175,
      -11.622,
      18.025,
      -53.175,
      -12.622,
      18.025,
      -57.148,
      -12.622,
      18.233,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2085.0",
    polygon: [
      25.136,
      -7.154,
      8.36,
      23.936,
      -7.154,
      8.36,
      23.936,
      -7.154,
      10.82,
      23.936,
      -7.154,
      10.82,
      25.136,
      -7.154,
      10.82,
      25.136,
      -7.154,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2860.0",
    polygon: [
      -74.886,
      12.699,
      8.36,
      -74.886,
      13.849,
      8.36,
      -74.886,
      13.849,
      10.82,
      -74.886,
      13.849,
      10.82,
      -74.886,
      12.699,
      10.82,
      -74.886,
      12.699,
      8.36,
    ],
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2685.0",
    polygon: [
      -50.79,
      -38.127,
      18.088,
      -50.79,
      -39.127,
      18.088,
      -51.998,
      -39.127,
      18.025,
      -51.998,
      -39.127,
      18.025,
      -51.998,
      -38.127,
      18.025,
      -50.79,
      -38.127,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2372.0",
    polygon: [
      -24.165,
      -9.165,
      18.161,
      -24.165,
      -8.165,
      18.161,
      -22.957,
      -8.165,
      18.098,
      -22.957,
      -8.165,
      18.098,
      -22.957,
      -9.165,
      18.098,
      -24.165,
      -9.165,
      18.161,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2568.0",
    polygon: [
      -55.767,
      -27.603,
      18.161,
      -55.767,
      -26.603,
      18.161,
      -54.558,
      -26.603,
      18.098,
      -54.558,
      -26.603,
      18.098,
      -54.558,
      -27.603,
      18.098,
      -55.767,
      -27.603,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "1760.0",
    polygon: [
      -27.704,
      -7.155,
      39.128,
      -28.904,
      -7.155,
      39.128,
      -28.904,
      -7.155,
      40.05,
      -28.904,
      -7.155,
      40.05,
      -27.704,
      -7.155,
      40.05,
      -27.704,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2811.0",
    polygon: [
      -74.981,
      -28.202,
      2.78,
      -74.981,
      -26.005,
      2.78,
      -74.981,
      -26.005,
      3.473,
      -74.981,
      -26.005,
      3.473,
      -74.981,
      -28.202,
      3.473,
      -74.981,
      -28.202,
      2.78,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2409.0",
    polygon: [
      -25.543,
      -29.908,
      18.233,
      -25.543,
      -28.908,
      18.233,
      -24.335,
      -28.908,
      18.17,
      -24.335,
      -28.908,
      18.17,
      -24.335,
      -29.908,
      18.17,
      -25.543,
      -29.908,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2567.0",
    polygon: [
      -57.145,
      -27.603,
      18.233,
      -57.145,
      -26.603,
      18.233,
      -55.936,
      -26.603,
      18.17,
      -55.936,
      -26.603,
      18.17,
      -55.936,
      -27.603,
      18.17,
      -57.145,
      -27.603,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2687.0",
    polygon: [
      -57.304,
      -40.432,
      18.241,
      -57.304,
      -40.932,
      18.241,
      -61.277,
      -40.932,
      18.45,
      -61.277,
      -40.932,
      18.45,
      -61.277,
      -40.432,
      18.45,
      -57.304,
      -40.432,
      18.241,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2812.0",
    polygon: [
      -74.981,
      -28.202,
      3.473,
      -74.981,
      -26.005,
      3.473,
      -74.981,
      -26.005,
      4.167,
      -74.981,
      -26.005,
      4.167,
      -74.981,
      -28.202,
      4.167,
      -74.981,
      -28.202,
      3.473,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "3045.0",
    polygon: [
      -74.947,
      22.246,
      4.86,
      -74.947,
      23.446,
      4.86,
      -74.947,
      23.446,
      7.32,
      -74.947,
      23.446,
      7.32,
      -74.947,
      22.246,
      7.32,
      -74.947,
      22.246,
      4.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2861.0",
    polygon: [
      -74.886,
      11.549,
      8.36,
      -74.886,
      12.699,
      8.36,
      -74.886,
      12.699,
      10.82,
      -74.886,
      12.699,
      10.82,
      -74.886,
      11.549,
      10.82,
      -74.886,
      11.549,
      8.36,
    ],
    "04-15 14:00": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "3046.0",
    polygon: [
      -74.947,
      20.893,
      4.86,
      -74.947,
      22.093,
      4.86,
      -74.947,
      22.093,
      7.32,
      -74.947,
      22.093,
      7.32,
      -74.947,
      20.893,
      7.32,
      -74.947,
      20.893,
      4.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "3000.0",
    polygon: [
      -83.127,
      0.681,
      36.36,
      -83.127,
      1.881,
      36.36,
      -83.127,
      1.881,
      38.82,
      -83.127,
      1.881,
      38.82,
      -83.127,
      0.681,
      38.82,
      -83.127,
      0.681,
      36.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2999.0",
    polygon: [
      -83.118,
      4.738,
      29.36,
      -83.118,
      5.938,
      29.36,
      -83.118,
      5.938,
      31.82,
      -83.118,
      5.938,
      31.82,
      -83.118,
      4.738,
      31.82,
      -83.118,
      4.738,
      29.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2134.0",
    polygon: [
      -33.131,
      -16.256,
      25.86,
      -34.331,
      -16.256,
      25.86,
      -34.331,
      -16.256,
      28.32,
      -34.331,
      -16.256,
      28.32,
      -33.131,
      -16.256,
      28.32,
      -33.131,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1996.0",
    polygon: [
      37.305,
      -7.156,
      36.36,
      36.105,
      -7.156,
      36.36,
      36.105,
      -7.156,
      39.128,
      36.105,
      -7.156,
      39.128,
      37.305,
      -7.156,
      39.128,
      37.305,
      -7.156,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2412.0",
    polygon: [
      -29.675,
      -33.365,
      18.45,
      -29.675,
      -32.365,
      18.45,
      -25.702,
      -32.365,
      18.241,
      -25.702,
      -32.365,
      18.241,
      -25.702,
      -33.365,
      18.241,
      -29.675,
      -33.365,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2183.0",
    polygon: [
      33.249,
      -7.154,
      3.82,
      30.697,
      -7.154,
      3.82,
      30.697,
      -7.154,
      4.86,
      30.697,
      -7.154,
      4.86,
      33.249,
      -7.154,
      4.86,
      33.249,
      -7.154,
      3.82,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2526.0",
    polygon: [
      -61.277,
      -12.622,
      18.45,
      -61.277,
      -11.622,
      18.45,
      -57.304,
      -11.622,
      18.241,
      -57.304,
      -11.622,
      18.241,
      -57.304,
      -12.622,
      18.241,
      -61.277,
      -12.622,
      18.45,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1918.0",
    polygon: [
      -45.782,
      -7.155,
      22.36,
      -46.982,
      -7.155,
      22.36,
      -46.982,
      -7.155,
      24.82,
      -46.982,
      -7.155,
      24.82,
      -45.782,
      -7.155,
      24.82,
      -45.782,
      -7.155,
      22.36,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2132.0",
    polygon: [
      -37.66,
      -16.256,
      25.86,
      -38.86,
      -16.256,
      25.86,
      -38.86,
      -16.256,
      28.32,
      -38.86,
      -16.256,
      28.32,
      -37.66,
      -16.256,
      28.32,
      -37.66,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1995.0",
    polygon: [
      37.305,
      -7.156,
      39.128,
      36.105,
      -7.156,
      39.128,
      36.105,
      -7.156,
      40.05,
      36.105,
      -7.156,
      40.05,
      37.305,
      -7.156,
      40.05,
      37.305,
      -7.156,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2373.0",
    polygon: [
      -22.781,
      -9.165,
      18.088,
      -22.781,
      -8.165,
      18.088,
      -21.573,
      -8.165,
      18.025,
      -21.573,
      -8.165,
      18.025,
      -21.573,
      -9.165,
      18.025,
      -22.781,
      -9.165,
      18.088,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1879.0",
    polygon: [
      -24.999,
      -7.155,
      22.36,
      -26.199,
      -7.155,
      22.36,
      -26.199,
      -7.155,
      24.82,
      -26.199,
      -7.155,
      24.82,
      -24.999,
      -7.155,
      24.82,
      -24.999,
      -7.155,
      22.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1917.0",
    polygon: [
      -44.43,
      -7.155,
      18.86,
      -45.63,
      -7.155,
      18.86,
      -45.63,
      -7.155,
      21.32,
      -45.63,
      -7.155,
      21.32,
      -44.43,
      -7.155,
      21.32,
      -44.43,
      -7.155,
      18.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2566.0",
    polygon: [
      -61.277,
      -27.603,
      18.45,
      -61.277,
      -26.603,
      18.45,
      -57.304,
      -26.603,
      18.241,
      -57.304,
      -26.603,
      18.241,
      -57.304,
      -27.603,
      18.241,
      -61.277,
      -27.603,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1957.0",
    polygon: [
      -81.438,
      -7.156,
      18.86,
      -82.638,
      -7.156,
      18.86,
      -82.638,
      -7.156,
      21.32,
      -82.638,
      -7.156,
      21.32,
      -81.438,
      -7.156,
      21.32,
      -81.438,
      -7.156,
      18.86,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1840.0",
    polygon: [
      -49.838,
      -7.155,
      32.86,
      -51.038,
      -7.155,
      32.86,
      -51.038,
      -7.155,
      35.32,
      -51.038,
      -7.155,
      35.32,
      -49.838,
      -7.155,
      35.32,
      -49.838,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1956.0",
    polygon: [
      -80.086,
      -7.156,
      18.86,
      -81.286,
      -7.156,
      18.86,
      -81.286,
      -7.156,
      21.32,
      -81.286,
      -7.156,
      21.32,
      -80.086,
      -7.156,
      21.32,
      -80.086,
      -7.156,
      18.86,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2764.0",
    polygon: [
      -74.981,
      -21.459,
      4.86,
      -74.981,
      -20.639,
      4.86,
      -74.981,
      -20.639,
      7.32,
      -74.981,
      -20.639,
      7.32,
      -74.981,
      -21.459,
      7.32,
      -74.981,
      -21.459,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1878.0",
    polygon: [
      -23.647,
      -7.155,
      22.36,
      -24.847,
      -7.155,
      22.36,
      -24.847,
      -7.155,
      24.82,
      -24.847,
      -7.155,
      24.82,
      -23.647,
      -7.155,
      24.82,
      -23.647,
      -7.155,
      22.36,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1761.0",
    polygon: [
      -27.704,
      -7.155,
      36.36,
      -28.904,
      -7.155,
      36.36,
      -28.904,
      -7.155,
      39.128,
      -28.904,
      -7.155,
      39.128,
      -27.704,
      -7.155,
      39.128,
      -27.704,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2956.0",
    polygon: [
      -83.118,
      -5.365,
      11.86,
      -83.118,
      -4.165,
      11.86,
      -83.118,
      -4.165,
      14.32,
      -83.118,
      -4.165,
      14.32,
      -83.118,
      -5.365,
      14.32,
      -83.118,
      -5.365,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2283.0",
    polygon: [
      -30.129,
      -8.252,
      22.36,
      -30.129,
      -9.502,
      22.36,
      -30.129,
      -9.502,
      24.82,
      -30.129,
      -9.502,
      24.82,
      -30.129,
      -8.252,
      24.82,
      -30.129,
      -8.252,
      22.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2646.0",
    polygon: [
      -43.895,
      -27.756,
      18.45,
      -43.895,
      -28.756,
      18.45,
      -47.869,
      -28.756,
      18.241,
      -47.869,
      -28.756,
      18.241,
      -47.869,
      -27.756,
      18.241,
      -43.895,
      -27.756,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2723.0",
    polygon: [
      -74.884,
      -20.053,
      8.36,
      -74.884,
      -18.903,
      8.36,
      -74.884,
      -18.903,
      10.82,
      -74.884,
      -18.903,
      10.82,
      -74.884,
      -20.053,
      10.82,
      -74.884,
      -20.053,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2282.0",
    polygon: [
      -11.63,
      -11.952,
      22.36,
      -11.63,
      -10.802,
      22.36,
      -11.63,
      -10.802,
      24.82,
      -11.63,
      -10.802,
      24.82,
      -11.63,
      -11.952,
      24.82,
      -11.63,
      -11.952,
      22.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2955.0",
    polygon: [
      -83.118,
      -6.717,
      11.86,
      -83.118,
      -5.517,
      11.86,
      -83.118,
      -5.517,
      14.32,
      -83.118,
      -5.517,
      14.32,
      -83.118,
      -6.717,
      14.32,
      -83.118,
      -6.717,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "2998.0",
    polygon: [
      -83.118,
      3.386,
      29.36,
      -83.118,
      4.586,
      29.36,
      -83.118,
      4.586,
      31.82,
      -83.118,
      4.586,
      31.82,
      -83.118,
      3.386,
      31.82,
      -83.118,
      3.386,
      29.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2957.0",
    polygon: [
      -83.118,
      -4.012,
      11.86,
      -83.118,
      -2.812,
      11.86,
      -83.118,
      -2.812,
      14.32,
      -83.118,
      -2.812,
      14.32,
      -83.118,
      -4.012,
      14.32,
      -83.118,
      -4.012,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2862.0",
    polygon: [
      -74.886,
      15.399,
      8.36,
      -74.886,
      16.549,
      8.36,
      -74.886,
      16.549,
      10.82,
      -74.886,
      16.549,
      10.82,
      -74.886,
      15.399,
      10.82,
      -74.886,
      15.399,
      8.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2810.0",
    polygon: [
      -74.981,
      -36.464,
      0.05,
      -74.981,
      -34.144,
      0.05,
      -74.981,
      -34.144,
      2.78,
      -74.981,
      -34.144,
      2.78,
      -74.981,
      -36.464,
      2.78,
      -74.981,
      -36.464,
      0.05,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2813.0",
    polygon: [
      -74.981,
      -28.202,
      0.05,
      -74.981,
      -26.005,
      0.05,
      -74.981,
      -26.005,
      2.78,
      -74.981,
      -26.005,
      2.78,
      -74.981,
      -28.202,
      2.78,
      -74.981,
      -28.202,
      0.05,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2490.0",
    polygon: [
      -12.294,
      -28.908,
      18.45,
      -12.294,
      -29.908,
      18.45,
      -16.267,
      -29.908,
      18.241,
      -16.267,
      -29.908,
      18.241,
      -16.267,
      -28.908,
      18.241,
      -12.294,
      -28.908,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1762.0",
    polygon: [
      -29.056,
      -6.955,
      39.128,
      -30.256,
      -6.955,
      39.128,
      -30.256,
      -6.955,
      40.05,
      -30.256,
      -6.955,
      40.05,
      -29.056,
      -6.955,
      40.05,
      -29.056,
      -6.955,
      39.128,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2765.0",
    polygon: [
      -74.981,
      -22.959,
      4.86,
      -74.981,
      -21.459,
      4.86,
      -74.981,
      -21.459,
      7.32,
      -74.981,
      -21.459,
      7.32,
      -74.981,
      -22.959,
      7.32,
      -74.981,
      -22.959,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2809.0",
    polygon: [
      -74.981,
      -38.784,
      0.05,
      -74.981,
      -36.464,
      0.05,
      -74.981,
      -36.464,
      2.78,
      -74.981,
      -36.464,
      2.78,
      -74.981,
      -38.784,
      2.78,
      -74.981,
      -38.784,
      0.05,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2901.0",
    polygon: [
      -74.826,
      8.648,
      25.86,
      -74.826,
      9.999,
      25.86,
      -74.826,
      9.999,
      28.32,
      -74.826,
      9.999,
      28.32,
      -74.826,
      8.648,
      28.32,
      -74.826,
      8.648,
      25.86,
    ],
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "1880.0",
    polygon: [
      -26.351,
      -7.155,
      22.36,
      -27.551,
      -7.155,
      22.36,
      -27.551,
      -7.155,
      24.82,
      -27.551,
      -7.155,
      24.82,
      -26.351,
      -7.155,
      24.82,
      -26.351,
      -7.155,
      22.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "3037.0",
    polygon: [
      -74.947,
      26.27,
      4.86,
      -74.947,
      27.47,
      4.86,
      -74.947,
      27.47,
      7.32,
      -74.947,
      27.47,
      7.32,
      -74.947,
      26.27,
      7.32,
      -74.947,
      26.27,
      4.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "1919.0",
    polygon: [
      -47.134,
      -7.155,
      22.36,
      -48.334,
      -7.155,
      22.36,
      -48.334,
      -7.155,
      24.82,
      -48.334,
      -7.155,
      24.82,
      -47.134,
      -7.155,
      24.82,
      -47.134,
      -7.155,
      22.36,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2529.0",
    polygon: [
      -57.145,
      -11.47,
      18.233,
      -57.145,
      -10.47,
      18.233,
      -55.936,
      -10.47,
      18.17,
      -55.936,
      -10.47,
      18.17,
      -55.936,
      -11.47,
      18.17,
      -57.145,
      -11.47,
      18.233,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2726.0",
    polygon: [
      -74.884,
      -24.302,
      8.36,
      -74.884,
      -23.152,
      8.36,
      -74.884,
      -23.152,
      10.82,
      -74.884,
      -23.152,
      10.82,
      -74.884,
      -24.302,
      10.82,
      -74.884,
      -24.302,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1958.0",
    polygon: [
      -76.03,
      -7.156,
      18.86,
      -77.23,
      -7.156,
      18.86,
      -77.23,
      -7.156,
      21.32,
      -77.23,
      -7.156,
      21.32,
      -76.03,
      -7.156,
      21.32,
      -76.03,
      -7.156,
      18.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1920.0",
    polygon: [
      -48.486,
      -7.155,
      22.36,
      -49.686,
      -7.155,
      22.36,
      -49.686,
      -7.155,
      24.82,
      -49.686,
      -7.155,
      24.82,
      -48.486,
      -7.155,
      24.82,
      -48.486,
      -7.155,
      22.36,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1998.0",
    polygon: [
      35.953,
      -7.156,
      36.36,
      34.753,
      -7.156,
      36.36,
      34.753,
      -7.156,
      39.128,
      34.753,
      -7.156,
      39.128,
      35.953,
      -7.156,
      39.128,
      35.953,
      -7.156,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1842.0",
    polygon: [
      -52.543,
      -7.155,
      32.86,
      -53.743,
      -7.155,
      32.86,
      -53.743,
      -7.155,
      35.32,
      -53.743,
      -7.155,
      35.32,
      -52.543,
      -7.155,
      35.32,
      -52.543,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1802.0",
    polygon: [
      -76.03,
      -7.155,
      39.128,
      -77.23,
      -7.155,
      39.128,
      -77.23,
      -7.155,
      40.05,
      -77.23,
      -7.155,
      40.05,
      -76.03,
      -7.155,
      40.05,
      -76.03,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2088.0",
    polygon: [
      34.601,
      -7.154,
      11.86,
      33.401,
      -7.154,
      11.86,
      33.401,
      -7.154,
      14.32,
      33.401,
      -7.154,
      14.32,
      34.601,
      -7.154,
      14.32,
      34.601,
      -7.154,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2246.0",
    polygon: [
      -62.008,
      -16.256,
      25.86,
      -63.208,
      -16.256,
      25.86,
      -63.208,
      -16.256,
      28.32,
      -63.208,
      -16.256,
      28.32,
      -62.008,
      -16.256,
      28.32,
      -62.008,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2325.0",
    polygon: [
      -11.63,
      -10.802,
      32.86,
      -11.63,
      -9.652,
      32.86,
      -11.63,
      -9.652,
      35.32,
      -11.63,
      -9.652,
      35.32,
      -11.63,
      -10.802,
      35.32,
      -11.63,
      -10.802,
      32.86,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2688.0",
    polygon: [
      -43.895,
      -39.28,
      18.45,
      -43.895,
      -40.28,
      18.45,
      -47.869,
      -40.28,
      18.241,
      -47.869,
      -40.28,
      18.241,
      -47.869,
      -39.28,
      18.241,
      -43.895,
      -39.28,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2608.0",
    polygon: [
      -43.895,
      -11.622,
      18.45,
      -43.895,
      -12.622,
      18.45,
      -47.869,
      -12.622,
      18.241,
      -47.869,
      -12.622,
      18.241,
      -47.869,
      -11.622,
      18.241,
      -43.895,
      -11.622,
      18.45,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2569.0",
    polygon: [
      -54.383,
      -27.603,
      18.088,
      -54.383,
      -26.603,
      18.088,
      -53.175,
      -26.603,
      18.025,
      -53.175,
      -26.603,
      18.025,
      -53.175,
      -27.603,
      18.025,
      -54.383,
      -27.603,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2284.0",
    polygon: [
      -30.129,
      -9.502,
      22.36,
      -30.129,
      -10.752,
      22.36,
      -30.129,
      -10.752,
      24.82,
      -30.129,
      -10.752,
      24.82,
      -30.129,
      -9.502,
      24.82,
      -30.129,
      -9.502,
      22.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2086.0",
    polygon: [
      37.305,
      -7.154,
      11.86,
      36.105,
      -7.154,
      11.86,
      36.105,
      -7.154,
      14.32,
      36.105,
      -7.154,
      14.32,
      37.305,
      -7.154,
      14.32,
      37.305,
      -7.154,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2047.0",
    polygon: [
      35.953,
      -7.154,
      22.36,
      34.753,
      -7.154,
      22.36,
      34.753,
      -7.154,
      24.82,
      34.753,
      -7.154,
      24.82,
      35.953,
      -7.154,
      24.82,
      35.953,
      -7.154,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2244.0",
    polygon: [
      -67.453,
      -16.256,
      25.86,
      -68.276,
      -16.256,
      25.86,
      -68.276,
      -16.256,
      28.32,
      -68.276,
      -16.256,
      28.32,
      -67.453,
      -16.256,
      28.32,
      -67.453,
      -16.256,
      25.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2452.0",
    polygon: [
      -12.294,
      -8.165,
      18.45,
      -12.294,
      -9.165,
      18.45,
      -16.267,
      -9.165,
      18.241,
      -16.267,
      -9.165,
      18.241,
      -16.267,
      -8.165,
      18.241,
      -12.294,
      -8.165,
      18.45,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2323.0",
    polygon: [
      -74.807,
      -11.952,
      29.36,
      -74.807,
      -10.802,
      29.36,
      -74.807,
      -10.802,
      31.82,
      -74.807,
      -10.802,
      31.82,
      -74.807,
      -11.952,
      31.82,
      -74.807,
      -11.952,
      29.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2184.0",
    polygon: [
      27.84,
      -7.154,
      2.78,
      26.64,
      -7.154,
      2.78,
      26.64,
      -7.154,
      3.82,
      26.64,
      -7.154,
      3.82,
      27.84,
      -7.154,
      3.82,
      27.84,
      -7.154,
      2.78,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2491.0",
    polygon: [
      -16.426,
      -28.908,
      18.233,
      -16.426,
      -29.908,
      18.233,
      -17.634,
      -29.908,
      18.17,
      -17.634,
      -29.908,
      18.17,
      -17.634,
      -28.908,
      18.17,
      -16.426,
      -28.908,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1841.0",
    polygon: [
      -51.19,
      -7.155,
      32.86,
      -52.39,
      -7.155,
      32.86,
      -52.39,
      -7.155,
      35.32,
      -52.39,
      -7.155,
      35.32,
      -51.19,
      -7.155,
      35.32,
      -51.19,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1801.0",
    polygon: [
      -74.677,
      -7.155,
      36.36,
      -75.877,
      -7.155,
      36.36,
      -75.877,
      -7.155,
      39.128,
      -75.877,
      -7.155,
      39.128,
      -74.677,
      -7.155,
      39.128,
      -74.677,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1997.0",
    polygon: [
      35.953,
      -7.156,
      39.128,
      34.753,
      -7.156,
      39.128,
      34.753,
      -7.156,
      40.05,
      34.753,
      -7.156,
      40.05,
      35.953,
      -7.156,
      40.05,
      35.953,
      -7.156,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2493.0",
    polygon: [
      -19.188,
      -28.908,
      18.088,
      -19.188,
      -29.908,
      18.088,
      -20.396,
      -29.908,
      18.025,
      -20.396,
      -29.908,
      18.025,
      -20.396,
      -28.908,
      18.025,
      -19.188,
      -28.908,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2186.0",
    polygon: [
      25.136,
      -7.154,
      2.78,
      23.936,
      -7.154,
      2.78,
      23.936,
      -7.154,
      3.82,
      23.936,
      -7.154,
      3.82,
      25.136,
      -7.154,
      3.82,
      25.136,
      -7.154,
      2.78,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1803.0",
    polygon: [
      -76.03,
      -7.155,
      36.36,
      -77.23,
      -7.155,
      36.36,
      -77.23,
      -7.155,
      39.128,
      -77.23,
      -7.155,
      39.128,
      -76.03,
      -7.155,
      39.128,
      -76.03,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1999.0",
    polygon: [
      34.601,
      -7.156,
      39.128,
      33.401,
      -7.156,
      39.128,
      33.401,
      -7.156,
      40.05,
      33.401,
      -7.156,
      40.05,
      34.601,
      -7.156,
      40.05,
      34.601,
      -7.156,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1882.0",
    polygon: [
      -12.83,
      -7.155,
      25.86,
      -14.03,
      -7.155,
      25.86,
      -14.03,
      -7.155,
      28.32,
      -14.03,
      -7.155,
      28.32,
      -12.83,
      -7.155,
      28.32,
      -12.83,
      -7.155,
      25.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1921.0",
    polygon: [
      -49.838,
      -7.155,
      22.36,
      -51.038,
      -7.155,
      22.36,
      -51.038,
      -7.155,
      24.82,
      -51.038,
      -7.155,
      24.82,
      -49.838,
      -7.155,
      24.82,
      -49.838,
      -7.155,
      22.36,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1960.0",
    polygon: [
      -78.734,
      -7.156,
      22.36,
      -79.934,
      -7.156,
      22.36,
      -79.934,
      -7.156,
      24.82,
      -79.934,
      -7.156,
      24.82,
      -78.734,
      -7.156,
      24.82,
      -78.734,
      -7.156,
      22.36,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1764.0",
    polygon: [
      -45.782,
      -7.155,
      39.128,
      -46.982,
      -7.155,
      39.128,
      -46.982,
      -7.155,
      40.05,
      -46.982,
      -7.155,
      40.05,
      -45.782,
      -7.155,
      40.05,
      -45.782,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1993.0",
    polygon: [
      -12.032,
      -7.071,
      32.86,
      -12.589,
      -7.071,
      32.86,
      -12.589,
      -7.071,
      35.32,
      -12.589,
      -7.071,
      35.32,
      -12.032,
      -7.071,
      35.32,
      -12.032,
      -7.071,
      32.86,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2767.0",
    polygon: [
      -74.981,
      -25.279,
      4.86,
      -74.981,
      -24.459,
      4.86,
      -74.981,
      -24.459,
      7.32,
      -74.981,
      -24.459,
      7.32,
      -74.981,
      -25.279,
      7.32,
      -74.981,
      -25.279,
      4.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2648.0",
    polygon: [
      -43.895,
      -26.603,
      18.45,
      -43.895,
      -27.603,
      18.45,
      -47.869,
      -27.603,
      18.241,
      -47.869,
      -27.603,
      18.241,
      -47.869,
      -26.603,
      18.241,
      -43.895,
      -26.603,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2959.0",
    polygon: [
      -83.118,
      -6.717,
      15.36,
      -83.118,
      -5.517,
      15.36,
      -83.118,
      -5.517,
      17.82,
      -83.118,
      -5.517,
      17.82,
      -83.118,
      -6.717,
      17.82,
      -83.118,
      -6.717,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "2414.0",
    polygon: [
      -29.675,
      -32.213,
      18.45,
      -29.675,
      -31.213,
      18.45,
      -25.702,
      -31.213,
      18.241,
      -25.702,
      -31.213,
      18.241,
      -25.702,
      -32.213,
      18.241,
      -29.675,
      -32.213,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2570.0",
    polygon: [
      -61.277,
      -31.061,
      18.45,
      -61.277,
      -30.061,
      18.45,
      -57.304,
      -30.061,
      18.241,
      -57.304,
      -30.061,
      18.241,
      -57.304,
      -31.061,
      18.241,
      -61.277,
      -31.061,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2689.0",
    polygon: [
      -48.024,
      -39.28,
      18.233,
      -48.024,
      -40.28,
      18.233,
      -51.998,
      -40.28,
      18.025,
      -51.998,
      -40.28,
      18.025,
      -51.998,
      -39.28,
      18.025,
      -48.024,
      -39.28,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2815.0",
    polygon: [
      -74.981,
      -32.593,
      0.05,
      -74.981,
      -31.209,
      0.05,
      -74.981,
      -31.209,
      2.78,
      -74.981,
      -31.209,
      2.78,
      -74.981,
      -32.593,
      2.78,
      -74.981,
      -32.593,
      0.05,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2864.0",
    polygon: [
      -74.886,
      18.099,
      8.36,
      -74.886,
      19.249,
      8.36,
      -74.886,
      19.249,
      10.82,
      -74.886,
      19.249,
      10.82,
      -74.886,
      18.099,
      10.82,
      -74.886,
      18.099,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2903.0",
    polygon: [
      -74.826,
      9.998,
      29.36,
      -74.826,
      11.148,
      29.36,
      -74.826,
      11.148,
      31.82,
      -74.826,
      11.148,
      31.82,
      -74.826,
      9.998,
      31.82,
      -74.826,
      9.998,
      29.36,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3053.0",
    polygon: [
      -74.947,
      16.846,
      4.86,
      -74.947,
      18.046,
      4.86,
      -74.947,
      18.046,
      7.32,
      -74.947,
      18.046,
      7.32,
      -74.947,
      16.846,
      7.32,
      -74.947,
      16.846,
      4.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "3003.0",
    polygon: [
      -83.127,
      4.738,
      36.36,
      -83.127,
      5.938,
      36.36,
      -83.127,
      5.938,
      38.82,
      -83.127,
      5.938,
      38.82,
      -83.127,
      4.738,
      38.82,
      -83.127,
      4.738,
      36.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2531.0",
    polygon: [
      -54.383,
      -11.47,
      18.088,
      -54.383,
      -10.47,
      18.088,
      -53.175,
      -10.47,
      18.025,
      -53.175,
      -10.47,
      18.025,
      -53.175,
      -11.47,
      18.025,
      -54.383,
      -11.47,
      18.088,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2728.0",
    polygon: [
      -74.884,
      -27.002,
      8.36,
      -74.884,
      -25.852,
      8.36,
      -74.884,
      -25.852,
      10.82,
      -74.884,
      -25.852,
      10.82,
      -74.884,
      -27.002,
      10.82,
      -74.884,
      -27.002,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2243.0",
    polygon: [
      -68.276,
      -16.256,
      25.86,
      -69.098,
      -16.256,
      25.86,
      -69.098,
      -16.256,
      28.32,
      -69.098,
      -16.256,
      28.32,
      -68.276,
      -16.256,
      28.32,
      -68.276,
      -16.256,
      25.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2046.0",
    polygon: [
      37.305,
      -7.154,
      22.36,
      36.105,
      -7.154,
      22.36,
      36.105,
      -7.154,
      24.82,
      36.105,
      -7.154,
      24.82,
      37.305,
      -7.154,
      24.82,
      37.305,
      -7.154,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2451.0",
    polygon: [
      -16.423,
      -9.317,
      18.233,
      -16.423,
      -10.317,
      18.233,
      -20.396,
      -10.317,
      18.025,
      -20.396,
      -10.317,
      18.025,
      -20.396,
      -9.317,
      18.025,
      -16.423,
      -9.317,
      18.233,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2322.0",
    polygon: [
      -74.807,
      -10.802,
      29.36,
      -74.807,
      -9.652,
      29.36,
      -74.807,
      -9.652,
      31.82,
      -74.807,
      -9.652,
      31.82,
      -74.807,
      -10.802,
      31.82,
      -74.807,
      -10.802,
      29.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2285.0",
    polygon: [
      -30.129,
      -10.752,
      22.36,
      -30.129,
      -12.002,
      22.36,
      -30.129,
      -12.002,
      24.82,
      -30.129,
      -12.002,
      24.82,
      -30.129,
      -10.752,
      24.82,
      -30.129,
      -10.752,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2609.0",
    polygon: [
      -48.024,
      -11.622,
      18.233,
      -48.024,
      -12.622,
      18.233,
      -51.998,
      -12.622,
      18.025,
      -51.998,
      -12.622,
      18.025,
      -51.998,
      -11.622,
      18.025,
      -48.024,
      -11.622,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2146.0",
    polygon: [
      -36.676,
      -15.241,
      22.36,
      -37.498,
      -15.241,
      22.36,
      -37.498,
      -15.241,
      24.82,
      -37.498,
      -15.241,
      24.82,
      -36.676,
      -15.241,
      24.82,
      -36.676,
      -15.241,
      22.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2245.0",
    polygon: [
      -73.344,
      -16.256,
      25.86,
      -74.544,
      -16.256,
      25.86,
      -74.544,
      -16.256,
      28.32,
      -74.544,
      -16.256,
      28.32,
      -73.344,
      -16.256,
      28.32,
      -73.344,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2048.0",
    polygon: [
      34.601,
      -7.154,
      22.36,
      33.401,
      -7.154,
      22.36,
      33.401,
      -7.154,
      24.82,
      33.401,
      -7.154,
      24.82,
      34.601,
      -7.154,
      24.82,
      34.601,
      -7.154,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2087.0",
    polygon: [
      35.953,
      -7.154,
      11.86,
      34.753,
      -7.154,
      11.86,
      34.753,
      -7.154,
      14.32,
      34.753,
      -7.154,
      14.32,
      35.953,
      -7.154,
      14.32,
      35.953,
      -7.154,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2453.0",
    polygon: [
      -16.426,
      -8.165,
      18.233,
      -16.426,
      -9.165,
      18.233,
      -17.634,
      -9.165,
      18.17,
      -17.634,
      -9.165,
      18.17,
      -17.634,
      -8.165,
      18.17,
      -16.426,
      -8.165,
      18.233,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2324.0",
    polygon: [
      -11.74,
      -7.925,
      32.86,
      -11.74,
      -7.368,
      32.86,
      -11.74,
      -7.368,
      35.32,
      -11.74,
      -7.368,
      35.32,
      -11.74,
      -7.925,
      35.32,
      -11.74,
      -7.925,
      32.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2185.0",
    polygon: [
      26.488,
      -7.154,
      2.78,
      25.288,
      -7.154,
      2.78,
      25.288,
      -7.154,
      3.82,
      25.288,
      -7.154,
      3.82,
      26.488,
      -7.154,
      3.82,
      26.488,
      -7.154,
      2.78,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2492.0",
    polygon: [
      -17.804,
      -28.908,
      18.161,
      -17.804,
      -29.908,
      18.161,
      -19.012,
      -29.908,
      18.098,
      -19.012,
      -29.908,
      18.098,
      -19.012,
      -28.908,
      18.098,
      -17.804,
      -28.908,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1881.0",
    polygon: [
      -27.704,
      -7.155,
      22.36,
      -28.904,
      -7.155,
      22.36,
      -28.904,
      -7.155,
      24.82,
      -28.904,
      -7.155,
      24.82,
      -27.704,
      -7.155,
      24.82,
      -27.704,
      -7.155,
      22.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2049.0",
    polygon: [
      33.249,
      -7.154,
      22.36,
      32.049,
      -7.154,
      22.36,
      32.049,
      -7.154,
      24.82,
      32.049,
      -7.154,
      24.82,
      33.249,
      -7.154,
      24.82,
      33.249,
      -7.154,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2454.0",
    polygon: [
      -17.804,
      -8.165,
      18.161,
      -17.804,
      -9.165,
      18.161,
      -19.012,
      -9.165,
      18.098,
      -19.012,
      -9.165,
      18.098,
      -19.012,
      -8.165,
      18.098,
      -17.804,
      -8.165,
      18.161,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2147.0",
    polygon: [
      -35.853,
      -15.241,
      22.36,
      -36.676,
      -15.241,
      22.36,
      -36.676,
      -15.241,
      24.82,
      -36.676,
      -15.241,
      24.82,
      -35.853,
      -15.241,
      24.82,
      -35.853,
      -15.241,
      22.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2768.0",
    polygon: [
      -74.981,
      -26.824,
      4.86,
      -74.981,
      -26.004,
      4.86,
      -74.981,
      -26.004,
      7.32,
      -74.981,
      -26.004,
      7.32,
      -74.981,
      -26.824,
      7.32,
      -74.981,
      -26.824,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2571.0",
    polygon: [
      -57.148,
      -31.061,
      18.233,
      -57.148,
      -30.061,
      18.233,
      -53.175,
      -30.061,
      18.025,
      -53.175,
      -30.061,
      18.025,
      -53.175,
      -31.061,
      18.025,
      -57.148,
      -31.061,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2415.0",
    polygon: [
      -25.543,
      -32.213,
      18.233,
      -25.543,
      -31.213,
      18.233,
      -24.335,
      -31.213,
      18.17,
      -24.335,
      -31.213,
      18.17,
      -24.335,
      -32.213,
      18.17,
      -25.543,
      -32.213,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2649.0",
    polygon: [
      -48.028,
      -26.603,
      18.233,
      -48.028,
      -27.603,
      18.233,
      -49.236,
      -27.603,
      18.17,
      -49.236,
      -27.603,
      18.17,
      -49.236,
      -26.603,
      18.17,
      -48.028,
      -26.603,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3047.0",
    polygon: [
      -74.947,
      19.841,
      4.86,
      -74.947,
      20.741,
      4.86,
      -74.947,
      20.741,
      7.32,
      -74.947,
      20.741,
      7.32,
      -74.947,
      19.841,
      7.32,
      -74.947,
      19.841,
      4.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2610.0",
    polygon: [
      -43.895,
      -10.47,
      18.45,
      -43.895,
      -11.47,
      18.45,
      -47.869,
      -11.47,
      18.241,
      -47.869,
      -11.47,
      18.241,
      -47.869,
      -10.47,
      18.241,
      -43.895,
      -10.47,
      18.45,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2904.0",
    polygon: [
      -74.826,
      8.648,
      29.36,
      -74.826,
      9.999,
      29.36,
      -74.826,
      9.999,
      31.82,
      -74.826,
      9.999,
      31.82,
      -74.826,
      8.648,
      31.82,
      -74.826,
      8.648,
      29.36,
    ],
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2766.0",
    polygon: [
      -74.981,
      -24.459,
      4.86,
      -74.981,
      -22.959,
      4.86,
      -74.981,
      -22.959,
      7.32,
      -74.981,
      -22.959,
      7.32,
      -74.981,
      -24.459,
      7.32,
      -74.981,
      -24.459,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2865.0",
    polygon: [
      -74.886,
      16.949,
      8.36,
      -74.886,
      18.099,
      8.36,
      -74.886,
      18.099,
      10.82,
      -74.886,
      18.099,
      10.82,
      -74.886,
      16.949,
      10.82,
      -74.886,
      16.949,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2958.0",
    polygon: [
      -83.118,
      -2.66,
      11.86,
      -83.118,
      -1.46,
      11.86,
      -83.118,
      -1.46,
      14.32,
      -83.118,
      -1.46,
      14.32,
      -83.118,
      -2.66,
      14.32,
      -83.118,
      -2.66,
      11.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "3054.0",
    polygon: [
      -74.947,
      15.493,
      4.86,
      -74.947,
      16.693,
      4.86,
      -74.947,
      16.693,
      7.32,
      -74.947,
      16.693,
      7.32,
      -74.947,
      15.493,
      7.32,
      -74.947,
      15.493,
      4.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "3001.0",
    polygon: [
      -83.127,
      2.033,
      36.36,
      -83.127,
      3.233,
      36.36,
      -83.127,
      3.233,
      38.82,
      -83.127,
      3.233,
      38.82,
      -83.127,
      2.033,
      38.82,
      -83.127,
      2.033,
      36.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2690.0",
    polygon: [
      -74.981,
      -10.685,
      4.86,
      -74.981,
      -9.865,
      4.86,
      -74.981,
      -9.865,
      7.32,
      -74.981,
      -9.865,
      7.32,
      -74.981,
      -10.685,
      7.32,
      -74.981,
      -10.685,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2647.0",
    polygon: [
      -48.024,
      -27.756,
      18.233,
      -48.024,
      -28.756,
      18.233,
      -51.998,
      -28.756,
      18.025,
      -51.998,
      -28.756,
      18.025,
      -51.998,
      -27.756,
      18.025,
      -48.024,
      -27.756,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3005.0",
    polygon: [
      -81.756,
      -0.692,
      36.36,
      -81.756,
      -0.112,
      36.36,
      -81.756,
      -0.112,
      39.128,
      -81.756,
      -0.112,
      39.128,
      -81.756,
      -0.692,
      39.128,
      -81.756,
      -0.692,
      36.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2532.0",
    polygon: [
      -61.277,
      -10.317,
      18.45,
      -61.277,
      -9.317,
      18.45,
      -57.304,
      -9.317,
      18.241,
      -57.304,
      -9.317,
      18.241,
      -57.304,
      -10.317,
      18.241,
      -61.277,
      -10.317,
      18.45,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "2960.0",
    polygon: [
      -83.118,
      -5.365,
      15.36,
      -83.118,
      -4.165,
      15.36,
      -83.118,
      -4.165,
      17.82,
      -83.118,
      -4.165,
      17.82,
      -83.118,
      -5.365,
      17.82,
      -83.118,
      -5.365,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2729.0",
    polygon: [
      -74.884,
      -28.152,
      8.36,
      -74.884,
      -27.002,
      8.36,
      -74.884,
      -27.002,
      10.82,
      -74.884,
      -27.002,
      10.82,
      -74.884,
      -28.152,
      10.82,
      -74.884,
      -28.152,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2286.0",
    polygon: [
      -61.716,
      -8.252,
      22.36,
      -61.716,
      -9.502,
      22.36,
      -61.716,
      -9.502,
      24.82,
      -61.716,
      -9.502,
      24.82,
      -61.716,
      -8.252,
      24.82,
      -61.716,
      -8.252,
      22.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "2413.0",
    polygon: [
      -25.546,
      -33.365,
      18.233,
      -25.546,
      -32.365,
      18.233,
      -21.573,
      -32.365,
      18.025,
      -21.573,
      -32.365,
      18.025,
      -21.573,
      -33.365,
      18.025,
      -25.546,
      -33.365,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2530.0",
    polygon: [
      -55.767,
      -11.47,
      18.161,
      -55.767,
      -10.47,
      18.161,
      -54.558,
      -10.47,
      18.098,
      -54.558,
      -10.47,
      18.098,
      -54.558,
      -11.47,
      18.098,
      -55.767,
      -11.47,
      18.161,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2727.0",
    polygon: [
      -74.884,
      -25.452,
      8.36,
      -74.884,
      -24.302,
      8.36,
      -74.884,
      -24.302,
      10.82,
      -74.884,
      -24.302,
      10.82,
      -74.884,
      -25.452,
      10.82,
      -74.884,
      -25.452,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3002.0",
    polygon: [
      -83.127,
      3.386,
      36.36,
      -83.127,
      4.586,
      36.36,
      -83.127,
      4.586,
      38.82,
      -83.127,
      4.586,
      38.82,
      -83.127,
      3.386,
      38.82,
      -83.127,
      3.386,
      36.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2814.0",
    polygon: [
      -74.981,
      -31.209,
      0.05,
      -74.981,
      -28.202,
      0.05,
      -74.981,
      -28.202,
      2.78,
      -74.981,
      -28.202,
      2.78,
      -74.981,
      -31.209,
      2.78,
      -74.981,
      -31.209,
      0.05,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2863.0",
    polygon: [
      -74.886,
      14.249,
      8.36,
      -74.886,
      15.399,
      8.36,
      -74.886,
      15.399,
      10.82,
      -74.886,
      15.399,
      10.82,
      -74.886,
      14.249,
      10.82,
      -74.886,
      14.249,
      8.36,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "3051.0",
    polygon: [
      -74.947,
      23.598,
      4.86,
      -74.947,
      24.498,
      4.86,
      -74.947,
      24.498,
      7.32,
      -74.947,
      24.498,
      7.32,
      -74.947,
      23.598,
      7.32,
      -74.947,
      23.598,
      4.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2902.0",
    polygon: [
      -74.826,
      7.498,
      25.86,
      -74.826,
      8.648,
      25.86,
      -74.826,
      8.648,
      28.32,
      -74.826,
      8.648,
      28.32,
      -74.826,
      7.498,
      28.32,
      -74.826,
      7.498,
      25.86,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "1763.0",
    polygon: [
      -29.056,
      -6.955,
      36.36,
      -30.256,
      -6.955,
      36.36,
      -30.256,
      -6.955,
      39.128,
      -30.256,
      -6.955,
      39.128,
      -29.056,
      -6.955,
      39.128,
      -29.056,
      -6.955,
      36.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1959.0",
    polygon: [
      -77.382,
      -7.156,
      22.36,
      -78.582,
      -7.156,
      22.36,
      -78.582,
      -7.156,
      24.82,
      -78.582,
      -7.156,
      24.82,
      -77.382,
      -7.156,
      24.82,
      -77.382,
      -7.156,
      22.36,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2763.0",
    polygon: [
      -74.981,
      -19.874,
      4.86,
      -74.981,
      -19.054,
      4.86,
      -74.981,
      -19.054,
      7.32,
      -74.981,
      -19.054,
      7.32,
      -74.981,
      -19.874,
      7.32,
      -74.981,
      -19.874,
      4.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1844.0",
    polygon: [
      -55.247,
      -7.155,
      32.86,
      -56.447,
      -7.155,
      32.86,
      -56.447,
      -7.155,
      35.32,
      -56.447,
      -7.155,
      35.32,
      -55.247,
      -7.155,
      35.32,
      -55.247,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1804.0",
    polygon: [
      -36.676,
      -7.155,
      32.86,
      -37.498,
      -7.155,
      32.86,
      -37.498,
      -7.155,
      35.32,
      -37.498,
      -7.155,
      35.32,
      -36.676,
      -7.155,
      35.32,
      -36.676,
      -7.155,
      32.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1883.0",
    polygon: [
      -14.182,
      -7.155,
      25.86,
      -15.382,
      -7.155,
      25.86,
      -15.382,
      -7.155,
      28.32,
      -15.382,
      -7.155,
      28.32,
      -14.182,
      -7.155,
      28.32,
      -14.182,
      -7.155,
      25.86,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1765.0",
    polygon: [
      -45.782,
      -7.155,
      36.36,
      -46.982,
      -7.155,
      36.36,
      -46.982,
      -7.155,
      39.128,
      -46.982,
      -7.155,
      39.128,
      -45.782,
      -7.155,
      39.128,
      -45.782,
      -7.155,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2816.0",
    polygon: [
      -74.961,
      -12.185,
      -0.35,
      -74.961,
      -9.865,
      -0.35,
      -74.961,
      -9.865,
      2.78,
      -74.961,
      -9.865,
      2.78,
      -74.961,
      -12.185,
      2.78,
      -74.961,
      -12.185,
      -0.35,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2187.0",
    polygon: [
      25.136,
      -7.154,
      3.82,
      23.936,
      -7.154,
      3.82,
      23.936,
      -7.154,
      4.86,
      23.936,
      -7.154,
      4.86,
      25.136,
      -7.154,
      4.86,
      25.136,
      -7.154,
      3.82,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2494.0",
    polygon: [
      -12.294,
      -32.365,
      18.45,
      -12.294,
      -33.365,
      18.45,
      -16.267,
      -33.365,
      18.241,
      -16.267,
      -33.365,
      18.241,
      -16.267,
      -32.365,
      18.241,
      -12.294,
      -32.365,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2135.0",
    polygon: [
      -31.769,
      -16.256,
      25.86,
      -32.969,
      -16.256,
      25.86,
      -32.969,
      -16.256,
      28.32,
      -32.969,
      -16.256,
      28.32,
      -31.769,
      -16.256,
      28.32,
      -31.769,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2611.0",
    polygon: [
      -48.028,
      -10.47,
      18.233,
      -48.028,
      -11.47,
      18.233,
      -49.236,
      -11.47,
      18.17,
      -49.236,
      -11.47,
      18.17,
      -49.236,
      -10.47,
      18.17,
      -48.028,
      -10.47,
      18.233,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2377.0",
    polygon: [
      -25.546,
      -19.537,
      18.233,
      -25.546,
      -18.537,
      18.233,
      -21.573,
      -18.537,
      18.025,
      -21.573,
      -18.537,
      18.025,
      -21.573,
      -19.537,
      18.025,
      -25.546,
      -19.537,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2050.0",
    polygon: [
      31.897,
      -7.154,
      22.36,
      30.697,
      -7.154,
      22.36,
      30.697,
      -7.154,
      24.82,
      30.697,
      -7.154,
      24.82,
      31.897,
      -7.154,
      24.82,
      31.897,
      -7.154,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2455.0",
    polygon: [
      -19.188,
      -8.165,
      18.088,
      -19.188,
      -9.165,
      18.088,
      -20.396,
      -9.165,
      18.025,
      -20.396,
      -9.165,
      18.025,
      -20.396,
      -8.165,
      18.025,
      -19.188,
      -8.165,
      18.088,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2650.0",
    polygon: [
      -49.406,
      -26.603,
      18.161,
      -49.406,
      -27.603,
      18.161,
      -50.614,
      -27.603,
      18.098,
      -50.614,
      -27.603,
      18.098,
      -50.614,
      -26.603,
      18.098,
      -49.406,
      -26.603,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2089.0",
    polygon: [
      33.249,
      -7.154,
      11.86,
      32.049,
      -7.154,
      11.86,
      32.049,
      -7.154,
      14.32,
      32.049,
      -7.154,
      14.32,
      33.249,
      -7.154,
      14.32,
      33.249,
      -7.154,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2247.0",
    polygon: [
      -71.982,
      -16.256,
      25.86,
      -73.182,
      -16.256,
      25.86,
      -73.182,
      -16.256,
      28.32,
      -73.182,
      -16.256,
      28.32,
      -71.982,
      -16.256,
      28.32,
      -71.982,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2326.0",
    polygon: [
      -11.63,
      -11.952,
      32.86,
      -11.63,
      -10.802,
      32.86,
      -11.63,
      -10.802,
      35.32,
      -11.63,
      -10.802,
      35.32,
      -11.63,
      -11.952,
      35.32,
      -11.63,
      -11.952,
      32.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2000.0",
    polygon: [
      34.601,
      -7.156,
      36.36,
      33.401,
      -7.156,
      36.36,
      33.401,
      -7.156,
      39.128,
      33.401,
      -7.156,
      39.128,
      34.601,
      -7.156,
      39.128,
      34.601,
      -7.156,
      36.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1922.0",
    polygon: [
      -51.19,
      -7.155,
      22.36,
      -52.39,
      -7.155,
      22.36,
      -52.39,
      -7.155,
      24.82,
      -52.39,
      -7.155,
      24.82,
      -51.19,
      -7.155,
      24.82,
      -51.19,
      -7.155,
      22.36,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1805.0",
    polygon: [
      -35.853,
      -7.155,
      32.86,
      -36.676,
      -7.155,
      32.86,
      -36.676,
      -7.155,
      35.32,
      -36.676,
      -7.155,
      35.32,
      -35.853,
      -7.155,
      35.32,
      -35.853,
      -7.155,
      32.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2730.0",
    polygon: [
      -74.884,
      -29.702,
      8.36,
      -74.884,
      -28.552,
      8.36,
      -74.884,
      -28.552,
      10.82,
      -74.884,
      -28.552,
      10.82,
      -74.884,
      -29.702,
      10.82,
      -74.884,
      -29.702,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2001.0",
    polygon: [
      33.249,
      -7.156,
      39.128,
      32.049,
      -7.156,
      39.128,
      32.049,
      -7.156,
      40.05,
      32.049,
      -7.156,
      40.05,
      33.249,
      -7.156,
      40.05,
      33.249,
      -7.156,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1961.0",
    polygon: [
      -80.086,
      -7.156,
      22.36,
      -81.286,
      -7.156,
      22.36,
      -81.286,
      -7.156,
      24.82,
      -81.286,
      -7.156,
      24.82,
      -80.086,
      -7.156,
      24.82,
      -80.086,
      -7.156,
      22.36,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2287.0",
    polygon: [
      -61.716,
      -9.502,
      22.36,
      -61.716,
      -10.752,
      22.36,
      -61.716,
      -10.752,
      24.82,
      -61.716,
      -10.752,
      24.82,
      -61.716,
      -9.502,
      24.82,
      -61.716,
      -9.502,
      22.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1923.0",
    polygon: [
      -52.543,
      -7.155,
      22.36,
      -53.743,
      -7.155,
      22.36,
      -53.743,
      -7.155,
      24.82,
      -53.743,
      -7.155,
      24.82,
      -52.543,
      -7.155,
      24.82,
      -52.543,
      -7.155,
      22.36,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2961.0",
    polygon: [
      -83.118,
      -4.012,
      15.36,
      -83.118,
      -2.812,
      15.36,
      -83.118,
      -2.812,
      17.82,
      -83.118,
      -2.812,
      17.82,
      -83.118,
      -4.012,
      17.82,
      -83.118,
      -4.012,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1962.0",
    polygon: [
      -81.438,
      -7.156,
      22.36,
      -82.638,
      -7.156,
      22.36,
      -82.638,
      -7.156,
      24.82,
      -82.638,
      -7.156,
      24.82,
      -81.438,
      -7.156,
      24.82,
      -81.438,
      -7.156,
      22.36,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2416.0",
    polygon: [
      -24.165,
      -32.213,
      18.161,
      -24.165,
      -31.213,
      18.161,
      -22.957,
      -31.213,
      18.098,
      -22.957,
      -31.213,
      18.098,
      -22.957,
      -32.213,
      18.098,
      -24.165,
      -32.213,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2771.0",
    polygon: [
      -74.981,
      -33.405,
      4.86,
      -74.981,
      -32.585,
      4.86,
      -74.981,
      -32.585,
      7.32,
      -74.981,
      -32.585,
      7.32,
      -74.981,
      -33.405,
      7.32,
      -74.981,
      -33.405,
      4.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "2572.0",
    polygon: [
      -61.277,
      -29.908,
      18.45,
      -61.277,
      -28.908,
      18.45,
      -57.304,
      -28.908,
      18.241,
      -57.304,
      -28.908,
      18.241,
      -57.304,
      -29.908,
      18.241,
      -61.277,
      -29.908,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2148.0",
    polygon: [
      -41.744,
      -15.241,
      22.36,
      -42.944,
      -15.241,
      22.36,
      -42.944,
      -15.241,
      24.82,
      -42.944,
      -15.241,
      24.82,
      -41.744,
      -15.241,
      24.82,
      -41.744,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2691.0",
    polygon: [
      -74.981,
      -12.185,
      4.86,
      -74.981,
      -10.685,
      4.86,
      -74.981,
      -10.685,
      7.32,
      -74.981,
      -10.685,
      7.32,
      -74.981,
      -12.185,
      7.32,
      -74.981,
      -12.185,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2533.0",
    polygon: [
      -57.148,
      -10.317,
      18.233,
      -57.148,
      -9.317,
      18.233,
      -53.175,
      -9.317,
      18.025,
      -53.175,
      -9.317,
      18.025,
      -53.175,
      -10.317,
      18.025,
      -57.148,
      -10.317,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1884.0",
    polygon: [
      -15.534,
      -7.155,
      25.86,
      -16.734,
      -7.155,
      25.86,
      -16.734,
      -7.155,
      28.32,
      -16.734,
      -7.155,
      28.32,
      -15.534,
      -7.155,
      28.32,
      -15.534,
      -7.155,
      25.86,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1766.0",
    polygon: [
      -47.134,
      -7.155,
      39.128,
      -48.334,
      -7.155,
      39.128,
      -48.334,
      -7.155,
      40.05,
      -48.334,
      -7.155,
      40.05,
      -47.134,
      -7.155,
      40.05,
      -47.134,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2820.0",
    polygon: [
      -74.961,
      -14.505,
      -0.35,
      -74.961,
      -12.185,
      -0.35,
      -74.961,
      -12.185,
      2.78,
      -74.961,
      -12.185,
      2.78,
      -74.961,
      -14.505,
      2.78,
      -74.961,
      -14.505,
      -0.35,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2905.0",
    polygon: [
      -74.826,
      7.498,
      29.36,
      -74.826,
      8.648,
      29.36,
      -74.826,
      8.648,
      31.82,
      -74.826,
      8.648,
      31.82,
      -74.826,
      7.498,
      31.82,
      -74.826,
      7.498,
      29.36,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "2866.0",
    polygon: [
      -74.886,
      20.799,
      8.36,
      -74.886,
      21.949,
      8.36,
      -74.886,
      21.949,
      10.82,
      -74.886,
      21.949,
      10.82,
      -74.886,
      20.799,
      10.82,
      -74.886,
      20.799,
      8.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "3055.0",
    polygon: [
      -74.947,
      14.441,
      4.86,
      -74.947,
      15.341,
      4.86,
      -74.947,
      15.341,
      7.32,
      -74.947,
      15.341,
      7.32,
      -74.947,
      14.441,
      7.32,
      -74.947,
      14.441,
      4.86,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2495.0",
    polygon: [
      -16.423,
      -32.365,
      18.233,
      -16.423,
      -33.365,
      18.233,
      -20.396,
      -33.365,
      18.025,
      -20.396,
      -33.365,
      18.025,
      -20.396,
      -32.365,
      18.025,
      -16.423,
      -32.365,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1845.0",
    polygon: [
      -56.599,
      -7.155,
      32.86,
      -57.799,
      -7.155,
      32.86,
      -57.799,
      -7.155,
      35.32,
      -57.799,
      -7.155,
      35.32,
      -56.599,
      -7.155,
      35.32,
      -56.599,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2288.0",
    polygon: [
      -61.716,
      -10.752,
      22.36,
      -61.716,
      -12.002,
      22.36,
      -61.716,
      -12.002,
      24.82,
      -61.716,
      -12.002,
      24.82,
      -61.716,
      -10.752,
      24.82,
      -61.716,
      -10.752,
      22.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2417.0",
    polygon: [
      -22.781,
      -32.213,
      18.088,
      -22.781,
      -31.213,
      18.088,
      -21.573,
      -31.213,
      18.025,
      -21.573,
      -31.213,
      18.025,
      -21.573,
      -32.213,
      18.025,
      -22.781,
      -32.213,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2149.0",
    polygon: [
      -30.408,
      -15.241,
      22.36,
      -31.608,
      -15.241,
      22.36,
      -31.608,
      -15.241,
      24.82,
      -31.608,
      -15.241,
      24.82,
      -30.408,
      -15.241,
      24.82,
      -30.408,
      -15.241,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2612.0",
    polygon: [
      -49.406,
      -10.47,
      18.161,
      -49.406,
      -11.47,
      18.161,
      -50.614,
      -11.47,
      18.098,
      -50.614,
      -11.47,
      18.098,
      -50.614,
      -10.47,
      18.098,
      -49.406,
      -10.47,
      18.161,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2248.0",
    polygon: [
      -70.621,
      -16.256,
      25.86,
      -71.821,
      -16.256,
      25.86,
      -71.821,
      -16.256,
      28.32,
      -71.821,
      -16.256,
      28.32,
      -70.621,
      -16.256,
      28.32,
      -70.621,
      -16.256,
      25.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2327.0",
    polygon: [
      -11.746,
      -7.925,
      36.36,
      -11.746,
      -7.368,
      36.36,
      -11.746,
      -7.368,
      38.82,
      -11.746,
      -7.368,
      38.82,
      -11.746,
      -7.925,
      38.82,
      -11.746,
      -7.925,
      36.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2051.0",
    polygon: [
      30.545,
      -7.154,
      22.36,
      29.345,
      -7.154,
      22.36,
      29.345,
      -7.154,
      24.82,
      29.345,
      -7.154,
      24.82,
      30.545,
      -7.154,
      24.82,
      30.545,
      -7.154,
      22.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2090.0",
    polygon: [
      31.897,
      -7.154,
      11.86,
      30.697,
      -7.154,
      11.86,
      30.697,
      -7.154,
      14.32,
      30.697,
      -7.154,
      14.32,
      31.897,
      -7.154,
      14.32,
      31.897,
      -7.154,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "2188.0",
    polygon: [
      26.488,
      -7.154,
      3.82,
      25.288,
      -7.154,
      3.82,
      25.288,
      -7.154,
      4.86,
      25.288,
      -7.154,
      4.86,
      26.488,
      -7.154,
      4.86,
      26.488,
      -7.154,
      3.82,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "3007.0",
    polygon: [
      -82.012,
      0.148,
      36.36,
      -83.112,
      0.148,
      36.36,
      -83.112,
      0.148,
      38.82,
      -83.112,
      0.148,
      38.82,
      -82.012,
      0.148,
      38.82,
      -82.012,
      0.148,
      36.36,
    ],
    "04-15 12:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "2731.0",
    polygon: [
      -74.884,
      -30.852,
      8.36,
      -74.884,
      -29.702,
      8.36,
      -74.884,
      -29.702,
      10.82,
      -74.884,
      -29.702,
      10.82,
      -74.884,
      -30.852,
      10.82,
      -74.884,
      -30.852,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2534.0",
    polygon: [
      -61.277,
      -9.165,
      18.45,
      -61.277,
      -8.165,
      18.45,
      -57.304,
      -8.165,
      18.241,
      -57.304,
      -8.165,
      18.241,
      -57.304,
      -9.165,
      18.241,
      -61.277,
      -9.165,
      18.45,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "2378.0",
    polygon: [
      -29.675,
      -18.384,
      18.45,
      -29.675,
      -17.384,
      18.45,
      -25.702,
      -17.384,
      18.241,
      -25.702,
      -17.384,
      18.241,
      -25.702,
      -18.384,
      18.241,
      -29.675,
      -18.384,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2651.0",
    polygon: [
      -50.79,
      -26.603,
      18.088,
      -50.79,
      -27.603,
      18.088,
      -51.998,
      -27.603,
      18.025,
      -51.998,
      -27.603,
      18.025,
      -51.998,
      -26.603,
      18.025,
      -50.79,
      -26.603,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "2772.0",
    polygon: [
      -74.981,
      -34.964,
      4.86,
      -74.981,
      -34.144,
      4.86,
      -74.981,
      -34.144,
      7.32,
      -74.981,
      -34.144,
      7.32,
      -74.981,
      -34.964,
      7.32,
      -74.981,
      -34.964,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2692.0",
    polygon: [
      -74.887,
      -10.802,
      8.36,
      -74.887,
      -9.652,
      8.36,
      -74.887,
      -9.652,
      10.82,
      -74.887,
      -9.652,
      10.82,
      -74.887,
      -10.802,
      10.82,
      -74.887,
      -10.802,
      8.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2573.0",
    polygon: [
      -57.145,
      -29.908,
      18.233,
      -57.145,
      -28.908,
      18.233,
      -55.936,
      -28.908,
      18.17,
      -55.936,
      -28.908,
      18.17,
      -55.936,
      -29.908,
      18.17,
      -57.145,
      -29.908,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "2962.0",
    polygon: [
      -83.118,
      -2.66,
      15.36,
      -83.118,
      -1.46,
      15.36,
      -83.118,
      -1.46,
      17.82,
      -83.118,
      -1.46,
      17.82,
      -83.118,
      -2.66,
      17.82,
      -83.118,
      -2.66,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "2906.0",
    polygon: [
      -83.127,
      -6.717,
      36.36,
      -83.127,
      -5.517,
      36.36,
      -83.127,
      -5.517,
      39.128,
      -83.127,
      -5.517,
      39.128,
      -83.127,
      -6.717,
      39.128,
      -83.127,
      -6.717,
      36.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3059.0",
    polygon: [
      -74.947,
      18.198,
      4.86,
      -74.947,
      19.098,
      4.86,
      -74.947,
      19.098,
      7.32,
      -74.947,
      19.098,
      7.32,
      -74.947,
      18.198,
      7.32,
      -74.947,
      18.198,
      4.86,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "3338.0",
    polygon: [
      -61.277,
      19.973,
      18.45,
      -61.277,
      20.973,
      18.45,
      -57.304,
      20.973,
      18.241,
      -57.304,
      20.973,
      18.241,
      -57.304,
      19.973,
      18.241,
      -61.277,
      19.973,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3548.0",
    polygon: [
      -24.267,
      28.04,
      18.161,
      -24.267,
      29.04,
      18.161,
      -23.058,
      29.04,
      18.098,
      -23.058,
      29.04,
      18.098,
      -23.058,
      28.04,
      18.098,
      -24.267,
      28.04,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "3869.0",
    polygon: [
      6.743,
      6.378,
      32.86,
      7.943,
      6.378,
      32.86,
      7.943,
      6.378,
      35.32,
      7.943,
      6.378,
      35.32,
      6.743,
      6.378,
      35.32,
      6.743,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3752.0",
    polygon: [
      14.856,
      6.378,
      18.86,
      16.056,
      6.378,
      18.86,
      16.056,
      6.378,
      21.32,
      16.056,
      6.378,
      21.32,
      14.856,
      6.378,
      21.32,
      14.856,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "2867.0",
    polygon: [
      -74.886,
      19.649,
      8.36,
      -74.886,
      20.799,
      8.36,
      -74.886,
      20.799,
      10.82,
      -74.886,
      20.799,
      10.82,
      -74.886,
      19.649,
      10.82,
      -74.886,
      19.649,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "3299.0",
    polygon: [
      1.473,
      8.648,
      18.86,
      1.473,
      7.498,
      18.86,
      1.473,
      7.498,
      21.32,
      1.473,
      7.498,
      21.32,
      1.473,
      8.648,
      21.32,
      1.473,
      8.648,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "3992.0",
    polygon: [
      -74.53,
      38.499,
      4.86,
      -73.362,
      38.499,
      4.86,
      -73.362,
      38.499,
      7.32,
      -73.362,
      38.499,
      7.32,
      -74.53,
      38.499,
      7.32,
      -74.53,
      38.499,
      4.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4031.0",
    polygon: [
      -4.07,
      38.498,
      11.86,
      -2.903,
      38.498,
      11.86,
      -2.903,
      38.498,
      14.32,
      -2.903,
      38.498,
      14.32,
      -4.07,
      38.498,
      14.32,
      -4.07,
      38.498,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3953.0",
    polygon: [
      -57.965,
      38.497,
      15.36,
      -56.797,
      38.497,
      15.36,
      -56.797,
      38.497,
      17.82,
      -56.797,
      38.497,
      17.82,
      -57.965,
      38.497,
      17.82,
      -57.965,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3911.0",
    polygon: [
      -10.204,
      38.498,
      15.36,
      -9.036,
      38.498,
      15.36,
      -9.036,
      38.498,
      17.82,
      -9.036,
      38.498,
      17.82,
      -10.204,
      38.498,
      17.82,
      -10.204,
      38.498,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "2821.0",
    polygon: [
      -74.961,
      -19.874,
      2.78,
      -74.961,
      -17.554,
      2.78,
      -74.961,
      -17.554,
      3.473,
      -74.961,
      -17.554,
      3.473,
      -74.961,
      -19.874,
      3.473,
      -74.961,
      -19.874,
      2.78,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "3261.0",
    polygon: [
      -43.226,
      9.998,
      22.36,
      -43.226,
      11.148,
      22.36,
      -43.226,
      11.148,
      24.82,
      -43.226,
      11.148,
      24.82,
      -43.226,
      9.998,
      24.82,
      -43.226,
      9.998,
      22.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4470.0",
    polygon: [
      22.932,
      -7.451,
      36.36,
      22.932,
      -8.451,
      36.36,
      22.932,
      -8.451,
      39.127,
      22.932,
      -8.451,
      39.127,
      22.932,
      -7.451,
      39.127,
      22.932,
      -7.451,
      36.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4430.0",
    polygon: [
      31.938,
      -0.105,
      8.36,
      31.938,
      -0.705,
      8.36,
      31.938,
      -0.705,
      10.82,
      31.938,
      -0.705,
      10.82,
      31.938,
      -0.105,
      10.82,
      31.938,
      -0.105,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "3912.0",
    polygon: [
      -11.37,
      38.498,
      15.36,
      -10.203,
      38.498,
      15.36,
      -10.203,
      38.498,
      17.82,
      -10.203,
      38.498,
      17.82,
      -11.37,
      38.498,
      17.82,
      -11.37,
      38.498,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3753.0",
    polygon: [
      18.912,
      6.378,
      18.86,
      20.112,
      6.378,
      18.86,
      20.112,
      6.378,
      21.32,
      20.112,
      6.378,
      21.32,
      18.912,
      6.378,
      21.32,
      18.912,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3870.0",
    polygon: [
      5.391,
      6.378,
      32.86,
      6.591,
      6.378,
      32.86,
      6.591,
      6.378,
      35.32,
      6.591,
      6.378,
      35.32,
      5.391,
      6.378,
      35.32,
      5.391,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3549.0",
    polygon: [
      -22.883,
      28.04,
      18.088,
      -22.883,
      29.04,
      18.088,
      -21.675,
      29.04,
      18.025,
      -21.675,
      29.04,
      18.025,
      -21.675,
      28.04,
      18.025,
      -22.883,
      28.04,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "4159.0",
    polygon: [
      26.741,
      12.368,
      8.36,
      27.38,
      11.317,
      8.36,
      27.38,
      11.317,
      10.82,
      27.38,
      11.317,
      10.82,
      26.741,
      12.368,
      10.82,
      26.741,
      12.368,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4254.0",
    polygon: [
      7.373,
      30.39,
      1.36,
      8.355,
      29.857,
      1.36,
      8.355,
      29.857,
      3.82,
      8.355,
      29.857,
      3.82,
      7.373,
      30.39,
      3.82,
      7.373,
      30.39,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
  },
  {
    ID: "4119.0",
    polygon: [
      21.592,
      19.241,
      15.36,
      22.399,
      18.313,
      15.36,
      22.399,
      18.313,
      17.82,
      22.399,
      18.313,
      17.82,
      21.592,
      19.241,
      17.82,
      21.592,
      19.241,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "4157.0",
    polygon: [
      25.151,
      14.76,
      8.36,
      25.848,
      13.747,
      8.36,
      25.848,
      13.747,
      10.82,
      25.848,
      13.747,
      10.82,
      25.151,
      14.76,
      10.82,
      25.151,
      14.76,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "4471.0",
    polygon: [
      22.932,
      -7.451,
      39.128,
      22.932,
      -8.451,
      39.128,
      22.932,
      -8.451,
      40.05,
      22.932,
      -8.451,
      40.05,
      22.932,
      -7.451,
      40.05,
      22.932,
      -7.451,
      39.128,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4196.0",
    polygon: [
      28.952,
      8.454,
      11.86,
      29.496,
      7.351,
      11.86,
      29.496,
      7.351,
      14.32,
      29.496,
      7.351,
      14.32,
      28.952,
      8.454,
      14.32,
      28.952,
      8.454,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "4197.0",
    polygon: [
      29.475,
      7.395,
      11.86,
      29.993,
      6.28,
      11.86,
      29.993,
      6.28,
      14.32,
      29.993,
      6.28,
      14.32,
      29.475,
      7.395,
      14.32,
      29.475,
      7.395,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4431.0",
    polygon: [
      31.938,
      -0.105,
      11.86,
      31.938,
      -0.705,
      11.86,
      31.938,
      -0.705,
      14.32,
      31.938,
      -0.705,
      14.32,
      31.938,
      -0.105,
      14.32,
      31.938,
      -0.105,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "3913.0",
    polygon: [
      -12.904,
      38.497,
      15.36,
      -11.736,
      38.497,
      15.36,
      -11.736,
      38.497,
      17.82,
      -11.736,
      38.497,
      17.82,
      -12.904,
      38.497,
      17.82,
      -12.904,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4118.0",
    polygon: [
      20.785,
      20.127,
      15.36,
      21.613,
      19.217,
      15.36,
      21.613,
      19.217,
      17.82,
      21.613,
      19.217,
      17.82,
      20.785,
      20.127,
      17.82,
      20.785,
      20.127,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4311.0",
    polygon: [
      30.257,
      5.743,
      25.86,
      30.735,
      4.61,
      25.86,
      30.735,
      4.61,
      28.32,
      30.735,
      4.61,
      28.32,
      30.257,
      5.743,
      28.32,
      30.257,
      5.743,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "4158.0",
    polygon: [
      26.099,
      13.369,
      8.36,
      26.763,
      12.333,
      8.36,
      26.763,
      12.333,
      10.82,
      26.763,
      12.333,
      10.82,
      26.099,
      13.369,
      10.82,
      26.099,
      13.369,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "3300.0",
    polygon: [
      1.473,
      11.148,
      22.36,
      1.473,
      9.998,
      22.36,
      1.473,
      9.998,
      24.82,
      1.473,
      9.998,
      24.82,
      1.473,
      11.148,
      24.82,
      1.473,
      11.148,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "4120.0",
    polygon: [
      22.683,
      17.975,
      15.36,
      23.458,
      17.02,
      15.36,
      23.458,
      17.02,
      17.82,
      23.458,
      17.02,
      17.82,
      22.683,
      17.975,
      17.82,
      22.683,
      17.975,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "3184.0",
    polygon: [
      -11.237,
      14.657,
      25.86,
      -11.237,
      15.437,
      25.86,
      -11.237,
      15.437,
      28.32,
      -11.237,
      15.437,
      28.32,
      -11.237,
      14.657,
      28.32,
      -11.237,
      14.657,
      25.86,
    ],
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4253.0",
    polygon: [
      5.611,
      31.279,
      1.36,
      6.612,
      30.784,
      1.36,
      6.612,
      30.784,
      3.82,
      6.612,
      30.784,
      3.82,
      5.611,
      31.279,
      3.82,
      5.611,
      31.279,
      1.36,
    ],
    "04-15 05:15": 1,
  },
  {
    ID: "3994.0",
    polygon: [
      -71.83,
      38.499,
      4.86,
      -70.662,
      38.499,
      4.86,
      -70.662,
      38.499,
      7.32,
      -70.662,
      38.499,
      7.32,
      -71.83,
      38.499,
      7.32,
      -71.83,
      38.499,
      4.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4472.0",
    polygon: [
      22.928,
      -7.448,
      32.86,
      22.928,
      -8.448,
      32.86,
      22.928,
      -8.448,
      35.32,
      22.928,
      -8.448,
      35.32,
      22.928,
      -7.448,
      35.32,
      22.928,
      -7.448,
      32.86,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4432.0",
    polygon: [
      31.938,
      -0.105,
      15.36,
      31.938,
      -0.705,
      15.36,
      31.938,
      -0.705,
      17.82,
      31.938,
      -0.705,
      17.82,
      31.938,
      -0.105,
      17.82,
      31.938,
      -0.105,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "3339.0",
    polygon: [
      -57.148,
      19.973,
      18.233,
      -57.148,
      20.973,
      18.233,
      -53.175,
      20.973,
      18.025,
      -53.175,
      20.973,
      18.025,
      -53.175,
      19.973,
      18.025,
      -57.148,
      19.973,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4312.0",
    polygon: [
      30.713,
      4.665,
      25.86,
      31.165,
      3.522,
      25.86,
      31.165,
      3.522,
      28.32,
      31.165,
      3.522,
      28.32,
      30.713,
      4.665,
      28.32,
      30.713,
      4.665,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3955.0",
    polygon: [
      -14.07,
      38.497,
      11.86,
      -12.903,
      38.497,
      11.86,
      -12.903,
      38.497,
      14.32,
      -12.903,
      38.497,
      14.32,
      -14.07,
      38.497,
      14.32,
      -14.07,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4255.0",
    polygon: [
      8.476,
      29.789,
      1.36,
      9.444,
      29.232,
      1.36,
      9.444,
      29.232,
      3.82,
      9.444,
      29.232,
      3.82,
      8.476,
      29.789,
      3.82,
      8.476,
      29.789,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
  },
  {
    ID: "4310.0",
    polygon: [
      31.695,
      2.067,
      22.36,
      32.084,
      0.9,
      22.36,
      32.084,
      0.9,
      24.82,
      32.084,
      0.9,
      24.82,
      31.695,
      2.067,
      24.82,
      31.695,
      2.067,
      22.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "4198.0",
    polygon: [
      30.235,
      5.734,
      11.86,
      30.713,
      4.6,
      11.86,
      30.713,
      4.6,
      14.32,
      30.713,
      4.6,
      14.32,
      30.235,
      5.734,
      14.32,
      30.235,
      5.734,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "3588.0",
    polygon: [
      -12.395,
      23.278,
      18.45,
      -12.395,
      22.278,
      18.45,
      -16.369,
      22.278,
      18.241,
      -16.369,
      22.278,
      18.241,
      -16.369,
      23.278,
      18.241,
      -12.395,
      23.278,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3185.0",
    polygon: [
      1.099,
      15.425,
      25.86,
      1.099,
      14.645,
      25.86,
      1.099,
      14.645,
      28.32,
      1.099,
      14.645,
      28.32,
      1.099,
      15.425,
      28.32,
      1.099,
      15.425,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "3186.0",
    polygon: [
      -69.098,
      14.448,
      22.36,
      -68.276,
      14.448,
      22.36,
      -68.276,
      14.448,
      24.82,
      -68.276,
      14.448,
      24.82,
      -69.098,
      14.448,
      24.82,
      -69.098,
      14.448,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "3754.0",
    polygon: [
      20.265,
      6.378,
      18.86,
      21.465,
      6.378,
      18.86,
      21.465,
      6.378,
      21.32,
      21.465,
      6.378,
      21.32,
      20.265,
      6.378,
      21.32,
      20.265,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3871.0",
    polygon: [
      4.039,
      6.378,
      32.86,
      5.239,
      6.378,
      32.86,
      5.239,
      6.378,
      35.32,
      5.239,
      6.378,
      35.32,
      4.039,
      6.378,
      35.32,
      4.039,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3589.0",
    polygon: [
      -16.524,
      23.278,
      18.233,
      -16.524,
      22.278,
      18.233,
      -20.498,
      22.278,
      18.025,
      -20.498,
      22.278,
      18.025,
      -20.498,
      23.278,
      18.025,
      -16.524,
      23.278,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3550.0",
    polygon: [
      -12.395,
      16.364,
      18.45,
      -12.395,
      15.364,
      18.45,
      -16.369,
      15.364,
      18.241,
      -16.369,
      15.364,
      18.241,
      -16.369,
      16.364,
      18.241,
      -12.395,
      16.364,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3438.0",
    polygon: [
      -43.895,
      27.888,
      18.45,
      -43.895,
      26.888,
      18.45,
      -47.869,
      26.888,
      18.241,
      -47.869,
      26.888,
      18.241,
      -47.869,
      27.888,
      18.241,
      -43.895,
      27.888,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3340.0",
    polygon: [
      -61.277,
      18.821,
      18.45,
      -61.277,
      19.821,
      18.45,
      -57.304,
      19.821,
      18.241,
      -57.304,
      19.821,
      18.241,
      -57.304,
      18.821,
      18.241,
      -61.277,
      18.821,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3301.0",
    polygon: [
      1.473,
      9.999,
      22.36,
      1.473,
      8.648,
      22.36,
      1.473,
      8.648,
      24.82,
      1.473,
      8.648,
      24.82,
      1.473,
      9.999,
      24.82,
      1.473,
      9.999,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4433.0",
    polygon: [
      32.018,
      -0.105,
      18.86,
      32.018,
      -0.705,
      18.86,
      32.018,
      -0.705,
      21.32,
      32.018,
      -0.705,
      21.32,
      32.018,
      -0.105,
      21.32,
      32.018,
      -0.105,
      18.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4473.0",
    polygon: [
      22.828,
      -7.448,
      8.36,
      22.828,
      -8.448,
      8.36,
      22.828,
      -8.448,
      10.82,
      22.828,
      -8.448,
      10.82,
      22.828,
      -7.448,
      10.82,
      22.828,
      -7.448,
      8.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "3755.0",
    polygon: [
      21.617,
      6.378,
      18.86,
      22.817,
      6.378,
      18.86,
      22.817,
      6.378,
      21.32,
      22.817,
      6.378,
      21.32,
      21.617,
      6.378,
      21.32,
      21.617,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4256.0",
    polygon: [
      9.552,
      29.168,
      1.36,
      10.507,
      28.587,
      1.36,
      10.507,
      28.587,
      3.82,
      10.507,
      28.587,
      3.82,
      9.552,
      29.168,
      3.82,
      9.552,
      29.168,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "4199.0",
    polygon: [
      30.69,
      4.657,
      11.86,
      31.142,
      3.513,
      11.86,
      31.142,
      3.513,
      14.32,
      31.142,
      3.513,
      14.32,
      30.69,
      4.657,
      14.32,
      30.69,
      4.657,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "4160.0",
    polygon: [
      27.598,
      10.945,
      8.36,
      28.203,
      9.873,
      8.36,
      28.203,
      9.873,
      10.82,
      28.203,
      9.873,
      10.82,
      27.598,
      10.945,
      10.82,
      27.598,
      10.945,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4121.0",
    polygon: [
      23.437,
      17.047,
      15.36,
      24.19,
      16.075,
      15.36,
      24.19,
      16.075,
      17.82,
      24.19,
      16.075,
      17.82,
      23.437,
      17.047,
      17.82,
      23.437,
      17.047,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "3302.0",
    polygon: [
      1.473,
      8.648,
      22.36,
      1.473,
      7.498,
      22.36,
      1.473,
      7.498,
      24.82,
      1.473,
      7.498,
      24.82,
      1.473,
      8.648,
      24.82,
      1.473,
      8.648,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "3914.0",
    polygon: [
      -14.07,
      38.497,
      15.36,
      -12.903,
      38.497,
      15.36,
      -12.903,
      38.497,
      17.82,
      -12.903,
      38.497,
      17.82,
      -14.07,
      38.497,
      17.82,
      -14.07,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3551.0",
    polygon: [
      -16.524,
      16.364,
      18.233,
      -16.524,
      15.364,
      18.233,
      -20.498,
      15.364,
      18.025,
      -20.498,
      15.364,
      18.025,
      -20.498,
      16.364,
      18.025,
      -16.524,
      16.364,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3439.0",
    polygon: [
      -48.024,
      27.888,
      18.233,
      -48.024,
      26.888,
      18.233,
      -51.998,
      26.888,
      18.025,
      -51.998,
      26.888,
      18.025,
      -51.998,
      27.888,
      18.025,
      -48.024,
      27.888,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4313.0",
    polygon: [
      31.296,
      3.173,
      25.86,
      31.712,
      2.016,
      25.86,
      31.712,
      2.016,
      28.32,
      31.712,
      2.016,
      28.32,
      31.296,
      3.173,
      28.32,
      31.296,
      3.173,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4034.0",
    polygon: [
      -8.67,
      38.498,
      11.86,
      -7.503,
      38.498,
      11.86,
      -7.503,
      38.498,
      14.32,
      -7.503,
      38.498,
      14.32,
      -8.67,
      38.498,
      14.32,
      -8.67,
      38.498,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3872.0",
    polygon: [
      2.686,
      6.378,
      32.86,
      3.886,
      6.378,
      32.86,
      3.886,
      6.378,
      35.32,
      3.886,
      6.378,
      35.32,
      2.686,
      6.378,
      35.32,
      2.686,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3590.0",
    polygon: [
      -12.395,
      22.126,
      18.45,
      -12.395,
      21.126,
      18.45,
      -16.369,
      21.126,
      18.241,
      -16.369,
      21.126,
      18.241,
      -16.369,
      22.126,
      18.241,
      -12.395,
      22.126,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4082.0",
    polygon: [
      -74.927,
      31.679,
      1.36,
      -74.927,
      32.579,
      1.36,
      -74.927,
      32.579,
      3.82,
      -74.927,
      32.579,
      3.82,
      -74.927,
      31.679,
      3.82,
      -74.927,
      31.679,
      1.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "4122.0",
    polygon: [
      24.452,
      15.726,
      15.36,
      25.173,
      14.729,
      15.36,
      25.173,
      14.729,
      17.82,
      25.173,
      14.729,
      17.82,
      24.452,
      15.726,
      17.82,
      24.452,
      15.726,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "4161.0",
    polygon: [
      28.181,
      9.913,
      8.36,
      28.761,
      8.828,
      8.36,
      28.761,
      8.828,
      10.82,
      28.761,
      8.828,
      10.82,
      28.181,
      9.913,
      10.82,
      28.181,
      9.913,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4083.0",
    polygon: [
      -74.927,
      30.327,
      1.36,
      -74.927,
      31.527,
      1.36,
      -74.927,
      31.527,
      3.82,
      -74.927,
      31.527,
      3.82,
      -74.927,
      30.327,
      3.82,
      -74.927,
      30.327,
      1.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "3440.0",
    polygon: [
      -43.895,
      26.735,
      18.45,
      -43.895,
      25.735,
      18.45,
      -47.869,
      25.735,
      18.241,
      -47.869,
      25.735,
      18.241,
      -47.869,
      26.735,
      18.241,
      -43.895,
      26.735,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3303.0",
    polygon: [
      1.473,
      11.148,
      25.86,
      1.473,
      9.998,
      25.86,
      1.473,
      9.998,
      28.32,
      1.473,
      9.998,
      28.32,
      1.473,
      11.148,
      28.32,
      1.473,
      11.148,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "3873.0",
    polygon: [
      8.095,
      6.378,
      32.86,
      9.295,
      6.378,
      32.86,
      9.295,
      6.378,
      35.32,
      9.295,
      6.378,
      35.32,
      8.095,
      6.378,
      35.32,
      8.095,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3552.0",
    polygon: [
      -12.395,
      15.211,
      18.45,
      -12.395,
      14.211,
      18.45,
      -16.369,
      14.211,
      18.241,
      -16.369,
      14.211,
      18.241,
      -16.369,
      15.211,
      18.241,
      -12.395,
      15.211,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4257.0",
    polygon: [
      10.61,
      28.522,
      1.36,
      11.55,
      27.919,
      1.36,
      11.55,
      27.919,
      3.82,
      11.55,
      27.919,
      3.82,
      10.61,
      28.522,
      3.82,
      10.61,
      28.522,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "4314.0",
    polygon: [
      31.695,
      2.067,
      25.86,
      32.084,
      0.9,
      25.86,
      32.084,
      0.9,
      28.32,
      32.084,
      0.9,
      28.32,
      31.695,
      2.067,
      28.32,
      31.695,
      2.067,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "4200.0",
    polygon: [
      31.273,
      3.165,
      11.86,
      31.688,
      2.007,
      11.86,
      31.688,
      2.007,
      14.32,
      31.688,
      2.007,
      14.32,
      31.273,
      3.165,
      14.32,
      31.273,
      3.165,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "3304.0",
    polygon: [
      1.473,
      9.999,
      25.86,
      1.473,
      8.648,
      25.86,
      1.473,
      8.648,
      28.32,
      1.473,
      8.648,
      28.32,
      1.473,
      9.999,
      28.32,
      1.473,
      9.999,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "3187.0",
    polygon: [
      -68.276,
      14.448,
      22.36,
      -67.453,
      14.448,
      22.36,
      -67.453,
      14.448,
      24.82,
      -67.453,
      14.448,
      24.82,
      -68.276,
      14.448,
      24.82,
      -68.276,
      14.448,
      22.36,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3148.0",
    polygon: [
      -11.237,
      14.657,
      29.36,
      -11.237,
      15.437,
      29.36,
      -11.237,
      15.437,
      31.82,
      -11.237,
      15.437,
      31.82,
      -11.237,
      14.657,
      31.82,
      -11.237,
      14.657,
      29.36,
    ],
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3226.0",
    polygon: [
      -5.848,
      14.448,
      22.36,
      -5.026,
      14.448,
      22.36,
      -5.026,
      14.448,
      24.82,
      -5.026,
      14.448,
      24.82,
      -5.848,
      14.448,
      24.82,
      -5.848,
      14.448,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "4474.0",
    polygon: [
      22.828,
      -7.448,
      11.86,
      22.828,
      -8.448,
      11.86,
      22.828,
      -8.448,
      14.32,
      22.828,
      -8.448,
      14.32,
      22.828,
      -7.448,
      14.32,
      22.828,
      -7.448,
      11.86,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "3441.0",
    polygon: [
      -48.028,
      26.735,
      18.233,
      -48.028,
      25.735,
      18.233,
      -49.236,
      25.735,
      18.17,
      -49.236,
      25.735,
      18.17,
      -49.236,
      26.735,
      18.17,
      -48.028,
      26.735,
      18.233,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3514.0",
    polygon: [
      -29.777,
      17.669,
      18.45,
      -29.777,
      18.669,
      18.45,
      -25.804,
      18.669,
      18.241,
      -25.804,
      18.669,
      18.241,
      -25.804,
      17.669,
      18.241,
      -29.777,
      17.669,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4162.0",
    polygon: [
      28.952,
      8.454,
      8.36,
      29.496,
      7.351,
      8.36,
      29.496,
      7.351,
      10.82,
      29.496,
      7.351,
      10.82,
      28.952,
      8.454,
      10.82,
      28.952,
      8.454,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "3919.0",
    polygon: [
      -15.603,
      38.497,
      15.36,
      -14.435,
      38.497,
      15.36,
      -14.435,
      38.497,
      17.82,
      -14.435,
      38.497,
      17.82,
      -15.603,
      38.497,
      17.82,
      -15.603,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4123.0",
    polygon: [
      25.151,
      14.76,
      15.36,
      25.848,
      13.747,
      15.36,
      25.848,
      13.747,
      17.82,
      25.848,
      13.747,
      17.82,
      25.151,
      14.76,
      17.82,
      25.151,
      14.76,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "4258.0",
    polygon: [
      12.289,
      27.423,
      1.36,
      13.204,
      26.783,
      1.36,
      13.204,
      26.783,
      3.82,
      13.204,
      26.783,
      3.82,
      12.289,
      27.423,
      3.82,
      12.289,
      27.423,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4036.0",
    polygon: [
      -11.37,
      38.498,
      11.86,
      -10.203,
      38.498,
      11.86,
      -10.203,
      38.498,
      14.32,
      -10.203,
      38.498,
      14.32,
      -11.37,
      38.498,
      14.32,
      -11.37,
      38.498,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3958.0",
    polygon: [
      -16.769,
      38.497,
      11.86,
      -15.601,
      38.497,
      11.86,
      -15.601,
      38.497,
      14.32,
      -15.601,
      38.497,
      14.32,
      -16.769,
      38.497,
      14.32,
      -16.769,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4435.0",
    polygon: [
      32.018,
      -0.105,
      25.86,
      32.018,
      -0.705,
      25.86,
      32.018,
      -0.705,
      28.32,
      32.018,
      -0.705,
      28.32,
      32.018,
      -0.105,
      28.32,
      32.018,
      -0.105,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "3875.0",
    polygon: [
      10.799,
      6.378,
      32.86,
      11.999,
      6.378,
      32.86,
      11.999,
      6.378,
      35.32,
      11.999,
      6.378,
      35.32,
      10.799,
      6.378,
      35.32,
      10.799,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3515.0",
    polygon: [
      -25.648,
      17.669,
      18.233,
      -25.648,
      18.669,
      18.233,
      -21.675,
      18.669,
      18.025,
      -21.675,
      18.669,
      18.025,
      -21.675,
      17.669,
      18.025,
      -25.648,
      17.669,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3344.0",
    polygon: [
      -61.277,
      22.278,
      18.45,
      -61.277,
      23.278,
      18.45,
      -57.304,
      23.278,
      18.241,
      -57.304,
      23.278,
      18.241,
      -57.304,
      22.278,
      18.241,
      -61.277,
      22.278,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3305.0",
    polygon: [
      1.473,
      8.648,
      25.86,
      1.473,
      7.498,
      25.86,
      1.473,
      7.498,
      28.32,
      1.473,
      7.498,
      28.32,
      1.473,
      8.648,
      28.32,
      1.473,
      8.648,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "3227.0",
    polygon: [
      -5.026,
      14.448,
      22.36,
      -4.203,
      14.448,
      22.36,
      -4.203,
      14.448,
      24.82,
      -4.203,
      14.448,
      24.82,
      -5.026,
      14.448,
      24.82,
      -5.026,
      14.448,
      22.36,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3149.0",
    polygon: [
      1.099,
      15.425,
      29.36,
      1.099,
      14.645,
      29.36,
      1.099,
      14.645,
      31.82,
      1.099,
      14.645,
      31.82,
      1.099,
      15.425,
      31.82,
      1.099,
      15.425,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4315.0",
    polygon: [
      30.257,
      5.743,
      29.36,
      30.735,
      4.61,
      29.36,
      30.735,
      4.61,
      31.82,
      30.735,
      4.61,
      31.82,
      30.257,
      5.743,
      31.82,
      30.257,
      5.743,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "4163.0",
    polygon: [
      29.475,
      7.395,
      8.36,
      29.993,
      6.28,
      8.36,
      29.993,
      6.28,
      10.82,
      29.993,
      6.28,
      10.82,
      29.475,
      7.395,
      10.82,
      29.475,
      7.395,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3920.0",
    polygon: [
      -16.769,
      38.497,
      15.36,
      -15.601,
      38.497,
      15.36,
      -15.601,
      38.497,
      17.82,
      -15.601,
      38.497,
      17.82,
      -16.769,
      38.497,
      17.82,
      -16.769,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4124.0",
    polygon: [
      26.099,
      13.369,
      15.36,
      26.763,
      12.333,
      15.36,
      26.763,
      12.333,
      17.82,
      26.763,
      12.333,
      17.82,
      26.099,
      13.369,
      17.82,
      26.099,
      13.369,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "4437.0",
    polygon: [
      32.018,
      -0.105,
      32.86,
      32.018,
      -0.705,
      32.86,
      32.018,
      -0.705,
      35.32,
      32.018,
      -0.705,
      35.32,
      32.018,
      -0.105,
      35.32,
      32.018,
      -0.105,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4477.0",
    polygon: [
      22.928,
      -7.448,
      22.36,
      22.928,
      -8.448,
      22.36,
      22.928,
      -8.448,
      24.82,
      22.928,
      -8.448,
      24.82,
      22.928,
      -7.448,
      24.82,
      22.928,
      -7.448,
      22.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4260.0",
    polygon: [
      14.298,
      25.978,
      1.36,
      15.181,
      25.294,
      1.36,
      15.181,
      25.294,
      3.82,
      15.181,
      25.294,
      3.82,
      14.298,
      25.978,
      3.82,
      14.298,
      25.978,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "4164.0",
    polygon: [
      30.235,
      5.734,
      8.36,
      30.713,
      4.6,
      8.36,
      30.713,
      4.6,
      10.82,
      30.713,
      4.6,
      10.82,
      30.235,
      5.734,
      10.82,
      30.235,
      5.734,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "4125.0",
    polygon: [
      26.741,
      12.368,
      15.36,
      27.38,
      11.317,
      15.36,
      27.38,
      11.317,
      17.82,
      27.38,
      11.317,
      17.82,
      26.741,
      12.368,
      17.82,
      26.741,
      12.368,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "3921.0",
    polygon: [
      -42.93,
      38.497,
      15.36,
      -41.763,
      38.497,
      15.36,
      -41.763,
      38.497,
      17.82,
      -41.763,
      38.497,
      17.82,
      -42.93,
      38.497,
      17.82,
      -42.93,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3837.0",
    polygon: [
      6.743,
      6.378,
      29.36,
      7.943,
      6.378,
      29.36,
      7.943,
      6.378,
      31.82,
      7.943,
      6.378,
      31.82,
      6.743,
      6.378,
      31.82,
      6.743,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4086.0",
    polygon: [
      -74.927,
      26.27,
      1.36,
      -74.927,
      27.47,
      1.36,
      -74.927,
      27.47,
      3.82,
      -74.927,
      27.47,
      3.82,
      -74.927,
      26.27,
      3.82,
      -74.927,
      26.27,
      1.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "3594.0",
    polygon: [
      -12.395,
      25.583,
      18.45,
      -12.395,
      24.583,
      18.45,
      -16.369,
      24.583,
      18.241,
      -16.369,
      24.583,
      18.241,
      -16.369,
      25.583,
      18.241,
      -12.395,
      25.583,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3516.0",
    polygon: [
      -29.777,
      16.516,
      18.45,
      -29.777,
      17.516,
      18.45,
      -25.804,
      17.516,
      18.241,
      -25.804,
      17.516,
      18.241,
      -25.804,
      16.516,
      18.241,
      -29.777,
      16.516,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3443.0",
    polygon: [
      -50.79,
      26.735,
      18.088,
      -50.79,
      25.735,
      18.088,
      -51.998,
      25.735,
      18.025,
      -51.998,
      25.735,
      18.025,
      -51.998,
      26.735,
      18.025,
      -50.79,
      26.735,
      18.088,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "4084.0",
    polygon: [
      -74.927,
      28.975,
      1.36,
      -74.927,
      30.175,
      1.36,
      -74.927,
      30.175,
      3.82,
      -74.927,
      30.175,
      3.82,
      -74.927,
      28.975,
      3.82,
      -74.927,
      28.975,
      1.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "3997.0",
    polygon: [
      -67.23,
      38.499,
      4.86,
      -66.062,
      38.499,
      4.86,
      -66.062,
      38.499,
      7.32,
      -66.062,
      38.499,
      7.32,
      -67.23,
      38.499,
      7.32,
      -67.23,
      38.499,
      4.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3442.0",
    polygon: [
      -49.406,
      26.735,
      18.161,
      -49.406,
      25.735,
      18.161,
      -50.614,
      25.735,
      18.098,
      -50.614,
      25.735,
      18.098,
      -50.614,
      26.735,
      18.098,
      -49.406,
      26.735,
      18.161,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "4316.0",
    polygon: [
      30.713,
      4.665,
      29.36,
      31.165,
      3.522,
      29.36,
      31.165,
      3.522,
      31.82,
      31.165,
      3.522,
      31.82,
      30.713,
      4.665,
      31.82,
      30.713,
      4.665,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4436.0",
    polygon: [
      32.018,
      -0.105,
      29.36,
      32.018,
      -0.705,
      29.36,
      32.018,
      -0.705,
      31.82,
      32.018,
      -0.705,
      31.82,
      32.018,
      -0.105,
      31.82,
      32.018,
      -0.105,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4476.0",
    polygon: [
      22.928,
      -7.448,
      18.86,
      22.928,
      -8.448,
      18.86,
      22.928,
      -8.448,
      21.32,
      22.928,
      -8.448,
      21.32,
      22.928,
      -7.448,
      21.32,
      22.928,
      -7.448,
      18.86,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "3874.0",
    polygon: [
      12.152,
      6.378,
      32.86,
      13.352,
      6.378,
      32.86,
      13.352,
      6.378,
      35.32,
      13.352,
      6.378,
      35.32,
      12.152,
      6.378,
      35.32,
      12.152,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3150.0",
    polygon: [
      -69.098,
      15.448,
      25.86,
      -68.276,
      15.448,
      25.86,
      -68.276,
      15.448,
      28.32,
      -68.276,
      15.448,
      28.32,
      -69.098,
      15.448,
      28.32,
      -69.098,
      15.448,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "4475.0",
    polygon: [
      22.828,
      -7.448,
      15.36,
      22.828,
      -8.448,
      15.36,
      22.828,
      -8.448,
      17.82,
      22.828,
      -8.448,
      17.82,
      22.828,
      -7.448,
      17.82,
      22.828,
      -7.448,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4201.0",
    polygon: [
      31.67,
      2.059,
      11.86,
      32.059,
      0.892,
      11.86,
      32.059,
      0.892,
      14.32,
      32.059,
      0.892,
      14.32,
      31.67,
      2.059,
      14.32,
      31.67,
      2.059,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3444.0",
    polygon: [
      -43.895,
      30.193,
      18.45,
      -43.895,
      29.193,
      18.45,
      -47.869,
      29.193,
      18.241,
      -47.869,
      29.193,
      18.241,
      -47.869,
      30.193,
      18.241,
      -43.895,
      30.193,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3346.0",
    polygon: [
      -61.277,
      21.126,
      18.45,
      -61.277,
      22.126,
      18.45,
      -57.304,
      22.126,
      18.241,
      -57.304,
      22.126,
      18.241,
      -57.304,
      21.126,
      18.241,
      -61.277,
      21.126,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3307.0",
    polygon: [
      -57.148,
      15.364,
      18.233,
      -57.148,
      16.364,
      18.233,
      -53.175,
      16.364,
      18.025,
      -53.175,
      16.364,
      18.025,
      -53.175,
      15.364,
      18.025,
      -57.148,
      15.364,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4317.0",
    polygon: [
      31.296,
      3.173,
      29.36,
      31.712,
      2.016,
      29.36,
      31.712,
      2.016,
      31.82,
      31.712,
      2.016,
      31.82,
      31.296,
      3.173,
      31.82,
      31.296,
      3.173,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "3268.0",
    polygon: [
      -43.226,
      8.648,
      29.36,
      -43.226,
      9.999,
      29.36,
      -43.226,
      9.999,
      31.82,
      -43.226,
      9.999,
      31.82,
      -43.226,
      8.648,
      31.82,
      -43.226,
      8.648,
      29.36,
    ],
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3151.0",
    polygon: [
      -68.276,
      15.448,
      25.86,
      -67.453,
      15.448,
      25.86,
      -67.453,
      15.448,
      28.32,
      -67.453,
      15.448,
      28.32,
      -68.276,
      15.448,
      28.32,
      -68.276,
      15.448,
      25.86,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3999.0",
    polygon: [
      -64.53,
      38.499,
      4.86,
      -63.362,
      38.499,
      4.86,
      -63.362,
      38.499,
      7.32,
      -63.362,
      38.499,
      7.32,
      -64.53,
      38.499,
      7.32,
      -64.53,
      38.499,
      4.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3876.0",
    polygon: [
      9.447,
      6.378,
      32.86,
      10.647,
      6.378,
      32.86,
      10.647,
      6.378,
      35.32,
      10.647,
      6.378,
      35.32,
      9.447,
      6.378,
      35.32,
      9.447,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4085.0",
    polygon: [
      -74.927,
      27.622,
      1.36,
      -74.927,
      28.822,
      1.36,
      -74.927,
      28.822,
      3.82,
      -74.927,
      28.822,
      3.82,
      -74.927,
      27.622,
      3.82,
      -74.927,
      27.622,
      1.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "3959.0",
    polygon: [
      -30.23,
      38.497,
      11.86,
      -29.063,
      38.497,
      11.86,
      -29.063,
      38.497,
      14.32,
      -29.063,
      38.497,
      14.32,
      -30.23,
      38.497,
      14.32,
      -30.23,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4037.0",
    polygon: [
      -42.93,
      38.497,
      11.86,
      -41.763,
      38.497,
      11.86,
      -41.763,
      38.497,
      14.32,
      -41.763,
      38.497,
      14.32,
      -42.93,
      38.497,
      14.32,
      -42.93,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3345.0",
    polygon: [
      -57.148,
      22.278,
      18.233,
      -57.148,
      23.278,
      18.233,
      -53.175,
      23.278,
      18.025,
      -53.175,
      23.278,
      18.025,
      -53.175,
      22.278,
      18.025,
      -57.148,
      22.278,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3267.0",
    polygon: [
      -43.226,
      9.998,
      29.36,
      -43.226,
      11.148,
      29.36,
      -43.226,
      11.148,
      31.82,
      -43.226,
      11.148,
      31.82,
      -43.226,
      9.998,
      31.82,
      -43.226,
      9.998,
      29.36,
    ],
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3306.0",
    polygon: [
      -61.277,
      15.364,
      18.45,
      -61.277,
      16.364,
      18.45,
      -57.304,
      16.364,
      18.241,
      -57.304,
      16.364,
      18.241,
      -57.304,
      15.364,
      18.241,
      -61.277,
      15.364,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3595.0",
    polygon: [
      -16.524,
      25.583,
      18.233,
      -16.524,
      24.583,
      18.233,
      -20.498,
      24.583,
      18.025,
      -20.498,
      24.583,
      18.025,
      -20.498,
      25.583,
      18.025,
      -16.524,
      25.583,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4318.0",
    polygon: [
      31.695,
      2.067,
      29.36,
      32.084,
      0.9,
      29.36,
      32.084,
      0.9,
      31.82,
      32.084,
      0.9,
      31.82,
      31.695,
      2.067,
      31.82,
      31.695,
      2.067,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "4439.0",
    polygon: [
      37.333,
      -2.775,
      32.86,
      37.333,
      -3.975,
      32.86,
      37.333,
      -3.975,
      35.32,
      37.333,
      -3.975,
      35.32,
      37.333,
      -2.775,
      35.32,
      37.333,
      -2.775,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4478.0",
    polygon: [
      22.928,
      -7.448,
      25.86,
      22.928,
      -8.448,
      25.86,
      22.928,
      -8.448,
      28.32,
      22.928,
      -8.448,
      28.32,
      22.928,
      -7.448,
      28.32,
      22.928,
      -7.448,
      25.86,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4261.0",
    polygon: [
      15.264,
      25.228,
      1.36,
      16.131,
      24.523,
      1.36,
      16.131,
      24.523,
      3.82,
      16.131,
      24.523,
      3.82,
      15.264,
      25.228,
      3.82,
      15.264,
      25.228,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "3878.0",
    polygon: [
      17.56,
      6.378,
      32.86,
      18.76,
      6.378,
      32.86,
      18.76,
      6.378,
      35.32,
      18.76,
      6.378,
      35.32,
      17.56,
      6.378,
      35.32,
      17.56,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3596.0",
    polygon: [
      -12.395,
      24.431,
      18.45,
      -12.395,
      23.431,
      18.45,
      -16.369,
      23.431,
      18.241,
      -16.369,
      23.431,
      18.241,
      -16.369,
      24.431,
      18.241,
      -12.395,
      24.431,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3557.0",
    polygon: [
      -16.524,
      14.059,
      18.233,
      -16.524,
      13.059,
      18.233,
      -20.498,
      13.059,
      18.025,
      -20.498,
      13.059,
      18.025,
      -20.498,
      14.059,
      18.025,
      -16.524,
      14.059,
      18.233,
    ],
    "04-15 17:30": 1,
  },
  {
    ID: "3445.0",
    polygon: [
      -48.024,
      30.193,
      18.233,
      -48.024,
      29.193,
      18.233,
      -51.998,
      29.193,
      18.025,
      -51.998,
      29.193,
      18.025,
      -51.998,
      30.193,
      18.025,
      -48.024,
      30.193,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3269.0",
    polygon: [
      -43.226,
      7.498,
      29.36,
      -43.226,
      8.648,
      29.36,
      -43.226,
      8.648,
      31.82,
      -43.226,
      8.648,
      31.82,
      -43.226,
      7.498,
      31.82,
      -43.226,
      7.498,
      29.36,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3308.0",
    polygon: [
      -61.277,
      14.211,
      18.45,
      -61.277,
      15.211,
      18.45,
      -57.304,
      15.211,
      18.241,
      -57.304,
      15.211,
      18.241,
      -57.304,
      14.211,
      18.241,
      -61.277,
      14.211,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
  },
  {
    ID: "4165.0",
    polygon: [
      30.69,
      4.657,
      8.36,
      31.142,
      3.513,
      8.36,
      31.142,
      3.513,
      10.82,
      31.142,
      3.513,
      10.82,
      30.69,
      4.657,
      10.82,
      30.69,
      4.657,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "3922.0",
    polygon: [
      -41.764,
      38.497,
      15.36,
      -40.596,
      38.497,
      15.36,
      -40.596,
      38.497,
      17.82,
      -40.596,
      38.497,
      17.82,
      -41.764,
      38.497,
      17.82,
      -41.764,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3877.0",
    polygon: [
      13.504,
      6.378,
      32.86,
      14.704,
      6.378,
      32.86,
      14.704,
      6.378,
      35.32,
      14.704,
      6.378,
      35.32,
      13.504,
      6.378,
      35.32,
      13.504,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4087.0",
    polygon: [
      -74.927,
      25.218,
      1.36,
      -74.927,
      26.118,
      1.36,
      -74.927,
      26.118,
      3.82,
      -74.927,
      26.118,
      3.82,
      -74.927,
      25.218,
      3.82,
      -74.927,
      25.218,
      1.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3838.0",
    polygon: [
      5.391,
      6.378,
      29.36,
      6.591,
      6.378,
      29.36,
      6.591,
      6.378,
      31.82,
      6.591,
      6.378,
      31.82,
      5.391,
      6.378,
      31.82,
      5.391,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4039.0",
    polygon: [
      -40.23,
      38.497,
      11.86,
      -39.063,
      38.497,
      11.86,
      -39.063,
      38.497,
      14.32,
      -39.063,
      38.497,
      14.32,
      -40.23,
      38.497,
      14.32,
      -40.23,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3556.0",
    polygon: [
      -12.395,
      14.059,
      18.45,
      -12.395,
      13.059,
      18.45,
      -16.369,
      13.059,
      18.241,
      -16.369,
      13.059,
      18.241,
      -16.369,
      14.059,
      18.241,
      -12.395,
      14.059,
      18.45,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4259.0",
    polygon: [
      13.309,
      26.708,
      1.36,
      14.208,
      26.046,
      1.36,
      14.208,
      26.046,
      3.82,
      14.208,
      26.046,
      3.82,
      13.309,
      26.708,
      3.82,
      13.309,
      26.708,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4126.0",
    polygon: [
      27.598,
      10.945,
      15.36,
      28.203,
      9.873,
      15.36,
      28.203,
      9.873,
      17.82,
      28.203,
      9.873,
      17.82,
      27.598,
      10.945,
      17.82,
      27.598,
      10.945,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "3962.0",
    polygon: [
      -27.531,
      38.497,
      11.86,
      -26.364,
      38.497,
      11.86,
      -26.364,
      38.497,
      14.32,
      -26.364,
      38.497,
      14.32,
      -27.531,
      38.497,
      14.32,
      -27.531,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4088.0",
    polygon: [
      -74.927,
      22.246,
      1.36,
      -74.927,
      23.446,
      1.36,
      -74.927,
      23.446,
      3.82,
      -74.927,
      23.446,
      3.82,
      -74.927,
      22.246,
      3.82,
      -74.927,
      22.246,
      1.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "3923.0",
    polygon: [
      -40.23,
      38.497,
      15.36,
      -39.063,
      38.497,
      15.36,
      -39.063,
      38.497,
      17.82,
      -39.063,
      38.497,
      17.82,
      -40.23,
      38.497,
      17.82,
      -40.23,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3839.0",
    polygon: [
      4.039,
      6.378,
      29.36,
      5.239,
      6.378,
      29.36,
      5.239,
      6.378,
      31.82,
      5.239,
      6.378,
      31.82,
      4.039,
      6.378,
      31.82,
      4.039,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3114.0",
    polygon: [
      -69.098,
      15.448,
      29.36,
      -68.276,
      15.448,
      29.36,
      -68.276,
      15.448,
      31.82,
      -68.276,
      15.448,
      31.82,
      -69.098,
      15.448,
      31.82,
      -69.098,
      15.448,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "4440.0",
    polygon: [
      37.333,
      -4.128,
      32.86,
      37.333,
      -5.328,
      32.86,
      37.333,
      -5.328,
      35.32,
      37.333,
      -5.328,
      35.32,
      37.333,
      -4.128,
      35.32,
      37.333,
      -4.128,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4319.0",
    polygon: [
      30.257,
      5.743,
      32.86,
      30.735,
      4.61,
      32.86,
      30.735,
      4.61,
      35.32,
      30.735,
      4.61,
      35.32,
      30.257,
      5.743,
      35.32,
      30.257,
      5.743,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "3270.0",
    polygon: [
      -30.122,
      11.148,
      29.36,
      -30.122,
      9.998,
      29.36,
      -30.122,
      9.998,
      31.82,
      -30.122,
      9.998,
      31.82,
      -30.122,
      11.148,
      31.82,
      -30.122,
      11.148,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "4127.0",
    polygon: [
      28.181,
      9.913,
      15.36,
      28.761,
      8.828,
      15.36,
      28.761,
      8.828,
      17.82,
      28.761,
      8.828,
      17.82,
      28.181,
      9.913,
      17.82,
      28.181,
      9.913,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3924.0",
    polygon: [
      -39.064,
      38.497,
      15.36,
      -37.896,
      38.497,
      15.36,
      -37.896,
      38.497,
      17.82,
      -37.896,
      38.497,
      17.82,
      -39.064,
      38.497,
      17.82,
      -39.064,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4002.0",
    polygon: [
      -1.37,
      38.498,
      8.36,
      -0.203,
      38.498,
      8.36,
      -0.203,
      38.498,
      10.82,
      -0.203,
      38.498,
      10.82,
      -1.37,
      38.498,
      10.82,
      -1.37,
      38.498,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3840.0",
    polygon: [
      2.686,
      6.378,
      29.36,
      3.886,
      6.378,
      29.36,
      3.886,
      6.378,
      31.82,
      3.886,
      6.378,
      31.82,
      2.686,
      6.378,
      31.82,
      2.686,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3558.0",
    polygon: [
      -12.395,
      12.907,
      18.45,
      -12.395,
      11.907,
      18.45,
      -16.369,
      11.907,
      18.241,
      -16.369,
      11.907,
      18.241,
      -16.369,
      12.907,
      18.241,
      -12.395,
      12.907,
      18.45,
    ],
    "04-15 17:30": 1,
  },
  {
    ID: "3446.0",
    polygon: [
      -43.895,
      29.04,
      18.45,
      -43.895,
      28.04,
      18.45,
      -47.869,
      28.04,
      18.241,
      -47.869,
      28.04,
      18.241,
      -47.869,
      29.04,
      18.241,
      -43.895,
      29.04,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3879.0",
    polygon: [
      16.208,
      6.378,
      32.86,
      17.408,
      6.378,
      32.86,
      17.408,
      6.378,
      35.32,
      17.408,
      6.378,
      35.32,
      16.208,
      6.378,
      35.32,
      16.208,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4479.0",
    polygon: [
      22.928,
      -7.448,
      29.36,
      22.928,
      -8.448,
      29.36,
      22.928,
      -8.448,
      31.82,
      22.928,
      -8.448,
      31.82,
      22.928,
      -7.448,
      31.82,
      22.928,
      -7.448,
      29.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4262.0",
    polygon: [
      16.848,
      23.914,
      1.36,
      17.686,
      23.175,
      1.36,
      17.686,
      23.175,
      3.82,
      17.686,
      23.175,
      3.82,
      16.848,
      23.914,
      3.82,
      16.848,
      23.914,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "4166.0",
    polygon: [
      31.273,
      3.165,
      8.36,
      31.688,
      2.007,
      8.36,
      31.688,
      2.007,
      10.82,
      31.688,
      2.007,
      10.82,
      31.273,
      3.165,
      10.82,
      31.273,
      3.165,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4442.0",
    polygon: [
      37.333,
      -1.423,
      8.36,
      37.333,
      -2.623,
      8.36,
      37.333,
      -2.623,
      10.82,
      37.333,
      -2.623,
      10.82,
      37.333,
      -1.423,
      10.82,
      37.333,
      -1.423,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4321.0",
    polygon: [
      31.296,
      3.173,
      32.86,
      31.712,
      2.016,
      32.86,
      31.712,
      2.016,
      35.32,
      31.712,
      2.016,
      35.32,
      31.296,
      3.173,
      35.32,
      31.296,
      3.173,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "4481.0",
    polygon: [
      22.828,
      -7.448,
      0.36,
      22.828,
      -8.448,
      0.36,
      22.828,
      -8.448,
      2.82,
      22.828,
      -8.448,
      2.82,
      22.828,
      -7.448,
      2.82,
      22.828,
      -7.448,
      0.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4264.0",
    polygon: [
      18.665,
      22.267,
      1.36,
      19.465,
      21.488,
      1.36,
      19.465,
      21.488,
      3.82,
      19.465,
      21.488,
      3.82,
      18.665,
      22.267,
      3.82,
      18.665,
      22.267,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "4129.0",
    polygon: [
      29.475,
      7.395,
      15.36,
      29.993,
      6.28,
      15.36,
      29.993,
      6.28,
      17.82,
      29.993,
      6.28,
      17.82,
      29.475,
      7.395,
      17.82,
      29.475,
      7.395,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4168.0",
    polygon: [
      1.367,
      36.745,
      11.86,
      1.367,
      35.645,
      11.86,
      1.367,
      35.645,
      14.32,
      1.367,
      35.645,
      14.32,
      1.367,
      36.745,
      14.32,
      1.367,
      36.745,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "3115.0",
    polygon: [
      -68.276,
      15.448,
      29.36,
      -67.453,
      15.448,
      29.36,
      -67.453,
      15.448,
      31.82,
      -67.453,
      15.448,
      31.82,
      -68.276,
      15.448,
      31.82,
      -68.276,
      15.448,
      29.36,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4480.0",
    polygon: [
      22.828,
      -7.448,
      4.86,
      22.828,
      -8.448,
      4.86,
      22.828,
      -8.448,
      7.32,
      22.828,
      -8.448,
      7.32,
      22.828,
      -7.448,
      7.32,
      22.828,
      -7.448,
      4.86,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4320.0",
    polygon: [
      30.713,
      4.665,
      32.86,
      31.165,
      3.522,
      32.86,
      31.165,
      3.522,
      35.32,
      31.165,
      3.522,
      35.32,
      30.713,
      4.665,
      35.32,
      30.713,
      4.665,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3842.0",
    polygon: [
      12.152,
      6.378,
      29.36,
      13.352,
      6.378,
      29.36,
      13.352,
      6.378,
      31.82,
      13.352,
      6.378,
      31.82,
      12.152,
      6.378,
      31.82,
      12.152,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3881.0",
    polygon: [
      18.912,
      6.378,
      32.86,
      20.112,
      6.378,
      32.86,
      20.112,
      6.378,
      35.32,
      20.112,
      6.378,
      35.32,
      18.912,
      6.378,
      35.32,
      18.912,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4091.0",
    polygon: [
      -74.927,
      23.598,
      1.36,
      -74.927,
      24.498,
      1.36,
      -74.927,
      24.498,
      3.82,
      -74.927,
      24.498,
      3.82,
      -74.927,
      23.598,
      3.82,
      -74.927,
      23.598,
      1.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3965.0",
    polygon: [
      -45.67,
      38.497,
      11.86,
      -44.502,
      38.497,
      11.86,
      -44.502,
      38.497,
      14.32,
      -44.502,
      38.497,
      14.32,
      -45.67,
      38.497,
      14.32,
      -45.67,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3926.0",
    polygon: [
      -35.63,
      38.497,
      15.36,
      -34.463,
      38.497,
      15.36,
      -34.463,
      38.497,
      17.82,
      -34.463,
      38.497,
      17.82,
      -35.63,
      38.497,
      17.82,
      -35.63,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4004.0",
    polygon: [
      -4.07,
      38.498,
      8.36,
      -2.903,
      38.498,
      8.36,
      -2.903,
      38.498,
      10.82,
      -2.903,
      38.498,
      10.82,
      -4.07,
      38.498,
      10.82,
      -4.07,
      38.498,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4042.0",
    polygon: [
      -35.63,
      38.497,
      11.86,
      -34.463,
      38.497,
      11.86,
      -34.463,
      38.497,
      14.32,
      -34.463,
      38.497,
      14.32,
      -35.63,
      38.497,
      14.32,
      -35.63,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3880.0",
    polygon: [
      14.856,
      6.378,
      32.86,
      16.056,
      6.378,
      32.86,
      16.056,
      6.378,
      35.32,
      16.056,
      6.378,
      35.32,
      14.856,
      6.378,
      35.32,
      14.856,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3271.0",
    polygon: [
      -30.122,
      9.999,
      29.36,
      -30.122,
      8.648,
      29.36,
      -30.122,
      8.648,
      31.82,
      -30.122,
      8.648,
      31.82,
      -30.122,
      9.999,
      31.82,
      -30.122,
      9.999,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4090.0",
    polygon: [
      -74.927,
      19.841,
      1.36,
      -74.927,
      20.741,
      1.36,
      -74.927,
      20.741,
      3.82,
      -74.927,
      20.741,
      3.82,
      -74.927,
      19.841,
      3.82,
      -74.927,
      19.841,
      1.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "3841.0",
    polygon: [
      8.095,
      6.378,
      29.36,
      9.295,
      6.378,
      29.36,
      9.295,
      6.378,
      31.82,
      9.295,
      6.378,
      31.82,
      8.095,
      6.378,
      31.82,
      8.095,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3520.0",
    polygon: [
      -29.777,
      19.973,
      18.45,
      -29.777,
      20.973,
      18.45,
      -25.804,
      20.973,
      18.241,
      -25.804,
      20.973,
      18.241,
      -25.804,
      19.973,
      18.241,
      -29.777,
      19.973,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4443.0",
    polygon: [
      37.333,
      -2.775,
      8.36,
      37.333,
      -3.975,
      8.36,
      37.333,
      -3.975,
      10.82,
      37.333,
      -3.975,
      10.82,
      37.333,
      -2.775,
      10.82,
      37.333,
      -2.775,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4169.0",
    polygon: [
      1.367,
      35.645,
      11.86,
      1.367,
      34.545,
      11.86,
      1.367,
      34.545,
      14.32,
      1.367,
      34.545,
      14.32,
      1.367,
      35.645,
      14.32,
      1.367,
      35.645,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3522.0",
    polygon: [
      -29.777,
      18.821,
      18.45,
      -29.777,
      19.821,
      18.45,
      -25.804,
      19.821,
      18.241,
      -25.804,
      19.821,
      18.241,
      -25.804,
      18.821,
      18.241,
      -29.777,
      18.821,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4322.0",
    polygon: [
      31.695,
      2.067,
      32.86,
      32.084,
      0.9,
      32.86,
      32.084,
      0.9,
      35.32,
      32.084,
      0.9,
      35.32,
      31.695,
      2.067,
      35.32,
      31.695,
      2.067,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "3521.0",
    polygon: [
      -25.648,
      19.973,
      18.233,
      -25.648,
      20.973,
      18.233,
      -21.675,
      20.973,
      18.025,
      -21.675,
      20.973,
      18.025,
      -21.675,
      19.973,
      18.025,
      -25.648,
      19.973,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 15:45": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3448.0",
    polygon: [
      -49.406,
      29.04,
      18.161,
      -49.406,
      28.04,
      18.161,
      -50.614,
      28.04,
      18.098,
      -50.614,
      28.04,
      18.098,
      -50.614,
      29.04,
      18.098,
      -49.406,
      29.04,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3350.0",
    polygon: [
      -61.277,
      24.583,
      18.45,
      -61.277,
      25.583,
      18.45,
      -57.304,
      25.583,
      18.241,
      -57.304,
      25.583,
      18.241,
      -57.304,
      24.583,
      18.241,
      -61.277,
      24.583,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4089.0",
    polygon: [
      -74.927,
      20.893,
      1.36,
      -74.927,
      22.093,
      1.36,
      -74.927,
      22.093,
      3.82,
      -74.927,
      22.093,
      3.82,
      -74.927,
      20.893,
      3.82,
      -74.927,
      20.893,
      1.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "4128.0",
    polygon: [
      28.952,
      8.454,
      15.36,
      29.496,
      7.351,
      15.36,
      29.496,
      7.351,
      17.82,
      29.496,
      7.351,
      17.82,
      28.952,
      8.454,
      17.82,
      28.952,
      8.454,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "4441.0",
    polygon: [
      37.333,
      -5.48,
      32.86,
      37.333,
      -6.68,
      32.86,
      37.333,
      -6.68,
      35.32,
      37.333,
      -6.68,
      35.32,
      37.333,
      -5.48,
      35.32,
      37.333,
      -5.48,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4263.0",
    polygon: [
      17.774,
      23.095,
      1.36,
      18.593,
      22.336,
      1.36,
      18.593,
      22.336,
      3.82,
      18.593,
      22.336,
      3.82,
      17.774,
      23.095,
      3.82,
      17.774,
      23.095,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "4167.0",
    polygon: [
      31.67,
      2.059,
      8.36,
      32.059,
      0.892,
      8.36,
      32.059,
      0.892,
      10.82,
      32.059,
      0.892,
      10.82,
      31.67,
      2.059,
      10.82,
      31.67,
      2.059,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3351.0",
    polygon: [
      -57.148,
      24.583,
      18.233,
      -57.148,
      25.583,
      18.233,
      -53.175,
      25.583,
      18.025,
      -53.175,
      25.583,
      18.025,
      -53.175,
      24.583,
      18.025,
      -57.148,
      24.583,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3449.0",
    polygon: [
      -50.79,
      29.04,
      18.088,
      -50.79,
      28.04,
      18.088,
      -51.998,
      28.04,
      18.025,
      -51.998,
      28.04,
      18.025,
      -51.998,
      29.04,
      18.025,
      -50.79,
      29.04,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3272.0",
    polygon: [
      -30.122,
      8.648,
      29.36,
      -30.122,
      7.498,
      29.36,
      -30.122,
      7.498,
      31.82,
      -30.122,
      7.498,
      31.82,
      -30.122,
      8.648,
      31.82,
      -30.122,
      8.648,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "3447.0",
    polygon: [
      -48.028,
      29.04,
      18.233,
      -48.028,
      28.04,
      18.233,
      -49.236,
      28.04,
      18.17,
      -49.236,
      28.04,
      18.17,
      -49.236,
      29.04,
      18.17,
      -48.028,
      29.04,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "4130.0",
    polygon: [
      30.235,
      5.734,
      15.36,
      30.713,
      4.6,
      15.36,
      30.713,
      4.6,
      17.82,
      30.713,
      4.6,
      17.82,
      30.235,
      5.734,
      17.82,
      30.235,
      5.734,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "4265.0",
    polygon: [
      19.529,
      21.425,
      1.36,
      20.311,
      20.627,
      1.36,
      20.311,
      20.627,
      3.82,
      20.311,
      20.627,
      3.82,
      19.529,
      21.425,
      3.82,
      19.529,
      21.425,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "3273.0",
    polygon: [
      -30.132,
      11.148,
      18.86,
      -30.132,
      9.998,
      18.86,
      -30.132,
      9.998,
      21.32,
      -30.132,
      9.998,
      21.32,
      -30.132,
      11.148,
      21.32,
      -30.132,
      11.148,
      18.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3312.0",
    polygon: [
      -61.277,
      13.059,
      18.45,
      -61.277,
      14.059,
      18.45,
      -57.304,
      14.059,
      18.241,
      -57.304,
      14.059,
      18.241,
      -57.304,
      13.059,
      18.241,
      -61.277,
      13.059,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
  },
  {
    ID: "3600.0",
    polygon: [
      -12.395,
      27.888,
      18.45,
      -12.395,
      26.888,
      18.45,
      -16.369,
      26.888,
      18.241,
      -16.369,
      26.888,
      18.241,
      -16.369,
      27.888,
      18.241,
      -12.395,
      27.888,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3882.0",
    polygon: [
      20.265,
      6.378,
      32.86,
      21.465,
      6.378,
      32.86,
      21.465,
      6.378,
      35.32,
      21.465,
      6.378,
      35.32,
      20.265,
      6.378,
      35.32,
      20.265,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4266.0",
    polygon: [
      20.909,
      19.993,
      4.86,
      21.675,
      19.148,
      4.86,
      21.675,
      19.148,
      7.32,
      21.675,
      19.148,
      7.32,
      20.909,
      19.993,
      7.32,
      20.909,
      19.993,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "3927.0",
    polygon: [
      -34.464,
      38.497,
      15.36,
      -33.296,
      38.497,
      15.36,
      -33.296,
      38.497,
      17.82,
      -33.296,
      38.497,
      17.82,
      -34.464,
      38.497,
      17.82,
      -34.464,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3843.0",
    polygon: [
      10.799,
      6.378,
      29.36,
      11.999,
      6.378,
      29.36,
      11.999,
      6.378,
      31.82,
      11.999,
      6.378,
      31.82,
      10.799,
      6.378,
      31.82,
      10.799,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4092.0",
    polygon: [
      -74.927,
      16.846,
      1.36,
      -74.927,
      18.046,
      1.36,
      -74.927,
      18.046,
      3.82,
      -74.927,
      18.046,
      3.82,
      -74.927,
      16.846,
      3.82,
      -74.927,
      16.846,
      1.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "4131.0",
    polygon: [
      30.69,
      4.657,
      15.36,
      31.142,
      3.513,
      15.36,
      31.142,
      3.513,
      17.82,
      31.142,
      3.513,
      17.82,
      30.69,
      4.657,
      17.82,
      30.69,
      4.657,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "3352.0",
    polygon: [
      -61.277,
      23.431,
      18.45,
      -61.277,
      24.431,
      18.45,
      -57.304,
      24.431,
      18.241,
      -57.304,
      24.431,
      18.241,
      -57.304,
      23.431,
      18.241,
      -61.277,
      23.431,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4170.0",
    polygon: [
      1.96,
      32.86,
      11.86,
      3.102,
      32.402,
      11.86,
      3.102,
      32.402,
      14.32,
      3.102,
      32.402,
      14.32,
      1.96,
      32.86,
      14.32,
      1.96,
      32.86,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4358.0",
    polygon: [
      22.563,
      18.117,
      0.05,
      23.361,
      17.142,
      0.05,
      23.361,
      17.142,
      2.78,
      23.361,
      17.142,
      2.78,
      22.563,
      18.117,
      2.78,
      22.563,
      18.117,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "3196.0",
    polygon: [
      -69.098,
      14.448,
      18.86,
      -68.276,
      14.448,
      18.86,
      -68.276,
      14.448,
      21.32,
      -68.276,
      14.448,
      21.32,
      -69.098,
      14.448,
      21.32,
      -69.098,
      14.448,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "3484.0",
    polygon: [
      -61.277,
      30.345,
      18.45,
      -61.277,
      30.845,
      18.45,
      -57.304,
      30.845,
      18.241,
      -57.304,
      30.845,
      18.241,
      -57.304,
      30.345,
      18.241,
      -61.277,
      30.345,
      18.45,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3601.0",
    polygon: [
      -16.524,
      27.888,
      18.233,
      -16.524,
      26.888,
      18.233,
      -20.498,
      26.888,
      18.025,
      -20.498,
      26.888,
      18.025,
      -20.498,
      27.888,
      18.025,
      -16.524,
      27.888,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3883.0",
    polygon: [
      21.617,
      6.378,
      32.86,
      22.817,
      6.378,
      32.86,
      22.817,
      6.378,
      35.32,
      22.817,
      6.378,
      35.32,
      21.617,
      6.378,
      35.32,
      21.617,
      6.378,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3313.0",
    polygon: [
      -57.148,
      13.059,
      18.233,
      -57.148,
      14.059,
      18.233,
      -53.175,
      14.059,
      18.025,
      -53.175,
      14.059,
      18.025,
      -53.175,
      13.059,
      18.025,
      -57.148,
      13.059,
      18.233,
    ],
    "04-15 05:15": 1,
  },
  {
    ID: "4444.0",
    polygon: [
      37.333,
      -4.128,
      8.36,
      37.333,
      -5.328,
      8.36,
      37.333,
      -5.328,
      10.82,
      37.333,
      -5.328,
      10.82,
      37.333,
      -4.128,
      10.82,
      37.333,
      -4.128,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4044.0",
    polygon: [
      -32.93,
      38.497,
      11.86,
      -31.763,
      38.497,
      11.86,
      -31.763,
      38.497,
      14.32,
      -31.763,
      38.497,
      14.32,
      -32.93,
      38.497,
      14.32,
      -32.93,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4267.0",
    polygon: [
      21.775,
      19.034,
      4.86,
      22.519,
      18.17,
      4.86,
      22.519,
      18.17,
      7.32,
      22.519,
      18.17,
      7.32,
      21.775,
      19.034,
      7.32,
      21.775,
      19.034,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4132.0",
    polygon: [
      31.273,
      3.165,
      15.36,
      31.688,
      2.007,
      15.36,
      31.688,
      2.007,
      17.82,
      31.688,
      2.007,
      17.82,
      31.273,
      3.165,
      17.82,
      31.273,
      3.165,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4366.0",
    polygon: [
      25.986,
      13.541,
      2.78,
      27.327,
      11.407,
      2.78,
      27.327,
      11.407,
      3.82,
      27.327,
      11.407,
      3.82,
      25.986,
      13.541,
      3.82,
      25.986,
      13.541,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "3805.0",
    polygon: [
      6.743,
      6.378,
      25.86,
      7.943,
      6.378,
      25.86,
      7.943,
      6.378,
      28.32,
      7.943,
      6.378,
      28.32,
      6.743,
      6.378,
      28.32,
      6.743,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4171.0",
    polygon: [
      3.099,
      32.403,
      11.86,
      4.229,
      31.917,
      11.86,
      4.229,
      31.917,
      14.32,
      4.229,
      31.917,
      14.32,
      3.099,
      32.403,
      14.32,
      3.099,
      32.403,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "3928.0",
    polygon: [
      -32.93,
      38.497,
      15.36,
      -31.763,
      38.497,
      15.36,
      -31.763,
      38.497,
      17.82,
      -31.763,
      38.497,
      17.82,
      -32.93,
      38.497,
      17.82,
      -32.93,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4445.0",
    polygon: [
      37.333,
      -5.48,
      8.36,
      37.333,
      -6.68,
      8.36,
      37.333,
      -6.68,
      10.82,
      37.333,
      -6.68,
      10.82,
      37.333,
      -5.48,
      10.82,
      37.333,
      -5.48,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4093.0",
    polygon: [
      -74.927,
      15.493,
      1.36,
      -74.927,
      16.693,
      1.36,
      -74.927,
      16.693,
      3.82,
      -74.927,
      16.693,
      3.82,
      -74.927,
      15.493,
      3.82,
      -74.927,
      15.493,
      1.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "3485.0",
    polygon: [
      -57.148,
      30.345,
      18.233,
      -57.148,
      30.845,
      18.233,
      -53.175,
      30.845,
      18.025,
      -53.175,
      30.845,
      18.025,
      -53.175,
      30.345,
      18.025,
      -57.148,
      30.345,
      18.233,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3844.0",
    polygon: [
      9.447,
      6.378,
      29.36,
      10.647,
      6.378,
      29.36,
      10.647,
      6.378,
      31.82,
      10.647,
      6.378,
      31.82,
      9.447,
      6.378,
      31.82,
      9.447,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3236.0",
    polygon: [
      -5.848,
      14.448,
      18.86,
      -5.026,
      14.448,
      18.86,
      -5.026,
      14.448,
      21.32,
      -5.026,
      14.448,
      21.32,
      -5.848,
      14.448,
      21.32,
      -5.848,
      14.448,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "3727.0",
    polygon: [
      26.552,
      6.348,
      36.36,
      27.431,
      6.348,
      36.36,
      27.431,
      6.348,
      38.82,
      27.431,
      6.348,
      38.82,
      26.552,
      6.348,
      38.82,
      26.552,
      6.348,
      36.36,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4270.0",
    polygon: [
      22.619,
      18.052,
      4.86,
      23.34,
      17.169,
      4.86,
      23.34,
      17.169,
      7.32,
      23.34,
      17.169,
      7.32,
      22.619,
      18.052,
      7.32,
      22.619,
      18.052,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "4094.0",
    polygon: [
      -74.927,
      14.441,
      1.36,
      -74.927,
      15.341,
      1.36,
      -74.927,
      15.341,
      3.82,
      -74.927,
      15.341,
      3.82,
      -74.927,
      14.441,
      3.82,
      -74.927,
      14.441,
      1.36,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "4172.0",
    polygon: [
      4.589,
      31.756,
      11.86,
      5.703,
      31.234,
      11.86,
      5.703,
      31.234,
      14.32,
      5.703,
      31.234,
      14.32,
      4.589,
      31.756,
      14.32,
      4.589,
      31.756,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4446.0",
    polygon: [
      37.333,
      -1.423,
      11.86,
      37.333,
      -2.623,
      11.86,
      37.333,
      -2.623,
      14.32,
      37.333,
      -2.623,
      14.32,
      37.333,
      -1.423,
      14.32,
      37.333,
      -1.423,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3968.0",
    polygon: [
      -48.369,
      38.497,
      11.86,
      -47.201,
      38.497,
      11.86,
      -47.201,
      38.497,
      14.32,
      -47.201,
      38.497,
      14.32,
      -48.369,
      38.497,
      14.32,
      -48.369,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3603.0",
    polygon: [
      -16.528,
      26.735,
      18.233,
      -16.528,
      25.735,
      18.233,
      -17.736,
      25.735,
      18.17,
      -17.736,
      25.735,
      18.17,
      -17.736,
      26.735,
      18.17,
      -16.528,
      26.735,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3845.0",
    polygon: [
      13.504,
      6.378,
      29.36,
      14.704,
      6.378,
      29.36,
      14.704,
      6.378,
      31.82,
      14.704,
      6.378,
      31.82,
      13.504,
      6.378,
      31.82,
      13.504,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3806.0",
    polygon: [
      5.391,
      6.378,
      25.86,
      6.591,
      6.378,
      25.86,
      6.591,
      6.378,
      28.32,
      6.591,
      6.378,
      28.32,
      5.391,
      6.378,
      28.32,
      5.391,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3846.0",
    polygon: [
      17.56,
      6.378,
      29.36,
      18.76,
      6.378,
      29.36,
      18.76,
      6.378,
      31.82,
      18.76,
      6.378,
      31.82,
      17.56,
      6.378,
      31.82,
      17.56,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3602.0",
    polygon: [
      -12.395,
      26.735,
      18.45,
      -12.395,
      25.735,
      18.45,
      -16.369,
      25.735,
      18.241,
      -16.369,
      25.735,
      18.241,
      -16.369,
      26.735,
      18.241,
      -12.395,
      26.735,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3276.0",
    polygon: [
      -30.122,
      11.148,
      22.36,
      -30.122,
      9.998,
      22.36,
      -30.122,
      9.998,
      24.82,
      -30.122,
      9.998,
      24.82,
      -30.122,
      11.148,
      24.82,
      -30.122,
      11.148,
      22.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3885.0",
    polygon: [
      24.321,
      6.378,
      32.86,
      25.521,
      6.378,
      32.86,
      25.521,
      6.378,
      35.32,
      25.521,
      6.378,
      35.32,
      24.321,
      6.378,
      35.32,
      24.321,
      6.378,
      32.86,
    ],
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4095.0",
    polygon: [
      -74.927,
      18.198,
      1.36,
      -74.927,
      19.098,
      1.36,
      -74.927,
      19.098,
      3.82,
      -74.927,
      19.098,
      3.82,
      -74.927,
      18.198,
      3.82,
      -74.927,
      18.198,
      1.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "3807.0",
    polygon: [
      4.039,
      6.378,
      25.86,
      5.239,
      6.378,
      25.86,
      5.239,
      6.378,
      28.32,
      5.239,
      6.378,
      28.32,
      4.039,
      6.378,
      28.32,
      4.039,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3884.0",
    polygon: [
      22.969,
      6.378,
      32.86,
      24.169,
      6.378,
      32.86,
      24.169,
      6.378,
      35.32,
      24.169,
      6.378,
      35.32,
      22.969,
      6.378,
      35.32,
      22.969,
      6.378,
      32.86,
    ],
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4133.0",
    polygon: [
      31.67,
      2.059,
      15.36,
      32.059,
      0.892,
      15.36,
      32.059,
      0.892,
      17.82,
      32.059,
      0.892,
      17.82,
      31.67,
      2.059,
      17.82,
      31.67,
      2.059,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3486.0",
    polygon: [
      -43.895,
      30.845,
      18.45,
      -43.895,
      30.345,
      18.45,
      -47.869,
      30.345,
      18.241,
      -47.869,
      30.345,
      18.241,
      -47.869,
      30.845,
      18.241,
      -43.895,
      30.845,
      18.45,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "3237.0",
    polygon: [
      -5.026,
      14.448,
      18.86,
      -4.203,
      14.448,
      18.86,
      -4.203,
      14.448,
      21.32,
      -4.203,
      14.448,
      21.32,
      -5.026,
      14.448,
      21.32,
      -5.026,
      14.448,
      18.86,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4047.0",
    polygon: [
      -73.364,
      38.499,
      11.86,
      -72.196,
      38.499,
      11.86,
      -72.196,
      38.499,
      14.32,
      -72.196,
      38.499,
      14.32,
      -73.364,
      38.499,
      14.32,
      -73.364,
      38.499,
      11.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4215.0",
    polygon: [
      1.367,
      36.802,
      4.86,
      1.367,
      35.728,
      4.86,
      1.367,
      35.728,
      7.32,
      1.367,
      35.728,
      7.32,
      1.367,
      36.802,
      7.32,
      1.367,
      36.802,
      4.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "3969.0",
    polygon: [
      -61.83,
      38.497,
      11.86,
      -60.662,
      38.497,
      11.86,
      -60.662,
      38.497,
      14.32,
      -60.662,
      38.497,
      14.32,
      -61.83,
      38.497,
      14.32,
      -61.83,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4007.0",
    polygon: [
      -8.67,
      38.498,
      8.36,
      -7.503,
      38.498,
      8.36,
      -7.503,
      38.498,
      10.82,
      -7.503,
      38.498,
      10.82,
      -8.67,
      38.498,
      10.82,
      -8.67,
      38.498,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4272.0",
    polygon: [
      23.428,
      17.058,
      4.86,
      24.127,
      16.158,
      4.86,
      24.127,
      16.158,
      7.32,
      24.127,
      16.158,
      7.32,
      23.428,
      17.058,
      7.32,
      23.428,
      17.058,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "3930.0",
    polygon: [
      -30.23,
      38.497,
      15.36,
      -29.063,
      38.497,
      15.36,
      -29.063,
      38.497,
      17.82,
      -29.063,
      38.497,
      17.82,
      -30.23,
      38.497,
      17.82,
      -30.23,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4134.0",
    polygon: [
      1.367,
      36.745,
      8.36,
      1.367,
      35.645,
      8.36,
      1.367,
      35.645,
      10.82,
      1.367,
      35.645,
      10.82,
      1.367,
      36.745,
      10.82,
      1.367,
      36.745,
      8.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "4173.0",
    polygon: [
      5.698,
      31.237,
      11.86,
      6.799,
      30.689,
      11.86,
      6.799,
      30.689,
      14.32,
      6.799,
      30.689,
      14.32,
      5.698,
      31.237,
      14.32,
      5.698,
      31.237,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
  },
  {
    ID: "4046.0",
    polygon: [
      -74.53,
      38.499,
      11.86,
      -73.362,
      38.499,
      11.86,
      -73.362,
      38.499,
      14.32,
      -73.362,
      38.499,
      14.32,
      -74.53,
      38.499,
      14.32,
      -74.53,
      38.499,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4448.0",
    polygon: [
      37.333,
      -4.128,
      11.86,
      37.333,
      -5.328,
      11.86,
      37.333,
      -5.328,
      14.32,
      37.333,
      -5.328,
      14.32,
      37.333,
      -4.128,
      14.32,
      37.333,
      -4.128,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3931.0",
    polygon: [
      -29.064,
      38.497,
      15.36,
      -27.896,
      38.497,
      15.36,
      -27.896,
      38.497,
      17.82,
      -27.896,
      38.497,
      17.82,
      -29.064,
      38.497,
      17.82,
      -29.064,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4369.0",
    polygon: [
      25.986,
      13.541,
      0.05,
      27.327,
      11.407,
      0.05,
      27.327,
      11.407,
      2.78,
      27.327,
      11.407,
      2.78,
      25.986,
      13.541,
      2.78,
      25.986,
      13.541,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "3929.0",
    polygon: [
      -31.764,
      38.497,
      15.36,
      -30.596,
      38.497,
      15.36,
      -30.596,
      38.497,
      17.82,
      -30.596,
      38.497,
      17.82,
      -31.764,
      38.497,
      17.82,
      -31.764,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3886.0",
    polygon: [
      27.583,
      6.378,
      32.86,
      28.783,
      6.378,
      32.86,
      28.783,
      6.378,
      35.32,
      28.783,
      6.378,
      35.32,
      27.583,
      6.378,
      35.32,
      27.583,
      6.378,
      32.86,
    ],
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3161.0",
    polygon: [
      -62.101,
      15.425,
      25.86,
      -62.101,
      14.645,
      25.86,
      -62.101,
      14.645,
      28.32,
      -62.101,
      14.645,
      28.32,
      -62.101,
      15.425,
      28.32,
      -62.101,
      15.425,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "3414.0",
    polygon: [
      -43.895,
      18.669,
      18.45,
      -43.895,
      17.669,
      18.45,
      -47.869,
      17.669,
      18.241,
      -47.869,
      17.669,
      18.241,
      -47.869,
      18.669,
      18.241,
      -43.895,
      18.669,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4009.0",
    polygon: [
      -11.37,
      38.498,
      8.36,
      -10.203,
      38.498,
      8.36,
      -10.203,
      38.498,
      10.82,
      -10.203,
      38.498,
      10.82,
      -11.37,
      38.498,
      10.82,
      -11.37,
      38.498,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4135.0",
    polygon: [
      1.367,
      35.645,
      8.36,
      1.367,
      34.545,
      8.36,
      1.367,
      34.545,
      10.82,
      1.367,
      34.545,
      10.82,
      1.367,
      35.645,
      10.82,
      1.367,
      35.645,
      8.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3604.0",
    polygon: [
      -17.906,
      26.735,
      18.161,
      -17.906,
      25.735,
      18.161,
      -19.114,
      25.735,
      18.098,
      -19.114,
      25.735,
      18.098,
      -19.114,
      26.735,
      18.098,
      -17.906,
      26.735,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "4368.0",
    polygon: [
      24.552,
      15.589,
      0.05,
      25.997,
      13.524,
      0.05,
      25.997,
      13.524,
      2.78,
      25.997,
      13.524,
      2.78,
      24.552,
      15.589,
      2.78,
      24.552,
      15.589,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4174.0",
    polygon: [
      7.173,
      30.495,
      11.86,
      8.256,
      29.912,
      11.86,
      8.256,
      29.912,
      14.32,
      8.256,
      29.912,
      14.32,
      7.173,
      30.495,
      14.32,
      7.173,
      30.495,
      11.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "4273.0",
    polygon: [
      24.54,
      15.606,
      4.86,
      25.207,
      14.681,
      4.86,
      25.207,
      14.681,
      7.32,
      25.207,
      14.681,
      7.32,
      24.54,
      15.606,
      7.32,
      24.54,
      15.606,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "3526.0",
    polygon: [
      -29.777,
      22.278,
      18.45,
      -29.777,
      23.278,
      18.45,
      -25.804,
      23.278,
      18.241,
      -25.804,
      23.278,
      18.241,
      -25.804,
      22.278,
      18.241,
      -29.777,
      22.278,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4048.0",
    polygon: [
      -71.83,
      38.499,
      11.86,
      -70.662,
      38.499,
      11.86,
      -70.662,
      38.499,
      14.32,
      -70.662,
      38.499,
      14.32,
      -71.83,
      38.499,
      14.32,
      -71.83,
      38.499,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4216.0",
    polygon: [
      1.367,
      35.576,
      4.86,
      1.367,
      34.502,
      4.86,
      1.367,
      34.502,
      7.32,
      1.367,
      34.502,
      7.32,
      1.367,
      35.576,
      7.32,
      1.367,
      35.576,
      4.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4447.0",
    polygon: [
      37.333,
      -2.775,
      11.86,
      37.333,
      -3.975,
      11.86,
      37.333,
      -3.975,
      14.32,
      37.333,
      -3.975,
      14.32,
      37.333,
      -2.775,
      14.32,
      37.333,
      -2.775,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4096.0",
    polygon: [
      -74.927,
      11.446,
      1.36,
      -74.927,
      12.646,
      1.36,
      -74.927,
      12.646,
      3.82,
      -74.927,
      12.646,
      3.82,
      -74.927,
      11.446,
      3.82,
      -74.927,
      11.446,
      1.36,
    ],
    "04-15 14:00": 1,
  },
  {
    ID: "4367.0",
    polygon: [
      24.553,
      15.589,
      2.78,
      25.997,
      13.524,
      2.78,
      25.997,
      13.524,
      3.82,
      25.997,
      13.524,
      3.82,
      24.553,
      15.589,
      3.82,
      24.553,
      15.589,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "3160.0",
    polygon: [
      -74.437,
      14.657,
      25.86,
      -74.437,
      15.437,
      25.86,
      -74.437,
      15.437,
      28.32,
      -74.437,
      15.437,
      28.32,
      -74.437,
      14.657,
      28.32,
      -74.437,
      14.657,
      25.86,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3415.0",
    polygon: [
      -48.024,
      18.669,
      18.233,
      -48.024,
      17.669,
      18.233,
      -51.998,
      17.669,
      18.025,
      -51.998,
      17.669,
      18.025,
      -51.998,
      18.669,
      18.025,
      -48.024,
      18.669,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3808.0",
    polygon: [
      2.686,
      6.378,
      25.86,
      3.886,
      6.378,
      25.86,
      3.886,
      6.378,
      28.32,
      3.886,
      6.378,
      28.32,
      2.686,
      6.378,
      28.32,
      2.686,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3487.0",
    polygon: [
      -48.024,
      30.845,
      18.233,
      -48.024,
      30.345,
      18.233,
      -51.998,
      30.345,
      18.025,
      -51.998,
      30.345,
      18.025,
      -51.998,
      30.845,
      18.025,
      -48.024,
      30.845,
      18.233,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "3847.0",
    polygon: [
      16.208,
      6.378,
      29.36,
      17.408,
      6.378,
      29.36,
      17.408,
      6.378,
      31.82,
      17.408,
      6.378,
      31.82,
      16.208,
      6.378,
      31.82,
      16.208,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3888.0",
    polygon: [
      25.674,
      6.378,
      32.86,
      26.553,
      6.378,
      32.86,
      26.553,
      6.378,
      35.32,
      26.553,
      6.378,
      35.32,
      25.674,
      6.378,
      35.32,
      25.674,
      6.378,
      32.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3606.0",
    polygon: [
      -12.395,
      30.193,
      18.45,
      -12.395,
      29.193,
      18.45,
      -16.369,
      29.193,
      18.241,
      -16.369,
      29.193,
      18.241,
      -16.369,
      30.193,
      18.241,
      -12.395,
      30.193,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3933.0",
    polygon: [
      -27.531,
      38.497,
      15.36,
      -26.364,
      38.497,
      15.36,
      -26.364,
      38.497,
      17.82,
      -26.364,
      38.497,
      17.82,
      -27.531,
      38.497,
      17.82,
      -27.531,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3810.0",
    polygon: [
      12.152,
      6.378,
      25.86,
      13.352,
      6.378,
      25.86,
      13.352,
      6.378,
      28.32,
      13.352,
      6.378,
      28.32,
      12.152,
      6.378,
      28.32,
      12.152,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3162.0",
    polygon: [
      -37.498,
      15.448,
      25.86,
      -36.676,
      15.448,
      25.86,
      -36.676,
      15.448,
      28.32,
      -36.676,
      15.448,
      28.32,
      -37.498,
      15.448,
      28.32,
      -37.498,
      15.448,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "4370.0",
    polygon: [
      28.814,
      8.724,
      0.05,
      29.914,
      6.457,
      0.05,
      29.914,
      6.457,
      2.78,
      29.914,
      6.457,
      2.78,
      28.814,
      8.724,
      2.78,
      28.814,
      8.724,
      0.05,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "4449.0",
    polygon: [
      37.333,
      -5.48,
      11.86,
      37.333,
      -6.68,
      11.86,
      37.333,
      -6.68,
      14.32,
      37.333,
      -6.68,
      14.32,
      37.333,
      -5.48,
      14.32,
      37.333,
      -5.48,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4010.0",
    polygon: [
      -42.93,
      38.497,
      8.36,
      -41.763,
      38.497,
      8.36,
      -41.763,
      38.497,
      10.82,
      -41.763,
      38.497,
      10.82,
      -42.93,
      38.497,
      10.82,
      -42.93,
      38.497,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3416.0",
    polygon: [
      -43.895,
      17.516,
      18.45,
      -43.895,
      16.516,
      18.45,
      -47.869,
      16.516,
      18.241,
      -47.869,
      16.516,
      18.241,
      -47.869,
      17.516,
      18.241,
      -43.895,
      17.516,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3356.0",
    polygon: [
      -61.277,
      26.888,
      18.45,
      -61.277,
      27.888,
      18.45,
      -57.304,
      27.888,
      18.241,
      -57.304,
      27.888,
      18.241,
      -57.304,
      26.888,
      18.241,
      -61.277,
      26.888,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3605.0",
    polygon: [
      -19.29,
      26.735,
      18.088,
      -19.29,
      25.735,
      18.088,
      -20.498,
      25.735,
      18.025,
      -20.498,
      25.735,
      18.025,
      -20.498,
      26.735,
      18.025,
      -19.29,
      26.735,
      18.088,
    ],
    "04-15 15:15": 1,
  },
  {
    ID: "3357.0",
    polygon: [
      -57.148,
      26.888,
      18.233,
      -57.148,
      27.888,
      18.233,
      -53.175,
      27.888,
      18.025,
      -53.175,
      27.888,
      18.025,
      -53.175,
      26.888,
      18.025,
      -57.148,
      26.888,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3849.0",
    polygon: [
      18.912,
      6.378,
      29.36,
      20.112,
      6.378,
      29.36,
      20.112,
      6.378,
      31.82,
      20.112,
      6.378,
      31.82,
      18.912,
      6.378,
      31.82,
      18.912,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3489.0",
    polygon: [
      -25.648,
      15.364,
      18.233,
      -25.648,
      16.364,
      18.233,
      -21.675,
      16.364,
      18.025,
      -21.675,
      16.364,
      18.025,
      -21.675,
      15.364,
      18.025,
      -25.648,
      15.364,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4049.0",
    polygon: [
      -70.664,
      38.499,
      11.86,
      -69.496,
      38.499,
      11.86,
      -69.496,
      38.499,
      14.32,
      -69.496,
      38.499,
      14.32,
      -70.664,
      38.499,
      14.32,
      -70.664,
      38.499,
      11.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3163.0",
    polygon: [
      -36.676,
      15.448,
      25.86,
      -35.853,
      15.448,
      25.86,
      -35.853,
      15.448,
      28.32,
      -35.853,
      15.448,
      28.32,
      -36.676,
      15.448,
      28.32,
      -36.676,
      15.448,
      25.86,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4176.0",
    polygon: [
      9.679,
      29.093,
      11.86,
      10.727,
      28.449,
      11.86,
      10.727,
      28.449,
      14.32,
      10.727,
      28.449,
      14.32,
      9.679,
      29.093,
      14.32,
      9.679,
      29.093,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "3887.0",
    polygon: [
      26.552,
      6.378,
      32.86,
      27.431,
      6.378,
      32.86,
      27.431,
      6.378,
      35.32,
      27.431,
      6.378,
      35.32,
      26.552,
      6.378,
      35.32,
      26.552,
      6.378,
      32.86,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3488.0",
    polygon: [
      -29.777,
      15.364,
      18.45,
      -29.777,
      16.364,
      18.45,
      -25.804,
      16.364,
      18.241,
      -25.804,
      16.364,
      18.241,
      -25.804,
      15.364,
      18.241,
      -29.777,
      15.364,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "3279.0",
    polygon: [
      -30.122,
      11.148,
      25.86,
      -30.122,
      9.998,
      25.86,
      -30.122,
      9.998,
      28.32,
      -30.122,
      9.998,
      28.32,
      -30.122,
      11.148,
      28.32,
      -30.122,
      11.148,
      25.86,
    ],
    "04-15 06:15": 1,
  },
  {
    ID: "4136.0",
    polygon: [
      1.96,
      32.86,
      8.36,
      3.102,
      32.402,
      8.36,
      3.102,
      32.402,
      10.82,
      3.102,
      32.402,
      10.82,
      1.96,
      32.86,
      10.82,
      1.96,
      32.86,
      8.36,
    ],
    "04-15 05:00": 1,
  },
  {
    ID: "3490.0",
    polygon: [
      -29.777,
      14.211,
      18.45,
      -29.777,
      15.211,
      18.45,
      -25.804,
      15.211,
      18.241,
      -25.804,
      15.211,
      18.241,
      -25.804,
      14.211,
      18.241,
      -29.777,
      14.211,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "4175.0",
    polygon: [
      8.247,
      29.917,
      11.86,
      9.316,
      29.307,
      11.86,
      9.316,
      29.307,
      14.32,
      9.316,
      29.307,
      14.32,
      8.247,
      29.917,
      14.32,
      8.247,
      29.917,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "3124.0",
    polygon: [
      -74.437,
      14.657,
      29.36,
      -74.437,
      15.437,
      29.36,
      -74.437,
      15.437,
      31.82,
      -74.437,
      15.437,
      31.82,
      -74.437,
      14.657,
      31.82,
      -74.437,
      14.657,
      29.36,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4137.0",
    polygon: [
      3.099,
      32.403,
      8.36,
      4.229,
      31.917,
      8.36,
      4.229,
      31.917,
      10.82,
      4.229,
      31.917,
      10.82,
      3.099,
      32.403,
      10.82,
      3.099,
      32.403,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "3809.0",
    polygon: [
      8.095,
      6.378,
      25.86,
      9.295,
      6.378,
      25.86,
      9.295,
      6.378,
      28.32,
      9.295,
      6.378,
      28.32,
      8.095,
      6.378,
      28.32,
      8.095,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4450.0",
    polygon: [
      37.333,
      -1.423,
      15.36,
      37.333,
      -2.623,
      15.36,
      37.333,
      -2.623,
      17.82,
      37.333,
      -2.623,
      17.82,
      37.333,
      -1.423,
      17.82,
      37.333,
      -1.423,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4217.0",
    polygon: [
      1.367,
      36.802,
      1.36,
      1.367,
      35.728,
      1.36,
      1.367,
      35.728,
      3.82,
      1.367,
      35.728,
      3.82,
      1.367,
      36.802,
      3.82,
      1.367,
      36.802,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "3527.0",
    polygon: [
      -25.648,
      22.278,
      18.233,
      -25.648,
      23.278,
      18.233,
      -21.675,
      23.278,
      18.025,
      -21.675,
      23.278,
      18.025,
      -21.675,
      22.278,
      18.025,
      -25.648,
      22.278,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3972.0",
    polygon: [
      -59.131,
      38.497,
      11.86,
      -57.964,
      38.497,
      11.86,
      -57.964,
      38.497,
      14.32,
      -57.964,
      38.497,
      14.32,
      -59.131,
      38.497,
      14.32,
      -59.131,
      38.497,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3848.0",
    polygon: [
      14.856,
      6.378,
      29.36,
      16.056,
      6.378,
      29.36,
      16.056,
      6.378,
      31.82,
      16.056,
      6.378,
      31.82,
      14.856,
      6.378,
      31.82,
      14.856,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4371.0",
    polygon: [
      27.608,
      10.929,
      0.05,
      28.818,
      8.718,
      0.05,
      28.818,
      8.718,
      2.78,
      28.818,
      8.718,
      2.78,
      27.608,
      10.929,
      2.78,
      27.608,
      10.929,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4274.0",
    polygon: [
      25.287,
      14.566,
      4.86,
      25.93,
      13.624,
      4.86,
      25.93,
      13.624,
      7.32,
      25.93,
      13.624,
      7.32,
      25.287,
      14.566,
      7.32,
      25.287,
      14.566,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "3528.0",
    polygon: [
      -29.777,
      21.126,
      18.45,
      -29.777,
      22.126,
      18.45,
      -25.804,
      22.126,
      18.241,
      -25.804,
      22.126,
      18.241,
      -25.804,
      21.126,
      18.241,
      -29.777,
      21.126,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 15:30": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4218.0",
    polygon: [
      1.367,
      35.576,
      1.36,
      1.367,
      34.502,
      1.36,
      1.367,
      34.502,
      3.82,
      1.367,
      34.502,
      3.82,
      1.367,
      35.576,
      3.82,
      1.367,
      35.576,
      1.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3358.0",
    polygon: [
      -61.277,
      25.735,
      18.45,
      -61.277,
      26.735,
      18.45,
      -57.304,
      26.735,
      18.241,
      -57.304,
      26.735,
      18.241,
      -57.304,
      25.735,
      18.241,
      -61.277,
      25.735,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4275.0",
    polygon: [
      26.008,
      13.508,
      4.86,
      26.626,
      12.55,
      4.86,
      26.626,
      12.55,
      7.32,
      26.626,
      12.55,
      7.32,
      26.008,
      13.508,
      7.32,
      26.008,
      13.508,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "3811.0",
    polygon: [
      10.799,
      6.378,
      25.86,
      11.999,
      6.378,
      25.86,
      11.999,
      6.378,
      28.32,
      11.999,
      6.378,
      28.32,
      10.799,
      6.378,
      28.32,
      10.799,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4177.0",
    polygon: [
      10.715,
      28.457,
      11.86,
      11.747,
      27.789,
      11.86,
      11.747,
      27.789,
      14.32,
      11.747,
      27.789,
      14.32,
      10.715,
      28.457,
      14.32,
      10.715,
      28.457,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4138.0",
    polygon: [
      4.589,
      31.756,
      8.36,
      5.703,
      31.234,
      8.36,
      5.703,
      31.234,
      10.82,
      5.703,
      31.234,
      10.82,
      4.589,
      31.756,
      10.82,
      4.589,
      31.756,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4276.0",
    polygon: [
      26.692,
      12.446,
      4.86,
      27.287,
      11.474,
      4.86,
      27.287,
      11.474,
      7.32,
      27.287,
      11.474,
      7.32,
      26.692,
      12.446,
      7.32,
      26.692,
      12.446,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "3934.0",
    polygon: [
      -26.365,
      38.497,
      15.36,
      -25.197,
      38.497,
      15.36,
      -25.197,
      38.497,
      17.82,
      -25.197,
      38.497,
      17.82,
      -26.365,
      38.497,
      17.82,
      -26.365,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4012.0",
    polygon: [
      -40.23,
      38.497,
      8.36,
      -39.063,
      38.497,
      8.36,
      -39.063,
      38.497,
      10.82,
      -39.063,
      38.497,
      10.82,
      -40.23,
      38.497,
      10.82,
      -40.23,
      38.497,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4451.0",
    polygon: [
      37.333,
      -2.775,
      15.36,
      37.333,
      -3.975,
      15.36,
      37.333,
      -3.975,
      17.82,
      37.333,
      -3.975,
      17.82,
      37.333,
      -2.775,
      17.82,
      37.333,
      -2.775,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3607.0",
    polygon: [
      -16.524,
      30.193,
      18.233,
      -16.524,
      29.193,
      18.233,
      -20.498,
      29.193,
      18.025,
      -20.498,
      29.193,
      18.025,
      -20.498,
      30.193,
      18.025,
      -16.524,
      30.193,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4051.0",
    polygon: [
      -67.23,
      38.499,
      11.86,
      -66.062,
      38.499,
      11.86,
      -66.062,
      38.499,
      14.32,
      -66.062,
      38.499,
      14.32,
      -67.23,
      38.499,
      14.32,
      -67.23,
      38.499,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4099.0",
    polygon: [
      -74.927,
      12.798,
      1.36,
      -74.927,
      13.698,
      1.36,
      -74.927,
      13.698,
      3.82,
      -74.927,
      13.698,
      3.82,
      -74.927,
      12.798,
      3.82,
      -74.927,
      12.798,
      1.36,
    ],
    "04-15 14:15": 1,
  },
  {
    ID: "3850.0",
    polygon: [
      20.265,
      6.378,
      29.36,
      21.465,
      6.378,
      29.36,
      21.465,
      6.378,
      31.82,
      21.465,
      6.378,
      31.82,
      20.265,
      6.378,
      31.82,
      20.265,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3125.0",
    polygon: [
      -62.101,
      15.425,
      29.36,
      -62.101,
      14.645,
      29.36,
      -62.101,
      14.645,
      31.82,
      -62.101,
      14.645,
      31.82,
      -62.101,
      15.425,
      31.82,
      -62.101,
      15.425,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "4372.0",
    polygon: [
      31.189,
      3.389,
      0.05,
      32.021,
      1.011,
      0.05,
      32.021,
      1.011,
      2.78,
      32.021,
      1.011,
      2.78,
      31.189,
      3.389,
      2.78,
      31.189,
      3.389,
      0.05,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3359.0",
    polygon: [
      -57.145,
      25.735,
      18.233,
      -57.145,
      26.735,
      18.233,
      -55.936,
      26.735,
      18.17,
      -55.936,
      26.735,
      18.17,
      -55.936,
      25.735,
      18.17,
      -57.145,
      25.735,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "4139.0",
    polygon: [
      5.698,
      31.237,
      8.36,
      6.799,
      30.689,
      8.36,
      6.799,
      30.689,
      10.82,
      6.799,
      30.689,
      10.82,
      5.698,
      31.237,
      10.82,
      5.698,
      31.237,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
  },
  {
    ID: "4223.0",
    polygon: [
      3.323,
      32.309,
      4.86,
      4.347,
      31.865,
      4.86,
      4.347,
      31.865,
      7.32,
      4.347,
      31.865,
      7.32,
      3.323,
      32.309,
      7.32,
      3.323,
      32.309,
      4.86,
    ],
    "04-15 05:00": 1,
  },
  {
    ID: "3935.0",
    polygon: [
      -44.504,
      38.497,
      15.36,
      -43.336,
      38.497,
      15.36,
      -43.336,
      38.497,
      17.82,
      -43.336,
      38.497,
      17.82,
      -44.504,
      38.497,
      17.82,
      -44.504,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4452.0",
    polygon: [
      37.333,
      -4.128,
      15.36,
      37.333,
      -5.328,
      15.36,
      37.333,
      -5.328,
      17.82,
      37.333,
      -5.328,
      17.82,
      37.333,
      -4.128,
      17.82,
      37.333,
      -4.128,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4178.0",
    polygon: [
      12.072,
      27.571,
      11.86,
      13.083,
      26.87,
      11.86,
      13.083,
      26.87,
      14.32,
      13.083,
      26.87,
      14.32,
      12.072,
      27.571,
      14.32,
      12.072,
      27.571,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "3812.0",
    polygon: [
      9.447,
      6.378,
      25.86,
      10.647,
      6.378,
      25.86,
      10.647,
      6.378,
      28.32,
      10.647,
      6.378,
      28.32,
      9.447,
      6.378,
      28.32,
      9.447,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4100.0",
    polygon: [
      1.367,
      36.745,
      15.36,
      1.367,
      35.645,
      15.36,
      1.367,
      35.645,
      17.82,
      1.367,
      35.645,
      17.82,
      1.367,
      36.745,
      17.82,
      1.367,
      36.745,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "3851.0",
    polygon: [
      21.617,
      6.378,
      29.36,
      22.817,
      6.378,
      29.36,
      22.817,
      6.378,
      31.82,
      22.817,
      6.378,
      31.82,
      21.617,
      6.378,
      31.82,
      21.617,
      6.378,
      29.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3608.0",
    polygon: [
      -12.395,
      29.04,
      18.45,
      -12.395,
      28.04,
      18.45,
      -16.369,
      28.04,
      18.241,
      -16.369,
      28.04,
      18.241,
      -16.369,
      29.04,
      18.241,
      -12.395,
      29.04,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3773.0",
    polygon: [
      6.743,
      6.378,
      22.36,
      7.943,
      6.378,
      22.36,
      7.943,
      6.378,
      24.82,
      7.943,
      6.378,
      24.82,
      6.743,
      6.378,
      24.82,
      6.743,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4277.0",
    polygon: [
      27.661,
      10.837,
      4.86,
      28.22,
      9.843,
      4.86,
      28.22,
      9.843,
      7.32,
      28.22,
      9.843,
      7.32,
      27.661,
      10.837,
      7.32,
      27.661,
      10.837,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "3282.0",
    polygon: [
      -11.626,
      9.998,
      18.86,
      -11.626,
      11.148,
      18.86,
      -11.626,
      11.148,
      21.32,
      -11.626,
      11.148,
      21.32,
      -11.626,
      9.998,
      21.32,
      -11.626,
      9.998,
      18.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4179.0",
    polygon: [
      13.069,
      26.88,
      11.86,
      14.063,
      26.155,
      11.86,
      14.063,
      26.155,
      14.32,
      14.063,
      26.155,
      14.32,
      13.069,
      26.88,
      14.32,
      13.069,
      26.88,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "4101.0",
    polygon: [
      1.367,
      35.645,
      15.36,
      1.367,
      34.545,
      15.36,
      1.367,
      34.545,
      17.82,
      1.367,
      34.545,
      17.82,
      1.367,
      35.645,
      17.82,
      1.367,
      35.645,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "4053.0",
    polygon: [
      -64.53,
      38.499,
      11.86,
      -63.362,
      38.499,
      11.86,
      -63.362,
      38.499,
      14.32,
      -63.362,
      38.499,
      14.32,
      -64.53,
      38.499,
      14.32,
      -64.53,
      38.499,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3774.0",
    polygon: [
      5.391,
      6.378,
      22.36,
      6.591,
      6.378,
      22.36,
      6.591,
      6.378,
      24.82,
      6.591,
      6.378,
      24.82,
      5.391,
      6.378,
      24.82,
      5.391,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3127.0",
    polygon: [
      -36.676,
      15.448,
      29.36,
      -35.853,
      15.448,
      29.36,
      -35.853,
      15.448,
      31.82,
      -35.853,
      15.448,
      31.82,
      -36.676,
      15.448,
      31.82,
      -36.676,
      15.448,
      29.36,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3975.0",
    polygon: [
      -1.37,
      38.498,
      4.86,
      -0.203,
      38.498,
      4.86,
      -0.203,
      38.498,
      7.32,
      -0.203,
      38.498,
      7.32,
      -1.37,
      38.498,
      7.32,
      -1.37,
      38.498,
      4.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4453.0",
    polygon: [
      37.333,
      -5.48,
      15.36,
      37.333,
      -6.68,
      15.36,
      37.333,
      -6.68,
      17.82,
      37.333,
      -6.68,
      17.82,
      37.333,
      -5.48,
      17.82,
      37.333,
      -5.48,
      15.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3360.0",
    polygon: [
      -55.767,
      25.735,
      18.161,
      -55.767,
      26.735,
      18.161,
      -54.558,
      26.735,
      18.098,
      -54.558,
      26.735,
      18.098,
      -54.558,
      25.735,
      18.098,
      -55.767,
      25.735,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "3126.0",
    polygon: [
      -37.498,
      15.448,
      29.36,
      -36.676,
      15.448,
      29.36,
      -36.676,
      15.448,
      31.82,
      -36.676,
      15.448,
      31.82,
      -37.498,
      15.448,
      31.82,
      -37.498,
      15.448,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "3936.0",
    polygon: [
      -45.67,
      38.497,
      15.36,
      -44.502,
      38.497,
      15.36,
      -44.502,
      38.497,
      17.82,
      -44.502,
      38.497,
      17.82,
      -45.67,
      38.497,
      17.82,
      -45.67,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4224.0",
    polygon: [
      4.473,
      31.808,
      4.86,
      5.487,
      31.338,
      4.86,
      5.487,
      31.338,
      7.32,
      5.487,
      31.338,
      7.32,
      4.473,
      31.808,
      7.32,
      4.473,
      31.808,
      4.86,
    ],
    "04-15 05:00": 1,
  },
  {
    ID: "4373.0",
    polygon: [
      30.247,
      5.708,
      0.05,
      31.195,
      3.373,
      0.05,
      31.195,
      3.373,
      2.78,
      31.195,
      3.373,
      2.78,
      30.247,
      5.708,
      2.78,
      30.247,
      5.708,
      0.05,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3609.0",
    polygon: [
      -16.528,
      29.04,
      18.233,
      -16.528,
      28.04,
      18.233,
      -17.736,
      28.04,
      18.17,
      -17.736,
      28.04,
      18.17,
      -17.736,
      29.04,
      18.17,
      -16.528,
      29.04,
      18.233,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3813.0",
    polygon: [
      13.504,
      6.378,
      25.86,
      14.704,
      6.378,
      25.86,
      14.704,
      6.378,
      28.32,
      14.704,
      6.378,
      28.32,
      13.504,
      6.378,
      28.32,
      13.504,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4374.0",
    polygon: [
      31.188,
      3.39,
      2.78,
      32.021,
      1.012,
      2.78,
      32.021,
      1.012,
      3.82,
      32.021,
      1.012,
      3.82,
      31.188,
      3.39,
      3.82,
      31.188,
      3.39,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4140.0",
    polygon: [
      7.173,
      30.495,
      8.36,
      8.256,
      29.912,
      8.36,
      8.256,
      29.912,
      10.82,
      8.256,
      29.912,
      10.82,
      7.173,
      30.495,
      10.82,
      7.173,
      30.495,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "3420.0",
    polygon: [
      -43.895,
      20.973,
      18.45,
      -43.895,
      19.973,
      18.45,
      -47.869,
      19.973,
      18.241,
      -47.869,
      19.973,
      18.241,
      -47.869,
      20.973,
      18.241,
      -43.895,
      20.973,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4278.0",
    polygon: [
      28.282,
      9.729,
      4.86,
      28.815,
      8.722,
      4.86,
      28.815,
      8.722,
      7.32,
      28.815,
      8.722,
      7.32,
      28.282,
      9.729,
      7.32,
      28.282,
      9.729,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "3361.0",
    polygon: [
      -54.383,
      25.735,
      18.088,
      -54.383,
      26.735,
      18.088,
      -53.175,
      26.735,
      18.025,
      -53.175,
      26.735,
      18.025,
      -53.175,
      25.735,
      18.025,
      -54.383,
      25.735,
      18.088,
    ],
    "04-15 07:30": 1,
  },
  {
    ID: "3610.0",
    polygon: [
      -17.906,
      29.04,
      18.161,
      -17.906,
      28.04,
      18.161,
      -19.114,
      28.04,
      18.098,
      -19.114,
      28.04,
      18.098,
      -19.114,
      29.04,
      18.098,
      -17.906,
      29.04,
      18.161,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3532.0",
    polygon: [
      -29.777,
      24.583,
      18.45,
      -29.777,
      25.583,
      18.45,
      -25.804,
      25.583,
      18.241,
      -25.804,
      25.583,
      18.241,
      -25.804,
      24.583,
      18.241,
      -29.777,
      24.583,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3362.0",
    polygon: [
      -61.277,
      29.193,
      18.45,
      -61.277,
      30.193,
      18.45,
      -57.304,
      30.193,
      18.241,
      -57.304,
      30.193,
      18.241,
      -57.304,
      29.193,
      18.241,
      -61.277,
      29.193,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3494.0",
    polygon: [
      -29.777,
      13.059,
      18.45,
      -29.777,
      14.059,
      18.45,
      -25.804,
      14.059,
      18.241,
      -25.804,
      14.059,
      18.241,
      -25.804,
      13.059,
      18.241,
      -29.777,
      13.059,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
  },
  {
    ID: "3421.0",
    polygon: [
      -48.024,
      20.973,
      18.233,
      -48.024,
      19.973,
      18.233,
      -51.998,
      19.973,
      18.025,
      -51.998,
      19.973,
      18.025,
      -51.998,
      20.973,
      18.025,
      -48.024,
      20.973,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3775.0",
    polygon: [
      4.039,
      6.378,
      22.36,
      5.239,
      6.378,
      22.36,
      5.239,
      6.378,
      24.82,
      5.239,
      6.378,
      24.82,
      4.039,
      6.378,
      24.82,
      4.039,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4180.0",
    polygon: [
      14.414,
      25.89,
      11.86,
      15.383,
      25.133,
      11.86,
      15.383,
      25.133,
      14.32,
      15.383,
      25.133,
      14.32,
      14.414,
      25.89,
      14.32,
      14.414,
      25.89,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4141.0",
    polygon: [
      8.247,
      29.917,
      8.36,
      9.316,
      29.307,
      8.36,
      9.316,
      29.307,
      10.82,
      9.316,
      29.307,
      10.82,
      8.247,
      29.917,
      10.82,
      8.247,
      29.917,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "4225.0",
    polygon: [
      5.611,
      31.279,
      4.86,
      6.612,
      30.784,
      4.86,
      6.612,
      30.784,
      7.32,
      6.612,
      30.784,
      7.32,
      5.611,
      31.279,
      7.32,
      5.611,
      31.279,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "3814.0",
    polygon: [
      17.56,
      6.378,
      25.86,
      18.76,
      6.378,
      25.86,
      18.76,
      6.378,
      28.32,
      18.76,
      6.378,
      28.32,
      17.56,
      6.378,
      28.32,
      17.56,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3611.0",
    polygon: [
      -19.29,
      29.04,
      18.088,
      -19.29,
      28.04,
      18.088,
      -20.498,
      28.04,
      18.025,
      -20.498,
      28.04,
      18.025,
      -20.498,
      29.04,
      18.025,
      -19.29,
      29.04,
      18.088,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3533.0",
    polygon: [
      -25.648,
      24.583,
      18.233,
      -25.648,
      25.583,
      18.233,
      -21.675,
      25.583,
      18.025,
      -21.675,
      25.583,
      18.025,
      -21.675,
      24.583,
      18.025,
      -25.648,
      24.583,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3422.0",
    polygon: [
      -43.895,
      19.821,
      18.45,
      -43.895,
      18.821,
      18.45,
      -47.869,
      18.821,
      18.241,
      -47.869,
      18.821,
      18.241,
      -47.869,
      19.821,
      18.241,
      -43.895,
      19.821,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4456.0",
    polygon: [
      37.333,
      -4.128,
      18.86,
      37.333,
      -5.328,
      18.86,
      37.333,
      -5.328,
      21.32,
      37.333,
      -5.328,
      21.32,
      37.333,
      -4.128,
      21.32,
      37.333,
      -4.128,
      18.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3777.0",
    polygon: [
      8.095,
      6.378,
      22.36,
      9.295,
      6.378,
      22.36,
      9.295,
      6.378,
      24.82,
      9.295,
      6.378,
      24.82,
      8.095,
      6.378,
      24.82,
      8.095,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4182.0",
    polygon: [
      16.643,
      24.091,
      11.86,
      17.569,
      23.28,
      11.86,
      17.569,
      23.28,
      14.32,
      17.569,
      23.28,
      14.32,
      16.643,
      24.091,
      14.32,
      16.643,
      24.091,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4143.0",
    polygon: [
      10.715,
      28.457,
      8.36,
      11.747,
      27.789,
      8.36,
      11.747,
      27.789,
      10.82,
      11.747,
      27.789,
      10.82,
      10.715,
      28.457,
      10.82,
      10.715,
      28.457,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4227.0",
    polygon: [
      8.476,
      29.789,
      4.86,
      9.444,
      29.232,
      4.86,
      9.444,
      29.232,
      7.32,
      9.444,
      29.232,
      7.32,
      8.476,
      29.789,
      7.32,
      8.476,
      29.789,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
  },
  {
    ID: "4281.0",
    polygon: [
      30.301,
      5.583,
      4.86,
      30.741,
      4.532,
      4.86,
      30.741,
      4.532,
      7.32,
      30.741,
      4.532,
      7.32,
      30.301,
      5.583,
      7.32,
      30.301,
      5.583,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4454.0",
    polygon: [
      37.333,
      -1.423,
      18.86,
      37.333,
      -2.623,
      18.86,
      37.333,
      -2.623,
      21.32,
      37.333,
      -2.623,
      21.32,
      37.333,
      -1.423,
      21.32,
      37.333,
      -1.423,
      18.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4279.0",
    polygon: [
      28.872,
      8.611,
      4.86,
      29.381,
      7.591,
      4.86,
      29.381,
      7.591,
      7.32,
      29.381,
      7.591,
      7.32,
      28.872,
      8.611,
      7.32,
      28.872,
      8.611,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4102.0",
    polygon: [
      1.96,
      32.86,
      15.36,
      3.102,
      32.402,
      15.36,
      3.102,
      32.402,
      17.82,
      3.102,
      32.402,
      17.82,
      1.96,
      32.86,
      17.82,
      1.96,
      32.86,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3853.0",
    polygon: [
      24.321,
      6.378,
      29.36,
      25.521,
      6.378,
      29.36,
      25.521,
      6.378,
      31.82,
      25.521,
      6.378,
      31.82,
      24.321,
      6.378,
      31.82,
      24.321,
      6.378,
      29.36,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "4015.0",
    polygon: [
      -35.63,
      38.497,
      8.36,
      -34.463,
      38.497,
      8.36,
      -34.463,
      38.497,
      10.82,
      -34.463,
      38.497,
      10.82,
      -35.63,
      38.497,
      10.82,
      -35.63,
      38.497,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3938.0",
    polygon: [
      -47.202,
      38.497,
      15.36,
      -46.035,
      38.497,
      15.36,
      -46.035,
      38.497,
      17.82,
      -46.035,
      38.497,
      17.82,
      -47.202,
      38.497,
      17.82,
      -47.202,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3854.0",
    polygon: [
      27.583,
      6.378,
      29.36,
      28.783,
      6.378,
      29.36,
      28.783,
      6.378,
      31.82,
      28.783,
      6.378,
      31.82,
      27.583,
      6.378,
      31.82,
      27.583,
      6.378,
      29.36,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "4226.0",
    polygon: [
      7.373,
      30.39,
      4.86,
      8.355,
      29.857,
      4.86,
      8.355,
      29.857,
      7.32,
      8.355,
      29.857,
      7.32,
      7.373,
      30.39,
      7.32,
      7.373,
      30.39,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
  },
  {
    ID: "3363.0",
    polygon: [
      -57.148,
      29.193,
      18.233,
      -57.148,
      30.193,
      18.233,
      -53.175,
      30.193,
      18.025,
      -53.175,
      30.193,
      18.025,
      -53.175,
      29.193,
      18.025,
      -57.148,
      29.193,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4376.0",
    polygon: [
      28.815,
      8.724,
      2.78,
      29.914,
      6.456,
      2.78,
      29.914,
      6.456,
      3.82,
      29.914,
      6.456,
      3.82,
      28.815,
      8.724,
      3.82,
      28.815,
      8.724,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3495.0",
    polygon: [
      -25.648,
      13.059,
      18.233,
      -25.648,
      14.059,
      18.233,
      -21.675,
      14.059,
      18.025,
      -21.675,
      14.059,
      18.025,
      -21.675,
      13.059,
      18.025,
      -25.648,
      13.059,
      18.233,
    ],
    "04-15 05:15": 1,
  },
  {
    ID: "4017.0",
    polygon: [
      -32.93,
      38.497,
      8.36,
      -31.763,
      38.497,
      8.36,
      -31.763,
      38.497,
      10.82,
      -31.763,
      38.497,
      10.82,
      -32.93,
      38.497,
      10.82,
      -32.93,
      38.497,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4142.0",
    polygon: [
      9.679,
      29.093,
      8.36,
      10.727,
      28.449,
      8.36,
      10.727,
      28.449,
      10.82,
      10.727,
      28.449,
      10.82,
      9.679,
      29.093,
      10.82,
      9.679,
      29.093,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "3778.0",
    polygon: [
      12.152,
      6.378,
      22.36,
      13.352,
      6.378,
      22.36,
      13.352,
      6.378,
      24.82,
      13.352,
      6.378,
      24.82,
      12.152,
      6.378,
      24.82,
      12.152,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4183.0",
    polygon: [
      17.55,
      23.297,
      11.86,
      18.456,
      22.465,
      11.86,
      18.456,
      22.465,
      14.32,
      18.456,
      22.465,
      14.32,
      17.55,
      23.297,
      14.32,
      17.55,
      23.297,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "4144.0",
    polygon: [
      12.072,
      27.571,
      8.36,
      13.083,
      26.87,
      8.36,
      13.083,
      26.87,
      10.82,
      13.083,
      26.87,
      10.82,
      12.072,
      27.571,
      10.82,
      12.072,
      27.571,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "4228.0",
    polygon: [
      9.552,
      29.168,
      4.86,
      10.507,
      28.587,
      4.86,
      10.507,
      28.587,
      7.32,
      10.507,
      28.587,
      7.32,
      9.552,
      29.168,
      7.32,
      9.552,
      29.168,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "3613.0",
    polygon: [
      -25.648,
      30.345,
      18.233,
      -25.648,
      30.845,
      18.233,
      -21.675,
      30.845,
      18.025,
      -21.675,
      30.845,
      18.025,
      -21.675,
      30.345,
      18.025,
      -25.648,
      30.345,
      18.233,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4181.0",
    polygon: [
      15.366,
      25.147,
      11.86,
      16.317,
      24.367,
      11.86,
      16.317,
      24.367,
      14.32,
      16.317,
      24.367,
      14.32,
      15.366,
      25.147,
      14.32,
      15.366,
      25.147,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "3939.0",
    polygon: [
      -48.369,
      38.497,
      15.36,
      -47.201,
      38.497,
      15.36,
      -47.201,
      38.497,
      17.82,
      -47.201,
      38.497,
      17.82,
      -48.369,
      38.497,
      17.82,
      -48.369,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4104.0",
    polygon: [
      4.589,
      31.756,
      15.36,
      5.703,
      31.234,
      15.36,
      5.703,
      31.234,
      17.82,
      5.703,
      31.234,
      17.82,
      4.589,
      31.756,
      17.82,
      4.589,
      31.756,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "3816.0",
    polygon: [
      14.856,
      6.378,
      25.86,
      16.056,
      6.378,
      25.86,
      16.056,
      6.378,
      28.32,
      16.056,
      6.378,
      28.32,
      14.856,
      6.378,
      28.32,
      14.856,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3855.0",
    polygon: [
      26.552,
      6.378,
      29.36,
      27.431,
      6.378,
      29.36,
      27.431,
      6.378,
      31.82,
      27.431,
      6.378,
      31.82,
      26.552,
      6.378,
      31.82,
      26.552,
      6.378,
      29.36,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3247.0",
    polygon: [
      -61.737,
      9.999,
      29.36,
      -61.737,
      8.648,
      29.36,
      -61.737,
      8.648,
      31.82,
      -61.737,
      8.648,
      31.82,
      -61.737,
      9.999,
      31.82,
      -61.737,
      9.999,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4377.0",
    polygon: [
      27.608,
      10.928,
      2.78,
      28.818,
      8.717,
      2.78,
      28.818,
      8.717,
      3.82,
      28.818,
      8.717,
      3.82,
      27.608,
      10.928,
      3.82,
      27.608,
      10.928,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3365.0",
    polygon: [
      -57.145,
      28.04,
      18.233,
      -57.145,
      29.04,
      18.233,
      -55.936,
      29.04,
      18.17,
      -55.936,
      29.04,
      18.17,
      -55.936,
      28.04,
      18.17,
      -57.145,
      28.04,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "4282.0",
    polygon: [
      30.786,
      4.42,
      4.86,
      31.2,
      3.358,
      4.86,
      31.2,
      3.358,
      7.32,
      31.2,
      3.358,
      7.32,
      30.786,
      4.42,
      7.32,
      30.786,
      4.42,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3940.0",
    polygon: [
      -74.53,
      38.499,
      15.36,
      -73.362,
      38.499,
      15.36,
      -73.362,
      38.499,
      17.82,
      -73.362,
      38.499,
      17.82,
      -74.53,
      38.499,
      17.82,
      -74.53,
      38.499,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3206.0",
    polygon: [
      -37.448,
      14.448,
      22.36,
      -36.626,
      14.448,
      22.36,
      -36.626,
      14.448,
      24.82,
      -36.626,
      14.448,
      24.82,
      -37.448,
      14.448,
      24.82,
      -37.448,
      14.448,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "4375.0",
    polygon: [
      30.247,
      5.708,
      2.78,
      31.195,
      3.373,
      2.78,
      31.195,
      3.373,
      3.82,
      31.195,
      3.373,
      3.82,
      30.247,
      5.708,
      3.82,
      30.247,
      5.708,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "4455.0",
    polygon: [
      37.333,
      -2.775,
      18.86,
      37.333,
      -3.975,
      18.86,
      37.333,
      -3.975,
      21.32,
      37.333,
      -3.975,
      21.32,
      37.333,
      -2.775,
      21.32,
      37.333,
      -2.775,
      18.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3776.0",
    polygon: [
      2.686,
      6.378,
      22.36,
      3.886,
      6.378,
      22.36,
      3.886,
      6.378,
      24.82,
      3.886,
      6.378,
      24.82,
      2.686,
      6.378,
      24.82,
      2.686,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3364.0",
    polygon: [
      -61.277,
      28.04,
      18.45,
      -61.277,
      29.04,
      18.45,
      -57.304,
      29.04,
      18.241,
      -57.304,
      29.04,
      18.241,
      -57.304,
      28.04,
      18.241,
      -61.277,
      28.04,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3612.0",
    polygon: [
      -29.777,
      30.345,
      18.45,
      -29.777,
      30.845,
      18.45,
      -25.804,
      30.845,
      18.241,
      -25.804,
      30.845,
      18.241,
      -25.804,
      30.345,
      18.241,
      -29.777,
      30.345,
      18.45,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3534.0",
    polygon: [
      -29.777,
      23.431,
      18.45,
      -29.777,
      24.431,
      18.45,
      -25.804,
      24.431,
      18.241,
      -25.804,
      24.431,
      18.241,
      -25.804,
      23.431,
      18.241,
      -29.777,
      23.431,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4280.0",
    polygon: [
      29.427,
      7.496,
      4.86,
      29.91,
      6.464,
      4.86,
      29.91,
      6.464,
      7.32,
      29.91,
      6.464,
      7.32,
      29.427,
      7.496,
      7.32,
      29.427,
      7.496,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4103.0",
    polygon: [
      3.099,
      32.403,
      15.36,
      4.229,
      31.917,
      15.36,
      4.229,
      31.917,
      17.82,
      4.229,
      31.917,
      17.82,
      3.099,
      32.403,
      17.82,
      3.099,
      32.403,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "3815.0",
    polygon: [
      16.208,
      6.378,
      25.86,
      17.408,
      6.378,
      25.86,
      17.408,
      6.378,
      28.32,
      17.408,
      6.378,
      28.32,
      16.208,
      6.378,
      28.32,
      16.208,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3246.0",
    polygon: [
      -61.737,
      11.148,
      29.36,
      -61.737,
      9.998,
      29.36,
      -61.737,
      9.998,
      31.82,
      -61.737,
      9.998,
      31.82,
      -61.737,
      11.148,
      31.82,
      -61.737,
      11.148,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "4378.0",
    polygon: [
      23.352,
      17.154,
      0.05,
      24.126,
      16.16,
      0.05,
      24.126,
      16.16,
      2.78,
      24.126,
      16.16,
      2.78,
      23.352,
      17.154,
      2.78,
      23.352,
      17.154,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "3207.0",
    polygon: [
      -36.626,
      14.448,
      22.36,
      -35.803,
      14.448,
      22.36,
      -35.803,
      14.448,
      24.82,
      -35.803,
      14.448,
      24.82,
      -36.626,
      14.448,
      24.82,
      -36.626,
      14.448,
      22.36,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3817.0",
    polygon: [
      18.912,
      6.378,
      25.86,
      20.112,
      6.378,
      25.86,
      20.112,
      6.378,
      28.32,
      20.112,
      6.378,
      28.32,
      18.912,
      6.378,
      28.32,
      18.912,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3248.0",
    polygon: [
      -61.737,
      8.648,
      29.36,
      -61.737,
      7.498,
      29.36,
      -61.737,
      7.498,
      31.82,
      -61.737,
      7.498,
      31.82,
      -61.737,
      8.648,
      31.82,
      -61.737,
      8.648,
      29.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "3285.0",
    polygon: [
      -11.626,
      9.998,
      22.36,
      -11.626,
      11.148,
      22.36,
      -11.626,
      11.148,
      24.82,
      -11.626,
      11.148,
      24.82,
      -11.626,
      9.998,
      24.82,
      -11.626,
      9.998,
      22.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4457.0",
    polygon: [
      37.333,
      -5.48,
      18.86,
      37.333,
      -6.68,
      18.86,
      37.333,
      -6.68,
      21.32,
      37.333,
      -6.68,
      21.32,
      37.333,
      -5.48,
      21.32,
      37.333,
      -5.48,
      18.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3614.0",
    polygon: [
      -12.395,
      30.845,
      18.45,
      -12.395,
      30.345,
      18.45,
      -16.369,
      30.345,
      18.241,
      -16.369,
      30.345,
      18.241,
      -16.369,
      30.845,
      18.241,
      -12.395,
      30.845,
      18.45,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "4105.0",
    polygon: [
      5.698,
      31.237,
      15.36,
      6.799,
      30.689,
      15.36,
      6.799,
      30.689,
      17.82,
      6.799,
      30.689,
      17.82,
      5.698,
      31.237,
      17.82,
      5.698,
      31.237,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
  },
  {
    ID: "4106.0",
    polygon: [
      7.173,
      30.495,
      15.36,
      8.256,
      29.912,
      15.36,
      8.256,
      29.912,
      17.82,
      8.256,
      29.912,
      17.82,
      7.173,
      30.495,
      17.82,
      7.173,
      30.495,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "3857.0",
    polygon: [
      -24.857,
      6.349,
      32.86,
      -23.657,
      6.349,
      32.86,
      -23.657,
      6.349,
      35.32,
      -23.657,
      6.349,
      35.32,
      -24.857,
      6.349,
      35.32,
      -24.857,
      6.349,
      32.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4019.0",
    polygon: [
      -74.53,
      38.499,
      8.36,
      -73.362,
      38.499,
      8.36,
      -73.362,
      38.499,
      10.82,
      -73.362,
      38.499,
      10.82,
      -74.53,
      38.499,
      10.82,
      -74.53,
      38.499,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4145.0",
    polygon: [
      13.069,
      26.88,
      8.36,
      14.063,
      26.155,
      8.36,
      14.063,
      26.155,
      10.82,
      14.063,
      26.155,
      10.82,
      13.069,
      26.88,
      10.82,
      13.069,
      26.88,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "4458.0",
    polygon: [
      37.333,
      -1.423,
      22.36,
      37.333,
      -2.623,
      22.36,
      37.333,
      -2.623,
      24.82,
      37.333,
      -2.623,
      24.82,
      37.333,
      -1.423,
      24.82,
      37.333,
      -1.423,
      22.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3779.0",
    polygon: [
      10.799,
      6.378,
      22.36,
      11.999,
      6.378,
      22.36,
      11.999,
      6.378,
      24.82,
      11.999,
      6.378,
      24.82,
      10.799,
      6.378,
      24.82,
      10.799,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4184.0",
    polygon: [
      18.771,
      22.166,
      11.86,
      19.649,
      21.305,
      11.86,
      19.649,
      21.305,
      14.32,
      19.649,
      21.305,
      14.32,
      18.771,
      22.166,
      14.32,
      18.771,
      22.166,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3818.0",
    polygon: [
      20.265,
      6.378,
      25.86,
      21.465,
      6.378,
      25.86,
      21.465,
      6.378,
      28.32,
      21.465,
      6.378,
      28.32,
      20.265,
      6.378,
      28.32,
      20.265,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3366.0",
    polygon: [
      -55.767,
      28.04,
      18.161,
      -55.767,
      29.04,
      18.161,
      -54.558,
      29.04,
      18.098,
      -54.558,
      29.04,
      18.098,
      -54.558,
      28.04,
      18.098,
      -55.767,
      28.04,
      18.161,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "3249.0",
    polygon: [
      -61.737,
      11.148,
      18.86,
      -61.737,
      9.998,
      18.86,
      -61.737,
      9.998,
      21.32,
      -61.737,
      9.998,
      21.32,
      -61.737,
      11.148,
      21.32,
      -61.737,
      11.148,
      18.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4229.0",
    polygon: [
      10.61,
      28.522,
      4.86,
      11.55,
      27.919,
      4.86,
      11.55,
      27.919,
      7.32,
      11.55,
      27.919,
      7.32,
      10.61,
      28.522,
      7.32,
      10.61,
      28.522,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "3941.0",
    polygon: [
      -73.364,
      38.499,
      15.36,
      -72.196,
      38.499,
      15.36,
      -72.196,
      38.499,
      17.82,
      -72.196,
      38.499,
      17.82,
      -73.364,
      38.499,
      17.82,
      -73.364,
      38.499,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4283.0",
    polygon: [
      31.24,
      3.253,
      4.86,
      31.628,
      2.181,
      4.86,
      31.628,
      2.181,
      7.32,
      31.628,
      2.181,
      7.32,
      31.24,
      3.253,
      7.32,
      31.24,
      3.253,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "3819.0",
    polygon: [
      21.617,
      6.378,
      25.86,
      22.817,
      6.378,
      25.86,
      22.817,
      6.378,
      28.32,
      22.817,
      6.378,
      28.32,
      21.617,
      6.378,
      28.32,
      21.617,
      6.378,
      25.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4380.0",
    polygon: [
      37.322,
      -5.479,
      36.36,
      37.322,
      -6.679,
      36.36,
      37.322,
      -6.679,
      39.128,
      37.322,
      -6.679,
      39.128,
      37.322,
      -5.479,
      39.128,
      37.322,
      -5.479,
      36.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4459.0",
    polygon: [
      37.333,
      -2.775,
      22.36,
      37.333,
      -3.975,
      22.36,
      37.333,
      -3.975,
      24.82,
      37.333,
      -3.975,
      24.82,
      37.333,
      -2.775,
      24.82,
      37.333,
      -2.775,
      22.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3741.0",
    polygon: [
      6.743,
      6.378,
      18.86,
      7.943,
      6.378,
      18.86,
      7.943,
      6.378,
      21.32,
      7.943,
      6.378,
      21.32,
      6.743,
      6.378,
      21.32,
      6.743,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3780.0",
    polygon: [
      9.447,
      6.378,
      22.36,
      10.647,
      6.378,
      22.36,
      10.647,
      6.378,
      24.82,
      10.647,
      6.378,
      24.82,
      9.447,
      6.378,
      24.82,
      9.447,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3172.0",
    polygon: [
      -42.837,
      14.657,
      25.86,
      -42.837,
      15.437,
      25.86,
      -42.837,
      15.437,
      28.32,
      -42.837,
      15.437,
      28.32,
      -42.837,
      14.657,
      28.32,
      -42.837,
      14.657,
      25.86,
    ],
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3615.0",
    polygon: [
      -16.524,
      30.845,
      18.233,
      -16.524,
      30.345,
      18.233,
      -20.498,
      30.345,
      18.025,
      -20.498,
      30.345,
      18.025,
      -20.498,
      30.845,
      18.025,
      -16.524,
      30.845,
      18.233,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "3576.0",
    polygon: [
      -12.395,
      18.669,
      18.45,
      -12.395,
      17.669,
      18.45,
      -16.369,
      17.669,
      18.241,
      -16.369,
      17.669,
      18.241,
      -16.369,
      18.669,
      18.241,
      -12.395,
      18.669,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3537.0",
    polygon: [
      -22.883,
      23.431,
      18.088,
      -22.883,
      24.431,
      18.088,
      -21.675,
      24.431,
      18.025,
      -21.675,
      24.431,
      18.025,
      -21.675,
      23.431,
      18.025,
      -22.883,
      23.431,
      18.088,
    ],
    "04-15 07:30": 1,
  },
  {
    ID: "4284.0",
    polygon: [
      31.657,
      2.097,
      4.86,
      32.019,
      1.016,
      4.86,
      32.019,
      1.016,
      7.32,
      32.019,
      1.016,
      7.32,
      31.657,
      2.097,
      7.32,
      31.657,
      2.097,
      4.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "4230.0",
    polygon: [
      12.289,
      27.423,
      4.86,
      13.204,
      26.783,
      4.86,
      13.204,
      26.783,
      7.32,
      13.204,
      26.783,
      7.32,
      12.289,
      27.423,
      7.32,
      12.289,
      27.423,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "3367.0",
    polygon: [
      -54.383,
      28.04,
      18.088,
      -54.383,
      29.04,
      18.088,
      -53.175,
      29.04,
      18.025,
      -53.175,
      29.04,
      18.025,
      -53.175,
      28.04,
      18.025,
      -54.383,
      28.04,
      18.088,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "3942.0",
    polygon: [
      -71.83,
      38.499,
      15.36,
      -70.662,
      38.499,
      15.36,
      -70.662,
      38.499,
      17.82,
      -70.662,
      38.499,
      17.82,
      -71.83,
      38.499,
      17.82,
      -71.83,
      38.499,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3426.0",
    polygon: [
      -43.895,
      23.278,
      18.45,
      -43.895,
      22.278,
      18.45,
      -47.869,
      22.278,
      18.241,
      -47.869,
      22.278,
      18.241,
      -47.869,
      23.278,
      18.241,
      -43.895,
      23.278,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4107.0",
    polygon: [
      8.247,
      29.917,
      15.36,
      9.316,
      29.307,
      15.36,
      9.316,
      29.307,
      17.82,
      9.316,
      29.307,
      17.82,
      8.247,
      29.917,
      17.82,
      8.247,
      29.917,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "3858.0",
    polygon: [
      -26.209,
      6.349,
      32.86,
      -25.009,
      6.349,
      32.86,
      -25.009,
      6.349,
      35.32,
      -25.009,
      6.349,
      35.32,
      -26.209,
      6.349,
      35.32,
      -26.209,
      6.349,
      32.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4185.0",
    polygon: [
      19.63,
      21.324,
      11.86,
      20.487,
      20.443,
      11.86,
      20.487,
      20.443,
      14.32,
      20.487,
      20.443,
      14.32,
      19.63,
      21.324,
      14.32,
      19.63,
      21.324,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "4146.0",
    polygon: [
      14.414,
      25.89,
      8.36,
      15.383,
      25.133,
      8.36,
      15.383,
      25.133,
      10.82,
      15.383,
      25.133,
      10.82,
      14.414,
      25.89,
      10.82,
      14.414,
      25.89,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "3859.0",
    polygon: [
      -27.561,
      6.349,
      32.86,
      -26.361,
      6.349,
      32.86,
      -26.361,
      6.349,
      35.32,
      -26.361,
      6.349,
      35.32,
      -27.561,
      6.349,
      35.32,
      -27.561,
      6.349,
      32.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4231.0",
    polygon: [
      13.309,
      26.708,
      4.86,
      14.208,
      26.046,
      4.86,
      14.208,
      26.046,
      7.32,
      14.208,
      26.046,
      7.32,
      13.309,
      26.708,
      7.32,
      13.309,
      26.708,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4147.0",
    polygon: [
      15.366,
      25.147,
      8.36,
      16.317,
      24.367,
      8.36,
      16.317,
      24.367,
      10.82,
      16.317,
      24.367,
      10.82,
      15.366,
      25.147,
      10.82,
      15.366,
      25.147,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "3943.0",
    polygon: [
      -70.664,
      38.499,
      15.36,
      -69.496,
      38.499,
      15.36,
      -69.496,
      38.499,
      17.82,
      -69.496,
      38.499,
      17.82,
      -70.664,
      38.499,
      17.82,
      -70.664,
      38.499,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4289.0",
    polygon: [
      22.557,
      18.125,
      2.78,
      24.129,
      16.156,
      2.78,
      24.129,
      16.156,
      3.82,
      24.129,
      16.156,
      3.82,
      22.557,
      18.125,
      3.82,
      22.557,
      18.125,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "4108.0",
    polygon: [
      9.679,
      29.093,
      15.36,
      10.727,
      28.449,
      15.36,
      10.727,
      28.449,
      17.82,
      10.727,
      28.449,
      17.82,
      9.679,
      29.093,
      17.82,
      9.679,
      29.093,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "3577.0",
    polygon: [
      -16.524,
      18.669,
      18.233,
      -16.524,
      17.669,
      18.233,
      -20.498,
      17.669,
      18.025,
      -20.498,
      17.669,
      18.025,
      -20.498,
      18.669,
      18.025,
      -16.524,
      18.669,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4381.0",
    polygon: [
      37.322,
      -5.479,
      39.128,
      37.322,
      -6.679,
      39.128,
      37.322,
      -6.679,
      40.05,
      37.322,
      -6.679,
      40.05,
      37.322,
      -5.479,
      40.05,
      37.322,
      -5.479,
      39.128,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "4460.0",
    polygon: [
      37.333,
      -4.128,
      22.36,
      37.333,
      -5.328,
      22.36,
      37.333,
      -5.328,
      24.82,
      37.333,
      -5.328,
      24.82,
      37.333,
      -4.128,
      24.82,
      37.333,
      -4.128,
      22.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4186.0",
    polygon: [
      20.785,
      20.127,
      11.86,
      21.613,
      19.217,
      11.86,
      21.613,
      19.217,
      14.32,
      21.613,
      19.217,
      14.32,
      20.785,
      20.127,
      14.32,
      20.785,
      20.127,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "3427.0",
    polygon: [
      -48.024,
      23.278,
      18.233,
      -48.024,
      22.278,
      18.233,
      -51.998,
      22.278,
      18.025,
      -51.998,
      22.278,
      18.025,
      -51.998,
      23.278,
      18.025,
      -48.024,
      23.278,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4021.0",
    polygon: [
      -71.83,
      38.499,
      8.36,
      -70.662,
      38.499,
      8.36,
      -70.662,
      38.499,
      10.82,
      -70.662,
      38.499,
      10.82,
      -71.83,
      38.499,
      10.82,
      -71.83,
      38.499,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3742.0",
    polygon: [
      5.391,
      6.378,
      18.86,
      6.591,
      6.378,
      18.86,
      6.591,
      6.378,
      21.32,
      6.591,
      6.378,
      21.32,
      5.391,
      6.378,
      21.32,
      5.391,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3173.0",
    polygon: [
      -30.501,
      15.425,
      25.86,
      -30.501,
      14.645,
      25.86,
      -30.501,
      14.645,
      28.32,
      -30.501,
      14.645,
      28.32,
      -30.501,
      15.425,
      28.32,
      -30.501,
      15.425,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "3781.0",
    polygon: [
      13.504,
      6.378,
      22.36,
      14.704,
      6.378,
      22.36,
      14.704,
      6.378,
      24.82,
      14.704,
      6.378,
      24.82,
      13.504,
      6.378,
      24.82,
      13.504,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3538.0",
    polygon: [
      -29.777,
      26.888,
      18.45,
      -29.777,
      27.888,
      18.45,
      -25.804,
      27.888,
      18.241,
      -25.804,
      27.888,
      18.241,
      -25.804,
      26.888,
      18.241,
      -29.777,
      26.888,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3388.0",
    polygon: [
      -43.895,
      16.364,
      18.45,
      -43.895,
      15.364,
      18.45,
      -47.869,
      15.364,
      18.241,
      -47.869,
      15.364,
      18.241,
      -47.869,
      16.364,
      18.241,
      -43.895,
      16.364,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4109.0",
    polygon: [
      10.715,
      28.457,
      15.36,
      11.747,
      27.789,
      15.36,
      11.747,
      27.789,
      17.82,
      11.747,
      27.789,
      17.82,
      10.715,
      28.457,
      17.82,
      10.715,
      28.457,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4232.0",
    polygon: [
      14.298,
      25.978,
      4.86,
      15.181,
      25.294,
      4.86,
      15.181,
      25.294,
      7.32,
      15.181,
      25.294,
      7.32,
      14.298,
      25.978,
      7.32,
      14.298,
      25.978,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "3578.0",
    polygon: [
      -12.395,
      17.516,
      18.45,
      -12.395,
      16.516,
      18.45,
      -16.369,
      16.516,
      18.241,
      -16.369,
      16.516,
      18.241,
      -16.369,
      17.516,
      18.241,
      -12.395,
      17.516,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3291.0",
    polygon: [
      -11.626,
      9.998,
      29.36,
      -11.626,
      11.148,
      29.36,
      -11.626,
      11.148,
      31.82,
      -11.626,
      11.148,
      31.82,
      -11.626,
      9.998,
      31.82,
      -11.626,
      9.998,
      29.36,
    ],
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3252.0",
    polygon: [
      -61.737,
      11.148,
      22.36,
      -61.737,
      9.998,
      22.36,
      -61.737,
      9.998,
      24.82,
      -61.737,
      9.998,
      24.82,
      -61.737,
      11.148,
      24.82,
      -61.737,
      11.148,
      22.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4187.0",
    polygon: [
      21.592,
      19.241,
      11.86,
      22.399,
      18.313,
      11.86,
      22.399,
      18.313,
      14.32,
      22.399,
      18.313,
      14.32,
      21.592,
      19.241,
      14.32,
      21.592,
      19.241,
      11.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "4148.0",
    polygon: [
      16.643,
      24.091,
      8.36,
      17.569,
      23.28,
      8.36,
      17.569,
      23.28,
      10.82,
      17.569,
      23.28,
      10.82,
      16.643,
      24.091,
      10.82,
      16.643,
      24.091,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4292.0",
    polygon: [
      20.906,
      19.997,
      2.78,
      22.572,
      18.107,
      2.78,
      22.572,
      18.107,
      3.82,
      22.572,
      18.107,
      3.82,
      20.906,
      19.997,
      3.82,
      20.906,
      19.997,
      2.78,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "3743.0",
    polygon: [
      4.039,
      6.378,
      18.86,
      5.239,
      6.378,
      18.86,
      5.239,
      6.378,
      21.32,
      5.239,
      6.378,
      21.32,
      4.039,
      6.378,
      21.32,
      4.039,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4301.0",
    polygon: [
      20.905,
      19.997,
      0.05,
      22.572,
      18.107,
      0.05,
      22.572,
      18.107,
      2.78,
      22.572,
      18.107,
      2.78,
      20.905,
      19.997,
      2.78,
      20.905,
      19.997,
      0.05,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "3175.0",
    polygon: [
      -5.076,
      15.448,
      25.86,
      -4.253,
      15.448,
      25.86,
      -4.253,
      15.448,
      28.32,
      -4.253,
      15.448,
      28.32,
      -5.076,
      15.448,
      28.32,
      -5.076,
      15.448,
      25.86,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4382.0",
    polygon: [
      37.333,
      -1.423,
      32.86,
      37.333,
      -2.623,
      32.86,
      37.333,
      -2.623,
      35.32,
      37.333,
      -2.623,
      35.32,
      37.333,
      -1.423,
      35.32,
      37.333,
      -1.423,
      32.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4149.0",
    polygon: [
      17.55,
      23.297,
      8.36,
      18.456,
      22.465,
      8.36,
      18.456,
      22.465,
      10.82,
      18.456,
      22.465,
      10.82,
      17.55,
      23.297,
      10.82,
      17.55,
      23.297,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "3945.0",
    polygon: [
      -67.23,
      38.499,
      15.36,
      -66.062,
      38.499,
      15.36,
      -66.062,
      38.499,
      17.82,
      -66.062,
      38.499,
      17.82,
      -67.23,
      38.499,
      17.82,
      -67.23,
      38.499,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3428.0",
    polygon: [
      -43.895,
      22.126,
      18.45,
      -43.895,
      21.126,
      18.45,
      -47.869,
      21.126,
      18.241,
      -47.869,
      21.126,
      18.241,
      -47.869,
      22.126,
      18.241,
      -43.895,
      22.126,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 07:00": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3782.0",
    polygon: [
      17.56,
      6.378,
      22.36,
      18.76,
      6.378,
      22.36,
      18.76,
      6.378,
      24.82,
      18.76,
      6.378,
      24.82,
      17.56,
      6.378,
      24.82,
      17.56,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4233.0",
    polygon: [
      15.264,
      25.228,
      4.86,
      16.131,
      24.523,
      4.86,
      16.131,
      24.523,
      7.32,
      16.131,
      24.523,
      7.32,
      15.264,
      25.228,
      7.32,
      15.264,
      25.228,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "3174.0",
    polygon: [
      -5.898,
      15.448,
      25.86,
      -5.076,
      15.448,
      25.86,
      -5.076,
      15.448,
      28.32,
      -5.076,
      15.448,
      28.32,
      -5.898,
      15.448,
      28.32,
      -5.898,
      15.448,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "3136.0",
    polygon: [
      -42.837,
      14.657,
      29.36,
      -42.837,
      15.437,
      29.36,
      -42.837,
      15.437,
      31.82,
      -42.837,
      15.437,
      31.82,
      -42.837,
      14.657,
      31.82,
      -42.837,
      14.657,
      29.36,
    ],
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4462.0",
    polygon: [
      37.333,
      -1.423,
      25.86,
      37.333,
      -2.623,
      25.86,
      37.333,
      -2.623,
      28.32,
      37.333,
      -2.623,
      28.32,
      37.333,
      -1.423,
      28.32,
      37.333,
      -1.423,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4383.0",
    polygon: [
      37.322,
      -4.127,
      36.36,
      37.322,
      -5.327,
      36.36,
      37.322,
      -5.327,
      39.128,
      37.322,
      -5.327,
      39.128,
      37.322,
      -4.127,
      39.128,
      37.322,
      -4.127,
      36.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3861.0",
    polygon: [
      -23.505,
      6.349,
      32.86,
      -22.305,
      6.349,
      32.86,
      -22.305,
      6.349,
      35.32,
      -22.305,
      6.349,
      35.32,
      -23.505,
      6.349,
      35.32,
      -23.505,
      6.349,
      32.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3783.0",
    polygon: [
      16.208,
      6.378,
      22.36,
      17.408,
      6.378,
      22.36,
      17.408,
      6.378,
      24.82,
      17.408,
      6.378,
      24.82,
      16.208,
      6.378,
      24.82,
      16.208,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3744.0",
    polygon: [
      2.686,
      6.378,
      18.86,
      3.886,
      6.378,
      18.86,
      3.886,
      6.378,
      21.32,
      3.886,
      6.378,
      21.32,
      2.686,
      6.378,
      21.32,
      2.686,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3540.0",
    polygon: [
      -29.777,
      25.735,
      18.45,
      -29.777,
      26.735,
      18.45,
      -25.804,
      26.735,
      18.241,
      -25.804,
      26.735,
      18.241,
      -25.804,
      25.735,
      18.241,
      -29.777,
      25.735,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 14:15": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4110.0",
    polygon: [
      12.072,
      27.571,
      15.36,
      13.083,
      26.87,
      15.36,
      13.083,
      26.87,
      17.82,
      13.083,
      26.87,
      17.82,
      12.072,
      27.571,
      17.82,
      12.072,
      27.571,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "3389.0",
    polygon: [
      -48.024,
      16.364,
      18.233,
      -48.024,
      15.364,
      18.233,
      -51.998,
      15.364,
      18.025,
      -51.998,
      15.364,
      18.025,
      -51.998,
      16.364,
      18.025,
      -48.024,
      16.364,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "4188.0",
    polygon: [
      22.683,
      17.975,
      11.86,
      23.458,
      17.02,
      11.86,
      23.458,
      17.02,
      14.32,
      23.458,
      17.02,
      14.32,
      22.683,
      17.975,
      14.32,
      22.683,
      17.975,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "3292.0",
    polygon: [
      -11.626,
      8.648,
      29.36,
      -11.626,
      9.999,
      29.36,
      -11.626,
      9.999,
      31.82,
      -11.626,
      9.999,
      31.82,
      -11.626,
      8.648,
      31.82,
      -11.626,
      8.648,
      29.36,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4461.0",
    polygon: [
      37.333,
      -5.48,
      22.36,
      37.333,
      -6.68,
      22.36,
      37.333,
      -6.68,
      24.82,
      37.333,
      -6.68,
      24.82,
      37.333,
      -5.48,
      24.82,
      37.333,
      -5.48,
      22.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3390.0",
    polygon: [
      -43.895,
      15.211,
      18.45,
      -43.895,
      14.211,
      18.45,
      -47.869,
      14.211,
      18.241,
      -47.869,
      14.211,
      18.241,
      -47.869,
      15.211,
      18.241,
      -43.895,
      15.211,
      18.45,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3541.0",
    polygon: [
      -25.645,
      25.735,
      18.233,
      -25.645,
      26.735,
      18.233,
      -24.436,
      26.735,
      18.17,
      -24.436,
      26.735,
      18.17,
      -24.436,
      25.735,
      18.17,
      -25.645,
      25.735,
      18.233,
    ],
    "04-15 07:30": 1,
  },
  {
    ID: "3946.0",
    polygon: [
      -66.064,
      38.499,
      15.36,
      -64.896,
      38.499,
      15.36,
      -64.896,
      38.499,
      17.82,
      -64.896,
      38.499,
      17.82,
      -66.064,
      38.499,
      17.82,
      -66.064,
      38.499,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3862.0",
    polygon: [
      -19.448,
      6.349,
      32.86,
      -18.248,
      6.349,
      32.86,
      -18.248,
      6.349,
      35.32,
      -18.248,
      6.349,
      35.32,
      -19.448,
      6.349,
      35.32,
      -19.448,
      6.349,
      32.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4111.0",
    polygon: [
      13.069,
      26.88,
      15.36,
      14.063,
      26.155,
      15.36,
      14.063,
      26.155,
      17.82,
      14.063,
      26.155,
      17.82,
      13.069,
      26.88,
      17.82,
      13.069,
      26.88,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "4024.0",
    polygon: [
      -67.23,
      38.499,
      8.36,
      -66.062,
      38.499,
      8.36,
      -66.062,
      38.499,
      10.82,
      -66.062,
      38.499,
      10.82,
      -67.23,
      38.499,
      10.82,
      -67.23,
      38.499,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3903.0",
    polygon: [
      -0.204,
      38.498,
      15.36,
      0.964,
      38.498,
      15.36,
      0.964,
      38.498,
      17.82,
      0.964,
      38.498,
      17.82,
      -0.204,
      38.498,
      17.82,
      -0.204,
      38.498,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3947.0",
    polygon: [
      -64.53,
      38.499,
      15.36,
      -63.362,
      38.499,
      15.36,
      -63.362,
      38.499,
      17.82,
      -63.362,
      38.499,
      17.82,
      -64.53,
      38.499,
      17.82,
      -64.53,
      38.499,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3332.0",
    polygon: [
      -61.277,
      17.669,
      18.45,
      -61.277,
      18.669,
      18.45,
      -57.304,
      18.669,
      18.241,
      -57.304,
      18.669,
      18.241,
      -57.304,
      17.669,
      18.241,
      -61.277,
      17.669,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3784.0",
    polygon: [
      14.856,
      6.378,
      22.36,
      16.056,
      6.378,
      22.36,
      16.056,
      6.378,
      24.82,
      16.056,
      6.378,
      24.82,
      14.856,
      6.378,
      24.82,
      14.856,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3745.0",
    polygon: [
      8.095,
      6.378,
      18.86,
      9.295,
      6.378,
      18.86,
      9.295,
      6.378,
      21.32,
      9.295,
      6.378,
      21.32,
      8.095,
      6.378,
      21.32,
      8.095,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4189.0",
    polygon: [
      23.437,
      17.047,
      11.86,
      24.19,
      16.075,
      11.86,
      24.19,
      16.075,
      14.32,
      24.19,
      16.075,
      14.32,
      23.437,
      17.047,
      14.32,
      23.437,
      17.047,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "3542.0",
    polygon: [
      -24.267,
      25.735,
      18.161,
      -24.267,
      26.735,
      18.161,
      -23.058,
      26.735,
      18.098,
      -23.058,
      26.735,
      18.098,
      -23.058,
      25.735,
      18.098,
      -24.267,
      25.735,
      18.161,
    ],
    "04-15 07:30": 1,
  },
  {
    ID: "3293.0",
    polygon: [
      -11.626,
      7.498,
      29.36,
      -11.626,
      8.648,
      29.36,
      -11.626,
      8.648,
      31.82,
      -11.626,
      8.648,
      31.82,
      -11.626,
      7.498,
      31.82,
      -11.626,
      7.498,
      29.36,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4303.0",
    polygon: [
      30.257,
      5.743,
      18.86,
      30.735,
      4.61,
      18.86,
      30.735,
      4.61,
      21.32,
      30.735,
      4.61,
      21.32,
      30.257,
      5.743,
      21.32,
      30.257,
      5.743,
      18.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "4150.0",
    polygon: [
      18.771,
      22.166,
      8.36,
      19.649,
      21.305,
      8.36,
      19.649,
      21.305,
      10.82,
      19.649,
      21.305,
      10.82,
      18.771,
      22.166,
      10.82,
      18.771,
      22.166,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4234.0",
    polygon: [
      16.848,
      23.914,
      4.86,
      17.686,
      23.175,
      4.86,
      17.686,
      23.175,
      7.32,
      17.686,
      23.175,
      7.32,
      16.848,
      23.914,
      7.32,
      16.848,
      23.914,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "4190.0",
    polygon: [
      24.452,
      15.726,
      11.86,
      25.173,
      14.729,
      11.86,
      25.173,
      14.729,
      14.32,
      25.173,
      14.729,
      14.32,
      24.452,
      15.726,
      14.32,
      24.452,
      15.726,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "4304.0",
    polygon: [
      30.713,
      4.665,
      18.86,
      31.165,
      3.522,
      18.86,
      31.165,
      3.522,
      21.32,
      31.165,
      3.522,
      21.32,
      30.713,
      4.665,
      21.32,
      30.713,
      4.665,
      18.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4112.0",
    polygon: [
      14.414,
      25.89,
      15.36,
      15.383,
      25.133,
      15.36,
      15.383,
      25.133,
      17.82,
      15.383,
      25.133,
      17.82,
      14.414,
      25.89,
      17.82,
      14.414,
      25.89,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "3137.0",
    polygon: [
      -30.501,
      15.425,
      29.36,
      -30.501,
      14.645,
      29.36,
      -30.501,
      14.645,
      31.82,
      -30.501,
      14.645,
      31.82,
      -30.501,
      15.425,
      31.82,
      -30.501,
      15.425,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "4151.0",
    polygon: [
      19.63,
      21.324,
      8.36,
      20.487,
      20.443,
      8.36,
      20.487,
      20.443,
      10.82,
      20.487,
      20.443,
      10.82,
      19.63,
      21.324,
      10.82,
      19.63,
      21.324,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "3904.0",
    polygon: [
      -1.37,
      38.498,
      15.36,
      -0.203,
      38.498,
      15.36,
      -0.203,
      38.498,
      17.82,
      -0.203,
      38.498,
      17.82,
      -1.37,
      38.498,
      17.82,
      -1.37,
      38.498,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4384.0",
    polygon: [
      37.322,
      -4.127,
      39.128,
      37.322,
      -5.327,
      39.128,
      37.322,
      -5.327,
      40.05,
      37.322,
      -5.327,
      40.05,
      37.322,
      -4.127,
      40.05,
      37.322,
      -4.127,
      39.128,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "4235.0",
    polygon: [
      17.774,
      23.095,
      4.86,
      18.593,
      22.336,
      4.86,
      18.593,
      22.336,
      7.32,
      18.593,
      22.336,
      7.32,
      17.774,
      23.095,
      7.32,
      17.774,
      23.095,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "4463.0",
    polygon: [
      37.333,
      -2.775,
      25.86,
      37.333,
      -3.975,
      25.86,
      37.333,
      -3.975,
      28.32,
      37.333,
      -3.975,
      28.32,
      37.333,
      -2.775,
      28.32,
      37.333,
      -2.775,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4152.0",
    polygon: [
      20.785,
      20.127,
      8.36,
      21.613,
      19.217,
      8.36,
      21.613,
      19.217,
      10.82,
      21.613,
      19.217,
      10.82,
      20.785,
      20.127,
      10.82,
      20.785,
      20.127,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "3216.0",
    polygon: [
      -37.448,
      14.448,
      18.86,
      -36.626,
      14.448,
      18.86,
      -36.626,
      14.448,
      21.32,
      -36.626,
      14.448,
      21.32,
      -37.448,
      14.448,
      21.32,
      -37.448,
      14.448,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "3906.0",
    polygon: [
      -4.07,
      38.498,
      15.36,
      -2.903,
      38.498,
      15.36,
      -2.903,
      38.498,
      17.82,
      -2.903,
      38.498,
      17.82,
      -4.07,
      38.498,
      17.82,
      -4.07,
      38.498,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3138.0",
    polygon: [
      -5.898,
      15.448,
      29.36,
      -5.076,
      15.448,
      29.36,
      -5.076,
      15.448,
      31.82,
      -5.076,
      15.448,
      31.82,
      -5.898,
      15.448,
      31.82,
      -5.898,
      15.448,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "4385.0",
    polygon: [
      37.322,
      -2.775,
      36.36,
      37.322,
      -3.975,
      36.36,
      37.322,
      -3.975,
      39.128,
      37.322,
      -3.975,
      39.128,
      37.322,
      -2.775,
      39.128,
      37.322,
      -2.775,
      36.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4236.0",
    polygon: [
      18.665,
      22.267,
      4.86,
      19.465,
      21.488,
      4.86,
      19.465,
      21.488,
      7.32,
      19.465,
      21.488,
      7.32,
      18.665,
      22.267,
      7.32,
      18.665,
      22.267,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "4464.0",
    polygon: [
      37.333,
      -4.128,
      25.86,
      37.333,
      -5.328,
      25.86,
      37.333,
      -5.328,
      28.32,
      37.333,
      -5.328,
      28.32,
      37.333,
      -4.128,
      28.32,
      37.333,
      -4.128,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3948.0",
    polygon: [
      -63.364,
      38.499,
      15.36,
      -62.196,
      38.499,
      15.36,
      -62.196,
      38.499,
      17.82,
      -62.196,
      38.499,
      17.82,
      -63.364,
      38.499,
      17.82,
      -63.364,
      38.499,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3334.0",
    polygon: [
      -61.277,
      16.516,
      18.45,
      -61.277,
      17.516,
      18.45,
      -57.304,
      17.516,
      18.241,
      -57.304,
      17.516,
      18.241,
      -57.304,
      16.516,
      18.241,
      -61.277,
      16.516,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3746.0",
    polygon: [
      12.152,
      6.378,
      18.86,
      13.352,
      6.378,
      18.86,
      13.352,
      6.378,
      21.32,
      13.352,
      6.378,
      21.32,
      12.152,
      6.378,
      21.32,
      12.152,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4113.0",
    polygon: [
      15.366,
      25.147,
      15.36,
      16.317,
      24.367,
      15.36,
      16.317,
      24.367,
      17.82,
      16.317,
      24.367,
      17.82,
      15.366,
      25.147,
      17.82,
      15.366,
      25.147,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "3583.0",
    polygon: [
      -16.524,
      20.973,
      18.233,
      -16.524,
      19.973,
      18.233,
      -20.498,
      19.973,
      18.025,
      -20.498,
      19.973,
      18.025,
      -20.498,
      20.973,
      18.025,
      -16.524,
      20.973,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 16:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3544.0",
    polygon: [
      -29.777,
      29.193,
      18.45,
      -29.777,
      30.193,
      18.45,
      -25.804,
      30.193,
      18.241,
      -25.804,
      30.193,
      18.241,
      -25.804,
      29.193,
      18.241,
      -29.777,
      29.193,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3785.0",
    polygon: [
      18.912,
      6.378,
      22.36,
      20.112,
      6.378,
      22.36,
      20.112,
      6.378,
      24.82,
      20.112,
      6.378,
      24.82,
      18.912,
      6.378,
      24.82,
      18.912,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3432.0",
    polygon: [
      -43.895,
      25.583,
      18.45,
      -43.895,
      24.583,
      18.45,
      -47.869,
      24.583,
      18.241,
      -47.869,
      24.583,
      18.241,
      -47.869,
      25.583,
      18.241,
      -43.895,
      25.583,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3295.0",
    polygon: [
      1.473,
      9.999,
      29.36,
      1.473,
      8.648,
      29.36,
      1.473,
      8.648,
      31.82,
      1.473,
      8.648,
      31.82,
      1.473,
      9.999,
      31.82,
      1.473,
      9.999,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "3217.0",
    polygon: [
      -36.626,
      14.448,
      18.86,
      -35.803,
      14.448,
      18.86,
      -35.803,
      14.448,
      21.32,
      -35.803,
      14.448,
      21.32,
      -36.626,
      14.448,
      21.32,
      -36.626,
      14.448,
      18.86,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "3139.0",
    polygon: [
      -5.076,
      15.448,
      29.36,
      -4.253,
      15.448,
      29.36,
      -4.253,
      15.448,
      31.82,
      -4.253,
      15.448,
      31.82,
      -5.076,
      15.448,
      31.82,
      -5.076,
      15.448,
      29.36,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
  {
    ID: "4386.0",
    polygon: [
      37.322,
      -2.775,
      39.128,
      37.322,
      -3.975,
      39.128,
      37.322,
      -3.975,
      40.05,
      37.322,
      -3.975,
      40.05,
      37.322,
      -2.775,
      40.05,
      37.322,
      -2.775,
      39.128,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "3543.0",
    polygon: [
      -22.883,
      25.735,
      18.088,
      -22.883,
      26.735,
      18.088,
      -21.675,
      26.735,
      18.025,
      -21.675,
      26.735,
      18.025,
      -21.675,
      25.735,
      18.025,
      -22.883,
      25.735,
      18.088,
    ],
    "04-15 07:30": 1,
  },
  {
    ID: "4305.0",
    polygon: [
      31.296,
      3.173,
      18.86,
      31.712,
      2.016,
      18.86,
      31.712,
      2.016,
      21.32,
      31.712,
      2.016,
      21.32,
      31.296,
      3.173,
      21.32,
      31.296,
      3.173,
      18.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4466.0",
    polygon: [
      37.333,
      -1.423,
      29.36,
      37.333,
      -2.623,
      29.36,
      37.333,
      -2.623,
      31.82,
      37.333,
      -2.623,
      31.82,
      37.333,
      -1.423,
      31.82,
      37.333,
      -1.423,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3582.0",
    polygon: [
      -12.395,
      20.973,
      18.45,
      -12.395,
      19.973,
      18.45,
      -16.369,
      19.973,
      18.241,
      -16.369,
      19.973,
      18.241,
      -16.369,
      20.973,
      18.241,
      -12.395,
      20.973,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3333.0",
    polygon: [
      -57.148,
      17.669,
      18.233,
      -57.148,
      18.669,
      18.233,
      -53.175,
      18.669,
      18.025,
      -53.175,
      18.669,
      18.025,
      -53.175,
      17.669,
      18.025,
      -57.148,
      17.669,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3294.0",
    polygon: [
      1.473,
      11.148,
      29.36,
      1.473,
      9.998,
      29.36,
      1.473,
      9.998,
      31.82,
      1.473,
      9.998,
      31.82,
      1.473,
      11.148,
      31.82,
      1.473,
      11.148,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "4465.0",
    polygon: [
      37.333,
      -5.48,
      25.86,
      37.333,
      -6.68,
      25.86,
      37.333,
      -6.68,
      28.32,
      37.333,
      -6.68,
      28.32,
      37.333,
      -5.48,
      28.32,
      37.333,
      -5.48,
      25.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4026.0",
    polygon: [
      -64.53,
      38.499,
      8.36,
      -63.362,
      38.499,
      8.36,
      -63.362,
      38.499,
      10.82,
      -63.362,
      38.499,
      10.82,
      -64.53,
      38.499,
      10.82,
      -64.53,
      38.499,
      8.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3905.0",
    polygon: [
      -2.904,
      38.498,
      15.36,
      -1.736,
      38.498,
      15.36,
      -1.736,
      38.498,
      17.82,
      -1.736,
      38.498,
      17.82,
      -2.904,
      38.498,
      17.82,
      -2.904,
      38.498,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4306.0",
    polygon: [
      31.695,
      2.067,
      18.86,
      32.084,
      0.9,
      18.86,
      32.084,
      0.9,
      21.32,
      32.084,
      0.9,
      21.32,
      31.695,
      2.067,
      21.32,
      31.695,
      2.067,
      18.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "3786.0",
    polygon: [
      20.265,
      6.378,
      22.36,
      21.465,
      6.378,
      22.36,
      21.465,
      6.378,
      24.82,
      21.465,
      6.378,
      24.82,
      20.265,
      6.378,
      24.82,
      20.265,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4387.0",
    polygon: [
      37.322,
      -1.423,
      36.36,
      37.322,
      -2.623,
      36.36,
      37.322,
      -2.623,
      39.128,
      37.322,
      -2.623,
      39.128,
      37.322,
      -1.423,
      39.128,
      37.322,
      -1.423,
      36.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3865.0",
    polygon: [
      -18.096,
      6.349,
      32.86,
      -16.896,
      6.349,
      32.86,
      -16.896,
      6.349,
      35.32,
      -16.896,
      6.349,
      35.32,
      -18.096,
      6.349,
      35.32,
      -18.096,
      6.349,
      32.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4191.0",
    polygon: [
      25.151,
      14.76,
      11.86,
      25.848,
      13.747,
      11.86,
      25.848,
      13.747,
      14.32,
      25.848,
      13.747,
      14.32,
      25.151,
      14.76,
      14.32,
      25.151,
      14.76,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "3545.0",
    polygon: [
      -25.648,
      29.193,
      18.233,
      -25.648,
      30.193,
      18.233,
      -21.675,
      30.193,
      18.025,
      -21.675,
      30.193,
      18.025,
      -21.675,
      29.193,
      18.025,
      -25.648,
      29.193,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4153.0",
    polygon: [
      21.592,
      19.241,
      8.36,
      22.399,
      18.313,
      8.36,
      22.399,
      18.313,
      10.82,
      22.399,
      18.313,
      10.82,
      21.592,
      19.241,
      10.82,
      21.592,
      19.241,
      8.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "4114.0",
    polygon: [
      16.643,
      24.091,
      15.36,
      17.569,
      23.28,
      15.36,
      17.569,
      23.28,
      17.82,
      17.569,
      23.28,
      17.82,
      16.643,
      24.091,
      17.82,
      16.643,
      24.091,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4237.0",
    polygon: [
      19.529,
      21.425,
      4.86,
      20.311,
      20.627,
      4.86,
      20.311,
      20.627,
      7.32,
      20.311,
      20.627,
      7.32,
      19.529,
      21.425,
      7.32,
      19.529,
      21.425,
      4.86,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "4154.0",
    polygon: [
      22.683,
      17.975,
      8.36,
      23.458,
      17.02,
      8.36,
      23.458,
      17.02,
      10.82,
      23.458,
      17.02,
      10.82,
      22.683,
      17.975,
      10.82,
      22.683,
      17.975,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "3433.0",
    polygon: [
      -48.024,
      25.583,
      18.233,
      -48.024,
      24.583,
      18.233,
      -51.998,
      24.583,
      18.025,
      -51.998,
      24.583,
      18.025,
      -51.998,
      25.583,
      18.025,
      -48.024,
      25.583,
      18.233,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3748.0",
    polygon: [
      9.447,
      6.378,
      18.86,
      10.647,
      6.378,
      18.86,
      10.647,
      6.378,
      21.32,
      10.647,
      6.378,
      21.32,
      9.447,
      6.378,
      21.32,
      9.447,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3949.0",
    polygon: [
      -61.83,
      38.497,
      15.36,
      -60.662,
      38.497,
      15.36,
      -60.662,
      38.497,
      17.82,
      -60.662,
      38.497,
      17.82,
      -61.83,
      38.497,
      17.82,
      -61.83,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3296.0",
    polygon: [
      1.473,
      8.648,
      29.36,
      1.473,
      7.498,
      29.36,
      1.473,
      7.498,
      31.82,
      1.473,
      7.498,
      31.82,
      1.473,
      8.648,
      31.82,
      1.473,
      8.648,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "4115.0",
    polygon: [
      17.55,
      23.297,
      15.36,
      18.456,
      22.465,
      15.36,
      18.456,
      22.465,
      17.82,
      18.456,
      22.465,
      17.82,
      17.55,
      23.297,
      17.82,
      17.55,
      23.297,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "3747.0",
    polygon: [
      10.799,
      6.378,
      18.86,
      11.999,
      6.378,
      18.86,
      11.999,
      6.378,
      21.32,
      11.999,
      6.378,
      21.32,
      10.799,
      6.378,
      21.32,
      10.799,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3950.0",
    polygon: [
      -60.664,
      38.497,
      15.36,
      -59.496,
      38.497,
      15.36,
      -59.496,
      38.497,
      17.82,
      -59.496,
      38.497,
      17.82,
      -60.664,
      38.497,
      17.82,
      -60.664,
      38.497,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3787.0",
    polygon: [
      21.617,
      6.378,
      22.36,
      22.817,
      6.378,
      22.36,
      22.817,
      6.378,
      24.82,
      22.817,
      6.378,
      24.82,
      21.617,
      6.378,
      24.82,
      21.617,
      6.378,
      22.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4192.0",
    polygon: [
      26.099,
      13.369,
      11.86,
      26.763,
      12.333,
      11.86,
      26.763,
      12.333,
      14.32,
      26.763,
      12.333,
      14.32,
      26.099,
      13.369,
      14.32,
      26.099,
      13.369,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "3584.0",
    polygon: [
      -12.395,
      19.821,
      18.45,
      -12.395,
      18.821,
      18.45,
      -16.369,
      18.821,
      18.241,
      -16.369,
      18.821,
      18.241,
      -16.369,
      19.821,
      18.241,
      -12.395,
      19.821,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3394.0",
    polygon: [
      -43.895,
      14.059,
      18.45,
      -43.895,
      13.059,
      18.45,
      -47.869,
      13.059,
      18.241,
      -47.869,
      13.059,
      18.241,
      -47.869,
      14.059,
      18.241,
      -43.895,
      14.059,
      18.45,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4194.0",
    polygon: [
      27.598,
      10.945,
      11.86,
      28.203,
      9.873,
      11.86,
      28.203,
      9.873,
      14.32,
      28.203,
      9.873,
      14.32,
      27.598,
      10.945,
      14.32,
      27.598,
      10.945,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4308.0",
    polygon: [
      30.713,
      4.665,
      22.36,
      31.165,
      3.522,
      22.36,
      31.165,
      3.522,
      24.82,
      31.165,
      3.522,
      24.82,
      30.713,
      4.665,
      24.82,
      30.713,
      4.665,
      22.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4155.0",
    polygon: [
      23.437,
      17.047,
      8.36,
      24.19,
      16.075,
      8.36,
      24.19,
      16.075,
      10.82,
      24.19,
      16.075,
      10.82,
      23.437,
      17.047,
      10.82,
      23.437,
      17.047,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "3867.0",
    polygon: [
      -15.392,
      6.349,
      32.86,
      -14.192,
      6.349,
      32.86,
      -14.192,
      6.349,
      35.32,
      -14.192,
      6.349,
      35.32,
      -15.392,
      6.349,
      35.32,
      -15.392,
      6.349,
      32.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "3546.0",
    polygon: [
      -29.777,
      28.04,
      18.45,
      -29.777,
      29.04,
      18.45,
      -25.804,
      29.04,
      18.241,
      -25.804,
      29.04,
      18.241,
      -25.804,
      28.04,
      18.241,
      -29.777,
      28.04,
      18.45,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4307.0",
    polygon: [
      30.257,
      5.743,
      22.36,
      30.735,
      4.61,
      22.36,
      30.735,
      4.61,
      24.82,
      30.735,
      4.61,
      24.82,
      30.257,
      5.743,
      24.82,
      30.257,
      5.743,
      22.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
  },
  {
    ID: "4388.0",
    polygon: [
      37.322,
      -1.423,
      39.128,
      37.322,
      -2.623,
      39.128,
      37.322,
      -2.623,
      40.05,
      37.322,
      -2.623,
      40.05,
      37.322,
      -1.423,
      40.05,
      37.322,
      -1.423,
      39.128,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "4468.0",
    polygon: [
      37.333,
      -4.128,
      29.36,
      37.333,
      -5.328,
      29.36,
      37.333,
      -5.328,
      31.82,
      37.333,
      -5.328,
      31.82,
      37.333,
      -4.128,
      31.82,
      37.333,
      -4.128,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "3750.0",
    polygon: [
      17.56,
      6.378,
      18.86,
      18.76,
      6.378,
      18.86,
      18.76,
      6.378,
      21.32,
      18.76,
      6.378,
      21.32,
      17.56,
      6.378,
      21.32,
      17.56,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "3434.0",
    polygon: [
      -43.895,
      24.431,
      18.45,
      -43.895,
      23.431,
      18.45,
      -47.869,
      23.431,
      18.241,
      -47.869,
      23.431,
      18.241,
      -47.869,
      24.431,
      18.241,
      -43.895,
      24.431,
      18.45,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3297.0",
    polygon: [
      1.473,
      11.148,
      18.86,
      1.473,
      9.998,
      18.86,
      1.473,
      9.998,
      21.32,
      1.473,
      9.998,
      21.32,
      1.473,
      11.148,
      21.32,
      1.473,
      11.148,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "3258.0",
    polygon: [
      -43.226,
      9.998,
      18.86,
      -43.226,
      11.148,
      18.86,
      -43.226,
      11.148,
      21.32,
      -43.226,
      11.148,
      21.32,
      -43.226,
      9.998,
      21.32,
      -43.226,
      9.998,
      18.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4195.0",
    polygon: [
      28.181,
      9.913,
      11.86,
      28.761,
      8.828,
      11.86,
      28.761,
      8.828,
      14.32,
      28.761,
      8.828,
      14.32,
      28.181,
      9.913,
      14.32,
      28.181,
      9.913,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3868.0",
    polygon: [
      -16.744,
      6.349,
      32.86,
      -15.544,
      6.349,
      32.86,
      -15.544,
      6.349,
      35.32,
      -15.544,
      6.349,
      35.32,
      -16.744,
      6.349,
      35.32,
      -16.744,
      6.349,
      32.86,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "4309.0",
    polygon: [
      31.296,
      3.173,
      22.36,
      31.712,
      2.016,
      22.36,
      31.712,
      2.016,
      24.82,
      31.712,
      2.016,
      24.82,
      31.296,
      3.173,
      24.82,
      31.296,
      3.173,
      22.36,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "4156.0",
    polygon: [
      24.452,
      15.726,
      8.36,
      25.173,
      14.729,
      8.36,
      25.173,
      14.729,
      10.82,
      25.173,
      14.729,
      10.82,
      24.452,
      15.726,
      10.82,
      24.452,
      15.726,
      8.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "3751.0",
    polygon: [
      16.208,
      6.378,
      18.86,
      17.408,
      6.378,
      18.86,
      17.408,
      6.378,
      21.32,
      17.408,
      6.378,
      21.32,
      16.208,
      6.378,
      21.32,
      16.208,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4469.0",
    polygon: [
      37.333,
      -5.48,
      29.36,
      37.333,
      -6.68,
      29.36,
      37.333,
      -6.68,
      31.82,
      37.333,
      -6.68,
      31.82,
      37.333,
      -5.48,
      31.82,
      37.333,
      -5.48,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4429.0",
    polygon: [
      32.008,
      -0.105,
      36.36,
      32.008,
      -0.705,
      36.36,
      32.008,
      -0.705,
      38.82,
      32.008,
      -0.705,
      38.82,
      32.008,
      -0.105,
      38.82,
      32.008,
      -0.105,
      36.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "3298.0",
    polygon: [
      1.473,
      9.999,
      18.86,
      1.473,
      8.648,
      18.86,
      1.473,
      8.648,
      21.32,
      1.473,
      8.648,
      21.32,
      1.473,
      9.999,
      21.32,
      1.473,
      9.999,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "4029.0",
    polygon: [
      -1.37,
      38.498,
      11.86,
      -0.203,
      38.498,
      11.86,
      -0.203,
      38.498,
      14.32,
      -0.203,
      38.498,
      14.32,
      -1.37,
      38.498,
      14.32,
      -1.37,
      38.498,
      11.86,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4870.0",
    polygon: [
      -74.961,
      -19.056,
      -0.35,
      -74.961,
      -17.556,
      -0.35,
      -74.961,
      -17.556,
      2.78,
      -74.961,
      -17.556,
      2.78,
      -74.961,
      -19.056,
      2.78,
      -74.961,
      -19.056,
      -0.35,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "3396.0",
    polygon: [
      -43.895,
      12.907,
      18.45,
      -43.895,
      11.907,
      18.45,
      -47.869,
      11.907,
      18.241,
      -47.869,
      11.907,
      18.241,
      -47.869,
      12.907,
      18.241,
      -43.895,
      12.907,
      18.45,
    ],
    "04-15 17:30": 1,
  },
  {
    ID: "4193.0",
    polygon: [
      26.741,
      12.368,
      11.86,
      27.38,
      11.317,
      11.86,
      27.38,
      11.317,
      14.32,
      27.38,
      11.317,
      14.32,
      26.741,
      12.368,
      14.32,
      26.741,
      12.368,
      11.86,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
  },
  {
    ID: "3910.0",
    polygon: [
      -8.67,
      38.498,
      15.36,
      -7.503,
      38.498,
      15.36,
      -7.503,
      38.498,
      17.82,
      -7.503,
      38.498,
      17.82,
      -8.67,
      38.498,
      17.82,
      -8.67,
      38.498,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "5637.0",
    polygon: [
      -43.584,
      -31.986,
      15.36,
      -43.584,
      -30.836,
      15.36,
      -43.584,
      -30.836,
      17.82,
      -43.584,
      -30.836,
      17.82,
      -43.584,
      -31.986,
      17.82,
      -43.584,
      -31.986,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "4117.0",
    polygon: [
      19.63,
      21.324,
      15.36,
      20.487,
      20.443,
      15.36,
      20.487,
      20.443,
      17.82,
      20.487,
      20.443,
      17.82,
      19.63,
      21.324,
      17.82,
      19.63,
      21.324,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "3952.0",
    polygon: [
      -59.131,
      38.497,
      15.36,
      -57.964,
      38.497,
      15.36,
      -57.964,
      38.497,
      17.82,
      -57.964,
      38.497,
      17.82,
      -59.131,
      38.497,
      17.82,
      -59.131,
      38.497,
      15.36,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "3749.0",
    polygon: [
      13.504,
      6.378,
      18.86,
      14.704,
      6.378,
      18.86,
      14.704,
      6.378,
      21.32,
      14.704,
      6.378,
      21.32,
      13.504,
      6.378,
      21.32,
      13.504,
      6.378,
      18.86,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
  },
  {
    ID: "4467.0",
    polygon: [
      37.333,
      -2.775,
      29.36,
      37.333,
      -3.975,
      29.36,
      37.333,
      -3.975,
      31.82,
      37.333,
      -3.975,
      31.82,
      37.333,
      -2.775,
      31.82,
      37.333,
      -2.775,
      29.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5424.0",
    polygon: [
      -24.071,
      -7.235,
      11.86,
      -25.221,
      -7.235,
      11.86,
      -25.221,
      -7.235,
      14.32,
      -25.221,
      -7.235,
      14.32,
      -24.071,
      -7.235,
      14.32,
      -24.071,
      -7.235,
      11.86,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:45": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "800.0",
    polygon: [
      -25.221,
      -7.235,
      4.86,
      -26.371,
      -7.235,
      4.86,
      -26.371,
      -7.235,
      7.32,
      -26.371,
      -7.235,
      7.32,
      -25.221,
      -7.235,
      7.32,
      -25.221,
      -7.235,
      4.86,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "4116.0",
    polygon: [
      18.771,
      22.166,
      15.36,
      19.649,
      21.305,
      15.36,
      19.649,
      21.305,
      17.82,
      19.649,
      21.305,
      17.82,
      18.771,
      22.166,
      17.82,
      18.771,
      22.166,
      15.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "3909.0",
    polygon: [
      -7.504,
      38.498,
      15.36,
      -6.336,
      38.498,
      15.36,
      -6.336,
      38.498,
      17.82,
      -6.336,
      38.498,
      17.82,
      -7.504,
      38.498,
      17.82,
      -7.504,
      38.498,
      15.36,
    ],
    "04-15 18:00": 1,
  },
  {
    ID: "840.0",
    polygon: [
      -29.923,
      -10.155,
      4.86,
      -29.923,
      -11.305,
      4.86,
      -29.923,
      -11.305,
      7.32,
      -29.923,
      -11.305,
      7.32,
      -29.923,
      -10.155,
      7.32,
      -29.923,
      -10.155,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "879.0",
    polygon: [
      -43.584,
      -9.005,
      4.86,
      -43.584,
      -7.855,
      4.86,
      -43.584,
      -7.855,
      7.32,
      -43.584,
      -7.855,
      7.32,
      -43.584,
      -9.005,
      7.32,
      -43.584,
      -9.005,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "4871.0",
    polygon: [
      -74.961,
      -17.554,
      -0.35,
      -74.961,
      -16.394,
      -0.35,
      -74.961,
      -16.394,
      2.78,
      -74.961,
      -16.394,
      2.78,
      -74.961,
      -17.554,
      2.78,
      -74.961,
      -17.554,
      -0.35,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5273.0",
    polygon: [
      -61.524,
      -20.505,
      4.86,
      -61.524,
      -21.655,
      4.86,
      -61.524,
      -21.655,
      7.32,
      -61.524,
      -21.655,
      7.32,
      -61.524,
      -20.505,
      7.32,
      -61.524,
      -20.505,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "839.0",
    polygon: [
      -29.923,
      -9.005,
      4.86,
      -29.923,
      -10.155,
      4.86,
      -29.923,
      -10.155,
      7.32,
      -29.923,
      -10.155,
      7.32,
      -29.923,
      -9.005,
      7.32,
      -29.923,
      -9.005,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5601.0",
    polygon: [
      -29.923,
      -33.154,
      15.36,
      -29.923,
      -34.304,
      15.36,
      -29.923,
      -34.304,
      17.82,
      -29.923,
      -34.304,
      17.82,
      -29.923,
      -33.154,
      17.82,
      -29.923,
      -33.154,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5563.0",
    polygon: [
      -11.981,
      -27.405,
      15.36,
      -11.981,
      -26.255,
      15.36,
      -11.981,
      -26.255,
      17.82,
      -11.981,
      -26.255,
      17.82,
      -11.981,
      -27.405,
      17.82,
      -11.981,
      -27.405,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1190.0",
    polygon: [
      -61.524,
      -21.655,
      11.86,
      -61.524,
      -22.805,
      11.86,
      -61.524,
      -22.805,
      14.32,
      -61.524,
      -22.805,
      14.32,
      -61.524,
      -21.655,
      14.32,
      -61.524,
      -21.655,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5463.0",
    polygon: [
      -29.923,
      -10.155,
      11.86,
      -29.923,
      -11.305,
      11.86,
      -29.923,
      -11.305,
      14.32,
      -29.923,
      -11.305,
      14.32,
      -29.923,
      -10.155,
      14.32,
      -29.923,
      -10.155,
      11.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5501.0",
    polygon: [
      -43.584,
      -9.005,
      11.86,
      -43.584,
      -7.855,
      11.86,
      -43.584,
      -7.855,
      14.32,
      -43.584,
      -7.855,
      14.32,
      -43.584,
      -9.005,
      14.32,
      -43.584,
      -9.005,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5197.0",
    polygon: [
      -29.849,
      16.286,
      15.36,
      -29.849,
      15.136,
      15.36,
      -29.849,
      15.136,
      17.82,
      -29.849,
      15.136,
      17.82,
      -29.849,
      16.286,
      17.82,
      -29.849,
      16.286,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "644.0",
    polygon: [
      -29.849,
      25.486,
      11.86,
      -29.849,
      24.336,
      11.86,
      -29.849,
      24.336,
      14.32,
      -29.849,
      24.336,
      14.32,
      -29.849,
      25.486,
      14.32,
      -29.849,
      25.486,
      11.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "5308.0",
    polygon: [
      -11.981,
      -27.405,
      8.36,
      -11.981,
      -26.255,
      8.36,
      -11.981,
      -26.255,
      10.82,
      -11.981,
      -26.255,
      10.82,
      -11.981,
      -27.405,
      10.82,
      -11.981,
      -27.405,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5385.0",
    polygon: [
      -43.584,
      -30.836,
      8.36,
      -43.584,
      -29.686,
      8.36,
      -43.584,
      -29.686,
      10.82,
      -43.584,
      -29.686,
      10.82,
      -43.584,
      -30.836,
      10.82,
      -43.584,
      -30.836,
      8.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5346.0",
    polygon: [
      -29.923,
      -32.004,
      8.36,
      -29.923,
      -33.154,
      8.36,
      -29.923,
      -33.154,
      10.82,
      -29.923,
      -33.154,
      10.82,
      -29.923,
      -32.004,
      10.82,
      -29.923,
      -32.004,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "878.0",
    polygon: [
      -44.176,
      -7.235,
      4.86,
      -45.326,
      -7.235,
      4.86,
      -45.326,
      -7.235,
      7.32,
      -45.326,
      -7.235,
      7.32,
      -44.176,
      -7.235,
      7.32,
      -44.176,
      -7.235,
      4.86,
    ],
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5347.0",
    polygon: [
      -29.923,
      -33.154,
      8.36,
      -29.923,
      -34.304,
      8.36,
      -29.923,
      -34.304,
      10.82,
      -29.923,
      -34.304,
      10.82,
      -29.923,
      -33.154,
      10.82,
      -29.923,
      -33.154,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5307.0",
    polygon: [
      -11.981,
      -28.555,
      8.36,
      -11.981,
      -27.405,
      8.36,
      -11.981,
      -27.405,
      10.82,
      -11.981,
      -27.405,
      10.82,
      -11.981,
      -28.555,
      10.82,
      -11.981,
      -28.555,
      8.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1308.0",
    polygon: [
      -43.584,
      -33.136,
      15.36,
      -43.584,
      -31.986,
      15.36,
      -43.584,
      -31.986,
      17.82,
      -43.584,
      -31.986,
      17.82,
      -43.584,
      -33.136,
      17.82,
      -43.584,
      -33.136,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5602.0",
    polygon: [
      -29.923,
      -34.304,
      15.36,
      -29.923,
      -35.454,
      15.36,
      -29.923,
      -35.454,
      17.82,
      -29.923,
      -35.454,
      17.82,
      -29.923,
      -34.304,
      17.82,
      -29.923,
      -34.304,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1230.0",
    polygon: [
      -11.981,
      -26.255,
      15.36,
      -11.981,
      -25.105,
      15.36,
      -11.981,
      -25.105,
      17.82,
      -11.981,
      -25.105,
      17.82,
      -11.981,
      -26.255,
      17.82,
      -11.981,
      -26.255,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1191.0",
    polygon: [
      -61.524,
      -22.805,
      11.86,
      -61.524,
      -23.955,
      11.86,
      -61.524,
      -23.955,
      14.32,
      -61.524,
      -23.955,
      14.32,
      -61.524,
      -22.805,
      14.32,
      -61.524,
      -22.805,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5464.0",
    polygon: [
      -29.923,
      -11.305,
      11.86,
      -29.923,
      -12.455,
      11.86,
      -29.923,
      -12.455,
      14.32,
      -29.923,
      -12.455,
      14.32,
      -29.923,
      -11.305,
      14.32,
      -29.923,
      -11.305,
      11.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5502.0",
    polygon: [
      -43.584,
      -10.136,
      11.86,
      -43.584,
      -8.986,
      11.86,
      -43.584,
      -8.986,
      14.32,
      -43.584,
      -8.986,
      14.32,
      -43.584,
      -10.136,
      14.32,
      -43.584,
      -10.136,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5425.0",
    polygon: [
      -22.921,
      -7.235,
      11.86,
      -24.071,
      -7.235,
      11.86,
      -24.071,
      -7.235,
      14.32,
      -24.071,
      -7.235,
      14.32,
      -22.921,
      -7.235,
      14.32,
      -22.921,
      -7.235,
      11.86,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:30": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "5274.0",
    polygon: [
      -61.524,
      -21.655,
      4.86,
      -61.524,
      -22.805,
      4.86,
      -61.524,
      -22.805,
      7.32,
      -61.524,
      -22.805,
      7.32,
      -61.524,
      -21.655,
      7.32,
      -61.524,
      -21.655,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5386.0",
    polygon: [
      -43.584,
      -31.986,
      8.36,
      -43.584,
      -30.836,
      8.36,
      -43.584,
      -30.836,
      10.82,
      -43.584,
      -30.836,
      10.82,
      -43.584,
      -31.986,
      10.82,
      -43.584,
      -31.986,
      8.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3547.0",
    polygon: [
      -25.645,
      28.04,
      18.233,
      -25.645,
      29.04,
      18.233,
      -24.436,
      29.04,
      18.17,
      -24.436,
      29.04,
      18.17,
      -24.436,
      28.04,
      18.17,
      -25.645,
      28.04,
      18.233,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "5309.0",
    polygon: [
      -11.981,
      -26.255,
      8.36,
      -11.981,
      -25.105,
      8.36,
      -11.981,
      -25.105,
      10.82,
      -11.981,
      -25.105,
      10.82,
      -11.981,
      -26.255,
      10.82,
      -11.981,
      -26.255,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "841.0",
    polygon: [
      -29.923,
      -11.305,
      4.86,
      -29.923,
      -12.455,
      4.86,
      -29.923,
      -12.455,
      7.32,
      -29.923,
      -12.455,
      7.32,
      -29.923,
      -11.305,
      7.32,
      -29.923,
      -11.305,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5124.0",
    polygon: [
      -29.849,
      24.336,
      11.86,
      -29.849,
      23.186,
      11.86,
      -29.849,
      23.186,
      14.32,
      -29.849,
      23.186,
      14.32,
      -29.849,
      24.336,
      14.32,
      -29.849,
      24.336,
      11.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "802.0",
    polygon: [
      -22.921,
      -7.235,
      4.86,
      -24.071,
      -7.235,
      4.86,
      -24.071,
      -7.235,
      7.32,
      -24.071,
      -7.235,
      7.32,
      -22.921,
      -7.235,
      7.32,
      -22.921,
      -7.235,
      4.86,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "5125.0",
    polygon: [
      -29.849,
      23.186,
      11.86,
      -29.849,
      22.036,
      11.86,
      -29.849,
      22.036,
      14.32,
      -29.849,
      22.036,
      14.32,
      -29.849,
      23.186,
      14.32,
      -29.849,
      23.186,
      11.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "5387.0",
    polygon: [
      -43.584,
      -33.136,
      8.36,
      -43.584,
      -31.986,
      8.36,
      -43.584,
      -31.986,
      10.82,
      -43.584,
      -31.986,
      10.82,
      -43.584,
      -33.136,
      10.82,
      -43.584,
      -33.136,
      8.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "801.0",
    polygon: [
      -24.071,
      -7.235,
      4.86,
      -25.221,
      -7.235,
      4.86,
      -25.221,
      -7.235,
      7.32,
      -25.221,
      -7.235,
      7.32,
      -24.071,
      -7.235,
      7.32,
      -24.071,
      -7.235,
      4.86,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "5465.0",
    polygon: [
      -29.923,
      -12.455,
      11.86,
      -29.923,
      -13.605,
      11.86,
      -29.923,
      -13.605,
      14.32,
      -29.923,
      -13.605,
      14.32,
      -29.923,
      -12.455,
      14.32,
      -29.923,
      -12.455,
      11.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5503.0",
    polygon: [
      -43.584,
      -11.286,
      11.86,
      -43.584,
      -10.136,
      11.86,
      -43.584,
      -10.136,
      14.32,
      -43.584,
      -10.136,
      14.32,
      -43.584,
      -11.286,
      14.32,
      -43.584,
      -11.286,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "5426.0",
    polygon: [
      -21.771,
      -7.235,
      11.86,
      -22.921,
      -7.235,
      11.86,
      -22.921,
      -7.235,
      14.32,
      -22.921,
      -7.235,
      14.32,
      -21.771,
      -7.235,
      14.32,
      -21.771,
      -7.235,
      11.86,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 11:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "5275.0",
    polygon: [
      -61.524,
      -22.805,
      4.86,
      -61.524,
      -23.955,
      4.86,
      -61.524,
      -23.955,
      7.32,
      -61.524,
      -23.955,
      7.32,
      -61.524,
      -22.805,
      7.32,
      -61.524,
      -22.805,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "880.0",
    polygon: [
      -43.584,
      -10.136,
      4.86,
      -43.584,
      -8.986,
      4.86,
      -43.584,
      -8.986,
      7.32,
      -43.584,
      -8.986,
      7.32,
      -43.584,
      -10.136,
      7.32,
      -43.584,
      -10.136,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5348.0",
    polygon: [
      -29.923,
      -34.304,
      8.36,
      -29.923,
      -35.454,
      8.36,
      -29.923,
      -35.454,
      10.82,
      -29.923,
      -35.454,
      10.82,
      -29.923,
      -34.304,
      10.82,
      -29.923,
      -34.304,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "1309.0",
    polygon: [
      -43.584,
      -34.286,
      15.36,
      -43.584,
      -33.136,
      15.36,
      -43.584,
      -33.136,
      17.82,
      -43.584,
      -33.136,
      17.82,
      -43.584,
      -34.286,
      17.82,
      -43.584,
      -34.286,
      15.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "5564.0",
    polygon: [
      -11.981,
      -25.105,
      15.36,
      -11.981,
      -23.955,
      15.36,
      -11.981,
      -23.955,
      17.82,
      -11.981,
      -23.955,
      17.82,
      -11.981,
      -25.105,
      17.82,
      -11.981,
      -25.105,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5538.0",
    polygon: [
      -61.524,
      -23.955,
      11.86,
      -61.524,
      -25.105,
      11.86,
      -61.524,
      -25.105,
      14.32,
      -61.524,
      -25.105,
      14.32,
      -61.524,
      -23.955,
      14.32,
      -61.524,
      -23.955,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5603.0",
    polygon: [
      -29.923,
      -35.454,
      15.36,
      -29.923,
      -36.604,
      15.36,
      -29.923,
      -36.604,
      17.82,
      -29.923,
      -36.604,
      17.82,
      -29.923,
      -35.454,
      17.82,
      -29.923,
      -35.454,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "881.0",
    polygon: [
      -43.584,
      -11.286,
      4.86,
      -43.584,
      -10.136,
      4.86,
      -43.584,
      -10.136,
      7.32,
      -43.584,
      -10.136,
      7.32,
      -43.584,
      -11.286,
      7.32,
      -43.584,
      -11.286,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5276.0",
    polygon: [
      -61.524,
      -23.955,
      4.86,
      -61.524,
      -25.105,
      4.86,
      -61.524,
      -25.105,
      7.32,
      -61.524,
      -25.105,
      7.32,
      -61.524,
      -23.955,
      7.32,
      -61.524,
      -23.955,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5310.0",
    polygon: [
      -11.981,
      -25.105,
      8.36,
      -11.981,
      -23.955,
      8.36,
      -11.981,
      -23.955,
      10.82,
      -11.981,
      -23.955,
      10.82,
      -11.981,
      -25.105,
      10.82,
      -11.981,
      -25.105,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5388.0",
    polygon: [
      -43.584,
      -34.286,
      8.36,
      -43.584,
      -33.136,
      8.36,
      -43.584,
      -33.136,
      10.82,
      -43.584,
      -33.136,
      10.82,
      -43.584,
      -34.286,
      10.82,
      -43.584,
      -34.286,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:45": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5349.0",
    polygon: [
      -29.923,
      -35.454,
      8.36,
      -29.923,
      -36.604,
      8.36,
      -29.923,
      -36.604,
      10.82,
      -29.923,
      -36.604,
      10.82,
      -29.923,
      -35.454,
      10.82,
      -29.923,
      -35.454,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:45": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5126.0",
    polygon: [
      -29.849,
      22.036,
      11.86,
      -29.849,
      20.886,
      11.86,
      -29.849,
      20.886,
      14.32,
      -29.849,
      20.886,
      14.32,
      -29.849,
      22.036,
      14.32,
      -29.849,
      22.036,
      11.86,
    ],
    "04-15 06:30": 1,
  },
  {
    ID: "842.0",
    polygon: [
      -29.923,
      -12.455,
      4.86,
      -29.923,
      -13.605,
      4.86,
      -29.923,
      -13.605,
      7.32,
      -29.923,
      -13.605,
      7.32,
      -29.923,
      -12.455,
      7.32,
      -29.923,
      -12.455,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "803.0",
    polygon: [
      -21.771,
      -7.235,
      4.86,
      -22.921,
      -7.235,
      4.86,
      -22.921,
      -7.235,
      7.32,
      -22.921,
      -7.235,
      7.32,
      -21.771,
      -7.235,
      7.32,
      -21.771,
      -7.235,
      4.86,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "5200.0",
    polygon: [
      -29.849,
      13.987,
      15.36,
      -29.849,
      12.837,
      15.36,
      -29.849,
      12.837,
      17.82,
      -29.849,
      12.837,
      17.82,
      -29.849,
      13.987,
      17.82,
      -29.849,
      13.987,
      15.36,
    ],
    "04-15 05:30": 1,
  },
  {
    ID: "5604.0",
    polygon: [
      -29.923,
      -36.604,
      15.36,
      -29.923,
      -37.754,
      15.36,
      -29.923,
      -37.754,
      17.82,
      -29.923,
      -37.754,
      17.82,
      -29.923,
      -36.604,
      17.82,
      -29.923,
      -36.604,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "1310.0",
    polygon: [
      -43.584,
      -35.436,
      15.36,
      -43.584,
      -34.286,
      15.36,
      -43.584,
      -34.286,
      17.82,
      -43.584,
      -34.286,
      17.82,
      -43.584,
      -35.436,
      17.82,
      -43.584,
      -35.436,
      15.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5565.0",
    polygon: [
      -11.981,
      -23.955,
      15.36,
      -11.981,
      -22.805,
      15.36,
      -11.981,
      -22.805,
      17.82,
      -11.981,
      -22.805,
      17.82,
      -11.981,
      -23.955,
      17.82,
      -11.981,
      -23.955,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1193.0",
    polygon: [
      -61.524,
      -25.105,
      11.86,
      -61.524,
      -26.255,
      11.86,
      -61.524,
      -26.255,
      14.32,
      -61.524,
      -26.255,
      14.32,
      -61.524,
      -25.105,
      14.32,
      -61.524,
      -25.105,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5466.0",
    polygon: [
      -29.923,
      -13.605,
      11.86,
      -29.923,
      -14.755,
      11.86,
      -29.923,
      -14.755,
      14.32,
      -29.923,
      -14.755,
      14.32,
      -29.923,
      -13.605,
      14.32,
      -29.923,
      -13.605,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5504.0",
    polygon: [
      -43.584,
      -12.436,
      11.86,
      -43.584,
      -11.286,
      11.86,
      -43.584,
      -11.286,
      14.32,
      -43.584,
      -11.286,
      14.32,
      -43.584,
      -12.436,
      14.32,
      -43.584,
      -12.436,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "5427.0",
    polygon: [
      -20.626,
      -7.235,
      11.86,
      -21.776,
      -7.235,
      11.86,
      -21.776,
      -7.235,
      14.32,
      -21.776,
      -7.235,
      14.32,
      -20.626,
      -7.235,
      14.32,
      -20.626,
      -7.235,
      11.86,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:30": 1,
    "04-15 11:45": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "5539.0",
    polygon: [
      -61.524,
      -26.255,
      11.86,
      -61.524,
      -27.405,
      11.86,
      -61.524,
      -27.405,
      14.32,
      -61.524,
      -27.405,
      14.32,
      -61.524,
      -26.255,
      14.32,
      -61.524,
      -26.255,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5128.0",
    polygon: [
      -29.849,
      19.736,
      11.86,
      -29.849,
      18.586,
      11.86,
      -29.849,
      18.586,
      14.32,
      -29.849,
      18.586,
      14.32,
      -29.849,
      19.736,
      14.32,
      -29.849,
      19.736,
      11.86,
    ],
    "04-15 06:30": 1,
  },
  {
    ID: "5605.0",
    polygon: [
      -29.923,
      -37.754,
      15.36,
      -29.923,
      -38.904,
      15.36,
      -29.923,
      -38.904,
      17.82,
      -29.923,
      -38.904,
      17.82,
      -29.923,
      -37.754,
      17.82,
      -29.923,
      -37.754,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "4559.0",
    polygon: [
      2.566,
      -30.987,
      0.031,
      2.566,
      -32.317,
      0.031,
      2.566,
      -32.317,
      2.761,
      2.566,
      -32.317,
      2.761,
      2.566,
      -30.987,
      2.761,
      2.566,
      -30.987,
      0.031,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "882.0",
    polygon: [
      -43.584,
      -12.436,
      4.86,
      -43.584,
      -11.286,
      4.86,
      -43.584,
      -11.286,
      7.32,
      -43.584,
      -11.286,
      7.32,
      -43.584,
      -12.436,
      7.32,
      -43.584,
      -12.436,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5389.0",
    polygon: [
      -43.584,
      -35.436,
      8.36,
      -43.584,
      -34.286,
      8.36,
      -43.584,
      -34.286,
      10.82,
      -43.584,
      -34.286,
      10.82,
      -43.584,
      -35.436,
      10.82,
      -43.584,
      -35.436,
      8.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "922.0",
    polygon: [
      -61.524,
      -26.255,
      4.86,
      -61.524,
      -27.405,
      4.86,
      -61.524,
      -27.405,
      7.32,
      -61.524,
      -27.405,
      7.32,
      -61.524,
      -26.255,
      7.32,
      -61.524,
      -26.255,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5350.0",
    polygon: [
      -29.923,
      -36.604,
      8.36,
      -29.923,
      -37.754,
      8.36,
      -29.923,
      -37.754,
      10.82,
      -29.923,
      -37.754,
      10.82,
      -29.923,
      -36.604,
      10.82,
      -29.923,
      -36.604,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5312.0",
    polygon: [
      -11.981,
      -22.805,
      8.36,
      -11.981,
      -21.655,
      8.36,
      -11.981,
      -21.655,
      10.82,
      -11.981,
      -21.655,
      10.82,
      -11.981,
      -22.805,
      10.82,
      -11.981,
      -22.805,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "883.0",
    polygon: [
      -43.584,
      -13.586,
      4.86,
      -43.584,
      -12.436,
      4.86,
      -43.584,
      -12.436,
      7.32,
      -43.584,
      -12.436,
      7.32,
      -43.584,
      -13.586,
      7.32,
      -43.584,
      -13.586,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5390.0",
    polygon: [
      -43.584,
      -36.586,
      8.36,
      -43.584,
      -35.436,
      8.36,
      -43.584,
      -35.436,
      10.82,
      -43.584,
      -35.436,
      10.82,
      -43.584,
      -36.586,
      10.82,
      -43.584,
      -36.586,
      8.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5351.0",
    polygon: [
      -29.923,
      -37.754,
      8.36,
      -29.923,
      -38.904,
      8.36,
      -29.923,
      -38.904,
      10.82,
      -29.923,
      -38.904,
      10.82,
      -29.923,
      -37.754,
      10.82,
      -29.923,
      -37.754,
      8.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "844.0",
    polygon: [
      -29.923,
      -14.755,
      4.86,
      -29.923,
      -15.905,
      4.86,
      -29.923,
      -15.905,
      7.32,
      -29.923,
      -15.905,
      7.32,
      -29.923,
      -14.755,
      7.32,
      -29.923,
      -14.755,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "805.0",
    polygon: [
      -19.476,
      -7.235,
      4.86,
      -20.626,
      -7.235,
      4.86,
      -20.626,
      -7.235,
      7.32,
      -20.626,
      -7.235,
      7.32,
      -19.476,
      -7.235,
      7.32,
      -19.476,
      -7.235,
      4.86,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1312.0",
    polygon: [
      -43.584,
      -37.736,
      15.36,
      -43.584,
      -36.586,
      15.36,
      -43.584,
      -36.586,
      17.82,
      -43.584,
      -36.586,
      17.82,
      -43.584,
      -37.736,
      17.82,
      -43.584,
      -37.736,
      15.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5540.0",
    polygon: [
      -61.524,
      -27.405,
      11.86,
      -61.524,
      -28.555,
      11.86,
      -61.524,
      -28.555,
      14.32,
      -61.524,
      -28.555,
      14.32,
      -61.524,
      -27.405,
      14.32,
      -61.524,
      -27.405,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5606.0",
    polygon: [
      -59.154,
      -7.235,
      15.36,
      -60.304,
      -7.235,
      15.36,
      -60.304,
      -7.235,
      17.82,
      -60.304,
      -7.235,
      17.82,
      -59.154,
      -7.235,
      17.82,
      -59.154,
      -7.235,
      15.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "5567.0",
    polygon: [
      -11.981,
      -21.655,
      15.36,
      -11.981,
      -20.505,
      15.36,
      -11.981,
      -20.505,
      17.82,
      -11.981,
      -20.505,
      17.82,
      -11.981,
      -21.655,
      17.82,
      -11.981,
      -21.655,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5468.0",
    polygon: [
      -29.923,
      -15.905,
      11.86,
      -29.923,
      -17.055,
      11.86,
      -29.923,
      -17.055,
      14.32,
      -29.923,
      -17.055,
      14.32,
      -29.923,
      -15.905,
      14.32,
      -29.923,
      -15.905,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5506.0",
    polygon: [
      -43.584,
      -14.736,
      11.86,
      -43.584,
      -13.586,
      11.86,
      -43.584,
      -13.586,
      14.32,
      -43.584,
      -13.586,
      14.32,
      -43.584,
      -14.736,
      14.32,
      -43.584,
      -14.736,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5429.0",
    polygon: [
      -18.301,
      -7.235,
      11.86,
      -19.451,
      -7.235,
      11.86,
      -19.451,
      -7.235,
      14.32,
      -19.451,
      -7.235,
      14.32,
      -18.301,
      -7.235,
      14.32,
      -18.301,
      -7.235,
      11.86,
    ],
    "04-15 10:45": 1,
    "04-15 11:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "4696.0",
    polygon: [
      6.508,
      22.37,
      15.36,
      5.524,
      22.966,
      15.36,
      5.524,
      22.966,
      17.82,
      5.524,
      22.966,
      17.82,
      6.508,
      22.37,
      17.82,
      6.508,
      22.37,
      15.36,
    ],
    "04-15 15:30": 1,
  },
  {
    ID: "4598.0",
    polygon: [
      1.621,
      11.148,
      15.36,
      1.621,
      9.998,
      15.36,
      1.621,
      9.998,
      17.82,
      1.621,
      9.998,
      17.82,
      1.621,
      11.148,
      17.82,
      1.621,
      11.148,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
  },
  {
    ID: "843.0",
    polygon: [
      -29.923,
      -13.605,
      4.86,
      -29.923,
      -14.755,
      4.86,
      -29.923,
      -14.755,
      7.32,
      -29.923,
      -14.755,
      7.32,
      -29.923,
      -13.605,
      7.32,
      -29.923,
      -13.605,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5277.0",
    polygon: [
      -61.524,
      -25.105,
      4.86,
      -61.524,
      -26.255,
      4.86,
      -61.524,
      -26.255,
      7.32,
      -61.524,
      -26.255,
      7.32,
      -61.524,
      -25.105,
      7.32,
      -61.524,
      -25.105,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5311.0",
    polygon: [
      -11.981,
      -23.955,
      8.36,
      -11.981,
      -22.805,
      8.36,
      -11.981,
      -22.805,
      10.82,
      -11.981,
      -22.805,
      10.82,
      -11.981,
      -23.955,
      10.82,
      -11.981,
      -23.955,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "804.0",
    polygon: [
      -20.626,
      -7.235,
      4.86,
      -21.776,
      -7.235,
      4.86,
      -21.776,
      -7.235,
      7.32,
      -21.776,
      -7.235,
      7.32,
      -20.626,
      -7.235,
      7.32,
      -20.626,
      -7.235,
      4.86,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "4599.0",
    polygon: [
      1.621,
      9.999,
      15.36,
      1.621,
      8.648,
      15.36,
      1.621,
      8.648,
      17.82,
      1.621,
      8.648,
      17.82,
      1.621,
      9.999,
      17.82,
      1.621,
      9.999,
      15.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "5507.0",
    polygon: [
      -43.584,
      -15.886,
      11.86,
      -43.584,
      -14.736,
      11.86,
      -43.584,
      -14.736,
      14.32,
      -43.584,
      -14.736,
      14.32,
      -43.584,
      -15.886,
      14.32,
      -43.584,
      -15.886,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5203.0",
    polygon: [
      -43.529,
      17.987,
      15.36,
      -43.529,
      19.137,
      15.36,
      -43.529,
      19.137,
      17.82,
      -43.529,
      19.137,
      17.82,
      -43.529,
      17.987,
      17.82,
      -43.529,
      17.987,
      15.36,
    ],
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5467.0",
    polygon: [
      -29.923,
      -14.755,
      11.86,
      -29.923,
      -15.905,
      11.86,
      -29.923,
      -15.905,
      14.32,
      -29.923,
      -15.905,
      14.32,
      -29.923,
      -14.755,
      14.32,
      -29.923,
      -14.755,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "923.0",
    polygon: [
      -61.524,
      -27.405,
      4.86,
      -61.524,
      -28.555,
      4.86,
      -61.524,
      -28.555,
      7.32,
      -61.524,
      -28.555,
      7.32,
      -61.524,
      -27.405,
      7.32,
      -61.524,
      -27.405,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "4560.0",
    polygon: [
      2.566,
      -29.646,
      0.031,
      2.566,
      -30.976,
      0.031,
      2.566,
      -30.976,
      2.761,
      2.566,
      -30.976,
      2.761,
      2.566,
      -29.646,
      2.761,
      2.566,
      -29.646,
      0.031,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5505.0",
    polygon: [
      -43.584,
      -13.586,
      11.86,
      -43.584,
      -12.436,
      11.86,
      -43.584,
      -12.436,
      14.32,
      -43.584,
      -12.436,
      14.32,
      -43.584,
      -13.586,
      14.32,
      -43.584,
      -13.586,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5568.0",
    polygon: [
      -11.981,
      -20.505,
      15.36,
      -11.981,
      -19.355,
      15.36,
      -11.981,
      -19.355,
      17.82,
      -11.981,
      -19.355,
      17.82,
      -11.981,
      -20.505,
      17.82,
      -11.981,
      -20.505,
      15.36,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "884.0",
    polygon: [
      -43.584,
      -14.736,
      4.86,
      -43.584,
      -13.586,
      4.86,
      -43.584,
      -13.586,
      7.32,
      -43.584,
      -13.586,
      7.32,
      -43.584,
      -14.736,
      7.32,
      -43.584,
      -14.736,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1311.0",
    polygon: [
      -43.584,
      -36.586,
      15.36,
      -43.584,
      -35.436,
      15.36,
      -43.584,
      -35.436,
      17.82,
      -43.584,
      -35.436,
      17.82,
      -43.584,
      -36.586,
      17.82,
      -43.584,
      -36.586,
      15.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "5469.0",
    polygon: [
      -29.923,
      -17.055,
      11.86,
      -29.923,
      -18.205,
      11.86,
      -29.923,
      -18.205,
      14.32,
      -29.923,
      -18.205,
      14.32,
      -29.923,
      -17.055,
      14.32,
      -29.923,
      -17.055,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5261.0",
    polygon: [
      -18.301,
      -7.235,
      4.86,
      -19.451,
      -7.235,
      4.86,
      -19.451,
      -7.235,
      7.32,
      -19.451,
      -7.235,
      7.32,
      -18.301,
      -7.235,
      7.32,
      -18.301,
      -7.235,
      4.86,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "5566.0",
    polygon: [
      -11.981,
      -22.805,
      15.36,
      -11.981,
      -21.655,
      15.36,
      -11.981,
      -21.655,
      17.82,
      -11.981,
      -21.655,
      17.82,
      -11.981,
      -22.805,
      17.82,
      -11.981,
      -22.805,
      15.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5607.0",
    polygon: [
      -57.996,
      -7.235,
      15.36,
      -59.146,
      -7.235,
      15.36,
      -59.146,
      -7.235,
      17.82,
      -59.146,
      -7.235,
      17.82,
      -57.996,
      -7.235,
      17.82,
      -57.996,
      -7.235,
      15.36,
    ],
    "04-15 08:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "5391.0",
    polygon: [
      -43.584,
      -37.736,
      8.36,
      -43.584,
      -36.586,
      8.36,
      -43.584,
      -36.586,
      10.82,
      -43.584,
      -36.586,
      10.82,
      -43.584,
      -37.736,
      10.82,
      -43.584,
      -37.736,
      8.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1313.0",
    polygon: [
      -43.584,
      -38.886,
      15.36,
      -43.584,
      -37.736,
      15.36,
      -43.584,
      -37.736,
      17.82,
      -43.584,
      -37.736,
      17.82,
      -43.584,
      -38.886,
      17.82,
      -43.584,
      -38.886,
      15.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "5352.0",
    polygon: [
      -59.154,
      -7.235,
      8.36,
      -60.304,
      -7.235,
      8.36,
      -60.304,
      -7.235,
      10.82,
      -60.304,
      -7.235,
      10.82,
      -59.154,
      -7.235,
      10.82,
      -59.154,
      -7.235,
      8.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:30": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "845.0",
    polygon: [
      -29.923,
      -15.905,
      4.86,
      -29.923,
      -17.055,
      4.86,
      -29.923,
      -17.055,
      7.32,
      -29.923,
      -17.055,
      7.32,
      -29.923,
      -15.905,
      7.32,
      -29.923,
      -15.905,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "846.0",
    polygon: [
      -29.923,
      -17.055,
      4.86,
      -29.923,
      -18.205,
      4.86,
      -29.923,
      -18.205,
      7.32,
      -29.923,
      -18.205,
      7.32,
      -29.923,
      -17.055,
      7.32,
      -29.923,
      -17.055,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5201.0",
    polygon: [
      -43.529,
      15.687,
      15.36,
      -43.529,
      16.837,
      15.36,
      -43.529,
      16.837,
      17.82,
      -43.529,
      16.837,
      17.82,
      -43.529,
      15.687,
      17.82,
      -43.529,
      15.687,
      15.36,
    ],
    "04-15 16:45": 1,
  },
  {
    ID: "5430.0",
    polygon: [
      -17.151,
      -7.235,
      11.86,
      -18.301,
      -7.235,
      11.86,
      -18.301,
      -7.235,
      14.32,
      -18.301,
      -7.235,
      14.32,
      -17.151,
      -7.235,
      14.32,
      -17.151,
      -7.235,
      11.86,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 12:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5127.0",
    polygon: [
      -29.849,
      20.886,
      11.86,
      -29.849,
      19.736,
      11.86,
      -29.849,
      19.736,
      14.32,
      -29.849,
      19.736,
      14.32,
      -29.849,
      20.886,
      14.32,
      -29.849,
      20.886,
      11.86,
    ],
    "04-15 06:30": 1,
  },
  {
    ID: "5428.0",
    polygon: [
      -19.476,
      -7.235,
      11.86,
      -20.626,
      -7.235,
      11.86,
      -20.626,
      -7.235,
      14.32,
      -20.626,
      -7.235,
      14.32,
      -19.476,
      -7.235,
      14.32,
      -19.476,
      -7.235,
      11.86,
    ],
    "04-15 09:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1196.0",
    polygon: [
      -61.524,
      -28.555,
      11.86,
      -61.524,
      -29.705,
      11.86,
      -61.524,
      -29.705,
      14.32,
      -61.524,
      -29.705,
      14.32,
      -61.524,
      -28.555,
      14.32,
      -61.524,
      -28.555,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5313.0",
    polygon: [
      -11.981,
      -21.655,
      8.36,
      -11.981,
      -20.505,
      8.36,
      -11.981,
      -20.505,
      10.82,
      -11.981,
      -20.505,
      10.82,
      -11.981,
      -21.655,
      10.82,
      -11.981,
      -21.655,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "847.0",
    polygon: [
      -29.923,
      -18.205,
      4.86,
      -29.923,
      -19.355,
      4.86,
      -29.923,
      -19.355,
      7.32,
      -29.923,
      -19.355,
      7.32,
      -29.923,
      -18.205,
      7.32,
      -29.923,
      -18.205,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5205.0",
    polygon: [
      -43.529,
      20.897,
      15.36,
      -43.529,
      22.047,
      15.36,
      -43.529,
      22.047,
      17.82,
      -43.529,
      22.047,
      17.82,
      -43.529,
      20.897,
      17.82,
      -43.529,
      20.897,
      15.36,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "885.0",
    polygon: [
      -43.584,
      -15.886,
      4.86,
      -43.584,
      -14.736,
      4.86,
      -43.584,
      -14.736,
      7.32,
      -43.584,
      -14.736,
      7.32,
      -43.584,
      -15.886,
      7.32,
      -43.584,
      -15.886,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5470.0",
    polygon: [
      -29.923,
      -18.205,
      11.86,
      -29.923,
      -19.355,
      11.86,
      -29.923,
      -19.355,
      14.32,
      -29.923,
      -19.355,
      14.32,
      -29.923,
      -18.205,
      14.32,
      -29.923,
      -18.205,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5263.0",
    polygon: [
      -16.001,
      -7.235,
      4.86,
      -17.151,
      -7.235,
      4.86,
      -17.151,
      -7.235,
      7.32,
      -17.151,
      -7.235,
      7.32,
      -16.001,
      -7.235,
      7.32,
      -16.001,
      -7.235,
      4.86,
    ],
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "4600.0",
    polygon: [
      1.621,
      8.648,
      15.36,
      1.621,
      7.498,
      15.36,
      1.621,
      7.498,
      17.82,
      1.621,
      7.498,
      17.82,
      1.621,
      8.648,
      17.82,
      1.621,
      8.648,
      15.36,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "5353.0",
    polygon: [
      -57.996,
      -7.235,
      8.36,
      -59.146,
      -7.235,
      8.36,
      -59.146,
      -7.235,
      10.82,
      -59.146,
      -7.235,
      10.82,
      -57.996,
      -7.235,
      10.82,
      -57.996,
      -7.235,
      8.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 11:00": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "5508.0",
    polygon: [
      -43.584,
      -17.036,
      11.86,
      -43.584,
      -15.886,
      11.86,
      -43.584,
      -15.886,
      14.32,
      -43.584,
      -15.886,
      14.32,
      -43.584,
      -17.036,
      14.32,
      -43.584,
      -17.036,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5392.0",
    polygon: [
      -43.584,
      -38.886,
      8.36,
      -43.584,
      -37.736,
      8.36,
      -43.584,
      -37.736,
      10.82,
      -43.584,
      -37.736,
      10.82,
      -43.584,
      -38.886,
      10.82,
      -43.584,
      -38.886,
      8.36,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "5204.0",
    polygon: [
      -43.529,
      19.747,
      15.36,
      -43.529,
      20.897,
      15.36,
      -43.529,
      20.897,
      17.82,
      -43.529,
      20.897,
      17.82,
      -43.529,
      19.747,
      17.82,
      -43.529,
      19.747,
      15.36,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5509.0",
    polygon: [
      -43.584,
      -18.186,
      11.86,
      -43.584,
      -17.036,
      11.86,
      -43.584,
      -17.036,
      14.32,
      -43.584,
      -17.036,
      14.32,
      -43.584,
      -18.186,
      14.32,
      -43.584,
      -18.186,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "848.0",
    polygon: [
      -29.923,
      -19.355,
      4.86,
      -29.923,
      -20.505,
      4.86,
      -29.923,
      -20.505,
      7.32,
      -29.923,
      -20.505,
      7.32,
      -29.923,
      -19.355,
      7.32,
      -29.923,
      -19.355,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5431.0",
    polygon: [
      -16.001,
      -7.235,
      11.86,
      -17.151,
      -7.235,
      11.86,
      -17.151,
      -7.235,
      14.32,
      -17.151,
      -7.235,
      14.32,
      -16.001,
      -7.235,
      14.32,
      -16.001,
      -7.235,
      11.86,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:30": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1314.0",
    polygon: [
      -61.524,
      -7.855,
      15.36,
      -61.524,
      -9.005,
      15.36,
      -61.524,
      -9.005,
      17.82,
      -61.524,
      -9.005,
      17.82,
      -61.524,
      -7.855,
      17.82,
      -61.524,
      -7.855,
      15.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5264.0",
    polygon: [
      -14.851,
      -7.235,
      4.86,
      -16.001,
      -7.235,
      4.86,
      -16.001,
      -7.235,
      7.32,
      -16.001,
      -7.235,
      7.32,
      -14.851,
      -7.235,
      7.32,
      -14.851,
      -7.235,
      4.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "886.0",
    polygon: [
      -43.584,
      -17.036,
      4.86,
      -43.584,
      -15.886,
      4.86,
      -43.584,
      -15.886,
      7.32,
      -43.584,
      -15.886,
      7.32,
      -43.584,
      -17.036,
      7.32,
      -43.584,
      -17.036,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "924.0",
    polygon: [
      -61.524,
      -28.555,
      4.86,
      -61.524,
      -29.705,
      4.86,
      -61.524,
      -29.705,
      7.32,
      -61.524,
      -29.705,
      7.32,
      -61.524,
      -28.555,
      7.32,
      -61.524,
      -28.555,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5471.0",
    polygon: [
      -29.923,
      -19.355,
      11.86,
      -29.923,
      -20.505,
      11.86,
      -29.923,
      -20.505,
      14.32,
      -29.923,
      -20.505,
      14.32,
      -29.923,
      -19.355,
      14.32,
      -29.923,
      -19.355,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5354.0",
    polygon: [
      -56.846,
      -7.235,
      8.36,
      -57.996,
      -7.235,
      8.36,
      -57.996,
      -7.235,
      10.82,
      -57.996,
      -7.235,
      10.82,
      -56.846,
      -7.235,
      10.82,
      -56.846,
      -7.235,
      8.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "4562.0",
    polygon: [
      2.566,
      -26.985,
      0.031,
      2.566,
      -28.315,
      0.031,
      2.566,
      -28.315,
      2.761,
      2.566,
      -28.315,
      2.761,
      2.566,
      -26.985,
      2.761,
      2.566,
      -26.985,
      0.031,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5206.0",
    polygon: [
      -43.529,
      22.046,
      15.36,
      -43.529,
      23.196,
      15.36,
      -43.529,
      23.196,
      17.82,
      -43.529,
      23.196,
      17.82,
      -43.529,
      22.046,
      17.82,
      -43.529,
      22.046,
      15.36,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5569.0",
    polygon: [
      -11.981,
      -19.355,
      15.36,
      -11.981,
      -18.205,
      15.36,
      -11.981,
      -18.205,
      17.82,
      -11.981,
      -18.205,
      17.82,
      -11.981,
      -19.355,
      17.82,
      -11.981,
      -19.355,
      15.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5314.0",
    polygon: [
      -11.981,
      -20.505,
      8.36,
      -11.981,
      -19.355,
      8.36,
      -11.981,
      -19.355,
      10.82,
      -11.981,
      -19.355,
      10.82,
      -11.981,
      -20.505,
      10.82,
      -11.981,
      -20.505,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5393.0",
    polygon: [
      -61.524,
      -7.855,
      8.36,
      -61.524,
      -9.005,
      8.36,
      -61.524,
      -9.005,
      10.82,
      -61.524,
      -9.005,
      10.82,
      -61.524,
      -7.855,
      10.82,
      -61.524,
      -7.855,
      8.36,
    ],
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "1315.0",
    polygon: [
      -61.524,
      -9.005,
      15.36,
      -61.524,
      -10.155,
      15.36,
      -61.524,
      -10.155,
      17.82,
      -61.524,
      -10.155,
      17.82,
      -61.524,
      -9.005,
      17.82,
      -61.524,
      -9.005,
      15.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "4561.0",
    polygon: [
      2.566,
      -28.326,
      0.031,
      2.566,
      -29.656,
      0.031,
      2.566,
      -29.656,
      2.761,
      2.566,
      -29.656,
      2.761,
      2.566,
      -28.326,
      2.761,
      2.566,
      -28.326,
      0.031,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5262.0",
    polygon: [
      -17.151,
      -7.235,
      4.86,
      -18.301,
      -7.235,
      4.86,
      -18.301,
      -7.235,
      7.32,
      -18.301,
      -7.235,
      7.32,
      -17.151,
      -7.235,
      7.32,
      -17.151,
      -7.235,
      4.86,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "5609.0",
    polygon: [
      -55.696,
      -7.235,
      15.36,
      -56.846,
      -7.235,
      15.36,
      -56.846,
      -7.235,
      17.82,
      -56.846,
      -7.235,
      17.82,
      -55.696,
      -7.235,
      17.82,
      -55.696,
      -7.235,
      15.36,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "925.0",
    polygon: [
      -61.524,
      -29.705,
      4.86,
      -61.524,
      -30.855,
      4.86,
      -61.524,
      -30.855,
      7.32,
      -61.524,
      -30.855,
      7.32,
      -61.524,
      -29.705,
      7.32,
      -61.524,
      -29.705,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5570.0",
    polygon: [
      -11.981,
      -18.205,
      15.36,
      -11.981,
      -17.055,
      15.36,
      -11.981,
      -17.055,
      17.82,
      -11.981,
      -17.055,
      17.82,
      -11.981,
      -18.205,
      17.82,
      -11.981,
      -18.205,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5355.0",
    polygon: [
      -55.696,
      -7.235,
      8.36,
      -56.846,
      -7.235,
      8.36,
      -56.846,
      -7.235,
      10.82,
      -56.846,
      -7.235,
      10.82,
      -55.696,
      -7.235,
      10.82,
      -55.696,
      -7.235,
      8.36,
    ],
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "5541.0",
    polygon: [
      -61.524,
      -29.705,
      11.86,
      -61.524,
      -30.855,
      11.86,
      -61.524,
      -30.855,
      14.32,
      -61.524,
      -30.855,
      14.32,
      -61.524,
      -29.705,
      14.32,
      -61.524,
      -29.705,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5432.0",
    polygon: [
      -14.851,
      -7.235,
      11.86,
      -16.001,
      -7.235,
      11.86,
      -16.001,
      -7.235,
      14.32,
      -16.001,
      -7.235,
      14.32,
      -14.851,
      -7.235,
      14.32,
      -14.851,
      -7.235,
      11.86,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "4699.0",
    polygon: [
      9.824,
      20.078,
      15.36,
      8.9,
      20.763,
      15.36,
      8.9,
      20.763,
      17.82,
      8.9,
      20.763,
      17.82,
      9.824,
      20.078,
      17.82,
      9.824,
      20.078,
      15.36,
    ],
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5542.0",
    polygon: [
      -61.524,
      -30.855,
      11.86,
      -61.524,
      -32.005,
      11.86,
      -61.524,
      -32.005,
      14.32,
      -61.524,
      -32.005,
      14.32,
      -61.524,
      -30.855,
      14.32,
      -61.524,
      -30.855,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5315.0",
    polygon: [
      -11.981,
      -19.355,
      8.36,
      -11.981,
      -18.205,
      8.36,
      -11.981,
      -18.205,
      10.82,
      -11.981,
      -18.205,
      10.82,
      -11.981,
      -19.355,
      10.82,
      -11.981,
      -19.355,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5608.0",
    polygon: [
      -56.846,
      -7.235,
      15.36,
      -57.996,
      -7.235,
      15.36,
      -57.996,
      -7.235,
      17.82,
      -57.996,
      -7.235,
      17.82,
      -56.846,
      -7.235,
      17.82,
      -56.846,
      -7.235,
      15.36,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "4655.0",
    polygon: [
      1.621,
      11.148,
      11.86,
      1.621,
      9.998,
      11.86,
      1.621,
      9.998,
      14.32,
      1.621,
      9.998,
      14.32,
      1.621,
      11.148,
      14.32,
      1.621,
      11.148,
      11.86,
    ],
    "04-15 06:15": 1,
  },
  {
    ID: "1316.0",
    polygon: [
      -61.524,
      -10.155,
      15.36,
      -61.524,
      -11.305,
      15.36,
      -61.524,
      -11.305,
      17.82,
      -61.524,
      -11.305,
      17.82,
      -61.524,
      -10.155,
      17.82,
      -61.524,
      -10.155,
      15.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1199.0",
    polygon: [
      -61.524,
      -32.005,
      11.86,
      -61.524,
      -33.155,
      11.86,
      -61.524,
      -33.155,
      14.32,
      -61.524,
      -33.155,
      14.32,
      -61.524,
      -32.005,
      14.32,
      -61.524,
      -32.005,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5610.0",
    polygon: [
      -54.546,
      -7.235,
      15.36,
      -55.696,
      -7.235,
      15.36,
      -55.696,
      -7.235,
      17.82,
      -55.696,
      -7.235,
      17.82,
      -54.546,
      -7.235,
      17.82,
      -54.546,
      -7.235,
      15.36,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "5571.0",
    polygon: [
      -11.981,
      -17.055,
      15.36,
      -11.981,
      -15.905,
      15.36,
      -11.981,
      -15.905,
      17.82,
      -11.981,
      -15.905,
      17.82,
      -11.981,
      -17.055,
      17.82,
      -11.981,
      -17.055,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4563.0",
    polygon: [
      2.566,
      -25.665,
      0.031,
      2.566,
      -26.995,
      0.031,
      2.566,
      -26.995,
      2.761,
      2.566,
      -26.995,
      2.761,
      2.566,
      -25.665,
      2.761,
      2.566,
      -25.665,
      0.031,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "4700.0",
    polygon: [
      10.91,
      19.223,
      15.36,
      10.009,
      19.937,
      15.36,
      10.009,
      19.937,
      17.82,
      10.009,
      19.937,
      17.82,
      10.91,
      19.223,
      17.82,
      10.91,
      19.223,
      15.36,
    ],
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5511.0",
    polygon: [
      -43.584,
      -20.486,
      11.86,
      -43.584,
      -19.336,
      11.86,
      -43.584,
      -19.336,
      14.32,
      -43.584,
      -19.336,
      14.32,
      -43.584,
      -20.486,
      14.32,
      -43.584,
      -20.486,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5278.0",
    polygon: [
      -61.524,
      -32.005,
      4.86,
      -61.524,
      -33.155,
      4.86,
      -61.524,
      -33.155,
      7.32,
      -61.524,
      -33.155,
      7.32,
      -61.524,
      -32.005,
      7.32,
      -61.524,
      -32.005,
      4.86,
    ],
    "04-15 08:00": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5434.0",
    polygon: [
      -12.551,
      -7.235,
      11.86,
      -13.701,
      -7.235,
      11.86,
      -13.701,
      -7.235,
      14.32,
      -13.701,
      -7.235,
      14.32,
      -12.551,
      -7.235,
      14.32,
      -12.551,
      -7.235,
      11.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5317.0",
    polygon: [
      -11.981,
      -17.055,
      8.36,
      -11.981,
      -15.905,
      8.36,
      -11.981,
      -15.905,
      10.82,
      -11.981,
      -15.905,
      10.82,
      -11.981,
      -17.055,
      10.82,
      -11.981,
      -17.055,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "888.0",
    polygon: [
      -43.584,
      -19.336,
      4.86,
      -43.584,
      -18.186,
      4.86,
      -43.584,
      -18.186,
      7.32,
      -43.584,
      -18.186,
      7.32,
      -43.584,
      -19.336,
      7.32,
      -43.584,
      -19.336,
      4.86,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5395.0",
    polygon: [
      -61.524,
      -10.155,
      8.36,
      -61.524,
      -11.305,
      8.36,
      -61.524,
      -11.305,
      10.82,
      -61.524,
      -11.305,
      10.82,
      -61.524,
      -10.155,
      10.82,
      -61.524,
      -10.155,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "5356.0",
    polygon: [
      -54.546,
      -7.235,
      8.36,
      -55.696,
      -7.235,
      8.36,
      -55.696,
      -7.235,
      10.82,
      -55.696,
      -7.235,
      10.82,
      -54.546,
      -7.235,
      10.82,
      -54.546,
      -7.235,
      8.36,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:15": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "5207.0",
    polygon: [
      -43.529,
      23.196,
      15.36,
      -43.529,
      24.346,
      15.36,
      -43.529,
      24.346,
      17.82,
      -43.529,
      24.346,
      17.82,
      -43.529,
      23.196,
      17.82,
      -43.529,
      23.196,
      15.36,
    ],
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "5318.0",
    polygon: [
      -11.981,
      -15.905,
      8.36,
      -11.981,
      -14.755,
      8.36,
      -11.981,
      -14.755,
      10.82,
      -11.981,
      -14.755,
      10.82,
      -11.981,
      -15.905,
      10.82,
      -11.981,
      -15.905,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5396.0",
    polygon: [
      -61.524,
      -11.305,
      8.36,
      -61.524,
      -12.455,
      8.36,
      -61.524,
      -12.455,
      10.82,
      -61.524,
      -12.455,
      10.82,
      -61.524,
      -11.305,
      10.82,
      -61.524,
      -11.305,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "1201.0",
    polygon: [
      -61.524,
      -34.305,
      11.86,
      -61.524,
      -35.455,
      11.86,
      -61.524,
      -35.455,
      14.32,
      -61.524,
      -35.455,
      14.32,
      -61.524,
      -34.305,
      14.32,
      -61.524,
      -34.305,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5572.0",
    polygon: [
      -11.981,
      -15.905,
      15.36,
      -11.981,
      -14.755,
      15.36,
      -11.981,
      -14.755,
      17.82,
      -11.981,
      -14.755,
      17.82,
      -11.981,
      -15.905,
      17.82,
      -11.981,
      -15.905,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5265.0",
    polygon: [
      -13.701,
      -7.235,
      4.86,
      -14.851,
      -7.235,
      4.86,
      -14.851,
      -7.235,
      7.32,
      -14.851,
      -7.235,
      7.32,
      -13.701,
      -7.235,
      7.32,
      -13.701,
      -7.235,
      4.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "889.0",
    polygon: [
      -43.584,
      -20.486,
      4.86,
      -43.584,
      -19.336,
      4.86,
      -43.584,
      -19.336,
      7.32,
      -43.584,
      -19.336,
      7.32,
      -43.584,
      -20.486,
      7.32,
      -43.584,
      -20.486,
      4.86,
    ],
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5573.0",
    polygon: [
      -11.981,
      -14.755,
      15.36,
      -11.981,
      -13.605,
      15.36,
      -11.981,
      -13.605,
      17.82,
      -11.981,
      -13.605,
      17.82,
      -11.981,
      -14.755,
      17.82,
      -11.981,
      -14.755,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5611.0",
    polygon: [
      -53.396,
      -7.235,
      15.36,
      -54.546,
      -7.235,
      15.36,
      -54.546,
      -7.235,
      17.82,
      -54.546,
      -7.235,
      17.82,
      -53.396,
      -7.235,
      17.82,
      -53.396,
      -7.235,
      15.36,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "926.0",
    polygon: [
      -61.524,
      -30.855,
      4.86,
      -61.524,
      -32.005,
      4.86,
      -61.524,
      -32.005,
      7.32,
      -61.524,
      -32.005,
      7.32,
      -61.524,
      -30.855,
      7.32,
      -61.524,
      -30.855,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "4658.0",
    polygon: [
      1.621,
      13.648,
      15.36,
      1.621,
      12.498,
      15.36,
      1.621,
      12.498,
      17.82,
      1.621,
      12.498,
      17.82,
      1.621,
      13.648,
      17.82,
      1.621,
      13.648,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "5512.0",
    polygon: [
      -43.584,
      -21.636,
      11.86,
      -43.584,
      -20.486,
      11.86,
      -43.584,
      -20.486,
      14.32,
      -43.584,
      -20.486,
      14.32,
      -43.584,
      -21.636,
      14.32,
      -43.584,
      -21.636,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "4564.0",
    polygon: [
      2.566,
      -24.324,
      0.031,
      2.566,
      -25.654,
      0.031,
      2.566,
      -25.654,
      2.761,
      2.566,
      -25.654,
      2.761,
      2.566,
      -24.324,
      2.761,
      2.566,
      -24.324,
      0.031,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5266.0",
    polygon: [
      -12.551,
      -7.235,
      4.86,
      -13.701,
      -7.235,
      4.86,
      -13.701,
      -7.235,
      7.32,
      -13.701,
      -7.235,
      7.32,
      -12.551,
      -7.235,
      7.32,
      -12.551,
      -7.235,
      4.86,
    ],
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5474.0",
    polygon: [
      -29.923,
      -22.805,
      11.86,
      -29.923,
      -23.955,
      11.86,
      -29.923,
      -23.955,
      14.32,
      -29.923,
      -23.955,
      14.32,
      -29.923,
      -22.805,
      14.32,
      -29.923,
      -22.805,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5316.0",
    polygon: [
      -11.981,
      -18.205,
      8.36,
      -11.981,
      -17.055,
      8.36,
      -11.981,
      -17.055,
      10.82,
      -11.981,
      -17.055,
      10.82,
      -11.981,
      -18.205,
      10.82,
      -11.981,
      -18.205,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5435.0",
    polygon: [
      -11.981,
      -37.755,
      11.86,
      -11.981,
      -36.605,
      11.86,
      -11.981,
      -36.605,
      14.32,
      -11.981,
      -36.605,
      14.32,
      -11.981,
      -37.755,
      14.32,
      -11.981,
      -37.755,
      11.86,
    ],
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "4565.0",
    polygon: [
      2.566,
      -23.004,
      0.031,
      2.566,
      -24.334,
      0.031,
      2.566,
      -24.334,
      2.761,
      2.566,
      -24.334,
      2.761,
      2.566,
      -23.004,
      2.761,
      2.566,
      -23.004,
      0.031,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5357.0",
    polygon: [
      -53.396,
      -7.235,
      8.36,
      -54.546,
      -7.235,
      8.36,
      -54.546,
      -7.235,
      10.82,
      -54.546,
      -7.235,
      10.82,
      -53.396,
      -7.235,
      10.82,
      -53.396,
      -7.235,
      8.36,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:45": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:15": 1,
    "04-15 11:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "887.0",
    polygon: [
      -43.584,
      -18.186,
      4.86,
      -43.584,
      -17.036,
      4.86,
      -43.584,
      -17.036,
      7.32,
      -43.584,
      -17.036,
      7.32,
      -43.584,
      -18.186,
      7.32,
      -43.584,
      -18.186,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1200.0",
    polygon: [
      -61.524,
      -33.155,
      11.86,
      -61.524,
      -34.305,
      11.86,
      -61.524,
      -34.305,
      14.32,
      -61.524,
      -34.305,
      14.32,
      -61.524,
      -33.155,
      14.32,
      -61.524,
      -33.155,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "850.0",
    polygon: [
      -29.923,
      -21.655,
      4.86,
      -29.923,
      -22.805,
      4.86,
      -29.923,
      -22.805,
      7.32,
      -29.923,
      -22.805,
      7.32,
      -29.923,
      -21.655,
      7.32,
      -29.923,
      -21.655,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5279.0",
    polygon: [
      -61.524,
      -33.155,
      4.86,
      -61.524,
      -34.305,
      4.86,
      -61.524,
      -34.305,
      7.32,
      -61.524,
      -34.305,
      7.32,
      -61.524,
      -33.155,
      7.32,
      -61.524,
      -33.155,
      4.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "4702.0",
    polygon: [
      13.012,
      17.397,
      15.36,
      12.158,
      18.167,
      15.36,
      12.158,
      18.167,
      17.82,
      12.158,
      18.167,
      17.82,
      13.012,
      17.397,
      17.82,
      13.012,
      17.397,
      15.36,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "849.0",
    polygon: [
      -29.923,
      -20.505,
      4.86,
      -29.923,
      -21.655,
      4.86,
      -29.923,
      -21.655,
      7.32,
      -29.923,
      -21.655,
      7.32,
      -29.923,
      -20.505,
      7.32,
      -29.923,
      -20.505,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "4701.0",
    polygon: [
      11.984,
      18.319,
      15.36,
      11.106,
      19.062,
      15.36,
      11.106,
      19.062,
      17.82,
      11.106,
      19.062,
      17.82,
      11.984,
      18.319,
      17.82,
      11.984,
      18.319,
      15.36,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5394.0",
    polygon: [
      -61.524,
      -9.005,
      8.36,
      -61.524,
      -10.155,
      8.36,
      -61.524,
      -10.155,
      10.82,
      -61.524,
      -10.155,
      10.82,
      -61.524,
      -9.005,
      10.82,
      -61.524,
      -9.005,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "1317.0",
    polygon: [
      -61.524,
      -11.305,
      15.36,
      -61.524,
      -12.455,
      15.36,
      -61.524,
      -12.455,
      17.82,
      -61.524,
      -12.455,
      17.82,
      -61.524,
      -11.305,
      17.82,
      -61.524,
      -11.305,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1318.0",
    polygon: [
      -61.524,
      -12.455,
      15.36,
      -61.524,
      -13.605,
      15.36,
      -61.524,
      -13.605,
      17.82,
      -61.524,
      -13.605,
      17.82,
      -61.524,
      -12.455,
      17.82,
      -61.524,
      -12.455,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5473.0",
    polygon: [
      -29.923,
      -21.655,
      11.86,
      -29.923,
      -22.805,
      11.86,
      -29.923,
      -22.805,
      14.32,
      -29.923,
      -22.805,
      14.32,
      -29.923,
      -21.655,
      14.32,
      -29.923,
      -21.655,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5510.0",
    polygon: [
      -43.584,
      -19.336,
      11.86,
      -43.584,
      -18.186,
      11.86,
      -43.584,
      -18.186,
      14.32,
      -43.584,
      -18.186,
      14.32,
      -43.584,
      -19.336,
      14.32,
      -43.584,
      -19.336,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5472.0",
    polygon: [
      -29.923,
      -20.505,
      11.86,
      -29.923,
      -21.655,
      11.86,
      -29.923,
      -21.655,
      14.32,
      -29.923,
      -21.655,
      14.32,
      -29.923,
      -20.505,
      14.32,
      -29.923,
      -20.505,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5433.0",
    polygon: [
      -13.701,
      -7.235,
      11.86,
      -14.851,
      -7.235,
      11.86,
      -14.851,
      -7.235,
      14.32,
      -14.851,
      -7.235,
      14.32,
      -13.701,
      -7.235,
      14.32,
      -13.701,
      -7.235,
      11.86,
    ],
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5612.0",
    polygon: [
      -52.251,
      -7.235,
      15.36,
      -53.401,
      -7.235,
      15.36,
      -53.401,
      -7.235,
      17.82,
      -53.401,
      -7.235,
      17.82,
      -52.251,
      -7.235,
      17.82,
      -52.251,
      -7.235,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1203.0",
    polygon: [
      -61.524,
      -36.605,
      11.86,
      -61.524,
      -37.755,
      11.86,
      -61.524,
      -37.755,
      14.32,
      -61.524,
      -37.755,
      14.32,
      -61.524,
      -36.605,
      14.32,
      -61.524,
      -36.605,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "1319.0",
    polygon: [
      -61.524,
      -13.605,
      15.36,
      -61.524,
      -14.755,
      15.36,
      -61.524,
      -14.755,
      17.82,
      -61.524,
      -14.755,
      17.82,
      -61.524,
      -13.605,
      17.82,
      -61.524,
      -13.605,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5575.0",
    polygon: [
      -11.981,
      -12.455,
      15.36,
      -11.981,
      -11.305,
      15.36,
      -11.981,
      -11.305,
      17.82,
      -11.981,
      -11.305,
      17.82,
      -11.981,
      -12.455,
      17.82,
      -11.981,
      -12.455,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5614.0",
    polygon: [
      -49.926,
      -7.235,
      15.36,
      -51.076,
      -7.235,
      15.36,
      -51.076,
      -7.235,
      17.82,
      -51.076,
      -7.235,
      17.82,
      -49.926,
      -7.235,
      17.82,
      -49.926,
      -7.235,
      15.36,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "5514.0",
    polygon: [
      -43.584,
      -23.936,
      11.86,
      -43.584,
      -22.786,
      11.86,
      -43.584,
      -22.786,
      14.32,
      -43.584,
      -22.786,
      14.32,
      -43.584,
      -23.936,
      14.32,
      -43.584,
      -23.936,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5281.0",
    polygon: [
      -61.524,
      -35.455,
      4.86,
      -61.524,
      -36.605,
      4.86,
      -61.524,
      -36.605,
      7.32,
      -61.524,
      -36.605,
      7.32,
      -61.524,
      -35.455,
      7.32,
      -61.524,
      -35.455,
      4.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5437.0",
    polygon: [
      -11.981,
      -35.455,
      11.86,
      -11.981,
      -34.305,
      11.86,
      -11.981,
      -34.305,
      14.32,
      -11.981,
      -34.305,
      14.32,
      -11.981,
      -35.455,
      14.32,
      -11.981,
      -35.455,
      11.86,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5320.0",
    polygon: [
      -11.981,
      -13.605,
      8.36,
      -11.981,
      -12.455,
      8.36,
      -11.981,
      -12.455,
      10.82,
      -11.981,
      -12.455,
      10.82,
      -11.981,
      -13.605,
      10.82,
      -11.981,
      -13.605,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5398.0",
    polygon: [
      -61.524,
      -13.605,
      8.36,
      -61.524,
      -14.755,
      8.36,
      -61.524,
      -14.755,
      10.82,
      -61.524,
      -14.755,
      10.82,
      -61.524,
      -13.605,
      10.82,
      -61.524,
      -13.605,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "5359.0",
    polygon: [
      -51.101,
      -7.235,
      8.36,
      -52.251,
      -7.235,
      8.36,
      -52.251,
      -7.235,
      10.82,
      -52.251,
      -7.235,
      10.82,
      -51.101,
      -7.235,
      10.82,
      -51.101,
      -7.235,
      8.36,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 11:15": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "891.0",
    polygon: [
      -43.584,
      -22.786,
      4.86,
      -43.584,
      -21.636,
      4.86,
      -43.584,
      -21.636,
      7.32,
      -43.584,
      -21.636,
      7.32,
      -43.584,
      -22.786,
      7.32,
      -43.584,
      -22.786,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "852.0",
    polygon: [
      -29.923,
      -23.955,
      4.86,
      -29.923,
      -25.105,
      4.86,
      -29.923,
      -25.105,
      7.32,
      -29.923,
      -25.105,
      7.32,
      -29.923,
      -23.955,
      7.32,
      -29.923,
      -23.955,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5210.0",
    polygon: [
      -43.529,
      26.626,
      15.36,
      -43.529,
      27.776,
      15.36,
      -43.529,
      27.776,
      17.82,
      -43.529,
      27.776,
      17.82,
      -43.529,
      26.626,
      17.82,
      -43.529,
      26.626,
      15.36,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "812.0",
    polygon: [
      -11.981,
      -37.755,
      4.86,
      -11.981,
      -36.605,
      4.86,
      -11.981,
      -36.605,
      7.32,
      -11.981,
      -36.605,
      7.32,
      -11.981,
      -37.755,
      7.32,
      -11.981,
      -37.755,
      4.86,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "4703.0",
    polygon: [
      14.022,
      16.429,
      15.36,
      13.194,
      17.227,
      15.36,
      13.194,
      17.227,
      17.82,
      13.194,
      17.227,
      17.82,
      14.022,
      16.429,
      17.82,
      14.022,
      16.429,
      15.36,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "813.0",
    polygon: [
      -11.981,
      -36.605,
      4.86,
      -11.981,
      -35.455,
      4.86,
      -11.981,
      -35.455,
      7.32,
      -11.981,
      -35.455,
      7.32,
      -11.981,
      -36.605,
      7.32,
      -11.981,
      -36.605,
      4.86,
    ],
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "4659.0",
    polygon: [
      1.621,
      12.499,
      15.36,
      1.621,
      11.148,
      15.36,
      1.621,
      11.148,
      17.82,
      1.621,
      11.148,
      17.82,
      1.621,
      12.499,
      17.82,
      1.621,
      12.499,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "4566.0",
    polygon: [
      2.566,
      -21.663,
      0.031,
      2.566,
      -22.993,
      0.031,
      2.566,
      -22.993,
      2.761,
      2.566,
      -22.993,
      2.761,
      2.566,
      -21.663,
      2.761,
      2.566,
      -21.663,
      0.031,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5475.0",
    polygon: [
      -29.923,
      -23.955,
      11.86,
      -29.923,
      -25.105,
      11.86,
      -29.923,
      -25.105,
      14.32,
      -29.923,
      -25.105,
      14.32,
      -29.923,
      -23.955,
      14.32,
      -29.923,
      -23.955,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5436.0",
    polygon: [
      -11.981,
      -36.605,
      11.86,
      -11.981,
      -35.455,
      11.86,
      -11.981,
      -35.455,
      14.32,
      -11.981,
      -35.455,
      14.32,
      -11.981,
      -36.605,
      14.32,
      -11.981,
      -36.605,
      11.86,
    ],
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5280.0",
    polygon: [
      -61.524,
      -34.305,
      4.86,
      -61.524,
      -35.455,
      4.86,
      -61.524,
      -35.455,
      7.32,
      -61.524,
      -35.455,
      7.32,
      -61.524,
      -34.305,
      7.32,
      -61.524,
      -34.305,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5319.0",
    polygon: [
      -11.981,
      -14.755,
      8.36,
      -11.981,
      -13.605,
      8.36,
      -11.981,
      -13.605,
      10.82,
      -11.981,
      -13.605,
      10.82,
      -11.981,
      -14.755,
      10.82,
      -11.981,
      -14.755,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1202.0",
    polygon: [
      -61.524,
      -35.455,
      11.86,
      -61.524,
      -36.605,
      11.86,
      -61.524,
      -36.605,
      14.32,
      -61.524,
      -36.605,
      14.32,
      -61.524,
      -35.455,
      14.32,
      -61.524,
      -35.455,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "5175.0",
    polygon: [
      -11.63,
      28.066,
      15.36,
      -11.63,
      29.216,
      15.36,
      -11.63,
      29.216,
      17.82,
      -11.63,
      29.216,
      17.82,
      -11.63,
      28.066,
      17.82,
      -11.63,
      28.066,
      15.36,
    ],
    "04-15 13:00": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "890.0",
    polygon: [
      -43.584,
      -21.636,
      4.86,
      -43.584,
      -20.486,
      4.86,
      -43.584,
      -20.486,
      7.32,
      -43.584,
      -20.486,
      7.32,
      -43.584,
      -21.636,
      7.32,
      -43.584,
      -21.636,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "4567.0",
    polygon: [
      2.566,
      -20.343,
      0.031,
      2.566,
      -21.673,
      0.031,
      2.566,
      -21.673,
      2.761,
      2.566,
      -21.673,
      2.761,
      2.566,
      -20.343,
      2.761,
      2.566,
      -20.343,
      0.031,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5574.0",
    polygon: [
      -11.981,
      -13.605,
      15.36,
      -11.981,
      -12.455,
      15.36,
      -11.981,
      -12.455,
      17.82,
      -11.981,
      -12.455,
      17.82,
      -11.981,
      -13.605,
      17.82,
      -11.981,
      -13.605,
      15.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5209.0",
    polygon: [
      -43.529,
      25.495,
      15.36,
      -43.529,
      26.645,
      15.36,
      -43.529,
      26.645,
      17.82,
      -43.529,
      26.645,
      17.82,
      -43.529,
      25.495,
      17.82,
      -43.529,
      25.495,
      15.36,
    ],
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "5613.0",
    polygon: [
      -51.101,
      -7.235,
      15.36,
      -52.251,
      -7.235,
      15.36,
      -52.251,
      -7.235,
      17.82,
      -52.251,
      -7.235,
      17.82,
      -51.101,
      -7.235,
      17.82,
      -51.101,
      -7.235,
      15.36,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "851.0",
    polygon: [
      -29.923,
      -22.805,
      4.86,
      -29.923,
      -23.955,
      4.86,
      -29.923,
      -23.955,
      7.32,
      -29.923,
      -23.955,
      7.32,
      -29.923,
      -22.805,
      7.32,
      -29.923,
      -22.805,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1320.0",
    polygon: [
      -61.524,
      -14.755,
      15.36,
      -61.524,
      -15.905,
      15.36,
      -61.524,
      -15.905,
      17.82,
      -61.524,
      -15.905,
      17.82,
      -61.524,
      -14.755,
      17.82,
      -61.524,
      -14.755,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5513.0",
    polygon: [
      -43.584,
      -22.786,
      11.86,
      -43.584,
      -21.636,
      11.86,
      -43.584,
      -21.636,
      14.32,
      -43.584,
      -21.636,
      14.32,
      -43.584,
      -22.786,
      14.32,
      -43.584,
      -22.786,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "4704.0",
    polygon: [
      14.998,
      15.433,
      15.36,
      14.196,
      16.257,
      15.36,
      14.196,
      16.257,
      17.82,
      14.196,
      16.257,
      17.82,
      14.998,
      15.433,
      17.82,
      14.998,
      15.433,
      15.36,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "5358.0",
    polygon: [
      -52.251,
      -7.235,
      8.36,
      -53.401,
      -7.235,
      8.36,
      -53.401,
      -7.235,
      10.82,
      -53.401,
      -7.235,
      10.82,
      -52.251,
      -7.235,
      10.82,
      -52.251,
      -7.235,
      8.36,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "5397.0",
    polygon: [
      -61.524,
      -12.455,
      8.36,
      -61.524,
      -13.605,
      8.36,
      -61.524,
      -13.605,
      10.82,
      -61.524,
      -13.605,
      10.82,
      -61.524,
      -12.455,
      10.82,
      -61.524,
      -12.455,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "853.0",
    polygon: [
      -29.923,
      -25.105,
      4.86,
      -29.923,
      -26.255,
      4.86,
      -29.923,
      -26.255,
      7.32,
      -29.923,
      -26.255,
      7.32,
      -29.923,
      -25.105,
      7.32,
      -29.923,
      -25.105,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5211.0",
    polygon: [
      -43.529,
      27.776,
      15.36,
      -43.529,
      28.926,
      15.36,
      -43.529,
      28.926,
      17.82,
      -43.529,
      28.926,
      17.82,
      -43.529,
      27.776,
      17.82,
      -43.529,
      27.776,
      15.36,
    ],
    "04-15 11:30": 1,
    "04-15 12:45": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "814.0",
    polygon: [
      -11.981,
      -35.455,
      4.86,
      -11.981,
      -34.305,
      4.86,
      -11.981,
      -34.305,
      7.32,
      -11.981,
      -34.305,
      7.32,
      -11.981,
      -35.455,
      7.32,
      -11.981,
      -35.455,
      4.86,
    ],
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5137.0",
    polygon: [
      -43.529,
      17.987,
      11.86,
      -43.529,
      19.137,
      11.86,
      -43.529,
      19.137,
      14.32,
      -43.529,
      19.137,
      14.32,
      -43.529,
      17.987,
      14.32,
      -43.529,
      17.987,
      11.86,
    ],
    "04-15 16:15": 1,
  },
  {
    ID: "5176.0",
    polygon: [
      -11.63,
      26.205,
      15.36,
      -11.63,
      27.355,
      15.36,
      -11.63,
      27.355,
      17.82,
      -11.63,
      27.355,
      17.82,
      -11.63,
      26.205,
      17.82,
      -11.63,
      26.205,
      15.36,
    ],
    "04-15 15:15": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "4783.0",
    polygon: [
      1.621,
      21.457,
      15.36,
      1.621,
      20.307,
      15.36,
      1.621,
      20.307,
      17.82,
      1.621,
      20.307,
      17.82,
      1.621,
      21.457,
      17.82,
      1.621,
      21.457,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "5615.0",
    polygon: [
      -48.776,
      -7.235,
      15.36,
      -49.926,
      -7.235,
      15.36,
      -49.926,
      -7.235,
      17.82,
      -49.926,
      -7.235,
      17.82,
      -48.776,
      -7.235,
      17.82,
      -48.776,
      -7.235,
      15.36,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5576.0",
    polygon: [
      -11.981,
      -11.305,
      15.36,
      -11.981,
      -10.155,
      15.36,
      -11.981,
      -10.155,
      17.82,
      -11.981,
      -10.155,
      17.82,
      -11.981,
      -11.305,
      17.82,
      -11.981,
      -11.305,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5515.0",
    polygon: [
      -43.584,
      -25.086,
      11.86,
      -43.584,
      -23.936,
      11.86,
      -43.584,
      -23.936,
      14.32,
      -43.584,
      -23.936,
      14.32,
      -43.584,
      -25.086,
      14.32,
      -43.584,
      -25.086,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5477.0",
    polygon: [
      -29.923,
      -26.254,
      11.86,
      -29.923,
      -27.404,
      11.86,
      -29.923,
      -27.404,
      14.32,
      -29.923,
      -27.404,
      14.32,
      -29.923,
      -26.254,
      14.32,
      -29.923,
      -26.254,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5399.0",
    polygon: [
      -61.524,
      -14.755,
      8.36,
      -61.524,
      -15.905,
      8.36,
      -61.524,
      -15.905,
      10.82,
      -61.524,
      -15.905,
      10.82,
      -61.524,
      -14.755,
      10.82,
      -61.524,
      -14.755,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "892.0",
    polygon: [
      -43.584,
      -23.936,
      4.86,
      -43.584,
      -22.786,
      4.86,
      -43.584,
      -22.786,
      7.32,
      -43.584,
      -22.786,
      7.32,
      -43.584,
      -23.936,
      7.32,
      -43.584,
      -23.936,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5360.0",
    polygon: [
      -49.926,
      -7.235,
      8.36,
      -51.076,
      -7.235,
      8.36,
      -51.076,
      -7.235,
      10.82,
      -51.076,
      -7.235,
      10.82,
      -49.926,
      -7.235,
      10.82,
      -49.926,
      -7.235,
      8.36,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "5177.0",
    polygon: [
      -11.63,
      25.055,
      15.36,
      -11.63,
      26.205,
      15.36,
      -11.63,
      26.205,
      17.82,
      -11.63,
      26.205,
      17.82,
      -11.63,
      25.055,
      17.82,
      -11.63,
      25.055,
      15.36,
    ],
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "893.0",
    polygon: [
      -43.584,
      -25.086,
      4.86,
      -43.584,
      -23.936,
      4.86,
      -43.584,
      -23.936,
      7.32,
      -43.584,
      -23.936,
      7.32,
      -43.584,
      -25.086,
      7.32,
      -43.584,
      -25.086,
      4.86,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5361.0",
    polygon: [
      -48.776,
      -7.235,
      8.36,
      -49.926,
      -7.235,
      8.36,
      -49.926,
      -7.235,
      10.82,
      -49.926,
      -7.235,
      10.82,
      -48.776,
      -7.235,
      10.82,
      -48.776,
      -7.235,
      8.36,
    ],
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 12:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5438.0",
    polygon: [
      -11.981,
      -34.305,
      11.86,
      -11.981,
      -33.155,
      11.86,
      -11.981,
      -33.155,
      14.32,
      -11.981,
      -33.155,
      14.32,
      -11.981,
      -34.305,
      14.32,
      -11.981,
      -34.305,
      11.86,
    ],
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5212.0",
    polygon: [
      -43.529,
      28.926,
      15.36,
      -43.529,
      30.076,
      15.36,
      -43.529,
      30.076,
      17.82,
      -43.529,
      30.076,
      17.82,
      -43.529,
      28.926,
      17.82,
      -43.529,
      28.926,
      15.36,
    ],
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5138.0",
    polygon: [
      -43.529,
      19.747,
      11.86,
      -43.529,
      20.897,
      11.86,
      -43.529,
      20.897,
      14.32,
      -43.529,
      20.897,
      14.32,
      -43.529,
      19.747,
      14.32,
      -43.529,
      19.747,
      11.86,
    ],
    "04-15 16:15": 1,
  },
  {
    ID: "5282.0",
    polygon: [
      -61.524,
      -36.605,
      4.86,
      -61.524,
      -37.755,
      4.86,
      -61.524,
      -37.755,
      7.32,
      -61.524,
      -37.755,
      7.32,
      -61.524,
      -36.605,
      7.32,
      -61.524,
      -36.605,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "4568.0",
    polygon: [
      2.566,
      -19.002,
      0.031,
      2.566,
      -20.332,
      0.031,
      2.566,
      -20.332,
      2.761,
      2.566,
      -20.332,
      2.761,
      2.566,
      -19.002,
      2.761,
      2.566,
      -19.002,
      0.031,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5321.0",
    polygon: [
      -11.981,
      -12.455,
      8.36,
      -11.981,
      -11.305,
      8.36,
      -11.981,
      -11.305,
      10.82,
      -11.981,
      -11.305,
      10.82,
      -11.981,
      -12.455,
      10.82,
      -11.981,
      -12.455,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1321.0",
    polygon: [
      -61.524,
      -15.905,
      15.36,
      -61.524,
      -17.055,
      15.36,
      -61.524,
      -17.055,
      17.82,
      -61.524,
      -17.055,
      17.82,
      -61.524,
      -15.905,
      17.82,
      -61.524,
      -15.905,
      15.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5543.0",
    polygon: [
      -61.524,
      -37.755,
      11.86,
      -61.524,
      -38.905,
      11.86,
      -61.524,
      -38.905,
      14.32,
      -61.524,
      -38.905,
      14.32,
      -61.524,
      -37.755,
      14.32,
      -61.524,
      -37.755,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "854.0",
    polygon: [
      -29.923,
      -26.254,
      4.86,
      -29.923,
      -27.404,
      4.86,
      -29.923,
      -27.404,
      7.32,
      -29.923,
      -27.404,
      7.32,
      -29.923,
      -26.254,
      7.32,
      -29.923,
      -26.254,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5439.0",
    polygon: [
      -11.981,
      -33.155,
      11.86,
      -11.981,
      -32.005,
      11.86,
      -11.981,
      -32.005,
      14.32,
      -11.981,
      -32.005,
      14.32,
      -11.981,
      -33.155,
      14.32,
      -11.981,
      -33.155,
      11.86,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "815.0",
    polygon: [
      -11.981,
      -34.305,
      4.86,
      -11.981,
      -33.155,
      4.86,
      -11.981,
      -33.155,
      7.32,
      -11.981,
      -33.155,
      7.32,
      -11.981,
      -34.305,
      7.32,
      -11.981,
      -34.305,
      4.86,
    ],
    "04-15 11:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "5283.0",
    polygon: [
      -61.524,
      -37.755,
      4.86,
      -61.524,
      -38.905,
      4.86,
      -61.524,
      -38.905,
      7.32,
      -61.524,
      -38.905,
      7.32,
      -61.524,
      -37.755,
      7.32,
      -61.524,
      -37.755,
      4.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5400.0",
    polygon: [
      -61.524,
      -15.905,
      8.36,
      -61.524,
      -17.055,
      8.36,
      -61.524,
      -17.055,
      10.82,
      -61.524,
      -17.055,
      10.82,
      -61.524,
      -15.905,
      10.82,
      -61.524,
      -15.905,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "4705.0",
    polygon: [
      15.951,
      14.395,
      15.36,
      15.176,
      15.245,
      15.36,
      15.176,
      15.245,
      17.82,
      15.176,
      15.245,
      17.82,
      15.951,
      14.395,
      17.82,
      15.951,
      14.395,
      15.36,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "5322.0",
    polygon: [
      -11.981,
      -11.305,
      8.36,
      -11.981,
      -10.155,
      8.36,
      -11.981,
      -10.155,
      10.82,
      -11.981,
      -10.155,
      10.82,
      -11.981,
      -11.305,
      10.82,
      -11.981,
      -11.305,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5478.0",
    polygon: [
      -29.923,
      -27.404,
      11.86,
      -29.923,
      -28.554,
      11.86,
      -29.923,
      -28.554,
      14.32,
      -29.923,
      -28.554,
      14.32,
      -29.923,
      -27.404,
      14.32,
      -29.923,
      -27.404,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5616.0",
    polygon: [
      -47.626,
      -7.235,
      15.36,
      -48.776,
      -7.235,
      15.36,
      -48.776,
      -7.235,
      17.82,
      -48.776,
      -7.235,
      17.82,
      -47.626,
      -7.235,
      17.82,
      -47.626,
      -7.235,
      15.36,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5516.0",
    polygon: [
      -43.584,
      -26.236,
      11.86,
      -43.584,
      -25.086,
      11.86,
      -43.584,
      -25.086,
      14.32,
      -43.584,
      -25.086,
      14.32,
      -43.584,
      -26.236,
      14.32,
      -43.584,
      -26.236,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "4569.0",
    polygon: [
      2.566,
      -17.682,
      0.031,
      2.566,
      -19.012,
      0.031,
      2.566,
      -19.012,
      2.761,
      2.566,
      -19.012,
      2.761,
      2.566,
      -17.682,
      2.761,
      2.566,
      -17.682,
      0.031,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1322.0",
    polygon: [
      -61.524,
      -17.055,
      15.36,
      -61.524,
      -18.205,
      15.36,
      -61.524,
      -18.205,
      17.82,
      -61.524,
      -18.205,
      17.82,
      -61.524,
      -17.055,
      17.82,
      -61.524,
      -17.055,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5544.0",
    polygon: [
      -61.524,
      -38.905,
      11.86,
      -61.524,
      -40.055,
      11.86,
      -61.524,
      -40.055,
      14.32,
      -61.524,
      -40.055,
      14.32,
      -61.524,
      -38.905,
      14.32,
      -61.524,
      -38.905,
      11.86,
    ],
    "04-15 06:30": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5577.0",
    polygon: [
      -11.981,
      -10.155,
      15.36,
      -11.981,
      -9.005,
      15.36,
      -11.981,
      -9.005,
      17.82,
      -11.981,
      -9.005,
      17.82,
      -11.981,
      -10.155,
      17.82,
      -11.981,
      -10.155,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5213.0",
    polygon: [
      -61.429,
      30.076,
      15.36,
      -61.429,
      28.926,
      15.36,
      -61.429,
      28.926,
      17.82,
      -61.429,
      28.926,
      17.82,
      -61.429,
      30.076,
      17.82,
      -61.429,
      30.076,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:30": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1323.0",
    polygon: [
      -61.524,
      -18.205,
      15.36,
      -61.524,
      -19.355,
      15.36,
      -61.524,
      -19.355,
      17.82,
      -61.524,
      -19.355,
      17.82,
      -61.524,
      -18.205,
      17.82,
      -61.524,
      -18.205,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5545.0",
    polygon: [
      -27.529,
      -7.235,
      15.36,
      -28.679,
      -7.235,
      15.36,
      -28.679,
      -7.235,
      17.82,
      -28.679,
      -7.235,
      17.82,
      -27.529,
      -7.235,
      17.82,
      -27.529,
      -7.235,
      15.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "4706.0",
    polygon: [
      16.902,
      13.287,
      15.36,
      16.156,
      14.162,
      15.36,
      16.156,
      14.162,
      17.82,
      16.156,
      14.162,
      17.82,
      16.902,
      13.287,
      17.82,
      16.902,
      13.287,
      15.36,
    ],
    "04-15 17:15": 1,
  },
  {
    ID: "5578.0",
    polygon: [
      -11.981,
      -9.005,
      15.36,
      -11.981,
      -7.855,
      15.36,
      -11.981,
      -7.855,
      17.82,
      -11.981,
      -7.855,
      17.82,
      -11.981,
      -9.005,
      17.82,
      -11.981,
      -9.005,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "855.0",
    polygon: [
      -29.923,
      -27.404,
      4.86,
      -29.923,
      -28.554,
      4.86,
      -29.923,
      -28.554,
      7.32,
      -29.923,
      -28.554,
      7.32,
      -29.923,
      -27.404,
      7.32,
      -29.923,
      -27.404,
      4.86,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5617.0",
    polygon: [
      -46.476,
      -7.235,
      15.36,
      -47.626,
      -7.235,
      15.36,
      -47.626,
      -7.235,
      17.82,
      -47.626,
      -7.235,
      17.82,
      -46.476,
      -7.235,
      17.82,
      -46.476,
      -7.235,
      15.36,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "816.0",
    polygon: [
      -11.981,
      -33.155,
      4.86,
      -11.981,
      -32.005,
      4.86,
      -11.981,
      -32.005,
      7.32,
      -11.981,
      -32.005,
      7.32,
      -11.981,
      -33.155,
      7.32,
      -11.981,
      -33.155,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "5284.0",
    polygon: [
      -61.524,
      -38.905,
      4.86,
      -61.524,
      -40.055,
      4.86,
      -61.524,
      -40.055,
      7.32,
      -61.524,
      -40.055,
      7.32,
      -61.524,
      -38.905,
      7.32,
      -61.524,
      -38.905,
      4.86,
    ],
    "04-15 06:30": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5517.0",
    polygon: [
      -43.584,
      -27.386,
      11.86,
      -43.584,
      -26.236,
      11.86,
      -43.584,
      -26.236,
      14.32,
      -43.584,
      -26.236,
      14.32,
      -43.584,
      -27.386,
      14.32,
      -43.584,
      -27.386,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5139.0",
    polygon: [
      -43.529,
      20.897,
      11.86,
      -43.529,
      22.047,
      11.86,
      -43.529,
      22.047,
      14.32,
      -43.529,
      22.047,
      14.32,
      -43.529,
      20.897,
      14.32,
      -43.529,
      20.897,
      11.86,
    ],
    "04-15 16:15": 1,
  },
  {
    ID: "5440.0",
    polygon: [
      -11.981,
      -32.005,
      11.86,
      -11.981,
      -30.855,
      11.86,
      -11.981,
      -30.855,
      14.32,
      -11.981,
      -30.855,
      14.32,
      -11.981,
      -32.005,
      14.32,
      -11.981,
      -32.005,
      11.86,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5479.0",
    polygon: [
      -29.923,
      -28.554,
      11.86,
      -29.923,
      -29.704,
      11.86,
      -29.923,
      -29.704,
      14.32,
      -29.923,
      -29.704,
      14.32,
      -29.923,
      -28.554,
      14.32,
      -29.923,
      -28.554,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "4707.0",
    polygon: [
      17.774,
      12.201,
      15.36,
      17.057,
      13.099,
      15.36,
      17.057,
      13.099,
      17.82,
      17.057,
      13.099,
      17.82,
      17.774,
      12.201,
      17.82,
      17.774,
      12.201,
      15.36,
    ],
    "04-15 17:30": 1,
  },
  {
    ID: "5178.0",
    polygon: [
      -11.63,
      23.418,
      15.36,
      -11.63,
      24.568,
      15.36,
      -11.63,
      24.568,
      17.82,
      -11.63,
      24.568,
      17.82,
      -11.63,
      23.418,
      17.82,
      -11.63,
      23.418,
      15.36,
    ],
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "5401.0",
    polygon: [
      -61.524,
      -17.055,
      8.36,
      -61.524,
      -18.205,
      8.36,
      -61.524,
      -18.205,
      10.82,
      -61.524,
      -18.205,
      10.82,
      -61.524,
      -17.055,
      10.82,
      -61.524,
      -17.055,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "5323.0",
    polygon: [
      -11.981,
      -10.155,
      8.36,
      -11.981,
      -9.005,
      8.36,
      -11.981,
      -9.005,
      10.82,
      -11.981,
      -9.005,
      10.82,
      -11.981,
      -10.155,
      10.82,
      -11.981,
      -10.155,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "894.0",
    polygon: [
      -43.584,
      -26.236,
      4.86,
      -43.584,
      -25.086,
      4.86,
      -43.584,
      -25.086,
      7.32,
      -43.584,
      -25.086,
      7.32,
      -43.584,
      -26.236,
      7.32,
      -43.584,
      -26.236,
      4.86,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5362.0",
    polygon: [
      -47.626,
      -7.235,
      8.36,
      -48.776,
      -7.235,
      8.36,
      -48.776,
      -7.235,
      10.82,
      -48.776,
      -7.235,
      10.82,
      -47.626,
      -7.235,
      10.82,
      -47.626,
      -7.235,
      8.36,
    ],
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5441.0",
    polygon: [
      -11.981,
      -30.855,
      11.86,
      -11.981,
      -29.705,
      11.86,
      -11.981,
      -29.705,
      14.32,
      -11.981,
      -29.705,
      14.32,
      -11.981,
      -30.855,
      14.32,
      -11.981,
      -30.855,
      11.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5285.0",
    polygon: [
      -27.529,
      -7.235,
      8.36,
      -28.679,
      -7.235,
      8.36,
      -28.679,
      -7.235,
      10.82,
      -28.679,
      -7.235,
      10.82,
      -27.529,
      -7.235,
      10.82,
      -27.529,
      -7.235,
      8.36,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:30": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "5324.0",
    polygon: [
      -11.981,
      -9.005,
      8.36,
      -11.981,
      -7.855,
      8.36,
      -11.981,
      -7.855,
      10.82,
      -11.981,
      -7.855,
      10.82,
      -11.981,
      -9.005,
      10.82,
      -11.981,
      -9.005,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5402.0",
    polygon: [
      -61.524,
      -18.205,
      8.36,
      -61.524,
      -19.355,
      8.36,
      -61.524,
      -19.355,
      10.82,
      -61.524,
      -19.355,
      10.82,
      -61.524,
      -18.205,
      10.82,
      -61.524,
      -18.205,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "895.0",
    polygon: [
      -43.584,
      -27.386,
      4.86,
      -43.584,
      -26.236,
      4.86,
      -43.584,
      -26.236,
      7.32,
      -43.584,
      -26.236,
      7.32,
      -43.584,
      -27.386,
      7.32,
      -43.584,
      -27.386,
      4.86,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5363.0",
    polygon: [
      -46.476,
      -7.235,
      8.36,
      -47.626,
      -7.235,
      8.36,
      -47.626,
      -7.235,
      10.82,
      -47.626,
      -7.235,
      10.82,
      -46.476,
      -7.235,
      10.82,
      -46.476,
      -7.235,
      8.36,
    ],
    "04-15 10:45": 1,
    "04-15 11:45": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "4786.0",
    polygon: [
      1.621,
      21.457,
      11.86,
      1.621,
      20.307,
      11.86,
      1.621,
      20.307,
      14.32,
      1.621,
      20.307,
      14.32,
      1.621,
      21.457,
      14.32,
      1.621,
      21.457,
      11.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "1324.0",
    polygon: [
      -61.524,
      -19.355,
      15.36,
      -61.524,
      -20.505,
      15.36,
      -61.524,
      -20.505,
      17.82,
      -61.524,
      -20.505,
      17.82,
      -61.524,
      -19.355,
      17.82,
      -61.524,
      -19.355,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5179.0",
    polygon: [
      -11.63,
      22.268,
      15.36,
      -11.63,
      23.418,
      15.36,
      -11.63,
      23.418,
      17.82,
      -11.63,
      23.418,
      17.82,
      -11.63,
      22.268,
      17.82,
      -11.63,
      22.268,
      15.36,
    ],
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5546.0",
    polygon: [
      -26.371,
      -7.235,
      15.36,
      -27.521,
      -7.235,
      15.36,
      -27.521,
      -7.235,
      17.82,
      -27.521,
      -7.235,
      17.82,
      -26.371,
      -7.235,
      17.82,
      -26.371,
      -7.235,
      15.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "4664.0",
    polygon: [
      1.621,
      13.648,
      11.86,
      1.621,
      12.498,
      11.86,
      1.621,
      12.498,
      14.32,
      1.621,
      12.498,
      14.32,
      1.621,
      13.648,
      14.32,
      1.621,
      13.648,
      11.86,
    ],
    "04-15 06:30": 1,
  },
  {
    ID: "5140.0",
    polygon: [
      -43.529,
      22.046,
      11.86,
      -43.529,
      23.196,
      11.86,
      -43.529,
      23.196,
      14.32,
      -43.529,
      23.196,
      14.32,
      -43.529,
      22.046,
      14.32,
      -43.529,
      22.046,
      11.86,
    ],
    "04-15 16:15": 1,
  },
  {
    ID: "5214.0",
    polygon: [
      -61.429,
      28.927,
      15.36,
      -61.429,
      27.777,
      15.36,
      -61.429,
      27.777,
      17.82,
      -61.429,
      27.777,
      17.82,
      -61.429,
      28.927,
      17.82,
      -61.429,
      28.927,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "856.0",
    polygon: [
      -29.923,
      -28.554,
      4.86,
      -29.923,
      -29.704,
      4.86,
      -29.923,
      -29.704,
      7.32,
      -29.923,
      -29.704,
      7.32,
      -29.923,
      -28.554,
      7.32,
      -29.923,
      -28.554,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "817.0",
    polygon: [
      -11.981,
      -32.005,
      4.86,
      -11.981,
      -30.855,
      4.86,
      -11.981,
      -30.855,
      7.32,
      -11.981,
      -30.855,
      7.32,
      -11.981,
      -32.005,
      7.32,
      -11.981,
      -32.005,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5579.0",
    polygon: [
      -29.923,
      -7.855,
      15.36,
      -29.923,
      -9.005,
      15.36,
      -29.923,
      -9.005,
      17.82,
      -29.923,
      -9.005,
      17.82,
      -29.923,
      -7.855,
      17.82,
      -29.923,
      -7.855,
      15.36,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5618.0",
    polygon: [
      -45.326,
      -7.235,
      15.36,
      -46.476,
      -7.235,
      15.36,
      -46.476,
      -7.235,
      17.82,
      -46.476,
      -7.235,
      17.82,
      -45.326,
      -7.235,
      17.82,
      -45.326,
      -7.235,
      15.36,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5480.0",
    polygon: [
      -29.923,
      -29.704,
      11.86,
      -29.923,
      -30.854,
      11.86,
      -29.923,
      -30.854,
      14.32,
      -29.923,
      -30.854,
      14.32,
      -29.923,
      -29.704,
      14.32,
      -29.923,
      -29.704,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5518.0",
    polygon: [
      -43.584,
      -28.536,
      11.86,
      -43.584,
      -27.386,
      11.86,
      -43.584,
      -27.386,
      14.32,
      -43.584,
      -27.386,
      14.32,
      -43.584,
      -28.536,
      14.32,
      -43.584,
      -28.536,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5141.0",
    polygon: [
      -43.529,
      23.196,
      11.86,
      -43.529,
      24.346,
      11.86,
      -43.529,
      24.346,
      14.32,
      -43.529,
      24.346,
      14.32,
      -43.529,
      23.196,
      14.32,
      -43.529,
      23.196,
      11.86,
    ],
    "04-15 16:15": 1,
  },
  {
    ID: "5442.0",
    polygon: [
      -11.981,
      -29.705,
      11.86,
      -11.981,
      -28.555,
      11.86,
      -11.981,
      -28.555,
      14.32,
      -11.981,
      -28.555,
      14.32,
      -11.981,
      -29.705,
      14.32,
      -11.981,
      -29.705,
      11.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5286.0",
    polygon: [
      -26.371,
      -7.235,
      8.36,
      -27.521,
      -7.235,
      8.36,
      -27.521,
      -7.235,
      10.82,
      -27.521,
      -7.235,
      10.82,
      -26.371,
      -7.235,
      10.82,
      -26.371,
      -7.235,
      8.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 11:00": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "5215.0",
    polygon: [
      -61.429,
      27.289,
      15.36,
      -61.429,
      26.139,
      15.36,
      -61.429,
      26.139,
      17.82,
      -61.429,
      26.139,
      17.82,
      -61.429,
      27.289,
      17.82,
      -61.429,
      27.289,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:30": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "857.0",
    polygon: [
      -29.923,
      -29.704,
      4.86,
      -29.923,
      -30.854,
      4.86,
      -29.923,
      -30.854,
      7.32,
      -29.923,
      -30.854,
      7.32,
      -29.923,
      -29.704,
      7.32,
      -29.923,
      -29.704,
      4.86,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "818.0",
    polygon: [
      -11.981,
      -30.855,
      4.86,
      -11.981,
      -29.705,
      4.86,
      -11.981,
      -29.705,
      7.32,
      -11.981,
      -29.705,
      7.32,
      -11.981,
      -30.855,
      7.32,
      -11.981,
      -30.855,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5325.0",
    polygon: [
      -29.923,
      -7.855,
      8.36,
      -29.923,
      -9.005,
      8.36,
      -29.923,
      -9.005,
      10.82,
      -29.923,
      -9.005,
      10.82,
      -29.923,
      -7.855,
      10.82,
      -29.923,
      -7.855,
      8.36,
    ],
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5403.0",
    polygon: [
      -61.524,
      -19.355,
      8.36,
      -61.524,
      -20.505,
      8.36,
      -61.524,
      -20.505,
      10.82,
      -61.524,
      -20.505,
      10.82,
      -61.524,
      -19.355,
      10.82,
      -61.524,
      -19.355,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "896.0",
    polygon: [
      -43.584,
      -28.536,
      4.86,
      -43.584,
      -27.386,
      4.86,
      -43.584,
      -27.386,
      7.32,
      -43.584,
      -27.386,
      7.32,
      -43.584,
      -28.536,
      7.32,
      -43.584,
      -28.536,
      4.86,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5364.0",
    polygon: [
      -45.326,
      -7.235,
      8.36,
      -46.476,
      -7.235,
      8.36,
      -46.476,
      -7.235,
      10.82,
      -46.476,
      -7.235,
      10.82,
      -45.326,
      -7.235,
      10.82,
      -45.326,
      -7.235,
      8.36,
    ],
    "04-15 10:45": 1,
    "04-15 11:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "1325.0",
    polygon: [
      -61.524,
      -20.505,
      15.36,
      -61.524,
      -21.655,
      15.36,
      -61.524,
      -21.655,
      17.82,
      -61.524,
      -21.655,
      17.82,
      -61.524,
      -20.505,
      17.82,
      -61.524,
      -20.505,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5547.0",
    polygon: [
      -25.221,
      -7.235,
      15.36,
      -26.371,
      -7.235,
      15.36,
      -26.371,
      -7.235,
      17.82,
      -26.371,
      -7.235,
      17.82,
      -25.221,
      -7.235,
      17.82,
      -25.221,
      -7.235,
      15.36,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "5580.0",
    polygon: [
      -29.923,
      -9.005,
      15.36,
      -29.923,
      -10.155,
      15.36,
      -29.923,
      -10.155,
      17.82,
      -29.923,
      -10.155,
      17.82,
      -29.923,
      -9.005,
      17.82,
      -29.923,
      -9.005,
      15.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5619.0",
    polygon: [
      -44.176,
      -7.235,
      15.36,
      -45.326,
      -7.235,
      15.36,
      -45.326,
      -7.235,
      17.82,
      -45.326,
      -7.235,
      17.82,
      -44.176,
      -7.235,
      17.82,
      -44.176,
      -7.235,
      15.36,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5481.0",
    polygon: [
      -29.923,
      -30.854,
      11.86,
      -29.923,
      -32.004,
      11.86,
      -29.923,
      -32.004,
      14.32,
      -29.923,
      -32.004,
      14.32,
      -29.923,
      -30.854,
      14.32,
      -29.923,
      -30.854,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5519.0",
    polygon: [
      -43.584,
      -29.686,
      11.86,
      -43.584,
      -28.536,
      11.86,
      -43.584,
      -28.536,
      14.32,
      -43.584,
      -28.536,
      14.32,
      -43.584,
      -29.686,
      14.32,
      -43.584,
      -29.686,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5142.0",
    polygon: [
      -43.529,
      24.346,
      11.86,
      -43.529,
      25.496,
      11.86,
      -43.529,
      25.496,
      14.32,
      -43.529,
      25.496,
      14.32,
      -43.529,
      24.346,
      14.32,
      -43.529,
      24.346,
      11.86,
    ],
    "04-15 16:15": 1,
  },
  {
    ID: "5443.0",
    polygon: [
      -11.981,
      -28.555,
      11.86,
      -11.981,
      -27.405,
      11.86,
      -11.981,
      -27.405,
      14.32,
      -11.981,
      -27.405,
      14.32,
      -11.981,
      -28.555,
      14.32,
      -11.981,
      -28.555,
      11.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5365.0",
    polygon: [
      -44.176,
      -7.235,
      8.36,
      -45.326,
      -7.235,
      8.36,
      -45.326,
      -7.235,
      10.82,
      -45.326,
      -7.235,
      10.82,
      -44.176,
      -7.235,
      10.82,
      -44.176,
      -7.235,
      8.36,
    ],
    "04-15 11:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 14:00": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "5326.0",
    polygon: [
      -29.923,
      -9.005,
      8.36,
      -29.923,
      -10.155,
      8.36,
      -29.923,
      -10.155,
      10.82,
      -29.923,
      -10.155,
      10.82,
      -29.923,
      -9.005,
      10.82,
      -29.923,
      -9.005,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5404.0",
    polygon: [
      -61.524,
      -20.505,
      8.36,
      -61.524,
      -21.655,
      8.36,
      -61.524,
      -21.655,
      10.82,
      -61.524,
      -21.655,
      10.82,
      -61.524,
      -20.505,
      10.82,
      -61.524,
      -20.505,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "819.0",
    polygon: [
      -11.981,
      -29.705,
      4.86,
      -11.981,
      -28.555,
      4.86,
      -11.981,
      -28.555,
      7.32,
      -11.981,
      -28.555,
      7.32,
      -11.981,
      -29.705,
      7.32,
      -11.981,
      -29.705,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "858.0",
    polygon: [
      -29.923,
      -30.854,
      4.86,
      -29.923,
      -32.004,
      4.86,
      -29.923,
      -32.004,
      7.32,
      -29.923,
      -32.004,
      7.32,
      -29.923,
      -30.854,
      7.32,
      -29.923,
      -30.854,
      4.86,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5216.0",
    polygon: [
      -61.429,
      26.139,
      15.36,
      -61.429,
      24.989,
      15.36,
      -61.429,
      24.989,
      17.82,
      -61.429,
      24.989,
      17.82,
      -61.429,
      26.139,
      17.82,
      -61.429,
      26.139,
      15.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "5581.0",
    polygon: [
      -29.923,
      -10.155,
      15.36,
      -29.923,
      -11.305,
      15.36,
      -29.923,
      -11.305,
      17.82,
      -29.923,
      -11.305,
      17.82,
      -29.923,
      -10.155,
      17.82,
      -29.923,
      -10.155,
      15.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5620.0",
    polygon: [
      -43.584,
      -9.005,
      15.36,
      -43.584,
      -7.855,
      15.36,
      -43.584,
      -7.855,
      17.82,
      -43.584,
      -7.855,
      17.82,
      -43.584,
      -9.005,
      17.82,
      -43.584,
      -9.005,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5482.0",
    polygon: [
      -29.923,
      -32.004,
      11.86,
      -29.923,
      -33.154,
      11.86,
      -29.923,
      -33.154,
      14.32,
      -29.923,
      -33.154,
      14.32,
      -29.923,
      -32.004,
      14.32,
      -29.923,
      -32.004,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5520.0",
    polygon: [
      -43.584,
      -30.836,
      11.86,
      -43.584,
      -29.686,
      11.86,
      -43.584,
      -29.686,
      14.32,
      -43.584,
      -29.686,
      14.32,
      -43.584,
      -30.836,
      14.32,
      -43.584,
      -30.836,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "897.0",
    polygon: [
      -43.584,
      -29.686,
      4.86,
      -43.584,
      -28.536,
      4.86,
      -43.584,
      -28.536,
      7.32,
      -43.584,
      -28.536,
      7.32,
      -43.584,
      -29.686,
      7.32,
      -43.584,
      -29.686,
      4.86,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5287.0",
    polygon: [
      -25.221,
      -7.235,
      8.36,
      -26.371,
      -7.235,
      8.36,
      -26.371,
      -7.235,
      10.82,
      -26.371,
      -7.235,
      10.82,
      -25.221,
      -7.235,
      10.82,
      -25.221,
      -7.235,
      8.36,
    ],
    "04-15 08:15": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "5217.0",
    polygon: [
      -61.429,
      24.989,
      15.36,
      -61.429,
      23.839,
      15.36,
      -61.429,
      23.839,
      17.82,
      -61.429,
      23.839,
      17.82,
      -61.429,
      24.989,
      17.82,
      -61.429,
      24.989,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:30": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "5582.0",
    polygon: [
      -29.923,
      -11.305,
      15.36,
      -29.923,
      -12.455,
      15.36,
      -29.923,
      -12.455,
      17.82,
      -29.923,
      -12.455,
      17.82,
      -29.923,
      -11.305,
      17.82,
      -29.923,
      -11.305,
      15.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "898.0",
    polygon: [
      -43.584,
      -30.836,
      4.86,
      -43.584,
      -29.686,
      4.86,
      -43.584,
      -29.686,
      7.32,
      -43.584,
      -29.686,
      7.32,
      -43.584,
      -30.836,
      7.32,
      -43.584,
      -30.836,
      4.86,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "820.0",
    polygon: [
      -11.981,
      -28.555,
      4.86,
      -11.981,
      -27.405,
      4.86,
      -11.981,
      -27.405,
      7.32,
      -11.981,
      -27.405,
      7.32,
      -11.981,
      -28.555,
      7.32,
      -11.981,
      -28.555,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5621.0",
    polygon: [
      -43.584,
      -10.136,
      15.36,
      -43.584,
      -8.986,
      15.36,
      -43.584,
      -8.986,
      17.82,
      -43.584,
      -8.986,
      17.82,
      -43.584,
      -10.136,
      17.82,
      -43.584,
      -10.136,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5405.0",
    polygon: [
      -61.524,
      -21.655,
      8.36,
      -61.524,
      -22.805,
      8.36,
      -61.524,
      -22.805,
      10.82,
      -61.524,
      -22.805,
      10.82,
      -61.524,
      -21.655,
      10.82,
      -61.524,
      -21.655,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "1326.0",
    polygon: [
      -61.524,
      -21.655,
      15.36,
      -61.524,
      -22.805,
      15.36,
      -61.524,
      -22.805,
      17.82,
      -61.524,
      -22.805,
      17.82,
      -61.524,
      -21.655,
      17.82,
      -61.524,
      -21.655,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5521.0",
    polygon: [
      -43.584,
      -31.986,
      11.86,
      -43.584,
      -30.836,
      11.86,
      -43.584,
      -30.836,
      14.32,
      -43.584,
      -30.836,
      14.32,
      -43.584,
      -31.986,
      14.32,
      -43.584,
      -31.986,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5548.0",
    polygon: [
      -24.071,
      -7.235,
      15.36,
      -25.221,
      -7.235,
      15.36,
      -25.221,
      -7.235,
      17.82,
      -25.221,
      -7.235,
      17.82,
      -24.071,
      -7.235,
      17.82,
      -24.071,
      -7.235,
      15.36,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "5143.0",
    polygon: [
      -43.529,
      25.495,
      11.86,
      -43.529,
      26.645,
      11.86,
      -43.529,
      26.645,
      14.32,
      -43.529,
      26.645,
      14.32,
      -43.529,
      25.495,
      14.32,
      -43.529,
      25.495,
      11.86,
    ],
    "04-15 15:30": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "5367.0",
    polygon: [
      -43.584,
      -10.136,
      8.36,
      -43.584,
      -8.986,
      8.36,
      -43.584,
      -8.986,
      10.82,
      -43.584,
      -8.986,
      10.82,
      -43.584,
      -10.136,
      10.82,
      -43.584,
      -10.136,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "4788.0",
    polygon: [
      1.621,
      22.759,
      15.36,
      1.621,
      21.609,
      15.36,
      1.621,
      21.609,
      17.82,
      1.621,
      21.609,
      17.82,
      1.621,
      22.759,
      17.82,
      1.621,
      22.759,
      15.36,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "5483.0",
    polygon: [
      -29.923,
      -33.154,
      11.86,
      -29.923,
      -34.304,
      11.86,
      -29.923,
      -34.304,
      14.32,
      -29.923,
      -34.304,
      14.32,
      -29.923,
      -33.154,
      14.32,
      -29.923,
      -33.154,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5366.0",
    polygon: [
      -43.584,
      -9.005,
      8.36,
      -43.584,
      -7.855,
      8.36,
      -43.584,
      -7.855,
      10.82,
      -43.584,
      -7.855,
      10.82,
      -43.584,
      -9.005,
      10.82,
      -43.584,
      -9.005,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5549.0",
    polygon: [
      -22.921,
      -7.235,
      15.36,
      -24.071,
      -7.235,
      15.36,
      -24.071,
      -7.235,
      17.82,
      -24.071,
      -7.235,
      17.82,
      -22.921,
      -7.235,
      17.82,
      -22.921,
      -7.235,
      15.36,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "5444.0",
    polygon: [
      -11.981,
      -27.405,
      11.86,
      -11.981,
      -26.255,
      11.86,
      -11.981,
      -26.255,
      14.32,
      -11.981,
      -26.255,
      14.32,
      -11.981,
      -27.405,
      14.32,
      -11.981,
      -27.405,
      11.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "859.0",
    polygon: [
      -29.923,
      -32.004,
      4.86,
      -29.923,
      -33.154,
      4.86,
      -29.923,
      -33.154,
      7.32,
      -29.923,
      -33.154,
      7.32,
      -29.923,
      -32.004,
      7.32,
      -29.923,
      -32.004,
      4.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "5109.0",
    polygon: [
      -11.63,
      28.066,
      11.86,
      -11.63,
      29.216,
      11.86,
      -11.63,
      29.216,
      14.32,
      -11.63,
      29.216,
      14.32,
      -11.63,
      28.066,
      14.32,
      -11.63,
      28.066,
      11.86,
    ],
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "4665.0",
    polygon: [
      1.621,
      12.499,
      11.86,
      1.621,
      11.148,
      11.86,
      1.621,
      11.148,
      14.32,
      1.621,
      11.148,
      14.32,
      1.621,
      12.499,
      14.32,
      1.621,
      12.499,
      11.86,
    ],
    "04-15 06:30": 1,
  },
  {
    ID: "5181.0",
    polygon: [
      -11.63,
      18.004,
      15.36,
      -11.63,
      19.154,
      15.36,
      -11.63,
      19.154,
      17.82,
      -11.63,
      19.154,
      17.82,
      -11.63,
      18.004,
      17.82,
      -11.63,
      18.004,
      15.36,
    ],
    "04-15 16:45": 1,
  },
  {
    ID: "5288.0",
    polygon: [
      -24.071,
      -7.235,
      8.36,
      -25.221,
      -7.235,
      8.36,
      -25.221,
      -7.235,
      10.82,
      -25.221,
      -7.235,
      10.82,
      -24.071,
      -7.235,
      10.82,
      -24.071,
      -7.235,
      8.36,
    ],
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "1327.0",
    polygon: [
      -61.524,
      -22.805,
      15.36,
      -61.524,
      -23.955,
      15.36,
      -61.524,
      -23.955,
      17.82,
      -61.524,
      -23.955,
      17.82,
      -61.524,
      -22.805,
      17.82,
      -61.524,
      -22.805,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "821.0",
    polygon: [
      -11.981,
      -27.405,
      4.86,
      -11.981,
      -26.255,
      4.86,
      -11.981,
      -26.255,
      7.32,
      -11.981,
      -26.255,
      7.32,
      -11.981,
      -27.405,
      7.32,
      -11.981,
      -27.405,
      4.86,
    ],
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5484.0",
    polygon: [
      -29.923,
      -34.304,
      11.86,
      -29.923,
      -35.454,
      11.86,
      -29.923,
      -35.454,
      14.32,
      -29.923,
      -35.454,
      14.32,
      -29.923,
      -34.304,
      14.32,
      -29.923,
      -34.304,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5522.0",
    polygon: [
      -43.584,
      -33.136,
      11.86,
      -43.584,
      -31.986,
      11.86,
      -43.584,
      -31.986,
      14.32,
      -43.584,
      -31.986,
      14.32,
      -43.584,
      -33.136,
      14.32,
      -43.584,
      -33.136,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5289.0",
    polygon: [
      -22.921,
      -7.235,
      8.36,
      -24.071,
      -7.235,
      8.36,
      -24.071,
      -7.235,
      10.82,
      -24.071,
      -7.235,
      10.82,
      -22.921,
      -7.235,
      10.82,
      -22.921,
      -7.235,
      8.36,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:15": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "5445.0",
    polygon: [
      -11.981,
      -26.255,
      11.86,
      -11.981,
      -25.105,
      11.86,
      -11.981,
      -25.105,
      14.32,
      -11.981,
      -25.105,
      14.32,
      -11.981,
      -26.255,
      14.32,
      -11.981,
      -26.255,
      11.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "899.0",
    polygon: [
      -43.584,
      -31.986,
      4.86,
      -43.584,
      -30.836,
      4.86,
      -43.584,
      -30.836,
      7.32,
      -43.584,
      -30.836,
      7.32,
      -43.584,
      -31.986,
      7.32,
      -43.584,
      -31.986,
      4.86,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5406.0",
    polygon: [
      -61.524,
      -22.805,
      8.36,
      -61.524,
      -23.955,
      8.36,
      -61.524,
      -23.955,
      10.82,
      -61.524,
      -23.955,
      10.82,
      -61.524,
      -22.805,
      10.82,
      -61.524,
      -22.805,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5328.0",
    polygon: [
      -29.923,
      -11.305,
      8.36,
      -29.923,
      -12.455,
      8.36,
      -29.923,
      -12.455,
      10.82,
      -29.923,
      -12.455,
      10.82,
      -29.923,
      -11.305,
      10.82,
      -29.923,
      -11.305,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5144.0",
    polygon: [
      -43.529,
      26.626,
      11.86,
      -43.529,
      27.776,
      11.86,
      -43.529,
      27.776,
      14.32,
      -43.529,
      27.776,
      14.32,
      -43.529,
      26.626,
      14.32,
      -43.529,
      26.626,
      11.86,
    ],
    "04-15 15:30": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "860.0",
    polygon: [
      -29.923,
      -33.154,
      4.86,
      -29.923,
      -34.304,
      4.86,
      -29.923,
      -34.304,
      7.32,
      -29.923,
      -34.304,
      7.32,
      -29.923,
      -33.154,
      7.32,
      -29.923,
      -33.154,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "5218.0",
    polygon: [
      -61.429,
      21.889,
      15.36,
      -61.429,
      20.739,
      15.36,
      -61.429,
      20.739,
      17.82,
      -61.429,
      20.739,
      17.82,
      -61.429,
      21.889,
      17.82,
      -61.429,
      21.889,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "5550.0",
    polygon: [
      -21.771,
      -7.235,
      15.36,
      -22.921,
      -7.235,
      15.36,
      -22.921,
      -7.235,
      17.82,
      -22.921,
      -7.235,
      17.82,
      -21.771,
      -7.235,
      17.82,
      -21.771,
      -7.235,
      15.36,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1328.0",
    polygon: [
      -61.524,
      -23.955,
      15.36,
      -61.524,
      -25.105,
      15.36,
      -61.524,
      -25.105,
      17.82,
      -61.524,
      -25.105,
      17.82,
      -61.524,
      -23.955,
      17.82,
      -61.524,
      -23.955,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5622.0",
    polygon: [
      -43.584,
      -11.286,
      15.36,
      -43.584,
      -10.136,
      15.36,
      -43.584,
      -10.136,
      17.82,
      -43.584,
      -10.136,
      17.82,
      -43.584,
      -11.286,
      17.82,
      -43.584,
      -11.286,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5583.0",
    polygon: [
      -29.923,
      -12.455,
      15.36,
      -29.923,
      -13.605,
      15.36,
      -29.923,
      -13.605,
      17.82,
      -29.923,
      -13.605,
      17.82,
      -29.923,
      -12.455,
      17.82,
      -29.923,
      -12.455,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5111.0",
    polygon: [
      -11.63,
      25.055,
      11.86,
      -11.63,
      26.205,
      11.86,
      -11.63,
      26.205,
      14.32,
      -11.63,
      26.205,
      14.32,
      -11.63,
      25.055,
      14.32,
      -11.63,
      25.055,
      11.86,
    ],
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "5523.0",
    polygon: [
      -43.584,
      -34.286,
      11.86,
      -43.584,
      -33.136,
      11.86,
      -43.584,
      -33.136,
      14.32,
      -43.584,
      -33.136,
      14.32,
      -43.584,
      -34.286,
      14.32,
      -43.584,
      -34.286,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "4668.0",
    polygon: [
      1.621,
      16.248,
      15.36,
      1.621,
      15.098,
      15.36,
      1.621,
      15.098,
      17.82,
      1.621,
      15.098,
      17.82,
      1.621,
      16.248,
      17.82,
      1.621,
      16.248,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "5368.0",
    polygon: [
      -43.584,
      -11.286,
      8.36,
      -43.584,
      -10.136,
      8.36,
      -43.584,
      -10.136,
      10.82,
      -43.584,
      -10.136,
      10.82,
      -43.584,
      -11.286,
      10.82,
      -43.584,
      -11.286,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "862.0",
    polygon: [
      -29.923,
      -35.454,
      4.86,
      -29.923,
      -36.604,
      4.86,
      -29.923,
      -36.604,
      7.32,
      -29.923,
      -36.604,
      7.32,
      -29.923,
      -35.454,
      7.32,
      -29.923,
      -35.454,
      4.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:45": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "4669.0",
    polygon: [
      1.621,
      17.55,
      15.36,
      1.621,
      16.4,
      15.36,
      1.621,
      16.4,
      17.82,
      1.621,
      16.4,
      17.82,
      1.621,
      17.55,
      17.82,
      1.621,
      17.55,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "5623.0",
    polygon: [
      -43.584,
      -12.436,
      15.36,
      -43.584,
      -11.286,
      15.36,
      -43.584,
      -11.286,
      17.82,
      -43.584,
      -11.286,
      17.82,
      -43.584,
      -12.436,
      17.82,
      -43.584,
      -12.436,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "900.0",
    polygon: [
      -43.584,
      -33.136,
      4.86,
      -43.584,
      -31.986,
      4.86,
      -43.584,
      -31.986,
      7.32,
      -43.584,
      -31.986,
      7.32,
      -43.584,
      -33.136,
      7.32,
      -43.584,
      -33.136,
      4.86,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "4791.0",
    polygon: [
      1.621,
      22.759,
      11.86,
      1.621,
      21.609,
      11.86,
      1.621,
      21.609,
      14.32,
      1.621,
      21.609,
      14.32,
      1.621,
      22.759,
      14.32,
      1.621,
      22.759,
      11.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "5408.0",
    polygon: [
      -61.524,
      -25.105,
      8.36,
      -61.524,
      -26.255,
      8.36,
      -61.524,
      -26.255,
      10.82,
      -61.524,
      -26.255,
      10.82,
      -61.524,
      -25.105,
      10.82,
      -61.524,
      -25.105,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "901.0",
    polygon: [
      -43.584,
      -34.286,
      4.86,
      -43.584,
      -33.136,
      4.86,
      -43.584,
      -33.136,
      7.32,
      -43.584,
      -33.136,
      7.32,
      -43.584,
      -34.286,
      7.32,
      -43.584,
      -34.286,
      4.86,
    ],
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "5485.0",
    polygon: [
      -29.923,
      -35.454,
      11.86,
      -29.923,
      -36.604,
      11.86,
      -29.923,
      -36.604,
      14.32,
      -29.923,
      -36.604,
      14.32,
      -29.923,
      -35.454,
      14.32,
      -29.923,
      -35.454,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:45": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5329.0",
    polygon: [
      -29.923,
      -12.455,
      8.36,
      -29.923,
      -13.605,
      8.36,
      -29.923,
      -13.605,
      10.82,
      -29.923,
      -13.605,
      10.82,
      -29.923,
      -12.455,
      10.82,
      -29.923,
      -12.455,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5330.0",
    polygon: [
      -29.923,
      -13.605,
      8.36,
      -29.923,
      -14.755,
      8.36,
      -29.923,
      -14.755,
      10.82,
      -29.923,
      -14.755,
      10.82,
      -29.923,
      -13.605,
      10.82,
      -29.923,
      -13.605,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5146.0",
    polygon: [
      -43.529,
      28.926,
      11.86,
      -43.529,
      30.076,
      11.86,
      -43.529,
      30.076,
      14.32,
      -43.529,
      30.076,
      14.32,
      -43.529,
      28.926,
      14.32,
      -43.529,
      28.926,
      11.86,
    ],
    "04-15 15:00": 1,
    "04-15 15:30": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "5369.0",
    polygon: [
      -43.584,
      -12.436,
      8.36,
      -43.584,
      -11.286,
      8.36,
      -43.584,
      -11.286,
      10.82,
      -43.584,
      -11.286,
      10.82,
      -43.584,
      -12.436,
      10.82,
      -43.584,
      -12.436,
      8.36,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "823.0",
    polygon: [
      -11.981,
      -25.105,
      4.86,
      -11.981,
      -23.955,
      4.86,
      -11.981,
      -23.955,
      7.32,
      -11.981,
      -23.955,
      7.32,
      -11.981,
      -25.105,
      7.32,
      -11.981,
      -25.105,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5219.0",
    polygon: [
      -61.429,
      20.739,
      15.36,
      -61.429,
      19.589,
      15.36,
      -61.429,
      19.589,
      17.82,
      -61.429,
      19.589,
      17.82,
      -61.429,
      20.739,
      17.82,
      -61.429,
      20.739,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "822.0",
    polygon: [
      -11.981,
      -26.255,
      4.86,
      -11.981,
      -25.105,
      4.86,
      -11.981,
      -25.105,
      7.32,
      -11.981,
      -25.105,
      7.32,
      -11.981,
      -26.255,
      7.32,
      -11.981,
      -26.255,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5290.0",
    polygon: [
      -21.771,
      -7.235,
      8.36,
      -22.921,
      -7.235,
      8.36,
      -22.921,
      -7.235,
      10.82,
      -22.921,
      -7.235,
      10.82,
      -21.771,
      -7.235,
      10.82,
      -21.771,
      -7.235,
      8.36,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:45": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:15": 1,
    "04-15 11:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "5291.0",
    polygon: [
      -20.626,
      -7.235,
      8.36,
      -21.776,
      -7.235,
      8.36,
      -21.776,
      -7.235,
      10.82,
      -21.776,
      -7.235,
      10.82,
      -20.626,
      -7.235,
      10.82,
      -20.626,
      -7.235,
      8.36,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "5551.0",
    polygon: [
      -20.626,
      -7.235,
      15.36,
      -21.776,
      -7.235,
      15.36,
      -21.776,
      -7.235,
      17.82,
      -21.776,
      -7.235,
      17.82,
      -20.626,
      -7.235,
      17.82,
      -20.626,
      -7.235,
      15.36,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "5446.0",
    polygon: [
      -11.981,
      -25.105,
      11.86,
      -11.981,
      -23.955,
      11.86,
      -11.981,
      -23.955,
      14.32,
      -11.981,
      -23.955,
      14.32,
      -11.981,
      -25.105,
      14.32,
      -11.981,
      -25.105,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "861.0",
    polygon: [
      -29.923,
      -34.304,
      4.86,
      -29.923,
      -35.454,
      4.86,
      -29.923,
      -35.454,
      7.32,
      -29.923,
      -35.454,
      7.32,
      -29.923,
      -34.304,
      7.32,
      -29.923,
      -34.304,
      4.86,
    ],
    "04-15 07:30": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "5110.0",
    polygon: [
      -11.63,
      26.205,
      11.86,
      -11.63,
      27.355,
      11.86,
      -11.63,
      27.355,
      14.32,
      -11.63,
      27.355,
      14.32,
      -11.63,
      26.205,
      14.32,
      -11.63,
      26.205,
      11.86,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1329.0",
    polygon: [
      -61.524,
      -25.105,
      15.36,
      -61.524,
      -26.255,
      15.36,
      -61.524,
      -26.255,
      17.82,
      -61.524,
      -26.255,
      17.82,
      -61.524,
      -25.105,
      17.82,
      -61.524,
      -25.105,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5407.0",
    polygon: [
      -61.524,
      -23.955,
      8.36,
      -61.524,
      -25.105,
      8.36,
      -61.524,
      -25.105,
      10.82,
      -61.524,
      -25.105,
      10.82,
      -61.524,
      -23.955,
      10.82,
      -61.524,
      -23.955,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5145.0",
    polygon: [
      -43.529,
      27.776,
      11.86,
      -43.529,
      28.926,
      11.86,
      -43.529,
      28.926,
      14.32,
      -43.529,
      28.926,
      14.32,
      -43.529,
      27.776,
      14.32,
      -43.529,
      27.776,
      11.86,
    ],
    "04-15 15:30": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "5584.0",
    polygon: [
      -29.923,
      -13.605,
      15.36,
      -29.923,
      -14.755,
      15.36,
      -29.923,
      -14.755,
      17.82,
      -29.923,
      -14.755,
      17.82,
      -29.923,
      -13.605,
      17.82,
      -29.923,
      -13.605,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "5447.0",
    polygon: [
      -11.981,
      -23.955,
      11.86,
      -11.981,
      -22.805,
      11.86,
      -11.981,
      -22.805,
      14.32,
      -11.981,
      -22.805,
      14.32,
      -11.981,
      -23.955,
      14.32,
      -11.981,
      -23.955,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "4672.0",
    polygon: [
      1.621,
      18.853,
      15.36,
      1.621,
      17.703,
      15.36,
      1.621,
      17.703,
      17.82,
      1.621,
      17.703,
      17.82,
      1.621,
      18.853,
      17.82,
      1.621,
      18.853,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "5553.0",
    polygon: [
      -18.301,
      -7.235,
      15.36,
      -19.451,
      -7.235,
      15.36,
      -19.451,
      -7.235,
      17.82,
      -19.451,
      -7.235,
      17.82,
      -18.301,
      -7.235,
      17.82,
      -18.301,
      -7.235,
      15.36,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1331.0",
    polygon: [
      -61.524,
      -27.405,
      15.36,
      -61.524,
      -28.555,
      15.36,
      -61.524,
      -28.555,
      17.82,
      -61.524,
      -28.555,
      17.82,
      -61.524,
      -27.405,
      17.82,
      -61.524,
      -27.405,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5552.0",
    polygon: [
      -19.476,
      -7.235,
      15.36,
      -20.626,
      -7.235,
      15.36,
      -20.626,
      -7.235,
      17.82,
      -20.626,
      -7.235,
      17.82,
      -19.476,
      -7.235,
      17.82,
      -19.476,
      -7.235,
      15.36,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "1330.0",
    polygon: [
      -61.524,
      -26.255,
      15.36,
      -61.524,
      -27.405,
      15.36,
      -61.524,
      -27.405,
      17.82,
      -61.524,
      -27.405,
      17.82,
      -61.524,
      -26.255,
      17.82,
      -61.524,
      -26.255,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5585.0",
    polygon: [
      -29.923,
      -14.755,
      15.36,
      -29.923,
      -15.905,
      15.36,
      -29.923,
      -15.905,
      17.82,
      -29.923,
      -15.905,
      17.82,
      -29.923,
      -14.755,
      17.82,
      -29.923,
      -14.755,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5624.0",
    polygon: [
      -43.584,
      -13.586,
      15.36,
      -43.584,
      -12.436,
      15.36,
      -43.584,
      -12.436,
      17.82,
      -43.584,
      -12.436,
      17.82,
      -43.584,
      -13.586,
      17.82,
      -43.584,
      -13.586,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5524.0",
    polygon: [
      -43.584,
      -35.436,
      11.86,
      -43.584,
      -34.286,
      11.86,
      -43.584,
      -34.286,
      14.32,
      -43.584,
      -34.286,
      14.32,
      -43.584,
      -35.436,
      14.32,
      -43.584,
      -35.436,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5486.0",
    polygon: [
      -29.923,
      -36.604,
      11.86,
      -29.923,
      -37.754,
      11.86,
      -29.923,
      -37.754,
      14.32,
      -29.923,
      -37.754,
      14.32,
      -29.923,
      -36.604,
      14.32,
      -29.923,
      -36.604,
      11.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5625.0",
    polygon: [
      -43.584,
      -14.736,
      15.36,
      -43.584,
      -13.586,
      15.36,
      -43.584,
      -13.586,
      17.82,
      -43.584,
      -13.586,
      17.82,
      -43.584,
      -14.736,
      17.82,
      -43.584,
      -14.736,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5525.0",
    polygon: [
      -43.584,
      -36.586,
      11.86,
      -43.584,
      -35.436,
      11.86,
      -43.584,
      -35.436,
      14.32,
      -43.584,
      -35.436,
      14.32,
      -43.584,
      -36.586,
      14.32,
      -43.584,
      -36.586,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5487.0",
    polygon: [
      -29.923,
      -37.754,
      11.86,
      -29.923,
      -38.904,
      11.86,
      -29.923,
      -38.904,
      14.32,
      -29.923,
      -38.904,
      14.32,
      -29.923,
      -37.754,
      14.32,
      -29.923,
      -37.754,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5292.0",
    polygon: [
      -19.476,
      -7.235,
      8.36,
      -20.626,
      -7.235,
      8.36,
      -20.626,
      -7.235,
      10.82,
      -20.626,
      -7.235,
      10.82,
      -19.476,
      -7.235,
      10.82,
      -19.476,
      -7.235,
      8.36,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 11:15": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "5448.0",
    polygon: [
      -11.981,
      -22.805,
      11.86,
      -11.981,
      -21.655,
      11.86,
      -11.981,
      -21.655,
      14.32,
      -11.981,
      -21.655,
      14.32,
      -11.981,
      -22.805,
      14.32,
      -11.981,
      -22.805,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "902.0",
    polygon: [
      -43.584,
      -35.436,
      4.86,
      -43.584,
      -34.286,
      4.86,
      -43.584,
      -34.286,
      7.32,
      -43.584,
      -34.286,
      7.32,
      -43.584,
      -35.436,
      7.32,
      -43.584,
      -35.436,
      4.86,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5331.0",
    polygon: [
      -29.923,
      -14.755,
      8.36,
      -29.923,
      -15.905,
      8.36,
      -29.923,
      -15.905,
      10.82,
      -29.923,
      -15.905,
      10.82,
      -29.923,
      -14.755,
      10.82,
      -29.923,
      -14.755,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4673.0",
    polygon: [
      1.621,
      20.155,
      15.36,
      1.621,
      19.005,
      15.36,
      1.621,
      19.005,
      17.82,
      1.621,
      19.005,
      17.82,
      1.621,
      20.155,
      17.82,
      1.621,
      20.155,
      15.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "5294.0",
    polygon: [
      -17.151,
      -7.235,
      8.36,
      -18.301,
      -7.235,
      8.36,
      -18.301,
      -7.235,
      10.82,
      -18.301,
      -7.235,
      10.82,
      -17.151,
      -7.235,
      10.82,
      -17.151,
      -7.235,
      8.36,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 12:00": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "826.0",
    polygon: [
      -11.981,
      -21.655,
      4.86,
      -11.981,
      -20.505,
      4.86,
      -11.981,
      -20.505,
      7.32,
      -11.981,
      -20.505,
      7.32,
      -11.981,
      -21.655,
      7.32,
      -11.981,
      -21.655,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5450.0",
    polygon: [
      -11.981,
      -20.505,
      11.86,
      -11.981,
      -19.355,
      11.86,
      -11.981,
      -19.355,
      14.32,
      -11.981,
      -19.355,
      14.32,
      -11.981,
      -20.505,
      14.32,
      -11.981,
      -20.505,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5147.0",
    polygon: [
      -61.429,
      30.076,
      11.86,
      -61.429,
      28.926,
      11.86,
      -61.429,
      28.926,
      14.32,
      -61.429,
      28.926,
      14.32,
      -61.429,
      30.076,
      14.32,
      -61.429,
      30.076,
      11.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "5184.0",
    polygon: [
      -11.63,
      29.216,
      15.36,
      -11.63,
      30.366,
      15.36,
      -11.63,
      30.366,
      17.82,
      -11.63,
      30.366,
      17.82,
      -11.63,
      29.216,
      17.82,
      -11.63,
      29.216,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5372.0",
    polygon: [
      -43.584,
      -15.886,
      8.36,
      -43.584,
      -14.736,
      8.36,
      -43.584,
      -14.736,
      10.82,
      -43.584,
      -14.736,
      10.82,
      -43.584,
      -15.886,
      10.82,
      -43.584,
      -15.886,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "865.0",
    polygon: [
      -59.154,
      -7.235,
      4.86,
      -60.304,
      -7.235,
      4.86,
      -60.304,
      -7.235,
      7.32,
      -60.304,
      -7.235,
      7.32,
      -59.154,
      -7.235,
      7.32,
      -59.154,
      -7.235,
      4.86,
    ],
    "04-15 08:30": 1,
    "04-15 09:15": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "5148.0",
    polygon: [
      -61.429,
      28.927,
      11.86,
      -61.429,
      27.777,
      11.86,
      -61.429,
      27.777,
      14.32,
      -61.429,
      27.777,
      14.32,
      -61.429,
      28.927,
      14.32,
      -61.429,
      28.927,
      11.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "5222.0",
    polygon: [
      -61.429,
      18.439,
      15.36,
      -61.429,
      17.289,
      15.36,
      -61.429,
      17.289,
      17.82,
      -61.429,
      17.289,
      17.82,
      -61.429,
      18.439,
      17.82,
      -61.429,
      18.439,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "5293.0",
    polygon: [
      -18.301,
      -7.235,
      8.36,
      -19.451,
      -7.235,
      8.36,
      -19.451,
      -7.235,
      10.82,
      -19.451,
      -7.235,
      10.82,
      -18.301,
      -7.235,
      10.82,
      -18.301,
      -7.235,
      8.36,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "825.0",
    polygon: [
      -11.981,
      -22.805,
      4.86,
      -11.981,
      -21.655,
      4.86,
      -11.981,
      -21.655,
      7.32,
      -11.981,
      -21.655,
      7.32,
      -11.981,
      -22.805,
      7.32,
      -11.981,
      -22.805,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5554.0",
    polygon: [
      -17.151,
      -7.235,
      15.36,
      -18.301,
      -7.235,
      15.36,
      -18.301,
      -7.235,
      17.82,
      -18.301,
      -7.235,
      17.82,
      -17.151,
      -7.235,
      17.82,
      -17.151,
      -7.235,
      15.36,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "5410.0",
    polygon: [
      -61.524,
      -27.405,
      8.36,
      -61.524,
      -28.555,
      8.36,
      -61.524,
      -28.555,
      10.82,
      -61.524,
      -28.555,
      10.82,
      -61.524,
      -27.405,
      10.82,
      -61.524,
      -27.405,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5149.0",
    polygon: [
      -61.429,
      27.289,
      11.86,
      -61.429,
      26.139,
      11.86,
      -61.429,
      26.139,
      14.32,
      -61.429,
      26.139,
      14.32,
      -61.429,
      27.289,
      14.32,
      -61.429,
      27.289,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "5588.0",
    polygon: [
      -29.923,
      -18.205,
      15.36,
      -29.923,
      -19.355,
      15.36,
      -29.923,
      -19.355,
      17.82,
      -29.923,
      -19.355,
      17.82,
      -29.923,
      -18.205,
      17.82,
      -29.923,
      -18.205,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "1333.0",
    polygon: [
      -61.524,
      -29.705,
      15.36,
      -61.524,
      -30.855,
      15.36,
      -61.524,
      -30.855,
      17.82,
      -61.524,
      -30.855,
      17.82,
      -61.524,
      -29.705,
      17.82,
      -61.524,
      -29.705,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "1294.0",
    polygon: [
      -43.584,
      -17.036,
      15.36,
      -43.584,
      -15.886,
      15.36,
      -43.584,
      -15.886,
      17.82,
      -43.584,
      -15.886,
      17.82,
      -43.584,
      -17.036,
      17.82,
      -43.584,
      -17.036,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "904.0",
    polygon: [
      -43.584,
      -37.736,
      4.86,
      -43.584,
      -36.586,
      4.86,
      -43.584,
      -36.586,
      7.32,
      -43.584,
      -36.586,
      7.32,
      -43.584,
      -37.736,
      7.32,
      -43.584,
      -37.736,
      4.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5527.0",
    polygon: [
      -43.584,
      -38.886,
      11.86,
      -43.584,
      -37.736,
      11.86,
      -43.584,
      -37.736,
      14.32,
      -43.584,
      -37.736,
      14.32,
      -43.584,
      -38.886,
      14.32,
      -43.584,
      -38.886,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "5411.0",
    polygon: [
      -61.524,
      -28.555,
      8.36,
      -61.524,
      -29.705,
      8.36,
      -61.524,
      -29.705,
      10.82,
      -61.524,
      -29.705,
      10.82,
      -61.524,
      -28.555,
      10.82,
      -61.524,
      -28.555,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5489.0",
    polygon: [
      -57.996,
      -7.235,
      11.86,
      -59.146,
      -7.235,
      11.86,
      -59.146,
      -7.235,
      14.32,
      -59.146,
      -7.235,
      14.32,
      -57.996,
      -7.235,
      14.32,
      -57.996,
      -7.235,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "5587.0",
    polygon: [
      -29.923,
      -17.055,
      15.36,
      -29.923,
      -18.205,
      15.36,
      -29.923,
      -18.205,
      17.82,
      -29.923,
      -18.205,
      17.82,
      -29.923,
      -17.055,
      17.82,
      -29.923,
      -17.055,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5333.0",
    polygon: [
      -29.923,
      -17.055,
      8.36,
      -29.923,
      -18.205,
      8.36,
      -29.923,
      -18.205,
      10.82,
      -29.923,
      -18.205,
      10.82,
      -29.923,
      -17.055,
      10.82,
      -29.923,
      -17.055,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "903.0",
    polygon: [
      -43.584,
      -36.586,
      4.86,
      -43.584,
      -35.436,
      4.86,
      -43.584,
      -35.436,
      7.32,
      -43.584,
      -35.436,
      7.32,
      -43.584,
      -36.586,
      7.32,
      -43.584,
      -36.586,
      4.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5409.0",
    polygon: [
      -61.524,
      -26.255,
      8.36,
      -61.524,
      -27.405,
      8.36,
      -61.524,
      -27.405,
      10.82,
      -61.524,
      -27.405,
      10.82,
      -61.524,
      -26.255,
      10.82,
      -61.524,
      -26.255,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5332.0",
    polygon: [
      -29.923,
      -15.905,
      8.36,
      -29.923,
      -17.055,
      8.36,
      -29.923,
      -17.055,
      10.82,
      -29.923,
      -17.055,
      10.82,
      -29.923,
      -15.905,
      10.82,
      -29.923,
      -15.905,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5626.0",
    polygon: [
      -43.584,
      -15.886,
      15.36,
      -43.584,
      -14.736,
      15.36,
      -43.584,
      -14.736,
      17.82,
      -43.584,
      -14.736,
      17.82,
      -43.584,
      -15.886,
      17.82,
      -43.584,
      -15.886,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5370.0",
    polygon: [
      -43.584,
      -13.586,
      8.36,
      -43.584,
      -12.436,
      8.36,
      -43.584,
      -12.436,
      10.82,
      -43.584,
      -12.436,
      10.82,
      -43.584,
      -13.586,
      10.82,
      -43.584,
      -13.586,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "708.0",
    polygon: [
      -11.63,
      21.118,
      15.36,
      -11.63,
      22.268,
      15.36,
      -11.63,
      22.268,
      17.82,
      -11.63,
      22.268,
      17.82,
      -11.63,
      21.118,
      17.82,
      -11.63,
      21.118,
      15.36,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "5488.0",
    polygon: [
      -59.154,
      -7.235,
      11.86,
      -60.304,
      -7.235,
      11.86,
      -60.304,
      -7.235,
      14.32,
      -60.304,
      -7.235,
      14.32,
      -59.154,
      -7.235,
      14.32,
      -59.154,
      -7.235,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "863.0",
    polygon: [
      -29.923,
      -36.604,
      4.86,
      -29.923,
      -37.754,
      4.86,
      -29.923,
      -37.754,
      7.32,
      -29.923,
      -37.754,
      7.32,
      -29.923,
      -36.604,
      7.32,
      -29.923,
      -36.604,
      4.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "5526.0",
    polygon: [
      -43.584,
      -37.736,
      11.86,
      -43.584,
      -36.586,
      11.86,
      -43.584,
      -36.586,
      14.32,
      -43.584,
      -36.586,
      14.32,
      -43.584,
      -37.736,
      14.32,
      -43.584,
      -37.736,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "864.0",
    polygon: [
      -29.923,
      -37.754,
      4.86,
      -29.923,
      -38.904,
      4.86,
      -29.923,
      -38.904,
      7.32,
      -29.923,
      -38.904,
      7.32,
      -29.923,
      -37.754,
      7.32,
      -29.923,
      -37.754,
      4.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "824.0",
    polygon: [
      -11.981,
      -23.955,
      4.86,
      -11.981,
      -22.805,
      4.86,
      -11.981,
      -22.805,
      7.32,
      -11.981,
      -22.805,
      7.32,
      -11.981,
      -23.955,
      7.32,
      -11.981,
      -23.955,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5371.0",
    polygon: [
      -43.584,
      -14.736,
      8.36,
      -43.584,
      -13.586,
      8.36,
      -43.584,
      -13.586,
      10.82,
      -43.584,
      -13.586,
      10.82,
      -43.584,
      -14.736,
      10.82,
      -43.584,
      -14.736,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5449.0",
    polygon: [
      -11.981,
      -21.655,
      11.86,
      -11.981,
      -20.505,
      11.86,
      -11.981,
      -20.505,
      14.32,
      -11.981,
      -20.505,
      14.32,
      -11.981,
      -21.655,
      14.32,
      -11.981,
      -21.655,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5221.0",
    polygon: [
      -61.429,
      23.839,
      15.36,
      -61.429,
      22.689,
      15.36,
      -61.429,
      22.689,
      17.82,
      -61.429,
      22.689,
      17.82,
      -61.429,
      23.839,
      17.82,
      -61.429,
      23.839,
      15.36,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "1332.0",
    polygon: [
      -61.524,
      -28.555,
      15.36,
      -61.524,
      -29.705,
      15.36,
      -61.524,
      -29.705,
      17.82,
      -61.524,
      -29.705,
      17.82,
      -61.524,
      -28.555,
      17.82,
      -61.524,
      -28.555,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "5224.0",
    polygon: [
      -61.429,
      16.139,
      15.36,
      -61.429,
      14.989,
      15.36,
      -61.429,
      14.989,
      17.82,
      -61.429,
      14.989,
      17.82,
      -61.429,
      16.139,
      17.82,
      -61.429,
      16.139,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
  },
  {
    ID: "905.0",
    polygon: [
      -43.584,
      -38.886,
      4.86,
      -43.584,
      -37.736,
      4.86,
      -43.584,
      -37.736,
      7.32,
      -43.584,
      -37.736,
      7.32,
      -43.584,
      -38.886,
      7.32,
      -43.584,
      -38.886,
      4.86,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "5555.0",
    polygon: [
      -16.001,
      -7.235,
      15.36,
      -17.151,
      -7.235,
      15.36,
      -17.151,
      -7.235,
      17.82,
      -17.151,
      -7.235,
      17.82,
      -16.001,
      -7.235,
      17.82,
      -16.001,
      -7.235,
      15.36,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "827.0",
    polygon: [
      -11.981,
      -20.505,
      4.86,
      -11.981,
      -19.355,
      4.86,
      -11.981,
      -19.355,
      7.32,
      -11.981,
      -19.355,
      7.32,
      -11.981,
      -20.505,
      7.32,
      -11.981,
      -20.505,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5412.0",
    polygon: [
      -61.524,
      -29.705,
      8.36,
      -61.524,
      -30.855,
      8.36,
      -61.524,
      -30.855,
      10.82,
      -61.524,
      -30.855,
      10.82,
      -61.524,
      -29.705,
      10.82,
      -61.524,
      -29.705,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5334.0",
    polygon: [
      -29.923,
      -18.205,
      8.36,
      -29.923,
      -19.355,
      8.36,
      -29.923,
      -19.355,
      10.82,
      -29.923,
      -19.355,
      10.82,
      -29.923,
      -18.205,
      10.82,
      -29.923,
      -18.205,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5185.0",
    polygon: [
      -29.849,
      30.086,
      15.36,
      -29.849,
      28.936,
      15.36,
      -29.849,
      28.936,
      17.82,
      -29.849,
      28.936,
      17.82,
      -29.849,
      30.086,
      17.82,
      -29.849,
      30.086,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:30": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "866.0",
    polygon: [
      -57.996,
      -7.235,
      4.86,
      -59.146,
      -7.235,
      4.86,
      -59.146,
      -7.235,
      7.32,
      -59.146,
      -7.235,
      7.32,
      -57.996,
      -7.235,
      7.32,
      -57.996,
      -7.235,
      4.86,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "5589.0",
    polygon: [
      -29.923,
      -19.355,
      15.36,
      -29.923,
      -20.505,
      15.36,
      -29.923,
      -20.505,
      17.82,
      -29.923,
      -20.505,
      17.82,
      -29.923,
      -19.355,
      17.82,
      -29.923,
      -19.355,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "1295.0",
    polygon: [
      -43.584,
      -18.186,
      15.36,
      -43.584,
      -17.036,
      15.36,
      -43.584,
      -17.036,
      17.82,
      -43.584,
      -17.036,
      17.82,
      -43.584,
      -18.186,
      17.82,
      -43.584,
      -18.186,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5528.0",
    polygon: [
      -61.524,
      -7.855,
      11.86,
      -61.524,
      -9.005,
      11.86,
      -61.524,
      -9.005,
      14.32,
      -61.524,
      -9.005,
      14.32,
      -61.524,
      -7.855,
      14.32,
      -61.524,
      -7.855,
      11.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5490.0",
    polygon: [
      -56.846,
      -7.235,
      11.86,
      -57.996,
      -7.235,
      11.86,
      -57.996,
      -7.235,
      14.32,
      -57.996,
      -7.235,
      14.32,
      -56.846,
      -7.235,
      14.32,
      -56.846,
      -7.235,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "5451.0",
    polygon: [
      -11.981,
      -19.355,
      11.86,
      -11.981,
      -18.205,
      11.86,
      -11.981,
      -18.205,
      14.32,
      -11.981,
      -18.205,
      14.32,
      -11.981,
      -19.355,
      14.32,
      -11.981,
      -19.355,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5295.0",
    polygon: [
      -16.001,
      -7.235,
      8.36,
      -17.151,
      -7.235,
      8.36,
      -17.151,
      -7.235,
      10.82,
      -17.151,
      -7.235,
      10.82,
      -16.001,
      -7.235,
      10.82,
      -16.001,
      -7.235,
      8.36,
    ],
    "04-15 10:45": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5150.0",
    polygon: [
      -61.429,
      26.139,
      11.86,
      -61.429,
      24.989,
      11.86,
      -61.429,
      24.989,
      14.32,
      -61.429,
      24.989,
      14.32,
      -61.429,
      26.139,
      14.32,
      -61.429,
      26.139,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "5186.0",
    polygon: [
      -29.849,
      28.936,
      15.36,
      -29.849,
      27.786,
      15.36,
      -29.849,
      27.786,
      17.82,
      -29.849,
      27.786,
      17.82,
      -29.849,
      28.936,
      17.82,
      -29.849,
      28.936,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "5335.0",
    polygon: [
      -29.923,
      -19.355,
      8.36,
      -29.923,
      -20.505,
      8.36,
      -29.923,
      -20.505,
      10.82,
      -29.923,
      -20.505,
      10.82,
      -29.923,
      -19.355,
      10.82,
      -29.923,
      -19.355,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5557.0",
    polygon: [
      -13.701,
      -7.235,
      15.36,
      -14.851,
      -7.235,
      15.36,
      -14.851,
      -7.235,
      17.82,
      -14.851,
      -7.235,
      17.82,
      -13.701,
      -7.235,
      17.82,
      -13.701,
      -7.235,
      15.36,
    ],
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "828.0",
    polygon: [
      -11.981,
      -19.355,
      4.86,
      -11.981,
      -18.205,
      4.86,
      -11.981,
      -18.205,
      7.32,
      -11.981,
      -18.205,
      7.32,
      -11.981,
      -19.355,
      7.32,
      -11.981,
      -19.355,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5556.0",
    polygon: [
      -14.851,
      -7.235,
      15.36,
      -16.001,
      -7.235,
      15.36,
      -16.001,
      -7.235,
      17.82,
      -16.001,
      -7.235,
      17.82,
      -14.851,
      -7.235,
      17.82,
      -14.851,
      -7.235,
      15.36,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "867.0",
    polygon: [
      -56.846,
      -7.235,
      4.86,
      -57.996,
      -7.235,
      4.86,
      -57.996,
      -7.235,
      7.32,
      -57.996,
      -7.235,
      7.32,
      -56.846,
      -7.235,
      7.32,
      -56.846,
      -7.235,
      4.86,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "5590.0",
    polygon: [
      -29.923,
      -20.505,
      15.36,
      -29.923,
      -21.655,
      15.36,
      -29.923,
      -21.655,
      17.82,
      -29.923,
      -21.655,
      17.82,
      -29.923,
      -20.505,
      17.82,
      -29.923,
      -20.505,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1334.0",
    polygon: [
      -61.524,
      -30.855,
      15.36,
      -61.524,
      -32.005,
      15.36,
      -61.524,
      -32.005,
      17.82,
      -61.524,
      -32.005,
      17.82,
      -61.524,
      -30.855,
      17.82,
      -61.524,
      -30.855,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5151.0",
    polygon: [
      -61.429,
      24.989,
      11.86,
      -61.429,
      23.839,
      11.86,
      -61.429,
      23.839,
      14.32,
      -61.429,
      23.839,
      14.32,
      -61.429,
      24.989,
      14.32,
      -61.429,
      24.989,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "1335.0",
    polygon: [
      -61.524,
      -32.005,
      15.36,
      -61.524,
      -33.155,
      15.36,
      -61.524,
      -33.155,
      17.82,
      -61.524,
      -33.155,
      17.82,
      -61.524,
      -32.005,
      17.82,
      -61.524,
      -32.005,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5413.0",
    polygon: [
      -61.524,
      -30.855,
      8.36,
      -61.524,
      -32.005,
      8.36,
      -61.524,
      -32.005,
      10.82,
      -61.524,
      -32.005,
      10.82,
      -61.524,
      -30.855,
      10.82,
      -61.524,
      -30.855,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "1296.0",
    polygon: [
      -43.584,
      -19.336,
      15.36,
      -43.584,
      -18.186,
      15.36,
      -43.584,
      -18.186,
      17.82,
      -43.584,
      -18.186,
      17.82,
      -43.584,
      -19.336,
      17.82,
      -43.584,
      -19.336,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5491.0",
    polygon: [
      -55.696,
      -7.235,
      11.86,
      -56.846,
      -7.235,
      11.86,
      -56.846,
      -7.235,
      14.32,
      -56.846,
      -7.235,
      14.32,
      -55.696,
      -7.235,
      14.32,
      -55.696,
      -7.235,
      11.86,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:45": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "5529.0",
    polygon: [
      -61.524,
      -9.005,
      11.86,
      -61.524,
      -10.155,
      11.86,
      -61.524,
      -10.155,
      14.32,
      -61.524,
      -10.155,
      14.32,
      -61.524,
      -9.005,
      14.32,
      -61.524,
      -9.005,
      11.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "906.0",
    polygon: [
      -61.524,
      -7.855,
      4.86,
      -61.524,
      -9.005,
      4.86,
      -61.524,
      -9.005,
      7.32,
      -61.524,
      -9.005,
      7.32,
      -61.524,
      -7.855,
      7.32,
      -61.524,
      -7.855,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5373.0",
    polygon: [
      -43.584,
      -17.036,
      8.36,
      -43.584,
      -15.886,
      8.36,
      -43.584,
      -15.886,
      10.82,
      -43.584,
      -15.886,
      10.82,
      -43.584,
      -17.036,
      10.82,
      -43.584,
      -17.036,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5296.0",
    polygon: [
      -14.851,
      -7.235,
      8.36,
      -16.001,
      -7.235,
      8.36,
      -16.001,
      -7.235,
      10.82,
      -16.001,
      -7.235,
      10.82,
      -14.851,
      -7.235,
      10.82,
      -14.851,
      -7.235,
      8.36,
    ],
    "04-15 10:45": 1,
    "04-15 11:45": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5374.0",
    polygon: [
      -43.584,
      -18.186,
      8.36,
      -43.584,
      -17.036,
      8.36,
      -43.584,
      -17.036,
      10.82,
      -43.584,
      -17.036,
      10.82,
      -43.584,
      -18.186,
      10.82,
      -43.584,
      -18.186,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5452.0",
    polygon: [
      -11.981,
      -18.205,
      11.86,
      -11.981,
      -17.055,
      11.86,
      -11.981,
      -17.055,
      14.32,
      -11.981,
      -17.055,
      14.32,
      -11.981,
      -18.205,
      14.32,
      -11.981,
      -18.205,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5187.0",
    polygon: [
      -29.849,
      27.786,
      15.36,
      -29.849,
      26.636,
      15.36,
      -29.849,
      26.636,
      17.82,
      -29.849,
      26.636,
      17.82,
      -29.849,
      27.786,
      17.82,
      -29.849,
      27.786,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:30": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "907.0",
    polygon: [
      -61.524,
      -9.005,
      4.86,
      -61.524,
      -10.155,
      4.86,
      -61.524,
      -10.155,
      7.32,
      -61.524,
      -10.155,
      7.32,
      -61.524,
      -9.005,
      7.32,
      -61.524,
      -9.005,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "829.0",
    polygon: [
      -11.981,
      -18.205,
      4.86,
      -11.981,
      -17.055,
      4.86,
      -11.981,
      -17.055,
      7.32,
      -11.981,
      -17.055,
      7.32,
      -11.981,
      -18.205,
      7.32,
      -11.981,
      -18.205,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5078.0",
    polygon: [
      -43.529,
      26.626,
      8.36,
      -43.529,
      27.776,
      8.36,
      -43.529,
      27.776,
      10.82,
      -43.529,
      27.776,
      10.82,
      -43.529,
      26.626,
      10.82,
      -43.529,
      26.626,
      8.36,
    ],
    "04-15 15:15": 1,
  },
  {
    ID: "5558.0",
    polygon: [
      -12.551,
      -7.235,
      15.36,
      -13.701,
      -7.235,
      15.36,
      -13.701,
      -7.235,
      17.82,
      -13.701,
      -7.235,
      17.82,
      -12.551,
      -7.235,
      17.82,
      -12.551,
      -7.235,
      15.36,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5337.0",
    polygon: [
      -29.923,
      -21.655,
      8.36,
      -29.923,
      -22.805,
      8.36,
      -29.923,
      -22.805,
      10.82,
      -29.923,
      -22.805,
      10.82,
      -29.923,
      -21.655,
      10.82,
      -29.923,
      -21.655,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5591.0",
    polygon: [
      -29.923,
      -21.655,
      15.36,
      -29.923,
      -22.805,
      15.36,
      -29.923,
      -22.805,
      17.82,
      -29.923,
      -22.805,
      17.82,
      -29.923,
      -21.655,
      17.82,
      -29.923,
      -21.655,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "1336.0",
    polygon: [
      -61.524,
      -33.155,
      15.36,
      -61.524,
      -34.305,
      15.36,
      -61.524,
      -34.305,
      17.82,
      -61.524,
      -34.305,
      17.82,
      -61.524,
      -33.155,
      17.82,
      -61.524,
      -33.155,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5627.0",
    polygon: [
      -43.584,
      -20.486,
      15.36,
      -43.584,
      -19.336,
      15.36,
      -43.584,
      -19.336,
      17.82,
      -43.584,
      -19.336,
      17.82,
      -43.584,
      -20.486,
      17.82,
      -43.584,
      -20.486,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5492.0",
    polygon: [
      -54.546,
      -7.235,
      11.86,
      -55.696,
      -7.235,
      11.86,
      -55.696,
      -7.235,
      14.32,
      -55.696,
      -7.235,
      14.32,
      -54.546,
      -7.235,
      14.32,
      -54.546,
      -7.235,
      11.86,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:30": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "5336.0",
    polygon: [
      -29.923,
      -20.505,
      8.36,
      -29.923,
      -21.655,
      8.36,
      -29.923,
      -21.655,
      10.82,
      -29.923,
      -21.655,
      10.82,
      -29.923,
      -20.505,
      10.82,
      -29.923,
      -20.505,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5530.0",
    polygon: [
      -61.524,
      -10.155,
      11.86,
      -61.524,
      -11.305,
      11.86,
      -61.524,
      -11.305,
      14.32,
      -61.524,
      -11.305,
      14.32,
      -61.524,
      -10.155,
      14.32,
      -61.524,
      -10.155,
      11.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5453.0",
    polygon: [
      -11.981,
      -17.055,
      11.86,
      -11.981,
      -15.905,
      11.86,
      -11.981,
      -15.905,
      14.32,
      -11.981,
      -15.905,
      14.32,
      -11.981,
      -17.055,
      14.32,
      -11.981,
      -17.055,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "868.0",
    polygon: [
      -55.696,
      -7.235,
      4.86,
      -56.846,
      -7.235,
      4.86,
      -56.846,
      -7.235,
      7.32,
      -56.846,
      -7.235,
      7.32,
      -55.696,
      -7.235,
      7.32,
      -55.696,
      -7.235,
      4.86,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "5375.0",
    polygon: [
      -43.584,
      -19.336,
      8.36,
      -43.584,
      -18.186,
      8.36,
      -43.584,
      -18.186,
      10.82,
      -43.584,
      -18.186,
      10.82,
      -43.584,
      -19.336,
      10.82,
      -43.584,
      -19.336,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5297.0",
    polygon: [
      -13.701,
      -7.235,
      8.36,
      -14.851,
      -7.235,
      8.36,
      -14.851,
      -7.235,
      10.82,
      -14.851,
      -7.235,
      10.82,
      -13.701,
      -7.235,
      10.82,
      -13.701,
      -7.235,
      8.36,
    ],
    "04-15 10:45": 1,
    "04-15 11:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5414.0",
    polygon: [
      -61.524,
      -32.005,
      8.36,
      -61.524,
      -33.155,
      8.36,
      -61.524,
      -33.155,
      10.82,
      -61.524,
      -33.155,
      10.82,
      -61.524,
      -32.005,
      10.82,
      -61.524,
      -32.005,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5454.0",
    polygon: [
      -11.981,
      -15.905,
      11.86,
      -11.981,
      -14.755,
      11.86,
      -11.981,
      -14.755,
      14.32,
      -11.981,
      -14.755,
      14.32,
      -11.981,
      -15.905,
      14.32,
      -11.981,
      -15.905,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "908.0",
    polygon: [
      -61.524,
      -10.155,
      4.86,
      -61.524,
      -11.305,
      4.86,
      -61.524,
      -11.305,
      7.32,
      -61.524,
      -11.305,
      7.32,
      -61.524,
      -10.155,
      7.32,
      -61.524,
      -10.155,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5559.0",
    polygon: [
      -11.981,
      -37.755,
      15.36,
      -11.981,
      -36.605,
      15.36,
      -11.981,
      -36.605,
      17.82,
      -11.981,
      -36.605,
      17.82,
      -11.981,
      -37.755,
      17.82,
      -11.981,
      -37.755,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5298.0",
    polygon: [
      -12.551,
      -7.235,
      8.36,
      -13.701,
      -7.235,
      8.36,
      -13.701,
      -7.235,
      10.82,
      -13.701,
      -7.235,
      10.82,
      -12.551,
      -7.235,
      10.82,
      -12.551,
      -7.235,
      8.36,
    ],
    "04-15 11:15": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 14:00": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "5415.0",
    polygon: [
      -61.524,
      -33.155,
      8.36,
      -61.524,
      -34.305,
      8.36,
      -61.524,
      -34.305,
      10.82,
      -61.524,
      -34.305,
      10.82,
      -61.524,
      -33.155,
      10.82,
      -61.524,
      -33.155,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "830.0",
    polygon: [
      -11.981,
      -17.055,
      4.86,
      -11.981,
      -15.905,
      4.86,
      -11.981,
      -15.905,
      7.32,
      -11.981,
      -15.905,
      7.32,
      -11.981,
      -17.055,
      7.32,
      -11.981,
      -17.055,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "869.0",
    polygon: [
      -54.546,
      -7.235,
      4.86,
      -55.696,
      -7.235,
      4.86,
      -55.696,
      -7.235,
      7.32,
      -55.696,
      -7.235,
      7.32,
      -54.546,
      -7.235,
      7.32,
      -54.546,
      -7.235,
      4.86,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "5080.0",
    polygon: [
      -43.529,
      28.926,
      8.36,
      -43.529,
      30.076,
      8.36,
      -43.529,
      30.076,
      10.82,
      -43.529,
      30.076,
      10.82,
      -43.529,
      28.926,
      10.82,
      -43.529,
      28.926,
      8.36,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5376.0",
    polygon: [
      -43.584,
      -20.486,
      8.36,
      -43.584,
      -19.336,
      8.36,
      -43.584,
      -19.336,
      10.82,
      -43.584,
      -19.336,
      10.82,
      -43.584,
      -20.486,
      10.82,
      -43.584,
      -20.486,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1337.0",
    polygon: [
      -61.524,
      -34.305,
      15.36,
      -61.524,
      -35.455,
      15.36,
      -61.524,
      -35.455,
      17.82,
      -61.524,
      -35.455,
      17.82,
      -61.524,
      -34.305,
      17.82,
      -61.524,
      -34.305,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5592.0",
    polygon: [
      -29.923,
      -22.805,
      15.36,
      -29.923,
      -23.955,
      15.36,
      -29.923,
      -23.955,
      17.82,
      -29.923,
      -23.955,
      17.82,
      -29.923,
      -22.805,
      17.82,
      -29.923,
      -22.805,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "909.0",
    polygon: [
      -61.524,
      -11.305,
      4.86,
      -61.524,
      -12.455,
      4.86,
      -61.524,
      -12.455,
      7.32,
      -61.524,
      -12.455,
      7.32,
      -61.524,
      -11.305,
      7.32,
      -61.524,
      -11.305,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5493.0",
    polygon: [
      -53.396,
      -7.235,
      11.86,
      -54.546,
      -7.235,
      11.86,
      -54.546,
      -7.235,
      14.32,
      -54.546,
      -7.235,
      14.32,
      -53.396,
      -7.235,
      14.32,
      -53.396,
      -7.235,
      11.86,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 11:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "5628.0",
    polygon: [
      -43.584,
      -21.636,
      15.36,
      -43.584,
      -20.486,
      15.36,
      -43.584,
      -20.486,
      17.82,
      -43.584,
      -20.486,
      17.82,
      -43.584,
      -21.636,
      17.82,
      -43.584,
      -21.636,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5531.0",
    polygon: [
      -61.524,
      -11.305,
      11.86,
      -61.524,
      -12.455,
      11.86,
      -61.524,
      -12.455,
      14.32,
      -61.524,
      -12.455,
      14.32,
      -61.524,
      -11.305,
      14.32,
      -61.524,
      -11.305,
      11.86,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5188.0",
    polygon: [
      -29.849,
      26.636,
      15.36,
      -29.849,
      25.486,
      15.36,
      -29.849,
      25.486,
      17.82,
      -29.849,
      25.486,
      17.82,
      -29.849,
      26.636,
      17.82,
      -29.849,
      26.636,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "5079.0",
    polygon: [
      -43.529,
      27.776,
      8.36,
      -43.529,
      28.926,
      8.36,
      -43.529,
      28.926,
      10.82,
      -43.529,
      28.926,
      10.82,
      -43.529,
      27.776,
      10.82,
      -43.529,
      27.776,
      8.36,
    ],
    "04-15 15:15": 1,
  },
  {
    ID: "5416.0",
    polygon: [
      -61.524,
      -34.305,
      8.36,
      -61.524,
      -35.455,
      8.36,
      -61.524,
      -35.455,
      10.82,
      -61.524,
      -35.455,
      10.82,
      -61.524,
      -34.305,
      10.82,
      -61.524,
      -34.305,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5339.0",
    polygon: [
      -29.923,
      -23.955,
      8.36,
      -29.923,
      -25.105,
      8.36,
      -29.923,
      -25.105,
      10.82,
      -29.923,
      -25.105,
      10.82,
      -29.923,
      -23.955,
      10.82,
      -29.923,
      -23.955,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "910.0",
    polygon: [
      -61.524,
      -12.455,
      4.86,
      -61.524,
      -13.605,
      4.86,
      -61.524,
      -13.605,
      7.32,
      -61.524,
      -13.605,
      7.32,
      -61.524,
      -12.455,
      7.32,
      -61.524,
      -12.455,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4858.0",
    polygon: [
      30.257,
      5.743,
      36.36,
      30.735,
      4.61,
      36.36,
      30.735,
      4.61,
      38.82,
      30.735,
      4.61,
      38.82,
      30.257,
      5.743,
      38.82,
      30.257,
      5.743,
      36.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5417.0",
    polygon: [
      -61.524,
      -35.455,
      8.36,
      -61.524,
      -36.605,
      8.36,
      -61.524,
      -36.605,
      10.82,
      -61.524,
      -36.605,
      10.82,
      -61.524,
      -35.455,
      10.82,
      -61.524,
      -35.455,
      8.36,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5378.0",
    polygon: [
      -43.584,
      -22.786,
      8.36,
      -43.584,
      -21.636,
      8.36,
      -43.584,
      -21.636,
      10.82,
      -43.584,
      -21.636,
      10.82,
      -43.584,
      -22.786,
      10.82,
      -43.584,
      -22.786,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1221.0",
    polygon: [
      -11.981,
      -36.605,
      15.36,
      -11.981,
      -35.455,
      15.36,
      -11.981,
      -35.455,
      17.82,
      -11.981,
      -35.455,
      17.82,
      -11.981,
      -36.605,
      17.82,
      -11.981,
      -36.605,
      15.36,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1338.0",
    polygon: [
      -61.524,
      -35.455,
      15.36,
      -61.524,
      -36.605,
      15.36,
      -61.524,
      -36.605,
      17.82,
      -61.524,
      -36.605,
      17.82,
      -61.524,
      -35.455,
      17.82,
      -61.524,
      -35.455,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5593.0",
    polygon: [
      -29.923,
      -23.955,
      15.36,
      -29.923,
      -25.105,
      15.36,
      -29.923,
      -25.105,
      17.82,
      -29.923,
      -25.105,
      17.82,
      -29.923,
      -23.955,
      17.82,
      -29.923,
      -23.955,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5494.0",
    polygon: [
      -52.251,
      -7.235,
      11.86,
      -53.401,
      -7.235,
      11.86,
      -53.401,
      -7.235,
      14.32,
      -53.401,
      -7.235,
      14.32,
      -52.251,
      -7.235,
      14.32,
      -52.251,
      -7.235,
      11.86,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:30": 1,
    "04-15 11:45": 1,
    "04-15 12:45": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "5629.0",
    polygon: [
      -43.584,
      -22.786,
      15.36,
      -43.584,
      -21.636,
      15.36,
      -43.584,
      -21.636,
      17.82,
      -43.584,
      -21.636,
      17.82,
      -43.584,
      -22.786,
      17.82,
      -43.584,
      -22.786,
      15.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5532.0",
    polygon: [
      -61.524,
      -12.455,
      11.86,
      -61.524,
      -13.605,
      11.86,
      -61.524,
      -13.605,
      14.32,
      -61.524,
      -13.605,
      14.32,
      -61.524,
      -12.455,
      14.32,
      -61.524,
      -12.455,
      11.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5455.0",
    polygon: [
      -11.981,
      -14.755,
      11.86,
      -11.981,
      -13.605,
      11.86,
      -11.981,
      -13.605,
      14.32,
      -11.981,
      -13.605,
      14.32,
      -11.981,
      -14.755,
      14.32,
      -11.981,
      -14.755,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5456.0",
    polygon: [
      -11.981,
      -13.605,
      11.86,
      -11.981,
      -12.455,
      11.86,
      -11.981,
      -12.455,
      14.32,
      -11.981,
      -12.455,
      14.32,
      -11.981,
      -13.605,
      14.32,
      -11.981,
      -13.605,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5300.0",
    polygon: [
      -11.981,
      -36.605,
      8.36,
      -11.981,
      -35.455,
      8.36,
      -11.981,
      -35.455,
      10.82,
      -11.981,
      -35.455,
      10.82,
      -11.981,
      -36.605,
      10.82,
      -11.981,
      -36.605,
      8.36,
    ],
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1339.0",
    polygon: [
      -61.524,
      -36.605,
      15.36,
      -61.524,
      -37.755,
      15.36,
      -61.524,
      -37.755,
      17.82,
      -61.524,
      -37.755,
      17.82,
      -61.524,
      -36.605,
      17.82,
      -61.524,
      -36.605,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5418.0",
    polygon: [
      -61.524,
      -36.605,
      8.36,
      -61.524,
      -37.755,
      8.36,
      -61.524,
      -37.755,
      10.82,
      -61.524,
      -37.755,
      10.82,
      -61.524,
      -36.605,
      10.82,
      -61.524,
      -36.605,
      8.36,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5301.0",
    polygon: [
      -11.981,
      -35.455,
      8.36,
      -11.981,
      -34.305,
      8.36,
      -11.981,
      -34.305,
      10.82,
      -11.981,
      -34.305,
      10.82,
      -11.981,
      -35.455,
      10.82,
      -11.981,
      -35.455,
      8.36,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5267.0",
    polygon: [
      -61.524,
      -13.605,
      4.86,
      -61.524,
      -14.755,
      4.86,
      -61.524,
      -14.755,
      7.32,
      -61.524,
      -14.755,
      7.32,
      -61.524,
      -13.605,
      7.32,
      -61.524,
      -13.605,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5338.0",
    polygon: [
      -29.923,
      -22.805,
      8.36,
      -29.923,
      -23.955,
      8.36,
      -29.923,
      -23.955,
      10.82,
      -29.923,
      -23.955,
      10.82,
      -29.923,
      -22.805,
      10.82,
      -29.923,
      -22.805,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5594.0",
    polygon: [
      -29.923,
      -25.105,
      15.36,
      -29.923,
      -26.255,
      15.36,
      -29.923,
      -26.255,
      17.82,
      -29.923,
      -26.255,
      17.82,
      -29.923,
      -25.105,
      17.82,
      -29.923,
      -25.105,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5155.0",
    polygon: [
      -61.429,
      23.839,
      11.86,
      -61.429,
      22.689,
      11.86,
      -61.429,
      22.689,
      14.32,
      -61.429,
      22.689,
      14.32,
      -61.429,
      23.839,
      14.32,
      -61.429,
      23.839,
      11.86,
    ],
    "04-15 06:15": 1,
  },
  {
    ID: "5495.0",
    polygon: [
      -51.101,
      -7.235,
      11.86,
      -52.251,
      -7.235,
      11.86,
      -52.251,
      -7.235,
      14.32,
      -52.251,
      -7.235,
      14.32,
      -51.101,
      -7.235,
      14.32,
      -51.101,
      -7.235,
      11.86,
    ],
    "04-15 09:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "5533.0",
    polygon: [
      -61.524,
      -13.605,
      11.86,
      -61.524,
      -14.755,
      11.86,
      -61.524,
      -14.755,
      14.32,
      -61.524,
      -14.755,
      14.32,
      -61.524,
      -13.605,
      14.32,
      -61.524,
      -13.605,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5630.0",
    polygon: [
      -43.584,
      -23.936,
      15.36,
      -43.584,
      -22.786,
      15.36,
      -43.584,
      -22.786,
      17.82,
      -43.584,
      -22.786,
      17.82,
      -43.584,
      -23.936,
      17.82,
      -43.584,
      -23.936,
      15.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5595.0",
    polygon: [
      -29.923,
      -26.254,
      15.36,
      -29.923,
      -27.404,
      15.36,
      -29.923,
      -27.404,
      17.82,
      -29.923,
      -27.404,
      17.82,
      -29.923,
      -26.254,
      17.82,
      -29.923,
      -26.254,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "831.0",
    polygon: [
      -11.981,
      -15.905,
      4.86,
      -11.981,
      -14.755,
      4.86,
      -11.981,
      -14.755,
      7.32,
      -11.981,
      -14.755,
      7.32,
      -11.981,
      -15.905,
      7.32,
      -11.981,
      -15.905,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5496.0",
    polygon: [
      -49.926,
      -7.235,
      11.86,
      -51.076,
      -7.235,
      11.86,
      -51.076,
      -7.235,
      14.32,
      -51.076,
      -7.235,
      14.32,
      -49.926,
      -7.235,
      14.32,
      -49.926,
      -7.235,
      11.86,
    ],
    "04-15 10:45": 1,
    "04-15 11:45": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "870.0",
    polygon: [
      -53.396,
      -7.235,
      4.86,
      -54.546,
      -7.235,
      4.86,
      -54.546,
      -7.235,
      7.32,
      -54.546,
      -7.235,
      7.32,
      -53.396,
      -7.235,
      7.32,
      -53.396,
      -7.235,
      4.86,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "833.0",
    polygon: [
      -11.981,
      -13.605,
      4.86,
      -11.981,
      -12.455,
      4.86,
      -11.981,
      -12.455,
      7.32,
      -11.981,
      -12.455,
      7.32,
      -11.981,
      -13.605,
      7.32,
      -11.981,
      -13.605,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5631.0",
    polygon: [
      -43.584,
      -25.086,
      15.36,
      -43.584,
      -23.936,
      15.36,
      -43.584,
      -23.936,
      17.82,
      -43.584,
      -23.936,
      17.82,
      -43.584,
      -25.086,
      17.82,
      -43.584,
      -25.086,
      15.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5340.0",
    polygon: [
      -29.923,
      -25.105,
      8.36,
      -29.923,
      -26.255,
      8.36,
      -29.923,
      -26.255,
      10.82,
      -29.923,
      -26.255,
      10.82,
      -29.923,
      -25.105,
      10.82,
      -29.923,
      -25.105,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5534.0",
    polygon: [
      -61.524,
      -14.755,
      11.86,
      -61.524,
      -15.905,
      11.86,
      -61.524,
      -15.905,
      14.32,
      -61.524,
      -15.905,
      14.32,
      -61.524,
      -14.755,
      14.32,
      -61.524,
      -14.755,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1222.0",
    polygon: [
      -11.981,
      -35.455,
      15.36,
      -11.981,
      -34.305,
      15.36,
      -11.981,
      -34.305,
      17.82,
      -11.981,
      -34.305,
      17.82,
      -11.981,
      -35.455,
      17.82,
      -11.981,
      -35.455,
      15.36,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5377.0",
    polygon: [
      -43.584,
      -21.636,
      8.36,
      -43.584,
      -20.486,
      8.36,
      -43.584,
      -20.486,
      10.82,
      -43.584,
      -20.486,
      10.82,
      -43.584,
      -21.636,
      10.82,
      -43.584,
      -21.636,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5457.0",
    polygon: [
      -11.981,
      -12.455,
      11.86,
      -11.981,
      -11.305,
      11.86,
      -11.981,
      -11.305,
      14.32,
      -11.981,
      -11.305,
      14.32,
      -11.981,
      -12.455,
      14.32,
      -11.981,
      -12.455,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "5190.0",
    polygon: [
      -29.849,
      24.336,
      15.36,
      -29.849,
      23.186,
      15.36,
      -29.849,
      23.186,
      17.82,
      -29.849,
      23.186,
      17.82,
      -29.849,
      24.336,
      17.82,
      -29.849,
      24.336,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "832.0",
    polygon: [
      -11.981,
      -14.755,
      4.86,
      -11.981,
      -13.605,
      4.86,
      -11.981,
      -13.605,
      7.32,
      -11.981,
      -13.605,
      7.32,
      -11.981,
      -14.755,
      7.32,
      -11.981,
      -14.755,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "4859.0",
    polygon: [
      30.713,
      4.665,
      36.36,
      31.165,
      3.522,
      36.36,
      31.165,
      3.522,
      38.82,
      31.165,
      3.522,
      38.82,
      30.713,
      4.665,
      38.82,
      30.713,
      4.665,
      36.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "5379.0",
    polygon: [
      -43.584,
      -23.936,
      8.36,
      -43.584,
      -22.786,
      8.36,
      -43.584,
      -22.786,
      10.82,
      -43.584,
      -22.786,
      10.82,
      -43.584,
      -23.936,
      10.82,
      -43.584,
      -23.936,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "871.0",
    polygon: [
      -52.251,
      -7.235,
      4.86,
      -53.401,
      -7.235,
      4.86,
      -53.401,
      -7.235,
      7.32,
      -53.401,
      -7.235,
      7.32,
      -52.251,
      -7.235,
      7.32,
      -52.251,
      -7.235,
      4.86,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1223.0",
    polygon: [
      -11.981,
      -34.305,
      15.36,
      -11.981,
      -33.155,
      15.36,
      -11.981,
      -33.155,
      17.82,
      -11.981,
      -33.155,
      17.82,
      -11.981,
      -34.305,
      17.82,
      -11.981,
      -34.305,
      15.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5191.0",
    polygon: [
      -29.849,
      23.186,
      15.36,
      -29.849,
      22.036,
      15.36,
      -29.849,
      22.036,
      17.82,
      -29.849,
      22.036,
      17.82,
      -29.849,
      23.186,
      17.82,
      -29.849,
      23.186,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "5299.0",
    polygon: [
      -11.981,
      -37.755,
      8.36,
      -11.981,
      -36.605,
      8.36,
      -11.981,
      -36.605,
      10.82,
      -11.981,
      -36.605,
      10.82,
      -11.981,
      -37.755,
      10.82,
      -11.981,
      -37.755,
      8.36,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "872.0",
    polygon: [
      -51.101,
      -7.235,
      4.86,
      -52.251,
      -7.235,
      4.86,
      -52.251,
      -7.235,
      7.32,
      -52.251,
      -7.235,
      7.32,
      -51.101,
      -7.235,
      7.32,
      -51.101,
      -7.235,
      4.86,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1340.0",
    polygon: [
      -61.524,
      -37.755,
      15.36,
      -61.524,
      -38.905,
      15.36,
      -61.524,
      -38.905,
      17.82,
      -61.524,
      -38.905,
      17.82,
      -61.524,
      -37.755,
      17.82,
      -61.524,
      -37.755,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5596.0",
    polygon: [
      -29.923,
      -27.404,
      15.36,
      -29.923,
      -28.554,
      15.36,
      -29.923,
      -28.554,
      17.82,
      -29.923,
      -28.554,
      17.82,
      -29.923,
      -27.404,
      17.82,
      -29.923,
      -27.404,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
  },
  {
    ID: "5122.0",
    polygon: [
      -11.63,
      29.216,
      11.86,
      -11.63,
      30.366,
      11.86,
      -11.63,
      30.366,
      14.32,
      -11.63,
      30.366,
      14.32,
      -11.63,
      29.216,
      14.32,
      -11.63,
      29.216,
      11.86,
    ],
    "04-15 16:00": 1,
  },
  {
    ID: "4682.0",
    polygon: [
      1.621,
      16.248,
      11.86,
      1.621,
      15.098,
      11.86,
      1.621,
      15.098,
      14.32,
      1.621,
      15.098,
      14.32,
      1.621,
      16.248,
      14.32,
      1.621,
      16.248,
      11.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "1224.0",
    polygon: [
      -11.981,
      -33.155,
      15.36,
      -11.981,
      -32.005,
      15.36,
      -11.981,
      -32.005,
      17.82,
      -11.981,
      -32.005,
      17.82,
      -11.981,
      -33.155,
      17.82,
      -11.981,
      -33.155,
      15.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1341.0",
    polygon: [
      -61.524,
      -38.905,
      15.36,
      -61.524,
      -40.055,
      15.36,
      -61.524,
      -40.055,
      17.82,
      -61.524,
      -40.055,
      17.82,
      -61.524,
      -38.905,
      17.82,
      -61.524,
      -38.905,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "835.0",
    polygon: [
      -11.981,
      -11.305,
      4.86,
      -11.981,
      -10.155,
      4.86,
      -11.981,
      -10.155,
      7.32,
      -11.981,
      -10.155,
      7.32,
      -11.981,
      -11.305,
      7.32,
      -11.981,
      -11.305,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5535.0",
    polygon: [
      -61.524,
      -15.905,
      11.86,
      -61.524,
      -17.055,
      11.86,
      -61.524,
      -17.055,
      14.32,
      -61.524,
      -17.055,
      14.32,
      -61.524,
      -15.905,
      14.32,
      -61.524,
      -15.905,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5458.0",
    polygon: [
      -11.981,
      -11.305,
      11.86,
      -11.981,
      -10.155,
      11.86,
      -11.981,
      -10.155,
      14.32,
      -11.981,
      -10.155,
      14.32,
      -11.981,
      -11.305,
      14.32,
      -11.981,
      -11.305,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "5302.0",
    polygon: [
      -11.981,
      -34.305,
      8.36,
      -11.981,
      -33.155,
      8.36,
      -11.981,
      -33.155,
      10.82,
      -11.981,
      -33.155,
      10.82,
      -11.981,
      -34.305,
      10.82,
      -11.981,
      -34.305,
      8.36,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "5380.0",
    polygon: [
      -43.584,
      -25.086,
      8.36,
      -43.584,
      -23.936,
      8.36,
      -43.584,
      -23.936,
      10.82,
      -43.584,
      -23.936,
      10.82,
      -43.584,
      -25.086,
      10.82,
      -43.584,
      -25.086,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5269.0",
    polygon: [
      -61.524,
      -15.905,
      4.86,
      -61.524,
      -17.055,
      4.86,
      -61.524,
      -17.055,
      7.32,
      -61.524,
      -17.055,
      7.32,
      -61.524,
      -15.905,
      7.32,
      -61.524,
      -15.905,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5419.0",
    polygon: [
      -61.524,
      -37.755,
      8.36,
      -61.524,
      -38.905,
      8.36,
      -61.524,
      -38.905,
      10.82,
      -61.524,
      -38.905,
      10.82,
      -61.524,
      -37.755,
      10.82,
      -61.524,
      -37.755,
      8.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4861.0",
    polygon: [
      31.695,
      2.067,
      36.36,
      32.084,
      0.9,
      36.36,
      32.084,
      0.9,
      38.82,
      32.084,
      0.9,
      38.82,
      31.695,
      2.067,
      38.82,
      31.695,
      2.067,
      36.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "5192.0",
    polygon: [
      -29.849,
      22.036,
      15.36,
      -29.849,
      20.886,
      15.36,
      -29.849,
      20.886,
      17.82,
      -29.849,
      20.886,
      17.82,
      -29.849,
      22.036,
      17.82,
      -29.849,
      22.036,
      15.36,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "5123.0",
    polygon: [
      -29.849,
      30.086,
      11.86,
      -29.849,
      28.936,
      11.86,
      -29.849,
      28.936,
      14.32,
      -29.849,
      28.936,
      14.32,
      -29.849,
      30.086,
      14.32,
      -29.849,
      30.086,
      11.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "836.0",
    polygon: [
      -11.981,
      -10.155,
      4.86,
      -11.981,
      -9.005,
      4.86,
      -11.981,
      -9.005,
      7.32,
      -11.981,
      -9.005,
      7.32,
      -11.981,
      -10.155,
      7.32,
      -11.981,
      -10.155,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5268.0",
    polygon: [
      -61.524,
      -14.755,
      4.86,
      -61.524,
      -15.905,
      4.86,
      -61.524,
      -15.905,
      7.32,
      -61.524,
      -15.905,
      7.32,
      -61.524,
      -14.755,
      7.32,
      -61.524,
      -14.755,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5342.0",
    polygon: [
      -29.923,
      -27.404,
      8.36,
      -29.923,
      -28.554,
      8.36,
      -29.923,
      -28.554,
      10.82,
      -29.923,
      -28.554,
      10.82,
      -29.923,
      -27.404,
      10.82,
      -29.923,
      -27.404,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "874.0",
    polygon: [
      -48.776,
      -7.235,
      4.86,
      -49.926,
      -7.235,
      4.86,
      -49.926,
      -7.235,
      7.32,
      -49.926,
      -7.235,
      7.32,
      -48.776,
      -7.235,
      7.32,
      -48.776,
      -7.235,
      4.86,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "4683.0",
    polygon: [
      1.621,
      17.55,
      11.86,
      1.621,
      16.4,
      11.86,
      1.621,
      16.4,
      14.32,
      1.621,
      16.4,
      14.32,
      1.621,
      17.55,
      14.32,
      1.621,
      17.55,
      11.86,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "5632.0",
    polygon: [
      -43.584,
      -26.236,
      15.36,
      -43.584,
      -25.086,
      15.36,
      -43.584,
      -25.086,
      17.82,
      -43.584,
      -25.086,
      17.82,
      -43.584,
      -26.236,
      17.82,
      -43.584,
      -26.236,
      15.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5497.0",
    polygon: [
      -48.776,
      -7.235,
      11.86,
      -49.926,
      -7.235,
      11.86,
      -49.926,
      -7.235,
      14.32,
      -49.926,
      -7.235,
      14.32,
      -48.776,
      -7.235,
      14.32,
      -48.776,
      -7.235,
      11.86,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 12:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1147.0",
    polygon: [
      -47.626,
      -7.235,
      11.86,
      -48.776,
      -7.235,
      11.86,
      -48.776,
      -7.235,
      14.32,
      -48.776,
      -7.235,
      14.32,
      -47.626,
      -7.235,
      14.32,
      -47.626,
      -7.235,
      11.86,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:30": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "641.0",
    polygon: [
      -29.849,
      28.936,
      11.86,
      -29.849,
      27.786,
      11.86,
      -29.849,
      27.786,
      14.32,
      -29.849,
      27.786,
      14.32,
      -29.849,
      28.936,
      14.32,
      -29.849,
      28.936,
      11.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "5536.0",
    polygon: [
      -61.524,
      -17.055,
      11.86,
      -61.524,
      -18.205,
      11.86,
      -61.524,
      -18.205,
      14.32,
      -61.524,
      -18.205,
      14.32,
      -61.524,
      -17.055,
      14.32,
      -61.524,
      -17.055,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "4867.0",
    polygon: [
      -74.981,
      -33.398,
      0.05,
      -74.981,
      -32.578,
      0.05,
      -74.981,
      -32.578,
      2.78,
      -74.981,
      -32.578,
      2.78,
      -74.981,
      -33.398,
      2.78,
      -74.981,
      -33.398,
      0.05,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "873.0",
    polygon: [
      -49.926,
      -7.235,
      4.86,
      -51.076,
      -7.235,
      4.86,
      -51.076,
      -7.235,
      7.32,
      -51.076,
      -7.235,
      7.32,
      -49.926,
      -7.235,
      7.32,
      -49.926,
      -7.235,
      4.86,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "4860.0",
    polygon: [
      31.296,
      3.173,
      36.36,
      31.712,
      2.016,
      36.36,
      31.712,
      2.016,
      38.82,
      31.712,
      2.016,
      38.82,
      31.296,
      3.173,
      38.82,
      31.296,
      3.173,
      36.36,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "5303.0",
    polygon: [
      -11.981,
      -33.155,
      8.36,
      -11.981,
      -32.005,
      8.36,
      -11.981,
      -32.005,
      10.82,
      -11.981,
      -32.005,
      10.82,
      -11.981,
      -33.155,
      10.82,
      -11.981,
      -33.155,
      8.36,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5459.0",
    polygon: [
      -11.981,
      -10.155,
      11.86,
      -11.981,
      -9.005,
      11.86,
      -11.981,
      -9.005,
      14.32,
      -11.981,
      -9.005,
      14.32,
      -11.981,
      -10.155,
      14.32,
      -11.981,
      -10.155,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "4684.0",
    polygon: [
      1.621,
      18.853,
      11.86,
      1.621,
      17.703,
      11.86,
      1.621,
      17.703,
      14.32,
      1.621,
      17.703,
      14.32,
      1.621,
      18.853,
      14.32,
      1.621,
      18.853,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
  },
  {
    ID: "834.0",
    polygon: [
      -11.981,
      -12.455,
      4.86,
      -11.981,
      -11.305,
      4.86,
      -11.981,
      -11.305,
      7.32,
      -11.981,
      -11.305,
      7.32,
      -11.981,
      -12.455,
      7.32,
      -11.981,
      -12.455,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5420.0",
    polygon: [
      -61.524,
      -38.905,
      8.36,
      -61.524,
      -40.055,
      8.36,
      -61.524,
      -40.055,
      10.82,
      -61.524,
      -40.055,
      10.82,
      -61.524,
      -38.905,
      10.82,
      -61.524,
      -38.905,
      8.36,
    ],
    "04-15 06:30": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5193.0",
    polygon: [
      -29.849,
      20.886,
      15.36,
      -29.849,
      19.736,
      15.36,
      -29.849,
      19.736,
      17.82,
      -29.849,
      19.736,
      17.82,
      -29.849,
      20.886,
      17.82,
      -29.849,
      20.886,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:30": 1,
  },
  {
    ID: "5381.0",
    polygon: [
      -43.584,
      -26.236,
      8.36,
      -43.584,
      -25.086,
      8.36,
      -43.584,
      -25.086,
      10.82,
      -43.584,
      -25.086,
      10.82,
      -43.584,
      -26.236,
      10.82,
      -43.584,
      -26.236,
      8.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5560.0",
    polygon: [
      -11.981,
      -32.005,
      15.36,
      -11.981,
      -30.855,
      15.36,
      -11.981,
      -30.855,
      17.82,
      -11.981,
      -30.855,
      17.82,
      -11.981,
      -32.005,
      17.82,
      -11.981,
      -32.005,
      15.36,
    ],
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5597.0",
    polygon: [
      -29.923,
      -28.554,
      15.36,
      -29.923,
      -29.704,
      15.36,
      -29.923,
      -29.704,
      17.82,
      -29.923,
      -29.704,
      17.82,
      -29.923,
      -28.554,
      17.82,
      -29.923,
      -28.554,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "5633.0",
    polygon: [
      -43.584,
      -27.386,
      15.36,
      -43.584,
      -26.236,
      15.36,
      -43.584,
      -26.236,
      17.82,
      -43.584,
      -26.236,
      17.82,
      -43.584,
      -27.386,
      17.82,
      -43.584,
      -27.386,
      15.36,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5270.0",
    polygon: [
      -61.524,
      -17.055,
      4.86,
      -61.524,
      -18.205,
      4.86,
      -61.524,
      -18.205,
      7.32,
      -61.524,
      -18.205,
      7.32,
      -61.524,
      -17.055,
      7.32,
      -61.524,
      -17.055,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5341.0",
    polygon: [
      -29.923,
      -26.254,
      8.36,
      -29.923,
      -27.404,
      8.36,
      -29.923,
      -27.404,
      10.82,
      -29.923,
      -27.404,
      10.82,
      -29.923,
      -26.254,
      10.82,
      -29.923,
      -26.254,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5271.0",
    polygon: [
      -61.524,
      -18.205,
      4.86,
      -61.524,
      -19.355,
      4.86,
      -61.524,
      -19.355,
      7.32,
      -61.524,
      -19.355,
      7.32,
      -61.524,
      -18.205,
      7.32,
      -61.524,
      -18.205,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "4868.0",
    polygon: [
      -74.981,
      -24.459,
      -0.35,
      -74.981,
      -22.959,
      -0.35,
      -74.981,
      -22.959,
      2.78,
      -74.981,
      -22.959,
      2.78,
      -74.981,
      -24.459,
      2.78,
      -74.981,
      -24.459,
      -0.35,
    ],
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "4685.0",
    polygon: [
      1.621,
      20.155,
      11.86,
      1.621,
      19.005,
      11.86,
      1.621,
      19.005,
      14.32,
      1.621,
      19.005,
      14.32,
      1.621,
      20.155,
      14.32,
      1.621,
      20.155,
      11.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "643.0",
    polygon: [
      -29.849,
      26.636,
      11.86,
      -29.849,
      25.486,
      11.86,
      -29.849,
      25.486,
      14.32,
      -29.849,
      25.486,
      14.32,
      -29.849,
      26.636,
      14.32,
      -29.849,
      26.636,
      11.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
  },
  {
    ID: "642.0",
    polygon: [
      -29.849,
      27.786,
      11.86,
      -29.849,
      26.636,
      11.86,
      -29.849,
      26.636,
      14.32,
      -29.849,
      26.636,
      14.32,
      -29.849,
      27.786,
      14.32,
      -29.849,
      27.786,
      11.86,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
  },
  {
    ID: "798.0",
    polygon: [
      -27.529,
      -7.235,
      4.86,
      -28.679,
      -7.235,
      4.86,
      -28.679,
      -7.235,
      7.32,
      -28.679,
      -7.235,
      7.32,
      -27.529,
      -7.235,
      7.32,
      -27.529,
      -7.235,
      4.86,
    ],
    "04-15 08:30": 1,
    "04-15 09:15": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "5304.0",
    polygon: [
      -11.981,
      -32.005,
      8.36,
      -11.981,
      -30.855,
      8.36,
      -11.981,
      -30.855,
      10.82,
      -11.981,
      -30.855,
      10.82,
      -11.981,
      -32.005,
      10.82,
      -11.981,
      -32.005,
      8.36,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "1189.0",
    polygon: [
      -61.524,
      -20.505,
      11.86,
      -61.524,
      -21.655,
      11.86,
      -61.524,
      -21.655,
      14.32,
      -61.524,
      -21.655,
      14.32,
      -61.524,
      -20.505,
      14.32,
      -61.524,
      -20.505,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "4869.0",
    polygon: [
      -74.981,
      -25.279,
      -0.35,
      -74.981,
      -24.459,
      -0.35,
      -74.981,
      -24.459,
      2.78,
      -74.981,
      -24.459,
      2.78,
      -74.981,
      -25.279,
      2.78,
      -74.981,
      -25.279,
      -0.35,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "837.0",
    polygon: [
      -11.981,
      -9.005,
      4.86,
      -11.981,
      -7.855,
      4.86,
      -11.981,
      -7.855,
      7.32,
      -11.981,
      -7.855,
      7.32,
      -11.981,
      -9.005,
      7.32,
      -11.981,
      -9.005,
      4.86,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5562.0",
    polygon: [
      -11.981,
      -29.705,
      15.36,
      -11.981,
      -28.555,
      15.36,
      -11.981,
      -28.555,
      17.82,
      -11.981,
      -28.555,
      17.82,
      -11.981,
      -29.705,
      17.82,
      -11.981,
      -29.705,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5500.0",
    polygon: [
      -44.176,
      -7.235,
      11.86,
      -45.326,
      -7.235,
      11.86,
      -45.326,
      -7.235,
      14.32,
      -45.326,
      -7.235,
      14.32,
      -44.176,
      -7.235,
      14.32,
      -44.176,
      -7.235,
      11.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5599.0",
    polygon: [
      -29.923,
      -30.854,
      15.36,
      -29.923,
      -32.004,
      15.36,
      -29.923,
      -32.004,
      17.82,
      -29.923,
      -32.004,
      17.82,
      -29.923,
      -30.854,
      17.82,
      -29.923,
      -30.854,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5306.0",
    polygon: [
      -11.981,
      -29.705,
      8.36,
      -11.981,
      -28.555,
      8.36,
      -11.981,
      -28.555,
      10.82,
      -11.981,
      -28.555,
      10.82,
      -11.981,
      -29.705,
      10.82,
      -11.981,
      -29.705,
      8.36,
    ],
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5460.0",
    polygon: [
      -11.981,
      -9.005,
      11.86,
      -11.981,
      -7.855,
      11.86,
      -11.981,
      -7.855,
      14.32,
      -11.981,
      -7.855,
      14.32,
      -11.981,
      -9.005,
      14.32,
      -11.981,
      -9.005,
      11.86,
    ],
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5635.0",
    polygon: [
      -43.584,
      -29.686,
      15.36,
      -43.584,
      -28.536,
      15.36,
      -43.584,
      -28.536,
      17.82,
      -43.584,
      -28.536,
      17.82,
      -43.584,
      -29.686,
      17.82,
      -43.584,
      -29.686,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "5421.0",
    polygon: [
      -27.529,
      -7.235,
      11.86,
      -28.679,
      -7.235,
      11.86,
      -28.679,
      -7.235,
      14.32,
      -28.679,
      -7.235,
      14.32,
      -27.529,
      -7.235,
      14.32,
      -27.529,
      -7.235,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "1188.0",
    polygon: [
      -61.524,
      -19.355,
      11.86,
      -61.524,
      -20.505,
      11.86,
      -61.524,
      -20.505,
      14.32,
      -61.524,
      -20.505,
      14.32,
      -61.524,
      -19.355,
      14.32,
      -61.524,
      -19.355,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5194.0",
    polygon: [
      -29.849,
      19.736,
      15.36,
      -29.849,
      18.586,
      15.36,
      -29.849,
      18.586,
      17.82,
      -29.849,
      18.586,
      17.82,
      -29.849,
      19.736,
      17.82,
      -29.849,
      19.736,
      15.36,
    ],
    "04-15 06:15": 1,
  },
  {
    ID: "5499.0",
    polygon: [
      -45.326,
      -7.235,
      11.86,
      -46.476,
      -7.235,
      11.86,
      -46.476,
      -7.235,
      14.32,
      -46.476,
      -7.235,
      14.32,
      -45.326,
      -7.235,
      14.32,
      -45.326,
      -7.235,
      11.86,
    ],
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5343.0",
    polygon: [
      -29.923,
      -28.554,
      8.36,
      -29.923,
      -29.704,
      8.36,
      -29.923,
      -29.704,
      10.82,
      -29.923,
      -29.704,
      10.82,
      -29.923,
      -28.554,
      10.82,
      -29.923,
      -28.554,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5461.0",
    polygon: [
      -29.923,
      -7.855,
      11.86,
      -29.923,
      -9.005,
      11.86,
      -29.923,
      -9.005,
      14.32,
      -29.923,
      -9.005,
      14.32,
      -29.923,
      -7.855,
      14.32,
      -29.923,
      -7.855,
      11.86,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "875.0",
    polygon: [
      -47.626,
      -7.235,
      4.86,
      -48.776,
      -7.235,
      4.86,
      -48.776,
      -7.235,
      7.32,
      -48.776,
      -7.235,
      7.32,
      -47.626,
      -7.235,
      7.32,
      -47.626,
      -7.235,
      4.86,
    ],
    "04-15 10:30": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "5382.0",
    polygon: [
      -43.584,
      -27.386,
      8.36,
      -43.584,
      -26.236,
      8.36,
      -43.584,
      -26.236,
      10.82,
      -43.584,
      -26.236,
      10.82,
      -43.584,
      -27.386,
      10.82,
      -43.584,
      -27.386,
      8.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5462.0",
    polygon: [
      -29.923,
      -9.005,
      11.86,
      -29.923,
      -10.155,
      11.86,
      -29.923,
      -10.155,
      14.32,
      -29.923,
      -10.155,
      14.32,
      -29.923,
      -9.005,
      14.32,
      -29.923,
      -9.005,
      11.86,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5345.0",
    polygon: [
      -29.923,
      -30.854,
      8.36,
      -29.923,
      -32.004,
      8.36,
      -29.923,
      -32.004,
      10.82,
      -29.923,
      -32.004,
      10.82,
      -29.923,
      -30.854,
      10.82,
      -29.923,
      -30.854,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5305.0",
    polygon: [
      -11.981,
      -30.855,
      8.36,
      -11.981,
      -29.705,
      8.36,
      -11.981,
      -29.705,
      10.82,
      -11.981,
      -29.705,
      10.82,
      -11.981,
      -30.855,
      10.82,
      -11.981,
      -30.855,
      8.36,
    ],
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "877.0",
    polygon: [
      -45.326,
      -7.235,
      4.86,
      -46.476,
      -7.235,
      4.86,
      -46.476,
      -7.235,
      7.32,
      -46.476,
      -7.235,
      7.32,
      -45.326,
      -7.235,
      7.32,
      -45.326,
      -7.235,
      4.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "5561.0",
    polygon: [
      -11.981,
      -30.855,
      15.36,
      -11.981,
      -29.705,
      15.36,
      -11.981,
      -29.705,
      17.82,
      -11.981,
      -29.705,
      17.82,
      -11.981,
      -30.855,
      17.82,
      -11.981,
      -30.855,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:30": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5195.0",
    polygon: [
      -29.849,
      18.586,
      15.36,
      -29.849,
      17.436,
      15.36,
      -29.849,
      17.436,
      17.82,
      -29.849,
      17.436,
      17.82,
      -29.849,
      18.586,
      17.82,
      -29.849,
      18.586,
      15.36,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "5423.0",
    polygon: [
      -25.221,
      -7.235,
      11.86,
      -26.371,
      -7.235,
      11.86,
      -26.371,
      -7.235,
      14.32,
      -26.371,
      -7.235,
      14.32,
      -25.221,
      -7.235,
      14.32,
      -25.221,
      -7.235,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "5344.0",
    polygon: [
      -29.923,
      -29.704,
      8.36,
      -29.923,
      -30.854,
      8.36,
      -29.923,
      -30.854,
      10.82,
      -29.923,
      -30.854,
      10.82,
      -29.923,
      -29.704,
      10.82,
      -29.923,
      -29.704,
      8.36,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5598.0",
    polygon: [
      -29.923,
      -29.704,
      15.36,
      -29.923,
      -30.854,
      15.36,
      -29.923,
      -30.854,
      17.82,
      -29.923,
      -30.854,
      17.82,
      -29.923,
      -29.704,
      17.82,
      -29.923,
      -29.704,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5384.0",
    polygon: [
      -43.584,
      -29.686,
      8.36,
      -43.584,
      -28.536,
      8.36,
      -43.584,
      -28.536,
      10.82,
      -43.584,
      -28.536,
      10.82,
      -43.584,
      -29.686,
      10.82,
      -43.584,
      -29.686,
      8.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:45": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5422.0",
    polygon: [
      -26.371,
      -7.235,
      11.86,
      -27.521,
      -7.235,
      11.86,
      -27.521,
      -7.235,
      14.32,
      -27.521,
      -7.235,
      14.32,
      -26.371,
      -7.235,
      14.32,
      -26.371,
      -7.235,
      11.86,
    ],
    "04-15 08:15": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "5634.0",
    polygon: [
      -43.584,
      -28.536,
      15.36,
      -43.584,
      -27.386,
      15.36,
      -43.584,
      -27.386,
      17.82,
      -43.584,
      -27.386,
      17.82,
      -43.584,
      -28.536,
      17.82,
      -43.584,
      -28.536,
      15.36,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "876.0",
    polygon: [
      -46.476,
      -7.235,
      4.86,
      -47.626,
      -7.235,
      4.86,
      -47.626,
      -7.235,
      7.32,
      -47.626,
      -7.235,
      7.32,
      -46.476,
      -7.235,
      7.32,
      -46.476,
      -7.235,
      4.86,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "838.0",
    polygon: [
      -29.923,
      -7.855,
      4.86,
      -29.923,
      -9.005,
      4.86,
      -29.923,
      -9.005,
      7.32,
      -29.923,
      -9.005,
      7.32,
      -29.923,
      -7.855,
      7.32,
      -29.923,
      -7.855,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "5498.0",
    polygon: [
      -46.476,
      -7.235,
      11.86,
      -47.626,
      -7.235,
      11.86,
      -47.626,
      -7.235,
      14.32,
      -47.626,
      -7.235,
      14.32,
      -46.476,
      -7.235,
      14.32,
      -46.476,
      -7.235,
      11.86,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1228.0",
    polygon: [
      -11.981,
      -28.555,
      15.36,
      -11.981,
      -27.405,
      15.36,
      -11.981,
      -27.405,
      17.82,
      -11.981,
      -27.405,
      17.82,
      -11.981,
      -28.555,
      17.82,
      -11.981,
      -28.555,
      15.36,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5537.0",
    polygon: [
      -61.524,
      -18.205,
      11.86,
      -61.524,
      -19.355,
      11.86,
      -61.524,
      -19.355,
      14.32,
      -61.524,
      -19.355,
      14.32,
      -61.524,
      -18.205,
      14.32,
      -61.524,
      -18.205,
      11.86,
    ],
    "04-15 06:15": 1,
    "04-15 06:45": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5383.0",
    polygon: [
      -43.584,
      -28.536,
      8.36,
      -43.584,
      -27.386,
      8.36,
      -43.584,
      -27.386,
      10.82,
      -43.584,
      -27.386,
      10.82,
      -43.584,
      -28.536,
      10.82,
      -43.584,
      -28.536,
      8.36,
    ],
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5272.0",
    polygon: [
      -61.524,
      -19.355,
      4.86,
      -61.524,
      -20.505,
      4.86,
      -61.524,
      -20.505,
      7.32,
      -61.524,
      -20.505,
      7.32,
      -61.524,
      -19.355,
      7.32,
      -61.524,
      -19.355,
      4.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:30": 1,
  },
  {
    ID: "5636.0",
    polygon: [
      -43.584,
      -30.836,
      15.36,
      -43.584,
      -29.686,
      15.36,
      -43.584,
      -29.686,
      17.82,
      -43.584,
      -29.686,
      17.82,
      -43.584,
      -30.836,
      17.82,
      -43.584,
      -30.836,
      15.36,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5600.0",
    polygon: [
      -29.923,
      -32.004,
      15.36,
      -29.923,
      -33.154,
      15.36,
      -29.923,
      -33.154,
      17.82,
      -29.923,
      -33.154,
      17.82,
      -29.923,
      -32.004,
      17.82,
      -29.923,
      -32.004,
      15.36,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 07:00": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "799.0",
    polygon: [
      -26.371,
      -7.235,
      4.86,
      -27.521,
      -7.235,
      4.86,
      -27.521,
      -7.235,
      7.32,
      -27.521,
      -7.235,
      7.32,
      -26.371,
      -7.235,
      7.32,
      -26.371,
      -7.235,
      4.86,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "1954.0",
    polygon: [
      -77.382,
      -7.156,
      18.86,
      -78.582,
      -7.156,
      18.86,
      -78.582,
      -7.156,
      21.32,
      -78.582,
      -7.156,
      21.32,
      -77.382,
      -7.156,
      21.32,
      -77.382,
      -7.156,
      18.86,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1758.0",
    polygon: [
      -26.351,
      -7.155,
      39.128,
      -27.551,
      -7.155,
      39.128,
      -27.551,
      -7.155,
      40.05,
      -27.551,
      -7.155,
      40.05,
      -26.351,
      -7.155,
      40.05,
      -26.351,
      -7.155,
      39.128,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1843.0",
    polygon: [
      -53.895,
      -7.155,
      32.86,
      -55.095,
      -7.155,
      32.86,
      -55.095,
      -7.155,
      35.32,
      -55.095,
      -7.155,
      35.32,
      -53.895,
      -7.155,
      35.32,
      -53.895,
      -7.155,
      32.86,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1876.0",
    polygon: [
      -20.943,
      -7.155,
      22.36,
      -22.143,
      -7.155,
      22.36,
      -22.143,
      -7.155,
      24.82,
      -22.143,
      -7.155,
      24.82,
      -20.943,
      -7.155,
      24.82,
      -20.943,
      -7.155,
      22.36,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1915.0",
    polygon: [
      -57.951,
      -7.155,
      18.86,
      -59.151,
      -7.155,
      18.86,
      -59.151,
      -7.155,
      21.32,
      -59.151,
      -7.155,
      21.32,
      -57.951,
      -7.155,
      21.32,
      -57.951,
      -7.155,
      18.86,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "3539.0",
    polygon: [
      -25.648,
      26.888,
      18.233,
      -25.648,
      27.888,
      18.233,
      -21.675,
      27.888,
      18.025,
      -21.675,
      27.888,
      18.025,
      -21.675,
      26.888,
      18.025,
      -25.648,
      26.888,
      18.233,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 14:15": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5586.0",
    polygon: [
      -29.923,
      -15.905,
      15.36,
      -29.923,
      -17.055,
      15.36,
      -29.923,
      -17.055,
      17.82,
      -29.923,
      -17.055,
      17.82,
      -29.923,
      -15.905,
      17.82,
      -29.923,
      -15.905,
      15.36,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "2920.0",
    polygon: [
      -83.118,
      -5.365,
      32.86,
      -83.118,
      -4.165,
      32.86,
      -83.118,
      -4.165,
      35.32,
      -83.118,
      -4.165,
      35.32,
      -83.118,
      -5.365,
      35.32,
      -83.118,
      -5.365,
      32.86,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
    "04-15 18:00": 1,
  },
];

export default project15_29ShaindgProgression;
