import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Dropdown } from "semantic-ui-react";
import {
  setIsRoomFilterByDetails,
  setSelectedRoomsDetails,
  setSelectedRoomsNames,
} from "../redux/sceneSlice";
import { getRoomTypeColor } from "./utils";
import { useAllRoomsNames, useUniqueRoomsFunctions } from "./CustomHooks";
import { CloseButton } from "react-bootstrap";

const RoomFilter = () => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.sceneVisibility.isMenuOpen);
  const roomsFunctions = useUniqueRoomsFunctions();
  const allRoomsNames = useAllRoomsNames();
  const nameFilterOptions = allRoomsNames?.map((name) => {
    return { key: name, value: name, text: name };
  });
  const functionsFilterOptions = roomsFunctions?.map((roomFunction, index) => {
    return {
      key: index + roomFunction,
      value: roomFunction ?? "Not specified",
      text: roomFunction ?? "Not specified",
      color: getRoomTypeColor(roomFunction, roomsFunctions),
    };
  });

  functionsFilterOptions.unshift({
    key: "-1all",
    value: "all",
    text: "Select all",
    color: "black",
  });

  var roomsFilter = useSelector((state) => state.sceneVisibility.roomsFilter);
  const { selectedRoomNames, selectedRoomDetails } = roomsFilter;

  const handleRoomNameChange = (e, { value }) => {
    dispatch(setSelectedRoomsNames(value));
  };

  const handleRoomDetailClick = (option) => {
    let newSelectedRooms = [];
    if (option.key === "-1all") {
      if (
        functionsFilterOptions
          .slice(1)
          .some((option) => !selectedRoomDetails.includes(option.value))
      )
        newSelectedRooms = functionsFilterOptions.slice(1).map((e) => e.value);
      else newSelectedRooms = [];
    } else if (selectedRoomDetails?.includes(option.value))
      newSelectedRooms = selectedRoomDetails?.filter((e) => e !== option.value);
    else {
      newSelectedRooms = [...selectedRoomDetails, option.value];
    }
    dispatch(setSelectedRoomsDetails(newSelectedRooms));
  };

  const isRoomDetailChecked = (option) => {
    if (option.key === "-1all") {
      return functionsFilterOptions
        .slice(1)
        .every((option) => selectedRoomDetails.includes(option.value));
    } else return selectedRoomDetails?.includes(option.value);
  };

  if (roomsFilter.isByDetails)
    return (
      <div
        style={{
          left: "20px",
          width: "250px",
          top: isMenuOpen ? "210px" : "64px",
          padding: 16,
          backgroundColor: "white",
          borderRadius: 7,
          border: "1px solid #ddd",
          position: "absolute",
          zIndex: 4,
          // textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: 8,
        }}
      >
        <CloseButton
          style={{
            position: "absolute",
            right: 16,
          }}
          onClick={() => dispatch(setIsRoomFilterByDetails(false))}
        />
        <h4>Filter by</h4>
        <div>
          <h5>Rental unit</h5>
          <Dropdown
            fluid
            clearable
            // simple
            multiple
            selection
            options={[
              {
                key: "Rental unit 1",
                value: "Rental unit 1",
                text: "Rental unit 1",
              },
            ]}
            placeholder="Select Rental unit"
          // onChange={handleRoomNameChange}
          // value={selectedRoomNames}
          />
        </div>

        <div>
          <h5>Room type</h5>
          <ul
            style={{
              backgroundColor: "white",
              border: "1px solid #ddd",
              borderRadius: 6,
              padding: "8px 0",
              maxHeight: "250px",
              overflow: "scroll",
            }}
          >
            {functionsFilterOptions?.map((option) => {
              const isAllOption = option.key === "-1all";
              return (
                <li
                  key={option.key}
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    padding: 8,
                    listStyle: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRoomDetailClick(option)}
                >
                  <div
                    style={{
                      width: !isAllOption ? 16 : 8,
                      height: !isAllOption ? 16 : 8,
                      borderRadius: !isAllOption ? 0 : "50%",
                      backgroundColor: option.color,
                    }}
                  />
                  <Checkbox
                    label={option.text}
                    checked={isRoomDetailChecked(option)}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <h5>Room name</h5>
          <Dropdown
            clearable
            fluid
            // simple
            multiple
            search
            selection
            options={nameFilterOptions}
            placeholder="Select room"
            onChange={handleRoomNameChange}
            value={selectedRoomNames}
          />
        </div>
      </div>
    );
};

export default RoomFilter
