import { useSelector } from "react-redux";
import { Spinner } from "./spinner";

const Loader = () => {
  var loading = useSelector((state) => state.sceneVisibility.loading);
  var windIsLoading = useSelector((state) => state.windDirection.isLoading);
  var windRiskIsLoading = useSelector((state) => state.shadingSystem.windRiskIsLoading);
  const windTurbineIsLoading = useSelector(state => state.windTurbine.windTurbineIsLoading)
  const pVDataIsLoading = useSelector(state => state.pvData.pVDataIsLoading)
  const daylightIsLoading = useSelector(state => state.daylightFactor.daylightIsLoading)

  var sensorIsLoading = useSelector((state) => state.sensorSolars.isLoading);

  const someIsLoading = loading.some((e) => e)
  if (someIsLoading) return <Spinner title="Building model..." />;
  else if (sensorIsLoading || windIsLoading || windTurbineIsLoading || windRiskIsLoading || pVDataIsLoading || daylightIsLoading)
    return <Spinner title="Loading data..." />;
};
export default Loader;
