const zoneOneProject15 = [
  {
    position: [-69.776153, 0.817757, 48.16],
    "overall score": 0.3794478493,
    "0": {
      velocity: [0.0770743866, 0.4952816965, 1.265742756],
      direction: [-179.9804088159, 14.2911975966, 179.3585490268],
      "speed factor": 0.63054411,
      "Uz factor": 0.9685659196,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2054263988,
      "Direction stability factor": 0.0018362282,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0239906508, 0.1395643119, 0.2653280883],
      direction: [-146.0634782664, -24.0277766335, 19.1930289157],
      "speed factor": 0.1498463579,
      "Uz factor": 0.9231824816,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6626552718,
      "Direction stability factor": 0.5409541467,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.22409101, 1.0869060807, 1.3875643539],
      direction: [-17.5382208446, -10.5522166948, 11.525322114],
      "speed factor": 1.0407565904,
      "Uz factor": 0.9953256141,
      "Directional variance factor": 0.0620251827,
      "Velocity stability factor": 0.1696002447,
      "Direction stability factor": 0.9192679362,
      score: 0.517540325,
    },
    "112.5": {
      velocity: [0.1389195637, 0.839231478, 1.166447147],
      direction: [-69.9475343821, 23.6537529953, 98.5567432919],
      "speed factor": 0.9164880244,
      "Uz factor": 0.971067565,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2437737344,
      "Direction stability factor": 0.531932562,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4719564763, 1.1417748236, 1.6555976528],
      direction: [-60.0035177123, -25.150606752, 2.5562359993],
      "speed factor": 1.1644576219,
      "Uz factor": 0.9948054511,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2874074138,
      "Direction stability factor": 0.8262229064,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6901214024, 0.9739418105, 1.276248059],
      direction: [-18.9506116925, 1.9718110449, 25.8732732679],
      "speed factor": 1.6060701633,
      "Uz factor": 0.995704371,
      "Directional variance factor": 0.8247279071,
      "Velocity stability factor": 0.5641223895,
      "Direction stability factor": 0.8754892084,
      score: 0.7849571784,
    },
    "247.5": {
      velocity: [0.9490349597, 0.9739892331, 0.9995231776],
      direction: [-1.9984603938, -0.0454906038, 1.411038314],
      "speed factor": 1.1751289348,
      "Uz factor": 0.9984880287,
      "Directional variance factor": 0.9959563908,
      "Velocity stability factor": 0.9537138734,
      "Direction stability factor": 0.9905291703,
      score: 0.9826821512,
    },
    "292.5": {
      velocity: [1.110070599, 1.2055544928, 1.2509091624],
      direction: [-9.9863642468, -6.6771347403, -4.7965631348],
      "speed factor": 1.4242205923,
      "Uz factor": 0.9834499557,
      "Directional variance factor": 0.406476912,
      "Velocity stability factor": 0.8711100698,
      "Direction stability factor": 0.9855838858,
      score: 0.8121886883,
    },
    "337.5": {
      velocity: [0.0976919062, 0.9593491233, 1.3871651526],
      direction: [-172.6579603462, 17.9191100347, 161.8134373582],
      "speed factor": 1.2386680204,
      "Uz factor": 0.9871564284,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1739900351,
      "Direction stability factor": 0.0709127842,
      score: 0.0,
    },
    id: "IDN:4574.0",
    "45": {
      velocity: [0.3864526263, 0.8723481675, 1.20972675],
      direction: [-17.6998968011, -3.6007760008, 5.1121988741],
      "speed factor": 0.8181830881,
      "Uz factor": 0.9850256799,
      "Directional variance factor": 0.6799310222,
      "Velocity stability factor": 0.3482489139,
      "Direction stability factor": 0.9366330676,
      score: 0.0,
    },
    "90": {
      velocity: [0.1844710215, 0.3732444791, 0.639437728],
      direction: [-36.2296609175, -9.127182026, 24.6708913576],
      "speed factor": 0.9560949524,
      "Uz factor": 0.9998311961,
      "Directional variance factor": 0.188694931,
      "Velocity stability factor": 0.3581377323,
      "Direction stability factor": 0.8308317992,
      score: 0.0,
    },
    "135": {
      velocity: [0.3401920828, 1.1015114227, 1.7674261386],
      direction: [-18.8721283884, 2.1630019446, 17.3524125784],
      "speed factor": 1.0022409269,
      "Uz factor": 0.953533673,
      "Directional variance factor": 0.8077331605,
      "Velocity stability factor": 0.1813910202,
      "Direction stability factor": 0.8993762751,
      score: 0.6969691827,
    },
    "180": {
      velocity: [0.0226312403, 0.4150623529, 0.9112055234],
      direction: [-154.5775610125, -44.843501984, 178.925699958],
      "speed factor": 0.589210181,
      "Uz factor": 0.9893048395,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2860831245,
      "Direction stability factor": 0.0736020529,
      score: 0.0,
    },
    "225": {
      velocity: [0.4624829902, 0.5045785909, 0.5692420942],
      direction: [-0.6616265891, 6.7642481792, 13.7645176093],
      "speed factor": 1.0127229462,
      "Uz factor": 0.9998249731,
      "Directional variance factor": 0.3987334952,
      "Velocity stability factor": 0.8459364343,
      "Direction stability factor": 0.9599273772,
      score: 0.791131171,
    },
    "270": {
      velocity: [0.4840182821, 0.5288037567, 0.5633201367],
      direction: [-14.4522511797, -7.5878499124, -3.229756466],
      "speed factor": 1.003547467,
      "Uz factor": 0.9997558128,
      "Directional variance factor": 0.3255244522,
      "Velocity stability factor": 0.8909461564,
      "Direction stability factor": 0.9688264036,
      score: 0.7885308539,
    },
    "315": {
      velocity: [0.6710269545, 0.9034013217, 1.2487891721],
      direction: [-18.7751454895, 4.6398436331, 36.4604295817],
      "speed factor": 1.421024288,
      "Uz factor": 0.9991224451,
      "Directional variance factor": 0.5875694548,
      "Velocity stability factor": 0.5079590059,
      "Direction stability factor": 0.846567847,
      score: 0.6971660387,
    },
  },
  {
    position: [-65.92847, 2.317757, 48.16],
    "overall score": 0.3742786466,
    "0": {
      velocity: [0.1199597963, 1.036487943, 1.428683981],
      direction: [-43.4903526532, 6.8720318376, 77.2326007597],
      "speed factor": 1.3195548557,
      "Uz factor": 0.9552468809,
      "Directional variance factor": 0.3891527255,
      "Velocity stability factor": 0.1251742579,
      "Direction stability factor": 0.6646584627,
      score: 0.4609109772,
    },
    "22.5": {
      velocity: [0.6253626197, 0.738236011, 0.869238918],
      direction: [0.4727189972, 2.741828358, 5.0041916153],
      "speed factor": 0.792623673,
      "Uz factor": 0.9114014035,
      "Directional variance factor": 0.7562819237,
      "Velocity stability factor": 0.659106418,
      "Direction stability factor": 0.9874125761,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.0227894333, 1.2625560089, 1.4130450076],
      direction: [-10.509983012, -5.3960114466, 1.297509907],
      "speed factor": 1.2089485103,
      "Uz factor": 0.9764680298,
      "Directional variance factor": 0.5203545381,
      "Velocity stability factor": 0.7214649264,
      "Direction stability factor": 0.9672014086,
      score: 0.7940555704,
    },
    "112.5": {
      velocity: [0.2369951844, 0.8830690138, 1.196970479],
      direction: [-41.191315235, 25.082345855, 58.0038129034],
      "speed factor": 0.9643610817,
      "Uz factor": 0.9881239904,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2934899813,
      "Direction stability factor": 0.7244579774,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.513428854, 1.1199125502, 1.6834580582],
      direction: [-87.8644441155, -24.6997830681, 1.8900888985],
      "speed factor": 1.1421610268,
      "Uz factor": 0.9967969286,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.295602288,
      "Direction stability factor": 0.7506818527,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6826234288, 0.9926264331, 1.337312953],
      direction: [-17.7476651511, 4.5259366376, 28.6901387844],
      "speed factor": 1.6368818756,
      "Uz factor": 0.9948611085,
      "Directional variance factor": 0.5976945211,
      "Velocity stability factor": 0.513135084,
      "Direction stability factor": 0.8710061002,
      score: 0.7132104514,
    },
    "247.5": {
      velocity: [0.9411866787, 0.9668586708, 0.9946042242],
      direction: [-2.6423728202, -0.6601242963, 1.3364410863],
      "speed factor": 1.1665258313,
      "Uz factor": 0.9996406295,
      "Directional variance factor": 0.9413222848,
      "Velocity stability factor": 0.9510283513,
      "Direction stability factor": 0.9889477391,
      score: 0.9675615286,
    },
    "292.5": {
      velocity: [0.7774703129, 1.1361532327, 1.2291760154],
      direction: [-10.2608457667, -6.781394619, -4.8516285217],
      "speed factor": 1.3422311805,
      "Uz factor": 0.9977553748,
      "Directional variance factor": 0.3972093672,
      "Velocity stability factor": 0.5866166548,
      "Direction stability factor": 0.9849743965,
      score: 0.7384437038,
    },
    "337.5": {
      velocity: [0.2116241191, 0.9955660192, 1.3612704827],
      direction: [-4.4830140272, 12.3819848049, 140.0321121779],
      "speed factor": 1.2854296316,
      "Uz factor": 0.9599565681,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2635602521,
      "Direction stability factor": 0.5985690939,
      score: 0.0,
    },
    id: "IDN:4595.0",
    "45": {
      velocity: [1.137176413, 1.246986261, 1.308996692],
      direction: [-4.3827278366, 2.3801796516, 9.5254968684],
      "speed factor": 1.1695594807,
      "Uz factor": 0.969340096,
      "Directional variance factor": 0.7884284754,
      "Velocity stability factor": 0.8639771976,
      "Direction stability factor": 0.9613660425,
      score: 0.8937844395,
    },
    "90": {
      velocity: [0.1845417794, 0.3757986875, 0.6282314444],
      direction: [-35.5217497953, -9.3683229586, 22.9627983981],
      "speed factor": 0.9626377572,
      "Uz factor": 0.9998810653,
      "Directional variance factor": 0.1672601815,
      "Velocity stability factor": 0.3740472645,
      "Direction stability factor": 0.8375429217,
      score: 0.0,
    },
    "135": {
      velocity: [0.3295924785, 1.078216389, 1.7272913218],
      direction: [-31.1626213203, 5.3429468512, 145.1165697583],
      "speed factor": 0.98104529,
      "Uz factor": 0.9656662918,
      "Directional variance factor": 0.525071391,
      "Velocity stability factor": 0.1983313321,
      "Direction stability factor": 0.5103355803,
      score: 0.0,
    },
    "180": {
      velocity: [0.0720716565, 0.3769758051, 0.8594924054],
      direction: [-176.3602368602, -48.8194070841, 152.3533511721],
      "speed factor": 0.5351436497,
      "Uz factor": 0.9923118689,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3673540058,
      "Direction stability factor": 0.0869066999,
      score: 0.0,
    },
    "225": {
      velocity: [0.4614122815, 0.5005207704, 0.5626493256],
      direction: [-1.0516175491, 6.3270815112, 13.4228588708],
      "speed factor": 1.0045786293,
      "Uz factor": 0.9999937738,
      "Directional variance factor": 0.4375927546,
      "Velocity stability factor": 0.8539052931,
      "Direction stability factor": 0.9597931211,
      score: 0.8027710724,
    },
    "270": {
      velocity: [0.4757913353, 0.5191768804, 0.5575218571],
      direction: [-15.8150334456, -8.0939618947, -3.0709112725],
      "speed factor": 0.9852778779,
      "Uz factor": 0.9999142433,
      "Directional variance factor": 0.2805367205,
      "Velocity stability factor": 0.8876063166,
      "Direction stability factor": 0.9645996606,
      score: 0.0,
    },
    "315": {
      velocity: [0.6002524982, 0.8937889204, 1.2001720269],
      direction: [-22.4197055605, 7.9179669511, 34.1700313519],
      "speed factor": 1.4059042574,
      "Uz factor": 0.9954866221,
      "Directional variance factor": 0.2961807155,
      "Velocity stability factor": 0.4890891231,
      "Direction stability factor": 0.8428062864,
      score: 0.6177206028,
    },
  },
  {
    position: [-72.776153, -0.682243, 48.16],
    "overall score": 0.3571066637,
    "0": {
      velocity: [0.0688861388, 0.6599591051, 1.263270234],
      direction: [-54.1803822312, 16.1743546339, 155.6217726074],
      "speed factor": 0.8401952455,
      "Uz factor": 0.9824806372,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2016056824,
      "Direction stability factor": 0.4172162366,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1544719279, 0.2777203826, 0.351874133],
      direction: [-179.9277055029, 176.60092738, 179.8923028864],
      "speed factor": 0.298180726,
      "Uz factor": 0.9999221232,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7240685327,
      "Direction stability factor": 0.0004999767,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2732990923, 0.9779648747, 1.4081949584],
      direction: [-21.6547033742, -9.8990624412, 72.20463836],
      "speed factor": 0.9364409737,
      "Uz factor": 0.9987385163,
      "Directional variance factor": 0.1200833386,
      "Velocity stability factor": 0.1899966987,
      "Direction stability factor": 0.7392796063,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1329511813, 0.83744326, 1.180340364],
      direction: [-152.844834426, 18.8894022901, 59.1790595427],
      "speed factor": 0.9145351897,
      "Uz factor": 0.960208917,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2291562551,
      "Direction stability factor": 0.411044739,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4622015431, 1.1429726632, 1.6751651065],
      direction: [-55.736764616, -28.8463314921, 1.4418052539],
      "speed factor": 1.1656792581,
      "Uz factor": 0.9911046048,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2697543311,
      "Direction stability factor": 0.8411706393,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.686704113, 0.9479723466, 1.264032176],
      direction: [-21.79396415, 0.700282787, 23.1623210661],
      "speed factor": 1.5632454476,
      "Uz factor": 0.9958699687,
      "Directional variance factor": 0.9377526412,
      "Velocity stability factor": 0.5706655315,
      "Direction stability factor": 0.87512143,
      score: 0.8146652581,
    },
    "247.5": {
      velocity: [0.9455316898, 0.9705737452, 0.9941788465],
      direction: [-1.1058861811, 0.8645857388, 2.068202029],
      "speed factor": 1.1710081103,
      "Uz factor": 0.994886739,
      "Directional variance factor": 0.9231479343,
      "Velocity stability factor": 0.9554017048,
      "Direction stability factor": 0.9911830883,
      score: 0.9652289539,
    },
    "292.5": {
      velocity: [1.1499386061, 1.2294238283, 1.2720499656],
      direction: [-6.5808764257, -2.8707276445, -0.8234688551],
      "speed factor": 1.4524193999,
      "Uz factor": 0.972495206,
      "Directional variance factor": 0.7448242094,
      "Velocity stability factor": 0.8882484724,
      "Direction stability factor": 0.9840072012,
      score: 0.900271771,
    },
    "337.5": {
      velocity: [0.14037595, 1.0224474488, 1.3800857942],
      direction: [-15.3499382264, 16.1848266691, 132.3324030285],
      "speed factor": 1.3201377127,
      "Uz factor": 0.9986593497,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2058674441,
      "Direction stability factor": 0.5897712743,
      score: 0.0,
    },
    id: "IDN:4568.0",
    "45": {
      velocity: [0.1438882893, 0.5415771414, 0.9479370032],
      direction: [-59.5806370002, 0.3531526339, 62.1924980293],
      "speed factor": 0.5079500072,
      "Uz factor": 0.9998847814,
      "Directional variance factor": 0.9686086548,
      "Velocity stability factor": 0.3634688526,
      "Direction stability factor": 0.6617412916,
      score: 0.0,
    },
    "90": {
      velocity: [0.1965288738, 0.3793788459, 0.6528977374],
      direction: [-32.3227351977, -8.4416420818, 25.1090951368],
      "speed factor": 0.9718086132,
      "Uz factor": 0.9998596647,
      "Directional variance factor": 0.249631815,
      "Velocity stability factor": 0.3561595838,
      "Direction stability factor": 0.840467138,
      score: 0.0,
    },
    "135": {
      velocity: [0.2407114427, 1.2129776271, 1.8001996872],
      direction: [-19.3646907424, -3.4989026115, 8.8732385333],
      "speed factor": 1.1036615656,
      "Uz factor": 0.9514052957,
      "Directional variance factor": 0.6889864345,
      "Velocity stability factor": 0.10553488,
      "Direction stability factor": 0.9215613076,
      score: 0.6594109824,
    },
    "180": {
      velocity: [0.0379660833, 0.4547505276, 0.9905631102],
      direction: [-144.4951965889, -43.4433074143, 60.4286505288],
      "speed factor": 0.6455503344,
      "Uz factor": 0.9862541237,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2346446369,
      "Direction stability factor": 0.4307670913,
      score: 0.0,
    },
    "225": {
      velocity: [0.4596191616, 0.5050138659, 0.5691600449],
      direction: [0.0907039986, 7.4937440359, 14.2334259431],
      "speed factor": 1.0135965723,
      "Uz factor": 0.9985446402,
      "Directional variance factor": 0.333889419,
      "Velocity stability factor": 0.8419220615,
      "Direction stability factor": 0.9607146613,
      score: 0.7743102008,
    },
    "270": {
      velocity: [0.4931048451, 0.5406925912, 0.5725346981],
      direction: [-12.9303724723, -6.5898953919, -2.6772226675],
      "speed factor": 1.0261097305,
      "Uz factor": 0.9986173771,
      "Directional variance factor": 0.4142315207,
      "Velocity stability factor": 0.8907701364,
      "Direction stability factor": 0.9715190283,
      score: 0.8120099284,
    },
    "315": {
      velocity: [0.641871612, 0.9204239398, 1.2791779126],
      direction: [-17.8004043995, 0.6139183333, 27.4589259163],
      "speed factor": 1.4478003766,
      "Uz factor": 0.9941171123,
      "Directional variance factor": 0.9454294815,
      "Velocity stability factor": 0.4572493387,
      "Direction stability factor": 0.874279638,
      score: 0.787809524,
    },
  },
  {
    position: [-71.276153, -0.682243, 48.16],
    "overall score": 0.3567049482,
    "0": {
      velocity: [0.0628820874, 0.516990547, 1.190767024],
      direction: [-169.2395737123, 15.295257273, 163.9652336448],
      "speed factor": 0.6581816906,
      "Uz factor": 0.9828366632,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2460575053,
      "Direction stability factor": 0.0744310907,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0917331733, 0.2455634514, 0.3533544791],
      direction: [-179.6124520458, 177.9374712162, 179.9897991231],
      "speed factor": 0.2636547146,
      "Uz factor": 0.9997210254,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.634302207,
      "Direction stability factor": 0.0011048579,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1353404127, 0.8368794541, 1.3483070287],
      direction: [-25.5757720868, -10.45407, 84.373214066],
      "speed factor": 0.8013459698,
      "Uz factor": 0.9984732145,
      "Directional variance factor": 0.0707493333,
      "Velocity stability factor": 0.13427567,
      "Direction stability factor": 0.6945861496,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1556149191, 0.8569911189, 1.16198873],
      direction: [-34.4915286891, 20.2720286418, 131.7844629075],
      "speed factor": 0.9358825522,
      "Uz factor": 0.9596141109,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2593422102,
      "Direction stability factor": 0.5381222456,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4633895212, 1.1520904349, 1.6410681413],
      direction: [-56.6454630691, -27.6260606719, 1.4215705441],
      "speed factor": 1.1749781659,
      "Uz factor": 0.9901561417,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2909970772,
      "Direction stability factor": 0.8387026844,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6972231039, 0.9579852432, 1.273045483],
      direction: [-20.2951969593, 1.4762366767, 24.541661186],
      "speed factor": 1.5797571265,
      "Uz factor": 0.9943021959,
      "Directional variance factor": 0.8687789621,
      "Velocity stability factor": 0.5717852449,
      "Direction stability factor": 0.8754531718,
      score: 0.7978676377,
    },
    "247.5": {
      velocity: [0.9429776785, 0.9678377396, 0.9928883713],
      direction: [-2.1209003693, -0.1596341812, 1.1411167033],
      "speed factor": 1.1677070889,
      "Uz factor": 0.997007172,
      "Directional variance factor": 0.985810295,
      "Velocity stability factor": 0.9542433316,
      "Direction stability factor": 0.9909388415,
      score: 0.9804828274,
    },
    "292.5": {
      velocity: [1.1384335994, 1.2316138129, 1.2751807393],
      direction: [-7.5972307029, -4.0562465909, -2.0582351951],
      "speed factor": 1.4550066087,
      "Uz factor": 0.9772814175,
      "Directional variance factor": 0.6394447475,
      "Velocity stability factor": 0.8748543801,
      "Direction stability factor": 0.9846139014,
      score: 0.8708817326,
    },
    "337.5": {
      velocity: [0.1190769521, 0.967886427, 1.3844358805],
      direction: [-168.6879224185, 16.7120540796, 149.8797392191],
      "speed factor": 1.2496909992,
      "Uz factor": 0.9973214323,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1894371698,
      "Direction stability factor": 0.1150898288,
      score: 0.0,
    },
    id: "IDN:4569.0",
    "45": {
      velocity: [0.244648184, 0.735341639, 1.147799354],
      direction: [-16.0537703661, 1.4669710835, 45.7938842259],
      "speed factor": 0.6896834491,
      "Uz factor": 0.9980768921,
      "Directional variance factor": 0.8696025704,
      "Velocity stability factor": 0.2850136558,
      "Direction stability factor": 0.8282009595,
      score: 0.0,
    },
    "90": {
      velocity: [0.195817305, 0.3741777753, 0.6502232471],
      direction: [-32.0161473994, -7.9070136181, 30.6076012368],
      "speed factor": 0.9584856637,
      "Uz factor": 0.9998384194,
      "Directional variance factor": 0.2971543451,
      "Velocity stability factor": 0.3589288529,
      "Direction stability factor": 0.8260451427,
      score: 0.0,
    },
    "135": {
      velocity: [0.3207629861, 1.2178499232, 1.7802881541],
      direction: [-16.9083354754, -2.1863179276, 10.6196584057],
      "speed factor": 1.1080947602,
      "Uz factor": 0.9502426469,
      "Directional variance factor": 0.8056606287,
      "Velocity stability factor": 0.1628700254,
      "Direction stability factor": 0.9235333503,
      score: 0.7038993387,
    },
    "180": {
      velocity: [0.0559142621, 0.4519200599, 0.9497185711],
      direction: [-146.3849426294, -44.2372475037, 51.6033589145],
      "speed factor": 0.6415322865,
      "Uz factor": 0.9808009066,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2818811081,
      "Direction stability factor": 0.4500324957,
      score: 0.0,
    },
    "225": {
      velocity: [0.4581276745, 0.5004398118, 0.5639192519],
      direction: [-0.8700873189, 6.5938210439, 13.6153797961],
      "speed factor": 1.0044161401,
      "Uz factor": 0.9990491753,
      "Directional variance factor": 0.4138825739,
      "Velocity stability factor": 0.8473326675,
      "Direction stability factor": 0.9597625913,
      score: 0.795185106,
    },
    "270": {
      velocity: [0.4887788144, 0.5352143408, 0.5676825959],
      direction: [-14.2865525549, -7.4651348165, -3.1405658931],
      "speed factor": 1.0157132759,
      "Uz factor": 0.9992524384,
      "Directional variance factor": 0.3364324608,
      "Velocity stability factor": 0.8914935762,
      "Direction stability factor": 0.9690389259,
      score: 0.7915009722,
    },
    "315": {
      velocity: [0.6566819457, 0.9155659511, 1.2773967254],
      direction: [-19.091282911, 1.3654485982, 31.3374779137],
      "speed factor": 1.4401589001,
      "Uz factor": 0.9982283107,
      "Directional variance factor": 0.8786267913,
      "Velocity stability factor": 0.4713792146,
      "Direction stability factor": 0.8599201088,
      score: 0.7674615559,
    },
  },
  {
    position: [-65.92847, 3.817757, 48.16],
    "overall score": 0.3538348224,
    "0": {
      velocity: [0.5662055193, 1.083493511, 1.45158705],
      direction: [-10.6586498937, 6.7750198265, 49.6692860555],
      "speed factor": 1.3793977375,
      "Uz factor": 0.934826644,
      "Directional variance factor": 0.3977760154,
      "Velocity stability factor": 0.4081605859,
      "Direction stability factor": 0.8324224001,
      score: 0.0,
    },
    "22.5": {
      velocity: [1.0651384344, 1.1246616928, 1.1782207192],
      direction: [3.212415241, 4.233213102, 5.4070178745],
      "speed factor": 1.2075182848,
      "Uz factor": 0.8918902004,
      "Directional variance factor": 0.6237143909,
      "Velocity stability factor": 0.8419320557,
      "Direction stability factor": 0.9939038816,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.2362536803, 1.3497688204, 1.437447897],
      direction: [-9.4927875929, -5.9508643193, 0.0662228331],
      "speed factor": 1.2924583093,
      "Uz factor": 0.9765182619,
      "Directional variance factor": 0.4710342827,
      "Velocity stability factor": 0.8564027021,
      "Direction stability factor": 0.9734471933,
      score: 0.8185828428,
    },
    "112.5": {
      velocity: [0.0904546043, 0.8521885728, 1.262035065],
      direction: [-27.7676663045, 24.0360025654, 64.5316299987],
      "speed factor": 0.9306379015,
      "Uz factor": 0.9938805559,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1377555882,
      "Direction stability factor": 0.7436130658,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4717548959, 1.0724342031, 1.5933576012],
      direction: [-93.2119161376, -25.0592422747, 7.103036864],
      "speed factor": 1.0937394624,
      "Uz factor": 0.9984425562,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3247567013,
      "Direction stability factor": 0.7213473528,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5283303502, 0.9746096094, 1.308580583],
      direction: [-17.1264254378, 3.7189736991, 27.9774678683],
      "speed factor": 1.6071713912,
      "Uz factor": 0.9976492493,
      "Directional variance factor": 0.6694245601,
      "Velocity stability factor": 0.4197608942,
      "Direction stability factor": 0.8747114075,
      score: 0.7096520673,
    },
    "247.5": {
      velocity: [0.9447950742, 0.9688363283, 0.9929486859],
      direction: [-1.880342429, 0.7116483324, 3.1081709227],
      "speed factor": 1.1689118973,
      "Uz factor": 0.9996095007,
      "Directional variance factor": 0.9367423705,
      "Velocity stability factor": 0.9558541724,
      "Direction stability factor": 0.9861430185,
      score: 0.9662206449,
    },
    "292.5": {
      velocity: [1.0378480797, 1.2301726891, 1.2824206664],
      direction: [-11.0093279247, -5.9727841813, -3.7891084283],
      "speed factor": 1.4533040908,
      "Uz factor": 0.9982722524,
      "Directional variance factor": 0.4690858506,
      "Velocity stability factor": 0.7761767596,
      "Direction stability factor": 0.9799438347,
      score: 0.8012875699,
    },
    "337.5": {
      velocity: [0.6205877265, 1.1215058207, 1.4165780075],
      direction: [-7.3499985553, 10.8689235384, 49.6176588526],
      "speed factor": 1.448037384,
      "Uz factor": 0.9386979208,
      "Directional variance factor": 0.0338734633,
      "Velocity stability factor": 0.4901050441,
      "Direction stability factor": 0.8417565072,
      score: 0.0,
    },
    id: "IDN:4599.0",
    "45": {
      velocity: [1.18396079, 1.269891912, 1.324904968],
      direction: [-4.2857830405, 1.4648690101, 6.756373309],
      "speed factor": 1.1910428941,
      "Uz factor": 0.951587462,
      "Directional variance factor": 0.8697894213,
      "Velocity stability factor": 0.8884204927,
      "Direction stability factor": 0.9693273435,
      score: 0.9242161502,
    },
    "90": {
      velocity: [0.1747959849, 0.3804599545, 0.6188114023],
      direction: [-39.3463806682, -10.5241771137, 18.521612975],
      "speed factor": 0.9745779575,
      "Uz factor": 0.9995395394,
      "Directional variance factor": 0.0645175899,
      "Velocity stability factor": 0.3735876964,
      "Direction stability factor": 0.8392555732,
      score: 0.0,
    },
    "135": {
      velocity: [0.1690557484, 0.9563218549, 1.707250164],
      direction: [-102.1466201942, 4.2953206539, 156.546156047],
      "speed factor": 0.8701361443,
      "Uz factor": 0.9750075244,
      "Directional variance factor": 0.6181937197,
      "Velocity stability factor": 0.1177482374,
      "Direction stability factor": 0.2814089549,
      score: 0.0,
    },
    "180": {
      velocity: [0.0822340102, 0.3493154877, 0.8672602389],
      direction: [-166.163908591, -48.9012166966, 177.9861902896],
      "speed factor": 0.4958778851,
      "Uz factor": 0.9928859637,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3692778611,
      "Direction stability factor": 0.0440275031,
      score: 0.0,
    },
    "225": {
      velocity: [0.4693122971, 0.5106189896, 0.5755324519],
      direction: [-0.2950604813, 7.2279873729, 14.0143986249],
      "speed factor": 1.02484643,
      "Uz factor": 0.9999983882,
      "Directional variance factor": 0.3575122335,
      "Velocity stability factor": 0.8467141895,
      "Direction stability factor": 0.9602515025,
      score: 0.781182357,
    },
    "270": {
      velocity: [0.472940002, 0.518638631, 0.5593431285],
      direction: [-15.1710709981, -7.6691028928, -3.0834773445],
      "speed factor": 0.9842564047,
      "Uz factor": 0.9998263819,
      "Directional variance factor": 0.3183019651,
      "Velocity stability factor": 0.8811806722,
      "Direction stability factor": 0.966423351,
      score: 0.0,
    },
    "315": {
      velocity: [0.6441374178, 0.8892604308, 1.1943686711],
      direction: [-17.2187339316, 6.8544221979, 33.408279485],
      "speed factor": 1.3987810735,
      "Uz factor": 0.9888184771,
      "Directional variance factor": 0.3907180269,
      "Velocity stability factor": 0.5314052657,
      "Direction stability factor": 0.8593694072,
      score: 0.6602155267,
    },
  },
  {
    position: [-64.42847, 2.317757, 48.16],
    "overall score": 0.3515588302,
    "0": {
      velocity: [0.1097209753, 1.021695361, 1.402061011],
      direction: [-106.1719168384, 7.090105585, 51.7402686047],
      "speed factor": 1.3007223902,
      "Uz factor": 0.9591060038,
      "Directional variance factor": 0.3697683924,
      "Velocity stability factor": 0.1361263557,
      "Direction stability factor": 0.5613550404,
      score: 0.4071512073,
    },
    "22.5": {
      velocity: [0.9494928205, 1.0236455475, 1.1053523753],
      direction: [1.0092709033, 2.8781235386, 4.6861121253],
      "speed factor": 1.0990600318,
      "Uz factor": 0.9323608519,
      "Directional variance factor": 0.7441667966,
      "Velocity stability factor": 0.7821374103,
      "Direction stability factor": 0.9897865522,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.2007800509, 1.3338194635, 1.4471772477],
      direction: [-9.4780139023, -6.2552352232, -1.4045066307],
      "speed factor": 1.2771861542,
      "Uz factor": 0.9615025991,
      "Directional variance factor": 0.4439790913,
      "Velocity stability factor": 0.8241402152,
      "Direction stability factor": 0.9775735909,
      score: 0.8058166221,
    },
    "112.5": {
      velocity: [0.2018138786, 0.8950014891, 1.220862576],
      direction: [-57.0506911729, 25.0475288978, 61.6052227088],
      "speed factor": 0.9773920165,
      "Uz factor": 0.9892232739,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2500139136,
      "Direction stability factor": 0.6704002392,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5410998095, 1.1169910101, 1.7034605628],
      direction: [-81.0955610551, -24.5187217192, 2.2452364938],
      "speed factor": 1.1391814467,
      "Uz factor": 0.995520015,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3002189585,
      "Direction stability factor": 0.7684977846,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6830739687, 0.9926607122, 1.336943],
      direction: [-18.9009247005, 6.1555056242, 29.8989168252],
      "speed factor": 1.6369384032,
      "Uz factor": 0.9939269959,
      "Directional variance factor": 0.4528439445,
      "Velocity stability factor": 0.5137452498,
      "Direction stability factor": 0.8644448847,
      score: 0.6738697409,
    },
    "247.5": {
      velocity: [0.9356712615, 0.9635915809, 0.9923329511],
      direction: [-3.3740250628, -1.308061849, 0.7377305114],
      "speed factor": 1.1625840508,
      "Uz factor": 0.999717739,
      "Directional variance factor": 0.8837278356,
      "Velocity stability factor": 0.9480542145,
      "Direction stability factor": 0.9885784567,
      score: 0.9522347409,
    },
    "292.5": {
      velocity: [0.7522394861, 1.1525492614, 1.2438494469],
      direction: [-10.9615921642, -5.6549216202, -3.4354668999],
      "speed factor": 1.3616011566,
      "Uz factor": 0.9990448427,
      "Directional variance factor": 0.4973403004,
      "Velocity stability factor": 0.5500978424,
      "Direction stability factor": 0.9790940965,
      score: 0.7514065839,
    },
    "337.5": {
      velocity: [0.2337868482, 1.0151404744, 1.3384490319],
      direction: [-176.1496296409, 10.1127111311, 53.6145736884],
      "speed factor": 1.3107032793,
      "Uz factor": 0.962298508,
      "Directional variance factor": 0.1010923439,
      "Velocity stability factor": 0.2923761899,
      "Direction stability factor": 0.3617661019,
      score: 0.2792501844,
    },
    id: "IDN:4596.0",
    "45": {
      velocity: [1.126063872, 1.231867504, 1.326813091],
      direction: [-0.7989398299, 6.139601905, 12.7541409918],
      "speed factor": 1.1553794644,
      "Uz factor": 0.9550967222,
      "Directional variance factor": 0.4542576084,
      "Velocity stability factor": 0.841075387,
      "Direction stability factor": 0.9623525533,
      score: 0.8050095255,
    },
    "90": {
      velocity: [0.1850710411, 0.3731471548, 0.6269499834],
      direction: [-32.3623041712, -8.7074182851, 22.8066451558],
      "speed factor": 0.9558456486,
      "Uz factor": 0.9989668958,
      "Directional variance factor": 0.2260072635,
      "Velocity stability factor": 0.3766018131,
      "Direction stability factor": 0.8467529185,
      score: 0.0,
    },
    "135": {
      velocity: [0.264233861, 1.131279851, 1.6996957004],
      direction: [-67.9429735756, 5.9123009957, 156.2560455231],
      "speed factor": 1.0293265627,
      "Uz factor": 0.9670316538,
      "Directional variance factor": 0.4744621337,
      "Velocity stability factor": 0.176671866,
      "Direction stability factor": 0.3772249469,
      score: 0.3513959734,
    },
    "180": {
      velocity: [0.0593674941, 0.371960178, 1.0227992186],
      direction: [-172.5598995363, -51.6953743974, 147.2068244834],
      "speed factor": 0.5280236145,
      "Uz factor": 0.9843866622,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2259395984,
      "Direction stability factor": 0.1117590999,
      score: 0.0,
    },
    "225": {
      velocity: [0.4596392265, 0.4976508754, 0.557091874],
      direction: [-1.3030156364, 6.004089594, 13.2146307858],
      "speed factor": 0.9988185583,
      "Uz factor": 0.9999912391,
      "Directional variance factor": 0.4663031472,
      "Velocity stability factor": 0.8593665383,
      "Direction stability factor": 0.9596732044,
      score: 0.0,
    },
    "270": {
      velocity: [0.4763716273, 0.5184733561, 0.5561980165],
      direction: [-16.6989359956, -8.6102924851, -3.0965385873],
      "speed factor": 0.9839427512,
      "Uz factor": 0.9999238274,
      "Directional variance factor": 0.234640668,
      "Velocity stability factor": 0.8902248299,
      "Direction stability factor": 0.9622155628,
      score: 0.0,
    },
    "315": {
      velocity: [0.5053567677, 0.8838070657, 1.2077896716],
      direction: [-22.2915112668, 7.9131762767, 32.2782159644],
      "speed factor": 1.3902030871,
      "Uz factor": 0.9947993726,
      "Directional variance factor": 0.2966065532,
      "Velocity stability factor": 0.4017854166,
      "Direction stability factor": 0.8484174244,
      score: 0.5988067046,
    },
  },
  {
    position: [-74.276153, 5.317757, 48.16],
    "overall score": 0.3507075095,
    "0": {
      velocity: [0.6984656969, 1.091855962, 1.356231091],
      direction: [4.9263092742, 20.1092278469, 52.3904927348],
      "speed factor": 1.3900439905,
      "Uz factor": 0.9523376386,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5603121683,
      "Direction stability factor": 0.8681550459,
      score: 0.0,
    },
    "22.5": {
      velocity: [1.248776509, 1.2766770113, 1.3063348996],
      direction: [16.6014925876, 17.5899067457, 18.7106371827],
      "speed factor": 1.3707329456,
      "Uz factor": 0.8917101422,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9195441046,
      "Direction stability factor": 0.994141265,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.1015298241, 1.2509990895, 1.3707108981],
      direction: [-11.9941714952, -5.4594911517, 0.0526617838],
      "speed factor": 1.1978822919,
      "Uz factor": 0.9557469317,
      "Directional variance factor": 0.5147118976,
      "Velocity stability factor": 0.8078787975,
      "Direction stability factor": 0.9665365742,
      score: 0.8139159609,
    },
    "112.5": {
      velocity: [0.0828631971, 0.5829139699, 1.067283653],
      direction: [-155.2978927471, 19.0040199316, 179.2756632683],
      "speed factor": 0.6365748744,
      "Uz factor": 0.9968929042,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2754991523,
      "Direction stability factor": 0.0706290111,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2948823919, 1.0059632474, 1.6559602495],
      direction: [-90.367697623, -26.9582568094, 3.5119041923],
      "speed factor": 1.0259479773,
      "Uz factor": 0.9994365702,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1805844458,
      "Direction stability factor": 0.7392233283,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6194508953, 0.9378760345, 1.280035859],
      direction: [-25.786769764, 0.3413683093, 23.9242072938],
      "speed factor": 1.5465962131,
      "Uz factor": 0.9999612391,
      "Directional variance factor": 0.9696561503,
      "Velocity stability factor": 0.5087508949,
      "Direction stability factor": 0.8619139526,
      score: 0.8005587376,
    },
    "247.5": {
      velocity: [0.982705372, 1.0070450783, 1.0277805457],
      direction: [-2.2634054363, -0.9046952619, -0.2018137435],
      "speed factor": 1.2150111828,
      "Uz factor": 0.9860116877,
      "Directional variance factor": 0.9195826434,
      "Velocity stability factor": 0.9586763946,
      "Direction stability factor": 0.9942733564,
      score: 0.9667014377,
    },
    "292.5": {
      velocity: [1.1264578541, 1.1822649414, 1.2144657422],
      direction: [-12.596881932, -9.2414188489, -7.601297102],
      "speed factor": 1.3967067314,
      "Uz factor": 0.9811526876,
      "Directional variance factor": 0.1785405468,
      "Velocity stability factor": 0.919458632,
      "Direction stability factor": 0.9861233755,
      score: 0.7675614824,
    },
    "337.5": {
      velocity: [0.6625379796, 1.0477247561, 1.374500827],
      direction: [-10.3405439298, 18.1076657545, 42.5830181969],
      "speed factor": 1.352774624,
      "Uz factor": 0.9659981263,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5439312849,
      "Direction stability factor": 0.8529901052,
      score: 0.0,
    },
    id: "IDN:4583.0",
    "45": {
      velocity: [1.043818147, 1.244400747, 1.350362248],
      direction: [5.3500339038, 8.7759618234, 18.5730123126],
      "speed factor": 1.1671345042,
      "Uz factor": 0.9240340186,
      "Directional variance factor": 0.2199145046,
      "Velocity stability factor": 0.7573220814,
      "Direction stability factor": 0.9632695044,
      score: 0.0,
    },
    "90": {
      velocity: [0.2131205092, 0.3915532228, 0.6206378715],
      direction: [-43.7992395452, -13.3893757446, 18.2660299366],
      "speed factor": 1.0029942327,
      "Uz factor": 0.9990577465,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4250787705,
      "Direction stability factor": 0.8275964737,
      score: 0.0,
    },
    "135": {
      velocity: [0.0249094805, 0.4175191492, 1.4473813793],
      direction: [-169.4366529273, -4.6111602815, 171.2632774319],
      "speed factor": 0.3798914568,
      "Uz factor": 0.9898693525,
      "Directional variance factor": 0.5901190861,
      "Velocity stability factor": 0.1841224183,
      "Direction stability factor": 0.0536113046,
      score: 0.0,
    },
    "180": {
      velocity: [0.0281221405, 0.3606025006, 0.9160093377],
      direction: [-177.1580184699, -29.5830246414, 179.8982461022],
      "speed factor": 0.5119005932,
      "Uz factor": 0.9998973897,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2866351574,
      "Direction stability factor": 0.0081770429,
      score: 0.0,
    },
    "225": {
      velocity: [0.4783959454, 0.5308571049, 0.5993896236],
      direction: [4.1054294626, 10.5654910901, 15.2718824952],
      "speed factor": 1.0654656797,
      "Uz factor": 0.9959301124,
      "Directional variance factor": 0.0608452364,
      "Velocity stability factor": 0.8253945866,
      "Direction stability factor": 0.9689820749,
      score: 0.7060509932,
    },
    "270": {
      velocity: [0.4979785195, 0.5378651738, 0.5759903088],
      direction: [-12.1535060343, -7.4313907634, -4.7473923037],
      "speed factor": 1.0207439449,
      "Uz factor": 0.9972830912,
      "Directional variance factor": 0.3394319321,
      "Velocity stability factor": 0.8927202205,
      "Direction stability factor": 0.9794274619,
      score: 0.7977517691,
    },
    "315": {
      velocity: [0.6084773077, 0.8584028965, 1.162650858],
      direction: [-19.6690491768, 2.2987355812, 32.2783642484],
      "speed factor": 1.3502430598,
      "Uz factor": 0.9838133939,
      "Directional variance factor": 0.7956679483,
      "Velocity stability factor": 0.528047878,
      "Direction stability factor": 0.8557016294,
      score: 0.7587797713,
    },
  },
  {
    position: [-74.276153, -0.682243, 48.16],
    "overall score": 0.3506186535,
    "0": {
      velocity: [0.0650937164, 0.7764000327, 1.350086231],
      direction: [-15.8328286108, 16.8470988273, 135.3552221334],
      "speed factor": 0.9884364213,
      "Uz factor": 0.9850246407,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1410378571,
      "Direction stability factor": 0.5800331924,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0392965238, 0.1859603963, 0.330598054],
      direction: [-179.2838187729, 164.8922098228, 179.9785477534],
      "speed factor": 0.1996605559,
      "Uz factor": 0.999836422,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5928147885,
      "Direction stability factor": 0.0020489819,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4500733334, 1.0209082158, 1.3585669814],
      direction: [-26.7255749509, -8.8558683113, 33.0553454164],
      "speed factor": 0.977560962,
      "Uz factor": 0.9985015531,
      "Directional variance factor": 0.2128117057,
      "Velocity stability factor": 0.3515855719,
      "Direction stability factor": 0.8339418879,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0732109826, 0.8224756633, 1.177743624],
      direction: [-53.5295891993, 17.5146593612, 92.532062803],
      "speed factor": 0.8981897314,
      "Uz factor": 0.9610328127,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1871005623,
      "Direction stability factor": 0.5942731889,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4655300034, 1.1264349018, 1.6715699309],
      direction: [-70.7690843523, -30.2790161564, -1.6805997451],
      "speed factor": 1.1488129532,
      "Uz factor": 0.9924981141,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2739225975,
      "Direction stability factor": 0.8080875428,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6647632197, 0.9361099985, 1.254175963],
      direction: [-22.7628566344, 0.0713304386, 21.8671914852],
      "speed factor": 1.5436839469,
      "Uz factor": 0.9974308711,
      "Directional variance factor": 0.9936595166,
      "Velocity stability factor": 0.5616786657,
      "Direction stability factor": 0.8760276441,
      score: 0.8268483676,
    },
    "247.5": {
      velocity: [0.9469115426, 0.9712955724, 0.9925739753],
      direction: [-0.5537536839, 1.2163557684, 2.3319466753],
      "speed factor": 1.171879003,
      "Uz factor": 0.9910872693,
      "Directional variance factor": 0.8918794873,
      "Velocity stability factor": 0.9581380127,
      "Direction stability factor": 0.9919841657,
      score: 0.9584964578,
    },
    "292.5": {
      velocity: [1.1243899485, 1.1971989558, 1.2410469162],
      direction: [-6.2351729457, -2.2788180262, 0.004381407],
      "speed factor": 1.4143495098,
      "Uz factor": 0.9685054614,
      "Directional variance factor": 0.7974383977,
      "Velocity stability factor": 0.8932401178,
      "Direction stability factor": 0.9826679046,
      score: 0.9140035812,
    },
    "337.5": {
      velocity: [0.250752804, 1.055997616, 1.3483312003],
      direction: [-17.5982627898, 15.0198117175, 82.0061856225],
      "speed factor": 1.3634561651,
      "Uz factor": 0.9987413609,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.296913918,
      "Direction stability factor": 0.7233209766,
      score: 0.0,
    },
    id: "IDN:4567.0",
    "45": {
      velocity: [0.0660247241, 0.3663591541, 0.7296986292],
      direction: [-155.2570905931, 1.5930238229, 125.9168613885],
      "speed factor": 0.3436115019,
      "Uz factor": 0.999742689,
      "Directional variance factor": 0.8583978824,
      "Velocity stability factor": 0.4745976146,
      "Direction stability factor": 0.2189612445,
      score: 0.0,
    },
    "90": {
      velocity: [0.1988629754, 0.3872183595, 0.6578554611],
      direction: [-32.3132875848, -8.9226206628, 24.0618825086],
      "speed factor": 0.991890141,
      "Uz factor": 0.9998912584,
      "Directional variance factor": 0.2068781633,
      "Velocity stability factor": 0.3524582052,
      "Direction stability factor": 0.8434023053,
      score: 0.0,
    },
    "135": {
      velocity: [0.2191104652, 1.1937920579, 1.8046522389],
      direction: [-92.0797342703, -4.5344488164, 7.862886682],
      "speed factor": 1.0862050397,
      "Uz factor": 0.9526085578,
      "Directional variance factor": 0.596937883,
      "Velocity stability factor": 0.0905915336,
      "Direction stability factor": 0.7223816085,
      score: 0.5330731584,
    },
    "180": {
      velocity: [0.0441091159, 0.448591759, 0.8806478157],
      direction: [-139.9757424891, -41.9409646913, 75.6025567962],
      "speed factor": 0.6368075294,
      "Uz factor": 0.989694481,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3278906377,
      "Direction stability factor": 0.4011713909,
      score: 0.0,
    },
    "225": {
      velocity: [0.4606223395, 0.5085825941, 0.5721168729],
      direction: [1.1551538397, 8.3015196765, 14.6544175456],
      "speed factor": 1.0207592482,
      "Uz factor": 0.9968929222,
      "Directional variance factor": 0.2620871399,
      "Velocity stability factor": 0.8391027581,
      "Direction stability factor": 0.9625020453,
      score: 0.7565484971,
    },
    "270": {
      velocity: [0.4955191333, 0.5436890133, 0.5751134104],
      direction: [-11.2503596183, -5.4813272064, -2.0225346785],
      "speed factor": 1.0317962481,
      "Uz factor": 0.9972344484,
      "Directional variance factor": 0.512770915,
      "Velocity stability factor": 0.8905440245,
      "Direction stability factor": 0.9743671529,
      score: 0.8380123114,
    },
    "315": {
      velocity: [0.6287721016, 0.9101756969, 1.2522473644],
      direction: [-15.6930182023, 1.2954623524, 24.3050988287],
      "speed factor": 1.4316801853,
      "Uz factor": 0.9871349123,
      "Directional variance factor": 0.8848477909,
      "Velocity stability factor": 0.4690282979,
      "Direction stability factor": 0.8888941194,
      score: 0.7829160819,
    },
  },
  {
    position: [-72.776153, 5.317757, 48.16],
    "overall score": 0.3486142568,
    "0": {
      velocity: [0.6556309514, 1.081178099, 1.346810118],
      direction: [4.7164267912, 19.1498636129, 47.3985415807],
      "speed factor": 1.3764499819,
      "Uz factor": 0.9398072055,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5379765008,
      "Direction stability factor": 0.88143857,
      score: 0.0,
    },
    "22.5": {
      velocity: [1.2474063711, 1.2785244343, 1.314207316],
      direction: [14.5163412917, 15.7734752978, 16.958951942],
      "speed factor": 1.3727164728,
      "Uz factor": 0.8863644946,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9066247374,
      "Direction stability factor": 0.9932149704,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.1508464959, 1.277667059, 1.3787998182],
      direction: [-10.1695694502, -6.30275609, -3.4964933972],
      "speed factor": 1.2234179527,
      "Uz factor": 0.9561006778,
      "Directional variance factor": 0.4397550142,
      "Velocity stability factor": 0.8373040655,
      "Direction stability factor": 0.9814636776,
      score: 0.8099966087,
    },
    "112.5": {
      velocity: [0.0971021066, 0.6465342926, 1.085922056],
      direction: [-174.711438439, 19.6682884316, 119.1869444392],
      "speed factor": 0.7060518487,
      "Uz factor": 0.9964380899,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2722612708,
      "Direction stability factor": 0.1836156031,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2795455552, 1.025074088, 1.5391725978],
      direction: [-118.2741219772, -26.1874872861, 3.0294737528],
      "speed factor": 1.0454384789,
      "Uz factor": 0.9996205437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2416613162,
      "Direction stability factor": 0.6630455674,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6162002882, 0.9476476671, 1.328655047],
      direction: [-24.9855400647, 0.7589630111, 24.058116994],
      "speed factor": 1.5627100377,
      "Uz factor": 0.9999494,
      "Directional variance factor": 0.9325366212,
      "Velocity stability factor": 0.4701775216,
      "Direction stability factor": 0.8637676193,
      score: 0.7825623453,
    },
    "247.5": {
      velocity: [0.9809976971, 1.0026429242, 1.0245138008],
      direction: [-1.9213992015, -0.3060414871, 0.5903647178],
      "speed factor": 1.2096999346,
      "Uz factor": 0.9896617852,
      "Directional variance factor": 0.9727963123,
      "Velocity stability factor": 0.9601057046,
      "Direction stability factor": 0.993022878,
      score: 0.9797369432,
    },
    "292.5": {
      velocity: [1.152950019, 1.2085213687, 1.2405499632],
      direction: [-12.3496698093, -8.8338083569, -7.1362293437],
      "speed factor": 1.4277256067,
      "Uz factor": 0.9865035637,
      "Directional variance factor": 0.2147725905,
      "Velocity stability factor": 0.9198319663,
      "Direction stability factor": 0.9855182209,
      score: 0.7764102497,
    },
    "337.5": {
      velocity: [0.7022800652, 1.0663101717, 1.3975982123],
      direction: [-6.3334015178, 17.8652882445, 43.0265619756],
      "speed factor": 1.3767712687,
      "Uz factor": 0.955921168,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5545935366,
      "Direction stability factor": 0.8628889903,
      score: 0.0,
    },
    id: "IDN:4584.0",
    "45": {
      velocity: [1.022516959, 1.244196165, 1.322455495],
      direction: [4.6317800616, 7.7855354706, 14.0837652755],
      "speed factor": 1.1669426249,
      "Uz factor": 0.9213427312,
      "Directional variance factor": 0.3079524026,
      "Velocity stability factor": 0.7625514261,
      "Direction stability factor": 0.9737444855,
      score: 0.0,
    },
    "90": {
      velocity: [0.2237776212, 0.3894541421, 0.6181271407],
      direction: [-44.6547823695, -13.4574456881, 17.7830261247],
      "speed factor": 0.9976172732,
      "Uz factor": 0.999092227,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4436558253,
      "Direction stability factor": 0.8265616431,
      score: 0.0,
    },
    "135": {
      velocity: [0.057959579, 0.4702570474, 1.4225768626],
      direction: [-159.6364106995, -3.3674762206, 163.7386435395],
      "speed factor": 0.4278765062,
      "Uz factor": 0.9898181786,
      "Directional variance factor": 0.7006687804,
      "Velocity stability factor": 0.2173056985,
      "Direction stability factor": 0.1017359604,
      score: 0.0,
    },
    "180": {
      velocity: [0.0588103139, 0.3550872419, 0.8645596341],
      direction: [-178.8941237844, -31.8312023928, 173.7163119582],
      "speed factor": 0.5040712958,
      "Uz factor": 0.9995413554,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3526280829,
      "Direction stability factor": 0.0205265674,
      score: 0.0,
    },
    "225": {
      velocity: [0.4737286175, 0.5249008413, 0.5948472826],
      direction: [3.8250280427, 10.9151683418, 16.0057422525],
      "speed factor": 1.0535110608,
      "Uz factor": 0.9985828018,
      "Directional variance factor": 0.0297628141,
      "Velocity stability factor": 0.8252142186,
      "Direction stability factor": 0.9661646828,
      score: 0.6968265995,
    },
    "270": {
      velocity: [0.492786921, 0.5336471583, 0.5726423339],
      direction: [-12.1877300556, -7.1925381012, -4.3695115581],
      "speed factor": 1.0127391251,
      "Uz factor": 0.9982008531,
      "Directional variance factor": 0.3606632799,
      "Velocity stability factor": 0.8901849173,
      "Direction stability factor": 0.9782827264,
      score: 0.8018534125,
    },
    "315": {
      velocity: [0.6148408907, 0.866371429, 1.176022047],
      direction: [-19.7535590181, 3.4631894187, 32.8916021184],
      "speed factor": 1.3627773322,
      "Uz factor": 0.9858537508,
      "Directional variance factor": 0.6921609406,
      "Velocity stability factor": 0.5220799741,
      "Direction stability factor": 0.8537634413,
      score: 0.7304419493,
    },
  },
  {
    position: [-71.276153, 5.317757, 48.16],
    "overall score": 0.345850274,
    "0": {
      velocity: [0.6494628643, 1.077887831, 1.350039418],
      direction: [2.9264106946, 18.1879370986, 41.4551621531],
      "speed factor": 1.3722611352,
      "Uz factor": 0.926529455,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5316947523,
      "Direction stability factor": 0.8929756904,
      score: 0.0,
    },
    "22.5": {
      velocity: [1.2534944871, 1.2746203947, 1.3017095364],
      direction: [14.0969152786, 15.7415688599, 16.8354206246],
      "speed factor": 1.368524813,
      "Uz factor": 0.8794316422,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9326043532,
      "Direction stability factor": 0.9923930407,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.1744810175, 1.2981466933, 1.374038146],
      direction: [-9.8581834854, -7.4406416218, -4.0623414125],
      "speed factor": 1.243028032,
      "Uz factor": 0.9583695261,
      "Directional variance factor": 0.3386096336,
      "Velocity stability factor": 0.8575711325,
      "Direction stability factor": 0.9839004387,
      score: 0.7909954109,
    },
    "112.5": {
      velocity: [0.145818926, 0.6991446389, 1.130040385],
      direction: [-177.2737470495, 20.2539646714, 139.9010152011],
      "speed factor": 0.763505309,
      "Uz factor": 0.9962208682,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2756456074,
      "Direction stability factor": 0.1189589937,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2092254931, 1.0348703077, 1.620516634],
      direction: [-75.9796102515, -25.4523224906, 7.3043691784],
      "speed factor": 1.0554293129,
      "Uz factor": 0.9999304896,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.150354327,
      "Direction stability factor": 0.7686556127,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6159667206, 0.9530879395, 1.299410502],
      direction: [-24.1756602826, 1.1163110347, 23.8239509879],
      "speed factor": 1.571681271,
      "Uz factor": 0.9999756086,
      "Directional variance factor": 0.9007723525,
      "Velocity stability factor": 0.4917517587,
      "Direction stability factor": 0.8666677465,
      score: 0.781464901,
    },
    "247.5": {
      velocity: [0.9814344286, 1.0068940034, 1.033580599],
      direction: [-1.2687668482, 0.6436630301, 1.9893674368],
      "speed factor": 1.2148289092,
      "Uz factor": 0.9928877161,
      "Directional variance factor": 0.9427855084,
      "Velocity stability factor": 0.9521939109,
      "Direction stability factor": 0.990949627,
      score: 0.9692196683,
    },
    "292.5": {
      velocity: [1.1744452079, 1.2321581927, 1.2662262116],
      direction: [-12.5524890401, -8.5438585778, -6.7266495985],
      "speed factor": 1.4556497292,
      "Uz factor": 0.9907245648,
      "Directional variance factor": 0.2405459042,
      "Velocity stability factor": 0.9160056246,
      "Direction stability factor": 0.9838171127,
      score: 0.7810464385,
    },
    "337.5": {
      velocity: [0.7322592184, 1.0766028711, 1.4104578313],
      direction: [-4.6752653109, 17.1375484786, 46.3715590574],
      "speed factor": 1.3900607346,
      "Uz factor": 0.9452635513,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5655599572,
      "Direction stability factor": 0.8582032656,
      score: 0.0,
    },
    id: "IDN:4585.0",
    "45": {
      velocity: [1.044685468, 1.227936021, 1.308780582],
      direction: [3.3868723487, 6.9468195181, 9.4530250945],
      "speed factor": 1.1516920916,
      "Uz factor": 0.9152150174,
      "Directional variance factor": 0.3825049317,
      "Velocity stability factor": 0.7909271378,
      "Direction stability factor": 0.9831495757,
      score: 0.0,
    },
    "90": {
      velocity: [0.2296299337, 0.3891971592, 0.617238575],
      direction: [-45.0556607741, -13.4590699856, 16.6432842645],
      "speed factor": 0.9969589913,
      "Uz factor": 0.9991132088,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4531657857,
      "Direction stability factor": 0.8286140416,
      score: 0.0,
    },
    "135": {
      velocity: [0.05600208, 0.535120749, 1.3588142445],
      direction: [-127.9278298499, -1.0647115717, 175.8536928376],
      "speed factor": 0.4868945563,
      "Uz factor": 0.9871779044,
      "Directional variance factor": 0.9053589714,
      "Velocity stability factor": 0.2527548424,
      "Direction stability factor": 0.156162437,
      score: 0.0,
    },
    "180": {
      velocity: [0.0459833282, 0.3455357265, 0.8620684229],
      direction: [-173.0851385869, -35.4318608294, 169.8329669952],
      "speed factor": 0.4905122484,
      "Uz factor": 0.9947409212,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3443238995,
      "Direction stability factor": 0.0474497067,
      score: 0.0,
    },
    "225": {
      velocity: [0.4746470006, 0.5243613921, 0.5938365085],
      direction: [3.1391855884, 10.7024556842, 16.1612127951],
      "speed factor": 1.0524283501,
      "Uz factor": 0.9995963364,
      "Directional variance factor": 0.0486706058,
      "Velocity stability factor": 0.8279981764,
      "Direction stability factor": 0.9638277022,
      score: 0.7010810467,
    },
    "270": {
      velocity: [0.4875501327, 0.5291004103, 0.5689419548],
      direction: [-12.4245303769, -7.1120873141, -4.1002028276],
      "speed factor": 1.004110447,
      "Uz factor": 0.9987019849,
      "Directional variance factor": 0.367814461,
      "Velocity stability factor": 0.8880720875,
      "Direction stability factor": 0.9768768679,
      score: 0.8024100711,
    },
    "315": {
      velocity: [0.6228181347, 0.8691694287, 1.1837884383],
      direction: [-19.300139339, 4.4910538884, 33.5311865336],
      "speed factor": 1.3671785052,
      "Uz factor": 0.985668061,
      "Directional variance factor": 0.6007952099,
      "Velocity stability factor": 0.5222595431,
      "Direction stability factor": 0.853246317,
      score: 0.7073868468,
    },
  },
  {
    position: [-74.276153, 3.817757, 48.16],
    "overall score": 0.3446766066,
    "0": {
      velocity: [0.7041737818, 1.121917048, 1.433346204],
      direction: [4.5927931665, 19.5027506041, 55.181503513],
      "speed factor": 1.4283148187,
      "Uz factor": 0.9573827141,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.512579646,
      "Direction stability factor": 0.8594758046,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.9813317142, 1.1102183376, 1.184931222],
      direction: [11.2180180107, 13.3397546912, 15.311465652],
      "speed factor": 1.1920108521,
      "Uz factor": 0.8923188885,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7154058594,
      "Direction stability factor": 0.9886293121,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7728613535, 1.0733820549, 1.2977416008],
      direction: [-21.1424612981, -9.9441130099, -1.6335152778],
      "speed factor": 1.027806788,
      "Uz factor": 0.9612698466,
      "Directional variance factor": 0.1160788436,
      "Velocity stability factor": 0.6253799615,
      "Direction stability factor": 0.9458084833,
      score: 0.6582689429,
    },
    "112.5": {
      velocity: [0.111075774, 0.5829788997, 1.106135959],
      direction: [-135.4807560215, 20.7049802681, 77.4143394884],
      "speed factor": 0.6366457814,
      "Uz factor": 0.9918602362,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2676686641,
      "Direction stability factor": 0.4086247347,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4327014142, 1.0248630136, 1.5737223506],
      direction: [-96.8024931906, -26.3739684934, 9.5495884619],
      "speed factor": 1.0452232112,
      "Uz factor": 0.9999032042,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3130662602,
      "Direction stability factor": 0.704577551,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6082371454, 0.9410626964, 1.264083106],
      direction: [-25.442702082, 0.3082299714, 23.3528063686],
      "speed factor": 1.5518511498,
      "Uz factor": 0.9999939908,
      "Directional variance factor": 0.9726017803,
      "Velocity stability factor": 0.5122750911,
      "Direction stability factor": 0.864456921,
      score: 0.8034476783,
    },
    "247.5": {
      velocity: [0.9733171053, 0.9948826943, 1.0151344482],
      direction: [-1.1197959033, 0.4796392085, 1.3295495949],
      "speed factor": 1.2003371301,
      "Uz factor": 0.9856741274,
      "Directional variance factor": 0.9573654037,
      "Velocity stability factor": 0.9616630789,
      "Direction stability factor": 0.9931962625,
      score: 0.9763552519,
    },
    "292.5": {
      velocity: [1.1289918891, 1.1882516074, 1.2224059444],
      direction: [-11.6700049137, -8.3247863763, -6.6815027767],
      "speed factor": 1.4037792719,
      "Uz factor": 0.9745035585,
      "Directional variance factor": 0.2600189888,
      "Velocity stability factor": 0.9145111199,
      "Direction stability factor": 0.9861430496,
      score: 0.786704052,
    },
    "337.5": {
      velocity: [0.6620957564, 1.0910203926, 1.4659528225],
      direction: [-12.6348203798, 18.2209630767, 48.1079586244],
      "speed factor": 1.4086759837,
      "Uz factor": 0.9792433214,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4850657439,
      "Direction stability factor": 0.8312700583,
      score: 0.0,
    },
    id: "IDN:4579.0",
    "45": {
      velocity: [0.5121777526, 0.9969925564, 1.262255629],
      direction: [-6.3899172886, 2.5569595762, 63.9478408888],
      "speed factor": 0.9350881666,
      "Uz factor": 0.9158287867,
      "Directional variance factor": 0.7727147043,
      "Velocity stability factor": 0.4061952677,
      "Direction stability factor": 0.8046173384,
      score: 0.0,
    },
    "90": {
      velocity: [0.2028183067, 0.3886241629, 0.6313871236],
      direction: [-42.836714898, -13.3755656054, 21.7773255961],
      "speed factor": 0.9954912165,
      "Uz factor": 0.9999135631,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3953795987,
      "Direction stability factor": 0.8205165542,
      score: 0.0,
    },
    "135": {
      velocity: [0.0516135546, 0.5144480366, 1.4968487293],
      direction: [-178.1342169131, -2.0934921163, 171.6652521873],
      "speed factor": 0.4680849117,
      "Uz factor": 0.9788809644,
      "Directional variance factor": 0.8139118119,
      "Velocity stability factor": 0.1710662402,
      "Direction stability factor": 0.0283348081,
      score: 0.0,
    },
    "180": {
      velocity: [0.0223449986, 0.3822795871, 0.9519343947],
      direction: [-176.7401438957, -32.9234140893, 159.5273118211],
      "speed factor": 0.5426727409,
      "Uz factor": 0.9994434589,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2531299074,
      "Direction stability factor": 0.0659237341,
      score: 0.0,
    },
    "225": {
      velocity: [0.4782896105, 0.5296475764, 0.5969856061],
      direction: [3.7723591672, 10.7130039051, 15.7458080934],
      "speed factor": 1.0630380752,
      "Uz factor": 0.9969623949,
      "Directional variance factor": 0.0477329862,
      "Velocity stability factor": 0.8287103617,
      "Direction stability factor": 0.9667404196,
      score: 0.7024810468,
    },
    "270": {
      velocity: [0.497134375, 0.5400830317, 0.5776915113],
      direction: [-11.5276595176, -6.6925284697, -4.08511924],
      "speed factor": 1.0249529274,
      "Uz factor": 0.9969115067,
      "Directional variance factor": 0.4051085805,
      "Velocity stability factor": 0.8892199256,
      "Direction stability factor": 0.979326277,
      score: 0.813245265,
    },
    "315": {
      velocity: [0.6250254629, 0.8772806562, 1.1795276709],
      direction: [-19.4427220852, 1.7308921393, 30.3483432151],
      "speed factor": 1.3799372327,
      "Uz factor": 0.9872264748,
      "Directional variance factor": 0.846142921,
      "Velocity stability factor": 0.5277679826,
      "Direction stability factor": 0.8616914853,
      score: 0.7743234685,
    },
  },
  {
    position: [-67.62847, 2.917754, 48.16],
    "overall score": 0.3427402612,
    "0": {
      velocity: [0.1622962776, 0.9202155852, 1.314434594],
      direction: [-10.8575275012, 8.9042330329, 47.4801109126],
      "speed factor": 1.1715282864,
      "Uz factor": 0.933100651,
      "Directional variance factor": 0.2085126193,
      "Velocity stability factor": 0.2298451657,
      "Direction stability factor": 0.8379510044,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7720385618, 0.9078107466, 0.9904244807],
      direction: [-2.8672065013, 0.530396045, 3.4573024327],
      "speed factor": 0.9746913963,
      "Uz factor": 0.9039165341,
      "Directional variance factor": 0.9528536849,
      "Velocity stability factor": 0.6947372143,
      "Direction stability factor": 0.9824319196,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.1029130852, 1.3256914984, 1.4322655617],
      direction: [-12.8876366532, -8.3019973788, 0.9578797703],
      "speed factor": 1.2694032984,
      "Uz factor": 0.9852524445,
      "Directional variance factor": 0.2620446774,
      "Velocity stability factor": 0.7649329765,
      "Direction stability factor": 0.9615402322,
      score: 0.7375145296,
    },
    "112.5": {
      velocity: [0.1601334901, 0.8395905772, 1.187545121],
      direction: [-25.1425732879, 24.5580850631, 74.5742087107],
      "speed factor": 0.916880181,
      "Uz factor": 0.9899282931,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2438590715,
      "Direction stability factor": 0.7230089389,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4282822613, 1.104056258, 1.6762225161],
      direction: [-85.0328975371, -24.8319184962, 3.8988100499],
      "speed factor": 1.1259897294,
      "Uz factor": 0.9985058388,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2486971633,
      "Direction stability factor": 0.7529674789,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6096741311, 0.9833657376, 1.337557202],
      direction: [-18.3173054841, 2.4135111542, 27.0986599222],
      "speed factor": 1.6216106072,
      "Uz factor": 0.9974240145,
      "Directional variance factor": 0.7854656752,
      "Velocity stability factor": 0.4587041382,
      "Direction stability factor": 0.8738445405,
      score: 0.7479647236,
    },
    "247.5": {
      velocity: [0.9471484727, 0.9713630298, 0.9958532708],
      direction: [-1.6301770422, 0.6071741861, 2.8472067356],
      "speed factor": 1.1719603911,
      "Uz factor": 0.9992627423,
      "Directional variance factor": 0.9460289612,
      "Velocity stability factor": 0.9553488607,
      "Direction stability factor": 0.9875628228,
      score: 0.9691258669,
    },
    "292.5": {
      velocity: [0.9324108919, 1.1963123025, 1.2614604817],
      direction: [-11.0535753041, -7.9668021158, -6.0913105423],
      "speed factor": 1.4133020334,
      "Uz factor": 0.995985647,
      "Directional variance factor": 0.2918398119,
      "Velocity stability factor": 0.6988667192,
      "Direction stability factor": 0.9862159312,
      score: 0.7407845984,
    },
    "337.5": {
      velocity: [0.0870059186, 1.026298243, 1.3501620593],
      direction: [-21.6953990834, 15.1872160845, 64.0501875899],
      "speed factor": 1.3251096834,
      "Uz factor": 0.949646939,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1908482301,
      "Direction stability factor": 0.7618178148,
      score: 0.0,
    },
    id: "IDN:4609.0",
    "45": {
      velocity: [0.9294280907, 1.150352054, 1.307085542],
      direction: [-9.0119594147, -1.6042452945, 3.4110400627],
      "speed factor": 1.0789254004,
      "Uz factor": 0.9593094208,
      "Directional variance factor": 0.8574004183,
      "Velocity stability factor": 0.7010246685,
      "Direction stability factor": 0.9654916681,
      score: 0.8723521057,
    },
    "90": {
      velocity: [0.1743473676, 0.378175243, 0.6237027485],
      direction: [-40.68247457, -10.7245246842, 21.2429782194],
      "speed factor": 0.9687254902,
      "Uz factor": 0.9999979392,
      "Directional variance factor": 0.046708917,
      "Velocity stability factor": 0.3660541318,
      "Direction stability factor": 0.8279848534,
      score: 0.0,
    },
    "135": {
      velocity: [0.1841999654, 0.9241207888, 1.6875897067],
      direction: [-51.3932786635, 4.1658766269, 117.1042462075],
      "speed factor": 0.8408371052,
      "Uz factor": 0.9692821531,
      "Directional variance factor": 0.6296998554,
      "Velocity stability factor": 0.1377109189,
      "Direction stability factor": 0.5319513198,
      score: 0.0,
    },
    "180": {
      velocity: [0.0543937995, 0.3670047484, 0.8041533909],
      direction: [-173.8098608663, -47.2609792417, 176.8852880137],
      "speed factor": 0.5209890339,
      "Uz factor": 0.9962197402,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3976125181,
      "Direction stability factor": 0.0258468087,
      score: 0.0,
    },
    "225": {
      velocity: [0.4673753626, 0.5094037163, 0.5749454565],
      direction: [-0.0629236876, 7.3380134017, 14.1375691804],
      "speed factor": 1.0224072953,
      "Uz factor": 0.9999945959,
      "Directional variance factor": 0.3477321421,
      "Velocity stability factor": 0.8447660985,
      "Direction stability factor": 0.9605541865,
      score: 0.7784016534,
    },
    "270": {
      velocity: [0.4755338374, 0.5210774116, 0.560411685],
      direction: [-14.3855732947, -7.3265993366, -3.0784479936],
      "speed factor": 0.9888846474,
      "Uz factor": 0.9998341781,
      "Directional variance factor": 0.3487467256,
      "Velocity stability factor": 0.8832781961,
      "Direction stability factor": 0.9685913186,
      score: 0.0,
    },
    "315": {
      velocity: [0.6562904939, 0.904218914, 1.2428361043],
      direction: [-18.4489036323, 7.2819254489, 35.9819648181],
      "speed factor": 1.4223103372,
      "Uz factor": 0.9942541934,
      "Directional variance factor": 0.3527177379,
      "Velocity stability factor": 0.5004787845,
      "Direction stability factor": 0.8488031432,
      score: 0.6377007022,
    },
  },
  {
    position: [-72.776153, 3.817757, 48.16],
    "overall score": 0.3414744354,
    "0": {
      velocity: [0.6665841111, 1.116422445, 1.436014637],
      direction: [4.8741008036, 18.5725043742, 54.0008448385],
      "speed factor": 1.4213196287,
      "Uz factor": 0.9469752407,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4856688379,
      "Direction stability factor": 0.8635368221,
      score: 0.0,
    },
    "22.5": {
      velocity: [1.0500726257, 1.1330126494, 1.2008962786],
      direction: [8.6975121379, 11.8279616219, 14.6737214029],
      "speed factor": 1.216484477,
      "Uz factor": 0.8952922767,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7891766618,
      "Direction stability factor": 0.9833994187,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.9072080558, 1.1954715828, 1.3473659676],
      direction: [-18.1339342656, -10.9291929034, -6.1193232191],
      "speed factor": 1.1447124555,
      "Uz factor": 0.9668542628,
      "Directional variance factor": 0.0285161864,
      "Velocity stability factor": 0.6858483917,
      "Direction stability factor": 0.9666260804,
      score: 0.6619041847,
    },
    "112.5": {
      velocity: [0.044034705, 0.6378249344, 1.108891133],
      direction: [-162.3079630987, 21.3193147987, 68.2613688997],
      "speed factor": 0.6965407392,
      "Uz factor": 0.9917681753,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2163009412,
      "Direction stability factor": 0.3595296333,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3429282698, 1.0470030043, 1.5179574767],
      direction: [-78.9765398804, -25.6881991437, 3.6782032744],
      "speed factor": 1.0678030408,
      "Uz factor": 0.9999990343,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2925921149,
      "Direction stability factor": 0.7704034912,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6125018663, 0.952270265, 1.301559099],
      direction: [-23.9216965334, 0.6352512147, 22.9631026497],
      "speed factor": 1.5703328921,
      "Uz factor": 0.9999791555,
      "Directional variance factor": 0.9435332254,
      "Velocity stability factor": 0.4875772724,
      "Direction stability factor": 0.8697644467,
      score: 0.7926598478,
    },
    "247.5": {
      velocity: [0.9673398495, 0.9891082518, 1.0100320734],
      direction: [-1.0516535323, 0.7626741604, 1.6917643775],
      "speed factor": 1.1933702004,
      "Uz factor": 0.9905215405,
      "Directional variance factor": 0.9322067413,
      "Velocity stability factor": 0.9608610135,
      "Direction stability factor": 0.9923793947,
      score: 0.969456636,
    },
    "292.5": {
      velocity: [1.1587723484, 1.2208879765, 1.2573334231],
      direction: [-11.9941464542, -8.5607956176, -6.8644652508],
      "speed factor": 1.4423352968,
      "Uz factor": 0.9810634699,
      "Directional variance factor": 0.2390403895,
      "Velocity stability factor": 0.9098007695,
      "Direction stability factor": 0.9857508855,
      score: 0.7800857325,
    },
    "337.5": {
      velocity: [0.6969329149, 1.1129625795, 1.4908914319],
      direction: [-8.8655139756, 18.5743032063, 48.0365817078],
      "speed factor": 1.4370067389,
      "Uz factor": 0.9719509783,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4914065502,
      "Direction stability factor": 0.8419386231,
      score: 0.0,
    },
    id: "IDN:4580.0",
    "45": {
      velocity: [0.51621919, 0.9459159307, 1.202194987],
      direction: [-10.9010116283, 1.2575067939, 20.2357556756],
      "speed factor": 0.8871829461,
      "Uz factor": 0.911747417,
      "Directional variance factor": 0.8882216183,
      "Velocity stability factor": 0.4569421559,
      "Direction stability factor": 0.9135089797,
      score: 0.0,
    },
    "90": {
      velocity: [0.1947006603, 0.3862882226, 0.6273102633],
      direction: [-43.8946482013, -13.2015848346, 21.6190541264],
      "speed factor": 0.9895075225,
      "Uz factor": 0.9999483952,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3896789,
      "Direction stability factor": 0.8180174935,
      score: 0.0,
    },
    "135": {
      velocity: [0.0230063433, 0.5777170941, 1.4458612377],
      direction: [-94.1955810744, -0.0349552596, 162.1380726917],
      "speed factor": 0.5256520304,
      "Uz factor": 0.9777296026,
      "Directional variance factor": 0.9968928658,
      "Velocity stability factor": 0.1839027461,
      "Direction stability factor": 0.2879620729,
      score: 0.0,
    },
    "180": {
      velocity: [0.0524407059, 0.3820988151, 0.931579227],
      direction: [-179.9381499028, -36.8353406756, 151.5940935602],
      "speed factor": 0.5424161223,
      "Uz factor": 0.9998198197,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2936642012,
      "Direction stability factor": 0.0790771015,
      score: 0.0,
    },
    "225": {
      velocity: [0.4763200464, 0.5259428244, 0.5940924909],
      direction: [3.1905313223, 10.626094832, 16.1363854518],
      "speed factor": 1.0556023904,
      "Uz factor": 0.9990905797,
      "Directional variance factor": 0.0554582372,
      "Velocity stability factor": 0.8300431338,
      "Direction stability factor": 0.9640392941,
      score: 0.7033949898,
    },
    "270": {
      velocity: [0.4907021659, 0.5352992, 0.5735681016],
      direction: [-11.7493447149, -6.5463350119, -3.7673716228],
      "speed factor": 1.0158743191,
      "Uz factor": 0.9981704382,
      "Directional variance factor": 0.4181035545,
      "Velocity stability factor": 0.8860449248,
      "Direction stability factor": 0.9778278525,
      score: 0.8149510461,
    },
    "315": {
      velocity: [0.6316599066, 0.8886159406, 1.2004160001],
      direction: [-20.4940424623, 2.8831707826, 32.5686878525],
      "speed factor": 1.3977673089,
      "Uz factor": 0.9915630925,
      "Directional variance factor": 0.7437181527,
      "Velocity stability factor": 0.5156289126,
      "Direction stability factor": 0.8526035269,
      score: 0.7411385298,
    },
  },
  {
    position: [-67.62847, 1.417754, 48.16],
    "overall score": 0.3390387889,
    "0": {
      velocity: [0.0821530709, 0.7024787754, 1.297730459],
      direction: [-170.4782731743, 10.7436397531, 168.5170603696],
      "speed factor": 0.894327122,
      "Uz factor": 0.9630963091,
      "Directional variance factor": 0.0450097997,
      "Velocity stability factor": 0.187438879,
      "Direction stability factor": 0.0583462957,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3094593516, 0.5084027207, 0.6424695911],
      direction: [-8.1042131273, 0.0610001156, 10.3382386027],
      "speed factor": 0.5458579991,
      "Uz factor": 0.939159054,
      "Directional variance factor": 0.9945777675,
      "Velocity stability factor": 0.5345137917,
      "Direction stability factor": 0.9487709674,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.6050203045, 1.0993755843, 1.3663324995],
      direction: [-22.5298523099, -8.1064185375, 6.9814312545],
      "speed factor": 1.0526966452,
      "Uz factor": 0.9882720258,
      "Directional variance factor": 0.2794294633,
      "Velocity stability factor": 0.4566326218,
      "Direction stability factor": 0.9180242123,
      score: 0.6430276274,
    },
    "112.5": {
      velocity: [0.224636427, 0.8725123647, 1.18757288],
      direction: [-23.8043527855, 25.1955483105, 68.1889011936],
      "speed factor": 0.9528326265,
      "Uz factor": 0.9806761315,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2913106668,
      "Direction stability factor": 0.7444631834,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4596084657, 1.141134246, 1.6630298849],
      direction: [-85.223650335, -24.7994781048, 1.017996079],
      "speed factor": 1.1638043184,
      "Uz factor": 0.9961462581,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2754990292,
      "Direction stability factor": 0.7604398711,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6924949233, 0.9870381327, 1.320813062],
      direction: [-15.3861311238, 3.4993974094, 27.6709489926],
      "speed factor": 1.627666538,
      "Uz factor": 0.9944833335,
      "Directional variance factor": 0.6889424525,
      "Velocity stability factor": 0.5327463683,
      "Direction stability factor": 0.8803969997,
      score: 0.745620705,
    },
    "247.5": {
      velocity: [0.9447426014, 0.9701596474, 0.9973639191],
      direction: [-2.619717876, -0.6684499843, 1.1652146965],
      "speed factor": 1.1705084967,
      "Uz factor": 0.9993852228,
      "Directional variance factor": 0.9405822236,
      "Velocity stability factor": 0.9517583097,
      "Direction stability factor": 0.9894862984,
      score: 0.9678282825,
    },
    "292.5": {
      velocity: [0.8940965681, 1.0804770818, 1.1637609311],
      direction: [-10.6082737623, -7.5481141499, -5.3486920232],
      "speed factor": 1.2764563681,
      "Uz factor": 0.9922251034,
      "Directional variance factor": 0.32905652,
      "Velocity stability factor": 0.7532137499,
      "Direction stability factor": 0.9853900507,
      score: 0.7632625928,
    },
    "337.5": {
      velocity: [0.1918877935, 0.8483706836, 1.2873034164],
      direction: [-153.4530167901, 15.9815632998, 107.0937507307],
      "speed factor": 1.0953776991,
      "Uz factor": 0.972035309,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.298299346,
      "Direction stability factor": 0.2762589791,
      score: 0.0,
    },
    id: "IDN:4608.0",
    "45": {
      velocity: [0.8070277331, 1.123867652, 1.265428252],
      direction: [-9.3359162539, -0.6650397337, 6.4737822608],
      "speed factor": 1.0540854447,
      "Uz factor": 0.9810611119,
      "Directional variance factor": 0.940885357,
      "Velocity stability factor": 0.6371038182,
      "Direction stability factor": 0.9560841708,
      score: 0.8725393792,
    },
    "90": {
      velocity: [0.186442793, 0.3734067656, 0.6347773244],
      direction: [-36.0799396698, -9.012468859, 24.533528551],
      "speed factor": 0.956510662,
      "Uz factor": 0.9999405513,
      "Directional variance factor": 0.198891657,
      "Velocity stability factor": 0.3674943356,
      "Direction stability factor": 0.8316292549,
      score: 0.0,
    },
    "135": {
      velocity: [0.3187850107, 1.085916344, 1.752642769],
      direction: [-20.2864394477, 3.9105807882, 36.3238585701],
      "speed factor": 0.9880513091,
      "Uz factor": 0.9590073453,
      "Directional variance factor": 0.6523928188,
      "Velocity stability factor": 0.1775919079,
      "Direction stability factor": 0.8427491722,
      score: 0.0,
    },
    "180": {
      velocity: [0.0866569288, 0.4000784191, 0.8477914472],
      direction: [-154.5993766256, -47.8554861874, 171.6005947374],
      "speed factor": 0.5679394339,
      "Uz factor": 0.990550315,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3884734372,
      "Direction stability factor": 0.0938889684,
      score: 0.0,
    },
    "225": {
      velocity: [0.4608487081, 0.5003764893, 0.56346201],
      direction: [-1.1279155405, 6.2940940487, 13.4418220121],
      "speed factor": 1.0042890475,
      "Uz factor": 0.9999440322,
      "Directional variance factor": 0.4405249735,
      "Velocity stability factor": 0.851919222,
      "Direction stability factor": 0.9595285068,
      score: 0.8028753023,
    },
    "270": {
      velocity: [0.4801631202, 0.5230088109, 0.5594448931],
      direction: [-15.224335125, -7.994797483, -3.172062053],
      "speed factor": 0.9925499974,
      "Uz factor": 0.9998963527,
      "Directional variance factor": 0.2893513348,
      "Velocity stability factor": 0.8909737722,
      "Direction stability factor": 0.9665214637,
      score: 0.0,
    },
    "315": {
      velocity: [0.6342920809, 0.8984363041, 1.2310707608],
      direction: [-18.0977922581, 7.4529530106, 37.9564264216],
      "speed factor": 1.4132144582,
      "Uz factor": 0.9986032099,
      "Directional variance factor": 0.3375152879,
      "Velocity stability factor": 0.4917639715,
      "Direction stability factor": 0.844293837,
      score: 0.6294667334,
    },
  },
  {
    position: [-71.276153, 3.817757, 48.16],
    "overall score": 0.3378963964,
    "0": {
      velocity: [0.6069944022, 1.106982463, 1.469322603],
      direction: [2.2250717453, 17.4813962845, 40.3562511572],
      "speed factor": 1.4093015689,
      "Uz factor": 0.9373173082,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4235707439,
      "Direction stability factor": 0.8940800572,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.8705869135, 0.9639125642, 1.0843546013],
      direction: [7.9018533519, 11.4609751503, 13.7844196829],
      "speed factor": 1.0349263728,
      "Uz factor": 0.8800407628,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7011926401,
      "Direction stability factor": 0.983659538,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.9596535577, 1.2824902981, 1.38521264],
      direction: [-15.2830339389, -11.1121524232, -5.5620664928],
      "speed factor": 1.2280363996,
      "Uz factor": 0.9721286011,
      "Directional variance factor": 0.0122531179,
      "Velocity stability factor": 0.696267938,
      "Direction stability factor": 0.9729973126,
      score: 0.6636289203,
    },
    "112.5": {
      velocity: [0.0817348858, 0.7007112664, 1.114921901],
      direction: [-144.7437820265, 21.9979723019, 154.5925031271],
      "speed factor": 0.7652161544,
      "Uz factor": 0.9916518353,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.23960858,
      "Direction stability factor": 0.168510319,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2941269703, 1.0699659434, 1.598815479],
      direction: [-71.7169293905, -25.0081713751, 7.3591386195],
      "speed factor": 1.0912221676,
      "Uz factor": 0.9999233096,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.214532768,
      "Direction stability factor": 0.7803442555,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6235301621, 0.961988102, 1.316879577],
      direction: [-22.9036637557, 0.8298798459, 23.6636000577],
      "speed factor": 1.5863580056,
      "Uz factor": 0.9998979442,
      "Directional variance factor": 0.9262329026,
      "Velocity stability factor": 0.484385358,
      "Direction stability factor": 0.8706464894,
      score: 0.7879778098,
    },
    "247.5": {
      velocity: [0.9635594761, 0.9874655484, 1.0096912094],
      direction: [-0.9136222878, 1.0772315996, 2.1223439693],
      "speed factor": 1.1913882604,
      "Uz factor": 0.9941389314,
      "Directional variance factor": 0.90424608,
      "Velocity stability factor": 0.9577077715,
      "Direction stability factor": 0.9915667604,
      score: 0.9612718431,
    },
    "292.5": {
      velocity: [1.1784056199, 1.2468860148, 1.2853272751],
      direction: [-13.0733632451, -9.1243686113, -7.2602403393],
      "speed factor": 1.4730489159,
      "Uz factor": 0.9873935464,
      "Directional variance factor": 0.1889450123,
      "Velocity stability factor": 0.9021494941,
      "Direction stability factor": 0.9838524364,
      score: 0.7646998448,
    },
    "337.5": {
      velocity: [0.7320542359, 1.1229679481, 1.4827698129],
      direction: [-5.1739103257, 18.3630119414, 52.6879460258],
      "speed factor": 1.4499252165,
      "Uz factor": 0.9634240443,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5191070857,
      "Direction stability factor": 0.8392726212,
      score: 0.0,
    },
    id: "IDN:4581.0",
    "45": {
      velocity: [0.6418286145, 0.9613966332, 1.179875347],
      direction: [-16.2087640796, -0.2250447256, 3.6387010271],
      "speed factor": 0.9017024343,
      "Uz factor": 0.9099607421,
      "Directional variance factor": 0.9799960244,
      "Velocity stability factor": 0.5740513005,
      "Direction stability factor": 0.9448681525,
      score: 0.0,
    },
    "90": {
      velocity: [0.1892475622, 0.3853103162, 0.6245766319],
      direction: [-44.4324840166, -12.9731114272, 20.9368681318],
      "speed factor": 0.9870025387,
      "Uz factor": 0.9999598496,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3858423048,
      "Direction stability factor": 0.8184184663,
      score: 0.0,
    },
    "135": {
      velocity: [0.1641996001, 0.6556615351, 1.391041772],
      direction: [-101.0646009004, 1.7747417441, 33.7701869569],
      "speed factor": 0.5965719566,
      "Uz factor": 0.9765813158,
      "Directional variance factor": 0.8422451783,
      "Velocity stability factor": 0.296328437,
      "Direction stability factor": 0.6254589226,
      score: 0.0,
    },
    "180": {
      velocity: [0.0278969639, 0.3689247572, 0.8394893022],
      direction: [-178.3486900477, -39.5528876998, 176.8969573158],
      "speed factor": 0.5237146212,
      "Uz factor": 0.9994110339,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3479335635,
      "Direction stability factor": 0.0132065351,
      score: 0.0,
    },
    "225": {
      velocity: [0.473604736, 0.522703912, 0.5907091557],
      direction: [2.2608850698, 9.9687931648, 16.085382128],
      "speed factor": 1.0491016769,
      "Uz factor": 0.9997854448,
      "Directional variance factor": 0.113885052,
      "Velocity stability factor": 0.8310071573,
      "Direction stability factor": 0.9615986193,
      score: 0.717022362,
    },
    "270": {
      velocity: [0.4851586773, 0.530379622, 0.569042451],
      direction: [-12.1207373007, -6.4977986063, -3.4628317284],
      "speed factor": 1.0065380956,
      "Uz factor": 0.9989230476,
      "Directional variance factor": 0.4224179017,
      "Velocity stability factor": 0.8846452207,
      "Direction stability factor": 0.9759502623,
      score: 0.8147409117,
    },
    "315": {
      velocity: [0.641922682, 0.8938193572, 1.2309655283],
      direction: [-22.0791632432, 4.389359577, 35.5539848854],
      "speed factor": 1.4059521335,
      "Uz factor": 0.9928524739,
      "Directional variance factor": 0.6098347043,
      "Velocity stability factor": 0.4983520576,
      "Direction stability factor": 0.8399079219,
      score: 0.6970006514,
    },
  },
  {
    position: [-61.42847, 3.817757, 48.16],
    "overall score": 0.3292738422,
    "0": {
      velocity: [0.6919006958, 1.041434154, 1.380148579],
      direction: [-13.6274620951, 7.7392171979, 42.157332775],
      "speed factor": 1.3258518867,
      "Uz factor": 0.9511446281,
      "Directional variance factor": 0.3120695824,
      "Velocity stability factor": 0.5399359375,
      "Direction stability factor": 0.8450422365,
      score: 0.6355224982,
    },
    "22.5": {
      velocity: [1.2238409463, 1.2372488285, 1.2552427814],
      direction: [5.2909299653, 6.3348617741, 7.5265455235],
      "speed factor": 1.3283999916,
      "Uz factor": 0.9103851141,
      "Directional variance factor": 0.4369011756,
      "Velocity stability factor": 0.956106091,
      "Direction stability factor": 0.9937899568,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.2169453007, 1.2928438832, 1.3704742066],
      direction: [-10.6882798007, -7.6001459451, -2.7828914158],
      "speed factor": 1.2379503766,
      "Uz factor": 0.9490289484,
      "Directional variance factor": 0.3244314715,
      "Velocity stability factor": 0.8904226155,
      "Direction stability factor": 0.9780405878,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0889331126, 0.8550727795, 1.247576377],
      direction: [-75.5387762955, 25.0906070342, 60.8392402609],
      "speed factor": 0.9337876176,
      "Uz factor": 0.9927244339,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1472769362,
      "Direction stability factor": 0.6211721762,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3276804093, 1.0460900478, 1.5483869564],
      direction: [-86.579228915, -22.506940486, 17.6660005552],
      "speed factor": 1.0668719472,
      "Uz factor": 0.9937869821,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2650927894,
      "Direction stability factor": 0.7104299181,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.595270195, 0.966194466, 1.305024994],
      direction: [-17.947845112, 7.7727077073, 32.0206006856],
      "speed factor": 1.5932944731,
      "Uz factor": 0.9954075565,
      "Directional variance factor": 0.3090926482,
      "Velocity stability factor": 0.4721853675,
      "Direction stability factor": 0.8611987617,
      score: 0.6259188848,
    },
    "247.5": {
      velocity: [0.9481116387, 0.9771461312, 1.0057557181],
      direction: [-3.1670260957, -0.9834271691, 1.0574988698],
      "speed factor": 1.1789377677,
      "Uz factor": 0.9999096597,
      "Directional variance factor": 0.9125842516,
      "Velocity stability factor": 0.9471535882,
      "Direction stability factor": 0.9882652084,
      score: 0.9590670642,
    },
    "292.5": {
      velocity: [1.1189848611, 1.2367841662, 1.2957797118],
      direction: [-6.3920602269, -2.8049111028, 0.1562832986],
      "speed factor": 1.461114772,
      "Uz factor": 0.9990885598,
      "Directional variance factor": 0.7506745686,
      "Velocity stability factor": 0.8382042856,
      "Direction stability factor": 0.9818101569,
      score: 0.888124792,
    },
    "337.5": {
      velocity: [0.7508484721, 1.1500616666, 1.4723289346],
      direction: [-11.6859484647, 6.0213758218, 40.3012849927],
      "speed factor": 1.4849073953,
      "Uz factor": 0.9560349148,
      "Directional variance factor": 0.4647665936,
      "Velocity stability factor": 0.537834497,
      "Direction stability factor": 0.8555910182,
      score: 0.6784457817,
    },
    id: "IDN:4602.0",
    "45": {
      velocity: [1.168771981, 1.238535062, 1.275654481],
      direction: [-1.4632833009, 5.0475659855, 10.0826838335],
      "speed factor": 1.1616330262,
      "Uz factor": 0.9224858861,
      "Directional variance factor": 0.551327468,
      "Velocity stability factor": 0.9153856735,
      "Direction stability factor": 0.9679278691,
      score: 0.0,
    },
    "90": {
      velocity: [0.1594304411, 0.3608842382, 0.6115952628],
      direction: [-31.3517551219, -8.1561809357, 18.7173671123],
      "speed factor": 0.9244332277,
      "Uz factor": 0.9928137786,
      "Directional variance factor": 0.275006139,
      "Velocity stability factor": 0.3620906021,
      "Direction stability factor": 0.8609191049,
      score: 0.0,
    },
    "135": {
      velocity: [0.32311986, 1.0262767457, 1.5988785274],
      direction: [-108.7094760135, 7.3334504211, 38.9817533035],
      "speed factor": 0.9337865551,
      "Uz factor": 0.9741139505,
      "Directional variance factor": 0.3481377403,
      "Velocity stability factor": 0.2682717337,
      "Direction stability factor": 0.5897465852,
      score: 0.0,
    },
    "180": {
      velocity: [0.0427414185, 0.3373978368, 0.8486476146],
      direction: [-159.1683870017, -54.5313522544, 178.5242114632],
      "speed factor": 0.4789599421,
      "Uz factor": 0.9391369405,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3525020424,
      "Direction stability factor": 0.0619650043,
      score: 0.0,
    },
    "225": {
      velocity: [0.4627209835, 0.5017671026, 0.5583596914],
      direction: [-1.3733056149, 6.1942053125, 13.4234678071],
      "speed factor": 1.0070801014,
      "Uz factor": 0.9998851048,
      "Directional variance factor": 0.4494039722,
      "Velocity stability factor": 0.8619842261,
      "Direction stability factor": 0.9588978516,
      score: 0.8072959754,
    },
    "270": {
      velocity: [0.4768959594, 0.5191705954, 0.5584792862],
      direction: [-17.4804959805, -9.0479772402, -3.1962538803],
      "speed factor": 0.9852659504,
      "Uz factor": 0.9999374613,
      "Directional variance factor": 0.1957353564,
      "Velocity stability factor": 0.8878087353,
      "Direction stability factor": 0.9603215497,
      score: 0.0,
    },
    "315": {
      velocity: [0.540972002, 0.8667009345, 1.2046680975],
      direction: [-15.1966120782, 5.4075904754, 31.2567639782],
      "speed factor": 1.3632956348,
      "Uz factor": 0.9880287822,
      "Directional variance factor": 0.5193252911,
      "Velocity stability factor": 0.4347749357,
      "Direction stability factor": 0.8709628443,
      score: 0.6740064788,
    },
  },
  {
    position: [-64.42847, 0.817757, 48.16],
    "overall score": 0.3182271987,
    "0": {
      velocity: [0.0912279286, 0.9351895603, 1.413622857],
      direction: [-167.3215583469, 8.2637328743, 165.0529340799],
      "speed factor": 1.1905916838,
      "Uz factor": 0.9736090178,
      "Directional variance factor": 0.2654459667,
      "Velocity stability factor": 0.1160359546,
      "Direction stability factor": 0.076737521,
      score: 0.1337392409,
    },
    "22.5": {
      velocity: [0.8967298242, 1.0191738207, 1.1339994301],
      direction: [5.3811052587, 7.1724152977, 8.8299396961],
      "speed factor": 1.0942588619,
      "Uz factor": 0.9547410759,
      "Directional variance factor": 0.3624519735,
      "Velocity stability factor": 0.6683413418,
      "Direction stability factor": 0.9904199043,
      score: 0.752908281,
    },
    "67.5": {
      velocity: [1.1283888407, 1.2880034897, 1.4024691102],
      direction: [-13.8350691922, -9.5836116212, -2.6127121568],
      "speed factor": 1.2333155038,
      "Uz factor": 0.9567379879,
      "Directional variance factor": 0.1481234114,
      "Velocity stability factor": 0.8043821202,
      "Direction stability factor": 0.968826786,
      score: 0.7225397759,
    },
    "112.5": {
      velocity: [0.2806475082, 0.9160226477, 1.182639444],
      direction: [-61.061513057, 25.3703389104, 59.7313719278],
      "speed factor": 1.0003483052,
      "Uz factor": 0.9820085348,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3361638127,
      "Direction stability factor": 0.6644642084,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5479341549, 1.1591544147, 1.783923239],
      direction: [-79.6716349677, -24.5913465726, 1.3672805522],
      "speed factor": 1.1821824805,
      "Uz factor": 0.9929235234,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2558921779,
      "Direction stability factor": 0.7748919013,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.68938283, 0.9932366592, 1.337751213],
      direction: [-14.9553497386, 7.1959862703, 30.7824999646],
      "speed factor": 1.6378881636,
      "Uz factor": 0.9907634855,
      "Directional variance factor": 0.360356776,
      "Velocity stability factor": 0.5178358494,
      "Direction stability factor": 0.8729504175,
      score: 0.6560233651,
    },
    "247.5": {
      velocity: [0.9231841888, 0.9494603662, 0.980921475],
      direction: [-4.6964870556, -2.616145705, -0.9357732625],
      "speed factor": 1.1455345817,
      "Uz factor": 0.9995347822,
      "Directional variance factor": 0.7674537151,
      "Velocity stability factor": 0.9470681389,
      "Direction stability factor": 0.9895535728,
      score: 0.9234072499,
    },
    "292.5": {
      velocity: [0.6495798158, 0.9417548639, 1.0929656376],
      direction: [-16.5103920174, -6.6589717641, -1.2570455082],
      "speed factor": 1.1125724122,
      "Uz factor": 0.9983332571,
      "Directional variance factor": 0.4080913987,
      "Velocity stability factor": 0.5942306833,
      "Direction stability factor": 0.957629593,
      score: 0.729395317,
    },
    "337.5": {
      velocity: [0.1486006946, 0.8275158029, 1.3252338048],
      direction: [-168.10898113, 10.1543472466, 151.3645191709],
      "speed factor": 1.0684508241,
      "Uz factor": 0.979096613,
      "Directional variance factor": 0.0973913559,
      "Velocity stability factor": 0.2462730989,
      "Direction stability factor": 0.1125736103,
      score: 0.1422029188,
    },
    id: "IDN:4592.0",
    "45": {
      velocity: [0.8261658174, 1.031576773, 1.225075505],
      direction: [-1.0823793822, 6.6122916476, 14.0799931237],
      "speed factor": 0.9675250103,
      "Uz factor": 0.9500765097,
      "Directional variance factor": 0.4122407424,
      "Velocity stability factor": 0.6842001775,
      "Direction stability factor": 0.9578822986,
      score: 0.0,
    },
    "90": {
      velocity: [0.1919494763, 0.3694694459, 0.6406904975],
      direction: [-29.4935457214, -7.7113873963, 25.2806041774],
      "speed factor": 0.9464249094,
      "Uz factor": 0.9992645418,
      "Directional variance factor": 0.3145433426,
      "Velocity stability factor": 0.366920864,
      "Direction stability factor": 0.8478495836,
      score: 0.0,
    },
    "135": {
      velocity: [0.307547092, 1.211100404, 1.6843948481],
      direction: [-16.8027425696, 5.028464887, 122.5264917763],
      "speed factor": 1.1019535217,
      "Uz factor": 0.9608298575,
      "Directional variance factor": 0.5530253434,
      "Velocity stability factor": 0.2102907491,
      "Direction stability factor": 0.612974349,
      score: 0.4973161976,
    },
    "180": {
      velocity: [0.066412319, 0.411159176, 1.0017292423],
      direction: [-168.00408083, -52.579341303, 155.7740210535],
      "speed factor": 0.5836693471,
      "Uz factor": 0.9771824242,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2485281781,
      "Direction stability factor": 0.1006163837,
      score: 0.0,
    },
    "225": {
      velocity: [0.454837573, 0.4909740455, 0.547222929],
      direction: [-1.7882364238, 5.5583908138, 13.0402707296],
      "speed factor": 0.9854177147,
      "Uz factor": 0.9996798909,
      "Directional variance factor": 0.5059208165,
      "Velocity stability factor": 0.866679123,
      "Direction stability factor": 0.9588097024,
      score: 0.0,
    },
    "270": {
      velocity: [0.4822221327, 0.5209115024, 0.5547417278],
      direction: [-17.6458176749, -9.4976885047, -3.5306130442],
      "speed factor": 0.98856979,
      "Uz factor": 0.9999045559,
      "Directional variance factor": 0.1557610218,
      "Velocity stability factor": 0.900272943,
      "Direction stability factor": 0.9607910982,
      score: 0.0,
    },
    "315": {
      velocity: [0.3259456307, 0.8611976907, 1.1701628291],
      direction: [-45.1877699789, 8.0768994351, 31.6145179724],
      "speed factor": 1.3546391907,
      "Uz factor": 0.9987450893,
      "Directional variance factor": 0.2820533835,
      "Velocity stability factor": 0.281037325,
      "Direction stability factor": 0.7866603112,
      score: 0.5341028328,
    },
  },
  {
    position: [-74.276153, 0.817757, 48.16],
    "overall score": 0.3126241994,
    "0": {
      velocity: [0.2947161703, 0.9458889625, 1.421266979],
      direction: [-0.5043742463, 17.6387632228, 102.3989853038],
      "speed factor": 1.2042131139,
      "Uz factor": 0.9767594076,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2469493124,
      "Direction stability factor": 0.7141573346,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.124198735, 0.2261762987, 0.4284942576],
      direction: [9.5479758513, 39.2363629246, 102.7538064232],
      "speed factor": 0.2428392627,
      "Uz factor": 0.9499219089,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5746516106,
      "Direction stability factor": 0.7410949151,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.5849506164, 1.0439610281, 1.3209917568],
      direction: [-29.7249485042, -11.9112093712, 11.6228509947],
      "speed factor": 0.9996349634,
      "Uz factor": 0.9943653983,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4746691998,
      "Direction stability factor": 0.8851450014,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1649319713, 0.7468473049, 1.117303768],
      direction: [-60.5244574467, 19.3758565613, 67.9289297723],
      "speed factor": 0.815599306,
      "Uz factor": 0.9681055295,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2990859039,
      "Direction stability factor": 0.6431850355,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4600201013, 1.0827403644, 1.6666345262],
      direction: [-81.5345991106, -27.7826133118, -1.0355499853],
      "speed factor": 1.1042503686,
      "Uz factor": 0.9968757992,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2735767303,
      "Direction stability factor": 0.7763915302,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6392331929, 0.9413359358, 1.248413038],
      direction: [-23.5921435233, 0.0792031873, 22.0984827962],
      "speed factor": 1.5523017328,
      "Uz factor": 0.9991634607,
      "Directional variance factor": 0.9929597167,
      "Velocity stability factor": 0.546978708,
      "Direction stability factor": 0.8730815936,
      score: 0.8215254029,
    },
    "247.5": {
      velocity: [0.9577344144, 0.9816758591, 1.0021384583],
      direction: [-0.1458459697, 1.5838182256, 2.5813560698],
      "speed factor": 1.1844029352,
      "Uz factor": 0.9900964356,
      "Directional variance factor": 0.8592161577,
      "Velocity stability factor": 0.9592916668,
      "Direction stability factor": 0.9924244388,
      score: 0.9508391755,
    },
    "292.5": {
      velocity: [1.1265078001, 1.1947850991, 1.2346810669],
      direction: [-7.7812952799, -4.3345271106, -2.3534416459],
      "speed factor": 1.4114978226,
      "Uz factor": 0.9668091751,
      "Directional variance factor": 0.6147087013,
      "Velocity stability factor": 0.9010040682,
      "Direction stability factor": 0.9849226288,
      score: 0.8713895068,
    },
    "337.5": {
      velocity: [0.4433217779, 1.0861981282, 1.3447488671],
      direction: [-16.5638093743, 16.937553448, 57.8278549662],
      "speed factor": 1.4024496949,
      "Uz factor": 0.9961633473,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4225643995,
      "Direction stability factor": 0.7933564879,
      score: 0.0,
    },
    id: "IDN:4571.0",
    "45": {
      velocity: [0.0078342341, 0.3139293008, 0.8095164734],
      direction: [-179.7907443037, -14.3930021506, 172.0055955579],
      "speed factor": 0.2944370772,
      "Uz factor": 0.9584854941,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3653422898,
      "Direction stability factor": 0.0227879448,
      score: 0.0,
    },
    "90": {
      velocity: [0.1948031815, 0.3862734965, 0.6460243839],
      direction: [-37.4487972189, -10.6794614473, 25.7050397805],
      "speed factor": 0.9894698005,
      "Uz factor": 0.9999284217,
      "Directional variance factor": 0.050714538,
      "Velocity stability factor": 0.3634218503,
      "Direction stability factor": 0.824572675,
      score: 0.0,
    },
    "135": {
      velocity: [0.163956983, 0.9961963351, 1.5499443632],
      direction: [-88.1382256529, -1.3965725878, 13.6970000301],
      "speed factor": 0.9064170536,
      "Uz factor": 0.9551086916,
      "Directional variance factor": 0.8758602144,
      "Velocity stability factor": 0.2050485968,
      "Direction stability factor": 0.7171243731,
      score: 0.0,
    },
    "180": {
      velocity: [0.0376178062, 0.4271150144, 0.8660867634],
      direction: [-173.4230624529, -40.2424881767, 106.8319811538],
      "speed factor": 0.6063197811,
      "Uz factor": 0.9977550218,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3343741985,
      "Direction stability factor": 0.2215137678,
      score: 0.0,
    },
    "225": {
      velocity: [0.4697954067, 0.5202994581, 0.5838359996],
      direction: [2.286045899, 9.4162074826, 15.3437735047],
      "speed factor": 1.0442757772,
      "Uz factor": 0.9972986695,
      "Directional variance factor": 0.1630037793,
      "Velocity stability factor": 0.8354285516,
      "Direction stability factor": 0.9637285344,
      score: 0.73147235,
    },
    "270": {
      velocity: [0.4950881568, 0.5415748462, 0.5753617168],
      direction: [-10.9483076442, -5.6255971336, -2.5764901492],
      "speed factor": 1.0277840469,
      "Uz factor": 0.9969439869,
      "Directional variance factor": 0.4999469215,
      "Velocity stability factor": 0.8896098925,
      "Direction stability factor": 0.9767449514,
      score: 0.8357616792,
    },
    "315": {
      velocity: [0.6341675494, 0.903343641, 1.2364092608],
      direction: [-16.0897983094, 1.009837978, 25.9135249937],
      "speed factor": 1.4209335579,
      "Uz factor": 0.987329842,
      "Directional variance factor": 0.9102366242,
      "Velocity stability factor": 0.4871114772,
      "Direction stability factor": 0.8833241019,
      score: 0.7909990763,
    },
  },
  {
    position: [-72.776153, 0.817757, 48.16],
    "overall score": 0.3106984134,
    "0": {
      velocity: [0.2288202306, 0.8677760959, 1.422347606],
      direction: [-3.8477222767, 16.4140673662, 123.9719498287],
      "speed factor": 1.1047674686,
      "Uz factor": 0.9715083327,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2021783626,
      "Direction stability factor": 0.6449453553,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0445552885, 0.1224668364, 0.2870100243],
      direction: [-170.7920895816, 117.8373283734, 174.4285160879],
      "speed factor": 0.1314892693,
      "Uz factor": 0.8207744016,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6610934972,
      "Direction stability factor": 0.0410538731,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4985155841, 1.1628583469, 1.3941081037],
      direction: [-21.7450061779, -11.9449357871, 22.7059427034],
      "speed factor": 1.1134839614,
      "Uz factor": 0.995346779,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3607934269,
      "Direction stability factor": 0.876525142,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1148962468, 0.7698191767, 1.115088148],
      direction: [-65.3528994458, 20.6922215556, 50.8484696334],
      "speed factor": 0.8406858834,
      "Uz factor": 0.9684599947,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.263891891,
      "Direction stability factor": 0.6772184192,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4626684636, 1.1062391548, 1.7193908848],
      direction: [-64.9567926512, -26.6365061364, 3.7181141199],
      "speed factor": 1.1282159922,
      "Uz factor": 0.9958287878,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2434099979,
      "Direction stability factor": 0.8092363701,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6607390507, 0.9539168289, 1.257512222],
      direction: [-22.4353650572, 0.3383782966, 23.3169497192],
      "speed factor": 1.5730481438,
      "Uz factor": 0.9985158057,
      "Directional variance factor": 0.9699219292,
      "Velocity stability factor": 0.5562050267,
      "Direction stability factor": 0.8729102367,
      score: 0.8179868573,
    },
    "247.5": {
      velocity: [0.9535820998, 0.9781928558, 1.0003038208],
      direction: [-0.4405677288, 1.482083731, 2.5213120583],
      "speed factor": 1.1802006527,
      "Uz factor": 0.9944006412,
      "Directional variance factor": 0.8682592239,
      "Velocity stability factor": 0.9571668881,
      "Direction stability factor": 0.9917725561,
      score: 0.9522428061,
    },
    "292.5": {
      velocity: [1.1557259466, 1.229914712, 1.2689014798],
      direction: [-8.36195561, -5.065625462, -3.266560769],
      "speed factor": 1.4529993212,
      "Uz factor": 0.9718120324,
      "Directional variance factor": 0.5497221812,
      "Velocity stability factor": 0.8964261902,
      "Direction stability factor": 0.9858461254,
      score: 0.8544601556,
    },
    "337.5": {
      velocity: [0.2017157383, 1.0705138988, 1.387432466],
      direction: [-13.4415776745, 18.1446455949, 80.4149543247],
      "speed factor": 1.3821989302,
      "Uz factor": 0.9948796961,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2404543208,
      "Direction stability factor": 0.7392874111,
      score: 0.0,
    },
    id: "IDN:4572.0",
    "45": {
      velocity: [0.0375913018, 0.4336845057, 0.7888034607],
      direction: [-136.0380419388, -8.0048276348, 55.6015585786],
      "speed factor": 0.4067565467,
      "Uz factor": 0.9820368435,
      "Directional variance factor": 0.2884597658,
      "Velocity stability factor": 0.4052973045,
      "Direction stability factor": 0.4676677763,
      score: 0.0,
    },
    "90": {
      velocity: [0.1874876664, 0.3800287423, 0.6415066531],
      direction: [-37.6404748128, -10.1768572258, 24.5320771114],
      "speed factor": 0.9734733737,
      "Uz factor": 0.9998934626,
      "Directional variance factor": 0.0953904688,
      "Velocity stability factor": 0.3594747655,
      "Direction stability factor": 0.8272984669,
      score: 0.0,
    },
    "135": {
      velocity: [0.1799552953, 1.0323376965, 1.6676012295],
      direction: [-51.9452824823, 0.1246579373, 14.2474677741],
      "speed factor": 0.9393012805,
      "Uz factor": 0.9527545168,
      "Directional variance factor": 0.9889192945,
      "Velocity stability factor": 0.1467409878,
      "Direction stability factor": 0.8161312493,
      score: 0.0,
    },
    "180": {
      velocity: [0.0096970284, 0.4303004331, 0.9146472099],
      direction: [-167.2011015253, -43.0921749284, 164.5696384248],
      "speed factor": 0.6108417069,
      "Uz factor": 0.9970727075,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2729260589,
      "Direction stability factor": 0.0784146112,
      score: 0.0,
    },
    "225": {
      velocity: [0.4666885088, 0.5153675889, 0.5803885605],
      direction: [1.3282500908, 8.6056739381, 14.9813060859],
      "speed factor": 1.0343771862,
      "Uz factor": 0.9991187958,
      "Directional variance factor": 0.2350512055,
      "Velocity stability factor": 0.8359199849,
      "Direction stability factor": 0.9620748445,
      score: 0.7487802198,
    },
    "270": {
      velocity: [0.4926196914, 0.5387056764, 0.57260408],
      direction: [-12.2132452923, -6.2803254754, -2.8743472965],
      "speed factor": 1.0223390249,
      "Uz factor": 0.9986263458,
      "Directional variance factor": 0.4417488466,
      "Velocity stability factor": 0.8900075534,
      "Direction stability factor": 0.9740586167,
      score: 0.8199684083,
    },
    "315": {
      velocity: [0.6494396126, 0.9173326996, 1.2782166868],
      direction: [-17.5831517392, 0.990973105, 30.2035966694],
      "speed factor": 1.4429379446,
      "Uz factor": 0.9940678996,
      "Directional variance factor": 0.9119135018,
      "Velocity stability factor": 0.4645131038,
      "Direction stability factor": 0.8672590322,
      score: 0.7777361675,
    },
  },
  {
    position: [-74.276153, 2.317757, 48.16],
    "overall score": 0.3079217004,
    "0": {
      velocity: [0.4727001173, 1.076325071, 1.468525867],
      direction: [3.1411123253, 18.8333612411, 59.8391636025],
      "speed factor": 1.3702715823,
      "Uz factor": 0.9667286352,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3343333831,
      "Direction stability factor": 0.8425054131,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4975496287, 0.6813527303, 0.8885661124],
      direction: [1.9795834651, 13.0767103053, 23.7113571728],
      "speed factor": 0.7315496612,
      "Uz factor": 0.9183549558,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4534318804,
      "Direction stability factor": 0.9396339619,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.5961791949, 0.9752371926, 1.2397252612],
      direction: [-32.2488630775, -12.8797607669, -0.5555067074],
      "speed factor": 0.9338291077,
      "Uz factor": 0.9802749746,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.540685226,
      "Direction stability factor": 0.911962899,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.148205862, 0.6509224775, 1.120100962],
      direction: [-74.6813385327, 20.6429540815, 175.5222355286],
      "speed factor": 0.7108439937,
      "Uz factor": 0.9803248393,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2847173993,
      "Direction stability factor": 0.3049900721,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4443555988, 1.0428740499, 1.5266105566],
      direction: [-91.1035156902, -26.3859154737, -1.9294990969],
      "speed factor": 1.0635920595,
      "Uz factor": 0.9996029094,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3484453949,
      "Direction stability factor": 0.7522943984,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6148721234, 0.9420597328, 1.233296979],
      direction: [-25.1991834149, 0.2255475328, 21.9291490393],
      "speed factor": 1.5534953039,
      "Uz factor": 0.9998317469,
      "Directional variance factor": 0.9799513304,
      "Velocity stability factor": 0.5401035846,
      "Direction stability factor": 0.8690879654,
      score: 0.8145577115,
    },
    "247.5": {
      velocity: [0.9663663829, 0.9888534402, 1.008815582],
      direction: [-0.3941062964, 1.353613132, 2.3474763209],
      "speed factor": 1.1930627674,
      "Uz factor": 0.9877711332,
      "Directional variance factor": 0.8796788327,
      "Velocity stability factor": 0.9610838116,
      "Direction stability factor": 0.9923844927,
      score: 0.9563829074,
    },
    "292.5": {
      velocity: [1.1275307966, 1.1908782462, 1.2273531802],
      direction: [-9.8348600295, -6.5421171066, -4.7633411714],
      "speed factor": 1.4068823362,
      "Uz factor": 0.9689298384,
      "Directional variance factor": 0.4184784794,
      "Velocity stability factor": 0.9086464691,
      "Direction stability factor": 0.9859124476,
      score: 0.8247374609,
    },
    "337.5": {
      velocity: [0.6596030675, 1.1042742402, 1.4529421282],
      direction: [-14.8080741674, 17.9277395656, 50.6425317285],
      "speed factor": 1.4257887499,
      "Uz factor": 0.9897285532,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4918033612,
      "Direction stability factor": 0.8181927614,
      score: 0.0,
    },
    id: "IDN:4575.0",
    "45": {
      velocity: [0.1544723481, 0.5439885994, 1.036528565],
      direction: [-43.1034717178, -7.1438988315, 144.8815302236],
      "speed factor": 0.5102117351,
      "Uz factor": 0.9095566497,
      "Directional variance factor": 0.3649867705,
      "Velocity stability factor": 0.3017136324,
      "Direction stability factor": 0.4778194391,
      score: 0.0,
    },
    "90": {
      velocity: [0.1850475873, 0.3875002118, 0.6393993005],
      direction: [-41.419649501, -12.2017011917, 24.4140188355],
      "speed factor": 0.9926121277,
      "Uz factor": 0.9999882839,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3590053585,
      "Direction stability factor": 0.8171286991,
      score: 0.0,
    },
    "135": {
      velocity: [0.0890284348, 0.728874351, 1.5022422202],
      direction: [-109.0080333351, 0.5186493365, 39.6133648397],
      "speed factor": 0.6631866815,
      "Uz factor": 0.9639013035,
      "Directional variance factor": 0.9538978368,
      "Velocity stability factor": 0.1894325318,
      "Direction stability factor": 0.5871627828,
      score: 0.0,
    },
    "180": {
      velocity: [0.052325971, 0.4048824193, 0.8807301317],
      direction: [-173.1854205948, -36.7541575955, 146.1391271062],
      "speed factor": 0.5747590498,
      "Uz factor": 0.9999935809,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3344262588,
      "Direction stability factor": 0.1129873675,
      score: 0.0,
    },
    "225": {
      velocity: [0.4765133833, 0.5275581775, 0.5922974402],
      direction: [3.160101931, 10.2732801271, 15.7789894364],
      "speed factor": 1.058844512,
      "Uz factor": 0.9973554071,
      "Directional variance factor": 0.0868195443,
      "Velocity stability factor": 0.8329125668,
      "Direction stability factor": 0.9649475347,
      score: 0.7124067951,
    },
    "270": {
      velocity: [0.494162531, 0.539481179, 0.5756403362],
      direction: [-11.0379907225, -6.0327365044, -3.2860010983],
      "speed factor": 1.0238107497,
      "Uz factor": 0.9967720273,
      "Directional variance factor": 0.4637567552,
      "Velocity stability factor": 0.8879538459,
      "Direction stability factor": 0.9784666955,
      score: 0.827160998,
    },
    "315": {
      velocity: [0.6345203814, 0.891759813, 1.2013125739],
      direction: [-17.9362112052, 1.1021513608, 27.6626655352],
      "speed factor": 1.4027125297,
      "Uz factor": 0.9876882656,
      "Directional variance factor": 0.9020309902,
      "Velocity stability factor": 0.5173014342,
      "Direction stability factor": 0.8733364535,
      score: 0.7915013328,
    },
  },
  {
    position: [-71.276153, 0.817757, 48.16],
    "overall score": 0.3072489169,
    "0": {
      velocity: [0.1142464761, 0.723827051, 1.228916355],
      direction: [-3.9412530557, 14.5271424678, 178.6922275635],
      "speed factor": 0.921505654,
      "Uz factor": 0.9708254525,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2548912021,
      "Direction stability factor": 0.4926847761,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0536503056, 0.1715512139, 0.2799086862],
      direction: [-179.5022402106, -177.2661678571, 179.795226025],
      "speed factor": 0.1841898136,
      "Uz factor": 0.9977754054,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6837329812,
      "Direction stability factor": 0.0019514827,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2288335375, 1.1859182936, 1.3889445194],
      direction: [-18.1804263446, -11.3059855718, 45.6481057084],
      "speed factor": 1.1355647942,
      "Uz factor": 0.9961548873,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1720000458,
      "Direction stability factor": 0.8226985221,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0774714152, 0.8028847962, 1.118086471],
      direction: [-41.3433369816, 22.6255399944, 162.7533988584],
      "speed factor": 0.8767954015,
      "Uz factor": 0.9688478319,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2341417881,
      "Direction stability factor": 0.4330646227,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4129753159, 1.1271926655, 1.7196455527],
      direction: [-63.8348922287, -25.6943050935, 4.4787581489],
      "speed factor": 1.1495857708,
      "Uz factor": 0.995074439,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2133396997,
      "Direction stability factor": 0.8102398601,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6748826398, 0.9644657279, 1.266614144],
      direction: [-21.1629364338, 0.979096624, 24.5062115432],
      "speed factor": 1.5904437127,
      "Uz factor": 0.997363924,
      "Directional variance factor": 0.912969189,
      "Velocity stability factor": 0.5599543013,
      "Direction stability factor": 0.8731412556,
      score: 0.8048015004,
    },
    "247.5": {
      velocity: [0.9510846805, 0.976174272, 1.0004225543],
      direction: [-1.206649947, 0.7634062543, 1.9440053129],
      "speed factor": 1.1777652087,
      "Uz factor": 0.9970635928,
      "Directional variance factor": 0.9321416663,
      "Velocity stability factor": 0.9547684754,
      "Direction stability factor": 0.9912481798,
      score: 0.9673516253,
    },
    "292.5": {
      velocity: [1.1622047076, 1.2466589206, 1.287798377],
      direction: [-9.5973057068, -6.1673297356, -4.4043577974],
      "speed factor": 1.4727806308,
      "Uz factor": 0.9774163391,
      "Directional variance factor": 0.4517929124,
      "Velocity stability factor": 0.885061599,
      "Direction stability factor": 0.9855751447,
      score: 0.8270012002,
    },
    "337.5": {
      velocity: [0.1367464442, 1.0349365907, 1.4021018983],
      direction: [-153.7516719012, 18.3362475551, 104.3336069067],
      "speed factor": 1.3362631257,
      "Uz factor": 0.9917088753,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1894393954,
      "Direction stability factor": 0.2830964478,
      score: 0.0,
    },
    id: "IDN:4573.0",
    "45": {
      velocity: [0.228341861, 0.6745311779, 1.041656856],
      direction: [-30.5419972931, -5.5591307534, 20.4190263086],
      "speed factor": 0.632648778,
      "Uz factor": 0.9839476976,
      "Directional variance factor": 0.5058550441,
      "Velocity stability factor": 0.3561331322,
      "Direction stability factor": 0.8584416011,
      score: 0.0,
    },
    "90": {
      velocity: [0.1853223844, 0.3760551735, 0.6397585223],
      direction: [-37.1655642101, -9.6048489065, 23.939913913],
      "speed factor": 0.9632947662,
      "Uz factor": 0.9998326909,
      "Directional variance factor": 0.1462356528,
      "Velocity stability factor": 0.358886253,
      "Direction stability factor": 0.8302625608,
      score: 0.0,
    },
    "135": {
      velocity: [0.2275859811, 1.0620131333, 1.7524532178],
      direction: [-19.3705037479, 0.9957116709, 24.2815371862],
      "speed factor": 0.9663023053,
      "Uz factor": 0.9516941525,
      "Directional variance factor": 0.9114922959,
      "Velocity stability factor": 0.1253922172,
      "Direction stability factor": 0.8787443307,
      score: 0.0,
    },
    "180": {
      velocity: [0.0416836775, 0.4239708026, 0.8910611876],
      direction: [-173.3815292219, -43.7638060749, 55.7054790903],
      "speed factor": 0.6018563514,
      "Uz factor": 0.9934764663,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.317575413,
      "Direction stability factor": 0.3636471991,
      score: 0.0,
    },
    "225": {
      velocity: [0.4640759268, 0.5096901734, 0.5746465131],
      direction: [0.29647861, 7.596064259, 14.3167271222],
      "speed factor": 1.0229822338,
      "Uz factor": 0.9996701298,
      "Directional variance factor": 0.3247942881,
      "Velocity stability factor": 0.840436102,
      "Direction stability factor": 0.9610548652,
      score: 0.7718350301,
    },
    "270": {
      velocity: [0.4875133112, 0.5337945115, 0.5680745833],
      direction: [-13.4512498157, -6.9787538925, -3.0988396487],
      "speed factor": 1.0130187676,
      "Uz factor": 0.9994559391,
      "Directional variance factor": 0.3796663207,
      "Velocity stability factor": 0.8892142382,
      "Direction stability factor": 0.9712433051,
      score: 0.8028417923,
    },
    "315": {
      velocity: [0.6608009503, 0.9168090129, 1.2803968186],
      direction: [-18.0671329234, 2.4019816223, 34.1718464576],
      "speed factor": 1.4421142005,
      "Uz factor": 0.997703587,
      "Directional variance factor": 0.7864905225,
      "Velocity stability factor": 0.4723321158,
      "Direction stability factor": 0.8548917239,
      score: 0.7421515215,
    },
  },
  {
    position: [-72.776153, 2.317757, 48.16],
    "overall score": 0.304688113,
    "0": {
      velocity: [0.3023023212, 1.040882743, 1.442293523],
      direction: [3.6832185051, 17.5527554275, 61.5554169628],
      "speed factor": 1.3251498843,
      "Uz factor": 0.9588307694,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2379649885,
      "Direction stability factor": 0.8392438932,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3728979435, 0.5692458812, 0.794550793],
      direction: [2.4460524531, 10.7425890491, 23.2948043817],
      "speed factor": 0.6111836248,
      "Uz factor": 0.9087157517,
      "Directional variance factor": 0.0451031956,
      "Velocity stability factor": 0.4106079547,
      "Direction stability factor": 0.9420868002,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7341362132, 1.1683119853, 1.3605140457],
      direction: [-20.8339713977, -13.1951198367, -2.2680080916],
      "speed factor": 1.1187060409,
      "Uz factor": 0.9842359342,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5529386199,
      "Direction stability factor": 0.9484278797,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0798845222, 0.6869958463, 1.120302053],
      direction: [-160.0472290692, 21.5984956787, 164.788422055],
      "speed factor": 0.7502381434,
      "Uz factor": 0.9805053139,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.23428716,
      "Direction stability factor": 0.0976787469,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4542219315, 1.0717713179, 1.6349667504],
      direction: [-76.4041872236, -25.552069189, 4.4720914986],
      "speed factor": 1.0930634082,
      "Uz factor": 0.9991289059,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2891511205,
      "Direction stability factor": 0.7753436702,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6310438632, 0.9551137302, 1.26124588],
      direction: [-22.8447811971, 0.511730469, 23.1234129531],
      "speed factor": 1.5750218833,
      "Uz factor": 0.9996462288,
      "Directional variance factor": 0.9545128472,
      "Velocity stability factor": 0.5313454078,
      "Direction stability factor": 0.8723105718,
      score: 0.8076198496,
    },
    "247.5": {
      velocity: [0.9608832196, 0.9845714106, 1.0054458911],
      direction: [-0.3443545303, 1.5407416353, 2.4974070993],
      "speed factor": 1.1878964506,
      "Uz factor": 0.9927226773,
      "Directional variance factor": 0.863045188,
      "Velocity stability factor": 0.9591462416,
      "Direction stability factor": 0.9921062177,
      score: 0.9516009662,
    },
    "292.5": {
      velocity: [1.1601944113, 1.2282788729, 1.265424985],
      direction: [-10.4231868687, -7.1535327936, -5.4859860584],
      "speed factor": 1.45106677,
      "Uz factor": 0.9749349575,
      "Directional variance factor": 0.3641304183,
      "Velocity stability factor": 0.9036971056,
      "Direction stability factor": 0.9862855533,
      score: 0.8100996576,
    },
    "337.5": {
      velocity: [0.4711509859, 1.1152812816, 1.4183222648],
      direction: [-11.5465916897, 19.0555671811, 52.6331388343],
      "speed factor": 1.4400005419,
      "Uz factor": 0.9863096889,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3932616151,
      "Direction stability factor": 0.8217229708,
      score: 0.0,
    },
    id: "IDN:4576.0",
    "45": {
      velocity: [0.1384477071, 0.5381628495, 0.9011165398],
      direction: [-63.4374795191, -8.7443149167, 49.4860575416],
      "speed factor": 0.5047477125,
      "Uz factor": 0.9125009856,
      "Directional variance factor": 0.222727563,
      "Velocity stability factor": 0.3962275435,
      "Direction stability factor": 0.6863235082,
      score: 0.0,
    },
    "90": {
      velocity: [0.177405928, 0.3838262476, 0.6317232133],
      direction: [-42.2187979351, -11.8405745852, 23.7630274183],
      "speed factor": 0.9832009808,
      "Uz factor": 0.9999676101,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.359053929,
      "Direction stability factor": 0.8167171518,
      score: 0.0,
    },
    "135": {
      velocity: [0.0897904077, 0.7687837581, 1.4327218135],
      direction: [-60.0885977126, 2.0304234016, 21.1130813166],
      "speed factor": 0.6994993701,
      "Uz factor": 0.9603726329,
      "Directional variance factor": 0.8195179199,
      "Velocity stability factor": 0.2297439206,
      "Direction stability factor": 0.7744397805,
      score: 0.0,
    },
    "180": {
      velocity: [0.0474694888, 0.4079542889, 0.9577032793],
      direction: [-178.1578468782, -40.9021405779, 146.8757582268],
      "speed factor": 0.5791197845,
      "Uz factor": 0.9999470363,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2686809915,
      "Direction stability factor": 0.0971288747,
      score: 0.0,
    },
    "225": {
      velocity: [0.4728003003, 0.5231389109, 0.5888286324],
      direction: [2.2926181019, 9.7260873188, 15.8661157076],
      "speed factor": 1.0499747486,
      "Uz factor": 0.9992545574,
      "Directional variance factor": 0.135458905,
      "Velocity stability factor": 0.8325600543,
      "Direction stability factor": 0.96229584,
      score: 0.7231526598,
    },
    "270": {
      velocity: [0.4895470644, 0.5362047729, 0.5727510585],
      direction: [-11.6404308589, -6.1613798752, -3.181913434],
      "speed factor": 1.0175928874,
      "Uz factor": 0.9983708839,
      "Directional variance factor": 0.4523217889,
      "Velocity stability factor": 0.8855800357,
      "Direction stability factor": 0.9765041183,
      score: 0.8227275153,
    },
    "315": {
      velocity: [0.6457500737, 0.9059889915, 1.2444005955],
      direction: [-19.6675889275, 1.8309958087, 32.2044564265],
      "speed factor": 1.4250946182,
      "Uz factor": 0.9935740423,
      "Directional variance factor": 0.837244817,
      "Velocity stability factor": 0.4901698503,
      "Direction stability factor": 0.8559109851,
      score: 0.7598091594,
    },
  },
  {
    position: [-61.42847, 5.317757, 48.16],
    "overall score": 0.3034147301,
    "0": {
      velocity: [0.6462210349, 1.009120008, 1.306415924],
      direction: [-10.4631856074, 8.0232615471, 42.5279311054],
      "speed factor": 1.2847126834,
      "Uz factor": 0.9403123322,
      "Directional variance factor": 0.2868211958,
      "Velocity stability factor": 0.5586881556,
      "Direction stability factor": 0.8528024536,
      score: 0.0,
    },
    "22.5": {
      velocity: [1.205885826, 1.2170470598, 1.2319059793],
      direction: [5.8576647621, 6.7322340192, 7.6043356962],
      "speed factor": 1.306709909,
      "Uz factor": 0.900204017,
      "Directional variance factor": 0.4015791983,
      "Velocity stability factor": 0.9636286784,
      "Direction stability factor": 0.9951481363,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.2275875891, 1.2864049803, 1.3508636726],
      direction: [-8.3210271975, -5.6816521287, -1.9851675173],
      "speed factor": 1.2317848663,
      "Uz factor": 0.9539864514,
      "Directional variance factor": 0.4949642552,
      "Velocity stability factor": 0.9120148045,
      "Direction stability factor": 0.9824003898,
      score: 0.8429449598,
    },
    "112.5": {
      velocity: [0.1462906609, 0.809932105, 1.242121436],
      direction: [-115.2171032524, 25.2873547555, 60.5414284418],
      "speed factor": 0.8844914595,
      "Uz factor": 0.994459019,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1935048477,
      "Direction stability factor": 0.5117818564,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2724486624, 1.0017742795, 1.5371800155],
      direction: [-99.2486549883, -21.9150239279, 17.5550613538],
      "speed factor": 1.0216757903,
      "Uz factor": 0.9951589082,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2385883462,
      "Direction stability factor": 0.6755452324,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4634513804, 0.9282621793, 1.356323649],
      direction: [-14.6540760555, 7.5379184617, 36.6654311365],
      "speed factor": 1.5307425698,
      "Uz factor": 0.9976811323,
      "Directional variance factor": 0.3299628034,
      "Velocity stability factor": 0.3360086484,
      "Direction stability factor": 0.8574458134,
      score: 0.5952157696,
    },
    "247.5": {
      velocity: [0.9497243396, 0.9791911666, 1.0074083663],
      direction: [-1.9974373743, 0.1414849822, 2.9440582298],
      "speed factor": 1.1814051259,
      "Uz factor": 0.9999475729,
      "Directional variance factor": 0.9874235571,
      "Velocity stability factor": 0.9471169657,
      "Direction stability factor": 0.9862736233,
      score: 0.9767719424,
    },
    "292.5": {
      velocity: [1.1519526913, 1.2364824105, 1.2792261198],
      direction: [-5.9675326797, -2.8403159742, -0.4507210439],
      "speed factor": 1.4607582831,
      "Uz factor": 0.9976832317,
      "Directional variance factor": 0.747527469,
      "Velocity stability factor": 0.8835243493,
      "Direction stability factor": 0.9846755232,
      score: 0.9001007162,
    },
    "337.5": {
      velocity: [0.7283428324, 1.1155729995, 1.4261359343],
      direction: [-13.4069792724, 4.834694808, 35.0385214358],
      "speed factor": 1.4403771947,
      "Uz factor": 0.9430277648,
      "Directional variance factor": 0.5702493504,
      "Velocity stability factor": 0.5530081317,
      "Direction stability factor": 0.8654291647,
      score: 0.0,
    },
    id: "IDN:4606.0",
    "45": {
      velocity: [1.168288347, 1.221857682, 1.253852529],
      direction: [-0.6031433333, 5.0896426728, 9.9581260238],
      "speed factor": 1.1459911635,
      "Uz factor": 0.9239995134,
      "Directional variance factor": 0.547587318,
      "Velocity stability factor": 0.9322624786,
      "Direction stability factor": 0.9706631407,
      score: 0.0,
    },
    "90": {
      velocity: [0.1745973324, 0.374517833, 0.6124323012],
      direction: [-33.7746433578, -8.3442188234, 17.5562771493],
      "speed factor": 0.9593567481,
      "Uz factor": 0.9929325097,
      "Directional variance factor": 0.2582916601,
      "Velocity stability factor": 0.3823070086,
      "Direction stability factor": 0.8574141097,
      score: 0.0,
    },
    "135": {
      velocity: [0.2481577573, 0.8620552666, 1.4030935347],
      direction: [-134.4443873665, 7.816990013, 170.5613824273],
      "speed factor": 0.7843650567,
      "Uz factor": 0.9763714113,
      "Directional variance factor": 0.3051564433,
      "Velocity stability factor": 0.3375713011,
      "Direction stability factor": 0.1527617506,
      score: 0.0,
    },
    "180": {
      velocity: [0.0581171537, 0.3258700824, 0.73752959],
      direction: [-174.9010990749, -52.0703527419, 179.8712998997],
      "speed factor": 0.4625954844,
      "Uz factor": 0.8760984165,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4541322961,
      "Direction stability factor": 0.014521114,
      score: 0.0,
    },
    "225": {
      velocity: [0.4664414828, 0.5085786083, 0.5680803028],
      direction: [-0.9919039521, 6.8596092474, 13.725240498],
      "speed factor": 1.0207512484,
      "Uz factor": 0.9997089095,
      "Directional variance factor": 0.3902569558,
      "Velocity stability factor": 0.8533254923,
      "Direction stability factor": 0.9591190432,
      score: 0.7904551336,
    },
    "270": {
      velocity: [0.4751344243, 0.5168840623, 0.5607552675],
      direction: [-16.7128686241, -8.6858041469, -3.3038873198],
      "speed factor": 0.9809266384,
      "Uz factor": 0.9999143312,
      "Directional variance factor": 0.2279285203,
      "Velocity stability factor": 0.8822564481,
      "Direction stability factor": 0.9627528297,
      score: 0.0,
    },
    "315": {
      velocity: [0.620691761, 0.8545499237, 1.1755290812],
      direction: [-17.8337024792, 3.0471374376, 28.973009821],
      "speed factor": 1.3441824445,
      "Uz factor": 0.9823094832,
      "Directional variance factor": 0.7291433389,
      "Velocity stability factor": 0.5274825901,
      "Direction stability factor": 0.8699813547,
      score: 0.7491471596,
    },
  },
  {
    position: [-62.92847, 5.317757, 48.16],
    "overall score": 0.3024821845,
    "0": {
      velocity: [0.6093188752, 1.015605631, 1.315027984],
      direction: [-9.3542576793, 7.5270060746, 42.057197756],
      "speed factor": 1.2929695429,
      "Uz factor": 0.9326295859,
      "Directional variance factor": 0.3309327934,
      "Velocity stability factor": 0.5282638603,
      "Direction stability factor": 0.8571904016,
      score: 0.0,
    },
    "22.5": {
      velocity: [1.2265213595, 1.2415569155, 1.2594966753],
      direction: [5.0104299071, 5.9125193427, 6.7426032498],
      "speed factor": 1.333025466,
      "Uz factor": 0.8952948722,
      "Directional variance factor": 0.4744427251,
      "Velocity stability factor": 0.9539066584,
      "Direction stability factor": 0.9951884074,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.2416742226, 1.3072562658, 1.3783165118],
      direction: [-7.9983040548, -5.503055566, -1.8390353308],
      "speed factor": 1.2517508165,
      "Uz factor": 0.9607330596,
      "Directional variance factor": 0.5108395052,
      "Velocity stability factor": 0.9024750123,
      "Direction stability factor": 0.9828909202,
      score: 0.8447740895,
    },
    "112.5": {
      velocity: [0.0479598402, 0.8068304807, 1.210918712],
      direction: [-124.957462437, 24.0875080836, 60.8133244283],
      "speed factor": 0.8811043111,
      "Uz factor": 0.9947108071,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1441007921,
      "Direction stability factor": 0.4839700365,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3053387123, 0.9964104129, 1.5194675757],
      direction: [-114.0996802494, -23.4228557221, 11.8000654083],
      "speed factor": 1.0162053637,
      "Uz factor": 0.9967406716,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2690527806,
      "Direction stability factor": 0.6502784843,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4353502977, 0.9273428628, 1.311917974],
      direction: [-14.9258699124, 6.3795940452, 32.1304123014],
      "speed factor": 1.529226579,
      "Uz factor": 0.9982654466,
      "Directional variance factor": 0.4329249738,
      "Velocity stability factor": 0.3481336842,
      "Direction stability factor": 0.869288105,
      score: 0.629908717,
    },
    "247.5": {
      velocity: [0.9472409895, 0.9715522546, 1.0008698835],
      direction: [-1.524394282, 0.7147400643, 3.6626256814],
      "speed factor": 1.1721886929,
      "Uz factor": 0.9998294186,
      "Directional variance factor": 0.9364675498,
      "Velocity stability factor": 0.9508345931,
      "Direction stability factor": 0.9855916112,
      score: 0.9646213413,
    },
    "292.5": {
      velocity: [1.1572085573, 1.2353193581, 1.2806352752],
      direction: [-6.9865639365, -3.4436015754, -1.0477566005],
      "speed factor": 1.4593842738,
      "Uz factor": 0.9976237029,
      "Directional variance factor": 0.6939020822,
      "Velocity stability factor": 0.887044708,
      "Direction stability factor": 0.983503313,
      score: 0.886988354,
    },
    "337.5": {
      velocity: [0.741201702, 1.1179385137, 1.4275971365],
      direction: [-12.6849097298, 6.0336602775, 37.4563239881],
      "speed factor": 1.4434314393,
      "Uz factor": 0.9318265562,
      "Directional variance factor": 0.463674642,
      "Velocity stability factor": 0.5603092423,
      "Direction stability factor": 0.8607187952,
      score: 0.0,
    },
    id: "IDN:4605.0",
    "45": {
      velocity: [1.18309887, 1.239877621, 1.269649851],
      direction: [-0.8945614605, 4.5287704975, 9.4851743203],
      "speed factor": 1.1628922242,
      "Uz factor": 0.9328074116,
      "Directional variance factor": 0.5974426224,
      "Velocity stability factor": 0.9314812719,
      "Direction stability factor": 0.9711674006,
      score: 0.0,
    },
    "90": {
      velocity: [0.1855684793, 0.3824566595, 0.6140080953],
      direction: [-35.6321771973, -9.12874399, 20.7935747659],
      "speed factor": 0.9796926737,
      "Uz factor": 0.9960780372,
      "Directional variance factor": 0.1885560898,
      "Velocity stability factor": 0.395561874,
      "Direction stability factor": 0.8432618001,
      score: 0.0,
    },
    "135": {
      velocity: [0.2161051098, 0.8755384536, 1.4829176372],
      direction: [-120.0435883159, 5.6002110507, 147.9161992464],
      "speed factor": 0.7966331108,
      "Uz factor": 0.9781332377,
      "Directional variance factor": 0.5022034622,
      "Velocity stability factor": 0.2734029107,
      "Direction stability factor": 0.2556672568,
      score: 0.0,
    },
    "180": {
      velocity: [0.0722760874, 0.3324615068, 0.9971376743],
      direction: [-175.4718998363, -50.5563150241, 178.9159773565],
      "speed factor": 0.4719524747,
      "Uz factor": 0.8994865288,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2569284224,
      "Direction stability factor": 0.01558923,
      score: 0.0,
    },
    "225": {
      velocity: [0.4698851448, 0.5125705224, 0.574708052],
      direction: [-0.6527786281, 7.217809231, 14.0255456288],
      "speed factor": 1.0287632868,
      "Uz factor": 0.9998627206,
      "Directional variance factor": 0.3584169572,
      "Velocity stability factor": 0.8487305508,
      "Direction stability factor": 0.9592268771,
      score: 0.7814003155,
    },
    "270": {
      velocity: [0.4732494483, 0.5163410495, 0.5595004193],
      direction: [-16.153924319, -8.3459812915, -3.4199788541],
      "speed factor": 0.9798961255,
      "Uz factor": 0.9998034364,
      "Directional variance factor": 0.2581349963,
      "Velocity stability factor": 0.8813899126,
      "Direction stability factor": 0.9646279293,
      score: 0.0,
    },
    "315": {
      velocity: [0.623837735, 0.8593381174, 1.1799262227],
      direction: [-16.2686670812, 3.8571362657, 29.7162853431],
      "speed factor": 1.3517141353,
      "Uz factor": 0.9807458085,
      "Directional variance factor": 0.657143443,
      "Velocity stability factor": 0.5264170554,
      "Direction stability factor": 0.872264021,
      score: 0.7320221351,
    },
  },
  {
    position: [-64.42847, 5.317757, 48.16],
    "overall score": 0.3009337017,
    "0": {
      velocity: [0.6875769586, 1.025991557, 1.322737378],
      direction: [-8.1038021777, 7.888758865, 48.3523175369],
      "speed factor": 1.3061918859,
      "Uz factor": 0.9250154796,
      "Directional variance factor": 0.2987769898,
      "Velocity stability factor": 0.5754226202,
      "Direction stability factor": 0.8431774452,
      score: 0.0,
    },
    "22.5": {
      velocity: [1.2308577726, 1.2480209687, 1.2710611294],
      direction: [5.8860291115, 6.5624323721, 7.2000464108],
      "speed factor": 1.3399657419,
      "Uz factor": 0.8802295256,
      "Directional variance factor": 0.416672678,
      "Velocity stability factor": 0.9438032051,
      "Direction stability factor": 0.9963499519,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.2461808804, 1.3226912451, 1.3977305338],
      direction: [-7.9601815371, -5.5668723529, -1.4812846973],
      "speed factor": 1.2665304344,
      "Uz factor": 0.9651755014,
      "Directional variance factor": 0.505166902,
      "Velocity stability factor": 0.8918352571,
      "Direction stability factor": 0.9820030643,
      score: 0.8402520719,
    },
    "112.5": {
      velocity: [0.0641726231, 0.8132221198, 1.201006946],
      direction: [-97.0991423766, 23.0915110373, 140.1388758123],
      "speed factor": 0.8880843409,
      "Uz factor": 0.9953384155,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1633275947,
      "Direction stability factor": 0.341005505,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2244595634, 0.9998224815, 1.4993821034],
      direction: [-121.9719141663, -24.5598060437, 11.4361945508],
      "speed factor": 1.0196852173,
      "Uz factor": 0.9981847447,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2324529021,
      "Direction stability factor": 0.6294219202,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3647138096, 0.9354496354, 1.284159308],
      direction: [-16.4700557361, 4.8324632929, 28.2783356807],
      "speed factor": 1.542594981,
      "Uz factor": 0.9987704393,
      "Directional variance factor": 0.5704477073,
      "Velocity stability factor": 0.316247261,
      "Direction stability factor": 0.8756989127,
      score: 0.6595231984,
    },
    "247.5": {
      velocity: [0.9385500535, 0.9695198879, 0.9950780228],
      direction: [-1.5372434164, 1.1907246374, 3.7621147041],
      "speed factor": 1.1697366196,
      "Uz factor": 0.9996619073,
      "Directional variance factor": 0.89415781,
      "Velocity stability factor": 0.9481768054,
      "Direction stability factor": 0.9852795608,
      score: 0.9532234342,
    },
    "292.5": {
      velocity: [1.164010685, 1.2349900187, 1.2782265043],
      direction: [-8.6470271, -4.1583537527, -1.8520470932],
      "speed factor": 1.458995198,
      "Uz factor": 0.9975452322,
      "Directional variance factor": 0.6303685553,
      "Velocity stability factor": 0.8954741612,
      "Direction stability factor": 0.9811250555,
      score: 0.8720232069,
    },
    "337.5": {
      velocity: [0.7455729803, 1.1126809101, 1.4208257238],
      direction: [-11.197103175, 7.7375108949, 40.3708868522],
      "speed factor": 1.4366430602,
      "Uz factor": 0.9245426593,
      "Directional variance factor": 0.3122212538,
      "Velocity stability factor": 0.5674470203,
      "Direction stability factor": 0.8567555833,
      score: 0.0,
    },
    id: "IDN:4604.0",
    "45": {
      velocity: [1.195762412, 1.253089981, 1.28812284],
      direction: [-1.597188502, 3.601305149, 8.0091455006],
      "speed factor": 1.1752842139,
      "Uz factor": 0.9342853442,
      "Directional variance factor": 0.6798839868,
      "Velocity stability factor": 0.9268821799,
      "Direction stability factor": 0.9733157389,
      score: 0.0,
    },
    "90": {
      velocity: [0.1949501788, 0.3861002059, 0.6135780912],
      direction: [-38.4463941068, -9.9752622769, 18.0653416417],
      "speed factor": 0.989025903,
      "Uz factor": 0.9976076106,
      "Directional variance factor": 0.1133100198,
      "Velocity stability factor": 0.4094041227,
      "Direction stability factor": 0.8430229563,
      score: 0.0,
    },
    "135": {
      velocity: [0.0993584577, 0.8622270465, 1.5351826999],
      direction: [-160.4853062379, 3.9625388364, 149.9638116092],
      "speed factor": 0.7845213553,
      "Uz factor": 0.9805222265,
      "Directional variance factor": 0.6477743257,
      "Velocity stability factor": 0.1764640051,
      "Direction stability factor": 0.1376413393,
      score: 0.0,
    },
    "180": {
      velocity: [0.0879294027, 0.3302874132, 0.9829844257],
      direction: [-165.7373737495, -49.0865822612, 178.7935276662],
      "speed factor": 0.468866196,
      "Uz factor": 0.9268094708,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2808762334,
      "Direction stability factor": 0.0429697183,
      score: 0.0,
    },
    "225": {
      velocity: [0.4738422293, 0.5168318349, 0.582102614],
      direction: [-0.139746253, 7.657975619, 14.5201745014],
      "speed factor": 1.0373160257,
      "Uz factor": 0.9999506348,
      "Directional variance factor": 0.3192910561,
      "Velocity stability factor": 0.8437699432,
      "Direction stability factor": 0.9592779979,
      score: 0.7704042488,
    },
    "270": {
      velocity: [0.4736001806, 0.517003993, 0.5593712187],
      direction: [-15.4359042033, -8.0187508984, -3.3489446297],
      "speed factor": 0.9811542393,
      "Uz factor": 0.9997543234,
      "Directional variance factor": 0.2872221424,
      "Velocity stability factor": 0.8820499038,
      "Direction stability factor": 0.9664251123,
      score: 0.0,
    },
    "315": {
      velocity: [0.6289121866, 0.8648991022, 1.1794769343],
      direction: [-15.3350647905, 4.4688326356, 30.716029844],
      "speed factor": 1.3604614044,
      "Uz factor": 0.9802387405,
      "Directional variance factor": 0.6027704324,
      "Velocity stability factor": 0.5311212511,
      "Direction stability factor": 0.8720802927,
      score: 0.7195130672,
    },
  },
  {
    position: [-71.276153, 2.317757, 48.16],
    "overall score": 0.300752313,
    "0": {
      velocity: [0.1845776871, 0.951888097, 1.396647213],
      direction: [0.3616902429, 15.7048183012, 77.8098865793],
      "speed factor": 1.211850624,
      "Uz factor": 0.9533822194,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1897837338,
      "Direction stability factor": 0.7848661213,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1537562246, 0.296314963, 0.4992170593],
      direction: [-5.0338248189, 4.1151824117, 22.0561123449],
      "speed factor": 0.3181452149,
      "Uz factor": 0.8214769671,
      "Directional variance factor": 0.6342060079,
      "Velocity stability factor": 0.5171101816,
      "Direction stability factor": 0.9247501745,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.755960725, 1.2701554947, 1.3929578999],
      direction: [-17.6611912825, -12.1708735919, 0.1046026211],
      "speed factor": 1.2162253257,
      "Uz factor": 0.9879723101,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5453593321,
      "Direction stability factor": 0.9506505725,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1250180284, 0.7390411938, 1.111039153],
      direction: [-69.4651349544, 23.2087680543, 103.9891331206],
      "speed factor": 0.8070745932,
      "Uz factor": 0.981653599,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2743211131,
      "Direction stability factor": 0.5181825887,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4208380803, 1.0961277817, 1.6822691498],
      direction: [-69.6881265276, -24.8604404345, 6.4008121785],
      "speed factor": 1.1179037439,
      "Uz factor": 0.9986238075,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2405752301,
      "Direction stability factor": 0.7886418369,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6441756777, 0.9665906656, 1.301847546],
      direction: [-21.4043402839, 0.830961945, 24.2674830029],
      "speed factor": 1.5939478225,
      "Uz factor": 0.9991520823,
      "Directional variance factor": 0.926136716,
      "Velocity stability factor": 0.5109172408,
      "Direction stability factor": 0.8731338242,
      score: 0.7958304013,
    },
    "247.5": {
      velocity: [0.954115384, 0.9796867437, 1.0021920437],
      direction: [-0.7091916201, 1.2333265504, 2.2638028324],
      "speed factor": 1.1820030452,
      "Uz factor": 0.9960713069,
      "Directional variance factor": 0.8903709733,
      "Velocity stability factor": 0.9559247197,
      "Direction stability factor": 0.9917416821,
      score: 0.9574447643,
    },
    "292.5": {
      velocity: [1.1760619564, 1.252130253, 1.2899932781],
      direction: [-11.4272967936, -8.1365391816, -6.3603657905],
      "speed factor": 1.4792443654,
      "Uz factor": 0.9814500078,
      "Directional variance factor": 0.2767520727,
      "Velocity stability factor": 0.8957345222,
      "Direction stability factor": 0.9859251917,
      score: 0.7860842446,
    },
    "337.5": {
      velocity: [0.2133449123, 1.1068780409, 1.3913402223],
      direction: [-7.3981613024, 19.1930636899, 77.5597775827],
      "speed factor": 1.429150659,
      "Uz factor": 0.9804705275,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2454005017,
      "Direction stability factor": 0.7640057253,
      score: 0.0,
    },
    id: "IDN:4577.0",
    "45": {
      velocity: [0.3462498934, 0.7306728613, 1.010764716],
      direction: [-26.3078739591, -6.8222899859, 8.0388092001],
      "speed factor": 0.6853045611,
      "Uz factor": 0.9420882551,
      "Directional variance factor": 0.3935742235,
      "Velocity stability factor": 0.473931896,
      "Direction stability factor": 0.9045925468,
      score: 0.0,
    },
    "90": {
      velocity: [0.1732189395, 0.3810540093, 0.6267456537],
      direction: [-42.2331598547, -11.2984305022, 23.0456788032],
      "speed factor": 0.9760996754,
      "Uz factor": 0.999940877,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3601692584,
      "Direction stability factor": 0.8186698926,
      score: 0.0,
    },
    "135": {
      velocity: [0.1038286644, 0.8428440202, 1.593783118],
      direction: [-38.7411574684, 3.4561420136, 20.5999843753],
      "speed factor": 0.7668851676,
      "Uz factor": 0.9607372869,
      "Directional variance factor": 0.6927873766,
      "Velocity stability factor": 0.145416906,
      "Direction stability factor": 0.8351634949,
      score: 0.0,
    },
    "180": {
      velocity: [0.0418748488, 0.3976583904, 0.9304720252],
      direction: [-170.1407784819, -42.6958901253, 176.3870528135],
      "speed factor": 0.5645040331,
      "Uz factor": 0.9985738158,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2860647311,
      "Direction stability factor": 0.0374226908,
      score: 0.0,
    },
    "225": {
      velocity: [0.469330108, 0.5176288066, 0.5837137737],
      direction: [1.2872785516, 8.7849662654, 15.3280781968],
      "speed factor": 1.0389156012,
      "Uz factor": 0.9998058906,
      "Directional variance factor": 0.2191141097,
      "Velocity stability factor": 0.8349334649,
      "Direction stability factor": 0.9609977788,
      score: 0.7440107831,
    },
    "270": {
      velocity: [0.4845259079, 0.5314597304, 0.5683460205],
      direction: [-12.4277913706, -6.4102518194, -3.1138765712],
      "speed factor": 1.008587892,
      "Uz factor": 0.9992672448,
      "Directional variance factor": 0.4301998383,
      "Velocity stability factor": 0.8847327657,
      "Direction stability factor": 0.9741280144,
      score: 0.8157971582,
    },
    "315": {
      velocity: [0.6551481332, 0.9101609901, 1.2799238488],
      direction: [-20.6965751114, 3.4184526602, 35.567772221],
      "speed factor": 1.4316570519,
      "Uz factor": 0.9962496685,
      "Directional variance factor": 0.6961375413,
      "Velocity stability factor": 0.4679207903,
      "Direction stability factor": 0.8437101463,
      score: 0.712869656,
    },
  },
  {
    position: [-64.42847, 3.817757, 48.16],
    "overall score": 0.3000124564,
    "0": {
      velocity: [0.4979543112, 1.060344731, 1.418239236],
      direction: [-10.2657846321, 6.9185327284, 47.7139979365],
      "speed factor": 1.3499269798,
      "Uz factor": 0.9413256489,
      "Directional variance factor": 0.385019313,
      "Velocity stability factor": 0.3848291705,
      "Direction stability factor": 0.8389450484,
      score: 0.0,
    },
    "22.5": {
      velocity: [1.1426258934, 1.1840122689, 1.2287028739],
      direction: [1.7761641556, 2.9663947831, 3.8656204016],
      "speed factor": 1.2712413638,
      "Uz factor": 0.9035146472,
      "Directional variance factor": 0.7363204637,
      "Velocity stability factor": 0.8796804346,
      "Direction stability factor": 0.9941959549,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.259177659, 1.3562854757, 1.439132572],
      direction: [-8.1539633044, -5.1099260694, -0.7692809806],
      "speed factor": 1.2986982707,
      "Uz factor": 0.96797713,
      "Directional variance factor": 0.5457843494,
      "Velocity stability factor": 0.8715617195,
      "Direction stability factor": 0.9794869935,
      score: 0.844080014,
    },
    "112.5": {
      velocity: [0.0873727525, 0.8600468719, 1.244655408],
      direction: [-57.3823693525, 24.057979001, 66.4820173853],
      "speed factor": 0.9392196065,
      "Uz factor": 0.993705081,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1482782992,
      "Direction stability factor": 0.6559322591,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4128181492, 1.0599729217, 1.6067152637],
      direction: [-93.8980147171, -24.5718184162, 6.0553588913],
      "speed factor": 1.081030622,
      "Uz factor": 0.9972028285,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2812329874,
      "Direction stability factor": 0.72235174,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5972308228, 0.9715482281, 1.318348827],
      direction: [-17.2079759641, 5.3262282632, 29.0904826705],
      "speed factor": 1.6021230473,
      "Uz factor": 0.9967932332,
      "Directional variance factor": 0.5265574877,
      "Velocity stability factor": 0.4637350323,
      "Direction stability factor": 0.8713931705,
      score: 0.6832697152,
    },
    "247.5": {
      velocity: [0.9402618282, 0.9685549641, 0.9934259259],
      direction: [-2.0443679266, 0.1930107808, 2.5147455164],
      "speed factor": 1.1685724283,
      "Uz factor": 0.9997627051,
      "Directional variance factor": 0.9828434861,
      "Velocity stability factor": 0.9512607048,
      "Direction stability factor": 0.987335796,
      score: 0.9771939457,
    },
    "292.5": {
      velocity: [1.0671385918, 1.2292455084, 1.2844258454],
      direction: [-9.1825013656, -4.6876639943, -2.1632727733],
      "speed factor": 1.4522087359,
      "Uz factor": 0.9987339919,
      "Directional variance factor": 0.5833187561,
      "Velocity stability factor": 0.8011472265,
      "Direction stability factor": 0.9805021428,
      score: 0.836367567,
    },
    "337.5": {
      velocity: [0.4918727482, 1.1281531051, 1.4356244626],
      direction: [-8.3056052827, 9.028836151, 40.7146910264],
      "speed factor": 1.4566200557,
      "Uz factor": 0.9422221829,
      "Directional variance factor": 0.1974367866,
      "Velocity stability factor": 0.3954521176,
      "Direction stability factor": 0.8638325103,
      score: 0.0,
    },
    id: "IDN:4600.0",
    "45": {
      velocity: [1.226085031, 1.293387447, 1.332297449],
      direction: [-2.356907975, 4.2538350661, 10.3798432193],
      "speed factor": 1.2130795649,
      "Uz factor": 0.9492920583,
      "Directional variance factor": 0.6218813275,
      "Velocity stability factor": 0.915916149,
      "Direction stability factor": 0.9646201356,
      score: 0.0,
    },
    "90": {
      velocity: [0.1769757605, 0.3786780419, 0.6175207326],
      direction: [-36.0422920173, -9.5252755123, 18.3974942018],
      "speed factor": 0.9700134489,
      "Uz factor": 0.9985567487,
      "Directional variance factor": 0.1533088433,
      "Velocity stability factor": 0.3784837643,
      "Direction stability factor": 0.8487783716,
      score: 0.0,
    },
    "135": {
      velocity: [0.2253553562, 1.0193525518, 1.6807280899],
      direction: [-111.2484455286, 5.3321930304, 147.0899620319],
      "speed factor": 0.927486384,
      "Uz factor": 0.974487948,
      "Directional variance factor": 0.5260272862,
      "Velocity stability factor": 0.165251709,
      "Direction stability factor": 0.2823933123,
      score: 0.0,
    },
    "180": {
      velocity: [0.0705957488, 0.34634792, 1.0094485609],
      direction: [-178.3123409093, -51.0452472837, 152.1994145946],
      "speed factor": 0.4916652141,
      "Uz factor": 0.9788025642,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2456873006,
      "Direction stability factor": 0.0819117903,
      score: 0.0,
    },
    "225": {
      velocity: [0.4659711255, 0.5065639986, 0.5688824826],
      direction: [-0.7926719577, 6.7566284876, 13.6435113052],
      "speed factor": 1.0167077922,
      "Uz factor": 0.999983303,
      "Directional variance factor": 0.3994108011,
      "Velocity stability factor": 0.8514891,
      "Direction stability factor": 0.9598994909,
      score: 0.7926747208,
    },
    "270": {
      velocity: [0.4722436273, 0.5173318192, 0.557834786],
      direction: [-16.054754502, -8.1110909611, -3.129669266],
      "speed factor": 0.9817763777,
      "Uz factor": 0.999891087,
      "Directional variance factor": 0.2790141368,
      "Velocity stability factor": 0.8822972693,
      "Direction stability factor": 0.9640969855,
      score: 0.0,
    },
    "315": {
      velocity: [0.6362265121, 0.8843490779, 1.1923672143],
      direction: [-16.5960069692, 6.6341279908, 32.043406835],
      "speed factor": 1.3910556566,
      "Uz factor": 0.9881669774,
      "Directional variance factor": 0.4102997342,
      "Velocity stability factor": 0.5263725879,
      "Direction stability factor": 0.8648905172,
      score: 0.6666133391,
    },
  },
  {
    position: [-69.776153, -0.682243, 48.16],
    "overall score": 0.2997475289,
    "0": {
      velocity: [0.023406471, 0.3771526262, 1.21659001],
      direction: [-173.5460771042, 18.2320127288, 178.9059395505],
      "speed factor": 0.4801537563,
      "Uz factor": 0.9821833632,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2024082025,
      "Direction stability factor": 0.0209666204,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0230516334, 0.0899247574, 0.2261160356],
      direction: [-176.364087931, -35.100516202, 162.51422173],
      "speed factor": 0.0965497353,
      "Uz factor": 0.9495254139,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7161538372,
      "Direction stability factor": 0.0586713621,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0510221419, 0.69733239, 1.19994755],
      direction: [-33.0311020101, -9.8647070451, 74.8250244992],
      "speed factor": 0.6677240044,
      "Uz factor": 0.9973457965,
      "Directional variance factor": 0.1231371515,
      "Velocity stability factor": 0.1799834669,
      "Direction stability factor": 0.7003996486,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2600622484, 0.8780852274, 1.195228859],
      direction: [-44.4558525959, 21.7041194823, 78.4768721762],
      "speed factor": 0.9589185063,
      "Uz factor": 0.9607292593,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3117483509,
      "Direction stability factor": 0.658520209,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4699619967, 1.1595413527, 1.6485944703],
      direction: [-54.8237146808, -26.6384880848, -0.7760518583],
      "speed factor": 1.1825771055,
      "Uz factor": 0.9896602059,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2904228247,
      "Direction stability factor": 0.8498676033,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.7002160738, 0.9649683412, 1.276285466],
      direction: [-18.5765638181, 2.7514903648, 26.1151578182],
      "speed factor": 1.5912725428,
      "Uz factor": 0.9922142034,
      "Directional variance factor": 0.7554230787,
      "Velocity stability factor": 0.5716015517,
      "Direction stability factor": 0.8758563288,
      score: 0.769684322,
    },
    "247.5": {
      velocity: [0.937952537, 0.9631228216, 0.9903874069],
      direction: [-3.1134020007, -1.1997902607, 0.1553828668],
      "speed factor": 1.1620184875,
      "Uz factor": 0.9981939364,
      "Directional variance factor": 0.8933519768,
      "Velocity stability factor": 0.9519292396,
      "Direction stability factor": 0.990920042,
      score: 0.9567803251,
    },
    "292.5": {
      velocity: [1.0683014044, 1.1730266542, 1.2186472517],
      direction: [-8.3197288251, -4.5757903667, -2.3298537448],
      "speed factor": 1.3857927836,
      "Uz factor": 0.9823099759,
      "Directional variance factor": 0.5932630785,
      "Velocity stability factor": 0.8624093764,
      "Direction stability factor": 0.9833614581,
      score: 0.8555988428,
    },
    "337.5": {
      velocity: [0.1181099371, 0.8671017339, 1.3365323474],
      direction: [-142.1582490267, 16.6204297454, 158.1739782842],
      "speed factor": 1.1195623806,
      "Uz factor": 0.9949009253,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2195037352,
      "Direction stability factor": 0.165743813,
      score: 0.0,
    },
    id: "IDN:4570.0",
    "45": {
      velocity: [0.5315306422, 0.9172308192, 1.218134153],
      direction: [-10.1978424279, 2.1857605147, 16.6375316706],
      "speed factor": 0.8602789254,
      "Uz factor": 0.9962698555,
      "Directional variance factor": 0.8057101765,
      "Velocity stability factor": 0.4564452216,
      "Direction stability factor": 0.9254572942,
      score: 0.0,
    },
    "90": {
      velocity: [0.1941375114, 0.3704905926, 0.6498714147],
      direction: [-31.5840785031, -7.4743586955, 32.261030397],
      "speed factor": 0.9490406566,
      "Uz factor": 0.9998723716,
      "Directional variance factor": 0.3356125604,
      "Velocity stability factor": 0.3570553791,
      "Direction stability factor": 0.8226524753,
      score: 0.0,
    },
    "135": {
      velocity: [0.4479839068, 1.2214855669, 1.7552958636],
      direction: [-14.9187034117, -0.9938061076, 12.1325278807],
      "speed factor": 1.1114027522,
      "Uz factor": 0.950624133,
      "Directional variance factor": 0.9116616793,
      "Velocity stability factor": 0.250173927,
      "Direction stability factor": 0.9248576909,
      score: 0.752887747,
    },
    "180": {
      velocity: [0.043501088, 0.4478111565, 0.8605802303],
      direction: [-178.3846538683, -46.1655469046, 54.98955549],
      "speed factor": 0.6356994094,
      "Uz factor": 0.9754749459,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.343525241,
      "Direction stability factor": 0.3517383073,
      score: 0.0,
    },
    "225": {
      velocity: [0.4561860744, 0.4958870548, 0.5580566165],
      direction: [-1.5506938923, 5.8939502962, 13.1458935953],
      "speed factor": 0.9952784525,
      "Uz factor": 0.9991567509,
      "Directional variance factor": 0.476093307,
      "Velocity stability factor": 0.8529910952,
      "Direction stability factor": 0.9591761459,
      score: 0.0,
    },
    "270": {
      velocity: [0.4859728535, 0.5306204582, 0.5634851559],
      direction: [-15.395623026, -8.2075973404, -3.4662991563],
      "speed factor": 1.0069951471,
      "Uz factor": 0.99951597,
      "Directional variance factor": 0.270435792,
      "Velocity stability factor": 0.8934071019,
      "Direction stability factor": 0.9668629893,
      score: 0.7743922181,
    },
    "315": {
      velocity: [0.4479394952, 0.8969766905, 1.2184183861],
      direction: [-21.0814432653, 3.2809403821, 33.9496113952],
      "speed factor": 1.410918528,
      "Uz factor": 0.9998101788,
      "Directional variance factor": 0.7083608549,
      "Velocity stability factor": 0.3438352529,
      "Direction stability factor": 0.8471359593,
      score: 0.6866170066,
    },
  },
  {
    position: [-62.92847, 3.817757, 48.16],
    "overall score": 0.2995506074,
    "0": {
      velocity: [0.5468488694, 1.049229522, 1.411639907],
      direction: [-12.1108610851, 6.9609319813, 42.3160517263],
      "speed factor": 1.3357761852,
      "Uz factor": 0.9475975489,
      "Directional variance factor": 0.3812504905,
      "Velocity stability factor": 0.4219244436,
      "Direction stability factor": 0.8488141311,
      score: 0.0,
    },
    "22.5": {
      velocity: [1.2444755031, 1.2662928488, 1.2921587121],
      direction: [2.5509751394, 3.6885000664, 4.9367899887],
      "speed factor": 1.3595837563,
      "Uz factor": 0.9175452488,
      "Directional variance factor": 0.6721333274,
      "Velocity stability factor": 0.9333477668,
      "Direction stability factor": 0.9933727365,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.254127909, 1.3319586636, 1.4150798144],
      direction: [-9.2831094183, -6.36574952, -1.9311419408],
      "speed factor": 1.2754043629,
      "Uz factor": 0.9572412962,
      "Directional variance factor": 0.4341555982,
      "Velocity stability factor": 0.8851246369,
      "Direction stability factor": 0.9795778681,
      score: 0.8196089928,
    },
    "112.5": {
      velocity: [0.1197801899, 0.8593215427, 1.211168748],
      direction: [-82.721123656, 24.3560008625, 70.6483804783],
      "speed factor": 0.9384275062,
      "Uz factor": 0.9935091311,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1967741723,
      "Direction stability factor": 0.5739735996,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3683662579, 1.0501743787, 1.5397252203],
      direction: [-93.5731509373, -23.7753581399, 11.0063633505],
      "speed factor": 1.0710374186,
      "Uz factor": 0.9954908939,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.294801728,
      "Direction stability factor": 0.7095013492,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5782396073, 0.9666949334, 1.303964797],
      direction: [-15.3088764113, 6.6376771362, 30.1334878934],
      "speed factor": 1.5941197644,
      "Uz factor": 0.9963289908,
      "Directional variance factor": 0.4099842546,
      "Velocity stability factor": 0.4603088632,
      "Direction stability factor": 0.8737712103,
      score: 0.6544588846,
    },
    "247.5": {
      velocity: [0.9454125747, 0.972179101, 0.9985812343],
      direction: [-2.7110621427, -0.4243721836, 1.8552548738],
      "speed factor": 1.1729449901,
      "Uz factor": 0.9998228986,
      "Directional variance factor": 0.9622780281,
      "Velocity stability factor": 0.9512565227,
      "Direction stability factor": 0.9873157861,
      score: 0.9720415307,
    },
    "292.5": {
      velocity: [1.1170668495, 1.2349528772, 1.2968323418],
      direction: [-7.0319590898, -3.5979795147, -0.7489520735],
      "speed factor": 1.4589513198,
      "Uz factor": 0.9989890875,
      "Directional variance factor": 0.6801795987,
      "Velocity stability factor": 0.835485671,
      "Direction stability factor": 0.9825472027,
      score: 0.8701899188,
    },
    "337.5": {
      velocity: [0.6335230874, 1.138642613, 1.4477793494],
      direction: [-8.9344734023, 7.2994031702, 39.563700554],
      "speed factor": 1.470163632,
      "Uz factor": 0.9473667044,
      "Directional variance factor": 0.3511641627,
      "Velocity stability factor": 0.4784042335,
      "Direction stability factor": 0.8652828501,
      score: 0.0,
    },
    id: "IDN:4601.0",
    "45": {
      velocity: [1.194994701, 1.268109259, 1.307251731],
      direction: [-0.7902316435, 5.4785681682, 11.1021311128],
      "speed factor": 1.189370928,
      "Uz factor": 0.938039937,
      "Directional variance factor": 0.5130161628,
      "Velocity stability factor": 0.9111308869,
      "Direction stability factor": 0.966965659,
      score: 0.0,
    },
    "90": {
      velocity: [0.1722566124, 0.3723466769, 0.6157534639],
      direction: [-33.1589169298, -8.6746960365, 17.9650839278],
      "speed factor": 0.9537951618,
      "Uz factor": 0.9964982528,
      "Directional variance factor": 0.2289159079,
      "Velocity stability factor": 0.3743192837,
      "Direction stability factor": 0.8579888865,
      score: 0.0,
    },
    "135": {
      velocity: [0.2218915714, 1.0369693393, 1.640524424],
      direction: [-111.2907921582, 6.3219560284, 141.3261141954],
      "speed factor": 0.94351551,
      "Uz factor": 0.9743501583,
      "Directional variance factor": 0.438048353,
      "Velocity stability factor": 0.1863243541,
      "Direction stability factor": 0.2982863712,
      score: 0.0,
    },
    "180": {
      velocity: [0.0390980461, 0.3480472, 1.0578126325],
      direction: [-177.5044328065, -53.5156850936, 167.3992124768],
      "speed factor": 0.4940774616,
      "Uz factor": 0.9610212913,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1815230889,
      "Direction stability factor": 0.0419343187,
      score: 0.0,
    },
    "225": {
      velocity: [0.4632269905, 0.5031877285, 0.5627573814],
      direction: [-1.1322181661, 6.3985933564, 13.3915635193],
      "speed factor": 1.0099313925,
      "Uz factor": 0.9999536516,
      "Directional variance factor": 0.4312361461,
      "Velocity stability factor": 0.8563681565,
      "Direction stability factor": 0.959656162,
      score: 0.8017291566,
    },
    "270": {
      velocity: [0.473827836, 0.5176472989, 0.5577683649],
      direction: [-16.8625602714, -8.5696597242, -3.2046972975],
      "speed factor": 0.9823750853,
      "Uz factor": 0.999880115,
      "Directional variance factor": 0.238252469,
      "Velocity stability factor": 0.8845671724,
      "Direction stability factor": 0.9620614917,
      score: 0.0,
    },
    "315": {
      velocity: [0.6186080438, 0.8750895965, 1.1983765416],
      direction: [-15.337316711, 6.1752087666, 31.1419247256],
      "speed factor": 1.3764907588,
      "Uz factor": 0.9878015818,
      "Directional variance factor": 0.4510925541,
      "Velocity stability factor": 0.5062503928,
      "Direction stability factor": 0.870890996,
      score: 0.6747812347,
    },
  },
  {
    position: [-65.92847, 5.317757, 48.16],
    "overall score": 0.2969374058,
    "0": {
      velocity: [0.6868829622, 1.037231559, 1.32402373],
      direction: [-8.0958324558, 8.349311111, 50.5961642688],
      "speed factor": 1.3205015547,
      "Uz factor": 0.916824003,
      "Directional variance factor": 0.2578390124,
      "Velocity stability factor": 0.5740988426,
      "Direction stability factor": 0.8369666758,
      score: 0.0,
    },
    "22.5": {
      velocity: [1.2393518002, 1.2616394646, 1.2873596769],
      direction: [7.1651230807, 7.9298870901, 8.527325696],
      "speed factor": 1.3545875459,
      "Uz factor": 0.8721924653,
      "Directional variance factor": 0.2951211475,
      "Velocity stability factor": 0.9328939418,
      "Direction stability factor": 0.9962161038,
      score: 0.0,
    },
    "67.5": {
      velocity: [1.2337815257, 1.3224268512, 1.4017397068],
      direction: [-9.5645500178, -6.594820263, -1.8269527087],
      "speed factor": 1.2662772665,
      "Uz factor": 0.9688757559,
      "Directional variance factor": 0.4137937544,
      "Velocity stability factor": 0.8801240844,
      "Direction stability factor": 0.9785066741,
      score: 0.8127327968,
    },
    "112.5": {
      velocity: [0.048903385, 0.8136328864, 1.238740916],
      direction: [-140.6891688322, 22.6153071916, 85.7313359284],
      "speed factor": 0.8885329211,
      "Uz factor": 0.9957791673,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1243189892,
      "Direction stability factor": 0.3710541534,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3494137915, 1.0098257414, 1.5175240142],
      direction: [-112.5322317975, -25.2454214235, 10.9133024403],
      "speed factor": 1.0298872046,
      "Uz factor": 0.9992216445,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2967575807,
      "Direction stability factor": 0.6570957382,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3110768308, 0.9438029701, 1.261933124],
      direction: [-21.6749996639, 3.4221600457, 27.1433787376],
      "speed factor": 1.556369974,
      "Uz factor": 0.9993658298,
      "Directional variance factor": 0.6958079959,
      "Velocity stability factor": 0.2928883811,
      "Direction stability factor": 0.8643933933,
      score: 0.6793707909,
    },
    "247.5": {
      velocity: [0.9421386963, 0.9752246679, 0.9991936256],
      direction: [-1.5129154393, 1.6530004413, 4.4839854317],
      "speed factor": 1.1766195008,
      "Uz factor": 0.9994091346,
      "Directional variance factor": 0.8530666274,
      "Velocity stability factor": 0.9476937038,
      "Direction stability factor": 0.983341942,
      score: 0.9418610538,
    },
    "292.5": {
      velocity: [1.1697359199, 1.2354534008, 1.2772633013],
      direction: [-10.3296834878, -5.1852203125, -3.1106822399],
      "speed factor": 1.4595426294,
      "Uz factor": 0.9971812984,
      "Directional variance factor": 0.5390915278,
      "Velocity stability factor": 0.9015951573,
      "Direction stability factor": 0.9799472188,
      score: 0.8501452806,
    },
    "337.5": {
      velocity: [0.7503016905, 1.106138598, 1.4033813999],
      direction: [-8.5358020295, 9.851097678, 40.9658848674],
      "speed factor": 1.428195924,
      "Uz factor": 0.9211037726,
      "Directional variance factor": 0.1243468731,
      "Velocity stability factor": 0.5816506086,
      "Direction stability factor": 0.8624953142,
      score: 0.0,
    },
    id: "IDN:4603.0",
    "45": {
      velocity: [1.206401423, 1.25959709, 1.296362282],
      direction: [-1.6094966438, 3.2050010712, 6.5478319381],
      "speed factor": 1.1813872892,
      "Uz factor": 0.9303448755,
      "Directional variance factor": 0.7151110159,
      "Velocity stability factor": 0.9287818166,
      "Direction stability factor": 0.9773407539,
      score: 0.0,
    },
    "90": {
      velocity: [0.1955405084, 0.3877345139, 0.6152233671],
      direction: [-41.3532705498, -11.0785860172, 14.5587791405],
      "speed factor": 0.9932123109,
      "Uz factor": 0.9985391299,
      "Directional variance factor": 0.0152367985,
      "Velocity stability factor": 0.4079158155,
      "Direction stability factor": 0.8446887509,
      score: 0.0,
    },
    "135": {
      velocity: [0.0299535126, 0.8236506125, 1.5965658535],
      direction: [-102.8159821811, 2.9327486495, 161.0609609255],
      "speed factor": 0.7494215097,
      "Uz factor": 0.9821924717,
      "Directional variance factor": 0.7393112312,
      "Velocity stability factor": 0.1014487603,
      "Direction stability factor": 0.2670084914,
      score: 0.0,
    },
    "180": {
      velocity: [0.0787326471, 0.3329615671, 0.8706980582],
      direction: [-177.0390991849, -48.2331917284, 172.6805712602],
      "speed factor": 0.4726623454,
      "Uz factor": 0.9603677855,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3637026386,
      "Direction stability factor": 0.028556471,
      score: 0.0,
    },
    "225": {
      velocity: [0.4750527786, 0.5199020516, 0.5882019398],
      direction: [0.4794530706, 8.2194023981, 15.1563141314],
      "speed factor": 1.0434781557,
      "Uz factor": 0.9999867065,
      "Directional variance factor": 0.2693864535,
      "Velocity stability factor": 0.8367149725,
      "Direction stability factor": 0.9592309415,
      score: 0.7561408272,
    },
    "270": {
      velocity: [0.4755647037, 0.5186181733, 0.5606087662],
      direction: [-14.710764877, -7.7136827995, -3.3608129575],
      "speed factor": 0.9842175808,
      "Uz factor": 0.9996058314,
      "Directional variance factor": 0.3143393067,
      "Velocity stability factor": 0.8830496217,
      "Direction stability factor": 0.9684723558,
      score: 0.0,
    },
    "315": {
      velocity: [0.6330382049, 0.8689754535, 1.1765814425],
      direction: [-15.8165690597, 4.8232914078, 31.9505724224],
      "speed factor": 1.3668733877,
      "Uz factor": 0.9799567182,
      "Directional variance factor": 0.571262986,
      "Velocity stability factor": 0.5371009962,
      "Direction stability factor": 0.8673134959,
      score: 0.7107477435,
    },
  },
];

export default zoneOneProject15;
