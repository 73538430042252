import { ErrorBoundary } from "react-error-boundary";

function GeneralFallbackComponent({ error, resetErrorBoundary }) {
  return (
    <p>No data available!</p>
  );
}


function EmptyFallbackComponent({ error, resetErrorBoundary }) {
  return
}



export const GeneralErrorBoundary = ({ children, resetKeys }) => {
  return (
    <ErrorBoundary
      FallbackComponent={GeneralFallbackComponent}
      resetKeys={resetKeys}
    >
      {children}
    </ErrorBoundary>
  );
};


export const EmptyErrorBoundary = ({ children, resetKeys }) => {
  return (
    <ErrorBoundary
      FallbackComponent={EmptyFallbackComponent}
      resetKeys={resetKeys}
    >
      {children}
    </ErrorBoundary>
  );
};


export const withErrorBoundary = (Component) => {
  const WithErrorBoundary = (props) => {
    return (<GeneralErrorBoundary>
      <Component {...props} />
    </GeneralErrorBoundary>)
  }

  return WithErrorBoundary
}

