import { LinearProgress } from "@material-ui/core";
import axios from "axios";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "../App.css";
import {
  setRoomEnergyDataFirstValue,
  setscenarioDescriptionList,
} from "../redux/roomData";
import { setGlobalSceneVariable, updateCamera } from "../redux/sceneGlobal";
import Convas from "./Convas";
import Filter from "./Filter";
import DaylightInfoBoxView from "./InformationBox/DaylightBoxView";
import EnergyDataInfoBox from "./InformationBox/EnergyDataInfoBox";
import RoomConfiguration from "./InformationBox/RoomConfiguration";
import SensorPlacementInfoBox from "./InformationBox/SensorPlacementInfoBox";
import VirtualSensorsInfoBox from "./InformationBox/VirtualSensorsInfoBox";
import WindGif from "./InformationBox/WindGif";
import WindRiskInfoBox from "./InformationBox/WindRiskInfoBox";
import WindowConfiguration from "./InformationBox/WindowConfiguration";
import ScenarioModal from "./ScenarioModal";
import ColorBarDaylightFactor from "./colorBarComponent/ColorBarDaylightFactor";
import ColorBarEnergyData from "./colorBarComponent/ColorBarEnergyData";
import ColorBarPV from "./colorBarComponent/ColorBarPV";
import ColorBarSolarSensors from "./colorBarComponent/ColorBarSunHours";
import ColorBarWindRisk from "./colorBarComponent/ColorBarWindRisk";
import SensorPlacementColorBar from "./colorBarComponent/SensorPlacementColorBar";
import VirtualWindColorBar from "./colorBarComponent/VirtualWindColorBar";
import NavBar from "./navBar/NavBar";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Dropdown, DropdownItem, DropdownMenu } from "semantic-ui-react";
import { updatePVDataFilter } from "../redux/pvData";
import {
  setIsMenuOpen,
  updatePVPanelFilter
} from "../redux/sceneSlice";
import { updateUser } from "../redux/user";
import ActivityTracker from "./ActivityTracker";
import Authenticator from "./Authenticator";
import WindTurbineInfoBox from "./InformationBox/WindTurbineInfoBox";
import LoggedoutModal from "./LoggedoutModal";
import RoomFilter from "./RoomFiltler";
import ShadingProgressionSelector from "./ShadingProgressionSelector";
import TokenRefresher from "./TokenRefresher";
import WindTurbineColorBar from "./colorBarComponent/WindTurbineColorBar";
import { publish, windowEvents } from "./events";
import Compass from "./threeDComponent/Compass";
import { EmptyErrorBoundary } from "./utility/ErrorBoundary";
import { CaptureComponent } from "./utility/ScreenShotTaker";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { FaVideo } from "react-icons/fa6";
import { GiPhotoCamera } from "react-icons/gi";
const decodeQueryParam = (param) => {
  // Replace URL-encoded characters and decode the parameter value
  return decodeURIComponent(
    param.replace(/%[0-9A-Fa-f]{2}/g, (match) =>
      String.fromCharCode(parseInt(match.substring(1), 16))
    )
  );
};

const removeNoscriptTags = () => {
  document.querySelectorAll('noscript')?.forEach((noscript) => {
    noscript.style.display = 'none';
  });
};

const restoreNoscriptTags = () => {
  document.querySelectorAll('noscript')?.forEach((noscript) => {
    noscript.style.display = '';
  });
};


const CapturePageButton = () => {
  const capturePage = () => {
    removeNoscriptTags()

    html2canvas(document.body, {
      useCORS: true,
    }).then((canvasSnapshot) => {
      const screenshot = canvasSnapshot.toDataURL('image/png');
      saveAs(screenshot, 'page-screenshot.png');
    });
    restoreNoscriptTags()
  };

  return <button onClick={capturePage}
    style={{
      border: "1px solid #ddd",
      background: "transparent",
      borderRadius: "4px",
      cursor: "pointer",
      width: "42px",
      height: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: 'center'
    }}>
    <GiPhotoCamera style={{
      width: "34px",
      height: "34px",
      padding: 4
    }} />
  </button>;
};

const TopMenu = () => {

  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.sceneVisibility.isMenuOpen);
  const buildingsInfo = useSelector((state) => state.sceneVar.buildingsInfo);
  const [hovered, setHovered] = useState(false);

  let mainScene = useRef();
  let mainScene1 = useRef();

  // const { cameraTarget } = useSelector(store => store.sceneVar)
  return (
    <div
      className="TopBar"
      style={{
        position: "relative",
        transition: "all 0.4s linear",
      }}
    >
      {isMenuOpen && (
        <>
          <div className="Title">
            <p
              style={{
                color: "#2459ea",
                fontSize: "26px",
                textTransform: "uppercase",
                marginLeft: "10px",
                fontWeight: "700",
              }}
            >
              3D viewer
            </p>
          </div>
          <NavBar value={mainScene} valuef={mainScene1} />
          <Filter />
        </>
      )}
      <div
        style={{
          position: "absolute",
          bottom: isMenuOpen ? "-104px" : "-48px",
          left: "20px",
          zIndex: 9,
          display: "flex",
          alignItems: "center",
          gap: 16
        }}

      >
        <div style={{
          width: "42px",
          background: hovered ? "white" : "#fff5",
          cursor: "pointer",
          border: "1px solid #ddd",
          borderTop: "0px solid #ddd",
          height: "32px",
          borderRadius: "4px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: 'center'
        }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => dispatch(setIsMenuOpen(!isMenuOpen))}>

          {!isMenuOpen && (
            <KeyboardDoubleArrowDownIcon stroke={!hovered ? "#eee" : "#666"} />
          )}
          {isMenuOpen && (
            <KeyboardDoubleArrowUpIcon stroke={!hovered ? "#eee" : "#666"} />
          )}
        </div>
        <CaptureComponent targetElement={() => document.body}
          style={{
            border: "1px solid #ddd",
            borderRadius: "4px",
            width: "40px",
            height: "32px",
            padding: 3
          }}
          id='Home-Capture-Button'
        />
        <div style={{ width: '60px' }}>
          <Dropdown
            style={{
              border: "1px solid #ddd",
              color: "black",
              borderRadius: "7px",
              height: "34px",
              alignItems: "center",
              padding: "4px 8px 2px 8px",
              background: "transparent",
              cursor: "pointer",
            }}
            icon={() => <FaVideo style={{
              transform: 'rotate(25deg)',
              width: "20px",
              height: "24px",
              cursor: 'pointer',
            }} />
            }
          >
            <DropdownMenu>
              {!buildingsInfo?.length && <DropdownItem
                onClick={() => publish(windowEvents.RESET_CAMERA)}
              >
                Reset
              </DropdownItem>}
              {buildingsInfo?.length > 0 && buildingsInfo?.map(building => {
                return <DropdownItem onClick={() => {
                  publish(windowEvents.RESET_CAMERA)
                  dispatch(updateCamera(building.coordinates))
                }}>
                  {building.name}
                </DropdownItem>

              })}
            </DropdownMenu>

          </Dropdown>

          {/* Keep the component below to find the good coordinates for camera */}
          {/* 
          <div style={{ display: 'flex', gap: 8 }}>
            <input type="number" onChange={(e) => {
              console.log([Number(e.target.value), cameraTarget[1], cameraTarget[2]])
              publish(windowEvents.RESET_CAMERA)
              dispatch(updateCamera([Number(e.target.value), cameraTarget[1], cameraTarget[2]]))
            }} value={cameraTarget[0]} />
            <input type="number" onChange={(e) => {
              publish(windowEvents.RESET_CAMERA)
              dispatch(updateCamera([cameraTarget[0], Number(e.target.value), cameraTarget[2]]))
            }} value={cameraTarget[1]} />
            <input type="number" onChange={(e) => {
              publish(windowEvents.RESET_CAMERA)
              dispatch(updateCamera([cameraTarget[0], cameraTarget[1], Number(e.target.value)]))
            }} value={cameraTarget[2]} />
            <button onClick={() => {
              publish(windowEvents.RESET_CAMERA)
              dispatch(updateCamera([cameraTarget[0] + 1, 22, 90]))
            }}>
              Click
            </button>

          </div> */}

        </div>
      </div>
    </div>
  );
};

export default function Project() {
  var dispatch = useDispatch();

  let [searchParams, setSearchParams] = useSearchParams();
  const [resultArray, setResultArray] = useState();
  const [nodes, setNodes] = useState(false);

  const token = searchParams.get("token");
  const project_id = searchParams.get("project_id");
  localStorage.setItem("project_id", project_id);
  localStorage.setItem("token", token);

  const { user } = useSelector((store) => store.user);
  // let token, project_id;
  // token = user?.token;
  // project_id = user?.project_id;
  useEffect(() => {
    if (!resultArray?.length && token && project_id !== undefined)
      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/project_info/?project_id=${project_id}`
        )
        .then((response) => {
          setNodes(true);
          setResultArray(response.data[0]);

          localStorage.setItem(
            "scenario_list",
            JSON.parse(response.data[0].all_scenario_list)
          );
          localStorage.setItem(
            "floor_list",
            JSON.parse(response.data[0].floor_list)
          );
          localStorage.setItem(
            "value_list",
            JSON.parse(response.data[0].energy_value_list)
          );
          let floorsList;
          var energyValueData;
          let scenarioList;
          floorsList = localStorage.getItem("floor_list");
          scenarioList = localStorage.getItem("scenario_list");
          energyValueData = localStorage.getItem("value_list");
          floorsList = floorsList.split(",");
          scenarioList = scenarioList.split(",");
          energyValueData = energyValueData.split(",");

          var dict = {};
          for (let i = 0; i < floorsList.length; i++) {
            for (let j = 0; j < energyValueData.length; j++) {
              for (let k = 0; k < scenarioList.length; k++) {
                dict[
                  `${floorsList[i]}${energyValueData[j]}${scenarioList[k]}`
                ] = false;
              }
            }
          }
          dispatch(setRoomEnergyDataFirstValue([dict]));
        })
        .catch((response) => {
          if (
            response.response?.status === 401 ||
            response.response?.status === 403
          )
            dispatch(updateUser({ isNotAuthorized: true }));
        });
  }, [project_id, token]);

  let windRef = useRef();
  let roomRef = useRef();
  const childCompRef = useRef({});
  if (resultArray) {
    const wholeData = resultArray;
    localStorage.setItem(
      "scenario_list",
      JSON.parse(wholeData?.all_scenario_list)
    );
    localStorage.setItem("floor_list", JSON.parse(wholeData?.floor_list));
    localStorage.setItem("value_list", JSON.parse(wholeData?.energy_value_list));
    localStorage.setItem(
      "daylight_value_list",
      JSON.parse(wholeData?.daylight_value_list)
    );
    localStorage.setItem(
      "daylight_scenario_list",
      JSON.parse(wholeData?.daylight_scenario_list)
    );
    let floorsList;
    var energyValueData;
    let scenarioList;
    floorsList = localStorage.getItem("floor_list");
    scenarioList = localStorage.getItem("scenario_list");
    energyValueData = localStorage.getItem("value_list");
    var scenarioDescription = JSON.parse(wholeData?.scenario_description);
    var allWindDirections = JSON.parse(wholeData?.all_wind_direction)
    if (allWindDirections?.length)
      allWindDirections?.sort((a, b) => a - b)

    let pvScenarios
    try {
      pvScenarios = JSON.parse(wholeData?.pv_panel_data)
    } catch {
      pvScenarios = undefined
    }

    let pvPanelList = wholeData?.pv_panels
    if (pvPanelList)
      try {
        pvPanelList = JSON.parse(pvPanelList)
      } catch {
        pvPanelList = [pvPanelList]
      }
    const buildingsInfo = project_id == 355 ? [{ name: 'B1', coordinates: [0, 0, 0] },
    { name: 'B2', coordinates: [390, 27, 470] }] : project_id == 383 ? [{ name: 'B1', coordinates: [390, 27, 470] },
    { name: 'B2', coordinates: [0, 0, 0] }]
      : project_id == 317 ?
        [{ name: 'Reset', coordinates: [350, 22, 90] }]
        : project_id == 784 ?
          [{ name: 'Reset', coordinates: [-300, 22, 150] }]
          : [{ name: 'Reset', coordinates: [0, 0, 0] }]
    if (buildingsInfo.length) {
      publish(windowEvents.RESET_CAMERA)
      dispatch(updateCamera(buildingsInfo[0].coordinates))
    }


    dispatch(
      setGlobalSceneVariable([
        JSON.parse(wholeData?.ceiling_list),
        JSON.parse(wholeData?.floor_list),
        JSON.parse(wholeData?.external_list),
        JSON.parse(wholeData?.internal_list),
        JSON.parse(wholeData?.all_scenario_list),
        JSON.parse(wholeData?.energy_value_list),
        allWindDirections,
        pvPanelList,
        wholeData?.rooftop,
        JSON.parse(wholeData?.stairs),
        wholeData?.sensor_placement,
        wholeData?.shading_progression,
        wholeData?.wind_risk,
        JSON.parse(wholeData?.stairs),
        JSON.parse(wholeData?.doors),
        JSON.parse(wholeData?.wind_risk_directions_list),
        pvScenarios,
        buildingsInfo
      ])
    );
    dispatch(setscenarioDescriptionList([scenarioDescription]));

    if (pvScenarios?.length) {
      const pvDataFilters = {}
      for (const index in pvScenarios) {
        pvDataFilters[pvScenarios[index]] = false
      }
      dispatch(updatePVDataFilter(pvDataFilters))
    }
    if (pvPanelList?.length) {
      const pvPanelsFilters = {}
      for (const index in pvPanelList) {
        pvPanelsFilters[pvPanelList[index]] = index === '0'
      }
      dispatch(updatePVPanelFilter(pvPanelsFilters))
    }
  }
  // const blockAccess =
  //   user?.isNotAuthorized ||
  //   user?.isAuthenticated === false ||
  //   user?.isInactive;
  const blockAccess = false;
  return (
    <>
      <ActivityTracker />
      <Authenticator />
      <TokenRefresher />
      {blockAccess && <LoggedoutModal />}
      {!blockAccess && resultArray && (
        <Suspense fallback={<LinearProgress />}>
          {/* <CircularProgress /> */}
          <div style={{ width: "100vw" }}>
            <TopMenu />
            <EmptyErrorBoundary>
              <RoomFilter />
            </EmptyErrorBoundary>

            <EmptyErrorBoundary>
              <ColorBarSolarSensors />
              <ColorBarWindRisk />
              <ColorBarEnergyData />
              <SensorPlacementColorBar />
              <WindTurbineColorBar />
              <VirtualWindColorBar />
              <ColorBarDaylightFactor />
              <ColorBarPV />
            </EmptyErrorBoundary>

            <ScenarioModal />

            <EmptyErrorBoundary>
              <WindowConfiguration />
              <RoomConfiguration />
              <VirtualSensorsInfoBox />
              <WindRiskInfoBox />
              <EnergyDataInfoBox />
              <DaylightInfoBoxView />
              <SensorPlacementInfoBox />
              <WindTurbineInfoBox />
            </EmptyErrorBoundary>
            <EmptyErrorBoundary>
              <WindGif />
            </EmptyErrorBoundary>
            <EmptyErrorBoundary>
              <Compass />
            </EmptyErrorBoundary>
            <EmptyErrorBoundary>
              <ShadingProgressionSelector />
            </EmptyErrorBoundary>

            <Convas />
          </div>
        </Suspense>
      )}
    </>
  );
}
