import { useDispatch, useSelector } from "react-redux";
import { decryptData } from "./utils";
import { useEffect } from "react";
import { updateUser } from "../redux/user";
import axios from "axios";

const shouldRefreshToken = (expiration) => {
  return !expiration || new Date(expiration) - new Date() < 1000 * 60;
};

const TokenRefresher = () => {
  // console.log("TokenRefresher");
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("TokenRefresher UseEffect");
    const refreshToken = async () => {
      // console.log("refreshToken");
      // console.log("USER");
      // console.log(user);

      if (user && user.role === 4 && shouldRefreshToken(user.expiration)) {
        // console.log(
        //   "Should Refresh Should RefreshShould RefreshShould Refresh true"
        // );
        const basicUsername = "webapp";
        const basicPassword = "firstset";

        const basicAuthKey = `${basicUsername}:${basicPassword}`;
        const headers = {
          Authorization: "Basic " + window.btoa(basicAuthKey),
        };
        var FormData = require("form-data");
        var data = new FormData();
        data.append("username", user.username);
        data.append("password", user.password);
        data.append("grant_type", "password");
        const url = `${process.env.REACT_APP_API_URL}user/signin/`;
        let config = { headers };
        try {
          const response = await axios.post(url, data, config);
          const resp = response.data;
          let expiration = new Date();
          expiration.setSeconds(expiration.getSeconds() + resp.expires_in);
          dispatch(
            updateUser({
              ...user,
              token: resp.access_token,
              role: resp.role,
              expiration,
            })
          );
          localStorage.setItem("token", resp.access_token);
        } catch {}
      }
    };

    const refreshTokenInterval = setInterval(() => {
      refreshToken();
    }, 10000);
    return () => clearInterval(refreshTokenInterval);
  }, [user]);
};

export default TokenRefresher;
