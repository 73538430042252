import chroma from "chroma-js";
import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Color, InstancedMesh, Matrix4, MeshLambertMaterial } from "three";
import {
  disappearSensorsVirtualBox,
  setCoordinate,
  toggleSensorsVirtualBox,
  updateClickedSolarSensor,
  updateVirtualSensorInfo,
} from "../../redux/solarSensors";
import { useColorBar } from "../CustomHooks";
import { sunsensors } from "../navBar/NavBar";
import { SphereInstancedMesh } from "./InstancedMesh";

const calculateColor = (value, minValue, maxValue, colorBar) => {
  const normalizedValue = (value - minValue) / (maxValue - minValue);
  return chroma.scale(colorBar)(normalizedValue).css();
};

const SunHoursOld = ({
  sensorRow,
  id_val,
  colorBar,
  sunval,
  name,
  geometry,
  minIdx,
  maxIdx,
}) => {
  const dispatch = useDispatch();
  const minMaxRangeList = useSelector((state) => state.sensorSolars.rangeList);
  const minValue = minMaxRangeList[minIdx];
  const maxValue = minMaxRangeList[maxIdx];

  const { max, min } = useColorBar(
    "Solar Sensors",
    sunsensors[id_val],
    minValue,
    maxValue
  );

  const colorCallback = useCallback(
    (value) => calculateColor(value, min, max, colorBar),
    [colorBar, min, max]
  );

  const groupRef = useRef();

  const meshRef = useRef();
  const instanceCount = sensorRow.length;

  let material = new MeshLambertMaterial({ color: "white" });
  const instancedMesh = new InstancedMesh(geometry, material, instanceCount);
  meshRef.current = instancedMesh;

  sensorRow.forEach((sensor, index) => {
    const matrix = new Matrix4();
    matrix.setPosition(sensor.utm_x, sensor.utm_z, -sensor.utm_y);
    instancedMesh.setMatrixAt(index, matrix);
    instancedMesh.setColorAt(index, new Color(colorCallback(sensor[sunval])));
  });

  instancedMesh.instanceMatrix.needsUpdate = true;

  groupRef.current = instancedMesh;

  const handlePointerEnter = useCallback((config) => {
    const sensor = sensorRow[config.instanceId];
    const {
      name,
      sunshine_hours,
      direct_energy,
      diffuse_energy,
      global_energy,
      utm_x,
      utm_y,
      utm_z,
    } = sensor;

    dispatch(toggleSensorsVirtualBox());
    dispatch(
      updateVirtualSensorInfo([
        name,
        sunshine_hours,
        direct_energy,
        diffuse_energy,
        global_energy,
        utm_x,
        utm_y,
        utm_z,
      ])
    );
    dispatch(setCoordinate([config.pageX, config.pageY]));
  }, []);

  const handlePointerLeave = useCallback(() => {
    dispatch(disappearSensorsVirtualBox());
  }, []);

  return (
    <group
      ref={groupRef}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      <primitive object={instancedMesh} />
    </group>
  );
};

const SunHours = ({
  sensorRow,
  id_val,
  colorBar,
  sunval,
  name,
  minIdx,
  maxIdx,
}) => {
  const dispatch = useDispatch();
  const minMaxRangeList = useSelector((state) => state.sensorSolars.rangeList);
  const minValue = minMaxRangeList[minIdx];
  const maxValue = minMaxRangeList[maxIdx];

  const { max, min } = useColorBar(
    "Solar Sensors",
    sunsensors[id_val],
    minValue,
    maxValue
  );

  const colorCallback = useCallback(
    (value) => calculateColor(value, min, max, colorBar),
    [colorBar, min, max]
  );

  const onClick = useCallback((config) => {
    const sensor = sensorRow[config.instanceId];
    const {
      name,
      sunshine_hours,
      direct_energy,
      diffuse_energy,
      global_energy,
      utm_x,
      utm_y,
      utm_z,
    } = sensor;

    dispatch(toggleSensorsVirtualBox());
    dispatch(
      updateVirtualSensorInfo([
        name,
        sunshine_hours,
        direct_energy,
        diffuse_energy,
        global_energy,
        utm_x,
        utm_y,
        utm_z,
      ])
    );
    dispatch(updateClickedSolarSensor(sensor))
    dispatch(setCoordinate([config.pageX, config.pageY]));
    config.stopPropagation()
  }, []);


  const setColor = (sensor) => new Color(colorCallback(sensor[sunval]))
  const clickedSensor = useSelector((state) => state.sensorSolars.clickedSensor);
  const showSensorInfoBox = useSelector((state) => state.sensorSolars.showSensorInfoBox);

  return (
    <SphereInstancedMesh
      objects={sensorRow}
      setColor={setColor}
      groupProps={{ onClick }}
      highlightProps={{
        highlightedObject: [clickedSensor],
        showHighlight: showSensorInfoBox,
      }}
    />
  )
};

export default SunHours;
