import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      if (!state.user) state.user = action.payload;
      else {
        state.user = { ...state.user, ...action.payload };
      }
    },
  },
});

export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
