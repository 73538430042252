import React from "react";
import { useSelector } from "react-redux";
import { generateAxisValues, generateNumbers } from "../utils";
import ColorBarComponent, { CustomColorBar } from "./ColorBarComponent";
import "./colorBar.css";
import ColorbarSetRange from "./CustomizeColorbar";
import { useSensorPlacementColorBar } from "../CustomHooks";

const SensorPlacementColorBarOld = () => {
  var colorBarVisibility = useSelector((state) => state.shadingSystem.value);
  var selectedZone = useSelector((state) => state.shadingSystem.selectedZone);
  var groupZone = useSelector((state) => state.shadingSystem.zoneGroup);
  var rangeValue = useSelector((state) => state.infoWindRisk.sensorRange);
  const colorbars = useSelector((state) => state.colorbars["Sensor Placement"]);
  const { max } = colorbars["Zone"] || {
    max: rangeValue.max,
  };
  const generatedNumbers = generateAxisValues(0, max, undefined);

  return (
    groupZone[selectedZone] &&
    generatedNumbers && (
      <>
        <ColorbarSetRange
          category="Sensor Placement"
          subcategory={"Zone"}
          defaultMax={rangeValue.max}
        />
        <ColorBarComponent
          generatedNumbers={generatedNumbers}
          min={0}
          max={generatedNumbers[generatedNumbers.length - 1]}
          shiftUp={2}
          label="score"
          className="sensor-placement-color-bar"
        />
      </>
    )
  );
};

const SensorPlacementColorBar = () => {
  const { isVisible } = useSensorPlacementColorBar();
  return (
    <CustomColorBar
      colorbarClassName="sensor-placement-color-bar"
      category="Sensor Placement"
      subcategory="Zone"
      unit="Score"
      dataMin={0}
      dataMax={1}
      isVisible={isVisible}
    />
  );
};

export default SensorPlacementColorBar;
