import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { daylightToggle } from "../../redux/daylightFactor";
import { updateFilter } from "../../redux/sceneGlobal";
import { deselectAllTarget } from "../../redux/sceneSlice";
import "../NavBar.css";
import { isOptionSelected } from "../utility/helpers";


const DropDownDaylight = (props) => {
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );

  let floorsList;

  floorsList = localStorage.getItem("floor_list");
  floorsList = floorsList.split(",");
  const scenarioListString = localStorage.getItem("daylight_scenario_list");
  var scenarioList = scenarioListString.split(",");

  var valueList = localStorage.getItem("daylight_value_list");
  valueList = valueList.split(",");

  var scenarioDescriptionList = useSelector(
    (state) => state.roomData.scenarioDescriptionList
  );
  var selector = useSelector((state) => state.daylightFactor.value);
  const dispatch = useDispatch();
  return (
    <Dropdown
      item
      simple
      text="Day Light Factor "
      className="drop-down drop-down-wide"
    >
      <Dropdown.Menu>
        <Dropdown.Header content="Values" />

        {valueList.map((parentName, id) => {
          return (
            <Dropdown
              item
              simple
              text={parentName}
              className={`drop-down drop-down-wide ${selector.parentName === parentName && "selected-item"
                }`}
            >
              <Dropdown.Menu>
                <Dropdown.Header content="Scenarios" />
                {scenarioList?.map((childName) => {
                  const isSelected = isOptionSelected({
                    selector,
                    parentName,
                    childName,
                  });

                  return (
                    <Dropdown.Item
                      className={`${isSelected && "selected-item"}`}
                      onClick={() => {
                        if (!isSelected) dispatch(deselectAllTarget());
                        const action = daylightToggle({
                          parentName,
                          childName,
                        });
                        dispatch(action);
                        dispatch(
                          updateFilter([
                            `Day Light Factor >> ${parentName} >> ${childName}`,
                          ])
                        );
                        // dispatch(hideEnergyData());
                      }}
                    // onClick={() => {
                    //   dispatch(setModalScenarioIsShowing());
                    //   dispatch(setLastChosenScenario([scenarioValue]));
                    //   for (let f = 0; f < selectedFloor.length; f++) {
                    //     if (selectedFloor[f] == true) {
                    //       dispatch(setCurrentFloorName([floorsList[f]]));
                    //       // dispatch(hideEnergyData());
                    //       dispatch(
                    //         showEnergyData([
                    //           `${floorsList[f]}${props.data}${scenarioValue}`,
                    //         ])
                    //       );
                    //       dispatch(
                    //         updateFilter([
                    //           `${props.parentTitle} >> ${props.data} >> ${scenarioValue}`,
                    //         ])
                    //       );
                    //     }
                    //   }
                    //   dispatch(toggleRoomVisibility());
                    //   dispatch(setCurrentValue([props.data]));
                    //   dispatch(setScenarioDescription([scenarioDescriptionList[index]]));
                    // }}
                    >
                      <Checkbox
                        radio
                        checked={isSelected}
                        label={childName}
                      // key={id}
                      />
                      <button
                        style={{ background: "none", border: "none" }}
                        onClick={() => {
                          // dispatch(toggleInfoBpx([title]));
                        }}
                      >
                        {/* <i
                      class="info circle icon"
                      style={{ marginLeft: "5px", color: "blue" }}
                    ></i> */}
                      </button>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDownDaylight;
