const zoneOneProject52 = [
  {
    position: [-53.79376876, 56.83523722, 47.87999908],
    "overall score": 0.3447304655,
    "0": {
      velocity: [0.3198657455, 1.0487152002, 1.3219483742],
      direction: [-20.8112076844, -6.5383975508, 53.5617267223],
      "speed factor": 1.0746720018,
      "Uz factor": 0.992427704,
      "Directional variance factor": 0.4188091066,
      "Velocity stability factor": 0.3037221321,
      "Direction stability factor": 0.7934085155,
      score: 0.5773370674,
    },
    "22.5": {
      velocity: [0.1806293683, 0.6930080701, 1.1709246018],
      direction: [-142.5602482212, 6.1655579685, 115.546286804],
      "speed factor": 0.6153049916,
      "Uz factor": 0.9981792798,
      "Directional variance factor": 0.4519504028,
      "Velocity stability factor": 0.3596023651,
      "Direction stability factor": 0.2830374027,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1143274456, 0.5693620069, 0.9749344573],
      direction: [-55.8736006691, 21.6118815002, 106.2772461249],
      "speed factor": 0.8819797052,
      "Uz factor": 0.9854182767,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2310007091,
      "Direction stability factor": 0.5495809811,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5135174998, 0.9225987301, 1.2654008582],
      direction: [-35.2547754638, -5.5790062244, 15.5983429658],
      "speed factor": 1.0378591179,
      "Uz factor": 0.9715579578,
      "Directional variance factor": 0.5040883356,
      "Velocity stability factor": 0.3775766478,
      "Direction stability factor": 0.8587413377,
      score: 0.6497869147,
    },
    "157.5": {
      velocity: [0.1656127655, 0.849606174, 1.3069418265],
      direction: [-73.7635199074, -10.5274893405, 43.6730836043],
      "speed factor": 1.2069851027,
      "Uz factor": 0.999679319,
      "Directional variance factor": 0.0642231697,
      "Velocity stability factor": 0.0666308584,
      "Direction stability factor": 0.6737872125,
      score: 0.3696071133,
    },
    "202.5": {
      velocity: [0.325793835, 0.9058335894, 1.2240014492],
      direction: [-47.6626574732, -7.4489535353, 24.4174732134],
      "speed factor": 1.0613248424,
      "Uz factor": 0.9982758415,
      "Directional variance factor": 0.3378707969,
      "Velocity stability factor": 0.3093662842,
      "Direction stability factor": 0.7997774148,
      score: 0.5616979776,
    },
    "247.5": {
      velocity: [0.529842579, 0.7316337868, 0.905262683],
      direction: [-29.1028924543, -10.6213686383, 12.1658939913],
      "speed factor": 1.3572688411,
      "Uz factor": 0.9938848597,
      "Directional variance factor": 0.0558783433,
      "Velocity stability factor": 0.6357119092,
      "Direction stability factor": 0.8853644821,
      score: 0.6155798042,
    },
    "292.5": {
      velocity: [0.6734145701, 1.0880580254, 1.2654512163],
      direction: [-30.6056659514, -4.4583734424, 12.2628230113],
      "speed factor": 1.4816637526,
      "Uz factor": 0.9560702339,
      "Directional variance factor": 0.6037001385,
      "Velocity stability factor": 0.5658360155,
      "Direction stability factor": 0.880920864,
      score: 0.7328444705,
    },
    "337.5": {
      velocity: [0.6469759913, 0.8974977921, 1.0946215431],
      direction: [-22.2434470514, -3.7948622193, 9.6202781531],
      "speed factor": 1.1397664935,
      "Uz factor": 0.9680558862,
      "Directional variance factor": 0.6626789138,
      "Velocity stability factor": 0.6394508412,
      "Direction stability factor": 0.9114896522,
      score: 0.7812772649,
    },
    id: "IDN:737.0",
    "45": {
      velocity: [0.1325841473, 0.5482504929, 0.9916907271],
      direction: [-43.2502226399, 16.5649968562, 83.6941519877],
      "speed factor": 0.744733349,
      "Uz factor": 0.999736597,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3015735354,
      "Direction stability factor": 0.6473767371,
      score: 0.0,
    },
    "90": {
      velocity: [0.2827875572, 1.1071626, 1.474579225],
      direction: [-11.5410891774, 7.8419302014, 77.062226162],
      "speed factor": 1.7947429737,
      "Uz factor": 0.97675691,
      "Directional variance factor": 0.3029395377,
      "Velocity stability factor": 0.1301618751,
      "Direction stability factor": 0.7538796796,
      score: 0.485215193,
    },
    "135": {
      velocity: [0.0554653862, 0.6298066161, 1.0519363779],
      direction: [-86.6315956351, -24.8653743271, 57.7924006018],
      "speed factor": 0.5330370341,
      "Uz factor": 0.9647478188,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1970329035,
      "Direction stability factor": 0.5988222327,
      score: 0.0,
    },
    "180": {
      velocity: [0.238674675, 0.6517557877, 1.0680895095],
      direction: [-81.9335379245, -16.276899704, 31.9549909925],
      "speed factor": 1.2728750271,
      "Uz factor": 0.995333336,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3464568744,
      "Direction stability factor": 0.6836429752,
      score: 0.0,
    },
    "225": {
      velocity: [0.2345725324, 0.5820690883, 0.872780499],
      direction: [-57.5773801689, -3.6401796976, 31.7654848414],
      "speed factor": 0.7945880046,
      "Uz factor": 0.9889935488,
      "Directional variance factor": 0.6764284713,
      "Velocity stability factor": 0.4263910544,
      "Direction stability factor": 0.751825375,
      score: 0.0,
    },
    "270": {
      velocity: [0.755906082, 1.0406058836, 1.3660032447],
      direction: [-17.6936057426, 0.0168336157, 22.4491562738],
      "speed factor": 0.9521879842,
      "Uz factor": 0.9622179668,
      "Directional variance factor": 0.9985036786,
      "Velocity stability factor": 0.5187534031,
      "Direction stability factor": 0.8884923277,
      score: 0.0,
    },
    "315": {
      velocity: [0.5865406208, 1.0186800598, 1.1880482356],
      direction: [-13.1972200941, -4.7793813799, 8.0130645727],
      "speed factor": 1.2551152107,
      "Uz factor": 0.9713165472,
      "Directional variance factor": 0.5751660996,
      "Velocity stability factor": 0.5120353875,
      "Direction stability factor": 0.9410825426,
      score: 0.7423416431,
    },
  },
  {
    position: [-50.04879157, 56.82216473, 47.87999908],
    "overall score": 0.3327100965,
    "0": {
      velocity: [0.394099225, 0.9545021567, 1.4056918326],
      direction: [-35.6958744943, -13.3166660215, 42.0283559964],
      "speed factor": 0.9781270866,
      "Uz factor": 0.9955278154,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2971143059,
      "Direction stability factor": 0.7840993597,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2140328277, 0.7159307978, 1.1681558419],
      direction: [-106.7577521231, 4.4754878166, 140.1073951851],
      "speed factor": 0.6356575233,
      "Uz factor": 0.9954812961,
      "Directional variance factor": 0.6021788607,
      "Velocity stability factor": 0.3829939789,
      "Direction stability factor": 0.3142634797,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2080278728, 0.543286008, 0.9092210233],
      direction: [-55.6231102409, 18.7870879927, 52.8918725753],
      "speed factor": 0.8415862446,
      "Uz factor": 0.9761553149,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3734456864,
      "Direction stability factor": 0.6985694922,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5892171957, 0.9469441986, 1.2845909714],
      direction: [-23.866090399, -2.697596514, 16.6186196622],
      "speed factor": 1.0652460691,
      "Uz factor": 0.9616255628,
      "Directional variance factor": 0.7602136432,
      "Velocity stability factor": 0.4243563558,
      "Direction stability factor": 0.8875424721,
      score: 0.7399137358,
    },
    "157.5": {
      velocity: [0.411667981, 0.9477785209, 1.3466612888],
      direction: [-41.7356200731, -9.9645716001, 15.6868698002],
      "speed factor": 1.3464527335,
      "Uz factor": 0.9964861029,
      "Directional variance factor": 0.1142603022,
      "Velocity stability factor": 0.235370472,
      "Direction stability factor": 0.8404930837,
      score: 0.5076542354,
    },
    "202.5": {
      velocity: [0.1517429744, 0.7397909527, 1.1809631579],
      direction: [-85.0305920503, -9.267838788, 24.2069522457],
      "speed factor": 0.8667800857,
      "Uz factor": 0.9999746338,
      "Directional variance factor": 0.1761921077,
      "Velocity stability factor": 0.2086304453,
      "Direction stability factor": 0.696562377,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4564080048, 0.7729678076, 0.9863999012],
      direction: [-41.0626341524, -7.8001686483, 14.3081853593],
      "speed factor": 1.4339484307,
      "Uz factor": 0.9973680052,
      "Directional variance factor": 0.3066516757,
      "Velocity stability factor": 0.4857235028,
      "Direction stability factor": 0.846192168,
      score: 0.6211898786,
    },
    "292.5": {
      velocity: [0.6858876885, 1.1713546337, 1.3733062644],
      direction: [-30.7865604682, -6.602531312, 12.2320537379],
      "speed factor": 1.5950929652,
      "Uz factor": 0.976660298,
      "Directional variance factor": 0.4131083278,
      "Velocity stability factor": 0.4958886585,
      "Direction stability factor": 0.8805038494,
      score: 0.6675011713,
    },
    "337.5": {
      velocity: [0.7009359601, 0.9331828796, 1.1823877991],
      direction: [-28.283417333, -9.3500961571, 6.1234207541],
      "speed factor": 1.1850843398,
      "Uz factor": 0.974565243,
      "Directional variance factor": 0.1688803416,
      "Velocity stability factor": 0.6122220921,
      "Direction stability factor": 0.9044254498,
      score: 0.6474883333,
    },
    id: "IDN:738.0",
    "45": {
      velocity: [0.0777875552, 0.5384262123, 0.965713377],
      direction: [-61.7002632684, 11.8743842943, 70.8268473778],
      "speed factor": 0.7313882276,
      "Uz factor": 0.9949859283,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2781444037,
      "Direction stability factor": 0.6318691371,
      score: 0.0,
    },
    "90": {
      velocity: [0.7365057709, 1.0845864571, 1.3394433669],
      direction: [-11.0148887679, 8.0181432302, 25.2294878574],
      "speed factor": 1.7581463853,
      "Uz factor": 0.967546695,
      "Directional variance factor": 0.2872761573,
      "Velocity stability factor": 0.5599414544,
      "Direction stability factor": 0.899321176,
      score: 0.661464991,
    },
    "135": {
      velocity: [0.291303188, 0.7209487792, 1.0811067976],
      direction: [-59.1709818649, -17.6288313873, 18.8262108848],
      "speed factor": 0.6101752334,
      "Uz factor": 0.9512051833,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3635677139,
      "Direction stability factor": 0.7833411313,
      score: 0.0,
    },
    "180": {
      velocity: [0.1676087345, 0.641781088, 1.077392229],
      direction: [-111.0138571306, -18.0452370486, 59.8144335468],
      "speed factor": 1.2533945002,
      "Uz factor": 0.9999306607,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2831298357,
      "Direction stability factor": 0.5254769703,
      score: 0.0,
    },
    "225": {
      velocity: [0.153543672, 0.5356348224, 0.900921105],
      direction: [-90.0946683471, -0.3040216414, 61.2369813649],
      "speed factor": 0.73120015,
      "Uz factor": 0.9930116753,
      "Directional variance factor": 0.9729758541,
      "Velocity stability factor": 0.328271655,
      "Direction stability factor": 0.5796343064,
      score: 0.0,
    },
    "270": {
      velocity: [0.7081693383, 1.1377900021, 1.4988786992],
      direction: [-16.2735569237, -0.1194335335, 22.6724273236],
      "speed factor": 1.0411145907,
      "Uz factor": 0.9790076111,
      "Directional variance factor": 0.9893836859,
      "Velocity stability factor": 0.3762859225,
      "Direction stability factor": 0.8918167104,
      score: 0.7873257573,
    },
    "315": {
      velocity: [0.6432588511, 1.0507718462, 1.2603882998],
      direction: [-17.009966909, -6.8530337564, 5.833846528],
      "speed factor": 1.2946554853,
      "Uz factor": 0.9830097164,
      "Directional variance factor": 0.3908414439,
      "Velocity stability factor": 0.4993623939,
      "Direction stability factor": 0.9365449627,
      score: 0.6908234408,
    },
  },
  {
    position: [-54.78806345, 32.40355892, 47.87999908],
    "overall score": 0.313623769,
    "0": {
      velocity: [0.4694728315, 1.0357891533, 1.4434450591],
      direction: [-28.1178900822, -10.0127377939, 37.5172122903],
      "speed factor": 1.0614260217,
      "Uz factor": 0.998362877,
      "Directional variance factor": 0.1099788628,
      "Velocity stability factor": 0.3232541044,
      "Direction stability factor": 0.8176802712,
      score: 0.5171483774,
    },
    "22.5": {
      velocity: [0.1719985667, 0.7096800726, 1.3481716072],
      direction: [-145.0044657074, -1.9252508408, 68.6029712119],
      "speed factor": 0.6301076566,
      "Uz factor": 0.9991337101,
      "Directional variance factor": 0.8288665919,
      "Velocity stability factor": 0.2394001224,
      "Direction stability factor": 0.4066460086,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0402941883, 0.6616282506, 1.1047103212],
      direction: [-147.1213525344, -0.1511485497, 25.1857748594],
      "speed factor": 1.0249062678,
      "Uz factor": 0.995449962,
      "Directional variance factor": 0.9865645734,
      "Velocity stability factor": 0.0488861462,
      "Direction stability factor": 0.5213690906,
      score: 0.5195472252,
    },
    "112.5": {
      velocity: [0.4413369245, 0.8803023096, 1.2806158279],
      direction: [-45.2143408923, -26.0083512564, 6.0295030096],
      "speed factor": 0.9902785996,
      "Uz factor": 0.9947269392,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3052289525,
      "Direction stability factor": 0.8576559892,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5267771602, 1.0081455016, 1.2811165051],
      direction: [-34.5390108389, -10.7601172807, 6.7611156557],
      "speed factor": 1.4322125227,
      "Uz factor": 0.9924984328,
      "Directional variance factor": 0.0435451306,
      "Velocity stability factor": 0.3831077373,
      "Direction stability factor": 0.8852774264,
      score: 0.5493019302,
    },
    "202.5": {
      velocity: [0.589385162, 0.9363843921, 1.1151598018],
      direction: [-24.4151694064, -7.157792658, 7.649646785],
      "speed factor": 1.0971198563,
      "Uz factor": 0.9822672107,
      "Directional variance factor": 0.3637517637,
      "Velocity stability factor": 0.5957307783,
      "Direction stability factor": 0.9109310661,
      score: 0.6953361686,
    },
    "247.5": {
      velocity: [0.5456039111, 0.8287763602, 0.9995297709],
      direction: [-14.3845151761, 5.0130563087, 11.3061981079],
      "speed factor": 1.537480021,
      "Uz factor": 0.9937907971,
      "Directional variance factor": 0.5543949948,
      "Velocity stability factor": 0.5595340179,
      "Direction stability factor": 0.9286369075,
      score: 0.7428007069,
    },
    "292.5": {
      velocity: [0.8817562845, 1.1686453995, 1.3681114775],
      direction: [6.7595318475, 18.6002230171, 33.3920637992],
      "speed factor": 1.5914036637,
      "Uz factor": 0.9656616976,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.643336422,
      "Direction stability factor": 0.9260207446,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3008411391, 0.8665784697, 1.1373253065],
      direction: [-20.8916586181, -1.2115396766, 33.8296259975],
      "speed factor": 1.1005008729,
      "Uz factor": 0.9776756225,
      "Directional variance factor": 0.8923075843,
      "Velocity stability factor": 0.3262668161,
      "Direction stability factor": 0.8479964316,
      score: 0.7286418159,
    },
    id: "IDN:726.0",
    "45": {
      velocity: [0.1644249033, 0.5243802233, 1.0448777914],
      direction: [-153.7902485496, 7.7250167302, 97.5475937172],
      "speed factor": 0.7123084155,
      "Uz factor": 0.9958233516,
      "Directional variance factor": 0.3133318462,
      "Velocity stability factor": 0.2842196623,
      "Direction stability factor": 0.301839327,
      score: 0.0,
    },
    "90": {
      velocity: [0.4398853781, 1.0818940539, 1.4183952112],
      direction: [-35.4280213049, -12.034208221, 2.2342858353],
      "speed factor": 1.753781921,
      "Uz factor": 0.9845506669,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2858272285,
      "Direction stability factor": 0.8953824802,
      score: 0.0,
    },
    "135": {
      velocity: [0.2258952345, 0.6443343318, 0.9442738029],
      direction: [-77.2356003283, -37.1065054759, 8.3205819519],
      "speed factor": 0.545332571,
      "Uz factor": 0.9996092677,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4211227843,
      "Direction stability factor": 0.7623439381,
      score: 0.0,
    },
    "180": {
      velocity: [0.6219431312, 0.8501894002, 1.0384383168],
      direction: [-42.6459718371, -10.4787497865, 17.1438882134],
      "speed factor": 1.6604146464,
      "Uz factor": 0.9928430885,
      "Directional variance factor": 0.0685555745,
      "Velocity stability factor": 0.6718197528,
      "Direction stability factor": 0.8339170554,
      score: 0.6020523596,
    },
    "225": {
      velocity: [0.2007394621, 0.5413782481, 0.9074895388],
      direction: [-45.8979319621, 1.1585367356, 42.7344573024],
      "speed factor": 0.7390405546,
      "Uz factor": 0.9915272417,
      "Directional variance factor": 0.8970189568,
      "Velocity stability factor": 0.364786735,
      "Direction stability factor": 0.7537989187,
      score: 0.0,
    },
    "270": {
      velocity: [0.5905881834, 0.8911569788, 1.2011356332],
      direction: [-7.735862036, 23.2662400037, 48.0692156309],
      "speed factor": 0.8154374107,
      "Uz factor": 0.9769945765,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5183982152,
      "Direction stability factor": 0.8449858954,
      score: 0.0,
    },
    "315": {
      velocity: [0.7589069848, 1.1901041558, 1.3777046065],
      direction: [-2.2878569956, 7.6994521488, 26.6933101639],
      "speed factor": 1.4663267568,
      "Uz factor": 0.9842998157,
      "Directional variance factor": 0.3156042534,
      "Velocity stability factor": 0.4980091119,
      "Direction stability factor": 0.9194967579,
      score: 0.6631517203,
    },
  },
  {
    position: [-50.55130774, 35.7537903, 47.87999908],
    "overall score": 0.2851355095,
    "0": {
      velocity: [0.234598174, 0.9729831044, 1.4104484003],
      direction: [-29.6963403316, -11.6662096376, 27.989156271],
      "speed factor": 0.9970654571,
      "Uz factor": 0.9984131003,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1829830544,
      "Direction stability factor": 0.8397625094,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.213784934, 0.7202927988, 1.2991952449],
      direction: [-97.1081390462, -1.2446628563, 91.5154958457],
      "speed factor": 0.6395304378,
      "Uz factor": 0.9995635382,
      "Directional variance factor": 0.8893633017,
      "Velocity stability factor": 0.2980939698,
      "Direction stability factor": 0.4760454586,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3657048829, 0.6925175399, 1.063137921],
      direction: [-34.7369189076, 0.9060140416, 26.7904678566],
      "speed factor": 1.0727558361,
      "Uz factor": 0.9845817368,
      "Directional variance factor": 0.9194654185,
      "Velocity stability factor": 0.3768055518,
      "Direction stability factor": 0.8290905923,
      score: 0.7386130387,
    },
    "112.5": {
      velocity: [0.5081733028, 0.8683343052, 1.212480422],
      direction: [-45.0429482716, -23.8585235523, 5.8524468741],
      "speed factor": 0.9768154308,
      "Uz factor": 0.9633670684,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.416961164,
      "Direction stability factor": 0.8586239024,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5155714601, 0.9498769356, 1.1965410066],
      direction: [-43.9228194433, -11.5872476435, 5.1241278049],
      "speed factor": 1.3494338268,
      "Uz factor": 0.9712089064,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4431089308,
      "Direction stability factor": 0.8637584799,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4444187648, 0.9380513296, 1.1980884178],
      direction: [-25.1214055182, -3.7598137995, 5.9551659209],
      "speed factor": 1.0990729326,
      "Uz factor": 0.9762034193,
      "Directional variance factor": 0.6657943289,
      "Velocity stability factor": 0.4205018253,
      "Direction stability factor": 0.9136761904,
      score: 0.7284121338,
    },
    "247.5": {
      velocity: [0.5481332293, 0.8554945206, 1.0495486729],
      direction: [-18.2621457411, 4.371539445, 11.0220564666],
      "speed factor": 1.5870454282,
      "Uz factor": 0.9970977541,
      "Directional variance factor": 0.611418716,
      "Velocity stability factor": 0.5134526023,
      "Direction stability factor": 0.9186549939,
      score: 0.7405453265,
    },
    "292.5": {
      velocity: [0.2528937331, 0.9782486542, 1.3048691031],
      direction: [-7.8182101033, 10.3805334677, 53.2073451732],
      "speed factor": 1.332130767,
      "Uz factor": 0.9622623727,
      "Directional variance factor": 0.077285914,
      "Velocity stability factor": 0.2285446835,
      "Direction stability factor": 0.8304845687,
      score: 0.4916999337,
    },
    "337.5": {
      velocity: [0.1344627025, 0.6804407298, 1.114977853],
      direction: [-30.6386891681, -4.7868755145, 93.9086397168],
      "speed factor": 0.8641174958,
      "Uz factor": 0.9932424386,
      "Directional variance factor": 0.5744999543,
      "Velocity stability factor": 0.2102592972,
      "Direction stability factor": 0.6540351975,
      score: 0.0,
    },
    id: "IDN:750.0",
    "45": {
      velocity: [0.146991671, 0.5314393879, 0.9784484112],
      direction: [-86.870152975, 10.3351319867, 117.8861421269],
      "speed factor": 0.7218974544,
      "Uz factor": 0.9865983335,
      "Directional variance factor": 0.0813216012,
      "Velocity stability factor": 0.3240519802,
      "Direction stability factor": 0.4312325136,
      score: 0.0,
    },
    "90": {
      velocity: [0.323219149, 1.0678237593, 1.3338720392],
      direction: [-42.9752709891, -8.5037053461, 14.2110479731],
      "speed factor": 1.7309735617,
      "Uz factor": 0.9746423191,
      "Directional variance factor": 0.2441150803,
      "Velocity stability factor": 0.2623673762,
      "Direction stability factor": 0.841149114,
      score: 0.5471951711,
    },
    "135": {
      velocity: [0.1495270202, 0.6171759072, 0.9944270857],
      direction: [-133.7909184217, -32.313963208, 13.4712626032],
      "speed factor": 0.5223470295,
      "Uz factor": 0.9844926763,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3191703942,
      "Direction stability factor": 0.590938386,
      score: 0.0,
    },
    "180": {
      velocity: [0.5696732775, 0.8436256889, 1.0881123003],
      direction: [-40.6707021234, -8.1984557764, 15.0019791741],
      "speed factor": 1.6475957588,
      "Uz factor": 0.9866027814,
      "Directional variance factor": 0.2712483754,
      "Velocity stability factor": 0.5914924049,
      "Direction stability factor": 0.8453536631,
      score: 0.6383620266,
    },
    "225": {
      velocity: [0.1438993499, 0.5761750286, 0.9857290439],
      direction: [-41.051494716, 3.2398581333, 38.9485609332],
      "speed factor": 0.786541968,
      "Uz factor": 0.9951078189,
      "Directional variance factor": 0.7120126104,
      "Velocity stability factor": 0.2433797942,
      "Direction stability factor": 0.7777776232,
      score: 0.0,
    },
    "270": {
      velocity: [0.7047756381, 0.9980069243, 1.3595903185],
      direction: [1.5826539872, 23.1029015955, 51.9469658697],
      "speed factor": 0.9132085609,
      "Uz factor": 0.9866779196,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4834800819,
      "Direction stability factor": 0.8600991337,
      score: 0.0,
    },
    "315": {
      velocity: [0.30506235, 0.9882292097, 1.3516396041],
      direction: [-15.7444372876, -0.4228054706, 56.9816997941],
      "speed factor": 1.217596733,
      "Uz factor": 0.977683753,
      "Directional variance factor": 0.9624172915,
      "Velocity stability factor": 0.1509788874,
      "Direction stability factor": 0.7979829526,
      score: 0.677340521,
    },
  },
  {
    position: [-54.69268291, 35.06824222, 47.87999908],
    "overall score": 0.2810085029,
    "0": {
      velocity: [0.3966741973, 1.0439192458, 1.4445569877],
      direction: [-26.6938547382, -10.7279415637, 29.1925884784],
      "speed factor": 1.0697573424,
      "Uz factor": 0.9980885167,
      "Directional variance factor": 0.0464051943,
      "Velocity stability factor": 0.2718987694,
      "Direction stability factor": 0.84475988,
      score: 0.5019559309,
    },
    "22.5": {
      velocity: [0.2863790861, 0.7430099066, 1.4398861484],
      direction: [-84.6250918163, 2.0154241911, 95.6105478339],
      "speed factor": 0.6597004047,
      "Uz factor": 0.9997516971,
      "Directional variance factor": 0.820851183,
      "Velocity stability factor": 0.2540576087,
      "Direction stability factor": 0.4993454454,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1021544312, 0.5981390553, 1.1104135829],
      direction: [-98.7340730232, 4.5199925204, 85.1031874109],
      "speed factor": 0.9265572717,
      "Uz factor": 0.9946230021,
      "Directional variance factor": 0.5982228871,
      "Velocity stability factor": 0.099065471,
      "Direction stability factor": 0.4893409432,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3017315517, 0.8652726881, 1.1367172528],
      direction: [-68.4907256596, -23.8294073108, 1.839266478],
      "speed factor": 0.9733713254,
      "Uz factor": 0.9860824155,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3087829472,
      "Direction stability factor": 0.8046389107,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5775316941, 1.0292692802, 1.2911458651],
      direction: [-41.6225595159, -11.6245076768, 2.5035459252],
      "speed factor": 1.4622218221,
      "Uz factor": 0.9956229485,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4164124362,
      "Direction stability factor": 0.8774274849,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6191851918, 0.9667709372, 1.150504946],
      direction: [-26.1292800616, -8.487666808, 4.3489627481],
      "speed factor": 1.1327224167,
      "Uz factor": 0.9856123172,
      "Directional variance factor": 0.2455407282,
      "Velocity stability factor": 0.5914671282,
      "Direction stability factor": 0.9153382144,
      score: 0.6669210713,
    },
    "247.5": {
      velocity: [0.5183586354, 0.8250808645, 0.9880342141],
      direction: [-17.241014566, 3.7127321422, 9.6065171139],
      "speed factor": 1.530624431,
      "Uz factor": 0.988277746,
      "Directional variance factor": 0.6699793651,
      "Velocity stability factor": 0.544251312,
      "Direction stability factor": 0.9254235231,
      score: 0.7662694309,
    },
    "292.5": {
      velocity: [0.8717852511, 1.1717202639, 1.3556964346],
      direction: [1.6572372548, 16.3888594709, 26.4497908707],
      "speed factor": 1.5955908624,
      "Uz factor": 0.9512658893,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6451287113,
      "Direction stability factor": 0.9311317955,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4495745223, 0.8775992631, 1.1466085944],
      direction: [-22.6975456789, -2.4565393892, 21.7826587869],
      "speed factor": 1.1144965966,
      "Uz factor": 0.9746640887,
      "Directional variance factor": 0.7816409432,
      "Velocity stability factor": 0.43858473,
      "Direction stability factor": 0.8764438765,
      score: 0.7432783565,
    },
    id: "IDN:727.0",
    "45": {
      velocity: [0.1781702776, 0.5327841877, 1.1176980269],
      direction: [-176.9805250279, 8.7329168656, 138.4911729585],
      "speed factor": 0.7237242055,
      "Uz factor": 0.9987378659,
      "Directional variance factor": 0.2237407231,
      "Velocity stability factor": 0.2361936694,
      "Direction stability factor": 0.1236897278,
      score: 0.0,
    },
    "90": {
      velocity: [0.2387414321, 0.997916028, 1.390320203],
      direction: [-56.4403002597, -5.6311352762, 15.3463320594],
      "speed factor": 1.6176510836,
      "Uz factor": 0.9828638739,
      "Directional variance factor": 0.4994546421,
      "Velocity stability factor": 0.1595115608,
      "Direction stability factor": 0.800592688,
      score: 0.5650378947,
    },
    "135": {
      velocity: [0.2343688959, 0.641709026, 0.9521949013],
      direction: [-108.4380703586, -34.8319738786, 8.1006086138],
      "speed factor": 0.5431106426,
      "Uz factor": 0.9969386978,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4215680455,
      "Direction stability factor": 0.6762814473,
      score: 0.0,
    },
    "180": {
      velocity: [0.6300167442, 0.8899893245, 1.12758566],
      direction: [-49.3822142845, -11.1493529978, 14.2919296244],
      "speed factor": 1.7381436527,
      "Uz factor": 0.9962359205,
      "Directional variance factor": 0.0089464002,
      "Velocity stability factor": 0.6079371492,
      "Direction stability factor": 0.823127378,
      score: 0.5657845764,
    },
    "225": {
      velocity: [0.1665879756, 0.5568706727, 0.8992678331],
      direction: [-41.559688095, 0.5547761734, 39.5563485301],
      "speed factor": 0.7601894096,
      "Uz factor": 0.9891676524,
      "Directional variance factor": 0.9506865624,
      "Velocity stability factor": 0.3414815508,
      "Direction stability factor": 0.774677676,
      score: 0.0,
    },
    "270": {
      velocity: [0.6495809619, 0.9237284743, 1.2316578103],
      direction: [-8.1618346713, 21.8700379338, 49.9074555326],
      "speed factor": 0.8452413808,
      "Uz factor": 0.9637362101,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5408559169,
      "Direction stability factor": 0.8386964161,
      score: 0.0,
    },
    "315": {
      velocity: [0.7311407396, 1.166683907, 1.3436669828],
      direction: [-4.4543508979, 6.1319905294, 33.4312767716],
      "speed factor": 1.4374706796,
      "Uz factor": 0.9744231317,
      "Directional variance factor": 0.4549341752,
      "Velocity stability factor": 0.5030966797,
      "Direction stability factor": 0.8947621454,
      score: 0.6868887864,
    },
  },
  {
    position: [-45.85689357, 38.73742191, 47.87999908],
    "overall score": 0.2785107687,
    "0": {
      velocity: [0.3168951391, 0.8982559566, 1.5399160136],
      direction: [-27.552096907, -9.0651663598, 91.0783768968],
      "speed factor": 0.920488734,
      "Uz factor": 0.9949373839,
      "Directional variance factor": 0.1942074347,
      "Velocity stability factor": 0.1502074355,
      "Direction stability factor": 0.6704709061,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1972764714, 0.8285766201, 1.3130138837],
      direction: [-79.0442908316, -3.4376699159, 47.9982468887],
      "speed factor": 0.7356730061,
      "Uz factor": 0.9936352127,
      "Directional variance factor": 0.6944293408,
      "Velocity stability factor": 0.2784822385,
      "Direction stability factor": 0.6471040619,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4747632396, 0.6789604748, 1.0006528653],
      direction: [-27.7377311905, -2.3743701321, 24.8717364642],
      "speed factor": 1.0517550384,
      "Uz factor": 0.9840156267,
      "Directional variance factor": 0.7889448771,
      "Velocity stability factor": 0.5300889445,
      "Direction stability factor": 0.8538625898,
      score: 0.7566897503,
    },
    "112.5": {
      velocity: [0.4424026003, 0.8031759638, 1.1708659123],
      direction: [-43.0420662499, -21.8409334012, 6.2781075723],
      "speed factor": 0.9035168487,
      "Uz factor": 0.9501980122,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3969642079,
      "Direction stability factor": 0.8629995172,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5234371823, 0.9446842756, 1.1974865148],
      direction: [-40.3907546035, -0.103521401, 18.0607118664],
      "speed factor": 1.3420569227,
      "Uz factor": 0.9721804304,
      "Directional variance factor": 0.9907980977,
      "Velocity stability factor": 0.4487682226,
      "Direction stability factor": 0.8376348154,
      score: 0.7787089878,
    },
    "202.5": {
      velocity: [0.5410418021, 0.9255275697, 1.2098066901],
      direction: [-20.1545205957, -0.4119579857, 11.6739314251],
      "speed factor": 1.0843994013,
      "Uz factor": 0.9871566093,
      "Directional variance factor": 0.9633815124,
      "Velocity stability factor": 0.4857852769,
      "Direction stability factor": 0.9115876333,
      score: 0.818085514,
    },
    "247.5": {
      velocity: [0.4233195008, 0.8357595988, 1.0705531138],
      direction: [-29.7266414862, 2.6676328832, 11.6063790845],
      "speed factor": 1.5504347701,
      "Uz factor": 0.9994555325,
      "Directional variance factor": 0.762877077,
      "Velocity stability factor": 0.3719582552,
      "Direction stability factor": 0.885186054,
      score: 0.72630186,
    },
    "292.5": {
      velocity: [0.0498149952, 0.3292239554, 0.8989514796],
      direction: [-33.691544685, 20.1431195835, 138.4776821575],
      "speed factor": 0.4483209441,
      "Uz factor": 0.9827575913,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3772944938,
      "Direction stability factor": 0.5217521477,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0722100912, 0.5894825837, 1.1095964732],
      direction: [-152.0776066434, -6.4435732223, 159.8735744823],
      "speed factor": 0.7486062955,
      "Uz factor": 0.9999098442,
      "Directional variance factor": 0.4272379358,
      "Velocity stability factor": 0.1644532468,
      "Direction stability factor": 0.1334689413,
      score: 0.0,
    },
    id: "IDN:747.0",
    "45": {
      velocity: [0.1669545312, 0.5610422832, 1.050319326],
      direction: [-126.5058009101, 2.3200467804, 71.3463036995],
      "speed factor": 0.7621094809,
      "Uz factor": 0.9816153641,
      "Directional variance factor": 0.7937736195,
      "Velocity stability factor": 0.2818523742,
      "Direction stability factor": 0.4504108205,
      score: 0.0,
    },
    "90": {
      velocity: [0.5273018846, 1.002994381, 1.2613031805],
      direction: [-49.6533722253, -9.4164162116, 16.5670930563],
      "speed factor": 1.6258832424,
      "Uz factor": 0.9657659699,
      "Directional variance factor": 0.1629852256,
      "Velocity stability factor": 0.4642836259,
      "Direction stability factor": 0.8160542631,
      score: 0.5648443444,
    },
    "135": {
      velocity: [0.246654053, 0.6347632407, 0.8623889665],
      direction: [-113.9128722139, -30.7709461608, 20.5177618902],
      "speed factor": 0.5372320749,
      "Uz factor": 0.9641401025,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5038341509,
      "Direction stability factor": 0.6265815719,
      score: 0.0,
    },
    "180": {
      velocity: [0.5625996083, 0.8697190102, 1.1097718146],
      direction: [-18.8814356174, 1.2750397141, 18.8011430472],
      "speed factor": 1.6985558541,
      "Uz factor": 0.9859925554,
      "Directional variance factor": 0.8866631365,
      "Velocity stability factor": 0.5688518952,
      "Direction stability factor": 0.8953261704,
      score: 0.8115418431,
    },
    "225": {
      velocity: [0.1160095049, 0.5334915726, 0.9245498248],
      direction: [-43.5308163255, 4.3556461795, 43.7061164862],
      "speed factor": 0.7282743795,
      "Uz factor": 0.9986867152,
      "Directional variance factor": 0.6128314507,
      "Velocity stability factor": 0.2732996382,
      "Direction stability factor": 0.7576751866,
      score: 0.0,
    },
    "270": {
      velocity: [0.0947666254, 0.6276544269, 1.238075049],
      direction: [-85.2198490558, 16.8708905302, 144.1106845282],
      "speed factor": 0.5743240674,
      "Uz factor": 0.9976551352,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.098154652,
      "Direction stability factor": 0.36297074,
      score: 0.0,
    },
    "315": {
      velocity: [0.1157809365, 0.5683114659, 1.1909876049],
      direction: [-30.429373891, 1.2930053868, 84.9957000053],
      "speed factor": 0.7002162833,
      "Uz factor": 0.994762484,
      "Directional variance factor": 0.8850661878,
      "Velocity stability factor": 0.1277536768,
      "Direction stability factor": 0.6793747947,
      score: 0.0,
    },
  },
  {
    position: [-45.85200666, 40.13741357, 47.87999908],
    "overall score": 0.2778512325,
    "0": {
      velocity: [0.3137077823, 0.9006496241, 1.4698777677],
      direction: [-41.0936586331, -9.2438243837, 115.31034282],
      "speed factor": 0.9229416473,
      "Uz factor": 0.9958166678,
      "Directional variance factor": 0.1783267214,
      "Velocity stability factor": 0.1966574918,
      "Direction stability factor": 0.5655444404,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2938051316, 0.8286736309, 1.3217677337],
      direction: [-85.901365152, -3.0395373567, 49.8717902294],
      "speed factor": 0.7357591397,
      "Uz factor": 0.9924047488,
      "Directional variance factor": 0.7298189016,
      "Velocity stability factor": 0.3352438778,
      "Direction stability factor": 0.6228523462,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4544776694, 0.6683582611, 0.9997452122],
      direction: [-27.4792314839, -0.7176418877, 27.0318825921],
      "speed factor": 1.0353315026,
      "Uz factor": 0.9787348323,
      "Directional variance factor": 0.93620961,
      "Velocity stability factor": 0.5127737189,
      "Direction stability factor": 0.8485802387,
      score: 0.7865359516,
    },
    "112.5": {
      velocity: [0.4937861914, 0.8268093712, 1.1918209232],
      direction: [-40.7717774305, -20.8842372775, 10.3018738265],
      "speed factor": 0.930102781,
      "Uz factor": 0.9396887675,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4221535657,
      "Direction stability factor": 0.8581287465,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5742300538, 0.9741522169, 1.2348150281],
      direction: [-38.9431773251, 0.034089436, 27.1197058304],
      "speed factor": 1.3839202792,
      "Uz factor": 0.9760437,
      "Directional variance factor": 0.9969698279,
      "Velocity stability factor": 0.4597792595,
      "Direction stability factor": 0.8164919912,
      score: 0.7724332675,
    },
    "202.5": {
      velocity: [0.5067589074, 0.9004718872, 1.2130316301],
      direction: [-25.185581255, -1.4821193528, 15.3005698986],
      "speed factor": 1.055042775,
      "Uz factor": 0.9889127265,
      "Directional variance factor": 0.8682560575,
      "Velocity stability factor": 0.4569454242,
      "Direction stability factor": 0.887538469,
      score: 0.7750696049,
    },
    "247.5": {
      velocity: [0.4747182199, 0.8330629669, 1.0718662014],
      direction: [-31.4356280377, 3.2865038041, 12.8383893355],
      "speed factor": 1.5454321929,
      "Uz factor": 0.999684498,
      "Directional variance factor": 0.7078663285,
      "Velocity stability factor": 0.42055874,
      "Direction stability factor": 0.8770166184,
      score: 0.7206145763,
    },
    "292.5": {
      velocity: [0.0581654153, 0.3353197057, 0.84344921],
      direction: [-32.9677312408, 18.3393013247, 131.5423057312],
      "speed factor": 0.4566218362,
      "Uz factor": 0.9834319133,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4241202069,
      "Direction stability factor": 0.5430276751,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1199940316, 0.591295587, 1.143428311],
      direction: [-162.2757200176, -5.1459057295, 143.1302267933],
      "speed factor": 0.7509086972,
      "Uz factor": 0.9999296988,
      "Directional variance factor": 0.5425861574,
      "Velocity stability factor": 0.1756907512,
      "Direction stability factor": 0.1516501477,
      score: 0.0,
    },
    id: "IDN:746.0",
    "45": {
      velocity: [0.1656548113, 0.557083799, 1.0533820508],
      direction: [-131.765344132, 2.727920272, 76.1067676712],
      "speed factor": 0.7567323489,
      "Uz factor": 0.9805935397,
      "Directional variance factor": 0.757518198,
      "Velocity stability factor": 0.2783058448,
      "Direction stability factor": 0.4225774672,
      score: 0.0,
    },
    "90": {
      velocity: [0.5519214888, 1.0141177726, 1.2857236054],
      direction: [-51.9136834648, -8.2782186055, 20.6737499464],
      "speed factor": 1.6439145857,
      "Uz factor": 0.9594477749,
      "Directional variance factor": 0.2641583462,
      "Velocity stability factor": 0.4644289984,
      "Direction stability factor": 0.7983682405,
      score: 0.5813309564,
    },
    "135": {
      velocity: [0.2236490111, 0.6576077766, 0.9375403429],
      direction: [-111.9339125658, -30.4459525666, 20.9350474646],
      "speed factor": 0.5565665553,
      "Uz factor": 0.95857915,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.424738648,
      "Direction stability factor": 0.6309195555,
      score: 0.0,
    },
    "180": {
      velocity: [0.5181687229, 0.8866587418, 1.1369497423],
      direction: [-21.8148981338, 0.6176328032, 17.6024178521],
      "speed factor": 1.7316390453,
      "Uz factor": 0.9873271448,
      "Directional variance factor": 0.9450993064,
      "Velocity stability factor": 0.5124272383,
      "Direction stability factor": 0.8905074556,
      score: 0.809635364,
    },
    "225": {
      velocity: [0.0958059036, 0.5158624419, 0.9032990882],
      direction: [-63.1291595067, 3.1032029452, 36.0986359946],
      "speed factor": 0.7042086869,
      "Uz factor": 0.9991332668,
      "Directional variance factor": 0.7241597382,
      "Velocity stability factor": 0.274240783,
      "Direction stability factor": 0.7243672347,
      score: 0.0,
    },
    "270": {
      velocity: [0.1334939387, 0.6304282909, 1.2455194222],
      direction: [-42.5669521198, 16.4532914778, 145.9593399686],
      "speed factor": 0.5768622424,
      "Uz factor": 0.9974413749,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1228307354,
      "Direction stability factor": 0.4763158553,
      score: 0.0,
    },
    "315": {
      velocity: [0.125470621, 0.5806232554, 1.1926376303],
      direction: [-26.9705569099, 1.7879760943, 84.7660200164],
      "speed factor": 0.7153856332,
      "Uz factor": 0.9953713807,
      "Directional variance factor": 0.8410687916,
      "Velocity stability factor": 0.1342757374,
      "Direction stability factor": 0.6896206196,
      score: 0.0,
    },
  },
  {
    position: [-52.61952834, 79.68102978, 47.87999908],
    "overall score": 0.2670698258,
    "0": {
      velocity: [0.5550059142, 1.1349462318, 1.4445300004],
      direction: [-18.773078523, -4.2723387169, 50.3929406722],
      "speed factor": 1.1630373419,
      "Uz factor": 0.9999006908,
      "Directional variance factor": 0.6202365585,
      "Velocity stability factor": 0.3819312735,
      "Direction stability factor": 0.8078721689,
      score: 0.6544780424,
    },
    "22.5": {
      velocity: [0.1001091478, 0.7613502751, 1.2894838345],
      direction: [-123.6199705917, 18.7855681872, 169.882890265],
      "speed factor": 0.6759843713,
      "Uz factor": 0.9962908702,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2308629683,
      "Direction stability factor": 0.1847142754,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0923415542, 0.4728557923, 1.0584844952],
      direction: [-139.8768226367, 3.6847079958, 87.5805598458],
      "speed factor": 0.7324851452,
      "Uz factor": 0.9945642606,
      "Directional variance factor": 0.6724704004,
      "Velocity stability factor": 0.1366986017,
      "Direction stability factor": 0.3681739375,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3907592959, 0.8819285721, 1.2162133518],
      direction: [-33.3847197185, -1.6230379431, 25.6957856625],
      "speed factor": 0.9921080313,
      "Uz factor": 0.9851330811,
      "Directional variance factor": 0.8557299606,
      "Velocity stability factor": 0.3166734244,
      "Direction stability factor": 0.8358874851,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4527977611, 0.921517155, 1.2217237138],
      direction: [-51.7370704665, -17.04488743, 3.5122088971],
      "speed factor": 1.3091447685,
      "Uz factor": 0.9903257687,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3711789343,
      "Direction stability factor": 0.8465297795,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4809319497, 0.9772846329, 1.3223401624],
      direction: [-60.2039373346, -12.1787768789, 7.5606241639],
      "speed factor": 1.1450408454,
      "Uz factor": 0.9983257194,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.353039463,
      "Direction stability factor": 0.8117651069,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2292606856, 0.4461851269, 0.6774374888],
      direction: [-30.8772655476, -0.6460074403, 43.0301631977],
      "speed factor": 0.8277271786,
      "Uz factor": 0.9883784558,
      "Directional variance factor": 0.9425771164,
      "Velocity stability factor": 0.5651126027,
      "Direction stability factor": 0.7947015868,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4940939123, 0.9553721282, 1.2903467707],
      direction: [-33.773064879, -1.2432032337, 29.9670798602],
      "speed factor": 1.3009786422,
      "Uz factor": 0.9598885921,
      "Directional variance factor": 0.8894930459,
      "Velocity stability factor": 0.416076157,
      "Direction stability factor": 0.8229440424,
      score: 0.7378643219,
    },
    "337.5": {
      velocity: [0.7715787191, 1.0155003637, 1.2660882691],
      direction: [-9.8041918359, 2.8749930598, 23.8430658551],
      "speed factor": 1.2896224356,
      "Uz factor": 0.9871976676,
      "Directional variance factor": 0.7444450614,
      "Velocity stability factor": 0.6017049615,
      "Direction stability factor": 0.9065353953,
      score: 0.7898052034,
    },
    id: "IDN:705.0",
    "45": {
      velocity: [0.129971017, 0.4895455429, 1.1265391262],
      direction: [-50.8362237978, 1.5656997056, 95.1206674378],
      "speed factor": 0.6649896286,
      "Uz factor": 0.9991589626,
      "Directional variance factor": 0.8608266928,
      "Velocity stability factor": 0.1898216618,
      "Direction stability factor": 0.594564191,
      score: 0.0,
    },
    "90": {
      velocity: [0.2609349133, 0.990206271, 1.3874249108],
      direction: [-121.2467235447, 4.1632532092, 28.5788312133],
      "speed factor": 1.6051533419,
      "Uz factor": 0.9901603968,
      "Directional variance factor": 0.6299330481,
      "Velocity stability factor": 0.177822791,
      "Direction stability factor": 0.5838179034,
      score: 0.4938479115,
    },
    "135": {
      velocity: [0.5065785391, 1.0375682492, 1.2907893371],
      direction: [-54.4965742241, -20.5246675305, 20.3689350538],
      "speed factor": 0.8781462247,
      "Uz factor": 0.9923064197,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.368074462,
      "Direction stability factor": 0.792040252,
      score: 0.0,
    },
    "180": {
      velocity: [0.5164088755, 0.8648743362, 1.1849952425],
      direction: [-53.3087483737, -25.4811021725, -3.3417402457],
      "speed factor": 1.6890942358,
      "Uz factor": 0.9988272034,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4731827719,
      "Direction stability factor": 0.8612027552,
      score: 0.0,
    },
    "225": {
      velocity: [0.5283904328, 0.7588058753, 1.0501483431],
      direction: [-15.962465699, 1.7364085681, 21.177804461],
      "speed factor": 1.0358530601,
      "Uz factor": 0.9940137115,
      "Directional variance factor": 0.8456525717,
      "Velocity stability factor": 0.5310541071,
      "Direction stability factor": 0.8968325829,
      score: 0.7925929612,
    },
    "270": {
      velocity: [0.803027783, 1.0301607387, 1.2543473618],
      direction: [-16.7090948936, 8.1301489544, 24.6600687019],
      "speed factor": 0.9426303394,
      "Uz factor": 0.9563666548,
      "Directional variance factor": 0.2773200929,
      "Velocity stability factor": 0.6439976701,
      "Direction stability factor": 0.8850856567,
      score: 0.0,
    },
    "315": {
      velocity: [0.6877381487, 0.9226002155, 1.1919885194],
      direction: [-6.872940376, 3.0110954179, 12.0569295194],
      "speed factor": 1.1367352808,
      "Uz factor": 0.975555792,
      "Directional variance factor": 0.732347074,
      "Velocity stability factor": 0.590933962,
      "Direction stability factor": 0.9474170281,
      score: 0.804528773,
    },
  },
  {
    position: [-52.20709274, 79.56483697, 47.87999908],
    "overall score": 0.2651169262,
    "0": {
      velocity: [0.4060799497, 1.1048236506, 1.4158686483],
      direction: [-19.5132032365, -4.4810445092, 62.7347141138],
      "speed factor": 1.1321691952,
      "Uz factor": 0.9999994767,
      "Directional variance factor": 0.6016849325,
      "Velocity stability factor": 0.2983677175,
      "Direction stability factor": 0.7715335629,
      score: 0.610779944,
    },
    "22.5": {
      velocity: [0.1254092962, 0.7636164888, 1.2760840288],
      direction: [-141.2323876598, 19.9287300934, 177.1080964046],
      "speed factor": 0.6779964873,
      "Uz factor": 0.9971067661,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2558892011,
      "Direction stability factor": 0.1157208776,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0707419355, 0.4729804801, 1.0672552431],
      direction: [-139.2941907761, 3.300704142, 79.6953347445],
      "speed factor": 0.7326782949,
      "Uz factor": 0.9928719095,
      "Directional variance factor": 0.7066040763,
      "Velocity stability factor": 0.1095610231,
      "Direction stability factor": 0.3916957624,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3883435401, 0.8840075349, 1.2152379642],
      direction: [-34.535485172, -1.8972535181, 25.1303639228],
      "speed factor": 0.9944467192,
      "Uz factor": 0.9845169257,
      "Directional variance factor": 0.8313552428,
      "Velocity stability factor": 0.3154810603,
      "Direction stability factor": 0.8342615303,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.44976478, 0.9166735285, 1.2076170105],
      direction: [-51.1079448756, -16.8728650792, 3.8862097376],
      "speed factor": 1.3022637155,
      "Uz factor": 0.9888588625,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3802349295,
      "Direction stability factor": 0.8472384594,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4704300245, 0.9571524352, 1.3034209266],
      direction: [-59.9232075666, -12.1401005839, 7.8941817451],
      "speed factor": 1.1214528466,
      "Uz factor": 0.9979001789,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3595115507,
      "Direction stability factor": 0.811618363,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2351191743, 0.4521251863, 0.6931437934],
      direction: [-31.5764888776, -0.9020429616, 42.5200144116],
      "speed factor": 0.8387467046,
      "Uz factor": 0.9890451643,
      "Directional variance factor": 0.9198184034,
      "Velocity stability factor": 0.5555567957,
      "Direction stability factor": 0.7941763798,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4904797926, 0.9642972819, 1.3023068554],
      direction: [-36.8286928411, -1.7991514932, 29.7149455326],
      "speed factor": 1.3131324763,
      "Uz factor": 0.960818585,
      "Directional variance factor": 0.8400754228,
      "Velocity stability factor": 0.4046549743,
      "Direction stability factor": 0.8151565601,
      score: 0.7187608793,
    },
    "337.5": {
      velocity: [0.6878140217, 1.0132132537, 1.2640341578],
      direction: [-10.094362284, 2.4668602729, 26.2800597165],
      "speed factor": 1.2867179478,
      "Uz factor": 0.9876646417,
      "Directional variance factor": 0.7807235313,
      "Velocity stability factor": 0.5358924388,
      "Direction stability factor": 0.8989599389,
      score: 0.778633962,
    },
    id: "IDN:706.0",
    "45": {
      velocity: [0.0273434714, 0.4835388763, 1.1171549007],
      direction: [-127.1938929099, 1.739903456, 97.9006536424],
      "speed factor": 0.6568302836,
      "Uz factor": 0.9981508378,
      "Directional variance factor": 0.845341915,
      "Velocity stability factor": 0.114017793,
      "Direction stability factor": 0.3747373707,
      score: 0.0,
    },
    "90": {
      velocity: [0.2829224974, 0.9992095784, 1.3884799764],
      direction: [-96.8173971313, 4.0136101526, 29.3819241871],
      "speed factor": 1.6197479667,
      "Uz factor": 0.9896706132,
      "Directional variance factor": 0.6432346531,
      "Velocity stability factor": 0.1931005473,
      "Direction stability factor": 0.6494463297,
      score: 0.5338069649,
    },
    "135": {
      velocity: [0.5132876899, 1.0391806825, 1.2849516978],
      direction: [-52.8255493913, -20.6040935935, 19.1724894754],
      "speed factor": 0.8795109082,
      "Uz factor": 0.9912234963,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3781848011,
      "Direction stability factor": 0.8000054476,
      score: 0.0,
    },
    "180": {
      velocity: [0.5036622781, 0.8611779719, 1.1816968198],
      direction: [-53.8040552932, -25.5238907091, -2.7987324719],
      "speed factor": 1.681875259,
      "Uz factor": 0.9981316195,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4657380176,
      "Direction stability factor": 0.8583185477,
      score: 0.0,
    },
    "225": {
      velocity: [0.5319027549, 0.7587643399, 1.0538849783],
      direction: [-16.0272690027, 1.9167859773, 22.8344303439],
      "speed factor": 1.0357963597,
      "Uz factor": 0.9939699303,
      "Directional variance factor": 0.8296190242,
      "Velocity stability factor": 0.5308524989,
      "Direction stability factor": 0.8920508351,
      score: 0.7861432984,
    },
    "270": {
      velocity: [0.8127652284, 1.0432170931, 1.2712952237],
      direction: [-16.3699078947, 8.1270770843, 24.4462218471],
      "speed factor": 0.9545773252,
      "Uz factor": 0.9590988929,
      "Directional variance factor": 0.2775931481,
      "Velocity stability factor": 0.6383100704,
      "Direction stability factor": 0.8866218618,
      score: 0.0,
    },
    "315": {
      velocity: [0.6753598954, 0.925663912, 1.1979714428],
      direction: [-7.7955416302, 2.3862805248, 11.8139837549],
      "speed factor": 1.1405100598,
      "Uz factor": 0.9762318107,
      "Directional variance factor": 0.7878861756,
      "Velocity stability factor": 0.5760387151,
      "Direction stability factor": 0.9455290962,
      score: 0.8137457707,
    },
  },
  {
    position: [-52.91929065, 80.0059607, 47.87999908],
    "overall score": 0.2642197654,
    "0": {
      velocity: [0.5113802299, 1.1630718228, 1.5021147858],
      direction: [-18.1039339645, -3.9836651915, 52.2471879297],
      "speed factor": 1.1918590708,
      "Uz factor": 0.9996545397,
      "Directional variance factor": 0.6458964274,
      "Velocity stability factor": 0.3116071225,
      "Direction stability factor": 0.804580217,
      score: 0.641665996,
    },
    "22.5": {
      velocity: [0.2160681205, 0.7555090659, 1.2755171751],
      direction: [-167.5387747593, 17.8378167801, 167.2331010783],
      "speed factor": 0.6707981039,
      "Uz factor": 0.9956676232,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.314882425,
      "Direction stability factor": 0.0700781227,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0643021405, 0.4717215072, 1.0215848726],
      direction: [-144.1156926682, 4.3056227737, 172.9106545506],
      "speed factor": 0.730728062,
      "Uz factor": 0.9962542317,
      "Directional variance factor": 0.6172779757,
      "Velocity stability factor": 0.1446156814,
      "Direction stability factor": 0.1193712577,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3676984748, 0.8764221129, 1.2159698188],
      direction: [-31.7143802067, -1.2008056287, 25.9393219705],
      "speed factor": 0.9859136494,
      "Uz factor": 0.9857787721,
      "Directional variance factor": 0.8932617219,
      "Velocity stability factor": 0.2977848392,
      "Direction stability factor": 0.8398508273,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.450840059, 0.9267819521, 1.2342967304],
      direction: [-53.2002631182, -17.1417763888, 3.9390893963],
      "speed factor": 1.3166241534,
      "Uz factor": 0.9911336289,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3592958368,
      "Direction stability factor": 0.8412795763,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5175652051, 0.99673914, 1.3352838776],
      direction: [-58.7503561743, -12.1456421225, 7.5039193825],
      "speed factor": 1.1678348242,
      "Uz factor": 0.9986614743,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3712544001,
      "Direction stability factor": 0.8159603457,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2240287845, 0.4410078111, 0.6631054351],
      direction: [-29.6658811636, -0.2884937135, 43.2394079735],
      "speed factor": 0.8181226338,
      "Uz factor": 0.9878075957,
      "Directional variance factor": 0.9743561144,
      "Velocity stability factor": 0.5739429161,
      "Direction stability factor": 0.797485308,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4941512807, 0.9443739278, 1.2753205005],
      direction: [-32.8862921576, -1.0129699583, 30.3219502236],
      "speed factor": 1.2860018354,
      "Uz factor": 0.956821423,
      "Directional variance factor": 0.9099582259,
      "Velocity stability factor": 0.4271375881,
      "Direction stability factor": 0.8244215489,
      score: 0.746484728,
    },
    "337.5": {
      velocity: [0.7974879534, 1.0172042199, 1.2667346169],
      direction: [-9.5849328932, 3.1975845999, 22.747357281],
      "speed factor": 1.2917862272,
      "Uz factor": 0.9864652505,
      "Directional variance factor": 0.7157702578,
      "Velocity stability factor": 0.6220525613,
      "Direction stability factor": 0.9101880828,
      score: 0.7895497462,
    },
    id: "IDN:704.0",
    "45": {
      velocity: [0.1052064242, 0.4989927608, 1.1335164158],
      direction: [-52.5110743177, 1.7504282981, 94.9555099403],
      "speed factor": 0.6778225551,
      "Uz factor": 0.9998352838,
      "Directional variance factor": 0.8444063735,
      "Velocity stability factor": 0.1640165158,
      "Direction stability factor": 0.5903705993,
      score: 0.0,
    },
    "90": {
      velocity: [0.2542809829, 0.9740724544, 1.388931761],
      direction: [-139.9059632914, 4.5240768273, 28.1921755045],
      "speed factor": 1.578999953,
      "Uz factor": 0.9902868665,
      "Directional variance factor": 0.5978598376,
      "Velocity stability factor": 0.1718665838,
      "Direction stability factor": 0.5330607256,
      score: 0.4589619681,
    },
    "135": {
      velocity: [0.5019438337, 1.0263436611, 1.2977941978],
      direction: [-57.542515449, -20.3045294045, 22.0014449681],
      "speed factor": 0.8686462909,
      "Uz factor": 0.9933993401,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3586951738,
      "Direction stability factor": 0.7790445544,
      score: 0.0,
    },
    "180": {
      velocity: [0.5287411844, 0.8729574129, 1.1969575539],
      direction: [-51.6329372588, -25.4554346873, -0.2959972948],
      "speed factor": 1.7048804346,
      "Uz factor": 0.999384486,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4734743139,
      "Direction stability factor": 0.857397389,
      score: 0.0,
    },
    "225": {
      velocity: [0.5278073927, 0.7632533114, 1.0503177315],
      direction: [-15.8262267088, 1.6060203826, 20.9087661111],
      "speed factor": 1.041924297,
      "Uz factor": 0.9939749982,
      "Directional variance factor": 0.8572426327,
      "Velocity stability factor": 0.530377839,
      "Direction stability factor": 0.8979583533,
      score: 0.7958842946,
    },
    "270": {
      velocity: [0.7938735372, 1.0199151299, 1.2394511411],
      direction: [-16.9483477055, 7.9298699916, 24.5734703888],
      "speed factor": 0.9332552765,
      "Uz factor": 0.9526056751,
      "Directional variance factor": 0.2951226674,
      "Velocity stability factor": 0.6485269583,
      "Direction stability factor": 0.8846616164,
      score: 0.0,
    },
    "315": {
      velocity: [0.6896576391, 0.9201412132, 1.1875568424],
      direction: [-6.3440601335, 3.4779861563, 12.9256374977],
      "speed factor": 1.1337055453,
      "Uz factor": 0.9740619531,
      "Directional variance factor": 0.690845675,
      "Velocity stability factor": 0.5960862575,
      "Direction stability factor": 0.9464730621,
      score: 0.7949695142,
    },
  },
  {
    position: [-52.13536577, 92.22471892, 47.87999908],
    "overall score": 0.2642011979,
    "0": {
      velocity: [0.8772771444, 1.2367114164, 1.4119999796],
      direction: [-9.3618978249, 1.3255665585, 22.7748660576],
      "speed factor": 1.2673213216,
      "Uz factor": 0.9821643878,
      "Directional variance factor": 0.8821718615,
      "Velocity stability factor": 0.6284581082,
      "Direction stability factor": 0.9107312114,
      score: 0.8330230981,
    },
    "22.5": {
      velocity: [0.3508408912, 0.9354150633, 1.4489103477],
      direction: [-41.2437514165, 14.3587199028, 57.8587459283],
      "speed factor": 0.8305322585,
      "Uz factor": 0.9778765503,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2899076365,
      "Direction stability factor": 0.7247152852,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2926704965, 0.6676858268, 1.1997193603],
      direction: [-29.0406710362, -4.5195551295, 35.5261389881],
      "speed factor": 1.034289857,
      "Uz factor": 0.99989371,
      "Directional variance factor": 0.5982617663,
      "Velocity stability factor": 0.1895023819,
      "Direction stability factor": 0.8206477499,
      score: 0.607264912,
    },
    "112.5": {
      velocity: [0.0983173839, 0.3819168475, 0.967624661],
      direction: [-142.5727944707, 51.886250849, 175.7030686477],
      "speed factor": 0.4296297724,
      "Uz factor": 0.9912159095,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2803708935,
      "Direction stability factor": 0.1159003802,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1312796319, 0.5784296113, 1.0588389084],
      direction: [-121.7274475834, -8.375758311, 163.9672458943],
      "speed factor": 0.8217406431,
      "Uz factor": 0.9981608606,
      "Directional variance factor": 0.2554881501,
      "Velocity stability factor": 0.2414499592,
      "Direction stability factor": 0.2064036292,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6013582455, 1.1006119351, 1.4474103523],
      direction: [-42.8816557591, -9.5746308081, 9.1439003175],
      "speed factor": 1.2895379485,
      "Uz factor": 0.998813594,
      "Directional variance factor": 0.1489217059,
      "Velocity stability factor": 0.3494687631,
      "Direction stability factor": 0.8554845665,
      score: 0.5523399005,
    },
    "247.5": {
      velocity: [0.2444373498, 0.4466258016, 0.7802370022],
      direction: [-45.7742220174, -1.6640648743, 32.3361763753],
      "speed factor": 0.8285446833,
      "Uz factor": 0.9630577622,
      "Directional variance factor": 0.8520831223,
      "Velocity stability factor": 0.4800879592,
      "Direction stability factor": 0.7830266711,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3935900852, 0.7669113114, 1.070585544],
      direction: [-49.2942508636, -18.5343777429, 35.3494003119],
      "speed factor": 1.0443419974,
      "Uz factor": 0.9275537062,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5035323442,
      "Direction stability factor": 0.7648787467,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7429027358, 0.9884012501, 1.1936835962],
      direction: [-2.1637137442, 7.6242843882, 25.9831396759],
      "speed factor": 1.2552082432,
      "Uz factor": 0.9587363507,
      "Directional variance factor": 0.3222858322,
      "Velocity stability factor": 0.6369255555,
      "Direction stability factor": 0.9218142961,
      score: 0.700709995,
    },
    id: "IDN:699.0",
    "45": {
      velocity: [0.1467980204, 0.7193318748, 1.3768581962],
      direction: [-34.2133062313, -4.3033661986, 153.449584805],
      "speed factor": 0.9771271401,
      "Uz factor": 0.9908955553,
      "Directional variance factor": 0.6174785601,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.4787141916,
      score: 0.0,
    },
    "90": {
      velocity: [0.0771251113, 0.4768608695, 1.2222669081],
      direction: [-149.4934312503, 27.1112977086, 176.0431072157],
      "speed factor": 0.7730054239,
      "Uz factor": 0.9999240342,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1642096348,
      "Direction stability factor": 0.0957318376,
      score: 0.0,
    },
    "135": {
      velocity: [0.049502652, 0.3139922801, 1.0135298346],
      direction: [-178.4868422285, 63.9768196435, 179.6902000092],
      "speed factor": 0.2657474682,
      "Uz factor": 0.9643225179,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2231764756,
      "Direction stability factor": 0.0050637716,
      score: 0.0,
    },
    "180": {
      velocity: [0.2206723995, 0.8218976291, 1.3568415454],
      direction: [-66.4339180512, -27.5337837861, 57.2628270786],
      "speed factor": 1.6051609923,
      "Uz factor": 0.9958331931,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1047477041,
      "Direction stability factor": 0.6563979302,
      score: 0.0,
    },
    "225": {
      velocity: [0.5131743706, 0.9029418549, 1.1613477136],
      direction: [-16.5158484784, -1.7371389516, 14.5723268119],
      "speed factor": 1.2326144459,
      "Uz factor": 0.9933088942,
      "Directional variance factor": 0.8455876487,
      "Velocity stability factor": 0.4174343674,
      "Direction stability factor": 0.9136439575,
      score: 0.7725774828,
    },
    "270": {
      velocity: [0.7449379386, 1.051462172, 1.2929551666],
      direction: [-23.7943790694, -8.4964650841, 6.2673682437],
      "speed factor": 0.9621218387,
      "Uz factor": 0.9543689699,
      "Directional variance factor": 0.2447586592,
      "Velocity stability factor": 0.5677222546,
      "Direction stability factor": 0.9164951464,
      score: 0.0,
    },
    "315": {
      velocity: [0.6866145618, 0.8468844013, 1.0443717192],
      direction: [-11.0764759792, 5.2812806742, 24.0437273897],
      "speed factor": 1.0434458627,
      "Uz factor": 0.954732531,
      "Directional variance factor": 0.530552829,
      "Velocity stability factor": 0.7097745259,
      "Direction stability factor": 0.9024438795,
      score: 0.7613037785,
    },
  },
  {
    position: [-50.56305378, 32.38881079, 47.87999908],
    "overall score": 0.2640508785,
    "0": {
      velocity: [0.2556505952, 0.9643893291, 1.5117681309],
      direction: [-34.9564960056, -11.4271291333, 32.6073147885],
      "speed factor": 0.9882589768,
      "Uz factor": 0.9991521437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1272108561,
      "Direction stability factor": 0.8123227478,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2525590521, 0.711502108, 1.2383422169],
      direction: [-79.8970542661, -7.5215643612, 52.5797702782],
      "speed factor": 0.6317253975,
      "Uz factor": 0.9957495769,
      "Directional variance factor": 0.3314165012,
      "Velocity stability factor": 0.3625202001,
      "Direction stability factor": 0.6320088207,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4430996549, 0.730494635, 1.0114095298],
      direction: [-25.7931878404, -4.5790858269, 25.7262409433],
      "speed factor": 1.1315848881,
      "Uz factor": 0.9897322898,
      "Directional variance factor": 0.5929701487,
      "Velocity stability factor": 0.4921841387,
      "Direction stability factor": 0.8568904756,
      score: 0.6997338097,
    },
    "112.5": {
      velocity: [0.3757183559, 0.8151584384, 1.2086325077],
      direction: [-52.5193336955, -26.8551394388, 11.2412657023],
      "speed factor": 0.9169962956,
      "Uz factor": 0.9800162551,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3104978151,
      "Direction stability factor": 0.8228872239,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5039648937, 0.9314157141, 1.193059548],
      direction: [-38.8224601226, -8.4657471708, 10.9076723374],
      "speed factor": 1.3232070643,
      "Uz factor": 0.9820451647,
      "Directional variance factor": 0.2474891404,
      "Velocity stability factor": 0.4364642871,
      "Direction stability factor": 0.8618607432,
      score: 0.6019187285,
    },
    "202.5": {
      velocity: [0.5205097482, 0.9271177463, 1.1419096895],
      direction: [-19.4727842958, -0.6750345704, 10.2901073331],
      "speed factor": 1.0862625404,
      "Uz factor": 0.9766865363,
      "Directional variance factor": 0.9399969271,
      "Velocity stability factor": 0.5222042836,
      "Direction stability factor": 0.917325301,
      score: 0.8242129532,
    },
    "247.5": {
      velocity: [0.5800495057, 0.866295292, 1.0634356139],
      direction: [-13.5972835691, 4.0990618887, 11.0221010979],
      "speed factor": 1.6070821608,
      "Uz factor": 0.9979067549,
      "Directional variance factor": 0.6356389432,
      "Velocity stability factor": 0.5309473291,
      "Direction stability factor": 0.9316128204,
      score: 0.7574529783,
    },
    "292.5": {
      velocity: [0.2451127531, 1.0382438905, 1.3284452997],
      direction: [-0.5467464841, 14.0099989429, 72.4253753384],
      "speed factor": 1.4138293206,
      "Uz factor": 0.9706885228,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2055492206,
      "Direction stability factor": 0.7972996616,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1587728071, 0.6607863618, 1.1321425427],
      direction: [-36.2510412198, -3.6993082338, 102.6071860183],
      "speed factor": 0.8391576682,
      "Uz factor": 0.994115571,
      "Directional variance factor": 0.6711726014,
      "Velocity stability factor": 0.2160144607,
      "Direction stability factor": 0.6142827021,
      score: 0.0,
    },
    id: "IDN:753.0",
    "45": {
      velocity: [0.121036632, 0.54384377, 1.0785922101],
      direction: [-38.5205386351, 8.1938026373, 81.9625682947],
      "speed factor": 0.738747338,
      "Uz factor": 0.9846631687,
      "Directional variance factor": 0.2716619878,
      "Velocity stability factor": 0.221537615,
      "Direction stability factor": 0.665324703,
      score: 0.0,
    },
    "90": {
      velocity: [0.7055889682, 1.0712350819, 1.3030440052],
      direction: [-36.465382999, -12.3352151358, 3.2097539447],
      "speed factor": 1.7365034155,
      "Uz factor": 0.9787818405,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5639429414,
      "Direction stability factor": 0.8897912863,
      score: 0.0,
    },
    "135": {
      velocity: [0.2679299231, 0.5991357188, 0.9372793747],
      direction: [-86.4219696541, -36.1608123367, 14.2124694616],
      "speed factor": 0.5070787102,
      "Uz factor": 0.9937327142,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4606309765,
      "Direction stability factor": 0.7204598913,
      score: 0.0,
    },
    "180": {
      velocity: [0.5120528269, 0.8229305007, 1.0421098096],
      direction: [-24.698124857, -4.406965377, 16.419966249],
      "speed factor": 1.6071781841,
      "Uz factor": 0.9874448111,
      "Directional variance factor": 0.6082697443,
      "Velocity stability factor": 0.5823379534,
      "Direction stability factor": 0.8857830803,
      score: 0.7405434646,
    },
    "225": {
      velocity: [0.2095051732, 0.5624879614, 0.9730200959],
      direction: [-34.7332083414, 3.957002642, 38.6132619488],
      "speed factor": 0.7678576235,
      "Uz factor": 0.9927458151,
      "Directional variance factor": 0.6482664318,
      "Velocity stability factor": 0.313767592,
      "Direction stability factor": 0.7962598047,
      score: 0.0,
    },
    "270": {
      velocity: [0.5849104678, 0.970550921, 1.4222597258],
      direction: [-2.063341658, 23.463791716, 40.9473058958],
      "speed factor": 0.8880854314,
      "Uz factor": 0.992741028,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3394962222,
      "Direction stability factor": 0.880525979,
      score: 0.0,
    },
    "315": {
      velocity: [0.3994330407, 1.0144770826, 1.3475889307],
      direction: [-7.0746273319, 4.6880143725, 66.7795461314],
      "speed factor": 1.2499367245,
      "Uz factor": 0.9840023108,
      "Directional variance factor": 0.5832876113,
      "Velocity stability factor": 0.2308218381,
      "Direction stability factor": 0.7948495182,
      score: 0.6009521214,
    },
  },
  {
    position: [-50.56305378, 32.38881079, 47.87999908],
    "overall score": 0.2640508785,
    "0": {
      velocity: [0.2556505952, 0.9643893291, 1.5117681309],
      direction: [-34.9564960056, -11.4271291333, 32.6073147885],
      "speed factor": 0.9882589768,
      "Uz factor": 0.9991521437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1272108561,
      "Direction stability factor": 0.8123227478,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2525590521, 0.711502108, 1.2383422169],
      direction: [-79.8970542661, -7.5215643612, 52.5797702782],
      "speed factor": 0.6317253975,
      "Uz factor": 0.9957495769,
      "Directional variance factor": 0.3314165012,
      "Velocity stability factor": 0.3625202001,
      "Direction stability factor": 0.6320088207,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4430996549, 0.730494635, 1.0114095298],
      direction: [-25.7931878404, -4.5790858269, 25.7262409433],
      "speed factor": 1.1315848881,
      "Uz factor": 0.9897322898,
      "Directional variance factor": 0.5929701487,
      "Velocity stability factor": 0.4921841387,
      "Direction stability factor": 0.8568904756,
      score: 0.6997338097,
    },
    "112.5": {
      velocity: [0.3757183559, 0.8151584384, 1.2086325077],
      direction: [-52.5193336955, -26.8551394388, 11.2412657023],
      "speed factor": 0.9169962956,
      "Uz factor": 0.9800162551,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3104978151,
      "Direction stability factor": 0.8228872239,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5039648937, 0.9314157141, 1.193059548],
      direction: [-38.8224601226, -8.4657471708, 10.9076723374],
      "speed factor": 1.3232070643,
      "Uz factor": 0.9820451647,
      "Directional variance factor": 0.2474891404,
      "Velocity stability factor": 0.4364642871,
      "Direction stability factor": 0.8618607432,
      score: 0.6019187285,
    },
    "202.5": {
      velocity: [0.5205097482, 0.9271177463, 1.1419096895],
      direction: [-19.4727842958, -0.6750345704, 10.2901073331],
      "speed factor": 1.0862625404,
      "Uz factor": 0.9766865363,
      "Directional variance factor": 0.9399969271,
      "Velocity stability factor": 0.5222042836,
      "Direction stability factor": 0.917325301,
      score: 0.8242129532,
    },
    "247.5": {
      velocity: [0.5800495057, 0.866295292, 1.0634356139],
      direction: [-13.5972835691, 4.0990618887, 11.0221010979],
      "speed factor": 1.6070821608,
      "Uz factor": 0.9979067549,
      "Directional variance factor": 0.6356389432,
      "Velocity stability factor": 0.5309473291,
      "Direction stability factor": 0.9316128204,
      score: 0.7574529783,
    },
    "292.5": {
      velocity: [0.2451127531, 1.0382438905, 1.3284452997],
      direction: [-0.5467464841, 14.0099989429, 72.4253753384],
      "speed factor": 1.4138293206,
      "Uz factor": 0.9706885228,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2055492206,
      "Direction stability factor": 0.7972996616,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1587728071, 0.6607863618, 1.1321425427],
      direction: [-36.2510412198, -3.6993082338, 102.6071860183],
      "speed factor": 0.8391576682,
      "Uz factor": 0.994115571,
      "Directional variance factor": 0.6711726014,
      "Velocity stability factor": 0.2160144607,
      "Direction stability factor": 0.6142827021,
      score: 0.0,
    },
    id: "IDN:752.0",
    "45": {
      velocity: [0.121036632, 0.54384377, 1.0785922101],
      direction: [-38.5205386351, 8.1938026373, 81.9625682947],
      "speed factor": 0.738747338,
      "Uz factor": 0.9846631687,
      "Directional variance factor": 0.2716619878,
      "Velocity stability factor": 0.221537615,
      "Direction stability factor": 0.665324703,
      score: 0.0,
    },
    "90": {
      velocity: [0.7055889682, 1.0712350819, 1.3030440052],
      direction: [-36.465382999, -12.3352151358, 3.2097539447],
      "speed factor": 1.7365034155,
      "Uz factor": 0.9787818405,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5639429414,
      "Direction stability factor": 0.8897912863,
      score: 0.0,
    },
    "135": {
      velocity: [0.2679299231, 0.5991357188, 0.9372793747],
      direction: [-86.4219696541, -36.1608123367, 14.2124694616],
      "speed factor": 0.5070787102,
      "Uz factor": 0.9937327142,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4606309765,
      "Direction stability factor": 0.7204598913,
      score: 0.0,
    },
    "180": {
      velocity: [0.5120528269, 0.8229305007, 1.0421098096],
      direction: [-24.698124857, -4.406965377, 16.419966249],
      "speed factor": 1.6071781841,
      "Uz factor": 0.9874448111,
      "Directional variance factor": 0.6082697443,
      "Velocity stability factor": 0.5823379534,
      "Direction stability factor": 0.8857830803,
      score: 0.7405434646,
    },
    "225": {
      velocity: [0.2095051732, 0.5624879614, 0.9730200959],
      direction: [-34.7332083414, 3.957002642, 38.6132619488],
      "speed factor": 0.7678576235,
      "Uz factor": 0.9927458151,
      "Directional variance factor": 0.6482664318,
      "Velocity stability factor": 0.313767592,
      "Direction stability factor": 0.7962598047,
      score: 0.0,
    },
    "270": {
      velocity: [0.5849104678, 0.970550921, 1.4222597258],
      direction: [-2.063341658, 23.463791716, 40.9473058958],
      "speed factor": 0.8880854314,
      "Uz factor": 0.992741028,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3394962222,
      "Direction stability factor": 0.880525979,
      score: 0.0,
    },
    "315": {
      velocity: [0.3994330407, 1.0144770826, 1.3475889307],
      direction: [-7.0746273319, 4.6880143725, 66.7795461314],
      "speed factor": 1.2499367245,
      "Uz factor": 0.9840023108,
      "Directional variance factor": 0.5832876113,
      "Velocity stability factor": 0.2308218381,
      "Direction stability factor": 0.7948495182,
      score: 0.6009521214,
    },
  },
  {
    position: [-50.04879157, 56.82216473, 46.87999908],
    "overall score": 0.263735536,
    "0": {
      velocity: [0.3141197751, 0.9261797781, 1.3967128627],
      direction: [-41.2021576326, -17.3832859818, 47.576407456],
      "speed factor": 0.9491036993,
      "Uz factor": 0.9896422384,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2477809861,
      "Direction stability factor": 0.7533928748,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1459237423, 0.6335666675, 1.1234923584],
      direction: [-132.4754844209, 4.2722192878, 110.8402457006],
      "speed factor": 0.5625284175,
      "Uz factor": 0.9896794031,
      "Directional variance factor": 0.6202471744,
      "Velocity stability factor": 0.3678323307,
      "Direction stability factor": 0.3241229719,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2030325515, 0.538122474, 0.8971912802],
      direction: [-57.7698083786, 23.0971239927, 54.138781814],
      "speed factor": 0.8335875861,
      "Uz factor": 0.9680414852,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3797313258,
      "Direction stability factor": 0.689142805,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5769968972, 0.9337647143, 1.2600707203],
      direction: [-20.4204881946, -1.741238344, 19.8877193374],
      "speed factor": 1.05042007,
      "Uz factor": 0.952337819,
      "Directional variance factor": 0.8452232583,
      "Velocity stability factor": 0.4345384907,
      "Direction stability factor": 0.8880327569,
      score: 0.7639568157,
    },
    "157.5": {
      velocity: [0.3620517759, 0.8573223801, 1.2838593916],
      direction: [-55.7566666471, -10.7140737985, 33.0801848331],
      "speed factor": 1.2179470591,
      "Uz factor": 0.9955709226,
      "Directional variance factor": 0.0476378846,
      "Velocity stability factor": 0.2461536183,
      "Direction stability factor": 0.7532309681,
      score: 0.4500633598,
    },
    "202.5": {
      velocity: [0.1952924217, 0.6635463749, 1.1249322344],
      direction: [-87.1103719705, -9.9860284284, 21.9134995039],
      "speed factor": 0.7774477122,
      "Uz factor": 0.9997808469,
      "Directional variance factor": 0.1123530286,
      "Velocity stability factor": 0.2851980009,
      "Direction stability factor": 0.6971559126,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3030720198, 0.7245559255, 1.0047005692],
      direction: [-55.5460997357, -8.302145873, 15.8260029102],
      "speed factor": 1.3441385554,
      "Uz factor": 0.9971434734,
      "Directional variance factor": 0.262031478,
      "Velocity stability factor": 0.3191762456,
      "Direction stability factor": 0.8017441593,
      score: 0.5461740105,
    },
    "292.5": {
      velocity: [0.4613916512, 1.1640229855, 1.3784249956],
      direction: [-32.0183486921, -8.715138234, 14.080448291],
      "speed factor": 1.5851090883,
      "Uz factor": 0.9776873362,
      "Directional variance factor": 0.2253210459,
      "Velocity stability factor": 0.32750303,
      "Direction stability factor": 0.8719477862,
      score: 0.574179912,
    },
    "337.5": {
      velocity: [0.6946146846, 0.9153020086, 1.1647063628],
      direction: [-29.0181563947, -10.8681868338, 5.9626693588],
      "speed factor": 1.1623767434,
      "Uz factor": 0.9634085502,
      "Directional variance factor": 0.0339389481,
      "Velocity stability factor": 0.6213719573,
      "Direction stability factor": 0.9028310396,
      score: 0.6152432461,
    },
    id: "IDN:472.0",
    "45": {
      velocity: [0.066487237, 0.5114963383, 0.9476520839],
      direction: [-60.0836991245, 15.8179532703, 74.3473972122],
      "speed factor": 0.6948071838,
      "Uz factor": 0.9917391749,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2836408623,
      "Direction stability factor": 0.626580288,
      score: 0.0,
    },
    "90": {
      velocity: [0.6904713159, 1.0588546791, 1.3328604764],
      direction: [-11.158486032, 10.2848798117, 24.9182337024],
      "speed factor": 1.7164344202,
      "Uz factor": 0.9572296156,
      "Directional variance factor": 0.0857884612,
      "Velocity stability factor": 0.531147433,
      "Direction stability factor": 0.8997868896,
      score: 0.6041274183,
    },
    "135": {
      velocity: [0.2996318901, 0.7168700671, 1.1140654502],
      direction: [-64.4406520532, -19.8675605015, 21.6428173527],
      "speed factor": 0.6067232142,
      "Uz factor": 0.930559484,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3437206336,
      "Direction stability factor": 0.7608792517,
      score: 0.0,
    },
    "180": {
      velocity: [0.1834229159, 0.5632810913, 1.028982452],
      direction: [-146.9285896004, -24.2357646432, 48.799442824],
      "speed factor": 1.1000844916,
      "Uz factor": 0.998461047,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3337355456,
      "Direction stability factor": 0.456311021,
      score: 0.0,
    },
    "225": {
      velocity: [0.0982645083, 0.4634761172, 0.8960447021],
      direction: [-99.7833381136, -0.8208362206, 88.414712259],
      "speed factor": 0.6326956207,
      "Uz factor": 0.994247961,
      "Directional variance factor": 0.9270367804,
      "Velocity stability factor": 0.2829706309,
      "Direction stability factor": 0.4772276379,
      score: 0.0,
    },
    "270": {
      velocity: [0.3320486275, 1.0657034502, 1.3950438268],
      direction: [-27.7834213286, -0.8356431131, 23.0899766207],
      "speed factor": 0.9751530681,
      "Uz factor": 0.9788713128,
      "Directional variance factor": 0.9257206122,
      "Velocity stability factor": 0.1615059807,
      "Direction stability factor": 0.8586850057,
      score: 0.0,
    },
    "315": {
      velocity: [0.6538636377, 1.0384536371, 1.2621029092],
      direction: [-19.6602752421, -7.815069431, 6.9448645006],
      "speed factor": 1.2794782257,
      "Uz factor": 0.979467869,
      "Directional variance factor": 0.3053271617,
      "Velocity stability factor": 0.5065744254,
      "Direction stability factor": 0.926096834,
      score: 0.6660238138,
    },
  },
  {
    position: [-50.56177969, 32.75380857, 47.87999908],
    "overall score": 0.263411046,
    "0": {
      velocity: [0.2620375534, 0.9649293412, 1.5131965212],
      direction: [-34.5079468173, -11.4291808229, 33.7871697622],
      "speed factor": 0.9888123548,
      "Uz factor": 0.9991183836,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1306562219,
      "Direction stability factor": 0.8102913428,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.286201487, 0.708706288, 1.2272816665],
      direction: [-88.1753701764, -6.7738850331, 61.524852789],
      "speed factor": 0.629243057,
      "Uz factor": 0.9952698227,
      "Directional variance factor": 0.397876886,
      "Velocity stability factor": 0.3914284338,
      "Direction stability factor": 0.5841660473,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4237016214, 0.725806491, 1.0162359809],
      direction: [-26.2866742338, -4.112995532, 26.2538579095],
      "speed factor": 1.1243226405,
      "Uz factor": 0.9885875078,
      "Directional variance factor": 0.6344003972,
      "Velocity stability factor": 0.470538241,
      "Direction stability factor": 0.8540540774,
      score: 0.7032616982,
    },
    "112.5": {
      velocity: [0.3890765669, 0.8214388815, 1.1957517823],
      direction: [-51.8462925695, -26.8345496752, 10.4455225426],
      "speed factor": 0.9240613553,
      "Uz factor": 0.9774492291,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.332218906,
      "Direction stability factor": 0.8269671802,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5017745627, 0.9351696191, 1.1862503029],
      direction: [-40.2095104916, -8.7473485958, 10.0792561252],
      "speed factor": 1.3285400144,
      "Uz factor": 0.9814537343,
      "Directional variance factor": 0.2224579026,
      "Velocity stability factor": 0.4402415956,
      "Direction stability factor": 0.8603089816,
      score: 0.5958293653,
    },
    "202.5": {
      velocity: [0.5203541871, 0.930778699, 1.1480333847],
      direction: [-19.4715949667, -0.9340126266, 9.6715123147],
      "speed factor": 1.0905519155,
      "Uz factor": 0.9760160235,
      "Directional variance factor": 0.9169766554,
      "Velocity stability factor": 0.5173761503,
      "Direction stability factor": 0.9190469242,
      score: 0.8181116635,
    },
    "247.5": {
      velocity: [0.5754427239, 0.8656626964, 1.0650372947],
      direction: [-14.3532143209, 4.1289945709, 11.2112568077],
      "speed factor": 1.6059086197,
      "Uz factor": 0.997897189,
      "Directional variance factor": 0.6329782604,
      "Velocity stability factor": 0.5249229609,
      "Direction stability factor": 0.9289875802,
      score: 0.7539690954,
    },
    "292.5": {
      velocity: [0.2639672656, 1.0350625949, 1.3302442174],
      direction: [-1.2224938088, 13.6080921816, 74.8252937706],
      "speed factor": 1.4094971891,
      "Uz factor": 0.9695859305,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2180567656,
      "Direction stability factor": 0.7887561456,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1585375006, 0.6621411173, 1.1095968606],
      direction: [-35.0873528315, -3.6404166025, 96.2693971016],
      "speed factor": 0.8408781237,
      "Uz factor": 0.9941817938,
      "Directional variance factor": 0.6764074131,
      "Velocity stability factor": 0.2339840063,
      "Direction stability factor": 0.6351201391,
      score: 0.0,
    },
    id: "IDN:751.0",
    "45": {
      velocity: [0.1177421418, 0.5409566141, 1.079798348],
      direction: [-31.9377920433, 8.8759189442, 77.9923990679],
      "speed factor": 0.7348254787,
      "Uz factor": 0.9829545533,
      "Directional variance factor": 0.2110294272,
      "Velocity stability factor": 0.2178787469,
      "Direction stability factor": 0.694638358,
      score: 0.0,
    },
    "90": {
      velocity: [0.6943337205, 1.0693133615, 1.3045007932],
      direction: [-37.6894258864, -11.9229771534, 4.2844070928],
      "speed factor": 1.7333882505,
      "Uz factor": 0.9768357721,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5546649664,
      "Direction stability factor": 0.8834060195,
      score: 0.0,
    },
    "135": {
      velocity: [0.2741184598, 0.6011590366, 0.9420930543],
      direction: [-87.7844624789, -36.071013774, 14.1803704681],
      "speed factor": 0.5087911459,
      "Uz factor": 0.9924915012,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4617388513,
      "Direction stability factor": 0.7167643529,
      score: 0.0,
    },
    "180": {
      velocity: [0.5166785398, 0.8275152041, 1.0503687497],
      direction: [-26.8334506398, -4.6596137111, 16.0272310806],
      "speed factor": 1.6161320815,
      "Uz factor": 0.9871628829,
      "Directional variance factor": 0.5858121146,
      "Velocity stability factor": 0.579475127,
      "Direction stability factor": 0.8809425508,
      score: 0.7317930858,
    },
    "225": {
      velocity: [0.1989691536, 0.5645383903, 0.9753104079],
      direction: [-33.8416599641, 3.7461156119, 39.1571049923],
      "speed factor": 0.7706566833,
      "Uz factor": 0.9928024524,
      "Directional variance factor": 0.6670119456,
      "Velocity stability factor": 0.3022395339,
      "Direction stability factor": 0.7972256529,
      score: 0.0,
    },
    "270": {
      velocity: [0.5697639578, 0.9794136487, 1.4283510128],
      direction: [-1.705911759, 23.4888636553, 40.6951283452],
      "speed factor": 0.8961951134,
      "Uz factor": 0.9920936099,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3227437799,
      "Direction stability factor": 0.882219333,
      score: 0.0,
    },
    "315": {
      velocity: [0.3908512607, 1.0105730768, 1.3471749215],
      direction: [-7.1327739257, 4.1090383602, 67.1173491476],
      "speed factor": 1.2451266008,
      "Uz factor": 0.9828885007,
      "Directional variance factor": 0.6347521458,
      "Velocity stability factor": 0.2241958487,
      "Direction stability factor": 0.7937496581,
      score: 0.6116118277,
    },
  },
  {
    position: [-53.00215104, 80.42343294, 47.87999908],
    "overall score": 0.2629102602,
    "0": {
      velocity: [0.5229415098, 1.1720029263, 1.5013186396],
      direction: [-17.7433900999, -3.7602975953, 49.9239193071],
      "speed factor": 1.2010112284,
      "Uz factor": 0.999505568,
      "Directional variance factor": 0.6657513249,
      "Velocity stability factor": 0.3201934428,
      "Direction stability factor": 0.8120352516,
      score: 0.6525038177,
    },
    "22.5": {
      velocity: [0.2204275343, 0.7474240886, 1.2444580041],
      direction: [-178.3068941432, 17.3826740371, 164.6162707776],
      "speed factor": 0.6636196494,
      "Uz factor": 0.9954280932,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3377866834,
      "Direction stability factor": 0.047435653,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0509859341, 0.4721927177, 0.9737201157],
      direction: [-144.4697788207, 4.7722709593, 177.4514758961],
      "speed factor": 0.7314579984,
      "Uz factor": 0.9973114108,
      "Directional variance factor": 0.575798137,
      "Velocity stability factor": 0.1754866952,
      "Direction stability factor": 0.1057742925,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3532023811, 0.8677131287, 1.2236808688],
      direction: [-33.8599199788, -0.7766746371, 28.667956519],
      "speed factor": 0.9761166506,
      "Uz factor": 0.9863203933,
      "Directional variance factor": 0.9309622545,
      "Velocity stability factor": 0.279401343,
      "Direction stability factor": 0.8263114542,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4467666912, 0.9287233639, 1.2368180855],
      direction: [-54.0885354323, -17.03184522, 5.5651417597],
      "speed factor": 1.319382202,
      "Uz factor": 0.9912651329,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3539027288,
      "Direction stability factor": 0.8342953411,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5394474768, 1.0095037803, 1.343371441],
      direction: [-56.1379178005, -12.0801538147, 7.7669371171],
      "speed factor": 1.1827905844,
      "Uz factor": 0.9988943207,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3818611801,
      "Direction stability factor": 0.8224865141,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2214192312, 0.4390266334, 0.6560762272],
      direction: [-28.6306000125, -0.0183871556, 43.3339184824],
      "speed factor": 0.8144473105,
      "Uz factor": 0.9873740111,
      "Directional variance factor": 0.9983655862,
      "Velocity stability factor": 0.5782315185,
      "Direction stability factor": 0.8000985597,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4894106331, 0.9345754301, 1.2622322427],
      direction: [-35.0477420624, -1.3698764916, 30.4212666807],
      "speed factor": 1.2726587245,
      "Uz factor": 0.952265762,
      "Directional variance factor": 0.8782332007,
      "Velocity stability factor": 0.4332592222,
      "Direction stability factor": 0.8181416424,
      score: 0.7369439269,
    },
    "337.5": {
      velocity: [0.8075586242, 1.0177534925, 1.2652596994],
      direction: [-9.5182794838, 3.3482343008, 22.8009763726],
      "speed factor": 1.2924837694,
      "Uz factor": 0.9856947632,
      "Directional variance factor": 0.7023791733,
      "Velocity stability factor": 0.631351776,
      "Direction stability factor": 0.9102242893,
      score: 0.788544882,
    },
    id: "IDN:703.0",
    "45": {
      velocity: [0.0968091878, 0.5101872427, 1.1339063444],
      direction: [-65.6137692259, 2.428410812, 100.7938764969],
      "speed factor": 0.6930289326,
      "Uz factor": 0.999999006,
      "Directional variance factor": 0.7841412612,
      "Velocity stability factor": 0.1568728287,
      "Direction stability factor": 0.5377565397,
      score: 0.0,
    },
    "90": {
      velocity: [0.2548885859, 0.9527632421, 1.3990522205],
      direction: [-147.1462246286, 4.9582908699, 27.4963307757],
      "speed factor": 1.5444570963,
      "Uz factor": 0.9898430968,
      "Directional variance factor": 0.5592630338,
      "Velocity stability factor": 0.1649235538,
      "Direction stability factor": 0.5148817905,
      score: 0.4384875422,
    },
    "135": {
      velocity: [0.3452177022, 1.0175283073, 1.3035519732],
      direction: [-63.4660348637, -20.3021394912, 23.1849509975],
      "speed factor": 0.8611854134,
      "Uz factor": 0.9939165181,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2277638853,
      "Direction stability factor": 0.7593028171,
      score: 0.0,
    },
    "180": {
      velocity: [0.532782279, 0.8811889373, 1.2114990217],
      direction: [-49.8557847837, -25.4954664256, 1.3993074183],
      "speed factor": 1.7209565508,
      "Uz factor": 0.9996424052,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4652004726,
      "Direction stability factor": 0.8576247439,
      score: 0.0,
    },
    "225": {
      velocity: [0.5355592339, 0.7704133856, 1.0539451073],
      direction: [-15.4480146604, 1.6531751555, 20.7933430721],
      "speed factor": 1.051698582,
      "Uz factor": 0.9937988145,
      "Directional variance factor": 0.8530510973,
      "Velocity stability factor": 0.5340848285,
      "Direction stability factor": 0.8993295619,
      score: 0.7964487624,
    },
    "270": {
      velocity: [0.7903384754, 1.0172333247, 1.2341186961],
      direction: [-17.0795960342, 7.6579525979, 24.3170048192],
      "speed factor": 0.9308013382,
      "Uz factor": 0.9479823555,
      "Directional variance factor": 0.3192931024,
      "Velocity stability factor": 0.6499447399,
      "Direction stability factor": 0.8850094421,
      score: 0.0,
    },
    "315": {
      velocity: [0.6868461198, 0.9174038052, 1.1854812448],
      direction: [-6.4182100632, 3.5221380353, 12.9982799475],
      "speed factor": 1.1303327862,
      "Uz factor": 0.9722636028,
      "Directional variance factor": 0.6869210635,
      "Velocity stability factor": 0.5954892513,
      "Direction stability factor": 0.9460653055,
      score: 0.7936352315,
    },
  },
  {
    position: [-53.82729637, 55.863901, 47.87999908],
    "overall score": 0.2602422345,
    "0": {
      velocity: [0.3358392536, 1.0686374209, 1.3438833254],
      direction: [-22.3152857131, -6.0001670341, 52.5334195766],
      "speed factor": 1.0950873184,
      "Uz factor": 0.9923633744,
      "Directional variance factor": 0.4666518192,
      "Velocity stability factor": 0.2995799379,
      "Direction stability factor": 0.7920869298,
      score: 0.5876014041,
    },
    "22.5": {
      velocity: [0.1431368558, 0.69760358, 1.1879032322],
      direction: [-129.0053113546, 5.5211249619, 73.3895074029],
      "speed factor": 0.6193852328,
      "Uz factor": 0.9984218183,
      "Directional variance factor": 0.5092333367,
      "Velocity stability factor": 0.3243773232,
      "Direction stability factor": 0.4377921701,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1040414917, 0.5613089117, 0.9833290991],
      direction: [-59.1217740606, 20.4425814478, 150.4730495117],
      "speed factor": 0.869504924,
      "Uz factor": 0.9831035105,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2143085782,
      "Direction stability factor": 0.4177921567,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3919901545, 0.8850752435, 1.2797203533],
      direction: [-36.3156339245, -7.0316133909, 17.1480295926],
      "speed factor": 0.9956478168,
      "Uz factor": 0.9661072927,
      "Directional variance factor": 0.3749676986,
      "Velocity stability factor": 0.2651200483,
      "Direction stability factor": 0.8514898236,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1468224956, 0.8281896024, 1.2755224911],
      direction: [-73.5427365678, -10.3140017682, 81.6826172177],
      "speed factor": 1.1765598496,
      "Uz factor": 0.9997810069,
      "Directional variance factor": 0.0831998428,
      "Velocity stability factor": 0.0769588,
      "Direction stability factor": 0.5688184617,
      score: 0.3244488916,
    },
    "202.5": {
      velocity: [0.4290703923, 0.908926477, 1.2181349116],
      direction: [-55.7943441756, -7.0935430937, 22.6116700934],
      "speed factor": 1.064948641,
      "Uz factor": 0.9982400738,
      "Directional variance factor": 0.3694628361,
      "Velocity stability factor": 0.3932866385,
      "Direction stability factor": 0.7822055159,
      score: 0.5817901266,
    },
    "247.5": {
      velocity: [0.5422200049, 0.7453934542, 0.9192424325],
      direction: [-28.1480078049, -9.9566248851, 12.6411513644],
      "speed factor": 1.3827946822,
      "Uz factor": 0.9931130293,
      "Directional variance factor": 0.1149666769,
      "Velocity stability factor": 0.634157098,
      "Direction stability factor": 0.8866967801,
      score: 0.6306293338,
    },
    "292.5": {
      velocity: [0.6846788684, 1.0961517602, 1.2729074846],
      direction: [-25.8612422003, -3.9010927821, 11.6258538419],
      "speed factor": 1.4926854013,
      "Uz factor": 0.9464792593,
      "Directional variance factor": 0.6532361972,
      "Velocity stability factor": 0.5686285951,
      "Direction stability factor": 0.8958691777,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6459383404, 0.9074984626, 1.1039018789],
      direction: [-22.0798139445, -3.615865245, 9.103727124],
      "speed factor": 1.1524667243,
      "Uz factor": 0.967031709,
      "Directional variance factor": 0.678589756,
      "Velocity stability factor": 0.6311403791,
      "Direction stability factor": 0.9133790526,
      score: 0.7841220601,
    },
    id: "IDN:736.0",
    "45": {
      velocity: [0.080933059, 0.5476643265, 0.9823105002],
      direction: [-49.5867875394, 16.2737913138, 85.7835955324],
      "speed factor": 0.7439371113,
      "Uz factor": 0.9991146592,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2672086627,
      "Direction stability factor": 0.6239711581,
      score: 0.0,
    },
    "90": {
      velocity: [0.2609834503, 1.0813668953, 1.4735428726],
      direction: [-13.1700245573, 5.9899980865, 80.6525885743],
      "speed factor": 1.7529273815,
      "Uz factor": 0.9729494831,
      "Directional variance factor": 0.4675557256,
      "Velocity stability factor": 0.115004373,
      "Direction stability factor": 0.7393816302,
      score: 0.5153308398,
    },
    "135": {
      velocity: [0.1341959129, 0.6255814745, 1.0396887486],
      direction: [-79.5965280175, -27.5215531825, 84.4397431722],
      "speed factor": 0.5294610841,
      "Uz factor": 0.9649271995,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.270344085,
      "Direction stability factor": 0.5443436911,
      score: 0.0,
    },
    "180": {
      velocity: [0.217175592, 0.647586486, 1.021684555],
      direction: [-75.7988944015, -16.1934027438, 21.0358057057],
      "speed factor": 1.2647324065,
      "Uz factor": 0.9952002728,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3660816272,
      "Direction stability factor": 0.7310147219,
      score: 0.0,
    },
    "225": {
      velocity: [0.2350530812, 0.5788533735, 0.8611947412],
      direction: [-55.7694025374, -3.1708955013, 33.7527311351],
      "speed factor": 0.7901982019,
      "Uz factor": 0.9884205298,
      "Directional variance factor": 0.7181426221,
      "Velocity stability factor": 0.4372360169,
      "Direction stability factor": 0.7513274065,
      score: 0.0,
    },
    "270": {
      velocity: [0.7559385302, 1.0390437399, 1.3779197956],
      direction: [-16.9141102016, 1.0162003641, 22.9667713969],
      "speed factor": 0.9507585723,
      "Uz factor": 0.9519632811,
      "Directional variance factor": 0.9096710787,
      "Velocity stability factor": 0.5093791849,
      "Direction stability factor": 0.8892197733,
      score: 0.0,
    },
    "315": {
      velocity: [0.6041323947, 1.0312672291, 1.204354622],
      direction: [-13.4449522697, -4.8004039481, 9.3364205286],
      "speed factor": 1.270623856,
      "Uz factor": 0.9670600212,
      "Directional variance factor": 0.5732974268,
      "Velocity stability factor": 0.51307814,
      "Direction stability factor": 0.9367184089,
      score: 0.7399530962,
    },
  },
  {
    position: [-49.20711102, 79.55436501, 47.87999908],
    "overall score": 0.2528108153,
    "0": {
      velocity: [0.2015853166, 0.7875236713, 1.476623415],
      direction: [-36.1229621413, -1.7766330831, 92.2617941061],
      "speed factor": 0.8070157085,
      "Uz factor": 0.9961899615,
      "Directional variance factor": 0.8420770593,
      "Velocity stability factor": 0.1140642665,
      "Direction stability factor": 0.6433756771,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1956478492, 0.8024155081, 1.2672161343],
      direction: [-38.7601366469, 23.9670421954, 111.5783047601],
      "speed factor": 0.7124451918,
      "Uz factor": 0.9999826602,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3070452404,
      "Direction stability factor": 0.5823932183,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0837981763, 0.5066432604, 1.0051385639],
      direction: [-65.5678924402, 2.3901895166, 53.3288503945],
      "speed factor": 0.7848241858,
      "Uz factor": 0.9782348365,
      "Directional variance factor": 0.7875387096,
      "Velocity stability factor": 0.1767321261,
      "Direction stability factor": 0.6697312699,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3050466811, 0.8814029802, 1.2042377748],
      direction: [-34.1667105524, -2.835796285, 26.9203829594],
      "speed factor": 0.9915167772,
      "Uz factor": 0.9786254675,
      "Directional variance factor": 0.7479292191,
      "Velocity stability factor": 0.2556325013,
      "Direction stability factor": 0.8303136291,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4332867579, 0.8775833073, 1.1496339263],
      direction: [-50.801599981, -14.6008256045, 11.3228582115],
      "speed factor": 1.2467305567,
      "Uz factor": 0.9792011193,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4141774142,
      "Direction stability factor": 0.8274320606,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3647033713, 0.8429072031, 1.2610496095],
      direction: [-50.6661594675, -12.7158138404, 23.0806110659],
      "speed factor": 0.9875968003,
      "Uz factor": 0.9964350907,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3107975001,
      "Direction stability factor": 0.7951478596,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2811088549, 0.4872439741, 0.7767252689],
      direction: [-33.7133638363, -2.8594501362, 44.8620922013],
      "speed factor": 0.9038962879,
      "Uz factor": 0.9924067406,
      "Directional variance factor": 0.7458266546,
      "Velocity stability factor": 0.5190796782,
      "Direction stability factor": 0.7817348443,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3497811657, 0.9356915419, 1.3526113189],
      direction: [-53.8643804609, -5.4162968403, 29.1076687847],
      "speed factor": 1.2741785904,
      "Uz factor": 0.9718378457,
      "Directional variance factor": 0.518551392,
      "Velocity stability factor": 0.2645848228,
      "Direction stability factor": 0.7695220854,
      score: 0.5805450964,
    },
    "337.5": {
      velocity: [0.118797349, 0.8565783474, 1.2298662525],
      direction: [-18.4423436668, 0.1878567602, 110.0013732055],
      "speed factor": 1.0878013382,
      "Uz factor": 0.9960166732,
      "Directional variance factor": 0.9833016213,
      "Velocity stability factor": 0.1051068041,
      "Direction stability factor": 0.6432118976,
      score: 0.5937080551,
    },
    id: "IDN:707.0",
    "45": {
      velocity: [0.143212216, 0.4548128496, 1.0007981352],
      direction: [-48.3966406377, 4.175342386, 97.4802322876],
      "speed factor": 0.6178093792,
      "Uz factor": 0.9873030903,
      "Directional variance factor": 0.6288584546,
      "Velocity stability factor": 0.3028097844,
      "Direction stability factor": 0.5947864641,
      score: 0.0,
    },
    "90": {
      velocity: [0.4841142012, 1.0180959862, 1.3552576013],
      direction: [-16.04591461, 3.7532820655, 28.0264304952],
      "speed factor": 1.6503633864,
      "Uz factor": 0.9870799061,
      "Directional variance factor": 0.6663749275,
      "Velocity stability factor": 0.3641894282,
      "Direction stability factor": 0.8775768192,
      score: 0.6964294985,
    },
    "135": {
      velocity: [0.5122728253, 1.0182434329, 1.2790155917],
      direction: [-99.5227827702, -21.0994223729, 5.3450363574],
      "speed factor": 0.8617906601,
      "Uz factor": 0.9806677617,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3821503908,
      "Direction stability factor": 0.7087005024,
      score: 0.0,
    },
    "180": {
      velocity: [0.5050061158, 0.8441801327, 1.1916932903],
      direction: [-49.4768140424, -25.2998360288, -4.6570408845],
      "speed factor": 1.6486785841,
      "Uz factor": 0.9905516083,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4589201161,
      "Direction stability factor": 0.8755006301,
      score: 0.0,
    },
    "225": {
      velocity: [0.5346425303, 0.7570255465, 1.0630978471],
      direction: [-17.7125723184, 2.2322709914, 21.9975065691],
      "speed factor": 1.0334227165,
      "Uz factor": 0.9942645292,
      "Directional variance factor": 0.8015759119,
      "Velocity stability factor": 0.5250346081,
      "Direction stability factor": 0.8896942253,
      score: 0.7764997426,
    },
    "270": {
      velocity: [0.8884725793, 1.1274976074, 1.4140021148],
      direction: [-8.9797578415, 8.8049334009, 25.0635598401],
      "speed factor": 1.0316967172,
      "Uz factor": 0.9761874515,
      "Directional variance factor": 0.2173392532,
      "Velocity stability factor": 0.5854606184,
      "Direction stability factor": 0.9054352287,
      score: 0.6534175822,
    },
    "315": {
      velocity: [0.3444709789, 0.9391942236, 1.2282519137],
      direction: [-13.2901800574, -1.9158007675, 11.056518344],
      "speed factor": 1.1571807502,
      "Uz factor": 0.9877418739,
      "Directional variance factor": 0.8297065984,
      "Velocity stability factor": 0.2830451172,
      "Direction stability factor": 0.9323702822,
      score: 0.74437307,
    },
  },
  {
    position: [-54.97810694, 41.06614952, 47.87999908],
    "overall score": 0.2524796011,
    "0": {
      velocity: [0.4991167114, 1.086624558, 1.3950463152],
      direction: [-22.6175106016, -9.9393909998, 32.2424884811],
      "speed factor": 1.1135196559,
      "Uz factor": 0.9972378969,
      "Directional variance factor": 0.1164985778,
      "Velocity stability factor": 0.3774805227,
      "Direction stability factor": 0.8476111137,
      score: 0.5473003319,
    },
    "22.5": {
      velocity: [0.1340162249, 0.719762718, 1.1990986133],
      direction: [-112.0660365043, -2.3328324415, 75.0128579423],
      "speed factor": 0.639059792,
      "Uz factor": 0.9996942895,
      "Directional variance factor": 0.7926371163,
      "Velocity stability factor": 0.3112394976,
      "Direction stability factor": 0.4803364043,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1123513823, 0.5483120237, 1.1144420984],
      direction: [-168.7954364478, 11.0024200794, 129.6188553877],
      "speed factor": 0.8493718779,
      "Uz factor": 0.9995989028,
      "Directional variance factor": 0.022007104,
      "Velocity stability factor": 0.1045773046,
      "Direction stability factor": 0.1710714116,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.105848315, 0.6664367009, 1.1406586541],
      direction: [-92.0089538468, -15.2214386651, 12.8240933701],
      "speed factor": 0.7496947306,
      "Uz factor": 0.9976483984,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1433643093,
      "Direction stability factor": 0.7087970911,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1876819348, 0.9302515424, 1.3120967845],
      direction: [-49.6708463157, -17.0813408813, 6.0027940714],
      "speed factor": 1.3215531946,
      "Uz factor": 0.9949017123,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0804631556,
      "Direction stability factor": 0.8453509989,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3806437362, 0.9619583169, 1.1728771347],
      direction: [-38.4928990296, -8.6199747851, 5.1647040151],
      "speed factor": 1.127083684,
      "Uz factor": 0.9948061389,
      "Directional variance factor": 0.2337800191,
      "Velocity stability factor": 0.3908500806,
      "Direction stability factor": 0.8787288804,
      score: 0.5955219652,
    },
    "247.5": {
      velocity: [0.5292162021, 0.8139546249, 0.993127359],
      direction: [-20.7399857966, -0.2045704861, 8.7620473988],
      "speed factor": 1.5099839158,
      "Uz factor": 0.9837998411,
      "Directional variance factor": 0.9818159568,
      "Velocity stability factor": 0.5498448063,
      "Direction stability factor": 0.9180499078,
      score: 0.8419401447,
    },
    "292.5": {
      velocity: [0.7127863527, 1.1517095818, 1.3316368978],
      direction: [-3.7495670975, 11.7373406496, 27.7142498193],
      "speed factor": 1.5683413026,
      "Uz factor": 0.9313168105,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5461723185,
      "Direction stability factor": 0.9126005086,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.5890754868, 0.9016291239, 1.1489966541],
      direction: [-23.0388656855, -2.8263864693, 9.4536346085],
      "speed factor": 1.1450130284,
      "Uz factor": 0.9703983806,
      "Directional variance factor": 0.7487656472,
      "Velocity stability factor": 0.5490201901,
      "Direction stability factor": 0.9097430547,
      score: 0.7793179867,
    },
    id: "IDN:730.0",
    "45": {
      velocity: [0.1142824292, 0.5240489515, 1.1692624473],
      direction: [-131.1424458442, 6.5524449472, 173.7672801167],
      "speed factor": 0.7118584219,
      "Uz factor": 0.9998208156,
      "Directional variance factor": 0.4175604491,
      "Velocity stability factor": 0.1423346282,
      "Direction stability factor": 0.153028539,
      score: 0.0,
    },
    "90": {
      velocity: [0.21060944, 0.8588932414, 1.333172184],
      direction: [-98.8883782606, -0.4887042623, 90.7905980958],
      "speed factor": 1.3922910782,
      "Uz factor": 0.9968747889,
      "Directional variance factor": 0.9565596211,
      "Velocity stability factor": 0.1806891265,
      "Direction stability factor": 0.4731139546,
      score: 0.5208691642,
    },
    "135": {
      velocity: [0.0903637027, 0.5823542214, 1.0331296343],
      direction: [-141.9825239673, -27.6321481798, 86.9099775661],
      "speed factor": 0.4928756845,
      "Uz factor": 0.9998385002,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2403090214,
      "Direction stability factor": 0.3641874957,
      score: 0.0,
    },
    "180": {
      velocity: [0.4379757879, 0.8775743489, 1.1251483132],
      direction: [-41.9240895219, -14.2154289167, 9.1310822244],
      "speed factor": 1.713897282,
      "Uz factor": 0.9999960842,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4585376805,
      "Direction stability factor": 0.8581800785,
      score: 0.0,
    },
    "225": {
      velocity: [0.1118410866, 0.5571632892, 0.9016222866],
      direction: [-67.1343476935, -0.8726938416, 27.6280317072],
      "speed factor": 0.7605888632,
      "Uz factor": 0.9884501298,
      "Directional variance factor": 0.9224272141,
      "Velocity stability factor": 0.2901599713,
      "Direction stability factor": 0.7367711683,
      score: 0.0,
    },
    "270": {
      velocity: [0.6629579602, 0.949865532, 1.2309441707],
      direction: [-10.9968221691, 18.0242027416, 38.7996576847],
      "speed factor": 0.8691576326,
      "Uz factor": 0.9417937782,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5519706571,
      "Direction stability factor": 0.8616764449,
      score: 0.0,
    },
    "315": {
      velocity: [0.6673980432, 1.1276123054, 1.303450714],
      direction: [-8.8708327212, 2.8651102776, 29.0081198611],
      "speed factor": 1.3893305781,
      "Uz factor": 0.9634929305,
      "Directional variance factor": 0.7453235309,
      "Velocity stability factor": 0.4840111955,
      "Direction stability factor": 0.8947806873,
      score: 0.7547240252,
    },
  },
  {
    position: [-54.65624756, 50.06039249, 47.87999908],
    "overall score": 0.2498859767,
    "0": {
      velocity: [0.3269274446, 1.1105001197, 1.4168435906],
      direction: [-18.6395922285, -7.357523533, 44.2347024976],
      "speed factor": 1.1379861629,
      "Uz factor": 0.9949484169,
      "Directional variance factor": 0.3459979082,
      "Velocity stability factor": 0.2426926996,
      "Direction stability factor": 0.8253491813,
      score: 0.5598472426,
    },
    "22.5": {
      velocity: [0.2001352328, 0.7099192246, 1.2997169128],
      direction: [-71.0989538388, 1.2348078531, 61.3532956101],
      "speed factor": 0.6303199939,
      "Uz factor": 0.9999697841,
      "Directional variance factor": 0.890239302,
      "Velocity stability factor": 0.2889297217,
      "Direction stability factor": 0.6320770849,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0953403013, 0.4889023323, 1.0709333184],
      direction: [-78.9556305467, 7.4325723416, 138.8831727951],
      "speed factor": 0.7573423055,
      "Uz factor": 0.9930972934,
      "Directional variance factor": 0.339326903,
      "Velocity stability factor": 0.1282544435,
      "Direction stability factor": 0.3948922129,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0607277668, 0.6086213179, 1.2040457395],
      direction: [-123.7090313138, -26.4475612349, 177.9439298754],
      "speed factor": 0.6846564638,
      "Uz factor": 0.9829871691,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0535396253,
      "Direction stability factor": 0.1620751078,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0904848493, 0.4314429744, 1.0013058344],
      direction: [-128.1836433447, -5.6285019859, 120.9079818872],
      "speed factor": 0.6129254456,
      "Uz factor": 0.9992514151,
      "Directional variance factor": 0.4996887124,
      "Velocity stability factor": 0.2551383908,
      "Direction stability factor": 0.3080788188,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2550877429, 0.9457966429, 1.1995910441],
      direction: [-99.1179684188, -7.0920037395, 16.3538961217],
      "speed factor": 1.108147771,
      "Uz factor": 0.9975936616,
      "Directional variance factor": 0.3695996676,
      "Velocity stability factor": 0.2737694335,
      "Direction stability factor": 0.6792448207,
      score: 0.5004646856,
    },
    "247.5": {
      velocity: [0.5318807808, 0.7922459428, 0.9908509772],
      direction: [-21.0606734357, -7.0662452138, 9.9587436192],
      "speed factor": 1.4697116946,
      "Uz factor": 0.9876392211,
      "Directional variance factor": 0.3718893143,
      "Velocity stability factor": 0.5546392566,
      "Direction stability factor": 0.9138349526,
      score: 0.6885496191,
    },
    "292.5": {
      velocity: [0.6838686016, 1.1246621703, 1.2837960499],
      direction: [-13.9947099548, 3.9715858281, 14.5171573876],
      "speed factor": 1.5315094716,
      "Uz factor": 0.9310420081,
      "Directional variance factor": 0.6469701486,
      "Velocity stability factor": 0.5600493769,
      "Direction stability factor": 0.9208003685,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6437413488, 0.9222362301, 1.1427767896],
      direction: [-21.6250099579, -1.8236531833, 9.6767530772],
      "speed factor": 1.1711827743,
      "Uz factor": 0.9632384253,
      "Directional variance factor": 0.8378974948,
      "Velocity stability factor": 0.5980596531,
      "Direction stability factor": 0.9130506582,
      score: 0.8155146161,
    },
    id: "IDN:733.0",
    "45": {
      velocity: [0.0571312227, 0.5448678427, 1.0552510716],
      direction: [-109.4614243625, 13.1580785748, 149.6852776903],
      "speed factor": 0.7401384195,
      "Uz factor": 0.9961932079,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1885601465,
      "Direction stability factor": 0.2801480499,
      score: 0.0,
    },
    "90": {
      velocity: [0.0873994479, 0.8036457773, 1.2351612345],
      direction: [-65.0919258715, -10.051457664, 105.3039506102],
      "speed factor": 1.3027333222,
      "Uz factor": 0.9719456641,
      "Directional variance factor": 0.1065370965,
      "Velocity stability factor": 0.1622974155,
      "Direction stability factor": 0.5266781209,
      score: 0.3305476884,
    },
    "135": {
      velocity: [0.0583451239, 0.5710079485, 1.1205097089],
      direction: [-173.7587096924, -35.3026780981, 152.6152765688],
      "speed factor": 0.4832727627,
      "Uz factor": 0.9956496471,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1440962959,
      "Direction stability factor": 0.0934055937,
      score: 0.0,
    },
    "180": {
      velocity: [0.0764159985, 0.6934503399, 1.048004044],
      direction: [-167.5329512824, -11.1624475551, 23.9098917701],
      "speed factor": 1.35430423,
      "Uz factor": 0.9986757109,
      "Directional variance factor": 0.0077824395,
      "Velocity stability factor": 0.2344305145,
      "Direction stability factor": 0.4682143249,
      score: 0.2946604009,
    },
    "225": {
      velocity: [0.211620262, 0.569524637, 0.8882829622],
      direction: [-47.4566372173, -1.800803758, 38.9385639188],
      "speed factor": 0.7774634558,
      "Uz factor": 0.9865175975,
      "Directional variance factor": 0.8399285548,
      "Velocity stability factor": 0.3918286856,
      "Direction stability factor": 0.7600133302,
      score: 0.0,
    },
    "270": {
      velocity: [0.7219839592, 1.0159774627, 1.3606182358],
      direction: [-8.9597030593, 9.8423434808, 31.4354023465],
      "speed factor": 0.9296521839,
      "Uz factor": 0.9371636186,
      "Directional variance factor": 0.1251250239,
      "Velocity stability factor": 0.4962432363,
      "Direction stability factor": 0.8877913739,
      score: 0.0,
    },
    "315": {
      velocity: [0.6403010099, 1.0761401969, 1.25808322],
      direction: [-10.5670497451, -0.9322649244, 22.1208354622],
      "speed factor": 1.325911818,
      "Uz factor": 0.9583269777,
      "Directional variance factor": 0.9171320067,
      "Velocity stability factor": 0.4988328504,
      "Direction stability factor": 0.9092003189,
      score: 0.8085913737,
    },
  },
  {
    position: [-55.19267986, 35.06998754, 47.87999908],
    "overall score": 0.2491236184,
    "0": {
      velocity: [0.3873232918, 1.0547990478, 1.4412425742],
      direction: [-25.4310541884, -9.9282600337, 29.7726729355],
      "speed factor": 1.0809064309,
      "Uz factor": 0.9975070766,
      "Directional variance factor": 0.117487997,
      "Velocity stability factor": 0.2677044289,
      "Direction stability factor": 0.8466563135,
      score: 0.5196262632,
    },
    "22.5": {
      velocity: [0.226811266, 0.7432972776, 1.4116648655],
      direction: [-84.0674599359, 2.1015247719, 95.9977327646],
      "speed factor": 0.6599555544,
      "Uz factor": 0.9998745968,
      "Directional variance factor": 0.8131977981,
      "Velocity stability factor": 0.2337866353,
      "Direction stability factor": 0.4998189092,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0743022453, 0.5888081849, 1.1146152014],
      direction: [-106.1105414932, 4.6449845327, 126.3845223383],
      "speed factor": 0.9121031313,
      "Uz factor": 0.9956026678,
      "Directional variance factor": 0.587112486,
      "Velocity stability factor": 0.070423649,
      "Direction stability factor": 0.3541803782,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2251016215, 0.8450107259, 1.1275609359],
      direction: [-74.2749376109, -23.6005822229, 3.0615007925],
      "speed factor": 0.9505780334,
      "Uz factor": 0.9890718998,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.252927006,
      "Direction stability factor": 0.78517656,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5680170747, 1.034058573, 1.2964221553],
      direction: [-43.0430522286, -11.4147438184, 2.4619093817],
      "speed factor": 1.4690256864,
      "Uz factor": 0.9965189552,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4043165568,
      "Direction stability factor": 0.8735973289,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6253864075, 0.9686077651, 1.1460428686],
      direction: [-25.8122823662, -8.7469063646, 5.1143776932],
      "speed factor": 1.1348745461,
      "Uz factor": 0.9872525577,
      "Directional variance factor": 0.222497212,
      "Velocity stability factor": 0.5996661565,
      "Direction stability factor": 0.9140926109,
      score: 0.6625871476,
    },
    "247.5": {
      velocity: [0.5121113637, 0.8147052472, 0.9722261672],
      direction: [-17.8307418631, 3.4121976101, 9.4014534016],
      "speed factor": 1.5113764108,
      "Uz factor": 0.9878381789,
      "Directional variance factor": 0.6966935458,
      "Velocity stability factor": 0.5535285897,
      "Direction stability factor": 0.9243550132,
      score: 0.7747330405,
    },
    "292.5": {
      velocity: [0.8959694488, 1.1570485379, 1.3300885447],
      direction: [2.657296596, 17.2843631614, 27.6187002667],
      "speed factor": 1.5756116296,
      "Uz factor": 0.9523735853,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6816432266,
      "Direction stability factor": 0.9306627676,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.5391943161, 0.8850977649, 1.1480890089],
      direction: [-21.1397500132, -1.5271063046, 18.2127826718],
      "speed factor": 1.1240192285,
      "Uz factor": 0.9739408672,
      "Directional variance factor": 0.8642572174,
      "Velocity stability factor": 0.5095752244,
      "Direction stability factor": 0.8906874092,
      score: 0.7888018151,
    },
    id: "IDN:728.0",
    "45": {
      velocity: [0.1785155008, 0.5334161519, 1.1536088182],
      direction: [-172.1359101346, 8.2180849085, 150.1084724862],
      "speed factor": 0.7245826541,
      "Uz factor": 0.9994588688,
      "Directional variance factor": 0.2695035637,
      "Velocity stability factor": 0.207279988,
      "Direction stability factor": 0.1048767149,
      score: 0.0,
    },
    "90": {
      velocity: [0.2170495284, 0.956899921, 1.3791615572],
      direction: [-52.9473070939, -5.6641191372, 17.7073050548],
      "speed factor": 1.5511627739,
      "Uz factor": 0.9854285202,
      "Directional variance factor": 0.4965227434,
      "Velocity stability factor": 0.1518237832,
      "Direction stability factor": 0.8037371885,
      score: 0.5639552259,
    },
    "135": {
      velocity: [0.229346765, 0.638895995, 0.9834603353],
      direction: [-108.815709784, -34.5626708782, 7.7516910296],
      "speed factor": 0.5407298329,
      "Uz factor": 0.9979884152,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3923271334,
      "Direction stability factor": 0.6762016644,
      score: 0.0,
    },
    "180": {
      velocity: [0.6334631939, 0.8898732922, 1.1189155208],
      direction: [-48.3868897058, -11.2518925618, 14.4801286354],
      "speed factor": 1.7379170424,
      "Uz factor": 0.9972318885,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6174844988,
      "Direction stability factor": 0.8253693935,
      score: 0.0,
    },
    "225": {
      velocity: [0.1620851797, 0.5511652089, 0.8833131397],
      direction: [-41.0538969388, -0.1687346591, 39.2697220303],
      "speed factor": 0.7524008272,
      "Uz factor": 0.9892506301,
      "Directional variance factor": 0.9850013636,
      "Velocity stability factor": 0.3517742943,
      "Direction stability factor": 0.7768788362,
      score: 0.0,
    },
    "270": {
      velocity: [0.6266983964, 0.8972717543, 1.1881699266],
      direction: [-9.6299655247, 21.9264810277, 50.0603221895],
      "speed factor": 0.8210326277,
      "Uz factor": 0.9620897664,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5571094578,
      "Direction stability factor": 0.8341936452,
      score: 0.0,
    },
    "315": {
      velocity: [0.7170810831, 1.1670172119, 1.3381751564],
      direction: [-3.12920514, 6.8349924183, 29.8996534915],
      "speed factor": 1.4378813444,
      "Uz factor": 0.975498014,
      "Directional variance factor": 0.3924451184,
      "Velocity stability factor": 0.4961461478,
      "Direction stability factor": 0.9082531705,
      score: 0.6762744018,
    },
  },
  {
    position: [-54.55578191, 52.86786819, 47.87999908],
    "overall score": 0.2489694584,
    "0": {
      velocity: [0.3971698393, 1.1057994049, 1.3556588414],
      direction: [-20.5728359058, -6.3695057359, 37.7340225934],
      "speed factor": 1.1331691005,
      "Uz factor": 0.9938585607,
      "Directional variance factor": 0.4338217124,
      "Velocity stability factor": 0.3340123263,
      "Direction stability factor": 0.8380365042,
      score: 0.6109767617,
    },
    "22.5": {
      velocity: [0.2069059657, 0.7154405443, 1.1657854339],
      direction: [-136.3850791718, 4.8577935827, 59.7228162289],
      "speed factor": 0.6352222392,
      "Uz factor": 0.9991290996,
      "Directional variance factor": 0.568196126,
      "Velocity stability factor": 0.3799181064,
      "Direction stability factor": 0.4552558461,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0353133566, 0.5117101326, 1.0570972808],
      direction: [-66.353265408, 13.6662665795, 118.5687309904],
      "speed factor": 0.7926731086,
      "Uz factor": 0.9840753349,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0869803493,
      "Direction stability factor": 0.4863277878,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1724229243, 0.6918261734, 1.1997222067],
      direction: [-88.6284220672, -17.9023390557, 58.0744787996],
      "speed factor": 0.7782561135,
      "Uz factor": 0.9649046925,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1495821049,
      "Direction stability factor": 0.592491942,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1309517626, 0.6000258381, 1.1886525414],
      direction: [-72.9530929093, -8.1382140777, 82.6253427397],
      "speed factor": 0.8524211216,
      "Uz factor": 0.9999838687,
      "Directional variance factor": 0.2766031931,
      "Velocity stability factor": 0.135021352,
      "Direction stability factor": 0.5678376788,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4612304637, 0.9444161229, 1.1961388093],
      direction: [-37.3880533842, -7.2341079006, 16.3435100302],
      "speed factor": 1.1065302773,
      "Uz factor": 0.9974023669,
      "Directional variance factor": 0.3569681866,
      "Velocity stability factor": 0.4349274339,
      "Direction stability factor": 0.8507456572,
      score: 0.6233467337,
    },
    "247.5": {
      velocity: [0.5448524217, 0.7684316703, 0.9494093991],
      direction: [-26.0551391474, -8.9495116819, 11.6442565069],
      "speed factor": 1.4255333493,
      "Uz factor": 0.9908539212,
      "Directional variance factor": 0.2044878505,
      "Velocity stability factor": 0.6074390068,
      "Direction stability factor": 0.8952794565,
      score: 0.6506214426,
    },
    "292.5": {
      velocity: [0.6919195355, 1.0977153175, 1.2632433115],
      direction: [-17.1852021836, 1.3555927423, 13.9315547448],
      "speed factor": 1.4948145766,
      "Uz factor": 0.9284318317,
      "Directional variance factor": 0.8795028673,
      "Velocity stability factor": 0.5810255858,
      "Direction stability factor": 0.9135645641,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6443024695, 0.9124473073, 1.1329022416],
      direction: [-21.0224391864, -2.7164087332, 9.3860707005],
      "speed factor": 1.1587514498,
      "Uz factor": 0.9623677665,
      "Directional variance factor": 0.7585414459,
      "Velocity stability factor": 0.6064649005,
      "Direction stability factor": 0.915531917,
      score: 0.7990175451,
    },
    id: "IDN:734.0",
    "45": {
      velocity: [0.0213946674, 0.5359881301, 1.0420149114],
      direction: [-154.9202139762, 14.7931874426, 74.0266651269],
      "speed factor": 0.7280763817,
      "Uz factor": 0.9978469268,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1702680373,
      "Direction stability factor": 0.3640364469,
      score: 0.0,
    },
    "90": {
      velocity: [0.0849108918, 0.8418744146, 1.3254518145],
      direction: [-47.2031465959, -1.5569307075, 123.9032526963],
      "speed factor": 1.3647030619,
      "Uz factor": 0.9612317983,
      "Directional variance factor": 0.8616061593,
      "Velocity stability factor": 0.0945818642,
      "Direction stability factor": 0.5247044464,
      score: 0.5013992291,
    },
    "135": {
      velocity: [0.0169663145, 0.5498264111, 1.1156589198],
      direction: [-148.998303917, -34.8268467622, 160.659879017],
      "speed factor": 0.4653457616,
      "Uz factor": 0.9885428111,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1146616223,
      "Direction stability factor": 0.1398383807,
      score: 0.0,
    },
    "180": {
      velocity: [0.2394275258, 0.6713625827, 1.0540114801],
      direction: [-91.7343509843, -13.0976253905, 24.5119322862],
      "speed factor": 1.3111669765,
      "Uz factor": 0.9978666809,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3581429684,
      "Direction stability factor": 0.6770936576,
      score: 0.0,
    },
    "225": {
      velocity: [0.22415338, 0.5742012088, 0.877413972],
      direction: [-46.1046220966, -3.0158963827, 35.2163707271],
      "speed factor": 0.783847488,
      "Uz factor": 0.9880367474,
      "Directional variance factor": 0.7319203215,
      "Velocity stability factor": 0.4128620467,
      "Direction stability factor": 0.7741083533,
      score: 0.0,
    },
    "270": {
      velocity: [0.7399923684, 1.0095906074, 1.3488344784],
      direction: [-13.1190336168, 6.1054595732, 29.3318357192],
      "speed factor": 0.923808005,
      "Uz factor": 0.937211911,
      "Directional variance factor": 0.4572924824,
      "Velocity stability factor": 0.5197433927,
      "Direction stability factor": 0.8820809185,
      score: 0.0,
    },
    "315": {
      velocity: [0.6572659377, 1.0517966571, 1.2266465259],
      direction: [-11.4067725658, -2.066373601, 17.7211591171],
      "speed factor": 1.2959181543,
      "Uz factor": 0.9566414253,
      "Directional variance factor": 0.8163223466,
      "Velocity stability factor": 0.538097987,
      "Direction stability factor": 0.9190890787,
      score: 0.7981496227,
    },
  },
  {
    position: [-53.93078572, 52.86568654, 47.87999908],
    "overall score": 0.2486267311,
    "0": {
      velocity: [0.387184175, 1.1016382329, 1.3750116415],
      direction: [-23.5088547139, -7.2914645734, 42.2253666481],
      "speed factor": 1.128904935,
      "Uz factor": 0.9936698532,
      "Directional variance factor": 0.3518698157,
      "Velocity stability factor": 0.3136270578,
      "Direction stability factor": 0.8174049407,
      score: 0.5750766887,
    },
    "22.5": {
      velocity: [0.2469781955, 0.7090895061, 1.20637308],
      direction: [-95.3320876037, 5.1960835606, 55.8296090385],
      "speed factor": 0.629583307,
      "Uz factor": 0.9995753678,
      "Directional variance factor": 0.5381259057,
      "Velocity stability factor": 0.3795848003,
      "Direction stability factor": 0.5801063982,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1197722056, 0.535893583, 1.0463677908],
      direction: [-58.3642351963, 14.0232791675, 118.4268958889],
      "speed factor": 0.8301348855,
      "Uz factor": 0.9808812392,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1720363205,
      "Direction stability factor": 0.5089135248,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2757205166, 0.7765906903, 1.2192438087],
      direction: [-70.8715699504, -17.8897379752, 66.7826142909],
      "speed factor": 0.8736102733,
      "Uz factor": 0.9564274592,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2189334638,
      "Direction stability factor": 0.617627266,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1486750236, 0.6660777635, 1.205971653],
      direction: [-65.9901744398, -8.9832023377, 68.3285083272],
      "speed factor": 0.9462571747,
      "Uz factor": 0.9999998413,
      "Directional variance factor": 0.2014931255,
      "Velocity stability factor": 0.135351862,
      "Direction stability factor": 0.6268925479,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3904567615, 0.9233110003, 1.1973622035],
      direction: [-43.6817379188, -6.7920049695, 16.8873795409],
      "speed factor": 1.0818023458,
      "Uz factor": 0.9977853468,
      "Directional variance factor": 0.3962662249,
      "Velocity stability factor": 0.3795687156,
      "Direction stability factor": 0.8317524515,
      score: 0.6098349609,
    },
    "247.5": {
      velocity: [0.538648005, 0.7817208543, 0.9768999224],
      direction: [-25.9844433523, -8.246170271, 11.8737615734],
      "speed factor": 1.450186387,
      "Uz factor": 0.9910037944,
      "Directional variance factor": 0.267007087,
      "Velocity stability factor": 0.5747431941,
      "Direction stability factor": 0.8948383197,
      score: 0.6578567301,
    },
    "292.5": {
      velocity: [0.6935663301, 1.1237767727, 1.304518444],
      direction: [-18.0352708012, 0.4144110748, 12.5873376668],
      "speed factor": 1.5303037808,
      "Uz factor": 0.9275777859,
      "Directional variance factor": 0.96316346,
      "Velocity stability factor": 0.5519645518,
      "Direction stability factor": 0.9149371987,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6541007444, 0.9223665888, 1.1441178577],
      direction: [-23.0981023697, -4.0544777907, 8.9550605372],
      "speed factor": 1.1713483218,
      "Uz factor": 0.9639295575,
      "Directional variance factor": 0.6396019742,
      "Velocity stability factor": 0.605323325,
      "Direction stability factor": 0.9109634364,
      score: 0.766713043,
    },
    id: "IDN:735.0",
    "45": {
      velocity: [0.0578110238, 0.5441389657, 1.049229908],
      direction: [-132.5104155634, 14.2855624756, 93.0430500597],
      "speed factor": 0.7391483264,
      "Uz factor": 0.9960062756,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1940078187,
      "Direction stability factor": 0.3734625955,
      score: 0.0,
    },
    "90": {
      velocity: [0.1122928254, 0.9224379537, 1.3349944718],
      direction: [-34.9298794354, -1.2275098969, 72.4657462189],
      "speed factor": 1.4952989165,
      "Uz factor": 0.9580289736,
      "Directional variance factor": 0.8908880092,
      "Velocity stability factor": 0.1076019944,
      "Direction stability factor": 0.7016788176,
      score: 0.6004619097,
    },
    "135": {
      velocity: [0.0710014497, 0.5842329913, 1.117816049],
      direction: [-139.1977042462, -34.9538429368, 164.7096038178],
      "speed factor": 0.4944657819,
      "Uz factor": 0.9824025361,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1564654803,
      "Direction stability factor": 0.1558130332,
      score: 0.0,
    },
    "180": {
      velocity: [0.1806348258, 0.6464101326, 1.0051329445],
      direction: [-79.5864056523, -14.0296055476, 16.0762918528],
      "speed factor": 1.2624349956,
      "Uz factor": 0.9979827115,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3503310344,
      "Direction stability factor": 0.7342702847,
      score: 0.0,
    },
    "225": {
      velocity: [0.2217071645, 0.5743230102, 0.877139006],
      direction: [-46.9203846234, -1.8528733533, 36.889032674],
      "speed factor": 0.7840137603,
      "Uz factor": 0.9881215668,
      "Directional variance factor": 0.8353001464,
      "Velocity stability factor": 0.4109105697,
      "Direction stability factor": 0.7671960631,
      score: 0.0,
    },
    "270": {
      velocity: [0.773459056, 1.043630122, 1.3989847319],
      direction: [-11.8228133604, 6.3519489692, 28.5204960329],
      "speed factor": 0.9549552601,
      "Uz factor": 0.9373493114,
      "Directional variance factor": 0.4353823138,
      "Velocity stability factor": 0.5065833424,
      "Direction stability factor": 0.8879352517,
      score: 0.0,
    },
    "315": {
      velocity: [0.6781585724, 1.0637436765, 1.2491338569],
      direction: [-12.4027156694, -3.3970520311, 16.8484849082],
      "speed factor": 1.3106380711,
      "Uz factor": 0.9572413536,
      "Directional variance factor": 0.6980398195,
      "Velocity stability factor": 0.536804312,
      "Direction stability factor": 0.9187466651,
      score: 0.7680843654,
    },
  },
  {
    position: [-50.4827199, 95.04948189, 47.87999908],
    "overall score": 0.2484043096,
    "0": {
      velocity: [0.7447576337, 1.1691259539, 1.3617499781],
      direction: [-14.620654462, 0.2902170716, 33.4861277636],
      "speed factor": 1.1980630479,
      "Uz factor": 0.9654011429,
      "Directional variance factor": 0.974202927,
      "Velocity stability factor": 0.5712947198,
      "Direction stability factor": 0.8663700494,
      score: 0.8195594364,
    },
    "22.5": {
      velocity: [0.3816937064, 1.0171781958, 1.5466774291],
      direction: [-39.069159629, 18.5752577739, 47.2730831297],
      "speed factor": 0.903127753,
      "Uz factor": 0.9542200566,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2466359571,
      "Direction stability factor": 0.7601604368,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2598609587, 0.7077941607, 1.2163681825],
      direction: [-31.1772540593, -3.9833010811, 38.662600845],
      "speed factor": 1.0964203401,
      "Uz factor": 0.9958489763,
      "Directional variance factor": 0.6459287928,
      "Velocity stability factor": 0.1453086405,
      "Direction stability factor": 0.806000403,
      score: 0.6008095598,
    },
    "112.5": {
      velocity: [0.0352334392, 0.3966041086, 0.8854924677],
      direction: [-152.7638677141, 43.9443482275, 160.0096091818],
      "speed factor": 0.4461519151,
      "Uz factor": 0.9999154286,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2961393961,
      "Direction stability factor": 0.1311847864,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0294515645, 0.4355127428, 1.0548980773],
      direction: [-114.8267158224, -9.7373534597, 152.0278309854],
      "speed factor": 0.6187071242,
      "Uz factor": 0.9999425885,
      "Directional variance factor": 0.1344574702,
      "Velocity stability factor": 0.1613986148,
      "Direction stability factor": 0.25873737,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5855553476, 1.0949327758, 1.494349228],
      direction: [-41.7529316138, -10.2581810241, 11.0608330599],
      "speed factor": 1.2828839307,
      "Uz factor": 0.997682281,
      "Directional variance factor": 0.0881616867,
      "Velocity stability factor": 0.3012264819,
      "Direction stability factor": 0.8532950981,
      score: 0.5239945912,
    },
    "247.5": {
      velocity: [0.2222302383, 0.4717109879, 0.8632629301],
      direction: [-39.4559198319, -3.9902822094, 30.4938181955],
      "speed factor": 0.8750807267,
      "Uz factor": 0.9783133872,
      "Directional variance factor": 0.6453082481,
      "Velocity stability factor": 0.3779753057,
      "Direction stability factor": 0.8056951721,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4026773775, 0.7555206054, 1.0725290686],
      direction: [-53.5088156158, -19.0680293763, 31.6805138868],
      "speed factor": 1.0288306957,
      "Uz factor": 0.9579603567,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5087711527,
      "Direction stability factor": 0.7633629736,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6942597485, 0.9487210515, 1.1218461644],
      direction: [-4.3099896949, 6.9593544225, 26.742952719],
      "speed factor": 1.2048168537,
      "Uz factor": 0.9297919231,
      "Directional variance factor": 0.381390718,
      "Velocity stability factor": 0.6556071607,
      "Direction stability factor": 0.9137418266,
      score: 0.0,
    },
    id: "IDN:697.0",
    "45": {
      velocity: [0.2373898037, 0.764906685, 1.295001891],
      direction: [-29.3283653603, -0.471603892, 45.4066774067],
      "speed factor": 1.039035121,
      "Uz factor": 0.986606958,
      "Directional variance factor": 0.958079654,
      "Velocity stability factor": 0.1401948392,
      "Direction stability factor": 0.792402659,
      score: 0.6707699528,
    },
    "90": {
      velocity: [0.0937480308, 0.5930342423, 1.1375743926],
      direction: [-85.3634113969, 24.6270835231, 137.5182134413],
      "speed factor": 0.9613258609,
      "Uz factor": 0.996328173,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.238155468,
      "Direction stability factor": 0.3808843754,
      score: 0.0,
    },
    "135": {
      velocity: [0.0847544387, 0.3644353158, 0.8451445226],
      direction: [-172.6145334685, 48.7514660992, 176.2990321543],
      "speed factor": 0.3084399479,
      "Uz factor": 0.9988813952,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.387269451,
      "Direction stability factor": 0.030795651,
      score: 0.0,
    },
    "180": {
      velocity: [0.1264130503, 0.6560039586, 1.3610632274],
      direction: [-115.9922543829, -27.7859118421, 171.6087342906],
      "speed factor": 1.2811716785,
      "Uz factor": 0.9943386698,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0271488979,
      "Direction stability factor": 0.2011083648,
      score: 0.0,
    },
    "225": {
      velocity: [0.4351983348, 0.9285296036, 1.1844325479],
      direction: [-21.3014402173, -2.2771274471, 13.0341119543],
      "speed factor": 1.2675445231,
      "Uz factor": 0.9957978152,
      "Directional variance factor": 0.7975886714,
      "Velocity stability factor": 0.3266028169,
      "Direction stability factor": 0.9046234662,
      score: 0.7333596052,
    },
    "270": {
      velocity: [0.758569038, 1.1032919634, 1.3539607432],
      direction: [-23.4202642539, -9.7646790118, 5.1058498917],
      "speed factor": 1.0095477714,
      "Uz factor": 0.9808392642,
      "Directional variance factor": 0.1320285323,
      "Velocity stability factor": 0.5303531152,
      "Direction stability factor": 0.920760794,
      score: 0.6259758089,
    },
    "315": {
      velocity: [0.6793184948, 0.8264175809, 0.9791827789],
      direction: [-9.4949369273, 6.5286457757, 25.9240089924],
      "speed factor": 1.0182287031,
      "Uz factor": 0.9413267354,
      "Directional variance factor": 0.4196759311,
      "Velocity stability factor": 0.756739307,
      "Direction stability factor": 0.9016140391,
      score: 0.0,
    },
  },
  {
    position: [-54.87082048, 44.06423051, 47.87999908],
    "overall score": 0.2445795925,
    "0": {
      velocity: [0.487295292, 1.0951661039, 1.4394987448],
      direction: [-22.9159730779, -8.9330353584, 37.0035136099],
      "speed factor": 1.1222726141,
      "Uz factor": 0.997031035,
      "Directional variance factor": 0.2059524126,
      "Velocity stability factor": 0.3383797194,
      "Direction stability factor": 0.8335569814,
      score: 0.5528615237,
    },
    "22.5": {
      velocity: [0.1902220496, 0.7365429005, 1.2626656385],
      direction: [-74.5260557862, 1.1126229079, 97.0295067082],
      "speed factor": 0.6539585074,
      "Uz factor": 0.9990809931,
      "Directional variance factor": 0.901100186,
      "Velocity stability factor": 0.3064792046,
      "Direction stability factor": 0.5234567708,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0821779086, 0.5271398806, 1.1084992927],
      direction: [-134.5391147544, 6.8131385255, 74.3303468412],
      "speed factor": 0.8165748168,
      "Uz factor": 0.9997623493,
      "Directional variance factor": 0.3943876866,
      "Velocity stability factor": 0.0829258815,
      "Direction stability factor": 0.4198070511,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1068913974, 0.5335169983, 1.0287562568],
      direction: [-152.9362144481, -22.6978400916, 31.5959561039],
      "speed factor": 0.6001693511,
      "Uz factor": 0.9976711936,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2368627266,
      "Direction stability factor": 0.4874106374,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0333898613, 0.4104256432, 1.1780164017],
      direction: [-141.8180026459, -10.1391672793, 55.6393548835],
      "speed factor": 0.5830673697,
      "Uz factor": 0.9926968032,
      "Directional variance factor": 0.0987406863,
      "Velocity stability factor": 0.0639342079,
      "Direction stability factor": 0.4515073402,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3120088614, 0.9537271436, 1.1952126876],
      direction: [-38.9049356842, -7.8327913935, 9.4194564035],
      "speed factor": 1.1174395852,
      "Uz factor": 0.9959951585,
      "Directional variance factor": 0.3037518761,
      "Velocity stability factor": 0.3209027282,
      "Direction stability factor": 0.8657655775,
      score: 0.5890464399,
    },
    "247.5": {
      velocity: [0.52437303, 0.810710511, 0.9913149727],
      direction: [-21.8055353732, -2.553192928, 8.310363021],
      "speed factor": 1.5039656937,
      "Uz factor": 0.9838227039,
      "Directional variance factor": 0.7730495175,
      "Velocity stability factor": 0.5469038898,
      "Direction stability factor": 0.9163447267,
      score: 0.7881607152,
    },
    "292.5": {
      velocity: [0.6963864447, 1.1474896079, 1.3288789784],
      direction: [-7.9404082647, 9.0263372139, 29.6608094354],
      "speed factor": 1.5625947502,
      "Uz factor": 0.9284123653,
      "Directional variance factor": 0.1976589143,
      "Velocity stability factor": 0.5361681066,
      "Direction stability factor": 0.8955521731,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.5957512908, 0.9094943374, 1.1256416982],
      direction: [-23.2423009652, -2.7713923815, 10.1295672113],
      "speed factor": 1.1550013615,
      "Uz factor": 0.9696821817,
      "Directional variance factor": 0.7536540105,
      "Velocity stability factor": 0.5732079993,
      "Direction stability factor": 0.9073003662,
      score: 0.7853656855,
    },
    id: "IDN:731.0",
    "45": {
      velocity: [0.1953869055, 0.5217505971, 1.1369164382],
      direction: [-139.1699985447, 4.1305901818, 81.2199863316],
      "speed factor": 0.7087363798,
      "Uz factor": 0.9999551611,
      "Directional variance factor": 0.6328364283,
      "Velocity stability factor": 0.2345662828,
      "Direction stability factor": 0.3878055976,
      score: 0.0,
    },
    "90": {
      velocity: [0.1089623632, 0.7927804327, 1.259001962],
      direction: [-108.9915712796, -7.8697046902, 38.3194574685],
      "speed factor": 1.2851202807,
      "Uz factor": 0.99820969,
      "Directional variance factor": 0.3004706942,
      "Velocity stability factor": 0.160634937,
      "Direction stability factor": 0.5908026979,
      score: 0.4106777568,
    },
    "135": {
      velocity: [0.0372469511, 0.6009364138, 1.0449009388],
      direction: [-117.6134073376, -30.3894329503, 174.4335940315],
      "speed factor": 0.508602729,
      "Uz factor": 0.9998903538,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1880215245,
      "Direction stability factor": 0.1887583295,
      score: 0.0,
    },
    "180": {
      velocity: [0.30610116, 0.8447156198, 1.1676440076],
      direction: [-92.9444692575, -14.2172030039, 13.2274652503],
      "speed factor": 1.649724387,
      "Uz factor": 0.9999999261,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3211413854,
      "Direction stability factor": 0.7050779597,
      score: 0.0,
    },
    "225": {
      velocity: [0.1351734208, 0.5585568608, 0.8755898455],
      direction: [-50.2608762014, -0.8272385147, 31.3821381817],
      "speed factor": 0.762491241,
      "Uz factor": 0.9877614664,
      "Directional variance factor": 0.9264676876,
      "Velocity stability factor": 0.3345280742,
      "Direction stability factor": 0.7732138489,
      score: 0.0,
    },
    "270": {
      velocity: [0.7092169556, 0.9760798537, 1.2680395998],
      direction: [-12.8609925571, 14.881681784, 35.5870037246],
      "speed factor": 0.8931445834,
      "Uz factor": 0.9395129881,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5591989076,
      "Direction stability factor": 0.8654222326,
      score: 0.0,
    },
    "315": {
      velocity: [0.6538054248, 1.1105218971, 1.2946107509],
      direction: [-9.4166110153, 1.4406717405, 27.2044828858],
      "speed factor": 1.3682734942,
      "Uz factor": 0.9613724309,
      "Directional variance factor": 0.8719402897,
      "Velocity stability factor": 0.4801556705,
      "Direction stability factor": 0.8982747392,
      score: 0.7871613596,
    },
  },
  {
    position: [-55.0853934, 38.06806853, 47.87999908],
    "overall score": 0.2441297408,
    "0": {
      velocity: [0.3099622199, 1.0612918899, 1.4130063362],
      direction: [-22.9362905471, -9.9643074939, 32.9086267538],
      "speed factor": 1.0875599777,
      "Uz factor": 0.9977164171,
      "Directional variance factor": 0.1142837783,
      "Velocity stability factor": 0.2335709816,
      "Direction stability factor": 0.8448752297,
      score: 0.5094013048,
    },
    "22.5": {
      velocity: [0.2414309846, 0.7182414915, 1.2702543393],
      direction: [-81.0757054362, 0.360886051, 107.4602880504],
      "speed factor": 0.637709132,
      "Uz factor": 0.9999292777,
      "Directional variance factor": 0.9679212399,
      "Velocity stability factor": 0.334687252,
      "Direction stability factor": 0.476288907,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1029356875, 0.5633114179, 1.0942828498],
      direction: [-156.9549841584, 10.0238183268, 145.0784361806],
      "speed factor": 0.8726069396,
      "Uz factor": 0.9968083693,
      "Directional variance factor": 0.1089939265,
      "Velocity stability factor": 0.1141772557,
      "Direction stability factor": 0.1610182768,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1593960091, 0.7793871732, 1.1263894368],
      direction: [-175.0431339476, -17.4465169845, 2.8690980938],
      "speed factor": 0.8767561212,
      "Uz factor": 0.9910207123,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1995044391,
      "Direction stability factor": 0.5057993554,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.401944286, 1.0305425477, 1.3143661124],
      direction: [-43.8967366541, -14.4817977548, 6.5190393125],
      "speed factor": 1.4640306778,
      "Uz factor": 0.9982506059,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2538292364,
      "Direction stability factor": 0.8599561779,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5312584686, 0.9762973164, 1.2041486829],
      direction: [-27.4875957459, -9.1013899126, 3.5492820573],
      "speed factor": 1.1438840506,
      "Uz factor": 0.9915705272,
      "Directional variance factor": 0.1909875633,
      "Velocity stability factor": 0.4826133049,
      "Direction stability factor": 0.9137864505,
      score: 0.6252934423,
    },
    "247.5": {
      velocity: [0.509577166, 0.8142627608, 0.9745699286],
      direction: [-19.1695520892, 1.5213559991, 8.7517124692],
      "speed factor": 1.5105555451,
      "Uz factor": 0.9847988678,
      "Directional variance factor": 0.8647683556,
      "Velocity stability factor": 0.5487952726,
      "Direction stability factor": 0.9224409318,
      score: 0.8146113729,
    },
    "292.5": {
      velocity: [0.7472370976, 1.1535021493, 1.3232023631],
      direction: [1.8190166699, 14.5392268144, 29.3241864935],
      "speed factor": 1.5707823325,
      "Uz factor": 0.940004048,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5776217972,
      "Direction stability factor": 0.9235967505,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.5776621126, 0.8938999754, 1.148283072],
      direction: [-22.4345298035, -2.3075114499, 12.8113417806],
      "speed factor": 1.1351974895,
      "Uz factor": 0.9709112382,
      "Directional variance factor": 0.7948878711,
      "Velocity stability factor": 0.5404022087,
      "Direction stability factor": 0.9020948012,
      score: 0.7848699205,
    },
    id: "IDN:729.0",
    "45": {
      velocity: [0.0896230716, 0.544984697, 1.1305653008],
      direction: [-172.1469174833, 10.3730309088, 165.0031088119],
      "speed factor": 0.7402971522,
      "Uz factor": 0.9999535166,
      "Directional variance factor": 0.0779528081,
      "Velocity stability factor": 0.1537469064,
      "Direction stability factor": 0.0634721492,
      score: 0.0,
    },
    "90": {
      velocity: [0.155680828, 0.8774618875, 1.3022022146],
      direction: [-80.07348954, -1.7402202665, 152.1966780753],
      "speed factor": 1.422391397,
      "Uz factor": 0.9897744175,
      "Directional variance factor": 0.8453137541,
      "Velocity stability factor": 0.1632027308,
      "Direction stability factor": 0.35480509,
      score: 0.4295316662,
    },
    "135": {
      velocity: [0.1884769137, 0.6254543068, 1.0132576472],
      direction: [-158.316444691, -31.4688702081, 100.0960241425],
      "speed factor": 0.5293534557,
      "Uz factor": 0.9984232132,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3353827694,
      "Direction stability factor": 0.2821875866,
      score: 0.0,
    },
    "180": {
      velocity: [0.5604616075, 0.8922692532, 1.1637368949],
      direction: [-46.3764135022, -12.4086046907, 8.0024664801],
      "speed factor": 1.7425963393,
      "Uz factor": 0.9998890795,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5246450865,
      "Direction stability factor": 0.8489475556,
      score: 0.0,
    },
    "225": {
      velocity: [0.139963695, 0.5586982248, 0.8997756317],
      direction: [-38.7296768196, -0.6700618349, 34.3491821048],
      "speed factor": 0.7626842182,
      "Uz factor": 0.9891065468,
      "Directional variance factor": 0.940438948,
      "Velocity stability factor": 0.3170957641,
      "Direction stability factor": 0.7970031697,
      score: 0.0,
    },
    "270": {
      velocity: [0.6329512914, 0.9234916142, 1.1966458553],
      direction: [-8.9266306966, 20.1636629818, 42.0660531334],
      "speed factor": 0.8450246462,
      "Uz factor": 0.954170239,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5553559218,
      "Direction stability factor": 0.858353656,
      score: 0.0,
    },
    "315": {
      velocity: [0.7782076663, 1.1450761439, 1.3111546962],
      direction: [-4.4846456343, 4.8278140587, 25.9430208337],
      "speed factor": 1.410847765,
      "Uz factor": 0.9681827889,
      "Directional variance factor": 0.5708609726,
      "Velocity stability factor": 0.5676541999,
      "Direction stability factor": 0.9154787043,
      score: 0.7423681453,
    },
  },
  {
    position: [-52.79462529, 83.24889712, 47.87999908],
    "overall score": 0.2410280876,
    "0": {
      velocity: [0.4628448335, 1.195236841, 1.5249982832],
      direction: [-16.5094473549, -3.055445, 68.7449630137],
      "speed factor": 1.224820207,
      "Uz factor": 0.9987557536,
      "Directional variance factor": 0.7284048889,
      "Velocity stability factor": 0.2619830759,
      "Direction stability factor": 0.7631821934,
      score: 0.6291880879,
    },
    "22.5": {
      velocity: [0.1744549522, 0.7161220414, 1.2275847247],
      direction: [-64.8082654251, 18.6746589952, 129.6390649484],
      "speed factor": 0.6358273238,
      "Uz factor": 0.9944034327,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.318968937,
      "Direction stability factor": 0.4598685267,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1239876872, 0.5075645308, 0.9815539667],
      direction: [-66.2482541961, 6.3057384396, 119.5015917065],
      "speed factor": 0.7862512951,
      "Uz factor": 0.9994611345,
      "Directional variance factor": 0.4394899165,
      "Velocity stability factor": 0.233717769,
      "Direction stability factor": 0.4840282058,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1129838381, 0.7076650034, 1.1201150868],
      direction: [-61.6766170007, -0.0983306321, 64.8818657947],
      "speed factor": 0.7960736907,
      "Uz factor": 0.9926561137,
      "Directional variance factor": 0.9912594994,
      "Velocity stability factor": 0.166277587,
      "Direction stability factor": 0.6484486589,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2548104978, 0.8858280934, 1.2299224504],
      direction: [-66.3403828476, -16.0945586854, 14.2420716013],
      "speed factor": 1.2584434353,
      "Uz factor": 0.9950002865,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2025617876,
      "Direction stability factor": 0.7761598488,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5367333536, 1.0529226145, 1.3841726948],
      direction: [-39.0041616353, -10.9453613825, 10.0774460329],
      "speed factor": 1.2336624971,
      "Uz factor": 0.9999864384,
      "Directional variance factor": 0.0270789882,
      "Velocity stability factor": 0.3484021157,
      "Direction stability factor": 0.8636622009,
      score: 0.5257013764,
    },
    "247.5": {
      velocity: [0.1947118336, 0.4339507734, 0.6917699343],
      direction: [-24.3350597626, 1.6412254392, 40.9926022582],
      "speed factor": 0.8050309785,
      "Uz factor": 0.9819762369,
      "Directional variance factor": 0.8541132943,
      "Velocity stability factor": 0.5176807406,
      "Direction stability factor": 0.8185342722,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4521637768, 0.8838027479, 1.1873147035],
      direction: [-43.7561968374, -6.3148150145, 30.8424806341],
      "speed factor": 1.2035189902,
      "Uz factor": 0.9287300028,
      "Directional variance factor": 0.4386831098,
      "Velocity stability factor": 0.46088463,
      "Direction stability factor": 0.7927814515,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7500296117, 1.0153171749, 1.2516660202],
      direction: [-8.9998799212, 3.6466227097, 20.4935168811],
      "speed factor": 1.2893897972,
      "Uz factor": 0.9817809586,
      "Directional variance factor": 0.6758557591,
      "Velocity stability factor": 0.5959647441,
      "Direction stability factor": 0.9180738978,
      score: 0.7769920747,
    },
    id: "IDN:702.0",
    "45": {
      velocity: [0.123036498, 0.5459733916, 1.1108061144],
      direction: [-51.9193253775, 3.9142380759, 62.9824506535],
      "speed factor": 0.7416401766,
      "Uz factor": 0.9955785778,
      "Directional variance factor": 0.6520677266,
      "Velocity stability factor": 0.196974558,
      "Direction stability factor": 0.6808283999,
      score: 0.0,
    },
    "90": {
      velocity: [0.1237787331, 0.6825517326, 1.4939091443],
      direction: [-102.7184560585, 9.9327217017, 46.5647236102],
      "speed factor": 1.1064363322,
      "Uz factor": 0.9829914794,
      "Directional variance factor": 0.1170914043,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.5853245009,
      score: 0.3219351015,
    },
    "135": {
      velocity: [0.0895898604, 0.893088285, 1.3060301456],
      direction: [-116.7536397061, -21.552637192, 21.7040851943],
      "speed factor": 0.7558655601,
      "Uz factor": 0.9933494875,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0197792691,
      "Direction stability factor": 0.6153952086,
      score: 0.0,
    },
    "180": {
      velocity: [0.3926462574, 0.9145343396, 1.2815299474],
      direction: [-55.1647110077, -26.5570898285, 2.9325286867],
      "speed factor": 1.7860799156,
      "Uz factor": 0.9996220174,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2995979805,
      "Direction stability factor": 0.8386187786,
      score: 0.0,
    },
    "225": {
      velocity: [0.5639865651, 0.8099808435, 1.0810167342],
      direction: [-14.6118316417, 2.144172882, 21.6121120423],
      "speed factor": 1.105712492,
      "Uz factor": 0.9927149743,
      "Directional variance factor": 0.8094068549,
      "Velocity stability factor": 0.5353033093,
      "Direction stability factor": 0.8993779342,
      score: 0.7858665082,
    },
    "270": {
      velocity: [0.7841427435, 1.0231661806, 1.233052021],
      direction: [-16.7823757815, 4.6456473744, 20.8415987807],
      "speed factor": 0.9362300928,
      "Uz factor": 0.9283698116,
      "Directional variance factor": 0.5870535667,
      "Velocity stability factor": 0.6458989235,
      "Direction stability factor": 0.8954889595,
      score: 0.0,
    },
    "315": {
      velocity: [0.6809944822, 0.9031552914, 1.1786710371],
      direction: [-8.9643508864, 2.3270731572, 13.0588143959],
      "speed factor": 1.1127771992,
      "Uz factor": 0.963774423,
      "Directional variance factor": 0.7931490527,
      "Velocity stability factor": 0.5962668778,
      "Direction stability factor": 0.9388245409,
      score: 0.8167662531,
    },
  },
  {
    position: [-50.07556487, 49.15221146, 47.87999908],
    "overall score": 0.2394659493,
    "0": {
      velocity: [0.266544094, 0.9966886817, 1.4131978317],
      direction: [-41.3231495638, -13.148835949, 28.9582360377],
      "speed factor": 1.0213577723,
      "Uz factor": 0.998938225,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2032696737,
      "Direction stability factor": 0.8047739289,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1317356623, 0.7277514682, 1.2128458238],
      direction: [-74.9596772838, 7.0446867137, 102.0179413558],
      "speed factor": 0.6461528088,
      "Uz factor": 0.9934979826,
      "Directional variance factor": 0.3738056255,
      "Velocity stability factor": 0.3008747623,
      "Direction stability factor": 0.5083955038,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.165192073, 0.5856496663, 1.0300396985],
      direction: [-31.5415734225, 9.6880791751, 44.4433416348],
      "speed factor": 0.9072103755,
      "Uz factor": 0.9752896973,
      "Directional variance factor": 0.1388374067,
      "Velocity stability factor": 0.2272114894,
      "Direction stability factor": 0.7889307915,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5662910263, 0.9083031024, 1.2725296853],
      direction: [-46.9758795007, -17.3156606777, 2.2656078176],
      "speed factor": 1.0217775354,
      "Uz factor": 0.9508832892,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4153621986,
      "Direction stability factor": 0.8632180908,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3059588657, 0.9560938732, 1.307986527],
      direction: [-34.677402315, -8.3805516289, 17.8958201244],
      "speed factor": 1.3582658614,
      "Uz factor": 0.9978432006,
      "Directional variance factor": 0.2550620774,
      "Velocity stability factor": 0.1805503512,
      "Direction stability factor": 0.853963271,
      score: 0.5358847427,
    },
    "202.5": {
      velocity: [0.1442432269, 0.6622781578, 1.2203705422],
      direction: [-132.8185072661, -7.4746423154, 21.1420346038],
      "speed factor": 0.7759617988,
      "Uz factor": 0.9998864157,
      "Directional variance factor": 0.3355873497,
      "Velocity stability factor": 0.1725634534,
      "Direction stability factor": 0.5723318281,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4368000662, 0.8099859793, 1.1073552089],
      direction: [-14.3793630294, -1.8687861757, 11.8779274251],
      "speed factor": 1.5026216,
      "Uz factor": 0.9947388344,
      "Directional variance factor": 0.8338856733,
      "Velocity stability factor": 0.3493282589,
      "Direction stability factor": 0.9270630821,
      score: 0.7593350241,
    },
    "292.5": {
      velocity: [0.4530665802, 1.1430020541, 1.3680249184],
      direction: [-30.6512436682, -1.4802842501, 20.6945124672],
      "speed factor": 1.5564838208,
      "Uz factor": 0.9649766911,
      "Directional variance factor": 0.8684191778,
      "Velocity stability factor": 0.3290247144,
      "Direction stability factor": 0.8573728996,
      score: 0.7280474229,
    },
    "337.5": {
      velocity: [0.1948191623, 0.9003275696, 1.21897744],
      direction: [-28.685851815, -7.6472387319, 24.60391054],
      "speed factor": 1.1433601352,
      "Uz factor": 0.9901259415,
      "Directional variance factor": 0.3202454461,
      "Velocity stability factor": 0.1751076181,
      "Direction stability factor": 0.8519728823,
      score: 0.5498247072,
    },
    id: "IDN:741.0",
    "45": {
      velocity: [0.1133004105, 0.5628711805, 1.0470538045],
      direction: [-109.0441410112, 5.4170615865, 103.7837336439],
      "speed factor": 0.7645938211,
      "Uz factor": 0.983563135,
      "Directional variance factor": 0.5184834145,
      "Velocity stability factor": 0.2408880376,
      "Direction stability factor": 0.4088114593,
      score: 0.0,
    },
    "90": {
      velocity: [0.5772536873, 1.0877859018, 1.40250623],
      direction: [-35.5580441471, -6.2872000289, 7.9915478948],
      "speed factor": 1.7633327788,
      "Uz factor": 0.9598010406,
      "Directional variance factor": 0.4411377752,
      "Velocity stability factor": 0.3976832161,
      "Direction stability factor": 0.879028911,
      score: 0.6492197033,
    },
    "135": {
      velocity: [0.2982184694, 0.7106925766, 1.0456921985],
      direction: [-114.6441156721, -36.3648789888, 17.3834087626],
      "speed factor": 0.601494893,
      "Uz factor": 0.9622338327,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3976775892,
      "Direction stability factor": 0.6332568766,
      score: 0.0,
    },
    "180": {
      velocity: [0.1335963909, 0.5467594196, 0.9516691398],
      direction: [-92.8664245375, -14.7584742877, 125.0271643577],
      "speed factor": 1.0678177688,
      "Uz factor": 0.9998619569,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3553939487,
      "Direction stability factor": 0.3947400308,
      score: 0.0,
    },
    "225": {
      velocity: [0.0600926541, 0.5260365624, 0.9397436198],
      direction: [-60.4809934825, 4.1388016827, 71.459772051],
      "speed factor": 0.7180974748,
      "Uz factor": 0.9967894972,
      "Directional variance factor": 0.6321065171,
      "Velocity stability factor": 0.2093867685,
      "Direction stability factor": 0.6334978735,
      score: 0.0,
    },
    "270": {
      velocity: [0.438890648, 0.9709516817, 1.2994460187],
      direction: [-1.3914959914, 11.5986753048, 31.9368671242],
      "speed factor": 0.8884521404,
      "Uz factor": 0.9747354501,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3211911661,
      "Direction stability factor": 0.9074212136,
      score: 0.0,
    },
    "315": {
      velocity: [0.3990363807, 1.0179952754, 1.3001918437],
      direction: [-21.4428863216, -6.8452975579, 18.8600181346],
      "speed factor": 1.2542714882,
      "Uz factor": 0.9785466812,
      "Directional variance factor": 0.391529106,
      "Velocity stability factor": 0.2689502751,
      "Direction stability factor": 0.8880474876,
      score: 0.6091435891,
    },
  },
  {
    position: [-45.85689357, 38.73742191, 46.87999908],
    "overall score": 0.2360651696,
    "0": {
      velocity: [0.2943743689, 0.8178454664, 1.3984713957],
      direction: [-37.759505806, -9.9016256779, 85.2348363457],
      "speed factor": 0.8380879998,
      "Uz factor": 0.9944900266,
      "Directional variance factor": 0.1198554953,
      "Velocity stability factor": 0.232839387,
      "Direction stability factor": 0.6583490496,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1404791737, 0.7603148221, 1.2457302274],
      direction: [-78.9579815898, -3.569310342, 71.8162121645],
      "speed factor": 0.6750650178,
      "Uz factor": 0.9876252226,
      "Directional variance factor": 0.6827279696,
      "Velocity stability factor": 0.2852634883,
      "Direction stability factor": 0.5811827951,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4404276767, 0.6581762408, 0.9945529823],
      direction: [-27.0291949026, -2.718253188, 25.8027490637],
      "speed factor": 1.0195588744,
      "Uz factor": 0.9759844942,
      "Directional variance factor": 0.7583774944,
      "Velocity stability factor": 0.5048588248,
      "Direction stability factor": 0.8532446001,
      score: 0.7424313798,
    },
    "112.5": {
      velocity: [0.4561675468, 0.809893972, 1.1750349248],
      direction: [-49.433713306, -25.1202238351, 3.5082821018],
      "speed factor": 0.9110741386,
      "Uz factor": 0.9369057853,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4049079047,
      "Direction stability factor": 0.8529389016,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5474185315, 0.9219551837, 1.1809409459],
      direction: [-41.1536975481, 1.4226409659, 30.2141551246],
      "speed factor": 1.3097670499,
      "Uz factor": 0.9627770578,
      "Directional variance factor": 0.8735430253,
      "Velocity stability factor": 0.4819107895,
      "Direction stability factor": 0.8017559648,
      score: 0.7397414361,
    },
    "202.5": {
      velocity: [0.5040557391, 0.8834959827, 1.1430219653],
      direction: [-24.7546412243, -0.7512289127, 14.9124764937],
      "speed factor": 1.0351528643,
      "Uz factor": 0.985557063,
      "Directional variance factor": 0.9332240967,
      "Velocity stability factor": 0.5086975305,
      "Direction stability factor": 0.8898135619,
      score: 0.8053871877,
    },
    "247.5": {
      velocity: [0.3149381811, 0.7498790428, 1.0478848113],
      direction: [-38.9779952331, 1.5522681106, 14.8951762741],
      "speed factor": 1.3911159896,
      "Uz factor": 0.9998875047,
      "Directional variance factor": 0.8620206124,
      "Velocity stability factor": 0.2887868132,
      "Direction stability factor": 0.8503523014,
      score: 0.7128780071,
    },
    "292.5": {
      velocity: [0.0210272346, 0.1861328435, 0.6720643889],
      direction: [-178.5638727745, 78.0292299878, 172.4959252751],
      "speed factor": 0.2534665256,
      "Uz factor": 0.991259085,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5225685997,
      "Direction stability factor": 0.0248338943,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0539154201, 0.4464355454, 0.9323860848],
      direction: [-168.5251062501, -7.0889258193, 178.8517421059],
      "speed factor": 0.5669454349,
      "Uz factor": 0.9994816214,
      "Directional variance factor": 0.3698732605,
      "Velocity stability factor": 0.2924494438,
      "Direction stability factor": 0.0350643101,
      score: 0.0,
    },
    id: "IDN:481.0",
    "45": {
      velocity: [0.1344388494, 0.5359986227, 1.0288035603],
      direction: [-125.994434767, 4.2955508158, 82.0971098547],
      "speed factor": 0.7280906347,
      "Uz factor": 0.9736814669,
      "Directional variance factor": 0.6181732608,
      "Velocity stability factor": 0.2729097905,
      "Direction stability factor": 0.4219679316,
      score: 0.0,
    },
    "90": {
      velocity: [0.5745933868, 0.9774284652, 1.2460549937],
      direction: [-53.8291833058, -12.2586574453, 19.2811382485],
      "speed factor": 1.5844401447,
      "Uz factor": 0.9540682904,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5099286889,
      "Direction stability factor": 0.7969157735,
      score: 0.0,
    },
    "135": {
      velocity: [0.1916777719, 0.6237853893, 0.87417179],
      direction: [-113.1941339377, -34.665419871, 27.386104649],
      "speed factor": 0.5279409668,
      "Uz factor": 0.9511731019,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4500389428,
      "Direction stability factor": 0.6094993373,
      score: 0.0,
    },
    "180": {
      velocity: [0.5117666615, 0.8449119983, 1.0983834374],
      direction: [-20.9964805949, 2.3215669401, 19.5018923776],
      "speed factor": 1.6501079131,
      "Uz factor": 0.9799847346,
      "Directional variance factor": 0.7936384942,
      "Velocity stability factor": 0.5377712752,
      "Direction stability factor": 0.8875045195,
      score: 0.7766047021,
    },
    "225": {
      velocity: [0.0915871602, 0.4609618591, 0.8751352793],
      direction: [-53.3266775944, 3.180632046, 93.7308080377],
      "speed factor": 0.6292633832,
      "Uz factor": 0.9986716765,
      "Directional variance factor": 0.7172771515,
      "Velocity stability factor": 0.2957621436,
      "Direction stability factor": 0.5915069844,
      score: 0.0,
    },
    "270": {
      velocity: [0.0672803132, 0.4154248592, 1.03639912],
      direction: [-145.0103118509, 23.8856063621, 172.39616679],
      "speed factor": 0.38012716,
      "Uz factor": 0.9988090355,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2355559799,
      "Direction stability factor": 0.1183153371,
      score: 0.0,
    },
    "315": {
      velocity: [0.1019454284, 0.3376665155, 1.039546364],
      direction: [-47.8521326798, 13.4841084314, 112.6107814014],
      "speed factor": 0.4160387511,
      "Uz factor": 0.9994915973,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2393843968,
      "Direction stability factor": 0.5542696831,
      score: 0.0,
    },
  },
  {
    position: [-52.35511895, 89.23277832, 47.87999908],
    "overall score": 0.231533917,
    "0": {
      velocity: [0.8249582537, 1.2595442049, 1.4634027953],
      direction: [-11.6276182067, 0.4126332855, 16.8858064189],
      "speed factor": 1.2907192455,
      "Uz factor": 0.9897514181,
      "Directional variance factor": 0.9633214857,
      "Velocity stability factor": 0.5563890726,
      "Direction stability factor": 0.9207960427,
      score: 0.8403256609,
    },
    "22.5": {
      velocity: [0.1479875231, 0.712474325, 1.27677244],
      direction: [-56.1759967924, 8.9424069759, 172.3372268],
      "speed factor": 0.6325886053,
      "Uz factor": 0.9964562919,
      "Directional variance factor": 0.2051193799,
      "Velocity stability factor": 0.2700447637,
      "Direction stability factor": 0.3652410456,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3003620979, 0.6330772221, 1.1598500429],
      direction: [-31.9390671409, -0.4161974375, 50.744819092],
      "speed factor": 0.9806788212,
      "Uz factor": 0.9981561175,
      "Directional variance factor": 0.9630046722,
      "Velocity stability factor": 0.2320006562,
      "Direction stability factor": 0.7703225382,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0960829812, 0.3894293987, 1.0345772411],
      direction: [-164.2199486279, 30.5578646224, 163.0218431094],
      "speed factor": 0.4380808677,
      "Uz factor": 0.9858956105,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2230965924,
      "Direction stability factor": 0.090995023,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.119600916, 0.7145649502, 1.1409537931],
      direction: [-37.3542101996, -12.5088276695, 146.4801270228],
      "speed factor": 1.0151400452,
      "Uz factor": 0.9974504487,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1647463551,
      "Direction stability factor": 0.4893490633,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4054253956, 1.0778334332, 1.4229406209],
      direction: [-38.1608337216, -9.2622324572, 10.7168706021],
      "speed factor": 1.2628493931,
      "Uz factor": 0.9990211243,
      "Directional variance factor": 0.1766904482,
      "Velocity stability factor": 0.2176304121,
      "Direction stability factor": 0.8642285991,
      score: 0.5306945146,
    },
    "247.5": {
      velocity: [0.2272076781, 0.4336713828, 0.7466728583],
      direction: [-35.5060808439, 0.5328798728, 36.1988305903],
      "speed factor": 0.8045126752,
      "Uz factor": 0.9603588114,
      "Directional variance factor": 0.9526329002,
      "Velocity stability factor": 0.4959380792,
      "Direction stability factor": 0.8008196905,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4361305931, 0.7991580015, 1.0981155961],
      direction: [-48.1976831693, -15.7294844933, 34.252452645],
      "speed factor": 1.0882539494,
      "Uz factor": 0.9179513014,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5145401076,
      "Direction stability factor": 0.770971845,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.75418226, 1.0139527801, 1.2235701667],
      direction: [-3.7547169986, 6.5357055005, 26.306133047],
      "speed factor": 1.2876571005,
      "Uz factor": 0.9728832688,
      "Directional variance factor": 0.4190484,
      "Velocity stability factor": 0.6219387991,
      "Direction stability factor": 0.9164976388,
      score: 0.7184956191,
    },
    id: "IDN:700.0",
    "45": {
      velocity: [0.0726156089, 0.5818265087, 1.2487149567],
      direction: [-142.4327270171, -5.5764944983, 158.6484075238],
      "speed factor": 0.790342389,
      "Uz factor": 0.9981955206,
      "Directional variance factor": 0.5043116002,
      "Velocity stability factor": 0.0438684457,
      "Direction stability factor": 0.1636635152,
      score: 0.0,
    },
    "90": {
      velocity: [0.0545254944, 0.4291843319, 1.174945107],
      direction: [-179.7866358875, 30.6633693232, 178.3350815002],
      "speed factor": 0.6957203613,
      "Uz factor": 0.991366281,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1822533072,
      "Direction stability factor": 0.0052174517,
      score: 0.0,
    },
    "135": {
      velocity: [0.0733388748, 0.2897529076, 1.0487063277],
      direction: [-165.532246979, 30.597543387, 166.2777046077],
      "speed factor": 0.2452324675,
      "Uz factor": 0.8911799597,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2140383632,
      "Direction stability factor": 0.07830569,
      score: 0.0,
    },
    "180": {
      velocity: [0.1751893094, 0.8786089887, 1.3371813694],
      direction: [-51.2057529799, -29.3858638969, 1.5785672895],
      "speed factor": 1.7159179273,
      "Uz factor": 0.9961259646,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0844003612,
      "Direction stability factor": 0.8533768881,
      score: 0.0,
    },
    "225": {
      velocity: [0.4980871577, 0.8719351765, 1.1347488683],
      direction: [-15.4940608369, 0.2038710137, 17.1955619743],
      "speed factor": 1.1902869366,
      "Uz factor": 0.9911496648,
      "Directional variance factor": 0.9818781321,
      "Velocity stability factor": 0.4277807995,
      "Direction stability factor": 0.9091954922,
      score: 0.807012479,
    },
    "270": {
      velocity: [0.763328241, 1.0366140112, 1.2443583016],
      direction: [-19.1758649815, -4.0636300405, 10.9758958524],
      "speed factor": 0.9485352921,
      "Uz factor": 0.9325702071,
      "Directional variance factor": 0.6387884408,
      "Velocity stability factor": 0.6205619469,
      "Direction stability factor": 0.9162451088,
      score: 0.0,
    },
    "315": {
      velocity: [0.6971966983, 0.8719633139, 1.1050550325],
      direction: [-12.1099982922, 2.9277357938, 19.7193788998],
      "speed factor": 1.0743455788,
      "Uz factor": 0.9598861535,
      "Directional variance factor": 0.7397568183,
      "Velocity stability factor": 0.6691306492,
      "Direction stability factor": 0.9115850634,
      score: 0.8080143986,
    },
  },
  {
    position: [-55.19267986, 35.06998754, 46.87999908],
    "overall score": 0.2308593302,
    "0": {
      velocity: [0.2937904389, 1.0158987932, 1.4455142407],
      direction: [-28.1303026947, -11.1724621254, 41.4992822981],
      "speed factor": 1.0410433543,
      "Uz factor": 0.9954677962,
      "Directional variance factor": 0.0068922555,
      "Velocity stability factor": 0.199746837,
      "Direction stability factor": 0.8065844861,
      score: 0.4549520162,
    },
    "22.5": {
      velocity: [0.118158991, 0.6773652328, 1.2991636942],
      direction: [-175.0633271409, 2.5377208003, 131.480265227],
      "speed factor": 0.6014160971,
      "Uz factor": 0.9997658405,
      "Directional variance factor": 0.7744248177,
      "Velocity stability factor": 0.2362756144,
      "Direction stability factor": 0.1484900212,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0998112836, 0.4905747207, 0.9935569484],
      direction: [-113.179404477, 7.0140015986, 169.7361434493],
      "speed factor": 0.7599329465,
      "Uz factor": 0.9949441897,
      "Directional variance factor": 0.3765331912,
      "Velocity stability factor": 0.2013895156,
      "Direction stability factor": 0.214123478,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1753507772, 0.6824145869, 1.1585435462],
      direction: [-136.2687315694, -24.0456223587, -0.1727631909],
      "speed factor": 0.7676687362,
      "Uz factor": 0.9885729773,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.186094316,
      "Direction stability factor": 0.6219556434,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4632266627, 1.0384545413, 1.3237932439],
      direction: [-48.9436776421, -12.6149774269, 1.3570309881],
      "speed factor": 1.4752707778,
      "Uz factor": 0.9966598725,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2962360122,
      "Direction stability factor": 0.8602758094,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6017943662, 0.9516576781, 1.1422716861],
      direction: [-31.2326303221, -10.5765728987, 0.9781165143],
      "speed factor": 1.1150148847,
      "Uz factor": 0.9845790346,
      "Directional variance factor": 0.0598601868,
      "Velocity stability factor": 0.5844258567,
      "Direction stability factor": 0.9105257032,
      score: 0.6163343625,
    },
    "247.5": {
      velocity: [0.4770861022, 0.7654560491, 0.9327470309],
      direction: [-19.2288245257, 3.9447844323, 11.456950916],
      "speed factor": 1.4200132134,
      "Uz factor": 0.979357331,
      "Directional variance factor": 0.6493524949,
      "Velocity stability factor": 0.5578503975,
      "Direction stability factor": 0.9147617349,
      score: 0.7591815905,
    },
    "292.5": {
      velocity: [0.8535674979, 1.1581806923, 1.3502484946],
      direction: [2.0911824013, 18.8357788583, 29.0611416063],
      "speed factor": 1.5771533416,
      "Uz factor": 0.9397947139,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6357641002,
      "Direction stability factor": 0.9250834467,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4431441436, 0.861852234, 1.1242584668],
      direction: [-22.0761730488, -1.2793636295, 21.3399783694],
      "speed factor": 1.094498847,
      "Uz factor": 0.96835689,
      "Directional variance factor": 0.8862787885,
      "Velocity stability factor": 0.4514070446,
      "Direction stability factor": 0.8793995794,
      score: 0.774121248,
    },
    id: "IDN:462.0",
    "45": {
      velocity: [0.1186543546, 0.4808972501, 1.158446487],
      direction: [-165.826999061, 9.3435612776, 179.9062523646],
      "speed factor": 0.6532419474,
      "Uz factor": 0.9997030169,
      "Directional variance factor": 0.1694612198,
      "Velocity stability factor": 0.1546818986,
      "Direction stability factor": 0.0396298572,
      score: 0.0,
    },
    "90": {
      velocity: [0.0814210879, 0.6816121454, 1.1662494738],
      direction: [-160.9732619587, -3.6649030011, 50.5411640302],
      "speed factor": 1.1049132338,
      "Uz factor": 0.9845469096,
      "Directional variance factor": 0.6742308443,
      "Velocity stability factor": 0.208229832,
      "Direction stability factor": 0.4124599278,
      score: 0.426845133,
    },
    "135": {
      velocity: [0.1058721745, 0.5663417465, 0.973548394],
      direction: [-110.3498894989, -36.1760336133, 103.0961893476],
      "speed factor": 0.4793235211,
      "Uz factor": 0.9983523417,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3008171231,
      "Direction stability factor": 0.4070942254,
      score: 0.0,
    },
    "180": {
      velocity: [0.5942261886, 0.8906745924, 1.1779861922],
      direction: [-48.8746087944, -13.496434159, 11.8835787825],
      "speed factor": 1.7394819768,
      "Uz factor": 0.9962970098,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5400222886,
      "Direction stability factor": 0.8312272567,
      score: 0.0,
    },
    "225": {
      velocity: [0.1565470664, 0.5351447045, 0.8635913243],
      direction: [-43.5736298415, -0.1308334304, 41.9388155677],
      "speed factor": 0.7305310855,
      "Uz factor": 0.9815994627,
      "Directional variance factor": 0.9883703617,
      "Velocity stability factor": 0.3645223306,
      "Direction stability factor": 0.7624654294,
      score: 0.0,
    },
    "270": {
      velocity: [0.5858267489, 0.9074288064, 1.2285608139],
      direction: [-2.2772314144, 25.3466955902, 55.3417629383],
      "speed factor": 0.8303266583,
      "Uz factor": 0.9465457271,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4930093101,
      "Direction stability factor": 0.8399472379,
      score: 0.0,
    },
    "315": {
      velocity: [0.7740604674, 1.1458300233, 1.3310561308],
      direction: [-2.8034000412, 8.0263403556, 30.5745179364],
      "speed factor": 1.4117766195,
      "Uz factor": 0.9680874069,
      "Directional variance factor": 0.2865475239,
      "Velocity stability factor": 0.5481450835,
      "Direction stability factor": 0.9072835612,
      score: 0.6623149324,
    },
  },
  {
    position: [-52.57487212, 86.24083772, 47.87999908],
    "overall score": 0.2299536766,
    "0": {
      velocity: [0.6444981007, 1.2369569285, 1.5063264483],
      direction: [-14.6724732093, -1.2274053755, 37.0871053347],
      "speed factor": 1.2675729103,
      "Uz factor": 0.995777576,
      "Directional variance factor": 0.8908973,
      "Velocity stability factor": 0.4011751255,
      "Direction stability factor": 0.8562233929,
      score: 0.7511298028,
    },
    "22.5": {
      velocity: [0.1085983125, 0.6981312947, 1.2841538497],
      direction: [-105.1400318157, 14.077048229, 119.4023030985],
      "speed factor": 0.6198537779,
      "Uz factor": 0.997892367,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2397994453,
      "Direction stability factor": 0.3762712919,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0889845952, 0.5903716155, 1.0616458812],
      direction: [-29.899706639, 3.6593252767, 87.7408973367],
      "speed factor": 0.9145249896,
      "Uz factor": 0.9970962681,
      "Directional variance factor": 0.6747266421,
      "Velocity stability factor": 0.1308741051,
      "Direction stability factor": 0.6732205445,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0798689707, 0.4906262104, 1.0488821504],
      direction: [-168.7707873735, 7.3385736595, 139.626914667],
      "speed factor": 0.5519202111,
      "Uz factor": 0.9911673787,
      "Directional variance factor": 0.3476823414,
      "Velocity stability factor": 0.1978324498,
      "Direction stability factor": 0.1433397166,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0799921885, 0.7906710463, 1.2553267043],
      direction: [-59.1028681518, -15.4080458611, 154.6991298358],
      "speed factor": 1.1232594622,
      "Uz factor": 0.9975778131,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0388214884,
      "Direction stability factor": 0.4061055611,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5049042069, 1.067114523, 1.3700252619],
      direction: [-31.3193232954, -9.7066447877, 11.2566694255],
      "speed factor": 1.2502905238,
      "Uz factor": 0.9994222061,
      "Directional variance factor": 0.13718713,
      "Velocity stability factor": 0.3348066089,
      "Direction stability factor": 0.8817333536,
      score: 0.5588651115,
    },
    "247.5": {
      velocity: [0.2037678135, 0.4296478342, 0.7249142778],
      direction: [-38.1097022943, 1.9368411277, 36.94981206],
      "speed factor": 0.7970485078,
      "Uz factor": 0.9714299607,
      "Directional variance factor": 0.8278363442,
      "Velocity stability factor": 0.4943066488,
      "Direction stability factor": 0.791501349,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4356596224, 0.8371416552, 1.135342441],
      direction: [-42.7148304472, -11.3217324705, 31.9947055741],
      "speed factor": 1.1399782156,
      "Uz factor": 0.9192439041,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4868948022,
      "Direction stability factor": 0.7924735111,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7378349569, 1.0136231154, 1.2399488456],
      direction: [-6.9745221647, 4.5172624697, 19.5975334178],
      "speed factor": 1.2872384468,
      "Uz factor": 0.9786770716,
      "Directional variance factor": 0.5984655583,
      "Velocity stability factor": 0.5955801651,
      "Direction stability factor": 0.9261887345,
      score: 0.7616057981,
    },
    id: "IDN:701.0",
    "45": {
      velocity: [0.1038005558, 0.5506339131, 1.1621324877],
      direction: [-127.7089572977, 0.4517284943, 74.2877537571],
      "speed factor": 0.747970943,
      "Uz factor": 0.99757534,
      "Directional variance factor": 0.9598463561,
      "Velocity stability factor": 0.1396096283,
      "Direction stability factor": 0.4388980248,
      score: 0.0,
    },
    "90": {
      velocity: [0.0715273764, 0.455118942, 0.9916898099],
      direction: [-168.0643110018, 18.8718424704, 161.7452161324],
      "speed factor": 0.7377611232,
      "Uz factor": 0.9752339116,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3284125176,
      "Direction stability factor": 0.0838624246,
      score: 0.0,
    },
    "135": {
      velocity: [0.0948241664, 0.472556699, 1.2447163758],
      direction: [-166.2809643399, -19.300270825, 118.6791024301],
      "speed factor": 0.3999485157,
      "Uz factor": 0.9820206027,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0734044279,
      "Direction stability factor": 0.208444259,
      score: 0.0,
    },
    "180": {
      velocity: [0.4086419279, 0.9094756217, 1.3059767674],
      direction: [-64.747147855, -28.2865308126, 15.9039800572],
      "speed factor": 1.7762002708,
      "Uz factor": 0.9969431505,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.292938839,
      "Direction stability factor": 0.7759690891,
      score: 0.0,
    },
    "225": {
      velocity: [0.541093549, 0.8429834824, 1.1074857808],
      direction: [-14.7037627603, 1.646469586, 19.1039303564],
      "speed factor": 1.1507647058,
      "Uz factor": 0.9913529066,
      "Directional variance factor": 0.8536471479,
      "Velocity stability factor": 0.4909376445,
      "Direction stability factor": 0.9060897413,
      score: 0.7891910688,
    },
    "270": {
      velocity: [0.7729105887, 1.0294383532, 1.2334360934],
      direction: [-17.7854274746, 0.1436654163, 15.7978979862],
      "speed factor": 0.9419693333,
      "Uz factor": 0.9224526084,
      "Directional variance factor": 0.9872297408,
      "Velocity stability factor": 0.6367360062,
      "Direction stability factor": 0.9067129848,
      score: 0.0,
    },
    "315": {
      velocity: [0.6615892194, 0.888816214, 1.1497991815],
      direction: [-11.1703810931, 2.0640484995, 15.2189438583],
      "speed factor": 1.095110029,
      "Uz factor": 0.960962663,
      "Directional variance factor": 0.8165290223,
      "Velocity stability factor": 0.6039465185,
      "Direction stability factor": 0.9266963196,
      score: 0.818467045,
    },
  },
  {
    position: [-50.56305378, 32.38881079, 46.87999908],
    "overall score": 0.2233308715,
    "0": {
      velocity: [0.1908403138, 0.8810149744, 1.3074807734],
      direction: [-32.7520463644, -11.1938846511, 39.205314352],
      "speed factor": 0.9028210194,
      "Uz factor": 0.9985429142,
      "Directional variance factor": 0.004988031,
      "Velocity stability factor": 0.2241238236,
      "Direction stability factor": 0.8001184425,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2513423837, 0.6351253299, 1.3454517751],
      direction: [-87.2982048398, -10.6596125507, 178.9435654757],
      "speed factor": 0.5639123159,
      "Uz factor": 0.9917750924,
      "Directional variance factor": 0.0524788844,
      "Velocity stability factor": 0.2924685055,
      "Direction stability factor": 0.2604395269,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4369294108, 0.7116401456, 1.0148672011],
      direction: [-27.4100239749, -4.8424046384, 24.622711287],
      "speed factor": 1.1023780272,
      "Uz factor": 0.9843460338,
      "Directional variance factor": 0.5695640321,
      "Velocity stability factor": 0.4835810714,
      "Direction stability factor": 0.8554646243,
      score: 0.691018588,
    },
    "112.5": {
      velocity: [0.3954148371, 0.8323285356, 1.2203004215],
      direction: [-55.8358213635, -30.6568270043, 5.1588004264],
      "speed factor": 0.9363114554,
      "Uz factor": 0.9747760345,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.317144016,
      "Direction stability factor": 0.830570495,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4838492223, 0.9060927486, 1.1424275365],
      direction: [-45.4398725066, -9.3381165995, 9.6367821033],
      "speed factor": 1.287232229,
      "Uz factor": 0.9761309047,
      "Directional variance factor": 0.1699451912,
      "Velocity stability factor": 0.461420289,
      "Direction stability factor": 0.8470092928,
      score: 0.5813460164,
    },
    "202.5": {
      velocity: [0.4923384667, 0.9027371008, 1.1247214935],
      direction: [-19.1622633156, -0.1157358318, 11.3033928039],
      "speed factor": 1.0576968248,
      "Uz factor": 0.9682589208,
      "Directional variance factor": 0.9897123705,
      "Velocity stability factor": 0.5137593662,
      "Direction stability factor": 0.9153731774,
      score: 0.8335545229,
    },
    "247.5": {
      velocity: [0.5436527697, 0.8255998615, 1.0411807017],
      direction: [-15.2383996187, 4.4618389706, 12.8266506614],
      "speed factor": 1.5315872331,
      "Uz factor": 0.9976417507,
      "Directional variance factor": 0.6033920915,
      "Velocity stability factor": 0.5172248409,
      "Direction stability factor": 0.922041527,
      score: 0.7411749966,
    },
    "292.5": {
      velocity: [0.1917487503, 0.5905345751, 1.2106748409],
      direction: [-5.5089529992, 17.9133257778, 108.924805534],
      "speed factor": 0.8041608574,
      "Uz factor": 0.9737069552,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2527810326,
      "Direction stability factor": 0.6821284485,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0817005057, 0.5163379811, 1.0753584879],
      direction: [-75.230219575, -1.1691381417, 95.508068513],
      "speed factor": 0.6557171898,
      "Uz factor": 0.9963419651,
      "Directional variance factor": 0.8960766096,
      "Velocity stability factor": 0.1996736075,
      "Direction stability factor": 0.5257269775,
      score: 0.0,
    },
    id: "IDN:487.0",
    "45": {
      velocity: [0.1200170916, 0.5146984752, 1.0633180198],
      direction: [-49.3941884817, 9.4173118466, 75.5031923526],
      "speed factor": 0.6991569075,
      "Uz factor": 0.978732473,
      "Directional variance factor": 0.1629056136,
      "Velocity stability factor": 0.2331261944,
      "Direction stability factor": 0.653062831,
      score: 0.0,
    },
    "90": {
      velocity: [0.6690371349, 1.0455864998, 1.2924550436],
      direction: [-35.7521626241, -14.9717074752, 1.3028641589],
      "speed factor": 1.6949263133,
      "Uz factor": 0.9711660597,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.544993744,
      "Direction stability factor": 0.89706937,
      score: 0.0,
    },
    "135": {
      velocity: [0.2820248762, 0.5964988642, 0.9385934173],
      direction: [-89.8591116137, -40.9008109148, 17.9781936324],
      "speed factor": 0.5048470074,
      "Uz factor": 0.9899594509,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4709299723,
      "Direction stability factor": 0.7004519299,
      score: 0.0,
    },
    "180": {
      velocity: [0.5347353889, 0.798481171, 1.03223412],
      direction: [-34.1673174425, -4.5853996716, 19.0410590308],
      "speed factor": 1.5594287942,
      "Uz factor": 0.9804265176,
      "Directional variance factor": 0.5924089181,
      "Velocity stability factor": 0.6079924518,
      "Direction stability factor": 0.8521989542,
      score: 0.7261998196,
    },
    "225": {
      velocity: [0.1727250327, 0.5500508423, 0.9714711433],
      direction: [-34.9892089597, 5.0193204932, 42.0809366667],
      "speed factor": 0.7508795948,
      "Uz factor": 0.9896416681,
      "Directional variance factor": 0.5538381784,
      "Velocity stability factor": 0.2821024835,
      "Direction stability factor": 0.7859162621,
      score: 0.0,
    },
    "270": {
      velocity: [0.2686358244, 0.8196717241, 1.3176997079],
      direction: [-3.334183256, 25.4842184268, 55.8962767049],
      "speed factor": 0.7500260944,
      "Uz factor": 0.9947421485,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1724950472,
      "Direction stability factor": 0.8354709446,
      score: 0.0,
    },
    "315": {
      velocity: [0.2303124899, 0.6678263402, 1.2716157094],
      direction: [-8.6945517965, 12.5589915157, 79.0925359842],
      "speed factor": 0.8228285119,
      "Uz factor": 0.9888935843,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1552573741,
      "Direction stability factor": 0.7561469784,
      score: 0.0,
    },
  },
  {
    position: [-50.56305378, 32.38881079, 46.87999908],
    "overall score": 0.2233308715,
    "0": {
      velocity: [0.1908403138, 0.8810149744, 1.3074807734],
      direction: [-32.7520463644, -11.1938846511, 39.205314352],
      "speed factor": 0.9028210194,
      "Uz factor": 0.9985429142,
      "Directional variance factor": 0.004988031,
      "Velocity stability factor": 0.2241238236,
      "Direction stability factor": 0.8001184425,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2513423837, 0.6351253299, 1.3454517751],
      direction: [-87.2982048398, -10.6596125507, 178.9435654757],
      "speed factor": 0.5639123159,
      "Uz factor": 0.9917750924,
      "Directional variance factor": 0.0524788844,
      "Velocity stability factor": 0.2924685055,
      "Direction stability factor": 0.2604395269,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4369294108, 0.7116401456, 1.0148672011],
      direction: [-27.4100239749, -4.8424046384, 24.622711287],
      "speed factor": 1.1023780272,
      "Uz factor": 0.9843460338,
      "Directional variance factor": 0.5695640321,
      "Velocity stability factor": 0.4835810714,
      "Direction stability factor": 0.8554646243,
      score: 0.691018588,
    },
    "112.5": {
      velocity: [0.3954148371, 0.8323285356, 1.2203004215],
      direction: [-55.8358213635, -30.6568270043, 5.1588004264],
      "speed factor": 0.9363114554,
      "Uz factor": 0.9747760345,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.317144016,
      "Direction stability factor": 0.830570495,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4838492223, 0.9060927486, 1.1424275365],
      direction: [-45.4398725066, -9.3381165995, 9.6367821033],
      "speed factor": 1.287232229,
      "Uz factor": 0.9761309047,
      "Directional variance factor": 0.1699451912,
      "Velocity stability factor": 0.461420289,
      "Direction stability factor": 0.8470092928,
      score: 0.5813460164,
    },
    "202.5": {
      velocity: [0.4923384667, 0.9027371008, 1.1247214935],
      direction: [-19.1622633156, -0.1157358318, 11.3033928039],
      "speed factor": 1.0576968248,
      "Uz factor": 0.9682589208,
      "Directional variance factor": 0.9897123705,
      "Velocity stability factor": 0.5137593662,
      "Direction stability factor": 0.9153731774,
      score: 0.8335545229,
    },
    "247.5": {
      velocity: [0.5436527697, 0.8255998615, 1.0411807017],
      direction: [-15.2383996187, 4.4618389706, 12.8266506614],
      "speed factor": 1.5315872331,
      "Uz factor": 0.9976417507,
      "Directional variance factor": 0.6033920915,
      "Velocity stability factor": 0.5172248409,
      "Direction stability factor": 0.922041527,
      score: 0.7411749966,
    },
    "292.5": {
      velocity: [0.1917487503, 0.5905345751, 1.2106748409],
      direction: [-5.5089529992, 17.9133257778, 108.924805534],
      "speed factor": 0.8041608574,
      "Uz factor": 0.9737069552,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2527810326,
      "Direction stability factor": 0.6821284485,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0817005057, 0.5163379811, 1.0753584879],
      direction: [-75.230219575, -1.1691381417, 95.508068513],
      "speed factor": 0.6557171898,
      "Uz factor": 0.9963419651,
      "Directional variance factor": 0.8960766096,
      "Velocity stability factor": 0.1996736075,
      "Direction stability factor": 0.5257269775,
      score: 0.0,
    },
    id: "IDN:486.0",
    "45": {
      velocity: [0.1200170916, 0.5146984752, 1.0633180198],
      direction: [-49.3941884817, 9.4173118466, 75.5031923526],
      "speed factor": 0.6991569075,
      "Uz factor": 0.978732473,
      "Directional variance factor": 0.1629056136,
      "Velocity stability factor": 0.2331261944,
      "Direction stability factor": 0.653062831,
      score: 0.0,
    },
    "90": {
      velocity: [0.6690371349, 1.0455864998, 1.2924550436],
      direction: [-35.7521626241, -14.9717074752, 1.3028641589],
      "speed factor": 1.6949263133,
      "Uz factor": 0.9711660597,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.544993744,
      "Direction stability factor": 0.89706937,
      score: 0.0,
    },
    "135": {
      velocity: [0.2820248762, 0.5964988642, 0.9385934173],
      direction: [-89.8591116137, -40.9008109148, 17.9781936324],
      "speed factor": 0.5048470074,
      "Uz factor": 0.9899594509,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4709299723,
      "Direction stability factor": 0.7004519299,
      score: 0.0,
    },
    "180": {
      velocity: [0.5347353889, 0.798481171, 1.03223412],
      direction: [-34.1673174425, -4.5853996716, 19.0410590308],
      "speed factor": 1.5594287942,
      "Uz factor": 0.9804265176,
      "Directional variance factor": 0.5924089181,
      "Velocity stability factor": 0.6079924518,
      "Direction stability factor": 0.8521989542,
      score: 0.7261998196,
    },
    "225": {
      velocity: [0.1727250327, 0.5500508423, 0.9714711433],
      direction: [-34.9892089597, 5.0193204932, 42.0809366667],
      "speed factor": 0.7508795948,
      "Uz factor": 0.9896416681,
      "Directional variance factor": 0.5538381784,
      "Velocity stability factor": 0.2821024835,
      "Direction stability factor": 0.7859162621,
      score: 0.0,
    },
    "270": {
      velocity: [0.2686358244, 0.8196717241, 1.3176997079],
      direction: [-3.334183256, 25.4842184268, 55.8962767049],
      "speed factor": 0.7500260944,
      "Uz factor": 0.9947421485,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1724950472,
      "Direction stability factor": 0.8354709446,
      score: 0.0,
    },
    "315": {
      velocity: [0.2303124899, 0.6678263402, 1.2716157094],
      direction: [-8.6945517965, 12.5589915157, 79.0925359842],
      "speed factor": 0.8228285119,
      "Uz factor": 0.9888935843,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1552573741,
      "Direction stability factor": 0.7561469784,
      score: 0.0,
    },
  },
  {
    position: [-50.56177969, 32.75380857, 46.87999908],
    "overall score": 0.2215882649,
    "0": {
      velocity: [0.1788669785, 0.8804234573, 1.3004874687],
      direction: [-33.5582647487, -11.2842414363, 63.8686485633],
      "speed factor": 0.9022148617,
      "Uz factor": 0.9985072931,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.220663545,
      "Direction stability factor": 0.7293696852,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2456362633, 0.6309088719, 1.2998878007],
      direction: [-157.4019695629, -10.0907895789, 79.1787939394],
      "speed factor": 0.5601686255,
      "Uz factor": 0.9899409362,
      "Directional variance factor": 0.1030409263,
      "Velocity stability factor": 0.3182435213,
      "Direction stability factor": 0.3428312125,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4117968924, 0.704652579, 1.014776144],
      direction: [-27.7537000781, -4.2272592264, 25.1660383972],
      "speed factor": 1.0915538206,
      "Uz factor": 0.9824789641,
      "Directional variance factor": 0.6242436243,
      "Velocity stability factor": 0.4612051603,
      "Direction stability factor": 0.8530007265,
      score: 0.6978625594,
    },
    "112.5": {
      velocity: [0.4102115127, 0.8372726043, 1.2296783427],
      direction: [-55.0107037018, -30.5500947809, 4.0677856531],
      "speed factor": 0.9418731873,
      "Uz factor": 0.9709625594,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.321629764,
      "Direction stability factor": 0.8358930851,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.484127882, 0.9115865189, 1.1511730089],
      direction: [-47.2478872001, -9.7141360663, 8.2748554829],
      "speed factor": 1.2950369028,
      "Uz factor": 0.9757093142,
      "Directional variance factor": 0.1365212385,
      "Velocity stability factor": 0.454496202,
      "Direction stability factor": 0.8457701592,
      score: 0.5706394397,
    },
    "202.5": {
      velocity: [0.4958787473, 0.9074395911, 1.1313004188],
      direction: [-19.3120905781, -0.639816014, 10.5043706873],
      "speed factor": 1.0632065231,
      "Uz factor": 0.9674233379,
      "Directional variance factor": 0.9431274654,
      "Velocity stability factor": 0.5114229459,
      "Direction stability factor": 0.9171764965,
      score: 0.8222258511,
    },
    "247.5": {
      velocity: [0.5388387093, 0.8272398449, 1.043693996],
      direction: [-16.2161675994, 4.4851541877, 13.0358893108],
      "speed factor": 1.5346295999,
      "Uz factor": 0.9977851154,
      "Directional variance factor": 0.6013196278,
      "Velocity stability factor": 0.510114758,
      "Direction stability factor": 0.9187442864,
      score: 0.7372307396,
    },
    "292.5": {
      velocity: [0.191626751, 0.5777018834, 1.2237121353],
      direction: [-5.4416458301, 17.6701669895, 101.8763017947],
      "speed factor": 0.7866859307,
      "Uz factor": 0.9726046628,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2431307999,
      "Direction stability factor": 0.7018945899,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0702081333, 0.5176150073, 1.0596211644],
      direction: [-71.4984978339, -0.754742806, 98.060480577],
      "speed factor": 0.6573389339,
      "Uz factor": 0.9964398783,
      "Directional variance factor": 0.9329117506,
      "Velocity stability factor": 0.2030926374,
      "Direction stability factor": 0.5290028377,
      score: 0.0,
    },
    id: "IDN:485.0",
    "45": {
      velocity: [0.1195586007, 0.511170925, 1.0512310649],
      direction: [-39.8838912948, 10.3374296421, 73.8365928647],
      "speed factor": 0.6943651485,
      "Uz factor": 0.9757085147,
      "Directional variance factor": 0.0811173652,
      "Velocity stability factor": 0.2425797676,
      "Direction stability factor": 0.6841097662,
      score: 0.0,
    },
    "90": {
      velocity: [0.6594468112, 1.0434649686, 1.2967544255],
      direction: [-35.9568206809, -14.3957943446, 2.3014379152],
      "speed factor": 1.6914872491,
      "Uz factor": 0.9678199481,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5348562377,
      "Direction stability factor": 0.8937270595,
      score: 0.0,
    },
    "135": {
      velocity: [0.2640342541, 0.5989412553, 0.9404741939],
      direction: [-92.1160068077, -40.6634097348, 17.9046627067],
      "speed factor": 0.506914126,
      "Uz factor": 0.987667744,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4549173845,
      "Direction stability factor": 0.6943870291,
      score: 0.0,
    },
    "180": {
      velocity: [0.5519985176, 0.8056221925, 1.0464335237],
      direction: [-36.287681003, -4.9402481464, 17.9748985069],
      "speed factor": 1.5733751651,
      "Uz factor": 0.9803692435,
      "Directional variance factor": 0.5608668314,
      "Velocity stability factor": 0.610406535,
      "Direction stability factor": 0.8492706125,
      score: 0.7174536478,
    },
    "225": {
      velocity: [0.1741190765, 0.5524365852, 0.9729661457],
      direction: [-34.4929907522, 4.8049662416, 42.2020853985],
      "speed factor": 0.7541363949,
      "Uz factor": 0.990052425,
      "Directional variance factor": 0.5728918896,
      "Velocity stability factor": 0.2820117439,
      "Direction stability factor": 0.7869581218,
      score: 0.0,
    },
    "270": {
      velocity: [0.24191892, 0.8221396597, 1.2632230649],
      direction: [-3.0674345956, 25.5320578309, 62.0758669564],
      "speed factor": 0.7522843352,
      "Uz factor": 0.9938432437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.194392018,
      "Direction stability factor": 0.8190463846,
      score: 0.0,
    },
    "315": {
      velocity: [0.207051605, 0.6559608374, 1.2795427844],
      direction: [-8.2957401982, 11.754739239, 80.5944150205],
      "speed factor": 0.8082090317,
      "Uz factor": 0.9866848671,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1299565791,
      "Direction stability factor": 0.7530829022,
      score: 0.0,
    },
  },
  {
    position: [-55.0853934, 38.06806853, 46.87999908],
    "overall score": 0.2167884474,
    "0": {
      velocity: [0.1453669002, 1.0288377859, 1.4209813043],
      direction: [-25.87676399, -11.0163292658, 74.9381132693],
      "speed factor": 1.0543026006,
      "Uz factor": 0.9960579083,
      "Directional variance factor": 0.0207707319,
      "Velocity stability factor": 0.1136638316,
      "Direction stability factor": 0.7199586743,
      score: 0.393587978,
    },
    "22.5": {
      velocity: [0.0864249252, 0.6521154217, 1.2002544342],
      direction: [-82.6616620157, -0.4253124788, 157.0629908165],
      "speed factor": 0.5789974047,
      "Uz factor": 0.9999987247,
      "Directional variance factor": 0.9621944463,
      "Velocity stability factor": 0.279716029,
      "Direction stability factor": 0.3340981866,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0351951252, 0.4549225341, 0.9726873227],
      direction: [-179.8002614093, 12.831271518, 166.0462058291],
      "speed factor": 0.7047053328,
      "Uz factor": 0.9976080179,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1622996033,
      "Direction stability factor": 0.0393153688,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0348614482, 0.5922494593, 0.9788612083],
      direction: [-150.3607840466, -15.749574746, 31.3371111884],
      "speed factor": 0.6662392666,
      "Uz factor": 0.9908749613,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2185390347,
      "Direction stability factor": 0.4952836243,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.21034673, 0.9825856341, 1.3231059816],
      direction: [-55.1772246642, -16.8824642651, 2.8722119877],
      "speed factor": 1.3959011349,
      "Uz factor": 0.9997113906,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0899950041,
      "Direction stability factor": 0.8387515649,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3498139284, 0.9309239839, 1.1955664747],
      direction: [-33.2656232873, -9.9526081301, 2.5229119424],
      "speed factor": 1.0907221394,
      "Uz factor": 0.9912113585,
      "Directional variance factor": 0.1153237218,
      "Velocity stability factor": 0.3496990958,
      "Direction stability factor": 0.9005874021,
      score: 0.5665494055,
    },
    "247.5": {
      velocity: [0.4757035191, 0.7691005701, 0.9436234958],
      direction: [-19.3575633067, 1.8277722642, 10.6428974752],
      "speed factor": 1.426774239,
      "Uz factor": 0.9751375787,
      "Directional variance factor": 0.8375313543,
      "Velocity stability factor": 0.5459548566,
      "Direction stability factor": 0.9166653867,
      score: 0.8042042461,
    },
    "292.5": {
      velocity: [0.7268184083, 1.149410888, 1.3327086513],
      direction: [-1.2773107689, 15.6987450126, 27.65553254],
      "speed factor": 1.5652110547,
      "Uz factor": 0.9223065388,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5556766228,
      "Direction stability factor": 0.9196309908,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.5079014072, 0.8737209377, 1.1292150502],
      direction: [-23.8767601372, -2.3203937745, 14.8199085594],
      "speed factor": 1.109571364,
      "Uz factor": 0.9636652904,
      "Directional variance factor": 0.7937427756,
      "Velocity stability factor": 0.4995725738,
      "Direction stability factor": 0.8925092536,
      score: 0.7695834642,
    },
    id: "IDN:463.0",
    "45": {
      velocity: [0.0276449045, 0.485044887, 1.1316715218],
      direction: [-174.2103318001, 10.8430131638, 172.7561054045],
      "speed factor": 0.6588760208,
      "Uz factor": 0.9999867984,
      "Directional variance factor": 0.0361766077,
      "Velocity stability factor": 0.102461295,
      "Direction stability factor": 0.0362043411,
      score: 0.0,
    },
    "90": {
      velocity: [0.036213309, 0.6498310922, 1.0798049493],
      direction: [-152.4711721892, 5.9257766834, 170.2889124445],
      "speed factor": 1.0533952166,
      "Uz factor": 0.9843117616,
      "Directional variance factor": 0.4732642948,
      "Velocity stability factor": 0.2383267813,
      "Direction stability factor": 0.1034442094,
      score: 0.2296198737,
    },
    "135": {
      velocity: [0.1068793966, 0.5406408204, 1.0032334402],
      direction: [-160.4625529543, -32.2671607728, 140.519570433],
      "speed factor": 0.457571534,
      "Uz factor": 0.9989778321,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2777082225,
      "Direction stability factor": 0.1639385461,
      score: 0.0,
    },
    "180": {
      velocity: [0.3280714591, 0.8524948629, 1.1424509705],
      direction: [-50.297099764, -14.271200464, 8.3363867528],
      "speed factor": 1.6649172009,
      "Uz factor": 0.999953583,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3583040606,
      "Direction stability factor": 0.8371292041,
      score: 0.0,
    },
    "225": {
      velocity: [0.1063639358, 0.5418186779, 0.8874828393],
      direction: [-47.8122134508, -0.5300864358, 55.6523687393],
      "speed factor": 0.7396417895,
      "Uz factor": 0.982975209,
      "Directional variance factor": 0.9528812057,
      "Velocity stability factor": 0.2979454755,
      "Direction stability factor": 0.7125983828,
      score: 0.0,
    },
    "270": {
      velocity: [0.6439875152, 0.9357237657, 1.2294975954],
      direction: [-3.3688525703, 23.0841434731, 48.2797226791],
      "speed factor": 0.8562174598,
      "Uz factor": 0.940275004,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5381477726,
      "Direction stability factor": 0.8565317354,
      score: 0.0,
    },
    "315": {
      velocity: [0.6634820023, 1.119121023, 1.3038710827],
      direction: [-5.0833938747, 5.5581227073, 24.8249072531],
      "speed factor": 1.3788684731,
      "Uz factor": 0.9580122885,
      "Directional variance factor": 0.5059446482,
      "Velocity stability factor": 0.480493344,
      "Direction stability factor": 0.9169213858,
      score: 0.7050701909,
    },
  },
  {
    position: [-52.79462529, 83.24889712, 46.87999908],
    "overall score": 0.2142971742,
    "0": {
      velocity: [0.2824050849, 1.086509273, 1.5153810983],
      direction: [-17.5138283942, -3.3016536564, 58.1563231137],
      "speed factor": 1.1134015175,
      "Uz factor": 0.9986475379,
      "Directional variance factor": 0.706519675,
      "Velocity stability factor": 0.1432902984,
      "Direction stability factor": 0.7898051347,
      score: 0.6073550607,
    },
    "22.5": {
      velocity: [0.1267053408, 0.6391797303, 1.2147481572],
      direction: [-81.6120786232, 22.7915386212, 120.7134015187],
      "speed factor": 0.5675121193,
      "Uz factor": 0.9869983681,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2963915984,
      "Direction stability factor": 0.4379847774,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0463161193, 0.4388171101, 0.9635273378],
      direction: [-64.6583228432, 5.6263472486, 147.6371596729],
      "speed factor": 0.6797569573,
      "Uz factor": 0.9990510949,
      "Directional variance factor": 0.4998802446,
      "Velocity stability factor": 0.1804217639,
      "Direction stability factor": 0.4102903263,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0385748636, 0.5498516063, 1.1526385161],
      direction: [-179.7846933993, -0.1830069547, 94.1087134641],
      "speed factor": 0.618544644,
      "Uz factor": 0.9965140521,
      "Directional variance factor": 0.9837327151,
      "Velocity stability factor": 0.0777569082,
      "Direction stability factor": 0.2391849809,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1668525855, 0.8295750579, 1.2297023024],
      direction: [-143.0768760399, -17.2154402755, 26.3688681595],
      "speed factor": 1.178528084,
      "Uz factor": 0.9956101935,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1308105946,
      "Direction stability factor": 0.5293173772,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5198494584, 1.0306647615, 1.3950095064],
      direction: [-40.6599336186, -10.8801322904, 12.5610327185],
      "speed factor": 1.2075839628,
      "Uz factor": 0.9999887316,
      "Directional variance factor": 0.0328771297,
      "Velocity stability factor": 0.3270876061,
      "Direction stability factor": 0.8521639824,
      score: 0.5160731752,
    },
    "247.5": {
      velocity: [0.2107146419, 0.4307155372, 0.6957809666],
      direction: [-24.3435352427, 0.7455558187, 44.3900110096],
      "speed factor": 0.7990292255,
      "Uz factor": 0.9735804002,
      "Directional variance factor": 0.9337283717,
      "Velocity stability factor": 0.5293169347,
      "Direction stability factor": 0.8090734826,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4486463409, 0.8815303477, 1.1738507277],
      direction: [-49.0436721985, -8.6567391755, 33.4041998633],
      "speed factor": 1.2004245476,
      "Uz factor": 0.9001552053,
      "Directional variance factor": 0.2305120733,
      "Velocity stability factor": 0.4681788227,
      "Direction stability factor": 0.7709781332,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6450872853, 1.001001903, 1.2359325795],
      direction: [-7.1969134213, 4.156296309, 27.0171114269],
      "speed factor": 1.2712102904,
      "Uz factor": 0.9782867455,
      "Directional variance factor": 0.6305514392,
      "Velocity stability factor": 0.5241128323,
      "Direction stability factor": 0.9049610421,
      score: 0.7411465889,
    },
    id: "IDN:436.0",
    "45": {
      velocity: [0.0837637496, 0.482863164, 0.9127578737],
      direction: [-154.0700641571, 6.176560548, 71.4154909473],
      "speed factor": 0.6559124084,
      "Uz factor": 0.9936541378,
      "Directional variance factor": 0.4509723957,
      "Velocity stability factor": 0.326054009,
      "Direction stability factor": 0.3736512358,
      score: 0.0,
    },
    "90": {
      velocity: [0.0526193866, 0.435001815, 1.229898076],
      direction: [-121.8364284523, 9.8694751936, 103.4569684398],
      "speed factor": 0.7051506716,
      "Uz factor": 0.9786412325,
      "Directional variance factor": 0.1227133161,
      "Velocity stability factor": 0.1407542817,
      "Direction stability factor": 0.3741850086,
      score: 0.0,
    },
    "135": {
      velocity: [0.1039674253, 0.7310010562, 1.2970747594],
      direction: [-137.9003327902, -21.0159624278, 156.5034322246],
      "speed factor": 0.6186829813,
      "Uz factor": 0.9955759648,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0385812132,
      "Direction stability factor": 0.1822117638,
      score: 0.0,
    },
    "180": {
      velocity: [0.3517167044, 0.9016006084, 1.3065277135],
      direction: [-56.9330422328, -29.9044035501, 9.1133585652],
      "speed factor": 1.7608204184,
      "Uz factor": 0.9992575775,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2476500958,
      "Direction stability factor": 0.8165377756,
      score: 0.0,
    },
    "225": {
      velocity: [0.5414049854, 0.7962918544, 1.0460515977],
      direction: [-15.3070452153, 3.1496893176, 23.1740855942],
      "speed factor": 1.0870255239,
      "Uz factor": 0.9877118375,
      "Directional variance factor": 0.7200276162,
      "Velocity stability factor": 0.5464334101,
      "Direction stability factor": 0.89310797,
      score: 0.7631692416,
    },
    "270": {
      velocity: [0.7901199776, 1.0231966225, 1.2382655875],
      direction: [-15.1403271242, 5.3295528504, 22.3636347077],
      "speed factor": 0.9362579482,
      "Uz factor": 0.904487416,
      "Directional variance factor": 0.5262619688,
      "Velocity stability factor": 0.6465013069,
      "Direction stability factor": 0.8958223282,
      score: 0.0,
    },
    "315": {
      velocity: [0.6546617722, 0.8962599178, 1.1739637367],
      direction: [-9.8718469964, 2.689483024, 14.5389473983],
      "speed factor": 1.1042814128,
      "Uz factor": 0.9504224912,
      "Directional variance factor": 0.7609348423,
      "Velocity stability factor": 0.5787235678,
      "Direction stability factor": 0.9321922378,
      score: 0.8010107214,
    },
  },
  {
    position: [-53.00215104, 80.42343294, 46.87999908],
    "overall score": 0.212822245,
    "0": {
      velocity: [0.4719486988, 1.0958784329, 1.4231574036],
      direction: [-18.5280668151, -3.5979980225, 67.7966826865],
      "speed factor": 1.123002574,
      "Uz factor": 0.9996550628,
      "Directional variance factor": 0.6801779536,
      "Velocity stability factor": 0.339070901,
      "Direction stability factor": 0.7602090292,
      score: 0.6349167282,
    },
    "22.5": {
      velocity: [0.1677520086, 0.6853884355, 1.1595885421],
      direction: [-90.4042325705, 21.0639946065, 151.8161278998],
      "speed factor": 0.6085397035,
      "Uz factor": 0.9923550822,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3586056472,
      "Direction stability factor": 0.3271656654,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0225805143, 0.4159160764, 0.9491549683],
      direction: [-154.6501038273, 3.8842149317, 139.8235574925],
      "speed factor": 0.6442817294,
      "Uz factor": 0.9930903664,
      "Directional variance factor": 0.6547364505,
      "Velocity stability factor": 0.1720552024,
      "Direction stability factor": 0.1820176074,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.267439462, 0.8350471585, 1.2526642082],
      direction: [-45.2932684369, -0.0901830176, 34.0949715443],
      "speed factor": 0.9393697162,
      "Uz factor": 0.9859593626,
      "Directional variance factor": 0.9919837318,
      "Velocity stability factor": 0.1844122067,
      "Direction stability factor": 0.7794771112,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4412297402, 0.9140293372, 1.2226980065],
      direction: [-59.215804945, -18.9440200547, 7.2192385451],
      "speed factor": 1.2985072699,
      "Uz factor": 0.9890799695,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3609219375,
      "Direction stability factor": 0.8154582125,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5425720221, 0.9841649995, 1.330086528],
      direction: [-45.2539358865, -12.7470653129, 9.2396077549],
      "speed factor": 1.1531022643,
      "Uz factor": 0.9980825111,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.394478447,
      "Direction stability factor": 0.8486290454,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2202250277, 0.4328642486, 0.6680247456],
      direction: [-29.8377645518, -1.2295701898, 46.8031302305],
      "speed factor": 0.803015344,
      "Uz factor": 0.9828318955,
      "Directional variance factor": 0.890704872,
      "Velocity stability factor": 0.5654785065,
      "Direction stability factor": 0.7871086256,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4659959448, 0.9287129301, 1.2592959464],
      direction: [-42.9541727616, -2.0512350741, 33.8522332251],
      "speed factor": 1.2646754612,
      "Uz factor": 0.9349245577,
      "Directional variance factor": 0.8176679934,
      "Velocity stability factor": 0.4182416041,
      "Direction stability factor": 0.7866488723,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7723385194, 1.0050979238, 1.2515196884],
      direction: [-7.5408032726, 4.1288263514, 28.6033021375],
      "speed factor": 1.2764119826,
      "Uz factor": 0.9832194906,
      "Directional variance factor": 0.6329932132,
      "Velocity stability factor": 0.6140509681,
      "Direction stability factor": 0.8995997072,
      score: 0.7615608989,
    },
    id: "IDN:437.0",
    "45": {
      velocity: [0.0512697125, 0.4565477823, 1.0321899994],
      direction: [-54.2553384603, 1.4013700729, 112.7061128926],
      "speed factor": 0.6201660797,
      "Uz factor": 0.9997699193,
      "Directional variance factor": 0.8754337713,
      "Velocity stability factor": 0.2025428461,
      "Direction stability factor": 0.5362181907,
      score: 0.0,
    },
    "90": {
      velocity: [0.2333371617, 0.8425277892, 1.4266121566],
      direction: [-122.421183513, 4.8770770123, 31.2452906962],
      "speed factor": 1.3657622013,
      "Uz factor": 0.9885171469,
      "Directional variance factor": 0.5664820434,
      "Velocity stability factor": 0.1290792576,
      "Direction stability factor": 0.5731486828,
      score: 0.4604646666,
    },
    "135": {
      velocity: [0.1488417743, 0.9798851682, 1.3127644443],
      direction: [-83.5154522354, -20.625836402, 19.9421288181],
      "speed factor": 0.8293261304,
      "Uz factor": 0.9950803323,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.062098531,
      "Direction stability factor": 0.7126178304,
      score: 0.0,
    },
    "180": {
      velocity: [0.4661623862, 0.8793563431, 1.2286883074],
      direction: [-49.9185629473, -29.3270176755, 7.2302868256],
      "speed factor": 1.7173775058,
      "Uz factor": 0.9992957695,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3991624538,
      "Direction stability factor": 0.8412531951,
      score: 0.0,
    },
    "225": {
      velocity: [0.5186583779, 0.7571634697, 1.0211594121],
      direction: [-16.624987779, 2.2749339326, 22.4152949996],
      "speed factor": 1.0336109967,
      "Uz factor": 0.9901243307,
      "Directional variance factor": 0.7977836504,
      "Velocity stability factor": 0.548361814,
      "Direction stability factor": 0.8915547701,
      score: 0.7823137511,
    },
    "270": {
      velocity: [0.7927530628, 1.0143456803, 1.2362924383],
      direction: [-15.3262483071, 9.7614232159, 26.9920790135],
      "speed factor": 0.9281590503,
      "Uz factor": 0.932850351,
      "Directional variance factor": 0.1323179364,
      "Velocity stability factor": 0.6501347194,
      "Direction stability factor": 0.8824490908,
      score: 0.0,
    },
    "315": {
      velocity: [0.6570870746, 0.9080945808, 1.18289006],
      direction: [-6.3094741915, 4.442903312, 14.3771103475],
      "speed factor": 1.1188628953,
      "Uz factor": 0.9636395729,
      "Directional variance factor": 0.6050752612,
      "Velocity stability factor": 0.5734497058,
      "Direction stability factor": 0.9425372652,
      score: 0.7658998743,
    },
  },
  {
    position: [-52.91929065, 80.0059607, 46.87999908],
    "overall score": 0.2118128903,
    "0": {
      velocity: [0.3572507788, 1.0822638701, 1.415305464],
      direction: [-18.9514919461, -3.7792945829, 70.0402790549],
      "speed factor": 1.1090510364,
      "Uz factor": 0.9998248617,
      "Directional variance factor": 0.6640627037,
      "Velocity stability factor": 0.2648310237,
      "Direction stability factor": 0.7528006361,
      score: 0.6086237499,
    },
    "22.5": {
      velocity: [0.1950317056, 0.6924418917, 1.1588179068],
      direction: [-93.2128943929, 21.6728297174, 149.9877027473],
      "speed factor": 0.6148022955,
      "Uz factor": 0.9929825286,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3767450525,
      "Direction stability factor": 0.3244427857,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0601996022, 0.4166194957, 0.9424494613],
      direction: [-149.7702020319, 3.4896815056, 122.7953596634],
      "speed factor": 0.6453713728,
      "Uz factor": 0.991584335,
      "Directional variance factor": 0.6898060884,
      "Velocity stability factor": 0.2116616448,
      "Direction stability factor": 0.2428734397,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3084415341, 0.851133549, 1.2422063758],
      direction: [-38.876179635, -0.6193715436, 30.3734487917],
      "speed factor": 0.9574657817,
      "Uz factor": 0.9846406281,
      "Directional variance factor": 0.9449447517,
      "Velocity stability factor": 0.2270116949,
      "Direction stability factor": 0.807639921,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4427374012, 0.9124572248, 1.2224677801],
      direction: [-57.2608105953, -19.1520851617, 5.8372088954],
      "speed factor": 1.296273863,
      "Uz factor": 0.9888050741,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.362343167,
      "Direction stability factor": 0.8247277236,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5450259211, 0.9680002644, 1.316455971],
      direction: [-50.3881300542, -12.8040577256, 9.0121087624],
      "speed factor": 1.1341627647,
      "Uz factor": 0.9976561991,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4068458189,
      "Direction stability factor": 0.8349993366,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2215169419, 0.4336840101, 0.6723284025],
      direction: [-31.3209607664, -1.5329477721, 46.1776795958],
      "speed factor": 0.8045361003,
      "Uz factor": 0.9833387455,
      "Directional variance factor": 0.8637379758,
      "Velocity stability factor": 0.5625560684,
      "Direction stability factor": 0.784725999,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4662680944, 0.9383694517, 1.2762501474],
      direction: [-40.8192020108, -1.5690398224, 33.6576863947],
      "speed factor": 1.2778252359,
      "Uz factor": 0.9419821338,
      "Directional variance factor": 0.8605297936,
      "Velocity stability factor": 0.4060079933,
      "Direction stability factor": 0.7931197544,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7568845449, 1.0047434922, 1.253790993],
      direction: [-7.6545951579, 3.9860327141, 28.1002265104],
      "speed factor": 1.2759618764,
      "Uz factor": 0.984144165,
      "Directional variance factor": 0.645685981,
      "Velocity stability factor": 0.5997744172,
      "Direction stability factor": 0.9006810509,
      score: 0.761705625,
    },
    id: "IDN:438.0",
    "45": {
      velocity: [0.0371645369, 0.4483604378, 1.0264368304],
      direction: [-51.2421658744, 0.3399645085, 115.8044820161],
      "speed factor": 0.6090445421,
      "Uz factor": 0.9989910992,
      "Directional variance factor": 0.9697809326,
      "Velocity stability factor": 0.1957529291,
      "Direction stability factor": 0.5359815336,
      score: 0.0,
    },
    "90": {
      velocity: [0.2394425806, 0.8790701952, 1.406103537],
      direction: [-131.351422983, 4.342388734, 29.4692246876],
      "speed factor": 1.4249985108,
      "Uz factor": 0.98902826,
      "Directional variance factor": 0.6140098903,
      "Velocity stability factor": 0.148503714,
      "Direction stability factor": 0.5532759787,
      score: 0.4672663904,
    },
    "135": {
      velocity: [0.1916466131, 0.9907487568, 1.3037592723],
      direction: [-75.7531905223, -20.8109188257, 18.0722368262],
      "speed factor": 0.8385205321,
      "Uz factor": 0.9943234082,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1038475978,
      "Direction stability factor": 0.7393738129,
      score: 0.0,
    },
    "180": {
      velocity: [0.4674499438, 0.8703718687, 1.2143522787],
      direction: [-50.3241488198, -29.4281512399, 4.6103925993],
      "speed factor": 1.6998308828,
      "Uz factor": 0.9987800738,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4114731661,
      "Direction stability factor": 0.8474040516,
      score: 0.0,
    },
    "225": {
      velocity: [0.5143705912, 0.749241188, 1.0182658244],
      direction: [-16.6255581533, 2.2313942742, 23.1529459728],
      "speed factor": 1.0227962152,
      "Uz factor": 0.9904410232,
      "Directional variance factor": 0.8016538423,
      "Velocity stability factor": 0.5471087349,
      "Direction stability factor": 0.8895041552,
      score: 0.7819427219,
    },
    "270": {
      velocity: [0.7954224817, 1.0177935256, 1.2423194398],
      direction: [-15.2914340931, 10.0820621975, 27.3433035195],
      "speed factor": 0.93131394,
      "Uz factor": 0.9399902271,
      "Directional variance factor": 0.1038166936,
      "Velocity stability factor": 0.6474862475,
      "Direction stability factor": 0.8815701733,
      score: 0.0,
    },
    "315": {
      velocity: [0.6690133942, 0.9104029306, 1.1854551499],
      direction: [-6.1791994592, 4.3940959476, 14.0863763636],
      "speed factor": 1.1217070121,
      "Uz factor": 0.9664410281,
      "Directional variance factor": 0.6094136935,
      "Velocity stability factor": 0.5810438721,
      "Direction stability factor": 0.9437067338,
      score: 0.7694677583,
    },
  },
  {
    position: [-52.61952834, 79.68102978, 46.87999908],
    "overall score": 0.2108880601,
    "0": {
      velocity: [0.183723003, 1.0353464952, 1.4113938962],
      direction: [-20.9282777554, -4.1631800473, 70.3440484864],
      "speed factor": 1.0609724073,
      "Uz factor": 0.9999749594,
      "Directional variance factor": 0.6299395514,
      "Velocity stability factor": 0.1469764593,
      "Direction stability factor": 0.7464657604,
      score: 0.5674618829,
    },
    "22.5": {
      velocity: [0.1317087714, 0.6933447647, 1.163760157],
      direction: [-103.5951747426, 22.8077934954, 145.2448148431],
      "speed factor": 0.6156039345,
      "Uz factor": 0.993796341,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3325997701,
      "Direction stability factor": 0.3087778067,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0654370399, 0.4224245259, 0.9476733702],
      direction: [-133.7641184957, 2.4568863052, 118.0261172198],
      "speed factor": 0.6543637517,
      "Uz factor": 0.9879632604,
      "Directional variance factor": 0.7816101062,
      "Velocity stability factor": 0.2116737336,
      "Direction stability factor": 0.3005826786,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3268165679, 0.8615396948, 1.2410815499],
      direction: [-36.6772482266, -1.1983476606, 27.3586577314],
      "speed factor": 0.9691719688,
      "Uz factor": 0.9835001027,
      "Directional variance factor": 0.8934802079,
      "Velocity stability factor": 0.2431540498,
      "Direction stability factor": 0.8221224834,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4433530969, 0.9083214122, 1.2110676068],
      direction: [-57.2057134031, -19.2487645424, 5.0636606089],
      "speed factor": 1.2903983593,
      "Uz factor": 0.9875404312,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3721696419,
      "Direction stability factor": 0.8270295166,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5218958875, 0.9443711909, 1.2959661377],
      direction: [-54.3639961798, -12.782029755, 9.0907528762],
      "speed factor": 1.1064776324,
      "Uz factor": 0.996994118,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4048157635,
      "Direction stability factor": 0.8237368082,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2266939922, 0.4390231292, 0.6861480762],
      direction: [-32.9956220649, -1.9614781715, 45.1426021965],
      "speed factor": 0.8144408098,
      "Uz factor": 0.9841845571,
      "Directional variance factor": 0.8256463848,
      "Velocity stability factor": 0.5541697173,
      "Direction stability factor": 0.7829493771,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4668004213, 0.9503711237, 1.2891745911],
      direction: [-40.7749674663, -1.815481153, 33.3889780711],
      "speed factor": 1.2941685209,
      "Uz factor": 0.9472467659,
      "Directional variance factor": 0.8386238975,
      "Velocity stability factor": 0.3969203619,
      "Direction stability factor": 0.7939890402,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6872230783, 1.0017739473, 1.2545262343],
      direction: [-7.8852918939, 3.6108015392, 27.5658539649],
      "speed factor": 1.2721907387,
      "Uz factor": 0.9850335872,
      "Directional variance factor": 0.6790398632,
      "Velocity stability factor": 0.5430744819,
      "Direction stability factor": 0.9015245948,
      score: 0.7562908837,
    },
    id: "IDN:439.0",
    "45": {
      velocity: [0.044566592, 0.4413698968, 1.0014437441],
      direction: [-78.1260766451, -0.0375031484, 114.3595362263],
      "speed factor": 0.5995487203,
      "Uz factor": 0.9973765347,
      "Directional variance factor": 0.9966663868,
      "Velocity stability factor": 0.2220891539,
      "Direction stability factor": 0.465317742,
      score: 0.0,
    },
    "90": {
      velocity: [0.2443731668, 0.9097108706, 1.4008788541],
      direction: [-116.7866068135, 3.8288570071, 30.9435934548],
      "speed factor": 1.4746679423,
      "Uz factor": 0.9888724261,
      "Directional variance factor": 0.6596571549,
      "Velocity stability factor": 0.1559156137,
      "Direction stability factor": 0.5896383326,
      score: 0.4987123585,
    },
    "135": {
      velocity: [0.2766465202, 1.0030761446, 1.2964210385],
      direction: [-65.9830726257, -21.2296629996, 16.2608495259],
      "speed factor": 0.8489538208,
      "Uz factor": 0.9930734035,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1782546699,
      "Direction stability factor": 0.7715446607,
      score: 0.0,
    },
    "180": {
      velocity: [0.5141788792, 0.8613357515, 1.2004220465],
      direction: [-51.3780417469, -29.4803637844, 4.2009946627],
      "speed factor": 1.6821834018,
      "Uz factor": 0.9977450601,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4592699746,
      "Direction stability factor": 0.8456137878,
      score: 0.0,
    },
    "225": {
      velocity: [0.5109654924, 0.7456801369, 1.0210699604],
      direction: [-16.6641440377, 2.4347428858, 24.8618133831],
      "speed factor": 1.0179349908,
      "Uz factor": 0.9905731072,
      "Directional variance factor": 0.7835784102,
      "Velocity stability factor": 0.5415279951,
      "Direction stability factor": 0.8846501183,
      score: 0.7736016605,
    },
    "270": {
      velocity: [0.8064946818, 1.0306171301, 1.260289879],
      direction: [-14.725378868, 10.3169539975, 27.6641898359],
      "speed factor": 0.9430479523,
      "Uz factor": 0.9464016879,
      "Directional variance factor": 0.0829374224,
      "Velocity stability factor": 0.6420448943,
      "Direction stability factor": 0.882251198,
      score: 0.0,
    },
    "315": {
      velocity: [0.6645155831, 0.9151522905, 1.1885658083],
      direction: [-6.680800935, 3.9292288689, 13.6660599661],
      "speed factor": 1.1275586961,
      "Uz factor": 0.9685140619,
      "Directional variance factor": 0.6507352117,
      "Velocity stability factor": 0.5748716079,
      "Direction stability factor": 0.9434809419,
      score: 0.7781421759,
    },
  },
  {
    position: [-52.20709274, 79.56483697, 46.87999908],
    "overall score": 0.2103011021,
    "0": {
      velocity: [0.0935005716, 0.9790938395, 1.4152460083],
      direction: [-27.0556648003, -4.5375554584, 74.8861863265],
      "speed factor": 1.0033274393,
      "Uz factor": 0.9999828214,
      "Directional variance factor": 0.596661737,
      "Velocity stability factor": 0.0816105697,
      "Direction stability factor": 0.7168281913,
      score: 0.5279821723,
    },
    "22.5": {
      velocity: [0.116121357, 0.6894784516, 1.16416915],
      direction: [-115.3825098668, 24.2609793737, 142.7580444145],
      "speed factor": 0.6121711292,
      "Uz factor": 0.9943817977,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3222553182,
      "Direction stability factor": 0.2829429048,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0419750667, 0.4216749363, 0.9405277882],
      direction: [-127.4371259335, 1.5605333585, 111.3186079874],
      "speed factor": 0.6532025873,
      "Uz factor": 0.9853854436,
      "Directional variance factor": 0.8612859237,
      "Velocity stability factor": 0.1970941483,
      "Direction stability factor": 0.336789628,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.325385757, 0.8651592833, 1.2373495013],
      direction: [-36.5981086818, -1.558649246, 26.0449522656],
      "speed factor": 0.973243753,
      "Uz factor": 0.9824355992,
      "Directional variance factor": 0.8614534004,
      "Velocity stability factor": 0.2450590581,
      "Direction stability factor": 0.8259914974,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4400984212, 0.9039327085, 1.1974471209],
      direction: [-57.4845360011, -19.1169809126, 5.1346410812],
      "speed factor": 1.2841635883,
      "Uz factor": 0.9851207837,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3806467127,
      "Direction stability factor": 0.8260578414,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5085828189, 0.9219919479, 1.2717005111],
      direction: [-55.6362185217, -12.6639447711, 9.1617408913],
      "speed factor": 1.0802568709,
      "Uz factor": 0.9962053171,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4132372082,
      "Direction stability factor": 0.8200056683,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2326184797, 0.4454925395, 0.7014911783],
      direction: [-33.8910215928, -2.397068791, 43.5728151697],
      "speed factor": 0.8264423454,
      "Uz factor": 0.9851993458,
      "Directional variance factor": 0.7869272186,
      "Velocity stability factor": 0.545030385,
      "Direction stability factor": 0.7848226757,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4670738371, 0.9615623264, 1.2983639319],
      direction: [-42.5431391259, -2.3579238618, 33.1723523924],
      "speed factor": 1.3094081488,
      "Uz factor": 0.9492316815,
      "Directional variance factor": 0.7904067678,
      "Velocity stability factor": 0.3903819601,
      "Direction stability factor": 0.7896791902,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.5930949059, 0.9966241095, 1.2536251537],
      direction: [-8.1652866699, 3.1304405669, 27.3092694908],
      "speed factor": 1.2656507643,
      "Uz factor": 0.9856101076,
      "Directional variance factor": 0.7217386163,
      "Velocity stability factor": 0.4679861685,
      "Direction stability factor": 0.9014595662,
      score: 0.7481609793,
    },
    id: "IDN:440.0",
    "45": {
      velocity: [0.0509473244, 0.4359517391, 0.9558560238],
      direction: [-122.0533337703, 0.2437688515, 104.8815676232],
      "speed factor": 0.5921887948,
      "Uz factor": 0.9946571876,
      "Directional variance factor": 0.9783316576,
      "Velocity stability factor": 0.2643378615,
      "Direction stability factor": 0.3696252739,
      score: 0.0,
    },
    "90": {
      velocity: [0.2661070088, 0.9260537011, 1.4029392471],
      direction: [-88.6170218395, 3.6898852421, 32.6773777041],
      "speed factor": 1.5011601487,
      "Uz factor": 0.9883524301,
      "Directional variance factor": 0.6720102007,
      "Velocity stability factor": 0.1702744286,
      "Direction stability factor": 0.6630711124,
      score: 0.5421067135,
    },
    "135": {
      velocity: [0.3807536384, 1.0096348829, 1.2889725682],
      direction: [-63.1170859715, -21.5690813763, 14.9119241182],
      "speed factor": 0.8545048111,
      "Uz factor": 0.9917506968,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2681473689,
      "Direction stability factor": 0.7832527498,
      score: 0.0,
    },
    "180": {
      velocity: [0.5178836643, 0.8571392352, 1.1955318015],
      direction: [-52.2781563143, -29.5111249679, 7.8585825199],
      "speed factor": 1.6739876312,
      "Uz factor": 0.9963846532,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4660424877,
      "Direction stability factor": 0.8329535032,
      score: 0.0,
    },
    "225": {
      velocity: [0.5097040937, 0.7474273407, 1.0283299412],
      direction: [-16.7341166601, 2.66439047, 26.5378167169],
      "speed factor": 1.0203201151,
      "Uz factor": 0.9905816537,
      "Directional variance factor": 0.7631652916,
      "Velocity stability factor": 0.5338691445,
      "Direction stability factor": 0.8798001851,
      score: 0.7641587015,
    },
    "270": {
      velocity: [0.8207035831, 1.0468620351, 1.2788919082],
      direction: [-13.6827471125, 10.2653894028, 27.794328137],
      "speed factor": 0.9579125649,
      "Uz factor": 0.9507694693,
      "Directional variance factor": 0.087520942,
      "Velocity stability factor": 0.638579581,
      "Direction stability factor": 0.8847859021,
      score: 0.0,
    },
    "315": {
      velocity: [0.6210819007, 0.9220264139, 1.193879253],
      direction: [-7.5642850776, 3.2622157543, 13.2644484456],
      "speed factor": 1.1360283002,
      "Uz factor": 0.9696217812,
      "Directional variance factor": 0.7100252663,
      "Velocity stability factor": 0.5353261851,
      "Direction stability factor": 0.9421424069,
      score: 0.7824090663,
    },
  },
  {
    position: [-54.97810694, 41.06614952, 46.87999908],
    "overall score": 0.2096192004,
    "0": {
      velocity: [0.4004359803, 1.0415717462, 1.4000763693],
      direction: [-25.8193249571, -10.9315057801, 51.6249548343],
      "speed factor": 1.0673517398,
      "Uz factor": 0.9956375775,
      "Directional variance factor": 0.0283105973,
      "Velocity stability factor": 0.3054190755,
      "Direction stability factor": 0.7848770006,
      score: 0.4758709185,
    },
    "22.5": {
      velocity: [0.1901621008, 0.6405599001, 1.0813815836],
      direction: [-102.3914380252, -4.182868803, 102.1660300753],
      "speed factor": 0.5687375385,
      "Uz factor": 0.9994355408,
      "Directional variance factor": 0.6281894397,
      "Velocity stability factor": 0.4236720226,
      "Direction stability factor": 0.4317848108,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0575095822, 0.4577092846, 1.0622412464],
      direction: [-168.8944740758, 12.9073636607, 176.6746253037],
      "speed factor": 0.709022195,
      "Uz factor": 0.9999798252,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1022174735,
      "Direction stability factor": 0.0400858351,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0361574016, 0.4809137884, 0.9302006125],
      direction: [-168.5783031911, -14.3962514796, 33.2740070593],
      "speed factor": 0.5409944149,
      "Uz factor": 0.9994670897,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2598940167,
      "Direction stability factor": 0.4392991382,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1244128128, 0.7095699027, 1.2780219688],
      direction: [-88.2166619, -17.9160709427, 70.4610366233],
      "speed factor": 1.0080438773,
      "Uz factor": 0.9969558102,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0565883017,
      "Direction stability factor": 0.5592286152,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2590133651, 0.9143704362, 1.1721879518],
      direction: [-51.0388808962, -9.1360661415, 4.8605040795],
      "speed factor": 1.0713270853,
      "Uz factor": 0.9945825586,
      "Directional variance factor": 0.1879052319,
      "Velocity stability factor": 0.2978581478,
      "Direction stability factor": 0.8447239306,
      score: 0.5438028102,
    },
    "247.5": {
      velocity: [0.472030691, 0.7720168438, 0.9645169952],
      direction: [-21.4513863368, -0.2395834486, 10.560650263],
      "speed factor": 1.4321842782,
      "Uz factor": 0.9738986259,
      "Directional variance factor": 0.9787036935,
      "Velocity stability factor": 0.5221169736,
      "Direction stability factor": 0.9110776761,
      score: 0.8307440048,
    },
    "292.5": {
      velocity: [0.6758961076, 1.1449622212, 1.3189062253],
      direction: [-8.7606432312, 12.4949498667, 30.8157245019],
      "speed factor": 1.5591530797,
      "Uz factor": 0.9112095904,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5284551445,
      "Direction stability factor": 0.8900656452,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.551606938, 0.8877173851, 1.1458819152],
      direction: [-25.2206170349, -2.7900794362, 9.8824553369],
      "speed factor": 1.1273459836,
      "Uz factor": 0.9628172233,
      "Directional variance factor": 0.751992939,
      "Velocity stability factor": 0.5213504474,
      "Direction stability factor": 0.9024914656,
      score: 0.7695815794,
    },
    id: "IDN:464.0",
    "45": {
      velocity: [0.0598750021, 0.4659439698, 1.1938019581],
      direction: [-128.1172419429, 10.0949161893, 173.9819741426],
      "speed factor": 0.6329296874,
      "Uz factor": 0.9992230234,
      "Directional variance factor": 0.1026741165,
      "Velocity stability factor": 0.0781532658,
      "Direction stability factor": 0.1608355109,
      score: 0.0,
    },
    "90": {
      velocity: [0.1653477906, 0.597633167, 1.0219723456],
      direction: [-114.6158973376, 3.542243137, 119.9547093045],
      "speed factor": 0.9687808524,
      "Uz factor": 0.9997807269,
      "Directional variance factor": 0.6851339434,
      "Velocity stability factor": 0.3747861167,
      "Direction stability factor": 0.3484149815,
      score: 0.0,
    },
    "135": {
      velocity: [0.0636332097, 0.4867628869, 0.9546246033],
      direction: [-148.9921865473, -28.8809910689, 166.4994650194],
      "speed factor": 0.4119719274,
      "Uz factor": 0.9999943631,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2820295038,
      "Direction stability factor": 0.1236343012,
      score: 0.0,
    },
    "180": {
      velocity: [0.3355822356, 0.817265737, 1.1296331985],
      direction: [-56.2328352739, -17.0159070944, 9.0657823673],
      "speed factor": 1.5961149356,
      "Uz factor": 0.9996379434,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3743220802,
      "Direction stability factor": 0.818614951,
      score: 0.0,
    },
    "225": {
      velocity: [0.0877608253, 0.5354647756, 0.8686406276],
      direction: [-69.9701661439, -0.1331770408, 33.2509091358],
      "speed factor": 0.7309680176,
      "Uz factor": 0.9819881082,
      "Directional variance factor": 0.9881620408,
      "Velocity stability factor": 0.2981603751,
      "Direction stability factor": 0.7132747909,
      score: 0.0,
    },
    "270": {
      velocity: [0.6802816297, 0.9556198521, 1.2392164225],
      direction: [-7.5658303334, 20.9460368758, 40.8700422581],
      "speed factor": 0.8744230212,
      "Uz factor": 0.9182525921,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5591104445,
      "Direction stability factor": 0.8654559095,
      score: 0.0,
    },
    "315": {
      velocity: [0.6096729472, 1.1004017457, 1.2897320352],
      direction: [-9.5912667931, 3.1722509618, 31.9351242305],
      "speed factor": 1.3558044605,
      "Uz factor": 0.9504677856,
      "Directional variance factor": 0.7180221367,
      "Velocity stability factor": 0.4483116069,
      "Direction stability factor": 0.8846489138,
      score: 0.7339078928,
    },
  },
  {
    position: [-54.76353402, 47.0623115, 47.87999908],
    "overall score": 0.2095079395,
    "0": {
      velocity: [0.3173332714, 1.1056571637, 1.4242547976],
      direction: [-23.8396289395, -8.0271841499, 57.939701546],
      "speed factor": 1.1330233386,
      "Uz factor": 0.9964753882,
      "Directional variance factor": 0.28647252,
      "Velocity stability factor": 0.2308768378,
      "Direction stability factor": 0.7728351931,
      score: 0.515754936,
    },
    "22.5": {
      velocity: [0.2138032525, 0.7166653308, 1.2784493254],
      direction: [-52.1689347694, 2.2668172779, 80.3483314096],
      "speed factor": 0.6363096973,
      "Uz factor": 0.9999581644,
      "Directional variance factor": 0.7985051309,
      "Velocity stability factor": 0.3115216513,
      "Direction stability factor": 0.6318964828,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0406818045, 0.4913614473, 1.0545320565],
      direction: [-137.0872559828, 3.420566701, 92.8697783866],
      "speed factor": 0.7611516385,
      "Uz factor": 0.9988314686,
      "Directional variance factor": 0.6959496266,
      "Velocity stability factor": 0.094069518,
      "Direction stability factor": 0.3612304601,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.122834055, 0.616287557, 1.1837164942],
      direction: [-134.9294908914, -26.6119795052, 156.1922738807],
      "speed factor": 0.6932804473,
      "Uz factor": 0.9971608407,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1217813287,
      "Direction stability factor": 0.1913284312,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0336028935, 0.3276388478, 1.08512731],
      direction: [-178.9722607733, -1.5776339504, 178.9011707378],
      "speed factor": 0.4654570794,
      "Uz factor": 0.9929316741,
      "Directional variance factor": 0.8597658711,
      "Velocity stability factor": 0.1400723283,
      "Direction stability factor": 0.0059071347,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2212312389, 0.9452328963, 1.2432953476],
      direction: [-86.0493679296, -7.5497546914, 15.3218737427],
      "speed factor": 1.1074872542,
      "Uz factor": 0.9970942277,
      "Directional variance factor": 0.3289106941,
      "Velocity stability factor": 0.2141327661,
      "Direction stability factor": 0.7184132176,
      score: 0.4949674738,
    },
    "247.5": {
      velocity: [0.5261085039, 0.8063357476, 1.0109606454],
      direction: [-20.6750742735, -4.8120521992, 8.1639715014],
      "speed factor": 1.4958499804,
      "Uz factor": 0.9855809609,
      "Directional variance factor": 0.5722620267,
      "Velocity stability factor": 0.5295247669,
      "Direction stability factor": 0.9198915395,
      score: 0.7353924682,
    },
    "292.5": {
      velocity: [0.6866664615, 1.1394551171, 1.3057342454],
      direction: [-11.8470011704, 6.1447762744, 18.485463651],
      "speed factor": 1.5516537769,
      "Uz factor": 0.9283191307,
      "Directional variance factor": 0.4537976645,
      "Velocity stability factor": 0.5460130087,
      "Direction stability factor": 0.9157431533,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6376082264, 0.9202851771, 1.1285311636],
      direction: [-21.6660452264, -2.2804463901, 10.0121325426],
      "speed factor": 1.1687050581,
      "Uz factor": 0.9684260662,
      "Directional variance factor": 0.7972936542,
      "Velocity stability factor": 0.6045937431,
      "Direction stability factor": 0.9120050618,
      score: 0.8064743802,
    },
    id: "IDN:732.0",
    "45": {
      velocity: [0.148592607, 0.5222892271, 1.0757452347],
      direction: [-126.4640010056, 5.9593876682, 67.8941518289],
      "speed factor": 0.7094680448,
      "Uz factor": 0.9993164596,
      "Directional variance factor": 0.4702766517,
      "Velocity stability factor": 0.2462542517,
      "Direction stability factor": 0.4601162421,
      score: 0.0,
    },
    "90": {
      velocity: [0.0634686669, 0.8700345537, 1.2961271767],
      direction: [-123.4204639423, -13.8180096876, 126.8449479323],
      "speed factor": 1.4103514715,
      "Uz factor": 0.9936733511,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1003349026,
      "Direction stability factor": 0.3048183003,
      score: 0.0,
    },
    "135": {
      velocity: [0.1196673848, 0.6008324892, 1.0979069542],
      direction: [-164.6479326415, -33.7744205781, 89.5884701279],
      "speed factor": 0.5085147724,
      "Uz factor": 0.9999418642,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2117239806,
      "Direction stability factor": 0.2937877701,
      score: 0.0,
    },
    "180": {
      velocity: [0.2283086185, 0.7565970973, 1.1407592589],
      direction: [-164.0729589655, -12.4968788902, 30.9560249458],
      "speed factor": 1.4776294571,
      "Uz factor": 0.9995048609,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2810282398,
      "Direction stability factor": 0.4582528225,
      score: 0.0,
    },
    "225": {
      velocity: [0.1902591548, 0.5630690683, 0.8836122623],
      direction: [-49.3139080798, -0.916120619, 41.7763134941],
      "speed factor": 0.7686508979,
      "Uz factor": 0.9867965569,
      "Directional variance factor": 0.9185670561,
      "Velocity stability factor": 0.3768276712,
      "Direction stability factor": 0.7469716067,
      score: 0.0,
    },
    "270": {
      velocity: [0.7049750671, 0.9994910306, 1.326261684],
      direction: [-4.8174533736, 12.2049625758, 34.0698004044],
      "speed factor": 0.9145665661,
      "Uz factor": 0.9413144883,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5099271258,
      "Direction stability factor": 0.8919798506,
      score: 0.0,
    },
    "315": {
      velocity: [0.5604447539, 1.0905097995, 1.2754310506],
      direction: [-8.8486200986, 0.3005679705, 26.271027169],
      "speed factor": 1.3436165985,
      "Uz factor": 0.9572476676,
      "Directional variance factor": 0.9732828471,
      "Velocity stability factor": 0.4199773991,
      "Direction stability factor": 0.9024454243,
      score: 0.7995377737,
    },
  },
  {
    position: [-48.9216034, 95.65235413, 47.87999908],
    "overall score": 0.2088509833,
    "0": {
      velocity: [0.6719733059, 1.1283964085, 1.3447159593],
      direction: [-18.4770455033, -2.2693490142, 32.3135953706],
      "speed factor": 1.1563254035,
      "Uz factor": 0.9508875827,
      "Directional variance factor": 0.7982800876,
      "Velocity stability factor": 0.5325576884,
      "Direction stability factor": 0.8589148865,
      score: 0.7621668872,
    },
    "22.5": {
      velocity: [0.4228326981, 1.0248932464, 1.5552897424],
      direction: [-38.4452000139, 18.1535989522, 47.385419086],
      "speed factor": 0.9099777585,
      "Uz factor": 0.9477474266,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2676700964,
      "Direction stability factor": 0.7615816136,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2425888607, 0.704101912, 1.2090457352],
      direction: [-34.4877212463, -4.0891233319, 41.4157898377],
      "speed factor": 1.0907008007,
      "Uz factor": 0.9947210504,
      "Directional variance factor": 0.6365223705,
      "Velocity stability factor": 0.136418085,
      "Direction stability factor": 0.7891569137,
      score: 0.5878135707,
    },
    "112.5": {
      velocity: [0.0582390564, 0.4337133593, 1.0794496523],
      direction: [-175.4897792272, 32.9676872513, 173.5418589379],
      "speed factor": 0.4878972296,
      "Uz factor": 0.9999438148,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1546224355,
      "Direction stability factor": 0.0304676718,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0478485466, 0.4045550032, 0.9725005054],
      direction: [-173.0082652145, -12.0563980867, 168.8848220231],
      "speed factor": 0.5747272996,
      "Uz factor": 0.99734694,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2438275388,
      "Direction stability factor": 0.0502969799,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5930711882, 1.0509722916, 1.4908706245],
      direction: [-46.4445490349, -11.5973345365, 15.1098282251],
      "speed factor": 1.2313773907,
      "Uz factor": 0.9962826917,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.309680133,
      "Direction stability factor": 0.8290156187,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1494433477, 0.4674424728, 0.8852963808],
      direction: [-59.3929113986, -3.3188431378, 30.9779685085],
      "speed factor": 0.8671621168,
      "Uz factor": 0.9818025847,
      "Directional variance factor": 0.7049917211,
      "Velocity stability factor": 0.2859665914,
      "Direction stability factor": 0.748969778,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3986580531, 0.7651359565, 1.1033003387],
      direction: [-56.8350620216, -18.3419957461, 30.0926595482],
      "speed factor": 1.0419244067,
      "Uz factor": 0.9699838304,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4832578282,
      "Direction stability factor": 0.7585341068,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7045135347, 0.9428749239, 1.099768268],
      direction: [-5.8696194412, 5.1054474797, 25.1931131504],
      "speed factor": 1.1973926345,
      "Uz factor": 0.9149366876,
      "Directional variance factor": 0.5461824462,
      "Velocity stability factor": 0.6816482125,
      "Direction stability factor": 0.9137146317,
      score: 0.0,
    },
    id: "IDN:696.0",
    "45": {
      velocity: [0.2491941908, 0.743989411, 1.2313045941],
      direction: [-28.8924688959, -1.8081448073, 37.1020942716],
      "speed factor": 1.0106214821,
      "Uz factor": 0.9804097801,
      "Directional variance factor": 0.8392760171,
      "Velocity stability factor": 0.2015753192,
      "Direction stability factor": 0.816681769,
      score: 0.6685537186,
    },
    "90": {
      velocity: [0.1053476789, 0.6356510779, 1.1596184707],
      direction: [-113.0318206493, 23.0039667622, 144.7204181803],
      "speed factor": 1.0304089984,
      "Uz factor": 0.99909761,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2305325221,
      "Direction stability factor": 0.2840215588,
      score: 0.0,
    },
    "135": {
      velocity: [0.0205019269, 0.3733698703, 0.9231881364],
      direction: [-176.4403536043, 31.2023175198, 172.6367166917],
      "speed factor": 0.316001711,
      "Uz factor": 0.9998708784,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2726056948,
      "Direction stability factor": 0.0303414714,
      score: 0.0,
    },
    "180": {
      velocity: [0.0976044374, 0.5494764321, 1.3141254936],
      direction: [-170.9071073674, -29.2285287658, 155.9722658922],
      "speed factor": 1.0731240774,
      "Uz factor": 0.9972023805,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0414338635,
      "Direction stability factor": 0.0920017409,
      score: 0.0,
    },
    "225": {
      velocity: [0.2179847905, 0.9277097128, 1.2471730485],
      direction: [-25.8408587967, -1.3454716744, 13.7939583989],
      "speed factor": 1.2664252823,
      "Uz factor": 0.996496092,
      "Directional variance factor": 0.8804025178,
      "Velocity stability factor": 0.0749855498,
      "Direction stability factor": 0.8899032856,
      score: 0.6837986597,
    },
    "270": {
      velocity: [0.7784152164, 1.1250596546, 1.3650349899],
      direction: [-23.5936067832, -9.1657602931, 6.1795828905],
      "speed factor": 1.0294659117,
      "Uz factor": 0.9877256092,
      "Directional variance factor": 0.1852657517,
      "Velocity stability factor": 0.537272443,
      "Direction stability factor": 0.9172966954,
      score: 0.6392828963,
    },
    "315": {
      velocity: [0.6974935774, 0.8417067454, 1.0029106902],
      direction: [-10.5358229589, 6.6417446907, 25.5294175498],
      "speed factor": 1.0370664753,
      "Uz factor": 0.9357488739,
      "Directional variance factor": 0.4096226942,
      "Velocity stability factor": 0.7522346526,
      "Direction stability factor": 0.8998187764,
      score: 0.0,
    },
  },
  {
    position: [-48.9216034, 95.65235413, 47.87999908],
    "overall score": 0.2088509833,
    "0": {
      velocity: [0.6719733059, 1.1283964085, 1.3447159593],
      direction: [-18.4770455033, -2.2693490142, 32.3135953706],
      "speed factor": 1.1563254035,
      "Uz factor": 0.9508875827,
      "Directional variance factor": 0.7982800876,
      "Velocity stability factor": 0.5325576884,
      "Direction stability factor": 0.8589148865,
      score: 0.7621668872,
    },
    "22.5": {
      velocity: [0.4228326981, 1.0248932464, 1.5552897424],
      direction: [-38.4452000139, 18.1535989522, 47.385419086],
      "speed factor": 0.9099777585,
      "Uz factor": 0.9477474266,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2676700964,
      "Direction stability factor": 0.7615816136,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2425888607, 0.704101912, 1.2090457352],
      direction: [-34.4877212463, -4.0891233319, 41.4157898377],
      "speed factor": 1.0907008007,
      "Uz factor": 0.9947210504,
      "Directional variance factor": 0.6365223705,
      "Velocity stability factor": 0.136418085,
      "Direction stability factor": 0.7891569137,
      score: 0.5878135707,
    },
    "112.5": {
      velocity: [0.0582390564, 0.4337133593, 1.0794496523],
      direction: [-175.4897792272, 32.9676872513, 173.5418589379],
      "speed factor": 0.4878972296,
      "Uz factor": 0.9999438148,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1546224355,
      "Direction stability factor": 0.0304676718,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0478485466, 0.4045550032, 0.9725005054],
      direction: [-173.0082652145, -12.0563980867, 168.8848220231],
      "speed factor": 0.5747272996,
      "Uz factor": 0.99734694,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2438275388,
      "Direction stability factor": 0.0502969799,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5930711882, 1.0509722916, 1.4908706245],
      direction: [-46.4445490349, -11.5973345365, 15.1098282251],
      "speed factor": 1.2313773907,
      "Uz factor": 0.9962826917,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.309680133,
      "Direction stability factor": 0.8290156187,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1494433477, 0.4674424728, 0.8852963808],
      direction: [-59.3929113986, -3.3188431378, 30.9779685085],
      "speed factor": 0.8671621168,
      "Uz factor": 0.9818025847,
      "Directional variance factor": 0.7049917211,
      "Velocity stability factor": 0.2859665914,
      "Direction stability factor": 0.748969778,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3986580531, 0.7651359565, 1.1033003387],
      direction: [-56.8350620216, -18.3419957461, 30.0926595482],
      "speed factor": 1.0419244067,
      "Uz factor": 0.9699838304,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4832578282,
      "Direction stability factor": 0.7585341068,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7045135347, 0.9428749239, 1.099768268],
      direction: [-5.8696194412, 5.1054474797, 25.1931131504],
      "speed factor": 1.1973926345,
      "Uz factor": 0.9149366876,
      "Directional variance factor": 0.5461824462,
      "Velocity stability factor": 0.6816482125,
      "Direction stability factor": 0.9137146317,
      score: 0.0,
    },
    id: "IDN:695.0",
    "45": {
      velocity: [0.2491941908, 0.743989411, 1.2313045941],
      direction: [-28.8924688959, -1.8081448073, 37.1020942716],
      "speed factor": 1.0106214821,
      "Uz factor": 0.9804097801,
      "Directional variance factor": 0.8392760171,
      "Velocity stability factor": 0.2015753192,
      "Direction stability factor": 0.816681769,
      score: 0.6685537186,
    },
    "90": {
      velocity: [0.1053476789, 0.6356510779, 1.1596184707],
      direction: [-113.0318206493, 23.0039667622, 144.7204181803],
      "speed factor": 1.0304089984,
      "Uz factor": 0.99909761,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2305325221,
      "Direction stability factor": 0.2840215588,
      score: 0.0,
    },
    "135": {
      velocity: [0.0205019269, 0.3733698703, 0.9231881364],
      direction: [-176.4403536043, 31.2023175198, 172.6367166917],
      "speed factor": 0.316001711,
      "Uz factor": 0.9998708784,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2726056948,
      "Direction stability factor": 0.0303414714,
      score: 0.0,
    },
    "180": {
      velocity: [0.0976044374, 0.5494764321, 1.3141254936],
      direction: [-170.9071073674, -29.2285287658, 155.9722658922],
      "speed factor": 1.0731240774,
      "Uz factor": 0.9972023805,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0414338635,
      "Direction stability factor": 0.0920017409,
      score: 0.0,
    },
    "225": {
      velocity: [0.2179847905, 0.9277097128, 1.2471730485],
      direction: [-25.8408587967, -1.3454716744, 13.7939583989],
      "speed factor": 1.2664252823,
      "Uz factor": 0.996496092,
      "Directional variance factor": 0.8804025178,
      "Velocity stability factor": 0.0749855498,
      "Direction stability factor": 0.8899032856,
      score: 0.6837986597,
    },
    "270": {
      velocity: [0.7784152164, 1.1250596546, 1.3650349899],
      direction: [-23.5936067832, -9.1657602931, 6.1795828905],
      "speed factor": 1.0294659117,
      "Uz factor": 0.9877256092,
      "Directional variance factor": 0.1852657517,
      "Velocity stability factor": 0.537272443,
      "Direction stability factor": 0.9172966954,
      score: 0.6392828963,
    },
    "315": {
      velocity: [0.6974935774, 0.8417067454, 1.0029106902],
      direction: [-10.5358229589, 6.6417446907, 25.5294175498],
      "speed factor": 1.0370664753,
      "Uz factor": 0.9357488739,
      "Directional variance factor": 0.4096226942,
      "Velocity stability factor": 0.7522346526,
      "Direction stability factor": 0.8998187764,
      score: 0.0,
    },
  },
  {
    position: [-51.63108851, 93.82668631, 47.87999908],
    "overall score": 0.2085451656,
    "0": {
      velocity: [0.8284436209, 1.2094920883, 1.3775496489],
      direction: [-10.9560749415, 1.4403000765, 29.3493699687],
      "speed factor": 1.2394282866,
      "Uz factor": 0.9754535142,
      "Directional variance factor": 0.8719733265,
      "Velocity stability factor": 0.6184642229,
      "Direction stability factor": 0.8880404308,
      score: 0.8166296028,
    },
    "22.5": {
      velocity: [0.4238090704, 1.0279682645, 1.5551396989],
      direction: [-40.0460306581, 18.0491303813, 47.5436420347],
      "speed factor": 0.9127079922,
      "Uz factor": 0.9648769382,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2683985196,
      "Direction stability factor": 0.7566953536,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2875717658, 0.6993832614, 1.2217998149],
      direction: [-31.0311287094, -4.4807137335, 34.6905139532],
      "speed factor": 1.0833912963,
      "Uz factor": 0.9977255561,
      "Directional variance factor": 0.6017143348,
      "Velocity stability factor": 0.1652162978,
      "Direction stability factor": 0.8174398815,
      score: 0.6004525989,
    },
    "112.5": {
      velocity: [0.0457762735, 0.3777238748, 0.8927547309],
      direction: [-133.282291823, 52.1674466361, 174.1227539899],
      "speed factor": 0.424912971,
      "Uz factor": 0.9996839092,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2988551152,
      "Direction stability factor": 0.146097095,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1084604721, 0.503110438, 1.0389540059],
      direction: [-148.9247694964, -8.1789860907, 158.1599891352],
      "speed factor": 0.7147391606,
      "Uz factor": 0.999981243,
      "Directional variance factor": 0.2729790142,
      "Velocity stability factor": 0.2390503487,
      "Direction stability factor": 0.1469867816,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5643401527, 1.109037302, 1.4596337526],
      direction: [-39.1578322854, -9.786171135, 9.1253909802],
      "speed factor": 1.2994095754,
      "Uz factor": 0.9984932462,
      "Directional variance factor": 0.1301181213,
      "Velocity stability factor": 0.3116068759,
      "Direction stability factor": 0.8658799354,
      score: 0.543371217,
    },
    "247.5": {
      velocity: [0.2434461165, 0.4579244833, 0.8194855316],
      direction: [-35.4656991253, -3.0247791786, 29.8272852816],
      "speed factor": 0.8495050993,
      "Uz factor": 0.9723543638,
      "Directional variance factor": 0.7311307397,
      "Velocity stability factor": 0.441041392,
      "Direction stability factor": 0.8186305989,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4033555398, 0.7551374493, 1.0585329946],
      direction: [-50.3382575096, -19.2244943376, 33.8156112425],
      "speed factor": 1.0283089327,
      "Uz factor": 0.9427613708,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.519532353,
      "Direction stability factor": 0.7662392535,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.708181335, 0.9684094235, 1.1604417823],
      direction: [-2.7675403217, 8.0603554895, 26.6500373682],
      "speed factor": 1.2298198642,
      "Uz factor": 0.9458650174,
      "Directional variance factor": 0.2835239565,
      "Velocity stability factor": 0.6357338453,
      "Direction stability factor": 0.9182845064,
      score: 0.0,
    },
    id: "IDN:698.0",
    "45": {
      velocity: [0.1781016794, 0.7653076883, 1.3377049631],
      direction: [-29.7672104432, -1.2682473229, 50.6583051816],
      "speed factor": 1.0395798365,
      "Uz factor": 0.988475829,
      "Directional variance factor": 0.8872669046,
      "Velocity stability factor": 0.0572792238,
      "Direction stability factor": 0.7765957899,
      score: 0.6244344271,
    },
    "90": {
      velocity: [0.1221064589, 0.5309635807, 1.1210772978],
      direction: [-95.1514096605, 25.5321573723, 165.7048508274],
      "speed factor": 0.860707502,
      "Uz factor": 0.9963069051,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2708936093,
      "Direction stability factor": 0.2753992764,
      score: 0.0,
    },
    "135": {
      velocity: [0.0803183688, 0.3426507515, 0.9293587474],
      direction: [-176.5296625316, 61.7599265143, 175.4860147254],
      "speed factor": 0.2900025748,
      "Uz factor": 0.9972874866,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3158340852,
      "Direction stability factor": 0.0221786743,
      score: 0.0,
    },
    "180": {
      velocity: [0.2394760431, 0.7676205728, 1.3473841106],
      direction: [-74.1654761232, -27.5627754762, 40.9015826202],
      "speed factor": 1.4991582366,
      "Uz factor": 0.9951328467,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1270162152,
      "Direction stability factor": 0.6803692813,
      score: 0.0,
    },
    "225": {
      velocity: [0.5405179179, 0.9196250806, 1.1715768508],
      direction: [-24.6668437133, -2.4375019995, 12.9190293997],
      "speed factor": 1.2553888747,
      "Uz factor": 0.9950797052,
      "Directional variance factor": 0.7833331556,
      "Velocity stability factor": 0.4328164674,
      "Direction stability factor": 0.8955947969,
      score: 0.7518348042,
    },
    "270": {
      velocity: [0.7438870381, 1.0718491915, 1.3205914613],
      direction: [-24.0880525577, -9.700638892, 5.0425740711],
      "speed factor": 0.9807766198,
      "Uz factor": 0.9696427715,
      "Directional variance factor": 0.1377209874,
      "Velocity stability factor": 0.5450937031,
      "Direction stability factor": 0.9190815927,
      score: 0.0,
    },
    "315": {
      velocity: [0.6723617343, 0.8292883995, 1.0050396849],
      direction: [-10.3381966655, 6.1075820515, 25.4142371185],
      "speed factor": 1.0217658374,
      "Uz factor": 0.948266301,
      "Directional variance factor": 0.4571038176,
      "Velocity stability factor": 0.7301196805,
      "Direction stability factor": 0.9006876839,
      score: 0.0,
    },
  },
  {
    position: [12.4859298, 93.58910899, 47.87999908],
    "overall score": 0.2036246421,
    "0": {
      velocity: [0.0908100585, 0.6216452074, 1.1524371777],
      direction: [-149.010696853, -22.1993146646, 55.0420938207],
      "speed factor": 0.6370315785,
      "Uz factor": 0.9985570193,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2623487865,
      "Direction stability factor": 0.4331866926,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2709729831, 0.9880212062, 1.3864716603],
      direction: [-91.3565871344, -17.1108402531, 2.2924747561],
      "speed factor": 0.8772399719,
      "Uz factor": 0.9999998568,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2786366222,
      "Direction stability factor": 0.739863717,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1904330686, 0.450703367, 0.8711707828],
      direction: [-48.5332731812, -8.6669322351, 63.2656637717],
      "speed factor": 0.6981695616,
      "Uz factor": 0.987725167,
      "Directional variance factor": 0.2296060236,
      "Velocity stability factor": 0.391723734,
      "Direction stability factor": 0.6894473974,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5656991055, 0.9463721343, 1.1685100894],
      direction: [-42.8949154147, 2.2779665974, 49.4120862651],
      "speed factor": 1.0646025367,
      "Uz factor": 0.9781471984,
      "Directional variance factor": 0.7975140802,
      "Velocity stability factor": 0.5009815963,
      "Direction stability factor": 0.743591662,
      score: 0.6964197501,
    },
    "157.5": {
      velocity: [0.4031445826, 0.9148018021, 1.1465177092],
      direction: [-10.6197612723, 15.0251370418, 61.5414055222],
      "speed factor": 1.2996046649,
      "Uz factor": 0.9858872243,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3920758169,
      "Direction stability factor": 0.7995523145,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4574545983, 0.8892194835, 1.1885491775],
      direction: [-91.0026049183, -1.8867884741, 21.8328729097],
      "speed factor": 1.041858835,
      "Uz factor": 0.9548783173,
      "Directional variance factor": 0.832285469,
      "Velocity stability factor": 0.4378598468,
      "Direction stability factor": 0.6865681171,
      score: 0.6608203875,
    },
    "247.5": {
      velocity: [0.1356278349, 0.5797561906, 0.9807217208],
      direction: [-43.5027253475, -1.7166388078, 112.3719086213],
      "speed factor": 1.0755175979,
      "Uz factor": 0.9916247196,
      "Directional variance factor": 0.8474098838,
      "Velocity stability factor": 0.1799649648,
      "Direction stability factor": 0.5670149056,
      score: 0.540351165,
    },
    "292.5": {
      velocity: [0.0746307956, 0.3574583772, 0.8731753189],
      direction: [-150.4569950289, 3.9109006403, 166.1684578997],
      "speed factor": 0.4867691871,
      "Uz factor": 0.9996799246,
      "Directional variance factor": 0.6523643875,
      "Velocity stability factor": 0.4143955881,
      "Direction stability factor": 0.120484853,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1623648171, 0.6316119132, 1.0132979366],
      direction: [-79.5262254135, 6.8220103161, 69.8430750555],
      "speed factor": 0.8021079293,
      "Uz factor": 0.9985896944,
      "Directional variance factor": 0.393599083,
      "Velocity stability factor": 0.3146291319,
      "Direction stability factor": 0.5850852765,
      score: 0.0,
    },
    id: "IDN:679.0",
    "45": {
      velocity: [0.3166984521, 0.7438443174, 1.0933184543],
      direction: [-65.6717379268, -6.3276815968, 21.0342843483],
      "speed factor": 1.0104243897,
      "Uz factor": 0.996108293,
      "Directional variance factor": 0.4375394136,
      "Velocity stability factor": 0.3686325129,
      "Direction stability factor": 0.7591499381,
      score: 0.5811179507,
    },
    "90": {
      velocity: [0.562548941, 0.8621869964, 1.0999467769],
      direction: [-16.3719392945, 3.1982209185, 20.7710168975],
      "speed factor": 1.3976303515,
      "Uz factor": 0.9803746488,
      "Directional variance factor": 0.7157136961,
      "Velocity stability factor": 0.607776142,
      "Direction stability factor": 0.8968251217,
      score: 0.7792850204,
    },
    "135": {
      velocity: [0.7190953345, 1.1358130926, 1.2710153587],
      direction: [-10.0673589787, 8.5678564661, 22.8005448997],
      "speed factor": 0.9612957799,
      "Uz factor": 0.9744836828,
      "Directional variance factor": 0.2384127586,
      "Velocity stability factor": 0.5552568785,
      "Direction stability factor": 0.908700267,
      score: 0.0,
    },
    "180": {
      velocity: [0.4093866654, 0.7822548108, 1.0578330173],
      direction: [-23.4477108512, 12.5199404827, 54.4788741483],
      "speed factor": 1.5277388131,
      "Uz factor": 0.9700955242,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4890522353,
      "Direction stability factor": 0.7835372639,
      score: 0.0,
    },
    "225": {
      velocity: [0.182914966, 0.517731578, 0.8296689142],
      direction: [-42.9359171227, -4.1307103159, 51.521515668],
      "speed factor": 0.7067602622,
      "Uz factor": 0.9907776933,
      "Directional variance factor": 0.6328257497,
      "Velocity stability factor": 0.418710092,
      "Direction stability factor": 0.7376182422,
      score: 0.0,
    },
    "270": {
      velocity: [0.330659553, 0.8063119921, 1.2869884434],
      direction: [-49.6592946711, -1.3045966111, 40.4245258767],
      "speed factor": 0.7378015082,
      "Uz factor": 0.9992971054,
      "Directional variance factor": 0.8840358568,
      "Velocity stability factor": 0.245644707,
      "Direction stability factor": 0.7497671651,
      score: 0.0,
    },
    "315": {
      velocity: [0.070963313, 0.4836509085, 1.1032706055],
      direction: [-158.2749027901, -21.5188268935, 20.8872884563],
      "speed factor": 0.5959060513,
      "Uz factor": 0.9987093506,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1625551935,
      "Direction stability factor": 0.5023272465,
      score: 0.0,
    },
  },
  {
    position: [-54.69268291, 35.06824222, 46.87999908],
    "overall score": 0.1986329917,
    "0": {
      velocity: [0.2942870177, 1.0025073762, 1.4538265633],
      direction: [-29.2795678827, -12.2947149061, 49.4415755418],
      "speed factor": 1.0273204857,
      "Uz factor": 0.9964901284,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1943162176,
      "Direction stability factor": 0.7813301572,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0946740876, 0.6734326906, 1.3822663931],
      direction: [-94.7071052976, 2.4345275867, 123.2414771579],
      "speed factor": 0.5979244887,
      "Uz factor": 0.9996096734,
      "Directional variance factor": 0.7835975479,
      "Velocity stability factor": 0.1673482419,
      "Direction stability factor": 0.394587271,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.099136791, 0.5076974326, 1.0153503685],
      direction: [-109.6623019196, 6.702036903, 170.6549555213],
      "speed factor": 0.7864571688,
      "Uz factor": 0.9936535035,
      "Directional variance factor": 0.4042633864,
      "Velocity stability factor": 0.1813132105,
      "Direction stability factor": 0.2213409516,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2072181067, 0.7230363252, 1.1331523103],
      direction: [-129.7524290034, -24.3162254772, -4.1522862644],
      "speed factor": 0.8133653539,
      "Uz factor": 0.984494502,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2334940461,
      "Direction stability factor": 0.6511107146,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.479120675, 1.0342216986, 1.3201458013],
      direction: [-47.268604622, -12.7532509054, 1.6114884032],
      "speed factor": 1.4692574292,
      "Uz factor": 0.9957261429,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3122168469,
      "Direction stability factor": 0.8642219638,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5848831677, 0.9531749862, 1.1549189444],
      direction: [-31.6718849917, -10.4245808564, 1.4843019975],
      "speed factor": 1.116792647,
      "Uz factor": 0.9822471436,
      "Directional variance factor": 0.0733705905,
      "Velocity stability factor": 0.5616982974,
      "Direction stability factor": 0.9078994806,
      score: 0.6127169623,
    },
    "247.5": {
      velocity: [0.4826893151, 0.7797034767, 0.9526056603],
      direction: [-18.1167663283, 4.5714325195, 12.1385355944],
      "speed factor": 1.4464439085,
      "Uz factor": 0.9805507141,
      "Directional variance factor": 0.5936504427,
      "Velocity stability factor": 0.5440176847,
      "Direction stability factor": 0.9159574947,
      score: 0.7423957792,
    },
    "292.5": {
      velocity: [0.83374676, 1.1774848496, 1.374513427],
      direction: [1.4329553832, 17.9389387008, 28.6494822681],
      "speed factor": 1.6034407909,
      "Uz factor": 0.9384027568,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6034343274,
      "Direction stability factor": 0.9243985364,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3425011187, 0.8455641774, 1.1201169607],
      direction: [-24.0878737395, -2.4422740255, 28.2168461257],
      "speed factor": 1.073814026,
      "Uz factor": 0.969979512,
      "Directional variance factor": 0.7829089755,
      "Velocity stability factor": 0.3736813946,
      "Direction stability factor": 0.8547091115,
      score: 0.7165021483,
    },
    id: "IDN:461.0",
    "45": {
      velocity: [0.1008776829, 0.4801401773, 1.1049161701],
      direction: [-165.5620718406, 10.1694085791, 174.9954341828],
      "speed factor": 0.6522135537,
      "Uz factor": 0.9989460959,
      "Directional variance factor": 0.0960525707,
      "Velocity stability factor": 0.1837484808,
      "Direction stability factor": 0.0540069277,
      score: 0.0,
    },
    "90": {
      velocity: [0.1024355299, 0.7359377451, 1.1733869874],
      direction: [-169.7137598271, -3.2433145902, 31.1054280904],
      "speed factor": 1.1929766206,
      "Uz factor": 0.9808335883,
      "Directional variance factor": 0.7117053698,
      "Velocity stability factor": 0.2183580127,
      "Direction stability factor": 0.4421689225,
      score: 0.4536003068,
    },
    "135": {
      velocity: [0.1126702287, 0.5798229073, 0.9755503376],
      direction: [-107.9366425039, -36.6627062814, 65.1242576031],
      "speed factor": 0.4907333059,
      "Uz factor": 0.9967117831,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3046818809,
      "Direction stability factor": 0.5192752775,
      score: 0.0,
    },
    "180": {
      velocity: [0.5999550727, 0.8907031352, 1.1854575606],
      direction: [-50.7928516004, -13.3703692521, 11.1011494194],
      "speed factor": 1.7395377207,
      "Uz factor": 0.9948658634,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5386492861,
      "Direction stability factor": 0.8280722194,
      score: 0.0,
    },
    "225": {
      velocity: [0.1634201922, 0.5433721695, 0.8826896593],
      direction: [-44.1137776883, 0.956635371, 42.0346609517],
      "speed factor": 0.7417624756,
      "Uz factor": 0.9819029405,
      "Directional variance factor": 0.9149657448,
      "Velocity stability factor": 0.3535345496,
      "Direction stability factor": 0.7606987816,
      score: 0.0,
    },
    "270": {
      velocity: [0.6045295769, 0.9416499428, 1.2812898859],
      direction: [1.7321314876, 25.1194578398, 55.2087578905],
      "speed factor": 0.8616401032,
      "Uz factor": 0.9496175774,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4661692998,
      "Direction stability factor": 0.8514538155,
      score: 0.0,
    },
    "315": {
      velocity: [0.6770868582, 1.1477391472, 1.3427249815],
      direction: [-4.537564944, 7.0704468339, 35.0400370095],
      "speed factor": 1.4141288501,
      "Uz factor": 0.9665006947,
      "Directional variance factor": 0.371515837,
      "Velocity stability factor": 0.4600104123,
      "Direction stability factor": 0.8900622168,
      score: 0.6529126707,
    },
  },
  {
    position: [-35.48417584, 96.51135055, 47.87999908],
    "overall score": 0.1981878046,
    "0": {
      velocity: [0.4851336089, 0.9206027023, 1.221586699],
      direction: [-49.099149591, -23.9283750251, 27.9268504264],
      "speed factor": 0.9433885851,
      "Uz factor": 0.9435745048,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4882897151,
      "Direction stability factor": 0.7860388888,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7004695386, 1.1970925044, 1.4454100421],
      direction: [-20.6377740333, -0.439182731, 12.5586773505],
      "speed factor": 1.0628692869,
      "Uz factor": 0.984456521,
      "Directional variance factor": 0.960961535,
      "Velocity stability factor": 0.5182667547,
      "Direction stability factor": 0.907787635,
      score: 0.82370089,
    },
    "67.5": {
      velocity: [0.3051589215, 0.6314517757, 1.0164311961],
      direction: [-33.3141433856, -1.9849355382, 58.3919297119],
      "speed factor": 0.9781608963,
      "Uz factor": 0.9851753811,
      "Directional variance factor": 0.8235612855,
      "Velocity stability factor": 0.3644394394,
      "Direction stability factor": 0.7452609081,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2334324231, 0.8049561096, 1.2118341329],
      direction: [-169.8598391069, 25.9795925399, 134.5911798743],
      "speed factor": 0.9055193884,
      "Uz factor": 0.9938733067,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.190060446,
      "Direction stability factor": 0.1543027251,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1463151005, 0.7220551433, 1.1509529721],
      direction: [-164.2808891278, 9.0665595069, 109.0667672461],
      "speed factor": 1.0257809182,
      "Uz factor": 0.9890521091,
      "Directional variance factor": 0.1940835994,
      "Velocity stability factor": 0.1784157436,
      "Direction stability factor": 0.2407009545,
      score: 0.213475313,
    },
    "202.5": {
      velocity: [0.1785062115, 0.4995400918, 0.8335845526],
      direction: [-117.8109270485, 20.6951276265, 72.8071016944],
      "speed factor": 0.5852888604,
      "Uz factor": 0.6237393488,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4963088915,
      "Direction stability factor": 0.4705054757,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1271508302, 0.4182871984, 0.8373661694],
      direction: [-90.1137621761, -10.0011848013, 47.9468965956],
      "speed factor": 0.7759731592,
      "Uz factor": 0.9999530437,
      "Directional variance factor": 0.1110057954,
      "Velocity stability factor": 0.3108440726,
      "Direction stability factor": 0.6164981701,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1425839593, 0.6890218944, 1.1390435151],
      direction: [-52.2837175113, 0.9239294275, 40.1996517457],
      "speed factor": 0.938276031,
      "Uz factor": 0.9916121487,
      "Directional variance factor": 0.9178729398,
      "Velocity stability factor": 0.2692566348,
      "Direction stability factor": 0.7431017521,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7805681698, 1.0078439648, 1.2857038576],
      direction: [-26.2228519993, -12.4064685669, 8.1156427096],
      "speed factor": 1.2798992842,
      "Uz factor": 0.94908225,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5931463038,
      "Direction stability factor": 0.9046152925,
      score: 0.0,
    },
    id: "IDN:686.0",
    "45": {
      velocity: [0.3438958146, 0.5883652332, 0.8122343358],
      direction: [-61.0288176027, -9.5451985469, 31.1715595751],
      "speed factor": 0.7992244718,
      "Uz factor": 0.9695293852,
      "Directional variance factor": 0.1515379069,
      "Velocity stability factor": 0.619255602,
      "Direction stability factor": 0.7438878412,
      score: 0.0,
    },
    "90": {
      velocity: [0.3376583055, 0.7230739681, 1.1150488511],
      direction: [-33.168768443, 22.7756822757, 59.8335427743],
      "speed factor": 1.1721240617,
      "Uz factor": 0.993254868,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4326156553,
      "Direction stability factor": 0.7416602466,
      score: 0.0,
    },
    "135": {
      velocity: [0.5668098122, 1.0975561282, 1.2775931449],
      direction: [-7.0503571143, 7.8543954641, 48.0560505241],
      "speed factor": 0.9289169856,
      "Uz factor": 0.9842467645,
      "Directional variance factor": 0.3018315143,
      "Velocity stability factor": 0.4272431073,
      "Direction stability factor": 0.8469266454,
      score: 0.0,
    },
    "180": {
      velocity: [0.1750938687, 0.8147413714, 1.2566896006],
      direction: [-31.73051657, -1.6466689631, 96.7611950333],
      "speed factor": 1.5911848651,
      "Uz factor": 0.9836889178,
      "Directional variance factor": 0.8536294255,
      "Velocity stability factor": 0.1477492011,
      "Direction stability factor": 0.6430785789,
      score: 0.5718839461,
    },
    "225": {
      velocity: [0.3590653101, 0.7688993359, 1.1681990795],
      direction: [-38.3235184762, -3.1446933569, 20.8488865622],
      "speed factor": 1.0496317384,
      "Uz factor": 0.9999348306,
      "Directional variance factor": 0.7204717016,
      "Velocity stability factor": 0.2727662572,
      "Direction stability factor": 0.8356322082,
      score: 0.6661255938,
    },
    "270": {
      velocity: [0.4177809852, 1.0047553177, 1.340517958],
      direction: [-59.5643082344, -3.0826462038, 16.3182864866],
      "speed factor": 0.9193835589,
      "Uz factor": 0.9998148144,
      "Directional variance factor": 0.7259870041,
      "Velocity stability factor": 0.2721421192,
      "Direction stability factor": 0.7892150147,
      score: 0.0,
    },
    "315": {
      velocity: [0.789776849, 0.9505438657, 1.0957743834],
      direction: [-11.6689075416, 0.4359920928, 11.6829280856],
      "speed factor": 1.1711646389,
      "Uz factor": 0.9506039767,
      "Directional variance factor": 0.9612451473,
      "Velocity stability factor": 0.7517637938,
      "Direction stability factor": 0.9351337899,
      score: 0.8958191302,
    },
  },
  {
    position: [-53.82729637, 55.863901, 46.87999908],
    "overall score": 0.1978135834,
    "0": {
      velocity: [0.3120876461, 1.0451100496, 1.3363556289],
      direction: [-25.0131795444, -5.9074203683, 43.593746883],
      "speed factor": 1.0709776199,
      "Uz factor": 0.9878777297,
      "Directional variance factor": 0.4748959673,
      "Velocity stability factor": 0.2883070649,
      "Direction stability factor": 0.8094252044,
      score: 0.5955133602,
    },
    "22.5": {
      velocity: [0.1378803666, 0.6618465061, 1.1324253844],
      direction: [-129.0898099466, 5.8351012592, 141.7371803294],
      "speed factor": 0.5876373976,
      "Uz factor": 0.9970047619,
      "Directional variance factor": 0.4813243325,
      "Velocity stability factor": 0.3568541423,
      "Direction stability factor": 0.2477028048,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0881153891, 0.4958118574, 1.0228186216],
      direction: [-59.4379829131, 21.6601412446, 172.3207905921],
      "speed factor": 0.7680456205,
      "Uz factor": 0.9836801258,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1647916955,
      "Direction stability factor": 0.3562256292,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1238334462, 0.6791482745, 1.2602140479],
      direction: [-73.4562516241, -6.6367407843, 93.087081336],
      "speed factor": 0.7639943629,
      "Uz factor": 0.9654131591,
      "Directional variance factor": 0.4100674858,
      "Velocity stability factor": 0.059282513,
      "Direction stability factor": 0.5373796307,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1206980699, 0.7165749908, 1.2101987811],
      direction: [-79.0039215189, -11.3743562022, 97.2783274053],
      "speed factor": 1.0179955906,
      "Uz factor": 0.9989062946,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1090156394,
      "Direction stability factor": 0.5103270863,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3241904203, 0.859808428, 1.1993267499],
      direction: [-53.4076759566, -7.3870889896, 22.1851720948],
      "speed factor": 1.0073992122,
      "Uz factor": 0.9978985243,
      "Directional variance factor": 0.3433698676,
      "Velocity stability factor": 0.3271058433,
      "Direction stability factor": 0.7900198665,
      score: 0.562628861,
    },
    "247.5": {
      velocity: [0.5209655548, 0.72866299, 0.9221950758],
      direction: [-33.9480418371, -12.2143205332, 9.452936644],
      "speed factor": 1.3517576549,
      "Uz factor": 0.9883840323,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6106677969,
      "Direction stability factor": 0.8794417264,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.6584005308, 1.0849159601, 1.2736461024],
      direction: [-29.171628266, -6.1290822679, 10.8775769992],
      "speed factor": 1.4773850429,
      "Uz factor": 0.928522178,
      "Directional variance factor": 0.4551926873,
      "Velocity stability factor": 0.5488159888,
      "Direction stability factor": 0.8887522076,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6385529328, 0.8944326741, 1.0971928841],
      direction: [-24.3170084155, -3.7114586395, 9.9628111135],
      "speed factor": 1.1358739837,
      "Uz factor": 0.9584135796,
      "Directional variance factor": 0.6700925654,
      "Velocity stability factor": 0.6305955728,
      "Direction stability factor": 0.9047782791,
      score: 0.7775611741,
    },
    id: "IDN:470.0",
    "45": {
      velocity: [0.0279982617, 0.5076632735, 0.9938702755],
      direction: [-95.1930644855, 17.3407272714, 143.0246933873],
      "speed factor": 0.6896004194,
      "Uz factor": 0.9994767724,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.214776616,
      "Direction stability factor": 0.3382840059,
      score: 0.0,
    },
    "90": {
      velocity: [0.1866369514, 0.8555094651, 1.2035343005],
      direction: [-13.5237821846, 5.7725442836, 98.8620550943],
      "speed factor": 1.3868058778,
      "Uz factor": 0.9718088122,
      "Directional variance factor": 0.4868849526,
      "Velocity stability factor": 0.2578098108,
      "Direction stability factor": 0.6878171187,
      score: 0.5300822502,
    },
    "135": {
      velocity: [0.1138580293, 0.4701043817, 0.95606202],
      direction: [-161.8788957428, -25.9145583848, 134.0191484109],
      "speed factor": 0.3978729962,
      "Uz factor": 0.9656764888,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3213429205,
      "Direction stability factor": 0.1780609885,
      score: 0.0,
    },
    "180": {
      velocity: [0.1646976849, 0.55174416, 0.9382888561],
      direction: [-76.8328196381, -22.0215079473, 22.118686266],
      "speed factor": 1.077552936,
      "Uz factor": 0.9925038056,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.390443514,
      "Direction stability factor": 0.7251347058,
      score: 0.0,
    },
    "225": {
      velocity: [0.2065170794, 0.5584748408, 0.8365817246],
      direction: [-76.538013772, -3.9061844977, 40.0830145327],
      "speed factor": 0.7623792746,
      "Uz factor": 0.9837841222,
      "Directional variance factor": 0.6527836002,
      "Velocity stability factor": 0.4337101138,
      "Direction stability factor": 0.6760526992,
      score: 0.0,
    },
    "270": {
      velocity: [0.7587671373, 1.0352416667, 1.406768831],
      direction: [-18.0417179866, -0.499848433, 21.4606205039],
      "speed factor": 0.9472795525,
      "Uz factor": 0.9319429819,
      "Directional variance factor": 0.9555690282,
      "Velocity stability factor": 0.4888541877,
      "Direction stability factor": 0.890271282,
      score: 0.0,
    },
    "315": {
      velocity: [0.6072253747, 1.0118908118, 1.2019521109],
      direction: [-17.6328037881, -6.2244054155, 12.4864244578],
      "speed factor": 1.2467501816,
      "Uz factor": 0.956268421,
      "Directional variance factor": 0.4467195186,
      "Velocity stability factor": 0.5175362801,
      "Direction stability factor": 0.9163354771,
      score: 0.6992316882,
    },
  },
  {
    position: [-35.48357818, 96.68256738, 47.87999908],
    "overall score": 0.1977566435,
    "0": {
      velocity: [0.4631050954, 0.9101019712, 1.211904597],
      direction: [-49.178603294, -23.8014993005, 28.2087698647],
      "speed factor": 0.9326279499,
      "Uz factor": 0.9418884439,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4797110482,
      "Direction stability factor": 0.7850350746,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6927518486, 1.1915637727, 1.4420860305],
      direction: [-20.3124160654, -0.3960238819, 12.7046974988],
      "speed factor": 1.0579604606,
      "Uz factor": 0.9842294597,
      "Directional variance factor": 0.9647978772,
      "Velocity stability factor": 0.5154254795,
      "Direction stability factor": 0.9082857957,
      score: 0.824198737,
    },
    "67.5": {
      velocity: [0.3040254229, 0.6314782741, 1.0175135666],
      direction: [-32.9925078051, -1.863650198, 58.2649091173],
      "speed factor": 0.9782019442,
      "Uz factor": 0.9854214664,
      "Directional variance factor": 0.8343422046,
      "Velocity stability factor": 0.3624594395,
      "Direction stability factor": 0.7465071752,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2458367057, 0.8058711649, 1.2114829321],
      direction: [-166.4142779275, 25.9231195935, 136.0903711513],
      "speed factor": 0.9065487616,
      "Uz factor": 0.9942650602,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.200619678,
      "Direction stability factor": 0.1597093081,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1628664481, 0.7186901545, 1.1494979297],
      direction: [-158.0531470769, 9.0300803106, 106.8907894964],
      "speed factor": 1.0210004782,
      "Uz factor": 0.9890480137,
      "Directional variance factor": 0.1973261946,
      "Velocity stability factor": 0.1931412153,
      "Direction stability factor": 0.2640446206,
      score: 0.2296391628,
    },
    "202.5": {
      velocity: [0.1545664786, 0.4985696399, 0.8277385319],
      direction: [-104.0313419651, 18.9309514069, 71.1655943605],
      "speed factor": 0.5841518251,
      "Uz factor": 0.6382568568,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4823965983,
      "Direction stability factor": 0.5133418435,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1205613832, 0.4177774072, 0.8390473768],
      direction: [-89.7161589744, -9.8482171461, 47.1735902122],
      "speed factor": 0.7750274351,
      "Uz factor": 0.9999635203,
      "Directional variance factor": 0.1246029203,
      "Velocity stability factor": 0.3028186609,
      "Direction stability factor": 0.6197506967,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1431930522, 0.6892351591, 1.136322846],
      direction: [-54.4518440915, 0.8446016529, 39.8457899836],
      "speed factor": 0.9385664443,
      "Uz factor": 0.9899403871,
      "Directional variance factor": 0.9249242975,
      "Velocity stability factor": 0.2716984815,
      "Direction stability factor": 0.7380621276,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7761862024, 1.0059451867, 1.2792806481],
      direction: [-26.1131818224, -12.571726325, 7.8574700571],
      "speed factor": 1.2774879539,
      "Uz factor": 0.9496391382,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5947903906,
      "Direction stability factor": 0.9056370781,
      score: 0.0,
    },
    id: "IDN:687.0",
    "45": {
      velocity: [0.3423498985, 0.5859562297, 0.8017480688],
      direction: [-60.0083609927, -9.3004323309, 31.3135330693],
      "speed factor": 0.7959521259,
      "Uz factor": 0.968837395,
      "Directional variance factor": 0.1732949039,
      "Velocity stability factor": 0.6265238244,
      "Direction stability factor": 0.746328072,
      score: 0.0,
    },
    "90": {
      velocity: [0.3427666557, 0.7217351821, 1.1185548195],
      direction: [-33.0722859886, 22.7548897244, 58.8581166598],
      "speed factor": 1.1699538504,
      "Uz factor": 0.9933249745,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4337851657,
      "Direction stability factor": 0.7446377704,
      score: 0.0,
    },
    "135": {
      velocity: [0.5482150058, 1.0982904363, 1.2795851032],
      direction: [-6.7160415574, 7.9263713771, 47.5767948116],
      "speed factor": 0.9295384675,
      "Uz factor": 0.984582449,
      "Directional variance factor": 0.2954336554,
      "Velocity stability factor": 0.4106540698,
      "Direction stability factor": 0.8491865656,
      score: 0.0,
    },
    "180": {
      velocity: [0.1729835562, 0.8083228844, 1.2533341735],
      direction: [-31.8672053996, -1.6311655174, 111.7460241954],
      "speed factor": 1.5786495996,
      "Uz factor": 0.9841819403,
      "Directional variance factor": 0.8550075096,
      "Velocity stability factor": 0.1487302976,
      "Direction stability factor": 0.6010743622,
      score: 0.5514716329,
    },
    "225": {
      velocity: [0.3657235163, 0.7718816953, 1.1861471512],
      direction: [-38.8785313044, -3.1632226899, 23.9092318852],
      "speed factor": 1.0537029853,
      "Uz factor": 0.999901104,
      "Directional variance factor": 0.7188246498,
      "Velocity stability factor": 0.262619145,
      "Direction stability factor": 0.8255895467,
      score: 0.6581557221,
    },
    "270": {
      velocity: [0.4144323565, 1.0142761643, 1.3419629868],
      direction: [-57.7701890275, -2.9781046471, 15.8164042585],
      "speed factor": 0.928095441,
      "Uz factor": 0.9998721822,
      "Directional variance factor": 0.7352795869,
      "Velocity stability factor": 0.2683608668,
      "Direction stability factor": 0.7955927964,
      score: 0.0,
    },
    "315": {
      velocity: [0.7888312161, 0.9489103163, 1.0942208771],
      direction: [-12.322334634, 0.1930490726, 11.5335761402],
      "speed factor": 1.1691519434,
      "Uz factor": 0.9508978776,
      "Directional variance factor": 0.9828400824,
      "Velocity stability factor": 0.7522569226,
      "Direction stability factor": 0.9337335812,
      score: 0.9006410418,
    },
  },
  {
    position: [-50.4827199, 95.04948189, 46.87999908],
    "overall score": 0.1976149946,
    "0": {
      velocity: [0.6982781761, 1.1639367474, 1.3541746106],
      direction: [-15.9292921634, 1.2954391815, 33.278359292],
      "speed factor": 1.1927454032,
      "Uz factor": 0.9520164139,
      "Directional variance factor": 0.8848498505,
      "Velocity stability factor": 0.5442629601,
      "Direction stability factor": 0.8633120793,
      score: 0.7889342423,
    },
    "22.5": {
      velocity: [0.3504180996, 1.06350062, 1.580134061],
      direction: [-38.5056463855, 21.7289726917, 49.1083618571],
      "speed factor": 0.9442563055,
      "Uz factor": 0.953455365,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.204775337,
      "Direction stability factor": 0.7566277549,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.20818398, 0.6914176652, 1.2188053549],
      direction: [-27.4485894494, -3.868955021, 38.6328455397],
      "speed factor": 1.0710520569,
      "Uz factor": 0.9936803914,
      "Directional variance factor": 0.656092887,
      "Velocity stability factor": 0.0969546958,
      "Direction stability factor": 0.8164404584,
      score: 0.5964821249,
    },
    "112.5": {
      velocity: [0.0625685894, 0.3441584513, 0.7923141459],
      direction: [-175.7194976702, 71.4598520165, 172.8986984928],
      "speed factor": 0.3871542145,
      "Uz factor": 0.9937219577,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3959027416,
      "Direction stability factor": 0.0316161218,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0314080683, 0.3536416943, 0.9607474442],
      direction: [-179.3059246454, -1.8157343715, 166.2600241733],
      "speed factor": 0.5023977812,
      "Uz factor": 0.9955316325,
      "Directional variance factor": 0.8386013892,
      "Velocity stability factor": 0.2399942092,
      "Direction stability factor": 0.0400945866,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5457077317, 1.0775771543, 1.5304972682],
      direction: [-42.5616042298, -10.5004294406, 20.3775295657],
      "speed factor": 1.2625491226,
      "Uz factor": 0.9980702117,
      "Directional variance factor": 0.0666284942,
      "Velocity stability factor": 0.2427932629,
      "Direction stability factor": 0.8251690728,
      score: 0.4899399757,
    },
    "247.5": {
      velocity: [0.1681881579, 0.454013188, 0.8529515898],
      direction: [-102.0957128101, -5.9602637973, 29.9903259154],
      "speed factor": 0.8422491753,
      "Uz factor": 0.9707354217,
      "Directional variance factor": 0.4701987736,
      "Velocity stability factor": 0.335541276,
      "Direction stability factor": 0.6330943369,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3869435225, 0.7660983383, 1.0883705228],
      direction: [-58.949039115, -23.1540571451, 31.3538007954],
      "speed factor": 1.0432349308,
      "Uz factor": 0.9540767095,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4856157245,
      "Direction stability factor": 0.749158778,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7035215447, 0.9355910144, 1.1081843756],
      direction: [-2.753381031, 8.9114678824, 30.3396870154],
      "speed factor": 1.1881425216,
      "Uz factor": 0.9106008758,
      "Directional variance factor": 0.2078695216,
      "Velocity stability factor": 0.6740706063,
      "Direction stability factor": 0.908074811,
      score: 0.0,
    },
    id: "IDN:431.0",
    "45": {
      velocity: [0.2020389842, 0.7418824672, 1.290612844],
      direction: [-29.6707838303, -0.4304339979, 53.4820944763],
      "speed factor": 1.0077594485,
      "Uz factor": 0.9778982701,
      "Directional variance factor": 0.9617392002,
      "Velocity stability factor": 0.1150238979,
      "Direction stability factor": 0.7690197825,
      score: 0.6537006658,
    },
    "90": {
      velocity: [0.0950307873, 0.4946020114, 1.08938034],
      direction: [-117.2215326706, 21.6910943718, 168.2566697156],
      "speed factor": 0.8017643342,
      "Uz factor": 0.9921168927,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2742664898,
      "Direction stability factor": 0.2070049934,
      score: 0.0,
    },
    "135": {
      velocity: [0.0526209584, 0.3367285968, 0.665689063],
      direction: [-179.464863561, 100.3579118804, 179.1087953661],
      "speed factor": 0.2849903573,
      "Uz factor": 0.9894244691,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5059830944,
      "Direction stability factor": 0.0039620585,
      score: 0.0,
    },
    "180": {
      velocity: [0.057430021, 0.5011855027, 1.2834668753],
      direction: [-153.2484850021, -29.0620110003, 139.8567872468],
      "speed factor": 0.9788121907,
      "Uz factor": 0.9870630906,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0339358249,
      "Direction stability factor": 0.1858186882,
      score: 0.0,
    },
    "225": {
      velocity: [0.3320463974, 0.9026549128, 1.1808548684],
      direction: [-50.1270968518, -4.0980839319, 11.3792012366],
      "speed factor": 1.2322227384,
      "Uz factor": 0.992906928,
      "Directional variance factor": 0.6357258727,
      "Velocity stability factor": 0.2371074047,
      "Direction stability factor": 0.829149172,
      score: 0.6327829053,
    },
    "270": {
      velocity: [0.7741886109, 1.0986999662, 1.3521429191],
      direction: [-28.2039646062, -11.5019697353, 4.6867188029],
      "speed factor": 1.0053459457,
      "Uz factor": 0.9773980511,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5441077897,
      "Direction stability factor": 0.9086369905,
      score: 0.0,
    },
    "315": {
      velocity: [0.6900163105, 0.8273531802, 0.9742973787],
      direction: [-11.7811696729, 7.6971656914, 28.1017114049],
      "speed factor": 1.0193814546,
      "Uz factor": 0.9247155662,
      "Directional variance factor": 0.3158074941,
      "Velocity stability factor": 0.7693809723,
      "Direction stability factor": 0.8892142192,
      score: 0.0,
    },
  },
  {
    position: [-5.258533199, 95.21003953, 47.87999908],
    "overall score": 0.1973147085,
    "0": {
      velocity: [0.2611078694, 1.0718545386, 1.5123573883],
      direction: [-178.5042666886, 5.043583415, 129.8631494466],
      "speed factor": 1.0983840631,
      "Uz factor": 0.9912558249,
      "Directional variance factor": 0.5516814742,
      "Velocity stability factor": 0.1305933041,
      "Direction stability factor": 0.1434238441,
      score: 0.2422806166,
    },
    "22.5": {
      velocity: [0.1207260164, 0.799838402, 1.2955073345],
      direction: [-44.6970668672, -3.2186270057, 63.828456529],
      "speed factor": 0.710157042,
      "Uz factor": 0.9896121642,
      "Directional variance factor": 0.7138998217,
      "Velocity stability factor": 0.2403001123,
      "Direction stability factor": 0.6985402128,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1024927437, 0.4773738883, 0.9327837048],
      direction: [-41.3823963925, 8.5251226727, 82.4111129247],
      "speed factor": 0.7394839771,
      "Uz factor": 0.9995889219,
      "Directional variance factor": 0.242211318,
      "Velocity stability factor": 0.2580897533,
      "Direction stability factor": 0.6561291408,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2416789841, 0.8848103449, 1.2421830094],
      direction: [-7.6409495863, 15.022111118, 47.6555314006],
      "speed factor": 0.9953498244,
      "Uz factor": 0.9700255227,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1717637286,
      "Direction stability factor": 0.8463986639,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4042835076, 0.8247640641, 1.150000937],
      direction: [-27.3781349673, -1.0575958792, 45.3360717259],
      "speed factor": 1.1716933905,
      "Uz factor": 0.9741033553,
      "Directional variance factor": 0.9059914774,
      "Velocity stability factor": 0.3901586661,
      "Direction stability factor": 0.7980160925,
      score: 0.7230455821,
    },
    "202.5": {
      velocity: [0.1477414952, 0.8384554883, 1.1838794373],
      direction: [-64.4325377262, -26.3150096297, 134.0150226259],
      "speed factor": 0.9823809244,
      "Uz factor": 0.959852762,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2033113662,
      "Direction stability factor": 0.4487567768,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2153171462, 0.5121762761, 0.8630326267],
      direction: [-66.3494858082, -3.0867064181, 35.2439641564],
      "speed factor": 0.9501487127,
      "Uz factor": 0.9916415662,
      "Directional variance factor": 0.7256260962,
      "Velocity stability factor": 0.3714906761,
      "Direction stability factor": 0.7177959723,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0463464691, 0.4798545617, 0.9442076279],
      direction: [-172.3043169283, 19.9713565026, 153.5322682202],
      "speed factor": 0.6534422741,
      "Uz factor": 0.9480467492,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.341562755,
      "Direction stability factor": 0.0948983746,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2248910491, 0.6214766239, 1.3293933904],
      direction: [-66.3166920609, -21.4983554991, 81.7086038543],
      "speed factor": 0.7892367409,
      "Uz factor": 0.8773868009,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1103957396,
      "Direction stability factor": 0.5888186225,
      score: 0.0,
    },
    id: "IDN:672.0",
    "45": {
      velocity: [0.0884431536, 0.5424070641, 1.1065724656],
      direction: [-162.4025767774, 0.375379501, 72.8501888848],
      "speed factor": 0.7367957433,
      "Uz factor": 0.998382729,
      "Directional variance factor": 0.9666329332,
      "Velocity stability factor": 0.1722930862,
      "Direction stability factor": 0.3465200954,
      score: 0.0,
    },
    "90": {
      velocity: [0.2970607497, 0.7963480832, 1.1403333778],
      direction: [-10.3177791389, 21.4417444788, 63.060323855],
      "speed factor": 1.2909035465,
      "Uz factor": 0.9942557842,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3845311211,
      "Direction stability factor": 0.7961719361,
      score: 0.0,
    },
    "135": {
      velocity: [0.8434985967, 1.1942222673, 1.3669907815],
      direction: [-2.4710612377, 5.11924312, 21.6815296218],
      "speed factor": 1.0107304038,
      "Uz factor": 0.9539472333,
      "Directional variance factor": 0.5449561671,
      "Velocity stability factor": 0.5781643388,
      "Direction stability factor": 0.9329094698,
      score: 0.7472348614,
    },
    "180": {
      velocity: [0.2816719928, 0.8461176167, 1.2523794313],
      direction: [-44.993435544, -4.4878335174, 28.5051116396],
      "speed factor": 1.6524624784,
      "Uz factor": 0.9757118327,
      "Directional variance factor": 0.6010814651,
      "Velocity stability factor": 0.2351243949,
      "Direction stability factor": 0.7958373689,
      score: 0.6069701495,
    },
    "225": {
      velocity: [0.2473733487, 0.523633543, 0.7958835806],
      direction: [-87.3416918443, -15.4241732545, 26.495479688],
      "speed factor": 0.7148170903,
      "Uz factor": 0.9888516537,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5070096393,
      "Direction stability factor": 0.6837856346,
      score: 0.0,
    },
    "270": {
      velocity: [0.4041405083, 0.8345550326, 1.340469648],
      direction: [-39.0919925659, 7.8073986287, 36.9917598359],
      "speed factor": 0.7636448023,
      "Uz factor": 0.9942960181,
      "Directional variance factor": 0.3060090108,
      "Velocity stability factor": 0.2614205744,
      "Direction stability factor": 0.7886562433,
      score: 0.0,
    },
    "315": {
      velocity: [0.7072506664, 0.920740015, 1.1515682643],
      direction: [-12.1690338176, 1.2352315659, 20.1839347554],
      "speed factor": 1.1344433287,
      "Uz factor": 0.9583499338,
      "Directional variance factor": 0.8902016386,
      "Velocity stability factor": 0.639553583,
      "Direction stability factor": 0.9101306429,
      score: 0.8375041268,
    },
  },
  {
    position: [-35.61091946, 97.1184151, 47.87999908],
    "overall score": 0.1952568694,
    "0": {
      velocity: [0.420498877, 0.8877756498, 1.2025944334],
      direction: [-49.3886061531, -23.6431250628, 28.6787362698],
      "speed factor": 0.9097490287,
      "Uz factor": 0.9364384923,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4565759241,
      "Direction stability factor": 0.783146271,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6780936555, 1.1763100392, 1.4346498835],
      direction: [-19.6966989289, -0.1392831965, 13.3444356375],
      "speed factor": 1.044417042,
      "Uz factor": 0.9835513996,
      "Directional variance factor": 0.9876192714,
      "Velocity stability factor": 0.5107551741,
      "Direction stability factor": 0.9082190706,
      score: 0.8287031467,
    },
    "67.5": {
      velocity: [0.3100362583, 0.6331250788, 1.0248498918],
      direction: [-32.0240484677, -1.6119349242, 58.2440015576],
      "speed factor": 0.9807529544,
      "Uz factor": 0.9862874248,
      "Directional variance factor": 0.8567168956,
      "Velocity stability factor": 0.3612750421,
      "Direction stability factor": 0.7492554166,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2364149141, 0.8052582073, 1.2094272446],
      direction: [-168.8728147988, 25.8351631323, 138.8598286459],
      "speed factor": 0.9058592272,
      "Uz factor": 0.99531058,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1945218768,
      "Direction stability factor": 0.1451871015,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1272626775, 0.7091539466, 1.1510484916],
      direction: [-142.0895334408, 8.863374899, 104.1710883405],
      "speed factor": 1.0074529533,
      "Uz factor": 0.9890823042,
      "Directional variance factor": 0.2121444534,
      "Velocity stability factor": 0.1627567201,
      "Direction stability factor": 0.3159427173,
      score: 0.251696652,
    },
    "202.5": {
      velocity: [0.1134980887, 0.4974101214, 0.8625152861],
      direction: [-85.4226519841, 15.5189678178, 75.0691713391],
      "speed factor": 0.582793269,
      "Uz factor": 0.6580264874,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4240791081,
      "Direction stability factor": 0.5541893797,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.107408265, 0.4132439111, 0.8456835754],
      direction: [-92.3739684697, -9.5639127604, 46.8897876287],
      "speed factor": 0.7666172537,
      "Uz factor": 0.9999939396,
      "Directional variance factor": 0.1498744213,
      "Velocity stability factor": 0.2836161398,
      "Direction stability factor": 0.6131562331,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1893575969, 0.6901190241, 1.1302268716],
      direction: [-56.7170138429, 0.3454155007, 37.3310406441],
      "speed factor": 0.9397700482,
      "Uz factor": 0.9849779335,
      "Directional variance factor": 0.9692963999,
      "Velocity stability factor": 0.3100231956,
      "Direction stability factor": 0.7387554042,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7706058681, 1.0001905573, 1.2565818169],
      direction: [-25.8511087607, -12.9202789493, 7.2388940423],
      "speed factor": 1.2701799317,
      "Uz factor": 0.9499752958,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6085782181,
      "Direction stability factor": 0.9080833255,
      score: 0.0,
    },
    id: "IDN:688.0",
    "45": {
      velocity: [0.3391701884, 0.5827562182, 0.7931499913],
      direction: [-57.1195199989, -8.7913926323, 31.9802973412],
      "speed factor": 0.7916052893,
      "Uz factor": 0.9671652076,
      "Directional variance factor": 0.2185428771,
      "Velocity stability factor": 0.6309287856,
      "Direction stability factor": 0.7525005074,
      score: 0.0,
    },
    "90": {
      velocity: [0.3354524727, 0.7209533652, 1.1271047053],
      direction: [-28.8397070176, 22.6159477102, 61.0391476635],
      "speed factor": 1.1686865023,
      "Uz factor": 0.9940283042,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4222066555,
      "Direction stability factor": 0.7503365148,
      score: 0.0,
    },
    "135": {
      velocity: [0.4732237533, 1.0992694208, 1.2834036967],
      direction: [-6.1215537397, 8.1033520931, 45.989872499],
      "speed factor": 0.9303670314,
      "Uz factor": 0.9854326677,
      "Directional variance factor": 0.2797020362,
      "Velocity stability factor": 0.347148244,
      "Direction stability factor": 0.8552460382,
      score: 0.0,
    },
    "180": {
      velocity: [0.1798247278, 0.7806726175, 1.2377604229],
      direction: [-32.8551433516, -1.6514676507, 143.3711068319],
      "speed factor": 1.5246487992,
      "Uz factor": 0.9852078303,
      "Directional variance factor": 0.8532028755,
      "Velocity stability factor": 0.1663922898,
      "Direction stability factor": 0.5104826384,
      score: 0.5101401105,
    },
    "225": {
      velocity: [0.392662678, 0.7830101639, 1.2181377739],
      direction: [-41.9168096187, -3.2974122354, 32.3675630134],
      "speed factor": 1.0688945628,
      "Uz factor": 0.9998004929,
      "Directional variance factor": 0.7068966902,
      "Velocity stability factor": 0.2580789898,
      "Direction stability factor": 0.7936545205,
      score: 0.6380711802,
    },
    "270": {
      velocity: [0.41604182, 1.0349602303, 1.3441727949],
      direction: [-52.7137738269, -2.8387990968, 13.3942039283],
      "speed factor": 0.9470220291,
      "Uz factor": 0.9999624388,
      "Directional variance factor": 0.7476623025,
      "Velocity stability factor": 0.267887313,
      "Direction stability factor": 0.8163667285,
      score: 0.0,
    },
    "315": {
      velocity: [0.7858358208, 0.9432365348, 1.0911148067],
      direction: [-13.9026637244, -0.3472985723, 11.2038151193],
      "speed factor": 1.1621612799,
      "Uz factor": 0.9510767043,
      "Directional variance factor": 0.9691290158,
      "Velocity stability factor": 0.7523467061,
      "Direction stability factor": 0.930259781,
      score: 0.895498821,
    },
  },
  {
    position: [-50.55130774, 35.7537903, 46.87999908],
    "overall score": 0.1941774473,
    "0": {
      velocity: [0.2299861438, 0.8827196491, 1.3461858224],
      direction: [-40.6296141673, -11.4593079048, 55.6629000579],
      "speed factor": 0.9045678866,
      "Uz factor": 0.9971358437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2244300918,
      "Direction stability factor": 0.7325207938,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1327966331, 0.6336644277, 1.2845395794],
      direction: [-128.4656826576, -2.0641936186, 92.0194770673],
      "speed factor": 0.5626152164,
      "Uz factor": 0.9990586688,
      "Directional variance factor": 0.8165161228,
      "Velocity stability factor": 0.2551984157,
      "Direction stability factor": 0.387541223,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1883434017, 0.653245049, 1.0661576136],
      direction: [-47.0693267521, 2.0966673281, 29.3630323602],
      "speed factor": 1.0119201295,
      "Uz factor": 0.9778001,
      "Directional variance factor": 0.8136295708,
      "Velocity stability factor": 0.2156251374,
      "Direction stability factor": 0.7876878914,
      score: 0.6511576227,
    },
    "112.5": {
      velocity: [0.4860591996, 0.8768802129, 1.2065029736],
      direction: [-42.1609916743, -25.3889604728, 0.5228717911],
      "speed factor": 0.9864289799,
      "Uz factor": 0.9500199707,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4036029341,
      "Direction stability factor": 0.8814337126,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5258207278, 0.925215799, 1.1992964948],
      direction: [-52.6491511481, -13.8750629677, 7.4621042725],
      "speed factor": 1.3143992127,
      "Uz factor": 0.9605201482,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4492372795,
      "Direction stability factor": 0.8330242905,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1990919353, 0.8601568703, 1.1751972981],
      direction: [-30.054118251, -4.5681449727, 25.9144282341],
      "speed factor": 1.0078074665,
      "Uz factor": 0.9710983174,
      "Directional variance factor": 0.5939426691,
      "Velocity stability factor": 0.2494705422,
      "Direction stability factor": 0.8445318153,
      score: 0.6331192105,
    },
    "247.5": {
      velocity: [0.5020114163, 0.8094979385, 1.0309796937],
      direction: [-20.7696239937, 5.4845956034, 13.9917956284],
      "speed factor": 1.5017162256,
      "Uz factor": 0.9968968609,
      "Directional variance factor": 0.5124803908,
      "Velocity stability factor": 0.4867167692,
      "Direction stability factor": 0.903440501,
      score: 0.7015195405,
    },
    "292.5": {
      velocity: [0.1147104323, 0.5276002871, 1.0991970434],
      direction: [-9.9634078429, 15.1725275429, 88.9973968378],
      "speed factor": 0.718460048,
      "Uz factor": 0.9616196415,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2780368706,
      "Direction stability factor": 0.7251088759,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0815145164, 0.5033150894, 1.0100154321],
      direction: [-35.8140905781, -0.4117038021, 136.7450701281],
      "speed factor": 0.6391789256,
      "Uz factor": 0.9959658621,
      "Directional variance factor": 0.9634041065,
      "Velocity stability factor": 0.252153355,
      "Direction stability factor": 0.520668998,
      score: 0.0,
    },
    id: "IDN:484.0",
    "45": {
      velocity: [0.0851860986, 0.4893459607, 0.968932007],
      direction: [-97.6327171788, 14.5443741305, 102.7055641151],
      "speed factor": 0.6647185199,
      "Uz factor": 0.9792704471,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.281542541,
      "Direction stability factor": 0.4435047742,
      score: 0.0,
    },
    "90": {
      velocity: [0.3638435369, 1.0265976754, 1.3356554915],
      direction: [-46.5502302389, -8.0317665808, 22.058687688],
      "speed factor": 1.6641448733,
      "Uz factor": 0.9673358203,
      "Directional variance factor": 0.2860651928,
      "Velocity stability factor": 0.2907157255,
      "Direction stability factor": 0.8094196724,
      score: 0.5489050658,
    },
    "135": {
      velocity: [0.1323078403, 0.607351168, 1.0118132271],
      direction: [-175.8550968402, -34.84971322, 16.3678542709],
      "speed factor": 0.5140318583,
      "Uz factor": 0.9749069152,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2912850523,
      "Direction stability factor": 0.466047358,
      score: 0.0,
    },
    "180": {
      velocity: [0.507959564, 0.8101890309, 1.0766518764],
      direction: [-48.0393954634, -10.2010046273, 16.1464700418],
      "speed factor": 1.5822941724,
      "Uz factor": 0.9829846348,
      "Directional variance factor": 0.0932440331,
      "Velocity stability factor": 0.5518949796,
      "Direction stability factor": 0.8217059292,
      score: 0.5721377178,
    },
    "225": {
      velocity: [0.101621897, 0.5312550636, 0.9745873439],
      direction: [-40.8535122244, 4.7678241716, 42.184872115],
      "speed factor": 0.7252213,
      "Uz factor": 0.9955125478,
      "Directional variance factor": 0.576193407,
      "Velocity stability factor": 0.2153955832,
      "Direction stability factor": 0.7693378213,
      score: 0.0,
    },
    "270": {
      velocity: [0.4403362405, 0.8586646457, 1.2698285915],
      direction: [1.1666677323, 25.2056958846, 52.7974998266],
      "speed factor": 0.7857058768,
      "Uz factor": 0.98818958,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3456937756,
      "Direction stability factor": 0.856581022,
      score: 0.0,
    },
    "315": {
      velocity: [0.1656131867, 0.6076505501, 1.2326962653],
      direction: [-14.9041148905, 3.503771356, 108.3454584759],
      "speed factor": 0.74868595,
      "Uz factor": 0.9777123693,
      "Directional variance factor": 0.6885536572,
      "Velocity stability factor": 0.134343825,
      "Direction stability factor": 0.657640074,
      score: 0.0,
    },
  },
  {
    position: [-53.79376876, 56.83523722, 46.87999908],
    "overall score": 0.1932331462,
    "0": {
      velocity: [0.2585639591, 1.0176931632, 1.3027540055],
      direction: [-24.8234254091, -6.2555637558, 49.8641673202],
      "speed factor": 1.0428821368,
      "Uz factor": 0.9874376096,
      "Directional variance factor": 0.4439498884,
      "Velocity stability factor": 0.2744646017,
      "Direction stability factor": 0.7925344646,
      score: 0.5758708548,
    },
    "22.5": {
      velocity: [0.156308479, 0.6514865182, 1.1369250932],
      direction: [-129.1515267714, 6.8018976592, 145.2355752644],
      "speed factor": 0.5784390166,
      "Uz factor": 0.9956367662,
      "Directional variance factor": 0.3953868747,
      "Velocity stability factor": 0.3658612712,
      "Direction stability factor": 0.2378136055,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1049889847, 0.5188791517, 0.998110723],
      direction: [-56.9567282192, 23.5304700905, 114.2763249084],
      "speed factor": 0.8037783972,
      "Uz factor": 0.9866222044,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.201947028,
      "Direction stability factor": 0.5243526302,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2444348475, 0.7676950047, 1.2802739079],
      direction: [-50.2815446252, -5.1906691566, 27.0201002112],
      "speed factor": 0.8636032484,
      "Uz factor": 0.9727069677,
      "Directional variance factor": 0.5386071861,
      "Velocity stability factor": 0.1425127141,
      "Direction stability factor": 0.7852732088,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.027563126, 0.7425439566, 1.2503687771],
      direction: [-89.1062087203, -12.1414526612, 79.5883597333],
      "speed factor": 1.05488816,
      "Uz factor": 0.9987585951,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.5314039765,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2978108049, 0.8605638205, 1.2081995203],
      direction: [-55.1935376481, -7.9229442843, 24.8226794697],
      "speed factor": 1.0082842719,
      "Uz factor": 0.9978203742,
      "Directional variance factor": 0.2957382858,
      "Velocity stability factor": 0.3000002099,
      "Direction stability factor": 0.7777327302,
      score: 0.5378009891,
    },
    "247.5": {
      velocity: [0.5177939161, 0.7171542664, 0.9125377309],
      direction: [-36.9102738151, -12.9696131904, 9.1334559502],
      "speed factor": 1.3304075859,
      "Uz factor": 0.9904714122,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.616961188,
      "Direction stability factor": 0.8721007507,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.6464399471, 1.0744799762, 1.261948707],
      direction: [-29.6375529952, -6.7622294428, 12.0377102492],
      "speed factor": 1.4631738348,
      "Uz factor": 0.9437785788,
      "Directional variance factor": 0.3989129384,
      "Velocity stability factor": 0.5486229823,
      "Direction stability factor": 0.8842353799,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6306849701, 0.8808417633, 1.0778361769],
      direction: [-24.391218998, -3.8054295076, 9.2471796343],
      "speed factor": 1.1186143706,
      "Uz factor": 0.9582128228,
      "Directional variance factor": 0.6617395993,
      "Velocity stability factor": 0.6398490037,
      "Direction stability factor": 0.9065600038,
      score: 0.7786771527,
    },
    id: "IDN:471.0",
    "45": {
      velocity: [0.0945854307, 0.510296458, 0.9601450257],
      direction: [-87.386031608, 18.3539548965, 128.9572752476],
      "speed factor": 0.6931772887,
      "Uz factor": 0.9998880162,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2963274383,
      "Direction stability factor": 0.3990463698,
      score: 0.0,
    },
    "90": {
      velocity: [0.2057669642, 0.9193411511, 1.2624182724],
      direction: [-12.1279826373, 7.8386479333, 90.3770863446],
      "speed factor": 1.4902789087,
      "Uz factor": 0.9752431336,
      "Directional variance factor": 0.3032312948,
      "Velocity stability factor": 0.2287950844,
      "Direction stability factor": 0.7152636973,
      score: 0.4906384434,
    },
    "135": {
      velocity: [0.132031751, 0.4975345237, 0.9868467574],
      direction: [-174.1022204563, -23.207358777, 143.0227970666],
      "speed factor": 0.4210885057,
      "Uz factor": 0.9636651856,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3111808277,
      "Direction stability factor": 0.1190971735,
      score: 0.0,
    },
    "180": {
      velocity: [0.1835098388, 0.5670487384, 0.9313501291],
      direction: [-79.6754955151, -22.1965120888, 29.866378995],
      "speed factor": 1.107442683,
      "Uz factor": 0.9925480371,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4107340977,
      "Direction stability factor": 0.6957170152,
      score: 0.0,
    },
    "225": {
      velocity: [0.2079886359, 0.5620643321, 0.8440745768],
      direction: [-73.7365059124, -4.6504549369, 37.201960567],
      "speed factor": 0.767279323,
      "Uz factor": 0.9847092371,
      "Directional variance factor": 0.5866262278,
      "Velocity stability factor": 0.42829829,
      "Direction stability factor": 0.6918375931,
      score: 0.0,
    },
    "270": {
      velocity: [0.7548869907, 1.035602327, 1.4003975398],
      direction: [-19.430881273, -1.8096094546, 20.9817142822],
      "speed factor": 0.9476095684,
      "Uz factor": 0.9491186492,
      "Directional variance factor": 0.8391458263,
      "Velocity stability factor": 0.4908192105,
      "Direction stability factor": 0.8877427901,
      score: 0.0,
    },
    "315": {
      velocity: [0.5880019261, 0.9986074617, 1.1824647234],
      direction: [-16.5229257012, -6.0154612252, 10.1298788243],
      "speed factor": 1.2303837723,
      "Uz factor": 0.9628341389,
      "Directional variance factor": 0.4652923355,
      "Velocity stability factor": 0.5177503968,
      "Direction stability factor": 0.9259644319,
      score: 0.708742899,
    },
  },
  {
    position: [-48.85687529, 38.74789386, 47.87999908],
    "overall score": 0.1927623643,
    "0": {
      velocity: [0.1133908207, 0.9418036114, 1.4875817879],
      direction: [-79.779689607, -11.1981646931, 83.1624067153],
      "speed factor": 0.965114239,
      "Uz factor": 0.996504677,
      "Directional variance factor": 0.0046075828,
      "Velocity stability factor": 0.0451698001,
      "Direction stability factor": 0.5473830658,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1320142675, 0.7506006537, 1.459833808],
      direction: [-79.3326932975, 2.7250684183, 90.2252018661],
      "speed factor": 0.6664400442,
      "Uz factor": 0.9993958368,
      "Directional variance factor": 0.7577716961,
      "Velocity stability factor": 0.1413343571,
      "Direction stability factor": 0.5290058468,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1453839194, 0.6723898881, 1.0697316155],
      direction: [-44.0135707583, 2.8702700456, 31.4118073777],
      "speed factor": 1.0415767616,
      "Uz factor": 0.9842122184,
      "Directional variance factor": 0.7448648848,
      "Velocity stability factor": 0.174044932,
      "Direction stability factor": 0.7904850607,
      score: 0.6249699846,
    },
    "112.5": {
      velocity: [0.5391284803, 0.8920305248, 1.2680187643],
      direction: [-52.2619349115, -21.5374290496, 3.8509854135],
      "speed factor": 1.0034720224,
      "Uz factor": 0.9646083917,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3966107524,
      "Direction stability factor": 0.8441307769,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5644078619, 0.9603257463, 1.2344270043],
      direction: [-33.7630245451, -4.3251799782, 8.618191694],
      "speed factor": 1.3642778324,
      "Uz factor": 0.9499203522,
      "Directional variance factor": 0.6155395575,
      "Velocity stability factor": 0.4520640776,
      "Direction stability factor": 0.8822743993,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1557218488, 0.8029588758, 1.1290202941],
      direction: [-29.0424900419, -0.7798254528, 23.3755635106],
      "speed factor": 0.9407911257,
      "Uz factor": 0.9739328934,
      "Directional variance factor": 0.930682182,
      "Velocity stability factor": 0.2516287869,
      "Direction stability factor": 0.8543942957,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.5065239375, 0.8515803354, 1.0706344516],
      direction: [-21.8668610514, 3.8902943571, 12.0768525615],
      "speed factor": 1.5797841431,
      "Uz factor": 0.9976922349,
      "Directional variance factor": 0.6541960571,
      "Velocity stability factor": 0.4526165755,
      "Direction stability factor": 0.9057119066,
      score: 0.7295591115,
    },
    "292.5": {
      velocity: [0.1078720328, 0.6688415179, 1.0620296312],
      direction: [-23.3341774391, 7.8000464641, 93.8542553163],
      "speed factor": 0.9107953897,
      "Uz factor": 0.9691855149,
      "Directional variance factor": 0.3066625365,
      "Velocity stability factor": 0.3002783401,
      "Direction stability factor": 0.6744765757,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1251195812, 0.6073128673, 1.2033783302],
      direction: [-80.9775915749, -6.7305962627, 119.5306124104],
      "speed factor": 0.771249649,
      "Uz factor": 0.9997610778,
      "Directional variance factor": 0.4017247766,
      "Velocity stability factor": 0.1315332335,
      "Direction stability factor": 0.4430327667,
      score: 0.0,
    },
    id: "IDN:748.0",
    "45": {
      velocity: [0.1839605841, 0.5350136808, 1.0822311142],
      direction: [-162.8486535248, 7.6361737487, 92.272702615],
      "speed factor": 0.7267527078,
      "Uz factor": 0.9926866889,
      "Directional variance factor": 0.3212290001,
      "Velocity stability factor": 0.2697344831,
      "Direction stability factor": 0.2913295663,
      score: 0.0,
    },
    "90": {
      velocity: [0.4033221868, 1.0791486983, 1.3710831275],
      direction: [-68.9723659235, -8.1395811098, 23.9188538761],
      "speed factor": 1.7493316192,
      "Uz factor": 0.9672523403,
      "Directional variance factor": 0.2764816791,
      "Velocity stability factor": 0.2936723886,
      "Direction stability factor": 0.7419688339,
      score: 0.5135229339,
    },
    "135": {
      velocity: [0.1751978686, 0.6397094608, 1.0403549451],
      direction: [-105.7668982303, -29.7599749424, 17.3324540617],
      "speed factor": 0.5414183099,
      "Uz factor": 0.9796281411,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3028470758,
      "Direction stability factor": 0.6580573547,
      score: 0.0,
    },
    "180": {
      velocity: [0.4977509905, 0.8403495386, 1.1032442948],
      direction: [-32.0252575203, -5.9802554024, 31.6089193794],
      "speed factor": 1.6411974575,
      "Uz factor": 0.9698757337,
      "Directional variance factor": 0.468421742,
      "Velocity stability factor": 0.5228973848,
      "Direction stability factor": 0.8232383975,
      score: 0.6594489805,
    },
    "225": {
      velocity: [0.1323224101, 0.5425729314, 0.994281972],
      direction: [-44.9018813775, 4.2257102533, 43.6744406018],
      "speed factor": 0.7406714281,
      "Uz factor": 0.9973871927,
      "Directional variance factor": 0.6243813108,
      "Velocity stability factor": 0.2252874593,
      "Direction stability factor": 0.7539546612,
      score: 0.0,
    },
    "270": {
      velocity: [0.1264239278, 0.7648221886, 1.2860144779],
      direction: [-2.1933722085, 17.4460294565, 65.7378548778],
      "speed factor": 0.6998369984,
      "Uz factor": 0.9879913888,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0853112584,
      "Direction stability factor": 0.811302147,
      score: 0.0,
    },
    "315": {
      velocity: [0.2006115285, 0.8324967039, 1.2611801363],
      direction: [-36.7787889491, -3.555007302, 70.6525329744],
      "speed factor": 1.0257187877,
      "Uz factor": 0.9857245783,
      "Directional variance factor": 0.6839993509,
      "Velocity stability factor": 0.1396285982,
      "Direction stability factor": 0.7015796613,
      score: 0.5566968179,
    },
  },
  {
    position: [-50.05926353, 53.82218301, 47.87999908],
    "overall score": 0.1894269864,
    "0": {
      velocity: [0.353478726, 1.0340853475, 1.5062693015],
      direction: [-41.825306345, -14.0018065932, 28.4825476937],
      "speed factor": 1.0596800448,
      "Uz factor": 0.9921486201,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1990056099,
      "Direction stability factor": 0.8047004054,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0975116081, 0.7049543317, 1.199865785],
      direction: [-84.4517517477, 4.525490465, 110.1849648186],
      "speed factor": 0.6259117864,
      "Uz factor": 0.996744513,
      "Directional variance factor": 0.5977341809,
      "Velocity stability factor": 0.2871368215,
      "Direction stability factor": 0.459342454,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.213981945, 0.557775135, 0.9418865314],
      direction: [-60.9292589062, 16.0534320923, 48.5451546254],
      "speed factor": 0.8640308682,
      "Uz factor": 0.9629314932,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3495775619,
      "Direction stability factor": 0.6959044069,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6317525592, 0.9316525147, 1.2317428939],
      direction: [-25.4308171971, -7.4358934192, 11.1438174695],
      "speed factor": 1.048043993,
      "Uz factor": 0.9413495927,
      "Directional variance factor": 0.3390316961,
      "Velocity stability factor": 0.5033165833,
      "Direction stability factor": 0.8984037926,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3638555449, 0.9612504162, 1.3109939375],
      direction: [-50.556159812, -9.957295241, 25.856185911],
      "speed factor": 1.3655914561,
      "Uz factor": 0.9978437623,
      "Directional variance factor": 0.1149070897,
      "Velocity stability factor": 0.2254383255,
      "Direction stability factor": 0.7877434841,
      score: 0.4789580959,
    },
    "202.5": {
      velocity: [0.0260523141, 0.7112996227, 1.1346320275],
      direction: [-87.679754052, -8.3453232925, 31.9050550271],
      "speed factor": 0.8333980643,
      "Uz factor": 0.999960012,
      "Directional variance factor": 0.2581934851,
      "Velocity stability factor": 0.1476107366,
      "Direction stability factor": 0.6678199748,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4062468336, 0.800685828, 1.0593788052],
      direction: [-27.1576824784, -5.7286070974, 12.508284386],
      "speed factor": 1.4853686986,
      "Uz factor": 0.9966345194,
      "Directional variance factor": 0.4907904802,
      "Velocity stability factor": 0.3662347956,
      "Direction stability factor": 0.8898167587,
      score: 0.6591646983,
    },
    "292.5": {
      velocity: [0.3149654921, 1.1698371921, 1.4375949912],
      direction: [-26.6064702592, -7.6997671779, 20.1931000782],
      "speed factor": 1.5930265881,
      "Uz factor": 0.9607107895,
      "Directional variance factor": 0.3155762509,
      "Velocity stability factor": 0.1767312047,
      "Direction stability factor": 0.8700011935,
      score: 0.5580774606,
    },
    "337.5": {
      velocity: [0.669471515, 0.9693656356, 1.2130722379],
      direction: [-26.1627786604, -6.7266541559, 11.2817393752],
      "speed factor": 1.2310341942,
      "Uz factor": 0.9767865666,
      "Directional variance factor": 0.4020751861,
      "Velocity stability factor": 0.5621652386,
      "Direction stability factor": 0.8959874499,
      score: 0.6890538311,
    },
    id: "IDN:739.0",
    "45": {
      velocity: [0.1107319862, 0.5327324686, 0.9713803706],
      direction: [-128.4675711324, 11.0487318646, 92.7567574494],
      "speed factor": 0.7236539513,
      "Uz factor": 0.9858360992,
      "Directional variance factor": 0.0178905009,
      "Velocity stability factor": 0.3003200971,
      "Direction stability factor": 0.3854879762,
      score: 0.0,
    },
    "90": {
      velocity: [0.6356340778, 1.0668723042, 1.3638506259],
      direction: [-18.7721805546, 3.3806499966, 16.4911136997],
      "speed factor": 1.7294312252,
      "Uz factor": 0.9470254901,
      "Directional variance factor": 0.6994977781,
      "Velocity stability factor": 0.4685056677,
      "Direction stability factor": 0.9020464048,
      score: 0.0,
    },
    "135": {
      velocity: [0.3549449764, 0.7030282812, 1.0413796287],
      direction: [-75.963138784, -26.8610350364, 10.5654959761],
      "speed factor": 0.5950082141,
      "Uz factor": 0.9274659851,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4468635372,
      "Direction stability factor": 0.7596426812,
      score: 0.0,
    },
    "180": {
      velocity: [0.1650977643, 0.6102375361, 1.0546509935],
      direction: [-97.3806941513, -16.4351015682, 71.0157043732],
      "speed factor": 1.1917901382,
      "Uz factor": 0.9999149933,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2990704124,
      "Direction stability factor": 0.5322322263,
      score: 0.0,
    },
    "225": {
      velocity: [0.1402274794, 0.5289840202, 0.9184516506],
      direction: [-108.8839260272, 1.6814011306, 41.8054175152],
      "speed factor": 0.7221210772,
      "Uz factor": 0.995048052,
      "Directional variance factor": 0.8505421217,
      "Velocity stability factor": 0.3005472047,
      "Direction stability factor": 0.5814184902,
      score: 0.0,
    },
    "270": {
      velocity: [0.424865022, 1.0524997675, 1.4737095874],
      direction: [-7.2582622064, 4.0650953203, 20.4097556989],
      "speed factor": 0.963071272,
      "Uz factor": 0.959462023,
      "Directional variance factor": 0.6386581938,
      "Velocity stability factor": 0.172668046,
      "Direction stability factor": 0.9231443947,
      score: 0.0,
    },
    "315": {
      velocity: [0.7121613409, 1.094838263, 1.3136569269],
      direction: [-24.3328247042, -8.1836293558, 12.0812909415],
      "speed factor": 1.3489496962,
      "Uz factor": 0.9849224623,
      "Directional variance factor": 0.2725662795,
      "Velocity stability factor": 0.5120451456,
      "Direction stability factor": 0.8988496788,
      score: 0.6455776957,
    },
  },
  {
    position: [-50.54083578, 38.75377202, 47.87999908],
    "overall score": 0.188805371,
    "0": {
      velocity: [0.1855439373, 0.9854477167, 1.4532395955],
      direction: [-44.724082828, -12.534294094, 32.3239534737],
      "speed factor": 1.0098385817,
      "Uz factor": 0.9986774756,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1191660201,
      "Direction stability factor": 0.7859776769,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0614881053, 0.7450097766, 1.6078636374],
      direction: [-92.1496015649, 2.6322996702, 98.958357115],
      "speed factor": 0.6614760405,
      "Uz factor": 0.9997920432,
      "Directional variance factor": 0.7660178071,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.4691445592,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1657715383, 0.6549964214, 1.0886604239],
      direction: [-58.7495956082, 6.7134768094, 33.7995554375],
      "speed factor": 1.0146331222,
      "Uz factor": 0.9865903215,
      "Directional variance factor": 0.4032465058,
      "Velocity stability factor": 0.1753484587,
      "Direction stability factor": 0.7429190249,
      score: 0.5161082536,
    },
    "112.5": {
      velocity: [0.2528236533, 0.8992619019, 1.273673712],
      direction: [-45.4487023147, -20.2021055974, 8.073964432],
      "speed factor": 1.0116068164,
      "Uz factor": 0.9716418161,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1549208951,
      "Direction stability factor": 0.8513259257,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5379646816, 0.9803614164, 1.2859126731],
      direction: [-39.7458696192, -10.320448439, 5.4024279646],
      "speed factor": 1.3927413207,
      "Uz factor": 0.9472490973,
      "Directional variance factor": 0.0826268054,
      "Velocity stability factor": 0.3883345315,
      "Direction stability factor": 0.8745880623,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1665257951, 0.8037229698, 1.1790670904],
      direction: [-49.7744593256, -8.8285298379, 4.7186729774],
      "speed factor": 0.9416863806,
      "Uz factor": 0.9698941123,
      "Directional variance factor": 0.2152417922,
      "Velocity stability factor": 0.2214548774,
      "Direction stability factor": 0.848630188,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.5357800729, 0.8511845597, 1.0492724954],
      direction: [-20.268707014, 3.8434548518, 11.3007537943],
      "speed factor": 1.5790499314,
      "Uz factor": 0.9956772889,
      "Directional variance factor": 0.6583595687,
      "Velocity stability factor": 0.5017337319,
      "Direction stability factor": 0.9123070533,
      score: 0.7461768518,
    },
    "292.5": {
      velocity: [0.3757994752, 0.9657717477, 1.2132310437],
      direction: [-20.9911351829, 6.9892134468, 25.650884291],
      "speed factor": 1.315140331,
      "Uz factor": 0.9563389019,
      "Directional variance factor": 0.3787365825,
      "Velocity stability factor": 0.3858781734,
      "Direction stability factor": 0.8704388348,
      score: 0.6263731064,
    },
    "337.5": {
      velocity: [0.1159956548, 0.6896856846, 1.1169740688],
      direction: [-31.1485592859, -8.082497887, 75.2241369622],
      "speed factor": 0.8758580146,
      "Uz factor": 0.9946150467,
      "Directional variance factor": 0.2815557434,
      "Velocity stability factor": 0.1937774794,
      "Direction stability factor": 0.7045202882,
      score: 0.0,
    },
    id: "IDN:749.0",
    "45": {
      velocity: [0.1345216852, 0.5441441475, 1.0109517629],
      direction: [-131.0182189493, 9.4969526832, 172.9494551471],
      "speed factor": 0.7391553652,
      "Uz factor": 0.9967078719,
      "Directional variance factor": 0.1558264282,
      "Velocity stability factor": 0.2874900799,
      "Direction stability factor": 0.1556453497,
      score: 0.0,
    },
    "90": {
      velocity: [0.2609163178, 1.0533534231, 1.3672746592],
      direction: [-79.1527749207, -6.9400675213, 30.1880126847],
      "speed factor": 1.7075167234,
      "Uz factor": 0.9698565555,
      "Directional variance factor": 0.3831051092,
      "Velocity stability factor": 0.1925160318,
      "Direction stability factor": 0.69627559,
      score: 0.4920430803,
    },
    "135": {
      velocity: [0.1063818409, 0.6368229547, 0.9726901539],
      direction: [-169.682626355, -28.7719178913, 169.795686496],
      "speed factor": 0.5389753146,
      "Uz factor": 0.9867805489,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.301919397,
      "Direction stability factor": 0.0570046865,
      score: 0.0,
    },
    "180": {
      velocity: [0.354780542, 0.8359689742, 1.1170255796],
      direction: [-50.6984326513, -11.5648829334, 14.9731325877],
      "speed factor": 1.6326422424,
      "Uz factor": 0.9777878633,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.399383778,
      "Direction stability factor": 0.8175789854,
      score: 0.0,
    },
    "225": {
      velocity: [0.1386040953, 0.569189536, 0.9609205848],
      direction: [-56.8605924738, 3.5220184063, 39.8574209782],
      "speed factor": 0.7770060062,
      "Uz factor": 0.9971271655,
      "Directional variance factor": 0.6869316972,
      "Velocity stability factor": 0.260917884,
      "Direction stability factor": 0.7313388515,
      score: 0.0,
    },
    "270": {
      velocity: [0.4864278159, 0.9152580035, 1.3069297991],
      direction: [2.0344162617, 20.1924913026, 42.2841191049],
      "speed factor": 0.8374906265,
      "Uz factor": 0.979446856,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3527854065,
      "Direction stability factor": 0.8881952699,
      score: 0.0,
    },
    "315": {
      velocity: [0.3366706038, 0.9798478214, 1.3088887786],
      direction: [-21.7166136822, -3.6350597017, 37.2237836824],
      "speed factor": 1.2072700285,
      "Uz factor": 0.9779318689,
      "Directional variance factor": 0.6768835821,
      "Velocity stability factor": 0.2113016472,
      "Direction stability factor": 0.836276674,
      score: 0.6401846443,
    },
  },
  {
    position: [-50.06973548, 50.82220129, 47.87999908],
    "overall score": 0.1882173883,
    "0": {
      velocity: [0.3289576521, 1.0269525715, 1.5050246475],
      direction: [-53.5659979511, -14.2828071219, 45.1844109094],
      "speed factor": 1.0523707252,
      "Uz factor": 0.9966798954,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1828324366,
      "Direction stability factor": 0.7256933087,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1336319199, 0.7127598945, 1.188202195],
      direction: [-133.8331141923, 6.5388274495, 115.6701968363],
      "speed factor": 0.6328421555,
      "Uz factor": 0.9942232473,
      "Directional variance factor": 0.4187708934,
      "Velocity stability factor": 0.3180374022,
      "Direction stability factor": 0.3069352471,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.233944138, 0.5777024076, 0.9978951501],
      direction: [-38.7109185754, 12.2045877252, 45.7421330193],
      "speed factor": 0.894899542,
      "Uz factor": 0.9703948063,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.317368115,
      "Direction stability factor": 0.76540819,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5752225424, 0.9099095291, 1.2492197978],
      direction: [-37.1958521036, -13.4066526318, 10.1472683805],
      "speed factor": 1.0235846532,
      "Uz factor": 0.9428803643,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4420522461,
      "Direction stability factor": 0.868491332,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3036633112, 0.9616016787, 1.3315140682],
      direction: [-46.5685184421, -8.6333882319, 15.1336478672],
      "speed factor": 1.3660904739,
      "Uz factor": 0.9980610524,
      "Directional variance factor": 0.2325877127,
      "Velocity stability factor": 0.1594324445,
      "Direction stability factor": 0.8286050936,
      score: 0.5123075861,
    },
    "202.5": {
      velocity: [0.2080405725, 0.6893576469, 1.1639674393],
      direction: [-133.5170658539, -7.8949160766, 20.4721680026],
      "speed factor": 0.8076896292,
      "Uz factor": 0.9997994522,
      "Directional variance factor": 0.2982296821,
      "Velocity stability factor": 0.26498583,
      "Direction stability factor": 0.5722521282,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4526135164, 0.8123901457, 1.0996575314],
      direction: [-17.9266818439, -3.4818897616, 12.0744725544],
      "speed factor": 1.5070816185,
      "Uz factor": 0.9953669003,
      "Directional variance factor": 0.6904986879,
      "Velocity stability factor": 0.3721422312,
      "Direction stability factor": 0.91666346,
      score: 0.7239919598,
    },
    "292.5": {
      velocity: [0.17270035, 0.9361793878, 1.3464372903],
      direction: [-32.2333570246, -2.3558967301, 42.1381716761],
      "speed factor": 1.2748429149,
      "Uz factor": 0.9566102056,
      "Directional variance factor": 0.7905869573,
      "Velocity stability factor": 0.1392520884,
      "Direction stability factor": 0.7934124203,
      score: 0.6291659716,
    },
    "337.5": {
      velocity: [0.2448292242, 0.9310917665, 1.2223280228],
      direction: [-29.4748990512, -6.822326182, 10.8846023509],
      "speed factor": 1.1824287559,
      "Uz factor": 0.9875953939,
      "Directional variance factor": 0.393571006,
      "Velocity stability factor": 0.212688771,
      "Direction stability factor": 0.8878902739,
      score: 0.5955100812,
    },
    id: "IDN:740.0",
    "45": {
      velocity: [0.1610839284, 0.5520255386, 0.9798247918],
      direction: [-125.9747217015, 8.5818982455, 109.124292206],
      "speed factor": 0.7498613014,
      "Uz factor": 0.9826910663,
      "Directional variance factor": 0.2371646004,
      "Velocity stability factor": 0.3343895856,
      "Direction stability factor": 0.3469471836,
      score: 0.0,
    },
    "90": {
      velocity: [0.5962106507, 1.0644135249, 1.3776106559],
      direction: [-27.6807567408, -3.6246391192, 8.9508181298],
      "speed factor": 1.725445472,
      "Uz factor": 0.9413321331,
      "Directional variance factor": 0.6778098561,
      "Velocity stability factor": 0.429689321,
      "Direction stability factor": 0.8982456254,
      score: 0.0,
    },
    "135": {
      velocity: [0.3561205405, 0.7119800215, 1.0435776821],
      direction: [-85.1859477506, -34.3031956259, 17.6774012845],
      "speed factor": 0.6025845225,
      "Uz factor": 0.9451427876,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4460396042,
      "Direction stability factor": 0.7142684749,
      score: 0.0,
    },
    "180": {
      velocity: [0.0796255076, 0.5687121361, 0.9859288517],
      direction: [-114.1344499441, -15.0141000375, 159.0270020719],
      "speed factor": 1.1106912885,
      "Uz factor": 0.9998952089,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2858720442,
      "Direction stability factor": 0.2412181888,
      score: 0.0,
    },
    "225": {
      velocity: [0.1014682279, 0.5269632974, 0.9303976365],
      direction: [-71.1197126114, 3.1957177556, 49.2383935001],
      "speed factor": 0.7193625695,
      "Uz factor": 0.9957046195,
      "Directional variance factor": 0.7159361995,
      "Velocity stability factor": 0.2549743205,
      "Direction stability factor": 0.6656719275,
      score: 0.0,
    },
    "270": {
      velocity: [0.1777679615, 1.0178904505, 1.4023898923],
      direction: [-3.6886067701, 10.1430218595, 30.1737827336],
      "speed factor": 0.9314026295,
      "Uz factor": 0.9719802119,
      "Directional variance factor": 0.0983980569,
      "Velocity stability factor": 0.034014297,
      "Direction stability factor": 0.9059378069,
      score: 0.0,
    },
    "315": {
      velocity: [0.2671665485, 1.0251141562, 1.3290275462],
      direction: [-18.7627672008, -8.1146647205, 19.9851448143],
      "speed factor": 1.2630426578,
      "Uz factor": 0.9785354096,
      "Directional variance factor": 0.2786964693,
      "Velocity stability factor": 0.138580165,
      "Direction stability factor": 0.8923669111,
      score: 0.5505026141,
    },
  },
  {
    position: [-45.85200666, 40.13741357, 46.87999908],
    "overall score": 0.1851027497,
    "0": {
      velocity: [0.2826349676, 0.8041365658, 1.4151709146],
      direction: [-37.2111777672, -9.5577389986, 135.2605327529],
      "speed factor": 0.8240397895,
      "Uz factor": 0.9952478048,
      "Directional variance factor": 0.1504232001,
      "Velocity stability factor": 0.2130791495,
      "Direction stability factor": 0.5209119152,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1998944031, 0.7588575654, 1.2562322189],
      direction: [-89.9509110864, -2.6853409808, 83.1796585247],
      "speed factor": 0.673771155,
      "Uz factor": 0.9858541715,
      "Directional variance factor": 0.7613030239,
      "Velocity stability factor": 0.3168943805,
      "Direction stability factor": 0.5190817511,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3892698182, 0.6483466145, 0.9893591295],
      direction: [-26.2994400663, -0.7278607463, 29.5000118363],
      "speed factor": 1.004332128,
      "Uz factor": 0.9664516,
      "Directional variance factor": 0.935301267,
      "Velocity stability factor": 0.4637874795,
      "Direction stability factor": 0.8450015225,
      score: 0.7722729479,
    },
    "112.5": {
      velocity: [0.5084107903, 0.8342245297, 1.1902807568],
      direction: [-48.0546788143, -24.1030355611, 5.0441412146],
      "speed factor": 0.9384443163,
      "Uz factor": 0.9175651846,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.435535066,
      "Direction stability factor": 0.8525032777,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.55777055, 0.9592857151, 1.227267935],
      direction: [-39.2453112333, 1.2661192325, 26.6501358605],
      "speed factor": 1.3628003218,
      "Uz factor": 0.9707661438,
      "Directional variance factor": 0.8874560682,
      "Velocity stability factor": 0.4524907664,
      "Direction stability factor": 0.8169570914,
      score: 0.7434652543,
    },
    "202.5": {
      velocity: [0.3687637141, 0.8296368393, 1.1419214676],
      direction: [-27.7190098731, -2.1705054465, 35.9340746017],
      "speed factor": 0.9720485065,
      "Uz factor": 0.9881007438,
      "Directional variance factor": 0.8070661825,
      "Velocity stability factor": 0.405517384,
      "Direction stability factor": 0.8231858765,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3144441173, 0.7262892068, 1.0777169854],
      direction: [-43.1753739639, 2.4798895576, 15.5270364698],
      "speed factor": 1.3473540011,
      "Uz factor": 0.9999657053,
      "Directional variance factor": 0.7795653727,
      "Velocity stability factor": 0.2593598135,
      "Direction stability factor": 0.8369377488,
      score: 0.6782001709,
    },
    "292.5": {
      velocity: [0.0238757437, 0.1839381934, 0.6359102102],
      direction: [-168.8028121068, 81.6192656602, 176.9139121971],
      "speed factor": 0.2504779593,
      "Uz factor": 0.9879416932,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5511708197,
      "Direction stability factor": 0.0396757658,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.071326632, 0.4425242564, 0.9722196406],
      direction: [-173.9748040117, -3.2004987305, 145.1369757211],
      "speed factor": 0.5619783406,
      "Uz factor": 0.9993793836,
      "Directional variance factor": 0.715511224,
      "Velocity stability factor": 0.2743897151,
      "Direction stability factor": 0.1135783896,
      score: 0.0,
    },
    id: "IDN:480.0",
    "45": {
      velocity: [0.1420039654, 0.530746231, 0.9772009565],
      direction: [-115.7553069367, 5.1426456784, 81.2340218803],
      "speed factor": 0.7209558828,
      "Uz factor": 0.9731675613,
      "Directional variance factor": 0.5428759397,
      "Velocity stability factor": 0.3210112745,
      "Direction stability factor": 0.45280742,
      score: 0.0,
    },
    "90": {
      velocity: [0.4990998052, 0.9920760591, 1.2775104431],
      direction: [-55.5946149495, -10.7135618624, 23.3017473097],
      "speed factor": 1.6081843232,
      "Uz factor": 0.943010591,
      "Directional variance factor": 0.04768339,
      "Velocity stability factor": 0.4318711332,
      "Direction stability factor": 0.7808434382,
      score: 0.0,
    },
    "135": {
      velocity: [0.187978435, 0.6500428235, 0.9516739305],
      direction: [-111.7007344722, -34.4084409072, 27.6751163965],
      "speed factor": 0.5501639548,
      "Uz factor": 0.9433333242,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3846059145,
      "Direction stability factor": 0.6128448587,
      score: 0.0,
    },
    "180": {
      velocity: [0.4371543339, 0.863696089, 1.1327455168],
      direction: [-23.9294523715, 1.5543267707, 19.7960165624],
      "speed factor": 1.6867931262,
      "Uz factor": 0.9827784019,
      "Directional variance factor": 0.8618376204,
      "Velocity stability factor": 0.4519041414,
      "Direction stability factor": 0.8785403641,
      score: 0.7677056225,
    },
    "225": {
      velocity: [0.0721058775, 0.4281851617, 0.8617024232],
      direction: [-65.8531036661, 0.6637604847, 45.1460590108],
      "speed factor": 0.5845196043,
      "Uz factor": 0.9986214129,
      "Directional variance factor": 0.940999068,
      "Velocity stability factor": 0.2903259351,
      "Direction stability factor": 0.6916689926,
      score: 0.0,
    },
    "270": {
      velocity: [0.0639301861, 0.3815649417, 1.0004358192],
      direction: [-171.1070787283, 24.1463833712, 163.5187450778],
      "speed factor": 0.3491442422,
      "Uz factor": 0.999520454,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2612813558,
      "Direction stability factor": 0.0704838228,
      score: 0.0,
    },
    "315": {
      velocity: [0.0721213826, 0.3459944526, 1.0529020268],
      direction: [-35.2948822031, 16.1735145455, 128.6677926294],
      "speed factor": 0.4262995983,
      "Uz factor": 0.9992435275,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2043554641,
      "Direction stability factor": 0.5445481255,
      score: 0.0,
    },
  },
  {
    position: [-53.93078572, 52.86568654, 46.87999908],
    "overall score": 0.1845355244,
    "0": {
      velocity: [0.2475390611, 1.0808752967, 1.3669153349],
      direction: [-26.7938948627, -8.0665231509, 42.2081451687],
      "speed factor": 1.1076280944,
      "Uz factor": 0.9904569816,
      "Directional variance factor": 0.2829757199,
      "Velocity stability factor": 0.2222228956,
      "Direction stability factor": 0.8083276666,
      score: 0.5304634872,
    },
    "22.5": {
      velocity: [0.2262725674, 0.66502938, 1.2643195565],
      direction: [-150.3718034174, 4.9210458366, 63.6689876933],
      "speed factor": 0.5904633938,
      "Uz factor": 0.9995481517,
      "Directional variance factor": 0.5625737034,
      "Velocity stability factor": 0.3287225725,
      "Direction stability factor": 0.4054422469,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0929038867, 0.4366488034, 1.0157380367],
      direction: [-161.763907858, 14.1885098428, 127.8084172369],
      "speed factor": 0.6763981057,
      "Uz factor": 0.9818987492,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1753973679,
      "Direction stability factor": 0.1956324303,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0815369484, 0.5049204865, 1.1692313435],
      direction: [-173.7798312378, -20.111635147, 178.1789313409],
      "speed factor": 0.5680002731,
      "Uz factor": 0.9532331219,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0995858813,
      "Direction stability factor": 0.0223367706,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0804971484, 0.5532709614, 1.0755771032],
      direction: [-82.2916572818, -8.9856451398, 127.1413945289],
      "speed factor": 0.7859992412,
      "Uz factor": 0.9993428252,
      "Directional variance factor": 0.2012759876,
      "Velocity stability factor": 0.1862321262,
      "Direction stability factor": 0.4182415227,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3665304918, 0.8747256392, 1.1695259643],
      direction: [-40.6550199257, -6.6644878221, 16.7937589416],
      "speed factor": 1.0248770437,
      "Uz factor": 0.9976601537,
      "Directional variance factor": 0.4076010825,
      "Velocity stability factor": 0.3825750994,
      "Direction stability factor": 0.8404200587,
      score: 0.6177540748,
    },
    "247.5": {
      velocity: [0.5057939173, 0.7607722841, 0.9729507214],
      direction: [-32.2115986117, -9.8214409398, 10.9464857927],
      "speed factor": 1.4113242648,
      "Uz factor": 0.9859307185,
      "Directional variance factor": 0.1269830276,
      "Velocity stability factor": 0.5466953995,
      "Direction stability factor": 0.8801164322,
      score: 0.6084778229,
    },
    "292.5": {
      velocity: [0.6793455775, 1.1119815166, 1.3006961494],
      direction: [-25.681087248, 0.2358216291, 13.3561148799],
      "speed factor": 1.5142415828,
      "Uz factor": 0.8994232421,
      "Directional variance factor": 0.9790380774,
      "Velocity stability factor": 0.5443389496,
      "Direction stability factor": 0.8915633274,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6502788808, 0.9108177276, 1.1359167738],
      direction: [-25.4322660695, -4.4001366977, 9.6715284827],
      "speed factor": 1.1566819849,
      "Uz factor": 0.9516053477,
      "Directional variance factor": 0.608876738,
      "Velocity stability factor": 0.6088504999,
      "Direction stability factor": 0.9024894596,
      score: 0.7556765393,
    },
    id: "IDN:469.0",
    "45": {
      velocity: [0.0580482052, 0.4990421659, 1.0391144196],
      direction: [-124.7755114339, 16.0818622021, 84.7623846424],
      "speed factor": 0.677889666,
      "Uz factor": 0.9965596103,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2024242117,
      "Direction stability factor": 0.4179502887,
      score: 0.0,
    },
    "90": {
      velocity: [0.0670242331, 0.6177682088, 1.0563463941],
      direction: [-70.559727549, -3.6151174461, 144.6853759382],
      "speed factor": 1.0014203443,
      "Uz factor": 0.9501318662,
      "Directional variance factor": 0.678656227,
      "Velocity stability factor": 0.2779357695,
      "Direction stability factor": 0.4020969348,
      score: 0.4401964665,
    },
    "135": {
      velocity: [0.0525983438, 0.4355153961, 1.0643044207],
      direction: [-176.101445904, -38.1765001981, 165.8504718248],
      "speed factor": 0.3685985969,
      "Uz factor": 0.9862774024,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1847563071,
      "Direction stability factor": 0.0501335619,
      score: 0.0,
    },
    "180": {
      velocity: [0.1472907052, 0.5316902353, 0.9479967332],
      direction: [-136.9054683225, -18.5015060206, 47.2595867293],
      "speed factor": 1.038387745,
      "Uz factor": 0.996889653,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3690781759,
      "Direction stability factor": 0.4884304026,
      score: 0.0,
    },
    "225": {
      velocity: [0.1674673656, 0.5533414508, 0.8481192693],
      direction: [-69.7802950533, -1.9469142075, 38.7942735065],
      "speed factor": 0.7553716355,
      "Uz factor": 0.982987938,
      "Directional variance factor": 0.8269409593,
      "Velocity stability factor": 0.3882432668,
      "Direction stability factor": 0.6984039762,
      score: 0.0,
    },
    "270": {
      velocity: [0.7806066149, 1.0427369033, 1.401039936],
      direction: [-9.9285757181, 7.2596240034, 28.869330621],
      "speed factor": 0.9541379361,
      "Uz factor": 0.9134622908,
      "Directional variance factor": 0.3547000886,
      "Velocity stability factor": 0.5106002083,
      "Direction stability factor": 0.8922280379,
      score: 0.0,
    },
    "315": {
      velocity: [0.707034168, 1.0457927211, 1.2514873544],
      direction: [-15.6787059289, -4.1061987691, 20.1898112357],
      "speed factor": 1.2885207077,
      "Uz factor": 0.9401929195,
      "Directional variance factor": 0.6350045539,
      "Velocity stability factor": 0.5583199919,
      "Direction stability factor": 0.9003652301,
      score: 0.0,
    },
  },
  {
    position: [-45.82059079, 49.13735874, 47.87999908],
    "overall score": 0.1833633584,
    "0": {
      velocity: [0.2842664727, 0.9299517156, 1.3784286865],
      direction: [-80.6702954924, -14.4201440473, 79.4242592364],
      "speed factor": 0.9529689963,
      "Uz factor": 0.9991227191,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2397424009,
      "Direction stability factor": 0.5552929035,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3472474917, 0.8702198457, 1.3528208265],
      direction: [-110.2139015416, -2.300068245, 99.5055110464],
      "speed factor": 0.7726470122,
      "Uz factor": 0.9994784843,
      "Directional variance factor": 0.7955494893,
      "Velocity stability factor": 0.3497224226,
      "Direction stability factor": 0.4174460761,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2481575776, 0.5671323705, 0.9495839501],
      direction: [-54.4736462979, 9.2073054423, 75.1026986127],
      "speed factor": 0.8785258498,
      "Uz factor": 0.9718310765,
      "Directional variance factor": 0.1815728496,
      "Velocity stability factor": 0.3732372898,
      "Direction stability factor": 0.6400657086,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5058943571, 0.878718743, 1.211509955],
      direction: [-38.9236248332, -8.3538340785, 13.3719458624],
      "speed factor": 0.9884971978,
      "Uz factor": 0.9457289409,
      "Directional variance factor": 0.2574369708,
      "Velocity stability factor": 0.4158779804,
      "Direction stability factor": 0.8547345258,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.6239318235, 1.0141348199, 1.3174889299],
      direction: [-25.2376655203, -4.9226495937, 9.517132748],
      "speed factor": 1.440721192,
      "Uz factor": 0.988567858,
      "Directional variance factor": 0.5624311472,
      "Velocity stability factor": 0.432814932,
      "Direction stability factor": 0.9034588937,
      score: 0.7005409666,
    },
    "202.5": {
      velocity: [0.0852984449, 0.447113292, 1.0971205669],
      direction: [-103.1642614986, -20.5210793674, 135.557734729],
      "speed factor": 0.5238627158,
      "Uz factor": 0.9889634971,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2220078512,
      "Direction stability factor": 0.3368833438,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.370597104, 0.7313228226, 1.1239758229],
      direction: [-25.9933568111, 0.0566693893, 15.3950849394],
      "speed factor": 1.356691965,
      "Uz factor": 0.9987961044,
      "Directional variance factor": 0.9949627209,
      "Velocity stability factor": 0.2689605799,
      "Direction stability factor": 0.8850321062,
      score: 0.7584968783,
    },
    "292.5": {
      velocity: [0.0194588609, 0.3395957013, 1.0415300872],
      direction: [-178.6962489025, 6.7475187559, 157.3242940601],
      "speed factor": 0.4624446761,
      "Uz factor": 0.9573205093,
      "Directional variance factor": 0.400220555,
      "Velocity stability factor": 0.2504745797,
      "Direction stability factor": 0.0666096029,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1211394832, 0.6795216396, 1.2125973651],
      direction: [-172.0868999369, -7.634770143, 78.6847597786],
      "speed factor": 0.8629503083,
      "Uz factor": 0.9968136497,
      "Directional variance factor": 0.3213537651,
      "Velocity stability factor": 0.1209021969,
      "Direction stability factor": 0.3034120563,
      score: 0.0,
    },
    id: "IDN:743.0",
    "45": {
      velocity: [0.0962176456, 0.546738136, 1.0306861867],
      direction: [-146.7363876847, 0.1960563885, 147.933833838],
      "speed factor": 0.7426789913,
      "Uz factor": 0.9855101837,
      "Directional variance factor": 0.9825727655,
      "Velocity stability factor": 0.2403066456,
      "Direction stability factor": 0.1814716069,
      score: 0.0,
    },
    "90": {
      velocity: [0.6085797388, 1.004292571, 1.2698075257],
      direction: [-32.8818856915, -0.4456257484, 32.2537841335],
      "speed factor": 1.6279876463,
      "Uz factor": 0.9602212529,
      "Directional variance factor": 0.9603888224,
      "Velocity stability factor": 0.5173979196,
      "Direction stability factor": 0.8190675838,
      score: 0.7789804774,
    },
    "135": {
      velocity: [0.1644557896, 0.6662920778, 1.0803850287],
      direction: [-106.8110346892, -21.8845995973, 17.6192888983],
      "speed factor": 0.5639165165,
      "Uz factor": 0.9571928525,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2619343183,
      "Direction stability factor": 0.6543602123,
      score: 0.0,
    },
    "180": {
      velocity: [0.1912867352, 0.8180608165, 1.2236566591],
      direction: [-74.6620591936, -8.8557436923, 124.5192771975],
      "speed factor": 1.597667721,
      "Uz factor": 0.9977694804,
      "Directional variance factor": 0.2128227829,
      "Velocity stability factor": 0.1865370152,
      "Direction stability factor": 0.44671851,
      score: 0.3231992046,
    },
    "225": {
      velocity: [0.0897693325, 0.5084232611, 0.8997885122],
      direction: [-76.6636461461, 0.8200127616, 125.4682027704],
      "speed factor": 0.6940533909,
      "Uz factor": 0.9999972131,
      "Directional variance factor": 0.9271099767,
      "Velocity stability factor": 0.2719704676,
      "Direction stability factor": 0.4385226419,
      score: 0.0,
    },
    "270": {
      velocity: [0.0389681272, 0.6130756296, 1.2124711909],
      direction: [-54.5433093707, 16.0217102006, 153.0620308258],
      "speed factor": 0.5609839971,
      "Uz factor": 0.9903721148,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0743370232,
      "Direction stability factor": 0.4233184995,
      score: 0.0,
    },
    "315": {
      velocity: [0.1283824512, 0.8363011508, 1.2829919679],
      direction: [-22.1170994782, -5.5482671539, 172.2424181757],
      "speed factor": 1.0304062448,
      "Uz factor": 0.9962385249,
      "Directional variance factor": 0.5068206974,
      "Velocity stability factor": 0.0633392302,
      "Direction stability factor": 0.460112451,
      score: 0.3725962074,
    },
  },
  {
    position: [-45.8415347, 43.13739529, 47.87999908],
    "overall score": 0.1799824959,
    "0": {
      velocity: [0.2614154845, 0.8912516082, 1.3747929841],
      direction: [-43.1001418561, -10.7141458785, 154.2632072566],
      "speed factor": 0.9133110206,
      "Uz factor": 0.9974073961,
      "Directional variance factor": 0.0476314775,
      "Velocity stability factor": 0.2263910286,
      "Direction stability factor": 0.4517684747,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2770817425, 0.8346213901, 1.3290698683],
      direction: [-77.5863330699, -1.6548688898, 83.3347879183],
      "speed factor": 0.7410400102,
      "Uz factor": 0.995763938,
      "Directional variance factor": 0.8529005431,
      "Velocity stability factor": 0.3197072096,
      "Direction stability factor": 0.5529968861,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3680768736, 0.6387080551, 0.9936755188],
      direction: [-27.6552507593, 3.5046862297, 33.820887545],
      "speed factor": 0.9894013568,
      "Uz factor": 0.9710784142,
      "Directional variance factor": 0.6884723351,
      "Velocity stability factor": 0.4409934988,
      "Direction stability factor": 0.8292329492,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5345502609, 0.8594792175, 1.1653266062],
      direction: [-33.7537388093, -16.5079275142, 12.5957641936],
      "speed factor": 0.9668540757,
      "Uz factor": 0.9253929184,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4778313379,
      "Direction stability factor": 0.8712513805,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.6167443848, 1.0066419652, 1.290810772],
      direction: [-36.723677254, -2.0903874728, 14.8792506958],
      "speed factor": 1.4300765378,
      "Uz factor": 0.9840043315,
      "Directional variance factor": 0.8141877802,
      "Velocity stability factor": 0.4487542755,
      "Direction stability factor": 0.8566585335,
      score: 0.7440647807,
    },
    "202.5": {
      velocity: [0.1921753342, 0.7324094227, 1.1464995283],
      direction: [-36.1922301521, -6.3375497014, 16.7540030053],
      "speed factor": 0.858131476,
      "Uz factor": 0.9888743775,
      "Directional variance factor": 0.4366622488,
      "Velocity stability factor": 0.2662181284,
      "Direction stability factor": 0.8529271301,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4291584921, 0.7892131451, 1.1011073399],
      direction: [-23.754774163, 3.3013864917, 12.6568185217],
      "speed factor": 1.4640854893,
      "Uz factor": 0.9993056067,
      "Directional variance factor": 0.706543423,
      "Velocity stability factor": 0.3479758802,
      "Direction stability factor": 0.898856687,
      score: 0.7130581693,
    },
    "292.5": {
      velocity: [0.0524348675, 0.3621937124, 0.9441285188],
      direction: [-65.1721357948, 12.0775531412, 115.5322960492],
      "speed factor": 0.4932175331,
      "Uz factor": 0.9803900978,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3460856333,
      "Direction stability factor": 0.4980432449,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0956428487, 0.6136532021, 1.2116144192],
      direction: [-153.521839809, -4.6025449086, 152.7928834128],
      "speed factor": 0.7793014808,
      "Uz factor": 0.9995816672,
      "Directional variance factor": 0.590884897,
      "Velocity stability factor": 0.1011580271,
      "Direction stability factor": 0.1491257688,
      score: 0.0,
    },
    id: "IDN:745.0",
    "45": {
      velocity: [0.0811643783, 0.5593194101, 1.0376397345],
      direction: [-157.9970780378, 1.6844902291, 111.1136621888],
      "speed factor": 0.7597691619,
      "Uz factor": 0.9831426989,
      "Directional variance factor": 0.8502675352,
      "Velocity stability factor": 0.2224158013,
      "Direction stability factor": 0.252470166,
      score: 0.0,
    },
    "90": {
      velocity: [0.6072780037, 1.0288426686, 1.2619088587],
      direction: [-48.8705565474, -5.2302379802, 28.8402914608],
      "speed factor": 1.6677840729,
      "Uz factor": 0.9515255162,
      "Directional variance factor": 0.5350899573,
      "Velocity stability factor": 0.52221274,
      "Direction stability factor": 0.7841365333,
      score: 0.656393941,
    },
    "135": {
      velocity: [0.2163584044, 0.6887557715, 1.0347000201],
      direction: [-113.6999801002, -29.3149742028, 19.2543702099],
      "speed factor": 0.5829286709,
      "Uz factor": 0.9509018205,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3405714802,
      "Direction stability factor": 0.6306823602,
      score: 0.0,
    },
    "180": {
      velocity: [0.4966008134, 0.8980358371, 1.1912916908],
      direction: [-25.5002079056, -1.8387956381, 14.8833087842],
      "speed factor": 1.7538584421,
      "Uz factor": 0.9926855109,
      "Directional variance factor": 0.8365514988,
      "Velocity stability factor": 0.4526135433,
      "Direction stability factor": 0.8878235648,
      score: 0.7662030429,
    },
    "225": {
      velocity: [0.0708557005, 0.5043525014, 0.9665096628],
      direction: [-77.5841445614, 2.2909139933, 66.5736920317],
      "speed factor": 0.6884963584,
      "Uz factor": 0.9999734995,
      "Directional variance factor": 0.7963632006,
      "Velocity stability factor": 0.1950035854,
      "Direction stability factor": 0.599561565,
      score: 0.0,
    },
    "270": {
      velocity: [0.0846025046, 0.6959369946, 1.2493995118],
      direction: [-145.5424483613, 16.4901350037, 94.4219465238],
      "speed factor": 0.6368048216,
      "Uz factor": 0.9957628299,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0812043884,
      "Direction stability factor": 0.3334322364,
      score: 0.0,
    },
    "315": {
      velocity: [0.1495433997, 0.6777696579, 1.3168196963],
      direction: [-29.4473046184, -0.3254241892, 94.8795822981],
      "speed factor": 0.8350796689,
      "Uz factor": 0.9946442641,
      "Directional variance factor": 0.9710734054,
      "Velocity stability factor": 0.0530634829,
      "Direction stability factor": 0.6546475363,
      score: 0.0,
    },
  },
  {
    position: [-35.4946478, 93.51136883, 47.87999908],
    "overall score": 0.1796104742,
    "0": {
      velocity: [0.1798656813, 0.9590901841, 1.3328769066],
      direction: [-76.8708710222, -24.7877441797, 27.0158723975],
      "speed factor": 0.9828286725,
      "Uz factor": 0.9758116381,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1988522956,
      "Direction stability factor": 0.7114257127,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.8498044252, 1.2595090753, 1.4878891465],
      direction: [-27.3237446899, -0.2772345253, 10.740730969],
      "speed factor": 1.1182874405,
      "Uz factor": 0.9890997857,
      "Directional variance factor": 0.9753569311,
      "Velocity stability factor": 0.5873675521,
      "Direction stability factor": 0.8942653454,
      score: 0.8378137935,
    },
    "67.5": {
      velocity: [0.2865232564, 0.6290279092, 1.0004900304],
      direction: [-33.7112442267, -5.1379132539, 62.8723560907],
      "speed factor": 0.9744061655,
      "Uz factor": 0.9832986469,
      "Directional variance factor": 0.5432965997,
      "Velocity stability factor": 0.3620317573,
      "Direction stability factor": 0.7317122213,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1154359951, 0.7932542417, 1.2007532226],
      direction: [-5.6013382362, 26.9516247543, 96.8676908818],
      "speed factor": 0.8923556045,
      "Uz factor": 0.9834355509,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1015537459,
      "Direction stability factor": 0.715363808,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2825084768, 0.8055096343, 1.1021514506],
      direction: [-25.5919534856, 11.9442415798, 111.4374458301],
      "speed factor": 1.144339764,
      "Uz factor": 0.9808927454,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3297029883,
      "Direction stability factor": 0.6193627797,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2093252796, 0.4556337986, 0.8028143779],
      direction: [-42.2015937295, 8.4845231638, 144.7924965069],
      "speed factor": 0.5338458136,
      "Uz factor": 0.6939824151,
      "Directional variance factor": 0.2458201632,
      "Velocity stability factor": 0.5436649893,
      "Direction stability factor": 0.4805719716,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1244023403, 0.4177663158, 0.7010061353],
      direction: [-92.8471826661, -11.4058462951, 59.7993510417],
      "speed factor": 0.7750068593,
      "Uz factor": 0.9994901758,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4404937472,
      "Direction stability factor": 0.5759818508,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2917010569, 0.6874831279, 1.1215743542],
      direction: [-100.3021277225, -1.7738715875, 59.0070954805],
      "speed factor": 0.9361806147,
      "Uz factor": 0.9975156531,
      "Directional variance factor": 0.8423225256,
      "Velocity stability factor": 0.3914209539,
      "Direction stability factor": 0.55747438,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2392688984, 0.8917260257, 1.2927558853],
      direction: [-25.0980698252, -8.2450287175, 10.6847833698],
      "speed factor": 1.1324367083,
      "Uz factor": 0.9543574045,
      "Directional variance factor": 0.2671085584,
      "Velocity stability factor": 0.1514852646,
      "Direction stability factor": 0.9006031856,
      score: 0.5549500486,
    },
    id: "IDN:685.0",
    "45": {
      velocity: [0.223908031, 0.6021155755, 0.967389855],
      direction: [-77.9950997565, -10.5951110902, 38.0051594954],
      "speed factor": 0.8179026829,
      "Uz factor": 0.9819432524,
      "Directional variance factor": 0.0582123475,
      "Velocity stability factor": 0.3955728031,
      "Direction stability factor": 0.6777770576,
      score: 0.0,
    },
    "90": {
      velocity: [0.3582642806, 0.7461128125, 1.0889236901],
      direction: [-29.9997819424, 23.3367705359, 74.901648596],
      "speed factor": 1.2094707026,
      "Uz factor": 0.9896909089,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4667227269,
      "Direction stability factor": 0.7086071374,
      score: 0.0,
    },
    "135": {
      velocity: [0.6814743342, 1.0809259008, 1.2772932796],
      direction: [-14.194537984, 6.6129279067, 41.3420572149],
      "speed factor": 0.9148419873,
      "Uz factor": 0.9792479846,
      "Directional variance factor": 0.4121841861,
      "Velocity stability factor": 0.5198826533,
      "Direction stability factor": 0.84573168,
      score: 0.0,
    },
    "180": {
      velocity: [0.6118081807, 0.934025954, 1.2166486825],
      direction: [-22.4643015383, -0.8097621379, 22.6880599695],
      "speed factor": 1.824146918,
      "Uz factor": 0.9697705681,
      "Directional variance factor": 0.9280211433,
      "Velocity stability factor": 0.523411765,
      "Direction stability factor": 0.8745767736,
      score: 0.8001466139,
    },
    "225": {
      velocity: [0.1656171198, 0.7073567167, 1.1112890916],
      direction: [-126.2188901, -3.6839393255, 15.1314358176],
      "speed factor": 0.9656193282,
      "Uz factor": 0.9983804357,
      "Directional variance factor": 0.6725387266,
      "Velocity stability factor": 0.1500483685,
      "Direction stability factor": 0.6073602058,
      score: 0.0,
    },
    "270": {
      velocity: [0.1781054516, 0.7129138399, 1.3211594188],
      direction: [-121.894328949, -10.0543152066, 17.5123433674],
      "speed factor": 0.6523391832,
      "Uz factor": 0.9977814293,
      "Directional variance factor": 0.1062830927,
      "Velocity stability factor": 0.098355368,
      "Direction stability factor": 0.6127592436,
      score: 0.0,
    },
    "315": {
      velocity: [0.43228958, 0.8360376498, 1.1240663972],
      direction: [-1.747555046, 6.7852731177, 18.4559776435],
      "speed factor": 1.0300815854,
      "Uz factor": 0.9621460536,
      "Directional variance factor": 0.3968646118,
      "Velocity stability factor": 0.4388057636,
      "Direction stability factor": 0.9438790759,
      score: 0.6808571318,
    },
  },
  {
    position: [-52.35511895, 89.23277832, 46.87999908],
    "overall score": 0.1775015981,
    "0": {
      velocity: [0.5869286281, 1.2282554909, 1.4509122387],
      direction: [-12.1355436016, 1.2300564381, 22.610851759],
      "speed factor": 1.258656103,
      "Uz factor": 0.9887439641,
      "Directional variance factor": 0.8906616499,
      "Velocity stability factor": 0.3996775824,
      "Direction stability factor": 0.9034822351,
      score: 0.7743259256,
    },
    "22.5": {
      velocity: [0.0803893574, 0.5555499111, 1.1420966746],
      direction: [-174.2942931327, 11.7329570885, 163.57934056],
      "speed factor": 0.4932592391,
      "Uz factor": 0.9960926902,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3134220666,
      "Direction stability factor": 0.0614621286,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2192771389, 0.5906219632, 1.1363028348],
      direction: [-43.3401525557, 1.4998310863, 53.7360318464],
      "speed factor": 0.9149127948,
      "Uz factor": 0.9967973827,
      "Directional variance factor": 0.8666816812,
      "Velocity stability factor": 0.1805875384,
      "Direction stability factor": 0.7303439322,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0857231701, 0.3223915639, 0.7741088743],
      direction: [-168.3245564846, 70.5188405693, 179.5282874467],
      "speed factor": 0.3626679869,
      "Uz factor": 0.9715662784,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4301412144,
      "Direction stability factor": 0.0337421002,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0671689283, 0.5854641328, 1.0200359112],
      direction: [-40.8482438671, -10.2505439365, 161.0039407771],
      "speed factor": 0.8317341705,
      "Uz factor": 0.998366553,
      "Directional variance factor": 0.088840539,
      "Velocity stability factor": 0.2207535334,
      "Direction stability factor": 0.4392994871,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4790651395, 1.0592279547, 1.4058675734],
      direction: [-44.1504922327, -8.7531038818, 11.9001276191],
      "speed factor": 1.2410501832,
      "Uz factor": 0.9988378365,
      "Directional variance factor": 0.2219463216,
      "Velocity stability factor": 0.2873796675,
      "Direction stability factor": 0.8443038337,
      score: 0.5494834141,
    },
    "247.5": {
      velocity: [0.1810773258, 0.4254370809, 0.7369074171],
      direction: [-42.4497301219, -1.4473073624, 35.289487878],
      "speed factor": 0.789237053,
      "Uz factor": 0.9434787689,
      "Directional variance factor": 0.8713504567,
      "Velocity stability factor": 0.4606514659,
      "Direction stability factor": 0.7840577278,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4287302953, 0.8057448725, 1.0918546058],
      direction: [-55.7913851159, -20.0823353638, 36.2130910181],
      "speed factor": 1.0972236255,
      "Uz factor": 0.8898867335,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5137046081,
      "Direction stability factor": 0.7444320107,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7612726448, 1.0043079634, 1.2179050176],
      direction: [-3.2053085772, 6.8629950362, 27.0415225809],
      "speed factor": 1.2754087819,
      "Uz factor": 0.970376675,
      "Directional variance factor": 0.3899559968,
      "Velocity stability factor": 0.6322125458,
      "Direction stability factor": 0.9159810246,
      score: 0.7135326479,
    },
    id: "IDN:434.0",
    "45": {
      velocity: [0.0611887811, 0.4372721661, 1.1005607637],
      direction: [-175.6020813289, 1.8313238423, 164.7998808649],
      "speed factor": 0.5939824386,
      "Uz factor": 0.9970254564,
      "Directional variance factor": 0.8372156585,
      "Velocity stability factor": 0.1550234671,
      "Direction stability factor": 0.0544389939,
      score: 0.0,
    },
    "90": {
      velocity: [0.0325164255, 0.310101157, 0.9270211935],
      direction: [-178.47051948, 38.1257612287, 174.8845487743],
      "speed factor": 0.50268305,
      "Uz factor": 0.9727180001,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3471389579,
      "Direction stability factor": 0.0184581437,
      score: 0.0,
    },
    "135": {
      velocity: [0.0650578853, 0.2935701698, 0.5868074045],
      direction: [-178.480734661, 98.3602584457, 164.2822404219],
      "speed factor": 0.2484632086,
      "Uz factor": 0.8730869925,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5795685975,
      "Direction stability factor": 0.0478806248,
      score: 0.0,
    },
    "180": {
      velocity: [0.106722381, 0.8051467289, 1.3422856965],
      direction: [-61.0960350225, -30.6026251341, 2.5550166259],
      "speed factor": 1.5724465876,
      "Uz factor": 0.9932152107,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0264293842,
      "Direction stability factor": 0.8231915232,
      score: 0.0,
    },
    "225": {
      velocity: [0.4807713721, 0.8494703213, 1.1198371603],
      direction: [-17.2702477047, 0.1253855148, 19.4030165965],
      "speed factor": 1.1596199507,
      "Uz factor": 0.9853062906,
      "Directional variance factor": 0.9888546209,
      "Velocity stability factor": 0.425620061,
      "Direction stability factor": 0.8981298214,
      score: 0.8026835812,
    },
    "270": {
      velocity: [0.7616255776, 1.0338891924, 1.2453928466],
      direction: [-23.0265294904, -4.7540569402, 11.4842585907],
      "speed factor": 0.9460419949,
      "Uz factor": 0.9091797256,
      "Directional variance factor": 0.5774171609,
      "Velocity stability factor": 0.618402828,
      "Direction stability factor": 0.9041366998,
      score: 0.0,
    },
    "315": {
      velocity: [0.6734068129, 0.8707628796, 1.1000243508],
      direction: [-14.8110301035, 2.9441410262, 19.0530527069],
      "speed factor": 1.0728665242,
      "Uz factor": 0.9484021103,
      "Directional variance factor": 0.7382985754,
      "Velocity stability factor": 0.6539125083,
      "Direction stability factor": 0.9059331033,
      score: 0.0,
    },
  },
  {
    position: [-52.57487212, 86.24083772, 46.87999908],
    "overall score": 0.1751729943,
    "0": {
      velocity: [0.5288131823, 1.1353979737, 1.4149191944],
      direction: [-15.7621611773, -0.6893092959, 64.87314405],
      "speed factor": 1.1635002648,
      "Uz factor": 0.9962008143,
      "Directional variance factor": 0.9387280626,
      "Velocity stability factor": 0.3843062566,
      "Direction stability factor": 0.776013041,
      score: 0.7187651003,
    },
    "22.5": {
      velocity: [0.1272475333, 0.5921752263, 1.0713357708],
      direction: [-130.304334712, 20.5344813522, 176.4005023237],
      "speed factor": 0.5257779647,
      "Uz factor": 0.9941680437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3894832025,
      "Direction stability factor": 0.1480421193,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0870135458, 0.5381966668, 1.0279388257],
      direction: [-75.7965883242, 4.0911744904, 88.8198153324],
      "speed factor": 0.8337025158,
      "Uz factor": 0.9947883485,
      "Directional variance factor": 0.6363400453,
      "Velocity stability factor": 0.159231957,
      "Direction stability factor": 0.5427322121,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0114765521, 0.3412151158, 1.0148502256],
      direction: [-158.84662995, 18.089739031, 176.4095247214],
      "speed factor": 0.3838431677,
      "Uz factor": 0.991249883,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1693881792,
      "Direction stability factor": 0.0687329037,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0702088983, 0.673880351, 1.1732756685],
      direction: [-138.5227102198, -16.0252082424, 116.5571196755],
      "speed factor": 0.957341848,
      "Uz factor": 0.9985996385,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0979214324,
      "Direction stability factor": 0.291444917,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.482477162, 1.0483441588, 1.3645660249],
      direction: [-31.5119951694, -9.0909313397, 13.5533525184],
      "speed factor": 1.2282981247,
      "Uz factor": 0.9992505641,
      "Directional variance factor": 0.1919172142,
      "Velocity stability factor": 0.3217600258,
      "Direction stability factor": 0.8748184786,
      score: 0.5658285493,
    },
    "247.5": {
      velocity: [0.1744737938, 0.4238745737, 0.7238905798],
      direction: [-40.0388504809, 0.7464122711, 38.3419160862],
      "speed factor": 0.7863384139,
      "Uz factor": 0.9573051308,
      "Directional variance factor": 0.9336522426,
      "Velocity stability factor": 0.4668746029,
      "Direction stability factor": 0.7822756484,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4433369423, 0.8382225073, 1.1137222232],
      direction: [-50.0660466683, -15.0445570734, 33.9315251162],
      "speed factor": 1.1414500666,
      "Uz factor": 0.8893800901,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5083798502,
      "Direction stability factor": 0.7666734117,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6474805118, 0.9984796026, 1.228720971],
      direction: [-6.0589388998, 4.6678709976, 20.633856458],
      "speed factor": 1.2680071254,
      "Uz factor": 0.9751831264,
      "Directional variance factor": 0.5850781335,
      "Velocity stability factor": 0.5318488975,
      "Direction stability factor": 0.9258533462,
      score: 0.7421584309,
    },
    id: "IDN:435.0",
    "45": {
      velocity: [0.0773697413, 0.4663286188, 0.9588274993],
      direction: [-141.435931292, 6.5473041714, 147.9087073752],
      "speed factor": 0.6334521876,
      "Uz factor": 0.9966632153,
      "Directional variance factor": 0.418017407,
      "Velocity stability factor": 0.2834027348,
      "Direction stability factor": 0.1962648926,
      score: 0.0,
    },
    "90": {
      velocity: [0.0530827531, 0.3071525934, 0.7910416663],
      direction: [-168.0716389584, 16.7735978183, 175.8326936727],
      "speed factor": 0.4979033422,
      "Uz factor": 0.9167891089,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4613951291,
      "Direction stability factor": 0.0447101871,
      score: 0.0,
    },
    "135": {
      velocity: [0.0311713396, 0.2540864939, 0.9797736054],
      direction: [-178.1871058211, -17.7097883494, 176.1966142329],
      "speed factor": 0.2150461866,
      "Uz factor": 0.9505975861,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2356060403,
      "Direction stability factor": 0.0156007776,
      score: 0.0,
    },
    "180": {
      velocity: [0.2425027815, 0.8708152127, 1.2829853358],
      direction: [-67.5742839268, -30.908110207, 12.7040726824],
      "speed factor": 1.7006967309,
      "Uz factor": 0.9941028185,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1801446122,
      "Direction stability factor": 0.777004565,
      score: 0.0,
    },
    "225": {
      velocity: [0.5377129573, 0.8250864967, 1.0818702411],
      direction: [-15.5041075915, 2.2728286642, 21.3651943752],
      "speed factor": 1.1263333618,
      "Uz factor": 0.9851567538,
      "Directional variance factor": 0.7979707854,
      "Velocity stability factor": 0.5109219846,
      "Direction stability factor": 0.8975852723,
      score: 0.7760158287,
    },
    "270": {
      velocity: [0.7743364959, 1.0287653398, 1.2367883059],
      direction: [-17.0335325777, -0.1082986553, 16.5095748682],
      "speed factor": 0.9413535044,
      "Uz factor": 0.8954649745,
      "Directional variance factor": 0.9903734529,
      "Velocity stability factor": 0.6352165305,
      "Direction stability factor": 0.9068247015,
      score: 0.0,
    },
    "315": {
      velocity: [0.6250432178, 0.883539812, 1.1434833429],
      direction: [-13.2220593488, 1.7068062225, 15.2828097139],
      "speed factor": 1.0886089765,
      "Uz factor": 0.9480560085,
      "Directional variance factor": 0.8482838913,
      "Velocity stability factor": 0.5794227229,
      "Direction stability factor": 0.9208198082,
      score: 0.0,
    },
  },
  {
    position: [-52.13536577, 92.22471892, 46.87999908],
    "overall score": 0.1716767476,
    "0": {
      velocity: [0.8158066087, 1.2374008798, 1.4155176127],
      direction: [-8.9047060605, 2.5748707778, 20.4072700076],
      "speed factor": 1.2680278499,
      "Uz factor": 0.9775914976,
      "Directional variance factor": 0.7711225975,
      "Velocity stability factor": 0.5833023273,
      "Direction stability factor": 0.9185778443,
      score: 0.7978951533,
    },
    "22.5": {
      velocity: [0.1628900651, 0.8046985004, 1.2715402983],
      direction: [-39.84544464, 15.3054769347, 102.8065917499],
      "speed factor": 0.714472205,
      "Uz factor": 0.9787873912,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2830653291,
      "Direction stability factor": 0.6037443434,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1997676274, 0.6043333336, 1.2013027766],
      direction: [-30.0167653546, -2.8458121393, 38.0305467654],
      "speed factor": 0.9361526217,
      "Uz factor": 0.9999991583,
      "Directional variance factor": 0.747038921,
      "Velocity stability factor": 0.1050737338,
      "Direction stability factor": 0.8109796886,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0563187725, 0.3319094291, 0.8677112348],
      direction: [-174.5545605387, 88.3536450005, 179.8545098047],
      "speed factor": 0.3733749202,
      "Uz factor": 0.9842244781,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3283138792,
      "Direction stability factor": 0.0155303602,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0777956041, 0.4529964672, 1.0196825537],
      direction: [-175.9808542991, -3.5669111221, 155.2714276454],
      "speed factor": 0.6435452144,
      "Uz factor": 0.9995964177,
      "Directional variance factor": 0.6829412336,
      "Velocity stability factor": 0.2297329107,
      "Direction stability factor": 0.0798547724,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5966993466, 1.0832631534, 1.4285594461],
      direction: [-41.243925398, -9.6202027561, 9.862028851],
      "speed factor": 1.2692111543,
      "Uz factor": 0.9989744902,
      "Directional variance factor": 0.1448708661,
      "Velocity stability factor": 0.3603810272,
      "Direction stability factor": 0.858039016,
      score: 0.5553324813,
    },
    "247.5": {
      velocity: [0.2224727285, 0.4335035608, 0.7691222859],
      direction: [-40.4775556467, -3.7864841524, 38.6379715634],
      "speed factor": 0.8042013453,
      "Uz factor": 0.9479732255,
      "Directional variance factor": 0.6634236309,
      "Velocity stability factor": 0.4695597773,
      "Direction stability factor": 0.7802346466,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4201895722, 0.7719670405, 1.0661395816],
      direction: [-56.7827924209, -23.7084231364, 36.7313320907],
      "speed factor": 1.0512266399,
      "Uz factor": 0.9062552908,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5262992052,
      "Direction stability factor": 0.740238543,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7702764549, 0.9858322891, 1.1828386182],
      direction: [-0.2672149256, 9.6647619729, 30.7237447548],
      "speed factor": 1.2519458222,
      "Uz factor": 0.952712675,
      "Directional variance factor": 0.1409100469,
      "Velocity stability factor": 0.6677082117,
      "Direction stability factor": 0.9139140009,
      score: 0.6591115651,
    },
    id: "IDN:433.0",
    "45": {
      velocity: [0.1308685956, 0.6064971818, 1.3545691882],
      direction: [-37.3105469314, -3.1155004384, 103.2226787106],
      "speed factor": 0.8238545761,
      "Uz factor": 0.9858372878,
      "Directional variance factor": 0.7230666277,
      "Velocity stability factor": 0.0051701399,
      "Direction stability factor": 0.6096299288,
      score: 0.0,
    },
    "90": {
      velocity: [0.0285779681, 0.382645036, 1.1028855352],
      direction: [-176.9008617031, 27.2311329696, 170.4453648265],
      "speed factor": 0.6202788008,
      "Uz factor": 0.9993383167,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2159085308,
      "Direction stability factor": 0.0351493708,
      score: 0.0,
    },
    "135": {
      velocity: [0.1060466153, 0.3143217632, 0.7437203512],
      direction: [-165.6716932501, 103.3113918101, 179.7101023035],
      "speed factor": 0.2660263264,
      "Uz factor": 0.9688725388,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4861556107,
      "Direction stability factor": 0.0406061235,
      score: 0.0,
    },
    "180": {
      velocity: [0.1498032466, 0.7103539238, 1.3690499356],
      direction: [-73.6412558833, -27.4514109742, 110.9964315007],
      "speed factor": 1.3873168248,
      "Uz factor": 0.9940954144,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0392861824,
      "Direction stability factor": 0.487117535,
      score: 0.0,
    },
    "225": {
      velocity: [0.4827567652, 0.877371441, 1.1560073346],
      direction: [-21.2899243143, -2.7606237728, 16.7893664401],
      "speed factor": 1.1977080326,
      "Uz factor": 0.9894843077,
      "Directional variance factor": 0.7546112202,
      "Velocity stability factor": 0.3948954426,
      "Direction stability factor": 0.8942241923,
      score: 0.7344887619,
    },
    "270": {
      velocity: [0.7636561235, 1.0477709653, 1.290724011],
      direction: [-27.5189706614, -10.7278376372, 5.2708518679],
      "speed factor": 0.9587442654,
      "Uz factor": 0.9376991985,
      "Directional variance factor": 0.0464144323,
      "Velocity stability factor": 0.5842471614,
      "Direction stability factor": 0.9089171596,
      score: 0.0,
    },
    "315": {
      velocity: [0.6771616961, 0.8478204943, 1.039880186],
      direction: [-13.4080769194, 5.5646072064, 24.9291282146],
      "speed factor": 1.0445992225,
      "Uz factor": 0.9437170438,
      "Directional variance factor": 0.5053682483,
      "Velocity stability factor": 0.7057497144,
      "Direction stability factor": 0.8935077635,
      score: 0.0,
    },
  },
  {
    position: [-45.83106275, 46.13737702, 47.87999908],
    "overall score": 0.1688829703,
    "0": {
      velocity: [0.3080395284, 0.9130652836, 1.3189618603],
      direction: [-51.6088452158, -12.7384713022, 137.2950447897],
      "speed factor": 0.9356646073,
      "Uz factor": 0.9985948365,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2975800341,
      "Direction stability factor": 0.4752669722,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2579824634, 0.8655017576, 1.2986459376],
      direction: [-96.9518841238, -2.1699987259, 109.1930088379],
      "speed factor": 0.7684579367,
      "Uz factor": 0.9985178546,
      "Directional variance factor": 0.8071112244,
      "Velocity stability factor": 0.3270305611,
      "Direction stability factor": 0.4273752973,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3110591962, 0.6070752146, 0.973833238],
      direction: [-46.3640474964, 7.1170163717, 66.6401742646],
      "speed factor": 0.9403999781,
      "Uz factor": 0.9684254003,
      "Directional variance factor": 0.3673763225,
      "Velocity stability factor": 0.4077752548,
      "Direction stability factor": 0.686099384,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5313079551, 0.8803684492, 1.1864256663],
      direction: [-37.0062969342, -11.9653070344, 10.7696626082],
      "speed factor": 0.9903530021,
      "Uz factor": 0.9317066081,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.457681092,
      "Direction stability factor": 0.8672890013,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5824556005, 1.0127655007, 1.3041509028],
      direction: [-27.0171926447, -3.9320867506, 11.1233229661],
      "speed factor": 1.4387758814,
      "Uz factor": 0.9866263793,
      "Directional variance factor": 0.6504811777,
      "Velocity stability factor": 0.4098037561,
      "Direction stability factor": 0.8940541233,
      score: 0.7120982951,
    },
    "202.5": {
      velocity: [0.0294473009, 0.5311892235, 1.0862182875],
      direction: [-151.9745056326, -11.9984601337, 58.1265703905],
      "speed factor": 0.6223707373,
      "Uz factor": 0.9887707574,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1874465751,
      "Direction stability factor": 0.4163858999,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3607295269, 0.7513184665, 1.1093955524],
      direction: [-27.2464557206, 1.7884558169, 14.1153132701],
      "speed factor": 1.3937862939,
      "Uz factor": 0.9989545896,
      "Directional variance factor": 0.8410261496,
      "Velocity stability factor": 0.2735335318,
      "Direction stability factor": 0.8851061972,
      score: 0.721193019,
    },
    "292.5": {
      velocity: [0.1025315268, 0.5365431144, 1.0859810017],
      direction: [-57.2263707675, 7.911655739, 149.29429095],
      "speed factor": 0.7306379492,
      "Uz factor": 0.9824430939,
      "Directional variance factor": 0.2967417121,
      "Velocity stability factor": 0.2787974438,
      "Direction stability factor": 0.4263314952,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0608321518, 0.6045428807, 1.1601883374],
      direction: [-150.5310059256, -6.5227957241, 84.927507677],
      "speed factor": 0.7677319379,
      "Uz factor": 0.9976560097,
      "Directional variance factor": 0.4201959356,
      "Velocity stability factor": 0.1145406308,
      "Direction stability factor": 0.3459485733,
      score: 0.0,
    },
    id: "IDN:744.0",
    "45": {
      velocity: [0.1396551085, 0.5682736761, 1.082490199],
      direction: [-149.5776401391, 1.4218964435, 171.014045909],
      "speed factor": 0.7719324714,
      "Uz factor": 0.983833116,
      "Directional variance factor": 0.873609205,
      "Velocity stability factor": 0.2335049057,
      "Direction stability factor": 0.1094675388,
      score: 0.0,
    },
    "90": {
      velocity: [0.6181346453, 1.0294691155, 1.2889126818],
      direction: [-43.4719461196, -2.2894831079, 32.4546613127],
      "speed factor": 1.6687995618,
      "Uz factor": 0.9532844861,
      "Directional variance factor": 0.7964903904,
      "Velocity stability factor": 0.510427598,
      "Direction stability factor": 0.7890927571,
      score: 0.7212758757,
    },
    "135": {
      velocity: [0.2349320022, 0.686132632, 1.0639016085],
      direction: [-116.1703709032, -26.0616305538, 20.5188816729],
      "speed factor": 0.5807085759,
      "Uz factor": 0.9510909116,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3320073305,
      "Direction stability factor": 0.6203076317,
      score: 0.0,
    },
    "180": {
      velocity: [0.2344534246, 0.8520973437, 1.1912253613],
      direction: [-32.3054323242, -5.3702034126, 71.8267666573],
      "speed factor": 1.6641408484,
      "Uz factor": 0.9966593128,
      "Directional variance factor": 0.5226485855,
      "Velocity stability factor": 0.2461049694,
      "Direction stability factor": 0.7107438917,
      score: 0.5475603346,
    },
    "225": {
      velocity: [0.0884991358, 0.5036098229, 0.9070631762],
      direction: [-64.776584885, 1.3287401855, 162.4827138152],
      "speed factor": 0.687482521,
      "Uz factor": 0.9999938144,
      "Directional variance factor": 0.8818897613,
      "Velocity stability factor": 0.2642905125,
      "Direction stability factor": 0.3687241703,
      score: 0.0,
    },
    "270": {
      velocity: [0.1444080126, 0.719136779, 1.2768684586],
      direction: [-11.0157748323, 16.961260715, 170.2141387847],
      "speed factor": 0.6580333734,
      "Uz factor": 0.9917894688,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1067115715,
      "Direction stability factor": 0.4965835733,
      score: 0.0,
    },
    "315": {
      velocity: [0.0815015668, 0.559644634, 1.2781111265],
      direction: [-20.1914883624, -2.361792904, 110.2374991271],
      "speed factor": 0.6895378838,
      "Uz factor": 0.9931532345,
      "Directional variance factor": 0.790062853,
      "Velocity stability factor": 0.0292672847,
      "Direction stability factor": 0.637697257,
      score: 0.0,
    },
  },
  {
    position: [-54.78806345, 32.40355892, 46.87999908],
    "overall score": 0.1683928404,
    "0": {
      velocity: [0.280094577, 0.9861352103, 1.4415061832],
      direction: [-30.747188847, -11.3534312549, 33.0486370854],
      "speed factor": 1.0105430914,
      "Uz factor": 0.9970877242,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1930154523,
      "Direction stability factor": 0.8227893724,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0607760515, 0.6396507359, 1.169846117],
      direction: [-121.0817396792, -4.2513144874, 101.3531476511],
      "speed factor": 0.5679303137,
      "Uz factor": 0.9973096641,
      "Directional variance factor": 0.6221053789,
      "Velocity stability factor": 0.2827938347,
      "Direction stability factor": 0.382125313,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.058559478, 0.5818326599, 1.0529007121],
      direction: [-147.7442901728, 0.3773374368, 50.6825019922],
      "speed factor": 0.9012975781,
      "Uz factor": 0.9946397433,
      "Directional variance factor": 0.9664588945,
      "Velocity stability factor": 0.1115018893,
      "Direction stability factor": 0.4488144662,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3345064076, 0.8246382088, 1.1316259315],
      direction: [-65.6742364156, -27.6567956527, 0.7573770774],
      "speed factor": 0.927660375,
      "Uz factor": 0.9952917917,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3401292892,
      "Direction stability factor": 0.8154677403,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5224152917, 0.9981865117, 1.2788107763],
      direction: [-36.8218107092, -12.4289782448, 4.7519274165],
      "speed factor": 1.4180643764,
      "Uz factor": 0.9898830205,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3814262439,
      "Direction stability factor": 0.8845173941,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5575704338, 0.9092519428, 1.0946853876],
      direction: [-27.1091673851, -8.9310473536, 3.7099438354],
      "speed factor": 1.0653299748,
      "Uz factor": 0.975967089,
      "Directional variance factor": 0.2061291241,
      "Velocity stability factor": 0.587011187,
      "Direction stability factor": 0.9143913577,
      score: 0.6554807567,
    },
    "247.5": {
      velocity: [0.5093103763, 0.7803391732, 0.9611042085],
      direction: [-15.7268118591, 6.3671485816, 13.5943896719],
      "speed factor": 1.4476232021,
      "Uz factor": 0.9905703682,
      "Directional variance factor": 0.4340312372,
      "Velocity stability factor": 0.5616028263,
      "Direction stability factor": 0.918552218,
      score: 0.7081846248,
    },
    "292.5": {
      velocity: [0.8465624034, 1.1752569618, 1.3826689522],
      direction: [7.2382107817, 20.6976434338, 32.474924998],
      "speed factor": 1.6004069632,
      "Uz factor": 0.9573789912,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6068517771,
      "Direction stability factor": 0.9298980161,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2862874601, 0.8318182871, 1.1373816302],
      direction: [-21.9572559869, -0.9902392164, 44.6740880838],
      "speed factor": 1.0563575984,
      "Uz factor": 0.9730899312,
      "Directional variance factor": 0.9119787363,
      "Velocity stability factor": 0.3144994162,
      "Direction stability factor": 0.8149129331,
      score: 0.7140760047,
    },
    id: "IDN:460.0",
    "45": {
      velocity: [0.1450125388, 0.4671468045, 0.9638204337],
      direction: [-154.1459476493, 6.3096882713, 109.381435767],
      "speed factor": 0.6345635958,
      "Uz factor": 0.993397637,
      "Directional variance factor": 0.4391388203,
      "Velocity stability factor": 0.3343350912,
      "Direction stability factor": 0.2679794905,
      score: 0.0,
    },
    "90": {
      velocity: [0.3089317807, 0.8095006451, 1.2138736323],
      direction: [-43.7395049428, -13.8135756108, 5.3213173374],
      "speed factor": 1.3122242342,
      "Uz factor": 0.9823322086,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3395213739,
      "Direction stability factor": 0.8637199381,
      score: 0.0,
    },
    "135": {
      velocity: [0.1881860303, 0.6119111023, 0.9235852934],
      direction: [-86.567116354, -40.1448979898, 10.727793437],
      "speed factor": 0.5178911602,
      "Uz factor": 0.9997159855,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4074073244,
      "Direction stability factor": 0.7297363617,
      score: 0.0,
    },
    "180": {
      velocity: [0.5705789375, 0.83582011, 1.050546289],
      direction: [-47.0414905039, -12.8356875741, 17.1172360652],
      "speed factor": 1.6323515114,
      "Uz factor": 0.9881725942,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.621806423,
      "Direction stability factor": 0.8217813151,
      score: 0.0,
    },
    "225": {
      velocity: [0.1716089278, 0.5265744398, 0.8907941509],
      direction: [-48.1729833684, 1.7086135832, 42.5203653817],
      "speed factor": 0.7188317362,
      "Uz factor": 0.9865738804,
      "Directional variance factor": 0.848123237,
      "Velocity stability factor": 0.3536102665,
      "Direction stability factor": 0.7480740312,
      score: 0.0,
    },
    "270": {
      velocity: [0.5563216031, 0.9051250762, 1.2474633863],
      direction: [-3.8503313795, 27.249162518, 48.2858381033],
      "speed factor": 0.8282186709,
      "Uz factor": 0.9693506093,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4548251468,
      "Direction stability factor": 0.855177307,
      score: 0.0,
    },
    "315": {
      velocity: [0.7424130606, 1.176996444, 1.3826197993],
      direction: [-1.4473438412, 9.4895295416, 29.3238878467],
      "speed factor": 1.4501767514,
      "Uz factor": 0.9807459325,
      "Directional variance factor": 0.156486263,
      "Velocity stability factor": 0.4806412662,
      "Direction stability factor": 0.9145243564,
      score: 0.6165440605,
    },
  },
  {
    position: [-50.05926353, 53.82218301, 46.87999908],
    "overall score": 0.1666277672,
    "0": {
      velocity: [0.2673496544, 0.9876123004, 1.4298875148],
      direction: [-37.6884986105, -16.8518264208, 28.1026118702],
      "speed factor": 1.0120567411,
      "Uz factor": 0.9872586603,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1922328961,
      "Direction stability factor": 0.8172469153,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0470305912, 0.6269474263, 1.1387930151],
      direction: [-114.7956769055, 6.2878037282, 147.236658298],
      "speed factor": 0.5566513544,
      "Uz factor": 0.9938035575,
      "Directional variance factor": 0.441084113,
      "Velocity stability factor": 0.293986227,
      "Direction stability factor": 0.2721324022,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1976654574, 0.5438103138, 0.9151965775],
      direction: [-52.3744428837, 19.2733264644, 55.3273637749],
      "speed factor": 0.8423984292,
      "Uz factor": 0.9457503022,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3588468196,
      "Direction stability factor": 0.7008283148,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6011743214, 0.9174994225, 1.2525830348],
      direction: [-26.8312815503, -7.5981424127, 14.3684478964],
      "speed factor": 1.0321227531,
      "Uz factor": 0.9226173894,
      "Directional variance factor": 0.3246095633,
      "Velocity stability factor": 0.460751471,
      "Direction stability factor": 0.8855563071,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2155849667, 0.8557578928, 1.2698318301],
      direction: [-58.0281250086, -10.8074819056, 35.594842021],
      "speed factor": 1.2157244847,
      "Uz factor": 0.9981194387,
      "Directional variance factor": 0.0393349417,
      "Velocity stability factor": 0.1378459345,
      "Direction stability factor": 0.7399362027,
      score: 0.4142633204,
    },
    "202.5": {
      velocity: [0.096705038, 0.6199642476, 1.0889071347],
      direction: [-116.5395089654, -9.4178899941, 20.5962952463],
      "speed factor": 0.7263844762,
      "Uz factor": 0.999254329,
      "Directional variance factor": 0.1628542227,
      "Velocity stability factor": 0.2370937297,
      "Direction stability factor": 0.6190672105,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1518975109, 0.7175635701, 1.064087665],
      direction: [-32.6381632118, -6.1845199353, 13.787666577],
      "speed factor": 1.3311668934,
      "Uz factor": 0.9966362392,
      "Directional variance factor": 0.4502648946,
      "Velocity stability factor": 0.1148582452,
      "Direction stability factor": 0.8710393617,
      score: 0.5768004658,
    },
    "292.5": {
      velocity: [0.1243642368, 0.8688944129, 1.2273264569],
      direction: [-61.9726585152, -8.491831403, 40.6675987668],
      "speed factor": 1.1832175549,
      "Uz factor": 0.9569146747,
      "Directional variance factor": 0.245170542,
      "Velocity stability factor": 0.1911540015,
      "Direction stability factor": 0.7148881742,
      score: 0.466525223,
    },
    "337.5": {
      velocity: [0.4197164174, 0.8975828272, 1.1994026211],
      direction: [-28.152029935, -6.3144155585, 9.6901328302],
      "speed factor": 1.1398744827,
      "Uz factor": 0.971110569,
      "Directional variance factor": 0.438718617,
      "Velocity stability factor": 0.372013854,
      "Direction stability factor": 0.8948828812,
      score: 0.6501245584,
    },
    id: "IDN:473.0",
    "45": {
      velocity: [0.1230787836, 0.5142545854, 0.9390313176],
      direction: [-124.632417477, 14.7241619518, 109.560196351],
      "speed factor": 0.6985539358,
      "Uz factor": 0.9749359476,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3366564092,
      "Direction stability factor": 0.3494649616,
      score: 0.0,
    },
    "90": {
      velocity: [0.6129546735, 1.0444564557, 1.3748889866],
      direction: [-19.4015547434, 4.1086547562, 16.1706304521],
      "speed factor": 1.6930944788,
      "Uz factor": 0.9264357346,
      "Directional variance factor": 0.6347862439,
      "Velocity stability factor": 0.443896503,
      "Direction stability factor": 0.9011883745,
      score: 0.0,
    },
    "135": {
      velocity: [0.3177511656, 0.699716734, 1.0289684747],
      direction: [-79.0814263847, -30.8560264496, 16.5259757854],
      "speed factor": 0.5922054851,
      "Uz factor": 0.8915850203,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4268934044,
      "Direction stability factor": 0.7344238829,
      score: 0.0,
    },
    "180": {
      velocity: [0.1255141246, 0.5011223565, 0.9695884051],
      direction: [-99.8327087499, -25.830907175, 78.0121900151],
      "speed factor": 0.9786888666,
      "Uz factor": 0.9991806747,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.334905863,
      "Direction stability factor": 0.5059863923,
      score: 0.0,
    },
    "225": {
      velocity: [0.0560739891, 0.4446714988, 0.8467676021],
      direction: [-158.6712940565, 0.1279293212, 38.5240166927],
      "speed factor": 0.6070252587,
      "Uz factor": 0.9968607504,
      "Directional variance factor": 0.9886285048,
      "Velocity stability factor": 0.2893399122,
      "Direction stability factor": 0.4522352479,
      score: 0.0,
    },
    "270": {
      velocity: [0.1086673953, 0.6873142137, 1.1046589312],
      direction: [-132.1332593317, 4.0336658148, 98.5763668094],
      "speed factor": 0.6289146986,
      "Uz factor": 0.9477134003,
      "Directional variance factor": 0.6414519276,
      "Velocity stability factor": 0.214358685,
      "Direction stability factor": 0.3591399274,
      score: 0.0,
    },
    "315": {
      velocity: [0.5680682507, 1.0587494031, 1.3389357462],
      direction: [-28.6513256789, -10.1248152476, 14.7895422482],
      "speed factor": 1.3044846293,
      "Uz factor": 0.9872673494,
      "Directional variance factor": 0.1000164224,
      "Velocity stability factor": 0.3746445606,
      "Direction stability factor": 0.8793309224,
      score: 0.558330707,
    },
  },
  {
    position: [-47.1184631, 79.54707423, 47.87999908],
    "overall score": 0.166602666,
    "0": {
      velocity: [0.1574650811, 0.590995176, 1.2318589521],
      direction: [-110.3880784161, 2.7767939454, 132.5733113732],
      "speed factor": 0.6056229268,
      "Uz factor": 0.9993333826,
      "Directional variance factor": 0.7531738715,
      "Velocity stability factor": 0.2534780543,
      "Direction stability factor": 0.3251072506,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3944472536, 0.852051188, 1.3144636025],
      direction: [-39.2232444387, 20.3913509005, 117.4146517728],
      "speed factor": 0.7565155034,
      "Uz factor": 0.9970475521,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4050498538,
      "Direction stability factor": 0.5648947327,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1380919436, 0.5300508672, 0.9660909647],
      direction: [-52.2099000396, -0.4534472482, 55.8007391814],
      "speed factor": 0.8210841291,
      "Uz factor": 0.9784237002,
      "Directional variance factor": 0.9596935779,
      "Velocity stability factor": 0.2601377267,
      "Direction stability factor": 0.6999704466,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2612514038, 0.8643639773, 1.1671504293],
      direction: [-33.1192855673, -3.3640249313, 26.0143153719],
      "speed factor": 0.9723490892,
      "Uz factor": 0.971828878,
      "Directional variance factor": 0.7009755617,
      "Velocity stability factor": 0.2500795477,
      "Direction stability factor": 0.8357399974,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4239749609, 0.8439673784, 1.1253489528],
      direction: [-46.7733904279, -13.1323653158, 14.5185214404],
      "speed factor": 1.1989744002,
      "Uz factor": 0.9727494334,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4264223499,
      "Direction stability factor": 0.8297446893,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3985648521, 0.7347934125, 1.1565569416],
      direction: [-65.6238095032, -13.9571292708, 26.7662879807],
      "speed factor": 0.8609246906,
      "Uz factor": 0.9894904301,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4171782948,
      "Direction stability factor": 0.7433608403,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2924851326, 0.5018775922, 0.8144019222],
      direction: [-31.9207228199, -3.8602624834, 39.9614253881],
      "speed factor": 0.931043413,
      "Uz factor": 0.9946224806,
      "Directional variance factor": 0.656865557,
      "Velocity stability factor": 0.4935591654,
      "Direction stability factor": 0.8003273661,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1170098108, 0.7911247862, 1.3127151229],
      direction: [-71.573372688, -6.8258950706, 41.7111978107],
      "speed factor": 1.077314713,
      "Uz factor": 0.9861322017,
      "Directional variance factor": 0.3932537715,
      "Velocity stability factor": 0.123141809,
      "Direction stability factor": 0.6853206375,
      score: 0.4717592139,
    },
    "337.5": {
      velocity: [0.0626712163, 0.6353876944, 1.2395273103],
      direction: [-60.5665054262, 1.6875871441, 156.0376350078],
      "speed factor": 0.806902937,
      "Uz factor": 0.9998999045,
      "Directional variance factor": 0.8499922539,
      "Velocity stability factor": 0.052119533,
      "Direction stability factor": 0.3983218321,
      score: 0.0,
    },
    id: "IDN:708.0",
    "45": {
      velocity: [0.1780670718, 0.4603076308, 0.8760716942],
      direction: [-71.1968737183, 3.5706994649, 79.8677683677],
      "speed factor": 0.6252733885,
      "Uz factor": 0.9765148119,
      "Directional variance factor": 0.682604492,
      "Velocity stability factor": 0.432544329,
      "Direction stability factor": 0.5803759942,
      score: 0.0,
    },
    "90": {
      velocity: [0.5530504859, 1.0193203262, 1.2990804508],
      direction: [-13.270269927, 3.269565213, 22.172970082],
      "speed factor": 1.6523480773,
      "Uz factor": 0.9844562252,
      "Directional variance factor": 0.7093719811,
      "Velocity stability factor": 0.4555044112,
      "Direction stability factor": 0.9015465555,
      score: 0.7419923758,
    },
    "135": {
      velocity: [0.3553345538, 0.9743942896, 1.2343083758],
      direction: [-51.9007163674, -19.9407951637, 10.8401452923],
      "speed factor": 0.8246789235,
      "Uz factor": 0.9687477674,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.291713393,
      "Direction stability factor": 0.8257198287,
      score: 0.0,
    },
    "180": {
      velocity: [0.4426036198, 0.8183258229, 1.1924324075],
      direction: [-46.545970854, -24.7207519912, -8.5254263136],
      "speed factor": 1.5981852768,
      "Uz factor": 0.9824081891,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4091672474,
      "Direction stability factor": 0.8943873763,
      score: 0.0,
    },
    "225": {
      velocity: [0.5359080627, 0.7388996152, 1.0732979732],
      direction: [-18.0854510801, 2.2303040036, 21.6240970146],
      "speed factor": 1.0086788366,
      "Uz factor": 0.9949206203,
      "Directional variance factor": 0.8017507552,
      "Velocity stability factor": 0.5170043685,
      "Direction stability factor": 0.8896956997,
      score: 0.7745366308,
    },
    "270": {
      velocity: [0.5949169634, 1.0356909192, 1.3690804903],
      direction: [-14.0513912296, 6.1208386725, 22.9739324125],
      "speed factor": 0.9476906331,
      "Uz factor": 0.9873545382,
      "Directional variance factor": 0.4559254513,
      "Velocity stability factor": 0.3893373294,
      "Direction stability factor": 0.8971518788,
      score: 0.0,
    },
    "315": {
      velocity: [0.1928455411, 0.8596350911, 1.2007583639],
      direction: [-13.4173258355, -1.5852600633, 46.3453947624],
      "speed factor": 1.0591559815,
      "Uz factor": 0.9964107725,
      "Directional variance factor": 0.8590879944,
      "Velocity stability factor": 0.1823448649,
      "Direction stability factor": 0.8339924428,
      score: 0.6773544362,
    },
  },
  {
    position: [-46.71443165, 79.6549658, 47.87999908],
    "overall score": 0.1662890065,
    "0": {
      velocity: [0.1692124936, 0.562745238, 1.2223153213],
      direction: [-99.5153774269, 3.9772112013, 133.8883324791],
      "speed factor": 0.5766737731,
      "Uz factor": 0.999939152,
      "Directional variance factor": 0.6464701154,
      "Velocity stability factor": 0.2682717268,
      "Direction stability factor": 0.3516563614,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4182512005, 0.8653376739, 1.3186990961],
      direction: [-46.0532492244, 19.452101026, 119.9242527338],
      "speed factor": 0.7683122507,
      "Uz factor": 0.9964066813,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.417704253,
      "Direction stability factor": 0.5389513834,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1486012133, 0.5312369005, 0.9439759269],
      direction: [-54.5654453849, -0.8919562785, 54.3155898848],
      "speed factor": 0.8229213738,
      "Uz factor": 0.9789836094,
      "Directional variance factor": 0.9207149975,
      "Velocity stability factor": 0.2892893244,
      "Direction stability factor": 0.6975526798,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2602827208, 0.8583647165, 1.1562614586],
      direction: [-32.9885381271, -3.3258084507, 25.9076282478],
      "speed factor": 0.96560034,
      "Uz factor": 0.970345994,
      "Directional variance factor": 0.7043725822,
      "Velocity stability factor": 0.2582917507,
      "Direction stability factor": 0.8363995378,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4235555026, 0.8367761483, 1.1170851651],
      direction: [-46.1187827031, -12.8382914541, 14.8822441948],
      "speed factor": 1.188758246,
      "Uz factor": 0.9715461949,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4328373754,
      "Direction stability factor": 0.8305527031,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3811525687, 0.7114761581, 1.1496505624],
      direction: [-64.3625330089, -14.2237417569, 24.4878612096],
      "speed factor": 0.8336049029,
      "Uz factor": 0.9869308594,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4091002831,
      "Direction stability factor": 0.7531933494,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2936877636, 0.5032175918, 0.8180246909],
      direction: [-31.8239626356, -4.1531780602, 37.0542219594],
      "speed factor": 0.9335292737,
      "Uz factor": 0.9952399876,
      "Directional variance factor": 0.6308286169,
      "Velocity stability factor": 0.4912107899,
      "Direction stability factor": 0.8086717095,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.06940195, 0.7547708341, 1.2836707554],
      direction: [-64.2751975301, -7.0181211851, 43.1422611171],
      "speed factor": 1.0278096942,
      "Uz factor": 0.9880615463,
      "Directional variance factor": 0.3761670058,
      "Velocity stability factor": 0.1095284621,
      "Direction stability factor": 0.7016181704,
      score: 0.4722329522,
    },
    "337.5": {
      velocity: [0.0757589799, 0.5841408882, 1.1688171937],
      direction: [-97.9538601103, 2.6788331954, 150.8933592415],
      "speed factor": 0.7418226737,
      "Uz factor": 0.99997176,
      "Directional variance factor": 0.7618814937,
      "Velocity stability factor": 0.1196132344,
      "Direction stability factor": 0.308757724,
      score: 0.0,
    },
    id: "IDN:709.0",
    "45": {
      velocity: [0.184457492, 0.4592758083, 0.8669682082],
      direction: [-60.5805056128, 3.4147369629, 80.7599237078],
      "speed factor": 0.6238717799,
      "Uz factor": 0.9748855159,
      "Directional variance factor": 0.6964678255,
      "Velocity stability factor": 0.4451403845,
      "Direction stability factor": 0.6073876963,
      score: 0.0,
    },
    "90": {
      velocity: [0.5390830509, 1.0133082342, 1.2855676744],
      direction: [-13.3080176218, 3.323189643, 21.5073253301],
      "speed factor": 1.6426023002,
      "Uz factor": 0.9837258827,
      "Directional variance factor": 0.7046053651,
      "Velocity stability factor": 0.4551725753,
      "Direction stability factor": 0.903290714,
      score: 0.7415898421,
    },
    "135": {
      velocity: [0.4092374341, 0.9656510362, 1.2229054969],
      direction: [-51.395891904, -19.5015925827, 11.8027219736],
      "speed factor": 0.8172790681,
      "Uz factor": 0.965832006,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3443374796,
      "Direction stability factor": 0.8244482948,
      score: 0.0,
    },
    "180": {
      velocity: [0.4435550532, 0.8125837701, 1.1945452999],
      direction: [-45.9677819674, -24.5219150744, -8.3216498963],
      "speed factor": 1.5869710833,
      "Uz factor": 0.9804342768,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4082520679,
      "Direction stability factor": 0.8954274109,
      score: 0.0,
    },
    "225": {
      velocity: [0.5405769209, 0.7362450618, 1.0769093262],
      direction: [-18.8613123104, 2.1789673218, 21.9218993928],
      "speed factor": 1.0050550807,
      "Uz factor": 0.9952346914,
      "Directional variance factor": 0.8063140158,
      "Velocity stability factor": 0.5179548336,
      "Direction stability factor": 0.8867133008,
      score: 0.7744238628,
    },
    "270": {
      velocity: [0.4986933924, 1.0020659377, 1.3570442299],
      direction: [-14.8623722946, 5.5118561515, 22.6040097994],
      "speed factor": 0.9169226893,
      "Uz factor": 0.9887893982,
      "Directional variance factor": 0.510057231,
      "Velocity stability factor": 0.322930109,
      "Direction stability factor": 0.8959267164,
      score: 0.0,
    },
    "315": {
      velocity: [0.1825683596, 0.8416314041, 1.1949136337],
      direction: [-13.4560850836, -1.1603319385, 56.0416797361],
      "speed factor": 1.0369736473,
      "Uz factor": 0.9973101009,
      "Directional variance factor": 0.8968593832,
      "Velocity stability factor": 0.1787491009,
      "Direction stability factor": 0.8069506533,
      score: 0.6723774477,
    },
  },
  {
    position: [-49.20711102, 79.55436501, 46.87999908],
    "overall score": 0.1651343356,
    "0": {
      velocity: [0.0594118401, 0.5995202229, 1.3154525482],
      direction: [-63.8739323431, 0.991683166, 147.201520473],
      "speed factor": 0.6143589775,
      "Uz factor": 0.9873388983,
      "Directional variance factor": 0.9118503852,
      "Velocity stability factor": 0.1272642383,
      "Direction stability factor": 0.4136792977,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0979638875, 0.690958163, 1.2250595409],
      direction: [-33.330832895, 31.8934821213, 116.5346187364],
      "speed factor": 0.6134849289,
      "Uz factor": 0.9999441716,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2711371656,
      "Direction stability factor": 0.5837070788,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1478844717, 0.450474111, 1.0799630719],
      direction: [-101.6531090511, -0.0822056904, 46.8242024101],
      "speed factor": 0.6978144289,
      "Uz factor": 0.9709848328,
      "Directional variance factor": 0.9926928275,
      "Velocity stability factor": 0.1671369477,
      "Direction stability factor": 0.5875630237,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.261446693, 0.862810915, 1.220727437],
      direction: [-37.0889436527, -3.144171804, 27.5791655068],
      "speed factor": 0.9706020026,
      "Uz factor": 0.9753553616,
      "Directional variance factor": 0.7205180619,
      "Velocity stability factor": 0.2058891454,
      "Direction stability factor": 0.8203663634,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4358829341, 0.8671657917, 1.1360787403],
      direction: [-56.3113834509, -16.1672144293, 11.9316361572],
      "speed factor": 1.2319310101,
      "Uz factor": 0.9721877214,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4273858601,
      "Direction stability factor": 0.8104360566,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4161343211, 0.8121065271, 1.2125683996],
      direction: [-53.8646703421, -12.6124995715, 23.6215021687],
      "speed factor": 0.9515090211,
      "Uz factor": 0.9936150721,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.387620169,
      "Direction stability factor": 0.7847606319,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2718640849, 0.4805012908, 0.7804396572],
      direction: [-35.3348059164, -5.3598660047, 49.214987164],
      "speed factor": 0.8913877978,
      "Uz factor": 0.9894851217,
      "Directional variance factor": 0.5235674662,
      "Velocity stability factor": 0.5065047868,
      "Direction stability factor": 0.7651394637,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1623255142, 0.7909245607, 1.2687206793],
      direction: [-69.7896647742, -5.5714154871, 41.9527229385],
      "speed factor": 1.0770420559,
      "Uz factor": 0.9708401,
      "Directional variance factor": 0.5047630678,
      "Velocity stability factor": 0.1886364865,
      "Direction stability factor": 0.6896044786,
      score: 0.5181521279,
    },
    "337.5": {
      velocity: [0.0665853703, 0.6464975378, 1.1882397483],
      direction: [-54.6861412772, 2.1121064292, 117.594585811],
      "speed factor": 0.8210117486,
      "Uz factor": 0.9969490986,
      "Directional variance factor": 0.8122572063,
      "Velocity stability factor": 0.0965808979,
      "Direction stability factor": 0.5214424248,
      score: 0.0,
    },
    id: "IDN:441.0",
    "45": {
      velocity: [0.0431014125, 0.3973172853, 0.9050235309],
      direction: [-67.389922584, 5.1606501954, 105.7496348001],
      "speed factor": 0.539708466,
      "Uz factor": 0.9798844118,
      "Directional variance factor": 0.5412755382,
      "Velocity stability factor": 0.2992845917,
      "Direction stability factor": 0.519056785,
      score: 0.0,
    },
    "90": {
      velocity: [0.3976899025, 0.9817754453, 1.3554435157],
      direction: [-18.0934510385, 3.4232729165, 29.3471572547],
      "speed factor": 1.5914867267,
      "Uz factor": 0.9851950402,
      "Directional variance factor": 0.6957090741,
      "Velocity stability factor": 0.3009763119,
      "Direction stability factor": 0.8682205325,
      score: 0.6832816127,
    },
    "135": {
      velocity: [0.424045706, 1.0140499494, 1.2845412109],
      direction: [-106.7841544816, -23.2342863795, 0.8165008085],
      "speed factor": 0.858241504,
      "Uz factor": 0.979836781,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3066034206,
      "Direction stability factor": 0.7011092909,
      score: 0.0,
    },
    "180": {
      velocity: [0.442188661, 0.8384477423, 1.2036097477],
      direction: [-52.6080931018, -28.2695004135, -8.0363149195],
      "speed factor": 1.637483261,
      "Uz factor": 0.9859766709,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4000330157,
      "Direction stability factor": 0.876189505,
      score: 0.0,
    },
    "225": {
      velocity: [0.5241701274, 0.7500837457, 1.0480953388],
      direction: [-17.5185592042, 2.3271369235, 21.3933803328],
      "speed factor": 1.0239463987,
      "Uz factor": 0.9914519214,
      "Directional variance factor": 0.7931433846,
      "Velocity stability factor": 0.529106179,
      "Direction stability factor": 0.8919112791,
      score: 0.7765180304,
    },
    "270": {
      velocity: [0.6019534439, 1.0045444641, 1.3634861803],
      direction: [-10.850355709, 8.7827662133, 27.3376707994],
      "speed factor": 0.919190621,
      "Uz factor": 0.9772324782,
      "Directional variance factor": 0.2193096699,
      "Velocity stability factor": 0.3993005373,
      "Direction stability factor": 0.8939221486,
      score: 0.0,
    },
    "315": {
      velocity: [0.1840849071, 0.8131151889, 1.1952222116],
      direction: [-13.5294602541, -2.1268650048, 46.5696580044],
      "speed factor": 1.0018388324,
      "Uz factor": 0.9875160408,
      "Directional variance factor": 0.8109453329,
      "Velocity stability factor": 0.1797290493,
      "Direction stability factor": 0.8330580048,
      score: 0.664197598,
    },
  },
  {
    position: [-54.87082048, 44.06423051, 46.87999908],
    "overall score": 0.1624281835,
    "0": {
      velocity: [0.4373313066, 1.0608004947, 1.4430517735],
      direction: [-25.3817534389, -9.8431402577, 37.849730545],
      "speed factor": 1.08705642,
      "Uz factor": 0.9951350922,
      "Directional variance factor": 0.1250541993,
      "Velocity stability factor": 0.3011944501,
      "Direction stability factor": 0.8243569889,
      score: 0.5187406568,
    },
    "22.5": {
      velocity: [0.1506359076, 0.6486573211, 1.1659801954],
      direction: [-80.9456793257, -1.4906521048, 105.5797172499],
      "speed factor": 0.5759270414,
      "Uz factor": 0.9989352434,
      "Directional variance factor": 0.8674975907,
      "Velocity stability factor": 0.3434038067,
      "Direction stability factor": 0.4818738984,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0577536027, 0.4409807098, 1.0788452019],
      direction: [-131.9968531236, 6.8709958066, 137.8921046429],
      "speed factor": 0.6831085175,
      "Uz factor": 0.9999525783,
      "Directional variance factor": 0.3892448172,
      "Velocity stability factor": 0.0875989795,
      "Direction stability factor": 0.2503084506,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0471304841, 0.3533134819, 0.9233883374],
      direction: [-172.9071978977, -32.3880966371, 101.0219727329],
      "speed factor": 0.3974529844,
      "Uz factor": 0.9999063985,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2746170742,
      "Direction stability factor": 0.2390856371,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0169672835, 0.2660453458, 0.9943811677],
      direction: [-176.4471885016, -0.4979689417, 176.9003278758],
      "speed factor": 0.3779548441,
      "Uz factor": 0.9601727539,
      "Directional variance factor": 0.9557360941,
      "Velocity stability factor": 0.2006792875,
      "Direction stability factor": 0.0184791212,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2078448551, 0.9108035877, 1.1995405633],
      direction: [-55.5995838106, -8.2110161006, 6.910841557],
      "speed factor": 1.0671479679,
      "Uz factor": 0.9959210031,
      "Directional variance factor": 0.2701319022,
      "Velocity stability factor": 0.237483093,
      "Direction stability factor": 0.8263599295,
      score: 0.5400837136,
    },
    "247.5": {
      velocity: [0.4740241912, 0.7714018168, 0.971206279],
      direction: [-21.3340520683, -3.1049427248, 9.8493235323],
      "speed factor": 1.4310433289,
      "Uz factor": 0.9738883184,
      "Directional variance factor": 0.7240050911,
      "Velocity stability factor": 0.51756043,
      "Direction stability factor": 0.9133795122,
      score: 0.7670811364,
    },
    "292.5": {
      velocity: [0.6748015548, 1.1417613963, 1.3120755012],
      direction: [-14.7179056744, 9.5596938254, 30.4675965516],
      "speed factor": 1.5547943541,
      "Uz factor": 0.9068620889,
      "Directional variance factor": 0.1502494377,
      "Velocity stability factor": 0.5326617067,
      "Direction stability factor": 0.874484716,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.5472489335, 0.8969755323, 1.1177220165],
      direction: [-25.8609963963, -2.7507917685, 10.8994621168],
      "speed factor": 1.1391032558,
      "Uz factor": 0.9622493078,
      "Directional variance factor": 0.7554851761,
      "Velocity stability factor": 0.5405213134,
      "Direction stability factor": 0.8978876152,
      score: 0.77294543,
    },
    id: "IDN:465.0",
    "45": {
      velocity: [0.128591495, 0.4543328606, 1.0209734827],
      direction: [-141.173723632, 5.6463332579, 89.2000391494],
      "speed factor": 0.617157371,
      "Uz factor": 0.9999119343,
      "Directional variance factor": 0.4981037104,
      "Velocity stability factor": 0.2745216818,
      "Direction stability factor": 0.3600728812,
      score: 0.0,
    },
    "90": {
      velocity: [0.0565653253, 0.5598347173, 0.9891147933],
      direction: [-174.1099921235, -12.5006087934, 77.29457832],
      "speed factor": 0.9075084592,
      "Uz factor": 0.9999983815,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3193717472,
      "Direction stability factor": 0.301653971,
      score: 0.0,
    },
    "135": {
      velocity: [0.0871551115, 0.5258731478, 1.0132681129],
      direction: [-179.1747272264, -32.0738695915, 175.3426016265],
      "speed factor": 0.4450729095,
      "Uz factor": 0.9991971457,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2537281326,
      "Direction stability factor": 0.0152296421,
      score: 0.0,
    },
    "180": {
      velocity: [0.1269233527, 0.7303098489, 1.1722756133],
      direction: [-117.5890074876, -15.8272484596, 13.1848910254],
      "speed factor": 1.4262906234,
      "Uz factor": 0.9995984515,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1763074937,
      "Direction stability factor": 0.6367391708,
      score: 0.0,
    },
    "225": {
      velocity: [0.1142698679, 0.5395492614, 0.8529411681],
      direction: [-45.918942657, -0.6740229601, 31.9515870139],
      "speed factor": 0.7365437877,
      "Uz factor": 0.9807119405,
      "Directional variance factor": 0.940086848,
      "Velocity stability factor": 0.3360965582,
      "Direction stability factor": 0.7836929731,
      score: 0.0,
    },
    "270": {
      velocity: [0.7209543647, 0.9803294662, 1.2742625333],
      direction: [-3.4927500588, 17.0520574714, 36.4562117679],
      "speed factor": 0.897033116,
      "Uz factor": 0.9165753601,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5635487437,
      "Direction stability factor": 0.8890306616,
      score: 0.0,
    },
    "315": {
      velocity: [0.5066592464, 1.083909303, 1.2872383602],
      direction: [-11.8305926465, 1.3640538757, 27.6274996989],
      "speed factor": 1.3354841298,
      "Uz factor": 0.9491069141,
      "Directional variance factor": 0.8787507666,
      "Velocity stability factor": 0.3667661465,
      "Direction stability factor": 0.8903941879,
      score: 0.0,
    },
  },
  {
    position: [-51.63108851, 93.82668631, 46.87999908],
    "overall score": 0.1622294608,
    "0": {
      velocity: [0.7744823783, 1.2032572532, 1.3697724714],
      direction: [-11.5520322521, 2.5770278074, 27.5944707751],
      "speed factor": 1.2330391327,
      "Uz factor": 0.9671445197,
      "Directional variance factor": 0.7709308616,
      "Velocity stability factor": 0.5863741123,
      "Direction stability factor": 0.8912597138,
      score: 0.7849561004,
    },
    "22.5": {
      velocity: [0.3886722915, 1.0158335381, 1.4600562492],
      direction: [-38.784436994, 19.8878039266, 53.279583892],
      "speed factor": 0.9019338642,
      "Uz factor": 0.9669135572,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.30716444,
      "Direction stability factor": 0.7442666087,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2136549768, 0.658074269, 1.2272271461],
      direction: [-29.1690141282, -3.7961129803, 35.577936643],
      "speed factor": 1.0194009133,
      "Uz factor": 0.997133721,
      "Directional variance factor": 0.6625677351,
      "Velocity stability factor": 0.0943180001,
      "Direction stability factor": 0.820147359,
      score: 0.5992951133,
    },
    "112.5": {
      velocity: [0.0559020966, 0.3339682254, 0.8615679824],
      direction: [-179.8609945409, 82.3207969141, 171.382905719],
      "speed factor": 0.3756909223,
      "Uz factor": 0.9992242263,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3330544483,
      "Direction stability factor": 0.0243224993,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0193111502, 0.3999292227, 1.0207481644],
      direction: [-174.637686271, -2.6093036345, 175.0931586991],
      "speed factor": 0.5681557276,
      "Uz factor": 0.9959495023,
      "Directional variance factor": 0.7680618992,
      "Velocity stability factor": 0.1810333774,
      "Direction stability factor": 0.0285254306,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5621271422, 1.0958510466, 1.4671679191],
      direction: [-43.2379059212, -10.1638233868, 11.057187967],
      "speed factor": 1.2839598276,
      "Uz factor": 0.9988414453,
      "Directional variance factor": 0.0965490323,
      "Velocity stability factor": 0.3041122511,
      "Direction stability factor": 0.8491802948,
      score: 0.5247554682,
    },
    "247.5": {
      velocity: [0.1806035073, 0.4436521319, 0.8089435213],
      direction: [-55.119393833, -5.2999530737, 29.7218089995],
      "speed factor": 0.8230281676,
      "Uz factor": 0.9610056416,
      "Directional variance factor": 0.5288930601,
      "Velocity stability factor": 0.3902916183,
      "Direction stability factor": 0.7643299921,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3948016951, 0.7632143726, 1.0719392435],
      direction: [-55.5435749122, -24.0585903522, 34.1415444622],
      "speed factor": 1.0393076885,
      "Uz factor": 0.9320278337,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5034281442,
      "Direction stability factor": 0.7508746684,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.720987031, 0.9591746946, 1.1496547387],
      direction: [-0.7583746305, 10.0484492242, 29.9952105034],
      "speed factor": 1.2180923317,
      "Uz factor": 0.9348833672,
      "Directional variance factor": 0.1068045134,
      "Velocity stability factor": 0.6547362511,
      "Direction stability factor": 0.9145733746,
      score: 0.0,
    },
    id: "IDN:432.0",
    "45": {
      velocity: [0.1627755247, 0.7196018518, 1.3326308664],
      direction: [-32.1765172997, -1.0615553235, 62.3122717419],
      "speed factor": 0.9774938719,
      "Uz factor": 0.98071287,
      "Directional variance factor": 0.9056395268,
      "Velocity stability factor": 0.0489446251,
      "Direction stability factor": 0.7375311416,
      score: 0.0,
    },
    "90": {
      velocity: [0.0453919705, 0.4260659573, 1.129343671],
      direction: [-178.8479277243, 21.6751455328, 175.9200429563],
      "speed factor": 0.6906653849,
      "Uz factor": 0.991963459,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2088696874,
      "Direction stability factor": 0.0145334148,
      score: 0.0,
    },
    "135": {
      velocity: [0.0398820506, 0.3220740386, 0.7344679483],
      direction: [-164.9025946343, 102.9332725943, 176.5735590735],
      "speed factor": 0.2725874672,
      "Uz factor": 0.996919617,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4402951754,
      "Direction stability factor": 0.0514551286,
      score: 0.0,
    },
    "180": {
      velocity: [0.1173573027, 0.6345118525, 1.3376998757],
      direction: [-120.3412394256, -27.9827146159, 50.3549570735],
      "speed factor": 1.2391977281,
      "Uz factor": 0.9909453003,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.038422673,
      "Direction stability factor": 0.5258438986,
      score: 0.0,
    },
    "225": {
      velocity: [0.4489029183, 0.8930105822, 1.1628504744],
      direction: [-33.8783487103, -3.8843427954, 14.0707900629],
      "speed factor": 1.2190571718,
      "Uz factor": 0.9924104616,
      "Directional variance factor": 0.6547250849,
      "Velocity stability factor": 0.3583177801,
      "Direction stability factor": 0.8668079479,
      score: 0.6866646902,
    },
    "270": {
      velocity: [0.7620780051, 1.0700361986, 1.3227507993],
      direction: [-28.5102939993, -11.9376434062, 4.1320619685],
      "speed factor": 0.9791176728,
      "Uz factor": 0.9613469826,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5577395034,
      "Direction stability factor": 0.909326789,
      score: 0.0,
    },
    "315": {
      velocity: [0.6807731751, 0.8305930257, 0.9992556244],
      direction: [-11.5579275861, 7.1374932929, 27.510771471],
      "speed factor": 1.0233732666,
      "Uz factor": 0.9345937715,
      "Directional variance factor": 0.3655561517,
      "Velocity stability factor": 0.7416355817,
      "Direction stability factor": 0.891475836,
      score: 0.0,
    },
  },
  {
    position: [-54.65624756, 50.06039249, 46.87999908],
    "overall score": 0.1606900637,
    "0": {
      velocity: [0.2956479983, 1.0834711037, 1.4285455564],
      direction: [-20.4494783662, -7.3702945316, 40.7905353892],
      "speed factor": 1.1102881503,
      "Uz factor": 0.992638722,
      "Directional variance factor": 0.3448627083,
      "Velocity stability factor": 0.2128278909,
      "Direction stability factor": 0.8298888507,
      score: 0.5543670751,
    },
    "22.5": {
      velocity: [0.1652347468, 0.6555128639, 1.2129101129],
      direction: [-76.933758334, 2.9363861756, 71.1066908169],
      "speed factor": 0.5820139109,
      "Uz factor": 0.9996232091,
      "Directional variance factor": 0.7389878955,
      "Velocity stability factor": 0.3224961568,
      "Direction stability factor": 0.5887765301,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0693821485, 0.3973711599, 1.0090311872],
      direction: [-157.3296116504, 5.6024187057, 166.5374574245],
      "speed factor": 0.6155544174,
      "Uz factor": 0.9933293942,
      "Directional variance factor": 0.5020072262,
      "Velocity stability factor": 0.160372348,
      "Direction stability factor": 0.1003692526,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0608925341, 0.3876487494, 1.0125185672],
      direction: [-175.1922813079, -36.7916539161, 125.5003643951],
      "speed factor": 0.4360777616,
      "Uz factor": 0.9862319376,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2122258607,
      "Direction stability factor": 0.1647426508,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0419665829, 0.3246044271, 0.8510919292],
      direction: [-168.5574148473, -1.2263875542, 178.1765134756],
      "speed factor": 0.4611462578,
      "Uz factor": 0.9958494538,
      "Directional variance factor": 0.890987773,
      "Velocity stability factor": 0.3383042141,
      "Direction stability factor": 0.0368501991,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2300769357, 0.9078508157, 1.1885039029],
      direction: [-72.0731042532, -6.8552914186, 15.3004221919],
      "speed factor": 1.0636883366,
      "Uz factor": 0.9970246319,
      "Directional variance factor": 0.3906407628,
      "Velocity stability factor": 0.2630634976,
      "Direction stability factor": 0.7572957599,
      score: 0.542073945,
    },
    "247.5": {
      velocity: [0.4896171519, 0.760966612, 0.9784873742],
      direction: [-23.9995295757, -8.6344003788, 10.1582134075],
      "speed factor": 1.4116847666,
      "Uz factor": 0.9797105368,
      "Directional variance factor": 0.2324977441,
      "Velocity stability factor": 0.5256258308,
      "Direction stability factor": 0.9051173806,
      score: 0.642089584,
    },
    "292.5": {
      velocity: [0.6841353252, 1.1172257875, 1.2863030366],
      direction: [-22.3255843116, 3.5304802606, 16.7872850244],
      "speed factor": 1.5213829723,
      "Uz factor": 0.91088085,
      "Directional variance factor": 0.6861795324,
      "Velocity stability factor": 0.558406503,
      "Direction stability factor": 0.8913531407,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6453354262, 0.9194989588, 1.1424349597],
      direction: [-23.7178784556, -0.8743889127, 10.815801799],
      "speed factor": 1.1677066096,
      "Uz factor": 0.9541244049,
      "Directional variance factor": 0.9222765411,
      "Velocity stability factor": 0.5996188995,
      "Direction stability factor": 0.9040731104,
      score: 0.8325104154,
    },
    id: "IDN:467.0",
    "45": {
      velocity: [0.0605010155, 0.4757113776, 1.1194413163],
      direction: [-124.4586670672, 13.3979414409, 155.6505928046],
      "speed factor": 0.6461975539,
      "Uz factor": 0.9961030682,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1391150437,
      "Direction stability factor": 0.2219187226,
      score: 0.0,
    },
    "90": {
      velocity: [0.0896414473, 0.4646558116, 1.1673592448],
      direction: [-144.7722950375, -16.7001051093, 135.4207590797],
      "speed factor": 0.753220668,
      "Uz factor": 0.9735316238,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2134195485,
      "Direction stability factor": 0.2216859608,
      score: 0.0,
    },
    "135": {
      velocity: [0.0486491829, 0.467084359, 0.9778399745],
      direction: [-170.8018597414, -38.8172394839, 179.2245435054],
      "speed factor": 0.395316999,
      "Uz factor": 0.9976777661,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.251248016,
      "Direction stability factor": 0.0277044354,
      score: 0.0,
    },
    "180": {
      velocity: [0.1120569353, 0.5442669522, 0.9472017589],
      direction: [-126.6352079721, -12.2660698012, 45.9371765125],
      "speed factor": 1.0629499953,
      "Uz factor": 0.9968608376,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.34194189,
      "Direction stability factor": 0.5206322653,
      score: 0.0,
    },
    "225": {
      velocity: [0.196079264, 0.5498953519, 0.8579096798],
      direction: [-57.6903298649, -2.1850821571, 39.8748204428],
      "speed factor": 0.7506673335,
      "Uz factor": 0.9791611156,
      "Directional variance factor": 0.8057704749,
      "Velocity stability factor": 0.4051596552,
      "Direction stability factor": 0.7289856936,
      score: 0.0,
    },
    "270": {
      velocity: [0.7383846088, 1.0160440871, 1.3498186474],
      direction: [-5.9604610854, 11.2591721388, 32.2509361928],
      "speed factor": 0.9297131473,
      "Uz factor": 0.910816555,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5176988712,
      "Direction stability factor": 0.8938572298,
      score: 0.0,
    },
    "315": {
      velocity: [0.6702779643, 1.0513424973, 1.2546446782],
      direction: [-12.6353084115, -1.2377819162, 23.9239066162],
      "speed factor": 1.2953585842,
      "Uz factor": 0.9450405156,
      "Directional variance factor": 0.8899749408,
      "Velocity stability factor": 0.5259407028,
      "Direction stability factor": 0.8984466249,
      score: 0.0,
    },
  },
  {
    position: [-54.76353402, 47.0623115, 46.87999908],
    "overall score": 0.1575790848,
    "0": {
      velocity: [0.2533663463, 1.0798548205, 1.4188578462],
      direction: [-22.8484754255, -8.6053162308, 82.1978410856],
      "speed factor": 1.1065823604,
      "Uz factor": 0.9950493455,
      "Directional variance factor": 0.2350830017,
      "Velocity stability factor": 0.1901806165,
      "Direction stability factor": 0.7082046764,
      score: 0.4604182427,
    },
    "22.5": {
      velocity: [0.2058715979, 0.6534617722, 1.2330276212],
      direction: [-62.765801907, 1.4910511314, 92.7165405975],
      "speed factor": 0.5801927965,
      "Uz factor": 0.9999097972,
      "Directional variance factor": 0.8674621217,
      "Velocity stability factor": 0.335765471,
      "Direction stability factor": 0.5681046042,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0654298335, 0.376314434, 0.8577635773],
      direction: [-99.6800497513, 1.6297623258, 156.6339483903],
      "speed factor": 0.5829361453,
      "Uz factor": 0.9994487689,
      "Directional variance factor": 0.8551322377,
      "Velocity stability factor": 0.2920065967,
      "Direction stability factor": 0.2880166718,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0497082808, 0.4057621055, 0.9769737842],
      direction: [-178.8348053905, -40.0185936871, 177.2417289299],
      "speed factor": 0.4564540218,
      "Uz factor": 0.9999603711,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2323919708,
      "Direction stability factor": 0.0108985158,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0447322587, 0.2501423122, 0.7579123062],
      direction: [-175.350072877, 32.1088484401, 171.1745249285],
      "speed factor": 0.3553623474,
      "Uz factor": 0.9036909392,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4167674586,
      "Direction stability factor": 0.0374316728,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1763484114, 0.902971444, 1.2343272824],
      direction: [-45.3655571689, -7.408007719, 15.1235974526],
      "speed factor": 1.0579713942,
      "Uz factor": 0.9965260285,
      "Directional variance factor": 0.341510425,
      "Velocity stability factor": 0.1865178302,
      "Direction stability factor": 0.8319745705,
      score: 0.547994349,
    },
    "247.5": {
      velocity: [0.475175556, 0.7708685116, 0.9933559073],
      direction: [-19.3062041323, -5.7549808349, 9.5924204648],
      "speed factor": 1.4300539835,
      "Uz factor": 0.9764369163,
      "Directional variance factor": 0.488446148,
      "Velocity stability factor": 0.4971848101,
      "Direction stability factor": 0.9197260428,
      score: 0.7062707609,
    },
    "292.5": {
      velocity: [0.6846287236, 1.1304003404, 1.2985221421],
      direction: [-19.8456865733, 5.9624412681, 19.9254450414],
      "speed factor": 1.5393234286,
      "Uz factor": 0.9063263197,
      "Directional variance factor": 0.4700052206,
      "Velocity stability factor": 0.5498075764,
      "Direction stability factor": 0.8895246344,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6208865718, 0.9121359365, 1.1181349107],
      direction: [-23.4751053406, -2.0976189142, 10.133777661],
      "speed factor": 1.1583560282,
      "Uz factor": 0.9611229377,
      "Directional variance factor": 0.8135449854,
      "Velocity stability factor": 0.5994990466,
      "Direction stability factor": 0.9066419917,
      score: 0.8065820038,
    },
    id: "IDN:466.0",
    "45": {
      velocity: [0.0868614604, 0.4505908364, 0.9845724999],
      direction: [-132.7980733532, 5.5599166267, 80.8454344218],
      "speed factor": 0.6120742744,
      "Uz factor": 0.999439282,
      "Directional variance factor": 0.5057851887,
      "Velocity stability factor": 0.2701893313,
      "Direction stability factor": 0.4065458117,
      score: 0.0,
    },
    "90": {
      velocity: [0.1200790186, 0.5569269808, 1.1008934987],
      direction: [-137.2730172663, -21.3173468618, 152.0631895516],
      "speed factor": 0.9027949333,
      "Uz factor": 0.9987877881,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2841451646,
      "Direction stability factor": 0.1962883144,
      score: 0.0,
    },
    "135": {
      velocity: [0.1065196522, 0.5200205145, 1.0304332103],
      direction: [-171.5238831772, -33.7849913717, 161.680298214],
      "speed factor": 0.4401195313,
      "Uz factor": 0.9998634358,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2555004678,
      "Direction stability factor": 0.0744328295,
      score: 0.0,
    },
    "180": {
      velocity: [0.1282761227, 0.600545304, 1.114845264],
      direction: [-165.7895414469, -12.5619361845, 36.4836200448],
      "speed factor": 1.1728612688,
      "Uz factor": 0.9988306807,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.222626057,
      "Direction stability factor": 0.438130107,
      score: 0.0,
    },
    "225": {
      velocity: [0.1749532858, 0.5440665499, 0.8621471547],
      direction: [-53.6356698411, -0.6661287106, 42.8308681488],
      "speed factor": 0.7427103808,
      "Uz factor": 0.9795638023,
      "Directional variance factor": 0.9407885591,
      "Velocity stability factor": 0.3823634754,
      "Direction stability factor": 0.7320373945,
      score: 0.0,
    },
    "270": {
      velocity: [0.6996042166, 1.0061441343, 1.2924278035],
      direction: [-1.8500556451, 13.7712452716, 34.4812303574],
      "speed factor": 0.9206543709,
      "Uz factor": 0.9220454246,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5323788551,
      "Direction stability factor": 0.8990797611,
      score: 0.0,
    },
    "315": {
      velocity: [0.3913004303, 1.0639449621, 1.268021734],
      direction: [-11.0840413458, 0.2202862375, 38.0711725446],
      "speed factor": 1.310886075,
      "Uz factor": 0.9413750204,
      "Directional variance factor": 0.9804190011,
      "Velocity stability factor": 0.2887721439,
      "Direction stability factor": 0.8634577392,
      score: 0.0,
    },
  },
  {
    position: [-2.410495206, 101.4859026, 47.87999908],
    "overall score": 0.1570275088,
    "0": {
      velocity: [0.7275434483, 1.0860515374, 1.3778981567],
      direction: [-20.4735646657, 3.9620605536, 23.8504082463],
      "speed factor": 1.1129324525,
      "Uz factor": 0.9534698657,
      "Directional variance factor": 0.6478168397,
      "Velocity stability factor": 0.548113522,
      "Direction stability factor": 0.876877853,
      score: 0.7374215169,
    },
    "22.5": {
      velocity: [0.6531332697, 1.0142120875, 1.319677994],
      direction: [-22.9026024814, -3.1796052343, 17.6893427434],
      "speed factor": 0.9004942177,
      "Uz factor": 0.9439894386,
      "Directional variance factor": 0.7173684236,
      "Velocity stability factor": 0.5689632237,
      "Direction stability factor": 0.8872445966,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1998755692, 0.5115224563, 0.8749771789],
      direction: [-47.1274243028, -3.1911998021, 85.4302226205],
      "speed factor": 0.7923823855,
      "Uz factor": 0.9886493735,
      "Directional variance factor": 0.7163377954,
      "Velocity stability factor": 0.3967599007,
      "Direction stability factor": 0.6317843141,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1903535525, 0.9573830397, 1.2875033496],
      direction: [-75.8009183789, 10.7447796172, 89.7600434839],
      "speed factor": 1.0769890361,
      "Uz factor": 0.9976453749,
      "Directional variance factor": 0.0449084785,
      "Velocity stability factor": 0.0917585196,
      "Direction stability factor": 0.5401084393,
      score: 0.3042209692,
    },
    "157.5": {
      velocity: [0.2333898171, 0.7046776673, 1.1768219231],
      direction: [-50.126312527, 4.7015514346, 64.3295177135],
      "speed factor": 1.0010937687,
      "Uz factor": 0.998938453,
      "Directional variance factor": 0.5820843169,
      "Velocity stability factor": 0.228469295,
      "Direction stability factor": 0.6820671382,
      score: 0.5436719721,
    },
    "202.5": {
      velocity: [0.0808855192, 0.5720797414, 1.0953619975],
      direction: [-163.9176581186, -26.415472937, 175.9986518442],
      "speed factor": 0.6702803346,
      "Uz factor": 0.9951512229,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2199669111,
      "Direction stability factor": 0.0557880279,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2236607578, 0.5446802859, 0.9891036768],
      direction: [-33.4243226879, -4.8391763295, 27.4858323738],
      "speed factor": 1.0104475678,
      "Uz factor": 0.9977309711,
      "Directional variance factor": 0.5698509929,
      "Velocity stability factor": 0.2572541092,
      "Direction stability factor": 0.8308051248,
      score: 0.6221788379,
    },
    "292.5": {
      velocity: [0.1518610894, 0.6040031408, 1.1408671197],
      direction: [-99.1999699534, 14.9648828801, 66.4353235892],
      "speed factor": 0.8225016858,
      "Uz factor": 0.8734822411,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2747226011,
      "Direction stability factor": 0.5399019624,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1623245713, 0.5133416212, 0.8952937278],
      direction: [-153.4965231729, -1.0740607219, 135.7834559894],
      "speed factor": 0.6519119987,
      "Uz factor": 0.8525940638,
      "Directional variance factor": 0.9045279358,
      "Velocity stability factor": 0.4096413742,
      "Direction stability factor": 0.1964445023,
      score: 0.0,
    },
    id: "IDN:669.0",
    "45": {
      velocity: [0.173747363, 0.5964660536, 1.0692104289],
      direction: [-69.4536256167, 0.0404574665, 69.6477838479],
      "speed factor": 0.8102284766,
      "Uz factor": 0.9829218135,
      "Directional variance factor": 0.9964037808,
      "Velocity stability factor": 0.2720168628,
      "Direction stability factor": 0.6136071959,
      score: 0.0,
    },
    "90": {
      velocity: [0.3256395502, 0.7437981811, 1.1818324264],
      direction: [-25.6467843574, 11.3082145159, 34.1043324612],
      "speed factor": 1.2057186175,
      "Uz factor": 0.9966924222,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3751011808,
      "Direction stability factor": 0.8340246755,
      score: 0.0,
    },
    "135": {
      velocity: [0.1277732498, 0.7468103637, 1.2703104996],
      direction: [-106.7435505587, 10.5484227447, 30.8828591921],
      "speed factor": 0.6320631939,
      "Uz factor": 0.9948525928,
      "Directional variance factor": 0.0623624227,
      "Velocity stability factor": 0.0793311338,
      "Direction stability factor": 0.6177044174,
      score: 0.0,
    },
    "180": {
      velocity: [0.114619608, 0.6017203984, 1.2412344547],
      direction: [-176.1932422652, -2.9246764307, 117.6599009985],
      "speed factor": 1.1751562209,
      "Uz factor": 0.9945849986,
      "Directional variance factor": 0.7400287617,
      "Velocity stability factor": 0.1122760799,
      "Direction stability factor": 0.1837412687,
      score: 0.3049468447,
    },
    "225": {
      velocity: [0.1032732063, 0.5271846598, 0.8273188603],
      direction: [-83.0087610147, -13.447244574, 141.7244715276],
      "speed factor": 0.7196647533,
      "Uz factor": 0.9998453501,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3492418055,
      "Direction stability factor": 0.3757410207,
      score: 0.0,
    },
    "270": {
      velocity: [0.4240910308, 1.0362571422, 1.2896397817],
      direction: [-45.7568611052, -0.5376804184, 13.7458181939],
      "speed factor": 0.9482087454,
      "Uz factor": 0.9973722643,
      "Directional variance factor": 0.952206185,
      "Velocity stability factor": 0.3172523719,
      "Direction stability factor": 0.8347147797,
      score: 0.0,
    },
    "315": {
      velocity: [0.7836608016, 0.923978784, 1.1500076135],
      direction: [-23.0581083943, -8.3301365114, 14.0443565191],
      "speed factor": 1.1384338144,
      "Uz factor": 0.9462830263,
      "Directional variance factor": 0.2595434212,
      "Velocity stability factor": 0.7028062891,
      "Direction stability factor": 0.8969375975,
      score: 0.0,
    },
  },
  {
    position: [-52.61952834, 79.68102978, 45.87999908],
    "overall score": 0.1529953627,
    "0": {
      velocity: [0.2568250505, 0.8674628393, 1.4552195855],
      direction: [-39.4767733419, -3.6031998235, 86.4484132779],
      "speed factor": 0.8889334547,
      "Uz factor": 0.9997021116,
      "Directional variance factor": 0.6797155712,
      "Velocity stability factor": 0.1673185745,
      "Direction stability factor": 0.6502078149,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1303452077, 0.5763259449, 1.2249153877],
      direction: [-140.1522926719, 24.0131720531, 157.5516990298],
      "speed factor": 0.5117057736,
      "Uz factor": 0.9981405578,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2921705257,
      "Direction stability factor": 0.1730444675,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.082607694, 0.327444798, 0.9148662185],
      direction: [-120.6463531866, -5.2734952281, 112.51813923],
      "speed factor": 0.5072338212,
      "Uz factor": 0.9392325054,
      "Directional variance factor": 0.5312448686,
      "Velocity stability factor": 0.2563316282,
      "Direction stability factor": 0.3523208544,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2243272253, 0.8050715646, 1.2854805423],
      direction: [-69.3602973762, -1.1810230561, 32.8290998544],
      "speed factor": 0.9056492672,
      "Uz factor": 0.9783185305,
      "Directional variance factor": 0.8950201728,
      "Velocity stability factor": 0.1215570908,
      "Direction stability factor": 0.7161405632,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4235551319, 0.9294650857, 1.2411395702],
      direction: [-66.2079705437, -25.0986856767, 11.2039311785],
      "speed factor": 1.3204359223,
      "Uz factor": 0.979275611,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.331386441,
      "Direction stability factor": 0.7849669397,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4611902862, 0.901043074, 1.2511733001],
      direction: [-54.2906725711, -14.5308142076, 9.9972768087],
      "speed factor": 1.055712009,
      "Uz factor": 0.9825662667,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.392580406,
      "Direction stability factor": 0.8214223628,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2277940539, 0.4552545837, 0.7446998351],
      direction: [-40.582924182, -6.3663109606, 56.3075953992],
      "speed factor": 0.8445521139,
      "Uz factor": 0.9637292806,
      "Directional variance factor": 0.4341056924,
      "Velocity stability factor": 0.4984215865,
      "Direction stability factor": 0.7308596678,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4772967022, 1.0070200437, 1.36379562],
      direction: [-43.9145587519, 0.1957303879, 35.384962048],
      "speed factor": 1.371310226,
      "Uz factor": 0.9215393471,
      "Directional variance factor": 0.9826017433,
      "Velocity stability factor": 0.3498951376,
      "Direction stability factor": 0.7797235533,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4876912703, 0.8863395239, 1.1793834431],
      direction: [-2.9617299318, 7.9960671097, 41.0504222314],
      "speed factor": 1.1255961853,
      "Uz factor": 0.9812265321,
      "Directional variance factor": 0.2892384791,
      "Velocity stability factor": 0.4428872798,
      "Direction stability factor": 0.8777440218,
      score: 0.6219034506,
    },
    id: "IDN:349.0",
    "45": {
      velocity: [0.0695379222, 0.384851979, 0.7185450504],
      direction: [-117.531047754, -4.8815489965, 136.3991850064],
      "speed factor": 0.522775824,
      "Uz factor": 0.9664826319,
      "Directional variance factor": 0.5660845336,
      "Velocity stability factor": 0.4723777413,
      "Direction stability factor": 0.2946382423,
      score: 0.0,
    },
    "90": {
      velocity: [0.1131807958, 0.6678906323, 1.4084284181],
      direction: [-130.2840306631, 0.4956529328, 50.6174094689],
      "speed factor": 1.0826702595,
      "Uz factor": 0.9757973821,
      "Directional variance factor": 0.9559419615,
      "Velocity stability factor": 0.0546537675,
      "Direction stability factor": 0.4974959996,
      score: 0.5013969321,
    },
    "135": {
      velocity: [0.1189147371, 0.9797499153, 1.3599008199],
      direction: [-129.1147870627, -22.5569298163, 3.8082258394],
      "speed factor": 0.829211659,
      "Uz factor": 0.9905213339,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.6307694086,
      score: 0.0,
    },
    "180": {
      velocity: [0.4916831811, 0.8881748292, 1.2691454204],
      direction: [-59.1306299422, -39.5132665897, 20.5828422928],
      "speed factor": 1.7345999547,
      "Uz factor": 0.9912786309,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3873932792,
      "Direction stability factor": 0.7785736882,
      score: 0.0,
    },
    "225": {
      velocity: [0.4905931916, 0.7547518979, 1.0374896634],
      direction: [-21.0599929231, 3.3791720068, 32.9330738388],
      "speed factor": 1.0303189373,
      "Uz factor": 0.9716468739,
      "Directional variance factor": 0.6996291549,
      "Velocity stability factor": 0.5084600555,
      "Direction stability factor": 0.850019259,
      score: 0.7270319321,
    },
    "270": {
      velocity: [0.8765709807, 1.0943252978, 1.3430564529],
      direction: [-4.8500234916, 18.0930028267, 37.0057468319],
      "speed factor": 1.0013429828,
      "Uz factor": 0.9220267361,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6320347647,
      "Direction stability factor": 0.8837339713,
      score: 0.0,
    },
    "315": {
      velocity: [0.3962856043, 0.9181312343, 1.226325035],
      direction: [-1.2363050924, 9.2101353336, 19.9298064769],
      "speed factor": 1.1312290513,
      "Uz factor": 0.9569187704,
      "Directional variance factor": 0.1813213037,
      "Velocity stability factor": 0.3266421584,
      "Direction stability factor": 0.9412052456,
      score: 0.5975934883,
    },
  },
  {
    position: [-47.07558315, 49.14173951, 47.87999908],
    "overall score": 0.1498631706,
    "0": {
      velocity: [0.3306957083, 0.9439363651, 1.4065043141],
      direction: [-53.4108305302, -15.3566107374, 95.5967904355],
      "speed factor": 0.9672997806,
      "Uz factor": 0.9996457766,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2524950529,
      "Direction stability factor": 0.5860899418,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3787083676, 0.8232624416, 1.3515649457],
      direction: [-88.0950036588, -1.00636629, 94.8596631572],
      "speed factor": 0.7309546765,
      "Uz factor": 0.9990582883,
      "Directional variance factor": 0.9105452187,
      "Velocity stability factor": 0.3708794805,
      "Direction stability factor": 0.4917925922,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1856388202, 0.5808843535, 0.9813622796],
      direction: [-41.8189029233, 9.7916622832, 72.1834210816],
      "speed factor": 0.8998285883,
      "Uz factor": 0.9705684785,
      "Directional variance factor": 0.1296300193,
      "Velocity stability factor": 0.288977701,
      "Direction stability factor": 0.6833268778,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5145484083, 0.9004175446, 1.1709991398],
      direction: [-40.6363958923, -10.8482726036, 9.3901032266],
      "speed factor": 1.0129068337,
      "Uz factor": 0.9469768186,
      "Directional variance factor": 0.0357091019,
      "Velocity stability factor": 0.4565775925,
      "Direction stability factor": 0.8610375024,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5707834147, 1.0141908626, 1.3303939124],
      direction: [-27.6407387717, -6.6944787508, 9.2458255095],
      "speed factor": 1.4408008085,
      "Uz factor": 0.9915501216,
      "Directional variance factor": 0.4049352221,
      "Velocity stability factor": 0.3787970337,
      "Direction stability factor": 0.8975373214,
      score: 0.6447017247,
    },
    "202.5": {
      velocity: [0.0712653383, 0.4191533054, 1.2371341049],
      direction: [-113.7532919289, -18.7996339693, 58.4563971368],
      "speed factor": 0.4911032457,
      "Uz factor": 0.9955476475,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1035610636,
      "Direction stability factor": 0.5216397526,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3874698139, 0.7545614895, 1.1240983706],
      direction: [-17.5336154924, 0.0122907361, 14.4023828949],
      "speed factor": 1.3998024925,
      "Uz factor": 0.9979204001,
      "Directional variance factor": 0.9989074901,
      "Velocity stability factor": 0.2852140638,
      "Direction stability factor": 0.9112888934,
      score: 0.7766748352,
    },
    "292.5": {
      velocity: [0.03786662, 0.5087610451, 1.1285638272],
      direction: [-33.1006774713, 0.7056269035, 171.266975094],
      "speed factor": 0.6928056975,
      "Uz factor": 0.9700325171,
      "Directional variance factor": 0.9372776086,
      "Velocity stability factor": 0.2001484226,
      "Direction stability factor": 0.4323120762,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1203015854, 0.7112052391, 1.183546222],
      direction: [-46.0026709659, -7.2448047662, 80.0194753801],
      "speed factor": 0.9031865132,
      "Uz factor": 0.9959950791,
      "Directional variance factor": 0.3560173541,
      "Velocity stability factor": 0.1436261172,
      "Direction stability factor": 0.6499384824,
      score: 0.0,
    },
    id: "IDN:742.0",
    "45": {
      velocity: [0.1710050527, 0.5447276975, 1.0401001572],
      direction: [-124.8527074344, 1.1921324594, 173.1788351839],
      "speed factor": 0.7399480488,
      "Uz factor": 0.9864024095,
      "Directional variance factor": 0.8940326703,
      "Velocity stability factor": 0.293453181,
      "Direction stability factor": 0.1721346038,
      score: 0.0,
    },
    "90": {
      velocity: [0.6543914992, 1.0299741667, 1.3146158632],
      direction: [-35.4782564925, -2.3851614345, 23.8930336083],
      "speed factor": 1.6696182646,
      "Uz factor": 0.9603653077,
      "Directional variance factor": 0.7879856503,
      "Velocity stability factor": 0.5181302753,
      "Direction stability factor": 0.8350797497,
      score: 0.7440688563,
    },
    "135": {
      velocity: [0.255544941, 0.6825116294, 1.1290491979],
      direction: [-104.0191209994, -26.8954221098, 15.9036884828],
      "speed factor": 0.5776439392,
      "Uz factor": 0.9553161065,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2961208276,
      "Direction stability factor": 0.6668810848,
      score: 0.0,
    },
    "180": {
      velocity: [0.1423748415, 0.7674811212, 1.1334107433],
      direction: [-123.6285642818, -10.8919931339, 114.2356799883],
      "speed factor": 1.4988858884,
      "Uz factor": 0.9989202349,
      "Directional variance factor": 0.0318228325,
      "Velocity stability factor": 0.2191064423,
      "Direction stability factor": 0.3392659881,
      score: 0.2323653128,
    },
    "225": {
      velocity: [0.0659536655, 0.5012627944, 0.8819809892],
      direction: [-97.3082376125, 2.2666783545, 97.7171325849],
      "speed factor": 0.6842785703,
      "Uz factor": 0.9998340074,
      "Directional variance factor": 0.7985174796,
      "Velocity stability factor": 0.2665704643,
      "Direction stability factor": 0.4582628606,
      score: 0.0,
    },
    "270": {
      velocity: [0.113969091, 0.7084260352, 1.2098455961],
      direction: [-12.9666011704, 13.5622126881, 147.5921528606],
      "speed factor": 0.648232697,
      "Uz factor": 0.9888460087,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1355691013,
      "Direction stability factor": 0.554003461,
      score: 0.0,
    },
    "315": {
      velocity: [0.1112603195, 0.8022406824, 1.2549429963],
      direction: [-30.2445217166, -6.4321175203, 130.6850189102],
      "speed factor": 0.9884403582,
      "Uz factor": 0.9932052208,
      "Directional variance factor": 0.4282562204,
      "Velocity stability factor": 0.0722034758,
      "Direction stability factor": 0.5529734983,
      score: 0.0,
    },
  },
  {
    position: [6.735951834, 93.60918028, 47.87999908],
    "overall score": 0.1482085591,
    "0": {
      velocity: [0.0320950117, 0.5464915577, 1.1681371411],
      direction: [-149.2613972431, -13.7830266422, 161.5995700857],
      "speed factor": 0.5600177972,
      "Uz factor": 0.991017053,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2106429459,
      "Direction stability factor": 0.136497313,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0583284716, 0.7019156842, 1.2191362979],
      direction: [-160.8428346526, -17.0224904084, 176.0281832131],
      "speed factor": 0.6232138452,
      "Uz factor": 0.9981516237,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2493363985,
      "Direction stability factor": 0.0642471726,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0729119281, 0.4312402691, 0.8470287281],
      direction: [-41.5343149138, 0.506235683, 85.3053945392],
      "speed factor": 0.6680199256,
      "Uz factor": 0.9956735939,
      "Directional variance factor": 0.9550012726,
      "Velocity stability factor": 0.3082844293,
      "Direction stability factor": 0.6476674737,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.523020814, 0.9492913235, 1.1959002054],
      direction: [-18.4451822424, 7.6733718457, 54.2642026658],
      "speed factor": 1.0678864207,
      "Uz factor": 0.9829420267,
      "Directional variance factor": 0.3179225026,
      "Velocity stability factor": 0.4429776352,
      "Direction stability factor": 0.7980294864,
      score: 0.5892397776,
    },
    "157.5": {
      velocity: [0.3881223644, 0.8728260785, 1.1393843842],
      direction: [-13.6430132491, 9.670018594, 49.0862818337],
      "speed factor": 1.2399722438,
      "Uz factor": 0.9589654311,
      "Directional variance factor": 0.1404427916,
      "Velocity stability factor": 0.3856243475,
      "Direction stability factor": 0.8257519581,
      score: 0.5443927638,
    },
    "202.5": {
      velocity: [0.3583713275, 0.8382532905, 1.1531011374],
      direction: [-44.9296813597, -10.7857426655, 26.8807902348],
      "speed factor": 0.9821440183,
      "Uz factor": 0.9184900717,
      "Directional variance factor": 0.0412673186,
      "Velocity stability factor": 0.3889305846,
      "Direction stability factor": 0.8005264678,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2148144761, 0.5531503917, 0.8851681752],
      direction: [-44.6336305269, -4.7684582358, 41.6882382338],
      "speed factor": 1.0261606348,
      "Uz factor": 0.9896002427,
      "Directional variance factor": 0.5761370457,
      "Velocity stability factor": 0.3495237294,
      "Direction stability factor": 0.7602170312,
      score: 0.6115237094,
    },
    "292.5": {
      velocity: [0.0655661015, 0.3526019435, 0.9184729698],
      direction: [-135.3316890141, -0.5889516922, 162.1087149974],
      "speed factor": 0.4801559352,
      "Uz factor": 0.9989586088,
      "Directional variance factor": 0.9476487385,
      "Velocity stability factor": 0.3745295215,
      "Direction stability factor": 0.1737766555,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0659176449, 0.5386823811, 0.9602628411],
      direction: [-94.4125301236, 8.757232718, 46.441563012],
      "speed factor": 0.6840931911,
      "Uz factor": 0.9985075068,
      "Directional variance factor": 0.221579314,
      "Velocity stability factor": 0.2796635489,
      "Direction stability factor": 0.6087386302,
      score: 0.0,
    },
    id: "IDN:677.0",
    "45": {
      velocity: [0.1425027638, 0.6552628144, 1.0665625596],
      direction: [-97.3224561229, -2.2215442768, 42.1990510713],
      "speed factor": 0.8900969111,
      "Uz factor": 0.9997778254,
      "Directional variance factor": 0.8025293976,
      "Velocity stability factor": 0.2487686262,
      "Direction stability factor": 0.6124402578,
      score: 0.0,
    },
    "90": {
      velocity: [0.53352794, 0.8962246747, 1.1534270244],
      direction: [-13.6458084485, 8.6522498225, 35.6292508533],
      "speed factor": 1.4528064241,
      "Uz factor": 0.9897063598,
      "Directional variance factor": 0.2309111269,
      "Velocity stability factor": 0.5475619844,
      "Direction stability factor": 0.8631248353,
      score: 0.6261806955,
    },
    "135": {
      velocity: [0.6098210809, 1.1539242695, 1.3290531956],
      direction: [1.2162490589, 8.8143662277, 21.1438063612],
      "speed factor": 0.9766241803,
      "Uz factor": 0.9544678608,
      "Directional variance factor": 0.2165007798,
      "Velocity stability factor": 0.4204349874,
      "Direction stability factor": 0.9446456742,
      score: 0.0,
    },
    "180": {
      velocity: [0.4542142904, 0.7693411617, 1.0039294719],
      direction: [-30.207444818, 5.0150089003, 41.7408671221],
      "speed factor": 1.5025185361,
      "Uz factor": 0.9379308033,
      "Directional variance factor": 0.5542214311,
      "Velocity stability factor": 0.5668481405,
      "Direction stability factor": 0.8001435779,
      score: 0.0,
    },
    "225": {
      velocity: [0.1346531981, 0.5001085506, 0.7589839189],
      direction: [-83.1354251829, -8.9496248569, 40.957861015],
      "speed factor": 0.6827029012,
      "Uz factor": 0.9819618243,
      "Directional variance factor": 0.2044777905,
      "Velocity stability factor": 0.438863654,
      "Direction stability factor": 0.6552964272,
      score: 0.0,
    },
    "270": {
      velocity: [0.1957958068, 0.840407845, 1.3302997191],
      direction: [-46.5974526167, 3.348561609, 29.5383374183],
      "speed factor": 0.7690003146,
      "Uz factor": 0.9999126473,
      "Directional variance factor": 0.7023500792,
      "Velocity stability factor": 0.1050996787,
      "Direction stability factor": 0.7885116943,
      score: 0.0,
    },
    "315": {
      velocity: [0.0803646148, 0.4312768551, 0.9440010288],
      direction: [-163.2665411844, -23.3812025063, 16.7236355488],
      "speed factor": 0.5313760053,
      "Uz factor": 0.9961570796,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2993870771,
      "Direction stability factor": 0.5000272869,
      score: 0.0,
    },
  },
  {
    position: [12.50687371, 99.58907243, 47.87999908],
    "overall score": 0.1474107728,
    "0": {
      velocity: [0.5481932212, 1.1097046668, 1.5851949837],
      direction: [-47.9455016586, -20.8651129166, 3.4796936416],
      "speed factor": 1.1371710217,
      "Uz factor": 0.9739659752,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2794592427,
      "Direction stability factor": 0.8571522353,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6870341974, 1.0482344882, 1.4028641247],
      direction: [-54.1290307163, -20.3690891539, -1.0197707395],
      "speed factor": 0.9307018788,
      "Uz factor": 0.9922570859,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5370917915,
      "Direction stability factor": 0.8524742778,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2173635301, 0.468439809, 0.7983927366],
      direction: [-56.0533633976, -8.7052638859, 50.7062763393],
      "speed factor": 0.7256444927,
      "Uz factor": 0.9884260258,
      "Directional variance factor": 0.2261987657,
      "Velocity stability factor": 0.4808187225,
      "Direction stability factor": 0.7034454452,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5799713128, 1.0614612724, 1.2532329861],
      direction: [-21.4279300175, 5.8405732338, 28.2117357318],
      "speed factor": 1.1940697769,
      "Uz factor": 0.9807088157,
      "Directional variance factor": 0.4808379348,
      "Velocity stability factor": 0.442661175,
      "Direction stability factor": 0.8621120396,
      score: 0.6619307972,
    },
    "157.5": {
      velocity: [0.3897652353, 1.0005217973, 1.2525988564],
      direction: [-13.6818427636, 14.0615493992, 56.2126401784],
      "speed factor": 1.4213819782,
      "Uz factor": 0.9984748706,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2943820465,
      "Direction stability factor": 0.8058486585,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1246328489, 0.682139143, 1.1367714722],
      direction: [-164.9173941574, -11.0284833144, 155.1225755531],
      "speed factor": 0.7992320299,
      "Uz factor": 0.9820731208,
      "Directional variance factor": 0.0196903721,
      "Velocity stability factor": 0.2217644927,
      "Direction stability factor": 0.1110000841,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1143299822, 0.4995921191, 0.8629457535],
      direction: [-49.0369434621, -5.3178835116, 138.7728688397],
      "speed factor": 0.926803585,
      "Uz factor": 0.9998019357,
      "Directional variance factor": 0.5272992434,
      "Velocity stability factor": 0.2735822958,
      "Direction stability factor": 0.4783060769,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0146593848, 0.3673413799, 0.8365103233],
      direction: [-159.0529889275, 18.6705797702, 175.8540162473],
      "speed factor": 0.5002273728,
      "Uz factor": 0.9547606028,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3973040682,
      "Direction stability factor": 0.0697027634,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.242445577, 0.7207232449, 1.1175296969],
      direction: [-47.7852604323, 4.1906286885, 30.2028853241],
      "speed factor": 0.9152737899,
      "Uz factor": 0.9997538161,
      "Directional variance factor": 0.6274996721,
      "Velocity stability factor": 0.2951770838,
      "Direction stability factor": 0.7833662618,
      score: 0.0,
    },
    id: "IDN:681.0",
    "45": {
      velocity: [0.2762123176, 0.7059505874, 1.0479414424],
      direction: [-56.3184621447, -6.4029917078, 26.9217057885],
      "speed factor": 0.9589502463,
      "Uz factor": 0.995547389,
      "Directional variance factor": 0.4308451815,
      "Velocity stability factor": 0.3726086415,
      "Direction stability factor": 0.7687773113,
      score: 0.0,
    },
    "90": {
      velocity: [0.5472261092, 0.8156664228, 1.0549552474],
      direction: [-14.2241953756, 9.5137430601, 27.0820849605],
      "speed factor": 1.3222191404,
      "Uz factor": 0.9738327039,
      "Directional variance factor": 0.1543339502,
      "Velocity stability factor": 0.6294300645,
      "Direction stability factor": 0.8852603324,
      score: 0.6385711699,
    },
    "135": {
      velocity: [0.905204209, 1.2411688745, 1.3773992328],
      direction: [-2.6733179057, 9.221642863, 23.3912455046],
      "speed factor": 1.0504636801,
      "Uz factor": 0.9882110968,
      "Directional variance factor": 0.1802984122,
      "Velocity stability factor": 0.6195001456,
      "Direction stability factor": 0.927598435,
      score: 0.6637488569,
    },
    "180": {
      velocity: [0.1750417421, 0.7118967693, 1.134257569],
      direction: [-51.555331555, 9.5987106884, 94.9060601912],
      "speed factor": 1.3903299926,
      "Uz factor": 0.9938032151,
      "Directional variance factor": 0.1467812721,
      "Velocity stability factor": 0.2441792892,
      "Direction stability factor": 0.5931628007,
      score: 0.3943215407,
    },
    "225": {
      velocity: [0.0423843921, 0.446480163, 0.7974452918],
      direction: [-162.3479733071, -13.2650333489, 44.1973492896],
      "speed factor": 0.6094942833,
      "Uz factor": 0.9984835776,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.321365904,
      "Direction stability factor": 0.4262629928,
      score: 0.0,
    },
    "270": {
      velocity: [0.4140306211, 0.9752064376, 1.4422239397],
      direction: [-42.8694774489, 3.7356242376, 28.8699173065],
      "speed factor": 0.8923453794,
      "Uz factor": 0.9990199555,
      "Directional variance factor": 0.6679445122,
      "Velocity stability factor": 0.1889578157,
      "Direction stability factor": 0.8007239035,
      score: 0.0,
    },
    "315": {
      velocity: [0.3060665742, 0.7894617687, 1.1624225414],
      direction: [-63.3438213318, -11.6013377679, 31.9702657675],
      "speed factor": 0.9726954648,
      "Uz factor": 0.9866248646,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3052932375,
      "Direction stability factor": 0.7352386469,
      score: 0.0,
    },
  },
  {
    position: [-46.31176664, 81.4640645, 47.87999908],
    "overall score": 0.141270215,
    "0": {
      velocity: [0.1223314919, 0.5306493715, 1.1818982623],
      direction: [-39.696088434, 14.5179414234, 157.3874082486],
      "speed factor": 0.5437835002,
      "Uz factor": 0.9991497764,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2637803804,
      "Direction stability factor": 0.4525458425,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3256099539, 0.8835433347, 1.3473847199],
      direction: [-43.5141183228, 19.3008318958, 88.8259052881],
      "speed factor": 0.7844766137,
      "Uz factor": 0.9966015147,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3392453872,
      "Direction stability factor": 0.6323888233,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1106575456, 0.5307481042, 0.9940759078],
      direction: [-43.4254923122, 1.6864552419, 60.6568125843],
      "speed factor": 0.8221641958,
      "Uz factor": 0.9782509932,
      "Directional variance factor": 0.8500928674,
      "Velocity stability factor": 0.2106175236,
      "Direction stability factor": 0.7108824864,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2728886677, 0.8423544791, 1.1485864612],
      direction: [-30.7510513859, -2.356108713, 29.344005435],
      "speed factor": 0.9475899413,
      "Uz factor": 0.9624763961,
      "Directional variance factor": 0.7905681144,
      "Velocity stability factor": 0.2750807023,
      "Direction stability factor": 0.8330692866,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4357674339, 0.8408996011, 1.0995345387],
      direction: [-48.2784754081, -13.7670281618, 19.0001775894],
      "speed factor": 1.1946161909,
      "Uz factor": 0.966585887,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4571769404,
      "Direction stability factor": 0.8131148528,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.329047287, 0.7181748294, 1.1442652843],
      direction: [-70.0089871714, -17.5520280864, 8.5008801626],
      "speed factor": 0.8414534375,
      "Uz factor": 0.9889755264,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3731771745,
      "Direction stability factor": 0.7819170352,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.173435004, 0.4857206773, 0.7965415967],
      direction: [-38.2882141962, -6.0699423074, 42.9637046845],
      "speed factor": 0.9010703887,
      "Uz factor": 0.9984048572,
      "Directional variance factor": 0.4604495727,
      "Velocity stability factor": 0.3953698575,
      "Direction stability factor": 0.7743002253,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0785735016, 0.6074149137, 1.1760577807],
      direction: [-55.91809611, -7.7802815412, 110.745316702],
      "speed factor": 0.8271476698,
      "Uz factor": 0.985999659,
      "Directional variance factor": 0.3084194186,
      "Velocity stability factor": 0.1951711931,
      "Direction stability factor": 0.5370460755,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0475474716, 0.4532049056, 1.0397383604],
      direction: [-140.7071655737, 5.8138913627, 145.4656538076],
      "speed factor": 0.5755421023,
      "Uz factor": 0.9996805143,
      "Directional variance factor": 0.4832096566,
      "Velocity stability factor": 0.200855255,
      "Direction stability factor": 0.2050755017,
      score: 0.0,
    },
    id: "IDN:712.0",
    "45": {
      velocity: [0.1399192004, 0.451691168, 0.8272335545],
      direction: [-39.040874109, 6.4700228707, 84.7989156992],
      "speed factor": 0.6135689446,
      "Uz factor": 0.9755606995,
      "Directional variance factor": 0.4248868559,
      "Velocity stability factor": 0.4412351789,
      "Direction stability factor": 0.6560005839,
      score: 0.0,
    },
    "90": {
      velocity: [0.5427803433, 0.9662235464, 1.2373783856],
      direction: [-12.9325135442, 5.7425470151, 23.618698832],
      "speed factor": 1.5662766435,
      "Uz factor": 0.9714684407,
      "Directional variance factor": 0.4895513764,
      "Velocity stability factor": 0.4930423874,
      "Direction stability factor": 0.8984688545,
      score: 0.6948828682,
    },
    "135": {
      velocity: [0.4960354002, 0.9705646871, 1.2403230832],
      direction: [-58.9327339551, -19.1840270329, 9.3800460551],
      "speed factor": 0.8214377382,
      "Uz factor": 0.9458130676,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4002449396,
      "Direction stability factor": 0.8102422777,
      score: 0.0,
    },
    "180": {
      velocity: [0.4531771015, 0.8242272976, 1.2484129686],
      direction: [-45.5610710855, -27.1702724308, -10.2515372031],
      "speed factor": 1.6097108205,
      "Uz factor": 0.9734981429,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3733884268,
      "Direction stability factor": 0.9019179614,
      score: 0.0,
    },
    "225": {
      velocity: [0.5307991605, 0.7595058179, 1.0803131006],
      direction: [-22.9021815638, 0.800200891, 19.720275322],
      "speed factor": 1.0368085583,
      "Uz factor": 0.9979879421,
      "Directional variance factor": 0.9288710319,
      "Velocity stability factor": 0.5061075258,
      "Direction stability factor": 0.8816042864,
      score: 0.7995467826,
    },
    "270": {
      velocity: [0.2324778618, 0.8169995566, 1.2298100499],
      direction: [-25.5415886829, 6.4677830716, 24.8243006362],
      "speed factor": 0.7475809748,
      "Uz factor": 0.9848214959,
      "Directional variance factor": 0.4250859492,
      "Velocity stability factor": 0.2133011743,
      "Direction stability factor": 0.8600947519,
      score: 0.0,
    },
    "315": {
      velocity: [0.2991732563, 0.8502326739, 1.1824918684],
      direction: [-14.3652989735, -0.5601059125, 16.2451087243],
      "speed factor": 1.0475712678,
      "Uz factor": 0.9981722408,
      "Directional variance factor": 0.9502128078,
      "Velocity stability factor": 0.2834201701,
      "Direction stability factor": 0.9149710897,
      score: 0.7658937893,
    },
  },
  {
    position: [-46.31567545, 80.34427683, 47.87999908],
    "overall score": 0.138904173,
    "0": {
      velocity: [0.094653694, 0.5346540545, 1.213993527],
      direction: [-168.3487708525, 8.337383227, 126.3901170428],
      "speed factor": 0.5478873033,
      "Uz factor": 0.9996180854,
      "Directional variance factor": 0.2588992687,
      "Velocity stability factor": 0.2222482158,
      "Direction stability factor": 0.181280867,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.377541344, 0.8801739196, 1.3390882829],
      direction: [-51.6105380643, 18.7408677291, 113.5904672455],
      "speed factor": 0.7814849921,
      "Uz factor": 0.9958476424,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3781931239,
      "Direction stability factor": 0.5411083186,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1461077603, 0.531592412, 0.9557239015],
      direction: [-51.335172722, -0.4865278485, 54.4229702899],
      "speed factor": 0.8234720848,
      "Uz factor": 0.9780076889,
      "Directional variance factor": 0.9567530801,
      "Velocity stability factor": 0.2765638322,
      "Direction stability factor": 0.7062273805,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2626852777, 0.8491937378, 1.1470531908],
      direction: [-33.1566878064, -3.0763476685, 27.4735910255],
      "speed factor": 0.9552836297,
      "Uz factor": 0.9669541187,
      "Directional variance factor": 0.7265468739,
      "Velocity stability factor": 0.2679034123,
      "Direction stability factor": 0.8315825588,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4228463354, 0.8339008146, 1.1038419082],
      direction: [-46.8644659996, -12.7748263646, 20.1502918148],
      "speed factor": 1.1846734301,
      "Uz factor": 0.9692057013,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4430876467,
      "Direction stability factor": 0.813847895,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3431704016, 0.6995053989, 1.1406133745],
      direction: [-66.0305824265, -15.4304495774, 14.8269602654],
      "speed factor": 0.8195792979,
      "Uz factor": 0.9857399465,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3868444279,
      "Direction stability factor": 0.7753957147,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2915073189, 0.4981104453, 0.8120520844],
      direction: [-33.8988718579, -5.0377337991, 36.6683878997],
      "speed factor": 0.9240549014,
      "Uz factor": 0.9964037383,
      "Directional variance factor": 0.5522014401,
      "Velocity stability factor": 0.494890506,
      "Direction stability factor": 0.803979834,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0293651666, 0.6760467867, 1.2223332453],
      direction: [-62.0243137971, -7.0865813258, 53.9912907745],
      "speed factor": 0.9206071693,
      "Uz factor": 0.9886921373,
      "Directional variance factor": 0.3700816599,
      "Velocity stability factor": 0.1251491309,
      "Direction stability factor": 0.6777344317,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0745914263, 0.5040189408, 1.1202440089],
      direction: [-151.9442173099, 4.3715144919, 145.4752000248],
      "speed factor": 0.6400727733,
      "Uz factor": 0.9999039949,
      "Directional variance factor": 0.6114209341,
      "Velocity stability factor": 0.1577953639,
      "Direction stability factor": 0.1738349518,
      score: 0.0,
    },
    id: "IDN:711.0",
    "45": {
      velocity: [0.1758096387, 0.4543294428, 0.8601903606],
      direction: [-37.7801115338, 4.2611148859, 77.7128653079],
      "speed factor": 0.6171527283,
      "Uz factor": 0.9736843236,
      "Directional variance factor": 0.6212342324,
      "Velocity stability factor": 0.443620129,
      "Direction stability factor": 0.6791861754,
      score: 0.0,
    },
    "90": {
      velocity: [0.5126660688, 0.9933148421, 1.2599736976],
      direction: [-11.7027908197, 4.0269962741, 21.9898529502],
      "speed factor": 1.6101924266,
      "Uz factor": 0.9797053592,
      "Directional variance factor": 0.6420447756,
      "Velocity stability factor": 0.4545718986,
      "Direction stability factor": 0.9064093229,
      score: 0.72735883,
    },
    "135": {
      velocity: [0.5147931415, 0.9601734615, 1.2217208638],
      direction: [-51.8737654167, -19.2027543522, 11.0332598544],
      "speed factor": 0.8126431211,
      "Uz factor": 0.9576799654,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4303499998,
      "Direction stability factor": 0.8252582631,
      score: 0.0,
    },
    "180": {
      velocity: [0.4429562269, 0.8136166533, 1.2164765699],
      direction: [-43.9810732641, -25.2078242056, -8.7125200365],
      "speed factor": 1.5889882976,
      "Uz factor": 0.9768747168,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3904993235,
      "Direction stability factor": 0.9020317966,
      score: 0.0,
    },
    "225": {
      velocity: [0.5355872765, 0.7426102817, 1.0807384343],
      direction: [-20.8878404162, 1.5401574248, 21.1463715387],
      "speed factor": 1.0137443024,
      "Uz factor": 0.9962616294,
      "Directional variance factor": 0.8630971178,
      "Velocity stability factor": 0.5100287099,
      "Direction stability factor": 0.8832383001,
      score: 0.784900607,
    },
    "270": {
      velocity: [0.3837192652, 0.9221387072, 1.2809618737],
      direction: [-14.6726014696, 5.4192175003, 23.2116261165],
      "speed factor": 0.8437866926,
      "Uz factor": 0.989048823,
      "Directional variance factor": 0.5182917778,
      "Velocity stability factor": 0.2922521554,
      "Direction stability factor": 0.8947660345,
      score: 0.0,
    },
    "315": {
      velocity: [0.20258285, 0.8285633069, 1.1856814377],
      direction: [-13.6310507284, -0.4248515985, 44.6675571328],
      "speed factor": 1.0208724512,
      "Uz factor": 0.9980868495,
      "Directional variance factor": 0.9622354135,
      "Velocity stability factor": 0.2024750649,
      "Direction stability factor": 0.8380594226,
      score: 0.7102073309,
    },
  },
  {
    position: [-46.153284, 82.7039409, 47.87999908],
    "overall score": 0.137823226,
    "0": {
      velocity: [0.1291070755, 0.5276985804, 1.1047990067],
      direction: [-33.1356137643, 18.1923523259, 153.6553963306],
      "speed factor": 0.5407596739,
      "Uz factor": 0.9965033603,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3220592013,
      "Direction stability factor": 0.4811360831,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3218274982, 0.8790713626, 1.3559666911],
      direction: [-29.8860536397, 19.2513415484, 78.3383887137],
      "speed factor": 0.7805060585,
      "Uz factor": 0.9974536062,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3312496406,
      "Direction stability factor": 0.699376549,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0952437244, 0.5344076397, 0.9841690861],
      direction: [-52.2774274699, 3.132024914, 66.7794317925],
      "speed factor": 0.8278330603,
      "Uz factor": 0.9807009028,
      "Directional variance factor": 0.7215977854,
      "Velocity stability factor": 0.2056967193,
      "Direction stability factor": 0.669286502,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2861201216, 0.8317199192, 1.1374771478],
      direction: [-26.5380517655, -0.2825171486, 28.9917019607],
      "speed factor": 0.9356268044,
      "Uz factor": 0.9539349684,
      "Directional variance factor": 0.9748873646,
      "Velocity stability factor": 0.2952304527,
      "Direction stability factor": 0.8457506841,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4626245798, 0.8458305948, 1.1327561944],
      direction: [-48.6760471921, -14.6955693046, 20.1188071137],
      "speed factor": 1.2016213613,
      "Uz factor": 0.9616790107,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4519720987,
      "Direction stability factor": 0.8089031825,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3703905056, 0.7225809164, 1.1356164283],
      direction: [-71.8857519008, -20.0046747311, 10.9421610803],
      "speed factor": 0.8466158533,
      "Uz factor": 0.9899639603,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4116161853,
      "Direction stability factor": 0.7699224639,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0773188118, 0.4741162898, 0.7796604604],
      direction: [-39.9218600616, -6.4163536867, 47.3146654751],
      "speed factor": 0.8795428516,
      "Uz factor": 0.999616177,
      "Directional variance factor": 0.4296574501,
      "Velocity stability factor": 0.3184842913,
      "Direction stability factor": 0.757676318,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.111265468, 0.5645537507, 1.1607587358],
      direction: [-98.3124489949, -9.1807655415, 155.3309364046],
      "speed factor": 0.7687814521,
      "Uz factor": 0.9840116823,
      "Directional variance factor": 0.1839319519,
      "Velocity stability factor": 0.2303649077,
      "Direction stability factor": 0.2954350406,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0647927998, 0.4015413121, 0.9403397677],
      direction: [-149.0731948172, 9.1863610575, 179.5847286157],
      "speed factor": 0.5099325451,
      "Uz factor": 0.9993217568,
      "Directional variance factor": 0.1834345727,
      "Velocity stability factor": 0.2948042899,
      "Direction stability factor": 0.0870613238,
      score: 0.0,
    },
    id: "IDN:713.0",
    "45": {
      velocity: [0.1064869081, 0.4628669533, 0.7860811472],
      direction: [-49.0453598489, 8.1705721482, 66.1749253544],
      "speed factor": 0.628749925,
      "Uz factor": 0.9802752562,
      "Directional variance factor": 0.2737269202,
      "Velocity stability factor": 0.4475113881,
      "Direction stability factor": 0.6799436522,
      score: 0.0,
    },
    "90": {
      velocity: [0.5088253961, 0.938425163, 1.2373462327],
      direction: [-12.729959348, 8.4754234031, 28.8841198191],
      "speed factor": 1.5212146505,
      "Uz factor": 0.9600838951,
      "Directional variance factor": 0.2466290308,
      "Velocity stability factor": 0.4682835804,
      "Direction stability factor": 0.8844053356,
      score: 0.6209308206,
    },
    "135": {
      velocity: [0.4751565019, 0.9824640031, 1.2450380427],
      direction: [-65.4130522457, -17.9526513802, 9.2866780025],
      "speed factor": 0.8315087281,
      "Uz factor": 0.9303651608,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3796211323,
      "Direction stability factor": 0.7925007493,
      score: 0.0,
    },
    "180": {
      velocity: [0.4942525882, 0.8352858388, 1.2679618178],
      direction: [-48.8943301734, -28.6133787293, -11.6014876735],
      "speed factor": 1.6313080831,
      "Uz factor": 0.9679203065,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.390350489,
      "Direction stability factor": 0.8964087708,
      score: 0.0,
    },
    "225": {
      velocity: [0.5455879873, 0.7760788916, 1.0831762177],
      direction: [-24.2328608042, 0.626001736, 18.5698333674],
      "speed factor": 1.0594326176,
      "Uz factor": 0.9993838343,
      "Directional variance factor": 0.9443554012,
      "Velocity stability factor": 0.5168261223,
      "Direction stability factor": 0.8811036273,
      score: 0.8058471945,
    },
    "270": {
      velocity: [0.10290887, 0.7243601524, 1.1476928909],
      direction: [-11.2844670277, 6.6149126729, 54.4639098633],
      "speed factor": 0.6628129288,
      "Uz factor": 0.9802952789,
      "Directional variance factor": 0.4120077624,
      "Velocity stability factor": 0.1758710165,
      "Direction stability factor": 0.8173656197,
      score: 0.0,
    },
    "315": {
      velocity: [0.3754244153, 0.8662035052, 1.1801967433],
      direction: [-15.8020248602, -0.519843481, 17.9222407157],
      "speed factor": 1.0672489213,
      "Uz factor": 0.9989371269,
      "Directional variance factor": 0.9537916906,
      "Velocity stability factor": 0.347139741,
      "Direction stability factor": 0.9063214845,
      score: 0.7783936001,
    },
  },
  {
    position: [-46.42617996, 79.94135678, 47.87999908],
    "overall score": 0.1372449062,
    "0": {
      velocity: [0.1164691873, 0.5437234881, 1.2192230666],
      direction: [-110.4860101931, 5.9349765198, 126.7414382169],
      "speed factor": 0.5571812149,
      "Uz factor": 0.9998925866,
      "Directional variance factor": 0.4724465316,
      "Velocity stability factor": 0.2337726472,
      "Direction stability factor": 0.3410348655,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3920836208, 0.8753259135, 1.330995619],
      direction: [-50.905666624, 18.8158476346, 119.3138726063],
      "speed factor": 0.7771805655,
      "Uz factor": 0.9959350998,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3928305391,
      "Direction stability factor": 0.5271679466,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1534264556, 0.5318937308, 0.9443054016],
      direction: [-54.7369705274, -0.8961981852, 52.5903072079],
      "speed factor": 0.8239388478,
      "Uz factor": 0.9785721702,
      "Directional variance factor": 0.9203379391,
      "Velocity stability factor": 0.2933065378,
      "Direction stability factor": 0.701868673,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2586655221, 0.8527366272, 1.1489942761],
      direction: [-33.3592273421, -3.2334574354, 26.537199496],
      "speed factor": 0.9592691327,
      "Uz factor": 0.9686882568,
      "Directional variance factor": 0.7125815613,
      "Velocity stability factor": 0.2629689148,
      "Direction stability factor": 0.8336210366,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4221260955, 0.8331774, 1.1129318782],
      direction: [-46.3941966145, -12.6403909886, 18.7426010336],
      "speed factor": 1.1836457179,
      "Uz factor": 0.970297166,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.435064941,
      "Direction stability factor": 0.819064451,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3683978134, 0.6985987211, 1.1437593212],
      direction: [-64.6070731593, -14.7102025748, 20.3060823507],
      "speed factor": 0.818516984,
      "Uz factor": 0.9853077936,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4038229127,
      "Direction stability factor": 0.7641301236,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.293461726, 0.5016072701, 0.8170584214],
      direction: [-32.6940400254, -4.6025175953, 36.4877512528],
      "speed factor": 0.9305419328,
      "Uz factor": 0.9958373237,
      "Directional variance factor": 0.5908873249,
      "Velocity stability factor": 0.4919290724,
      "Direction stability factor": 0.8078283576,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0414893052, 0.7112012512, 1.2524447954],
      direction: [-113.7530235888, -7.0028337142, 48.1258941343],
      "speed factor": 0.9684787852,
      "Uz factor": 0.9888542234,
      "Directional variance factor": 0.3775258921,
      "Velocity stability factor": 0.1119582477,
      "Direction stability factor": 0.5503363397,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0809089068, 0.5391149935, 1.1411658136],
      direction: [-143.3933264863, 3.6568821839, 144.7612280272],
      "speed factor": 0.684642582,
      "Uz factor": 0.9999731016,
      "Directional variance factor": 0.6749438059,
      "Velocity stability factor": 0.1460325378,
      "Direction stability factor": 0.1995706819,
      score: 0.0,
    },
    id: "IDN:710.0",
    "45": {
      velocity: [0.1833856973, 0.4567680543, 0.8616134883],
      direction: [-38.1980989555, 3.6545506948, 80.9766480203],
      "speed factor": 0.6204652932,
      "Uz factor": 0.9740638259,
      "Directional variance factor": 0.6751510494,
      "Velocity stability factor": 0.4486222671,
      "Direction stability factor": 0.6689590362,
      score: 0.0,
    },
    "90": {
      velocity: [0.5183544783, 1.0042540647, 1.2742877929],
      direction: [-12.7299314787, 3.5581583374, 21.5646703287],
      "speed factor": 1.6279252265,
      "Uz factor": 0.9822315462,
      "Directional variance factor": 0.6837192589,
      "Velocity stability factor": 0.4482763769,
      "Direction stability factor": 0.9047372172,
      score: 0.7353675175,
    },
    "135": {
      velocity: [0.4751070834, 0.9606412085, 1.2182444114],
      direction: [-51.9761125779, -19.2797521307, 11.8193698581],
      "speed factor": 0.8130389989,
      "Uz factor": 0.9619023061,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4011719082,
      "Direction stability factor": 0.8227903266,
      score: 0.0,
    },
    "180": {
      velocity: [0.4454328686, 0.8110404448, 1.2036156782],
      direction: [-44.9739673153, -24.7246703206, -8.3923570024],
      "speed factor": 1.5839569783,
      "Uz factor": 0.9783999236,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4025846385,
      "Direction stability factor": 0.8983844158,
      score: 0.0,
    },
    "225": {
      velocity: [0.5409741213, 0.7374571998, 1.0794879012],
      direction: [-19.9101663315, 1.9434444466, 21.8289920704],
      "speed factor": 1.0067097818,
      "Uz factor": 0.9956871579,
      "Directional variance factor": 0.8272493825,
      "Velocity stability factor": 0.5159942564,
      "Direction stability factor": 0.8840578933,
      score: 0.7778398564,
    },
    "270": {
      velocity: [0.4125298856, 0.9677174277, 1.3384618414],
      direction: [-15.1710022457, 5.2443759935, 22.794693956],
      "speed factor": 0.8854926935,
      "Uz factor": 0.98946621,
      "Directional variance factor": 0.533833245,
      "Velocity stability factor": 0.2696219064,
      "Direction stability factor": 0.8945397328,
      score: 0.0,
    },
    "315": {
      velocity: [0.1865482348, 0.8259246827, 1.1892620629],
      direction: [-13.4855430974, -0.6709131064, 57.8090830579],
      "speed factor": 1.0176214035,
      "Uz factor": 0.9978748463,
      "Directional variance factor": 0.9403632794,
      "Velocity stability factor": 0.1865624764,
      "Direction stability factor": 0.8019593718,
      score: 0.6827111249,
    },
  },
  {
    position: [-48.9216034, 95.65235413, 46.87999908],
    "overall score": 0.1368073062,
    "0": {
      velocity: [0.6370721255, 1.1263310181, 1.3433788434],
      direction: [-19.7629808549, -1.7177566723, 34.0697828297],
      "speed factor": 1.1542088926,
      "Uz factor": 0.9314253858,
      "Directional variance factor": 0.847310518,
      "Velocity stability factor": 0.5092363428,
      "Direction stability factor": 0.8504645453,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4112698482, 1.0789887103, 1.542188387],
      direction: [-38.947152248, 21.3807524344, 49.8931788136],
      "speed factor": 0.9580078038,
      "Uz factor": 0.9422866032,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2686650071,
      "Direction stability factor": 0.7532213026,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2197537732, 0.6916603518, 1.2164361334],
      direction: [-31.2453095408, -4.3229346673, 46.587417459],
      "speed factor": 1.0714279946,
      "Uz factor": 0.99170314,
      "Directional variance factor": 0.6157391407,
      "Velocity stability factor": 0.1094099655,
      "Direction stability factor": 0.7837979806,
      score: 0.5731862668,
    },
    "112.5": {
      velocity: [0.0562545275, 0.3638297981, 0.8510634953],
      direction: [-170.2963186732, 50.3889506503, 178.8086829804],
      "speed factor": 0.4092831054,
      "Uz factor": 0.9920516079,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3420420115,
      "Direction stability factor": 0.0302638843,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0412672017, 0.3226024307, 0.7728120446],
      direction: [-178.6848695488, 3.282554368, 178.195377683],
      "speed factor": 0.4583021403,
      "Uz factor": 0.9870998019,
      "Directional variance factor": 0.7082173895,
      "Velocity stability factor": 0.401748886,
      "Direction stability factor": 0.0086659799,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5443952107, 1.0230194408, 1.5140694351],
      direction: [-49.9541187752, -11.113366555, 18.1625830249],
      "speed factor": 1.1986262813,
      "Uz factor": 0.9962175669,
      "Directional variance factor": 0.0121451951,
      "Velocity stability factor": 0.2544154579,
      "Direction stability factor": 0.8107869394,
      score: 0.472033633,
    },
    "247.5": {
      velocity: [0.0645957631, 0.4287140967, 0.8735714972],
      direction: [-121.6218139817, -4.7664143358, 30.942080078],
      "speed factor": 0.7953163122,
      "Uz factor": 0.9783054119,
      "Directional variance factor": 0.5763187257,
      "Velocity stability factor": 0.2150121358,
      "Direction stability factor": 0.5762114054,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3693850718, 0.778559604, 1.1481501266],
      direction: [-61.0202326617, -22.0153091195, 29.5272898578],
      "speed factor": 1.0602040677,
      "Uz factor": 0.9701205066,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4289006578,
      "Direction stability factor": 0.7484791041,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7197968481, 0.9305871629, 1.0902290235],
      direction: [-6.0274259367, 6.5814932788, 28.9147049023],
      "speed factor": 1.1817879407,
      "Uz factor": 0.8893895622,
      "Directional variance factor": 0.4149783752,
      "Velocity stability factor": 0.7016411563,
      "Direction stability factor": 0.9029385254,
      score: 0.0,
    },
    id: "IDN:429.0",
    "45": {
      velocity: [0.1516865363, 0.7243833501, 1.229588959],
      direction: [-30.0489434772, -2.5853502104, 54.846730338],
      "speed factor": 0.9839889709,
      "Uz factor": 0.967151522,
      "Directional variance factor": 0.7701910924,
      "Velocity stability factor": 0.1236994385,
      "Direction stability factor": 0.7641786838,
      score: 0.0,
    },
    "90": {
      velocity: [0.11401639, 0.5542830329, 1.0510062962],
      direction: [-138.6100287175, 21.1729289321, 173.61185243],
      "speed factor": 0.8985090165,
      "Uz factor": 0.9969142456,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3161308599,
      "Direction stability factor": 0.1327169968,
      score: 0.0,
    },
    "135": {
      velocity: [0.082397606, 0.3487965202, 0.8127106169],
      direction: [-179.8954015509, 100.7971684966, 176.6891414588],
      "speed factor": 0.2952040481,
      "Uz factor": 0.9831341815,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4115058815,
      "Direction stability factor": 0.0094873805,
      score: 0.0,
    },
    "180": {
      velocity: [0.078641186, 0.3926092453, 1.0437017491],
      direction: [-178.6520490659, -32.4819119222, 172.11949945],
      "speed factor": 0.7667634307,
      "Uz factor": 0.991238447,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2395738891,
      "Direction stability factor": 0.0256345875,
      score: 0.0,
    },
    "225": {
      velocity: [0.2787767301, 0.8647509836, 1.1867638697],
      direction: [-120.2304427499, -2.8907154266, 14.300520785],
      "speed factor": 1.180479727,
      "Uz factor": 0.9934856023,
      "Directional variance factor": 0.7430475176,
      "Velocity stability factor": 0.1839187649,
      "Direction stability factor": 0.6263028791,
      score: 0.5448930102,
    },
    "270": {
      velocity: [0.8318147578, 1.1325169025, 1.3712346265],
      direction: [-30.3922888538, -11.0215583773, 5.5346032569],
      "speed factor": 1.0362895343,
      "Uz factor": 0.9862165956,
      "Directional variance factor": 0.020305922,
      "Velocity stability factor": 0.5745038791,
      "Direction stability factor": 0.9002030775,
      score: 0.598803989,
    },
    "315": {
      velocity: [0.7036579177, 0.8448167616, 0.9948315851],
      direction: [-12.2020062214, 7.9552249359, 27.3298470006],
      "speed factor": 1.0408983248,
      "Uz factor": 0.9154917594,
      "Directional variance factor": 0.2928688946,
      "Velocity stability factor": 0.7637894479,
      "Direction stability factor": 0.8901892966,
      score: 0.0,
    },
  },
  {
    position: [-48.9216034, 95.65235413, 46.87999908],
    "overall score": 0.1368073062,
    "0": {
      velocity: [0.6370721255, 1.1263310181, 1.3433788434],
      direction: [-19.7629808549, -1.7177566723, 34.0697828297],
      "speed factor": 1.1542088926,
      "Uz factor": 0.9314253858,
      "Directional variance factor": 0.847310518,
      "Velocity stability factor": 0.5092363428,
      "Direction stability factor": 0.8504645453,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4112698482, 1.0789887103, 1.542188387],
      direction: [-38.947152248, 21.3807524344, 49.8931788136],
      "speed factor": 0.9580078038,
      "Uz factor": 0.9422866032,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2686650071,
      "Direction stability factor": 0.7532213026,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2197537732, 0.6916603518, 1.2164361334],
      direction: [-31.2453095408, -4.3229346673, 46.587417459],
      "speed factor": 1.0714279946,
      "Uz factor": 0.99170314,
      "Directional variance factor": 0.6157391407,
      "Velocity stability factor": 0.1094099655,
      "Direction stability factor": 0.7837979806,
      score: 0.5731862668,
    },
    "112.5": {
      velocity: [0.0562545275, 0.3638297981, 0.8510634953],
      direction: [-170.2963186732, 50.3889506503, 178.8086829804],
      "speed factor": 0.4092831054,
      "Uz factor": 0.9920516079,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3420420115,
      "Direction stability factor": 0.0302638843,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0412672017, 0.3226024307, 0.7728120446],
      direction: [-178.6848695488, 3.282554368, 178.195377683],
      "speed factor": 0.4583021403,
      "Uz factor": 0.9870998019,
      "Directional variance factor": 0.7082173895,
      "Velocity stability factor": 0.401748886,
      "Direction stability factor": 0.0086659799,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5443952107, 1.0230194408, 1.5140694351],
      direction: [-49.9541187752, -11.113366555, 18.1625830249],
      "speed factor": 1.1986262813,
      "Uz factor": 0.9962175669,
      "Directional variance factor": 0.0121451951,
      "Velocity stability factor": 0.2544154579,
      "Direction stability factor": 0.8107869394,
      score: 0.472033633,
    },
    "247.5": {
      velocity: [0.0645957631, 0.4287140967, 0.8735714972],
      direction: [-121.6218139817, -4.7664143358, 30.942080078],
      "speed factor": 0.7953163122,
      "Uz factor": 0.9783054119,
      "Directional variance factor": 0.5763187257,
      "Velocity stability factor": 0.2150121358,
      "Direction stability factor": 0.5762114054,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3693850718, 0.778559604, 1.1481501266],
      direction: [-61.0202326617, -22.0153091195, 29.5272898578],
      "speed factor": 1.0602040677,
      "Uz factor": 0.9701205066,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4289006578,
      "Direction stability factor": 0.7484791041,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7197968481, 0.9305871629, 1.0902290235],
      direction: [-6.0274259367, 6.5814932788, 28.9147049023],
      "speed factor": 1.1817879407,
      "Uz factor": 0.8893895622,
      "Directional variance factor": 0.4149783752,
      "Velocity stability factor": 0.7016411563,
      "Direction stability factor": 0.9029385254,
      score: 0.0,
    },
    id: "IDN:430.0",
    "45": {
      velocity: [0.1516865363, 0.7243833501, 1.229588959],
      direction: [-30.0489434772, -2.5853502104, 54.846730338],
      "speed factor": 0.9839889709,
      "Uz factor": 0.967151522,
      "Directional variance factor": 0.7701910924,
      "Velocity stability factor": 0.1236994385,
      "Direction stability factor": 0.7641786838,
      score: 0.0,
    },
    "90": {
      velocity: [0.11401639, 0.5542830329, 1.0510062962],
      direction: [-138.6100287175, 21.1729289321, 173.61185243],
      "speed factor": 0.8985090165,
      "Uz factor": 0.9969142456,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3161308599,
      "Direction stability factor": 0.1327169968,
      score: 0.0,
    },
    "135": {
      velocity: [0.082397606, 0.3487965202, 0.8127106169],
      direction: [-179.8954015509, 100.7971684966, 176.6891414588],
      "speed factor": 0.2952040481,
      "Uz factor": 0.9831341815,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4115058815,
      "Direction stability factor": 0.0094873805,
      score: 0.0,
    },
    "180": {
      velocity: [0.078641186, 0.3926092453, 1.0437017491],
      direction: [-178.6520490659, -32.4819119222, 172.11949945],
      "speed factor": 0.7667634307,
      "Uz factor": 0.991238447,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2395738891,
      "Direction stability factor": 0.0256345875,
      score: 0.0,
    },
    "225": {
      velocity: [0.2787767301, 0.8647509836, 1.1867638697],
      direction: [-120.2304427499, -2.8907154266, 14.300520785],
      "speed factor": 1.180479727,
      "Uz factor": 0.9934856023,
      "Directional variance factor": 0.7430475176,
      "Velocity stability factor": 0.1839187649,
      "Direction stability factor": 0.6263028791,
      score: 0.5448930102,
    },
    "270": {
      velocity: [0.8318147578, 1.1325169025, 1.3712346265],
      direction: [-30.3922888538, -11.0215583773, 5.5346032569],
      "speed factor": 1.0362895343,
      "Uz factor": 0.9862165956,
      "Directional variance factor": 0.020305922,
      "Velocity stability factor": 0.5745038791,
      "Direction stability factor": 0.9002030775,
      score: 0.598803989,
    },
    "315": {
      velocity: [0.7036579177, 0.8448167616, 0.9948315851],
      direction: [-12.2020062214, 7.9552249359, 27.3298470006],
      "speed factor": 1.0408983248,
      "Uz factor": 0.9154917594,
      "Directional variance factor": 0.2928688946,
      "Velocity stability factor": 0.7637894479,
      "Direction stability factor": 0.8901892966,
      score: 0.0,
    },
  },
  {
    position: [12.50687371, 99.58907243, 46.87999908],
    "overall score": 0.1356411314,
    "0": {
      velocity: [0.2876884487, 0.9645250768, 1.5527192754],
      direction: [-46.3213027867, -22.2710625704, 1.6458805436],
      "speed factor": 0.9883980844,
      "Uz factor": 0.9710745963,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.121017627,
      "Direction stability factor": 0.8667578241,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6884409169, 1.0457396795, 1.4027061975],
      direction: [-64.94287992, -22.9178041607, -1.061029567],
      "speed factor": 0.928486799,
      "Uz factor": 0.9928991028,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.538103607,
      "Direction stability factor": 0.8225504157,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2235694807, 0.4538979421, 0.7970641357],
      direction: [-56.9936552149, -8.000132201, 48.9096475086],
      "speed factor": 0.7031181714,
      "Uz factor": 0.9829620343,
      "Directional variance factor": 0.2888771377,
      "Velocity stability factor": 0.4875512551,
      "Direction stability factor": 0.7058241591,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5659778314, 1.0451806134, 1.2514634097],
      direction: [-22.7817734151, 5.8957358195, 32.0992500778],
      "speed factor": 1.1757551728,
      "Uz factor": 0.971203634,
      "Directional variance factor": 0.4759345938,
      "Velocity stability factor": 0.4325419937,
      "Direction stability factor": 0.8475527125,
      score: 0.6508955031,
    },
    "157.5": {
      velocity: [0.2414287788, 0.9647264767, 1.2852951135],
      direction: [-18.5846220844, 14.2601752026, 62.0951823036],
      "speed factor": 1.3705296892,
      "Uz factor": 0.998636323,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1463350421,
      "Direction stability factor": 0.7758894323,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.098686678, 0.5406281291, 1.0002926812],
      direction: [-169.924299108, -14.9765771554, 162.5135058731],
      "speed factor": 0.6334298823,
      "Uz factor": 0.9814697217,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3067532558,
      "Direction stability factor": 0.0765616528,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1008268482, 0.4392563162, 0.8629517745],
      direction: [-54.4399038609, -4.5011754836, 131.7554163497],
      "speed factor": 0.8148733997,
      "Uz factor": 0.9996684392,
      "Directional variance factor": 0.5998955126,
      "Velocity stability factor": 0.2604737164,
      "Direction stability factor": 0.4827907772,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0427699617, 0.3098610779, 0.8346830899],
      direction: [-166.128177006, 20.9809154151, 178.0870829121],
      "speed factor": 0.4219535326,
      "Uz factor": 0.941551751,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4192586534,
      "Direction stability factor": 0.0438465002,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.201040206, 0.6854780903, 1.1504027072],
      direction: [-53.9304527951, 2.7947462874, 33.2305579409],
      "speed factor": 0.870514631,
      "Uz factor": 0.9994057455,
      "Directional variance factor": 0.7515781078,
      "Velocity stability factor": 0.2353507148,
      "Direction stability factor": 0.7578860813,
      score: 0.0,
    },
    id: "IDN:415.0",
    "45": {
      velocity: [0.2468711868, 0.6757565868, 0.994886514],
      direction: [-65.081235121, -7.7435932869, 25.8206783013],
      "speed factor": 0.9179352733,
      "Uz factor": 0.9942223166,
      "Directional variance factor": 0.3116805967,
      "Velocity stability factor": 0.3918872085,
      "Direction stability factor": 0.7474946849,
      score: 0.0,
    },
    "90": {
      velocity: [0.5017598316, 0.8061970919, 1.0625803537],
      direction: [-17.5334850941, 10.8718771207, 31.0333806959],
      "speed factor": 1.3068690779,
      "Uz factor": 0.9644189403,
      "Directional variance factor": 0.0336109226,
      "Velocity stability factor": 0.5906809181,
      "Direction stability factor": 0.8650920395,
      score: 0.5886189799,
    },
    "135": {
      velocity: [0.9116530135, 1.2368976132, 1.3816973637],
      direction: [-4.4230189082, 9.6214048973, 25.9054142501],
      "speed factor": 1.0468486967,
      "Uz factor": 0.9842653396,
      "Directional variance factor": 0.1447640091,
      "Velocity stability factor": 0.6212331817,
      "Direction stability factor": 0.9157543523,
      score: 0.6493764739,
    },
    "180": {
      velocity: [0.0876156361, 0.5674709935, 1.0163531933],
      direction: [-79.6599369361, 9.6086869663, 152.2917995807],
      "speed factor": 1.1082673446,
      "Uz factor": 0.9956098001,
      "Directional variance factor": 0.1458944919,
      "Velocity stability factor": 0.2681948516,
      "Direction stability factor": 0.3556896208,
      score: 0.2813671463,
    },
    "225": {
      velocity: [0.0454664453, 0.3876950936, 0.8633698385],
      direction: [-144.3742912605, -15.2596722045, 141.8028727353],
      "speed factor": 0.5292462303,
      "Uz factor": 0.9978592482,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2648842894,
      "Direction stability factor": 0.2050634333,
      score: 0.0,
    },
    "270": {
      velocity: [0.3735211958, 0.8894561633, 1.3162950742],
      direction: [-55.3763482487, 5.0068563748, 47.1634593224],
      "speed factor": 0.8138811096,
      "Uz factor": 0.9984884926,
      "Directional variance factor": 0.5549461,
      "Velocity stability factor": 0.2563369438,
      "Direction stability factor": 0.7151672012,
      score: 0.0,
    },
    "315": {
      velocity: [0.1419708438, 0.6314369896, 1.1663947159],
      direction: [-70.8568411146, -12.8957946058, 26.9486333999],
      "speed factor": 0.7779932106,
      "Uz factor": 0.9886930867,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1689505078,
      "Direction stability factor": 0.7283181263,
      score: 0.0,
    },
  },
  {
    position: [-5.263975058, 93.65106809, 47.87999908],
    "overall score": 0.1335498066,
    "0": {
      velocity: [0.2877804871, 0.9673910584, 1.4622131754],
      direction: [-154.1844886319, 5.3683556733, 121.442398132],
      "speed factor": 0.9913350021,
      "Uz factor": 0.9947980961,
      "Directional variance factor": 0.522812829,
      "Velocity stability factor": 0.1839680035,
      "Direction stability factor": 0.234369759,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1280315389, 0.638219628, 1.2474190205],
      direction: [-38.8171038783, -0.4178856212, 166.9435116228],
      "speed factor": 0.5666596678,
      "Uz factor": 0.9936007806,
      "Directional variance factor": 0.9628546114,
      "Velocity stability factor": 0.2761218356,
      "Direction stability factor": 0.4284427347,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1063835304, 0.4607741534, 0.9427434709],
      direction: [-36.1263458414, 11.4978223862, 86.4990786865],
      "speed factor": 0.7137698811,
      "Uz factor": 0.9950885906,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2526667893,
      "Direction stability factor": 0.6593738208,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4617279756, 0.8846887269, 1.2169327406],
      direction: [-10.8788958032, 11.7275268725, 41.0471288327],
      "speed factor": 0.9952130126,
      "Uz factor": 0.9623476375,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3748271243,
      "Direction stability factor": 0.8557610427,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.373736317, 0.7843218484, 1.0929155162],
      direction: [-28.0418182549, -1.7752010207, 44.4942815593],
      "speed factor": 1.1142395331,
      "Uz factor": 0.968427234,
      "Directional variance factor": 0.8422043537,
      "Velocity stability factor": 0.4118614036,
      "Direction stability factor": 0.7985108338,
      score: 0.7127718563,
    },
    "202.5": {
      velocity: [0.3003946036, 0.839469091, 1.1747629239],
      direction: [-65.9611660105, -24.6954868963, 97.6883505804],
      "speed factor": 0.9835685176,
      "Uz factor": 0.9468337884,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3276963672,
      "Direction stability factor": 0.5454180095,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2514454797, 0.5117602192, 0.8365383105],
      direction: [-67.7500567108, -2.150531972, 34.8639371846],
      "speed factor": 0.949376877,
      "Uz factor": 0.9936950669,
      "Directional variance factor": 0.8088416025,
      "Velocity stability factor": 0.4322564296,
      "Direction stability factor": 0.7149611281,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0849264566, 0.4818540795, 0.983736225],
      direction: [-174.864785232, 17.9343855175, 166.1031623843],
      "speed factor": 0.6561651189,
      "Uz factor": 0.9581899321,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3408671019,
      "Direction stability factor": 0.0528668122,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2621686433, 0.6403589375, 1.3400939513],
      direction: [-57.9801769542, -22.275523173, 77.9299418096],
      "speed factor": 0.8132161072,
      "Uz factor": 0.9050542405,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1318017984,
      "Direction stability factor": 0.6224718923,
      score: 0.0,
    },
    id: "IDN:673.0",
    "45": {
      velocity: [0.091560241, 0.5532917848, 1.0918608976],
      direction: [-64.3551449332, 4.0913168472, 71.4925312504],
      "speed factor": 0.7515813469,
      "Uz factor": 0.9999609029,
      "Directional variance factor": 0.6363273914,
      "Velocity stability factor": 0.1867872189,
      "Direction stability factor": 0.6226453439,
      score: 0.0,
    },
    "90": {
      velocity: [0.2352987438, 0.7817381706, 1.1551150517],
      direction: [-10.6774000065, 19.4964612485, 75.5591594344],
      "speed factor": 1.2672204507,
      "Uz factor": 0.9831280973,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3286651399,
      "Direction stability factor": 0.7604540016,
      score: 0.0,
    },
    "135": {
      velocity: [0.7839145448, 1.1671916841, 1.3227465758],
      direction: [-5.512933397, 2.1443552168, 17.1302649004],
      "speed factor": 0.9878530609,
      "Uz factor": 0.952556717,
      "Directional variance factor": 0.8093906474,
      "Velocity stability factor": 0.5658033249,
      "Direction stability factor": 0.937102227,
      score: 0.0,
    },
    "180": {
      velocity: [0.5228367228, 0.8244525313, 1.2051345522],
      direction: [-43.6680027734, -4.1198897263, 50.0572683546],
      "speed factor": 1.6101507005,
      "Uz factor": 0.9697354466,
      "Directional variance factor": 0.6337875799,
      "Velocity stability factor": 0.4623787307,
      "Direction stability factor": 0.7396520246,
      score: 0.64386759,
    },
    "225": {
      velocity: [0.2830353669, 0.5057001697, 0.8206546201],
      direction: [-89.3249094989, -14.8537505819, 32.4570049972],
      "speed factor": 0.6903360732,
      "Uz factor": 0.9839800035,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5167982396,
      "Direction stability factor": 0.6617169042,
      score: 0.0,
    },
    "270": {
      velocity: [0.3681233196, 0.803001744, 1.301526387],
      direction: [-34.2943435053, 9.114713003, 37.0410097137],
      "speed factor": 0.7347725244,
      "Uz factor": 0.9977714528,
      "Directional variance factor": 0.1898032886,
      "Velocity stability factor": 0.2637286696,
      "Direction stability factor": 0.8018462411,
      score: 0.0,
    },
    "315": {
      velocity: [0.6170442332, 0.9058405592, 1.1474684931],
      direction: [-10.4621583793, 2.5513176043, 29.5495267622],
      "speed factor": 1.1160857164,
      "Uz factor": 0.9655207891,
      "Directional variance factor": 0.773216213,
      "Velocity stability factor": 0.5697007617,
      "Direction stability factor": 0.8888564302,
      score: 0.7801574587,
    },
  },
  {
    position: [-45.8415347, 43.13739529, 46.87999908],
    "overall score": 0.1332183462,
    "0": {
      velocity: [0.2805856726, 0.7969119742, 1.3568127637],
      direction: [-56.9445472196, -10.6824212464, 146.8369632491],
      "speed factor": 0.8166363817,
      "Uz factor": 0.9968547944,
      "Directional variance factor": 0.0504514448,
      "Velocity stability factor": 0.2522042764,
      "Direction stability factor": 0.4339402487,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2000074072, 0.7690631503, 1.1908350209],
      direction: [-149.8422439637, -0.8780519828, 79.2731971411],
      "speed factor": 0.6828324454,
      "Uz factor": 0.9915383574,
      "Directional variance factor": 0.9219509349,
      "Velocity stability factor": 0.3592580889,
      "Direction stability factor": 0.3635682192,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3066751576, 0.6187285081, 0.9798685589],
      direction: [-23.4978891525, 4.6840604282, 39.4862694231],
      "speed factor": 0.9584517066,
      "Uz factor": 0.9537152357,
      "Directional variance factor": 0.583639073,
      "Velocity stability factor": 0.398464989,
      "Direction stability factor": 0.825044004,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5862310136, 0.8630644629, 1.1629507839],
      direction: [-40.8934660366, -18.22816111, 7.0553689553],
      "speed factor": 0.9708872264,
      "Uz factor": 0.8953191452,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5225803994,
      "Direction stability factor": 0.8668087917,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5904670362, 0.9885500369, 1.2951164994],
      direction: [-34.5080297344, -2.0494136791, 16.123122745],
      "speed factor": 1.4043744082,
      "Uz factor": 0.9817156683,
      "Directional variance factor": 0.8178298952,
      "Velocity stability factor": 0.4237436975,
      "Direction stability factor": 0.8593579098,
      score: 0.7400723531,
    },
    "202.5": {
      velocity: [0.1528866503, 0.5895517988, 1.0732373787],
      direction: [-168.2815303063, -9.6504206445, 50.3692620299],
      "speed factor": 0.6907515655,
      "Uz factor": 0.983262866,
      "Directional variance factor": 0.1421848316,
      "Velocity stability factor": 0.2923403973,
      "Direction stability factor": 0.392636688,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2394696384, 0.6105128042, 1.0097338338],
      direction: [-29.257376663, 2.4708443544, 15.1196041333],
      "speed factor": 1.1325748224,
      "Uz factor": 0.9996362208,
      "Directional variance factor": 0.7803693907,
      "Velocity stability factor": 0.2525757941,
      "Direction stability factor": 0.8767306089,
      score: 0.6966016007,
    },
    "292.5": {
      velocity: [0.0282314542, 0.1833412749, 0.5564417883],
      direction: [-178.8035373923, 82.3820996326, 174.2921036561],
      "speed factor": 0.2496651052,
      "Uz factor": 0.9647945211,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6126423849,
      "Direction stability factor": 0.0191787749,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0506500571, 0.4326382884, 1.0641871228],
      direction: [-158.483566468, -0.2524211039, 175.9934792889],
      "speed factor": 0.5494237749,
      "Uz factor": 0.9990378735,
      "Directional variance factor": 0.9775625685,
      "Velocity stability factor": 0.1836623084,
      "Direction stability factor": 0.0708970951,
      score: 0.0,
    },
    id: "IDN:479.0",
    "45": {
      velocity: [0.1330581291, 0.5368558318, 1.021558894],
      direction: [-150.8687612316, 4.6282692133, 117.4200911194],
      "speed factor": 0.7292550518,
      "Uz factor": 0.9760511814,
      "Directional variance factor": 0.5885982921,
      "Velocity stability factor": 0.2776769931,
      "Direction stability factor": 0.2547531879,
      score: 0.0,
    },
    "90": {
      velocity: [0.6290241874, 1.0070705766, 1.25033764],
      direction: [-53.4990964313, -6.6079735078, 30.0256364426],
      "speed factor": 1.6324908747,
      "Uz factor": 0.9299228257,
      "Directional variance factor": 0.4126245771,
      "Velocity stability factor": 0.5465296971,
      "Direction stability factor": 0.7679868531,
      score: 0.0,
    },
    "135": {
      velocity: [0.3307403638, 0.683234592, 1.0490104678],
      direction: [-113.0165329622, -32.5733024452, 29.2134109423],
      "speed factor": 0.5782558189,
      "Uz factor": 0.9307272917,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.421210186,
      "Direction stability factor": 0.6049168225,
      score: 0.0,
    },
    "180": {
      velocity: [0.2482530869, 0.8411148535, 1.1871040643],
      direction: [-28.1785464778, -2.1311909389, 24.2929616324],
      "speed factor": 1.6426921127,
      "Uz factor": 0.9921291248,
      "Directional variance factor": 0.8105608054,
      "Velocity stability factor": 0.2602259125,
      "Direction stability factor": 0.8542458108,
      score: 0.6948195849,
    },
    "225": {
      velocity: [0.0747753188, 0.4244249284, 0.8639421049],
      direction: [-106.3407151817, 0.3621618298, 72.5412472111],
      "speed factor": 0.5793864744,
      "Uz factor": 0.9999750089,
      "Directional variance factor": 0.9678078374,
      "Velocity stability factor": 0.2907121947,
      "Direction stability factor": 0.50310566,
      score: 0.0,
    },
    "270": {
      velocity: [0.0330839712, 0.3993961733, 0.9744599652],
      direction: [-155.9870233312, 24.9752164517, 164.2727948938],
      "speed factor": 0.3654603949,
      "Uz factor": 0.9982270129,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2574395995,
      "Direction stability factor": 0.1103893938,
      score: 0.0,
    },
    "315": {
      velocity: [0.0846330261, 0.375934214, 1.0964198945],
      direction: [-19.0271356346, 14.6514232776, 133.0432782627],
      "speed factor": 0.4631883638,
      "Uz factor": 0.9973147215,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1792020997,
      "Direction stability factor": 0.5775821836,
      score: 0.0,
    },
  },
  {
    position: [-35.63762623, 90.14194684, 47.87999908],
    "overall score": 0.1326153965,
    "0": {
      velocity: [0.2097119118, 0.7042109618, 1.1281604049],
      direction: [-166.6197487008, -21.9585101133, 34.6890861067],
      "speed factor": 0.721640922,
      "Uz factor": 0.9949995866,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3618337049,
      "Direction stability factor": 0.4408087922,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7496406761, 1.2713366384, 1.5131847844],
      direction: [-27.1567008762, -0.1741377081, 12.5567009616],
      "speed factor": 1.1287888457,
      "Uz factor": 0.993085541,
      "Directional variance factor": 0.9845210926,
      "Velocity stability factor": 0.5062362974,
      "Direction stability factor": 0.8896849949,
      score: 0.817531845,
    },
    "67.5": {
      velocity: [0.3073068257, 0.6241070444, 0.9673124991],
      direction: [-38.0248843259, -8.9342971062, 47.4904335529],
      "speed factor": 0.966783418,
      "Uz factor": 0.9909901786,
      "Directional variance factor": 0.2058402572,
      "Velocity stability factor": 0.4102489429,
      "Direction stability factor": 0.7624574503,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1776382112, 0.7572143984, 1.1454117893],
      direction: [-6.7280907271, 23.2470044914, 94.5716936493],
      "speed factor": 0.8518132985,
      "Uz factor": 0.9535653637,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1988586157,
      "Direction stability factor": 0.7186117101,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4243139041, 0.8201652788, 1.1327142462],
      direction: [-29.2873178753, 10.0525856619, 60.8941799773],
      "speed factor": 1.1651601689,
      "Uz factor": 0.9597490839,
      "Directional variance factor": 0.10643683,
      "Velocity stability factor": 0.4206762608,
      "Direction stability factor": 0.7494958393,
      score: 0.5065261924,
    },
    "202.5": {
      velocity: [0.3598178628, 0.6277429071, 1.0079061982],
      direction: [-39.521406189, -13.2006548661, 27.5404200287],
      "speed factor": 0.7354983849,
      "Uz factor": 0.8215812251,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5016835215,
      "Direction stability factor": 0.8137171494,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2005507808, 0.443352669, 0.6967758873],
      direction: [-103.3479422953, -10.7870282973, 41.3746689246],
      "speed factor": 0.8224726278,
      "Uz factor": 0.9945192096,
      "Directional variance factor": 0.0411530402,
      "Velocity stability factor": 0.5184890348,
      "Direction stability factor": 0.5979927466,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1669308032, 0.6005447792, 1.0120645014],
      direction: [-158.4041133408, -9.9675606594, 66.6903685856],
      "speed factor": 0.8177922596,
      "Uz factor": 0.9926266495,
      "Directional variance factor": 0.1139946081,
      "Velocity stability factor": 0.3802298959,
      "Direction stability factor": 0.3747375502,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0539249021, 0.3293232589, 0.9556385164],
      direction: [-143.598757632, -18.2895926744, 174.5203079242],
      "speed factor": 0.4182200996,
      "Uz factor": 0.9710283597,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2737287709,
      "Direction stability factor": 0.116335929,
      score: 0.0,
    },
    id: "IDN:724.0",
    "45": {
      velocity: [0.223326191, 0.5982022595, 0.8521525042],
      direction: [-51.7152613348, -8.2724809421, 53.0889363269],
      "speed factor": 0.8125869067,
      "Uz factor": 0.98748498,
      "Directional variance factor": 0.2646683607,
      "Velocity stability factor": 0.4887841054,
      "Direction stability factor": 0.7088772287,
      score: 0.0,
    },
    "90": {
      velocity: [0.2965784213, 0.758791197, 1.1501681326],
      direction: [-43.2875815345, 21.8587201636, 84.5644598979],
      "speed factor": 1.230022735,
      "Uz factor": 0.9781142356,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3770011202,
      "Direction stability factor": 0.6448554405,
      score: 0.0,
    },
    "135": {
      velocity: [0.6025774337, 1.025911267, 1.269370924],
      direction: [-22.4130683552, 5.3203098382, 28.8915722433],
      "speed factor": 0.8682803341,
      "Uz factor": 0.968040502,
      "Directional variance factor": 0.5270835699,
      "Velocity stability factor": 0.4626905978,
      "Direction stability factor": 0.8574871094,
      score: 0.0,
    },
    "180": {
      velocity: [0.6095091072, 0.9097740418, 1.1456142518],
      direction: [-21.8119800478, -1.6133287855, 21.9301860181],
      "speed factor": 1.7767830834,
      "Uz factor": 0.9528951739,
      "Directional variance factor": 0.8565929968,
      "Velocity stability factor": 0.5775722626,
      "Direction stability factor": 0.8784939832,
      score: 0.7977883064,
    },
    "225": {
      velocity: [0.2565186911, 0.6667002694, 1.0981935874],
      direction: [-25.9659474452, 0.8289000635, 17.1594808517],
      "speed factor": 0.9101188284,
      "Uz factor": 0.9945592661,
      "Directional variance factor": 0.9263199944,
      "Velocity stability factor": 0.2435189234,
      "Direction stability factor": 0.8802071436,
      score: 0.0,
    },
    "270": {
      velocity: [0.1056466168, 0.3784301312, 1.0326888348],
      direction: [-112.3938185799, -42.1153729326, 26.9950594018],
      "speed factor": 0.3462757894,
      "Uz factor": 0.9926285022,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2687461279,
      "Direction stability factor": 0.6128086723,
      score: 0.0,
    },
    "315": {
      velocity: [0.0367109165, 0.3776467751, 0.8938945594],
      direction: [-170.2252488284, 2.6999609387, 47.2607805138],
      "speed factor": 0.4652984096,
      "Uz factor": 0.9873528668,
      "Directional variance factor": 0.7600034721,
      "Velocity stability factor": 0.3046217971,
      "Direction stability factor": 0.3958721407,
      score: 0.0,
    },
  },
  {
    position: [-50.56177969, 32.75380857, 45.87999908],
    "overall score": 0.1319415618,
    "0": {
      velocity: [0.0958338501, 0.6659631686, 1.2066267109],
      direction: [-49.8357203128, -10.0014430893, 101.5092311092],
      "speed factor": 0.6824464558,
      "Uz factor": 0.9987347605,
      "Directional variance factor": 0.1109828365,
      "Velocity stability factor": 0.2281869153,
      "Direction stability factor": 0.5795973572,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1181192536, 0.4696192137, 1.1308430273],
      direction: [-149.3741689855, -17.4437471494, 176.6747136279],
      "speed factor": 0.4169634652,
      "Uz factor": 0.9568022336,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3450984236,
      "Direction stability factor": 0.0943086594,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.280987803, 0.6692300377, 0.959778978],
      direction: [-30.5897423579, -5.6116912655, 22.2663939901],
      "speed factor": 1.0366819427,
      "Uz factor": 0.9556843181,
      "Directional variance factor": 0.5011829986,
      "Velocity stability factor": 0.393463073,
      "Direction stability factor": 0.853177399,
      score: 0.6502502174,
    },
    "112.5": {
      velocity: [0.5106532013, 0.8746047878, 1.291911654],
      direction: [-57.7435323153, -36.8930355223, -11.5947476924],
      "speed factor": 0.9838692857,
      "Uz factor": 0.935469806,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3532593858,
      "Direction stability factor": 0.8718089316,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5259918647, 0.9124165807, 1.1974258167],
      direction: [-52.5204768373, -12.0556546532, 15.4370937618],
      "speed factor": 1.2962161223,
      "Uz factor": 0.957838509,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4509070584,
      "Direction stability factor": 0.8112289706,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5327011438, 0.9276302858, 1.182596957],
      direction: [-24.4965448923, 4.3062522729, 15.6736405635],
      "speed factor": 1.0868630601,
      "Uz factor": 0.9453252451,
      "Directional variance factor": 0.6172220202,
      "Velocity stability factor": 0.5002937481,
      "Direction stability factor": 0.8884161515,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4621872053, 0.7795489711, 1.0238344911],
      direction: [-22.3213467283, 2.8612912636, 17.9996543798],
      "speed factor": 1.446157282,
      "Uz factor": 0.9988614362,
      "Directional variance factor": 0.7456629988,
      "Velocity stability factor": 0.4550067638,
      "Direction stability factor": 0.8879972191,
      score: 0.7441660502,
    },
    "292.5": {
      velocity: [0.0623079465, 0.1584679461, 0.5646120217],
      direction: [-176.807113907, 90.672697043, 179.0488193684],
      "speed factor": 0.2157938328,
      "Uz factor": 0.9234943613,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6316404734,
      "Direction stability factor": 0.0115112965,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0713373421, 0.3095432853, 0.741313363],
      direction: [-159.5693838259, 11.1845831469, 166.665752523],
      "speed factor": 0.3931007607,
      "Uz factor": 0.9987644145,
      "Directional variance factor": 0.0058148314,
      "Velocity stability factor": 0.4603782172,
      "Direction stability factor": 0.0937912879,
      score: 0.0,
    },
    id: "IDN:395.0",
    "45": {
      velocity: [0.1071502022, 0.4542985553, 0.9376143101],
      direction: [-70.8547375952, 10.4691079662, 168.1546455278],
      "speed factor": 0.6171107714,
      "Uz factor": 0.9458381765,
      "Directional variance factor": 0.0694126252,
      "Velocity stability factor": 0.3248589587,
      "Direction stability factor": 0.3360850469,
      score: 0.0,
    },
    "90": {
      velocity: [0.6032244892, 1.0450610119, 1.389242042],
      direction: [-37.2111745932, -22.9327675271, -1.156930591],
      "speed factor": 1.6940744821,
      "Uz factor": 0.9158875017,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4263191691,
      "Direction stability factor": 0.8998493222,
      score: 0.0,
    },
    "135": {
      velocity: [0.1236042483, 0.6217633086, 0.9708058794],
      direction: [-95.7581106576, -51.7347121951, 25.0462826088],
      "speed factor": 0.5262295782,
      "Uz factor": 0.9565544191,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3173157678,
      "Direction stability factor": 0.6644322409,
      score: 0.0,
    },
    "180": {
      velocity: [0.5231705556, 0.8156528639, 1.0827990222],
      direction: [-50.7055316748, -3.084264701, 24.585796301],
      "speed factor": 1.5929650045,
      "Uz factor": 0.9581778719,
      "Directional variance factor": 0.7258431377,
      "Velocity stability factor": 0.5590368992,
      "Direction stability factor": 0.7908574223,
      score: 0.7166487204,
    },
    "225": {
      velocity: [0.1400249056, 0.5198437895, 1.0155115482],
      direction: [-28.6138654533, 5.8648367134, 86.2935523043],
      "speed factor": 0.709643662,
      "Uz factor": 0.9836195836,
      "Directional variance factor": 0.478681181,
      "Velocity stability factor": 0.2131295815,
      "Direction stability factor": 0.6808127285,
      score: 0.0,
    },
    "270": {
      velocity: [0.038893717, 0.2783555755, 1.059803695],
      direction: [-175.6055457608, 43.5868561006, 146.2282141229],
      "speed factor": 0.2547043395,
      "Uz factor": 0.9830143993,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1947029382,
      "Direction stability factor": 0.1060173337,
      score: 0.0,
    },
    "315": {
      velocity: [0.0490043518, 0.3008467845, 0.6540853667],
      direction: [-163.2096022567, 69.5322712233, 145.1945055007],
      "speed factor": 0.3706731782,
      "Uz factor": 0.9619542696,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5091365168,
      "Direction stability factor": 0.1433219229,
      score: 0.0,
    },
  },
  {
    position: [-35.50511975, 90.5113871, 47.87999908],
    "overall score": 0.1314432918,
    "0": {
      velocity: [0.1692824344, 0.7328566224, 1.1419494149],
      direction: [-137.2296510081, -21.8270251578, 34.1804226591],
      "speed factor": 0.7509955927,
      "Uz factor": 0.9939959209,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3241610302,
      "Direction stability factor": 0.5238609065,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7859755364, 1.2726194276, 1.5053183062],
      direction: [-26.3607712934, -0.291750892, 12.3921195676],
      "speed factor": 1.129927803,
      "Uz factor": 0.9925199035,
      "Directional variance factor": 0.9740665874,
      "Velocity stability factor": 0.5348201295,
      "Direction stability factor": 0.8923530809,
      score: 0.8233982197,
    },
    "67.5": {
      velocity: [0.2868592095, 0.6232746634, 0.9629265035],
      direction: [-37.6290815415, -8.7935405002, 48.249455993],
      "speed factor": 0.9654940043,
      "Uz factor": 0.9901781764,
      "Directional variance factor": 0.2183519555,
      "Velocity stability factor": 0.3958970091,
      "Direction stability factor": 0.7614485068,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1683380781, 0.7633117158, 1.1513045947],
      direction: [-6.9707655211, 24.1361677966, 91.8940189649],
      "speed factor": 0.8586723547,
      "Uz factor": 0.9577964989,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.186281612,
      "Direction stability factor": 0.7253755986,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4371983753, 0.8274162804, 1.2207935961],
      direction: [-28.9316220824, 10.7876797235, 60.6020616893],
      "speed factor": 1.1754612369,
      "Uz factor": 0.961876667,
      "Directional variance factor": 0.0410951357,
      "Velocity stability factor": 0.3591825323,
      "Direction stability factor": 0.7512953229,
      score: 0.4757170784,
    },
    "202.5": {
      velocity: [0.3737441587, 0.6315654616, 1.0091209915],
      direction: [-41.5649145305, -12.6665498979, 35.7692640892],
      "speed factor": 0.7399771015,
      "Uz factor": 0.8149803807,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5114574225,
      "Direction stability factor": 0.7851828372,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1928498063, 0.4400136772, 0.6958578078],
      direction: [-103.4336093741, -10.9533033493, 39.7747161168],
      "speed factor": 0.8162783956,
      "Uz factor": 0.9950410989,
      "Directional variance factor": 0.0263730356,
      "Velocity stability factor": 0.5119072672,
      "Direction stability factor": 0.6021990959,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.07441279, 0.6143687639, 1.0237469319],
      direction: [-174.2804621668, -9.242982021, 52.0538611361],
      "speed factor": 0.83661708,
      "Uz factor": 0.9915963424,
      "Directional variance factor": 0.1784015981,
      "Velocity stability factor": 0.3038155724,
      "Direction stability factor": 0.3712935464,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0709266055, 0.384359876, 1.0275075127],
      direction: [-140.0184806551, -15.7343297355, 177.201535387],
      "speed factor": 0.4881131875,
      "Uz factor": 0.9736199093,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2295367618,
      "Direction stability factor": 0.1188332888,
      score: 0.0,
    },
    id: "IDN:725.0",
    "45": {
      velocity: [0.2369599089, 0.6004614672, 0.8454014759],
      direction: [-52.3636186966, -8.4169911972, 52.9718801035],
      "speed factor": 0.8156557727,
      "Uz factor": 0.9866792791,
      "Directional variance factor": 0.2518230047,
      "Velocity stability factor": 0.505356259,
      "Direction stability factor": 0.7074013922,
      score: 0.0,
    },
    "90": {
      velocity: [0.2955603815, 0.7562553581, 1.1422026165],
      direction: [-42.2918521154, 22.2864815451, 79.1818322066],
      "speed factor": 1.225912066,
      "Uz factor": 0.9797037032,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3820717882,
      "Direction stability factor": 0.6625730991,
      score: 0.0,
    },
    "135": {
      velocity: [0.6105270733, 1.0337178256, 1.2727520787],
      direction: [-21.3382014634, 5.5849822524, 30.2536999877],
      "speed factor": 0.8748874175,
      "Uz factor": 0.9699245713,
      "Directional variance factor": 0.5035571331,
      "Velocity stability factor": 0.4663719323,
      "Direction stability factor": 0.8566891626,
      score: 0.0,
    },
    "180": {
      velocity: [0.6163982439, 0.9160744127, 1.1492168405],
      direction: [-21.8945086933, -1.3402308845, 22.2272359911],
      "speed factor": 1.7890876689,
      "Uz factor": 0.955547659,
      "Directional variance factor": 0.8808683658,
      "Velocity stability factor": 0.5801619207,
      "Direction stability factor": 0.8774395981,
      score: 0.8039773707,
    },
    "225": {
      velocity: [0.2143296526, 0.6675201362, 1.123150001],
      direction: [-30.6261717857, 0.3709929558, 20.5786480227],
      "speed factor": 0.9112380364,
      "Uz factor": 0.9937443619,
      "Directional variance factor": 0.9670228484,
      "Velocity stability factor": 0.1831698929,
      "Direction stability factor": 0.8577643894,
      score: 0.0,
    },
    "270": {
      velocity: [0.112318168, 0.4005514792, 1.0179137516],
      direction: [-121.8049450497, -38.9278519306, 23.1815612292],
      "speed factor": 0.3665175371,
      "Uz factor": 0.998183556,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2856633019,
      "Direction stability factor": 0.5972597048,
      score: 0.0,
    },
    "315": {
      velocity: [0.0266039958, 0.3992506351, 0.8814061607],
      direction: [-162.7327503649, 3.5188484916, 49.5915994268],
      "speed factor": 0.4919165151,
      "Uz factor": 0.9855015973,
      "Directional variance factor": 0.6872134674,
      "Velocity stability factor": 0.3065537377,
      "Direction stability factor": 0.4102101395,
      score: 0.0,
    },
  },
  {
    position: [-35.50511975, 90.5113871, 47.87999908],
    "overall score": 0.1314432918,
    "0": {
      velocity: [0.1692824344, 0.7328566224, 1.1419494149],
      direction: [-137.2296510081, -21.8270251578, 34.1804226591],
      "speed factor": 0.7509955927,
      "Uz factor": 0.9939959209,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3241610302,
      "Direction stability factor": 0.5238609065,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7859755364, 1.2726194276, 1.5053183062],
      direction: [-26.3607712934, -0.291750892, 12.3921195676],
      "speed factor": 1.129927803,
      "Uz factor": 0.9925199035,
      "Directional variance factor": 0.9740665874,
      "Velocity stability factor": 0.5348201295,
      "Direction stability factor": 0.8923530809,
      score: 0.8233982197,
    },
    "67.5": {
      velocity: [0.2868592095, 0.6232746634, 0.9629265035],
      direction: [-37.6290815415, -8.7935405002, 48.249455993],
      "speed factor": 0.9654940043,
      "Uz factor": 0.9901781764,
      "Directional variance factor": 0.2183519555,
      "Velocity stability factor": 0.3958970091,
      "Direction stability factor": 0.7614485068,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1683380781, 0.7633117158, 1.1513045947],
      direction: [-6.9707655211, 24.1361677966, 91.8940189649],
      "speed factor": 0.8586723547,
      "Uz factor": 0.9577964989,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.186281612,
      "Direction stability factor": 0.7253755986,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4371983753, 0.8274162804, 1.2207935961],
      direction: [-28.9316220824, 10.7876797235, 60.6020616893],
      "speed factor": 1.1754612369,
      "Uz factor": 0.961876667,
      "Directional variance factor": 0.0410951357,
      "Velocity stability factor": 0.3591825323,
      "Direction stability factor": 0.7512953229,
      score: 0.4757170784,
    },
    "202.5": {
      velocity: [0.3737441587, 0.6315654616, 1.0091209915],
      direction: [-41.5649145305, -12.6665498979, 35.7692640892],
      "speed factor": 0.7399771015,
      "Uz factor": 0.8149803807,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5114574225,
      "Direction stability factor": 0.7851828372,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1928498063, 0.4400136772, 0.6958578078],
      direction: [-103.4336093741, -10.9533033493, 39.7747161168],
      "speed factor": 0.8162783956,
      "Uz factor": 0.9950410989,
      "Directional variance factor": 0.0263730356,
      "Velocity stability factor": 0.5119072672,
      "Direction stability factor": 0.6021990959,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.07441279, 0.6143687639, 1.0237469319],
      direction: [-174.2804621668, -9.242982021, 52.0538611361],
      "speed factor": 0.83661708,
      "Uz factor": 0.9915963424,
      "Directional variance factor": 0.1784015981,
      "Velocity stability factor": 0.3038155724,
      "Direction stability factor": 0.3712935464,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0709266055, 0.384359876, 1.0275075127],
      direction: [-140.0184806551, -15.7343297355, 177.201535387],
      "speed factor": 0.4881131875,
      "Uz factor": 0.9736199093,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2295367618,
      "Direction stability factor": 0.1188332888,
      score: 0.0,
    },
    id: "IDN:684.0",
    "45": {
      velocity: [0.2369599089, 0.6004614672, 0.8454014759],
      direction: [-52.3636186966, -8.4169911972, 52.9718801035],
      "speed factor": 0.8156557727,
      "Uz factor": 0.9866792791,
      "Directional variance factor": 0.2518230047,
      "Velocity stability factor": 0.505356259,
      "Direction stability factor": 0.7074013922,
      score: 0.0,
    },
    "90": {
      velocity: [0.2955603815, 0.7562553581, 1.1422026165],
      direction: [-42.2918521154, 22.2864815451, 79.1818322066],
      "speed factor": 1.225912066,
      "Uz factor": 0.9797037032,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3820717882,
      "Direction stability factor": 0.6625730991,
      score: 0.0,
    },
    "135": {
      velocity: [0.6105270733, 1.0337178256, 1.2727520787],
      direction: [-21.3382014634, 5.5849822524, 30.2536999877],
      "speed factor": 0.8748874175,
      "Uz factor": 0.9699245713,
      "Directional variance factor": 0.5035571331,
      "Velocity stability factor": 0.4663719323,
      "Direction stability factor": 0.8566891626,
      score: 0.0,
    },
    "180": {
      velocity: [0.6163982439, 0.9160744127, 1.1492168405],
      direction: [-21.8945086933, -1.3402308845, 22.2272359911],
      "speed factor": 1.7890876689,
      "Uz factor": 0.955547659,
      "Directional variance factor": 0.8808683658,
      "Velocity stability factor": 0.5801619207,
      "Direction stability factor": 0.8774395981,
      score: 0.8039773707,
    },
    "225": {
      velocity: [0.2143296526, 0.6675201362, 1.123150001],
      direction: [-30.6261717857, 0.3709929558, 20.5786480227],
      "speed factor": 0.9112380364,
      "Uz factor": 0.9937443619,
      "Directional variance factor": 0.9670228484,
      "Velocity stability factor": 0.1831698929,
      "Direction stability factor": 0.8577643894,
      score: 0.0,
    },
    "270": {
      velocity: [0.112318168, 0.4005514792, 1.0179137516],
      direction: [-121.8049450497, -38.9278519306, 23.1815612292],
      "speed factor": 0.3665175371,
      "Uz factor": 0.998183556,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2856633019,
      "Direction stability factor": 0.5972597048,
      score: 0.0,
    },
    "315": {
      velocity: [0.0266039958, 0.3992506351, 0.8814061607],
      direction: [-162.7327503649, 3.5188484916, 49.5915994268],
      "speed factor": 0.4919165151,
      "Uz factor": 0.9855015973,
      "Directional variance factor": 0.6872134674,
      "Velocity stability factor": 0.3065537377,
      "Direction stability factor": 0.4102101395,
      score: 0.0,
    },
  },
  {
    position: [12.4859298, 93.58910899, 46.87999908],
    "overall score": 0.1292531674,
    "0": {
      velocity: [0.1028768298, 0.4237105684, 1.080424586],
      direction: [-163.9023908987, -42.4993912975, 165.1618238366],
      "speed factor": 0.4341978495,
      "Uz factor": 0.9989657166,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.320769717,
      "Direction stability factor": 0.0859327368,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2164848497, 0.9196112793, 1.4022734212],
      direction: [-127.7521986314, -16.9015627707, 2.6094329743],
      "speed factor": 0.8165004635,
      "Uz factor": 0.9998176585,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2331820137,
      "Direction stability factor": 0.6378843567,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1430607934, 0.4387338392, 0.8050263428],
      direction: [-47.1425827062, -8.7114897826, 68.6708339761],
      "speed factor": 0.6796279652,
      "Uz factor": 0.9837049977,
      "Directional variance factor": 0.2256453527,
      "Velocity stability factor": 0.4084976869,
      "Direction stability factor": 0.6782960648,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5604428736, 0.9274297894, 1.1579657522],
      direction: [-44.6464613315, 1.4460837449, 48.040991575],
      "speed factor": 1.043293722,
      "Uz factor": 0.9715650581,
      "Directional variance factor": 0.8714592227,
      "Velocity stability factor": 0.5053591904,
      "Direction stability factor": 0.742534853,
      score: 0.7154720298,
    },
    "157.5": {
      velocity: [0.5269104345, 0.9026541658, 1.1409405306],
      direction: [-13.184028914, 17.0409883218, 69.9246675209],
      "speed factor": 1.2823472384,
      "Uz factor": 0.9795809909,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4978514407,
      "Direction stability factor": 0.7691425099,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4115493925, 0.8557484085, 1.1577602444],
      direction: [-146.6187663285, -1.9286118413, 27.9751216423],
      "speed factor": 1.0026422683,
      "Uz factor": 0.9434414324,
      "Directional variance factor": 0.8285678363,
      "Velocity stability factor": 0.4262369131,
      "Direction stability factor": 0.5150169779,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1757926797, 0.5454741829, 0.8766592547],
      direction: [-48.1481583945, -1.254096465, 114.8439759188],
      "speed factor": 1.0119203423,
      "Uz factor": 0.9853319145,
      "Directional variance factor": 0.8885247587,
      "Velocity stability factor": 0.3199156258,
      "Direction stability factor": 0.5472440714,
      score: 0.5757321318,
    },
    "292.5": {
      velocity: [0.0374723208, 0.3226127695, 0.8910504376],
      direction: [-129.2896145545, -1.8401645325, 173.8098203589],
      "speed factor": 0.4393181572,
      "Uz factor": 0.9998512051,
      "Directional variance factor": 0.8364298193,
      "Velocity stability factor": 0.3740372683,
      "Direction stability factor": 0.1580571252,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1353392648, 0.5705930265, 1.0213942181],
      direction: [-90.3640882875, 4.522757025, 42.72785488],
      "speed factor": 0.7246177302,
      "Uz factor": 0.9988118976,
      "Directional variance factor": 0.5979771533,
      "Velocity stability factor": 0.2863407962,
      "Direction stability factor": 0.6303001579,
      score: 0.0,
    },
    id: "IDN:413.0",
    "45": {
      velocity: [0.2798071998, 0.7066487753, 1.0868075359],
      direction: [-68.9210572502, -7.1741630488, 29.4188453311],
      "speed factor": 0.9598986518,
      "Uz factor": 0.9944385886,
      "Directional variance factor": 0.3622966179,
      "Velocity stability factor": 0.3439342628,
      "Direction stability factor": 0.7268336039,
      score: 0.0,
    },
    "90": {
      velocity: [0.5404070901, 0.8518700861, 1.0820304809],
      direction: [-18.2765100448, 2.9161735201, 24.5797971339],
      "speed factor": 1.3809063379,
      "Uz factor": 0.9750682957,
      "Directional variance factor": 0.740784576,
      "Velocity stability factor": 0.604692089,
      "Direction stability factor": 0.8809547023,
      score: 0.7768465174,
    },
    "135": {
      velocity: [0.7017279259, 1.1283119518, 1.2742631235],
      direction: [-11.9215677902, 9.4111605617, 27.1717214219],
      "speed factor": 0.9549471869,
      "Uz factor": 0.9679838848,
      "Directional variance factor": 0.1634523945,
      "Velocity stability factor": 0.538644949,
      "Direction stability factor": 0.89140753,
      score: 0.0,
    },
    "180": {
      velocity: [0.411554092, 0.7698681442, 1.0450265534],
      direction: [-24.8619871542, 15.0749818284, 53.4704635493],
      "speed factor": 1.5035477296,
      "Uz factor": 0.9604747218,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5008510155,
      "Direction stability factor": 0.7824098592,
      score: 0.0,
    },
    "225": {
      velocity: [0.1894074691, 0.5016561296, 0.7935525277],
      direction: [-44.7342024979, -3.2445973532, 57.96878582],
      "speed factor": 0.6848155158,
      "Uz factor": 0.9866048412,
      "Directional variance factor": 0.7115913464,
      "Velocity stability factor": 0.4570061358,
      "Direction stability factor": 0.7147139213,
      score: 0.0,
    },
    "270": {
      velocity: [0.2310455554, 0.7238973881, 1.2126214925],
      direction: [-62.4617838537, -4.2446286265, 58.1850740595],
      "speed factor": 0.6623894845,
      "Uz factor": 0.9996312042,
      "Directional variance factor": 0.6226996776,
      "Velocity stability factor": 0.2257297556,
      "Direction stability factor": 0.6648698391,
      score: 0.0,
    },
    "315": {
      velocity: [0.0528028698, 0.3779335298, 0.9592423181],
      direction: [-161.9886809669, -34.280143562, 34.317099915],
      "speed factor": 0.4656517199,
      "Uz factor": 0.9975333675,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2646637162,
      "Direction stability factor": 0.4547061642,
      score: 0.0,
    },
  },
  {
    position: [12.49640175, 96.58909071, 47.87999908],
    "overall score": 0.1280988011,
    "0": {
      velocity: [0.1415025206, 0.8687928558, 1.5807018329],
      direction: [-109.3765908152, -18.3983925301, 5.2115097142],
      "speed factor": 0.8902963905,
      "Uz factor": 0.9902078034,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.6816997208,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3935395002, 1.0371674651, 1.3895871509],
      direction: [-62.7087089174, -19.0210331446, -0.5033211471],
      "speed factor": 0.9208757385,
      "Uz factor": 0.998663968,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3558824296,
      "Direction stability factor": 0.8272072562,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2085845014, 0.4565360412, 0.7872067521],
      direction: [-52.5417750898, -8.536284374, 59.7940192772],
      "speed factor": 0.7072047628,
      "Uz factor": 0.9867876881,
      "Directional variance factor": 0.2412191668,
      "Velocity stability factor": 0.4829694687,
      "Direction stability factor": 0.6879561268,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5850312187, 1.0123216732, 1.2199853747],
      direction: [-35.79798948, 3.5057460557, 35.497245049],
      "speed factor": 1.1387911607,
      "Uz factor": 0.976974363,
      "Directional variance factor": 0.6883781284,
      "Velocity stability factor": 0.4743728668,
      "Direction stability factor": 0.8019576819,
      score: 0.6916665897,
    },
    "157.5": {
      velocity: [0.549380419, 0.9789666387, 1.2183288737],
      direction: [-13.0126687727, 15.25848724, 60.01902827],
      "speed factor": 1.3907598427,
      "Uz factor": 0.99392005,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4529396768,
      "Direction stability factor": 0.7971341749,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2900066763, 0.8448972146, 1.2047377875],
      direction: [-90.6912654451, -5.9469618248, 71.6588504204],
      "speed factor": 0.9899284081,
      "Uz factor": 0.9708118308,
      "Directional variance factor": 0.4713811711,
      "Velocity stability factor": 0.2966613327,
      "Direction stability factor": 0.5490274559,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1500954902, 0.5257109084, 0.9563258567],
      direction: [-68.4730607556, -6.1543203999, 123.7745897867],
      "speed factor": 0.9752570867,
      "Uz factor": 0.9965526114,
      "Directional variance factor": 0.4529492978,
      "Velocity stability factor": 0.2176760973,
      "Direction stability factor": 0.4659787485,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.059090962, 0.3583263624, 0.8496522834],
      direction: [-175.1315345693, 11.003741341, 162.9118677169],
      "speed factor": 0.4879511666,
      "Uz factor": 0.9967635404,
      "Directional variance factor": 0.0218896586,
      "Velocity stability factor": 0.420249987,
      "Direction stability factor": 0.0609905492,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1482266205, 0.6790984605, 1.0616211882],
      direction: [-62.2998772729, 6.0885596105, 40.2226579324],
      "speed factor": 0.862412897,
      "Uz factor": 0.9998002214,
      "Directional variance factor": 0.4587947013,
      "Velocity stability factor": 0.2643205284,
      "Direction stability factor": 0.71521518,
      score: 0.0,
    },
    id: "IDN:680.0",
    "45": {
      velocity: [0.3770785691, 0.7263066153, 1.0725687863],
      direction: [-60.0362050443, -6.1344163192, 23.4663917522],
      "speed factor": 0.9866014988,
      "Uz factor": 0.9963968819,
      "Directional variance factor": 0.4547185494,
      "Velocity stability factor": 0.4345884609,
      "Direction stability factor": 0.7680483422,
      score: 0.0,
    },
    "90": {
      velocity: [0.5523540839, 0.8360007067, 1.0602534411],
      direction: [-15.7357878046, 6.2902739928, 24.779427894],
      "speed factor": 1.3551816096,
      "Uz factor": 0.9708945101,
      "Directional variance factor": 0.440864534,
      "Velocity stability factor": 0.6293058288,
      "Direction stability factor": 0.8874577342,
      score: 0.7112714578,
    },
    "135": {
      velocity: [0.814633401, 1.2066910802, 1.3506345845],
      direction: [-7.0478433318, 9.0264821172, 25.200849461],
      "speed factor": 1.0212833877,
      "Uz factor": 0.9833066379,
      "Directional variance factor": 0.197646034,
      "Velocity stability factor": 0.5680844524,
      "Direction stability factor": 0.9104202978,
      score: 0.6466427705,
    },
    "180": {
      velocity: [0.4361627496, 0.8243232734, 1.0973261104],
      direction: [-24.6773425027, 12.4562038759, 45.0374309638],
      "speed factor": 1.6098982606,
      "Uz factor": 0.9794436641,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4790317807,
      "Direction stability factor": 0.8063478515,
      score: 0.0,
    },
    "225": {
      velocity: [0.1074949729, 0.4994687828, 0.8726364862],
      direction: [-129.7182023024, -8.6793301106, 71.6073667628],
      "speed factor": 0.6818295481,
      "Uz factor": 0.9925145433,
      "Directional variance factor": 0.2285039902,
      "Velocity stability factor": 0.312305644,
      "Direction stability factor": 0.4407623082,
      score: 0.0,
    },
    "270": {
      velocity: [0.3505797587, 0.9015643128, 1.4145356289],
      direction: [-53.9303989468, -0.3190184201, 32.7342953483],
      "speed factor": 0.8249604573,
      "Uz factor": 0.9981272544,
      "Directional variance factor": 0.9716428071,
      "Velocity stability factor": 0.1607482004,
      "Direction stability factor": 0.7592647381,
      score: 0.0,
    },
    "315": {
      velocity: [0.1304029745, 0.5643134491, 1.0829685619],
      direction: [-81.5561111562, -14.2268783296, 36.631863992],
      "speed factor": 0.6952903287,
      "Uz factor": 0.9979604696,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2272445329,
      "Direction stability factor": 0.671700069,
      score: 0.0,
    },
  },
  {
    position: [-47.07558315, 49.14173951, 46.87999908],
    "overall score": 0.127989956,
    "0": {
      velocity: [0.2437483555, 0.8186704491, 1.2914802773],
      direction: [-74.0267644216, -16.2639087795, 85.0085880357],
      "speed factor": 0.8389334017,
      "Uz factor": 0.9989322884,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2720035975,
      "Direction stability factor": 0.5582351321,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1417772075, 0.7292897463, 1.3086197379],
      direction: [-95.9285346051, 0.4087619894, 79.6459015728],
      "speed factor": 0.6475186084,
      "Uz factor": 0.9976223303,
      "Directional variance factor": 0.9636656009,
      "Velocity stability factor": 0.2454339155,
      "Direction stability factor": 0.5122932328,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1467859967, 0.5600585876, 0.9536116231],
      direction: [-41.4989939706, 11.4292888542, 71.8291469186],
      "speed factor": 0.8675680886,
      "Uz factor": 0.9598579709,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2790573094,
      "Direction stability factor": 0.6851996086,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5170525424, 0.8958033021, 1.1916582236],
      direction: [-38.8975639385, -10.8682658637, 10.614403683],
      "speed factor": 1.0077161332,
      "Uz factor": 0.9354538224,
      "Directional variance factor": 0.0339319232,
      "Velocity stability factor": 0.4415485795,
      "Direction stability factor": 0.8624667566,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5045608294, 0.9857763799, 1.3253738551],
      direction: [-30.368956017, -7.829184139, 11.1498357282],
      "speed factor": 1.4004340382,
      "Uz factor": 0.9891408048,
      "Directional variance factor": 0.304072521,
      "Velocity stability factor": 0.3287461299,
      "Direction stability factor": 0.8846700229,
      score: 0.6005396742,
    },
    "202.5": {
      velocity: [0.0540532704, 0.3161676777, 1.1149113492],
      direction: [-157.4962569171, -34.0483022645, 169.3124182183],
      "speed factor": 0.3704395759,
      "Uz factor": 0.9930854563,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1843040013,
      "Direction stability factor": 0.0921981246,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2131954437, 0.5851109178, 1.0529398377],
      direction: [-30.0841409802, 0.427005115, 23.2073072519],
      "speed factor": 1.085451262,
      "Uz factor": 0.9978572483,
      "Directional variance factor": 0.9620439898,
      "Velocity stability factor": 0.1851558328,
      "Direction stability factor": 0.8519681994,
      score: 0.7127840553,
    },
    "292.5": {
      velocity: [0.0223691438, 0.265671586, 0.7965097511],
      direction: [-59.7747888237, 33.5198423861, 179.9771466856],
      "speed factor": 0.361778462,
      "Uz factor": 0.9517450882,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4322919488,
      "Direction stability factor": 0.3340224014,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0543679561, 0.423044607, 1.0967558354],
      direction: [-173.2823110338, -8.0469038658, 160.5323168276],
      "speed factor": 0.537240395,
      "Uz factor": 0.9971082864,
      "Directional variance factor": 0.2847196564,
      "Velocity stability factor": 0.1604248685,
      "Direction stability factor": 0.0727371448,
      score: 0.0,
    },
    id: "IDN:476.0",
    "45": {
      velocity: [0.1462614448, 0.5264944933, 1.0171110375],
      direction: [-166.3642260653, 1.3944676365, 116.0465479323],
      "speed factor": 0.7151804007,
      "Uz factor": 0.9793559542,
      "Directional variance factor": 0.8760473212,
      "Velocity stability factor": 0.2920268376,
      "Direction stability factor": 0.2155256278,
      score: 0.0,
    },
    "90": {
      velocity: [0.6579669311, 1.0091407872, 1.3240440659],
      direction: [-40.926215303, -2.9028062391, 16.2722223264],
      "speed factor": 1.6358467466,
      "Uz factor": 0.9530714064,
      "Directional variance factor": 0.7419727787,
      "Velocity stability factor": 0.5138585865,
      "Direction stability factor": 0.841115451,
      score: 0.7345155668,
    },
    "135": {
      velocity: [0.3456129261, 0.6752841527, 1.093242929],
      direction: [-104.937130969, -31.2278977811, 17.1268749978],
      "speed factor": 0.571526962,
      "Uz factor": 0.9387782699,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3975516622,
      "Direction stability factor": 0.6609333168,
      score: 0.0,
    },
    "180": {
      velocity: [0.1187009297, 0.648500315, 1.1084795188],
      direction: [-110.8283595351, -15.6609923847, 138.0662417046],
      "speed factor": 1.2665171091,
      "Uz factor": 0.9983929906,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2200971505,
      "Direction stability factor": 0.3086261077,
      score: 0.0,
    },
    "225": {
      velocity: [0.0474693553, 0.4264886369, 0.8460956405],
      direction: [-95.9865863131, -0.3919719173, 136.2771754411],
      "speed factor": 0.5822036624,
      "Uz factor": 0.9999463731,
      "Directional variance factor": 0.9651580518,
      "Velocity stability factor": 0.2822101802,
      "Direction stability factor": 0.354822884,
      score: 0.0,
    },
    "270": {
      velocity: [0.0907004079, 0.4084376732, 0.9536867448],
      direction: [-88.3185460943, 26.6532418377, 153.900992872],
      "speed factor": 0.3737336593,
      "Uz factor": 0.990855674,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3192736121,
      "Direction stability factor": 0.3271679473,
      score: 0.0,
    },
    "315": {
      velocity: [0.0670435824, 0.4632927441, 1.1081456514],
      direction: [-35.5847486987, -1.7626284086, 164.1688299134],
      "speed factor": 0.5708227667,
      "Uz factor": 0.9930831297,
      "Directional variance factor": 0.8433219192,
      "Velocity stability factor": 0.1554205546,
      "Direction stability factor": 0.4451289483,
      score: 0.0,
    },
  },
  {
    position: [12.49640175, 96.58909071, 46.87999908],
    "overall score": 0.126072271,
    "0": {
      velocity: [0.0759256938, 0.533322859, 1.2825047882],
      direction: [-148.2189281825, -22.9580251319, 118.9169155114],
      "speed factor": 0.5465231594,
      "Uz factor": 0.994112085,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1616316906,
      "Direction stability factor": 0.2579559897,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1317624006, 0.9544134153, 1.4064321276],
      direction: [-81.6759716756, -18.8600429971, 1.3732237418],
      "speed factor": 0.8474004326,
      "Uz factor": 0.9994524737,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1757049303,
      "Direction stability factor": 0.7693077905,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1947985784, 0.4447658757, 0.7846085079],
      direction: [-52.9108281488, -7.7844782284, 61.2417281058],
      "speed factor": 0.6889719918,
      "Uz factor": 0.9811194436,
      "Directional variance factor": 0.3080463797,
      "Velocity stability factor": 0.4729726677,
      "Direction stability factor": 0.682909566,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5803946084, 0.9941127972, 1.2090073293],
      direction: [-37.9046095061, 2.8615719308, 34.7249710814],
      "speed factor": 1.1183074472,
      "Uz factor": 0.9664936527,
      "Directional variance factor": 0.7456380506,
      "Velocity stability factor": 0.4796224274,
      "Direction stability factor": 0.798251165,
      score: 0.705440702,
    },
    "157.5": {
      velocity: [0.4317101717, 0.9732992083, 1.2190126058],
      direction: [-17.1920433607, 16.5984042974, 66.0148640935],
      "speed factor": 1.3827084605,
      "Uz factor": 0.9927899987,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3561508051,
      "Direction stability factor": 0.7688697015,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2131670716, 0.7652738677, 1.1595467881],
      direction: [-115.1638742812, -7.3036518101, 85.3670651513],
      "speed factor": 0.8966372814,
      "Uz factor": 0.9659066971,
      "Directional variance factor": 0.3507865058,
      "Velocity stability factor": 0.2723266539,
      "Direction stability factor": 0.4429696127,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0915512691, 0.4498855002, 0.9381084776],
      direction: [-130.9092089627, -6.258694556, 125.9833734286],
      "speed factor": 0.834591817,
      "Uz factor": 0.9951317669,
      "Directional variance factor": 0.443671595,
      "Velocity stability factor": 0.1785450328,
      "Direction stability factor": 0.2864094934,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0523579461, 0.3099146027, 0.8253218337],
      direction: [-179.5823523355, 5.9876128576, 173.8961579496],
      "speed factor": 0.42202642,
      "Uz factor": 0.9980864982,
      "Directional variance factor": 0.467767746,
      "Velocity stability factor": 0.4331548841,
      "Direction stability factor": 0.0181152492,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1624024455, 0.6251221778, 1.0765543277],
      direction: [-74.3053889061, 5.4294199032, 54.4713024212],
      "speed factor": 0.793866368,
      "Uz factor": 0.9997699473,
      "Directional variance factor": 0.5173848975,
      "Velocity stability factor": 0.2637105612,
      "Direction stability factor": 0.6422869685,
      score: 0.0,
    },
    id: "IDN:414.0",
    "45": {
      velocity: [0.250624324, 0.6888511563, 1.0216176804],
      direction: [-65.3672897209, -6.6150940305, 30.2386377096],
      "speed factor": 0.9357226948,
      "Uz factor": 0.9951821428,
      "Directional variance factor": 0.4119916417,
      "Velocity stability factor": 0.3732067979,
      "Direction stability factor": 0.7344279794,
      score: 0.0,
    },
    "90": {
      velocity: [0.5310683049, 0.8280885674, 1.0708368306],
      direction: [-17.7101429353, 7.0789276862, 29.4431497808],
      "speed factor": 1.3423558002,
      "Uz factor": 0.959806953,
      "Directional variance factor": 0.3707619835,
      "Velocity stability factor": 0.6060458762,
      "Direction stability factor": 0.8690186313,
      score: 0.6787112806,
    },
    "135": {
      velocity: [0.8081672157, 1.2047799854, 1.3585871039],
      direction: [-9.7203470551, 9.179123376, 27.8143708587],
      "speed factor": 1.019665932,
      "Uz factor": 0.979426631,
      "Directional variance factor": 0.1840779221,
      "Velocity stability factor": 0.5564657042,
      "Direction stability factor": 0.8957368947,
      score: 0.6330043539,
    },
    "180": {
      velocity: [0.3216811279, 0.7522952155, 1.1212016538],
      direction: [-28.42986168, 14.3417252576, 52.3098523022],
      "speed factor": 1.4692279085,
      "Uz factor": 0.9793414194,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3700123005,
      "Direction stability factor": 0.7757230167,
      score: 0.0,
    },
    "225": {
      velocity: [0.1116635932, 0.4569856689, 0.862892352],
      direction: [-146.8120557602, -9.1816278821, 89.1291987201],
      "speed factor": 0.6238354485,
      "Uz factor": 0.9908531708,
      "Directional variance factor": 0.1838552994,
      "Velocity stability factor": 0.324810158,
      "Direction stability factor": 0.3446076264,
      score: 0.0,
    },
    "270": {
      velocity: [0.2983901593, 0.8129377971, 1.2790880076],
      direction: [-65.776624148, -1.7767955265, 36.4428382314],
      "speed factor": 0.7438643338,
      "Uz factor": 0.99641812,
      "Directional variance factor": 0.8420626199,
      "Velocity stability factor": 0.2264223948,
      "Direction stability factor": 0.7160570489,
      score: 0.0,
    },
    "315": {
      velocity: [0.0584544201, 0.4110996353, 0.9911167166],
      direction: [-109.8403820931, -24.8294849771, 62.8939667643],
      "speed factor": 0.5065156625,
      "Uz factor": 0.999833678,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2433907984,
      "Direction stability factor": 0.5201823643,
      score: 0.0,
    },
  },
  {
    position: [-5.258533199, 95.21003953, 46.87999908],
    "overall score": 0.1232359543,
    "0": {
      velocity: [0.1319373058, 0.8238135614, 1.3910137936],
      direction: [-169.0825344754, 5.9955782688, 128.4231695643],
      "speed factor": 0.8442038114,
      "Uz factor": 0.9949223743,
      "Directional variance factor": 0.4670597094,
      "Velocity stability factor": 0.1251548851,
      "Direction stability factor": 0.1735952666,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0768307475, 0.4894381039, 1.15450181],
      direction: [-107.3710966911, 3.7978466219, 156.5459873444],
      "speed factor": 0.4345601752,
      "Uz factor": 0.9979320936,
      "Directional variance factor": 0.6624136336,
      "Velocity stability factor": 0.3030987363,
      "Direction stability factor": 0.2668969888,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0924441174, 0.4449536711, 0.9214643295],
      direction: [-73.3783100431, 12.2702881577, 84.4798786412],
      "speed factor": 0.6892628994,
      "Uz factor": 0.9996757253,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2592252368,
      "Direction stability factor": 0.5615050314,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0698415196, 0.8210253083, 1.2671191853],
      direction: [-37.2157670661, 14.7435058342, 121.9553722408],
      "speed factor": 0.9235961143,
      "Uz factor": 0.9609719944,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0088707646,
      "Direction stability factor": 0.5578579464,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4131154338, 0.8332448439, 1.2016533306],
      direction: [-32.9263813062, -3.8404176887, 46.1925601996],
      "speed factor": 1.1837415313,
      "Uz factor": 0.9674524179,
      "Directional variance factor": 0.6586295388,
      "Velocity stability factor": 0.3551404541,
      "Direction stability factor": 0.7802251625,
      score: 0.6435550795,
    },
    "202.5": {
      velocity: [0.1759700675, 0.799274176, 1.2214023152],
      direction: [-81.0991478629, -28.6525205444, 136.2069181654],
      "speed factor": 0.9364739272,
      "Uz factor": 0.9569408322,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1961649552,
      "Direction stability factor": 0.3963720388,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2170754043, 0.4993099876, 0.8796235686],
      direction: [-64.3234664839, -3.1253704305, 38.3418682968],
      "speed factor": 0.926280197,
      "Uz factor": 0.9863739039,
      "Directional variance factor": 0.7221892951,
      "Velocity stability factor": 0.3570978132,
      "Direction stability factor": 0.7148185145,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0666761804, 0.4497973996, 0.9603601214],
      direction: [-162.8377545061, 19.1705938151, 154.5373130297],
      "speed factor": 0.6125119133,
      "Uz factor": 0.9422062505,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3446260747,
      "Direction stability factor": 0.1184025902,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2028044427, 0.6473672842, 1.215188569],
      direction: [-64.5330963359, -25.4943457295, 94.8807861005],
      "speed factor": 0.8221162726,
      "Uz factor": 0.8804873281,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1845909255,
      "Direction stability factor": 0.5571836599,
      score: 0.0,
    },
    id: "IDN:406.0",
    "45": {
      velocity: [0.0572974944, 0.4605099428, 1.1147761847],
      direction: [-71.877938361, 7.1040759882, 155.4267721036],
      "speed factor": 0.6255482054,
      "Uz factor": 0.9986666329,
      "Directional variance factor": 0.3685265788,
      "Velocity stability factor": 0.1403032867,
      "Direction stability factor": 0.3685980265,
      score: 0.0,
    },
    "90": {
      velocity: [0.2143469631, 0.7177048994, 1.1006694305],
      direction: [-9.4375290642, 25.3789459004, 77.7198861315],
      "speed factor": 1.1634206442,
      "Uz factor": 0.992754911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3531108717,
      "Direction stability factor": 0.7578960689,
      score: 0.0,
    },
    "135": {
      velocity: [0.8092857278, 1.1761427221, 1.3756468114],
      direction: [-1.3551725687, 4.9440524979, 24.4443925254],
      "speed factor": 0.9954287748,
      "Uz factor": 0.9416664485,
      "Directional variance factor": 0.5605286669,
      "Velocity stability factor": 0.5436201167,
      "Direction stability factor": 0.9283345414,
      score: 0.0,
    },
    "180": {
      velocity: [0.3057318281, 0.8373555474, 1.2560438821],
      direction: [-47.2950786314, -6.0206190608, 28.0031906652],
      "speed factor": 1.6353502112,
      "Uz factor": 0.9729670991,
      "Directional variance factor": 0.4648338613,
      "Velocity stability factor": 0.2511950784,
      "Direction stability factor": 0.7908381408,
      score: 0.5744263053,
    },
    "225": {
      velocity: [0.183526216, 0.5067740775, 0.8035525463],
      direction: [-89.8766436317, -17.1104868388, 23.0606532154],
      "speed factor": 0.6918020749,
      "Uz factor": 0.9865582838,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4427323567,
      "Direction stability factor": 0.6862852865,
      score: 0.0,
    },
    "270": {
      velocity: [0.314706445, 0.822818657, 1.3498930884],
      direction: [-42.3699425333, 10.0867440535, 37.6498232124],
      "speed factor": 0.7529056396,
      "Uz factor": 0.9890520808,
      "Directional variance factor": 0.1034005286,
      "Velocity stability factor": 0.1834414587,
      "Direction stability factor": 0.7777228729,
      score: 0.0,
    },
    "315": {
      velocity: [0.5286248463, 0.883528951, 1.1580671754],
      direction: [-11.8212896378, 2.9150008527, 26.8943739281],
      "speed factor": 1.0885955947,
      "Uz factor": 0.9529387986,
      "Directional variance factor": 0.7408888131,
      "Velocity stability factor": 0.4893737424,
      "Direction stability factor": 0.8924564901,
      score: 0.7537938839,
    },
  },
  {
    position: [-5.263975058, 93.65106809, 46.87999908],
    "overall score": 0.121586206,
    "0": {
      velocity: [0.0857172428, 0.7086463775, 1.3596428433],
      direction: [-160.4436541202, 7.8677159864, 137.5798625214],
      "speed factor": 0.7261861189,
      "Uz factor": 0.9978463829,
      "Directional variance factor": 0.3006474679,
      "Velocity stability factor": 0.1148372643,
      "Direction stability factor": 0.1721568982,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.053844461, 0.3913430528, 1.1366997522],
      direction: [-162.7778666987, 9.0951165562, 176.1613568363],
      "speed factor": 0.3474639678,
      "Uz factor": 0.9974294981,
      "Directional variance factor": 0.191545195,
      "Velocity stability factor": 0.2997462332,
      "Direction stability factor": 0.0585021568,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0700665581, 0.4377676163, 0.9478616406],
      direction: [-54.2209281119, 13.5055977546, 79.1473558769],
      "speed factor": 0.6781312214,
      "Uz factor": 0.9947668126,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2156422306,
      "Direction stability factor": 0.6295325445,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.319638574, 0.8670428179, 1.2325113439],
      direction: [-21.9182564053, 10.8990748844, 42.9614592206],
      "speed factor": 0.9753625978,
      "Uz factor": 0.9508126766,
      "Directional variance factor": 0.0311933436,
      "Velocity stability factor": 0.2443065494,
      "Direction stability factor": 0.8197785677,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3929648713, 0.7821472426, 1.096367166],
      direction: [-33.8732206046, -4.7073967073, 44.6006739415],
      "speed factor": 1.1111501997,
      "Uz factor": 0.958186092,
      "Directional variance factor": 0.5815647371,
      "Velocity stability factor": 0.4247636212,
      "Direction stability factor": 0.7820169596,
      score: 0.6425905694,
    },
    "202.5": {
      velocity: [0.2228602747, 0.8152088702, 1.1442027096],
      direction: [-74.4630115343, -27.6382706917, 120.5070684686],
      "speed factor": 0.9551438982,
      "Uz factor": 0.9351762729,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2915778721,
      "Direction stability factor": 0.4584164444,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2010390719, 0.4918282635, 0.8216162372],
      direction: [-72.0187233806, -1.697583096, 38.3450929374],
      "speed factor": 0.9124006972,
      "Uz factor": 0.9904072193,
      "Directional variance factor": 0.8491037248,
      "Velocity stability factor": 0.3978242819,
      "Direction stability factor": 0.6934338436,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0605425781, 0.4538414306, 1.0047432798],
      direction: [-170.2829914806, 17.2993758908, 108.5827081051],
      "speed factor": 0.6180188753,
      "Uz factor": 0.9599797312,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3075801279,
      "Direction stability factor": 0.2253730567,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2416088397, 0.6624308597, 1.2151958685],
      direction: [-58.0887851706, -24.4879386684, 67.4431157854],
      "speed factor": 0.8412460786,
      "Uz factor": 0.9079533887,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2158394452,
      "Direction stability factor": 0.6513002751,
      score: 0.0,
    },
    id: "IDN:407.0",
    "45": {
      velocity: [0.049189287, 0.4916280271, 1.0851126511],
      direction: [-161.010479275, 11.3281514112, 94.1211023008],
      "speed factor": 0.6678184368,
      "Uz factor": 0.9999999864,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1578270849,
      "Direction stability factor": 0.2913011623,
      score: 0.0,
    },
    "90": {
      velocity: [0.1743498468, 0.7233242736, 1.1174818098],
      direction: [-4.242333307, 22.1983941935, 105.4615022259],
      "speed factor": 1.1725298143,
      "Uz factor": 0.9772784846,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3116480334,
      "Direction stability factor": 0.6952671235,
      score: 0.0,
    },
    "135": {
      velocity: [0.8266261414, 1.1537237853, 1.3161923389],
      direction: [-6.3245934465, 1.2773074453, 18.4012144218],
      "speed factor": 0.9764545004,
      "Uz factor": 0.9382790728,
      "Directional variance factor": 0.8864615604,
      "Velocity stability factor": 0.605502266,
      "Direction stability factor": 0.9313172004,
      score: 0.0,
    },
    "180": {
      velocity: [0.5252251725, 0.8140339907, 1.2041078505],
      direction: [-47.998258907, -5.4476596481, 56.8131511483],
      "speed factor": 1.589803355,
      "Uz factor": 0.9632726327,
      "Directional variance factor": 0.5157635868,
      "Velocity stability factor": 0.4650697228,
      "Direction stability factor": 0.7088571943,
      score: 0.5996369245,
    },
    "225": {
      velocity: [0.2373749888, 0.4879165225, 0.8234065024],
      direction: [-88.3759731053, -16.9222001237, 30.043065811],
      "speed factor": 0.6660594486,
      "Uz factor": 0.9784132924,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4732862388,
      "Direction stability factor": 0.6710582252,
      score: 0.0,
    },
    "270": {
      velocity: [0.2981651359, 0.7896543426, 1.3367916269],
      direction: [-35.3927040231, 12.0458858312, 34.0481006717],
      "speed factor": 0.7225592211,
      "Uz factor": 0.9956201911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1807280959,
      "Direction stability factor": 0.8071088758,
      score: 0.0,
    },
    "315": {
      velocity: [0.4389699236, 0.8628190752, 1.1538160932],
      direction: [-10.9057174152, 3.8115202575, 37.4570542708],
      "speed factor": 1.0630789667,
      "Uz factor": 0.9635503208,
      "Directional variance factor": 0.6611981993,
      "Velocity stability factor": 0.4200910752,
      "Direction stability factor": 0.8656589675,
      score: 0.7031518024,
    },
  },
  {
    position: [9.735933557, 93.59870832, 47.87999908],
    "overall score": 0.1213723852,
    "0": {
      velocity: [0.0626917736, 0.5376450608, 1.2157986058],
      direction: [-175.1081636576, -19.4141279848, 5.7706877784],
      "speed factor": 0.5509523402,
      "Uz factor": 0.9935266537,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.198785865,
      "Direction stability factor": 0.497558746,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1122464408, 0.833217099, 1.3148779378],
      direction: [-127.2852742561, -17.4585164002, 9.3241182743],
      "speed factor": 0.7397931744,
      "Uz factor": 0.9999867409,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2222901411,
      "Direction stability factor": 0.6205294652,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0820621252, 0.4546966306, 0.8492987459],
      direction: [-43.1513962225, -4.6632154343, 78.835396662],
      "speed factor": 0.7043553932,
      "Uz factor": 0.9913941663,
      "Directional variance factor": 0.5854919614,
      "Velocity stability factor": 0.3144322446,
      "Direction stability factor": 0.6611477975,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5938119062, 0.9680782545, 1.2020207323],
      direction: [-31.047037508, 3.8159564664, 51.1350412448],
      "speed factor": 1.0890204056,
      "Uz factor": 0.9812168117,
      "Directional variance factor": 0.6608038697,
      "Velocity stability factor": 0.4965131598,
      "Direction stability factor": 0.7717164479,
      score: 0.6751874813,
    },
    "157.5": {
      velocity: [0.392153673, 0.8953539697, 1.144782332],
      direction: [-11.7303114938, 13.1721839887, 53.741859546],
      "speed factor": 1.2719762828,
      "Uz factor": 0.9703845064,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3845067216,
      "Direction stability factor": 0.8181328582,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3937516343, 0.8626140044, 1.1853427151],
      direction: [-45.6928912601, -5.4444850806, 123.4200110953],
      "speed factor": 1.010686381,
      "Uz factor": 0.9363273963,
      "Directional variance factor": 0.5160457706,
      "Velocity stability factor": 0.39134396,
      "Direction stability factor": 0.5302419379,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1802012649, 0.5626356719, 0.9223238406],
      direction: [-48.2139763326, -3.7982776887, 84.7247030599],
      "speed factor": 1.0437569725,
      "Uz factor": 0.9884405737,
      "Directional variance factor": 0.6623753166,
      "Velocity stability factor": 0.2798829543,
      "Direction stability factor": 0.6307258906,
      score: 0.550927513,
    },
    "292.5": {
      velocity: [0.0549856888, 0.3560234762, 0.8843424231],
      direction: [-174.6923978411, 2.3463805241, 87.8137116804],
      "speed factor": 0.4848152097,
      "Uz factor": 0.9998280748,
      "Directional variance factor": 0.7914328423,
      "Velocity stability factor": 0.39179977,
      "Direction stability factor": 0.2708163624,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1448801795, 0.5783178293, 1.0064786611],
      direction: [-129.5106933735, 8.158115435, 56.62230087],
      "speed factor": 0.7344277503,
      "Uz factor": 0.9988890784,
      "Directional variance factor": 0.2748341836,
      "Velocity stability factor": 0.3060388816,
      "Direction stability factor": 0.4829639049,
      score: 0.0,
    },
    id: "IDN:678.0",
    "45": {
      velocity: [0.2099557087, 0.6928358639, 1.0754439513],
      direction: [-84.8397621841, -3.7508366034, 35.2208211997],
      "speed factor": 0.941135448,
      "Uz factor": 0.9983872944,
      "Directional variance factor": 0.6665923019,
      "Velocity stability factor": 0.2963854455,
      "Direction stability factor": 0.6664983795,
      score: 0.0,
    },
    "90": {
      velocity: [0.567222791, 0.8907711213, 1.1351837096],
      direction: [-15.7494413815, 5.2141914726, 30.8009142839],
      "speed factor": 1.4439660544,
      "Uz factor": 0.9852719672,
      "Directional variance factor": 0.5365163136,
      "Velocity stability factor": 0.5854694458,
      "Direction stability factor": 0.8706934565,
      score: 0.7158431681,
    },
    "135": {
      velocity: [0.6765487333, 1.1526085525, 1.3037592969],
      direction: [-8.7564547095, 9.3177879893, 24.1456223485],
      "speed factor": 0.9755106228,
      "Uz factor": 0.9655764989,
      "Directional variance factor": 0.1717521787,
      "Velocity stability factor": 0.4945869481,
      "Direction stability factor": 0.9086053415,
      score: 0.0,
    },
    "180": {
      velocity: [0.4466999005, 0.7754003543, 1.0276223815],
      direction: [-31.7495108965, 9.396187255, 59.5052226806],
      "speed factor": 1.5143521018,
      "Uz factor": 0.9487400815,
      "Directional variance factor": 0.1647833551,
      "Velocity stability factor": 0.542258134,
      "Direction stability factor": 0.746514629,
      score: 0.0,
    },
    "225": {
      velocity: [0.142313426, 0.5103647839, 0.8009489704],
      direction: [-67.5822097053, -6.30048206, 56.2696953624],
      "speed factor": 0.696703782,
      "Uz factor": 0.9869973037,
      "Directional variance factor": 0.4399571502,
      "Velocity stability factor": 0.4080311437,
      "Direction stability factor": 0.6559669304,
      score: 0.0,
    },
    "270": {
      velocity: [0.3385744839, 0.8151877599, 1.3430801709],
      direction: [-47.3110042395, 0.4874339239, 34.4708545524],
      "speed factor": 0.7459231224,
      "Uz factor": 0.9999330436,
      "Directional variance factor": 0.9566725401,
      "Velocity stability factor": 0.2076426954,
      "Direction stability factor": 0.77282817,
      score: 0.0,
    },
    "315": {
      velocity: [0.0638756351, 0.4559878153, 0.9854157713],
      direction: [-171.9188008149, -22.693002591, 25.4902414081],
      "speed factor": 0.5618223675,
      "Uz factor": 0.9976308673,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.252413495,
      "Direction stability factor": 0.4516415494,
      score: 0.0,
    },
  },
  {
    position: [-50.56305378, 32.38881079, 45.87999908],
    "overall score": 0.1201261633,
    "0": {
      velocity: [0.1046200451, 0.683103958, 1.2230100746],
      direction: [-53.1358623404, -10.1352620327, 100.2087519558],
      "speed factor": 0.7000114977,
      "Uz factor": 0.9974278834,
      "Directional variance factor": 0.0990878193,
      "Velocity stability factor": 0.2229081686,
      "Direction stability factor": 0.5740427381,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.118672815, 0.4684244175, 1.2090789415],
      direction: [-163.0887074892, -13.6409532649, 173.90712932],
      "speed factor": 0.4159026347,
      "Uz factor": 0.9741520767,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2948633085,
      "Direction stability factor": 0.0639004533,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3202714819, 0.6846942042, 0.978976226],
      direction: [-30.6747884854, -6.494444865, 19.938175602],
      "speed factor": 1.0606369675,
      "Uz factor": 0.9598692289,
      "Directional variance factor": 0.422716012,
      "Velocity stability factor": 0.4114113942,
      "Direction stability factor": 0.8594084331,
      score: 0.6382360681,
    },
    "112.5": {
      velocity: [0.5164034457, 0.8693819384, 1.2606744572],
      direction: [-62.9219861038, -38.0654981711, -10.5613742403],
      "speed factor": 0.977993945,
      "Uz factor": 0.9575992519,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3838782935,
      "Direction stability factor": 0.8545538559,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5031900329, 0.8788453325, 1.1440697133],
      direction: [-50.5186539437, -10.7079926399, 15.1913942551],
      "speed factor": 1.248523441,
      "Uz factor": 0.9565469637,
      "Directional variance factor": 0.048178432,
      "Velocity stability factor": 0.4758940803,
      "Direction stability factor": 0.8174720883,
      score: 0.5397541723,
    },
    "202.5": {
      velocity: [0.5280604598, 0.9087323347, 1.1588651506],
      direction: [-24.5965690691, 3.801804262, 15.9905802939],
      "speed factor": 1.0647211731,
      "Uz factor": 0.934682938,
      "Directional variance factor": 0.6620618434,
      "Velocity stability factor": 0.5149729521,
      "Direction stability factor": 0.8872579184,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4617760036, 0.7780833424, 1.0070189118],
      direction: [-19.2111228664, 3.2964127252, 17.1122947633],
      "speed factor": 1.4434383641,
      "Uz factor": 0.9952707597,
      "Directional variance factor": 0.7069855355,
      "Velocity stability factor": 0.4709247162,
      "Direction stability factor": 0.8991016177,
      score: 0.7440283718,
    },
    "292.5": {
      velocity: [0.0518765171, 0.1689449943, 0.5696902258],
      direction: [-179.7675921049, 93.5021988992, 160.802706482],
      "speed factor": 0.2300609603,
      "Uz factor": 0.9450186181,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6202666432,
      "Direction stability factor": 0.0539713928,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0570027758, 0.3082733985, 0.7913584315],
      direction: [-159.1640799085, 10.7317529418, 179.8494371628],
      "speed factor": 0.3914880834,
      "Uz factor": 0.998672031,
      "Directional variance factor": 0.0460664052,
      "Velocity stability factor": 0.40852464,
      "Direction stability factor": 0.0582957859,
      score: 0.0,
    },
    id: "IDN:396.0",
    "45": {
      velocity: [0.1138005062, 0.4667131923, 0.9417819052],
      direction: [-63.6590843278, 9.3977234097, 134.1387300242],
      "speed factor": 0.633974585,
      "Uz factor": 0.957445911,
      "Directional variance factor": 0.164646808,
      "Velocity stability factor": 0.3268773225,
      "Direction stability factor": 0.4505616268,
      score: 0.0,
    },
    "90": {
      velocity: [0.657507031, 1.049478159, 1.3796687039],
      direction: [-37.7828865344, -24.6240390517, -3.1909072012],
      "speed factor": 1.7012348068,
      "Uz factor": 0.9344713432,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.472924864,
      "Direction stability factor": 0.9039111685,
      score: 0.0,
    },
    "135": {
      velocity: [0.1471450262, 0.6108913705, 0.9499316154],
      direction: [-97.2344970924, -52.4597995729, 25.2771517146],
      "speed factor": 0.5170281099,
      "Uz factor": 0.9710497926,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3531058887,
      "Direction stability factor": 0.6596898644,
      score: 0.0,
    },
    "180": {
      velocity: [0.5154774522, 0.7938161027, 1.0483086364],
      direction: [-47.2399566083, -2.7308764639, 24.0175456345],
      "speed factor": 1.5503179447,
      "Uz factor": 0.9490457333,
      "Directional variance factor": 0.7572554254,
      "Velocity stability factor": 0.5801520022,
      "Direction stability factor": 0.8020624938,
      score: 0.0,
    },
    "225": {
      velocity: [0.1604746038, 0.5441349738, 1.0288176383],
      direction: [-28.7568573644, 7.3231949221, 90.562194956],
      "speed factor": 0.742803787,
      "Uz factor": 0.9736520777,
      "Directional variance factor": 0.3490493403,
      "Velocity stability factor": 0.2195501178,
      "Direction stability factor": 0.668558188,
      score: 0.0,
    },
    "270": {
      velocity: [0.0356510967, 0.2861946452, 1.0899941515],
      direction: [-156.2944906433, 43.6332188369, 179.7559216242],
      "speed factor": 0.2618773415,
      "Uz factor": 0.9841827342,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.16833082,
      "Direction stability factor": 0.0665266326,
      score: 0.0,
    },
    "315": {
      velocity: [0.0905150504, 0.3283945409, 0.7416062562],
      direction: [-129.5942450788, 69.8837912496, 136.9406757872],
      "speed factor": 0.4046147556,
      "Uz factor": 0.9799928466,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4718113949,
      "Direction stability factor": 0.2596252198,
      score: 0.0,
    },
  },
  {
    position: [-50.56305378, 32.38881079, 45.87999908],
    "overall score": 0.1201261633,
    "0": {
      velocity: [0.1046200451, 0.683103958, 1.2230100746],
      direction: [-53.1358623404, -10.1352620327, 100.2087519558],
      "speed factor": 0.7000114977,
      "Uz factor": 0.9974278834,
      "Directional variance factor": 0.0990878193,
      "Velocity stability factor": 0.2229081686,
      "Direction stability factor": 0.5740427381,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.118672815, 0.4684244175, 1.2090789415],
      direction: [-163.0887074892, -13.6409532649, 173.90712932],
      "speed factor": 0.4159026347,
      "Uz factor": 0.9741520767,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2948633085,
      "Direction stability factor": 0.0639004533,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3202714819, 0.6846942042, 0.978976226],
      direction: [-30.6747884854, -6.494444865, 19.938175602],
      "speed factor": 1.0606369675,
      "Uz factor": 0.9598692289,
      "Directional variance factor": 0.422716012,
      "Velocity stability factor": 0.4114113942,
      "Direction stability factor": 0.8594084331,
      score: 0.6382360681,
    },
    "112.5": {
      velocity: [0.5164034457, 0.8693819384, 1.2606744572],
      direction: [-62.9219861038, -38.0654981711, -10.5613742403],
      "speed factor": 0.977993945,
      "Uz factor": 0.9575992519,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3838782935,
      "Direction stability factor": 0.8545538559,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5031900329, 0.8788453325, 1.1440697133],
      direction: [-50.5186539437, -10.7079926399, 15.1913942551],
      "speed factor": 1.248523441,
      "Uz factor": 0.9565469637,
      "Directional variance factor": 0.048178432,
      "Velocity stability factor": 0.4758940803,
      "Direction stability factor": 0.8174720883,
      score: 0.5397541723,
    },
    "202.5": {
      velocity: [0.5280604598, 0.9087323347, 1.1588651506],
      direction: [-24.5965690691, 3.801804262, 15.9905802939],
      "speed factor": 1.0647211731,
      "Uz factor": 0.934682938,
      "Directional variance factor": 0.6620618434,
      "Velocity stability factor": 0.5149729521,
      "Direction stability factor": 0.8872579184,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4617760036, 0.7780833424, 1.0070189118],
      direction: [-19.2111228664, 3.2964127252, 17.1122947633],
      "speed factor": 1.4434383641,
      "Uz factor": 0.9952707597,
      "Directional variance factor": 0.7069855355,
      "Velocity stability factor": 0.4709247162,
      "Direction stability factor": 0.8991016177,
      score: 0.7440283718,
    },
    "292.5": {
      velocity: [0.0518765171, 0.1689449943, 0.5696902258],
      direction: [-179.7675921049, 93.5021988992, 160.802706482],
      "speed factor": 0.2300609603,
      "Uz factor": 0.9450186181,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6202666432,
      "Direction stability factor": 0.0539713928,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0570027758, 0.3082733985, 0.7913584315],
      direction: [-159.1640799085, 10.7317529418, 179.8494371628],
      "speed factor": 0.3914880834,
      "Uz factor": 0.998672031,
      "Directional variance factor": 0.0460664052,
      "Velocity stability factor": 0.40852464,
      "Direction stability factor": 0.0582957859,
      score: 0.0,
    },
    id: "IDN:397.0",
    "45": {
      velocity: [0.1138005062, 0.4667131923, 0.9417819052],
      direction: [-63.6590843278, 9.3977234097, 134.1387300242],
      "speed factor": 0.633974585,
      "Uz factor": 0.957445911,
      "Directional variance factor": 0.164646808,
      "Velocity stability factor": 0.3268773225,
      "Direction stability factor": 0.4505616268,
      score: 0.0,
    },
    "90": {
      velocity: [0.657507031, 1.049478159, 1.3796687039],
      direction: [-37.7828865344, -24.6240390517, -3.1909072012],
      "speed factor": 1.7012348068,
      "Uz factor": 0.9344713432,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.472924864,
      "Direction stability factor": 0.9039111685,
      score: 0.0,
    },
    "135": {
      velocity: [0.1471450262, 0.6108913705, 0.9499316154],
      direction: [-97.2344970924, -52.4597995729, 25.2771517146],
      "speed factor": 0.5170281099,
      "Uz factor": 0.9710497926,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3531058887,
      "Direction stability factor": 0.6596898644,
      score: 0.0,
    },
    "180": {
      velocity: [0.5154774522, 0.7938161027, 1.0483086364],
      direction: [-47.2399566083, -2.7308764639, 24.0175456345],
      "speed factor": 1.5503179447,
      "Uz factor": 0.9490457333,
      "Directional variance factor": 0.7572554254,
      "Velocity stability factor": 0.5801520022,
      "Direction stability factor": 0.8020624938,
      score: 0.0,
    },
    "225": {
      velocity: [0.1604746038, 0.5441349738, 1.0288176383],
      direction: [-28.7568573644, 7.3231949221, 90.562194956],
      "speed factor": 0.742803787,
      "Uz factor": 0.9736520777,
      "Directional variance factor": 0.3490493403,
      "Velocity stability factor": 0.2195501178,
      "Direction stability factor": 0.668558188,
      score: 0.0,
    },
    "270": {
      velocity: [0.0356510967, 0.2861946452, 1.0899941515],
      direction: [-156.2944906433, 43.6332188369, 179.7559216242],
      "speed factor": 0.2618773415,
      "Uz factor": 0.9841827342,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.16833082,
      "Direction stability factor": 0.0665266326,
      score: 0.0,
    },
    "315": {
      velocity: [0.0905150504, 0.3283945409, 0.7416062562],
      direction: [-129.5942450788, 69.8837912496, 136.9406757872],
      "speed factor": 0.4046147556,
      "Uz factor": 0.9799928466,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4718113949,
      "Direction stability factor": 0.2596252198,
      score: 0.0,
    },
  },
  {
    position: [-35.95052285, 97.41273697, 47.87999908],
    "overall score": 0.1198123808,
    "0": {
      velocity: [0.4048042217, 0.8786502552, 1.1977979229],
      direction: [-49.5413499857, -23.8290241045, 28.7333468757],
      "speed factor": 0.9003977709,
      "Uz factor": 0.9306462326,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4490035575,
      "Direction stability factor": 0.7825702865,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6735163937, 1.1611093368, 1.4293264009],
      direction: [-19.5055756544, 0.3419139832, 14.374114062],
      "speed factor": 1.0309207085,
      "Uz factor": 0.9826816054,
      "Directional variance factor": 0.9696076459,
      "Velocity stability factor": 0.5112377352,
      "Direction stability factor": 0.9058897508,
      score: 0.8231562207,
    },
    "67.5": {
      velocity: [0.3224802942, 0.6378585788, 1.0375062922],
      direction: [-31.0605943569, -1.4323113252, 58.1996680392],
      "speed factor": 0.9880854615,
      "Uz factor": 0.9869577853,
      "Directional variance factor": 0.8726834378,
      "Velocity stability factor": 0.3610852829,
      "Direction stability factor": 0.7520548267,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.173971988, 0.8017428217, 1.2112025657],
      direction: [-176.7364514684, 25.8361848402, 153.2600210557],
      "speed factor": 0.9019046639,
      "Uz factor": 0.9961039557,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1413607897,
      "Direction stability factor": 0.0833431319,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0762596697, 0.6945733296, 1.1395252783],
      direction: [-135.2196982334, 8.707546122, 131.58411893],
      "speed factor": 0.9867391354,
      "Uz factor": 0.9896004387,
      "Directional variance factor": 0.2259959003,
      "Velocity stability factor": 0.1304704818,
      "Direction stability factor": 0.2588782857,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1722565271, 0.4581801048, 0.8154812887],
      direction: [-97.5109130558, 11.8579109593, 80.7453175973],
      "speed factor": 0.5368292071,
      "Uz factor": 0.7077537632,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5054231336,
      "Direction stability factor": 0.5048438037,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1047619956, 0.4103334759, 0.85509059],
      direction: [-93.8286171599, -9.407557605, 46.8689624095],
      "speed factor": 0.7612180455,
      "Uz factor": 0.9999971503,
      "Directional variance factor": 0.1637726573,
      "Velocity stability factor": 0.2719202616,
      "Direction stability factor": 0.6091733901,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2107786528, 0.6917115877, 1.1255225159],
      direction: [-62.6169090667, -0.2590838988, 37.9267596408],
      "speed factor": 0.9419387227,
      "Uz factor": 0.9807351199,
      "Directional variance factor": 0.9769703201,
      "Velocity stability factor": 0.3291819975,
      "Direction stability factor": 0.7207120314,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7733954358, 0.9940165586, 1.2399936332],
      direction: [-25.9212158423, -12.9645633945, 6.8665881823],
      "speed factor": 1.2623393365,
      "Uz factor": 0.9477580243,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6241857271,
      "Direction stability factor": 0.9089227666,
      score: 0.0,
    },
    id: "IDN:689.0",
    "45": {
      velocity: [0.336733126, 0.5851721657, 0.8004714255],
      direction: [-54.2043751952, -8.5662903219, 32.999346951],
      "speed factor": 0.7948870678,
      "Uz factor": 0.9653740527,
      "Directional variance factor": 0.2385519714,
      "Velocity stability factor": 0.6229954367,
      "Direction stability factor": 0.7577674385,
      score: 0.0,
    },
    "90": {
      velocity: [0.3365605353, 0.7256934933, 1.134494267],
      direction: [-25.5816463649, 22.6027624054, 64.1453663983],
      "speed factor": 1.1763703887,
      "Uz factor": 0.9949658127,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4176220561,
      "Direction stability factor": 0.7507582979,
      score: 0.0,
    },
    "135": {
      velocity: [0.400558236, 1.0992709627, 1.2845347777],
      direction: [-6.1771617176, 8.1451562039, 44.3694472063],
      "speed factor": 0.9303683363,
      "Uz factor": 0.9857542118,
      "Directional variance factor": 0.2759861152,
      "Velocity stability factor": 0.2876821473,
      "Direction stability factor": 0.859592753,
      score: 0.0,
    },
    "180": {
      velocity: [0.1589237303, 0.7389786842, 1.2133899228],
      direction: [-33.6956418117, -1.7928495017, 158.3999449838],
      "speed factor": 1.4432208052,
      "Uz factor": 0.985649913,
      "Directional variance factor": 0.8406355999,
      "Velocity stability factor": 0.1691261083,
      "Direction stability factor": 0.4664011478,
      score: 0.4856410009,
    },
    "225": {
      velocity: [0.3418166577, 0.7955752164, 1.2252242405],
      direction: [-43.5745842431, -3.5562560036, 38.7025639173],
      "speed factor": 1.0860472345,
      "Uz factor": 0.999579514,
      "Directional variance factor": 0.6838883552,
      "Velocity stability factor": 0.2060103939,
      "Direction stability factor": 0.7714523662,
      score: 0.6082008704,
    },
    "270": {
      velocity: [0.4121698499, 1.0491146267, 1.3591295083],
      direction: [-47.1664006918, -2.8593348473, 13.0803121291],
      "speed factor": 0.9599737589,
      "Uz factor": 0.9999999996,
      "Directional variance factor": 0.7458369025,
      "Velocity stability factor": 0.2530351872,
      "Direction stability factor": 0.8326480199,
      score: 0.0,
    },
    "315": {
      velocity: [0.7818758673, 0.9399254041, 1.0868906878],
      direction: [-15.1652284563, -0.7326064089, 10.6554949104],
      "speed factor": 1.1580816374,
      "Uz factor": 0.949469048,
      "Directional variance factor": 0.9348794303,
      "Velocity stability factor": 0.7525610066,
      "Direction stability factor": 0.9282757684,
      score: 0.0,
    },
  },
  {
    position: [-36.39892114, 97.47700053, 47.87999908],
    "overall score": 0.1181410487,
    "0": {
      velocity: [0.4115359979, 0.8824936709, 1.1988225382],
      direction: [-50.8164706194, -24.1533235704, 28.611571734],
      "speed factor": 0.9043363152,
      "Uz factor": 0.9262157694,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4529690686,
      "Direction stability factor": 0.779366549,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6768830874, 1.1521513093, 1.4305811944],
      direction: [-19.9872742617, 0.8450337911, 15.4096530866],
      "speed factor": 1.022967094,
      "Uz factor": 0.9815736724,
      "Directional variance factor": 0.9248858852,
      "Velocity stability factor": 0.5126034451,
      "Direction stability factor": 0.9016752018,
      score: 0.8102099335,
    },
    "67.5": {
      velocity: [0.3373812209, 0.6437209972, 1.0520723959],
      direction: [-30.7678862646, -1.4629800599, 57.6665133721],
      "speed factor": 0.9971667383,
      "Uz factor": 0.9876673741,
      "Directional variance factor": 0.869957328,
      "Velocity stability factor": 0.3613844655,
      "Direction stability factor": 0.7543488899,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1319242661, 0.7946101055, 1.2156858267],
      direction: [-5.2876285013, 25.9304695566, 164.9079575592],
      "speed factor": 0.8938808566,
      "Uz factor": 0.9963745042,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1028415565,
      "Direction stability factor": 0.5272344832,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0930692848, 0.6791698936, 1.1649920406],
      direction: [-130.0801284077, 8.5101537087, 152.1212227152],
      "speed factor": 0.9648563874,
      "Uz factor": 0.990230395,
      "Directional variance factor": 0.2435418926,
      "Velocity stability factor": 0.1233907409,
      "Direction stability factor": 0.216107358,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1467880902, 0.427661287, 0.8607414629],
      direction: [-148.9951200963, 9.2344215112, 116.7131251638],
      "speed factor": 0.5010716685,
      "Uz factor": 0.7559474834,
      "Directional variance factor": 0.1791625323,
      "Velocity stability factor": 0.4510397564,
      "Direction stability factor": 0.2619215409,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1008530791, 0.4079413098, 0.8665029023],
      direction: [-96.0975932962, -9.5274796514, 46.8728659905],
      "speed factor": 0.7567802892,
      "Uz factor": 0.9999683668,
      "Directional variance factor": 0.1531129199,
      "Velocity stability factor": 0.2570533402,
      "Direction stability factor": 0.6028598353,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2127266338, 0.6925761917, 1.1227074705],
      direction: [-64.6370202003, -0.749041293, 39.0780438266],
      "speed factor": 0.9431160978,
      "Uz factor": 0.9793351631,
      "Directional variance factor": 0.9334185517,
      "Velocity stability factor": 0.3326749139,
      "Direction stability factor": 0.7119025999,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.774313232, 0.9885877178, 1.2379575863],
      direction: [-25.7498588078, -12.5309350775, 6.7565381564],
      "speed factor": 1.2554450456,
      "Uz factor": 0.9434178673,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6265648542,
      "Direction stability factor": 0.9097044529,
      score: 0.0,
    },
    id: "IDN:690.0",
    "45": {
      velocity: [0.3396083678, 0.5918797912, 0.812460684],
      direction: [-52.9269752751, -8.7132807836, 33.6792188697],
      "speed factor": 0.8039985825,
      "Uz factor": 0.9645539672,
      "Directional variance factor": 0.2254861526,
      "Velocity stability factor": 0.6155860294,
      "Direction stability factor": 0.7594272385,
      score: 0.0,
    },
    "90": {
      velocity: [0.3318684771, 0.7349796243, 1.1539655325],
      direction: [-22.4417465482, 22.7481621821, 67.3307919499],
      "speed factor": 1.1914234787,
      "Uz factor": 0.9957693441,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3999862723,
      "Direction stability factor": 0.7506318375,
      score: 0.0,
    },
    "135": {
      velocity: [0.35794967, 1.0975086067, 1.2809604769],
      direction: [-6.8646124262, 8.0004424227, 45.5630262294],
      "speed factor": 0.9288767658,
      "Uz factor": 0.9856105028,
      "Directional variance factor": 0.2888495624,
      "Velocity stability factor": 0.2562279145,
      "Direction stability factor": 0.8543676704,
      score: 0.0,
    },
    "180": {
      velocity: [0.1505850633, 0.688328995, 1.1704362169],
      direction: [-34.4237594212, -1.9793396038, 165.755153182],
      "speed factor": 1.3443022749,
      "Uz factor": 0.9851199204,
      "Directional variance factor": 0.8240587019,
      "Velocity stability factor": 0.1964012664,
      "Direction stability factor": 0.443947465,
      score: 0.4770887246,
    },
    "225": {
      velocity: [0.309835131, 0.8062962992, 1.2220702276],
      direction: [-42.7978356827, -3.6379966166, 37.2825124333],
      "speed factor": 1.1006826858,
      "Uz factor": 0.9993973767,
      "Directional variance factor": 0.676622523,
      "Velocity stability factor": 0.1801007835,
      "Direction stability factor": 0.7775545886,
      score: 0.6029581209,
    },
    "270": {
      velocity: [0.4184346946, 1.0530673978, 1.3784185143],
      direction: [-41.7598608792, -2.9774061203, 12.9518126806],
      "speed factor": 0.9635906721,
      "Uz factor": 0.9999801273,
      "Directional variance factor": 0.7353416782,
      "Velocity stability factor": 0.2427616869,
      "Direction stability factor": 0.848023129,
      score: 0.0,
    },
    "315": {
      velocity: [0.7787041836, 0.9377153245, 1.0882014048],
      direction: [-15.5012673225, -0.725307235, 10.4613215687],
      "speed factor": 1.1553585994,
      "Uz factor": 0.9467157573,
      "Directional variance factor": 0.9355282458,
      "Velocity stability factor": 0.748924722,
      "Direction stability factor": 0.9278816975,
      score: 0.0,
    },
  },
  {
    position: [-42.33622582, 96.61189181, 47.87999908],
    "overall score": 0.1157982882,
    "0": {
      velocity: [0.5605304839, 1.0066713074, 1.3005339171],
      direction: [-40.2676727914, -17.9858462861, 25.9829654451],
      "speed factor": 1.0315874784,
      "Uz factor": 0.9149297981,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4858228274,
      "Direction stability factor": 0.8159704493,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4713460562, 1.0875488402, 1.4352472015],
      direction: [-33.738170618, 10.0408786629, 32.0268248212],
      "speed factor": 0.9656081347,
      "Uz factor": 0.9591466212,
      "Directional variance factor": 0.1074774522,
      "Velocity stability factor": 0.3766707211,
      "Direction stability factor": 0.8173194571,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2179131761, 0.6830025204, 1.1475178867],
      direction: [-31.3849484747, -4.4922187468, 37.3107403014],
      "speed factor": 1.0580164365,
      "Uz factor": 0.9918194082,
      "Directional variance factor": 0.600691667,
      "Velocity stability factor": 0.1693475029,
      "Direction stability factor": 0.8091786423,
      score: 0.5970991136,
    },
    "112.5": {
      velocity: [0.0904053911, 0.5852683228, 1.2005909605],
      direction: [-159.1483595777, 23.8490868618, 164.4718477853],
      "speed factor": 0.6583859758,
      "Uz factor": 0.9967325932,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0809672592,
      "Direction stability factor": 0.1010549795,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0796936025, 0.4720294343, 1.0845251317],
      direction: [-173.2309456166, 2.5278508915, 177.6996373089],
      "speed factor": 0.670584222,
      "Uz factor": 0.9863370185,
      "Directional variance factor": 0.775302143,
      "Velocity stability factor": 0.1782573721,
      "Direction stability factor": 0.0251928252,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1317902815, 0.6092563793, 1.1377247991],
      direction: [-75.584271837, -20.3861468951, 69.9889139891],
      "speed factor": 0.7138385442,
      "Uz factor": 0.9998176091,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2265348426,
      "Direction stability factor": 0.5956300394,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0892780038, 0.3882654983, 0.9075942519],
      direction: [-97.2418007018, -5.006007643, 71.5726004274],
      "speed factor": 0.7202792878,
      "Uz factor": 0.9986810811,
      "Directional variance factor": 0.5550215428,
      "Velocity stability factor": 0.205948588,
      "Direction stability factor": 0.531071108,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1749013519, 0.7208060629, 1.1017680568],
      direction: [-83.4388716324, -8.9469043237, 41.6061553867],
      "speed factor": 0.981558144,
      "Uz factor": 0.9906631776,
      "Directional variance factor": 0.2047196157,
      "Velocity stability factor": 0.3202918361,
      "Direction stability factor": 0.6526527027,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7386265098, 0.9571200388, 1.1605061952],
      direction: [-16.4197884402, -4.4532302402, 13.2865116887],
      "speed factor": 1.2154830463,
      "Uz factor": 0.8999396126,
      "Directional variance factor": 0.604157312,
      "Velocity stability factor": 0.6602035582,
      "Direction stability factor": 0.9174824996,
      score: 0.0,
    },
    id: "IDN:692.0",
    "45": {
      velocity: [0.2897607594, 0.6664858371, 1.0148587023],
      direction: [-36.9717568421, -9.0219692353, 24.3194994629],
      "speed factor": 0.9053420579,
      "Uz factor": 0.9614431118,
      "Directional variance factor": 0.1980471791,
      "Velocity stability factor": 0.4105183169,
      "Direction stability factor": 0.8297465103,
      score: 0.0,
    },
    "90": {
      velocity: [0.2831951815, 0.7692615785, 1.1982661068],
      direction: [-26.9202392999, 26.9839693512, 77.039802271],
      "speed factor": 1.2469955297,
      "Uz factor": 0.9991778471,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3321285932,
      "Direction stability factor": 0.7112221067,
      score: 0.0,
    },
    "135": {
      velocity: [0.1778538518, 0.7585142603, 1.2391313893],
      direction: [-60.2851715376, 7.122529231, 50.7393502634],
      "speed factor": 0.6419687906,
      "Uz factor": 0.9890716672,
      "Directional variance factor": 0.3668862906,
      "Velocity stability factor": 0.1448110883,
      "Direction stability factor": 0.6915985506,
      score: 0.0,
    },
    "180": {
      velocity: [0.0412619019, 0.3014269008, 0.859002121],
      direction: [-178.9138561743, -24.5244111364, 177.4533777619],
      "speed factor": 0.5886848751,
      "Uz factor": 0.8927435382,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3556559678,
      "Direction stability factor": 0.0100910168,
      score: 0.0,
    },
    "225": {
      velocity: [0.1972705469, 0.8079702344, 1.1565617301],
      direction: [-22.9152072768, -0.9342628491, 101.2677972346],
      "speed factor": 1.1029677905,
      "Uz factor": 0.9989621896,
      "Directional variance factor": 0.9169544134,
      "Velocity stability factor": 0.1378076852,
      "Direction stability factor": 0.6550472097,
      score: 0.5912141295,
    },
    "270": {
      velocity: [0.6645479342, 1.1243151487, 1.4080108494],
      direction: [-35.2058681168, -5.6173741678, 10.945133361],
      "speed factor": 1.0287846648,
      "Uz factor": 0.9981576826,
      "Directional variance factor": 0.5006778517,
      "Velocity stability factor": 0.4135540702,
      "Direction stability factor": 0.8718027737,
      score: 0.6644593673,
    },
    "315": {
      velocity: [0.7419137531, 0.9021392818, 1.0709488123],
      direction: [-13.43371417, 4.9455728549, 19.4515748949],
      "speed factor": 1.1115253743,
      "Uz factor": 0.9268156149,
      "Directional variance factor": 0.560393524,
      "Velocity stability factor": 0.733074925,
      "Direction stability factor": 0.9086519748,
      score: 0.0,
    },
  },
  {
    position: [-48.2735305, 95.74678309, 47.87999908],
    "overall score": 0.1153102464,
    "0": {
      velocity: [0.6557013302, 1.1187390878, 1.3435256207],
      direction: [-19.3836277831, -3.6108229728, 31.1279761579],
      "speed factor": 1.1464290541,
      "Uz factor": 0.9450983653,
      "Directional variance factor": 0.679037958,
      "Velocity stability factor": 0.5220785026,
      "Direction stability factor": 0.8596899891,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4628117679, 1.034136076, 1.5748762599],
      direction: [-38.6580197758, 17.746010993, 49.0745663278],
      "speed factor": 0.9181842419,
      "Uz factor": 0.9459136314,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2808574186,
      "Direction stability factor": 0.7562983719,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2480358254, 0.7024142912, 1.2046870148],
      direction: [-35.3976586686, -4.3129701043, 41.4405580265],
      "speed factor": 1.0880865636,
      "Uz factor": 0.9943188226,
      "Directional variance factor": 0.6166248796,
      "Velocity stability factor": 0.1451799993,
      "Direction stability factor": 0.7865605092,
      score: 0.5837314743,
    },
    "112.5": {
      velocity: [0.0645886401, 0.4426222075, 1.1832922635],
      direction: [-171.9515885934, 29.6997127464, 164.2959030871],
      "speed factor": 0.4979190615,
      "Uz factor": 0.9997315975,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.073915852,
      "Direction stability factor": 0.0659791898,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0599666167, 0.3976369765, 1.0028271675],
      direction: [-159.3147734898, -13.545993417, 171.3388997551],
      "speed factor": 0.5648992693,
      "Uz factor": 0.9943865233,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2289367081,
      "Direction stability factor": 0.0815175743,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5436359328, 1.0163189156, 1.4731283461],
      direction: [-47.6283067347, -12.4507579473, 16.8859741808],
      "speed factor": 1.1907755746,
      "Uz factor": 0.9957386107,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2853113367,
      "Direction stability factor": 0.8207936641,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1147472996, 0.4560484683, 0.8911874927],
      direction: [-104.9331903658, -2.751816203, 32.6791866004],
      "speed factor": 0.8460248655,
      "Uz factor": 0.9831987644,
      "Directional variance factor": 0.7553941153,
      "Velocity stability factor": 0.2465829279,
      "Direction stability factor": 0.6177433973,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4159223179, 0.7720167747, 1.1319835814],
      direction: [-58.2903597861, -18.1759069003, 29.6421157189],
      "speed factor": 1.0512943655,
      "Uz factor": 0.9741139566,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4748838382,
      "Direction stability factor": 0.7557431236,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7145609064, 0.94309536, 1.0925804239],
      direction: [-6.5820225285, 4.3281299356, 24.5123670116],
      "speed factor": 1.1976725747,
      "Uz factor": 0.9099554207,
      "Directional variance factor": 0.6152773391,
      "Velocity stability factor": 0.6955300494,
      "Direction stability factor": 0.9136266957,
      score: 0.0,
    },
    id: "IDN:694.0",
    "45": {
      velocity: [0.2666457871, 0.7335333896, 1.2029702427],
      direction: [-29.5729966555, -2.786930708, 31.0330240993],
      "speed factor": 0.9964182156,
      "Uz factor": 0.9773291667,
      "Directional variance factor": 0.752272826,
      "Velocity stability factor": 0.2387978458,
      "Direction stability factor": 0.8316499423,
      score: 0.0,
    },
    "90": {
      velocity: [0.1122294346, 0.6499375112, 1.1691083523],
      direction: [-105.0136449745, 23.5617962929, 144.6658246983],
      "speed factor": 1.0535677249,
      "Uz factor": 0.9989676436,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2286289619,
      "Direction stability factor": 0.3064459176,
      score: 0.0,
    },
    "135": {
      velocity: [0.0539287775, 0.3819115886, 0.9920705009],
      direction: [-176.6253495377, 23.7198594215, 171.4364577631],
      "speed factor": 0.3232309971,
      "Uz factor": 0.9972265944,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2440352584,
      "Direction stability factor": 0.0331616464,
      score: 0.0,
    },
    "180": {
      velocity: [0.0510963674, 0.5128627721, 1.2684945502],
      direction: [-171.9477372777, -30.3841229521, 170.9543355275],
      "speed factor": 1.0016178257,
      "Uz factor": 0.9985925366,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0407427255,
      "Direction stability factor": 0.0474942422,
      score: 0.0,
    },
    "225": {
      velocity: [0.1764915645, 0.9194073298, 1.262479142],
      direction: [-70.7127969827, -0.7075040471, 15.5099252948],
      "speed factor": 1.255091621,
      "Uz factor": 0.9972382629,
      "Directional variance factor": 0.9371107514,
      "Velocity stability factor": 0.0239354227,
      "Direction stability factor": 0.7604924381,
      score: 0.6205077626,
    },
    "270": {
      velocity: [0.7961368998, 1.1363734047, 1.3706059706],
      direction: [-24.9841287176, -9.0946410602, 6.6140785845],
      "speed factor": 1.0398183583,
      "Uz factor": 0.9894237272,
      "Directional variance factor": 0.1915874613,
      "Velocity stability factor": 0.5468569562,
      "Direction stability factor": 0.9122272019,
      score: 0.6407247054,
    },
    "315": {
      velocity: [0.7045164988, 0.851833704, 1.0145049976],
      direction: [-10.9470889629, 6.7073939155, 25.1959761503],
      "speed factor": 1.0495438962,
      "Uz factor": 0.9344425341,
      "Directional variance factor": 0.4037872075,
      "Velocity stability factor": 0.7485261799,
      "Direction stability factor": 0.8996025969,
      score: 0.0,
    },
  },
  {
    position: [-39.36757348, 97.04444617, 46.87999908],
    "overall score": 0.1144142937,
    "0": {
      velocity: [0.5391873613, 0.9834896091, 1.3312790812],
      direction: [-55.1224962215, -27.1147479802, 26.1702626113],
      "speed factor": 1.0078320088,
      "Uz factor": 0.8858254543,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4496302818,
      "Direction stability factor": 0.774186781,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5434306687, 1.1392028217, 1.4394991326],
      direction: [-28.0171849576, 5.4533344307, 22.9415684496],
      "speed factor": 1.0114704472,
      "Uz factor": 0.954179826,
      "Directional variance factor": 0.5152591617,
      "Velocity stability factor": 0.4205363152,
      "Direction stability factor": 0.8584479072,
      score: 0.6631728228,
    },
    "67.5": {
      velocity: [0.2540064845, 0.6659769863, 1.1194427082],
      direction: [-32.7768546532, -4.1564389459, 61.3469592112],
      "speed factor": 1.0316427493,
      "Uz factor": 0.9873559586,
      "Directional variance factor": 0.6305387604,
      "Velocity stability factor": 0.2266855448,
      "Direction stability factor": 0.7385449615,
      score: 0.583578557,
    },
    "112.5": {
      velocity: [0.0817336523, 0.6362589624, 1.1749302069],
      direction: [-178.9139895314, 25.8584827307, 148.929217145],
      "speed factor": 0.7157468831,
      "Uz factor": 0.997396585,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.095031089,
      "Direction stability factor": 0.0893244259,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.049654276, 0.4461438458, 1.081869627],
      direction: [-173.1343621493, 8.7736375415, 178.2615550832],
      "speed factor": 0.6338101017,
      "Uz factor": 0.9938087103,
      "Directional variance factor": 0.2201211074,
      "Velocity stability factor": 0.1558631169,
      "Direction stability factor": 0.0239002299,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0977457475, 0.400879509, 0.9711905613],
      direction: [-139.1471990371, -7.7681086119, 145.168680545],
      "speed factor": 0.469692653,
      "Uz factor": 0.980245487,
      "Directional variance factor": 0.3095014567,
      "Velocity stability factor": 0.3284064533,
      "Direction stability factor": 0.2102336678,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0733857001, 0.352660358, 0.8514192606],
      direction: [-84.3429692509, -13.5959445175, 72.6845486495],
      "speed factor": 0.6542274619,
      "Uz factor": 0.9995129375,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2450368073,
      "Direction stability factor": 0.5638124503,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1689291884, 0.6856824107, 1.1124274722],
      direction: [-72.7099522988, -3.2883602522, 35.5071771773],
      "speed factor": 0.9337284869,
      "Uz factor": 0.9838902494,
      "Directional variance factor": 0.7077013109,
      "Velocity stability factor": 0.3080952388,
      "Direction stability factor": 0.6993968626,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7483880674, 0.9567546654, 1.2251149275],
      direction: [-22.6857845007, -9.1381613229, 11.4326723236],
      "speed factor": 1.2150190447,
      "Uz factor": 0.8939194642,
      "Directional variance factor": 0.1877189935,
      "Velocity stability factor": 0.6160277531,
      "Direction stability factor": 0.9052265088,
      score: 0.0,
    },
    id: "IDN:425.0",
    "45": {
      velocity: [0.3295657034, 0.6298704075, 0.9958395714],
      direction: [-47.3838172583, -13.7051532332, 28.6086456884],
      "speed factor": 0.8556043343,
      "Uz factor": 0.9433109327,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4583404284,
      "Direction stability factor": 0.7889098251,
      score: 0.0,
    },
    "90": {
      velocity: [0.305190964, 0.748320931, 1.2187108355],
      direction: [-16.292110564, 27.9928429069, 91.096516116],
      "speed factor": 1.2130501275,
      "Uz factor": 0.9999812022,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3332606414,
      "Direction stability factor": 0.7016982592,
      score: 0.0,
    },
    "135": {
      velocity: [0.2355949167, 0.9721014429, 1.2579027348],
      direction: [-96.69578025, 6.9201619279, 164.3785151743],
      "speed factor": 0.822738372,
      "Uz factor": 0.9927093533,
      "Directional variance factor": 0.3848744953,
      "Velocity stability factor": 0.1762133096,
      "Direction stability factor": 0.2747936238,
      score: 0.0,
    },
    "180": {
      velocity: [0.0285189639, 0.2416588791, 0.7655483184],
      direction: [-176.1586030516, 39.6004257641, 172.4762747961],
      "speed factor": 0.4719582979,
      "Uz factor": 0.8343477744,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.419252649,
      "Direction stability factor": 0.0315697838,
      score: 0.0,
    },
    "225": {
      velocity: [0.2555195705, 0.7626720138, 1.2067886413],
      direction: [-36.1315818152, -4.568919863, 36.4772352567],
      "speed factor": 1.0411307622,
      "Uz factor": 0.9979816772,
      "Directional variance factor": 0.59387379,
      "Velocity stability factor": 0.1450178043,
      "Direction stability factor": 0.7983088415,
      score: 0.5838773193,
    },
    "270": {
      velocity: [0.3365817199, 0.9638948858, 1.3514072849],
      direction: [-72.2251288418, -3.2546254399, 12.8338392277],
      "speed factor": 0.8819949443,
      "Uz factor": 0.999710246,
      "Directional variance factor": 0.7106999609,
      "Velocity stability factor": 0.1995023426,
      "Direction stability factor": 0.7637250887,
      score: 0.0,
    },
    "315": {
      velocity: [0.7654297375, 0.9140088818, 1.0706593205],
      direction: [-10.8589399387, 2.3936102376, 14.5639931036],
      "speed factor": 1.1261499027,
      "Uz factor": 0.9102715571,
      "Directional variance factor": 0.7872346455,
      "Velocity stability factor": 0.7523867836,
      "Direction stability factor": 0.9293807415,
      score: 0.0,
    },
  },
  {
    position: [-52.35511895, 89.23277832, 45.87999908],
    "overall score": 0.1142884002,
    "0": {
      velocity: [0.0919038001, 0.5881968664, 1.3510104651],
      direction: [-18.6434613485, 5.4442415419, 85.5951729274],
      "speed factor": 0.602755356,
      "Uz factor": 0.9887751129,
      "Directional variance factor": 0.5160674185,
      "Velocity stability factor": 0.1251339171,
      "Direction stability factor": 0.7104482381,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1407755741, 0.4202712796, 0.9362573688],
      direction: [-159.3263720989, 38.0072159153, 178.191048731],
      "speed factor": 0.3731486361,
      "Uz factor": 0.9613382235,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4855830436,
      "Direction stability factor": 0.0624516088,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0830886685, 0.5040633173, 1.0306111403],
      direction: [-64.8714185744, 6.7831002059, 51.4105382504],
      "speed factor": 0.7808276819,
      "Uz factor": 0.9989437136,
      "Directional variance factor": 0.3970577595,
      "Velocity stability factor": 0.1533370063,
      "Direction stability factor": 0.6769945644,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.071475924, 0.3267606186, 0.5872048551],
      direction: [-172.3951832943, 125.4927574215, 176.2136697686],
      "speed factor": 0.3675828681,
      "Uz factor": 0.9346906366,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5730697767,
      "Direction stability factor": 0.0316420748,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0836094921, 0.4053344006, 0.8846581324],
      direction: [-174.4562584968, 5.6450716, 169.8748628337],
      "speed factor": 0.5758345433,
      "Uz factor": 0.9972732093,
      "Directional variance factor": 0.4982158578,
      "Velocity stability factor": 0.3449092752,
      "Direction stability factor": 0.043524663,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.498751203, 1.0093306499, 1.4276249468],
      direction: [-39.3119308213, -5.7987455154, 17.8237374806],
      "speed factor": 1.1825877352,
      "Uz factor": 0.999859813,
      "Directional variance factor": 0.4845559542,
      "Velocity stability factor": 0.2857870329,
      "Direction stability factor": 0.8412898103,
      score: 0.6132306519,
    },
    "247.5": {
      velocity: [0.1887101433, 0.4514614626, 0.8200389983],
      direction: [-47.8836016614, -6.3572424319, 43.1779044615],
      "speed factor": 0.8375154171,
      "Uz factor": 0.8865433166,
      "Directional variance factor": 0.4349117838,
      "Velocity stability factor": 0.3873914029,
      "Direction stability factor": 0.7470513719,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.245829612, 0.8890094501, 1.1938646169],
      direction: [-64.4605206383, -28.5697837036, 7.6631270937],
      "speed factor": 1.2106092204,
      "Uz factor": 0.8006616265,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3047682812,
      "Direction stability factor": 0.7996565341,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0856667195, 0.9354476428, 1.2106392016],
      direction: [-5.1069131144, 6.3733411159, 141.6684689696],
      "speed factor": 1.1879604484,
      "Uz factor": 0.959756553,
      "Directional variance factor": 0.4334807897,
      "Velocity stability factor": 0.0939083825,
      "Direction stability factor": 0.5922906053,
      score: 0.4279925957,
    },
    id: "IDN:344.0",
    "45": {
      velocity: [0.0634184606, 0.3760541906, 0.7191065905],
      direction: [-147.0139900462, 57.0848759692, 174.9704654956],
      "speed factor": 0.5108250707,
      "Uz factor": 0.960534733,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4669462985,
      "Direction stability factor": 0.1055987346,
      score: 0.0,
    },
    "90": {
      velocity: [0.0634550954, 0.2577316895, 0.5973987926],
      direction: [-178.8201525273, -144.7176851182, 179.49263565],
      "speed factor": 0.4177906106,
      "Uz factor": 0.887320761,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6102971711,
      "Direction stability factor": 0.0046866995,
      score: 0.0,
    },
    "135": {
      velocity: [0.039842536, 0.3244432275, 0.6699150242],
      direction: [-171.4026039811, 106.3046966719, 172.8968403818],
      "speed factor": 0.274592631,
      "Uz factor": 0.8466797624,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4922807782,
      "Direction stability factor": 0.0436126545,
      score: 0.0,
    },
    "180": {
      velocity: [0.0660974251, 0.6423970987, 1.1134035574],
      direction: [-131.1868150663, -31.6886421749, 51.47825012],
      "speed factor": 1.2545975653,
      "Uz factor": 0.9918550315,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.174767927,
      "Direction stability factor": 0.4925970411,
      score: 0.0,
    },
    "225": {
      velocity: [0.4786707544, 0.8416405556, 1.1448946759],
      direction: [-16.8640115173, 0.5396818717, 19.7974009019],
      "speed factor": 1.1489314638,
      "Uz factor": 0.9529384318,
      "Directional variance factor": 0.9520282781,
      "Velocity stability factor": 0.4012108574,
      "Direction stability factor": 0.8981627433,
      score: 0.7873911555,
    },
    "270": {
      velocity: [0.8546134663, 1.1276337653, 1.3536317921],
      direction: [-30.3492953859, -4.1270035855, 12.7130698884],
      "speed factor": 1.0318213061,
      "Uz factor": 0.8117770135,
      "Directional variance factor": 0.6331552368,
      "Velocity stability factor": 0.6063727456,
      "Direction stability factor": 0.8803823187,
      score: 0.0,
    },
    "315": {
      velocity: [0.7276364292, 0.9661522593, 1.1700003298],
      direction: [-21.9018288658, 2.9059114442, 18.9544398823],
      "speed factor": 1.190395733,
      "Uz factor": 0.9064548828,
      "Directional variance factor": 0.7416967605,
      "Velocity stability factor": 0.6411384926,
      "Direction stability factor": 0.8865103646,
      score: 0.0,
    },
  },
  {
    position: [3.735970111, 93.61965223, 47.87999908],
    "overall score": 0.1123765481,
    "0": {
      velocity: [0.0533729028, 0.5259605289, 1.1976486201],
      direction: [-178.1247837254, -9.5205207227, 162.5268264522],
      "speed factor": 0.5389786038,
      "Uz factor": 0.9896835414,
      "Directional variance factor": 0.1537314913,
      "Velocity stability factor": 0.2049219956,
      "Direction stability factor": 0.0537455273,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0268918883, 0.6352406317, 1.0962879089],
      direction: [-166.4279038311, -13.3283106983, 163.9047763105],
      "speed factor": 0.5640146895,
      "Uz factor": 0.995120905,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3084499861,
      "Direction stability factor": 0.0824092218,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1076648241, 0.4259960303, 0.9099770202],
      direction: [-39.6247741293, 4.7626469282, 100.1880341636],
      "speed factor": 0.65989625,
      "Uz factor": 0.9970805083,
      "Directional variance factor": 0.5766536064,
      "Velocity stability factor": 0.2830903054,
      "Direction stability factor": 0.6116310881,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5041288547, 0.9233259301, 1.2353038115],
      direction: [-11.7006557375, 11.0445237327, 51.9643309725],
      "speed factor": 1.038677167,
      "Uz factor": 0.9780900321,
      "Directional variance factor": 0.0182645571,
      "Velocity stability factor": 0.3947194568,
      "Direction stability factor": 0.8231528147,
      score: 0.5148224108,
    },
    "157.5": {
      velocity: [0.4263279746, 0.8527111865, 1.1468515877],
      direction: [-15.5271341407, 6.4416712643, 44.8340421734],
      "speed factor": 1.2113962097,
      "Uz factor": 0.9567104978,
      "Directional variance factor": 0.4274069987,
      "Velocity stability factor": 0.4107619536,
      "Direction stability factor": 0.8323300658,
      score: 0.625707271,
    },
    "202.5": {
      velocity: [0.4541652293, 0.8338737684, 1.1280127457],
      direction: [-55.5400494188, -15.218680379, 19.1772312869],
      "speed factor": 0.9770127275,
      "Uz factor": 0.9125035701,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4818772333,
      "Direction stability factor": 0.792451998,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2359428469, 0.5467663489, 0.8187697481],
      direction: [-45.9388765895, -4.6941822634, 23.7596747094],
      "speed factor": 1.014317466,
      "Uz factor": 0.9925363114,
      "Directional variance factor": 0.5827393544,
      "Velocity stability factor": 0.4344551694,
      "Direction stability factor": 0.8063929131,
      score: 0.6574950875,
    },
    "292.5": {
      velocity: [0.0633983099, 0.3529971212, 0.8783577084],
      direction: [-167.8371790825, -3.1341388159, 119.3164378806],
      "speed factor": 0.4806940687,
      "Uz factor": 0.990367819,
      "Directional variance factor": 0.721409883,
      "Velocity stability factor": 0.4023579082,
      "Direction stability factor": 0.202351064,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1116675918, 0.4567803522, 0.8835485655],
      direction: [-107.9632608224, 9.0285019059, 171.5392251073],
      "speed factor": 0.5800826975,
      "Uz factor": 0.9999999459,
      "Directional variance factor": 0.1974664972,
      "Velocity stability factor": 0.3783004553,
      "Direction stability factor": 0.2236042058,
      score: 0.0,
    },
    id: "IDN:676.0",
    "45": {
      velocity: [0.0724995061, 0.6429811347, 1.0627809654],
      direction: [-141.3187237402, -0.9069944432, 140.7676113507],
      "speed factor": 0.8734137041,
      "Uz factor": 0.9999797886,
      "Directional variance factor": 0.9193782717,
      "Velocity stability factor": 0.1949325092,
      "Direction stability factor": 0.216426847,
      score: 0.0,
    },
    "90": {
      velocity: [0.4074894538, 0.8746040189, 1.1550934013],
      direction: [-11.1963242303, 12.349096157, 39.5327498101],
      "speed factor": 1.4177587082,
      "Uz factor": 0.9912719052,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4543556283,
      "Direction stability factor": 0.8590859054,
      score: 0.0,
    },
    "135": {
      velocity: [0.6134369657, 1.1585401874, 1.3538607122],
      direction: [-5.7372431713, 7.5071887758, 22.2534686176],
      "speed factor": 0.9805308639,
      "Uz factor": 0.9490336988,
      "Directional variance factor": 0.332694331,
      "Velocity stability factor": 0.4033585414,
      "Direction stability factor": 0.9222480228,
      score: 0.0,
    },
    "180": {
      velocity: [0.4438631806, 0.7647549427, 1.1196591715],
      direction: [-29.2650799051, 2.3184717859, 34.3123349378],
      "speed factor": 1.4935616786,
      "Uz factor": 0.9343828865,
      "Directional variance factor": 0.793913619,
      "Velocity stability factor": 0.4675018992,
      "Direction stability factor": 0.8233960699,
      score: 0.0,
    },
    "225": {
      velocity: [0.1700391703, 0.4942281659, 0.708728545],
      direction: [-83.8817740728, -11.423719184, 48.8998518818],
      "speed factor": 0.6746755326,
      "Uz factor": 0.9766450001,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5158364353,
      "Direction stability factor": 0.6311621501,
      score: 0.0,
    },
    "270": {
      velocity: [0.1588312343, 0.878996248, 1.3905956878],
      direction: [-36.6291811939, 5.6784916286, 37.1014113483],
      "speed factor": 0.8043099494,
      "Uz factor": 0.9999709839,
      "Directional variance factor": 0.4952451886,
      "Velocity stability factor": 0.0283802522,
      "Direction stability factor": 0.7951927985,
      score: 0.0,
    },
    "315": {
      velocity: [0.1028830794, 0.4257268124, 0.9859982089],
      direction: [-153.9044195278, -19.7392632276, 27.7731820404],
      "speed factor": 0.5245378003,
      "Uz factor": 0.9873029629,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2835852424,
      "Direction stability factor": 0.4953399956,
      score: 0.0,
    },
  },
  {
    position: [-52.79462529, 83.24889712, 45.87999908],
    "overall score": 0.1120442251,
    "0": {
      velocity: [0.2876221699, 0.8778489805, 1.5391389381],
      direction: [-88.3519295064, -4.9609629037, 88.9569292702],
      "speed factor": 0.8995766638,
      "Uz factor": 0.9927491134,
      "Directional variance factor": 0.5590255197,
      "Velocity stability factor": 0.1304076111,
      "Direction stability factor": 0.5074753923,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1213585332, 0.5399719954, 1.211826021],
      direction: [-145.6013768462, 25.5139727968, 145.1512843907],
      "speed factor": 0.4794279871,
      "Uz factor": 0.9754233723,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2948236278,
      "Direction stability factor": 0.1923537188,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0520309233, 0.3602763149, 0.9269584585],
      direction: [-159.828963513, -1.6048649228, 176.4499479987],
      "speed factor": 0.5580920296,
      "Uz factor": 0.9999918271,
      "Directional variance factor": 0.8573453402,
      "Velocity stability factor": 0.2182045405,
      "Direction stability factor": 0.0658919125,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0830571574, 0.3244443856, 0.9534584975],
      direction: [-169.4123211232, -9.6316290248, 175.5919626723],
      "speed factor": 0.3649772679,
      "Uz factor": 0.9998692722,
      "Directional variance factor": 0.1438551978,
      "Velocity stability factor": 0.2794652072,
      "Direction stability factor": 0.0416547672,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0593412065, 0.4320901131, 0.9926991922],
      direction: [-178.4723602029, -30.2744863563, 175.9075710633],
      "speed factor": 0.6138447972,
      "Uz factor": 0.9977710756,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2367078245,
      "Direction stability factor": 0.015611302,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.420729792, 0.9648534363, 1.3499949335],
      direction: [-56.6013117652, -8.7991736295, 24.1851378074],
      "speed factor": 1.1304757664,
      "Uz factor": 0.9999256766,
      "Directional variance factor": 0.2178512329,
      "Velocity stability factor": 0.2854860864,
      "Direction stability factor": 0.7755931956,
      score: 0.5136309276,
    },
    "247.5": {
      velocity: [0.2099375218, 0.4741579495, 0.776935775],
      direction: [-32.1486903505, -2.2309812147, 53.6316326785],
      "speed factor": 0.8796201354,
      "Uz factor": 0.9304404608,
      "Directional variance factor": 0.8016905587,
      "Velocity stability factor": 0.449814464,
      "Direction stability factor": 0.7617213249,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3798170321, 0.96886825, 1.2360656803],
      direction: [-55.6096391908, -14.2045053315, 23.2173816456],
      "speed factor": 1.3193569952,
      "Uz factor": 0.7991244826,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3720788616,
      "Direction stability factor": 0.7810360532,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3411772485, 0.965319169, 1.2284038777],
      direction: [-6.3998201305, 4.7169182156, 63.7686751662],
      "speed factor": 1.2258954328,
      "Uz factor": 0.9643669043,
      "Directional variance factor": 0.5807183808,
      "Velocity stability factor": 0.2853970881,
      "Direction stability factor": 0.8050875131,
      score: 0.6190726238,
    },
    id: "IDN:346.0",
    "45": {
      velocity: [0.0902404316, 0.4063398066, 0.7303365813],
      direction: [-128.050909892, 11.7192022816, 172.9795720332],
      "speed factor": 0.5519644923,
      "Uz factor": 0.9991726107,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4796220849,
      "Direction stability factor": 0.1638042169,
      score: 0.0,
    },
    "90": {
      velocity: [0.035666569, 0.2586895272, 0.6910110168],
      direction: [-172.9186756573, -24.6867415172, 161.1469051408],
      "speed factor": 0.4193432936,
      "Uz factor": 0.9647607149,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.521691919,
      "Direction stability factor": 0.0720400533,
      score: 0.0,
    },
    "135": {
      velocity: [0.0984232944, 0.3931049787, 1.2132387902],
      direction: [-125.5982490654, -27.3503192318, 139.695328883],
      "speed factor": 0.3327045264,
      "Uz factor": 0.997863232,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1016696228,
      "Direction stability factor": 0.2630733946,
      score: 0.0,
    },
    "180": {
      velocity: [0.1101894097, 0.7097588759, 1.0260671681],
      direction: [-100.2581669604, -40.6119827853, 23.2814555413],
      "speed factor": 1.3861546999,
      "Uz factor": 0.9975376729,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2783278185,
      "Direction stability factor": 0.6568343819,
      score: 0.0,
    },
    "225": {
      velocity: [0.5309049113, 0.8163340277, 1.1176031641],
      direction: [-13.8932829974, 6.4164785685, 33.3238010984],
      "speed factor": 1.1143852837,
      "Uz factor": 0.9546809456,
      "Directional variance factor": 0.4296463495,
      "Velocity stability factor": 0.4726869865,
      "Direction stability factor": 0.8688414331,
      score: 0.6600040505,
    },
    "270": {
      velocity: [0.8569055002, 1.0753649816, 1.2915928257],
      direction: [-19.1647328528, 4.9073096664, 25.1510733051],
      "speed factor": 0.9839936813,
      "Uz factor": 0.8370362096,
      "Directional variance factor": 0.5637946963,
      "Velocity stability factor": 0.6571172448,
      "Direction stability factor": 0.8769005385,
      score: 0.0,
    },
    "315": {
      velocity: [0.6468309799, 0.969334204, 1.2442727532],
      direction: [-17.1017333906, 4.5297945501, 17.5202878031],
      "speed factor": 1.1943162056,
      "Uz factor": 0.9063755746,
      "Directional variance factor": 0.5973515956,
      "Velocity stability factor": 0.5153337443,
      "Direction stability factor": 0.9038277189,
      score: 0.0,
    },
  },
  {
    position: [-54.55578191, 52.86786819, 46.87999908],
    "overall score": 0.1117406983,
    "0": {
      velocity: [0.3029926826, 1.0857302106, 1.3652102748],
      direction: [-22.8554780834, -6.7213787742, 32.490305839],
      "speed factor": 1.1126031725,
      "Uz factor": 0.990595938,
      "Directional variance factor": 0.402544109,
      "Velocity stability factor": 0.2619385078,
      "Direction stability factor": 0.8462617113,
      score: 0.5892515098,
    },
    "22.5": {
      velocity: [0.2449628771, 0.6755530985, 1.1992935823],
      direction: [-148.8172579332, 4.5415737194, 79.6536666936],
      "speed factor": 0.5998071473,
      "Uz factor": 0.9987380395,
      "Directional variance factor": 0.5963045583,
      "Velocity stability factor": 0.3828596706,
      "Direction stability factor": 0.3653585427,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0705262053, 0.4016115371, 0.9621678152],
      direction: [-166.1138820269, 13.8337910236, 139.9674850978],
      "speed factor": 0.6221230444,
      "Uz factor": 0.9854606116,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2032696034,
      "Direction stability factor": 0.1497739802,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0551983868, 0.4193081704, 1.1201801205],
      direction: [-163.5858511826, -19.1810527899, 168.4992536401],
      "speed factor": 0.4716923985,
      "Uz factor": 0.9655385734,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1183878546,
      "Direction stability factor": 0.0775413755,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0628021311, 0.4966541528, 1.1065803283],
      direction: [-97.5440235031, -7.7950881727, 134.9480275045],
      "speed factor": 0.7055670992,
      "Uz factor": 0.9995267396,
      "Directional variance factor": 0.3071032735,
      "Velocity stability factor": 0.1464071202,
      "Direction stability factor": 0.3541887472,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3914625126, 0.9048676185, 1.1889662056],
      direction: [-37.9217415493, -7.4203570313, 16.5171972408],
      "speed factor": 1.0601930574,
      "Uz factor": 0.9968939264,
      "Directional variance factor": 0.3404127083,
      "Velocity stability factor": 0.3867977401,
      "Direction stability factor": 0.8487807256,
      score: 0.6061929749,
    },
    "247.5": {
      velocity: [0.5081944921, 0.7447841895, 0.9439574771],
      direction: [-32.1469923146, -10.9159854831, 10.5529232387],
      "speed factor": 1.3816644228,
      "Uz factor": 0.9855793287,
      "Directional variance factor": 0.0296901793,
      "Velocity stability factor": 0.5771583244,
      "Direction stability factor": 0.8813891235,
      score: 0.5924066876,
    },
    "292.5": {
      velocity: [0.6580465021, 1.0770510691, 1.2493040302],
      direction: [-22.2044007717, 1.2724584906, 14.8200985012],
      "speed factor": 1.4666750223,
      "Uz factor": 0.901873246,
      "Directional variance factor": 0.8868925786,
      "Velocity stability factor": 0.5664073737,
      "Direction stability factor": 0.8971541687,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6387870798, 0.8979212293, 1.1213302503],
      direction: [-23.0675868672, -2.4138329494, 10.2592748732],
      "speed factor": 1.1403042323,
      "Uz factor": 0.9483259569,
      "Directional variance factor": 0.7854370712,
      "Velocity stability factor": 0.6113430961,
      "Direction stability factor": 0.9074253841,
      score: 0.0,
    },
    id: "IDN:468.0",
    "45": {
      velocity: [0.0649674386, 0.4875986883, 1.0325225468],
      direction: [-142.4118056863, 16.5724053489, 166.7520933014],
      "speed factor": 0.6623450573,
      "Uz factor": 0.9982718132,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2134083135,
      "Direction stability factor": 0.1412113917,
      score: 0.0,
    },
    "90": {
      velocity: [0.091185219, 0.5060042256, 1.0272524357],
      direction: [-165.8000372674, -3.3782814812, 166.9487151726],
      "speed factor": 0.820247657,
      "Uz factor": 0.95523914,
      "Directional variance factor": 0.6997083128,
      "Velocity stability factor": 0.3168042917,
      "Direction stability factor": 0.0756979099,
      score: 0.0,
    },
    "135": {
      velocity: [0.0581205727, 0.4051591007, 1.0319032071],
      direction: [-133.1056609835, -36.7217583975, 178.3350252272],
      "speed factor": 0.3429065365,
      "Uz factor": 0.9931996757,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.215315427,
      "Direction stability factor": 0.1348869827,
      score: 0.0,
    },
    "180": {
      velocity: [0.1432317582, 0.5610793943, 0.9455566757],
      direction: [-102.6391652982, -16.5937662848, 44.2890189171],
      "speed factor": 1.095784591,
      "Uz factor": 0.9959754789,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3678025608,
      "Direction stability factor": 0.591866155,
      score: 0.0,
    },
    "225": {
      velocity: [0.2039949895, 0.5523853815, 0.8418653292],
      direction: [-60.8099950239, -3.5045496607, 37.2900611735],
      "speed factor": 0.7540664963,
      "Uz factor": 0.9825266489,
      "Directional variance factor": 0.6884844746,
      "Velocity stability factor": 0.4266945071,
      "Direction stability factor": 0.7274998439,
      score: 0.0,
    },
    "270": {
      velocity: [0.7445330006, 1.0017411802, 1.3581254847],
      direction: [-12.2403212855, 6.6343510348, 30.7977838231],
      "speed factor": 0.9166255257,
      "Uz factor": 0.9119944892,
      "Directional variance factor": 0.410279908,
      "Velocity stability factor": 0.5159962824,
      "Direction stability factor": 0.880449708,
      score: 0.0,
    },
    "315": {
      velocity: [0.6878601689, 1.0288442812, 1.2228225918],
      direction: [-13.0637921177, -2.2193097556, 22.2269454375],
      "speed factor": 1.267638543,
      "Uz factor": 0.9396448757,
      "Directional variance factor": 0.8027280217,
      "Velocity stability factor": 0.5660192407,
      "Direction stability factor": 0.9019701735,
      score: 0.0,
    },
  },
  {
    position: [-50.07556487, 49.15221146, 46.87999908],
    "overall score": 0.1108031917,
    "0": {
      velocity: [0.0964002189, 0.7857013782, 1.2682893362],
      direction: [-48.8328301476, -14.5414210162, 42.541091371],
      "speed factor": 0.8051483117,
      "Uz factor": 0.9979539967,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1857353548,
      "Direction stability factor": 0.7461835513,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1054948883, 0.6614624799, 1.2057047744],
      direction: [-72.6093222976, 11.143164831, 108.2104901396],
      "speed factor": 0.5872964301,
      "Uz factor": 0.985269565,
      "Directional variance factor": 0.0094964595,
      "Velocity stability factor": 0.2885234774,
      "Direction stability factor": 0.4977227432,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1356310812, 0.5214970746, 1.013839043],
      direction: [-36.3643179402, 6.7667192701, 50.7645227718],
      "speed factor": 0.8078337343,
      "Uz factor": 0.9636590084,
      "Directional variance factor": 0.3985138427,
      "Velocity stability factor": 0.2152733005,
      "Direction stability factor": 0.7579754425,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2118433292, 0.7358001474, 1.1767300987],
      direction: [-56.6072880679, -24.1193505126, 2.5649915565],
      "speed factor": 0.8277237622,
      "Uz factor": 0.9299817099,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2012483707,
      "Direction stability factor": 0.8356325566,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0998762798, 0.7991473505, 1.206461194],
      direction: [-78.7906009105, -9.9049201409, 62.4926038209],
      "speed factor": 1.1353012447,
      "Uz factor": 0.9991848797,
      "Directional variance factor": 0.1195626541,
      "Velocity stability factor": 0.0950443243,
      "Direction stability factor": 0.6075466535,
      score: 0.3574250714,
    },
    "202.5": {
      velocity: [0.0912637717, 0.5271126244, 1.1638548977],
      direction: [-128.4523695101, -8.1512109669, 33.9931339522],
      "speed factor": 0.6175943678,
      "Uz factor": 0.9996946076,
      "Directional variance factor": 0.2754479141,
      "Velocity stability factor": 0.1752824368,
      "Direction stability factor": 0.5487624904,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2638700919, 0.6834903761, 1.070603204],
      direction: [-14.8141864483, -1.8196312899, 15.8879579],
      "speed factor": 1.2679570125,
      "Uz factor": 0.9936072541,
      "Directional variance factor": 0.8382549965,
      "Velocity stability factor": 0.2171882591,
      "Direction stability factor": 0.9147162657,
      score: 0.7212189467,
    },
    "292.5": {
      velocity: [0.2474016734, 0.8460741763, 1.1426551683],
      direction: [-33.4080315141, 1.5623735574, 43.5904266754],
      "speed factor": 1.1521420822,
      "Uz factor": 0.969854787,
      "Directional variance factor": 0.8611223505,
      "Velocity stability factor": 0.3434750585,
      "Direction stability factor": 0.7861153939,
      score: 0.6942070492,
    },
    "337.5": {
      velocity: [0.1015089245, 0.728657188, 1.1989619905],
      direction: [-27.8850908622, -6.7226387386, 70.0282524716],
      "speed factor": 0.9253494052,
      "Uz factor": 0.9922633692,
      "Directional variance factor": 0.4024321121,
      "Velocity stability factor": 0.116073469,
      "Direction stability factor": 0.7280184907,
      score: 0.0,
    },
    id: "IDN:475.0",
    "45": {
      velocity: [0.1068638057, 0.5385926611, 1.0194477735],
      direction: [-124.1087518307, 6.1314015562, 97.0555082821],
      "speed factor": 0.7316143286,
      "Uz factor": 0.9666294053,
      "Directional variance factor": 0.4549865283,
      "Velocity stability factor": 0.2580981112,
      "Direction stability factor": 0.385654833,
      score: 0.0,
    },
    "90": {
      velocity: [0.5976370504, 1.037099391, 1.4010246038],
      direction: [-40.0991843136, -10.2440221799, 17.888515988],
      "speed factor": 1.6811684616,
      "Uz factor": 0.9466202641,
      "Directional variance factor": 0.0894202507,
      "Velocity stability factor": 0.4136415433,
      "Direction stability factor": 0.8389230547,
      score: 0.0,
    },
    "135": {
      velocity: [0.2819715746, 0.6482056183, 0.9955874318],
      direction: [-120.0868171232, -42.4627501611, 22.4428191166],
      "speed factor": 0.5486090356,
      "Uz factor": 0.9535990404,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4249606283,
      "Direction stability factor": 0.6040843438,
      score: 0.0,
    },
    "180": {
      velocity: [0.0446588819, 0.3593871353, 0.8976974979],
      direction: [-176.4935683116, -26.3167368907, 178.8661809746],
      "speed factor": 0.701880855,
      "Uz factor": 0.999999637,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3278423531,
      "Direction stability factor": 0.0128895853,
      score: 0.0,
    },
    "225": {
      velocity: [0.0475428464, 0.4328007452, 0.8468197858],
      direction: [-130.1925736902, 3.4596088593, 122.8587032386],
      "speed factor": 0.5908203809,
      "Uz factor": 0.9978208209,
      "Directional variance factor": 0.6924792125,
      "Velocity stability factor": 0.2816253849,
      "Direction stability factor": 0.2970797863,
      score: 0.0,
    },
    "270": {
      velocity: [0.1377831974, 0.6666471874, 1.1615042877],
      direction: [-10.1352536136, 13.6810173431, 92.0526428625],
      "speed factor": 0.6100037023,
      "Uz factor": 0.9723074764,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1924855237,
      "Direction stability factor": 0.716144732,
      score: 0.0,
    },
    "315": {
      velocity: [0.1479031308, 0.6876364193, 1.2074166922],
      direction: [-17.5766148537, -5.2309068709, 68.9427101579],
      "speed factor": 0.8472365008,
      "Uz factor": 0.9798894512,
      "Directional variance factor": 0.5350305004,
      "Velocity stability factor": 0.1404844897,
      "Direction stability factor": 0.7596685416,
      score: 0.0,
    },
  },
  {
    position: [-5.248061244, 98.21002125, 47.87999908],
    "overall score": 0.1095432958,
    "0": {
      velocity: [0.7148382559, 1.1817858825, 1.5147851563],
      direction: [-34.523575868, 7.1698250046, 30.6649009016],
      "speed factor": 1.211036323,
      "Uz factor": 0.980428752,
      "Directional variance factor": 0.3626822218,
      "Velocity stability factor": 0.4441722606,
      "Direction stability factor": 0.8189208979,
      score: 0.6111740695,
    },
    "22.5": {
      velocity: [0.6654817021, 1.088501512, 1.4260637433],
      direction: [-20.2169099294, -2.1478233033, 15.9013930303],
      "speed factor": 0.9664539888,
      "Uz factor": 0.9702422338,
      "Directional variance factor": 0.809082373,
      "Velocity stability factor": 0.5081517876,
      "Direction stability factor": 0.8996713807,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0790491415, 0.4989545862, 0.918404549],
      direction: [-48.7361427512, -0.5973354108, 60.6368392011],
      "speed factor": 0.7729139169,
      "Uz factor": 0.9978160196,
      "Directional variance factor": 0.946903519,
      "Velocity stability factor": 0.2499901762,
      "Direction stability factor": 0.6961861612,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1344319326, 0.8300371137, 1.2766765427],
      direction: [-48.2484712321, 17.6050380801, 154.5445357934],
      "speed factor": 0.9337337658,
      "Uz factor": 0.9980463968,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0544281753,
      "Direction stability factor": 0.4366860916,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3196678098, 0.8224408131, 1.250752883],
      direction: [-53.4516240478, 1.4704612652, 44.2205804727],
      "speed factor": 1.1683928855,
      "Uz factor": 0.9896573258,
      "Directional variance factor": 0.869292332,
      "Velocity stability factor": 0.2385665929,
      "Direction stability factor": 0.7286883208,
      score: 0.6413088916,
    },
    "202.5": {
      velocity: [0.1169219894, 0.758447563, 1.260262581],
      direction: [-178.6345563694, -26.3140655539, 139.2914840415],
      "speed factor": 0.8886392045,
      "Uz factor": 0.9857155617,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1208830245,
      "Direction stability factor": 0.11687211,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2224050404, 0.5153842011, 0.8877212947],
      direction: [-43.0316854845, -4.4539343718, 33.4812786024],
      "speed factor": 0.9560998,
      "Uz factor": 0.9896579747,
      "Directional variance factor": 0.6040947225,
      "Velocity stability factor": 0.3544118031,
      "Direction stability factor": 0.7874639886,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0884624583, 0.5267514995, 0.9787585983],
      direction: [-162.026619083, 19.8211167184, 130.8138195406],
      "speed factor": 0.717304211,
      "Uz factor": 0.9107096957,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3471104837,
      "Direction stability factor": 0.1865543372,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1862639111, 0.5632270913, 1.208815452],
      direction: [-99.1501380338, -16.060683923, 111.2663224347],
      "speed factor": 0.7152634497,
      "Uz factor": 0.8145905274,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1764017393,
      "Direction stability factor": 0.415509832,
      score: 0.0,
    },
    id: "IDN:671.0",
    "45": {
      velocity: [0.1137572022, 0.5757679736, 1.1308950549],
      direction: [-92.0649529971, -1.6254838061, 110.1754794947],
      "speed factor": 0.7821125869,
      "Uz factor": 0.9871105904,
      "Directional variance factor": 0.8555125506,
      "Velocity stability factor": 0.1730991112,
      "Direction stability factor": 0.4382210209,
      score: 0.0,
    },
    "90": {
      velocity: [0.2571359764, 0.8051588523, 1.1393796277],
      direction: [-11.6472985828, 19.5771323416, 76.7312702706],
      "speed factor": 1.3051860611,
      "Uz factor": 0.9996485443,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3560878264,
      "Direction stability factor": 0.7545039754,
      score: 0.0,
    },
    "135": {
      velocity: [0.1745602334, 1.070417106, 1.3790112624],
      direction: [0.9436961989, 10.45376841, 40.2616372557],
      "speed factor": 0.9059478654,
      "Uz factor": 0.9763040511,
      "Directional variance factor": 0.0707761413,
      "Velocity stability factor": 0.0294403412,
      "Direction stability factor": 0.8907834971,
      score: 0.0,
    },
    "180": {
      velocity: [0.0527719988, 0.826149923, 1.2750018444],
      direction: [-83.1360825353, -3.9862417605, 39.5814187959],
      "speed factor": 1.6134656959,
      "Uz factor": 0.9898713626,
      "Directional variance factor": 0.6456673991,
      "Velocity stability factor": 0.0369355836,
      "Direction stability factor": 0.6591180519,
      score: 0.5002097716,
    },
    "225": {
      velocity: [0.1247670457, 0.5391504058, 0.807392417],
      direction: [-88.0588553594, -13.9500843369, 105.5710376739],
      "speed factor": 0.7359993061,
      "Uz factor": 0.9972976173,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3864695524,
      "Direction stability factor": 0.462139186,
      score: 0.0,
    },
    "270": {
      velocity: [0.3960614538, 0.9139224268, 1.2816535798],
      direction: [-55.7934545394, 3.5408217257, 21.5116846357],
      "speed factor": 0.8362685307,
      "Uz factor": 0.9931167756,
      "Directional variance factor": 0.6852602911,
      "Velocity stability factor": 0.3014420933,
      "Direction stability factor": 0.7852635023,
      score: 0.0,
    },
    "315": {
      velocity: [0.7607479298, 0.9366834197, 1.1534494164],
      direction: [-16.2653194559, -2.5639796792, 18.456455078],
      "speed factor": 1.15408719,
      "Uz factor": 0.9468987554,
      "Directional variance factor": 0.7720906952,
      "Velocity stability factor": 0.6814264291,
      "Direction stability factor": 0.9035506263,
      score: 0.0,
    },
  },
  {
    position: [-45.83106275, 46.13737702, 46.87999908],
    "overall score": 0.1089180763,
    "0": {
      velocity: [0.2530085273, 0.830085266, 1.3049673315],
      direction: [-75.2078980983, -12.7868505547, 133.7321777855],
      "speed factor": 0.8506307473,
      "Uz factor": 0.997447562,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2690666295,
      "Direction stability factor": 0.4196109003,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2359934813, 0.792781024, 1.2759485248],
      direction: [-85.6724564321, -1.926579151, 101.4519380061],
      "speed factor": 0.7038909679,
      "Uz factor": 0.9973086286,
      "Directional variance factor": 0.8287485199,
      "Velocity stability factor": 0.3274886844,
      "Direction stability factor": 0.4802100154,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2839861908, 0.5897746575, 0.9597403187],
      direction: [-39.7556215073, 9.1543418247, 75.5841676037],
      "speed factor": 0.9136002618,
      "Uz factor": 0.9508568934,
      "Directional variance factor": 0.1862807267,
      "Velocity stability factor": 0.3961768402,
      "Direction stability factor": 0.6796116969,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5619619607, 0.8792446128, 1.1384848373],
      direction: [-40.3270797784, -12.5238920084, 10.4873247384],
      "speed factor": 0.9890887646,
      "Uz factor": 0.9061546342,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5227433917,
      "Direction stability factor": 0.8588488763,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5583665568, 0.9866687943, 1.3000638696],
      direction: [-27.5912857586, -4.4469521339, 16.416327222],
      "speed factor": 1.4017018384,
      "Uz factor": 0.9822011627,
      "Directional variance factor": 0.6047153659,
      "Velocity stability factor": 0.3934462831,
      "Direction stability factor": 0.8777566306,
      score: 0.6884187276,
    },
    "202.5": {
      velocity: [0.0613481234, 0.3869616407, 0.9602348654],
      direction: [-156.9244279621, -20.7360335659, 164.0886708438],
      "speed factor": 0.453385707,
      "Uz factor": 0.9784327461,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3088441014,
      "Direction stability factor": 0.1082969478,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1896600055, 0.5595334228, 1.0017468849],
      direction: [-36.7648073799, 0.9085777328, 15.6853265009],
      "speed factor": 1.0380019267,
      "Uz factor": 0.9994032454,
      "Directional variance factor": 0.9192375349,
      "Velocity stability factor": 0.2119932425,
      "Direction stability factor": 0.8543051837,
      score: 0.7099602862,
    },
    "292.5": {
      velocity: [0.029688746, 0.2287737441, 0.6332649943],
      direction: [-167.5236039665, 35.6944163585, 168.5340386289],
      "speed factor": 0.3115328009,
      "Uz factor": 0.9732891192,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.557373567,
      "Direction stability factor": 0.0665065483,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0819764177, 0.4015270224, 1.1079552259],
      direction: [-179.7750719696, -0.9829858436, 140.9903853843],
      "speed factor": 0.509914398,
      "Uz factor": 0.9948088211,
      "Directional variance factor": 0.9126234806,
      "Velocity stability factor": 0.1736413001,
      "Direction stability factor": 0.1089848407,
      score: 0.0,
    },
    id: "IDN:478.0",
    "45": {
      velocity: [0.1407199448, 0.5536202416, 1.0824290251],
      direction: [-134.951246121, 3.3911710515, 179.0274482386],
      "speed factor": 0.7520275165,
      "Uz factor": 0.9774982333,
      "Directional variance factor": 0.6985625732,
      "Velocity stability factor": 0.2344203164,
      "Direction stability factor": 0.1278369601,
      score: 0.0,
    },
    "90": {
      velocity: [0.6388413768, 1.0123724769, 1.2919208748],
      direction: [-46.4076830387, -2.4926134758, 32.442935776],
      "speed factor": 1.6410854103,
      "Uz factor": 0.9338453693,
      "Directional variance factor": 0.7784343577,
      "Velocity stability factor": 0.5233450096,
      "Direction stability factor": 0.7809705033,
      score: 0.0,
    },
    "135": {
      velocity: [0.3411599388, 0.6845996111, 1.0982118243],
      direction: [-174.2973248881, -29.1051824801, 26.3571949826],
      "speed factor": 0.5794111032,
      "Uz factor": 0.9275011156,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3899594072,
      "Direction stability factor": 0.4426263337,
      score: 0.0,
    },
    "180": {
      velocity: [0.1758263138, 0.7654732734, 1.1828070006],
      direction: [-135.5895780385, -7.2747302964, 77.2890636756],
      "speed factor": 1.4949645741,
      "Uz factor": 0.9963550289,
      "Directional variance factor": 0.353357307,
      "Velocity stability factor": 0.2065426392,
      "Direction stability factor": 0.4086704397,
      score: 0.3443102064,
    },
    "225": {
      velocity: [0.0764340555, 0.4282952273, 0.8853360871],
      direction: [-72.9453951762, -1.0944589784, 113.4578579267],
      "speed factor": 0.5846698559,
      "Uz factor": 0.9998986421,
      "Directional variance factor": 0.9027147575,
      "Velocity stability factor": 0.2729745387,
      "Direction stability factor": 0.4822131858,
      score: 0.0,
    },
    "270": {
      velocity: [0.0623843408, 0.459584426, 1.0100550284],
      direction: [-126.7534260317, 27.5024852663, 165.6229360795],
      "speed factor": 0.4205345895,
      "Uz factor": 0.9944896289,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.252474325,
      "Direction stability factor": 0.1878434386,
      score: 0.0,
    },
    "315": {
      velocity: [0.0588293476, 0.2852763689, 1.1058876834],
      direction: [-172.8947049828, 19.0339772351, 177.7830300585],
      "speed factor": 0.3514888767,
      "Uz factor": 0.9826114955,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1505886167,
      "Direction stability factor": 0.0258951804,
      score: 0.0,
    },
  },
  {
    position: [-48.2735305, 95.74678309, 46.87999908],
    "overall score": 0.1072549622,
    "0": {
      velocity: [0.6281401806, 1.1158306419, 1.3421357831],
      direction: [-20.3410132377, -3.3543279321, 33.4551229596],
      "speed factor": 1.1434486211,
      "Uz factor": 0.9215832513,
      "Directional variance factor": 0.7018375171,
      "Velocity stability factor": 0.5038938691,
      "Direction stability factor": 0.8505662883,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.45276041, 1.0877260401, 1.5551091308],
      direction: [-39.3281238243, 20.9732947479, 53.0025322327],
      "speed factor": 0.9657654662,
      "Uz factor": 0.938347137,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2871403499,
      "Direction stability factor": 0.7435259554,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2335921782, 0.6903261188, 1.2115787527],
      direction: [-32.2382612965, -4.6478607264, 48.6346183543],
      "speed factor": 1.0693611787,
      "Uz factor": 0.9910754323,
      "Directional variance factor": 0.5868568243,
      "Velocity stability factor": 0.1261156694,
      "Direction stability factor": 0.7753531121,
      score: 0.5659196795,
    },
    "112.5": {
      velocity: [0.0713477723, 0.3722264055, 1.0587595505],
      direction: [-177.4705344421, 44.4931774674, 169.8468843507],
      "speed factor": 0.4187287021,
      "Uz factor": 0.9926864574,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.18260174,
      "Direction stability factor": 0.0352293922,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0512774377, 0.3092033199, 0.7845415626],
      direction: [-179.5843887848, 2.1604097781, 179.0022444425],
      "speed factor": 0.4392668182,
      "Uz factor": 0.9516271328,
      "Directional variance factor": 0.8079635753,
      "Velocity stability factor": 0.400342872,
      "Direction stability factor": 0.0039260188,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.398039027, 0.9856721384, 1.479006558],
      direction: [-51.4366045062, -11.6530457698, 23.3231900298],
      "speed factor": 1.1548681118,
      "Uz factor": 0.9951795189,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1688418014,
      "Direction stability factor": 0.7923339041,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0899868265, 0.4093488881, 0.8797774138],
      direction: [-138.8573048745, -4.7217089049, 163.0114349296],
      "speed factor": 0.7593915165,
      "Uz factor": 0.9803487399,
      "Directional variance factor": 0.5802925418,
      "Velocity stability factor": 0.2336284017,
      "Direction stability factor": 0.1614757228,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.385558487, 0.7825368417, 1.1814972072],
      direction: [-63.9257538857, -21.523922803, 28.9681651411],
      "speed factor": 1.0656200738,
      "Uz factor": 0.9754776669,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.416306527,
      "Direction stability factor": 0.741961336,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7329853341, 0.9315671957, 1.0869950916],
      direction: [-7.1453558878, 5.716411969, 27.8605380507],
      "speed factor": 1.1830325215,
      "Uz factor": 0.8837415303,
      "Directional variance factor": 0.4918744916,
      "Velocity stability factor": 0.7148683378,
      "Direction stability factor": 0.9027614057,
      score: 0.0,
    },
    id: "IDN:428.0",
    "45": {
      velocity: [0.1413257864, 0.7137072715, 1.1991844629],
      direction: [-30.4448075866, -3.8884632952, 44.5140638026],
      "speed factor": 0.9694867828,
      "Uz factor": 0.9627867173,
      "Directional variance factor": 0.6543588182,
      "Velocity stability factor": 0.13999437,
      "Direction stability factor": 0.7917809128,
      score: 0.0,
    },
    "90": {
      velocity: [0.1107950152, 0.5742342567, 1.0690370753],
      direction: [-139.0823649784, 21.5576658553, 179.598948889],
      "speed factor": 0.9308505339,
      "Uz factor": 0.9969696508,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3006198153,
      "Direction stability factor": 0.1147741281,
      score: 0.0,
    },
    "135": {
      velocity: [0.0873701063, 0.3534832868, 0.8437445484],
      direction: [-176.0005969403, 95.6097209506, 179.3758013756],
      "speed factor": 0.2991706946,
      "Uz factor": 0.9888168328,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3905052985,
      "Direction stability factor": 0.012843338,
      score: 0.0,
    },
    "180": {
      velocity: [0.0730054428, 0.3588118306, 0.9184408419],
      direction: [-179.5518423108, -36.0530621318, 178.9622449387],
      "speed factor": 0.7007572886,
      "Uz factor": 0.9956815207,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3338333602,
      "Direction stability factor": 0.0041275354,
      score: 0.0,
    },
    "225": {
      velocity: [0.2110115837, 0.8261560089, 1.1701340777],
      direction: [-120.2739273117, -2.3382228845, 13.8841472567],
      "speed factor": 1.1277933629,
      "Uz factor": 0.9951152633,
      "Directional variance factor": 0.7921579658,
      "Velocity stability factor": 0.1379592999,
      "Direction stability factor": 0.6273386818,
      score: 0.5461986573,
    },
    "270": {
      velocity: [0.8471495467, 1.1453844334, 1.3878739358],
      direction: [-31.1112719508, -10.6983104936, 6.0892740351],
      "speed factor": 1.0480637405,
      "Uz factor": 0.9884646562,
      "Directional variance factor": 0.0490390672,
      "Velocity stability factor": 0.5734748693,
      "Direction stability factor": 0.89666515,
      score: 0.6039610592,
    },
    "315": {
      velocity: [0.7104079358, 0.8541736161, 1.0055289317],
      direction: [-11.9006198389, 8.2823530848, 26.7846174975],
      "speed factor": 1.0524269008,
      "Uz factor": 0.9138750048,
      "Directional variance factor": 0.2637908369,
      "Velocity stability factor": 0.760587233,
      "Direction stability factor": 0.8925410074,
      score: 0.0,
    },
  },
  {
    position: [-45.30487816, 96.17933745, 47.87999908],
    "overall score": 0.1071411881,
    "0": {
      velocity: [0.6023926255, 1.0620236574, 1.3048656913],
      direction: [-29.5727416738, -10.9008243332, 26.6327386027],
      "speed factor": 1.0883098572,
      "Uz factor": 0.9246174402,
      "Directional variance factor": 0.031037837,
      "Velocity stability factor": 0.5119000823,
      "Direction stability factor": 0.8438736659,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5145670135, 1.0639728495, 1.5137305382],
      direction: [-38.3594761824, 14.6002317064, 42.6074696986],
      "speed factor": 0.9446755866,
      "Uz factor": 0.947390234,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3538674767,
      "Direction stability factor": 0.775091817,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2303897078, 0.6941957788, 1.177013592],
      direction: [-39.9825882854, -4.9145637965, 38.8808524451],
      "speed factor": 1.0753555399,
      "Uz factor": 0.992700364,
      "Directional variance factor": 0.5631498848,
      "Velocity stability factor": 0.1541399433,
      "Direction stability factor": 0.7809348869,
      score: 0.5697899004,
    },
    "112.5": {
      velocity: [0.0636893515, 0.4795425904, 1.2460575338],
      direction: [-178.7235126973, 21.233040788, 159.7733684398],
      "speed factor": 0.539451913,
      "Uz factor": 0.9988378858,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0212131186,
      "Direction stability factor": 0.0597308857,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0986896115, 0.4047186605, 1.0740042157],
      direction: [-172.8176110342, -11.8013654563, 177.472172329],
      "speed factor": 0.5749597978,
      "Uz factor": 0.983016681,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2023960608,
      "Direction stability factor": 0.026972824,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2438661677, 0.8021497582, 1.2965913286],
      direction: [-67.0841659955, -16.0291656826, 14.201231175],
      "speed factor": 0.9398431187,
      "Uz factor": 0.9967126702,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1905574189,
      "Direction stability factor": 0.7742072301,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0882352248, 0.4058057241, 0.9009207404],
      direction: [-103.696841805, -3.1066260986, 100.8436472803],
      "speed factor": 0.7528185204,
      "Uz factor": 0.9945501244,
      "Directional variance factor": 0.7238554579,
      "Velocity stability factor": 0.2114123572,
      "Direction stability factor": 0.4318319748,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4373040283, 0.7620144711, 1.1365143202],
      direction: [-59.9807829898, -14.8643840801, 36.2240525201],
      "speed factor": 1.0376737218,
      "Uz factor": 0.9876608503,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4872413249,
      "Direction stability factor": 0.7327643458,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7330941457, 0.9474302941, 1.0858344275],
      direction: [-10.8942839189, 0.0298347215, 17.926330137],
      "speed factor": 1.203177672,
      "Uz factor": 0.8956509888,
      "Directional variance factor": 0.9973480248,
      "Velocity stability factor": 0.7158908172,
      "Direction stability factor": 0.9199427387,
      score: 0.0,
    },
    id: "IDN:693.0",
    "45": {
      velocity: [0.2949102916, 0.6948107236, 1.0873054851],
      direction: [-31.5818905844, -6.515038438, 32.0919648956],
      "speed factor": 0.9438180609,
      "Uz factor": 0.9665546138,
      "Directional variance factor": 0.4208854722,
      "Velocity stability factor": 0.3558077816,
      "Direction stability factor": 0.8231281792,
      score: 0.0,
    },
    "90": {
      velocity: [0.137741711, 0.7355707419, 1.210344481],
      direction: [-69.7403731395, 25.8169094333, 96.1625495015],
      "speed factor": 1.1923816978,
      "Uz factor": 0.9984684009,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2171527898,
      "Direction stability factor": 0.5391585482,
      score: 0.0,
    },
    "135": {
      velocity: [0.0841537958, 0.4401214274, 1.1822737605],
      direction: [-178.1788084343, 3.0595383585, 175.6375377904],
      "speed factor": 0.372496913,
      "Uz factor": 0.9861039763,
      "Directional variance factor": 0.7280410348,
      "Velocity stability factor": 0.1151230623,
      "Direction stability factor": 0.017176816,
      score: 0.0,
    },
    "180": {
      velocity: [0.0934931839, 0.3593991828, 0.9954529951],
      direction: [-177.4982998214, -36.5043222043, 179.1128308059],
      "speed factor": 0.7019043837,
      "Uz factor": 0.9802455622,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2892945608,
      "Direction stability factor": 0.009413526,
      score: 0.0,
    },
    "225": {
      velocity: [0.1267064832, 0.8438662719, 1.2393250566],
      direction: [-168.1165587504, 0.0536684409, 23.0819617517],
      "speed factor": 1.1519698101,
      "Uz factor": 0.9999848779,
      "Directional variance factor": 0.9952294719,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.4688929986,
      score: 0.4832538673,
    },
    "270": {
      velocity: [0.846064251, 1.159836102, 1.418539966],
      direction: [-28.7844407222, -7.8044364711, 8.9868789401],
      "speed factor": 1.061287484,
      "Uz factor": 0.9952574761,
      "Directional variance factor": 0.3062723137,
      "Velocity stability factor": 0.5484293217,
      "Direction stability factor": 0.8950796676,
      score: 0.6612152426,
    },
    "315": {
      velocity: [0.7220661579, 0.8794218331, 1.0479596363],
      direction: [-12.1317556895, 6.4831095466, 22.8462275204],
      "speed factor": 1.0835352167,
      "Uz factor": 0.9279925435,
      "Directional variance factor": 0.4237235959,
      "Velocity stability factor": 0.7356234884,
      "Direction stability factor": 0.9028389355,
      score: 0.0,
    },
  },
  {
    position: [12.51858065, 102.9428494, 47.87999908],
    "overall score": 0.1056097754,
    "0": {
      velocity: [0.548403137, 0.9893140739, 1.3468286892],
      direction: [-41.5440386617, -19.7141443072, 2.1325635703],
      "speed factor": 1.0138006353,
      "Uz factor": 0.9703410748,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4452293401,
      "Direction stability factor": 0.8786761049,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4948589781, 0.9522553809, 1.2198396572],
      direction: [-52.5470182219, -18.9722579825, -0.1655064425],
      "speed factor": 0.8454843664,
      "Uz factor": 0.9808321374,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5311742432,
      "Direction stability factor": 0.8544958006,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2142036761, 0.4813668643, 0.8270027947],
      direction: [-53.7031754345, -6.3777926062, 116.1488092247],
      "speed factor": 0.7456693631,
      "Uz factor": 0.9938893006,
      "Directional variance factor": 0.4330851017,
      "Velocity stability factor": 0.4524305736,
      "Direction stability factor": 0.5281889315,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6994223631, 1.1233677722, 1.3084187548],
      direction: [-8.6282807253, 9.0898775062, 32.2590219779],
      "speed factor": 1.2637102643,
      "Uz factor": 0.9899900857,
      "Directional variance factor": 0.1920108883,
      "Velocity stability factor": 0.4958611979,
      "Direction stability factor": 0.8864241592,
      score: 0.6151801011,
    },
    "157.5": {
      velocity: [0.3253968074, 0.9807896058, 1.2729167445],
      direction: [-12.2702199645, 12.5365587928, 56.5242930293],
      "speed factor": 1.393349624,
      "Uz factor": 0.9999416054,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2251263017,
      "Direction stability factor": 0.8089041306,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0984451367, 0.5406322057, 1.0596327522],
      direction: [-174.1798687756, -14.2107176749, 178.2862253523],
      "speed factor": 0.6334346587,
      "Uz factor": 0.9921872717,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2609408293,
      "Direction stability factor": 0.0209275163,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0592773177, 0.5037085609, 1.0479857927],
      direction: [-45.7099401231, -2.5974851502, 76.0426662957],
      "speed factor": 0.9344400805,
      "Uz factor": 0.9995528835,
      "Directional variance factor": 0.7691124311,
      "Velocity stability factor": 0.040608857,
      "Direction stability factor": 0.6617983155,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1012264328, 0.5218569618, 0.9265567384],
      direction: [-60.5275695871, 10.8382485499, 137.1790626865],
      "speed factor": 0.7106390709,
      "Uz factor": 0.9066444536,
      "Directional variance factor": 0.0366001289,
      "Velocity stability factor": 0.3947525101,
      "Direction stability factor": 0.4508149104,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3534321826, 0.7381159513, 1.0897565072],
      direction: [-59.1333065642, 4.5903552663, 32.87802882],
      "speed factor": 0.9373614475,
      "Uz factor": 0.9990315131,
      "Directional variance factor": 0.5919684208,
      "Velocity stability factor": 0.4069390062,
      "Direction stability factor": 0.7444129573,
      score: 0.0,
    },
    id: "IDN:683.0",
    "45": {
      velocity: [0.2879630794, 0.6727380894, 1.036884199],
      direction: [-40.2585398471, -5.6058985842, 25.428946722],
      "speed factor": 0.9138350021,
      "Uz factor": 0.9953654544,
      "Directional variance factor": 0.5016979036,
      "Velocity stability factor": 0.391150828,
      "Direction stability factor": 0.8175347595,
      score: 0.0,
    },
    "90": {
      velocity: [0.5662626701, 0.8127190471, 1.065764056],
      direction: [-11.8712865689, 12.0271965165, 30.0179317269],
      "speed factor": 1.317441358,
      "Uz factor": 0.9875660102,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6354351514,
      "Direction stability factor": 0.8836410603,
      score: 0.0,
    },
    "135": {
      velocity: [0.9332298784, 1.2689299036, 1.3826438943],
      direction: [2.6716762378, 10.0558668636, 21.6474448279],
      "speed factor": 1.0739592361,
      "Uz factor": 0.9938833373,
      "Directional variance factor": 0.1061451677,
      "Velocity stability factor": 0.637857328,
      "Direction stability factor": 0.9472895317,
      score: 0.6596453898,
    },
    "180": {
      velocity: [0.1530214291, 0.6079217034, 1.0370900312],
      direction: [-85.4089851344, 7.1903471672, 95.4057724833],
      "speed factor": 1.1872673313,
      "Uz factor": 0.9979406114,
      "Directional variance factor": 0.3608580296,
      "Velocity stability factor": 0.3033920621,
      "Direction stability factor": 0.4977367844,
      score: 0.4149309151,
    },
    "225": {
      velocity: [0.1182743709, 0.4189544047, 0.8403750271],
      direction: [-139.793868463, -12.6007006868, 92.1362684207],
      "speed factor": 0.5719186108,
      "Uz factor": 0.9992184829,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3509899318,
      "Direction stability factor": 0.3557496198,
      score: 0.0,
    },
    "270": {
      velocity: [0.3394440141, 0.9992130918, 1.273254961],
      direction: [-30.1212045944, 2.0980107922, 17.336655591],
      "speed factor": 0.9143122432,
      "Uz factor": 0.9997246529,
      "Directional variance factor": 0.8135101518,
      "Velocity stability factor": 0.2634069329,
      "Direction stability factor": 0.8681726106,
      score: 0.0,
    },
    "315": {
      velocity: [0.4131427422, 0.8996606013, 1.2376613409],
      direction: [-34.8185157133, -19.8327201664, 23.9144867629],
      "speed factor": 1.1084713933,
      "Uz factor": 0.9884176263,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3311208559,
      "Direction stability factor": 0.8368527709,
      score: 0.0,
    },
  },
  {
    position: [12.51858065, 102.9428494, 47.87999908],
    "overall score": 0.1056097754,
    "0": {
      velocity: [0.548403137, 0.9893140739, 1.3468286892],
      direction: [-41.5440386617, -19.7141443072, 2.1325635703],
      "speed factor": 1.0138006353,
      "Uz factor": 0.9703410748,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4452293401,
      "Direction stability factor": 0.8786761049,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4948589781, 0.9522553809, 1.2198396572],
      direction: [-52.5470182219, -18.9722579825, -0.1655064425],
      "speed factor": 0.8454843664,
      "Uz factor": 0.9808321374,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5311742432,
      "Direction stability factor": 0.8544958006,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2142036761, 0.4813668643, 0.8270027947],
      direction: [-53.7031754345, -6.3777926062, 116.1488092247],
      "speed factor": 0.7456693631,
      "Uz factor": 0.9938893006,
      "Directional variance factor": 0.4330851017,
      "Velocity stability factor": 0.4524305736,
      "Direction stability factor": 0.5281889315,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6994223631, 1.1233677722, 1.3084187548],
      direction: [-8.6282807253, 9.0898775062, 32.2590219779],
      "speed factor": 1.2637102643,
      "Uz factor": 0.9899900857,
      "Directional variance factor": 0.1920108883,
      "Velocity stability factor": 0.4958611979,
      "Direction stability factor": 0.8864241592,
      score: 0.6151801011,
    },
    "157.5": {
      velocity: [0.3253968074, 0.9807896058, 1.2729167445],
      direction: [-12.2702199645, 12.5365587928, 56.5242930293],
      "speed factor": 1.393349624,
      "Uz factor": 0.9999416054,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2251263017,
      "Direction stability factor": 0.8089041306,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0984451367, 0.5406322057, 1.0596327522],
      direction: [-174.1798687756, -14.2107176749, 178.2862253523],
      "speed factor": 0.6334346587,
      "Uz factor": 0.9921872717,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2609408293,
      "Direction stability factor": 0.0209275163,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0592773177, 0.5037085609, 1.0479857927],
      direction: [-45.7099401231, -2.5974851502, 76.0426662957],
      "speed factor": 0.9344400805,
      "Uz factor": 0.9995528835,
      "Directional variance factor": 0.7691124311,
      "Velocity stability factor": 0.040608857,
      "Direction stability factor": 0.6617983155,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1012264328, 0.5218569618, 0.9265567384],
      direction: [-60.5275695871, 10.8382485499, 137.1790626865],
      "speed factor": 0.7106390709,
      "Uz factor": 0.9066444536,
      "Directional variance factor": 0.0366001289,
      "Velocity stability factor": 0.3947525101,
      "Direction stability factor": 0.4508149104,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3534321826, 0.7381159513, 1.0897565072],
      direction: [-59.1333065642, 4.5903552663, 32.87802882],
      "speed factor": 0.9373614475,
      "Uz factor": 0.9990315131,
      "Directional variance factor": 0.5919684208,
      "Velocity stability factor": 0.4069390062,
      "Direction stability factor": 0.7444129573,
      score: 0.0,
    },
    id: "IDN:664.0",
    "45": {
      velocity: [0.2879630794, 0.6727380894, 1.036884199],
      direction: [-40.2585398471, -5.6058985842, 25.428946722],
      "speed factor": 0.9138350021,
      "Uz factor": 0.9953654544,
      "Directional variance factor": 0.5016979036,
      "Velocity stability factor": 0.391150828,
      "Direction stability factor": 0.8175347595,
      score: 0.0,
    },
    "90": {
      velocity: [0.5662626701, 0.8127190471, 1.065764056],
      direction: [-11.8712865689, 12.0271965165, 30.0179317269],
      "speed factor": 1.317441358,
      "Uz factor": 0.9875660102,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6354351514,
      "Direction stability factor": 0.8836410603,
      score: 0.0,
    },
    "135": {
      velocity: [0.9332298784, 1.2689299036, 1.3826438943],
      direction: [2.6716762378, 10.0558668636, 21.6474448279],
      "speed factor": 1.0739592361,
      "Uz factor": 0.9938833373,
      "Directional variance factor": 0.1061451677,
      "Velocity stability factor": 0.637857328,
      "Direction stability factor": 0.9472895317,
      score: 0.6596453898,
    },
    "180": {
      velocity: [0.1530214291, 0.6079217034, 1.0370900312],
      direction: [-85.4089851344, 7.1903471672, 95.4057724833],
      "speed factor": 1.1872673313,
      "Uz factor": 0.9979406114,
      "Directional variance factor": 0.3608580296,
      "Velocity stability factor": 0.3033920621,
      "Direction stability factor": 0.4977367844,
      score: 0.4149309151,
    },
    "225": {
      velocity: [0.1182743709, 0.4189544047, 0.8403750271],
      direction: [-139.793868463, -12.6007006868, 92.1362684207],
      "speed factor": 0.5719186108,
      "Uz factor": 0.9992184829,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3509899318,
      "Direction stability factor": 0.3557496198,
      score: 0.0,
    },
    "270": {
      velocity: [0.3394440141, 0.9992130918, 1.273254961],
      direction: [-30.1212045944, 2.0980107922, 17.336655591],
      "speed factor": 0.9143122432,
      "Uz factor": 0.9997246529,
      "Directional variance factor": 0.8135101518,
      "Velocity stability factor": 0.2634069329,
      "Direction stability factor": 0.8681726106,
      score: 0.0,
    },
    "315": {
      velocity: [0.4131427422, 0.8996606013, 1.2376613409],
      direction: [-34.8185157133, -19.8327201664, 23.9144867629],
      "speed factor": 1.1084713933,
      "Uz factor": 0.9884176263,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3311208559,
      "Direction stability factor": 0.8368527709,
      score: 0.0,
    },
  },
  {
    position: [-52.13536577, 92.22471892, 45.87999908],
    "overall score": 0.1039326994,
    "0": {
      velocity: [0.3591608276, 1.1504701381, 1.4216117676],
      direction: [-9.0678848871, 6.8225173648, 37.9996940024],
      "speed factor": 1.1789454812,
      "Uz factor": 0.9714416385,
      "Directional variance factor": 0.393554012,
      "Velocity stability factor": 0.2617763705,
      "Direction stability factor": 0.8692567253,
      score: 0.5984609583,
    },
    "22.5": {
      velocity: [0.0411460024, 0.4904484669, 1.1159512514],
      direction: [-149.8829965509, 26.8273403901, 146.4885979208],
      "speed factor": 0.435457252,
      "Uz factor": 0.9575765793,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3049519818,
      "Direction stability factor": 0.1767455709,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1292380979, 0.4872688328, 1.1536516164],
      direction: [-65.3165421512, 12.7227199049, 92.1856134313],
      "speed factor": 0.7548119058,
      "Uz factor": 0.9995897534,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0846306635,
      "Direction stability factor": 0.5624940123,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0798443366, 0.3084819068, 0.5882676657],
      direction: [-167.972085163, 130.2466694031, 177.6429868999],
      "speed factor": 0.3470205943,
      "Uz factor": 0.9598099015,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.579117493,
      "Direction stability factor": 0.0399581332,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0742557436, 0.3522277566, 0.8946920583],
      direction: [-176.7026188474, 27.818948936, 179.3041250367],
      "speed factor": 0.5003890838,
      "Uz factor": 0.9999970279,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3290542009,
      "Direction stability factor": 0.0110923781,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.140964966, 1.0073847184, 1.4209390355],
      direction: [-42.1111476482, -10.477313126, 14.2947019312],
      "speed factor": 1.1803077739,
      "Uz factor": 0.9999374806,
      "Directional variance factor": 0.0686832777,
      "Velocity stability factor": 0.0158252572,
      "Direction stability factor": 0.8433170845,
      score: 0.442785676,
    },
    "247.5": {
      velocity: [0.105637792, 0.4380186723, 0.8219881431],
      direction: [-63.3826347821, -10.2360340941, 37.3328801455],
      "speed factor": 0.8125774212,
      "Uz factor": 0.9046172696,
      "Directional variance factor": 0.0901303027,
      "Velocity stability factor": 0.304890977,
      "Direction stability factor": 0.7202346808,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2263391262, 0.8446316137, 1.1493513348],
      direction: [-63.1379435582, -34.4656892686, 17.6418126355],
      "speed factor": 1.1501776716,
      "Uz factor": 0.8410215428,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3231184914,
      "Direction stability factor": 0.7756117884,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6730998415, 1.0114289695, 1.24260697],
      direction: [1.2212769265, 12.5297247211, 37.9300060828],
      "speed factor": 1.2844520177,
      "Uz factor": 0.9439752903,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5412993265,
      "Direction stability factor": 0.8980313079,
      score: 0.0,
    },
    id: "IDN:343.0",
    "45": {
      velocity: [0.0561160627, 0.3729371754, 0.900904269],
      direction: [-132.1966882939, 48.3723801283, 149.7768803165],
      "speed factor": 0.5065909748,
      "Uz factor": 0.9326303009,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.31321392,
      "Direction stability factor": 0.2167400872,
      score: 0.0,
    },
    "90": {
      velocity: [0.0520673682, 0.2883239125, 0.8771937858],
      direction: [-178.8154582652, -21.4376628738, 179.9805112392],
      "speed factor": 0.4673814994,
      "Uz factor": 0.58582593,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3977752695,
      "Direction stability factor": 0.0033445292,
      score: 0.0,
    },
    "135": {
      velocity: [0.0260476107, 0.3370048358, 0.7237344092],
      direction: [-175.5670689006, 111.5646853773, 176.057875242],
      "speed factor": 0.2852241523,
      "Uz factor": 0.9465954856,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4377964361,
      "Direction stability factor": 0.023264044,
      score: 0.0,
    },
    "180": {
      velocity: [0.0872473789, 0.5184602564, 1.3563523575],
      direction: [-120.5748857089, -25.7154990261, 136.7397738203],
      "speed factor": 1.012549678,
      "Uz factor": 0.9948753354,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.2852370569,
      score: 0.0,
    },
    "225": {
      velocity: [0.3786670006, 0.8586394959, 1.1682627771],
      direction: [-36.290396614, -5.8822821486, 14.2447612445],
      "speed factor": 1.1721368776,
      "Uz factor": 0.9704448468,
      "Directional variance factor": 0.4771304757,
      "Velocity stability factor": 0.2903266264,
      "Direction stability factor": 0.8596245615,
      score: 0.6216765563,
    },
    "270": {
      velocity: [0.8228379098, 1.1023334325, 1.334904183],
      direction: [-38.4317902404, -16.8946542298, 0.3144623809],
      "speed factor": 1.0086706847,
      "Uz factor": 0.8828487646,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5960804829,
      "Direction stability factor": 0.8923715205,
      score: 0.0,
    },
    "315": {
      velocity: [0.7298895903, 0.9369053707, 1.1312387133],
      direction: [-20.3817621402, 6.6058854533, 25.2082528027],
      "speed factor": 1.1543606556,
      "Uz factor": 0.9058997316,
      "Directional variance factor": 0.4128101819,
      "Velocity stability factor": 0.6744111554,
      "Direction stability factor": 0.8733610696,
      score: 0.0,
    },
  },
  {
    position: [12.51734566, 102.5890542, 47.87999908],
    "overall score": 0.1028083476,
    "0": {
      velocity: [0.5475564949, 1.003716296, 1.363680449],
      direction: [-41.7773342253, -19.871389819, 0.947246067],
      "speed factor": 1.0285593275,
      "Uz factor": 0.9697444624,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4329319454,
      "Direction stability factor": 0.8813206103,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5101034991, 0.9655052338, 1.2385037339],
      direction: [-51.7027145158, -19.3137471211, -0.3493107858],
      "speed factor": 0.8572485882,
      "Uz factor": 0.981473299,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5289629074,
      "Direction stability factor": 0.8573516563,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.207561743, 0.4795533036, 0.8229055302],
      direction: [-55.4646012535, -6.6548422468, 119.3862091822],
      "speed factor": 0.7428600368,
      "Uz factor": 0.9930038917,
      "Directional variance factor": 0.408458467,
      "Velocity stability factor": 0.4501567735,
      "Direction stability factor": 0.5143033043,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6837453688, 1.1161808518, 1.3031073355],
      direction: [-9.5984339657, 8.7905180669, 31.4617505698],
      "speed factor": 1.2556254809,
      "Uz factor": 0.9887671877,
      "Directional variance factor": 0.2186206163,
      "Velocity stability factor": 0.4872803777,
      "Direction stability factor": 0.8859439318,
      score: 0.6194472144,
    },
    "157.5": {
      velocity: [0.3268401135, 0.9828777734, 1.2705160025],
      direction: [-12.548962554, 12.6020665419, 57.2707659187],
      "speed factor": 1.3963161598,
      "Uz factor": 0.9998977549,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2282699315,
      "Direction stability factor": 0.8060563098,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0867984938, 0.5548420977, 1.0672805644],
      direction: [-172.9367649637, -13.9066911675, 178.9725001882],
      "speed factor": 0.6500837558,
      "Uz factor": 0.9912574497,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2461052824,
      "Direction stability factor": 0.0224742635,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0751111946, 0.5060094749, 1.0333818508],
      direction: [-45.1802692019, -2.6048738933, 75.3367243575],
      "speed factor": 0.9387085532,
      "Uz factor": 0.9994647171,
      "Directional variance factor": 0.7684556539,
      "Velocity stability factor": 0.0701441291,
      "Direction stability factor": 0.6652305734,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1225500407, 0.503096412, 0.9201466822],
      direction: [-82.1757690722, 11.828593549, 165.2995701359],
      "speed factor": 0.6850918795,
      "Uz factor": 0.9129822195,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4150907075,
      "Direction stability factor": 0.3125685022,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3454454225, 0.7381354877, 1.095569464],
      direction: [-60.3094366716, 4.415193515, 32.8556208828],
      "speed factor": 0.9373862576,
      "Uz factor": 0.9989772844,
      "Directional variance factor": 0.6075383542,
      "Velocity stability factor": 0.3958242385,
      "Direction stability factor": 0.7412081735,
      score: 0.0,
    },
    id: "IDN:682.0",
    "45": {
      velocity: [0.2864161259, 0.6763480769, 1.0374472104],
      direction: [-42.1445364542, -5.8276379929, 24.8862034859],
      "speed factor": 0.9187387425,
      "Uz factor": 0.995227394,
      "Directional variance factor": 0.481987734,
      "Velocity stability factor": 0.3894354933,
      "Direction stability factor": 0.8138035002,
      score: 0.0,
    },
    "90": {
      velocity: [0.5617379659, 0.8122893391, 1.065802419],
      direction: [-11.8067666268, 11.9499812119, 29.931918651],
      "speed factor": 1.3167447888,
      "Uz factor": 0.9857393093,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6321047624,
      "Direction stability factor": 0.8840592076,
      score: 0.0,
    },
    "135": {
      velocity: [0.9306024652, 1.266388622, 1.3826436528],
      direction: [2.1215811368, 9.9635586477, 21.8690088398],
      "speed factor": 1.0718084216,
      "Uz factor": 0.9932843087,
      "Directional variance factor": 0.1143503424,
      "Velocity stability factor": 0.6357403247,
      "Direction stability factor": 0.9451460342,
      score: 0.6600956838,
    },
    "180": {
      velocity: [0.1514372396, 0.616677769, 1.0495486172],
      direction: [-115.2941767919, 7.3223142581, 97.0863706296],
      "speed factor": 1.2043678732,
      "Uz factor": 0.9976528823,
      "Directional variance factor": 0.3491276215,
      "Velocity stability factor": 0.2923269606,
      "Direction stability factor": 0.4100540349,
      score: 0.365390663,
    },
    "225": {
      velocity: [0.1483965333, 0.4240761914, 0.8363617435],
      direction: [-140.1623198177, -12.4885973747, 89.2857802276],
      "speed factor": 0.5789104102,
      "Uz factor": 0.9992841159,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3816702087,
      "Direction stability factor": 0.3626441665,
      score: 0.0,
    },
    "270": {
      velocity: [0.3773914866, 0.9945577075, 1.3008429799],
      direction: [-31.1902875029, 2.5348655449, 18.0580580778],
      "speed factor": 0.9100524162,
      "Uz factor": 0.9997738951,
      "Directional variance factor": 0.7746786182,
      "Velocity stability factor": 0.2715785031,
      "Direction stability factor": 0.8631990401,
      score: 0.0,
    },
    "315": {
      velocity: [0.4316066998, 0.9045250774, 1.2321827245],
      direction: [-34.4009357021, -19.0818246205, 23.8126799529],
      "speed factor": 1.114464912,
      "Uz factor": 0.9873316458,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3505439331,
      "Direction stability factor": 0.8382955121,
      score: 0.0,
    },
  },
  {
    position: [-35.4946478, 93.51136883, 46.87999908],
    "overall score": 0.0988188478,
    "0": {
      velocity: [0.1568750156, 0.7799260379, 1.1954467272],
      direction: [-121.4192128518, -24.0318370462, 30.1394261732],
      "speed factor": 0.7992300257,
      "Uz factor": 0.9800722543,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2783683937,
      "Direction stability factor": 0.5790037805,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.8068749271, 1.265526597, 1.492150197],
      direction: [-29.1450799981, -0.1996757742, 13.7088265301],
      "speed factor": 1.1236302514,
      "Uz factor": 0.9858247129,
      "Directional variance factor": 0.9822510423,
      "Velocity stability factor": 0.5568506772,
      "Direction stability factor": 0.8809613708,
      score: 0.8252561152,
    },
    "67.5": {
      velocity: [0.2740984172, 0.6259922127, 1.0141287461],
      direction: [-34.2006768049, -6.6813094673, 61.2269890723],
      "speed factor": 0.9697036693,
      "Uz factor": 0.9730911166,
      "Directional variance factor": 0.4061058251,
      "Velocity stability factor": 0.3387425498,
      "Direction stability factor": 0.7349231503,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1696167111, 0.7564024887, 1.1986043752],
      direction: [-3.361548587, 29.6530534791, 108.4997504943],
      "speed factor": 0.8508999569,
      "Uz factor": 0.9827287777,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1481844304,
      "Direction stability factor": 0.6892741692,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1610537517, 0.7371872855, 1.1373269781],
      direction: [-27.8541087116, 13.0309305036, 111.2086801643],
      "speed factor": 1.0472782551,
      "Uz factor": 0.9840949133,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2016121078,
      "Direction stability factor": 0.6137144753,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2062139334, 0.4519859526, 0.8000107352],
      direction: [-55.1363382478, 49.0649328306, 147.4687654944],
      "speed factor": 0.529571795,
      "Uz factor": 0.6201346334,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5434283954,
      "Direction stability factor": 0.4372080452,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1420987681, 0.3681720928, 0.6884569496],
      direction: [-113.7163883787, -11.5832246388, 53.6588580083],
      "speed factor": 0.6830035993,
      "Uz factor": 0.9984274026,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4698425132,
      "Direction stability factor": 0.53506876,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1484428805, 0.6342673158, 1.010135132],
      direction: [-83.7919186596, -1.8598291349, 56.9127013041],
      "speed factor": 0.8637139466,
      "Uz factor": 0.9936321999,
      "Directional variance factor": 0.8346818547,
      "Velocity stability factor": 0.3680868511,
      "Direction stability factor": 0.6091538334,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0548700263, 0.5416289928, 1.0521341468],
      direction: [-137.0038881875, -9.9012240866, 17.6784668921],
      "speed factor": 0.687835205,
      "Uz factor": 0.9540312098,
      "Directional variance factor": 0.1198911923,
      "Velocity stability factor": 0.1967690994,
      "Direction stability factor": 0.5703267914,
      score: 0.0,
    },
    id: "IDN:419.0",
    "45": {
      velocity: [0.0944050405, 0.6019174272, 1.0245047581],
      direction: [-102.1922327268, -13.2266062561, 46.1035937167],
      "speed factor": 0.8176335219,
      "Uz factor": 0.9816635018,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2438583609,
      "Direction stability factor": 0.5880671488,
      score: 0.0,
    },
    "90": {
      velocity: [0.2432797345, 0.7323036289, 1.0720610271],
      direction: [-35.8930602585, 27.2875244543, 83.1328558241],
      "speed factor": 1.187085612,
      "Uz factor": 0.9879807795,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3951077315,
      "Direction stability factor": 0.6693724553,
      score: 0.0,
    },
    "135": {
      velocity: [0.6538064059, 1.0631500647, 1.2406252251],
      direction: [-16.5370395553, 7.4581020839, 55.5714185589],
      "speed factor": 0.8997974026,
      "Uz factor": 0.9757813934,
      "Directional variance factor": 0.3370575925,
      "Velocity stability factor": 0.5271350522,
      "Direction stability factor": 0.7996987275,
      score: 0.0,
    },
    "180": {
      velocity: [0.3428864443, 0.8674791564, 1.2023569079],
      direction: [-23.0067323238, 0.1396919542, 28.6488533586],
      "speed factor": 1.6941814335,
      "Uz factor": 0.9700017247,
      "Directional variance factor": 0.9875829374,
      "Velocity stability factor": 0.3227743346,
      "Direction stability factor": 0.856512262,
      score: 0.755845449,
    },
    "225": {
      velocity: [0.1518325258, 0.5988444549, 1.0674302682],
      direction: [-133.8227248325, -2.5851079201, 27.1202257707],
      "speed factor": 0.8174882157,
      "Uz factor": 0.9956940213,
      "Directional variance factor": 0.7702126293,
      "Velocity stability factor": 0.1770785026,
      "Direction stability factor": 0.5529362483,
      score: 0.0,
    },
    "270": {
      velocity: [0.0716747092, 0.5473141707, 1.2188836553],
      direction: [-129.5695789845, -16.6714283443, 20.0917543537],
      "speed factor": 0.5008101387,
      "Uz factor": 0.9857063415,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0950779073,
      "Direction stability factor": 0.5842740741,
      score: 0.0,
    },
    "315": {
      velocity: [0.0265714535, 0.4592978115, 0.853475226],
      direction: [-144.6124713931, 3.6220472779, 23.9375171764],
      "speed factor": 0.5659006122,
      "Uz factor": 0.9627525937,
      "Directional variance factor": 0.678040242,
      "Velocity stability factor": 0.3291859171,
      "Direction stability factor": 0.5318055873,
      score: 0.0,
    },
  },
  {
    position: [-45.69008694, 84.25610757, 47.87999908],
    "overall score": 0.0988051083,
    "0": {
      velocity: [0.1335268031, 0.4913662258, 1.053873221],
      direction: [-54.9178353594, 20.4713260961, 120.0206138562],
      "speed factor": 0.5035280555,
      "Uz factor": 0.9898240051,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3605149683,
      "Direction stability factor": 0.5140598633,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3467587699, 0.8741807981, 1.3669101943],
      direction: [-21.7536867209, 18.7277735471, 87.9169796018],
      "speed factor": 0.7761638454,
      "Uz factor": 0.9975970935,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3402951591,
      "Direction stability factor": 0.6953592602,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1293619218, 0.5393978399, 1.0107864454],
      direction: [-30.3944004925, 4.6207867382, 72.7275786953],
      "speed factor": 0.8355632129,
      "Uz factor": 0.9850638194,
      "Directional variance factor": 0.589263401,
      "Velocity stability factor": 0.212399127,
      "Direction stability factor": 0.7135500578,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3236760337, 0.8135760056, 1.132230216],
      direction: [-25.9097110715, 3.8414436621, 30.6656100354],
      "speed factor": 0.9152161692,
      "Uz factor": 0.9396192574,
      "Directional variance factor": 0.6585383412,
      "Velocity stability factor": 0.3306634614,
      "Direction stability factor": 0.8428463303,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4759697404, 0.8452732214, 1.1697682691],
      direction: [-46.820614592, -14.3404344211, 21.9270570608],
      "speed factor": 1.200829534,
      "Uz factor": 0.9504329318,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4326174989,
      "Direction stability factor": 0.8090342454,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3202701377, 0.7177371572, 1.1609639272],
      direction: [-70.6551458567, -23.7934894797, 9.4570445034],
      "speed factor": 0.8409406365,
      "Uz factor": 0.9866439536,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3535887845,
      "Direction stability factor": 0.7774661379,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1251990199, 0.4551574247, 0.7463105899],
      direction: [-35.1875714487, -6.037583584, 50.4332898462],
      "speed factor": 0.8443718722,
      "Uz factor": 0.9999999863,
      "Directional variance factor": 0.4633259036,
      "Velocity stability factor": 0.3973057235,
      "Direction stability factor": 0.7621642742,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1037866527, 0.5214576739, 1.1543420552],
      direction: [-145.1742849867, -12.1056735523, 144.9231985545],
      "speed factor": 0.7100953404,
      "Uz factor": 0.9851365968,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2295860021,
      "Direction stability factor": 0.1941736568,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.057360594, 0.3179364367, 0.7632416921],
      direction: [-152.6044307419, 18.006878671, 176.2084853944],
      "speed factor": 0.4037595421,
      "Uz factor": 0.9969016017,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4314590303,
      "Direction stability factor": 0.0866307885,
      score: 0.0,
    },
    id: "IDN:714.0",
    "45": {
      velocity: [0.1228776044, 0.4880778947, 0.9001349108],
      direction: [-42.6591983627, 8.1411252627, 73.080078185],
      "speed factor": 0.6629960024,
      "Uz factor": 0.9910201858,
      "Directional variance factor": 0.2763444211,
      "Velocity stability factor": 0.3681144047,
      "Direction stability factor": 0.6785020096,
      score: 0.0,
    },
    "90": {
      velocity: [0.4674255976, 0.9038432785, 1.2307013012],
      direction: [-1.7110117458, 13.1775309168, 40.0784237818],
      "speed factor": 1.4651564038,
      "Uz factor": 0.9480666851,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.442917479,
      "Direction stability factor": 0.8839182346,
      score: 0.0,
    },
    "135": {
      velocity: [0.5149113755, 0.9965629, 1.2240187458],
      direction: [-64.4833035336, -14.7285204337, 10.8039646271],
      "speed factor": 0.8434413341,
      "Uz factor": 0.9152626362,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4285936159,
      "Direction stability factor": 0.7908686996,
      score: 0.0,
    },
    "180": {
      velocity: [0.5444854357, 0.8503600019, 1.2798424308],
      direction: [-52.2010161194, -29.16178694, -12.9878075132],
      "speed factor": 1.6607478306,
      "Uz factor": 0.9557949844,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4205703961,
      "Direction stability factor": 0.8910744205,
      score: 0.0,
    },
    "225": {
      velocity: [0.5514837281, 0.7876077869, 1.093812695],
      direction: [-25.9370102714, 0.6383451874, 18.8586391248],
      "speed factor": 1.0751708214,
      "Uz factor": 0.9999496442,
      "Directional variance factor": 0.9432582056,
      "Velocity stability factor": 0.5125652404,
      "Direction stability factor": 0.8755676406,
      score: 0.8017396818,
    },
    "270": {
      velocity: [0.0236148552, 0.5816550322, 1.1329100252],
      direction: [-11.9063882804, 5.0929949317, 98.3306088381],
      "speed factor": 0.5322331359,
      "Uz factor": 0.9765385269,
      "Directional variance factor": 0.5472893394,
      "Velocity stability factor": 0.1249844154,
      "Direction stability factor": 0.6937861191,
      score: 0.0,
    },
    "315": {
      velocity: [0.4043672397, 0.8811660666, 1.1675178319],
      direction: [-19.7615946025, 0.3529695888, 22.1714779794],
      "speed factor": 1.085684286,
      "Uz factor": 0.999838543,
      "Directional variance factor": 0.9686249254,
      "Velocity stability factor": 0.3809047901,
      "Direction stability factor": 0.8835192428,
      score: 0.7791420503,
    },
  },
  {
    position: [-44.96703638, 85.67180679, 47.87999908],
    "overall score": 0.0951483936,
    "0": {
      velocity: [0.1481405384, 0.4512205549, 1.0705580549],
      direction: [-49.9333977702, 20.0499041173, 161.1838097377],
      "speed factor": 0.4623887371,
      "Uz factor": 0.9838875023,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3590759053,
      "Direction stability factor": 0.4135633125,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2733466368, 0.8767890388, 1.4187953326],
      direction: [-21.6150916011, 15.6832684731, 91.3458214093],
      "speed factor": 0.7784796388,
      "Uz factor": 0.9973789776,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2592687403,
      "Direction stability factor": 0.6862196861,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2258441176, 0.5457501976, 1.0157279035],
      direction: [-28.5938129169, 4.7669659703, 75.5144577815],
      "speed factor": 0.8454034386,
      "Uz factor": 0.9905874743,
      "Directional variance factor": 0.5762696915,
      "Velocity stability factor": 0.2941957677,
      "Direction stability factor": 0.7108103592,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.276796659, 0.7900152409, 1.1250814682],
      direction: [-26.2151893636, 8.7367933528, 42.2053151284],
      "speed factor": 0.8887119549,
      "Uz factor": 0.9262001185,
      "Directional variance factor": 0.2233961464,
      "Velocity stability factor": 0.2977736924,
      "Direction stability factor": 0.8099430431,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4771611275, 0.8401983967, 1.2227628777],
      direction: [-42.5054867204, -12.6240151127, 18.0496141812],
      "speed factor": 1.1936200314,
      "Uz factor": 0.9381131142,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3902532675,
      "Direction stability factor": 0.8317913864,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2785099555, 0.7063971867, 1.1474277892],
      direction: [-72.0506844818, -27.56034211, 6.7893300267],
      "speed factor": 0.8276540985,
      "Uz factor": 0.9743149112,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3318872578,
      "Direction stability factor": 0.7809999597,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1111170519, 0.4356181266, 0.7140936415],
      direction: [-73.1428696794, -6.692651281, 49.3106027019],
      "speed factor": 0.8081241197,
      "Uz factor": 0.999919531,
      "Directional variance factor": 0.4050976639,
      "Velocity stability factor": 0.4149029627,
      "Direction stability factor": 0.6598514656,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0402247446, 0.4795969549, 1.1316588328],
      direction: [-178.8907798661, -15.4199811333, 150.4173137159],
      "speed factor": 0.653091478,
      "Uz factor": 0.9880934965,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1996080384,
      "Direction stability factor": 0.0852552956,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0185842857, 0.247706605, 0.6399521349],
      direction: [-176.0255207397, 29.0841189767, 163.0998812411],
      "speed factor": 0.3145720147,
      "Uz factor": 0.9853054322,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4995289143,
      "Direction stability factor": 0.0579849945,
      score: 0.0,
    },
    id: "IDN:715.0",
    "45": {
      velocity: [0.1516839185, 0.5000903996, 0.9262844068],
      direction: [-43.027173988, 5.8875027646, 79.8387336102],
      "speed factor": 0.6793135673,
      "Uz factor": 0.9968540415,
      "Directional variance factor": 0.4766664209,
      "Velocity stability factor": 0.3702743138,
      "Direction stability factor": 0.6587058122,
      score: 0.0,
    },
    "90": {
      velocity: [0.4228493503, 0.8743488333, 1.1643363586],
      direction: [-3.917118751, 17.5408488708, 42.4100972037],
      "speed factor": 1.4173450449,
      "Uz factor": 0.9448127075,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4588201223,
      "Direction stability factor": 0.871313289,
      score: 0.0,
    },
    "135": {
      velocity: [0.5212838872, 1.0062418614, 1.24270559],
      direction: [-57.2119749609, -10.6429157236, 10.1825399081],
      "speed factor": 0.8516331262,
      "Uz factor": 0.9101752236,
      "Directional variance factor": 0.0539630468,
      "Velocity stability factor": 0.4186705936,
      "Direction stability factor": 0.8127930143,
      score: 0.0,
    },
    "180": {
      velocity: [0.5501028138, 0.8670301504, 1.2774733164],
      direction: [-52.2326158175, -27.7552677601, -11.6077351286],
      "speed factor": 1.6933045276,
      "Uz factor": 0.9419350944,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4268634078,
      "Direction stability factor": 0.8871531092,
      score: 0.0,
    },
    "225": {
      velocity: [0.4829938532, 0.7896781377, 1.0971577934],
      direction: [-29.1932486625, 0.3510850034, 20.3086686915],
      "speed factor": 1.0779970767,
      "Uz factor": 0.9999996835,
      "Directional variance factor": 0.9687924441,
      "Velocity stability factor": 0.4480013593,
      "Direction stability factor": 0.862494674,
      score: 0.7854457879,
    },
    "270": {
      velocity: [0.0445860052, 0.4500058242, 1.1256459477],
      direction: [-177.4854625825, 1.9989359339, 147.2838107019],
      "speed factor": 0.4117698596,
      "Uz factor": 0.9764144388,
      "Directional variance factor": 0.8223168059,
      "Velocity stability factor": 0.1472564533,
      "Direction stability factor": 0.0978631298,
      score: 0.0,
    },
    "315": {
      velocity: [0.4303157238, 0.8648750501, 1.1445982434],
      direction: [-21.0637257877, 2.225565612, 28.437396185],
      "speed factor": 1.0656121325,
      "Uz factor": 0.9999849561,
      "Directional variance factor": 0.8021719456,
      "Velocity stability factor": 0.4205483283,
      "Direction stability factor": 0.8624968834,
      score: 0.7369285102,
    },
  },
  {
    position: [-46.31567545, 80.34427683, 46.87999908],
    "overall score": 0.0942580084,
    "0": {
      velocity: [0.0482075205, 0.4017317625, 1.0837344313],
      direction: [-160.9053185, 26.4355627787, 174.8214490346],
      "speed factor": 0.4116750451,
      "Uz factor": 0.998180423,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2804840151,
      "Direction stability factor": 0.0674256457,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1136552876, 0.7384861597, 1.2866465942],
      direction: [-65.6636535911, 27.0343440561, 102.365820955],
      "speed factor": 0.65568388,
      "Uz factor": 0.9912562697,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2414576652,
      "Direction stability factor": 0.5332514596,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1352394381, 0.5007224207, 0.9777485704],
      direction: [-48.2115282815, -0.3107119285, 57.1167912623],
      "speed factor": 0.7756524104,
      "Uz factor": 0.9670627379,
      "Directional variance factor": 0.9723811619,
      "Velocity stability factor": 0.2471721511,
      "Direction stability factor": 0.7074213346,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2568079657, 0.8240060523, 1.1481904196],
      direction: [-32.8588663769, -3.8782657891, 29.1933283226],
      "speed factor": 0.9269492431,
      "Uz factor": 0.9578356409,
      "Directional variance factor": 0.6552652632,
      "Velocity stability factor": 0.262096642,
      "Direction stability factor": 0.8276327925,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4295019545, 0.8160183816, 1.0700289157],
      direction: [-52.6721681665, -14.51818626, 20.4528699024],
      "speed factor": 1.159268918,
      "Uz factor": 0.96111903,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.476182531,
      "Direction stability factor": 0.7968748943,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.266897182, 0.663070399, 1.0744319367],
      direction: [-76.1419926004, -16.7910628841, 17.3048122035],
      "speed factor": 0.7768900325,
      "Uz factor": 0.9819510724,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3790848358,
      "Direction stability factor": 0.7404255422,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.246323057, 0.4930958744, 0.8072844509],
      direction: [-37.3588684067, -8.1840853763, 41.0177195644],
      "speed factor": 0.9147522682,
      "Uz factor": 0.994921236,
      "Directional variance factor": 0.2725257443,
      "Velocity stability factor": 0.4556723175,
      "Direction stability factor": 0.7822872556,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0337845239, 0.4314377554, 0.991387699],
      direction: [-89.0317173169, -6.3216503939, 143.6158501908],
      "speed factor": 0.5875106554,
      "Uz factor": 0.9885825748,
      "Directional variance factor": 0.4380755205,
      "Velocity stability factor": 0.2977515619,
      "Direction stability factor": 0.3537567569,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0615985876, 0.3548056149, 0.9208302163],
      direction: [-177.0615873894, 14.1483555787, 168.3455353024],
      "speed factor": 0.4505811101,
      "Uz factor": 0.9997431678,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3079452266,
      "Direction stability factor": 0.0405357703,
      score: 0.0,
    },
    id: "IDN:445.0",
    "45": {
      velocity: [0.1286728211, 0.4002438143, 0.7852849789],
      direction: [-37.9806961394, 9.2153155862, 82.7596196965],
      "speed factor": 0.543683809,
      "Uz factor": 0.9551951779,
      "Directional variance factor": 0.1808608368,
      "Velocity stability factor": 0.4661950929,
      "Direction stability factor": 0.6646102338,
      score: 0.0,
    },
    "90": {
      velocity: [0.496084246, 0.9588778814, 1.2455808442],
      direction: [-12.6358422637, 3.7906219175, 24.309764449],
      "speed factor": 1.5543691056,
      "Uz factor": 0.9722868108,
      "Directional variance factor": 0.6630558296,
      "Velocity stability factor": 0.4529742628,
      "Direction stability factor": 0.8973733147,
      score: 0.7276941804,
    },
    "135": {
      velocity: [0.4830187024, 0.949003757, 1.2120131214],
      direction: [-52.3194005813, -22.3575136697, 10.5011559874],
      "speed factor": 0.803189638,
      "Uz factor": 0.945739316,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.412568417,
      "Direction stability factor": 0.825498454,
      score: 0.0,
    },
    "180": {
      velocity: [0.4689503277, 0.8026736682, 1.2193304566],
      direction: [-54.5864784508, -28.7222208481, -12.457500085],
      "speed factor": 1.5676167153,
      "Uz factor": 0.9695490959,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4087328144,
      "Direction stability factor": 0.8829750601,
      score: 0.0,
    },
    "225": {
      velocity: [0.5171848767, 0.7346172216, 1.0449778559],
      direction: [-22.3113130093, 1.7733444182, 22.0161126325],
      "speed factor": 1.0028329006,
      "Uz factor": 0.9948477328,
      "Directional variance factor": 0.8423693851,
      "Velocity stability factor": 0.5256299043,
      "Direction stability factor": 0.8768682621,
      score: 0.7804339534,
    },
    "270": {
      velocity: [0.163052883, 0.5974072952, 1.2450161403],
      direction: [-16.7350493954, 4.5422248355, 41.1796324359],
      "speed factor": 0.5466469652,
      "Uz factor": 0.990913141,
      "Directional variance factor": 0.5962466813,
      "Velocity stability factor": 0.1465439157,
      "Direction stability factor": 0.8391258838,
      score: 0.0,
    },
    "315": {
      velocity: [0.1308444372, 0.6157658387, 0.9988889344],
      direction: [-9.013942883, 3.9633583159, 84.9160352477],
      "speed factor": 0.7586847931,
      "Uz factor": 0.999219976,
      "Directional variance factor": 0.647701483,
      "Velocity stability factor": 0.2958110815,
      "Direction stability factor": 0.7390833941,
      score: 0.0,
    },
  },
  {
    position: [-46.31176664, 81.4640645, 46.87999908],
    "overall score": 0.0914202718,
    "0": {
      velocity: [0.0701299295, 0.4028456574, 0.9979645247],
      direction: [-138.6071332461, 41.8742329896, 172.3029928919],
      "speed factor": 0.4128165101,
      "Uz factor": 0.9957345645,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3553119521,
      "Direction stability factor": 0.1363607607,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0458836691, 0.7438840601, 1.2588946584],
      direction: [-162.416711465, 28.8721864197, 89.5011904336],
      "speed factor": 0.6604765443,
      "Uz factor": 0.9925669295,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2155779989,
      "Direction stability factor": 0.3002280503,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1247347241, 0.4958043577, 1.0008564327],
      direction: [-47.2850572991, 3.2187600402, 80.2764360856],
      "speed factor": 0.7680340029,
      "Uz factor": 0.9657613015,
      "Directional variance factor": 0.7138879964,
      "Velocity stability factor": 0.2171374815,
      "Direction stability factor": 0.6456625184,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2674432534, 0.8188949637, 1.1450823692],
      direction: [-27.787428828, -2.7350938572, 30.1604385217],
      "speed factor": 0.9211996254,
      "Uz factor": 0.9490336724,
      "Directional variance factor": 0.756880546,
      "Velocity stability factor": 0.2734736388,
      "Direction stability factor": 0.8390337018,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4501287935, 0.8252696193, 1.0983769687],
      direction: [-53.9957268264, -16.1236360238, 24.5228681316],
      "speed factor": 1.1724116027,
      "Uz factor": 0.9570748934,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4698681883,
      "Direction stability factor": 0.7818927918,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3395269312, 0.6841995642, 1.0868009022],
      direction: [-73.1113815873, -19.1865649492, 16.390606186],
      "speed factor": 0.8016461336,
      "Uz factor": 0.9884424209,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4254194786,
      "Direction stability factor": 0.7513833673,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.172957427, 0.4755311336, 0.7922233181],
      direction: [-46.9377328006, -9.8651570314, 86.6203025279],
      "speed factor": 0.8821675573,
      "Uz factor": 0.9981334783,
      "Directional variance factor": 0.1230971528,
      "Velocity stability factor": 0.399096674,
      "Direction stability factor": 0.6290054574,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0222144821, 0.3523113858, 0.9078727736],
      direction: [-125.1709502769, -5.4309636584, 150.1007669252],
      "speed factor": 0.479760268,
      "Uz factor": 0.9826099387,
      "Directional variance factor": 0.5172476748,
      "Velocity stability factor": 0.3505116022,
      "Direction stability factor": 0.2353563411,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.03721293, 0.3083176122, 0.8805129227],
      direction: [-177.841129025, 20.9741294921, 157.6026697837],
      "speed factor": 0.391544232,
      "Uz factor": 0.9999424168,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3207771154,
      "Direction stability factor": 0.06821167,
      score: 0.0,
    },
    id: "IDN:446.0",
    "45": {
      velocity: [0.111541104, 0.3982098139, 0.7312827859],
      direction: [-26.5923190937, 12.5502321237, 91.1248647798],
      "speed factor": 0.5409208604,
      "Uz factor": 0.9580844312,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4961696232,
      "Direction stability factor": 0.6730078226,
      score: 0.0,
    },
    "90": {
      velocity: [0.4371202548, 0.931239196, 1.2478988614],
      direction: [-13.0809586716, 5.6918257773, 27.2279036188],
      "speed factor": 1.5095659877,
      "Uz factor": 0.9592670775,
      "Directional variance factor": 0.4940599309,
      "Velocity stability factor": 0.4082471275,
      "Direction stability factor": 0.8880309381,
      score: 0.6695922336,
    },
    "135": {
      velocity: [0.4283364509, 0.9615138862, 1.2264470661],
      direction: [-59.8459044786, -22.3929651427, 8.6252931678],
      "speed factor": 0.8137775899,
      "Uz factor": 0.9279565032,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3568738391,
      "Direction stability factor": 0.8098022288,
      score: 0.0,
    },
    "180": {
      velocity: [0.4746241626, 0.8168192011, 1.2532880086],
      direction: [-53.6052968078, -31.1879574783, -14.3180974607],
      "speed factor": 1.5952428538,
      "Uz factor": 0.9650171367,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3864464649,
      "Direction stability factor": 0.8908688907,
      score: 0.0,
    },
    "225": {
      velocity: [0.4813351958, 0.7519316971, 1.0621211658],
      direction: [-24.2033021874, 0.710728144, 19.4100739454],
      "speed factor": 1.0264690546,
      "Uz factor": 0.9976593756,
      "Directional variance factor": 0.936824165,
      "Velocity stability factor": 0.4780008317,
      "Direction stability factor": 0.878851733,
      score: 0.7931321156,
    },
    "270": {
      velocity: [0.1115222564, 0.4740932998, 1.1319398273],
      direction: [-48.2062332721, 8.5306698842, 76.961202313],
      "speed factor": 0.4338106776,
      "Uz factor": 0.9882946882,
      "Directional variance factor": 0.2417182325,
      "Velocity stability factor": 0.1950913503,
      "Direction stability factor": 0.6523126789,
      score: 0.0,
    },
    "315": {
      velocity: [0.1300215406, 0.642737638, 0.979158735],
      direction: [-10.0853656715, 3.275508757, 52.4958941156],
      "speed factor": 0.791916734,
      "Uz factor": 0.9991759546,
      "Directional variance factor": 0.708843666,
      "Velocity stability factor": 0.3111493656,
      "Direction stability factor": 0.8261631673,
      score: 0.0,
    },
  },
  {
    position: [-46.42617996, 79.94135678, 45.87999908],
    "overall score": 0.0902673305,
    "0": {
      velocity: [0.0823284538, 0.3408796115, 0.8424519497],
      direction: [-168.893682751, 94.6756500134, 173.0263462474],
      "speed factor": 0.3493167395,
      "Uz factor": 0.9557861588,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4718427883,
      "Direction stability factor": 0.0502221417,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2426708055, 0.5835196977, 1.0603327857],
      direction: [-65.3836416709, 39.9325928295, 149.9193269743],
      "speed factor": 0.5180929316,
      "Uz factor": 0.960901323,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4712397065,
      "Direction stability factor": 0.4019361982,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1315359941, 0.4617140636, 1.0889871463],
      direction: [-70.7163381612, -6.450485621, 55.5726999632],
      "speed factor": 0.7152258647,
      "Uz factor": 0.9512086438,
      "Directional variance factor": 0.4266235004,
      "Velocity stability factor": 0.144465189,
      "Direction stability factor": 0.6491971163,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.331669578, 0.8175518507, 1.2161174189],
      direction: [-42.0273367223, -5.9395059491, 47.3230316595],
      "speed factor": 0.9196887171,
      "Uz factor": 0.9365755645,
      "Directional variance factor": 0.4720439156,
      "Velocity stability factor": 0.2678372465,
      "Direction stability factor": 0.7518045323,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3698133999, 0.8153609856, 1.0860703648],
      direction: [-67.8384756174, -18.5830023834, 28.5543369254],
      "speed factor": 1.1583349946,
      "Uz factor": 0.9381724056,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4142511819,
      "Direction stability factor": 0.7322421874,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2899191177, 0.6852515874, 1.0228360699],
      direction: [-109.9911029513, -11.811636131, 35.3565719601],
      "speed factor": 0.8028787423,
      "Uz factor": 0.9726827469,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4364586204,
      "Direction stability factor": 0.5962564586,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2376004009, 0.4957647796, 0.814329607],
      direction: [-37.511988136, -10.2366986971, 11.7683333392],
      "speed factor": 0.9197034089,
      "Uz factor": 0.9819370581,
      "Directional variance factor": 0.0900712269,
      "Velocity stability factor": 0.4403720548,
      "Direction stability factor": 0.8631102181,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0299857315, 0.2818941125, 0.9061188608],
      direction: [-157.762483426, 1.1941750162, 106.145471752],
      "speed factor": 0.3838694985,
      "Uz factor": 0.9367106459,
      "Directional variance factor": 0.8938511097,
      "Velocity stability factor": 0.3574967819,
      "Direction stability factor": 0.2669223467,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0509996239, 0.2455417305, 0.6498599533],
      direction: [-175.738459953, 33.4844643398, 178.9503456191],
      "speed factor": 0.3118227584,
      "Uz factor": 0.9983672239,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5176572466,
      "Direction stability factor": 0.0147533179,
      score: 0.0,
    },
    id: "IDN:354.0",
    "45": {
      velocity: [0.0885810802, 0.3380606419, 0.727748954],
      direction: [-108.8362889429, 9.9926994719, 84.0403630802],
      "speed factor": 0.4592153355,
      "Uz factor": 0.9285253418,
      "Directional variance factor": 0.1117600469,
      "Velocity stability factor": 0.4803767438,
      "Direction stability factor": 0.4642315222,
      score: 0.0,
    },
    "90": {
      velocity: [0.3766243776, 0.9390508672, 1.2683034361],
      direction: [-22.1659188573, 1.9669148842, 27.8482583539],
      "speed factor": 1.5222289354,
      "Uz factor": 0.9510088412,
      "Directional variance factor": 0.8251631214,
      "Velocity stability factor": 0.3492013233,
      "Direction stability factor": 0.86107173,
      score: 0.7241269762,
    },
    "135": {
      velocity: [0.4102656252, 0.9940214982, 1.2539833116],
      direction: [-90.142206201, -30.442488653, -0.4396565354],
      "speed factor": 0.8412904179,
      "Uz factor": 0.9114964653,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3201231681,
      "Direction stability factor": 0.7508262509,
      score: 0.0,
    },
    "180": {
      velocity: [0.5243398221, 0.8265354635, 1.2505508508],
      direction: [-75.7158395571, -35.2304529503, -11.6782352965],
      "speed factor": 1.6142186542,
      "Uz factor": 0.9487631348,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4277770232,
      "Direction stability factor": 0.8221177659,
      score: 0.0,
    },
    "225": {
      velocity: [0.47656506, 0.745633937, 1.0723280854],
      direction: [-21.9278729326, 3.7663265, 22.9198324988],
      "speed factor": 1.0178719228,
      "Uz factor": 0.9855185543,
      "Directional variance factor": 0.6652154222,
      "Velocity stability factor": 0.4645397446,
      "Direction stability factor": 0.8754230405,
      score: 0.7201503119,
    },
    "270": {
      velocity: [0.068906404, 0.3999425384, 1.2775605176],
      direction: [-33.8701230652, 2.6646516123, 95.4945555048],
      "speed factor": 0.3659603367,
      "Uz factor": 0.9609685236,
      "Directional variance factor": 0.7631420789,
      "Velocity stability factor": 0.0466097624,
      "Direction stability factor": 0.6406536706,
      score: 0.0,
    },
    "315": {
      velocity: [0.0437699058, 0.2920235919, 0.6998704963],
      direction: [-172.0161313861, 30.6151267685, 167.2889145635],
      "speed factor": 0.3598021269,
      "Uz factor": 0.9998843079,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.467747602,
      "Direction stability factor": 0.0574859835,
      score: 0.0,
    },
  },
  {
    position: [3.561135135, 102.0686813, 47.87999908],
    "overall score": 0.0879460671,
    "0": {
      velocity: [0.6574692163, 1.0429930878, 1.3245361254],
      direction: [-31.4095392254, -5.8114854224, 13.8256744785],
      "speed factor": 1.068808261,
      "Uz factor": 0.9376880519,
      "Directional variance factor": 0.483423518,
      "Velocity stability factor": 0.5365013703,
      "Direction stability factor": 0.8743466286,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.65428863, 0.9678351825, 1.2960986434],
      direction: [-43.5733284679, -10.9036606073, 10.9881470727],
      "speed factor": 0.8593172929,
      "Uz factor": 0.936544914,
      "Directional variance factor": 0.0307857238,
      "Velocity stability factor": 0.5849585046,
      "Direction stability factor": 0.8484403457,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2110701039, 0.5083499853, 0.9036306602],
      direction: [-157.0700785822, -4.1892221554, 78.9543405531],
      "speed factor": 0.7874680164,
      "Uz factor": 0.9928143839,
      "Directional variance factor": 0.6276246973,
      "Velocity stability factor": 0.38115938,
      "Direction stability factor": 0.3443766135,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3906118076, 1.0195082421, 1.4289218728],
      direction: [-52.9163291477, 5.5537587766, 48.4409563908],
      "speed factor": 1.1468755486,
      "Uz factor": 0.999903658,
      "Directional variance factor": 0.5063325532,
      "Velocity stability factor": 0.1404671694,
      "Direction stability factor": 0.7184519846,
      score: 0.5209259229,
    },
    "157.5": {
      velocity: [0.0851031671, 0.7393254061, 1.1656975884],
      direction: [-125.5603504255, 9.2226190702, 45.1493402118],
      "speed factor": 1.0503157563,
      "Uz factor": 0.9988961579,
      "Directional variance factor": 0.1802116382,
      "Velocity stability factor": 0.1162991271,
      "Direction stability factor": 0.5258064149,
      score: 0.3370308988,
    },
    "202.5": {
      velocity: [0.0399939525, 0.5018709045, 1.1049885565],
      direction: [-178.9721453728, -20.4976493945, 177.8835954787],
      "speed factor": 0.5880197697,
      "Uz factor": 0.9984330933,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1811234184,
      "Direction stability factor": 0.0087340532,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1394734998, 0.4991049723, 1.0621074898],
      direction: [-49.3886067061, -3.465683825, 38.7718155425],
      "speed factor": 0.925899869,
      "Uz factor": 0.9998239861,
      "Directional variance factor": 0.6919392156,
      "Velocity stability factor": 0.1047240914,
      "Direction stability factor": 0.7551099382,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2166725258, 0.5497078848, 0.911115425],
      direction: [-60.3209335803, 15.9970369309, 87.1685898806],
      "speed factor": 0.7485650841,
      "Uz factor": 0.8913988419,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4907374432,
      "Direction stability factor": 0.5903068793,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0465340038, 0.5523107488, 1.1047055436],
      direction: [-76.3092062977, 12.321584899, 112.9611682624],
      "speed factor": 0.7014003722,
      "Uz factor": 0.9694283873,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1477121643,
      "Direction stability factor": 0.4742489596,
      score: 0.0,
    },
    id: "IDN:667.0",
    "45": {
      velocity: [0.192866301, 0.631516494, 1.0857978788],
      direction: [-48.5508301682, -4.9983094082, 23.8213284508],
      "speed factor": 0.8578403479,
      "Uz factor": 0.9907654175,
      "Directional variance factor": 0.5557058304,
      "Velocity stability factor": 0.2740748825,
      "Direction stability factor": 0.7989662261,
      score: 0.0,
    },
    "90": {
      velocity: [0.2752778669, 0.7623262446, 1.1122544578],
      direction: [-13.0748284496, 10.0360213402, 40.9817880807],
      "speed factor": 1.2357531506,
      "Uz factor": 0.9991034427,
      "Directional variance factor": 0.1079092142,
      "Velocity stability factor": 0.3891263313,
      "Direction stability factor": 0.8498427319,
      score: 0.5491802523,
    },
    "135": {
      velocity: [0.5352514377, 1.1115936114, 1.3599384173],
      direction: [3.8345891315, 12.2937266662, 37.1049889401],
      "speed factor": 0.940797614,
      "Uz factor": 0.9996533269,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3354583173,
      "Direction stability factor": 0.9075822228,
      score: 0.0,
    },
    "180": {
      velocity: [0.0188449097, 0.4755738693, 1.1865474988],
      direction: [-164.6839870626, 4.8191185551, 175.4450770542],
      "speed factor": 0.9287928288,
      "Uz factor": 0.9962317904,
      "Directional variance factor": 0.5716339062,
      "Velocity stability factor": 0.0799007105,
      "Direction stability factor": 0.0551970441,
      score: 0.0,
    },
    "225": {
      velocity: [0.0393820551, 0.4506675738, 0.8906095814],
      direction: [-132.3338082059, -15.3469843322, 155.6983710633],
      "speed factor": 0.6152105572,
      "Uz factor": 0.9999993873,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2349332048,
      "Direction stability factor": 0.1999106131,
      score: 0.0,
    },
    "270": {
      velocity: [0.1765344355, 1.0364928955, 1.312580666],
      direction: [-33.9728326051, 1.5589044594, 16.1790078346],
      "speed factor": 0.9484244674,
      "Uz factor": 0.9988264927,
      "Directional variance factor": 0.8614307147,
      "Velocity stability factor": 0.1038830932,
      "Direction stability factor": 0.8606893321,
      score: 0.0,
    },
    "315": {
      velocity: [0.6068556187, 0.9098068117, 1.1627638112],
      direction: [-27.3579415147, -12.1813143052, 10.9928302509],
      "speed factor": 1.1209725342,
      "Uz factor": 0.9556417988,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.549027279,
      "Direction stability factor": 0.8934700784,
      score: 0.0,
    },
  },
  {
    position: [-46.71443165, 79.6549658, 45.87999908],
    "overall score": 0.0879126634,
    "0": {
      velocity: [0.075404224, 0.3417198516, 0.76688845],
      direction: [-172.8106261621, 82.2365736306, 178.8896095075],
      "speed factor": 0.3501777765,
      "Uz factor": 0.971438606,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5195354666,
      "Direction stability factor": 0.0230549009,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.114960877, 0.5726726802, 1.0702680397],
      direction: [-178.6539535158, 40.7839859842, 126.0859184124],
      "speed factor": 0.5084621289,
      "Uz factor": 0.9705268637,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3822282215,
      "Direction stability factor": 0.1535003558,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1450926589, 0.4603154291, 1.1160592983],
      direction: [-79.977958103, -7.9961830803, 54.9442380726],
      "speed factor": 0.7130592866,
      "Uz factor": 0.9564039466,
      "Directional variance factor": 0.2892281706,
      "Velocity stability factor": 0.1323883642,
      "Direction stability factor": 0.6252161217,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3332979892, 0.8301883026, 1.2504639549],
      direction: [-42.4761493537, -5.2575188582, 50.4673111982],
      "speed factor": 0.9339038427,
      "Uz factor": 0.9414515002,
      "Directional variance factor": 0.5326649904,
      "Velocity stability factor": 0.2407525603,
      "Direction stability factor": 0.7418237207,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3526442017, 0.8305567735, 1.104529483],
      direction: [-67.7691907047, -17.6692850036, 26.2302788588],
      "speed factor": 1.1799227492,
      "Uz factor": 0.9324050612,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3851146496,
      "Direction stability factor": 0.7388903623,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2958449113, 0.6909151039, 1.0863736116],
      direction: [-101.7472055143, -9.9280371279, 31.133254193],
      "speed factor": 0.8095144322,
      "Uz factor": 0.9657432047,
      "Directional variance factor": 0.1175078109,
      "Velocity stability factor": 0.3921608266,
      "Direction stability factor": 0.6308876119,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2747033045, 0.5125877992, 0.8189224682],
      direction: [-34.5937621973, -10.1979003118, 11.6328902997],
      "speed factor": 0.9509121376,
      "Uz factor": 0.9750578938,
      "Directional variance factor": 0.0935199723,
      "Velocity stability factor": 0.4719181046,
      "Direction stability factor": 0.871592632,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0453649107, 0.3130067657, 0.9651489834],
      direction: [-166.4451004215, -6.2207176558, 120.5133869351],
      "speed factor": 0.4262371751,
      "Uz factor": 0.9102793995,
      "Directional variance factor": 0.4470473195,
      "Velocity stability factor": 0.3254858116,
      "Direction stability factor": 0.2028930907,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.057756947, 0.270512201, 0.7135008861],
      direction: [-177.4515435958, 21.6131673796, 168.2185974945],
      "speed factor": 0.3435337061,
      "Uz factor": 0.9999242817,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4718412264,
      "Direction stability factor": 0.0398051636,
      score: 0.0,
    },
    id: "IDN:353.0",
    "45": {
      velocity: [0.110102494, 0.3342646168, 0.7471225467],
      direction: [-118.1643091743, 7.6253392946, 79.2835235723],
      "speed factor": 0.454058885,
      "Uz factor": 0.9397796244,
      "Directional variance factor": 0.3221920627,
      "Velocity stability factor": 0.4821228545,
      "Direction stability factor": 0.4515337979,
      score: 0.0,
    },
    "90": {
      velocity: [0.305702416, 0.9548148957, 1.3009940684],
      direction: [-26.3906273209, 1.61174199, 30.2462095047],
      "speed factor": 1.5477828869,
      "Uz factor": 0.9599086812,
      "Directional variance factor": 0.8567340453,
      "Velocity stability factor": 0.2735788913,
      "Direction stability factor": 0.8426754533,
      score: 0.7039159608,
    },
    "135": {
      velocity: [0.4301669755, 1.0036419888, 1.2498278958],
      direction: [-102.7590910619, -29.7260105774, 0.1784189186],
      "speed factor": 0.8494327232,
      "Uz factor": 0.9392433199,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3395083703,
      "Direction stability factor": 0.7140624723,
      score: 0.0,
    },
    "180": {
      velocity: [0.5217147286, 0.8287688977, 1.2361915059],
      direction: [-75.453596591, -33.940630768, -9.586418228],
      "speed factor": 1.6185805374,
      "Uz factor": 0.9540079924,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4370231074,
      "Direction stability factor": 0.8170356157,
      score: 0.0,
    },
    "225": {
      velocity: [0.5045158166, 0.7622580901, 1.1237719261],
      direction: [-19.9979767901, 4.4477701181, 22.7197422012],
      "speed factor": 1.040565711,
      "Uz factor": 0.9782067932,
      "Directional variance factor": 0.6046426562,
      "Velocity stability factor": 0.4434246162,
      "Direction stability factor": 0.8813396695,
      score: 0.7026866528,
    },
    "270": {
      velocity: [0.0515977245, 0.4402220369, 1.2732551588],
      direction: [-30.1685209438, 1.9702926296, 117.9859891559],
      "speed factor": 0.4028173784,
      "Uz factor": 0.9664261841,
      "Directional variance factor": 0.8248628774,
      "Velocity stability factor": 0.0363527013,
      "Direction stability factor": 0.5884596942,
      score: 0.0,
    },
    "315": {
      velocity: [0.044286917, 0.2878972668, 0.6677582699],
      direction: [-90.8168601154, 31.323090015, 143.59290039],
      "speed factor": 0.3547180836,
      "Uz factor": 0.9988570473,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4942176131,
      "Direction stability factor": 0.3488617764,
      score: 0.0,
    },
  },
  {
    position: [-5.248061244, 98.21002125, 46.87999908],
    "overall score": 0.0875630043,
    "0": {
      velocity: [0.2684101942, 1.1355649257, 1.4767805867],
      direction: [-47.7695768958, 7.9182069848, 32.0717063386],
      "speed factor": 1.1636713491,
      "Uz factor": 0.9779577704,
      "Directional variance factor": 0.2961593791,
      "Velocity stability factor": 0.1603870415,
      "Direction stability factor": 0.7782186577,
      score: 0.503245934,
    },
    "22.5": {
      velocity: [0.3498577231, 0.953104757, 1.2996162957],
      direction: [-22.4164396633, -2.3051152974, 16.0464269974],
      "speed factor": 0.8462385068,
      "Uz factor": 0.9649552265,
      "Directional variance factor": 0.7951008625,
      "Velocity stability factor": 0.3858163474,
      "Direction stability factor": 0.8931587037,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1005640233, 0.4535742749, 0.9038238698],
      direction: [-60.6577767177, 3.628759768, 62.315028692],
      "speed factor": 0.7026167894,
      "Uz factor": 0.9983621765,
      "Directional variance factor": 0.6774435762,
      "Velocity stability factor": 0.2822435281,
      "Direction stability factor": 0.6584088739,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0938367891, 0.6688218129, 1.2648102817],
      direction: [-178.6762402895, 18.2760682431, 151.9257699493],
      "speed factor": 0.752377815,
      "Uz factor": 0.9988399836,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0306458597,
      "Direction stability factor": 0.0816610827,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2205945968, 0.7052032481, 1.1632500337],
      direction: [-81.2291722353, 2.6398395369, 66.581969308],
      "speed factor": 1.0018404302,
      "Uz factor": 0.9898571015,
      "Directional variance factor": 0.7653475967,
      "Velocity stability factor": 0.2291044484,
      "Direction stability factor": 0.5894134957,
      score: 0.5433197591,
    },
    "202.5": {
      velocity: [0.1504409705, 0.6815859115, 1.2689808017],
      direction: [-167.1029036779, -29.1430536388, 177.6935546529],
      "speed factor": 0.7985838332,
      "Uz factor": 0.988799529,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1399523811,
      "Direction stability factor": 0.0422320602,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2239356841, 0.5040719526, 0.8816120779],
      direction: [-54.2962241882, -5.6678633891, 35.7752348452],
      "speed factor": 0.935114216,
      "Uz factor": 0.9828854846,
      "Directional variance factor": 0.496189921,
      "Velocity stability factor": 0.3618251252,
      "Direction stability factor": 0.7498015027,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0716732486, 0.4806681207, 0.9048881319],
      direction: [-165.6156293973, 20.3965183558, 170.2990032632],
      "speed factor": 0.6545501388,
      "Uz factor": 0.9054960951,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3889704362,
      "Direction stability factor": 0.0669037982,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1558726553, 0.5907598761, 1.2898910211],
      direction: [-88.5669991564, -22.4662752212, 114.77312144],
      "speed factor": 0.7502283776,
      "Uz factor": 0.8155626264,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.086622516,
      "Direction stability factor": 0.4351663317,
      score: 0.0,
    },
    id: "IDN:405.0",
    "45": {
      velocity: [0.078805402, 0.4870258633, 1.1118423468],
      direction: [-160.6566063256, 0.6211210535, 171.2488851532],
      "speed factor": 0.6615669423,
      "Uz factor": 0.9773072009,
      "Directional variance factor": 0.9447892397,
      "Velocity stability factor": 0.1601736524,
      "Direction stability factor": 0.0780403014,
      score: 0.0,
    },
    "90": {
      velocity: [0.16590516, 0.726554862, 1.0852498374],
      direction: [-18.3754330289, 22.4750597342, 65.346481057],
      "speed factor": 1.1777666926,
      "Uz factor": 0.9985384248,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.329009363,
      "Direction stability factor": 0.7674391275,
      score: 0.0,
    },
    "135": {
      velocity: [0.0608387705, 0.6840771692, 1.2215659692],
      direction: [-87.8953830536, 9.6071231476, 124.7345084517],
      "speed factor": 0.5789689343,
      "Uz factor": 0.9804836944,
      "Directional variance factor": 0.146033498,
      "Velocity stability factor": 0.0646734763,
      "Direction stability factor": 0.4093614125,
      score: 0.0,
    },
    "180": {
      velocity: [0.0537825946, 0.7802494592, 1.2754052257],
      direction: [-96.2899465287, -3.9379757462, 132.2384591424],
      "speed factor": 1.5238223737,
      "Uz factor": 0.9913287409,
      "Directional variance factor": 0.6499577115,
      "Velocity stability factor": 0.0374140425,
      "Direction stability factor": 0.3651988731,
      score: 0.354442375,
    },
    "225": {
      velocity: [0.137467191, 0.512723747, 0.8624980914],
      direction: [-152.6819980403, -14.3142054288, 102.3908349545],
      "speed factor": 0.6999240248,
      "Uz factor": 0.9965175973,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3483562851,
      "Direction stability factor": 0.2914643528,
      score: 0.0,
    },
    "270": {
      velocity: [0.3920116108, 0.8883495509, 1.2872239944],
      direction: [-50.4551143451, 4.0705767343, 28.3782255042],
      "speed factor": 0.8128685235,
      "Uz factor": 0.9863715534,
      "Directional variance factor": 0.638170957,
      "Velocity stability factor": 0.2938536034,
      "Direction stability factor": 0.7810185004,
      score: 0.0,
    },
    "315": {
      velocity: [0.7585258496, 0.9226113261, 1.1706235204],
      direction: [-15.0214590552, -1.5611059992, 22.0224296208],
      "speed factor": 1.1367489702,
      "Uz factor": 0.9349727587,
      "Directional variance factor": 0.8612350223,
      "Velocity stability factor": 0.6656915469,
      "Direction stability factor": 0.8971003092,
      score: 0.0,
    },
  },
  {
    position: [-40.58147966, 89.23484332, 47.87999908],
    "overall score": 0.0864598885,
    "0": {
      velocity: [0.0467091841, 0.4767109617, 1.121143711],
      direction: [-165.5413965413, -13.8871818355, 178.7766408674],
      "speed factor": 0.4885100582,
      "Uz factor": 0.975938771,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2534498052,
      "Direction stability factor": 0.0435610072,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4099775854, 1.1629636636, 1.4560689624],
      direction: [-34.7399468259, 3.3620720007, 30.3901235327],
      "speed factor": 1.0325671201,
      "Uz factor": 0.9983736937,
      "Directional variance factor": 0.7011491555,
      "Velocity stability factor": 0.3235204804,
      "Direction stability factor": 0.8190831379,
      score: 0.6657089779,
    },
    "67.5": {
      velocity: [0.2355623415, 0.6105897365, 1.0843200396],
      direction: [-34.5088168934, -3.2619303774, 62.165073063],
      "speed factor": 0.9458442069,
      "Uz factor": 0.996847211,
      "Directional variance factor": 0.7100506331,
      "Velocity stability factor": 0.2415887169,
      "Direction stability factor": 0.7314614168,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3451421548, 0.7434817201, 1.1527608496],
      direction: [-44.2356486457, 23.5272847096, 60.1631383476],
      "speed factor": 0.8363649948,
      "Uz factor": 0.9048559524,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3314378758,
      "Direction stability factor": 0.7100033695,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4557176471, 0.8329538413, 1.2658964769],
      direction: [-30.3148924935, 1.4460338045, 36.8174120312],
      "speed factor": 1.1833281212,
      "Uz factor": 0.9267972125,
      "Directional variance factor": 0.8714636618,
      "Velocity stability factor": 0.3374426843,
      "Direction stability factor": 0.8135213763,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.338321761, 0.6917931724, 1.0131247353],
      direction: [-64.2113991518, -31.000170328, 2.8051863215],
      "speed factor": 0.8105432259,
      "Uz factor": 0.8649454807,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4811425797,
      "Direction stability factor": 0.8138428181,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.206830727, 0.4296688131, 0.6782806134],
      direction: [-84.0216917197, -13.647330906, 40.7780890509],
      "speed factor": 0.7970874261,
      "Uz factor": 0.9999482637,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5425296163,
      "Direction stability factor": 0.6533339423,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.076798879, 0.5267491894, 1.021831139],
      direction: [-174.5866299252, -18.509209804, 166.2777781498],
      "speed factor": 0.7173010651,
      "Uz factor": 0.9989362373,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3069703131,
      "Direction stability factor": 0.053154422,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0125655685, 0.1640152223, 0.5417335664],
      direction: [-179.8579325591, 5.1486552852, 178.23917485],
      "speed factor": 0.2082891529,
      "Uz factor": 0.8265755529,
      "Directional variance factor": 0.5423417524,
      "Velocity stability factor": 0.5737898529,
      "Direction stability factor": 0.0052858128,
      score: 0.0,
    },
    id: "IDN:719.0",
    "45": {
      velocity: [0.0689740863, 0.5386380958, 0.9473708176],
      direction: [-80.1575431735, -5.2284222882, 116.2737509837],
      "speed factor": 0.7316760463,
      "Uz factor": 0.9993611197,
      "Directional variance factor": 0.5352513522,
      "Velocity stability factor": 0.2858912527,
      "Direction stability factor": 0.4543575162,
      score: 0.0,
    },
    "90": {
      velocity: [0.2586102545, 0.7899953721, 1.1647692338],
      direction: [-0.1413216951, 27.7973333138, 134.8173171668],
      "speed factor": 1.2806056159,
      "Uz factor": 0.9447322684,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3386330441,
      "Direction stability factor": 0.6251148921,
      score: 0.0,
    },
    "135": {
      velocity: [0.5888959897, 1.0230408167, 1.2558978442],
      direction: [-32.7288774044, 2.4420393078, 19.055089048],
      "speed factor": 0.8658509275,
      "Uz factor": 0.9244004964,
      "Directional variance factor": 0.7829298393,
      "Velocity stability factor": 0.4625226957,
      "Direction stability factor": 0.8561556487,
      score: 0.0,
    },
    "180": {
      velocity: [0.6048627088, 0.9017534419, 1.2058020331],
      direction: [-35.3872221087, -13.7692314191, 4.4362650869],
      "speed factor": 1.7611189013,
      "Uz factor": 0.9072660782,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5264857247,
      "Direction stability factor": 0.8893792022,
      score: 0.0,
    },
    "225": {
      velocity: [0.3845442233, 0.7511617979, 1.1008486267],
      direction: [-31.2632113031, -2.2640844555, 19.919894431],
      "speed factor": 1.0254180579,
      "Uz factor": 0.9999165565,
      "Directional variance factor": 0.7987480484,
      "Velocity stability factor": 0.3561994914,
      "Direction stability factor": 0.8578247063,
      score: 0.7176492381,
    },
    "270": {
      velocity: [0.0946713907, 0.3407594517, 0.9315794946],
      direction: [-126.9573348047, -46.5601268553, -0.3188875952],
      "speed factor": 0.3118059013,
      "Uz factor": 0.993319198,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.339844206,
      "Direction stability factor": 0.6482265355,
      score: 0.0,
    },
    "315": {
      velocity: [0.127962023, 0.5903144681, 1.0392792757],
      direction: [-8.3576525418, 8.9733501551, 29.0687991067],
      "speed factor": 0.7273261716,
      "Uz factor": 0.9949399763,
      "Directional variance factor": 0.2023688751,
      "Velocity stability factor": 0.2607066657,
      "Direction stability factor": 0.8960376343,
      score: 0.0,
    },
  },
  {
    position: [-35.61091946, 97.1184151, 46.87999908],
    "overall score": 0.0863190028,
    "0": {
      velocity: [0.4924598269, 0.9261248303, 1.2338319709],
      direction: [-52.7758229491, -27.7198376182, 25.2439364795],
      "speed factor": 0.9490473916,
      "Uz factor": 0.9325503693,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4848718051,
      "Direction stability factor": 0.783278446,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6878457801, 1.180056153, 1.4296274866],
      direction: [-22.4175358125, -0.5095695763, 12.868503131],
      "speed factor": 1.0477431253,
      "Uz factor": 0.9769130375,
      "Directional variance factor": 0.9547049266,
      "Velocity stability factor": 0.5203094648,
      "Direction stability factor": 0.9019832252,
      score: 0.8197452104,
    },
    "67.5": {
      velocity: [0.3083313201, 0.627417866, 1.0259337421],
      direction: [-33.9214250677, -2.1599258826, 62.216105738],
      "speed factor": 0.9719121013,
      "Uz factor": 0.9805326071,
      "Directional variance factor": 0.8080065882,
      "Velocity stability factor": 0.3587831076,
      "Direction stability factor": 0.7329513033,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1416810571, 0.7728226195, 1.1919364049],
      direction: [-160.7898331476, 28.415948677, 144.2030706646],
      "speed factor": 0.8693714569,
      "Uz factor": 0.9955802539,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1305786372,
      "Direction stability factor": 0.1527974894,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0939295823, 0.6459324192, 1.182202994],
      direction: [-35.7486682014, 8.9815161164, 169.7856870276],
      "speed factor": 0.9176378788,
      "Uz factor": 0.9900962527,
      "Directional variance factor": 0.2016430119,
      "Velocity stability factor": 0.1100193144,
      "Direction stability factor": 0.4290712355,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2189097721, 0.5508193528, 0.9258099928],
      direction: [-65.4608284542, 25.5931620272, 80.8964441822],
      "speed factor": 0.6453704849,
      "Uz factor": 0.6074958952,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4564629398,
      "Direction stability factor": 0.5934520205,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1304996318, 0.3806954469, 0.7901851601],
      direction: [-90.9160758532, -11.8112251488, 44.259773885],
      "speed factor": 0.7062359302,
      "Uz factor": 0.9999190471,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3598755659,
      "Direction stability factor": 0.6245115285,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1890097871, 0.6839506397, 1.1446720146],
      direction: [-63.6165779842, 0.0597698673, 36.8181716311],
      "speed factor": 0.9313702465,
      "Uz factor": 0.9834275385,
      "Directional variance factor": 0.9946871229,
      "Velocity stability factor": 0.2991749359,
      "Direction stability factor": 0.7210145844,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7667791668, 0.9892053366, 1.2797809661],
      direction: [-28.3428178887, -14.6955445133, 6.4704786198],
      "speed factor": 1.2562293832,
      "Uz factor": 0.9385680583,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5868106665,
      "Direction stability factor": 0.9032963986,
      score: 0.0,
    },
    id: "IDN:422.0",
    "45": {
      velocity: [0.3451558006, 0.5821523888, 0.8587771755],
      direction: [-61.6310554588, -13.0499877911, 33.1617062847],
      "speed factor": 0.7907850587,
      "Uz factor": 0.9570791775,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5824420747,
      "Direction stability factor": 0.7366867729,
      score: 0.0,
    },
    "90": {
      velocity: [0.3343631086, 0.7021525259, 1.1158938874],
      direction: [-26.6749260117, 26.7623650466, 63.637330622],
      "speed factor": 1.1382097918,
      "Uz factor": 0.9926970723,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4295938749,
      "Direction stability factor": 0.7491326205,
      score: 0.0,
    },
    "135": {
      velocity: [0.2442321991, 1.0752358918, 1.279563675],
      direction: [-6.8267226164, 9.405602477, 54.6760670427],
      "speed factor": 0.9100262463,
      "Uz factor": 0.9828982876,
      "Directional variance factor": 0.1639464465,
      "Velocity stability factor": 0.1657187053,
      "Direction stability factor": 0.8291589176,
      score: 0.0,
    },
    "180": {
      velocity: [0.1435671554, 0.629261149, 1.1427709269],
      direction: [-36.5837375144, -0.8687895023, 123.6179620477],
      "speed factor": 1.2289431366,
      "Uz factor": 0.9860987863,
      "Directional variance factor": 0.9227742665,
      "Velocity stability factor": 0.2126705132,
      "Direction stability factor": 0.554995279,
      score: 0.5613588344,
    },
    "225": {
      velocity: [0.2465455755, 0.7098012493, 1.063523036],
      direction: [-37.4327257426, -2.6691137758, 43.8298940718],
      "speed factor": 0.9689563828,
      "Uz factor": 0.9999860031,
      "Directional variance factor": 0.7627454421,
      "Velocity stability factor": 0.2657164997,
      "Direction stability factor": 0.7742705005,
      score: 0.0,
    },
    "270": {
      velocity: [0.3496722822, 0.935541172, 1.3489477343],
      direction: [-79.4969565071, -2.4294815851, 35.4448390878],
      "speed factor": 0.8560503806,
      "Uz factor": 0.9999674233,
      "Directional variance factor": 0.7840460813,
      "Velocity stability factor": 0.2117683214,
      "Direction stability factor": 0.6807172345,
      score: 0.0,
    },
    "315": {
      velocity: [0.7857829219, 0.939272095, 1.0777881168],
      direction: [-11.3759829463, -1.2984895958, 10.2600923179],
      "speed factor": 1.1572766956,
      "Uz factor": 0.9403301179,
      "Directional variance factor": 0.8845787026,
      "Velocity stability factor": 0.7631148828,
      "Direction stability factor": 0.9398997909,
      score: 0.0,
    },
  },
  {
    position: [-36.39892114, 97.47700053, 46.87999908],
    "overall score": 0.0862659772,
    "0": {
      velocity: [0.4785367489, 0.9173311819, 1.2234836799],
      direction: [-53.4768812232, -28.4359545912, 25.5284452157],
      "speed factor": 0.9400360912,
      "Uz factor": 0.9169246838,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.482387933,
      "Direction stability factor": 0.7805407599,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6672778859, 1.1571021242, 1.4276421245],
      direction: [-22.7779664298, 0.6819926467, 14.9320223917],
      "speed factor": 1.027362802,
      "Uz factor": 0.9728978589,
      "Directional variance factor": 0.9393784314,
      "Velocity stability factor": 0.5082926347,
      "Direction stability factor": 0.8952500311,
      score: 0.8095427821,
    },
    "67.5": {
      velocity: [0.32376288, 0.6385217561, 1.0566306184],
      direction: [-32.9358947241, -2.0588817717, 59.8129753907],
      "speed factor": 0.9891127673,
      "Uz factor": 0.9835460069,
      "Directional variance factor": 0.816988287,
      "Velocity stability factor": 0.3451427149,
      "Direction stability factor": 0.7423642497,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1099388944, 0.7636102053, 1.2068219146],
      direction: [-128.0323847121, 28.1737639624, 177.165422699],
      "speed factor": 0.8590081345,
      "Uz factor": 0.996667787,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0919793626,
      "Direction stability factor": 0.1522283127,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0781686417, 0.603129098, 1.1659847524],
      direction: [-39.9052563738, 8.7940630407, 160.9791303447],
      "speed factor": 0.856829739,
      "Uz factor": 0.9915941618,
      "Directional variance factor": 0.2183055075,
      "Velocity stability factor": 0.1103932912,
      "Direction stability factor": 0.4419878147,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1577867324, 0.4534503596, 0.8736859695],
      direction: [-164.1488437424, 26.0524270227, 111.656688136],
      "speed factor": 0.5312875755,
      "Uz factor": 0.7129401779,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4495435772,
      "Direction stability factor": 0.2338735226,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.123220784, 0.3760349654, 0.8235610797],
      direction: [-101.5812311792, -13.2442338274, 50.7299697014],
      "speed factor": 0.6975901754,
      "Uz factor": 0.9998294775,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3204262998,
      "Direction stability factor": 0.5769133309,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1823948179, 0.6912791742, 1.1310832072],
      direction: [-57.3547572063, -0.9646512926, 41.0724718658],
      "speed factor": 0.9413498833,
      "Uz factor": 0.9767076321,
      "Directional variance factor": 0.9142532184,
      "Velocity stability factor": 0.3042891284,
      "Direction stability factor": 0.7265910304,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7625596809, 0.9767099164, 1.2613778909],
      direction: [-27.7641849664, -14.2591455603, 6.5205387991],
      "speed factor": 1.2403609751,
      "Uz factor": 0.929090261,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5982346183,
      "Direction stability factor": 0.9047646562,
      score: 0.0,
    },
    id: "IDN:424.0",
    "45": {
      velocity: [0.3444522162, 0.5890750907, 0.8709407164],
      direction: [-57.3629994004, -12.9328486206, 33.8894379402],
      "speed factor": 0.8001887291,
      "Uz factor": 0.9515972876,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5719815091,
      "Direction stability factor": 0.7465210074,
      score: 0.0,
    },
    "90": {
      velocity: [0.3368557379, 0.7193103516, 1.1335495877],
      direction: [-24.0509306292, 26.9909255906, 70.1792116425],
      "speed factor": 1.1660231292,
      "Uz factor": 0.9953424217,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4185269933,
      "Direction stability factor": 0.7382496048,
      score: 0.0,
    },
    "135": {
      velocity: [0.230717551, 1.073586478, 1.2728632359],
      direction: [-7.7265444927, 9.0946353291, 64.9383383665],
      "speed factor": 0.9086302644,
      "Uz factor": 0.9832111832,
      "Directional variance factor": 0.1915879707,
      "Velocity stability factor": 0.160227742,
      "Direction stability factor": 0.7981531032,
      score: 0.0,
    },
    "180": {
      velocity: [0.0699322926, 0.5063547827, 1.0853417366],
      direction: [-42.2437385938, 0.0162641607, 148.3492172278],
      "speed factor": 0.9889077624,
      "Uz factor": 0.9858047408,
      "Directional variance factor": 0.9985542968,
      "Velocity stability factor": 0.199901142,
      "Direction stability factor": 0.4705751227,
      score: 0.0,
    },
    "225": {
      velocity: [0.2107915622, 0.7388727395, 1.1675794102],
      direction: [-31.1656410588, -4.2385366868, 55.3148914474],
      "speed factor": 1.0086421483,
      "Uz factor": 0.9994554002,
      "Directional variance factor": 0.6232411834,
      "Velocity stability factor": 0.1400576345,
      "Direction stability factor": 0.7597762986,
      score: 0.5707128538,
    },
    "270": {
      velocity: [0.3069147261, 0.9603705053, 1.3850550767],
      direction: [-78.5909706038, -2.6600700955, 22.6237403594],
      "speed factor": 0.8787700224,
      "Uz factor": 0.9999875757,
      "Directional variance factor": 0.7635493248,
      "Velocity stability factor": 0.1495594368,
      "Direction stability factor": 0.7188480251,
      score: 0.0,
    },
    "315": {
      velocity: [0.7758608778, 0.9272503955, 1.0715238757],
      direction: [-12.9732123037, -1.5062027124, 10.2562167867],
      "speed factor": 1.1424647654,
      "Uz factor": 0.9327436517,
      "Directional variance factor": 0.8661153145,
      "Velocity stability factor": 0.7601475415,
      "Direction stability factor": 0.9354738081,
      score: 0.0,
    },
  },
  {
    position: [-47.1184631, 79.54707423, 45.87999908],
    "overall score": 0.0861606075,
    "0": {
      velocity: [0.0896564025, 0.3585419763, 0.7531229173],
      direction: [-168.3083787105, 63.1263671211, 178.1505958053],
      "speed factor": 0.3674162664,
      "Uz factor": 0.9961297601,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5390030352,
      "Direction stability factor": 0.0376139597,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0814552399, 0.5662301083, 1.0845987547],
      direction: [-56.0874936401, 43.4118960963, 156.3065186228],
      "speed factor": 0.5027419261,
      "Uz factor": 0.9788009805,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3512937226,
      "Direction stability factor": 0.4100166326,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1322496207, 0.4309064486, 1.0852113346],
      direction: [-90.9342835632, -9.65965788, 55.7400374177],
      "speed factor": 0.6675028153,
      "Uz factor": 0.9562883623,
      "Directional variance factor": 0.141363744,
      "Velocity stability factor": 0.1484767469,
      "Direction stability factor": 0.5925713306,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3041623359, 0.8461259569, 1.2643084898],
      direction: [-43.4075312847, -4.7974692044, 54.0273328474],
      "speed factor": 0.9518325904,
      "Uz factor": 0.9505538615,
      "Directional variance factor": 0.5735582929,
      "Velocity stability factor": 0.2051727427,
      "Direction stability factor": 0.7293475996,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3342313092, 0.8468721922, 1.1263825192],
      direction: [-68.1295074897, -16.8495530118, 23.5633843719],
      "speed factor": 1.2031010969,
      "Uz factor": 0.9411056911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3521855176,
      "Direction stability factor": 0.7452975226,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3068676687, 0.7041230673, 1.1412334884],
      direction: [-83.5726435996, -8.7892108961, 29.6355748608],
      "speed factor": 0.8249896142,
      "Uz factor": 0.9648805552,
      "Directional variance factor": 0.2187368092,
      "Velocity stability factor": 0.3584543738,
      "Direction stability factor": 0.6855327265,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.277261833, 0.5163254332, 0.8161730849],
      direction: [-32.5145310685, -11.2567966866, 10.5842118817],
      "speed factor": 0.957845899,
      "Uz factor": 0.9677647493,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4770686253,
      "Direction stability factor": 0.8802812696,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0551558569, 0.3169988572, 0.9437107825],
      direction: [-130.2036810548, -4.5721474086, 139.8672248259],
      "speed factor": 0.4316734083,
      "Uz factor": 0.8896747649,
      "Directional variance factor": 0.593586897,
      "Velocity stability factor": 0.3483873854,
      "Direction stability factor": 0.2498030392,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0555317778, 0.2988151486, 0.8527173694],
      direction: [-175.9813535082, 18.5651964948, 170.82246707],
      "speed factor": 0.379476693,
      "Uz factor": 0.9999997835,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3579192437,
      "Direction stability factor": 0.0366560539,
      score: 0.0,
    },
    id: "IDN:352.0",
    "45": {
      velocity: [0.0833225553, 0.3199242947, 0.768279083],
      direction: [-128.4858234048, 4.9668333135, 97.9734968692],
      "speed factor": 0.4345792562,
      "Uz factor": 0.9352861891,
      "Directional variance factor": 0.5585037055,
      "Velocity stability factor": 0.4431520171,
      "Direction stability factor": 0.3709463326,
      score: 0.0,
    },
    "90": {
      velocity: [0.2173137289, 0.9578082174, 1.3286588104],
      direction: [-45.5558827776, 0.9192254023, 30.7017698677],
      "speed factor": 1.552635149,
      "Uz factor": 0.9699396589,
      "Directional variance factor": 0.9182910754,
      "Velocity stability factor": 0.188876422,
      "Direction stability factor": 0.7881731871,
      score: 0.6708784679,
    },
    "135": {
      velocity: [0.4309492278, 1.019397415, 1.2801284536],
      direction: [-114.739950989, -29.5367588936, -0.8747524026],
      "speed factor": 0.8627673333,
      "Uz factor": 0.9516128133,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3157222006,
      "Direction stability factor": 0.6837077817,
      score: 0.0,
    },
    "180": {
      velocity: [0.5248713197, 0.8403423461, 1.2446346123],
      direction: [-75.8947043845, -32.7131207174, -6.8057853581],
      "speed factor": 1.6411834107,
      "Uz factor": 0.9561436902,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4328575612,
      "Direction stability factor": 0.808086336,
      score: 0.0,
    },
    "225": {
      velocity: [0.5117494149, 0.7685354217, 1.1320278443],
      direction: [-19.4323598207, 4.1991833477, 23.4940442881],
      "speed factor": 1.0491349556,
      "Uz factor": 0.9754429359,
      "Directional variance factor": 0.626739258,
      "Velocity stability factor": 0.4425057749,
      "Direction stability factor": 0.8807599886,
      score: 0.7076912525,
    },
    "270": {
      velocity: [0.0463203904, 0.4467422487, 1.2666765066],
      direction: [-78.0942292258, 4.9461332256, 108.1092592058],
      "speed factor": 0.4087835827,
      "Uz factor": 0.9637010731,
      "Directional variance factor": 0.5603437133,
      "Velocity stability factor": 0.0373791852,
      "Direction stability factor": 0.4827680877,
      score: 0.0,
    },
    "315": {
      velocity: [0.0807007553, 0.2870095701, 0.6339976121],
      direction: [-10.9647956977, 33.3108598381, 174.8968012838],
      "speed factor": 0.353624353,
      "Uz factor": 0.9989360324,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5511456884,
      "Direction stability factor": 0.4837177862,
      score: 0.0,
    },
  },
  {
    position: [-44.01360092, 86.92164496, 47.87999908],
    "overall score": 0.0857884989,
    "0": {
      velocity: [0.1317068729, 0.4236361907, 1.0688406227],
      direction: [-164.0852153242, 13.7226651528, 151.3951893727],
      "speed factor": 0.434121631,
      "Uz factor": 0.9810205076,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3488506131,
      "Direction stability factor": 0.1236655425,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3182012669, 0.9069684228, 1.4074817263],
      direction: [-50.0865578327, 10.994869229, 85.596688781],
      "speed factor": 0.805275179,
      "Uz factor": 0.9975703609,
      "Directional variance factor": 0.0226782908,
      "Velocity stability factor": 0.2955912475,
      "Direction stability factor": 0.6231020927,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.302029877, 0.555529856, 1.0253320505],
      direction: [-33.9157167798, 3.3681166542, 61.3766702679],
      "speed factor": 0.8605527814,
      "Uz factor": 0.9949174536,
      "Directional variance factor": 0.700611853,
      "Velocity stability factor": 0.3536900688,
      "Direction stability factor": 0.7352989249,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2710050139, 0.7782498906, 1.1303745088],
      direction: [-21.0839024411, 13.9514042285, 53.3771959652],
      "speed factor": 0.8754767577,
      "Uz factor": 0.9174085497,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2885975787,
      "Direction stability factor": 0.7931636155,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4623871794, 0.8385779242, 1.2456020489],
      direction: [-36.4948568663, -9.5517571764, 16.8962948973],
      "speed factor": 1.1913179222,
      "Uz factor": 0.9270802122,
      "Directional variance factor": 0.1509549177,
      "Velocity stability factor": 0.3594935804,
      "Direction stability factor": 0.8516912451,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3784506197, 0.7061765978, 1.0982349514],
      direction: [-71.8221554371, -30.5675312519, 3.8781142292],
      "speed factor": 0.8273956444,
      "Uz factor": 0.954478912,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4465563198,
      "Direction stability factor": 0.7897214731,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0734995131, 0.4254025999, 0.6893961879],
      direction: [-106.9896801121, -8.554122336, 35.3918585896],
      "speed factor": 0.7891730866,
      "Uz factor": 0.999958477,
      "Directional variance factor": 0.2396335701,
      "Velocity stability factor": 0.4023659857,
      "Direction stability factor": 0.6044957258,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0673609511, 0.4594968982, 1.0961297499],
      direction: [-160.4086491397, -19.0927162411, 177.8591060588],
      "speed factor": 0.6257202121,
      "Uz factor": 0.9937944268,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2455629837,
      "Direction stability factor": 0.0603673467,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0499989808, 0.2066510729, 0.5253823166],
      direction: [-177.6463550364, 39.5187781885, 179.7435372504],
      "speed factor": 0.2624340369,
      "Uz factor": 0.9554986929,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6171098738,
      "Direction stability factor": 0.0072502992,
      score: 0.0,
    },
    id: "IDN:716.0",
    "45": {
      velocity: [0.1323280921, 0.5022136601, 0.9107397627],
      direction: [-45.4263448542, 1.9727924889, 87.9313691403],
      "speed factor": 0.6821977652,
      "Uz factor": 0.9991428411,
      "Directional variance factor": 0.8246406677,
      "Velocity stability factor": 0.3671759431,
      "Direction stability factor": 0.6295619056,
      score: 0.0,
    },
    "90": {
      velocity: [0.3789825624, 0.8435397502, 1.1878844729],
      direction: [-7.1548692117, 21.2532851168, 45.0887321849],
      "speed factor": 1.3674026198,
      "Uz factor": 0.9430436424,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4096168483,
      "Direction stability factor": 0.854878885,
      score: 0.0,
    },
    "135": {
      velocity: [0.5277140377, 1.0117600842, 1.2611332612],
      direction: [-50.5094943331, -6.7716562868, 10.5105596113],
      "speed factor": 0.8563034759,
      "Uz factor": 0.9109301611,
      "Directional variance factor": 0.3980749967,
      "Velocity stability factor": 0.4090028618,
      "Direction stability factor": 0.8304998502,
      score: 0.0,
    },
    "180": {
      velocity: [0.5570933707, 0.8801234767, 1.2646838405],
      direction: [-47.3771272276, -25.1889793068, -9.0136905956],
      "speed factor": 1.7188757129,
      "Uz factor": 0.9305411336,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4424492208,
      "Direction stability factor": 0.8934348982,
      score: 0.0,
    },
    "225": {
      velocity: [0.3757915062, 0.785039036, 1.0987323709],
      direction: [-32.368252536, -0.2774605721, 21.8176791443],
      "speed factor": 1.0716641953,
      "Uz factor": 0.9999996465,
      "Directional variance factor": 0.975336838,
      "Velocity stability factor": 0.3502347687,
      "Direction stability factor": 0.8494835231,
      score: 0.7561346632,
    },
    "270": {
      velocity: [0.0656989824, 0.379526212, 1.0937759435],
      direction: [-157.5386438552, -7.2327561238, 179.2645526132],
      "speed factor": 0.3472787387,
      "Uz factor": 0.9781520591,
      "Directional variance factor": 0.3570883446,
      "Velocity stability factor": 0.1890495989,
      "Direction stability factor": 0.0644355654,
      score: 0.0,
    },
    "315": {
      velocity: [0.1957695107, 0.8145766486, 1.1287729792],
      direction: [-19.6804015799, 4.7162750522, 44.7531730499],
      "speed factor": 1.0036394961,
      "Uz factor": 0.9999761613,
      "Directional variance factor": 0.5807755509,
      "Velocity stability factor": 0.2431140275,
      "Direction stability factor": 0.8210178483,
      score: 0.6164813187,
    },
  },
  {
    position: [-35.48357818, 96.68256738, 46.87999908],
    "overall score": 0.0856730506,
    "0": {
      velocity: [0.4975880819, 0.9528375701, 1.2622820529],
      direction: [-52.5386962292, -27.8958301997, 24.3647935257],
      "speed factor": 0.9764212997,
      "Uz factor": 0.9400782877,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4686670815,
      "Direction stability factor": 0.7863791951,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7184138358, 1.1970139795, 1.4393067082],
      direction: [-22.9200447469, -0.7744333614, 12.21512865],
      "speed factor": 1.0627995666,
      "Uz factor": 0.9783635488,
      "Directional variance factor": 0.931161479,
      "Velocity stability factor": 0.5338177192,
      "Direction stability factor": 0.9024022961,
      score: 0.8174459476,
    },
    "67.5": {
      velocity: [0.3009306614, 0.6267727446, 1.020432454],
      direction: [-34.6771204931, -2.5083360965, 62.9467238948],
      "speed factor": 0.9709127653,
      "Uz factor": 0.9787235407,
      "Directional variance factor": 0.7770367914,
      "Velocity stability factor": 0.3570859164,
      "Direction stability factor": 0.7288226545,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2231393683, 0.7746312296, 1.1972823206],
      direction: [-164.0520668251, 28.5759315718, 141.9912718178],
      "speed factor": 0.8714060169,
      "Uz factor": 0.9943817973,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1935859265,
      "Direction stability factor": 0.1498796149,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.113319968, 0.654156233, 1.1543149484],
      direction: [-32.2824707386, 9.1877824536, 174.1228853745],
      "speed factor": 0.9293209633,
      "Uz factor": 0.9905830865,
      "Directional variance factor": 0.1833082264,
      "Velocity stability factor": 0.148683211,
      "Direction stability factor": 0.4266517886,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2071593879, 0.5592239674, 0.9294812349],
      direction: [-63.8229592466, 30.5884782713, 72.6947872894],
      "speed factor": 0.6552177972,
      "Uz factor": 0.5728857334,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.444605219,
      "Direction stability factor": 0.6207840374,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.125102545, 0.3825541736, 0.7767290213],
      direction: [-87.7413764046, -11.4298756256, 45.5790119096],
      "speed factor": 0.7096840923,
      "Uz factor": 0.9999792858,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3676956498,
      "Direction stability factor": 0.629665588,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2019714999, 0.6798857005, 1.1551890281],
      direction: [-69.4014133525, 0.7003566365, 40.7172452318],
      "speed factor": 0.9258348128,
      "Uz factor": 0.9888741292,
      "Directional variance factor": 0.9377460768,
      "Velocity stability factor": 0.3009677309,
      "Direction stability factor": 0.6941148373,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7748081099, 1.0010298291, 1.3028199453],
      direction: [-28.2865870044, -14.6109325, 6.9532937656],
      "speed factor": 1.2712457548,
      "Uz factor": 0.9384061767,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5747210659,
      "Direction stability factor": 0.9021114423,
      score: 0.0,
    },
    id: "IDN:421.0",
    "45": {
      velocity: [0.35137884, 0.5870594763, 0.8793447913],
      direction: [-63.9839634352, -13.5878945285, 32.3485781051],
      "speed factor": 0.7974507557,
      "Uz factor": 0.9608172107,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5707803881,
      "Direction stability factor": 0.7324096068,
      score: 0.0,
    },
    "90": {
      velocity: [0.3249759423, 0.7030129037, 1.1041618717],
      direction: [-27.3502412068, 26.9938390993, 62.4818009483],
      "speed factor": 1.1396044892,
      "Uz factor": 0.9913538186,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4313052809,
      "Direction stability factor": 0.7504665496,
      score: 0.0,
    },
    "135": {
      velocity: [0.2753462342, 1.0741747745, 1.2769586003],
      direction: [-7.5786672009, 9.1788926091, 45.6705450618],
      "speed factor": 0.9091281693,
      "Uz factor": 0.9815914648,
      "Directional variance factor": 0.1840984347,
      "Velocity stability factor": 0.1928899284,
      "Direction stability factor": 0.8520855215,
      score: 0.0,
    },
    "180": {
      velocity: [0.1420367168, 0.6627676138, 1.1405084162],
      direction: [-45.386428938, -0.8169319279, 121.5347235136],
      "speed factor": 1.2943810555,
      "Uz factor": 0.9854174448,
      "Directional variance factor": 0.9273838286,
      "Velocity stability factor": 0.2132473544,
      "Direction stability factor": 0.5363301321,
      score: 0.5533228618,
    },
    "225": {
      velocity: [0.2698213754, 0.6933507582, 1.0612994912],
      direction: [-38.3299512588, -2.1602716919, 35.3618565047],
      "speed factor": 0.9464996621,
      "Uz factor": 0.9999952461,
      "Directional variance factor": 0.8079758496,
      "Velocity stability factor": 0.2886348163,
      "Direction stability factor": 0.795300534,
      score: 0.0,
    },
    "270": {
      velocity: [0.2562001959, 0.9043073387, 1.3315627414],
      direction: [-81.7594053181, -2.6027907813, 42.7809337054],
      "speed factor": 0.8274704146,
      "Uz factor": 0.999788947,
      "Directional variance factor": 0.7686408194,
      "Velocity stability factor": 0.1517505784,
      "Direction stability factor": 0.6540546138,
      score: 0.0,
    },
    "315": {
      velocity: [0.794539189, 0.954826163, 1.0972910546],
      direction: [-10.4668084642, -1.0183228066, 9.9033197668],
      "speed factor": 1.1764408553,
      "Uz factor": 0.9408847568,
      "Directional variance factor": 0.9094824172,
      "Velocity stability factor": 0.7543967971,
      "Direction stability factor": 0.9434163105,
      score: 0.0,
    },
  },
  {
    position: [-35.48417584, 96.51135055, 46.87999908],
    "overall score": 0.0853940274,
    "0": {
      velocity: [0.4994408491, 0.9650196095, 1.2736531132],
      direction: [-52.7663207269, -28.0422643637, 23.9481160662],
      "speed factor": 0.9889048574,
      "Uz factor": 0.9422428024,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4620534784,
      "Direction stability factor": 0.7869043422,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7324360958, 1.2031332891, 1.4437273206],
      direction: [-23.1903916592, -0.792564456, 12.1086975076],
      "speed factor": 1.0682327526,
      "Uz factor": 0.9788605647,
      "Directional variance factor": 0.9295498261,
      "Velocity stability factor": 0.5400268499,
      "Direction stability factor": 0.9019469745,
      score: 0.8183676563,
    },
    "67.5": {
      velocity: [0.2990767265, 0.6272142152, 1.0202114987],
      direction: [-34.927243377, -2.658433234, 63.1991882283],
      "speed factor": 0.9715966326,
      "Uz factor": 0.9781953212,
      "Directional variance factor": 0.7636948236,
      "Velocity stability factor": 0.35562676,
      "Direction stability factor": 0.7274265789,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2141318621, 0.7735748675, 1.1994393388],
      direction: [-165.4764886291, 28.6294569997, 140.3869148534],
      "speed factor": 0.8702176834,
      "Uz factor": 0.9939308282,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1843437207,
      "Direction stability factor": 0.1503794348,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0906761911, 0.655793809, 1.1457022532],
      direction: [-31.2283971451, 9.2308258306, 173.9576931904],
      "speed factor": 0.9316473704,
      "Uz factor": 0.9907871879,
      "Directional variance factor": 0.1794821484,
      "Velocity stability factor": 0.1372087125,
      "Direction stability factor": 0.430038638,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2046946628, 0.5662660926, 0.9531267246],
      direction: [-76.0766044041, 34.1034241351, 73.2764110228],
      "speed factor": 0.6634687414,
      "Uz factor": 0.5555647465,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4245290202,
      "Direction stability factor": 0.5851305127,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1084377976, 0.3817552177, 0.7758804062],
      direction: [-88.849665993, -11.4133216123, 46.0829993611],
      "speed factor": 0.7082019327,
      "Uz factor": 0.9999882872,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3523484997,
      "Direction stability factor": 0.6251870407,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2047899478, 0.6772385633, 1.1584493001],
      direction: [-69.498111248, 0.9076110998, 48.4773926574],
      "speed factor": 0.9222300719,
      "Uz factor": 0.9909147064,
      "Directional variance factor": 0.9193234578,
      "Velocity stability factor": 0.3006437238,
      "Direction stability factor": 0.6722902669,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7790979076, 1.0067705136, 1.3127662615],
      direction: [-28.248072651, -14.6256997666, 7.1523438294],
      "speed factor": 1.2785360678,
      "Uz factor": 0.937803746,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.570165111,
      "Direction stability factor": 0.9016655098,
      score: 0.0,
    },
    id: "IDN:420.0",
    "45": {
      velocity: [0.3536201783, 0.5899644828, 0.8906136554],
      direction: [-64.7422605042, -13.8044098388, 32.1063181028],
      "speed factor": 0.8013968629,
      "Uz factor": 0.9622209376,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5634412953,
      "Direction stability factor": 0.7309761705,
      score: 0.0,
    },
    "90": {
      velocity: [0.3374385065, 0.7045919842, 1.0996247035],
      direction: [-26.3032522113, 27.0504509259, 62.0929099987],
      "speed factor": 1.142164225,
      "Uz factor": 0.991042137,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4437126636,
      "Direction stability factor": 0.754455105,
      score: 0.0,
    },
    "135": {
      velocity: [0.2961142172, 1.0735753243, 1.2747374282],
      direction: [-8.000328948, 9.0627093725, 46.2732543113],
      "speed factor": 0.9086208244,
      "Uz factor": 0.9811329468,
      "Directional variance factor": 0.1944258336,
      "Velocity stability factor": 0.2114148381,
      "Direction stability factor": 0.8492400465,
      score: 0.0,
    },
    "180": {
      velocity: [0.1370105618, 0.6696210535, 1.1394789641],
      direction: [-48.4698065099, -0.8138003068, 121.8125676965],
      "speed factor": 1.3077657807,
      "Uz factor": 0.9850778371,
      "Directional variance factor": 0.927662195,
      "Velocity stability factor": 0.2100981249,
      "Direction stability factor": 0.526993405,
      score: 0.5479367825,
    },
    "225": {
      velocity: [0.2603984919, 0.6885427439, 1.0617097472],
      direction: [-37.931039238, -2.1259120091, 32.0378216168],
      "speed factor": 0.9399361965,
      "Uz factor": 0.9999799793,
      "Directional variance factor": 0.8110300436,
      "Velocity stability factor": 0.2797969812,
      "Direction stability factor": 0.8056420532,
      score: 0.0,
    },
    "270": {
      velocity: [0.2440861834, 0.890476544, 1.3316455207],
      direction: [-83.2754173663, -2.7351036049, 44.4642633015],
      "speed factor": 0.8148147909,
      "Uz factor": 0.9996697328,
      "Directional variance factor": 0.7568796796,
      "Velocity stability factor": 0.1421297099,
      "Direction stability factor": 0.6451675537,
      score: 0.0,
    },
    "315": {
      velocity: [0.7986824953, 0.9599695763, 1.1037065465],
      direction: [-10.3368579663, -0.8755555852, 9.684864154],
      "speed factor": 1.1827780523,
      "Uz factor": 0.940686288,
      "Directional variance factor": 0.9221728369,
      "Velocity stability factor": 0.7525535183,
      "Direction stability factor": 0.9443841052,
      score: 0.0,
    },
  },
  {
    position: [-52.57487212, 86.24083772, 45.87999908],
    "overall score": 0.0848206799,
    "0": {
      velocity: [0.1293728659, 0.6744362633, 1.4180474691],
      direction: [-41.1547017619, 0.8572395646, 89.0906483142],
      "speed factor": 0.6911292685,
      "Uz factor": 0.9925058897,
      "Directional variance factor": 0.9238009276,
      "Velocity stability factor": 0.1045892031,
      "Direction stability factor": 0.6382073609,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0522753543, 0.4812318642, 1.1489154738],
      direction: [-167.1221297572, 30.5009530609, 170.1551130117],
      "speed factor": 0.4272740548,
      "Uz factor": 0.9944702656,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2908319508,
      "Direction stability factor": 0.0631187701,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0372363704, 0.4410180718, 0.9626543603],
      direction: [-117.6163837865, 2.7878478591, 177.7542292704],
      "speed factor": 0.6831663937,
      "Uz factor": 0.996134776,
      "Directional variance factor": 0.7521913014,
      "Velocity stability factor": 0.1730885662,
      "Direction stability factor": 0.1795260748,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0865956294, 0.2840334249, 0.7126440011],
      direction: [-172.9156388989, 98.5936114781, 179.3238093195],
      "speed factor": 0.3195177602,
      "Uz factor": 0.8911170146,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4817452444,
      "Direction stability factor": 0.0215570883,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0413932245, 0.4343145544, 0.9684680014],
      direction: [-177.151178669, -13.0514880468, 169.4879793064],
      "speed factor": 0.617004929,
      "Uz factor": 0.9972343109,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2418461788,
      "Direction stability factor": 0.0371134501,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5652230439, 0.9874574085, 1.3661713328],
      direction: [-28.8390233568, -5.0952476166, 22.4487826941],
      "speed factor": 1.1569598331,
      "Uz factor": 0.9999578157,
      "Directional variance factor": 0.5470891007,
      "Velocity stability factor": 0.3841491831,
      "Direction stability factor": 0.8575338721,
      score: 0.661576507,
    },
    "247.5": {
      velocity: [0.1948724911, 0.4638274323, 0.8020520296],
      direction: [-41.1541977216, -3.5864150008, 47.8099810686],
      "speed factor": 0.8604557811,
      "Uz factor": 0.9002560423,
      "Directional variance factor": 0.6812075555,
      "Velocity stability factor": 0.4108246402,
      "Direction stability factor": 0.7528772811,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.307288337, 0.9288197107, 1.2283712708],
      direction: [-60.0519315698, -22.4614274424, 15.5630017887],
      "speed factor": 1.2648208697,
      "Uz factor": 0.7859153007,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3245333051,
      "Direction stability factor": 0.7899585184,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4018791204, 0.9648009218, 1.2141023061],
      direction: [-6.3486858139, 4.5153402973, 22.8520630094],
      "speed factor": 1.225237291,
      "Uz factor": 0.9599116123,
      "Directional variance factor": 0.598636418,
      "Velocity stability factor": 0.345807447,
      "Direction stability factor": 0.9188868088,
      score: 0.6955543707,
    },
    id: "IDN:345.0",
    "45": {
      velocity: [0.0601252909, 0.3861196272, 0.7420952037],
      direction: [-142.2100293866, 26.0616953669, 172.7900571522],
      "speed factor": 0.524497774,
      "Uz factor": 0.9993215469,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4455800406,
      "Direction stability factor": 0.1249997596,
      score: 0.0,
    },
    "90": {
      velocity: [0.0480918133, 0.2589741416, 0.7875189554],
      direction: [-175.8138348491, -122.443350085, 177.8356522653],
      "speed factor": 0.4198046619,
      "Uz factor": 0.8219482909,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4603235312,
      "Direction stability factor": 0.0176403136,
      score: 0.0,
    },
    "135": {
      velocity: [0.0235751633, 0.1982893278, 0.5628314455],
      direction: [-174.4911772257, 96.4413015649, 172.3884160234],
      "speed factor": 0.167822237,
      "Uz factor": 0.4470944023,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5654614586,
      "Direction stability factor": 0.0364455743,
      score: 0.0,
    },
    "180": {
      velocity: [0.0675475433, 0.6951124995, 1.1018955769],
      direction: [-140.2329375292, -37.1819495843, 36.6698514036],
      "speed factor": 1.3575504173,
      "Uz factor": 0.9890945969,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.18497835,
      "Direction stability factor": 0.5086033641,
      score: 0.0,
    },
    "225": {
      velocity: [0.569520626, 0.8311800801, 1.1402586828],
      direction: [-12.5433110256, 4.3058574997, 24.593457165],
      "speed factor": 1.1346517699,
      "Uz factor": 0.9468218817,
      "Directional variance factor": 0.6172571111,
      "Velocity stability factor": 0.4870317012,
      "Direction stability factor": 0.8968423106,
      score: 0.0,
    },
    "270": {
      velocity: [0.8483828565, 1.1055020894, 1.3334321455],
      direction: [-22.2064541341, -0.2061255975, 17.7732202269],
      "speed factor": 1.011570108,
      "Uz factor": 0.800218599,
      "Directional variance factor": 0.9816777247,
      "Velocity stability factor": 0.6173915667,
      "Direction stability factor": 0.888945349,
      score: 0.0,
    },
    "315": {
      velocity: [0.6221073939, 0.9699799089, 1.207200137],
      direction: [-20.8703162142, 2.7942930525, 18.5633694382],
      "speed factor": 1.1951117783,
      "Uz factor": 0.9040628589,
      "Directional variance factor": 0.7516183953,
      "Velocity stability factor": 0.5253517218,
      "Direction stability factor": 0.8904619843,
      score: 0.0,
    },
  },
  {
    position: [-45.82059079, 49.13735874, 46.87999908],
    "overall score": 0.0847456623,
    "0": {
      velocity: [0.2950980092, 0.8662420583, 1.3160476369],
      direction: [-81.156349946, -15.0050276365, 80.2474951766],
      "speed factor": 0.8876824581,
      "Uz factor": 0.9977836842,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2906127602,
      "Direction stability factor": 0.5516559858,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2554497051, 0.7878305926, 1.2709359186],
      direction: [-169.9062076779, -2.4735550091, 90.8072244681],
      "speed factor": 0.6994956005,
      "Uz factor": 0.9991476615,
      "Directional variance factor": 0.7801284436,
      "Velocity stability factor": 0.3433120272,
      "Direction stability factor": 0.2757960218,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2560802639, 0.547101309, 0.9182091559],
      direction: [-49.1248893657, 11.7056037783, 79.2408374502],
      "speed factor": 0.8474964002,
      "Uz factor": 0.9600692261,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4083517313,
      "Direction stability factor": 0.6434285366,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.495415232, 0.8662458624, 1.1699606915],
      direction: [-40.8998099326, -8.1807962446, 12.3564612779],
      "speed factor": 0.9744660786,
      "Uz factor": 0.9303652322,
      "Directional variance factor": 0.2728181116,
      "Velocity stability factor": 0.4415984323,
      "Direction stability factor": 0.8520659133,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5873085334, 0.992979756, 1.3217719401],
      direction: [-26.9464251307, -5.0679702909, 12.5032662884],
      "speed factor": 1.4106674474,
      "Uz factor": 0.984553423,
      "Directional variance factor": 0.5495137519,
      "Velocity stability factor": 0.3993621096,
      "Direction stability factor": 0.8904175238,
      score: 0.6824277273,
    },
    "202.5": {
      velocity: [0.0687377374, 0.3539299315, 0.8951850069],
      direction: [-176.4026951612, -34.4481518078, 123.497483673],
      "speed factor": 0.4146839255,
      "Uz factor": 0.9737166099,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3645429636,
      "Direction stability factor": 0.1669439477,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1582391619, 0.5539635806, 1.0557126387],
      direction: [-43.5742315653, -0.4249539448, 27.9493764997],
      "speed factor": 1.0276691982,
      "Uz factor": 0.9994325944,
      "Directional variance factor": 0.962226316,
      "Velocity stability factor": 0.1291385413,
      "Direction stability factor": 0.8013233109,
      score: 0.6735028698,
    },
    "292.5": {
      velocity: [0.0620145604, 0.2204060656, 0.6525522686],
      direction: [-179.0613338784, 86.8956478798, 179.1412215894],
      "speed factor": 0.300138109,
      "Uz factor": 0.8103876831,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5669352463,
      "Direction stability factor": 0.0049929015,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0431070705, 0.4131826431, 0.9580468716],
      direction: [-175.9143717161, -8.8198899046, 178.8866893613],
      "speed factor": 0.5247163128,
      "Uz factor": 0.996879024,
      "Directional variance factor": 0.2160097863,
      "Velocity stability factor": 0.2630759441,
      "Direction stability factor": 0.014441497,
      score: 0.0,
    },
    id: "IDN:477.0",
    "45": {
      velocity: [0.0666739581, 0.5293202008, 1.0119618343],
      direction: [-142.8658477909, 0.5383482588, 149.0136205368],
      "speed factor": 0.7190187897,
      "Uz factor": 0.9789183988,
      "Directional variance factor": 0.9521468214,
      "Velocity stability factor": 0.2315108685,
      "Direction stability factor": 0.1892236991,
      score: 0.0,
    },
    "90": {
      velocity: [0.6211974834, 0.9772904924, 1.2645001854],
      direction: [-38.0260317378, -0.1623622228, 29.7700614353],
      "speed factor": 1.5842164868,
      "Uz factor": 0.9490469154,
      "Directional variance factor": 0.9855678024,
      "Velocity stability factor": 0.5304806778,
      "Direction stability factor": 0.811677519,
      score: 0.0,
    },
    "135": {
      velocity: [0.2183346817, 0.6518345883, 1.096376125],
      direction: [-101.7415177619, -24.819364093, 18.6725635411],
      "speed factor": 0.5516804155,
      "Uz factor": 0.938169222,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2924647138,
      "Direction stability factor": 0.6655164408,
      score: 0.0,
    },
    "180": {
      velocity: [0.1391134422, 0.743268671, 1.1607473245],
      direction: [-62.3757389205, -11.7430599716, 51.8197497283],
      "speed factor": 1.4515991228,
      "Uz factor": 0.9961510321,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1949965531,
      "Direction stability factor": 0.6827903093,
      score: 0.0,
    },
    "225": {
      velocity: [0.0531730291, 0.4356248048, 0.8662449409],
      direction: [-80.4966920236, -1.4678357116, 159.1061799848],
      "speed factor": 0.5946755313,
      "Uz factor": 0.9999889758,
      "Directional variance factor": 0.8695257145,
      "Velocity stability factor": 0.2692267312,
      "Direction stability factor": 0.3344364666,
      score: 0.0,
    },
    "270": {
      velocity: [0.0517901692, 0.3524613727, 0.9502262193],
      direction: [-179.8562598766, 34.4956383056, 161.3797991904],
      "speed factor": 0.3225135368,
      "Uz factor": 0.9887096778,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2913107649,
      "Direction stability factor": 0.0521220581,
      score: 0.0,
    },
    "315": {
      velocity: [0.0930125305, 0.5731628075, 1.1983049211],
      direction: [-179.052428085, -1.6079330975, 157.7078838784],
      "speed factor": 0.7061936189,
      "Uz factor": 0.9977494337,
      "Directional variance factor": 0.8570726136,
      "Velocity stability factor": 0.1033470568,
      "Direction stability factor": 0.064554689,
      score: 0.0,
    },
  },
  {
    position: [-35.95052285, 97.41273697, 46.87999908],
    "overall score": 0.0845475744,
    "0": {
      velocity: [0.4841301321, 0.9138673558, 1.2203319118],
      direction: [-53.3087722139, -27.9887067413, 25.631658808],
      "speed factor": 0.9364865318,
      "Uz factor": 0.9245729124,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4884643333,
      "Direction stability factor": 0.7807210249,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6733984896, 1.1638103008, 1.4236730282],
      direction: [-22.30432619, 0.0715017748, 13.9172820522],
      "speed factor": 1.0333188287,
      "Uz factor": 0.9750544406,
      "Directional variance factor": 0.9936442867,
      "Velocity stability factor": 0.5148173758,
      "Direction stability factor": 0.8993844215,
      score: 0.8268076264,
    },
    "67.5": {
      velocity: [0.3170358861, 0.632442443, 1.0399837936],
      direction: [-33.0697757878, -1.9154022499, 61.3232084263],
      "speed factor": 0.979695506,
      "Uz factor": 0.9820977812,
      "Directional variance factor": 0.8297420222,
      "Velocity stability factor": 0.3540066248,
      "Direction stability factor": 0.7377972661,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1383730669, 0.7684137482, 1.1970161944],
      direction: [-166.2752001057, 28.2191660054, 152.3682740392],
      "speed factor": 0.8644117847,
      "Uz factor": 0.9964063248,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1236350735,
      "Direction stability factor": 0.1148792385,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0576760301, 0.6270347209, 1.1852789704],
      direction: [-37.6169929735, 8.8395467625, 166.0884979897],
      "speed factor": 0.8907910396,
      "Uz factor": 0.9906145704,
      "Directional variance factor": 0.21426251,
      "Velocity stability factor": 0.0778559624,
      "Direction stability factor": 0.434151414,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2084429796, 0.494738738, 0.8819598081],
      direction: [-92.873207091, 24.1393030758, 81.0881879502],
      "speed factor": 0.5796633281,
      "Uz factor": 0.6668701166,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4821314999,
      "Direction stability factor": 0.5167739027,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1331575169, 0.3789815714, 0.8076411608],
      direction: [-96.1849986527, -12.5051530233, 46.599839218],
      "speed factor": 0.7030564847,
      "Uz factor": 0.9998520681,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3455162463,
      "Direction stability factor": 0.6033754504,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1814383414, 0.6888108028, 1.1363026661],
      direction: [-58.7126675646, -0.596138039, 35.577966482],
      "speed factor": 0.937988577,
      "Uz factor": 0.9785233632,
      "Directional variance factor": 0.9470099521,
      "Velocity stability factor": 0.2997600696,
      "Direction stability factor": 0.7380815721,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7634305046, 0.9829550855, 1.2675728437],
      direction: [-28.2292345935, -14.7588504395, 6.2029759808],
      "speed factor": 1.2482919522,
      "Uz factor": 0.9353395472,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5939463812,
      "Direction stability factor": 0.9043549706,
      score: 0.0,
    },
    id: "IDN:423.0",
    "45": {
      velocity: [0.3420615242, 0.583320467, 0.8564471816],
      direction: [-59.0709377866, -12.7777381936, 33.8481367184],
      "speed factor": 0.7923717544,
      "Uz factor": 0.9535791377,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5818207373,
      "Direction stability factor": 0.7418914597,
      score: 0.0,
    },
    "90": {
      velocity: [0.3442609873, 0.7083277346, 1.1264681305],
      direction: [-25.4996963558, 26.7686199591, 66.6000005814],
      "speed factor": 1.1482199857,
      "Uz factor": 0.9941777076,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4291002253,
      "Direction stability factor": 0.7441675085,
      score: 0.0,
    },
    "135": {
      velocity: [0.2270884764, 1.075285972, 1.2782144785],
      direction: [-6.8918655095, 9.3842720351, 64.7484204797],
      "speed factor": 0.9100686316,
      "Uz factor": 0.9833707821,
      "Directional variance factor": 0.1658424858,
      "Velocity stability factor": 0.1529913053,
      "Direction stability factor": 0.8009992056,
      score: 0.0,
    },
    "180": {
      velocity: [0.0750172605, 0.5719721449, 1.147140553],
      direction: [-37.9141640622, -0.6774403928, 140.4985766649],
      "speed factor": 1.1170580656,
      "Uz factor": 0.9863913632,
      "Directional variance factor": 0.9397830762,
      "Velocity stability factor": 0.1552130749,
      "Direction stability factor": 0.5044090535,
      score: 0.5259535645,
    },
    "225": {
      velocity: [0.2015339034, 0.7256181183, 1.1346968376],
      direction: [-33.4694818387, -3.6181094488, 50.3389526558],
      "speed factor": 0.9905481398,
      "Uz factor": 0.9998142841,
      "Directional variance factor": 0.6783902712,
      "Velocity stability factor": 0.1612912488,
      "Direction stability factor": 0.7671987931,
      score: 0.0,
    },
    "270": {
      velocity: [0.3572591199, 0.9550498941, 1.3775532229],
      direction: [-78.4385687474, -2.4765138326, 28.7733978671],
      "speed factor": 0.8739014912,
      "Uz factor": 0.9999997966,
      "Directional variance factor": 0.7798654371,
      "Velocity stability factor": 0.1951887422,
      "Direction stability factor": 0.7021889816,
      score: 0.0,
    },
    "315": {
      velocity: [0.780470339, 0.9319600951, 1.0728865122],
      direction: [-12.6677806732, -1.6183558948, 10.1394748115],
      "speed factor": 1.148267584,
      "Uz factor": 0.9378505847,
      "Directional variance factor": 0.8561461427,
      "Velocity stability factor": 0.7627814825,
      "Direction stability factor": 0.9366465125,
      score: 0.0,
    },
  },
  {
    position: [9.735933557, 93.59870832, 46.87999908],
    "overall score": 0.0844664093,
    "0": {
      velocity: [0.0636739532, 0.3466435411, 0.9202114452],
      direction: [-174.3897354307, -50.1912151376, 174.9754330392],
      "speed factor": 0.3552233324,
      "Uz factor": 0.9976899319,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4048513749,
      "Direction stability factor": 0.0295411987,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0617038171, 0.6670238691, 1.2904766589],
      direction: [-166.0489982048, -16.2762310209, 176.1670735741],
      "speed factor": 0.5922342521,
      "Uz factor": 0.9997813688,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2053852274,
      "Direction stability factor": 0.0493998006,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0681612806, 0.43562583, 0.8088652829],
      direction: [-47.6646989596, -3.4012334984, 79.0321251307],
      "speed factor": 0.6748134517,
      "Uz factor": 0.9906807422,
      "Directional variance factor": 0.6976681335,
      "Velocity stability factor": 0.3381405858,
      "Direction stability factor": 0.6480643775,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5377466516, 0.954051838, 1.2013086874],
      direction: [-30.4373811721, 4.1349669968, 53.861457769],
      "speed factor": 1.0732416669,
      "Uz factor": 0.9782291644,
      "Directional variance factor": 0.6324473781,
      "Velocity stability factor": 0.4506907194,
      "Direction stability factor": 0.7658365585,
      score: 0.6537028036,
    },
    "157.5": {
      velocity: [0.4896714173, 0.8839891268, 1.1355584706],
      direction: [-15.6973084634, 14.2825752777, 65.2986675723],
      "speed factor": 1.2558309246,
      "Uz factor": 0.9545469749,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4717990936,
      "Direction stability factor": 0.7750111777,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3595709318, 0.8355179647, 1.1250815448],
      direction: [-42.5946150722, -6.5035669037, 121.8816797809],
      "speed factor": 0.978939159,
      "Uz factor": 0.9155605554,
      "Directional variance factor": 0.4219051641,
      "Velocity stability factor": 0.4113972864,
      "Direction stability factor": 0.5431214032,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1882559136, 0.5375201931, 0.8804684518],
      direction: [-54.2139942256, -4.1025019899, 152.3738888685],
      "speed factor": 0.9971647327,
      "Uz factor": 0.9797537966,
      "Directional variance factor": 0.6353331565,
      "Velocity stability factor": 0.3283130519,
      "Direction stability factor": 0.4261447692,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0327881797, 0.3209024693, 0.8825327816],
      direction: [-165.0346205362, -2.369307491, 103.3092085683],
      "speed factor": 0.4369891548,
      "Uz factor": 0.9999956722,
      "Directional variance factor": 0.7893948897,
      "Velocity stability factor": 0.3768485371,
      "Direction stability factor": 0.2546004747,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0814581804, 0.4969843523, 0.8993470111],
      direction: [-109.7106237371, 5.4339903391, 97.4539747857],
      "speed factor": 0.6311392825,
      "Uz factor": 0.9978416854,
      "Directional variance factor": 0.5169786365,
      "Velocity stability factor": 0.3412441412,
      "Direction stability factor": 0.4245427819,
      score: 0.0,
    },
    id: "IDN:412.0",
    "45": {
      velocity: [0.1024360732, 0.6504261697, 1.0486729471],
      direction: [-67.8337619386, -2.566729299, 166.6312194627],
      "speed factor": 0.8835269023,
      "Uz factor": 0.9983258376,
      "Directional variance factor": 0.7718462845,
      "Velocity stability factor": 0.2307393634,
      "Direction stability factor": 0.348708385,
      score: 0.0,
    },
    "90": {
      velocity: [0.5496973462, 0.8863133172, 1.1380738457],
      direction: [-17.0977012524, 5.2411671921, 39.3590236406],
      "speed factor": 1.4367398236,
      "Uz factor": 0.9839741643,
      "Directional variance factor": 0.5341184718,
      "Velocity stability factor": 0.5705689804,
      "Direction stability factor": 0.8431757642,
      score: 0.6977597451,
    },
    "135": {
      velocity: [0.6302548287, 1.1497365678, 1.3130862428],
      direction: [-11.0166755847, 10.6789605185, 28.3798249957],
      "speed factor": 0.9730799176,
      "Uz factor": 0.9545229081,
      "Directional variance factor": 0.050759065,
      "Velocity stability factor": 0.4497670654,
      "Direction stability factor": 0.8905652762,
      score: 0.0,
    },
    "180": {
      velocity: [0.4440814322, 0.7574505881, 1.0009047749],
      direction: [-36.141695505, 10.7492936733, 60.3617266854],
      "speed factor": 1.4792963193,
      "Uz factor": 0.9258519576,
      "Directional variance factor": 0.044507229,
      "Velocity stability factor": 0.5612472159,
      "Direction stability factor": 0.7319349384,
      score: 0.0,
    },
    "225": {
      velocity: [0.1507582665, 0.4929989948, 0.7783925953],
      direction: [-72.8960887131, -6.2316047436, 60.9754305561],
      "speed factor": 0.6729975795,
      "Uz factor": 0.9797959687,
      "Directional variance factor": 0.4460795783,
      "Velocity stability factor": 0.4358944352,
      "Direction stability factor": 0.6281346687,
      score: 0.0,
    },
    "270": {
      velocity: [0.2584936183, 0.7143122533, 1.1931732574],
      direction: [-54.0228630248, -3.5365192578, 47.9526907707],
      "speed factor": 0.6536187767,
      "Uz factor": 0.9999835878,
      "Directional variance factor": 0.6856427326,
      "Velocity stability factor": 0.2627217057,
      "Direction stability factor": 0.7167345728,
      score: 0.0,
    },
    "315": {
      velocity: [0.0669974203, 0.3495988001, 0.8484735005],
      direction: [-162.6641421781, -41.2641025989, 35.9798118866],
      "speed factor": 0.4307405131,
      "Uz factor": 0.9944308485,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3660384951,
      "Direction stability factor": 0.4482112387,
      score: 0.0,
    },
  },
  {
    position: [-52.91929065, 80.0059607, 45.87999908],
    "overall score": 0.084411462,
    "0": {
      velocity: [0.280360085, 0.9374340205, 1.4676785374],
      direction: [-32.2546752992, -3.4854016211, 80.6979304015],
      "speed factor": 0.9606364961,
      "Uz factor": 0.999602871,
      "Directional variance factor": 0.6901865226,
      "Velocity stability factor": 0.1750145778,
      "Direction stability factor": 0.6862427619,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0799841413, 0.5909206864, 1.2190972073],
      direction: [-132.0149809016, 25.3181880111, 161.6604629126],
      "speed factor": 0.5246640892,
      "Uz factor": 0.9953952937,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.263365824,
      "Direction stability factor": 0.1842348783,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0249719866, 0.3134071551, 0.9244806101],
      direction: [-148.7640591889, -5.1346831566, 96.9617059361],
      "speed factor": 0.4854885766,
      "Uz factor": 0.9640544135,
      "Directional variance factor": 0.5435837194,
      "Velocity stability factor": 0.1962399977,
      "Direction stability factor": 0.3174284302,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.203621515, 0.7542798769, 1.232921272],
      direction: [-61.7588994811, -1.6211793096, 36.6709586742],
      "speed factor": 0.8485121669,
      "Uz factor": 0.9843339047,
      "Directional variance factor": 0.8558951725,
      "Velocity stability factor": 0.147926074,
      "Direction stability factor": 0.7265837273,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3870620714, 0.9385751223, 1.2778026382],
      direction: [-76.9056746403, -25.4981548229, 10.1436468303],
      "speed factor": 1.3333780109,
      "Uz factor": 0.9833509642,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.271559985,
      "Direction stability factor": 0.7581963292,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.536699262, 0.9271453145, 1.2835573341],
      direction: [-51.6969421461, -14.576138487, 10.4243881772],
      "speed factor": 1.0862948408,
      "Uz factor": 0.9905109458,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4257392641,
      "Direction stability factor": 0.8274407491,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2253070914, 0.4471253934, 0.7276869025],
      direction: [-37.4513721518, -4.6064404476, 55.630586515],
      "speed factor": 0.8294714863,
      "Uz factor": 0.9657350355,
      "Directional variance factor": 0.5905386269,
      "Velocity stability factor": 0.5125168305,
      "Direction stability factor": 0.7414390037,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4881446484, 0.992384833, 1.3155147336],
      direction: [-45.1997997105, -0.1471384364, 36.3650578595],
      "speed factor": 1.351380718,
      "Uz factor": 0.9018128492,
      "Directional variance factor": 0.9869210279,
      "Velocity stability factor": 0.3932566588,
      "Direction stability factor": 0.7734309512,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.5168010539, 0.960660557, 1.2171495289],
      direction: [-3.315452027, 7.1213347602, 36.7443077397],
      "speed factor": 1.2199792846,
      "Uz factor": 0.9782839696,
      "Directional variance factor": 0.3669924658,
      "Velocity stability factor": 0.4359151956,
      "Direction stability factor": 0.8887228895,
      score: 0.6450883601,
    },
    id: "IDN:348.0",
    "45": {
      velocity: [0.0268947967, 0.3864774528, 0.7070475487],
      direction: [-97.8046818808, 0.8747272681, 140.5331595164],
      "speed factor": 0.5249838377,
      "Uz factor": 0.9767764555,
      "Directional variance factor": 0.9222464651,
      "Velocity stability factor": 0.4470573348,
      "Direction stability factor": 0.3379504406,
      score: 0.0,
    },
    "90": {
      velocity: [0.0945398651, 0.5898286944, 1.4418638062],
      direction: [-145.7138757825, 0.1993629064, 50.2667791002],
      "speed factor": 0.9561295739,
      "Uz factor": 0.9737307509,
      "Directional variance factor": 0.9822788528,
      "Velocity stability factor": 0.016645474,
      "Direction stability factor": 0.455609292,
      score: 0.0,
    },
    "135": {
      velocity: [0.140354088, 0.9502069049, 1.3584244387],
      direction: [-156.6826764807, -22.5441943972, 5.4790059244],
      "speed factor": 0.8042079226,
      "Uz factor": 0.9927915186,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0184657446,
      "Direction stability factor": 0.5495508822,
      score: 0.0,
    },
    "180": {
      velocity: [0.4470833269, 0.9092458161, 1.3083317812],
      direction: [-60.3606444248, -39.7840880607, 22.8751622924],
      "speed factor": 1.7757514619,
      "Uz factor": 0.9955535728,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3213733546,
      "Direction stability factor": 0.7687894258,
      score: 0.0,
    },
    "225": {
      velocity: [0.4920800066, 0.7518372458, 1.0224060377],
      direction: [-19.2961624995, 4.4835628385, 35.2139937177],
      "speed factor": 1.0263401181,
      "Uz factor": 0.9712742694,
      "Directional variance factor": 0.601461081,
      "Velocity stability factor": 0.5233532463,
      "Direction stability factor": 0.8485828994,
      score: 0.7054950315,
    },
    "270": {
      velocity: [0.8544891755, 1.0768574386, 1.3215012911],
      direction: [-4.9255434204, 17.2112170128, 35.8849791899],
      "speed factor": 0.9853593276,
      "Uz factor": 0.9027539307,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6316193468,
      "Direction stability factor": 0.8866374372,
      score: 0.0,
    },
    "315": {
      velocity: [0.5567984742, 0.9668579054, 1.2511035711],
      direction: [-0.6372370463, 9.389427547, 19.1221035005],
      "speed factor": 1.1912651592,
      "Uz factor": 0.9467824242,
      "Directional variance factor": 0.165384218,
      "Velocity stability factor": 0.4367547322,
      "Direction stability factor": 0.9451129429,
      score: 0.0,
    },
  },
  {
    position: [9.532765476, 102.6514601, 47.87999908],
    "overall score": 0.0830807797,
    "0": {
      velocity: [0.5886919926, 1.0042895993, 1.3648590493],
      direction: [-37.3703918346, -15.4184668185, 3.7363115554],
      "speed factor": 1.0291468206,
      "Uz factor": 0.9503007691,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4606952283,
      "Direction stability factor": 0.8858147128,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5633946505, 0.9562514107, 1.2531140159],
      direction: [-55.3959420474, -17.7303187524, 2.5300281483],
      "speed factor": 0.8490323439,
      "Uz factor": 0.9570191766,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5539767986,
      "Direction stability factor": 0.8390945272,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1267468108, 0.4974996507, 0.8755614794],
      direction: [-57.4892003804, -5.0351431008, 108.9388834387],
      "speed factor": 0.7706601248,
      "Uz factor": 0.992961537,
      "Directional variance factor": 0.5524317244,
      "Velocity stability factor": 0.3308932633,
      "Direction stability factor": 0.5376997672,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6929022753, 1.1423415971, 1.354342202],
      direction: [-12.3391813941, 6.6638957249, 34.3787308448],
      "speed factor": 1.2850544918,
      "Uz factor": 0.9918703605,
      "Directional variance factor": 0.4076537133,
      "Velocity stability factor": 0.4524474417,
      "Direction stability factor": 0.8702280216,
      score: 0.6501392995,
    },
    "157.5": {
      velocity: [0.2018648539, 0.9013425611, 1.2663813007],
      direction: [-25.8296231728, 12.6224732742, 52.2915354342],
      "speed factor": 1.2804839194,
      "Uz factor": 0.9998855347,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.129447557,
      "Direction stability factor": 0.7829967816,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0528741982, 0.5201220493, 1.031128959],
      direction: [-168.5052196156, -13.7204186399, 160.5011534829],
      "speed factor": 0.6094038225,
      "Uz factor": 0.9985851582,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2478178655,
      "Direction stability factor": 0.0860934081,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0917394135, 0.4896683797, 0.9470052597],
      direction: [-50.9304464578, -1.9630398944, 64.7512280493],
      "speed factor": 0.9083938525,
      "Uz factor": 0.9993564493,
      "Directional variance factor": 0.8255075649,
      "Velocity stability factor": 0.170094625,
      "Direction stability factor": 0.6786620153,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1752162758, 0.5235478896, 0.9263849022],
      direction: [-63.5294345975, 14.2781857369, 122.8453270004],
      "speed factor": 0.7129416931,
      "Uz factor": 0.9011154311,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4491382147,
      "Direction stability factor": 0.4822923289,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2444680581, 0.6528924415, 0.9845613669],
      direction: [-61.3172426276, 6.7274948263, 38.7842760216],
      "speed factor": 0.8291328794,
      "Uz factor": 0.9944348015,
      "Directional variance factor": 0.4020004599,
      "Velocity stability factor": 0.4039033364,
      "Direction stability factor": 0.721940226,
      score: 0.0,
    },
    id: "IDN:665.0",
    "45": {
      velocity: [0.2308966977, 0.6629105868, 0.9961967588],
      direction: [-53.6580503528, -6.1614241051, 21.997042231],
      "speed factor": 0.900485504,
      "Uz factor": 0.9936232225,
      "Directional variance factor": 0.4523178573,
      "Velocity stability factor": 0.3778352669,
      "Direction stability factor": 0.789846965,
      score: 0.0,
    },
    "90": {
      velocity: [0.5533746555, 0.8516373638, 1.1580696533],
      direction: [-11.9039726773, 12.4729192235, 31.2812023627],
      "speed factor": 1.3805290884,
      "Uz factor": 0.9903586349,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5586588015,
      "Direction stability factor": 0.8800411804,
      score: 0.0,
    },
    "135": {
      velocity: [0.9091888359, 1.2735788158, 1.4001163219],
      direction: [-6.2624951592, 7.9526871933, 26.2971816821],
      "speed factor": 1.0778938445,
      "Uz factor": 0.9966794321,
      "Directional variance factor": 0.2930944717,
      "Velocity stability factor": 0.6044053251,
      "Direction stability factor": 0.9095564532,
      score: 0.6791531758,
    },
    "180": {
      velocity: [0.0455856015, 0.5072425322, 1.1754014728],
      direction: [-176.1829874167, 8.8748978233, 165.2496661657],
      "speed factor": 0.9906415319,
      "Uz factor": 0.9991955579,
      "Directional variance factor": 0.2111201935,
      "Velocity stability factor": 0.1097538105,
      "Direction stability factor": 0.0515759623,
      score: 0.0,
    },
    "225": {
      velocity: [0.1061240286, 0.4285694193, 0.8534333916],
      direction: [-145.1763079563, -14.5312919082, 161.2980350834],
      "speed factor": 0.5850441581,
      "Uz factor": 0.9991863426,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3283328349,
      "Direction stability factor": 0.1486823804,
      score: 0.0,
    },
    "270": {
      velocity: [0.4119098467, 1.013322243, 1.2814111449],
      direction: [-43.6518029359, 2.7115091873, 17.4749640244],
      "speed factor": 0.9272225721,
      "Uz factor": 0.9997006365,
      "Directional variance factor": 0.7589769611,
      "Velocity stability factor": 0.3141345899,
      "Direction stability factor": 0.8302034251,
      score: 0.0,
    },
    "315": {
      velocity: [0.4458611453, 0.9058042464, 1.180199984],
      direction: [-31.0073991183, -17.2812439084, 10.4967386252],
      "speed factor": 1.1160409755,
      "Uz factor": 0.9776320804,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4042779208,
      "Direction stability factor": 0.8847107285,
      score: 0.0,
    },
  },
  {
    position: [-53.00215104, 80.42343294, 45.87999908],
    "overall score": 0.0830118453,
    "0": {
      velocity: [0.3061057013, 0.9559755873, 1.4948515626],
      direction: [-29.1863584736, -3.6011510205, 82.8679802024],
      "speed factor": 0.9796369861,
      "Uz factor": 0.999068855,
      "Directional variance factor": 0.6798976871,
      "Velocity stability factor": 0.1740227701,
      "Direction stability factor": 0.6887379481,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0760961208, 0.5973530442, 1.1837842479],
      direction: [-161.9426764858, 27.6381302838, 164.8481242316],
      "speed factor": 0.5303752231,
      "Uz factor": 0.994264385,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2836874975,
      "Direction stability factor": 0.0922477758,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0355000267, 0.3098281915, 0.8916490871],
      direction: [-131.9074040621, -2.2841097962, 177.8959136047],
      "speed factor": 0.4799445234,
      "Uz factor": 0.9696536973,
      "Directional variance factor": 0.7969680181,
      "Velocity stability factor": 0.2349841316,
      "Direction stability factor": 0.1394352287,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1116008016, 0.6617075775, 1.1299092454],
      direction: [-61.4204534284, -2.8404573579, 42.9598818013],
      "speed factor": 0.7443747972,
      "Uz factor": 0.9899775708,
      "Directional variance factor": 0.7475149015,
      "Velocity stability factor": 0.1570248922,
      "Direction stability factor": 0.7100546244,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3134054646, 0.8755108919, 1.2287232395],
      direction: [-82.1793081599, -25.881973447, 12.8102343475],
      "speed factor": 1.2437863991,
      "Uz factor": 0.9870668809,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2514609545,
      "Direction stability factor": 0.7361401597,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5873082936, 0.9597498777, 1.3283375659],
      direction: [-47.6846207971, -14.413071448, 12.2707233554],
      "speed factor": 1.1244961542,
      "Uz factor": 0.9931853875,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4302210405,
      "Direction stability factor": 0.8334573774,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2234150802, 0.4527359903, 0.73212768],
      direction: [-31.7867561974, -2.6520119108, 54.7936851379],
      "speed factor": 0.8398798196,
      "Uz factor": 0.9654865403,
      "Directional variance factor": 0.7642656079,
      "Velocity stability factor": 0.5063718225,
      "Direction stability factor": 0.7594987741,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4745639562, 0.9779985172, 1.2963892012],
      direction: [-48.159288409, -0.5808153731, 36.1051272845],
      "speed factor": 1.3317901428,
      "Uz factor": 0.887957905,
      "Directional variance factor": 0.9483719668,
      "Velocity stability factor": 0.3973229102,
      "Direction stability factor": 0.7659321786,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.496511582, 0.975424216, 1.2339497168],
      direction: [-3.9118764297, 6.3911270816, 40.4179316308],
      "speed factor": 1.2387282153,
      "Uz factor": 0.974147531,
      "Directional variance factor": 0.431899815,
      "Velocity stability factor": 0.4060419051,
      "Direction stability factor": 0.8768616443,
      score: 0.6479162521,
    },
    id: "IDN:347.0",
    "45": {
      velocity: [0.0419963219, 0.384481675, 0.7284563786],
      direction: [-82.5208508134, 3.2442351179, 171.4088515112],
      "speed factor": 0.5222728099,
      "Uz factor": 0.984675048,
      "Directional variance factor": 0.7116235451,
      "Velocity stability factor": 0.4419296956,
      "Direction stability factor": 0.2946397158,
      score: 0.0,
    },
    "90": {
      velocity: [0.120065888, 0.4995975523, 1.419812905],
      direction: [-106.9206311731, -0.0154657021, 110.9239303849],
      "speed factor": 0.809862252,
      "Uz factor": 0.9721836714,
      "Directional variance factor": 0.9986252709,
      "Velocity stability factor": 0.0513698504,
      "Direction stability factor": 0.3948762179,
      score: 0.0,
    },
    "135": {
      velocity: [0.1413316376, 0.8934863614, 1.3234093879],
      direction: [-143.226593503, -22.7839106195, 10.7870154245],
      "speed factor": 0.7562024722,
      "Uz factor": 0.9944870261,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0474689711,
      "Direction stability factor": 0.5721844196,
      score: 0.0,
    },
    "180": {
      velocity: [0.36681693, 0.9134233239, 1.3225922327],
      direction: [-67.7785495828, -40.4083058242, 10.547687651],
      "speed factor": 1.7839101087,
      "Uz factor": 0.9979877217,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.246890274,
      "Direction stability factor": 0.7824271188,
      score: 0.0,
    },
    "225": {
      velocity: [0.4958953558, 0.7614996912, 1.032248778],
      direction: [-18.4290392764, 5.518150518, 36.7122666141],
      "speed factor": 1.0395304136,
      "Uz factor": 0.9725706691,
      "Directional variance factor": 0.5094977317,
      "Velocity stability factor": 0.5179359441,
      "Direction stability factor": 0.8468297059,
      score: 0.6802732719,
    },
    "270": {
      velocity: [0.8818709015, 1.0911687008, 1.3231587097],
      direction: [-9.59766405, 16.4050206535, 34.9882686812],
      "speed factor": 0.9984545946,
      "Uz factor": 0.8697638012,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6519107628,
      "Direction stability factor": 0.8761501869,
      score: 0.0,
    },
    "315": {
      velocity: [0.5754253264, 0.9580266801, 1.2372370969],
      direction: [-1.3345656105, 9.0107728038, 18.6387223014],
      "speed factor": 1.1803842108,
      "Uz factor": 0.9349014973,
      "Directional variance factor": 0.1990424174,
      "Velocity stability factor": 0.4631144873,
      "Direction stability factor": 0.9445186447,
      score: 0.0,
    },
  },
  {
    position: [-35.94095872, 89.87253324, 47.87999908],
    "overall score": 0.082853677,
    "0": {
      velocity: [0.1891690839, 0.6763917361, 1.1211600613],
      direction: [-178.7324976143, -22.4125039305, 35.4799051395],
      "speed factor": 0.6931331412,
      "Uz factor": 0.9953121055,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3524239698,
      "Direction stability factor": 0.4049655479,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.715203549, 1.2694716037, 1.5208551967],
      direction: [-29.1738642275, 0.1277481676, 13.155596883],
      "speed factor": 1.1271329268,
      "Uz factor": 0.9937534133,
      "Directional variance factor": 0.9886446073,
      "Velocity stability factor": 0.4790064697,
      "Direction stability factor": 0.8824181636,
      score: 0.808121851,
    },
    "67.5": {
      velocity: [0.2933443253, 0.6268877599, 0.9741300771],
      direction: [-37.9600032934, -8.8562212088, 48.2491273144],
      "speed factor": 0.9710909317,
      "Uz factor": 0.9914748362,
      "Directional variance factor": 0.212780337,
      "Velocity stability factor": 0.3916808098,
      "Direction stability factor": 0.7605301928,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.182378295, 0.751722507, 1.1436570804],
      direction: [-6.5256738869, 22.8218554787, 98.1497044712],
      "speed factor": 0.8456353044,
      "Uz factor": 0.9485021541,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2042351287,
      "Direction stability factor": 0.7092350601,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4190976485, 0.8169620769, 1.1326139466],
      direction: [-29.9907747358, 9.5242273839, 59.9636068204],
      "speed factor": 1.16060957,
      "Uz factor": 0.9564209595,
      "Directional variance factor": 0.1534020103,
      "Velocity stability factor": 0.4164924758,
      "Direction stability factor": 0.7501267179,
      score: 0.5175369805,
    },
    "202.5": {
      velocity: [0.3535485153, 0.6281490082, 1.011333413],
      direction: [-42.0511206125, -14.3343739377, 28.1324010139],
      "speed factor": 0.7359741954,
      "Uz factor": 0.8187807529,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4942278145,
      "Direction stability factor": 0.8050457733,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1687049627, 0.4438875283, 0.6971144604],
      direction: [-103.1455000068, -10.9393474969, 44.2585124721],
      "speed factor": 0.8234648562,
      "Uz factor": 0.9945403128,
      "Directional variance factor": 0.0276135558,
      "Velocity stability factor": 0.48725898,
      "Direction stability factor": 0.5905444098,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1430443721, 0.5871650807, 0.9868932158],
      direction: [-148.0639153311, -10.4982138337, 92.0898506194],
      "speed factor": 0.799572446,
      "Uz factor": 0.9942357962,
      "Directional variance factor": 0.066825437,
      "Velocity stability factor": 0.3811721307,
      "Direction stability factor": 0.3329062057,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0470078605, 0.2855761932, 0.8995625691],
      direction: [-148.6298575006, -21.5122572976, 172.2018778502],
      "speed factor": 0.3626640413,
      "Uz factor": 0.9654650368,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3133230482,
      "Direction stability factor": 0.1088007351,
      score: 0.0,
    },
    id: "IDN:723.0",
    "45": {
      velocity: [0.2141383968, 0.5967934222, 0.8698677205],
      direction: [-50.6453100309, -8.1869865414, 53.6718955836],
      "speed factor": 0.8106731681,
      "Uz factor": 0.9889958772,
      "Directional variance factor": 0.272267863,
      "Velocity stability factor": 0.4669128092,
      "Direction stability factor": 0.7102299844,
      score: 0.0,
    },
    "90": {
      velocity: [0.2895160344, 0.7600422791, 1.1573577983],
      direction: [-43.3649940346, 21.7501017914, 97.3867170202],
      "speed factor": 1.2320507756,
      "Uz factor": 0.9765255114,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3665991523,
      "Direction stability factor": 0.6090230248,
      score: 0.0,
    },
    "135": {
      velocity: [0.5988513264, 1.0201046, 1.2641463142],
      direction: [-23.3264504869, 5.1429842766, 28.4465238081],
      "speed factor": 0.8633658595,
      "Uz factor": 0.9655814004,
      "Directional variance factor": 0.5428458421,
      "Velocity stability factor": 0.4638981072,
      "Direction stability factor": 0.8561861825,
      score: 0.0,
    },
    "180": {
      velocity: [0.615040304, 0.9044167515, 1.1476995231],
      direction: [-21.7253849589, -1.9961774614, 22.633660619],
      "speed factor": 1.7663203285,
      "Uz factor": 0.9487506411,
      "Directional variance factor": 0.8225620034,
      "Velocity stability factor": 0.5802875033,
      "Direction stability factor": 0.876780429,
      score: 0.0,
    },
    "225": {
      velocity: [0.2802237448, 0.6677438941, 1.0830241068],
      direction: [-24.0360302536, 0.798608954, 17.7861926825],
      "speed factor": 0.9115434903,
      "Uz factor": 0.9949793904,
      "Directional variance factor": 0.9290125374,
      "Velocity stability factor": 0.278458601,
      "Direction stability factor": 0.8838271585,
      score: 0.0,
    },
    "270": {
      velocity: [0.1251295334, 0.3609745297, 1.029487897],
      direction: [-100.5811876545, -44.2023471276, 26.8857040057],
      "speed factor": 0.3303033504,
      "Uz factor": 0.9847131697,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2866392249,
      "Direction stability factor": 0.6459253009,
      score: 0.0,
    },
    "315": {
      velocity: [0.0672227757, 0.3776682187, 0.9246542286],
      direction: [-157.0916476777, 2.7054275349, 38.506519241],
      "speed factor": 0.4653248302,
      "Uz factor": 0.9879816082,
      "Directional variance factor": 0.7595175525,
      "Velocity stability factor": 0.3044207647,
      "Direction stability factor": 0.4566717586,
      score: 0.0,
    },
  },
  {
    position: [-36.3076623, 89.78439987, 47.87999908],
    "overall score": 0.0825787319,
    "0": {
      velocity: [0.2090403548, 0.6541186607, 1.1163448065],
      direction: [-177.3344522306, -22.5711314245, 36.1045875379],
      "speed factor": 0.6703087839,
      "Uz factor": 0.9944042911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.369576928,
      "Direction stability factor": 0.4071137784,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7214141175, 1.2679404331, 1.5262060105],
      direction: [-32.0468254565, 0.5151693494, 14.0014887102],
      "speed factor": 1.1257734377,
      "Uz factor": 0.9944535129,
      "Directional variance factor": 0.9542071689,
      "Velocity stability factor": 0.4795624501,
      "Direction stability factor": 0.8720880162,
      score: 0.7944864129,
    },
    "67.5": {
      velocity: [0.278138776, 0.6313371527, 0.9802976127],
      direction: [-37.457250587, -8.5511217442, 50.0329339841],
      "speed factor": 0.9779833376,
      "Uz factor": 0.9915583789,
      "Directional variance factor": 0.2399002894,
      "Velocity stability factor": 0.372582793,
      "Direction stability factor": 0.7569717095,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1836460657, 0.7498346074, 1.1458929078],
      direction: [-6.5224550328, 23.003963595, 100.7374752083],
      "speed factor": 0.8435115492,
      "Uz factor": 0.9444480469,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2034337529,
      "Direction stability factor": 0.7020557493,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4272168448, 0.8167692398, 1.1422848158],
      direction: [-30.872996993, 9.1703860275, 57.8642879063],
      "speed factor": 1.1603356178,
      "Uz factor": 0.9529841197,
      "Directional variance factor": 0.1848545753,
      "Velocity stability factor": 0.4152235309,
      "Direction stability factor": 0.7535075419,
      score: 0.5267732975,
    },
    "202.5": {
      velocity: [0.3664009406, 0.6332921195, 1.0258643169],
      direction: [-45.3644017261, -16.3624874408, 26.6801827977],
      "speed factor": 0.7420001497,
      "Uz factor": 0.811892663,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4929372288,
      "Direction stability factor": 0.7998761541,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1114838976, 0.4408674958, 0.6976119689],
      direction: [-103.5120469576, -11.308500308, 41.5568141452],
      "speed factor": 0.817862332,
      "Uz factor": 0.9946236962,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4312518862,
      "Direction stability factor": 0.5970309414,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1354157415, 0.5805065093, 0.9705271316],
      direction: [-146.3785563854, -10.9056932055, 105.2930069842],
      "speed factor": 0.7905051319,
      "Uz factor": 0.9951952669,
      "Directional variance factor": 0.0306050484,
      "Velocity stability factor": 0.3875796524,
      "Direction stability factor": 0.300912324,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0135482657, 0.2676198965, 0.7695385139],
      direction: [-150.0229633379, -22.3191944894, 172.3545244525],
      "speed factor": 0.3398606589,
      "Uz factor": 0.9595029098,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3910993933,
      "Direction stability factor": 0.1045069784,
      score: 0.0,
    },
    id: "IDN:722.0",
    "45": {
      velocity: [0.2149793123, 0.5945574152, 0.8904561259],
      direction: [-50.5868862058, -8.0743016966, 52.6956429616],
      "speed factor": 0.8076358175,
      "Uz factor": 0.9908056439,
      "Directional variance factor": 0.2822842936,
      "Velocity stability factor": 0.4508587247,
      "Direction stability factor": 0.7131040856,
      score: 0.0,
    },
    "90": {
      velocity: [0.2767732193, 0.7606504955, 1.1608370972],
      direction: [-42.5959006485, 22.1076136759, 113.7265734204],
      "speed factor": 1.2330367123,
      "Uz factor": 0.9750987366,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3547593203,
      "Direction stability factor": 0.5657709054,
      score: 0.0,
    },
    "135": {
      velocity: [0.5992433539, 1.0193582666, 1.2598722004],
      direction: [-23.6533896685, 5.1453082935, 28.787667946],
      "speed factor": 0.8627342001,
      "Uz factor": 0.9633145146,
      "Directional variance factor": 0.5426392628,
      "Velocity stability factor": 0.4676581344,
      "Direction stability factor": 0.8543303955,
      score: 0.0,
    },
    "180": {
      velocity: [0.6231211198, 0.9020063057, 1.1515519836],
      direction: [-21.4326669164, -2.49537574, 22.4173659873],
      "speed factor": 1.7616127428,
      "Uz factor": 0.9443546085,
      "Directional variance factor": 0.7781888231,
      "Velocity stability factor": 0.583619265,
      "Direction stability factor": 0.878194353,
      score: 0.0,
    },
    "225": {
      velocity: [0.2867062958, 0.6718586622, 1.0781003455],
      direction: [-22.8961710898, 0.4698586505, 16.9692506483],
      "speed factor": 0.9171605991,
      "Uz factor": 0.9953256121,
      "Directional variance factor": 0.9582347866,
      "Velocity stability factor": 0.2887103733,
      "Direction stability factor": 0.8892627174,
      score: 0.0,
    },
    "270": {
      velocity: [0.1537154122, 0.3557821172, 1.0234247633],
      direction: [-97.9879961519, -45.6575869222, 25.9941460009],
      "speed factor": 0.3255521253,
      "Uz factor": 0.9809147981,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3139704771,
      "Direction stability factor": 0.6556051607,
      score: 0.0,
    },
    "315": {
      velocity: [0.0645288779, 0.3901119009, 0.9443572383],
      direction: [-90.5661539965, 3.4857571416, 30.9469951333],
      "speed factor": 0.480656685,
      "Uz factor": 0.9886474582,
      "Directional variance factor": 0.6901549207,
      "Velocity stability factor": 0.2862515878,
      "Direction stability factor": 0.6624634746,
      score: 0.0,
    },
  },
  {
    position: [-52.20709274, 79.56483697, 45.87999908],
    "overall score": 0.0818356293,
    "0": {
      velocity: [0.24434247, 0.7749980837, 1.4559026181],
      direction: [-85.9553633299, -4.036693625, 92.752040307],
      "speed factor": 0.7941800994,
      "Uz factor": 0.9999489904,
      "Directional variance factor": 0.6411827889,
      "Velocity stability factor": 0.1581707012,
      "Direction stability factor": 0.5035905455,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.120548165, 0.5689854115, 1.2159024864],
      direction: [-129.3736144125, 24.1110116963, 172.2195333624],
      "speed factor": 0.5051882928,
      "Uz factor": 0.9978359118,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2916634422,
      "Direction stability factor": 0.1622412562,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.12538323, 0.3372615543, 0.8679513972],
      direction: [-127.2030659728, -7.5202104799, 80.1870749158],
      "speed factor": 0.5224406312,
      "Uz factor": 0.9144072971,
      "Directional variance factor": 0.3315368462,
      "Velocity stability factor": 0.3364748529,
      "Direction stability factor": 0.4239162753,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2431290548, 0.8182799189, 1.3030804981],
      direction: [-80.7170067942, -0.8272938068, 33.9807281696],
      "speed factor": 0.920507743,
      "Uz factor": 0.9731679993,
      "Directional variance factor": 0.9264627727,
      "Velocity stability factor": 0.1225520247,
      "Direction stability factor": 0.6813951807,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.432550231, 0.9241357507, 1.2316290854],
      direction: [-63.5073816825, -24.8079562469, 13.4602515394],
      "speed factor": 1.3128648521,
      "Uz factor": 0.9708918959,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3465201492,
      "Direction stability factor": 0.7862010188,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4159779642, 0.8736668889, 1.2104438873],
      direction: [-54.6593689127, -13.6704995317, 12.0190479519],
      "speed factor": 1.0236365531,
      "Uz factor": 0.9763801629,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3891334878,
      "Direction stability factor": 0.8147821754,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2358674971, 0.4633596673, 0.7599275142],
      direction: [-43.7788125829, -7.7909972434, 58.0646044708],
      "speed factor": 0.8595880206,
      "Uz factor": 0.9667612422,
      "Directional variance factor": 0.3074669117,
      "Velocity stability factor": 0.4914794891,
      "Direction stability factor": 0.7171016193,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3598313534, 0.9386030144, 1.2946724087],
      direction: [-45.2190821072, -0.1532609241, 33.8953480365],
      "speed factor": 1.2781432901,
      "Uz factor": 0.9387815842,
      "Directional variance factor": 0.9863768067,
      "Velocity stability factor": 0.3144439283,
      "Direction stability factor": 0.780237694,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4040975859, 0.742718203, 1.143756573],
      direction: [-3.0319487232, 8.7600542932, 43.0252316473],
      "speed factor": 0.9432060215,
      "Uz factor": 0.9859056789,
      "Directional variance factor": 0.2213285073,
      "Velocity stability factor": 0.404253154,
      "Direction stability factor": 0.8720633879,
      score: 0.0,
    },
    id: "IDN:350.0",
    "45": {
      velocity: [0.0882268407, 0.3657104336, 0.6853264083],
      direction: [-139.5930896649, -8.5522873157, 134.9900890952],
      "speed factor": 0.4967743022,
      "Uz factor": 0.9592607312,
      "Directional variance factor": 0.2397966831,
      "Velocity stability factor": 0.5145769456,
      "Direction stability factor": 0.2372689479,
      score: 0.0,
    },
    "90": {
      velocity: [0.1879438221, 0.7000387815, 1.4000667708],
      direction: [-93.269666753, 0.6213024925, 49.3918743308],
      "speed factor": 1.1347833502,
      "Uz factor": 0.9737628209,
      "Directional variance factor": 0.9447731118,
      "Velocity stability factor": 0.1153229366,
      "Direction stability factor": 0.6037179414,
      score: 0.5668829828,
    },
    "135": {
      velocity: [0.1042367534, 0.9843757055, 1.3449619009],
      direction: [-132.9721471413, -22.3698897061, 2.7070761973],
      "speed factor": 0.8331266981,
      "Uz factor": 0.9878857499,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0002102645,
      "Direction stability factor": 0.6231132685,
      score: 0.0,
    },
    "180": {
      velocity: [0.5087756342, 0.8794376285, 1.2559596292],
      direction: [-59.3347810305, -39.1213478026, 16.5141953042],
      "speed factor": 1.7175362556,
      "Uz factor": 0.9843355902,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4112512301,
      "Direction stability factor": 0.7893083991,
      score: 0.0,
    },
    "225": {
      velocity: [0.4925058584, 0.7619210986, 1.0526574964],
      direction: [-22.2903971932, 2.6263520796, 30.4756500337],
      "speed factor": 1.0401056808,
      "Uz factor": 0.9758275304,
      "Directional variance factor": 0.7665464818,
      "Velocity stability factor": 0.4965465692,
      "Direction stability factor": 0.8534276466,
      score: 0.742487086,
    },
    "270": {
      velocity: [0.8700309049, 1.089926168, 1.3450825991],
      direction: [-5.9170677897, 18.8336924803, 39.0565429351],
      "speed factor": 0.997317637,
      "Uz factor": 0.9406586086,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6252777015,
      "Direction stability factor": 0.8750733035,
      score: 0.0,
    },
    "315": {
      velocity: [0.1798877305, 0.7589136531, 1.1086478991],
      direction: [-4.9720275478, 9.406358301, 53.7530616499],
      "speed factor": 0.9350571462,
      "Uz factor": 0.9602695824,
      "Directional variance factor": 0.1638792621,
      "Velocity stability factor": 0.246556345,
      "Direction stability factor": 0.8368747522,
      score: 0.0,
    },
  },
  {
    position: [9.532765476, 102.6514601, 46.87999908],
    "overall score": 0.0814783308,
    "0": {
      velocity: [0.5427033981, 1.0062908488, 1.356452775],
      direction: [-40.5234419924, -17.6237033254, 2.9249761444],
      "speed factor": 1.0311976032,
      "Uz factor": 0.928463804,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4345818749,
      "Direction stability factor": 0.8793099496,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5823283615, 0.9540718584, 1.2835554384],
      direction: [-57.6937189627, -19.7701875333, 3.2640848786],
      "speed factor": 0.8470971726,
      "Uz factor": 0.9367747525,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5465350671,
      "Direction stability factor": 0.8306727671,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0872328725, 0.4819176332, 0.8660850106],
      direction: [-95.388731639, -3.9782504786, 122.9827510568],
      "speed factor": 0.7465225409,
      "Uz factor": 0.9895858611,
      "Directional variance factor": 0.6463777352,
      "Velocity stability factor": 0.3040531464,
      "Direction stability factor": 0.3934125481,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6139553142, 1.1369169154, 1.3596517475],
      direction: [-13.2017455136, 6.885740204, 31.8415860926],
      "speed factor": 1.2789521038,
      "Uz factor": 0.9884686145,
      "Directional variance factor": 0.3879342041,
      "Velocity stability factor": 0.3826983021,
      "Direction stability factor": 0.8748796344,
      score: 0.6300979438,
    },
    "157.5": {
      velocity: [0.095294434, 0.7292679253, 1.2118274681],
      direction: [-48.8986099451, 12.2067901164, 108.7950022162],
      "speed factor": 1.0360276898,
      "Uz factor": 0.9999981379,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.086908837,
      "Direction stability factor": 0.5619621884,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0755707911, 0.396164732, 0.8739964039],
      direction: [-175.7263106157, -17.9726469234, 178.1365813407],
      "speed factor": 0.4641685588,
      "Uz factor": 0.9997483717,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.386088874,
      "Direction stability factor": 0.0170475223,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1161754173, 0.4548560632, 0.9472832313],
      direction: [-52.0230046783, -1.9887977951, 84.9981419629],
      "speed factor": 0.8438128102,
      "Uz factor": 0.9987682229,
      "Directional variance factor": 0.8232179738,
      "Velocity stability factor": 0.1935363196,
      "Direction stability factor": 0.6193857038,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1307831163, 0.4766565841, 0.92381168],
      direction: [-147.2562691902, 15.606431873, 139.4159895626],
      "speed factor": 0.6490874261,
      "Uz factor": 0.8933504959,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4184406602,
      "Direction stability factor": 0.2036881701,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2341644808, 0.6265150155, 0.9857247666],
      direction: [-65.9992517746, 5.6074478572, 39.3187064956],
      "speed factor": 0.7956351855,
      "Uz factor": 0.9886165153,
      "Directional variance factor": 0.5015601905,
      "Velocity stability factor": 0.3946674378,
      "Direction stability factor": 0.7074501159,
      score: 0.0,
    },
    id: "IDN:399.0",
    "45": {
      velocity: [0.2061259388, 0.6272944221, 0.9660245654],
      direction: [-60.5966222225, -7.3841959201, 29.3359276791],
      "speed factor": 0.8521051633,
      "Uz factor": 0.9897587264,
      "Directional variance factor": 0.3436270293,
      "Velocity stability factor": 0.3822264621,
      "Direction stability factor": 0.7501873614,
      score: 0.0,
    },
    "90": {
      velocity: [0.4497226121, 0.8545385562, 1.155688926],
      direction: [-12.2080944523, 14.8087783668, 32.8463786288],
      "speed factor": 1.3852320061,
      "Uz factor": 0.9884592094,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4847451687,
      "Direction stability factor": 0.8748486859,
      score: 0.0,
    },
    "135": {
      velocity: [0.8734331412, 1.2704761827, 1.413176915],
      direction: [-8.4955940277, 7.6697768184, 25.5404795538],
      "speed factor": 1.0752679299,
      "Uz factor": 0.9961227114,
      "Directional variance factor": 0.3182420606,
      "Velocity stability factor": 0.5650686327,
      "Direction stability factor": 0.9054553512,
      score: 0.6735553489,
    },
    "180": {
      velocity: [0.0634129922, 0.3664697742, 0.9403024223],
      direction: [-176.010245209, 13.0813602493, 165.6062012293],
      "speed factor": 0.7157132052,
      "Uz factor": 0.9998251741,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3090489401,
      "Direction stability factor": 0.0510654266,
      score: 0.0,
    },
    "225": {
      velocity: [0.0907145033, 0.3741944728, 0.7511215612],
      direction: [-168.6124784011, -15.5044013827, 79.7866760107],
      "speed factor": 0.5108164055,
      "Uz factor": 0.9979604997,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4064389417,
      "Direction stability factor": 0.3100023489,
      score: 0.0,
    },
    "270": {
      velocity: [0.2191239684, 0.9574772641, 1.264704712],
      direction: [-41.4903885936, 2.9040636087, 22.9072316769],
      "speed factor": 0.8761226132,
      "Uz factor": 0.9993800171,
      "Directional variance factor": 0.7418610126,
      "Velocity stability factor": 0.1752425591,
      "Direction stability factor": 0.8211177215,
      score: 0.0,
    },
    "315": {
      velocity: [0.4765764649, 0.9030305497, 1.1837601673],
      direction: [-32.0023424934, -18.0212686455, 4.8191295994],
      "speed factor": 1.1126235051,
      "Uz factor": 0.9685703556,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4263071442,
      "Direction stability factor": 0.8977181331,
      score: 0.0,
    },
  },
  {
    position: [-39.36757348, 97.04444617, 47.87999908],
    "overall score": 0.0812654807,
    "0": {
      velocity: [0.5490265787, 0.9513115342, 1.2538353711],
      direction: [-47.9229301701, -23.2541431465, 27.3174505539],
      "speed factor": 0.9748574928,
      "Uz factor": 0.910696767,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5102771476,
      "Direction stability factor": 0.7909989424,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5259570266, 1.1155460928, 1.4184598743],
      direction: [-25.6855271354, 4.9959394801, 20.8928758278],
      "speed factor": 0.9904662136,
      "Uz factor": 0.9700963881,
      "Directional variance factor": 0.5559164907,
      "Velocity stability factor": 0.4228421045,
      "Direction stability factor": 0.8706155473,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2674760052, 0.6726315024, 1.1142128811],
      direction: [-31.35496316, -3.0550267554, 50.6413032743],
      "speed factor": 1.0419510383,
      "Uz factor": 0.990369432,
      "Directional variance factor": 0.7284420662,
      "Velocity stability factor": 0.2433944317,
      "Direction stability factor": 0.7722325932,
      score: 0.6290754211,
    },
    "112.5": {
      velocity: [0.16952504, 0.721964117, 1.202926426],
      direction: [-156.0854368843, 26.7333847101, 125.0354115188],
      "speed factor": 0.8121591946,
      "Uz factor": 0.9965427035,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1445306674,
      "Direction stability factor": 0.2191087544,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0380472227, 0.561163104, 1.1052340307],
      direction: [-173.4241084349, 7.4911655899, 159.8609566732],
      "speed factor": 0.7972111402,
      "Uz factor": 0.9936864604,
      "Directional variance factor": 0.3341186142,
      "Velocity stability factor": 0.1272637585,
      "Direction stability factor": 0.0742081525,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0745718525, 0.4494564983, 0.993597131],
      direction: [-145.5589990798, -19.7962276444, 47.201880611],
      "speed factor": 0.5266081462,
      "Uz factor": 0.9899233412,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2933595385,
      "Direction stability factor": 0.464553112,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0689484309, 0.395966769, 0.9270033312],
      direction: [-73.2051984476, -8.232582071, 122.3962093209],
      "speed factor": 0.734566073,
      "Uz factor": 0.9996802566,
      "Directional variance factor": 0.268214927,
      "Velocity stability factor": 0.1673882724,
      "Direction stability factor": 0.4566627562,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2389403891, 0.6934285715, 1.1109143181],
      direction: [-89.3982959991, -4.2571248829, 43.144371668],
      "speed factor": 0.9442768266,
      "Uz factor": 0.986280633,
      "Directional variance factor": 0.6215888993,
      "Velocity stability factor": 0.3605468887,
      "Direction stability factor": 0.6318259231,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7564259388, 0.9692874242, 1.2381847422],
      direction: [-21.3637450987, -8.4535270399, 8.8509404017],
      "speed factor": 1.2309348707,
      "Uz factor": 0.9157686804,
      "Directional variance factor": 0.2485753742,
      "Velocity stability factor": 0.6119748524,
      "Direction stability factor": 0.9160703181,
      score: 0.0,
    },
    id: "IDN:691.0",
    "45": {
      velocity: [0.376354582, 0.6368946032, 0.9472964522],
      direction: [-47.0418794969, -10.1071496081, 30.9632570813],
      "speed factor": 0.8651458721,
      "Uz factor": 0.9608957795,
      "Directional variance factor": 0.1015867015,
      "Velocity stability factor": 0.5358423259,
      "Direction stability factor": 0.7833190651,
      score: 0.0,
    },
    "90": {
      velocity: [0.324512831, 0.771211775, 1.1697790093],
      direction: [-20.5437146744, 24.9739224142, 78.4822954767],
      "speed factor": 1.2501568554,
      "Uz factor": 0.9999239893,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3830761135,
      "Direction stability factor": 0.7249277496,
      score: 0.0,
    },
    "135": {
      velocity: [0.2375354744, 1.0611961364, 1.2845411524],
      direction: [-69.8696686825, 7.0469858419, 70.3275440103],
      "speed factor": 0.8981436947,
      "Uz factor": 0.9905115314,
      "Directional variance factor": 0.3736012585,
      "Velocity stability factor": 0.156311507,
      "Direction stability factor": 0.6105632981,
      score: 0.0,
    },
    "180": {
      velocity: [0.1078211842, 0.3815268759, 0.9393437604],
      direction: [-177.6903522685, -4.1500034457, 174.7920754006],
      "speed factor": 0.7451196317,
      "Uz factor": 0.9662544589,
      "Directional variance factor": 0.6311108048,
      "Velocity stability factor": 0.3447960647,
      "Direction stability factor": 0.0208821454,
      score: 0.0,
    },
    "225": {
      velocity: [0.3879470287, 0.8380905174, 1.1961578475],
      direction: [-30.0736243547, -2.7069147892, 32.6189457815],
      "speed factor": 1.1440852731,
      "Uz factor": 0.9984437257,
      "Directional variance factor": 0.7593853521,
      "Velocity stability factor": 0.2735957874,
      "Direction stability factor": 0.8258539718,
      score: 0.6711722708,
    },
    "270": {
      velocity: [0.5358904092, 1.0755662948, 1.4215186907],
      direction: [-38.7602656466, -3.534920328, 12.2962293797],
      "speed factor": 0.9841778894,
      "Uz factor": 0.9995172108,
      "Directional variance factor": 0.6857848597,
      "Velocity stability factor": 0.3014135737,
      "Direction stability factor": 0.8581764027,
      score: 0.0,
    },
    "315": {
      velocity: [0.7614714246, 0.9207321134, 1.083666321],
      direction: [-13.8628193892, 2.3592270996, 14.9820403598],
      "speed factor": 1.1344335931,
      "Uz factor": 0.9318235808,
      "Directional variance factor": 0.7902909245,
      "Velocity stability factor": 0.7386239111,
      "Direction stability factor": 0.9198753896,
      score: 0.0,
    },
  },
  {
    position: [-49.20711102, 79.55436501, 45.87999908],
    "overall score": 0.0805609771,
    "0": {
      velocity: [0.0703494888, 0.4250099686, 0.9043017261],
      direction: [-170.6249296829, 9.5677935472, 163.2584425933],
      "speed factor": 0.4355294113,
      "Uz factor": 0.9609280933,
      "Directional variance factor": 0.1495294625,
      "Velocity stability factor": 0.4205443054,
      "Direction stability factor": 0.0725461881,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0536300223, 0.5468333678, 1.1193104923],
      direction: [-125.9372716561, 47.2847163565, 152.8986905743],
      "speed factor": 0.4855200325,
      "Uz factor": 0.9986995358,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3108527341,
      "Direction stability factor": 0.2254556605,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0481747176, 0.3439660192, 0.8056507364],
      direction: [-137.0538314889, -26.3297767569, 80.1401889406],
      "speed factor": 0.5328262943,
      "Uz factor": 0.8901417443,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3231538745,
      "Direction stability factor": 0.3966832766,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1892373687, 0.8232188712, 1.3004667727],
      direction: [-157.3315528066, -1.2970150263, 50.2887951636],
      "speed factor": 0.9260637194,
      "Uz factor": 0.9624459234,
      "Directional variance factor": 0.8847097754,
      "Velocity stability factor": 0.080103153,
      "Direction stability factor": 0.4232768112,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.443382891, 0.8937835644, 1.2130438588],
      direction: [-62.2212682838, -17.9573802269, 44.6718043432],
      "speed factor": 1.2697453012,
      "Uz factor": 0.9379466402,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3705778452,
      "Direction stability factor": 0.7030747983,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4202429846, 0.781829207, 1.2534012162],
      direction: [-49.4694230542, -8.8238095105, 23.6117611777],
      "speed factor": 0.9160344346,
      "Uz factor": 0.9657122853,
      "Directional variance factor": 0.2156613768,
      "Velocity stability factor": 0.3593828907,
      "Direction stability factor": 0.7969967105,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0599791572, 0.4909889043, 0.8103688298],
      direction: [-42.2633991602, -16.1214085931, 18.2713122363],
      "speed factor": 0.9108435847,
      "Uz factor": 0.9547297464,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2718609945,
      "Direction stability factor": 0.8318480239,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0323395355, 0.2527783821, 0.8242371942],
      direction: [-138.4984024804, 24.7873743617, 155.5968820087],
      "speed factor": 0.344221133,
      "Uz factor": 0.9231294561,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4192699978,
      "Direction stability factor": 0.1830686542,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0335926494, 0.3558979345, 0.8808109939],
      direction: [-53.1841959961, 17.7741141564, 178.3659447186],
      "speed factor": 0.4519682883,
      "Uz factor": 0.9999441937,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3176211398,
      "Direction stability factor": 0.3568051647,
      score: 0.0,
    },
    id: "IDN:351.0",
    "45": {
      velocity: [0.0665091627, 0.2986324701, 0.6716721832],
      direction: [-154.2239225563, -15.2306258295, 142.0095457304],
      "speed factor": 0.4056568348,
      "Uz factor": 0.9048911469,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5080216136,
      "Direction stability factor": 0.1771292548,
      score: 0.0,
    },
    "90": {
      velocity: [0.1541056202, 0.816857112, 1.3037491041],
      direction: [-105.4465091821, 2.9994811844, 36.1051475856],
      "speed factor": 1.3241492824,
      "Uz factor": 0.9848412119,
      "Directional variance factor": 0.7333794503,
      "Velocity stability factor": 0.1609240445,
      "Direction stability factor": 0.6068009534,
      score: 0.5269763504,
    },
    "135": {
      velocity: [0.1247653172, 0.9767084519, 1.3535901009],
      direction: [-147.3487639636, -23.8628325394, 1.6001160635],
      "speed factor": 0.8266375155,
      "Uz factor": 0.979745901,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0097997063,
      "Direction stability factor": 0.586253111,
      score: 0.0,
    },
    "180": {
      velocity: [0.5176574301, 0.8708885606, 1.2776800053],
      direction: [-67.62083724, -32.9978916865, 3.2790768858],
      "speed factor": 1.7008399788,
      "Uz factor": 0.9553837222,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4011349824,
      "Direction stability factor": 0.8030557941,
      score: 0.0,
    },
    "225": {
      velocity: [0.503945174, 0.7910230281, 1.1290070584],
      direction: [-21.899916954, 1.5321227484, 23.8238047029],
      "speed factor": 1.0798329994,
      "Uz factor": 0.9715250745,
      "Directional variance factor": 0.8638113112,
      "Velocity stability factor": 0.4382064983,
      "Direction stability factor": 0.8729896621,
      score: 0.7619992834,
    },
    "270": {
      velocity: [0.0730442681, 0.3849177244, 1.1603970815],
      direction: [-18.8891786167, 16.7652923533, 101.0221137237],
      "speed factor": 0.3522121467,
      "Uz factor": 0.9695519474,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1422926166,
      "Direction stability factor": 0.6669130768,
      score: 0.0,
    },
    "315": {
      velocity: [0.0219776834, 0.195230189, 0.7182925758],
      direction: [-177.9956768516, 28.5803217109, 179.1683208319],
      "speed factor": 0.2405430218,
      "Uz factor": 0.2818817228,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4351243137,
      "Direction stability factor": 0.0078777842,
      score: 0.0,
    },
  },
  {
    position: [-2.263993335, 93.64059614, 47.87999908],
    "overall score": 0.0804845289,
    "0": {
      velocity: [0.0988989493, 0.7314630718, 1.3354677441],
      direction: [-174.2754750251, 2.0265082357, 137.3067752539],
      "speed factor": 0.7495675503,
      "Uz factor": 0.9890639743,
      "Directional variance factor": 0.8198659346,
      "Velocity stability factor": 0.1407939233,
      "Direction stability factor": 0.1344937492,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.048191354, 0.5567381956, 1.1589076633],
      direction: [-47.8814065419, -3.0467663816, 172.6013400376],
      "speed factor": 0.494314288,
      "Uz factor": 0.9851302465,
      "Directional variance factor": 0.7291763216,
      "Velocity stability factor": 0.2817292525,
      "Direction stability factor": 0.3875479262,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0634369135, 0.4346602552, 0.9347223934],
      direction: [-41.373476473, 8.4311594727, 137.0810395139],
      "speed factor": 0.673317712,
      "Uz factor": 0.9950171289,
      "Directional variance factor": 0.2505636024,
      "Velocity stability factor": 0.2214589154,
      "Direction stability factor": 0.5042930111,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4803874166, 0.8898853691, 1.2321663521],
      direction: [-17.1271152104, 13.0467722782, 46.873533607],
      "speed factor": 1.0010588721,
      "Uz factor": 0.9636636822,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3776630911,
      "Direction stability factor": 0.82222042,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4275133521, 0.8114056323, 1.1351006929],
      direction: [-20.3586607235, 0.6913350079, 49.0664323976],
      "speed factor": 1.1527158587,
      "Uz factor": 0.961300539,
      "Directional variance factor": 0.9385479993,
      "Velocity stability factor": 0.4213411263,
      "Direction stability factor": 0.8071525191,
      score: 0.7435485409,
    },
    "202.5": {
      velocity: [0.5175013687, 0.8412154141, 1.139247491],
      direction: [-65.1465582591, -22.576029361, 6.0707451685],
      "speed factor": 0.9856146066,
      "Uz factor": 0.923351027,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5219381043,
      "Direction stability factor": 0.8021741571,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2232870684, 0.5245171425, 0.8473825766],
      direction: [-67.0763603686, -2.3602459933, 31.2345537191],
      "speed factor": 0.973042507,
      "Uz factor": 0.9936427356,
      "Directional variance factor": 0.7902003561,
      "Velocity stability factor": 0.3944102655,
      "Direction stability factor": 0.7269141275,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0704848166, 0.4193039895, 1.0495454612],
      direction: [-177.903570062, 11.1367483871, 177.861821729],
      "speed factor": 0.5709874914,
      "Uz factor": 0.9622251966,
      "Directional variance factor": 0.01006681,
      "Velocity stability factor": 0.2820159473,
      "Direction stability factor": 0.0117628006,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1175599473, 0.5410912173, 1.1539638719],
      direction: [-136.9669591602, -21.2770540516, 102.9366215088],
      "speed factor": 0.6871522635,
      "Uz factor": 0.9353918296,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1652445519,
      "Direction stability factor": 0.3336011648,
      score: 0.0,
    },
    id: "IDN:674.0",
    "45": {
      velocity: [0.1323762517, 0.585584262, 1.1556832801],
      direction: [-156.7605111659, -0.3711175871, 86.2944115308],
      "speed factor": 0.7954468518,
      "Uz factor": 0.9999999765,
      "Directional variance factor": 0.96701177,
      "Velocity stability factor": 0.1680837666,
      "Direction stability factor": 0.324847437,
      score: 0.0,
    },
    "90": {
      velocity: [0.4335546529, 0.8239065724, 1.1812738149],
      direction: [-7.1015999688, 18.4378147424, 59.3076526849],
      "speed factor": 1.3355766639,
      "Uz factor": 0.9849177294,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4542715381,
      "Direction stability factor": 0.8155298537,
      score: 0.0,
    },
    "135": {
      velocity: [0.7337523555, 1.1667077578, 1.3626562296],
      direction: [-3.47380585, 4.5305275838, 23.0290516258],
      "speed factor": 0.9874434898,
      "Uz factor": 0.9479377111,
      "Directional variance factor": 0.597286437,
      "Velocity stability factor": 0.4932224601,
      "Direction stability factor": 0.9263809515,
      score: 0.0,
    },
    "180": {
      velocity: [0.5010733373, 0.7929625726, 1.1724018645],
      direction: [-43.1972176754, -2.8385449347, 31.2312483563],
      "speed factor": 1.5486510057,
      "Uz factor": 0.9485635683,
      "Directional variance factor": 0.7476848947,
      "Velocity stability factor": 0.4710220679,
      "Direction stability factor": 0.793254261,
      score: 0.0,
    },
    "225": {
      velocity: [0.2138898588, 0.4927008005, 0.7916729303],
      direction: [-84.8642972431, -14.9554694226, 37.0957046823],
      "speed factor": 0.6725905116,
      "Uz factor": 0.9744688033,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4806997786,
      "Direction stability factor": 0.6612222169,
      score: 0.0,
    },
    "270": {
      velocity: [0.4719610654, 0.8647468853, 1.3973996732],
      direction: [-33.4106412456, 9.795749926, 33.70789319],
      "speed factor": 0.7912713224,
      "Uz factor": 0.9978571378,
      "Directional variance factor": 0.1292666732,
      "Velocity stability factor": 0.2700110608,
      "Direction stability factor": 0.8135596266,
      score: 0.0,
    },
    "315": {
      velocity: [0.2303824308, 0.8199737024, 1.1805660739],
      direction: [-155.6782197894, -0.7055521039, 22.4579559322],
      "speed factor": 1.0102892035,
      "Uz factor": 0.9647287578,
      "Directional variance factor": 0.9372842574,
      "Velocity stability factor": 0.2291768518,
      "Direction stability factor": 0.5051772897,
      score: 0.5442039221,
    },
  },
  {
    position: [-45.85200666, 40.13741357, 45.87999908],
    "overall score": 0.079210996,
    "0": {
      velocity: [0.0426767653, 0.5870865295, 1.142135346],
      direction: [-105.201041159, -5.4297676593, 166.4247844159],
      "speed factor": 0.6016175371,
      "Uz factor": 0.9961577631,
      "Directional variance factor": 0.5173539858,
      "Velocity stability factor": 0.2360623221,
      "Direction stability factor": 0.2454838178,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1618352433, 0.667400113, 1.2248916271],
      direction: [-128.4750113553, -1.5634896379, 144.0250021627],
      "speed factor": 0.5925683099,
      "Uz factor": 0.9442746252,
      "Directional variance factor": 0.8610231433,
      "Velocity stability factor": 0.3125496609,
      "Direction stability factor": 0.243055518,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3349501327, 0.6634644586, 1.0068117354],
      direction: [-28.2061218287, -1.5276079303, 34.4116160715],
      "speed factor": 1.0277506763,
      "Uz factor": 0.9139008801,
      "Directional variance factor": 0.8642126284,
      "Velocity stability factor": 0.3996550237,
      "Direction stability factor": 0.8260618392,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6568453845, 0.9254789806, 1.3183031215],
      direction: [-56.4101945324, -32.1726959393, 0.1650679034],
      "speed factor": 1.0410991984,
      "Uz factor": 0.8198422964,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.452432698,
      "Direction stability factor": 0.8428464932,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4506801469, 0.9603904122, 1.321471406],
      direction: [-43.7632320348, 2.7572049534, 30.9919251784],
      "speed factor": 1.3643696995,
      "Uz factor": 0.9643445051,
      "Directional variance factor": 0.7549151153,
      "Velocity stability factor": 0.2878743582,
      "Direction stability factor": 0.7923467855,
      score: 0.6568707611,
    },
    "202.5": {
      velocity: [0.1847282429, 0.6187652961, 1.0144023304],
      direction: [-33.6434944989, -0.823640327, 55.6172945563],
      "speed factor": 0.7249797182,
      "Uz factor": 0.9835063292,
      "Directional variance factor": 0.9267875265,
      "Velocity stability factor": 0.3620618564,
      "Direction stability factor": 0.7520533637,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0181302497, 0.3171585161, 0.8430621357],
      direction: [-116.4841625138, -19.4585104578, 145.5316639166],
      "speed factor": 0.5883672669,
      "Uz factor": 0.9972805348,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1995291181,
      "Direction stability factor": 0.2721782599,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0489861982, 0.1570776312, 0.4554221349],
      direction: [-178.5956222301, 143.0861314825, 176.4778685439],
      "speed factor": 0.2139005706,
      "Uz factor": 0.9667586346,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7019443866,
      "Direction stability factor": 0.0136847478,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0497616003, 0.2879300652, 0.7676401786],
      direction: [-179.7565664215, 18.2851943755, 173.757487035],
      "speed factor": 0.3656533127,
      "Uz factor": 0.9998157661,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4217958461,
      "Direction stability factor": 0.0180165182,
      score: 0.0,
    },
    id: "IDN:390.0",
    "45": {
      velocity: [0.1175410088, 0.5061934766, 1.0915470151],
      direction: [-120.0445637135, 8.9684377024, 85.6555668888],
      "speed factor": 0.6876038744,
      "Uz factor": 0.9444492066,
      "Directional variance factor": 0.2028055376,
      "Velocity stability factor": 0.2081639375,
      "Direction stability factor": 0.4286107483,
      score: 0.0,
    },
    "90": {
      velocity: [0.6157125369, 1.0168312074, 1.297254579],
      direction: [-63.6316488485, -17.0827956005, 29.9944729605],
      "speed factor": 1.6483131429,
      "Uz factor": 0.8707324853,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5025714075,
      "Direction stability factor": 0.7399274394,
      score: 0.0,
    },
    "135": {
      velocity: [0.153334741, 0.6839557162, 1.1322737067],
      direction: [-118.7768381838, -43.8899855693, 41.4528160225],
      "speed factor": 0.5788661426,
      "Uz factor": 0.8920021024,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2111603995,
      "Direction stability factor": 0.5549176272,
      score: 0.0,
    },
    "180": {
      velocity: [0.320660549, 0.8171578625, 1.1788466079],
      direction: [-33.2737524147, 6.0018779785, 29.4140242227],
      "speed factor": 1.5959042574,
      "Uz factor": 0.9837287144,
      "Directional variance factor": 0.4664997352,
      "Velocity stability factor": 0.3237863902,
      "Direction stability factor": 0.8258672871,
      score: 0.6105051749,
    },
    "225": {
      velocity: [0.0529473343, 0.2840428513, 0.739381401],
      direction: [-174.1820541646, -7.0946924352, 154.7564073499],
      "speed factor": 0.3877495764,
      "Uz factor": 0.9960751937,
      "Directional variance factor": 0.3693606724,
      "Velocity stability factor": 0.3830463709,
      "Direction stability factor": 0.0862820513,
      score: 0.0,
    },
    "270": {
      velocity: [0.0175852067, 0.2336774389, 0.5662351917],
      direction: [-173.9200459929, 103.097908528, 178.9953252692],
      "speed factor": 0.213822402,
      "Uz factor": 0.9999177164,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5672231338,
      "Direction stability factor": 0.0196795243,
      score: 0.0,
    },
    "315": {
      velocity: [0.066735301, 0.2044337812, 0.6136014733],
      direction: [-175.2498128823, 86.9005560196, 177.0960667263],
      "speed factor": 0.2518827633,
      "Uz factor": 0.9992564181,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.556362491,
      "Direction stability factor": 0.0212614455,
      score: 0.0,
    },
  },
  {
    position: [12.51734566, 102.5890542, 46.87999908],
    "overall score": 0.0774516604,
    "0": {
      velocity: [0.503161232, 1.0113986119, 1.3706888193],
      direction: [-45.8344101823, -23.2326810049, -2.1620903828],
      "speed factor": 1.0364317887,
      "Uz factor": 0.9590720269,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3972151183,
      "Direction stability factor": 0.8786880006,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5700955043, 0.97059691, 1.2777009509],
      direction: [-54.7408418486, -22.142357053, -0.5283925367],
      "speed factor": 0.8617693635,
      "Uz factor": 0.9754265324,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5424103448,
      "Direction stability factor": 0.849409863,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2079920585, 0.4618797842, 0.8050256192],
      direction: [-61.0716883312, -5.5419638572, 133.2130086807],
      "speed factor": 0.715482577,
      "Uz factor": 0.9893274935,
      "Directional variance factor": 0.5073809905,
      "Velocity stability factor": 0.4665179593,
      "Direction stability factor": 0.4603202861,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6470164945, 1.099690964, 1.2885751269],
      direction: [-9.8617883464, 9.7135949872, 33.0393901273],
      "speed factor": 1.2370755091,
      "Uz factor": 0.9821548644,
      "Directional variance factor": 0.1365693345,
      "Velocity stability factor": 0.4689055555,
      "Direction stability factor": 0.8808300598,
      score: 0.5917837524,
    },
    "157.5": {
      velocity: [0.0951213255, 0.9335008833, 1.2646296287],
      direction: [-16.4462435027, 12.8408647577, 81.2336594279],
      "speed factor": 1.3261693405,
      "Uz factor": 0.9999442834,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0435861151,
      "Direction stability factor": 0.7286669363,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0481952075, 0.4388985055, 0.869629712],
      direction: [-173.8141258139, -20.2590997642, 169.9802096209],
      "speed factor": 0.5142378166,
      "Uz factor": 0.9902135496,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3683972891,
      "Direction stability factor": 0.0450157349,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0858732471, 0.4658314312, 1.0426525868],
      direction: [-49.6241612524, -2.9511918383, 94.6850579559],
      "speed factor": 0.8641734405,
      "Uz factor": 0.9994519552,
      "Directional variance factor": 0.7376718366,
      "Velocity stability factor": 0.0715912248,
      "Direction stability factor": 0.5991410578,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0513344772, 0.4504268699, 0.874633374],
      direction: [-179.6323344778, 12.4481526945, 177.0961806622],
      "speed factor": 0.6133690949,
      "Uz factor": 0.9046572056,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3962422229,
      "Direction stability factor": 0.0090874579,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.291816793, 0.7147650909, 1.1260126231],
      direction: [-54.3513689841, 2.3154895307, 35.4422487793],
      "speed factor": 0.9077073041,
      "Uz factor": 0.9977965987,
      "Directional variance factor": 0.7941787084,
      "Velocity stability factor": 0.3281099218,
      "Direction stability factor": 0.750573284,
      score: 0.0,
    },
    id: "IDN:416.0",
    "45": {
      velocity: [0.2423264406, 0.6415442103, 0.9731626102],
      direction: [-52.7127763138, -7.3418835911, 21.3892152789],
      "speed factor": 0.8714618126,
      "Uz factor": 0.9931896694,
      "Directional variance factor": 0.3473881252,
      "Velocity stability factor": 0.4058533201,
      "Direction stability factor": 0.7941611345,
      score: 0.0,
    },
    "90": {
      velocity: [0.5139229319, 0.8052789141, 1.0661083379],
      direction: [-12.9954487168, 14.0721917904, 32.2492745837],
      "speed factor": 1.3053806847,
      "Uz factor": 0.9812267777,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5969833226,
      "Direction stability factor": 0.8743202131,
      score: 0.0,
    },
    "135": {
      velocity: [0.9713058081, 1.2623316877, 1.3858405517],
      direction: [1.2890826168, 10.6898871172, 23.9654787022],
      "speed factor": 1.0683748339,
      "Uz factor": 0.9908765241,
      "Directional variance factor": 0.0497878118,
      "Velocity stability factor": 0.6659634226,
      "Direction stability factor": 0.9370100109,
      score: 0.647442814,
    },
    "180": {
      velocity: [0.0309224965, 0.4851666943, 0.9491498553],
      direction: [-71.787389933, 6.8839549092, 152.4180015548],
      "speed factor": 0.9475275567,
      "Uz factor": 0.9983496376,
      "Directional variance factor": 0.388092897,
      "Velocity stability factor": 0.2764764347,
      "Direction stability factor": 0.3772072459,
      score: 0.0,
    },
    "225": {
      velocity: [0.0185119595, 0.3728435152, 0.8361172148],
      direction: [-173.4957533378, -13.1095805311, 116.1051710774],
      "speed factor": 0.5089722005,
      "Uz factor": 0.9989992581,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.26515225,
      "Direction stability factor": 0.1955529877,
      score: 0.0,
    },
    "270": {
      velocity: [0.2600278187, 0.9449427968, 1.2764501027],
      direction: [-53.7017357609, 2.5612778256, 19.1696731036],
      "speed factor": 0.8646531709,
      "Uz factor": 0.9992918583,
      "Directional variance factor": 0.7723308599,
      "Velocity stability factor": 0.1982428455,
      "Direction stability factor": 0.7975794198,
      score: 0.0,
    },
    "315": {
      velocity: [0.4423272724, 0.9001498656, 1.2448853058],
      direction: [-42.5886478604, -19.9742534327, 17.3871752877],
      "speed factor": 1.1090742156,
      "Uz factor": 0.9820892228,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3489360563,
      "Direction stability factor": 0.8334004913,
      score: 0.0,
    },
  },
  {
    position: [6.546950306, 102.3600707, 47.87999908],
    "overall score": 0.0770877327,
    "0": {
      velocity: [0.639228794, 1.0236171445, 1.3243906947],
      direction: [-34.6381031323, -10.4332508804, 8.8688887167],
      "speed factor": 1.0489527428,
      "Uz factor": 0.9402030852,
      "Directional variance factor": 0.0725999217,
      "Velocity stability factor": 0.523928413,
      "Direction stability factor": 0.8791472449,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5942248309, 0.9602100046, 1.280554678],
      direction: [-50.82420209, -14.110308375, 7.2247344197],
      "speed factor": 0.8525470831,
      "Uz factor": 0.9436128748,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5561687101,
      "Direction stability factor": 0.8387529541,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.178575404, 0.5079034198, 0.8842513875],
      direction: [-97.8132705008, -4.8406446695, 49.5502981455],
      "speed factor": 0.7867762566,
      "Uz factor": 0.9927752477,
      "Directional variance factor": 0.5697204738,
      "Velocity stability factor": 0.3694400306,
      "Direction stability factor": 0.5906567538,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.531224701, 1.1204571132, 1.3841397886],
      direction: [-17.077874611, 5.2781696776, 38.743840189],
      "speed factor": 1.2604359762,
      "Uz factor": 0.9971682733,
      "Directional variance factor": 0.530829362,
      "Velocity stability factor": 0.2939406598,
      "Direction stability factor": 0.8449396811,
      score: 0.628662346,
    },
    "157.5": {
      velocity: [0.0399442351, 0.7929599058, 1.1985096647],
      direction: [-88.7924030462, 11.2756559001, 50.727656916],
      "speed factor": 1.126511109,
      "Uz factor": 0.9990328081,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0525351035,
      "Direction stability factor": 0.6124442779,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0815298665, 0.4854020214, 1.0663829137],
      direction: [-175.6084207262, -15.6997856545, 175.851869158],
      "speed factor": 0.5687239135,
      "Uz factor": 0.9982936218,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2427444293,
      "Direction stability factor": 0.023721417,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0853161553, 0.4953526438, 0.9114575835],
      direction: [-32.0594571926, -2.2865102971, 57.0526068556],
      "speed factor": 0.9189388474,
      "Uz factor": 0.9994808531,
      "Directional variance factor": 0.7967546403,
      "Velocity stability factor": 0.1983554414,
      "Direction stability factor": 0.7524664888,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2224280468, 0.5333701116, 0.9512282189],
      direction: [-116.6663696282, 14.8553759126, 108.1625292528],
      "speed factor": 0.7263171104,
      "Uz factor": 0.8918174717,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4655418906,
      "Direction stability factor": 0.3754752809,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1586163473, 0.5820668654, 0.9594496134],
      direction: [-98.5356044744, 10.4738215468, 119.7074872231],
      "speed factor": 0.7391887935,
      "Uz factor": 0.9863887239,
      "Directional variance factor": 0.0689936403,
      "Velocity stability factor": 0.3549812809,
      "Direction stability factor": 0.3937691897,
      score: 0.0,
    },
    id: "IDN:666.0",
    "45": {
      velocity: [0.1954623202, 0.648424839, 1.0495925906],
      direction: [-55.2592620557, -6.1238412197, 24.5223368291],
      "speed factor": 0.8808083316,
      "Uz factor": 0.9922062262,
      "Directional variance factor": 0.4556585583,
      "Velocity stability factor": 0.3056191175,
      "Direction stability factor": 0.7783844475,
      score: 0.0,
    },
    "90": {
      velocity: [0.4888832561, 0.8394210799, 1.1486845232],
      direction: [-13.4098168849, 11.8973301037, 30.6330561279],
      "speed factor": 1.3607261347,
      "Uz factor": 0.995517515,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5184390758,
      "Direction stability factor": 0.8776586861,
      score: 0.0,
    },
    "135": {
      velocity: [0.7114076504, 1.2544490855, 1.407764013],
      direction: [-2.7895394918, 9.5447718673, 28.0766514848],
      "speed factor": 1.061703391,
      "Uz factor": 0.9997246614,
      "Directional variance factor": 0.151575834,
      "Velocity stability factor": 0.4388685157,
      "Direction stability factor": 0.9142605806,
      score: 0.6047413777,
    },
    "180": {
      velocity: [0.0409178118, 0.4728930641, 1.1651959686],
      direction: [-175.1857696375, 7.4449799102, 174.1216823241],
      "speed factor": 0.9235572327,
      "Uz factor": 0.9994701461,
      "Directional variance factor": 0.338224008,
      "Velocity stability factor": 0.1141172907,
      "Direction stability factor": 0.0297015223,
      score: 0.0,
    },
    "225": {
      velocity: [0.0406086988, 0.4301957891, 0.8834793107],
      direction: [-110.8884453568, -15.0202279913, 146.1544134479],
      "speed factor": 0.5872643308,
      "Uz factor": 0.9997454887,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2424442374,
      "Direction stability factor": 0.2859920589,
      score: 0.0,
    },
    "270": {
      velocity: [0.3048982639, 1.0128882227, 1.2861333771],
      direction: [-50.3583506678, 2.5938470833, 17.001997496],
      "speed factor": 0.9268254295,
      "Uz factor": 0.999008535,
      "Directional variance factor": 0.7694358148,
      "Velocity stability factor": 0.2259985985,
      "Direction stability factor": 0.8128879218,
      score: 0.0,
    },
    "315": {
      velocity: [0.5835964643, 0.906224167, 1.126768076],
      direction: [-29.2321407942, -14.4922960818, 9.6626388274],
      "speed factor": 1.1165583594,
      "Uz factor": 0.9668793448,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5593596514,
      "Direction stability factor": 0.8919589455,
      score: 0.0,
    },
  },
  {
    position: [12.51858065, 102.9428494, 46.87999908],
    "overall score": 0.0770412149,
    "0": {
      velocity: [0.502767629, 0.992958687, 1.3525879812],
      direction: [-45.6122262939, -23.0752757055, -2.317203275],
      "speed factor": 1.0175354564,
      "Uz factor": 0.9597983018,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4095186505,
      "Direction stability factor": 0.8797360472,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5481835727, 0.9531764957, 1.2494983092],
      direction: [-54.3985669987, -21.7895350672, -0.2250405257],
      "speed factor": 0.8463022018,
      "Uz factor": 0.9739339273,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.54647838,
      "Direction stability factor": 0.849517982,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2183074003, 0.4633425387, 0.8082428874],
      direction: [-59.9440524504, -5.210434178, 130.1317361394],
      "speed factor": 0.7177484812,
      "Uz factor": 0.9909750654,
      "Directional variance factor": 0.5368502953,
      "Velocity stability factor": 0.4728604751,
      "Direction stability factor": 0.4720116984,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6643112365, 1.1069211826, 1.292536576],
      direction: [-8.7681298573, 10.1058277816, 33.1040003513],
      "speed factor": 1.2452089999,
      "Uz factor": 0.9842604873,
      "Directional variance factor": 0.1017041972,
      "Velocity stability factor": 0.479943109,
      "Direction stability factor": 0.8836885272,
      score: 0.5872560902,
    },
    "157.5": {
      velocity: [0.0791161369, 0.9316152289, 1.2663002685],
      direction: [-15.7610958925, 12.770727121, 83.7460405397],
      "speed factor": 1.3234905031,
      "Uz factor": 0.99996777,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0291309739,
      "Direction stability factor": 0.7235912877,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0700056319, 0.4320823601, 0.8718128291],
      direction: [-176.7517329126, -20.6649920223, 166.176869755],
      "speed factor": 0.5062516428,
      "Uz factor": 0.9907011234,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3834887667,
      "Direction stability factor": 0.0474205481,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0909758855, 0.4666672505, 1.0592348274],
      direction: [-49.3589466104, -3.1504259968, 91.4302864971],
      "speed factor": 0.8657239861,
      "Uz factor": 0.9995531211,
      "Directional variance factor": 0.7199621336,
      "Velocity stability factor": 0.0604520174,
      "Direction stability factor": 0.6089187969,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0529002496, 0.4732157194, 0.8816403229],
      direction: [-169.7544421479, 11.5174423356, 175.5890818769],
      "speed factor": 0.6444018261,
      "Uz factor": 0.8986116138,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3922519921,
      "Direction stability factor": 0.0407124333,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2938966194, 0.7140436839, 1.1190492362],
      direction: [-57.2384277866, 2.4665410706, 35.3881658485],
      "speed factor": 0.906791162,
      "Uz factor": 0.9978618948,
      "Directional variance factor": 0.7807519048,
      "Velocity stability factor": 0.3353936376,
      "Direction stability factor": 0.7427039066,
      score: 0.0,
    },
    id: "IDN:417.0",
    "45": {
      velocity: [0.2420099732, 0.6358091305, 0.9713087151],
      direction: [-50.3145109829, -7.0606168765, 22.063737077],
      "speed factor": 0.8636713861,
      "Uz factor": 0.9932642983,
      "Directional variance factor": 0.372389611,
      "Velocity stability factor": 0.4071032001,
      "Direction stability factor": 0.7989493109,
      score: 0.0,
    },
    "90": {
      velocity: [0.5208789102, 0.8068333235, 1.0676094355],
      direction: [-12.1391396319, 14.1558338518, 31.8043933113],
      "speed factor": 1.3079004279,
      "Uz factor": 0.9841391081,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6009646084,
      "Direction stability factor": 0.8779346307,
      score: 0.0,
    },
    "135": {
      velocity: [0.9738815161, 1.265200037, 1.3872628196],
      direction: [1.9535575405, 10.8483149285, 23.7308259111],
      "speed factor": 1.0708024622,
      "Uz factor": 0.991727201,
      "Directional variance factor": 0.0357053397,
      "Velocity stability factor": 0.6668928772,
      "Direction stability factor": 0.9395075879,
      score: 0.6454033481,
    },
    "180": {
      velocity: [0.0542340721, 0.4806733705, 0.917447601],
      direction: [-71.5462142332, 6.8205763643, 155.3728254856],
      "speed factor": 0.9387521232,
      "Uz factor": 0.9986074198,
      "Directional variance factor": 0.3937265454,
      "Velocity stability factor": 0.3198249605,
      "Direction stability factor": 0.3696693341,
      score: 0.0,
    },
    "225": {
      velocity: [0.0309925254, 0.3701974796, 0.8357035475],
      direction: [-178.7375024711, -13.2191765565, 166.5974665939],
      "speed factor": 0.5053600724,
      "Uz factor": 0.9988033056,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2767413367,
      "Direction stability factor": 0.040736197,
      score: 0.0,
    },
    "270": {
      velocity: [0.3077766136, 0.9567047245, 1.2718083126],
      direction: [-67.7125890823, 2.0307644567, 18.1923386529],
      "speed factor": 0.8754157145,
      "Uz factor": 0.9992238723,
      "Directional variance factor": 0.8194876039,
      "Velocity stability factor": 0.2395687068,
      "Direction stability factor": 0.7613752007,
      score: 0.0,
    },
    "315": {
      velocity: [0.4392203933, 0.8971999515, 1.2305625099],
      direction: [-38.5528248811, -20.7917873118, 16.9079724281],
      "speed factor": 1.1054396279,
      "Uz factor": 0.9834821392,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3580348115,
      "Direction stability factor": 0.8459422297,
      score: 0.0,
    },
  },
  {
    position: [12.51858065, 102.9428494, 46.87999908],
    "overall score": 0.0770412149,
    "0": {
      velocity: [0.502767629, 0.992958687, 1.3525879812],
      direction: [-45.6122262939, -23.0752757055, -2.317203275],
      "speed factor": 1.0175354564,
      "Uz factor": 0.9597983018,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4095186505,
      "Direction stability factor": 0.8797360472,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5481835727, 0.9531764957, 1.2494983092],
      direction: [-54.3985669987, -21.7895350672, -0.2250405257],
      "speed factor": 0.8463022018,
      "Uz factor": 0.9739339273,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.54647838,
      "Direction stability factor": 0.849517982,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2183074003, 0.4633425387, 0.8082428874],
      direction: [-59.9440524504, -5.210434178, 130.1317361394],
      "speed factor": 0.7177484812,
      "Uz factor": 0.9909750654,
      "Directional variance factor": 0.5368502953,
      "Velocity stability factor": 0.4728604751,
      "Direction stability factor": 0.4720116984,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6643112365, 1.1069211826, 1.292536576],
      direction: [-8.7681298573, 10.1058277816, 33.1040003513],
      "speed factor": 1.2452089999,
      "Uz factor": 0.9842604873,
      "Directional variance factor": 0.1017041972,
      "Velocity stability factor": 0.479943109,
      "Direction stability factor": 0.8836885272,
      score: 0.5872560902,
    },
    "157.5": {
      velocity: [0.0791161369, 0.9316152289, 1.2663002685],
      direction: [-15.7610958925, 12.770727121, 83.7460405397],
      "speed factor": 1.3234905031,
      "Uz factor": 0.99996777,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0291309739,
      "Direction stability factor": 0.7235912877,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0700056319, 0.4320823601, 0.8718128291],
      direction: [-176.7517329126, -20.6649920223, 166.176869755],
      "speed factor": 0.5062516428,
      "Uz factor": 0.9907011234,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3834887667,
      "Direction stability factor": 0.0474205481,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0909758855, 0.4666672505, 1.0592348274],
      direction: [-49.3589466104, -3.1504259968, 91.4302864971],
      "speed factor": 0.8657239861,
      "Uz factor": 0.9995531211,
      "Directional variance factor": 0.7199621336,
      "Velocity stability factor": 0.0604520174,
      "Direction stability factor": 0.6089187969,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0529002496, 0.4732157194, 0.8816403229],
      direction: [-169.7544421479, 11.5174423356, 175.5890818769],
      "speed factor": 0.6444018261,
      "Uz factor": 0.8986116138,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3922519921,
      "Direction stability factor": 0.0407124333,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2938966194, 0.7140436839, 1.1190492362],
      direction: [-57.2384277866, 2.4665410706, 35.3881658485],
      "speed factor": 0.906791162,
      "Uz factor": 0.9978618948,
      "Directional variance factor": 0.7807519048,
      "Velocity stability factor": 0.3353936376,
      "Direction stability factor": 0.7427039066,
      score: 0.0,
    },
    id: "IDN:398.0",
    "45": {
      velocity: [0.2420099732, 0.6358091305, 0.9713087151],
      direction: [-50.3145109829, -7.0606168765, 22.063737077],
      "speed factor": 0.8636713861,
      "Uz factor": 0.9932642983,
      "Directional variance factor": 0.372389611,
      "Velocity stability factor": 0.4071032001,
      "Direction stability factor": 0.7989493109,
      score: 0.0,
    },
    "90": {
      velocity: [0.5208789102, 0.8068333235, 1.0676094355],
      direction: [-12.1391396319, 14.1558338518, 31.8043933113],
      "speed factor": 1.3079004279,
      "Uz factor": 0.9841391081,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6009646084,
      "Direction stability factor": 0.8779346307,
      score: 0.0,
    },
    "135": {
      velocity: [0.9738815161, 1.265200037, 1.3872628196],
      direction: [1.9535575405, 10.8483149285, 23.7308259111],
      "speed factor": 1.0708024622,
      "Uz factor": 0.991727201,
      "Directional variance factor": 0.0357053397,
      "Velocity stability factor": 0.6668928772,
      "Direction stability factor": 0.9395075879,
      score: 0.6454033481,
    },
    "180": {
      velocity: [0.0542340721, 0.4806733705, 0.917447601],
      direction: [-71.5462142332, 6.8205763643, 155.3728254856],
      "speed factor": 0.9387521232,
      "Uz factor": 0.9986074198,
      "Directional variance factor": 0.3937265454,
      "Velocity stability factor": 0.3198249605,
      "Direction stability factor": 0.3696693341,
      score: 0.0,
    },
    "225": {
      velocity: [0.0309925254, 0.3701974796, 0.8357035475],
      direction: [-178.7375024711, -13.2191765565, 166.5974665939],
      "speed factor": 0.5053600724,
      "Uz factor": 0.9988033056,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2767413367,
      "Direction stability factor": 0.040736197,
      score: 0.0,
    },
    "270": {
      velocity: [0.3077766136, 0.9567047245, 1.2718083126],
      direction: [-67.7125890823, 2.0307644567, 18.1923386529],
      "speed factor": 0.8754157145,
      "Uz factor": 0.9992238723,
      "Directional variance factor": 0.8194876039,
      "Velocity stability factor": 0.2395687068,
      "Direction stability factor": 0.7613752007,
      score: 0.0,
    },
    "315": {
      velocity: [0.4392203933, 0.8971999515, 1.2305625099],
      direction: [-38.5528248811, -20.7917873118, 16.9079724281],
      "speed factor": 1.1054396279,
      "Uz factor": 0.9834821392,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3580348115,
      "Direction stability factor": 0.8459422297,
      score: 0.0,
    },
  },
  {
    position: [0.575319964, 101.7772919, 47.87999908],
    "overall score": 0.0722450886,
    "0": {
      velocity: [0.6781947764, 1.0634012907, 1.3458342588],
      direction: [-26.056938587, -1.1319194134, 18.2009425554],
      "speed factor": 1.0897215885,
      "Uz factor": 0.9409939238,
      "Directional variance factor": 0.899384941,
      "Velocity stability factor": 0.5361035288,
      "Direction stability factor": 0.8770614413,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6649871939, 0.9871595275, 1.3058145382],
      direction: [-29.1218623347, -6.8942663617, 19.176978053],
      "speed factor": 0.8764749083,
      "Uz factor": 0.937647685,
      "Directional variance factor": 0.3871763234,
      "Velocity stability factor": 0.5855939706,
      "Direction stability factor": 0.8658365545,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1455145378, 0.5049920171, 0.8956527319],
      direction: [-53.6947435746, -3.6002400524, 71.2560797634],
      "speed factor": 0.7822662999,
      "Uz factor": 0.9914454735,
      "Directional variance factor": 0.679978662,
      "Velocity stability factor": 0.329710621,
      "Direction stability factor": 0.6529143796,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2012201551, 0.9735878326, 1.3291759259],
      direction: [-70.4131979835, 7.6149982266, 78.4980183927],
      "speed factor": 1.095218296,
      "Uz factor": 0.9991611959,
      "Directional variance factor": 0.3231112687,
      "Velocity stability factor": 0.0662567483,
      "Direction stability factor": 0.5863577323,
      score: 0.3905208704,
    },
    "157.5": {
      velocity: [0.1113265106, 0.6948572159, 1.1855327611],
      direction: [-32.7305642701, 6.7973347047, 68.6881640937],
      "speed factor": 0.9871424358,
      "Uz factor": 0.9989967946,
      "Directional variance factor": 0.3957924707,
      "Velocity stability factor": 0.1215233185,
      "Direction stability factor": 0.7182813101,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0618849059, 0.5179043791, 1.0711029618],
      direction: [-171.6689851686, -23.3669231128, 136.9781314614],
      "speed factor": 0.6068054773,
      "Uz factor": 0.995581693,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.224010123,
      "Direction stability factor": 0.1426468982,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1547637599, 0.5206481537, 1.0409218898],
      direction: [-44.1616563743, -3.9101325842, 32.5063726574],
      "speed factor": 0.9658650666,
      "Uz factor": 0.9998796473,
      "Directional variance factor": 0.6524326592,
      "Velocity stability factor": 0.1401183639,
      "Direction stability factor": 0.7870332527,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1823194316, 0.5713282166, 0.9123823726],
      direction: [-63.8187412344, 15.1352878464, 67.847073055],
      "speed factor": 0.7780065856,
      "Uz factor": 0.891594874,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.464615852,
      "Direction stability factor": 0.634261627,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.118605632, 0.5318160009, 0.9279094665],
      direction: [-127.5408712682, 8.1341260705, 88.491770329],
      "speed factor": 0.6753733144,
      "Uz factor": 0.943775739,
      "Directional variance factor": 0.2769665715,
      "Velocity stability factor": 0.3481587932,
      "Direction stability factor": 0.3999093289,
      score: 0.0,
    },
    id: "IDN:668.0",
    "45": {
      velocity: [0.2251355945, 0.6151954829, 1.0711636991],
      direction: [-49.7819897218, -3.2494735916, 43.318632758],
      "speed factor": 0.8356701877,
      "Uz factor": 0.9881791184,
      "Directional variance factor": 0.711157903,
      "Velocity stability factor": 0.312205922,
      "Direction stability factor": 0.7413871598,
      score: 0.0,
    },
    "90": {
      velocity: [0.2712437423, 0.7199106206, 1.1269790609],
      direction: [-84.0016772638, 8.8790110565, 33.6047690581],
      "speed factor": 1.1669961828,
      "Uz factor": 0.9998064392,
      "Directional variance factor": 0.2107545728,
      "Velocity stability factor": 0.3754351326,
      "Direction stability factor": 0.6733154269,
      score: 0.4832051398,
    },
    "135": {
      velocity: [0.2592298491, 0.900446086, 1.3174446589],
      direction: [-2.8131355385, 12.4644428205, 44.6145463499],
      "speed factor": 0.7620928374,
      "Uz factor": 0.9975399588,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1472790674,
      "Direction stability factor": 0.8682564392,
      score: 0.0,
    },
    "180": {
      velocity: [0.0447191753, 0.5208137875, 1.1320828071],
      direction: [-178.2653432205, -0.2710446109, 179.9940320007],
      "speed factor": 1.0171461096,
      "Uz factor": 0.9936444323,
      "Directional variance factor": 0.9759071457,
      "Velocity stability factor": 0.1432043447,
      "Direction stability factor": 0.0048350688,
      score: 0.282195407,
    },
    "225": {
      velocity: [0.0563099527, 0.491344051, 0.8305068995],
      direction: [-137.7013483415, -15.2927333361, 140.4618119374],
      "speed factor": 0.6707384001,
      "Uz factor": 0.9999909395,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.304166796,
      "Direction stability factor": 0.2273245548,
      score: 0.0,
    },
    "270": {
      velocity: [0.4968516653, 1.0542065195, 1.3300121071],
      direction: [-45.0385542421, 0.3388009008, 14.1477988462],
      "speed factor": 0.9646330052,
      "Uz factor": 0.9986165666,
      "Directional variance factor": 0.9698843644,
      "Velocity stability factor": 0.3428003738,
      "Direction stability factor": 0.8355934636,
      score: 0.0,
    },
    "315": {
      velocity: [0.6787294898, 0.9159925296, 1.1643569181],
      direction: [-26.2536829378, -10.1104997517, 13.4452957841],
      "speed factor": 1.1285939542,
      "Uz factor": 0.9483756775,
      "Directional variance factor": 0.101288911,
      "Velocity stability factor": 0.6060415628,
      "Direction stability factor": 0.8897250591,
      score: 0.0,
    },
  },
  {
    position: [-45.30487816, 96.17933745, 46.87999908],
    "overall score": 0.0705055591,
    "0": {
      velocity: [0.5446147879, 1.0671720025, 1.3178405363],
      direction: [-32.9364981355, -12.4953981331, 29.8212478901],
      "speed factor": 1.0935856293,
      "Uz factor": 0.8946545209,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4627389398,
      "Direction stability factor": 0.8256729277,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5756599541, 1.114375653, 1.5469709752],
      direction: [-39.4596285028, 17.2626492763, 43.9751609811],
      "speed factor": 0.9894270086,
      "Uz factor": 0.9343650503,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3718789512,
      "Direction stability factor": 0.7682366959,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2306188561, 0.6824741765, 1.1826286569],
      direction: [-35.8252794732, -5.9020341876, 53.3606957362],
      "speed factor": 1.0571979967,
      "Uz factor": 0.9886955154,
      "Directional variance factor": 0.4753747389,
      "Velocity stability factor": 0.1493273331,
      "Direction stability factor": 0.75226118,
      score: 0.532306108,
    },
    "112.5": {
      velocity: [0.0621094421, 0.4100478547, 1.2306868122],
      direction: [-175.4429856633, 26.1356158365, 178.322436338],
      "speed factor": 0.4612751903,
      "Uz factor": 0.9989255339,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0326294155,
      "Direction stability factor": 0.0173182722,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0422076791, 0.3062947397, 0.912079206],
      direction: [-179.5685637086, -39.4579924231, 174.797117992],
      "speed factor": 0.4351347709,
      "Uz factor": 0.7389282797,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2886265073,
      "Direction stability factor": 0.0156508842,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1907146274, 0.7486733446, 1.2299641081],
      direction: [-65.8276093601, -13.2060720671, 19.1582955812],
      "speed factor": 0.8771871884,
      "Uz factor": 0.9955609978,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2009188977,
      "Direction stability factor": 0.7639280418,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0433211839, 0.3393751025, 0.9035173115],
      direction: [-154.0001927205, -7.7981009486, 124.7805149018],
      "speed factor": 0.6295817121,
      "Uz factor": 0.9934399874,
      "Directional variance factor": 0.3068354712,
      "Velocity stability factor": 0.165310537,
      "Direction stability factor": 0.2256091455,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1702209368, 0.723528606, 1.1161319389],
      direction: [-79.3052224944, -14.1630830953, 28.3562496777],
      "speed factor": 0.9852655689,
      "Uz factor": 0.9896092779,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3063258967,
      "Direction stability factor": 0.7009403551,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7345872545, 0.9351251742, 1.0877199785],
      direction: [-12.3614054035, 0.433761097, 22.9249147301],
      "speed factor": 1.1875509334,
      "Uz factor": 0.864514123,
      "Directional variance factor": 0.961443458,
      "Velocity stability factor": 0.7155747308,
      "Direction stability factor": 0.9019824441,
      score: 0.0,
    },
    id: "IDN:427.0",
    "45": {
      velocity: [0.170825325, 0.6778558478, 1.1039995163],
      direction: [-33.2713703809, -8.9791520887, 33.0474783508],
      "speed factor": 0.9207868707,
      "Uz factor": 0.9477365746,
      "Directional variance factor": 0.2018531477,
      "Velocity stability factor": 0.241358911,
      "Direction stability factor": 0.8157809757,
      score: 0.0,
    },
    "90": {
      velocity: [0.1458053125, 0.6639210261, 1.1290615642],
      direction: [-77.1595853124, 25.462054151, 92.9410865732],
      "speed factor": 1.0762354117,
      "Uz factor": 0.9958271444,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2823630192,
      "Direction stability factor": 0.5274981337,
      score: 0.0,
    },
    "135": {
      velocity: [0.0348404604, 0.3194502024, 1.0554802491],
      direction: [-176.8591188107, -2.2603554807, 177.2855980084],
      "speed factor": 0.2703667826,
      "Uz factor": 0.9718709545,
      "Directional variance factor": 0.7990795128,
      "Velocity stability factor": 0.1775574256,
      "Direction stability factor": 0.0162646755,
      score: 0.0,
    },
    "180": {
      velocity: [0.0640948245, 0.2686797565, 0.7742330397],
      direction: [-175.211275998, -134.96009654, 179.0525983683],
      "speed factor": 0.5247299047,
      "Uz factor": 0.7732921764,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4404417072,
      "Direction stability factor": 0.0159336823,
      score: 0.0,
    },
    "225": {
      velocity: [0.1155558086, 0.6580549252, 1.1187320781],
      direction: [-153.4351584648, -2.7396334737, 167.6574397799],
      "speed factor": 0.8983169874,
      "Uz factor": 0.9999789326,
      "Directional variance factor": 0.7564770246,
      "Velocity stability factor": 0.0983646206,
      "Direction stability factor": 0.108076116,
      score: 0.0,
    },
    "270": {
      velocity: [0.5885670654, 1.1224996687, 1.4002937838],
      direction: [-34.5383414898, -8.2717727421, 8.8969769444],
      "speed factor": 1.0271234419,
      "Uz factor": 0.9956226541,
      "Directional variance factor": 0.2647313118,
      "Velocity stability factor": 0.3597073636,
      "Direction stability factor": 0.8793463377,
      score: 0.5957828377,
    },
    "315": {
      velocity: [0.72794022, 0.8782284246, 1.0349152452],
      direction: [-12.0565744204, 7.7320455203, 23.7974479414],
      "speed factor": 1.0820648187,
      "Uz factor": 0.9051903994,
      "Directional variance factor": 0.3127070649,
      "Velocity stability factor": 0.7509708181,
      "Direction stability factor": 0.9004054934,
      score: 0.0,
    },
  },
  {
    position: [-48.85687529, 38.74789386, 46.87999908],
    "overall score": 0.0693921738,
    "0": {
      velocity: [0.1250699163, 0.8322645212, 1.3204968814],
      direction: [-85.1629867035, -11.6270319765, 82.9114563675],
      "speed factor": 0.8528639413,
      "Uz factor": 0.9945913146,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1693805335,
      "Direction stability factor": 0.533126547,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1405958169, 0.6542435718, 1.4378205371],
      direction: [-176.0629385416, 2.214863186, 112.4961737212],
      "speed factor": 0.5808869374,
      "Uz factor": 0.9990426179,
      "Directional variance factor": 0.8031232724,
      "Velocity stability factor": 0.1611192151,
      "Direction stability factor": 0.1984469104,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1354055454, 0.6262845329, 1.0784961743],
      direction: [-74.9856959786, 4.9944958242, 36.5004671554],
      "speed factor": 0.9701564927,
      "Uz factor": 0.9774153446,
      "Directional variance factor": 0.5560448156,
      "Velocity stability factor": 0.1572970996,
      "Direction stability factor": 0.6903162135,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3458859559, 0.8725571383, 1.2580428044],
      direction: [-76.7639957453, -22.9134064511, 12.8405764845],
      "speed factor": 0.981565823,
      "Uz factor": 0.9617443406,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2448992028,
      "Direction stability factor": 0.7510984105,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5263837563, 0.9346577302, 1.2423252131],
      direction: [-31.6517679469, -3.2566376719, 11.0146298626],
      "speed factor": 1.3278128043,
      "Uz factor": 0.9231248229,
      "Directional variance factor": 0.7105210958,
      "Velocity stability factor": 0.4145092017,
      "Direction stability factor": 0.8814822283,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1372225152, 0.5298151453, 1.0394311694],
      direction: [-111.8740875631, -1.4748371573, 40.8038814354],
      "speed factor": 0.6207607912,
      "Uz factor": 0.9493041015,
      "Directional variance factor": 0.8689033638,
      "Velocity stability factor": 0.3062898761,
      "Direction stability factor": 0.5758945306,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4322131821, 0.7846334553, 1.0670214446],
      direction: [-21.7376265192, 4.6490537093, 15.8994859579],
      "speed factor": 1.4555896129,
      "Uz factor": 0.9978959236,
      "Directional variance factor": 0.5867507814,
      "Velocity stability factor": 0.3840151673,
      "Direction stability factor": 0.8954524653,
      score: 0.6904177198,
    },
    "292.5": {
      velocity: [0.0682844478, 0.28966366, 0.8466520111],
      direction: [-58.2450025118, 20.1321067181, 144.485834555],
      "speed factor": 0.3944496851,
      "Uz factor": 0.9685818548,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4291921541,
      "Direction stability factor": 0.4368587859,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0385423252, 0.4233820746, 0.9096903533],
      direction: [-114.177409647, -3.9822386833, 129.0773011329],
      "speed factor": 0.5376689578,
      "Uz factor": 0.9995783947,
      "Directional variance factor": 0.6460232282,
      "Velocity stability factor": 0.2983473477,
      "Direction stability factor": 0.3242924701,
      score: 0.0,
    },
    id: "IDN:482.0",
    "45": {
      velocity: [0.1497964121, 0.4945999476, 1.1033731042],
      direction: [-125.7931426385, 10.5637385533, 172.9166685989],
      "speed factor": 0.6718554386,
      "Uz factor": 0.9918531401,
      "Directional variance factor": 0.0610010175,
      "Velocity stability factor": 0.2247723235,
      "Direction stability factor": 0.1702505243,
      score: 0.0,
    },
    "90": {
      velocity: [0.2550340656, 1.0090337197, 1.3097561893],
      direction: [-105.9514599454, -8.6856723515, 34.2172586858],
      "speed factor": 1.6356731871,
      "Uz factor": 0.9555565492,
      "Directional variance factor": 0.2279402354,
      "Velocity stability factor": 0.2302031142,
      "Direction stability factor": 0.6106424482,
      score: 0.4198570615,
    },
    "135": {
      velocity: [0.1262801713, 0.608780372, 1.0090684169],
      direction: [-140.0378963469, -31.3357753425, 22.3796888172],
      "speed factor": 0.5152414655,
      "Uz factor": 0.9740852822,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2886396892,
      "Direction stability factor": 0.5488400412,
      score: 0.0,
    },
    "180": {
      velocity: [0.2711160513, 0.8009171114, 1.0864273168],
      direction: [-35.3028192224, -7.8895779209, 19.6809694188],
      "speed factor": 1.5641861708,
      "Uz factor": 0.9496630856,
      "Directional variance factor": 0.2987041848,
      "Velocity stability factor": 0.3575698786,
      "Direction stability factor": 0.8472672538,
      score: 0.0,
    },
    "225": {
      velocity: [0.0802697405, 0.4547992534, 0.8829761169],
      direction: [-137.9318260515, 3.4109612432, 78.1933868067],
      "speed factor": 0.6208507521,
      "Uz factor": 0.997033452,
      "Directional variance factor": 0.6968034451,
      "Velocity stability factor": 0.2785430734,
      "Direction stability factor": 0.3996521865,
      score: 0.0,
    },
    "270": {
      velocity: [0.0220138008, 0.4876907611, 1.1162867037],
      direction: [-178.6384694528, 17.6683502664, 179.5183415459],
      "speed factor": 0.4462527938,
      "Uz factor": 0.9860239152,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1368340279,
      "Direction stability factor": 0.0051199694,
      score: 0.0,
    },
    "315": {
      velocity: [0.0634657945, 0.5200461423, 1.151304625],
      direction: [-125.5819274857, -0.9634916231, 85.3629864349],
      "speed factor": 0.6407486013,
      "Uz factor": 0.9874164053,
      "Directional variance factor": 0.9143563002,
      "Velocity stability factor": 0.1175060125,
      "Direction stability factor": 0.4140419058,
      score: 0.0,
    },
  },
  {
    position: [6.735951834, 93.60918028, 46.87999908],
    "overall score": 0.0677733087,
    "0": {
      velocity: [0.0545620237, 0.3118845345, 0.8970850483],
      direction: [-179.5821006984, -37.2565671587, 165.7214727373],
      "speed factor": 0.319604004,
      "Uz factor": 0.9954947429,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4145890584,
      "Direction stability factor": 0.0408234071,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0218859971, 0.444057983, 1.1066850003],
      direction: [-173.8841968749, -14.6371683574, 170.2232288365],
      "speed factor": 0.3942682708,
      "Uz factor": 0.9989733751,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2984892863,
      "Direction stability factor": 0.0441460397,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0595063123, 0.3919472001, 0.7888397209],
      direction: [-25.6847052068, 4.2994588388, 85.5359209145],
      "speed factor": 0.6071523421,
      "Uz factor": 0.9962088968,
      "Directional variance factor": 0.617825881,
      "Velocity stability factor": 0.3483008313,
      "Direction stability factor": 0.6910538163,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4317050665, 0.9330527021, 1.2390979798],
      direction: [-15.493123669, 9.2631473455, 54.1316499215],
      "speed factor": 1.049619106,
      "Uz factor": 0.9794946161,
      "Directional variance factor": 0.1766091248,
      "Velocity stability factor": 0.331624782,
      "Direction stability factor": 0.8065978511,
      score: 0.5303574023,
    },
    "157.5": {
      velocity: [0.4337979855, 0.8613921856, 1.1293501077],
      direction: [-18.3010576728, 9.8903544553, 55.4295471291],
      "speed factor": 1.2237287904,
      "Uz factor": 0.9382910697,
      "Directional variance factor": 0.1208573818,
      "Velocity stability factor": 0.4311834252,
      "Direction stability factor": 0.7951927644,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2819741656, 0.8155120875, 1.119910534],
      direction: [-43.7326359589, -12.4519145821, 27.2295902808],
      "speed factor": 0.9554991644,
      "Uz factor": 0.8950395327,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3557089713,
      "Direction stability factor": 0.8028827049,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2064094755, 0.5342963331, 0.8360774419],
      direction: [-46.9986323952, -5.0929354742, 70.606085844],
      "speed factor": 0.9911840839,
      "Uz factor": 0.9837624684,
      "Directional variance factor": 0.5472946245,
      "Velocity stability factor": 0.3890030426,
      "Direction stability factor": 0.6733202271,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0471053699, 0.3095140601, 0.8268187195],
      direction: [-154.2144254686, -10.6091974271, 118.7515133244],
      "speed factor": 0.4214809809,
      "Uz factor": 0.9986958435,
      "Directional variance factor": 0.0569602287,
      "Velocity stability factor": 0.4282052355,
      "Direction stability factor": 0.2417612811,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0639367549, 0.4578759739, 0.8255917862],
      direction: [-124.6452428235, 5.0989672283, 66.5496718912],
      "speed factor": 0.5814740691,
      "Uz factor": 0.9964986526,
      "Directional variance factor": 0.5467584686,
      "Velocity stability factor": 0.3865367818,
      "Direction stability factor": 0.4689030147,
      score: 0.0,
    },
    id: "IDN:411.0",
    "45": {
      velocity: [0.1384217183, 0.5848151427, 1.0120739138],
      direction: [-179.4620040491, 0.0169944848, 173.6493309737],
      "speed factor": 0.7944020944,
      "Uz factor": 0.9999816339,
      "Directional variance factor": 0.9984893791,
      "Velocity stability factor": 0.2897484101,
      "Direction stability factor": 0.0191351805,
      score: 0.0,
    },
    "90": {
      velocity: [0.4050712813, 0.868450737, 1.1386185727],
      direction: [-16.7197122465, 10.307866048, 43.0939416069],
      "speed factor": 1.4077840584,
      "Uz factor": 0.9894729628,
      "Directional variance factor": 0.0837452402,
      "Velocity stability factor": 0.4646149846,
      "Direction stability factor": 0.8338509615,
      score: 0.554015537,
    },
    "135": {
      velocity: [0.5766854032, 1.1489222209, 1.3377908261],
      direction: [2.1691450028, 9.7566499806, 24.8977886832],
      "speed factor": 0.9723906949,
      "Uz factor": 0.9387547146,
      "Directional variance factor": 0.1327422239,
      "Velocity stability factor": 0.386693023,
      "Direction stability factor": 0.9368648787,
      score: 0.0,
    },
    "180": {
      velocity: [0.3943748245, 0.7545278884, 1.014540989],
      direction: [-29.4718919261, 5.6290047192, 45.7108670397],
      "speed factor": 1.4735883049,
      "Uz factor": 0.9148224161,
      "Directional variance factor": 0.499644025,
      "Velocity stability factor": 0.5113358036,
      "Direction stability factor": 0.7911590029,
      score: 0.0,
    },
    "225": {
      velocity: [0.1262088258, 0.480260862, 0.7480884691],
      direction: [-75.3848862942, -9.9804185074, 43.2316088901],
      "speed factor": 0.6556086342,
      "Uz factor": 0.9702513305,
      "Directional variance factor": 0.1128516882,
      "Velocity stability factor": 0.4410666349,
      "Direction stability factor": 0.6705097356,
      score: 0.0,
    },
    "270": {
      velocity: [0.2245937097, 0.7254968644, 1.1170886472],
      direction: [-58.3076283001, 0.2953030848, 28.4642538451],
      "speed factor": 0.6638530569,
      "Uz factor": 0.9999001942,
      "Directional variance factor": 0.9737508369,
      "Velocity stability factor": 0.2959971335,
      "Direction stability factor": 0.758966994,
      score: 0.0,
    },
    "315": {
      velocity: [0.0666979693, 0.3325988057, 0.7602553809],
      direction: [-157.8666709104, -49.1705732731, 14.0454978577],
      "speed factor": 0.4097948282,
      "Uz factor": 0.9945264427,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4373612814,
      "Direction stability factor": 0.5224661979,
      score: 0.0,
    },
  },
  {
    position: [-5.23758929, 101.210003, 47.87999908],
    "overall score": 0.063936672,
    "0": {
      velocity: [0.7545007008, 1.104374181, 1.3585327512],
      direction: [-16.3380423881, 7.7231453163, 27.8204423947],
      "speed factor": 1.1317086007,
      "Uz factor": 0.9747447821,
      "Directional variance factor": 0.3134981941,
      "Velocity stability factor": 0.5802999312,
      "Direction stability factor": 0.8773375423,
      score: 0.6621183025,
    },
    "22.5": {
      velocity: [0.6803365593, 1.0499911168, 1.3426827864],
      direction: [-20.358630512, 0.2571112485, 17.6183269368],
      "speed factor": 0.9322615466,
      "Uz factor": 0.9636179682,
      "Directional variance factor": 0.9771456668,
      "Velocity stability factor": 0.5716782804,
      "Direction stability factor": 0.8945084515,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2440810638, 0.5410278998, 0.9137817994],
      direction: [-41.5137907151, -1.3025244729, 69.653776466],
      "speed factor": 0.8380882844,
      "Uz factor": 0.9886873521,
      "Directional variance factor": 0.8842200468,
      "Velocity stability factor": 0.4015858762,
      "Direction stability factor": 0.6912012023,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0726484243, 0.8962349736, 1.2806419159],
      direction: [-29.5324797871, 12.56067415, 74.6270620562],
      "speed factor": 1.0082017336,
      "Uz factor": 0.9980825571,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.7106679393,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1767212251, 0.6943305367, 1.1701403419],
      direction: [-54.0669278934, 4.2615948465, 49.2563230544],
      "speed factor": 0.9863942139,
      "Uz factor": 0.9989947251,
      "Directional variance factor": 0.6211915692,
      "Velocity stability factor": 0.1875903453,
      "Direction stability factor": 0.7129909696,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.079808624, 0.6723496592, 1.2330268499],
      direction: [-174.8663873, -26.193048382, 176.1661416327],
      "speed factor": 0.7877621281,
      "Uz factor": 0.9955110544,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1132880907,
      "Direction stability factor": 0.0249096419,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2568379431, 0.5191495129, 0.9204921169],
      direction: [-41.5540411982, -4.748929769, 30.0123334303],
      "speed factor": 0.9630849073,
      "Uz factor": 0.9934487391,
      "Directional variance factor": 0.5778729094,
      "Velocity stability factor": 0.3560245993,
      "Direction stability factor": 0.8012045149,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1858423148, 0.619908618, 0.9730136617],
      direction: [-123.1556973069, 15.7912127294, 61.2233071062],
      "speed factor": 0.8441609802,
      "Uz factor": 0.8603197595,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4227359899,
      "Direction stability factor": 0.4878360989,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1561496642, 0.5150800214, 1.0034095563],
      direction: [-130.812572756, -5.6026305866, 176.6658053928],
      "speed factor": 0.6541196591,
      "Uz factor": 0.7476014377,
      "Directional variance factor": 0.5019883923,
      "Velocity stability factor": 0.3175876759,
      "Direction stability factor": 0.145893394,
      score: 0.0,
    },
    id: "IDN:670.0",
    "45": {
      velocity: [0.2100442282, 0.5929120283, 1.0561931748],
      direction: [-49.1612165073, 3.5108405906, 155.7892068133],
      "speed factor": 0.8054007543,
      "Uz factor": 0.9805482062,
      "Directional variance factor": 0.6879252808,
      "Velocity stability factor": 0.3121076812,
      "Direction stability factor": 0.4306932686,
      score: 0.0,
    },
    "90": {
      velocity: [0.2680479797, 0.7896246885, 1.2492833179],
      direction: [-35.7596838141, 13.7347975911, 45.2777332431],
      "speed factor": 1.2800047269,
      "Uz factor": 0.9956799559,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2838379981,
      "Direction stability factor": 0.7748960637,
      score: 0.0,
    },
    "135": {
      velocity: [0.2023640392, 0.7214026288, 1.2471412084],
      direction: [-103.238134216, 8.5181555573, 37.1827960999],
      "speed factor": 0.6105593492,
      "Uz factor": 0.9941356371,
      "Directional variance factor": 0.2428306171,
      "Velocity stability factor": 0.1581072635,
      "Direction stability factor": 0.6099418602,
      score: 0.0,
    },
    "180": {
      velocity: [0.1528297021, 0.7645896831, 1.2389411439],
      direction: [-123.8212975288, -2.7638162194, 138.0867424107],
      "speed factor": 1.4932389278,
      "Uz factor": 0.9946862898,
      "Directional variance factor": 0.7543274472,
      "Velocity stability factor": 0.1441910164,
      "Direction stability factor": 0.2724776668,
      score: 0.3608684493,
    },
    "225": {
      velocity: [0.138250331, 0.5464348633, 0.8139832473],
      direction: [-131.7152086829, -13.2640801673, 90.0995792699],
      "speed factor": 0.7459433878,
      "Uz factor": 0.99981648,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3926643573,
      "Direction stability factor": 0.3838478112,
      score: 0.0,
    },
    "270": {
      velocity: [0.3764303491, 1.003814625, 1.2867380846],
      direction: [-42.7885064988, -0.2819680428, 13.9289923939],
      "speed factor": 0.9185227946,
      "Uz factor": 0.9964624482,
      "Directional variance factor": 0.974936174,
      "Velocity stability factor": 0.2819463414,
      "Direction stability factor": 0.842451392,
      score: 0.0,
    },
    "315": {
      velocity: [0.7641444354, 0.92024752, 1.1408656663],
      direction: [-21.8367734933, -7.1848638802, 15.387599465],
      "speed factor": 1.1338365259,
      "Uz factor": 0.9457600344,
      "Directional variance factor": 0.3613454329,
      "Velocity stability factor": 0.6943901873,
      "Direction stability factor": 0.896598964,
      score: 0.0,
    },
  },
  {
    position: [-50.06973548, 50.82220129, 46.87999908],
    "overall score": 0.06259585,
    "0": {
      velocity: [0.2802668302, 0.8042389291, 1.3125223676],
      direction: [-82.683380849, -15.8037577115, 39.4203065557],
      "speed factor": 0.8241446864,
      "Uz factor": 0.9947312079,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.282757066,
      "Direction stability factor": 0.6608230905,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1312494008, 0.6416382979, 1.1285613691],
      direction: [-61.9373432875, 11.0861445981, 105.1626904003],
      "speed factor": 0.5696950216,
      "Uz factor": 0.9855622743,
      "Directional variance factor": 0.0145649246,
      "Velocity stability factor": 0.355064829,
      "Direction stability factor": 0.5358332398,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2076676569, 0.5421199348, 0.9671702094],
      direction: [-34.626503764, 12.7126393477, 53.678516549],
      "speed factor": 0.8397799194,
      "Uz factor": 0.9579581732,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3213430562,
      "Direction stability factor": 0.7547082769,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.279318876, 0.8433979964, 1.2469479036],
      direction: [-43.9341842262, -15.7004636267, 49.9380561047],
      "speed factor": 0.9487638254,
      "Uz factor": 0.9280359781,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1989782773,
      "Direction stability factor": 0.7392437769,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1354983382, 0.8243507882, 1.3074656175],
      direction: [-84.9408637733, -9.4564746825, 83.4240720797],
      "speed factor": 1.171106274,
      "Uz factor": 0.9986848029,
      "Directional variance factor": 0.1594244727,
      "Velocity stability factor": 0.0415751855,
      "Direction stability factor": 0.5323196226,
      score: 0.3164097259,
    },
    "202.5": {
      velocity: [0.1224737431, 0.5642505175, 1.143605418],
      direction: [-143.9856141625, -9.6747886155, 16.688948775],
      "speed factor": 0.6611071819,
      "Uz factor": 0.9998165555,
      "Directional variance factor": 0.1400187897,
      "Velocity stability factor": 0.2148497165,
      "Direction stability factor": 0.5536817696,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3049219873, 0.7323047111, 1.0935960689],
      direction: [-16.643087241, -3.7020975709, 13.0822866291],
      "speed factor": 1.3585134864,
      "Uz factor": 0.9947839207,
      "Directional variance factor": 0.6709246604,
      "Velocity stability factor": 0.2347118006,
      "Direction stability factor": 0.917429517,
      score: 0.6851238737,
    },
    "292.5": {
      velocity: [0.0840400128, 0.5072012285, 1.1818581977],
      direction: [-31.9129792516, -2.0680728463, 178.2610215585],
      "speed factor": 0.6906816162,
      "Uz factor": 0.9526057718,
      "Directional variance factor": 0.8161713025,
      "Velocity stability factor": 0.1949263268,
      "Direction stability factor": 0.4161833311,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0974233549, 0.7449254858, 1.1990226494],
      direction: [-33.0460509082, -7.7347110713, 23.4589901843],
      "speed factor": 0.9460091337,
      "Uz factor": 0.9908584163,
      "Directional variance factor": 0.312470127,
      "Velocity stability factor": 0.1127339535,
      "Direction stability factor": 0.8430415525,
      score: 0.0,
    },
    id: "IDN:474.0",
    "45": {
      velocity: [0.1496252533, 0.532353868, 0.9400878171],
      direction: [-134.0906372393, 11.8357314606, 101.2736388574],
      "speed factor": 0.7231396672,
      "Uz factor": 0.9676351102,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3573789484,
      "Direction stability factor": 0.3462103442,
      score: 0.0,
    },
    "90": {
      velocity: [0.5904975361, 1.0455955695, 1.3829733052],
      direction: [-31.9632724966, -5.3234611318, 22.7637825808],
      "speed factor": 1.6949410155,
      "Uz factor": 0.9187461095,
      "Directional variance factor": 0.526803455,
      "Velocity stability factor": 0.4216055912,
      "Direction stability factor": 0.8479804026,
      score: 0.0,
    },
    "135": {
      velocity: [0.2623942872, 0.6552417964, 1.0232427398],
      direction: [-85.7629162108, -39.2882494173, 19.7571151198],
      "speed factor": 0.5545641072,
      "Uz factor": 0.9279587231,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3869000925,
      "Direction stability factor": 0.7068888019,
      score: 0.0,
    },
    "180": {
      velocity: [0.0438120772, 0.4132408963, 0.928189049],
      direction: [-172.624193549, -25.7712033358, 162.9739684076],
      "speed factor": 0.8070569176,
      "Uz factor": 0.9998074052,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.30314908,
      "Direction stability factor": 0.0677828835,
      score: 0.0,
    },
    "225": {
      velocity: [0.0357456364, 0.4351211477, 0.8682259841],
      direction: [-138.1224980484, 1.5784624732, 167.1380514396],
      "speed factor": 0.5939879843,
      "Uz factor": 0.9968697408,
      "Directional variance factor": 0.8596922246,
      "Velocity stability factor": 0.2517828054,
      "Direction stability factor": 0.1520540292,
      score: 0.0,
    },
    "270": {
      velocity: [0.0797255979, 0.7501922287, 1.216725656],
      direction: [-28.6765310033, 11.4904304288, 108.6880795337],
      "speed factor": 0.6864501127,
      "Uz factor": 0.9728462553,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1031307109,
      "Direction stability factor": 0.6184316374,
      score: 0.0,
    },
    "315": {
      velocity: [0.0484500142, 0.7190385187, 1.2811369741],
      direction: [-19.9964995036, -7.4409486497, 83.2594894028],
      "speed factor": 0.8859270124,
      "Uz factor": 0.9775506003,
      "Directional variance factor": 0.3385823422,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.7131778086,
      score: 0.0,
    },
  },
  {
    position: [-5.23758929, 101.210003, 46.87999908],
    "overall score": 0.0606279805,
    "0": {
      velocity: [0.7288095342, 1.102885771, 1.3819188284],
      direction: [-17.5917794208, 9.5459654318, 32.309645546],
      "speed factor": 1.130183351,
      "Uz factor": 0.9648122942,
      "Directional variance factor": 0.1514697394,
      "Velocity stability factor": 0.546199551,
      "Direction stability factor": 0.8613849306,
      score: 0.6051097879,
    },
    "22.5": {
      velocity: [0.6445581059, 1.0397982994, 1.3411231496],
      direction: [-19.497932586, 1.7175450201, 25.1612272823],
      "speed factor": 0.9232115922,
      "Uz factor": 0.9537890276,
      "Directional variance factor": 0.8473293315,
      "Velocity stability factor": 0.5495498802,
      "Direction stability factor": 0.8759467781,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2506083307, 0.5176664035, 0.8813538039],
      direction: [-39.9154160482, 0.3173327505, 67.0763259894],
      "speed factor": 0.8018997692,
      "Uz factor": 0.9821777523,
      "Directional variance factor": 0.9717926444,
      "Velocity stability factor": 0.4363945273,
      "Direction stability factor": 0.7028007166,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1770821286, 0.8210705834, 1.2771910668],
      direction: [-56.8947577285, 12.3175455236, 62.2076247857],
      "speed factor": 0.9236470456,
      "Uz factor": 0.995845132,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0893088863,
      "Direction stability factor": 0.6691600486,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0598731709, 0.5489423657, 1.1082661517],
      direction: [-133.2545291334, 6.0658964821, 96.2086667959],
      "speed factor": 0.779849862,
      "Uz factor": 0.9999607658,
      "Directional variance factor": 0.4608092016,
      "Velocity stability factor": 0.1426331896,
      "Direction stability factor": 0.3626022335,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0753757492, 0.5887980896, 1.2121948805],
      direction: [-173.3158395279, -28.897131335, 179.8683353419],
      "speed factor": 0.6898684781,
      "Uz factor": 0.9975986213,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1258973889,
      "Direction stability factor": 0.0189328476,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2497395246, 0.5128018251, 0.9403275409],
      direction: [-39.7167773019, -7.0226784954, 29.9264452705],
      "speed factor": 0.9513091814,
      "Uz factor": 0.9906069906,
      "Directional variance factor": 0.3757619115,
      "Velocity stability factor": 0.3298894031,
      "Direction stability factor": 0.806546604,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1250532139, 0.5984900157, 1.0018144903],
      direction: [-115.1067813832, 16.2517133756, 62.6233298592],
      "speed factor": 0.814994184,
      "Uz factor": 0.8492499832,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3570361367,
      "Direction stability factor": 0.5063052465,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2234171773, 0.5278729594, 1.0961091847],
      direction: [-124.5655888816, -13.6208226335, 164.8879184836],
      "speed factor": 0.6703658964,
      "Uz factor": 0.7315107267,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2971037735,
      "Direction stability factor": 0.1959624795,
      score: 0.0,
    },
    id: "IDN:404.0",
    "45": {
      velocity: [0.1952013817, 0.5500652085, 1.0641876104],
      direction: [-163.305336589, 5.3757743125, 66.1670778504],
      "speed factor": 0.7471984252,
      "Uz factor": 0.9693343895,
      "Directional variance factor": 0.5221533944,
      "Velocity stability factor": 0.2935416935,
      "Direction stability factor": 0.3625766266,
      score: 0.0,
    },
    "90": {
      velocity: [0.2234698588, 0.7126400826, 1.2172427327],
      direction: [-25.986065366, 16.4516703597, 39.3681160146],
      "speed factor": 1.155210428,
      "Uz factor": 0.9929823865,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2746873832,
      "Direction stability factor": 0.8184606073,
      score: 0.0,
    },
    "135": {
      velocity: [0.0334079627, 0.4004181653, 1.0582101398],
      direction: [-136.9925295429, -2.3205751561, 160.2512078389],
      "speed factor": 0.3388940442,
      "Uz factor": 0.9990251656,
      "Directional variance factor": 0.7937266528,
      "Velocity stability factor": 0.1742033281,
      "Direction stability factor": 0.1743229517,
      score: 0.0,
    },
    "180": {
      velocity: [0.0991354407, 0.6948477495, 1.2223878685],
      direction: [-120.9162221612, -2.3226218305, 139.8531343602],
      "speed factor": 1.3570333622,
      "Uz factor": 0.9959408845,
      "Directional variance factor": 0.7935447262,
      "Velocity stability factor": 0.114925521,
      "Direction stability factor": 0.2756406763,
      score: 0.3649378999,
    },
    "225": {
      velocity: [0.1311195409, 0.5227264383, 0.8374603925],
      direction: [-125.4013616861, -13.4992910124, 170.0129439711],
      "speed factor": 0.7135787931,
      "Uz factor": 0.9997257295,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3651545386,
      "Direction stability factor": 0.1794047065,
      score: 0.0,
    },
    "270": {
      velocity: [0.3453541022, 0.9767594983, 1.2505277138],
      direction: [-48.4224276701, -1.23478407, 14.6097367048],
      "speed factor": 0.8937664801,
      "Uz factor": 0.9942402182,
      "Directional variance factor": 0.890241416,
      "Velocity stability factor": 0.2859961547,
      "Direction stability factor": 0.8249106545,
      score: 0.0,
    },
    "315": {
      velocity: [0.7617632438, 0.9096737555, 1.14802894],
      direction: [-23.3900033267, -6.8534612317, 16.6960232113],
      "speed factor": 1.1208085957,
      "Uz factor": 0.9342448697,
      "Directional variance factor": 0.3908034461,
      "Velocity stability factor": 0.6866473738,
      "Direction stability factor": 0.8886499263,
      score: 0.0,
    },
  },
  {
    position: [9.735933557, 93.59870832, 45.87999908],
    "overall score": 0.0570876792,
    "0": {
      velocity: [0.0852768668, 0.2877501891, 0.6237510188],
      direction: [-179.1916987228, -137.5734575442, 178.367940807],
      "speed factor": 0.2948723082,
      "Uz factor": 0.9866993289,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6258515777,
      "Direction stability factor": 0.0067787791,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0183989268, 0.41962773, 1.2314489481],
      direction: [-179.1019945524, -8.5172001067, 179.8829186125],
      "speed factor": 0.372577244,
      "Uz factor": 0.9997305183,
      "Directional variance factor": 0.2429155461,
      "Velocity stability factor": 0.215552758,
      "Direction stability factor": 0.0028196857,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0542856693, 0.3088188649, 0.6954694077],
      direction: [-102.84302704, 3.4656417228, 153.5459052538],
      "speed factor": 0.4783810091,
      "Uz factor": 0.9934086388,
      "Directional variance factor": 0.691942958,
      "Velocity stability factor": 0.4270673682,
      "Direction stability factor": 0.2878085214,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3434180567, 0.9431573054, 1.25309223],
      direction: [-22.8198956083, 10.3031271387, 57.1454117975],
      "speed factor": 1.0609860788,
      "Uz factor": 0.9652233336,
      "Directional variance factor": 0.0841664766,
      "Velocity stability factor": 0.2469544086,
      "Direction stability factor": 0.7778741461,
      score: 0.4717172943,
    },
    "157.5": {
      velocity: [0.5991218906, 0.9211034531, 1.1773571822],
      direction: [-22.7036511586, 15.6326817982, 67.79602473],
      "speed factor": 1.3085570467,
      "Uz factor": 0.8841718647,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5271241255,
      "Direction stability factor": 0.7486120114,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1302415286, 0.82024802, 1.1243811872],
      direction: [-157.139859407, -8.5647644388, 128.5678414461],
      "speed factor": 0.9610480454,
      "Uz factor": 0.8488548894,
      "Directional variance factor": 0.2386876054,
      "Velocity stability factor": 0.2356039344,
      "Direction stability factor": 0.2063674976,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1157203402, 0.4732108707, 0.9186656207],
      direction: [-171.3973502461, -4.330711814, 170.4687364877],
      "speed factor": 0.8778631901,
      "Uz factor": 0.9535945403,
      "Directional variance factor": 0.6150478388,
      "Velocity stability factor": 0.2208637733,
      "Direction stability factor": 0.0503719813,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0509767827, 0.2628727566, 0.7669394267],
      direction: [-149.7566879015, -21.2491957311, 132.1721033065],
      "speed factor": 0.3579671542,
      "Uz factor": 0.9917463763,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4749561597,
      "Direction stability factor": 0.2168644689,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0482263726, 0.3468451454, 0.7734631103],
      direction: [-155.6114666288, -0.2055185513, 178.2194569111],
      "speed factor": 0.4404718079,
      "Uz factor": 0.9918898735,
      "Directional variance factor": 0.9817316843,
      "Velocity stability factor": 0.4158693309,
      "Direction stability factor": 0.0726918791,
      score: 0.0,
    },
    id: "IDN:322.0",
    "45": {
      velocity: [0.0551446555, 0.4698715305, 0.9354347232],
      direction: [-117.0144012265, -2.8072713765, 171.8952063012],
      "speed factor": 0.6382648133,
      "Uz factor": 0.9990717099,
      "Directional variance factor": 0.7504647665,
      "Velocity stability factor": 0.2843520301,
      "Direction stability factor": 0.1974733124,
      score: 0.0,
    },
    "90": {
      velocity: [0.2079495752, 0.7612957446, 1.1675706171],
      direction: [-51.7118740731, 10.4758400257, 56.5914766871],
      "speed factor": 1.2340826799,
      "Uz factor": 0.9853779033,
      "Directional variance factor": 0.0688142199,
      "Velocity stability factor": 0.299613355,
      "Direction stability factor": 0.699157359,
      score: 0.4416855732,
    },
    "135": {
      velocity: [0.6562833925, 1.2281584269, 1.4221817672],
      direction: [6.7964358559, 14.8085974413, 35.0818973765],
      "speed factor": 1.0394522836,
      "Uz factor": 0.9020656533,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3828308107,
      "Direction stability factor": 0.9214292736,
      score: 0.0,
    },
    "180": {
      velocity: [0.3170425707, 0.768260792, 1.1301282007],
      direction: [-31.6766840017, 13.9341021406, 54.9426806513],
      "speed factor": 1.5004085808,
      "Uz factor": 0.8470316314,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3593235834,
      "Direction stability factor": 0.7593906537,
      score: 0.0,
    },
    "225": {
      velocity: [0.1081319766, 0.4837246504, 0.7976882603],
      direction: [-79.2265361862, -6.6377001713, 61.0281676302],
      "speed factor": 0.6603370844,
      "Uz factor": 0.9477967418,
      "Directional variance factor": 0.409982207,
      "Velocity stability factor": 0.3802401828,
      "Direction stability factor": 0.6104036005,
      score: 0.0,
    },
    "270": {
      velocity: [0.0990356358, 0.5484422055, 1.0160570077],
      direction: [-111.9915454043, -18.3038356741, 35.9419500958],
      "speed factor": 0.501842327,
      "Uz factor": 0.9949156256,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2766506035,
      "Direction stability factor": 0.5890736236,
      score: 0.0,
    },
    "315": {
      velocity: [0.063172293, 0.2608576281, 0.6249979368],
      direction: [-179.1716946323, -79.6067449537, 116.8300937326],
      "speed factor": 0.3214025579,
      "Uz factor": 0.9609546364,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5442268296,
      "Direction stability factor": 0.1777728101,
      score: 0.0,
    },
  },
  {
    position: [-50.04879157, 56.82216473, 45.87999908],
    "overall score": 0.0524763455,
    "0": {
      velocity: [0.2074143733, 0.9497358397, 1.3903898699],
      direction: [-62.3016207668, -30.3186474786, 37.2506889265],
      "speed factor": 0.9732427982,
      "Uz factor": 0.9476785465,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1780321972,
      "Direction stability factor": 0.7234658064,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1266431425, 0.5428220988, 1.0323117595],
      direction: [-142.6486234852, 1.9990225748, 166.6812250389],
      "speed factor": 0.4819585244,
      "Uz factor": 0.9639494476,
      "Directional variance factor": 0.8223091045,
      "Velocity stability factor": 0.4143281511,
      "Direction stability factor": 0.1407504208,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1862762489, 0.5537581628, 0.8985896512],
      direction: [-60.9145122815, 31.8194975389, 71.6487630391],
      "speed factor": 0.8578083105,
      "Uz factor": 0.9332930559,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.363509135,
      "Direction stability factor": 0.6317686797,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5213129501, 0.9484036345, 1.2493360253],
      direction: [-26.0780084838, 2.6230154118, 21.6798012998],
      "speed factor": 1.0668878326,
      "Uz factor": 0.9264262376,
      "Directional variance factor": 0.7668430745,
      "Velocity stability factor": 0.3973286444,
      "Direction stability factor": 0.8673394173,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2442682754, 0.635124909, 1.167379575],
      direction: [-100.4760040738, -14.0648746259, 72.6424694654],
      "speed factor": 0.90228429,
      "Uz factor": 0.9879798901,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2450874767,
      "Direction stability factor": 0.5191153513,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1063341291, 0.5277629686, 1.0090420502],
      direction: [-114.3727641085, -9.3416734933, 94.1632928744],
      "speed factor": 0.6183563471,
      "Uz factor": 0.9991882108,
      "Directional variance factor": 0.1696290228,
      "Velocity stability factor": 0.3059059888,
      "Direction stability factor": 0.420733175,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0314536753, 0.4306788528, 1.0620119436],
      direction: [-109.0834979256, -12.8227806244, 101.0200356166],
      "speed factor": 0.7989611715,
      "Uz factor": 0.9996759308,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.4163790735,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0473496573, 1.0102535344, 1.410974157],
      direction: [-147.1574137257, -5.8565625062, 153.8852743585],
      "speed factor": 1.3757134343,
      "Uz factor": 0.9743818097,
      "Directional variance factor": 0.4794166661,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.1637703109,
      score: 0.201739322,
    },
    "337.5": {
      velocity: [0.6174351664, 0.9381361187, 1.196502362],
      direction: [-30.5671537557, -16.2447172334, 8.2900985851],
      "speed factor": 1.1913746461,
      "Uz factor": 0.9126171333,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.533599319,
      "Direction stability factor": 0.8920631879,
      score: 0.0,
    },
    id: "IDN:382.0",
    "45": {
      velocity: [0.0565655384, 0.4855789468, 0.9099289884],
      direction: [-118.8275213591, 28.7663890764, 98.0136148911],
      "speed factor": 0.6596014778,
      "Uz factor": 0.9731378423,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3062425183,
      "Direction stability factor": 0.3976635104,
      score: 0.0,
    },
    "90": {
      velocity: [0.6458893466, 1.0710870276, 1.3853826894],
      direction: [-7.9309068654, 17.6196654193, 42.2956050239],
      "speed factor": 1.7362634151,
      "Uz factor": 0.9225131473,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4602752142,
      "Direction stability factor": 0.8604819114,
      score: 0.0,
    },
    "135": {
      velocity: [0.1638383262, 0.7469232505, 1.1263965566],
      direction: [-99.1696414762, -20.8303355058, 178.9188745246],
      "speed factor": 0.6321587357,
      "Uz factor": 0.8714076857,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2243601731,
      "Direction stability factor": 0.2275319,
      score: 0.0,
    },
    "180": {
      velocity: [0.086932887, 0.434479992, 0.7591286057],
      direction: [-90.0755211728, -41.3378436621, 132.0270789516],
      "speed factor": 0.8485367404,
      "Uz factor": 0.9805116129,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4703387583,
      "Direction stability factor": 0.383048333,
      score: 0.0,
    },
    "225": {
      velocity: [0.0456361182, 0.2805184961, 0.7545150557],
      direction: [-128.5678862644, -10.0825948974, 116.1758258841],
      "speed factor": 0.3829384459,
      "Uz factor": 0.9943362085,
      "Directional variance factor": 0.1037693425,
      "Velocity stability factor": 0.3628733562,
      "Direction stability factor": 0.3201563551,
      score: 0.0,
    },
    "270": {
      velocity: [0.0716148537, 0.5318111044, 1.2793562127],
      direction: [-168.0323032716, -0.8055090486, 30.6870298255],
      "speed factor": 0.4866243325,
      "Uz factor": 0.970760193,
      "Directional variance factor": 0.9283991957,
      "Velocity stability factor": 0.0473297461,
      "Direction stability factor": 0.4480018525,
      score: 0.0,
    },
    "315": {
      velocity: [0.7692861602, 1.0859638862, 1.3388900056],
      direction: [-20.4241971554, -9.4546216612, 5.8517054667],
      "speed factor": 1.3380155808,
      "Uz factor": 0.9585949718,
      "Directional variance factor": 0.1595891857,
      "Velocity stability factor": 0.5379168728,
      "Direction stability factor": 0.9270113816,
      score: 0.6378822054,
    },
  },
  {
    position: [12.4859298, 93.58910899, 45.87999908],
    "overall score": 0.050589702,
    "0": {
      velocity: [0.0726731347, 0.3114688604, 0.7990822394],
      direction: [-179.3943952952, -114.8113189311, 175.1232347149],
      "speed factor": 0.3191780415,
      "Uz factor": 0.9984465151,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4952685855,
      "Direction stability factor": 0.0152288055,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0894734067, 0.7411191399, 1.3239399208],
      direction: [-163.9018169395, -13.9198855166, 169.5788426663],
      "speed factor": 0.658021639,
      "Uz factor": 0.9998007803,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2017032807,
      "Direction stability factor": 0.0736648344,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1474597011, 0.4444066997, 0.8391642649],
      direction: [-47.8433051464, -11.4748155837, 75.4646281789],
      "speed factor": 0.6884156043,
      "Uz factor": 0.9636018269,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.381924256,
      "Direction stability factor": 0.657477963,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5419284377, 0.9115971153, 1.1630362955],
      direction: [-47.5300774053, -1.3659169125, 51.5341869728],
      "speed factor": 1.0254830697,
      "Uz factor": 0.9474761511,
      "Directional variance factor": 0.8785851633,
      "Velocity stability factor": 0.4858350959,
      "Direction stability factor": 0.7248214878,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5796162579, 0.9155170312, 1.1571557852],
      direction: [-23.4317325103, 21.8973244756, 70.1749183284],
      "speed factor": 1.3006207484,
      "Uz factor": 0.950558175,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5276931156,
      "Direction stability factor": 0.7399815254,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1632452964, 0.8290744635, 1.162546756],
      direction: [-164.5783104377, -0.1954465976, 177.6083183761],
      "speed factor": 0.9713895959,
      "Uz factor": 0.9156505868,
      "Directional variance factor": 0.9826269691,
      "Velocity stability factor": 0.2316350147,
      "Direction stability factor": 0.0494815866,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1114367667, 0.4771000749, 0.8737360671],
      direction: [-43.6308028637, 1.0560943211, 126.0737209397],
      "speed factor": 0.8850781325,
      "Uz factor": 0.9729878511,
      "Directional variance factor": 0.9061249492,
      "Velocity stability factor": 0.2603045128,
      "Direction stability factor": 0.528598545,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0182013145, 0.2673123011, 0.8281038978],
      direction: [-175.1774774287, -18.1471854854, 123.5651614279],
      "speed factor": 0.3640127069,
      "Uz factor": 0.99132156,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4060662716,
      "Direction stability factor": 0.1701593365,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0924508065, 0.4533019137, 0.9204283731],
      direction: [-154.2327877532, -1.0351271338, 88.6313745003],
      "speed factor": 0.5756652965,
      "Uz factor": 0.9997525242,
      "Directional variance factor": 0.9079886992,
      "Velocity stability factor": 0.3331183256,
      "Direction stability factor": 0.3253773271,
      score: 0.0,
    },
    id: "IDN:323.0",
    "45": {
      velocity: [0.1992879356, 0.6508339098, 1.0407597362],
      direction: [-108.8119670078, -8.3952355514, 30.5101398305],
      "speed factor": 0.8840807689,
      "Uz factor": 0.9806467016,
      "Directional variance factor": 0.2537568399,
      "Velocity stability factor": 0.3159100529,
      "Direction stability factor": 0.6129941477,
      score: 0.0,
    },
    "90": {
      velocity: [0.5367099889, 0.8596001201, 1.1053089058],
      direction: [-23.7769269153, 0.1926279313, 35.6483424941],
      "speed factor": 1.3934369493,
      "Uz factor": 0.9557378122,
      "Directional variance factor": 0.9828775172,
      "Velocity stability factor": 0.585003798,
      "Direction stability factor": 0.8349298072,
      score: 0.8094352324,
    },
    "135": {
      velocity: [0.6953261843, 1.1312049836, 1.3007177917],
      direction: [-7.2759827036, 12.0370259168, 36.8264448765],
      "speed factor": 0.9573957053,
      "Uz factor": 0.9482417392,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5121688987,
      "Direction stability factor": 0.8774932567,
      score: 0.0,
    },
    "180": {
      velocity: [0.442259658, 0.7843624664, 1.081370883],
      direction: [-21.8018284428, 23.5835808003, 66.7244728495],
      "speed factor": 1.5318550515,
      "Uz factor": 0.932256589,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4964079128,
      "Direction stability factor": 0.7540936075,
      score: 0.0,
    },
    "225": {
      velocity: [0.155215233, 0.4947547565, 0.8015122376],
      direction: [-65.5742372157, 0.2254976544, 66.7846309693],
      "speed factor": 0.6753943863,
      "Uz factor": 0.9712272958,
      "Directional variance factor": 0.9799557641,
      "Velocity stability factor": 0.419120784,
      "Direction stability factor": 0.6323364773,
      score: 0.0,
    },
    "270": {
      velocity: [0.1067495629, 0.5718579821, 1.1300367199],
      direction: [-65.8396961793, -9.5834943321, 124.0154072576],
      "speed factor": 0.5232685187,
      "Uz factor": 0.9995618413,
      "Directional variance factor": 0.1481338371,
      "Velocity stability factor": 0.1928278117,
      "Direction stability factor": 0.4726247127,
      score: 0.0,
    },
    "315": {
      velocity: [0.0300338641, 0.290884038, 0.7231943286],
      direction: [-171.7167944354, -57.1378356463, 119.9621914244],
      "speed factor": 0.358398083,
      "Uz factor": 0.9887478717,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4376832992,
      "Direction stability factor": 0.1897805948,
      score: 0.0,
    },
  },
  {
    position: [-46.153284, 82.7039409, 46.87999908],
    "overall score": 0.0504777732,
    "0": {
      velocity: [0.0691730134, 0.4056696562, 0.8857483674],
      direction: [-94.9548874592, 54.2170192722, 176.506147864],
      "speed factor": 0.4157104058,
      "Uz factor": 0.9861493874,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.432618299,
      "Direction stability factor": 0.2459415685,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1465113024, 0.7453732318, 1.1890604537],
      direction: [-47.8262032517, 29.412703145, 116.2170752102],
      "speed factor": 0.6617987436,
      "Uz factor": 0.9943367893,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3258111438,
      "Direction stability factor": 0.5443242265,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1154889528, 0.4985339029, 0.9588782094],
      direction: [-57.867046211, 6.1716611159, 69.2785830958],
      "speed factor": 0.7722622504,
      "Uz factor": 0.9692063443,
      "Directional variance factor": 0.4514079008,
      "Velocity stability factor": 0.2463857121,
      "Direction stability factor": 0.6468176964,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2849286368, 0.810469651, 1.1349252793],
      direction: [-25.73258234, 0.3378380896, 29.9498162246],
      "speed factor": 0.9117217372,
      "Uz factor": 0.9341976577,
      "Directional variance factor": 0.9699699476,
      "Velocity stability factor": 0.2963566042,
      "Direction stability factor": 0.8453266707,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4669160344, 0.8315730777, 1.1611875837],
      direction: [-53.8963656904, -16.924098841, 25.485549123],
      "speed factor": 1.1813665522,
      "Uz factor": 0.9478398283,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4322306666,
      "Direction stability factor": 0.7794946811,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3672368386, 0.6860293433, 1.0842405224],
      direction: [-74.5953956563, -22.5501381513, 14.5431462992],
      "speed factor": 0.8037900042,
      "Uz factor": 0.9911763849,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4486943658,
      "Direction stability factor": 0.752392939,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0879744566, 0.4462837092, 0.7927863675],
      direction: [-46.4697433362, -10.0702796128, 103.1828323836],
      "speed factor": 0.8279100606,
      "Uz factor": 0.9999849013,
      "Directional variance factor": 0.1048640344,
      "Velocity stability factor": 0.3160872776,
      "Direction stability factor": 0.5842984008,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0229701405, 0.3225617278, 0.8780379803],
      direction: [-147.9653223103, -6.0025395502, 175.26308021],
      "speed factor": 0.4392486511,
      "Uz factor": 0.9785159965,
      "Directional variance factor": 0.4664409289,
      "Velocity stability factor": 0.3729447953,
      "Direction stability factor": 0.1021433263,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0400025846, 0.2632641034, 0.7521537129],
      direction: [-174.6120964747, 36.6277362715, 176.9096524022],
      "speed factor": 0.3343290721,
      "Uz factor": 0.9999979194,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4264089318,
      "Direction stability factor": 0.0235506976,
      score: 0.0,
    },
    id: "IDN:447.0",
    "45": {
      velocity: [0.0904001583, 0.4013223392, 0.7521060824],
      direction: [-28.9705621851, 15.1511088879, 89.6456746082],
      "speed factor": 0.5451488572,
      "Uz factor": 0.9692685774,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4620540222,
      "Direction stability factor": 0.6705104534,
      score: 0.0,
    },
    "90": {
      velocity: [0.3834263333, 0.8980409323, 1.2481429723],
      direction: [-4.8122462801, 9.0670596278, 37.0983928852],
      "speed factor": 1.4557506307,
      "Uz factor": 0.9404587069,
      "Directional variance factor": 0.1940391442,
      "Velocity stability factor": 0.3688800482,
      "Direction stability factor": 0.8835815579,
      score: 0.0,
    },
    "135": {
      velocity: [0.4136813097, 0.9713963504, 1.2243694561],
      direction: [-66.206448531, -20.6465459393, 8.3728507144],
      "speed factor": 0.8221416167,
      "Uz factor": 0.904384914,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3467387284,
      "Direction stability factor": 0.7928352799,
      score: 0.0,
    },
    "180": {
      velocity: [0.474933714, 0.8310479745, 1.272446936],
      direction: [-53.2705351371, -32.9015583497, -15.7458577956],
      "speed factor": 1.6230315602,
      "Uz factor": 0.9554562295,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3715939694,
      "Direction stability factor": 0.8957647852,
      score: 0.0,
    },
    "225": {
      velocity: [0.479361885, 0.7662147922, 1.0438256224],
      direction: [-26.0644980547, 0.1484042339, 18.7378562406],
      "speed factor": 1.0459670425,
      "Uz factor": 0.9995913597,
      "Directional variance factor": 0.9868085125,
      "Velocity stability factor": 0.4926709378,
      "Direction stability factor": 0.8755490158,
      score: 0.8076443705,
    },
    "270": {
      velocity: [0.0569602188, 0.3703960225, 1.0021541942],
      direction: [-22.2310665473, 13.3098940475, 115.7393876501],
      "speed factor": 0.3389243205,
      "Uz factor": 0.983546164,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2544279635,
      "Direction stability factor": 0.6167487383,
      score: 0.0,
    },
    "315": {
      velocity: [0.1279425395, 0.6662720601, 0.997733012],
      direction: [-11.8265199439, 2.8466359674, 19.6207089319],
      "speed factor": 0.8209134842,
      "Uz factor": 0.9997747957,
      "Directional variance factor": 0.7469656918,
      "Velocity stability factor": 0.2943946835,
      "Direction stability factor": 0.9126465865,
      score: 0.0,
    },
  },
  {
    position: [-45.69008694, 84.25610757, 46.87999908],
    "overall score": 0.0503921396,
    "0": {
      velocity: [0.1216897368, 0.3931258446, 0.8425837074],
      direction: [-178.670669168, 67.9770701837, 162.3143322166],
      "speed factor": 0.4028561218,
      "Uz factor": 0.9676704555,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4991006705,
      "Direction stability factor": 0.0528194406,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.304369228, 0.7374252906, 1.3065365971],
      direction: [-21.4354006938, 29.6979023594, 97.1213722282],
      "speed factor": 0.6547419601,
      "Uz factor": 0.9947866624,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3519249702,
      "Direction stability factor": 0.6706756308,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1180427991, 0.4990639703, 1.0023998541],
      direction: [-32.0598339571, 8.5975879498, 68.2497830765],
      "speed factor": 0.7730833601,
      "Uz factor": 0.9757888522,
      "Directional variance factor": 0.23576996,
      "Velocity stability factor": 0.2097787503,
      "Direction stability factor": 0.7213621749,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3108799612, 0.7888991562, 1.1287695373],
      direction: [-27.2255818907, 5.7283054707, 32.6213514939],
      "speed factor": 0.8874564375,
      "Uz factor": 0.9136324615,
      "Directional variance factor": 0.4908172915,
      "Velocity stability factor": 0.3229354448,
      "Direction stability factor": 0.8337585184,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4686044501, 0.8250240544, 1.2049713424],
      direction: [-51.4082439599, -16.3586518021, 19.402918358],
      "speed factor": 1.1720627433,
      "Uz factor": 0.9321996412,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3978054553,
      "Direction stability factor": 0.8033023269,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3151205231, 0.6827994737, 1.1077764354],
      direction: [-77.1953980428, -28.2681405452, 9.7153840965],
      "speed factor": 0.80000571,
      "Uz factor": 0.986244505,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3905252088,
      "Direction stability factor": 0.7585811607,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0698531623, 0.4108131162, 0.750525044],
      direction: [-65.6074249872, -9.7193390331, 49.1391128531],
      "speed factor": 0.7621078361,
      "Uz factor": 0.9994636033,
      "Directional variance factor": 0.1360587526,
      "Velocity stability factor": 0.3395115031,
      "Direction stability factor": 0.6812596171,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.026254181, 0.2803968907, 0.8633278809],
      direction: [-147.6072248503, -9.9856015142, 168.1122885074],
      "speed factor": 0.3818306557,
      "Uz factor": 0.9764648325,
      "Directional variance factor": 0.1123909765,
      "Velocity stability factor": 0.3861406127,
      "Direction stability factor": 0.1230013518,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0167078322, 0.2062954899, 0.4446137264],
      direction: [-172.2604123715, 89.7199330485, 176.6541323586],
      "speed factor": 0.2619824686,
      "Uz factor": 0.9975795194,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6553498419,
      "Direction stability factor": 0.0307929313,
      score: 0.0,
    },
    id: "IDN:448.0",
    "45": {
      velocity: [0.0785827855, 0.4249949741, 0.8163771936],
      direction: [-34.5206535251, 16.5017653122, 78.0411671471],
      "speed factor": 0.5773053276,
      "Uz factor": 0.9867598146,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4001964924,
      "Direction stability factor": 0.6873282759,
      score: 0.0,
    },
    "90": {
      velocity: [0.3402333464, 0.8600249036, 1.1994939924],
      direction: [-2.3413866273, 15.3914851075, 43.1102241891],
      "speed factor": 1.3941255357,
      "Uz factor": 0.9226629975,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3728621458,
      "Direction stability factor": 0.8737455255,
      score: 0.0,
    },
    "135": {
      velocity: [0.4656653743, 0.983855119, 1.2504691202],
      direction: [-61.8574094649, -16.4331536578, 9.3074508462],
      "speed factor": 0.8326860995,
      "Uz factor": 0.8841545792,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3675966582,
      "Direction stability factor": 0.8023198325,
      score: 0.0,
    },
    "180": {
      velocity: [0.4948445578, 0.8477151624, 1.2805489188],
      direction: [-57.256998412, -32.9657775213, -16.0124352424],
      "speed factor": 1.6555824753,
      "Uz factor": 0.9354211473,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3808988427,
      "Direction stability factor": 0.885431769,
      score: 0.0,
    },
    "225": {
      velocity: [0.5017852759, 0.7623487053, 1.0463452835],
      direction: [-29.3123158567, 0.0606681857, 21.1003493898],
      "speed factor": 1.0406894108,
      "Uz factor": 0.9999836159,
      "Directional variance factor": 0.9946072724,
      "Velocity stability factor": 0.5105600242,
      "Direction stability factor": 0.8599648188,
      score: 0.8062742335,
    },
    "270": {
      velocity: [0.0384641463, 0.2378498203, 0.9320063503],
      direction: [-23.9133213273, 17.9794984141, 170.6112287162],
      "speed factor": 0.2176402657,
      "Uz factor": 0.9715036022,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2951710463,
      "Direction stability factor": 0.4596540277,
      score: 0.0,
    },
    "315": {
      velocity: [0.1477494739, 0.704269876, 1.0212429867],
      direction: [-16.0519092087, 2.4463127295, 26.9818045112],
      "speed factor": 0.8677305748,
      "Uz factor": 0.9999232406,
      "Directional variance factor": 0.7825499796,
      "Velocity stability factor": 0.2913906441,
      "Direction stability factor": 0.8804619063,
      score: 0.0,
    },
  },
  {
    position: [-35.61091946, 97.1184151, 45.87999908],
    "overall score": 0.0501603034,
    "0": {
      velocity: [0.5202402671, 1.0159154472, 1.2756359997],
      direction: [-69.6694222125, -37.9044623412, 24.6401828608],
      "speed factor": 1.0410604204,
      "Uz factor": 0.9294458879,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4751277838,
      "Direction stability factor": 0.7380288748,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.758684627, 1.2213070076, 1.46841197],
      direction: [-29.878261062, -0.878919538, 15.5992016011],
      "speed factor": 1.0843687547,
      "Uz factor": 0.9508027869,
      "Directional variance factor": 0.9218738188,
      "Velocity stability factor": 0.5410381707,
      "Direction stability factor": 0.8736737148,
      score: 0.8025648548,
    },
    "67.5": {
      velocity: [0.303594748, 0.6373512149, 1.0215188138],
      direction: [-38.9382809212, -1.734142204, 51.3165538589],
      "speed factor": 0.9872995209,
      "Uz factor": 0.9613174729,
      "Directional variance factor": 0.8458540263,
      "Velocity stability factor": 0.3584957012,
      "Direction stability factor": 0.7492921256,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1122361844, 0.7450133027, 1.2446454167],
      direction: [-133.5439715302, 33.7461980325, 147.8913177436],
      "speed factor": 0.8380879182,
      "Uz factor": 0.9925113349,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0625700882,
      "Direction stability factor": 0.2182353076,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1118991023, 0.53265436, 1.0391373968],
      direction: [-172.8013546201, 7.4761449896, 170.8543927907],
      "speed factor": 0.7567104584,
      "Uz factor": 0.985963754,
      "Directional variance factor": 0.3354537787,
      "Velocity stability factor": 0.2417124555,
      "Direction stability factor": 0.0454007016,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1288693117, 0.4430397682, 0.784743679],
      direction: [-162.9646397532, 29.0227536415, 167.4535809234],
      "speed factor": 0.5190899495,
      "Uz factor": 0.4848873587,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4956968253,
      "Direction stability factor": 0.0821716092,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1042568188, 0.3348910486, 0.7326685763],
      direction: [-95.2134376068, -14.4389545182, 55.5311706016],
      "speed factor": 0.6212632517,
      "Uz factor": 0.9773736464,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3902220021,
      "Direction stability factor": 0.5812649772,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2204859707, 0.617682286, 1.0776300717],
      direction: [-102.2671585338, -3.8107848296, 50.5789066083],
      "speed factor": 0.841129271,
      "Uz factor": 0.9799765902,
      "Directional variance factor": 0.6612635707,
      "Velocity stability factor": 0.3714221905,
      "Direction stability factor": 0.5754275968,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7715384339, 1.0263296504, 1.3991273245],
      direction: [-36.6676055264, -21.8883440482, 2.3908203093],
      "speed factor": 1.3033749576,
      "Uz factor": 0.9175104331,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4945182731,
      "Direction stability factor": 0.8915043727,
      score: 0.0,
    },
    id: "IDN:332.0",
    "45": {
      velocity: [0.3623159733, 0.6189675208, 1.0072907844],
      direction: [-71.3871330386, -21.039573679, 34.0984027616],
      "speed factor": 0.8407940544,
      "Uz factor": 0.9339955156,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4756558876,
      "Direction stability factor": 0.7069846228,
      score: 0.0,
    },
    "90": {
      velocity: [0.2850875907, 0.7218263351, 1.2008683737],
      direction: [-15.7180514436, 37.3167865423, 73.3470953277],
      "speed factor": 1.1701016121,
      "Uz factor": 0.9871822039,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3316104982,
      "Direction stability factor": 0.7525968145,
      score: 0.0,
    },
    "135": {
      velocity: [0.1052952075, 1.0716065989, 1.3047250533],
      direction: [-4.1998107731, 14.0652977823, 69.9468295525],
      "speed factor": 0.906954593,
      "Uz factor": 0.972832472,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.033486465,
      "Direction stability factor": 0.7940371102,
      score: 0.0,
    },
    "180": {
      velocity: [0.0467261898, 0.437833762, 1.0641750059],
      direction: [-106.6566098117, 5.8085746486, 141.7608688286],
      "speed factor": 0.8550866324,
      "Uz factor": 0.9636647392,
      "Directional variance factor": 0.4836822535,
      "Velocity stability factor": 0.1982942048,
      "Direction stability factor": 0.3099514482,
      score: 0.0,
    },
    "225": {
      velocity: [0.1512450594, 0.5926140692, 0.967157709],
      direction: [-22.2624477919, 5.610139082, 90.5857974833],
      "speed factor": 0.8089830575,
      "Uz factor": 0.9770906758,
      "Directional variance factor": 0.5013209705,
      "Velocity stability factor": 0.2666735311,
      "Direction stability factor": 0.686532652,
      score: 0.0,
    },
    "270": {
      velocity: [0.1609860971, 0.7402715723, 1.3211835989],
      direction: [-93.833606155, -6.1453257292, 69.5019513311],
      "speed factor": 0.6773723918,
      "Uz factor": 0.9999984341,
      "Directional variance factor": 0.4537488241,
      "Velocity stability factor": 0.084832493,
      "Direction stability factor": 0.5462901181,
      score: 0.0,
    },
    "315": {
      velocity: [0.7772590901, 0.9189348996, 1.1039348066],
      direction: [-15.959722256, -6.7442707665, 3.0332651747],
      "speed factor": 1.132219247,
      "Uz factor": 0.922732113,
      "Directional variance factor": 0.4005092652,
      "Velocity stability factor": 0.7349889086,
      "Direction stability factor": 0.9472417016,
      score: 0.0,
    },
  },
  {
    position: [-35.48357818, 96.68256738, 45.87999908],
    "overall score": 0.0499827044,
    "0": {
      velocity: [0.4608729791, 0.9488902105, 1.176005673],
      direction: [-74.4893252793, -39.101778281, 25.5869068673],
      "speed factor": 0.9723762389,
      "Uz factor": 0.9510672453,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.503103783,
      "Direction stability factor": 0.7220104663,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7986370286, 1.2556083426, 1.5010567158],
      direction: [-30.9485235813, -0.9185642258, 16.7911843873],
      "speed factor": 1.1148240748,
      "Uz factor": 0.9587208725,
      "Directional variance factor": 0.9183498466,
      "Velocity stability factor": 0.5457638377,
      "Direction stability factor": 0.8673897001,
      score: 0.7997232711,
    },
    "67.5": {
      velocity: [0.2797131057, 0.6414326029, 1.0304438857],
      direction: [-37.1900620995, -0.5212351203, 49.1536777477],
      "speed factor": 0.993621863,
      "Uz factor": 0.9479343846,
      "Directional variance factor": 0.9536679893,
      "Velocity stability factor": 0.3291811133,
      "Direction stability factor": 0.7601562782,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1507340329, 0.7352360509, 1.2177874326],
      direction: [-169.8391777884, 33.4657512373, 178.0964300628],
      "speed factor": 0.827089193,
      "Uz factor": 0.9894126332,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1166728901,
      "Direction stability factor": 0.0335122004,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0465736259, 0.5513102681, 1.0427176762],
      direction: [-125.7803352011, 8.1828377068, 171.1442524154],
      "speed factor": 0.7832138006,
      "Uz factor": 0.9857108599,
      "Directional variance factor": 0.2726366483,
      "Velocity stability factor": 0.185361918,
      "Direction stability factor": 0.1752094788,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1792692648, 0.5076081385, 0.9248045145],
      direction: [-161.4260386653, 24.0604629011, 136.0300706239],
      "speed factor": 0.5947418311,
      "Uz factor": 0.4940189254,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.426756385,
      "Direction stability factor": 0.1737330298,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1039679598, 0.3343254088, 0.7047412811],
      direction: [-109.5344676655, -9.3694042237, 87.8281436068],
      "speed factor": 0.6202139216,
      "Uz factor": 0.9816630207,
      "Directional variance factor": 0.167164069,
      "Velocity stability factor": 0.4170408993,
      "Direction stability factor": 0.4517705242,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0857470515, 0.5481933893, 0.9966819581],
      direction: [-124.1407462217, -4.4882465467, 55.9991965975],
      "speed factor": 0.7465027189,
      "Uz factor": 0.9878596915,
      "Directional variance factor": 0.6010447514,
      "Velocity stability factor": 0.3319752566,
      "Direction stability factor": 0.4996112699,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.5144206264, 0.8186378103, 1.2390426933],
      direction: [-39.0904504562, -21.7637129059, 3.0844385294],
      "speed factor": 1.0396192109,
      "Uz factor": 0.9265467657,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4163644079,
      "Direction stability factor": 0.8828475306,
      score: 0.0,
    },
    id: "IDN:331.0",
    "45": {
      velocity: [0.3536162298, 0.6251006468, 1.0228252047],
      direction: [-76.2203932851, -20.2468250682, 34.1813483441],
      "speed factor": 0.8491251794,
      "Uz factor": 0.9473741673,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4559542793,
      "Direction stability factor": 0.6933284955,
      score: 0.0,
    },
    "90": {
      velocity: [0.3110364579, 0.7123215224, 1.1231242022],
      direction: [-13.5299257964, 36.9865245883, 69.9376697073],
      "speed factor": 1.1546940326,
      "Uz factor": 0.9792745688,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4072916435,
      "Direction stability factor": 0.768145568,
      score: 0.0,
    },
    "135": {
      velocity: [0.152135165, 1.0753810744, 1.3093336104],
      direction: [-5.6826294172, 13.2789664872, 69.1866971106],
      "speed factor": 0.9101491216,
      "Uz factor": 0.9677952645,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.067516984,
      "Direction stability factor": 0.7920296485,
      score: 0.0,
    },
    "180": {
      velocity: [0.0407571641, 0.4542211764, 1.0651605205],
      direction: [-101.6773676287, 4.510370537, 164.7484665998],
      "speed factor": 0.8870911516,
      "Uz factor": 0.9643411858,
      "Directional variance factor": 0.5990781745,
      "Velocity stability factor": 0.1928143269,
      "Direction stability factor": 0.2599282383,
      score: 0.0,
    },
    "225": {
      velocity: [0.1147396959, 0.5547498473, 0.8836422316],
      direction: [-23.8778602549, 12.3036213101, 108.0127592375],
      "speed factor": 0.7572942508,
      "Uz factor": 0.976933843,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.30892531,
      "Direction stability factor": 0.6336371681,
      score: 0.0,
    },
    "270": {
      velocity: [0.0966592779, 0.6974928393, 1.313127004],
      direction: [-99.1108254058, -6.5263109642, 61.7504705751],
      "speed factor": 0.6382284697,
      "Uz factor": 0.9995995243,
      "Directional variance factor": 0.4198834698,
      "Velocity stability factor": 0.0404463597,
      "Direction stability factor": 0.5531630667,
      score: 0.0,
    },
    "315": {
      velocity: [0.4765216984, 0.7013587507, 0.9216127974],
      direction: [-17.0559488035, -7.6563750755, 0.0966099658],
      "speed factor": 0.8641437787,
      "Uz factor": 0.9299868059,
      "Directional variance factor": 0.3194333266,
      "Velocity stability factor": 0.6389260912,
      "Direction stability factor": 0.9523540034,
      score: 0.0,
    },
  },
  {
    position: [-35.48417584, 96.51135055, 45.87999908],
    "overall score": 0.0495860057,
    "0": {
      velocity: [0.3712819686, 0.8887254235, 1.1847114118],
      direction: [-78.6563486322, -39.260779243, 26.5791705778],
      "speed factor": 0.9107223102,
      "Uz factor": 0.9587731392,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4348041746,
      "Direction stability factor": 0.7076791133,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.8115247548, 1.2726468908, 1.5199956496],
      direction: [-32.0173661157, -1.0825963951, 16.9634289342],
      "speed factor": 1.1299521869,
      "Uz factor": 0.9586051858,
      "Directional variance factor": 0.9037692093,
      "Velocity stability factor": 0.5418506824,
      "Direction stability factor": 0.863942236,
      score: 0.7933760909,
    },
    "67.5": {
      velocity: [0.2687066214, 0.642926799, 1.034072537],
      direction: [-36.2712736392, -0.5434404464, 46.8963712024],
      "speed factor": 0.9959364725,
      "Uz factor": 0.9424254134,
      "Directional variance factor": 0.9516941825,
      "Velocity stability factor": 0.3161038216,
      "Direction stability factor": 0.7689787643,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1532614033, 0.7355918392, 1.222124843],
      direction: [-172.3994590349, 33.4438388482, 149.011458267],
      "speed factor": 0.8274894301,
      "Uz factor": 0.9888077747,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1151745046,
      "Direction stability factor": 0.1071918964,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0183496123, 0.5536587807, 1.0452011844],
      direction: [-120.1228968686, 8.4809969641, 156.9716323802],
      "speed factor": 0.7865501932,
      "Uz factor": 0.9868209933,
      "Directional variance factor": 0.2461336032,
      "Velocity stability factor": 0.1602495694,
      "Direction stability factor": 0.2302929743,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1186120957, 0.5480460564, 0.9957503817],
      direction: [-161.2043873377, 30.1100823773, 160.9262128362],
      "speed factor": 0.6421211371,
      "Uz factor": 0.5004041564,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3255665348,
      "Direction stability factor": 0.1051927773,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1095076967, 0.3334787273, 0.7044845852],
      direction: [-125.3036821807, -7.9000819395, 112.7297915538],
      "speed factor": 0.6186432255,
      "Uz factor": 0.9843822832,
      "Directional variance factor": 0.2977704943,
      "Velocity stability factor": 0.4226654554,
      "Direction stability factor": 0.3387959063,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1084139309, 0.5233184296, 1.0017295617],
      direction: [-162.7574726774, -4.7513624944, 58.4800784322],
      "speed factor": 0.7126292257,
      "Uz factor": 0.9904394287,
      "Directional variance factor": 0.5776566672,
      "Velocity stability factor": 0.3448961712,
      "Direction stability factor": 0.3854512469,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3946019461, 0.6975437008, 1.1622307727],
      direction: [-41.4595403886, -21.5191206869, 3.3786150004],
      "speed factor": 0.8858372075,
      "Uz factor": 0.9236231787,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3817252812,
      "Direction stability factor": 0.8754495684,
      score: 0.0,
    },
    id: "IDN:330.0",
    "45": {
      velocity: [0.3411643031, 0.6255740069, 1.0172217003],
      direction: [-81.0283415101, -19.9641072948, 33.5474171131],
      "speed factor": 0.849768183,
      "Uz factor": 0.9516830555,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4503867287,
      "Direction stability factor": 0.6817340038,
      score: 0.0,
    },
    "90": {
      velocity: [0.3208043291, 0.7122111541, 1.1067924624],
      direction: [-13.8883989859, 36.7046622577, 69.2288840389],
      "speed factor": 1.1545151223,
      "Uz factor": 0.9764807322,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4263406411,
      "Direction stability factor": 0.7691186583,
      score: 0.0,
    },
    "135": {
      velocity: [0.1636206763, 1.0817677842, 1.320479824],
      direction: [-6.301414432, 13.0489298711, 69.5043132594],
      "speed factor": 0.9155545155,
      "Uz factor": 0.966456356,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0677903936,
      "Direction stability factor": 0.7894285342,
      score: 0.0,
    },
    "180": {
      velocity: [0.0475936868, 0.4500327075, 1.0580407496],
      direction: [-167.8171629108, 5.2468480897, 148.951551268],
      "speed factor": 0.8789110975,
      "Uz factor": 0.96502783,
      "Directional variance factor": 0.5336135031,
      "Velocity stability factor": 0.2038112844,
      "Direction stability factor": 0.1200869051,
      score: 0.0,
    },
    "225": {
      velocity: [0.1026504892, 0.5533506708, 0.8793276929],
      direction: [-24.6761950356, 14.461775378, 109.707654317],
      "speed factor": 0.7553842218,
      "Uz factor": 0.97738591,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3019375892,
      "Direction stability factor": 0.6267115296,
      score: 0.0,
    },
    "270": {
      velocity: [0.1284457596, 0.6727804965, 1.3061049211],
      direction: [-100.674998177, -6.4442392016, 39.4876392609],
      "speed factor": 0.6156158781,
      "Uz factor": 0.9993016247,
      "Directional variance factor": 0.4271787376,
      "Velocity stability factor": 0.0710586799,
      "Direction stability factor": 0.6106593405,
      score: 0.0,
    },
    "315": {
      velocity: [0.3430302795, 0.5952213263, 0.8226426725],
      direction: [-21.1668864107, -7.8906210351, 1.9998430633],
      "speed factor": 0.7333719093,
      "Uz factor": 0.9339072029,
      "Directional variance factor": 0.2986114635,
      "Velocity stability factor": 0.6109211757,
      "Direction stability factor": 0.9356479737,
      score: 0.0,
    },
  },
  {
    position: [-35.50511975, 90.5113871, 46.87999908],
    "overall score": 0.0474305693,
    "0": {
      velocity: [0.1092389251, 0.556920182, 1.121379565],
      direction: [-155.9558516573, -22.8853655032, 36.0510224978],
      "speed factor": 0.5707045409,
      "Uz factor": 0.9985523682,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2967335162,
      "Direction stability factor": 0.4666475718,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4654635124, 1.2757673721, 1.5259266146],
      direction: [-32.3156714117, -0.3124739306, 12.8453876857],
      "speed factor": 1.1327227862,
      "Uz factor": 0.9902995272,
      "Directional variance factor": 0.9722245395,
      "Velocity stability factor": 0.3142266673,
      "Direction stability factor": 0.8745526136,
      score: 0.7588891085,
    },
    "67.5": {
      velocity: [0.2123049484, 0.6209535998, 0.9727783908],
      direction: [-39.4447741382, -10.7587341855, 54.9963287017],
      "speed factor": 0.9618985221,
      "Uz factor": 0.9845646727,
      "Directional variance factor": 0.0436680724,
      "Velocity stability factor": 0.3204755132,
      "Direction stability factor": 0.7376636032,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1637448035, 0.7374234075, 1.1381333185],
      direction: [-9.1156683082, 29.2484518488, 99.5405835318],
      "speed factor": 0.8295498164,
      "Uz factor": 0.948698682,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.193382645,
      "Direction stability factor": 0.6981770782,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4262015628, 0.8182221052, 1.3823055464],
      direction: [-33.4808610339, 14.5108814613, 61.608960428],
      "speed factor": 1.1623996175,
      "Uz factor": 0.9543429801,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.218106342,
      "Direction stability factor": 0.7358616071,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3972296356, 0.6179564084, 1.0175336643],
      direction: [-36.5915684364, -9.8725943395, 50.1109341354],
      "speed factor": 0.7240319805,
      "Uz factor": 0.7702126353,
      "Directional variance factor": 0.1224360587,
      "Velocity stability factor": 0.5230469332,
      "Direction stability factor": 0.7591597151,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1444867319, 0.3914920174, 0.6804388422],
      direction: [-100.2505454111, -12.3060938455, 54.7749635496],
      "speed factor": 0.7262648695,
      "Uz factor": 0.9912383696,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4799400221,
      "Direction stability factor": 0.5693735862,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0917500779, 0.5386129525, 1.0516127021],
      direction: [-163.3259925204, -8.6013998474, 94.507092667],
      "speed factor": 0.7334565526,
      "Uz factor": 0.9873092996,
      "Directional variance factor": 0.2354311247,
      "Velocity stability factor": 0.2960946182,
      "Direction stability factor": 0.2837969856,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0391467232, 0.1909212834, 0.8107596797],
      direction: [-174.858546233, -72.2063799443, 177.4965608774],
      "speed factor": 0.2424581805,
      "Uz factor": 0.9989427671,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3785163256,
      "Direction stability factor": 0.0212358136,
      score: 0.0,
    },
    id: "IDN:418.0",
    "45": {
      velocity: [0.2657602087, 0.5856078011, 0.915491946],
      direction: [-47.8565457427, -9.6763175105, 67.3047855976],
      "speed factor": 0.7954788269,
      "Uz factor": 0.9864354397,
      "Directional variance factor": 0.139882888,
      "Velocity stability factor": 0.4717886571,
      "Direction stability factor": 0.6801074129,
      score: 0.0,
    },
    "90": {
      velocity: [0.283595797, 0.7368378571, 1.0943393602],
      direction: [-40.4832612783, 26.5069096381, 89.6183985413],
      "speed factor": 1.1944357285,
      "Uz factor": 0.9749759867,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4082727042,
      "Direction stability factor": 0.6386065005,
      score: 0.0,
    },
    "135": {
      velocity: [0.5811843004, 1.0133115209, 1.2363640489],
      direction: [-25.0529210391, 6.9027331993, 42.2291886323],
      "speed factor": 0.8576165348,
      "Uz factor": 0.9635678282,
      "Directional variance factor": 0.3864237156,
      "Velocity stability factor": 0.4720490765,
      "Direction stability factor": 0.8131052509,
      score: 0.0,
    },
    "180": {
      velocity: [0.5791094369, 0.904966061, 1.1652248651],
      direction: [-22.7576889242, 0.6183914794, 26.3034698586],
      "speed factor": 1.7673931266,
      "Uz factor": 0.948252997,
      "Directional variance factor": 0.9450318685,
      "Velocity stability factor": 0.5381663155,
      "Direction stability factor": 0.8637190034,
      score: 0.0,
    },
    "225": {
      velocity: [0.0852198773, 0.5937471296, 0.9419027007],
      direction: [-44.6651443935, 1.0645291364, 57.8610678802],
      "speed factor": 0.8105298089,
      "Uz factor": 0.987236745,
      "Directional variance factor": 0.9053751879,
      "Velocity stability factor": 0.2300300895,
      "Direction stability factor": 0.7152049659,
      score: 0.0,
    },
    "270": {
      velocity: [0.0921725606, 0.3396029938, 0.831903754],
      direction: [-120.7322167022, -58.8577085623, 40.6370699042],
      "speed factor": 0.310747705,
      "Uz factor": 0.9937585973,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4164976643,
      "Direction stability factor": 0.5517519816,
      score: 0.0,
    },
    "315": {
      velocity: [0.0187022926, 0.1791165056, 0.6974976376],
      direction: [-179.8610864721, -29.2247695339, 179.2703457588],
      "speed factor": 0.2206893602,
      "Uz factor": 0.9951973767,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4493368008,
      "Direction stability factor": 0.0024126882,
      score: 0.0,
    },
  },
  {
    position: [-35.50511975, 90.5113871, 46.87999908],
    "overall score": 0.0474305693,
    "0": {
      velocity: [0.1092389251, 0.556920182, 1.121379565],
      direction: [-155.9558516573, -22.8853655032, 36.0510224978],
      "speed factor": 0.5707045409,
      "Uz factor": 0.9985523682,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2967335162,
      "Direction stability factor": 0.4666475718,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4654635124, 1.2757673721, 1.5259266146],
      direction: [-32.3156714117, -0.3124739306, 12.8453876857],
      "speed factor": 1.1327227862,
      "Uz factor": 0.9902995272,
      "Directional variance factor": 0.9722245395,
      "Velocity stability factor": 0.3142266673,
      "Direction stability factor": 0.8745526136,
      score: 0.7588891085,
    },
    "67.5": {
      velocity: [0.2123049484, 0.6209535998, 0.9727783908],
      direction: [-39.4447741382, -10.7587341855, 54.9963287017],
      "speed factor": 0.9618985221,
      "Uz factor": 0.9845646727,
      "Directional variance factor": 0.0436680724,
      "Velocity stability factor": 0.3204755132,
      "Direction stability factor": 0.7376636032,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1637448035, 0.7374234075, 1.1381333185],
      direction: [-9.1156683082, 29.2484518488, 99.5405835318],
      "speed factor": 0.8295498164,
      "Uz factor": 0.948698682,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.193382645,
      "Direction stability factor": 0.6981770782,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4262015628, 0.8182221052, 1.3823055464],
      direction: [-33.4808610339, 14.5108814613, 61.608960428],
      "speed factor": 1.1623996175,
      "Uz factor": 0.9543429801,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.218106342,
      "Direction stability factor": 0.7358616071,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3972296356, 0.6179564084, 1.0175336643],
      direction: [-36.5915684364, -9.8725943395, 50.1109341354],
      "speed factor": 0.7240319805,
      "Uz factor": 0.7702126353,
      "Directional variance factor": 0.1224360587,
      "Velocity stability factor": 0.5230469332,
      "Direction stability factor": 0.7591597151,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1444867319, 0.3914920174, 0.6804388422],
      direction: [-100.2505454111, -12.3060938455, 54.7749635496],
      "speed factor": 0.7262648695,
      "Uz factor": 0.9912383696,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4799400221,
      "Direction stability factor": 0.5693735862,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0917500779, 0.5386129525, 1.0516127021],
      direction: [-163.3259925204, -8.6013998474, 94.507092667],
      "speed factor": 0.7334565526,
      "Uz factor": 0.9873092996,
      "Directional variance factor": 0.2354311247,
      "Velocity stability factor": 0.2960946182,
      "Direction stability factor": 0.2837969856,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0391467232, 0.1909212834, 0.8107596797],
      direction: [-174.858546233, -72.2063799443, 177.4965608774],
      "speed factor": 0.2424581805,
      "Uz factor": 0.9989427671,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3785163256,
      "Direction stability factor": 0.0212358136,
      score: 0.0,
    },
    id: "IDN:459.0",
    "45": {
      velocity: [0.2657602087, 0.5856078011, 0.915491946],
      direction: [-47.8565457427, -9.6763175105, 67.3047855976],
      "speed factor": 0.7954788269,
      "Uz factor": 0.9864354397,
      "Directional variance factor": 0.139882888,
      "Velocity stability factor": 0.4717886571,
      "Direction stability factor": 0.6801074129,
      score: 0.0,
    },
    "90": {
      velocity: [0.283595797, 0.7368378571, 1.0943393602],
      direction: [-40.4832612783, 26.5069096381, 89.6183985413],
      "speed factor": 1.1944357285,
      "Uz factor": 0.9749759867,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4082727042,
      "Direction stability factor": 0.6386065005,
      score: 0.0,
    },
    "135": {
      velocity: [0.5811843004, 1.0133115209, 1.2363640489],
      direction: [-25.0529210391, 6.9027331993, 42.2291886323],
      "speed factor": 0.8576165348,
      "Uz factor": 0.9635678282,
      "Directional variance factor": 0.3864237156,
      "Velocity stability factor": 0.4720490765,
      "Direction stability factor": 0.8131052509,
      score: 0.0,
    },
    "180": {
      velocity: [0.5791094369, 0.904966061, 1.1652248651],
      direction: [-22.7576889242, 0.6183914794, 26.3034698586],
      "speed factor": 1.7673931266,
      "Uz factor": 0.948252997,
      "Directional variance factor": 0.9450318685,
      "Velocity stability factor": 0.5381663155,
      "Direction stability factor": 0.8637190034,
      score: 0.0,
    },
    "225": {
      velocity: [0.0852198773, 0.5937471296, 0.9419027007],
      direction: [-44.6651443935, 1.0645291364, 57.8610678802],
      "speed factor": 0.8105298089,
      "Uz factor": 0.987236745,
      "Directional variance factor": 0.9053751879,
      "Velocity stability factor": 0.2300300895,
      "Direction stability factor": 0.7152049659,
      score: 0.0,
    },
    "270": {
      velocity: [0.0921725606, 0.3396029938, 0.831903754],
      direction: [-120.7322167022, -58.8577085623, 40.6370699042],
      "speed factor": 0.310747705,
      "Uz factor": 0.9937585973,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4164976643,
      "Direction stability factor": 0.5517519816,
      score: 0.0,
    },
    "315": {
      velocity: [0.0187022926, 0.1791165056, 0.6974976376],
      direction: [-179.8610864721, -29.2247695339, 179.2703457588],
      "speed factor": 0.2206893602,
      "Uz factor": 0.9951973767,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4493368008,
      "Direction stability factor": 0.0024126882,
      score: 0.0,
    },
  },
  {
    position: [-37.47491052, 89.78847435, 47.87999908],
    "overall score": 0.0472917284,
    "0": {
      velocity: [0.1293239906, 0.6041502976, 1.0884407285],
      direction: [-170.0775091217, -22.4594918198, 176.041933376],
      "speed factor": 0.6191036513,
      "Uz factor": 0.9895710046,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3335761561,
      "Direction stability factor": 0.0385571042,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7253177319, 1.2615636196, 1.5364592901],
      direction: [-38.4813324375, 1.5722160983, 17.1446308501],
      "speed factor": 1.1201116203,
      "Uz factor": 0.996397193,
      "Directional variance factor": 0.8602474579,
      "Velocity stability factor": 0.4754562903,
      "Direction stability factor": 0.8454834353,
      score: 0.7566676547,
    },
    "67.5": {
      velocity: [0.2415433663, 0.6440969582, 1.0082074326],
      direction: [-35.6604551546, -7.6590859807, 55.8133435809],
      "speed factor": 0.997749127,
      "Uz factor": 0.9924969246,
      "Directional variance factor": 0.3191923573,
      "Velocity stability factor": 0.3149438533,
      "Direction stability factor": 0.7459061146,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2113766955, 0.7444812223, 1.1539028489],
      direction: [-6.0157919517, 24.4513394044, 109.3031734946],
      "speed factor": 0.837489365,
      "Uz factor": 0.9341120281,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2197589143,
      "Direction stability factor": 0.6796695404,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4469406235, 0.8238648064, 1.1849977302],
      direction: [-33.9171604258, 8.1652834188, 50.6186483847],
      "speed factor": 1.1704158685,
      "Uz factor": 0.9429310557,
      "Directional variance factor": 0.2741970294,
      "Velocity stability factor": 0.3964232125,
      "Direction stability factor": 0.7651783089,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3599904909, 0.6560728154, 1.0717501509],
      direction: [-56.2940659192, -22.9298613234, 18.6947313481],
      "speed factor": 0.7686912757,
      "Uz factor": 0.8070879452,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4527265066,
      "Direction stability factor": 0.7916977854,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1221900253, 0.4335015895, 0.698408567],
      direction: [-100.3080113266, -13.0355883692, 52.2969663596],
      "speed factor": 0.8041976882,
      "Uz factor": 0.9964642414,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4408675769,
      "Direction stability factor": 0.5760972842,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.100337362, 0.5655616003, 0.9546103787],
      direction: [-148.9348310907, -12.1807017705, 118.3964052714],
      "speed factor": 0.7701538919,
      "Uz factor": 0.9968628162,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3735276706,
      "Direction stability factor": 0.2574132323,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0108886018, 0.2446059437, 0.6101130156],
      direction: [-151.4081660946, -18.8300953121, 176.7954107326],
      "speed factor": 0.3106343672,
      "Uz factor": 0.9426315062,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5173640006,
      "Direction stability factor": 0.0883233977,
      score: 0.0,
    },
    id: "IDN:721.0",
    "45": {
      velocity: [0.1517519677, 0.5826336599, 0.9528838778],
      direction: [-55.8438503481, -7.9190521441, 51.5277021001],
      "speed factor": 0.7914388084,
      "Uz factor": 0.9955109264,
      "Directional variance factor": 0.2960842539,
      "Velocity stability factor": 0.3487051075,
      "Direction stability factor": 0.7017456876,
      score: 0.0,
    },
    "90": {
      velocity: [0.2388015918, 0.7588556218, 1.1663520745],
      direction: [-32.1090225419, 23.823626782, 153.6547653996],
      "speed factor": 1.2301271694,
      "Uz factor": 0.9706965106,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3230202942,
      "Direction stability factor": 0.4839894779,
      score: 0.0,
    },
    "135": {
      velocity: [0.6082389774, 1.022279598, 1.2525121714],
      direction: [-27.1409182125, 5.3284300785, 29.6104314869],
      "speed factor": 0.8652066698,
      "Uz factor": 0.9551985399,
      "Directional variance factor": 0.5263617708,
      "Velocity stability factor": 0.480837696,
      "Direction stability factor": 0.8423573619,
      score: 0.0,
    },
    "180": {
      velocity: [0.6052813919, 0.9001610537, 1.1670264248],
      direction: [-20.4592787921, -4.4628652738, 17.4091279143],
      "speed factor": 1.7580089771,
      "Uz factor": 0.9297898042,
      "Directional variance factor": 0.6033008646,
      "Velocity stability factor": 0.5573691362,
      "Direction stability factor": 0.8948099814,
      score: 0.0,
    },
    "225": {
      velocity: [0.2989998045, 0.6911925152, 1.1070181519],
      direction: [-20.4426920138, -1.1807121166, 15.6100070636],
      "speed factor": 0.9435534243,
      "Uz factor": 0.9968192982,
      "Directional variance factor": 0.8950478119,
      "Velocity stability factor": 0.273768777,
      "Direction stability factor": 0.8998536137,
      score: 0.0,
    },
    "270": {
      velocity: [0.1053525554, 0.3575509374, 0.965041578],
      direction: [-136.532017123, -49.2170413777, 3.3666315504],
      "speed factor": 0.3271706529,
      "Uz factor": 0.9832768216,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3218745444,
      "Direction stability factor": 0.6113926426,
      score: 0.0,
    },
    "315": {
      velocity: [0.0591082443, 0.4484452622, 0.9867383852],
      direction: [-51.8241625178, 6.1343372027, 20.3273384433],
      "speed factor": 0.5525291915,
      "Uz factor": 0.9887858646,
      "Directional variance factor": 0.454725582,
      "Velocity stability factor": 0.2474730641,
      "Direction stability factor": 0.799579164,
      score: 0.0,
    },
  },
  {
    position: [-44.96703638, 85.67180679, 46.87999908],
    "overall score": 0.0472603399,
    "0": {
      velocity: [0.0824837185, 0.3681971393, 0.9455732469],
      direction: [-20.119614904, 81.1537943856, 173.9678816989],
      "speed factor": 0.3773104049,
      "Uz factor": 0.9653313281,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4002988182,
      "Direction stability factor": 0.460868065,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2592984082, 0.7241244555, 1.3592151275],
      direction: [-24.9136439748, 26.5948147903, 95.024854447],
      "speed factor": 0.6429324725,
      "Uz factor": 0.9931730161,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2887130607,
      "Direction stability factor": 0.6668375044,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1777591641, 0.500585912, 1.0093083147],
      direction: [-34.4291454138, 9.5110059599, 66.8912923517],
      "speed factor": 0.7754409494,
      "Uz factor": 0.983494444,
      "Directional variance factor": 0.154577248,
      "Velocity stability factor": 0.2569654924,
      "Direction stability factor": 0.7185543395,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2529337705, 0.7645555807, 1.114383982],
      direction: [-31.0991154117, 11.7331615224, 45.3699064323],
      "speed factor": 0.8600716157,
      "Uz factor": 0.8945144022,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2868751218,
      "Direction stability factor": 0.7875860504,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4555126899, 0.8175422131, 1.2704832722],
      direction: [-46.3182975239, -14.361651073, 20.6077820271],
      "speed factor": 1.1614337351,
      "Uz factor": 0.9141078989,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3335240301,
      "Direction stability factor": 0.8140942235,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2649120725, 0.6802231621, 1.0948078135],
      direction: [-88.7544100855, -34.0956245441, 1.8502957632],
      "speed factor": 0.7969871605,
      "Uz factor": 0.9686386575,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3618914266,
      "Direction stability factor": 0.7483202615,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0794151636, 0.3947736139, 0.6696866558],
      direction: [-91.2062694349, -10.8429019842, 27.2705617053],
      "speed factor": 0.7323526266,
      "Uz factor": 0.9995834048,
      "Directional variance factor": 0.0361864903,
      "Velocity stability factor": 0.4272313266,
      "Direction stability factor": 0.6708976913,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0372350064, 0.2531887553, 0.8164665205],
      direction: [-176.7770302036, -18.9470419815, 176.6945628936],
      "speed factor": 0.3447799588,
      "Uz factor": 0.9738764365,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4285585846,
      "Direction stability factor": 0.0181344636,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0352092251, 0.1841697012, 0.3598663658],
      direction: [-176.1332892126, 135.0630481308, 178.6754376956],
      "speed factor": 0.2338840902,
      "Uz factor": 0.9930017511,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7385099472,
      "Direction stability factor": 0.014420203,
      score: 0.0,
    },
    id: "IDN:449.0",
    "45": {
      velocity: [0.0914899121, 0.4445623294, 0.8021626975],
      direction: [-43.1674545995, 14.6288394294, 83.1396637586],
      "speed factor": 0.6038852618,
      "Uz factor": 0.996165068,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4222455134,
      "Direction stability factor": 0.6491468934,
      score: 0.0,
    },
    "90": {
      velocity: [0.3138088893, 0.827779609, 1.1480390075],
      direction: [-4.7459413219, 20.668251506, 49.0413495536],
      "speed factor": 1.3418549697,
      "Uz factor": 0.9180572292,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3911308651,
      "Direction stability factor": 0.8505908587,
      score: 0.0,
    },
    "135": {
      velocity: [0.3770266792, 0.9900044386, 1.2541966129],
      direction: [-57.5422669851, -11.2762999615, 10.2098750168],
      "speed factor": 0.8378905782,
      "Uz factor": 0.8769585604,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2931669857,
      "Direction stability factor": 0.8117996056,
      score: 0.0,
    },
    "180": {
      velocity: [0.5384999903, 0.8621711887, 1.271716102],
      direction: [-54.7829167356, -30.9544759736, -14.0881744202],
      "speed factor": 1.6838150054,
      "Uz factor": 0.9177658258,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4222573199,
      "Direction stability factor": 0.8869590491,
      score: 0.0,
    },
    "225": {
      velocity: [0.3679704052, 0.7469331789, 1.0305280333],
      direction: [-35.2256277919, -0.5748971502, 23.9479913016],
      "speed factor": 1.0196455302,
      "Uz factor": 0.9999499781,
      "Directional variance factor": 0.9488980311,
      "Velocity stability factor": 0.4045060509,
      "Direction stability factor": 0.8356288359,
      score: 0.7561654384,
    },
    "270": {
      velocity: [0.0225353769, 0.1767701988, 0.9378015439],
      direction: [-178.9195355882, -14.408323748, 169.1414590313],
      "speed factor": 0.1617504397,
      "Uz factor": 0.7436812549,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2780351147,
      "Direction stability factor": 0.0331639038,
      score: 0.0,
    },
    "315": {
      velocity: [0.1823621185, 0.6951219333, 1.0488446425],
      direction: [-22.2198075849, 2.6393370498, 49.5836170309],
      "speed factor": 0.856459399,
      "Uz factor": 0.9990546096,
      "Directional variance factor": 0.7653922622,
      "Velocity stability factor": 0.2970782103,
      "Direction stability factor": 0.8005460427,
      score: 0.0,
    },
  },
  {
    position: [-35.63762623, 90.14194684, 46.87999908],
    "overall score": 0.0469981029,
    "0": {
      velocity: [0.1451950335, 0.5362558271, 1.1004732335],
      direction: [-169.0096515917, -22.7225449619, 36.2610255434],
      "speed factor": 0.5495287215,
      "Uz factor": 0.9982815072,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3362432904,
      "Direction stability factor": 0.4298036746,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4457400331, 1.2739474162, 1.5330934602],
      direction: [-36.4097189764, -0.1662164619, 12.9434052251],
      "speed factor": 1.1311068917,
      "Uz factor": 0.9911361295,
      "Directional variance factor": 0.9852252034,
      "Velocity stability factor": 0.2968374082,
      "Direction stability factor": 0.8629079883,
      score: 0.7519696471,
    },
    "67.5": {
      velocity: [0.2185804399, 0.6215921803, 0.9760010182],
      direction: [-39.7095462359, -10.8300143034, 54.4831399816],
      "speed factor": 0.9628877258,
      "Uz factor": 0.9863864831,
      "Directional variance factor": 0.0373320619,
      "Velocity stability factor": 0.3232034137,
      "Direction stability factor": 0.7383536494,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1706656017, 0.7293707494, 1.1296636837],
      direction: [-9.0557062528, 28.5467860827, 101.4290817662],
      "speed factor": 0.8204911386,
      "Uz factor": 0.9417331582,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2061231383,
      "Direction stability factor": 0.6930978111,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4238628364, 0.8085415598, 1.263127587],
      direction: [-33.9490027128, 13.649107851, 60.915023095],
      "speed factor": 1.1486470408,
      "Uz factor": 0.9504614388,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3136564672,
      "Direction stability factor": 0.7364888172,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3952202969, 0.6237526386, 1.0231846412],
      direction: [-32.7483233723, -11.4482909079, 51.9254659035],
      "speed factor": 0.7308231652,
      "Uz factor": 0.7799502376,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5171569005,
      "Direction stability factor": 0.7647950298,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1103943379, 0.3971709632, 0.6865742545],
      direction: [-99.37574446, -12.2606966077, 66.8061675787],
      "speed factor": 0.7367999983,
      "Uz factor": 0.9904909902,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4409050567,
      "Direction stability factor": 0.5383835777,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0579066279, 0.5220687027, 0.9949697449],
      direction: [-152.3052570978, -8.8073642361, 58.7561629072],
      "speed factor": 0.7109274093,
      "Uz factor": 0.9893130601,
      "Directional variance factor": 0.217123179,
      "Velocity stability factor": 0.3128144022,
      "Direction stability factor": 0.4137182778,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0444847861, 0.1730132244, 0.7198429505],
      direction: [-175.9307772853, -93.7571636346, 176.2492753522],
      "speed factor": 0.2197160571,
      "Uz factor": 0.9973909952,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4560432532,
      "Direction stability factor": 0.021722076,
      score: 0.0,
    },
    id: "IDN:458.0",
    "45": {
      velocity: [0.2574984592, 0.5812375945, 0.9198152457],
      direction: [-46.5377663293, -9.2859268164, 54.2618587587],
      "speed factor": 0.789542419,
      "Uz factor": 0.9873803257,
      "Directional variance factor": 0.174584283,
      "Velocity stability factor": 0.4615574103,
      "Direction stability factor": 0.7200010414,
      score: 0.0,
    },
    "90": {
      velocity: [0.2802568264, 0.7364530884, 1.1078363705],
      direction: [-39.9004748198, 26.0584239602, 103.4824145251],
      "speed factor": 1.1938120073,
      "Uz factor": 0.9720418187,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3959848366,
      "Direction stability factor": 0.6017141963,
      score: 0.0,
    },
    "135": {
      velocity: [0.5734726523, 1.0038204731, 1.2332397434],
      direction: [-26.2833468817, 6.6511801403, 41.0119512457],
      "speed factor": 0.8495837835,
      "Uz factor": 0.9604848032,
      "Directional variance factor": 0.4087839875,
      "Velocity stability factor": 0.4683525462,
      "Direction stability factor": 0.8130686163,
      score: 0.0,
    },
    "180": {
      velocity: [0.5808001738, 0.9003928766, 1.1668220445],
      direction: [-22.5604111892, 0.4496545497, 25.9684274523],
      "speed factor": 1.7584617257,
      "Uz factor": 0.943895228,
      "Directional variance factor": 0.9600307067,
      "Velocity stability factor": 0.5382400349,
      "Direction stability factor": 0.8651976704,
      score: 0.0,
    },
    "225": {
      velocity: [0.1409863801, 0.594682748, 0.9167981914],
      direction: [-44.8311579646, 1.2468084719, 43.2395869056],
      "speed factor": 0.8118070304,
      "Uz factor": 0.9893911601,
      "Directional variance factor": 0.8891725803,
      "Velocity stability factor": 0.302715387,
      "Direction stability factor": 0.755359042,
      score: 0.0,
    },
    "270": {
      velocity: [0.0778042868, 0.3305686097, 0.8126698164],
      direction: [-124.5074665809, -60.6652771779, 47.019521005],
      "speed factor": 0.3024809518,
      "Uz factor": 0.9788394567,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4203357156,
      "Direction stability factor": 0.5235361456,
      score: 0.0,
    },
    "315": {
      velocity: [0.0214509324, 0.1736626849, 0.7125732347],
      direction: [-178.4860468298, -31.8937235447, 171.2293213491],
      "speed factor": 0.2139697104,
      "Uz factor": 0.9962284877,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4393367297,
      "Direction stability factor": 0.0285684217,
      score: 0.0,
    },
  },
  {
    position: [-46.71443165, 79.6549658, 46.87999908],
    "overall score": 0.0466973207,
    "0": {
      velocity: [0.0805430644, 0.4331129547, 1.1344605334],
      direction: [-152.0816108002, 14.6444927949, 165.4441037278],
      "speed factor": 0.4438329547,
      "Uz factor": 0.9999886401,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2677056889,
      "Direction stability factor": 0.1179841263,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1733260816, 0.7253953183, 1.2852133889],
      direction: [-56.66642757, 27.2937158442, 115.238484766],
      "speed factor": 0.6440608406,
      "Uz factor": 0.9933040559,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2809719992,
      "Direction stability factor": 0.5224863546,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1521035764, 0.5028817595, 0.9741966236],
      direction: [-100.5483477828, -1.3531862319, 53.7637603569],
      "speed factor": 0.7789973701,
      "Uz factor": 0.9700829835,
      "Directional variance factor": 0.8797167794,
      "Velocity stability factor": 0.2654150363,
      "Direction stability factor": 0.5713552552,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2457641789, 0.8351695596, 1.1639180242],
      direction: [-32.8116387369, -4.1238423797, 33.5340661638],
      "speed factor": 0.9395074089,
      "Uz factor": 0.9633226376,
      "Directional variance factor": 0.6334362329,
      "Velocity stability factor": 0.2399347747,
      "Direction stability factor": 0.8157063753,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4275755572, 0.8202286326, 1.0713747937],
      direction: [-52.8735920271, -14.3921210265, 15.1048520246],
      "speed factor": 1.1652501719,
      "Uz factor": 0.9639655012,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4735064923,
      "Direction stability factor": 0.8111709887,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2842034448, 0.6717134453, 1.0670557185],
      direction: [-67.7657550539, -14.9530083986, 26.3202099689],
      "speed factor": 0.7870167046,
      "Uz factor": 0.982337727,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3980632471,
      "Direction stability factor": 0.7386500972,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.284786956, 0.4975002259, 0.8126704399],
      direction: [-32.1856139874, -6.8221545535, 31.2697094575],
      "speed factor": 0.9229228709,
      "Uz factor": 0.9930295747,
      "Directional variance factor": 0.3935862619,
      "Velocity stability factor": 0.4877693963,
      "Direction stability factor": 0.8237352127,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0389478296, 0.5177216613, 1.0728501447],
      direction: [-73.9713626764, -7.0394659695, 121.5476834494],
      "speed factor": 0.7050078226,
      "Uz factor": 0.9892012618,
      "Directional variance factor": 0.3742696916,
      "Velocity stability factor": 0.2417983724,
      "Direction stability factor": 0.4568915385,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0520079452, 0.4226020367, 1.0330681196],
      direction: [-179.8029478024, 9.2590986699, 162.4987712805],
      "speed factor": 0.5366783581,
      "Uz factor": 0.9992268698,
      "Directional variance factor": 0.1769690071,
      "Velocity stability factor": 0.2098203162,
      "Direction stability factor": 0.0491618914,
      score: 0.0,
    },
    id: "IDN:443.0",
    "45": {
      velocity: [0.1447773351, 0.4033086061, 0.8247041186],
      direction: [-76.7758227845, 7.3469273966, 77.5658776936],
      "speed factor": 0.5478469655,
      "Uz factor": 0.9596157779,
      "Directional variance factor": 0.346939787,
      "Velocity stability factor": 0.44724104,
      "Direction stability factor": 0.5712730542,
      score: 0.0,
    },
    "90": {
      velocity: [0.5468545268, 0.981419948, 1.2783416615],
      direction: [-15.2150871147, 2.9825813675, 23.0118258706],
      "speed factor": 1.5909104552,
      "Uz factor": 0.9787888855,
      "Directional variance factor": 0.7348816562,
      "Velocity stability factor": 0.4661186055,
      "Direction stability factor": 0.8938141306,
      score: 0.7471571307,
    },
    "135": {
      velocity: [0.4073052525, 0.9559413503, 1.2187569301],
      direction: [-53.2393175993, -22.6614162543, 11.1253187142],
      "speed factor": 0.8090612723,
      "Uz factor": 0.9580886516,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3461234668,
      "Direction stability factor": 0.8212093436,
      score: 0.0,
    },
    "180": {
      velocity: [0.4597826453, 0.8010898176, 1.1955751437],
      direction: [-54.8543666578, -27.737946895, -11.5065241472],
      "speed factor": 1.5645234649,
      "Uz factor": 0.9744944027,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4202272382,
      "Direction stability factor": 0.8795893264,
      score: 0.0,
    },
    "225": {
      velocity: [0.5310013224, 0.7276202158, 1.0456288207],
      direction: [-20.1561043065, 2.6213862233, 23.4018060236],
      "speed factor": 0.9932812221,
      "Uz factor": 0.9930744292,
      "Directional variance factor": 0.7669878913,
      "Velocity stability factor": 0.5374627831,
      "Direction stability factor": 0.8790058046,
      score: 0.0,
    },
    "270": {
      velocity: [0.1773616213, 0.7203545164, 1.2439656314],
      direction: [-15.8590371606, 3.7478536261, 39.5368926035],
      "speed factor": 0.6591476425,
      "Uz factor": 0.9905368782,
      "Directional variance factor": 0.6668574555,
      "Velocity stability factor": 0.1586593391,
      "Direction stability factor": 0.8461224173,
      score: 0.0,
    },
    "315": {
      velocity: [0.2050154381, 0.6202260797, 1.0300815885],
      direction: [-11.3226621676, 2.9087137245, 96.83126012],
      "speed factor": 0.7641802538,
      "Uz factor": 0.9991015423,
      "Directional variance factor": 0.7414476689,
      "Velocity stability factor": 0.3306766622,
      "Direction stability factor": 0.6995724381,
      score: 0.0,
    },
  },
  {
    position: [-47.1184631, 79.54707423, 46.87999908],
    "overall score": 0.0465779175,
    "0": {
      velocity: [0.0430042691, 0.4593028993, 1.1474771027],
      direction: [-168.2588313283, 10.6580613307, 168.7541994981],
      "speed factor": 0.470671128,
      "Uz factor": 0.9989827122,
      "Directional variance factor": 0.0526167706,
      "Velocity stability factor": 0.2325782648,
      "Direction stability factor": 0.0638526921,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2160663988, 0.7149155212, 1.3019084707],
      direction: [-47.437584078, 28.5439650034, 120.099696781],
      "speed factor": 0.6347560839,
      "Uz factor": 0.9949420508,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2978147614,
      "Direction stability factor": 0.5346186643,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1553944353, 0.4999176092, 0.9970744269],
      direction: [-103.9401609263, -1.0668317579, 52.9041701481],
      "speed factor": 0.7744057038,
      "Uz factor": 0.9691552534,
      "Directional variance factor": 0.9051705104,
      "Velocity stability factor": 0.2479130335,
      "Direction stability factor": 0.5643213026,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2437280099, 0.8424254197, 1.1773561868],
      direction: [-31.4876762568, -4.0981064484, 32.6644787621],
      "speed factor": 0.9476697446,
      "Uz factor": 0.9657751882,
      "Directional variance factor": 0.6357238713,
      "Velocity stability factor": 0.2271248286,
      "Direction stability factor": 0.8217995694,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4303773435, 0.8292092614, 1.0851518433],
      direction: [-53.9921279644, -14.6791551909, 15.7914295442],
      "speed factor": 1.1780084186,
      "Uz factor": 0.9655436792,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4645310159,
      "Direction stability factor": 0.8061567847,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3234196306, 0.6945263432, 1.0689529923],
      direction: [-61.3516254018, -14.3847036693, 28.0237603639],
      "speed factor": 0.8137455602,
      "Uz factor": 0.9853113825,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4267578367,
      "Direction stability factor": 0.7517350395,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.285399658, 0.4954956936, 0.8107478301],
      direction: [-32.423458009, -6.5472264202, 34.528956607],
      "speed factor": 0.919204222,
      "Uz factor": 0.9920497184,
      "Directional variance factor": 0.4180243182,
      "Velocity stability factor": 0.4902295307,
      "Direction stability factor": 0.8140210705,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0417174639, 0.5567127099, 1.113775941],
      direction: [-56.9266479346, -6.878584011, 151.0359734987],
      "speed factor": 0.7581039095,
      "Uz factor": 0.98702963,
      "Directional variance factor": 0.3885703101,
      "Velocity stability factor": 0.2138169435,
      "Direction stability factor": 0.4223260516,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0413611207, 0.4634679322, 1.0870368685],
      direction: [-177.5263394953, 7.2659407132, 157.5936108287],
      "speed factor": 0.5885755091,
      "Uz factor": 0.9994691416,
      "Directional variance factor": 0.3541386033,
      "Velocity stability factor": 0.1577767059,
      "Direction stability factor": 0.0691112491,
      score: 0.0,
    },
    id: "IDN:442.0",
    "45": {
      velocity: [0.1484166436, 0.4025920886, 0.8351595049],
      direction: [-87.8214805241, 6.9961591956, 84.7601754821],
      "speed factor": 0.5468736614,
      "Uz factor": 0.9616246283,
      "Directional variance factor": 0.3781191826,
      "Velocity stability factor": 0.4416997845,
      "Direction stability factor": 0.5206065111,
      score: 0.0,
    },
    "90": {
      velocity: [0.562016036, 0.9870939773, 1.2947282667],
      direction: [-14.7764397333, 3.036831373, 23.7970517439],
      "speed factor": 1.6001082228,
      "Uz factor": 0.9804045757,
      "Directional variance factor": 0.7300594335,
      "Velocity stability factor": 0.4652244599,
      "Direction stability factor": 0.8928514126,
      score: 0.7452466796,
    },
    "135": {
      velocity: [0.4842067823, 0.9694921478, 1.2361907206],
      direction: [-80.1917980834, -23.1391573719, 9.8568518418],
      "speed factor": 0.8205299942,
      "Uz factor": 0.9627528713,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.394043214,
      "Direction stability factor": 0.7498648613,
      score: 0.0,
    },
    "180": {
      velocity: [0.4545460709, 0.8087511698, 1.1948583519],
      direction: [-54.8200146778, -27.8514039595, -11.3510174378],
      "speed factor": 1.5794860385,
      "Uz factor": 0.9771021728,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4166658444,
      "Direction stability factor": 0.8792527854,
      score: 0.0,
    },
    "225": {
      velocity: [0.5268053173, 0.7303309849, 1.0417914181],
      direction: [-19.3273089811, 2.677533197, 23.210021362],
      "speed factor": 0.9969817185,
      "Uz factor": 0.992513414,
      "Directional variance factor": 0.7619970492,
      "Velocity stability factor": 0.5371404782,
      "Direction stability factor": 0.881840749,
      score: 0.0,
    },
    "270": {
      velocity: [0.2113643274, 0.7723470779, 1.2574030475],
      direction: [-15.0572962692, 4.4177912053, 36.2234715142],
      "speed factor": 0.706722515,
      "Uz factor": 0.9889674908,
      "Directional variance factor": 0.6073074484,
      "Velocity stability factor": 0.1748813059,
      "Direction stability factor": 0.8575534228,
      score: 0.0,
    },
    "315": {
      velocity: [0.2162968837, 0.6442488246, 1.0562285191],
      direction: [-12.6489934741, 1.6320505595, 97.8372486974],
      "speed factor": 0.7937786663,
      "Uz factor": 0.9986181072,
      "Directional variance factor": 0.8549288392,
      "Velocity stability factor": 0.3186172461,
      "Direction stability factor": 0.6930937717,
      score: 0.0,
    },
  },
  {
    position: [-46.42617996, 79.94135678, 46.87999908],
    "overall score": 0.0462624212,
    "0": {
      velocity: [0.0974949645, 0.4148932592, 1.1121483945],
      direction: [-120.9857805092, 20.2562510818, 162.0276486347],
      "speed factor": 0.4251623027,
      "Uz factor": 0.9993987702,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2949875522,
      "Direction stability factor": 0.2138515857,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1436586914, 0.7334768236, 1.2871219887],
      direction: [-63.8411865789, 26.7294050983, 109.4529932984],
      "speed factor": 0.6512362124,
      "Uz factor": 0.9918686891,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2605526448,
      "Direction stability factor": 0.5186272781,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1421760685, 0.5028389149, 0.9676465588],
      direction: [-96.6873428393, -1.1050693855, 55.1554183612],
      "speed factor": 0.778931001,
      "Uz factor": 0.9691087281,
      "Directional variance factor": 0.9017716102,
      "Velocity stability factor": 0.2623971068,
      "Direction stability factor": 0.5782145522,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2502679318, 0.8283603949, 1.1533200422],
      direction: [-33.4033781502, -4.0572757214, 28.6157727076],
      "speed factor": 0.9318475743,
      "Uz factor": 0.9607920126,
      "Directional variance factor": 0.6393532692,
      "Velocity stability factor": 0.2524362782,
      "Direction stability factor": 0.827724581,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4266429338, 0.8158281523, 1.0637090502],
      direction: [-52.5110146434, -14.2463781951, 17.8888026587],
      "speed factor": 1.1589986706,
      "Uz factor": 0.9623501334,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4790127804,
      "Direction stability factor": 0.8044449519,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2620597378, 0.6605048566, 1.0708297886],
      direction: [-74.2462243814, -15.8157336444, 22.3798703495],
      "speed factor": 0.7738841009,
      "Uz factor": 0.9808610033,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.378135014,
      "Direction stability factor": 0.7315941813,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2804329661, 0.4965982248, 0.8106854566],
      direction: [-33.2666009942, -7.3977534164, 27.011368634],
      "speed factor": 0.9212495502,
      "Uz factor": 0.9939526123,
      "Directional variance factor": 0.3424219185,
      "Velocity stability factor": 0.4854706358,
      "Direction stability factor": 0.8325611955,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0390289538, 0.4748166917, 1.0420215776],
      direction: [-81.3652743285, -6.8233919537, 130.9324638397],
      "speed factor": 0.6465819513,
      "Uz factor": 0.9897551874,
      "Directional variance factor": 0.3934762708,
      "Velocity stability factor": 0.2644656765,
      "Direction stability factor": 0.4102840606,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0602651731, 0.3841350851, 0.9325703741],
      direction: [-173.7014648792, 11.7285128649, 174.6895175092],
      "speed factor": 0.487827717,
      "Uz factor": 0.9994161716,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2974153207,
      "Direction stability factor": 0.0322472711,
      score: 0.0,
    },
    id: "IDN:444.0",
    "45": {
      velocity: [0.135613238, 0.4018279495, 0.8067593621],
      direction: [-57.4223714536, 8.0713640038, 75.7057503306],
      "speed factor": 0.545835669,
      "Uz factor": 0.9572901692,
      "Directional variance factor": 0.2825454219,
      "Velocity stability factor": 0.4543794383,
      "Direction stability factor": 0.6301996617,
      score: 0.0,
    },
    "90": {
      velocity: [0.5218379581, 0.9703778371, 1.2615556161],
      direction: [-14.7718705073, 3.2740040886, 22.7210411543],
      "speed factor": 1.5730108703,
      "Uz factor": 0.9762253359,
      "Directional variance factor": 0.7089774144,
      "Velocity stability factor": 0.4601114959,
      "Direction stability factor": 0.8958530232,
      score: 0.7401987392,
    },
    "135": {
      velocity: [0.4332160869, 0.9492639294, 1.2101859723],
      direction: [-52.4375788694, -22.4102895529, 11.3136698614],
      "speed factor": 0.803409835,
      "Uz factor": 0.95224382,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3739092676,
      "Direction stability factor": 0.822913198,
      score: 0.0,
    },
    "180": {
      velocity: [0.465822914, 0.798909271, 1.2047545123],
      direction: [-54.9405111971, -28.1088598419, -11.926864924],
      "speed factor": 1.5602648709,
      "Uz factor": 0.9717116582,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4177537629,
      "Direction stability factor": 0.8805176492,
      score: 0.0,
    },
    "225": {
      velocity: [0.5244756359, 0.7290762401, 1.046565453],
      direction: [-21.2449409441, 2.2886669149, 22.9195661845],
      "speed factor": 0.9952688546,
      "Uz factor": 0.9938658494,
      "Directional variance factor": 0.7965629409,
      "Velocity stability factor": 0.5307557957,
      "Direction stability factor": 0.8773208135,
      score: 0.0,
    },
    "270": {
      velocity: [0.1540208134, 0.6565928691, 1.2490353053],
      direction: [-16.3973483497, 3.6650036027, 40.7064751329],
      "speed factor": 0.6008036764,
      "Uz factor": 0.9911653224,
      "Directional variance factor": 0.674221902,
      "Velocity stability factor": 0.1362490601,
      "Direction stability factor": 0.8413782681,
      score: 0.0,
    },
    "315": {
      velocity: [0.139439053, 0.6060850803, 1.0075207439],
      direction: [-9.7016273831, 3.9504087508, 90.4418977674],
      "speed factor": 0.7467571353,
      "Uz factor": 0.9992476681,
      "Directional variance factor": 0.6488525555,
      "Velocity stability factor": 0.2957809086,
      "Direction stability factor": 0.7218235412,
      score: 0.0,
    },
  },
  {
    position: [-35.94095872, 89.87253324, 46.87999908],
    "overall score": 0.0457259956,
    "0": {
      velocity: [0.1122955739, 0.5111116321, 1.0960138264],
      direction: [-173.6720594111, -23.4775333934, 36.9910573417],
      "speed factor": 0.5237621813,
      "Uz factor": 0.9971778148,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3164822662,
      "Direction stability factor": 0.4148246757,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4114321201, 1.2721782173, 1.5421313224],
      direction: [-43.9160967822, 0.2617669595, 13.5173948028],
      "speed factor": 1.1295360631,
      "Uz factor": 0.9920824656,
      "Directional variance factor": 0.9767318258,
      "Velocity stability factor": 0.2688068462,
      "Direction stability factor": 0.8404625234,
      score: 0.7316159297,
    },
    "67.5": {
      velocity: [0.2282061726, 0.6248551207, 0.981232221],
      direction: [-39.5266913866, -10.6616265265, 54.9793585821],
      "speed factor": 0.967942238,
      "Uz factor": 0.9875165948,
      "Directional variance factor": 0.0522998643,
      "Velocity stability factor": 0.3271301658,
      "Direction stability factor": 0.7374831945,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1734500714, 0.7212244716, 1.1272465917],
      direction: [-8.8071002473, 28.3233760461, 105.3628186862],
      "speed factor": 0.8113271452,
      "Uz factor": 0.9343785189,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2104290901,
      "Direction stability factor": 0.6828613363,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4218874223, 0.8033360305, 1.2075361134],
      direction: [-34.7111981278, 13.0991081531, 59.8294313756],
      "speed factor": 1.141251854,
      "Uz factor": 0.9448322557,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3575032219,
      "Direction stability factor": 0.7373871403,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3865422618, 0.6237852436, 1.0276377722],
      direction: [-36.8150947126, -13.7185866225, 51.5073089777],
      "speed factor": 0.7308613669,
      "Uz factor": 0.7746183956,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5070603193,
      "Direction stability factor": 0.7546599897,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0617438637, 0.3987188968, 0.6837780672],
      direction: [-96.9623098604, -12.6256310404, 88.2675244293],
      "speed factor": 0.7396716017,
      "Uz factor": 0.9904378395,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.396410448,
      "Direction stability factor": 0.4854726825,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0413506984, 0.5064195243, 0.920602829],
      direction: [-164.6349471395, -9.0534398183, 66.9718156649],
      "speed factor": 0.68961713,
      "Uz factor": 0.9916011416,
      "Directional variance factor": 0.1952497939,
      "Velocity stability factor": 0.3552094944,
      "Direction stability factor": 0.3566478811,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0546084323, 0.1670765319, 0.5723626282],
      direction: [-177.0064801315, -114.9353432794, 173.4712315121],
      "speed factor": 0.212176826,
      "Uz factor": 0.9950817371,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5829829225,
      "Direction stability factor": 0.026450801,
      score: 0.0,
    },
    id: "IDN:457.0",
    "45": {
      velocity: [0.2553334064, 0.5773134633, 0.9337303068],
      direction: [-46.8768278231, -8.7739974931, 52.2226680115],
      "speed factor": 0.7842119515,
      "Uz factor": 0.9892861314,
      "Directional variance factor": 0.2200891117,
      "Velocity stability factor": 0.4484847866,
      "Direction stability factor": 0.7247236227,
      score: 0.0,
    },
    "90": {
      velocity: [0.2835242919, 0.7349141059, 1.1196516969],
      direction: [-38.9955240095, 25.8985949988, 126.1248638917],
      "speed factor": 1.1913172715,
      "Uz factor": 0.9693200916,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.389746116,
      "Direction stability factor": 0.5413322558,
      score: 0.0,
    },
    "135": {
      velocity: [0.5699935695, 0.9971819059, 1.2298136645],
      direction: [-27.3446407856, 6.463570695, 40.6984362701],
      "speed factor": 0.8439652299,
      "Uz factor": 0.9569261887,
      "Directional variance factor": 0.4254603827,
      "Velocity stability factor": 0.4683098351,
      "Direction stability factor": 0.8109914526,
      score: 0.0,
    },
    "180": {
      velocity: [0.5810735899, 0.8949478492, 1.1712866305],
      direction: [-22.5139581833, 0.0786234923, 25.3544655044],
      "speed factor": 1.7478276208,
      "Uz factor": 0.937198955,
      "Directional variance factor": 0.9930112451,
      "Velocity stability factor": 0.5349375736,
      "Direction stability factor": 0.8670321564,
      score: 0.0,
    },
    "225": {
      velocity: [0.1626387585, 0.5956029976, 0.9137373357],
      direction: [-43.9966157816, 0.992716446, 33.5316478543],
      "speed factor": 0.8130632718,
      "Uz factor": 0.9905678036,
      "Directional variance factor": 0.9117585381,
      "Velocity stability factor": 0.3249271627,
      "Direction stability factor": 0.7846437121,
      score: 0.0,
    },
    "270": {
      velocity: [0.0862715469, 0.3229128423, 0.771547509],
      direction: [-122.6309732822, -62.4066397653, 75.5967673901],
      "speed factor": 0.2954756774,
      "Uz factor": 0.9628661966,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4594521253,
      "Direction stability factor": 0.449367387,
      score: 0.0,
    },
    "315": {
      velocity: [0.0153175198, 0.1693892606, 0.7155423827],
      direction: [-171.942439182, -30.7118734671, 144.979470677],
      "speed factor": 0.2087044263,
      "Uz factor": 0.9970554077,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4319524051,
      "Direction stability factor": 0.1196613615,
      score: 0.0,
    },
  },
  {
    position: [-41.75495903, 88.69744659, 47.87999908],
    "overall score": 0.045029616,
    "0": {
      velocity: [0.0393370154, 0.4478243545, 1.1113082259],
      direction: [-172.8186004774, -6.3422363508, 163.6566537194],
      "speed factor": 0.4589084772,
      "Uz factor": 0.9729028118,
      "Directional variance factor": 0.4362456577,
      "Velocity stability factor": 0.2551613934,
      "Direction stability factor": 0.0653465161,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3772008485, 1.0704666233, 1.4381041789],
      direction: [-26.0349616106, 4.8228652318, 24.1600375934],
      "speed factor": 0.9504412502,
      "Uz factor": 0.9982981247,
      "Directional variance factor": 0.5713008683,
      "Velocity stability factor": 0.3139419834,
      "Direction stability factor": 0.8605694467,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.229534592, 0.5914117057, 1.0565571326],
      direction: [-32.5010744445, -1.1494218873, 60.8912968003],
      "speed factor": 0.9161361586,
      "Uz factor": 0.9970695329,
      "Directional variance factor": 0.8978291656,
      "Velocity stability factor": 0.2610102652,
      "Direction stability factor": 0.7405767465,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2830331295, 0.7485864452, 1.1503623156],
      direction: [-23.8216670293, 21.0144684851, 63.1680626996],
      "speed factor": 0.842107454,
      "Uz factor": 0.9037461875,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2820083948,
      "Direction stability factor": 0.7583618619,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4515831416, 0.8312309783, 1.2578759494],
      direction: [-29.3105589894, -2.2546337744, 32.617210034],
      "speed factor": 1.1808805519,
      "Uz factor": 0.9242638821,
      "Directional variance factor": 0.7995881089,
      "Velocity stability factor": 0.3406206399,
      "Direction stability factor": 0.8279784194,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3133152044, 0.7025021735, 0.9754011636],
      direction: [-77.3422335104, -31.5517820197, 3.7952032832],
      "speed factor": 0.8230904852,
      "Uz factor": 0.8935736029,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4909207193,
      "Direction stability factor": 0.7746182311,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1199079034, 0.4232418493, 0.6750806478],
      direction: [-76.5135188754, -12.5030313898, 36.5319413442],
      "speed factor": 0.7851646339,
      "Uz factor": 0.9999999178,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.461289321,
      "Direction stability factor": 0.6859848327,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0892392012, 0.5061376754, 1.04124239],
      direction: [-158.2127931546, -20.7232915553, 177.1029226151],
      "speed factor": 0.689233322,
      "Uz factor": 0.9998864585,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3018582542,
      "Direction stability factor": 0.0685674562,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0104905059, 0.1716931367, 0.5155771774],
      direction: [-178.4651912561, 27.4748801014, 179.5757564649],
      "speed factor": 0.2180396276,
      "Uz factor": 0.8199587345,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5931857833,
      "Direction stability factor": 0.0054418119,
      score: 0.0,
    },
    id: "IDN:718.0",
    "45": {
      velocity: [0.0375415501, 0.5274336634, 0.9427416578],
      direction: [-49.1586392452, -3.6732868468, 109.2372311224],
      "speed factor": 0.7164561521,
      "Uz factor": 0.9994771208,
      "Directional variance factor": 0.6734856136,
      "Velocity stability factor": 0.2641009558,
      "Direction stability factor": 0.5600114712,
      score: 0.0,
    },
    "90": {
      velocity: [0.1867375604, 0.7970734286, 1.1662000914],
      direction: [-0.2085703985, 26.7979555597, 170.7884470512],
      "speed factor": 1.2920793527,
      "Uz factor": 0.9408096291,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2851318947,
      "Direction stability factor": 0.5250082849,
      score: 0.0,
    },
    "135": {
      velocity: [0.5492841683, 1.0197088435, 1.276883093],
      direction: [-35.5171512564, -0.3129564873, 13.095992848],
      "speed factor": 0.8630309109,
      "Uz factor": 0.9154199532,
      "Directional variance factor": 0.9721816456,
      "Velocity stability factor": 0.4136929214,
      "Direction stability factor": 0.8649634886,
      score: 0.0,
    },
    "180": {
      velocity: [0.5978668345, 0.8987931175, 1.2201588158],
      direction: [-42.3824558063, -17.8486864817, -1.157206593],
      "speed factor": 1.755337406,
      "Uz factor": 0.9100113211,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5096607516,
      "Direction stability factor": 0.8854854189,
      score: 0.0,
    },
    "225": {
      velocity: [0.3445757669, 0.7657064331, 1.1039406346],
      direction: [-36.4070876102, -1.6558607384, 15.5075285834],
      "speed factor": 1.0452730766,
      "Uz factor": 0.9999920736,
      "Directional variance factor": 0.8528123788,
      "Velocity stability factor": 0.3174975811,
      "Direction stability factor": 0.8557927328,
      score: 0.7204738564,
    },
    "270": {
      velocity: [0.0845470614, 0.3352631261, 0.9589105314],
      direction: [-131.6319882399, -36.6091704834, 17.4503994041],
      "speed factor": 0.3067765859,
      "Uz factor": 0.9891535592,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3102992932,
      "Direction stability factor": 0.5858822565,
      score: 0.0,
    },
    "315": {
      velocity: [0.0895816767, 0.65124139, 1.0684974537],
      direction: [-11.0409970117, 8.6650767649, 27.2939313552],
      "speed factor": 0.8023942027,
      "Uz factor": 0.9980970379,
      "Directional variance factor": 0.2297709542,
      "Velocity stability factor": 0.2058683114,
      "Direction stability factor": 0.8935140879,
      score: 0.0,
    },
  },
  {
    position: [-42.79727393, 88.02484617, 47.87999908],
    "overall score": 0.0444716947,
    "0": {
      velocity: [0.0424747896, 0.4294320921, 1.090007232],
      direction: [-162.8946777339, 2.2657896932, 179.1985701256],
      "speed factor": 0.4400609869,
      "Uz factor": 0.9757466941,
      "Directional variance factor": 0.7985964717,
      "Velocity stability factor": 0.272142202,
      "Direction stability factor": 0.0497409782,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.130952396, 0.9828265152, 1.4346531132],
      direction: [-30.8221667481, 6.9888978837, 68.7246335914],
      "speed factor": 0.8726277322,
      "Uz factor": 0.9978845443,
      "Directional variance factor": 0.3787646326,
      "Velocity stability factor": 0.1569313597,
      "Direction stability factor": 0.7234811102,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.209180309, 0.5721465955, 1.0347639907],
      direction: [-29.3443994552, 0.7036205949, 61.3246388852],
      "speed factor": 0.8862932186,
      "Uz factor": 0.9966701723,
      "Directional variance factor": 0.9374559471,
      "Velocity stability factor": 0.2622959641,
      "Direction stability factor": 0.7481415602,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.271954783, 0.7615652563, 1.1455610326],
      direction: [-19.2570682493, 18.0559010177, 62.8028400085],
      "speed factor": 0.8567077097,
      "Uz factor": 0.91108673,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2768121222,
      "Direction stability factor": 0.7720558104,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.453224743, 0.8315896178, 1.2314020361],
      direction: [-30.7231287497, -5.5859291797, 26.0379616176],
      "speed factor": 1.1813900499,
      "Uz factor": 0.9248206789,
      "Directional variance factor": 0.5034729618,
      "Velocity stability factor": 0.3636132672,
      "Direction stability factor": 0.8423303045,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2990615793, 0.7174154979, 1.0366785169],
      direction: [-84.4434348817, -31.9027819624, 9.2199279477],
      "speed factor": 0.8405637627,
      "Uz factor": 0.9243666778,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4328447918,
      "Direction stability factor": 0.7398239921,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1531007255, 0.4213536503, 0.6899951036],
      direction: [-99.6755819606, -10.8729981675, 38.4472760769],
      "speed factor": 0.7816617971,
      "Uz factor": 0.99999616,
      "Directional variance factor": 0.033511274,
      "Velocity stability factor": 0.4790256945,
      "Direction stability factor": 0.6163253943,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.100668178, 0.474467665, 1.0633208537],
      direction: [-170.3004003123, -21.5836113229, 168.7228311177],
      "speed factor": 0.6461066639,
      "Uz factor": 0.9992346958,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2940485626,
      "Direction stability factor": 0.0582688016,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0374013728, 0.1817285349, 0.486278636],
      direction: [-177.6023039271, 42.903029527, 178.8035922081],
      "speed factor": 0.2307839605,
      "Uz factor": 0.8732986573,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6384587784,
      "Direction stability factor": 0.0099836218,
      score: 0.0,
    },
    id: "IDN:717.0",
    "45": {
      velocity: [0.13677834, 0.5144789066, 0.9423947788],
      direction: [-48.7671696669, -1.9284816471, 100.922509819],
      "speed factor": 0.6988586496,
      "Uz factor": 0.9995442926,
      "Directional variance factor": 0.8285794091,
      "Velocity stability factor": 0.3450593275,
      "Direction stability factor": 0.5841953348,
      score: 0.0,
    },
    "90": {
      velocity: [0.2835819418, 0.8139373323, 1.1674833628],
      direction: [-52.0464743372, 24.899286128, 158.3390839384],
      "speed factor": 1.319416234,
      "Uz factor": 0.9406986439,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3548778906,
      "Direction stability factor": 0.4155956715,
      score: 0.0,
    },
    "135": {
      velocity: [0.5259386326, 1.0178658471, 1.267423258],
      direction: [-45.7700897492, -3.072864619, 11.6481405365],
      "speed factor": 0.8614710903,
      "Uz factor": 0.912158454,
      "Directional variance factor": 0.7268564783,
      "Velocity stability factor": 0.4025036737,
      "Direction stability factor": 0.8405049159,
      score: 0.0,
    },
    "180": {
      velocity: [0.574549939, 0.8921862725, 1.2393435719],
      direction: [-45.7054723508, -21.4824847165, -4.89470699],
      "speed factor": 1.7424342785,
      "Uz factor": 0.918516204,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4761712828,
      "Direction stability factor": 0.8866367629,
      score: 0.0,
    },
    "225": {
      velocity: [0.2993318044, 0.7749965626, 1.1030655925],
      direction: [-39.7566860998, -1.1108485393, 20.1273524593],
      "speed factor": 1.0579551201,
      "Uz factor": 0.9999998147,
      "Directional variance factor": 0.9012579076,
      "Velocity stability factor": 0.2776196557,
      "Direction stability factor": 0.8336554484,
      score: 0.711547115,
    },
    "270": {
      velocity: [0.0741676209, 0.3435135452, 0.9713024808],
      direction: [-156.0837247272, -23.9054327625, 175.5260261513],
      "speed factor": 0.3143259857,
      "Uz factor": 0.9818060745,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2923371478,
      "Direction stability factor": 0.0788618031,
      score: 0.0,
    },
    "315": {
      velocity: [0.1045687891, 0.7276914569, 1.1016320308],
      direction: [-13.7777485165, 7.3825877205, 39.8781189169],
      "speed factor": 0.8965882934,
      "Uz factor": 0.9996525808,
      "Directional variance factor": 0.3437699804,
      "Velocity stability factor": 0.1911464352,
      "Direction stability factor": 0.8509559238,
      score: 0.0,
    },
  },
  {
    position: [-36.3076623, 89.78439987, 46.87999908],
    "overall score": 0.0443917972,
    "0": {
      velocity: [0.045426645, 0.4819280052, 1.112190975],
      direction: [-170.3996762479, -24.4280553192, 137.897264212],
      "speed factor": 0.493856229,
      "Uz factor": 0.994600957,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2587792943,
      "Direction stability factor": 0.1436196098,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4131264224, 1.2705902958, 1.5503378063],
      direction: [-48.5469964302, 0.8319805753, 14.3750170738],
      "speed factor": 1.1281261863,
      "Uz factor": 0.9930371271,
      "Directional variance factor": 0.9260461711,
      "Velocity stability factor": 0.2645955913,
      "Direction stability factor": 0.8252166292,
      score: 0.7102687552,
    },
    "67.5": {
      velocity: [0.2286213324, 0.6308661194, 0.9863863764],
      direction: [-38.9554718264, -10.328727042, 56.1610883489],
      "speed factor": 0.9772536757,
      "Uz factor": 0.9879049425,
      "Directional variance factor": 0.0818909296,
      "Velocity stability factor": 0.3228956148,
      "Direction stability factor": 0.7357873328,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1717208978, 0.7173243723, 1.1303607468],
      direction: [-8.6476557518, 28.7481442584, 110.2037547181],
      "speed factor": 0.8069398059,
      "Uz factor": 0.9281560014,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2064196904,
      "Direction stability factor": 0.6698571931,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4215560055, 0.8025995623, 1.2153196987],
      direction: [-35.8523323806, 12.7734382619, 57.98848368],
      "speed factor": 1.1402055974,
      "Uz factor": 0.9391879621,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3508668426,
      "Direction stability factor": 0.7393310665,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3813816203, 0.627277335, 1.0397644645],
      direction: [-43.9728717126, -16.6334714824, 43.8781357516],
      "speed factor": 0.7349528948,
      "Uz factor": 0.7634680508,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4937680522,
      "Direction stability factor": 0.7559694237,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0487926297, 0.3964915271, 0.6786381709],
      direction: [-95.5693082923, -13.2754359011, 137.089298357],
      "speed factor": 0.7355395625,
      "Uz factor": 0.9902678885,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3888307332,
      "Direction stability factor": 0.3537260926,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0304389306, 0.4962704322, 0.9023452369],
      direction: [-169.1346754336, -9.4143702287, 73.000644223],
      "speed factor": 0.675796597,
      "Uz factor": 0.9929250111,
      "Directional variance factor": 0.1631670908,
      "Velocity stability factor": 0.3605964791,
      "Direction stability factor": 0.3274018898,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0452146442, 0.1606973344, 0.3837441281],
      direction: [-179.0218622153, -122.3688934674, 178.3774095115],
      "speed factor": 0.2040756411,
      "Uz factor": 0.9937958438,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7273366838,
      "Direction stability factor": 0.0072242452,
      score: 0.0,
    },
    id: "IDN:456.0",
    "45": {
      velocity: [0.2314032805, 0.572964537, 0.9466517072],
      direction: [-53.8463316977, -8.3374671608, 53.0402451107],
      "speed factor": 0.7783044503,
      "Uz factor": 0.9915643912,
      "Directional variance factor": 0.2588918079,
      "Velocity stability factor": 0.4185256618,
      "Direction stability factor": 0.7030928422,
      score: 0.0,
    },
    "90": {
      velocity: [0.2859563505, 0.7343932944, 1.124682855],
      direction: [-37.3610318351, 26.2452075218, 145.6039060447],
      "speed factor": 1.1904730209,
      "Uz factor": 0.9671389152,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3878491437,
      "Direction stability factor": 0.4917640614,
      score: 0.0,
    },
    "135": {
      velocity: [0.5709784516, 0.9962727893, 1.2282151693],
      direction: [-27.6960729683, 6.5362452237, 40.7833522864],
      "speed factor": 0.8431957988,
      "Uz factor": 0.9535534183,
      "Directional variance factor": 0.4190004246,
      "Velocity stability factor": 0.4703915485,
      "Direction stability factor": 0.8097793743,
      score: 0.0,
    },
    "180": {
      velocity: [0.5836095168, 0.8924587283, 1.1746203722],
      direction: [-22.0169214741, -0.4224148851, 24.5996499491],
      "speed factor": 1.742966383,
      "Uz factor": 0.9301775593,
      "Directional variance factor": 0.9624520102,
      "Velocity stability factor": 0.53430893,
      "Direction stability factor": 0.8705095238,
      score: 0.0,
    },
    "225": {
      velocity: [0.1609358916, 0.5973245913, 0.9095693645],
      direction: [-44.1491930281, 0.3866963713, 24.1438800496],
      "speed factor": 0.8154134357,
      "Uz factor": 0.9911296825,
      "Directional variance factor": 0.9656269892,
      "Velocity stability factor": 0.3271427506,
      "Direction stability factor": 0.8102970192,
      score: 0.0,
    },
    "270": {
      velocity: [0.0932994776, 0.3226485191, 0.753643636],
      direction: [-163.1337467948, -64.7340418424, -7.4623694768],
      "speed factor": 0.2952338132,
      "Uz factor": 0.9562947698,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4791184119,
      "Direction stability factor": 0.5675795075,
      score: 0.0,
    },
    "315": {
      velocity: [0.0203234156, 0.1731981184, 0.7416276513],
      direction: [-166.2750154852, -26.3861830413, 91.9371035089],
      "speed factor": 0.2133973182,
      "Uz factor": 0.9969522495,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4148520597,
      "Direction stability factor": 0.2827441139,
      score: 0.0,
    },
  },
  {
    position: [-45.8415347, 43.13739529, 45.87999908],
    "overall score": 0.0442935,
    "0": {
      velocity: [0.1001885097, 0.5897909736, 1.0027322542],
      direction: [-85.529586001, -3.9278844506, 131.6469546231],
      "speed factor": 0.6043889191,
      "Uz factor": 0.9984323487,
      "Directional variance factor": 0.6508547155,
      "Velocity stability factor": 0.372884814,
      "Direction stability factor": 0.3967318316,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1268914572, 0.6815429794, 1.1984570421],
      direction: [-143.8441323382, -0.1571680545, 176.0883401233],
      "speed factor": 0.6051254167,
      "Uz factor": 0.965676626,
      "Directional variance factor": 0.9860295063,
      "Velocity stability factor": 0.3070469866,
      "Direction stability factor": 0.1112986876,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3094942103, 0.6270092055, 0.9843878361],
      direction: [-23.732014862, 8.1717955553, 57.8279702195],
      "speed factor": 0.971279059,
      "Uz factor": 0.8726846743,
      "Directional variance factor": 0.2736181729,
      "Velocity stability factor": 0.3969457457,
      "Direction stability factor": 0.7734444859,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6219039036, 0.9386064597, 1.2972421718],
      direction: [-48.1452505536, -18.4303567494, 7.2778634188],
      "speed factor": 1.0558666954,
      "Uz factor": 0.798700762,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4409421301,
      "Direction stability factor": 0.8460469056,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2018110545, 0.9414409362, 1.3130147969],
      direction: [-37.2088967285, -6.9759997263, 42.4290415964],
      "speed factor": 1.3374493027,
      "Uz factor": 0.9587759281,
      "Directional variance factor": 0.3799111354,
      "Velocity stability factor": 0.0912670862,
      "Direction stability factor": 0.7787835047,
      score: 0.5071863077,
    },
    "202.5": {
      velocity: [0.0449112696, 0.2744638228, 0.648918901],
      direction: [-127.8817489882, -23.2658061762, 179.5670521873],
      "speed factor": 0.3215770279,
      "Uz factor": 0.914988852,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5355772672,
      "Direction stability factor": 0.1459755523,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0462306396, 0.2930896289, 0.5764331022],
      direction: [-147.6730936343, -6.5041653052, 177.7421338448],
      "speed factor": 0.5437165808,
      "Uz factor": 0.9988585191,
      "Directional variance factor": 0.4218519729,
      "Velocity stability factor": 0.4855191803,
      "Direction stability factor": 0.0960688126,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0255749832, 0.1707006184, 0.415164459],
      direction: [-177.6020327007, 147.364543979, 179.4687511206],
      "speed factor": 0.2324516827,
      "Uz factor": 0.9639714565,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7142985655,
      "Direction stability factor": 0.0081367116,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0481027789, 0.2863294764, 0.7867844355],
      direction: [-173.6047309754, 39.0730062382, 177.8254814575],
      "speed factor": 0.3636206642,
      "Uz factor": 0.9668792133,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4050403297,
      "Direction stability factor": 0.0238049655,
      score: 0.0,
    },
    id: "IDN:389.0",
    "45": {
      velocity: [0.0643707485, 0.5050437326, 1.1248868775],
      direction: [-144.7677674616, 12.1215115853, 135.8845358224],
      "speed factor": 0.6860420834,
      "Uz factor": 0.9377066262,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1378339452,
      "Direction stability factor": 0.2204102687,
      score: 0.0,
    },
    "90": {
      velocity: [0.6082177018, 1.04594758, 1.3637105289],
      direction: [-62.2554130587, -9.227525333, 31.9717667304],
      "speed factor": 1.695511635,
      "Uz factor": 0.8429884625,
      "Directional variance factor": 0.179775526,
      "Velocity stability factor": 0.44859787,
      "Direction stability factor": 0.7382578339,
      score: 0.0,
    },
    "135": {
      velocity: [0.1786606592, 0.7125589515, 1.1644607695],
      direction: [-120.88104437, -38.888569565, 43.5906134908],
      "speed factor": 0.6030745,
      "Uz factor": 0.8634494266,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2056316151,
      "Direction stability factor": 0.5431342837,
      score: 0.0,
    },
    "180": {
      velocity: [0.0662050186, 0.6091974923, 1.144342044],
      direction: [-145.260550193, -8.1911513437, 145.6794618386],
      "speed factor": 1.1897589391,
      "Uz factor": 0.9914966567,
      "Directional variance factor": 0.2718976583,
      "Velocity stability factor": 0.1504745126,
      "Direction stability factor": 0.1918332999,
      score: 0.2015096927,
    },
    "225": {
      velocity: [0.0447796231, 0.2721624672, 0.6581017156],
      direction: [-157.3808642, -6.6469086689, 140.6986589868],
      "speed factor": 0.371531552,
      "Uz factor": 0.9999742326,
      "Directional variance factor": 0.4091636739,
      "Velocity stability factor": 0.4487579956,
      "Direction stability factor": 0.1720013245,
      score: 0.0,
    },
    "270": {
      velocity: [0.045449181, 0.2378824818, 0.5915961837],
      direction: [-178.396537346, 101.7287822463, 177.7534336732],
      "speed factor": 0.217670152,
      "Uz factor": 0.9957873187,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5691974943,
      "Direction stability factor": 0.0106945249,
      score: 0.0,
    },
    "315": {
      velocity: [0.0461102047, 0.2166405825, 0.5620913203],
      direction: [-147.4589262339, 88.7505925399, 176.8129969599],
      "speed factor": 0.2669227573,
      "Uz factor": 0.9750258998,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5814175599,
      "Direction stability factor": 0.0992446578,
      score: 0.0,
    },
  },
  {
    position: [0.735988388, 93.63012419, 47.87999908],
    "overall score": 0.0435688793,
    "0": {
      velocity: [0.0888051541, 0.6063143124, 1.2442186518],
      direction: [-174.0475014971, -3.5167285949, 155.3519649889],
      "speed factor": 0.6213212278,
      "Uz factor": 0.9878052326,
      "Directional variance factor": 0.6874019027,
      "Velocity stability factor": 0.1971831228,
      "Direction stability factor": 0.085001482,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0727681846, 0.5374080436, 1.0147929415],
      direction: [-143.232885581, -9.0569609989, 138.6566152882],
      "speed factor": 0.4771515167,
      "Uz factor": 0.9870488574,
      "Directional variance factor": 0.1949368001,
      "Velocity stability factor": 0.3908176007,
      "Direction stability factor": 0.2169736087,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1160152978, 0.4252548795, 0.8968083455],
      direction: [-50.6007046603, 6.753386065, 106.783828576],
      "speed factor": 0.6587481579,
      "Uz factor": 0.9965033512,
      "Directional variance factor": 0.3996990164,
      "Velocity stability factor": 0.3023188379,
      "Direction stability factor": 0.562820741,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4878481368, 0.8995735707, 1.2216041995],
      direction: [-9.3997784033, 13.1112578654, 49.1729718959],
      "speed factor": 1.0119574221,
      "Uz factor": 0.9718044704,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3925827682,
      "Direction stability factor": 0.8372979158,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4598010074, 0.8325087203, 1.1533858325],
      direction: [-16.3145214541, 3.4654753426, 44.2269914177],
      "speed factor": 1.1826957643,
      "Uz factor": 0.958614549,
      "Directional variance factor": 0.6919577473,
      "Velocity stability factor": 0.4327922638,
      "Direction stability factor": 0.8318291309,
      score: 0.6971020682,
    },
    "202.5": {
      velocity: [0.4156470377, 0.8342549439, 1.1319263086],
      direction: [-68.4874759027, -19.2057261748, 9.443960906],
      "speed factor": 0.9774593338,
      "Uz factor": 0.9164815315,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4492513684,
      "Direction stability factor": 0.7835237866,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2167975352, 0.5375748099, 0.8130571229],
      direction: [-57.0748063609, -3.9854296747, 22.5328165424],
      "speed factor": 0.9972660535,
      "Uz factor": 0.9935706687,
      "Directional variance factor": 0.6457395845,
      "Velocity stability factor": 0.421420791,
      "Direction stability factor": 0.7788677142,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0972094082, 0.3647872523, 1.0155163571],
      direction: [-175.4656022901, 1.6234486638, 170.3989149805],
      "speed factor": 0.4967492877,
      "Uz factor": 0.9740724614,
      "Directional variance factor": 0.8556934521,
      "Velocity stability factor": 0.3265690451,
      "Direction stability factor": 0.0392652298,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0774808803, 0.4244304163, 1.015579045],
      direction: [-164.9633048613, -1.2209288594, 178.689410605],
      "speed factor": 0.5390002868,
      "Uz factor": 0.9947102898,
      "Directional variance factor": 0.8914729903,
      "Velocity stability factor": 0.2444233998,
      "Direction stability factor": 0.0454091237,
      score: 0.0,
    },
    id: "IDN:675.0",
    "45": {
      velocity: [0.103685004, 0.6197551172, 1.1373854737],
      direction: [-150.8224420579, -1.3120802296, 150.1021641183],
      "speed factor": 0.8418639107,
      "Uz factor": 0.999983916,
      "Directional variance factor": 0.8833706463,
      "Velocity stability factor": 0.1596342276,
      "Direction stability factor": 0.1640983162,
      score: 0.0,
    },
    "90": {
      velocity: [0.5130590433, 0.8510796775, 1.1831163822],
      direction: [-12.51729201, 15.5255830882, 51.1395224142],
      "speed factor": 1.3796250625,
      "Uz factor": 0.9885590389,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5109536046,
      "Direction stability factor": 0.8231755155,
      score: 0.0,
    },
    "135": {
      velocity: [0.6308283943, 1.1647374106, 1.3698626748],
      direction: [-1.7319316649, 6.0965623332, 25.2238319792],
      "speed factor": 0.9857758858,
      "Uz factor": 0.9473082518,
      "Directional variance factor": 0.4580833482,
      "Velocity stability factor": 0.4044781881,
      "Direction stability factor": 0.9251228788,
      score: 0.0,
    },
    "180": {
      velocity: [0.4822747098, 0.7731789988, 1.1552446048],
      direction: [-37.6881610754, -0.0543402047, 31.5249763325],
      "speed factor": 1.5100138083,
      "Uz factor": 0.937212344,
      "Directional variance factor": 0.9951697596,
      "Velocity stability factor": 0.4697287408,
      "Direction stability factor": 0.807741285,
      score: 0.0,
    },
    "225": {
      velocity: [0.2142530186, 0.4907663135, 0.7805167464],
      direction: [-83.6158246687, -14.1102749198, 33.9348007917],
      "speed factor": 0.6699497252,
      "Uz factor": 0.974328887,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4910531413,
      "Direction stability factor": 0.6734704848,
      score: 0.0,
    },
    "270": {
      velocity: [0.2921242002, 0.8899366608, 1.3953128707],
      direction: [-29.6768112538, 8.3439957515, 33.8991207284],
      "speed factor": 0.8143207803,
      "Uz factor": 0.9990502834,
      "Directional variance factor": 0.2583114888,
      "Velocity stability factor": 0.1298012418,
      "Direction stability factor": 0.8234001889,
      score: 0.0,
    },
    "315": {
      velocity: [0.117038637, 0.4810270709, 0.9015434827],
      direction: [-174.6386796385, -9.540891254, 24.4286062695],
      "speed factor": 0.5926732222,
      "Uz factor": 0.9702696284,
      "Directional variance factor": 0.1519207774,
      "Velocity stability factor": 0.3635814515,
      "Direction stability factor": 0.4470353169,
      score: 0.0,
    },
  },
  {
    position: [-44.01360092, 86.92164496, 46.87999908],
    "overall score": 0.0433239004,
    "0": {
      velocity: [0.0647867541, 0.3552899218, 0.8968755346],
      direction: [-177.7370994958, 89.47267244, 163.2667945484],
      "speed factor": 0.3640837202,
      "Uz factor": 0.9611898539,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4218390925,
      "Direction stability factor": 0.052766961,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.154183995, 0.7127022231, 1.3587809481],
      direction: [-35.7375013615, 20.1517166427, 100.7948611953],
      "speed factor": 0.6327909504,
      "Uz factor": 0.9934610718,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2210191327,
      "Direction stability factor": 0.6207434373,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2446296112, 0.5001342974, 1.0015960515],
      direction: [-37.4144347206, 9.2228557117, 67.0394037573],
      "speed factor": 0.7747413682,
      "Uz factor": 0.9910560507,
      "Directional variance factor": 0.1801906034,
      "Velocity stability factor": 0.3236092108,
      "Direction stability factor": 0.7098504487,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2968677906, 0.7535249664, 1.1028128766],
      direction: [-30.2259187845, 18.1076622221, 78.3434714134],
      "speed factor": 0.8476629453,
      "Uz factor": 0.8849497313,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.332823321,
      "Direction stability factor": 0.6984183606,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4529565787, 0.8123200795, 1.2909212907],
      direction: [-38.6517008204, -10.2564673512, 19.1196383138],
      "speed factor": 1.1540149596,
      "Uz factor": 0.8975421985,
      "Directional variance factor": 0.0883140132,
      "Velocity stability factor": 0.3147196276,
      "Direction stability factor": 0.839524058,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.280336937, 0.6943733114, 1.0401943735],
      direction: [-85.878029447, -37.5562469242, 1.8180985182],
      "speed factor": 0.8135662598,
      "Uz factor": 0.9365826318,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4157440256,
      "Direction stability factor": 0.7563996445,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1501353279, 0.3874973305, 0.6223086711],
      direction: [-112.183779694, -13.4067204128, 30.8328161326],
      "speed factor": 0.718854244,
      "Uz factor": 0.9998897321,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5418276115,
      "Direction stability factor": 0.6027316783,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0266361293, 0.2624603297, 0.7750239696],
      direction: [-174.8621736472, -32.2357000173, 179.4895379518],
      "speed factor": 0.3574055316,
      "Uz factor": 0.9863469948,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4511774756,
      "Direction stability factor": 0.01568969,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0688838507, 0.1818572404, 0.3531036693],
      direction: [-179.9016835946, 154.1250082568, 175.938815264],
      "speed factor": 0.2309474085,
      "Uz factor": 0.9936989168,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7710795604,
      "Direction stability factor": 0.0115541698,
      score: 0.0,
    },
    id: "IDN:450.0",
    "45": {
      velocity: [0.1278722805, 0.4494819288, 0.8264316052],
      direction: [-48.4923921755, 11.0277547487, 90.1601799441],
      "speed factor": 0.6105679548,
      "Uz factor": 0.9994891002,
      "Directional variance factor": 0.0197551335,
      "Velocity stability factor": 0.4320933736,
      "Direction stability factor": 0.6148539663,
      score: 0.0,
    },
    "90": {
      velocity: [0.3041878827, 0.7977413416, 1.1717837127],
      direction: [-3.82893161, 25.7369957038, 77.9386452139],
      "speed factor": 1.2931620591,
      "Uz factor": 0.9110880698,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.366778649,
      "Direction stability factor": 0.7728678422,
      score: 0.0,
    },
    "135": {
      velocity: [0.3894417262, 0.9953528486, 1.2629113761],
      direction: [-40.5019693206, -6.6750515263, 11.2268261175],
      "speed factor": 0.8424172067,
      "Uz factor": 0.8824959159,
      "Directional variance factor": 0.4066620865,
      "Velocity stability factor": 0.2961487143,
      "Direction stability factor": 0.8563089016,
      score: 0.0,
    },
    "180": {
      velocity: [0.544253542, 0.8705312463, 1.2505177242],
      direction: [-47.9393449912, -28.0244536397, -8.6531087945],
      "speed factor": 1.7001421461,
      "Uz factor": 0.9032348965,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4434942781,
      "Direction stability factor": 0.8908715661,
      score: 0.0,
    },
    "225": {
      velocity: [0.2653440716, 0.7353281501, 1.0234012496],
      direction: [-49.6238392433, -1.6186776274, 22.7471092841],
      "speed factor": 1.0038033959,
      "Uz factor": 0.9999803091,
      "Directional variance factor": 0.8561175442,
      "Velocity stability factor": 0.3186729072,
      "Direction stability factor": 0.7989695874,
      score: 0.6931824066,
    },
    "270": {
      velocity: [0.0649732077, 0.2088202375, 0.8461257703],
      direction: [-178.69250298, -98.6590319725, 173.9436262738],
      "speed factor": 0.19107726,
      "Uz factor": 0.9244864334,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3838243556,
      "Direction stability factor": 0.0204551965,
      score: 0.0,
    },
    "315": {
      velocity: [0.1031334492, 0.6310931435, 1.0572262908],
      direction: [-21.773935183, 3.1208730417, 154.5363055468],
      "speed factor": 0.7775695579,
      "Uz factor": 0.9985846619,
      "Directional variance factor": 0.722589063,
      "Velocity stability factor": 0.2260055694,
      "Direction stability factor": 0.5102493313,
      score: 0.0,
    },
  },
  {
    position: [-50.54083578, 38.75377202, 46.87999908],
    "overall score": 0.0427401062,
    "0": {
      velocity: [0.2608687703, 0.8689422826, 1.3708939002],
      direction: [-59.1710432292, -12.791781908, 75.7808887545],
      "speed factor": 0.8904495158,
      "Uz factor": 0.9975466974,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2287203584,
      "Direction stability factor": 0.6251335223,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0968894298, 0.6503649633, 1.5943105908],
      direction: [-122.351485514, 2.5295464578, 118.6390670387],
      "speed factor": 0.5774432153,
      "Uz factor": 0.9995358597,
      "Directional variance factor": 0.775151426,
      "Velocity stability factor": 0.0316574921,
      "Direction stability factor": 0.3305817985,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1454215482, 0.575954718, 1.0776754514],
      direction: [-113.4290063008, 10.5779199314, 50.2442052624],
      "speed factor": 0.8921922543,
      "Uz factor": 0.9776031707,
      "Directional variance factor": 0.0597404505,
      "Velocity stability factor": 0.1669803048,
      "Direction stability factor": 0.5453521901,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2756561847, 0.8031927792, 1.2554346707],
      direction: [-37.6692257784, -15.7147058122, 14.2782500997],
      "speed factor": 0.9035357648,
      "Uz factor": 0.9607888056,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1889207245,
      "Direction stability factor": 0.8557014559,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4621020176, 0.9414991508, 1.2951619003],
      direction: [-42.6352053303, -12.5160516799, 4.957566088],
      "speed factor": 1.3375320048,
      "Uz factor": 0.9094311187,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.318730755,
      "Direction stability factor": 0.8677978572,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1489633326, 0.5221405467, 0.9707575704],
      direction: [-118.2933755195, -18.8315180294, 5.8933568009],
      "speed factor": 0.611768806,
      "Uz factor": 0.9357802324,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3681206894,
      "Direction stability factor": 0.6550368547,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4675929803, 0.812109243, 1.0651858103],
      direction: [-19.6788861409, 5.4794154422, 15.9073187378],
      "speed factor": 1.5065605101,
      "Uz factor": 0.9951752504,
      "Directional variance factor": 0.5129408496,
      "Velocity stability factor": 0.4201270823,
      "Direction stability factor": 0.9011494309,
      score: 0.6838416984,
    },
    "292.5": {
      velocity: [0.1007259435, 0.5630771289, 0.9546079629],
      direction: [-34.1566264334, 10.800242913, 50.0427815771],
      "speed factor": 0.7667706614,
      "Uz factor": 0.9522225732,
      "Directional variance factor": 0.0399784077,
      "Velocity stability factor": 0.3738144044,
      "Direction stability factor": 0.7661127555,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1044398832, 0.4858845689, 0.9507372579],
      direction: [-36.5457640016, -6.486892868, 130.6079942876],
      "speed factor": 0.6170432465,
      "Uz factor": 0.9971804885,
      "Directional variance factor": 0.4233873006,
      "Velocity stability factor": 0.3183629206,
      "Direction stability factor": 0.5356840048,
      score: 0.0,
    },
    id: "IDN:483.0",
    "45": {
      velocity: [0.0629970216, 0.494249908, 1.0222330871],
      direction: [-169.422484153, 13.2612901139, 164.1519422592],
      "speed factor": 0.6713799513,
      "Uz factor": 0.9958209387,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.220171432,
      "Direction stability factor": 0.0734043711,
      score: 0.0,
    },
    "90": {
      velocity: [0.1770510141, 0.8195873056, 1.2056449442],
      direction: [-36.7898407187, -4.2003392805, 36.4032017007],
      "speed factor": 1.3285750061,
      "Uz factor": 0.9468138358,
      "Directional variance factor": 0.6266365084,
      "Velocity stability factor": 0.2492729731,
      "Direction stability factor": 0.7966859933,
      score: 0.0,
    },
    "135": {
      velocity: [0.1321204333, 0.5829973215, 0.9457116224],
      direction: [-179.1853971623, -29.7516551283, 55.0265315509],
      "speed factor": 0.4934199724,
      "Uz factor": 0.9808766473,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3443994253,
      "Direction stability factor": 0.3494113091,
      score: 0.0,
    },
    "180": {
      velocity: [0.3274923687, 0.7701598972, 1.1100100125],
      direction: [-65.7593687735, -16.8512892089, 10.1541570098],
      "speed factor": 1.5041175215,
      "Uz factor": 0.9616393072,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3834098384,
      "Direction stability factor": 0.789129095,
      score: 0.0,
    },
    "225": {
      velocity: [0.1103988857, 0.5102257028, 0.938083758],
      direction: [-48.85129278, 3.8503659426, 44.2512896542],
      "speed factor": 0.6965139211,
      "Uz factor": 0.9978374033,
      "Directional variance factor": 0.6577452495,
      "Velocity stability factor": 0.2560928856,
      "Direction stability factor": 0.7413817155,
      score: 0.0,
    },
    "270": {
      velocity: [0.0429871369, 0.6485239235, 1.2223360343],
      direction: [4.2846593934, 21.2314094395, 78.3521399489],
      "speed factor": 0.5934203306,
      "Uz factor": 0.9779135598,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0697258108,
      "Direction stability factor": 0.7942569985,
      score: 0.0,
    },
    "315": {
      velocity: [0.160387543, 0.6695669622, 1.1835679283],
      direction: [-42.5327623246, -2.1455889484, 60.2340111267],
      "speed factor": 0.8249731314,
      "Uz factor": 0.9775795803,
      "Directional variance factor": 0.8092809824,
      "Velocity stability factor": 0.169959269,
      "Direction stability factor": 0.7145367404,
      score: 0.0,
    },
  },
  {
    position: [-35.50511975, 90.5113871, 45.87999908],
    "overall score": 0.0427164504,
    "0": {
      velocity: [0.0481015338, 0.4173307847, 1.1198920274],
      direction: [-165.7006377277, -11.5602473489, 133.7234200211],
      "speed factor": 0.4276601595,
      "Uz factor": 0.9837763285,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.255286961,
      "Direction stability factor": 0.1682665063,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4341747703, 1.2951219025, 1.6193331882],
      direction: [-62.5066476799, -0.9577041813, 12.1226902889],
      "speed factor": 1.1499072025,
      "Uz factor": 0.9799892611,
      "Directional variance factor": 0.9148707394,
      "Velocity stability factor": 0.2335895174,
      "Direction stability factor": 0.7926962834,
      score: 0.6834632059,
    },
    "67.5": {
      velocity: [0.1910922133, 0.6531149967, 1.0584573559],
      direction: [-44.8665986323, -16.1155097364, 48.4671514259],
      "speed factor": 1.0117186698,
      "Uz factor": 0.9608089911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2249619507,
      "Direction stability factor": 0.7407395832,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2684912554, 0.7058785231, 1.1557781135],
      direction: [-15.4015485328, 39.0037133584, 135.5083400963],
      "speed factor": 0.7940640252,
      "Uz factor": 0.9407890527,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2654870541,
      "Direction stability factor": 0.5808058649,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4009590035, 0.8099000843, 1.2967266771],
      direction: [-42.0907851812, 23.608203312, 77.5820205706],
      "speed factor": 1.1505770159,
      "Uz factor": 0.9529302775,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2674488601,
      "Direction stability factor": 0.6675755396,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2257592071, 0.4705520159, 1.0276387538],
      direction: [-26.8783115473, 11.1803833325, 128.8127239677],
      "speed factor": 0.5513248239,
      "Uz factor": 0.8134786047,
      "Directional variance factor": 0.0061881482,
      "Velocity stability factor": 0.383433137,
      "Direction stability factor": 0.5675249013,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1042587622, 0.303377775, 0.6721328348],
      direction: [-101.6824292301, -5.8326778295, 106.6552934751],
      "speed factor": 0.5628023316,
      "Uz factor": 0.9776046899,
      "Directional variance factor": 0.4815397485,
      "Velocity stability factor": 0.4489646146,
      "Direction stability factor": 0.4212841036,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0611287224, 0.4371536672, 0.9122842485],
      direction: [-167.1237729634, -0.8917094501, 123.5862353213],
      "speed factor": 0.5952943022,
      "Uz factor": 0.9977869821,
      "Directional variance factor": 0.9207369378,
      "Velocity stability factor": 0.3758138503,
      "Direction stability factor": 0.1924721992,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0607685876, 0.1832662982, 0.3960957005],
      direction: [-179.9258902395, -151.7496341559, 179.7918034988],
      "speed factor": 0.2327368244,
      "Uz factor": 0.9984488206,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7299159838,
      "Direction stability factor": 0.0007841841,
      score: 0.0,
    },
    id: "IDN:328.0",
    "45": {
      velocity: [0.2011761442, 0.5543127353, 1.0096166106],
      direction: [-36.5155301648, -7.3466667876, 88.0837752367],
      "speed factor": 0.7529681872,
      "Uz factor": 0.9742312013,
      "Directional variance factor": 0.3469629522,
      "Velocity stability factor": 0.3427634824,
      "Direction stability factor": 0.6538908183,
      score: 0.0,
    },
    "90": {
      velocity: [0.0993786525, 0.7289830297, 1.0448460149],
      direction: [-32.3769060518, 35.170483852, 109.2181981122],
      "speed factor": 1.1817028236,
      "Uz factor": 0.9604607547,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3099435246,
      "Direction stability factor": 0.6066802662,
      score: 0.0,
    },
    "135": {
      velocity: [0.5635783843, 1.0163207144, 1.2133677384],
      direction: [-33.5910739103, 10.2020359803, 68.582120584],
      "speed factor": 0.8601633668,
      "Uz factor": 0.9466128298,
      "Directional variance factor": 0.0931523573,
      "Velocity stability factor": 0.4763927146,
      "Direction stability factor": 0.7161855708,
      score: 0.0,
    },
    "180": {
      velocity: [0.5420726481, 0.9501367753, 1.3266181557],
      direction: [-22.3807533676, 7.3985350208, 37.6894173317],
      "speed factor": 1.8556112525,
      "Uz factor": 0.9381391959,
      "Directional variance factor": 0.3423524426,
      "Velocity stability factor": 0.3818119691,
      "Direction stability factor": 0.8331384147,
      score: 0.0,
    },
    "225": {
      velocity: [0.0275433649, 0.4652662845, 0.7567559481],
      direction: [-42.5368033406, 19.3443061137, 102.0546530877],
      "speed factor": 0.635139395,
      "Uz factor": 0.9613184232,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3445978697,
      "Direction stability factor": 0.5983570655,
      score: 0.0,
    },
    "270": {
      velocity: [0.0669725572, 0.280530353, 0.6402598476],
      direction: [-175.0803632156, -73.0885899005, 168.514620829],
      "speed factor": 0.2566943312,
      "Uz factor": 0.9649854444,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5477891483,
      "Direction stability factor": 0.0455694888,
      score: 0.0,
    },
    "315": {
      velocity: [0.0527750737, 0.1639779919, 0.2468677175],
      direction: [-178.2134220087, -138.8635465189, 179.2252273791],
      "speed factor": 0.2020372048,
      "Uz factor": 0.9940065921,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8425450661,
      "Direction stability factor": 0.0071148628,
      score: 0.0,
    },
  },
  {
    position: [-35.50511975, 90.5113871, 45.87999908],
    "overall score": 0.0427164504,
    "0": {
      velocity: [0.0481015338, 0.4173307847, 1.1198920274],
      direction: [-165.7006377277, -11.5602473489, 133.7234200211],
      "speed factor": 0.4276601595,
      "Uz factor": 0.9837763285,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.255286961,
      "Direction stability factor": 0.1682665063,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4341747703, 1.2951219025, 1.6193331882],
      direction: [-62.5066476799, -0.9577041813, 12.1226902889],
      "speed factor": 1.1499072025,
      "Uz factor": 0.9799892611,
      "Directional variance factor": 0.9148707394,
      "Velocity stability factor": 0.2335895174,
      "Direction stability factor": 0.7926962834,
      score: 0.6834632059,
    },
    "67.5": {
      velocity: [0.1910922133, 0.6531149967, 1.0584573559],
      direction: [-44.8665986323, -16.1155097364, 48.4671514259],
      "speed factor": 1.0117186698,
      "Uz factor": 0.9608089911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2249619507,
      "Direction stability factor": 0.7407395832,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2684912554, 0.7058785231, 1.1557781135],
      direction: [-15.4015485328, 39.0037133584, 135.5083400963],
      "speed factor": 0.7940640252,
      "Uz factor": 0.9407890527,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2654870541,
      "Direction stability factor": 0.5808058649,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4009590035, 0.8099000843, 1.2967266771],
      direction: [-42.0907851812, 23.608203312, 77.5820205706],
      "speed factor": 1.1505770159,
      "Uz factor": 0.9529302775,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2674488601,
      "Direction stability factor": 0.6675755396,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2257592071, 0.4705520159, 1.0276387538],
      direction: [-26.8783115473, 11.1803833325, 128.8127239677],
      "speed factor": 0.5513248239,
      "Uz factor": 0.8134786047,
      "Directional variance factor": 0.0061881482,
      "Velocity stability factor": 0.383433137,
      "Direction stability factor": 0.5675249013,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1042587622, 0.303377775, 0.6721328348],
      direction: [-101.6824292301, -5.8326778295, 106.6552934751],
      "speed factor": 0.5628023316,
      "Uz factor": 0.9776046899,
      "Directional variance factor": 0.4815397485,
      "Velocity stability factor": 0.4489646146,
      "Direction stability factor": 0.4212841036,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0611287224, 0.4371536672, 0.9122842485],
      direction: [-167.1237729634, -0.8917094501, 123.5862353213],
      "speed factor": 0.5952943022,
      "Uz factor": 0.9977869821,
      "Directional variance factor": 0.9207369378,
      "Velocity stability factor": 0.3758138503,
      "Direction stability factor": 0.1924721992,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0607685876, 0.1832662982, 0.3960957005],
      direction: [-179.9258902395, -151.7496341559, 179.7918034988],
      "speed factor": 0.2327368244,
      "Uz factor": 0.9984488206,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7299159838,
      "Direction stability factor": 0.0007841841,
      score: 0.0,
    },
    id: "IDN:369.0",
    "45": {
      velocity: [0.2011761442, 0.5543127353, 1.0096166106],
      direction: [-36.5155301648, -7.3466667876, 88.0837752367],
      "speed factor": 0.7529681872,
      "Uz factor": 0.9742312013,
      "Directional variance factor": 0.3469629522,
      "Velocity stability factor": 0.3427634824,
      "Direction stability factor": 0.6538908183,
      score: 0.0,
    },
    "90": {
      velocity: [0.0993786525, 0.7289830297, 1.0448460149],
      direction: [-32.3769060518, 35.170483852, 109.2181981122],
      "speed factor": 1.1817028236,
      "Uz factor": 0.9604607547,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3099435246,
      "Direction stability factor": 0.6066802662,
      score: 0.0,
    },
    "135": {
      velocity: [0.5635783843, 1.0163207144, 1.2133677384],
      direction: [-33.5910739103, 10.2020359803, 68.582120584],
      "speed factor": 0.8601633668,
      "Uz factor": 0.9466128298,
      "Directional variance factor": 0.0931523573,
      "Velocity stability factor": 0.4763927146,
      "Direction stability factor": 0.7161855708,
      score: 0.0,
    },
    "180": {
      velocity: [0.5420726481, 0.9501367753, 1.3266181557],
      direction: [-22.3807533676, 7.3985350208, 37.6894173317],
      "speed factor": 1.8556112525,
      "Uz factor": 0.9381391959,
      "Directional variance factor": 0.3423524426,
      "Velocity stability factor": 0.3818119691,
      "Direction stability factor": 0.8331384147,
      score: 0.0,
    },
    "225": {
      velocity: [0.0275433649, 0.4652662845, 0.7567559481],
      direction: [-42.5368033406, 19.3443061137, 102.0546530877],
      "speed factor": 0.635139395,
      "Uz factor": 0.9613184232,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3445978697,
      "Direction stability factor": 0.5983570655,
      score: 0.0,
    },
    "270": {
      velocity: [0.0669725572, 0.280530353, 0.6402598476],
      direction: [-175.0803632156, -73.0885899005, 168.514620829],
      "speed factor": 0.2566943312,
      "Uz factor": 0.9649854444,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5477891483,
      "Direction stability factor": 0.0455694888,
      score: 0.0,
    },
    "315": {
      velocity: [0.0527750737, 0.1639779919, 0.2468677175],
      direction: [-178.2134220087, -138.8635465189, 179.2252273791],
      "speed factor": 0.2020372048,
      "Uz factor": 0.9940065921,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8425450661,
      "Direction stability factor": 0.0071148628,
      score: 0.0,
    },
  },
  {
    position: [-35.94095872, 89.87253324, 45.87999908],
    "overall score": 0.0426596547,
    "0": {
      velocity: [0.1034177174, 0.3851541346, 1.1090560341],
      direction: [-165.0067064041, -15.0456370283, 174.924315448],
      "speed factor": 0.3946871035,
      "Uz factor": 0.976345523,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3012515306,
      "Direction stability factor": 0.0557471615,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4886243356, 1.238774279, 1.5989323086],
      direction: [-57.5527737847, -1.7520054136, 13.7347138233],
      "speed factor": 1.0998775196,
      "Uz factor": 0.9874928618,
      "Directional variance factor": 0.8442661855,
      "Velocity stability factor": 0.2819933128,
      "Direction stability factor": 0.8019792011,
      score: 0.6825544751,
    },
    "67.5": {
      velocity: [0.1602532223, 0.65153014, 1.0984554432],
      direction: [-45.0073091892, -15.9611535345, 52.9684163365],
      "speed factor": 1.0092636212,
      "Uz factor": 0.9778039796,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1616651587,
      "Direction stability factor": 0.7278452069,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2309385285, 0.6945931151, 1.126390202],
      direction: [-15.5644520503, 42.5239877383, 123.1208866606],
      "speed factor": 0.7813687296,
      "Uz factor": 0.8982239499,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2587280646,
      "Direction stability factor": 0.614762948,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.391411215, 0.8117007347, 1.466137014],
      direction: [-42.3157829056, 23.8357528893, 65.2990355229],
      "speed factor": 1.1531350931,
      "Uz factor": 0.9202547974,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1210984362,
      "Direction stability factor": 0.7010699488,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3254345556, 0.5974089415, 1.0730246828],
      direction: [-32.2132847434, -0.4141615497, 94.6552633779],
      "speed factor": 0.6999574294,
      "Uz factor": 0.7655175008,
      "Directional variance factor": 0.96318564,
      "Velocity stability factor": 0.4251763855,
      "Direction stability factor": 0.6475873663,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1022307471, 0.3288163525, 0.6529935502],
      direction: [-171.6567975599, -9.5870967437, 66.6359684228],
      "speed factor": 0.6099939584,
      "Uz factor": 0.9749604034,
      "Directional variance factor": 0.1478136228,
      "Velocity stability factor": 0.4655684981,
      "Direction stability factor": 0.33807565,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0521889558, 0.4110092986, 0.9020604053],
      direction: [-166.4831882067, 2.071134838, 127.7215557876],
      "speed factor": 0.5596921905,
      "Uz factor": 0.9994198105,
      "Directional variance factor": 0.8158991255,
      "Velocity stability factor": 0.3767555147,
      "Direction stability factor": 0.1827646,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0606271649, 0.1773025175, 0.3844322959],
      direction: [-179.1231461346, -153.4142108575, 178.8209243697],
      "speed factor": 0.225163193,
      "Uz factor": 0.9808754671,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7391961852,
      "Direction stability factor": 0.0057109153,
      score: 0.0,
    },
    id: "IDN:367.0",
    "45": {
      velocity: [0.0721031931, 0.5117095676, 0.9551020877],
      direction: [-36.6137092383, -5.0059113291, 98.4808470113],
      "speed factor": 0.6950968307,
      "Uz factor": 0.9870214395,
      "Directional variance factor": 0.5550301041,
      "Velocity stability factor": 0.2821498396,
      "Direction stability factor": 0.6247373438,
      score: 0.0,
    },
    "90": {
      velocity: [0.1674284522, 0.7101059434, 1.0410294819],
      direction: [-21.3197745129, 36.9023322868, 169.6339759633],
      "speed factor": 1.1511025142,
      "Uz factor": 0.9358518753,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3623957088,
      "Direction stability factor": 0.4695729153,
      score: 0.0,
    },
    "135": {
      velocity: [0.5593024757, 0.9955783149, 1.208648722],
      direction: [-32.8296232407, 11.9166271183, 66.2114082618],
      "speed factor": 0.8426080302,
      "Uz factor": 0.9273683137,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4767497755,
      "Direction stability factor": 0.7248860236,
      score: 0.0,
    },
    "180": {
      velocity: [0.5336014524, 0.9262610606, 1.2691336492],
      direction: [-22.3575749023, 5.5809429366, 34.3217869244],
      "speed factor": 1.8089821292,
      "Uz factor": 0.9084513905,
      "Directional variance factor": 0.5039161834,
      "Velocity stability factor": 0.4204323447,
      "Direction stability factor": 0.8425573283,
      score: 0.0,
    },
    "225": {
      velocity: [0.1216303101, 0.4725676317, 0.8658065152],
      direction: [-40.8581664199, 9.7008541928, 84.9899629003],
      "speed factor": 0.645106533,
      "Uz factor": 0.9795006058,
      "Directional variance factor": 0.1377018495,
      "Velocity stability factor": 0.3311488565,
      "Direction stability factor": 0.650421863,
      score: 0.0,
    },
    "270": {
      velocity: [0.1081397085, 0.3174112019, 0.6303252247],
      direction: [-167.0215016708, -68.9262377635, 27.6072961494],
      "speed factor": 0.2904414988,
      "Uz factor": 0.8905514873,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5880983916,
      "Direction stability factor": 0.4593644505,
      score: 0.0,
    },
    "315": {
      velocity: [0.0765756581, 0.1724341419, 0.4080529989],
      direction: [-178.2389219452, -125.0605566724, 167.6580671635],
      "speed factor": 0.2124560232,
      "Uz factor": 0.9906820891,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7310936583,
      "Direction stability factor": 0.0391750303,
      score: 0.0,
    },
  },
  {
    position: [-35.63762623, 90.14194684, 45.87999908],
    "overall score": 0.0426499458,
    "0": {
      velocity: [0.079133486, 0.4088851924, 1.1186772971],
      direction: [-164.3584814834, -11.2654264189, 170.5553864866],
      "speed factor": 0.4190055299,
      "Uz factor": 0.9848582704,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.277692949,
      "Direction stability factor": 0.0696837001,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4784114344, 1.2799732802, 1.6149680616],
      direction: [-60.8835141102, -1.4196817575, 12.7776226418],
      "speed factor": 1.1364571097,
      "Uz factor": 0.9840241935,
      "Directional variance factor": 0.873806066,
      "Velocity stability factor": 0.265019005,
      "Direction stability factor": 0.7953857312,
      score: 0.6823991334,
    },
    "67.5": {
      velocity: [0.1741438288, 0.6604968094, 1.0741012473],
      direction: [-45.2198059511, -16.4757256067, 50.5953945586],
      "speed factor": 1.0231535898,
      "Uz factor": 0.9684426301,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1958389749,
      "Direction stability factor": 0.7338466653,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2567219039, 0.7040263269, 1.1266103954],
      direction: [-16.3872575507, 41.2989954789, 124.9232709959],
      "speed factor": 0.7919804339,
      "Uz factor": 0.918575908,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2798897531,
      "Direction stability factor": 0.607470754,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.407303724, 0.8176377589, 1.4282650203],
      direction: [-41.7382874668, 23.7210393185, 69.1782832744],
      "speed factor": 1.1615694714,
      "Uz factor": 0.9364496311,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1650665865,
      "Direction stability factor": 0.6918984146,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2994529226, 0.5633727034, 1.0588497645],
      direction: [-27.3692077267, 3.5710255905, 109.6823083832],
      "speed factor": 0.6600786863,
      "Uz factor": 0.8139154282,
      "Directional variance factor": 0.6825755031,
      "Velocity stability factor": 0.4160981778,
      "Direction stability factor": 0.6193013441,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0913268506, 0.3241856145, 0.6670622431],
      direction: [-95.944428554, -7.8515186133, 155.1454346546],
      "speed factor": 0.6014033812,
      "Uz factor": 0.9778602417,
      "Directional variance factor": 0.3020872344,
      "Velocity stability factor": 0.4413363997,
      "Direction stability factor": 0.3025281578,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0654837959, 0.4253890587, 0.913294313],
      direction: [-158.7851516845, 0.9550290587, 141.0725499472],
      "speed factor": 0.5792738385,
      "Uz factor": 0.9987206252,
      "Directional variance factor": 0.9151085281,
      "Velocity stability factor": 0.3782668782,
      "Direction stability factor": 0.1670619399,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0543416042, 0.1799586167, 0.3885310639],
      direction: [-179.0822836496, -152.0437826967, 179.7425857662],
      "speed factor": 0.2285362742,
      "Uz factor": 0.9917115547,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7308322889,
      "Direction stability factor": 0.0032642516,
      score: 0.0,
    },
    id: "IDN:368.0",
    "45": {
      velocity: [0.1439699854, 0.5386779329, 0.9876113112],
      direction: [-36.3224154566, -6.5869637711, 90.0841520896],
      "speed factor": 0.7317301602,
      "Uz factor": 0.9796782966,
      "Directional variance factor": 0.4144921092,
      "Velocity stability factor": 0.3141462976,
      "Direction stability factor": 0.6488706457,
      score: 0.0,
    },
    "90": {
      velocity: [0.176325635, 0.7230278915, 1.0440271389],
      direction: [-26.7187607219, 35.8486528736, 174.5492690399],
      "speed factor": 1.1720493703,
      "Uz factor": 0.9467885448,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3667015221,
      "Direction stability factor": 0.4409221396,
      score: 0.0,
    },
    "135": {
      velocity: [0.562670881, 1.0034920025, 1.2041727411],
      direction: [-33.8378245746, 10.4926059974, 66.2693551107],
      "speed factor": 0.8493057823,
      "Uz factor": 0.9377529833,
      "Directional variance factor": 0.0673239113,
      "Velocity stability factor": 0.4830708668,
      "Direction stability factor": 0.7219245009,
      score: 0.0,
    },
    "180": {
      velocity: [0.5364763625, 0.9369658405, 1.2919942567],
      direction: [-22.6762393313, 5.8842664267, 35.8403711801],
      "speed factor": 1.8298884983,
      "Uz factor": 0.9257452987,
      "Directional variance factor": 0.4769540954,
      "Velocity stability factor": 0.4046844769,
      "Direction stability factor": 0.8374538597,
      score: 0.0,
    },
    "225": {
      velocity: [0.124259834, 0.4812663609, 0.8498169183],
      direction: [-42.7570179873, 14.8551327006, 95.3892786817],
      "speed factor": 0.6569812503,
      "Uz factor": 0.9769366249,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3478833613,
      "Direction stability factor": 0.616260287,
      score: 0.0,
    },
    "270": {
      velocity: [0.0819596246, 0.3017231949, 0.6592381455],
      direction: [-168.309552607, -70.0311028306, 170.4660644111],
      "speed factor": 0.2760864659,
      "Uz factor": 0.9275884392,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5446408529,
      "Direction stability factor": 0.0589566194,
      score: 0.0,
    },
    "315": {
      velocity: [0.0546657476, 0.1670376777, 0.4504405169],
      direction: [-177.9161621845, -132.0792050904, 176.9135296358],
      "speed factor": 0.2058070422,
      "Uz factor": 0.9937812118,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6789332717,
      "Direction stability factor": 0.0143619672,
      score: 0.0,
    },
  },
  {
    position: [-36.3076623, 89.78439987, 45.87999908],
    "overall score": 0.042485883,
    "0": {
      velocity: [0.0850722553, 0.3614025157, 1.0484311975],
      direction: [-172.409794791, -19.0577386132, 154.5622306172],
      "speed factor": 0.3703476072,
      "Uz factor": 0.9560592731,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3306285419,
      "Direction stability factor": 0.0917443739,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4674735646, 1.1448007639, 1.5440191768],
      direction: [-57.0221263782, -2.0973982019, 14.6709066717],
      "speed factor": 1.0164407238,
      "Uz factor": 0.9915518757,
      "Directional variance factor": 0.8135646043,
      "Velocity stability factor": 0.3038265351,
      "Direction stability factor": 0.800852686,
      score: 0.6797741278,
    },
    "67.5": {
      velocity: [0.1467173545, 0.6100549614, 1.0567240755],
      direction: [-47.4705859833, -14.6693779036, 55.4189617653],
      "speed factor": 0.9450158045,
      "Uz factor": 0.9847464966,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1868593751,
      "Direction stability factor": 0.7141957007,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2361847441, 0.6889543598, 1.1466340411],
      direction: [-14.5290809716, 44.0993812766, 124.9323523926],
      "speed factor": 0.7750255239,
      "Uz factor": 0.8841751158,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2463127465,
      "Direction stability factor": 0.6126071295,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.365687105, 0.8152548191, 1.4873301564],
      direction: [-40.3490303396, 24.0385954927, 65.0398420939],
      "speed factor": 1.1581841701,
      "Uz factor": 0.8969899574,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0827299085,
      "Direction stability factor": 0.7072531321,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2903014759, 0.5970750505, 1.0768643476],
      direction: [-45.9596918209, -3.8248648848, 98.0441688607],
      "speed factor": 0.6995662242,
      "Uz factor": 0.7251490202,
      "Directional variance factor": 0.6600120102,
      "Velocity stability factor": 0.3952101606,
      "Direction stability factor": 0.5999892759,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0925940005, 0.3204817925, 0.6380780358],
      direction: [-156.5212446597, -11.9262680751, 74.8478656654],
      "speed factor": 0.5945323451,
      "Uz factor": 0.9689826326,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4706907391,
      "Direction stability factor": 0.3573080269,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0395572651, 0.3975782743, 0.8803483289],
      direction: [-156.1268859924, 2.4752967858, 134.1059700225],
      "speed factor": 0.5414024841,
      "Uz factor": 0.9999212074,
      "Directional variance factor": 0.779973619,
      "Velocity stability factor": 0.3834145221,
      "Direction stability factor": 0.1937976222,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0401967722, 0.1758276403, 0.3932916297],
      direction: [-179.3518754345, -155.6843197316, 179.8578848627],
      "speed factor": 0.2232901906,
      "Uz factor": 0.9756652363,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7156052297,
      "Direction stability factor": 0.0021951103,
      score: 0.0,
    },
    id: "IDN:366.0",
    "45": {
      velocity: [0.0653003143, 0.4839659503, 0.9140665692],
      direction: [-38.7020901152, -4.2281470386, 107.4031779579],
      "speed factor": 0.657410413,
      "Uz factor": 0.9934195548,
      "Directional variance factor": 0.6241647077,
      "Velocity stability factor": 0.3099798924,
      "Direction stability factor": 0.5941520331,
      score: 0.0,
    },
    "90": {
      velocity: [0.1566614492, 0.703019171, 1.0485132183],
      direction: [-67.0042782703, 39.7747408571, 167.1461341025],
      "speed factor": 1.1396146487,
      "Uz factor": 0.9203447064,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3490752692,
      "Direction stability factor": 0.3495821879,
      score: 0.0,
    },
    "135": {
      velocity: [0.5672332866, 1.0026026917, 1.2116418978],
      direction: [-32.0766140394, 13.0416268805, 67.9827374137],
      "speed factor": 0.8485531138,
      "Uz factor": 0.9088676351,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4807285754,
      "Direction stability factor": 0.7220573571,
      score: 0.0,
    },
    "180": {
      velocity: [0.528875393, 0.9209030095, 1.2775289756],
      direction: [-21.743793434, 5.6738890768, 32.4561699819],
      "speed factor": 1.7985178886,
      "Uz factor": 0.8981083282,
      "Directional variance factor": 0.4956543043,
      "Velocity stability factor": 0.4100932584,
      "Direction stability factor": 0.8494445461,
      score: 0.0,
    },
    "225": {
      velocity: [0.0916098267, 0.4548289038, 0.8402231557],
      direction: [-38.2459225328, 6.0711660168, 80.9303267416],
      "speed factor": 0.6208912281,
      "Uz factor": 0.9775872527,
      "Directional variance factor": 0.4603407985,
      "Velocity stability factor": 0.3271608555,
      "Direction stability factor": 0.6689548631,
      score: 0.0,
    },
    "270": {
      velocity: [0.0996314315, 0.3297658357, 0.6392717108],
      direction: [-162.4377019612, -69.8858437148, 36.0749081805],
      "speed factor": 0.3017463876,
      "Uz factor": 0.8648383163,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5743300185,
      "Direction stability factor": 0.4485760829,
      score: 0.0,
    },
    "315": {
      velocity: [0.0666828573, 0.1761392279, 0.3375237297],
      direction: [-176.4204148853, -122.0248392884, 178.5613721687],
      "speed factor": 0.2170210579,
      "Uz factor": 0.9933976352,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7802841425,
      "Direction stability factor": 0.0139394804,
      score: 0.0,
    },
  },
  {
    position: [-35.4946478, 93.51136883, 45.87999908],
    "overall score": 0.0418400691,
    "0": {
      velocity: [0.1056177481, 0.4264840476, 1.2515081548],
      direction: [-156.9229878904, -32.4062458053, 125.2354409559],
      "speed factor": 0.4370399753,
      "Uz factor": 0.9538706354,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2038000596,
      "Direction stability factor": 0.2162265865,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3084206734, 1.2946781767, 1.5541408496],
      direction: [-53.8989896774, 1.5102822851, 14.935548421],
      "speed factor": 1.1495132292,
      "Uz factor": 0.9679773727,
      "Directional variance factor": 0.8657526858,
      "Velocity stability factor": 0.1944258362,
      "Direction stability factor": 0.8087929497,
      score: 0.6694411054,
    },
    "67.5": {
      velocity: [0.231815719, 0.6547694212, 1.0822085933],
      direction: [-37.7005722479, -9.0667280122, 40.5977853508],
      "speed factor": 1.0142814836,
      "Uz factor": 0.9258068438,
      "Directional variance factor": 0.1940686211,
      "Velocity stability factor": 0.2401275978,
      "Direction stability factor": 0.7825045622,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0845365035, 0.7023829312, 1.2050560272],
      direction: [-82.1015064551, 32.1454366033, 173.7712813817],
      "speed factor": 0.7901317285,
      "Uz factor": 0.9703577773,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0724126152,
      "Direction stability factor": 0.289242256,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0871585251, 0.5709520591, 1.0895432586],
      direction: [-29.7156312612, 12.3216816094, 134.7633803319],
      "speed factor": 0.8111177281,
      "Uz factor": 0.9856556568,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1802583406,
      "Direction stability factor": 0.5431138567,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1365519001, 0.6221185356, 1.203884373],
      direction: [-175.0878353847, 87.1242221643, 146.2333515022],
      "speed factor": 0.7289085595,
      "Uz factor": 0.6306410011,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1793258262,
      "Direction stability factor": 0.1074411475,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0586300969, 0.3014396233, 0.639435106],
      direction: [-173.1874872767, 0.9268698558, 95.8319834667],
      "speed factor": 0.5592068265,
      "Uz factor": 0.9803743134,
      "Directional variance factor": 0.9176115684,
      "Velocity stability factor": 0.4364171081,
      "Direction stability factor": 0.2527236924,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.13635643, 0.5064498254, 0.9809118218],
      direction: [-73.4945559824, -6.7099200174, 88.2786666709],
      "speed factor": 0.6896583925,
      "Uz factor": 0.9868959515,
      "Directional variance factor": 0.4035626651,
      "Velocity stability factor": 0.3806539909,
      "Direction stability factor": 0.5506299371,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0846942904, 0.2234968867, 0.4742460748],
      direction: [-179.9720357613, -141.3402150718, 176.2669613047],
      "speed factor": 0.283827175,
      "Uz factor": 0.9977194405,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6862415641,
      "Direction stability factor": 0.0104472304,
      score: 0.0,
    },
    id: "IDN:329.0",
    "45": {
      velocity: [0.1193923596, 0.5852250738, 0.9213511799],
      direction: [-151.6026048928, -10.2338593834, 51.3400175682],
      "speed factor": 0.7949589372,
      "Uz factor": 0.9729727626,
      "Directional variance factor": 0.0903236104,
      "Velocity stability factor": 0.3480328556,
      "Direction stability factor": 0.4362704932,
      score: 0.0,
    },
    "90": {
      velocity: [0.3321314206, 0.7388588475, 1.0587310959],
      direction: [-39.2627872174, 32.9671549491, 82.3591922647],
      "speed factor": 1.1977118131,
      "Uz factor": 0.9739592976,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4696857545,
      "Direction stability factor": 0.6621611681,
      score: 0.0,
    },
    "135": {
      velocity: [0.2390608099, 1.0810783263, 1.3167843945],
      direction: [-20.4939829628, 8.6111208957, 84.9598858395],
      "speed factor": 0.9149709926,
      "Uz factor": 0.9577897977,
      "Directional variance factor": 0.2345670315,
      "Velocity stability factor": 0.1315586858,
      "Direction stability factor": 0.7070725867,
      score: 0.0,
    },
    "180": {
      velocity: [0.0561669596, 0.4294314667, 0.9914916145],
      direction: [-171.6546380561, 5.3431379381, 158.9346327134],
      "speed factor": 0.8386770015,
      "Uz factor": 0.9763378315,
      "Directional variance factor": 0.5250544055,
      "Velocity stability factor": 0.2630045027,
      "Direction stability factor": 0.0816964701,
      score: 0.0,
    },
    "225": {
      velocity: [0.1275830248, 0.4570295881, 0.8420158251],
      direction: [-126.3563555373, 34.8417798449, 153.1302697552],
      "speed factor": 0.623895403,
      "Uz factor": 0.9155103918,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.357881652,
      "Direction stability factor": 0.2236482631,
      score: 0.0,
    },
    "270": {
      velocity: [0.0977593134, 0.3813529258, 0.8767282056],
      direction: [-170.79855078, -34.4475574781, 27.5722983459],
      "speed factor": 0.3489502409,
      "Uz factor": 0.9120844289,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3855468418,
      "Direction stability factor": 0.4489698635,
      score: 0.0,
    },
    "315": {
      velocity: [0.0651266111, 0.2215307573, 0.3858446564],
      direction: [-178.0325961915, -147.6568898955, 176.8093546094],
      "speed factor": 0.2729479393,
      "Uz factor": 0.9808316158,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7398219858,
      "Direction stability factor": 0.0143279144,
      score: 0.0,
    },
  },
  {
    position: [-35.95052285, 97.41273697, 45.87999908],
    "overall score": 0.0414855351,
    "0": {
      velocity: [0.5095585464, 1.0281560633, 1.2980943908],
      direction: [-67.1452969421, -37.2806629552, 22.7315909722],
      "speed factor": 1.0536040046,
      "Uz factor": 0.904334594,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4521010136,
      "Direction stability factor": 0.750341978,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7215345569, 1.2022058341, 1.4514519091],
      direction: [-31.3534662429, -1.2437315165, 15.0383494096],
      "speed factor": 1.0674092879,
      "Uz factor": 0.9474640078,
      "Directional variance factor": 0.8894460874,
      "Velocity stability factor": 0.5279818278,
      "Direction stability factor": 0.8711338454,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3153289124, 0.6458561991, 1.0409990409],
      direction: [-39.1546989803, -2.2914628865, 49.551881785],
      "speed factor": 1.0004743084,
      "Uz factor": 0.9642248842,
      "Directional variance factor": 0.7963144101,
      "Velocity stability factor": 0.3515741718,
      "Direction stability factor": 0.7535928312,
      score: 0.6637685611,
    },
    "112.5": {
      velocity: [0.073668676, 0.7414914781, 1.2468277857],
      direction: [-110.5828872542, 34.0564720327, 145.9254496417],
      "speed factor": 0.8341261116,
      "Uz factor": 0.9943550679,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0288365642,
      "Direction stability factor": 0.287476842,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0957029836, 0.5047534711, 1.0309785117],
      direction: [-166.7186485173, 7.9155900313, 157.2224892629],
      "speed factor": 0.7170733204,
      "Uz factor": 0.9887219188,
      "Directional variance factor": 0.2963919972,
      "Velocity stability factor": 0.2351396747,
      "Direction stability factor": 0.1001635062,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0582516382, 0.4507003901, 0.8965753573],
      direction: [-163.4197407722, 37.2705937136, 152.6441492764],
      "speed factor": 0.5280655588,
      "Uz factor": 0.6532926021,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3554111366,
      "Direction stability factor": 0.1220447499,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1229584281, 0.3442989135, 0.759621312],
      direction: [-91.5215804617, -19.2477846994, 53.3136432984],
      "speed factor": 0.6387159747,
      "Uz factor": 0.9824949638,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3822155394,
      "Direction stability factor": 0.597679934,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2590775623, 0.6574409706, 1.143819201],
      direction: [-83.5754473965, -3.5323590525, 45.8468597019],
      "speed factor": 0.8952706867,
      "Uz factor": 0.9717412461,
      "Directional variance factor": 0.6860125287,
      "Velocity stability factor": 0.3511838201,
      "Direction stability factor": 0.6404935914,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7815764648, 1.0227988098, 1.3670679008],
      direction: [-34.408150111, -21.070679922, 2.4354353541],
      "speed factor": 1.2988910092,
      "Uz factor": 0.905158782,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5284250143,
      "Direction stability factor": 0.897656707,
      score: 0.0,
    },
    id: "IDN:333.0",
    "45": {
      velocity: [0.3494001786, 0.6169233366, 1.0023281808],
      direction: [-70.0336617419, -22.6619586514, 32.1005681515],
      "speed factor": 0.8380172723,
      "Uz factor": 0.9198846812,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4691901949,
      "Direction stability factor": 0.7162938059,
      score: 0.0,
    },
    "90": {
      velocity: [0.2887417655, 0.739009848, 1.2273990524],
      direction: [-17.2239261214, 37.3527334419, 82.9426693552],
      "speed factor": 1.1979565893,
      "Uz factor": 0.9919272153,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3149139095,
      "Direction stability factor": 0.721759457,
      score: 0.0,
    },
    "135": {
      velocity: [0.1105387651, 1.0150501138, 1.2721748372],
      direction: [-4.4848426414, 14.713074305, 106.5591796473],
      "speed factor": 0.8590879935,
      "Uz factor": 0.9778153538,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0639410963,
      "Direction stability factor": 0.6915443825,
      score: 0.0,
    },
    "180": {
      velocity: [0.0766028112, 0.4024462537, 1.0718108262],
      direction: [-112.3668540036, 11.4904900389, 144.290723609],
      "speed factor": 0.7859750473,
      "Uz factor": 0.9614339961,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.215818997,
      "Direction stability factor": 0.2870622844,
      score: 0.0,
    },
    "225": {
      velocity: [0.1713635302, 0.6074904376, 1.0235895217],
      direction: [-20.679356063, -2.5475299902, 68.7944199496],
      "speed factor": 0.8292909283,
      "Uz factor": 0.9891772768,
      "Directional variance factor": 0.7735528898,
      "Velocity stability factor": 0.2340358036,
      "Direction stability factor": 0.7514617333,
      score: 0.0,
    },
    "270": {
      velocity: [0.2656554569, 0.7640229161, 1.3370095793],
      direction: [-89.1277086285, -5.2492173565, 30.0136714327],
      "speed factor": 0.6991056382,
      "Uz factor": 0.9997514111,
      "Directional variance factor": 0.5334029016,
      "Velocity stability factor": 0.1549124354,
      "Direction stability factor": 0.6690517221,
      score: 0.0,
    },
    "315": {
      velocity: [0.8249133764, 0.9767376703, 1.1386356679],
      direction: [-16.5363170641, -6.5023324806, 3.7860690087],
      "speed factor": 1.2034380128,
      "Uz factor": 0.9087825963,
      "Directional variance factor": 0.4220148906,
      "Velocity stability factor": 0.7454971929,
      "Direction stability factor": 0.9435489276,
      score: 0.0,
    },
  },
  {
    position: [-47.1184631, 79.54707423, 44.87999908],
    "overall score": 0.041421562,
    "0": {
      velocity: [0.0188504307, 0.2052271544, 0.5156029786],
      direction: [-179.6541645034, 131.6714649558, 176.8635312255],
      "speed factor": 0.2103067418,
      "Uz factor": 0.9077404458,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.654841033,
      "Direction stability factor": 0.0096730674,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0381772665, 0.298820135, 0.5648041349],
      direction: [-178.8972683047, 55.924366098, 148.7679757081],
      "speed factor": 0.265315122,
      "Uz factor": 0.9656453458,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6594443863,
      "Direction stability factor": 0.0898187666,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0617129952, 0.2249378551, 0.415070265],
      direction: [-99.1167287405, -5.689740994, 34.0827677257],
      "speed factor": 0.3484437331,
      "Uz factor": 0.999958705,
      "Directional variance factor": 0.494245245,
      "Velocity stability factor": 0.684256012,
      "Direction stability factor": 0.6300013987,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1973105429, 0.5777392541, 0.9162387626],
      direction: [-43.8868433568, -15.0556747368, 68.3041292456],
      "speed factor": 0.6499162995,
      "Uz factor": 0.9411727328,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4048575389,
      "Direction stability factor": 0.6883584094,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1351557224, 0.565282628, 0.8192631678],
      direction: [-78.7608998599, -31.1914927511, 56.4995480067],
      "speed factor": 0.8030635036,
      "Uz factor": 0.8083248331,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4405427839,
      "Direction stability factor": 0.6242765337,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1481880941, 0.4715751754, 0.8737469136],
      direction: [-76.133034638, 21.2420810948, 55.8108175919],
      "speed factor": 0.5525236142,
      "Uz factor": 0.9152868683,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4421163041,
      "Direction stability factor": 0.6334892994,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1877409494, 0.3751900044, 0.6383678248],
      direction: [-8.2302881695, 3.6434040238, 19.166084142],
      "speed factor": 0.696022671,
      "Uz factor": 0.9666860245,
      "Directional variance factor": 0.6761418646,
      "Velocity stability factor": 0.5627351803,
      "Direction stability factor": 0.9238989658,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0113996152, 0.3183408864, 0.7132528972],
      direction: [-114.6488281165, -24.7364140308, 162.5958131144],
      "speed factor": 0.4335009176,
      "Uz factor": 0.9905151473,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4853031153,
      "Direction stability factor": 0.2298759966,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0263925415, 0.1178080854, 0.3746403759],
      direction: [-160.9524298864, 32.2429897452, 175.8854210747],
      "speed factor": 0.1496089568,
      "Uz factor": 0.9623453997,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7195091893,
      "Direction stability factor": 0.0643393029,
      score: 0.0,
    },
    id: "IDN:45.0",
    "45": {
      velocity: [0.026207983, 0.1298147045, 0.4207188963],
      direction: [-120.0812469654, 16.7404510593, 170.8185458321],
      "speed factor": 0.1763378983,
      "Uz factor": 0.9943506002,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6792751111,
      "Direction stability factor": 0.19194502,
      score: 0.0,
    },
    "90": {
      velocity: [0.2050748174, 0.6751191309, 1.0107170378],
      direction: [-33.8132759168, -4.8346880379, 25.8145933016],
      "speed factor": 1.0943878674,
      "Uz factor": 0.9824191517,
      "Directional variance factor": 0.5702499522,
      "Velocity stability factor": 0.4119959576,
      "Direction stability factor": 0.8343670299,
      score: 0.6627449924,
    },
    "135": {
      velocity: [0.2727263255, 0.6801903083, 0.9101487596],
      direction: [-101.3208876305, -40.0200075273, -11.6488555456],
      "speed factor": 0.5756792883,
      "Uz factor": 0.9686398884,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4863581124,
      "Direction stability factor": 0.750911022,
      score: 0.0,
    },
    "180": {
      velocity: [0.2692416086, 0.5376185938, 0.8338503688],
      direction: [-84.9334601795, -54.038074415, -7.9232745961],
      "speed factor": 1.0499657924,
      "Uz factor": 0.8250038468,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5551126426,
      "Direction stability factor": 0.7860828178,
      score: 0.0,
    },
    "225": {
      velocity: [0.3092176066, 0.5894976749, 0.8680952282],
      direction: [-5.3212703531, 19.4509532434, 32.5765124915],
      "speed factor": 0.8047288381,
      "Uz factor": 0.9710448962,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4976916303,
      "Direction stability factor": 0.894728381,
      score: 0.0,
    },
    "270": {
      velocity: [0.0183073405, 0.3826059884, 1.0387845337],
      direction: [-178.061199371, -0.1017965977, 168.0896819548],
      "speed factor": 0.3500968335,
      "Uz factor": 0.9985596253,
      "Directional variance factor": 0.9909514135,
      "Velocity stability factor": 0.1950443202,
      "Direction stability factor": 0.0384697741,
      score: 0.0,
    },
    "315": {
      velocity: [0.0179567685, 0.0890821757, 0.2198774686],
      direction: [-179.1047372468, 100.563991206, 174.4615949811],
      "speed factor": 0.1097581058,
      "Uz factor": 0.9504025096,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8361946652,
      "Direction stability factor": 0.0178712994,
      score: 0.0,
    },
  },
  {
    position: [-37.47491052, 89.78847435, 46.87999908],
    "overall score": 0.0409617418,
    "0": {
      velocity: [0.1264208131, 0.4164808392, 1.0259568152],
      direction: [-177.6080456314, -28.072778663, 151.3989716214],
      "speed factor": 0.4267891769,
      "Uz factor": 0.9793688973,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.374974686,
      "Direction stability factor": 0.0860916187,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4056958145, 1.2574037211, 1.5681925802],
      direction: [-56.2639525659, 2.3993505552, 18.1511299256],
      "speed factor": 1.1164181477,
      "Uz factor": 0.9961337669,
      "Directional variance factor": 0.7867243951,
      "Velocity stability factor": 0.2482442062,
      "Direction stability factor": 0.7932914375,
      score: 0.6553878691,
    },
    "67.5": {
      velocity: [0.2193215399, 0.6389298269, 1.0694179537],
      direction: [-37.3898538527, -8.6627273261, 59.3895730357],
      "speed factor": 0.9897448962,
      "Uz factor": 0.9906621491,
      "Directional variance factor": 0.2299797932,
      "Velocity stability factor": 0.2403925014,
      "Direction stability factor": 0.7311682586,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1823432168, 0.7048751477, 1.1393491523],
      direction: [-7.5524235242, 30.7403548398, 127.4553800462],
      "speed factor": 0.792935298,
      "Uz factor": 0.9125282309,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2077722751,
      "Direction stability factor": 0.6249783234,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4235083383, 0.8109071126, 1.2584860792],
      direction: [-36.8466629254, 11.7591780532, 48.8854274486],
      "speed factor": 1.1520076414,
      "Uz factor": 0.9215902397,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.317162347,
      "Direction stability factor": 0.7618553045,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3782971772, 0.6525481052, 1.0802302291],
      direction: [-63.5626184536, -24.45792652, 24.4116849313],
      "speed factor": 0.7645615299,
      "Uz factor": 0.757587347,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4602822062,
      "Direction stability factor": 0.755626935,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1145938321, 0.3914872165, 0.650633906],
      direction: [-129.3593492813, -16.1897674703, 44.2595111078],
      "speed factor": 0.7262559633,
      "Uz factor": 0.9932173414,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4798546668,
      "Direction stability factor": 0.5177253878,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0425316695, 0.4789467577, 0.9265730533],
      direction: [-169.3053143161, -11.2717676062, 103.5129885006],
      "speed factor": 0.6522060715,
      "Uz factor": 0.9945635546,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3516973449,
      "Direction stability factor": 0.2421713811,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0304628273, 0.1510811756, 0.3264195317],
      direction: [-176.6965808366, -139.5813096061, 177.7783693768],
      "speed factor": 0.1918637163,
      "Uz factor": 0.997116664,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.761626268,
      "Direction stability factor": 0.0153473605,
      score: 0.0,
    },
    id: "IDN:455.0",
    "45": {
      velocity: [0.1375141302, 0.5464936282, 0.8921375545],
      direction: [-70.1554791422, -6.5224262642, 74.8486617399],
      "speed factor": 0.7423468564,
      "Uz factor": 0.9972014438,
      "Directional variance factor": 0.4202287765,
      "Velocity stability factor": 0.3865150347,
      "Direction stability factor": 0.5972107198,
      score: 0.0,
    },
    "90": {
      velocity: [0.2522894703, 0.7325798085, 1.1263080185],
      direction: [-33.0830669913, 28.491229599, 167.7790205793],
      "speed factor": 1.1875333071,
      "Uz factor": 0.9607102511,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3620909797,
      "Direction stability factor": 0.4420497567,
      score: 0.0,
    },
    "135": {
      velocity: [0.5830652452, 0.9999130997, 1.2277310882],
      direction: [-29.7619100234, 7.2039633943, 41.3483339766],
      "speed factor": 0.8462767767,
      "Uz factor": 0.941730428,
      "Directional variance factor": 0.3596476983,
      "Velocity stability factor": 0.4805212952,
      "Direction stability factor": 0.8024715444,
      score: 0.0,
    },
    "180": {
      velocity: [0.5896329476, 0.8910502204, 1.1822380878],
      direction: [-20.6455714897, -2.5659872239, 20.7711518613],
      "speed factor": 1.7402155759,
      "Uz factor": 0.9075153384,
      "Directional variance factor": 0.7719122468,
      "Velocity stability factor": 0.5330527023,
      "Direction stability factor": 0.8849535462,
      score: 0.0,
    },
    "225": {
      velocity: [0.1552993541, 0.6148120632, 0.9436781734],
      direction: [-45.0363939552, -2.2974170075, 22.9515118762],
      "speed factor": 0.8392857485,
      "Uz factor": 0.9943078108,
      "Directional variance factor": 0.7957851549,
      "Velocity stability factor": 0.2914204039,
      "Direction stability factor": 0.811144706,
      score: 0.0,
    },
    "270": {
      velocity: [0.0927566683, 0.3241180218, 0.6953196707],
      direction: [-139.4932302801, -72.3991817006, -5.4438149703],
      "speed factor": 0.2965784555,
      "Uz factor": 0.9623118382,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.524696373,
      "Direction stability factor": 0.627640513,
      score: 0.0,
    },
    "315": {
      velocity: [0.0210415156, 0.2007770443, 0.8256722973],
      direction: [-145.3615988995, -13.5006725298, 37.886124147],
      "speed factor": 0.2473772995,
      "Uz factor": 0.9961266361,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3472545684,
      "Direction stability factor": 0.4909785471,
      score: 0.0,
    },
  },
  {
    position: [-36.39892114, 97.47700053, 45.87999908],
    "overall score": 0.040954487,
    "0": {
      velocity: [0.523510802, 1.0487809823, 1.309548224],
      direction: [-66.4452644761, -36.7691319824, 20.92315032],
      "speed factor": 1.0747394121,
      "Uz factor": 0.8756614753,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4538369945,
      "Direction stability factor": 0.7573099589,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6983024929, 1.2075152273, 1.4606836738],
      direction: [-32.7966995062, -1.5708991946, 14.8349184126],
      "speed factor": 1.0721233688,
      "Uz factor": 0.9425933747,
      "Directional variance factor": 0.860364516,
      "Velocity stability factor": 0.5069883317,
      "Direction stability factor": 0.8676899502,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.329468473, 0.6628628586, 1.0766398775],
      direction: [-39.0205346996, -2.1771865001, 54.1738807705],
      "speed factor": 1.0268187577,
      "Uz factor": 0.9666318551,
      "Directional variance factor": 0.8064723111,
      "Velocity stability factor": 0.3323616093,
      "Direction stability factor": 0.7411266237,
      score: 0.655271792,
    },
    "112.5": {
      velocity: [0.0608212743, 0.6996521312, 1.1893451784],
      direction: [-131.2176759463, 34.1488852092, 168.089221607],
      "speed factor": 0.7870597693,
      "Uz factor": 0.9961600882,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0657864368,
      "Direction stability factor": 0.1685919512,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0787874444, 0.4842823079, 1.0223201081],
      direction: [-177.9173689242, 7.5093068821, 163.7028531082],
      "speed factor": 0.6879911528,
      "Uz factor": 0.990617432,
      "Directional variance factor": 0.3325060549,
      "Velocity stability factor": 0.2283870598,
      "Direction stability factor": 0.0510549388,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0588621913, 0.4553188743, 0.9239776472],
      direction: [-162.7306545864, 33.1330776762, 175.4724527556],
      "speed factor": 0.5334768308,
      "Uz factor": 0.7837374186,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3348109141,
      "Direction stability factor": 0.0605469241,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.104570211, 0.3505223998, 0.7961975365],
      direction: [-95.1378860052, -23.4420318585, 48.6717685055],
      "speed factor": 0.6502612918,
      "Uz factor": 0.9860648862,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3288809117,
      "Direction stability factor": 0.6005287375,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2208103022, 0.6694074144, 1.1553200138],
      direction: [-73.6697333883, -3.1734749586, 41.5502384844],
      "speed factor": 0.9115659996,
      "Uz factor": 0.9633159905,
      "Directional variance factor": 0.717913337,
      "Velocity stability factor": 0.3146869158,
      "Direction stability factor": 0.6799445226,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7744732691, 1.010306675, 1.3471690147],
      direction: [-32.8507748458, -19.8320492268, 2.3647730163],
      "speed factor": 1.2830267733,
      "Uz factor": 0.8891260891,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5387311043,
      "Direction stability factor": 0.9021790337,
      score: 0.0,
    },
    id: "IDN:334.0",
    "45": {
      velocity: [0.329391629, 0.6249236732, 1.0129495332],
      direction: [-68.1634885519, -23.4206828426, 29.6459012021],
      "speed factor": 0.8488847818,
      "Uz factor": 0.904240507,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4442890538,
      "Direction stability factor": 0.7283072507,
      score: 0.0,
    },
    "90": {
      velocity: [0.2810131841, 0.7442787776, 1.1345683371],
      direction: [-17.2749254157, 38.0739275692, 90.528257821],
      "speed factor": 1.2064976784,
      "Uz factor": 0.9946723158,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3770263429,
      "Direction stability factor": 0.7005467132,
      score: 0.0,
    },
    "135": {
      velocity: [0.1284503328, 0.9000287706, 1.2208288599],
      direction: [-16.8138730744, 15.9064046603, 100.5099625773],
      "speed factor": 0.7617396424,
      "Uz factor": 0.9805284874,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1197495748,
      "Direction stability factor": 0.6741004565,
      score: 0.0,
    },
    "180": {
      velocity: [0.0346994951, 0.3419899564, 1.0240572086],
      direction: [-144.7256715554, 18.7557461023, 133.2083661746],
      "speed factor": 0.6679042722,
      "Uz factor": 0.958159687,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2204287823,
      "Direction stability factor": 0.2279610063,
      score: 0.0,
    },
    "225": {
      velocity: [0.1613483345, 0.6125927267, 1.0506419012],
      direction: [-25.7375104067, -6.1400694494, 74.2441803682],
      "speed factor": 0.8362561113,
      "Uz factor": 0.9973286903,
      "Directional variance factor": 0.4542160489,
      "Velocity stability factor": 0.2007201858,
      "Direction stability factor": 0.7222730812,
      score: 0.0,
    },
    "270": {
      velocity: [0.2264561186, 0.7670433628, 1.3408147287],
      direction: [-87.1420280269, -4.1791561332, 20.4071957519],
      "speed factor": 0.7018694445,
      "Uz factor": 0.9991860879,
      "Directional variance factor": 0.6285194548,
      "Velocity stability factor": 0.1209903577,
      "Direction stability factor": 0.7012521562,
      score: 0.0,
    },
    "315": {
      velocity: [0.8388913056, 0.9881620742, 1.139176459],
      direction: [-16.8792139198, -5.4513081731, 5.3602532249],
      "speed factor": 1.2175140154,
      "Uz factor": 0.894756443,
      "Directional variance factor": 0.5154392735,
      "Velocity stability factor": 0.7563978827,
      "Direction stability factor": 0.9382237024,
      score: 0.0,
    },
  },
  {
    position: [-53.82729637, 55.863901, 45.87999908],
    "overall score": 0.040417107,
    "0": {
      velocity: [0.141718844, 1.032746245, 1.3705998509],
      direction: [-29.429373607, -2.0006853714, 39.2590025387],
      "speed factor": 1.0583077981,
      "Uz factor": 0.9749011563,
      "Directional variance factor": 0.8221613003,
      "Velocity stability factor": 0.146135635,
      "Direction stability factor": 0.8091989552,
      score: 0.6466737114,
    },
    "22.5": {
      velocity: [0.1110534749, 0.551347878, 1.1730715669],
      direction: [-170.9000405842, 2.7981859361, 112.6542619173],
      "speed factor": 0.4895283562,
      "Uz factor": 0.9941795581,
      "Directional variance factor": 0.7512723612,
      "Velocity stability factor": 0.3132210967,
      "Direction stability factor": 0.2123491597,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0175345098, 0.2452763706, 0.8686648728],
      direction: [-175.4013616917, 14.3490699359, 177.0812117909],
      "speed factor": 0.3799494494,
      "Uz factor": 0.975854252,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2394686114,
      "Direction stability factor": 0.0208817403,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0327779747, 0.2382564614, 0.6475641765],
      direction: [-175.6298363209, 64.111142515, 177.9936050977],
      "speed factor": 0.2680218742,
      "Uz factor": 0.3074636096,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.491068283,
      "Direction stability factor": 0.0177126627,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0532197318, 0.4813590951, 1.0293870143],
      direction: [-175.5269736889, -12.5961208222, 145.5556419524],
      "speed factor": 0.6838383178,
      "Uz factor": 0.9939176745,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2016987478,
      "Direction stability factor": 0.1081038454,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1951361247, 0.6550894965, 1.0872371462],
      direction: [-114.5037834519, -8.7431920934, 24.214521355],
      "speed factor": 0.7675391648,
      "Uz factor": 0.994546887,
      "Directional variance factor": 0.2228273695,
      "Velocity stability factor": 0.3140616561,
      "Direction stability factor": 0.6146713755,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3962772525, 0.744683975, 0.996623967],
      direction: [-47.4546124226, -16.4415332719, 8.1038932969],
      "speed factor": 1.3814785128,
      "Uz factor": 0.9587301624,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4174548564,
      "Direction stability factor": 0.8456708174,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.6785323052, 1.1325751919, 1.3441924463],
      direction: [-41.0893001391, -10.7490346717, 3.7228880887],
      "speed factor": 1.5422850341,
      "Uz factor": 0.8329844774,
      "Directional variance factor": 0.0445302514,
      "Velocity stability factor": 0.5118449828,
      "Direction stability factor": 0.8755216994,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6784780558, 0.924829047, 1.1516422048],
      direction: [-32.8212746511, -5.065841838, 12.5841748892],
      "speed factor": 1.1744754909,
      "Uz factor": 0.9404988394,
      "Directional variance factor": 0.5497029477,
      "Velocity stability factor": 0.6188972834,
      "Direction stability factor": 0.8738737513,
      score: 0.0,
    },
    id: "IDN:380.0",
    "45": {
      velocity: [0.0397306662, 0.4484604535, 1.0436577867],
      direction: [-160.0276807361, 17.549995622, 175.4652151436],
      "speed factor": 0.6091804015,
      "Uz factor": 0.9993286987,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1838390185,
      "Direction stability factor": 0.0680752892,
      score: 0.0,
    },
    "90": {
      velocity: [0.0654669363, 0.298531336, 0.8943281664],
      direction: [-179.0158102871, 136.6537296623, 177.422420013],
      "speed factor": 0.4839280315,
      "Uz factor": 0.9676778368,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3950493885,
      "Direction stability factor": 0.0098938047,
      score: 0.0,
    },
    "135": {
      velocity: [0.0461964059, 0.2275957068, 0.7109079677],
      direction: [-178.7270849802, 20.8609820098, 167.192046674],
      "speed factor": 0.1926257004,
      "Uz factor": 0.7927740537,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4643682383,
      "Direction stability factor": 0.0391135232,
      score: 0.0,
    },
    "180": {
      velocity: [0.1163352447, 0.4269711882, 0.8703891936],
      direction: [-124.1691200807, -37.1166091536, 113.7498966834],
      "speed factor": 0.8338720928,
      "Uz factor": 0.986199882,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4058380027,
      "Direction stability factor": 0.3391138423,
      score: 0.0,
    },
    "225": {
      velocity: [0.172466142, 0.5295607229, 0.9324295351],
      direction: [-106.3901137953, -3.455426641, 40.5633208313],
      "speed factor": 0.7229083395,
      "Uz factor": 0.9518726115,
      "Directional variance factor": 0.6928509652,
      "Velocity stability factor": 0.3169596381,
      "Direction stability factor": 0.5917960149,
      score: 0.0,
    },
    "270": {
      velocity: [0.7856236875, 1.0834415099, 1.45663418],
      direction: [-20.1039323126, -5.5381854445, 16.0625683278],
      "speed factor": 0.9913839655,
      "Uz factor": 0.8478816907,
      "Directional variance factor": 0.5077168494,
      "Velocity stability factor": 0.4707047734,
      "Direction stability factor": 0.8995374982,
      score: 0.0,
    },
    "315": {
      velocity: [0.7913375854, 1.0653498638, 1.3112434933],
      direction: [-29.4545763606, -12.9426749475, 18.0588833578],
      "speed factor": 1.3126170537,
      "Uz factor": 0.8949184244,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5782336271,
      "Direction stability factor": 0.8680181674,
      score: 0.0,
    },
  },
  {
    position: [-45.85689357, 38.73742191, 45.87999908],
    "overall score": 0.0402419833,
    "0": {
      velocity: [0.1616195203, 0.616524373, 1.2421380605],
      direction: [-58.0882381657, -11.7185769892, 69.6850116952],
      "speed factor": 0.6317839981,
      "Uz factor": 0.9972347439,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2492224455,
      "Direction stability factor": 0.6450743059,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1261349941, 0.6604228223, 1.2072149962],
      direction: [-139.3870905168, -2.8704740745, 109.6019838906],
      "speed factor": 0.5863733434,
      "Uz factor": 0.9632274856,
      "Directional variance factor": 0.7448467489,
      "Velocity stability factor": 0.3008942656,
      "Direction stability factor": 0.3083636822,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.3600668617, 0.663476684, 1.0262461469],
      direction: [-32.0148907886, -5.467324538, 32.2616844529],
      "speed factor": 1.0277696143,
      "Uz factor": 0.9426505297,
      "Directional variance factor": 0.5140155966,
      "Velocity stability factor": 0.4047324842,
      "Direction stability factor": 0.8214539577,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4888384031, 0.876530325, 1.2889139804],
      direction: [-61.0148977771, -33.5668025414, 5.4841292119],
      "speed factor": 0.9860353804,
      "Uz factor": 0.9037780304,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.337682212,
      "Direction stability factor": 0.8152804806,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4922656655, 0.911820023, 1.210149269],
      direction: [-40.0868278994, 6.8034699518, 29.1396862325],
      "speed factor": 1.2953686282,
      "Uz factor": 0.9221887269,
      "Directional variance factor": 0.3952471154,
      "Velocity stability factor": 0.4129209307,
      "Direction stability factor": 0.8077041274,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3940350403, 0.8167573267, 1.0974460118],
      direction: [-25.8389225037, -0.3058644438, 22.4014272892],
      "speed factor": 0.9569581557,
      "Uz factor": 0.9750794349,
      "Directional variance factor": 0.9728120494,
      "Velocity stability factor": 0.4591458308,
      "Direction stability factor": 0.8659990284,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0333681111, 0.3410876457, 0.6667830345],
      direction: [-89.8445316108, -18.0904122943, 30.3787502797],
      "speed factor": 0.632758686,
      "Uz factor": 0.9999444628,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.385367191,
      "Direction stability factor": 0.6660464392,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0233133383, 0.1499596814, 0.4222915224],
      direction: [-177.2838908213, 134.1007178219, 174.7772119331],
      "speed factor": 0.2042076976,
      "Uz factor": 0.9557520249,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7074134527,
      "Direction stability factor": 0.0220524923,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0721338571, 0.2930033451, 0.7402177222],
      direction: [-176.783235865, -0.6581827127, 171.1473023805],
      "speed factor": 0.3720960632,
      "Uz factor": 0.999995901,
      "Directional variance factor": 0.94149487,
      "Velocity stability factor": 0.4619022247,
      "Direction stability factor": 0.0335262827,
      score: 0.0,
    },
    id: "IDN:391.0",
    "45": {
      velocity: [0.1227646438, 0.4996301217, 1.0826101492],
      direction: [-126.150916273, 6.7217852824, 101.7158105517],
      "speed factor": 0.6786883343,
      "Uz factor": 0.9418946981,
      "Directional variance factor": 0.4025079749,
      "Velocity stability factor": 0.2196759766,
      "Direction stability factor": 0.3670368699,
      score: 0.0,
    },
    "90": {
      velocity: [0.5672616308, 0.9753852389, 1.2390601704],
      direction: [-67.8773441341, -20.167264886, 29.6752325318],
      "speed factor": 1.5811280152,
      "Uz factor": 0.9164688178,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5096827762,
      "Direction stability factor": 0.7290206204,
      score: 0.0,
    },
    "135": {
      velocity: [0.0804517587, 0.6268929595, 1.0202801753],
      direction: [-118.6205867589, -44.3762818555, 42.4776309894],
      "speed factor": 0.5305710599,
      "Uz factor": 0.9056125998,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2426761028,
      "Direction stability factor": 0.5525049507,
      score: 0.0,
    },
    "180": {
      velocity: [0.4155411475, 0.8267651049, 1.1076136475],
      direction: [-26.9451180522, 6.6447648458, 24.9928044196],
      "speed factor": 1.6146671424,
      "Uz factor": 0.96453647,
      "Directional variance factor": 0.4093542359,
      "Velocity stability factor": 0.4546767119,
      "Direction stability factor": 0.8557279931,
      score: 0.6438717335,
    },
    "225": {
      velocity: [0.0246800843, 0.3404885452, 0.7644659341],
      direction: [-149.3713373242, -4.7239207466, 130.0311752288],
      "speed factor": 0.4648041257,
      "Uz factor": 0.995527496,
      "Directional variance factor": 0.5800959336,
      "Velocity stability factor": 0.3350948226,
      "Direction stability factor": 0.2238819096,
      score: 0.0,
    },
    "270": {
      velocity: [0.0284238575, 0.2375902933, 0.5207690873],
      direction: [-168.0784509925, 103.4951492282, 177.1589588541],
      "speed factor": 0.21740279,
      "Uz factor": 0.9915992347,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6116365051,
      "Direction stability factor": 0.0410071949,
      score: 0.0,
    },
    "315": {
      velocity: [0.0451571254, 0.1949252406, 0.7220008453],
      direction: [-169.9828327485, 62.9696099944, 178.6274219099],
      "speed factor": 0.240167295,
      "Uz factor": 0.9955910161,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4509200293,
      "Direction stability factor": 0.0316381815,
      score: 0.0,
    },
  },
  {
    position: [-39.07192354, 89.64835169, 47.87999908],
    "overall score": 0.0402209776,
    "0": {
      velocity: [0.1427969877, 0.5440243851, 1.1397523056],
      direction: [-170.3244229827, -20.6325649228, 176.2809240571],
      "speed factor": 0.5574895595,
      "Uz factor": 0.9850511723,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3072847455,
      "Direction stability factor": 0.0372073693,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3325684919, 1.2316125715, 1.5430636128],
      direction: [-41.6207609586, 2.4521948575, 34.895349866],
      "speed factor": 1.093518814,
      "Uz factor": 0.9980597637,
      "Directional variance factor": 0.7820271238,
      "Velocity stability factor": 0.2172049443,
      "Direction stability factor": 0.7874552477,
      score: 0.6435356409,
    },
    "67.5": {
      velocity: [0.2283655844, 0.639610893, 1.0767414079],
      direction: [-41.512769279, -5.7026438935, 60.899756532],
      "speed factor": 0.990799913,
      "Uz factor": 0.9950884908,
      "Directional variance factor": 0.4930983206,
      "Velocity stability factor": 0.2419299426,
      "Direction stability factor": 0.7155207616,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2515120725, 0.741130956, 1.1553175442],
      direction: [-8.5182644178, 24.958893189, 77.617823945],
      "speed factor": 0.8337205495,
      "Uz factor": 0.9186143824,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2518126314,
      "Direction stability factor": 0.7607330879,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4508719387, 0.8294089613, 1.2428296753],
      direction: [-31.7617712578, 5.330438024, 39.5847342907],
      "speed factor": 1.1782921204,
      "Uz factor": 0.9339991821,
      "Directional variance factor": 0.5261832868,
      "Velocity stability factor": 0.3523437385,
      "Direction stability factor": 0.8018152624,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3492469271, 0.6775689665, 0.99361687],
      direction: [-54.2443049539, -28.4854914392, 8.2070809043],
      "speed factor": 0.7938773578,
      "Uz factor": 0.8367543049,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5045426013,
      "Direction stability factor": 0.8265239282,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2082794151, 0.4327169764, 0.6787851209],
      direction: [-91.3238204361, -14.3019357553, 52.4588230189],
      "speed factor": 0.8027421363,
      "Uz factor": 0.9991035766,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5434457999,
      "Direction stability factor": 0.6006037682,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0356275561, 0.5406955412, 1.0064277366],
      direction: [-169.9518428491, -15.2065776871, 141.8798855595],
      "speed factor": 0.7362925192,
      "Uz factor": 0.9976402724,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2880736737,
      "Direction stability factor": 0.1338007544,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0351088758, 0.2017153894, 0.5863130036],
      direction: [-173.8226162192, -9.7215068561, 173.9997784663],
      "speed factor": 0.2561660252,
      "Uz factor": 0.9252945953,
      "Directional variance factor": 0.1358660572,
      "Velocity stability factor": 0.5560411942,
      "Direction stability factor": 0.0338266814,
      score: 0.0,
    },
    id: "IDN:720.0",
    "45": {
      velocity: [0.0565232945, 0.5508955904, 0.9326335864],
      direction: [-68.8584802171, -6.6713844352, 87.3676039263],
      "speed factor": 0.7483264006,
      "Uz factor": 0.9986999526,
      "Directional variance factor": 0.4069880502,
      "Velocity stability factor": 0.2877500556,
      "Direction stability factor": 0.5660386552,
      score: 0.0,
    },
    "90": {
      velocity: [0.3153210199, 0.7733520637, 1.1688896146],
      direction: [-92.9583039862, 26.5873310526, 136.6557467511],
      "speed factor": 1.2536263259,
      "Uz factor": 0.9576281512,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3770165324,
      "Direction stability factor": 0.3621831924,
      score: 0.0,
    },
    "135": {
      velocity: [0.6348782465, 1.0236843058, 1.2451046289],
      direction: [-30.0817946816, 4.681394817, 25.8561245255],
      "speed factor": 0.8663955447,
      "Uz factor": 0.9396651472,
      "Directional variance factor": 0.5838760163,
      "Velocity stability factor": 0.5082729849,
      "Direction stability factor": 0.8446168911,
      score: 0.0,
    },
    "180": {
      velocity: [0.5977233229, 0.9012156063, 1.1871549813],
      direction: [-26.1222917838, -8.7532512614, 11.3528856141],
      "speed factor": 1.7600685118,
      "Uz factor": 0.913192486,
      "Directional variance factor": 0.2219332212,
      "Velocity stability factor": 0.5355532692,
      "Direction stability factor": 0.895902285,
      score: 0.0,
    },
    "225": {
      velocity: [0.3533545729, 0.722667446, 1.1191773154],
      direction: [-28.2061091944, -2.5454125167, 20.0347764679],
      "speed factor": 0.9865201492,
      "Uz factor": 0.9991636369,
      "Directional variance factor": 0.7737411096,
      "Velocity stability factor": 0.3116933684,
      "Direction stability factor": 0.8659975398,
      score: 0.0,
    },
    "270": {
      velocity: [0.1088904446, 0.3599679369, 0.8971216088],
      direction: [-133.1833887052, -51.3895327505, -1.8206346149],
      "speed factor": 0.3293822855,
      "Uz factor": 0.990153478,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.378240732,
      "Direction stability factor": 0.6351034609,
      score: 0.0,
    },
    "315": {
      velocity: [0.0853431336, 0.5191578225, 1.0198108891],
      direction: [-5.7489871366, 8.0657870419, 22.5969960324],
      "speed factor": 0.6396541028,
      "Uz factor": 0.9908106674,
      "Directional variance factor": 0.2830411518,
      "Velocity stability factor": 0.2419261452,
      "Direction stability factor": 0.9212611579,
      score: 0.0,
    },
  },
  {
    position: [12.49640175, 96.58909071, 45.87999908],
    "overall score": 0.0398731356,
    "0": {
      velocity: [0.0226569505, 0.3126443536, 0.7764122208],
      direction: [-177.3065147327, -126.2414845118, 167.5942684968],
      "speed factor": 0.3203826294,
      "Uz factor": 0.9872518574,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4762676275,
      "Direction stability factor": 0.0419422688,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0591297541, 0.7014430113, 1.3413807551],
      direction: [-173.849701737, -14.2845834586, 168.0277375243],
      "speed factor": 0.6227941704,
      "Uz factor": 0.9992630387,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1708023217,
      "Direction stability factor": 0.0503404465,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1728961673, 0.4580098137, 0.8141436915],
      direction: [-44.7661004353, -7.4322192081, 59.3179788532],
      "speed factor": 0.7094877347,
      "Uz factor": 0.9448310061,
      "Directional variance factor": 0.3393582926,
      "Velocity stability factor": 0.4270103721,
      "Direction stability factor": 0.7108775575,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4944770475, 1.0077954544, 1.2800458241],
      direction: [-40.0180992697, 0.9331012743, 33.6694919275],
      "speed factor": 1.1336994807,
      "Uz factor": 0.9093108123,
      "Directional variance factor": 0.9170576645,
      "Velocity stability factor": 0.3496912177,
      "Direction stability factor": 0.7953122467,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1280461229, 0.8202174199, 1.2311451589],
      direction: [-26.5781860352, 16.2620337582, 95.9879005976],
      "speed factor": 1.1652342427,
      "Uz factor": 0.9943274368,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0978950458,
      "Direction stability factor": 0.6595386482,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0640796569, 0.3544574121, 0.9279619607],
      direction: [-167.7703598419, -32.7760004796, 174.4886659915],
      "speed factor": 0.415301951,
      "Uz factor": 0.8624753086,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3357590872,
      "Direction stability factor": 0.0492804838,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.036269706, 0.2836244355, 0.7999622031],
      direction: [-165.4529689293, 6.6028963543, 166.1131983714],
      "speed factor": 0.5261575065,
      "Uz factor": 0.9856391408,
      "Directional variance factor": 0.4130758796,
      "Velocity stability factor": 0.2589526273,
      "Direction stability factor": 0.0789828686,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0145888977, 0.2373294467, 0.6748217428],
      direction: [-174.5624016234, -13.4010454227, 178.3897339256],
      "speed factor": 0.3231835347,
      "Uz factor": 0.9523863254,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5158250345,
      "Direction stability factor": 0.0195774013,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0611018013, 0.465748044, 0.9759460991],
      direction: [-92.2322780936, 2.9336878007, 117.3274356974],
      "speed factor": 0.5914711096,
      "Uz factor": 0.9998323696,
      "Directional variance factor": 0.7392277511,
      "Velocity stability factor": 0.2631528658,
      "Direction stability factor": 0.4178896839,
      score: 0.0,
    },
    id: "IDN:324.0",
    "45": {
      velocity: [0.0798023577, 0.6502036365, 0.9960807146],
      direction: [-104.1219849223, -4.660717054, 129.9406689145],
      "speed factor": 0.8832246173,
      "Uz factor": 0.978590729,
      "Directional variance factor": 0.5857140396,
      "Velocity stability factor": 0.2550946898,
      "Direction stability factor": 0.3498259616,
      score: 0.0,
    },
    "90": {
      velocity: [0.5246710734, 0.8535417493, 1.1567165979],
      direction: [-19.7359561405, 8.8839160031, 36.183606728],
      "speed factor": 1.383616153,
      "Uz factor": 0.9089592203,
      "Directional variance factor": 0.2103185775,
      "Velocity stability factor": 0.5386968135,
      "Direction stability factor": 0.8446678809,
      score: 0.0,
    },
    "135": {
      velocity: [0.8371585446, 1.2680462647, 1.4517398961],
      direction: [-10.288914001, 8.3093259662, 28.2808145732],
      "speed factor": 1.073211368,
      "Uz factor": 0.9517132826,
      "Directional variance factor": 0.2613932474,
      "Velocity stability factor": 0.5047637036,
      "Direction stability factor": 0.8928618651,
      score: 0.6379701703,
    },
    "180": {
      velocity: [0.0584983472, 0.3009390368, 0.8870238657],
      direction: [-175.3339113275, 22.310603634, 168.5169839831],
      "speed factor": 0.5877320798,
      "Uz factor": 0.9619635863,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.347157617,
      "Direction stability factor": 0.0448586241,
      score: 0.0,
    },
    "225": {
      velocity: [0.0551719464, 0.3045366032, 0.8549133623],
      direction: [-172.9944198626, -13.7319093298, 142.7678639001],
      "speed factor": 0.4157257905,
      "Uz factor": 0.9868109032,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2812079225,
      "Direction stability factor": 0.1228825451,
      score: 0.0,
    },
    "270": {
      velocity: [0.098442666, 0.6380341173, 1.057911832],
      direction: [-70.6518366631, -0.4431717057, 63.6660931621],
      "speed factor": 0.5838218192,
      "Uz factor": 0.9956447061,
      "Directional variance factor": 0.9606069595,
      "Velocity stability factor": 0.2431676474,
      "Direction stability factor": 0.6268946394,
      score: 0.0,
    },
    "315": {
      velocity: [0.0387626269, 0.2659147634, 0.8290724368],
      direction: [-172.279457322, -41.7605463124, 144.729715664],
      "speed factor": 0.3276334518,
      "Uz factor": 0.999927523,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3588722557,
      "Direction stability factor": 0.1194189639,
      score: 0.0,
    },
  },
  {
    position: [-45.82059079, 49.13735874, 45.87999908],
    "overall score": 0.0388171304,
    "0": {
      velocity: [0.2081424079, 0.7254708384, 1.2560202518],
      direction: [-119.0446455193, -14.1304207091, 88.7283887302],
      "speed factor": 0.7434270028,
      "Uz factor": 0.9964391308,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2719022064,
      "Direction stability factor": 0.4228526826,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1714828495, 0.6546294154, 1.1888848706],
      direction: [-167.4930888291, -2.6745331918, 89.7327318721],
      "speed factor": 0.5812295185,
      "Uz factor": 0.9948747549,
      "Directional variance factor": 0.7622637163,
      "Velocity stability factor": 0.3420731252,
      "Direction stability factor": 0.2854838314,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1412955142, 0.533262992, 0.8783458391],
      direction: [-44.7422654619, 16.4172970164, 103.6032854165],
      "speed factor": 0.8260599247,
      "Uz factor": 0.9171383458,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3414053458,
      "Direction stability factor": 0.5879290253,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5524065135, 0.8998048462, 1.1766283205],
      direction: [-37.1874651151, -3.862663618, 21.5767025011],
      "speed factor": 1.0122175909,
      "Uz factor": 0.8808941141,
      "Directional variance factor": 0.6566521228,
      "Velocity stability factor": 0.4832573094,
      "Direction stability factor": 0.8367662011,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4328970105, 0.9306795553, 1.3457942144],
      direction: [-30.9722192813, -5.9680170083, 11.9854516805],
      "speed factor": 1.3221612472,
      "Uz factor": 0.960852899,
      "Directional variance factor": 0.4695095993,
      "Velocity stability factor": 0.2534404767,
      "Direction stability factor": 0.8806731362,
      score: 0.6210740871,
    },
    "202.5": {
      velocity: [0.0679251652, 0.2791562, 0.5840256782],
      direction: [-168.5097910394, -59.5210819349, 178.3380917893],
      "speed factor": 0.3270748771,
      "Uz factor": 0.9013221011,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6031692346,
      "Direction stability factor": 0.0365336588,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0746013135, 0.2656658725, 0.718182042],
      direction: [-122.6511755729, -17.4527005622, 116.8329839158],
      "speed factor": 0.4928422079,
      "Uz factor": 0.9997878106,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3755028238,
      "Direction stability factor": 0.3347662236,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0111928867, 0.1692191818, 0.4518880486],
      direction: [-176.7971582646, 140.598687731, 177.3268095738],
      "speed factor": 0.2304343353,
      "Uz factor": 0.9442499625,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6768207362,
      "Direction stability factor": 0.0163223116,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0237322179, 0.248160158, 0.6259714697],
      direction: [-178.1801113554, 16.37259704, 179.0227102072],
      "speed factor": 0.3151479988,
      "Uz factor": 0.9200088132,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5149357461,
      "Direction stability factor": 0.0077699401,
      score: 0.0,
    },
    id: "IDN:387.0",
    "45": {
      velocity: [0.1145702346, 0.5061116941, 0.9646063117],
      direction: [-177.8804308151, 1.0062202894, 157.1585046493],
      "speed factor": 0.6874927827,
      "Uz factor": 0.9431723078,
      "Directional variance factor": 0.9105581965,
      "Velocity stability factor": 0.3089475671,
      "Direction stability factor": 0.0693362904,
      score: 0.0,
    },
    "90": {
      velocity: [0.6420345219, 0.9913943981, 1.3373360262],
      direction: [-45.1517057419, 3.3946300036, 27.3204917079],
      "speed factor": 1.6070793307,
      "Uz factor": 0.91442221,
      "Directional variance factor": 0.6982551108,
      "Velocity stability factor": 0.4925289604,
      "Direction stability factor": 0.7986883404,
      score: 0.0,
    },
    "135": {
      velocity: [0.3083679068, 0.6841629909, 1.0901853151],
      direction: [-103.7262172438, -29.9436356603, 16.0725942627],
      "speed factor": 0.5790415696,
      "Uz factor": 0.8693599615,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3700030813,
      "Direction stability factor": 0.6672255236,
      score: 0.0,
    },
    "180": {
      velocity: [0.1094986402, 0.6591727972, 1.1312438292],
      direction: [-160.3951381816, -21.1615812551, 103.6381413641],
      "speed factor": 1.2873604008,
      "Uz factor": 0.9792994152,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1949088481,
      "Direction stability factor": 0.2665742235,
      score: 0.0,
    },
    "225": {
      velocity: [0.0436659279, 0.3142807088, 0.6644882418],
      direction: [-112.9734287891, -6.7921870321, 102.8609019601],
      "speed factor": 0.429027561,
      "Uz factor": 0.9992885184,
      "Directional variance factor": 0.3962500416,
      "Velocity stability factor": 0.4420169421,
      "Direction stability factor": 0.4004601924,
      score: 0.0,
    },
    "270": {
      velocity: [0.0445709851, 0.232998541, 0.5982002185],
      direction: [-172.0874384035, 128.3849980533, 179.5051251152],
      "speed factor": 0.2132011885,
      "Uz factor": 0.9620606516,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5632954867,
      "Direction stability factor": 0.0233539902,
      score: 0.0,
    },
    "315": {
      velocity: [0.0418316263, 0.3441316425, 1.1147400106],
      direction: [-101.8086593295, 33.5022205856, 167.8181066924],
      "speed factor": 0.4240044308,
      "Uz factor": 0.9725840179,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1296181275,
      "Direction stability factor": 0.2510367611,
      score: 0.0,
    },
  },
  {
    position: [12.50687371, 99.58907243, 45.87999908],
    "overall score": 0.0385970182,
    "0": {
      velocity: [0.0867070529, 0.2830704736, 0.913746031],
      direction: [-176.9074331388, -76.6530436043, 177.7696999696],
      "speed factor": 0.2900767649,
      "Uz factor": 0.9692566784,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4253478507,
      "Direction stability factor": 0.0147857414,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1068191093, 0.7083487773, 1.3146579753],
      direction: [-127.0551085716, -22.044593474, 35.1992906611],
      "speed factor": 0.6289256319,
      "Uz factor": 0.9985156766,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2189226738,
      "Direction stability factor": 0.5492933355,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1540912003, 0.4541934904, 0.8274634105],
      direction: [-56.2858891701, -5.1186952543, 102.4377995443],
      "speed factor": 0.7035759955,
      "Uz factor": 0.9480758476,
      "Directional variance factor": 0.5450048663,
      "Velocity stability factor": 0.3983052135,
      "Direction stability factor": 0.5591008647,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6467179592, 1.062429362, 1.3158109949],
      direction: [-28.2088224906, 6.191422339, 33.3732181235],
      "speed factor": 1.19515881,
      "Uz factor": 0.9282408336,
      "Directional variance factor": 0.4496513476,
      "Velocity stability factor": 0.4461120525,
      "Direction stability factor": 0.8289387761,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0695025825, 0.7837696231, 1.2347467431],
      direction: [-108.8057716643, 11.5538866149, 129.9447200409],
      "speed factor": 1.1134550194,
      "Uz factor": 0.9970933537,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0470732946,
      "Direction stability factor": 0.3368041897,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0172726796, 0.3369479748, 0.8929533269],
      direction: [-174.7628286526, -75.6538401309, 175.8009225363],
      "speed factor": 0.3947869238,
      "Uz factor": 0.7140286746,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3266873162,
      "Direction stability factor": 0.0262118023,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0454124061, 0.3414858935, 0.7881176983],
      direction: [-161.403111076, 4.8424906859, 124.474426278],
      "speed factor": 0.6334974837,
      "Uz factor": 0.9998036241,
      "Directional variance factor": 0.5695563835,
      "Velocity stability factor": 0.2793175166,
      "Direction stability factor": 0.2058957296,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0554972011, 0.238502412, 0.6156612854],
      direction: [-179.2197536608, 14.1025935698, 178.8387571262],
      "speed factor": 0.324780821,
      "Uz factor": 0.5717411656,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5892094309,
      "Direction stability factor": 0.0053930256,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0693411056, 0.5046074838, 1.036237642],
      direction: [-107.9185350543, -5.122278637, 101.6093839732],
      "speed factor": 0.6408201863,
      "Uz factor": 0.9992163243,
      "Directional variance factor": 0.5446863434,
      "Velocity stability factor": 0.2212281985,
      "Direction stability factor": 0.4179780027,
      score: 0.0,
    },
    id: "IDN:325.0",
    "45": {
      velocity: [0.1673085595, 0.615999852, 0.9836719975],
      direction: [-104.663427041, -5.5682503673, 32.8590414457],
      "speed factor": 0.8367628278,
      "Uz factor": 0.9824093988,
      "Directional variance factor": 0.5050444118,
      "Velocity stability factor": 0.3363223572,
      "Direction stability factor": 0.6179931431,
      score: 0.0,
    },
    "90": {
      velocity: [0.3549870653, 0.8213802, 1.1365658443],
      direction: [-18.1739691953, 13.7321262824, 37.2351866264],
      "speed factor": 1.3314813403,
      "Uz factor": 0.9224605401,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4295588415,
      "Direction stability factor": 0.8460856783,
      score: 0.0,
    },
    "135": {
      velocity: [0.9003578471, 1.2696915121, 1.4308720574],
      direction: [-8.1123815611, 10.3084322885, 25.3661170456],
      "speed factor": 1.0746038237,
      "Uz factor": 0.9640837974,
      "Directional variance factor": 0.0836949077,
      "Velocity stability factor": 0.5725059147,
      "Direction stability factor": 0.9070041705,
      score: 0.6175522909,
    },
    "180": {
      velocity: [0.0439848936, 0.3347371982, 0.8201546948],
      direction: [-176.2402762343, 5.5893253516, 169.0138183221],
      "speed factor": 0.6537396802,
      "Uz factor": 0.9267738723,
      "Directional variance factor": 0.5031710799,
      "Velocity stability factor": 0.3884116647,
      "Direction stability factor": 0.0409608485,
      score: 0.0,
    },
    "225": {
      velocity: [0.0402668276, 0.2783698253, 0.7315916017],
      direction: [-179.5801932803, -18.226539624, 179.585201688],
      "speed factor": 0.3800052751,
      "Uz factor": 0.9815617662,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3786506979,
      "Direction stability factor": 0.0023183473,
      score: 0.0,
    },
    "270": {
      velocity: [0.2120885439, 0.7040356725, 1.2207053069],
      direction: [-45.8434167858, 5.3874895988, 47.0815644314],
      "speed factor": 0.6442153734,
      "Uz factor": 0.9995756689,
      "Directional variance factor": 0.5211120357,
      "Velocity stability factor": 0.2043998654,
      "Direction stability factor": 0.7418750522,
      score: 0.0,
    },
    "315": {
      velocity: [0.0252005654, 0.2990374752, 0.967224764],
      direction: [-119.371862455, -37.7508964373, 172.043866544],
      "speed factor": 0.3684439291,
      "Uz factor": 0.9965384843,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2357960868,
      "Direction stability factor": 0.1905118639,
      score: 0.0,
    },
  },
  {
    position: [-54.55578191, 52.86786819, 45.87999908],
    "overall score": 0.0368442533,
    "0": {
      velocity: [0.2773460224, 1.0492777677, 1.3992849077],
      direction: [-19.1890275436, -6.6601002709, 29.483186979],
      "speed factor": 1.0752484934,
      "Uz factor": 0.9635397776,
      "Directional variance factor": 0.407991087,
      "Velocity stability factor": 0.2204423142,
      "Direction stability factor": 0.8647994041,
      score: 0.5895080524,
    },
    "22.5": {
      velocity: [0.1364537998, 0.5885982381, 1.1392485584],
      direction: [-124.9052428506, -1.1233322918, 160.0295747488],
      "speed factor": 0.522602044,
      "Uz factor": 0.9941904732,
      "Directional variance factor": 0.9001482407,
      "Velocity stability factor": 0.351519254,
      "Direction stability factor": 0.2085143956,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.042099216, 0.2050318876, 0.6618789369],
      direction: [-179.5916200302, 20.9292582033, 175.8847233924],
      "speed factor": 0.3176080624,
      "Uz factor": 0.9381325637,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4461930248,
      "Direction stability factor": 0.0125657127,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0208466243, 0.2776910957, 0.6813216273],
      direction: [-179.8445513242, -172.4322598713, 178.060550415],
      "speed factor": 0.3123830829,
      "Uz factor": 0.8727627062,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.453246224,
      "Direction stability factor": 0.0058191618,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0602858848, 0.374069078, 0.91060597],
      direction: [-176.1776353044, -12.0530224652, 139.0869177932],
      "speed factor": 0.5314177537,
      "Uz factor": 0.9999896949,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3046155091,
      "Direction stability factor": 0.1242651303,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.208903849, 0.7974605149, 1.1533045942],
      direction: [-99.1842382204, -6.7034313807, 14.6448220935],
      "speed factor": 0.9343489414,
      "Uz factor": 0.993926678,
      "Directional variance factor": 0.4041394328,
      "Velocity stability factor": 0.2738482891,
      "Direction stability factor": 0.6838081658,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4544263144, 0.7469350845, 0.989066601],
      direction: [-40.8624578747, -15.9207839996, 12.0091186338],
      "speed factor": 1.3856545921,
      "Uz factor": 0.9630889523,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4812129474,
      "Direction stability factor": 0.8531345097,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.638703887, 1.091962821, 1.2737485321],
      direction: [-33.7859445327, 5.627532841, 23.4305276403],
      "speed factor": 1.4869811105,
      "Uz factor": 0.8133104745,
      "Directional variance factor": 0.4997748586,
      "Velocity stability factor": 0.5342965419,
      "Direction stability factor": 0.8410653551,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6316370942, 0.8995617584, 1.1279412786],
      direction: [-20.5188669974, 2.5141328753, 10.987737066],
      "speed factor": 1.1423876025,
      "Uz factor": 0.8762616451,
      "Directional variance factor": 0.7765215222,
      "Velocity stability factor": 0.6002595012,
      "Direction stability factor": 0.9124816554,
      score: 0.0,
    },
    id: "IDN:378.0",
    "45": {
      velocity: [0.0321537122, 0.3842574052, 1.0272101321],
      direction: [-176.1119802726, 20.6939150558, 175.8664114797],
      "speed factor": 0.521968166,
      "Uz factor": 0.9992226816,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1910506173,
      "Direction stability factor": 0.0222822451,
      score: 0.0,
    },
    "90": {
      velocity: [0.0595087635, 0.2487137939, 0.8078933574],
      direction: [-179.4214661225, -176.2876866216, 179.8487071444],
      "speed factor": 0.403172338,
      "Uz factor": 0.9972779801,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4537858676,
      "Direction stability factor": 0.0020272965,
      score: 0.0,
    },
    "135": {
      velocity: [0.0143391977, 0.2330676213, 0.7330590042],
      direction: [-175.5159052415, 42.9381661536, 175.4464794161],
      "speed factor": 0.197256857,
      "Uz factor": 0.800583065,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4208478109,
      "Direction stability factor": 0.0251044871,
      score: 0.0,
    },
    "180": {
      velocity: [0.0557994797, 0.4057751569, 0.7797289847],
      direction: [-139.3613290263, -23.6946917717, 120.6488193083],
      "speed factor": 0.7924763748,
      "Uz factor": 0.9929263568,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4295747655,
      "Direction stability factor": 0.277749588,
      score: 0.0,
    },
    "225": {
      velocity: [0.1916474388, 0.5400807522, 0.8995116571],
      direction: [-114.551104391, -5.1336316642, 35.3195206903],
      "speed factor": 0.7372693307,
      "Uz factor": 0.9577385184,
      "Directional variance factor": 0.5436771854,
      "Velocity stability factor": 0.3637853662,
      "Direction stability factor": 0.5836927081,
      score: 0.0,
    },
    "270": {
      velocity: [0.7879895849, 1.0308828198, 1.3622730303],
      direction: [-10.635657681, 10.2236835734, 38.0538251096],
      "speed factor": 0.943291067,
      "Uz factor": 0.8356559216,
      "Directional variance factor": 0.0912281268,
      "Velocity stability factor": 0.5470033781,
      "Direction stability factor": 0.8647514367,
      score: 0.0,
    },
    "315": {
      velocity: [0.7044760972, 1.0224180952, 1.2556336758],
      direction: [-17.2700043529, -0.2756637816, 29.4296226974],
      "speed factor": 1.2597208424,
      "Uz factor": 0.8670615412,
      "Directional variance factor": 0.9754965527,
      "Velocity stability factor": 0.5528811478,
      "Direction stability factor": 0.8702788137,
      score: 0.0,
    },
  },
  {
    position: [6.546950306, 102.3600707, 46.87999908],
    "overall score": 0.0366485587,
    "0": {
      velocity: [0.6018216939, 1.0236072465, 1.3413124477],
      direction: [-36.8326591011, -11.9192756308, 9.6165434976],
      "speed factor": 1.0489425999,
      "Uz factor": 0.9149114222,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4861790529,
      "Direction stability factor": 0.8709744372,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6641579749, 0.955115764, 1.3013834461],
      direction: [-55.6113149345, -15.1667801243, 9.3275756627],
      "speed factor": 0.8480240309,
      "Uz factor": 0.9208288808,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5879232063,
      "Direction stability factor": 0.8196141928,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1713242331, 0.484710841, 0.8729542238],
      direction: [-109.7589989655, -4.778844075, 65.1486719337],
      "speed factor": 0.7508494061,
      "Uz factor": 0.9889745537,
      "Directional variance factor": 0.57521386,
      "Velocity stability factor": 0.3730553458,
      "Direction stability factor": 0.5141453586,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4672220095, 0.9820682627, 1.3157866767],
      direction: [-19.9481999721, 6.2634629719, 51.2025594527],
      "speed factor": 1.1047581874,
      "Uz factor": 0.997055654,
      "Directional variance factor": 0.4432477358,
      "Velocity stability factor": 0.2975420206,
      "Direction stability factor": 0.8023590016,
      score: 0.5863769399,
    },
    "157.5": {
      velocity: [0.0479371244, 0.5367507124, 1.0573047755],
      direction: [-157.794952039, 9.8472259145, 163.8830163222],
      "speed factor": 0.7625299033,
      "Uz factor": 0.9993212441,
      "Directional variance factor": 0.1246910298,
      "Velocity stability factor": 0.1745477704,
      "Direction stability factor": 0.1064500879,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0299549147, 0.3487573838, 0.8335889041],
      direction: [-179.4565330982, -24.5473468422, 179.579581364],
      "speed factor": 0.4086234819,
      "Uz factor": 0.9989755252,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3820841423,
      "Direction stability factor": 0.0026774598,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1119198135, 0.4607771202, 0.8402312304],
      direction: [-33.9110229708, -2.0637872361, 78.9275416688],
      "speed factor": 0.8547970844,
      "Uz factor": 0.9989459016,
      "Directional variance factor": 0.8165522457,
      "Velocity stability factor": 0.2932845825,
      "Direction stability factor": 0.6865595427,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1253305427, 0.4885331486, 0.9268897768],
      direction: [-100.5441828715, 16.2050869259, 150.4163920223],
      "speed factor": 0.6652603458,
      "Uz factor": 0.8827103784,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4121847808,
      "Direction stability factor": 0.302887292,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1811757205, 0.5709946441, 1.015111836],
      direction: [-107.1193280662, 9.3423901811, 117.1343458222],
      "speed factor": 0.7251277596,
      "Uz factor": 0.9742952545,
      "Directional variance factor": 0.1695653172,
      "Velocity stability factor": 0.3283191049,
      "Direction stability factor": 0.3770731281,
      score: 0.0,
    },
    id: "IDN:400.0",
    "45": {
      velocity: [0.1831750423, 0.6112497887, 1.0012013524],
      direction: [-66.7074392306, -7.1002398212, 24.3505530201],
      "speed factor": 0.8303104295,
      "Uz factor": 0.9868560718,
      "Directional variance factor": 0.3688675714,
      "Velocity stability factor": 0.3349704948,
      "Direction stability factor": 0.7470611326,
      score: 0.0,
    },
    "90": {
      velocity: [0.236593619, 0.7369662756, 1.1149049054],
      direction: [-13.3949542168, 12.8491188609, 38.5190754613],
      "speed factor": 1.1946438988,
      "Uz factor": 0.995540068,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3589578925,
      "Direction stability factor": 0.855794362,
      score: 0.0,
    },
    "135": {
      velocity: [0.4674419466, 1.165934643, 1.3619886712],
      direction: [0.0827409331, 9.094173737, 28.1971677223],
      "speed factor": 0.9867891638,
      "Uz factor": 0.9999999928,
      "Directional variance factor": 0.1916290012,
      "Velocity stability factor": 0.2791645796,
      "Direction stability factor": 0.92190437,
      score: 0.0,
    },
    "180": {
      velocity: [0.0527880649, 0.3469915498, 0.9167598035],
      direction: [-179.7007621219, 12.4733465858, 179.7975841205],
      "speed factor": 0.6776723531,
      "Uz factor": 0.9995429007,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3192275239,
      "Direction stability factor": 0.0013934827,
      score: 0.0,
    },
    "225": {
      velocity: [0.0313733806, 0.3661372201, 0.7226836062],
      direction: [-113.9349350212, -14.8886547948, 161.6907458212],
      "speed factor": 0.4998173739,
      "Uz factor": 0.9989669156,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3786637738,
      "Direction stability factor": 0.2343731088,
      score: 0.0,
    },
    "270": {
      velocity: [0.2533468107, 0.959036574, 1.2830549798],
      direction: [-57.317525858, 2.9178575875, 28.2571585023],
      "speed factor": 0.8775494322,
      "Uz factor": 0.9982108718,
      "Directional variance factor": 0.7406348811,
      "Velocity stability factor": 0.1877628968,
      "Direction stability factor": 0.7622925434,
      score: 0.0,
    },
    "315": {
      velocity: [0.6216126308, 0.9024315636, 1.1586262141],
      direction: [-31.2544189665, -15.1864169933, 6.4833140702],
      "speed factor": 1.1118854946,
      "Uz factor": 0.9544885356,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5643552656,
      "Direction stability factor": 0.8951729638,
      score: 0.0,
    },
  },
  {
    position: [-45.83106275, 46.13737702, 45.87999908],
    "overall score": 0.0347828862,
    "0": {
      velocity: [0.1611981514, 0.6656118214, 1.2286934245],
      direction: [-99.367185604, -9.9169428637, 120.8654381467],
      "speed factor": 0.6820864124,
      "Uz factor": 0.997095784,
      "Directional variance factor": 0.1184939677,
      "Velocity stability factor": 0.2582714125,
      "Direction stability factor": 0.3882427118,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1221514699, 0.691073828, 1.2325220427],
      direction: [-162.7857470536, 0.4669376338, 105.3491537585],
      "speed factor": 0.613587625,
      "Uz factor": 0.9869227671,
      "Directional variance factor": 0.9584944326,
      "Velocity stability factor": 0.2819528312,
      "Direction stability factor": 0.2551808311,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2820285316, 0.6002152682, 0.9756966745],
      direction: [-22.8834382777, 13.4977781004, 90.1845765074],
      "speed factor": 0.9297734638,
      "Uz factor": 0.8784427989,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3801696909,
      "Direction stability factor": 0.6859221812,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.6534730017, 0.9593035222, 1.2582963833],
      direction: [-44.6416583661, -11.7246163313, 16.1896946059],
      "speed factor": 1.0791494447,
      "Uz factor": 0.8127535995,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4993156952,
      "Direction stability factor": 0.8310240195,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4879571648, 0.9482374482, 1.3668424927],
      direction: [-31.3355089501, -8.4481592323, 23.4210122551],
      "speed factor": 1.3471047042,
      "Uz factor": 0.9535176588,
      "Directional variance factor": 0.2490525127,
      "Velocity stability factor": 0.281255098,
      "Direction stability factor": 0.8478985522,
      score: 0.5565261788,
    },
    "202.5": {
      velocity: [0.0870796532, 0.2965320909, 0.7675350577],
      direction: [-177.0410033141, -55.3767779493, 170.3500182511],
      "speed factor": 0.3474334341,
      "Uz factor": 0.8394924113,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4767964143,
      "Direction stability factor": 0.0350249401,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0354807174, 0.2533979376, 0.6181903241],
      direction: [-144.0800051878, -10.8314514033, 28.0028290194],
      "speed factor": 0.4700837103,
      "Uz factor": 0.9979933122,
      "Directional variance factor": 0.0372043197,
      "Velocity stability factor": 0.434568986,
      "Direction stability factor": 0.5219921272,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0360642362, 0.1771189491, 0.4281324819],
      direction: [-179.905453843, 154.3456826704, 178.9654749124],
      "speed factor": 0.2411918488,
      "Uz factor": 0.9673350575,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7124807851,
      "Direction stability factor": 0.003136309,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0452538267, 0.2689165586, 0.790359291],
      direction: [-177.3910179618, 45.2961535184, 172.0360690001],
      "speed factor": 0.3415073393,
      "Uz factor": 0.9005943637,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3998663736,
      "Direction stability factor": 0.0293692029,
      score: 0.0,
    },
    id: "IDN:388.0",
    "45": {
      velocity: [0.1208421119, 0.528366857, 1.040105676],
      direction: [-150.1443970965, 11.8836394678, 133.7708099446],
      "speed factor": 0.717723785,
      "Uz factor": 0.9480475467,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2526678108,
      "Direction stability factor": 0.2113466471,
      score: 0.0,
    },
    "90": {
      velocity: [0.6466428859, 1.0578171141, 1.4079420044],
      direction: [-50.3986573285, -2.4531888333, 21.6069409789],
      "speed factor": 1.714752497,
      "Uz factor": 0.8484015686,
      "Directional variance factor": 0.7819387704,
      "Velocity stability factor": 0.4443601046,
      "Direction stability factor": 0.7999844491,
      score: 0.0,
    },
    "135": {
      velocity: [0.2466428977, 0.7320863477, 1.1302331092],
      direction: [-102.5883599275, -34.4751459588, 127.0872088723],
      "speed factor": 0.6196015182,
      "Uz factor": 0.856522413,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2879934563,
      "Direction stability factor": 0.3620123089,
      score: 0.0,
    },
    "180": {
      velocity: [0.0941378345, 0.6623875873, 1.1567993764],
      direction: [-156.5158703317, -17.4965224823, 141.6794689302],
      "speed factor": 1.2936388659,
      "Uz factor": 0.9845046767,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1626685264,
      "Direction stability factor": 0.1716796132,
      score: 0.0,
    },
    "225": {
      velocity: [0.0293524418, 0.3040831801, 0.6298471418],
      direction: [-163.8431835752, -7.0100405885, 37.2503696717],
      "speed factor": 0.4151068184,
      "Uz factor": 0.999803429,
      "Directional variance factor": 0.376885281,
      "Velocity stability factor": 0.4602870073,
      "Direction stability factor": 0.4414067965,
      score: 0.0,
    },
    "270": {
      velocity: [0.0355596134, 0.2467640841, 0.5593503437],
      direction: [-179.5890504602, 107.8532420776, 175.3895684701],
      "speed factor": 0.2257971048,
      "Uz factor": 0.9994626228,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5868321935,
      "Direction stability factor": 0.0139482807,
      score: 0.0,
    },
    "315": {
      velocity: [0.0482778481, 0.2328588853, 0.7355121399],
      direction: [-137.3901031881, 91.770055302, 176.2241308319],
      "speed factor": 0.2869053203,
      "Uz factor": 0.8840598627,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4424908235,
      "Direction stability factor": 0.1288493499,
      score: 0.0,
    },
  },
  {
    position: [-42.33622582, 96.61189181, 46.87999908],
    "overall score": 0.0321746989,
    "0": {
      velocity: [0.5608307835, 1.0272551706, 1.3218495417],
      direction: [-42.9739750406, -20.6016958496, 26.6539131824],
      "speed factor": 1.0526808139,
      "Uz factor": 0.8881336065,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4712207325,
      "Direction stability factor": 0.8065891994,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5362809994, 1.1271882184, 1.4873059404],
      direction: [-35.0337864089, 11.7184863802, 34.0326308308],
      "speed factor": 1.0008029735,
      "Uz factor": 0.9418689425,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3849974206,
      "Direction stability factor": 0.808148841,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1413058569, 0.6688773803, 1.1516468883],
      direction: [-32.2255020206, -5.8981338739, 60.2487603836],
      "speed factor": 1.0361356529,
      "Uz factor": 0.9882952021,
      "Directional variance factor": 0.4757214334,
      "Velocity stability factor": 0.097205198,
      "Direction stability factor": 0.7431270489,
      score: 0.5147951823,
    },
    "112.5": {
      velocity: [0.0365541767, 0.4884354811, 1.1342611199],
      direction: [-178.4944847419, 26.2378517259, 179.1132166024],
      "speed factor": 0.5494557938,
      "Uz factor": 0.9986996972,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0912973034,
      "Direction stability factor": 0.006645274,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0644660016, 0.3573047746, 1.0270939527],
      direction: [-179.3227243626, 2.9166557591, 179.8274328747],
      "speed factor": 0.5076017021,
      "Uz factor": 0.960764626,
      "Directional variance factor": 0.7407417103,
      "Velocity stability factor": 0.2127710972,
      "Direction stability factor": 0.0023606743,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1330231048, 0.526112952, 1.0959703648],
      direction: [-71.2552174831, -15.4824156694, 63.0690708477],
      "speed factor": 0.6164230962,
      "Uz factor": 0.9996925411,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.259587835,
      "Direction stability factor": 0.6268769769,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0871800216, 0.3294972504, 0.7887939275],
      direction: [-133.0494511805, -10.9262423247, 160.3295320357],
      "speed factor": 0.6112571059,
      "Uz factor": 0.9983213724,
      "Directional variance factor": 0.02877846,
      "Velocity stability factor": 0.3191904549,
      "Direction stability factor": 0.18505838,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2333717278, 0.6966573223, 1.0960682387],
      direction: [-96.9726486466, -7.8601557971, 36.6335688182],
      "speed factor": 0.9486735802,
      "Uz factor": 0.9905711706,
      "Directional variance factor": 0.3013194847,
      "Velocity stability factor": 0.3673503878,
      "Direction stability factor": 0.6288716182,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7354315382, 0.9449739251, 1.1533786687],
      direction: [-18.5057285003, -4.8380726068, 16.0422980535],
      "speed factor": 1.2000582357,
      "Uz factor": 0.8729416288,
      "Directional variance factor": 0.5699491016,
      "Velocity stability factor": 0.6633709735,
      "Direction stability factor": 0.9040332596,
      score: 0.0,
    },
    id: "IDN:426.0",
    "45": {
      velocity: [0.2269162481, 0.6547413258, 1.0538947299],
      direction: [-38.3460093804, -12.3063278257, 23.0990005502],
      "speed factor": 0.8893885306,
      "Uz factor": 0.9422601787,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3276926625,
      "Direction stability factor": 0.8293194169,
      score: 0.0,
    },
    "90": {
      velocity: [0.297100003, 0.723984209, 1.2314202741],
      direction: [-30.2118195264, 28.6461903707, 77.7842146825],
      "speed factor": 1.1735995888,
      "Uz factor": 0.9974595144,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3180793131,
      "Direction stability factor": 0.7000110161,
      score: 0.0,
    },
    "135": {
      velocity: [0.0505742144, 0.4967251625, 1.1483767061],
      direction: [-175.0368316402, 6.5942218831, 162.6039367945],
      "speed factor": 0.4204035026,
      "Uz factor": 0.9915392512,
      "Directional variance factor": 0.4138469437,
      "Velocity stability factor": 0.1153788855,
      "Direction stability factor": 0.0621089766,
      score: 0.0,
    },
    "180": {
      velocity: [0.0733090879, 0.245036673, 0.6386554319],
      direction: [-176.4936116686, 174.1288927805, 179.1580336364],
      "speed factor": 0.4785551087,
      "Uz factor": 0.7713407823,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5545314583,
      "Direction stability factor": 0.012078763,
      score: 0.0,
    },
    "225": {
      velocity: [0.1646653333, 0.6874146041, 1.1370387606],
      direction: [-143.0236116387, -4.0774943903, 129.3177752866],
      "speed factor": 0.9383961622,
      "Uz factor": 0.9989959217,
      "Directional variance factor": 0.6375560542,
      "Velocity stability factor": 0.1260496179,
      "Direction stability factor": 0.2434961474,
      score: 0.0,
    },
    "270": {
      velocity: [0.268931107, 1.0241742568, 1.3420782928],
      direction: [-71.8157179068, -5.6378186851, 11.5649247117],
      "speed factor": 0.9371525152,
      "Uz factor": 0.9983287565,
      "Directional variance factor": 0.4988605613,
      "Velocity stability factor": 0.1534980612,
      "Direction stability factor": 0.7683871038,
      score: 0.0,
    },
    "315": {
      velocity: [0.7462494439, 0.8970122508, 1.0569602788],
      direction: [-10.9466460927, 5.6935700038, 19.541754532],
      "speed factor": 1.1052083619,
      "Uz factor": 0.9038257692,
      "Directional variance factor": 0.4939048886,
      "Velocity stability factor": 0.7479401949,
      "Direction stability factor": 0.9153099983,
      score: 0.0,
    },
  },
  {
    position: [-47.07558315, 49.14173951, 45.87999908],
    "overall score": 0.0320577594,
    "0": {
      velocity: [0.0525453815, 0.5699560501, 1.0645184824],
      direction: [-101.3815655977, -10.5250098017, 178.2646566994],
      "speed factor": 0.5840630603,
      "Uz factor": 0.9998401834,
      "Directional variance factor": 0.0644435732,
      "Velocity stability factor": 0.2968499274,
      "Direction stability factor": 0.2232049381,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1361391446, 0.6200133918, 1.2227521603],
      direction: [-167.4582287902, 2.2564803129, 163.3538309343],
      "speed factor": 0.550494794,
      "Uz factor": 0.983789772,
      "Directional variance factor": 0.7994239722,
      "Velocity stability factor": 0.2973162126,
      "Direction stability factor": 0.0810776119,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0904880644, 0.4754371078, 0.9202130888],
      direction: [-111.4701135758, 3.5264575829, 55.1731698984],
      "speed factor": 0.736483775,
      "Uz factor": 0.9573047626,
      "Directional variance factor": 0.6865371037,
      "Velocity stability factor": 0.2585954486,
      "Direction stability factor": 0.5371019903,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2449420381, 0.6865032292, 1.0768327258],
      direction: [-60.1673290493, -17.640651566, 16.1911452707],
      "speed factor": 0.7722681732,
      "Uz factor": 0.9391520074,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3113450582,
      "Direction stability factor": 0.7878931269,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1499927844, 0.6953269776, 1.2767460586],
      direction: [-154.3490313008, -7.0015603508, 54.6085800675],
      "speed factor": 0.9878097984,
      "Uz factor": 0.9894555508,
      "Directional variance factor": 0.3776390799,
      "Velocity stability factor": 0.0785508121,
      "Direction stability factor": 0.4195621906,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0631382748, 0.214370059, 0.7125309588],
      direction: [-174.0825667274, -61.5048421141, 178.2613133946],
      "speed factor": 0.2511678433,
      "Uz factor": 0.9796552175,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5006806052,
      "Direction stability factor": 0.0212669997,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0383751295, 0.2241416026, 0.6821954026],
      direction: [-102.2809457162, 1.3279589373, 140.6754900085],
      "speed factor": 0.4158096833,
      "Uz factor": 0.9995148013,
      "Directional variance factor": 0.8819592056,
      "Velocity stability factor": 0.3752703822,
      "Direction stability factor": 0.3251210119,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0299115331, 0.1677074132, 0.4500099672],
      direction: [-173.0126171279, 120.6499545394, 179.3625120618],
      "speed factor": 0.2283756835,
      "Uz factor": 0.9970236201,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6919251347,
      "Direction stability factor": 0.0211801967,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0304412379, 0.2269597295, 0.6939942893],
      direction: [-175.7465618586, 17.4089510484, 172.9029307387],
      "speed factor": 0.2882247704,
      "Uz factor": 0.9023561993,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4655514982,
      "Direction stability factor": 0.0315291872,
      score: 0.0,
    },
    id: "IDN:386.0",
    "45": {
      velocity: [0.06384175, 0.5099140281, 0.9881039316],
      direction: [-173.9319555296, -2.8749797065, 122.831787606],
      "speed factor": 0.692657803,
      "Uz factor": 0.9551045337,
      "Directional variance factor": 0.7444462483,
      "Velocity stability factor": 0.248604093,
      "Direction stability factor": 0.1756562691,
      score: 0.0,
    },
    "90": {
      velocity: [0.2780394705, 0.927145953, 1.2943896278],
      direction: [-55.1317048217, -7.9302347922, 35.1568032239],
      "speed factor": 1.5029307209,
      "Uz factor": 0.9612184845,
      "Directional variance factor": 0.2950902407,
      "Velocity stability factor": 0.2582091829,
      "Direction stability factor": 0.7491985888,
      score: 0.5129241503,
    },
    "135": {
      velocity: [0.1847625972, 0.5880088765, 0.9497959029],
      direction: [-130.477413154, -48.8866924117, 15.8895173098],
      "speed factor": 0.4976615036,
      "Uz factor": 0.9141196322,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3835278926,
      "Direction stability factor": 0.5934251932,
      score: 0.0,
    },
    "180": {
      velocity: [0.0642979368, 0.5385232108, 1.1218965812],
      direction: [-161.2678720505, -28.0206716323, 170.6688769973],
      "speed factor": 1.0517325039,
      "Uz factor": 0.9967798838,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1666578713,
      "Direction stability factor": 0.0779534749,
      score: 0.0,
    },
    "225": {
      velocity: [0.0736169155, 0.2982192328, 0.6138408338],
      direction: [-111.3905489449, -9.1882591522, 129.2599330785],
      "speed factor": 0.4071018886,
      "Uz factor": 0.9994130756,
      "Directional variance factor": 0.1832658531,
      "Velocity stability factor": 0.5144572172,
      "Direction stability factor": 0.3315264388,
      score: 0.0,
    },
    "270": {
      velocity: [0.051918848, 0.2708468057, 0.4773849183],
      direction: [-177.8324784597, 116.9659483568, 179.9233771064],
      "speed factor": 0.2478335728,
      "Uz factor": 0.9989087819,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6643910005,
      "Direction stability factor": 0.0062337345,
      score: 0.0,
    },
    "315": {
      velocity: [0.0371477457, 0.2787567594, 0.8976985914],
      direction: [-161.085068676, 56.6135832955, 162.2063236311],
      "speed factor": 0.3434560689,
      "Uz factor": 0.9165918702,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3018902011,
      "Direction stability factor": 0.1019683547,
      score: 0.0,
    },
  },
  {
    position: [-39.07192354, 89.64835169, 46.87999908],
    "overall score": 0.0288265593,
    "0": {
      velocity: [0.0695421099, 0.3467160326, 1.0852225851],
      direction: [-175.4545037655, -28.3567076891, 176.305265534],
      "speed factor": 0.3552976182,
      "Uz factor": 0.9194887059,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2942739296,
      "Direction stability factor": 0.0228895297,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1680742877, 1.1509604014, 1.5391157371],
      direction: [-72.0351268477, 3.5427163859, 99.6085493792],
      "speed factor": 1.0219097159,
      "Uz factor": 0.9987663104,
      "Directional variance factor": 0.6850918768,
      "Velocity stability factor": 0.1133838961,
      "Direction stability factor": 0.5232120105,
      score: 0.4612249485,
    },
    "67.5": {
      velocity: [0.2045082976, 0.5925932277, 1.0595173835],
      direction: [-45.9780834293, -4.8190191961, 59.9673922193],
      "speed factor": 0.917966415,
      "Uz factor": 0.9955866873,
      "Directional variance factor": 0.5716427381,
      "Velocity stability factor": 0.236002761,
      "Direction stability factor": 0.7057070121,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2624098722, 0.6986554834, 1.1400592591],
      direction: [-10.9015751424, 31.4590921684, 85.4333875237],
      "speed factor": 0.7859386101,
      "Uz factor": 0.8853586662,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2734651362,
      "Direction stability factor": 0.7324028815,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4266543573, 0.811953057, 1.3019844593],
      direction: [-30.2181385462, 8.3060326264, 40.2020734395],
      "speed factor": 1.1534935526,
      "Uz factor": 0.9072878057,
      "Directional variance factor": 0.2616859888,
      "Velocity stability factor": 0.2841625313,
      "Direction stability factor": 0.8043883,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3805043123, 0.6701940175, 0.9821426531],
      direction: [-60.5861079561, -29.0991501808, 12.8749498032],
      "speed factor": 0.7852364588,
      "Uz factor": 0.7985347335,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5373990197,
      "Direction stability factor": 0.7959415062,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1634362386, 0.3976341746, 0.6416907549],
      direction: [-107.5500976668, -19.2204826124, 65.8900575341],
      "speed factor": 0.7376593114,
      "Uz factor": 0.9982072742,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5359267584,
      "Direction stability factor": 0.5182217911,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0520795758, 0.4414496174, 0.9575185643],
      direction: [-172.7723337271, -16.8668440146, 134.3328957327],
      "speed factor": 0.6011443152,
      "Uz factor": 0.9940853334,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3360056315,
      "Direction stability factor": 0.1469299182,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0581118024, 0.1514418523, 0.3312983518],
      direction: [-179.5127349121, -162.1649637474, 179.1143424461],
      "speed factor": 0.1923217533,
      "Uz factor": 0.9966793699,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7799661358,
      "Direction stability factor": 0.003813674,
      score: 0.0,
    },
    id: "IDN:454.0",
    "45": {
      velocity: [0.0311857925, 0.4901901449, 0.8549695604],
      direction: [-46.5259849688, -2.6447821987, 170.6019963918],
      "speed factor": 0.6658652441,
      "Uz factor": 0.9996685218,
      "Directional variance factor": 0.764908249,
      "Velocity stability factor": 0.3302898638,
      "Direction stability factor": 0.3968667184,
      score: 0.0,
    },
    "90": {
      velocity: [0.3368759963, 0.7549701262, 1.1561155477],
      direction: [-119.368413989, 32.6367582234, 135.3951836123],
      "speed factor": 1.2238286673,
      "Uz factor": 0.9390640622,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4020718432,
      "Direction stability factor": 0.29232334,
      score: 0.0,
    },
    "135": {
      velocity: [0.6113116396, 0.9986712992, 1.2295745724],
      direction: [-32.2221575472, 6.7238178559, 37.4573099514],
      "speed factor": 0.8452257784,
      "Uz factor": 0.9176178974,
      "Directional variance factor": 0.4023273017,
      "Velocity stability factor": 0.5017970456,
      "Direction stability factor": 0.8064459236,
      score: 0.0,
    },
    "180": {
      velocity: [0.5573565523, 0.8852025865, 1.1955941777],
      direction: [-25.3243951344, -7.8142647581, 13.7400571239],
      "speed factor": 1.728795183,
      "Uz factor": 0.8828442314,
      "Directional variance factor": 0.3053986882,
      "Velocity stability factor": 0.4970962716,
      "Direction stability factor": 0.8914876326,
      score: 0.0,
    },
    "225": {
      velocity: [0.2649307742, 0.6605039555, 1.0233236798],
      direction: [-50.5152029321, -4.2584389728, 14.7635114405],
      "speed factor": 0.9016601819,
      "Uz factor": 0.9987446878,
      "Directional variance factor": 0.6214720913,
      "Velocity stability factor": 0.3183711618,
      "Direction stability factor": 0.8186702379,
      score: 0.0,
    },
    "270": {
      velocity: [0.1051627066, 0.3218134119, 0.6184715958],
      direction: [-143.6306937097, -82.31785023, -14.578735783],
      "speed factor": 0.2944696631,
      "Uz factor": 0.9743397745,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5951003035,
      "Direction stability factor": 0.6415223391,
      score: 0.0,
    },
    "315": {
      velocity: [0.0256065193, 0.2397775176, 0.8940210937],
      direction: [-152.7821992578, -2.5286429849, 32.6527710299],
      "speed factor": 0.2954297638,
      "Uz factor": 0.9952035346,
      "Directional variance factor": 0.7752317347,
      "Velocity stability factor": 0.2955108615,
      "Direction stability factor": 0.4849028603,
      score: 0.0,
    },
  },
  {
    position: [-54.65624756, 50.06039249, 45.87999908],
    "overall score": 0.0276224631,
    "0": {
      velocity: [0.2624657177, 0.9899722684, 1.407948509],
      direction: [-32.9850646493, -7.8957836577, 99.2066126802],
      "speed factor": 1.0144751208,
      "Uz factor": 0.9876971749,
      "Directional variance factor": 0.2981525638,
      "Velocity stability factor": 0.2040832834,
      "Direction stability factor": 0.6328008963,
      score: 0.4419594099,
    },
    "22.5": {
      velocity: [0.1612222984, 0.564011887, 1.1804474154],
      direction: [-115.0199710849, 2.6007974499, 122.1923044779],
      "speed factor": 0.5007724215,
      "Uz factor": 0.9989093084,
      "Directional variance factor": 0.7688180045,
      "Velocity stability factor": 0.3408941775,
      "Direction stability factor": 0.3410770123,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0363581431, 0.2115867958, 0.6836722461],
      direction: [-174.8328727775, 21.4157535834, 176.2718408546],
      "speed factor": 0.3277620521,
      "Uz factor": 0.9505492121,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4215895531,
      "Direction stability factor": 0.0247091288,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0832535745, 0.2696128082, 0.6014402018],
      direction: [-179.7227236613, -176.2234671282, 179.1887285883],
      "speed factor": 0.3032955738,
      "Uz factor": 0.9787840641,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.571035249,
      "Direction stability factor": 0.0030237438,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0546457351, 0.2504276607, 0.8321912554],
      direction: [-171.0054015248, 13.8091249963, 178.0374996052],
      "speed factor": 0.3557677251,
      "Uz factor": 0.8725185862,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3641299257,
      "Direction stability factor": 0.0304363858,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0851808345, 0.8108251705, 1.1945571794],
      direction: [-92.4931410284, -5.8554449042, 13.1538894359],
      "speed factor": 0.9500077126,
      "Uz factor": 0.9926090649,
      "Directional variance factor": 0.4795160085,
      "Velocity stability factor": 0.1469982049,
      "Direction stability factor": 0.7065360265,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4473696064, 0.7613586002, 0.9997679309],
      direction: [-33.1411842793, -11.1918983167, 10.9715012955],
      "speed factor": 1.412411952,
      "Uz factor": 0.9355293825,
      "Directional variance factor": 0.0051645941,
      "Velocity stability factor": 0.4639814734,
      "Direction stability factor": 0.8774647623,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.7138803609, 1.1840172585, 1.388940347],
      direction: [-36.9263600196, 1.8545708559, 20.9553945235],
      "speed factor": 1.6123363031,
      "Uz factor": 0.8420657252,
      "Directional variance factor": 0.8351492573,
      "Velocity stability factor": 0.5049517032,
      "Direction stability factor": 0.8392173485,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4943654575, 0.8989192675, 1.1616571597],
      direction: [-29.4700223758, -0.4667812117, 13.636705843],
      "speed factor": 1.141571679,
      "Uz factor": 0.938583632,
      "Directional variance factor": 0.9585083367,
      "Velocity stability factor": 0.46254026,
      "Direction stability factor": 0.8802590883,
      score: 0.0,
    },
    id: "IDN:377.0",
    "45": {
      velocity: [0.0422188009, 0.337420843, 1.0170799816],
      direction: [-171.1714597223, 11.4821172681, 174.5779562559],
      "speed factor": 0.4583462444,
      "Uz factor": 0.9879821394,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2074687078,
      "Direction stability factor": 0.0395849556,
      score: 0.0,
    },
    "90": {
      velocity: [0.0411198931, 0.2737991226, 0.8323283327],
      direction: [-179.6138723741, -155.0355392036, 179.7574522769],
      "speed factor": 0.4438363899,
      "Uz factor": 0.9937757066,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4225305613,
      "Direction stability factor": 0.0017463204,
      score: 0.0,
    },
    "135": {
      velocity: [0.022931783, 0.2772317562, 0.7755475807],
      direction: [-179.9503536223, -17.5760389076, 179.5664354964],
      "speed factor": 0.2346351869,
      "Uz factor": 0.9841404582,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3935340548,
      "Direction stability factor": 0.0013422524,
      score: 0.0,
    },
    "180": {
      velocity: [0.0437577934, 0.356449802, 0.8335898612],
      direction: [-175.6024533321, -16.5358938264, 144.2218586096],
      "speed factor": 0.6961442612,
      "Uz factor": 0.9982132687,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3776463877,
      "Direction stability factor": 0.1115991335,
      score: 0.0,
    },
    "225": {
      velocity: [0.1246244994, 0.5342334176, 0.9052100556],
      direction: [-123.2435353566, -2.02451348, 33.5891441791],
      "speed factor": 0.7292870791,
      "Uz factor": 0.9444635368,
      "Directional variance factor": 0.8200432462,
      "Velocity stability factor": 0.2984248377,
      "Direction stability factor": 0.564353668,
      score: 0.0,
    },
    "270": {
      velocity: [0.8287272784, 1.1086491087, 1.425399471],
      direction: [0.2608730434, 17.1677439429, 34.353742542],
      "speed factor": 1.0144497323,
      "Uz factor": 0.7683101096,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5293430625,
      "Direction stability factor": 0.9052975847,
      score: 0.0,
    },
    "315": {
      velocity: [0.5316515773, 1.0809622668, 1.3478856551],
      direction: [-21.5038104903, -3.5508262903, 27.1729589361],
      "speed factor": 1.3318530879,
      "Uz factor": 0.8946606976,
      "Directional variance factor": 0.6843709964,
      "Velocity stability factor": 0.3378415572,
      "Direction stability factor": 0.8647867516,
      score: 0.0,
    },
  },
  {
    position: [-51.63108851, 93.82668631, 45.87999908],
    "overall score": 0.026176095,
    "0": {
      velocity: [0.6638371913, 1.2532217103, 1.4329805901],
      direction: [-11.0408298861, 6.533516793, 25.7286999777],
      "speed factor": 1.2842402626,
      "Uz factor": 0.9418081342,
      "Directional variance factor": 0.4192429517,
      "Velocity stability factor": 0.465575482,
      "Direction stability factor": 0.897862417,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.166507155, 0.6650190498, 1.210013776],
      direction: [-35.6365408747, 26.3267437793, 121.1244602105],
      "speed factor": 0.5904542219,
      "Uz factor": 0.9734827314,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3251919735,
      "Direction stability factor": 0.5645527748,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0781326366, 0.464926267, 1.1801513958],
      direction: [-76.1659704412, 13.10797126, 137.1212721546],
      "speed factor": 0.7202017819,
      "Uz factor": 0.9919309569,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0152861493,
      "Direction stability factor": 0.4075354372,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.070020536, 0.307909517, 0.60441256],
      direction: [-177.8294380216, 131.0866092319, 175.351045356],
      "speed factor": 0.3463766958,
      "Uz factor": 0.9855564984,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5576201132,
      "Direction stability factor": 0.0189431017,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0764354456, 0.3329511078, 0.9227495334],
      direction: [-178.7482821171, 44.7106410518, 178.8815012001],
      "speed factor": 0.4730038921,
      "Uz factor": 0.9997447217,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3078915795,
      "Direction stability factor": 0.0065839352,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3020800359, 0.9833707261, 1.4748701923],
      direction: [-109.8610367967, -14.5494300968, 144.7362735069],
      "speed factor": 1.1521716496,
      "Uz factor": 0.9999939791,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0982391924,
      "Direction stability factor": 0.2927852492,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0879523749, 0.4341985202, 0.8554166439],
      direction: [-110.1282646206, -11.8492892067, 27.6189885853],
      "speed factor": 0.8054905787,
      "Uz factor": 0.9361446199,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2552926967,
      "Direction stability factor": 0.6173687411,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2959093055, 0.8395657047, 1.1722289571],
      direction: [-67.6222211431, -33.689196106, 22.5629758955],
      "speed factor": 1.1432791666,
      "Uz factor": 0.9143793625,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3573599977,
      "Direction stability factor": 0.7494855638,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.8206477221, 1.0137745588, 1.2027791224],
      direction: [4.947306625, 16.3728930934, 38.1523887387],
      "speed factor": 1.2874307705,
      "Uz factor": 0.9032978011,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6922181973,
      "Direction stability factor": 0.9077636608,
      score: 0.0,
    },
    id: "IDN:342.0",
    "45": {
      velocity: [0.1249731081, 0.4718815505, 1.1379180703],
      direction: [-36.3618513294, 15.2171294149, 106.4773354349],
      "speed factor": 0.6409951874,
      "Uz factor": 0.9547545748,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1765077984,
      "Direction stability factor": 0.6032244812,
      score: 0.0,
    },
    "90": {
      velocity: [0.0537839166, 0.3296831831, 0.8625738006],
      direction: [-177.1745902862, 12.3217084208, 178.7037600162],
      "speed factor": 0.5344260873,
      "Uz factor": 0.9936258422,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4096986116,
      "Direction stability factor": 0.0114490269,
      score: 0.0,
    },
    "135": {
      velocity: [0.0646394844, 0.3129744634, 0.7419786918],
      direction: [-175.0432985792, 118.7345308492, 174.4962260104],
      "speed factor": 0.2648860387,
      "Uz factor": 0.9895574371,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4541927449,
      "Direction stability factor": 0.0290568761,
      score: 0.0,
    },
    "180": {
      velocity: [0.0465307022, 0.4319799439, 1.2489680443],
      direction: [-178.9855807217, -23.4654176194, 151.6411907612],
      "speed factor": 0.8436541618,
      "Uz factor": 0.9852086845,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0525312229,
      "Direction stability factor": 0.0815923014,
      score: 0.0,
    },
    "225": {
      velocity: [0.2310952775, 0.837054412, 1.1538169886],
      direction: [-83.3839716407, -10.9983360853, 9.8156473018],
      "speed factor": 1.1426708758,
      "Uz factor": 0.9836742077,
      "Directional variance factor": 0.0223701257,
      "Velocity stability factor": 0.1706756177,
      "Direction stability factor": 0.7411121696,
      score: 0.4188175207,
    },
    "270": {
      velocity: [0.8353951374, 1.1300479434, 1.3777186845],
      direction: [-40.2785160183, -16.5180592138, 1.3925233503],
      "speed factor": 1.034030357,
      "Uz factor": 0.936687202,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5722134483,
      "Direction stability factor": 0.8842471129,
      score: 0.0,
    },
    "315": {
      velocity: [0.7517877705, 0.9089332746, 1.0856673316],
      direction: [-12.6155003329, 9.9769395188, 29.8880945604],
      "speed factor": 1.1198962496,
      "Uz factor": 0.8704255663,
      "Directional variance factor": 0.1131609317,
      "Velocity stability factor": 0.7291448908,
      "Direction stability factor": 0.8819344586,
      score: 0.0,
    },
  },
  {
    position: [-5.258533199, 95.21003953, 45.87999908],
    "overall score": 0.0211612247,
    "0": {
      velocity: [0.0372536373, 0.4152649679, 1.3545372874],
      direction: [-166.6076079596, 37.1356770444, 178.3774904527],
      "speed factor": 0.4255432116,
      "Uz factor": 0.9988798658,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0847107563,
      "Direction stability factor": 0.04170806,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.014399589, 0.3427075455, 0.9481619214],
      direction: [-167.2136619398, 89.5005480265, 175.9926903164],
      "speed factor": 0.3042816852,
      "Uz factor": 0.9939803428,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3961606912,
      "Direction stability factor": 0.0466490215,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0805121834, 0.4126884969, 0.9008794113],
      direction: [-46.1964685939, 11.968238509, 93.3782532093],
      "speed factor": 0.6392819935,
      "Uz factor": 0.9884429261,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2669571501,
      "Direction stability factor": 0.6122924394,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0179331757, 0.312122712, 0.9908162921],
      direction: [-171.3320095695, 7.668348106, 162.6531061955],
      "speed factor": 0.3511162458,
      "Uz factor": 0.8886263502,
      "Directional variance factor": 0.3183690572,
      "Velocity stability factor": 0.1946288426,
      "Direction stability factor": 0.0722635673,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1039432187, 0.5460308498, 1.0103528962],
      direction: [-148.9609252835, -1.9757466461, 62.9576369415],
      "speed factor": 0.7757136441,
      "Uz factor": 0.9665368256,
      "Directional variance factor": 0.8243780759,
      "Velocity stability factor": 0.2587459203,
      "Direction stability factor": 0.4113373272,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0859807004, 0.5630329138, 1.0992877359],
      direction: [-113.4771967744, -37.7823332917, 104.1022043315],
      "speed factor": 0.6596805699,
      "Uz factor": 0.9608002888,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2208660982,
      "Direction stability factor": 0.3956127747,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1390728615, 0.5048268325, 0.896568305],
      direction: [-69.6599917324, -0.5859193907, 51.828835589],
      "speed factor": 0.9365146091,
      "Uz factor": 0.9499969937,
      "Directional variance factor": 0.9479182764,
      "Velocity stability factor": 0.264965925,
      "Direction stability factor": 0.6625310352,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0751395489, 0.3979802219, 1.0444973032],
      direction: [-177.3074447747, 18.2717076157, 166.1347662294],
      "speed factor": 0.5419498366,
      "Uz factor": 0.9187703951,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2891314621,
      "Direction stability factor": 0.0459938583,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0847117396, 0.686196787, 1.2148707429],
      direction: [-61.786107424, -27.5712202371, 150.1391455562],
      "speed factor": 0.8714273312,
      "Uz factor": 0.8634263537,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0897309796,
      "Direction stability factor": 0.4113187417,
      score: 0.0,
    },
    id: "IDN:316.0",
    "45": {
      velocity: [0.071267218, 0.3834754151, 0.8754257331],
      direction: [-156.3660703559, 33.0809964877, 156.053662414],
      "speed factor": 0.5209059251,
      "Uz factor": 0.9968252733,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3462445734,
      "Direction stability factor": 0.132167409,
      score: 0.0,
    },
    "90": {
      velocity: [0.064339035, 0.4750953176, 0.8716675403],
      direction: [-97.3772717624, 31.3028960522, 164.052192752],
      "speed factor": 0.7701434126,
      "Uz factor": 0.9905621733,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4107652099,
      "Direction stability factor": 0.273807043,
      score: 0.0,
    },
    "135": {
      velocity: [0.0472577281, 0.3261939093, 0.8967960423],
      direction: [-127.5264836803, -7.5256136606, 151.3111156418],
      "speed factor": 0.2760743211,
      "Uz factor": 0.9196973715,
      "Directional variance factor": 0.3310565635,
      "Velocity stability factor": 0.3154328433,
      "Direction stability factor": 0.225451113,
      score: 0.0,
    },
    "180": {
      velocity: [0.1378020937, 0.7571624825, 1.311306917],
      direction: [-125.0907204056, -9.8217927592, 27.5424710991],
      "speed factor": 1.4787336509,
      "Uz factor": 0.9788424882,
      "Directional variance factor": 0.1269517547,
      "Velocity stability factor": 0.0753288001,
      "Direction stability factor": 0.5760189125,
      score: 0.338579595,
    },
    "225": {
      velocity: [0.0421492895, 0.4666168142, 0.8692855703],
      direction: [-126.6758864323, -15.160492435, 71.4662937722],
      "speed factor": 0.6369830158,
      "Uz factor": 0.9765123437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.256585949,
      "Direction stability factor": 0.449605055,
      score: 0.0,
    },
    "270": {
      velocity: [0.3040917237, 0.8471393482, 1.3674606449],
      direction: [-40.0742868483, 16.7447156136, 46.213416056],
      "speed factor": 0.7751598574,
      "Uz factor": 0.9562726689,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1612111876,
      "Direction stability factor": 0.7603119364,
      score: 0.0,
    },
    "315": {
      velocity: [0.243769218, 0.7849408426, 1.2168283669],
      direction: [-38.7736771294, 5.378842472, 38.8983325625],
      "speed factor": 0.9671252338,
      "Uz factor": 0.9351269752,
      "Directional variance factor": 0.5218806692,
      "Velocity stability factor": 0.2106194187,
      "Direction stability factor": 0.7842444175,
      score: 0.0,
    },
  },
  {
    position: [-5.248061244, 98.21002125, 45.87999908],
    "overall score": 0.0156027632,
    "0": {
      velocity: [0.0613458732, 0.3809519533, 1.1349054854],
      direction: [-177.9918877038, 19.9306025431, 173.4867382183],
      "speed factor": 0.3903809138,
      "Uz factor": 0.9819247577,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2540577229,
      "Direction stability factor": 0.0236704835,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.037110752, 0.2590950066, 1.0160158944],
      direction: [-168.6021381839, 57.801592802, 178.4262463558],
      "speed factor": 0.2300441477,
      "Uz factor": 0.9362506181,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3669680345,
      "Direction stability factor": 0.0360322652,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0361751716, 0.391224762, 0.8790608659],
      direction: [-121.1473268196, 23.5630150828, 120.1976970326],
      "speed factor": 0.6060332374,
      "Uz factor": 0.9945860148,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2468356725,
      "Direction stability factor": 0.329597156,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0426768813, 0.3916256977, 1.0433811531],
      direction: [-166.2149698167, 4.8619095014, 179.4860174923],
      "speed factor": 0.4405515507,
      "Uz factor": 0.9929271826,
      "Directional variance factor": 0.5678302665,
      "Velocity stability factor": 0.1715979607,
      "Direction stability factor": 0.0397194797,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.029661217, 0.3458810954, 0.9495730039],
      direction: [-178.4330797566, 37.4588632581, 177.2720303832],
      "speed factor": 0.4913727586,
      "Uz factor": 0.9209120933,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2477040108,
      "Direction stability factor": 0.0119302496,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0935868973, 0.4064882127, 1.046916718],
      direction: [-171.7324049154, -34.3320474625, 168.1458956118],
      "speed factor": 0.476264121,
      "Uz factor": 0.9723875281,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2669827042,
      "Direction stability factor": 0.0558936096,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1690775889, 0.518564819, 0.9320724684],
      direction: [-56.0794593452, -7.6662577228, 39.0771484379],
      "speed factor": 0.9620002297,
      "Uz factor": 0.9454610331,
      "Directional variance factor": 0.3185548691,
      "Velocity stability factor": 0.2596295591,
      "Direction stability factor": 0.7356760895,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.070855312, 0.3990638061, 0.993016145],
      direction: [-167.9831292467, 23.7164128923, 170.7015148062],
      "speed factor": 0.5434254081,
      "Uz factor": 0.8619158772,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3237428389,
      "Direction stability factor": 0.0592093221,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0763350748, 0.6208542109, 1.2086556343],
      direction: [-72.6675281783, -29.8084682296, 149.0478091591],
      "speed factor": 0.788446315,
      "Uz factor": 0.7919280872,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0879899878,
      "Direction stability factor": 0.384124063,
      score: 0.0,
    },
    id: "IDN:315.0",
    "45": {
      velocity: [0.0565834661, 0.3339995442, 1.007403279],
      direction: [-177.4620717794, 37.5358315683, 176.7947931543],
      "speed factor": 0.4536988154,
      "Uz factor": 0.9011678959,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.227013579,
      "Direction stability factor": 0.015953153,
      score: 0.0,
    },
    "90": {
      velocity: [0.0672680575, 0.5564815615, 0.9765547722],
      direction: [-130.0286116919, 24.818161257, 51.3090112532],
      "speed factor": 0.9020728955,
      "Uz factor": 0.9973011089,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3363502428,
      "Direction stability factor": 0.4962843807,
      score: 0.0,
    },
    "135": {
      velocity: [0.0499357779, 0.2701895072, 0.5638057341],
      direction: [-178.9034429405, -134.5917143147, 175.9954961005],
      "speed factor": 0.2286749772,
      "Uz factor": 0.9785658389,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5859180345,
      "Direction stability factor": 0.0141696138,
      score: 0.0,
    },
    "180": {
      velocity: [0.0356337514, 0.5644632985, 1.2791816511],
      direction: [-160.6715784933, 1.7570080172, 175.0972788551],
      "speed factor": 1.102393335,
      "Uz factor": 0.9909732415,
      "Directional variance factor": 0.8438215096,
      "Velocity stability factor": 0.0201378761,
      "Direction stability factor": 0.0673087296,
      score: 0.2496442112,
    },
    "225": {
      velocity: [0.0509357836, 0.4682160768, 0.8947980983],
      direction: [-170.3785417482, -11.3399113555, 151.9009016454],
      "speed factor": 0.6391661843,
      "Uz factor": 0.9864750706,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2415529142,
      "Direction stability factor": 0.1047793239,
      score: 0.0,
    },
    "270": {
      velocity: [0.3300669897, 0.8871182117, 1.316327222],
      direction: [-38.3388278082, 7.6329536341, 36.2797251718],
      "speed factor": 0.8117418084,
      "Uz factor": 0.9451529668,
      "Directional variance factor": 0.3215152325,
      "Velocity stability factor": 0.2220347685,
      "Direction stability factor": 0.7927262417,
      score: 0.0,
    },
    "315": {
      velocity: [0.5982681138, 0.8993750202, 1.2401526852],
      direction: [-15.3268983611, 1.9267641388, 28.9023352275],
      "speed factor": 1.108119529,
      "Uz factor": 0.9026426882,
      "Directional variance factor": 0.8287320765,
      "Velocity stability factor": 0.4792801479,
      "Direction stability factor": 0.8771410178,
      score: 0.0,
    },
  },
  {
    position: [-5.23758929, 101.210003, 45.87999908],
    "overall score": 0.0152863164,
    "0": {
      velocity: [0.6336313623, 1.1450696394, 1.4988165436],
      direction: [-28.5047108241, 15.6313175764, 38.8763513117],
      "speed factor": 1.1734113144,
      "Uz factor": 0.926727145,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3988426941,
      "Direction stability factor": 0.812830383,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6348540307, 1.0914366332, 1.432990354],
      direction: [-17.1623434314, 6.2854459814, 26.3828139441],
      "speed factor": 0.9690600114,
      "Uz factor": 0.9260205965,
      "Directional variance factor": 0.4412936905,
      "Velocity stability factor": 0.4838664304,
      "Direction stability factor": 0.8790412295,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1584933633, 0.4592474056, 0.8455899829],
      direction: [-35.5906717325, 7.6283261048, 128.2004154088],
      "speed factor": 0.711404847,
      "Uz factor": 0.9560678868,
      "Directional variance factor": 0.3219265685,
      "Velocity stability factor": 0.3860417054,
      "Direction stability factor": 0.5450247579,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1336303285, 0.6494800329, 1.2522220364],
      direction: [-73.922400682, 10.51252021, 65.258945873],
      "speed factor": 0.7306196638,
      "Uz factor": 0.9973183935,
      "Directional variance factor": 0.0655537591,
      "Velocity stability factor": 0.0740084978,
      "Direction stability factor": 0.6133851485,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0776014536, 0.3677101078, 1.0502945938],
      direction: [-179.1933482017, 23.0993583927, 176.8539711857],
      "speed factor": 0.5223839419,
      "Uz factor": 0.9908795334,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2045398717,
      "Direction stability factor": 0.0109796684,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0818669885, 0.3994287222, 1.1147897483],
      direction: [-175.2397903767, -35.9720551405, 163.4832633444],
      "speed factor": 0.4679928307,
      "Uz factor": 0.9962262169,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2057835266,
      "Direction stability factor": 0.0591026286,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2454972484, 0.5339068792, 1.0278166014],
      direction: [-77.3975471135, -14.629882939, 25.9396996742],
      "speed factor": 0.9904616,
      "Uz factor": 0.9770554114,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2408780977,
      "Direction stability factor": 0.7129520923,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0604446294, 0.5379178808, 0.9991678814],
      direction: [-113.3377653141, 17.7586867909, 158.8565506724],
      "speed factor": 0.7325100382,
      "Uz factor": 0.8331831832,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3115969593,
      "Direction stability factor": 0.2439046778,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1070388346, 0.5741484359, 1.251762359],
      direction: [-177.2596834485, -26.0168883251, 171.3885014158],
      "speed factor": 0.7291328795,
      "Uz factor": 0.7340867188,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0780002122,
      "Direction stability factor": 0.0315328198,
      score: 0.0,
    },
    id: "IDN:314.0",
    "45": {
      velocity: [0.1216319833, 0.5148368483, 1.0187613921],
      direction: [-168.8487616876, 10.0391032898, 176.7546185942],
      "speed factor": 0.699344871,
      "Uz factor": 0.9460512786,
      "Directional variance factor": 0.1076352631,
      "Velocity stability factor": 0.2706621787,
      "Direction stability factor": 0.0399906103,
      score: 0.0,
    },
    "90": {
      velocity: [0.1087259833, 0.5555776555, 1.0197502338],
      direction: [-66.132540668, 25.21499406, 166.6249592182],
      "speed factor": 0.9006076374,
      "Uz factor": 0.9961347776,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3350820893,
      "Direction stability factor": 0.3534513892,
      score: 0.0,
    },
    "135": {
      velocity: [0.1283412837, 0.3034004462, 0.6958824358],
      direction: [-179.8289942111, -109.0256260582, 178.0181605827],
      "speed factor": 0.2567830662,
      "Uz factor": 0.9994784554,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5426692047,
      "Direction stability factor": 0.0059801256,
      score: 0.0,
    },
    "180": {
      velocity: [0.0315844534, 0.5264322972, 1.2096114765],
      direction: [-161.1897727484, 2.6508032092, 173.2168016395],
      "speed factor": 1.0281190242,
      "Uz factor": 0.9934758156,
      "Directional variance factor": 0.7643730481,
      "Velocity stability factor": 0.0717655017,
      "Direction stability factor": 0.0710928489,
      score: 0.2445810619,
    },
    "225": {
      velocity: [0.0611408436, 0.4880810214, 0.8621849675],
      direction: [-173.7277402476, -14.6685836901, 172.9085167703],
      "speed factor": 0.6662840077,
      "Uz factor": 0.9970066978,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2800370738,
      "Direction stability factor": 0.0371215083,
      score: 0.0,
    },
    "270": {
      velocity: [0.4434678685, 0.9503954925, 1.2485292403],
      direction: [-55.0347196526, -3.7927579761, 20.4213644074],
      "speed factor": 0.8696425635,
      "Uz factor": 0.9823982547,
      "Directional variance factor": 0.6628659577,
      "Velocity stability factor": 0.3649650102,
      "Direction stability factor": 0.7903997665,
      score: 0.0,
    },
    "315": {
      velocity: [0.7485957153, 0.9172319068, 1.1795037924],
      direction: [-26.6556395231, -6.3141126354, 25.5560120745],
      "speed factor": 1.1301209903,
      "Uz factor": 0.8937572571,
      "Directional variance factor": 0.4387455435,
      "Velocity stability factor": 0.6504318686,
      "Direction stability factor": 0.8549676345,
      score: 0.0,
    },
  },
  {
    position: [-48.2735305, 95.74678309, 45.87999908],
    "overall score": 0.0144620874,
    "0": {
      velocity: [0.5688177886, 1.1628788342, 1.3742815194],
      direction: [-25.2104425136, -1.5784615869, 24.9264602296],
      "speed factor": 1.1916613055,
      "Uz factor": 0.8415063427,
      "Directional variance factor": 0.8596923034,
      "Velocity stability factor": 0.4403389969,
      "Direction stability factor": 0.8607308257,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4755700183, 1.2155117661, 1.6115439209],
      direction: [-29.7682087419, 27.211026034, 55.5639261932],
      "speed factor": 1.0792233009,
      "Uz factor": 0.9197668873,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2653958375,
      "Direction stability factor": 0.7629662918,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1856449082, 0.641077346, 1.1981587331],
      direction: [-166.2006274523, -4.1741021614, 51.7254692946],
      "speed factor": 0.9930715464,
      "Uz factor": 0.9779186844,
      "Directional variance factor": 0.6289686968,
      "Velocity stability factor": 0.0952636885,
      "Direction stability factor": 0.3946497313,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0148126093, 0.3202776752, 0.7106904416],
      direction: [-177.6930273618, 118.008909565, 179.3556628581],
      "speed factor": 0.3602900097,
      "Uz factor": 0.966253756,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4239390881,
      "Direction stability factor": 0.0081980827,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0729631807, 0.2833069559, 0.5467031123],
      direction: [-179.159797935, 152.5434060037, 177.3994158123],
      "speed factor": 0.4024773897,
      "Uz factor": 0.966900797,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6125795369,
      "Direction stability factor": 0.0095577396,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1881103887, 0.924038212, 1.3619600207],
      direction: [-59.0369898055, -10.856311704, 158.1951971411],
      "speed factor": 1.0826543873,
      "Uz factor": 0.9994678879,
      "Directional variance factor": 0.0349945152,
      "Velocity stability factor": 0.0974245594,
      "Direction stability factor": 0.3965772585,
      score: 0.2313933979,
    },
    "247.5": {
      velocity: [0.0678899872, 0.2767458592, 0.8197062551],
      direction: [-166.0660407403, -31.3094853303, 159.2218485482],
      "speed factor": 0.5133969185,
      "Uz factor": 0.9651263833,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2704767008,
      "Direction stability factor": 0.0964225298,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0956391281, 0.6975776569, 1.1238812247],
      direction: [-118.7131464874, -21.6312922575, 173.9280745331],
      "speed factor": 0.9499268465,
      "Uz factor": 0.9798003955,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2459492354,
      "Direction stability factor": 0.1871077194,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.8229510036, 0.9906630102, 1.155346301],
      direction: [-9.6596525624, 8.9293724736, 36.6185724561],
      "speed factor": 1.2580805382,
      "Uz factor": 0.7973940405,
      "Directional variance factor": 0.2062780023,
      "Velocity stability factor": 0.7322773691,
      "Direction stability factor": 0.8714493749,
      score: 0.0,
    },
    id: "IDN:338.0",
    "45": {
      velocity: [0.094541618, 0.7013593533, 1.2399551475],
      direction: [-36.5567089378, -5.234104346, 73.0055284415],
      "speed factor": 0.9527135987,
      "Uz factor": 0.9127323269,
      "Directional variance factor": 0.5347462804,
      "Velocity stability factor": 0.068815045,
      "Direction stability factor": 0.6956604517,
      score: 0.0,
    },
    "90": {
      velocity: [0.0437967618, 0.4461183259, 0.9933491759],
      direction: [-165.1244547049, 10.8307347789, 177.7617114024],
      "speed factor": 0.7231708612,
      "Uz factor": 0.9954451272,
      "Directional variance factor": 0.0372680197,
      "Velocity stability factor": 0.3069620188,
      "Direction stability factor": 0.0475384275,
      score: 0.0,
    },
    "135": {
      velocity: [0.0852608824, 0.3237352825, 0.6192975052],
      direction: [-179.0743529906, 139.6610106663, 179.9829299088],
      "speed factor": 0.2739934615,
      "Uz factor": 0.9909073077,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5696675167,
      "Direction stability factor": 0.0026186586,
      score: 0.0,
    },
    "180": {
      velocity: [0.044677583, 0.2562686143, 0.6328586734],
      direction: [-178.6446468289, 133.5683416287, 178.8300217149],
      "speed factor": 0.5004910206,
      "Uz factor": 0.8275476422,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5365386628,
      "Direction stability factor": 0.0070148096,
      score: 0.0,
    },
    "225": {
      velocity: [0.0808941941, 0.4042395823, 0.9090804882],
      direction: [-177.5404715744, -55.1064057247, 172.8916505372],
      "speed factor": 0.551831268,
      "Uz factor": 0.960030539,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2556422175,
      "Direction stability factor": 0.0265774386,
      score: 0.0,
    },
    "270": {
      velocity: [0.2466644193, 0.9328359937, 1.3198250038],
      direction: [-41.3496150037, -14.2539290986, 4.0396773132],
      "speed factor": 0.8535750551,
      "Uz factor": 0.9870239312,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1534874923,
      "Direction stability factor": 0.8739186325,
      score: 0.0,
    },
    "315": {
      velocity: [0.7949797071, 0.9302201252, 1.0798851079],
      direction: [-12.4406404799, 9.2933823301, 26.4874154641],
      "speed factor": 1.1461237679,
      "Uz factor": 0.8380594209,
      "Directional variance factor": 0.1739215707,
      "Velocity stability factor": 0.7688744912,
      "Direction stability factor": 0.8918665113,
      score: 0.0,
    },
  },
  {
    position: [-55.19267986, 35.06998754, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1093336689, 0.5508890932, 0.9277939385],
      direction: [-27.1194363306, -7.6716600091, 71.4324577098],
      "speed factor": 0.5645241763,
      "Uz factor": 0.9761547519,
      "Directional variance factor": 0.3180746659,
      "Velocity stability factor": 0.4313086015,
      "Direction stability factor": 0.7262447388,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0797128931, 0.3087447445, 0.6861308264],
      direction: [-125.3449428728, -9.9803989818, 83.3164458696],
      "speed factor": 0.2741269411,
      "Uz factor": 0.9942705075,
      "Directional variance factor": 0.1128534238,
      "Velocity stability factor": 0.6078456231,
      "Direction stability factor": 0.4203850313,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0117224371, 0.1118062999, 0.2376120263],
      direction: [-178.6834540296, -83.9204049494, 179.1988492455],
      "speed factor": 0.1731954121,
      "Uz factor": 0.642907113,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7981553351,
      "Direction stability factor": 0.0058824909,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0056030153, 0.0979327369, 0.2122014817],
      direction: [-164.5345564125, -107.5066742015, 172.3110979886],
      "speed factor": 0.1101674873,
      "Uz factor": 0.8857883471,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.828973858,
      "Direction stability factor": 0.0643176267,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0224767753, 0.093922356, 0.413944933],
      direction: [-173.6440536761, -49.786796268, 88.4286210499],
      "speed factor": 0.1334299208,
      "Uz factor": 0.9507957761,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6798606898,
      "Direction stability factor": 0.272020348,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1107065403, 0.62291953, 0.9148085797],
      direction: [-167.3603047916, -23.7109525912, -10.4881036826],
      "speed factor": 0.7298470489,
      "Uz factor": 0.9254918917,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3817242578,
      "Direction stability factor": 0.5642438858,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2232102621, 0.4632376207, 0.6084992489],
      direction: [-35.2830913586, -0.6502090642, 21.64673652],
      "speed factor": 0.8593616095,
      "Uz factor": 0.6580775692,
      "Directional variance factor": 0.9422036387,
      "Velocity stability factor": 0.62613566,
      "Direction stability factor": 0.8418615892,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3646936924, 0.6710389618, 0.8032028827],
      direction: [-2.7986550746, 32.6808295762, 46.049915013],
      "speed factor": 0.9137877604,
      "Uz factor": 0.8729944501,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.678423796,
      "Direction stability factor": 0.8643095275,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1662159126, 0.4664302877, 0.7147336765],
      direction: [-12.9474216254, 6.677751628, 48.7799765199],
      "speed factor": 0.5923375167,
      "Uz factor": 0.9630714561,
      "Directional variance factor": 0.4064220775,
      "Velocity stability factor": 0.5582048843,
      "Direction stability factor": 0.8285350052,
      score: 0.0,
    },
    id: "IDN:65.0",
    "45": {
      velocity: [0.0079447643, 0.1798393388, 0.5191702434],
      direction: [-178.082026712, 0.3314698893, 176.7283021267],
      "speed factor": 0.244290438,
      "Uz factor": 0.9315562971,
      "Directional variance factor": 0.9705360098,
      "Velocity stability factor": 0.5843898623,
      "Direction stability factor": 0.0144157532,
      score: 0.0,
    },
    "90": {
      velocity: [0.0129680661, 0.1588022519, 0.4717842661],
      direction: [-150.2439250054, -83.5052308178, 176.5973811305],
      "speed factor": 0.2574230973,
      "Uz factor": 0.900346949,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6651295408,
      "Direction stability factor": 0.092107483,
      score: 0.0,
    },
    "135": {
      velocity: [0.0147350515, 0.1156221985, 0.4302879734],
      direction: [-176.6125934269, -79.8103391725, 178.5424124668],
      "speed factor": 0.0978568853,
      "Uz factor": 0.9967016833,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6651429636,
      "Direction stability factor": 0.013458317,
      score: 0.0,
    },
    "180": {
      velocity: [0.0359866393, 0.2154323765, 0.8403594166],
      direction: [-178.6229558087, -25.1544056569, 54.0190625329],
      "speed factor": 0.4207381004,
      "Uz factor": 0.9732793323,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3661889356,
      "Direction stability factor": 0.3537721713,
      score: 0.0,
    },
    "225": {
      velocity: [0.0342073405, 0.2695923207, 0.5620646296],
      direction: [-74.0689500729, -11.9380016455, 80.6733151862],
      "speed factor": 0.3680230207,
      "Uz factor": 0.7304128586,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5255721037,
      "Direction stability factor": 0.5701603743,
      score: 0.0,
    },
    "270": {
      velocity: [0.4629010294, 0.6785990332, 0.9099717687],
      direction: [32.681992064, 68.5003532669, 85.412124685],
      "speed factor": 0.6209400271,
      "Uz factor": 0.86062817,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6473491683,
      "Direction stability factor": 0.8535274094,
      score: 0.0,
    },
    "315": {
      velocity: [0.286123235, 0.7339761835, 0.9813880058],
      direction: [23.2608685365, 34.0566635157, 42.2215421914],
      "speed factor": 0.904331702,
      "Uz factor": 0.9358180884,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4359762101,
      "Direction stability factor": 0.9473314621,
      score: 0.0,
    },
  },
  {
    position: [-52.20709274, 79.56483697, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0903788762, 0.3255306564, 0.6600083795],
      direction: [-174.0623201726, 17.1691807625, 121.1876703367],
      "speed factor": 0.3335878817,
      "Uz factor": 0.9970227172,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6042038803,
      "Direction stability factor": 0.1798611375,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0621835163, 0.3003914123, 0.6944439503],
      direction: [-130.5731841623, 36.4367618313, 167.7595339884],
      "speed factor": 0.2667102208,
      "Uz factor": 0.9900086758,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.591133964,
      "Direction stability factor": 0.1712980051,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0237185508, 0.1500248601, 0.3436181556],
      direction: [-116.8673778959, 1.1689931905, 110.4479477184],
      "speed factor": 0.2323985097,
      "Uz factor": 0.954111821,
      "Directional variance factor": 0.8960894942,
      "Velocity stability factor": 0.71415226,
      "Direction stability factor": 0.36856854,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0506377902, 0.6443212876, 1.0913386276],
      direction: [-63.3842422268, -11.4359706445, 11.7948803974],
      "speed factor": 0.7248164357,
      "Uz factor": 0.9906804871,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1384880427,
      "Direction stability factor": 0.7911691038,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2924292786, 0.6903170917, 1.0012733407],
      direction: [-67.1786134332, -45.6277187474, 27.0676165074],
      "speed factor": 0.9806925507,
      "Uz factor": 0.9717493536,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4203133904,
      "Direction stability factor": 0.7382049168,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1788046031, 0.4612528831, 0.6545310607],
      direction: [-89.3071271185, -19.8456896943, 40.0958524701],
      "speed factor": 0.5404294444,
      "Uz factor": 0.7435975966,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6342129304,
      "Direction stability factor": 0.6405472789,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1357629395, 0.3002026141, 0.524167371],
      direction: [-51.3178781988, -2.8715760387, 51.3265762705],
      "speed factor": 0.5569120254,
      "Uz factor": 0.9212519669,
      "Directional variance factor": 0.7447487966,
      "Velocity stability factor": 0.6231125949,
      "Direction stability factor": 0.7148765154,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0963714495, 0.49182772, 0.7566229676],
      direction: [-28.6971023614, -3.7264888376, 25.9519663025],
      "speed factor": 0.6697467306,
      "Uz factor": 0.9996776967,
      "Directional variance factor": 0.6687565478,
      "Velocity stability factor": 0.5158113409,
      "Direction stability factor": 0.8481970315,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0449456304, 0.135675623, 0.3356228374],
      direction: [-35.7937878981, 32.2400930061, 74.0425328708],
      "speed factor": 0.1722996206,
      "Uz factor": 0.9880153631,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7658785572,
      "Direction stability factor": 0.694899109,
      score: 0.0,
    },
    id: "IDN:43.0",
    "45": {
      velocity: [0.0104301297, 0.1225730833, 0.487137441],
      direction: [-177.2061904422, 15.1520232102, 128.8181124279],
      "speed factor": 0.1665010137,
      "Uz factor": 0.9514256468,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6124520404,
      "Direction stability factor": 0.149932492,
      score: 0.0,
    },
    "90": {
      velocity: [0.1074151958, 0.5620128809, 1.0858230136],
      direction: [-55.0213473393, 3.3555110002, 51.7963263771],
      "speed factor": 0.9110393261,
      "Uz factor": 0.9932292229,
      "Directional variance factor": 0.7017323555,
      "Velocity stability factor": 0.2859016852,
      "Direction stability factor": 0.7032842397,
      score: 0.0,
    },
    "135": {
      velocity: [0.1136691777, 0.7954772898, 1.1130036465],
      direction: [-79.3677294185, -34.8826511147, -5.4731739867],
      "speed factor": 0.6732524626,
      "Uz factor": 0.9935536768,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1947254826,
      "Direction stability factor": 0.794737346,
      score: 0.0,
    },
    "180": {
      velocity: [0.2234508327, 0.7236412544, 1.0624655493],
      direction: [-82.0316249616, -73.1235904445, 16.1464637103],
      "speed factor": 1.4132668995,
      "Uz factor": 0.9828587056,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3388925812,
      "Direction stability factor": 0.727283087,
      score: 0.0,
    },
    "225": {
      velocity: [0.2682932833, 0.5159493517, 0.6766840308],
      direction: [-26.7454902286, 10.9286558163, 37.6583888736],
      "speed factor": 0.7043273283,
      "Uz factor": 0.9377615587,
      "Directional variance factor": 0.0285639274,
      "Velocity stability factor": 0.6329463149,
      "Direction stability factor": 0.8211003358,
      score: 0.0,
    },
    "270": {
      velocity: [0.4371022924, 0.6322267393, 0.9423613554],
      direction: [-1.8511010344, 21.5451961227, 47.4714848519],
      "speed factor": 0.5785078808,
      "Uz factor": 0.9983260803,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6014500321,
      "Direction stability factor": 0.862992817,
      score: 0.0,
    },
    "315": {
      velocity: [0.0279509338, 0.1101417424, 0.2024469527],
      direction: [-46.1971957231, -4.9869065561, 31.1651711926],
      "speed factor": 0.1357055877,
      "Uz factor": 0.9970279859,
      "Directional variance factor": 0.5567194172,
      "Velocity stability factor": 0.8584425531,
      "Direction stability factor": 0.7851045363,
      score: 0.0,
    },
  },
  {
    position: [-52.61952834, 79.68102978, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0735019725, 0.4139474708, 0.8478869134],
      direction: [-59.5927481674, -3.344673672, 107.0677034309],
      "speed factor": 0.4241931051,
      "Uz factor": 0.9854603007,
      "Directional variance factor": 0.7026956736,
      "Velocity stability factor": 0.4619334971,
      "Direction stability factor": 0.5370543011,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0366195016, 0.2628839024, 0.6535549573],
      direction: [-174.0671487928, 37.0055330995, 171.2998206243],
      "speed factor": 0.233408216,
      "Uz factor": 0.9982264616,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6010442206,
      "Direction stability factor": 0.0406473072,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0113537523, 0.1273393218, 0.341430217],
      direction: [-171.0737926026, 8.7456312698, 84.0243329776],
      "speed factor": 0.1972570986,
      "Uz factor": 0.9762570799,
      "Directional variance factor": 0.2226105538,
      "Velocity stability factor": 0.7050586808,
      "Direction stability factor": 0.2913940956,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0468776755, 0.5781360797, 0.9990428194],
      direction: [-55.6433538829, -1.7290620207, 19.5842597411],
      "speed factor": 0.6503627005,
      "Uz factor": 0.9999894461,
      "Directional variance factor": 0.8463055982,
      "Velocity stability factor": 0.2117795746,
      "Direction stability factor": 0.7910344066,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2709818585, 0.6988621, 1.0198330897],
      direction: [-57.9553692582, -37.9527372036, 1.0499725791],
      "speed factor": 0.9928319372,
      "Uz factor": 0.9958156357,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3875958697,
      "Direction stability factor": 0.8360962727,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1776520287, 0.4973688482, 0.7564157322],
      direction: [-71.228531646, -38.7256319902, 33.1181386425],
      "speed factor": 0.582744911,
      "Uz factor": 0.7805628761,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5549873762,
      "Direction stability factor": 0.7101481381,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1166921244, 0.275950166, 0.4780462954],
      direction: [-56.488220397, 5.0223744794, 60.3813701619],
      "speed factor": 0.5119208116,
      "Uz factor": 0.8883026947,
      "Directional variance factor": 0.5535667129,
      "Velocity stability factor": 0.649360757,
      "Direction stability factor": 0.6753622484,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1981509352, 0.667685345, 0.9251288715],
      direction: [-19.3857521307, 9.998763448, 27.0992254528],
      "speed factor": 0.9092209705,
      "Uz factor": 0.9797950052,
      "Directional variance factor": 0.1112210268,
      "Velocity stability factor": 0.4668782085,
      "Direction stability factor": 0.8708750623,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1338658965, 0.296036314, 0.4763988051],
      direction: [-9.0374095462, 12.0301705255, 35.3909612157],
      "speed factor": 0.3759477455,
      "Uz factor": 0.9998447892,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7241121875,
      "Direction stability factor": 0.876587859,
      score: 0.0,
    },
    id: "IDN:42.0",
    "45": {
      velocity: [0.0109778668, 0.1587614134, 0.4782470547],
      direction: [-135.9005135529, 4.0502920734, 172.6328197364],
      "speed factor": 0.2156585732,
      "Uz factor": 0.97700475,
      "Directional variance factor": 0.6399740379,
      "Velocity stability factor": 0.6201249361,
      "Direction stability factor": 0.1429629631,
      score: 0.0,
    },
    "90": {
      velocity: [0.0776698671, 0.3911181832, 0.8916627283],
      direction: [-60.6972907151, 12.153796937, 63.1672465665],
      "speed factor": 0.6340140203,
      "Uz factor": 0.9999971775,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4059011795,
      "Direction stability factor": 0.6559318409,
      score: 0.0,
    },
    "135": {
      velocity: [0.1084372929, 0.6961681745, 1.0379157468],
      direction: [-120.8964475678, -26.4806178737, 6.2911231326],
      "speed factor": 0.5892021606,
      "Uz factor": 0.9994434731,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2510162146,
      "Direction stability factor": 0.6467011925,
      score: 0.0,
    },
    "180": {
      velocity: [0.2260395188, 0.717620981, 1.0303758247],
      direction: [-73.0985508169, -61.8281071244, -5.8346401497],
      "speed factor": 1.4015093427,
      "Uz factor": 0.9999863439,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3662176735,
      "Direction stability factor": 0.8131558037,
      score: 0.0,
    },
    "225": {
      velocity: [0.2440589909, 0.4367970709, 0.6639335959],
      direction: [-46.8480234905, 15.7538530803, 57.2691150234],
      "speed factor": 0.5962758028,
      "Uz factor": 0.7600996157,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6226248465,
      "Direction stability factor": 0.7107857264,
      score: 0.0,
    },
    "270": {
      velocity: [0.6150400186, 0.8207433156, 1.0298167548],
      direction: [11.5643905369, 34.0282645297, 47.9898981957],
      "speed factor": 0.7510066352,
      "Uz factor": 0.9766284448,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6728227814,
      "Direction stability factor": 0.8988180343,
      score: 0.0,
    },
    "315": {
      velocity: [0.2609025888, 0.4431655562, 0.6445522417],
      direction: [-6.2892284875, 9.1644770988, 18.5319665051],
      "speed factor": 0.5460240682,
      "Uz factor": 0.9996909207,
      "Directional variance factor": 0.1853798134,
      "Velocity stability factor": 0.6887696021,
      "Direction stability factor": 0.9310522361,
      score: 0.0,
    },
  },
  {
    position: [-46.153284, 82.7039409, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0222220944, 0.221421508, 0.6860574102],
      direction: [-156.9535695885, 125.1950822033, 176.8448266455],
      "speed factor": 0.2269019226,
      "Uz factor": 0.9008681567,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5387467808,
      "Direction stability factor": 0.0727822327,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0544890657, 0.2840837874, 0.8398491983],
      direction: [-118.2884678298, 46.6268708047, 167.9443614811],
      "speed factor": 0.2522310777,
      "Uz factor": 0.8000833176,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4921284537,
      "Direction stability factor": 0.2049088075,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0244137416, 0.2058384866, 0.4914965651],
      direction: [-131.8977406693, 6.2783144224, 118.8112330642],
      "speed factor": 0.318857538,
      "Uz factor": 0.8495833237,
      "Directional variance factor": 0.4419276069,
      "Velocity stability factor": 0.5826360287,
      "Direction stability factor": 0.3035861841,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1019051494, 0.4884449445, 0.7587932814],
      direction: [-76.6979536483, 11.4309608373, 54.228363041],
      "speed factor": 0.5494664394,
      "Uz factor": 0.8183350324,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.456215504,
      "Direction stability factor": 0.636315787,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.08491041, 0.5036375462, 0.8779060955],
      direction: [-106.5712200945, -6.1743637819, 23.1494626739],
      "speed factor": 0.7154879919,
      "Uz factor": 0.7427382751,
      "Directional variance factor": 0.4511676638,
      "Velocity stability factor": 0.3514949127,
      "Direction stability factor": 0.6396647701,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1456800276, 0.4649336705, 0.8127358179],
      direction: [-101.6896971363, -48.1108951479, -6.8543773788],
      "speed factor": 0.5447420589,
      "Uz factor": 0.9732816302,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4870994057,
      "Direction stability factor": 0.7365685562,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0124014204, 0.1672763446, 0.4318612109],
      direction: [-162.8895924501, -13.7612540471, 168.6792449779],
      "speed factor": 0.3103177772,
      "Uz factor": 0.9998638912,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5929780941,
      "Direction stability factor": 0.0789754516,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0215829726, 0.0975001011, 0.3055008813],
      direction: [-159.3085979675, 75.9882132064, 166.098624287],
      "speed factor": 0.1327708287,
      "Uz factor": 0.9522414574,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7917917221,
      "Direction stability factor": 0.0960910493,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0063738344, 0.0841847735, 0.2701887712],
      direction: [-179.2697057668, 142.6874416463, 176.4125090349],
      "speed factor": 0.1069094374,
      "Uz factor": 0.9654437743,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7875143556,
      "Direction stability factor": 0.0119938478,
      score: 0.0,
    },
    id: "IDN:50.0",
    "45": {
      velocity: [0.0187653902, 0.14133171, 0.3964923109],
      direction: [-171.8513670006, 14.0330378985, 164.1449067578],
      "speed factor": 0.1919823859,
      "Uz factor": 0.8392372454,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.692919966,
      "Direction stability factor": 0.0666770173,
      score: 0.0,
    },
    "90": {
      velocity: [0.1203207419, 0.4137993508, 0.719688969],
      direction: [-59.4517816722, 10.0000837857, 69.9959753552],
      "speed factor": 0.6707808567,
      "Uz factor": 0.6514085851,
      "Directional variance factor": 0.1111036635,
      "Velocity stability factor": 0.5625465852,
      "Direction stability factor": 0.6404228971,
      score: 0.0,
    },
    "135": {
      velocity: [0.2001323164, 0.6108222044, 0.800666233],
      direction: [-96.5820457785, -29.3734628831, 12.5273099142],
      "speed factor": 0.5169695711,
      "Uz factor": 0.6594426004,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5160832785,
      "Direction stability factor": 0.6969184564,
      score: 0.0,
    },
    "180": {
      velocity: [0.1319517217, 0.5883655335, 1.0512529225],
      direction: [-85.525144576, -35.9732108666, -17.2032519803],
      "speed factor": 1.1490742522,
      "Uz factor": 0.8370960502,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2756302935,
      "Direction stability factor": 0.810216965,
      score: 0.0,
    },
    "225": {
      velocity: [0.0127145046, 0.2348517777, 0.6497775761],
      direction: [-176.6918927324, -5.9068419372, 179.6133420912],
      "speed factor": 0.3205983776,
      "Uz factor": 0.9952267736,
      "Directional variance factor": 0.4749473834,
      "Velocity stability factor": 0.427420064,
      "Direction stability factor": 0.0102632366,
      score: 0.0,
    },
    "270": {
      velocity: [0.0255977701, 0.1190552776, 0.2501727552],
      direction: [-175.1607886823, 69.723915863, 175.8525533139],
      "speed factor": 0.1089394232,
      "Uz factor": 0.9952482186,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8228545321,
      "Direction stability factor": 0.0249629389,
      score: 0.0,
    },
    "315": {
      velocity: [0.0519810062, 0.1638758748, 0.4327901509],
      direction: [-12.4839288846, 67.358165987, 173.3374353243],
      "speed factor": 0.2019113864,
      "Uz factor": 0.9959624404,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6910739246,
      "Direction stability factor": 0.4838295439,
      score: 0.0,
    },
  },
  {
    position: [-55.0853934, 38.06806853, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2191869232, 0.7422578773, 1.2549182612],
      direction: [-19.8583553707, -7.1279037402, 110.5990329066],
      "speed factor": 0.7606295386,
      "Uz factor": 0.9907180459,
      "Directional variance factor": 0.3664085564,
      "Velocity stability factor": 0.2803419727,
      "Direction stability factor": 0.6376183659,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0337957228, 0.2706454697, 0.6925452719],
      direction: [-143.7080598751, -11.0227509265, 141.4380622139],
      "speed factor": 0.2402995226,
      "Uz factor": 0.9852626722,
      "Directional variance factor": 0.0201999176,
      "Velocity stability factor": 0.5740041566,
      "Direction stability factor": 0.2079274386,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0092113326, 0.0989497327, 0.3238656582],
      direction: [-176.3877533498, -69.753100085, 178.8256829897],
      "speed factor": 0.1532797324,
      "Uz factor": 0.8917733955,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.718839203,
      "Direction stability factor": 0.0132960102,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0285991678, 0.1215963932, 0.3714577134],
      direction: [-175.4487292286, -102.2170597129, 168.8636032912],
      "speed factor": 0.1367874475,
      "Uz factor": 0.9774060775,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7161751715,
      "Direction stability factor": 0.0435768541,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0305729893, 0.2755475214, 0.6162144859],
      direction: [-170.9677410606, -11.6854637695, 158.4943227889],
      "speed factor": 0.3914540213,
      "Uz factor": 0.9944795228,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5210673944,
      "Direction stability factor": 0.0848276004,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0393387578, 0.5321949279, 0.8547970444],
      direction: [-162.4401097398, -16.0066130004, -6.0462140675],
      "speed factor": 0.6235490764,
      "Uz factor": 0.9975299909,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3729924155,
      "Direction stability factor": 0.565572512,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1347163975, 0.371422873, 0.5201768459],
      direction: [-25.7780747063, 0.1182531046, 23.1259875662],
      "speed factor": 0.6890341883,
      "Uz factor": 0.813595961,
      "Directional variance factor": 0.9894886129,
      "Velocity stability factor": 0.6259692827,
      "Direction stability factor": 0.8641553826,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3218489091, 0.688383547, 0.8266817171],
      direction: [-22.8669558194, 34.4346349525, 51.2617323191],
      "speed factor": 0.9374067611,
      "Uz factor": 0.8110293483,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6297860532,
      "Direction stability factor": 0.7940869774,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1641178563, 0.4540769344, 0.6970425547],
      direction: [-1.0429248277, 15.1228378811, 45.5540706284],
      "speed factor": 0.5766495248,
      "Uz factor": 0.946066005,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5707640768,
      "Direction stability factor": 0.8705639015,
      score: 0.0,
    },
    id: "IDN:66.0",
    "45": {
      velocity: [0.0227756941, 0.134656903, 0.48294908],
      direction: [-175.6444242784, -10.5494945489, 170.4562192724],
      "speed factor": 0.1829154513,
      "Uz factor": 0.9450199713,
      "Directional variance factor": 0.0622671512,
      "Velocity stability factor": 0.6258935986,
      "Direction stability factor": 0.0386093235,
      score: 0.0,
    },
    "90": {
      velocity: [0.0177421032, 0.1536860774, 0.4086734682],
      direction: [-177.0945030754, -80.3840619196, 163.6248321855],
      "speed factor": 0.2491296288,
      "Uz factor": 0.9433876314,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7146757989,
      "Direction stability factor": 0.0535574021,
      score: 0.0,
    },
    "135": {
      velocity: [0.0133281776, 0.1051476437, 0.2548412014],
      direction: [-179.2709748717, -106.5295139537, 174.7894891471],
      "speed factor": 0.0889917424,
      "Uz factor": 0.8786949936,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8053861948,
      "Direction stability factor": 0.0164987111,
      score: 0.0,
    },
    "180": {
      velocity: [0.0422584454, 0.2486927062, 0.6613078413],
      direction: [-163.1631749977, -29.7666353429, 149.6371037929],
      "speed factor": 0.4856953188,
      "Uz factor": 0.9951545703,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5122157692,
      "Direction stability factor": 0.1311103367,
      score: 0.0,
    },
    "225": {
      velocity: [0.0331418184, 0.3228212582, 0.6729431303],
      direction: [-78.0844390207, -9.1842068999, 95.145376867],
      "speed factor": 0.4406863455,
      "Uz factor": 0.9090839963,
      "Directional variance factor": 0.1836260533,
      "Velocity stability factor": 0.4249589866,
      "Direction stability factor": 0.518806067,
      score: 0.0,
    },
    "270": {
      velocity: [0.5746294627, 0.7962233472, 0.9935141415],
      direction: [-0.6607371147, 41.2014309608, 71.8418331309],
      "speed factor": 0.7285700725,
      "Uz factor": 0.8300749881,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6695824231,
      "Direction stability factor": 0.7986039715,
      score: 0.0,
    },
    "315": {
      velocity: [0.2221311268, 0.7003109072, 0.9697417469],
      direction: [-7.2674369134, 18.8121123308, 36.4999992753],
      "speed factor": 0.8628527313,
      "Uz factor": 0.9213581028,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3935113745,
      "Direction stability factor": 0.8784237884,
      score: 0.0,
    },
  },
  {
    position: [-46.31176664, 81.4640645, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0209949205, 0.191188693, 0.5666265729],
      direction: [-178.870883004, 138.0504378085, 178.9266575294],
      "speed factor": 0.1959208137,
      "Uz factor": 0.77967221,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6208783261,
      "Direction stability factor": 0.006117943,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0158677241, 0.2636572632, 0.7634716136],
      direction: [-85.1751319949, 32.1398148819, 123.1478324784],
      "speed factor": 0.2340948643,
      "Uz factor": 0.762126644,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5165444137,
      "Direction stability factor": 0.4213250987,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0124698649, 0.2109919198, 0.5348332951],
      direction: [-110.3235276988, 2.7680118058, 159.0307322218],
      "speed factor": 0.3268405496,
      "Uz factor": 0.779557492,
      "Directional variance factor": 0.7539545062,
      "Velocity stability factor": 0.5332397924,
      "Direction stability factor": 0.2517937224,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.102794234, 0.3771405101, 0.684594815],
      direction: [-88.5077284834, 7.0785781084, 49.7209329997],
      "speed factor": 0.4242567265,
      "Uz factor": 0.6659840511,
      "Directional variance factor": 0.370793057,
      "Velocity stability factor": 0.5183744077,
      "Direction stability factor": 0.6160314959,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1208433542, 0.5120599373, 0.8605788877],
      direction: [-86.0994521732, -13.8343469743, 36.8986526607],
      "speed factor": 0.7274531835,
      "Uz factor": 0.8757807212,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3950506094,
      "Direction stability factor": 0.6583385977,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0435163483, 0.3421903181, 0.6129926299],
      direction: [-94.8161827936, -35.8219469949, 10.8605186353],
      "speed factor": 0.4009291438,
      "Uz factor": 0.9794103086,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5621284944,
      "Direction stability factor": 0.7064536071,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0078977953, 0.0803751012, 0.2568686124],
      direction: [-163.9676355088, 3.9529848076, 179.8345447866],
      "speed factor": 0.1491054983,
      "Uz factor": 0.9897175003,
      "Directional variance factor": 0.6486235727,
      "Velocity stability factor": 0.7584117029,
      "Direction stability factor": 0.0449939436,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0264216857, 0.1070989735, 0.2581452941],
      direction: [-155.7681481061, 60.1561696449, 152.7810289454],
      "speed factor": 0.1458420996,
      "Uz factor": 0.9401169153,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8300678754,
      "Direction stability factor": 0.1429189526,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0078824804, 0.0889403585, 0.2995688805],
      direction: [-177.6529788494, 129.4278539502, 171.4589711774],
      "speed factor": 0.1129487352,
      "Uz factor": 0.9621868591,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7650657182,
      "Direction stability factor": 0.0302445833,
      score: 0.0,
    },
    id: "IDN:49.0",
    "45": {
      velocity: [0.0147022856, 0.1480203247, 0.401731835],
      direction: [-145.4464746316, 5.8198692096, 179.3863342326],
      "speed factor": 0.2010680767,
      "Uz factor": 0.8715092217,
      "Directional variance factor": 0.4826782925,
      "Velocity stability factor": 0.6853572232,
      "Direction stability factor": 0.097686642,
      score: 0.0,
    },
    "90": {
      velocity: [0.1331624105, 0.6111318872, 1.0037268684],
      direction: [-52.9002433924, 1.4267720339, 50.6515281027],
      "speed factor": 0.9906626728,
      "Uz factor": 0.9080108464,
      "Directional variance factor": 0.8731758192,
      "Velocity stability factor": 0.3646119736,
      "Direction stability factor": 0.7123561903,
      score: 0.0,
    },
    "135": {
      velocity: [0.23639923, 0.6128597339, 0.828571122],
      direction: [-96.9549756645, -36.8743204159, -0.0880235724],
      "speed factor": 0.5186940349,
      "Uz factor": 0.8126177567,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5228214883,
      "Direction stability factor": 0.7309251331,
      score: 0.0,
    },
    "180": {
      velocity: [0.2673312287, 0.6360382892, 1.0209595414],
      direction: [-81.3797458848, -30.3373465943, -13.1152877456],
      "speed factor": 1.2421788496,
      "Uz factor": 0.756134971,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4061733856,
      "Direction stability factor": 0.8103765052,
      score: 0.0,
    },
    "225": {
      velocity: [0.0157852112, 0.1041144582, 0.2942378775],
      direction: [-174.5056309974, -10.9450162855, 179.8397816563],
      "speed factor": 0.1421276293,
      "Uz factor": 0.9998095841,
      "Directional variance factor": 0.0271096635,
      "Velocity stability factor": 0.7497321428,
      "Direction stability factor": 0.0157071871,
      score: 0.0,
    },
    "270": {
      velocity: [0.0185277755, 0.1195582261, 0.3412285066],
      direction: [-119.6417576411, 57.6197130211, 154.3991593695],
      "speed factor": 0.1093996373,
      "Uz factor": 0.9954677127,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7454526293,
      "Direction stability factor": 0.2387752305,
      score: 0.0,
    },
    "315": {
      velocity: [0.0405534422, 0.1121180271, 0.3050516452],
      direction: [-179.494656739, 69.7693602781, 177.9247253771],
      "speed factor": 0.1381405671,
      "Uz factor": 0.9318008122,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7854295441,
      "Direction stability factor": 0.007168383,
      score: 0.0,
    },
  },
  {
    position: [-54.78806345, 32.40355892, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.116681816, 0.4285076968, 0.7608298335],
      direction: [-53.3444842796, -9.0544069182, 53.8479627264],
      "speed factor": 0.4391137119,
      "Uz factor": 0.9802108629,
      "Directional variance factor": 0.1951638295,
      "Velocity stability factor": 0.552426122,
      "Direction stability factor": 0.7022432028,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0381197761, 0.2056766317, 0.46164258],
      direction: [-152.0810221548, -18.7573927673, 106.5402333509],
      "speed factor": 0.1826152733,
      "Uz factor": 0.9872996633,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7261190473,
      "Direction stability factor": 0.2816076236,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0160599728, 0.1133376564, 0.3085339361],
      direction: [-165.2819090891, -89.3331110104, 178.6550511683],
      "speed factor": 0.1755675854,
      "Uz factor": 0.8776657037,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7386585662,
      "Direction stability factor": 0.0446195548,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0093230759, 0.1318459244, 0.5390383313],
      direction: [-178.1095194512, -48.4861969497, 170.872130233],
      "speed factor": 0.1483174541,
      "Uz factor": 0.9899242965,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5614916313,
      "Direction stability factor": 0.0306065287,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4578688231, 0.9205960351, 1.2008245068],
      direction: [-47.7348217155, -33.9705886388, -22.4638239969],
      "speed factor": 1.3078361881,
      "Uz factor": 0.9923211047,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3924171981,
      "Direction stability factor": 0.9298027841,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3925677041, 0.7349083518, 0.9553719335],
      direction: [-57.3560215378, -33.3963581854, -11.4177395799],
      "speed factor": 0.8610593599,
      "Uz factor": 0.9016819824,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5672586492,
      "Direction stability factor": 0.8723936612,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2897365802, 0.5194367064, 0.6910894723],
      direction: [-15.3663604757, 29.968979072, 46.3947325888],
      "speed factor": 0.9636176857,
      "Uz factor": 0.9269499257,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6105480841,
      "Direction stability factor": 0.8284414082,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3774979736, 0.8312174448, 0.985340109],
      direction: [17.6740221589, 36.236809064, 47.5639276513],
      "speed factor": 1.1319109181,
      "Uz factor": 0.944636293,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5542452226,
      "Direction stability factor": 0.9169724847,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0807975845, 0.3646958792, 0.6590755484],
      direction: [-34.8525241252, 5.7213818293, 110.0190235786],
      "speed factor": 0.463141132,
      "Uz factor": 0.9811838869,
      "Directional variance factor": 0.4914327263,
      "Velocity stability factor": 0.5342349933,
      "Direction stability factor": 0.5975790342,
      score: 0.0,
    },
    id: "IDN:63.0",
    "45": {
      velocity: [0.0220746103, 0.1402642816, 0.3948834805],
      direction: [-175.8070095206, -7.9992060241, 167.8802968414],
      "speed factor": 0.1905324108,
      "Uz factor": 0.8028059281,
      "Directional variance factor": 0.2889594645,
      "Velocity stability factor": 0.6969181853,
      "Direction stability factor": 0.0453130379,
      score: 0.0,
    },
    "90": {
      velocity: [0.0238925618, 0.1065192784, 0.2843787334],
      direction: [-172.5415224398, -112.8911078363, 165.5275643833],
      "speed factor": 0.1726708674,
      "Uz factor": 0.8984535232,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8098822057,
      "Direction stability factor": 0.0609192033,
      score: 0.0,
    },
    "135": {
      velocity: [0.0082070344, 0.2052199844, 0.5339562932],
      direction: [-168.0944291298, -41.4978673658, 178.6347187822],
      "speed factor": 0.1736880005,
      "Uz factor": 0.9990367061,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5763455642,
      "Direction stability factor": 0.036863478,
      score: 0.0,
    },
    "180": {
      velocity: [0.3253562558, 0.6071461848, 0.8344648498],
      direction: [-78.1654766738, -48.6093330584, -17.3937935338],
      "speed factor": 1.1857527482,
      "Uz factor": 0.975342546,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5988443804,
      "Direction stability factor": 0.8311897691,
      score: 0.0,
    },
    "225": {
      velocity: [0.0849551074, 0.3894110352, 0.7513934102],
      direction: [-59.0888599198, 5.8856428475, 87.0017465088],
      "speed factor": 0.5315886785,
      "Uz factor": 0.8526897638,
      "Directional variance factor": 0.4768317469,
      "Velocity stability factor": 0.4010181758,
      "Direction stability factor": 0.5941927599,
      score: 0.0,
    },
    "270": {
      velocity: [0.5159347984, 0.7982546247, 1.047025687],
      direction: [35.4381992593, 51.4708123985, 67.3386881009],
      "speed factor": 0.7304287569,
      "Uz factor": 0.946351878,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5810738053,
      "Direction stability factor": 0.911387531,
      score: 0.0,
    },
    "315": {
      velocity: [0.5110539376, 1.0815349486, 1.527460394],
      direction: [0.2384529521, 11.7661508634, 26.4646633669],
      "speed factor": 1.3325586889,
      "Uz factor": 0.9998887271,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1754545238,
      "Direction stability factor": 0.9271494155,
      score: 0.0,
    },
  },
  {
    position: [-54.97810694, 41.06614952, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0927351409, 0.7442577532, 1.1564271165],
      direction: [-174.6717294955, -3.1457115562, 132.2025411858],
      "speed factor": 0.7626789135,
      "Uz factor": 0.9831438941,
      "Directional variance factor": 0.720381195,
      "Velocity stability factor": 0.2609140607,
      "Direction stability factor": 0.1475714703,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0574267373, 0.3182196768, 0.926800287],
      direction: [-174.2560762664, -18.9447676168, 84.4322223088],
      "speed factor": 0.2825395028,
      "Uz factor": 0.9920024283,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4377992075,
      "Direction stability factor": 0.2814213928,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0118985274, 0.0988595992, 0.3608397108],
      direction: [-172.8007369363, 18.5505049163, 166.8542404639],
      "speed factor": 0.1531401096,
      "Uz factor": 0.9908944223,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.688202026,
      "Direction stability factor": 0.0565139517,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0304126961, 0.1589858209, 0.4248613595],
      direction: [-166.6505826755, -70.0701162852, 167.8480296338],
      "speed factor": 0.1788479416,
      "Uz factor": 0.9982125129,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6734678902,
      "Direction stability factor": 0.070837188,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0215513599, 0.1943818218, 0.6806204604],
      direction: [-175.5105912897, 12.4605825814, 176.6231482933],
      "speed factor": 0.2761467257,
      "Uz factor": 0.9915691085,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4610189282,
      "Direction stability factor": 0.0218507234,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.059101497, 0.5537285973, 0.9037470895],
      direction: [-158.0846336936, -15.7392972935, 0.2550360093],
      "speed factor": 0.648779117,
      "Uz factor": 0.9913822317,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3505502349,
      "Direction stability factor": 0.5601675842,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1375810676, 0.3853490723, 0.5435277681],
      direction: [-25.5191286054, -4.1818165479, 25.1794890084],
      "speed factor": 0.7148689661,
      "Uz factor": 0.7742788384,
      "Directional variance factor": 0.6282829735,
      "Velocity stability factor": 0.6060904919,
      "Direction stability factor": 0.8591705066,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2838308146, 0.651513336, 0.8119991574],
      direction: [-50.8595684889, 36.8647061932, 53.5137617063],
      "speed factor": 0.8871987262,
      "Uz factor": 0.6780608655,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6126731788,
      "Direction stability factor": 0.7100740828,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1704038676, 0.4989327661, 0.7494015626],
      direction: [-6.946271705, 12.5859506575, 35.4665320335],
      "speed factor": 0.633613647,
      "Uz factor": 0.9493961463,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5336552971,
      "Direction stability factor": 0.8821866563,
      score: 0.0,
    },
    id: "IDN:67.0",
    "45": {
      velocity: [0.0227510149, 0.2081468115, 0.6786741358],
      direction: [-156.1878716161, 17.8145808835, 178.5215271221],
      "speed factor": 0.2827427864,
      "Uz factor": 0.9998463754,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4667552582,
      "Direction stability factor": 0.0702516702,
      score: 0.0,
    },
    "90": {
      velocity: [0.0391042629, 0.2130162717, 0.4611775577],
      direction: [-124.0907074418, -65.9469067039, 79.8074673678],
      "speed factor": 0.3453056099,
      "Uz factor": 0.9999172809,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6919466269,
      "Direction stability factor": 0.4336161811,
      score: 0.0,
    },
    "135": {
      velocity: [0.0230213724, 0.1452926069, 0.42475405],
      direction: [-179.480162403, -70.6322835997, 173.0086767625],
      "speed factor": 0.1229684451,
      "Uz factor": 0.9922846079,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6762794658,
      "Direction stability factor": 0.0208643357,
      score: 0.0,
    },
    "180": {
      velocity: [0.0851033656, 0.2815595518, 0.5406953322],
      direction: [-65.0956967485, -11.6423463568, 129.5599484354],
      "speed factor": 0.5498840652,
      "Uz factor": 0.9978440885,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6410131752,
      "Direction stability factor": 0.4592898745,
      score: 0.0,
    },
    "225": {
      velocity: [0.0135810978, 0.2740108771, 0.632121055],
      direction: [-67.1752333006, -14.0463558918, 138.6726736994],
      "speed factor": 0.3740548338,
      "Uz factor": 0.8281145011,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.44406828,
      "Direction stability factor": 0.4282002583,
      score: 0.0,
    },
    "270": {
      velocity: [0.2819405778, 0.5197378792, 0.8308645931],
      direction: [21.7142618514, 72.4000796595, 83.1605693395],
      "speed factor": 0.4755769416,
      "Uz factor": 0.5629149054,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5670069778,
      "Direction stability factor": 0.8293158125,
      score: 0.0,
    },
    "315": {
      velocity: [0.1406231139, 0.5915506458, 0.8454100633],
      direction: [1.3841896664, 23.0958107733, 41.9941031421],
      "speed factor": 0.7288492655,
      "Uz factor": 0.8258153869,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4282514765,
      "Direction stability factor": 0.8871946848,
      score: 0.0,
    },
  },
  {
    position: [-54.87082048, 44.06423051, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1190253667, 0.7578570552, 1.1779632998],
      direction: [-21.1040547758, -4.4097460345, 146.8802027116],
      "speed factor": 0.7766148125,
      "Uz factor": 0.98064393,
      "Directional variance factor": 0.6080225747,
      "Velocity stability factor": 0.2642173159,
      "Direction stability factor": 0.5333770625,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0518925725, 0.3006429555, 0.7859335268],
      direction: [-131.4874162363, -21.9862576096, 95.3069748595],
      "speed factor": 0.2669335599,
      "Uz factor": 0.9878103037,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5253152038,
      "Direction stability factor": 0.3700155803,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0145298515, 0.103559781, 0.3320606873],
      direction: [-168.8782764978, 18.8435639502, 178.6816928766],
      "speed factor": 0.1604210046,
      "Uz factor": 0.9715134468,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7162688843,
      "Direction stability factor": 0.0345556406,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.020940838, 0.1385418639, 0.3392005337],
      direction: [-177.5518861114, -113.1331809369, 179.7842539348],
      "speed factor": 0.1558499183,
      "Uz factor": 0.996422809,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7365385675,
      "Direction stability factor": 0.007399611,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0075031326, 0.0878075329, 0.423883302],
      direction: [-177.404909162, 73.3260969027, 178.7717377838],
      "speed factor": 0.1247429542,
      "Uz factor": 0.6552626152,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6594878597,
      "Direction stability factor": 0.0106204252,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0527796026, 0.5650323324, 0.9343018831],
      direction: [-130.3859858289, -15.581219156, -7.1280976698],
      "speed factor": 0.6620231996,
      "Uz factor": 0.9900153529,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3221956722,
      "Direction stability factor": 0.6576169773,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1905727104, 0.4368243497, 0.6384601195],
      direction: [-29.986489248, -12.4007215521, 17.6013743819],
      "speed factor": 0.8103618088,
      "Uz factor": 0.8328469211,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5653934155,
      "Direction stability factor": 0.8678114899,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1536202971, 0.5640298191, 0.7402707151],
      direction: [-71.3980218708, 32.074453584, 55.7325754574],
      "speed factor": 0.7680679879,
      "Uz factor": 0.5823222827,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5697859505,
      "Direction stability factor": 0.6468594519,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1710576838, 0.5427882996, 0.7900609982],
      direction: [-13.3906522869, 8.9755809406, 24.7874625594],
      "speed factor": 0.6893074527,
      "Uz factor": 0.9673643127,
      "Directional variance factor": 0.2021705831,
      "Velocity stability factor": 0.5014333921,
      "Direction stability factor": 0.893949681,
      score: 0.0,
    },
    id: "IDN:68.0",
    "45": {
      velocity: [0.0273663786, 0.148236607, 0.4044050338],
      direction: [-177.147464743, 17.1517826527, 159.7586125331],
      "speed factor": 0.2013618706,
      "Uz factor": 0.9274238159,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.693479504,
      "Direction stability factor": 0.0641497853,
      score: 0.0,
    },
    "90": {
      velocity: [0.0471007393, 0.2708754785, 0.5671591048],
      direction: [-178.0824873587, -69.043865124, 56.9398579448],
      "speed factor": 0.4390970774,
      "Uz factor": 0.9947758906,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.620431485,
      "Direction stability factor": 0.3471601519,
      score: 0.0,
    },
    "135": {
      velocity: [0.0235679974, 0.1101538222, 0.3159476428],
      direction: [-178.7894278464, -45.9341834763, 141.9523866128],
      "speed factor": 0.0932287232,
      "Uz factor": 0.9183899627,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7643973213,
      "Direction stability factor": 0.1090505154,
      score: 0.0,
    },
    "180": {
      velocity: [0.028800084, 0.3081937247, 0.6621831412],
      direction: [-106.0502593521, -0.5076237989, 33.3717940044],
      "speed factor": 0.6019004404,
      "Uz factor": 0.9974221527,
      "Directional variance factor": 0.9548778845,
      "Velocity stability factor": 0.5009214621,
      "Direction stability factor": 0.6127165185,
      score: 0.0,
    },
    "225": {
      velocity: [0.0420792154, 0.3144551163, 0.7033275376],
      direction: [-73.806369352, -12.7420202841, 100.9524918543],
      "speed factor": 0.4292656463,
      "Uz factor": 0.8872102126,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4056828296,
      "Direction stability factor": 0.5145587189,
      score: 0.0,
    },
    "270": {
      velocity: [0.3407685777, 0.6489691194, 0.8618488413],
      direction: [-37.8941103482, 58.3178652619, 78.2246236869],
      "speed factor": 0.5938276992,
      "Uz factor": 0.6798269064,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5889702184,
      "Direction stability factor": 0.677447961,
      score: 0.0,
    },
    "315": {
      velocity: [0.2946301492, 0.6857673151, 0.9549096707],
      direction: [-26.103425323, 9.7219216769, 37.3373260712],
      "speed factor": 0.8449335785,
      "Uz factor": 0.8230870111,
      "Directional variance factor": 0.1358291843,
      "Velocity stability factor": 0.4643575028,
      "Direction stability factor": 0.8237756906,
      score: 0.0,
    },
  },
  {
    position: [-54.76353402, 47.0623115, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0653122226, 0.7448373229, 1.1045928888],
      direction: [-29.7782652719, -6.5071744421, 111.346071326],
      "speed factor": 0.7632728281,
      "Uz factor": 0.9925089171,
      "Directional variance factor": 0.421584494,
      "Velocity stability factor": 0.2778757902,
      "Direction stability factor": 0.6079879539,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0390375371, 0.3155695804, 0.9308669881],
      direction: [-174.0313461991, -21.3993668098, 159.2034860662],
      "speed factor": 0.2801865467,
      "Uz factor": 0.9914890299,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4232775723,
      "Direction stability factor": 0.0743476882,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0091144132, 0.0972072316, 0.2344319177],
      direction: [-165.8496094895, 69.5682479166, 175.7650702368],
      "speed factor": 0.150580482,
      "Uz factor": 0.9189640893,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7986665239,
      "Direction stability factor": 0.0510703341,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0222984705, 0.1183109853, 0.2973896926],
      direction: [-173.1346397729, -146.1629038847, 175.9698828658],
      "speed factor": 0.1330915932,
      "Uz factor": 0.9272947262,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7722742514,
      "Direction stability factor": 0.0302652149,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0240764596, 0.1180166056, 0.3744339055],
      direction: [-179.5021693386, 150.1226240474, 178.6156787116],
      "speed factor": 0.1676591922,
      "Uz factor": 0.6444313132,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7134806781,
      "Direction stability factor": 0.0052281999,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.087174164, 0.5317868646, 0.8150002937],
      direction: [-177.1197424876, -15.8178419259, -0.1730889678],
      "speed factor": 0.6230709668,
      "Uz factor": 0.990024789,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4403729645,
      "Direction stability factor": 0.508481518,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1789098403, 0.3895415647, 0.5823713993],
      direction: [-46.5760551464, -28.4877895564, 24.476497436],
      "speed factor": 0.7226465447,
      "Uz factor": 0.816661389,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6085019436,
      "Direction stability factor": 0.8026317984,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2155141699, 0.531503346, 0.6816392065],
      direction: [-82.7547662117, 16.3499385958, 47.3248331512],
      "speed factor": 0.7237750411,
      "Uz factor": 0.5408156614,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6581719992,
      "Direction stability factor": 0.6386677795,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2707268117, 0.5696819931, 0.7881851292],
      direction: [-7.306374136, 11.1905534746, 20.7425873265],
      "speed factor": 0.7234607743,
      "Uz factor": 0.9660843429,
      "Directional variance factor": 0.0052841356,
      "Velocity stability factor": 0.5832212331,
      "Direction stability factor": 0.9220862182,
      score: 0.0,
    },
    id: "IDN:69.0",
    "45": {
      velocity: [0.0202160574, 0.1461661154, 0.5477526855],
      direction: [-179.8798560092, 18.7688371939, 163.2594226904],
      "speed factor": 0.198549353,
      "Uz factor": 0.9227999185,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5711294142,
      "Direction stability factor": 0.0468353369,
      score: 0.0,
    },
    "90": {
      velocity: [0.0151131189, 0.1321393625, 0.3228253532],
      direction: [-177.6816958126, -125.083261308, 179.3009040587],
      "speed factor": 0.2142017734,
      "Uz factor": 0.9688062983,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7754139082,
      "Direction stability factor": 0.008381667,
      score: 0.0,
    },
    "135": {
      velocity: [0.0246621949, 0.1459639122, 0.461634052],
      direction: [-165.5803292812, -24.8683893833, 162.8007702308],
      "speed factor": 0.1235366045,
      "Uz factor": 0.9948482755,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6478833541,
      "Direction stability factor": 0.0878302791,
      score: 0.0,
    },
    "180": {
      velocity: [0.01474504, 0.2384275269, 0.7654609141],
      direction: [-176.6431175096, 5.3666955258, 175.3850676089],
      "speed factor": 0.4656474871,
      "Uz factor": 0.9991168368,
      "Directional variance factor": 0.5229603977,
      "Velocity stability factor": 0.4084682617,
      "Direction stability factor": 0.0221439302,
      score: 0.0,
    },
    "225": {
      velocity: [0.0334182544, 0.2573280509, 0.591285024],
      direction: [-83.1548713896, -18.0633210396, 78.4691197148],
      "speed factor": 0.3512809503,
      "Uz factor": 0.8482916744,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4986001645,
      "Direction stability factor": 0.5510444692,
      score: 0.0,
    },
    "270": {
      velocity: [0.5064288945, 0.7903710709, 0.9883884701],
      direction: [-24.8761616627, 20.1934424745, 50.33964072],
      "speed factor": 0.7232150507,
      "Uz factor": 0.734040283,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6198287424,
      "Direction stability factor": 0.7910672156,
      score: 0.0,
    },
    "315": {
      velocity: [0.0749255214, 0.5903283413, 0.8319251833],
      direction: [-21.2417220728, 17.4538836861, 41.9277531517],
      "speed factor": 0.7273432649,
      "Uz factor": 0.7650836495,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.385894646,
      "Direction stability factor": 0.8245292355,
      score: 0.0,
    },
  },
  {
    position: [-46.31567545, 80.34427683, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0394776513, 0.1808690108, 0.6819044816],
      direction: [-179.8761194082, 137.9677341447, 178.5864388325],
      "speed factor": 0.1853457086,
      "Uz factor": 0.8085876307,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5536220558,
      "Direction stability factor": 0.0042706716,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0447211756, 0.2735478185, 0.8069171274],
      direction: [-53.6666449451, 10.5668010876, 155.866938054],
      "speed factor": 0.2428764475,
      "Uz factor": 0.8937604766,
      "Directional variance factor": 0.0607287922,
      "Velocity stability factor": 0.5071081144,
      "Direction stability factor": 0.4179622694,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0301465659, 0.2255306414, 0.6503102453],
      direction: [-103.4046140144, -29.5449325917, 146.0878776456],
      "speed factor": 0.3493619986,
      "Uz factor": 0.9506715036,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4458499371,
      "Direction stability factor": 0.3069653009,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1759779619, 0.5617441013, 0.9682448109],
      direction: [-62.2253973596, -7.1564766224, 56.1537172562],
      "speed factor": 0.63192287,
      "Uz factor": 0.8375614577,
      "Directional variance factor": 0.3638687447,
      "Velocity stability factor": 0.3441464259,
      "Direction stability factor": 0.6711691261,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1552980361, 0.4352531058, 0.7023660822],
      direction: [-93.7189329851, -12.3431614441, 39.4271534424],
      "speed factor": 0.6183382732,
      "Uz factor": 0.8033607375,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5526124322,
      "Direction stability factor": 0.6301497599,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1410697816, 0.4050418409, 0.6738612859],
      direction: [-90.653255187, -19.8736099884, 45.3998251405],
      "speed factor": 0.4745694717,
      "Uz factor": 0.9954564833,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5903354965,
      "Direction stability factor": 0.6220747769,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0190361391, 0.0876110551, 0.2566960187],
      direction: [-46.5341262591, -4.9264738224, 87.2922025556],
      "speed factor": 0.1625290648,
      "Uz factor": 0.9976204174,
      "Directional variance factor": 0.5620912158,
      "Velocity stability factor": 0.7693872467,
      "Direction stability factor": 0.6282601977,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0203369566, 0.08867258, 0.2012123163],
      direction: [-170.8436346398, 29.618771757, 179.2114701422],
      "speed factor": 0.1207499458,
      "Uz factor": 0.9549534849,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8673569155,
      "Direction stability factor": 0.0276247089,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0135226627, 0.0859266422, 0.2681763565],
      direction: [-173.1376036645, 149.0341970739, 173.8957923308],
      "speed factor": 0.1091215026,
      "Uz factor": 0.8104764903,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7948931365,
      "Direction stability factor": 0.0360183445,
      score: 0.0,
    },
    id: "IDN:48.0",
    "45": {
      velocity: [0.0171464149, 0.1808949927, 0.4840193249],
      direction: [-138.6143172447, -6.1508269262, 118.3577770055],
      "speed factor": 0.2457244188,
      "Uz factor": 0.9375877667,
      "Directional variance factor": 0.4532598288,
      "Velocity stability factor": 0.6204470975,
      "Direction stability factor": 0.2861886271,
      score: 0.0,
    },
    "90": {
      velocity: [0.1539869681, 0.6248518675, 0.9925451772],
      direction: [-45.2951158751, -0.9892886875, 43.1604619993],
      "speed factor": 1.0129031624,
      "Uz factor": 0.9299894754,
      "Directional variance factor": 0.9120632278,
      "Velocity stability factor": 0.3879719754,
      "Direction stability factor": 0.7542900615,
      score: 0.0,
    },
    "135": {
      velocity: [0.2488683031, 0.6912668837, 0.9258983927],
      direction: [-110.1504863469, -39.1728813334, -7.1266950584],
      "speed factor": 0.5850539515,
      "Uz factor": 0.7148352294,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4544418354,
      "Direction stability factor": 0.713822802,
      score: 0.0,
    },
    "180": {
      velocity: [0.3336396792, 0.5972609975, 0.9768206219],
      direction: [-89.7518789969, -28.0258468273, -6.6303371301],
      "speed factor": 1.1664470384,
      "Uz factor": 0.7850227659,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4932011508,
      "Direction stability factor": 0.7691068281,
      score: 0.0,
    },
    "225": {
      velocity: [0.0299238331, 0.1661142009, 0.3536070624],
      direction: [-41.5423855341, -0.6112578541, 25.2616941747],
      "speed factor": 0.226764063,
      "Uz factor": 0.9972008006,
      "Directional variance factor": 0.9456659685,
      "Velocity stability factor": 0.7090797898,
      "Direction stability factor": 0.8144331119,
      score: 0.0,
    },
    "270": {
      velocity: [0.013995998, 0.0935268417, 0.3129236998],
      direction: [-99.1980381436, 18.2028663621, 96.9012801061],
      "speed factor": 0.0855800801,
      "Uz factor": 0.9619768874,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.764204871,
      "Direction stability factor": 0.4552796715,
      score: 0.0,
    },
    "315": {
      velocity: [0.0195114259, 0.1089132678, 0.3158076773],
      direction: [-174.5809506912, 67.6084436085, 177.8810620457],
      "speed factor": 0.1341919847,
      "Uz factor": 0.8898589865,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7596338235,
      "Direction stability factor": 0.0209388535,
      score: 0.0,
    },
  },
  {
    position: [-54.69268291, 35.06824222, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0573574026, 0.3304347298, 0.8062647809],
      direction: [-46.9205322881, -3.1045156758, 154.9478771966],
      "speed factor": 0.338613336,
      "Uz factor": 0.9969535288,
      "Directional variance factor": 0.724043051,
      "Velocity stability factor": 0.4796360922,
      "Direction stability factor": 0.4392544181,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0390298736, 0.256857702, 0.7225494732],
      direction: [-127.0931732903, -8.8870302955, 88.6853230337],
      "speed factor": 0.2280576994,
      "Uz factor": 0.9887837271,
      "Directional variance factor": 0.2100417515,
      "Velocity stability factor": 0.5579860225,
      "Direction stability factor": 0.400615288,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0083103751, 0.1039413301, 0.2989266657],
      direction: [-179.5730135876, 8.062713958, 175.3341238065],
      "speed factor": 0.1610120496,
      "Uz factor": 0.9934855342,
      "Directional variance factor": 0.2833143148,
      "Velocity stability factor": 0.740318498,
      "Direction stability factor": 0.0141468406,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.005758785, 0.0943531587, 0.3875049732],
      direction: [-176.7514090554, -72.9857740571, 175.5085881415],
      "speed factor": 0.1061407119,
      "Uz factor": 0.7182777329,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6839832409,
      "Direction stability factor": 0.0215000078,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0155747272, 0.112375339, 0.6336377023],
      direction: [-174.3502308226, -60.4101373022, 178.2452724775],
      "speed factor": 0.1596449793,
      "Uz factor": 0.9003861759,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4945533868,
      "Direction stability factor": 0.0205680464,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.044163913, 0.4311049642, 0.693980434],
      direction: [-157.4146052701, -16.4580330708, 48.4871600818],
      "speed factor": 0.5051064716,
      "Uz factor": 0.9861779482,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.500354716,
      "Direction stability factor": 0.4280506518,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0723415967, 0.1984853161, 0.2968820422],
      direction: [-24.9284708968, 13.7191664211, 26.4404991769],
      "speed factor": 0.36821418,
      "Uz factor": 0.7438401947,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7821176615,
      "Direction stability factor": 0.8573084165,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1095145766, 0.2803676035, 0.4401321247],
      direction: [-9.9615992893, 26.1553034791, 42.4927126226],
      "speed factor": 0.3817907738,
      "Uz factor": 0.9974413915,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7575450219,
      "Direction stability factor": 0.854293578,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0271188019, 0.1946126111, 0.4665442253],
      direction: [-58.2367032481, 6.2734429992, 80.6924485303],
      "speed factor": 0.2471459376,
      "Uz factor": 0.9900972063,
      "Directional variance factor": 0.4423606223,
      "Velocity stability factor": 0.646071616,
      "Direction stability factor": 0.6140856895,
      score: 0.0,
    },
    id: "IDN:64.0",
    "45": {
      velocity: [0.0329973679, 0.2211866979, 0.7171976366],
      direction: [-178.5851241897, -0.0127817497, 177.46211894],
      "speed factor": 0.3004559274,
      "Uz factor": 0.9783336851,
      "Directional variance factor": 0.9988638445,
      "Velocity stability factor": 0.4437668317,
      "Direction stability factor": 0.0109798802,
      score: 0.0,
    },
    "90": {
      velocity: [0.0046557177, 0.0971486835, 0.4361785522],
      direction: [-160.5329995208, -40.1604592962, 172.9439732221],
      "speed factor": 0.1574808588,
      "Uz factor": 0.918990831,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6850498092,
      "Direction stability factor": 0.0736750757,
      score: 0.0,
    },
    "135": {
      velocity: [0.0139152328, 0.1619151597, 0.6880881675],
      direction: [-176.8645642038, -42.290527827, 171.8605790577],
      "speed factor": 0.137036948,
      "Uz factor": 0.9845905914,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4567441617,
      "Direction stability factor": 0.0313190465,
      score: 0.0,
    },
    "180": {
      velocity: [0.0204929652, 0.1009670576, 0.4294662515],
      direction: [-169.6904393089, -94.7518695062, 178.8611852494],
      "speed factor": 0.1971880397,
      "Uz factor": 0.841893713,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6777466851,
      "Direction stability factor": 0.0318010429,
      score: 0.0,
    },
    "225": {
      velocity: [0.011439742, 0.117040363, 0.2953774225],
      direction: [-93.4098038022, -2.6372594074, 70.8139382738],
      "speed factor": 0.1597729039,
      "Uz factor": 0.9484547675,
      "Directional variance factor": 0.7655769416,
      "Velocity stability factor": 0.7448023183,
      "Direction stability factor": 0.5438229387,
      score: 0.0,
    },
    "270": {
      velocity: [0.1931575855, 0.2796811514, 0.415384808],
      direction: [26.7700442011, 60.8434152031, 84.2902694293],
      "speed factor": 0.2559172843,
      "Uz factor": 0.9955490514,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8247064548,
      "Direction stability factor": 0.8402215966,
      score: 0.0,
    },
    "315": {
      velocity: [0.1048642189, 0.3376786407, 0.5229839786],
      direction: [15.8513716437, 34.8801628945, 47.8903137369],
      "speed factor": 0.4160536905,
      "Uz factor": 0.9994108691,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6608062117,
      "Direction stability factor": 0.9110029386,
      score: 0.0,
    },
  },
  {
    position: [-49.20711102, 79.55436501, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0317828543, 0.2265064644, 0.5322831453],
      direction: [-176.8467890347, 63.2906753328, 179.0646128209],
      "speed factor": 0.232112737,
      "Uz factor": 0.9055966044,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6522369857,
      "Direction stability factor": 0.0113572171,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0367280278, 0.261642344, 0.519723641],
      direction: [-165.2760028675, 63.2787577384, 175.0296614995],
      "speed factor": 0.2323058665,
      "Uz factor": 0.9060073858,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6876595606,
      "Direction stability factor": 0.0547064879,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0124574696, 0.0982156471, 0.2622009355],
      direction: [-148.1522349576, -36.6682683334, 160.6002752763],
      "speed factor": 0.152142585,
      "Uz factor": 0.7808249249,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7768405955,
      "Direction stability factor": 0.1423541382,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0427288865, 0.5278867556, 0.9079864505],
      direction: [-127.7531314243, -9.5565589923, 40.9525438463],
      "speed factor": 0.5938357214,
      "Uz factor": 0.9874351766,
      "Directional variance factor": 0.1505280896,
      "Velocity stability factor": 0.283723323,
      "Direction stability factor": 0.5313731242,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1354954916, 0.5228777794, 0.7677787745],
      direction: [-63.8532607781, -34.3130717786, 81.2100692336],
      "speed factor": 0.7428214502,
      "Uz factor": 0.920062605,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4829241406,
      "Direction stability factor": 0.5970463055,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2724601581, 0.4694567599, 0.7691626604],
      direction: [-65.2095834206, 7.5707498123, 51.3268848994],
      "speed factor": 0.5500415612,
      "Uz factor": 0.8742785921,
      "Directional variance factor": 0.3270444611,
      "Velocity stability factor": 0.6180844056,
      "Direction stability factor": 0.676287588,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1053667575, 0.2911420643, 0.5229989975],
      direction: [-22.1027181361, 0.8184533626, 16.478455597],
      "speed factor": 0.5401036138,
      "Uz factor": 0.918913912,
      "Directional variance factor": 0.92724859,
      "Velocity stability factor": 0.5947514538,
      "Direction stability factor": 0.892830073,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0203622758, 0.2632467447, 0.6060531989],
      direction: [-86.8518356382, -21.4414080129, 75.7752220675],
      "speed factor": 0.3584764327,
      "Uz factor": 0.9877769286,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5704895862,
      "Direction stability factor": 0.548258173,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0139708724, 0.0931811742, 0.2314725133],
      direction: [-86.5371214689, 46.6322612236, 173.7474120224],
      "speed factor": 0.1183343081,
      "Uz factor": 0.9700717138,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8248166807,
      "Direction stability factor": 0.276987407,
      score: 0.0,
    },
    id: "IDN:44.0",
    "45": {
      velocity: [0.0127156771, 0.0990773532, 0.4866550597],
      direction: [-168.4261762578, -16.0188248782, 176.2136631612],
      "speed factor": 0.1345848476,
      "Uz factor": 0.705885987,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6147022789,
      "Direction stability factor": 0.0426671127,
      score: 0.0,
    },
    "90": {
      velocity: [0.0335728615, 0.5298354495, 1.0271865672],
      direction: [-69.387491388, 1.904187951, 23.7904910096],
      "speed factor": 0.8588787683,
      "Uz factor": 0.9938290449,
      "Directional variance factor": 0.8307388488,
      "Velocity stability factor": 0.2748035533,
      "Direction stability factor": 0.7411722711,
      score: 0.0,
    },
    "135": {
      velocity: [0.0973646806, 0.6855123018, 1.0562593827],
      direction: [-97.7035580677, -31.0360803312, -10.7355038028],
      "speed factor": 0.5801835593,
      "Uz factor": 0.9833498039,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2273122839,
      "Direction stability factor": 0.7584220715,
      score: 0.0,
    },
    "180": {
      velocity: [0.3040931852, 0.6023291527, 0.9687528418],
      direction: [-83.9634972769, -67.3161994993, 16.8701831138],
      "speed factor": 1.1763451142,
      "Uz factor": 0.902791751,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4762768504,
      "Direction stability factor": 0.7199064434,
      score: 0.0,
    },
    "225": {
      velocity: [0.279146984, 0.5640683422, 0.8305171837],
      direction: [-12.2399828005, 17.2464795816, 31.2237337227],
      "speed factor": 0.7700150163,
      "Uz factor": 0.9323454456,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5044391557,
      "Direction stability factor": 0.8792674541,
      score: 0.0,
    },
    "270": {
      velocity: [0.0238019905, 0.3299110418, 0.9324490204],
      direction: [-178.6827506567, -3.1416662296, 164.2258357674],
      "speed factor": 0.301879256,
      "Uz factor": 0.9762410853,
      "Directional variance factor": 0.7207407796,
      "Velocity stability factor": 0.2832563113,
      "Direction stability factor": 0.0474761488,
      score: 0.0,
    },
    "315": {
      velocity: [0.0222110439, 0.1371821063, 0.2153301683],
      direction: [-179.0080826206, 147.232581758, 175.4877332412],
      "speed factor": 0.169022007,
      "Uz factor": 0.924186617,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8433348201,
      "Direction stability factor": 0.0152894004,
      score: 0.0,
    },
  },
  {
    position: [-35.50511975, 90.5113871, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0442430975, 0.2724329099, 0.8438623144],
      direction: [-172.8427394183, -3.8518118811, 178.5873538828],
      "speed factor": 0.2791759103,
      "Uz factor": 0.9844013406,
      "Directional variance factor": 0.6576167217,
      "Velocity stability factor": 0.4443999451,
      "Direction stability factor": 0.0238052964,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3428680425, 1.0986718247, 1.4662537768],
      direction: [-26.087213733, -11.1575469159, -3.6025902328],
      "speed factor": 0.9754839618,
      "Uz factor": 0.9963220817,
      "Directional variance factor": 0.0082180519,
      "Velocity stability factor": 0.2735362718,
      "Direction stability factor": 0.9375427125,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1557398408, 0.4604739456, 0.8782067231],
      direction: [-60.7390411246, -44.8632621539, 33.1203876859],
      "speed factor": 0.7133048393,
      "Uz factor": 0.9579603978,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.354436447,
      "Direction stability factor": 0.7392793644,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0832005695, 0.4267427764, 0.7476758576],
      direction: [-25.2878491147, 51.059547844, 134.3532485389],
      "speed factor": 0.4800558109,
      "Uz factor": 0.934228066,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4499347117,
      "Direction stability factor": 0.5565525065,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1677104178, 0.5348295493, 0.8981216833],
      direction: [-40.8948800003, 29.2369686778, 81.6579969041],
      "speed factor": 0.7598006209,
      "Uz factor": 0.9881087019,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.402675916,
      "Direction stability factor": 0.6595753419,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1601690299, 0.3607547101, 0.6513854687],
      direction: [-15.1269884414, 2.2126446375, 36.9338446522],
      "speed factor": 0.4226802144,
      "Uz factor": 0.9958020961,
      "Directional variance factor": 0.8033204767,
      "Velocity stability factor": 0.6223026514,
      "Direction stability factor": 0.8553865747,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0326537018, 0.1064264746, 0.267295767],
      direction: [-108.3149827815, -1.6216409032, 117.9654277887],
      "speed factor": 0.1974339354,
      "Uz factor": 0.9999187389,
      "Directional variance factor": 0.8558541419,
      "Velocity stability factor": 0.7723155765,
      "Direction stability factor": 0.371443304,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0188298586, 0.181544776, 0.4028009093],
      direction: [-138.4922956303, 9.6770486506, 175.4620631894],
      "speed factor": 0.2472187215,
      "Uz factor": 0.9959723557,
      "Directional variance factor": 0.1398178977,
      "Velocity stability factor": 0.7184187797,
      "Direction stability factor": 0.1279045588,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0238980178, 0.087619384, 0.1944681135],
      direction: [-177.1129413465, -161.1939448342, 178.7254045268],
      "speed factor": 0.1112711797,
      "Uz factor": 0.988467997,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.862616965,
      "Direction stability factor": 0.0115601504,
      score: 0.0,
    },
    id: "IDN:62.0",
    "45": {
      velocity: [0.0859462976, 0.3570829878, 0.6624095434],
      direction: [-48.9175582034, -20.6124464129, 100.0260257352],
      "speed factor": 0.485054939,
      "Uz factor": 0.9674254018,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5313536223,
      "Direction stability factor": 0.5862678224,
      score: 0.0,
    },
    "90": {
      velocity: [0.0677395885, 0.5097940441, 0.7704208178],
      direction: [-13.7375902707, 46.8003788158, 129.9825555281],
      "speed factor": 0.8263910636,
      "Uz factor": 0.9363394903,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4871428124,
      "Direction stability factor": 0.6007773728,
      score: 0.0,
    },
    "135": {
      velocity: [0.3209749081, 0.6535095616, 0.8129941466],
      direction: [-42.4440278349, 27.544314618, 83.2924147268],
      "speed factor": 0.5530980298,
      "Uz factor": 0.9297020838,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6035255791,
      "Direction stability factor": 0.650732104,
      score: 0.0,
    },
    "180": {
      velocity: [0.2790786814, 0.5412878835, 0.7459466249],
      direction: [-12.4195183239, 16.1021823965, 44.4086995629],
      "speed factor": 1.0571318926,
      "Uz factor": 0.9874268683,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6321281916,
      "Direction stability factor": 0.8421438392,
      score: 0.0,
    },
    "225": {
      velocity: [0.0348119176, 0.1706531225, 0.4191802723],
      direction: [-37.4019903361, 11.1523938083, 98.7595143413],
      "speed factor": 0.2329601877,
      "Uz factor": 0.9877462515,
      "Directional variance factor": 0.0086761059,
      "Velocity stability factor": 0.6545371758,
      "Direction stability factor": 0.6217735981,
      score: 0.0,
    },
    "270": {
      velocity: [0.0238448467, 0.122780131, 0.3237903215],
      direction: [-173.5306881667, -85.918847658, 178.891344333],
      "speed factor": 0.1123477844,
      "Uz factor": 0.9210193574,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7634020484,
      "Direction stability factor": 0.0210499097,
      score: 0.0,
    },
    "315": {
      velocity: [0.0217938895, 0.0849445053, 0.171202474],
      direction: [-177.5392472396, -152.0273224945, 179.5350113983],
      "speed factor": 0.1046600841,
      "Uz factor": 0.9010963496,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8787943822,
      "Direction stability factor": 0.0081270593,
      score: 0.0,
    },
  },
  {
    position: [-45.69008694, 84.25610757, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0330029398, 0.1929778084, 0.5398012384],
      direction: [-175.6544883276, 140.3082225734, 179.3867462597],
      "speed factor": 0.1977542116,
      "Uz factor": 0.8477509367,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6478609361,
      "Direction stability factor": 0.0137743484,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1125272227, 0.3894338588, 0.7512036002],
      direction: [-157.6513199936, 69.136526395, 140.2046761883],
      "speed factor": 0.3457688409,
      "Uz factor": 0.8999561871,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5869849437,
      "Direction stability factor": 0.1726222328,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0225887004, 0.2518314737, 0.6093520475],
      direction: [-106.7618551629, 13.4575199475, 161.0818728663],
      "speed factor": 0.3901037411,
      "Uz factor": 0.9132971111,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4756949552,
      "Direction stability factor": 0.2559896444,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1145639292, 0.4670194905, 0.7686494191],
      direction: [-64.7040340556, 27.2282601649, 65.5073592611],
      "speed factor": 0.5253643004,
      "Uz factor": 0.718113077,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4585355845,
      "Direction stability factor": 0.6383016852,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1806535312, 0.526539268, 0.8821599831],
      direction: [-90.6255899736, -15.12157497, 25.60828555],
      "speed factor": 0.7480231099,
      "Uz factor": 0.8686934383,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4263140252,
      "Direction stability factor": 0.6771281235,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1704750069, 0.5375396928, 0.9218840992],
      direction: [-95.2385370765, -52.7357509924, -12.2290604809],
      "speed factor": 0.6298112992,
      "Uz factor": 0.9496216606,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4222399752,
      "Direction stability factor": 0.7694181206,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0233741684, 0.1696935775, 0.4310738662],
      direction: [-159.3719294263, -30.1278864684, 169.542116411],
      "speed factor": 0.3148020353,
      "Uz factor": 0.9945211731,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6043894748,
      "Direction stability factor": 0.0863498727,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.023086917, 0.1068308161, 0.3147577782],
      direction: [-177.5312345348, 128.848822589, 177.7206209987],
      "speed factor": 0.1454769362,
      "Uz factor": 0.9455236853,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7861061741,
      "Direction stability factor": 0.0131892902,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0188762481, 0.066942558, 0.1781237087],
      direction: [-178.5339896983, 162.9021978111, 177.9788667332],
      "speed factor": 0.0850128939,
      "Uz factor": 0.7795088497,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8717366056,
      "Direction stability factor": 0.0096865099,
      score: 0.0,
    },
    id: "IDN:51.0",
    "45": {
      velocity: [0.0136740969, 0.1605029006, 0.4910995554],
      direction: [-131.2078647581, 27.0835243997, 177.2684121397],
      "speed factor": 0.2180241774,
      "Uz factor": 0.9311846398,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6118682095,
      "Direction stability factor": 0.1431214531,
      score: 0.0,
    },
    "90": {
      velocity: [0.1277776399, 0.4647684723, 0.7942856014],
      direction: [-45.8488055278, 55.1442578949, 100.1648773064],
      "speed factor": 0.7534032942,
      "Uz factor": 0.6075673381,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5135441444,
      "Direction stability factor": 0.5944064366,
      score: 0.0,
    },
    "135": {
      velocity: [0.1835936508, 0.5035476456, 0.6848866648],
      direction: [-84.9669247833, -25.6978713935, 23.2053283571],
      "speed factor": 0.4261777134,
      "Uz factor": 0.66751602,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5960526705,
      "Direction stability factor": 0.6995215191,
      score: 0.0,
    },
    "180": {
      velocity: [0.1845654217, 0.5451944892, 1.0293172698],
      direction: [-114.0550402929, -32.9955985626, -11.5826578221],
      "speed factor": 1.0647614694,
      "Uz factor": 0.7745255853,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3343719689,
      "Direction stability factor": 0.7153544931,
      score: 0.0,
    },
    "225": {
      velocity: [0.0305822749, 0.2580218224, 0.5874322713],
      direction: [-165.4292398019, -20.133893136, 172.5143936901],
      "speed factor": 0.3522280243,
      "Uz factor": 0.9985232942,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4995140206,
      "Direction stability factor": 0.0612676847,
      score: 0.0,
    },
    "270": {
      velocity: [0.02828981, 0.1145737038, 0.2394750358],
      direction: [-178.5952143864, 119.9097810247, 165.8014255165],
      "speed factor": 0.1048386385,
      "Uz factor": 0.7471153157,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8334164172,
      "Direction stability factor": 0.0433426669,
      score: 0.0,
    },
    "315": {
      velocity: [0.0187123967, 0.1431763828, 0.321701925],
      direction: [-173.5402216457, 61.9976970461, 177.9009222368],
      "speed factor": 0.1764075523,
      "Uz factor": 0.9999937451,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7542039965,
      "Direction stability factor": 0.0237746003,
      score: 0.0,
    },
  },
  {
    position: [-35.63762623, 90.14194684, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0455854183, 0.2424458004, 0.7086465128],
      direction: [-164.6471054113, -2.22274078, 170.604709009],
      "speed factor": 0.2484465883,
      "Uz factor": 0.9940716336,
      "Directional variance factor": 0.8024230418,
      "Velocity stability factor": 0.5392847336,
      "Direction stability factor": 0.0687449599,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2276293817, 0.9342211769, 1.3573141034],
      direction: [-19.8226396315, -0.2908899054, 9.0108903489],
      "speed factor": 0.8294722358,
      "Uz factor": 0.9994860873,
      "Directional variance factor": 0.9741431195,
      "Velocity stability factor": 0.2694628838,
      "Direction stability factor": 0.9199068612,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.166607389, 0.4733200654, 0.9045125759],
      direction: [-48.1738444552, -33.3494294794, 19.5118848216],
      "speed factor": 0.7332043352,
      "Uz factor": 0.9877031903,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.34064148,
      "Direction stability factor": 0.8119840853,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1279793925, 0.4360991049, 0.7718119374],
      direction: [-30.8621306555, 57.9205279118, 131.5677532701],
      "speed factor": 0.4905810268,
      "Uz factor": 0.9294111596,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4670231674,
      "Direction stability factor": 0.548805878,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1736041717, 0.5791458974, 0.9189617344],
      direction: [-58.2457948822, 39.9593287048, 78.8144824785],
      "speed factor": 0.8227582282,
      "Uz factor": 0.9708294941,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.390452962,
      "Direction stability factor": 0.6192770073,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2316742559, 0.4094554924, 0.7633846528],
      direction: [-4.860157712, 14.2200063367, 38.4171739438],
      "speed factor": 0.4797407504,
      "Uz factor": 0.9966882095,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5911667621,
      "Direction stability factor": 0.8797851898,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0299574513, 0.1627043248, 0.387503377],
      direction: [-92.0613820231, -8.3912860992, 166.5451727515],
      "speed factor": 0.3018361294,
      "Uz factor": 0.9983668633,
      "Directional variance factor": 0.2541079023,
      "Velocity stability factor": 0.6530560797,
      "Direction stability factor": 0.281648459,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0284460237, 0.1587927631, 0.3605972378],
      direction: [-164.5643113813, 8.4513463315, 154.6261393425],
      "speed factor": 0.2162361527,
      "Uz factor": 0.9956680991,
      "Directional variance factor": 0.248769215,
      "Velocity stability factor": 0.7564203238,
      "Direction stability factor": 0.1133598591,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0200583385, 0.0970547044, 0.2293742542],
      direction: [-177.6349098825, -151.4453784903, 178.4082301696],
      "speed factor": 0.1232534509,
      "Uz factor": 0.9749183095,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8314097459,
      "Direction stability factor": 0.0109912776,
      score: 0.0,
    },
    id: "IDN:61.0",
    "45": {
      velocity: [0.0930275373, 0.325100583, 0.5795516125],
      direction: [-37.6677768407, -10.0451243781, 97.8985911858],
      "speed factor": 0.4416106307,
      "Uz factor": 0.9904884481,
      "Directional variance factor": 0.1071000553,
      "Velocity stability factor": 0.604471322,
      "Direction stability factor": 0.6234267555,
      score: 0.0,
    },
    "90": {
      velocity: [0.059749203, 0.3840742955, 0.6260080973],
      direction: [-24.7385470851, 51.2215094708, 147.1590600629],
      "speed factor": 0.6225956722,
      "Uz factor": 0.7906242735,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5867116811,
      "Direction stability factor": 0.5225066468,
      score: 0.0,
    },
    "135": {
      velocity: [0.3144028126, 0.5832885637, 0.7681868664],
      direction: [-59.5509948418, 32.1192871888, 85.2671133778],
      "speed factor": 0.4936664654,
      "Uz factor": 0.8048171059,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6343359043,
      "Direction stability factor": 0.5977274772,
      score: 0.0,
    },
    "180": {
      velocity: [0.3620663438, 0.6869312889, 0.9705922946],
      direction: [-0.6927164826, 24.8889031273, 41.3111422105],
      "speed factor": 1.3415725637,
      "Uz factor": 0.9553410977,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5205077901,
      "Direction stability factor": 0.8833226147,
      score: 0.0,
    },
    "225": {
      velocity: [0.0975026648, 0.3194809126, 0.6037311835],
      direction: [-28.2938429599, 21.6309778799, 72.66365748],
      "speed factor": 0.4361264082,
      "Uz factor": 0.998457463,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5450116232,
      "Direction stability factor": 0.7195624988,
      score: 0.0,
    },
    "270": {
      velocity: [0.0209716126, 0.1749027739, 0.4312353146],
      direction: [-151.2729637313, -71.0374979501, 164.5365958448],
      "speed factor": 0.1600416857,
      "Uz factor": 0.9327877297,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6763826772,
      "Direction stability factor": 0.1227512234,
      score: 0.0,
    },
    "315": {
      velocity: [0.0397389321, 0.093820938, 0.1839042225],
      direction: [-176.0979998482, -147.9750497616, 177.7769842726],
      "speed factor": 0.1155967325,
      "Uz factor": 0.9656262616,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.883047931,
      "Direction stability factor": 0.017013933,
      score: 0.0,
    },
  },
  {
    position: [-35.94095872, 89.87253324, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0531879354, 0.1978389888, 0.4763281092],
      direction: [-177.0780452794, -39.4683641248, 178.0237609618],
      "speed factor": 0.2027357113,
      "Uz factor": 0.8910261646,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7059891773,
      "Direction stability factor": 0.0136060938,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1974001186, 0.584839269, 0.9191104972],
      direction: [-76.1800934292, -1.8351135605, 18.9617973082],
      "speed factor": 0.5192645468,
      "Uz factor": 0.9986760164,
      "Directional variance factor": 0.8368787946,
      "Velocity stability factor": 0.5332890597,
      "Direction stability factor": 0.7357169702,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1632946048, 0.3784065805, 0.7597770081],
      direction: [-52.6307688356, -21.8937341521, 34.9956922844],
      "speed factor": 0.5861770198,
      "Uz factor": 0.9995074037,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4670104485,
      "Direction stability factor": 0.7565931636,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1641064215, 0.406455505, 0.7111623448],
      direction: [-34.3234339511, 68.0452025754, 132.2560980033],
      "speed factor": 0.4572340478,
      "Uz factor": 0.7662207947,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5471366965,
      "Direction stability factor": 0.5372790779,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.202836225, 0.5967980239, 1.0796140704],
      direction: [-53.9188765911, 43.8539162545, 89.1226248418],
      "speed factor": 0.8478355574,
      "Uz factor": 0.8801247742,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2829785792,
      "Direction stability factor": 0.602662496,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1697180372, 0.3386274896, 0.5884917802],
      direction: [-13.7851234585, 30.1157436799, 58.6263516791],
      "speed factor": 0.3967547365,
      "Uz factor": 0.9032980778,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6780039919,
      "Direction stability factor": 0.7988570135,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0606523964, 0.2074042653, 0.5100526543],
      direction: [-129.9375591765, 3.632755497, 75.586692323],
      "speed factor": 0.3847599057,
      "Uz factor": 0.9976669863,
      "Directional variance factor": 0.6770884003,
      "Velocity stability factor": 0.5639254259,
      "Direction stability factor": 0.4290993014,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0294441076, 0.2320248999, 0.633408801],
      direction: [-173.8459614057, 9.2069298412, 179.8163811705],
      "speed factor": 0.3159600645,
      "Uz factor": 0.9915161472,
      "Directional variance factor": 0.1816062363,
      "Velocity stability factor": 0.5570887048,
      "Direction stability factor": 0.017604604,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0247847051, 0.0943011052, 0.2351972329],
      direction: [-179.6869904775, -154.1236392213, 179.7760623271],
      "speed factor": 0.1197565509,
      "Uz factor": 0.9533042438,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8305264968,
      "Direction stability factor": 0.00149152,
      score: 0.0,
    },
    id: "IDN:60.0",
    "45": {
      velocity: [0.0685880571, 0.2556684925, 0.4796243055],
      direction: [-44.1610492081, -0.8666661396, 109.6450596726],
      "speed factor": 0.3472953606,
      "Uz factor": 0.9952254509,
      "Directional variance factor": 0.9229630098,
      "Velocity stability factor": 0.6658405366,
      "Direction stability factor": 0.5727608087,
      score: 0.0,
    },
    "90": {
      velocity: [0.0328171897, 0.4303330404, 0.7070806047],
      direction: [-146.4332118464, 34.0836472578, 156.6347293569],
      "speed factor": 0.6975824513,
      "Uz factor": 0.7568517431,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5078837682,
      "Direction stability factor": 0.1581446078,
      score: 0.0,
    },
    "135": {
      velocity: [0.3282535734, 0.64605876, 0.8092500763],
      direction: [-54.0919799475, 16.7496359914, 95.2578276819],
      "speed factor": 0.5467920413,
      "Uz factor": 0.7649732532,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6124078186,
      "Direction stability factor": 0.5851394233,
      score: 0.0,
    },
    "180": {
      velocity: [0.3288040598, 0.6578427215, 0.9388906877],
      direction: [-10.2192074117, 30.4068137999, 50.8683871128],
      "speed factor": 1.2847627711,
      "Uz factor": 0.9131453422,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5192780438,
      "Direction stability factor": 0.8303122374,
      score: 0.0,
    },
    "225": {
      velocity: [0.0830046237, 0.3323227227, 0.6225471267],
      direction: [-15.0556764274, 28.9788793205, 63.7486197361],
      "speed factor": 0.4536568844,
      "Uz factor": 0.9999900597,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5150696601,
      "Direction stability factor": 0.7810991773,
      score: 0.0,
    },
    "270": {
      velocity: [0.0221890444, 0.1770600833, 0.4476556042],
      direction: [-160.8361311841, -56.7584926914, 163.524933092],
      "speed factor": 0.1620156934,
      "Uz factor": 0.8888258989,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6643906144,
      "Direction stability factor": 0.0989970437,
      score: 0.0,
    },
    "315": {
      velocity: [0.0180729747, 0.0676781412, 0.1248232673],
      direction: [-178.9857678843, -132.9920798334, 179.7427940335],
      "speed factor": 0.0833862051,
      "Uz factor": 0.9071483548,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.913400323,
      "Direction stability factor": 0.0035317725,
      score: 0.0,
    },
  },
  {
    position: [-36.3076623, 89.78439987, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0358037368, 0.1627292803, 0.3884526612],
      direction: [-171.8761052513, -77.0563009291, 136.3901208593],
      "speed factor": 0.1667570007,
      "Uz factor": 0.8003074409,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7549686681,
      "Direction stability factor": 0.1437049275,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0867384561, 0.1970581005, 0.5335967945],
      direction: [-95.8484254652, -5.709250761, 39.3049071043],
      "speed factor": 0.1749630893,
      "Uz factor": 0.9997124371,
      "Directional variance factor": 0.4925110435,
      "Velocity stability factor": 0.7110285768,
      "Direction stability factor": 0.6245740762,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0763665582, 0.2064118411, 0.5721364248],
      direction: [-64.935399734, -9.1270761324, 41.1498692306],
      "speed factor": 0.3197457023,
      "Uz factor": 0.9999957677,
      "Directional variance factor": 0.1887043438,
      "Velocity stability factor": 0.5570025915,
      "Direction stability factor": 0.7053186973,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1352515713, 0.4032513641, 0.8024638102],
      direction: [-25.5048079775, 55.5145637663, 147.5863127033],
      "speed factor": 0.4536296132,
      "Uz factor": 0.7219995228,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4476690119,
      "Direction stability factor": 0.5191913314,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1625681752, 0.5399172249, 0.9863731003],
      direction: [-47.0055451613, 41.9560738131, 93.6118253843],
      "speed factor": 0.7670283799,
      "Uz factor": 0.8488990684,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3262993802,
      "Direction stability factor": 0.6093961929,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0910670075, 0.2590849664, 0.5203164864],
      direction: [-111.0143951498, 33.2740777216, 81.9175246195],
      "speed factor": 0.3035583074,
      "Uz factor": 0.7977377632,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6699491766,
      "Direction stability factor": 0.4640780006,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0471208555, 0.144250491, 0.4112876299],
      direction: [-131.0383579189, 13.279174422, 72.6702244282],
      "speed factor": 0.2676020439,
      "Uz factor": 0.994743621,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6466315535,
      "Direction stability factor": 0.4341428268,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0158938792, 0.1764715268, 0.5085802149],
      direction: [-175.8870846589, 10.153159711, 177.9242916417],
      "speed factor": 0.2403102211,
      "Uz factor": 0.9765026649,
      "Directional variance factor": 0.0974969146,
      "Velocity stability factor": 0.6386935437,
      "Direction stability factor": 0.0171906214,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0177348966, 0.0746616989, 0.1920472693],
      direction: [-177.8620497484, -160.7013330335, 177.2835265578],
      "speed factor": 0.0948157238,
      "Uz factor": 0.9000359303,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.859602806,
      "Direction stability factor": 0.0134845103,
      score: 0.0,
    },
    id: "IDN:59.0",
    "45": {
      velocity: [0.032500034, 0.1704365777, 0.3586526205],
      direction: [-57.2576746226, 11.7205997092, 114.9446960429],
      "speed factor": 0.231517901,
      "Uz factor": 0.9977123617,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7348482677,
      "Direction stability factor": 0.5216600815,
      score: 0.0,
    },
    "90": {
      velocity: [0.0127841139, 0.3101603113, 0.565471298],
      direction: [-172.1589026499, 29.4225546373, 173.6130943378],
      "speed factor": 0.5027789408,
      "Uz factor": 0.7552332638,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.596617096,
      "Direction stability factor": 0.0395222306,
      score: 0.0,
    },
    "135": {
      velocity: [0.2669650843, 0.5660400848, 0.7538732436],
      direction: [-43.4763825538, 8.8700394678, 108.0086218063],
      "speed factor": 0.4790682096,
      "Uz factor": 0.7059057293,
      "Directional variance factor": 0.2115520473,
      "Velocity stability factor": 0.6076441419,
      "Direction stability factor": 0.5792083212,
      score: 0.0,
    },
    "180": {
      velocity: [0.3398646647, 0.6453564559, 0.9141418683],
      direction: [-29.632811371, 14.8084969368, 52.2694958854],
      "speed factor": 1.2603771716,
      "Uz factor": 0.8527279398,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.547494326,
      "Direction stability factor": 0.772493591,
      score: 0.0,
    },
    "225": {
      velocity: [0.0501669788, 0.2950230062, 0.5573102946],
      direction: [-9.4304789545, 31.9581885523, 61.1722081151],
      "speed factor": 0.4027386894,
      "Uz factor": 0.9983062896,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5441894214,
      "Direction stability factor": 0.8038814248,
      score: 0.0,
    },
    "270": {
      velocity: [0.0373435092, 0.1592201056, 0.3670271447],
      direction: [-167.9921460839, -57.921324724, 83.9228246659],
      "speed factor": 0.1456915377,
      "Uz factor": 0.8680237345,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7399444919,
      "Direction stability factor": 0.3002361924,
      score: 0.0,
    },
    "315": {
      velocity: [0.0346553597, 0.1081736058, 0.1910539457],
      direction: [-179.0216946138, -127.4099650086, 168.0099901803],
      "speed factor": 0.1332806475,
      "Uz factor": 0.9026103342,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8731238416,
      "Direction stability factor": 0.0360230978,
      score: 0.0,
    },
  },
  {
    position: [-37.47491052, 89.78847435, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0362134857, 0.1531835823, 0.3444923495],
      direction: [-178.7528835089, -104.0870898587, 173.4285420326],
      "speed factor": 0.1569750366,
      "Uz factor": 0.5756575297,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7857983525,
      "Direction stability factor": 0.0217182624,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.031303553, 0.2073679341, 0.7061384342],
      direction: [-179.3978647717, -37.0904681004, 179.1495987477],
      "speed factor": 0.1841169395,
      "Uz factor": 0.5904321307,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5636021993,
      "Direction stability factor": 0.0040348236,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0110650433, 0.1292425315, 0.4364499183],
      direction: [-139.763153947, 23.0034826493, 162.1094324855],
      "speed factor": 0.2002052972,
      "Uz factor": 0.9573197318,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6198954194,
      "Direction stability factor": 0.1614650377,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0747461981, 0.3825356125, 0.8605850836],
      direction: [-15.7686814813, 45.3138356163, 157.1484256232],
      "speed factor": 0.4303258398,
      "Uz factor": 0.7544901265,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3494676164,
      "Direction stability factor": 0.5196747025,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1138654577, 0.3770428165, 0.7992691761],
      direction: [-54.3757772986, 47.5366268928, 101.2070659854],
      "speed factor": 0.5356423676,
      "Uz factor": 0.5976447284,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.439482703,
      "Direction stability factor": 0.5678254353,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0840211595, 0.2919833664, 0.7365395723],
      direction: [-105.8448729368, -17.247747056, 64.2545028556],
      "speed factor": 0.3421038963,
      "Uz factor": 0.539060061,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4982772257,
      "Direction stability factor": 0.5275017339,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0236831577, 0.1361578158, 0.3961517148],
      direction: [-159.930989034, 4.5182475766, 112.5106284234],
      "speed factor": 0.2525891562,
      "Uz factor": 0.9569897858,
      "Directional variance factor": 0.5983779932,
      "Velocity stability factor": 0.6385759365,
      "Direction stability factor": 0.2432177293,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0208600218, 0.2055111655, 0.6962666016],
      direction: [-177.5330548646, 3.5744014142, 178.6102039124],
      "speed factor": 0.2798549688,
      "Uz factor": 0.9978023432,
      "Directional variance factor": 0.6822754298,
      "Velocity stability factor": 0.5046975323,
      "Direction stability factor": 0.0107131701,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0162534633, 0.0711709788, 0.1911305037],
      direction: [-178.1852505607, -166.7731929301, 174.4586826363],
      "speed factor": 0.0903827259,
      "Uz factor": 0.8967720793,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8591480033,
      "Direction stability factor": 0.0204335189,
      score: 0.0,
    },
    id: "IDN:58.0",
    "45": {
      velocity: [0.0310492489, 0.1330633586, 0.3577626376],
      direction: [-127.8488621824, 36.9772696161, 137.6390715217],
      "speed factor": 0.18075081,
      "Uz factor": 0.9613085389,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7343923532,
      "Direction stability factor": 0.2625335175,
      score: 0.0,
    },
    "90": {
      velocity: [0.0300332899, 0.2627760599, 0.4346856086],
      direction: [-63.3149999438, 35.9566299641, 171.5339813527],
      "speed factor": 0.4259676828,
      "Uz factor": 0.6228658245,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7046614575,
      "Direction stability factor": 0.3476417186,
      score: 0.0,
    },
    "135": {
      velocity: [0.2439492914, 0.6073632999, 0.8217844516],
      direction: [-37.1790630269, 6.592765943, 102.8623136359],
      "speed factor": 0.5140421261,
      "Uz factor": 0.6373703015,
      "Directional variance factor": 0.4139763606,
      "Velocity stability factor": 0.5343741817,
      "Direction stability factor": 0.6109961759,
      score: 0.0,
    },
    "180": {
      velocity: [0.3021406584, 0.5944166133, 0.934510083],
      direction: [-33.0217929888, 9.7097784832, 62.822571245],
      "speed factor": 1.1608919737,
      "Uz factor": 0.7982221418,
      "Directional variance factor": 0.1369085793,
      "Velocity stability factor": 0.5017201608,
      "Direction stability factor": 0.7337656549,
      score: 0.0,
    },
    "225": {
      velocity: [0.0144092989, 0.1763852683, 0.4371428149],
      direction: [-178.7245784874, 21.8783396396, 98.639206456],
      "speed factor": 0.240785194,
      "Uz factor": 0.96157913,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6200553127,
      "Direction stability factor": 0.2295450418,
      score: 0.0,
    },
    "270": {
      velocity: [0.0187767627, 0.1275760507, 0.2601552857],
      direction: [-175.6819320722, -58.3989975092, 98.9916536428],
      "speed factor": 0.1167362058,
      "Uz factor": 0.6934736949,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8095998476,
      "Direction stability factor": 0.2370178175,
      score: 0.0,
    },
    "315": {
      velocity: [0.0478339387, 0.1165765937, 0.2217662796],
      direction: [-178.9479802824, -123.1471605911, 179.8788027734],
      "speed factor": 0.1436339649,
      "Uz factor": 0.8631727712,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8588998289,
      "Direction stability factor": 0.003258936,
      score: 0.0,
    },
  },
  {
    position: [-39.07192354, 89.64835169, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0294261537, 0.1615027274, 0.5858636122],
      direction: [-179.949097106, 169.676216131, 177.2987177043],
      "speed factor": 0.1655000894,
      "Uz factor": 0.8098144573,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6133701193,
      "Direction stability factor": 0.0076449589,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0185766085, 0.221290832, 0.8474665379],
      direction: [-179.7758284934, 61.7487450283, 179.6095594676],
      "speed factor": 0.1964787415,
      "Uz factor": 0.8852623294,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4639788899,
      "Direction stability factor": 0.0017072557,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0230580857, 0.1983667263, 0.5310357421],
      direction: [-171.9535540681, 43.3436150094, 172.1152784503],
      "speed factor": 0.3072832832,
      "Uz factor": 0.9642118463,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5460942657,
      "Direction stability factor": 0.044253243,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0821690301, 0.3746709278, 0.8351957089],
      direction: [-19.6023946881, 52.2611606149, 146.3291745449],
      "speed factor": 0.421478619,
      "Uz factor": 0.7146492305,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3766301855,
      "Direction stability factor": 0.5390789744,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1558233488, 0.4316353988, 0.7840506532],
      direction: [-39.9980212888, 25.7317024706, 79.9252492902],
      "speed factor": 0.6131988116,
      "Uz factor": 0.7027636041,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4862410852,
      "Direction stability factor": 0.6668798039,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0507451612, 0.2559866977, 0.4730238433],
      direction: [-124.3823539987, 5.0461248301, 55.6810135505],
      "speed factor": 0.299928204,
      "Uz factor": 0.6456630347,
      "Directional variance factor": 0.5514555707,
      "Velocity stability factor": 0.6753090369,
      "Direction stability factor": 0.499823979,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0227300518, 0.1539581942, 0.4149861527],
      direction: [-101.9355654693, -25.2104687758, 105.0998721577],
      "speed factor": 0.2856110031,
      "Uz factor": 0.9584169604,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6193751359,
      "Direction stability factor": 0.4249015621,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0190677549, 0.1853049743, 0.5945163877],
      direction: [-179.9484584799, -6.4677734846, 176.5182935046],
      "speed factor": 0.2523391742,
      "Uz factor": 0.9780089893,
      "Directional variance factor": 0.4250868014,
      "Velocity stability factor": 0.5780006645,
      "Direction stability factor": 0.0098145778,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0048351758, 0.073601046, 0.2137140279],
      direction: [-179.9898188528, 178.8505617997, 179.6726816464],
      "speed factor": 0.0934687604,
      "Uz factor": 0.893314451,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.831761772,
      "Direction stability factor": 0.0009374986,
      score: 0.0,
    },
    id: "IDN:57.0",
    "45": {
      velocity: [0.0117686172, 0.1885474772, 0.4061332869],
      direction: [-159.1771360116, 41.8581387281, 167.1269208135],
      "speed factor": 0.2561194125,
      "Uz factor": 0.9718919263,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6793940025,
      "Direction stability factor": 0.0935998422,
      score: 0.0,
    },
    "90": {
      velocity: [0.0908661031, 0.4020078043, 0.6876243729],
      direction: [17.1706903341, 60.3405236652, 171.6643386191],
      "speed factor": 0.6516664148,
      "Uz factor": 0.7252520902,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5644514822,
      "Direction stability factor": 0.570850977,
      score: 0.0,
    },
    "135": {
      velocity: [0.2505878126, 0.5900352189, 0.8075909015],
      direction: [-35.9716986307, 22.5618398751, 95.0947401865],
      "speed factor": 0.4993764992,
      "Uz factor": 0.7738440472,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5511608901,
      "Direction stability factor": 0.6359265588,
      score: 0.0,
    },
    "180": {
      velocity: [0.1173186307, 0.3633397717, 0.5583449578],
      direction: [-57.5077530519, -6.2533460638, 62.26788133],
      "speed factor": 0.7096003296,
      "Uz factor": 0.5774936056,
      "Directional variance factor": 0.4441470166,
      "Velocity stability factor": 0.652490271,
      "Direction stability factor": 0.6672899045,
      score: 0.0,
    },
    "225": {
      velocity: [0.0630084435, 0.2256879423, 0.5718773605],
      direction: [-121.9520907759, -15.594520198, 34.5199095452],
      "speed factor": 0.3080887395,
      "Uz factor": 0.9353041026,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5426384844,
      "Direction stability factor": 0.5653555547,
      score: 0.0,
    },
    "270": {
      velocity: [0.0212522736, 0.2003275178, 0.3974457142],
      direction: [-143.7259958825, -50.7603425032, 140.7938193301],
      "speed factor": 0.1833061473,
      "Uz factor": 0.8329372455,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7032574086,
      "Direction stability factor": 0.20966718,
      score: 0.0,
    },
    "315": {
      velocity: [0.0519886758, 0.1119036061, 0.2340011858],
      direction: [-169.7200182156, -121.8360262886, 179.5960883967],
      "speed factor": 0.1378763791,
      "Uz factor": 0.9019741766,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8523449051,
      "Direction stability factor": 0.0296774816,
      score: 0.0,
    },
  },
  {
    position: [-40.58147966, 89.23484332, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0248133118, 0.1565414259, 0.4794011696],
      direction: [-179.5973773515, 148.470340332, 179.5166153347],
      "speed factor": 0.1604159904,
      "Uz factor": 0.7841937805,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6841383581,
      "Direction stability factor": 0.0024611314,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0346140112, 0.2858443111, 0.6845086306],
      direction: [-176.8839896868, 104.3393173193, 177.9038723592],
      "speed factor": 0.2537942038,
      "Uz factor": 0.980503509,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5797304045,
      "Direction stability factor": 0.014478161,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0323947388, 0.1985048857, 0.4848757593],
      direction: [-176.9866910967, 44.9614660368, 169.5600593885],
      "speed factor": 0.3074973013,
      "Uz factor": 0.8486284728,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.595683536,
      "Direction stability factor": 0.0373701375,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0588121484, 0.2864129311, 0.679413962],
      direction: [-41.4774250553, 68.8777412698, 139.603214514],
      "speed factor": 0.3221945384,
      "Uz factor": 0.5960014684,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4862540089,
      "Direction stability factor": 0.4969982234,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1588974435, 0.4916265496, 0.7653619011],
      direction: [-49.0676433441, 14.303371031, 61.3777223919],
      "speed factor": 0.6984246814,
      "Uz factor": 0.6929354592,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5040385551,
      "Direction stability factor": 0.6932073174,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1132403299, 0.2820870429, 0.5227472288],
      direction: [-111.2890101069, 17.089338748, 38.6794545081],
      "speed factor": 0.3305088152,
      "Uz factor": 0.7714687374,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6851292878,
      "Direction stability factor": 0.5834209316,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0325348855, 0.1542545206, 0.3795637906],
      direction: [-140.8801579093, -31.5059380673, 13.6318930624],
      "speed factor": 0.2861607243,
      "Uz factor": 0.9431732541,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.663261248,
      "Direction stability factor": 0.5707998584,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0242753353, 0.1719842009, 0.5744202106],
      direction: [-178.3614614644, -48.7290025558, 176.5458448595],
      "speed factor": 0.234199602,
      "Uz factor": 0.9354555186,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5965569147,
      "Direction stability factor": 0.0141463713,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0048882404, 0.0764492609, 0.2285700579],
      direction: [-179.8293618021, 170.3029179832, 179.3767030542],
      "speed factor": 0.0970858166,
      "Uz factor": 0.8239983709,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8198389534,
      "Direction stability factor": 0.0022053754,
      score: 0.0,
    },
    id: "IDN:56.0",
    "45": {
      velocity: [0.0458297276, 0.2103605935, 0.474965091],
      direction: [-21.9584029185, 51.4289811593, 162.868363723],
      "speed factor": 0.2857499471,
      "Uz factor": 0.9926693988,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6511265288,
      "Direction stability factor": 0.4865923149,
      score: 0.0,
    },
    "90": {
      velocity: [0.0615996443, 0.3829519511, 0.6857733271],
      direction: [-3.87184066, 46.4621848364, 166.352714089],
      "speed factor": 0.620776319,
      "Uz factor": 0.5692319437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5444421365,
      "Direction stability factor": 0.5271540146,
      score: 0.0,
    },
    "135": {
      velocity: [0.1792799031, 0.5051818728, 0.7221581758],
      direction: [-48.9787493832, 3.6745129697, 84.5518627965],
      "speed factor": 0.4275608421,
      "Uz factor": 0.6410815067,
      "Directional variance factor": 0.6733766249,
      "Velocity stability factor": 0.5625428196,
      "Direction stability factor": 0.6290816328,
      score: 0.0,
    },
    "180": {
      velocity: [0.1023002701, 0.3547582153, 0.5694541206],
      direction: [-60.7385338486, -5.6819690362, 54.8339838328],
      "speed factor": 0.6928406029,
      "Uz factor": 0.6993336781,
      "Directional variance factor": 0.4949360857,
      "Velocity stability factor": 0.6319029093,
      "Direction stability factor": 0.6789652287,
      score: 0.0,
    },
    "225": {
      velocity: [0.0260899896, 0.2474257903, 0.6683197478],
      direction: [-88.5788496929, -25.9260538682, 19.8595055963],
      "speed factor": 0.3377632809,
      "Uz factor": 0.9092271305,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4227763463,
      "Direction stability factor": 0.6987823464,
      score: 0.0,
    },
    "270": {
      velocity: [0.0409012417, 0.1845694571, 0.4356010681],
      direction: [-172.6742149496, -58.125144105, 178.0380883437],
      "speed factor": 0.1688870129,
      "Uz factor": 0.8315940274,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6886595122,
      "Direction stability factor": 0.0257991575,
      score: 0.0,
    },
    "315": {
      velocity: [0.0474992691, 0.1182988116, 0.2358921606],
      direction: [-172.2538926929, -108.2405501644, -13.2412296855],
      "speed factor": 0.1457559087,
      "Uz factor": 0.9833347101,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8471689101,
      "Direction stability factor": 0.5582981583,
      score: 0.0,
    },
  },
  {
    position: [-41.75495903, 88.69744659, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0235120016, 0.1316118308, 0.4142572616],
      direction: [-171.4243098795, 150.9842237952, 177.5771982441],
      "speed factor": 0.1348693617,
      "Uz factor": 0.8605739923,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.728498161,
      "Direction stability factor": 0.0305513663,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0335618106, 0.311544195, 0.7114755375],
      direction: [-178.8685946735, 113.373381836, 176.1832120951],
      "speed factor": 0.2766125051,
      "Uz factor": 0.9261541438,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5616111916,
      "Direction stability factor": 0.0137449812,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0295899298, 0.2148484109, 0.47049469],
      direction: [-154.7931396492, 40.6621109054, 163.2228691211],
      "speed factor": 0.3328145113,
      "Uz factor": 0.7715615379,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6060275557,
      "Direction stability factor": 0.1166221979,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0358761757, 0.3387834831, 0.6417206871],
      direction: [-47.319402643, 64.1872121759, 151.1797729289],
      "speed factor": 0.3811077507,
      "Uz factor": 0.6796979045,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4984703845,
      "Direction stability factor": 0.4486134012,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1142493152, 0.407827064, 0.6672901265],
      direction: [-67.6942019988, 7.6275338897, 60.3227988631],
      "speed factor": 0.5793757223,
      "Uz factor": 0.6481377462,
      "Directional variance factor": 0.3219969876,
      "Velocity stability factor": 0.547727956,
      "Direction stability factor": 0.6443972198,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0919256161, 0.3079780115, 0.5817397167],
      direction: [-96.9602181222, 5.5489846381, 34.1548388092],
      "speed factor": 0.3608441091,
      "Uz factor": 0.8108349873,
      "Directional variance factor": 0.5067569211,
      "Velocity stability factor": 0.6233809122,
      "Direction stability factor": 0.6357915085,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0518607319, 0.1740524731, 0.3763285851],
      direction: [-154.5446605651, -46.1764386195, 3.7794494398],
      "speed factor": 0.3228883119,
      "Uz factor": 0.9208902483,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6851533162,
      "Direction stability factor": 0.5602108055,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.010971251, 0.1575132745, 0.4742254841],
      direction: [-179.4646610233, -98.2146929783, 174.6543877226],
      "speed factor": 0.2144938081,
      "Uz factor": 0.8265162791,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6602772734,
      "Direction stability factor": 0.0163359757,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0088216609, 0.0561143049, 0.1897399298],
      direction: [-174.4974851821, 157.4675826952, 179.5636353809],
      "speed factor": 0.0712616846,
      "Uz factor": 0.8651620976,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8542821896,
      "Direction stability factor": 0.0164968873,
      score: 0.0,
    },
    id: "IDN:55.0",
    "45": {
      velocity: [0.0460001546, 0.2327323916, 0.4584160258],
      direction: [-20.169839239, 62.0191190972, 160.3311753619],
      "speed factor": 0.3161393846,
      "Uz factor": 0.9734588819,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6647189469,
      "Direction stability factor": 0.4986082928,
      score: 0.0,
    },
    "90": {
      velocity: [0.1220907571, 0.4379192644, 0.8532748218],
      direction: [-13.5129111658, 58.1056223109, 149.8309975295],
      "speed factor": 0.7098799425,
      "Uz factor": 0.7095823007,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4663398033,
      "Direction stability factor": 0.5462669203,
      score: 0.0,
    },
    "135": {
      velocity: [0.222324531, 0.5626427594, 0.747403584],
      direction: [-56.4201037862, -2.5623431016, 68.7274219086],
      "speed factor": 0.4761928822,
      "Uz factor": 0.7525310582,
      "Directional variance factor": 0.7722361687,
      "Velocity stability factor": 0.5768856232,
      "Direction stability factor": 0.6523679842,
      score: 0.0,
    },
    "180": {
      velocity: [0.0642079156, 0.3025803467, 0.5221310484],
      direction: [-84.1182320893, -25.8859798107, 39.9264037298],
      "speed factor": 0.5909375479,
      "Uz factor": 0.6344736506,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6391763168,
      "Direction stability factor": 0.6554315672,
      score: 0.0,
    },
    "225": {
      velocity: [0.0559299901, 0.210265358, 0.4479655207],
      direction: [-139.1044022627, -36.4436592719, 163.3241801265],
      "speed factor": 0.2870352241,
      "Uz factor": 0.8751739811,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6476460667,
      "Direction stability factor": 0.1599206045,
      score: 0.0,
    },
    "270": {
      velocity: [0.0519937243, 0.1506997515, 0.3455452693],
      direction: [-174.376495566, -68.4270498204, 179.5094338258],
      "speed factor": 0.1378951386,
      "Uz factor": 0.8361422005,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7684456006,
      "Direction stability factor": 0.0169835295,
      score: 0.0,
    },
    "315": {
      velocity: [0.0389959921, 0.1201802218, 0.2000867864],
      direction: [-173.4288825584, -99.8053344621, 6.3189173181],
      "speed factor": 0.1480739934,
      "Uz factor": 0.9813889496,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8693173534,
      "Direction stability factor": 0.5007005559,
      score: 0.0,
    },
  },
  {
    position: [-42.79727393, 88.02484617, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0142610912, 0.1444026409, 0.3323618054],
      direction: [-177.651507115, 164.5973703534, 178.9907191758],
      "speed factor": 0.1479767578,
      "Uz factor": 0.775073403,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7789738284,
      "Direction stability factor": 0.0093271492,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0636454369, 0.3076842971, 0.627665158],
      direction: [-0.9874992663, 105.421771091, 178.2250864597],
      "speed factor": 0.2731853957,
      "Uz factor": 0.8839396066,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6352634212,
      "Direction stability factor": 0.5021872619,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0178060889, 0.2003103731, 0.394970114],
      direction: [-109.7984013329, 33.2938996776, 175.0622324736],
      "speed factor": 0.3102941216,
      "Uz factor": 0.8118986491,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6629833781,
      "Direction stability factor": 0.2087204616,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0534246349, 0.3719612013, 0.6713435279],
      direction: [-52.1021668967, 64.6320267108, 124.9726643177],
      "speed factor": 0.4184303659,
      "Uz factor": 0.704970461,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4884749816,
      "Direction stability factor": 0.5081254688,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1311312248, 0.4033290094, 0.6120231563],
      direction: [-78.0172191844, 6.8270263484, 71.7788991432],
      "speed factor": 0.5729856028,
      "Uz factor": 0.627085627,
      "Directional variance factor": 0.3931532135,
      "Velocity stability factor": 0.6067306925,
      "Direction stability factor": 0.5838996713,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1395095333, 0.3561063934, 0.702437622],
      direction: [-63.0037490289, -8.0888800944, 23.9585544536],
      "speed factor": 0.417233989,
      "Uz factor": 0.8400904054,
      "Directional variance factor": 0.2809884361,
      "Velocity stability factor": 0.5671634135,
      "Direction stability factor": 0.7584380459,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0649416172, 0.1885248346, 0.3863037873],
      direction: [-118.2215702427, -52.269256873, 0.048908706],
      "speed factor": 0.3497362866,
      "Uz factor": 0.9530435441,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6881669092,
      "Direction stability factor": 0.6714708918,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.030049721, 0.1321251886, 0.3834230308],
      direction: [-178.07509749, -131.3305015512, 178.3454333773],
      "speed factor": 0.179921565,
      "Uz factor": 0.6822907404,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7408573182,
      "Direction stability factor": 0.0099429698,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0094062248, 0.0541247894, 0.1830981669],
      direction: [-179.4549346456, 154.1725221238, 179.0384516451],
      "speed factor": 0.0687351233,
      "Uz factor": 0.8741448636,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8601025223,
      "Direction stability factor": 0.0041850381,
      score: 0.0,
    },
    id: "IDN:54.0",
    "45": {
      velocity: [0.0510853594, 0.2496716989, 0.5327588462],
      direction: [-19.9349763092, 58.7110618638, 154.1013472058],
      "speed factor": 0.339149427,
      "Uz factor": 0.9440357207,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6084146969,
      "Direction stability factor": 0.516565768,
      score: 0.0,
    },
    "90": {
      velocity: [0.1673137074, 0.4244904955, 0.7910631828],
      direction: [0.4992594341, 51.7821660127, 148.8988048275],
      "speed factor": 0.6881115152,
      "Uz factor": 0.7679548337,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5447517475,
      "Direction stability factor": 0.5877790406,
      score: 0.0,
    },
    "135": {
      velocity: [0.1711458282, 0.4573059532, 0.7283671781],
      direction: [-64.2555277827, -12.6355537836, 62.3010756507],
      "speed factor": 0.387041042,
      "Uz factor": 0.5650127465,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.550985013,
      "Direction stability factor": 0.6484538794,
      score: 0.0,
    },
    "180": {
      velocity: [0.1043100156, 0.419934618, 0.7496945293],
      direction: [-81.6775101204, -34.5973257399, 31.4323450484],
      "speed factor": 0.8201297147,
      "Uz factor": 0.7516244889,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4914648318,
      "Direction stability factor": 0.6858059579,
      score: 0.0,
    },
    "225": {
      velocity: [0.0768405643, 0.2526879544, 0.5703518489],
      direction: [-132.256225453, -60.9111768211, 11.7433348725],
      "speed factor": 0.3449467107,
      "Uz factor": 0.9010675366,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5564416266,
      "Direction stability factor": 0.6000012213,
      score: 0.0,
    },
    "270": {
      velocity: [0.0527193251, 0.1454844124, 0.2999653764],
      direction: [-175.5926449666, -67.0858664278, 167.0606815732],
      "speed factor": 0.133122935,
      "Uz factor": 0.7811347899,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8049715225,
      "Direction stability factor": 0.0481852041,
      score: 0.0,
    },
    "315": {
      velocity: [0.0450603266, 0.127756093, 0.2411516696],
      direction: [-170.3423215684, -97.1290459882, 27.8281952196],
      "speed factor": 0.1574082207,
      "Uz factor": 0.99911976,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8409236495,
      "Direction stability factor": 0.4495263423,
      score: 0.0,
    },
  },
  {
    position: [-44.01360092, 86.92164496, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0196919865, 0.1331441908, 0.3366578859],
      direction: [-178.9401486962, 150.7430821975, 179.0098504612],
      "speed factor": 0.1364396493,
      "Uz factor": 0.7984197837,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7797623326,
      "Direction stability factor": 0.0056944468,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.055227272, 0.276104575, 0.4909097758],
      direction: [-162.8312850416, 90.4624320343, 154.7313236901],
      "speed factor": 0.2451465293,
      "Uz factor": 0.7732682614,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7182556923,
      "Direction stability factor": 0.1178816424,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0679538668, 0.2268923038, 0.4866222487],
      direction: [-91.4274212916, 22.6523820456, 105.1626520825],
      "speed factor": 0.3514713044,
      "Uz factor": 0.8891557676,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6258969722,
      "Direction stability factor": 0.4539164628,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0372195469, 0.3599313108, 0.6470072528],
      direction: [-68.1539672515, 52.5803408407, 141.9397622145],
      "speed factor": 0.4048975795,
      "Uz factor": 0.8443374099,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4952061331,
      "Direction stability factor": 0.416406307,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1928622548, 0.444137913, 0.683183472],
      direction: [-80.19110366, -8.0699808361, 42.9167030548],
      "speed factor": 0.6309603919,
      "Uz factor": 0.6724387619,
      "Directional variance factor": 0.2826683701,
      "Velocity stability factor": 0.5990195034,
      "Direction stability factor": 0.6580338702,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1679014435, 0.418568698, 0.7238318307],
      direction: [-76.4130511867, -14.3482721156, 24.2560139857],
      "speed factor": 0.4904182872,
      "Uz factor": 0.8166644921,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5725439609,
      "Direction stability factor": 0.7203637079,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0678320523, 0.1875769875, 0.3903508666],
      direction: [-121.3898484446, -52.8753131438, -4.1939286799],
      "speed factor": 0.3479779161,
      "Uz factor": 0.984102555,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6870445619,
      "Direction stability factor": 0.6744557784,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0282888617, 0.1133918656, 0.2559509531],
      direction: [-176.3648927728, -176.9107531554, 179.4561057031],
      "speed factor": 0.1544114498,
      "Uz factor": 0.7545660677,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8330463471,
      "Direction stability factor": 0.0116083376,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0088555835, 0.0470681607, 0.1435672113],
      direction: [-177.7602828478, 167.5518303731, 177.0934127474],
      "speed factor": 0.0597736429,
      "Uz factor": 0.8723253859,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8914986112,
      "Direction stability factor": 0.01429529,
      score: 0.0,
    },
    id: "IDN:53.0",
    "45": {
      velocity: [0.0615353883, 0.2635652798, 0.5424519925],
      direction: [-54.1207788204, 62.3061550804, 168.0524729845],
      "speed factor": 0.3580222107,
      "Uz factor": 0.9717482,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6090300185,
      "Direction stability factor": 0.3828520783,
      score: 0.0,
    },
    "90": {
      velocity: [0.1410947259, 0.4700150088, 0.855875388],
      direction: [-16.9787776445, 51.8823569588, 128.6814654274],
      "speed factor": 0.7619080833,
      "Uz factor": 0.6440311922,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4783119502,
      "Direction stability factor": 0.5953882137,
      score: 0.0,
    },
    "135": {
      velocity: [0.2224679342, 0.6884768737, 0.8946703754],
      direction: [-64.2810464325, -2.7957674, 51.1565460188],
      "speed factor": 0.5826926257,
      "Uz factor": 0.7484721728,
      "Directional variance factor": 0.7514873422,
      "Velocity stability factor": 0.4583320067,
      "Direction stability factor": 0.679340021,
      score: 0.0,
    },
    "180": {
      velocity: [0.1532327992, 0.4778392077, 0.8731520748],
      direction: [-90.6555144652, -30.4464670091, 25.3493123199],
      "speed factor": 0.9332170207,
      "Uz factor": 0.7399666844,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4327346533,
      "Direction stability factor": 0.67776437,
      score: 0.0,
    },
    "225": {
      velocity: [0.1340928885, 0.2915221104, 0.5992116273],
      direction: [-108.8286642775, -60.646757925, -5.811206576],
      "speed factor": 0.3979595835,
      "Uz factor": 0.9786692802,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5819602962,
      "Direction stability factor": 0.7138403953,
      score: 0.0,
    },
    "270": {
      velocity: [0.038477567, 0.1541077569, 0.3849110003],
      direction: [-162.5957391842, -81.1862096392, 168.9053420635],
      "speed factor": 0.1410135736,
      "Uz factor": 0.7008746271,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7267321977,
      "Direction stability factor": 0.0791636632,
      score: 0.0,
    },
    "315": {
      velocity: [0.020365343, 0.094868656, 0.2336470315],
      direction: [-173.9516490546, -56.5805270306, 173.3117203144],
      "speed factor": 0.1168876253,
      "Uz factor": 0.9999504628,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8269782229,
      "Direction stability factor": 0.0353795295,
      score: 0.0,
    },
  },
  {
    position: [-46.71443165, 79.6549658, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0313380804, 0.1813821696, 0.4677943778],
      direction: [-179.5437736071, 115.5681007181, 173.1816761471],
      "speed factor": 0.1858715686,
      "Uz factor": 0.7895599125,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6967367246,
      "Direction stability factor": 0.020207084,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0806441361, 0.3566458381, 0.6783682109],
      direction: [-179.0952366673, 41.34384419, 138.4940729952],
      "speed factor": 0.3166571558,
      "Uz factor": 0.9764367261,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6134677105,
      "Direction stability factor": 0.1178074732,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1056606618, 0.2987154228, 0.6752415328],
      direction: [-94.5888820915, -12.7204455551, 41.204728525],
      "speed factor": 0.4627301038,
      "Uz factor": 0.9992436325,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4910484345,
      "Direction stability factor": 0.6227955261,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1826006887, 0.5545521947, 0.9448089937],
      direction: [-53.1648364473, -23.8635700814, 88.2069218415],
      "speed factor": 0.6238324776,
      "Uz factor": 0.9153022942,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3690294606,
      "Direction stability factor": 0.6073006714,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1694367141, 0.5143330157, 0.7636692074],
      direction: [-88.4874794729, -30.6535699923, 58.0420840162],
      "speed factor": 0.7306824112,
      "Uz factor": 0.7135973524,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5140417508,
      "Direction stability factor": 0.5929734348,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1804170827, 0.4874024808, 0.8627560557],
      direction: [-94.6209508696, 15.9951589547, 42.8156309957],
      "speed factor": 0.5710677626,
      "Uz factor": 0.9595168369,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4753481346,
      "Direction stability factor": 0.618231717,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1635409101, 0.3675157239, 0.6206211618],
      direction: [-14.8705979479, -3.6765829752, 11.5213266978],
      "speed factor": 0.6817859559,
      "Uz factor": 0.9902132912,
      "Directional variance factor": 0.6731926244,
      "Velocity stability factor": 0.5564731605,
      "Direction stability factor": 0.9266890982,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0243732311, 0.2572225876, 0.571885579],
      direction: [-107.5333678273, -33.6524278198, 90.0927291292],
      "speed factor": 0.3502730328,
      "Uz factor": 0.9993463044,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5984874516,
      "Direction stability factor": 0.4510386196,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0176188356, 0.1259573092, 0.3187170081],
      direction: [-177.347585793, 53.5882650757, 173.2320333955],
      "speed factor": 0.1599579652,
      "Uz factor": 0.959981616,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7574851524,
      "Direction stability factor": 0.0261677245,
      score: 0.0,
    },
    id: "IDN:46.0",
    "45": {
      velocity: [0.0343274323, 0.1588786945, 0.4692803872],
      direction: [-105.7183816108, 9.5384617195, 97.991031214],
      "speed factor": 0.2158178857,
      "Uz factor": 0.9961238821,
      "Directional variance factor": 0.152136736,
      "Velocity stability factor": 0.6463970109,
      "Direction stability factor": 0.4341405199,
      score: 0.0,
    },
    "90": {
      velocity: [0.1661503822, 0.5668774349, 0.8742471065],
      direction: [-39.6021480229, -12.6782853068, 30.0868110292],
      "speed factor": 0.9189249107,
      "Uz factor": 0.9463366253,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4831902726,
      "Direction stability factor": 0.8064195582,
      score: 0.0,
    },
    "135": {
      velocity: [0.2899737845, 0.7113557451, 0.9294771237],
      direction: [-113.3538237922, -45.3628340611, -12.3019903996],
      "speed factor": 0.6020561659,
      "Uz factor": 0.937359111,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4846812966,
      "Direction stability factor": 0.7193004628,
      score: 0.0,
    },
    "180": {
      velocity: [0.30694845, 0.5643991243, 0.830342998],
      direction: [-87.4042613788, -43.3149185308, -2.0421085327],
      "speed factor": 1.1022680032,
      "Uz factor": 0.8942130063,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5875876647,
      "Direction stability factor": 0.7628829088,
      score: 0.0,
    },
    "225": {
      velocity: [0.2257788106, 0.4579385161, 0.6704533614],
      direction: [1.8369754034, 21.740949552, 33.3560176142],
      "speed factor": 0.6251361892,
      "Uz factor": 0.9890752157,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6003351361,
      "Direction stability factor": 0.912447105,
      score: 0.0,
    },
    "270": {
      velocity: [0.0126529523, 0.2786487895, 0.8392417855],
      direction: [-67.2735573781, -11.7339423351, 160.912687045],
      "speed factor": 0.2549726399,
      "Uz factor": 0.9952934085,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3479840798,
      "Direction stability factor": 0.366149321,
      score: 0.0,
    },
    "315": {
      velocity: [0.0286678052, 0.1006558175, 0.2064649467],
      direction: [-166.6712953644, 82.5262579022, 164.4258802171],
      "speed factor": 0.1240179843,
      "Uz factor": 0.9316879813,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8557645637,
      "Direction stability factor": 0.0802856234,
      score: 0.0,
    },
  },
  {
    position: [-44.96703638, 85.67180679, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0282823817, 0.1621314272, 0.4552935307],
      direction: [-179.108474038, 139.9766806857, 179.3416141771],
      "speed factor": 0.1661443502,
      "Uz factor": 0.8605526032,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7032995046,
      "Direction stability factor": 0.0043053105,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1041504885, 0.2812105307, 0.5654429022],
      direction: [-8.5262654042, 69.4915886307, 149.6937541423],
      "speed factor": 0.2496799831,
      "Uz factor": 0.7505636367,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7016944435,
      "Direction stability factor": 0.5604999457,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0429748338, 0.2331255334, 0.564720922],
      direction: [-88.6640974524, 7.0105601476, 130.3091208915],
      "speed factor": 0.3611269925,
      "Uz factor": 0.8627934914,
      "Directional variance factor": 0.376839098,
      "Velocity stability factor": 0.5337914212,
      "Direction stability factor": 0.3917410602,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0900237529, 0.4412960238, 0.7129661663],
      direction: [-69.5674417454, 49.2696685717, 95.8121425528],
      "speed factor": 0.4964271975,
      "Uz factor": 0.653962766,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4843164158,
      "Direction stability factor": 0.5406122658,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1390806061, 0.3944343359, 0.7076680555],
      direction: [-87.4543192374, -5.961602769, 36.7575217676],
      "speed factor": 0.5603494678,
      "Uz factor": 0.6680280535,
      "Directional variance factor": 0.4700797539,
      "Velocity stability factor": 0.5350140483,
      "Direction stability factor": 0.6549671083,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1720739586, 0.4081575263, 0.7581629597],
      direction: [-131.4913970158, -31.2028159401, 8.1662005528],
      "speed factor": 0.4782199814,
      "Uz factor": 0.8449167201,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5493549395,
      "Direction stability factor": 0.612062229,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0660403484, 0.1919582081, 0.406880121],
      direction: [-135.3895429469, -43.4517125102, -4.8699707877],
      "speed factor": 0.3561056082,
      "Uz factor": 0.9887633807,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6692668594,
      "Direction stability factor": 0.6374456329,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0244159297, 0.1069769892, 0.2262130689],
      direction: [-179.2824685141, 151.8630286196, 178.6690665448],
      "speed factor": 0.1456759874,
      "Uz factor": 0.8270006911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8520141437,
      "Direction stability factor": 0.0056901804,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.011950834, 0.0614950835, 0.1686057367],
      direction: [-178.2358842751, 161.3202676302, 179.0102747789],
      "speed factor": 0.0780949394,
      "Uz factor": 0.8792169664,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8738247411,
      "Direction stability factor": 0.0076495582,
      score: 0.0,
    },
    id: "IDN:52.0",
    "45": {
      velocity: [0.0284110291, 0.1755589816, 0.4233062777],
      direction: [-127.3832201474, 36.882062711, 178.9050997381],
      "speed factor": 0.2384760798,
      "Uz factor": 0.8685969214,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6789626587,
      "Direction stability factor": 0.1491991114,
      score: 0.0,
    },
    "90": {
      velocity: [0.1588788499, 0.474318197, 0.8058392252],
      direction: [-15.6936094519, 48.3538178523, 122.8175707957],
      "speed factor": 0.7688836775,
      "Uz factor": 0.698095808,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5278110974,
      "Direction stability factor": 0.6152467215,
      score: 0.0,
    },
    "135": {
      velocity: [0.1618218313, 0.4808115504, 0.695501804],
      direction: [-85.8475294614, -15.8379403367, 40.8528713565],
      "speed factor": 0.4069350119,
      "Uz factor": 0.4726260118,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5699549092,
      "Direction stability factor": 0.6480544422,
      score: 0.0,
    },
    "180": {
      velocity: [0.1212259171, 0.4981752847, 0.9780325562],
      direction: [-104.5608767553, -28.1764756657, 10.1693794526],
      "speed factor": 0.9729332535,
      "Uz factor": 0.7405528363,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3248733134,
      "Direction stability factor": 0.6813048439,
      score: 0.0,
    },
    "225": {
      velocity: [0.0924178304, 0.2598572725, 0.5192706606],
      direction: [-157.3730880085, -41.4538558796, -5.5812988087],
      "speed factor": 0.3547336145,
      "Uz factor": 0.9741210465,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6163529529,
      "Direction stability factor": 0.5783561411,
      score: 0.0,
    },
    "270": {
      velocity: [0.0348628741, 0.1298237109, 0.4034996255],
      direction: [-179.8837664769, -123.6005939956, 179.2292367497],
      "speed factor": 0.1187928874,
      "Uz factor": 0.6061166203,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7092181493,
      "Direction stability factor": 0.0024638799,
      score: 0.0,
    },
    "315": {
      velocity: [0.0128087466, 0.1004917061, 0.2473182183],
      direction: [-166.8803638145, 39.7433137556, 170.8103214615],
      "speed factor": 0.1238157826,
      "Uz factor": 0.9528866344,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8097574816,
      "Direction stability factor": 0.0619703187,
      score: 0.0,
    },
  },
  {
    position: [-46.42617996, 79.94135678, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0293660237, 0.1869658221, 0.6476817386],
      direction: [-178.9855220051, 129.9117946142, 177.2699599817],
      "speed factor": 0.1915934224,
      "Uz factor": 0.8015505787,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5703752013,
      "Direction stability factor": 0.0104014389,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0868636322, 0.3840781367, 0.9094169159],
      direction: [-45.6086836994, 29.2934681739, 167.4973705211],
      "speed factor": 0.3410136257,
      "Uz factor": 0.9568526275,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4680766304,
      "Direction stability factor": 0.4080387383,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0660353961, 0.2887485193, 0.7720781627],
      direction: [-90.0595423053, -27.5386948427, 59.3797648919],
      "speed factor": 0.4472907058,
      "Uz factor": 0.9828489727,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3691122899,
      "Direction stability factor": 0.5848908133,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2159890789, 0.5999555378, 0.9435234658],
      direction: [-62.2516991962, -13.3558384513, 69.3520680088],
      "speed factor": 0.6749080667,
      "Uz factor": 0.8337727804,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3977331899,
      "Direction stability factor": 0.63443398,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2017503311, 0.5686875782, 0.8451093634],
      direction: [-92.4536343745, -19.5739132789, 45.8094246094],
      "speed factor": 0.8079007145,
      "Uz factor": 0.8545112762,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4738664875,
      "Direction stability factor": 0.6159359473,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.281291852, 0.5026364809, 0.7985099252],
      direction: [-92.8920825769, -4.3838431259, 41.3034197002],
      "speed factor": 0.5889167615,
      "Uz factor": 0.9912013119,
      "Directional variance factor": 0.6103250555,
      "Velocity stability factor": 0.6023099402,
      "Direction stability factor": 0.6272347159,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1024528904, 0.2554026352, 0.4382147736],
      direction: [-29.7743496663, -7.0501735918, 14.680701812],
      "speed factor": 0.4738026659,
      "Uz factor": 0.9959167143,
      "Directional variance factor": 0.373317903,
      "Velocity stability factor": 0.6741941786,
      "Direction stability factor": 0.8765137459,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0224888023, 0.1135228914, 0.2885771744],
      direction: [-103.0146301394, -35.9898277649, 74.3615305906],
      "speed factor": 0.1545898741,
      "Uz factor": 0.9979626574,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8048668294,
      "Direction stability factor": 0.5072884424,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0116389553, 0.0823309705, 0.2322422129],
      direction: [-173.6533180773, 60.6540972424, 170.7085252101],
      "speed factor": 0.1045552228,
      "Uz factor": 0.9282961814,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8223185317,
      "Direction stability factor": 0.0434393242,
      score: 0.0,
    },
    id: "IDN:47.0",
    "45": {
      velocity: [0.0119825677, 0.1544512909, 0.4197869201],
      direction: [-114.7112263898, -14.1405689788, 105.9608785421],
      "speed factor": 0.2098037825,
      "Uz factor": 0.9784320376,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6684679657,
      "Direction stability factor": 0.3870219307,
      score: 0.0,
    },
    "90": {
      velocity: [0.2136603355, 0.5983131965, 0.9166480049],
      direction: [-43.3445885659, -12.9923988834, 45.4855621366],
      "speed factor": 0.9698832,
      "Uz factor": 0.8675909589,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4869191548,
      "Direction stability factor": 0.7532495814,
      score: 0.0,
    },
    "135": {
      velocity: [0.2703205539, 0.6967734011, 0.9168647616],
      direction: [-112.7915298493, -48.9211864622, -14.3951910503],
      "speed factor": 0.5897143943,
      "Uz factor": 0.8036518193,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4790076886,
      "Direction stability factor": 0.7266768367,
      score: 0.0,
    },
    "180": {
      velocity: [0.3152782567, 0.5696433882, 0.9659882319],
      direction: [-92.1522742356, -31.2758031131, -1.160854382],
      "speed factor": 1.1125100181,
      "Uz factor": 0.8430463232,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4872685978,
      "Direction stability factor": 0.747246056,
      score: 0.0,
    },
    "225": {
      velocity: [0.1264260217, 0.3265070228, 0.4788879146],
      direction: [-18.4815496525, 5.5365405, 26.0546303754],
      "speed factor": 0.4457178176,
      "Uz factor": 0.9991663032,
      "Directional variance factor": 0.5078630667,
      "Velocity stability factor": 0.6832140849,
      "Direction stability factor": 0.8762883888,
      score: 0.0,
    },
    "270": {
      velocity: [0.018331403, 0.1656048108, 0.5862606368],
      direction: [-128.3995618549, -14.1795288527, 114.0922393646],
      "speed factor": 0.1515337492,
      "Uz factor": 0.9983698749,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5520156005,
      "Direction stability factor": 0.3264116633,
      score: 0.0,
    },
    "315": {
      velocity: [0.0130195235, 0.1066658599, 0.2668491404],
      direction: [-172.3699615391, 70.4385693814, 179.7499599492],
      "speed factor": 0.1314229546,
      "Uz factor": 0.8738194951,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7940842849,
      "Direction stability factor": 0.021889107,
      score: 0.0,
    },
  },
  {
    position: [-54.76353402, 47.0623115, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2124081578, 0.9981892148, 1.4014973735],
      direction: [-26.5060371213, -11.3153477099, 116.0404028244],
      "speed factor": 1.0228954453,
      "Uz factor": 0.9857440847,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1737841968,
      "Direction stability factor": 0.6040376668,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1257115458, 0.5369525968, 1.1134214924],
      direction: [-123.3513186297, -6.7712427512, 107.5355326973],
      "speed factor": 0.4767471365,
      "Uz factor": 0.9995757993,
      "Directional variance factor": 0.3981117554,
      "Velocity stability factor": 0.3612742015,
      "Direction stability factor": 0.3586476352,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0485209249, 0.2429642471, 0.5026772777],
      direction: [-156.9615991032, 13.0792087679, 167.002072305],
      "speed factor": 0.376367816,
      "Uz factor": 0.9935184532,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5941865352,
      "Direction stability factor": 0.1001009128,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.052660802, 0.2681190961, 0.8743077049],
      direction: [-179.7436318783, -126.103271812, 179.5227551859],
      "speed factor": 0.301615252,
      "Uz factor": 0.999527809,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3198250582,
      "Direction stability factor": 0.0020378137,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.05636287, 0.2399735358, 0.9078215885],
      direction: [-175.8837014267, 94.8391249209, 177.0382128752],
      "speed factor": 0.3409161699,
      "Uz factor": 0.7097888459,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3036843445,
      "Direction stability factor": 0.0196613492,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0835019977, 0.8054108388, 1.1705361701],
      direction: [-96.7359302128, -5.622925084, 50.8500333011],
      "speed factor": 0.9436639815,
      "Uz factor": 0.9901178098,
      "Directional variance factor": 0.500184437,
      "Velocity stability factor": 0.1641771481,
      "Direction stability factor": 0.5900389902,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4395771742, 0.7617728651, 0.9951772188],
      direction: [-22.7539585834, -8.3746689151, 13.186484425],
      "speed factor": 1.4131804631,
      "Uz factor": 0.9324156477,
      "Directional variance factor": 0.2555849853,
      "Velocity stability factor": 0.4608746912,
      "Direction stability factor": 0.9001654361,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.7110482064, 1.2099679256, 1.4257177903],
      direction: [-34.0432802835, 5.0615716449, 24.4577560866],
      "speed factor": 1.6476746416,
      "Uz factor": 0.828496717,
      "Directional variance factor": 0.5500825204,
      "Velocity stability factor": 0.4759044121,
      "Direction stability factor": 0.8374971212,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4974735793, 0.8923873433, 1.1536793857],
      direction: [-27.5461762554, -2.2822856055, 12.4383557772],
      "speed factor": 1.1332765407,
      "Uz factor": 0.9383904373,
      "Directional variance factor": 0.7971301684,
      "Velocity stability factor": 0.4714692227,
      "Direction stability factor": 0.8889318555,
      score: 0.0,
    },
    id: "IDN:376.0",
    "45": {
      velocity: [0.090257768, 0.3443027052, 0.7907586374],
      direction: [-176.2632700562, 10.8250700894, 176.3608454556],
      "speed factor": 0.467694439,
      "Uz factor": 0.9971100097,
      "Directional variance factor": 0.0377715476,
      "Velocity stability factor": 0.4305149592,
      "Direction stability factor": 0.020488568,
      score: 0.0,
    },
    "90": {
      velocity: [0.0548151737, 0.2897759876, 0.8808746063],
      direction: [-177.2837570379, -100.371517185, 176.2072488283],
      "speed factor": 0.4697353557,
      "Uz factor": 0.9472075384,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3970943015,
      "Direction stability factor": 0.0180805393,
      score: 0.0,
    },
    "135": {
      velocity: [0.0687294595, 0.3710927964, 0.9037869873],
      direction: [-179.8758284373, -31.3579070811, 163.5729568517],
      "speed factor": 0.3140745087,
      "Uz factor": 0.9993576363,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3271016175,
      "Direction stability factor": 0.0459755964,
      score: 0.0,
    },
    "180": {
      velocity: [0.0437470121, 0.3964518696, 0.9844485025],
      direction: [-168.9723205915, -6.4122485286, 146.6731269722],
      "speed factor": 0.7742680521,
      "Uz factor": 0.9999435218,
      "Directional variance factor": 0.430022353,
      "Velocity stability factor": 0.2587677881,
      "Direction stability factor": 0.1232070901,
      score: 0.0,
    },
    "225": {
      velocity: [0.1287332093, 0.5346638769, 0.9448236899],
      direction: [-89.1366944696, 2.2020240117, 44.6507674175],
      "speed factor": 0.7298747032,
      "Uz factor": 0.934312812,
      "Directional variance factor": 0.8042645323,
      "Velocity stability factor": 0.2665137001,
      "Direction stability factor": 0.6283681614,
      score: 0.0,
    },
    "270": {
      velocity: [0.7393028778, 1.0621879716, 1.3344593324],
      direction: [-9.288491805, 14.1530416772, 35.1029067787],
      "speed factor": 0.9719362916,
      "Uz factor": 0.8615865868,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5305386816,
      "Direction stability factor": 0.8766905595,
      score: 0.0,
    },
    "315": {
      velocity: [0.2610728283, 1.1034289917, 1.3730698126],
      direction: [-20.091538873, 0.0363500388, 55.8133784855],
      "speed factor": 1.3595343288,
      "Uz factor": 0.8731943042,
      "Directional variance factor": 0.9967688854,
      "Velocity stability factor": 0.0979080493,
      "Direction stability factor": 0.7891530073,
      score: 0.0,
    },
  },
  {
    position: [-52.91929065, 80.0059607, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1294707966, 0.7003509336, 1.2622751217],
      direction: [-24.6199524853, -3.4507201768, 107.0568490197],
      "speed factor": 0.7176853541,
      "Uz factor": 0.9955776349,
      "Directional variance factor": 0.6932693176,
      "Velocity stability factor": 0.2128926721,
      "Direction stability factor": 0.6342311069,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.054181129, 0.3185106835, 0.7927362152],
      direction: [-108.6864605458, 14.5814850635, 173.3304232323],
      "speed factor": 0.2827978805,
      "Uz factor": 0.9947541712,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5223960346,
      "Direction stability factor": 0.2166197673,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0130127186, 0.1169232339, 0.4657404212],
      direction: [-92.504244998, -17.3590362457, 120.6591742993],
      "speed factor": 0.1811218841,
      "Uz factor": 0.9885027967,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5954631121,
      "Direction stability factor": 0.4078793908,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0375309863, 0.3410962551, 0.7571024181],
      direction: [-87.9416510651, -0.855588158, 45.4155900749],
      "speed factor": 0.3837094577,
      "Uz factor": 0.9999432136,
      "Directional variance factor": 0.9239477193,
      "Velocity stability factor": 0.4043250756,
      "Direction stability factor": 0.6295632191,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1761785476, 0.5331059066, 0.7851308479],
      direction: [-85.8207563512, -31.3478103199, 8.6088319558],
      "speed factor": 0.757351944,
      "Uz factor": 0.9999996846,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5020040186,
      "Direction stability factor": 0.737695588,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2771855295, 0.611539555, 0.8775697858],
      direction: [-55.3482247081, -33.2156645986, -3.044426073],
      "speed factor": 0.7165136395,
      "Uz factor": 0.9741813349,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5383632879,
      "Direction stability factor": 0.8547116705,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0987825885, 0.2619148023, 0.4779810913],
      direction: [-57.3412799381, -9.9315799289, 77.7186080941],
      "speed factor": 0.4858835207,
      "Uz factor": 0.8181994131,
      "Directional variance factor": 0.1171928952,
      "Velocity stability factor": 0.6320455481,
      "Direction stability factor": 0.6248336444,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2380013782, 0.6420168075, 0.9937248525],
      direction: [-39.824466474, 25.0009531394, 41.127444035],
      "speed factor": 0.8742668221,
      "Uz factor": 0.8874223612,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4457979638,
      "Direction stability factor": 0.7751335819,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3361110394, 0.5818314237, 0.7937900717],
      direction: [4.8506395509, 13.6184194948, 26.1035944588],
      "speed factor": 0.7388897972,
      "Uz factor": 0.9993543121,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6313695301,
      "Direction stability factor": 0.9409640141,
      score: 0.0,
    },
    id: "IDN:41.0",
    "45": {
      velocity: [0.0085503047, 0.1534041239, 0.5354931702],
      direction: [-131.6984735208, -11.6821006833, 120.666587634],
      "speed factor": 0.2083813301,
      "Uz factor": 0.9863989836,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5716121244,
      "Direction stability factor": 0.2989859412,
      score: 0.0,
    },
    "90": {
      velocity: [0.0496750623, 0.1912806988, 0.7204355115],
      direction: [-178.2392938319, 3.4104259135, 88.3490077971],
      "speed factor": 0.3100716103,
      "Uz factor": 0.9915979377,
      "Directional variance factor": 0.6968510299,
      "Velocity stability factor": 0.5104404342,
      "Direction stability factor": 0.2594769399,
      score: 0.0,
    },
    "135": {
      velocity: [0.0442572569, 0.4531097723, 0.7363414771],
      direction: [-136.5789448118, -20.4435325089, 77.6668939316],
      "speed factor": 0.383489603,
      "Uz factor": 0.9988674182,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4423110543,
      "Direction stability factor": 0.4048726702,
      score: 0.0,
    },
    "180": {
      velocity: [0.2673283766, 0.5949125361, 0.8506753856],
      direction: [-68.6425500594, -50.7440392741, -9.8198035354],
      "speed factor": 1.1618605079,
      "Uz factor": 0.993196603,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5403477106,
      "Direction stability factor": 0.8366034819,
      score: 0.0,
    },
    "225": {
      velocity: [0.2670405908, 0.4622395521, 0.6922655785],
      direction: [-46.419545771, 3.0069300391, 59.7445157898],
      "speed factor": 0.631007574,
      "Uz factor": 0.7873705054,
      "Directional variance factor": 0.7327173299,
      "Velocity stability factor": 0.6178160262,
      "Direction stability factor": 0.705099829,
      score: 0.0,
    },
    "270": {
      velocity: [0.551939534, 0.8163845884, 1.0922633884],
      direction: [11.1210752109, 48.1734849303, 61.2420807225],
      "speed factor": 0.7470182591,
      "Uz factor": 0.8821592954,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5737908124,
      "Direction stability factor": 0.8607749847,
      score: 0.0,
    },
    "315": {
      velocity: [0.3037994255, 0.6491565545, 0.9248150989],
      direction: [8.9706944537, 18.9407975782, 27.520564277],
      "speed factor": 0.799825478,
      "Uz factor": 0.9853940362,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4962097487,
      "Direction stability factor": 0.9484725838,
      score: 0.0,
    },
  },
  {
    position: [-35.50511975, 90.5113871, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0442430975, 0.2724329099, 0.8438623144],
      direction: [-172.8427394183, -3.8518118811, 178.5873538828],
      "speed factor": 0.2791759103,
      "Uz factor": 0.9844013406,
      "Directional variance factor": 0.6576167217,
      "Velocity stability factor": 0.4443999451,
      "Direction stability factor": 0.0238052964,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3428680425, 1.0986718247, 1.4662537768],
      direction: [-26.087213733, -11.1575469159, -3.6025902328],
      "speed factor": 0.9754839618,
      "Uz factor": 0.9963220817,
      "Directional variance factor": 0.0082180519,
      "Velocity stability factor": 0.2735362718,
      "Direction stability factor": 0.9375427125,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1557398408, 0.4604739456, 0.8782067231],
      direction: [-60.7390411246, -44.8632621539, 33.1203876859],
      "speed factor": 0.7133048393,
      "Uz factor": 0.9579603978,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.354436447,
      "Direction stability factor": 0.7392793644,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0832005695, 0.4267427764, 0.7476758576],
      direction: [-25.2878491147, 51.059547844, 134.3532485389],
      "speed factor": 0.4800558109,
      "Uz factor": 0.934228066,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4499347117,
      "Direction stability factor": 0.5565525065,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1677104178, 0.5348295493, 0.8981216833],
      direction: [-40.8948800003, 29.2369686778, 81.6579969041],
      "speed factor": 0.7598006209,
      "Uz factor": 0.9881087019,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.402675916,
      "Direction stability factor": 0.6595753419,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1601690299, 0.3607547101, 0.6513854687],
      direction: [-15.1269884414, 2.2126446375, 36.9338446522],
      "speed factor": 0.4226802144,
      "Uz factor": 0.9958020961,
      "Directional variance factor": 0.8033204767,
      "Velocity stability factor": 0.6223026514,
      "Direction stability factor": 0.8553865747,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0326537018, 0.1064264746, 0.267295767],
      direction: [-108.3149827815, -1.6216409032, 117.9654277887],
      "speed factor": 0.1974339354,
      "Uz factor": 0.9999187389,
      "Directional variance factor": 0.8558541419,
      "Velocity stability factor": 0.7723155765,
      "Direction stability factor": 0.371443304,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0188298586, 0.181544776, 0.4028009093],
      direction: [-138.4922956303, 9.6770486506, 175.4620631894],
      "speed factor": 0.2472187215,
      "Uz factor": 0.9959723557,
      "Directional variance factor": 0.1398178977,
      "Velocity stability factor": 0.7184187797,
      "Direction stability factor": 0.1279045588,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0238980178, 0.087619384, 0.1944681135],
      direction: [-177.1129413465, -161.1939448342, 178.7254045268],
      "speed factor": 0.1112711797,
      "Uz factor": 0.988467997,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.862616965,
      "Direction stability factor": 0.0115601504,
      score: 0.0,
    },
    id: "IDN:21.0",
    "45": {
      velocity: [0.0859462976, 0.3570829878, 0.6624095434],
      direction: [-48.9175582034, -20.6124464129, 100.0260257352],
      "speed factor": 0.485054939,
      "Uz factor": 0.9674254018,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5313536223,
      "Direction stability factor": 0.5862678224,
      score: 0.0,
    },
    "90": {
      velocity: [0.0677395885, 0.5097940441, 0.7704208178],
      direction: [-13.7375902707, 46.8003788158, 129.9825555281],
      "speed factor": 0.8263910636,
      "Uz factor": 0.9363394903,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4871428124,
      "Direction stability factor": 0.6007773728,
      score: 0.0,
    },
    "135": {
      velocity: [0.3209749081, 0.6535095616, 0.8129941466],
      direction: [-42.4440278349, 27.544314618, 83.2924147268],
      "speed factor": 0.5530980298,
      "Uz factor": 0.9297020838,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6035255791,
      "Direction stability factor": 0.650732104,
      score: 0.0,
    },
    "180": {
      velocity: [0.2790786814, 0.5412878835, 0.7459466249],
      direction: [-12.4195183239, 16.1021823965, 44.4086995629],
      "speed factor": 1.0571318926,
      "Uz factor": 0.9874268683,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6321281916,
      "Direction stability factor": 0.8421438392,
      score: 0.0,
    },
    "225": {
      velocity: [0.0348119176, 0.1706531225, 0.4191802723],
      direction: [-37.4019903361, 11.1523938083, 98.7595143413],
      "speed factor": 0.2329601877,
      "Uz factor": 0.9877462515,
      "Directional variance factor": 0.0086761059,
      "Velocity stability factor": 0.6545371758,
      "Direction stability factor": 0.6217735981,
      score: 0.0,
    },
    "270": {
      velocity: [0.0238448467, 0.122780131, 0.3237903215],
      direction: [-173.5306881667, -85.918847658, 178.891344333],
      "speed factor": 0.1123477844,
      "Uz factor": 0.9210193574,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7634020484,
      "Direction stability factor": 0.0210499097,
      score: 0.0,
    },
    "315": {
      velocity: [0.0217938895, 0.0849445053, 0.171202474],
      direction: [-177.5392472396, -152.0273224945, 179.5350113983],
      "speed factor": 0.1046600841,
      "Uz factor": 0.9010963496,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8787943822,
      "Direction stability factor": 0.0081270593,
      score: 0.0,
    },
  },
  {
    position: [12.51734566, 102.5890542, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1412522847, 0.2766202911, 0.5332794082],
      direction: [-68.3785888297, -37.9302322227, -9.4267220539],
      "speed factor": 0.2834669335,
      "Uz factor": 0.9226398154,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7276074828,
      "Direction stability factor": 0.8362448145,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0600086208, 0.3640446199, 0.8162849319],
      direction: [-116.6659045488, -35.7394733119, -5.5390399144],
      "speed factor": 0.323226354,
      "Uz factor": 0.9993015248,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5109361889,
      "Direction stability factor": 0.6913142649,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0644684798, 0.2755554779, 0.5433027839],
      direction: [-74.4353617728, 9.8578568492, 141.8478615455],
      "speed factor": 0.4268538052,
      "Uz factor": 0.9315657288,
      "Directional variance factor": 0.1237460579,
      "Velocity stability factor": 0.5721354401,
      "Direction stability factor": 0.3992132686,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2143901856, 0.7494844818, 0.9576428427],
      direction: [-8.9312712763, 25.9314504108, 57.3345972993],
      "speed factor": 0.8431176824,
      "Uz factor": 0.9334483659,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3847213066,
      "Direction stability factor": 0.8159281428,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.033200501, 0.5293507428, 0.9889957681],
      direction: [-151.5869783299, 16.796572444, 172.4350957802],
      "speed factor": 0.7520172055,
      "Uz factor": 0.9978374692,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2183588077,
      "Direction stability factor": 0.099938683,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0188221015, 0.163401858, 0.5127391297],
      direction: [-179.4554105551, -163.3704020886, 176.9209810128],
      "speed factor": 0.191450674,
      "Uz factor": 0.8182963843,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6202261625,
      "Direction stability factor": 0.010065579,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0201941561, 0.0833778567, 0.1975287298],
      direction: [-101.9283776441, 1.233265985, 136.4125186358],
      "speed factor": 0.1546759716,
      "Uz factor": 0.9699087623,
      "Directional variance factor": 0.8903763569,
      "Velocity stability factor": 0.8279237777,
      "Direction stability factor": 0.3379419548,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0120699066, 0.0743111352, 0.2463224822],
      direction: [-179.682500065, 25.6711729594, 171.4142012972],
      "speed factor": 0.1011932387,
      "Uz factor": 0.3823086343,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8282132833,
      "Direction stability factor": 0.0247313851,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0835911846, 0.3883459974, 0.7271590353],
      direction: [-78.557641843, -12.6258207002, 22.6987785025],
      "speed factor": 0.4931753143,
      "Uz factor": 0.999989911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4816482678,
      "Direction stability factor": 0.7187321657,
      score: 0.0,
    },
    id: "IDN:19.0",
    "45": {
      velocity: [0.0943384912, 0.3004034054, 0.5580332126],
      direction: [-78.5389438025, -21.0881736668, 76.3499118186],
      "speed factor": 0.4080624406,
      "Uz factor": 0.9452422121,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6230308642,
      "Direction stability factor": 0.5697531788,
      score: 0.0,
    },
    "90": {
      velocity: [0.0878303823, 0.510018262, 0.822031952],
      direction: [5.7346069516, 57.5588401032, 75.5498237424],
      "speed factor": 0.8267545274,
      "Uz factor": 0.9009666462,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4641374546,
      "Direction stability factor": 0.8060688422,
      score: 0.0,
    },
    "135": {
      velocity: [0.4542566689, 0.9795894446, 1.1577928236],
      direction: [26.3881901179, 34.3538181152, 40.653220642],
      "speed factor": 0.8290758446,
      "Uz factor": 0.9790353805,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4330829616,
      "Direction stability factor": 0.9603749152,
      score: 0.0,
    },
    "180": {
      velocity: [0.0139063026, 0.1664439504, 0.7804309289],
      direction: [-178.5880188206, 0.0722973258, 175.0530482392],
      "speed factor": 0.3250640069,
      "Uz factor": 0.9052292095,
      "Directional variance factor": 0.993573571,
      "Velocity stability factor": 0.3960116466,
      "Direction stability factor": 0.0176637026,
      score: 0.0,
    },
    "225": {
      velocity: [0.016631421, 0.1195285726, 0.3903487677],
      direction: [-168.8771090888, -1.522655456, 179.534206138],
      "speed factor": 0.1631695823,
      "Uz factor": 0.9691907597,
      "Directional variance factor": 0.8646528484,
      "Velocity stability factor": 0.6641100952,
      "Direction stability factor": 0.032190791,
      score: 0.0,
    },
    "270": {
      velocity: [0.040649705, 0.2977349158, 0.711687681],
      direction: [-112.2009510795, -1.9668852669, 151.8486163463],
      "speed factor": 0.2724370617,
      "Uz factor": 0.9764806174,
      "Directional variance factor": 0.8251657541,
      "Velocity stability factor": 0.4706830944,
      "Direction stability factor": 0.2665289794,
      score: 0.0,
    },
    "315": {
      velocity: [0.0777857147, 0.2586789749, 0.4953092925],
      direction: [-45.7872384085, -23.4717147785, 4.1574073796],
      "speed factor": 0.3187182403,
      "Uz factor": 0.9961796626,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6612898559,
      "Direction stability factor": 0.8612648728,
      score: 0.0,
    },
  },
  {
    position: [12.50687371, 99.58907243, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0081996552, 0.1106638996, 0.2929392331],
      direction: [-178.8206455141, -106.5159984123, 166.5707586689],
      "speed factor": 0.1134029472,
      "Uz factor": 0.8628972957,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8021541733,
      "Direction stability factor": 0.0405794328,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0420884931, 0.3902818752, 1.0871458974],
      direction: [-173.3283840461, -19.418268926, 169.7494633833],
      "speed factor": 0.3465217741,
      "Uz factor": 0.9934161523,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3241891231,
      "Direction stability factor": 0.0470059794,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0907409367, 0.2236649853, 0.4668338018],
      direction: [-60.5764442444, -17.6360122994, 105.0604803637],
      "speed factor": 0.3464719729,
      "Uz factor": 0.7797684895,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6639405179,
      "Direction stability factor": 0.5398974316,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1826174096, 0.6291622609, 0.8167135238],
      direction: [-43.6856049882, 21.4205118969, 46.2855959844],
      "speed factor": 0.7077635897,
      "Uz factor": 0.7730133612,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.47508317,
      "Direction stability factor": 0.7500799973,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0389023504, 0.4960769348, 0.973263109],
      direction: [-177.9535174119, 16.9391819351, 162.0121979262],
      "speed factor": 0.7047470798,
      "Uz factor": 0.987280951,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2358877653,
      "Direction stability factor": 0.0556507907,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0334517557, 0.215291875, 0.7321661986],
      direction: [-179.8868798373, -162.5654998082, 179.8394762926],
      "speed factor": 0.252247894,
      "Uz factor": 0.7066927717,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4627570014,
      "Direction stability factor": 0.0007601219,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0140989638, 0.139710943, 0.4222720711],
      direction: [-131.1973338059, 24.2293732533, 170.958854647],
      "speed factor": 0.2591806354,
      "Uz factor": 0.9749924502,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6039301029,
      "Direction stability factor": 0.1606772543,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0148536687, 0.0969667714, 0.2691821341],
      direction: [-179.534174079, -177.2016308603, 179.5889433809],
      "speed factor": 0.1320445666,
      "Uz factor": 0.3700976224,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8134908361,
      "Direction stability factor": 0.0024357848,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0106903459, 0.2149661289, 0.6882125695],
      direction: [-133.2960577441, 7.1364722193, 156.8647284864],
      "speed factor": 0.272993642,
      "Uz factor": 0.9927887951,
      "Directional variance factor": 0.3656469138,
      "Velocity stability factor": 0.4543002453,
      "Direction stability factor": 0.193997816,
      score: 0.0,
    },
    id: "IDN:18.0",
    "45": {
      velocity: [0.0478280775, 0.3273291223, 0.6449445281],
      direction: [-92.4964992103, -21.9960890725, 17.0888425829],
      "speed factor": 0.4446378373,
      "Uz factor": 0.9393652233,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5145632203,
      "Direction stability factor": 0.6955962728,
      score: 0.0,
    },
    "90": {
      velocity: [0.0685477433, 0.4602354241, 0.7993467902],
      direction: [-47.8998582048, 46.4094512872, 68.4335918824],
      "speed factor": 0.7460550904,
      "Uz factor": 0.7817522875,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4666208115,
      "Direction stability factor": 0.6768515275,
      score: 0.0,
    },
    "135": {
      velocity: [0.3807775107, 0.8807544137, 1.0581454845],
      direction: [1.1509173513, 29.4791716838, 36.9577012278],
      "speed factor": 0.7454267841,
      "Uz factor": 0.9524195705,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4541695646,
      "Direction stability factor": 0.9005367115,
      score: 0.0,
    },
    "180": {
      velocity: [0.0324877381, 0.1950229163, 0.7601691223],
      direction: [-163.7442936244, -3.118562991, 169.8153504886],
      "speed factor": 0.3808785508,
      "Uz factor": 0.8791078147,
      "Directional variance factor": 0.7227944008,
      "Velocity stability factor": 0.4266184465,
      "Direction stability factor": 0.073445433,
      score: 0.0,
    },
    "225": {
      velocity: [0.0149147474, 0.116809216, 0.4188639495],
      direction: [-178.1865302768, -76.8631067237, 176.5722090147],
      "speed factor": 0.1594573629,
      "Uz factor": 0.5648235983,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6369382897,
      "Direction stability factor": 0.0145590575,
      score: 0.0,
    },
    "270": {
      velocity: [0.1078101465, 0.331320523, 0.6806913421],
      direction: [-76.3086165633, 4.5622774529, 65.4200797904],
      "speed factor": 0.3031689768,
      "Uz factor": 0.9980694933,
      "Directional variance factor": 0.5944642264,
      "Velocity stability factor": 0.5481094773,
      "Direction stability factor": 0.6063091768,
      score: 0.0,
    },
    "315": {
      velocity: [0.0316276116, 0.1247606971, 0.4789468937],
      direction: [-160.4949102796, -43.9514400091, 169.4788999157],
      "speed factor": 0.1537175948,
      "Uz factor": 0.9577791955,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6371185088,
      "Direction stability factor": 0.0834060828,
      score: 0.0,
    },
  },
  {
    position: [12.49640175, 96.58909071, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0228753905, 0.1194485561, 0.3054092871],
      direction: [-178.8854316216, -132.4084918073, 179.1336157387],
      "speed factor": 0.1224050332,
      "Uz factor": 0.6852605244,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8036867485,
      "Direction stability factor": 0.0055026462,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0154634571, 0.3878534244, 0.9893762892],
      direction: [-178.0561563953, -14.4635964478, 178.1595649219],
      "speed factor": 0.3443656118,
      "Uz factor": 0.9875720368,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.370196429,
      "Direction stability factor": 0.0105118852,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0578761612, 0.2404460179, 0.4874962974],
      direction: [-57.3583198734, -17.6800546592, 78.586961684],
      "speed factor": 0.3724669111,
      "Uz factor": 0.8884089479,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6161109826,
      "Direction stability factor": 0.6223742179,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1096997722, 0.4486975894, 0.6009408228],
      direction: [-104.1362375919, 5.1097148433, 44.3473260464],
      "speed factor": 0.5047534417,
      "Uz factor": 0.6398174733,
      "Directional variance factor": 0.545803125,
      "Velocity stability factor": 0.5933413102,
      "Direction stability factor": 0.5875456566,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0299914175, 0.4539456912, 0.9328176258],
      direction: [-136.3354123829, 18.5401249315, 150.8278352652],
      "speed factor": 0.6448937207,
      "Uz factor": 0.9974072344,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2616764508,
      "Direction stability factor": 0.2023243121,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0019590245, 0.1771291994, 0.5225299211],
      direction: [-179.8238419559, -118.793924054, 176.8730555683],
      "speed factor": 0.2075343881,
      "Uz factor": 0.4543716506,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5997319473,
      "Direction stability factor": 0.0091752847,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0190093824, 0.087691421, 0.3227672886],
      direction: [-163.85946575, 60.5863973921, 178.1752514222],
      "speed factor": 0.1626781534,
      "Uz factor": 0.9008004715,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7052491688,
      "Direction stability factor": 0.0499035634,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0164240468, 0.0991186106, 0.2717003414],
      direction: [-179.4080526659, -46.6981232159, 177.2650214018],
      "speed factor": 0.134974835,
      "Uz factor": 0.3081133485,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8127957552,
      "Direction stability factor": 0.0092414609,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0335757779, 0.2078029102, 0.6184564381],
      direction: [-147.1896632981, 19.0237544187, 154.6615366237],
      "speed factor": 0.2638967988,
      "Uz factor": 0.9767480618,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5289169541,
      "Direction stability factor": 0.1615244447,
      score: 0.0,
    },
    id: "IDN:17.0",
    "45": {
      velocity: [0.0235013518, 0.3457340111, 0.65877577],
      direction: [-65.9137623506, -22.6315272336, 179.7318771152],
      "speed factor": 0.4696386985,
      "Uz factor": 0.931217724,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4835420001,
      "Direction stability factor": 0.3176510015,
      score: 0.0,
    },
    "90": {
      velocity: [0.2043570263, 0.4522236148, 0.7341982123],
      direction: [-56.4961772735, 32.3096795302, 65.3731254738],
      "speed factor": 0.7330677131,
      "Uz factor": 0.6774273594,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6132914198,
      "Direction stability factor": 0.661474159,
      score: 0.0,
    },
    "135": {
      velocity: [0.362270116, 0.8519898147, 1.0169904655],
      direction: [14.4554539854, 28.6848272056, 37.3359016171],
      "speed factor": 0.7210818564,
      "Uz factor": 0.9236040823,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4724192651,
      "Direction stability factor": 0.936443201,
      score: 0.0,
    },
    "180": {
      velocity: [0.0276958791, 0.1712850937, 0.6553130743],
      direction: [-179.4312500066, 9.0998976843, 179.1440410193],
      "speed factor": 0.3345187299,
      "Uz factor": 0.960067419,
      "Directional variance factor": 0.1911202058,
      "Velocity stability factor": 0.5054647126,
      "Direction stability factor": 0.0039575249,
      score: 0.0,
    },
    "225": {
      velocity: [0.0128383536, 0.0980421672, 0.4678931644],
      direction: [-178.4539115419, -56.23755998, 178.2641966038],
      "speed factor": 0.1338382876,
      "Uz factor": 0.5923204503,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5910055596,
      "Direction stability factor": 0.0091163663,
      score: 0.0,
    },
    "270": {
      velocity: [0.0924439225, 0.3273840222, 0.611453637],
      direction: [-105.9414777072, 10.8398808461, 100.3946137422],
      "speed factor": 0.2995669514,
      "Uz factor": 0.9999601175,
      "Directional variance factor": 0.0364550359,
      "Velocity stability factor": 0.5906034742,
      "Direction stability factor": 0.4268441904,
      score: 0.0,
    },
    "315": {
      velocity: [0.0202826668, 0.1204986338, 0.5143663401],
      direction: [-163.1513160247, -19.8643866794, 174.8622897066],
      "speed factor": 0.1484663087,
      "Uz factor": 0.9811841082,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5991815526,
      "Direction stability factor": 0.0610733174,
      score: 0.0,
    },
  },
  {
    position: [12.4859298, 93.58910899, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0262496681, 0.1695797694, 0.5270249384],
      direction: [-178.9804708797, -132.7676484504, 178.2203597571],
      "speed factor": 0.1737770466,
      "Uz factor": 0.9906670773,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6520459217,
      "Direction stability factor": 0.0077754705,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0449983817, 0.3901369342, 0.754420002],
      direction: [-174.5586840479, -12.4855191774, 172.4794789142],
      "speed factor": 0.3463930845,
      "Uz factor": 0.989875115,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5412358734,
      "Direction stability factor": 0.0360051029,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0584914638, 0.2559257971, 0.5092888488],
      direction: [-58.3855562806, -22.7626159803, 78.9172459269],
      "speed factor": 0.396446121,
      "Uz factor": 0.9057102921,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.597187956,
      "Direction stability factor": 0.6186033272,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3005931759, 0.7000041585, 0.9840764044],
      direction: [-60.8734725004, -13.4015262902, 55.4501201504],
      "speed factor": 0.7874557754,
      "Uz factor": 0.8573441313,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4341995771,
      "Direction stability factor": 0.6768789093,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4091227005, 0.6817906532, 0.9113124852],
      direction: [-36.6658504113, 43.6727218164, 89.7499623577],
      "speed factor": 0.9685795451,
      "Uz factor": 0.8353099512,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5893134905,
      "Direction stability factor": 0.6488449645,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0949634649, 0.5401453784, 0.794697764],
      direction: [-108.4331842338, 7.6094284404, 158.2795243537],
      "speed factor": 0.6328642648,
      "Uz factor": 0.9251690161,
      "Directional variance factor": 0.3236063609,
      "Velocity stability factor": 0.4619728319,
      "Direction stability factor": 0.259131365,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0653198965, 0.3062678492, 0.6271235487],
      direction: [-21.9146890561, 11.4425363035, 143.6381755404],
      "speed factor": 0.5681637676,
      "Uz factor": 0.9839920597,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.454855034,
      "Direction stability factor": 0.5401309317,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0190509843, 0.1126048523, 0.3229715187],
      direction: [-133.6390074541, -28.6018182425, 169.032389421],
      "speed factor": 0.1533397338,
      "Uz factor": 0.9698272382,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7771230024,
      "Direction stability factor": 0.1592461198,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0279367568, 0.2067612282, 0.5199282149],
      direction: [-150.2812616841, 5.7646312824, 132.2577906217],
      "speed factor": 0.2625739273,
      "Uz factor": 0.9858728351,
      "Directional variance factor": 0.4875883305,
      "Velocity stability factor": 0.6037331196,
      "Direction stability factor": 0.2151692992,
      score: 0.0,
    },
    id: "IDN:16.0",
    "45": {
      velocity: [0.0341323074, 0.2721487307, 0.5433401314],
      direction: [-106.2572499327, -29.8883545078, 42.8807807328],
      "speed factor": 0.369681812,
      "Uz factor": 0.952477873,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5860301521,
      "Direction stability factor": 0.5857276926,
      score: 0.0,
    },
    "90": {
      velocity: [0.3928271005, 0.6711171563, 0.9197204359],
      direction: [-46.0604832265, -18.6434082641, 39.327902559],
      "speed factor": 1.0879005494,
      "Uz factor": 0.9100700163,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6154429308,
      "Direction stability factor": 0.7628100395,
      score: 0.0,
    },
    "135": {
      velocity: [0.5490644559, 0.9271352033, 1.0931097049],
      direction: [-10.2764442993, 20.3748474432, 51.9554351934],
      "speed factor": 0.7846811804,
      "Uz factor": 0.8725655058,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5616024575,
      "Direction stability factor": 0.8271336681,
      score: 0.0,
    },
    "180": {
      velocity: [0.2772033318, 0.5988980384, 0.9046586333],
      direction: [-9.8866860194, 44.6075814034, 77.6995719102],
      "speed factor": 1.1696441693,
      "Uz factor": 0.966031988,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5055922779,
      "Direction stability factor": 0.7567048391,
      score: 0.0,
    },
    "225": {
      velocity: [0.0774681777, 0.3333703963, 0.6861746027],
      direction: [-149.4184449181, 8.5960136859, 48.8688368315],
      "speed factor": 0.4550870735,
      "Uz factor": 0.9811099534,
      "Directional variance factor": 0.2359098946,
      "Velocity stability factor": 0.4529064681,
      "Direction stability factor": 0.4492019951,
      score: 0.0,
    },
    "270": {
      velocity: [0.0645799222, 0.3377002203, 0.8389159473],
      direction: [-95.4977965109, -8.9552184586, 89.8897965098],
      "speed factor": 0.3090066058,
      "Uz factor": 0.9968728481,
      "Directional variance factor": 0.2039805815,
      "Velocity stability factor": 0.3892012622,
      "Direction stability factor": 0.4850344638,
      score: 0.0,
    },
    "315": {
      velocity: [0.0182024955, 0.1296416665, 0.3383468725],
      direction: [-177.0637568679, -50.483618406, 155.9237976664],
      "speed factor": 0.1597314348,
      "Uz factor": 0.9868181184,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7402873662,
      "Direction stability factor": 0.0750345707,
      score: 0.0,
    },
  },
  {
    position: [9.735933557, 93.59870832, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0273797525, 0.1915921628, 0.6859314619],
      direction: [-178.5917626693, -140.674587167, 178.162792346],
      "speed factor": 0.1963342699,
      "Uz factor": 0.9662727684,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5424179933,
      "Direction stability factor": 0.009015125,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0195245949, 0.1654030946, 0.5088251813],
      direction: [-174.8889005342, 15.0803466315, 179.5897968949],
      "speed factor": 0.1468573803,
      "Uz factor": 0.8520323497,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6835823018,
      "Direction stability factor": 0.0153369516,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0114295286, 0.1132261459, 0.331166118],
      direction: [-176.072393137, 38.1811716336, 178.4086291075],
      "speed factor": 0.1753948482,
      "Uz factor": 0.9347422184,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7142979232,
      "Direction stability factor": 0.0153304938,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1138974602, 0.6314652934, 0.9721266482],
      direction: [-18.758752558, -4.8631469422, 38.8513169075],
      "speed factor": 0.7103543404,
      "Uz factor": 0.9384292636,
      "Directional variance factor": 0.5677202718,
      "Velocity stability factor": 0.2895415464,
      "Direction stability factor": 0.8399720293,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3200182463, 0.5732559561, 0.8087647065],
      direction: [-43.7937923326, 19.1889939147, 96.0144397952],
      "speed factor": 0.8143907379,
      "Uz factor": 0.6296117534,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6003073263,
      "Direction stability factor": 0.6116437996,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0359544328, 0.3912052553, 0.6404835712],
      direction: [-119.4772381477, -12.9148803487, 148.325742914],
      "speed factor": 0.4583577611,
      "Uz factor": 0.6887382549,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5351762793,
      "Direction stability factor": 0.2561028304,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.040673677, 0.2641208199, 0.7090358391],
      direction: [-66.7090969987, 13.483788849, 150.4306621841],
      "speed factor": 0.4899759491,
      "Uz factor": 0.9606204386,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.351456213,
      "Direction stability factor": 0.3968340023,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0110880264, 0.1237508205, 0.4269356415],
      direction: [-171.7526227343, -37.5185090657, 159.2841610567],
      "speed factor": 0.1685177636,
      "Uz factor": 0.9281305427,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6950424291,
      "Direction stability factor": 0.0804533784,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0295328071, 0.1405788618, 0.6051218024],
      direction: [-179.9009267295, -25.8889331056, 176.0029479109],
      "speed factor": 0.1785264296,
      "Uz factor": 0.9982828103,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5364007814,
      "Direction stability factor": 0.011378126,
      score: 0.0,
    },
    id: "IDN:15.0",
    "45": {
      velocity: [0.0165552389, 0.1329026602, 0.3062258443],
      direction: [-175.2401875971, -35.2187053062, 175.1478734756],
      "speed factor": 0.1805325203,
      "Uz factor": 0.8480822412,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7645069639,
      "Direction stability factor": 0.0266998304,
      score: 0.0,
    },
    "90": {
      velocity: [0.0346166662, 0.5459430297, 1.0162109642],
      direction: [-153.832763929, 12.9142150548, 102.5636332164],
      "speed factor": 0.8849896272,
      "Uz factor": 0.9823025067,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2835760085,
      "Direction stability factor": 0.2877877857,
      score: 0.0,
    },
    "135": {
      velocity: [0.3710047348, 0.792891465, 0.9161421319],
      direction: [-9.5351162756, 0.7785848252, 31.2164675951],
      "speed factor": 0.6710639489,
      "Uz factor": 0.7846662972,
      "Directional variance factor": 0.93079246,
      "Velocity stability factor": 0.5607223927,
      "Direction stability factor": 0.8868011559,
      score: 0.0,
    },
    "180": {
      velocity: [0.1318544333, 0.4004855449, 0.9924508962],
      direction: [-75.3883316541, 62.9144861774, 84.6619108307],
      "speed factor": 0.7821457951,
      "Uz factor": 0.7305912098,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3218870957,
      "Direction stability factor": 0.5554159931,
      score: 0.0,
    },
    "225": {
      velocity: [0.0337115606, 0.2747081694, 0.5003575766],
      direction: [-95.3376052977, 5.2035408603, 65.5957096177],
      "speed factor": 0.375006714,
      "Uz factor": 0.9138750862,
      "Directional variance factor": 0.5374630346,
      "Velocity stability factor": 0.5805876092,
      "Direction stability factor": 0.5529630141,
      score: 0.0,
    },
    "270": {
      velocity: [0.0822452753, 0.3553219446, 0.7852455146],
      direction: [-176.4578317178, -25.0641190677, 153.1433941621],
      "speed factor": 0.3251310525,
      "Uz factor": 0.9696956511,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4454711587,
      "Direction stability factor": 0.0844410392,
      score: 0.0,
    },
    "315": {
      velocity: [0.0079247141, 0.1035201113, 0.3606092886],
      direction: [-176.5021605349, -67.6785736986, 176.2559751018],
      "speed factor": 0.1275470793,
      "Uz factor": 0.9141687546,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7138895875,
      "Direction stability factor": 0.0201162899,
      score: 0.0,
    },
  },
  {
    position: [6.735951834, 93.60918028, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0333527333, 0.1993442331, 0.653120343],
      direction: [-179.8951969667, -153.677662981, 178.0851828195],
      "speed factor": 0.2042782121,
      "Uz factor": 0.9724615238,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5693663801,
      "Direction stability factor": 0.0056100561,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0393594396, 0.1634641278, 0.493786179],
      direction: [-178.0642392592, 125.3166480276, 178.3788925882],
      "speed factor": 0.145135819,
      "Uz factor": 0.9205358336,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7061342928,
      "Direction stability factor": 0.0098801893,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0135790511, 0.1335919104, 0.352506546],
      direction: [-174.074731513, 49.6405090404, 155.731863894],
      "speed factor": 0.2069427752,
      "Uz factor": 0.9238775308,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6971498028,
      "Direction stability factor": 0.0838705683,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1523997071, 0.6510996054, 1.0073798952],
      direction: [-15.6830871872, -3.278206884, 152.5758037816],
      "speed factor": 0.7324415697,
      "Uz factor": 0.9225110448,
      "Directional variance factor": 0.7086038325,
      "Velocity stability factor": 0.2922311304,
      "Direction stability factor": 0.5326141918,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1488114935, 0.3455254221, 0.5187771284],
      direction: [-45.234233926, 6.6472916211, 99.9951663551],
      "speed factor": 0.4908674746,
      "Uz factor": 0.5284552871,
      "Directional variance factor": 0.4091296337,
      "Velocity stability factor": 0.697445269,
      "Direction stability factor": 0.5965849992,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0542364721, 0.3724348827, 0.6284256718],
      direction: [-147.9507994289, -19.1368576173, 98.472411129],
      "speed factor": 0.4363653521,
      "Uz factor": 0.5851939705,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5585047217,
      "Direction stability factor": 0.3154910818,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0325215565, 0.2620937675, 0.6828852486],
      direction: [-94.2681633937, 11.9693503697, 149.1190469474],
      "speed factor": 0.4862155227,
      "Uz factor": 0.9599780658,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3689209897,
      "Direction stability factor": 0.3239244157,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0273271043, 0.1278383627, 0.4180645323],
      direction: [-173.0908015149, -58.3153664674, 172.6967987184],
      "speed factor": 0.1740839769,
      "Uz factor": 0.7653292907,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7134567265,
      "Direction stability factor": 0.0394788882,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0309375809, 0.1208160445, 0.3756751684],
      direction: [-176.8849941918, -41.8403770444, 178.7725612457],
      "speed factor": 0.1534288781,
      "Uz factor": 0.9470248677,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7223364632,
      "Direction stability factor": 0.012062346,
      score: 0.0,
    },
    id: "IDN:14.0",
    "45": {
      velocity: [0.0280121027, 0.1443734292, 0.3742014188],
      direction: [-172.387908672, 32.6382675013, 178.3990565838],
      "speed factor": 0.1961142011,
      "Uz factor": 0.9601101599,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7185590405,
      "Direction stability factor": 0.0255917632,
      score: 0.0,
    },
    "90": {
      velocity: [0.0508095382, 0.6016116719, 1.0334619566],
      direction: [-109.9962014349, 18.7038267234, 65.3688104715],
      "speed factor": 0.9752301251,
      "Uz factor": 0.9677525886,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2828037314,
      "Direction stability factor": 0.5128749669,
      score: 0.0,
    },
    "135": {
      velocity: [0.3516461748, 0.7692307537, 0.9070222222],
      direction: [-12.0805196429, -3.6010952158, 85.3466884513],
      "speed factor": 0.6510386982,
      "Uz factor": 0.8426410935,
      "Directional variance factor": 0.6799026475,
      "Velocity stability factor": 0.5524719777,
      "Direction stability factor": 0.7293688664,
      score: 0.0,
    },
    "180": {
      velocity: [0.1251379916, 0.3738405763, 0.8949382203],
      direction: [-81.2106410457, 46.698434085, 83.0111486817],
      "speed factor": 0.7301083362,
      "Uz factor": 0.5261807426,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3934306132,
      "Direction stability factor": 0.5438283619,
      score: 0.0,
    },
    "225": {
      velocity: [0.0285265435, 0.2573307006, 0.4905689113],
      direction: [-130.6875526918, 0.1255485632, 74.2818986415],
      "speed factor": 0.3512845674,
      "Uz factor": 0.842537125,
      "Directional variance factor": 0.9888401277,
      "Velocity stability factor": 0.5847252788,
      "Direction stability factor": 0.430640413,
      score: 0.0,
    },
    "270": {
      velocity: [0.0450272857, 0.3298834497, 0.7736537136],
      direction: [-171.4733935332, -26.9685615497, 97.2773063941],
      "speed factor": 0.3018540083,
      "Uz factor": 0.9698709513,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.425257139,
      "Direction stability factor": 0.253470278,
      score: 0.0,
    },
    "315": {
      velocity: [0.0063380242, 0.1062634876, 0.2917733987],
      direction: [-177.3583339658, -80.9610125586, 178.2319935202],
      "speed factor": 0.1309271919,
      "Uz factor": 0.8621735351,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7684445575,
      "Direction stability factor": 0.0122490903,
      score: 0.0,
    },
  },
  {
    position: [3.735970111, 93.61965223, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0288506987, 0.1917393397, 0.5830384482],
      direction: [-179.7482773239, -163.8925887552, 178.8083049369],
      "speed factor": 0.1964850896,
      "Uz factor": 0.9766525793,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6149332864,
      "Direction stability factor": 0.0040094937,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0193813765, 0.1341783349, 0.3269598975],
      direction: [-173.3302010945, 123.9088776373, 177.2248370969],
      "speed factor": 0.1191336765,
      "Uz factor": 0.8670245017,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8010971367,
      "Direction stability factor": 0.026236005,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0310168877, 0.1591756419, 0.3541019079],
      direction: [-176.8954009564, 43.4230041452, 149.2208103419],
      "speed factor": 0.2465736808,
      "Uz factor": 0.9826723138,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7113059177,
      "Direction stability factor": 0.0941216353,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1173746519, 0.5770781045, 0.9598004053],
      direction: [-13.6750917709, -3.9443235104, 30.50121596],
      "speed factor": 0.6491725524,
      "Uz factor": 0.9066929217,
      "Directional variance factor": 0.6493934657,
      "Velocity stability factor": 0.3026239303,
      "Direction stability factor": 0.8772880341,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2678931923, 0.4962898986, 0.7582822989],
      direction: [-54.6233617665, -14.365689584, 91.9770651724],
      "speed factor": 0.7050496249,
      "Uz factor": 0.6570123747,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5989639841,
      "Direction stability factor": 0.5927765918,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0560996747, 0.3641339953, 0.6029906274],
      direction: [-135.5473843392, -31.0496425937, 58.4677571252],
      "speed factor": 0.4266395723,
      "Uz factor": 0.6905187233,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5794944011,
      "Direction stability factor": 0.4610690515,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0186398897, 0.2991666496, 0.723729276],
      direction: [-92.8421862563, 4.887506023, 88.6215627726],
      "speed factor": 0.5549901865,
      "Uz factor": 0.9655578417,
      "Directional variance factor": 0.5655550202,
      "Velocity stability factor": 0.3158180299,
      "Direction stability factor": 0.4959340305,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.012737991, 0.1124750626, 0.4535732604],
      direction: [-176.5203940723, -72.8054061768, 178.7057020482],
      "speed factor": 0.1531629927,
      "Uz factor": 0.7049074844,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6767179898,
      "Direction stability factor": 0.0132608441,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.019497954, 0.1430809152, 0.5033622769],
      direction: [-177.5223599625, -71.7873555724, 161.2562488333],
      "speed factor": 0.1817038821,
      "Uz factor": 0.8320317707,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6102789945,
      "Direction stability factor": 0.0589483089,
      score: 0.0,
    },
    id: "IDN:13.0",
    "45": {
      velocity: [0.025958459, 0.1813533354, 0.4945788709],
      direction: [-169.9361364174, 35.3836836444, 158.7126984317],
      "speed factor": 0.2463470231,
      "Uz factor": 0.9994562811,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6190264338,
      "Direction stability factor": 0.0870865699,
      score: 0.0,
    },
    "90": {
      velocity: [0.1591036665, 0.5825926198, 1.002390343],
      direction: [-24.8764850229, 24.4735567323, 70.3254642642],
      "speed factor": 0.9443996852,
      "Uz factor": 0.956874065,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3845208678,
      "Direction stability factor": 0.7355501409,
      score: 0.0,
    },
    "135": {
      velocity: [0.3959180601, 0.7572053094, 0.9368533334],
      direction: [-18.8464235662, -9.1545609508, 73.6789271912],
      "speed factor": 0.6408609595,
      "Uz factor": 0.8437135359,
      "Directional variance factor": 0.1862612488,
      "Velocity stability factor": 0.5641085096,
      "Direction stability factor": 0.7429851368,
      score: 0.0,
    },
    "180": {
      velocity: [0.2014335224, 0.55563665, 0.9099441303],
      direction: [-69.6149878644, 10.0049071978, 70.394784066],
      "speed factor": 1.0851549448,
      "Uz factor": 0.631367496,
      "Directional variance factor": 0.1106749158,
      "Velocity stability factor": 0.4417241916,
      "Direction stability factor": 0.6110839669,
      score: 0.0,
    },
    "225": {
      velocity: [0.018446754, 0.2510197492, 0.545284371],
      direction: [-131.3141734879, -5.7062787651, 76.4327325005],
      "speed factor": 0.3426694282,
      "Uz factor": 0.8850286415,
      "Directional variance factor": 0.4927752209,
      "Velocity stability factor": 0.5264885653,
      "Direction stability factor": 0.4229252611,
      score: 0.0,
    },
    "270": {
      velocity: [0.0629430397, 0.283132101, 0.9128387768],
      direction: [-167.3011654932, -25.7243075536, 166.1808467701],
      "speed factor": 0.2590750147,
      "Uz factor": 0.9557824558,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3295995193,
      "Direction stability factor": 0.073661077,
      score: 0.0,
    },
    "315": {
      velocity: [0.0133074111, 0.0994748113, 0.2872173137],
      direction: [-178.7831020511, -81.317897172, 145.3545147142],
      "speed factor": 0.1225628671,
      "Uz factor": 0.8337543881,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7777944348,
      "Direction stability factor": 0.0996177312,
      score: 0.0,
    },
  },
  {
    position: [0.735988388, 93.63012419, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0204512705, 0.1202293589, 0.6295827574],
      direction: [-179.3125535984, -161.489623382, 179.6080042378],
      "speed factor": 0.1232051616,
      "Uz factor": 0.839464824,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5767566857,
      "Direction stability factor": 0.0029984505,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0231808939, 0.1514847563, 0.3078752265],
      direction: [-175.2874779178, 122.4084506961, 174.4641336583],
      "speed factor": 0.1344996266,
      "Uz factor": 0.9127837878,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8158957338,
      "Direction stability factor": 0.0284677456,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0163103328, 0.1580799286, 0.4130065179],
      direction: [-131.065158043, 23.0478521984, 143.1743481486],
      "speed factor": 0.2448763478,
      "Uz factor": 0.9967981548,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6455303281,
      "Direction stability factor": 0.2382235939,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1350674941, 0.5695633367, 0.9532997025],
      direction: [-22.3476047169, -4.7984448293, 33.8151434219],
      "speed factor": 0.6407189636,
      "Uz factor": 0.9175214438,
      "Directional variance factor": 0.5734715707,
      "Velocity stability factor": 0.3226518073,
      "Direction stability factor": 0.8439923663,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2447451251, 0.4514687811, 0.7333349272],
      direction: [-53.8781430305, -17.4623387158, 89.3169742466],
      "speed factor": 0.6413749214,
      "Uz factor": 0.686298946,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6004354399,
      "Direction stability factor": 0.6022357853,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0274720558, 0.3820184883, 0.6241232279],
      direction: [-113.4365619081, -38.2707122731, 61.9015932864],
      "speed factor": 0.4475940356,
      "Uz factor": 0.5676331505,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5412336641,
      "Direction stability factor": 0.5129495689,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0263151913, 0.3222985769, 0.6023629711],
      direction: [-98.8553458214, 7.6751705591, 87.0007706201],
      "speed factor": 0.5979026991,
      "Uz factor": 0.9368653074,
      "Directional variance factor": 0.317762617,
      "Velocity stability factor": 0.4410332754,
      "Direction stability factor": 0.4837330099,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0132349536, 0.1125642588, 0.4230790532],
      direction: [-176.0948375958, -86.8718075689, 177.8819163841],
      "speed factor": 0.1532844557,
      "Uz factor": 0.4783297411,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6994450454,
      "Direction stability factor": 0.0167312389,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0279938181, 0.1444864239, 0.522412909],
      direction: [-178.3823318403, -80.522434045, 179.4879354867],
      "speed factor": 0.183488791,
      "Uz factor": 0.7010583526,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6017778204,
      "Direction stability factor": 0.0059159241,
      score: 0.0,
    },
    id: "IDN:12.0",
    "45": {
      velocity: [0.0255419755, 0.1978756343, 0.5534679896],
      direction: [-167.2363379211, 38.5012527604, 162.8091919801],
      "speed factor": 0.2687906089,
      "Uz factor": 0.9990920305,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5708128558,
      "Direction stability factor": 0.0832068614,
      score: 0.0,
    },
    "90": {
      velocity: [0.2071463579, 0.5175384386, 0.9079954127],
      direction: [-4.4865109682, 28.2293891046, 58.9347514702],
      "speed factor": 0.8389449537,
      "Uz factor": 0.9493513077,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4884800388,
      "Direction stability factor": 0.8238298266,
      score: 0.0,
    },
    "135": {
      velocity: [0.4112119186, 0.7244135548, 0.9495731554],
      direction: [-19.2902861743, -8.6680041092, 43.43940417],
      "speed factor": 0.6131076474,
      "Uz factor": 0.8865319875,
      "Directional variance factor": 0.2295107458,
      "Velocity stability factor": 0.566182696,
      "Direction stability factor": 0.8257508602,
      score: 0.0,
    },
    "180": {
      velocity: [0.2092802403, 0.5622169665, 0.8828302788],
      direction: [-70.233425324, 4.5926680709, 60.6758348362],
      "speed factor": 1.0980062624,
      "Uz factor": 0.6336281973,
      "Directional variance factor": 0.5917628381,
      "Velocity stability factor": 0.4692716128,
      "Direction stability factor": 0.6363631662,
      score: 0.0,
    },
    "225": {
      velocity: [0.0121825542, 0.256628841, 0.7107508501],
      direction: [-137.7726626465, -5.7358690356, 59.6889160488],
      "speed factor": 0.3503264523,
      "Uz factor": 0.843814348,
      "Directional variance factor": 0.4901449746,
      "Velocity stability factor": 0.372140361,
      "Direction stability factor": 0.4514956147,
      score: 0.0,
    },
    "270": {
      velocity: [0.0421353848, 0.2614114974, 0.9022581951],
      direction: [-175.7053805116, -6.0730331557, 179.8833018657],
      "speed factor": 0.239199961,
      "Uz factor": 0.9710087644,
      "Directional variance factor": 0.4601748306,
      "Velocity stability factor": 0.3215323711,
      "Direction stability factor": 0.0122536601,
      score: 0.0,
    },
    "315": {
      velocity: [0.0236213933, 0.1135435195, 0.3102429562],
      direction: [-154.849302104, -85.5753764956, 178.1766093133],
      "speed factor": 0.1398969157,
      "Uz factor": 0.7219825152,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7674822787,
      "Direction stability factor": 0.0749280238,
      score: 0.0,
    },
  },
  {
    position: [-2.263993335, 93.64059614, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0206077827, 0.1161989665, 0.3941926244],
      direction: [-177.4120367721, 140.2407074378, 178.9651599277],
      "speed factor": 0.1190750129,
      "Uz factor": 0.6430971347,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7404217481,
      "Direction stability factor": 0.0100633425,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0096129464, 0.1378773986, 0.335453211],
      direction: [-174.0841930289, 106.1804654783, 163.3969188454],
      "speed factor": 0.1224179851,
      "Uz factor": 0.9142531138,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.789287752,
      "Direction stability factor": 0.062552467,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0235126792, 0.179719352, 0.5187092828],
      direction: [-120.2406091614, 13.036061579, 112.0411972773],
      "speed factor": 0.2783972572,
      "Uz factor": 0.9993852697,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5575148333,
      "Direction stability factor": 0.3547727599,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1133791293, 0.5303437791, 0.9118561689],
      direction: [-45.3554118608, -7.0084288629, 28.2507354672],
      "speed factor": 0.5965997012,
      "Uz factor": 0.9301370118,
      "Directional variance factor": 0.3770285455,
      "Velocity stability factor": 0.339005512,
      "Direction stability factor": 0.7955384796,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2474304882, 0.4414064522, 0.6270633299],
      direction: [-58.8163057685, -29.9150130589, 81.0875821533],
      "speed factor": 0.6270799675,
      "Uz factor": 0.7588968972,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6895395099,
      "Direction stability factor": 0.6113780891,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0442687487, 0.3626775537, 0.5925981402],
      direction: [-122.2530850462, -50.858199492, 57.5010198335],
      "speed factor": 0.4249331246,
      "Uz factor": 0.7249174525,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5783883824,
      "Direction stability factor": 0.500683042,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0309866341, 0.2543918135, 0.4709511474],
      direction: [-149.6926927414, 8.1053120632, 161.3325824068],
      "speed factor": 0.4719274698,
      "Uz factor": 0.9472773231,
      "Directional variance factor": 0.2795278166,
      "Velocity stability factor": 0.5730813804,
      "Direction stability factor": 0.1360409024,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0176775857, 0.1084051786, 0.3799882164],
      direction: [-168.0576860714, -47.0381272959, 174.3235199662],
      "speed factor": 0.1476208253,
      "Uz factor": 0.8431015952,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7343032259,
      "Direction stability factor": 0.0489410943,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0184980259, 0.1412514787, 0.3289542431],
      direction: [-178.5964305273, 110.0294183238, 175.8405193647],
      "speed factor": 0.1793806113,
      "Uz factor": 0.7469368268,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7499478605,
      "Direction stability factor": 0.015452917,
      score: 0.0,
    },
    id: "IDN:11.0",
    "45": {
      velocity: [0.0401897564, 0.2131025829, 0.5281674837],
      direction: [-178.4463271985, 39.6868554153, 137.2807055086],
      "speed factor": 0.2894746149,
      "Uz factor": 0.9994842677,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6032895489,
      "Direction stability factor": 0.1229804647,
      score: 0.0,
    },
    "90": {
      velocity: [0.1115391644, 0.4418903046, 0.8144993532],
      direction: [-30.9243561683, 28.5835976211, 73.3499786417],
      "speed factor": 0.7163171148,
      "Uz factor": 0.9472621337,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4869392118,
      "Direction stability factor": 0.71034907,
      score: 0.0,
    },
    "135": {
      velocity: [0.3740606764, 0.7020827117, 0.9694076186],
      direction: [-22.9461879695, -13.145591114, 37.5672010762],
      "speed factor": 0.5942079311,
      "Uz factor": 0.8922437003,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5202629985,
      "Direction stability factor": 0.8319072527,
      score: 0.0,
    },
    "180": {
      velocity: [0.0086225315, 0.5651588422, 0.8874903525],
      direction: [-127.617494473, -7.6745737674, 45.3281492133],
      "speed factor": 1.1037517274,
      "Uz factor": 0.6556887694,
      "Directional variance factor": 0.3178156651,
      "Velocity stability factor": 0.3074900534,
      "Direction stability factor": 0.5195954342,
      score: 0.0,
    },
    "225": {
      velocity: [0.0155204412, 0.2535199095, 0.6703158727],
      direction: [-145.4577515168, -7.9604767602, 63.0636837474],
      "speed factor": 0.3460824205,
      "Uz factor": 0.9075482031,
      "Directional variance factor": 0.2924020658,
      "Velocity stability factor": 0.4114825627,
      "Direction stability factor": 0.4207737909,
      score: 0.0,
    },
    "270": {
      velocity: [0.0130656219, 0.2608666616, 0.8347893213],
      direction: [-163.8372285023, 16.2985925473, 177.0171729545],
      "speed factor": 0.2387014187,
      "Uz factor": 0.9835056225,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3518217129,
      "Direction stability factor": 0.0531822182,
      score: 0.0,
    },
    "315": {
      velocity: [0.0296961212, 0.1064388815, 0.1986180851],
      direction: [-178.9478447713, -166.1700305374, 179.5928983386],
      "speed factor": 0.1311432946,
      "Uz factor": 0.5994819789,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.862964427,
      "Direction stability factor": 0.0040534914,
      score: 0.0,
    },
  },
  {
    position: [-5.263975058, 93.65106809, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0289657281, 0.1999888602, 0.6023764039],
      direction: [-179.8660847441, 64.4849390116, 175.9551197898],
      "speed factor": 0.2049387943,
      "Uz factor": 0.8863448223,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6015766052,
      "Direction stability factor": 0.0116077652,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0243808015, 0.2174264072, 0.6710026065],
      direction: [-179.872225513, 61.983205944, 167.8486592642],
      "speed factor": 0.1930476129,
      "Uz factor": 0.9436677055,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5818468466,
      "Direction stability factor": 0.0341086534,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.025030852, 0.1614128634, 0.3590147228],
      direction: [-62.8543910649, 30.3206388212, 79.6059831807],
      "speed factor": 0.250039286,
      "Uz factor": 0.9993733811,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7015672005,
      "Direction stability factor": 0.6042767382,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1097806107, 0.5588249948, 0.8578857202],
      direction: [-40.2713423028, -6.2546713028, 20.302110375],
      "speed factor": 0.6286390791,
      "Uz factor": 0.9693413939,
      "Directional variance factor": 0.4440292175,
      "Velocity stability factor": 0.3807043542,
      "Direction stability factor": 0.8317404092,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2880349285, 0.5253130485, 0.8573162592],
      direction: [-62.6043045558, -34.7302849826, 50.171260997],
      "speed factor": 0.7462810926,
      "Uz factor": 0.8961657305,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5344465981,
      "Direction stability factor": 0.6867345401,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0840837027, 0.5419905924, 0.8520249183],
      direction: [-100.9921065759, -59.8403121012, 98.0995441561],
      "speed factor": 0.6350262199,
      "Uz factor": 0.9257851911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4095283909,
      "Direction stability factor": 0.4469676369,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0709516731, 0.2915861068, 0.4802157285],
      direction: [-107.23495372, 12.6226498326, 93.5525929705],
      "speed factor": 0.5409273661,
      "Uz factor": 0.8913542586,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6028715038,
      "Direction stability factor": 0.4422568147,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0120251016, 0.2675250608, 0.9022888442],
      direction: [-179.150382895, 24.1651622729, 176.8198530758],
      "speed factor": 0.3643024327,
      "Uz factor": 0.9894683919,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.347134242,
      "Direction stability factor": 0.011193789,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1102224826, 0.446179319, 0.9001608186],
      direction: [-69.1098528825, -4.0576613207, 74.5467314877],
      "speed factor": 0.5666200433,
      "Uz factor": 0.9069164683,
      "Directional variance factor": 0.6393189937,
      "Velocity stability factor": 0.3637564333,
      "Direction stability factor": 0.6009539323,
      score: 0.0,
    },
    id: "IDN:10.0",
    "45": {
      velocity: [0.0258301674, 0.1846088072, 0.4985985492],
      direction: [-166.6198874804, 43.032655568, 134.3882069614],
      "speed factor": 0.2507691958,
      "Uz factor": 0.9999907702,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6156542654,
      "Direction stability factor": 0.1638664043,
      score: 0.0,
    },
    "90": {
      velocity: [0.0362237625, 0.2295929298, 0.4247074185],
      direction: [-81.8103874079, 22.9781085978, 67.7653311803],
      "speed factor": 0.3721768579,
      "Uz factor": 0.9158985938,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7164622778,
      "Direction stability factor": 0.5845118928,
      score: 0.0,
    },
    "135": {
      velocity: [0.3628452039, 0.4876908485, 0.6423082348],
      direction: [-34.9583706503, -24.6911040169, -0.2691372911],
      "speed factor": 0.4127573081,
      "Uz factor": 0.8811380452,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7748056688,
      "Direction stability factor": 0.9036410184,
      score: 0.0,
    },
    "180": {
      velocity: [0.0756110465, 0.688738116, 1.098756404],
      direction: [-66.2177155613, -23.7496514748, 12.2349158375],
      "speed factor": 1.3451012857,
      "Uz factor": 0.9238907748,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1938055757,
      "Direction stability factor": 0.7820760239,
      score: 0.0,
    },
    "225": {
      velocity: [0.0812449515, 0.2828685837, 0.5526294316],
      direction: [-113.5281529772, -36.5002624771, 58.4296742445],
      "speed factor": 0.3861465727,
      "Uz factor": 0.8132761878,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5763287695,
      "Direction stability factor": 0.5223393688,
      score: 0.0,
    },
    "270": {
      velocity: [0.2182241239, 0.7157373009, 1.2886701291],
      direction: [-39.4133417719, 39.7608629938, 68.1650685038],
      "speed factor": 0.6549227412,
      "Uz factor": 0.9709562813,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1556287611,
      "Direction stability factor": 0.7011710826,
      score: 0.0,
    },
    "315": {
      velocity: [0.1018247142, 0.3344788284, 0.6355422133],
      direction: [-106.0711678761, 26.5297227019, 167.1793622947],
      "speed factor": 0.4121112033,
      "Uz factor": 0.9470118207,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5670291677,
      "Direction stability factor": 0.2409707495,
      score: 0.0,
    },
  },
  {
    position: [-5.258533199, 95.21003953, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0274959577, 0.2859703295, 1.2412150094],
      direction: [-177.8593506622, 35.9023254656, 176.421534058],
      "speed factor": 0.2930483953,
      "Uz factor": 0.9643922388,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1566706284,
      "Direction stability factor": 0.0158864313,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0111727632, 0.2011733178, 0.7266595497],
      direction: [-172.1503185262, -8.2978148588, 179.9954892589],
      "speed factor": 0.1786168906,
      "Uz factor": 0.9704130115,
      "Directional variance factor": 0.262416457,
      "Velocity stability factor": 0.5373136915,
      "Direction stability factor": 0.0218172006,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.012403398, 0.1090515738, 0.2999629665],
      direction: [-89.4155743415, 14.534985142, 139.6832649154],
      "speed factor": 0.1689281578,
      "Uz factor": 0.9722099008,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7430498459,
      "Direction stability factor": 0.3636143354,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0187939919, 0.0827418688, 0.2302560211],
      direction: [-173.4214350696, -104.1251161175, 172.5443050792],
      "speed factor": 0.0930788219,
      "Uz factor": 0.4556645851,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8249477082,
      "Direction stability factor": 0.0389840551,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0117342887, 0.1505117703, 0.5143801968],
      direction: [-175.2743445501, 45.9926652137, 173.9996563272],
      "speed factor": 0.2138231455,
      "Uz factor": 0.9640704818,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5889404766,
      "Direction stability factor": 0.029794442,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0169383558, 0.2119503328, 0.7290107912],
      direction: [-163.3294047316, -41.1935883713, 142.0120918202],
      "speed factor": 0.248332758,
      "Uz factor": 0.9728766467,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4524860131,
      "Direction stability factor": 0.1518291762,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0557583082, 0.265071184, 0.4835316111],
      direction: [-88.3724927396, -6.8873557686, 86.6827483678],
      "speed factor": 0.491738989,
      "Uz factor": 0.8287324447,
      "Directional variance factor": 0.3877905983,
      "Velocity stability factor": 0.5849110952,
      "Direction stability factor": 0.5137354414,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0230085626, 0.2052360672, 0.6080748887],
      direction: [-174.7998395578, 29.3869610749, 169.5850382222],
      "speed factor": 0.2794803534,
      "Uz factor": 0.9014172626,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5709476281,
      "Direction stability factor": 0.0433753395,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0523264235, 0.4083272103, 0.8611616264],
      direction: [-64.5987366455, -10.0516052265, 35.0813640855],
      "speed factor": 0.5185502145,
      "Uz factor": 0.9263952712,
      "Directional variance factor": 0.1065239799,
      "Velocity stability factor": 0.3485362452,
      "Direction stability factor": 0.7231108313,
      score: 0.0,
    },
    id: "IDN:9.0",
    "45": {
      velocity: [0.0383406326, 0.1593173813, 0.4209928676],
      direction: [-147.4239232383, 53.4319656589, 151.307237697],
      "speed factor": 0.2164137898,
      "Uz factor": 0.9999331347,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6889158413,
      "Direction stability factor": 0.1701912196,
      score: 0.0,
    },
    "90": {
      velocity: [0.010874063, 0.08069294, 0.2360944115],
      direction: [-137.4552293146, 13.1109064024, 175.8834080878],
      "speed factor": 0.1308056171,
      "Uz factor": 0.9438735316,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8356212324,
      "Direction stability factor": 0.1296148961,
      score: 0.0,
    },
    "135": {
      velocity: [0.0201459037, 0.098518387, 0.2983266471],
      direction: [-179.246780778, -178.6069022362, 179.3669246544],
      "speed factor": 0.0833810688,
      "Uz factor": 0.9542704937,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7758389499,
      "Direction stability factor": 0.0038508182,
      score: 0.0,
    },
    "180": {
      velocity: [0.0387128113, 0.3511763776, 0.8789057079],
      direction: [-140.7260528681, -0.1756964853, 164.8407139683],
      "speed factor": 0.6858452961,
      "Uz factor": 0.9950130189,
      "Directional variance factor": 0.9843825346,
      "Velocity stability factor": 0.3379642262,
      "Direction stability factor": 0.1512034255,
      score: 0.0,
    },
    "225": {
      velocity: [0.0141201789, 0.2193625026, 0.5269514904],
      direction: [-132.873093638, -25.0626016768, 137.7301308478],
      "speed factor": 0.299453822,
      "Uz factor": 0.9478334326,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5390771611,
      "Direction stability factor": 0.2483243764,
      score: 0.0,
    },
    "270": {
      velocity: [0.2980094688, 0.6754816891, 1.0805553911],
      direction: [-39.7781469744, 33.5320025569, 66.3216485549],
      "speed factor": 0.6180875566,
      "Uz factor": 0.8854406229,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.382725269,
      "Direction stability factor": 0.7052783458,
      score: 0.0,
    },
    "315": {
      velocity: [0.1331571925, 0.41682109, 0.8195234537],
      direction: [-44.8327279533, 26.9431315591, 51.4179487081],
      "speed factor": 0.5135650641,
      "Uz factor": 0.9512664899,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4431950013,
      "Direction stability factor": 0.7326370093,
      score: 0.0,
    },
  },
  {
    position: [-5.248061244, 98.21002125, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0449772024, 0.2207900036, 0.9035882039],
      direction: [-179.4158688235, 52.1812067409, 176.9983999635],
      "speed factor": 0.2262547879,
      "Uz factor": 0.9088104731,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4034106367,
      "Direction stability factor": 0.0099603645,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0151394983, 0.2136010341, 0.8665340216],
      direction: [-161.3513052766, -11.100323201, 175.5491816307],
      "speed factor": 0.1896511572,
      "Uz factor": 0.9823205762,
      "Directional variance factor": 0.0133046044,
      "Velocity stability factor": 0.4494257665,
      "Direction stability factor": 0.0641653141,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0206578787, 0.1799655428, 0.4139125492],
      direction: [-97.2992166549, 48.6299420428, 164.0722301064],
      "speed factor": 0.2787786232,
      "Uz factor": 0.9495069437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6486055091,
      "Direction stability factor": 0.2739682034,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0279932073, 0.1898943608, 0.5810522373],
      direction: [-173.4671689235, -80.1159801049, 168.9092379605],
      "speed factor": 0.2136178897,
      "Uz factor": 0.8902965805,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5421672105,
      "Direction stability factor": 0.0489544253,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0591010609, 0.2021745405, 0.6881679636],
      direction: [-179.3624050335, 160.5306674466, 179.3480910636],
      "speed factor": 0.2872173791,
      "Uz factor": 0.6867089525,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4855544688,
      "Direction stability factor": 0.0035819553,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0265845825, 0.201013965, 0.7186525296],
      direction: [-171.5871322943, -35.4284437461, 176.5852138777],
      "speed factor": 0.2355191034,
      "Uz factor": 0.9251927221,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.467867506,
      "Direction stability factor": 0.032854594,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0674612968, 0.2573770338, 0.5480194047],
      direction: [-53.8893814764, -24.8700925224, 91.4001589515],
      "speed factor": 0.4774654133,
      "Uz factor": 0.8207029908,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5336914732,
      "Direction stability factor": 0.5964179433,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0223994336, 0.2031622332, 0.5902571026],
      direction: [-167.8288505484, 42.9700479352, 178.3664437341],
      "speed factor": 0.2766563086,
      "Uz factor": 0.8883506885,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5835674197,
      "Direction stability factor": 0.0383464048,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0934736111, 0.3853042852, 0.7663769033],
      direction: [-163.4699161836, -4.8816797562, 40.0448349303],
      "speed factor": 0.4893125286,
      "Uz factor": 0.766280092,
      "Directional variance factor": 0.5660729106,
      "Velocity stability factor": 0.458020492,
      "Direction stability factor": 0.4346812469,
      score: 0.0,
    },
    id: "IDN:8.0",
    "45": {
      velocity: [0.0110556043, 0.1268171392, 0.3757859312],
      direction: [-172.6737559106, 98.248524754, 177.4881914259],
      "speed factor": 0.1722660609,
      "Uz factor": 0.9763708169,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7034857854,
      "Direction stability factor": 0.0273279241,
      score: 0.0,
    },
    "90": {
      velocity: [0.0317744399, 0.1592514055, 0.3490181362],
      direction: [-103.0929593193, 28.6218170685, 115.9355115495],
      "speed factor": 0.2581511885,
      "Uz factor": 0.9840869088,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7684572988,
      "Direction stability factor": 0.3915875809,
      score: 0.0,
    },
    "135": {
      velocity: [0.0257905357, 0.1752653892, 0.3259444082],
      direction: [-179.794213095, -156.3043979117, 179.8792554625],
      "speed factor": 0.1483359191,
      "Uz factor": 0.9657317924,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.758132765,
      "Direction stability factor": 0.0009070318,
      score: 0.0,
    },
    "180": {
      velocity: [0.0216335722, 0.3733923763, 1.068895422],
      direction: [-173.8082437631, 8.3162220056, 171.9005704308],
      "speed factor": 0.7292330043,
      "Uz factor": 0.990939346,
      "Directional variance factor": 0.2607802662,
      "Velocity stability factor": 0.1748028197,
      "Direction stability factor": 0.0396977384,
      score: 0.0,
    },
    "225": {
      velocity: [0.0112059759, 0.2603374607, 0.5350601587],
      direction: [-141.7606570108, -26.8915692874, 154.6389107619],
      "speed factor": 0.3553891241,
      "Uz factor": 0.9661604721,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5291700181,
      "Direction stability factor": 0.1766678673,
      score: 0.0,
    },
    "270": {
      velocity: [0.2688245557, 0.6777158191, 0.990586466],
      direction: [-48.2865903213, 16.8022470397, 55.7706713688],
      "speed factor": 0.6201318576,
      "Uz factor": 0.8484024958,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4306718924,
      "Direction stability factor": 0.7109520509,
      score: 0.0,
    },
    "315": {
      velocity: [0.1043388871, 0.5169885038, 0.861856281],
      direction: [0.0932263453, 26.5608150064, 42.9736042606],
      "speed factor": 0.6369812864,
      "Uz factor": 0.9283140326,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3854746432,
      "Direction stability factor": 0.8808878391,
      score: 0.0,
    },
  },
  {
    position: [-5.23758929, 101.210003, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2935545883, 0.686572397, 1.0244990045],
      direction: [-19.697801561, 34.1894629259, 63.5241355743],
      "speed factor": 0.7035657842,
      "Uz factor": 0.8856900233,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4921173114,
      "Direction stability factor": 0.7688279524,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3145634522, 0.7236634268, 0.9969093527],
      direction: [-4.5581823473, 16.7143093184, 43.6948999202],
      "speed factor": 0.6425231363,
      "Uz factor": 0.9349184003,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5587450226,
      "Direction stability factor": 0.8659636604,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0474630735, 0.2280600053, 0.639238461],
      direction: [-124.5615689711, 8.0299005146, 126.230818957],
      "speed factor": 0.3532801519,
      "Uz factor": 0.9773334459,
      "Directional variance factor": 0.2862310654,
      "Velocity stability factor": 0.4712164238,
      "Direction stability factor": 0.303354478,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0595911108, 0.2620438195, 0.5349154948],
      direction: [-179.772193236, -7.4075648042, 111.6207564073],
      "speed factor": 0.2947809903,
      "Uz factor": 0.9999018458,
      "Directional variance factor": 0.3415497952,
      "Velocity stability factor": 0.6065174297,
      "Direction stability factor": 0.1905751399,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0470405714, 0.1928760119, 0.4747204102],
      direction: [-177.8832291109, -151.9592344158, 177.6650169031],
      "speed factor": 0.2740075109,
      "Uz factor": 0.4000181489,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6502470867,
      "Direction stability factor": 0.0123659833,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0310002057, 0.1955813546, 0.6370135013],
      direction: [-175.7994383764, -96.460435303, 170.4067184458],
      "speed factor": 0.2291539558,
      "Uz factor": 0.9345724052,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5340351077,
      "Direction stability factor": 0.038316231,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0851584897, 0.308548361, 0.6622575748],
      direction: [-53.8012489396, -22.9215921058, 13.9978593533],
      "speed factor": 0.5723943917,
      "Uz factor": 0.9657496984,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4400131435,
      "Direction stability factor": 0.8116691436,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.024037003, 0.3129661356, 0.7150844537],
      direction: [-149.4942231067, 15.4173273487, 150.5044680345],
      "speed factor": 0.426181847,
      "Uz factor": 0.8781762528,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4932274605,
      "Direction stability factor": 0.1666703024,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0568473217, 0.3636799155, 0.8222511693],
      direction: [-158.473451158, -27.8733173622, 149.1640943878],
      "speed factor": 0.4618509211,
      "Uz factor": 0.8178866881,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.383517356,
      "Direction stability factor": 0.1454512624,
      score: 0.0,
    },
    id: "IDN:7.0",
    "45": {
      velocity: [0.037692831, 0.3118706143, 0.7014916892],
      direction: [-35.6158112035, 20.6647441983, 149.1508774572],
      "speed factor": 0.4236392854,
      "Uz factor": 0.9519838275,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.460352533,
      "Direction stability factor": 0.4867591982,
      score: 0.0,
    },
    "90": {
      velocity: [0.0444820923, 0.2077311293, 0.3972698166],
      direction: [-163.160424576, 38.2803117053, 98.2095787535],
      "speed factor": 0.3367382394,
      "Uz factor": 0.9999723224,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7425152224,
      "Direction stability factor": 0.273972213,
      score: 0.0,
    },
    "135": {
      velocity: [0.0556358938, 0.2443134591, 0.5266122123],
      direction: [-179.5952371505, -111.0433118038, 169.8599069347],
      "speed factor": 0.2067747755,
      "Uz factor": 0.9881179854,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6204821915,
      "Direction stability factor": 0.0292912664,
      score: 0.0,
    },
    "180": {
      velocity: [0.0415621267, 0.2311059121, 0.5843092455],
      direction: [-174.213449053, 0.2272915927, 168.7381303265],
      "speed factor": 0.4513484187,
      "Uz factor": 0.9472514536,
      "Directional variance factor": 0.9797963029,
      "Velocity stability factor": 0.5723386734,
      "Direction stability factor": 0.0473567239,
      score: 0.0,
    },
    "225": {
      velocity: [0.0309694641, 0.314831138, 0.7291420061],
      direction: [-178.4312199131, -23.0905445668, 177.9382344636],
      "speed factor": 0.4297789571,
      "Uz factor": 0.9939541416,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3724960569,
      "Direction stability factor": 0.010084849,
      score: 0.0,
    },
    "270": {
      velocity: [0.3039296948, 0.8029286005, 1.1165163761],
      direction: [-63.752324325, -14.4360604856, 14.8535472739],
      "speed factor": 0.7347055957,
      "Uz factor": 0.9677999573,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.359029022,
      "Direction stability factor": 0.7816503567,
      score: 0.0,
    },
    "315": {
      velocity: [0.5094910083, 0.6965709194, 0.953971336],
      direction: [-37.6501934403, -3.6843128849, 40.406064424],
      "speed factor": 0.8582446941,
      "Uz factor": 0.7249077103,
      "Directional variance factor": 0.6725055213,
      "Velocity stability factor": 0.6394215708,
      "Direction stability factor": 0.7831770615,
      score: 0.0,
    },
  },
  {
    position: [-2.410495206, 101.4859026, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.3238205675, 0.7506896261, 1.007518439],
      direction: [-35.6897522137, 5.322286785, 42.6219969118],
      "speed factor": 0.769269982,
      "Uz factor": 0.8343990057,
      "Directional variance factor": 0.5269078413,
      "Velocity stability factor": 0.5249456656,
      "Direction stability factor": 0.7824673635,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2157502474, 0.4547316264, 0.6619762831],
      direction: [-87.6345606349, 19.3998522882, 51.9622368123],
      "speed factor": 0.4037451389,
      "Uz factor": 0.6190053223,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7114374702,
      "Direction stability factor": 0.6122311182,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0188164953, 0.225557196, 0.7008799479],
      direction: [-176.8337029729, 14.7906060961, 174.1584568345],
      "speed factor": 0.3494031335,
      "Uz factor": 0.9478204749,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3905391145,
      "Direction stability factor": 0.0250217783,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0338735183, 0.4240468383, 0.8696005395],
      direction: [-179.9778637754, 5.3756187587, 154.3213806043],
      "speed factor": 0.4770230689,
      "Uz factor": 0.999694931,
      "Directional variance factor": 0.5221672214,
      "Velocity stability factor": 0.3081692683,
      "Direction stability factor": 0.0713909878,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0269877315, 0.1449521168, 0.332152265],
      direction: [-172.793297849, 160.8380747935, 178.5334694635],
      "speed factor": 0.2059248755,
      "Uz factor": 0.9510338484,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7504390553,
      "Direction stability factor": 0.024092313,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0205634122, 0.1510212788, 0.373605432],
      direction: [-177.9225263481, -141.5677248976, 173.4230271587],
      "speed factor": 0.1769449012,
      "Uz factor": 0.992901974,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7285452516,
      "Direction stability factor": 0.0240401291,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0126811463, 0.1239331983, 0.5727143468],
      direction: [-151.784726804, -37.4811561002, 101.6797564784],
      "speed factor": 0.2299110176,
      "Uz factor": 0.9956401004,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4565729879,
      "Direction stability factor": 0.2959319909,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0242250745, 0.2389149637, 0.6939963582],
      direction: [-139.4936186548, -1.8491958517, 151.9342523206],
      "speed factor": 0.3253426135,
      "Uz factor": 0.8792555242,
      "Directional variance factor": 0.8356270354,
      "Velocity stability factor": 0.5088301187,
      "Direction stability factor": 0.1904781362,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0719034821, 0.3453319344, 0.6691879402],
      direction: [-156.1874869301, 18.1399411825, 152.8581567837],
      "speed factor": 0.4385501239,
      "Uz factor": 0.4915947928,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5189265077,
      "Direction stability factor": 0.1415398786,
      score: 0.0,
    },
    id: "IDN:6.0",
    "45": {
      velocity: [0.0245475944, 0.2576168859, 0.7721071625],
      direction: [-172.4198148623, 23.4113172761, 161.8390259701],
      "speed factor": 0.3499420223,
      "Uz factor": 0.9388208954,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3922577263,
      "Direction stability factor": 0.0715032199,
      score: 0.0,
    },
    "90": {
      velocity: [0.0195909346, 0.1434796527, 0.3123284679],
      direction: [-173.5004982872, 47.4505520235, 178.816028373],
      "speed factor": 0.2325847156,
      "Uz factor": 0.9218690567,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7863433065,
      "Direction stability factor": 0.0213429815,
      score: 0.0,
    },
    "135": {
      velocity: [0.0356941552, 0.2000052506, 0.4928595058],
      direction: [-178.976080292, -131.8693884914, 178.6306157544],
      "speed factor": 0.1692745088,
      "Uz factor": 0.9793152645,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6316112188,
      "Direction stability factor": 0.0066480665,
      score: 0.0,
    },
    "180": {
      velocity: [0.0167585714, 0.1330064263, 0.3431618643],
      direction: [-174.4770980104, -130.5345854382, 175.9192042454],
      "speed factor": 0.259760729,
      "Uz factor": 0.5831572,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7428082795,
      "Direction stability factor": 0.0266769382,
      score: 0.0,
    },
    "225": {
      velocity: [0.0059213339, 0.1584686929, 0.4927146298],
      direction: [-170.5617842983, -13.460094224, 177.6048716751],
      "speed factor": 0.216327108,
      "Uz factor": 0.9943981695,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5624796246,
      "Direction stability factor": 0.0328704001,
      score: 0.0,
    },
    "270": {
      velocity: [0.0300301808, 0.5139506458, 0.8893428262],
      direction: [-132.548951959, -4.8979881292, 4.2500163033],
      "speed factor": 0.4702814362,
      "Uz factor": 0.9952040499,
      "Directional variance factor": 0.5646232774,
      "Velocity stability factor": 0.3221714319,
      "Direction stability factor": 0.6200028659,
      score: 0.0,
    },
    "315": {
      velocity: [0.421653048, 0.5758994173, 0.8260792662],
      direction: [-39.6635553397, -24.6348213974, 19.5970586907],
      "speed factor": 0.7095653945,
      "Uz factor": 0.8725356602,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6719149052,
      "Direction stability factor": 0.8353871832,
      score: 0.0,
    },
  },
  {
    position: [0.575319964, 101.7772919, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.3494366353, 0.6141977498, 0.8258034252],
      direction: [-49.9834074627, -15.827449261, 24.8145565653],
      "speed factor": 0.6293997886,
      "Uz factor": 0.7886012528,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6690056854,
      "Direction stability factor": 0.7922278777,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2660275058, 0.558632945, 0.7995742587],
      direction: [-76.4061441184, 3.8344504321, 46.5661669933],
      "speed factor": 0.4959965899,
      "Uz factor": 0.6119132738,
      "Directional variance factor": 0.6591599616,
      "Velocity stability factor": 0.6549694807,
      "Direction stability factor": 0.6584102469,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0359605387, 0.2266366445, 0.6763955072],
      direction: [-104.5078185087, 10.8949048246, 157.9234710088],
      "speed factor": 0.3510752711,
      "Uz factor": 0.9419725834,
      "Directional variance factor": 0.0315640156,
      "Velocity stability factor": 0.4277364349,
      "Direction stability factor": 0.2710241958,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0446811325, 0.2736864897, 0.6871007825],
      direction: [-178.3682704883, 13.739473662, 173.1870716177],
      "speed factor": 0.307878181,
      "Uz factor": 0.9961897606,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4681927887,
      "Direction stability factor": 0.023457383,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0215847026, 0.1622278191, 0.3856637602],
      direction: [-179.8355184898, 148.7440116063, 178.9498151963],
      "speed factor": 0.2304674412,
      "Uz factor": 0.992900208,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7022592615,
      "Direction stability factor": 0.0033740731,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0372373058, 0.190586679, 0.4617768789],
      direction: [-179.4707761855, -154.2728338242, 179.2213852885],
      "speed factor": 0.2233019171,
      "Uz factor": 0.9696908206,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.673570633,
      "Direction stability factor": 0.0036328848,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0150990622, 0.1002054991, 0.5093228811],
      direction: [-177.6284629412, -7.149505778, 162.9318672959],
      "speed factor": 0.1858932762,
      "Uz factor": 0.9474458792,
      "Directional variance factor": 0.3644883753,
      "Velocity stability factor": 0.5204309799,
      "Direction stability factor": 0.0539990827,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0092995788, 0.1937522562, 0.6864694442],
      direction: [-173.0849000774, -2.04488294, 167.9532506583],
      "speed factor": 0.263842266,
      "Uz factor": 0.8759465441,
      "Directional variance factor": 0.8182326276,
      "Velocity stability factor": 0.5034044449,
      "Direction stability factor": 0.0526718035,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.034532881, 0.2830074807, 0.5630420028],
      direction: [-123.3707552586, 20.4735073661, 161.324876673],
      "speed factor": 0.3594019358,
      "Uz factor": 0.5538297587,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5743205344,
      "Direction stability factor": 0.2091788002,
      score: 0.0,
    },
    id: "IDN:5.0",
    "45": {
      velocity: [0.0369631789, 0.2728768315, 0.720445826],
      direction: [-113.2363588431, 19.8894925535, 152.7057730024],
      "speed factor": 0.3706708507,
      "Uz factor": 0.8993917329,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4443502354,
      "Direction stability factor": 0.261271856,
      score: 0.0,
    },
    "90": {
      velocity: [0.0098338973, 0.1289761743, 0.4533060274],
      direction: [-178.7520177381, 171.1580335597, 178.8494804842],
      "speed factor": 0.2090741528,
      "Uz factor": 0.4573827121,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6763285257,
      "Direction stability factor": 0.0066625049,
      score: 0.0,
    },
    "135": {
      velocity: [0.0289191148, 0.1443390619, 0.354334262],
      direction: [-175.7909491568, -139.1766807852, 176.949289284],
      "speed factor": 0.1221614119,
      "Uz factor": 0.9675484571,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7377769567,
      "Direction stability factor": 0.0201660043,
      score: 0.0,
    },
    "180": {
      velocity: [0.0199005089, 0.1256273365, 0.3280584692],
      direction: [-179.9551824551, -177.1266623683, 172.7904533698],
      "speed factor": 0.2453494121,
      "Uz factor": 0.6656226638,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7571848149,
      "Direction stability factor": 0.0201510116,
      score: 0.0,
    },
    "225": {
      velocity: [0.0148313389, 0.1270880633, 0.4279212639],
      direction: [-173.6350223663, 7.26049771, 177.438377604],
      "speed factor": 0.1734891143,
      "Uz factor": 0.9827416939,
      "Directional variance factor": 0.3546224258,
      "Velocity stability factor": 0.6287227853,
      "Direction stability factor": 0.0247961112,
      score: 0.0,
    },
    "270": {
      velocity: [0.0558124286, 0.6263851946, 1.0675838552],
      direction: [-160.4382334896, -1.9654742017, 159.0375025866],
      "speed factor": 0.5731626788,
      "Uz factor": 0.9941277339,
      "Directional variance factor": 0.8252911821,
      "Velocity stability factor": 0.2019114567,
      "Direction stability factor": 0.1125673998,
      score: 0.0,
    },
    "315": {
      velocity: [0.3733715517, 0.5238361785, 0.7941915072],
      direction: [-43.1760438461, -33.0864016077, 3.3161878606],
      "speed factor": 0.645418303,
      "Uz factor": 0.8906019621,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6586157158,
      "Direction stability factor": 0.8708549119,
      score: 0.0,
    },
  },
  {
    position: [3.561135135, 102.0686813, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2286944107, 0.5745092387, 0.7792907529],
      direction: [-71.7121884453, -31.5763351553, 26.776421041],
      "speed factor": 0.5887289452,
      "Uz factor": 0.382360884,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6174287067,
      "Direction stability factor": 0.7264205292,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3184244125, 0.5207069011, 0.6995221751],
      direction: [-86.6616120685, -10.5324332707, 46.5221209375],
      "speed factor": 0.4623229789,
      "Uz factor": 0.7807307556,
      "Directional variance factor": 0.0637837093,
      "Velocity stability factor": 0.7535541951,
      "Direction stability factor": 0.6300451861,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0124033189, 0.2004689469, 0.6556100735],
      direction: [-146.97162968, 8.5486178875, 176.9286597401],
      "speed factor": 0.310539763,
      "Uz factor": 0.9614217501,
      "Directional variance factor": 0.2401228544,
      "Velocity stability factor": 0.4252596929,
      "Direction stability factor": 0.1002769738,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0093360998, 0.1405711729, 0.5654810685],
      direction: [-170.723428612, 37.1284920061, 177.8702151364],
      "speed factor": 0.1581327491,
      "Uz factor": 0.9729671354,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5396126116,
      "Direction stability factor": 0.0316843229,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0243473437, 0.1649166785, 0.348983172],
      direction: [-163.5865574948, 138.6269098886, 179.9333026354],
      "speed factor": 0.2342873442,
      "Uz factor": 0.9933052492,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.734515597,
      "Direction stability factor": 0.0457781663,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0172825305, 0.1713165005, 0.3766155956],
      direction: [-177.7960631161, -154.6545734002, 179.6361314695],
      "speed factor": 0.2007239078,
      "Uz factor": 0.9514415015,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7237080536,
      "Direction stability factor": 0.0071327928,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0168603097, 0.1188850615, 0.4915938678],
      direction: [-141.7508202466, 6.2016807599, 137.0177200105],
      "speed factor": 0.2205461155,
      "Uz factor": 0.9747593081,
      "Directional variance factor": 0.448739488,
      "Velocity stability factor": 0.5393433125,
      "Direction stability factor": 0.2256429437,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0152305876, 0.177989199, 0.6959165275],
      direction: [-179.7065721032, -1.2142160759, 177.4866126964],
      "speed factor": 0.2423769121,
      "Uz factor": 0.8968007014,
      "Directional variance factor": 0.8920696821,
      "Velocity stability factor": 0.5008259679,
      "Direction stability factor": 0.0077967089,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0733638804, 0.329188879, 0.7425333678],
      direction: [-105.2258901719, 13.3470280677, 162.2162494865],
      "speed factor": 0.4180494455,
      "Uz factor": 0.8212817131,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.461027827,
      "Direction stability factor": 0.2571051676,
      score: 0.0,
    },
    id: "IDN:4.0",
    "45": {
      velocity: [0.041611474, 0.310067509, 0.6647063927],
      direction: [-113.566716568, 11.5304035075, 129.8132271599],
      "speed factor": 0.4211899805,
      "Uz factor": 0.9105555693,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4934435478,
      "Direction stability factor": 0.3239446008,
      score: 0.0,
    },
    "90": {
      velocity: [0.0223786197, 0.1089279268, 0.2799530179],
      direction: [-177.0663655155, 176.6645997362, 179.7282933691],
      "speed factor": 0.1765753569,
      "Uz factor": 0.6817238205,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8120073856,
      "Direction stability factor": 0.0089037253,
      score: 0.0,
    },
    "135": {
      velocity: [0.0227674126, 0.1539038559, 0.4250943825],
      direction: [-170.3210618021, -49.3076673999, 175.4507174741],
      "speed factor": 0.1302565784,
      "Uz factor": 0.9958713903,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6758005787,
      "Direction stability factor": 0.0395228353,
      score: 0.0,
    },
    "180": {
      velocity: [0.0191433127, 0.1257270227, 0.3605093677],
      direction: [-170.534483623, 167.6811119454, 173.4263183352],
      "speed factor": 0.2455440986,
      "Uz factor": 0.7121570652,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7310182682,
      "Direction stability factor": 0.0445533279,
      score: 0.0,
    },
    "225": {
      velocity: [0.0104465115, 0.1151934269, 0.345017838],
      direction: [-176.1341187115, 14.1617470884, 173.877226143],
      "speed factor": 0.1572516339,
      "Uz factor": 0.9922478464,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.699293779,
      "Direction stability factor": 0.0277462643,
      score: 0.0,
    },
    "270": {
      velocity: [0.0763405482, 0.6524437736, 1.0911715352],
      direction: [-135.8243658239, -1.1654393601, 6.1537625448],
      "speed factor": 0.59700712,
      "Uz factor": 0.9918630823,
      "Directional variance factor": 0.8964053902,
      "Velocity stability factor": 0.1994980657,
      "Direction stability factor": 0.6056163101,
      score: 0.0,
    },
    "315": {
      velocity: [0.2839900773, 0.502252422, 0.7842104942],
      direction: [-44.1666283831, -33.1534823071, -8.2624326763],
      "speed factor": 0.6188249671,
      "Uz factor": 0.8983495411,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5942032056,
      "Direction stability factor": 0.900266123,
      score: 0.0,
    },
  },
  {
    position: [6.546950306, 102.3600707, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2416754003, 0.6905182354, 0.9769015687],
      direction: [-71.3269221494, -41.7078377229, 30.1108603031],
      "speed factor": 0.7076092863,
      "Uz factor": 0.5298733318,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4891422181,
      "Direction stability factor": 0.7182283821,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3998124135, 0.6227320836, 0.8632929716],
      direction: [-82.8746184318, -15.2881903811, 40.6089824995],
      "speed factor": 0.5529086543,
      "Uz factor": 0.6303550561,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7002794286,
      "Direction stability factor": 0.6569899974,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0177406993, 0.2499176005, 0.7506458588],
      direction: [-145.510325342, 4.1968627777, 157.6679108096],
      "speed factor": 0.3871390239,
      "Uz factor": 0.9674900043,
      "Directional variance factor": 0.6269455309,
      "Velocity stability factor": 0.3451092771,
      "Direction stability factor": 0.1578382329,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0321485155, 0.1162324385, 0.514850736],
      direction: [-166.9614176391, 73.8038141871, 162.485157324],
      "speed factor": 0.1307533732,
      "Uz factor": 0.9153126936,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6004099163,
      "Direction stability factor": 0.0848706251,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0215911838, 0.1230229164, 0.2773996608],
      direction: [-179.739520169, 131.229765764, 178.7598960637],
      "speed factor": 0.174771361,
      "Uz factor": 0.983411811,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7908020161,
      "Direction stability factor": 0.0041682882,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0262185623, 0.1497619332, 0.3592792324],
      direction: [-178.7566890058, -157.5853676309, 178.0290614684],
      "speed factor": 0.175469382,
      "Uz factor": 0.9417215424,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7439089531,
      "Direction stability factor": 0.0089284709,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0453589157, 0.198738854, 0.4037049318],
      direction: [-66.326217308, 6.5680214344, 89.2111213979],
      "speed factor": 0.3686845234,
      "Uz factor": 0.9960719476,
      "Directional variance factor": 0.4161758725,
      "Velocity stability factor": 0.6522797137,
      "Direction stability factor": 0.5679518369,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0124859672, 0.1693230546, 0.5715027574],
      direction: [-179.1834210903, 2.8299432019, 172.5412509352],
      "speed factor": 0.2305757842,
      "Uz factor": 0.8853939652,
      "Directional variance factor": 0.7484494932,
      "Velocity stability factor": 0.5900507872,
      "Direction stability factor": 0.0229870222,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0624125524, 0.3474048691, 0.7421621785],
      direction: [-108.274500595, 12.5566609824, 137.6358954786],
      "speed factor": 0.4411826223,
      "Uz factor": 0.7077255843,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4525062186,
      "Direction stability factor": 0.3169155665,
      score: 0.0,
    },
    id: "IDN:3.0",
    "45": {
      velocity: [0.1038064719, 0.3519841647, 0.6673132009],
      direction: [-96.3792148669, 9.1152089012, 162.2883218273],
      "speed factor": 0.4781287918,
      "Uz factor": 0.914053884,
      "Directional variance factor": 0.1897592088,
      "Velocity stability factor": 0.5418868605,
      "Direction stability factor": 0.2814790647,
      score: 0.0,
    },
    "90": {
      velocity: [0.0135321813, 0.0981441238, 0.3133809651],
      direction: [-174.2962892141, 138.5151178244, 177.9058708046],
      "speed factor": 0.1590944967,
      "Uz factor": 0.7283332051,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7811531068,
      "Direction stability factor": 0.0216606666,
      score: 0.0,
    },
    "135": {
      velocity: [0.0225288742, 0.1950589565, 0.3929767147],
      direction: [-33.4314651779, 3.5041214813, 156.6832628956],
      "speed factor": 0.1650882112,
      "Uz factor": 0.993261636,
      "Directional variance factor": 0.688522535,
      "Velocity stability factor": 0.7014891257,
      "Direction stability factor": 0.4719035331,
      score: 0.0,
    },
    "180": {
      velocity: [0.0212730521, 0.1520449993, 0.3690834],
      direction: [-177.56246597, 173.8054909192, 174.8838956417],
      "speed factor": 0.2969429444,
      "Uz factor": 0.8040975576,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7259404433,
      "Direction stability factor": 0.0209823289,
      score: 0.0,
    },
    "225": {
      velocity: [0.0097785917, 0.1004455894, 0.2214593977],
      direction: [-175.2878249592, 0.3528584351, 178.1739636354],
      "speed factor": 0.1371192219,
      "Uz factor": 0.9742293007,
      "Directional variance factor": 0.9686348058,
      "Velocity stability factor": 0.8097453961,
      "Direction stability factor": 0.0181616983,
      score: 0.0,
    },
    "270": {
      velocity: [0.0655980652, 0.6872145467, 1.0655836846],
      direction: [-32.1634468743, 0.7867004937, 164.2461745422],
      "speed factor": 0.6288235001,
      "Uz factor": 0.9873061666,
      "Directional variance factor": 0.9300710672,
      "Velocity stability factor": 0.2112081392,
      "Direction stability factor": 0.4544177183,
      score: 0.0,
    },
    "315": {
      velocity: [0.3433633057, 0.5258232385, 0.7944080128],
      direction: [-45.8666195604, -31.5118682662, -0.6681801541],
      "speed factor": 0.6478665588,
      "Uz factor": 0.9411882071,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6340963101,
      "Direction stability factor": 0.8744487794,
      score: 0.0,
    },
  },
  {
    position: [12.51858065, 102.9428494, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2824855016, 0.6840589756, 1.0415078542],
      direction: [-71.7875060338, -51.9986008095, -24.0389987444],
      "speed factor": 0.700990153,
      "Uz factor": 0.8734706089,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4726078965,
      "Direction stability factor": 0.8673652575,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4021590674, 0.6816099893, 1.0149535515],
      direction: [-93.847863741, -42.271482469, -7.4877605226],
      "speed factor": 0.6051849131,
      "Uz factor": 0.8706029373,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6037220769,
      "Direction stability factor": 0.7601108244,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1006457564, 0.3439773119, 0.6635879659],
      direction: [-84.5734969348, 14.9507803376, 158.6601373943],
      "speed factor": 0.5328437874,
      "Uz factor": 0.9264073486,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4969804404,
      "Direction stability factor": 0.3243510158,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2517452972, 0.7814245929, 0.9974837343],
      direction: [-3.888092274, 34.6275882157, 49.5222921293],
      "speed factor": 0.879048076,
      "Uz factor": 0.9117174323,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3826635307,
      "Direction stability factor": 0.8516378211,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0625126699, 0.5263538116, 0.9607075327],
      direction: [-80.9459862511, 17.8351927594, 159.4492116882],
      "speed factor": 0.747759643,
      "Uz factor": 0.9977631275,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2654639255,
      "Direction stability factor": 0.3322355613,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0211777517, 0.2000815399, 0.4698160012],
      direction: [-176.4714742441, -160.2109243494, 178.5670449378],
      "speed factor": 0.2344266224,
      "Uz factor": 0.5606864771,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6550411103,
      "Direction stability factor": 0.0137818912,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0151712861, 0.1500217657, 0.3261293808],
      direction: [-83.4001057865, 9.4500538232, 130.3752927252],
      "speed factor": 0.278308454,
      "Uz factor": 0.9922180861,
      "Directional variance factor": 0.1599952157,
      "Velocity stability factor": 0.6982624813,
      "Direction stability factor": 0.4061794486,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0135680133, 0.1817549893, 0.4593193945],
      direction: [-174.7601796235, 6.6471419542, 179.3228532109],
      "speed factor": 0.2475049797,
      "Uz factor": 0.8035780475,
      "Directional variance factor": 0.4091429374,
      "Velocity stability factor": 0.6731128098,
      "Direction stability factor": 0.0164360199,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1014278475, 0.5491258975, 1.0415594677],
      direction: [-64.6887456604, -14.0770925621, 18.2421388127],
      "speed factor": 0.6973558088,
      "Uz factor": 0.9983040925,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2427855846,
      "Direction stability factor": 0.769636432,
      score: 0.0,
    },
    id: "IDN:20.0",
    "45": {
      velocity: [0.1181865251, 0.3687084685, 0.6692432871],
      direction: [-94.0690553941, -19.4004283644, 66.5675480038],
      "speed factor": 0.5008467774,
      "Uz factor": 0.8956382246,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5520082897,
      "Direction stability factor": 0.5537872128,
      score: 0.0,
    },
    "90": {
      velocity: [0.1762604209, 0.6062350419, 0.846051297],
      direction: [-0.2282836454, 44.8174297456, 68.5360199927],
      "speed factor": 0.9827247433,
      "Uz factor": 0.9633712663,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5111480844,
      "Direction stability factor": 0.8089880454,
      score: 0.0,
    },
    "135": {
      velocity: [0.5126336646, 1.0112195565, 1.2011455043],
      direction: [12.7577480727, 23.1997062047, 32.5376088192],
      "speed factor": 0.8558460001,
      "Uz factor": 0.9910811171,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4451897171,
      "Direction stability factor": 0.9450559424,
      score: 0.0,
    },
    "180": {
      velocity: [0.0068690274, 0.1890217449, 0.7028389482],
      direction: [-174.1187283138, 27.960349075, 178.2201372081],
      "speed factor": 0.3691583,
      "Uz factor": 0.8589034499,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4516057123,
      "Direction stability factor": 0.0212809291,
      score: 0.0,
    },
    "225": {
      velocity: [0.0228877421, 0.1336575786, 0.3270093095],
      direction: [-179.8663683968, 12.7836586266, 160.0641491575],
      "speed factor": 0.1824572216,
      "Uz factor": 0.9827530882,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7266614322,
      "Direction stability factor": 0.0557485623,
      score: 0.0,
    },
    "270": {
      velocity: [0.1032390882, 0.5716402018, 0.9206950994],
      direction: [-92.6757991153, -0.210140881, 15.8116584175],
      "speed factor": 0.5230692427,
      "Uz factor": 0.9964218329,
      "Directional variance factor": 0.9813208106,
      "Velocity stability factor": 0.355188079,
      "Direction stability factor": 0.6986459513,
      score: 0.0,
    },
    "315": {
      velocity: [0.3014765567, 0.6574899316, 1.0342407786],
      direction: [-44.5158634416, -28.8371772292, -12.0720872031],
      "speed factor": 0.8100930279,
      "Uz factor": 0.9993588371,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4055553066,
      "Direction stability factor": 0.9098783993,
      score: 0.0,
    },
  },
  {
    position: [-35.4946478, 93.51136883, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0241129074, 0.289604545, 0.9731257607],
      direction: [-173.5116865566, -24.9026178094, 153.0626524234],
      "speed factor": 0.2967725614,
      "Uz factor": 0.960966692,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3405966462,
      "Direction stability factor": 0.0928490584,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3676295644, 0.9597147237, 1.1700836953],
      direction: [-31.9567601762, -11.516789456, -2.1812737206],
      "speed factor": 0.8521073353,
      "Uz factor": 0.9714474802,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4810742188,
      "Direction stability factor": 0.9172903154,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1270071615, 0.3198461203, 0.62120683],
      direction: [-54.9774286443, -27.3936231096, 83.3569522518],
      "speed factor": 0.4954629629,
      "Uz factor": 0.8379142332,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5584056492,
      "Direction stability factor": 0.6157378308,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0526027373, 0.4472495219, 0.8273891675],
      direction: [-178.7620928637, 47.3872525867, 175.7940075074],
      "speed factor": 0.5031244669,
      "Uz factor": 0.9517268868,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3586170492,
      "Direction stability factor": 0.0151219434,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0096732536, 0.3392607413, 0.8051678695],
      direction: [-87.0009209041, 17.3635722251, 164.0346491816],
      "speed factor": 0.4819676141,
      "Uz factor": 0.9915069273,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3494513088,
      "Direction stability factor": 0.302678972,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.061038317, 0.2363783473, 0.4374901567],
      direction: [-37.9249799627, 33.9884567784, 121.0528696934],
      "speed factor": 0.2769539738,
      "Uz factor": 0.6201512803,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.710545392,
      "Direction stability factor": 0.5583948621,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0064510059, 0.0993054201, 0.3417166102],
      direction: [-123.9196266071, 25.5294542196, 166.6865339848],
      "speed factor": 0.1842235212,
      "Uz factor": 0.9405985722,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6746757417,
      "Direction stability factor": 0.192760665,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.040543728, 0.1856684249, 0.428985571],
      direction: [-117.7345763763, -17.8155090328, 86.693191887],
      "speed factor": 0.2528341033,
      "Uz factor": 0.9969957019,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7151401701,
      "Direction stability factor": 0.4321450882,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0398535195, 0.0978836264, 0.2116302808],
      direction: [-178.7944280906, -145.0447199885, 179.5724624483],
      "speed factor": 0.1243061304,
      "Uz factor": 0.9907943436,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8616450749,
      "Direction stability factor": 0.0045364152,
      score: 0.0,
    },
    id: "IDN:22.0",
    "45": {
      velocity: [0.0659218696, 0.3691289236, 0.6312689279],
      direction: [-176.7689151514, -19.2040460968, 62.7054014535],
      "speed factor": 0.5014179158,
      "Uz factor": 0.9645684935,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5403907309,
      "Direction stability factor": 0.334793565,
      score: 0.0,
    },
    "90": {
      velocity: [0.1426481881, 0.5669866574, 0.8942951163],
      direction: [-30.2597196427, 48.3809252201, 88.3148220143],
      "speed factor": 0.9191019634,
      "Uz factor": 0.9324904919,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4514048283,
      "Direction stability factor": 0.6706262732,
      score: 0.0,
    },
    "135": {
      velocity: [0.1501461279, 0.7476430552, 1.0238687462],
      direction: [-11.7167469032, 23.0969177592, 104.8327099484],
      "speed factor": 0.6327679426,
      "Uz factor": 0.9463628876,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2959448696,
      "Direction stability factor": 0.6762515087,
      score: 0.0,
    },
    "180": {
      velocity: [0.0213040702, 0.2426492158, 0.7430717246],
      direction: [-39.0508729842, 6.7633977545, 165.7645124813],
      "speed factor": 0.4738924195,
      "Uz factor": 0.9891486199,
      "Directional variance factor": 0.3988090885,
      "Velocity stability factor": 0.4312782105,
      "Direction stability factor": 0.4310683737,
      score: 0.0,
    },
    "225": {
      velocity: [0.0318106331, 0.1462976466, 0.3475641374],
      direction: [-118.1749764867, 91.3567231606, 148.0925935363],
      "speed factor": 0.1997122977,
      "Uz factor": 0.8674167613,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7162068728,
      "Direction stability factor": 0.260367861,
      score: 0.0,
    },
    "270": {
      velocity: [0.0183408595, 0.1196169243, 0.2735877208],
      direction: [-178.9672977409, -25.2334243233, 179.594575701],
      "speed factor": 0.1094533481,
      "Uz factor": 0.9976617078,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7986604579,
      "Direction stability factor": 0.003994796,
      score: 0.0,
    },
    "315": {
      velocity: [0.0322480088, 0.1127931848, 0.2288217251],
      direction: [-179.2608406589, -148.0164340471, 178.9978070634],
      "speed factor": 0.1389724287,
      "Uz factor": 0.8805334351,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8405323308,
      "Direction stability factor": 0.0048370897,
      score: 0.0,
    },
  },
  {
    position: [-53.00215104, 80.42343294, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0881381264, 0.7799253734, 1.3331696061],
      direction: [-14.5815095233, 1.3403224114, 86.9365956649],
      "speed factor": 0.7992293447,
      "Uz factor": 0.999815636,
      "Directional variance factor": 0.8808602301,
      "Velocity stability factor": 0.1349137893,
      "Direction stability factor": 0.7180052634,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0366434156, 0.2429629267, 0.5751520324],
      direction: [-178.7553829142, 12.9066262411, 172.2021389898],
      "speed factor": 0.2157208668,
      "Uz factor": 0.9807604363,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.651760775,
      "Direction stability factor": 0.0251179947,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.015450274, 0.0987402537, 0.3953534888],
      direction: [-100.7405600412, -29.7593505938, 149.2086525976],
      "speed factor": 0.1529552355,
      "Uz factor": 0.9916730968,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6605357628,
      "Direction stability factor": 0.3056966316,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0135688001, 0.1331364023, 0.4525236696],
      direction: [-102.3346066166, -5.7944755524, 124.0204019774],
      "speed factor": 0.1497691516,
      "Uz factor": 0.9998684046,
      "Directional variance factor": 0.4849355065,
      "Velocity stability factor": 0.6366248059,
      "Direction stability factor": 0.3712360872,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0203077008, 0.1858109938, 0.5005918449],
      direction: [-137.4916215579, -30.4392718881, 19.7870635495],
      "speed factor": 0.2639706587,
      "Uz factor": 0.9996917069,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6072277359,
      "Direction stability factor": 0.5631147636,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3277236352, 0.655112153, 0.9264352427],
      direction: [-49.0931280536, -24.1166036335, 1.2802597459],
      "speed factor": 0.7675657104,
      "Uz factor": 0.9968355443,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.539649391,
      "Direction stability factor": 0.8600739228,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1137552055, 0.272124314, 0.4621476882],
      direction: [-45.9341943328, -7.498934448, 80.1951557609],
      "speed factor": 0.5048233951,
      "Uz factor": 0.8887693698,
      "Directional variance factor": 0.3334280491,
      "Velocity stability factor": 0.6619381035,
      "Direction stability factor": 0.6496406942,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.2100166397, 0.5809828772, 0.852594994],
      direction: [-55.1484168792, 10.7358990359, 40.670973757],
      "speed factor": 0.7911538263,
      "Uz factor": 0.8169616692,
      "Directional variance factor": 0.0456978635,
      "Velocity stability factor": 0.5287717737,
      "Direction stability factor": 0.733835026,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.337820529, 0.6443038532, 0.8718457064],
      direction: [12.7883652994, 19.2543097037, 29.0724333749],
      "speed factor": 0.8182259053,
      "Uz factor": 0.9920823872,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.569877713,
      "Direction stability factor": 0.9547664776,
      score: 0.0,
    },
    id: "IDN:40.0",
    "45": {
      velocity: [0.0192994045, 0.1491841, 0.581499246],
      direction: [-150.2098427134, -19.3467668687, 120.1089093591],
      "speed factor": 0.2026489276,
      "Uz factor": 0.9734425832,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5429493187,
      "Direction stability factor": 0.2491145776,
      score: 0.0,
    },
    "90": {
      velocity: [0.0132638596, 0.1295762354, 0.4662604538],
      direction: [-167.4063539475, -29.8324348077, 120.3643377533],
      "speed factor": 0.2100468694,
      "Uz factor": 0.8369910092,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6693770239,
      "Direction stability factor": 0.2006369675,
      score: 0.0,
    },
    "135": {
      velocity: [0.0092872729, 0.1990624261, 0.4107344727],
      direction: [-137.9583331094, -24.3649641951, 155.3428277048],
      "speed factor": 0.168476549,
      "Uz factor": 0.9964315332,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.676509507,
      "Direction stability factor": 0.1852745533,
      score: 0.0,
    },
    "180": {
      velocity: [0.0839584594, 0.3060706438, 0.535660048],
      direction: [-94.1758008474, -46.2919872917, 3.9579122118],
      "speed factor": 0.5977540766,
      "Uz factor": 0.96944921,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6440786253,
      "Direction stability factor": 0.7274063526,
      score: 0.0,
    },
    "225": {
      velocity: [0.3065639777, 0.519099077, 0.7326373246],
      direction: [-33.2563430571, -1.3380353709, 52.1297311086],
      "speed factor": 0.708627048,
      "Uz factor": 0.9159081384,
      "Directional variance factor": 0.8810635226,
      "Velocity stability factor": 0.6170535374,
      "Direction stability factor": 0.7628164607,
      score: 0.0,
    },
    "270": {
      velocity: [0.5018824934, 0.7342255278, 0.9647305934],
      direction: [-28.9336507919, 54.7354716946, 73.0425664046],
      "speed factor": 0.6718400658,
      "Uz factor": 0.6499667538,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6349039356,
      "Direction stability factor": 0.71673273,
      score: 0.0,
    },
    "315": {
      velocity: [0.2852559514, 0.6744128976, 0.9603544757],
      direction: [19.2756645599, 27.9572875371, 36.1351086749],
      "speed factor": 0.8309438061,
      "Uz factor": 0.9536998973,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4523357947,
      "Direction stability factor": 0.9531682108,
      score: 0.0,
    },
  },
  {
    position: [-35.48417584, 96.51135055, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0716047119, 0.3524164294, 0.7928846061],
      direction: [-92.3387403637, -39.9513076571, 19.9547144073],
      "speed factor": 0.3611391058,
      "Uz factor": 0.9975039341,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4988325189,
      "Direction stability factor": 0.6880737367,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5849725995, 0.8987203188, 1.0770755354],
      direction: [-28.3233761389, -14.5072327029, -3.3754552702],
      "speed factor": 0.7979518883,
      "Uz factor": 0.9401699764,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6817700968,
      "Direction stability factor": 0.9307002198,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1175773238, 0.3296731327, 0.5902360497],
      direction: [-48.644457314, -9.7363909165, 79.106791147],
      "speed factor": 0.5106856602,
      "Uz factor": 0.7800209574,
      "Directional variance factor": 0.1345430296,
      "Velocity stability factor": 0.577653656,
      "Direction stability factor": 0.6451354209,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0384307064, 0.4896064588, 0.8875661859],
      direction: [-28.1752825532, 52.1463091955, 158.7057327056],
      "speed factor": 0.5507730619,
      "Uz factor": 0.9895361741,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2970694913,
      "Direction stability factor": 0.4808860687,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0217443578, 0.3703157204, 0.8458551282],
      direction: [-174.8979225272, 14.5942885694, 167.0001897224],
      "speed factor": 0.5260855811,
      "Uz factor": 0.9904210708,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3260492625,
      "Direction stability factor": 0.0502830215,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0676493776, 0.2413787789, 0.500639378],
      direction: [-33.472626059, -12.7730353195, 126.1714517599],
      "speed factor": 0.2828127566,
      "Uz factor": 0.9660256717,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6670730818,
      "Direction stability factor": 0.5565442283,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0320154529, 0.1034641646, 0.2220805201],
      direction: [-157.5165030231, -12.8769453214, 104.3769371448],
      "speed factor": 0.1919384935,
      "Uz factor": 0.8797856407,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8155707707,
      "Direction stability factor": 0.2725182218,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0184452696, 0.1335939531, 0.3725478291],
      direction: [-167.2444510326, -34.2653550386, 152.4231563046],
      "speed factor": 0.181921656,
      "Uz factor": 0.9835500461,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7403225305,
      "Direction stability factor": 0.1120344241,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0291280538, 0.0872249597, 0.2985519957],
      direction: [-159.4871322706, -25.7575330804, 7.4434672454],
      "speed factor": 0.1107702853,
      "Uz factor": 0.9982245378,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7829966696,
      "Direction stability factor": 0.5363038902,
      score: 0.0,
    },
    id: "IDN:23.0",
    "45": {
      velocity: [0.1301755714, 0.3757337179, 0.5744538793],
      direction: [-67.6823364659, -30.3034030854, 39.8025439337],
      "speed factor": 0.5103897465,
      "Uz factor": 0.9537807588,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.638815794,
      "Direction stability factor": 0.7014308878,
      score: 0.0,
    },
    "90": {
      velocity: [0.209200065, 0.5858140965, 0.9973452226],
      direction: [-25.5440059548, 57.738526947, 83.8465789882],
      "speed factor": 0.9496217932,
      "Uz factor": 0.9549074676,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4247663207,
      "Direction stability factor": 0.696137264,
      score: 0.0,
    },
    "135": {
      velocity: [0.1479342207, 0.8113310877, 1.052269863],
      direction: [13.3732985159, 30.6578582796, 107.4112399692],
      "speed factor": 0.6866703296,
      "Uz factor": 0.9783216164,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.271276564,
      "Direction stability factor": 0.738783496,
      score: 0.0,
    },
    "180": {
      velocity: [0.0495925361, 0.2971562932, 0.7978927579],
      direction: [-170.5883378954, 6.4114706974, 170.7988263088],
      "speed factor": 0.580344405,
      "Uz factor": 0.9834299905,
      "Directional variance factor": 0.4300914936,
      "Velocity stability factor": 0.4103716916,
      "Direction stability factor": 0.0517023217,
      score: 0.0,
    },
    "225": {
      velocity: [0.032211833, 0.1353272249, 0.2987978492],
      direction: [-66.9713673978, 31.3899903272, 120.4083684388],
      "speed factor": 0.1847364716,
      "Uz factor": 0.9170676725,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7603976578,
      "Direction stability factor": 0.4795007338,
      score: 0.0,
    },
    "270": {
      velocity: [0.0346174226, 0.1663499662, 0.4211611845],
      direction: [-167.4898480121, -37.8893696183, 63.690337829],
      "speed factor": 0.1522155904,
      "Uz factor": 0.981238192,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.695093042,
      "Direction stability factor": 0.3578328171,
      score: 0.0,
    },
    "315": {
      velocity: [0.0150881024, 0.0682183, 0.1808287967],
      direction: [-149.2304566497, -7.3232829814, 166.7862509744],
      "speed factor": 0.0840517345,
      "Uz factor": 0.9232360335,
      "Directional variance factor": 0.3490415128,
      "Velocity stability factor": 0.8655451873,
      "Direction stability factor": 0.1221758122,
      score: 0.0,
    },
  },
  {
    position: [-52.79462529, 83.24889712, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1469952076, 0.6773672253, 1.2035621804],
      direction: [-35.2299817898, 2.831812986, 110.863138055],
      "speed factor": 0.6941327748,
      "Uz factor": 0.9962695744,
      "Directional variance factor": 0.7482832901,
      "Velocity stability factor": 0.2658647321,
      "Direction stability factor": 0.5941857782,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.031276322, 0.2416526125, 0.5508744122],
      direction: [-156.1296047804, 12.0540942393, 175.9618472197],
      "speed factor": 0.2145574706,
      "Uz factor": 0.9899513993,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6639897105,
      "Direction stability factor": 0.0775237444,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0300524843, 0.1299806044, 0.436493955],
      direction: [-169.0945213044, -20.6110175734, 169.554350065],
      "speed factor": 0.2013486213,
      "Uz factor": 0.9963901084,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6368223841,
      "Direction stability factor": 0.0593086906,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0182257632, 0.1342125024, 0.4166473997],
      direction: [-172.8633982351, -78.8981823091, 167.9895491082],
      "speed factor": 0.150979689,
      "Uz factor": 0.6289285828,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6701789875,
      "Direction stability factor": 0.0531862574,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0209841738, 0.1281435246, 0.4228625579],
      direction: [-178.0057093211, -84.2812989371, 175.5268882782],
      "speed factor": 0.1820459054,
      "Uz factor": 0.8723705163,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6713472957,
      "Direction stability factor": 0.0179650067,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2145975728, 0.666034644, 1.0005996411],
      direction: [-91.9629198873, -11.7573029128, 10.4588027208],
      "speed factor": 0.7803631063,
      "Uz factor": 0.9947290715,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3956413634,
      "Direction stability factor": 0.715495215,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0764392116, 0.2474709768, 0.4291851843],
      direction: [-49.6584482802, -14.9028104058, 80.5794298861],
      "speed factor": 0.4590884839,
      "Uz factor": 0.8355622835,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6577137038,
      "Direction stability factor": 0.6382281162,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1313542347, 0.4455651247, 0.6338344201],
      direction: [-87.7964817583, -30.5557145232, 42.1929110034],
      "speed factor": 0.6067486101,
      "Uz factor": 0.5515004058,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6315113248,
      "Direction stability factor": 0.6389183534,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.303733587, 0.6386017284, 0.8626341799],
      direction: [7.5170627886, 14.784395376, 41.8413800957],
      "speed factor": 0.8109845607,
      "Uz factor": 0.9851375679,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5498421959,
      "Direction stability factor": 0.9046546741,
      score: 0.0,
    },
    id: "IDN:39.0",
    "45": {
      velocity: [0.0212371041, 0.1407735553, 0.3943406079],
      direction: [-128.6946323823, -6.7467232334, 147.9274589702],
      "speed factor": 0.1912241988,
      "Uz factor": 0.9813415379,
      "Directional variance factor": 0.4002912681,
      "Velocity stability factor": 0.6966786576,
      "Direction stability factor": 0.2316053018,
      score: 0.0,
    },
    "90": {
      velocity: [0.0283302049, 0.1342307756, 0.316073475],
      direction: [-139.9576629917, -75.4370771802, 171.5330495879],
      "speed factor": 0.2175920152,
      "Uz factor": 0.9753080025,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7899884072,
      "Direction stability factor": 0.1347480206,
      score: 0.0,
    },
    "135": {
      velocity: [0.0226796695, 0.1016104931, 0.3615000467],
      direction: [-179.976900239, -80.8667816094, 167.0283183901],
      "speed factor": 0.0859980738,
      "Uz factor": 0.9461612068,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7269748776,
      "Direction stability factor": 0.0360966149,
      score: 0.0,
    },
    "180": {
      velocity: [0.015641016, 0.2929222205, 0.566889011],
      direction: [-136.0379761857, -80.6762037522, 141.1329978683],
      "speed factor": 0.572075287,
      "Uz factor": 0.9987538258,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5656403495,
      "Direction stability factor": 0.2300806276,
      score: 0.0,
    },
    "225": {
      velocity: [0.3438273848, 0.5443158389, 0.7523596992],
      direction: [-24.0319955379, -5.5410209332, 29.9160025251],
      "speed factor": 0.7430506875,
      "Uz factor": 0.9096754197,
      "Directional variance factor": 0.5074648059,
      "Velocity stability factor": 0.6328190773,
      "Direction stability factor": 0.8501444498,
      score: 0.0,
    },
    "270": {
      velocity: [0.5484955735, 0.7748431603, 0.9885847143],
      direction: [-38.8919366047, 7.2818232341, 38.5056296558],
      "speed factor": 0.7090065111,
      "Uz factor": 0.7288870669,
      "Directional variance factor": 0.3527268236,
      "Velocity stability factor": 0.6528562755,
      "Direction stability factor": 0.7850067604,
      score: 0.0,
    },
    "315": {
      velocity: [0.3076457103, 0.5560970694, 0.7949193207],
      direction: [-22.4362616424, 17.9969028919, 31.5405086832],
      "speed factor": 0.6851669312,
      "Uz factor": 0.8910466263,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6047061206,
      "Direction stability factor": 0.8500645269,
      score: 0.0,
    },
  },
  {
    position: [-52.57487212, 86.24083772, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0891366551, 0.5900857877, 1.174276485],
      direction: [-13.3217496049, 2.1887009514, 156.5679150646],
      "speed factor": 0.6046910301,
      "Uz factor": 0.9908162803,
      "Directional variance factor": 0.8054488043,
      "Velocity stability factor": 0.2460114311,
      "Direction stability factor": 0.5280842648,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0445366255, 0.2251031577, 0.6083683894],
      direction: [-165.2829576704, 27.9849819136, 179.6207113172],
      "speed factor": 0.1998636127,
      "Uz factor": 0.7956406508,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6353849681,
      "Direction stability factor": 0.0419342528,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0239017892, 0.1650063991, 0.4412709013],
      direction: [-102.284111851, -13.9077408306, 176.6519248497],
      "speed factor": 0.2556059124,
      "Uz factor": 0.9991558243,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6270579407,
      "Direction stability factor": 0.2251776758,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0322938593, 0.1811311239, 0.4575459912],
      direction: [-179.1308745486, 103.6586687542, 174.37730021],
      "speed factor": 0.2037598605,
      "Uz factor": 0.766478228,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6479681928,
      "Direction stability factor": 0.0180328479,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0091589512, 0.1641276312, 0.4016372694],
      direction: [-174.6511457793, 17.2097576351, 178.986462209],
      "speed factor": 0.2331663915,
      "Uz factor": 0.8072232137,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6790345891,
      "Direction stability factor": 0.0176733111,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2879600541, 0.7232291802, 1.0957369214],
      direction: [-35.2858652005, -8.6289498185, 22.0650519172],
      "speed factor": 0.8473753951,
      "Uz factor": 0.9924098871,
      "Directional variance factor": 0.2329822384,
      "Velocity stability factor": 0.3788986749,
      "Direction stability factor": 0.8406918969,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0544487656, 0.2292188688, 0.4343811048],
      direction: [-56.9975956206, -20.0346012912, 76.6544737289],
      "speed factor": 0.4252286239,
      "Uz factor": 0.8459526022,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6313334715,
      "Direction stability factor": 0.6287442518,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1280212556, 0.4483419646, 0.6683940901],
      direction: [-91.1857417386, -55.6559626563, 38.4880525997],
      "speed factor": 0.6105299738,
      "Uz factor": 0.5559705807,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6037231404,
      "Direction stability factor": 0.6397950157,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4275892058, 0.6396821436, 0.8426040498],
      direction: [8.9648394451, 15.5669667652, 22.4430528962],
      "speed factor": 0.812356621,
      "Uz factor": 0.9819754991,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6657327382,
      "Direction stability factor": 0.9625605182,
      score: 0.0,
    },
    id: "IDN:38.0",
    "45": {
      velocity: [0.0329399644, 0.1883372307, 0.4467035575],
      direction: [-90.3966489228, 23.3968548212, 164.6115102946],
      "speed factor": 0.2558338175,
      "Uz factor": 0.9764335393,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6636232916,
      "Direction stability factor": 0.2916440022,
      score: 0.0,
    },
    "90": {
      velocity: [0.0129693753, 0.1811459121, 0.5772716716],
      direction: [-175.2670375221, -123.1831875599, 179.2693410664],
      "speed factor": 0.2936428244,
      "Uz factor": 0.9079120755,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5881397189,
      "Direction stability factor": 0.0151767261,
      score: 0.0,
    },
    "135": {
      velocity: [0.0074328867, 0.0932680188, 0.2498866436],
      direction: [-175.1991121621, 100.2855516897, 178.6555652113],
      "speed factor": 0.078937418,
      "Uz factor": 0.3374381666,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8046281419,
      "Direction stability factor": 0.0170703406,
      score: 0.0,
    },
    "180": {
      velocity: [0.0341981962, 0.2787631547, 0.6844562403],
      direction: [-162.6895319224, -35.7633123019, 120.9968927928],
      "speed factor": 0.544422719,
      "Uz factor": 0.9997263882,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4876247,
      "Direction stability factor": 0.2119821536,
      score: 0.0,
    },
    "225": {
      velocity: [0.3337824584, 0.5035393924, 0.6979786529],
      direction: [-26.4877848033, -14.9205332473, 7.9390067217],
      "speed factor": 0.6873863757,
      "Uz factor": 0.9010521548,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6726675222,
      "Direction stability factor": 0.9043700235,
      score: 0.0,
    },
    "270": {
      velocity: [0.407654207, 0.565105473, 0.774528001],
      direction: [-57.7799840064, -6.1160267653, 55.9222173811],
      "speed factor": 0.5170897548,
      "Uz factor": 0.4371850874,
      "Directional variance factor": 0.4563531764,
      "Velocity stability factor": 0.7106087757,
      "Direction stability factor": 0.6841605517,
      score: 0.0,
    },
    "315": {
      velocity: [0.2820951682, 0.5931614357, 0.8042383827],
      direction: [-28.5528200595, 15.2851887388, 31.191325027],
      "speed factor": 0.730833919,
      "Uz factor": 0.8784515446,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5764186436,
      "Direction stability factor": 0.8340440414,
      score: 0.0,
    },
  },
  {
    position: [-52.35511895, 89.23277832, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0712628427, 0.4702725573, 1.1121792107],
      direction: [-70.5406251802, 5.6617698037, 73.7535646224],
      "speed factor": 0.4819122966,
      "Uz factor": 0.9998054515,
      "Directional variance factor": 0.496731573,
      "Velocity stability factor": 0.2767392542,
      "Direction stability factor": 0.5991828061,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0229988456, 0.215950229, 0.5609752768],
      direction: [-178.7616102058, 125.7985876608, 179.4699313861],
      "speed factor": 0.1917369502,
      "Uz factor": 0.8709684503,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6521049254,
      "Direction stability factor": 0.0049123845,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.030484149, 0.1871434476, 0.4972090709],
      direction: [-77.5085729481, 4.2913520679, 149.5001819656],
      "speed factor": 0.2898976763,
      "Uz factor": 0.994559996,
      "Directional variance factor": 0.6185464829,
      "Velocity stability factor": 0.5829558333,
      "Direction stability factor": 0.3694201252,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0198083492, 0.1948138893, 0.4567553232],
      direction: [-169.9881497851, 107.3346365782, 176.9328261008],
      "speed factor": 0.2191520156,
      "Uz factor": 0.8690893599,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.63828698,
      "Direction stability factor": 0.0363306225,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0218618061, 0.1785083338, 0.4662906162],
      direction: [-178.9951138894, 35.9531166931, 179.2402730096],
      "speed factor": 0.2535962028,
      "Uz factor": 0.8980055673,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6365499214,
      "Direction stability factor": 0.0049017031,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2054795029, 0.7437716404, 1.1453055238],
      direction: [-36.5195281885, -11.2514204761, 4.6773254712],
      "speed factor": 0.8714440801,
      "Uz factor": 0.9968155301,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2773658041,
      "Direction stability factor": 0.8855642954,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0589537105, 0.2028108203, 0.418532422],
      direction: [-57.8211560215, -25.2357677545, 69.7965008444],
      "speed factor": 0.3762385116,
      "Uz factor": 0.8383303557,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6510835704,
      "Direction stability factor": 0.6455065087,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1016639168, 0.4560293504, 0.7303894533],
      direction: [-95.6993346084, -65.8016836656, 24.3247668448],
      "speed factor": 0.6209982765,
      "Uz factor": 0.6196435904,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5389305952,
      "Direction stability factor": 0.6665997182,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3658809253, 0.6375067273, 0.8474440075],
      direction: [12.4952869709, 18.3077897833, 23.880303375],
      "speed factor": 0.8095939772,
      "Uz factor": 0.9749688803,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.612132493,
      "Direction stability factor": 0.9683749544,
      score: 0.0,
    },
    id: "IDN:37.0",
    "45": {
      velocity: [0.0447318183, 0.1870898439, 0.4576022705],
      direction: [-132.3954444132, 68.777644543, 178.4712100051],
      "speed factor": 0.2541393904,
      "Uz factor": 0.9536095446,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6643493869,
      "Direction stability factor": 0.1364815155,
      score: 0.0,
    },
    "90": {
      velocity: [0.0287642219, 0.1533979278, 0.3834797971],
      direction: [-177.0058697252, -117.1027977183, 179.6041788552],
      "speed factor": 0.2486625298,
      "Uz factor": 0.831449248,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7411081658,
      "Direction stability factor": 0.0094165317,
      score: 0.0,
    },
    "135": {
      velocity: [0.0039191002, 0.1754318132, 0.4268542488],
      direction: [-169.0207774917, 90.4894499591, 175.846507588],
      "speed factor": 0.1484767721,
      "Uz factor": 0.910005672,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6591942855,
      "Direction stability factor": 0.0420353192,
      score: 0.0,
    },
    "180": {
      velocity: [0.0219091933, 0.2931160821, 0.5990582812],
      direction: [-134.9052214159, -12.324859306, 66.6329598186],
      "speed factor": 0.5724538975,
      "Uz factor": 0.994059516,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5452314051,
      "Direction stability factor": 0.4401717188,
      score: 0.0,
    },
    "225": {
      velocity: [0.279204744, 0.4714198602, 0.6485085141],
      direction: [-32.7166643368, -20.5016409454, -5.0351317448],
      "speed factor": 0.6435396992,
      "Uz factor": 0.9204433715,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6680769322,
      "Direction stability factor": 0.9231068539,
      score: 0.0,
    },
    "270": {
      velocity: [0.400574078, 0.5797520777, 0.7445924262],
      direction: [-51.4359104345, -17.3488128962, 26.9884304405],
      "speed factor": 0.5304918711,
      "Uz factor": 0.4414876803,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7286372246,
      "Direction stability factor": 0.7821546087,
      score: 0.0,
    },
    "315": {
      velocity: [0.3772056476, 0.6079834202, 0.788908048],
      direction: [-19.9354144107, 17.0865069067, 32.9292412936],
      "speed factor": 0.749096079,
      "Uz factor": 0.8743491877,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6660122044,
      "Direction stability factor": 0.8531537342,
      score: 0.0,
    },
  },
  {
    position: [-54.55578191, 52.86786819, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1817048114, 0.7039226922, 1.0557416307],
      direction: [-11.9927327674, 9.6877781265, 80.8728752067],
      "speed factor": 0.7213455174,
      "Uz factor": 0.8628318434,
      "Directional variance factor": 0.1388641665,
      "Velocity stability factor": 0.3926923034,
      "Direction stability factor": 0.7420399778,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0511230772, 0.3267782791, 0.7790113008],
      direction: [-146.8751290171, -1.0264320686, 175.3617118084],
      "speed factor": 0.2901384774,
      "Uz factor": 0.9725071871,
      "Directional variance factor": 0.9087615939,
      "Velocity stability factor": 0.5292940114,
      "Direction stability factor": 0.1048976644,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.01803158, 0.0845771208, 0.356517417],
      direction: [-179.13543233, 90.5417215072, 174.526763845],
      "speed factor": 0.1310155985,
      "Uz factor": 0.4630703709,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6975444481,
      "Direction stability factor": 0.0176050106,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0160974096, 0.1398207176, 0.2977303127],
      direction: [-179.6522948684, 178.2918357827, 179.9113918268],
      "speed factor": 0.1572885393,
      "Uz factor": 0.7651645124,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7668589235,
      "Direction stability factor": 0.0012119814,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0265961283, 0.1790924289, 0.585545989],
      direction: [-163.4761665061, -4.7005118693, 176.3471512852],
      "speed factor": 0.2544259921,
      "Uz factor": 0.9991020889,
      "Directional variance factor": 0.5821767227,
      "Velocity stability factor": 0.542895586,
      "Direction stability factor": 0.0560463395,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0649216586, 0.3390226142, 0.5651456001],
      direction: [-124.0148539136, -14.0581127398, 0.0097676111],
      "speed factor": 0.3972176864,
      "Uz factor": 0.9897687224,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6153767637,
      "Direction stability factor": 0.6554871624,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1630509137, 0.4499544836, 0.726957529],
      direction: [-40.3603511043, -20.5107932659, 10.910355186],
      "speed factor": 0.8347197895,
      "Uz factor": 0.9403896792,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4528144283,
      "Direction stability factor": 0.8575813714,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4249395006, 0.8208613307, 1.0103894884],
      direction: [-54.9079913577, 9.3657886397, 32.8893071436],
      "speed factor": 1.1178084726,
      "Uz factor": 0.6905792993,
      "Directional variance factor": 0.1674854542,
      "Velocity stability factor": 0.5706662737,
      "Direction stability factor": 0.7561186153,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.356460694, 0.6264257666, 0.8402538948],
      direction: [-8.833046738, 28.4958193803, 36.5581147902],
      "speed factor": 0.7955218449,
      "Uz factor": 0.7408679265,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6103362786,
      "Direction stability factor": 0.8739134402,
      score: 0.0,
    },
    id: "IDN:71.0",
    "45": {
      velocity: [0.0326109178, 0.1538257293, 0.5217023008],
      direction: [-179.6238306479, 14.6017456836, 173.7945814715],
      "speed factor": 0.2089540311,
      "Uz factor": 0.9687904985,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.602384182,
      "Direction stability factor": 0.0182821886,
      score: 0.0,
    },
    "90": {
      velocity: [0.0545190112, 0.1635339143, 0.3192171454],
      direction: [-176.6508337522, -169.9553713786, 177.5645711286],
      "speed factor": 0.2650932606,
      "Uz factor": 0.9277072108,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8068080731,
      "Direction stability factor": 0.0160683198,
      score: 0.0,
    },
    "135": {
      velocity: [0.0210446239, 0.1115314702, 0.3137656539],
      direction: [-175.8626204481, 84.3413508189, 179.2457660016],
      "speed factor": 0.094394696,
      "Uz factor": 0.7047099547,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7641222299,
      "Direction stability factor": 0.0135878154,
      score: 0.0,
    },
    "180": {
      velocity: [0.0267127514, 0.1611651284, 0.444287256],
      direction: [-169.7042112825, -25.0539624293, 179.8349886129],
      "speed factor": 0.3147545002,
      "Uz factor": 0.9999230865,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6709692959,
      "Direction stability factor": 0.0290577781,
      score: 0.0,
    },
    "225": {
      velocity: [0.0471798251, 0.2672476545, 0.5805716953],
      direction: [-131.8598145555, -5.8019698627, 31.534314426],
      "speed factor": 0.3648222947,
      "Uz factor": 0.9205252589,
      "Directional variance factor": 0.4842693455,
      "Velocity stability factor": 0.5205977296,
      "Direction stability factor": 0.5461274195,
      score: 0.0,
    },
    "270": {
      velocity: [0.5488759438, 0.7488945452, 0.9686232872],
      direction: [-29.1255435046, 8.0105034302, 51.5561896409],
      "speed factor": 0.6852626903,
      "Uz factor": 0.5113079247,
      "Directional variance factor": 0.2879552506,
      "Velocity stability factor": 0.6689019506,
      "Direction stability factor": 0.7758840746,
      score: 0.0,
    },
    "315": {
      velocity: [0.4394941081, 0.8804721698, 1.2068364989],
      direction: [-11.3480583534, 12.9596347906, 44.0033264955],
      "speed factor": 1.0848293361,
      "Uz factor": 0.7437707223,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3775042523,
      "Direction stability factor": 0.8462461532,
      score: 0.0,
    },
  },
  {
    position: [-52.13536577, 92.22471892, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1797146971, 0.5925076367, 0.973999887],
      direction: [-0.9608459513, 16.7410011441, 75.2938300256],
      "speed factor": 0.6071728224,
      "Uz factor": 0.9999999284,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4481061914,
      "Direction stability factor": 0.7881814556,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0276973662, 0.1939987505, 0.74994501],
      direction: [-179.8697686674, 57.5999503078, 178.8539301944],
      "speed factor": 0.1722467669,
      "Uz factor": 0.7973694362,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5329416246,
      "Direction stability factor": 0.0035452809,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0080452682, 0.1844047285, 0.5458620251],
      direction: [-110.5703444511, 37.4153993743, 105.1749331512],
      "speed factor": 0.2856552178,
      "Uz factor": 0.9856604389,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5194314023,
      "Direction stability factor": 0.4007075622,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0315284655, 0.1953846702, 0.4295618312],
      direction: [-169.8364209095, 124.4811424985, 179.3666936314],
      "speed factor": 0.2197941042,
      "Uz factor": 0.9209438916,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6705004055,
      "Direction stability factor": 0.0299913485,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0368750372, 0.1865896044, 0.5048895939],
      direction: [-177.8005298723, 60.1284274426, 178.2432497774],
      "speed factor": 0.2650767846,
      "Uz factor": 0.8693096745,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6172616995,
      "Direction stability factor": 0.010989501,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1301472367, 0.7576999994, 1.2166394312],
      direction: [-106.402579031, -17.5243356484, 1.3752428077],
      "speed factor": 0.8877633175,
      "Uz factor": 0.9999369844,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1645938761,
      "Direction stability factor": 0.7006171616,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0568036708, 0.2299451852, 0.5002810823],
      direction: [-69.481711742, -38.8167835271, 115.5352283574],
      "speed factor": 0.4265760284,
      "Uz factor": 0.9003852198,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5696726472,
      "Direction stability factor": 0.4860640553,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1370091625, 0.5101578385, 0.7680785078],
      direction: [-92.5159553799, -73.243589344, 27.2137035181],
      "speed factor": 0.6947077818,
      "Uz factor": 0.7162136149,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5372117871,
      "Direction stability factor": 0.6674176142,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4100441893, 0.6592395793, 0.8526552198],
      direction: [17.5029434448, 24.7051920592, 36.468099841],
      "speed factor": 0.8371933505,
      "Uz factor": 0.9658902119,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6435058182,
      "Direction stability factor": 0.94731901,
      score: 0.0,
    },
    id: "IDN:36.0",
    "45": {
      velocity: [0.0266945601, 0.1751774736, 0.4419113762],
      direction: [-175.1367281572, 63.3981931255, 146.8572890152],
      "speed factor": 0.2379578464,
      "Uz factor": 0.9797917076,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6624418673,
      "Direction stability factor": 0.1055721745,
      score: 0.0,
    },
    "90": {
      velocity: [0.0099878259, 0.1464823238, 0.4309669563],
      direction: [-179.6641547367, -114.7059877341, 179.9255994846],
      "speed factor": 0.2374521334,
      "Uz factor": 0.6324993929,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.692745211,
      "Direction stability factor": 0.0011395716,
      score: 0.0,
    },
    "135": {
      velocity: [0.024916771, 0.1993535352, 0.4199845141],
      direction: [-159.1353556121, 99.618331826, 179.5085140661],
      "speed factor": 0.1687229293,
      "Uz factor": 0.9611486497,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6816501421,
      "Direction stability factor": 0.0593225842,
      score: 0.0,
    },
    "180": {
      velocity: [0.0281011135, 0.3670386299, 0.9077871657],
      direction: [-138.8932899067, -9.8151676859, 166.9103739036],
      "speed factor": 0.7168241768,
      "Uz factor": 0.9999025179,
      "Directional variance factor": 0.1275406501,
      "Velocity stability factor": 0.3068453224,
      "Direction stability factor": 0.1505453783,
      score: 0.0,
    },
    "225": {
      velocity: [0.1724882914, 0.5503378513, 0.7708100954],
      direction: [-50.6616254104, -27.566389133, -13.3567641607],
      "speed factor": 0.7512713935,
      "Uz factor": 0.9717021179,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4622399641,
      "Direction stability factor": 0.8963753854,
      score: 0.0,
    },
    "270": {
      velocity: [0.5320743193, 0.8070559033, 1.0198557639],
      direction: [-59.4190221936, -31.385913239, -6.2007949913],
      "speed factor": 0.738482211,
      "Uz factor": 0.8335887018,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6152364335,
      "Direction stability factor": 0.8521715911,
      score: 0.0,
    },
    "315": {
      velocity: [0.4190196361, 0.598635164, 0.7448091435],
      direction: [-17.809608113, 18.576833746, 38.0993130267],
      "speed factor": 0.737578097,
      "Uz factor": 0.8396350682,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7357078333,
      "Direction stability factor": 0.8446974413,
      score: 0.0,
    },
  },
  {
    position: [-51.63108851, 93.82668631, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.3985646947, 0.8725966453, 1.027885462],
      direction: [11.1490068953, 23.6581049039, 36.2511208642],
      "speed factor": 0.8941943278,
      "Uz factor": 0.9549228475,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.562728552,
      "Direction stability factor": 0.9302719056,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0596173413, 0.3394364597, 0.8236483879],
      direction: [-6.8954201658, 19.8566005493, 144.5049143018],
      "speed factor": 0.3013773677,
      "Uz factor": 0.9999477294,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5059214074,
      "Direction stability factor": 0.5794435154,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0276605921, 0.1705689981, 0.7375875825],
      direction: [-62.1090906988, 16.0237154985, 121.0348895365],
      "speed factor": 0.2642227491,
      "Uz factor": 0.9992179228,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3656415242,
      "Direction stability factor": 0.4912667216,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0547941034, 0.1953554922, 0.4739083584],
      direction: [-162.7496949785, 116.3331610234, 176.100275027],
      "speed factor": 0.219761281,
      "Uz factor": 0.9541629659,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6530492442,
      "Direction stability factor": 0.0587500833,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0441487396, 0.1727164525, 0.4923762768],
      direction: [-171.1289066103, 76.0644464146, 179.1976657367],
      "speed factor": 0.2453680206,
      "Uz factor": 0.9049811852,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6334433548,
      "Direction stability factor": 0.0268706324,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0860174152, 0.4226478159, 0.867656739],
      direction: [-166.0028355198, -19.8562166981, 178.7025230753],
      "speed factor": 0.4951976079,
      "Uz factor": 0.9999808515,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3989958868,
      "Direction stability factor": 0.042485115,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0575410171, 0.239954701, 0.536252067],
      direction: [-101.9190441658, -28.9791479013, 10.387365258],
      "speed factor": 0.4451448864,
      "Uz factor": 0.9755099822,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5354837619,
      "Direction stability factor": 0.6880377516,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1223806115, 0.6286657777, 0.8905553339],
      direction: [-80.5655117262, -46.662540079, 19.9636235368],
      "speed factor": 0.8560860483,
      "Uz factor": 0.9408283692,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4366669691,
      "Direction stability factor": 0.720752402,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4652533688, 0.6274848587, 0.7610015865],
      direction: [23.6243350485, 35.0561549824, 51.2855950299],
      "speed factor": 0.7968668261,
      "Uz factor": 0.9061236303,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7617941903,
      "Direction stability factor": 0.9231631667,
      score: 0.0,
    },
    id: "IDN:35.0",
    "45": {
      velocity: [0.0476125555, 0.2435147721, 0.8125920881],
      direction: [-26.6086462811, 10.1973968714, 173.7366151905],
      "speed factor": 0.3307859713,
      "Uz factor": 0.9926633176,
      "Directional variance factor": 0.0935647225,
      "Velocity stability factor": 0.3780958464,
      "Direction stability factor": 0.4434853848,
      score: 0.0,
    },
    "90": {
      velocity: [0.0241588313, 0.1430589095, 0.3903387749],
      direction: [-179.6189598621, -38.331033145, 176.0353912319],
      "speed factor": 0.2319026787,
      "Uz factor": 0.5521780404,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7327408102,
      "Direction stability factor": 0.012071247,
      score: 0.0,
    },
    "135": {
      velocity: [0.0174086502, 0.1977478414, 0.4743401788],
      direction: [-170.4578713712, 102.6011907546, 176.4204754084],
      "speed factor": 0.1673639498,
      "Uz factor": 0.97812261,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.631799635,
      "Direction stability factor": 0.0364490367,
      score: 0.0,
    },
    "180": {
      velocity: [0.04314868, 0.2179074905, 0.7210327359],
      direction: [-169.9860907122, 6.0201120535, 172.6958016168],
      "speed factor": 0.4255719828,
      "Uz factor": 0.9990599785,
      "Directional variance factor": 0.4648789286,
      "Velocity stability factor": 0.4658565939,
      "Direction stability factor": 0.0481058546,
      score: 0.0,
    },
    "225": {
      velocity: [0.1176522273, 0.5321438194, 0.858904198],
      direction: [-82.6604239685, -21.2107516434, -8.8332630833],
      "speed factor": 0.7264345489,
      "Uz factor": 0.9999421399,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3337771016,
      "Direction stability factor": 0.7949245531,
      score: 0.0,
    },
    "270": {
      velocity: [0.5186220376, 0.7882961885, 1.0273747211],
      direction: [-53.9637672572, -33.9904235152, -14.5415524804],
      "speed factor": 0.7213164662,
      "Uz factor": 0.8940199972,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5986942531,
      "Direction stability factor": 0.8904938478,
      score: 0.0,
    },
    "315": {
      velocity: [0.3979851487, 0.5511540436, 0.6937246993],
      direction: [-27.3813718081, 29.3312388938, 52.479847528],
      "speed factor": 0.6790766315,
      "Uz factor": 0.6294811515,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7600854391,
      "Direction stability factor": 0.7781632796,
      score: 0.0,
    },
  },
  {
    position: [-50.4827199, 95.04948189, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.3469785361, 0.7555248837, 0.8843889886],
      direction: [-8.8555694671, 23.7644611536, 47.3848671902],
      "speed factor": 0.7742249173,
      "Uz factor": 0.8019289821,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6265906689,
      "Direction stability factor": 0.8437765648,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2768551495, 0.7714533112, 1.0532950599],
      direction: [-2.5809356113, 38.7867671283, 70.443069768],
      "speed factor": 0.684954611,
      "Uz factor": 0.9963739327,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4978969246,
      "Direction stability factor": 0.7971555406,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0425231292, 0.3373111913, 0.913249118],
      direction: [-12.9754111369, 3.1709077599, 142.3752703891],
      "speed factor": 0.5225175222,
      "Uz factor": 0.9949385659,
      "Directional variance factor": 0.7181415325,
      "Velocity stability factor": 0.2219588513,
      "Direction stability factor": 0.5684703291,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0409160838, 0.194744161, 0.4330950553],
      direction: [-179.1391177843, 136.7882603215, 177.4558415951],
      "speed factor": 0.2190735762,
      "Uz factor": 0.9991122383,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6753467844,
      "Direction stability factor": 0.0094584462,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0250597327, 0.1710326654, 0.5148831656],
      direction: [-178.5270532111, 106.0256588311, 174.9321506612],
      "speed factor": 0.2429759641,
      "Uz factor": 0.9304127741,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5994265872,
      "Direction stability factor": 0.0181688781,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0807733478, 0.4752668407, 0.9264651406],
      direction: [-141.2891123085, -28.7724806192, 146.8233599554],
      "speed factor": 0.556848974,
      "Uz factor": 0.9896422727,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3497458093,
      "Direction stability factor": 0.199687577,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0318160608, 0.2183718815, 0.6658832894],
      direction: [-153.8362701381, -19.0403781793, 3.7720535834],
      "speed factor": 0.4051061553,
      "Uz factor": 0.9965802206,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.384734228,
      "Direction stability factor": 0.5621991008,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.152438202, 0.5498887028, 0.8268707374],
      direction: [-68.8709987877, -48.1848360527, -0.5248086706],
      "speed factor": 0.7488113132,
      "Uz factor": 0.9795547919,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5054118377,
      "Direction stability factor": 0.8101494719,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4231381931, 0.5924810772, 0.8351190466],
      direction: [20.0919080349, 48.0096065339, 67.9026873442],
      "speed factor": 0.7524141961,
      "Uz factor": 0.7358384736,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6681764186,
      "Direction stability factor": 0.8671922797,
      score: 0.0,
    },
    id: "IDN:34.0",
    "45": {
      velocity: [0.0913637739, 0.4372159905, 0.9593488127],
      direction: [-14.2341158446, 13.5034553972, 120.8808348987],
      "speed factor": 0.5939061306,
      "Uz factor": 0.9963751507,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2943556293,
      "Direction stability factor": 0.6246806924,
      score: 0.0,
    },
    "90": {
      velocity: [0.0340551582, 0.2052751925, 0.6632321109],
      direction: [-178.6445715723, -16.3227092065, 178.2985924493],
      "speed factor": 0.332757094,
      "Uz factor": 0.9922720184,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5407904623,
      "Direction stability factor": 0.0084912111,
      score: 0.0,
    },
    "135": {
      velocity: [0.0323934252, 0.2010726067, 0.512097485],
      direction: [-171.1868386202, 118.284060734, 178.2056407691],
      "speed factor": 0.170177866,
      "Uz factor": 0.99051234,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6134492832,
      "Direction stability factor": 0.029465335,
      score: 0.0,
    },
    "180": {
      velocity: [0.0203075586, 0.1214100544, 0.3934541242],
      direction: [-174.1671165781, 35.0958261045, 178.7051023147],
      "speed factor": 0.2371130863,
      "Uz factor": 0.9613763692,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7059765962,
      "Direction stability factor": 0.019799392,
      score: 0.0,
    },
    "225": {
      velocity: [0.0582762642, 0.1752984527, 0.320161504],
      direction: [-151.1489613217, -40.8014180672, -2.935221035],
      "speed factor": 0.2393015718,
      "Uz factor": 0.9136888769,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7646226245,
      "Direction stability factor": 0.5882951659,
      score: 0.0,
    },
    "270": {
      velocity: [0.5342960208, 0.8016262224, 1.0347644336],
      direction: [-46.7551481299, -29.8154135264, -15.7529950301],
      "speed factor": 0.7335138777,
      "Uz factor": 0.985825414,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6052289124,
      "Direction stability factor": 0.9138829081,
      score: 0.0,
    },
    "315": {
      velocity: [0.3827970906, 0.4857573353, 0.6235041671],
      direction: [-33.267288949, 18.5691508095, 60.5214864912],
      "speed factor": 0.5985013787,
      "Uz factor": 0.4307184695,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8047297616,
      "Direction stability factor": 0.7394756238,
      score: 0.0,
    },
  },
  {
    position: [-48.9216034, 95.65235413, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.3144861628, 0.7768598723, 0.9314494548],
      direction: [-35.2552750079, 8.3257545925, 47.8360607862],
      "speed factor": 0.7960879693,
      "Uz factor": 0.8046775983,
      "Directional variance factor": 0.2599329251,
      "Velocity stability factor": 0.5713149064,
      "Direction stability factor": 0.7691907339,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.422169067, 0.8398830419, 1.1257890579],
      direction: [6.7932744969, 45.8446675815, 57.3121758343],
      "speed factor": 0.7457117027,
      "Uz factor": 0.972359638,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5449876331,
      "Direction stability factor": 0.8596697185,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0524823815, 0.4409030176, 0.9484355813],
      direction: [-10.0696829146, 8.7133393028, 152.9792270744],
      "speed factor": 0.6829881671,
      "Uz factor": 0.9953824031,
      "Directional variance factor": 0.2254809509,
      "Velocity stability factor": 0.1994169627,
      "Direction stability factor": 0.5470863611,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0264776958, 0.2046309598, 0.417084692],
      direction: [-179.3102479844, 144.1333197466, 179.3046035644],
      "speed factor": 0.2301955341,
      "Uz factor": 0.9985551925,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6766480954,
      "Direction stability factor": 0.0038476346,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.028894257, 0.1824045007, 0.4856648229],
      direction: [-179.9786591946, 140.0739003052, 179.4334551595],
      "speed factor": 0.2591312561,
      "Uz factor": 0.957017583,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.626456939,
      "Direction stability factor": 0.0016330157,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0989485729, 0.5133259097, 0.8009850802],
      direction: [-113.7003448, -23.2016713575, 24.6221123125],
      "speed factor": 0.6014410887,
      "Uz factor": 0.9760515783,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4602026592,
      "Direction stability factor": 0.6157709525,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0129776603, 0.1556171117, 0.5721952031],
      direction: [-170.3135001551, -26.2871465487, 123.0906382071],
      "speed factor": 0.2886884951,
      "Uz factor": 0.9998723208,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4573644597,
      "Direction stability factor": 0.1849885045,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0920043043, 0.4909819484, 0.8829411234],
      direction: [-109.513153961, -32.9047720241, 5.1005320701],
      "speed factor": 0.6685950006,
      "Uz factor": 0.9905735087,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4199746196,
      "Direction stability factor": 0.6816286499,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3747561494, 0.5110929161, 0.6960873224],
      direction: [-40.4471865459, 25.5455076809, 78.4512802662],
      "speed factor": 0.6490562829,
      "Uz factor": 0.5138135727,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7411887963,
      "Direction stability factor": 0.6697264811,
      score: 0.0,
    },
    id: "IDN:33.0",
    "45": {
      velocity: [0.0721664738, 0.4372415428, 0.9428429461],
      direction: [-46.6476184561, 15.8825563964, 65.9841437201],
      "speed factor": 0.5939408405,
      "Uz factor": 0.9240481061,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.292167579,
      "Direction stability factor": 0.687133994,
      score: 0.0,
    },
    "90": {
      velocity: [0.0300930705, 0.2990237448, 0.8479315084],
      direction: [-178.3892159656, -3.5788166328, 165.8245098182],
      "speed factor": 0.4847262407,
      "Uz factor": 0.999710008,
      "Directional variance factor": 0.681882966,
      "Velocity stability factor": 0.4030944564,
      "Direction stability factor": 0.0438507617,
      score: 0.0,
    },
    "135": {
      velocity: [0.0338146353, 0.1833540467, 0.5891071525],
      direction: [-177.9686943961, 143.2186260595, 170.517218199],
      "speed factor": 0.155181757,
      "Uz factor": 0.998375232,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5525392872,
      "Direction stability factor": 0.0319835761,
      score: 0.0,
    },
    "180": {
      velocity: [0.0153323194, 0.1521721247, 0.3855002231],
      direction: [-153.2734519592, 94.4625787154, 175.8222065816],
      "speed factor": 0.2971912194,
      "Uz factor": 0.9070600483,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7083236533,
      "Direction stability factor": 0.0858453929,
      score: 0.0,
    },
    "225": {
      velocity: [0.0219876336, 0.1286617924, 0.2720042294],
      direction: [-144.7855750549, -91.6554149418, 106.7869122926],
      "speed factor": 0.1756374268,
      "Uz factor": 0.9891539452,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7752899315,
      "Direction stability factor": 0.3011875351,
      score: 0.0,
    },
    "270": {
      velocity: [0.1180656395, 0.4342041062, 0.8706848252],
      direction: [-105.3255419625, -25.8658207267, -5.1499903135],
      "speed factor": 0.3973107774,
      "Uz factor": 0.9997675868,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4063315747,
      "Direction stability factor": 0.7217345788,
      score: 0.0,
    },
    "315": {
      velocity: [0.4195626143, 0.5216397236, 0.638224099],
      direction: [-33.6933918555, 0.1909837859, 33.323018343],
      "speed factor": 0.6427120521,
      "Uz factor": 0.5546108291,
      "Directional variance factor": 0.9830236635,
      "Velocity stability factor": 0.8226139386,
      "Direction stability factor": 0.813843305,
      score: 0.0,
    },
  },
  {
    position: [-48.9216034, 95.65235413, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.3144861628, 0.7768598723, 0.9314494548],
      direction: [-35.2552750079, 8.3257545925, 47.8360607862],
      "speed factor": 0.7960879693,
      "Uz factor": 0.8046775983,
      "Directional variance factor": 0.2599329251,
      "Velocity stability factor": 0.5713149064,
      "Direction stability factor": 0.7691907339,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.422169067, 0.8398830419, 1.1257890579],
      direction: [6.7932744969, 45.8446675815, 57.3121758343],
      "speed factor": 0.7457117027,
      "Uz factor": 0.972359638,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5449876331,
      "Direction stability factor": 0.8596697185,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0524823815, 0.4409030176, 0.9484355813],
      direction: [-10.0696829146, 8.7133393028, 152.9792270744],
      "speed factor": 0.6829881671,
      "Uz factor": 0.9953824031,
      "Directional variance factor": 0.2254809509,
      "Velocity stability factor": 0.1994169627,
      "Direction stability factor": 0.5470863611,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0264776958, 0.2046309598, 0.417084692],
      direction: [-179.3102479844, 144.1333197466, 179.3046035644],
      "speed factor": 0.2301955341,
      "Uz factor": 0.9985551925,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6766480954,
      "Direction stability factor": 0.0038476346,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.028894257, 0.1824045007, 0.4856648229],
      direction: [-179.9786591946, 140.0739003052, 179.4334551595],
      "speed factor": 0.2591312561,
      "Uz factor": 0.957017583,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.626456939,
      "Direction stability factor": 0.0016330157,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0989485729, 0.5133259097, 0.8009850802],
      direction: [-113.7003448, -23.2016713575, 24.6221123125],
      "speed factor": 0.6014410887,
      "Uz factor": 0.9760515783,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4602026592,
      "Direction stability factor": 0.6157709525,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0129776603, 0.1556171117, 0.5721952031],
      direction: [-170.3135001551, -26.2871465487, 123.0906382071],
      "speed factor": 0.2886884951,
      "Uz factor": 0.9998723208,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4573644597,
      "Direction stability factor": 0.1849885045,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0920043043, 0.4909819484, 0.8829411234],
      direction: [-109.513153961, -32.9047720241, 5.1005320701],
      "speed factor": 0.6685950006,
      "Uz factor": 0.9905735087,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4199746196,
      "Direction stability factor": 0.6816286499,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3747561494, 0.5110929161, 0.6960873224],
      direction: [-40.4471865459, 25.5455076809, 78.4512802662],
      "speed factor": 0.6490562829,
      "Uz factor": 0.5138135727,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7411887963,
      "Direction stability factor": 0.6697264811,
      score: 0.0,
    },
    id: "IDN:32.0",
    "45": {
      velocity: [0.0721664738, 0.4372415428, 0.9428429461],
      direction: [-46.6476184561, 15.8825563964, 65.9841437201],
      "speed factor": 0.5939408405,
      "Uz factor": 0.9240481061,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.292167579,
      "Direction stability factor": 0.687133994,
      score: 0.0,
    },
    "90": {
      velocity: [0.0300930705, 0.2990237448, 0.8479315084],
      direction: [-178.3892159656, -3.5788166328, 165.8245098182],
      "speed factor": 0.4847262407,
      "Uz factor": 0.999710008,
      "Directional variance factor": 0.681882966,
      "Velocity stability factor": 0.4030944564,
      "Direction stability factor": 0.0438507617,
      score: 0.0,
    },
    "135": {
      velocity: [0.0338146353, 0.1833540467, 0.5891071525],
      direction: [-177.9686943961, 143.2186260595, 170.517218199],
      "speed factor": 0.155181757,
      "Uz factor": 0.998375232,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5525392872,
      "Direction stability factor": 0.0319835761,
      score: 0.0,
    },
    "180": {
      velocity: [0.0153323194, 0.1521721247, 0.3855002231],
      direction: [-153.2734519592, 94.4625787154, 175.8222065816],
      "speed factor": 0.2971912194,
      "Uz factor": 0.9070600483,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7083236533,
      "Direction stability factor": 0.0858453929,
      score: 0.0,
    },
    "225": {
      velocity: [0.0219876336, 0.1286617924, 0.2720042294],
      direction: [-144.7855750549, -91.6554149418, 106.7869122926],
      "speed factor": 0.1756374268,
      "Uz factor": 0.9891539452,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7752899315,
      "Direction stability factor": 0.3011875351,
      score: 0.0,
    },
    "270": {
      velocity: [0.1180656395, 0.4342041062, 0.8706848252],
      direction: [-105.3255419625, -25.8658207267, -5.1499903135],
      "speed factor": 0.3973107774,
      "Uz factor": 0.9997675868,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4063315747,
      "Direction stability factor": 0.7217345788,
      score: 0.0,
    },
    "315": {
      velocity: [0.4195626143, 0.5216397236, 0.638224099],
      direction: [-33.6933918555, 0.1909837859, 33.323018343],
      "speed factor": 0.6427120521,
      "Uz factor": 0.5546108291,
      "Directional variance factor": 0.9830236635,
      "Velocity stability factor": 0.8226139386,
      "Direction stability factor": 0.813843305,
      score: 0.0,
    },
  },
  {
    position: [-48.2735305, 95.74678309, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2425645092, 0.6428240603, 0.790804995],
      direction: [-58.6706959037, 7.5029649042, 54.129266809],
      "speed factor": 0.6587346302,
      "Uz factor": 0.496326241,
      "Directional variance factor": 0.3330697863,
      "Velocity stability factor": 0.6190656283,
      "Direction stability factor": 0.6866667702,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3973305662, 0.8058735489, 1.0684969619],
      direction: [5.6815480482, 44.4973369769, 57.5197166001],
      "speed factor": 0.715515502,
      "Uz factor": 0.9580426881,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5659745115,
      "Direction stability factor": 0.8560050874,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.05508207, 0.3795126933, 0.8596856651],
      direction: [-7.1686581978, 10.3646264478, 144.9843514686],
      "speed factor": 0.5878904622,
      "Uz factor": 0.990519532,
      "Directional variance factor": 0.0786998713,
      "Velocity stability factor": 0.2810428155,
      "Direction stability factor": 0.5773527509,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0514519415, 0.1699990133, 0.3511827742],
      direction: [-177.2866672697, 144.3145177266, 179.1615999569],
      "speed factor": 0.1912370136,
      "Uz factor": 0.9999660691,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7518771129,
      "Direction stability factor": 0.0098659244,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0243206538, 0.1528061578, 0.3662108161],
      direction: [-179.1918598035, 136.2566312082, 173.4120639134],
      "speed factor": 0.2170826457,
      "Uz factor": 0.8957571008,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7204051502,
      "Direction stability factor": 0.0205446563,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1604945547, 0.4441589922, 0.670549144],
      direction: [-128.0308186589, -15.6255661973, 16.8830986242],
      "speed factor": 0.5204012943,
      "Uz factor": 0.9930877485,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.607817958,
      "Direction stability factor": 0.5974613409,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0230659305, 0.1316808152, 0.4980357377],
      direction: [-159.5552971167, -63.4334134565, 134.8161404583],
      "speed factor": 0.2442837806,
      "Uz factor": 0.9644584234,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5391140688,
      "Direction stability factor": 0.1823015623,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0416803761, 0.43308769, 0.8328221791],
      direction: [-162.6211305737, -26.5390909149, 86.8088068806],
      "speed factor": 0.5897574549,
      "Uz factor": 0.991010921,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4198242968,
      "Direction stability factor": 0.3071390626,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3592958389, 0.4948863353, 0.6157049078],
      direction: [-27.4477924762, 23.2910218462, 74.5952817904],
      "speed factor": 0.6284749311,
      "Uz factor": 0.6509528065,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7934792969,
      "Direction stability factor": 0.7165470159,
      score: 0.0,
    },
    id: "IDN:31.0",
    "45": {
      velocity: [0.0548005384, 0.3817606671, 0.8310512776],
      direction: [-62.4147052726, 20.2697376036, 53.4139149607],
      "speed factor": 0.5185766431,
      "Uz factor": 0.9031320185,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3689327121,
      "Direction stability factor": 0.6782538327,
      score: 0.0,
    },
    "90": {
      velocity: [0.0345059748, 0.2812518841, 0.7515731955],
      direction: [-177.0381886202, -5.4374918163, 178.6162953583],
      "speed factor": 0.4559175345,
      "Uz factor": 0.9932670941,
      "Directional variance factor": 0.5166673941,
      "Velocity stability factor": 0.4766430883,
      "Direction stability factor": 0.0120708778,
      score: 0.0,
    },
    "135": {
      velocity: [0.0269679551, 0.1915585279, 0.6379445084],
      direction: [-178.0771256453, 154.5055067418, 179.5111584034],
      "speed factor": 0.1621256223,
      "Uz factor": 0.9994671034,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5076684889,
      "Direction stability factor": 0.006699211,
      score: 0.0,
    },
    "180": {
      velocity: [0.0146223983, 0.1653236408, 0.4015256594],
      direction: [-157.2934067678, 114.2061005364, 179.0811579168],
      "speed factor": 0.3228760493,
      "Uz factor": 0.9385143521,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6951369133,
      "Direction stability factor": 0.0656262092,
      score: 0.0,
    },
    "225": {
      velocity: [0.0190941488, 0.18817025, 0.3921048455],
      direction: [-145.9997589921, -91.1049647725, 160.2643499918],
      "speed factor": 0.2568729838,
      "Uz factor": 0.9581608251,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6647452185,
      "Direction stability factor": 0.1492663639,
      score: 0.0,
    },
    "270": {
      velocity: [0.0701633402, 0.3937569149, 0.8752713876],
      direction: [-153.3146786873, -18.0046856283, -2.0338625575],
      "speed factor": 0.3603002914,
      "Uz factor": 0.9993862063,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3649281925,
      "Direction stability factor": 0.5797755108,
      score: 0.0,
    },
    "315": {
      velocity: [0.3850274297, 0.4742882429, 0.596221231],
      direction: [-32.19110207, -5.5802070686, 24.5878462073],
      "speed factor": 0.5843703155,
      "Uz factor": 0.6366666827,
      "Directional variance factor": 0.5039815939,
      "Velocity stability factor": 0.828671992,
      "Direction stability factor": 0.8422806992,
      score: 0.0,
    },
  },
  {
    position: [-45.30487816, 96.17933745, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2019250608, 0.7040091302, 0.9324727955],
      direction: [-83.2009221234, -39.1361720283, 36.1264966331],
      "speed factor": 0.7214340948,
      "Uz factor": 0.4167269068,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.492392938,
      "Direction stability factor": 0.6685349479,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2688995928, 0.7582935086, 1.0109935088],
      direction: [2.5463159236, 43.3417206758, 55.9847572993],
      "speed factor": 0.6732703427,
      "Uz factor": 0.9126422792,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5201075673,
      "Direction stability factor": 0.8515598851,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0847194188, 0.4126995334, 0.8512703222],
      direction: [-11.8475462211, 5.4248502389, 37.1857052157],
      "speed factor": 0.6392990899,
      "Uz factor": 0.9704907066,
      "Directional variance factor": 0.5177910899,
      "Velocity stability factor": 0.3150449704,
      "Direction stability factor": 0.8637965238,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0203941378, 0.1949244959, 0.6554770125],
      direction: [-176.4323771512, 138.7500113642, 179.1457048186],
      "speed factor": 0.2192764403,
      "Uz factor": 0.9478906124,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4742663109,
      "Direction stability factor": 0.0122831056,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0148667183, 0.16322884, 0.4149138474],
      direction: [-178.1529251608, 143.5281808602, 179.8834490968],
      "speed factor": 0.2318895322,
      "Uz factor": 0.859656098,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6728448803,
      "Direction stability factor": 0.005454516,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0579677052, 0.4171631759, 0.7355106936],
      direction: [-80.5393254231, 17.6318900888, 59.4877676735],
      "speed factor": 0.4887714996,
      "Uz factor": 0.9895611822,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4790357771,
      "Direction stability factor": 0.6110358525,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0250978238, 0.1299135562, 0.6163326102],
      direction: [-162.3376813926, -16.3543457955, 175.1123181574],
      "speed factor": 0.2410053021,
      "Uz factor": 0.8941839193,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4262965962,
      "Direction stability factor": 0.0626388901,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0941257132, 0.4269202057, 0.8015071112],
      direction: [-168.2853213823, -20.3439927566, 158.9773814119],
      "speed factor": 0.5813588791,
      "Uz factor": 0.9675267207,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4812491283,
      "Direction stability factor": 0.0909369367,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2962609588, 0.4794112743, 0.591962335],
      direction: [-49.3853976901, -7.3704513338, 78.4437054279],
      "speed factor": 0.608822564,
      "Uz factor": 0.4543853246,
      "Directional variance factor": 0.3448487703,
      "Velocity stability factor": 0.7618319181,
      "Direction stability factor": 0.644919158,
      score: 0.0,
    },
    id: "IDN:30.0",
    "45": {
      velocity: [0.0193294634, 0.34636908, 0.7320489176],
      direction: [-122.7057468196, 7.1663199401, 48.9533507752],
      "speed factor": 0.4705013645,
      "Uz factor": 0.8100995364,
      "Directional variance factor": 0.3629937831,
      "Velocity stability factor": 0.4205816364,
      "Direction stability factor": 0.5231691733,
      score: 0.0,
    },
    "90": {
      velocity: [0.0284319969, 0.2513499452, 0.575275381],
      direction: [-177.492069459, 4.1227138289, 146.6627239333],
      "speed factor": 0.4074456165,
      "Uz factor": 0.99166743,
      "Directional variance factor": 0.6335365485,
      "Velocity stability factor": 0.6008822375,
      "Direction stability factor": 0.0995700184,
      score: 0.0,
    },
    "135": {
      velocity: [0.0386608206, 0.1938740957, 0.4368886241],
      direction: [-179.7239949809, 158.7972625164, 179.7495332071],
      "speed factor": 0.1640854038,
      "Uz factor": 0.975883613,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6791037313,
      "Direction stability factor": 0.0014624217,
      score: 0.0,
    },
    "180": {
      velocity: [0.0251438918, 0.1576606168, 0.3628207338],
      direction: [-178.9390391438, 120.5512247479, 175.1871062394],
      "speed factor": 0.3079102107,
      "Uz factor": 0.7355394815,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.733925209,
      "Direction stability factor": 0.0163162628,
      score: 0.0,
    },
    "225": {
      velocity: [0.06749654, 0.2226688559, 0.5193652561],
      direction: [-175.0560372762, -88.9716184615, 178.0338020487],
      "speed factor": 0.3039673561,
      "Uz factor": 0.9536646956,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5938691597,
      "Direction stability factor": 0.0191948908,
      score: 0.0,
    },
    "270": {
      velocity: [0.0374503112, 0.3863867513, 1.0211004411],
      direction: [-176.7582342865, -9.5810815373, 175.9466702413],
      "speed factor": 0.3535563537,
      "Uz factor": 0.9988845778,
      "Directional variance factor": 0.1483483078,
      "Velocity stability factor": 0.2240936257,
      "Direction stability factor": 0.0202641541,
      score: 0.0,
    },
    "315": {
      velocity: [0.4067837941, 0.4970805672, 0.6252403139],
      direction: [-22.5151273577, 0.5818046727, 31.3877216929],
      "speed factor": 0.6124527273,
      "Uz factor": 0.7461581438,
      "Directional variance factor": 0.9482840291,
      "Velocity stability factor": 0.8227802136,
      "Direction stability factor": 0.8502698637,
      score: 0.0,
    },
  },
  {
    position: [-42.33622582, 96.61189181, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2686006968, 0.7092301297, 0.9257573435],
      direction: [-82.523216537, -43.291568089, 49.9671621228],
      "speed factor": 0.7267843196,
      "Uz factor": 0.8329026054,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5433873258,
      "Direction stability factor": 0.6319711704,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3747347953, 0.7592664351, 0.9816179886],
      direction: [-11.1753713179, 41.6818125614, 55.8272257754],
      "speed factor": 0.6741341805,
      "Uz factor": 0.8697210988,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6075447518,
      "Direction stability factor": 0.8138816747,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0718014417, 0.4369321668, 0.8949479025],
      direction: [-11.1510435575, 2.5949770101, 163.2847114265],
      "speed factor": 0.6768370545,
      "Uz factor": 0.9705766726,
      "Directional variance factor": 0.7693353769,
      "Velocity stability factor": 0.2644737539,
      "Direction stability factor": 0.5154562362,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0317912615, 0.1651565718, 0.5487211295],
      direction: [-179.2992987941, 90.9827621891, 177.6192378535],
      "speed factor": 0.1857896053,
      "Uz factor": 0.9307654585,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5720756183,
      "Direction stability factor": 0.0085596204,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0195755118, 0.1561186364, 0.4238681471],
      direction: [-177.8334487493, 159.9759876751, 176.6589926102],
      "speed factor": 0.2217884876,
      "Uz factor": 0.9207353828,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6693729417,
      "Direction stability factor": 0.015298774,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0926710637, 0.3796475108, 0.8157757687],
      direction: [-24.8151909789, 35.683864483, 89.0952935067],
      "speed factor": 0.4448160669,
      "Uz factor": 0.9963080493,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4440032779,
      "Direction stability factor": 0.6835819875,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0282314385, 0.1432415453, 0.575558942],
      direction: [-146.6699443496, -7.7847910633, 142.7020525832],
      "speed factor": 0.2657303281,
      "Uz factor": 0.9290640429,
      "Directional variance factor": 0.3080185722,
      "Velocity stability factor": 0.4689019337,
      "Direction stability factor": 0.1961888974,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0461065735, 0.4602596131, 0.790219288],
      direction: [-85.7162530241, -15.5568420331, 42.5663227579],
      "speed factor": 0.6267588397,
      "Uz factor": 0.954002674,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4543125951,
      "Direction stability factor": 0.6436595117,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3387629081, 0.5507246936, 0.6893121481],
      direction: [-54.5879694759, -28.2243221263, 44.4454712382],
      "speed factor": 0.6993861805,
      "Uz factor": 0.565277227,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7176555579,
      "Direction stability factor": 0.7249071091,
      score: 0.0,
    },
    id: "IDN:29.0",
    "45": {
      velocity: [0.0259886279, 0.3830289811, 0.6844311978],
      direction: [-109.1390870848, -0.3052466339, 33.9013089784],
      "speed factor": 0.5202994974,
      "Uz factor": 0.8299721155,
      "Directional variance factor": 0.9728669659,
      "Velocity stability factor": 0.4647070259,
      "Direction stability factor": 0.6026655665,
      score: 0.0,
    },
    "90": {
      velocity: [0.0282827758, 0.2161360221, 0.4472677359],
      direction: [-141.7203383209, 20.2152056225, 157.1123187205],
      "speed factor": 0.3503628167,
      "Uz factor": 0.9927991563,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6942006712,
      "Direction stability factor": 0.169909286,
      score: 0.0,
    },
    "135": {
      velocity: [0.0195781781, 0.1467023833, 0.49582284],
      direction: [-179.6527802082, -170.5869865598, 178.9203354564],
      "speed factor": 0.1241616097,
      "Uz factor": 0.9379904487,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6162369035,
      "Direction stability factor": 0.0039635676,
      score: 0.0,
    },
    "180": {
      velocity: [0.0122695272, 0.1509625897, 0.3429399999],
      direction: [-166.6902339012, 106.4560891211, 177.3889467037],
      "speed factor": 0.294829005,
      "Uz factor": 0.7842998738,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7394459258,
      "Direction stability factor": 0.0442244983,
      score: 0.0,
    },
    "225": {
      velocity: [0.0630341153, 0.2054780477, 0.4957500915],
      direction: [-120.6428777597, -16.2131870203, 75.064191383],
      "speed factor": 0.2805000218,
      "Uz factor": 0.9180474207,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.611083271,
      "Direction stability factor": 0.4563692524,
      score: 0.0,
    },
    "270": {
      velocity: [0.0338479612, 0.4600674907, 1.1544552922],
      direction: [-164.535576199, -10.623215431, 125.4957857425],
      "speed factor": 0.4209766094,
      "Uz factor": 0.9928748796,
      "Directional variance factor": 0.0557141839,
      "Velocity stability factor": 0.1160613466,
      "Direction stability factor": 0.1943573279,
      score: 0.0,
    },
    "315": {
      velocity: [0.4417483529, 0.5221026501, 0.6587311226],
      direction: [-23.7469098681, -5.8632428647, 21.5367508229],
      "speed factor": 0.6432824236,
      "Uz factor": 0.7514671114,
      "Directional variance factor": 0.4788228565,
      "Velocity stability factor": 0.8239757727,
      "Direction stability factor": 0.8742120536,
      score: 0.0,
    },
  },
  {
    position: [-39.36757348, 97.04444617, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2206146707, 0.7152752367, 1.0338381148],
      direction: [-88.2877340529, -69.1010061772, 60.1189062747],
      "speed factor": 0.7329790493,
      "Uz factor": 0.7432849395,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4349473092,
      "Direction stability factor": 0.5877593324,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4036405462, 0.7846821594, 1.0066048087],
      direction: [-40.3663083476, 26.0864268412, 48.5464057302],
      "speed factor": 0.6967001832,
      "Uz factor": 0.8116877818,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6100790203,
      "Direction stability factor": 0.7530202387,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0311406027, 0.4555349913, 0.9948111079],
      direction: [-11.5445693943, 3.4866007832, 147.743868688],
      "speed factor": 0.7056540699,
      "Uz factor": 0.9694954789,
      "Directional variance factor": 0.6900799304,
      "Velocity stability factor": 0.1389078579,
      "Direction stability factor": 0.5575321164,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0257961072, 0.1224208839, 0.3815618025],
      direction: [-174.8551289999, 70.9917993711, 174.1283113461],
      "speed factor": 0.137714942,
      "Uz factor": 0.9637259699,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7054903874,
      "Direction stability factor": 0.0306015546,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0178552993, 0.1398793621, 0.464703258],
      direction: [-173.6182835278, 12.6004944593, 179.6323126002],
      "speed factor": 0.1987183138,
      "Uz factor": 0.8331750744,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6345715623,
      "Direction stability factor": 0.0187483441,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0113948122, 0.2010337436, 0.4820362718],
      direction: [-149.551636637, 50.9727773467, 139.6930301043],
      "speed factor": 0.2355422771,
      "Uz factor": 0.9912039424,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.638122796,
      "Direction stability factor": 0.1965425924,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0225514422, 0.1657194339, 0.6692746085],
      direction: [-108.8988718117, -13.9932433423, 63.9193170394],
      "speed factor": 0.3074295201,
      "Uz factor": 0.972784844,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3724535661,
      "Direction stability factor": 0.5199494754,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0962079619, 0.4126762117, 0.7957429269],
      direction: [-120.8992643154, -14.1422569656, 44.0639698047],
      "speed factor": 0.5619621105,
      "Uz factor": 0.9657255272,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4870032291,
      "Direction stability factor": 0.5417687941,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3697601647, 0.6072355429, 0.8327393418],
      direction: [-51.0344134741, -23.8198592844, 26.8586934691],
      "speed factor": 0.7711514519,
      "Uz factor": 0.7147973467,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6271006108,
      "Direction stability factor": 0.7836302585,
      score: 0.0,
    },
    id: "IDN:28.0",
    "45": {
      velocity: [0.08294776, 0.3837718878, 0.6486836476],
      direction: [-79.5016687872, -13.369473461, 28.2798885154],
      "speed factor": 0.521308648,
      "Uz factor": 0.8466186928,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5400746251,
      "Direction stability factor": 0.7006067853,
      score: 0.0,
    },
    "90": {
      velocity: [0.0243912726, 0.2332607379, 0.6750861776],
      direction: [-121.3452637659, 34.2044368324, 177.7187042171],
      "speed factor": 0.3781224821,
      "Uz factor": 0.9990036671,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5250854228,
      "Direction stability factor": 0.1692667556,
      score: 0.0,
    },
    "135": {
      velocity: [0.0118473381, 0.1327475568, 0.4190417322],
      direction: [-171.2787708338, 90.5268324343, 179.6596505006],
      "speed factor": 0.1123509378,
      "Uz factor": 0.8741098802,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6718783557,
      "Direction stability factor": 0.0251710518,
      score: 0.0,
    },
    "180": {
      velocity: [0.0340755702, 0.1523103739, 0.3716305575],
      direction: [-176.7366852912, 111.9564646857, 160.6451711993],
      "speed factor": 0.2974612193,
      "Uz factor": 0.9699843699,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7340212252,
      "Direction stability factor": 0.0628281764,
      score: 0.0,
    },
    "225": {
      velocity: [0.0202258907, 0.2795795263, 0.5099934441],
      direction: [-68.1289492778, 0.5092763379, 143.5196549854],
      "speed factor": 0.3816566494,
      "Uz factor": 0.9975294843,
      "Directional variance factor": 0.9547309922,
      "Velocity stability factor": 0.5598064196,
      "Direction stability factor": 0.4120872104,
      score: 0.0,
    },
    "270": {
      velocity: [0.0339266692, 0.5568000514, 1.1964645978],
      direction: [-134.916245059, -7.4893160973, 45.5445037909],
      "speed factor": 0.5094900259,
      "Uz factor": 0.995454143,
      "Directional variance factor": 0.3342830136,
      "Velocity stability factor": 0.0829863568,
      "Direction stability factor": 0.4987201421,
      score: 0.0,
    },
    "315": {
      velocity: [0.3775534523, 0.5007856659, 0.6647393826],
      direction: [-24.8836617232, -8.5368370827, 12.1606260764],
      "speed factor": 0.6170177776,
      "Uz factor": 0.8686906556,
      "Directional variance factor": 0.2411700371,
      "Velocity stability factor": 0.7670244436,
      "Direction stability factor": 0.8970992006,
      score: 0.0,
    },
  },
  {
    position: [-36.39892114, 97.47700053, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2889103977, 0.8855249689, 1.2283680568],
      direction: [-89.7908986062, -76.3376278661, 39.1228223837],
      "speed factor": 0.907442641,
      "Uz factor": 0.9064742279,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3472358894,
      "Direction stability factor": 0.6419063306,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5266240695, 0.9077683574, 1.1528427126],
      direction: [-43.2760165537, 0.8291698574, 26.4938507938],
      "speed factor": 0.8059854215,
      "Uz factor": 0.9104925536,
      "Directional variance factor": 0.9262960127,
      "Velocity stability factor": 0.5950410297,
      "Direction stability factor": 0.8061948129,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.144508681, 0.391219215, 0.7661435459],
      direction: [-19.5353092569, 11.1812559334, 50.5809709228],
      "speed factor": 0.6060246447,
      "Uz factor": 0.9627689646,
      "Directional variance factor": 0.0061105837,
      "Velocity stability factor": 0.4445353526,
      "Direction stability factor": 0.8052325551,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0168185149, 0.1442221943, 0.3335942558],
      direction: [-175.3366322069, 50.6101031175, 165.5706054557],
      "speed factor": 0.1622398931,
      "Uz factor": 0.9996396355,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7377670135,
      "Direction stability factor": 0.0530354509,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0416695709, 0.1323949673, 0.2777709577],
      direction: [-179.90314276, 38.5266874343, 174.8521269014],
      "speed factor": 0.1880856779,
      "Uz factor": 0.9268917151,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8069183058,
      "Direction stability factor": 0.0145686954,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0144932407, 0.1869802575, 0.3743769783],
      direction: [-101.6738888025, 38.7549687432, 162.379282151],
      "speed factor": 0.2190764337,
      "Uz factor": 0.9043726023,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7232846404,
      "Direction stability factor": 0.2665189696,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0248591631, 0.1288294637, 0.5095716014],
      direction: [-81.7542903646, 1.7159385986, 45.0449557513],
      "speed factor": 0.2389941799,
      "Uz factor": 0.9803272485,
      "Directional variance factor": 0.8474721246,
      "Velocity stability factor": 0.5296603276,
      "Direction stability factor": 0.6477798719,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.167293291, 0.4100910942, 0.7562286179],
      direction: [-62.1805090442, -20.6449787054, 47.3742633678],
      "speed factor": 0.5584418251,
      "Uz factor": 0.9902962009,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5681103361,
      "Direction stability factor": 0.6956811877,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4014431833, 0.6698927283, 0.9777816811],
      direction: [-53.981807251, -37.7456754775, -10.3876612529],
      "speed factor": 0.8507221885,
      "Uz factor": 0.9137726094,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5357971061,
      "Direction stability factor": 0.87890515,
      score: 0.0,
    },
    id: "IDN:27.0",
    "45": {
      velocity: [0.1344953741, 0.3617579992, 0.6215202265],
      direction: [-91.5162050977, -25.5852972602, 36.3830769175],
      "speed factor": 0.4914053882,
      "Uz factor": 0.7932180472,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.604064206,
      "Direction stability factor": 0.6447242166,
      score: 0.0,
    },
    "90": {
      velocity: [0.0775619641, 0.2982692927, 0.6076593837],
      direction: [-12.4639374234, 29.3393720431, 95.4446906045],
      "speed factor": 0.4835032517,
      "Uz factor": 0.9949635382,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6131044058,
      "Direction stability factor": 0.700253811,
      score: 0.0,
    },
    "135": {
      velocity: [0.0444298027, 0.1705880844, 0.2944227333],
      direction: [-91.840465093, 24.2056031053, 138.7509246136],
      "speed factor": 0.1443772806,
      "Uz factor": 0.9993132794,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7985529942,
      "Direction stability factor": 0.3594683619,
      score: 0.0,
    },
    "180": {
      velocity: [0.0107103678, 0.1501276117, 0.3515612814],
      direction: [-144.4623019168, 68.5990766297, 169.7624030612],
      "speed factor": 0.2931982982,
      "Uz factor": 0.9094938765,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7314241774,
      "Direction stability factor": 0.1271535973,
      score: 0.0,
    },
    "225": {
      velocity: [0.0984097824, 0.2978583576, 0.521494498],
      direction: [-44.4011524207, 12.2604430514, 60.6628312837],
      "speed factor": 0.4066092544,
      "Uz factor": 0.9833529259,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6197396612,
      "Direction stability factor": 0.7081556008,
      score: 0.0,
    },
    "270": {
      velocity: [0.0982979849, 0.5807239021, 1.2145036608],
      direction: [-168.961693481, -10.055773717, 40.5677967108],
      "speed factor": 0.5313811218,
      "Uz factor": 0.999924186,
      "Directional variance factor": 0.1061534474,
      "Velocity stability factor": 0.1195333863,
      "Direction stability factor": 0.4179736384,
      score: 0.0,
    },
    "315": {
      velocity: [0.5342416572, 0.6485436648, 0.8247953011],
      direction: [-36.7244370105, -29.1329954758, -17.1568860222],
      "speed factor": 0.7990703369,
      "Uz factor": 0.9006140274,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7642924332,
      "Direction stability factor": 0.9456456917,
      score: 0.0,
    },
  },
  {
    position: [-35.95052285, 97.41273697, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.3759745291, 0.9350610391, 1.2364091265],
      direction: [-78.747548423, -62.1296109772, 24.2807473147],
      "speed factor": 0.9582047809,
      "Uz factor": 0.9671140149,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.402143546,
      "Direction stability factor": 0.7138102896,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5597884278, 0.9339394703, 1.1427519799],
      direction: [-44.9206049053, -6.1228589452, 26.1163865088],
      "speed factor": 0.8292221154,
      "Uz factor": 0.818728449,
      "Directional variance factor": 0.4557458715,
      "Velocity stability factor": 0.6230129487,
      "Direction stability factor": 0.8026750238,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.196827876, 0.4122866893, 0.7704902845],
      direction: [-44.177575948, 18.3043798965, 56.5788715602],
      "speed factor": 0.6386595668,
      "Uz factor": 0.9232059166,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4874013582,
      "Direction stability factor": 0.7201209791,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0453887744, 0.360589076, 0.7775501702],
      direction: [-39.5669348083, 42.7526905558, 141.9307595947],
      "speed factor": 0.4056375195,
      "Uz factor": 0.9994837341,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3939028638,
      "Direction stability factor": 0.4958397378,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0118677835, 0.1842769589, 0.5436620245],
      direction: [-177.2954085425, 3.9136360203, 173.7562791747],
      "speed factor": 0.2617913464,
      "Uz factor": 0.9972759864,
      "Directional variance factor": 0.6521212426,
      "Velocity stability factor": 0.5651032193,
      "Direction stability factor": 0.024856423,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0343621493, 0.1244032723, 0.249089837],
      direction: [-90.4102557844, 30.5295400355, 133.939973003],
      "speed factor": 0.1457577693,
      "Uz factor": 0.9478404017,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8348954312,
      "Direction stability factor": 0.37680492,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0268067971, 0.1363030023, 0.4818210725],
      direction: [-129.9117662493, 1.6851247743, 55.842976192],
      "speed factor": 0.2528584947,
      "Uz factor": 0.9604110391,
      "Directional variance factor": 0.8502111312,
      "Velocity stability factor": 0.5584778761,
      "Direction stability factor": 0.4840146043,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.108685286, 0.4271988237, 0.7686588562],
      direction: [-123.5673894022, -11.0221965757, 60.6161925475],
      "speed factor": 0.5817382873,
      "Uz factor": 0.998281983,
      "Directional variance factor": 0.0202491933,
      "Velocity stability factor": 0.5160151711,
      "Direction stability factor": 0.488378939,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4347522869, 0.6844065097, 0.9855321105],
      direction: [-47.9143261466, -37.204120404, -18.0469659692],
      "speed factor": 0.8691537902,
      "Uz factor": 0.9554301499,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5563829434,
      "Direction stability factor": 0.9170351106,
      score: 0.0,
    },
    id: "IDN:26.0",
    "45": {
      velocity: [0.1370376573, 0.3652508077, 0.7165612943],
      direction: [-91.3644817772, -42.306626411, 49.2128753277],
      "speed factor": 0.4961499547,
      "Uz factor": 0.7853956304,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5288656211,
      "Direction stability factor": 0.6095073414,
      score: 0.0,
    },
    "90": {
      velocity: [0.2078878547, 0.4758887155, 0.8149510054],
      direction: [-9.4838482948, 40.9145616474, 79.6058001762],
      "speed factor": 0.7714295339,
      "Uz factor": 0.9995117282,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5569303873,
      "Direction stability factor": 0.7525287542,
      score: 0.0,
    },
    "135": {
      velocity: [0.0802001268, 0.4535786349, 0.6486683259],
      direction: [-3.6421258245, 20.9220052821, 141.3958873956],
      "speed factor": 0.383886425,
      "Uz factor": 0.999950669,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5419221803,
      "Direction stability factor": 0.5971166299,
      score: 0.0,
    },
    "180": {
      velocity: [0.0136747409, 0.1609519068, 0.4670352495],
      direction: [-135.588628755, 26.311716802, 160.4147602872],
      "speed factor": 0.3143380796,
      "Uz factor": 0.9391514642,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.642771468,
      "Direction stability factor": 0.1777683638,
      score: 0.0,
    },
    "225": {
      velocity: [0.0955269785, 0.2604761581, 0.4242661568],
      direction: [-44.3350223269, 14.8844314427, 68.7524471874],
      "speed factor": 0.3555784612,
      "Uz factor": 0.9688267899,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7045356009,
      "Direction stability factor": 0.6858681402,
      score: 0.0,
    },
    "270": {
      velocity: [0.0875291713, 0.5072333442, 1.1334836797],
      direction: [-151.9352774906, -5.3261089281, 37.7839332506],
      "speed factor": 0.4641348883,
      "Uz factor": 0.9952693321,
      "Directional variance factor": 0.5265680953,
      "Velocity stability factor": 0.1749477322,
      "Direction stability factor": 0.4730021924,
      score: 0.0,
    },
    "315": {
      velocity: [0.5098471803, 0.6415965952, 0.7826142732],
      direction: [-28.9304126787, -20.6385449717, -10.1251420564],
      "speed factor": 0.7905108557,
      "Uz factor": 0.9710219691,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7787215231,
      "Direction stability factor": 0.9477631372,
      score: 0.0,
    },
  },
  {
    position: [-35.61091946, 97.1184151, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.3809928443, 0.7553390611, 0.9606978538],
      direction: [-66.7462095405, -45.2457511415, 7.3146165165],
      "speed factor": 0.7740344954,
      "Uz factor": 0.9980802884,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5972031084,
      "Direction stability factor": 0.7942754832,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5971366071, 0.9223505772, 1.0985204374],
      direction: [-41.0178473813, -20.330542948, 5.3728675171],
      "speed factor": 0.8189326193,
      "Uz factor": 0.8946725082,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6757683888,
      "Direction stability factor": 0.8711369031,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2197664692, 0.4530660391, 0.8302516747],
      direction: [-47.8039575492, 4.5242884493, 65.4750913799],
      "speed factor": 0.7018294982,
      "Uz factor": 0.9246719669,
      "Directional variance factor": 0.5978410267,
      "Velocity stability factor": 0.4544981811,
      "Direction stability factor": 0.6853359752,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0586705081, 0.5175317759, 0.9816531332],
      direction: [-179.8013528825, 43.7792172202, 96.7706898143],
      "speed factor": 0.5821870927,
      "Uz factor": 1.0,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2359374189,
      "Direction stability factor": 0.2317443258,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0196107712, 0.2148828465, 0.5851213332],
      direction: [-172.0669727512, 1.8656129859, 160.1354806575],
      "speed factor": 0.3052713157,
      "Uz factor": 0.9941818097,
      "Directional variance factor": 0.8341677346,
      "Velocity stability factor": 0.5375303005,
      "Direction stability factor": 0.0772154072,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0385202394, 0.1706592289, 0.4032065229],
      direction: [-45.8639680757, -12.5417926583, 142.1874771556],
      "speed factor": 0.199953812,
      "Uz factor": 0.9736638258,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7195919528,
      "Direction stability factor": 0.4776348744,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0173367288, 0.0676379001, 0.2115850692],
      direction: [-107.0421785878, -11.2172609943, 125.7119022015],
      "speed factor": 0.1254764554,
      "Uz factor": 0.9364083422,
      "Directional variance factor": 0.0029101338,
      "Velocity stability factor": 0.8115115406,
      "Direction stability factor": 0.3534608867,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0535758026, 0.276620621, 0.5353403279],
      direction: [-127.7286773778, -2.3010983863, 76.0478437311],
      "speed factor": 0.3766883179,
      "Uz factor": 0.9982173177,
      "Directional variance factor": 0.7954579212,
      "Velocity stability factor": 0.646702941,
      "Direction stability factor": 0.433954108,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3348852486, 0.4810220586, 0.743028239],
      direction: [-31.8117396315, -21.511426327, -7.9052584177],
      "speed factor": 0.6108681601,
      "Uz factor": 0.988929801,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6712675659,
      "Direction stability factor": 0.9335931077,
      score: 0.0,
    },
    id: "IDN:25.0",
    "45": {
      velocity: [0.1169172333, 0.3771108905, 0.7528774075],
      direction: [-82.5243340234, -48.2680988233, 49.8568134269],
      "speed factor": 0.5122604723,
      "Uz factor": 0.8465954663,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4829845022,
      "Direction stability factor": 0.6322745904,
      score: 0.0,
    },
    "90": {
      velocity: [0.2513825144, 0.5852219677, 0.9544417955],
      direction: [-9.8733104492, 51.1893095431, 78.0821516365],
      "speed factor": 0.9486619351,
      "Uz factor": 0.9960031762,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4868668885,
      "Direction stability factor": 0.755679272,
      score: 0.0,
    },
    "135": {
      velocity: [0.0660590347, 0.7313906108, 0.9558002436],
      direction: [7.2801677784, 23.7426198298, 129.051787282],
      "speed factor": 0.6190126811,
      "Uz factor": 0.9957239728,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2830369162,
      "Direction stability factor": 0.6617455014,
      score: 0.0,
    },
    "180": {
      velocity: [0.0216149405, 0.2100566326, 0.6374717169],
      direction: [-137.1103822873, -10.5651650112, 165.1693219556],
      "speed factor": 0.4102393058,
      "Uz factor": 0.9995555309,
      "Directional variance factor": 0.0608742212,
      "Velocity stability factor": 0.5147314156,
      "Direction stability factor": 0.1603341549,
      score: 0.0,
    },
    "225": {
      velocity: [0.0691617054, 0.2200241323, 0.3753019942],
      direction: [-38.7811521059, 12.6402519479, 86.172835536],
      "speed factor": 0.300357019,
      "Uz factor": 0.9208069513,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7248470446,
      "Direction stability factor": 0.6529055899,
      score: 0.0,
    },
    "270": {
      velocity: [0.0650981949, 0.2804710225, 0.6862857011],
      direction: [-139.2165644883, -22.1366376628, 141.0956780734],
      "speed factor": 0.2566400419,
      "Uz factor": 0.9997050425,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5100053046,
      "Direction stability factor": 0.2213548818,
      score: 0.0,
    },
    "315": {
      velocity: [0.3205777634, 0.4585192136, 0.5897744426],
      direction: [-18.5709776423, -9.7013616999, 1.8887514617],
      "speed factor": 0.5649413021,
      "Uz factor": 0.9999852878,
      "Directional variance factor": 0.1376567378,
      "Velocity stability factor": 0.7816179712,
      "Direction stability factor": 0.9431674192,
      score: 0.0,
    },
  },
  {
    position: [-35.48357818, 96.68256738, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1946480778, 0.4390238253, 0.8001440925],
      direction: [-84.7620524338, -41.9376722977, 17.598251066],
      "speed factor": 0.4498901256,
      "Uz factor": 0.9972948485,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5792827237,
      "Direction stability factor": 0.7156658236,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6288890013, 0.9571431327, 1.1370676698],
      direction: [-30.1662872498, -13.7408681925, 2.1112344567],
      "speed factor": 0.8498240822,
      "Uz factor": 0.9318213448,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6713743474,
      "Direction stability factor": 0.9103402175,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1326677716, 0.3651901962, 0.6398504037],
      direction: [-46.981382192, -6.3405858742, 75.4958958679],
      "speed factor": 0.5657039593,
      "Uz factor": 0.8420970647,
      "Directional variance factor": 0.4363923667,
      "Velocity stability factor": 0.5468046633,
      "Direction stability factor": 0.6597853387,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0607156791, 0.5303178094, 0.9411804753],
      direction: [-18.3711558424, 53.4122198265, 163.0103446115],
      "speed factor": 0.5965704871,
      "Uz factor": 0.9942151789,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2711344868,
      "Direction stability factor": 0.4961624987,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0218466914, 0.3652584377, 0.8453134857],
      direction: [-170.4887862748, 13.405385529, 165.829972488],
      "speed factor": 0.5189009996,
      "Uz factor": 0.9905126389,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3265759006,
      "Direction stability factor": 0.0657812257,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0748199014, 0.2593725102, 0.5354043924],
      direction: [-32.2248244532, -12.1074461673, 125.3765079357],
      "speed factor": 0.3038952097,
      "Uz factor": 0.9677083603,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6458556202,
      "Direction stability factor": 0.5622185211,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.022048935, 0.0873728539, 0.2102601011],
      direction: [-153.5571217587, -16.4372106163, 104.3733120559],
      "speed factor": 0.1620871729,
      "Uz factor": 0.8952096421,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8173696995,
      "Direction stability factor": 0.2835265727,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0193231482, 0.1513965828, 0.4120808154],
      direction: [-159.7703367576, -23.2252857717, 92.2995636358],
      "speed factor": 0.2061643992,
      "Uz factor": 0.9953742712,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7119752049,
      "Direction stability factor": 0.2998058322,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0604690374, 0.1310817185, 0.3180675847],
      direction: [-55.9947119974, -22.5528215275, 4.4566091917],
      "speed factor": 0.166465647,
      "Uz factor": 0.9923287113,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.79252125,
      "Direction stability factor": 0.8320796634,
      score: 0.0,
    },
    id: "IDN:24.0",
    "45": {
      velocity: [0.1312732674, 0.3796360704, 0.6088685939],
      direction: [-69.1288685238, -32.4276236383, 32.7821372651],
      "speed factor": 0.5156906301,
      "Uz factor": 0.9507836587,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6117301121,
      "Direction stability factor": 0.7169138728,
      score: 0.0,
    },
    "90": {
      velocity: [0.2243726061, 0.5945508973, 1.0203721346],
      direction: [-24.681746729, 57.5928475699, 84.5710377532],
      "speed factor": 0.9637844029,
      "Uz factor": 0.9647641053,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4190337489,
      "Direction stability factor": 0.6965200431,
      score: 0.0,
    },
    "135": {
      velocity: [0.1471541002, 0.8306927069, 1.0762473685],
      direction: [14.3850200654, 31.157350743, 108.1190542199],
      "speed factor": 0.7030570423,
      "Uz factor": 0.9770432036,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2513266014,
      "Direction stability factor": 0.7396276829,
      score: 0.0,
    },
    "180": {
      velocity: [0.0391704327, 0.2901625869, 0.798163968],
      direction: [-167.3525763251, 4.1888739203, 163.030945331],
      "speed factor": 0.5666857397,
      "Uz factor": 0.9849108637,
      "Directional variance factor": 0.6276556515,
      "Velocity stability factor": 0.4019458216,
      "Direction stability factor": 0.0822679954,
      score: 0.0,
    },
    "225": {
      velocity: [0.0470551775, 0.1442830386, 0.3108783894],
      direction: [-51.236435205, 25.3807088653, 114.8676510066],
      "speed factor": 0.1969621374,
      "Uz factor": 0.8842060881,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7628808128,
      "Direction stability factor": 0.5385997605,
      score: 0.0,
    },
    "270": {
      velocity: [0.0353929214, 0.1712035455, 0.4176563233],
      direction: [-135.5179935099, -39.7269724447, 59.492980261],
      "speed factor": 0.1566567722,
      "Uz factor": 0.9828628085,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6984694037,
      "Direction stability factor": 0.4583028506,
      score: 0.0,
    },
    "315": {
      velocity: [0.0548409613, 0.1134706454, 0.2362141891],
      direction: [-28.6369522479, -0.3830193326, 34.0836757574],
      "speed factor": 0.1398071276,
      "Uz factor": 0.9585311311,
      "Directional variance factor": 0.9659538371,
      "Velocity stability factor": 0.8528635138,
      "Direction stability factor": 0.8257760333,
      score: 0.0,
    },
  },
  {
    position: [-54.65624756, 50.06039249, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0791258844, 0.6718646391, 1.0324289621],
      direction: [-10.8412666705, -1.3646760617, 63.5738948858],
      "speed factor": 0.6884939939,
      "Uz factor": 0.9891585255,
      "Directional variance factor": 0.8786954612,
      "Velocity stability factor": 0.3376156662,
      "Direction stability factor": 0.7932912179,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0257975241, 0.3772584079, 0.9347916361],
      direction: [-138.0145340606, -15.4197770661, 178.1249826137],
      "speed factor": 0.3349585545,
      "Uz factor": 0.9987698247,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4121776417,
      "Direction stability factor": 0.1218346759,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0094551281, 0.0954185001, 0.3066571914],
      direction: [-177.6952176924, 119.7281029102, 179.7586289453],
      "speed factor": 0.1478096177,
      "Uz factor": 0.8969993538,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.734433751,
      "Direction stability factor": 0.0070726482,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0151222179, 0.1135680208, 0.2923378439],
      direction: [-179.9735445332, -166.4610152471, 178.4656045955],
      "speed factor": 0.1277560894,
      "Uz factor": 0.9038436997,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7705156295,
      "Direction stability factor": 0.0043356969,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0108570017, 0.1154505525, 0.4398576961],
      direction: [-174.8756160699, -11.074940754, 178.7397016702],
      "speed factor": 0.1640137527,
      "Uz factor": 0.6412167149,
      "Directional variance factor": 0.0155608219,
      "Velocity stability factor": 0.6491669024,
      "Direction stability factor": 0.0177352285,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0800930497, 0.5287508205, 0.8195920736],
      direction: [-120.7989361982, -14.3632496507, -3.9763258861],
      "speed factor": 0.6195137692,
      "Uz factor": 0.9956432812,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4313976517,
      "Direction stability factor": 0.6754927491,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1518709559, 0.3878986785, 0.5945963784],
      direction: [-51.5920503686, -34.0598043662, 19.5418213834],
      "speed factor": 0.7195987929,
      "Uz factor": 0.7777010835,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5704023382,
      "Direction stability factor": 0.8024059118,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3398700617, 0.6404676371, 0.8051728349],
      direction: [-67.8415469693, 9.2052242305, 39.9328545138],
      "speed factor": 0.8721572384,
      "Uz factor": 0.7303409967,
      "Directional variance factor": 0.1817578462,
      "Velocity stability factor": 0.6587749976,
      "Direction stability factor": 0.7006266625,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3302030298, 0.5929985355, 0.793056301],
      direction: [-5.6612282443, 14.746524054, 22.2748166728],
      "speed factor": 0.753071336,
      "Uz factor": 0.9486710097,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6272020198,
      "Direction stability factor": 0.9223998752,
      score: 0.0,
    },
    id: "IDN:70.0",
    "45": {
      velocity: [0.0313880738, 0.1833516266, 0.600139326],
      direction: [-174.6589326147, -3.0097058472, 175.4031672232],
      "speed factor": 0.249061465,
      "Uz factor": 0.961403066,
      "Directional variance factor": 0.7324705914,
      "Velocity stability factor": 0.537623229,
      "Direction stability factor": 0.0276052782,
      score: 0.0,
    },
    "90": {
      velocity: [0.0430939963, 0.1431729077, 0.396281353],
      direction: [-178.7342134952, -138.3305935406, 175.7850203473],
      "speed factor": 0.2320874731,
      "Uz factor": 0.9763878286,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7422235476,
      "Direction stability factor": 0.0152243504,
      score: 0.0,
    },
    "135": {
      velocity: [0.0256028153, 0.1358938667, 0.4509318934],
      direction: [-170.6952789144, 13.6848114829, 176.9539498589],
      "speed factor": 0.1150138182,
      "Uz factor": 0.9235660399,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6572652313,
      "Direction stability factor": 0.0343076979,
      score: 0.0,
    },
    "180": {
      velocity: [0.0242491969, 0.1592591526, 0.5681902687],
      direction: [-179.8790137018, -1.8160179309, 169.3151520898],
      "speed factor": 0.3110321412,
      "Uz factor": 0.9808257302,
      "Directional variance factor": 0.8385761839,
      "Velocity stability factor": 0.5713978899,
      "Direction stability factor": 0.0300162061,
      score: 0.0,
    },
    "225": {
      velocity: [0.0595597844, 0.2976077222, 0.6651218739],
      direction: [-117.2484414401, -22.791326451, 14.385179201],
      "speed factor": 0.4062671097,
      "Uz factor": 0.9209794667,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4557325359,
      "Direction stability factor": 0.6343510538,
      score: 0.0,
    },
    "270": {
      velocity: [0.3123918735, 0.5509711476, 0.7449125223],
      direction: [11.2422725676, 55.6966023753, 79.9823732233],
      "speed factor": 0.5041563907,
      "Uz factor": 0.5847902438,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6588263263,
      "Direction stability factor": 0.809055276,
      score: 0.0,
    },
    "315": {
      velocity: [0.1708454909, 0.6752307232, 0.9429020728],
      direction: [-58.1384429585, 3.6888083097, 36.683031781],
      "speed factor": 0.8319514486,
      "Uz factor": 0.848249063,
      "Directional variance factor": 0.672105928,
      "Velocity stability factor": 0.3736799309,
      "Direction stability factor": 0.7366070146,
      score: 0.0,
    },
  },
  {
    position: [6.546950306, 102.3600707, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.5569112897, 1.1118589192, 1.4784446281],
      direction: [-41.2659708454, -13.7366340568, 11.9282702515],
      "speed factor": 1.139378594,
      "Uz factor": 0.8047273069,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3596902594,
      "Direction stability factor": 0.8522382192,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7279411413, 1.0318931976, 1.4368741882],
      direction: [-58.4006609259, -16.19947042, 19.83203846],
      "speed factor": 0.9161928448,
      "Uz factor": 0.8468233745,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5415518209,
      "Direction stability factor": 0.7826869462,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0710628231, 0.4160489992, 0.8571569917],
      direction: [-134.0914502489, -9.5147133743, 133.3289413841],
      "speed factor": 0.6444876357,
      "Uz factor": 0.9640673711,
      "Directional variance factor": 0.1542477001,
      "Velocity stability factor": 0.2975819974,
      "Direction stability factor": 0.2571655788,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0475814841, 0.3551226173, 0.9809918181],
      direction: [-160.5003845309, 35.7882133699, 170.6759218863],
      "speed factor": 0.3994881353,
      "Uz factor": 0.9888832573,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2273051631,
      "Direction stability factor": 0.0800658155,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0438368435, 0.2588280207, 0.8362840283],
      direction: [-177.3082950578, 49.3902158985, 178.00433474],
      "speed factor": 0.3677016184,
      "Uz factor": 0.9991523748,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3519434719,
      "Direction stability factor": 0.0130204728,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0613395427, 0.2881336951, 0.6114616671],
      direction: [-178.5276657232, -162.3859888423, 178.7498353867],
      "speed factor": 0.337593408,
      "Uz factor": 0.9873926239,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5770099464,
      "Direction stability factor": 0.0075624969,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1293757762, 0.3794838922, 0.8087227821],
      direction: [-68.7707437285, -1.2416810684, 94.6229023295],
      "speed factor": 0.7039883503,
      "Uz factor": 0.9999612927,
      "Directional variance factor": 0.8896283495,
      "Velocity stability factor": 0.3407970934,
      "Direction stability factor": 0.5461287609,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0408764012, 0.3978887514, 0.9135990387],
      direction: [-168.4826811134, 20.7188083034, 160.0635012512],
      "speed factor": 0.5418252765,
      "Uz factor": 0.8594392964,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.359997831,
      "Direction stability factor": 0.0873717157,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1794852647, 0.5759032992, 0.9652215773],
      direction: [-122.6601077972, 9.1183093225, 118.9044084685],
      "speed factor": 0.7313614469,
      "Uz factor": 0.9229493094,
      "Directional variance factor": 0.1894836158,
      "Velocity stability factor": 0.3671408877,
      "Direction stability factor": 0.3289874548,
      score: 0.0,
    },
    id: "IDN:310.0",
    "45": {
      velocity: [0.225596571, 0.582377908, 0.9811281564],
      direction: [-81.4238987159, -9.0161252665, 57.1830453094],
      "speed factor": 0.7910913997,
      "Uz factor": 0.9546775463,
      "Directional variance factor": 0.198566643,
      "Velocity stability factor": 0.3857767285,
      "Direction stability factor": 0.614980711,
      score: 0.0,
    },
    "90": {
      velocity: [0.0383938033, 0.2739256974, 0.8118611795],
      direction: [-110.7329769273, 37.1550406831, 177.0789180137],
      "speed factor": 0.4440415714,
      "Uz factor": 0.9824546282,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.43547901,
      "Direction stability factor": 0.2005225141,
      score: 0.0,
    },
    "135": {
      velocity: [0.0590922316, 0.7881204187, 1.1884083643],
      direction: [-57.3773358231, 9.7528817333, 96.8730952831],
      "speed factor": 0.6670259723,
      "Uz factor": 0.9996877531,
      "Directional variance factor": 0.1330771793,
      "Velocity stability factor": 0.0899848529,
      "Direction stability factor": 0.5715265803,
      score: 0.0,
    },
    "180": {
      velocity: [0.031172361, 0.2602377379, 0.8296430862],
      direction: [-179.552905825, 142.7361775421, 179.5462582629],
      "speed factor": 0.5082426945,
      "Uz factor": 0.998879252,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3708394982,
      "Direction stability factor": 0.002502322,
      score: 0.0,
    },
    "225": {
      velocity: [0.0212776212, 0.2688159499, 0.569386034],
      direction: [-174.7220444368, -10.1381440073, 178.1210752724],
      "speed factor": 0.3669631897,
      "Uz factor": 0.9964044696,
      "Directional variance factor": 0.0988316438,
      "Velocity stability factor": 0.5073707865,
      "Direction stability factor": 0.019880223,
      score: 0.0,
    },
    "270": {
      velocity: [0.0782147725, 0.8631854962, 1.27759745],
      direction: [-119.6857253588, 7.0976695781, 25.2997431251],
      "speed factor": 0.7898426009,
      "Uz factor": 0.9930849974,
      "Directional variance factor": 0.3690960375,
      "Velocity stability factor": 0.0539231008,
      "Direction stability factor": 0.5972625875,
      score: 0.0,
    },
    "315": {
      velocity: [0.6612565264, 0.9255447111, 1.3146143022],
      direction: [-34.1073007235, -16.3909164022, 1.3966037793],
      "speed factor": 1.1403631925,
      "Uz factor": 0.9174403142,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4699726718,
      "Direction stability factor": 0.901378043,
      score: 0.0,
    },
  },
  {
    position: [-53.93078572, 52.86568654, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0818772971, 0.2948235369, 0.4775245541],
      direction: [-74.418156062, -1.8753697001, 113.7931921554],
      "speed factor": 0.3021207288,
      "Uz factor": 0.7675521062,
      "Directional variance factor": 0.8333004711,
      "Velocity stability factor": 0.7250921025,
      "Direction stability factor": 0.4771906994,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.03735486, 0.2397762122, 0.579202477],
      direction: [-156.3598007466, -9.5623038946, 138.2910133795],
      "speed factor": 0.2128914605,
      "Uz factor": 0.9828868203,
      "Directional variance factor": 0.1500174316,
      "Velocity stability factor": 0.6496015322,
      "Direction stability factor": 0.1815255163,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0057251597, 0.055250063, 0.2853153681],
      direction: [-173.2184782088, -19.8527235954, 156.5137265332],
      "speed factor": 0.0855860308,
      "Uz factor": 0.887685828,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7501709037,
      "Direction stability factor": 0.0840772091,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0136845305, 0.1092059279, 0.3533727815],
      direction: [-179.2082347771, 179.6366655515, 178.9390610276],
      "speed factor": 0.1228490397,
      "Uz factor": 0.9796315507,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7187996016,
      "Direction stability factor": 0.0051464005,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0136692539, 0.1367615423, 0.4309521356],
      direction: [-162.0894298179, -6.6535118323, 173.0818748109],
      "speed factor": 0.194289012,
      "Uz factor": 0.9997060369,
      "Directional variance factor": 0.408576726,
      "Velocity stability factor": 0.6587496294,
      "Direction stability factor": 0.0689685983,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.022189751, 0.2632666019, 0.5110444264],
      direction: [-152.8643514626, -7.4507493728, 177.4045710424],
      "speed factor": 0.3084577433,
      "Uz factor": 0.9936785355,
      "Directional variance factor": 0.3377111669,
      "Velocity stability factor": 0.6241186163,
      "Direction stability factor": 0.0825863264,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0780092021, 0.2514266565, 0.5590241627],
      direction: [-62.8665545428, -5.4320284464, 30.8501018066],
      "speed factor": 0.4664267464,
      "Uz factor": 0.991346443,
      "Directional variance factor": 0.517153027,
      "Velocity stability factor": 0.5332481672,
      "Direction stability factor": 0.7396759546,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1082391834, 0.2685054219, 0.3592469665],
      direction: [-20.8144359305, 39.5169728812, 67.6644388445],
      "speed factor": 0.36563744,
      "Uz factor": 0.4615376756,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8159260243,
      "Direction stability factor": 0.7542253478,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1006725204, 0.249927886, 0.4356503039],
      direction: [-47.2021594128, 19.7393140056, 43.2262428581],
      "speed factor": 0.3173929037,
      "Uz factor": 0.8624268234,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7301973457,
      "Direction stability factor": 0.7488099937,
      score: 0.0,
    },
    id: "IDN:72.0",
    "45": {
      velocity: [0.0043760631, 0.1603991552, 0.7177991659],
      direction: [-170.0605037553, 2.3316718783, 170.2371765667],
      "speed factor": 0.2178832515,
      "Uz factor": 0.9999744454,
      "Directional variance factor": 0.7927402775,
      "Velocity stability factor": 0.4200095924,
      "Direction stability factor": 0.0547286658,
      score: 0.0,
    },
    "90": {
      velocity: [0.0161526635, 0.1615964047, 0.3738325979],
      direction: [-179.3431118955, -167.5824431993, 179.693830425],
      "speed factor": 0.2619525009,
      "Uz factor": 0.9785344344,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.738944606,
      "Direction stability factor": 0.0026751602,
      score: 0.0,
    },
    "135": {
      velocity: [0.008968613, 0.0751193616, 0.2380021371],
      direction: [-174.7395875713, 110.3372021819, 177.9678074262],
      "speed factor": 0.0635772961,
      "Uz factor": 0.6981535463,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8154423105,
      "Direction stability factor": 0.0202572361,
      score: 0.0,
    },
    "180": {
      velocity: [0.0119096564, 0.1674781167, 0.5023809751],
      direction: [-166.8785620585, -29.6721532431, 179.1644308053],
      "speed factor": 0.3270837273,
      "Uz factor": 0.9956055877,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6135297498,
      "Direction stability factor": 0.0387694643,
      score: 0.0,
    },
    "225": {
      velocity: [0.0265991784, 0.1325069218, 0.3434619337],
      direction: [-177.1631166139, 0.7002473287, 47.8097560022],
      "speed factor": 0.1808864493,
      "Uz factor": 0.9998221713,
      "Directional variance factor": 0.937755793,
      "Velocity stability factor": 0.7152098995,
      "Direction stability factor": 0.3750753538,
      score: 0.0,
    },
    "270": {
      velocity: [0.1301898063, 0.2762405003, 0.4043851642],
      direction: [-3.4094031708, 20.1952756962, 93.6606373011],
      "speed factor": 0.2527689774,
      "Uz factor": 0.4748120759,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7837138231,
      "Direction stability factor": 0.7303609987,
      score: 0.0,
    },
    "315": {
      velocity: [0.064575033, 0.3200229224, 0.5218050257],
      direction: [4.9003697938, 20.3730056199, 52.8901483492],
      "speed factor": 0.3943000884,
      "Uz factor": 0.9441215083,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.629078584,
      "Direction stability factor": 0.8666950596,
      score: 0.0,
    },
  },
  {
    position: [-48.9216034, 95.65235413, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.6126367021, 1.1837198354, 1.3946127742],
      direction: [-22.378848259, 0.9537299092, 28.2184616126],
      "speed factor": 1.2130181433,
      "Uz factor": 0.8696958503,
      "Directional variance factor": 0.9152240081,
      "Velocity stability factor": 0.4566589455,
      "Direction stability factor": 0.859451917,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4371432559, 1.2129496736, 1.6150948848],
      direction: [-31.0347274766, 27.9734715869, 53.5809539276],
      "speed factor": 1.0769484813,
      "Uz factor": 0.9299940989,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2382499564,
      "Direction stability factor": 0.7649564405,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1334966792, 0.6411390423, 1.2062762845],
      direction: [-24.858743168, -2.77980557, 179.7941466667],
      "speed factor": 0.9931671181,
      "Uz factor": 0.9813716369,
      "Directional variance factor": 0.7529061716,
      "Velocity stability factor": 0.0414129276,
      "Direction stability factor": 0.4315197505,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0470389061, 0.3160556544, 0.6966604541],
      direction: [-178.5186294473, 119.7182793342, 178.6790523181],
      "speed factor": 0.3555405313,
      "Uz factor": 0.9636805499,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4622309206,
      "Direction stability factor": 0.0077842173,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0939068881, 0.2828447309, 0.5140754214],
      direction: [-179.9166160542, 145.2623142045, 179.7243458924],
      "speed factor": 0.4018207341,
      "Uz factor": 0.96238077,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6563897681,
      "Direction stability factor": 0.0009973279,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0800245125, 0.9339864667, 1.3805802104],
      direction: [-179.5398869744, -12.6653541423, 17.913836584],
      "speed factor": 1.0943103139,
      "Uz factor": 0.9998964056,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.4515174346,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0846417004, 0.2992431288, 0.8513092352],
      direction: [-149.3148434227, -23.5936320962, 174.3196033407],
      "speed factor": 0.5551320646,
      "Uz factor": 0.9550176601,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2560658059,
      "Direction stability factor": 0.1010154257,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1783830664, 0.7492014338, 1.1221511382],
      direction: [-113.50505948, -24.5737286716, 24.4552196842],
      "speed factor": 1.0202255596,
      "Uz factor": 0.9733480128,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3078973926,
      "Direction stability factor": 0.6167770023,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7986258101, 0.9679805189, 1.1431896065],
      direction: [-10.8274693376, 8.9395463598, 38.3145471728],
      "speed factor": 1.229275182,
      "Uz factor": 0.8122549393,
      "Directional variance factor": 0.2053736569,
      "Velocity stability factor": 0.7224764405,
      "Direction stability factor": 0.8634943986,
      score: 0.0,
    },
    id: "IDN:339.0",
    "45": {
      velocity: [0.1437332311, 0.7019339884, 1.2531686652],
      direction: [-32.7966712261, -2.1853580376, 87.6193905271],
      "speed factor": 0.9534941724,
      "Uz factor": 0.9214237487,
      "Directional variance factor": 0.8057459522,
      "Velocity stability factor": 0.0980640981,
      "Direction stability factor": 0.6655109396,
      score: 0.0,
    },
    "90": {
      velocity: [0.0460308914, 0.4226675927, 0.9470423663],
      direction: [-178.1386986978, 12.0253490881, 167.9508157872],
      "speed factor": 0.6851565364,
      "Uz factor": 0.9966810925,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3423899889,
      "Direction stability factor": 0.0386402375,
      score: 0.0,
    },
    "135": {
      velocity: [0.0566204534, 0.3240918331, 0.7203874053],
      direction: [-171.0027923448, 137.2211732163, 178.720531966],
      "speed factor": 0.2742952282,
      "Uz factor": 0.9950223491,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4651294151,
      "Direction stability factor": 0.0285463214,
      score: 0.0,
    },
    "180": {
      velocity: [0.0558179329, 0.2621383465, 0.7199351473],
      direction: [-179.1274867581, 55.9058840823, 178.8629325531],
      "speed factor": 0.5119545713,
      "Uz factor": 0.831200297,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4767042714,
      "Direction stability factor": 0.0055821686,
      score: 0.0,
    },
    "225": {
      velocity: [0.0674423092, 0.3923951962, 0.9724165552],
      direction: [-179.1695977637, -52.2425421011, 154.5239323032],
      "speed factor": 0.5356623847,
      "Uz factor": 0.9643351808,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1866266952,
      "Direction stability factor": 0.0730735276,
      score: 0.0,
    },
    "270": {
      velocity: [0.6032323276, 1.0664199362, 1.3738095159],
      direction: [-38.6085034504, -15.3807895132, 2.8261052329],
      "speed factor": 0.9758086759,
      "Uz factor": 0.9830289749,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3921662447,
      "Direction stability factor": 0.8849038648,
      score: 0.0,
    },
    "315": {
      velocity: [0.7777449537, 0.9254049126, 1.0850138973],
      direction: [-12.0706525477, 10.2516896589, 28.2890088678],
      "speed factor": 1.1401909468,
      "Uz factor": 0.8255254125,
      "Directional variance factor": 0.088738697,
      "Velocity stability factor": 0.7507323809,
      "Direction stability factor": 0.8878898294,
      score: 0.0,
    },
  },
  {
    position: [-2.263993335, 93.64059614, 46.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0306479316, 0.409479267, 1.2100833955],
      direction: [-175.9665642983, 5.228977372, 170.7854512824],
      "speed factor": 0.4196143085,
      "Uz factor": 0.9884703932,
      "Directional variance factor": 0.5352020114,
      "Velocity stability factor": 0.1804919209,
      "Direction stability factor": 0.0367999567,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0633991841, 0.3156465171, 0.8249614228],
      direction: [-166.6039730965, 14.5339884103, 174.1000066426],
      "speed factor": 0.2802548569,
      "Uz factor": 0.9786411411,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5075179199,
      "Direction stability factor": 0.0536000563,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1279318363, 0.4065085587, 0.929579392],
      direction: [-146.4382565525, 12.6882723475, 95.7292139401],
      "speed factor": 0.6297088574,
      "Uz factor": 0.9956177338,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2836841979,
      "Direction stability factor": 0.327312582,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4697181587, 0.8716795458, 1.2575846908],
      direction: [-19.0482298065, 13.5287826644, 47.8041289929],
      "speed factor": 0.9805785927,
      "Uz factor": 0.9514010918,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.347789092,
      "Direction stability factor": 0.8142990033,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4549134169, 0.8093798035, 1.1301684505],
      direction: [-24.3587591847, -1.4932616823, 51.5215325101],
      "speed factor": 1.1498378839,
      "Uz factor": 0.9440868324,
      "Directional variance factor": 0.8672656282,
      "Velocity stability factor": 0.4477822105,
      "Direction stability factor": 0.789221412,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4879297837, 0.8147026064, 1.0992764112],
      direction: [-69.6919912997, -24.8167665605, 1.965589518],
      "speed factor": 0.9545507314,
      "Uz factor": 0.9040990118,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5299342977,
      "Direction stability factor": 0.8009511644,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1762045403, 0.5098294768, 0.8229012881],
      direction: [-73.6962414898, -2.7283891562, 36.195643379],
      "speed factor": 0.9457951171,
      "Uz factor": 0.9906144283,
      "Directional variance factor": 0.7574765194,
      "Velocity stability factor": 0.3724792011,
      "Direction stability factor": 0.6947447643,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0402230364, 0.3700311492, 1.0571894921],
      direction: [-173.4593329703, 6.0264551932, 177.6410979775],
      "speed factor": 0.5038901679,
      "Uz factor": 0.9566363299,
      "Directional variance factor": 0.4643150939,
      "Velocity stability factor": 0.2542181107,
      "Direction stability factor": 0.0247210251,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0742112508, 0.4712439903, 1.1084307199],
      direction: [-140.6852249782, -21.6825056878, 129.9477176424],
      "speed factor": 0.5984506203,
      "Uz factor": 0.9522727787,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1670039877,
      "Direction stability factor": 0.2482418261,
      score: 0.0,
    },
    id: "IDN:408.0",
    "45": {
      velocity: [0.1185698652, 0.5170691602, 1.1501245299],
      direction: [-154.0911046698, 4.3507950657, 150.9253941921],
      "speed factor": 0.7023772022,
      "Uz factor": 0.9997924013,
      "Directional variance factor": 0.6132626608,
      "Velocity stability factor": 0.1613786993,
      "Direction stability factor": 0.1527319476,
      score: 0.0,
    },
    "90": {
      velocity: [0.3074148145, 0.7738297962, 1.1663148341],
      direction: [0.0013269905, 21.8156775221, 58.7754852463],
      "speed factor": 1.2544007444,
      "Uz factor": 0.9799313975,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3731253517,
      "Direction stability factor": 0.8367384493,
      score: 0.0,
    },
    "135": {
      velocity: [0.7636548209, 1.1565989877, 1.3582535902],
      direction: [-3.4831955553, 4.3787161283, 26.3186246861],
      "speed factor": 0.9788879289,
      "Uz factor": 0.9329808995,
      "Directional variance factor": 0.6107807886,
      "Velocity stability factor": 0.5208658844,
      "Direction stability factor": 0.917217166,
      score: 0.0,
    },
    "180": {
      velocity: [0.456541979, 0.7817241871, 1.1743265101],
      direction: [-44.9575772177, -3.5402821108, 42.3109960445],
      "speed factor": 1.5267025082,
      "Uz factor": 0.9301369216,
      "Directional variance factor": 0.6853082568,
      "Velocity stability factor": 0.43441674,
      "Direction stability factor": 0.7575872965,
      score: 0.0,
    },
    "225": {
      velocity: [0.2107669479, 0.480262613, 0.8073618143],
      direction: [-82.2805085069, -17.1330273328, 26.1363793524],
      "speed factor": 0.6556110246,
      "Uz factor": 0.9632334193,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4637921021,
      "Direction stability factor": 0.6988419782,
      score: 0.0,
    },
    "270": {
      velocity: [0.3980580087, 0.8719929454, 1.4175533997],
      direction: [-37.8086540613, 13.0306322547, 41.4956152783],
      "speed factor": 0.7979017013,
      "Uz factor": 0.9963177218,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1958187689,
      "Direction stability factor": 0.7797103629,
      score: 0.0,
    },
    "315": {
      velocity: [0.1002420257, 0.664507744, 0.9808714072],
      direction: [-155.5747791648, -2.3727526466, 63.6470958463],
      "speed factor": 0.8187396711,
      "Uz factor": 0.9620082318,
      "Directional variance factor": 0.7890886536,
      "Velocity stability factor": 0.2856017707,
      "Direction stability factor": 0.3910503472,
      score: 0.0,
    },
  },
  {
    position: [0.735988388, 93.63012419, 46.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0300905039, 0.3137620634, 1.1002494865],
      direction: [-171.4983977352, -9.8080611129, 175.7225943998],
      "speed factor": 0.3215280037,
      "Uz factor": 0.9895597735,
      "Directional variance factor": 0.1281723455,
      "Velocity stability factor": 0.2564205851,
      "Direction stability factor": 0.0354972441,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0335874277, 0.2940951627, 0.9029918999],
      direction: [-177.1962190099, 9.0766589522, 174.9979153069],
      "speed factor": 0.2611199341,
      "Uz factor": 0.9760030383,
      "Directional variance factor": 0.1931858709,
      "Velocity stability factor": 0.4377792108,
      "Direction stability factor": 0.0216829602,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0848436597, 0.3879333946, 0.8691803087],
      direction: [-47.7033452741, 12.8156414989, 106.9973753802],
      "speed factor": 0.6009346899,
      "Uz factor": 0.998356404,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2991524369,
      "Direction stability factor": 0.570275776,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4378696486, 0.8859147603, 1.2260586762],
      direction: [-5.2048125057, 14.3432486515, 50.7672088128],
      "speed factor": 0.9965922146,
      "Uz factor": 0.9625911806,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.347522124,
      "Direction stability factor": 0.844522163,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4709299037, 0.8294422292, 1.1490209294],
      direction: [-22.8130889003, 2.0841840689, 43.3629455781],
      "speed factor": 1.1783393821,
      "Uz factor": 0.9406135517,
      "Directional variance factor": 0.8147391939,
      "Velocity stability factor": 0.4454629604,
      "Direction stability factor": 0.816177682,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2678863008, 0.814773212, 1.0831340788],
      direction: [-74.7120296166, -21.2399994446, 7.7201130779],
      "speed factor": 0.9546334569,
      "Uz factor": 0.8925993574,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.373154276,
      "Direction stability factor": 0.7710218258,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1547813361, 0.5188298337, 0.8097356801],
      direction: [-60.7467451269, -5.4848333387, 23.0103243684],
      "speed factor": 0.9624918639,
      "Uz factor": 0.9900326665,
      "Directional variance factor": 0.5124592588,
      "Velocity stability factor": 0.3644664604,
      "Direction stability factor": 0.7673414736,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0785745216, 0.3112462301, 0.827437572],
      direction: [-175.1001375559, -8.8649391922, 136.6505379801],
      "speed factor": 0.4238397646,
      "Uz factor": 0.9645910919,
      "Directional variance factor": 0.2120054051,
      "Velocity stability factor": 0.4508289851,
      "Direction stability factor": 0.1340259013,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.043488334, 0.3670935539, 1.2850542644],
      direction: [-173.9367993858, -2.1581750548, 178.8389696269],
      "speed factor": 0.46618603,
      "Uz factor": 0.9999360009,
      "Directional variance factor": 0.8081622174,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.0200673083,
      score: 0.0,
    },
    id: "IDN:409.0",
    "45": {
      velocity: [0.1021648713, 0.551894301, 1.1196029041],
      direction: [-177.0486504094, 2.4630338175, 125.4828290245],
      "speed factor": 0.7496830307,
      "Uz factor": 0.9995128957,
      "Directional variance factor": 0.7810636607,
      "Velocity stability factor": 0.1728550743,
      "Direction stability factor": 0.1596347794,
      score: 0.0,
    },
    "90": {
      velocity: [0.3909871854, 0.8065565174, 1.1901323088],
      direction: [-15.024350198, 18.9259598008, 51.6091490147],
      "speed factor": 1.3074517171,
      "Uz factor": 0.985400485,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4167379128,
      "Direction stability factor": 0.8149069466,
      score: 0.0,
    },
    "135": {
      velocity: [0.6454992501, 1.1563911773, 1.3728594511],
      direction: [-1.6389834628, 6.2299303501, 29.9837415085],
      "speed factor": 0.9787120485,
      "Uz factor": 0.9314029372,
      "Directional variance factor": 0.4462284133,
      "Velocity stability factor": 0.4138852876,
      "Direction stability factor": 0.9121590973,
      score: 0.0,
    },
    "180": {
      velocity: [0.4035126732, 0.7594948666, 1.1571992725],
      direction: [-43.823474477, 0.0883248967, 30.03699969],
      "speed factor": 1.4832887827,
      "Uz factor": 0.9153165869,
      "Directional variance factor": 0.9921488981,
      "Velocity stability factor": 0.4061274583,
      "Direction stability factor": 0.7948320162,
      score: 0.0,
    },
    "225": {
      velocity: [0.1822954587, 0.47689922, 0.7867626255],
      direction: [-80.2272017694, -15.9160035887, 33.0117510499],
      "speed factor": 0.6510196249,
      "Uz factor": 0.9618351208,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4567166312,
      "Direction stability factor": 0.6854473533,
      score: 0.0,
    },
    "270": {
      velocity: [0.0858322623, 0.819498095, 1.3535755911],
      direction: [-33.8292778862, 8.9012318622, 76.8946012048],
      "speed factor": 0.7498672182,
      "Uz factor": 0.9990317812,
      "Directional variance factor": 0.20877939,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.6924336692,
      score: 0.0,
    },
    "315": {
      velocity: [0.0213702592, 0.2568515172, 0.6532098688],
      direction: [-174.0767792188, -31.8138133658, 176.5688715393],
      "speed factor": 0.3164666305,
      "Uz factor": 0.9326793033,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4874289823,
      "Direction stability factor": 0.0259843034,
      score: 0.0,
    },
  },
  {
    position: [3.735970111, 93.61965223, 46.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0561988865, 0.3043013756, 1.0619857836],
      direction: [-178.4872861036, -30.591612675, 177.353471705],
      "speed factor": 0.311833154,
      "Uz factor": 0.9876771627,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3011482923,
      "Direction stability factor": 0.0115534505,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0269487507, 0.364232669, 0.9411155446],
      direction: [-179.2004488985, -7.3900518973, 177.7758098293],
      "speed factor": 0.3233933182,
      "Uz factor": 0.9967109884,
      "Directional variance factor": 0.343106498,
      "Velocity stability factor": 0.4088326057,
      "Direction stability factor": 0.0083992813,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0810205048, 0.3823997469, 0.7676843221],
      direction: [-27.3312585192, 11.1507416003, 104.5587528419],
      "speed factor": 0.5923627007,
      "Uz factor": 0.9982746075,
      "Directional variance factor": 0.0088229689,
      "Velocity stability factor": 0.3864284378,
      "Direction stability factor": 0.6336388573,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5043516665, 0.911560116, 1.2554247404],
      direction: [-12.8037729522, 12.7069716118, 50.7645636108],
      "speed factor": 1.0254414481,
      "Uz factor": 0.9713390306,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3782474168,
      "Direction stability factor": 0.8234212873,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4282749991, 0.8452059063, 1.1511143528],
      direction: [-21.0282310992, 5.8601829952, 47.51941762],
      "speed factor": 1.2007339034,
      "Uz factor": 0.9370153968,
      "Directional variance factor": 0.4790948449,
      "Velocity stability factor": 0.4088681607,
      "Direction stability factor": 0.8095898647,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3433931576, 0.8191462496, 1.0956492126],
      direction: [-66.2836306323, -16.6441029604, 34.2435671809],
      "speed factor": 0.9597571501,
      "Uz factor": 0.8886513081,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4215887437,
      "Direction stability factor": 0.7207577839,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1761076153, 0.5288847734, 0.8398063856],
      direction: [-47.7399256066, -5.6036353041, 26.9899328903],
      "speed factor": 0.9811449886,
      "Uz factor": 0.988571465,
      "Directional variance factor": 0.5018990841,
      "Velocity stability factor": 0.3559813252,
      "Direction stability factor": 0.7924170597,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.032013432, 0.2975994327, 0.6650235133],
      direction: [-154.0390806243, -15.3190642883, 148.9521491937],
      "speed factor": 0.4052562289,
      "Uz factor": 0.9791957464,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5357885684,
      "Direction stability factor": 0.158357695,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0615553083, 0.3869371556, 0.8996204755],
      direction: [-179.9225751678, 6.5600391716, 92.6700122547],
      "speed factor": 0.4913861727,
      "Uz factor": 0.9989687376,
      "Directional variance factor": 0.416885407,
      "Velocity stability factor": 0.3249934244,
      "Direction stability factor": 0.2427983683,
      score: 0.0,
    },
    id: "IDN:410.0",
    "45": {
      velocity: [0.0915330334, 0.5691826673, 1.0407610444],
      direction: [-42.6484774972, 2.1570663416, 158.911893724],
      "speed factor": 0.7731672284,
      "Uz factor": 0.9997040111,
      "Directional variance factor": 0.8082607696,
      "Velocity stability factor": 0.2283076636,
      "Direction stability factor": 0.4401100799,
      score: 0.0,
    },
    "90": {
      velocity: [0.3327654557, 0.8396963351, 1.1933074789],
      direction: [-20.977162024, 15.2487282301, 49.5687441938],
      "speed factor": 1.3611723314,
      "Uz factor": 0.9898569649,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.371926923,
      "Direction stability factor": 0.8040391494,
      score: 0.0,
    },
    "135": {
      velocity: [0.5670971783, 1.1516012187, 1.3615068265],
      direction: [0.6520061202, 8.1527464312, 29.5923542638],
      "speed factor": 0.9746580655,
      "Uz factor": 0.9320642291,
      "Directional variance factor": 0.2753114283,
      "Velocity stability factor": 0.3598561183,
      "Direction stability factor": 0.919610144,
      score: 0.0,
    },
    "180": {
      velocity: [0.3824709121, 0.7507303969, 1.1140554927],
      direction: [-36.1721738627, 2.6079317709, 35.338721769],
      "speed factor": 1.4661718274,
      "Uz factor": 0.9120456667,
      "Directional variance factor": 0.7681838426,
      "Velocity stability factor": 0.423542896,
      "Direction stability factor": 0.8013586232,
      score: 0.0,
    },
    "225": {
      velocity: [0.1439660496, 0.4770224237, 0.734296617],
      direction: [-78.2917976198, -13.0756740674, 47.2280440052],
      "speed factor": 0.6511878115,
      "Uz factor": 0.964108541,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4694223326,
      "Direction stability factor": 0.6513337733,
      score: 0.0,
    },
    "270": {
      velocity: [0.1890842813, 0.7630809338, 1.2346727526],
      direction: [-49.0507796381, 5.1821159464, 41.0654799302],
      "speed factor": 0.698243694,
      "Uz factor": 0.9998963212,
      "Directional variance factor": 0.5393674714,
      "Velocity stability factor": 0.1752364635,
      "Direction stability factor": 0.7496770568,
      score: 0.0,
    },
    "315": {
      velocity: [0.0455890964, 0.2965608568, 0.7417443114],
      direction: [-152.3258281308, -49.6919202934, 118.4989927748],
      "speed factor": 0.3653924888,
      "Uz factor": 0.9822996195,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4352538498,
      "Direction stability factor": 0.2477088308,
      score: 0.0,
    },
  },
  {
    position: [-37.47491052, 89.78847435, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0605552491, 0.3206107892, 0.8928702979],
      direction: [-177.4830611879, -70.2631207732, 176.2874255826],
      "speed factor": 0.3285462427,
      "Uz factor": 0.4783282758,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4216818744,
      "Direction stability factor": 0.0173042034,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0398208199, 0.6542387705, 1.35862583],
      direction: [-152.2965942956, -0.2990082417, 26.6479376425],
      "speed factor": 0.5808826744,
      "Uz factor": 0.9994874711,
      "Directional variance factor": 0.9734214896,
      "Velocity stability factor": 0.1471638145,
      "Direction stability factor": 0.5029318557,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0498221925, 0.425799496, 0.9356824698],
      direction: [-170.6995874572, -8.6517306618, 58.8978850108],
      "speed factor": 0.6595918053,
      "Uz factor": 0.9962256207,
      "Directional variance factor": 0.2309572745,
      "Velocity stability factor": 0.2084355393,
      "Direction stability factor": 0.3622292431,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2217469745, 0.6899390886, 1.1493484785],
      direction: [-7.4756931633, 50.8904516186, 137.6841384943],
      "speed factor": 0.7761332749,
      "Uz factor": 0.8484791564,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2321138231,
      "Direction stability factor": 0.5967782454,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3913164647, 0.8334685984, 1.5399054689],
      direction: [-31.2763897737, 20.6248469855, 58.9351736382],
      "speed factor": 1.1840594061,
      "Uz factor": 0.8449838101,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0606937389,
      "Direction stability factor": 0.7494123239,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.177449488, 0.6123192265, 1.1420608457],
      direction: [-87.7847920225, -19.4388234674, 124.7904594538],
      "speed factor": 0.7174271457,
      "Uz factor": 0.6931895556,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2583083068,
      "Direction stability factor": 0.4095131903,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0524727726, 0.3019553529, 0.6070140546],
      direction: [-137.8859003597, -21.5175160719, 89.8902407015],
      "speed factor": 0.5601635672,
      "Uz factor": 0.9705236599,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4619020592,
      "Direction stability factor": 0.3672884971,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0522195336, 0.3636000102, 0.8681713803],
      direction: [-168.656521594, -3.6199717914, 169.4212596687],
      "speed factor": 0.49513256,
      "Uz factor": 0.9974691123,
      "Directional variance factor": 0.6782247297,
      "Velocity stability factor": 0.4016301065,
      "Direction stability factor": 0.060895052,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0321433659, 0.180018987, 0.4065538346],
      direction: [-179.0377811077, -160.5870908639, 179.7949696149],
      "speed factor": 0.2286129408,
      "Uz factor": 0.9790129313,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6984369018,
      "Direction stability factor": 0.0032423591,
      score: 0.0,
    },
    id: "IDN:365.0",
    "45": {
      velocity: [0.0769410186, 0.4018293962, 0.7261828596],
      direction: [-135.3941553959, 1.3968673193, 124.0812664576],
      "speed factor": 0.5458376341,
      "Uz factor": 0.9997189749,
      "Directional variance factor": 0.8758340161,
      "Velocity stability factor": 0.4721869274,
      "Direction stability factor": 0.2792349393,
      score: 0.0,
    },
    "90": {
      velocity: [0.1422373538, 0.7377180945, 1.1184012419],
      direction: [-131.3305356241, 45.2479921347, 160.2602017475],
      "speed factor": 1.1958626192,
      "Uz factor": 0.866741497,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2875394341,
      "Direction stability factor": 0.1900257295,
      score: 0.0,
    },
    "135": {
      velocity: [0.6059275586, 1.0377725791, 1.2697195266],
      direction: [-34.3238333048, 14.5738414374, 68.5780926247],
      "speed factor": 0.8783191594,
      "Uz factor": 0.8768350606,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4651092569,
      "Direction stability factor": 0.7141613169,
      score: 0.0,
    },
    "180": {
      velocity: [0.5314697333, 0.9047585017, 1.2260930333],
      direction: [-22.7974706827, 3.1322661278, 25.4252804265],
      "speed factor": 1.7669877645,
      "Uz factor": 0.8473060499,
      "Directional variance factor": 0.7215763442,
      "Velocity stability factor": 0.4526667914,
      "Direction stability factor": 0.8660479136,
      score: 0.0,
    },
    "225": {
      velocity: [0.080679101, 0.4371258215, 0.8109252071],
      direction: [-179.4021609021, -6.2135081195, 40.3676512912],
      "speed factor": 0.5967245834,
      "Uz factor": 0.9728517495,
      "Directional variance factor": 0.4476881672,
      "Velocity stability factor": 0.3436689594,
      "Direction stability factor": 0.3895282995,
      score: 0.0,
    },
    "270": {
      velocity: [0.0442219969, 0.3420610447, 0.6616137608],
      direction: [-145.3106951584, -79.3309678397, 61.2937672791],
      "speed factor": 0.3129969009,
      "Uz factor": 0.8512237301,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5129993983,
      "Direction stability factor": 0.4260987155,
      score: 0.0,
    },
    "315": {
      velocity: [0.0989715843, 0.1895081241, 0.3671700187],
      direction: [-177.8989359932, -121.2386055469, 174.4455367106],
      "speed factor": 0.2334928683,
      "Uz factor": 0.9916046558,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7824277832,
      "Direction stability factor": 0.0212653536,
      score: 0.0,
    },
  },
  {
    position: [-39.07192354, 89.64835169, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0679674487, 0.2986305994, 1.0496209777],
      direction: [-178.8135774308, 169.1298949028, 179.459520124],
      "speed factor": 0.3060220201,
      "Uz factor": 0.8266468513,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3179168997,
      "Direction stability factor": 0.0047969512,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0623862489, 0.5451818293, 1.3535683751],
      direction: [-168.8948376066, 4.0568512169, 171.6441203568],
      "speed factor": 0.4840536717,
      "Uz factor": 0.9982499818,
      "Directional variance factor": 0.6393910029,
      "Velocity stability factor": 0.1650268002,
      "Direction stability factor": 0.0540584501,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0551418849, 0.3693313912, 0.9036265239],
      direction: [-154.19545391, 11.785171873, 174.4754364],
      "speed factor": 0.5721189465,
      "Uz factor": 0.994538848,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2418327101,
      "Direction stability factor": 0.0870253047,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1779974925, 0.6592135081, 1.1683137607],
      direction: [-5.8912103396, 51.9708187356, 156.7327913653],
      "speed factor": 0.7415691434,
      "Uz factor": 0.7975396123,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1801973478,
      "Direction stability factor": 0.5482666619,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4042920005, 0.8126730078, 1.4892286075],
      direction: [-20.0034947262, 14.471030456, 47.2828878244],
      "speed factor": 1.1545163439,
      "Uz factor": 0.8211349699,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1127481248,
      "Direction stability factor": 0.8130933818,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1589860414, 0.578520027, 0.9183968531],
      direction: [-97.9741496001, -13.7789279096, 45.1761816057],
      "speed factor": 0.6778261301,
      "Uz factor": 0.7335694874,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4160874364,
      "Direction stability factor": 0.6023601911,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0781024486, 0.3079426285, 0.6048258254],
      direction: [-109.3944959301, -31.4330544879, 48.2387956993],
      "speed factor": 0.571270685,
      "Uz factor": 0.9802407273,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4888951038,
      "Direction stability factor": 0.5621297455,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0256386861, 0.321150114, 0.786118985],
      direction: [-160.6745123697, -21.6091300182, 178.2745504905],
      "speed factor": 0.4373263851,
      "Uz factor": 0.9984961014,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4423095955,
      "Direction stability factor": 0.0584748254,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0281365, 0.1833513277, 0.3838792702],
      direction: [-179.0728217944, -170.8592706177, 179.7258466343],
      "speed factor": 0.2328448066,
      "Uz factor": 0.9585569143,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7134725096,
      "Direction stability factor": 0.0033370321,
      score: 0.0,
    },
    id: "IDN:364.0",
    "45": {
      velocity: [0.0334991829, 0.3715913764, 0.7361476835],
      direction: [-38.1150830273, 13.4281431213, 177.3472860521],
      "speed factor": 0.5047628663,
      "Uz factor": 0.9997380415,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4287690031,
      "Direction stability factor": 0.4014934192,
      score: 0.0,
    },
    "90": {
      velocity: [0.1778881582, 0.720364831, 1.0841370199],
      direction: [-177.8272698586, 52.3839055252, 147.4323577702],
      "speed factor": 1.1677324712,
      "Uz factor": 0.8721330076,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3385674427,
      "Direction stability factor": 0.0965010344,
      score: 0.0,
    },
    "135": {
      velocity: [0.5076227423, 1.0331707086, 1.2887990831],
      direction: [-27.6577279364, 14.7285985244, 64.7210812612],
      "speed factor": 0.8744243648,
      "Uz factor": 0.8278593403,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3705196605,
      "Direction stability factor": 0.7433921967,
      score: 0.0,
    },
    "180": {
      velocity: [0.4142639958, 0.8518939175, 1.2590428871],
      direction: [-29.8138116839, -5.6014867834, 27.8034546833],
      "speed factor": 1.6637435582,
      "Uz factor": 0.7866864467,
      "Directional variance factor": 0.5020900637,
      "Velocity stability factor": 0.33435066,
      "Direction stability factor": 0.8399520379,
      score: 0.0,
    },
    "225": {
      velocity: [0.2028813844, 0.4917415674, 0.8994674779],
      direction: [-91.6381504866, -16.2124093096, 15.3795183156],
      "speed factor": 0.6712810534,
      "Uz factor": 0.9907147155,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.373921926,
      "Direction stability factor": 0.7027286978,
      score: 0.0,
    },
    "270": {
      velocity: [0.0543731895, 0.326226932, 0.6482706888],
      direction: [-141.059873965, -80.5137157118, 133.465663527],
      "speed factor": 0.2985081765,
      "Uz factor": 0.8832035303,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5315317496,
      "Direction stability factor": 0.2374290625,
      score: 0.0,
    },
    "315": {
      velocity: [0.051196497, 0.1910546204, 0.4640383749],
      direction: [-164.8988373938, -119.7804185083, 173.8391443162],
      "speed factor": 0.2353983057,
      "Uz factor": 0.9848532674,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6650878192,
      "Direction stability factor": 0.0590611619,
      score: 0.0,
    },
  },
  {
    position: [-40.58147966, 89.23484332, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0853573368, 0.3054271017, 0.8910711956],
      direction: [-178.2023049574, 154.7939963923, 179.9025841713],
      "speed factor": 0.3129867429,
      "Uz factor": 0.8797160003,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4401652003,
      "Direction stability factor": 0.0052641969,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0521953332, 0.4667180939, 1.1268238302],
      direction: [-152.053641942, 17.6600593352, 166.3931206476],
      "speed factor": 0.4143876315,
      "Uz factor": 0.9999571061,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3050662826,
      "Direction stability factor": 0.1154256595,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0162647934, 0.380599525, 0.8762720817],
      direction: [-174.9648028077, 24.5857794223, 132.7838582891],
      "speed factor": 0.5895740369,
      "Uz factor": 0.9796688582,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2315365946,
      "Direction stability factor": 0.1451426081,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1394298273, 0.6592048531, 1.20782587],
      direction: [-32.1115354661, 47.660420955, 147.1077205981],
      "speed factor": 0.7415594072,
      "Uz factor": 0.7719098515,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1155614247,
      "Direction stability factor": 0.5021687332,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.430613914, 0.8094397705, 1.4634378963],
      direction: [-22.4494391851, 6.8010123988, 38.4010751801],
      "speed factor": 1.1499230754,
      "Uz factor": 0.8075395978,
      "Directional variance factor": 0.3954655646,
      "Velocity stability factor": 0.1553653834,
      "Direction stability factor": 0.8309707934,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2549471108, 0.5965838142, 0.9504862641],
      direction: [-82.8403133847, -19.3557858467, 34.0056079241],
      "speed factor": 0.6989906645,
      "Uz factor": 0.7730814028,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4651984883,
      "Direction stability factor": 0.6754279964,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0865569176, 0.3250187817, 0.5879447077],
      direction: [-99.1494301356, -37.2985483245, 127.0298409716],
      "speed factor": 0.6029490069,
      "Uz factor": 0.9920952556,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5134794359,
      "Direction stability factor": 0.3717242469,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0428524083, 0.2908867964, 0.7539208898],
      direction: [-172.9752281504, -48.1268139065, 176.8204251774],
      "speed factor": 0.3961152919,
      "Uz factor": 0.9999763733,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4785452435,
      "Direction stability factor": 0.0283454074,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0536625861, 0.1803650025, 0.3710936067],
      direction: [-179.6931329091, -178.7123243281, 179.4342721678],
      "speed factor": 0.2290523589,
      "Uz factor": 0.9433327091,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7443301135,
      "Direction stability factor": 0.0024238748,
      score: 0.0,
    },
    id: "IDN:363.0",
    "45": {
      velocity: [0.0611748311, 0.3741455805, 0.6649616434],
      direction: [-37.4870289483, 23.2825425649, 158.9874458733],
      "speed factor": 0.50823245,
      "Uz factor": 0.9999016185,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5091404273,
      "Direction stability factor": 0.4542375699,
      score: 0.0,
    },
    "90": {
      velocity: [0.0628618071, 0.7167396013, 1.0689945563],
      direction: [-130.1888077126, 52.9493283596, 135.4529728542],
      "speed factor": 1.1618558678,
      "Uz factor": 0.79161304,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2656664351,
      "Direction stability factor": 0.2621061651,
      score: 0.0,
    },
    "135": {
      velocity: [0.4625703281, 1.0218106274, 1.2887958439],
      direction: [-32.2300893014, 6.4153532288, 50.9505625996],
      "speed factor": 0.8648097564,
      "Uz factor": 0.7909072092,
      "Directional variance factor": 0.4297463797,
      "Velocity stability factor": 0.3342185482,
      "Direction stability factor": 0.7689426336,
      score: 0.0,
    },
    "180": {
      velocity: [0.2311546054, 0.8404274197, 1.3145655475],
      direction: [-36.656410041, -12.9169374371, 17.620009532],
      "speed factor": 1.6413495589,
      "Uz factor": 0.786930698,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1463188938,
      "Direction stability factor": 0.8492321679,
      score: 0.0,
    },
    "225": {
      velocity: [0.2386099944, 0.5169831375, 0.8765632223],
      direction: [-59.9555796106, -19.4713346246, 18.6627562068],
      "speed factor": 0.705738559,
      "Uz factor": 0.992975401,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4266200088,
      "Direction stability factor": 0.7816157338,
      score: 0.0,
    },
    "270": {
      velocity: [0.0434126529, 0.3367508974, 0.7399167906],
      direction: [-150.2182459739, -88.4387482553, -24.2409703949],
      "speed factor": 0.3081379447,
      "Uz factor": 0.8785975004,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4505953044,
      "Direction stability factor": 0.6500631234,
      score: 0.0,
    },
    "315": {
      velocity: [0.0565660451, 0.2080750624, 0.5444524895],
      direction: [-167.3489431277, -92.2639600597, 7.7977218547],
      "speed factor": 0.2563691841,
      "Uz factor": 0.9949889302,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6042089676,
      "Direction stability factor": 0.5134814862,
      score: 0.0,
    },
  },
  {
    position: [-41.75495903, 88.69744659, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0501575818, 0.2978842011, 0.6578834681],
      direction: [-179.4557714012, 150.0796221835, 177.5118822837],
      "speed factor": 0.3052571477,
      "Uz factor": 0.8890314588,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5777333402,
      "Direction stability factor": 0.0084231842,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1147993043, 0.4898598301, 1.0394636534],
      direction: [-174.6161232271, 43.6204668036, 176.6202168997],
      "speed factor": 0.4349346156,
      "Uz factor": 0.9856511684,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4020441155,
      "Direction stability factor": 0.0243434996,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0366589925, 0.409898511, 0.8560580092],
      direction: [-114.5851869036, 30.4150694918, 171.3580314163],
      "speed factor": 0.6349601194,
      "Uz factor": 0.9576803445,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2678222995,
      "Direction stability factor": 0.2057132824,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1476433674, 0.6886308342, 1.2129745678],
      direction: [-41.4924474575, 42.1536238883, 118.7532989968],
      "speed factor": 0.7746615801,
      "Uz factor": 0.7636839205,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1180985595,
      "Direction stability factor": 0.5548729265,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3788136081, 0.7988867096, 1.4698916014],
      direction: [-29.0302230004, 0.6103588733, 49.6297666032],
      "speed factor": 1.1349309677,
      "Uz factor": 0.8035179551,
      "Directional variance factor": 0.9457458779,
      "Velocity stability factor": 0.1077257516,
      "Direction stability factor": 0.7815000289,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3230589909, 0.6169062966, 0.9870392897],
      direction: [-68.0360756016, -28.1922727477, 24.8209149892],
      "speed factor": 0.7228016114,
      "Uz factor": 0.8071508509,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4894641576,
      "Direction stability factor": 0.742063915,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1204303616, 0.3449865062, 0.5974358082],
      direction: [-157.7628554996, -39.0463052684, 11.2476900015],
      "speed factor": 0.6399915421,
      "Uz factor": 0.993623756,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5371387905,
      "Direction stability factor": 0.5305262625,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0445639791, 0.278745491, 0.6977383871],
      direction: [-179.4238832211, -94.8826623453, 170.0395871803],
      "speed factor": 0.379581861,
      "Uz factor": 0.9884441428,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5210012667,
      "Direction stability factor": 0.0292681378,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0471181525, 0.168611776, 0.3693446115],
      direction: [-179.6057677396, 172.9936548679, 179.7237275767],
      "speed factor": 0.2141264908,
      "Uz factor": 0.941460883,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.740467702,
      "Direction stability factor": 0.001862513,
      score: 0.0,
    },
    id: "IDN:362.0",
    "45": {
      velocity: [0.0755749512, 0.3785798283, 0.7162149938],
      direction: [-39.3201028774, 30.2025227177, 110.3844895361],
      "speed factor": 0.5142558502,
      "Uz factor": 0.9980685342,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4791799172,
      "Direction stability factor": 0.58415391,
      score: 0.0,
    },
    "90": {
      velocity: [0.1368985979, 0.7495565264, 1.1831276594],
      direction: [16.1308058623, 50.4988772846, 123.8095773119],
      "speed factor": 1.2150530638,
      "Uz factor": 0.8023400735,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2364018396,
      "Direction stability factor": 0.7008923015,
      score: 0.0,
    },
    "135": {
      velocity: [0.4744381974, 1.0274305718, 1.309631962],
      direction: [-36.8397898791, 2.2605985132, 44.9167333025],
      "speed factor": 0.8695661981,
      "Uz factor": 0.7978888454,
      "Directional variance factor": 0.7990579099,
      "Velocity stability factor": 0.3269918364,
      "Direction stability factor": 0.7728985467,
      score: 0.0,
    },
    "180": {
      velocity: [0.2540792405, 0.8258367322, 1.2739081217],
      direction: [-44.0785830019, -21.4171706122, 5.4155535786],
      "speed factor": 1.6128540362,
      "Uz factor": 0.7853308818,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1964188161,
      "Direction stability factor": 0.8625162873,
      score: 0.0,
    },
    "225": {
      velocity: [0.2197520343, 0.5002896597, 0.8325232891],
      direction: [-65.9362736282, -21.7652870118, 30.0077036217],
      "speed factor": 0.682950135,
      "Uz factor": 0.9893822741,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4492530779,
      "Direction stability factor": 0.7334889521,
      score: 0.0,
    },
    "270": {
      velocity: [0.0587787447, 0.324078632, 0.6511345241],
      direction: [-153.6564130329, -93.8758101218, -45.9255994663],
      "speed factor": 0.2965424126,
      "Uz factor": 0.8817325016,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5327478631,
      "Direction stability factor": 0.7007477401,
      score: 0.0,
    },
    "315": {
      velocity: [0.0214414255, 0.2343082918, 0.64133779],
      direction: [-174.4364671279, -45.0099826733, 141.729593994],
      "speed factor": 0.2886911334,
      "Uz factor": 0.9996692215,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4971177723,
      "Direction stability factor": 0.1217609413,
      score: 0.0,
    },
  },
  {
    position: [-42.79727393, 88.02484617, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0451540238, 0.3052199129, 0.6605809729],
      direction: [-178.1831515914, 139.6612218623, 179.8374649628],
      "speed factor": 0.312774426,
      "Uz factor": 0.9021368523,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5723824047,
      "Direction stability factor": 0.0054982873,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0398809051, 0.5363775904, 1.0158916411],
      direction: [-21.9413535741, 59.8902065686, 176.2017822291],
      "speed factor": 0.4762366024,
      "Uz factor": 0.9435075543,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3688397703,
      "Direction stability factor": 0.4496024005,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1024103579, 0.4301798087, 0.8548291739],
      direction: [-163.8691370713, 29.0497508613, 71.9758548699],
      "speed factor": 0.6663772016,
      "Uz factor": 0.9636385933,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.327672761,
      "Direction stability factor": 0.3448750224,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.082227871, 0.7243447009, 1.1924956253],
      direction: [-49.1989298338, 35.1179011287, 85.4031399703],
      "speed factor": 0.8148371851,
      "Uz factor": 0.7784122483,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.080899225,
      "Direction stability factor": 0.6261053617,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3770587525, 0.7890930264, 1.3733956075],
      direction: [-35.4978953721, -3.364660178, 62.5301093285],
      "speed factor": 1.1210176629,
      "Uz factor": 0.8033134693,
      "Directional variance factor": 0.7009190953,
      "Velocity stability factor": 0.1852042439,
      "Direction stability factor": 0.7276999869,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3290111623, 0.6597429528, 0.957528812],
      direction: [-69.1838954002, -36.6932440806, 14.7957960089],
      "speed factor": 0.7729914122,
      "Uz factor": 0.831024108,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5167314628,
      "Direction stability factor": 0.7667230794,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1342423162, 0.3588231713, 0.6306106794],
      direction: [-98.3547460875, -38.2751235325, 7.7595625302],
      "speed factor": 0.6656602233,
      "Uz factor": 0.9943755338,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5183500259,
      "Direction stability factor": 0.7052380316,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0437387689, 0.2562658448, 0.6852081228],
      direction: [-178.3184044983, -125.8105407153, 179.9808204515],
      "speed factor": 0.3489701876,
      "Uz factor": 0.9362172072,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5295850478,
      "Direction stability factor": 0.0047243751,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0364719923, 0.160596764, 0.360347294],
      direction: [-179.7247340119, 166.1226341123, 178.8456616573],
      "speed factor": 0.203947923,
      "Uz factor": 0.943053583,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7391396673,
      "Direction stability factor": 0.0039711231,
      score: 0.0,
    },
    id: "IDN:361.0",
    "45": {
      velocity: [0.0755193088, 0.3826437409, 0.7197652807],
      direction: [-44.6847685274, 34.4708489094, 112.7382130698],
      "speed factor": 0.5197761942,
      "Uz factor": 0.995864481,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4762484108,
      "Direction stability factor": 0.56271394,
      score: 0.0,
    },
    "90": {
      velocity: [0.2412581036, 0.7646284603, 1.2085645807],
      direction: [-116.2531110065, 46.9843007288, 119.8678607264],
      "speed factor": 1.2394851096,
      "Uz factor": 0.8074780937,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2940040823,
      "Direction stability factor": 0.3441084119,
      score: 0.0,
    },
    "135": {
      velocity: [0.4334697498, 1.0078631323, 1.309279532],
      direction: [-32.3954526608, -1.5368297597, 30.3684595084],
      "speed factor": 0.8530052894,
      "Uz factor": 0.7908340618,
      "Directional variance factor": 0.8633929103,
      "Velocity stability factor": 0.2942630104,
      "Direction stability factor": 0.8256557995,
      score: 0.0,
    },
    "180": {
      velocity: [0.265456094, 0.841701851, 1.2564188901],
      direction: [-54.4970599318, -27.3087269304, 11.3161776871],
      "speed factor": 1.6438385153,
      "Uz factor": 0.8113072605,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2191640464,
      "Direction stability factor": 0.8171854511,
      score: 0.0,
    },
    "225": {
      velocity: [0.1464829166, 0.4903688825, 0.8806442894],
      direction: [-83.6520266102, -21.175268779, 11.253807406],
      "speed factor": 0.6694071883,
      "Uz factor": 0.9942205005,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3401499936,
      "Direction stability factor": 0.7363726833,
      score: 0.0,
    },
    "270": {
      velocity: [0.0904116259, 0.3087483069, 0.594383081],
      direction: [-161.4959020377, -100.135377402, -45.3927461996],
      "speed factor": 0.2825146702,
      "Uz factor": 0.8564972947,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6024657014,
      "Direction stability factor": 0.6774912338,
      score: 0.0,
    },
    "315": {
      velocity: [0.019955918, 0.2743040162, 0.6749521633],
      direction: [-179.3175561576, -21.2761268671, 19.9712191582],
      "speed factor": 0.3379698462,
      "Uz factor": 0.9938010542,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4686434865,
      "Direction stability factor": 0.4464200686,
      score: 0.0,
    },
  },
  {
    position: [-44.01360092, 86.92164496, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0701121235, 0.3318225983, 0.6381693318],
      direction: [-160.9679364011, 127.8119179018, 178.4484053579],
      "speed factor": 0.3400355558,
      "Uz factor": 0.9209059831,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6052963593,
      "Direction stability factor": 0.0571768284,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0788163565, 0.5644847468, 0.9487150648],
      direction: [-46.6217243683, 64.966479109, 162.1319884834],
      "speed factor": 0.5011922622,
      "Uz factor": 0.9086076514,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4374596013,
      "Direction stability factor": 0.4201285754,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1059518782, 0.4562549898, 0.8556675259],
      direction: [-49.7210137109, 25.6933734631, 74.2075421367],
      "speed factor": 0.7067693955,
      "Uz factor": 0.9568926167,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3300881893,
      "Direction stability factor": 0.6557540115,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0831966715, 0.7442117458, 1.1973229132],
      direction: [-44.2952208776, 28.3065279084, 122.8092420922],
      "speed factor": 0.8371862228,
      "Uz factor": 0.7985919729,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0777050957,
      "Direction stability factor": 0.5358209362,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4654009159, 0.8069176165, 1.3516147101],
      direction: [-42.1782656453, -10.49610065, 24.4610030486],
      "speed factor": 1.1463400009,
      "Uz factor": 0.803487087,
      "Directional variance factor": 0.0670132756,
      "Velocity stability factor": 0.275261941,
      "Direction stability factor": 0.8148909203,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3533389009, 0.6967151071, 1.1321926194],
      direction: [-82.1720510458, -46.8089578454, 16.0642310325],
      "speed factor": 0.8163100374,
      "Uz factor": 0.8746405324,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4011377446,
      "Direction stability factor": 0.7271214387,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.140459517, 0.3485381699, 0.5937812314],
      direction: [-98.3313005255, -32.733091312, 8.802911872],
      "speed factor": 0.6465803062,
      "Uz factor": 0.9967970541,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5601202491,
      "Direction stability factor": 0.7024049656,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0317894074, 0.2144819503, 0.4753087585],
      direction: [-176.6196525697, -153.7139540273, 179.8757973257],
      "speed factor": 0.292070941,
      "Uz factor": 0.9033841863,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6747496462,
      "Direction stability factor": 0.0097348614,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0149039894, 0.1483957461, 0.3171385856],
      direction: [-179.7233032389, 156.2063999371, 179.0480314964],
      "speed factor": 0.1884533875,
      "Uz factor": 0.9489788751,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7565698376,
      "Direction stability factor": 0.0034129591,
      score: 0.0,
    },
    id: "IDN:360.0",
    "45": {
      velocity: [0.0978855468, 0.3880074096, 0.6992206477],
      direction: [-45.1971500186, 38.2361696645, 111.5104817873],
      "speed factor": 0.5270621028,
      "Uz factor": 0.9940522309,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5111335911,
      "Direction stability factor": 0.5647010228,
      score: 0.0,
    },
    "90": {
      velocity: [0.2390123522, 0.8318170218, 1.2329091908],
      direction: [2.2809456104, 37.2701518372, 130.4126711255],
      "speed factor": 1.348399734,
      "Uz factor": 0.793747792,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2745969067,
      "Direction stability factor": 0.6440785402,
      score: 0.0,
    },
    "135": {
      velocity: [0.4038145369, 1.022925465, 1.3371917274],
      direction: [-39.0617493535, -4.366663751, 22.1079441127],
      "speed factor": 0.8657532996,
      "Uz factor": 0.8173715412,
      "Directional variance factor": 0.611852111,
      "Velocity stability factor": 0.2478745705,
      "Direction stability factor": 0.8300841848,
      score: 0.0,
    },
    "180": {
      velocity: [0.4798321025, 0.880293477, 1.2872204348],
      direction: [-63.2301655588, -34.7019640031, -12.781915743],
      "speed factor": 1.7192077224,
      "Uz factor": 0.8151823832,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3638128083,
      "Direction stability factor": 0.8598659727,
      score: 0.0,
    },
    "225": {
      velocity: [0.1151532794, 0.5260986293, 0.8935431291],
      direction: [-102.2762194826, -17.1211777625, 93.1460162683],
      "speed factor": 0.7181822028,
      "Uz factor": 0.9927960526,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3003982962,
      "Direction stability factor": 0.4571604562,
      score: 0.0,
    },
    "270": {
      velocity: [0.0307351219, 0.2762787998, 0.5681139293],
      direction: [-172.3560248003, -116.5095237258, 149.4129796321],
      "speed factor": 0.2528040228,
      "Uz factor": 0.8108338738,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5761138748,
      "Direction stability factor": 0.1061972099,
      score: 0.0,
    },
    "315": {
      velocity: [0.0206009776, 0.3153780198, 0.6904294303],
      direction: [-174.1055602268, -2.5914009332, 174.1135255334],
      "speed factor": 0.3885771063,
      "Uz factor": 0.9926416251,
      "Directional variance factor": 0.7696532504,
      "Velocity stability factor": 0.4566110663,
      "Direction stability factor": 0.0327247618,
      score: 0.0,
    },
  },
  {
    position: [-44.96703638, 85.67180679, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0957413722, 0.3647327708, 0.7130786387],
      direction: [-178.221826444, 119.721501264, 174.651700388],
      "speed factor": 0.3737602896,
      "Uz factor": 0.9221452715,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5710550573,
      "Direction stability factor": 0.0197957588,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1588928865, 0.5990314851, 1.0278176145],
      direction: [-26.5614999769, 63.3162437413, 136.2391708579],
      "speed factor": 0.5318654699,
      "Uz factor": 0.9285795512,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4380894486,
      "Direction stability factor": 0.5477759143,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1270306332, 0.4557275085, 0.8250973197],
      direction: [-51.9930498737, 20.141946186, 101.89330554],
      "speed factor": 0.7059522918,
      "Uz factor": 0.9433311218,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3762393525,
      "Direction stability factor": 0.5725379016,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1928427067, 0.7744395063, 1.2089227899],
      direction: [-86.6885781288, 19.4347036969, 56.479625819],
      "speed factor": 0.8711903416,
      "Uz factor": 0.7779072029,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1588695715,
      "Direction stability factor": 0.6023105446,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.445483859, 0.8192056585, 1.3397373337],
      direction: [-53.9961981204, -17.2708795231, 17.1260477262],
      "speed factor": 1.1637968933,
      "Uz factor": 0.8188091437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.268687159,
      "Direction stability factor": 0.802438206,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3482606968, 0.7026598766, 1.0543263001],
      direction: [-96.4460456183, -49.6816774295, 14.9706444851],
      "speed factor": 0.8232752588,
      "Uz factor": 0.9429263298,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4571046788,
      "Direction stability factor": 0.6905091942,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0747411808, 0.3316517635, 0.5996733718],
      direction: [-145.1429582297, -23.1092607306, 9.2361986686],
      "speed factor": 0.6152539875,
      "Uz factor": 0.9996043639,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4906331771,
      "Direction stability factor": 0.5711690086,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0482614758, 0.1758587117, 0.4443169618],
      direction: [-179.3894169768, -179.7445624172, 179.2021732589],
      "speed factor": 0.2394757197,
      "Uz factor": 0.88335059,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7095567833,
      "Direction stability factor": 0.0039122493,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0248197824, 0.1568718023, 0.3311067435],
      direction: [-179.2166530547, 149.9655134498, 176.5075570704],
      "speed factor": 0.1992174528,
      "Uz factor": 0.9555685781,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7533059231,
      "Direction stability factor": 0.0118771941,
      score: 0.0,
    },
    id: "IDN:359.0",
    "45": {
      velocity: [0.0893037079, 0.3742558777, 0.6397954753],
      direction: [-148.7835629904, 38.898031481, 113.7938463161],
      "speed factor": 0.5083822756,
      "Uz factor": 0.9756477569,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5524676124,
      "Direction stability factor": 0.2706183075,
      score: 0.0,
    },
    "90": {
      velocity: [0.3483660611, 0.8355924877, 1.2280447984],
      direction: [4.6227058634, 31.0516496778, 102.1003189432],
      "speed factor": 1.3545198747,
      "Uz factor": 0.8249014181,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3579598481,
      "Direction stability factor": 0.7292288526,
      score: 0.0,
    },
    "135": {
      velocity: [0.3865595701, 1.0340382064, 1.3464219375],
      direction: [-45.216473599, -10.5663433032, 12.5449069746],
      "speed factor": 0.8751585719,
      "Uz factor": 0.7484539467,
      "Directional variance factor": 0.0607694842,
      "Velocity stability factor": 0.2265325286,
      "Direction stability factor": 0.8395517206,
      score: 0.0,
    },
    "180": {
      velocity: [0.4416960451, 0.8776488686, 1.3461177223],
      direction: [-70.4036654457, -39.2189790914, -15.6461385803],
      "speed factor": 1.7140428186,
      "Uz factor": 0.8253791142,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2873547165,
      "Direction stability factor": 0.8478957587,
      score: 0.0,
    },
    "225": {
      velocity: [0.0497142553, 0.5581791349, 0.9230221313],
      direction: [-166.9501839332, -10.2196204936, 11.7219745608],
      "speed factor": 0.7619756037,
      "Uz factor": 0.9998590823,
      "Directional variance factor": 0.0915892895,
      "Velocity stability factor": 0.2150878146,
      "Direction stability factor": 0.5036884486,
      score: 0.0,
    },
    "270": {
      velocity: [0.0181839716, 0.225660798, 0.7012205026],
      direction: [-178.436435514, -142.9481082199, 179.7769121635],
      "speed factor": 0.2064869167,
      "Uz factor": 0.7481461584,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4612185956,
      "Direction stability factor": 0.0049629231,
      score: 0.0,
    },
    "315": {
      velocity: [0.022789635, 0.338343125, 0.6766564587],
      direction: [-160.044523822, 6.2477148935, 164.3358467622],
      "speed factor": 0.4168724013,
      "Uz factor": 0.9920157184,
      "Directional variance factor": 0.444647565,
      "Velocity stability factor": 0.4695597139,
      "Direction stability factor": 0.098943415,
      score: 0.0,
    },
  },
  {
    position: [-45.69008694, 84.25610757, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1072335735, 0.3821674696, 0.7821747304],
      direction: [-179.7100657646, 114.0935017219, 178.3618091106],
      "speed factor": 0.3916265155,
      "Uz factor": 0.921897193,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5310301005,
      "Direction stability factor": 0.0053559031,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1784399474, 0.6046124432, 1.0762979896],
      direction: [-138.7267171228, 58.7656552272, 116.8277683049],
      "speed factor": 0.5368206667,
      "Uz factor": 0.9592160967,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4193790424,
      "Direction stability factor": 0.2901264294,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0639665468, 0.4527869432, 0.8754032381],
      direction: [-52.9725112115, 16.7719545201, 146.5775508709],
      "speed factor": 0.7013971601,
      "Uz factor": 0.934784536,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2749370714,
      "Direction stability factor": 0.445694272,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2469980978, 0.788643724, 1.2217301303],
      direction: [-48.8570270952, 9.9370260411, 39.0099879417],
      "speed factor": 0.8871690942,
      "Uz factor": 0.827345395,
      "Directional variance factor": 0.1167087963,
      "Velocity stability factor": 0.1930982747,
      "Direction stability factor": 0.7559249582,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4497597867, 0.8406070502, 1.3234594123],
      direction: [-62.5238003668, -21.5043280117, 12.8367107057],
      "speed factor": 1.1942005813,
      "Uz factor": 0.8700764162,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.285495921,
      "Direction stability factor": 0.790665247,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2342977582, 0.6873632511, 1.0252180784],
      direction: [-101.0013291776, -43.1331560324, 21.2324305554],
      "speed factor": 0.8053528846,
      "Uz factor": 0.979330462,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3918597092,
      "Direction stability factor": 0.6604617785,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0481692161, 0.3224235594, 0.6131404287],
      direction: [-158.2354245011, -14.3294278304, 172.5100664002],
      "speed factor": 0.5981345569,
      "Uz factor": 0.9994083679,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4517813985,
      "Direction stability factor": 0.0812625253,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0246680983, 0.1670694466, 0.361626876],
      direction: [-179.8056083346, 116.9008208505, 179.6010547283],
      "speed factor": 0.227506932,
      "Uz factor": 0.8778518333,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7528947465,
      "Direction stability factor": 0.0016481582,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0286835826, 0.1630460582, 0.3721118257],
      direction: [-178.5083549482, 137.9361307351, 179.289896564],
      "speed factor": 0.2070583745,
      "Uz factor": 0.9646227701,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7233910542,
      "Direction stability factor": 0.006115968,
      score: 0.0,
    },
    id: "IDN:358.0",
    "45": {
      velocity: [0.0583914896, 0.3565999482, 0.6822375332],
      direction: [-29.6624119886, 33.8785966597, 125.0042459323],
      "speed factor": 0.4843987869,
      "Uz factor": 0.9521771264,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.492832907,
      "Direction stability factor": 0.5703703947,
      score: 0.0,
    },
    "90": {
      velocity: [0.3303407498, 0.8483079462, 1.2344307212],
      direction: [-12.3394520992, 21.7211148187, 64.0508949316],
      "speed factor": 1.3751320049,
      "Uz factor": 0.8153676664,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3401431251,
      "Direction stability factor": 0.7878045916,
      score: 0.0,
    },
    "135": {
      velocity: [0.4214719627, 1.0377183861, 1.3389288234],
      direction: [-57.4242649417, -19.2525960485, 3.246098352],
      "speed factor": 0.8782732931,
      "Uz factor": 0.7857305443,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2607033443,
      "Direction stability factor": 0.8314712131,
      score: 0.0,
    },
    "180": {
      velocity: [0.4729845909, 0.8831382411, 1.350670551],
      direction: [-75.1187100062, -43.0623301583, -24.257688608],
      "speed factor": 1.7247635292,
      "Uz factor": 0.8517275644,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3084213088,
      "Direction stability factor": 0.858719385,
      score: 0.0,
    },
    "225": {
      velocity: [0.0509354139, 0.6096655397, 0.946413657],
      direction: [-154.2466767729, -2.8633350134, 32.1191102307],
      "speed factor": 0.8322601807,
      "Uz factor": 0.9989756831,
      "Directional variance factor": 0.7454813321,
      "Velocity stability factor": 0.1951615185,
      "Direction stability factor": 0.4823172583,
      score: 0.0,
    },
    "270": {
      velocity: [0.0290406507, 0.1752898515, 0.4226019456],
      direction: [-178.3514477947, 147.8246547437, 179.211757882],
      "speed factor": 0.1603958741,
      "Uz factor": 0.7474497519,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6895575895,
      "Direction stability factor": 0.0067688731,
      score: 0.0,
    },
    "315": {
      velocity: [0.0250104036, 0.3313459487, 0.7196746467],
      direction: [-176.3568314583, 14.0861248833, 161.5664590766],
      "speed factor": 0.4082511837,
      "Uz factor": 0.9954840229,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4364633798,
      "Direction stability factor": 0.061324193,
      score: 0.0,
    },
  },
  {
    position: [-46.153284, 82.7039409, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0842545417, 0.3969926731, 0.8586050597],
      direction: [-120.9361799245, 105.4587666957, 170.424146898],
      "speed factor": 0.4068186583,
      "Uz factor": 0.9499709861,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4619574152,
      "Direction stability factor": 0.1906657588,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0758748631, 0.5831891573, 1.0761870237],
      direction: [-87.9139255309, 52.5527358788, 103.4662238491],
      "speed factor": 0.5177994528,
      "Uz factor": 0.9527185446,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3531246843,
      "Direction stability factor": 0.4683884739,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1387390821, 0.4390092611, 0.9580757974],
      direction: [-107.2405622968, 11.3937614242, 82.0467124182],
      "speed factor": 0.680054612,
      "Uz factor": 0.9170669511,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2678779693,
      "Direction stability factor": 0.4742020147,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2456313251, 0.8248730625, 1.2830795667],
      direction: [-42.9343606119, 2.8446252189, 33.0938621409],
      "speed factor": 0.9279245689,
      "Uz factor": 0.8578088218,
      "Directional variance factor": 0.747144425,
      "Velocity stability factor": 0.1411806033,
      "Direction stability factor": 0.7888104924,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4565189989, 0.839828581, 1.2568011534],
      direction: [-66.6549666519, -25.012498172, 13.3095139099],
      "speed factor": 1.1930946562,
      "Uz factor": 0.8658548631,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3455361007,
      "Direction stability factor": 0.7778764429,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2684626702, 0.6588434798, 0.9965143683],
      direction: [-93.1528049259, -35.0604341498, 28.0035847242],
      "speed factor": 0.7719375397,
      "Uz factor": 0.9922303797,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4401995245,
      "Direction stability factor": 0.6634544732,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0329356225, 0.3230359032, 0.6712659574],
      direction: [-174.7747576169, -10.4978126451, 179.3797158373],
      "speed factor": 0.5992705283,
      "Uz factor": 0.9964692416,
      "Directional variance factor": 0.0668610982,
      "Velocity stability factor": 0.3805975319,
      "Direction stability factor": 0.0162375737,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0272131538, 0.1705358841, 0.3953518635],
      direction: [-159.4741099963, 49.9045717984, 179.3992919271],
      "speed factor": 0.2322273556,
      "Uz factor": 0.9800412906,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7300292641,
      "Direction stability factor": 0.0586849947,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0402235944, 0.1805783951, 0.3484761334],
      direction: [-175.0181002716, 117.7532333745, 179.5866321141],
      "speed factor": 0.2293233542,
      "Uz factor": 0.9918985108,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7517227789,
      "Direction stability factor": 0.0149868545,
      score: 0.0,
    },
    id: "IDN:357.0",
    "45": {
      velocity: [0.0255604854, 0.3347026284, 0.733598503],
      direction: [-176.4339995825, 25.1798593605, 116.1857979408],
      "speed factor": 0.4546538719,
      "Uz factor": 0.9153129933,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4243874962,
      "Direction stability factor": 0.1871672291,
      score: 0.0,
    },
    "90": {
      velocity: [0.2152536487, 0.8512637311, 1.226920803],
      direction: [-56.8548288853, 7.7694330372, 39.4367947502],
      "speed factor": 1.3799234187,
      "Uz factor": 0.8555792324,
      "Directional variance factor": 0.30938373,
      "Velocity stability factor": 0.2616271079,
      "Direction stability factor": 0.7325232677,
      score: 0.0,
    },
    "135": {
      velocity: [0.3718419051, 1.0392144196, 1.3478227669],
      direction: [-69.5342753932, -25.4126517294, -3.7269743599],
      "speed factor": 0.8795394616,
      "Uz factor": 0.7992159541,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2135440717,
      "Direction stability factor": 0.8172019416,
      score: 0.0,
    },
    "180": {
      velocity: [0.4175926262, 0.8813109174, 1.3353713376],
      direction: [-71.9894156312, -45.9683874202, -23.8036464411],
      "speed factor": 1.7211947773,
      "Uz factor": 0.8980044685,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2768299496,
      "Direction stability factor": 0.8661506411,
      score: 0.0,
    },
    "225": {
      velocity: [0.0867430605, 0.6279578805, 1.0374416015],
      direction: [-168.7879793163, -1.3562019523, 45.1223079492],
      "speed factor": 0.8572312278,
      "Uz factor": 0.9998500919,
      "Directional variance factor": 0.8794487154,
      "Velocity stability factor": 0.1455305855,
      "Direction stability factor": 0.4058047576,
      score: 0.0,
    },
    "270": {
      velocity: [0.0478671882, 0.1800107962, 0.3913680128],
      direction: [-178.0842948199, 64.1632936613, 142.3041952112],
      "speed factor": 0.164715691,
      "Uz factor": 0.9943307083,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7290454489,
      "Direction stability factor": 0.1100319721,
      score: 0.0,
    },
    "315": {
      velocity: [0.0180131117, 0.3123200224, 0.7565233856],
      direction: [-3.5916536913, 20.1378195233, 177.8769622501],
      "speed factor": 0.3848093491,
      "Uz factor": 0.9978576998,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4008939026,
      "Direction stability factor": 0.4959205113,
      score: 0.0,
    },
  },
  {
    position: [-46.31176664, 81.4640645, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0841245839, 0.3834975291, 0.963653601],
      direction: [-177.9726523963, 103.3175411411, 175.7701482349],
      "speed factor": 0.3929894953,
      "Uz factor": 0.9500392746,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3888761553,
      "Direction stability factor": 0.0173811094,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1550419856, 0.5867054063, 1.0628660097],
      direction: [-92.7222906541, 47.3467976304, 97.7873643435],
      "speed factor": 0.5209214447,
      "Uz factor": 0.9494799135,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4129343065,
      "Direction stability factor": 0.4708065139,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0855988599, 0.428719071, 1.0453755705],
      direction: [-85.6304911094, 5.2235855481, 95.0544994838],
      "speed factor": 0.6641144216,
      "Uz factor": 0.9093708,
      "Directional variance factor": 0.5356812846,
      "Velocity stability factor": 0.1423871757,
      "Direction stability factor": 0.4980972484,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2734812509, 0.8349991679, 1.2625519116],
      direction: [-42.2894081751, -3.357317648, 32.5430955556],
      "speed factor": 0.9393157301,
      "Uz factor": 0.8820372604,
      "Directional variance factor": 0.7015717646,
      "Velocity stability factor": 0.1812284854,
      "Direction stability factor": 0.7921319341,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4578178746, 0.8312479721, 1.1897604834],
      direction: [-67.2937081975, -25.469034455, 41.2566989211],
      "speed factor": 1.1809046941,
      "Uz factor": 0.9177811964,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4014235965,
      "Direction stability factor": 0.6984710913,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1752963784, 0.6605465345, 0.9893076385],
      direction: [-98.3557371673, -24.2025209803, 47.3013325537],
      "speed factor": 0.773932933,
      "Uz factor": 0.9945486819,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3741050372,
      "Direction stability factor": 0.5953970286,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0340046882, 0.3195823379, 0.7029749404],
      direction: [-173.9882070742, -9.9521225628, 178.3796599562],
      "speed factor": 0.5928637484,
      "Uz factor": 0.9998692244,
      "Directional variance factor": 0.1153668833,
      "Velocity stability factor": 0.350866154,
      "Direction stability factor": 0.0212003694,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0432560204, 0.1896986314, 0.4776433133],
      direction: [-178.2667648216, 35.2143641381, 171.9063412312],
      "speed factor": 0.2583222397,
      "Uz factor": 0.9610293096,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6814465471,
      "Direction stability factor": 0.0272969276,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0445828651, 0.2008746106, 0.5577116998],
      direction: [-176.1153921833, 94.634679288, 179.3151209047],
      "speed factor": 0.2550982883,
      "Uz factor": 0.992782663,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5867083478,
      "Direction stability factor": 0.0126930192,
      score: 0.0,
    },
    id: "IDN:356.0",
    "45": {
      velocity: [0.0751857138, 0.3332363724, 0.7264779084],
      direction: [-151.7136712021, 20.1931817713, 93.0993735859],
      "speed factor": 0.4526621369,
      "Uz factor": 0.9099082277,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4705200547,
      "Direction stability factor": 0.3199637645,
      score: 0.0,
    },
    "90": {
      velocity: [0.3391669486, 0.908789351, 1.2860334101],
      direction: [-15.7469123354, 3.6238295998, 35.7133526659],
      "speed factor": 1.473174132,
      "Uz factor": 0.9189308336,
      "Directional variance factor": 0.6778818134,
      "Velocity stability factor": 0.3089223815,
      "Direction stability factor": 0.8570548194,
      score: 0.0,
    },
    "135": {
      velocity: [0.3428006788, 1.0179063153, 1.3056579105],
      direction: [-67.2837654009, -31.0565512185, -2.9504670198],
      "speed factor": 0.8615053406,
      "Uz factor": 0.860759134,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2241192346,
      "Direction stability factor": 0.8212963934,
      score: 0.0,
    },
    "180": {
      velocity: [0.5531960765, 0.8821882438, 1.3068673912],
      direction: [-78.1519388113, -46.122362871, -19.7657900207],
      "speed factor": 1.7229081904,
      "Uz factor": 0.9113342828,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4061395019,
      "Direction stability factor": 0.8378162534,
      score: 0.0,
    },
    "225": {
      velocity: [0.0789404345, 0.6428537832, 1.0095494565],
      direction: [-26.6013083587, -0.3555568158, 25.309187814],
      "speed factor": 0.877565765,
      "Uz factor": 0.9972243012,
      "Directional variance factor": 0.9683949497,
      "Velocity stability factor": 0.1635866556,
      "Direction stability factor": 0.8558041773,
      score: 0.0,
    },
    "270": {
      velocity: [0.0617604525, 0.2340016309, 0.8335198922],
      direction: [-132.4418715933, 33.6558434778, 104.172157969],
      "speed factor": 0.2141190481,
      "Uz factor": 0.9964212378,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.391233681,
      "Direction stability factor": 0.3427388068,
      score: 0.0,
    },
    "315": {
      velocity: [0.0553444016, 0.3034933125, 0.7251445196],
      direction: [-7.6296309198, 25.9099538766, 162.8317507773],
      "speed factor": 0.3739339641,
      "Uz factor": 0.9999776297,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4566340525,
      "Direction stability factor": 0.526496162,
      score: 0.0,
    },
  },
  {
    position: [-46.31567545, 80.34427683, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0971776762, 0.3517421799, 0.9320424627],
      direction: [-167.5097385843, 99.0086516518, 168.8182393136],
      "speed factor": 0.3604481679,
      "Uz factor": 0.9550201053,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.419910238,
      "Direction stability factor": 0.065755617,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.197794703, 0.5891337862, 1.0926022579],
      direction: [-76.5019695938, 41.6266403404, 125.5224524425],
      "speed factor": 0.5230775441,
      "Uz factor": 0.9458224891,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4213517116,
      "Direction stability factor": 0.4388210499,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1102111532, 0.4488159617, 1.0635877158],
      direction: [-71.7837264907, -4.8062516954, 58.8662650512],
      "speed factor": 0.695245845,
      "Uz factor": 0.9416953477,
      "Directional variance factor": 0.5727776271,
      "Velocity stability factor": 0.1481060569,
      "Direction stability factor": 0.6370833568,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.338072537, 0.8192788426, 1.19670959],
      direction: [-41.6524094197, -6.7628100263, 42.739467905],
      "speed factor": 0.9216314624,
      "Uz factor": 0.9258036745,
      "Directional variance factor": 0.398861331,
      "Velocity stability factor": 0.289203908,
      "Direction stability factor": 0.7655781185,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3621892259, 0.8270277961, 1.1382341647],
      direction: [-68.1241648615, -19.3876488943, 31.4713427848],
      "speed factor": 1.1749093402,
      "Uz factor": 0.9251545795,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3653570884,
      "Direction stability factor": 0.7233458121,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2969943496, 0.6786112767, 0.996591958],
      direction: [-113.8157167222, -15.5243926192, 44.1862380685],
      "speed factor": 0.7950985862,
      "Uz factor": 0.9834333444,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4620779337,
      "Direction stability factor": 0.5611056811,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1342517678, 0.4397508774, 0.7592029428],
      direction: [-38.6855406069, -10.3942327447, 11.6004307209],
      "speed factor": 0.8157908703,
      "Uz factor": 0.9900558479,
      "Directional variance factor": 0.0760682005,
      "Velocity stability factor": 0.3935799709,
      "Direction stability factor": 0.8603167463,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0550146565, 0.2475817608, 0.7346943461],
      direction: [-176.7993327591, 11.4107884486, 175.969613369],
      "speed factor": 0.3371446304,
      "Uz factor": 0.9459540962,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5015638911,
      "Direction stability factor": 0.0200862608,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0497538813, 0.23017269, 0.6318811296],
      direction: [-176.1419683531, 53.7554223758, 172.7279742108],
      "speed factor": 0.2923050308,
      "Uz factor": 0.9865567855,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.531134647,
      "Direction stability factor": 0.0309168262,
      score: 0.0,
    },
    id: "IDN:355.0",
    "45": {
      velocity: [0.0601115609, 0.3358889988, 0.7043261722],
      direction: [-138.3135366566, 13.3548733524, 102.7074041795],
      "speed factor": 0.4562654156,
      "Uz factor": 0.9211498111,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.476273906,
      "Direction stability factor": 0.3304973866,
      score: 0.0,
    },
    "90": {
      velocity: [0.3719113645, 0.9357343266, 1.2671103151],
      direction: [-21.6069817192, 2.3438293793, 29.5592927668],
      "speed factor": 1.5168527259,
      "Uz factor": 0.9472944403,
      "Directional variance factor": 0.7916596107,
      "Velocity stability factor": 0.3466323036,
      "Direction stability factor": 0.8578714598,
      score: 0.0,
    },
    "135": {
      velocity: [0.3911890362, 0.9897262211, 1.2512308137],
      direction: [-76.2252785059, -30.2569763192, -1.7245956987],
      "speed factor": 0.8376551087,
      "Uz factor": 0.8950639023,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.306969039,
      "Direction stability factor": 0.7930536589,
      score: 0.0,
    },
    "180": {
      velocity: [0.5372277428, 0.8407852688, 1.2743429353],
      direction: [-77.1699756824, -38.0477765387, -13.8003977291],
      "speed factor": 1.6420484359,
      "Uz factor": 0.9363962981,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4191850123,
      "Direction stability factor": 0.8239733946,
      score: 0.0,
    },
    "225": {
      velocity: [0.3471525963, 0.7251344014, 1.0022843],
      direction: [-23.6330628415, 2.9212528138, 22.8914574137],
      "speed factor": 0.9898878133,
      "Uz factor": 0.988777135,
      "Directional variance factor": 0.7403330832,
      "Velocity stability factor": 0.4111803278,
      "Direction stability factor": 0.8707652215,
      score: 0.0,
    },
    "270": {
      velocity: [0.060273975, 0.3278904088, 1.2215437396],
      direction: [-80.8871752234, 8.8604814355, 72.4485800526],
      "speed factor": 0.3000303115,
      "Uz factor": 0.970240693,
      "Directional variance factor": 0.2124016502,
      "Velocity stability factor": 0.0839866887,
      "Direction stability factor": 0.5740673465,
      score: 0.0,
    },
    "315": {
      velocity: [0.0548793007, 0.2976655054, 0.7082654931],
      direction: [-6.8570069404, 30.2958295568, 169.8161725001],
      "speed factor": 0.3667535257,
      "Uz factor": 0.9998099188,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4699496193,
      "Direction stability factor": 0.5092411682,
      score: 0.0,
    },
  },
  {
    position: [-50.4827199, 95.04948189, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.6772946237, 1.2207046583, 1.4034532239],
      direction: [-16.4406581915, 5.1279716072, 27.606060963],
      "speed factor": 1.2509183794,
      "Uz factor": 0.892880005,
      "Directional variance factor": 0.5441803016,
      "Velocity stability factor": 0.4954426437,
      "Direction stability factor": 0.8776480023,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3851732213, 1.0742720228, 1.4572786552],
      direction: [-34.6867605061, 28.2448900028, 63.8832638312],
      "speed factor": 0.9538199718,
      "Uz factor": 0.9572573567,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3066978806,
      "Direction stability factor": 0.7261943768,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0879948512, 0.5737408888, 1.2071207357],
      direction: [-23.4007451982, 2.6284847206, 172.3534180903],
      "speed factor": 0.8887628852,
      "Uz factor": 0.9904152299,
      "Directional variance factor": 0.7663569137,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.4562384353,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0514668522, 0.2996131193, 0.6027938167],
      direction: [-172.9292775324, 129.4131381176, 178.1780543498],
      "speed factor": 0.3370438279,
      "Uz factor": 0.999578664,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5436010473,
      "Direction stability factor": 0.0247018559,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0686968234, 0.2942157786, 0.823368038],
      direction: [-175.2356454148, 86.4742428227, 173.8609192659],
      "speed factor": 0.4179749073,
      "Uz factor": 0.9997831331,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3828363371,
      "Direction stability factor": 0.0302873203,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.223041074, 0.9457363233, 1.4506686474],
      direction: [-136.9258250065, -18.0354247326, 2.1739213921],
      "speed factor": 1.1080770972,
      "Uz factor": 0.999958638,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0560745876,
      "Direction stability factor": 0.6136118156,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0795804812, 0.3677020743, 0.8685366886],
      direction: [-117.7569336127, -12.6281875609, 151.337267801],
      "speed factor": 0.6821316583,
      "Uz factor": 0.9496898224,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2344380403,
      "Direction stability factor": 0.2525161072,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3509616455, 0.8398306562, 1.2006372056],
      direction: [-68.9160779635, -30.5522235796, 21.9598008542],
      "speed factor": 1.1436399645,
      "Uz factor": 0.9485560283,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3768991682,
      "Direction stability factor": 0.7475670033,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.8016246396, 0.9719738942, 1.1390820682],
      direction: [2.1825053971, 16.7036823661, 38.2383344765],
      "speed factor": 1.2343465208,
      "Uz factor": 0.825417733,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.728200154,
      "Direction stability factor": 0.8998449192,
      score: 0.0,
    },
    id: "IDN:341.0",
    "45": {
      velocity: [0.1856587614, 0.6512403848, 1.2788222916],
      direction: [-32.1032457079, 3.9358385466, 85.9657517276],
      "speed factor": 0.8846329171,
      "Uz factor": 0.9579695873,
      "Directional variance factor": 0.6501476847,
      "Velocity stability factor": 0.1112926411,
      "Direction stability factor": 0.6720305627,
      score: 0.0,
    },
    "90": {
      velocity: [0.0128474258, 0.3600002412, 0.8571744297],
      direction: [-179.6474355744, 9.4606499768, 175.8139024694],
      "speed factor": 0.5835709257,
      "Uz factor": 0.9952803623,
      "Directional variance factor": 0.1590533354,
      "Velocity stability factor": 0.3837615771,
      "Direction stability factor": 0.0126073943,
      score: 0.0,
    },
    "135": {
      velocity: [0.0401764121, 0.3239937532, 0.7873055459],
      direction: [-175.544836726, 129.4015041895, 177.9359436281],
      "speed factor": 0.2742122183,
      "Uz factor": 0.9999539521,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3979552679,
      "Direction stability factor": 0.0181089435,
      score: 0.0,
    },
    "180": {
      velocity: [0.0747474399, 0.3288745301, 0.9363739799],
      direction: [-177.0340107, -14.671443222, 179.8386998223],
      "speed factor": 0.6422899257,
      "Uz factor": 0.9880476949,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3210754393,
      "Direction stability factor": 0.0086869152,
      score: 0.0,
    },
    "225": {
      velocity: [0.1506560287, 0.5702701484, 1.0660758237],
      direction: [-110.1231560491, -20.5406960626, 1.7127903119],
      "speed factor": 0.7784811604,
      "Uz factor": 0.9837814121,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1772384384,
      "Direction stability factor": 0.6893445934,
      score: 0.0,
    },
    "270": {
      velocity: [0.8583158259, 1.1731307731, 1.4431856546],
      direction: [-39.606638164, -16.1847271688, 1.5810183992],
      "speed factor": 1.0734525373,
      "Uz factor": 0.9666567392,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.538652805,
      "Direction stability factor": 0.8855898429,
      score: 0.0,
    },
    "315": {
      velocity: [0.7736556208, 0.9162876373, 1.0732591248],
      direction: [-13.3314204561, 9.3172564133, 28.4891843577],
      "speed factor": 1.1289575563,
      "Uz factor": 0.8326440167,
      "Directional variance factor": 0.1717994299,
      "Velocity stability factor": 0.7569508612,
      "Direction stability factor": 0.8838316533,
      score: 0.0,
    },
  },
  {
    position: [-54.78806345, 32.40355892, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2462085823, 0.8591943491, 1.1973374102],
      direction: [-35.735083664, -13.2998897934, 49.0836418604],
      "speed factor": 0.8804603107,
      "Uz factor": 0.9872684562,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3391264019,
      "Direction stability factor": 0.7643924291,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1198871831, 0.501781193, 1.0474274214],
      direction: [-161.2248760915, -7.8550837735, 143.099650695],
      "speed factor": 0.4455193035,
      "Uz factor": 0.9848771428,
      "Directional variance factor": 0.3017703312,
      "Velocity stability factor": 0.4001843543,
      "Direction stability factor": 0.1546540923,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.02977133, 0.2715468556, 0.9573171722],
      direction: [-149.3687431697, -19.0604209907, 161.7680382566],
      "speed factor": 0.4206441821,
      "Uz factor": 0.9872139541,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.171187214,
      "Direction stability factor": 0.1357311627,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0566628872, 0.4071428995, 0.9813080162],
      direction: [-145.7073948839, -29.8758804765, 179.9131966008],
      "speed factor": 0.4580073186,
      "Uz factor": 0.9968822406,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2345611666,
      "Direction stability factor": 0.095498357,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5278968435, 1.0298819549, 1.3322734217],
      direction: [-37.542460484, -17.3453678942, -0.2400822087],
      "speed factor": 1.4630922127,
      "Uz factor": 0.9768661343,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3421877161,
      "Direction stability factor": 0.8963822826,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.5116498097, 0.8833999442, 1.0684878291],
      direction: [-37.0719135168, -15.5204455345, -0.1614538285],
      "speed factor": 1.0350403402,
      "Uz factor": 0.9504403967,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5718460806,
      "Direction stability factor": 0.8974709453,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4629767545, 0.7298836987, 0.9252859607],
      direction: [-17.3986638962, 11.6239974001, 22.6203451958],
      "speed factor": 1.3540222168,
      "Uz factor": 0.9777731478,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5513992557,
      "Direction stability factor": 0.8888360859,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.7126375199, 1.2573853721, 1.4648697402],
      direction: [9.5058493106, 25.6747005947, 35.5004208924],
      "speed factor": 1.7122453815,
      "Uz factor": 0.9318235029,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4483582391,
      "Direction stability factor": 0.9277928567,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2468884322, 0.6568896347, 1.0299019997],
      direction: [-29.9103749386, -0.4818654126, 66.8910493752],
      "speed factor": 0.8342090666,
      "Uz factor": 0.9617533538,
      "Directional variance factor": 0.9571675189,
      "Velocity stability factor": 0.3693338805,
      "Direction stability factor": 0.7311071547,
      score: 0.0,
    },
    id: "IDN:370.0",
    "45": {
      velocity: [0.090319923, 0.3234028989, 0.7419199338],
      direction: [-158.4357741987, 2.1299360598, 150.0640178754],
      "speed factor": 0.439304528,
      "Uz factor": 0.9860991703,
      "Directional variance factor": 0.8106723502,
      "Velocity stability factor": 0.4702698099,
      "Direction stability factor": 0.1430561331,
      score: 0.0,
    },
    "90": {
      velocity: [0.0377675656, 0.2274206183, 0.9581770919],
      direction: [-175.1006533127, -79.073465703, 174.140782208],
      "speed factor": 0.3686554773,
      "Uz factor": 0.9048145198,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.328232175,
      "Direction stability factor": 0.0298849013,
      score: 0.0,
    },
    "135": {
      velocity: [0.058042423, 0.4188833947, 0.8582277337],
      direction: [-176.0192604434, -39.8417454495, 130.7220125178],
      "speed factor": 0.3545220972,
      "Uz factor": 0.9994373147,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.355202027,
      "Direction stability factor": 0.1479409084,
      score: 0.0,
    },
    "180": {
      velocity: [0.5971110112, 0.8534701829, 1.1286404571],
      direction: [-50.1516928864, -21.2792267213, 7.0920616813],
      "speed factor": 1.6668219948,
      "Uz factor": 0.9692263389,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5811777159,
      "Direction stability factor": 0.8409895706,
      score: 0.0,
    },
    "225": {
      velocity: [0.1421056921, 0.5157371137, 0.8971745247],
      direction: [-53.1686063408, 6.1103097983, 56.7561080029],
      "speed factor": 0.7040376001,
      "Uz factor": 0.9511341716,
      "Directional variance factor": 0.4568613513,
      "Velocity stability factor": 0.3213587741,
      "Direction stability factor": 0.6946535713,
      score: 0.0,
    },
    "270": {
      velocity: [0.5375321831, 0.9835995525, 1.3606835993],
      direction: [9.2152822792, 36.617143614, 54.0198409886],
      "speed factor": 0.9000253506,
      "Uz factor": 0.9517490394,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3506955253,
      "Direction stability factor": 0.8755428925,
      score: 0.0,
    },
    "315": {
      velocity: [0.5753181068, 1.1867887524, 1.4380229012],
      direction: [4.9598845429, 15.2151039516, 46.3781569453],
      "speed factor": 1.4622418499,
      "Uz factor": 0.9731831096,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3001428404,
      "Direction stability factor": 0.8849492433,
      score: 0.0,
    },
  },
  {
    position: [-2.410495206, 101.4859026, 46.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.7011435262, 1.0849271154, 1.3768298367],
      direction: [-20.8240177118, 4.7170785196, 27.867756368],
      "speed factor": 1.1117801999,
      "Uz factor": 0.9334763916,
      "Directional variance factor": 0.5807041316,
      "Velocity stability factor": 0.5305123448,
      "Direction stability factor": 0.864745072,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6257131841, 1.0030209438, 1.3175690728],
      direction: [-22.1844682468, -2.7548581789, 19.5023861905],
      "speed factor": 0.8905578737,
      "Uz factor": 0.9219312586,
      "Directional variance factor": 0.7551237174,
      "Velocity stability factor": 0.5525951657,
      "Direction stability factor": 0.8842031821,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1721150357, 0.481063361, 0.8517771309],
      direction: [-62.7145139806, -3.2760426366, 94.818945631],
      "speed factor": 0.7451992164,
      "Uz factor": 0.9778905884,
      "Directional variance factor": 0.7087962101,
      "Velocity stability factor": 0.3926848582,
      "Direction stability factor": 0.5624070566,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.182228501, 0.8699582229, 1.2596095267],
      direction: [-50.3880363534, 11.1607860212, 69.1047627373],
      "speed factor": 0.9786422247,
      "Uz factor": 0.9948975325,
      "Directional variance factor": 0.0079301314,
      "Velocity stability factor": 0.1081234848,
      "Direction stability factor": 0.6680755581,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0713038779, 0.4902049261, 1.1063237255],
      direction: [-157.1595111521, 5.0601194492, 88.3536099782],
      "speed factor": 0.6964050653,
      "Uz factor": 0.9999711812,
      "Directional variance factor": 0.5502116045,
      "Velocity stability factor": 0.1535696236,
      "Direction stability factor": 0.318019108,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0831394017, 0.4230692053, 0.9084775423],
      direction: [-159.0082169032, -30.4297389129, 165.5477513099],
      "speed factor": 0.4956913311,
      "Uz factor": 0.9969736447,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3653957751,
      "Direction stability factor": 0.0984556439,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1845683003, 0.5331288779, 0.9616897519],
      direction: [-48.0933070118, -6.8722140275, 25.5266459197],
      "speed factor": 0.9890183139,
      "Uz factor": 0.9975410809,
      "Directional variance factor": 0.3891365309,
      "Velocity stability factor": 0.2459218702,
      "Direction stability factor": 0.7955001307,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.097335766, 0.5719772411, 1.0535606519],
      direction: [-157.6191679658, 15.2913605224, 67.563781676],
      "speed factor": 0.7788903951,
      "Uz factor": 0.8655619422,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2987623161,
      "Direction stability factor": 0.3744918066,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1362964878, 0.5052527333, 0.9224951584],
      direction: [-167.1923509189, -4.0161105817, 148.2554180525],
      "speed factor": 0.6416396132,
      "Uz factor": 0.8396174534,
      "Directional variance factor": 0.6430123927,
      "Velocity stability factor": 0.3667684887,
      "Direction stability factor": 0.1237561973,
      score: 0.0,
    },
    id: "IDN:403.0",
    "45": {
      velocity: [0.1807154868, 0.5538417061, 1.0561904769],
      direction: [-151.855144574, 0.2384033463, 174.3998510588],
      "speed factor": 0.7523283498,
      "Uz factor": 0.9716894167,
      "Directional variance factor": 0.9788085914,
      "Velocity stability factor": 0.2882665358,
      "Direction stability factor": 0.0937361232,
      score: 0.0,
    },
    "90": {
      velocity: [0.1801155269, 0.6162384442, 1.0914503657],
      direction: [-47.2230434132, 13.2559649488, 39.5227383485],
      "speed factor": 0.9989405511,
      "Uz factor": 0.9932717729,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3348554041,
      "Direction stability factor": 0.7590394951,
      score: 0.0,
    },
    "135": {
      velocity: [0.0653175691, 0.372658902, 1.0342451451],
      direction: [-123.5109443175, 4.0780840216, 132.7710177388],
      "speed factor": 0.3153999827,
      "Uz factor": 0.9969580217,
      "Directional variance factor": 0.6375036425,
      "Velocity stability factor": 0.2192276856,
      "Direction stability factor": 0.288105661,
      score: 0.0,
    },
    "180": {
      velocity: [0.0411482506, 0.4528713842, 1.1403206746],
      direction: [-174.4796151187, -4.93635315, 140.970271311],
      "speed factor": 0.8844550156,
      "Uz factor": 0.9965187331,
      "Directional variance factor": 0.5612130533,
      "Velocity stability factor": 0.1338995257,
      "Direction stability factor": 0.1237503155,
      score: 0.0,
    },
    "225": {
      velocity: [0.0576283011, 0.4818816586, 0.8082351628],
      direction: [-94.0610959956, -12.7528724389, 155.0443536244],
      "speed factor": 0.6578211989,
      "Uz factor": 0.9999991572,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.325369107,
      "Direction stability factor": 0.3080404177,
      score: 0.0,
    },
    "270": {
      velocity: [0.4206751689, 1.006313791, 1.2863025212],
      direction: [-52.2985977044, -1.2761963782, 13.991400643],
      "speed factor": 0.9208096122,
      "Uz factor": 0.9962028361,
      "Directional variance factor": 0.8865603219,
      "Velocity stability factor": 0.3171903708,
      "Direction stability factor": 0.8158611157,
      score: 0.0,
    },
    "315": {
      velocity: [0.7777396505, 0.9135771051, 1.1577161078],
      direction: [-24.9343605675, -7.9556870416, 14.6392041575],
      "speed factor": 1.1256179108,
      "Uz factor": 0.9315880798,
      "Directional variance factor": 0.2928278185,
      "Velocity stability factor": 0.6917494306,
      "Direction stability factor": 0.8900734313,
      score: 0.0,
    },
  },
  {
    position: [0.575319964, 101.7772919, 46.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.6813954283, 1.0612401932, 1.3774572871],
      direction: [-26.7160771514, -1.4493799784, 21.6617961685],
      "speed factor": 1.0875070015,
      "Uz factor": 0.9173369124,
      "Directional variance factor": 0.8711662241,
      "Velocity stability factor": 0.5163547864,
      "Direction stability factor": 0.8656170186,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6651300599, 0.9790629273, 1.3142964995],
      direction: [-36.2467080115, -6.9040853576, 19.2847618206],
      "speed factor": 0.8692861341,
      "Uz factor": 0.9145252584,
      "Directional variance factor": 0.3863035238,
      "Velocity stability factor": 0.580201299,
      "Direction stability factor": 0.8457459171,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1158301425, 0.4705120995, 0.878359456],
      direction: [-72.9162782309, -4.7780967749, 96.3808450925],
      "speed factor": 0.7288546088,
      "Uz factor": 0.9855542767,
      "Directional variance factor": 0.5752802867,
      "Velocity stability factor": 0.3186384802,
      "Direction stability factor": 0.529730213,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1704006896, 0.8135220724, 1.3051756837],
      direction: [-101.2627786492, 9.3695348645, 60.8721863968],
      "speed factor": 0.9151554981,
      "Uz factor": 0.9973078208,
      "Directional variance factor": 0.1671524565,
      "Velocity stability factor": 0.0606116656,
      "Direction stability factor": 0.5496250971,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0320787501, 0.4430823055, 1.0087520657],
      direction: [-144.774572682, 6.7793348418, 179.9475852301],
      "speed factor": 0.6294607529,
      "Uz factor": 0.9999918567,
      "Directional variance factor": 0.3973924585,
      "Velocity stability factor": 0.2012849183,
      "Direction stability factor": 0.0979940058,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0537467225, 0.3528813949, 0.7391991983],
      direction: [-173.5704723621, -32.1265521058, 153.7767689352],
      "speed factor": 0.413455402,
      "Uz factor": 0.9986528051,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4729541557,
      "Direction stability factor": 0.0907021075,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.10152694, 0.4813291421, 1.0574525369],
      direction: [-66.6136994591, -4.8629296937, 34.5516027841],
      "speed factor": 0.8929235618,
      "Uz factor": 0.9999544311,
      "Directional variance factor": 0.5677395828,
      "Velocity stability factor": 0.0724196524,
      "Direction stability factor": 0.7189852715,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1433578915, 0.5225880866, 0.9242035696],
      direction: [-146.0934815554, 16.3912380711, 73.8750955293],
      "speed factor": 0.7116346807,
      "Uz factor": 0.8842155261,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4273748541,
      "Direction stability factor": 0.3889761748,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1000413985, 0.5327665441, 1.0127296657],
      direction: [-114.8689966598, 6.2104768653, 71.9671202391],
      "speed factor": 0.6765804452,
      "Uz factor": 0.9366158106,
      "Directional variance factor": 0.447957612,
      "Velocity stability factor": 0.2648894072,
      "Direction stability factor": 0.4810107864,
      score: 0.0,
    },
    id: "IDN:402.0",
    "45": {
      velocity: [0.1884093961, 0.5741961708, 1.0433883049],
      direction: [-102.8210145788, -3.574060697, 41.9310196378],
      "speed factor": 0.7799774789,
      "Uz factor": 0.9797404513,
      "Directional variance factor": 0.6823057158,
      "Velocity stability factor": 0.3049292013,
      "Direction stability factor": 0.5979110161,
      score: 0.0,
    },
    "90": {
      velocity: [0.0564715644, 0.544611058, 0.9655992074],
      direction: [-130.3380715947, 8.6074623096, 58.9825439138],
      "speed factor": 0.882830462,
      "Uz factor": 0.9991839083,
      "Directional variance factor": 0.2348922391,
      "Velocity stability factor": 0.3364663425,
      "Direction stability factor": 0.4741094014,
      score: 0.0,
    },
    "135": {
      velocity: [0.0395013006, 0.4991695618, 1.1216409305],
      direction: [-146.6604710141, 10.0630826573, 74.8918279476],
      "speed factor": 0.4224723208,
      "Uz factor": 0.9986974693,
      "Directional variance factor": 0.1055037638,
      "Velocity stability factor": 0.1280001888,
      "Direction stability factor": 0.3845769473,
      score: 0.0,
    },
    "180": {
      velocity: [0.021040161, 0.3718828942, 0.9098261234],
      direction: [-179.0301247205, -3.2840026874, 176.1525912719],
      "speed factor": 0.7262849949,
      "Uz factor": 0.9945531387,
      "Directional variance factor": 0.70808865,
      "Velocity stability factor": 0.2996749856,
      "Direction stability factor": 0.0133813445,
      score: 0.0,
    },
    "225": {
      velocity: [0.0489947022, 0.4278335545, 0.7775296513],
      direction: [-175.554939233, -14.5509189861, 126.4953210289],
      "speed factor": 0.584039622,
      "Uz factor": 0.9998430895,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3452069141,
      "Direction stability factor": 0.1609714993,
      score: 0.0,
    },
    "270": {
      velocity: [0.3167955119, 1.0109341711, 1.3182677733],
      direction: [-35.170157382, -0.0830164934, 14.6388688468],
      "speed factor": 0.9250374092,
      "Uz factor": 0.9981311236,
      "Directional variance factor": 0.9926207561,
      "Velocity stability factor": 0.2100354712,
      "Direction stability factor": 0.8616415938,
      score: 0.0,
    },
    "315": {
      velocity: [0.7543070376, 0.9087664868, 1.1503437081],
      direction: [-27.8638438271, -10.3718092217, 14.888974256],
      "speed factor": 1.1196907504,
      "Uz factor": 0.9334564438,
      "Directional variance factor": 0.0780614025,
      "Velocity stability factor": 0.678720808,
      "Direction stability factor": 0.881242172,
      score: 0.0,
    },
  },
  {
    position: [-48.85687529, 38.74789386, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1269790608, 0.5707364294, 1.1239697748],
      direction: [-100.6128750448, -13.1422433311, 140.5958645291],
      "speed factor": 0.5848627549,
      "Uz factor": 0.9940075849,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3072601513,
      "Direction stability factor": 0.3299757234,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0281315716, 0.4988451628, 1.4440021646],
      direction: [-179.8382385639, -5.2613347262, 149.6326469851],
      "speed factor": 0.4429124738,
      "Uz factor": 0.995064776,
      "Directional variance factor": 0.5323258021,
      "Velocity stability factor": 0.0843940792,
      "Direction stability factor": 0.0848030957,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0409246139, 0.3542355897, 0.7945461412],
      direction: [-175.1091422497, 32.8280307374, 175.3594768366],
      "speed factor": 0.5487345436,
      "Uz factor": 0.9447625965,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.326598073,
      "Direction stability factor": 0.0264760581,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1349216263, 0.6100899819, 1.1840822344],
      direction: [-134.2151451866, 8.6918297144, 75.8086808365],
      "speed factor": 0.6863086082,
      "Uz factor": 0.9269844369,
      "Directional variance factor": 0.2273929143,
      "Velocity stability factor": 0.1314848835,
      "Direction stability factor": 0.4166004833,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5428316092, 0.9313305491, 1.2576200465],
      direction: [-30.1626952646, 1.7207683529, 23.6940434303],
      "speed factor": 1.3230860754,
      "Uz factor": 0.833354145,
      "Directional variance factor": 0.8470428131,
      "Velocity stability factor": 0.4154521313,
      "Direction stability factor": 0.8503979481,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1131626665, 0.4926235273, 0.9863065433],
      direction: [-177.7319610538, -40.0713668878, 166.4818622104],
      "speed factor": 0.57718503,
      "Uz factor": 0.7518839019,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3286378444,
      "Direction stability factor": 0.0438504909,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0718656153, 0.3277654066, 0.8203647012],
      direction: [-93.2998299835, -26.508298876, -0.7737724623],
      "speed factor": 0.6080443271,
      "Uz factor": 0.9946574953,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2736955213,
      "Direction stability factor": 0.7429831736,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0084971448, 0.1560186904, 0.442474447],
      direction: [-178.0160846044, 129.4007603932, 172.8560055514],
      "speed factor": 0.2124585572,
      "Uz factor": 0.9853602275,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6817472095,
      "Direction stability factor": 0.0253553051,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0351055903, 0.2331373041, 0.6584970244],
      direction: [-179.8397478354, 16.0673669882, 178.2657247892],
      "speed factor": 0.2960699068,
      "Uz factor": 0.9901027528,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4978990493,
      "Direction stability factor": 0.005262576,
      score: 0.0,
    },
    id: "IDN:392.0",
    "45": {
      velocity: [0.0657138123, 0.3752165847, 1.0485472438],
      direction: [-167.5958779071, 16.4487625965, 178.9816437449],
      "speed factor": 0.5096872823,
      "Uz factor": 0.9850350619,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2009875201,
      "Direction stability factor": 0.0372846621,
      score: 0.0,
    },
    "90": {
      velocity: [0.1151272435, 0.3855108333, 1.0310264248],
      direction: [-178.4595471569, 52.7861987612, 168.3636786324],
      "speed factor": 0.624924342,
      "Uz factor": 0.6597296185,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3315240843,
      "Direction stability factor": 0.0366021506,
      score: 0.0,
    },
    "135": {
      velocity: [0.0589397987, 0.3810096407, 0.907365575],
      direction: [-154.2667134952, -10.5901176818, 164.1095440074],
      "speed factor": 0.3224676332,
      "Uz factor": 0.8994088027,
      "Directional variance factor": 0.0586562061,
      "Velocity stability factor": 0.3163293384,
      "Direction stability factor": 0.1156215069,
      score: 0.0,
    },
    "180": {
      velocity: [0.3652183131, 0.7599236272, 1.1002831003],
      direction: [-46.2098182456, -13.8754862592, 4.6886648811],
      "speed factor": 1.4841261494,
      "Uz factor": 0.835351324,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4208006433,
      "Direction stability factor": 0.8586153246,
      score: 0.0,
    },
    "225": {
      velocity: [0.0275701576, 0.2678094279, 0.6533607065],
      direction: [-132.9302949459, -12.938210208, 162.0301951685],
      "speed factor": 0.3655891769,
      "Uz factor": 0.979831456,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4375515888,
      "Direction stability factor": 0.1806653052,
      score: 0.0,
    },
    "270": {
      velocity: [0.0273767139, 0.2132140816, 0.6291111661],
      direction: [-175.1226095466, 69.9462817576, 178.6357064497],
      "speed factor": 0.1950977693,
      "Uz factor": 0.7688319372,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.525349936,
      "Direction stability factor": 0.0173380111,
      score: 0.0,
    },
    "315": {
      velocity: [0.0352404795, 0.2057638032, 0.6949494197],
      direction: [-179.7858498969, 23.668216995, 166.686307616],
      "speed factor": 0.2535214827,
      "Uz factor": 0.999557441,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4648203788,
      "Direction stability factor": 0.0375773402,
      score: 0.0,
    },
  },
  {
    position: [-53.93078572, 52.86568654, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1817480568, 0.9752841454, 1.3738534962],
      direction: [-34.6120444823, -14.7989028411, 30.1065359027],
      "speed factor": 0.9994234513,
      "Uz factor": 0.9709803896,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1716884318,
      "Direction stability factor": 0.8202261656,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0809469293, 0.5692125221, 1.1661682239],
      direction: [-152.3947902019, -1.3762302679, 97.7616954672],
      "speed factor": 0.5053899387,
      "Uz factor": 0.9993900045,
      "Directional variance factor": 0.8776684206,
      "Velocity stability factor": 0.2982162017,
      "Direction stability factor": 0.3051208731,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0353985727, 0.204745841, 0.6166668222],
      direction: [-174.4256369128, 8.4506736203, 178.0567718464],
      "speed factor": 0.3171649572,
      "Uz factor": 0.9671292446,
      "Directional variance factor": 0.2488290115,
      "Velocity stability factor": 0.4806051245,
      "Direction stability factor": 0.0208821979,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0200536782, 0.2936097458, 0.7319936287],
      direction: [-179.6537920487, -172.5501333073, 178.9803441892],
      "speed factor": 0.3302904522,
      "Uz factor": 0.9251903739,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.410642561,
      "Direction stability factor": 0.003794066,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0651442547, 0.3933827894, 0.9244546209],
      direction: [-97.1108431462, -10.6276127634, 167.4664131534],
      "speed factor": 0.5588555981,
      "Uz factor": 0.9973781715,
      "Directional variance factor": 0.0553233099,
      "Velocity stability factor": 0.2972633342,
      "Direction stability factor": 0.2650631769,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1857777557, 0.7258412112, 1.1665652191],
      direction: [-116.7465807925, -5.3183364895, 16.8494557026],
      "speed factor": 0.8504357954,
      "Uz factor": 0.9974373361,
      "Directional variance factor": 0.5272589787,
      "Velocity stability factor": 0.2458704652,
      "Direction stability factor": 0.6288998986,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.320328005, 0.7391414571, 1.0333757134],
      direction: [-38.7289039974, -12.5852987504, 16.0170271205],
      "speed factor": 1.3711964742,
      "Uz factor": 0.9719617091,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3080956893,
      "Direction stability factor": 0.8479279691,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.6137268728, 1.0692188725, 1.277401281],
      direction: [-36.2613257883, 5.7117829357, 20.2861754213],
      "speed factor": 1.4560095233,
      "Uz factor": 0.7648180504,
      "Directional variance factor": 0.4922859613,
      "Velocity stability factor": 0.5133011996,
      "Direction stability factor": 0.8429236078,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6015904633, 0.8952271683, 1.1689913613],
      direction: [-25.1030108969, -8.1782481995, 2.9405158963],
      "speed factor": 1.1368829422,
      "Uz factor": 0.8734135699,
      "Directional variance factor": 0.2730446045,
      "Velocity stability factor": 0.5429957572,
      "Direction stability factor": 0.9221013145,
      score: 0.0,
    },
    id: "IDN:379.0",
    "45": {
      velocity: [0.0425563881, 0.3737686113, 1.0334774614],
      direction: [-167.3615592724, 18.6904497887, 176.438663669],
      "speed factor": 0.5077203819,
      "Uz factor": 0.9973585739,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1944125233,
      "Direction stability factor": 0.0449993807,
      score: 0.0,
    },
    "90": {
      velocity: [0.0763789095, 0.234585722, 0.8354406624],
      direction: [-179.8098964973, -165.6006074184, 179.718602521],
      "speed factor": 0.38027032,
      "Uz factor": 0.9255862445,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4459930626,
      "Direction stability factor": 0.0013097249,
      score: 0.0,
    },
    "135": {
      velocity: [0.0252387552, 0.2254738468, 0.737702763],
      direction: [-177.78623997, 63.9684554948, 173.7577017596],
      "speed factor": 0.1908298635,
      "Uz factor": 0.6519986924,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4258888011,
      "Direction stability factor": 0.0234890508,
      score: 0.0,
    },
    "180": {
      velocity: [0.056582717, 0.3742771202, 0.8350555327],
      direction: [-146.8637801877, -25.5583661192, 157.1466465122],
      "speed factor": 0.7309609037,
      "Uz factor": 0.9923804716,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3865969885,
      "Direction stability factor": 0.1555265925,
      score: 0.0,
    },
    "225": {
      velocity: [0.1301905727, 0.4971781298, 0.9119725789],
      direction: [-104.691521931, -1.4171961319, 40.7643090036],
      "speed factor": 0.6787025561,
      "Uz factor": 0.9713054676,
      "Directional variance factor": 0.8740270105,
      "Velocity stability factor": 0.2973494916,
      "Direction stability factor": 0.5959560252,
      score: 0.0,
    },
    "270": {
      velocity: [0.7609020444, 1.04959397, 1.3844452874],
      direction: [-5.0919921535, 13.4637404229, 38.0475043315],
      "speed factor": 0.9604123735,
      "Uz factor": 0.8435795595,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5081470919,
      "Direction stability factor": 0.8801680653,
      score: 0.0,
    },
    "315": {
      velocity: [0.6036183562, 1.0380615759, 1.3151331191],
      direction: [-18.4715661872, -2.7216132112, 20.1106436841],
      "speed factor": 1.2789951673,
      "Uz factor": 0.8644866174,
      "Directional variance factor": 0.7580788257,
      "Velocity stability factor": 0.4227936321,
      "Direction stability factor": 0.8928271948,
      score: 0.0,
    },
  },
  {
    position: [-53.79376876, 56.83523722, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2889105015, 0.9870984057, 1.2877252348],
      direction: [-25.8534969498, -2.4314071286, 46.9246100518],
      "speed factor": 1.011530127,
      "Uz factor": 0.9484351549,
      "Directional variance factor": 0.7838749219,
      "Velocity stability factor": 0.3059927665,
      "Direction stability factor": 0.7978385917,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1344840856, 0.5957847779, 1.1777608262],
      direction: [-72.8540756498, 5.692907413, 175.1151096976],
      "speed factor": 0.5289827977,
      "Uz factor": 0.9778758038,
      "Directional variance factor": 0.4939637855,
      "Velocity stability factor": 0.3253406311,
      "Direction stability factor": 0.3111967074,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0381875763, 0.2897547498, 0.8643555242],
      direction: [-156.4720896259, 22.346683394, 179.6099544089],
      "speed factor": 0.4488494239,
      "Uz factor": 0.9840579363,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2617738905,
      "Direction stability factor": 0.0664387666,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0599429046, 0.2995580809, 0.6091614986],
      direction: [-139.0383486743, -6.0512266867, 176.6129893943],
      "speed factor": 0.3369819136,
      "Uz factor": 0.8252770438,
      "Directional variance factor": 0.4621131834,
      "Velocity stability factor": 0.5453463965,
      "Direction stability factor": 0.1231907276,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0885426075, 0.5067835999, 1.2014323535],
      direction: [-161.4669470737, -10.1941831274, 161.8249968422],
      "speed factor": 0.7199574039,
      "Uz factor": 0.9958938664,
      "Directional variance factor": 0.0938503887,
      "Velocity stability factor": 0.0898882868,
      "Direction stability factor": 0.1019668225,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1557272959, 0.6573853874, 1.1075887265],
      direction: [-104.7496566041, -9.9287730649, 28.9214131791],
      "speed factor": 0.7702291578,
      "Uz factor": 0.9938711238,
      "Directional variance factor": 0.1174423942,
      "Velocity stability factor": 0.2681117524,
      "Direction stability factor": 0.6286914728,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4587444358, 0.7371618372, 0.9717904249],
      direction: [-47.1882723109, -20.1622364394, 8.4315832031],
      "speed factor": 1.3675240407,
      "Uz factor": 0.9684415262,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5021669275,
      "Direction stability factor": 0.8455004013,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.64050776, 1.0914153907, 1.2767644791],
      direction: [-45.2843035164, -15.6839451069, 2.8771727796],
      "speed factor": 1.486235647,
      "Uz factor": 0.8987528787,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5334076799,
      "Direction stability factor": 0.8662181214,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6485523155, 0.8858002592, 1.0815407616],
      direction: [-29.0586058206, -2.0485533486, 12.9797312743],
      "speed factor": 1.1249113526,
      "Uz factor": 0.9263111965,
      "Directional variance factor": 0.817906369,
      "Velocity stability factor": 0.6512561794,
      "Direction stability factor": 0.8832268414,
      score: 0.0,
    },
    id: "IDN:381.0",
    "45": {
      velocity: [0.0475719968, 0.4677402662, 0.9886210876],
      direction: [-128.2742949965, 20.3161509737, 176.4260023235],
      "speed factor": 0.6353697433,
      "Uz factor": 0.9986127848,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2349568669,
      "Direction stability factor": 0.1536102852,
      score: 0.0,
    },
    "90": {
      velocity: [0.067831101, 0.3219823001, 1.0596415274],
      direction: [-178.1915797208, 87.4807862051, 176.843915172],
      "speed factor": 0.5219427306,
      "Uz factor": 0.9471489314,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2761196903,
      "Direction stability factor": 0.013790292,
      score: 0.0,
    },
    "135": {
      velocity: [0.0457257024, 0.2377558159, 0.730843901],
      direction: [-167.6077577426, 6.2918354733, 176.5521697094],
      "speed factor": 0.201224712,
      "Uz factor": 0.8166789671,
      "Directional variance factor": 0.4407257357,
      "Velocity stability factor": 0.4479243497,
      "Direction stability factor": 0.0440002015,
      score: 0.0,
    },
    "180": {
      velocity: [0.1133662903, 0.4428853174, 0.956595031],
      direction: [-112.770458464, -34.3401934678, 97.3297029847],
      "speed factor": 0.8649522889,
      "Uz factor": 0.9845529231,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3355721119,
      "Direction stability factor": 0.4163884404,
      score: 0.0,
    },
    "225": {
      velocity: [0.172313505, 0.534339008, 0.8911408876],
      direction: [-95.0387919034, -5.6326239359, 41.3885284397],
      "speed factor": 0.7294312215,
      "Uz factor": 0.9630423551,
      "Directional variance factor": 0.4993223168,
      "Velocity stability factor": 0.3539318866,
      "Direction stability factor": 0.6210352213,
      score: 0.0,
    },
    "270": {
      velocity: [0.798250703, 1.0693124203, 1.489252026],
      direction: [-26.0505696481, -9.0752696289, 13.8983162027],
      "speed factor": 0.9784553923,
      "Uz factor": 0.9098297837,
      "Directional variance factor": 0.1933093663,
      "Velocity stability factor": 0.4549359422,
      "Direction stability factor": 0.8890308726,
      score: 0.0,
    },
    "315": {
      velocity: [0.6871153049, 0.9971876115, 1.2067941376],
      direction: [-27.9413234499, -10.8207286449, 20.3496056223],
      "speed factor": 1.2286343755,
      "Uz factor": 0.9287093136,
      "Directional variance factor": 0.0381574538,
      "Velocity stability factor": 0.5784178387,
      "Direction stability factor": 0.8658585304,
      score: 0.0,
    },
  },
  {
    position: [-50.05926353, 53.82218301, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1216954578, 0.3398295978, 0.9528334017],
      direction: [-134.9218166696, -48.9580699098, 175.1136964984],
      "speed factor": 0.3482407369,
      "Uz factor": 0.9621809685,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4224997631,
      "Direction stability factor": 0.1387902412,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0792570527, 0.5110774535, 1.0182162756],
      direction: [-169.9742767192, 20.5208863251, 177.3252931936],
      "speed factor": 0.4537732268,
      "Uz factor": 0.9653564601,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3928000002,
      "Direction stability factor": 0.0352789725,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1484831665, 0.5473461702, 0.9472438225],
      direction: [-27.6623938339, 23.6975727229, 108.9318811315],
      "speed factor": 0.8478757065,
      "Uz factor": 0.876929167,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2862638002,
      "Direction stability factor": 0.6205714584,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.32346074, 0.8986954817, 1.2336763394],
      direction: [-31.8708772878, -9.2317870467, 20.1784167195],
      "speed factor": 1.0109696332,
      "Uz factor": 0.8517952294,
      "Directional variance factor": 0.179396707,
      "Velocity stability factor": 0.2465062058,
      "Direction stability factor": 0.8554186278,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1086255151, 0.4937127217, 1.183218917],
      direction: [-88.6790334243, -19.2743513251, 47.6248283505],
      "speed factor": 0.7013883824,
      "Uz factor": 0.9922721163,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1212067094,
      "Direction stability factor": 0.6213781617,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0393394149, 0.4434920738, 0.8753285182],
      direction: [-154.6581049291, -14.7748951493, 76.0789619848],
      "speed factor": 0.5196198958,
      "Uz factor": 0.9963090646,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3572062275,
      "Direction stability factor": 0.359063703,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0221311813, 0.3296181416, 0.9778101971],
      direction: [-173.6278018416, -9.6097208023, 176.0357045102],
      "speed factor": 0.6114813738,
      "Uz factor": 0.999803885,
      "Directional variance factor": 0.1458025954,
      "Velocity stability factor": 0.0726589217,
      "Direction stability factor": 0.0287124824,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0303710254, 0.169904298, 0.4340272141],
      direction: [-178.2151804309, 152.7648377355, 179.7488671317],
      "speed factor": 0.2313672928,
      "Uz factor": 0.8526672815,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7039828862,
      "Direction stability factor": 0.0056554234,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0345001935, 0.1440750679, 0.7031726374],
      direction: [-170.747576935, -55.3771471332, 168.4518522286],
      "speed factor": 0.1829663944,
      "Uz factor": 0.9117898102,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.461428163,
      "Direction stability factor": 0.0577793634,
      score: 0.0,
    },
    id: "IDN:383.0",
    "45": {
      velocity: [0.0767661626, 0.5303252181, 0.9663039984],
      direction: [-132.4907032093, 24.2280770554, 117.5225713294],
      "speed factor": 0.7203839866,
      "Uz factor": 0.9016425985,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2768338873,
      "Direction stability factor": 0.3055186818,
      score: 0.0,
    },
    "90": {
      velocity: [0.6339878397, 1.0335448972, 1.3552242774],
      direction: [-26.7966430227, 2.7513820222, 30.0498301306],
      "speed factor": 1.6754065231,
      "Uz factor": 0.84984218,
      "Directional variance factor": 0.7554327091,
      "Velocity stability factor": 0.4736001538,
      "Direction stability factor": 0.8420931301,
      score: 0.0,
    },
    "135": {
      velocity: [0.2225715233, 0.7328840395, 1.2680034423],
      direction: [-125.0782369952, -39.9735627668, 137.1147834283],
      "speed factor": 0.6202766449,
      "Uz factor": 0.7946923623,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.157579659,
      "Direction stability factor": 0.2716860544,
      score: 0.0,
    },
    "180": {
      velocity: [0.0862746278, 0.3865653443, 0.7797153578],
      direction: [-163.3968853921, -53.1738094147, 165.0845853958],
      "speed factor": 0.7549597294,
      "Uz factor": 0.9580704886,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4535986056,
      "Direction stability factor": 0.08755147,
      score: 0.0,
    },
    "225": {
      velocity: [0.028174652, 0.2582768804, 0.8248204712],
      direction: [-162.9994800097, -9.1587401014, 179.2472342565],
      "speed factor": 0.3525762064,
      "Uz factor": 0.9997438574,
      "Directional variance factor": 0.1858897688,
      "Velocity stability factor": 0.2839901847,
      "Direction stability factor": 0.0493146826,
      score: 0.0,
    },
    "270": {
      velocity: [0.0940346114, 0.3198319818, 0.5644199418],
      direction: [-179.9771669457, 179.6013594701, 179.5453746552],
      "speed factor": 0.2926565906,
      "Uz factor": 0.8428248118,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6289585442,
      "Direction stability factor": 0.0013262733,
      score: 0.0,
    },
    "315": {
      velocity: [0.0301245255, 0.5301798508, 1.2220542963],
      direction: [-168.6501808402, -3.945091607, 161.5930304863],
      "speed factor": 0.6532343387,
      "Uz factor": 0.9988006305,
      "Directional variance factor": 0.6493251905,
      "Velocity stability factor": 0.0330636977,
      "Direction stability factor": 0.0826577463,
      score: 0.0,
    },
  },
  {
    position: [-50.06973548, 50.82220129, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0920989282, 0.3429218807, 0.8494083785],
      direction: [-177.9774943099, -15.6198088763, 179.3639457925],
      "speed factor": 0.351409557,
      "Uz factor": 0.840596062,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4737980739,
      "Direction stability factor": 0.0073848886,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0493417647, 0.5579516877, 1.0773372959],
      direction: [-148.6566261313, 32.3658058094, 111.6726401233],
      "speed factor": 0.4953917181,
      "Uz factor": 0.9019011974,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3352225835,
      "Direction stability factor": 0.2768631493,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0828593796, 0.5171267071, 1.0379818474],
      direction: [-173.7059861906, 8.2786245678, 27.2892030128],
      "speed factor": 0.8010637436,
      "Uz factor": 0.9049803301,
      "Directional variance factor": 0.2641222606,
      "Velocity stability factor": 0.1465459954,
      "Direction stability factor": 0.44168003,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0990992831, 0.7161676988, 1.2770772116],
      direction: [-103.3815346694, -28.614551514, 73.2148615336],
      "speed factor": 0.805638629,
      "Uz factor": 0.8895857588,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0248474543,
      "Direction stability factor": 0.509454455,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0341176665, 0.4842370765, 1.0783871991],
      direction: [-151.6228462789, -13.297846076, 158.7735301254],
      "speed factor": 0.6879268953,
      "Uz factor": 0.9945523836,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1460053103,
      "Direction stability factor": 0.1377878433,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0425261356, 0.3906736302, 0.9659356949],
      direction: [-177.5317531894, -18.3567983789, 131.4687435806],
      "speed factor": 0.4577348797,
      "Uz factor": 0.9962761452,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2899884559,
      "Direction stability factor": 0.1416652867,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0175303651, 0.2886017277, 0.9669603599],
      direction: [-167.7170434297, -12.1042495453, 171.8556062043],
      "speed factor": 0.5353909832,
      "Uz factor": 0.9978459695,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0787226458,
      "Direction stability factor": 0.0567426399,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0542441198, 0.1893460658, 0.6093814293],
      direction: [-176.7252261093, 134.4648602869, 177.417747348],
      "speed factor": 0.2578421333,
      "Uz factor": 0.9489735927,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5928957645,
      "Direction stability factor": 0.0162695182,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0309502851, 0.2394460481, 0.8591252646],
      direction: [-175.8491803766, -8.0886020344, 165.2054819375],
      "speed factor": 0.30408162,
      "Uz factor": 0.9422380589,
      "Directional variance factor": 0.2810131525,
      "Velocity stability factor": 0.3329593224,
      "Direction stability factor": 0.052625938,
      score: 0.0,
    },
    id: "IDN:384.0",
    "45": {
      velocity: [0.0596542709, 0.5270369499, 0.9780196872],
      direction: [-148.8543028182, 20.1040190155, 124.487225108],
      "speed factor": 0.7159172639,
      "Uz factor": 0.8865621257,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2533979765,
      "Direction stability factor": 0.240717978,
      score: 0.0,
    },
    "90": {
      velocity: [0.4758905985, 0.9972564262, 1.4202129929],
      direction: [-48.0104130595, -9.8744464984, 15.912895243],
      "speed factor": 1.6165818498,
      "Uz factor": 0.8465803846,
      "Directional variance factor": 0.1222714224,
      "Velocity stability factor": 0.3107791881,
      "Direction stability factor": 0.8224352547,
      score: 0.0,
    },
    "135": {
      velocity: [0.1117007554, 0.4794207898, 0.8907468125],
      direction: [-171.3230852126, -56.475712046, 129.3163514438],
      "speed factor": 0.4057579411,
      "Uz factor": 0.8484513088,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3722362661,
      "Direction stability factor": 0.1648904537,
      score: 0.0,
    },
    "180": {
      velocity: [0.0925693852, 0.3640559072, 0.8376314807],
      direction: [-170.8962279763, -68.9159704581, 170.2203470515],
      "speed factor": 0.7109989377,
      "Uz factor": 0.9533304326,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4129231954,
      "Direction stability factor": 0.0524539583,
      score: 0.0,
    },
    "225": {
      velocity: [0.0149779818, 0.2447934052, 0.7502065537],
      direction: [-175.4416368519, -11.0197642602, 176.378406635],
      "speed factor": 0.3341697872,
      "Uz factor": 0.9995479691,
      "Directional variance factor": 0.0204653991,
      "Velocity stability factor": 0.3391908157,
      "Direction stability factor": 0.0227221014,
      score: 0.0,
    },
    "270": {
      velocity: [0.0506619185, 0.2333235936, 0.7503285931],
      direction: [-179.1292661545, 109.6899151371, 176.5804484941],
      "speed factor": 0.213498622,
      "Uz factor": 0.9055411051,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4481006852,
      "Direction stability factor": 0.0119174593,
      score: 0.0,
    },
    "315": {
      velocity: [0.0420928663, 0.2002091987, 0.7014012791],
      direction: [-69.9421921842, 106.1671652424, 162.4937063423],
      "speed factor": 0.2466776572,
      "Uz factor": 0.8737282508,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4651453011,
      "Direction stability factor": 0.3543447263,
      score: 0.0,
    },
  },
  {
    position: [-50.07556487, 49.15221146, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1146475248, 0.4399368108, 1.0104519721],
      direction: [-162.9007485582, -5.7210870868, 122.1133209067],
      "speed factor": 0.4508257085,
      "Uz factor": 0.9632422655,
      "Directional variance factor": 0.4914589256,
      "Velocity stability factor": 0.3775674852,
      "Direction stability factor": 0.2082942515,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1221798156, 0.5497694498, 1.0935658749],
      direction: [-84.6465545609, 21.0855709367, 116.36569198],
      "speed factor": 0.4881269082,
      "Uz factor": 0.9236247818,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.371830426,
      "Direction stability factor": 0.4416326485,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.111601693, 0.379011073, 0.7292444577],
      direction: [-151.1345828681, -23.657208045, 17.4257696511],
      "speed factor": 0.5871134188,
      "Uz factor": 0.8164074863,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4481025118,
      "Direction stability factor": 0.5317767986,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0422580224, 0.4394180235, 0.9041236417],
      direction: [-178.4187429025, -67.8463525432, 160.9263963128],
      "speed factor": 0.4943145783,
      "Uz factor": 0.6731239961,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2865312394,
      "Direction stability factor": 0.0573746133,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0962062162, 0.503433751, 0.9870629547],
      direction: [-157.0012154316, -12.895887973, 137.8842839941],
      "speed factor": 0.7151984722,
      "Uz factor": 0.9999963216,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2714649808,
      "Direction stability factor": 0.1808736127,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0495440518, 0.3535409441, 0.8139304337],
      direction: [-146.4217231041, -13.5483800883, 179.5529473803],
      "speed factor": 0.4142281664,
      "Uz factor": 0.9882699183,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.41226171,
      "Direction stability factor": 0.0945148042,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0243180609, 0.301881775, 0.7656398327],
      direction: [-39.8001896866, -0.8816384054, 162.1114351977],
      "speed factor": 0.5600270713,
      "Uz factor": 0.9946848943,
      "Directional variance factor": 0.9216321417,
      "Velocity stability factor": 0.2806600125,
      "Direction stability factor": 0.4391343753,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0563881394, 0.1991223407, 0.4183709219],
      direction: [-19.9627757673, 91.1800920112, 179.232539284],
      "speed factor": 0.2711549822,
      "Uz factor": 0.9979138144,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.73454365,
      "Direction stability factor": 0.4466796804,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.027522361, 0.2816636228, 0.8829800617],
      direction: [-174.8822477197, -0.7816661719, 168.8208431475],
      "speed factor": 0.3576953198,
      "Uz factor": 0.9850770256,
      "Directional variance factor": 0.9305185625,
      "Velocity stability factor": 0.3109848783,
      "Direction stability factor": 0.045269192,
      score: 0.0,
    },
    id: "IDN:385.0",
    "45": {
      velocity: [0.0578359827, 0.4490051068, 0.8571507921],
      direction: [-137.6032719137, -0.0712419735, 117.6545928325],
      "speed factor": 0.6099202486,
      "Uz factor": 0.8605795363,
      "Directional variance factor": 0.9936673801,
      "Velocity stability factor": 0.3501823529,
      "Direction stability factor": 0.2909503757,
      score: 0.0,
    },
    "90": {
      velocity: [0.370297637, 0.6814391981, 1.1045881277],
      direction: [-92.9625512363, -41.925451022, -9.7768989109],
      "speed factor": 1.1046328812,
      "Uz factor": 0.78352235,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4640725549,
      "Direction stability factor": 0.7689287435,
      score: 0.0,
    },
    "135": {
      velocity: [0.1663141447, 0.4929769437, 0.8976684391],
      direction: [-161.8030927133, -71.262205331, 1.2817270498],
      "speed factor": 0.4172311965,
      "Uz factor": 0.889077384,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4106668039,
      "Direction stability factor": 0.5469866118,
      score: 0.0,
    },
    "180": {
      velocity: [0.0802976366, 0.3146945552, 0.7660941171],
      direction: [-175.1262215512, -88.1738925727, 179.660933656],
      "speed factor": 0.6145965223,
      "Uz factor": 0.9535110251,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4596219445,
      "Direction stability factor": 0.0144801244,
      score: 0.0,
    },
    "225": {
      velocity: [0.0272101754, 0.2593195157, 0.6411119666],
      direction: [-168.4065908638, -2.827614236, 144.8401433883],
      "speed factor": 0.3539995177,
      "Uz factor": 0.9993872578,
      "Directional variance factor": 0.7486565124,
      "Velocity stability factor": 0.4482369737,
      "Direction stability factor": 0.1298701826,
      score: 0.0,
    },
    "270": {
      velocity: [0.0624280274, 0.2347126144, 0.5152592524],
      direction: [3.3274501089, 89.1060279517, 155.7799076385],
      "speed factor": 0.214769621,
      "Uz factor": 0.9996872073,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6428052788,
      "Direction stability factor": 0.5765209513,
      score: 0.0,
    },
    "315": {
      velocity: [0.0465763175, 0.1802490735, 0.6108164418],
      direction: [-16.5459675437, 80.6430781901, 160.0980801827],
      "speed factor": 0.2220847966,
      "Uz factor": 0.9589793956,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5422681162,
      "Direction stability factor": 0.5093220896,
      score: 0.0,
    },
  },
  {
    position: [-54.87082048, 44.06423051, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2442536838, 0.9875406482, 1.4400278555],
      direction: [-29.0753415445, -13.9869788715, 69.7084079357],
      "speed factor": 1.0119833154,
      "Uz factor": 0.9861749441,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1691392836,
      "Direction stability factor": 0.7256006959,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1168758704, 0.5250946316, 1.0586268758],
      direction: [-105.8325771364, -10.8100923195, 105.161923966],
      "speed factor": 0.466218738,
      "Uz factor": 0.9997963076,
      "Directional variance factor": 0.0391029049,
      "Velocity stability factor": 0.3909946285,
      "Direction stability factor": 0.4139041636,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0110554934, 0.3244878125, 0.9807042543],
      direction: [-179.3170831418, 8.2248485407, 176.7052229327],
      "speed factor": 0.5026532536,
      "Uz factor": 0.9997932542,
      "Directional variance factor": 0.2689023519,
      "Velocity stability factor": 0.1335659605,
      "Direction stability factor": 0.0110491498,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0487038191, 0.244825601, 0.699690995],
      direction: [-178.9442257262, -78.767251022, 176.2492028314],
      "speed factor": 0.2754116974,
      "Uz factor": 0.9794313548,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4611004278,
      "Direction stability factor": 0.0133515873,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0328413236, 0.2311128088, 0.8522329456],
      direction: [-172.0533973819, 51.2229070278, 179.9969377143],
      "speed factor": 0.3283282606,
      "Uz factor": 0.7940122717,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3299085417,
      "Direction stability factor": 0.0220824025,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1790576803, 0.8037207309, 1.207799246],
      direction: [-77.2130509383, -7.1630339267, 10.5866673454],
      "speed factor": 0.9416837575,
      "Uz factor": 0.9931801431,
      "Directional variance factor": 0.3632858732,
      "Velocity stability factor": 0.2089984555,
      "Direction stability factor": 0.7561118937,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4269093422, 0.7641319939, 0.9875719276],
      direction: [-17.8767305335, -4.5958322161, 12.9205557692],
      "speed factor": 1.4175569314,
      "Uz factor": 0.9328095611,
      "Directional variance factor": 0.5914815808,
      "Velocity stability factor": 0.4559622657,
      "Direction stability factor": 0.9144519825,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.6490005485, 1.2037013307, 1.4185160939],
      direction: [-28.3034258799, 10.2834560604, 30.7080946248],
      "speed factor": 1.6391411017,
      "Uz factor": 0.8275625975,
      "Directional variance factor": 0.0859150169,
      "Velocity stability factor": 0.4356836903,
      "Direction stability factor": 0.8360791097,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3604119161, 0.8631849176, 1.1053416645],
      direction: [-31.2735806551, -3.6105463879, 13.2065602908],
      "speed factor": 1.0961912724,
      "Uz factor": 0.9365896631,
      "Directional variance factor": 0.6790625433,
      "Velocity stability factor": 0.4000079012,
      "Direction stability factor": 0.8764440529,
      score: 0.0,
    },
    id: "IDN:375.0",
    "45": {
      velocity: [0.0522993813, 0.3495749818, 0.9118896896],
      direction: [-159.1900616943, 8.6769673369, 103.1611648439],
      "speed factor": 0.4748562022,
      "Uz factor": 0.9970941458,
      "Directional variance factor": 0.2287140145,
      "Velocity stability factor": 0.3011802795,
      "Direction stability factor": 0.2712465929,
      score: 0.0,
    },
    "90": {
      velocity: [0.0820998835, 0.3922476181, 0.8832561276],
      direction: [-168.8481540625, -33.2465202369, 172.9053422393],
      "speed factor": 0.6358448674,
      "Uz factor": 0.9867820456,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4152700812,
      "Direction stability factor": 0.0506847325,
      score: 0.0,
    },
    "135": {
      velocity: [0.057614392, 0.3699731385, 0.927208327],
      direction: [-176.7978173931, -35.4298752068, 179.6858361599],
      "speed factor": 0.3131268859,
      "Uz factor": 0.9997244768,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2992718073,
      "Direction stability factor": 0.009767629,
      score: 0.0,
    },
    "180": {
      velocity: [0.0887100695, 0.4773722461, 1.0493042819],
      direction: [-176.6265907083, -14.733617134, 27.8407987499],
      "speed factor": 0.9323050476,
      "Uz factor": 0.9975944904,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2430931809,
      "Direction stability factor": 0.4320350293,
      score: 0.0,
    },
    "225": {
      velocity: [0.0998464873, 0.524563062, 0.9270139998],
      direction: [-63.0545557034, 1.3157118698, 67.8209264192],
      "speed factor": 0.7160859854,
      "Uz factor": 0.9421367405,
      "Directional variance factor": 0.8830478338,
      "Velocity stability factor": 0.2565578786,
      "Direction stability factor": 0.6364569941,
      score: 0.0,
    },
    "270": {
      velocity: [0.7783273496, 1.0742001619, 1.3630988273],
      direction: [-2.5003850419, 21.0949016356, 40.6501554595],
      "speed factor": 0.9829278336,
      "Uz factor": 0.8325073903,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5387303847,
      "Direction stability factor": 0.8801373875,
      score: 0.0,
    },
    "315": {
      velocity: [0.4525571765, 1.0903533836, 1.3500783995],
      direction: [-20.9218888997, 0.0976145251, 22.9861467252],
      "speed factor": 1.3434238784,
      "Uz factor": 0.9048642867,
      "Directional variance factor": 0.9913231533,
      "Velocity stability factor": 0.2718985012,
      "Direction stability factor": 0.8780332344,
      score: 0.0,
    },
  },
  {
    position: [-50.54083578, 38.75377202, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0972187198, 0.5833213957, 1.0589592635],
      direction: [-139.3078121527, -7.8224445667, 120.9830546106],
      "speed factor": 0.5977592123,
      "Uz factor": 0.9958471285,
      "Directional variance factor": 0.3046715941,
      "Velocity stability factor": 0.331753055,
      "Direction stability factor": 0.2769698145,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0393695136, 0.4977201593, 1.5614581542],
      direction: [-162.9780955057, -2.0294964741, 169.8970341743],
      "speed factor": 0.4419136106,
      "Uz factor": 0.9997421921,
      "Directional variance factor": 0.8196003134,
      "Velocity stability factor": 0.0157056879,
      "Direction stability factor": 0.075346862,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0217310194, 0.2983622598, 0.7546786387],
      direction: [-169.6636741534, 55.0697434368, 177.2892650225],
      "speed factor": 0.4621830308,
      "Uz factor": 0.8052882911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.345071337,
      "Direction stability factor": 0.0362418356,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1558092324, 0.4457677506, 0.9784699767],
      direction: [-90.4190428376, 18.3658412326, 84.8131581338],
      "speed factor": 0.5014575777,
      "Uz factor": 0.7154486177,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3189857809,
      "Direction stability factor": 0.5132438862,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.186712542, 0.6612246664, 1.1229404199],
      direction: [-82.9083001351, -22.8856691438, 1.1440381398],
      "speed factor": 0.9393626675,
      "Uz factor": 0.6562753054,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2343608512,
      "Direction stability factor": 0.7665212826,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0584888181, 0.2655189792, 0.6462836336],
      direction: [-128.7091228042, -58.9890895747, 165.5555186777],
      "speed factor": 0.3110967533,
      "Uz factor": 0.5856938915,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5480433352,
      "Direction stability factor": 0.1825982181,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0431309123, 0.2487006347, 0.733791224],
      direction: [-88.0621618221, -13.3516104107, 12.3829446221],
      "speed factor": 0.4613696473,
      "Uz factor": 0.9995335397,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3298192515,
      "Direction stability factor": 0.7209858154,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.033577598, 0.1441142154, 0.3668793632],
      direction: [-174.6524217469, 87.995575358, 177.3859202688],
      "speed factor": 0.1962476303,
      "Uz factor": 0.9995726626,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7555765789,
      "Direction stability factor": 0.0221157166,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0592167657, 0.2359586673, 0.6547435984],
      direction: [-170.9134772628, 25.5655459333, 169.576718083],
      "speed factor": 0.2996528629,
      "Uz factor": 0.9856197428,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5203421598,
      "Direction stability factor": 0.0541939018,
      score: 0.0,
    },
    id: "IDN:393.0",
    "45": {
      velocity: [0.0629775284, 0.3755976691, 1.01318718],
      direction: [-178.6411632821, 27.4983961021, 127.0591696677],
      "speed factor": 0.5102049403,
      "Uz factor": 0.9852614055,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2275096208,
      "Direction stability factor": 0.1508324085,
      score: 0.0,
    },
    "90": {
      velocity: [0.0679791085, 0.5983073681, 1.1227334087],
      direction: [-140.1535130407, 49.1776979963, 79.2390204632],
      "speed factor": 0.9698737521,
      "Uz factor": 0.647507928,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.23017963,
      "Direction stability factor": 0.3905762958,
      score: 0.0,
    },
    "135": {
      velocity: [0.0744395328, 0.4270684415, 0.861442778],
      direction: [-126.8426190166, -6.2766736418, 166.7268559998],
      "speed factor": 0.3614495142,
      "Uz factor": 0.8571896083,
      "Directional variance factor": 0.4420734541,
      "Velocity stability factor": 0.3658242779,
      "Direction stability factor": 0.1845292361,
      score: 0.0,
    },
    "180": {
      velocity: [0.3006118562, 0.6514583121, 1.0508558781],
      direction: [-97.1340750546, -45.6987375289, -29.8340032366],
      "speed factor": 1.2722940591,
      "Uz factor": 0.763020902,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4088400609,
      "Direction stability factor": 0.8130553561,
      score: 0.0,
    },
    "225": {
      velocity: [0.0423356405, 0.2596848073, 0.6249194817],
      direction: [-113.3038509113, -8.9662572177, 111.2328412553],
      "speed factor": 0.3544981806,
      "Uz factor": 0.9998673851,
      "Directional variance factor": 0.2029993584,
      "Velocity stability factor": 0.4763849399,
      "Direction stability factor": 0.3762869662,
      score: 0.0,
    },
    "270": {
      velocity: [0.0411750543, 0.2312245917, 0.7476791487],
      direction: [-174.340876613, 28.8906304938, 165.7928192563],
      "speed factor": 0.2115779675,
      "Uz factor": 0.9809152967,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4427073065,
      "Direction stability factor": 0.0551841781,
      score: 0.0,
    },
    "315": {
      velocity: [0.0443028652, 0.2196019864, 0.6889550011],
      direction: [-34.6423731169, 17.3669560796, 171.0338543532],
      "speed factor": 0.2705715016,
      "Uz factor": 0.9796705554,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.477035,
      "Direction stability factor": 0.4286771459,
      score: 0.0,
    },
  },
  {
    position: [3.561135135, 102.0686813, 46.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.6562006468, 1.0403944233, 1.3290432851],
      direction: [-32.4848575003, -6.9522345596, 15.4679890748],
      "speed factor": 1.0661452769,
      "Uz factor": 0.911971633,
      "Directional variance factor": 0.3820235947,
      "Velocity stability factor": 0.5324882159,
      "Direction stability factor": 0.8667976484,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6748325561, 0.9593947468, 1.3051739335],
      direction: [-49.0423013544, -11.8578245648, 15.1238150287],
      "speed factor": 0.8518232355,
      "Uz factor": 0.9105818605,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.592374967,
      "Direction stability factor": 0.8217607878,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1441383261, 0.4755842617, 0.8932367401],
      direction: [-167.2662946463, -4.6821226479, 81.3271792909],
      "speed factor": 0.7367117262,
      "Uz factor": 0.9890328143,
      "Directional variance factor": 0.5838113202,
      "Velocity stability factor": 0.3306397213,
      "Direction stability factor": 0.3094625724,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1379928958, 0.7939516389, 1.1795369706],
      direction: [-69.3876208537, 7.6966616354, 56.1924822221],
      "speed factor": 0.8931401276,
      "Uz factor": 0.9999999697,
      "Directional variance factor": 0.3158522991,
      "Velocity stability factor": 0.1377899947,
      "Direction stability factor": 0.6511663803,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0991225839, 0.4688216232, 0.9929955568],
      direction: [-134.863222786, 8.9211044612, 161.7820030774],
      "speed factor": 0.6660270751,
      "Uz factor": 0.9999066872,
      "Directional variance factor": 0.2070129368,
      "Velocity stability factor": 0.2689983301,
      "Direction stability factor": 0.1759854837,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0552780247, 0.3371664662, 0.9453552612],
      direction: [-164.5699796179, -28.850988079, 174.2570191684],
      "speed factor": 0.3950429205,
      "Uz factor": 0.999930722,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3156177486,
      "Direction stability factor": 0.0588138923,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0659644897, 0.4566995963, 0.9317984276],
      direction: [-40.6395824581, -3.3281797577, 49.9235131435],
      "speed factor": 0.8472327863,
      "Uz factor": 0.9995664075,
      "Directional variance factor": 0.7041617993,
      "Velocity stability factor": 0.1598398999,
      "Direction stability factor": 0.7484358456,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1202689514, 0.5071659732, 0.8980429942],
      direction: [-49.2511392876, 17.1794904833, 126.8824174792],
      "speed factor": 0.6906336073,
      "Uz factor": 0.8815580726,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4296274063,
      "Direction stability factor": 0.5107401201,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0544809548, 0.5524241771, 1.0748644093],
      direction: [-90.6513019113, 10.229695698, 137.7456584509],
      "speed factor": 0.7015444192,
      "Uz factor": 0.9534461787,
      "Directional variance factor": 0.0906937157,
      "Velocity stability factor": 0.1781479908,
      "Direction stability factor": 0.365563999,
      score: 0.0,
    },
    id: "IDN:401.0",
    "45": {
      velocity: [0.1917253114, 0.5962483635, 1.0420606968],
      direction: [-63.7743145151, -5.2589743334, 40.7431219646],
      "speed factor": 0.8099327704,
      "Uz factor": 0.9841595346,
      "Directional variance factor": 0.5325356148,
      "Velocity stability factor": 0.308704239,
      "Direction stability factor": 0.7096737876,
      score: 0.0,
    },
    "90": {
      velocity: [0.0820372199, 0.5862271445, 1.0088322293],
      direction: [-45.9049467744, 10.3636604794, 87.8977843357],
      "speed factor": 0.950291356,
      "Uz factor": 0.9993525016,
      "Directional variance factor": 0.0787857352,
      "Velocity stability factor": 0.3235716821,
      "Direction stability factor": 0.6283257469,
      score: 0.0,
    },
    "135": {
      velocity: [0.0913322111, 0.8324484705, 1.3076068029],
      direction: [2.3683265146, 10.6701619339, 38.7560608331],
      "speed factor": 0.7045430334,
      "Uz factor": 0.9999889508,
      "Directional variance factor": 0.0515411614,
      "Velocity stability factor": 0.0199127865,
      "Direction stability factor": 0.8989229602,
      score: 0.0,
    },
    "180": {
      velocity: [0.058915207, 0.3347248567, 0.9147861869],
      direction: [-166.9570025563, 6.7015613183, 176.0259535175],
      "speed factor": 0.6537155774,
      "Uz factor": 0.9989123745,
      "Directional variance factor": 0.4043056606,
      "Velocity stability factor": 0.3256105725,
      "Direction stability factor": 0.0472695665,
      score: 0.0,
    },
    "225": {
      velocity: [0.0415416326, 0.3860163409, 0.7639417173],
      direction: [-150.77903255, -14.1621109735, 169.4946994064],
      "speed factor": 0.5269545493,
      "Uz factor": 0.9998656366,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3507208113,
      "Direction stability factor": 0.1103507446,
      score: 0.0,
    },
    "270": {
      velocity: [0.1613651544, 0.9904990693, 1.3140135643],
      direction: [-93.8929953875, 1.9892444107, 16.9314062742],
      "speed factor": 0.9063386312,
      "Uz factor": 0.9981878488,
      "Directional variance factor": 0.8231782746,
      "Velocity stability factor": 0.0907872409,
      "Direction stability factor": 0.6921544398,
      score: 0.0,
    },
    "315": {
      velocity: [0.6902141727, 0.9040533831, 1.1533279804],
      direction: [-27.2859148447, -12.68882322, 9.4154085419],
      "speed factor": 1.1138837377,
      "Uz factor": 0.9420828592,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6243054215,
      "Direction stability factor": 0.8980518795,
      score: 0.0,
    },
  },
  {
    position: [-50.55130774, 35.7537903, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0874405124, 0.6192480318, 1.1164550821],
      direction: [-81.648026921, -5.7172566145, 130.2732960552],
      "speed factor": 0.6345750703,
      "Uz factor": 0.9955963372,
      "Directional variance factor": 0.491799412,
      "Velocity stability factor": 0.2850089902,
      "Direction stability factor": 0.4113296584,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0868187313, 0.4907538298, 1.1472890438],
      direction: [-128.0053688446, -11.2913605074, 171.9861605957],
      "speed factor": 0.4357283762,
      "Uz factor": 0.9969096842,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3142220047,
      "Direction stability factor": 0.166690196,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0909403842, 0.5377949348, 0.830197572],
      direction: [-77.6544549898, 12.4723161475, 53.696547191],
      "speed factor": 0.8330802061,
      "Uz factor": 0.9394901463,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3394333935,
      "Direction stability factor": 0.6351361051,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4926919084, 0.8542253104, 1.1852399593],
      direction: [-38.7157767478, -24.2525750619, 9.8373726159],
      "speed factor": 0.9609437973,
      "Uz factor": 0.8925582101,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4266955445,
      "Direction stability factor": 0.8651301407,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5483481959, 0.9293597964, 1.2866622554],
      direction: [-52.8496256227, -24.5653708764, 3.7561102381],
      "speed factor": 1.320286344,
      "Uz factor": 0.9001096103,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3962130787,
      "Direction stability factor": 0.8427618448,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0317816447, 0.2018647242, 0.8744050459],
      direction: [-144.8409221026, -56.7867038324, 172.935465463],
      "speed factor": 0.2365158999,
      "Uz factor": 0.9880463813,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.352105102,
      "Direction stability factor": 0.1172878123,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0163048284, 0.2328671679, 0.6379464902],
      direction: [-169.1217039875, -7.1481686888, 148.1624107474],
      "speed factor": 0.4319966584,
      "Uz factor": 0.9998821201,
      "Directional variance factor": 0.3646072277,
      "Velocity stability factor": 0.3967913499,
      "Direction stability factor": 0.1186552368,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.045096682, 0.1408488541, 0.4176507216],
      direction: [-178.8239871346, 123.5157672034, 179.2224784143],
      "speed factor": 0.1918010224,
      "Uz factor": 0.9218967564,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7267913273,
      "Direction stability factor": 0.0054264846,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0239619865, 0.2502249286, 0.6069091984],
      direction: [-106.4136877096, 50.2191362637, 155.6718388157],
      "speed factor": 0.3177701293,
      "Uz factor": 0.9940817898,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.53047422,
      "Direction stability factor": 0.2719846485,
      score: 0.0,
    },
    id: "IDN:394.0",
    "45": {
      velocity: [0.069746656, 0.4025244911, 0.9624061892],
      direction: [-104.5626979981, 27.0207003894, 172.2697301416],
      "speed factor": 0.5467818383,
      "Uz factor": 0.9402636323,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2742960461,
      "Direction stability factor": 0.2310210329,
      score: 0.0,
    },
    "90": {
      velocity: [0.3832617344, 0.924195593, 1.4243966489],
      direction: [-56.1046105742, 3.2092395511, 37.7840307604],
      "speed factor": 1.4981481011,
      "Uz factor": 0.9033015562,
      "Directional variance factor": 0.7147342621,
      "Velocity stability factor": 0.2401198411,
      "Direction stability factor": 0.7391982185,
      score: 0.0,
    },
    "135": {
      velocity: [0.093796549, 0.6115714956, 1.0492002191],
      direction: [-82.7224570206, -38.0227942909, 59.9354414592],
      "speed factor": 0.517603734,
      "Uz factor": 0.9240994921,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2301253952,
      "Direction stability factor": 0.6037280598,
      score: 0.0,
    },
    "180": {
      velocity: [0.1159743911, 0.6541801065, 1.0729904744],
      direction: [-169.0468559203, -34.3409540021, -9.4839334728],
      "speed factor": 1.2776097067,
      "Uz factor": 0.9337333984,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2459125924,
      "Direction stability factor": 0.5567696599,
      score: 0.0,
    },
    "225": {
      velocity: [0.0289357872, 0.304971593, 0.715374113],
      direction: [-170.5726668869, 0.9599857299, 124.2847799925],
      "speed factor": 0.4163195991,
      "Uz factor": 0.9997878418,
      "Directional variance factor": 0.9146679351,
      "Velocity stability factor": 0.3830425429,
      "Direction stability factor": 0.1809515364,
      score: 0.0,
    },
    "270": {
      velocity: [0.0453557444, 0.3181090943, 0.8533734271],
      direction: [-179.5401974288, 39.6910025946, 179.1076166786],
      "speed factor": 0.2910800929,
      "Uz factor": 0.991997462,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3626330628,
      "Direction stability factor": 0.0037560719,
      score: 0.0,
    },
    "315": {
      velocity: [0.0622232354, 0.2185062765, 0.968740592],
      direction: [-6.467683485, 95.7848528572, 171.4784985312],
      "speed factor": 0.2692214779,
      "Uz factor": 0.9107734436,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2646005141,
      "Direction stability factor": 0.50570505,
      score: 0.0,
    },
  },
  {
    position: [-54.97810694, 41.06614952, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1569435203, 0.9544419956, 1.4127693528],
      direction: [-35.5386112858, -14.8091679779, 136.7017878376],
      "speed factor": 0.9780654364,
      "Uz factor": 0.9832872731,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1274135405,
      "Direction stability factor": 0.5215544469,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0617736141, 0.5274340617, 0.994650521],
      direction: [-155.4090726559, -9.0359330542, 100.0506523078],
      "speed factor": 0.468295861,
      "Uz factor": 0.999961674,
      "Directional variance factor": 0.1968059507,
      "Velocity stability factor": 0.3967332723,
      "Direction stability factor": 0.2903896529,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0355211469, 0.3030194351, 0.8649505582],
      direction: [-178.3863703571, 10.4870927996, 176.3711964579],
      "speed factor": 0.4693973057,
      "Uz factor": 0.9988548714,
      "Directional variance factor": 0.0678139734,
      "Velocity stability factor": 0.2588595951,
      "Direction stability factor": 0.0145623144,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0590876763, 0.3185568576, 0.6943602861],
      direction: [-179.4326223273, -26.4556759961, 69.0947610362],
      "speed factor": 0.3583542101,
      "Uz factor": 0.9983038952,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4741092446,
      "Direction stability factor": 0.3096461573,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0280704657, 0.3526405558, 1.221177029],
      direction: [-167.8270029848, -10.8387866447, 169.2025041222],
      "speed factor": 0.5009755231,
      "Uz factor": 0.9984629843,
      "Directional variance factor": 0.0365522982,
      "Velocity stability factor": 0.0242876599,
      "Direction stability factor": 0.0638069247,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1031599746, 0.8128707491, 1.1838079046],
      direction: [-172.9777659294, -8.4876000078, 8.5195272195],
      "speed factor": 0.9524044259,
      "Uz factor": 0.9918448576,
      "Directional variance factor": 0.245546666,
      "Velocity stability factor": 0.1690875433,
      "Direction stability factor": 0.4958408524,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3913001739, 0.7572796651, 0.971234403],
      direction: [-13.3440558271, 0.6875934767, 15.8415676992],
      "speed factor": 1.4048450357,
      "Uz factor": 0.9189844567,
      "Directional variance factor": 0.9388805798,
      "Velocity stability factor": 0.4372620677,
      "Direction stability factor": 0.9189288235,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.6550459816, 1.2152273686, 1.4331854064],
      direction: [-17.7993959314, 14.6048183612, 30.8622459428],
      "speed factor": 1.6548366917,
      "Uz factor": 0.8367990073,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4293594572,
      "Direction stability factor": 0.8648287726,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3055439966, 0.8395603226, 1.1363353685],
      direction: [-28.0915651177, -3.7663410867, 20.5828055175],
      "speed factor": 1.0661895029,
      "Uz factor": 0.9362566357,
      "Directional variance factor": 0.6652141256,
      "Velocity stability factor": 0.3308519898,
      "Direction stability factor": 0.8647934149,
      score: 0.0,
    },
    id: "IDN:374.0",
    "45": {
      velocity: [0.0396401677, 0.3799908235, 1.0765442924],
      direction: [-147.065438172, 15.2150354133, 171.2852363056],
      "speed factor": 0.5161725201,
      "Uz factor": 0.9973714217,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1570297576,
      "Direction stability factor": 0.1156925709,
      score: 0.0,
    },
    "90": {
      velocity: [0.0633972714, 0.3557111137, 0.7730479089],
      direction: [-150.0457512084, -2.2283767249, 176.0618022464],
      "speed factor": 0.5766181245,
      "Uz factor": 0.9923358647,
      "Directional variance factor": 0.8019220689,
      "Velocity stability factor": 0.4820561374,
      "Direction stability factor": 0.0941456848,
      score: 0.0,
    },
    "135": {
      velocity: [0.0879409141, 0.3304198577, 0.8203349718],
      direction: [-176.3805628079, -38.5399893015, 171.3897262839],
      "speed factor": 0.2796509539,
      "Uz factor": 0.9999835219,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4098289514,
      "Direction stability factor": 0.0339714192,
      score: 0.0,
    },
    "180": {
      velocity: [0.1943514627, 0.6188861914, 1.0872027273],
      direction: [-111.6094645204, -23.4166321112, 44.0491664523],
      "speed factor": 1.2086809085,
      "Uz factor": 0.9952009805,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2964717027,
      "Direction stability factor": 0.567614914,
      score: 0.0,
    },
    "225": {
      velocity: [0.0760695301, 0.5261302346, 0.9255724449],
      direction: [-136.0892212332, 4.3348784374, 109.8725310061],
      "speed factor": 0.7182253475,
      "Uz factor": 0.9329906189,
      "Directional variance factor": 0.6146774722,
      "Velocity stability factor": 0.2364832521,
      "Direction stability factor": 0.3167729104,
      score: 0.0,
    },
    "270": {
      velocity: [0.7773740457, 1.0613491979, 1.3701624606],
      direction: [1.3187570389, 25.3076465914, 45.7449579995],
      "speed factor": 0.9711687866,
      "Uz factor": 0.7875773939,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5324065988,
      "Direction stability factor": 0.8765938862,
      score: 0.0,
    },
    "315": {
      velocity: [0.4779011946, 1.1066563611, 1.365632496],
      direction: [-16.2021567332, 3.0979682989, 31.7387110328],
      "speed factor": 1.3635107692,
      "Uz factor": 0.8951190625,
      "Directional variance factor": 0.7246250401,
      "Velocity stability factor": 0.2798404378,
      "Direction stability factor": 0.8668309229,
      score: 0.0,
    },
  },
  {
    position: [-55.0853934, 38.06806853, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0813820145, 0.9748324747, 1.4138590328],
      direction: [-30.1443975541, -14.3698671, 89.6485128704],
      "speed factor": 0.9989606013,
      "Uz factor": 0.9832019172,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0741539363,
      "Direction stability factor": 0.6672419155,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1362708327, 0.5575885108, 1.1598962638],
      direction: [-126.705431469, -3.3456288612, 92.2306221324],
      "speed factor": 0.4950692622,
      "Uz factor": 0.997131429,
      "Directional variance factor": 0.7026107679,
      "Velocity stability factor": 0.3380486112,
      "Direction stability factor": 0.3918442956,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0494317948, 0.242458849, 0.8116871653],
      direction: [-178.4248606136, 17.8281025807, 169.3080925568],
      "speed factor": 0.3755849206,
      "Uz factor": 0.9999976579,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3188832632,
      "Direction stability factor": 0.0340751301,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0511748115, 0.2528937904, 0.8177155657],
      direction: [-174.5671849805, -34.3010001383, 177.6425310186],
      "speed factor": 0.2844878468,
      "Uz factor": 0.9803597759,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3654429767,
      "Direction stability factor": 0.0216396778,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0426327574, 0.6918742, 1.2375682625],
      direction: [-174.3957958263, -21.133773957, 161.1012919981],
      "speed factor": 0.9829046421,
      "Uz factor": 0.9974696531,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.022791967,
      "Direction stability factor": 0.0680636449,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0958619431, 0.8209180622, 1.1613736136],
      direction: [-132.1755980323, -9.9965955439, 12.8582847572],
      "speed factor": 0.961833104,
      "Uz factor": 0.9907051075,
      "Directional variance factor": 0.1114137294,
      "Velocity stability factor": 0.1807258449,
      "Direction stability factor": 0.5971281034,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3823268795, 0.7510407538, 0.9549173996],
      direction: [-16.2156730073, 2.7170019671, 14.92156954],
      "speed factor": 1.3932711035,
      "Uz factor": 0.926590105,
      "Directional variance factor": 0.758488714,
      "Velocity stability factor": 0.4443880199,
      "Direction stability factor": 0.9135076596,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.7044408484, 1.2267115214, 1.4420148715],
      direction: [-11.5194357438, 18.8158780941, 33.9486365188],
      "speed factor": 1.6704752445,
      "Uz factor": 0.8583690806,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4591076772,
      "Direction stability factor": 0.8736997993,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2770334584, 0.7954578608, 1.1025983838],
      direction: [-27.8510992625, -3.3875115459, 49.6749706682],
      "speed factor": 1.0101821137,
      "Uz factor": 0.9362617165,
      "Directional variance factor": 0.6988878626,
      "Velocity stability factor": 0.33506155,
      "Direction stability factor": 0.7846498057,
      score: 0.0,
    },
    id: "IDN:373.0",
    "45": {
      velocity: [0.063094374, 0.3814010722, 1.0138382021],
      direction: [-177.8470239686, 10.3240026049, 172.0586033616],
      "speed factor": 0.5180881759,
      "Uz factor": 0.9994610118,
      "Directional variance factor": 0.0823108796,
      "Velocity stability factor": 0.2270753523,
      "Direction stability factor": 0.0280399241,
      score: 0.0,
    },
    "90": {
      velocity: [0.048721027, 0.3132154816, 0.7283174636],
      direction: [-152.4211786455, 8.5053037673, 168.9979915278],
      "speed factor": 0.5077314614,
      "Uz factor": 0.9793863342,
      "Directional variance factor": 0.2439729985,
      "Velocity stability factor": 0.5039914223,
      "Direction stability factor": 0.1071689717,
      score: 0.0,
    },
    "135": {
      velocity: [0.0688331583, 0.3080101537, 0.6918314951],
      direction: [-179.8329270047, -41.6503251793, 177.5436737062],
      "speed factor": 0.2606844936,
      "Uz factor": 0.9928208643,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4979812059,
      "Direction stability factor": 0.0072872202,
      score: 0.0,
    },
    "180": {
      velocity: [0.044112259, 0.4976777231, 1.0111564898],
      direction: [-152.4086497521, -22.4728571692, 146.0702078528],
      "speed factor": 0.9719615187,
      "Uz factor": 0.9963501102,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2380108446,
      "Direction stability factor": 0.1708920622,
      score: 0.0,
    },
    "225": {
      velocity: [0.0809235249, 0.5348536269, 0.9205102988],
      direction: [-61.435981614, 2.3495599212, 93.7279544476],
      "speed factor": 0.7301337326,
      "Uz factor": 0.9506488411,
      "Directional variance factor": 0.7911502292,
      "Velocity stability factor": 0.2453956872,
      "Direction stability factor": 0.5689890665,
      score: 0.0,
    },
    "270": {
      velocity: [0.6442525538, 1.0183775772, 1.3718130534],
      direction: [4.3733268532, 27.1488575108, 54.6999463128],
      "speed factor": 0.9318483662,
      "Uz factor": 0.8948638422,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4260979466,
      "Direction stability factor": 0.8602038348,
      score: 0.0,
    },
    "315": {
      velocity: [0.353652521, 1.1454253883, 1.400469798],
      direction: [-8.6930703879, 6.6232147771, 23.3942570205],
      "speed factor": 1.4112780689,
      "Uz factor": 0.9233887345,
      "Directional variance factor": 0.4112697976,
      "Velocity stability factor": 0.1507841723,
      "Direction stability factor": 0.910868535,
      score: 0.0,
    },
  },
  {
    position: [-55.19267986, 35.06998754, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2620038083, 0.9376936619, 1.3771795645],
      direction: [-31.0265220055, -13.8905245773, 58.1323686005],
      "speed factor": 0.9609025639,
      "Uz factor": 0.9845754391,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2251415446,
      "Direction stability factor": 0.752336415,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1398097224, 0.5543850497, 1.1451612664],
      direction: [-117.0487536264, 0.0736267192, 56.391734775],
      "speed factor": 0.492224987,
      "Uz factor": 0.997882311,
      "Directional variance factor": 0.9934554027,
      "Velocity stability factor": 0.3498658488,
      "Direction stability factor": 0.5182208656,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0425750634, 0.2507017171, 0.6291774418],
      direction: [-175.5616255744, 12.4653749428, 170.4508283803],
      "speed factor": 0.3883536728,
      "Uz factor": 0.9593275175,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4758387895,
      "Direction stability factor": 0.0388542946,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0275537328, 0.2210339986, 0.8316834283],
      direction: [-177.7239009125, -47.2258775508, 171.5219467984],
      "speed factor": 0.2486478068,
      "Uz factor": 0.9138791123,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3343261358,
      "Direction stability factor": 0.0298726452,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0541634616, 0.5715925376, 0.980690741],
      direction: [-160.9227259228, -23.6092373003, 5.5920346457],
      "speed factor": 0.812027618,
      "Uz factor": 0.9987054744,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2422939176,
      "Direction stability factor": 0.5374589984,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0738692575, 0.8801391409, 1.1545386708],
      direction: [-62.7465966114, -12.3756533955, 1.0295456842],
      "speed factor": 1.0312198023,
      "Uz factor": 0.9786402742,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1690710247,
      "Direction stability factor": 0.8228440492,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4189233159, 0.7187374503, 0.8937216692],
      direction: [-22.0896736618, 3.5212844987, 15.2051676268],
      "speed factor": 1.3333445828,
      "Uz factor": 0.9326315215,
      "Directional variance factor": 0.6869969335,
      "Velocity stability factor": 0.5392804387,
      "Direction stability factor": 0.8964032186,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.8039609528, 1.220637039, 1.4260071859],
      direction: [1.912546138, 23.1561395672, 35.3688182796],
      "speed factor": 1.662203314,
      "Uz factor": 0.8954601685,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5438287936,
      "Direction stability factor": 0.9070659107,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2873338818, 0.7779941436, 1.0744188554],
      direction: [-24.8811366739, -1.0446187124, 39.5629911356],
      "speed factor": 0.9880042767,
      "Uz factor": 0.95272528,
      "Directional variance factor": 0.9071450033,
      "Velocity stability factor": 0.3660546297,
      "Direction stability factor": 0.8209885339,
      score: 0.0,
    },
    id: "IDN:372.0",
    "45": {
      velocity: [0.0667932352, 0.3762903155, 0.8820192448],
      direction: [-173.1889159206, 5.6970566873, 177.1644993362],
      "speed factor": 0.5111458184,
      "Uz factor": 0.9959461755,
      "Directional variance factor": 0.4935949611,
      "Velocity stability factor": 0.3372470505,
      "Direction stability factor": 0.0267960687,
      score: 0.0,
    },
    "90": {
      velocity: [0.0320016901, 0.2682635238, 0.7455101233],
      direction: [-179.9148514064, -18.0075956225, 176.1049066148],
      "speed factor": 0.4348630223,
      "Uz factor": 0.9377926926,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4792404961,
      "Direction stability factor": 0.0110562277,
      score: 0.0,
    },
    "135": {
      velocity: [0.0341824806, 0.3386337778, 0.8597234111],
      direction: [-174.248488689, -41.2897004862, 175.8098557797],
      "speed factor": 0.2866028079,
      "Uz factor": 0.9995048888,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3347701945,
      "Direction stability factor": 0.0276157098,
      score: 0.0,
    },
    "180": {
      velocity: [0.1267787409, 0.5616118523, 1.0240634891],
      direction: [-72.0577607067, -19.9633822362, 41.9940610671],
      "speed factor": 1.0968244782,
      "Uz factor": 0.9975410572,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2929783088,
      "Direction stability factor": 0.683189384,
      score: 0.0,
    },
    "225": {
      velocity: [0.1580126801, 0.5209513737, 0.8695441195],
      direction: [-55.5887816751, -0.2864112595, 49.2631691219],
      "speed factor": 0.7111556356,
      "Uz factor": 0.9328753505,
      "Directional variance factor": 0.9745412214,
      "Velocity stability factor": 0.360489339,
      "Direction stability factor": 0.7087445811,
      score: 0.0,
    },
    "270": {
      velocity: [0.5751420329, 0.9795474146, 1.3238373444],
      direction: [9.2545566202, 33.3255969743, 62.8448589539],
      "speed factor": 0.8963175136,
      "Uz factor": 0.8798047554,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4094267393,
      "Direction stability factor": 0.8511380491,
      score: 0.0,
    },
    "315": {
      velocity: [0.6784635091, 1.1529475105, 1.3880323103],
      direction: [-2.3179072596, 11.4164398965, 30.8914757737],
      "speed factor": 1.420546072,
      "Uz factor": 0.9327212509,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4243722662,
      "Direction stability factor": 0.9077517138,
      score: 0.0,
    },
  },
  {
    position: [9.532765476, 102.6514601, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2417763334, 0.664605271, 1.011141299],
      direction: [-79.5486599819, -57.8534036446, 14.5747897259],
      "speed factor": 0.6810549488,
      "Uz factor": 0.6368035066,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4654215306,
      "Direction stability factor": 0.738545973,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.3153988359, 0.5304673933, 0.7518780176],
      direction: [-92.169450555, -28.2564714542, 33.1852310008],
      "speed factor": 0.4709890823,
      "Uz factor": 0.5837382046,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7177405018,
      "Direction stability factor": 0.6517925512,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0191514242, 0.2511439859, 0.7058264677],
      direction: [-169.166686239, 6.6197414405, 179.519331221],
      "speed factor": 0.3890387767,
      "Uz factor": 0.9595670253,
      "Directional variance factor": 0.4115785386,
      "Velocity stability factor": 0.3864184065,
      "Direction stability factor": 0.0314277293,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0298080464, 0.1105503045, 0.3223041042],
      direction: [-169.6753290792, 61.3668445138, 174.741014533],
      "speed factor": 0.1243613694,
      "Uz factor": 0.9364236454,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7578661973,
      "Direction stability factor": 0.0432879344,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0171311214, 0.1717956432, 0.4778947865],
      direction: [-177.7542255223, 71.2161571375, 178.9025064073],
      "speed factor": 0.2440598814,
      "Uz factor": 0.9955596149,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6231914166,
      "Direction stability factor": 0.0092868558,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0118030771, 0.1782446221, 0.3888563005],
      direction: [-177.4855682947, -157.1238065211, 179.8746274903],
      "speed factor": 0.2088412791,
      "Uz factor": 0.9798349603,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7100829868,
      "Direction stability factor": 0.0073327895,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0324906349, 0.1976856011, 0.4608823496],
      direction: [-57.2159169795, 6.9378552575, 118.4266961768],
      "speed factor": 0.3667306124,
      "Uz factor": 0.9967992349,
      "Directional variance factor": 0.3833017549,
      "Velocity stability factor": 0.5843110207,
      "Direction stability factor": 0.5121038523,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.023113319, 0.1855297459, 0.5224930207],
      direction: [-179.9997030851, -4.3260285739, 165.9768377525],
      "speed factor": 0.252645257,
      "Uz factor": 0.8695594483,
      "Directional variance factor": 0.6154641268,
      "Velocity stability factor": 0.6337850326,
      "Direction stability factor": 0.0389540532,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0372903631, 0.2816074981, 0.6214218103],
      direction: [-93.3817504072, -7.0201508459, 110.3535134013],
      "speed factor": 0.3576240449,
      "Uz factor": 0.6483975391,
      "Directional variance factor": 0.3759865915,
      "Velocity stability factor": 0.5295203961,
      "Direction stability factor": 0.4340687116,
      score: 0.0,
    },
    id: "IDN:2.0",
    "45": {
      velocity: [0.0926026329, 0.324056764, 0.661009658],
      direction: [-89.446100308, 13.6619577787, 112.7236335762],
      "speed factor": 0.4401927263,
      "Uz factor": 0.8900329155,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5379030748,
      "Direction stability factor": 0.4384174059,
      score: 0.0,
    },
    "90": {
      velocity: [0.0054463788, 0.0927388428, 0.417197907],
      direction: [-170.9040102659, 41.9301710068, 131.0329387952],
      "speed factor": 0.150332378,
      "Uz factor": 0.9616538564,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.699480046,
      "Direction stability factor": 0.1612862526,
      score: 0.0,
    },
    "135": {
      velocity: [0.0202175132, 0.1268902673, 0.5591078962],
      direction: [-168.50167882, 72.1063241765, 176.54531053],
      "speed factor": 0.1073936189,
      "Uz factor": 0.9433226851,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5657563042,
      "Direction stability factor": 0.0415361407,
      score: 0.0,
    },
    "180": {
      velocity: [0.0365110427, 0.161704164, 0.4711405929],
      direction: [-178.7949050909, 151.6486701581, 177.2064983265],
      "speed factor": 0.3158072334,
      "Uz factor": 0.8287681297,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6575306555,
      "Direction stability factor": 0.0111072127,
      score: 0.0,
    },
    "225": {
      velocity: [0.0216594878, 0.1421619304, 0.3675211553],
      direction: [-179.0009335011, -14.8681239378, 177.4947433348],
      "speed factor": 0.1940665925,
      "Uz factor": 0.9921619325,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6891462395,
      "Direction stability factor": 0.009734231,
      score: 0.0,
    },
    "270": {
      velocity: [0.0710775431, 0.6487909527, 1.0244843777],
      direction: [-42.8841579662, -0.0374632793, 9.349122333],
      "speed factor": 0.5936646709,
      "Uz factor": 0.9905261548,
      "Directional variance factor": 0.9966699307,
      "Velocity stability factor": 0.2479496339,
      "Direction stability factor": 0.8549075547,
      score: 0.0,
    },
    "315": {
      velocity: [0.2708960658, 0.5283652966, 0.7914968733],
      direction: [-52.0030191153, -35.9134215581, -6.1500767891],
      "speed factor": 0.6509986273,
      "Uz factor": 0.9662010607,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5776698997,
      "Direction stability factor": 0.8726307158,
      score: 0.0,
    },
  },
  {
    position: [-54.69268291, 35.06824222, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.273379103, 0.8823660995, 1.3553360286],
      direction: [-35.0820625648, -16.0386213782, 123.9797977106],
      "speed factor": 0.9042055863,
      "Uz factor": 0.9901180374,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.248223011,
      "Direction stability factor": 0.5581614992,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1068558079, 0.5342708638, 1.3709962883],
      direction: [-168.059098352, 0.5241776907, 112.7515458067],
      "speed factor": 0.4743660911,
      "Uz factor": 0.9964267671,
      "Directional variance factor": 0.9534064275,
      "Velocity stability factor": 0.1825139145,
      "Direction stability factor": 0.2199704329,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0212342674, 0.2498011136, 0.6344952825],
      direction: [-175.3174520794, 16.4898044744, 175.4932171534],
      "speed factor": 0.386958578,
      "Uz factor": 0.9869440534,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.452017844,
      "Direction stability factor": 0.0255259188,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0471838081, 0.2190570496, 0.8040000821],
      direction: [-179.0156325898, -34.0623070395, 170.8133213471],
      "speed factor": 0.2464238773,
      "Uz factor": 0.9161453932,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3734930865,
      "Direction stability factor": 0.0282529057,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0542708124, 0.5026477569, 1.0187235946],
      direction: [-73.4758620207, -22.1455320443, 155.7661391227],
      "speed factor": 0.714081857,
      "Uz factor": 0.9961720498,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2112787659,
      "Direction stability factor": 0.3632166635,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0474289494, 0.7941605361, 1.1687281808],
      direction: [-152.7573333081, -9.7278028133, 48.4516998758],
      "speed factor": 0.9304825033,
      "Uz factor": 0.9823596332,
      "Directional variance factor": 0.1353064166,
      "Velocity stability factor": 0.1378306726,
      "Direction stability factor": 0.4410860189,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4223664134, 0.7665277822, 0.9632436164],
      direction: [-18.7584504446, 3.4050937579, 14.4076171127],
      "speed factor": 1.4220014075,
      "Uz factor": 0.9408424254,
      "Directional variance factor": 0.6973249993,
      "Velocity stability factor": 0.475160969,
      "Direction stability factor": 0.9078720346,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.6315688382, 1.137076366, 1.3222088366],
      direction: [-0.7405218901, 22.1079674909, 34.5299354924],
      "speed factor": 1.548414511,
      "Uz factor": 0.9041480894,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4935262615,
      "Direction stability factor": 0.9020265073,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1545015881, 0.6779621184, 1.0480946487],
      direction: [-27.6762562209, -2.1719220499, 61.418802578],
      "speed factor": 0.8609698132,
      "Uz factor": 0.9639687156,
      "Directional variance factor": 0.8069402622,
      "Velocity stability factor": 0.2802693449,
      "Direction stability factor": 0.7525137256,
      score: 0.0,
    },
    id: "IDN:371.0",
    "45": {
      velocity: [0.0799499187, 0.3600098607, 0.7971228612],
      direction: [-176.8153654037, 5.627331085, 175.344093948],
      "speed factor": 0.4890307492,
      "Uz factor": 0.9955770029,
      "Directional variance factor": 0.4997927924,
      "Velocity stability factor": 0.4169610914,
      "Direction stability factor": 0.0217792796,
      score: 0.0,
    },
    "90": {
      velocity: [0.0203275204, 0.276759253, 0.7909231997],
      direction: [-179.8614677606, -3.776139398, 175.5967465573],
      "speed factor": 0.4486348479,
      "Uz factor": 0.9547853549,
      "Directional variance factor": 0.6643431646,
      "Velocity stability factor": 0.4375749396,
      "Direction stability factor": 0.0126160713,
      score: 0.0,
    },
    "135": {
      velocity: [0.0202809864, 0.3315185371, 0.8125498623],
      direction: [-163.3833333922, -38.6269555551, 176.4694012197],
      "speed factor": 0.2805808215,
      "Uz factor": 0.9973381666,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3615811757,
      "Direction stability factor": 0.0559646261,
      score: 0.0,
    },
    "180": {
      velocity: [0.0328682934, 0.4435763427, 0.8455895332],
      direction: [-117.694542187, -22.8070065454, 62.1858753796],
      "speed factor": 0.8663018571,
      "Uz factor": 0.9958059181,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3596107071,
      "Direction stability factor": 0.5003321734,
      score: 0.0,
    },
    "225": {
      velocity: [0.1181925983, 0.5233647047, 0.9241766812],
      direction: [-57.3804601848, 2.8190098795, 49.1769622599],
      "speed factor": 0.7144500966,
      "Uz factor": 0.9430539663,
      "Directional variance factor": 0.749421344,
      "Velocity stability factor": 0.2755971344,
      "Direction stability factor": 0.7040071599,
      score: 0.0,
    },
    "270": {
      velocity: [0.6547946129, 0.9837010333, 1.2508713991],
      direction: [12.0919957413, 34.6177126043, 66.0283894156],
      "speed factor": 0.9001182088,
      "Uz factor": 0.9166622222,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5298127211,
      "Direction stability factor": 0.8501766842,
      score: 0.0,
    },
    "315": {
      velocity: [0.4265967264, 1.0181924852, 1.3351531244],
      direction: [-5.1355475917, 9.9239081052, 37.0520319794],
      "speed factor": 1.2545144703,
      "Uz factor": 0.9426572547,
      "Directional variance factor": 0.1178748351,
      "Velocity stability factor": 0.2629463703,
      "Direction stability factor": 0.882812279,
      score: 0.0,
    },
  },
  {
    position: [-48.9216034, 95.65235413, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.6126367021, 1.1837198354, 1.3946127742],
      direction: [-22.378848259, 0.9537299092, 28.2184616126],
      "speed factor": 1.2130181433,
      "Uz factor": 0.8696958503,
      "Directional variance factor": 0.9152240081,
      "Velocity stability factor": 0.4566589455,
      "Direction stability factor": 0.859451917,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4371432559, 1.2129496736, 1.6150948848],
      direction: [-31.0347274766, 27.9734715869, 53.5809539276],
      "speed factor": 1.0769484813,
      "Uz factor": 0.9299940989,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2382499564,
      "Direction stability factor": 0.7649564405,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1334966792, 0.6411390423, 1.2062762845],
      direction: [-24.858743168, -2.77980557, 179.7941466667],
      "speed factor": 0.9931671181,
      "Uz factor": 0.9813716369,
      "Directional variance factor": 0.7529061716,
      "Velocity stability factor": 0.0414129276,
      "Direction stability factor": 0.4315197505,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0470389061, 0.3160556544, 0.6966604541],
      direction: [-178.5186294473, 119.7182793342, 178.6790523181],
      "speed factor": 0.3555405313,
      "Uz factor": 0.9636805499,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4622309206,
      "Direction stability factor": 0.0077842173,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0939068881, 0.2828447309, 0.5140754214],
      direction: [-179.9166160542, 145.2623142045, 179.7243458924],
      "speed factor": 0.4018207341,
      "Uz factor": 0.96238077,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6563897681,
      "Direction stability factor": 0.0009973279,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0800245125, 0.9339864667, 1.3805802104],
      direction: [-179.5398869744, -12.6653541423, 17.913836584],
      "speed factor": 1.0943103139,
      "Uz factor": 0.9998964056,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.4515174346,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0846417004, 0.2992431288, 0.8513092352],
      direction: [-149.3148434227, -23.5936320962, 174.3196033407],
      "speed factor": 0.5551320646,
      "Uz factor": 0.9550176601,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2560658059,
      "Direction stability factor": 0.1010154257,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.1783830664, 0.7492014338, 1.1221511382],
      direction: [-113.50505948, -24.5737286716, 24.4552196842],
      "speed factor": 1.0202255596,
      "Uz factor": 0.9733480128,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3078973926,
      "Direction stability factor": 0.6167770023,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7986258101, 0.9679805189, 1.1431896065],
      direction: [-10.8274693376, 8.9395463598, 38.3145471728],
      "speed factor": 1.229275182,
      "Uz factor": 0.8122549393,
      "Directional variance factor": 0.2053736569,
      "Velocity stability factor": 0.7224764405,
      "Direction stability factor": 0.8634943986,
      score: 0.0,
    },
    id: "IDN:340.0",
    "45": {
      velocity: [0.1437332311, 0.7019339884, 1.2531686652],
      direction: [-32.7966712261, -2.1853580376, 87.6193905271],
      "speed factor": 0.9534941724,
      "Uz factor": 0.9214237487,
      "Directional variance factor": 0.8057459522,
      "Velocity stability factor": 0.0980640981,
      "Direction stability factor": 0.6655109396,
      score: 0.0,
    },
    "90": {
      velocity: [0.0460308914, 0.4226675927, 0.9470423663],
      direction: [-178.1386986978, 12.0253490881, 167.9508157872],
      "speed factor": 0.6851565364,
      "Uz factor": 0.9966810925,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3423899889,
      "Direction stability factor": 0.0386402375,
      score: 0.0,
    },
    "135": {
      velocity: [0.0566204534, 0.3240918331, 0.7203874053],
      direction: [-171.0027923448, 137.2211732163, 178.720531966],
      "speed factor": 0.2742952282,
      "Uz factor": 0.9950223491,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4651294151,
      "Direction stability factor": 0.0285463214,
      score: 0.0,
    },
    "180": {
      velocity: [0.0558179329, 0.2621383465, 0.7199351473],
      direction: [-179.1274867581, 55.9058840823, 178.8629325531],
      "speed factor": 0.5119545713,
      "Uz factor": 0.831200297,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4767042714,
      "Direction stability factor": 0.0055821686,
      score: 0.0,
    },
    "225": {
      velocity: [0.0674423092, 0.3923951962, 0.9724165552],
      direction: [-179.1695977637, -52.2425421011, 154.5239323032],
      "speed factor": 0.5356623847,
      "Uz factor": 0.9643351808,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1866266952,
      "Direction stability factor": 0.0730735276,
      score: 0.0,
    },
    "270": {
      velocity: [0.6032323276, 1.0664199362, 1.3738095159],
      direction: [-38.6085034504, -15.3807895132, 2.8261052329],
      "speed factor": 0.9758086759,
      "Uz factor": 0.9830289749,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3921662447,
      "Direction stability factor": 0.8849038648,
      score: 0.0,
    },
    "315": {
      velocity: [0.7777449537, 0.9254049126, 1.0850138973],
      direction: [-12.0706525477, 10.2516896589, 28.2890088678],
      "speed factor": 1.1401909468,
      "Uz factor": 0.8255254125,
      "Directional variance factor": 0.088738697,
      "Velocity stability factor": 0.7507323809,
      "Direction stability factor": 0.8878898294,
      score: 0.0,
    },
  },
  {
    position: [-45.30487816, 96.17933745, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.4805632662, 1.1757578536, 1.434343929],
      direction: [-46.3853955221, -14.5089409075, 23.6679860067],
      "speed factor": 1.2048590941,
      "Uz factor": 0.7559009534,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3372838254,
      "Direction stability factor": 0.8054072735,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6258250656, 1.2408585711, 1.5991816989],
      direction: [-29.271560215, 22.2186040884, 48.9833046162],
      "speed factor": 1.1017281119,
      "Uz factor": 0.8965823223,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3705561081,
      "Direction stability factor": 0.7826253755,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1054815616, 0.6327438103, 1.1804037125],
      direction: [-37.6673082872, -8.6324278837, 74.847828293],
      "speed factor": 0.9801623441,
      "Uz factor": 0.9713527408,
      "Directional variance factor": 0.232673077,
      "Velocity stability factor": 0.0394984463,
      "Direction stability factor": 0.6874579539,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0329990232, 0.3428353425, 1.1572329263],
      direction: [-178.1945592796, 80.9113616906, 179.6291165709],
      "speed factor": 0.3856658095,
      "Uz factor": 0.9392821654,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0693377813,
      "Direction stability factor": 0.0060453449,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0572359352, 0.2798894363, 0.5432059893],
      direction: [-178.7463443195, 168.0664656179, 178.5209287282],
      "speed factor": 0.3976223223,
      "Uz factor": 0.9487445925,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6025778473,
      "Direction stability factor": 0.0075909082,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1940195725, 0.7179156287, 1.1680673129],
      direction: [-39.8065223586, -3.6320121192, 30.2903916464],
      "speed factor": 0.8411497436,
      "Uz factor": 0.9999871157,
      "Directional variance factor": 0.6771544783,
      "Velocity stability factor": 0.2510526523,
      "Direction stability factor": 0.80528635,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0175630955, 0.2563717995, 0.9269759745],
      direction: [-160.8361335357, -40.0724965098, 167.5499636774],
      "speed factor": 0.4756005825,
      "Uz factor": 0.9623160995,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.117553168,
      "Direction stability factor": 0.0878163966,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0818457463, 0.6507318799, 1.1023216475],
      direction: [-158.8147696928, -8.7420304439, 46.8306107878],
      "speed factor": 0.8861345779,
      "Uz factor": 0.9833202515,
      "Directional variance factor": 0.2229306272,
      "Velocity stability factor": 0.251644495,
      "Direction stability factor": 0.428762832,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7794645479, 0.9952831265, 1.1797226602],
      direction: [-16.901842388, 1.2044157573, 34.663330758],
      "speed factor": 1.2639477992,
      "Uz factor": 0.7562930107,
      "Directional variance factor": 0.8929408216,
      "Velocity stability factor": 0.6776183185,
      "Direction stability factor": 0.8567634079,
      score: 0.0,
    },
    id: "IDN:337.0",
    "45": {
      velocity: [0.043811565, 0.6774513647, 1.1870083866],
      direction: [-64.0692898361, -15.1449601109, 62.3333094732],
      "speed factor": 0.9202374283,
      "Uz factor": 0.8811799538,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0706171584,
      "Direction stability factor": 0.6488816686,
      score: 0.0,
    },
    "90": {
      velocity: [0.1280091625, 0.524753829, 1.0107979244],
      direction: [-156.2885245291, 20.861289537, 168.1624685124],
      "speed factor": 0.8506413128,
      "Uz factor": 0.9946233472,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3556899733,
      "Direction stability factor": 0.0987472416,
      score: 0.0,
    },
    "135": {
      velocity: [0.0478221225, 0.3174528143, 0.7738966927],
      direction: [-178.9994798718, 162.8537352464, 179.1176687856],
      "speed factor": 0.2686762925,
      "Uz factor": 0.9998973373,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4149212628,
      "Direction stability factor": 0.0052301426,
      score: 0.0,
    },
    "180": {
      velocity: [0.06471535, 0.2833085836, 0.660642382],
      direction: [-175.0360752986, 155.9348283958, 179.7371573134],
      "speed factor": 0.5532999136,
      "Uz factor": 0.9080204356,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5304351949,
      "Direction stability factor": 0.0145187983,
      score: 0.0,
    },
    "225": {
      velocity: [0.0569988362, 0.4210786835, 0.8733002807],
      direction: [-177.8920747226, -50.6290347816, 51.6502985251],
      "speed factor": 0.574818484,
      "Uz factor": 0.9789610254,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2663240898,
      "Direction stability factor": 0.3623822965,
      score: 0.0,
    },
    "270": {
      velocity: [0.0627410334, 0.6038340865, 1.2819723198],
      direction: [-122.4269675484, -9.91072375, 10.9588242911],
      "speed factor": 0.5525276867,
      "Uz factor": 0.997124072,
      "Directional variance factor": 0.1190467778,
      "Velocity stability factor": 0.0382664546,
      "Direction stability factor": 0.6294839116,
      score: 0.0,
    },
    "315": {
      velocity: [0.8363573694, 0.9748766967, 1.1255824837],
      direction: [-9.5374052286, 8.5448840871, 23.9713776458],
      "speed factor": 1.2011451082,
      "Uz factor": 0.8363024675,
      "Directional variance factor": 0.2404547478,
      "Velocity stability factor": 0.7653701842,
      "Direction stability factor": 0.9069200476,
      score: 0.0,
    },
  },
  {
    position: [-53.82729637, 55.863901, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1388957994, 0.6455857142, 1.0502079006],
      direction: [-33.221208888, -5.3621370194, 89.3213601689],
      "speed factor": 0.661564638,
      "Uz factor": 0.9868269721,
      "Directional variance factor": 0.5233655983,
      "Velocity stability factor": 0.3667922897,
      "Direction stability factor": 0.6596039748,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0276847597, 0.3041329996, 0.8677929384],
      direction: [-179.1746333931, -9.5528747488, 174.7934998498],
      "speed factor": 0.2700322851,
      "Uz factor": 0.9843227257,
      "Directional variance factor": 0.1508555779,
      "Velocity stability factor": 0.4567243459,
      "Direction stability factor": 0.0167551854,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0053349025, 0.0759171701, 0.3149152438],
      direction: [-176.5693812721, 24.9231585111, 178.1954672808],
      "speed factor": 0.1176007575,
      "Uz factor": 0.5632769743,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7233730847,
      "Direction stability factor": 0.0145420874,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0327241235, 0.1202983228, 0.2769039452],
      direction: [-179.1924874132, 166.548508387, 178.6615550123],
      "speed factor": 0.135327209,
      "Uz factor": 0.7062609442,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.797863297,
      "Direction stability factor": 0.0059609933,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.00851117, 0.2196958797, 0.5292188144],
      direction: [-133.4628356764, 3.6289855698, 178.7760921274],
      "speed factor": 0.3121089065,
      "Uz factor": 0.999972189,
      "Directional variance factor": 0.6774235049,
      "Velocity stability factor": 0.5741697432,
      "Direction stability factor": 0.132669645,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0736311662, 0.2686279258, 0.5588088615],
      direction: [-93.7531576283, -17.7497665324, 14.3385461358],
      "speed factor": 0.3147393676,
      "Uz factor": 0.9851742352,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6269458539,
      "Direction stability factor": 0.6997452673,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1993227966, 0.4201608347, 0.6727550127],
      direction: [-60.7722775084, -42.7693040154, -14.5267366778],
      "speed factor": 0.7794489805,
      "Uz factor": 0.8943325,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.540606067,
      "Direction stability factor": 0.8715401644,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.3277118922, 0.6197638106, 0.7660829742],
      direction: [-79.2551213161, -25.8913975504, 21.4107925813],
      "speed factor": 0.8439637885,
      "Uz factor": 0.4637396585,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6785250763,
      "Direction stability factor": 0.7203724614,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4267440384, 0.6432041468, 0.8425743158],
      direction: [-11.9891734572, 5.7464472677, 14.6728067602],
      "speed factor": 0.8168293464,
      "Uz factor": 0.9178970447,
      "Directional variance factor": 0.4892046873,
      "Velocity stability factor": 0.6650759599,
      "Direction stability factor": 0.9259389438,
      score: 0.0,
    },
    id: "IDN:73.0",
    "45": {
      velocity: [0.0271036125, 0.1892338732, 0.643139247],
      direction: [-140.1812006014, 19.7307673304, 174.4468415447],
      "speed factor": 0.2570518001,
      "Uz factor": 0.9999898632,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4991825225,
      "Direction stability factor": 0.1260332163,
      score: 0.0,
    },
    "90": {
      velocity: [0.0440642869, 0.2040316017, 0.5089459409],
      direction: [-179.3640791678, 169.9011945751, 178.5493967944],
      "speed factor": 0.3307411969,
      "Uz factor": 0.9656483704,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6607026235,
      "Direction stability factor": 0.0057959001,
      score: 0.0,
    },
    "135": {
      velocity: [0.0121739509, 0.1004057599, 0.3585958682],
      direction: [-170.4005350581, 62.3502130957, 177.5690030714],
      "speed factor": 0.0849784475,
      "Uz factor": 0.9079649206,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7208494744,
      "Direction stability factor": 0.0334179496,
      score: 0.0,
    },
    "180": {
      velocity: [0.0395139292, 0.2274647065, 0.5798141354],
      direction: [-165.7640523905, -38.0906004667, 134.0171852042],
      "speed factor": 0.4442371666,
      "Uz factor": 0.9887434045,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5742667349,
      "Direction stability factor": 0.16727434,
      score: 0.0,
    },
    "225": {
      velocity: [0.0621293951, 0.248049749, 0.5127995841],
      direction: [-84.4656146825, -20.484208922, 36.7696234569],
      "speed factor": 0.3386150528,
      "Uz factor": 0.8946691308,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5949463727,
      "Direction stability factor": 0.6632354496,
      score: 0.0,
    },
    "270": {
      velocity: [0.3184444556, 0.492990969, 0.6987474121],
      direction: [-71.6855807861, -43.1096473739, 29.21124429],
      "speed factor": 0.4511026551,
      "Uz factor": 0.580862523,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7000158094,
      "Direction stability factor": 0.7197310415,
      score: 0.0,
    },
    "315": {
      velocity: [0.357540162, 0.5899720296, 0.7789838879],
      direction: [-30.133808332, -3.2787712587, 29.1427309825],
      "speed factor": 0.7269042533,
      "Uz factor": 0.6576604487,
      "Directional variance factor": 0.7085536659,
      "Velocity stability factor": 0.6581096908,
      "Direction stability factor": 0.8353429463,
      score: 0.0,
    },
  },
  {
    position: [-42.33622582, 96.61189181, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.4835443273, 1.1323665372, 1.4114272574],
      direction: [-54.0963823068, -26.7597167131, 29.8333723697],
      "speed factor": 1.1603937971,
      "Uz factor": 0.8220265617,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3552783676,
      "Direction stability factor": 0.7668617926,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6808094021, 1.2523779137, 1.5749008794],
      direction: [-30.5140027898, 15.816760387, 45.0329882888],
      "speed factor": 1.1119558556,
      "Uz factor": 0.8865911713,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4218147799,
      "Direction stability factor": 0.790147247,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1363104289, 0.6397226884, 1.1680294685],
      direction: [-80.8750123583, -10.1871053347, 142.6279338726],
      "speed factor": 0.9909730915,
      "Uz factor": 0.969649829,
      "Directional variance factor": 0.0944795258,
      "Velocity stability factor": 0.078102782,
      "Direction stability factor": 0.3791584827,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0686294608, 0.3416557262, 0.8355993306],
      direction: [-175.2810087788, 38.0258280429, 178.4695948071],
      "speed factor": 0.3843388236,
      "Uz factor": 0.9999885376,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3650877466,
      "Direction stability factor": 0.0173594345,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0847110998, 0.2604241361, 0.5840318757],
      direction: [-178.7685677578, 157.5632191926, 176.7496912896],
      "speed factor": 0.3699691248,
      "Uz factor": 0.8603434135,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5916597413,
      "Direction stability factor": 0.0124492804,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.068877362, 0.5116822089, 1.0737297499],
      direction: [-43.9356482367, 9.4208342588, 65.209802304],
      "speed factor": 0.5995152378,
      "Uz factor": 0.9970881688,
      "Directional variance factor": 0.1625925103,
      "Velocity stability factor": 0.2273668943,
      "Direction stability factor": 0.6968181929,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.060905403, 0.277867675, 0.8459005928],
      direction: [-147.585693661, -31.4970990726, 55.062246306],
      "speed factor": 0.515478022,
      "Uz factor": 0.9728140916,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2382816054,
      "Direction stability factor": 0.4370890556,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0843013426, 0.6760440833, 1.0882620425],
      direction: [-123.6690277894, -3.032693598, 156.7544236294],
      "speed factor": 0.9206034881,
      "Uz factor": 0.9787870134,
      "Directional variance factor": 0.7304272357,
      "Velocity stability factor": 0.263755748,
      "Direction stability factor": 0.2210459683,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7665195682, 0.9785183188, 1.2021764267],
      direction: [-23.8663232161, -6.4953381524, 21.6980666078],
      "speed factor": 1.2426575338,
      "Uz factor": 0.7800276091,
      "Directional variance factor": 0.4226366087,
      "Velocity stability factor": 0.6491069481,
      "Direction stability factor": 0.8734322505,
      score: 0.0,
    },
    id: "IDN:336.0",
    "45": {
      velocity: [0.1004696018, 0.6757576325, 1.1375454092],
      direction: [-62.8348249227, -20.032747868, 24.9923849942],
      "speed factor": 0.9179366938,
      "Uz factor": 0.8719224403,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1568901849,
      "Direction stability factor": 0.756035528,
      score: 0.0,
    },
    "90": {
      velocity: [0.1460590808, 0.5724568249, 1.053890608],
      direction: [-52.3213487435, 29.6875560384, 128.4961371192],
      "speed factor": 0.9279692651,
      "Uz factor": 0.9937851759,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3374123224,
      "Direction stability factor": 0.4977292059,
      score: 0.0,
    },
    "135": {
      velocity: [0.0589898482, 0.3072773396, 0.7687676238],
      direction: [-171.4674042772, 4.5185821103, 176.9900950264],
      "speed factor": 0.2600642762,
      "Uz factor": 0.9987889092,
      "Directional variance factor": 0.5983482569,
      "Velocity stability factor": 0.4280533961,
      "Direction stability factor": 0.0320625019,
      score: 0.0,
    },
    "180": {
      velocity: [0.0358498356, 0.2748674573, 0.6552012798],
      direction: [-175.0902596465, 148.4524528657, 177.0923599301],
      "speed factor": 0.5368144462,
      "Uz factor": 0.8754097542,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5119777681,
      "Direction stability factor": 0.0217149456,
      score: 0.0,
    },
    "225": {
      velocity: [0.0773556366, 0.4826972065, 0.9448744357],
      direction: [-123.2035920941, -20.4986276142, 105.809046826],
      "speed factor": 0.658934511,
      "Uz factor": 0.9968849014,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2202909246,
      "Direction stability factor": 0.3638537808,
      score: 0.0,
    },
    "270": {
      velocity: [0.0899669104, 0.6272312974, 1.3083438077],
      direction: [-157.9354681695, -4.9704275377, 79.820617928],
      "speed factor": 0.5739368902,
      "Uz factor": 0.9976000984,
      "Directional variance factor": 0.5581842189,
      "Velocity stability factor": 0.0389403994,
      "Direction stability factor": 0.3395664275,
      score: 0.0,
    },
    "315": {
      velocity: [0.8156713517, 0.962130471, 1.1242431669],
      direction: [-8.2251220966, 5.787944691, 20.9131243678],
      "speed factor": 1.1854404897,
      "Uz factor": 0.8397979282,
      "Directional variance factor": 0.4855160275,
      "Velocity stability factor": 0.7496754446,
      "Direction stability factor": 0.9190604265,
      score: 0.0,
    },
  },
  {
    position: [-50.56177969, 32.75380857, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0490077274, 0.3038302114, 0.8197544307],
      direction: [-175.9904984479, -8.5209070354, 179.2359700684],
      "speed factor": 0.311350328,
      "Uz factor": 0.9973496686,
      "Directional variance factor": 0.2425860413,
      "Velocity stability factor": 0.4644614567,
      "Direction stability factor": 0.0132598097,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0266235064, 0.1719011276, 0.5659939325],
      direction: [-178.3252799007, -20.4055592125, 176.5997031436],
      "speed factor": 0.1526268256,
      "Uz factor": 0.9894144426,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6512034658,
      "Direction stability factor": 0.0140972693,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1161070414, 0.3642482662, 0.5880791626],
      direction: [-56.4582283389, -16.5635069708, 32.0644011912],
      "speed factor": 0.564244847,
      "Uz factor": 0.9261663065,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5782671746,
      "Direction stability factor": 0.7541038069,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1985644707, 0.4738500335, 0.7468734739],
      direction: [-83.5161461713, -60.1949752528, -29.025732261],
      "speed factor": 0.5330481842,
      "Uz factor": 0.8350319164,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5460993731,
      "Direction stability factor": 0.8486377391,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2683825738, 0.6609219643, 0.9391201006],
      direction: [-68.4806405662, -7.4880209649, 18.1890206503],
      "speed factor": 0.9389326365,
      "Uz factor": 0.9472417783,
      "Directional variance factor": 0.3343981365,
      "Velocity stability factor": 0.4514765889,
      "Direction stability factor": 0.7592509411,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0911226904, 0.3250542906, 0.5650120913],
      direction: [-31.3324375258, 7.5047978996, 25.0863715546],
      "speed factor": 0.3808516242,
      "Uz factor": 0.9904285243,
      "Directional variance factor": 0.3329068534,
      "Velocity stability factor": 0.6356254471,
      "Direction stability factor": 0.8432810859,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0449707394, 0.2101750945, 0.3488086957],
      direction: [-23.4074485103, 3.5638048357, 20.7285319509],
      "speed factor": 0.3899001277,
      "Uz factor": 0.9997794315,
      "Directional variance factor": 0.6832173479,
      "Velocity stability factor": 0.7051714923,
      "Direction stability factor": 0.8774000543,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.018645951, 0.0697382236, 0.2542096762],
      direction: [-169.5579752306, 88.2975638485, 178.7590394919],
      "speed factor": 0.0949660732,
      "Uz factor": 0.9607698186,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8272517653,
      "Direction stability factor": 0.0324527369,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0152454168, 0.1102957378, 0.4217030193],
      direction: [-179.8892463073, 24.561912243, 176.0203674454],
      "speed factor": 0.1400687416,
      "Uz factor": 0.9795959788,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6726250355,
      "Direction stability factor": 0.011362184,
      score: 0.0,
    },
    id: "IDN:88.0",
    "45": {
      velocity: [0.0405615935, 0.226200859, 0.6218762576],
      direction: [-127.1104074198, -15.3297467304, 164.0159354289],
      "speed factor": 0.3072670713,
      "Uz factor": 0.8974148297,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5274095727,
      "Direction stability factor": 0.1913157143,
      score: 0.0,
    },
    "90": {
      velocity: [0.2472379884, 0.549304247, 0.8677824389],
      direction: [-75.6984748888, -59.4439211928, -26.3395307641],
      "speed factor": 0.8904382588,
      "Uz factor": 0.7355407531,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.547090959,
      "Direction stability factor": 0.8628918219,
      score: 0.0,
    },
    "135": {
      velocity: [0.0564244749, 0.3773645555, 0.6704974372],
      direction: [-129.3804070876, -93.0274961671, 74.2159797731],
      "speed factor": 0.3193826141,
      "Uz factor": 0.8722496279,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5051733692,
      "Direction stability factor": 0.4344544809,
      score: 0.0,
    },
    "180": {
      velocity: [0.0669646685, 0.3596539652, 0.6934848212],
      direction: [-56.8333998961, -6.5092806208, 64.5434199921],
      "speed factor": 0.7024019722,
      "Uz factor": 0.9224970181,
      "Directional variance factor": 0.4213972781,
      "Velocity stability factor": 0.5063291349,
      "Direction stability factor": 0.662842167,
      score: 0.0,
    },
    "225": {
      velocity: [0.0299097013, 0.1672384773, 0.3266253354],
      direction: [-50.2231330303, 11.3920635956, 149.8979268489],
      "speed factor": 0.2282988233,
      "Uz factor": 0.9973188671,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7333177414,
      "Direction stability factor": 0.444108167,
      score: 0.0,
    },
    "270": {
      velocity: [0.005358869, 0.1002313194, 0.240638765],
      direction: [-166.3344993453, 92.8673840371, 166.9344842739],
      "speed factor": 0.0917148937,
      "Uz factor": 0.9177786368,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8144104642,
      "Direction stability factor": 0.0742528233,
      score: 0.0,
    },
    "315": {
      velocity: [0.0458001837, 0.1663214516, 0.3370536209],
      direction: [-177.6912166214, 65.6455782047, 162.548195025],
      "speed factor": 0.2049245803,
      "Uz factor": 0.8947075149,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7637247357,
      "Direction stability factor": 0.0548905232,
      score: 0.0,
    },
  },
  {
    position: [-50.55130774, 35.7537903, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0256724287, 0.2457031032, 0.6964257663],
      direction: [-158.6815952131, 4.2457933352, 172.3588168217],
      "speed factor": 0.2517845128,
      "Uz factor": 0.9919773142,
      "Directional variance factor": 0.622596148,
      "Velocity stability factor": 0.5339399263,
      "Direction stability factor": 0.0804432999,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0252632583, 0.2281847728, 0.957269236],
      direction: [-179.1667632978, -32.5994879462, 169.1383294806],
      "speed factor": 0.2025997037,
      "Uz factor": 0.999938176,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3972964792,
      "Direction stability factor": 0.0324858534,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0540134463, 0.2269998333, 0.4008570786],
      direction: [-162.9281578694, 45.7667084065, 171.901492203],
      "speed factor": 0.3516378747,
      "Uz factor": 0.7166124007,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6900763024,
      "Direction stability factor": 0.0699176387,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1540486261, 0.3804863154, 0.6120296757],
      direction: [-78.733337699, -26.0667438955, 49.373164945],
      "speed factor": 0.4280205237,
      "Uz factor": 0.64949874,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.620874572,
      "Direction stability factor": 0.6441486038,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3225064485, 0.641215902, 0.9330994143],
      direction: [-25.9133515508, -2.6401855577, 12.4443629229],
      "speed factor": 0.9109374025,
      "Uz factor": 0.7894284676,
      "Directional variance factor": 0.7653168393,
      "Velocity stability factor": 0.5006622963,
      "Direction stability factor": 0.8934507931,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0438984953, 0.1699575246, 0.6048344053],
      direction: [-174.7346388075, -159.5440838274, 177.2106698592],
      "speed factor": 0.1991316564,
      "Uz factor": 0.1599636709,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.568695204,
      "Direction stability factor": 0.0223741426,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0130813476, 0.1141235075, 0.2870555956],
      direction: [-179.4169012025, -97.5508979585, 167.6799033086],
      "speed factor": 0.211712859,
      "Uz factor": 0.9630555394,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7341496776,
      "Direction stability factor": 0.0358422097,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0106182082, 0.070618984, 0.2170576074],
      direction: [-179.7796166055, 99.7581405403, 178.6535113922],
      "speed factor": 0.0961654492,
      "Uz factor": 0.9624554826,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8486097901,
      "Direction stability factor": 0.0043524222,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0190227294, 0.1220464102, 0.3456777681],
      direction: [-177.4901898371, 129.6743195448, 178.4571818407],
      "speed factor": 0.1549913662,
      "Uz factor": 0.8047005383,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7369007712,
      "Direction stability factor": 0.0112573009,
      score: 0.0,
    },
    id: "IDN:87.0",
    "45": {
      velocity: [0.0099836838, 0.1847414965, 0.5215436421],
      direction: [-179.5171576197, 41.4663421184, 153.8596060674],
      "speed factor": 0.2509494386,
      "Uz factor": 0.7473260531,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5841179413,
      "Direction stability factor": 0.0739534342,
      score: 0.0,
    },
    "90": {
      velocity: [0.0720201209, 0.3814916037, 0.6597377773],
      direction: [-11.5203786655, 41.5398195629, 88.881291873],
      "speed factor": 0.6184090532,
      "Uz factor": 0.5857268519,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.571049842,
      "Direction stability factor": 0.7211064707,
      score: 0.0,
    },
    "135": {
      velocity: [0.0374273895, 0.2897912943, 0.5707167244],
      direction: [-116.4950031759, -33.1209210181, 85.0684460112],
      "speed factor": 0.2452649561,
      "Uz factor": 0.6866900225,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5702696893,
      "Direction stability factor": 0.44010153,
      score: 0.0,
    },
    "180": {
      velocity: [0.1952874474, 0.4573115856, 0.8762673742],
      direction: [-176.871678086, -33.6426372203, -15.6831629278],
      "speed factor": 0.8931267016,
      "Uz factor": 0.6205565455,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4634171812,
      "Direction stability factor": 0.5522541246,
      score: 0.0,
    },
    "225": {
      velocity: [0.0084995805, 0.1250313185, 0.4388785975],
      direction: [-170.6262875869, -12.7420222519, 175.048817902],
      "speed factor": 0.1706814326,
      "Uz factor": 0.987166299,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6131836846,
      "Direction stability factor": 0.0397913736,
      score: 0.0,
    },
    "270": {
      velocity: [0.0158017007, 0.0902089028, 0.2294166885],
      direction: [-177.553203611, 74.5722535685, 142.8649132537],
      "speed factor": 0.0825440588,
      "Uz factor": 0.9703971216,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8314998131,
      "Direction stability factor": 0.1099496754,
      score: 0.0,
    },
    "315": {
      velocity: [0.032299762, 0.1125437908, 0.2756471298],
      direction: [18.7206445447, 101.0877004104, 168.9292428134],
      "speed factor": 0.1386651503,
      "Uz factor": 0.8273122564,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8025878623,
      "Direction stability factor": 0.5827538937,
      score: 0.0,
    },
  },
  {
    position: [-50.54083578, 38.75377202, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0296624556, 0.2130453492, 0.5049680391],
      direction: [-81.7165335111, 23.6679250967, 137.7220808396],
      "speed factor": 0.2183184451,
      "Uz factor": 0.9999088596,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6697430444,
      "Direction stability factor": 0.3904482935,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0214196859, 0.3104429258, 1.24544299],
      direction: [-179.1261777862, -7.3910199183, 173.8198231776],
      "speed factor": 0.2756347149,
      "Uz factor": 0.9999860683,
      "Directional variance factor": 0.3430204517,
      "Velocity stability factor": 0.2084566273,
      "Direction stability factor": 0.0195944418,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0286976979, 0.0830433969, 0.249327311],
      direction: [-174.7590323011, 125.7999975331, 177.798670824],
      "speed factor": 0.1286397579,
      "Uz factor": 0.7132625844,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8028554105,
      "Direction stability factor": 0.0206730469,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.028140186, 0.1047033191, 0.3281803096],
      direction: [-164.2007874375, -10.0377266948, 172.3824168352],
      "speed factor": 0.1177839193,
      "Uz factor": 0.6962346729,
      "Directional variance factor": 0.1077576271,
      "Velocity stability factor": 0.751621076,
      "Direction stability factor": 0.0650466548,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0814167724, 0.212965696, 0.3754863838],
      direction: [-94.6492500872, -16.3569505372, 25.3368311851],
      "speed factor": 0.3025477337,
      "Uz factor": 0.2942053159,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7595123877,
      "Direction stability factor": 0.6667053298,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0189281209, 0.0751101283, 0.2172121615],
      direction: [-124.783416931, -60.9612528762, 143.1236185977],
      "speed factor": 0.0880031896,
      "Uz factor": 0.9211185305,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8475389859,
      "Direction stability factor": 0.2558137902,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0092688705, 0.1086138559, 0.3011999566],
      direction: [-136.8517814832, -74.3587683898, -18.1872155706],
      "speed factor": 0.2014917913,
      "Uz factor": 0.9995343103,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7167252982,
      "Direction stability factor": 0.6703762058,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0082437539, 0.0834847168, 0.2577861592],
      direction: [-177.5707907265, 120.2810828771, 179.3127230193],
      "speed factor": 0.1136853697,
      "Uz factor": 0.9884552051,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.817000644,
      "Direction stability factor": 0.0086569063,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0217081922, 0.114883927, 0.3320014235],
      direction: [-175.7525361537, 109.6725450365, 177.7990966832],
      "speed factor": 0.1458954571,
      "Uz factor": 0.9248134684,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.750079135,
      "Direction stability factor": 0.017912131,
      score: 0.0,
    },
    id: "IDN:86.0",
    "45": {
      velocity: [0.0241947257, 0.1423080542, 0.5150861605],
      direction: [-177.9898302962, 42.2699575481, 172.2298739191],
      "speed factor": 0.1933086339,
      "Uz factor": 0.9851378365,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6009207969,
      "Direction stability factor": 0.0271674883,
      score: 0.0,
    },
    "90": {
      velocity: [0.0505820481, 0.1311076352, 0.2441448712],
      direction: [-172.1654103289, 103.2651792151, 175.4931116717],
      "speed factor": 0.2125293133,
      "Uz factor": 0.4736704319,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8587267157,
      "Direction stability factor": 0.0342818833,
      score: 0.0,
    },
    "135": {
      velocity: [0.0090201717, 0.0899738499, 0.1945901231],
      direction: [-85.5592153661, 27.0584007457, 178.7567975172],
      "speed factor": 0.0761493971,
      "Uz factor": 0.6690799654,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8504657272,
      "Direction stability factor": 0.2657888531,
      score: 0.0,
    },
    "180": {
      velocity: [0.0731120009, 0.1722213931, 0.3243344009],
      direction: [-166.658336549, -111.2414235035, 174.7642072008],
      "speed factor": 0.3363473168,
      "Uz factor": 0.5095151283,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8020475814,
      "Direction stability factor": 0.0516040451,
      score: 0.0,
    },
    "225": {
      velocity: [0.0057303809, 0.0764746839, 0.3199561172],
      direction: [-132.040878216, -13.4437332589, 175.0768788356],
      "speed factor": 0.1043963126,
      "Uz factor": 0.9836222711,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7175800011,
      "Direction stability factor": 0.1468951193,
      score: 0.0,
    },
    "270": {
      velocity: [0.0105009884, 0.0611694498, 0.181573528],
      direction: [-176.6421911574, -170.1446812943, 179.2909326169],
      "speed factor": 0.0559720217,
      "Uz factor": 0.6392825602,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8650574326,
      "Direction stability factor": 0.0112968784,
      score: 0.0,
    },
    "315": {
      velocity: [0.0113255496, 0.063650211, 0.2425410943],
      direction: [-173.7617022849, 66.151467382, 174.8196863517],
      "speed factor": 0.0784233943,
      "Uz factor": 0.9999999324,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8124296336,
      "Direction stability factor": 0.0317183649,
      score: 0.0,
    },
  },
  {
    position: [-48.85687529, 38.74789386, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0389670416, 0.1842788548, 0.4616784609],
      direction: [-148.5709467387, -8.7642800636, 165.6023437915],
      "speed factor": 0.1888399498,
      "Uz factor": 0.9982716861,
      "Directional variance factor": 0.2209528832,
      "Velocity stability factor": 0.7062870892,
      "Direction stability factor": 0.1272964152,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0238936018, 0.2090086282, 0.698273059],
      direction: [-168.9192110309, -24.8037719257, 177.9118615725],
      "speed factor": 0.1855736719,
      "Uz factor": 0.9522342204,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5638967099,
      "Direction stability factor": 0.0365803539,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0227237606, 0.1737622788, 0.5190071207],
      direction: [-179.9084160588, 126.0816851306, 176.7150510412],
      "speed factor": 0.2691693538,
      "Uz factor": 0.7507204371,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5565437571,
      "Direction stability factor": 0.0093792581,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1119029579, 0.3271379847, 0.7860971314],
      direction: [-11.0752112285, 5.772937699, 163.9303732807],
      "speed factor": 0.3680073786,
      "Uz factor": 0.7300019297,
      "Directional variance factor": 0.4868499823,
      "Velocity stability factor": 0.4418892335,
      "Direction stability factor": 0.5138733764,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.184496763, 0.3862173963, 0.558924858],
      direction: [-53.0516391444, -9.5256734843, 69.480821625],
      "speed factor": 0.548676149,
      "Uz factor": 0.5808786412,
      "Directional variance factor": 0.1532734681,
      "Velocity stability factor": 0.6937959073,
      "Direction stability factor": 0.6596320534,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0819844223, 0.3045563463, 0.527856396],
      direction: [-119.6052316899, -82.4266980443, 97.3462673821],
      "speed factor": 0.3568350964,
      "Uz factor": 0.6346184051,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6571681056,
      "Direction stability factor": 0.397356947,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0154018839, 0.0823433493, 0.2090668763],
      direction: [-143.9749780544, -75.417140056, 27.9081111321],
      "speed factor": 0.1527568359,
      "Uz factor": 0.8791440383,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.812077591,
      "Direction stability factor": 0.5225469745,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0099622202, 0.0637358349, 0.2151932204],
      direction: [-177.0675088569, 142.1606706631, 173.1300630227],
      "speed factor": 0.0867923164,
      "Uz factor": 0.9013175277,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8494959571,
      "Direction stability factor": 0.027228967,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.009594466, 0.1094171269, 0.3505106138],
      direction: [-177.3373511344, 157.6484773183, 175.5175601821],
      "speed factor": 0.1389529602,
      "Uz factor": 0.9257075209,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7254143825,
      "Direction stability factor": 0.0198474686,
      score: 0.0,
    },
    id: "IDN:85.0",
    "45": {
      velocity: [0.0172080348, 0.1724348259, 0.5971511606],
      direction: [-178.1663000565, 51.2094903943, 175.127682838],
      "speed factor": 0.2342322844,
      "Uz factor": 0.8750376772,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5285245899,
      "Direction stability factor": 0.0186278253,
      score: 0.0,
    },
    "90": {
      velocity: [0.0751085854, 0.4135339761, 0.7462058082],
      direction: [-179.9066451382, 157.5619907904, 179.7379341588],
      "speed factor": 0.6703506765,
      "Uz factor": 0.4777553759,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5101946374,
      "Direction stability factor": 0.0009872797,
      score: 0.0,
    },
    "135": {
      velocity: [0.0248153718, 0.1898824924, 0.428016377],
      direction: [-179.9855652621, 74.8153612786, 177.7277483697],
      "speed factor": 0.1607071092,
      "Uz factor": 0.471769482,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6750962716,
      "Direction stability factor": 0.0063519066,
      score: 0.0,
    },
    "180": {
      velocity: [0.1167875289, 0.3062357046, 0.5845245414],
      direction: [-90.9997602447, -43.346747476, 76.6368504476],
      "speed factor": 0.5980764392,
      "Uz factor": 0.5223961603,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6314434027,
      "Direction stability factor": 0.5343427481,
      score: 0.0,
    },
    "225": {
      velocity: [0.0142081284, 0.1053849942, 0.4134538598],
      direction: [-168.0737972213, -1.6920739573, 170.4064190128],
      "speed factor": 0.14386205,
      "Uz factor": 0.914778744,
      "Directional variance factor": 0.849593426,
      "Velocity stability factor": 0.641165678,
      "Direction stability factor": 0.0597771771,
      score: 0.0,
    },
    "270": {
      velocity: [0.0154874584, 0.0787167593, 0.2610048349],
      direction: [-177.3153974484, 176.0114487352, 179.9571865364],
      "speed factor": 0.0720283765,
      "Uz factor": 0.5486123745,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8063351067,
      "Direction stability factor": 0.0075761556,
      score: 0.0,
    },
    "315": {
      velocity: [0.0276698422, 0.110059529, 0.3503856821],
      direction: [-178.4522909971, 120.0763553349, 179.8125856012],
      "speed factor": 0.1356042927,
      "Uz factor": 0.9799627166,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.738201303,
      "Direction stability factor": 0.0048197872,
      score: 0.0,
    },
  },
  {
    position: [-45.85689357, 38.73742191, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0190627498, 0.2878820017, 0.5927272387],
      direction: [-121.5253134101, -12.7845392436, 78.8997535286],
      "speed factor": 0.2950073833,
      "Uz factor": 0.9978997957,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6014002481,
      "Direction stability factor": 0.4432637029,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0454883989, 0.2732855002, 0.646272318],
      direction: [-111.0280818355, -14.2723293121, 178.6481457523],
      "speed factor": 0.2426435414,
      "Uz factor": 0.941393387,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6114889905,
      "Direction stability factor": 0.1953438123,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2122746396, 0.3924545375, 0.6340302198],
      direction: [-46.2200772158, -16.9082467963, 30.9842052201],
      "speed factor": 0.6079382415,
      "Uz factor": 0.8457731372,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6231383922,
      "Direction stability factor": 0.7855436599,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4474702694, 0.6831081121, 0.982803675],
      direction: [-76.3116671979, -47.3240606583, 6.1310595202],
      "speed factor": 0.7684489035,
      "Uz factor": 0.8682526981,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5568408196,
      "Direction stability factor": 0.7709924258,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2852311219, 0.6638224561, 0.973420657],
      direction: [-51.9988570378, 24.8307859301, 63.4309801166],
      "speed factor": 0.9430531932,
      "Uz factor": 0.7567813625,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4372044858,
      "Direction stability factor": 0.6793615635,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1501060067, 0.5562190629, 0.8449227628],
      direction: [-24.2112851085, 9.8013635041, 24.6030571771],
      "speed factor": 0.6516970845,
      "Uz factor": 0.9715329825,
      "Directional variance factor": 0.1287676885,
      "Velocity stability factor": 0.4657539409,
      "Direction stability factor": 0.8644046048,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0158294268, 0.1135300996, 0.383276338],
      direction: [-171.2400664568, -17.7504921766, 171.0479040172],
      "speed factor": 0.2106120158,
      "Uz factor": 0.9859193805,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6434486797,
      "Direction stability factor": 0.049200082,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0111407404, 0.0723757423, 0.3398811341],
      direction: [-177.4766835472, 130.7075432961, 177.1611266891],
      "speed factor": 0.0985577161,
      "Uz factor": 0.9358392695,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7589216139,
      "Direction stability factor": 0.0148949716,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0228559442, 0.1483370257, 0.4084500893],
      direction: [-175.7915735297, 23.2133733644, 179.3436241167],
      "speed factor": 0.1883788162,
      "Uz factor": 0.7300931161,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6894291832,
      "Direction stability factor": 0.0135133399,
      score: 0.0,
    },
    id: "IDN:84.0",
    "45": {
      velocity: [0.0381246727, 0.2966264615, 0.7323572273],
      direction: [-68.9322712381, -4.7299186686, 148.7744161525],
      "speed factor": 0.4029319097,
      "Uz factor": 0.8561181473,
      "Directional variance factor": 0.579562785,
      "Velocity stability factor": 0.4356109008,
      "Direction stability factor": 0.3952592017,
      score: 0.0,
    },
    "90": {
      velocity: [0.3435329013, 0.6261541414, 0.8662357174],
      direction: [-81.5258832575, -37.0088487043, 36.9449960407],
      "speed factor": 1.0150141864,
      "Uz factor": 0.8098603142,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6185014129,
      "Direction stability factor": 0.6709142242,
      score: 0.0,
    },
    "135": {
      velocity: [0.1203964902, 0.4359981735, 0.8828010021],
      direction: [-153.7896431177, -55.2858653413, 77.4379157511],
      "speed factor": 0.3690071957,
      "Uz factor": 0.7871605061,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.385646203,
      "Direction stability factor": 0.3577012254,
      score: 0.0,
    },
    "180": {
      velocity: [0.2965289893, 0.6712102754, 0.9859624757],
      direction: [-31.8758643797, 12.6180524945, 34.9144352353],
      "speed factor": 1.3108695214,
      "Uz factor": 0.9722459711,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4567561407,
      "Direction stability factor": 0.81447139,
      score: 0.0,
    },
    "225": {
      velocity: [0.0216237715, 0.1908881313, 0.5850896133],
      direction: [-159.7281914861, 3.355660237, 177.2184698057],
      "speed factor": 0.2605831892,
      "Uz factor": 0.9884275933,
      "Directional variance factor": 0.70171909,
      "Velocity stability factor": 0.493567827,
      "Direction stability factor": 0.064037052,
      score: 0.0,
    },
    "270": {
      velocity: [0.0330359442, 0.1221806121, 0.3810945709],
      direction: [-178.9628731965, 133.89845774, 177.3602361152],
      "speed factor": 0.1117992052,
      "Uz factor": 0.988446897,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.72545024,
      "Direction stability factor": 0.0102135852,
      score: 0.0,
    },
    "315": {
      velocity: [0.0276663908, 0.1021955606, 0.2821903223],
      direction: [-172.7306519916, 94.5557723323, 173.3712330766],
      "speed factor": 0.1259151009,
      "Uz factor": 0.9947270361,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7935210319,
      "Direction stability factor": 0.0386058748,
      score: 0.0,
    },
  },
  {
    position: [-45.85200666, 40.13741357, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0363450198, 0.3882773111, 0.7726830846],
      direction: [-147.4274861324, -0.276544867, 174.3008960217],
      "speed factor": 0.3978875819,
      "Uz factor": 0.9999999118,
      "Directional variance factor": 0.975418234,
      "Velocity stability factor": 0.4883696383,
      "Direction stability factor": 0.1063100496,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0619718168, 0.3979475766, 0.9650968549],
      direction: [-138.9241495801, -12.1996471802, 140.9520725157],
      "speed factor": 0.3533279637,
      "Uz factor": 0.9264285924,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.415973016,
      "Direction stability factor": 0.2225660497,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1436624012, 0.3776383626, 0.6937322363],
      direction: [-43.3619643782, -9.1693374609, 55.1842619635],
      "speed factor": 0.584986999,
      "Uz factor": 0.853705964,
      "Directional variance factor": 0.1849477813,
      "Velocity stability factor": 0.508482609,
      "Direction stability factor": 0.7262604824,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2626159847, 0.4894356529, 0.7312466083],
      direction: [-88.0938903608, -55.0001797116, 11.5822154004],
      "speed factor": 0.5505809171,
      "Uz factor": 0.5912167353,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6120586519,
      "Direction stability factor": 0.7231219284,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0785713781, 0.5419734129, 0.8608781919],
      direction: [-30.484669246, 9.6318204225, 30.9126708768],
      "speed factor": 0.7699494841,
      "Uz factor": 0.9510691508,
      "Directional variance factor": 0.1438381847,
      "Velocity stability factor": 0.3602361805,
      "Direction stability factor": 0.829451833,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0321569983, 0.1652290868, 0.368974129],
      direction: [-54.0744592443, -11.5933393834, 26.3289882522],
      "speed factor": 0.1935915565,
      "Uz factor": 0.9883991256,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7410206027,
      "Direction stability factor": 0.7766570903,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.009541033, 0.0928240541, 0.2553669586],
      direction: [-171.3824943626, -43.0696736968, 168.3079034769],
      "speed factor": 0.1721998063,
      "Uz factor": 0.9964655636,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7614633416,
      "Direction stability factor": 0.0564155616,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0085875285, 0.0794987563, 0.2807936566],
      direction: [-179.0788602915, 133.561057959, 176.4289626765],
      "speed factor": 0.1082574852,
      "Uz factor": 0.8064394487,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.800380436,
      "Direction stability factor": 0.0124782695,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0110991845, 0.1333903463, 0.3484777665],
      direction: [-179.0349838713, 41.6718841257, 171.6293090845],
      "speed factor": 0.169397461,
      "Uz factor": 0.8549746512,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7282636598,
      "Direction stability factor": 0.0259325196,
      score: 0.0,
    },
    id: "IDN:83.0",
    "45": {
      velocity: [0.060285093, 0.3071270707, 0.9602539974],
      direction: [-169.9440056363, -3.3871815679, 111.5463240508],
      "speed factor": 0.4171957433,
      "Uz factor": 0.8817920901,
      "Directional variance factor": 0.698917194,
      "Velocity stability factor": 0.2683537586,
      "Direction stability factor": 0.2180824175,
      score: 0.0,
    },
    "90": {
      velocity: [0.3451322193, 0.6774736477, 0.9498430746],
      direction: [-83.983562661, -41.5955873021, 47.2170605617],
      "speed factor": 1.0982046079,
      "Uz factor": 0.6993392863,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5586472278,
      "Direction stability factor": 0.6355538244,
      score: 0.0,
    },
    "135": {
      velocity: [0.1163175752, 0.403231008, 0.6444646947],
      direction: [-130.4659310308, -54.7890719927, 60.7289959673],
      "speed factor": 0.3412746946,
      "Uz factor": 0.7652902027,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.574413342,
      "Direction stability factor": 0.4689029806,
      score: 0.0,
    },
    "180": {
      velocity: [0.059056598, 0.4426777978, 0.736528602],
      direction: [-31.0514024511, -1.4235186694, 49.1096233084],
      "speed factor": 0.8645470045,
      "Uz factor": 0.994795531,
      "Directional variance factor": 0.8734650072,
      "Velocity stability factor": 0.466181273,
      "Direction stability factor": 0.777330484,
      score: 0.0,
    },
    "225": {
      velocity: [0.0049162476, 0.0866160646, 0.3031692075],
      direction: [-175.3119184809, -22.7961034939, 173.1403777439],
      "speed factor": 0.1182404071,
      "Uz factor": 0.9163535256,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7319360228,
      "Direction stability factor": 0.0320769549,
      score: 0.0,
    },
    "270": {
      velocity: [0.0259472352, 0.1644075626, 0.3509978498],
      direction: [-179.548522871, 127.278622739, 171.5222842265],
      "speed factor": 0.1504382285,
      "Uz factor": 0.9945960064,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7435990337,
      "Direction stability factor": 0.0248033136,
      score: 0.0,
    },
    "315": {
      velocity: [0.0141339826, 0.0797390022, 0.2459187694],
      direction: [-179.2948476095, 127.4324419667, 179.39707994],
      "speed factor": 0.0982463862,
      "Uz factor": 0.9356100314,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8119678439,
      "Direction stability factor": 0.0036335346,
      score: 0.0,
    },
  },
  {
    position: [-45.8415347, 43.13739529, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0472259347, 0.3855529851, 0.8551351048],
      direction: [-161.3957666408, 6.7845302401, 159.0453667095],
      "speed factor": 0.395095826,
      "Uz factor": 0.9960545368,
      "Directional variance factor": 0.3969306453,
      "Velocity stability factor": 0.4386398304,
      "Direction stability factor": 0.1098857407,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0709122155, 0.3957753518, 0.883549606],
      direction: [-171.7340269054, -6.9109732037, 138.3006494705],
      "speed factor": 0.3513992982,
      "Uz factor": 0.9649267873,
      "Directional variance factor": 0.3856912708,
      "Velocity stability factor": 0.4744889753,
      "Direction stability factor": 0.1387925656,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1452988064, 0.3032721491, 0.5537571385],
      direction: [-39.8258005765, 7.8261692565, 97.8825999952],
      "speed factor": 0.4697887767,
      "Uz factor": 0.596117361,
      "Directional variance factor": 0.3043405105,
      "Velocity stability factor": 0.6350202084,
      "Direction stability factor": 0.6174766651,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2388548714, 0.4452337775, 0.6322376383],
      direction: [-69.6823048298, -23.3533066432, 33.179376634],
      "speed factor": 0.5008568952,
      "Uz factor": 0.5911444838,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6743502596,
      "Direction stability factor": 0.714273107,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1022366286, 0.6222325779, 0.9915322054],
      direction: [-135.43388904, 7.916894896, 40.4991028868],
      "speed factor": 0.8839689198,
      "Uz factor": 0.9202733347,
      "Directional variance factor": 0.2962760092,
      "Velocity stability factor": 0.2727416855,
      "Direction stability factor": 0.5112972446,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0100653252, 0.109467105, 0.4076804573],
      direction: [-160.3172349546, -31.708729312, 164.0849525001],
      "speed factor": 0.1282577279,
      "Uz factor": 0.8620754222,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6942728922,
      "Direction stability factor": 0.0988828126,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.020824248, 0.0999785127, 0.3195800829],
      direction: [-171.606499752, -59.8296891764, 172.2714658274],
      "speed factor": 0.1854721891,
      "Uz factor": 0.998200566,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.710102918,
      "Direction stability factor": 0.0447834289,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0060564825, 0.0624757361, 0.2136678133],
      direction: [-179.8122225315, 137.1188103198, 177.2930377389],
      "speed factor": 0.085076376,
      "Uz factor": 0.8675138748,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8477503661,
      "Direction stability factor": 0.0080409437,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0184582424, 0.1402354245, 0.4747958523],
      direction: [-177.8707036288, 96.9041274681, 176.7112027252],
      "speed factor": 0.1780902855,
      "Uz factor": 0.6044970492,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.632449958,
      "Direction stability factor": 0.0150502601,
      score: 0.0,
    },
    id: "IDN:82.0",
    "45": {
      velocity: [0.0329361889, 0.2912873945, 0.9099720643],
      direction: [-171.0701901907, 5.0233731616, 155.1602777726],
      "speed factor": 0.3956794196,
      "Uz factor": 0.9045145958,
      "Directional variance factor": 0.5534779412,
      "Velocity stability factor": 0.2869975855,
      "Direction stability factor": 0.0938042557,
      score: 0.0,
    },
    "90": {
      velocity: [0.2609361768, 0.4900811577, 0.8069157179],
      direction: [-87.1462537472, -39.7332428625, 76.759919192],
      "speed factor": 0.794435898,
      "Uz factor": 0.5014102276,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6015127198,
      "Direction stability factor": 0.5447050752,
      score: 0.0,
    },
    "135": {
      velocity: [0.0294142222, 0.4046829808, 0.6620652044],
      direction: [-126.897072702, -42.6628606478, 113.3252772967],
      "speed factor": 0.3425035723,
      "Uz factor": 0.7189886779,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4902029999,
      "Direction stability factor": 0.3327156944,
      score: 0.0,
    },
    "180": {
      velocity: [0.0214489802, 0.4528530388, 0.857112318],
      direction: [-30.8007042403, -9.7409974605, 61.949754121],
      "speed factor": 0.8844191872,
      "Uz factor": 0.9867747549,
      "Directional variance factor": 0.1341335591,
      "Velocity stability factor": 0.3415333231,
      "Direction stability factor": 0.7423598379,
      score: 0.0,
    },
    "225": {
      velocity: [0.012729472, 0.0870325653, 0.3082284826],
      direction: [-152.8263622301, -21.242867485, 175.2347068889],
      "speed factor": 0.1188089761,
      "Uz factor": 0.9576624131,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.734411219,
      "Direction stability factor": 0.0887192524,
      score: 0.0,
    },
    "270": {
      velocity: [0.0309032555, 0.1860834517, 0.4216440752],
      direction: [-179.4794916907, 131.066851361, 179.6079313796],
      "speed factor": 0.1702723669,
      "Uz factor": 0.9990641906,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6917823894,
      "Direction stability factor": 0.0025349359,
      score: 0.0,
    },
    "315": {
      velocity: [0.021769185, 0.0782499161, 0.3035678333],
      direction: [-172.6662919005, 115.8065943896, 179.4746251207],
      "speed factor": 0.0964116839,
      "Uz factor": 0.7723064021,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7713948005,
      "Direction stability factor": 0.0218307861,
      score: 0.0,
    },
  },
  {
    position: [-45.83106275, 46.13737702, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.034860274, 0.3989269898, 0.8929021184],
      direction: [-120.2763388391, -3.4575593671, 150.7018273023],
      "speed factor": 0.4088008514,
      "Uz factor": 0.9999427684,
      "Directional variance factor": 0.6926613896,
      "Velocity stability factor": 0.4038061046,
      "Direction stability factor": 0.2472828718,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0431163545, 0.4116244449, 0.9801675187],
      direction: [-173.0485302817, -10.71778974, 172.9836996581],
      "speed factor": 0.3654713221,
      "Uz factor": 0.9515772396,
      "Directional variance factor": 0.0473075787,
      "Velocity stability factor": 0.394033891,
      "Direction stability factor": 0.0387993613,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1132581714, 0.2955376387, 0.5532737525],
      direction: [-27.0811435284, 23.1193019861, 99.3330310197],
      "speed factor": 0.4578075043,
      "Uz factor": 0.7484824967,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6068220857,
      "Direction stability factor": 0.6488495151,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2551565582, 0.4905154472, 0.6898939532],
      direction: [-79.9502464225, -2.3959272336, 42.804924185],
      "speed factor": 0.5517956102,
      "Uz factor": 0.5318780072,
      "Directional variance factor": 0.7870286903,
      "Velocity stability factor": 0.6401161116,
      "Direction stability factor": 0.659013415,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1662300091, 0.6022946686, 1.0374501128],
      direction: [-26.7673001947, 1.5994055275, 21.2174338803],
      "speed factor": 0.8556443146,
      "Uz factor": 0.9682207233,
      "Directional variance factor": 0.8578306198,
      "Velocity stability factor": 0.2875236528,
      "Direction stability factor": 0.866709072,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0239901481, 0.1364358782, 0.413362195],
      direction: [-179.6589362003, -75.6739617874, 179.0197117176],
      "speed factor": 0.1598558373,
      "Uz factor": 0.522099737,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7006110176,
      "Direction stability factor": 0.0036704225,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0064921605, 0.0957704389, 0.290516956],
      direction: [-171.5270670109, -72.0766470471, 129.2219235226],
      "speed factor": 0.1776657051,
      "Uz factor": 0.9980545685,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7243971503,
      "Direction stability factor": 0.1645861374,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0095661064, 0.0713258236, 0.4164953443],
      direction: [-176.8618867318, 128.6683588787, 177.2856135158],
      "speed factor": 0.0971279885,
      "Uz factor": 0.8761882062,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7015826293,
      "Direction stability factor": 0.0162569438,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0148689227, 0.1196356548, 0.431015273],
      direction: [-178.8045012268, 93.4762529353, 179.8779013505],
      "speed factor": 0.1519298565,
      "Uz factor": 0.6345734437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.664821384,
      "Direction stability factor": 0.0036599928,
      score: 0.0,
    },
    id: "IDN:81.0",
    "45": {
      velocity: [0.0336013372, 0.3178774546, 0.853852482],
      direction: [-179.0903669207, 8.3707856387, 145.9365643318],
      "speed factor": 0.4317988664,
      "Uz factor": 0.8387914929,
      "Directional variance factor": 0.2559301655,
      "Velocity stability factor": 0.3331617746,
      "Direction stability factor": 0.0971474132,
      score: 0.0,
    },
    "90": {
      velocity: [0.2549722384, 0.4968159308, 0.7959620966],
      direction: [-82.9371640342, -3.4331862449, 73.546923881],
      "speed factor": 0.8053531622,
      "Uz factor": 0.4338338678,
      "Directional variance factor": 0.6948278893,
      "Velocity stability factor": 0.6051544774,
      "Direction stability factor": 0.565321978,
      score: 0.0,
    },
    "135": {
      velocity: [0.1065054191, 0.4236679416, 0.6749280897],
      direction: [-124.6399173348, -32.1030755393, 59.4797549747],
      "speed factor": 0.3585715,
      "Uz factor": 0.6859902304,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5419588676,
      "Direction stability factor": 0.4885564658,
      score: 0.0,
    },
    "180": {
      velocity: [0.0537820146, 0.4573581793, 0.8697838002],
      direction: [-143.1711328545, -17.7076069375, 119.5278353835],
      "speed factor": 0.8932176988,
      "Uz factor": 0.9639225781,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3570257785,
      "Direction stability factor": 0.2702806438,
      score: 0.0,
    },
    "225": {
      velocity: [0.0071254201, 0.0907014461, 0.2276044681],
      direction: [-163.0393352674, -17.9566425726, 71.7286327822],
      "speed factor": 0.1238173998,
      "Uz factor": 0.9847527365,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8018377067,
      "Direction stability factor": 0.3478667554,
      score: 0.0,
    },
    "270": {
      velocity: [0.0205485353, 0.1613703661, 0.4356073455],
      direction: [-179.3447081385, 148.2022971077, 176.1445204649],
      "speed factor": 0.1476590956,
      "Uz factor": 0.9817350118,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6726002806,
      "Direction stability factor": 0.0125299205,
      score: 0.0,
    },
    "315": {
      velocity: [0.0182231589, 0.09189889, 0.25659894],
      direction: [-175.4103974878, 111.096462147, 179.0060466016],
      "speed factor": 0.113228578,
      "Uz factor": 0.6077987724,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8066209924,
      "Direction stability factor": 0.0155098775,
      score: 0.0,
    },
  },
  {
    position: [-45.82059079, 49.13735874, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0628630921, 0.5272067986, 0.9513646088],
      direction: [-61.6273175113, -20.8552779645, 132.2680365418],
      "speed factor": 0.54025572,
      "Uz factor": 0.999501065,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3826417862,
      "Direction stability factor": 0.4614017943,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0572071757, 0.4226498094, 0.8323522424],
      direction: [-118.425115644, -11.8807213739, 121.4950235933],
      "speed factor": 0.3752604747,
      "Uz factor": 0.9673347816,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4987342656,
      "Direction stability factor": 0.3335551688,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0910810469, 0.3587148187, 0.6994383944],
      direction: [-60.120667597, 35.5787309916, 129.7997201757],
      "speed factor": 0.5556731679,
      "Uz factor": 0.7686713995,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4563995384,
      "Direction stability factor": 0.4724433673,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.335662533, 0.7239303474, 0.9880799821],
      direction: [-39.1819695177, 9.2604876993, 35.7842442662],
      "speed factor": 0.8143710664,
      "Uz factor": 0.8680546584,
      "Directional variance factor": 0.1768455378,
      "Velocity stability factor": 0.4599164203,
      "Direction stability factor": 0.7917605173,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.104283383, 0.5421594182, 0.898874364],
      direction: [-32.7369409723, 3.296659224, 25.8796398822],
      "speed factor": 0.7702137309,
      "Uz factor": 0.9337677897,
      "Directional variance factor": 0.7069636245,
      "Velocity stability factor": 0.3501902937,
      "Direction stability factor": 0.8371761643,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0170439379, 0.1400210576, 0.423019267],
      direction: [-176.4332723975, -69.9433347977, 177.6357989833],
      "speed factor": 0.1640564322,
      "Uz factor": 0.8637960948,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6878447192,
      "Direction stability factor": 0.0164748017,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0312383559, 0.1552421581, 0.3641883063],
      direction: [-110.9427794505, -68.9575138661, 134.6034904647],
      "speed factor": 0.2879929109,
      "Uz factor": 0.9982232064,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6769227315,
      "Direction stability factor": 0.317927028,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.012301098, 0.0849115106, 0.2853466194],
      direction: [-176.318673664, 135.10178705, 176.5379024284],
      "speed factor": 0.115628307,
      "Uz factor": 0.9179605009,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7997648756,
      "Direction stability factor": 0.0198428442,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0187678151, 0.1551253414, 0.5391147443],
      direction: [-177.96944442, 32.1175199828, 177.1088647501],
      "speed factor": 0.1969995558,
      "Uz factor": 0.7035963777,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5808946456,
      "Direction stability factor": 0.0136713634,
      score: 0.0,
    },
    id: "IDN:80.0",
    "45": {
      velocity: [0.0512242054, 0.387718235, 0.8339687738],
      direction: [-145.7508397371, 7.6290187456, 137.940380952],
      "speed factor": 0.5266692933,
      "Uz factor": 0.8322159176,
      "Directional variance factor": 0.3218650004,
      "Velocity stability factor": 0.3636534344,
      "Direction stability factor": 0.2119688314,
      score: 0.0,
    },
    "90": {
      velocity: [0.3692822864, 0.6847020296, 0.9542411078],
      direction: [-58.4762502281, 22.4789995159, 73.1207761099],
      "speed factor": 1.1099220266,
      "Uz factor": 0.8181886313,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5730633985,
      "Direction stability factor": 0.6344527046,
      score: 0.0,
    },
    "135": {
      velocity: [0.0851130066, 0.472023461, 0.9003010278],
      direction: [-102.176434241, -28.8266012793, 29.4055771766],
      "speed factor": 0.3994972095,
      "Uz factor": 0.6712595927,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3431126808,
      "Direction stability factor": 0.6344944127,
      score: 0.0,
    },
    "180": {
      velocity: [0.0576941834, 0.3905551118, 0.7673673015],
      direction: [-162.1039012142, -18.1630288476, 54.6429013843],
      "speed factor": 0.7627517205,
      "Uz factor": 0.9253951125,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4408081837,
      "Direction stability factor": 0.3979255483,
      score: 0.0,
    },
    "225": {
      velocity: [0.0191982557, 0.0896461064, 0.2270046017],
      direction: [-98.9723252991, -8.9817789617, 107.8789078727],
      "speed factor": 0.1223767456,
      "Uz factor": 0.9952676622,
      "Directional variance factor": 0.2016196479,
      "Velocity stability factor": 0.8132276856,
      "Direction stability factor": 0.4254132412,
      score: 0.0,
    },
    "270": {
      velocity: [0.0084939072, 0.1625464172, 0.350291243],
      direction: [-176.3681895943, 140.0030165114, 175.6001865796],
      "speed factor": 0.1487352203,
      "Uz factor": 0.9373880373,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7303891663,
      "Direction stability factor": 0.0223100662,
      score: 0.0,
    },
    "315": {
      velocity: [0.0264131144, 0.1259382892, 0.6887344818],
      direction: [-177.8556523986, 110.5874588012, 179.816417802],
      "speed factor": 0.1551685055,
      "Uz factor": 0.8017285277,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.462701084,
      "Direction stability factor": 0.0064664717,
      score: 0.0,
    },
  },
  {
    position: [-47.07558315, 49.14173951, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0214593622, 0.3109533971, 0.7049512248],
      direction: [-176.5394699881, 7.3775650319, 179.2402695132],
      "speed factor": 0.31864982,
      "Uz factor": 0.9572649103,
      "Directional variance factor": 0.3442164416,
      "Velocity stability factor": 0.5250888069,
      "Direction stability factor": 0.0117229458,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0457807539, 0.3303673979, 0.888321645],
      direction: [-135.1368313645, 17.5091094791, 159.2160823506],
      "speed factor": 0.2933251686,
      "Uz factor": 0.9279804926,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4551511753,
      "Direction stability factor": 0.1823530175,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0258456485, 0.2756855268, 0.7080684482],
      direction: [-147.5486175002, -6.4191855735, 77.2338004101],
      "speed factor": 0.4270552597,
      "Uz factor": 0.9304516228,
      "Directional variance factor": 0.4294057268,
      "Velocity stability factor": 0.3903967292,
      "Direction stability factor": 0.3756043947,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.028749558, 0.3043642455, 0.529829586],
      direction: [-135.614504464, -49.1847129299, 10.9037311613],
      "speed factor": 0.342388513,
      "Uz factor": 0.929388254,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5851964174,
      "Direction stability factor": 0.593004901,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0055286934, 0.1396121255, 0.5757605538],
      direction: [-176.1293442931, -5.6610226713, 177.0071870715],
      "speed factor": 0.1983386666,
      "Uz factor": 0.9932538207,
      "Directional variance factor": 0.4967979848,
      "Velocity stability factor": 0.5336692631,
      "Direction stability factor": 0.0190651907,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0179317772, 0.0831984697, 0.2308876605],
      direction: [-179.5288958167, 131.8565336965, 179.0488194279],
      "speed factor": 0.0974799387,
      "Uz factor": 0.6500495239,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8362577753,
      "Direction stability factor": 0.003950791,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0126611969, 0.0806073188, 0.2712232984],
      direction: [-159.9510811101, -48.8780330451, 178.9646741799],
      "speed factor": 0.14953629,
      "Uz factor": 0.8191229335,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7491048206,
      "Direction stability factor": 0.0585673464,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0115679719, 0.0726908258, 0.3196582338],
      direction: [-100.8934759839, 100.6929060222, 178.6860787715],
      "speed factor": 0.0989867812,
      "Uz factor": 0.959637333,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7740651756,
      "Direction stability factor": 0.2233901257,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0187862738, 0.1035249756, 0.3005788616],
      direction: [-174.1698506186, 86.0575637646, 172.5603363617],
      "speed factor": 0.1314702938,
      "Uz factor": 0.7349044775,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7730345358,
      "Direction stability factor": 0.0368605917,
      score: 0.0,
    },
    id: "IDN:79.0",
    "45": {
      velocity: [0.0209223288, 0.3001780805, 0.6654433625],
      direction: [-152.6802763328, 3.7579121098, 177.0884967461],
      "speed factor": 0.4077563633,
      "Uz factor": 0.9144601362,
      "Directional variance factor": 0.665963368,
      "Velocity stability factor": 0.4760247943,
      "Direction stability factor": 0.0839756303,
      score: 0.0,
    },
    "90": {
      velocity: [0.1138671873, 0.5886218894, 0.8879618942],
      direction: [-59.8553095884, -25.0725164409, 4.9597470374],
      "speed factor": 0.9541733076,
      "Uz factor": 0.9656085989,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.435021148,
      "Direction stability factor": 0.819958176,
      score: 0.0,
    },
    "135": {
      velocity: [0.0154265781, 0.2197639313, 0.5215035375],
      direction: [-179.5571683193, -83.3816178168, 178.7625224458],
      "speed factor": 0.1859972746,
      "Uz factor": 0.7279184142,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5921977157,
      "Direction stability factor": 0.0046675257,
      score: 0.0,
    },
    "180": {
      velocity: [0.018554593, 0.2107136813, 0.5400453394],
      direction: [-147.2268408853, -33.9035296427, 178.6892214012],
      "speed factor": 0.4115225179,
      "Uz factor": 0.9881999296,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5890877782,
      "Direction stability factor": 0.0946776048,
      score: 0.0,
    },
    "225": {
      velocity: [0.0151401947, 0.092235481, 0.2192373454],
      direction: [-149.9599993512, 1.5133190883, 153.6011158993],
      "speed factor": 0.1259115254,
      "Uz factor": 0.9910167791,
      "Directional variance factor": 0.8654827477,
      "Velocity stability factor": 0.8165614385,
      "Direction stability factor": 0.1567746799,
      score: 0.0,
    },
    "270": {
      velocity: [0.0179479691, 0.1789962, 0.4288802391],
      direction: [-170.7469094975, 123.5799260134, 170.2257972128],
      "speed factor": 0.1637873027,
      "Uz factor": 0.9718399267,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6758553087,
      "Direction stability factor": 0.0528535925,
      score: 0.0,
    },
    "315": {
      velocity: [0.0193992894, 0.1267362914, 0.3811162378],
      direction: [-167.478341736, 109.5270152879, 171.9086035292],
      "speed factor": 0.1561517235,
      "Uz factor": 0.9454993332,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.706562201,
      "Direction stability factor": 0.0572584854,
      score: 0.0,
    },
  },
  {
    position: [-50.07556487, 49.15221146, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0496647865, 0.2011246743, 0.426113946],
      direction: [-162.1246429728, 19.0953905367, 141.1223759363],
      "speed factor": 0.206102721,
      "Uz factor": 0.7959938376,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7384315319,
      "Direction stability factor": 0.1576471697,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0277813677, 0.1229062416, 0.3196728968],
      direction: [-124.5100365117, 33.2675447242, 164.8887941517],
      "speed factor": 0.1091254593,
      "Uz factor": 0.8540048122,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8112414979,
      "Direction stability factor": 0.1961143593,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0186792633, 0.0750063013, 0.2076028245],
      direction: [-112.4150677325, -47.7625792712, 144.6481707111],
      "speed factor": 0.116189761,
      "Uz factor": 0.7340354103,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8311864967,
      "Direction stability factor": 0.2859354488,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0246853452, 0.1203781691, 0.2779088816],
      direction: [-179.1654374787, -112.5884141796, 176.8990404099],
      "speed factor": 0.1354170306,
      "Uz factor": 0.7971068871,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7903767378,
      "Direction stability factor": 0.0109320059,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0213007253, 0.1919369307, 0.4553215441],
      direction: [-161.8937812705, -28.0452846414, 175.790505821],
      "speed factor": 0.2726734139,
      "Uz factor": 0.9990848965,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6450614876,
      "Direction stability factor": 0.0619880914,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0214429962, 0.1309427477, 0.3199033374],
      direction: [-175.7961425131, -22.3274121166, 171.1667855343],
      "speed factor": 0.1534197812,
      "Uz factor": 0.9984232596,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7705132185,
      "Direction stability factor": 0.0362140888,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0060656414, 0.0469458091, 0.1327521326],
      direction: [-179.4348903192, 23.3074496581, 168.4721849847],
      "speed factor": 0.0870901331,
      "Uz factor": 0.9885138535,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8770700356,
      "Direction stability factor": 0.0335914575,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.013901257, 0.0452912911, 0.1204630104],
      direction: [34.3942532972, 108.1821383683, 176.334422171],
      "speed factor": 0.0616754464,
      "Uz factor": 0.9207065631,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9218540343,
      "Direction stability factor": 0.6057217531,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0079192149, 0.0534007592, 0.2323765232],
      direction: [-176.9861830195, -3.6688788352, 171.903782787],
      "speed factor": 0.0678156498,
      "Uz factor": 0.484543162,
      "Directional variance factor": 0.6738774369,
      "Velocity stability factor": 0.8192143463,
      "Direction stability factor": 0.0308612061,
      score: 0.0,
    },
    id: "IDN:78.0",
    "45": {
      velocity: [0.0302776197, 0.1255719716, 0.4273436328],
      direction: [-174.1007254292, 0.2495918165, 147.9404906024],
      "speed factor": 0.1705746482,
      "Uz factor": 0.86925345,
      "Directional variance factor": 0.9778140608,
      "Velocity stability factor": 0.6771978957,
      "Direction stability factor": 0.1054410666,
      score: 0.0,
    },
    "90": {
      velocity: [0.1055268354, 0.203271391, 0.3645906682],
      direction: [-136.4482153252, -105.5857416217, -13.3873893836],
      "speed factor": 0.3295088732,
      "Uz factor": 0.7179249934,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8109203104,
      "Direction stability factor": 0.6581643724,
      score: 0.0,
    },
    "135": {
      velocity: [0.0100943062, 0.1078020947, 0.2437218927],
      direction: [-179.0934469739, -77.0875347089, 171.1240954207],
      "speed factor": 0.0912383378,
      "Uz factor": 0.7700389745,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8117403654,
      "Direction stability factor": 0.0271734933,
      score: 0.0,
    },
    "180": {
      velocity: [0.0143278378, 0.1455678716, 0.4381763132],
      direction: [-179.8193232895, -106.9063022861, 179.982668735],
      "speed factor": 0.2842931541,
      "Uz factor": 0.9552502358,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6660256775,
      "Direction stability factor": 0.0005500222,
      score: 0.0,
    },
    "225": {
      velocity: [0.0086325054, 0.0822221448, 0.1992296027],
      direction: [-163.5470960324, -20.2232055632, 155.0139742967],
      "speed factor": 0.1122422256,
      "Uz factor": 0.994932206,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8286950246,
      "Direction stability factor": 0.115108138,
      score: 0.0,
    },
    "270": {
      velocity: [0.0225157894, 0.124647224, 0.4044021974],
      direction: [-171.6562941618, 132.5992802377, 177.3335434859],
      "speed factor": 0.1140562347,
      "Uz factor": 0.9993103521,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6987667777,
      "Direction stability factor": 0.0305837843,
      score: 0.0,
    },
    "315": {
      velocity: [0.0054012583, 0.0541392744, 0.3890160798],
      direction: [-172.2885367104, 110.8270419059, 179.3676444311],
      "speed factor": 0.0667049739,
      "Uz factor": 0.9156398821,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6887978587,
      "Direction stability factor": 0.0231772746,
      score: 0.0,
    },
  },
  {
    position: [-50.06973548, 50.82220129, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0495189459, 0.1880679368, 0.509788053],
      direction: [-179.6884499942, 105.6406120357, 179.1240868202],
      "speed factor": 0.1927228155,
      "Uz factor": 0.2332296453,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6801908512,
      "Direction stability factor": 0.0032985088,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0236918642, 0.2655963239, 0.7005598181],
      direction: [-152.7441568344, 34.7900222908, 169.3416271331],
      "speed factor": 0.235816509,
      "Uz factor": 0.710279753,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5622874652,
      "Direction stability factor": 0.1053172668,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0181314813, 0.240353499, 0.5074034228],
      direction: [-107.377866984, -10.6036902333, 75.222780222],
      "speed factor": 0.3723235932,
      "Uz factor": 0.6879924597,
      "Directional variance factor": 0.057449757,
      "Velocity stability factor": 0.5628088419,
      "Direction stability factor": 0.49277598,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0604638967, 0.4140720793, 0.8146782744],
      direction: [-113.9903839814, -56.2467218233, 106.165800035],
      "speed factor": 0.4658021617,
      "Uz factor": 0.8151692704,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3756469857,
      "Direction stability factor": 0.3884550444,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0294357391, 0.1654491611, 0.6482194153],
      direction: [-179.3537726941, -104.2222401556, 59.8624456248],
      "speed factor": 0.2350438107,
      "Uz factor": 0.7573971053,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4939640035,
      "Direction stability factor": 0.3355105047,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0277352407, 0.1842339483, 0.5402248849],
      direction: [-176.2912107047, -37.0601319339, 175.6831232097],
      "speed factor": 0.2158587057,
      "Uz factor": 0.9998511567,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6059456392,
      "Direction stability factor": 0.0222935169,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0118889166, 0.0598830594, 0.2394166328],
      direction: [-178.6780517855, -148.4721996687, 179.8769562107],
      "speed factor": 0.1110902914,
      "Uz factor": 0.8570717469,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7792189698,
      "Direction stability factor": 0.0040138667,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0112943343, 0.0766339653, 0.2033125987],
      direction: [-170.8501549617, 111.2019484207, 170.0816062524],
      "speed factor": 0.1043563541,
      "Uz factor": 0.9279879869,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.859185381,
      "Direction stability factor": 0.05296733,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0063396602, 0.0631548991, 0.1781008227],
      direction: [-175.0004630785, -170.4819312693, 174.1430999907],
      "speed factor": 0.0802028022,
      "Uz factor": 0.1269771961,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8616576387,
      "Direction stability factor": 0.0301567693,
      score: 0.0,
    },
    id: "IDN:77.0",
    "45": {
      velocity: [0.0343383942, 0.2534253854, 0.6186825331],
      direction: [-165.7807473717, 14.6821975843, 170.013713223],
      "speed factor": 0.3442483653,
      "Uz factor": 0.6345518986,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5249467055,
      "Direction stability factor": 0.0672376095,
      score: 0.0,
    },
    "90": {
      velocity: [0.157598401, 0.429690525, 0.614301258],
      direction: [-82.2710751391, -43.9411068596, 70.7896766421],
      "speed factor": 0.6965409151,
      "Uz factor": 0.5883989423,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6666719801,
      "Direction stability factor": 0.5748312451,
      score: 0.0,
    },
    "135": {
      velocity: [0.0691611313, 0.2679633122, 0.5844542845],
      direction: [-150.7356149255, -73.206379194, 176.4066900901],
      "speed factor": 0.2267908363,
      "Uz factor": 0.5477560982,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5847712071,
      "Direction stability factor": 0.091271375,
      score: 0.0,
    },
    "180": {
      velocity: [0.0177920996, 0.2148093107, 0.5234302436],
      direction: [-178.1661215433, -135.359777277, 179.9077268269],
      "speed factor": 0.4195212567,
      "Uz factor": 0.8659474814,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6015789454,
      "Direction stability factor": 0.0053504212,
      score: 0.0,
    },
    "225": {
      velocity: [0.0132772655, 0.0918133973, 0.277096657],
      direction: [-179.6782949626, -36.5018391338, 171.681983957],
      "speed factor": 0.1253353349,
      "Uz factor": 0.997797538,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7628842465,
      "Direction stability factor": 0.0239992252,
      score: 0.0,
    },
    "270": {
      velocity: [0.0341424177, 0.1660862032, 0.4504056188],
      direction: [-179.8862486334, 156.8858863195, 178.3502191813],
      "speed factor": 0.1519742388,
      "Uz factor": 0.9521172898,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6716502531,
      "Direction stability factor": 0.0048987005,
      score: 0.0,
    },
    "315": {
      velocity: [0.0107050197, 0.0631843977, 0.1984818731],
      direction: [-176.2150045636, 109.8715478814, 179.9380418528],
      "speed factor": 0.077849466,
      "Uz factor": 0.5350683022,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8476686625,
      "Direction stability factor": 0.0106859822,
      score: 0.0,
    },
  },
  {
    position: [-50.05926353, 53.82218301, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0358759028, 0.149424699, 0.5794933343],
      direction: [-173.3575751537, -112.7274921364, 179.5508420634],
      "speed factor": 0.1531231171,
      "Uz factor": 0.9078304029,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6222778688,
      "Direction stability factor": 0.0196988411,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.026817316, 0.2743951452, 0.7821709886],
      direction: [-166.3522807294, 21.7607698462, 170.2036483953],
      "speed factor": 0.2436287682,
      "Uz factor": 0.7376127771,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5115328347,
      "Direction stability factor": 0.0651224191,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0996106868, 0.2778364704, 0.5720035847],
      direction: [-45.8794180683, 31.159430696, 116.3079621158],
      "speed factor": 0.4303872146,
      "Uz factor": 0.7186884958,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5778911878,
      "Direction stability factor": 0.5494794995,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0708116651, 0.439154714, 0.680693064],
      direction: [-71.3769439409, -15.1219817878, 35.3577620939],
      "speed factor": 0.4940183735,
      "Uz factor": 0.6262041069,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4951285722,
      "Direction stability factor": 0.7035147055,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0208935147, 0.199652797, 0.5764711701],
      direction: [-154.7568835767, -39.6885550707, 141.6714015148],
      "speed factor": 0.2836348877,
      "Uz factor": 0.9150910862,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5456533465,
      "Direction stability factor": 0.176588097,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0157914768, 0.2018935994, 0.5752470856],
      direction: [-177.6609258554, -21.3465574235, 164.414982333],
      "speed factor": 0.2365497317,
      "Uz factor": 0.9990234522,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5698334107,
      "Direction stability factor": 0.0497891439,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0141373876, 0.0668112633, 0.2816908449],
      direction: [-178.8094236456, -48.6912018966, 176.8025892444],
      "speed factor": 0.1239429446,
      "Uz factor": 0.5613389069,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7403800779,
      "Direction stability factor": 0.0121888531,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0227534343, 0.0900122156, 0.4151579002],
      direction: [-174.9693638966, 135.3265985163, 179.0631045625],
      "speed factor": 0.1225741956,
      "Uz factor": 0.5653082134,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7122342214,
      "Direction stability factor": 0.0165764765,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0095066175, 0.0517145352, 0.1205141638],
      direction: [-179.4192323063, -143.0278775611, 179.5353112585],
      "speed factor": 0.0656742501,
      "Uz factor": 0.9225920895,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9105906955,
      "Direction stability factor": 0.0029040457,
      score: 0.0,
    },
    id: "IDN:76.0",
    "45": {
      velocity: [0.0296148597, 0.252671077, 0.5893475815],
      direction: [-170.2044331421, 21.9498326068, 179.4398237223],
      "speed factor": 0.3432237267,
      "Uz factor": 0.6533598976,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5449550089,
      "Direction stability factor": 0.0287659532,
      score: 0.0,
    },
    "90": {
      velocity: [0.1794356152, 0.4414972308, 0.6297428782],
      direction: [-75.7352662131, -19.8735635473, 53.9837668785],
      "speed factor": 0.7156799307,
      "Uz factor": 0.4990268076,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6713398525,
      "Direction stability factor": 0.6396693525,
      score: 0.0,
    },
    "135": {
      velocity: [0.0205637101, 0.2902393203, 0.592750991],
      direction: [-124.6595267657, -44.8397117005, 123.6785177842],
      "speed factor": 0.245644143,
      "Uz factor": 0.6092182842,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.538925304,
      "Direction stability factor": 0.3101720985,
      score: 0.0,
    },
    "180": {
      velocity: [0.0277811485, 0.2424077502, 0.542113775],
      direction: [-177.9102494841, -93.1318890043, 82.909129164],
      "speed factor": 0.4734208386,
      "Uz factor": 0.8037127342,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.594728068,
      "Direction stability factor": 0.275501726,
      score: 0.0,
    },
    "225": {
      velocity: [0.0060342566, 0.0850340704, 0.2694191857],
      direction: [-179.6825585382, -23.1064966842, 177.5233321293],
      "speed factor": 0.1160808118,
      "Uz factor": 0.9944157598,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7632747327,
      "Direction stability factor": 0.0077614148,
      score: 0.0,
    },
    "270": {
      velocity: [0.0357196398, 0.1840282196, 0.4215090592],
      direction: [-179.7725510612, 179.9183345408, 179.2782972577],
      "speed factor": 0.1683917632,
      "Uz factor": 0.7869896785,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6956880698,
      "Direction stability factor": 0.0026365324,
      score: 0.0,
    },
    "315": {
      velocity: [0.0126311891, 0.0848289101, 0.3619761523],
      direction: [-165.7098718685, 98.0018148704, 177.5949375582],
      "speed factor": 0.1045176592,
      "Uz factor": 0.8745122336,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7165988004,
      "Direction stability factor": 0.0463755294,
      score: 0.0,
    },
  },
  {
    position: [-50.04879157, 56.82216473, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1551872567, 0.7363769423, 1.0875930324],
      direction: [-88.9932096197, -60.0534792068, -0.8135614424],
      "speed factor": 0.7546030443,
      "Uz factor": 0.9011396726,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3521357551,
      "Direction stability factor": 0.7550565328,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0427823008, 0.3479809922, 0.8241264889],
      direction: [-171.5416390632, -17.107461032, 143.6378150412],
      "speed factor": 0.30896385,
      "Uz factor": 0.8675226439,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4947254584,
      "Direction stability factor": 0.1245015164,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1106792416, 0.3622488618, 0.6480493907],
      direction: [-63.5593988586, 60.2106735433, 97.2381143382],
      "speed factor": 0.5611476363,
      "Uz factor": 0.8443603759,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5198304708,
      "Direction stability factor": 0.5533402411,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3478402211, 0.7169891934, 1.0981273324],
      direction: [-5.517034711, 15.0430536885, 31.1723389101],
      "speed factor": 0.8065627531,
      "Uz factor": 0.9391906532,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3788980516,
      "Direction stability factor": 0.8980850733,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1330424584, 0.3644061624, 0.7956470242],
      direction: [-93.1752209193, -6.7974829219, 91.0869070145],
      "speed factor": 0.5176902227,
      "Uz factor": 0.981426458,
      "Directional variance factor": 0.3957792958,
      "Velocity stability factor": 0.4581276548,
      "Direction stability factor": 0.4881607557,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0586791171, 0.2586622482, 0.5915602192],
      direction: [-130.7849673807, -14.7957782559, 140.255551498],
      "speed factor": 0.3030630273,
      "Uz factor": 0.9967672481,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5902666045,
      "Direction stability factor": 0.2471096698,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0311880605, 0.123313642, 0.2674209024],
      direction: [-168.055295632, -70.5499134843, 122.034599077],
      "speed factor": 0.2287616661,
      "Uz factor": 0.9406595358,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7707719698,
      "Direction stability factor": 0.1941947369,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.037605177, 0.6564257698, 0.9968807357],
      direction: [-154.5842644962, -14.8832227301, 177.3913972867],
      "speed factor": 0.8938882363,
      "Uz factor": 0.9682456813,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2965251366,
      "Direction stability factor": 0.0778453839,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2968764979, 0.5418251547, 0.7961899294],
      direction: [-61.5388604981, -33.3453286709, -13.815579028],
      "speed factor": 0.6880843185,
      "Uz factor": 0.7861073213,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5978357498,
      "Direction stability factor": 0.8674353292,
      score: 0.0,
    },
    id: "IDN:75.0",
    "45": {
      velocity: [0.0403452409, 0.3784526807, 0.784950523],
      direction: [-142.3343042199, 53.4738936859, 124.6988988292],
      "speed factor": 0.5140831354,
      "Uz factor": 0.9363066945,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3946594674,
      "Direction stability factor": 0.2582411026,
      score: 0.0,
    },
    "90": {
      velocity: [0.3351123331, 0.6146649658, 0.8087438376],
      direction: [10.0104995977, 39.4176462334, 62.6203511715],
      "speed factor": 0.9963898965,
      "Uz factor": 0.8547153479,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6543164792,
      "Direction stability factor": 0.8538615234,
      score: 0.0,
    },
    "135": {
      velocity: [0.0473664803, 0.537395687, 0.903914447],
      direction: [-161.1324963023, -8.2803257729, 140.6093702658],
      "speed factor": 0.4548250143,
      "Uz factor": 0.8467899363,
      "Directional variance factor": 0.2639710424,
      "Velocity stability factor": 0.3097843895,
      "Direction stability factor": 0.1618281484,
      score: 0.0,
    },
    "180": {
      velocity: [0.0370787466, 0.2955957273, 0.5647415399],
      direction: [-178.1223087858, -52.0709492, 40.3634165864],
      "speed factor": 0.5772966293,
      "Uz factor": 0.9534581486,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5842244714,
      "Direction stability factor": 0.3930952073,
      score: 0.0,
    },
    "225": {
      velocity: [0.0091967212, 0.1114332201, 0.4771028352],
      direction: [-175.8434210413, -29.9844095912, 151.4518131721],
      "speed factor": 0.1521185402,
      "Uz factor": 0.994567676,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5794550574,
      "Direction stability factor": 0.0908465716,
      score: 0.0,
    },
    "270": {
      velocity: [0.0244911717, 0.2652852871, 0.9901811656],
      direction: [-177.6000321251, -14.5663156313, 171.9370788562],
      "speed factor": 0.2427446036,
      "Uz factor": 0.8986869152,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2382606385,
      "Direction stability factor": 0.0290635806,
      score: 0.0,
    },
    "315": {
      velocity: [0.5014489488, 0.8088615444, 1.0249035712],
      direction: [-26.1024330149, -17.1438689453, -3.829886368],
      "speed factor": 0.996597919,
      "Uz factor": 0.9621201161,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5753547823,
      "Direction stability factor": 0.9381318149,
      score: 0.0,
    },
  },
  {
    position: [-53.79376876, 56.83523722, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.1119010459, 0.6243468965, 0.866487377],
      direction: [-21.6099263105, 20.8916425569, 72.2957987463],
      "speed factor": 0.6398001373,
      "Uz factor": 0.8227456201,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4756901809,
      "Direction stability factor": 0.7391507637,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0751273151, 0.4294264899, 0.9618561656],
      direction: [-175.7988513434, 8.3219683146, 167.7422183473],
      "speed factor": 0.3812773244,
      "Uz factor": 0.9593652709,
      "Directional variance factor": 0.2602694831,
      "Velocity stability factor": 0.4265759952,
      "Direction stability factor": 0.0457192509,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.013768661, 0.1117089419, 0.4882207513],
      direction: [-179.6662067895, 27.135510704, 172.8566675076],
      "speed factor": 0.1730445981,
      "Uz factor": 0.8180144737,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.576051187,
      "Direction stability factor": 0.0207697936,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0298731999, 0.1491311314, 0.4559347172],
      direction: [-167.9299410197, -23.3147429976, 173.9800905619],
      "speed factor": 0.1677621044,
      "Uz factor": 0.7984820529,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6472981682,
      "Direction stability factor": 0.0502499123,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0228425962, 0.1798821775, 0.5382960859],
      direction: [-170.8688106285, 1.1654671977, 147.647537144],
      "speed factor": 0.255547941,
      "Uz factor": 0.9998786976,
      "Directional variance factor": 0.8964029158,
      "Velocity stability factor": 0.578466546,
      "Direction stability factor": 0.1152323673,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0646359371, 0.2809878283, 0.6156084758],
      direction: [-79.4755914254, -14.7540470718, 19.6302870331],
      "speed factor": 0.3292209144,
      "Uz factor": 0.9850096102,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5763560611,
      "Direction stability factor": 0.7247058932,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1560641565, 0.3417818116, 0.5403870937],
      direction: [-55.0139696513, -31.595447995, -2.3394707413],
      "speed factor": 0.6340464474,
      "Uz factor": 0.913481734,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6270730643,
      "Direction stability factor": 0.8536819475,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.4515332407, 0.8008729805, 0.9524525507],
      direction: [-77.7396825274, -40.2883981473, -9.1173417855],
      "speed factor": 1.0905893233,
      "Uz factor": 0.8287623992,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6326559767,
      "Direction stability factor": 0.8093823868,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4504724906, 0.6416905275, 0.8085160318],
      direction: [-30.3726729438, 6.9643597301, 27.3952230687],
      "speed factor": 0.8149071439,
      "Uz factor": 0.9011707742,
      "Directional variance factor": 0.3809458018,
      "Velocity stability factor": 0.7116193894,
      "Direction stability factor": 0.8395336222,
      score: 0.0,
    },
    id: "IDN:74.0",
    "45": {
      velocity: [0.0395447361, 0.288530861, 0.8015087393],
      direction: [-136.5616932976, 29.7572038111, 157.788374794],
      "speed factor": 0.3919349954,
      "Uz factor": 0.9921478551,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3805473763,
      "Direction stability factor": 0.182360922,
      score: 0.0,
    },
    "90": {
      velocity: [0.0320813257, 0.2079649928, 0.4519452811],
      direction: [-179.880080818, 154.1933976925, 177.6799106321],
      "speed factor": 0.3371173391,
      "Uz factor": 0.9390398436,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6935591299,
      "Direction stability factor": 0.0067778015,
      score: 0.0,
    },
    "135": {
      velocity: [0.0125228289, 0.1259692129, 0.3121030217],
      direction: [-176.6789702397, 38.27300988, 173.5795233767],
      "speed factor": 0.1066140842,
      "Uz factor": 0.8561258193,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7585950424,
      "Direction stability factor": 0.02705974,
      score: 0.0,
    },
    "180": {
      velocity: [0.0387948519, 0.1921549999, 0.501629821],
      direction: [-145.3849875217, -43.1430549655, 138.427294803],
      "speed factor": 0.3752775278,
      "Uz factor": 0.9828261238,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6353060015,
      "Direction stability factor": 0.2116325491,
      score: 0.0,
    },
    "225": {
      velocity: [0.0639735432, 0.2680758861, 0.4820851537],
      direction: [-76.9399415478, -24.154581782, 23.3378328546],
      "speed factor": 0.3659529216,
      "Uz factor": 0.9161454628,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6242093917,
      "Direction stability factor": 0.7214506267,
      score: 0.0,
    },
    "270": {
      velocity: [0.3899729878, 0.7642138659, 1.054862089],
      direction: [-46.1195265772, -23.7097632358, 3.7448983932],
      "speed factor": 0.6992803635,
      "Uz factor": 0.8584803946,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4755333465,
      "Direction stability factor": 0.8614877084,
      score: 0.0,
    },
    "315": {
      velocity: [0.4341386249, 0.6452213018, 0.8564999089],
      direction: [-50.6069079248, -19.7853450814, 28.8978027505],
      "speed factor": 0.794976855,
      "Uz factor": 0.732703184,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6573653347,
      "Direction stability factor": 0.7791535815,
      score: 0.0,
    },
  },
  {
    position: [-50.56305378, 32.38881079, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0517797728, 0.3184562855, 0.7681225609],
      direction: [-156.0272248023, -6.3989768334, 70.0364045847],
      "speed factor": 0.3263384127,
      "Uz factor": 0.9999907568,
      "Directional variance factor": 0.4312020593,
      "Velocity stability factor": 0.5022629722,
      "Direction stability factor": 0.3720454739,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0131914557, 0.2132975922, 0.6334205592],
      direction: [-157.8506701293, -8.5304994572, 170.520219075],
      "speed factor": 0.1893817386,
      "Uz factor": 0.9906019258,
      "Directional variance factor": 0.2417333816,
      "Velocity stability factor": 0.598914306,
      "Direction stability factor": 0.0878586411,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1481340485, 0.3567228699, 0.5801375627],
      direction: [-50.2031188122, -26.6924365653, 15.2411067057],
      "speed factor": 0.5525875065,
      "Uz factor": 0.9019210074,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6139813044,
      "Direction stability factor": 0.8182104847,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.320272085, 0.6240410839, 0.8826808324],
      direction: [-73.0028727142, -51.8780141258, -28.2228799154],
      "speed factor": 0.7020026233,
      "Uz factor": 0.9215626449,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5344273365,
      "Direction stability factor": 0.8756111311,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2648728261, 0.6925240936, 0.9627453102],
      direction: [-70.0944401438, -4.2668465444, 29.7036195863],
      "speed factor": 0.9838279073,
      "Uz factor": 0.8685753269,
      "Directional variance factor": 0.6207247516,
      "Velocity stability factor": 0.429285853,
      "Direction stability factor": 0.7227831674,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3145033393, 0.6140794632, 0.8338424829],
      direction: [-15.8594605513, 23.6816605296, 34.4182874688],
      "speed factor": 0.7194895366,
      "Uz factor": 0.8798358956,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6006790448,
      "Direction stability factor": 0.8603395888,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2256539271, 0.4432475867, 0.6273855687],
      direction: [-11.088604993, 7.4999573708, 17.7714697058],
      "speed factor": 0.8222776875,
      "Uz factor": 0.9885370119,
      "Directional variance factor": 0.3333371226,
      "Velocity stability factor": 0.6101805653,
      "Direction stability factor": 0.9198331258,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0268840196, 0.0804668256, 0.2849616482],
      direction: [-174.9193248016, 109.3572633573, 178.1959381616],
      "speed factor": 0.1095757542,
      "Uz factor": 0.9979422628,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8107414258,
      "Direction stability factor": 0.0191242695,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0083767513, 0.1121398842, 0.4109835123],
      direction: [-173.5810004972, 25.197470848, 179.955720775],
      "speed factor": 0.1424106931,
      "Uz factor": 0.972424643,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6757266359,
      "Direction stability factor": 0.017953552,
      score: 0.0,
    },
    id: "IDN:89.0",
    "45": {
      velocity: [0.044025444, 0.2293388762, 0.5614149865],
      direction: [-173.0924395352, -8.6651277931, 142.1252282246],
      "speed factor": 0.3115296959,
      "Uz factor": 0.9275887696,
      "Directional variance factor": 0.2297664184,
      "Velocity stability factor": 0.5793786737,
      "Direction stability factor": 0.1243953673,
      score: 0.0,
    },
    "90": {
      velocity: [0.3388019578, 0.6859060872, 0.9390749786],
      direction: [-64.0457627102, -50.9545106728, -22.0723488131],
      "speed factor": 1.111873839,
      "Uz factor": 0.8907914498,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5618862147,
      "Direction stability factor": 0.8834071836,
      score: 0.0,
    },
    "135": {
      velocity: [0.0548984015, 0.4987246488, 0.8231844617],
      direction: [-115.4389553797, -77.8899112155, 41.3693004159],
      "speed factor": 0.4220957685,
      "Uz factor": 0.9657258424,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3809067879,
      "Direction stability factor": 0.5644215117,
      score: 0.0,
    },
    "180": {
      velocity: [0.2421424286, 0.5062632647, 0.7313530876],
      direction: [-63.503915412, 10.8251096472, 52.2187241555],
      "speed factor": 0.9887290285,
      "Uz factor": 0.831486105,
      "Directional variance factor": 0.0377680314,
      "Velocity stability factor": 0.6145230952,
      "Direction stability factor": 0.6785482234,
      score: 0.0,
    },
    "225": {
      velocity: [0.0699133964, 0.3201198441, 0.6415719657],
      direction: [-14.8076849456, 16.9705771904, 145.4507407609],
      "speed factor": 0.4369986196,
      "Uz factor": 0.9824731012,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4862043624,
      "Direction stability factor": 0.5548377064,
      score: 0.0,
    },
    "270": {
      velocity: [0.0141140188, 0.1135860128, 0.3341449288],
      direction: [-167.3297082241, 105.3521269088, 178.5241871069],
      "speed factor": 0.1039348693,
      "Uz factor": 0.9458968171,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7475585927,
      "Direction stability factor": 0.0392947352,
      score: 0.0,
    },
    "315": {
      velocity: [0.0632729977, 0.17379128, 0.3799882891],
      direction: [-133.1767454424, 25.2644841303, 153.3323490346],
      "speed factor": 0.2141281523,
      "Uz factor": 0.6087495851,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7430691637,
      "Direction stability factor": 0.2041414042,
      score: 0.0,
    },
  },
  {
    position: [-50.56305378, 32.38881079, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0517797728, 0.3184562855, 0.7681225609],
      direction: [-156.0272248023, -6.3989768334, 70.0364045847],
      "speed factor": 0.3263384127,
      "Uz factor": 0.9999907568,
      "Directional variance factor": 0.4312020593,
      "Velocity stability factor": 0.5022629722,
      "Direction stability factor": 0.3720454739,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0131914557, 0.2132975922, 0.6334205592],
      direction: [-157.8506701293, -8.5304994572, 170.520219075],
      "speed factor": 0.1893817386,
      "Uz factor": 0.9906019258,
      "Directional variance factor": 0.2417333816,
      "Velocity stability factor": 0.598914306,
      "Direction stability factor": 0.0878586411,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1481340485, 0.3567228699, 0.5801375627],
      direction: [-50.2031188122, -26.6924365653, 15.2411067057],
      "speed factor": 0.5525875065,
      "Uz factor": 0.9019210074,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6139813044,
      "Direction stability factor": 0.8182104847,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.320272085, 0.6240410839, 0.8826808324],
      direction: [-73.0028727142, -51.8780141258, -28.2228799154],
      "speed factor": 0.7020026233,
      "Uz factor": 0.9215626449,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5344273365,
      "Direction stability factor": 0.8756111311,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2648728261, 0.6925240936, 0.9627453102],
      direction: [-70.0944401438, -4.2668465444, 29.7036195863],
      "speed factor": 0.9838279073,
      "Uz factor": 0.8685753269,
      "Directional variance factor": 0.6207247516,
      "Velocity stability factor": 0.429285853,
      "Direction stability factor": 0.7227831674,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3145033393, 0.6140794632, 0.8338424829],
      direction: [-15.8594605513, 23.6816605296, 34.4182874688],
      "speed factor": 0.7194895366,
      "Uz factor": 0.8798358956,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6006790448,
      "Direction stability factor": 0.8603395888,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2256539271, 0.4432475867, 0.6273855687],
      direction: [-11.088604993, 7.4999573708, 17.7714697058],
      "speed factor": 0.8222776875,
      "Uz factor": 0.9885370119,
      "Directional variance factor": 0.3333371226,
      "Velocity stability factor": 0.6101805653,
      "Direction stability factor": 0.9198331258,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0268840196, 0.0804668256, 0.2849616482],
      direction: [-174.9193248016, 109.3572633573, 178.1959381616],
      "speed factor": 0.1095757542,
      "Uz factor": 0.9979422628,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8107414258,
      "Direction stability factor": 0.0191242695,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0083767513, 0.1121398842, 0.4109835123],
      direction: [-173.5810004972, 25.197470848, 179.955720775],
      "speed factor": 0.1424106931,
      "Uz factor": 0.972424643,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6757266359,
      "Direction stability factor": 0.017953552,
      score: 0.0,
    },
    id: "IDN:90.0",
    "45": {
      velocity: [0.044025444, 0.2293388762, 0.5614149865],
      direction: [-173.0924395352, -8.6651277931, 142.1252282246],
      "speed factor": 0.3115296959,
      "Uz factor": 0.9275887696,
      "Directional variance factor": 0.2297664184,
      "Velocity stability factor": 0.5793786737,
      "Direction stability factor": 0.1243953673,
      score: 0.0,
    },
    "90": {
      velocity: [0.3388019578, 0.6859060872, 0.9390749786],
      direction: [-64.0457627102, -50.9545106728, -22.0723488131],
      "speed factor": 1.111873839,
      "Uz factor": 0.8907914498,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5618862147,
      "Direction stability factor": 0.8834071836,
      score: 0.0,
    },
    "135": {
      velocity: [0.0548984015, 0.4987246488, 0.8231844617],
      direction: [-115.4389553797, -77.8899112155, 41.3693004159],
      "speed factor": 0.4220957685,
      "Uz factor": 0.9657258424,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3809067879,
      "Direction stability factor": 0.5644215117,
      score: 0.0,
    },
    "180": {
      velocity: [0.2421424286, 0.5062632647, 0.7313530876],
      direction: [-63.503915412, 10.8251096472, 52.2187241555],
      "speed factor": 0.9887290285,
      "Uz factor": 0.831486105,
      "Directional variance factor": 0.0377680314,
      "Velocity stability factor": 0.6145230952,
      "Direction stability factor": 0.6785482234,
      score: 0.0,
    },
    "225": {
      velocity: [0.0699133964, 0.3201198441, 0.6415719657],
      direction: [-14.8076849456, 16.9705771904, 145.4507407609],
      "speed factor": 0.4369986196,
      "Uz factor": 0.9824731012,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4862043624,
      "Direction stability factor": 0.5548377064,
      score: 0.0,
    },
    "270": {
      velocity: [0.0141140188, 0.1135860128, 0.3341449288],
      direction: [-167.3297082241, 105.3521269088, 178.5241871069],
      "speed factor": 0.1039348693,
      "Uz factor": 0.9458968171,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7475585927,
      "Direction stability factor": 0.0392947352,
      score: 0.0,
    },
    "315": {
      velocity: [0.0632729977, 0.17379128, 0.3799882891],
      direction: [-133.1767454424, 25.2644841303, 153.3323490346],
      "speed factor": 0.2141281523,
      "Uz factor": 0.6087495851,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7430691637,
      "Direction stability factor": 0.2041414042,
      score: 0.0,
    },
  },
  {
    position: [12.51858065, 102.9428494, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.4530151639, 1.0419736677, 1.4968704793],
      direction: [-55.1649122967, -30.6802348818, -5.5974636437],
      "speed factor": 1.0677636092,
      "Uz factor": 0.9185950911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2746971831,
      "Direction stability factor": 0.8623126426,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6722924452, 1.0129424415, 1.4000623107],
      direction: [-70.003465473, -29.0564926493, -2.2282927102],
      "speed factor": 0.8993669299,
      "Uz factor": 0.942293499,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5293705504,
      "Direction stability factor": 0.8117356312,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2253943522, 0.4418045266, 0.7621016353],
      direction: [-71.534073339, -0.7037154702, 111.3446765546],
      "speed factor": 0.6843846647,
      "Uz factor": 0.975159087,
      "Directional variance factor": 0.9374475138,
      "Velocity stability factor": 0.5204227777,
      "Direction stability factor": 0.4920034725,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5979533951, 1.1000949317, 1.3509801508],
      direction: [-9.6981198182, 14.1233003612, 32.5353732456],
      "speed factor": 1.2375299446,
      "Uz factor": 0.959114678,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3766301218,
      "Direction stability factor": 0.8826847415,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1182392706, 0.808106102, 1.2366551952],
      direction: [-21.6134077187, 12.0996842593, 158.7174544767],
      "speed factor": 1.1480284117,
      "Uz factor": 0.9989986923,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0853690252,
      "Direction stability factor": 0.4990809383,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0793924495, 0.3159138526, 0.7925313266],
      direction: [-174.5131550907, -73.4960003196, 178.4341941905],
      "speed factor": 0.3701421803,
      "Uz factor": 0.8094557056,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4516660237,
      "Direction stability factor": 0.0195906964,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1457588066, 0.3910666126, 0.9536528628],
      direction: [-79.900775776, -4.7020486905, 95.1740087732],
      "speed factor": 0.7254756924,
      "Uz factor": 0.9983759359,
      "Directional variance factor": 0.5820401164,
      "Velocity stability factor": 0.2160617395,
      "Direction stability factor": 0.513681154,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0729995404, 0.3703287783, 0.814550964],
      direction: [-177.5806015513, 14.9951675175, 168.6548939717],
      "speed factor": 0.5042954645,
      "Uz factor": 0.8640922955,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4561908915,
      "Direction stability factor": 0.0382347347,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2446583749, 0.6853731308, 1.1584975138],
      direction: [-91.076935791, -3.8415534906, 31.7783264404],
      "speed factor": 0.8703813389,
      "Uz factor": 0.9931338841,
      "Directional variance factor": 0.6585285786,
      "Velocity stability factor": 0.2639624554,
      "Direction stability factor": 0.6587353827,
      score: 0.0,
    },
    id: "IDN:308.0",
    "45": {
      velocity: [0.2354750412, 0.5811453726, 0.8704726962],
      direction: [-70.8494721343, -10.3388931029, 39.3465266922],
      "speed factor": 0.7894171463,
      "Uz factor": 0.9787331057,
      "Directional variance factor": 0.0809872797,
      "Velocity stability factor": 0.4837669998,
      "Direction stability factor": 0.6939000033,
      score: 0.0,
    },
    "90": {
      velocity: [0.388093125, 0.8283634062, 1.1202695086],
      direction: [-15.3839879123, 20.0236001051, 42.5065201059],
      "speed factor": 1.3428013219,
      "Uz factor": 0.9701714171,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4656155519,
      "Direction stability factor": 0.8391930333,
      score: 0.0,
    },
    "135": {
      velocity: [0.8605468468, 1.2837212123, 1.4259733084],
      direction: [3.9458988838, 13.8816274152, 26.4835394353],
      "speed factor": 1.086477865,
      "Uz factor": 0.9810196332,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5443732452,
      "Direction stability factor": 0.9373954429,
      score: 0.0,
    },
    "180": {
      velocity: [0.0226844733, 0.3337880206, 0.7660478185],
      direction: [-173.8994868347, 17.2192179492, 176.8842501088],
      "speed factor": 0.6518859423,
      "Uz factor": 0.9847176095,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4142617374,
      "Direction stability factor": 0.0256007307,
      score: 0.0,
    },
    "225": {
      velocity: [0.0288203815, 0.2803299254, 0.7324876786],
      direction: [-173.9833936169, -6.9654314608, 171.2157587313],
      "speed factor": 0.3826810262,
      "Uz factor": 0.9990017234,
      "Directional variance factor": 0.3808505368,
      "Velocity stability factor": 0.3675574775,
      "Direction stability factor": 0.0411134657,
      score: 0.0,
    },
    "270": {
      velocity: [0.257386352, 0.897255101, 1.3142819729],
      direction: [-91.9553783279, 2.1851622057, 23.6848090095],
      "speed factor": 0.8210173895,
      "Uz factor": 0.9933544602,
      "Directional variance factor": 0.8057633595,
      "Velocity stability factor": 0.1663173476,
      "Direction stability factor": 0.6787772574,
      score: 0.0,
    },
    "315": {
      velocity: [0.458871778, 0.9135613395, 1.2910659264],
      direction: [-59.0825383698, -23.606092718, 2.8847652228],
      "speed factor": 1.1255984861,
      "Uz factor": 0.9734250212,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3248941739,
      "Direction stability factor": 0.8278686011,
      score: 0.0,
    },
  },
  {
    position: [6.735951834, 93.60918028, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0520704542, 0.2728252282, 0.7388591349],
      direction: [-178.4614196505, -147.6775677269, 175.0568824845],
      "speed factor": 0.2795779388,
      "Uz factor": 0.9925374264,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5227980762,
      "Direction stability factor": 0.0180047163,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0463938558, 0.2573849276, 0.828765975],
      direction: [-179.072778808, 119.1910070127, 178.8566923701],
      "speed factor": 0.2285258102,
      "Uz factor": 0.9517090068,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4940607227,
      "Direction stability factor": 0.0057514689,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0192600424, 0.293542714, 0.7097740539],
      direction: [-55.2498117942, 31.2895400555, 154.8362452115],
      "speed factor": 0.454717233,
      "Uz factor": 0.9866313891,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3829880794,
      "Direction stability factor": 0.4164276194,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2974262946, 0.9390633621, 1.2812597021],
      direction: [-11.1177415225, 15.5487116246, 62.777279017],
      "speed factor": 1.0563806786,
      "Uz factor": 0.9504405687,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1855639833,
      "Direction stability factor": 0.7947360541,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5582649436, 0.9179561372, 1.2381690491],
      direction: [-26.9829209454, 9.0126586606, 61.3781013229],
      "speed factor": 1.3040858417,
      "Uz factor": 0.8470929721,
      "Directional variance factor": 0.1988747857,
      "Velocity stability factor": 0.4439802393,
      "Direction stability factor": 0.7545527159,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.044431693, 0.7868297753, 1.169819589],
      direction: [-105.865459432, -15.9416738973, 19.4027435896],
      "speed factor": 0.9218933776,
      "Uz factor": 0.8297009604,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1346868897,
      "Direction stability factor": 0.6520327694,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1082556103, 0.485548502, 0.8965041245],
      direction: [-63.7685628818, -6.6104199403, 106.7270199986],
      "speed factor": 0.9007509828,
      "Uz factor": 0.9571539878,
      "Directional variance factor": 0.4124071164,
      "Velocity stability factor": 0.235124749,
      "Direction stability factor": 0.5264011587,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0578557128, 0.255234935, 0.5898793166],
      direction: [-156.6205066968, -39.0299158751, 173.6247294239],
      "speed factor": 0.3475663455,
      "Uz factor": 0.968161661,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6098459628,
      "Direction stability factor": 0.0826521219,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0454999541, 0.317996774, 0.8145410858],
      direction: [-177.5933635025, -6.3847963321, 155.5303208655],
      "speed factor": 0.4038361668,
      "Uz factor": 0.9905346975,
      "Directional variance factor": 0.4324625483,
      "Velocity stability factor": 0.3805877619,
      "Direction stability factor": 0.0746564323,
      score: 0.0,
    },
    id: "IDN:321.0",
    "45": {
      velocity: [0.1061643924, 0.4204448455, 0.8384379742],
      direction: [-151.1001646746, 8.2435838476, 158.0626019787],
      "speed factor": 0.5711245169,
      "Uz factor": 0.9999678197,
      "Directional variance factor": 0.2672369913,
      "Velocity stability factor": 0.4046847494,
      "Direction stability factor": 0.1412145371,
      score: 0.0,
    },
    "90": {
      velocity: [0.1531252242, 0.7410739645, 1.2328926096],
      direction: [-149.8232279784, 21.1212339823, 66.6119250486],
      "speed factor": 1.2013025826,
      "Uz factor": 0.9774935512,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2119236413,
      "Direction stability factor": 0.3987912416,
      score: 0.0,
    },
    "135": {
      velocity: [0.6037696689, 1.2330472318, 1.4570395279],
      direction: [3.8603261995, 11.1799070811, 62.438432223],
      "speed factor": 1.0435899252,
      "Uz factor": 0.8784608926,
      "Directional variance factor": 0.0062304817,
      "Velocity stability factor": 0.3124259242,
      "Direction stability factor": 0.8372830388,
      score: 0.0,
    },
    "180": {
      velocity: [0.3806920169, 0.7670765808, 1.125421391],
      direction: [-39.472344045, 6.8866855566, 47.3343917198],
      "speed factor": 1.4980958235,
      "Uz factor": 0.8336057318,
      "Directional variance factor": 0.3878501727,
      "Velocity stability factor": 0.4131853655,
      "Direction stability factor": 0.7588701784,
      score: 0.0,
    },
    "225": {
      velocity: [0.107760405, 0.4701333158, 0.794594791],
      direction: [-130.6660556234, -13.1629439331, 54.8816947638],
      "speed factor": 0.6417834253,
      "Uz factor": 0.9211930444,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3826865716,
      "Direction stability factor": 0.4845895823,
      score: 0.0,
    },
    "270": {
      velocity: [0.1511503328, 0.5202920633, 1.1197362812],
      direction: [-95.100932449, -17.0003028438, 36.6565422081],
      "speed factor": 0.476084038,
      "Uz factor": 0.9979365827,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2359763003,
      "Direction stability factor": 0.6340070148,
      score: 0.0,
    },
    "315": {
      velocity: [0.0495581571, 0.2442265959, 0.5095960139],
      direction: [-170.9153500263, -97.0502021053, 26.3194932456],
      "speed factor": 0.3009114711,
      "Uz factor": 0.9499476015,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6268007436,
      "Direction stability factor": 0.4521254354,
      score: 0.0,
    },
  },
  {
    position: [-39.36757348, 97.04444617, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.4859209854, 1.0925041353, 1.3935346635],
      direction: [-65.152483151, -32.2964466465, 18.4977697728],
      "speed factor": 1.119544759,
      "Uz factor": 0.8266700553,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.369362068,
      "Direction stability factor": 0.7676381863,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6735256705, 1.2771386665, 1.5741634687],
      direction: [-29.4282607305, 7.7458613518, 26.7529300248],
      "speed factor": 1.1339403252,
      "Uz factor": 0.8834082918,
      "Directional variance factor": 0.311478991,
      "Velocity stability factor": 0.417581448,
      "Direction stability factor": 0.8439411368,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1121344237, 0.6441041775, 1.13056448],
      direction: [-35.5283974034, -9.5982133049, 169.4678800348],
      "speed factor": 0.9977603102,
      "Uz factor": 0.9673225103,
      "Directional variance factor": 0.146825484,
      "Velocity stability factor": 0.0899772131,
      "Direction stability factor": 0.430565896,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0349301135, 0.3962395462, 1.1227214947],
      direction: [-173.6723325453, 29.1281965111, 173.3869909481],
      "speed factor": 0.4457418078,
      "Uz factor": 0.9984745329,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0995055943,
      "Direction stability factor": 0.0359463236,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0276150698, 0.3261496896, 0.9329722953],
      direction: [-170.5027956472, 9.7004505468, 175.9590205236],
      "speed factor": 0.4633415207,
      "Uz factor": 0.9841544238,
      "Directional variance factor": 0.1377377292,
      "Velocity stability factor": 0.2596066067,
      "Direction stability factor": 0.0376060662,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0869667352, 0.4195560937, 0.905223338],
      direction: [-36.6408071711, 28.9767942151, 176.9018318476],
      "speed factor": 0.4915751748,
      "Uz factor": 0.9712667753,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3708407843,
      "Direction stability factor": 0.4068260027,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0509703459, 0.3087897868, 0.9391340954],
      direction: [-118.0493554145, -26.243252213, 78.3911024373],
      "speed factor": 0.5728422657,
      "Uz factor": 0.9917930898,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1381722152,
      "Direction stability factor": 0.4543320615,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.182536191, 0.6775181374, 1.1233778347],
      direction: [-113.9931537325, -0.758711554, 37.8329437543],
      "speed factor": 0.9226107822,
      "Uz factor": 0.9690702656,
      "Directional variance factor": 0.932558973,
      "Velocity stability factor": 0.3100434585,
      "Direction stability factor": 0.5782608403,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7956787444, 1.0037268345, 1.2882628023],
      direction: [-26.4603217551, -10.4200128599, 15.1675394099],
      "speed factor": 1.2746707842,
      "Uz factor": 0.8277336472,
      "Directional variance factor": 0.0737766347,
      "Velocity stability factor": 0.6032558193,
      "Direction stability factor": 0.8843670523,
      score: 0.0,
    },
    id: "IDN:335.0",
    "45": {
      velocity: [0.213736939, 0.6657886376, 1.1111975138],
      direction: [-56.9068482116, -23.351074471, 22.0660135962],
      "speed factor": 0.904394995,
      "Uz factor": 0.8751884071,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2703929511,
      "Direction stability factor": 0.7806309394,
      score: 0.0,
    },
    "90": {
      velocity: [0.0967879907, 0.6097926525, 1.0257529252],
      direction: [-62.0773959145, 34.1546141147, 120.1865478696],
      "speed factor": 0.9884917341,
      "Uz factor": 0.9993439268,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3219879459,
      "Direction stability factor": 0.4937112673,
      score: 0.0,
    },
    "135": {
      velocity: [0.0582849924, 0.4517735917, 1.153017894],
      direction: [-178.3112265921, 12.4869441606, 178.5041003888],
      "speed factor": 0.3823587261,
      "Uz factor": 0.9974142249,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1178523944,
      "Direction stability factor": 0.0088463139,
      score: 0.0,
    },
    "180": {
      velocity: [0.0448530948, 0.2249977688, 0.5816412384],
      direction: [-175.4685935628, 127.4645449599, 174.4741276656],
      "speed factor": 0.4394192526,
      "Uz factor": 0.9236695046,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5770340889,
      "Direction stability factor": 0.0279368855,
      score: 0.0,
    },
    "225": {
      velocity: [0.0917088116, 0.6238285808, 1.0982614405],
      direction: [-41.0688653198, -10.6790202442, 110.5616981259],
      "speed factor": 0.8515942818,
      "Uz factor": 0.9994897334,
      "Directional variance factor": 0.0507537561,
      "Velocity stability factor": 0.0953300143,
      "Direction stability factor": 0.5788039904,
      score: 0.0,
    },
    "270": {
      velocity: [0.1329259664, 0.7275672629, 1.3420346441],
      direction: [-105.7346697489, -2.6019817749, 44.8947679427],
      "speed factor": 0.6657475384,
      "Uz factor": 0.9982857168,
      "Directional variance factor": 0.7687127311,
      "Velocity stability factor": 0.0462512007,
      "Direction stability factor": 0.5815848953,
      score: 0.0,
    },
    "315": {
      velocity: [0.8356053683, 0.9754018757, 1.1506708998],
      direction: [-9.6157273864, 0.3960850954, 12.7862338803],
      "speed factor": 1.2017921809,
      "Uz factor": 0.841794275,
      "Directional variance factor": 0.964792436,
      "Velocity stability factor": 0.7444075084,
      "Direction stability factor": 0.9377723298,
      score: 0.0,
    },
  },
  {
    position: [12.51858065, 102.9428494, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.4530151639, 1.0419736677, 1.4968704793],
      direction: [-55.1649122967, -30.6802348818, -5.5974636437],
      "speed factor": 1.0677636092,
      "Uz factor": 0.9185950911,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2746971831,
      "Direction stability factor": 0.8623126426,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6722924452, 1.0129424415, 1.4000623107],
      direction: [-70.003465473, -29.0564926493, -2.2282927102],
      "speed factor": 0.8993669299,
      "Uz factor": 0.942293499,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5293705504,
      "Direction stability factor": 0.8117356312,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.2253943522, 0.4418045266, 0.7621016353],
      direction: [-71.534073339, -0.7037154702, 111.3446765546],
      "speed factor": 0.6843846647,
      "Uz factor": 0.975159087,
      "Directional variance factor": 0.9374475138,
      "Velocity stability factor": 0.5204227777,
      "Direction stability factor": 0.4920034725,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5979533951, 1.1000949317, 1.3509801508],
      direction: [-9.6981198182, 14.1233003612, 32.5353732456],
      "speed factor": 1.2375299446,
      "Uz factor": 0.959114678,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3766301218,
      "Direction stability factor": 0.8826847415,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1182392706, 0.808106102, 1.2366551952],
      direction: [-21.6134077187, 12.0996842593, 158.7174544767],
      "speed factor": 1.1480284117,
      "Uz factor": 0.9989986923,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0853690252,
      "Direction stability factor": 0.4990809383,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0793924495, 0.3159138526, 0.7925313266],
      direction: [-174.5131550907, -73.4960003196, 178.4341941905],
      "speed factor": 0.3701421803,
      "Uz factor": 0.8094557056,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4516660237,
      "Direction stability factor": 0.0195906964,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1457588066, 0.3910666126, 0.9536528628],
      direction: [-79.900775776, -4.7020486905, 95.1740087732],
      "speed factor": 0.7254756924,
      "Uz factor": 0.9983759359,
      "Directional variance factor": 0.5820401164,
      "Velocity stability factor": 0.2160617395,
      "Direction stability factor": 0.513681154,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0729995404, 0.3703287783, 0.814550964],
      direction: [-177.5806015513, 14.9951675175, 168.6548939717],
      "speed factor": 0.5042954645,
      "Uz factor": 0.8640922955,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4561908915,
      "Direction stability factor": 0.0382347347,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2446583749, 0.6853731308, 1.1584975138],
      direction: [-91.076935791, -3.8415534906, 31.7783264404],
      "speed factor": 0.8703813389,
      "Uz factor": 0.9931338841,
      "Directional variance factor": 0.6585285786,
      "Velocity stability factor": 0.2639624554,
      "Direction stability factor": 0.6587353827,
      score: 0.0,
    },
    id: "IDN:327.0",
    "45": {
      velocity: [0.2354750412, 0.5811453726, 0.8704726962],
      direction: [-70.8494721343, -10.3388931029, 39.3465266922],
      "speed factor": 0.7894171463,
      "Uz factor": 0.9787331057,
      "Directional variance factor": 0.0809872797,
      "Velocity stability factor": 0.4837669998,
      "Direction stability factor": 0.6939000033,
      score: 0.0,
    },
    "90": {
      velocity: [0.388093125, 0.8283634062, 1.1202695086],
      direction: [-15.3839879123, 20.0236001051, 42.5065201059],
      "speed factor": 1.3428013219,
      "Uz factor": 0.9701714171,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4656155519,
      "Direction stability factor": 0.8391930333,
      score: 0.0,
    },
    "135": {
      velocity: [0.8605468468, 1.2837212123, 1.4259733084],
      direction: [3.9458988838, 13.8816274152, 26.4835394353],
      "speed factor": 1.086477865,
      "Uz factor": 0.9810196332,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5443732452,
      "Direction stability factor": 0.9373954429,
      score: 0.0,
    },
    "180": {
      velocity: [0.0226844733, 0.3337880206, 0.7660478185],
      direction: [-173.8994868347, 17.2192179492, 176.8842501088],
      "speed factor": 0.6518859423,
      "Uz factor": 0.9847176095,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4142617374,
      "Direction stability factor": 0.0256007307,
      score: 0.0,
    },
    "225": {
      velocity: [0.0288203815, 0.2803299254, 0.7324876786],
      direction: [-173.9833936169, -6.9654314608, 171.2157587313],
      "speed factor": 0.3826810262,
      "Uz factor": 0.9990017234,
      "Directional variance factor": 0.3808505368,
      "Velocity stability factor": 0.3675574775,
      "Direction stability factor": 0.0411134657,
      score: 0.0,
    },
    "270": {
      velocity: [0.257386352, 0.897255101, 1.3142819729],
      direction: [-91.9553783279, 2.1851622057, 23.6848090095],
      "speed factor": 0.8210173895,
      "Uz factor": 0.9933544602,
      "Directional variance factor": 0.8057633595,
      "Velocity stability factor": 0.1663173476,
      "Direction stability factor": 0.6787772574,
      score: 0.0,
    },
    "315": {
      velocity: [0.458871778, 0.9135613395, 1.2910659264],
      direction: [-59.0825383698, -23.606092718, 2.8847652228],
      "speed factor": 1.1255984861,
      "Uz factor": 0.9734250212,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3248941739,
      "Direction stability factor": 0.8278686011,
      score: 0.0,
    },
  },
  {
    position: [-42.79727393, 88.02484617, 46.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0553539613, 0.3357655962, 0.8332757653],
      direction: [-173.457140988, 92.5043134214, 169.309883817],
      "speed factor": 0.344076147,
      "Uz factor": 0.9280948513,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4594759758,
      "Direction stability factor": 0.0478693755,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.2342689857, 0.7541815196, 1.3768468409],
      direction: [-52.2130033067, 12.791548336, 95.933862982],
      "speed factor": 0.6696194079,
      "Uz factor": 0.9968805731,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2611252367,
      "Direction stability factor": 0.588480927,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.165645034, 0.5011699765, 0.9994698607],
      direction: [-37.5555868592, 5.8354589071, 60.7641695136],
      "speed factor": 0.7763457042,
      "Uz factor": 0.9956817863,
      "Directional variance factor": 0.4812925416,
      "Velocity stability factor": 0.2549320517,
      "Direction stability factor": 0.7268895656,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3106341991, 0.7327578225, 1.1239107247],
      direction: [-21.9567324346, 22.9605166336, 75.8909402572],
      "speed factor": 0.8243013593,
      "Uz factor": 0.8751208115,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3267542158,
      "Direction stability factor": 0.7282009092,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.445339735, 0.806127876, 1.2844563188],
      direction: [-31.8622669362, -5.1189633035, 23.7318894138],
      "speed factor": 1.1452180633,
      "Uz factor": 0.8959343925,
      "Directional variance factor": 0.5449810397,
      "Velocity stability factor": 0.3137776367,
      "Direction stability factor": 0.8455717879,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3238248869, 0.699630126, 0.9696219973],
      direction: [-80.5869098289, -37.2138035905, 6.9454662645],
      "speed factor": 0.8197254351,
      "Uz factor": 0.8967738763,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5034452493,
      "Direction stability factor": 0.7568545109,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1822386223, 0.3920104847, 0.6199136051],
      direction: [-142.9483723233, -16.9958353268, 28.7502193451],
      "speed factor": 0.7272266888,
      "Uz factor": 0.9999998555,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5753030215,
      "Direction stability factor": 0.5230594676,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0260940666, 0.312376122, 0.7663690591],
      direction: [-163.0123353869, -36.646414875, 168.7921439333],
      "speed factor": 0.4253783955,
      "Uz factor": 0.9997834084,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.457126949,
      "Direction stability factor": 0.0783208908,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0358161797, 0.1768056107, 0.3539544473],
      direction: [-179.72754182, 165.1926647985, 179.2224212462],
      "speed factor": 0.2245321522,
      "Uz factor": 0.9914849984,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7437604723,
      "Direction stability factor": 0.0029167693,
      score: 0.0,
    },
    id: "IDN:451.0",
    "45": {
      velocity: [0.0661378245, 0.4503994012, 0.8171901676],
      direction: [-84.8663026978, 6.8010023373, 92.8430394721],
      "speed factor": 0.6118142324,
      "Uz factor": 0.9999598081,
      "Directional variance factor": 0.3954664589,
      "Velocity stability factor": 0.3894182107,
      "Direction stability factor": 0.5063629384,
      score: 0.0,
    },
    "90": {
      velocity: [0.3462561318, 0.7780480247, 1.1845473573],
      direction: [-69.112570947, 30.3078594536, 150.7585390378],
      "speed factor": 1.2612386162,
      "Uz factor": 0.9077156017,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3881668354,
      "Direction stability factor": 0.3892469167,
      score: 0.0,
    },
    "135": {
      velocity: [0.4751491306, 0.9985662317, 1.2558894747],
      direction: [-46.7885653742, -2.8398566775, 13.9445046629],
      "speed factor": 0.8451368546,
      "Uz factor": 0.8819934668,
      "Directional variance factor": 0.7475682953,
      "Velocity stability factor": 0.3708709913,
      "Direction stability factor": 0.8312970277,
      score: 0.0,
    },
    "180": {
      velocity: [0.5617555388, 0.877459665, 1.2231212081],
      direction: [-44.12330116, -23.404839838, -4.205450421],
      "speed factor": 1.7136733051,
      "Uz factor": 0.8873154179,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4788723703,
      "Direction stability factor": 0.8891170813,
      score: 0.0,
    },
    "225": {
      velocity: [0.2414150695, 0.7268235298, 1.0426453072],
      direction: [-50.7879768883, -2.6686635311, 15.9067018208],
      "speed factor": 0.9921936585,
      "Uz factor": 0.9999976753,
      "Directional variance factor": 0.7627854639,
      "Velocity stability factor": 0.2798697983,
      "Direction stability factor": 0.8147370036,
      score: 0.0,
    },
    "270": {
      velocity: [0.0543227476, 0.2622748732, 0.7003640344],
      direction: [-165.3132426247, -99.3572245823, 8.8250285407],
      "speed factor": 0.2399899777,
      "Uz factor": 0.9735293057,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4904005629,
      "Direction stability factor": 0.5162825801,
      score: 0.0,
    },
    "315": {
      velocity: [0.021492933, 0.5020472418, 1.0412246102],
      direction: [-168.1818190956, 4.2393614611, 137.8212180065],
      "speed factor": 0.6185721647,
      "Uz factor": 0.9995897836,
      "Directional variance factor": 0.6231678701,
      "Velocity stability factor": 0.172756985,
      "Direction stability factor": 0.1499915636,
      score: 0.0,
    },
  },
  {
    position: [-41.75495903, 88.69744659, 46.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0352143076, 0.3305665449, 0.7662996696],
      direction: [-171.880790583, 94.6899606489, 177.9435923341],
      "speed factor": 0.3387484137,
      "Uz factor": 0.8385450292,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4920193779,
      "Direction stability factor": 0.028265603,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0824547369, 0.8344228183, 1.3894390039],
      direction: [-64.9319877225, 7.9127133006, 93.1319006872],
      "speed factor": 0.7408637031,
      "Uz factor": 0.9987880416,
      "Directional variance factor": 0.2966477066,
      "Velocity stability factor": 0.1548079752,
      "Direction stability factor": 0.5609336433,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1560019063, 0.5196453273, 1.0153440864],
      direction: [-37.7176905327, 2.7199004437, 59.4449358386],
      "speed factor": 0.8049652542,
      "Uz factor": 0.9968232469,
      "Directional variance factor": 0.7582310717,
      "Velocity stability factor": 0.232130905,
      "Direction stability factor": 0.7301038156,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2422775008, 0.7181101591, 1.1318686645],
      direction: [-49.296860289, 26.6901894646, 106.0859461802],
      "speed factor": 0.8078237612,
      "Uz factor": 0.8625669699,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2635795061,
      "Direction stability factor": 0.5683810931,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4369474308, 0.8077487165, 1.3130101546],
      direction: [-27.8363794722, -1.1631022368, 32.9879107713],
      "speed factor": 1.1475206953,
      "Uz factor": 0.8948599557,
      "Directional variance factor": 0.8966131345,
      "Velocity stability factor": 0.2835633995,
      "Direction stability factor": 0.8310436382,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3800296981, 0.6828159461, 0.9848001655],
      direction: [-63.7115565771, -34.9858291815, 8.1061725815],
      "speed factor": 0.8000250099,
      "Uz factor": 0.8630538708,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5349907209,
      "Direction stability factor": 0.8005063079,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1257145217, 0.3957586354, 0.628567156],
      direction: [-174.0232660116, -18.8419372085, 23.5489052364],
      "speed factor": 0.7341799602,
      "Uz factor": 0.9999858933,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5120580274,
      "Direction stability factor": 0.4511884132,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0299953478, 0.3596952683, 0.7629917006],
      direction: [-179.8668899687, -29.5767178532, 177.2619450557],
      "speed factor": 0.4898152751,
      "Uz factor": 0.9980576342,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4624646646,
      "Direction stability factor": 0.0079754583,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0150113181, 0.173197432, 0.3581037368],
      direction: [-179.8307534162, 172.3303254907, 178.0577742045],
      "speed factor": 0.2199499893,
      "Uz factor": 0.9908501436,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7236615388,
      "Direction stability factor": 0.0058652011,
      score: 0.0,
    },
    id: "IDN:452.0",
    "45": {
      velocity: [0.1132987364, 0.4580143435, 0.7868094811],
      direction: [-61.8457053073, 3.733074958, 91.2269595794],
      "speed factor": 0.6221582294,
      "Uz factor": 0.999973382,
      "Directional variance factor": 0.6681711148,
      "Velocity stability factor": 0.4524570765,
      "Direction stability factor": 0.5747981531,
      score: 0.0,
    },
    "90": {
      velocity: [0.2017726747, 0.7687620118, 1.1600788909],
      direction: [-1.3461283663, 32.3744956278, 144.3129253505],
      "speed factor": 1.2461857176,
      "Uz factor": 0.9099512234,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3005729905,
      "Direction stability factor": 0.5953915175,
      score: 0.0,
    },
    "135": {
      velocity: [0.4960213244, 0.998063462, 1.2416585713],
      direction: [-35.9205734917, 0.343467004, 22.5107276636],
      "speed factor": 0.8447113353,
      "Uz factor": 0.8852470862,
      "Directional variance factor": 0.9694695996,
      "Velocity stability factor": 0.3991574465,
      "Direction stability factor": 0.8376908301,
      score: 0.0,
    },
    "180": {
      velocity: [0.5558883974, 0.8802671609, 1.2145045757],
      direction: [-36.3982034727, -18.9719419976, 0.2595814476],
      "speed factor": 1.7191563273,
      "Uz factor": 0.8760376055,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4810388506,
      "Direction stability factor": 0.8981728197,
      score: 0.0,
    },
    "225": {
      velocity: [0.253963305, 0.7192341835, 1.0530998811],
      direction: [-51.6725394871, -3.3909034183, 15.8967099404],
      "speed factor": 0.9818333702,
      "Uz factor": 0.9999726486,
      "Directional variance factor": 0.6985863628,
      "Velocity stability factor": 0.2817515408,
      "Direction stability factor": 0.8123076405,
      score: 0.0,
    },
    "270": {
      velocity: [0.0776286452, 0.2854725644, 0.6408509968],
      direction: [-151.1264192037, -95.5198138258, -19.5077705901],
      "speed factor": 0.2612166142,
      "Uz factor": 0.9820212255,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5557284043,
      "Direction stability factor": 0.6343926427,
      score: 0.0,
    },
    "315": {
      velocity: [0.0201085946, 0.3957641954, 0.9665800001],
      direction: [-152.6450003309, 4.5530143233, 166.0820518836],
      "speed factor": 0.4876208744,
      "Uz factor": 0.9999754742,
      "Directional variance factor": 0.5952876157,
      "Velocity stability factor": 0.2321883525,
      "Direction stability factor": 0.1146470772,
      score: 0.0,
    },
  },
  {
    position: [-40.58147966, 89.23484332, 46.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0488985188, 0.337264683, 0.9818380851],
      direction: [-179.313758648, 56.4898512218, 177.9855391184],
      "speed factor": 0.3456123377,
      "Uz factor": 0.5865156572,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.351764861,
      "Direction stability factor": 0.0075019506,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1624291074, 0.9753094677, 1.4400998067],
      direction: [-79.3509520145, 4.9899485967, 65.6911556556],
      "speed factor": 0.8659535288,
      "Uz factor": 0.9991537607,
      "Directional variance factor": 0.5564490136,
      "Velocity stability factor": 0.1737642812,
      "Direction stability factor": 0.5971052565,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1689858179, 0.5442634666, 1.0245753681],
      direction: [-39.1968699911, -0.5232569739, 57.3440435342],
      "speed factor": 0.8431003931,
      "Uz factor": 0.9969363462,
      "Directional variance factor": 0.953488269,
      "Velocity stability factor": 0.2354840845,
      "Direction stability factor": 0.7318307958,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2524471259, 0.7056274035, 1.134627606],
      direction: [-45.7433378348, 29.8179525025, 88.9792886542],
      "speed factor": 0.7937815332,
      "Uz factor": 0.8660722246,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2697142109,
      "Direction stability factor": 0.625770482,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.4325106881, 0.8112088975, 1.3124716815],
      direction: [-26.4447001349, 3.3204822428, 38.115595946],
      "speed factor": 1.1524363693,
      "Uz factor": 0.8968263409,
      "Directional variance factor": 0.7048460229,
      "Velocity stability factor": 0.2803754279,
      "Direction stability factor": 0.8206658442,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3881018084, 0.6784423957, 1.0137580902],
      direction: [-58.3546593719, -32.9812430256, 7.6334238793],
      "speed factor": 0.7949007159,
      "Uz factor": 0.8327231524,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5189315745,
      "Direction stability factor": 0.8166997687,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.182929813, 0.3988084815, 0.6296641277],
      direction: [-89.0299227395, -19.6633229722, 77.4312881988],
      "speed factor": 0.7398377922,
      "Uz factor": 0.9998157859,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5665123183,
      "Direction stability factor": 0.5376077474,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.052942616, 0.3994403709, 0.8902656159],
      direction: [-174.2607347296, -22.6224410967, 179.5478618658],
      "speed factor": 0.5439381954,
      "Uz factor": 0.9951873205,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3859577911,
      "Direction stability factor": 0.0171983428,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0440020753, 0.1733433997, 0.351012321],
      direction: [-179.8027610675, -179.2549022157, 177.980984711],
      "speed factor": 0.2201353591,
      "Uz factor": 0.9939783263,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7527233647,
      "Direction stability factor": 0.0061562617,
      score: 0.0,
    },
    id: "IDN:453.0",
    "45": {
      velocity: [0.0775880423, 0.4689128704, 0.7608490102],
      direction: [-59.8601446207, 0.9424502419, 91.6303802632],
      "speed factor": 0.6369625872,
      "Uz factor": 0.9999671551,
      "Directional variance factor": 0.9162266452,
      "Velocity stability factor": 0.4445304536,
      "Direction stability factor": 0.5791929864,
      score: 0.0,
    },
    "90": {
      velocity: [0.2563566241, 0.7645649507, 1.1672140772],
      direction: [0.296282228, 33.8229023933, 129.8689651295],
      "speed factor": 1.2393821587,
      "Uz factor": 0.9171746955,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3352038276,
      "Direction stability factor": 0.6400758808,
      score: 0.0,
    },
    "135": {
      velocity: [0.54057275, 0.9999904512, 1.2373814227],
      direction: [-33.3503598059, 3.6335303001, 30.2428273101],
      "speed factor": 0.8463422432,
      "Uz factor": 0.8950019263,
      "Directional variance factor": 0.6770195289,
      "Velocity stability factor": 0.4385040394,
      "Direction stability factor": 0.823352258,
      score: 0.0,
    },
    "180": {
      velocity: [0.5276836536, 0.8816540614, 1.2031225172],
      direction: [-30.9978638532, -13.8936942632, 6.3674753478],
      "speed factor": 1.7218649354,
      "Uz factor": 0.874111388,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4677833,
      "Direction stability factor": 0.8962073911,
      score: 0.0,
    },
    "225": {
      velocity: [0.2978105359, 0.7044850877, 1.0665576013],
      direction: [-49.4150378685, -4.2029707505, 15.8628960985],
      "speed factor": 0.9616992404,
      "Uz factor": 0.9998492812,
      "Directional variance factor": 0.6264026,
      "Velocity stability factor": 0.3090650437,
      "Direction stability factor": 0.8186724056,
      score: 0.0,
    },
    "270": {
      velocity: [0.1171121343, 0.3038509377, 0.5577561379],
      direction: [-138.9182496752, -89.9156518664, -26.4856730031],
      "speed factor": 0.2780334192,
      "Uz factor": 0.9811821745,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.652418598,
      "Direction stability factor": 0.687687287,
      score: 0.0,
    },
    "315": {
      velocity: [0.0089329971, 0.3053146237, 0.9285684201],
      direction: [-177.2717380402, 3.1587433723, 112.9293242303],
      "speed factor": 0.3761780008,
      "Uz factor": 0.9985734352,
      "Directional variance factor": 0.7192228114,
      "Velocity stability factor": 0.2539586669,
      "Direction stability factor": 0.1938859381,
      score: 0.0,
    },
  },
  {
    position: [12.51734566, 102.5890542, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.4627081184, 1.0473775732, 1.5400386597],
      direction: [-56.1419053237, -31.2527058388, -5.894779598],
      "speed factor": 1.073301267,
      "Uz factor": 0.928156218,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2514375653,
      "Direction stability factor": 0.8604246508,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7039107036, 1.0329826083, 1.4162659494],
      direction: [-71.5319738915, -29.6059094284, -1.9703363875],
      "speed factor": 0.9171601061,
      "Uz factor": 0.9641151266,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5393387757,
      "Direction stability factor": 0.8067732292,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1712488412, 0.444790584, 0.7673556315],
      direction: [-91.4875600142, -1.0515696889, 112.9744911247],
      "speed factor": 0.6890102666,
      "Uz factor": 0.970683461,
      "Directional variance factor": 0.9065271388,
      "Velocity stability factor": 0.4673460792,
      "Direction stability factor": 0.4320498579,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.5029139836, 1.0950308434, 1.346492828],
      direction: [-11.1874912196, 13.6100634822, 32.8917452062],
      "speed factor": 1.2318331991,
      "Uz factor": 0.9565346452,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3016693796,
      "Direction stability factor": 0.8775576766,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0793823169, 0.8070997365, 1.2307763573],
      direction: [-178.4303364052, 11.4032203383, 145.8879121169],
      "speed factor": 1.1465987279,
      "Uz factor": 0.9986801705,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0583998043,
      "Direction stability factor": 0.0991159763,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0811681116, 0.3255238784, 0.7956282602],
      direction: [-175.3532804132, -82.4593392111, 177.6199747389],
      "speed factor": 0.3814018193,
      "Uz factor": 0.8295065256,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4506500954,
      "Direction stability factor": 0.0195187357,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1449287934, 0.3875503953, 0.9381997879],
      direction: [-62.4543261192, -2.7699715068, 93.369157491],
      "speed factor": 0.7189526856,
      "Uz factor": 0.9959760624,
      "Directional variance factor": 0.7537803105,
      "Velocity stability factor": 0.230251196,
      "Direction stability factor": 0.56715699,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0657431363, 0.3388623178, 0.7580766556],
      direction: [-174.2666686608, 17.8067488575, 179.9142798944],
      "speed factor": 0.4614459907,
      "Uz factor": 0.857912196,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4922843353,
      "Direction stability factor": 0.0161640318,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2052358484, 0.6927066281, 1.1991159927],
      direction: [-89.2842010844, -5.3075243208, 29.4829018507],
      "speed factor": 0.8796944253,
      "Uz factor": 0.9951485819,
      "Directional variance factor": 0.5282200604,
      "Velocity stability factor": 0.1994946705,
      "Direction stability factor": 0.6700913807,
      score: 0.0,
    },
    id: "IDN:326.0",
    "45": {
      velocity: [0.2509729977, 0.5983568827, 0.8940066583],
      direction: [-71.5960644257, -9.7709010774, 38.8518402593],
      "speed factor": 0.8127969439,
      "Uz factor": 0.9814632088,
      "Directional variance factor": 0.1314754598,
      "Velocity stability factor": 0.4772339816,
      "Direction stability factor": 0.6932002648,
      score: 0.0,
    },
    "90": {
      velocity: [0.3532186449, 0.8264692747, 1.1194949608],
      direction: [-15.8773328058, 19.6029925054, 41.2371434923],
      "speed factor": 1.3397308793,
      "Uz factor": 0.959133785,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4407274595,
      "Direction stability factor": 0.8413486769,
      score: 0.0,
    },
    "135": {
      velocity: [0.8595844669, 1.2889654219, 1.4376243286],
      direction: [2.3986237185, 13.2246194494, 26.4094314914],
      "speed factor": 1.0909163035,
      "Uz factor": 0.9762698259,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.534209231,
      "Direction stability factor": 0.9333033117,
      score: 0.0,
    },
    "180": {
      velocity: [0.0650368465, 0.3311646713, 0.7449510771],
      direction: [-174.5050570092, 13.1331957942, 159.3180996011],
      "speed factor": 0.6467625572,
      "Uz factor": 0.9774979954,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4642569039,
      "Direction stability factor": 0.0727134539,
      score: 0.0,
    },
    "225": {
      velocity: [0.041677403, 0.2845803238, 0.7162490305],
      direction: [-175.3984197661, -6.2881578806, 177.5219609885],
      "speed factor": 0.3884832852,
      "Uz factor": 0.9982131055,
      "Directional variance factor": 0.4410526328,
      "Velocity stability factor": 0.3937081012,
      "Direction stability factor": 0.019665609,
      score: 0.0,
    },
    "270": {
      velocity: [0.2921740015, 0.8648605651, 1.3073327243],
      direction: [-78.2571209421, 3.3672841469, 27.8646860115],
      "speed factor": 0.7913753431,
      "Uz factor": 0.992999987,
      "Directional variance factor": 0.7006858536,
      "Velocity stability factor": 0.1992395466,
      "Direction stability factor": 0.7052172029,
      score: 0.0,
    },
    "315": {
      velocity: [0.3614636228, 0.8485062528, 1.1651172886],
      direction: [-64.7512930999, -23.4261661815, 2.7024057569],
      "speed factor": 1.0454441452,
      "Uz factor": 0.9763821086,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3480472399,
      "Direction stability factor": 0.8126286143,
      score: 0.0,
    },
  },
  {
    position: [3.735970111, 93.61965223, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.064984847, 0.2743954421, 0.6401430433],
      direction: [-179.3406299175, -157.4494263488, 179.8463471876],
      "speed factor": 0.2811870172,
      "Uz factor": 0.9937070203,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6003623742,
      "Direction stability factor": 0.0022583969,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0531811584, 0.2565784355, 0.5457120037],
      direction: [-179.1853390074, 138.608362406, 179.7434451565],
      "speed factor": 0.2278097455,
      "Uz factor": 0.9865433427,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6814933791,
      "Direction stability factor": 0.0029755995,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0542704105, 0.3051848252, 0.7073834615],
      direction: [-35.6415011023, 33.8285186417, 122.8271342598],
      "speed factor": 0.4727516393,
      "Uz factor": 0.99552955,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4164078769,
      "Direction stability factor": 0.5598093462,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.4609605878, 0.910390788, 1.2574623706],
      direction: [-9.1761248066, 17.2747530235, 50.0113989602],
      "speed factor": 1.0241260359,
      "Uz factor": 0.9296760479,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3406406671,
      "Direction stability factor": 0.8355902118,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5628574212, 0.9061629906, 1.2735007846],
      direction: [-31.7541368089, 3.9168919791, 60.3862054088],
      "speed factor": 1.2873320177,
      "Uz factor": 0.8675122936,
      "Directional variance factor": 0.6518318241,
      "Velocity stability factor": 0.4188419372,
      "Direction stability factor": 0.744054605,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1311628986, 0.8030403577, 1.1980652054],
      direction: [-84.1448665063, -17.2681697713, 50.1134115263],
      "speed factor": 0.9408865945,
      "Uz factor": 0.829474027,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1796565817,
      "Direction stability factor": 0.6270603388,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1030124235, 0.4978760939, 0.8957922291],
      direction: [-77.5797976369, -9.0145195283, 51.1476145449],
      "speed factor": 0.9236201515,
      "Uz factor": 0.9643171369,
      "Directional variance factor": 0.1987093753,
      "Velocity stability factor": 0.2307278201,
      "Direction stability factor": 0.6424238551,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0378051308, 0.2591540062, 0.571077325],
      direction: [-177.8517521469, -49.5244325777, 118.794943101],
      "speed factor": 0.3529031434,
      "Uz factor": 0.8788895287,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6089303219,
      "Direction stability factor": 0.1759814021,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.045771141, 0.3162450999, 0.9070017527],
      direction: [-179.5994804657, -9.0076163814, 167.6315771428],
      "speed factor": 0.4016116494,
      "Uz factor": 0.9933274106,
      "Directional variance factor": 0.1993229883,
      "Velocity stability factor": 0.3063351768,
      "Direction stability factor": 0.0354692844,
      score: 0.0,
    },
    id: "IDN:320.0",
    "45": {
      velocity: [0.093875036, 0.4148088674, 0.9622659351],
      direction: [-56.5963137403, 11.9160030228, 151.7726339616],
      "speed factor": 0.5634687082,
      "Uz factor": 0.9968352933,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2940256777,
      "Direction stability factor": 0.4211973675,
      score: 0.0,
    },
    "90": {
      velocity: [0.021929801, 0.7670220631, 1.2348013977],
      direction: [-116.6158013859, 27.8935395234, 76.5176927598],
      "speed factor": 1.2433652097,
      "Uz factor": 0.9707494722,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1147765302,
      "Direction stability factor": 0.4635180718,
      score: 0.0,
    },
    "135": {
      velocity: [0.6673251635, 1.2236135169, 1.4687514101],
      direction: [0.1672045091, 8.4504211701, 49.7539840649],
      "speed factor": 1.0356056974,
      "Uz factor": 0.8772958827,
      "Directional variance factor": 0.2488514515,
      "Velocity stability factor": 0.3542020674,
      "Direction stability factor": 0.8622589457,
      score: 0.0,
    },
    "180": {
      velocity: [0.3655785117, 0.7587601524, 1.1323562633],
      direction: [-48.0870833593, 3.8598947002, 43.95685468],
      "speed factor": 1.4818538903,
      "Uz factor": 0.8370925096,
      "Directional variance factor": 0.6568982489,
      "Velocity stability factor": 0.3958121948,
      "Direction stability factor": 0.7443223943,
      score: 0.0,
    },
    "225": {
      velocity: [0.1089900952, 0.4759611996, 0.8066087834],
      direction: [-73.879640789, -17.0161863896, 41.050784389],
      "speed factor": 0.6497391245,
      "Uz factor": 0.9125479226,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.37299385,
      "Direction stability factor": 0.6807488189,
      score: 0.0,
    },
    "270": {
      velocity: [0.0354496788, 0.4934939491, 1.1262892821],
      direction: [-170.2895234333, -8.5377088904, 163.6127344077],
      "speed factor": 0.4515628981,
      "Uz factor": 0.9932426754,
      "Directional variance factor": 0.2410925431,
      "Velocity stability factor": 0.1395422256,
      "Direction stability factor": 0.0724937282,
      score: 0.0,
    },
    "315": {
      velocity: [0.0272894858, 0.2359557959, 0.4824754142],
      direction: [-169.6232477661, -106.6508053772, 178.2195212069],
      "speed factor": 0.2907210224,
      "Uz factor": 0.9359357764,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6307368025,
      "Direction stability factor": 0.0337700862,
      score: 0.0,
    },
  },
  {
    position: [9.532765476, 102.6514601, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.496466406, 1.0816959633, 1.5383104135],
      direction: [-48.1664091831, -21.2358620773, 3.8760832928],
      "speed factor": 1.1084690733,
      "Uz factor": 0.83779492,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2760947018,
      "Direction stability factor": 0.8554375209,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7017526225, 1.0128159534, 1.3930120479],
      direction: [-62.0495950839, -23.411227343, 4.4506313906],
      "speed factor": 0.8992546242,
      "Uz factor": 0.8595312434,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5529808827,
      "Direction stability factor": 0.8152771487,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0828047837, 0.432975585, 0.8499080644],
      direction: [-150.7576119408, -6.282942407, 146.8045552561],
      "speed factor": 0.6707080456,
      "Uz factor": 0.9721796785,
      "Directional variance factor": 0.4415162305,
      "Velocity stability factor": 0.3145513911,
      "Direction stability factor": 0.1734384245,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0743368404, 0.4497900351, 0.9681279005],
      direction: [-48.1438048926, 27.1508008062, 70.6608108623],
      "speed factor": 0.5059823667,
      "Uz factor": 0.9862287399,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.260102752,
      "Direction stability factor": 0.6699871785,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0777688776, 0.406329565, 1.0879945176],
      direction: [-175.86461553, 17.397253491, 157.3864147469],
      "speed factor": 0.5772483142,
      "Uz factor": 0.9981276187,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1738461145,
      "Direction stability factor": 0.0743026937,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0354497721, 0.3029016336, 0.6664468308],
      direction: [-177.595196106, -114.0594357463, 179.4661312816],
      "speed factor": 0.3548963434,
      "Uz factor": 0.9303608928,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.51482504,
      "Direction stability factor": 0.0081629795,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1121395321, 0.3800484509, 0.7832660665],
      direction: [-51.509594982, -3.34251532, 91.6382893954],
      "speed factor": 0.7050356747,
      "Uz factor": 0.9992220699,
      "Directional variance factor": 0.7028875271,
      "Velocity stability factor": 0.3487738102,
      "Direction stability factor": 0.6023669878,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0530437035, 0.3866222318, 0.8665994767],
      direction: [-163.9030700895, 19.8957946674, 173.6316308809],
      "speed factor": 0.5264830858,
      "Uz factor": 0.8607548543,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4033872424,
      "Direction stability factor": 0.0624036084,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2096163969, 0.5949228917, 1.0549504144],
      direction: [-94.9078617283, 4.0882679597, 54.2031751151],
      "speed factor": 0.755515149,
      "Uz factor": 0.9420748224,
      "Directional variance factor": 0.6365984036,
      "Velocity stability factor": 0.3191388417,
      "Direction stability factor": 0.5858026754,
      score: 0.0,
    },
    id: "IDN:309.0",
    "45": {
      velocity: [0.2335834346, 0.5951552031, 0.949132244],
      direction: [-73.0701836087, -10.7264551334, 41.5470591017],
      "speed factor": 0.8084478415,
      "Uz factor": 0.9622991581,
      "Directional variance factor": 0.0465373215,
      "Velocity stability factor": 0.4182814601,
      "Direction stability factor": 0.6816187702,
      score: 0.0,
    },
    "90": {
      velocity: [0.18187217, 0.427435993, 0.9259365455],
      direction: [-17.5801770304, 28.9020651113, 81.0787062716],
      "speed factor": 0.6928862527,
      "Uz factor": 0.9852402354,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4569390114,
      "Direction stability factor": 0.7259475464,
      score: 0.0,
    },
    "135": {
      velocity: [0.1327008399, 0.6236334539, 1.2923247603],
      direction: [0.228932984, 13.2372414666, 33.9319646434],
      "speed factor": 0.5278123762,
      "Uz factor": 0.9974901685,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0655625099,
      "Direction stability factor": 0.9063804676,
      score: 0.0,
    },
    "180": {
      velocity: [0.0470269956, 0.2633213216, 0.7632904922],
      direction: [-179.921852856, 92.2182842294, 177.9348851332],
      "speed factor": 0.5142649144,
      "Uz factor": 0.9969738369,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4356152496,
      "Direction stability factor": 0.0059535056,
      score: 0.0,
    },
    "225": {
      velocity: [0.0395883746, 0.2812947143, 0.5831246808],
      direction: [-169.309199258, -14.2118133254, 177.1149365558],
      "speed factor": 0.3839980686,
      "Uz factor": 0.9977826092,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5114801072,
      "Direction stability factor": 0.0377107339,
      score: 0.0,
    },
    "270": {
      velocity: [0.1220216823, 0.8561665678, 1.2562932377],
      direction: [-117.8500925394, 5.8159880646, 22.1516847974],
      "speed factor": 0.7834200548,
      "Uz factor": 0.9937914577,
      "Directional variance factor": 0.4830232831,
      "Velocity stability factor": 0.1052829626,
      "Direction stability factor": 0.6111061741,
      score: 0.0,
    },
    "315": {
      velocity: [0.5696122809, 0.908185857, 1.2633436603],
      direction: [-36.0168326831, -19.4886033814, 7.0535470899],
      "speed factor": 1.1189753567,
      "Uz factor": 0.9423602931,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4372201525,
      "Direction stability factor": 0.8803600562,
      score: 0.0,
    },
  },
  {
    position: [0.735988388, 93.63012419, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0517108247, 0.2686819154, 0.5945983647],
      direction: [-179.4131380665, -163.4365475442, 179.8877531451],
      "speed factor": 0.2753320747,
      "Uz factor": 0.9828751841,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6227850199,
      "Direction stability factor": 0.0019419689,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0784571802, 0.265029383, 0.5126906749],
      direction: [-178.2200254589, 134.5134080192, 177.6214253464],
      "speed factor": 0.235313136,
      "Uz factor": 0.9768675525,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.719192728,
      "Direction stability factor": 0.0115515255,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0746972173, 0.3209968693, 0.7323716875],
      direction: [-91.5582546594, 22.561500986, 124.3842476998],
      "speed factor": 0.4972455499,
      "Uz factor": 0.9988058836,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4123320001,
      "Direction stability factor": 0.4001597157,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3885400023, 0.8840257357, 1.2512855444],
      direction: [-10.6159003247, 16.554740397, 53.0691357938],
      "speed factor": 0.9944671939,
      "Uz factor": 0.9262600935,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2858028225,
      "Direction stability factor": 0.8230971219,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.546143602, 0.9088910223, 1.3212113414],
      direction: [-35.174804488, -1.7404997406, 47.7018741677],
      "speed factor": 1.2912075706,
      "Uz factor": 0.8704107822,
      "Directional variance factor": 0.8452889119,
      "Velocity stability factor": 0.3661562336,
      "Direction stability factor": 0.7697870037,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1582376219, 0.7843757349, 1.1781946297],
      direction: [-90.0395455378, -21.7400451634, 45.1277537672],
      "speed factor": 0.9190180878,
      "Uz factor": 0.8186223286,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2157529205,
      "Direction stability factor": 0.6245352797,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1173998009, 0.5071242156, 0.8283121733],
      direction: [-86.2580476937, -10.6084767607, 97.9149999841],
      "speed factor": 0.9407765316,
      "Uz factor": 0.9625728217,
      "Directional variance factor": 0.0570242879,
      "Velocity stability factor": 0.3101677079,
      "Direction stability factor": 0.4884082009,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0166194385, 0.2342041411, 0.6751797127],
      direction: [-159.1720828211, -40.9782868314, 150.0560856451],
      "speed factor": 0.3189276476,
      "Uz factor": 0.8570205532,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5170515971,
      "Direction stability factor": 0.1410328654,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0234344878, 0.288635978, 0.7219879409],
      direction: [-174.1634655906, -15.9426979509, 174.2740633938],
      "speed factor": 0.3665497781,
      "Uz factor": 0.9948633431,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4373609681,
      "Direction stability factor": 0.032117975,
      score: 0.0,
    },
    id: "IDN:319.0",
    "45": {
      velocity: [0.0818554305, 0.4101009339, 0.9362463915],
      direction: [-75.0257987552, 14.9877684937, 139.6521538196],
      "speed factor": 0.5570735383,
      "Uz factor": 0.9974428681,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3054071843,
      "Direction stability factor": 0.403672354,
      score: 0.0,
    },
    "90": {
      velocity: [0.3259319404, 0.7500039987, 1.1900156946],
      direction: [-4.5003891205, 30.868065729, 65.1108550731],
      "speed factor": 1.2157784294,
      "Uz factor": 0.960051539,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.369341964,
      "Direction stability factor": 0.8066354328,
      score: 0.0,
    },
    "135": {
      velocity: [0.8101046933, 1.2223023774, 1.475455881],
      direction: [-3.0200269967, 5.8418736404, 40.0425736737],
      "speed factor": 1.0344960141,
      "Uz factor": 0.8823435872,
      "Directional variance factor": 0.4807223431,
      "Velocity stability factor": 0.4638528208,
      "Direction stability factor": 0.8803816648,
      score: 0.0,
    },
    "180": {
      velocity: [0.3806412108, 0.7699256011, 1.1583913979],
      direction: [-51.4251136371, 0.7708821866, 34.945156932],
      "speed factor": 1.5036599426,
      "Uz factor": 0.837723586,
      "Directional variance factor": 0.931477139,
      "Velocity stability factor": 0.3871663887,
      "Direction stability factor": 0.7600825818,
      score: 0.0,
    },
    "225": {
      velocity: [0.094758133, 0.4796174509, 0.8332955276],
      direction: [-111.0428536771, -19.2874170254, 31.8896588724],
      "speed factor": 0.6547303076,
      "Uz factor": 0.9169964442,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.33621691,
      "Direction stability factor": 0.6029652429,
      score: 0.0,
    },
    "270": {
      velocity: [0.0547120524, 0.5067646259, 1.0643425527],
      direction: [-68.6763506852, 7.4958176406, 171.767291201],
      "speed factor": 0.4637059959,
      "Uz factor": 0.9987586273,
      "Directional variance factor": 0.3337050986,
      "Velocity stability factor": 0.2036002262,
      "Direction stability factor": 0.3321009948,
      score: 0.0,
    },
    "315": {
      velocity: [0.0650046268, 0.2295181646, 0.4792517666],
      direction: [-178.7186744445, -116.9431691503, 65.2153193189],
      "speed factor": 0.28278922,
      "Uz factor": 0.8933020856,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6639478203,
      "Direction stability factor": 0.3224055729,
      score: 0.0,
    },
  },
  {
    position: [-2.263993335, 93.64059614, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0750778656, 0.2806271993, 0.7455314433],
      direction: [-178.8890476983, 149.6761618311, 179.4946895048],
      "speed factor": 0.2875730169,
      "Uz factor": 0.9391289965,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5341482087,
      "Direction stability factor": 0.0044896189,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0683438577, 0.265590706, 0.5519333894],
      direction: [-178.9259328125, 127.8152728459, 179.3967805194],
      "speed factor": 0.235811521,
      "Uz factor": 0.9902281653,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6872754893,
      "Direction stability factor": 0.0046591296,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0358872511, 0.3492580794, 0.8919270305],
      direction: [-109.1729781752, 12.3030589687, 91.8362282188],
      "speed factor": 0.5410240483,
      "Uz factor": 0.9955773673,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2350817802,
      "Direction stability factor": 0.4416410933,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.3193596098, 0.8500801844, 1.2413975589],
      direction: [-15.4476384309, 13.4463107577, 48.4567576284],
      "speed factor": 0.9562808201,
      "Uz factor": 0.908499775,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2367194397,
      "Direction stability factor": 0.8224877887,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.5456630084, 0.8796247269, 1.2856518535],
      direction: [-36.209466346, -7.7265681117, 52.2015821939],
      "speed factor": 1.2496306804,
      "Uz factor": 0.8761590948,
      "Directional variance factor": 0.3131939456,
      "Velocity stability factor": 0.3948434534,
      "Direction stability factor": 0.7544137541,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3494340644, 0.7856407807, 1.2465398349],
      direction: [-96.8686060722, -27.2088618816, 42.5040986973],
      "speed factor": 0.9205002856,
      "Uz factor": 0.8482796193,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3102134942,
      "Direction stability factor": 0.6128535979,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1888700764, 0.50859567, 0.8443153022],
      direction: [-101.3999906089, -8.6990929749, 39.7345792612],
      "speed factor": 0.9435062568,
      "Uz factor": 0.9714010361,
      "Directional variance factor": 0.2267472911,
      "Velocity stability factor": 0.3639901343,
      "Direction stability factor": 0.6079595281,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0474239671, 0.2472117977, 0.6319871262],
      direction: [-155.2220257156, -10.5799884676, 177.7640477068],
      "speed factor": 0.3366408328,
      "Uz factor": 0.8926440913,
      "Directional variance factor": 0.0595565807,
      "Velocity stability factor": 0.5713166203,
      "Direction stability factor": 0.0750386849,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0564485175, 0.307915859, 0.7481294174],
      direction: [-177.3658733689, 7.4254738753, 179.0994592075],
      "speed factor": 0.3910340304,
      "Uz factor": 0.9283431857,
      "Directional variance factor": 0.3399578778,
      "Velocity stability factor": 0.4428963594,
      "Direction stability factor": 0.0098185206,
      score: 0.0,
    },
    id: "IDN:318.0",
    "45": {
      velocity: [0.0506140541, 0.4101665068, 0.893330515],
      direction: [-164.0298787566, 20.2867202963, 157.7035886474],
      "speed factor": 0.5571626113,
      "Uz factor": 0.9991110526,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3148981835,
      "Direction stability factor": 0.1062959239,
      score: 0.0,
    },
    "90": {
      velocity: [0.1778819298, 0.6786540995, 1.1211819671],
      direction: [-12.2462117534, 32.9809125927, 112.3555878926],
      "speed factor": 1.1001181548,
      "Uz factor": 0.9522867058,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3115253631,
      "Direction stability factor": 0.6538838899,
      score: 0.0,
    },
    "135": {
      velocity: [0.8751779764, 1.2116300334, 1.4517103217],
      direction: [-4.0568356726, 3.8674505974, 34.8737464723],
      "speed factor": 1.0254634723,
      "Uz factor": 0.885724086,
      "Directional variance factor": 0.6562266136,
      "Velocity stability factor": 0.5354240042,
      "Direction stability factor": 0.891859494,
      score: 0.0,
    },
    "180": {
      velocity: [0.2891141496, 0.7839403122, 1.1752721379],
      direction: [-51.0442098791, -5.548376667, 53.6302402434],
      "speed factor": 1.5310305868,
      "Uz factor": 0.8555298336,
      "Directional variance factor": 0.5068109629,
      "Velocity stability factor": 0.3017457159,
      "Direction stability factor": 0.7092376385,
      score: 0.0,
    },
    "225": {
      velocity: [0.0820256675, 0.4834283329, 0.8996300077],
      direction: [-115.4555275419, -20.6775994632, 88.1803225911],
      "speed factor": 0.6599325787,
      "Uz factor": 0.91481181,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2651530724,
      "Direction stability factor": 0.4343448607,
      score: 0.0,
    },
    "270": {
      velocity: [0.1313844694, 0.6161493409, 1.0313530545],
      direction: [-64.2227396567, 17.6725495612, 96.2577314437],
      "speed factor": 0.5637965421,
      "Uz factor": 0.9943580162,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2901018963,
      "Direction stability factor": 0.5542209136,
      score: 0.0,
    },
    "315": {
      velocity: [0.0274845686, 0.1859521799, 0.4636247313],
      direction: [-174.2319880591, -76.7954349189, 179.2356942524],
      "speed factor": 0.2291115912,
      "Uz factor": 0.9129523256,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6461874127,
      "Direction stability factor": 0.0181453269,
      score: 0.0,
    },
  },
  {
    position: [-5.263975058, 93.65106809, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.0511247329, 0.443086806, 1.2738335972],
      direction: [-170.2447872152, 31.133036809, 179.24588836],
      "speed factor": 0.4540536694,
      "Uz factor": 0.9904735784,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1504242298,
      "Direction stability factor": 0.0291925678,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.0918910162, 0.3327618673, 1.0389359242],
      direction: [-97.5802301597, 73.7727369214, 175.4994673044],
      "speed factor": 0.2954511597,
      "Uz factor": 0.9649048635,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3875712022,
      "Direction stability factor": 0.2414452848,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0640492594, 0.3811620946, 0.9472312341],
      direction: [-59.3024208068, 9.912637745, 74.991150234],
      "speed factor": 0.5904454947,
      "Uz factor": 0.9901801529,
      "Directional variance factor": 0.1188766449,
      "Velocity stability factor": 0.2108287486,
      "Direction stability factor": 0.6269623027,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.1460163432, 0.8474757956, 1.2669164766],
      direction: [-66.5425645952, 6.4763683098, 37.4095032159],
      "speed factor": 0.9533510647,
      "Uz factor": 0.9364065931,
      "Directional variance factor": 0.4243228169,
      "Velocity stability factor": 0.0720975392,
      "Direction stability factor": 0.7112442561,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.492665996, 0.8381907247, 1.2043182821],
      direction: [-45.0497039122, -12.3052712303, 44.4132602183],
      "speed factor": 1.1907678509,
      "Uz factor": 0.9252866379,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4180168488,
      "Direction stability factor": 0.7514917663,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1296345505, 0.7958344464, 1.1465137421],
      direction: [-95.885834036, -35.0498380988, 127.2737755682],
      "speed factor": 0.9324437494,
      "Uz factor": 0.9131349425,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2181194598,
      "Direction stability factor": 0.3801121955,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1870997626, 0.4785835449, 0.7833575717],
      direction: [-79.890624881, 0.3725374525, 46.8124484809],
      "speed factor": 0.8878301482,
      "Uz factor": 0.9702198437,
      "Directional variance factor": 0.9668855598,
      "Velocity stability factor": 0.4214225169,
      "Direction stability factor": 0.6480470184,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0870720871, 0.4106597372, 1.0892822213],
      direction: [-178.0244152604, 17.8359828969, 178.7018013644],
      "speed factor": 0.5592161751,
      "Uz factor": 0.9655846625,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2650395073,
      "Direction stability factor": 0.0090938427,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1385243159, 0.687426989, 1.2129582311],
      direction: [-73.6821455067, -23.6818183658, 65.9993037914],
      "speed factor": 0.8729896114,
      "Uz factor": 0.8925718023,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1346138865,
      "Direction stability factor": 0.6119959742,
      score: 0.0,
    },
    id: "IDN:317.0",
    "45": {
      velocity: [0.0503514362, 0.4204203626, 0.8744090501],
      direction: [-83.3339443986, 28.62072764, 137.0520213763],
      "speed factor": 0.5710912597,
      "Uz factor": 0.999755223,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3300672357,
      "Direction stability factor": 0.3878167617,
      score: 0.0,
    },
    "90": {
      velocity: [0.140742151, 0.6174360558, 1.0651876624],
      direction: [-80.3754608763, 29.7245159397, 129.590630515],
      "speed factor": 1.0008819145,
      "Uz factor": 0.953337612,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3252864809,
      "Direction stability factor": 0.4167608572,
      score: 0.0,
    },
    "135": {
      velocity: [0.9339949204, 1.1857988682, 1.3859543211],
      direction: [-9.2770564562, -2.7113239344, 18.244090708],
      "speed factor": 1.0036012573,
      "Uz factor": 0.892265337,
      "Directional variance factor": 0.758993428,
      "Velocity stability factor": 0.6358062295,
      "Direction stability factor": 0.923552369,
      score: 0.0,
    },
    "180": {
      velocity: [0.2695829064, 0.8242591066, 1.2232737649],
      direction: [-49.4855247403, -9.9715925388, 89.9172593498],
      "speed factor": 1.6097729431,
      "Uz factor": 0.9455774972,
      "Directional variance factor": 0.1136362188,
      "Velocity stability factor": 0.2485327262,
      "Direction stability factor": 0.6127700442,
      score: 0.0,
    },
    "225": {
      velocity: [0.1655610962, 0.4691686909, 0.8407051162],
      direction: [-102.2744569828, -22.888440918, 30.4623294884],
      "speed factor": 0.6404666067,
      "Uz factor": 0.9501977399,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3931936459,
      "Direction stability factor": 0.6312867042,
      score: 0.0,
    },
    "270": {
      velocity: [0.2670005853, 0.8185183224, 1.4275278278],
      direction: [-40.6633809489, 20.8531726629, 51.7805119338],
      "speed factor": 0.7489706946,
      "Uz factor": 0.9835060345,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0845723925,
      "Direction stability factor": 0.7432114087,
      score: 0.0,
    },
    "315": {
      velocity: [0.2563121887, 0.7448074262, 1.1334217412],
      direction: [-85.0306270118, 4.9691452304, 76.3648210876],
      "speed factor": 0.9176768709,
      "Uz factor": 0.9534966345,
      "Directional variance factor": 0.5582982017,
      "Velocity stability factor": 0.2884571825,
      "Direction stability factor": 0.5516793108,
      score: 0.0,
    },
  },
  {
    position: [-2.410495206, 101.4859026, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.6541650522, 1.1442008997, 1.5763994307],
      direction: [-29.3109017282, 6.0408663574, 31.76566239],
      "speed factor": 1.1725210725,
      "Uz factor": 0.8677626609,
      "Directional variance factor": 0.4630341016,
      "Velocity stability factor": 0.3592031551,
      "Direction stability factor": 0.8303428774,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6339908639, 1.0407409251, 1.3982623041],
      direction: [-29.4147148285, -2.7145295289, 22.8470728396],
      "speed factor": 0.9240485266,
      "Uz factor": 0.8410988957,
      "Directional variance factor": 0.7587084863,
      "Velocity stability factor": 0.5057659512,
      "Direction stability factor": 0.8548283676,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0891482802, 0.4094872941, 0.8210817808],
      direction: [-91.7669545365, -0.8113755349, 176.0175992757],
      "speed factor": 0.6343231171,
      "Uz factor": 0.9297396661,
      "Directional variance factor": 0.9278777302,
      "Velocity stability factor": 0.3459775074,
      "Direction stability factor": 0.2561540172,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0913777197, 0.6978669435, 1.1992206558],
      direction: [-138.2623530023, 13.4837536104, 156.3778091286],
      "speed factor": 0.7850515579,
      "Uz factor": 0.9945201153,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0829065356,
      "Direction stability factor": 0.1815551052,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0675677511, 0.2926613794, 0.7060674564],
      direction: [-178.2196236812, 26.025034314, 173.9156717863],
      "speed factor": 0.4157666644,
      "Uz factor": 0.9367918215,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4778404035,
      "Direction stability factor": 0.0218464015,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0208239259, 0.2782130737, 0.5717031586],
      direction: [-178.8799218592, -97.6210205292, 178.3185313874],
      "speed factor": 0.3259698581,
      "Uz factor": 0.999542171,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5764278042,
      "Direction stability factor": 0.0077820743,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0639480942, 0.3727944958, 0.8506178071],
      direction: [-109.1075396471, -15.6322455351, 42.8238631516],
      "speed factor": 0.6915787138,
      "Uz factor": 0.9975843704,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2366567354,
      "Direction stability factor": 0.5779683256,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0576807125, 0.4856539454, 1.0192309351],
      direction: [-157.5171515182, 18.1782096521, 175.8986855994],
      "speed factor": 0.6613395888,
      "Uz factor": 0.8473790476,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2948570352,
      "Direction stability factor": 0.0738448969,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0237478792, 0.5195239284, 0.9345039556],
      direction: [-125.006926523, 3.5684207241, 179.7371840553],
      "speed factor": 0.6597631452,
      "Uz factor": 0.7740310524,
      "Directional variance factor": 0.6828070467,
      "Velocity stability factor": 0.2664456603,
      "Direction stability factor": 0.1534885817,
      score: 0.0,
    },
    id: "IDN:313.0",
    "45": {
      velocity: [0.0883880042, 0.4890356672, 0.9897945627],
      direction: [-111.4909030229, 1.1336078729, 155.7784999386],
      "speed factor": 0.6642970228,
      "Uz factor": 0.9350997241,
      "Directional variance factor": 0.8992348557,
      "Velocity stability factor": 0.2671849913,
      "Direction stability factor": 0.2575849918,
      score: 0.0,
    },
    "90": {
      velocity: [0.0491509793, 0.3685607067, 0.8388000638],
      direction: [-142.2084970391, 24.0473306752, 172.6891546884],
      "speed factor": 0.5974476909,
      "Uz factor": 0.9893502806,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4236686683,
      "Direction stability factor": 0.1252843008,
      score: 0.0,
    },
    "135": {
      velocity: [0.0350170784, 0.2872819802, 0.6024076815],
      direction: [-178.3114350888, -131.3827797652, 179.3158897244],
      "speed factor": 0.2431411973,
      "Uz factor": 0.9988294828,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5427905188,
      "Direction stability factor": 0.0065907644,
      score: 0.0,
    },
    "180": {
      velocity: [0.057153044, 0.2937840661, 0.8815957208],
      direction: [-175.8522891683, -7.870354921, 177.6166674292],
      "speed factor": 0.5737584663,
      "Uz factor": 0.9885296387,
      "Directional variance factor": 0.3004128959,
      "Velocity stability factor": 0.3503747203,
      "Direction stability factor": 0.0181417872,
      score: 0.0,
    },
    "225": {
      velocity: [0.0459679478, 0.3484391221, 0.8133001439],
      direction: [-169.243507696, -15.2527783164, 174.9300592974],
      "speed factor": 0.4756575333,
      "Uz factor": 0.9994531331,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3103367007,
      "Direction stability factor": 0.0439623139,
      score: 0.0,
    },
    "270": {
      velocity: [0.1903458085, 0.9188674378, 1.2838870269],
      direction: [-111.1456185247, -2.5481807119, 22.6443413218],
      "speed factor": 0.8407933754,
      "Uz factor": 0.9957293183,
      "Directional variance factor": 0.7734950478,
      "Velocity stability factor": 0.137411183,
      "Direction stability factor": 0.6283612226,
      score: 0.0,
    },
    "315": {
      velocity: [0.7595308034, 0.9720581882, 1.2799857878],
      direction: [-26.4665247743, -8.6553845675, 16.6163947079],
      "speed factor": 1.1976724253,
      "Uz factor": 0.885362311,
      "Directional variance factor": 0.2306324829,
      "Velocity stability factor": 0.5777881966,
      "Direction stability factor": 0.8803252237,
      score: 0.0,
    },
  },
  {
    position: [0.575319964, 101.7772919, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.6480083095, 1.1263803795, 1.4322276248],
      direction: [-33.5337739073, -5.35098284, 24.0491544929],
      "speed factor": 1.1542594757,
      "Uz factor": 0.8486090068,
      "Directional variance factor": 0.5243570809,
      "Velocity stability factor": 0.455100271,
      "Direction stability factor": 0.8400474211,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.6908285724, 1.0486141087, 1.4195337449],
      direction: [-46.5299998413, -6.8372706344, 25.4274167383],
      "speed factor": 0.9310389347,
      "Uz factor": 0.8414760004,
      "Directional variance factor": 0.3922426103,
      "Velocity stability factor": 0.5287657123,
      "Direction stability factor": 0.8001182873,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0355137355, 0.4127407262, 0.8479597673],
      direction: [-133.5509217968, -5.7191674497, 135.7635266799],
      "speed factor": 0.6393629003,
      "Uz factor": 0.9587837351,
      "Directional variance factor": 0.49162956,
      "Velocity stability factor": 0.2740351707,
      "Direction stability factor": 0.2519043098,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.0463833115, 0.5647580256, 1.208348398],
      direction: [-171.4534914649, 16.2007412868, 157.7388131778],
      "speed factor": 0.6353133244,
      "Uz factor": 0.9967492656,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0381031897,
      "Direction stability factor": 0.0855769315,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0487475735, 0.2768276866, 0.6241781973],
      direction: [-178.6561994376, 94.2732734012, 179.8732611649],
      "speed factor": 0.3932726762,
      "Uz factor": 0.8944775274,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5294177588,
      "Direction stability factor": 0.0040848317,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0416318653, 0.2466339743, 0.5814303896],
      direction: [-177.369246622, -152.6923081289, 177.7626131521],
      "speed factor": 0.2889700348,
      "Uz factor": 0.9997480141,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5849477841,
      "Direction stability factor": 0.0135226117,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0495545055, 0.3470135446, 0.8623265664],
      direction: [-166.9843530561, -10.5596533772, 158.8860986876],
      "speed factor": 0.6437519427,
      "Uz factor": 0.9999728162,
      "Directional variance factor": 0.0613641442,
      "Velocity stability factor": 0.2113283782,
      "Direction stability factor": 0.0948043007,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0233077194, 0.4196827554, 0.9379116834],
      direction: [-172.7096224793, 19.9465524369, 150.5180079263],
      "speed factor": 0.5715032763,
      "Uz factor": 0.8652202657,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.329284591,
      "Direction stability factor": 0.1021454711,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.112655474, 0.5679402335, 1.1364515392],
      direction: [-121.0897115603, 5.6946543414, 161.1127082719],
      "speed factor": 0.7212488477,
      "Uz factor": 0.862595911,
      "Directional variance factor": 0.493808503,
      "Velocity stability factor": 0.1753993565,
      "Direction stability factor": 0.2161043894,
      score: 0.0,
    },
    id: "IDN:312.0",
    "45": {
      velocity: [0.1437818665, 0.5287274686, 1.0143133854],
      direction: [-69.874913326, -3.4638163427, 109.8053289222],
      "speed factor": 0.7182136332,
      "Uz factor": 0.9392732663,
      "Directional variance factor": 0.692105214,
      "Velocity stability factor": 0.2922854215,
      "Direction stability factor": 0.500888216,
      score: 0.0,
    },
    "90": {
      velocity: [0.0424839798, 0.2716725024, 0.7632837153],
      direction: [-171.8121556728, 9.0127566982, 137.8782581487],
      "speed factor": 0.4403890764,
      "Uz factor": 0.9873637671,
      "Directional variance factor": 0.1988660713,
      "Velocity stability factor": 0.4739188842,
      "Direction stability factor": 0.1397488505,
      score: 0.0,
    },
    "135": {
      velocity: [0.029081409, 0.2294100747, 0.5318620564],
      direction: [-179.2316331919, -117.1666917784, 174.3023271929],
      "speed factor": 0.1941612912,
      "Uz factor": 0.9963166028,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5948539194,
      "Direction stability factor": 0.0179612212,
      score: 0.0,
    },
    "180": {
      velocity: [0.0289943561, 0.2561285731, 0.5525585491],
      direction: [-179.5902985369, -175.4183309944, 178.9207106537],
      "speed factor": 0.5002175209,
      "Uz factor": 0.8400556474,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5874539917,
      "Direction stability factor": 0.0041360856,
      score: 0.0,
    },
    "225": {
      velocity: [0.0220268243, 0.2972320081, 0.6144937329],
      direction: [-176.6504367481, -8.9154825848, 153.6143489111],
      "speed factor": 0.4057542187,
      "Uz factor": 0.9999401275,
      "Directional variance factor": 0.2075126591,
      "Velocity stability factor": 0.4675022305,
      "Direction stability factor": 0.0825978176,
      score: 0.0,
    },
    "270": {
      velocity: [0.1816821436, 0.8745189526, 1.3386550087],
      direction: [-168.265912502, 1.9949858342, 19.7836594871],
      "speed factor": 0.8002130795,
      "Uz factor": 0.9969474663,
      "Directional variance factor": 0.8226679258,
      "Velocity stability factor": 0.0873760967,
      "Direction stability factor": 0.4776400778,
      score: 0.0,
    },
    "315": {
      velocity: [0.7426419464, 0.9588867327, 1.2903361398],
      direction: [-28.1930698934, -10.5058255466, 16.6138640997],
      "speed factor": 1.1814438812,
      "Uz factor": 0.8948185112,
      "Directional variance factor": 0.0661488403,
      "Velocity stability factor": 0.5556907705,
      "Direction stability factor": 0.8755362945,
      score: 0.0,
    },
  },
  {
    position: [3.561135135, 102.0686813, 45.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.6180358579, 1.1252193994, 1.4934606969],
      direction: [-36.3313336202, -10.633517395, 16.9866526845],
      "speed factor": 1.1530697601,
      "Uz factor": 0.7984311044,
      "Directional variance factor": 0.0547984538,
      "Velocity stability factor": 0.3917278646,
      "Direction stability factor": 0.8518944825,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.7473967512, 1.0351407856, 1.4258963345],
      direction: [-59.762064314, -13.5792322552, 23.1207865417],
      "speed factor": 0.9190762991,
      "Uz factor": 0.8224751704,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5612323338,
      "Direction stability factor": 0.7697698587,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.0356695705, 0.4133740494, 0.8844411261],
      direction: [-159.9170509067, -9.8504078949, 174.9383398491],
      "speed factor": 0.6403439601,
      "Uz factor": 0.9590418846,
      "Directional variance factor": 0.1244081871,
      "Velocity stability factor": 0.2415763345,
      "Direction stability factor": 0.0698461368,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.048988622, 0.3674175477, 1.0960108301],
      direction: [-150.0145978014, 23.9326411133, 174.0523574736],
      "speed factor": 0.4133190731,
      "Uz factor": 0.9997199302,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1332550918,
      "Direction stability factor": 0.0998140131,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0407495646, 0.2541875587, 0.7561703461],
      direction: [-179.5312138296, 92.4565913338, 167.1442863016],
      "speed factor": 0.3611091891,
      "Uz factor": 0.9440445393,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4149350055,
      "Direction stability factor": 0.0370124996,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0648847566, 0.2919402672, 0.5916010228],
      direction: [-179.6317347354, -166.1504128684, 177.8993545665],
      "speed factor": 0.3420533989,
      "Uz factor": 0.9976969259,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5950067598,
      "Direction stability factor": 0.0068580853,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0600554803, 0.3411214435, 0.8492944722],
      direction: [-64.768043925, -2.501675784, 179.83139882],
      "speed factor": 0.632821385,
      "Uz factor": 0.9999070653,
      "Directional variance factor": 0.7776288192,
      "Velocity stability factor": 0.2341636411,
      "Direction stability factor": 0.3205571035,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0602593664, 0.411960396, 1.0177851563],
      direction: [-134.7317415015, 19.8347345443, 177.6512575739],
      "speed factor": 0.560987348,
      "Uz factor": 0.8581238615,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2978083115,
      "Direction stability factor": 0.132269447,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.0865202365, 0.5634700598, 1.0255320462],
      direction: [-142.6058061858, 8.9675615078, 155.9546712534],
      "speed factor": 0.7155720045,
      "Uz factor": 0.8908026984,
      "Directional variance factor": 0.2028834215,
      "Velocity stability factor": 0.2436875187,
      "Direction stability factor": 0.1706653404,
      score: 0.0,
    },
    id: "IDN:311.0",
    "45": {
      velocity: [0.1808454946, 0.5604630782, 1.0043126189],
      direction: [-81.1987134889, -6.7123815894, 133.7945830561],
      "speed factor": 0.7613226995,
      "Uz factor": 0.9527408922,
      "Directional variance factor": 0.4033438587,
      "Velocity stability factor": 0.330547285,
      "Direction stability factor": 0.4027963985,
      score: 0.0,
    },
    "90": {
      velocity: [0.0345025802, 0.2513548303, 0.7396667394],
      direction: [-176.7677910296, 21.0519389904, 153.7190442796],
      "speed factor": 0.4074535353,
      "Uz factor": 0.9765352432,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4853306274,
      "Direction stability factor": 0.081981013,
      score: 0.0,
    },
    "135": {
      velocity: [0.0408842259, 0.3756421001, 1.0291557657],
      direction: [-162.5278655614, -0.3608769948, 178.9172239873],
      "speed factor": 0.3179248135,
      "Uz factor": 0.9997028174,
      "Directional variance factor": 0.9679220449,
      "Velocity stability factor": 0.2036401105,
      "Direction stability factor": 0.0515414179,
      score: 0.0,
    },
    "180": {
      velocity: [0.0281484655, 0.2478761788, 0.5312681469],
      direction: [-178.7947697198, 162.0412249588, 179.227287974],
      "speed factor": 0.4841006459,
      "Uz factor": 0.9831588131,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6035633853,
      "Direction stability factor": 0.0054942842,
      score: 0.0,
    },
    "225": {
      velocity: [0.0438555155, 0.264243721, 0.603222285],
      direction: [-146.6857677532, -7.43812919, 152.5066071544],
      "speed factor": 0.3607215968,
      "Uz factor": 0.9998200095,
      "Directional variance factor": 0.3388329609,
      "Velocity stability factor": 0.4972519938,
      "Direction stability factor": 0.1689100697,
      score: 0.0,
    },
    "270": {
      velocity: [0.1460163227, 0.8758885451, 1.305579029],
      direction: [-96.5777587874, 4.8049621909, 21.917545875],
      "speed factor": 0.8014663009,
      "Uz factor": 0.9931341232,
      "Directional variance factor": 0.5728922497,
      "Velocity stability factor": 0.0853332217,
      "Direction stability factor": 0.6708463759,
      score: 0.0,
    },
    "315": {
      velocity: [0.7188113369, 0.9451601896, 1.3126687356],
      direction: [-29.9579386329, -13.2728978333, 8.0917104414],
      "speed factor": 1.1645314141,
      "Uz factor": 0.899999456,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5182415179,
      "Direction stability factor": 0.8943065303,
      score: 0.0,
    },
  },
  {
    position: [12.51858065, 102.9428494, 44.87999908],
    "overall score": 0.0,
    "0": {
      velocity: [0.2824855016, 0.6840589756, 1.0415078542],
      direction: [-71.7875060338, -51.9986008095, -24.0389987444],
      "speed factor": 0.700990153,
      "Uz factor": 0.8734706089,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4726078965,
      "Direction stability factor": 0.8673652575,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4021590674, 0.6816099893, 1.0149535515],
      direction: [-93.847863741, -42.271482469, -7.4877605226],
      "speed factor": 0.6051849131,
      "Uz factor": 0.8706029373,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6037220769,
      "Direction stability factor": 0.7601108244,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.1006457564, 0.3439773119, 0.6635879659],
      direction: [-84.5734969348, 14.9507803376, 158.6601373943],
      "speed factor": 0.5328437874,
      "Uz factor": 0.9264073486,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4969804404,
      "Direction stability factor": 0.3243510158,
      score: 0.0,
    },
    "112.5": {
      velocity: [0.2517452972, 0.7814245929, 0.9974837343],
      direction: [-3.888092274, 34.6275882157, 49.5222921293],
      "speed factor": 0.879048076,
      "Uz factor": 0.9117174323,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3826635307,
      "Direction stability factor": 0.8516378211,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.0625126699, 0.5263538116, 0.9607075327],
      direction: [-80.9459862511, 17.8351927594, 159.4492116882],
      "speed factor": 0.747759643,
      "Uz factor": 0.9977631275,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2654639255,
      "Direction stability factor": 0.3322355613,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0211777517, 0.2000815399, 0.4698160012],
      direction: [-176.4714742441, -160.2109243494, 178.5670449378],
      "speed factor": 0.2344266224,
      "Uz factor": 0.5606864771,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6550411103,
      "Direction stability factor": 0.0137818912,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0151712861, 0.1500217657, 0.3261293808],
      direction: [-83.4001057865, 9.4500538232, 130.3752927252],
      "speed factor": 0.278308454,
      "Uz factor": 0.9922180861,
      "Directional variance factor": 0.1599952157,
      "Velocity stability factor": 0.6982624813,
      "Direction stability factor": 0.4061794486,
      score: 0.0,
    },
    "292.5": {
      velocity: [0.0135680133, 0.1817549893, 0.4593193945],
      direction: [-174.7601796235, 6.6471419542, 179.3228532109],
      "speed factor": 0.2475049797,
      "Uz factor": 0.8035780475,
      "Directional variance factor": 0.4091429374,
      "Velocity stability factor": 0.6731128098,
      "Direction stability factor": 0.0164360199,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1014278475, 0.5491258975, 1.0415594677],
      direction: [-64.6887456604, -14.0770925621, 18.2421388127],
      "speed factor": 0.6973558088,
      "Uz factor": 0.9983040925,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2427855846,
      "Direction stability factor": 0.769636432,
      score: 0.0,
    },
    id: "IDN:1.0",
    "45": {
      velocity: [0.1181865251, 0.3687084685, 0.6692432871],
      direction: [-94.0690553941, -19.4004283644, 66.5675480038],
      "speed factor": 0.5008467774,
      "Uz factor": 0.8956382246,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5520082897,
      "Direction stability factor": 0.5537872128,
      score: 0.0,
    },
    "90": {
      velocity: [0.1762604209, 0.6062350419, 0.846051297],
      direction: [-0.2282836454, 44.8174297456, 68.5360199927],
      "speed factor": 0.9827247433,
      "Uz factor": 0.9633712663,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5111480844,
      "Direction stability factor": 0.8089880454,
      score: 0.0,
    },
    "135": {
      velocity: [0.5126336646, 1.0112195565, 1.2011455043],
      direction: [12.7577480727, 23.1997062047, 32.5376088192],
      "speed factor": 0.8558460001,
      "Uz factor": 0.9910811171,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4451897171,
      "Direction stability factor": 0.9450559424,
      score: 0.0,
    },
    "180": {
      velocity: [0.0068690274, 0.1890217449, 0.7028389482],
      direction: [-174.1187283138, 27.960349075, 178.2201372081],
      "speed factor": 0.3691583,
      "Uz factor": 0.8589034499,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4516057123,
      "Direction stability factor": 0.0212809291,
      score: 0.0,
    },
    "225": {
      velocity: [0.0228877421, 0.1336575786, 0.3270093095],
      direction: [-179.8663683968, 12.7836586266, 160.0641491575],
      "speed factor": 0.1824572216,
      "Uz factor": 0.9827530882,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7266614322,
      "Direction stability factor": 0.0557485623,
      score: 0.0,
    },
    "270": {
      velocity: [0.1032390882, 0.5716402018, 0.9206950994],
      direction: [-92.6757991153, -0.210140881, 15.8116584175],
      "speed factor": 0.5230692427,
      "Uz factor": 0.9964218329,
      "Directional variance factor": 0.9813208106,
      "Velocity stability factor": 0.355188079,
      "Direction stability factor": 0.6986459513,
      score: 0.0,
    },
    "315": {
      velocity: [0.3014765567, 0.6574899316, 1.0342407786],
      direction: [-44.5158634416, -28.8371772292, -12.0720872031],
      "speed factor": 0.8100930279,
      "Uz factor": 0.9993588371,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4055553066,
      "Direction stability factor": 0.9098783993,
      score: 0.0,
    },
  },
];
export default zoneOneProject52;
