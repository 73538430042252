import React, { useEffect, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from "@react-three/fiber";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

function traverseColor(baseGltf, color) {
  baseGltf.scene.traverse((object) => {
    if (object.isMesh) {
      object.receiveShadow = true;
      object.castShadow = true;
      object.material.color.set(color);
    }
  });
}
export function AssetOld({ url, visible, color }) {
  const gltf = useLoader(GLTFLoader, url, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.6/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);
  });
  traverseColor(gltf, color);
  return <primitive object={gltf.scene} visible={visible} />;
}

export default function Asset({ url, visible, color, onFulfilled, onLoading }) {
  const [model, setModel] = useState(null);

  useEffect(() => {
    if (model === null) {
      const loadModel = async () => {
        if (onLoading) onLoading();

        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath(
          "https://www.gstatic.com/draco/versioned/decoders/1.5.6/"
        );
        dracoLoader.setDecoderConfig({ type: "js" });
        loader.setDRACOLoader(dracoLoader);
        try {
          const gltf = await loader.loadAsync(url);
          gltf.scene.traverse((child) => {
            // if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            // }
          });
          setModel(gltf?.scene);
          traverseColor(gltf, color);
        } catch {
        }

        if (onFulfilled) onFulfilled();
      };

      loadModel();
    }
  }, [url, model]);

  return (
    <group>
      {visible && model !== null && (
        <primitive object={model} visible={visible} />
      )}
    </group>
  );
}
