import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { setTurbineInfoBoxShow } from "../../redux/windTurbine";
import { xAxisLabelStyles, yAxisLabelStyles } from "../charts/Axis";
import ChartPaper from "../charts/ChartPaper";
import RadarChartComponent from "../charts/RadarChartComponent";
import "../InformationBox/InfoBoxDashboard.css";
import { arraySum, generateAxisValues, monthsOrder } from "../utils";
import InfoBoxDashboardContainer from "./InfoBoxDashboard";

// Constants
const CHART_WIDTH = 350;
const CHART_HEIGHT = 200;
const RADAR_CHART_DIMENSIONS = { height: 200, width: 320, outerRadius: "80%" };
const BAR_CHART_DIMENSIONS = { height: CHART_HEIGHT, width: CHART_WIDTH };
const BLUE = 'var(--clr-primary)'

const WindTurbineInfoBox = () => {
  // Redux Selectors
  var windTurbineSelector = useSelector((state) => state.windTurbine);
  const { valuesRanges } = windTurbineSelector
  const visible = windTurbineSelector.infoBoxShow

  const wholeSensorData = windTurbineSelector.TurbineData

  const radarDirections = ["0.0",
    "22.5",
    "45.0",
    "67.5",
    "90.0",
    "112.5",
    "135.0",
    "157.5",
    "180.0",
    "202.5",
    "225.0",
    "247.5",
    "270.0",
    "292.5",
    "315.0",
    "337.5"
  ]
  const radarChartList = radarDirections?.map((direction) => {
    return { direction, velocity: wholeSensorData?.wind_measurements?.[direction]?.avg }
  })
  radarChartList?.sort((a, b) => Number(a?.direction) - Number(b?.direction));

  const windFrequencyData = Object.entries(wholeSensorData?.aep_kwh_frequency || {})?.map(([value, hour]) => {
    return { hour: hour?.toFixed(2), value: parseFloat(value)?.toFixed(2) }
  })
  windFrequencyData.sort((a, b) => a.hour - b.hour)
  const hoursOfUse = windFrequencyData?.filter(row => parseFloat(row.value) > 0)?.map(row => parseFloat(row.hour))

  const monthlyGeneration = Object.entries(wholeSensorData?.monthly_generation || {})?.map(([month, value]) => {
    return { month, value: parseFloat(value)?.toFixed(2) }
  })

  monthlyGeneration.sort((a, b) => monthsOrder.indexOf(a) - monthsOrder.indexOf(b))

  const annualWindSpeedFrequency = Object.entries(wholeSensorData?.avg_wind_frequency || {})?.map(([velocity, hour]) => {
    return { hour, velocity: parseFloat(velocity)?.toFixed(2) }
  })

  annualWindSpeedFrequency.sort((a, b) => a?.velocity - b?.velocity)

  const captureFrameRef = useRef()

  const dispatch = useDispatch();
  if (visible)
    return (

      <InfoBoxDashboardContainer
        handleClickOutside={() => dispatch(setTurbineInfoBoxShow(false))}
        style={{
          width: '850px',
          display: 'block',
          height: '680px',
        }}
        needsCapture
        captureProps={{
          targetElement: () => captureFrameRef?.current,
        }}
      >

        <div ref={captureFrameRef}>
          <SummaryInfo name={wholeSensorData?.name} aep={wholeSensorData?.aep_kwh} utm={wholeSensorData?.coordinates}
            hoursOfUse={arraySum(hoursOfUse)} />
          <div style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr 1fr',
            gap: '10px',
            marginTop: '10px'
          }}>
            <BarChartSection
              dimentions={{ ...BAR_CHART_DIMENSIONS, width: 375 }}
              title="Annual Wind Speed Frequency"
              data={annualWindSpeedFrequency}
              axisDataKey={"velocity"}
              valueDataKey={"hour"}
              xLabel='Speed [m/s]'
              yLabel='Time [hour]'
              xAxisType='number'
              yDomain={valuesRanges?.avg_wind_frequency}
              xDomain={[valuesRanges?.avg_wind_frequency_velocity?.[0] - 1, valuesRanges?.avg_wind_frequency_velocity?.[1]]}
              xAxisTicks={generateAxisValues(valuesRanges?.avg_wind_frequency_velocity?.[0], valuesRanges?.avg_wind_frequency_velocity?.[1])}
            />
            <RadarChartComponent
              dimentions={RADAR_CHART_DIMENSIONS}
              title="Average wind speed coefficient"
              data={radarChartList}
              axisDataKey={"direction"}
              valueDataKey={"velocity"}
              fill={'white'}
              stroke={BLUE}
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                borderRadius: '7px',
              }}
            />
            <BarChartSection
              dimentions={BAR_CHART_DIMENSIONS}
              title="Monthly generation"
              data={monthlyGeneration}
              axisDataKey={"month"}
              valueDataKey={"value"}
              xLabel='Month'
              yLabel='Energy yield [kWh]'
              yDomain={valuesRanges?.monthly_generation}
            />

            <AEPFrequencySection title='Annual Energy Production Frequency' data={windFrequencyData}
              xLabel='Hour'
              yLabel='kW'
            />
          </div>
        </div>

      </InfoBoxDashboardContainer>
    );
};



const BarChartSection = ({ title, data, dimentions, axisDataKey, valueDataKey, style, xLabel, yLabel, yDomain, xDomain, xAxisType = 'category',
  xAxisTicks,
}) => {
  const yAxisNumbers = generateAxisValues(yDomain[0], yDomain[1]);
  return (
    <ChartPaper className="chart-section" style={style}>
      <p className="chart-title">{title}</p>
      <BarChart {...dimentions} data={data}
        margin={{ bottom: 15, left: 15, top: 10 }}
      >
        <XAxis dataKey={axisDataKey} label={{ value: xLabel, ...xAxisLabelStyles }} domain={xDomain} type={xAxisType}
          ticks={xAxisTicks} />

        <YAxis type="number" domain={yDomain}
          label={{
            value: yLabel, ...yAxisLabelStyles,
          }}
          tickFormatter={(value) => value?.toFixed(1)}
          ticks={yAxisNumbers}
        />
        <Tooltip />
        <Bar
          dataKey={valueDataKey}
          stroke={BLUE}
          fill={BLUE}
        />
        {/* <Legend /> */}
        <CartesianGrid strokeDasharray="3 3" stroke="#bbb" />
      </BarChart>
    </ChartPaper>)
}

const AEPFrequencySection = ({ title, data, xLabel, yLabel, style }) => (
  <ChartPaper style={style}>
    <p className="chart-title">{title}</p>
    <LineChart width={375} height={CHART_HEIGHT} data={data}
      margin={{ bottom: 15, left: 15 }}>
      <XAxis dataKey="hour" type="number" domain={[0, 8760]} label={{ value: xLabel, ...xAxisLabelStyles }} />
      <YAxis type="number" domain={[0, 8]} ticks={[0, 1, 2, 3, 4, 5, 6, 7]} interval={0} label={{
        value: yLabel, ...yAxisLabelStyles, position: 'left'
      }}
        tickFormatter={(value) => value?.toFixed(1)}
      />
      <Tooltip />
      <Line
        type="monotone"
        dataKey="value"
        stroke={BLUE}
        fill={BLUE}
        activeDot={{ r: 10 }}
      />
      <CartesianGrid strokeDasharray="3 3" />
    </LineChart>
  </ChartPaper>
);

const SummaryInfo = ({ name, aep, utm, hoursOfUse }) => {
  const items = [{ label: 'ID:', value: name },
  { label: 'Energy Yield:', value: `${aep?.toFixed(2)} [kWh/a]` },
  {
    label: 'Location:', value: <span style={{ display: 'flex', gap: '16px' }}>
      <span>{utm.utm_x?.toFixed(1)}</span>
      <span>{utm.utm_y?.toFixed(1)}</span>
      <span>{utm.utm_z?.toFixed(1)}</span>
    </span>
  },
  { label: 'Hours of use: ', value: hoursOfUse?.toFixed(2) }
  ]
  return (
    <ChartPaper style={{
      // width: '280px',
      color: 'white',
      padding: '12px',
    }}>
      <ul style={{
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'

      }}>
        {items.map(item => {
          return (
            <li >
              <h3 className="t-label-m-caps">{item.label}</h3>
              <span className="t-numbers-m ">{item.value}</span>
            </li>
          )
        })}
      </ul>
    </ChartPaper >
  )

}

export default WindTurbineInfoBox;
