import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: { checkStatus: [false, false] },
  rangeValues: {
    "kWh": [null, null],
    "kWh/m2": [null, null],
  },
  haveRange: false,
  currentValue: null,
  unit: 'kWh',
  unitOptions: ['kWh', 'kWh/m2'],
  filter: {},
  pVDataIsLoading: false,
};

const pvSlice = createSlice({
  name: "pvData",
  initialState,
  reducers: {
    setPVRange: (state, action) => {
      state.rangeValues = action.payload;
      state.haveRange = true;
    },
    setPVDataIsLoading: (state, action) => {
      state.pVDataIsLoading = action.payload;
    },
    deselectPVData: (state) => {
      state.filter = {};
    },
    energyGenerationToggle: (state, action) => {
      const selected = !state.value.checkStatus[action.payload];
      if (selected) state.value.checkStatus = [false, false];
      state.value.checkStatus[action.payload] = selected;
    },
    setPVDataUnit: (state, action) => {
      state.unit = action.payload;
    },
    updatePVDataFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
});

export const {
  setPVRange,
  energyGenerationToggle,
  deselectPVData,
  setPVDataUnit,
  updatePVDataFilter,
  setPVDataIsLoading
} = pvSlice.actions;

export default pvSlice.reducer;
